import React from 'react';
import PropTypes from 'prop-types';

import {
	Card,
	CardHeader,
	Button,
	Input,
	Title,
	Loader,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Block,
	Text
} from '@upsales/components';

import './DomainTable.scss';

const renderLoader = show =>
	show && (
		<TableRow>
			<TableColumn colSpan={3} title={<Loader style={{ margin: '10px auto' }} noU size="sm" />} />
		</TableRow>
	);

const sortFn = (a, b) => {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
};
class DomainTable extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			title: t(props.title),
			addNewDomain: t(props.addBtnText),
			domain: t('mail.domain'),
			verify: t('mail.validateDomain'),
			edit: {
				save: t('default.save'),
				cancel: t('default.abort')
			},
			noDomains: t('admin.noDomains'),
			agreeToEmailTerms: t('admin.domains.agreeToEmailTerms'),
			viewTerms: t('admin.domains.viewTerms')
		};

		this.state = {
			verifyDomain: null,
			newDomain: null,
			isLoadingDomains: !props.domains.length
		};
		this.renderDomainRows = this.renderDomainRows.bind(this);

		this.addDomain = domain => {
			this.setState({ savingDomain: true });
			props
				.addDomain(domain)
				.then(() => this.setState({ newDomain: null, savingDomain: false }))
				.catch(() => this.setState({ savingDomain: false }));
		};

		// eslint-disable-next-line promise/catch-or-return
		props.getDomains().finally(() => this.setState({ isLoadingDomains: false }));
	}

	renderDomainRows(rows, domain, i) {
		const toggleVerified = e => {
			e.stopPropagation();
			this.setState({
				verifyDomain: this.state.verifyDomain === i ? null : i
			});
		};
		const toggleEdit = (id, name) => {
			this.setState({ verifyDomain: null, newDomain: { id, name } });
		};
		rows.push(this.props.renderRow(domain, toggleVerified, toggleEdit, i));
		if (this.state.verifyDomain === i) {
			rows.push(this.props.renderVerifyRow(() => this.setState({ verifyDomain: null }), domain));
		}
		return rows;
	}

	renderEditDomainRow() {
		const lang = this.lang.edit;
		const domain = this.state.newDomain;
		return (
			domain && (
				<TableRow key="add-domain-row" className="DomainTable__edit-row">
					<TableColumn size="lg" />
					<TableColumn size="lg">
						<Input
							inline
							value={domain.name || ''}
							placeholder={this.lang.domain}
							onChange={e => this.setState({ newDomain: { ...domain, name: e.target.value } })}
							autofocus
							onKeyDown={e =>
								e.key.toLowerCase() === 'enter' && domain.name.length > 1 && !domain.adding
									? this.addDomain(domain)
									: null
							}
						/>
					</TableColumn>
					<TableColumn size="lg" className="Domain__tools-col" colSpan={this.props.hasDelete ? 2 : undefined}>
						<div className="DomainTable__Add-domain-buttons">
							<Button
								block
								type="link"
								shadow={false}
								disabled={domain.adding}
								onClick={() => this.setState({ newDomain: null })}
							>
								{lang.cancel}
							</Button>
							<Button
								block
								shadow={false}
								onClick={() => this.addDomain(domain)}
								disabled={domain.name?.length < 1 || domain.adding}
								loading={domain.adding}
							>
								{lang.save}
							</Button>
						</div>
					</TableColumn>
				</TableRow>
			)
		);
	}

	renderMissingMailAccount() {
		return (
			<Block className="DomainTable__error-wrapper">
				<Text>{this.lang.agreeToEmailTerms}</Text>
				<Button onClick={() => Tools.$state.go('administration.mailSettings', {})}>
					{this.lang.viewTerms}
				</Button>
			</Block>
		);
	}

	render() {
		const rows = this.props.domains.sort(sortFn).reduce(this.renderDomainRows, []);

		const tableHeader = [
			{}, // Indicator
			{ title: this.lang.domain },
			{ title: `${this.lang.verify} ${this.lang.domain.toLowerCase()}` }
		];
		if (this.props.hasDelete) {
			tableHeader.push({ title: '' });
		}
		return (
			<Card className="DomainTable">
				<CardHeader size="md" title={this.lang.title}>
					<Button
						onClick={() => {
							if (this.props.onAdd) {
								this.props.onAdd();
							} else {
								this.setState({ newDomain: { name: '' } });
							}
						}}
						disabled={this.props.missingMailAccount || this.state.isLoadingDomains}
						color={this.props.missingMailAccount ? 'light-grey' : 'green'}
					>
						{this.lang.addNewDomain}
					</Button>
				</CardHeader>
				<Table className="DomainTable__records-table">
					{rows.length ? <TableHeader columns={tableHeader} /> : null}
					{this.renderEditDomainRow()}
					{renderLoader(this.state.isLoadingDomains)}
					{rows}
					{!rows.length && !this.state.isLoadingDomains ? (
						this.props.missingMailAccount ? (
							this.renderMissingMailAccount()
						) : (
							<TableRow>
								<TableColumn
									align="center"
									colSpan={4}
									title={<Title color="grey-10">{this.lang.noDomains}</Title>}
								/>
							</TableRow>
						)
					) : null}
				</Table>
			</Card>
		);
	}
}

DomainTable.propTypes = {
	addDomain: PropTypes.func,
	getDomains: PropTypes.func.isRequired,
	domains: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	addBtnText: PropTypes.string.isRequired,
	renderRow: PropTypes.func.isRequired,
	renderVerifyRow: PropTypes.func.isRequired,
	hasDelete: PropTypes.bool,
	onAdd: PropTypes.func,
	missingMailAccount: PropTypes.bool
};

DomainTable.defaultProps = {
	domains: []
};

export default DomainTable;

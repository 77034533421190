'use strict';

angular.module('services.customfieldMapper', []).factory('CustomfieldMapper', [
	'$translate',
	'AppService',
	function ($translate, AppService) {
		var hasAccess = function (field, roleId, admin) {
			if (admin || !field.roles || !field.roles.length || _.find(field.roles, { id: roleId })) {
				return true;
			}

			return false;
		};

		var instance = {
			hasAccess: function (field) {
				var self = AppService.getSelf();
				var myRoleId = self.role ? self.role.id : null;
				return hasAccess(field, myRoleId, self.administrator);
			},

			map: function (form) {
				var customFields = [];
				angular.forEach(form, function (field, key) {
					var split = key.split('_');
					if (split[0] === 'upCustomField' && !_.isUndefined(field.$modelValue)) {
						if (
							_.isNull(field.$modelValue) &&
							!_.includes(
								['Integer', 'Currency', 'Discount', 'Select', 'User'],
								field.$$customField.datatype
							)
						) {
							return;
						}

						if (!_.isNull(field.$modelValue)) {
							if (field.$$customField.datatype === 'User') {
								field.$modelValue = field.$modelValue.id || field.$modelValue;
							} else if (field.$$customField.datatype === 'Users') {
								if (!_.isEmpty(field.$modelValue)) {
									field.$modelValue = field.$modelValue
										.map(function (modelValue) {
											return modelValue.id;
										})
										.join(',');
								}
							}
						}

						customFields.push({
							fieldId: parseInt(split[1]),
							value: field.$modelValue,
							datatype: field.$$customField.datatype
						});
					}
				});
				return customFields.length ? customFields : null;
			},

			mapValues: function (parsedFields) {
				var customFields = [];
				angular.forEach(parsedFields, function (field) {
					if (!_.isUndefined(field.value)) {
						// Only Selects can be saved as null
						if (_.isNull(field.value) && field.datatype !== 'Select') {
							return;
						}
						customFields.push({ fieldId: field.fieldId, value: field.value });
					}
				});

				return customFields.length ? customFields : null;
			},

			parse: function (fields, values) {
				values = values || [];
				var self = AppService.getSelf();
				var myRoleId = self.role ? self.role.id : null;
				angular.forEach(fields, function (field) {
					var valueObj = _.find(values, { fieldId: field.id });
					field.$hasAccess = hasAccess(field, myRoleId, self.administrator);
					field.fieldId = field.id;
					if (valueObj) {
						field.value = valueObj.value;
					}
				});
				return fields;
			},

			parseVisible: function (fields, values) {
				values = values || [];
				var results = [];
				var self = AppService.getSelf();
				var myRoleId = self.role ? self.role.id : null;
				angular.forEach(values, function (valueObj) {
					var field = _.find(fields, { id: valueObj.fieldId });
					field.$hasAccess = hasAccess(field, myRoleId, self.administrator);
					if (field && field.visible) {
						field.value = valueObj.value;
						results.push(field);
					}
				});
				return results;
			}
		};

		return instance;
	}
]);

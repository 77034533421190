import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import appService from 'App/legacy/AppService';
import AccessType from 'App/enum/AccessType';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular
	.module('services.appService', [])
	.service('AppService', [
		'$q',
		'$rootScope',
		function AppService($q, $rootScope) {
			var appLoadedDefer = $q.defer();

			// Service instance
			var Instance = {
				loaded: false,
				$loadedDefer: appLoadedDefer,
				loadedPromise: appLoadedDefer.promise,
				listViews: {},
				AccessType: AccessType
			};

			const listViewAddedHook = function (e, view) {
				const ListViews = appService.getListViews();
				const type = view.type === 'userDefinedObject' ? view.type + '1' : view.type;

				if (!ListViews[type]) {
					ListViews[type] = [];
				}
				const foundIndex = _.findIndex(ListViews[type], { id: view.id });
				if (foundIndex > -1) {
					ListViews[type][foundIndex] = view;
				} else {
					ListViews[type].push(view);
				}

				appService.setAllListViews(ListViews);
			};

			const listViewUpdatedHook = function (e, view) {
				const ListViews = appService.getListViews();
				const type = view.type === 'userDefinedObject' ? view.type + '1' : view.type;
				const foundIndex = _.findIndex(ListViews[type], { id: view.id });
				if (foundIndex > -1) {
					ListViews[type][foundIndex] = view;
				}

				appService.setAllListViews(ListViews);
			};

			$rootScope.$on('listView.added', listViewAddedHook);
			$rootScope.$on('salesboard.added', listViewAddedHook);

			$rootScope.$on('listView.updated', listViewUpdatedHook);
			$rootScope.$on('salesboard.updated', listViewUpdatedHook);

			$rootScope.$on('listView.removed', function (e, view) {
				const ListViews = appService.getListViews();
				var type = view.type === 'userDefinedObject' ? view.type + '1' : view.type;
				var index = _.findIndex(ListViews[type], { id: view.id });
				if (index !== -1) {
					ListViews[type].splice(index, 1);
					appService.setAllListViews(ListViews);
				}
			});
			$rootScope.$on('salesboard.deleted', function (e, view) {
				const ListViews = appService.getListViews();
				var index = _.findIndex(ListViews.salesboard, { id: view.id });
				if (index !== -1) {
					ListViews.salesboard.splice(index, 1);
					appService.setAllListViews(ListViews);
				}
			});

			$rootScope.$on('reportView.added', function (e, view) {
				const ReportViews = appService.getReportViews();
				var access = view.private ? 'own' : 'public';
				if (!ReportViews[view.type][access]) {
					ReportViews[view.type][access] = [];
				}

				ReportViews[view.type][access].push(view);

				appService.setAllReportViews(ReportViews);
			});

			$rootScope.$on('reportView.updated', function (e, view) {
				const ReportViews = appService.getReportViews();
				var access = view.private ? 'own' : 'public';
				var index = _.findIndex(ReportViews[view.type][access], { id: view.id });

				// If this view is default we un-default the others
				if (view.default) {
					ReportViews[view.type][access] = _.map(ReportViews[view.type][access], function (viewObj) {
						viewObj.default = false;

						return viewObj;
					});
				}

				if (index !== -1) {
					ReportViews[view.type][access][index] = view;
				}

				appService.setAllReportViews(ReportViews);
			});

			$rootScope.$on('reportView.deleted', function (e, view) {
				const ReportViews = appService.getReportViews();

				var access = view.private ? 'own' : 'public';
				var index = _.findIndex(ReportViews[view.type][access], { id: view.id });

				if (index !== -1) {
					ReportViews[view.type][access].splice(index, 1);
					appService.setAllReportViews(ReportViews);
				}
			});

			$rootScope.$on('self.updated', function (e, updated) {
				Instance.setSelf(updated);
			});

			$rootScope.$on('brand.added', function (e, brand) {
				const brands = appService.getBrands();
				appService.setBrands([...brands, brand]);
			});

			$rootScope.$on('brand.updated', function (e, brand) {
				const brands = appService.getBrands();
				var index = _.findIndex(brands, { id: brand.id });

				if (index !== -1) {
					brands[index] = brand;
					appService.setBrands([...brands]);
				}
			});

			$rootScope.$on('selectedBrand.changed', function (e, brand) {
				if (brand && brand.id) {
					var self = Instance.getSelf();
					self.userParams.selectedBrand = brand.id;
					Instance.setSelf(self);
				}
			});

			$rootScope.$on('userParam.updated', function (e, key, value) {
				if (key && typeof value !== 'undefined') {
					var self = Instance.getSelf();
					self.userParams[key] = value;
					Instance.setSelf(self);
				}
			});

			$rootScope.$on('defaultSignature.changed', function (e, signatureId) {
				var self = Instance.getSelf();
				self.userParams.defaultSignature = signatureId;
				Instance.setSelf(self);
			});

			Instance.updateLocale = appService.updateLocale;
			Instance.getCustomerId = appService.getCustomerId;

			Instance.setCustomerId = function (id) {
				appService.setCustomerId(id);

				if (id) {
					Sentry.configureScope(function (scope) {
						var mergedUserObject = _.assign({}, scope._user, { clientId: id });
						scope.setUser(mergedUserObject);
					});
				}
			};

			Instance.setMetadata = appService.setMetadata;
			Instance.getMetadata = appService.getMetadata;
			Instance.setSelf = appService.setSelf;
			Instance.getSelf = appService.getSelf;
			Instance.setAccessRights = appService.setAccessRights;
			Instance.getAccessRights = appService.getAccessRights;
			Instance.setAccountSelf = appService.setAccountSelf;
			Instance.getAccountSelf = appService.getAccountSelf;
			Instance.setScripts = appService.setScripts;
			Instance.getScripts = appService.getScripts;
			Instance.setJourneySteps = appService.setJourneySteps;
			Instance.getJourneySteps = appService.getJourneySteps;
			Instance.setAdAccount = appService.setAdAccount;
			Instance.getAdAccount = appService.getAdAccount;
			Instance.getCustomFields = appService.getCustomFields;
			Instance.getReadOnlyCustomFields = appService.getReadOnlyCustomFields;
			Instance.setCustomFields = appService.setCustomFields;
			// Used to set all listViews
			Instance.setAllListViews = appService.setAllListViews;

			Instance.purgeListViews = function (type, view) {
				if (type !== undefined) {
					const ListViews = appService.getListViews();
					var views = _.filter(ListViews[type], function (item) {
						return item.id !== view.id;
					});

					ListViews[type] = views;
					appService.setAllListViews(ListViews);

					$rootScope.$broadcast('ListViewIsPurged');
				}
			};

			Instance.purgeReportViews = function (type, view) {
				if (type !== undefined) {
					const ReportViews = appService.getReportViews();
					var views = _.filter(ReportViews[type], function (item) {
						return item.id !== view.id;
					});

					ReportViews[type] = views;
					appService.setAllReportViews(ReportViews);
				}
			};

			Instance.setListView = function (type, view, otherUpdates) {
				const ListViews = appService.getListViews();
				var updates = [];
				if (otherUpdates && Array.isArray(otherUpdates)) {
					updates = otherUpdates;
				}
				if (type !== undefined) {
					var push = true;
					ListViews[type] = ListViews[type].map(function (item) {
						if (item.id === view.id) {
							push = false;
							return view;
						}

						updates.forEach(function (update) {
							_.assign(item, update);
						});

						return item;
					});

					if (push) {
						ListViews[type].push(view);
					}
					appService.setAllListViews(ListViews);

					$rootScope.$broadcast('ListViewIsUpdated');
				}
			};

			Instance.setListViews = function (listType, arrayOfViews) {
				if (listType !== undefined) {
					const ListViews = appService.getListViews();
					ListViews[listType] = arrayOfViews;
					appService.setAllListViews(ListViews);
				}
			};

			Instance.setReportViews = function (type, view, otherUpdates) {
				const ReportViews = appService.getReportViews();
				var updates = [];
				if (otherUpdates && Array.isArray(otherUpdates)) {
					updates = otherUpdates;
				}

				if (type !== undefined) {
					var push = true;
					ReportViews[type] = ReportViews[type].map(function (item) {
						if (item.id === view.id) {
							push = false;
							return view;
						}

						updates.forEach(function (update) {
							_.assign(item, update);
						});

						return item;
					});

					if (push) {
						ReportViews[type].push(view);
					}

					AllIWantDataCache.clearData();
					appService.setAllReportViews(ReportViews);
					$rootScope.$broadcast('ReportViewIsUpdated');
				}
			};

			// Get all views for a type
			Instance.getListViews = appService.getListViews;
			Instance.setAllReportViews = appService.setAllReportViews;
			Instance.getReportViews = appService.getReportViews;
			Instance.setProducts = appService.setProducts;
			Instance.getProducts = appService.getProducts;
			Instance.setProductCategories = appService.setProductCategories;
			Instance.getProductCategories = appService.getProductCategories;
			Instance.setCategoryTypes = appService.setCategoryTypes;
			Instance.getCategoryTypes = appService.getCategoryTypes;
			Instance.setCategories = appService.setCategories;
			Instance.getCategories = appService.getCategories;
			Instance.setBrands = appService.setBrands;
			Instance.getBrands = appService.getBrands;
			Instance.setTodoTypes = appService.setTodoTypes;
			Instance.getTodoTypes = appService.getTodoTypes;
			Instance.getSelectedBrand = appService.getSelectedBrand;
			Instance.setStages = appService.setStages;
			Instance.getStages = appService.getStages;
			Instance.setStaticValues = appService.setStaticValues;
			Instance.setStaticValuesPromise = appService.setStaticValuesPromise;
			Instance.getStaticValuesPromise = appService.getStaticValuesPromise;
			Instance.getStaticValues = appService.getStaticValues;
			Instance.getStaticValue = appService.getStaticValue;
			Instance.setActivityTypes = appService.setActivityTypes;
			Instance.getActivityTypes = appService.getActivityTypes;
			Instance.setUserMap = appService.setUserMap;
			Instance.getUserMap = appService.getUserMap;
			Instance.getActiveUsers = appService.getActiveUsers;
			Instance.getRealActiveUsers = appService.getRealActiveUsers;
			Instance.getRealActiveUsersCount = () => appService.getRealActiveUsers().length;
			Instance.getUsers = appService.getUsers;
			Instance.setRoleMap = appService.setRoleMap;
			Instance.getRoleMap = appService.getRoleMap;
			Instance.getRoles = appService.getRoles;
			Instance.setTotals = appService.setTotals;
			Instance.getTotals = appService.getTotals;
			Instance.setDocumentTemplates = appService.setDocumentTemplates;
			Instance.getDocumentTemplates = appService.getDocumentTemplates;
			Instance.setProfileImageId = appService.setProfileImageId;
			Instance.getProfileImageId = appService.getProfileImageId;
			Instance.setIsLoggedIn = appService.setIsLoggedIn;
			Instance.isLoggedIn = appService.isLoggedIn;
			Instance.updateSession = appService.updateSession;
			Instance.setPhoneIntegration = appService.setPhoneIntegration;
			Instance.getPhoneIntegration = appService.getPhoneIntegration;
			Instance.setMailIntegration = appService.setMailIntegration;
			Instance.getMailIntegration = appService.getMailIntegration;
			Instance.setEsignIntegrations = appService.setEsignIntegrations;
			Instance.getEsignIntegrations = appService.getEsignIntegrations;
			Instance.setSMSIntegrations = appService.setSMSIntegrations;
			Instance.getSMSIntegrations = appService.getSMSIntegrations;
			Instance.setWebinarIntegrations = appService.setWebinarIntegrations;
			Instance.getWebinarIntegrations = appService.getWebinarIntegrations;
			Instance.setFileStorageIntegrations = appService.setFileStorageIntegrations;
			Instance.getFileStorageIntegrations = appService.getFileStorageIntegrations;
			Instance.setCalendarIntegrations = appService.setCalendarIntegrations;
			Instance.getCalendarIntegrations = appService.getCalendarIntegrations;
			Instance.setActiveCalendarIntegrations = appService.setActiveCalendarIntegrations;
			Instance.getActiveCalendarIntegrations = appService.getActiveCalendarIntegrations;
			Instance.setTopCountries = appService.setTopCountries;
			Instance.getTopCountries = appService.getTopCountries;
			Instance.setAcceptTerms = appService.setAcceptTerms;
			Instance.getAcceptTerms = appService.getAcceptTerms;
			Instance.setPriceLists = appService.setPriceLists;
			Instance.getPriceLists = appService.getPriceLists;
			Instance.setPaymentExtensions = appService.setPaymentExtensions;
			Instance.getPaymentExtensions = appService.getPaymentExtensions;

			Instance.reset = function () {
				appService.reset();
				var oldDefer = Instance.$loadedDefer;

				appLoadedDefer = $q.defer();
				Instance.$loadedDefer = appLoadedDefer;
				Instance.loadedPromise = appLoadedDefer.promise;

				appLoadedDefer.promise
					.then(function () {
						if (oldDefer && oldDefer.resolve) {
							oldDefer.resolve();
						}
					})
					.catch(err => {
						if (oldDefer && oldDefer.reject) {
							oldDefer.reject(err);
						} else {
							console.error('Error while loading app defer:', err);
						}
					});
			};

			// Set loaded variable to true when promise is resolved
			Instance.setLoaded = function () {
				appLoadedDefer.resolve();
				Instance.loaded = true;
				appService.setLoaded();
			};

			return Instance;
		}
	])
	.directive('upAppReady', function () {
		return {
			restrict: 'A',
			scope: {
				upAppReady: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					AppService.loadedPromise
						.then(function () {
							$scope.upAppReady({});
						})
						.catch(err => console.error('Error while loading app service:', err));
				}
			]
		};
	});

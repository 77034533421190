import { Feature, useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import { LazyExoticComponent, lazy } from 'react';
import portedAdminRoutes from './portedAdminRoutes';
const CustomerSupport = lazy(() => import('./subPages/CustomerSupport/CustomerSupport'));
const AdminTicketRules = lazy(() => import('./subPages/CustomerSupport/AdminTicketRules'));
const AdminEditTicketRule = lazy(() => import('./subPages/CustomerSupport/AdminEditTicketRule'));
const AdminTicketFields = lazy(() => import('./subPages/CustomerSupport/AdminTicketFields'));
const ProjectPlanTemplate = lazy(() => import('Components/Admin/ProjectPlanTemplate'));
const AdminTicketResponseTemplates = lazy(() => import('./subPages/CustomerSupport/AdminTicketResponseTemplates'));

const AllAdminRoutes = (): {
	path: string;
	Component: LazyExoticComponent<() => JSX.Element | null>;
	oldRouteState: string;
	hasAccess?: boolean;
	exact?: boolean;
}[] => {
	const hasSupportAccess = useFeatureAvailable(Feature.CUSTOMER_SUPPORT);
	const hasProjectPlan = useFeatureAvailable(Feature.PROJECT_PLAN);
	const hasTicketRules = useSoftDeployAccess('TICKET_RULES');
	const hasTicketResponseTemplates = useSoftDeployAccess('TICKET_RESPONSE_TEMPLATES');
	const { routes: portedRoutes } = portedAdminRoutes();

	const routes = [
		{
			path: '/admin/customer-support/rules',
			Component: AdminTicketRules,
			oldRouteState: 'ticketRules',
			hasAccess: hasSupportAccess && hasTicketRules,
			exact: true
		},
		{
			path: '/admin/customer-support/rules/:id',
			Component: AdminEditTicketRule,
			oldRouteState: 'editTicketRule',
			hasAccess: hasSupportAccess && hasTicketRules
		},
		{
			path: '/admin/customer-support/verify',
			Component: CustomerSupport,
			oldRouteState: 'customerSupport',
			hasAccess: hasSupportAccess
		},
		{
			path: '/admin/projectplan',
			Component: ProjectPlanTemplate,
			oldRouteState: 'projectplan',
			hasAccess: hasProjectPlan
		},
		{
			path: '/admin/customer-support/fields',
			Component: AdminTicketFields,
			oldRouteState: 'ticketFields',
			hasAccess: hasSupportAccess
		},
		{
			path: '/admin/customer-support/responsetemplates',
			Component: AdminTicketResponseTemplates,
			oldRouteState: 'ticketResponseTemplates',
			hasAccess: hasSupportAccess && hasTicketResponseTemplates
		}
	].filter(route => route.hasAccess);

	return [...routes, ...portedRoutes];
};
export default AllAdminRoutes;

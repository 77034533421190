import React from 'react';
import Timepicker from 'App/upsales/common/components/react/inputs/timepicker';

ReactTemplates.upFilters.filterTypes.time = window.ReactCreateClass({
	getDefaultProps: function () {
		return {};
	},
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	UNSAFE_componentWillMount: function () {
		this.lang = {
			from: Tools.$translate('default.from'),
			to: Tools.$translate('default.to')
		};
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.props.onChange(this.props.filter, {
			action: 'remove'
		});

		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var valueString = '';
		var filter = this.props.filter.value;

		if (filter) {
			const start = filter.start ? moment(filter.start, 'LT').format('LT') : '';
			const end = filter.end ? moment(filter.end, 'LT').format('LT') : '';

			if (start && end) {
				valueString = start + ' - ' + end;
			} else if (start) {
				valueString = start + ' - *';
			} else if (end) {
				valueString = '* - ' + end;
			}
		}

		return valueString;
	},
	onChangeTo: function (date) {
		this.onChange('end', date);
	},
	onChangeFrom: function (date) {
		this.onChange('start', date);
	},
	onChange: function (key, date) {
		var filter = this.props.filter.value ? this.props.filter.value : { start: null, end: null };
		var time = moment(date);

		if (time.isValid()) {
			filter[key] = time.format('HH:mm');
		} else {
			filter[key] = null;
		}

		this.props.onChange(
			{
				filterName: this.props.filterName,
				value: filter,
				type: 'time'
			},
			{
				action: 'add'
			}
		);
	},
	render: function () {
		var filter = this.props.filter;
		var active = this.props.filter.value ? true : false;

		var title = filter.title ? Tools.$translate(filter.title) : this.props.filterName;

		var fromValue = this.props.filter.value ? this.props.filter.value.start : null;
		var fromTimepicker = (
			<Timepicker
				onChange={this.onChangeFrom}
				className={'form-control time-input'}
				value={fromValue}
				placeholder={this.lang.from}
				invalidTimeValue={null}
				useNewTime
			/>
		);

		var toValue = this.props.filter.value ? this.props.filter.value.end : null;
		var toTimepicker = (
			<Timepicker
				onChange={this.onChangeTo}
				className={'form-control time-input'}
				value={toValue}
				placeholder={this.lang.to}
				invalidTimeValue={null}
				useNewTime
			/>
		);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'drilldown time-drilldown'}>
						{fromTimepicker}
						{toTimepicker}
					</div>
				)}
			</div>
		);
	}
});

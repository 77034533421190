import React, { useEffect, useRef, useState } from 'react';
import './NavbarSubMenuDropdown.scss';
import { OutsideClick } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

interface NavbarSubMenuDropdownProps {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	children: React.ReactNode;
	align?: 'left' | 'right';
	insideRefs?: (null | HTMLDivElement)[];
}

// TODO: This could get refactored to be used as brand DD and AppSidebar dd aswell
const NavbarSubMenuDropdown: React.FC<NavbarSubMenuDropdownProps> = ({
	isOpen,
	onOpenChange,
	children,
	align = 'left',
	insideRefs = []
}) => {
	const classes = new BemClass('NavbarSubMenuDropdown').mod({ open: isOpen, [align]: true });
	const dropdownRef = useRef<HTMLDivElement & { wrapperRef: HTMLDivElement }>(null);
	const [didOpenTime, setDidOpenTime] = useState<number | null>(null);

	useEffect(() => {
		if (isOpen) {
			setDidOpenTime(Date.now());
		} else {
			setDidOpenTime(null);
		}
	}, [isOpen]);

	return (
		<OutsideClick
			className={classes.b()}
			targetRef={() => [dropdownRef.current?.wrapperRef ?? null, ...insideRefs]}
			outsideClick={() => {
				if (typeof didOpenTime === 'number' && Date.now() - didOpenTime > 10) {
					onOpenChange(false);
				}
			}}
			listen={!!didOpenTime}
			ref={dropdownRef}
		>
			{children}
		</OutsideClick>
	);
};

export default NavbarSubMenuDropdown;

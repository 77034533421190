'use strict';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('EditAutomation', [
	'$scope',
	'$state',
	'$stateParams',
	'$upModal',
	'$translate',
	'AutomationTerms',
	'AppService',
	'RequestBuilder',
	'LeadSource',
	'Campaign',
	'CountryCodes',
	'$q',
	'CampaignAttributes',
	'TriggerHelper',
	'$safeApply',
	function (
		$scope,
		$state,
		$stateParams,
		$upModal,
		$translate,
		AutomationTerms,
		AppService,
		RequestBuilder,
		LeadSource,
		Campaign,
		CountryCodes,
		$q,
		CampaignAttributes,
		TriggerHelper,
		$safeApply
	) {
		var AdminCtrl = $scope.AdminCtrl;

		var customerId, initialHash;
		var selectOptions = {};
		var availableCriteriasFlat = [];

		var rootData = (this.rootData = AdminCtrl.getRootObject());
		rootData.pageComponent = 'editAutomation';
		rootData.pageLoading = true;
		rootData.saving = false;

		var pageData = (rootData.pageData = {
			automation: {},
			criterias: {
				or: [],
				and: []
			},
			availableCriterias: []
		});
		var criteriaTypeOptsMap = {
			'automation-clients': {
				type: $translate.instant('trigger.field.client'),
				custom: $translate.instant('default.otherAccountFields'),
				entity: 'Client',
				field: 'accountCustomFields',
				availableActions: [
					{
						alias: 'CreateActivity',
						action: 'CreateActivity',
						title: 'automationAction.CreateActivity',
						multiple: false,
						featureAvailable: true,
						available: () =>
							!(
								Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
								Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
							)
					},
					{
						alias: 'CreatePhonecall',
						drawerName: 'PlanPhonecallsTriggerAction',
						action: 'CreatePhonecall',
						title: 'automationAction.CreatePhonecall',
						multiple: false,
						featureAvailable: true,
						available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					},
					{
						alias: 'CreateTodo',
						drawerName: 'CreateTodoTriggerAction',
						action: 'CreateTodo',
						title: 'automationAction.CreateTodo',
						multiple: false,
						featureAvailable: true,
						available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					},
					{
						alias: 'AssignToUser',
						action: 'AssignToUser',
						title: 'automationAction.AssignToUser',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'UpdateClientContacts',
						action: 'UpdateClientContacts',
						title: 'automationAction.UpdateClientContacts',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'UpdateClient',
						action: 'UpdateClient',
						title: 'automationAction.UpdateClient',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'SendMail',
						action: 'SendEmail',
						title: 'automationAction.SendEmail',
						multiple: false,
						featureAvailable: true,
						modalName: 'SendEmailAction'
					},
					{
						alias: 'SendWebhook',
						action: 'SendWebRequest',
						title: 'automationAction.SendWebhook',
						multiple: false,
						featureAvailable: true,
						modalName: 'SendWebhookAction'
					}
				]
			},
			'automation-contacts': {
				type: $translate.instant('trigger.field.contact'),
				custom: $translate.instant('default.otherContactFields'),
				entity: 'Contact',
				field: 'contactCustomFields',
				availableActions: [
					{
						alias: 'CreateActivity',
						action: 'CreateActivity',
						title: 'automationAction.CreateActivity',
						multiple: false,
						featureAvailable: true,
						available: () =>
							!(
								Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
								Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
							)
					},
					{
						alias: 'CreatePhonecall',
						drawerName: 'PlanPhonecallsTriggerAction',
						action: 'CreatePhonecall',
						title: 'automationAction.CreatePhonecall',
						multiple: false,
						featureAvailable: true,
						available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					},
					{
						alias: 'CreateTodo',
						drawerName: 'CreateTodoTriggerAction',
						action: 'CreateTodo',
						title: 'automationAction.CreateTodo',
						multiple: false,
						featureAvailable: true,
						available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					},
					{
						alias: 'UpdateContact',
						action: 'UpdateContact',
						title: 'automationAction.UpdateContact',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'UpdateContactClient',
						action: 'UpdateContactClient',
						title: 'automationAction.ContactUpdateClient',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'SendMail',
						action: 'SendEmail',
						title: 'automationAction.SendEmail',
						multiple: false,
						featureAvailable: true,
						modalName: 'SendEmailAction'
					},
					{
						alias: 'SendWebhook',
						action: 'SendWebRequest',
						title: 'automationAction.SendWebhook',
						multiple: false,
						featureAvailable: true,
						modalName: 'SendWebhookAction'
					},
					{
						alias: 'AnonymizeContact',
						action: 'AnonymizeContact',
						title: 'automationAction.AnonymizeContact',
						multiple: false,
						featureAvailable: true
					},
					{
						alias: 'AddContactToSegment',
						action: 'AddContactToSegment',
						title: 'automationAction.AddContactToSegment',
						multiple: false,
						featureAvailable: true
					}
				]
			}
		};

		var goBack = function () {
			$state.go('administration.automations', { customerId: customerId });
		};

		var getCurrentHash = function () {
			return Tools.LZString.compressToBase64(
				JSON.stringify(pageData.automation) + JSON.stringify(pageData.criterias)
			);
		};

		var showSaveIfChanged = function () {
			var hash = getCurrentHash();

			var hasEmptyCriteriaOr = _.some(pageData.criterias.or, function (criteria) {
				return criteria.value === '' || criteria.value === null || criteria.value === undefined;
			});
			var hasEmptyCriteriaAnd = _.some(pageData.criterias.and, function (criteria) {
				return criteria.value === '' || criteria.value === null || criteria.value === undefined;
			});

			var name = pageData.automation.name;

			var hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
			var isSelectedBrand =
				!hasBrands ||
				pageData.automation.brandId === window.Tools.AppService.getSelectedBrand() ||
				!pageData.automation.id;

			rootData.saveVisible =
				hash !== initialHash && name && !hasEmptyCriteriaOr && !hasEmptyCriteriaAnd && isSelectedBrand;

			$safeApply($scope);
		};

		var editAction = function (action, actionIndex) {
			const modal = action.modalName || action.action + 'Action';

			if (action.drawerName) {
				return openDrawer(action.drawerName, {
					onSave: properties => {
						pageData.automation.actions[actionIndex].properties = properties;
						showSaveIfChanged();
					},
					triggerEntity: 'client',
					properties: action.properties
				});
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modal === 'SendWebhookAction') {
				return new Promise((res, rej) => {
					openModal('SendWebhook', {
						tagEntity: 'client',
						properties: action.properties,
						onClose: data => {
							if (data) {
								res(data);
							} else {
								rej();
							}
						}
					});
				});
			} else {
				return $upModal.open(modal, {
					properties: action.properties || null,
					accountCategories: selectOptions.accountCategories,
					accountCustomFields: selectOptions.accountCustomFields,
					contactCategories: selectOptions.contactCategories,
					contactCustomFields: selectOptions.contactCustomFields,
					activityCustomFields: selectOptions.activityCustomFields,
					campaigns: selectOptions.campaigns,
					leadSources: selectOptions.leadSources,
					activeUsers: selectOptions.activeUsers,
					roles: selectOptions.roles,
					activityTypes: selectOptions.activityTypes,
					//appointmentCustomFields: selectOptions.appointmentCustomFields,
					//appointmentTypes: selectOptions.appointmentType,
					action: action,
					tagEntity: 'client',
					removeAction: pageData.removeAction,
					actionIndex: actionIndex,
					hideDate: true,
					isAutomation: true,
					isTrigger: true
				});
			}
		};

		pageData.editAction = function (index, action) {
			var typeActions = criteriaTypeOptsMap[pageData.automation.job].availableActions;
			var theAction = _.find(typeActions, { action: action.action });
			theAction.properties = action.properties;
			const promise = editAction(theAction, index);
			if (promise && promise.then) {
				// eslint-disable-next-line promise/catch-or-return
				promise.then(function (properties) {
					pageData.automation.actions[index].properties = properties;
					showSaveIfChanged();
				});
			}
		};

		var initCriterias = function (type) {
			availableCriteriasFlat = [];

			var typeOpts = criteriaTypeOptsMap[type];
			var allCriterias = AutomationTerms.all(type, typeOpts.entity);

			var jobTypeCriterias = _.cloneDeep(allCriterias);
			pageData.availableCriterias = [];

			var typeCriterias = {
				text: typeOpts.type,
				$id: 'Standard',
				children: []
			};

			var customCriterias = {
				text: typeOpts.custom,
				$id: 'Custom',
				children: []
			};

			customCriterias.children = _.chain(selectOptions[typeOpts.field])
				.map(TriggerHelper.formatCustomFieldCriteria.bind(this, typeOpts.entity))
				.sortBy('text')
				.value();

			jobTypeCriterias.forEach(function (criteria) {
				criteria.text = $translate.instant(criteria.title);
				availableCriteriasFlat.push(criteria);
				typeCriterias.children.push(criteria);
			});

			pageData.availableCriterias.push(typeCriterias);
			if (customCriterias.children.length) {
				pageData.availableCriterias.push(customCriterias);
			}

			var cfs = selectOptions[typeOpts.field];
			_.each(cfs, function (cf) {
				availableCriteriasFlat.push(TriggerHelper.formatCustomFieldCriteria(typeOpts.entity, cf));
			});
		};

		pageData.abort = function () {
			goBack();
		};

		pageData.save = function () {
			pageData.saving = true;

			// join criterias and remove empty ones
			pageData.automation.criterias = [pageData.criterias.and, pageData.criterias.or];
			pageData.automation.criterias = _.filter(_.flatten(pageData.automation.criterias), function (obj) {
				return obj.attribute !== null;
			});

			window.Tools.Automations.save(pageData.automation)
				.then(function () {
					goBack();
				})
				.catch(function () {
					pageData.saving = false;
				});
		};

		pageData.propChange = function (key, value) {
			if (key === 'job') {
				initCriterias(value);
			}
			pageData.automation[key] = value;

			showSaveIfChanged();
			$safeApply($scope);
		};

		pageData.criteriaChange = function (criteria) {
			if (criteria.attribute && typeof criteria.attribute == 'object') {
				// fix
				criteria.$config = criteria.attribute;
				criteria.attribute = criteria.attribute.id;

				if (criteria.$config.type === 'integer') {
					criteria.value = parseInt(criteria.value);
				}

				// set operator
				criteria.$operatorSelect = {
					placeholder:
						$translate.instant('default.select') + ' ' + $translate.instant('admin.operator').toLowerCase(),
					width: '100%'
				};

				// select first
				if (!criteria.operator) {
					criteria.operator = criteria.$config.operators[0];
				}

				// set select options
				if (criteria.$config.type === 'select') {
					// value select config
					criteria.$valueSelect = {};

					// if customfield
					if (criteria.$config.customfield) {
						criteria.$valueOptions = criteria.$config.selectOptions;
					} else {
						criteria.$valueOptions = selectOptions[criteria.$config.selectOptions];
					}

					// select first option if value is undefined
					if (criteria.value === undefined) {
						criteria.value = criteria.$valueOptions[0].id;
					}
				}

				// if criteria is dateRange
				else if (criteria.$config.type === 'dateRange' && criteria.value === undefined) {
					criteria.value = { start: new Date(), end: new Date() };
				}

				// if criteria is date
				else if (criteria.$config.type === 'date' && criteria.value === undefined) {
					criteria.value = new Date();
				}

				// string
				else if (criteria.value === undefined) {
					criteria.value = '';
				}
			}
		};

		pageData.removeAction = function (index) {
			pageData.automation.actions.splice(index, 1);
			showSaveIfChanged();
		};

		pageData.addAction = function () {
			var typeActions = criteriaTypeOptsMap[pageData.automation.job].availableActions;
			var actions = _.filter(typeActions, function (a) {
				var selected = _.find(pageData.automation.actions, { action: a.action });
				if ((!selected || a.multiple) && !(a.available && !a.available())) {
					return a;
				}
			}).map(function (a) {
				var obj = {
					title: $translate.instant(a.title),
					action: a.action,
					modalName: a.modalName,
					drawerName: a.drawerName
				};
				if (!a.featureAvailable) {
					obj.$$disabled = true;
					obj.title = '<span class="text-grey">' + $translate.instant(a.title) + '</span>';
					obj.missingFeature =
						'<span class="text-grey text-xs">' +
						$translate.instant('default.versionMissingFeature') +
						'</span>';
				}

				return obj;
			});

			if (actions.length) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('list', {
						title: 'admin.addAction',
						hideHeader: true,
						columns: [
							{ title: 'default.name', value: 'title' },
							{ title: '', value: 'missingFeature' }
						],
						data: actions
					})
					.then(function (action) {
						var actionIndex = pageData.automation.actions.length - 1;
						if (action.drawerName) {
							openDrawer(action.drawerName, {
								onSave: properties => {
									pageData.automation.actions.push({
										action: action.action,
										properties
									});
									showSaveIfChanged();
								},
								triggerEntity: 'client'
							});
						} else {
							// eslint-disable-next-line promise/catch-or-return
							editAction(action, actionIndex).then(function (properties) {
								pageData.automation.actions.push({
									action: action.action,
									properties: properties
								});
								showSaveIfChanged();
							});
						}
					});
			} else {
				$upModal.open('warningAlert', {
					title: $translate.instant('noResult.action'),
					body: $translate.instant('noResult.action.description'),
					icon: 'fa-warning'
				});
			}
		};

		pageData.addCriteria = function (type) {
			var selected = pageData.availableCriterias[0].children[0];

			pageData.criterias[type].push({
				uuid: Date.now(),
				attribute: selected.id,
				operator: selected.operators[0],
				type: type,
				orGroup: type === 'or' ? 1 : 0
			});

			showSaveIfChanged();
		};

		pageData.removeCriteria = function (type, index) {
			var array = type === 'and' ? pageData.criterias.and : pageData.criterias.or;

			array.splice(index, 1);

			showSaveIfChanged();
		};

		rootData.onSave = function () {
			rootData.saving = true;
			var automation = _.cloneDeep(pageData.automation);

			// Merge criterias
			automation.criterias = _.flatten([pageData.criterias.and, pageData.criterias.or]);
			automation.criterias = _.filter(automation.criterias, function (obj) {
				return obj.attribute != null;
			});

			window.Tools.Automations.save(automation)
				.then(function () {
					initialHash = getCurrentHash();
					rootData.saveVisible = false;
					rootData.saving = false;
					goBack();
				})
				.catch(function () {
					rootData.saving = false;
				});
		};

		var loadData = function () {
			customerId = AppService.getCustomerId();

			var campaignFilter = new RequestBuilder();
			var campaignAttr = CampaignAttributes().attr;
			campaignFilter
				.addSort(campaignAttr.name, true)
				.addFilter(campaignAttr.active, campaignFilter.comparisonTypes.Equals, 1);

			var selectableUsers = [];
			selectableUsers.push({ id: '{{General.CurrentUser}}', name: $translate.instant('default.signedInUser') });
			selectableUsers = selectableUsers.concat(AppService.getActiveUsers());

			var promises = {
				roles: $q.when({ data: AppService.getRoles() }),
				leadSources: LeadSource.find({}),
				accountCategories: $q.when({ data: AppService.getCategories('account') }),
				contactCategories: $q.when({ data: AppService.getCategories('contact') }),
				activeUsers: $q.when({ data: selectableUsers }),
				accountCustomFields: $q.when({ data: AppService.getCustomFields('account') }),
				contactCustomFields: $q.when({ data: AppService.getCustomFields('contact') }),
				activityCustomFields: $q.when({ data: AppService.getCustomFields('activity') }),
				campaigns: Campaign.customer(customerId).find(campaignFilter.build()),
				countries: CountryCodes.customer(customerId).find({}),
				activityTypes: $q.when({ data: AppService.getActivityTypes('activity', true) }),
				sniCode: AppService.getStaticValuesPromise('sniCode').then(function (values) {
					return { data: values };
				}),
				sicCode: AppService.getStaticValuesPromise('sicCode').then(function (values) {
					return { data: values };
				}),
				naicsCode: AppService.getStaticValuesPromise('naicsCode').then(function (values) {
					return { data: values };
				}),
				ukSicCode: AppService.getStaticValuesPromise('ukSicCode').then(function (values) {
					return { data: values };
				}),
				naceCode: AppService.getStaticValuesPromise('naceCode').then(function (values) {
					return { data: values };
				}),
				companyForm: AppService.getStaticValuesPromise('companyForm').then(function (values) {
					return { data: values };
				}),
				creditRating: AppService.getStaticValuesPromise('creditRating').then(function (values) {
					return { data: values };
				}),
				status: AppService.getStaticValuesPromise('status').then(function (values) {
					return { data: values };
				})
			};

			if ($stateParams.id) {
				promises.automation = window.Tools.Automations.get($stateParams.id);
			} else {
				promises.automation = $q.when({ data: Tools.Automations.new() });
			}

			return $q.all(promises);
		};

		pageData.getSelectedCriteriaObject = function (attr) {
			return _.find(availableCriteriasFlat, { id: attr });
		};

		pageData.getCriteriaUnfiltered = function (attr) {
			var allCriterias = TriggerHelper.getEntityCriteriasUnfiltered(attr.split('.')[0].toLowerCase());
			var criteria = _.find(allCriterias, { id: attr });
			if (criteria) {
				criteria.text = $translate.instant(criteria.title);
			}

			return criteria;
		};

		pageData.onCriteriaChange = function (type, index, criteria) {
			var oldCriteria = pageData.criterias[type][index];

			// If attribute changed, select first operator if current is not available anymore
			if (oldCriteria.attribute !== criteria.attribute) {
				var availableOperators = pageData.getSelectedCriteriaObject(criteria.attribute).operators;

				if (!criteria.operator || availableOperators?.indexOf(criteria.operator) === -1) {
					criteria.operator = availableOperators[0];
				}

				criteria.value = null;
			}

			pageData.criterias[type].splice(index, 1, criteria);

			showSaveIfChanged();
			$safeApply($scope);
		};

		var init = function (res) {
			pageData.automation = res.automation.data;
			pageData.criterias = {
				and: _.filter(pageData.automation.criterias, { type: 'and' }),
				or: _.filter(pageData.automation.criterias, { type: 'or' })
			};

			// set all select options
			selectOptions.roles = res.roles.data;
			selectOptions.leadSources = res.leadSources.data;
			selectOptions.accountCategories = res.accountCategories.data;
			selectOptions.contactCategories = res.contactCategories.data;
			selectOptions.activeUsers = res.activeUsers.data;
			selectOptions.accountCustomFields = res.accountCustomFields.data;
			selectOptions.contactCustomFields = res.contactCustomFields.data;
			selectOptions.activityCustomFields = res.activityCustomFields.data;
			selectOptions.campaigns = res.campaigns.data;
			selectOptions.countries = res.countries.data;
			selectOptions.activityTypes = res.activityTypes.data;
			selectOptions.sniCode = res.sniCode.data;
			selectOptions.sicCode = res.sicCode.data;
			selectOptions.naicsCode = res.naicsCode.data;
			selectOptions.ukSicCode = res.ukSicCode.data;
			selectOptions.naceCode = res.naceCode.data;
			selectOptions.status = res.status.data;
			selectOptions.creditRating = res.creditRating.data;
			selectOptions.companyForm = res.companyForm.data;
			selectOptions.journeySteps = Tools.AppService.getJourneySteps();

			_.each(selectOptions.countries, function (countryObj) {
				countryObj.lang = $translate.instant(countryObj.lang);
			});
			_.each(selectOptions.journeySteps, function (obj) {
				obj.lang = $translate.instant(obj.name);
			});
			selectOptions.countries = _.sortBy(selectOptions.countries, 'name');

			initCriterias(pageData.automation.job);
			rootData.pageLoading = false;
			initialHash = getCurrentHash();
			$scope.$emit('setStaticData', { selectOptions: selectOptions });
		};

		AppService.loadedPromise.then(loadData).then(init); // eslint-disable-line promise/catch-or-return
	}
]);

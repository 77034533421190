import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropDownMenu, Icon } from '@upsales/components';

import './InlineDropDownMenu.scss';

const InlineDropDownMenu = ({ icon, isLoading, color, hoverColor, children, className = '' }) => {
	const classArray = className.split(' ');
	classArray.push('InlineDropDownMenu');
	return (
		<DropDownMenu
			align="right"
			className={classArray.join(' ').trim()}
			renderTrigger={(isExpanded, setExpanded) => (
				<div
					className={`InlineDropDownMenu__Button-wrapper${isExpanded ? ' InlineDropDownMenu--expanded' : ''}`}
				>
					<Button
						className="InlineDropDownMenu__Button"
						color={isExpanded ? hoverColor || color : color}
						hoverColor={hoverColor}
						type="link"
						onClick={setExpanded}
					>
						<Icon name={isLoading ? 'spinner' : icon} spin={isLoading} />
					</Button>
				</div>
			)}
		>
			{children}
		</DropDownMenu>
	);
};

InlineDropDownMenu.propTypes = {
	icon: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	color: PropTypes.string.isRequired,
	hoverColor: PropTypes.string.isRequired
};
InlineDropDownMenu.defaultProps = {
	icon: 'trash',
	isLoading: false,
	color: 'grey',
	hoverColor: 'red'
};
export default InlineDropDownMenu;

import Resource from 'Resources/Resource';
import config from '../../config';

const overrideRequestOpts = {
	headers: {
		Authorization: `OAuth ${config.STATUSPAGE.key}`
	},
	withCredentials: false
};

export type Page = {
	status: string;
};
export type Incident = {
	name: string;
	shortlink: string;
	// eslint-disable-next-line camelcase
	updated_at: string;
	// eslint-disable-next-line camelcase
	incident_updates: {
		body: string;
	}[];
};

class StatuspageResource extends Resource {
	constructor() {
		super('_');
	}

	_getUrl(path = '') {
		return `https://api.statuspage.io/v1/pages/${config.STATUSPAGE.pageId}/${path}.json`;
	}

	getPage(): Promise<Page> {
		return this._getRequest(`components/${config.STATUSPAGE.componentId}`, { ...overrideRequestOpts }).then(
			r => r.data
		);
	}

	getUnresolvedIncidents(): Promise<Incident[]> {
		return this._getRequest('incidents/unresolved', { ...overrideRequestOpts }).then(r => r.data);
	}

	getScheduledIncidents(): Promise<Incident[]> {
		return this._getRequest('incidents/scheduled', { ...overrideRequestOpts }).then(r => r.data);
	}
}

const resource = new StatuspageResource();

export default resource;

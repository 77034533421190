'use strict';

angular.module('upDirectives').directive('upEventMarketingCustom', [
	'StandardIntegration',
	'AppService',
	function (StandardIntegration, AppService) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/common/directives/event/upEventMarketingCustom.tpl.html?file'),
			require: 'upEventMarketingCustom',
			scope: {
				event: '=upEventMarketingCustom'
			},
			controller: [
				'$scope',
				function ($scope) {
					$scope.customerId = Tools.AppService.getCustomerId();
					$scope.uiElements = [];
					var metadata = AppService.getMetadata();
					$scope.expand = function () {
						$scope.expandable = $scope.event.marketingCustom.integrationId;
						if ($scope.event.$$expand) {
							$scope.event.$$expand = false;
							return;
						}
						var integration = _.find(metadata.integrations.active, {
							id: $scope.event.marketingCustom.integrationId
						});
						$scope.event.$$expand = true;

						if ($scope.expandable && !integration) {
							$scope.integrationInactive = true;
						}
						$scope.uiElements = [
							{
								integrationId: integration.id,
								integrationName: integration.name,
								type: 'widget',
								name: 'marketingCustom',
								externalId: $scope.event.marketingCustom.externalId
							}
						];
					};
				}
			]
		};
	}
]);

'use strict';

angular
	.module('upDirectives')
	.directive('upInsertSign', [
		'$translate',
		'Self',
		function ($translate, Self) {
			return {
				restrict: 'A',
				require: '?ngModel',
				link: function (scope, $textarea, attributes, ctrl) {
					var textarea = $textarea[0]; // jQuery is unnecessary
					window.ctrl = ctrl;

					angular
						.element('<a href="" >')
						.text('(' + $translate.instant('default.insertSign').toLowerCase() + ')')
						.insertBefore(textarea)
						.on('click', function (evt) {
							evt.preventDefault();
							evt.stopPropagation();
							// this one is cached so get it everytime
							Self.getSelf().then(function (user) {
								var dateTimeStr = moment().format('L LT');
								var signature = user.name + ' ' + dateTimeStr + ':\n';
								var pos = signature.length;

								ctrl.$setViewValue(signature + '\n\n' + (ctrl.$modelValue || ''));
								ctrl.$render();

								textarea.focus();
								textarea.setSelectionRange(pos, pos);
							});
						});
				}
			};
		}
	])
	.directive('upInsertSignAt', [
		'$translate',
		'Self',
		function ($translate, Self) {
			return {
				restrict: 'A',
				require: '?ngModel',
				link: function (scope, $element, attributes, ngModel) {
					$element.on('click', function (evt) {
						var textarea = angular.element(attributes.upInsertSignAt);

						evt.preventDefault();
						evt.stopPropagation();

						if (textarea.length) {
							// this one is cached so get it everytime
							Self.getSelf().then(function (user) {
								var dateTimeStr = moment().format('L LT');
								var signature = user.name + ' ' + dateTimeStr + ':\n';
								var pos = signature.length;

								ngModel.$setViewValue(signature + '\n\n' + (ngModel.$modelValue || ''));
								ngModel.$render();

								textarea[0].focus();
								textarea[0].scrollTop = 0;

								setTimeout(function () {
									textarea[0].setSelectionRange(pos, pos);
								});
							});
						}
					});
				}
			};
		}
	]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import { changeView, views, closeActivity, setNotes } from 'Store/reducers/AssignModalReducer';
import './AssignModalCloseActivity.scss';
import { Title, Label, Textarea, Button } from '@upsales/components';

const mapStateToProps = state => ({
	notes: state.AssignModal.notes
});

const mapDispatchToProps = {
	changeView,
	closeActivity,
	setNotes
};

class AssignModalCloseActivity extends React.Component {
	constructor(props) {
		super(props);
		const t = window.Tools.$translate;

		this.lang = {
			closeActivity: t('default.closeActivity'),
			addNotes: t('default.addNotes'),
			title: t('processedBy.closeActivity'),
			notes: t('default.notes'),
			note: t('tag.account.notes'),
			regret: t('default.regret'),
			placeholder: t('processedBy.closeActivity.description')
		};
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	render() {
		const { changeView, notes, setNotes } = this.props;
		const { placeholder, note, title, closeActivity, regret } = this.lang;

		const classNames = new BemClass('AssignModalCloseActivity');

		return (
			<div className={classNames}>
				<Title center>{title}</Title>

				<Label className={classNames.elem('label')} htmlFor="close-activity-textarea">
					{note}
				</Label>

				<Textarea
					rows="5"
					id="close-activity-textarea"
					placeholder={placeholder + '...'}
					defaultValue={notes}
					onChange={e => setNotes(e.target.value)}
				/>

				<Button
					block
					data-test-id="processedby-modal-close-activity"
					className={classNames.elem('submit').b()}
					gradient
					onClick={() => this.props.closeActivity()}
				>
					{closeActivity}
				</Button>

				<Button block type="link" color="grey" onClick={() => changeView(views.ACTIVITIES)}>
					{regret}
				</Button>
			</div>
		);
	}
}

AssignModalCloseActivity.propTypes = {
	changeView: PropTypes.func,
	closeActivity: PropTypes.func,
	setNotes: PropTypes.func,
	reloadModalPosition: PropTypes.func,
	notes: PropTypes.string
};

export const detached = AssignModalCloseActivity;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalCloseActivity);
export default Component;

import React from 'react';
import _ from 'lodash';

class AdvancedSearchSidebar extends React.Component {
	static defaultProps = {
		searchString: ''
	};

	state = {
		searchString: this.props.searchString
	};

	lang = {
		emptyFilter: Tools.$translate('advancedSearch.emptyFilter'),
		accountColumn: Tools.$translate('filters.columns.account'),
		searchFilters: Tools.$translate('default.searchFilters')
	};

	componentDidMount() {
		this.addScrollSpy();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.view !== this.props.view) {
			this.removeScrollSpy();
			this.addScrollSpy();
		}
	}

	componentWillUnmount() {
		this.removeScrollSpy();
	}

	setActive = binder => {
		$('[data-scroll-target]').removeClass('current');
		binder.addClass('current');
	};

	getActive = () => {
		var targetId = $('[data-scroll-target].current').attr('data-scroll-target');
		return $(targetId);
	};

	addScrollSpy = () => {
		var self = this;

		setTimeout(function () {
			var searchContainer = $('#search-content');
			var offset = 0;

			var binders = $('[data-scroll-target]');

			$('[data-scroll-target]').each(function () {
				var binder = $(this);
				var targetId = binder.attr('data-scroll-target');

				binder.on('click', function (event) {
					event.stopPropagation();
					event.preventDefault();
					var targetElement = $(targetId);

					if (!targetElement.length) {
						return;
					}

					var position = targetElement[0].offsetTop - offset + 4;
					searchContainer.scrollTop(position);
					self.setActive(binder);
				});
			});

			self.prevScrollTop = 0;

			searchContainer.on('scroll', function () {
				var scrollTop = searchContainer.scrollTop();
				var scrollDirection = scrollTop >= self.prevScrollTop ? 'Down' : 'Up';
				self.prevScrollTop = scrollTop;

				var visible = [];
				var nonvisible = [];

				binders.each(function () {
					var binder = $(this);
					var targetId = binder.attr('data-scroll-target');
					var targetElement = $(targetId);
					if (!targetElement.length) {
						return;
					}
					var targetOffset = targetElement[0].offsetTop;

					if (scrollDirection === 'Down') {
						if (scrollTop > targetOffset - 5) {
							nonvisible.push(binder);
						}
					} else {
						if (scrollTop <= targetOffset) {
							visible.push(binder);
						}
					}
				});

				if (scrollDirection === 'Down' && nonvisible.length) {
					self.setActive(nonvisible[nonvisible.length - 1]);
				} else if (visible.length) {
					var currentActiveIsAbove = self.getActive()[0].offsetTop <= scrollTop ? true : false;
					if (!currentActiveIsAbove) {
						self.setActive(visible[0]);
					}
				}
			});

			searchContainer.trigger('scroll');
		}, 1000);
	};

	removeScrollSpy = () => {
		$('#search-content').off('scroll');
		$('[data-scroll-target]').off('click');
	};

	onSearch = event => {
		this.setState({
			searchString: event.target.value
		});
	};

	getIcon = icon => {
		if (icon === 'up-icon-activity' || icon === 'up-icon-pipeline') {
			return (
				<span className="upsales-icons">
					<i style={{ paddingRight: '5px' }} className={icon} aria-hidden="true" />
				</span>
			);
		} else {
			return <i style={{ paddingRight: '5px' }} className={icon} aria-hidden="true" />;
		}
	};

	onResetAll = (filters, event) => {
		event.stopPropagation();
		event.preventDefault();

		filters.forEach(function (filter) {
			filter.reset();
		});

		this.props.safeApply();
	};

	onReset = (filter, event) => {
		event.stopPropagation();
		event.preventDefault();

		filter.reset();

		this.props.safeApply();
	};

	onClick = (filter, event) => {
		event.stopPropagation();
		event.preventDefault();

		filter.focus();
	};

	getOuterList = sections => {
		var self = this;
		var classes = this.state.searchString ? 'inner-list has-search' : 'inner-list';

		var sectionsWithFilters = _.filter(sections, function (section) {
			return section.filters.length > 0;
		});

		return _.map(sectionsWithFilters, function (section, index) {
			var icon = self.getIcon(section.icon);
			var filterList = self.getInnerList(section.filters);

			var nrActive = section.filters.reduce(function (sum, filter) {
				return filter.isActive() ? sum + 1 : sum;
			}, 0);

			return (
				<li key={index} className="list-section">
					<span className="section-header" data-scroll-target={section.id}>
						{icon}
						<span>{section.name}</span>
					</span>
					<ul className={classes}>{filterList}</ul>
					{nrActive > 0 ? (
						<div className="active-indicator-outer-wrap">
							{ReactTemplates.TOOLS.withTooltip(
								<div
									className="active-indicator-outer"
									onClick={self.onResetAll.bind(self, section.filters)}
								>
									<span className="text-content">{nrActive}</span>
									<i className="fa fa-times-circle" />
								</div>,
								self.lang.emptyFilter,
								{ placement: 'right', distance: 20 }
							)}
						</div>
					) : null}
				</li>
			);
		});
	};

	getInnerList = filters => {
		var self = this;

		return _.chain(filters)
			.filter(function (filter) {
				return filter.title.toLowerCase().indexOf(self.state.searchString.toLowerCase()) > -1;
			})
			.map(function (filter, index) {
				var filterName = filter.title;

				if (self.state.searchString) {
					filterName = self.highlight(filterName);
				}

				return (
					<li key={index} className="list-filter" onClick={self.onClick.bind(self, filter)}>
						<span>{filterName}</span>
						{filter.isActive() ? (
							<div className="active-indicator-wraper" onClick={self.onReset.bind(self, filter)}>
								{ReactTemplates.TOOLS.withTooltip(
									<div className="active-indicator">
										<i className="fa fa-times" />
									</div>,
									self.lang.emptyFilter,
									{ placement: 'right' }
								)}
							</div>
						) : null}
					</li>
				);
			})
			.value();
	};

	highlight = string => {
		const highlight = this.state.searchString.toLowerCase();
		const parts = string.split(new RegExp(`(${highlight})`, 'gi'));
		return parts.map((part, i) => (
			<span key={i} className={part.toLowerCase() === highlight ? 'highlighted' : ''}>
				{part}
			</span>
		));
	};

	clearSearch = () => {
		this.setState({
			searchString: ''
		});
	};

	render() {
		var currentType = this.props.config[this.props.view];
		var sectionList = this.getOuterList(currentType.sections);

		if (this.props.view !== 'ACCOUNT') {
			var index;

			for (var itr = 0; itr < currentType.sections.length; ++itr) {
				if (currentType.sections[itr].id === '#accountSearchInfo') {
					index = itr;
					break;
				}
			}

			sectionList.splice(
				index,
				0,
				<li key="account-divider" className="account-divider">
					<span>{this.lang.accountColumn}</span>
				</li>
			);
		}

		return (
			<div>
				<div className="search-wrap">
					<input
						type="text"
						className="search-input"
						placeholder={this.lang.searchFilters}
						value={this.state.searchString}
						onChange={this.onSearch}
					/>
					{this.state.searchString && (
						<span className="clear-search-button" onClick={this.clearSearch}>
							<i className="clear-search-icon fa fa-times-circle" />
						</span>
					)}
					<b className="search-icon fa fa-search" />
				</div>

				<ul className="outer-list">{sectionList}</ul>
			</div>
		);
	}
}

export default AdvancedSearchSidebar;

import React, { Component } from 'react';
import { Button } from '@upsales/components';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import AdminHeader from 'Components/Admin/AdminHeader';
import t from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import SettingsToggle from 'App/components/SettingsToggle';
type Key = { name: string; active: boolean; id: number };

type Props = {
	rootData: {
		pageData: {
			keys: Key[];
		};
		removeKey: (key: Key) => void;
		editKey: (key?: Key) => void;
	};
};

type State = {
	sort: 'name';
	descending: boolean;
	checked: boolean;
};

class ApiKeys extends Component<Props, State> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			apiKeys: t('admin.apiKeys'),
			apiKeysInfo: t('admin.apiKeysInfo'),
			name: t('default.name'),
			newApiKey: t('admin.newApiKey'),
			documentation: t('admin.apiKeys.documentation'),
			apiKeysInReportsTitle: t('admin.apiKeys.inReportsTitle'),
			apiKeysInReportsDescription: t('admin.apiKeys.inReportsDescription'),
			apiKeysInReportsToggle: t('admin.apiKeys.inReportsToggle')
		};
		this.state = {
			sort: 'name',
			descending: false,
			checked: Tools.AppService.getMetadata().params.IncludeAPIKeysInReports ?? false
		};
	}

	removeKey(key: Key, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		e.stopPropagation();
		this.props.rootData.removeKey(key);
	}

	updateSort(sort: 'name') {
		const descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	}

	getSortClass(name: 'name') {
		const sort = this.state.sort;
		const descending = this.state.descending;

		if (sort !== name) {
			return 'fa fa-sort';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	}

	handleToggle() {
		Tools.ClientParam.save(243, !this.state.checked)
			.then(() => {
				const metadata = Tools.AppService.getMetadata();
				Tools.AppService.setMetadata({
					...metadata,
					params: {
						...metadata.params,
						IncludeAPIKeysInReports: !this.state.checked
					}
				});
				this.setState({ checked: !this.state.checked });
			})
			.catch(e => {
				logError(e);
			});
	}

	render() {
		const rootData = this.props.rootData;
		const rightColumn = <img src="img/reportcenter.svg" alt="" />;

		const keys = rootData.pageData.keys.sort(sortAlphabetically('name', this.state.descending)).map((key, i) => {
			let className = '';
			if (!key.active) {
				className += ' inactive-row';
			}
			return (
				<tr key={key.id} className={className}>
					<td>{key.name}</td>
					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							<button
								className="up-btn btn-link btn-grey delete-trigger"
								onClick={e => this.removeKey(key, e)}
							>
								<b className="fa fa-trash-o"></b>
							</button>
						</div>
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-apikeys">
				<AdminHeader
					title={this.lang.apiKeys}
					description={this.lang.apiKeysInfo}
					image="api.svg"
					articleId={667}
				/>

				<div id="admin-content">
					<SettingsToggle
						toggleChecked={this.state.checked}
						onToggleChange={() => this.handleToggle()}
						toggleText={this.lang.apiKeysInReportsToggle}
						title={this.lang.apiKeysInReportsTitle}
						descriptionText={this.lang.apiKeysInReportsDescription}
						rightColumn={rightColumn}
					/>
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.apiKeys}</span>
							<div className="pull-right">
								<Button onClick={() => rootData.editKey()} size="sm">
									{this.lang.newApiKey}
								</Button>
							</div>
						</div>

						<table>
							<thead>
								<tr>
									<th
										onClick={() => this.updateSort('name')}
										className={'sortable' + (this.state.sort === 'name' ? ' active' : '')}
									>
										{this.lang.name} <i className={this.getSortClass('name')} />
									</th>
									<th style={{ width: '60px' }}></th>
								</tr>
							</thead>
							<tbody>{keys}</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default ApiKeys;

import Credentials from './Credentials';
import Config from './Config';
import StartSync from './StartSync';

export default {
	default: Credentials,
	Credentials: Credentials,
	Config: Config,
	StartSync: StartSync
};

import React from 'react';
import { TableRow, TableColumn, Link, Icon, Tooltip, Text, Block } from '@upsales/components';
import t from 'Components/Helpers/translate';
import StepTable from './StepTable';
import BemClass from '@upsales/components/Utils/bemClass';
import './StepContactTable.scss';
import Flow, { FlowStep } from 'App/resources/Model/Flow';
import Contact from 'App/resources/Model/Contact';
import FlowStepContacts from 'Resources/FlowStepContacts';

type Props = {
	flow: Flow;
	flowId: number;
	stepId: number | string;
	segmentId: number | null;
	stats: { currentlyInStep: number; fallenOutOfStep: number } | FlowStep['stats'];
	types: string[];
};

type StatsContact = Pick<Contact, 'id' | 'name' | 'client'> & {
	loopNumber: number;
};

// Move into StepTable when it's typed
type FetchFnParams = { flowId?: number; stepId?: number; segmentId?: number };
type FetchFnFilter = { type: string; searchStr: string; prevOffset: number };

export default (props: Props) => {
	const removePortal = () => {
		const portalContainer = document.getElementById('portal-container');
		if (portalContainer) {
			document.body.removeChild(portalContainer);
		}
	};
	const classes = new BemClass('StepContactTable');

	return (
		<StepTable
			{...props}
			rowRender={(c: StatsContact) => (
				<TableRow key={'contact' + c.id}>
					<TableColumn
						title={
							<>
								<Link
									href={Tools.$state.href('contact.dashboard', {
										customerId: Tools.AppService.getCustomerId(),
										id: c.id
									})}
									onClick={() => removePortal()}
								>
									{c.name}
								</Link>
								{props.flow.loop ? (
									<div className={classes.elem('loop-indicator').b()}>
										<Tooltip title={t('flow.contactLoopedTimes', { times: c.loopNumber })}>
											<div>
												<Text>{c.loopNumber}</Text>
												<Icon name="repeat" space="mls" />
											</div>
										</Tooltip>
									</div>
								) : null}
							</>
						}
						subtitle={
							<Link
								href={Tools.$state.href('account.dashboard', {
									customerId: Tools.AppService.getCustomerId(),
									id: c.client.id
								})}
								onClick={() => removePortal()}
								color="grey-10"
							>
								{c.client.name}
							</Link>
						}
					/>
				</TableRow>
			)}
			noResultText="no_contacts"
			header={[
				{
					title: () => (
						<Block>
							<Text size="sm">{t('default.contacts').toUpperCase()}</Text>
							<Tooltip position="left" distance={15} title={t('flow.contactDelayTooltip')}>
								<Icon name="info-circle" />
							</Tooltip>
						</Block>
					)
				}
			]}
			fetchFn={({ flowId, stepId, segmentId }: FetchFnParams, { type, searchStr, prevOffset }: FetchFnFilter) => {
				if (flowId && stepId && segmentId) {
					return FlowStepContacts.flow(flowId)
						.step(stepId)
						.type(type)
						.find({ searchStr, offset: prevOffset })
						.then(res => res.data)
						.catch(() => []);
				} else {
					return Promise.resolve([]);
				}
			}}
		/>
	);
};

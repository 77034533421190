'use strict';

ReactTemplates.findProspect.rangeGraphFilter = window.ReactCreateClass({
	getInitialState: function () {
		return {
			startValue: null,
			endValue: null,
			initialized: false
		};
	},
	getDefaultProps: function () {
		return {
			values: {}
		};
	},
	onChangeSlider: function (startValue, endValue) {
		var parsedValues = this.fromIndexToValue({ start: startValue, end: endValue });
		this.setState({
			startValue: startValue,
			endValue: endValue,
			initialized: true
		});

		if (this.state.initialized) {
			this.props.onChange(this.props.name, { start: parsedValues.start, end: parsedValues.end });
		}
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var shallUpdate =
			(!nextProps.values.start && this.state.startValue !== 0) ||
			(!nextProps.values.end && this.state.endValue !== nextProps.stepSize);

		if (shallUpdate) {
			this.setState({
				startValue: nextProps.values.start || 0,
				endValue: nextProps.values.end || nextProps.stepSize
			});
		}
	},
	fromIndexToValue: function (values) {
		// ranges in the object to se which value to use... or set..
		var ranges = this.props.range;
		var diff = this.props.stepSize / (ranges.length - 1);

		var valStart = values.start / diff;
		var firstDump = ranges[Math.floor(valStart)].value;
		var firstDistance = ranges[Math.ceil(valStart)].value - firstDump;
		var startValue =
			firstDistance > 0 ? firstDump + Math.round(firstDistance * (valStart - Math.floor(valStart))) : firstDump;

		var valEnd = values.end / diff;
		var secondDump = ranges[Math.floor(valEnd)].value;
		var lastDistance = ranges[Math.ceil(valEnd)].value - secondDump;
		var endValue =
			secondDump > 0 ? secondDump + Math.round(lastDistance * (valEnd - Math.floor(valEnd))) : secondDump;

		if (startValue === 0) {
			startValue = null;
		}

		if (endValue === ranges[ranges.length - 1].value) {
			endValue = null;
		}

		return { start: startValue, end: endValue };
	},
	deleteAllFilter: function (type) {
		this.props.onChange(type, { start: null, end: null });
	},
	fromValueToIndex: function (value) {
		var range = this.props.range;
		var tempIndex;

		for (var i = 0, j = range.length - 1; i < j; i++) {
			if (!tempIndex && tempIndex !== 0 && value >= range[i].value && value < range[i + 1].value) {
				tempIndex = i;
			}
		}

		var result,
			offset = this.props.stepSize / (range.length - 1);
		if (tempIndex || tempIndex === 0) {
			result =
				tempIndex * offset +
				(1 - (range[tempIndex + 1].value - value) / (range[tempIndex + 1].value - range[tempIndex].value)) *
					offset;
		} else {
			result = this.props.stepSize;
		}

		return result;
	},
	render: function () {
		var self = this;
		var graphConfig = this.props.config;
		var startValue, endValue;

		if (!graphConfig) {
			return null;
		}
		startValue = this.props.values.start ? this.fromValueToIndex(this.props.values.start) : this.state.startValue;
		endValue = this.props.values.end ? this.fromValueToIndex(this.props.values.end) : this.state.endValue;

		var graph = React.createElement(ReactTemplates.findProspect.rangeGraph, {
			config: graphConfig,
			startValue: startValue,
			name: self.props.name + '_graph',
			endValue: endValue,
			stepSize: self.props.stepSize / (graphConfig.series[0].data.length - 1)
		});

		var rangeSlider = React.createElement(ReactTemplates.INPUTS.multiRangeInput, {
			name: self.props.name + '_slider',
			startValue: startValue,
			endValue: endValue,
			max: self.props.stepSize,
			onChangeFn: self.onChangeSlider
		});

		var deleteBtn = null;
		if (startValue || endValue < self.props.stepSize) {
			deleteBtn = (
				<div className="delete-btn">
					<button
						className="up-btn btn-grey btn-link btn-hover-red"
						onClick={self.deleteAllFilter.bind(self, self.props.name)}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>
			);
		}

		return (
			<div className="range-slider">
				{deleteBtn}
				<div className="graph">{graph}</div>
				<div className="slider">{rangeSlider}</div>
			</div>
		);
	}
});

'use strict';

angular.module('upAttributes').service('OptInAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'id'
				},
				title: {
					title: 'default.title',
					field: 'title',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'title',
					limit: 45
				},
				type: {
					title: 'admin.optInType',
					field: 'type',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'type',
					limit: 10
				},
				approvedCount: {
					title: 'admin.approvedBy',
					field: 'approvedCount',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'approvedCount'
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: {},
				keys: {
					ID: 'id',
					TITLE: 'title',
					TYPE: 'type',
					APPROVEDCOUNT: 'approvedCount'
				}
			};
		};
	}
]);

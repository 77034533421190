'use strict';

angular.module('upResources').factory('BusinessEmail', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AccountProfileAttributes',
	'GenericMapper',
	'ResourceHelper',
	function AccountProfile($resource, $q, URL, API, AccountProfileAttributes, GenericMapper, ResourceHelper) {
		var AA = AccountProfileAttributes();
		var Attribute = AA.attr;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'businessEmail',
			createSuccessBody: 'saved.businessEmail',
			createErrorBody: 'saveError.businessEmail'
		});

		var baseUrl = URL + API + 'businessEmail/';
		var script = $resource(URL + API + 'master/script/be', {}, {});

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false
				},
				save: {
					method: 'POST',
					isArray: false
				}
			}
		);

		var Model = {
			new: function () {
				return [];
			},

			get: function () {
				return Resource.get({}).$promise;
			},

			getScript: function () {
				return script.get({}).$promise;
			},

			save: function (businessEmail, options) {
				var defered = $q.defer();

				if (!businessEmail) {
					return defered.reject('No businessEmail');
				}

				Resource.save(
					{ businessEmail: businessEmail },
					function (response) {
						if (response.error) {
							defered.reject(response);
						}

						defered.resolve(response);
					},
					defered.reject
				);

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !businessEmail.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !businessEmail.id);
					});

				return defered.promise;
			},
			attr: Attribute
		};

		return Model;
	}
]);

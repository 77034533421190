import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import { Text } from '@upsales/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';
import { makeIterator, editOpportunity, SIDEBAR_WIDTH } from '../shared';
import UpSelect from 'App/babel/components/Inputs/UpSelect.js';
import _ from 'lodash';
import translate from 'Components/Helpers/translate';

import './SideBar.scss';

const classes = new BEM('PeriodizationListSideBar');
const tableClasses = new BEM('PeriodizationListTable');

const SidebarRow = ({ opportunity, periodizationField }) => {
	const classes = new BEM('PeriodizationListSideBarColumn');
	const startOfDay = moment().startOf('day');
	const color = moment(opportunity.date).isBefore(startOfDay) ? 'red' : 'grey-10';

	return (
		<div onClick={() => editOpportunity(opportunity)}>
			<div className={classes.b()}>
				<div className="text-ellipsis">
					<ReactTemplates.TOOLS.accountHref id={opportunity.client.id} text={opportunity.client.name} />
				</div>
				<div className={classes.elem('bottom-row').b()}>
					<Text size="sm" className="text-ellipsis">
						{currencyFormat(opportunity[periodizationField], opportunity.currency)}
					</Text>
					<Text size="sm" color={color}>
						{moment(opportunity.date).format('YYYY-MM-DD')}
					</Text>
				</div>
			</div>
		</div>
	);
};

export default class SideBar extends React.Component {
	static propTypes = {
		startDate: PropTypes.object.isRequired,
		endDate: PropTypes.object.isRequired,
		opportunities: PropTypes.array.isRequired,
		activeYear: PropTypes.number.isRequired,
		periodizationField: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.language = {
			opportunity: translate('default.opportunity')
		};
	}

	scrollToYear(year) {
		const periodizationList = document.querySelector('.PeriodizationList');
		const yearElement = document.querySelector(`#PeriodizationList__scrollspy-${year}`);

		if (periodizationList && yearElement) {
			periodizationList.scrollTo({ left: yearElement.offsetLeft - SIDEBAR_WIDTH, behavior: 'smooth' });
		}
	}

	render() {
		const { startDate, endDate, activeYear, opportunities, periodizationField } = this.props;
		const yearIterator = makeIterator(moment(startDate).startOf('year'), endDate, 'year');
		const years = Array.from(yearIterator, momentObj => momentObj.year());
		let yearSelect = null;

		if (years.length > 10) {
			const upSelectData = years.map(year => ({ id: year, name: year }));
			const upSelectDefaultValue = _.find(upSelectData, { id: activeYear });

			yearSelect = (
				<UpSelect
					className="form-control"
					defaultValue={upSelectDefaultValue}
					data={upSelectData}
					required={true}
					onChange={event => this.scrollToYear(event.target.added.id)}
				/>
			);
		} else {
			const yearOptions = years.map(year => {
				return (
					<Text
						color={year === activeYear ? 'black' : 'grey-10'}
						size="sm"
						key={year}
						onClick={() => this.scrollToYear(year)}
					>
						{year}
					</Text>
				);
			});

			yearSelect = <div className={classes.elem('year-select').b()}>{yearOptions}</div>;
		}

		const sideBarRows = opportunities.map(opportunity => {
			return (
				<SidebarRow key={opportunity.id} opportunity={opportunity} periodizationField={periodizationField} />
			);
		});

		return (
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>{yearSelect}</div>
				<div className={tableClasses.b()}>
					<div>
						<Text size="sm">{this.language.opportunity}</Text>
					</div>
					{sideBarRows}
				</div>
			</div>
		);
	}
}

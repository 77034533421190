import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column, Link } from '@upsales/components';

import t from 'Components/Helpers/translate';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerInfoProgress from '../Components/DrawerInfoProgress';
import DrawerInfoSnooze from '../Components/DrawerInfoSnooze';
import StepContactTable from '../Components/StepContactTable';
import StepEmailSubject from '../Components/StepEmailSubject';
import StepDrawer from '../StepDrawer';

const COLOR = 'medium-turcose';

export default function FormSubmit(props) {
	const stats = props.popup.step.stats;
	const getStatsPercentage = num =>
		stats.hasCompletedStep ? Math.min(Math.floor((num / stats.hasCompletedStep) * 100), 100) : 0;
	const statsArray = [
		{
			color: COLOR,
			num: stats.convertedInTime,
			format: num => `${getStatsPercentage(num)}% ${t('flow.filledForm')}`
		},
		{
			color: null,
			num: stats.notConvertedInTime,
			format: num => `${getStatsPercentage(num)}% ${t('flow.didNotFillForm')}`
		}
	];

	return (
		<StepDrawer
			{...props}
			title={t('flow.behavior')}
			subtitle={t('event.form.submited')}
			color={COLOR}
			icon="file-document-box"
		>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<StepEmailSubject title={props.name || t('mail.mailings')} />
				<Row>
					<Column fixedWidth={90}>
						<DrawerInfoProgress total={stats.hasCompletedStep} statsArray={statsArray} icon="form" />
						{props.id ? <Link href={'#/form-overview/' + props.id}>{t('flow.showForm')}</Link> : null}
					</Column>
					<Column>
						<DrawerInfoTitle
							total={stats.hasCompletedStepUnique}
							title={'flow.contactsPassedThisStep'}
							statsArray={statsArray}
						/>
					</Column>
				</Row>
				<DrawerInfoSnooze step={props.popup.step} />
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={stats}
				types={['currentlyInStep', 'fallenOutOfStep', 'convertedInTime', 'notConvertedInTime']}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

FormSubmit.propTypes = {
	popup: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired,
	name: PropTypes.string,
	id: PropTypes.number
};

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Icon,
	Button,
	Loader,
	Text,
	Card,
	CardContent,
	Row,
	Column,
	Input,
	DropDownMenu,
	Tooltip,
	ScrollEndDetector,
	Flex
} from '@upsales/components';
import getAngularModule from '../../../../angularHelpers/getAngularModule';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import bemClass from '@upsales/components/Utils/bemClass';
import './PreviewPdf.scss';
import 'App/babel/components/DocumentTemplateEditor/DocumentTemplateEditorDesign.scss';
import 'App/babel/components/DocumentTemplateEditor/DocumentEditorToolbar.scss';
import OrderResource from 'App/resources/Order';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import { OrderRadioList } from 'Components/DocumentTemplateEditor/DocumentEditorToolbar';
import { makeCancelable } from 'Helpers/promise';
import LanguageSelect from 'App/components/Inputs/Selects/LanguageSelect';
import T from 'Components/Helpers/translate';

const mapStateToProps = state => ({
	pdf: state.pdfTemplate
});

class PreviewPdf extends React.Component {
	constructor(props) {
		super(props);
		const language = Tools.AppService.getSelf().language;
		const TranslateTo = language ? language.split('-')[0] : language;
		this.mounted = false;
		this.state = {
			url: null,
			pdfDoc: null,
			loading: true,
			previewError: false,
			language: TranslateTo,
			supportedLanguages: [],
			clientName: null,
			orderSearchString: '',
			loadingOrders: true,
			orders: [],
			orderId: this.props.pdfTemplateResource.orderId,
			type: this.props.pdfTemplateResource.type,
			selectedOrder: null,
			dropDownOrder: null,
			renderLoadingSize: 50,
			renderedCount: 50
		};
		const t = getAngularModule('$translate');
		this.lang = {
			headline: t('admin.salesProcess.create.title'),
			description: t('admin.salesProcess.create.description'),
			name: t('default.name'),
			roleAcces: t('default.permissionToRole'),
			create: t('admin.salesProcess.create'),
			placeholder: t('admin.salesProcess.create.placeholder'),
			abort: t('default.abort'),
			close: t('default.close'),
			downloadPdf: t('document.downloadPdfFile'),
			sendMail: t('mail.sendEmail'),
			generalPreviewError: t('document.previewError'),
			templateTooLarge: t('document.templateTooLarge'),
			searchOrder: t('admin.documentTemplateEditor.searchOrder'),
			chooseOrder: t('admin.documentTemplateEditor.chooseOrder'),
			cancel: t('default.cancel'),
			noResultsFor: t('default.noResultsFor'),
			searchOrderSpecifics: t('admin.documentTemplateEditor.searchOrderSpecifics'),
			preview: t('admin.documentTemplateEditor.preview'),
			previewInfo: t('admin.documentTemplateEditor.previewInfo'),
			previewTooltip: t('admin.documentTemplateEditor.previewTooltip')
		};

		this.classes = new bemClass('PreviewPdf');
		this.parentClasses = new bemClass('DocumentTemplateEditorDesign');
		this.toolBarClasses = new bemClass('DocumentEditorToolbar');
		this.hasNewCustomDocumentTemplates = Tools.FeatureHelper.hasSoftDeployAccess('NEW_CUSTOM_DOCUMENT_TEMPLATES');
		this.listContainerRef = React.createRef();
	}

	initMail = () => {
		const { uuid, templateType, contact } = this.props.pdfTemplateResource;
		const orderId = this.state.orderId;
		const mailDetails = `uuid=${uuid}&templateType=${templateType}&orderId=${orderId}&language=${
			this.state.language
		}&customerId=${Tools.AppService.getCustomerId()}`;
		const attachment = {
			filename: (this.state.clientName ?? '') + '-' + moment().format('YYYY-MM-DD HH:mm') + '.pdf',
			type: 'PdfTemplate',
			value: mailDetails
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
			const opportunityObj = orderId ? { opportunity: { id: orderId } } : undefined;
			openNewMailWithContact(contact, {
				attachments: [{ ...attachment, url: this.state.url }],
				...opportunityObj
			});
		} else {
			Tools.$upModal.open('sendEmail', {
				customerId: Tools.AppService.getCustomerId(),
				type: 'mail',
				contactId: contact ? contact.id : '',
				contact: contact || {},
				attachments: [attachment]
			});
		}
	};

	getTemplate = TranslateTo => {
		const pdfTemplateResource = this.props.pdfTemplateResource;
		const orderId = this.state.type === 'order' ? this.state.orderId : 0;
		if (this.state.type === 'order') {
			this.setState({ supportedLanguages: pdfTemplateResource.supportedLanguages });
			OrderResource.find({ id: orderId })
				.then(response => {
					if (response.data.length) {
						this.setState({ clientName: response.data[0].client.name });
					}
				})
				.catch(() => {
					this.setState({ loading: false, previewError: this.lang.generalPreviewError });
				});
		}
		const isCustom = pdfTemplateResource.templateType === 'custom';
		let serviceName;
		if (this.hasNewCustomDocumentTemplates) {
			serviceName = pdfTemplateResource.resource.getPdf(
				pdfTemplateResource.uuid,
				orderId,
				TranslateTo,
				isCustom,
				undefined,
				pdfTemplateResource.previewMeta,
				pdfTemplateResource.template
			);
		} else {
			serviceName = pdfTemplateResource.resource.getPdf(pdfTemplateResource.uuid, orderId, TranslateTo, isCustom);
		}
		this.cancelableServiceName = makeCancelable(serviceName);
		this.cancelableServiceName.promise
			.then(response => {
				const bytes = new Uint8Array(response.data.body.data);
				const blob = new Blob([bytes], { type: 'application/pdf' });
				const link = window.URL.createObjectURL(blob);
				this.setState({ url: link });
				const pdfjsLib = pdfjs;
				pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
				const url = link;

				this.cancelablePdfljsLibGetDocument = makeCancelable(pdfjsLib.getDocument(url).promise);
				this.cancelablePdfljsLibGetDocument.promise
					.then(pdfDoc_ => {
						const pdf = pdfDoc_;
						this.setState({ pdfDoc: pdf });
						this.setState({ loading: false });
					})
					.catch(() => {
						console.warn('Could not get document');
						this.setState({
							loading: false,
							previewError: this.lang.generalPreviewError
						});
					});
			})
			.catch(error => {
				// Template is too large and can not be previewd
				this.setState({
					loading: false,
					previewError:
						error?.response?.status === 413 ? this.lang.templateTooLarge : this.lang.generalPreviewError
				});
			});
	};

	fetchOrders = async () => {
		const rb = new RequestBuilder();
		rb.addSort('date');
		this.cancelableOrderResourceFind = makeCancelable(OrderResource.find(rb.build()));
		const fetchedOrders = await this.cancelableOrderResourceFind.promise;
		this.setState({ orders: fetchedOrders.data, loadingOrders: false });
	};

	loadMoreOrders = () => {
		this.timeout = setTimeout(() => {
			if (this.mounted) {
				this.setState(prevState => ({
					renderedCount: prevState.renderedCount + this.state.renderLoadingSize
				}));
			}
		});
	};

	filterOrders = query => {
		if (query === '') {
			return this.state.orders;
		} else {
			return this.state.orders.filter(
				item =>
					item.description.toLowerCase().includes(query.toLowerCase()) ||
					item.client.name.toLowerCase().includes(query.toLowerCase())
			);
		}
	};

	setOrderForPreview = order => {
		this.setState(
			{ type: 'order', orderId: order.id, loading: true, selectedOrder: order, dropDownOrder: order },
			() => {
				this.getTemplate(this.state.language);
			}
		);
	};

	renderContent = () => {
		const filteredOrders = this.filterOrders(this.state.orderSearchString);

		if (this.state.type === 'order' && !this.state.loading) {
			return (
				<object data={this.state.url + '#toolbar=0'} type="application/pdf" width="100%" height="100%"></object>
			);
		} else {
			if (this.state.loading === true) {
				return null;
			}
			return (
				<div className={this.classes.elem('chooseOrderModal').b()}>
					<Row>
						<Column>
							<Text size="lg" bold={true}>
								{this.lang.chooseOrder}
							</Text>
						</Column>
						<Column>
							<Input
								icon="search"
								color="grey-2"
								value={this.state.orderSearchString}
								placeholder={this.lang.searchOrder}
								onChange={e => {
									this.setState({ orderSearchString: e.target.value });
								}}
							/>
						</Column>
					</Row>
					<div className={this.parentClasses.elem('sidebar-section').b()}>
						{this.state.loadingOrders ? (
							<Row className={this.classes.elem('loading-orders').b()}>
								<Loader size="sm" data-testid="loader"></Loader>
							</Row>
						) : this.filterOrders(this.state.orderSearchString).length !== 0 ? (
							<>
								{filteredOrders.slice(0, this.state.renderedCount).map(order => (
									<Column
										key={order.id}
										className={this.parentClasses.elem('sidebar-section-input-row').b()}
										onClick={() => this.setOrderForPreview(order)}
									>
										<Row className={this.classes.elem('order-title').b()}>{order.description}</Row>
										<Row className={this.classes.elem('order-text').b()}>{order.client.name}</Row>
										<Row className={this.classes.elem('order-text').b()}>
											{order.value} {order.currency} {'•'} {order.date.toString()}
										</Row>
									</Column>
								))}
								<ScrollEndDetector
									onScrollEnd={this.loadMoreOrders}
									disabled={filteredOrders.length <= this.state.renderedCount}
								/>
							</>
						) : this.state.orderSearchString ? (
							<div className={this.classes.elem('search-info').b()}>
								{this.lang.noResultsFor} {' "'}
								{this.state.orderSearchString}
								{'" '}
							</div>
						) : (
							<div className={this.classes.elem('search-info').b()}>{this.lang.searchOrderSpecifics}</div>
						)}
					</div>
				</div>
			);
		}
	};

	previewDropDown = close => {
		return (
			<Column className={this.toolBarClasses.elem('preview-drop-down').b()}>
				<Row className={this.toolBarClasses.elem('preview-drop-down-orders').b()}>
					<Column>
						<Text>{this.lang.chooseOrder}</Text>
						<Row className={this.toolBarClasses.elem('preview-drop-down-search').b()}>
							<Column className={this.toolBarClasses.elem('preview-drop-down-search-inner').b()}>
								<Row className={this.toolBarClasses.elem('preview-drop-down-search-top').b()}>
									<Input
										icon="search"
										value={this.state.orderSearchString}
										placeholder={this.lang.searchOrder}
										onChange={e => {
											this.setState({ orderSearchString: e.target.value });
										}}
									/>
								</Row>
								<Row
									ref={this.listContainerRef}
									className={this.toolBarClasses.elem('preview-drop-down-search-content').b()}
								>
									<Column>
										{this.state.loadingOrders ? (
											<Row className={this.toolBarClasses.elem('loading-orders').b()}>
												<Loader size="sm" data-testid="loader"></Loader>
											</Row>
										) : (
											<OrderRadioList
												orderList={this.state.orders}
												searchString={this.state.orderSearchString}
												selectedOrder={this.state.dropDownOrder.id}
												setSelectedOrder={value => {
													this.setState({
														dropDownOrder: this.state.orders.find(
															item => item.id === parseInt(value)
														)
													});
												}}
												listContainerRef={this.listContainerRef}
											/>
										)}
									</Column>
								</Row>
							</Column>
						</Row>
					</Column>
				</Row>
				<Row>
					<Column>
						<Row>
							<Tooltip
								className={this.toolBarClasses.elem('preview-drop-down-button').b()}
								title={this.lang.previewTooltip}
								position="top"
								disabled={this.state.selectedOrder !== null}
							>
								<PrimaryButton
									onClick={() => {
										this.setOrderForPreview(this.state.dropDownOrder);
										close();
									}}
									disabled={!this.state.selectedOrder}
									className={this.toolBarClasses.elem('preview-drop-down-button').b()}
									loading={this.state.loadingPreview}
									data-testid="preview-order-button"
								>
									{this.lang.preview}
								</PrimaryButton>
							</Tooltip>
						</Row>
						<Row>
							<ThirdButton
								onClick={() => {
									close();
								}}
								className={this.toolBarClasses.elem('preview-drop-down-button').b()}
							>
								{this.lang.cancel}
							</ThirdButton>
						</Row>
					</Column>
				</Row>
			</Column>
		);
	};

	changeLanguage = lang => {
		this.setState({ language: lang.id, loading: true });
		this.getTemplate(lang.id);
	};

	componentDidMount() {
		this.fetchOrders();
		this.mounted = true;
		if (this.props.pdfTemplateResource.orderId) {
			this.getTemplate(this.state.language);
		} else {
			this.setState({ loading: false });
		}
	}

	componentWillUnmount() {
		this.mounted = false;
		clearTimeout(this.timeout);
		if (this.cancelableOrderResourceFind) {
			this.cancelableOrderResourceFind.cancel();
		}
		if (this.cancelablePdfljsLibGetDocument) {
			this.cancelablePdfljsLibGetDocument.cancel();
		}
		if (this.cancelableServiceName) {
			this.cancelableServiceName.cancel();
		}
	}

	render() {
		const close = (
			<Button
				className={this.classes.elem('close', 'exit-button').b()}
				color="grey"
				onClick={() => this.props.reject()}
				shadow="none"
			>
				<Icon name="times" />
			</Button>
		);
		return (
			<div className={this.classes.b()}>
				<div className="preview--modal--header">
					<Row style={{ position: 'inherit', width: '100%', height: '100%' }}>
						{this.state.loading ? (
							<Column className={this.classes.elem('header--column--left').b()} align="left">
								<Loader size="xs" className="pdf-loader" />
							</Column>
						) : !this.state.previewError ? (
							<>
								<Column className={this.classes.elem('header--column--left').b()} align="left">
									{this.state.orderId ? (
										<Tooltip title={this.lang.downloadPdf} position="bottom" delay={200}>
											<a
												className="email"
												href={this.state.url}
												download={
													(this.state.clientName ?? '') +
													'-' +
													moment().format('YYYY-MM-DD HH:mm') +
													'.pdf'
												}
											>
												<Icon name="download" />
											</a>
										</Tooltip>
									) : null}
									{(
										this.hasNewCustomDocumentTemplates
											? this.state.orderId && this.state.selectedOrder === null
												? !this.props.pdfTemplateResource.hideMailIcon
												: false
											: true
									) ? (
										<Tooltip title={this.lang.sendMail} position="bottom" delay={100}>
											<button onClick={this.initMail} className="email">
												<Icon name="email" />
											</button>
										</Tooltip>
									) : null}
								</Column>
								<Flex alignItems="center" gap="u3">
									{this.state.orderId && this.state.selectedOrder ? (
										<div className={this.classes.elem('dropdownTrigger').b()}>
											<DropDownMenu
												align="center"
												verticalAlign="bottom"
												renderTrigger={(expanded, setExpanded) => (
													<div
														onClick={setExpanded}
														className={this.classes.elem('dropdownTriggerDescription').b()}
													>
														<div
															className={this.classes
																.elem('dropdownTriggerDescription--text')
																.b()}
														>
															<Text color="black" size="md" ellipsis={true}>
																{this.state.selectedOrder.description}
															</Text>
															<Text color="grey-10" size="sm" ellipsis={true}>
																{this.state.selectedOrder.client.name}
															</Text>
														</div>
														<div>
															<Icon name="chevron-down" color="black" />
														</div>
													</div>
												)}
											>
												{close => this.previewDropDown(close)}
											</DropDownMenu>
										</div>
									) : null}
									{this.state.supportedLanguages && this.state.supportedLanguages.length > 0 ? (
										<LanguageSelect
											value={this.state.language}
											onChange={this.changeLanguage}
											required
											anchor={document.querySelector('.PreviewPdf')}
											options={this.state.supportedLanguages.map(l => ({
												...l,
												name: T(l.name)
											}))}
										/>
									) : null}
									{close}
								</Flex>
							</>
						) : null}
					</Row>
				</div>
				<div
					className="up-modal-content"
					style={{
						top: '48px',
						overflowY: this.state.type === 'order' && !this.state.loading ? 'hidden' : 'auto'
					}}
				>
					{this.state.previewError ? (
						<Card color="grey-11" border="blue" className="error-card">
							<CardContent>
								<Text center size="xl" color="white">
									{this.state.previewError}
								</Text>
							</CardContent>
						</Card>
					) : (
						this.renderContent()
					)}
				</div>
				<div className="up-modal-controls">
					<button type="submit" className="btn btn-link btn-sm" onClick={() => this.props.reject()}>
						{this.lang.close}
					</button>
				</div>
			</div>
		);
	}
}

PreviewPdf.propTypes = {
	upModal: PropTypes.object,
	pdfUrl: PropTypes.string,
	reject: PropTypes.func.isRequired,
	saving: PropTypes.bool,
	pdfTemplateResource: PropTypes.object,
	disableEmail: PropTypes.bool,
	hideMailIcon: PropTypes.bool
};

PreviewPdf.defaultProps = {
	reject: () => {}
};

export const detached = PreviewPdf;
export default connect(mapStateToProps, null)(PreviewPdf);

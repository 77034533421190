'use strict';
import { DefaultNoDataAsTable } from 'App/components/ListView/DefaultNoData';

angular.module('domain.admin').controller('EditSalesboard', [
	'meta',
	'$stateParams',
	'$translate',
	'NotificationService',
	'$state',
	'ListViewService',
	'AppService',
	function (meta, $stateParams, $translate, NotificationService, $state, ListViewService, AppService) {
		var EditSalesboard = this;
		EditSalesboard.DefaultNoDataAsTable = DefaultNoDataAsTable;
		EditSalesboard.formatNoData = () => {
			return $translate.instant('admin.salesboard.noSalesboardFoundForId', { id: $stateParams.id });
		};
		EditSalesboard.noDataSubtitle = ' ';

		var customerId;

		EditSalesboard.save = function () {
			EditSalesboard.saving = true;

			try {
				JSON.parse(EditSalesboard.board.filters);
			} catch (error) {
				EditSalesboard.saving = false;
				NotificationService.addNotification({
					icon: 'times',
					style: NotificationService.style.ERROR,
					title: $translate.instant('default.error'),
					body: $translate.instant('admin.salesboard.mainFilterInvalid')
				});
				return;
			}

			for (const [index, col] of Object.entries(EditSalesboard.board.columns)) {
				if (col && col.filters) {
					try {
						JSON.parse(col.filters);
					} catch (error) {
						EditSalesboard.saving = false;
						NotificationService.addNotification({
							icon: 'times',
							style: NotificationService.style.ERROR,
							title: $translate.instant('default.error'),
							body: $translate.instant('admin.salesboard.columnFilterInvalid', { nr: index })
						});
						return;
					}
				}
			}

			EditSalesboard.board.filters = JSON.parse(EditSalesboard.board.filters);

			for (const col of EditSalesboard.board.columns) {
				if (col && col.filters) {
					col.filters = JSON.parse(col.filters);
				}
			}

			EditSalesboard.board.standard = true;

			ListViewService.save('salesboard', EditSalesboard.board, customerId, 'public')
				.then(function () {
					EditSalesboard.saving = false;
					$state.go('admin.salesboards', { customerId: customerId });
				})
				.catch(function () {
					EditSalesboard.saving = false;
				});
		};

		EditSalesboard.changeimage = function (col) {
			console.log('col', col);
		};

		EditSalesboard.removeColumn = function (col) {
			_.remove(EditSalesboard.board.columns, col);
		};

		EditSalesboard.addColSort = function (col) {
			if (!col.sorting) {
				col.sorting = [];
			}

			col.sorting.push({ attribute: '', ascending: false });
		};

		EditSalesboard.removeColSort = function (col, sort) {
			_.remove(col.sorting, sort);
		};

		EditSalesboard.addColumn = function (type) {
			if (type === 'appointment') {
				EditSalesboard.board.columns.push({
					type: 'appointment',
					title: 'New appointment column',
					image: 'foo.png',
					sortOrder: 0,
					filters: JSON.stringify([
						{
							comparisonType: 'Equals',
							field: 'activityType.first',
							value: 1
						}
					])
				});
			}
			if (type === 'opportunity') {
				EditSalesboard.board.columns.push({
					type: 'opportunity',
					title: 'New opportunity column',
					image: 'foo.png',
					sortOrder: 0,
					filters: JSON.stringify([
						{
							comparisonType: 'GreaterThanEquals',
							field: 'probability',
							value: 90
						},
						{
							comparisonType: 'LessThan',
							field: 'probability',
							value: 100
						}
					])
				});
			}
			if (type === 'order') {
				EditSalesboard.board.columns.push({
					type: 'order',
					title: 'New order column',
					image: 'foo.png',
					sortOrder: EditSalesboard.board.columns.length,
					filters: JSON.stringify([
						{
							comparisonType: 'Equals',
							field: 'probability',
							value: 100
						}
					])
				});
			}
		};

		var init = function () {
			customerId = AppService.getCustomerId();

			EditSalesboard.edit = $stateParams.id !== 'new';
			EditSalesboard.masterCurrency = _.find(meta.metadata.customerCurrencies, { masterCurrency: true });
			EditSalesboard.stages = meta.stages.data;
			EditSalesboard.appointmentTypes = meta.appointmentType.data;
			EditSalesboard.columnLimit = 15;

			// do some intial stuff with data
			if (!EditSalesboard.edit) {
				EditSalesboard.board = angular.copy(meta.listViews[0]);
				EditSalesboard.board.id = null;
				EditSalesboard.board.title = '';
				EditSalesboard.board.default = false;
				EditSalesboard.board.editable = true;
				EditSalesboard.board.private = false;
				EditSalesboard.board.filters = [
					{
						filterName: 'User',
						comparisonType: 'Equals',
						field: 'user.id',
						filterType: 'selectGroup',
						inactive: false,
						value: 'self',
						title: 'default.user'
					}
				];

				// Not realy needed to delete this as it will be filtered by the api, but will do it anyways ;P
				delete EditSalesboard.board.isStandardView;
			} else {
				const board = _.find(meta.listViews, { id: parseInt($stateParams.id) });
				if (!board) {
					return;
				}
				EditSalesboard.board = angular.copy(_.find(meta.listViews, { id: parseInt($stateParams.id) }));
				EditSalesboard.board.default = EditSalesboard.board.default || false;
			}

			// Stringify filters
			EditSalesboard.board.filters = JSON.stringify(EditSalesboard.board.filters);

			EditSalesboard.board.columns.forEach(function (col) {
				// Stringify column filters
				if (col.filters) {
					col.filters = JSON.stringify(col.filters);
				}
				// Fix sorting
				if (!col.sorting) {
					col.sorting = [];
				}
			});
		};

		// eslint-disable-next-line
		AppService.loadedPromise.then(init);
	}
]);

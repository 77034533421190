import React, { Component } from 'react';
import { Card } from '@upsales/components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	onStateChange,
	openDropdown,
	closeDropdown,
	setFavorite,
	refreshData,
	updateDropdownSearch
} from '../../store/reducers/InsightsReducer';
import InsightsContent from './InsightsContent';
import InsightsNavbar from './InsightsNavbar';
import DashboardShape from './DashboardShape';
import sortAlphabetically from '../../utils/sortAlphabetically';

import './Insights.scss';
import ExploreShape from './ExploreShape';

class Insights extends Component {
	static propTypes = {
		dropdown: PropTypes.string,
		closeDropdown: PropTypes.func.isRequired,
		onStateChange: PropTypes.func.isRequired,
		openDropdown: PropTypes.func.isRequired,
		refreshData: PropTypes.func.isRequired,
		setFavorite: PropTypes.func.isRequired,
		updateDropdownSearch: PropTypes.func.isRequired,
		dropdownSearch: PropTypes.string,
		reports: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				title: PropTypes.string.isRequired
			})
		),
		favorites: PropTypes.arrayOf(DashboardShape),
		mine: PropTypes.arrayOf(DashboardShape),
		shared: PropTypes.arrayOf(DashboardShape),
		standard: PropTypes.arrayOf(DashboardShape),
		basicReporting: PropTypes.arrayOf(DashboardShape),
		dashboardsPending: PropTypes.bool,
		signedUrl: PropTypes.string,
		stateParamsType: PropTypes.string,
		explores: PropTypes.arrayOf(ExploreShape)
	};

	closeDropdown = e => {
		// Do not close if we click on another tab
		if (
			e.composedPath &&
			e.composedPath().some(element => ['dashboard', 'look', 'basicreporting'].indexOf(element.id) !== -1)
		) {
			return;
		}

		if (this.props.dropdown) {
			setTimeout(() => {
				this.props.closeDropdown();
			}, 10);
		}
	};

	openDropdown = type => {
		if (this.props.dropdown && this.props.dropdown === type) {
			return this.props.closeDropdown();
		}

		this.props.openDropdown(type);
	};

	goToState = (type = '', id = '', useNext) => {
		this.props.closeDropdown();
		Tools.$state.go('looker', { type, id, useNext }, { reload: false, notify: false });
		this.props.onStateChange({
			type,
			id,
			useNext: '' + useNext
		});
	};

	goToHome = () => {
		if (Tools.$state.params.type || Tools.$state.params.id) {
			this.props.refreshData();
			this.goToState(null, null);
		}
	};

	goToDashboard = (id, useNext) => {
		this.goToState('dashboard', id, useNext);
	};

	goToBasicReport = id => {
		this.goToState('basicreporting', id);
	};

	goToReport = id => {
		this.goToState('look', id);
	};

	UNSAFE_componentWillMount() {
		const stateParams = Tools.$state.params;
		this.props.onStateChange(stateParams);
		document.addEventListener('mouseup', this.closeDropdown);
	}

	componentWillUnmount() {
		document.removeEventListener('mouseup', this.closeDropdown);
	}

	render() {
		return (
			<Card color="light-grey" border="blue" className="InsightsCard">
				<InsightsNavbar
					dropdown={this.props.dropdown}
					reports={this.props.reports}
					favorites={this.props.favorites}
					mine={this.props.mine}
					shared={this.props.shared}
					standard={this.props.standard}
					basicReporting={this.props.basicReporting}
					stateParamsType={this.props.stateParamsType}
					goToHome={this.goToHome}
					openDropdown={this.openDropdown}
					goToDashboard={this.goToDashboard}
					goToBasicReport={this.goToBasicReport}
					goToReport={this.goToReport}
					dropdownSearch={this.props.dropdownSearch}
					updateDropdownSearch={this.props.updateDropdownSearch}
					hasExplores={!!this.props.explores.length}
				/>
				<InsightsContent
					closeDropdown={this.props.closeDropdown}
					goToDashboard={this.goToDashboard}
					dashboardsPending={this.props.dashboardsPending}
					favorites={this.props.favorites}
					mine={this.props.mine}
					setFavorite={this.props.setFavorite}
					shared={this.props.shared}
					signedUrl={this.props.signedUrl}
					standard={this.props.standard}
				/>
			</Card>
		);
	}
}

export const mapStateToProps = state => {
	const { favorites, mine, shared, standard } = state.Insights.dashboards.reduce(
		(result, dashboard) => {
			const { space } = dashboard;
			switch (space) {
				case 'looker.space.upsales':
					result.standard.push(dashboard);
					break;
				case 'looker.space.account':
					result.shared.push(dashboard);
					break;
				case 'looker.space.private':
					result.mine.push(dashboard);
					break;
			}
			if (dashboard.isFavorite) {
				result.favorites.push(dashboard);
			}

			return result;
		},
		{ favorites: [], mine: [], shared: [], standard: [] }
	);

	return {
		favorites: favorites.sort(sortAlphabetically('title')),
		mine: mine.sort(sortAlphabetically('title')),
		shared: shared.sort(sortAlphabetically('title')),
		standard: standard.sort(sortAlphabetically('title')),
		signedUrl: state.Insights.signedUrl,
		dropdown: state.Insights.openDropdown,
		reports: state.Insights.reports,
		dashboardsPending: state.Insights.dashboardsPending,
		stateParamsType: state.Insights.stateParamsType,
		dropdownSearch: state.Insights.dropdownSearch,
		explores: state.Insights.explores,
		basicReporting: state.Insights.basicReporting
	};
};

const mapDispatchToProps = {
	onStateChange,
	openDropdown,
	closeDropdown,
	setFavorite,
	refreshData,
	updateDropdownSearch
};

window.Insights = connect(mapStateToProps, mapDispatchToProps)(Insights);
export default window.Insights;
export const NotConnected = Insights;

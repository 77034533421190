import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import NavbarButton from 'App/navbar/NavbarButton';
import { Progressbar, Text } from '@upsales/components';

class OnboardingNotification extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;

		this.lang = {
			title: T('onboarding.notification.title')
		};
	}

	getStats() {
		const { onboarding } = Tools.AppService.getMetadata();
		const stats = {
			completed: 0,
			total: 0
		};

		onboarding.forEach(onbo => {
			onbo.steps.forEach(step => {
				if (step.hasSubSteps) {
					step.substeps.forEach(substep => {
						if (substep.completed) {
							stats.completed++;
						}

						stats.total++;
					});

					return;
				}

				if (step.completed) {
					stats.completed++;
				}

				stats.total++;
			});
		});

		return stats;
	}

	onClick() {
		return Tools.$state.go('onboarding', { openFullScreen: true });
	}

	render() {
		const MainClass = new Bem('OnboardingNotification');
		const { completed, total } = this.getStats();

		return (
			<NavbarButton onClick={this.onClick} className={MainClass.b()}>
				<Text color="white" size="sm" bold className={MainClass.elem('title').b()}>
					{this.lang.title}
					<span className={MainClass.elem('percent').b()}>{`${((completed / total) * 100).toFixed(
						0
					)}%`}</span>
				</Text>
				<Progressbar color="bright-green" containerColor="greyeen" value={(completed / total) * 100} />
			</NavbarButton>
		);
	}
}

export const detached = OnboardingNotification;
export default OnboardingNotification;

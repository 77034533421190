'use strict';

angular.module('upHelpers').factory('EntityType', function () {
	var Type = {
		Account: 'Account',
		User: 'User',
		Order: 'Order',
		Contact: 'Contact',
		Activity: 'Activity',
		ActivityList: 'ActivityList',
		Agreement: 'Agreement',
		Appointment: 'Appointment',
		Campaign: 'Campaign',
		Mail: 'Mail',
		Project: 'Project',
		Lead: 'Lead'
	};

	function findByString(type) {
		if (!(type && typeof type === 'string' && type.length > 0)) {
			return null;
		}
		return Type[type.charAt(0).toUpperCase() + type.slice(1)];
	}

	return {
		Type: Type,
		findByString: findByString
	};
});

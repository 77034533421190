import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { views, changeView } from 'Store/reducers/AssignModalReducer';
import AssignModalActivityList from './AssignModalActivityList';
import { Loader, Card, Title, Text } from '@upsales/components';
import './AssignModalActivities.scss';
import AssignButton from './AssignButton';

export const mapStateToProps = state => ({
	activitiesAndAppointments: state.AssignModal.activitiesAndAppointments,
	activitiesLoading: state.AssignModal.activitiesLoading,
	activitiesError: state.AssignModal.activitiesError
});

const mapDispatchToProps = {
	changeView
};

class AssignModalActivities extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;

		this.lang = {
			planedActivitiesAndAppointments: t('processedBy.planedActivitiesAndAppointments'),
			assignUser: t('processedBy.AssignUser'),
			delayedActivities: t('processedBy.delayedActivities'),
			delayedActivity: t('processedBy.delayedActivity'),
			noActiveActivities: t('processedBy.noActiveActivities'),
			noActivitiesDescription: t('processedBy.noActivitiesDescription'),
			activityError: t('listError.activity')
		};

		this.hasEventListener = false;

		this.goToAssign = this.goToAssign.bind(this);
	}
	goToAssign() {
		this.props.changeView(views.ASSIGN);
	}
	componentDidUpdate() {
		this.props.reloadModalPosition();
	}
	render() {
		const lang = this.lang;
		const props = this.props;
		const classNames = new BemClass('AssignModalActivities');

		if (props.activitiesLoading) {
			return <Loader size="sm" className={classNames.elem('loader').b()} />;
		}

		if (props.activitiesError) {
			return (
				<Title className={classNames.elem('error')} color="red">
					{lang.activityError}
				</Title>
			);
		}

		const separatedItems = _.reduce(
			props.activitiesAndAppointments,
			(res, item) => {
				if (!item.isAppointment) {
					const date = moment(item.date);

					if (!item.time) {
						date.hours(23).minutes(59);
					}
					if (moment().isAfter(date)) {
						res.delayed.push(item);
					} else {
						res.inTime.push(item);
					}
				} else {
					res.inTime.push(item);
				}
				return res;
			},
			{
				delayed: [],
				inTime: []
			}
		);

		let delayedSection = null;

		if (separatedItems.delayed.length) {
			const title = separatedItems.delayed.length === 1 ? lang.delayedActivity : lang.delayedActivities;

			delayedSection = (
				<AssignModalActivityList
					items={separatedItems.delayed}
					className={classNames.elem('inactive').b()}
					title={separatedItems.delayed.length + ' ' + title}
				/>
			);
		}

		let noActiveSection = null;
		let inTimeSection = null;
		let assignUserRow = null;

		if (separatedItems.inTime.length) {
			assignUserRow = (
				<div className={classNames.elem('assign-header')}>
					<Title>{lang.planedActivitiesAndAppointments}</Title>
					<AssignButton onClick={() => props.changeView(views.ASSIGN)}>{lang.assignUser}</AssignButton>
				</div>
			);

			inTimeSection = (
				<AssignModalActivityList className={classNames.b()} items={separatedItems.inTime} title={null} />
			);
		} else {
			noActiveSection = (
				<Card className={classNames.elem('no-assigned')}>
					<Title>{lang.noActiveActivities}</Title>
					<Text color="grey-10">{lang.noActivitiesDescription}</Text>

					<AssignButton onClick={this.goToAssign} className={classNames.elem('btn').b()}>
						{lang.assignUser}
					</AssignButton>
				</Card>
			);
		}

		return (
			<div className={classNames} ref={r => (this._activityList = r)}>
				{assignUserRow}
				{noActiveSection}
				{inTimeSection}
				{delayedSection}
			</div>
		);
	}
}

AssignModalActivities.defaultProps = {
	activitiesAndAppointments: []
};

AssignModalActivities.propTypes = {
	activitiesAndAppointments: PropTypes.array,
	hasInTime: PropTypes.bool,
	changeView: PropTypes.func,
	reloadModalPosition: PropTypes.func,
	activitiesLoading: PropTypes.bool,
	activitiesError: PropTypes.bool
};

export const detached = AssignModalActivities;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalActivities);
export default Component;

ReactTemplates.segment.contactStats = window.ReactCreateClass({
	chart: null,
	UNSAFE_componentWillMount: function () {
		var self = this;
		var t = Tools.$translate;
		this.lang = {
			contactsInSegment: t('segment.contactsInSegment'),
			prev12Weeks: t('date.prev12Weeks'),
			mailSent: t('default.mailSent'),
			formSubmits: t('form.latestSubmits'),
			adImpressions: t('default.adImpressions'),
			visits: t('default.siteVisits'),
			completedFlow: t('segment.completedFlow'),
			weekPrefix: t('calendar.weekPrefix')
		};
		this.chartConfig = {
			chart: {
				type: 'area',
				backgroundColor: 'transparent',
				margin: [10, 20, 20, 50],
				spacing: [0, 0, 0, 0]
			},
			title: { text: null },
			credits: { enabled: false },
			xAxis: {
				title: { text: null },
				lineColor: '#000',
				labels: {
					align: 'left',
					y: 15,
					style: { color: '#999', fontSize: '10px' },
					formatter: function () {
						if (this.value === 12) {
							return '';
						}
						var data = self.props.eventComponent.chartData[this.value];
						var sameWeek = data.week.toString() === moment().format('W');
						return (
							'<span' +
							(sameWeek ? ' style="color:#4A90E2;"' : '') +
							'>' +
							data.week +
							self.lang.weekPrefix +
							'</span>'
						);
					},
					useHTML: true
				},
				tickWidth: 0,
				startOnTick: true,
				endOnTick: true,
				tickInterval: 1,
				min: 0,
				max: 12
			},
			yAxis: {
				title: { text: null },
				allowDecimals: false,
				gridLineWidth: 0,
				labels: {
					align: 'left',
					x: -40,
					style: { color: '#999', fontSize: '10px' },
					formatter: function () {
						return self.props.tools.formatNumber(this.value);
					}
				},
				max: 25020,
				endOnTick: false
			},
			legend: { enabled: false },
			plotOptions: {
				series: {
					lineColor: '#4A90E2',
					color: 'rgba(74, 144, 226, 0.3)',
					lineWidth: 1,
					animation: false,
					marker: {
						enabled: false,
						states: {
							hover: {
								fillColor: '#4A90E2',
								lineColor: '#4A90E2',
								lineWidth: 1
							}
						}
					},
					states: {
						hover: {
							halo: {
								size: 0
							},
							lineWidth: 1
						}
					}
				}
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					return self.props.tools.formatNumber(this.y);
				},
				style: { color: '#fff', fontSize: '10px' },
				borderRadius: 3,
				backgroundColor: 'rgba(0,0,0,0.7)',
				padding: 5,
				shadow: false,
				borderWidth: 0
			},
			series: [{ data: [] }]
		};
	},
	componentDidMount: function () {
		this.renderChart();
	},
	componentDidUpdate: function () {
		this.renderChart();
	},
	componentWillUnmount: function () {
		this.chart.destroy();
	},
	renderChart: function () {
		var self = this;
		if (!this.props.eventComponent.chartData.length) {
			return;
		}
		var container = this._chart;
		var max = 0;
		this.chartConfig.series[0].data = _.map(this.props.eventComponent.chartData, function (data, i) {
			max = Math.max(max, data.totalContacts);
			return { x: i, y: data.totalContacts };
		});
		this.chartConfig.yAxis.max = max;
		new Highcharts.Chart(container, this.chartConfig, function () {
			self.chart = this;
		});
	},
	renderWidget: function (title, value, key, string) {
		return (
			<div className="widget" key={key}>
				<span className="title">{title}</span>
				{string ? value : this.props.tools.formatNumber(value)}
			</div>
		);
	},
	render: function () {
		var eventComponent = this.props.eventComponent;
		var self = this;
		var stats = {
			sentMail: this.props.segment.stats.mailCount,
			formSubmits: this.props.segment.stats.submitCount,
			completedFlowPercentage: this.props.segment.stats.percentageCompleted || 0,
			visits: this.props.segment.stats.visitCount
		};

		return (
			<div id="contact-stats">
				<div id="chart-wrap" className={eventComponent.chartDataLoading ? 'loading' : null}>
					<h2>
						{self.lang.contactsInSegment} <ReactTemplates.elevio sidebar={true} articleId={430} />
					</h2>
					<div className="subtitle">{self.lang.prev12Weeks}</div>
					<div
						className="chart"
						ref={function (r) {
							self._chart = r;
						}}
					></div>
					<ReactTemplates.loader />
				</div>
				<div id="widget-wrap">
					{self.renderWidget(self.lang.mailSent, stats.sentMail, 'mail')}
					{self.renderWidget(self.lang.formSubmits, stats.formSubmits, 'submits')}
					{self.renderWidget(
						self.lang.completedFlow,
						stats.completedFlowPercentage + '%',
						'completedFlow',
						true
					)}
					{self.renderWidget(self.lang.visits, stats.visits, 'visits')}
				</div>
			</div>
		);
	}
});

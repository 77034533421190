import { Text } from '@upsales/components';
import { FlowStep } from 'App/resources/Model/Flow';
import React from 'react';
import FlowStepBase from '../FlowStepBase';
import T from 'Components/Helpers/translate';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
};

export const MAX_HEIGHT = 54;

const FlowStepSnooze = ({ step, ...baseProps }: Props) => {
	const unitLangTag = step.waitTime === 1 ? `date.${step.waitUnit}` : `date.${step.waitUnit}s`;
	const text = `${step.waitTime} ${T(unitLangTag).toLowerCase()}`;

	return (
		<FlowStepBase
			icon="snooze"
			backgroundColor="white"
			borderColor="grey-11"
			title={T('flow.snooze')}
			maxHeight={MAX_HEIGHT}
			{...baseProps}
		>
			<Text>{text}</Text>
		</FlowStepBase>
	);
};

export default FlowStepSnooze;

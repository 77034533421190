import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import T from 'Components/Helpers/translate';

const currencyFormat = (v, c) => Tools.$filter('currencyFormat')(v, c);

// eslint-disable-next-line no-unused-vars
const SupersearchResultRowOpportunity = ({
	className,
	item: opportunity,
	searchStr,
	closeSearch,
	defaultCurrency,
	...props
}) => {
	const classes = new bemClass('SupersearchResultRowOpportunity', className);
	const lost = opportunity.probability === 0;

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={opportunity.user ? [opportunity.user] : []}
			searchStr={searchStr}
			customValues={opportunity.custom}
			inactive={lost}
			{...props}
		>
			<Column>
				<Text italic={lost} bold={true} color={lost ? 'grey-10' : 'black'} size="md">
					<SupersearchHighlight searchStr={searchStr} text={opportunity.description} type="string" />
				</Text>
				<Text color="grey-12" size="sm">
					{opportunity.client ? opportunity.client.name : T('default.noAccount')}
				</Text>
			</Column>
			<Column>
				<Text color="black" size="sm">
					{currencyFormat(
						Math.round(opportunity.orderValue * 100) / 100,
						opportunity.currency ? opportunity.currency : defaultCurrency
					)}
				</Text>
				<Text color="grey-12" size="sm">
					{moment(opportunity.date).format('L')}
				</Text>
			</Column>
			{opportunity.stage ? (
				<Column>
					<Text color="black" size="sm">
						{opportunity.stage.name}
					</Text>
					<Text color="grey-12" size="sm">
						{opportunity.probability + '%'}
					</Text>
				</Column>
			) : null}
		</SupersearchResultRow>
	);
};

SupersearchResultRowOpportunity.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	defaultCurrency: PropTypes.string.isRequired
};

export default SupersearchResultRowOpportunity;

import React, { useEffect, useState } from 'react';
import AdminHeader from '../Admin/AdminHeader';
import T from '../Helpers/translate';
import MailSignatureResource from '../../resources/MailSignature';
import { Card, Table, TableColumn, TableHeader, CardHeader, TableRow, Checkbox, Loader } from '@upsales/components';
import { useSelector } from 'react-redux';

export default function DefaultMailSignature() {
	const { self: user } = useSelector(state => state.App);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		retrieveData();
	}, []);

	if (loading) {
		return (
			<div className="admin-root-wrapper">
				<AdminHeader
					title={T('admin.mailSignature.title')}
					description={T('admin.mailSignature.description')}
					image={'admin-mailsignature.svg'}
				/>
				<div id="admin-content">
					<Card>
						<CardHeader title={T('admin.mailSignature.card.title')} />
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Loader />
						</div>
					</Card>
				</div>
			</div>
		);
	}

	return (
		<div className="admin-root-wrapper">
			<AdminHeader
				title={T('admin.mailSignature.title')}
				description={T('admin.mailSignature.description')}
				image={'admin-mailsignature.svg'}
			/>
			<div id="admin-content">
				<Card>
					<CardHeader title={T('admin.mailSignature.card.title')} />
					<Table>
						<TableHeader
							columns={[
								{ title: Tools.$translate('default.name') },
								{ title: Tools.$translate('default.default') }
							]}
						/>
						{data.map(row => {
							return (
								<TableRow key={row.name}>
									<TableColumn title={row.name} />
									<TableColumn>
										<Checkbox
											size="sm"
											checked={user.userParams.defaultSignature === row.id}
											onChange={value => changeDefault(value, row)}
										/>
									</TableColumn>
								</TableRow>
							);
						})}
					</Table>
				</Card>
			</div>
		</div>
	);

	async function retrieveData() {
		try {
			const response = await MailSignatureResource.find();
			setData(response.data);
			setLoading(false);
		} catch (error) {
			console.error('Mailsignature find failed.', error);
			setLoading(false);
		}
	}

	async function changeDefault(value, signature) {
		let signatureId = signature.id;

		if (value !== true) {
			signatureId = -1;
		}

		try {
			await Tools.UserParam.save(60, signatureId, { skipNotification: true });
			Tools.$rootScope.$broadcast('defaultSignature.changed', signatureId);

			retrieveData();
		} catch (error) {
			console.error('Mailsignature change default failed.', error);
		}
	}
}

window.DefaultMailSignature = DefaultMailSignature;

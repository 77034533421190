import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { getTimelineRow } from 'Components/Helpers/getTimelineRow';
import HistoryLog from '../HistoryLog';
import T, { useTranslation } from 'Components/Helpers/translate';
import React, { useMemo } from 'react';
import { TimelineProps } from 'Components/TimelineRow/TimelineGeneric';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import Client from 'App/resources/Model/Client';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore/TimelineLoadMore';
import Event from 'App/resources/Model/Event';
import { Icon } from '@upsales/components';
import { useSoftDeployAccess } from 'App/components/hooks';

type Props = {
	client: Client;
	isContactVariant?: boolean;
	cardAdditionalProps?: Partial<TimelineProps>;
};

const listeners = [
	'activity.added',
	'activity.deleted',
	'activity.updated',
	'appointment.added',
	'appointment.deleted',
	'appointment.updated'
];

const fetcher: FetcherFunction<{ client: Props['client'] }, Event> = (filters, { client }) => {
	const EventService = getAngularModule('EventService');
	const resource = Tools.ActivityList;

	filters.addSort(resource.attr.date, false);
	filters.addFilter(resource.attr.client.attr.id, ComparisonTypes.Equals, client!.id);
	filters.addFilter(resource.attr.projectPlan.attr.id, ComparisonTypes.Equals, null);

	const orBuilder = filters.orBuilder();
	orBuilder.next();
	orBuilder.addFilter(resource.attr.closeDate, ComparisonTypes.Equals, null);
	orBuilder.addFilter(resource.attr.isAppointment, ComparisonTypes.Equals, false);
	orBuilder.next();
	orBuilder.addFilter(resource.attr.isAppointment, ComparisonTypes.Equals, true);
	orBuilder.addFilter(resource.attr.date, ComparisonTypes.GreaterThanEquals, new Date());
	orBuilder.done();

	return resource.find(filters.build()).then(res => {
		const activityEvents = res.data.map(activity => {
			const event = EventService.create.Activity(activity);
			event.date = activity.date;
			event.client = client;
			return event;
		});

		return {
			...res,
			data: activityEvents
		};
	});
};

const OpenActivityHistoryLog = ({ client, isContactVariant = false, ...props }: Props) => {
	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore
	} = usePartialLoader({ fetcher, broadcastTypes: listeners, fetcherProps: { client } });
	const { t } = useTranslation();
	const hasRemovedActivities = useSoftDeployAccess('REMOVE_ACTIVITIES');

	const eventComponents = useMemo(
		() =>
			events.map((e, index) => (
				<div key={index}>
					{getTimelineRow(e, isContactVariant ? 'Contact' : 'Client', undefined, undefined, undefined, {
						hasAvatar: true
					})}
				</div>
			)),
		[events]
	);

	if (!loading && totalEvents === 0) {
		if (client.active) {
			return (
				<HistoryLog
					title={
						<>
							<Icon name="exclamation-circle" space="prm" />
							{t(hasRemovedActivities ? 'search.accountHasNoActivity' : 'search.accountHasNoTask')}
						</>
					}
					hasPriorHistory
				/>
			);
		}

		return null;
	}

	return (
		<HistoryLog
			title={`${T('default.openActivities')} (${totalEvents})`}
			loading={loading}
			hasPriorHistory={true}
			selectButtons={null}
			{...props}
		>
			{eventComponents}
			{eventComponents.length < totalEvents ? (
				<TimelineLoadMore
					newEventsLoading={loading && eventComponents.length > 0}
					numEventsLoaded={events.length}
					totalEvents={totalEvents ? totalEvents : null}
					incrementOffset={loadMore}
				/>
			) : null}
		</HistoryLog>
	);
};

export default OpenActivityHistoryLog;

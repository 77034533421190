'use strict';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';

angular.module('upDirectives').directive('upEventIcon', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventIcon.tpl.html?file'),
		require: 'upEventIcon',
		scope: {
			event: '=upEventIcon'
		},
		controller: [
			'$scope',
			function ($scope) {
				$scope.isPhoneType = function () {
					if (
						!(
							$scope.event.activity &&
							$scope.event.activity.activityType &&
							$scope.event.activity.activityType.name
						)
					) {
						return false;
					}

					var type = $scope.event.activity.activityType.name;
					return containsPhoneWords(type);
				};

				$scope.isPassedActivity = function () {
					if (
						$scope.event &&
						$scope.event.entityType === 'Activity' &&
						moment($scope.event.date).endOf('day').isBefore()
					) {
						return true;
					}
					return false;
				};
			}
		],
		link: function ($scope, elem, attr) {
			$scope.line = attr.line !== undefined;
		}
	};
});

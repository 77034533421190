'use strict';

import { default as ReduxComponentRoot } from 'Components/ReduxComponentRoot';

angular.module('upDirectives').directive('upListFilters', function () {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			activeFilters: '=',
			filterService: '=',
			updateFilter: '=',
			clearFilters: '=',
			quickSearch: '=',
			addButton: '=',
			secondButton: '=',
			linkButton: '=',
			selectModeBack: '=',
			opts: '=',
			tableType: '@',
			hideFilters: '=',
			hasChanged: '=',
			isDisabled: '=',
			quickDateFilterOptions: '=',
			quickDateFilterName: '@',
			quickPriorityFilterOptions: '=',
			quickPriorityFilterName: '@'
		},
		template: '<div id="up-list-filters"></div>',
		link: function ($scope, $element, $attrs) {
			let firstElement = $element[0];
			var renderTimeout;

			var mainContent = $element.parents('#main-content');
			mainContent.addClass('has-up-list-filters');

			var openOnInit = $attrs.openOnInit !== undefined && $attrs.openOnInit !== '';

			var render = function (activeFilters) {
				if (activeFilters && typeof activeFilters === 'object') {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					const opts = $scope.opts || {};

					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}
						var props = {
							opts: opts,
							activeFilters: activeFilters,
							quickSearch: $scope.quickSearch,
							addButton: $scope.addButton,
							secondButton: $scope.secondButton,
							linkButton: $scope.linkButton,
							filterService: $scope.filterService,
							showSearch: $attrs.showSearch !== undefined,
							tableType: opts.filterType || $scope.tableType,
							selectModeBack: $scope.selectModeBack,
							hideFilters: $scope.hideFilters,
							quickDateFilterOptions: $scope.quickDateFilterOptions,
							quickDateFilterName: $scope.quickDateFilterName,
							quickPriorityFilterOptions: $scope.quickPriorityFilterOptions,
							quickPriorityFilterName: $scope.quickPriorityFilterName,
							onChange: function (filter, options) {
								if (options && options.action === 'remove') {
									$scope.$root.$broadcast('filterCleared', filter);
								}
								$scope.updateFilter(filter, options);
							},
							hasChanged: $scope.hasChanged,
							isDisabled: $scope.isDisabled
						};

						if (openOnInit) {
							props.open = true;
							openOnInit = false;
						}

						props.component = ReactTemplates.upFilters.filterMenu;

						ReactDOM.render(React.createElement(ReduxComponentRoot, props), firstElement, function () {});
					}, 10);
				}
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$on('renderFilters', function () {
				render($scope.activeFilters);
			});

			$scope.$watch(
				'[activeFilters,linkButton]',
				function (watches) {
					render(watches[0]);
				},
				true
			);
		}
	};
});

import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../../../CriteriaRowWrapper';
import t from 'Components/Helpers/translate';
import Drilldown from './Drilldown';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import './Financials.scss';

class FinancialInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			lessOptions: t('default.lessOptions'),
			moreOptions: t('default.moreOptions')
		};

		this.toggleDrilldown = this.toggleDrilldown.bind(this);

		const { filter, config, registerDrilldown } = this.props;
		registerDrilldown(filter.filterName, Drilldown);

		const AppService = getAngularModule('AppService');
		this.currencies = AppService.getMetadata().customerCurrencies.filter(currency => currency.active);
		for (const currency of this.currencies) {
			currency.name = currency.id = currency.iso;
		}
		this.hasBaseCurrency = this.currencies.find(currency => currency.iso === config.baseCurrency);
	}

	toggleDrilldown() {
		const { filter, toggleDrilldown } = this.props;
		toggleDrilldown(filter.filterName);
	}

	onCurrencyChanged = event => {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);
		newFilter.value.currency = event.target.added;
		onChange(newFilter.value, newFilter);
	};

	render() {
		const { hasOpenDrilldown, filter } = this.props;
		const selectedCurrency = this.hasBaseCurrency
			? this.currencies.find(currency => currency.iso === filter.value.currency.iso)
			: null;

		return (
			<CriteriaRowWrapper rowFlex>
				<div className="criteria-row criteria-row--prospecting-financials">
					<div
						className="btn btn-link up-btn btn-bright-blue drilldown-btn btn-lg"
						onClick={this.toggleDrilldown}
					>
						{hasOpenDrilldown ? this.lang.lessOptions : this.lang.moreOptions}
					</div>
					{this.currencies.length > 1 && this.hasBaseCurrency ? (
						<ReactTemplates.INPUTS.upSelect
							className="form-control"
							data={this.currencies}
							required={true}
							multiple={false}
							onChange={this.onCurrencyChanged}
							defaultValue={selectedCurrency}
							options={{ minimumResultsForSearch: -1 }}
						/>
					) : null}
				</div>
			</CriteriaRowWrapper>
		);
	}
}

FinancialInput.propTypes = {
	registerDrilldown: PropTypes.func.isRequired,
	toggleDrilldown: PropTypes.func.isRequired,
	hasOpenDrilldown: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	locked: PropTypes.bool
};

export default FinancialInput;

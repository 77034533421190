import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Button, Icon, Text, Title, Checkbox } from '@upsales/components';
import './TemplateCard.scss';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import LazyLoadBackgroundImg from 'Components/Helpers/LazyLoadBackgroundImg';
import Label from 'App/resources/Model/Label';
import { MultiSelectCheckboxProps } from 'App/components/ListView/ListViewRenderHelpers';
import logError from 'Helpers/logError';

type Props = {
	subtitle?: string;
	title?: string;
	labels?: Label[];
	className?: string;
	canDelete?: boolean;
	canArchive?: boolean;
	onDelete?: () => Promise<void>;
	onArchive?: () => Promise<void>;
	onSelect?: React.MouseEventHandler;
	onPreview?: React.MouseEventHandler<HTMLButtonElement>;
	onEdit?: React.MouseEventHandler<HTMLButtonElement>;
	multiSelect?: MultiSelectCheckboxProps;
} & React.ComponentProps<typeof LazyLoadBackgroundImg>;

class TemplateCard extends React.PureComponent<Props> {
	lang: { [key: string]: string };
	onDelete: () => void;
	archive: () => void;

	constructor(p: Props) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			select: t('default.select'),
			thisActionIsIrreversable: t('default.thisActionIsIrreversable'),
			deleteThisTemplate: t('groupMail.deleteThisTemplate'),
			delete: t('default.delete'),
			edit: t('default.edit'),
			preview: t('templateCardPreview.preview')
		};

		this.onDelete = () => {
			this.setState({ deleting: true });
			this.props.onDelete?.().catch(() => {
				this.setState({ deleting: false });
			});
		};

		this.archive = () => {
			this.setState({ archiving: true });
			this.props.onArchive?.().catch(() => {
				this.setState({ archiving: false });
			});
		};
	}

	preview: React.MouseEventHandler<HTMLButtonElement> = e => {
		this.setState({ previewing: true });
		Promise.resolve(this.props.onPreview?.(e))
			.finally(() => {
				this.setState({ previewing: false });
			})
			.catch(e => logError(e, 'Failed to preview template'));
	};

	state = {
		delete: false,
		deleting: false,
		archiving: false,
		previewing: false
	};

	render() {
		// eslint-disable-next-line no-unused-vars
		const { onDelete, onArchive, ...usedProps } = this.props;
		const {
			className,
			subtitle,
			title,
			labels = [],
			canDelete,
			canArchive,
			onSelect,
			onPreview,
			onEdit,
			background,
			multiSelect,
			...props
		} = usedProps;
		const classes = new bemClass('TemplateCard', className);

		classes.mod({ delete: this.state.delete });

		const actions = [];

		if (onSelect) {
			actions.push(
				<Button key="onSelect" color="super-light-green" onClick={onSelect} block>
					{this.lang.select}
				</Button>
			);
		}
		if (onPreview) {
			actions.push(
				<Button
					key="onPreview"
					color="super-light-green"
					onClick={this.preview}
					block
					loading={this.state.previewing}
				>
					<Icon name="eye" space="mrm" />
					{this.lang.preview}
				</Button>
			);
		}
		if (onEdit) {
			actions.push(
				<Button key="onEdit" color="super-light-green" onClick={onEdit} block>
					<Icon name="edit" space="mrm" />
					{this.lang.edit}
				</Button>
			);
		}

		const hasLabels = labels.length > 0 ? true : false;

		return (
			<LazyLoadBackgroundImg background={background} {...props} className={classes.b()}>
				{multiSelect ? (
					<Checkbox
						className={classes.elem('multiSelect').mod({ checked: multiSelect.checked }).b()}
						size="sm"
						{...multiSelect}
					/>
				) : null}
				<div className={classes.elem('overlay').b()}>
					{canDelete ? (
						<Button
							color="white"
							type="link"
							className={classes.elem('delete').b()}
							onClick={e => {
								e.stopPropagation();
								this.setState({ delete: true });
							}}
						>
							<Icon name="trash" />
						</Button>
					) : null}
					{canArchive ? (
						<Button
							color="white"
							type="link"
							className={classes.elem('delete').b()}
							onClick={e => {
								e.stopPropagation();
								this.archive();
							}}
							loading={this.state.archiving}
						>
							<Icon name="archive" />
						</Button>
					) : null}
					{actions}
				</div>

				{title ? (
					<div className={classes.elem('text-wrap').mod({ hasLabels }).b()}>
						<div className={classes.elem('name-wrap').b()}>
							<Text color="black">{title}</Text>
							<Text color="grey-10" size="sm" className={classes.elem('subtitle').b()}>
								{subtitle}
							</Text>
						</div>
						{hasLabels ? (
							<div className={classes.elem('label-wrap').b()}>
								<Text color="grey-11" size="sm">
									<Icon name="tag" space="mrm" /> {labels.map(label => label.name).join(', ')}
								</Text>
							</div>
						) : null}
					</div>
				) : null}

				{canDelete ? (
					<div className={classes.elem('delete-confirm').b()}>
						<div className={classes.elem('delete-confirm-inner').b()}>
							<Button
								color="grey"
								type="link"
								className={classes.elem('delete').b()}
								onClick={e => {
									e.stopPropagation();
									this.setState({ delete: false });
								}}
							>
								<Icon name="times" />
							</Button>

							<Title>{this.lang.deleteThisTemplate}</Title>
							<Text color="grey-10">{this.lang.thisActionIsIrreversable}</Text>
							<Button
								block
								color="red"
								onClick={e => {
									e.stopPropagation();
									this.onDelete();
								}}
								loading={this.state.deleting}
							>
								{this.lang.delete}
							</Button>
						</div>
					</div>
				) : null}
			</LazyLoadBackgroundImg>
		);
	}
}

export default TemplateCard;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './FullScreenPage.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'App/babel/helpers/promise';
import delayedUnmount from 'Components/Helpers/delayedUnmount';
import EditorHeader from 'Components/EditorHeader';

class FullScreenPage extends React.Component {
	constructor(props) {
		super(props);
		this.portalRoot = document.getElementById('react-root');
		this.el = document.createElement('div');
		this.state = { mounted: false };
	}

	componentDidMount() {
		this.portalRoot.appendChild(this.el);

		document.getElementsByTagName('body')[0].style.overflow = 'hidden';

		this._mountedPromise = makeCancelable(
			new Promise(r => {
				setTimeout(() => {
					r();
				}, 0);
			})
		);
		this._mountedPromise.promise
			.then(() => {
				this.setState({ mounted: true });
			})
			.catch(() => {});
	}

	componentWillUnmount() {
		this._mountedPromise.cancel();
		this.portalRoot.removeChild(this.el);

		document.getElementsByTagName('body')[0].style.overflow = null;
	}

	render() {
		const {
			className,
			isMounted,
			children,
			title,
			subtitle,
			onAbort,
			onAbortTitle,
			onConfirm,
			onConfirmTitle,
			onConfirmSupertitle,
			onBack,
			...props
		} = this.props;

		const classes = new bemClass('FullScreenPage', className).mod({
			open: isMounted && this.state.mounted
		});
		return ReactDOM.createPortal(
			<div {...props} className={classes.b()}>
				{/* Passing a title will add the header, if you want a more custom header - add it as a child */}
				{title ? (
					<EditorHeader
						className={classes.elem('header').b()}
						title={title}
						subtitle={subtitle}
						onAbort={onAbort}
						onAbortTitle={onAbortTitle}
						onConfirm={onConfirm}
						onConfirmTitle={onConfirmTitle}
						onConfirmSupertitle={onConfirmSupertitle}
						onBack={onBack}
					></EditorHeader>
				) : null}
				{children}
			</div>,
			this.el
		);
	}
}

FullScreenPage.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.any,
	loading: PropTypes.bool,
	error: PropTypes.bool,
	onAbort: PropTypes.func,
	onAbortTitle: PropTypes.string,
	onConfirm: PropTypes.func,
	onConfirmTitle: PropTypes.string,
	onConfirmSupertitle: PropTypes.string,
	isMounted: PropTypes.bool.isRequired,
	onBack: PropTypes.func
};

FullScreenPage.defaultProps = {
	isMounted: true
};

export default delayedUnmount(FullScreenPage);

import { Block, Button, Icon, Text } from '@upsales/components';
import React from 'react';
import T from 'Components/Helpers/translate';
import './LoginError.scss';
import BemClass from '@upsales/components/Utils/bemClass';

export enum LoginErrors {
	CREDENTIALS = 'CREDENTIALS',
	FORCE_RESET_PASSWORD = 'FORCE_RESET_PASSWORD',
	ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
	IP_BLOCKED = 'IP_BLOCKED',
	NO_VALID_BRAND = 'NO_VALID_BRAND',
	MISSING_INDEX_TRIAL = 'MISSING_INDEX_TRIAL',
	MISSING_INDEX = 'MISSING_INDEX',
	REQUEST_PASSWORD_RESET_ERROR = 'REQUEST_PASSWORD_RESET_ERROR',
	RESET_PASSWORD_HASH_INVALID = 'RESET_PASSWORD_HASH_INVALID',
	PASSWORDS_NOT_MATCHING = 'PASSWORDS_NOT_MATCHING',
	PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
	PASSWORD_CAPITAL = 'PASSWORD_CAPITAL',
	PASSWORD_NUMBER = 'PASSWORD_NUMBER',
	INVALID_2FA = 'INVALID_2FA',
	OUTDATED_2FA = 'OUTDATED_2FA',
	NO_PHONE_2FA = 'NO_PHONE_2FA',
	UNKNOWN = 'UNKNOWN'
}

type Props = {
	error: null | LoginErrors | LoginErrors[];
	onClear?: () => void;
};

const parseError = (error: LoginErrors) => {
	switch (error) {
		case LoginErrors.IP_BLOCKED:
			return { title: 'default.accessDenied', message: 'login.ipBlocked', color: 'danger-5' };
		case LoginErrors.ACCOUNT_LOCKED:
			return { title: 'default.accessDenied', message: 'login.accountLockedThrottle', color: 'danger-5' };
		case LoginErrors.UNKNOWN:
			return { title: 'default.error', message: 'login.anErrorOccured', color: 'danger-5' };
		case LoginErrors.NO_VALID_BRAND:
			return { title: 'default.accessDenied', message: 'login.noValidBrand', color: 'danger-5' };
		case LoginErrors.MISSING_INDEX:
			return { title: 'default.error', message: 'login.missingIndex', color: 'bright-blue' };
		case LoginErrors.MISSING_INDEX_TRIAL:
			return { title: 'login.missingIndexTrial', message: 'login.missingIndexTrial2', color: 'bright-blue' };
		case LoginErrors.REQUEST_PASSWORD_RESET_ERROR:
			return { title: 'default.warning', message: 'login.sendResetLinkFailed', color: 'danger-5' };
		case LoginErrors.RESET_PASSWORD_HASH_INVALID:
			return { title: 'default.warning', message: 'login.resetHashInvalid', color: 'danger-5' };
		case LoginErrors.PASSWORDS_NOT_MATCHING:
			return { title: 'default.warning', message: 'admin.passwordNoMatch', color: 'danger-5' };
		case LoginErrors.PASSWORD_TOO_SHORT:
			return {
				title: 'default.warning',
				message: 'resetPassword.rule.atleastEightCharactersError',
				color: 'danger-5'
			};
		case LoginErrors.PASSWORD_CAPITAL:
			return {
				title: 'default.warning',
				message: 'resetPassword.rule.containCapitalAndNonCapitalError',
				color: 'danger-5'
			};
		case LoginErrors.PASSWORD_NUMBER:
			return { title: 'default.warning', message: 'resetPassword.rule.containNumberError', color: 'danger-5' };
		case LoginErrors.INVALID_2FA:
		case LoginErrors.OUTDATED_2FA:
			return { title: 'default.warning', message: 'login.smsCodeError', color: 'danger-5' };
		case LoginErrors.NO_PHONE_2FA:
			return { title: 'default.warning', message: 'login.twoFactorNoPhone', color: 'danger-5' };
		case LoginErrors.FORCE_RESET_PASSWORD:
			// This error is never rendered, it results in a different view on the login page
			return { title: '', message: '', color: 'danger-5' };
		case LoginErrors.CREDENTIALS:
		default:
			return { title: 'default.warning', message: 'login.credentialsError', color: 'yellow-3' };
	}
};

export default function LoginError({ error, onClear }: Props) {
	const classes = new BemClass('LoginError');

	if (!error) {
		return null;
	}

	const { title, message, color } = parseError(Array.isArray(error) ? error[0] : error);
	const textColor = color === 'yellow-3' ? 'yellow-6' : 'white';

	return (
		<Block backgroundColor={color} borderRadius space="mtl ptl pbl pll prl">
			<Text color={textColor} bold>
				{T(title)}
			</Text>
			{Array.isArray(error) ? (
				error.map(e => {
					const { message } = parseError(e);
					return (
						<Text key={e} color={textColor}>
							{T(message)}
						</Text>
					);
				})
			) : (
				<Text color={textColor}>{T(message)}</Text>
			)}
			{onClear ? (
				<Button type="link" color={textColor} onClick={onClear} className={classes.elem('clear-error').b()}>
					<Icon name="times" />
				</Button>
			) : null}
		</Block>
	);
}

import T from 'Components/Helpers/translate';
import { Button, Card, DropDownMenu, Icon, Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';

import './FolderModal.scss';

type Props = {
	onClick: (name: string) => void;
	startName?: string;
	className?: string;
	animate?: boolean;
	buttonText?: string;
	noDropDown?: boolean;
};

const FolderModal = ({
	onClick,
	startName = '',
	className = '',
	animate = false,
	buttonText = T('file.addFolder'),
	noDropDown = true
}: Props) => {
	const classes = new BemClass('FolderModal', className);
	const [name, setName] = useState(startName);

	const content = (close = () => {}) => (
		<Card className={classes.mod({ animate }).b()}>
			<Input
				autoFocus
				onChange={e => setName(e.target.value)}
				value={name}
				placeholder={T('default.name')}
				onKeyDown={e => {
					if (e.key === 'Enter' && !e.shiftKey && name?.length) {
						e.preventDefault();
						onClick(name);
						setName('');
						close();
					}
				}}
			/>
			<Button
				disabled={!name.length}
				onClick={() => {
					onClick(name);
					close();
				}}
			>
				{buttonText}
			</Button>
		</Card>
	);

	if (noDropDown) {
		return content();
	}

	return (
		<DropDownMenu
			align="right"
			arrowed={false}
			useAnimation
			noMinWidth
			renderTrigger={(expanded, setExpanded) => (
				<Icon onClick={setExpanded} className={classes.elem('add-icon').b()} name="folder" />
			)}
			onClose={() => setName('')}
		>
			{setClosed => content(setClosed)}
		</DropDownMenu>
	);
};

export default FolderModal;

'use strict';

angular.module('upAttributes').service('FileAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				type: {
					title: 'default.type',
					field: 'type',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				entity: {
					title: 'default.entity',
					field: 'entity',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				entityId: {
					title: 'default.entityId',
					field: 'entityId',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				account: {
					title: 'default.account',
					field: 'client',
					type: FilterType.String,
					displayType: DisplayType.String,
					attr: {
						id: {
							field: 'client.id'
						}
					}
				},
				uploadDate: {
					field: 'uploadDate',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				filename: {
					title: 'default.filename',
					field: 'filename',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				mimetype: {
					title: 'default.mimetype',
					field: 'mimetype',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				size: {
					title: 'default.size',
					field: 'size',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					attr: {
						id: {
							field: 'user.id'
						},
						name: {
							field: 'user.name'
						}
					}
				}
			};

			var standardFilters = {};

			var requiredFields = [];

			return {
				getAll: function () {
					return attributes;
				},
				standardFilters: standardFilters,
				attr: attributes,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					ENTITY: 'entity',
					ENTITYID: 'entityId',
					ACCOUNT: 'account',
					UPLOADDATE: 'uploadDate',
					FILENAME: 'filename',
					MIMETYPE: 'mimetype'
				}
			};
		};
	}
]);

'use strict';

angular.module('upResources').factory('OrderStage', [
	'$resource',
	'$q',
	'OrderStageAttributes',
	'URL',
	'API',
	'CacheService',
	'RequestBuilder',
	'ParseGeneric',
	'ResourceHelper',
	function OrderStage(
		$resource,
		$q,
		OrderStageAttributes,
		URL,
		API,
		CacheService,
		RequestBuilder,
		ParseGeneric,
		ResourceHelper
	) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'orderStage',
			createSuccessBody: 'saved.orderStage',
			updateSuccessBody: 'updated.orderStage',
			deleteSucccessBody: 'deleted.orderStage',
			createErrorBody: 'saveError.orderStage',
			updateErrorBody: 'saveError.orderStage',
			deleteErrorBody: 'deleteError.orderStage'
		});

		var Attribute = OrderStageAttributes().attr;

		var Model = {
			customer: function (customer) {
				var instance = {};
				var OrderStage = null;
				var Cache = CacheService.getCache(CacheService.cacheTypes.ORDERSTAGE);
				instance.type = null;
				OrderStage = $resource(
					URL + API + customer + '/orderstages/:id',
					{},
					{
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseOrderStage', { isArray: false })
						},
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseOrderStage', { isArray: true })
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric('ParseOrderStage', { isArray: false })
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric('ParseOrderStage', { isArray: false })
						}
					}
				);

				instance.find = function (filter, options) {
					var defered = $q.defer();
					var params = _.extend(filter, options) || {};

					if (instance.type) {
						switch (instance.type) {
							case 'order':
							default:
								if (!params.q) {
									params.q = [];
								}
								params.q.push({ a: 'probability', c: 'eq', v: 0 });
								params.q.push({ a: 'probability', c: 'eq', v: 100 });
								break;

							case 'opportunity':
								if (!params.q) {
									params.q = [];
								}
								params.q.push({ a: 'probability', c: 'ne', v: 0 });
								params.q.push({ a: 'probability', c: 'ne', v: 100 });

								break;
						}
					}

					OrderStage.find(params, function (response) {
						defered.resolve(response);
					});

					instance.type = null;

					return defered.promise;
				};

				instance.get = function (id) {
					var defered = $q.defer();

					OrderStage.get({ id: id }, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.getCached = function () {
					var type = instance.type;
					var key = 'OrderStage.' + type;
					var cached = Cache.get(key);

					if (cached) {
						return $q.when(cached);
					} else {
						var filter = RequestBuilder();
						filter.addSort(Attribute.name, true);
						return instance.find(filter.build()).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				instance.save = function (stage, options) {
					var params = {},
						method;

					if (stage.id) {
						params.id = stage.id;
						method = 'update';
					} else {
						method = 'create';
					}

					return OrderStage[method](params, stage)
						.$promise.then(function (res) {
							helper.onSave(options, res.data, !stage.id);
							return res;
						})
						.catch(function (err) {
							helper.onSaveError(options, !stage.id);
							return $q.reject(err);
						});
				};

				instance['delete'] = function (orderStage, options) {
					return OrderStage.delete({ id: orderStage.id })
						.$promise.then(function (res) {
							helper.onDelete(options, orderStage);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options, err);
							return $q.reject(err);
						});
				};

				instance.setType = function (type) {
					instance.type = type;
					return instance;
				};

				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);

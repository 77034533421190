import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

ReactTemplates.form.formDashboardTopbar = window.ReactCreateClass({
	getInitialState: function () {
		return {
			hasCopied: false,
			editing: this.props.editing
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			preview: t('default.preview')
		};
	},
	componentWillUnmount: function () {
		window.removeEventListener('resize', this.renderPreview);
	},
	componentDidMount: function () {
		window.addEventListener('resize', this.renderPreview);
		this.renderPreview();
	},
	componentDidUpdate: function () {
		this.renderPreview();
	},
	renderPreview: function () {
		if (!this.props.isLandingPage) {
			var iframe = this._preview;
			var parentWidth = iframe.parentNode.clientWidth;
			var scale = parentWidth / 1024;
			iframe.style.transform = 'scale(' + scale + ')';

			if (this.props.html && iframe.contentWindow) {
				iframe.contentWindow.document.open();
				iframe.contentWindow.document.write(this.props.html);
				iframe.contentWindow.document.close();
			}
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	openLoading: function () {
		window.open(this.props.url, '_blank');
	},
	copyToClipboard: function () {
		var self = this;
		ReactDOM.findDOMNode(this._hiddenScript).select();

		try {
			document.execCommand('copy');
			this.setState({
				hasCopied: true
			});

			setTimeout(function () {
				self.setState({
					hasCopied: false
				});
			}, 5000);
		} catch (err) {
			console.error(err);
			// Do nothing
		}
	},
	render: function () {
		var self = this;
		var numberFormat = self.props.tools.$filter('numberFormat');
		var statics = self.props.statics;

		var topBarClasses = 'top-bar';
		var previewClasses = 'preview';
		var visitWidget = null;
		var widgetClasses = 'key';

		var graph = null;
		if (this.props.graphConfig && !this.props.noData) {
			graph = (
				<div>
					{React.createElement(ReactTemplates.form.formGraph, {
						config: self.props.graphConfig
					})}
					<div className="x-axis-line"></div>
				</div>
			);
		} else {
			graph = (
				<div className="no-data">
					<div className="no-data-inner">{self.props.noVisitOrSubmitLabel}</div>
				</div>
			);
		}

		if (!self.props.loading) {
			topBarClasses += ' has-loading';
			previewClasses += ' has-loading';
			visitWidget = (
				<div className={widgetClasses}>
					<div className="title">
						<span>
							<i className="fa fa-eye"></i>
						</span>
						<span>{numberFormat(self.props.form.views)}</span>
					</div>
					<div className="subtitle">{statics.nrOfVisits}</div>
				</div>
			);
		} else {
			widgetClasses += ' no-visit';
		}

		var eye = null;
		if (!this.props.isVisitHidden && !this.props.noData) {
			eye = (
				<div className="right-icon">
					<i className="fa fa-eye"></i>
				</div>
			);
		}
		var graphComponent = (
			<div className="graph">
				{eye}
				{graph}
			</div>
		);

		if (self.props.loading) {
			graphComponent = (
				<div className="graph-spinner">
					<i className="fa fa-spin fa-refresh spinner"></i>
				</div>
			);
		}

		var buttonClassNames = 'btn up-btn btn-bright-blue btn-lined no-shadow';
		var OutsideClick = window.OutsideClick;

		return (
			<div>
				<div className={topBarClasses}>
					<div className="key-values">
						{visitWidget}
						<div className={widgetClasses}>
							<div className="title">
								<span>
									<i className="fa fa-mouse-pointer blue"></i>
								</span>
								<span>{numberFormat(self.props.totalSubmits)}</span>
							</div>
							<div className="subtitle">{statics.nrOfSubmits}</div>
						</div>
						<div className={widgetClasses}>
							<div className="title">
								<span>
									<i className="fa fa-tachometer"></i>
								</span>
								<span>{numberFormat(self.props.totalScore)}</span>
							</div>
							<div className="subtitle">{statics.createdScore}</div>
						</div>
					</div>
					{graphComponent}
				</div>
				{self.props.isLandingPage ? (
					<div className={previewClasses}>
						<div className="social-med-holder">
							<OutsideClick
								outsideClick={function () {
									self.setState({
										editing: false
									});
								}}
								targetClass="social-media-preview"
							>
								<ReactTemplates.form.socialmediaPreview
									form={self.props.form}
									url={self.props.url}
									isLandingPage={self.props.isLandingPage}
									saveTags={self.props.saveTags}
									editing={self.state.editing}
								/>
							</OutsideClick>

							<div className="button-wrapper">
								<div>
									<a
										target="_blank"
										href={self.props.url}
										className="btn up-btn btn-bright-blue no-shadow"
										style={{
											width: 'calc(60% - 20px)'
										}}
									>
										{Tools.$translate('landingpage.visit.page')}
									</a>
									<button
										className={
											self.state.hasCopied ? buttonClassNames + ' done-copy' : buttonClassNames
										}
										style={{
											width: 'calc(40% - 20px)'
										}}
										type="button"
										onClick={self.copyToClipboard}
									>
										{self.state.hasCopied
											? Tools.$translate('default.copiedToClipboard')
											: Tools.$translate('landingpage.copyLink')}
										{self.state.hasCopied && <i className="fa fa-check" />}
									</button>
									<input
										type="text"
										ref={self.setRef.bind(self, '_hiddenScript')}
										className="hidden-input"
										value={self.props.url}
									/>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className={previewClasses + ' old-form-preview'}>
						<div className="text-grey text-center" style={{ height: '25px', lineHeight: '25px' }}>
							{self.lang.preview}
						</div>
						<div className="curtain"></div>
						<iframe
							ref={self.setRef.bind(self, '_preview')}
							frameBorder="0"
							scrolling="no"
							name="preview_dashboard"
							sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-modals"
						></iframe>
					</div>
				)}
			</div>
		);
	}
});

ReactTemplates.form.socialmediaPreview = window.ReactCreateClass({
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	getStateFromProps: function (props) {
		var form = _.cloneDeep(props.form);

		if (form.hasOwnProperty('socialMediaTags') === false) {
			form.socialMediaTags = null;
		}

		if (typeof form.socialMediaTags === 'string') {
			form.socialMediaTags = JSON.parse(form.socialMediaTags);
		}

		if (form.socialMediaTags && form.socialMediaTags.values) {
			var smValue = form.socialMediaTags.values;

			return {
				name: smValue.title || smValue.name || this.getTitle(form.html) || form.name,
				desc: smValue.desc || this.getDescription(form.html),
				img: smValue.img || this.getImage(form.landingPageBody, true),
				metaTags: form.socialMediaTags.tags || [],
				editing: false,
				focus: null,
				form: form
			};
		}

		if (form.socialMediaTags && Array.isArray(form.socialMediaTags)) {
			return {
				name: form.name || this.getTitle(form.html),
				desc: this.getDescription(form.html),
				img: this.getImage(form.landingPageBody, true),
				metaTags: form.socialMediaTags || [],
				editing: false,
				focus: null,
				form: form
			};
		}

		return {
			name: form.name || this.getTitle(form.html),
			desc: this.getDescription(form.html),
			img: this.getImage(form.landingPageBody, true),
			metaTags: [],
			editing: false,
			focus: null,
			form: form
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var state = this.state;

		if (this.state.editing && this.state.editing !== nextProps.editing) {
			var form = _.cloneDeep(this.props.form);
			form.socialMediaTags = {
				tags: state.metaTags,
				values: {
					name: state.name,
					img: state.img,
					desc: state.desc
				},
				wasSetManually: true
			};

			this.props.saveTags(form);
		} else {
			this.setState(this.getStateFromProps(nextProps));
		}

		this.setState({
			editing: nextProps.editing
		});
	},
	generateSocialMediaArrays: function (state) {
		var props = this.props;

		var socialMediaArray = [];

		socialMediaArray.push('<meta property="og:title" content="' + state.name + '" />');
		socialMediaArray.push('<meta property="og:url" content="' + props.url + '" />');
		socialMediaArray.push('<meta property="og:image" content="' + encodeURI(state.img) + '" />');
		socialMediaArray.push('<meta property="og:description" content="' + state.desc + '" />');

		socialMediaArray.push('<meta name="twitter:card" content="summary">');
		socialMediaArray.push('<meta name="twitter:title" content="' + state.name + '">');
		socialMediaArray.push('<meta name="twitter:description" content="' + state.desc + '">');
		socialMediaArray.push('<meta name="twitter:image:src" content="' + encodeURI(state.img) + '">');

		return socialMediaArray;
	},
	click: function (key) {
		this.setState({
			editing: !this.state.editing,
			focus: key
		});
	},
	componentDidMount: function () {
		var metaTags = this.generateSocialMediaArrays(this.state);
		this.setState({
			metaTags: metaTags
		});
	},
	rebuildState: function (key, val, shouldSave) {
		var state = _.cloneDeep(this.state);
		state[key] = val;

		var metaTags = this.generateSocialMediaArrays(state);
		state.metaTags = metaTags;

		this.setState(state);
		if (shouldSave) {
			var form = _.cloneDeep(this.props.form);
			form.socialMediaTags = {
				tags: state.metaTags,
				values: {
					name: state.name,
					img: state.img,
					desc: state.desc
				},
				wasSetManually: true
			};

			this.onSave(form);
		}
	},
	onChange: function (key, e) {
		var state = _.cloneDeep(this.state);

		state[key] = e.target.value;

		var metaTags = this.generateSocialMediaArrays(state);
		state.metaTags = metaTags;

		this.setState(state);
	},
	onSave: function (form) {
		this.props.saveTags(form);
		this.setState({
			editing: false
		});
	},
	keyDown: function (e) {
		var self = this;

		if (e.keyCode === 13) {
			e.preventDefault();

			var form = _.cloneDeep(self.props.form);
			form.socialMediaTags = {
				tags: self.state.metaTags,
				values: {
					name: self.state.name,
					img: self.state.img,
					desc: self.state.desc
				},
				wasSetManually: true
			};

			self.onSave(form);
		}
	},
	getImage: function (body, returnOne) {
		if (!body && this.props.isLandingPage) {
			body = this.props.form.landingPageBody;
		}

		if (this.props.isLandingPage) {
			var allImages = [];

			var img = _.find(body.rows, function (row) {
				if (row.background.img !== null) {
					allImages.push(row.background.img);
				}

				return row.background.img;
			});

			if (!img) {
				return '';
			}

			if (returnOne) {
				return img.background.img;
			}

			var acceptedFileTypes = ['gif', 'jpg', 'png'];
			_.each(body.rows, function (row) {
				_.each(row.elements, function (elm) {
					if (elm.type === 'image' && acceptedFileTypes.indexOf(elm.value.src.substr(-3)) > -1) {
						allImages.push(elm.value.src);
					}
				});

				_.each(row.columns, function (col) {
					_.each(col.elements, function (elm) {
						if (elm.type === 'image' && acceptedFileTypes.indexOf(elm.value.src.substr(-3)) > -1) {
							allImages.push(elm.value.src);
						}
					});
				});
			});

			return allImages;
		}
	},
	getTitle: function (markdown) {
		var cleaned = [];
		var matches = markdown.match(/<h1(.*)>(.*)<\/h1>/g);
		_.each(matches, function (row) {
			row = row.replace(new RegExp('<[^>]*>', 'g'), '');
			cleaned.push(row);
		});

		return cleaned[0];
	},
	getDescription: function (html) {
		var cleaned = [];
		var matchesH3 = html.match(/<h3(.*)>(.*)<\/h3>/g);
		var filteredMatchesH3 = _.filter(matchesH3, function (item) {
			return item !== '<h3>&nbsp;</h3>';
		});

		_.each(filteredMatchesH3, function (row) {
			row = row.replace(new RegExp('<[^>]*>', 'g'), '');
			cleaned.push(row);
		});

		var matches = html.match(/<p(.*)>(.*)<\/p>/g);
		var filteredMatches = _.filter(matches, function (item) {
			return item !== '<p>&nbsp;</p>';
		});

		_.each(filteredMatches, function (row) {
			row = row.replace(new RegExp('<[^>]*>', 'g'), '');
			cleaned.push(row);
		});

		return cleaned[0];
	},
	createInput: function (text, key) {
		var self = this;

		if (key === 'desc') {
			return (
				<textarea
					type="text"
					className="inline-inputs"
					value={text}
					onKeyDown={self.keyDown}
					onChange={function (e) {
						self.onChange(key, e);
					}}
				></textarea>
			);
		}

		return (
			<input
				type="text"
				className="inline-inputs"
				value={text}
				onKeyDown={self.keyDown}
				onChange={function (e) {
					self.onChange(key, e);
				}}
			/>
		);
	},
	openPictureModal: function () {
		var self = this;

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('fileBrowser', {
				types: ['image'],
				public: true,
				extraFieldUploadedRow: [
					{
						title: window.Tools.$translate('form.landingPage.usedOnPage'),
						images: self.getImage() || []
					}
				]
			})
			.then(function (files) {
				var file = files;
				if (Array.isArray(files)) {
					file = files[0];
				}

				setTimeout(function () {
					if (typeof file === 'object' && file.$$publicUrl) {
						self.rebuildState('img', file.$$publicUrl, true);
					} else {
						self.rebuildState('img', file, true);
					}
				}, 900);
			});
	},
	selectPicture: function () {
		var self = this;

		return (
			<div className="show-on-hover">
				<div className="curtain"></div>
				<button className="btn up-btn btn-bright-blue no-shadow" onClick={self.openPictureModal}>
					{Tools.$translate('landingpage.changePreviewImage')}
				</button>
			</div>
		);
	},
	resetTags: function (e) {
		e.stopPropagation();
		e.preventDefault();

		var form = _.cloneDeep(this.props.form);
		form.socialMediaTags = getAngularModule('$templateParser').generateSocialMediaTagsObject(
			form,
			{ name: form.name || form.title },
			form.domain,
			form.urlName
		);
		this.onSave(form);
	},
	render: function () {
		var self = this;
		var form = this.props.form;
		var landingPageBody = form.landingPageBody;

		if (self.props.isLandingPage) {
			self.generateSocialMediaArrays(landingPageBody);
		}

		return (
			<div>
				<h2>
					{window.Tools.$translate('social.media.preview')}
					<ReactTemplates.elevio articleId="909" />
				</h2>
				<div className="social-media-preview">
					<div className="image">
						{self.selectPicture()}
						<img src={self.state.img} />
					</div>
					<div className="text-bar">
						<span
							onClick={function () {
								if (!self.state.editing) {
									self.click('name');
								}
							}}
							className={self.state.editing ? 'share-title editing' : 'share-title'}
						>
							{self.state.editing
								? self.createInput(self.state.name, 'name')
								: self.state.name.length > 87
								? self.state.name.substr(0, 87) + '..'
								: self.state.name}
							<span className="editing-text">
								{!self.state.editing &&
								self.props.form.socialMediaTags &&
								self.props.form.socialMediaTags.wasSetManually ? (
									<span onClick={self.resetTags}>{window.Tools.$translate('form.resetTags')} </span>
								) : null}
								{!self.state.editing && <span>{window.Tools.$translate('default.edit')}</span>}
							</span>
						</span>
						<span
							onClick={function () {
								if (!self.state.editing) {
									self.click('desc');
								}
							}}
							className={self.state.editing ? 'share-desc editing' : 'share-desc'}
						>
							{self.state.editing
								? self.createInput(self.state.desc, 'desc')
								: self.state.desc.length > 147
								? self.state.desc.substr(0, 147) + '..'
								: self.state.desc}
						</span>
						<span className="share-url">{self.props.url}</span>
					</div>
				</div>
			</div>
		);
	}
});

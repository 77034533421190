import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Prospecting from 'Resources/Prospecting';
import logError from 'App/babel/helpers/logError';

type ProspctingIdMap = { [prospectingId: string]: number };
type ProspectingResult = { data: { prospectingId: string; groupSize: number }[] };

export class GroupSizeBatchFetcher {
	waitTime: number = 50;
	promise: null | Promise<{ [prospectingId: string]: number }> = null;
	prospectingIds: Set<string> = new Set();

	private async batchFetch() {
		try {
			await new Promise(resolve => setTimeout(resolve, this.waitTime));
			this.promise = null;

			const countries = [...this.prospectingIds].reduce<{ [country: string]: string[] }>(
				(groupedProspectingIds, prospectingId) => {
					const country = Prospecting.getCountryFromProspectingId(prospectingId);
					if (country) {
						if (groupedProspectingIds[country]) {
							groupedProspectingIds[country].push(prospectingId);
						} else {
							groupedProspectingIds[country] = [prospectingId];
						}
					}
					return groupedProspectingIds;
				},
				{}
			);
			this.prospectingIds.clear();

			const promises = Object.entries(countries).map(([country, prospectingIds]) => {
				const rb = new RequestBuilder();
				rb.fields = ['groupSize'];
				rb.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, prospectingIds);
				rb.extraParams.push({ key: 'country', value: country });
				return Prospecting.find(rb.build());
			});

			const result: ProspectingResult[] = await Promise.all(promises);
			return result.reduce<ProspctingIdMap>(
				(prospectingIdMap, { data }) =>
					data.reduce<ProspctingIdMap>(
						(prospectingIdMap, { prospectingId, groupSize }) => (
							(prospectingIdMap[prospectingId] = groupSize), prospectingIdMap
						),
						prospectingIdMap
					),
				{}
			);
		} catch (error) {
			logError(error, 'GroupTreeColumn: Failed to batch find groupSize');
			return {};
		}
	}

	getGroupSize(prospectingId: string) {
		if (!this.promise) {
			this.promise = this.batchFetch();
		}
		this.prospectingIds.add(prospectingId);
		return this.promise.then(prospectindIdMap => prospectindIdMap[prospectingId] ?? 1);
	}
}

'use strict';

angular.module('domain.soliditet').controller('clientListModal', [
	'$scope',
	'$modalParams',
	'$state',
	'AppService',
	function ($scope, $modalParams, $state, AppService) {
		var Ctrl = this;
		var customerId = AppService.getCustomerId();
		Ctrl.clients = $modalParams.clients;

		this.goToClient = function (id) {
			$state.go('account.dashboard', { id: id, customerId: customerId });
		};
	}
]);

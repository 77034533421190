/**
 * WTF! kan vi inte göra ETT anrop istället göra 13 request varav 4 måste köas?
 *
 *
 * NEJ det kan vi inte!
 */

import logError from 'App/babel/helpers/logError';
import SalesCoach from 'Resources/SalesCoach';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { getSourceEntity, getSourceResource, onSourceClick, getSourceName } from 'Components/Helpers/SourceHelper';
import { get } from 'lodash';
import Product from 'Resources/Product';
import FieldTranslations from 'Resources/FieldTranslations';
import getSpeedTracker from 'App/helpers/speedTracker';

angular.module('domain.order').factory('EditOrderMeta', [
	'$q',
	'RequestBuilder',
	'SalesReport',
	'Account',
	'Order',
	'Contact',
	'ActivityList',
	'AppService',
	'FeatureHelper',
	'File',
	'NotificationService',
	function (
		$q,
		RequestBuilder,
		SalesReport,
		Account,
		Order,
		Contact,
		ActivityList,
		AppService,
		FeatureHelper,
		File,
		NotificationService
	) {
		return function ($modalParams) {
			if ($modalParams.id) {
				const speedTracker = getSpeedTracker('EditOrder', true);
				speedTracker?.startTracking();
			}
			return AppService.loadedPromise.then(function () {
				var params = $modalParams;
				var customerId = AppService.getCustomerId();
				const metadata = AppService.getMetadata();
				var contactId = params.contactId;
				var stageId = params.stageId;
				var clientId = params.clientId;
				var orderId = params.id;
				var copyId = params.copy;

				const isCopiedFromAppFramework =
					FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_UI_ELEMENTS_NEW_OPPORTUNITY') && params.order;

				if (isCopiedFromAppFramework) {
					contactId = contactId || params.order.contact?.id;
					stageId = stageId || params.order.stage?.id;
					clientId = clientId || params.order.client?.id;
					copyId = copyId || params.order.id;
					params.campaign = params.campaign || params.order.project;
					$modalParams.notes = $modalParams.notes || params.order.notes;

					params.order.closeDate = undefined;
					params.order.competitorId = undefined;
					params.order.lostReason = undefined;
					params.order.periodization = undefined;
				}

				var promises;
				var missingAccountRights = false;
				var missingContactRights = false;

				// Defers
				var getClient = $q.defer();
				var getActivities = $q.defer();
				var getContact = $q.defer();
				var getOrder = $q.defer();
				const getProducts = $q.defer();
				var getSalesReport = $q.defer();

				var type = $modalParams.type || 'order';

				var edit = !!orderId;
				var copy = !!copyId;

				if (copy) {
					orderId = copyId;
				}

				var getNewOrder = function (user, stage, project, contact) {
					var order = FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_UI_ELEMENTS_NEW_OPPORTUNITY')
						? Order.new(params.order)
						: Order.new();

					order.user = user;
					order.stage = stage;
					order.project = project;

					if (user?.role?.id && metadata.params.MultiCurrency && metadata.defaultCurrency) {
						order.currency = metadata.defaultCurrency.iso;
						order.currencyRate = metadata.defaultCurrency.rate;
					}

					if (contact) {
						order.contact = contact;
					}

					return order;
				};

				const salesCoachFilter = new RequestBuilder();
				salesCoachFilter.addFilter({ field: 'active' }, comparisonTypes.Equals, true);

				// Setup base promises
				promises = {
					salesCoaches: SalesCoach.find(salesCoachFilter.build()),
					type
				};

				const clientRelationField = metadata.standardFields?.Order?.clientRelationField ?? {};
				const shouldFetchClientOrderRelationTranslations =
					FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && clientRelationField;

				if (shouldFetchClientOrderRelationTranslations) {
					promises.clientOrderRelationTranslations = FieldTranslations.find({
						type: 'clientorderrelation',
						allTranslations: true
					});
				}

				const hasSpeedEditOrderInit = Tools.FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_INIT');

				// Setup promises if edit
				if (edit || copy || isCopiedFromAppFramework) {
					promises.order = getOrder.promise;
					promises.contact = getContact.promise;
					promises.salesReport = getSalesReport.promise;
					promises.activities = getActivities.promise;
					promises.products = getProducts.promise;
					if (!isCopiedFromAppFramework || (isCopiedFromAppFramework && clientId)) {
						promises.client = getClient.promise;
					}

					const fetchActivities = () => {
						if (!copy) {
							var activityFilter = new RequestBuilder();
							activityFilter.addFilter(
								ActivityList.attr.opportunity.attr.id,
								activityFilter.comparisonTypes.Equals,
								orderId
							);
							activityFilter.fields = [
								'id',
								'date',
								'description',
								'users',
								'contacts',
								'isAppointment',
								'closeDate',
								'activityType',
								'priority'
							];
							activityFilter.addSort(ActivityList.attr.date, false);
							getActivities.resolve(ActivityList.customer(customerId).find(activityFilter.build()));
						} else {
							getActivities.resolve({ data: [] });
						}
					};

					if (hasSpeedEditOrderInit) {
						fetchActivities();
					}

					// Get files for order if we have that feature
					var hasFiles = FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS);

					if (hasFiles && !copy) {
						var fileFilter = new RequestBuilder();
						fileFilter.addFilter(File.attr.entity, fileFilter.comparisonTypes.Equals, 'Order');
						fileFilter.addFilter(File.attr.entityId, fileFilter.comparisonTypes.Equals, orderId);
						promises.files = File.customer(customerId).find(fileFilter.build());
					} else {
						promises.files = Promise.resolve({ data: [] });
					}

					const orderRequest = isCopiedFromAppFramework
						? Promise.resolve({ data: params.order })
						: Order.customer(customerId).get(orderId);

					orderRequest
						.then(function (res) {
							// Get client
							clientId = res.data.client?.id;
							if (clientId) {
								Account.customer(customerId)
									.get(clientId)
									.then(getClient.resolve)
									.catch(function (e) {
										if (e && e.status === 404) {
											if (e.data && e.data.metadata && e.data.metadata.missingRights) {
												missingAccountRights = true;
											}
											getClient.resolve({ data: null });
										} else {
											getClient.reject(e);
										}
									});
							}

							if (!hasSpeedEditOrderInit) {
								// Get sales
								var salesReportFilter = new RequestBuilder();
								salesReportFilter.addFilter(
									SalesReport.attr.account,
									salesReportFilter.comparisonTypes.Equals,
									clientId
								);
								getSalesReport.resolve(
									SalesReport.customer(customerId).setType('order').find(salesReportFilter.build())
								);
							} else {
								getSalesReport.resolve({});
							}

							// Get source
							res.data.source = res.data.source ?? {};

							if (!hasSpeedEditOrderInit) {
								const source = res.data.source;
								const hasSource = source && source.type && source.id;
								const resource = getSourceResource(source);
								if (hasSource && resource) {
									resource
										.get(source.id)
										.then(({ data }) => {
											source.title = getSourceName(source, data);
											source.entity = getSourceEntity(source);
											source.onClick = onSourceClick;
											source.data = data;
										})
										.catch(err => {
											source.title = null;
											const errorStatus = get(err, 'status');
											if ([403, 404].indexOf(errorStatus) < 0) {
												logError(
													err,
													`Failed to get source resource ${source.type} with id ${source.id}`
												);
											}
										});
								}

								fetchActivities();
							}

							if (res.data.contact && res.data.contact.id) {
								Contact.customer(customerId)
									.get(res.data.contact.id)
									.then(getContact.resolve)
									.catch(function (e) {
										// If the contact is not found we open the order anyway
										if (e && e.status && e.status === 404) {
											if (e.data && e.data.metadata && e.data.metadata.missingRights) {
												missingContactRights = true;
											}
											getContact.resolve({});
										} else {
											getContact.reject(e);
										}
									});
							} else {
								getContact.resolve({});
							}

							getOrder.resolve(res);

							const totalNumberOfProducts = Tools.AppService.getTotals('products');
							if (totalNumberOfProducts < 4000) {
								// The reason for fetching all products is that an user do not need to have access to all products in a bundle.
								// This is not the products you see in the product select so do not worry
								const products = AppService.getProducts(false, true, true);
								getProducts.resolve(products);
							} else {
								const productIdsSet = new Set();

								for (const orderRow of res?.data?.orderRow) {
									const productId = orderRow.product?.id || orderRow.productId;
									productIdsSet.add(productId);
									for (const bundleRow of orderRow.bundleRows) {
										productIdsSet.add(bundleRow.productId);
									}
								}

								Product.find({ id: Array.from(productIdsSet), usePriceLists: true })
									.then(({ data }) => {
										getProducts.resolve(data);
									})
									.catch(error => getProducts.reject(error));
							}
						})
						.catch(function (err) {
							getOrder.reject(err);
						});
				} else {
					// FIx clientcontacts in ctrl to make FASTER!!
					if (clientId) {
						promises.client = Account.customer(customerId).get(clientId);

						if (contactId) {
							promises.contact = Contact.customer(customerId).get(contactId);
						} else {
							promises.contact = Promise.resolve({ data: null });
						}
					}
				}

				// Putting this after all requests have been fired so those do not have to wait for cloneDeep :S
				promises.users = Promise.resolve({ data: AppService.getActiveUsers() });
				promises.customFields = AppService.getCustomFields('order');
				promises.orderrowCustomFields = AppService.getCustomFields('orderrow');
				promises.productCustomFields = AppService.getCustomFields('product');
				promises.contactCustomFields = AppService.getCustomFields('contact');
				promises.priceLists = AppService.getPriceLists().filter(pricelist => pricelist.active);

				return $q.all(promises).then(
					function (results) {
						var order;
						var stages;
						var defaultCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });

						if (edit) {
							order = results.order.data;
							order.stage.probability = order.probability;
						} else if (copy) {
							order = results.order.data;
							order.stage.probability = order.probability;
							order.closeDate = undefined;
							order.competitorId = undefined;
							order.lostReason = undefined;
							order.periodization = undefined;

							order.custom = _.map(order.custom, function (cf) {
								if ((!cf.editable || !cf.visible) && cf.value) {
									delete cf.value;
								}
								return cf;
							});

							// set source to the copied order/opportunity
							order.source = {
								type: 'opportunity',
								id: order.id
							};
							delete order.id;

							// delete order row ids
							if (order.orderRow && order.orderRow.length) {
								order.orderRow.forEach(function (oRow) {
									delete oRow.id;
								});
							}

							// Fix stage if an order was copied as opportunity (when the selected stage is not in the "stage-type-array")
							stages = AppService.getStages();
							if (!_.find(stages, { id: order.stage.id })) {
								// Find the default stage
								var defaultStageId = metadata.params.defaultStageId;
								var defaultStage = _.find(stages, { id: defaultStageId });

								order.stage = defaultStage || stages[0];
							}
						} else {
							// Set initial user
							var user;
							if ($modalParams.userId) {
								user = _.find(results.users.data, { id: $modalParams.userId });
							} else {
								user = _.find(results.users.data, { id: metadata.user.id });
							}

							// Set initial stage
							var stage;
							stages = AppService.getStages(isCopiedFromAppFramework ? undefined : type);
							if (stageId) {
								stage = _.find(stages, { id: stageId });
							} else {
								stage = _.find(stages, { probability: 100 });
							}

							// Set initial contact
							var contact = results.contact ? results.contact.data : null;

							// Get new order object
							order = getNewOrder(user, stage, params.campaign, contact);

							order.source = params.source;

							// Set initial notes
							order.notes = $modalParams.notes || '';
						}

						if (params.phoneCallId) {
							order.phoneCallId = params.phoneCallId;
						}

						results.order = {
							data: order
						};

						// Fix for when a user are selected and no longer exist
						if (
							results.order.data.user &&
							!_.some(results.users.data, { id: results.order.data.user.id })
						) {
							results.users.data.push(results.order.data.user);
						}

						order.currency = order.currency || (order.currencyRate == 1 && defaultCurrency.iso); // eslint-disable-line eqeqeq

						angular.forEach(metadata.customerCurrencies, function (value) {
							if (value.masterCurrency) {
								results.masterCurrency = value.iso;
							}
						});

						if (!results.files) {
							results.files = { data: [] };
						}

						results.missingAccountRights = false;
						if (missingAccountRights) {
							results.missingAccountRights = true;
						}

						results.missingContactRights = false;
						if (missingContactRights) {
							results.missingContactRights = true;
						}
						if (!$modalParams.type) {
							results.type =
								order.probability > 0 && order.probability < 100 ? 'opportunity' : results.type;
						}

						if (results.clientOrderRelationTranslations) {
							results.clientOrderRelationTranslations = _.groupBy(
								results.clientOrderRelationTranslations.data,
								'tagId'
							);
						}

						return results;
					},
					function (err) {
						if (err !== 'abort') {
							NotificationService.addNotification({
								title: 'default.error',
								body: err && err.status === 404 ? 'errorNotFound.order' : 'openError.order',
								style: 'error',
								icon: 'times'
							});
						}
						return $q.reject(err);
					}
				);
			});
		};
	}
]);

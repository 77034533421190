import React from 'react';
import { EllipsisTooltip, Flex, Icon, IconName, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './DropdownItem.scss';

/**
 * @todo move to @upsales/components
 **/
type DropdownItemProps = {
	icon?: IconName;
	title: string;
	subtitle?: string;
	size?: 'sm' | 'md';
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};
const DropdownItem = ({ icon, title, subtitle, size = 'md', className, onClick }: DropdownItemProps) => {
	const classes = new BemClass('DropdownItem', className).mod({ [size]: true });
	return (
		<Flex className={classes.b()} onClick={onClick} alignItems="center">
			{icon ? (
				<Flex flex={[0, 0, '20px']}>
					<Icon name={icon} />
				</Flex>
			) : null}
			<Flex direction="column" className={classes.elem('text-wrap').b()}>
				<EllipsisTooltip title={title}>
					<Text>{title}</Text>
				</EllipsisTooltip>
				{subtitle ? (
					<EllipsisTooltip title={subtitle}>
						<Text size="sm" color="grey-11">
							{subtitle}
						</Text>
					</EllipsisTooltip>
				) : null}
			</Flex>
		</Flex>
	);
};

export default DropdownItem;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextEditor from 'Components/TextEditor';
import logError from 'App/babel/helpers/logError';
import { Loader } from '@upsales/components';

const AgendaTextarea = props => {
	const [hasSignatures, setHasSignatures] = useState(null);
	const [agenda, setAgenda] = useState(props.agenda);
	const isInitialMount = useRef(true);

	const configWithoutSignatures = {
		editorplaceholder: props.placeholder,
		toolbar: [
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', 'RemoveFormat']
			},
			{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
			{ name: 'links', items: ['Link', 'Unlink'] }
		],
		height: '102px',
		resize_enabled: true,
		enterMode: CKEDITOR.ENTER_BR,
		shiftEnterMode: CKEDITOR.ENTER_BR,
		fillEmptyBlocks: false,
		entities: false,
		ignoreEmptyParagraph: false
	};

	const configWithSignatures = {
		...configWithoutSignatures,
		extraPlugins: 'signatures',
		signaturePlugin: {
			transformer: body => {
				const calendarIntegrations = Tools.AppService.getMetadata().integrations.inits.calendar;

				if (calendarIntegrations?.length) {
					const googleCalendar = calendarIntegrations.find(integration =>
						integration.name.toLowerCase().includes('google')
					);
					if (googleCalendar) {
						body = body.replace(/<img[^>]*><br>|<img[^>]*>/g, '');
					}
				}

				return body;
			}
		}
	};
	configWithSignatures.toolbar[0].items.push('signatures');

	useEffect(() => {
		Tools.MailSignatures.getActiveSignatures()
			.then(res => {
				setHasSignatures(res.data.length !== 0);
			})
			.catch(e => logError(e, 'Could not get active signatures'));
	}, []);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setAgenda(props.forceUpdateAgenda);
		}
	}, [props.forceUpdateAgenda]);

	const onTextEditorChange = ({ value }) => {
		setAgenda(value);
	};

	const onTextEditorInstanceReady = e => {
		e.editor.document.$.body.style.margin = '10px';
		e.editor.dataProcessor.writer.selfClosingEnd = '>';

		const blockTags = ['table', 'thead', 'tbody', 'tfoot', 'td', 'th', 'tr'];

		for (let i = 0; i < blockTags.length; i++) {
			e.editor.dataProcessor.writer.setRules(blockTags[i], {
				indent: false,
				breakBeforeOpen: false,
				breakAfterOpen: false,
				breakBeforeClose: false,
				breakAfterClose: false
			});
		}
	};

	const onFocus = isFocused => {
		if (isFocused) {
			props.onAgendaEdit?.();
		}
		if (!isFocused) {
			props.onChange(agenda);
		}
	};

	return hasSignatures === null ? (
		<Loader />
	) : (
		<TextEditor
			value={agenda}
			onFocus={onFocus}
			readOnly={props.disabled}
			onChange={onTextEditorChange}
			onInstanceReady={onTextEditorInstanceReady}
			config={hasSignatures ? configWithSignatures : configWithoutSignatures}
		/>
	);
};

AgendaTextarea.propTypes = {
	agenda: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

export default AgendaTextarea;

angular.module('upAttributes').service('BannerAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			const attr = {
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'name'
				},
				status: {
					title: 'default.status',
					field: 'draft',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'draft'
				},
				type: {
					title: 'default.type',
					field: 'type',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'type'
				},
				size: {
					title: 'ads.creativeSize',
					field: 'width',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'width'
				},
				landingPage: {
					title: 'ads.creativeLandingPage',
					field: 'landingPage',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'cleanLandingPage'
				},
				regBy: {
					title: 'default.createdBy',
					field: 'regBy',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'userName'
				}
			};

			const standardFilters = {
				UniqueId: {
					title: 'default.id',
					field: 'uniqueId',
					columnPath: 'uniqueId',
					inputType: 'number',
					type: 'idList'
				},
				Name: {
					field: 'name',
					type: 'raw',
					title: 'default.name',
					isInactive: function (filter) {
						return !filter || filter.inactive || !filter.value;
					},
					generate: function () {
						return { value: '', comparisonType: 'src' };
					},
					build: function (filter, rb) {
						if (filter.value) {
							rb.addFilter({ field: 'name' }, filter.comparisonType, filter.value);
						}
					}
				}
			};

			return {
				getAll: () => attr,
				attr,
				standardFilters,
				requiredFields: [],
				relatedEntities: [],
				keys: {}
			};
		};
	}
]);

'use strict';
import EditRole from 'Components/EditRole';
import T from 'Components/Helpers/translate';
import { SalesboardHelper } from './editRoleSalesboardHelper';
import ReduxRootComponent from 'Components/ReduxComponentRoot';

window.EditRoleController = function ($scope) {
	var rootNode;

	var render = function (props) {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(
			React.createElement(ReduxRootComponent, Object.assign({ component: EditRole }, props)),
			rootNode,
			function () {}
		);
	};

	$scope.$on('$destroy', function () {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	var roleAccess = [
		{
			name: Tools.$translate('default.account'),
			namePlural: Tools.$translate('default.accounts'),
			group: 'sales',
			entity: 'Client',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			},
			modify: {
				selectedValue: 'own',
				values: ['own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.contact'),
			namePlural: Tools.$translate('default.contacts'),
			group: 'sales',
			entity: 'Contact',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			},
			modify: {
				selectedValue: 'own',
				values: ['own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.activity'),
			namePlural: Tools.$translate('default.activities'),
			group: 'sales',
			entity: 'Activity',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			},
			modify: {
				selectedValue: 'own',
				values: ['no', 'own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.order'),
			namePlural: Tools.$translate('default.order'),
			group: 'sales',
			entity: 'Order',
			modify: {
				selectedValue: 'own',
				values: ['no', 'own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.opportunity'),
			namePlural: Tools.$translate('default.opportunities'),
			group: 'sales',
			entity: 'Opportunity',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			},
			modify: {
				selectedValue: 'own',
				values: ['own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.document'),
			namePlural: Tools.$translate('default.documents'),
			group: 'sales',
			entity: 'Document',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.recurringOrder'),
			namePlural: Tools.$translate('default.recurringOrders'),
			group: 'sales',
			entity: 'Agreement',
			access: {
				selectedValue: 'own',
				values: ['no', 'own', 'all', 'top']
			},
			modify: {
				selectedValue: 'own',
				values: ['no', 'own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		},
		{
			name: Tools.$translate('default.campaign'),
			namePlural: Tools.$translate('default.campaigns'),
			group: 'sales',
			entity: 'Project',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			}
		},
		{
			name: Tools.$translate('salesboard.column.report'),
			namePlural: Tools.$translate('feature.reports'),
			group: 'sales',
			entity: 'Report',
			access: {
				selectedValue: 'own',
				values: ['own', 'all', 'top']
			}
		}
	];
	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS)) {
		roleAccess.push({
			name: Tools.$translate('default.socialEvent'),
			namePlural: Tools.$translate('default.socialEvents'),
			group: 'market',
			entity: 'SocialEvent',
			access: {
				selectedValue: 'no',
				values: ['no', 'own', 'all', 'top']
			},
			modify: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			},
			delete: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		});
	}
	if (Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_TARGET')) {
		roleAccess.splice(1, 0, {
			name: T('client.target.editTarget'),
			namePlural: T('client.target.editTarget'),
			group: 'sales',
			entity: 'ClientQuotas',
			modify: {
				selectedValue: 'no',
				values: ['no', 'own', 'all']
			}
		});
	}
	roleAccess[0].access.values = ['own', 'ownAndNoUser', 'all', 'roleAndNoUser', 'top'];
	var dataStore;

	var mapObject = function (obj) {
		var templateData = {
			access: {},
			modify: {},
			delete: {}
		};
		var values = ['no', 'own', 'all', 'top', 'ownAndNoUser', 'roleAndNoUser'];

		_.forEach(obj, function (entity) {
			if (entity.access) {
				var accessVal = values.indexOf(entity.access.selectedValue);
				templateData.access[entity.entity] = accessVal !== -1 ? accessVal : 1;
			}
			if (entity.modify) {
				var modifyVal = values.indexOf(entity.modify.selectedValue);
				templateData.modify[entity.entity] = modifyVal !== -1 ? modifyVal : 1;
			}
			if (entity.delete) {
				var deleteVal = values.indexOf(entity.delete.selectedValue);
				templateData.delete[entity.entity] = deleteVal !== -1 ? deleteVal : 1;
			}
		});
		return templateData;
	};

	var actions = {
		save: function () {
			dataStore.setStore({ saving: true });
			var store = dataStore.getStore();
			// MAP ROLE
			var role = Tools.Role.new();
			role.name = store.name;
			role.description = store.description;
			role.hasDiscount = store.hasDiscount;
			role.templateData = mapObject(store.roleAccess);

			const editingOwnRoleCurrency =
				role.defaultCurrency !== store.defaultCurrency && store.roleId === Tools.AppService.getSelf().role?.id;
			if (editingOwnRoleCurrency) {
				const metadata = Tools.AppService.getMetadata();
				metadata.role.defaultCurrency = store.defaultCurrency;
				Tools.AppService.setMetadata(metadata);
			}
			role.defaultCurrency = store.defaultCurrency;
			role.defaultSalesboardId = store.defaultSalesboard?.id ?? null;

			if ($scope.$modalParams.roleId) {
				role.id = $scope.$modalParams.roleId;
			}

			role.parent = {
				id: store.parentId !== -1 ? store.parentId : 0
			};

			Tools.Role.save(role)
				.then(function (res) {
					if (res.error) {
						$scope.reject(Tools.$translate('saveError.role'));
					}
					$scope.resolve();
				})
				.catch(function () {
					dataStore.setStore({ saving: false });
				});
		},
		close: function () {
			$scope.reject();
		},
		rootPropChanged: function (key, value) {
			dataStore.set(key, value);
		},
		accessChanged: function (entity, type, value) {
			var roleAccess = dataStore.get('roleAccess');
			var i = _.findIndex(roleAccess, { entity: entity });
			roleAccess[i][type].selectedValue = value;
			dataStore.setStore({ roleAccess: roleAccess });
		}
	};

	var parseRoleSettings = function (roleId, roleAccess) {
		return Tools.Role.getSettings(roleId).then(function (res) {
			if (!res) {
				return $scope.reject(Tools.$translate('admin.usersAndRoles.roleNotFound'));
			}
			var templateData = res.templateData;
			var values = ['no', 'own', 'all', 'top', 'ownAndNoUser', 'roleAndNoUser'];

			_.forEach(roleAccess, function (field) {
				var accessValue = templateData.access[field.entity];
				var modifyValue = templateData.modify[field.entity];
				var deleteValue = templateData.delete[field.entity];
				if (field.access) {
					field.access.selectedValue = accessValue !== undefined ? values[accessValue] : 'own';
					if (field.entity === 'SocialEvent' && !accessValue) {
						field.access.selectedValue = 'no';
					}
				}
				if (field.modify) {
					field.modify.selectedValue = modifyValue !== undefined ? values[modifyValue] : 'own';
					if (field.entity === 'SocialEvent' && !modifyValue) {
						field.modify.selectedValue = 'no';
					}
				}
				if (field.delete) {
					field.delete.selectedValue = deleteValue !== undefined ? values[deleteValue] : 'own';
					if (field.entity === 'SocialEvent' && !deleteValue) {
						field.delete.selectedValue = 'no';
					}
				}
			});

			var newStore = {
				roleAccess: roleAccess,
				name: res.name || '',
				description: res.description || '',
				parentId: res.parent ? res.parent.id : -1,
				roleId: res.id,
				parsingRoleSettings: false,
				hasDiscount: !!res.hasDiscount
			};

			var defaultCurrency = null;
			var metadata = Tools.AppService.getMetadata();
			if (metadata.params.MultiCurrency && res.defaultCurrency && res.defaultCurrency.length) {
				defaultCurrency = _.find(metadata.customerCurrencies, { iso: res.defaultCurrency });
				if (defaultCurrency) {
					newStore.defaultCurrency = defaultCurrency.iso;
				}
			}

			dataStore.setStore(newStore);
		});
	};

	var init = function () {
		var metadata = Tools.AppService.getMetadata();
		var udos = metadata.params.UserDefinedObject;
		var parentId = 0;
		var roleId = 0;
		rootNode = document.getElementById('edit-role-modal-root');
		const salesboards = Tools.AppService.getListViews().salesboard;
		const roles = Tools.AppService.getRoles();
		const salesboardHelper = new SalesboardHelper({
			salesboards,
			roles,
			currentRoleId: $scope.$modalParams.roleId
		});

		_.forEach(udos, function (udo) {
			roleAccess.push({
				name: udo.name || '<<UDO ' + udo.id + '>>',
				namePlural: udo.name || '<<UDO ' + udo.id + '>>',
				group: 'sales',
				entity: 'UserDefObj' + udo.id,
				access: {
					selectedValue: 'own',
					values: ['no', 'own', 'all', 'top']
				},
				modify: {
					selectedValue: 'own',
					values: ['no', 'own', 'all']
				},
				delete: {
					selectedValue: 'no',
					values: ['no', 'own', 'all']
				}
			});
		});
		let parsingRoleSettings = true;
		if ($scope.$modalParams.roleId) {
			parseRoleSettings($scope.$modalParams.roleId, roleAccess);
		} else {
			parentId = -1;
			parsingRoleSettings = false;
		}

		if (metadata.params.MultiCurrency) {
			// find master
			var defaultCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });
			if (!defaultCurrency) {
				// Select first if not found
				defaultCurrency = metadata.customerCurrencies[0];
			}
			defaultCurrency = defaultCurrency ? defaultCurrency.iso : null;
		}

		dataStore = new DataStore(render, actions, {
			name: '',
			description: '',
			parentId: parentId,
			saving: false,
			hasDiscount: true,
			roleAccess: roleAccess,
			roleId: roleId,
			defaultCurrency: defaultCurrency,
			hasMultiCurrency: metadata.params.MultiCurrency,
			currencies: metadata.customerCurrencies.filter(currency => currency.active),
			parsingRoleSettings,
			defaultSalesboard: salesboardHelper.getDefaultSalesboard(),
			salesboards: salesboardHelper.getFormatedSalesboards(),
			hasMultiSalesboards: salesboardHelper.hasMultiSalesboards()
		});
	};

	$scope.$on('modal.ready', init);
};

'use strict';

(function () {
	ReactTemplates.INPUTS.upTags = window.ReactCreateClass({
		ipRegExp: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
		setRef: function (name, ref) {
			this[name] = ref;
		},
		validate: function (value) {
			var parts = value.split('.');

			return (
				parts.length < 5 &&
				_.reduce(
					parts,
					function (res, part) {
						if (!res) {
							return res;
						} else if (part === '') {
							return true;
						} else if (part.length > 3) {
							return false;
						}

						var intPart = parseInt(part);

						return !isNaN(intPart) && part >= 0 && part <= 255;
					},
					true
				)
			);
		},
		componentDidMount: function () {
			var self = this;

			var opts = {
				allowClear: !this.props.required,
				tags: [],
				tokenSeparators: [',', ', ', ' '],
				dropdownCssClass: 'select2-dropdown-hidden',
				formatNoMatches: function () {
					return '';
				},
				createSearchChoice: function (term) {
					return self.ipRegExp.test(term) ? { id: term, text: term } : null;
				},
				tokenizer: function (input, selection, callback) {
					if (input !== '') {
						var parts = input.split(/[ ,]/).filter(function (ipAddress) {
							return self.ipRegExp.test(ipAddress);
						});

						if (parts.length > 1) {
							parts.forEach(function (ipAddress) {
								callback({ id: ipAddress, text: ipAddress });
							});
						}
					}
				}
			};

			opts = _.merge({}, opts, this.props.options);

			$(this._input)
				.select2(opts)
				.on('select2-close', function () {
					$('.ip-block-textarea .select2-input').removeClass('invalid-input');
				})
				.val(this.props.data)
				.trigger('change')
				.on('change', function () {
					if ($(this).val() === '') {
						self.props.onChange([]);
					} else {
						var values = $(this).val().split(',');
						self.props.onChange(values);
					}
				});

			$('.ip-block-textarea .select2-input').on('input', function () {
				this.value = this.value.replace(/[^0-9.]/g, '');

				if (self.validate(this.value) || this.value === '') {
					$(this).removeClass('invalid-input');
				} else {
					$(this).addClass('invalid-input');
				}
			});

			$('.select2-search-field input')[0].placeholder = this.props.placeholder || '';
		},
		render: function () {
			return (
				<input
					className={this.props.className}
					disabled={this.props.disabled}
					ref={this.setRef.bind(this, '_input')}
					type="hidden"
				/>
			);
		},
		componentDidUpdate: function () {
			var val = this.props.data;
			$(this._input).select2('val', val);
		}
	});
})();

import PropTypes from 'prop-types';
import React from 'react';
import ComparisonToggle from '../../ComparisonToggle';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import t from 'Components/Helpers/translate';

const contactFilterArray = [
	'ContactOnlyActivityFilters',
	'ContactOrderFilters',
	'ContactAgreementFilters',
	'Appointment',
	'ContactToDo',
	'ContactPhoneCall'
];

export default (Drilldown, { field, text }) => {
	class Component extends React.Component {
		constructor(props) {
			super(props);

			const { filter, registerDrilldown } = this.props;

			this.lang = {
				clients: t('default.clients'),
				contacts: t('default.contacts'),
				text: t(text).toLowerCase(),
				lessOptions: t('default.lessOptions'),
				moreOptions: t('default.moreOptions')
			};

			if (filter.filterName === 'ContactToDo' || filter.filterName === 'ClientToDo') {
				this.lang.text = t('default.todos').toLowerCase();
			} else if (filter.filterName === 'ContactPhoneCall' || filter.filterName === 'ClientPhoneCall') {
				this.lang.text = t('default.phonecall').toLowerCase();
			}

			this.toggleDrilldown = this.toggleDrilldown.bind(this);
			this.toggleHasValue = this.toggleHasValue.bind(this);

			registerDrilldown(filter.filterName, Drilldown);
		}

		toggleDrilldown() {
			const { filter, toggleDrilldown } = this.props;
			toggleDrilldown(filter.filterName);
		}

		toggleHasValue() {
			const { filter, onChange } = this.props;
			const newValue = _.assign({}, filter.value, { [field]: !filter.value[field] });
			onChange(newValue);
		}

		render() {
			const { filter, hasOpenDrilldown, locked } = this.props;
			const isContactFilter = contactFilterArray.some(f => f === filter.filterName);

			return (
				<CriteriaRowWrapper rowFlex>
					<div className="criteria-row">
						{field ? (
							<div className="comparison-toggle">
								{isContactFilter ? this.lang.contacts : this.lang.clients}
								<ComparisonToggle
									value={filter.value[field]}
									onChange={this.toggleHasValue}
									locked={locked}
								/>
								{this.lang.text}
							</div>
						) : null}
						<div
							className="btn btn-link up-btn btn-bright-blue drilldown-btn btn-lg"
							onClick={this.toggleDrilldown}
						>
							{hasOpenDrilldown ? this.lang.lessOptions : this.lang.moreOptions}
						</div>
					</div>
				</CriteriaRowWrapper>
			);
		}
	}

	Component.propTypes = {
		registerDrilldown: PropTypes.func.isRequired,
		toggleDrilldown: PropTypes.func.isRequired,
		hasOpenDrilldown: PropTypes.bool.isRequired,
		filter: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		locked: PropTypes.bool
	};

	return Component;
};

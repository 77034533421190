'use strict';

angular.module('domain.form').factory('FormEditorMeta', [
	'$q',
	'AppService',
	'AccountProfile',
	'OptIn',
	'Form',
	'$translate',
	'RequestBuilder',
	function ($q, AppService, AccountProfile, OptIn, Form, $translate, RequestBuilder) {
		return function (params) {
			return AppService.loadedPromise.then(function () {
				// // Do not use manual opt-ins in forms
				var rb = new RequestBuilder();
				rb.addFilter(OptIn.attr.type, rb.comparisonTypes.NotEquals, 'manual');
				var promises = {
					accountProfile: AccountProfile.get(),
					optIns: OptIn.find(rb.build()),
					domains: Tools.Domains.find()
				};

				if (params.id) {
					promises.form = Form.get(params.id).then(function (res) {
						if (params.copy) {
							res.data.name =
								res.data.name + ' (' + $translate.instant('default.copied').toLowerCase() + ')';
							delete res.data.id;
						}
						return res;
					});
				}

				return $q.all(promises).then(function (res) {
					if (!params.id) {
						res.form = { data: Form.new(res.accountProfile.data) };
						if (params.name) {
							res.form.data.name = params.name;
						}
						if (params.eventId) {
							res.form.data.actions.push({
								action: 'EventRegistration',
								properties: [
									{
										name: 'EventId',
										value: params.eventId
									}
								]
							});
							res.form.data.socialEventId = params.eventId;
						}

						if (params.domainProps) {
							res.form.data.domain = params.domainProps.domain;
							res.form.data.urlName = params.domainProps.urlName;
						}
					} else {
						res.oldForm = false;
						// Fix and convert old forms
						var form = res.form.data;
						if (!form.thankYouElement) {
							if (form.landingPage) {
								res.oldForm = true;
								res.form.data.thankYouElement = {
									animation: null,
									enabled: true,
									id: Date.now(),
									required: false,
									style: {},
									type: 'landingPage',
									value: {
										href: form.landingPage
									}
								};
							}
						}

						if (params.eventId) {
							res.form.data.socialEventId = params.eventId;
						}
					}

					return res;
				});
			});
		};
	}
]);

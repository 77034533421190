import { EllipsisTooltip, Text } from '@upsales/components';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import TooltipOverflowText from 'App/components/Text/TooltipOverflowText';

ReactTemplates.upFilters.components.itemRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			isGroup: false,
			value: null,
			text: '',
			checked: false,
			disabled: false,
			showCheckBox: true,
			showButton: true,
			className: ''
		};
	},
	onOnlyButtonClick: function (event) {
		event.stopPropagation();

		var values;
		if (this.props.isGroup) {
			values = _.chain(this.props.item.children)
				.pluck('id')
				.filter(_.isNumber)
				.map(function (id) {
					return id;
				})
				.value();
		} else {
			values = [this.props.value];
		}

		this.props.onChange(values, 'replace');
		this.props.onGoBack();
	},
	getChildValues: function (arr) {
		return _.reduce(
			arr,
			function (values, item) {
				if (item.isRole) {
					return values.concat(this.getChildValues(item.children));
				} else {
					values.push(item.id);
					return values;
				}
			}.bind(this),
			[]
		);
	},
	onClick: function () {
		if (this.props.disabled === false) {
			var values = this.props.isGroup ? this.getChildValues(this.props.item.children) : [this.props.value];

			if (this.props.checked) {
				this.props.onChange(values, 'remove');
			} else {
				this.props.onChange(values, 'add');
			}
		}
	},
	render: function () {
		var item = this.props.item;
		var classes = {};

		if (this.props.isGroup) {
			classes.div = 'list-row list-row-group ' + this.props.className;
			classes.input = 'list-input';
			classes.span = 'list-text list-text-group';
			classes.button = 'list-button list-button-group';
		} else {
			classes.div = 'list-row list-row-item ' + this.props.className;
			classes.input = 'list-input';
			classes.span = 'list-text list-text-item';
			classes.button = 'list-button list-button-item';
		}

		var marginWidth = isNaN(item.$depth * 10) ? 0 : item.$depth * 10;
		if (!this.props.indentWhileChecked) {
			marginWidth = this.props.checked ? 0 : marginWidth;
		}
		var marginLeft = marginWidth + 'px';

		var text = this.props.isGroup ? item.name + ' (' + item.$$length + ')' : item.name;
		text = this.props.text ? this.props.text : text;
		var buttonText = Tools.$translate('default.only');

		var textItem = (
			<div className={'list-text-wrap'}>
				<TooltipOverflowText
					size={'sm'}
					tooltipProps={{ title: typeof text === 'object' && text.props?.name ? text.props.name : text }}
				>
					{text}
				</TooltipOverflowText>
			</div>
		);

		if (this.props.tooltip) {
			textItem = ReactTemplates.TOOLS.withTooltip(textItem, text, { placement: 'top' });
		}

		return (
			<div className={classes.div} onClick={this.onClick} style={this.props.style}>
				{this.props.showCheckBox && (
					<input
						className={classes.input}
						style={{ marginLeft: marginLeft }}
						type="checkbox"
						disabled={this.props.disabled}
						readOnly
						checked={this.props.checked}
					/>
				)}

				<div className={'list-text-wrap'}>
					<span className={classes.span}>{textItem}</span>
					{this.props.secondaryText && this.props.secondaryText.length && (
						<span className="list-text-secondary">{this.props.secondaryText}</span>
					)}
				</div>

				{this.props.disabled === false && this.props.showButton && (
					<button className={classes.button} onClick={this.onOnlyButtonClick}>
						{buttonText}
					</button>
				)}
			</div>
		);
	}
});

ReactTemplates.upFilters.components.listRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			value: null,
			text: '',
			checked: false,
			disabled: false,
			showCheckBox: true,
			showButton: true
		};
	},
	onOnlyButtonClick: function (event) {
		event.stopPropagation();

		var values = [this.props.value];

		this.props.onChange(values, 'replace');
	},
	onClick: function () {
		if (this.props.disabled === false) {
			var values = [this.props.value];

			if (this.props.checked) {
				this.props.onChange(values, 'remove');
			} else {
				this.props.onChange(values, 'add');
			}
		}
	},
	render: function () {
		var textRow = <div className="text">{this.props.text || ''}</div>;

		if (this.props.tooltip) {
			textRow = (
				<EllipsisTooltip title={this.props.text}>
					<Text>{this.props.text}</Text>
				</EllipsisTooltip>
			);
		}

		var subtitleRow = null;

		if (this.props.subtitle) {
			subtitleRow = <div className="text subtitle">{this.props.subtitle}</div>;

			subtitleRow = ReactTemplates.TOOLS.withTooltip(subtitleRow, this.props.subtitle, { placement: 'left' });
		}

		var selectThisRowOnlyButton = null;

		if (this.props.disabled === false && this.props.showButton) {
			var selectThisRowOnlyButtonText = Tools.$translate('default.only');

			selectThisRowOnlyButton = (
				<button className="list-button list-button-item" onClick={this.onOnlyButtonClick}>
					{selectThisRowOnlyButtonText}
				</button>
			);
		}

		return (
			<div className="flex-row" onClick={this.onClick}>
				{this.props.showCheckBox && (
					<div className="list-input-wrap">
						<input
							className="list-input"
							type="checkbox"
							disabled={this.props.disabled}
							readOnly
							checked={this.props.checked}
						/>
					</div>
				)}
				<div className="text-container">
					{textRow}
					{subtitleRow}
					{selectThisRowOnlyButton}
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.components.leadSourceRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			value: null,
			text: '',
			checked: false,
			disabled: false,
			showCheckBox: true
		};
	},
	onClick: function () {
		if (this.props.disabled === false) {
			var values = [this.props.value];

			if (this.props.checked) {
				this.props.onChange(values, 'remove');
			} else {
				this.props.onChange(values, 'add');
			}
		}
	},
	render: function () {
		var item = this.props.item;
		var classes = {};

		classes.div = 'value-row list-row list-row-item';
		classes.input = 'list-input';
		classes.span = 'list-text list-text-item';
		classes.button = 'list-button list-button-item';

		if (this.props.checked) {
			classes.div += ' checked';
		}

		var marginWidth = isNaN(item.$depth * 10) ? 0 : item.$depth * 10;
		if (!this.props.indentWhileChecked) {
			marginWidth = this.props.checked ? 0 : marginWidth;
		}
		var marginLeft = marginWidth + 'px';

		var text = item.name;
		text = this.props.text ? this.props.text : text;

		var textItem = (
			<div className={'list-text-wrap'}>
				<span className={classes.span}>{text}</span>
			</div>
		);

		if (this.props.tooltip) {
			textItem = ReactTemplates.TOOLS.withTooltip(textItem, text, { placement: 'top' });
		}

		const filter = getAngularModule('$filter');

		return (
			<div className={classes.div} onClick={this.onClick}>
				{this.props.showCheckBox && (
					<input
						className={classes.input}
						style={{ marginLeft: marginLeft }}
						type="checkbox"
						disabled={this.props.disabled}
						readOnly
						checked={this.props.checked}
					/>
				)}

				<div className={'list-text-wrap has-number'}>
					<span className={classes.span}>{textItem}</span>
				</div>

				{this.props.count != null ? (
					<div className="list-text-number text-ellipsis">{filter('numberFormat')(this.props.count)}</div>
				) : null}
			</div>
		);
	}
});

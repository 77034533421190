import React from 'react';
import PropTypes from 'prop-types';
import { ButtonSelect, Button, Label, Help, Input, Icon, Text, Textarea, Block } from '@upsales/components';
import './CreateGroupMail.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from '../../angularHelpers/getAngularModule';
import UpSelect from 'Components/Inputs/UpSelect';
import MailCampaign, { status } from 'App/resources/MailCampaign';
import history from 'App/pages/routes/history';
import GroupMailEditorRobot from '../GroupMailEditor/GroupMailEditorRobot';
import FeatureIntroModal from 'Components/FeatureIntroModal';
import GroupMailAttr from 'Attributes/MailCampaignAttributes';
import AI from 'App/resources/AI';
import AcceptAISuggestionContract from 'App/components/AcceptAISuggestionContract';
import { AITracker } from 'App/babel/helpers/Tracker';
import logError from 'Helpers/logError';
import { getStyleDataAndGenerateHTML } from 'App/helpers/mailTemplateHelpers';

const SUBJECT_LENGTH = GroupMailAttr.subject.size;

const categorySelectOpts = {
	formatSelection: (category, container, escape) => escape(category.title),
	formatResult: (category, container, query, escape) => escape(category.title),
	matcher: (term, undef, item) => item.title.toLowerCase().indexOf(term.toLowerCase()) !== -1
};

class CreateGroupMail extends React.PureComponent {
	constructor(p) {
		super(p);

		getAngularModule('GroupMailCategory')
			.find({ active: 1 })
			.then(res => {
				this.setState({ categories: res.data, loading: false, category: res.data[0] });
			})
			.catch(error => {
				logError(error, 'ui/app/babel/components/CreateGroupMail/index.js - constructor');
				console.error('GroupMailCategory error', error);
			});

		this.onCategorySelect = this.onCategorySelect.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onNameChange = this.onNameChange.bind(this);

		const t = getAngularModule('$translate');
		const FeatureHelper = getAngularModule('FeatureHelper');
		const AppService = getAngularModule('AppService');
		const $rootScope = getAngularModule('$rootScope');

		this.lang = {
			headline: t('groupMail.letsSendEmails'),
			description: t('groupMail.firstThingIsName'),
			internalName: t('groupMail.internalEmailCampaignName'),
			whatTypeOfMailIsThis: t('groupMail.whatTypeOfMailIsThis'),
			whatIsAnEmailType: t('groupMail.whatIsAnEmailType'),
			nextStep: t('groupMail.nextTemplateDesign'),
			abort: t('default.abort'),
			aiDescription1: t('groupMail.aiDescription1'),
			aiDescription2: t('groupMail.aiDescription2'),
			aiDescription3: t('groupMail.aiDescription3'),
			generateAIContent: t('groupMail.generateAIContent'),
			AIToggleAI: t('groupMail.AIToggle.AI'),
			AIToggleSelf: t('groupMail.AIToggle.self'),
			AIheadline: t('groupMail.AIheadline'),
			AIdescription: t('groupMail.AIdescription')
		};

		this.haveAccessToAISuggestions = FeatureHelper.hasSoftDeployAccess('AI_SUGGESTIONS');
		const haveEnabledAISuggestions = AppService.getMetadata().params.AISuggestionsEnabled;
		const showAISuggestions = this.haveAccessToAISuggestions && haveEnabledAISuggestions;

		this.state = {
			internalName: '',
			category: null,
			loading: true,
			saving: false,
			aiMode: false,
			showAISuggestions,
			aiPrompt: '',
			expanded: false
		};

		if (p.copy) {
			this.state.internalName = `${p.copy.name || ''} (${t('default.copy')})`;
		}

		this.clientParamUnsubscribe = $rootScope.$on('clientParam.updated', (event, clientParam) => {
			if (clientParam?.paramId === 244) {
				this.setState({
					showAISuggestions: this.haveAccessToAISuggestions && clientParam.value,
					aiMode: clientParam.value
				});
			}
		});
	}

	componentWillUnmount() {
		this.clientParamUnsubscribe?.();
	}

	onCategorySelect({ target }) {
		this.setState({ category: target.added });
	}

	onNameChange({ target: { value } }) {
		this.setState({ internalName: value });
	}

	async onSaveAI() {
		try {
			AITracker.track(AITracker.events.GENERATE_MAIL_TEMPLATE, {});

			const StandardMailTemplate = getAngularModule('StandardMailTemplate');

			const [{ data: templates }, { data: ai }] = await Promise.all([
				StandardMailTemplate.find({}),
				AI.find({ prompt: this.state.aiPrompt, task: 'generateMailCampaign' })
			]);

			const aiAnswer = ai[0];

			const template = templates.find(template => template.id === 25);
			let bodyJson = (template.bodyJson.substr(0, 2947) + template.bodyJson.substr(11738)).replace(
				'\n\t\t\t\t\t\t\t\t\t\t<h2 class="text-left">Sociis natoque penatibus et magnis dis montes</h2>\n\t\t\t\t\t\t\t\t\t\t<p class="ingress text-left">Duis mollis, est non commodo luctus, nisi erat <strong>porttitor ligula</strong>, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus.</p>\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li class="text-left">Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li class="text-left">Vulla vitae elit libero, a pharetra augue</li>\n\t\t\t\t\t\t\t\t\t\t\t<li class="text-left">Cras mattis <a href="#">consectetur purus sit</a> amet fermentum.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li class="text-left">Donec sed odio dui.</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t\t<p class="text-left"><strong>Integer posuere erat a ante</strong> venenatis dapibus posuere velit aliquet. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>\n\t\t\t\t\t\t\t\t\t',
				aiAnswer.content
			);

			if (aiAnswer.imgUrl) {
				bodyJson = bodyJson.replace(
					'https://img.upsales.com/upsales/ad-templates/beach_cliffs_580.jpg',
					aiAnswer.imgUrl
				);
			}

			const body = await getStyleDataAndGenerateHTML(bodyJson);

			const overrides = {
				name: 'AI generated marketing mail ' + moment().format('YYYY-MM-DD'),
				subject: aiAnswer.subject,
				category: this.state.category,
				isDraft: true,
				body,
				bodyJson,
				template: { id: template.id },
				socialEventId: this.props.socialEventId || null
			};

			const saveData = {
				...MailCampaign.new(),
				...overrides
			};

			MailCampaign.save(saveData)
				.then(res => {
					history.push('/group-mail-editor/' + res.data.id + '/design');
					setTimeout(() => this.props.resolve(res.data), 500);
				})
				.catch(() => this.setState({ saving: false }));
		} catch (error) {
			console.error(error);
			logError(error, 'ui/app/babel/components/CreateGroupMail/index.js - onSaveAI');
			this.setState({ saving: false });
		}
	}

	onSave(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ saving: true });

		if (this.state.aiMode) {
			this.onSaveAI();
			return;
		}

		const overrides = {
			name: this.state.internalName,
			category: this.state.category,
			isDraft: true
		};
		const self = getAngularModule('AppService').getSelf();

		let saveData;
		if (this.props.copy) {
			saveData = {
				...this.props.copy,
				id: undefined,
				jobId: undefined,
				flowId: undefined,
				sendDate: undefined,
				socialEventId: undefined,
				socialEventSendToStatus: null,
				status: status.DRAFT,
				template: null,
				user: self,
				...overrides
			};
		} else {
			saveData = {
				...MailCampaign.new(),
				subject: this.state.internalName,
				...overrides
			};
		}

		if (this.props.filters) {
			const FilterHelper = getAngularModule('FilterHelper');
			const rb = FilterHelper.parseFilters(this.props.filters, 'contact');
			saveData.filter = JSON.stringify(rb.build());
			saveData.filterConfig = this.props.filters;
		} else if (this.props.segment) {
			saveData.segment = this.props.segment;
		} else if (this.props.project) {
			saveData.project = this.props.project;
		} else if (this.props.socialEventId) {
			saveData.socialEventId = this.props.socialEventId;
			saveData.socialEventSendToStatus = 'invited';
		}

		MailCampaign.save(saveData)
			.then(res => {
				history.push('/group-mail-editor/' + res.data.id);
				setTimeout(() => this.props.resolve(res.data), 500);
			})
			.catch(() => this.setState({ saving: false }));
	}

	render() {
		const classes = new bemClass('CreateGroupMail');
		const options = [
			{ value: true, title: this.lang.AIToggleAI },
			{ value: false, title: this.lang.AIToggleSelf }
		];

		const title = !this.state.showAISuggestions ? this.lang.headline : this.lang.AIheadline;
		const description = !this.state.showAISuggestions
			? this.lang.description
			: this.state.aiMode
			? ''
			: this.lang.headline;

		return (
			<FeatureIntroModal
				className={classes.b()}
				title={title}
				description={description}
				loading={this.state.loading}
				onClose={this.props.reject}
				renderIllustration={this.state.aiMode ? null : () => <GroupMailEditorRobot state="booting" />}
			>
				<form onSubmit={this.onSave} autoComplete="off">
					{this.haveAccessToAISuggestions && !this.state.showAISuggestions ? (
						<AcceptAISuggestionContract title="acceptAISuggestionContract.title" space="mbxl" />
					) : null}
					{this.state.showAISuggestions ? (
						<ButtonSelect
							options={options}
							onChange={value => this.setState({ aiMode: value })}
							value={this.state.aiMode}
						/>
					) : null}
					{this.state.showAISuggestions && this.state.aiMode ? (
						<>
							<Block space="mtxl">
								<Text>{this.lang.aiDescription1}</Text>
							</Block>
							<Block style={{ marginTop: 25 }}>
								<Text>{this.lang.aiDescription2}</Text>
							</Block>
							<Block space="mbxl">
								<Text italic={true}>{this.lang.aiDescription3}</Text>
							</Block>

							<Textarea
								disabled={this.state.saving}
								value={this.state.aiPrompt}
								onChange={({ target: { value } }) => this.setState({ aiPrompt: value })}
								autoFocus={true}
								state={this.state.aiPrompt?.length > 1000 ? 'error' : undefined}
							/>

							<Button
								style={{ maxWidth: 430 }}
								color={!this.state.aiPrompt ? 'grey' : 'green'}
								shadow={!this.state.aiPrompt ? 'none' : 'high'}
								size="xl"
								block
								className={classes.elem('next-step').b()}
								disabled={!this.state.aiPrompt || this.state.aiPrompt?.length > 1000}
								loading={this.state.saving}
								submit
							>
								<Icon space="mrl" name="play" />
								{this.lang.generateAIContent}
							</Button>
						</>
					) : (
						<>
							<Label required maxLength={SUBJECT_LENGTH} value={this.state.internalName}>
								{this.lang.internalName}
							</Label>
							<Input
								autoComplete="internal-name"
								disabled={this.state.saving}
								value={this.state.internalName}
								onChange={this.onNameChange}
								autofocus={true}
								state={this.state.internalName?.length > SUBJECT_LENGTH ? 'error' : undefined}
							/>

							<Label required>{this.lang.whatTypeOfMailIsThis}</Label>
							<UpSelect
								data={this.state.categories}
								defaultValue={this.state.category}
								options={categorySelectOpts}
								onChange={this.onCategorySelect}
								disabled={this.state.saving}
								required={true}
							/>

							<Help articleId={796} sidebar>
								<Button color="grey" type="link" className="btn-inline">
									{this.lang.whatIsAnEmailType}
								</Button>
							</Help>

							<Button
								color={!this.state.internalName || !this.state.category ? 'grey' : 'green'}
								shadow={!this.state.internalName || !this.state.category ? 'none' : 'high'}
								size="xl"
								block
								className={classes.elem('next-step').b()}
								disabled={
									!this.state.internalName ||
									!this.state.category ||
									this.state.internalName?.length > SUBJECT_LENGTH
								}
								loading={this.state.saving}
								submit
							>
								{this.lang.nextStep}
							</Button>

							<Button
								className={classes.elem('cancel').b()}
								color="grey"
								type="link"
								block
								size="lg"
								onClick={() => this.props.reject()}
								disabled={this.state.saving}
							>
								{this.lang.abort}
							</Button>
						</>
					)}
				</form>
			</FeatureIntroModal>
		);
	}
}

CreateGroupMail.propTypes = {
	reject: PropTypes.func.isRequired
};

CreateGroupMail.defaultProps = {
	reject: () => {}
};

window.CreateGroupMail = CreateGroupMail;

export default CreateGroupMail;

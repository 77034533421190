export const leadStatus = (type, contact = {}, account) => {
	const hasActivity = contact.hasActivity || (account && account.hasActivity),
		hasAppointment = contact.hasAppointment || (account && account.hasAppointment),
		hasOpportunity = contact.hasOpportunity || (account && account.hasOpportunity),
		hasOrder = contact.hasOrder || (account && account.hasOrder);

	const hadActivity = contact.hadActivity || (account && account.hadActivity),
		hadAppointment = contact.hadAppointment || (account && account.hadAppointment),
		hadOpportunity = contact.hadOpportunity || (account && account.hadOpportunity),
		hadOrder = contact.hadOrder || (account && account.hadOrder);

	switch (type) {
		/*** Market ***/
		case 'visit':
			return 'event.types.Visit';
		case 'mail':
			return 'event.types.Mail';
		case 'form':
			return 'event.types.Form';
		/*** Sales ***/
		case 'activity':
			if (hasAppointment) {
				return `search.${account ? 'account' : 'contact'}HasAppointment`;
			} else if (hasActivity) {
				return `search.${account ? 'account' : 'contact'}HasActivity`;
			} else if (hadAppointment) {
				return `search.${account ? 'account' : 'contact'}HadAppointment`;
			} else if (hadActivity) {
				return `search.${account ? 'account' : 'contact'}HadActivity`;
			} else {
				return `search.${account ? 'account' : 'contact'}HasNoActivity`;
			}
		case 'opportunity':
			if (hasOpportunity) {
				return `search.${account ? 'account' : 'contact'}HasOpportunity`;
			} else if (hadOpportunity) {
				return `search.${account ? 'account' : 'contact'}HadOpportunity`;
			} else {
				return `search.${account ? 'account' : 'contact'}HasNoOpportunity`;
			}
		case 'order':
			if (hasOrder) {
				return `search.${account ? 'account' : 'contact'}HasOrder`;
			} else if (hadOrder) {
				return `search.${account ? 'account' : 'contact'}HadOrder`;
			} else {
				return `search.${account ? 'account' : 'contact'}HasNoOrder`;
			}
	}
};

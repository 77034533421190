'use strict';

angular.module('domain.segment').factory('EditSegmentMeta', [
	'Segment',
	'FilterHelper',
	'Contact',
	'$q',
	function (Segment, FilterHelper, Contact, $q) {
		var updateAndFetch = function (segment, filter) {
			var oldSegment = _.cloneDeep(segment);
			if (filter.id) {
				var index = _.findIndex(segment.filter, { id: filter.id });
				if (index > -1) {
					segment.filter[index] = filter;
				} else {
					segment.filter.push(filter);
				}
			} else {
				segment.filter.push(filter);
			}

			var query = FilterHelper.mergeSegmentFilters(segment.filter);
			query.f = ['id'];
			query.limit = 0;

			return Contact.find(query)
				.then(function (res) {
					segment.nrOfContacts = res.metadata.total;
					return Segment.save(segment);
				})
				.then(function (res) {
					return res && res.data ? { segment: res.data } : $q.reject('Could not update segment with filter');
				})
				.catch(function () {
					return { segment: oldSegment };
				});
		};

		return function ($stateParams) {
			if ($stateParams.segment && $stateParams.filter) {
				return updateAndFetch($stateParams.segment, $stateParams.filter);
			} else if ($stateParams.segment) {
				return $q.when({ segment: $stateParams.segment });
			} else if ($stateParams.id) {
				return Segment.get($stateParams.id).then(function (res) {
					return { segment: res.data };
				});
			} else {
				return $q.reject('No segment provided');
			}
		};
	}
]);

export default {
	clientName: {
		title: 'default.name',
		field: 'clientName',
		locked: true
	},
	contactName: {
		title: 'default.contactName',
		field: 'contactName'
	},
	scoreTotal: {
		title: 'default.scoreTotal',
		field: 'scoreTotal'
	},
	scoreVisits: {
		title: 'default.scoreVisits',
		field: 'scoreVisits'
	},
	scoreMail: {
		title: 'default.scoreMail',
		field: 'scoreMail'
	},
	scoreForm: {
		title: 'default.scoreForm',
		field: 'scoreForm'
	},
	countTotal: {
		title: 'default.countTotal',
		field: 'countTotal'
	},
	countVisits: {
		title: 'default.countVisits',
		field: 'countVisits'
	},
	countMail: {
		title: 'default.countMail',
		field: 'countMail'
	},
	countForm: {
		title: 'default.countForm',
		field: 'countForm'
	},
	contactCount: {
		title: 'default.contactCount',
		field: 'contactCount'
	},
	assignedSalesperson: {
		title: 'default.assignedSalesperson',
		field: 'processedBy.user.name'
	},
	turnover: {
		title: 'default.turnover',
		field: 'turnover'
	},
	sniText: {
		field: 'default.sniText',
		title: 'sniText'
	},
	state: {
		title: 'default.state',
		field: 'state'
	}
};

'use strict';

import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';

angular.module('upAttributes').service('FormAttributes', [
	'FilterType',
	'DisplayType',
	'AppService',
	'$state',
	function (FilterType, DisplayType, AppService, $state) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				title: {
					title: 'default.title',
					field: 'title',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'name'
				},
				internalName: {
					title: 'default.internalName',
					field: 'internalName',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				actions: {
					title: 'form.action',
					field: 'actions',
					type: FilterType.Number,
					displayType: DisplayType.String,
					parent: 'actions',
					attr: {
						id: {
							field: 'action.id',
							type: FilterType.Number
						},
						action: {
							field: 'action.action',
							type: FilterType.String
						},
						properties: {
							field: 'action.properties',
							type: FilterType.String,
							attr: {
								name: {
									field: 'action.property.name'
								},
								value: {
									field: 'action.property.value'
								}
							}
						}
					}
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					parent: 'user',
					sortable: 'user.name',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						},
						email: {
							field: 'user.email',
							type: FilterType.String
						},
						role: {
							field: 'user.role',
							type: FilterType.Object,
							attr: {
								id: {
									field: 'user.role.id',
									type: FilterType.Number
								}
							}
						}
					},
					selectableColumn: true
				},
				projects: {
					title: 'default.campaigns',
					field: 'project.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'projects',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				regDate: {
					title: 'default.regDate',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'regDate',
					selectableColumn: true
				},
				modDate: {
					title: 'listColumnTitle.lastModified',
					field: 'modDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'modDate',
					selectableColumn: true
				},
				submits: {
					title: 'defaultView.formSubmit',
					field: 'submits',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'submits'
				},
				views: {
					title: 'form.views',
					field: 'views',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'views'
				},
				landingPageBody: {
					title: 'default.type',
					field: 'landingPageBody',
					type: FilterType.Object,
					displayType: DisplayType.Object,
					selectableColumn: true
					//attr
					//sortable:
				},
				isArchived: {
					title: 'default.archived',
					field: 'isArchived',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					selectableColumn: false
				},
				lastSubmitDate: {
					title: 'form.lastSubmitDate',
					field: 'lastSubmitDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: true,
					sortable: 'lastSubmitDate'
				},
				domain: {
					title: 'form.domain',
					field: 'domain',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				urlName: {
					title: 'form.urlName',
					field: 'urlName',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				socialEventId: {
					title: 'default.socialEvent',
					field: 'socialEventId',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				labels: {
					title: 'file.label',
					field: 'labels',
					displayType: DisplayType.String,
					selectableColumn: false
				}
			};

			var standardFilters = {
				Name: {
					title:
						$state.current.name === 'react-root-forms'
							? 'filters.title.formName'
							: 'filters.title.landingPageName',
					type: 'raw',
					displayType: 'text',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if ((filter.value && filter.value.length) || typeof filter.value === 'number') {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'name' },
								rb.comparisonTypes.Wildcard,
								filter.value.toString()
							);
							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'internalName' },
								rb.comparisonTypes.Wildcard,
								filter.value.toString()
							);

							orBuilder.done();
						}
					}
				},
				User: generateUserFilter(AppService.AccessType.ACCOUNT, {
					field: 'user.id',
					title: 'filters.columns.formUser',
					includeFields: ['name'],
					multiComparitors: false
				}),
				LandingPage: {
					title: 'form.landingPageFilter',
					type: 'radio',
					field: 'landingPageBody',
					hide: true,
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'form.form', value: null, comparisonType: 'Equals' },
						{ text: 'form.landingPageOption', value: null, comparisonType: 'NotEquals' }
					]
				},
				IsArchived: {
					title: 'default.archived',
					type: 'radio',
					field: 'isArchived',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: true, comparisonType: 'Equals' },
						{ text: 'default.no', value: false, comparisonType: 'Equals' }
					]
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: [],
				standardFilters: standardFilters,
				requiredFields: [],
				keys: {
					ID: 'id',
					ACTIONS: 'actions'
				}
			};
		};
	}
]);

'use strict';

angular.module('upResources').factory('ClientForm', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ClientForm($resource, $q, URL, API) {
		var baseUrl = URL + API + 'clientform';

		var ClientForm = $resource(
			baseUrl,
			{},
			{
				get: {
					method: 'GET',
					isArray: false
				},
				find: {
					method: 'GET',
					isArray: false
				}
			}
		);

		var Model = {
			get: function (id) {
				return ClientForm.get(id).$promise;
			},
			find: function (filter, options) {
				var params = angular.extend(filter, options);
				return ClientForm.find(params).$promise;
			}
		};

		return Model;
	}
]);

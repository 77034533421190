import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './NavbarSubMenu.scss';
import SubMenuItem from '../SubMenuItem';
import { Flex, Icon } from '@upsales/components';
import { globalTracker } from 'App/babel/helpers/Tracker';
import BrowserService from 'Services/BrowserService';
import NavbarSubMenuDropdown from '../NavbarSubMenuDropdown';
import { useSelector } from 'App/components/hooks';
import DropdownItem from 'App/components/DropdownItem';

type Props = {
	visible: boolean;
	items: ComponentProps<typeof SubMenuItem>['item'][];
};

const getBemElemSelector = (elem: string) => `.NavbarSubMenu__${elem}`;

const getWidthOfAllItems = (container: HTMLDivElement | null) => {
	const subMenuItems = Array.from(container?.querySelectorAll<HTMLDivElement>(getBemElemSelector('item')) || []);
	const itemWidths = subMenuItems.map(item => item.offsetWidth);
	return itemWidths;
};

const goToState = (stateName: string, params = {}, customerId: number) => {
	globalTracker.track(`navbar.${stateName}`);
	return Tools.$state.go(stateName, { ...params, customerId });
};

const getLastVisibleIndex = (container: HTMLDivElement | null) => {
	const showMoreWidth =
		container?.querySelector<HTMLDivElement>(getBemElemSelector('show-more-trigger'))?.offsetWidth || 0;
	let lastVisibleIndex = 0;
	const widths = getWidthOfAllItems(container);
	// Compensate for scrollbar width to prevent width to differ depending on if scrollbar is visible or not
	const containerWidth = container ? container.offsetWidth - BrowserService.scrollbarWidth : 0;
	let totalWidth = 0;
	for (let i = 0; i < widths.length; i++) {
		const isLast = i === widths.length - 1;
		// If this is the last item we want to check if it fits without the show more button.
		// If not last we subtract the width of the show more button since it will be shown by now
		const availableWidth = containerWidth - (isLast ? 0 : showMoreWidth);
		if (widths[i] + totalWidth <= availableWidth) {
			totalWidth += widths[i];
			lastVisibleIndex = i;
		} else {
			break;
		}
	}
	return lastVisibleIndex;
};

const NavbarSubMenu = ({ visible, items }: Props) => {
	const classes = new BemClass('NavbarSubMenu').mod({ visible });
	const lastIndex = items.length - 1;
	const [lastVisibleIndex, setLastVisibleIndex] = useState(lastIndex);
	const showMoreIsVisible = lastVisibleIndex !== lastIndex;
	const containerRef = useRef<HTMLDivElement>(null);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const { customerId } = useSelector(({ App }) => App);

	const calculateAndSetLastVisibleIndex = () => {
		const newLastVisibleIndex = getLastVisibleIndex(containerRef.current);
		setLastVisibleIndex(newLastVisibleIndex);
		setDropdownIsOpen(false);
	};

	// Add resize event listener to recalculate last visible index when window is resized
	useEffect(() => {
		window.addEventListener('resize', calculateAndSetLastVisibleIndex);

		return () => {
			window.removeEventListener('resize', calculateAndSetLastVisibleIndex);
		};
	}, []);

	// Calculate last visible index when items change
	useEffect(() => {
		calculateAndSetLastVisibleIndex();
	}, [items]);

	return (
		<div className={classes.b()} ref={containerRef}>
			<Flex className={classes.elem('item-container').b()}>
				{items.map((item, index) => (
					<SubMenuItem
						goToState={(state, params) => goToState(state, params, customerId)}
						item={item}
						key={item.id}
						alignRight={index > 4}
						className={classes
							.elem('item')
							.mod({ hidden: index > lastVisibleIndex })
							.b()}
					/>
				))}
			</Flex>
			<Flex
				alignItems="center"
				justifyContent="center"
				className={classes.elem('show-more-trigger').mod({ visible: showMoreIsVisible }).b()}
				onMouseDown={() => setDropdownIsOpen(!dropdownIsOpen)}
			>
				<Icon name="bars" />
			</Flex>
			<NavbarSubMenuDropdown isOpen={dropdownIsOpen} onOpenChange={open => setDropdownIsOpen(open)} align="right">
				{items.slice(lastVisibleIndex + 1).map(item => (
					<DropdownItem
						key={item.id}
						title={item.title}
						onClick={() => goToState(item.state, item.stateParams, customerId)}
					/>
				))}
			</NavbarSubMenuDropdown>
		</div>
	);
};

export default NavbarSubMenu;

ReactTemplates.upFilters.filterTypes.historyOpportunityPage = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			title: t('default.opportunity'),
			opportunities: t('default.opportunities'),
			all: t('default.all'),
			has: t('advancedSearch.has'),
			hasNot: t('advancedSearch.hasNot'),
			stage: t('default.stage'),
			from: t('date.from'),
			to: t('date.to'),
			selectStages: t('filter.history.selectStage'),
			selectProducts: t('filter.history.selectProduct'),
			date: t('default.date'),
			products: t('default.products'),
			orderValue: t('field.order.value'),
			showStagesWithZeroProbability: t('filter.history.showStagesWithZeroProbability'),
			onlyShowZeroProbabilityStages: t('filter.history.onlyShowZeroProbabilityStages')
		};

		this.datePresets = _.map(this.props.filter.presets, function (preset) {
			return {
				id: preset,
				name: t('date.' + preset)
			};
		});

		this.onFromValueChanged = _.debounce(this.onFromValueChanged, 300);
		this.onToValueChanged = _.debounce(this.onToValueChanged, 300);
	},
	onDatePresetChanged: function (event) {
		var value = event.target.value;
		var filterValue = this.getFilterValue();

		if (value !== filterValue.Date.value.preset) {
			this.onChange([
				{ path: 'value.Date.value.preset', value: value },
				{ path: 'value.Date.value.start', value: null },
				{ path: 'value.Date.value.end', value: null }
			]);
		}
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		var filterValue = this.getFilterValue(newProps);

		this.setState({
			from: filterValue.Value.value.start,
			to: filterValue.Value.value.end
		});
	},
	getInitialState: function () {
		var filterValue = this.getFilterValue();
		var showZeroProbabilityStages = false;

		var localStorageShowZeroProbabilityStages = localStorage.getItem(
			'listFilters.history.showZeroProbabilityStages'
		);

		if (localStorageShowZeroProbabilityStages === 'true') {
			showZeroProbabilityStages = true;
		} else if (showZeroProbabilityStages === null) {
			localStorage.setItem('listFilters.history.showZeroProbabilityStages', 'false');
		}

		return {
			from: filterValue.Value.value.start,
			to: filterValue.Value.value.end,
			showZeroProbabilityStages: showZeroProbabilityStages
		};
	},
	toggleShowZeroProbabilityStages: function () {
		this.setState(function (prevState) {
			var newValue = !prevState.showZeroProbabilityStages;

			localStorage.setItem('listFilters.history.showZeroProbabilityStages', newValue.toString());

			return {
				showZeroProbabilityStages: newValue
			};
		});
	},
	toggleOnlyShowZeroProbabilityStages: function () {
		const { onlyZeroStage } = this.getFilterValue();

		if (!onlyZeroStage) {
			this.onChange([
				{ path: 'value.Stage.value', value: [] },
				{ path: 'value.onlyZeroStage', value: onlyZeroStage }
			]);
		} else {
			this.onChange([{ path: 'value.onlyZeroStage', value: onlyZeroStage }]);
		}

		localStorage.removeItem('listFilters.history.showZeroProbabilityStages');
		this.setState({ showZeroProbabilityStages: false });
	},
	onFromValueChangedState: function (e) {
		var value = e.target.value;
		value = value.replace(/[^\d.-]/g, '');

		this.onFromValueChanged(value);
		this.setState({
			from: value
		});
	},
	onFromValueChanged: function (value) {
		this.onChange([{ path: 'value.Value.value.start', value: value }]);
	},
	onToValueChangedState: function (e) {
		var value = e.target.value;
		value = value.replace(/[^\d.-]/g, '');

		this.onToValueChanged(value);
		this.setState({
			to: value
		});
	},
	onToValueChanged: function (value) {
		this.onChange([{ path: 'value.Value.value.end', value: value }]);
	},
	onStageChanged: function (values) {
		this.onChange([{ path: 'value.Stage.value', value: _.pluck(values, 'id') }]);
	},
	onProductChanged: function (values) {
		this.onChange([{ path: 'value.OrderRowProduct.value', value: _.pluck(values, 'id') }]);
	},
	onInactiveFilter: function () {
		this.onChange([{ path: 'inactive', value: true }]);
	},
	onHasOpportunityTrue: function () {
		this.onChange([
			{ path: 'value.hasOpportunity', value: true },
			{ path: 'inactive', value: false }
		]);
	},
	onHasOpportunityFalse: function () {
		this.onChange([
			{ path: 'value.hasOpportunity', value: false },
			{ path: 'inactive', value: false }
		]);
	},
	onFromDateChanged: function (date) {
		this.onChange([{ path: 'value.Date.value.start', value: date }]);
	},
	onToDateChanged: function (date) {
		this.onChange([{ path: 'value.Date.value.end', value: date }]);
	},
	getFilterWithValue: function (filter) {
		var newFilter = _.cloneDeep(filter);

		if (newFilter.value) {
			return newFilter;
		} else {
			return _.assign(newFilter, newFilter.generate());
		}
	},
	getFilterValue: function (inProps) {
		var props = inProps || this.props;
		var filter = props.filter;

		return filter.value ? _.cloneDeep(filter.value) : filter.generate().value;
	},
	onChange: function (updates) {
		var props = this.props;
		var newFilter = this.getFilterWithValue(props.filter);

		updates.forEach(function (update) {
			_.set(newFilter, update.path, update.value);
		});

		props.onChange(newFilter, {
			action: 'add'
		});
	},
	classes: function (classes) {
		return _.map(classes, function (expression, classes) {
			return expression ? classes : '';
		}).join(' ');
	},
	stageIdToObject: function (values) {
		var stages = window.Tools.AppService.getStages('opportunity', true);

		return _.reduce(
			values,
			function (res, stageId) {
				var item = _.find(stages, { id: stageId });

				if (item) {
					res.push(item);
				}
				return res;
			},
			[]
		);
	},
	productIdToObject: function (values) {
		var stages = window.Tools.AppService.getProducts(true);

		return _.reduce(
			values,
			function (res, stageId) {
				var item = _.find(stages, { id: stageId });

				if (item) {
					res.push(item);
				}
				return res;
			},
			[]
		);
	},
	stageSortFn: function (data) {
		if (!Array.isArray(data)) {
			return data;
		}
		return _.clone(data).sort(function (a, b) {
			if (a.probability === b.probability) {
				return a.name >= b.name;
			} else if (a.probability === 0) {
				return 1;
			} else if (b.probability === 0) {
				return -1;
			} else {
				return a.probability >= b.probability;
			}
		});
	},
	render: function () {
		var classes = this.classes;
		var filterValue = this.getFilterValue();
		var filter = this.props.filter;
		let onlyZeroStage;

		if (Tools.FeatureHelper.hasSoftDeployAccess('ZERO_PROBABILITY_STAGES')) {
			onlyZeroStage = filterValue.onlyZeroStage;
		}

		var isInactive = filter.value ? filter.isInactive(filter) : true;
		var stageValues = this.stageIdToObject(filterValue.Stage.value);
		var productValues = this.productIdToObject(filterValue.OrderRowProduct.value);
		var currency = Tools.AppService.getMetadata().defaultCurrency;

		return (
			<div className="history-filter">
				<div className="title">{this.lang.title}</div>
				<div className="history-filter-row extra-thin-row">
					<div className="flex-btn-group">
						<button
							data-test-id="listfilters-history-opportunity-inactive"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': isInactive,
								'btn up-btn btn-light-grey no-shadow': !isInactive
							})}
							onClick={this.onInactiveFilter}
						>
							{this.lang.all}
						</button>
						<button
							data-test-id="listfilters-history-opportunity-has"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': filterValue.hasOpportunity && !isInactive,
								'btn up-btn btn-light-grey no-shadow': !filterValue.hasOpportunity || isInactive
							})}
							onClick={this.onHasOpportunityTrue}
						>
							{this.lang.has}
						</button>
						<button
							data-test-id="listfilters-history-opportunity-hasNot"
							className={classes({
								'btn up-btn btn-bright-blue no-shadow': !filterValue.hasOpportunity && !isInactive,
								'btn up-btn btn-light-grey no-shadow': filterValue.hasOpportunity || isInactive
							})}
							onClick={this.onHasOpportunityFalse}
						>
							{this.lang.hasNot}
						</button>
					</div>
				</div>
				<div className="history-filter-row">
					<label className="secondary-title">{this.lang.date}</label>
					<div
						className={
							'datepicker-group' +
							(filterValue.Date.value.preset === 'custom' ? ' datepicker-custom-visible' : '')
						}
					>
						<div
							className="datepicker-select-wrap"
							data-test-id="listfilters-history-opportunity-dateselect"
						>
							<ReactTemplates.INPUTS.upSelect
								key={'datepicker-select'}
								disabled={isInactive}
								data={this.datePresets}
								required={true}
								multiple={false}
								className="datepicker-select"
								onChange={this.onDatePresetChanged}
								defaultValue={filterValue.Date.value.preset}
							/>
						</div>
						{filterValue.Date.value.preset === 'custom' ? (
							<div className="datepicker-wrap" key="datepicker-wrap">
								<div id="datepicker-container">
									<ReactTemplates.upFilters.components.datepicker
										disabled={isInactive}
										placeholder={this.lang.from}
										name={'startDate'}
										className="form-control"
										value={filterValue.Date.value.start}
										onChange={this.onFromDateChanged}
									/>
									<ReactTemplates.upFilters.components.datepicker
										disabled={isInactive}
										placeholder={this.lang.to}
										name={'endDate'}
										className="form-control"
										value={filterValue.Date.value.end}
										onChange={this.onToDateChanged}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
				{this.state.showZeroProbabilityStages ? (
					<div
						key="showZeroProbabilityStages"
						className="history-filter-row"
						data-test-id="listfilters-history-opportunity-stages"
					>
						<label className="secondary-title">{this.lang.stage}</label>
						<ReactTemplates.INPUTS.upStages
							disabled={isInactive || onlyZeroStage}
							multiple={true}
							entity={'opportunity'}
							className={'form-control'}
							value={stageValues}
							onChange={this.onStageChanged}
							placeholder={this.lang.selectStages}
							sortFn={this.stageSortFn}
						/>
					</div>
				) : (
					<div
						key="dontShowZeroProbabilityStages"
						className="history-filter-row"
						data-test-id="listfilters-history-opportunity-stages"
					>
						<label className="secondary-title">{this.lang.stage}</label>
						<ReactTemplates.INPUTS.upStages
							disabled={isInactive || onlyZeroStage}
							multiple={true}
							entity={'opportunity'}
							className={'form-control'}
							value={stageValues}
							onChange={this.onStageChanged}
							placeholder={this.lang.selectStages}
							sortFn={this.stageSortFn}
							hideZeroProbability={true}
						/>
					</div>
				)}
				<div
					className="history-filter-row thin-row"
					data-test-id="listfilters-history-opportunity-showzerostages"
				>
					<label className="secondary-title font-size-12">{this.lang.showStagesWithZeroProbability}</label>
					<br />
					<ReactTemplates.upFilters.components.toggle
						disabled={isInactive || onlyZeroStage}
						className="toggle-lg toggle-bright-blue"
						checked={this.state.showZeroProbabilityStages}
						onChange={this.toggleShowZeroProbabilityStages}
					/>
				</div>
				{Tools.FeatureHelper.hasSoftDeployAccess('ZERO_PROBABILITY_STAGES') && (
					<div
						className="history-filter-row thin-row"
						data-test-id="listfilters-history-opportunity-applyzerostages"
					>
						<label className="secondary-title font-size-12">
							{this.lang.onlyShowZeroProbabilityStages}
						</label>
						<br />
						<ReactTemplates.upFilters.components.toggle
							disabled={isInactive}
							className="toggle-lg toggle-bright-blue"
							checked={onlyZeroStage}
							onChange={this.toggleOnlyShowZeroProbabilityStages}
						/>
					</div>
				)}
				<div className="history-filter-row" data-test-id="listfilters-history-opportunity-products">
					<label className="secondary-title">{this.lang.products}</label>
					<ReactTemplates.INPUTS.upProducts
						disabled={isInactive}
						multiple={true}
						className={'form-control'}
						value={productValues}
						onChange={this.onProductChanged}
						placeholder={this.lang.selectProducts}
					/>
				</div>
				<div className="history-filter-row">
					<label className="secondary-title">{this.lang.orderValue + ' (' + currency.iso + ')'}</label>
					<div className="history-filter-range">
						<div className="icon-input-wrap" data-test-id="listfilters-history-opportunity-valuefrom">
							<input
								className="form-control"
								placeholder={this.lang.from + ':'}
								disabled={isInactive}
								onChange={this.onFromValueChangedState}
								value={this.state.from}
							/>
							<i className="fa fa-money" />
						</div>
						{' - '}
						<div className="icon-input-wrap" data-test-id="listfilters-history-opportunity-valueto">
							<input
								className="form-control"
								placeholder={this.lang.to + ':'}
								disabled={isInactive}
								onChange={this.onToValueChangedState}
								value={this.state.to}
							/>
							<i className="fa fa-money" />
						</div>
					</div>
				</div>
			</div>
		);
	}
});

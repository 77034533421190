import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logError from 'App/babel/helpers/logError';
import {
	DrawerHeader,
	ButtonGroup,
	CardContent,
	Checkbox,
	Column,
	Button,
	Title,
	Block,
	Text,
	Card,
	Icon,
	Row
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import DrawerWrap from 'Components/DrawerWrap';
import T from 'Components/Helpers/translate';
import { getTierString } from 'App/babel/helpers/addons';
import { addSeats, removeSeats, getActiveLicense, getSeatChangeInfo } from 'Store/reducers/BillingReducer';
import { billingTracker } from 'App/babel/helpers/Tracker';
import Tooltip from '@upsales/components/Tooltip';
import moment from 'moment';
import { DangerButton } from '@upsales/components/Buttons';

import './BillingAddSeatDrawer.scss';

const getAddons = (addons, futureLicense) =>
	addons.reduce((res, addon) => {
		const futureAddon = futureLicense.orderRow?.find(row => row.productId === addon.productId) ? true : false;
		addon = { ...addon, futureAddon };
		if (addon.productActive) {
			res.push(addon);
		}
		return res;
	}, []);

const mapStateToProps = ({ Billing }) => ({
	licencePrice: Billing.licenceInfo ? Billing.licenceInfo.price : 0,
	intervalPeriod: Billing.licenceInfo ? Billing.licenceInfo.agreementIntervalPeriod : 12,
	currency: Billing.licenceInfo ? Billing.licenceInfo.currency : 'SEK',
	currentLicenses: Billing.maxLicences,
	addons: getAddons(Billing.addons, Billing.futureLicense),
	futureLicense: Billing.futureLicense,
	seatChangeInfo: Billing.seatChangeInfo,
	loadingSeatInfo: Billing.loadingSeatInfo,
	activeLicense: Billing.activeLicense,
	invoiceSubscriptions: Billing.invoiceSubscriptions,
	licenceInfo: Billing.licenceInfo,
	userCount: Billing.userCount,
	billingAddress: Billing.billingAddress
});

const mapDispatchToProps = {
	addSeats,
	removeSeats,
	getSeatChangeInfo
};

const currencyFormat = (num, cur) => Tools.$filter('currencyFormat')(num, cur);

const Addon = ({ addon, classes, subscriptionEnddate, futureLicense, currency, intervalPeriod }) => {
	let tierStr = '';
	if (addon.tier) {
		tierStr = getTierString({ ...addon, tier: addon.tier });
	}

	return (
		<div key={addon.productId}>
			<Block
				border="ts bs"
				borderColor="grey-4"
				space="pll prl ptm pbm"
				backgroundColor="white"
				className={classes.elem('add-on-row').b()}
			>
				<Row>
					<Column>
						<Text>{T(addon.name)}</Text>
						{futureLicense && !addon.futureAddon ? (
							<Text color="grey-10" size="sm">
								{`${T('admin.billing.cancelled')}: ${subscriptionEnddate}`}
							</Text>
						) : addon.tier && addon.basedOn === 'activeUsers' ? (
							<Text color="grey-10" size="sm">{`${addon.tier.start} - ${addon.tier.end || '∞'}`}</Text>
						) : null}
					</Column>
					<Column align="right">
						{addon.basedOn === 'activeUsers' && addon.type === 'quantity_based' ? (
							<>
								<Text>{`${addon.quantity} x ${currencyFormat(addon.price, currency)} / ${T(
									'admin.billing.interval.' + intervalPeriod
								)}`}</Text>
								<Text color="grey-10" size="sm">
									{currencyFormat(addon.quantity * addon.price, currency)}
								</Text>
							</>
						) : (
							<Text>{`${currencyFormat(addon.quantity * addon.price, currency)} / ${T(
								'admin.billing.interval.' + intervalPeriod
							)}`}</Text>
						)}
						{addon.tier ? (
							<Text color="grey-10" size="sm">
								{tierStr}
							</Text>
						) : null}
					</Column>
				</Row>
			</Block>
		</div>
	);
};

const BillingAddSeatDrawer = ({
	visible,
	onClose,
	currentLicenses,
	currency,
	addons,
	futureLicense,
	addSeats,
	removeSeats,
	getSeatChangeInfo,
	loadingSeatInfo,
	seatChangeInfo,
	intervalPeriod,
	licenceInfo,
	userCount,
	nextPage,
	activeUsers,
	activeLicense,
	billingAddress,
	agreementGroup
}) => {
	const futureLicenses =
		licenceInfo?.futureSeatRows?.reduce((totalQuantity, row) => (totalQuantity += row.quantity), 0) ?? 0; //if 0, there is no future license, as it can only go down to 1.

	const [seats, setSeats] = useState(activeUsers ?? currentLicenses);
	const [authorized, setAuthorized] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	let diffOrderPrice = 0;
	let priceDiffPerInterval = 0;

	useEffect(() => {
		getActiveLicense();
	}, []);

	useEffect(() => {
		if (activeUsers) {
			setSeats(activeUsers);
		}
	}, [activeUsers]);

	useEffect(() => {
		if (seats !== currentLicenses) {
			getSeatChangeInfo(seats - currentLicenses);
		}

		diffOrderPrice = 0;
		priceDiffPerInterval = 0;
	}, [seats]);

	const [showPricingChange, setShowPricingChange] = useState(false);
	const classes = new BemClass('BillingAddSeatDrawer');

	if (seatChangeInfo && seats !== currentLicenses) {
		const { diffOrder, newSubscription } = seatChangeInfo;

		if (diffOrder) {
			diffOrderPrice = diffOrder.totalValue;
		}

		if (newSubscription && activeLicense) {
			const previousCost = activeLicense.orderRow.reduce((total, row) => (total += row.quantity * row.price), 0);
			const newCost = newSubscription.orderRow.reduce((total, row) => (total += row.quantity * row.price), 0);

			const newOrderInterval = newSubscription.metadata.agreementIntervalPeriod;
			const previousOrderInterval = activeLicense.metadata.agreementIntervalPeriod;
			const orderIntervalRatio = newOrderInterval / previousOrderInterval;

			priceDiffPerInterval = newCost / orderIntervalRatio - previousCost;

			addons?.forEach(addon => {
				const currentAddonRow = activeLicense.orderRow.find(row => row.productId === addon.productId);
				const updatedAddonRow = newSubscription.orderRow.find(row => row.productId === addon.productId);

				if (currentAddonRow && updatedAddonRow) {
					const currentPrice = currentAddonRow.quantity * currentAddonRow.price;
					const updatedPrice = (updatedAddonRow.quantity * updatedAddonRow.price) / orderIntervalRatio;

					addon.affected = currentPrice !== updatedPrice;
					addon.price = updatedAddonRow.price / orderIntervalRatio;
					addon.quantity = updatedAddonRow.quantity;
				}
			});
		}
	}

	const changeSeats = async () => {
		if (submitting) {
			return;
		}
		setSubmitting(true);
		if (seats > currentLicenses) {
			billingTracker.track(billingTracker.events.ADD_SEATS, { seats });
			const addedSeats = await addSeats(seats - currentLicenses, agreementGroup);
			const metadata = Tools.AppService.getMetadata();
			Tools.AppService.setMetadata({ ...metadata, licenses: seats });
			if (nextPage) {
				Promise.all([addedSeats])
					.then(() => {
						nextPage('added_seats');
					})
					.catch(function (e) {
						setSubmitting(false);
						logError(e, 'Error occured while adding seats');
					});
			}
		} else if (seats < currentLicenses) {
			billingTracker.track(billingTracker.events.REMOVE_SEATS, { seats });
			await removeSeats(seats, agreementGroup);
		}
		setSubmitting(false);
		onClose(true);
	};
	const handleCancel = () => {
		onClose(false);
		setSeats(activeUsers ?? currentLicenses);
	};

	const getDecreaseDate = () => {
		const activeRenewalDate = activeLicense?.metadata?.agreementRenewalDate;
		const renewalDate = activeRenewalDate ? moment(activeRenewalDate) : moment().add(1, 'year');
		const todaysDate = moment();
		const daysUntilRenewal = renewalDate.diff(todaysDate, 'days');
		let subscriptionEnddate;
		if (daysUntilRenewal >= 90) {
			subscriptionEnddate = renewalDate.format('YYYY-MM-DD');
		} else {
			subscriptionEnddate = moment(renewalDate)
				.add(activeLicense?.metadata?.agreementIntervalPeriod ?? 0, 'M')
				.format('YYYY-MM-DD');
		}
		return subscriptionEnddate;
	};

	const alreadyDowngraded = () => {
		return T('admin.billing.alreadyDowngraded', {
			licenseDiff: currentLicenses - futureLicenses,
			seats: seats,
			decreaseDate: getDecreaseDate(),
			newSeats: seats - currentLicenses + futureLicenses
		});
	};

	const upgradeDowngrade = () => {
		return T('admin.billing.upgradeDowngrade', {
			futureLicenses: futureLicenses,
			seats: seats,
			decreaseDate: getDecreaseDate(),
			currentLicenses: currentLicenses
		});
	};

	const renewalDate = activeLicense?.metadata?.agreementRenewalDate;
	const noticeDateIsInFuture = renewalDate && moment(renewalDate).subtract(3, 'months').isAfter(moment());
	const willBeRenewed = futureLicense || !activeLicense?.metadata?.agreementEnddate;
	const canDecrease = seats > currentLicenses || (willBeRenewed && noticeDateIsInFuture);

	let decreaseDisabledTooltip = T('admin.billing.decreaseDisabled');
	if (willBeRenewed && !noticeDateIsInFuture) {
		decreaseDisabledTooltip = T('admin.billing.decreaseDisabledNoticeDate', {
			date: moment(renewalDate).format('L')
		});
	}

	const increaseDisabled = licenceInfo.price === 0 && seats >= currentLicenses;
	const increaseDisabledTooltip = T('admin.billing.increaseDisabledTooltip');

	return (
		<DrawerWrap className={classes.b()} isMounted={visible} onClose={handleCancel}>
			<DrawerHeader icon="users" iconColor="blue" onHide={handleCancel} title={T('admin.billing.editSeats')} />
			<Block
				className={classes.elem('content').b()}
				border="bs"
				borderColor="grey-4"
				space="pll prl ptl pbl"
				backgroundColor="white"
			>
				<Row>
					<Column>
						<Text className={classes.elem('content').elem('totalSeats').b()}>
							{T('admin.billing.totalSeats')}
						</Text>
					</Column>
					<Column align="right" className={classes.elem('licence-input-col').b()}>
						<ButtonGroup>
							<Tooltip title={decreaseDisabledTooltip} disabled={canDecrease}>
								<Button
									className="ButtonGroup__no-flex"
									color="light-grey"
									size="sm"
									shadow="none"
									onClick={() => setSeats(seats - 1)}
									loading={loadingSeatInfo}
									disabled={
										(Tools.AppService.getAccountSelf().version.localeCompare('starter', undefined, {
											sensitivity: 'accent'
										}) !== 0 &&
											seats <= currentLicenses) ||
										seats < 2 ||
										(activeUsers && activeUsers >= seats) ||
										!canDecrease
									}
								>
									{'-'}
								</Button>
							</Tooltip>
							<Block color="white" border="ts bs" borderColor="grey-4">
								<Text size="sm">{seats}</Text>
							</Block>
							<Tooltip title={increaseDisabledTooltip} disabled={!increaseDisabled}>
								<Button
									className="ButtonGroup__no-flex"
									color="light-grey"
									size="sm"
									shadow="none"
									loading={loadingSeatInfo}
									disabled={increaseDisabled}
									onClick={() => setSeats(seats + 1)}
								>
									{'+'}
								</Button>
							</Tooltip>
						</ButtonGroup>
					</Column>
				</Row>
			</Block>

			{seats !== currentLicenses && futureLicense && futureLicenses !== currentLicenses ? (
				<Block space="pll prl ptm pbm">
					{seats > currentLicenses ? (
						<Text size="sm">{alreadyDowngraded()}</Text>
					) : (
						<Text size="sm">{upgradeDowngrade()}</Text>
					)}
				</Block>
			) : seats < currentLicenses ? (
				<Block space="pll prl ptm pbm">
					<Text size="sm">
						{T('admin.billing.decreaseLicences', {
							decreaseCount: currentLicenses - seats,
							decreaseDate: getDecreaseDate()
						})}
					</Text>
				</Block>
			) : null}
			<Row>
				<Column className={classes.elem('expandable-text-left').b()}>
					{currentLicenses === 1 ? (
						<Text color="grey-10">{T('admin.billing.availableSeat', { count: currentLicenses })}</Text>
					) : (
						<Text color="grey-10">{T('admin.billing.availableSeats', { count: currentLicenses })}</Text>
					)}
				</Column>
				<Column align="right" className={classes.elem('expandable-text-right').b()}>
					{addons.length > 0 ? (
						showPricingChange ? (
							<Button type="link" onClick={() => setShowPricingChange(!showPricingChange)}>
								<Icon name="eye-slash" /> {T('admin.billing.pricingChangeHide')}
							</Button>
						) : (
							<Button type="link" onClick={() => setShowPricingChange(!showPricingChange)}>
								<Icon name="eye" /> {T('admin.billing.pricingChangeShow')}
							</Button>
						)
					) : null}
				</Column>
			</Row>

			{showPricingChange ? (
				<div className={classes.elem('addon_list').b()}>
					{addons?.some(addon => addon.affected) ? (
						<Block space="pll prl ptxl pbm">
							<Title size="sm" bold={true}>
								{T('admin.billing.pricingWillChangeOn')}
							</Title>
						</Block>
					) : null}
					{addons?.map(addon =>
						addon.affected ? (
							<Addon
								key={addon.alias}
								addon={addon}
								intervalPeriod={intervalPeriod}
								futureLicense={futureLicense}
								currency={currency}
								classes={classes}
								subscriptionEndDate={getDecreaseDate()}
							/>
						) : null
					)}

					{addons?.some(addon => !addon.affected) ? (
						<Block space="pll prl ptxl pbm">
							<Title size="sm" bold={true}>
								{T('admin.billing.pricingNotChanged')}
							</Title>
						</Block>
					) : null}

					{addons?.map(addon =>
						!addon.affected ? (
							<Addon
								key={addon.alias}
								addon={addon}
								intervalPeriod={intervalPeriod}
								futureLicense={futureLicense}
								currency={currency}
								classes={classes}
								subscriptionEndDate={getDecreaseDate()}
							/>
						) : null
					)}
				</div>
			) : null}

			<Card className={classes.elem('confirm-card').b()}>
				<CardContent>
					<Row>
						<Column>
							<Text color="grey-10">{T('admin.billing.cost.total.interval.' + intervalPeriod)}</Text>
						</Column>
						<Column align="right">
							{activeLicense ? (
								<>
									<Text loading={loadingSeatInfo}>
										{loadingSeatInfo
											? null
											: currencyFormat(
													priceDiffPerInterval + activeLicense.value,
													activeLicense.currency
											  )}
									</Text>
								</>
							) : null}
						</Column>
					</Row>
					{activeLicense && seats !== currentLicenses ? (
						<>
							<Row>
								<Column>
									<Text color="grey-10" size="sm">
										{T('admin.billing.cost.change.interval.' + intervalPeriod)}
									</Text>
								</Column>
								<Column align="right">
									<Text loading={loadingSeatInfo} size="sm" color="grey-10">
										{loadingSeatInfo
											? null
											: currencyFormat(Math.ceil(priceDiffPerInterval), activeLicense.currency)}
									</Text>
								</Column>
							</Row>
							<Row className={classes.elem('pay-now')}>
								<Column>
									<Text color="grey-10" size="sm">
										{T('admin.billing.payNow')}
									</Text>
								</Column>
								<Column align="right">
									<Text loading={loadingSeatInfo} size="sm" color="grey-10">
										{loadingSeatInfo
											? null
											: currencyFormat(
													Math.max(Math.ceil(diffOrderPrice), 0),
													activeLicense.currency
											  )}
									</Text>
								</Column>
							</Row>
						</>
					) : null}
					<Row>
						<Column align="right">
							<Block space="ptl">
								{seats !== currentLicenses ? (
									<Block className={classes.elem('confirm-card').elem('authorized').b()}>
										<Checkbox
											aria-label={T('admin.billing.iAmAuthorized')}
											checked={authorized}
											onChange={() => setAuthorized(v => !v)}
										></Checkbox>
										<Text>
											{T('admin.billing.iAmAuthorizedLong')}
											<span className={classes.elem('confirm-card').elem('requiredAsterisk')}>
												{' *'}
											</span>
										</Text>
									</Block>
								) : null}
								<div className={classes.elem('confirm-card').elem('cta').b()}>
									<Tooltip
										title={T('admin.billing.iAmAuthorized.tooltip')}
										disabled={seats === currentLicenses || authorized}
										position="bottom"
										distance={22}
									>
										{seats > currentLicenses ? (
											<Button
												size="lg"
												onClick={changeSeats}
												loading={submitting || loadingSeatInfo}
												disabled={submitting || userCount > seats || !authorized}
											>
												{`${T('default.add')} ${seats - currentLicenses} ${T(
													seats - currentLicenses === 1
														? 'admin.billing.license'
														: 'admin.billing.licenses'
												).toLowerCase()}`}
											</Button>
										) : seats < currentLicenses ? (
											<DangerButton
												size="lg"
												loading={submitting || loadingSeatInfo}
												disabled={submitting || !authorized}
												onClick={changeSeats}
											>{`${T('default.remove')} ${currentLicenses - seats} ${T(
												currentLicenses - seats === 1
													? 'admin.billing.license'
													: 'admin.billing.licenses'
											).toLowerCase()}`}</DangerButton>
										) : (
											<Button size="lg" disabled>
												{T('admin.billing.confirmSeats')}
											</Button>
										)}
									</Tooltip>

									<Button onClick={handleCancel} type="link" color="grey">
										{T('default.abort')}
									</Button>
								</div>
								<Text
									color="grey-10"
									align="left"
									size="sm"
									className={classes.elem('confirm-card').elem('invoiceAddress').b()}
								>
									{T('address.invoice')}
								</Text>
								{billingAddress.invoiceDeliveryType === 'mail' ? (
									<>
										{billingAddress.address ? (
											<Text align="left" size="sm">
												{billingAddress.address}
											</Text>
										) : null}
										{billingAddress.zipcode || billingAddress.city ? (
											<Text align="left" size="sm">
												{`${billingAddress.zipcode ? billingAddress.zipcode + ' ' : ''}${
													billingAddress.city || ''
												}`}
											</Text>
										) : null}
										{billingAddress.country ? (
											<Text align="left" size="sm">
												{billingAddress.country}
											</Text>
										) : null}
									</>
								) : (
									<Text align="left" size="sm">
										{billingAddress.email}
									</Text>
								)}
							</Block>
						</Column>
					</Row>
				</CardContent>
			</Card>
		</DrawerWrap>
	);
};

BillingAddSeatDrawer.propTypes = {
	visible: PropTypes.bool,
	intervalPeriod: PropTypes.number,
	nextPage: PropTypes.func
};

export const detached = BillingAddSeatDrawer;
export default connect(mapStateToProps, mapDispatchToProps)(BillingAddSeatDrawer);

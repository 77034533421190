import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import UpSelect from '../../../Inputs/UpSelect';

import { connect } from 'react-redux';
import { setCompanyProfileData } from 'Store/reducers/OnboardingReducer';
import { Title, Text, Icon, Button, Card, CardContent, ColorSelect, Label } from '@upsales/components';
import ButtonGroup from '@upsales/components/ButtonGroup/ButtonGroup';
import AccountProfilePreview from '../../../AccountProfilePreview';

const mapDispatchToProps = {
	setCompanyProfileData
};

const mapStateToProps = state => ({
	companyProfile: state.Onboarding.companyProfile,
	currentStepId: state.Onboarding.currentStepId,
	currentSubstepId: state.Onboarding.currentSubstepId
});

class CompanyProfileTypography extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.companyProfile.typography.maintitle'),
			description: T('onboarding.step.companyProfile.typography.description'),
			preview: T('default.preview'),
			types: {
				mainTitle: T('admin.mainTitle'),
				secondaryTitle: T('admin.secondaryTitle'),
				preabmle: T('admin.preamble'),
				paragraph: T('admin.paragraph'),
				link: T('default.link'),
				button: T('admin.button'),
				footerLinks: T('admin.footerLinkText'),
				footerText: T('admin.footerText')
			}
		};

		this.state = {
			previewOpen: false
		};

		this.config = {
			mainTitle: { profileKey: 'h2', colorKey: 'mainText' },
			secondaryTitle: { profileKey: 'h3', colorKey: 'secondaryText' },
			preabmle: { profileKey: 'ingress', colorKey: 'contentTextIngress' },
			paragraph: { profileKey: 'p', colorKey: 'contentText' },
			link: { profileKey: 'link', colorKey: 'linkText' },
			extra: [
				{ key: 'button', colorKey: 'buttonText' },
				{ key: 'footerLinks', colorKey: 'footerLinkText' },
				{ key: 'footerText', colorKey: 'footerText' }
			]
		};

		this.availableFonts = [
			'Arial, Helvetica, sans-serif',
			'"Arial Black", Gadget, sans-serif',
			'"Comic Sans MS", cursive, sans-serif',
			'Impact, Charcoal, sans-serif',
			'"Lucida Sans Unicode", "Lucida Grande", sans-serif',
			'Tahoma, Geneva, sans-serif',
			'"Trebuchet MS", Helvetica, sans-serif',
			'Verdana, Geneva, sans-serif',
			'Georgia, serif',
			'"Palatino Linotype", "Book Antiqua", Palatino, serif',
			'"Times New Roman", Times, serif',
			'"Courier New", Courier, monospace',
			'"Lucida Console", Monaco, monospace'
		];

		this.openPreview = this.openPreview.bind(this);
		this.closePreview = this.closePreview.bind(this);
		this.renderAccountProfile = this.renderAccountProfile.bind(this);
	}

	defaultChange(value, key) {
		if (typeof value === 'object') {
			value = value.target.value;
		}

		const { setCompanyProfileData } = this.props;
		setCompanyProfileData(key, value);
	}

	renderCardContent() {
		const renderer = [];
		const { config } = this;
		const { companyProfile } = this.props;

		for (const key in config) {
			const cfg = config[key];
			const colors = companyProfile.colors;

			if (Array.isArray(cfg)) {
				const content = [];
				let i = 0;
				for (const extra of cfg) {
					content.push(
						<div key={extra.key} className={'CardContent__Extra__Card'}>
							<Label>{this.lang.types[extra.key]}</Label>
							<ColorSelect
								color={colors[extra.colorKey]}
								className="CardContent__Extra__Color"
								style={{ boxShadow: '0 0 1px rgba(0,0,0,0.1)' }}
								onChange={value => this.defaultChange(value, `colors.${extra.colorKey}`)}
								align={i > 0 ? 'right' : 'left'}
							/>
						</div>
					);
					i++;
				}

				renderer.push(
					<CardContent key={cfg} className={'CardContent__Extra'}>
						{content}
					</CardContent>
				);
			} else {
				const model = companyProfile.typography[cfg.profileKey];

				const buttonGroupRenderer = model => {
					const buttonRenderer = [];
					const buttons = ['bold', 'underline', 'italic'];

					for (const type of buttons) {
						const props = {
							size: 'sm',
							shadow: 'none',
							key: `${cfg.profileKey}.${type}`,
							onClick: () => this.defaultChange(!model[type], `typography.${cfg.profileKey}.${type}`)
						};

						if (model[type] === false) {
							props.type = 'lined';
						}

						buttonRenderer.push(
							<Button {...props}>
								<Icon name={type} />
							</Button>
						);
					}

					return <ButtonGroup>{buttonRenderer}</ButtonGroup>;
				};

				renderer.push(
					<CardContent key={cfg.profileKey}>
						<Label>{this.lang.types[key]}</Label>
						<div className={'CardContent__Holder'}>
							<UpSelect
								id="typography-select"
								getId={x => x}
								formatResult={(x, container, query, escape) => escape(x)}
								formatSelection={(x, container, escape) => escape(x)}
								data={this.availableFonts}
								defaultValue={JSON.parse(JSON.stringify(model.fontFamily))}
								onChange={event =>
									this.defaultChange(event.target.value, `typography.${cfg.profileKey}.fontFamily`)
								}
								style={{ width: '214px' }}
							/>

							<UpSelect
								id="fontsize-select"
								getId={x => x}
								formatResult={(x, container, query, escape) => escape(x)}
								formatSelection={(x, container, escape) => escape(x)}
								data={[8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 30, 32, 34, 36, 42, 48, 72]}
								defaultValue={JSON.parse(JSON.stringify(model.size))}
								onChange={event =>
									this.defaultChange(
										parseInt(event.target.value),
										`typography.${cfg.profileKey}.size`
									)
								}
							/>

							<ColorSelect
								className="CardContent__Color"
								color={colors[cfg.colorKey]}
								style={{ boxShadow: '0 0 1px rgba(0,0,0,0.1)' }}
								onChange={value => this.defaultChange(value, `colors.${cfg.colorKey}`)}
								align="right"
							/>

							{buttonGroupRenderer(model)}
						</div>
					</CardContent>
				);
			}
		}

		return renderer;
	}

	closePreview() {
		this.setState({
			previewOpen: false
		});
	}

	openPreview() {
		const { previewOpen } = this.state;

		if (!previewOpen) {
			this.setState({
				previewOpen: true
			});
		}
	}

	renderAccountProfile() {
		const { previewOpen } = this.state;
		const { companyProfile } = this.props;

		if (previewOpen) {
			return <AccountProfilePreview onClose={this.closePreview} profile={companyProfile} />;
		}

		return null;
	}

	render() {
		const MainClass = new Bem('CompanyProfile');

		return (
			<div className={MainClass}>
				<Title>{this.lang.title}</Title>
				<Text>{this.lang.description}</Text>

				<div className={MainClass.elem('Card').b()}>
					<Button type="link" onClick={this.openPreview}>
						<Icon name="eye" />
						{this.lang.preview}
					</Button>

					<Card>{this.renderCardContent()}</Card>
				</div>

				{this.renderAccountProfile()}
			</div>
		);
	}
}

CompanyProfileTypography.propTypes = {
	setCompanyProfileData: PropTypes.func,
	companyProfile: PropTypes.object,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number
};

CompanyProfileTypography.setAsCompleteOnNext = true;

export const detached = CompanyProfileTypography;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileTypography);

'use strict';

angular.module('upResources').factory('Import', [
	'$resource',
	'$q',
	'URL',
	'API',
	'$http',
	'AppService',
	'ResourceHelper',
	'ParseGeneric',
	function Import($resource, $q, URL, API, $http, AppService, ResourceHelper, ParseGeneric) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'import',
			updateSuccessBody: 'updated.import',
			updateErrorBody: 'saveError.import',
			deleteSucccessBody: 'deleted.import',
			deleteErrorBody: 'deleteError.import'
		});

		const dateFields = ['regDate'];

		var Resource = $resource(
			URL + API + 'imports/:id',
			{},
			{
				create: { method: 'POST', isArray: false },
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { dateFields })
				},
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { dateFields })
				},
				update: { method: 'PUT', isArray: false },
				delete: { method: 'DELETE', isArray: false }
			}
		);

		var Model = {
			upload: function (file, isUpdate = false, importData) {
				var defer = $q.defer();
				$http({
					method: 'POST',
					url: URL + API + 'resources/upload/internal/import/0',
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					data: {},
					transformRequest: function (data, headersGetter) {
						var formData = new FormData();
						angular.forEach(data, function (value, key) {
							formData.append(key, value);
						});

						formData.append('file', file);
						var headers = headersGetter();
						delete headers['Content-Type'];

						return formData;
					}
				})
					.success(function (fileData) {
						var self = AppService.getSelf();
						var data = {
							file: {
								id: fileData.data.id,
								filename: fileData.data.filename
							},
							regDate: new Date(),
							numRows: 0,
							user: { id: self.id },
							type: importData.type
						};
						if (isUpdate) {
							const file = {
								id: fileData.data.id,
								filename: fileData.data.filename
							};
							Resource.update({ id: importData.id }, { file }, defer.resolve, defer.reject);
						} else {
							Resource.create(data, defer.resolve, defer.reject);
						}
					})
					.error(defer.reject);

				return defer.promise;
			},
			find: function (filter, options) {
				var params = _.extend(filter, options || {});

				return Resource.find(params).$promise;
			},
			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},
			save: function (importData, options) {
				var promise = Resource.update(
					{ id: importData.id, onboardingStep: _.get(options, 'onboardingStep') },
					importData
				).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !importData.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !importData.id);
					});

				return promise;
			},
			delete: function (imprt, options) {
				var promise = Resource.delete({ id: imprt.id }).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.onDelete(options, imprt);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});

				return promise;
			},
			status: {
				DRAFT: 'DRAFT',
				START: 'START',
				STARTED: 'STARTED',
				FINISHED: 'FINISHED'
			},
			type: {
				CLIENT_AND_CONTACT: 'CLIENT_AND_CONTACT',
				PRODUCT: 'PRODUCT'
			}
		};

		return Model;
	}
]);

'use strict';

angular.module('domain.campaign').controller('CampaignOpportunities', [
	'$scope',
	'FilterHelper',
	'$stateParams',
	'$upModal',
	'RequestBuilder',
	'Order',
	'AppService',
	function ($scope, FilterHelper, $stateParams, $upModal, RequestBuilder, Order, AppService) {
		var customerId;
		var CampaignCtrl = $scope.CampaignCtrl;
		var currentFilter;

		$scope.limit = 50;
		$scope.opportunitiesError = false;
		$scope.opportunitiesLoading = false;
		$scope.opportunitiesCurrent = 1;
		$scope.opportunitiesPages = 0;

		var updated = function (e, updated) {
			var index = _.findIndex($scope.opportunities, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'order')) {
				if (index !== -1) {
					$scope.opportunities[index] = updated;
				} else {
					$scope.opportunities.push(updated);
				}
			} else if (index !== -1) {
				$scope.opportunities.splice(index, 1);
			}
		};

		var added = function (e, added) {
			if (FilterHelper.match(currentFilter.q, added, 'order')) {
				$scope.opportunities.push(added);
			}
		};

		$scope.$on('opportunity.deleted', function (e, deleted) {
			var index = _.findIndex($scope.opportunities, { id: deleted.id });

			if (index !== -1) {
				$scope.opportunities.splice(index, 1);
			}
		});

		$scope.$on('opportunity.updated', updated);
		$scope.$on('order.updated', updated);

		$scope.$on('opportunity.added', added);
		$scope.$on('order.added', added);

		$scope.getOpportunities = function ($page) {
			$scope.opportunitiesError = false;
			$scope.opportunitiesLoading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Order.attr.campaign, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addFilter(Order.attr.probability, filter.comparisonTypes.NotEquals, 100);
			filter.addSort('date', true);
			filter.limit = $scope.limit;
			filter.offset = $scope.limit * ($page - 1);
			currentFilter = filter.build();

			Order.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					$scope.opportunitiesLoading = false;
					$scope.opportunities = res.data;
					CampaignCtrl.opportunitiesTotal = res.metadata.total;
					if (CampaignCtrl.opportunitiesTotal !== 0) {
						$scope.opportunitiesPages = Math.ceil(CampaignCtrl.opportunitiesTotal / $scope.limit);
					}
				})
				.catch(function () {
					$scope.opportunitiesLoading = false;
					$scope.opportunitiesError = true;
				});
		};

		$scope.moveOpportunity = function (opportunity) {
			opportunity.project = null;
			Order.customer(customerId).save(opportunity);
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			$scope.getOpportunities(1);
		};

		AppService.loadedPromise.then(init);
	}
]);

import PropTypes from 'prop-types';
import { status } from 'App/resources/MailCampaign';

export default PropTypes.shape({
	id: PropTypes.number,
	subject: PropTypes.string,
	body: PropTypes.string,
	category: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string
	}),
	attachments: PropTypes.array,
	status: PropTypes.oneOf(Object.keys(status))
});

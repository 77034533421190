'use strict';

angular.module('domain.advancedSearch').directive('marketeventsSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/marketeventsSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					$scope.inited = false;
					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					$scope.setMailCampaignEvent = function (event, not) {
						$scope.Filters.MailCampaignFilters.value.EventType.value = event;

						if (not) {
							$scope.Filters.MailCampaignFilters.value.EventType.not = true;
						} else {
							$scope.Filters.MailCampaignFilters.value.EventType.not = false;
						}

						// Trigger get
						$scope.ngChange();
					};

					$scope.toggleChange = function (visible, filter) {
						$scope.Filters[filter].inactive = !visible;
						$scope.ngChange();
					};

					var init = function () {
						$scope.showMarketMailHistory = !$scope.Filters.MailCampaignFilters.inactive;
						$scope.showMarketVisitHistory = !$scope.Filters.VisitorFilters.inactive;
						$scope.showMarketFormHistory = !$scope.Filters.FormSubmitFilters.inactive;

						$scope.showMailFilters = FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL);
						$scope.showMailCampaignFilters = FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL);
						$scope.showVisitorFilters = FeatureHelper.isAvailable(FeatureHelper.Feature.VISITS);
						$scope.showFormSubmitFilters = FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS);
						$scope.show =
							$scope.showMailFilters ||
							$scope.showMailCampaignFilters ||
							$scope.showVisitorFilters ||
							$scope.showFormSubmitFilters;

						$scope.inited = true;
					};
					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);

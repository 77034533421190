var app = angular.module('ngCkeditor', []);
var $defer,
	loaded = false;

app.run([
	'$q',
	'$timeout',
	function ($q, $timeout) {
		$defer = $q.defer();

		if (typeof CKEDITOR === 'undefined') {
			return;
		}
		CKEDITOR.disableAutoInline = true;
		function checkLoaded() {
			if (CKEDITOR.status.toLowerCase() === 'loaded') {
				loaded = true;
				$defer.resolve();
			} else {
				checkLoaded();
			}
		}
		CKEDITOR.on('loaded', checkLoaded);
		$timeout(checkLoaded, 100);
	}
]);

app.directive('upCkeditor', [
	'$timeout',
	'$q',
	'$parse',
	function ($timeout, $q, $parse) {
		'use strict';

		return {
			restrict: 'AC',
			require: ['ngModel', '^?form'],
			scope: false,
			priority: 1,
			link: function (scope, element, attrs, ctrls) {
				var ngModel = ctrls[0];
				var form = ctrls[1] || null;
				var EMPTY_HTML = '<p></p>',
					isTextarea = element[0].tagName.toLowerCase() === 'textarea',
					data = [],
					isReady = false;

				if (!isTextarea) {
					element.attr('contenteditable', true);
				}

				var onLoad = function () {
					var options = {
						toolbar: 'full',
						toolbar_full: [
							{ name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'Underline'] },
							{ name: 'paragraph', items: ['BulletedList', 'NumberedList', 'Blockquote'] },
							{
								name: 'editing',
								items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
							},
							{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
							{ name: 'tools', items: ['SpellChecker', 'Maximize'] },
							'/',
							{
								name: 'styles',
								items: ['Format', 'FontSize', 'TextColor', 'PasteText', 'PasteFromWord', 'RemoveFormat']
							},
							{ name: 'insert', items: ['Image', 'Table', 'SpecialChar'] },
							{ name: 'forms', items: ['Outdent', 'Indent'] },
							{ name: 'clipboard', items: ['Undo', 'Redo'] },
							{ name: 'document', items: ['PageBreak', 'Source'] }
						],
						disableNativeSpellChecker: false,
						contentsCss: [CKEDITOR.getUrl('contents.css'), '/styles/custom-ckeditor.css'],
						uiColor: '#FAFAFA',
						height: '400px',
						width: '100%',
						on: {}
					};

					var onResize;

					options = angular.extend(options, $parse(attrs.upCkeditor)(scope));

					var instance = isTextarea
							? CKEDITOR.replace(element[0], options)
							: CKEDITOR.inline(element[0], options),
						configLoaderDef = $q.defer();

					element.bind('$destroy', function () {
						instance.destroy(
							false //If the instance is replacing a DOM element, this parameter indicates whether or not to update the element with the instance contents.
						);

						if (attrs.fill !== undefined && onResize) {
							window.removeEventListener('resize', onResize);
						}
					});
					var typeTimeout = null;
					var ms = 0;
					if (attrs.delayModel !== undefined) {
						ms = 300;
					}
					var setModelData = function (setPristine) {
						var data = instance.getData();
						/* eslint-disable eqeqeq */
						if (data == '') {
							/* eslint-enable eqeqeq */
							data = null;
						}
						if (typeTimeout) {
							$timeout.cancel(typeTimeout);
						}
						typeTimeout = $timeout(function () {
							// for key up event
							if (setPristine !== true || data !== ngModel.$viewValue) {
								ngModel.$setViewValue(data);
							}
							if (setPristine === true && form) {
								form.$setPristine();
							}

							if ((form.form_bodyJson || form.body) && typeof data === 'string') {
								var body = form.body || form.form_bodyJson;

								/* eslint-disable no-control-regex, no-useless-escape */
								if (data.replace(/[^\x20-\xFF\x0A\™\€\u3040-\u319f\uff00-\uffef]/g, '') === '') {
									/* eslint-enable no-control-regex, no-useless-escape */
									body.$setValidity('Invalid string', false);
								} else {
									body.$setValidity('Invalid string', true);
								}
							}
						}, ms);
					};
					var onUpdateModelData = function (setPristine) {
						if (!data.length) {
							return;
						}

						var item = data.pop() || EMPTY_HTML;
						isReady = false;
						instance.setData(item, function () {
							setModelData(setPristine);
							isReady = true;
						});
					};

					//instance.on('pasteState',   setModelData);
					instance.on('change', setModelData);
					instance.on('blur', setModelData);
					//instance.on('key',          setModelData); // for source view

					instance.on('instanceReady', function (e) {
						if (e.editor.contextMenu) {
							e.editor.removeMenuItem('paste');
						}

						scope.$broadcast('ckeditor.ready', instance);
						if (options.init) {
							options.init(e);
						}
						scope.$apply(function () {
							onUpdateModelData(true);
						});

						instance.document.on('keyup', setModelData);

						setTimeout(function () {
							e.editor.container.setOpacity(1);
						}, 200);

						if (attrs.fill !== undefined) {
							onResize = function (editor) {
								editor.resize('100%', element[0].parentNode.clientHeight);
							}.bind(null, e.editor);

							onResize();

							window.addEventListener('resize', onResize);
						}
					});
					instance.on('customConfigLoaded', function () {
						configLoaderDef.resolve();
					});

					ngModel.$render = function () {
						data.push(ngModel.$viewValue);
						if (isReady) {
							onUpdateModelData();
						}
					};
				};

				if (CKEDITOR.status.toLowerCase() === 'loaded') {
					loaded = true;
				}
				if (loaded) {
					onLoad();
				} else {
					$defer.promise.then(onLoad);
				}
			}
		};
	}
]);

import md5 from 'md5';

var cachedAvatars = {};

angular
	.module('upDirectives')
	.directive('upAvatar', [
		'avatarService',
		function (avatarService) {
			var link = function ($scope, elem, attr) {
				$scope.size = attr.size ? attr.size : 35;
				$scope.fontSize = parseInt($scope.size) / 2 + 'px';
				if (attr.round !== undefined) {
					$scope.round = true;
				}

				elem.find('img').on('error', function () {
					$scope.genericAvatar = avatarService.getGravatar($scope.user);
				});

				$scope.$watch(
					'user',
					function () {
						avatarService
							.getAvatar($scope.user, attr)
							.then(function (avatar) {
								$scope.genericAvatar = avatar;
							})
							.catch(e => console.log('avatar error', e));
					},
					true
				);
			};
			return {
				restrict: 'AE',
				scope: {
					user: '='
				},
				templateUrl: require('App/upsales/common/directives/templates/upAvatar.tpl.html?file'),
				link: link,
				replace: true
			};
		}
	])
	.factory('avatarService', [
		'$http',
		'$q',
		'AppService',
		'File',
		'URL',
		'API',
		function ($http, $q, AppService, File, URL, API) {
			var checkForProfileImg = function (user, opts) {
				var profileId = 0;

				if (opts.skipProfile || !user || !user.id) {
					return $q.when(0);
				} else if (cachedAvatars[user.id]) {
					return cachedAvatars[user.id];
				}

				profileId = AppService.getProfileImageId(user.id);

				if (profileId || profileId === 0) {
					return $q.when(profileId);
				}
				cachedAvatars[user.id] = File.find({ entityId: user.id, entity: 'ProfileImage' }).then(function (res) {
					if (res.data.length) {
						profileId = res.data[0].id;
					}
					AppService.setProfileImageId(user.id, profileId || 0);
					return profileId;
				});
				return cachedAvatars[user.id];
			};

			var avatarService = function (user, options) {
				options = options || {};
				var size = options.size ? '&s=' + options.size : '';
				var notFoundQs = options.notFoundError ? '&d=404' : '';

				if (!user) {
					return {
						initials: '',
						url: 'https://secure.gravatar.com/avatar/xxx?d=mm' + size + notFoundQs,
						gravatar: true
					};
				}
				var initials = getInitials(user);
				var email = user.email || '';
				email = email.trim().toLowerCase();

				var emailHash = md5(email);
				var url;

				//Just for demo purposes
				var customerId = AppService.getCustomerId();
				if (customerId === 1) {
					var baseUrl = 'http://resource.upsales.com/3fdab54faac3ff9a9bb646d4f4b4424c/images/';
					switch (user.email) {
						case 'demo_2@upsales.com': //F
							url = baseUrl + 'womanone.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'demo_3@upsales.com': //F
							url = baseUrl + 'womantwo.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'demo_4@upsales.com': //M
							url = baseUrl + 'manone.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'demo_5@upsales.com': //F
							url = baseUrl + 'womantwo.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'demo_6@upsales.com': //F
							url = baseUrl + 'womanthree.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'demo_eng@upsales.com': //M
							url = baseUrl + 'mantwo.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'staffan@coor.se':
							url = baseUrl + 'staffan.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'ulrika@anticimex.se':
							url = baseUrl + 'ullrika.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'percy@abb.se':
							url = baseUrl + 'percy.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'peter@haglofs.se':
							url = baseUrl + 'peter.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'jan@ey.se':
							url = baseUrl + 'manthree.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'stefan@ey.se':
							url = baseUrl + 'stefanp.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ 'initials': initials, 'url':url, gravatar:true});

						case 'ahasmats@upsales.com':
							url = baseUrl + 'womanone.jpg';
							return { initials: initials, url: url, gravatar: true };
						//defer.resolve({ "initials": initials, 'url':url, gravatar:true});

						case '':
							if (user.id === 1249) {
								url = baseUrl + 'peter.jpg';
							}
							if (user.id === 637) {
								url = baseUrl + 'manthree.jpg';
							}
							if (user.id === 638) {
								url = baseUrl + 'stefanp.jpg';
							}
							if (user.id === 1202) {
								url = baseUrl + 'ullrika.jpg';
							}
							if (user.id === 1249 || user.id === 637 || user.id === 638 || user.id === 1202) {
								return { initials: initials, url: url, gravatar: true };
								// defer.resolve({ "initials": initials, 'url':url, gravatar:true});
							}
							break;
					}
				}

				url = 'https://secure.gravatar.com/avatar/' + emailHash + '?d=blank' + size + notFoundQs;
				return { initials: initials, url: url, gravatar: true };
			};

			function getInitials(user) {
				if (!user || !user.name) {
					return '';
				}
				var i1 = '',
					i2 = '',
					nameArray = [];
				if (angular.isDefined(user.name)) {
					i1 = [...user.name.toString()][0];
					nameArray = user.name.split(' ');
					if (nameArray.length > 2) {
						i2 = [...nameArray[nameArray.length - 1]][0];
					} else if (nameArray.length === 2) {
						i2 = [...nameArray[1]][0];
					}
				}
				return (i1 || '') + (i2 || '');
			}

			var getAvatar = function (user, opts) {
				var options = opts || {};

				if (options.skipGravatarCall) {
					return Promise.resolve({
						url: '',
						gravatar: false,
						profileImg: false,
						initials: getInitials(user)
					});
				}
				return checkForProfileImg(user, options).then(function (profileId) {
					if (profileId) {
						var customerId = AppService.getCustomerId();
						return {
							url: URL + API + customerId + '/resources/download/internal/' + profileId,
							gravatar: true,
							profileImg: true,
							initials: getInitials(user)
						};
					} else {
						return avatarService(user, options);
					}
				});
			};

			return {
				getAvatar: getAvatar,
				getGravatar: avatarService,
				getInitials: getInitials
			};
		}
	]);

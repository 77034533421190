import Resource from './Resource';

class SendBeam extends Resource {
	constructor() {
		super('function');
	}

	sendNotification(data) {
		const mappedData = Object.assign({}, data);

		return this._postRequest('sendbeam', mappedData, { methodName: 'sendNotification' });
	}

	makePhoneCall(number, name) {
		return this.sendNotification({
			'loc-key': 'makePhoneCall',
			'loc-args': [number, name],
			sound: 'assign_ding',
			category: 'PHONE_CALL'
		});
	}
}

const resource = new SendBeam();

export default resource;

import async from 'async';

angular.module('domain.contact').controller('ContactRelations', [
	'$scope',
	'$rootScope',
	'$location',
	'$stateParams',
	'RequestBuilder',
	'Account',
	'Contact',
	'$upModal',
	'$translate',
	'$filter',
	'AppService',
	'$state',
	function (
		$scope,
		$rootScope,
		$location,
		$stateParams,
		RequestBuilder,
		Account,
		Contact,
		$upModal,
		$translate,
		$filter,
		AppService,
		$state
	) {
		var ContactCtrl = $scope.ContactCtrl;

		$scope.mapRelation = function (relation, cb) {
			Account.customer($scope.customerId)
				.get(relation.relatedToClientId)
				.then(function (res) {
					relation.client = res.data;
					return cb();
				})
				.catch(function () {
					return cb();
				});
		};

		$scope.$on('contactRelation.added', function (e, res) {
			if (res.contactId !== ContactCtrl.contact.id) {
				return;
			}
			$scope.mapRelation(res, function () {
				$scope.relations.push(res);
			});
		});

		$scope.$on('contactRelation.deleted', function (e, res) {
			var index = _.findIndex($scope.relations, { id: res.id });
			if (index !== -1) {
				$scope.relations.splice(index, 1);
			}
			if (!$scope.relations.length) {
				$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
			}
		});

		$scope.$on('contactRelation.updated', function (e, res) {
			var index = _.findIndex($scope.relations, { id: res.id });
			if (index !== -1) {
				$scope.relations[index].description = res.description;
			}
		});

		var getRelatedClients = function () {
			if (!ContactCtrl.contact.connectedClients) {
				$scope.tableLoading = false;
				return;
			}
			if (!ContactCtrl.contact.connectedClients.length) {
				$scope.tableLoading = false;
				return;
			}
			async.eachSeries(
				ContactCtrl.contact.connectedClients,
				function (connectedClient, cb) {
					$scope.mapRelation(connectedClient, cb);
				},
				function () {
					$scope.tableLoading = false;
				}
			);
		};

		var init = function () {
			$scope.tableLoading = true;
			Contact.customer(ContactCtrl.customerId)
				.get(ContactCtrl.contact.id)
				.then(function (res) {
					ContactCtrl.contact.connectedClients = res.data.connectedClients;
					$scope.relations = ContactCtrl.contact.connectedClients || [];
					getRelatedClients();
				})
				.catch(e => console.log('get contact error', e));
		};

		AppService.loadedPromise.then(init).catch(e => console.log('load contactRelations error', e));
	}
]);

'use strict';

angular.module('upResources').factory('Onboarding', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	'$rootScope',
	'AppService',
	'$translate',
	'$state',
	function Onboarding($resource, $q, URL, API, ResourceHelper, $rootScope, AppService, $translate, $state) {
		var Resource = null;
		var baseUrl = URL + API + 'onboarding/';
		Resource = $resource(
			baseUrl,
			{},
			{
				update: { method: 'POST', isArray: false }
			}
		);

		var Model = {
			update: function (obj) {
				return Resource.update(obj).$promise.then(function (res) {
					var metadata = AppService.getMetadata();
					metadata.listOnboarding = res.data;

					AppService.setMetadata(metadata);

					$rootScope.$broadcast('onboarding.updated', metadata);
				});
			},
			updateStep: function (key) {
				var onboarding = AppService.getMetadata().listOnboarding;
				if (onboarding && onboarding.track && !onboarding.track[key]) {
					onboarding.track[key] = true;

					return Model.update(onboarding);
				}
			},
			setListIntro: function (key) {
				var onboarding = AppService.getMetadata().listOnboarding;
				if (onboarding && onboarding.listIntro && !onboarding.listIntro[key]) {
					onboarding.listIntro[key] = true;

					return Model.update(onboarding);
				}
			}
		};

		$rootScope.$on('AppService.loaded', function () {
			var t = $translate.instant;
			Model.stepMap = {
				movie: {
					title: t('onboarding.step.movie.title'),
					description: t('onboarding.step.movie.description'),
					descriptionLong: t('onboarding.step.movie.descriptionLong'),
					wistiaId: {
						maeasy: '831y1qkwqx',
						magrow: '831y1qkwqx'
					}
				},
				visits: {
					title: t('onboarding.step.visits.title'),
					description: t('onboarding.step.visits.description'),
					descriptionLong: t('onboarding.step.visits.descriptionLong'),
					action: t('onboarding.step.visits.action')
				},
				accountProfile: {
					title: t('onboarding.step.accountProfile.title'),
					description: t('onboarding.step.accountProfile.description'),
					descriptionLong: t('onboarding.step.accountProfile.descriptionLong'),
					action: t('onboarding.step.accountProfile.action')
				},
				forms: {
					title: t('onboarding.step.forms.title'),
					description: t('onboarding.step.forms.description'),
					descriptionLong: t('onboarding.step.forms.descriptionLong'),
					action: t('onboarding.step.forms.action')
				},
				mailSettings: {
					title: t('onboarding.step.mailSettings.title'),
					description: t('onboarding.step.mailSettings.description'),
					descriptionLong: t('onboarding.step.mailSettings.descriptionLong'),
					action: t('onboarding.step.mailSettings.action')
				},
				groupMail: {
					title: t('onboarding.step.groupMail.title'),
					description: t('onboarding.step.groupMail.description'),
					descriptionLong: t('onboarding.step.groupMail.descriptionLong'),
					action: t('onboarding.step.groupMail.action')
				}
			};
		});

		Model.stepAction = {
			visits: function () {
				$state.go('visitors');
			},
			accountProfile: function () {
				$state.go('administration.accountProfile');
			},
			forms: function () {
				$state.go('react-root-forms');
			},
			mailSettings: function () {
				$state.go('administration.mailSettings');
			},
			groupMail: function () {
				$state.go('react-root-mail-campaigns');
			}
		};

		return Model;
	}
]);

import React, { ComponentProps, useMemo } from 'react';
import { useSelectLanguage } from 'App/components/Inputs/Selects/StaticSelect';
import { Select, Flag } from '@upsales/components';
import _ from 'lodash';
import { locales } from 'App/helpers/regionHelpers';
import T from 'Components/Helpers/translate';

const localeToOption = (locale: (typeof locales)[0]) => ({
	id: locale.locale,
	title: T(locale.lang),
	country: locale.country
});

type MappedLocale = ReturnType<typeof localeToOption>;

export const LocaleSelect = ({
	value,
	language,
	...props
}: Omit<ComponentProps<typeof Select<MappedLocale>>, 'value' | 'options'> & {
	value: string | null;
}) => {
	const options = useMemo(() => {
		return _.sortBy(locales.map(localeToOption), 'title');
	}, []);
	const translatedLanguage = useSelectLanguage(language);

	const selected = useMemo(() => (value ? options.find(z => z.id === value) ?? null : null), [value, options]);
	return (
		<Select
			language={translatedLanguage}
			value={selected}
			options={options}
			renderItemLeft={item => <Flag code={item.country.toLowerCase()} />}
			{...props}
		/>
	);
};

export default LocaleSelect;

import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('MarketRejectlist', [
	'$scope',
	'Account',
	'MarketRejectlist',
	'AppService',
	function ($scope, Account, MarketRejectlist, AppService) {
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'marketRejectlist';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			rejectlist: []
		};

		$scope.$on(MarketRejectlist.events.deleted, function (eventObj, res) {
			_.remove(AdminSubCtrl.rootData.pageData.rejectlist, { id: res.id });
		});

		$scope.$on(MarketRejectlist.events.added, function (eventObj, added) {
			AdminSubCtrl.rootData.pageData.rejectlist.push(added);
		});

		AdminSubCtrl.rootData.addAccount = function () {
			const excludeIds = _.pluck(AdminSubCtrl.rootData.pageData.rejectlist, 'clientId');
			openModal('FindClient', {
				excludeIds,
				onClose: client => {
					if (client) {
						MarketRejectlist.save({
							clientId: client.id,
							name: client.name,
							dunsNo: client.dunsNo ? client.dunsNo : null
						});
					}
				}
			});
		};

		AdminSubCtrl.rootData.removeFromRejectlist = function (item) {
			MarketRejectlist.delete(item);
		};

		var init = function () {
			MarketRejectlist.list().then(function (res) {
				AdminSubCtrl.rootData.pageData.rejectlist = res.data;
				AdminSubCtrl.rootData.pageLoading = false;
			});
		};

		AppService.loadedPromise.then(init);
	}
]);

import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { FilterConfig } from '../filterConfigs/FilterConfig';

export default function getAppSyncStatusFilter(app: { id: number; name: string }): FilterConfig {
	const getAngularModule = require('../angularHelpers/getAngularModule').default;
	const t = getAngularModule('$translate');

	return {
		title: t('integrations.appSyncStatus', { name: app.name }),
		type: 'raw',
		inputType: 'select',
		multiple: true,
		displayType: 'list',
		dataPromise: function () {
			const data = [
				{ id: 0, name: t('integration.status.inProgress') },
				{ id: 1, name: t('integration.status.success') },
				{ id: 2, name: t('integration.status.failed') }
			];

			return Promise.resolve({ data });
		},
		generate: function () {
			return {
				value: []
			};
		},
		build: function (filter: any, rb: RequestBuilder, getField: (field: string) => any) {
			var val: [number] = filter.value;
			if (!val || !val.length) {
				return;
			}
			const or = rb.orBuilder();

			val.forEach(status => {
				or.next();
				if (status === 2) {
					const innerGroup1 = or.groupBuilder();
					innerGroup1.addFilter(
						getField('lastIntegrationStatus.integrationId'),
						comparisonTypes.Equals,
						app.id
					);

					innerGroup1.addFilter(
						getField('lastIntegrationStatus.callbackStatus'),
						comparisonTypes.GreaterThanEquals,
						2
					);
					innerGroup1.done();

					or.next();

					const innerGroup2 = or.groupBuilder();
					innerGroup2.addFilter(
						getField('lastIntegrationStatus.integrationId'),
						comparisonTypes.Equals,
						app.id
					);

					innerGroup2.addFilter(
						getField('lastIntegrationStatus.callbackStatus'),
						comparisonTypes.GreaterThanEquals,
						2
					);
					innerGroup2.done();
				} else {
					const group = or.groupBuilder();
					group.addFilter(getField('lastIntegrationStatus.integrationId'), comparisonTypes.Equals, app.id);

					group.addFilter(getField('lastIntegrationStatus.callbackStatus'), comparisonTypes.Equals, status);
					group.done();
				}
			});
			or.done();
		}
	};
}

'use strict';

angular.module('upDirectives').directive('upDisplayAdTargets', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/templates/upDisplayAdTargets.tpl.html?file'),
		replace: true,
		scope: {
			targets: '=',
			type: '=',
			updatewatcher: '=',
			subtype: '='
		},
		controller: [
			'$scope',
			'AppService',
			'Ads',
			'AdService',
			function ($scope, AppService, Ads, AdService) {
				var Ctrl = $scope;
				$scope.loading = true;

				var method;

				$scope.$on('adCampaign.targetChange', function (e, obj) {
					if (obj.type !== $scope.type) {
						return;
					}
					Ctrl.loading = true;
					Ctrl.targets = obj.target;

					method(function (err) {
						Ctrl.error = err;
						Ctrl.loading = false;
					});
				});

				$scope.mapAbmTargets = function (cb) {
					$scope.loadingAbmTargets = true;
					$scope.campaigns = [];
					$scope.accounts = [];
					$scope.campaignsTooltip = '';
					$scope.accountsTooltip = '';

					AdService.mapCampaigns($scope.customerId, $scope.targets, $scope.campaigns, function (err) {
						if (err) {
							$scope.loadingAbmTargets = false;
							$scope.loadingAbmTargetsErr = err;
							return cb(err);
						}
						AdService.mapAccounts($scope.customerId, $scope.targets, $scope.accounts, function (err) {
							$scope.loadingAbmTargets = false;
							$scope.loadingAbmTargetsErr = err;
							if (err) {
								return cb(err);
							}
							_.forEach($scope.accounts, function (a) {
								$scope.accountsTooltip += a.name + '\n';
							});
							_.forEach($scope.campaigns, function (c) {
								$scope.campaignsTooltip += c.name + '\n';
							});
							return cb(err);
						});
					});
				};

				$scope.mapRetargetingTargets = function (cb) {
					$scope.selectedPages = [];
					$scope.webPages = [];
					$scope.webPagesWildCard = [];
					$scope.ignoreWebPages = [];
					$scope.ignoreWebPagesWildCard = [];
					AdService.mapPages($scope.customerId, $scope.targets, $scope.selectedPages, function (err) {
						if (err) {
							return cb(err);
						}
						$scope.webPages = _.where($scope.selectedPages, { targetType: Ads.targetTypes.webPage });
						$scope.webPagesTooltip = '';
						$scope.webPagesWildCard = _.where($scope.selectedPages, {
							targetType: Ads.targetTypes.webPageWildCard
						});
						$scope.webPagesWildCardTooltip = '';
						$scope.ignoreWebPages = _.where($scope.selectedPages, {
							targetType: Ads.targetTypes.ignoreWebPage
						});
						$scope.ignoreWebPagesTooltip = '';
						$scope.ignoreWebPagesWildCard = _.where($scope.selectedPages, {
							targetType: Ads.targetTypes.ignoreWebPageWildCard
						});
						$scope.ignoreWebPagesWildCardTooltip = '';
						_.forEach($scope.webPages, function (w) {
							$scope.webPagesTooltip += w.url + '\n';
						});
						_.forEach($scope.webPagesWildCard, function (w) {
							$scope.webPagesWildCardTooltip += w.url + '\n';
						});
						_.forEach($scope.ignoreWebPages, function (w) {
							$scope.ignoreWebPagesTooltip += w.url + '\n';
						});
						_.forEach($scope.ignoreWebPagesWildCard, function (w) {
							$scope.ignoreWebPagesWildCardTooltip += w.url + '\n';
						});
						return cb();
					});
				};

				$scope.mapLocationTargets = function (cb) {
					$scope.loadingLocationTargets = true;
					$scope.countries = [];
					$scope.regions = [];
					$scope.countriesAndRegions = [];
					$scope.countriesTooltip = '';
					$scope.regionsTooltip = '';

					AdService.mapCountries($scope.customerId, $scope.targets, $scope.countries, function (err) {
						if (err) {
							$scope.loadingLocationTargets = false;
							$scope.loadingLocationTargetsErr = err;
							return cb(err);
						}
						AdService.mapRegions($scope.customerId, $scope.targets, $scope.regions, function (err) {
							$scope.loadingLocationTargets = false;
							$scope.loadingLocationTargetsErr = err;
							$scope.countriesAndRegions = $scope.countries.concat($scope.regions);
							_.forEach($scope.countries, function (c) {
								$scope.countriesTooltip += c.parsedName + '\n';
							});
							_.forEach($scope.regions, function (r) {
								$scope.regionsTooltip += r.name + '\n';
							});
							return cb(err);
						});
					});
				};

				var init = function () {
					$scope.customerId = AppService.getCustomerId();

					if ($scope.type === 'targetRetargeting') {
						method = $scope.mapRetargetingTargets;
					}
					if ($scope.type === 'targetAbm') {
						method = $scope.mapAbmTargets;
					}
					if ($scope.type === 'targetLocation') {
						method = $scope.mapLocationTargets;
					}

					if (!method) {
						$scope.loading = false;
						return;
					}
					method(function (err) {
						$scope.error = err;
						$scope.loading = false;
					});
				};
				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(init);
			}
		]
	};
});

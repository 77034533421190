require('../../upsales/common/directives/index.js');
require('../../upsales/common/directives/upAccounts.js');
require('../../upsales/common/directives/upCampaigns.js');
require('../../upsales/common/directives/upOptins.js');
require('../../upsales/common/directives/upCategories.js');
require('../../upsales/common/directives/upInlineEdit.js');
require('../../upsales/common/directives/upScrollTo.js');
require('../../upsales/common/directives/upCustomField.js');
require('../../upsales/common/directives/upNoResultRow.js');
require('../../upsales/common/directives/upLoadRow.js');
require('../../upsales/common/directives/upActivityRow.js');
require('../../upsales/common/directives/upFocus.js');
require('../../upsales/common/directives/upErrorRow.js');
require('../../upsales/common/directives/upDatepicker.js');
require('../../upsales/common/directives/upPlaceholder.js');
require('../../upsales/common/directives/upTable.js');
require('../../upsales/common/directives/upTable2.js');
require('../../upsales/common/directives/upListFilters.js');
require('../../upsales/common/directives/upBigAddressMap.js');
require('../../upsales/common/directives/upToggle.js');
require('../../upsales/common/directives/upScrollClass.js');
require('../../upsales/common/directives/upPaginator.js');
require('../../upsales/common/directives/upInsertSign.js');
require('../../upsales/common/directives/upTruncate.js');
require('../../upsales/common/directives/upAvatar.js');
require('../../upsales/common/directives/upRecipientSelect.js');
require('../../upsales/common/components/widgets/upwLatestAccount.js');
require('../../upsales/common/components/widgets/upwNextActivity.js');
require('../../upsales/common/components/widgets/upwLeadScore.js');

require('../../upsales/common/directives/upCurrency.js');
require('../../upsales/common/directives/mailTo.js');
require('../../upsales/common/directives/upInfo.js');
require('../../upsales/common/directives/upOrderRow.js');
require('../../upsales/common/directives/upComparisonTypeToggle.js');
require('../../upsales/common/directives/upDynamicBindings.js');
require('../../upsales/common/directives/select2Utils.js');
require('../../upsales/common/directives/notifyError.js');
require('../../upsales/common/directives/fileDropzone.js');
require('../../upsales/common/directives/upFileTypeIcon.js');
require('../../upsales/common/directives/upValueSuggest.js');
require('../../upsales/common/directives/upCustomFilterInput.js');
require('../../upsales/common/directives/upReactFilterTable.js');
require('../../upsales/common/directives/emptyBind.js');
require('../../upsales/common/directives/upCkeditor.js');
require('../../upsales/common/directives/cardNav.js');
require('../../upsales/common/directives/upElevio.js');
require('../../upsales/common/directives/upListViewActions.js');
require('../../upsales/common/directives/upInlineAction.js');

require('../../upsales/common/directives/inputs/upUserSelect.js');
require('../../upsales/common/directives/inputs/upCountrySelect.js');
require('../../upsales/common/directives/inputs/upActivityTypes.js');
require('../../upsales/common/directives/inputs/upProductSelect.js');
require('../../upsales/common/directives/inputs/upMailCampaignSelect.js');
require('../../upsales/common/directives/inputs/upPageSelect.js');
require('../../upsales/common/directives/inputs/upFormSelect.js');
require('../../upsales/common/directives/inputs/upOrderStage.js');
require('../../upsales/common/directives/inputs/selectHelper.js');
require('../../upsales/common/directives/inputs/multiEmailSelect.js');
require('../../upsales/common/directives/inputs/upLeadSourceSelect.js');
require('../../upsales/common/directives/inputs/upRoleSelect.js');
require('../../upsales/common/directives/inputs/upSegmentSelect.js');
require('../../upsales/common/directives/inputs/upStaticValues.js');
require('../../upsales/common/directives/inputs/upMailCampaignCategorySelect.js');
require('../../upsales/common/directives/inputs/upFieldTranslation.js');
require('../../upsales/common/directives/inputs/standardFieldInput.js');
require('../../upsales/common/directives/inputs/upLanguage.js');

require('../../upsales/common/directives/upMaskValue.js');
require('../../upsales/common/directives/upVoice.js');
require('../../upsales/common/directives/upLeadStatus.js');
require('../../upsales/common/directives/upFormSubmitFields.js');

require('../../upsales/common/directives/event/upEventEmail.js');
require('../../upsales/common/directives/event/upEventContact.js');
require('../../upsales/common/directives/event/upEventClient.js');
require('../../upsales/common/directives/event/upEventMail.js');
require('../../upsales/common/directives/event/upEventVisit.js');
require('../../upsales/common/directives/event/upEventForm.js');
require('../../upsales/common/directives/event/upEventActivity.js');
require('../../upsales/common/directives/event/upEventAppointment.js');
require('../../upsales/common/directives/event/upEventOrder.js');
require('../../upsales/common/directives/event/upEventOpportunity.js');
require('../../upsales/common/directives/event/upEventNews.js');
require('../../upsales/common/directives/event/upEventManual.js');
require('../../upsales/common/directives/event/upEventLead.js');
require('../../upsales/common/directives/event/upEventIcon.js');
require('../../upsales/common/directives/event/upEventAgreement.js');
require('../../upsales/common/directives/event/upEventEsign.js');
require('../../upsales/common/directives/event/upEventMailCampaign.js');
require('../../upsales/common/directives/event/upEventMarketingCustom.js');
require('../../upsales/common/directives/event/upEventSignals.js');
require('../../upsales/common/directives/upGallery.js');
require('../../upsales/common/directives/upFormTemplate.js');
require('../../upsales/common/directives/upChangeValid.js');
require('../../upsales/common/directives/ngDebounce.js');
require('../../upsales/common/directives/upDisplayAdTargets.js');
require('../../upsales/common/directives/upRatingLabel.js');
require('../../upsales/common/directives/upStepInt.js');
require('../../upsales/common/directives/upColorpicker.js');
require('../../upsales/common/directives/validNumber.js');
require('../../upsales/common/directives/upEsignProgress.js');
require('../../upsales/common/directives/upAccountChartSales.js');
require('../../upsales/common/directives/upTypeAhead.js');
require('../../upsales/common/directives/flashSidebarButton.js');
require('../../upsales/common/directives/uiElements.js');
require('../../upsales/common/directives/uiElementsReact.js');
require('../../upsales/common/directives/upFormDashboard.js');
require('../../upsales/common/directives/upSoliditetMatcher.js');
require('../../upsales/common/directives/deleteContentModalBody.js');
require('../../upsales/common/directives/inlineDeleteModal.js');
require('../../upsales/common/directives/toggle-priority-flag.js');
require('../../upsales/common/directives/reactDriver.js');
require('../../upsales/common/directives/react-roots/upBannerEditorRoot.js');
require('../../upsales/common/directives/react-roots/upCreativeTemplatesRoot.js');
require('../../upsales/common/directives/react-roots/upCellPhoneRoot.js');
require('../../upsales/common/directives/react-roots/upFlashRoot.js');
require('../../upsales/common/directives/react-roots/upVoiceRoot.js');
require('../../upsales/common/directives/react-roots/upLandingpageEditorRoot.js');
require('../../upsales/common/directives/react-roots/upFindProspectsRoot.js');
require('../../upsales/common/directives/react-roots/upAdminRoot.js');
require('../../upsales/common/directives/react-roots/upAdminSidebarRoot.js');
require('../../upsales/common/directives/react-roots/upMailCampaignRoot.js');
require('../../upsales/common/directives/react-roots/upAudioRoot.js');
require('../../upsales/common/directives/react-roots/createAccountModal.js');
require('../../upsales/common/directives/react-roots/upNotesRoot.js');
require('../../upsales/common/directives/react-roots/upPeriodiseRoot.js');
require('../../upsales/common/directives/react-roots/upPeriodizationChartRoot.js');
require('../../upsales/common/directives/react-roots/upSharedViewsRoot.js');
require('../../upsales/common/directives/react-roots/upDecisionMakerRoot.js');
require('../../upsales/common/directives/react-roots/upJourneyStepDisplayRoot.js');
require('../../upsales/common/directives/react-roots/upFileStorageAppsRoot.js');
require('../../upsales/common/directives/react-roots/upMailTemplateHeader.js');
require('../../upsales/common/directives/react-roots/upRequestChangePassword.js');
require('../../upsales/common/directives/react-roots/upActivityOutcomeRoot.js');
require('../../upsales/common/directives/react-roots/upActivityTimelineRoot.js');

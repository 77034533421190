import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button } from '@upsales/components';
import { importConfigChanged, navigateNextImport } from 'Store/reducers/OnboardingReducer';

import IntegrationHelper from 'App/helpers/IntegrationHelper';

const OnboardingImport = new Bem('OnboardingImport');
const StepWrapClass = new Bem('StepWrap');

const mapStateToProps = state => ({
	importFields: state.Onboarding.importFields,
	importConfig: state.Onboarding.importConfig,
	currentSubstepId: state.Onboarding.currentSubstepId,
	importStepLoading: state.Onboarding.importStepLoading
});

const mapDispatchToProps = {
	importConfigChanged,
	navigateNextImport
};

class ImportConfig extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			buttonText: T('onboarding.importToUpsales')
		};

		this.getIntegrationLang = IntegrationHelper.getIntegrationLang.bind('onboarding-import', '');
		this.specialButton = this.specialButton.bind(this);
	}

	specialButton(disabled) {
		const { navigateNextImport, importStepLoading } = this.props;

		return (
			<Button
				block
				size="xl"
				onClick={() => navigateNextImport({ phase: 2 })}
				className={StepWrapClass.elem('Button')}
				disabled={disabled}
				loading={importStepLoading}
			>
				{this.lang.buttonText}
			</Button>
		);
	}

	render() {
		const { getIntegrationLang, props, specialButton } = this;
		const { importFields, importConfig, importConfigChanged } = props;
		const fields = importFields.filter(field => field.section === 'config');

		const disabled = fields.reduce((disabled, field) => {
			if (disabled) {
				return true;
			}

			if (field.externalValidation) {
				return !(field.$tested && field.$tested.valid);
			} else if (field.$required) {
				const value = importConfig[field.name];
				return !value || (Array.isArray(value) && !value.length);
			}
			return false;
		}, false);

		const nextButton = specialButton(disabled);

		return (
			<div className={OnboardingImport.b()}>
				<ReactTemplates.admin.standardIntegrations.fields
					getIntegrationLang={getIntegrationLang}
					config={importConfig}
					onChange={importConfigChanged}
					fields={fields}
					showHero={true}
					onBtnClick={function () {}}
				/>
				{nextButton}
			</div>
		);
	}
}

ImportConfig.propTypes = {
	importFields: PropTypes.array,
	importConfig: PropTypes.object,
	importConfigChanged: PropTypes.func,
	navigateNextImport: PropTypes.func,
	importStepLoading: PropTypes.bool
};

export const detached = ImportConfig;
export default connect(mapStateToProps, mapDispatchToProps)(ImportConfig);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import { changeView, setAssignData, views, assign } from 'Store/reducers/AssignModalReducer';
import './AssignModalAssign.scss';
import { Title, Text, Column, Row, Label, Toggle, Button, Icon } from '@upsales/components';
import AssignModalContacts from './AssignModalContacts';
import AssignModalActivityInfo from './AssignModalActivityInfo';
import AssignButton from './AssignButton';
import logError from 'App/babel/helpers/logError';

export const mapStateToProps = state => ({
	selectedStep: state.AssignModal.assign.step,
	user: state.AssignModal.assign.user,
	selectedContact: state.AssignModal.assign.contact,
	setAsManager: state.AssignModal.assign.setAsManager,
	description: state.AssignModal.assign.description,
	notes: state.AssignModal.assign.notes,
	assigning: state.AssignModal.assign.assigning,
	client: state.AssignModal.client,
	assignableUsers: state.AssignModal.assignableUsers,
	activitiesAndAppointments: state.AssignModal.activitiesAndAppointments
});

const mapDispatchToProps = {
	changeView,
	setAssignData,
	assign
};

class AssignModalAssign extends React.Component {
	constructor(props) {
		super(props);
		const t = window.Tools.$translate;

		this.lang = {
			abort: t('default.abort'),
			chooseUser: t('assign.chooseSeller'),
			settUserAsAccountManager: t('processedBy.settUserAsAccountManager'),
			willBeAssignedTo: t('assign.willBeAssignedTo'),
			toContact: t('assign.toContact'),
			noContactSelected: t('assign.noContactSelected'),
			describeLead: t('assign.describeLead'),
			describeLeadInfo: t('assign.describeLeadInfo'),
			describeLeadInfoPhonecall: t('assign.describeLeadInfoPhonecall'),
			assign: t('default.assign'),
			or: t('default.or').toLowerCase()
		};

		this.steps = [
			{
				number: 1,
				title: t('assign.selectUserToAssign'),
				text: t('assign.assignUserInfo'),
				renderContent: classNames => (
					<div>
						<Label required>{this.lang.chooseUser}</Label>
						<ReactTemplates.INPUTS.upUsers
							className={classNames.elem('user-select')}
							required
							placeholder={this.lang.chooseUser}
							onChange={v => this.props.setAssignData({ user: v, step: 2 })}
							value={this.props.user}
							data={this.props.assignableUsers}
						/>
						<Label className={classNames.elem('manager-toggle-row')}>
							<Toggle
								size="md"
								color="medium-green"
								onChange={v => this.props.setAssignData({ setAsManager: v })}
								checked={this.props.setAsManager}
							/>{' '}
							{this.lang.settUserAsAccountManager}
						</Label>
					</div>
				),
				renderPassedTitle: (classNames, number) => {
					if (!this.props.user) {
						return null;
					}
					return (
						<div
							className={classNames.elem('passed-title')}
							onClick={() => this.props.setAssignData({ step: number })}
						>
							<Text>{this.props.user.name}</Text>{' '}
							<Text color="grey-10">{this.lang.willBeAssignedTo}</Text>{' '}
							<Text>{this.props.client.name}</Text>
						</div>
					);
				},
				titleBtnDisabled: () => false
			},
			{
				number: 2,
				title: t('assign.whoToContact'),
				text: t('assign.whoToContactInfo'),
				renderContent: () => <AssignModalContacts />,
				renderPassedTitle: (classNames, number) => {
					if (!this.props.selectedContact) {
						return null;
					}
					return (
						<div
							className={classNames.elem('passed-title')}
							onClick={() => this.props.setAssignData({ step: number })}
						>
							{this.props.selectedContact ? (
								<div>
									<Text color="grey-10">{this.lang.toContact}</Text>{' '}
									<Text>{this.props.selectedContact.name}</Text>
								</div>
							) : (
								<Text color="grey-10">{this.lang.noContactSelected}</Text>
							)}
						</div>
					);
				},
				titleBtnDisabled: () => !this.props.user
			},
			{
				number: 3,
				title: this.lang.describeLead,
				text: Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					? this.lang.describeLeadInfoPhonecall
					: this.lang.describeLeadInfo,
				renderContent: () => <AssignModalActivityInfo reloadModalPosition={this.props.reloadModalPosition} />,
				renderPassedTitle: () => null,
				titleBtnDisabled: () => false
			}
		];

		this.renderStep = this.renderStep.bind(this);
		this.abort = this.abort.bind(this);

		this.assign = () => {
			props
				.assign()
				.then(props.onAssign)
				.catch(e => logError(e, 'Failed to assign'));
		};
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	abort() {
		if (this.props.activitiesAndAppointments) {
			this.props.changeView(views.ACTIVITIES);
		} else {
			this.props.close();
		}
	}

	renderStep({ number, title, text, renderContent, renderPassedTitle, titleBtnDisabled }, rootClass) {
		const current = this.props.selectedStep === number;
		const passed = this.props.selectedStep > number;
		const classNames = rootClass.elem('step');
		const titleBtnIsDisabled = titleBtnDisabled();
		if (passed) {
			classNames.mod('passed');
		}
		if (current) {
			classNames.mod('active');
		}
		let titleElem = null;
		if (!current && this.props.selectedStep === number - 1 && !titleBtnIsDisabled) {
			titleElem = (
				<Button
					hoverColor="green"
					color="grey"
					type="link"
					className={classNames.elem('title-btn')}
					onClick={() => this.props.setAssignData({ step: number })}
				>
					{title} <Icon name="angle-right" />
				</Button>
			);
		} else {
			const passedTitle = renderPassedTitle(classNames, number);
			titleElem =
				!current && passedTitle ? (
					passedTitle
				) : (
					<Title
						className={classNames
							.elem('title')
							.mod(passed ? 'clickable' : null)
							.b()}
						size={current ? 'lg' : 'sm'}
						color={current ? null : 'grey-10'}
						onClick={passed ? () => this.props.setAssignData({ step: number }) : null}
					>
						{title}
					</Title>
				);
		}

		return (
			<Row key={number} className={classNames}>
				<Column fixedWidth={80} className={classNames.elem('indicator')}>
					<div className={classNames.elem('nr')}>{number}</div>
				</Column>
				<Column className={classNames.elem('content')}>
					{titleElem}
					{current ? (
						<Text color="grey-10" className={classNames.elem('desc')}>
							{text}
						</Text>
					) : null}
					{current ? renderContent(classNames) : null}
				</Column>
			</Row>
		);
	}

	render() {
		const { assign /*, abort, or*/ } = this.lang;
		const classNames = new BemClass('AssignModalAssign');
		const lastStepClass = classNames.elem('step');
		const lastEnabled = this.props.user && this.props.description;
		const submitText = `${assign} ${this.props.user ? this.props.user.name : ''}`;

		return (
			<div className={classNames}>
				{this.steps.map(s => this.renderStep(s, classNames))}
				<Row key={4} className={lastStepClass.mod('last')}>
					<Column fixedWidth={80} className={lastStepClass.elem('indicator')}>
						<div className={lastStepClass.elem('nr')}>{4}</div>
					</Column>
					<Column className={lastStepClass.elem('content')}>
						<div className={lastStepClass.elem('assign-btn')}>
							{lastEnabled ? (
								<AssignButton size="lg" onClick={this.assign} loading={this.props.assigning}>
									{submitText}
								</AssignButton>
							) : (
								<Button size="lg" disabled color="grey">
									{submitText}
								</Button>
							)}
						</div>
					</Column>
				</Row>
			</div>
		);
	}
}

AssignModalAssign.propTypes = {
	mainRef: PropTypes.element,
	changeView: PropTypes.func,
	setAssignData: PropTypes.func,
	close: PropTypes.func,
	onAssign: PropTypes.func,
	assign: PropTypes.func,
	reloadModalPosition: PropTypes.func,
	selectedStep: PropTypes.number,
	setAsManager: PropTypes.bool,
	assigning: PropTypes.bool,
	user: PropTypes.object,
	client: PropTypes.object,
	selectedContact: PropTypes.object,
	description: PropTypes.string,
	notes: PropTypes.string,
	assignableUsers: PropTypes.array,
	activitiesAndAppointments: PropTypes.array
};

export const detached = AssignModalAssign;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalAssign);
export default Component;

import moment from 'moment';
import { mapProperties, parseProperties } from 'Services/ActionProperties';

angular.module('services.actionProperties', []).service('ActionProperties', function () {
	var instance = {};

	instance.map = (target, properties, arrayProperties) => {
		target.push(...mapProperties(properties, arrayProperties));
	};

	instance.parse = (target, properties, arrayProperties) => {
		const parsed = parseProperties(properties, arrayProperties);
		Object.keys(parsed).reduce((result, name) => {
			result[name] = parsed[name];
			return result;
		}, target);
	};

	instance.parseCustomFields = function (availableFields, target, properties, entity, dateTypes) {
		angular.forEach(availableFields, function (field) {
			var key = entity + '.custom_' + field.id.toString();
			if (properties[key] !== undefined) {
				field.value = properties[key];
				if (field.datatype === 'Boolean') {
					// If field.value is truthy, make it true. If it is falsey, make it false.
					if (field.value === '0') {
						field.value = false;
					}
					if (field.value === '1') {
						field.value = true;
					}
					field.value = !!field.value;
				} else if (field.datatype === 'Date' && dateTypes) {
					var found = _.find(dateTypes, function (type) {
						return field.value.indexOf(type.tag) !== -1;
					});
					field.value = found;
				} else if (field.datatype === 'Users') {
					field.value = window.Tools.AppService.getActiveUsers()
						.filter(function (user) {
							return field.value.split(',').includes(String(user.id));
						})
						.map(function (user) {
							return { id: user.id, text: user.name };
						});
				}

				target.push(field);
			}
			// Remove field from properties
			delete properties[entity + '.custom_' + field.id.toString()];
		});

		_.each(target, function (field) {
			_.remove(availableFields, { id: field.id });
		});
	};

	instance.mapCustomFields = function (properties, selectedFields, entity) {
		angular.forEach(selectedFields, function (field) {
			if (field.value) {
				let value;
				if (field.datatype === 'Date' && field.value.tag) {
					field.value = '{{' + field.value.tag + '}}';
				} else if (field.datatype === 'User' && field.value.id) {
					field.value = String(field.value.id);
				} else if (field.datatype === 'Users' && field.value.length && Array.isArray(field.value)) {
					field.value = field.value
						.map(function (value) {
							return value.id;
						})
						.join(',');
				} else if (field.datatype === 'Date' && moment(field.value).format('HH:mm') === '00:00') {
					value = moment(field.value).format('YYYY-MM-DD');
				}
				properties.push({ name: entity + '.custom_' + field.id.toString(), value: value || field.value });
			} else if (field.datatype === 'Boolean' && !field.value) {
				properties.push({ name: entity + '.custom_' + field.id.toString(), value: false });
			} else if (
				_.includes(['Integer', 'Currency', 'Percent', 'Discount'], field.datatype) &&
				field.value === 0
			) {
				properties.push({ name: entity + '.custom_' + field.id.toString(), value: 0 });
			}
		});
	};

	instance.getCustomFieldValue = function (field) {
		if (!field.value) {
			return null;
		}

		if (field.datatype === 'Users' && Array.isArray(field.value)) {
			if (field.value.length) {
				field.value = field.value
					.map(function (value) {
						return value.id;
					})
					.join(',');
			} else {
				field.value = null;
			}
		} else if (field.datatype === 'User' && field.value.id) {
			field.value = String(field.value.id);
		}

		return field.value;
	};

	instance.parseCustomFieldUserValues = function (data) {
		if (data) {
			var customFieldValuesArray = data.split(',');

			if (isNaN(Number(customFieldValuesArray[0]))) {
				return data;
			}

			var userNames = customFieldValuesArray.map(function (value) {
				var newValue = Tools.AppService.getActiveUsers().find(function (user) {
					return user.id === Number(value);
				});

				if (newValue && newValue.name) {
					return newValue.name;
				}

				return newValue;
			});

			return userNames.filter(u => u).join(', ');
		}

		return '';
	};

	return instance;
});

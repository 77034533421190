'use strict';

angular.module('domain.admin').factory('EditSalesboardMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function () {
			return AppService.loadedPromise.then(function () {
				var promises = {
					listViews: AppService.getListViews('salesboard'),
					metadata: $q.when(AppService.getMetadata()),
					stages: $q.when({ data: AppService.getStages('all') }),
					appointmentType: $q.when({ data: AppService.getActivityTypes('appointment', true) })
				};

				return $q.all(promises);
			});
		};
	}
]);

const { escape } = require('lodash');

angular.module('services.utils', []).service('utils', [
	'$q',
	'Contact',
	'RequestBuilder',
	'AppService',
	function ($q, Contact, RequestBuilder, AppService) {
		var instance = {};

		instance.select2 = {
			clientContactsAndRelations: {
				formatSelection: function (contact, clientId) {
					if (contact.client && clientId && contact.client.id !== clientId) {
						return (
							'<i class="fa fa-sitemap related-contact-select-icon" style="font-size:10px;"></i> ' +
							escape(contact.name)
						);
					}

					return escape(contact.name);
				},
				formatResult: function (contact, clientId) {
					var title = '';
					var hasSoftDeployAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
					var openSpan = '<span class="subtitle grey" style="display:block;text-transform:uppercase;">';
					if (contact.titleCategory && contact.title && hasSoftDeployAccess) {
						title += openSpan + escape(contact.titleCategory.value + ' ' + contact.title) + '</span>';
					} else if (contact.titleCategory && !contact.title && hasSoftDeployAccess) {
						title += openSpan + escape(contact.titleCategory.value) + '</span>';
					} else if (contact.title) {
						title += openSpan + escape(contact.title) + '</span>';
					}
					if (contact.client && clientId && contact.client.id !== clientId) {
						title +=
							'<span style="font-size:9px;display:block;" class="related-client-name"><i class="Icon Icon-sitemap"></i> ' +
							escape(contact.client.name) +
							'</span>';
					}

					var info =
						'<div class="icons">' +
						'<b class="Icon' +
						(contact.email ? ' Icon-envelope icon-selected' : ' Icon-envelope-o') +
						'"></b>' +
						'<b class="Icon Icon-phone' +
						(contact.phone ? ' icon-selected' : '') +
						'"></b>' +
						'<b class="Icon Icon-mobile' +
						(contact.cellPhone ? ' icon-selected' : '') +
						'"></b>' +
						'</div>';
					return '<div style="position:relative;">' + escape(contact.name) + title + info + '</div>';
				}
			},
			formatParticipantSelection: function (person, clientId, jqObj) {
				if (person.phone === undefined) {
					jqObj.parent().addClass('user-token');
					return _.escape(person.name);
				}

				jqObj.parent().addClass('contact-token');
				if (person.client && clientId && person.client.id !== clientId) {
					return (
						'<i class="fa fa-sitemap related-contact-select-icon" style="font-size:10px;"></i> ' +
						_.escape(person.name)
					);
				}
				return _.escape(person.name);
			},
			formatParticipantResult: function (person, clientId, jqObj) {
				// For categories
				if (person.children) {
					if (!person.children.length) {
						jqObj.css('display', 'none');
					}
					return escape(person.name);
				}
				var title = '';

				if (person.title) {
					title +=
						'<span class="subtitle grey" style="display:block;text-transform:uppercase;">' +
						escape(person.title) +
						' </span>';
				}
				if (person.client && clientId && person.client.id !== clientId) {
					title +=
						'<span style="font-size:9px;display:block;" class="related-client-name"><i class="Icon Icon-sitemap"></i> ' +
						escape(person.client.name) +
						'</span>';
				}

				var info =
					'<div class="icons">' +
					'<b class="Icon' +
					(person.email ? ' Icon-envelope icon-selected' : ' Icon-envelope-o') +
					'"></b>' +
					'<b class="Icon Icon-phone' +
					(person.phone ? ' icon-selected' : '') +
					'"></b>' +
					'<b class="Icon Icon-mobile' +
					(person.cellPhone ? ' icon-selected' : '') +
					'"></b>' +
					'</div>';
				return '<div style="position:relative;">' + escape(person.name) + title + info + '</div>';
			}
		};

		instance.req = {
			clientContactsAndRelations: function (clientIds, fields, offset = 0) {
				if(!clientIds || !clientIds.length) {
					return Promise.resolve({ data: [], metadata: { total: 0, offset: 0, limit: 0 } });
				}
				
				var contactFilter = new RequestBuilder();
				var customerId = AppService.getCustomerId();
				var or;

				contactFilter.fields = fields || ['id', 'name', 'email', 'client', 'phone', 'cellPhone'];
				// only get active contacts
				contactFilter.addFilter(Contact.attr.active, contactFilter.comparisonTypes.Equals, 1);
				contactFilter.addSort(Contact.attr.name, true);
				contactFilter.offset = offset;

				or = contactFilter.orBuilder();
				or.next();
				or.addFilter(Contact.attr.account, contactFilter.comparisonTypes.Equals, clientIds);
				or.next();
				or.addFilter(Contact.attr.connectedClients, contactFilter.comparisonTypes.Equals, clientIds);
				or.done();

				return Contact.customer(customerId)
					.find(contactFilter.build())
					.then(function (res) {
						return res;
					});
			}
		};

		return instance;
	}
]);

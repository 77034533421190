import formSubmitFilters from 'App/babel/filterConfigs/FormSubmit';
const FormSubmitFilters = formSubmitFilters();

angular.module('upAttributes').service('FormSubmitAttributes', [
	'FilterType',
	'DisplayType',
	'AppService',
	'$q',
	'$translate',
	'FeatureHelper',
	function (FilterType, DisplayType, AppService, $q, $translate, FeatureHelper) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				formId: {
					title: 'default.account',
					field: 'formId',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				form: {
					title: 'form.form',
					field: 'form.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					parent: 'form',
					attr: {
						id: {
							field: 'form.id',
							type: FilterType.Number
						},
						title: {
							field: 'form.title',
							type: FilterType.String
						},
						name: {
							field: 'form.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: 'form.name',
					selectableColumn: true
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					parent: 'contact',
					sortable: 'contact.name',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				contactAndClient: {
					title: 'default.contactAndClient',
					field: 'contactAndClient',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				clientStatus: {
					title: 'default.clientHistory',
					field: 'client.status',
					type: FilterType.String,
					selectableColumn: false
				},
				eventTypes: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'eventTypes',
					title: 'scoreboard.eventTypes',
					selectableColumn: true
				},
				client: {
					title: 'default.account',
					field: 'client.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						},
						user: {
							field: 'client.user',
							type: FilterType.String
						},
						category: {
							field: 'client.category',
							type: FilterType.String
						}
					}
				},
				processedDate: {
					title: 'default.date',
					field: 'processedDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: [
						{ title: 'date.year', field: 'date', type: 'year' },
						{ title: 'date.month', field: 'date', type: 'month' }
					],
					sortable: 'processedDate',
					selectableColumn: true
				},
				regDate: {
					title: 'default.wasCreated',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				score: {
					title: 'default.score',
					field: 'score',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'score'
				},
				status: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'status',
					title: 'default.clientHistory',
					elevioId: 663,
					selectableColumn: true
				},
				assigned: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'assigned',
					title: 'assign.assignedTo',
					elevioId: 242,
					selectableColumn: true
				},
				landingPage: {
					title: 'default.type',
					field: 'form.landingPageBody',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					selectableColumn: true
					//sortable:
				},
				utmCampaign: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'visit.leadSource.utm.campaign',
					title: 'column.utmCampaign',
					selectableColumn: true,
					sortable: 'visit.leadSource.utm.campaign'
				},
				utmContent: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'visit.leadSource.utm.content',
					title: 'column.utmContent',
					selectableColumn: true,
					sortable: 'visit.leadSource.utm.content'
				},
				utmMedium: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'visit.leadSource.utm.medium',
					title: 'column.utmMedium',
					selectableColumn: true,
					sortable: 'visit.leadSource.utm.medium'
				},
				utmSource: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'visit.leadSource.utm.source',
					title: 'column.utmSource',
					selectableColumn: true,
					sortable: 'visit.leadSource.utm.source'
				},
				utmTerm: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'visit.leadSource.utm.term',
					title: 'column.utmTerm',
					selectableColumn: true,
					sortable: 'visit.leadSource.utm.term'
				},
				leadSource: {
					title: 'default.leadsource',
					field: 'visit.leadSource',
					displayType: DisplayType.String,
					selectableColumn: true,
					elevioId: 241,
					sortable: 'visit.leadSource.source',
					attr: {
						type: {
							field: 'visit.leadSource.type',
							type: FilterType.Number
						},
						source: {
							field: 'visit.leadSource.source',
							type: FilterType.String
						},
						value: {
							field: 'visit.leadSource.value',
							type: FilterType.String
						}
					}
				},
				users: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'users',
					title: 'default.accountManager',
					sortable: 'client.users.name',
					filterName: 'userId',
					selectableColumn: true
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				}
			};

			var filters = [
				{
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					search: true,
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.accounts',
					multiComparitors: true,
					field: 'client.id'
				},
				{
					multiple: true,
					field: 'contact.id',
					title: 'default.contacts',
					inputType: 'select',
					resource: 'Contact',
					type: 'list',
					search: true,
					multiComparitors: true,
					searchField: 'name',
					displayText: function (res) {
						return res.name + (res.client ? ' <i class="gray">(' + res.client.name + ')</i>' : '');
					}
				}
			];

			const standardFilters = { ...FormSubmitFilters };

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.ClientJourneyStep.hide = false;
					standardFilters.ContactJourneyStep.hide = false;
				}

				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;
			}

			return {
				getAll: function () {
					return attributes;
				},
				filters: filters,
				standardFilters: standardFilters,
				attr: attributes,
				keys: {
					ID: 'id',
					FORMID: 'formId',
					REGDATE: 'regDate'
				}
			};
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, ModalHeader, ModalContent, ModalControls, Button, Title } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import { init, selectIntegration, acceptTerms } from 'App/babel/store/reducers/ChooseMailIntegrationReducer';
import IntegrationHelper from 'App/helpers/IntegrationHelper';
import Field from 'App/babel/components/Admin/StandardIntegrations/Field';
import { configChanged, onRequestOauth, onRequestOauth2 } from 'App/babel/store/reducers/ConfigIntegration';

const mapStateToProps = state => ({
	configIntegration: state.ConfigIntegration
});

const mapDispatchToProps = {
	init,
	selectIntegration,
	configChanged,
	onRequestOauth,
	onRequestOauth2,
	acceptTerms
};

export class Terms extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			terms: translate('default.termsShort'),
			accept: translate('integration.acceptTerms'),
			abort: translate('default.abort')
		};
	}

	connectOauth = () => {
		const { acceptTerms, integration, sendEmailModalParams } = this.props;

		const oauthRedirectState = {
			integrationId: integration.id,
			state: Tools.$state.current.name,
			stateParams: Tools.$state.params
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
			oauthRedirectState.drawer = 'NewSingleMail';
		} else {
			oauthRedirectState.modal = 'sendEmail';
			oauthRedirectState.modalParams = sendEmailModalParams;
		}

		acceptTerms(oauthRedirectState);
	};

	render() {
		const { integration, reject, configIntegration, configChanged, onRequestOauth, acceptTerms } = this.props;
		const classes = new Bem('ChooseMailIntegration');
		let button;

		if (configIntegration.gettingSettings) {
			button = <Button color="bright-blue" loading={true} />;
		} else {
			const oauthField = configIntegration.fields.find(field => field.type === 'oauth2');

			if (oauthField) {
				button = (
					<Field
						field={{ ...oauthField, size: 'md' }}
						value={null}
						onChange={configChanged}
						integration={configIntegration.integration}
						gettingOauth={
							configIntegration.gettingOauth ||
							configIntegration.savingConfig ||
							configIntegration.savingContract
						}
						oauthErr={configIntegration.oauthErr}
						oauth={configIntegration.oauth}
						gettingOauthErr={configIntegration.gettingOauthErr}
						onRequestOauth={onRequestOauth}
						onRequestOauth2={this.connectOauth}
						getIntegrationLang={IntegrationHelper.getIntegrationLang.bind(null, '')}
						onBtnClick={() => {}}
					/>
				);
			} else {
				button = (
					<Button color="bright-blue" onClick={() => acceptTerms(null, integration)}>
						{this.lang.accept}
					</Button>
				);
			}
		}

		return (
			<div className={classes.mod('terms').b()}>
				<ModalHeader className="ModalHeader--no-border-bottom" onClose={this.props.reject} />
				<ModalContent>
					<Title size="lg" bold={true} style={{ marginLeft: 30 }}>
						{`${integration.name} ${this.lang.terms}`}
					</Title>
					<Text size="lg" style={{ margin: '20px 30px 25px 30px' }}>
						<pre>{integration.contract.body}</pre>
					</Text>
				</ModalContent>
				<ModalControls>
					{button}
					<Button color="grey" type="link" onClick={() => reject()}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

Terms.propTypes = {
	integration: PropTypes.object.isRequired,
	reject: PropTypes.func.isRequired,
	configIntegration: PropTypes.object.isRequired,
	configChanged: PropTypes.func.isRequired,
	onRequestOauth: PropTypes.func.isRequired,
	onRequestOauth2: PropTypes.func.isRequired,
	acceptTerms: PropTypes.func.isRequired,
	sendEmailModalParams: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);

/**
 * Opens a already added filter-object in a up-modal
 * So you can edit the filter built for the query-builder
 *
 * @param  {object} filterObj The filter object to modify
 */

import FilterService from 'App/services/FilterService';

angular.module('upListFilter.controllers').service('FilterService', [
	'$upModal',
	'$injector',
	'RequestBuilder',
	'$translate',
	function ($upModal, $injector, RequestBuilder, $translate) {
		// I extended the "ported" service because the following methods will never be used outside of angular.
		class ExtendedFilterService extends FilterService {
			constructor(...args) {
				super(...args);
			}
		}

		// I don't hink this is used anyware
		ExtendedFilterService.prototype.setCustomFields = function (fields) {
			this.customFields = fields;
		};

		// This is only used in the salesboard
		ExtendedFilterService.prototype.openFilter = function (filterObj, filterConfig, data, filters, change) {
			var self = this;
			var isCustom = filterConfig.type === 'custom';
			var controller = 'FilterController';
			var template = isCustom ? 'customField.tpl.html' : filterConfig.inputType + '.html';
			var resolve = {};
			var view = 'upsales/common/components/listFilters/' + template;

			var params = {
				filterObj: filterObj,
				filterConfig: filterConfig,
				filters: filters,
				change: change
			};

			// Is search
			var isSearch;
			if (filterConfig.search && typeof filterConfig.search === 'function') {
				isSearch = $injector.invoke(filterConfig.search);
			} else {
				isSearch = filterConfig.search;
			}

			let isSalesboardFilter = false;
			if (
				(filterConfig.inputType === 'select' && filterConfig.field === 'currency') ||
				(filterConfig.inputType === 'selectGroup' && filterConfig.field === 'user.id')
			) {
				isSalesboardFilter = true;
			}

			if (isCustom) {
				params.customFields = self.customFields;
			}

			if (filterConfig.controller) {
				controller = filterConfig.controller;
			}

			if (filterConfig.view) {
				view = filterConfig.view;
			}

			if (data) {
				params.meta = {
					data: data
				};
			} else {
				resolve.meta = function () {
					if (filterConfig.inputType !== 'select' && filterConfig.inputType !== 'selectGroup') {
						return { data: [] };
					}
					if (isSearch) {
						return { data: [] };
					} else {
						if (filterConfig.dataPromise) {
							return $injector.invoke(
								filterConfig.dataPromise,
								{},
								{
									customerId: self.customerId,
									filterConfig: filterConfig,
									filterName: filterObj.filterName,
									$translate: $translate.instant
								}
							);
						} else {
							var resource = $injector.get(filterConfig.resource).customer(self.customerId);

							if (filterConfig.resourceType) {
								resource.setType(filterConfig.resourceType);
							}
							var filters = new RequestBuilder();
							if (filterConfig.filters) {
								angular.forEach(filterConfig.filters(), function (filter) {
									filters.addFilter({ field: filter.a }, filter.c, filter.v);
								});
							}

							return resource.find(filters.build());
						}
					}
				};
			}
			return $upModal.create(
				{
					templateUrl: view,
					controller: controller,
					closeOnCurtain: true,
					escapeInInput: true,
					invisibleCurtain: isSalesboardFilter,
					disableBindings: false,
					position: {
						of: '.filters-wrap',
						my: isSalesboardFilter ? 'right top' : 'right top+10',
						at: isSalesboardFilter ? 'right+4 bottom' : 'right bottom'
					},
					resolve: resolve,
					style: isSalesboardFilter ? 'initially-hidden' : null
				},
				params
			);
		};

		return ExtendedFilterService;
	}
]);

import PropTypes from 'prop-types';
import React from 'react';
import ComparisonToggle from '../../ComparisonToggle';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import OrderDrilldown from './OrderOpportunityDrilldown.js';

export default class OpportunityInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			clients: Tools.$translate('default.clients'),
			contacts: Tools.$translate('default.contacts'),
			opportunities: Tools.$translate('default.opportunities').toLowerCase(),
			lessOptions: Tools.$translate('default.lessOptions'),
			moreOptions: Tools.$translate('default.moreOptions')
		};

		this.toggleDrilldown = this.toggleDrilldown.bind(this);
		this.toggleHasOrder = this.toggleHasOrder.bind(this);

		const { filter, registerDrilldown } = this.props;
		registerDrilldown(filter.filterName, OrderDrilldown);
	}

	toggleDrilldown() {
		const { filter, toggleDrilldown } = this.props;
		toggleDrilldown(filter.filterName);
	}

	toggleHasOrder() {
		const { filter, onChange } = this.props;
		const newValue = _.assign({}, filter.value, { hasOpportunity: !filter.value.hasOpportunity });
		onChange(newValue);
	}

	render() {
		const { filter, hasOpenDrilldown } = this.props;

		return (
			<CriteriaRowWrapper rowFlex>
				<div className="criteria-row">
					<div className="comparison-toggle">
						{filter.filterName === 'ContactOpportunityFilters' ? this.lang.contacts : this.lang.clients}
						<ComparisonToggle value={filter.value.hasOpportunity} onChange={this.toggleHasOrder} />
						{this.lang.opportunities}
					</div>
					<div
						className="btn btn-link up-btn btn-bright-blue drilldown-btn btn-lg"
						onClick={this.toggleDrilldown}
					>
						{hasOpenDrilldown ? this.lang.lessOptions : this.lang.moreOptions}
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

OpportunityInput.propTypes = {
	filter: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	hasOpenDrilldown: PropTypes.bool.isRequired,
	toggleDrilldown: PropTypes.func.isRequired,
	registerDrilldown: PropTypes.func.isRequired
};

window.SegmentOpportunityInput = OpportunityInput;

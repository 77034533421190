'use strict';

angular.module('upResources').directive('upElevio', function () {
	return {
		replace: true,
		template: function ($elem, $attr) {
			if ($attr.sidebar !== undefined) {
				return '<b data-elevio-article="{{ elevioId }}" class="fa fa-question-circle"></b>';
			}
			return '<b data-elevio-inline="{{ elevioId }}" class="fa fa-question-circle"></b>';
		},
		link: function (scope, element, attr) {
			scope.elevioId = attr.articleId;
			element.attr('tabindex', '-1');
		}
	};
});

'use strict';
import PropTypes from 'prop-types';

ReactTemplates.dragAndDrop = window.ReactCreateClass({
	timeout: null,

	propTypes: {
		items: PropTypes.array,
		categories: PropTypes.array,
		categoryObject: PropTypes.func,
		itemTemplate: PropTypes.func,
		categoryTemplate: PropTypes.func,
		options: PropTypes.object,
		itemClick: PropTypes.func,
		categoryClick: PropTypes.func,
		skipInactive: PropTypes.bool
	},

	getDefaultProps: function () {
		return {
			options: {
				maxIteration: 5,
				attribute: {
					category: {
						id: 'data-role-id',
						name: 'data-role-name'
					},
					parent: {
						id: 'data-parent-id',
						name: 'data-parent-name'
					},
					sort: 'data-sort-id',
					id: 'data-id'
				},
				dropClass: 'active-drop-target',
				sortKey: 'sortId',
				targetClass: 'admin-table-row'
			},
			skipInactive: false
		};
	},

	getStateFromProps: function (props) {
		return {
			items: props.items,
			categories: props.categories,
			tree: this.buildTree(props.items, props.categories),
			fromIndexDrag: null,
			fromRoleDrag: null,
			toIndexDrag: null,
			skipInactive: props.skipInactive
		};
	},

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},

	UNSAFE_componentWillReceiveProps: function (newProps) {
		var shallUpdate =
			!newProps.items ||
			this.props.items.length !== newProps.items.length ||
			this.props.skipInactive !== newProps.skipInactive ||
			!_.isEqual(this.props.categories, newProps.categories) ||
			!_.isEqual(this.props.items, newProps.items);

		if (shallUpdate) {
			this.setState({
				tree: this.buildTree(newProps.items, newProps.categories),
				categories: newProps.categories,
				items: newProps.items
			});
		}
	},

	getTargetNode: function (node, attribute, maxIteration) {
		if (maxIteration < 0) return false;
		if (!node) return false;
		if (!node.parentNode) return false;
		if (node.getAttribute(attribute)) return node;

		return this.getTargetNode(node.parentNode, attribute, --maxIteration);
	},

	getTargetByClass: function (node, lookingFor) {
		var found = _.includes(node.className, lookingFor);

		if (found) return node;
		if (!node.parentNode) return false;

		return this.getTargetByClass(node.parentNode, lookingFor);
	},

	buildTree: function (items, categories) {
		var self = this;
		var opts = this.props.options;
		var elements = document.getElementsByClassName(opts.dropClass);
		while (elements.length) {
			var regexReplace = new RegExp(opts.dropClass, 'g');
			elements[0].className = elements[0].className.replace(regexReplace, '');
			elements = document.getElementsByClassName(opts.dropClass);
		}

		var productCategoriesById = _.reduce(categories, self.props.categoryObject, {});
		items = _.sortBy(items, opts.sortKey);

		return self.props.buildTree(items, productCategoriesById);
	},

	onDrag: function (event) {
		var opts = this.props.options;
		event.dataTransfer.setData('text', event.target.getAttribute(opts.attribute.id));

		var targetId = event.target.getAttribute(opts.attribute.id);
		var fromIndex = event.target.getAttribute(opts.attribute.sort);
		var fromRole = null;

		var Y = this.getTargetNode(event.target, opts.attribute.parent.id, opts.maxIteration);
		var X = this.getTargetNode(event.target, opts.attribute.category.id, opts.maxIteration);

		if (Y) {
			fromRole = Y.getAttribute(opts.attribute.parent.id);
		} else if (X) {
			fromRole = X.getAttribute(opts.attribute.category.id);
		} else {
		}

		if (fromIndex > -1) {
			this.setState({
				fromIndexDrag: Number(fromIndex),
				fromRoleDrag: Number(fromRole)
			});
		}
	},

	hasClass: function (target, className) {
		return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
	},

	onEnter: function (item, event) {
		var self = this;
		var opts = this.props.options;
		event.preventDefault();

		var target = event.target;

		var elements = document.getElementsByClassName(opts.dropClass);
		while (elements.length) {
			var regexReplace = new RegExp(opts.dropClass, 'g');
			elements[0].className = elements[0].className.replace(regexReplace, '').trim();
			elements = document.getElementsByClassName(opts.dropClass);
		}

		if (!self.timeout) {
			self.timeout = setTimeout(function () {
				self.timeout = null;

				var overSortId = self.getTargetNode(target, opts.attribute.sort, opts.maxIteration);
				if (overSortId) {
					var sortId = overSortId.getAttribute(opts.attribute.sort);

					self.setState({
						toIndexDrag: Number(sortId)
					});
				}
			}, 100);
		}

		var REALTARGET = self.getTargetByClass(target, opts.targetClass);
		var grower = REALTARGET.querySelector('.fake-grow'); //self.getTargetByClass(target, 'fake-grow');
		if (grower) {
			grower.className = 'fake-grow active';

			if (REALTARGET) {
				REALTARGET.className += ' ' + opts.dropClass;
			}
		}
	},

	onDrop: function (event) {
		event.preventDefault();

		var self = this;
		var opts = this.props.options;
		var data = event.dataTransfer.getData('text');
		var roleId = null;
		var roleName = null;
		var fromSortId = null;
		var fromRoleId = null;
		var toSortId = null;
		var toRoleId = null;
		var depth = null;

		var Y = this.getTargetNode(event.target, opts.attribute.parent.id, opts.maxIteration);
		var X = this.getTargetNode(event.target, opts.attribute.category.id, opts.maxIteration);

		if (Y) {
			roleId = Y.getAttribute(opts.attribute.parent.id);
			roleName = Y.getAttribute(opts.attribute.parent.name);

			fromSortId = this.state.fromIndexDrag;
			fromRoleId = this.state.fromRoleDrag;

			toSortId = Y.getAttribute(opts.attribute.sort);
			toRoleId = Y.getAttribute(opts.attribute.parent.id);
		} else if (X) {
			roleId = X.getAttribute(opts.attribute.category.id);
			roleName = X.getAttribute(opts.attribute.category.name);

			fromSortId = this.state.fromIndexDrag;
			fromRoleId = this.state.fromRoleDrag;

			toSortId = X.getAttribute(opts.attribute.sort);
			toRoleId = X.getAttribute(opts.attribute.parent.id);
		} else {
		}

		var elements = document.getElementsByClassName(opts.dropClass);
		while (elements.length) {
			var regexReplace = new RegExp(opts.dropClass, 'g');
			elements[0].className = elements[0].className.replace(regexReplace, '');
			elements = document.getElementsByClassName(opts.dropClass);
		}

		var items = _.cloneDeep(self.state.items);
		var target = {
			id: parseInt(roleId),
			name: roleName
		};

		var savingItem;

		items = _.map(items, function (item, index) {
			var itemProps = self.props.itemTemplate(item);

			if (item.sortId === 0) {
				item.sortId = index;
			}

			if (item.id === parseInt(data)) {
				if (self.state.fromIndexDrag > self.state.toIndexDrag) {
					item.sortId = self.state.toIndexDrag - 1;
				} else if (self.state.fromIndexDrag === self.state.toIndexDrag) {
					item.sortId = self.state.toIndexDrag - 2;
				} else {
					item.sortId = self.state.toIndexDrag + 1;
				}

				if (item[itemProps.categoryName] && roleId) {
					item[itemProps.categoryName].id = parseInt(roleId);
					item[itemProps.categoryName].name = roleName;
				} else if (roleId) {
					item[itemProps.categoryName] = {
						id: parseInt(roleId),
						name: roleName
					};
				} else {
					item[itemProps.categoryName] = null;
				}
				savingItem = item;
			}
			return item;
		});

		items = _.sortBy(items, opts.sortKey);
		var newProducts = self.buildTree(items, self.state.categories);

		self.setState(
			{
				items: items,
				tree: newProducts
			},
			self.props.onChange(items, savingItem)
		);
	},

	getRow: function (item, group, level, isRole) {
		var self = this;

		if (isRole) {
			var classNames = 'admin-table-row is-header level-' + level;
			var childrenLength = [];

			childrenLength = _.filter(item.children, function (child) {
				if (self.props.mergeInactive) {
					return true;
				}
				return child.active === group;
			});

			var classes = 'fa fa-folder-open fa-tw';
			if (self.state.closedCategories) {
				classes = _.includes(self.state.closedCategories, item.$id)
					? 'fa fa-folder fa-tw'
					: 'fa fa-folder-open fa-tw';
			}

			return (
				<div
					className={classNames}
					key={'header-cell-' + item.name.replace(' ', '-') + '-' + item.id + '-' + group}
					data-role-id={item.id}
					data-role-name={item.name}
					onDragEnter={self.onEnter.bind(self, item)}
					onClick={self.props.categoryClick.bind(self, item)}
				>
					<div className="admin-table-cell color-grey" style={{ width: '32px', height: '50px' }}></div>
					<div className="admin-table-cell color-grey positioner">
						<i
							onClick={function () {
								self.props.toggleCategory(item.id);
							}}
							className={classes}
						></i>
					</div>
					<div className="admin-table-cell">
						{item.name} ({childrenLength.length})
					</div>
					<div
						className="admin-table-cell delete-btn"
						onClick={self.props.removeCategory ? self.props.removeCategory.bind(self, item) : null}
					>
						<i className="fa fa-trash"></i>
					</div>
				</div>
			);
		}

		var classNames = 'admin-table-row level-' + level;
		if (self.props.closedCategories && item.role) {
			classNames += _.includes(self.props.closedCategories, item.role.id) ? ' closed' : '';
		}

		if ((!!group && item.active === 1) || (!group && item.active === 0) || self.props.mergeInactive) {
			var itemProps = self.props.itemTemplate(item);

			return (
				<div
					className={classNames}
					draggable="true"
					onDragStart={self.onDrag}
					onDragEnter={self.onEnter.bind(self, item)}
					onClick={function () {
						self.props.itemClick(item);
					}}
					data-id={itemProps.targets.id}
					data-parent-id={itemProps.targets.parentId}
					data-parent-name={itemProps.targets.parentName}
					data-sort-id={itemProps.targets.sortId}
				>
					<div className="fake-grow"></div>
					<div className="admin-table-cell color-grey">
						<i className="fa fa-bars fa-tw"></i>
					</div>
					<div className="admin-table-cell color-grey positioner">
						<i className="fa fa-level-up fa-tw deg90"></i>
					</div>
					<div className={itemProps.name.class}>{itemProps.name.data}</div>
					<div className={itemProps.rightCell.class}>{itemProps.rightCell.data}</div>
				</div>
			);
		}
	},

	getRows: function (array, group, level, first) {
		var self = this;
		var val = [];

		if (first) {
			val.push(self.props.headerTemplate(!!group));
		}

		return _.reduce(
			array,
			function (values, item, index) {
				var itemProps = self.props.itemTemplate(item);

				if (item.type === itemProps.type) {
					var childValues = self.getRows(item.children, group, level + 1, false);

					values.push(self.getRow(item, group, level + 1, true));
					return values.concat(childValues);
				}

				values.push(self.getRow(item, group, level + 1, false));
				return values;
			},
			val
		);
	},

	render: function () {
		var self = this;
		var rows = [];
		rows = rows.concat(self.getRows(this.state.tree, 1, -1, true));
		if (!self.props.skipInactive && !self.props.mergeInactive) {
			rows = rows.concat(self.getRows(this.state.tree, 0, -1, true));
		}

		return (
			<div className="admin-table-body" onDrop={self.onDrop}>
				{rows}
			</div>
		);
	}
});

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Title } from '@upsales/components';
import './CriteriasCard.scss';
import t from '../../Helpers/translate';
import CriteriaTable from '../../Criteria/CriteriaTable';
import { addFilter, removeFilter, editFilter } from 'Store/reducers/JourneyEditorReducer';
import getFakeFilterConfigs from '../../../filters/fakeFilterConfigs';

const mapStateToProps = (state, { type }) => ({
	filters: { config: state.JourneyEdit.filters[type] },
	filtersStr: JSON.stringify(state.JourneyEdit.filters[type]),
	autoFocus: state.JourneyEdit.autoFocus
});

const mapDispatchToProps = {
	addFilter,
	removeFilter,
	editFilter
};

class CriteriasCard extends React.Component {
	constructor(props) {
		super(props);

		this.fakeFilterConfigs = getFakeFilterConfigs();
	}
	render() {
		const { type, filters, filterConfigs, addFilter, removeFilter, editFilter, autoFocus } = this.props;
		const typeText = type === 'and' ? t('default.all') : t('default.any');
		const cardColor = Object.keys(filters.config || {}).length === 0 ? 'light-grey' : 'white';
		return (
			<Card className="CriteriasCard" color={cardColor}>
				<Title className="CriteriasAdd__title">
					{t('segment.include')} {t('segment.contactsWhoMatch')} <b>{typeText.toLowerCase()}</b>{' '}
					{t('segment.followingCriterias')}
				</Title>
				<CriteriaTable
					noHeader
					filters={filters}
					filterConfigs={filterConfigs}
					addFilter={addFilter.bind(null, type)}
					removeFilter={removeFilter.bind(null, type)}
					editFilter={editFilter.bind(null, type)}
					state={{ autoFocus }}
					fakeConfigs={this.fakeFilterConfigs}
				/>
			</Card>
		);
	}
}

CriteriasCard.propTypes = {
	type: PropTypes.oneOf(['and', 'or']).isRequired,
	filters: PropTypes.object.isRequired,
	filterConfigs: PropTypes.object.isRequired,
	addFilter: PropTypes.func.isRequired,
	removeFilter: PropTypes.func.isRequired,
	editFilter: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool
};

CriteriasCard.defaultProps = {
	filterConfigs: {},
	filters: {},
	addFilter: () => {}
};

export const detached = CriteriasCard;
const ConnectedCriteriasCard = connect(mapStateToProps, mapDispatchToProps)(CriteriasCard);
export default ConnectedCriteriasCard;

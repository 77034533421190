import logError from 'App/babel/helpers/logError';

const getOpportunity = appointment => {
	return new Promise((resolve, reject) => {
		Tools.Opportunity.customer(Tools.AppService.getCustomerId())
			.get(appointment.opportunity.id)
			.then(response => {
				return resolve(response.data);
			})
			.catch(e => {
				logError(e, 'Failed to get opportunity');
				return reject(e);
			});
	});
};

export default getOpportunity;

'use strict';

const { Button } = require('@upsales/components');

ReactTemplates.admin.salesboards = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			salesboards: t('default.salesboards'),
			name: t('default.name'),
			newSalesboard: t('admin.newSalesboard'),
			noSalesboards: t('admin.noSalesboards')
		};
	},
	removeBoard: function (board, e) {
		e.stopPropagation();
		this.props.rootData.removeBoard(board);
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;

		var boards = _.map(rootData.pageData.salesboards, function (board, i) {
			return (
				<tr key={'board-' + i} onClick={rootData.editSalesboard.bind(self, board)}>
					<td>{board.title}</td>

					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							<button
								className="up-btn btn-link btn-grey delete-trigger"
								onClick={self.removeBoard.bind(self, board)}
							>
								<b className="fa fa-trash-o"></b>
							</button>
						</div>
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-salesboards">
				<div className="admin-page-header">
					<div className="page-header-text-wrap">
						<div className="page-header-title">{self.lang.salesboards}</div>
					</div>
				</div>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{self.lang.salesboards}</span>
							<div className="pull-right">
								<Button onClick={rootData.editSalesboard.bind(this, null)} size="sm">
									{self.lang.newSalesboard}
								</Button>
							</div>
						</div>

						{boards.length ? (
							<table>
								<thead>
									<tr>
										<th>{self.lang.name}</th>
										<th style={{ width: '60px' }}></th>
									</tr>
								</thead>
								<tbody>{boards}</tbody>
							</table>
						) : null}
						{!boards.length ? <div className="table-no-result">{self.lang.noSalesboards}</div> : null}
					</div>
				</div>
			</div>
		);
	}
});

'use strict';

angular.module('upResources').factory('Event', [
	'$resource',
	'$q',
	'URL',
	'API',
	'EventAttributes',
	'ParseGeneric',
	function Event($resource, $q, URL, API, EventAttributes, ParseGeneric) {
		var EA = EventAttributes();
		var Attribute = EA.attr;
		var Keys = EA.keys;

		const dateFields = [
			'client.hadActivity',
			'client.hasActivity',
			'client.hadOrder',
			'client.hasOrder',
			'client.hadOpportunity',
			'client.hasOpportunity',
			'client.hadAppointment',
			'client.hasAppointment',
			'visit.pages[].endDate',
			'visit.pages[].startDate',
			'mail.lastClickDate',
			'mail.lastReadDate',
			'date'
		];

		var Resource = $resource(
			URL + API + 'events/:id',
			{},
			{
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: false, dateFields })
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				delete: {
					method: 'DELETE',
					isArray: false
				}
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options);
				return Resource.find(params).$promise;
			},

			save: function (event) {
				return Resource.create(event).$promise;
			},

			delete: function (event) {
				return Resource.delete(event).$promise;
			},

			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

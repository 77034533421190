'use strict';

angular.module('upDirectives').directive('upDynamicBindings', [
	'$dynamicBindings',
	function ($dynamicBindings) {
		return {
			restrict: 'A',
			link: function ($scope, e, $attrs) {
				var recursive = $attrs.recursive !== undefined;

				$scope.$on('$dynamicBindingsOff', function () {
					$dynamicBindings.off($scope, recursive);
				});

				$scope.$on('$dynamicBindingsOn', function () {
					$dynamicBindings.on($scope);
				});
			}
		};
	}
]);

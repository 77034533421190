import { Flex, Title } from '@upsales/components';
import React from 'react';

type ListViewHeaderTitleProps = {
	title: string;
	total: number;
};

const ListViewHeaderTitle = ({ title, total }: ListViewHeaderTitleProps) => {
	return (
		<Flex space="pll">
			<Title size="md">{title}</Title>
			{total ? (
				<Title space="mls" color="grey-10" size="sm">
					({total})
				</Title>
			) : null}
		</Flex>
	);
};

export default ListViewHeaderTitle;

'use strict';

angular.module('upModals.controllers').controller('Assign', [
	'$scope',
	'$rootScope',
	'$q',
	'$modalParams',
	'$translate',
	'AppService',
	'$upModal',
	'Activity',
	'Account',
	'User',
	'FeatureHelper',
	function (
		$scope,
		$rootScope,
		$q,
		$modalParams,
		$translate,
		AppService,
		$upModal,
		Activity,
		Account,
		User,
		FeatureHelper
	) {
		var customerId = AppService.getCustomerId();
		var AssignCtrl = this;
		var metadata = AppService.getMetadata();
		var displayUser = function (user) {
			return _.escape(user.name);
		};

		AssignCtrl.loading = true;
		AssignCtrl.saving = false;
		AssignCtrl.account = $modalParams.account;
		AssignCtrl.users = [];
		AssignCtrl.activityDescription = Tools.$translate('default.from') + ' ' + metadata.user.name.split(' ')[0];

		AssignCtrl.selectMe = function () {
			AssignCtrl.user = metadata.user;
		};

		var createSelect = function () {
			var data = [
				{
					name: Tools.$translate('column.userId'),
					children: AssignCtrl.users
				}
			];

			if (AssignCtrl.account.users && AssignCtrl.account.users.length) {
				data.unshift({
					name: Tools.$translate(
						metadata.params.teamAccountManager ? 'default.accountManagers' : 'default.accountManager'
					),
					children: AssignCtrl.account.users
				});
			}

			AssignCtrl.userSelect = {
				data: data,
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				formatSelection: displayUser,
				formatResult: displayUser,
				allowClear: 1
			};
		};

		var init = function () {
			// check current assign

			AssignCtrl.users = AppService.getActiveUsers();
			createSelect();
			Account.customer(customerId)
				.getAssigned(AssignCtrl.account.id)
				.then(function (assignedUser) {
					AssignCtrl.assigned = assignedUser.data;
					AssignCtrl.loading = false;
				})
				.catch(function (err) {
					AssignCtrl.err = err && err.status !== 404 ? err : null;
					AssignCtrl.loading = false;
				});
		};

		AssignCtrl.save = function () {
			AssignCtrl.saving = true;
			Account.customer(customerId)
				.assign(AssignCtrl.account, AssignCtrl.user, { activityDescription: AssignCtrl.activityDescription })
				.then(function (res) {
					var userId = res.data.user;

					if (!FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS)) {
						$scope.resolve(userId);
					} else {
						Activity.customer(customerId)
							.get(res.data.activity)
							.then(function (response) {
								$rootScope.$broadcast('activity.added', response.data);
								$scope.resolve(userId);
							});
					}
				});
		};

		init();
	}
]);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@upsales/components';
import t from 'Components/Helpers/translate';

import './StateTabs.css';

class StateTabs extends PureComponent {
	selectedRef = React.createRef();
	wrapperRef = React.createRef();

	componentDidMount() {
		if (this.wrapperRef.current && this.selectedRef.current) {
			const offsetLeft =
				this.selectedRef.current.offsetLeft +
				this.selectedRef.current.offsetWidth / 2 -
				this.wrapperRef.current.offsetWidth / 2;
			this.wrapperRef.current.scrollTo(offsetLeft, 0);
		}
	}
	render() {
		const selected = Tools.$stateParams.type;
		const { state, tabs } = this.props;
		return (
			<div ref={this.wrapperRef} className="StateTabs">
				<Tabs
					onChange={type => Tools.$state.go('administration.' + state, { type })}
					selected={selected}
					noTransition
					selectedRef={this.selectedRef}
				>
					{tabs
						.filter(
							f =>
								Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature[f.feature]) &&
								!(f.available && !f.available())
						)
						.map(({ type, title }) => (
							<Tab key={type} id={type} title={t(title)} />
						))}
				</Tabs>
			</div>
		);
	}
}

StateTabs.propTypes = {
	state: PropTypes.string.isRequired,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			title: PropTypes.string,
			visible: PropTypes.bool,
			available: PropTypes.func
		})
	)
};

StateTabs.defaultProps = {
	tabs: []
};

export default StateTabs;

import EditStandardFieldModal from '../components/Admin/EditStandardFieldModal';

window.EditStandardFieldsCtrl = scope => {
	let rootNode;

	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	const render = props => {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(React.createElement(EditStandardFieldModal, props), rootNode);
	};

	const actions = {
		onClose: () => scope.reject(),
		onSave: field => {
			scope.resolve(field);
			//Hacky solution but we couldn't find any other way to update the UI,
			//we could get the correct value on init from AppService or API instead but this is only
			//to fix tooltip text in UI so feels kinda overkill and $modalParams
			//should really come with updated data after saving so it should get fixed there
			scope.$modalParams.field.tooltip = field.tooltip;
		}
	};

	const initialStore = {
		field: scope.$modalParams.field,
		name: scope.$modalParams.name
	};
	const init = () => {
		rootNode = document.getElementById('edit-standard-field-modal');
		new DataStore(render, actions, initialStore);
	};

	Tools.AppService.loadedPromise.then(init);
};

import React, { useState, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
	Card,
	Text,
	Headline,
	Block,
	Link,
	Icon,
	CardContent,
	Row,
	Column,
	Button,
	Tooltip,
	Progressbar
} from '@upsales/components';
import BillingAddonTiersModal from '../BillingAddonTiersModal';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { getTierString, getPerXText } from 'App/babel/helpers/addons';
import { buyAddon, addTrialAddon, requestAddon } from 'Store/reducers/BillingReducer';
import './BillingAddonCard.scss';
import BillingAddonModal from '../BillingAddonModal';
import './BillingAddonCard.scss';
import { RootState } from 'Store/index';
import { Addon, TrialAddon } from '../Addon';
import { billingTracker } from 'App/babel/helpers/Tracker';
import moment from 'moment';
import { SlideFade } from '@upsales/components/animations';
import { DefaultButton, PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { NEXT_STEP_TITLES, NEXT_STEPS } from './addonNextSteps';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { useSoftDeployAccess } from 'App/components/hooks';

type BillingAddonCardProps = {
	bought: boolean;
	location: string;
	addon: Addon;
	viewPlan: () => void;
	onAdd?: () => void; // This will be triggered whenver the addon is bought or a trial is added. Use this to transform the ui to show the freshly bought features.
	nextSteps?: (typeof NEXT_STEPS)[keyof typeof NEXT_STEPS];
};

const mapStateToProps = ({ Billing, App }: RootState, props: BillingAddonCardProps) => ({
	canBuy: !!App.self?.billingAdmin,
	buyingAddon: Billing.buyingAddon,
	buyingTrialAddon: Billing.buyingTrialAddon,
	ongoingTrialAddons: Billing.ongoingTrialAddons,
	oldTrial: [...Billing.trialAddons]
		.sort((a, b) => (a.endDate < b.endDate && 1) || -1)
		.find(ta => props.addon.alias === ta.alias),
	trialPeriod: Billing.trialPeriod
});

const mapDispatchToProps = {
	requestAddon,
	buyAddon,
	addTrialAddon
};

const currencyFormat = (num: number, c: string) => Tools.$filter('currencyFormat')(num, c);

const renderBoughtCard = (classes: bemClass, viewPlan: () => void) => {
	return (
		<Row>
			<Column fixedWidth={50} align="center" className={classes.elem('added-icon-col').b()}>
				<Block backgroundColor="success-6" className={classes.elem('added-icon').b()}>
					<Icon name="check" color="success-1" space="mrs" />
				</Block>
			</Column>
			<Column className={classes.elem('added-text-col').b()}>
				<Row>
					<Text size="xl" color="success-6" bold={true}>
						{T('admin.billing.addedToPlan')}
					</Text>
				</Row>
			</Column>
			<Column align="right" size={5} className={classes.elem('add-btn').b()}>
				<ThirdButton size="lg" onClick={viewPlan}>
					{T('admin.billing.viewPlan')}
				</ThirdButton>
			</Column>
		</Row>
	);
};

const renderBuyCard = (
	classes: bemClass,
	addon: Addon,
	onBuy: () => void,
	buyingAddon: string,
	buyButtonText: string
) => {
	return (
		<Row>
			<Column className={classes.elem('price-col').b()}>
				<div className={classes.elem('price').b()}>
					{addon.totalCost === 0 ? (
						<Text color="grey-10" bold={true} size="xl">
							{T('integration.free')}
						</Text>
					) : (
						<Text bold={true} size="xl">
							{currencyFormat(addon.pricePerMonth, addon.currency)}
							<Text color="grey-10">{`/ ${getPerXText(addon)}`}</Text>
						</Text>
					)}
					{addon.tier ? (
						<Text color="grey-10" size="sm">
							{getTierString(addon)}
						</Text>
					) : null}
				</div>
			</Column>
			<Column className={classes.elem('add-btn').b()} align="right" size={5}>
				<PrimaryButton
					size="lg"
					block
					onClick={onBuy}
					disabled={!!buyingAddon && buyingAddon !== addon.alias}
					loading={buyingAddon === addon.alias}
				>
					{buyButtonText}
				</PrimaryButton>
			</Column>
		</Row>
	);
};
const renderTrialCard = (
	classes: bemClass,
	addon: Addon,
	onBuyTrial: PropsFromRedux['addTrialAddon'],
	buyingAddon: string,
	buyingTrialAddon: string,
	trialPeriod: number,
	onBuy: () => void,
	location: string,
	showNextSteps: () => void,
	onAdd?: BillingAddonCardProps['onAdd']
) => {
	return (
		<Row>
			<Column className={classes.elem('price-col').b()}>
				<div className={classes.elem('price').b()}>
					<Row>
						<Text bold={true} size="xl">
							{T('admin.billing.tryForFree.trialPeriod', { trialPeriod })}
						</Text>
						<Tooltip
							className={classes.elem('trial_tooltip').b()}
							title={T('admin.billing.tryForFree.tooltip', {
								trialPeriod,
								pricePerMonth: currencyFormat(addon.pricePerMonth, addon.currency),
								perText: getPerXText(addon)
							})}
							position="top"
						>
							<Icon name="question-circle" />
						</Tooltip>
					</Row>
					<Text color="grey-10" size="sm">
						{`${currencyFormat(addon.pricePerMonth, addon.currency)} ${getPerXText(addon)}`}
					</Text>
				</div>
			</Column>
			<Column className={classes.elem('add-btn').b()} align="right" size={5}>
				<PrimaryButton
					block
					onClick={() => {
						// eslint-disable-next-line promise/catch-or-return
						onBuyTrial(addon).then(() => {
							onAdd?.();
							showNextSteps();
							billingTracker.track(billingTracker.events.STARTED_ADDON_TRIAL, {
								addon: addon.alias,
								location
							});
						});
					}}
					disabled={!!buyingTrialAddon && buyingTrialAddon !== addon.alias}
					loading={buyingTrialAddon === addon.alias}
					className={classes.elem('trial_btn').b()}
				>
					{T('admin.billing.tryForFree')}
				</PrimaryButton>
				<DefaultButton
					block
					onClick={() => onBuy()}
					disabled={!!buyingAddon && buyingAddon !== addon.alias}
					loading={buyingAddon === addon.alias}
				>
					{T('admin.billing.buyNow')}
				</DefaultButton>
			</Column>
		</Row>
	);
};

const renderTrialProgressCard = (classes: bemClass, trialAddon: TrialAddon) => {
	const daysRemaining = moment(trialAddon.endDate).diff(moment(), 'days') + 1;
	const period = moment(trialAddon.endDate).diff(trialAddon.date, 'days');
	const progress = (period - daysRemaining) / period;
	return (
		<Column className={classes.elem('trial-col').b()}>
			<Row>
				<Column>
					<Text size="lg">{T('admin.billing.trialAdded')}</Text>
				</Column>
			</Row>
			<Row>
				<Column>
					<Text color="grey-10" size="sm">
						{T('admin.billing.trialInstalled', {
							date: trialAddon.date.substring(0, 10)
						})}
					</Text>
				</Column>
				<Column align="right">
					<Text color="grey-10" size="sm">
						{daysRemaining === 1
							? T('admin.billing.trialRemaining', { daysRemaining })
							: T('admin.billing.trialRemaining.plural', { daysRemaining })}
					</Text>
				</Column>
			</Row>
			<Progressbar size="sm" color="green" value={progress * 100} />
		</Column>
	);
};

const renderConfirm = (
	addon: Addon,
	buyAddon: PropsFromRedux['buyAddon'],
	aboutToBuyAddon: Addon | null,
	setAboutToBuyAddon: (addon: null | Addon) => void,
	location: string,
	showNextSteps: () => void,
	onAdd?: BillingAddonCardProps['onAdd']
) => {
	const terms = addon.terms || 'https://img.upsales.com/addonTerms/upsales-subscriptions-pricing.pdf';
	return (
		<BillingAddonModal
			addon={addon}
			visible={!!aboutToBuyAddon}
			onConfirm={() => {
				setAboutToBuyAddon(null);
				// eslint-disable-next-line promise/catch-or-return
				buyAddon(aboutToBuyAddon as Addon).then(() => {
					onAdd?.();
					showNextSteps();
					billingTracker.track(billingTracker.events.BOUGHT_ADDON, { addon: addon.alias, location });
				});
			}}
			onAbort={() => setAboutToBuyAddon(null)}
			color="green"
		>
			{aboutToBuyAddon ? (
				<div className="container">
					<div className="pdf-content">
						<iframe
							style={{ backgroundColor: 'white' }}
							className="pdf"
							src={`${terms}#toolbar=0&view=FitH&statusbar=0&messages=0&navpanes=0&scrollbar=0`}
						></iframe>
					</div>
				</div>
			) : null}
		</BillingAddonModal>
	);
};

const renderAskBillingAdmin = (
	classes: bemClass,
	addon: Addon,
	requestTrial: (alias: string, trial: boolean) => void,
	requestingAddon: boolean,
	hasRequestedAddon: boolean,
	canHaveTrial: boolean
) => (
	<Column className={classes.elem('request-addon').b()}>
		<PrimaryButton
			size="lg"
			onClick={() => requestTrial(addon.alias, canHaveTrial)}
			disabled={hasRequestedAddon}
			loading={requestingAddon}
		>
			<Icon name={hasRequestedAddon ? 'check' : 'envelope'} space="mrs" />
			{hasRequestedAddon
				? T('admin.billing.addon.askAdminRequestSent')
				: canHaveTrial
				? T('admin.billing.addon.askAdminToTry')
				: T('admin.billing.addon.askAdminToBuy')}
		</PrimaryButton>
	</Column>
);

type Props = PropsFromRedux & BillingAddonCardProps;

const BillingAddonCard = ({
	canBuy,
	requestAddon,
	addon,
	location,
	buyAddon,
	addTrialAddon,
	bought,
	viewPlan,
	buyingAddon,
	buyingTrialAddon,
	trialPeriod,
	onAdd,
	ongoingTrialAddons,
	oldTrial,
	nextSteps: providedNextSteps
}: Props) => {
	const hasBillingTrialAddon = useSoftDeployAccess('BILLING_TRIAL_ADDON');
	const [showTiersForAddon, setShowTiersForAddon] = useState<Addon | null>(null);
	const [aboutToBuyAddon, setAboutToBuyAddon] = useState<Addon | null>(null);
	const [shouldShowNextSteps, setShowNextSteps] = useState(false);
	const [hasRequestedAddon, setHasRequestedAddon] = useState(false);
	const [requestingAddon, setRequestingAddon] = useState(false);
	const requestAddonPromise = useRef<CancelablePromise<void> | null>(null);
	const classes = new bemClass('BillingAddonCard').mod({ bought });
	const onBuy = () => setAboutToBuyAddon(addon);

	const viewTiers = () => setShowTiersForAddon(addon);
	const buyButtonText = addon.totalCost === 0 ? T('admin.billing.addToPlan') : T('admin.billing.buyNow');

	const showNextSteps = async () => setShowNextSteps(!shouldShowNextSteps);
	const nextSteps = providedNextSteps || NEXT_STEPS[addon.alias] || [];

	const onRequestAddon = (addon: string, trial: boolean) => {
		setRequestingAddon(true);
		requestAddonPromise.current = makeCancelable(requestAddon(addon, trial));

		billingTracker.track(billingTracker.events.REQUESTED_ADDON, { addon, location });

		// eslint-disable-next-line promise/catch-or-return
		requestAddonPromise.current.promise
			.then(() => {
				setHasRequestedAddon(true);
			})
			.finally(() => {
				setRequestingAddon(false);
			});
	};

	let cardContent = null;
	let cardColor = 'grey-1';

	if (bought) {
		cardColor = 'success-1';
		cardContent = renderBoughtCard(classes, viewPlan);
	} else if (addon.totalCost === 0 || !hasBillingTrialAddon) {
		cardContent = renderBuyCard(classes, addon, onBuy, buyingAddon, buyButtonText);
	} else if (!canBuy) {
		cardContent = renderAskBillingAdmin(
			classes,
			addon,
			onRequestAddon,
			requestingAddon,
			hasRequestedAddon,
			!oldTrial
		);
	} else {
		if (ongoingTrialAddons[addon.alias]) {
			cardColor = 'yellow-1';
			cardContent = renderTrialProgressCard(classes, ongoingTrialAddons[addon.alias]);
		} else if (oldTrial) {
			cardContent = renderBuyCard(classes, addon, onBuy, buyingAddon, buyButtonText);
		} else {
			cardContent = renderTrialCard(
				classes,
				addon,
				addTrialAddon,
				buyingAddon,
				buyingTrialAddon,
				trialPeriod,
				onBuy,
				location,
				showNextSteps,
				onAdd
			);
		}
	}

	return (
		<Card className={classes.b()}>
			<CardContent>
				<Text className={classes.elem('type').b()} color="grey-10" size="sm">
					{T('admin.billing.addon')}
				</Text>
				<Headline size="sm">{T(addon.name)}</Headline>
				<Block className={classes.elem('desc-wrapper').mod({ visible: !shouldShowNextSteps }).b()}>
					<Text color="grey-10" className={classes.elem('desc').b()}>
						{addon.description ? T(addon.description) : null}
					</Text>
					<div className={classes.elem('bottom_wrapper').b()}>
						<Row className={classes.elem('button-row').b()}>
							<Column>
								<Link
									href={addon.readMoreHref}
									target="__blank"
									onClick={() =>
										billingTracker.track(billingTracker.events.ADDON_READ_MORE, {
											addon: addon.alias,
											location
										})
									}
								>
									{T('default.readMore')}
									<Icon name="external-link" space="mls" />
								</Link>
							</Column>
							<Column align="right">
								{addon.tiers.length ? (
									<Button type="link" color="grey" size="sm" onClick={viewTiers}>
										{T('admin.billing.seeAllTiers')}
										<Icon name="info-circle" space="mls" />
									</Button>
								) : null}
							</Column>
						</Row>
					</div>
				</Block>
				<SlideFade visible={!!nextSteps?.length && shouldShowNextSteps} height maxHeight={300}>
					<div className={classes.elem('next').b()}>
						{NEXT_STEP_TITLES[addon.alias] ? (
							<Block space="mbl">
								<Text bold>{T(NEXT_STEP_TITLES[addon.alias])}</Text>
							</Block>
						) : null}
						{nextSteps.map(({ title, action }) => (
							<DefaultButton block ellipsis key={title} onClick={() => action()}>
								{T(title)}
							</DefaultButton>
						))}
					</div>
				</SlideFade>
			</CardContent>
			<Block backgroundColor={cardColor} space="prxl plxl mtl" className={classes.elem('add-card').b()}>
				{cardContent}
			</Block>
			<BillingAddonTiersModal addon={showTiersForAddon} onClose={() => setShowTiersForAddon(null)} />
			{renderConfirm(addon, buyAddon, aboutToBuyAddon, setAboutToBuyAddon, location, showNextSteps, onAdd)}
		</Card>
	);
};

BillingAddonCard.propTypes = {};

export const detached = BillingAddonCard;
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(BillingAddonCard);

type PropsFromRedux = ConnectedProps<typeof connector>;

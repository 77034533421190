import Tooltip from '@upsales/components/Tooltip';
import React from 'react';

window.loadTooltip = function () {
	ReactTemplates.TOOLS.withTooltip = (component, title, options) => {
		const { placement, delayShow, ...opts } = options || {};
		const mappedOpts = { position: placement, delay: delayShow, ...opts };
		return (
			<Tooltip title={title} {...mappedOpts}>
				{component}
			</Tooltip>
		);
	};
};

'use strict';

angular.module('upDirectives').directive('multiEmailSelect', function () {
	return {
		restrict: 'A',
		replace: true,
		require: '^ngModel',
		controller: [
			'$scope',
			'$translate',
			function ($scope, $translate) {
				$scope.emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
				$scope.tagRegex = /{{.*}}/;

				$scope.select2Config = {
					tags: [],
					simple_tags: true,
					formatResult: function (obj) {
						return $translate.instant('default.add') + ': ' + _.escape(obj.text);
					},
					createSearchChoice: function (term) {
						if ($scope.emailRegex.test(term) || $scope.tagRegex.test(term)) {
							return { id: term, text: term };
						} else {
							return null;
						}
					},
					formatNoMatches: function () {
						return $translate.instant('default.enterEmail');
					}
				};
			}
		],
		template: '<input type="hidden" multiple ui-select2="select2Config" class="form-control" />',
		link: function ($scope, $select, $attrs, ngModel) {
			// Add mail to model function
			var addMail = function (email) {
				// Check if string is an email
				if ($scope.emailRegex.test(email) || $scope.tagRegex.test(email)) {
					// Push thw new email to the model ang render
					ngModel.$modelValue.push(email);
					ngModel.$render();
					// Clear input
					$select.select2('val', '');
					// Apply scope
					$scope.$apply();
				}
			};

			$scope.$watch($attrs.ngModel, function (val) {
				if (!val || !val.length) {
					ngModel.$setViewValue(val);
					ngModel.$render();
					$select.select2('val', '');
				}
			});

			// Wait for element to load
			$select.ready(function () {
				// Wait for select2 to init
				setTimeout(function () {
					// The select2 input field
					var input = $select.select2('container').find('.select2-input');

					if ($attrs.initFocus !== undefined) {
						setTimeout(function () {
							$select.select2('open');
						}, 100);
					}

					// Listen on keydown in the select2
					input.on('keydown', function (e) {
						var email;
						// Only on comma input
						if (e.keyCode === 188) {
							// Prevent default behaviour
							e.preventDefault();
							e.stopPropagation();
							// Add email
							email = _.escape(input.val());
							addMail(email);
						} else if (e.keyCode === 9) {
							// For tab-press
							// Add email
							email = _.escape(input.val());
							addMail(email);
						}
					});
				}, 10);
			});
		}
	};
});

'use strict';

angular.module('domain.mail').controller('MailCampaignSubmits', [
	'$scope',
	'AppService',
	'FormSubmit',
	'RequestBuilder',
	'MultiRunnerActions',
	'$multiSelect',
	'Form',
	'$upModal',
	function ($scope, AppService, FormSubmit, RequestBuilder, MultiRunnerActions, $multiSelect, Form, $upModal) {
		var MailCampaignCtrl = $scope.MailCampaignCtrl;
		var getTimeout;
		var limit = 50;
		var searchStr = '';

		MailCampaignCtrl.rootData.pageComponent = 'submits';
		MailCampaignCtrl.rootData.hasMultiSelect = true;
		MailCampaignCtrl.rootData.listActionData.actions = MultiRunnerActions.get(MultiRunnerActions.type.FORMSUBMIT);
		MailCampaignCtrl.rootData.pageData = {
			submits: [],
			loading: true,
			total: 0,
			totalPages: 1,
			activePage: 1,
			currentSorting: { attr: 'processedDate', asc: false },
			onSearch: function (str) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				searchStr = str;
				getSubmits();
			},
			onChangePage: function (page) {
				MailCampaignCtrl.rootData.pageData.activePage = page;
				getSubmits();
			},
			onSort: function (attr) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				if (MailCampaignCtrl.rootData.pageData.currentSorting.attr === attr) {
					// Flip it
					MailCampaignCtrl.rootData.pageData.currentSorting.asc = !MailCampaignCtrl.rootData.pageData
						.currentSorting.asc;
				} else {
					MailCampaignCtrl.rootData.pageData.currentSorting = { attr: attr, asc: true };
				}
				getSubmits();
			},
			onClick: function (submit) {
				Form.get(submit.formId).then(function (res) {
					$upModal
						.open('editFormSubmit', {
							submit: submit,
							form: res.data
						})
						.then(function (updated) {
							angular.extend(submit, updated);
						});
				});
			}
		};

		var getSubmits = function () {
			MailCampaignCtrl.rootData.pageData.loading = true;

			if (getTimeout) {
				clearTimeout(getTimeout);
			}

			getTimeout = setTimeout(function () {
				var rb = new RequestBuilder();
				rb.limit = limit;
				rb.offset = limit * (MailCampaignCtrl.rootData.pageData.activePage - 1);

				if (searchStr && searchStr.length) {
					var orBuilder = rb.orBuilder();

					orBuilder.next();
					orBuilder.addFilter(FormSubmit.attr.client.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(FormSubmit.attr.contact.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(FormSubmit.attr.form.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(FormSubmit.attr.leadSource.attr.source, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.done();
				}

				rb.addSort(
					MailCampaignCtrl.rootData.pageData.currentSorting.attr,
					MailCampaignCtrl.rootData.pageData.currentSorting.asc
				);

				rb.addFilter(FormSubmit.attr.leadSource.attr.source, rb.comparisonTypes.Equals, 'upsalesMail');
				rb.addFilter(FormSubmit.attr.leadSource.attr.type, rb.comparisonTypes.Equals, 'email');
				rb.addFilter(
					FormSubmit.attr.leadSource.attr.value,
					rb.comparisonTypes.Equals,
					MailCampaignCtrl.rootData.mailCampaign.id.toString()
				);

				MailCampaignCtrl.rootData.currentRb = rb;
				FormSubmit.find(rb.build()).then(function (res) {
					MailCampaignCtrl.rootData.pageData.submits = res.data;
					MailCampaignCtrl.rootData.pageData.total = res.metadata.total;
					MailCampaignCtrl.rootData.pageData.loading = false;

					MailCampaignCtrl.rootData.pageData.totalPages = MailCampaignCtrl.calculateTotalPages(
						limit,
						MailCampaignCtrl.rootData.pageData.total
					);
				});
			}, 20);
		};

		var init = function () {
			$multiSelect.selectNone();
			getSubmits();
		};

		AppService.loadedPromise.then(init);
	}
]);

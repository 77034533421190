enum AppointmentParticipantResponse {
	accepted = 'confirmed',
	needsAction = 'waiting',
	declined = 'declined',
	tentative = 'waiting',
	tentativelyAccepted = 'waiting',
	none = 'waiting'
}

export default AppointmentParticipantResponse;

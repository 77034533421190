'use strict';

// https://www.scb.se/contentassets/d43b798da37140999abf883e206d0545/sni2007-kortversion.pdf
angular.module('upsalesApp').constant('ORG_NUMBER_MAP', [
	{
		id: 'A',
		name: 'default.snigroupA',
		gte: 0,
		lt: 5000,
		codes: ['1', '2', '3'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 1, 4);
		}
	},
	{
		id: 'B',
		name: 'default.snigroupB',
		gte: 5000,
		lt: 10000,
		codes: ['5', '6', '7', '8', '9'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 5, 10);
		}
	},
	{
		id: 'C',
		name: 'default.snigroupC',
		gte: 10000,
		lt: 34000,
		codes: [
			'10',
			'11',
			'12',
			'13',
			'14',
			'15',
			'16',
			'17',
			'18',
			'19',
			'20',
			'21',
			'22',
			'23',
			'24',
			'25',
			'26',
			'27',
			'28',
			'29',
			'30',
			'31',
			'32',
			'33'
		],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 10, 34);
		}
	},
	{
		id: 'D',
		name: 'default.snigroupD',
		gte: 34000,
		lt: 36000,
		codes: ['35'],
		isInGroup: function (sniCode) {
			return sniCode === 35;
		}
	},
	{
		id: 'E',
		name: 'default.snigroupE',
		gte: 36000,
		lt: 40000,
		codes: ['36', '37', '38', '39'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 36, 40);
		}
	},
	{
		id: 'F',
		name: 'default.snigroupF',
		gte: 40000,
		lt: 44000,
		codes: ['41', '42', '43'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 41, 44);
		}
	},
	{
		id: 'G',
		name: 'default.snigroupG',
		gte: 44000,
		lt: 48000,
		codes: ['45', '46', '47'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 45, 48);
		}
	},
	{
		id: 'H',
		name: 'default.snigroupH',
		gte: 48000,
		lt: 55000,
		codes: ['49', '50', '51', '52', '53'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 49, 54);
		}
	},
	{
		id: 'I',
		name: 'default.snigroupI',
		gte: 55000,
		lt: 58000,
		codes: ['55', '56'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 55, 57);
		}
	},
	{
		id: 'J',
		name: 'default.snigroupJ',
		gte: 58000,
		lt: 64000,
		codes: ['58', '59', '60', '61', '62', '63'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 58, 64);
		}
	},
	{
		id: 'K',
		name: 'default.snigroupK',
		gte: 64000,
		lt: 68000,
		codes: ['64', '65', '66'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 64, 67);
		}
	},
	{
		id: 'L',
		name: 'default.snigroupL',
		gte: 68000,
		lt: 69000,
		codes: ['68'],
		isInGroup: function (sniCode) {
			return sniCode === 68;
		}
	},
	{
		id: 'M',
		name: 'default.snigroupM',
		gte: 69000,
		lt: 76000,
		codes: ['69', '70', '71', '72', '73', '74', '75'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 69, 76);
		}
	},
	{
		id: 'N',
		name: 'default.snigroupN',
		gte: 76000,
		lt: 83000,
		codes: ['77', '78', '79', '80', '81', '82'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 77, 83);
		}
	},
	{
		id: 'O',
		name: 'default.snigroupO',
		gte: 83000,
		lt: 85000,
		codes: ['84'],
		isInGroup: function (sniCode) {
			return sniCode === 84;
		}
	},
	{
		id: 'P',
		name: 'default.snigroupP',
		gte: 85000,
		lt: 86000,
		codes: ['85'],
		isInGroup: function (sniCode) {
			return sniCode === 85;
		}
	},
	{
		id: 'Q',
		name: 'default.snigroupQ',
		gte: 86000,
		lt: 90000,
		codes: ['86', '87', '88'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 86, 89);
		}
	},
	{
		id: 'R',
		name: 'default.snigroupR',
		gte: 90000,
		lt: 94000,
		codes: ['90', '91', '92', '93'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 90, 94);
		}
	},
	{
		id: 'S',
		name: 'default.snigroupS',
		gte: 94000,
		lt: 97000,
		codes: ['94', '95', '96'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 94, 97);
		}
	},
	{
		id: 'T',
		name: 'default.snigroupT',
		gte: 97000,
		lt: 99000,
		codes: ['97', '98'],
		isInGroup: function (sniCode) {
			return _.inRange(sniCode, 97, 99);
		}
	},
	{
		id: 'U',
		name: 'default.snigroupU',
		gte: 99000,
		lt: 100000,
		codes: ['99'],
		isInGroup: function (sniCode) {
			return sniCode === 99;
		}
	}
]);

import React from 'react';

const CompanyPhone = ({ client }) => {
	const phoneProps = ReactTemplates.TOOLS.upVoice(client, 'client', client.phone);
	return (
		<div id="company-phone" className="company-util">
			<i className={phoneProps ? 'fa fa-phone fa-lg bright-blue' : 'fa fa-phone fa-lg'} />
			{phoneProps ? (
				<a href={phoneProps.href} target={phoneProps.target} onClick={phoneProps.onClick}>
					{client.phone}
				</a>
			) : (
				<span className="grey italic">{Tools.$translate('default.noPhone')}</span>
			)}
		</div>
	);
};

export default CompanyPhone;

import React from 'react';
import { Card, Icon, Text, Button, Row, Column, Calendar, CalendarHeader, OutsideClick } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Activity from 'Resources/Activity';
import { makeCancelable } from 'App/babel/helpers/promise';
import moment from 'moment';
import Opportunity from 'App/resources/Model/Order';

import './OpportunityRowPopUp.scss';

type Props = {
	close: () => void;
	startState: number;
	setCreateData: (data: { description: string; type: string }) => void;
	opportunity: Opportunity;
	isYes?: boolean;
	overrideOpportunity: (obj: object) => void;
	hideButtons: () => void;
	updateProbability: (probability: number) => void;
	daysPassed: number;
};

type State = {
	currentState: number;
	date: Date;
	showMonths: boolean;
};

class OpportunityRowPopUp extends React.Component<Props, State> {
	cancelablePromise: ReturnType<typeof makeCancelable> | null = null;

	states: { [key: string]: number };

	constructor(p: Props) {
		super(p);
		this.states = { meny: 1, calendar: 2, daysUntil: 3, daysUntilWithChangeDay: 4, expiredMeny: 5 };
		this.state = { currentState: p.startState, date: new Date(), showMonths: true };
	}
	componentDidMount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	saveActivity = (activity: object) => {
		this.cancelablePromise = makeCancelable(Activity.save(activity));
		return this.cancelablePromise;
	};

	scrollToTimelinesection = () => {
		$('.up-modal-content').animate({ scrollTop: 235 }, '400');
	};

	openCreate = (type: string) => {
		const { setCreateData } = this.props;
		this.scrollToTimelinesection();
		setCreateData({
			type,
			description: ''
		});
	};

	render() {
		const classes = new bemClass('OpportunityRowPopUp');
		const {
			opportunity,
			close,
			isYes,
			overrideOpportunity,
			hideButtons,
			updateProbability,
			daysPassed
		} = this.props;
		const { currentState, showMonths, date } = this.state;

		const diff = moment(opportunity.date).diff(moment(opportunity.regDate), 'days') + 1;
		const oneDay = diff === 1;

		let phoneCallDescriptionLangTag: string | null;
		if (opportunity.client?.name) {
			const lastChar = opportunity.client.name.substr(opportunity.client.name.length - 1);
			phoneCallDescriptionLangTag = ['s', 'x', 'z'].includes(lastChar)
				? 'opportunity.phoneCallDescriptionNonGenitive'
				: 'opportunity.phoneCallDescription';
		}

		return (
			<OutsideClick
				targetClass="OpportunityRowPopUp"
				outsideClick={() => {
					if (currentState === this.states.daysUntil || currentState === this.states.daysUntilWithChangeDay) {
						hideButtons();
					}
					close();
				}}
			>
				<Card className={classes.mod(isYes ? { isYes } : {}).b()}>
					{
						{
							[this.states.daysUntil]: (
								<div className={classes.elem('daysUntil').b()}>
									<Icon name="check" color="bright-blue" />
									<Text size="lg">
										{T('opportunity.untilClosing', {
											days: moment(opportunity.date).diff(moment(), 'days') + 1
										})}
									</Text>
									<Text>
										{oneDay
											? T('opportunity.opportunityWillHaveBeenActiveForOneDay')
											: T('opportunity.opportunityWillHaveBeenActiveFor', {
													days: diff
											  })}
									</Text>
								</div>
							),
							[this.states.calendar]: (
								<div className={classes.elem('calendar').b()}>
									<CalendarHeader
										isMonthView={showMonths}
										date={date}
										onDateChange={date => this.setState({ date })}
									/>
									<Calendar
										displayDate={date}
										showMonths={showMonths}
										min={new Date()}
										onSelect={async d => {
											await overrideOpportunity({
												date: moment(d).toDate()
											});
										}}
										onDateChange={d => {
											this.setState({ showMonths: false, date: d });
										}}
										isMonthView={true}
										weekIndicator={T('calendar.weekPrefix')}
									/>
								</div>
							),
							[this.states.meny]: (
								<div className={classes.elem('meny').b()}>
									<div className={classes.elem('meny').elem('menyText').b()}>
										<Text className={classes.elem('meny').elem('inline').b()} size="lg">
											{T('opportunity.menyText')}
										</Text>
										<Text size="lg" bold>
											{T('opportunity.menyTextBold')}
										</Text>
									</div>
									<Row>
										<Column>
											<Button
												type="link"
												onClick={() => {
													if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
														this.saveActivity({
															description: phoneCallDescriptionLangTag
																? T(phoneCallDescriptionLangTag, {
																		companyName: opportunity.client?.name
																  })
																: '',
															client: { id: opportunity.client.id },
															contacts: opportunity.contact
																? [{ id: opportunity.contact.id }]
																: [],
															opportunity: { id: opportunity.id },
															users: [{ id: Tools.AppService.getSelf().id }],
															activityType: {
																id: Tools.AppService.getTodoTypes().PHONE_CALL.id
															}
														});
													} else {
														Tools.$upModal.open('editActivity', {
															activity: {
																description: phoneCallDescriptionLangTag
																	? T(phoneCallDescriptionLangTag, {
																			companyName: opportunity.client?.name
																	  })
																	: '',
																client: opportunity.client,
																contacts: opportunity.contact
																	? [{ id: opportunity.contact.id }]
																	: []
															}
														});
													}
													close();
												}}
											>
												<Icon color="black" name="phone" />
												<Text>{T('opportunity.VerifyTheirTimeframe')}</Text>
											</Button>
										</Column>
										<Column>
											<Button
												type="link"
												onClick={() => this.setState({ currentState: this.states.calendar })}
											>
												<Icon color="black" name="calendar-arrow-right-o" />

												<Text>{T('opportunity.changeCloseDate')}</Text>
											</Button>
										</Column>
										<Column>
											{' '}
											<Button
												type="link"
												onClick={async () => {
													await overrideOpportunity({
														confirmedDate: moment(opportunity.date).toDate()
													});
													hideButtons();
													close();
												}}
											>
												<Icon color="black" name="check" />

												<Text>
													{T('opportunity.stillExpectToClose', {
														date: moment(opportunity.date).format('YYYY-MM-DD')
													})}
												</Text>
											</Button>
										</Column>
									</Row>
								</div>
							),
							[this.states.expiredMeny]: (
								<div className={classes.elem('meny').b()}>
									<Text size="lg" className={classes.elem('meny').elem('menyText').b()}>
										{T('opportunity.passedCloseDateMenyText', { days: daysPassed })}
									</Text>
									<Row>
										<Column>
											<Button
												type="link"
												onClick={() => {
													if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
														this.openCreate('phonecall');
													} else {
														Tools.$upModal.open('editActivity', {
															activity: {
																description: T(
																	'opportunity.passedCloseDateCallDescription'
																),
																client: opportunity.client,
																contacts: opportunity.contact
																	? [{ id: opportunity.contact.id }]
																	: []
															}
														});
													}
													close();
												}}
											>
												<Icon color="black" name="phone" />
												<Text>
													{T('opportunity.planACallWith', {
														name: opportunity.contact?.firstName || opportunity.client?.name
													})}
												</Text>
											</Button>
										</Column>
										<Column>
											<Button
												type="link"
												onClick={() => this.setState({ currentState: this.states.calendar })}
											>
												<Icon color="black" name="calendar-arrow-right-o" />

												<Text>{T('opportunity.changeCloseDate')}</Text>
											</Button>
										</Column>
										<Column>
											{' '}
											<Button
												type="link"
												onClick={async () => {
													updateProbability(0);
												}}
											>
												<Icon color="black" name="thumbs-down" />

												<Text>{T('opportunity.markAsLost')}</Text>
											</Button>
										</Column>
									</Row>
								</div>
							),
							[this.states.daysUntilWithChangeDay]: (
								<div className={classes.elem('daysUntilWithChangeDay').b()}>
									<Text size="lg">
										{T('opportunity.untilClosing', {
											days: moment(opportunity.date).diff(moment(), 'days') + 1
										})}
									</Text>
									<Text>
										{oneDay
											? T('opportunity.opportunityWillHaveBeenActiveForOneDay')
											: T('opportunity.opportunityWillHaveBeenActiveFor', {
													days: diff
											  })}
									</Text>
									<Button
										type="link"
										onClick={() => {
											this.setState({ currentState: this.states.calendar });
										}}
									>
										<Icon color="black" name="calendar-arrow-right-o" />

										<Text>{T('opportunity.changeCloseDate')}</Text>
									</Button>
								</div>
							)
						}[currentState]
					}
				</Card>
			</OutsideClick>
		);
	}
}

export default OpportunityRowPopUp;

import React from 'react';
import _ from 'lodash';
import { Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './TableTokens.scss';

ReactTemplates.admin.tableTokens = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			none: t('default.none'),
			all: t('default.all')
		};
	},

	checkWidth: function () {},

	setSize: function () {
		var items = this.props.items;
		if (items && items.length) {
			var space = ReactDOM.findDOMNode(this).offsetWidth;
			space -= this.props.additionalTokenSpace || 20; // more width
			this._more.innerText = '';
			let hiddenItems = 0;

			var elems = ReactDOM.findDOMNode(this).querySelectorAll('.TableTokens__token');
			for (var i = 0; i < this.props.items.length; i++) {
				elems[i].style.display = 'inline-block';
				if (space - elems[i].offsetWidth < 0) {
					elems[i].style.display = 'none';
					hiddenItems++;
				} else {
					space -= elems[i].offsetWidth;
				}
			}

			if (hiddenItems > 0) {
				this._more.innerText = '+' + hiddenItems;
			}
		}
	},

	componentDidMount: function () {
		this.setSize();
	},

	componentDidUpdate: function () {
		this.setSize();
	},

	render: function () {
		var self = this;
		var items = this.props.items || [];
		var newlineSeparated = _.pluck(items, 'name').join('\n');
		const classes = new BemClass('TableTokens');

		var tabNames = items.map(function (item) {
			return (
				<span key={item.id} className={classes.elem('token').b()} style={{ display: 'none' }}>
					{item.name}
				</span>
			);
		});

		tabNames.push(
			<span
				key="more"
				ref={function (r) {
					self._more = r;
				}}
				className={classes.elem('more').b()}
			></span>
		);

		var result =
			items && items.length ? (
				ReactTemplates.TOOLS.withTooltip(<span>{tabNames}</span>, newlineSeparated, {
					placement: 'left',
					delayShow: 250,
					disabled: this.props.noTooltip
				})
			) : (
				<Block className={classes.elem('placeholder').b()} space="mlm">
					{this.props.placeholder
						? this.props.placeholder
						: this.props.defaultText === 'all'
						? this.lang.all
						: this.lang.none}
				</Block>
			);

		return <div className={classes.b()}>{result}</div>;
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Title, Block } from '@upsales/components';
import './SupersearchResultHeader.scss';

const SupersearchResultHeader = ({ title, icon }) => {
	const classes = new bemClass('SupersearchResultHeader');
	return (
		<Block className={classes.b()} backgroundColor="grey-2" space="ptm prl pbm pll">
			{icon ? <Icon name={icon} color="black" space="mrm" /> : null}
			<Title size="sm" color="black">
				{title}
			</Title>
		</Block>
	);
};

SupersearchResultHeader.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string
};

export default SupersearchResultHeader;

import async from 'async';

angular.module('upDirectives').directive('upGallery', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/templates/upGallery.tpl.html?file'),
		replace: true,
		scope: {
			files: '=',
			editable: '='
		},
		controller: [
			'$scope',
			'AppService',
			'Visitor',
			'Ads',
			'$sce',
			'File',
			'BannerGroup',
			'AccountProfile',
			'$element',
			function ($scope, AppService, Visitor, Ads, $sce, File, BannerGroup, AccountProfile, $element) {
				var bannerGroups = {};
				$scope.creativeDetails = undefined;

				var init = function () {
					$scope.customerId = AppService.getCustomerId();
					$scope.$watch('files', function (files) {
						if (!files) {
							return;
						}

						$scope.loadCreatives(function (err, bannerGroups) {
							if (!err) {
								setTimeout(function () {
									_.each($scope.files, function (creative) {
										var iframe = $element.find(
											'#' +
												creative.creativeGroupId +
												'_' +
												creative.width +
												'x' +
												creative.height
										);
										if (
											creative.creativeGroupId &&
											bannerGroups[creative.creativeGroupId] &&
											iframe.length
										) {
											iframe[0].contentWindow.document.open();
											iframe[0].contentWindow.document.write(
												bannerGroups[creative.creativeGroupId]
											);
											iframe[0].contentWindow.document.close();
										}
									});
								}, 200);
							}
						});
					});
				};

				$scope.showCreativeInfo = function (creative) {
					if (!creative) {
						$scope.creativeDetails = null;
						return;
					}
					$scope.creativeDetails = creative;
					// $scope.creativeDetails.validPage = 'loading';
					// Visitor.customer($scope.customerId).validatePage(creative.declared_destination_url).then(function (res) {
					// 	$scope.creativeDetails.validPage = res.data;
					// }).catch(function () {
					// 	$scope.creativeDetails.validPage = 'err';
					// });
				};
				$scope.removeCreative = function (creative) {
					// remove from object
					var index = _.findIndex($scope.files, { id: creative.id });
					if (index !== -1) {
						$scope.files.splice(index, 1);
					}
					// remove from display group
					index = _.findIndex($scope.creatives[creative.width + 'x' + creative.height], { id: creative.id });
					if (index !== -1) {
						$scope.creatives[creative.width + 'x' + creative.height].splice(index, 1);
					}
					// hide details if this is the active creative
					if ($scope.creativeDetails && $scope.creativeDetails.id === creative.id) {
						$scope.creativeDetails = undefined;
					}
				};
				$scope.loadCreatives = function (cb) {
					$scope.creatives = {};
					$scope.formats = [];
					$scope.loadingCreatives = true;

					if (!$scope.files) {
						$scope.files = [];
					}

					async.waterfall(
						[
							function getAccountProfile(waterCb) {
								AccountProfile.get()
									.then(function (res) {
										waterCb(null, res.data);
									})
									.catch(waterCb);
							},
							function getCreatives(accountProfile, waterCb) {
								async.eachSeries(
									$scope.files,
									function (creative, cb2) {
										Ads.customer($scope.customerId)
											.getCreative(creative.creativeId)
											.then(function (res) {
												creative = _.extend(creative, res.data);
												if (!$scope.creatives[creative.width + 'x' + creative.height]) {
													$scope.creatives[creative.width + 'x' + creative.height] = [];
													$scope.formats.push({
														name: creative.width + 'x' + creative.height
													});
												}
												$scope.creatives[creative.width + 'x' + creative.height].push(creative);
												creative.sample_image_url = File.getInlineUrl(creative.fileId);
												creative.sample_image_url_thumb = File.getInlineThumbnailUrl(
													creative.fileId
												);
												if (creative.creativeGroupId) {
													if (bannerGroups[creative.creativeGroupId]) {
														cb2();
													} else {
														BannerGroup.get(creative.creativeGroupId)
															.then(function (res) {
																window.BannerParser.parse(
																	res.data,
																	res.data.body,
																	accountProfile,
																	function (err, html) {
																		if (err) {
																			return cb2(err);
																		}
																		bannerGroups[creative.creativeGroupId] =
																			'data:text/html;charset=utf-8,' + html;
																		cb2();
																	}
																);
															})
															.catch(e => console.log('get banner error', e));
													}
												} else {
													if (creative.code) {
														creative.code =
															'<body style="margin:0;padding:0;">' +
															creative.code +
															'</body>';
														creative.code = $sce.trustAsHtml(creative.code);
													}
													if (creative.preview_snippet) {
														creative.preview_snippet =
															'<body style="margin:0;padding:0;"><script>' +
															creative.preview_snippet +
															'</script></body>';
														creative.preview_snippet = $sce.trustAsHtml(
															creative.preview_snippet
														);
													}
													cb2();
												}
											})
											.catch(function (err) {
												cb2(err);
											});
									},
									waterCb
								);
							}
						],
						function (err) {
							$scope.loadingCreatives = false;
							$scope.loadingCreativesErr = err;
							return cb(err, bannerGroups);
						}
					);
				};

				AppService.loadedPromise.then(init).catch(e => console.log('load upGallery error', e));
			}
		]
	};
});

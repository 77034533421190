import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column, Label, Headline, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import StepIntInput from 'Components/Inputs/StepIntInput';
import './MailContentEditorPadding.scss';

const inputs = [
	{ name: 'horizontal', value: 'hPadding', icon: 'arrows-h' },
	{ name: 'vertical', value: 'vPadding', icon: 'arrows-v' }
];

const MailContentEditorPadding = ({ column, onChange }) => {
	const classes = new bemClass('MailContentEditor');
	const componentClasses = new bemClass('MailContentEditorPadding', classes.elem('sidebar-section').b());

	return (
		<div className={componentClasses.b()}>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Headline size="xs">{translate('mail.paddings')}</Headline>
				</Column>
			</Row>
			{inputs.map(input => (
				<Row key={input.name} className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Label>
							<Icon name={input.icon} className={componentClasses.elem('icon').b()} />
							{translate('mail.' + input.name)}
						</Label>
					</Column>
					<Column align="right">
						<StepIntInput
							size="sm"
							value={column[input.value]}
							onChange={e => onChange({ ...column, [input.value]: e.target.value })}
						/>
					</Column>
				</Row>
			))}
		</div>
	);
};

MailContentEditorPadding.propTypes = {
	column: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

MailContentEditorPadding.defaultProps = {
	onChange: () => {}
};

export default MailContentEditorPadding;

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Table, TableRow, TableColumn, Text } from '@upsales/components';
import SupersearchResultHeader from '../SupersearchResultHeader';
import T from 'Components/Helpers/translate';

class SupersearchHistory extends React.PureComponent {
	static propTypes = {
		closeSearch: PropTypes.func.isRequired
	};
	constructor(p) {
		super(p);

		this.userId = Tools.AppService.getSelf().id;
		this.customerId = Tools.AppService.getCustomerId();

		// Move state and eventlistener to redux-store as more components above this gets ported/refactored
		this.state = {
			clients: Tools.LatestAccountsService.get(this.customerId, this.userId)
		};

		this.removeListener = Tools.$rootScope.$on('LatestAccounts.change', (e, data) => {
			if (data.key === Tools.LatestAccountsService.getKey(this.customerId, this.userId)) {
				const clients = Tools.LatestAccountsService.get(this.customerId, this.userId);
				this.setState({ clients });
			}
		});
	}
	componentWillUnmount() {
		this.removeListener();
	}

	goToClient = id => {
		Tools.$state.go('account.dashboard', { id });
		this.props.closeSearch();
	};

	render() {
		if (!this.state.clients.length) {
			return null;
		}
		const classes = new bemClass('SupersearchHistory');
		return (
			<div className={classes.b()}>
				<SupersearchResultHeader title={T('default.latestAccounts')} icon="clock-o" />
				<Table>
					{this.state.clients.map(({ id, name }) => {
						return (
							<TableRow key={id} onClick={() => this.goToClient(id)}>
								<TableColumn>
									<Text italic={!name} color={name ? 'black' : 'grey-10'} size="sm">
										{name || T('default.noName')}
									</Text>
								</TableColumn>
							</TableRow>
						);
					})}
				</Table>
			</div>
		);
	}
}

export default SupersearchHistory;

import AdminHeader from 'Components/Admin/AdminHeader';

ReactTemplates.admin.twofa = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			smsAuth: t('feature.smsAuth'),
			smsAuthInfo: t('admin.smsAuthInfo'),
			use: t('default.use'),
			readMore: t('default.readMore'),
			usersWithoutEmail: t('admin.smsAuth.usersWithoutEmail'),
			usersWithoutEmailDescription: t('admin.smsAuth.usersWithoutEmailDescription'),
			name: t('default.name')
		};
	},
	getInitialState: function () {
		return {
			sort: 'name',
			descending: false
		};
	},
	updateSort: function (sort) {
		var descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	},
	getSortClass: function (name, type) {
		var sort = this.state.sort;
		var descending = this.state.descending;

		if (sort !== name) {
			return 'fa fa-sort';
		} else if (type === 'number') {
			return descending ? 'fa fa-sort-numeric-desc' : 'fa fa-sort-numeric-asc';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	},
	smsAutoChanged: function (val) {
		this.props.rootData.onChange(val);
	},
	userClicked: function (user) {
		window.Tools.$state.go('administration.user', { id: user.id });
	},
	render: function () {
		var self = this;
		var users = _.filter(this.props.rootData.pageData.users, function (user) {
			return !user.userCellPhone;
		});

		users = _.sortBy(users, function (user) {
			return user.name;
		});

		if (this.state.descending) {
			users = users.reverse();
		}

		var userRows = users.map(function (user) {
			var userClicked = self.userClicked.bind(null, user);

			return (
				<tr key={user.id} className="clickable">
					<td onClick={userClicked}>
						<ReactTemplates.TOOLS.avatar style={{ marginTop: 8 }} size={24} user={user} />
						{user.name}
					</td>
				</tr>
			);
		});

		var userInfo = null;

		if (userRows.length) {
			userInfo = (
				<div className="admin-table user-info">
					<div className="admin-table-top">
						<div className="admin-table-title">{self.lang.usersWithoutEmail}</div>
						<div>{self.lang.usersWithoutEmailDescription}</div>
					</div>

					<table>
						<thead>
							<tr>
								<th
									onClick={this.updateSort.bind(this, 'name')}
									className={'sortable' + (this.state.sort === 'name' ? ' active' : '')}
								>
									{self.lang.name} <i className={this.getSortClass('name')} />
								</th>
							</tr>
						</thead>
						<tbody>{userRows}</tbody>
					</table>
				</div>
			);
		}

		return (
			<div id="admin-page-2fa">
				<AdminHeader
					title={self.lang.smsAuth}
					description={self.lang.smsAuthInfo}
					image="sms-auth.svg"
					articleId={450}
					toggle={true}
					toggleChecked={this.props.rootData.pageData.smsAuth}
					onToggleChange={self.smsAutoChanged}
					toggleLabel={`${self.lang.use} ${self.lang.smsAuth.toLowerCase()}`}
				/>

				<div id="admin-content">{userInfo}</div>
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text, Block, Icon, Link, Flex } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import InactiveLabel from 'Components/Misc/InactiveLabel';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import T from 'Components/Helpers/translate';

const getPhoneNr = (client, searchStr) => {
	const tel = ReactTemplates.TOOLS.upVoice(client, 'client', client.phone);
	return tel ? (
		<Link target={tel.target} href={tel.href} onClick={tel.onClick}>
			<Icon name="phone" space="mrs" />
			<SupersearchHighlight searchStr={searchStr} text={client.phone} type="phone" />
		</Link>
	) : null;
};

// eslint-disable-next-line no-unused-vars
const SupersearchResultRowClient = ({ className, item: client, searchStr, closeSearch, ...props }) => {
	const hasMarket = Tools.FeatureHelper.isAvailableProduct('MA');
	const { name } = client;
	const classes = new bemClass('SupersearchResultRowClient', className);
	const visitAddress = (client.addresses || []).find(a => a.type === 'Visit');

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={client.users}
			inactive={!client.active}
			hideUsers={!client.active}
			alwaysShowCustomfields={true}
			searchStr={searchStr}
			customValues={client.custom}
			{...props}
		>
			<Column>
				<Flex alignItems="center">
					<Text italic={!client.active} bold={true} color={!client.active ? 'grey-10' : 'black'} size="md">
						{name ? (
							<SupersearchHighlight searchStr={searchStr} text={name} type="string" />
						) : (
							T('default.noName')
						)}
					</Text>
					<SubAccountLabel operationalAccount={client.operationalAccount} />
				</Flex>
				<Block className="inline" space="mrm">
					<ReactTemplates.TOOLS.leadStatus
						activity={client.hasActivity}
						activityOld={client.hadActivity}
						appointment={client.hasAppointment}
						appointmentOld={client.hadAppointment}
						opportunity={client.hasOpportunity}
						opportunityOld={client.hadOpportunity}
						order={client.hasOrder}
						orderOld={client.hadOrder}
					/>
				</Block>
				{hasMarket ? <ReactTemplates.TOOLS.eventTypes account={client} /> : null}
			</Column>
			<Column>
				{visitAddress ? (
					<Block>
						<Text size="sm">
							{visitAddress.country ? (
								<b className={`flag-icon flag-icon-${visitAddress.country.toLowerCase()}`} />
							) : null}
							<Block className="inline" space="mls">
								{visitAddress.city}
							</Block>
						</Text>
					</Block>
				) : null}
				{client.phone ? <Text size="sm">{getPhoneNr(client, searchStr)}</Text> : null}
			</Column>
			{!client.active ? (
				<Column fixedWidth={100} align="right">
					<InactiveLabel />
				</Column>
			) : null}
		</SupersearchResultRow>
	);
};

SupersearchResultRowClient.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object
};

export default SupersearchResultRowClient;

'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { getDynamicUserProperties } from 'App/helpers/actionHelpers';

angular.module('domain.admin').controller('CreateActivityAction', [
	'$scope',
	'$modalParams',
	'$upModal',
	'$translate',
	'$parse',
	'$q',
	'AppService',
	'ActionProperties',
	'TagsService',
	'RequestBuilder',
	'Campaign',
	'CampaignAttributes',
	'FeatureHelper',
	function (
		$scope,
		$modalParams,
		$upModal,
		$translate,
		$parse,
		$q,
		AppService,
		ActionProperties,
		TagsService,
		RequestBuilder,
		Campaign,
		CampaignAttributes,
		FeatureHelper
	) {
		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		$scope.ACTIVITY_PRIORITY = ACTIVITY_PRIORITY;

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		var arrayProperties = [];

		$scope.save = function () {
			$scope.saving = true;

			// map properties
			var properties = [];

			if (
				$scope.properties.hasOwnProperty('ActivityDelayInDays') &&
				$scope.properties.ActivityDelayInDays === undefined
			) {
				delete $scope.properties.ActivityDelayInDays;
			}

			// map custom
			ActionProperties.mapCustomFields(properties, $scope.customFields, 'Activity');

			ActionProperties.map(properties, $scope.properties, arrayProperties);

			$scope.resolve(properties);
		};

		var prevVal = 0;
		$scope.updateNumberOfDays = function () {
			var reg = new RegExp('^(?:[1-9]|[1-4][0-9]|50)$');
			var days = $scope.properties.ActivityDelayInDays;

			if (reg.test(days)) {
				prevVal = days;
			} else if (!days) {
				$scope.properties.ActivityDelayInDays = undefined;
			} else {
				$scope.properties.ActivityDelayInDays = prevVal;
			}
		};

		$scope.addTime = function (time) {
			$scope.properties.Date.setHours(time.getHours(), time.getMinutes(), 0, 0);
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.addField = function (field) {
			_.remove($scope.availableFields, { id: field.id });
			$scope.customFields.push(field);
			$scope.fieldToAdd = null;
		};

		$scope.removeField = function (field) {
			_.remove($scope.customFields, { id: field.id });
			$scope.availableFields.push(field);
			$scope.reloadModalPosition();
		};

		$scope.createCampaign = function () {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editCampaign', { customerId: AppService.getCustomerId(), noRedirect: true })
				.then(function (campaign) {
					$scope.campaigns.push(campaign);
					$scope.properties.Project = campaign.id;
				});
		};

		$scope.setDate = function () {
			if (!$scope.date.type || !$scope.date.type.tag) {
				return;
			}
			$scope.properties.Date = '{{' + $scope.date.type.tag + ':' + ($scope.date.offset || 0) + '}}';
		};

		$scope.changeCustomDate = function (field) {
			if (field.value && field.value.tag === 'RegularDate') {
				field.dates = undefined;
			}
		};

		var parseDate = function (tags, property) {
			var res = {
				type: null,
				offset: null
			};

			if (!$modalParams.properties) {
				return res;
			}

			var date = $modalParams.properties.find(function (prop) {
				return prop.name === property;
			});

			if (date && date.value) {
				var value = date.value;

				if (value instanceof Date === true) {
					res.type = 'General.Today';
					res.offset = 0;

					return res;
				}

				value = value.replace(/[{}]/g, '');
				var split = value.split(':');
				res.type = _.reduce(
					tags,
					function (result, group) {
						if (result) {
							return result;
						}

						return _.find(group.children, { tag: split[0] });
					},
					null
				);
				res.offset = parseInt(split[1]);
			}

			return res;
		};

		var loadData = function () {
			var customerId = AppService.getCustomerId();

			var campaignFilter = new RequestBuilder();
			var campaignAttr = CampaignAttributes().attr;
			campaignFilter
				.addSort(campaignAttr.name, true)
				.addFilter(campaignAttr.active, campaignFilter.comparisonTypes.Equals, 1);

			return Campaign.customer(customerId).find(campaignFilter.build());
		};

		var init = function () {
			$scope.isTrigger = $modalParams.isTrigger || false;
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.availableFields = AppService.getCustomFields('activity');
			if (!$scope.isTrigger) {
				$scope.availableFields = _.filter($scope.availableFields, 'editable');
			}

			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customFields = [];

			$scope.hideDate = $modalParams.hideDate || false;

			// parse properties
			$scope.properties = {};

			if ($modalParams.prefill) {
				$scope.properties.Notes = $modalParams.prefill.note || '';
				$scope.properties.Description = $modalParams.prefill.description || '';
			}

			// Set activity type
			$scope.activityTypes = AppService.getActivityTypes('activity', true);
			$scope.properties.ActivityType = $scope.activityTypes[0].id;

			// Date
			var dateTags = [];
			var dateTypes = TagsService.getTagsByEntity($modalParams.entity || $modalParams.tagEntity).reduce(function (
				result,
				value
			) {
				if (value.type !== 'Date') {
					return result;
				}

				if (!result[value.entity]) {
					result[value.entity] = {
						name: $translate.instant('default.' + value.entity),
						children: []
					};
				}

				result[value.entity].children.push(value);
				dateTags.push(value);
				return result;
			},
			{});

			var customDateTypes = _.cloneDeep(dateTypes);
			if (customDateTypes.general && customDateTypes.general.children) {
				var clearField = { name: $translate.instant('tag.general.clearfield'), tag: 'General.ClearField' };
				customDateTypes.general.children.push(clearField);
				customDateTypes.general.children.push({
					name: $translate.instant('tag.special.regularDate'),
					tag: 'RegularDate'
				});
				dateTags.push(clearField);
			}

			$scope.availableFields = _.map($scope.availableFields, function (field) {
				if (customDateTypes && field.datatype === 'Date') {
					field.dates = _.values(customDateTypes);
				}
				return field;
			});

			$scope.customDateTypes = {
				data: _.values(customDateTypes),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('tag')
			};

			if ($scope.isTrigger) {
				$scope.dateTypes = {
					data: _.values(dateTypes),
					formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
					formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
					matcher: function (term, text, op) {
						return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					},
					id: _.property('tag')
				};
				var dateProperty = _.find($modalParams.properties, {
					name: 'Date'
				});

				$scope.date = dateProperty ? parseDate(dateTypes, 'Date') : { type: 'General.Today', offset: 0 };
				$scope.setDate();
			} else {
				$scope.properties.Date = new Date();
			}

			angular.forEach($modalParams.properties, function (prop) {
				$scope.properties[prop.name] = prop.value;
				if (_.indexOf(arrayProperties, prop.name) !== -1) {
					$scope.properties[prop.name] = $scope.properties[prop.name].split(',');
				} else if (prop.name === 'ActivityDelayInDays') {
					$scope.properties[prop.name] = parseInt(prop.value);
				}
			});

			// parse fields
			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties, 'Activity');

			// parse custom
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Activity',
				dateTags
			);

			// Users
			const activeUsersCopy = AppService.getActiveUsers();

			const dynamicUserProperties = getDynamicUserProperties(
				$modalParams.tagEntity,
				$modalParams.isAutomation
			).map(p => ({ id: p.value, name: p.title }));

			$scope.togglePriority = function () {
				$scope.properties.Priority = $scope.properties.Priority
					? ACTIVITY_PRIORITY.NONE
					: ACTIVITY_PRIORITY.HIGH;
			};

			$scope.users = [...dynamicUserProperties, ...activeUsersCopy];

			loadData()
				.then(function (res) {
					$scope.campaigns = res.data;
				})
				.catch(err => console.error(err));

			if ($scope.properties.ShowTime) {
				$scope.time = $scope.properties.Date;
			}
		};

		AppService.loadedPromise.then(init).catch(err => console.error(err));
	}
]);

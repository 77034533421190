'use strict';

angular.module('services.urlParser', []).service('urlParser', function () {
	var instance = {};

	instance.parseUrl = function (url) {
		var parser = document.createElement('a');
		parser.href = url;

		var indexOfLastSlash = parser.pathname.lastIndexOf('/');
		var indexOfSecondToLastSlash = parser.pathname.lastIndexOf('/', indexOfLastSlash - 1);

		var parseUrl = parser.host;

		if (parser.pathname == '/') {
			return parseUrl;
		} else if (indexOfSecondToLastSlash == -1 || indexOfSecondToLastSlash == 0) {
			parseUrl += parser.pathname;
		} else {
			parseUrl += '/...' + parser.pathname.substring(indexOfSecondToLastSlash);
		}

		return parseUrl;
	};

	return instance;
});

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SharedViews from 'Components/SharedViews';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import moment from 'moment';
import './AdvancedSearchView.scss';

export default class AdvancedSearchView extends React.Component {
	static propTypes = {
		searchView: PropTypes.object,
		filters: PropTypes.object,
		selectedView: PropTypes.object,
		nextSearchView: PropTypes.object,
		onViewChange: PropTypes.func
	};

	static getDerivedStateFromProps(nextProps) {
		return { selectedView: nextProps.selectedView };
	}

	classNames = new bemClass('AdvancedSearchView');

	state = {
		availableViews: [],
		selectedView: this.props.selectedView
	};

	get viewType() {
		const view = this.props.searchView.resultsAs?.toLowerCase?.() ?? '';
		const filterName = view.charAt(0).toUpperCase() + view.slice(1);

		return `advancedSearch${filterName}`;
	}

	componentDidMount() {
		this.loadSearchViews();
	}

	componentDidUpdate(prevProps) {
		if (this.props.searchView !== prevProps.searchView) {
			this.setState({ availableViews: [] }, () => this.loadSearchViews());
		}
	}

	async loadSearchViews() {
		const availableViews = await Tools.AppService.getListViews(this.viewType);

		this.setState({ availableViews });
	}

	createView = name => {
		const { searchView, filters } = this.props;

		const view = _.cloneDeep(this.state.selectedView);

		delete view.p;
		delete view.offset;

		view.id = 0;
		view.default = false;
		view.private = true;
		view.editable = true;
		view.title = name;
		view.description = '';
		view.filters = _.cloneDeep(filters);
		view.columns = _.cloneDeep(searchView.columns);
		view.sorting = _.cloneDeep(searchView.sorting);

		return this.saveView(view);
	};

	async saveView(view) {
		const customerId = Tools.AppService.getCustomerId();

		view.filters = [
			{
				filterName: 'advancedSearch',
				dataType: 'raw',
				value: view.filters
			}
		];

		const selectedView = await Tools.ListViewService.save(this.viewType, view, customerId);
		const availableViews = await Tools.AppService.getListViews(this.viewType);

		this.setState({ availableViews });
		this.selectView(selectedView);
	}

	updateView = () => {
		const view = _.cloneDeep(this.state.selectedView);
		const { searchView, filters } = this.props;

		delete view.p;
		delete view.offset;

		view.filters = _.cloneDeep(filters);
		view.columns = _.cloneDeep(searchView.columns);
		view.sorting = _.cloneDeep(searchView.sorting);

		return this.saveView(view);
	};

	deleteView = view => {
		const availableViews = [...this.state.availableViews];
		const customerId = Tools.AppService.getCustomerId();

		_.pull(availableViews, view);

		Tools.ListViewService.delete(this.viewType, view, customerId);
		this.setState({ availableViews }, () => {
			if (this.state.selectedView.id === view.id) {
				this.selectView(availableViews[0]);
			}
		});
	};

	selectView = selectedView => {
		this.props.onViewChange(selectedView);
	};

	resetView = () => {
		this.props.onViewChange(this.state.selectedView);
	};

	toggleDefaultView = view => {
		const customerId = Tools.AppService.getCustomerId();
		const { availableViews } = this.state;

		if (view.default) {
			view.default = false;
		} else {
			view.default = true;

			availableViews.forEach(oldView => {
				if (oldView.id !== view.id && oldView.default && oldView.private) {
					oldView.default = false;
					Tools.ListViewService.save(this.viewType, oldView, customerId);
				}
			});
		}

		return Tools.ListViewService.save(this.viewType, view, customerId);
	};

	editView = view => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('editListView', {
				view,
				skipSave: true
			})
			.then(updatedView => this.saveView(updatedView));
	};

	isViewChanged() {
		const { selectedView } = this.state;
		const { filters, searchView } = this.props;
		const hasChanges = !!document.querySelector('#advanced-search-side-bar .active-indicator');

		if (
			!_.isEqual(selectedView.columns, searchView.columns) ||
			!_.isEqual(selectedView.sorting, searchView.sorting)
		) {
			return true;
		}

		if (!selectedView.editable && selectedView.default) {
			return hasChanges;
		}
		const [firstFilter] = selectedView.filters;

		if (firstFilter && firstFilter.value) {
			return Object.keys(filters).some(entity =>
				Object.keys(filters[entity]).some(column => {
					const savedFilter = firstFilter.value[entity]?.[column];
					const currentFilter = filters[entity][column];
					if (!savedFilter) {
						return currentFilter.value !== null;
					}

					if (savedFilter.dataType === 'dateRange') {
						return (
							!moment(savedFilter.value.start || Date.now()).isSame(
								moment(currentFilter.value.start || Date.now()),
								'day'
							) ||
							!moment(savedFilter.value.end || Date.now()).isSame(
								moment(currentFilter.value.end || Date.now()),
								'day'
							)
						);
					}

					return !_.isEqual(savedFilter.value, currentFilter.value);
				})
			);
		}
	}

	render() {
		const { availableViews, selectedView } = this.state;
		const { filters, nextSearchView } = this.props;

		if (!availableViews.length || !selectedView || nextSearchView) {
			return (
				<div className={`${this.classNames.b()} text-center`}>
					<window.Loader noU />
				</div>
			);
		}

		let viewPicker;

		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
			const sharedView = {
				...selectedView,
				filters: [
					{
						filterName: 'advancedSearch',
						dataType: 'raw',
						value: filters
					}
				]
			};

			const props = {
				hasChanged: this.isViewChanged(),
				changeView: this.selectView,
				type: this.viewType,
				component: SharedViews,
				selectedView: sharedView,
				list: [],
				hideSubtitle: true,
				wrapperStyle: { height: '50px' },
				wrapperId: 'advanced-search-picker'
			};

			viewPicker = <ReduxRootComponent {...props} />;
		} else {
			const props = {
				viewChanged: this.isViewChanged(),
				views: availableViews,
				selected: selectedView,
				setView: this.selectView,
				saveViewAs: this.createView,
				saveView: this.updateView,
				deleteView: this.deleteView,
				setAsDefault: this.toggleDefaultView,
				editView: this.editView,
				resetView: this.resetView,
				tools: {
					$translate: getAngularModule('$translate'),
					$upModal: getAngularModule('$upModal')
				}
			};
			viewPicker = <ReactTemplates.TOOLS.viewPicker {...props} />;
		}

		return <div className={this.classNames.b()}>{viewPicker}</div>;
	}
}

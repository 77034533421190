import { Icon, DropDownMenu, Block, Button } from '@upsales/components';

ReactTemplates.admin.importUploader = window.ReactCreateClass({
	getInitialState: function () {
		return {
			uploadError: false,
			reason: null,
			importType: Tools.Import.type.CLIENT_AND_CONTACT
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			uploadFile: t('default.uploadFile'),
			selectFileText: t('file.uploadHelpText_split1'),
			dropToUpload: t('file.dropToUpload'),
			clickToSelectFile: t('file.uploadHelpText_split1'),
			orDropToUpload: t('file.uploadHelpText_split2'),
			acceptedTypes: t('file.acceptedTypes'),
			beforeImporting: t('admin.beforeImporting'),
			beforeImportingInfo1: t('admin.beforeImportingInfo1'),
			beforeImportingInfo2: t('admin.beforeImportingInfo2'),
			importTemplate: t('admin.importTemplate'),
			downloadTemplateDesc: t('admin.beforeImportDownloadTemplate'),
			uploading: t('esign.uploading')
		};
	},

	componentDidMount: function () {
		var dropzone = ReactDOM.findDOMNode(this._dropzone);
		dropzone.addEventListener('dragenter', this.onDragenter);
		dropzone.addEventListener('dragleave', this.onDragleave);
	},

	componentWillUnmount: function () {
		var dropzone = ReactDOM.findDOMNode(this._dropzone);
		dropzone.removeEventListener('dragenter', this.onDragenter);
		dropzone.removeEventListener('dragleave', this.onDragleave);
	},

	getDataTransferFiles: function (event) {
		var dataTransferItemsList = [];
		if (event.dataTransfer) {
			var dt = event.dataTransfer;
			if (dt.files && dt.files.length) {
				dataTransferItemsList = dt.files;
			} else if (dt.items && dt.items.length) {
				dataTransferItemsList = dt.items;
			}
		} else if (event.target && event.target.files) {
			dataTransferItemsList = event.target.files;
		}

		return Array.prototype.slice.call(dataTransferItemsList);
	},

	enterCounter: 0,

	onDragenter: function () {
		this.enterCounter++;
		ReactDOM.findDOMNode(this._dropzone).classList.add('over');
	},

	onDragleave: function () {
		this.enterCounter--;
		if (this.enterCounter === 0) {
			ReactDOM.findDOMNode(this._dropzone).classList.remove('over');
		}
	},

	onUpload: function (ev) {
		ev.preventDefault();

		const self = this;
		const list = self.getDataTransferFiles(ev);

		this.enterCounter = 0;
		ReactDOM.findDOMNode(this._dropzone).classList.remove('over');

		_.each(list, function (file) {
			if (
				file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				file.type === 'application/vnd.ms-excel'
			) {
				self.props.onUpload(file, self.state.importType);
			} else if (
				(file.name && file.type === '' && file.name.slice(-3) === 'xls') ||
				file.name.slice(-4) === 'xlsx'
			) {
				self.props.onUpload(file, self.state.importType);
			} else {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					title: 'default.error',
					body: 'admin.filetypeNotSupported'
				});
			}
		});

		// This is so that you can select the same file again
		this._fileInput.value = null;
	},

	triggerUpload: function () {
		if (this._fileInput) {
			ReactDOM.findDOMNode(this._fileInput).click();
		}
	},

	availableTemplates: function (currentLanguage, importType) {
		var customerId = Tools.AppService.getCustomerId();
		let templates;
		switch (importType) {
			case Tools.Import.type.PRODUCT:
				templates = [
					{
						lang: 'en-GB',
						name: 'Download import template',
						url:
							Tools.URL +
							Tools.API +
							customerId +
							'/files/static?path=import/Import-Template-Products-EN.xlsx'
					},
					{
						lang: 'sv-SE',
						name: 'Ladda ner importmall',
						url:
							Tools.URL +
							Tools.API +
							customerId +
							'/files/static?path=import/Import-Template-Products-SV.xlsx'
					}
				];
				break;
			default:
				templates = [
					{
						lang: 'en-GB',
						name: 'Download import template',
						url: Tools.URL + Tools.API + customerId + '/files/static?path=import/Template-en.xlsx'
					},
					{
						lang: 'sv-SE',
						name: 'Ladda ner importmall',
						url: Tools.URL + Tools.API + customerId + '/files/static?path=import/Template-sv.xls'
					}
				];
		}

		const defaultTemplate =
			templates.find(temp => temp.lang === currentLanguage) || templates.find(temp => temp.lang === 'en-GB');
		return {
			default: defaultTemplate,
			templates: templates.filter(temp => temp.lang !== defaultTemplate.lang)
		};
	},

	templateButton: function (template, isMain = false, setExpanded) {
		return (
			<Button className="import-template-button" onClick={() => window.open(template.url)}>
				<div className="lang">
					<Icon name="file-excel" />
					{template.lang.substr(0, 2)}
				</div>
				{template.name}
				{isMain ? (
					<div className="expand-button" onClick={setExpanded}>
						<Icon name="caret-down" />
					</div>
				) : null}
			</Button>
		);
	},

	onChangeTab: function (importType) {
		this.setState({ importType });
	},

	renderTab: function (importType) {
		const active = this.state.importType === importType;
		return (
			<button
				className={'import-uploader-tab' + (active ? ' active' : '')}
				onClick={() => this.onChangeTab(importType)}
			>
				{Tools.$translate('import.type.' + importType)}
			</button>
		);
	},

	renderTabs: function () {
		const importTypes = Tools.Import.type;

		const tabs = [this.renderTab(importTypes.CLIENT_AND_CONTACT), this.renderTab(importTypes.PRODUCT)];

		return tabs;
	},

	render: function () {
		var self = this;
		const lang = Tools.AppService.getSelf().language;
		const templateTitle = Tools.$translate('import.template.type.' + this.state.importType);
		const availableTemplates = this.availableTemplates(lang, this.state.importType);
		return (
			<div className="admin-card import-uploader">
				<div className="import-uploader-tabs-wrap">{this.renderTabs()}</div>
				<div className="import-uploader-content">
					<div
						onClick={this.triggerUpload}
						className="import-dropzone"
						onDrop={this.onUpload}
						ref={function (r) {
							self._dropzone = r;
						}}
					>
						<h3 className="hide-on-hover">{this.lang.uploadFile}</h3>
						<p className="hide-on-hover">
							<span className="upload-linktext">
								<input
									type="file"
									ref={function (r) {
										self._fileInput = r;
									}}
									onChange={this.onUpload}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
								/>
								<Button type="link" color="blue" block={true}>
									{this.lang.clickToSelectFile}
								</Button>{' '}
								{this.lang.orDropToUpload + '.'}
							</span>
						</p>
						<div className="accepted-types hide-on-hover">
							{this.lang.acceptedTypes}
							{': .xls, .xlsx'}
						</div>
						<div className="on-hover show-on-hover">
							<svg
								width="120"
								height="151"
								viewBox="0 0 120 151"
								className="file-drop-icon"
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
							>
								<g className="card" fill="none" fill-rule="evenodd">
									<path
										className="paper"
										fill="#E4EDF7"
										d="M13,3.00972645 C13,1.34750043 14.3424077,0 16.0020839,0 L85,0 L107,22 L107,113.999935 C107,115.656825 105.653127,117 104.005268,117 L15.9947322,117 C14.3407873,117 13,115.665878 13,113.990274 L13,3.00972645 Z"
									/>
									<path
										className="arrow"
										fill="#4A90E2"
										d="M73.0412946,61.2142857 C73.0412946,61.8058065 72.8348235,62.3080336 72.421875,62.7209821 L61.5234375,73.6361607 C61.0881675,74.0491092 60.58036,74.2555804 60,74.2555804 C59.4084792,74.2555804 58.9062521,74.0491092 58.4933036,73.6361607 L47.5948661,62.7209821 C47.1707568,62.3191944 46.9587054,61.8169673 46.9587054,61.2142857 C46.9587054,60.6227649 47.1707568,60.1149575 47.5948661,59.6908482 L48.8337054,58.4352679 C49.2689754,58.0223194 49.7767828,57.8158482 50.3571429,57.8158482 C50.9486637,57.8158482 51.4508908,58.0223194 51.8638393,58.4352679 L56.7857143,63.3571429 L56.7857143,51.5714286 C56.7857143,50.9910685 56.9977657,50.4888414 57.421875,50.0647321 C57.8459843,49.6406229 58.3482114,49.4285714 58.9285714,49.4285714 L61.0714286,49.4285714 C61.6517886,49.4285714 62.1540157,49.6406229 62.578125,50.0647321 C63.0022343,50.4888414 63.2142857,50.9910685 63.2142857,51.5714286 L63.2142857,63.3571429 L68.1361607,58.4352679 C68.5491092,58.0223194 69.0513363,57.8158482 69.6428571,57.8158482 C70.2232172,57.8158482 70.7310246,58.0223194 71.1662946,58.4352679 L72.421875,59.6908482 C72.8348235,60.1261182 73.0412946,60.6339257 73.0412946,61.2142857 Z"
									/>
									<path
										className="fold"
										fill="#4A90E2"
										opacity=".2"
										d="M84,0.0459127487 C84,-0.511600348 84.3143399,-0.649214134 84.7031002,-0.260453851 L106.789944,21.8263904 C107.178256,22.2147019 107.044718,22.5294906 106.483578,22.5294906 L85.0094668,22.5294906 C84.4519537,22.5294906 84,22.0811639 84,21.5200238 L84,0.0459127487 Z"
									/>
								</g>
								<ellipse
									className="shadow"
									id="shadow"
									cx="60"
									cy="147"
									fill="#000000"
									opacity=".1"
									rx="60"
									ry="4"
								/>
							</svg>
						</div>
						{this.props.loading ? (
							<div className="import-loading">
								<ReactTemplates.loader dark={false} />
								<h3>{this.lang.uploading}</h3>
							</div>
						) : null}
					</div>
					<div className="import-content">
						<h2>{this.lang.beforeImporting}</h2>
						{this.lang.beforeImportingInfo1}{' '}
						<ReactTemplates.elevio articleId={865} sidebar={true}>
							<a
								href
								onClick={function (e) {
									e.preventDefault();
								}}
							>
								{this.lang.beforeImportingInfo2}
							</a>
						</ReactTemplates.elevio>
						{'.'}
						<h2>{templateTitle}</h2>
						{this.lang.downloadTemplateDesc}
						<DropDownMenu
							renderTrigger={(isExpanded, setExpanded) =>
								this.templateButton(availableTemplates.default, true, setExpanded)
							}
						>
							{
								<Block>
									{availableTemplates.templates.map(template => this.templateButton(template))}
								</Block>
							}
						</DropDownMenu>
					</div>
				</div>
			</div>
		);
	}
});

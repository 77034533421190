'use strict';

(function () {
	var UNSAFE_componentWillMount = function () {
		var t = window.Tools.$translate;
		this.lang = {
			noName: t('default.noName')
		};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var initSelect2 = function (self) {
		var Contact = window.Tools.Contact;
		var opts = {
			resource: Contact,
			required: self.props.required || false,
			multiple: self.props.multiple || false,
			placeholder: function () {
				return self.props.placeholder;
			},
			linked: self.props.linked || false,
			ajax: true,
			asIds: false,
			idAttr: Contact.attr.id,
			titleAttr: Contact.attr.name,
			sorting: { field: Contact.attr.name, ascending: true },
			emptyMsg: self.lang.noName,
			addEvent: 'contact.added',
			filterType: 'contact',
			onChange: self.props.onChange,
			goTo:
				self.props.goTo ||
				function () {
					if (self.props.beforeGoTo && typeof self.props.beforeGoTo === 'function') {
						self.props.beforeGoTo().then(function () {
							return window.Tools.$state.go(
								'contact.dashboard',
								{ id: self.props.value.id },
								{ reload: true }
							);
						});
					} else {
						return window.Tools.$state.go(
							'contact.dashboard',
							{ id: self.props.value.id },
							{ reload: true }
						);
					}
				},
			filters: function (rb) {
				if (self.props.accountId) {
					rb.addFilter(Contact.attr.client.attr.id, rb.comparisonTypes.Equals, self.props.accountId);
				}
				if (self.props.onlyActive) {
					rb.addFilter(Contact.attr.active, rb.comparisonTypes.Equals, true);
				}
			}
		};

		if (self.props.formatSelection) {
			opts.formatSelection = self.props.formatSelection;
		}
		if (self.props.formatResult) {
			opts.formatResult = self.props.formatResult;
		}
		if (self.props.fields) {
			opts.fields = self.props.fields;
		}

		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upContacts = window.ReactCreateClass({
		accountId: null,
		getStateFromProps: function (props) {
			return {
				accountId: props.accountId
			};
		},
		getInitialState: function () {
			return this.getStateFromProps(this.props);
		},
		UNSAFE_componentWillReceiveProps: function (nextProps) {
			var state = this.getStateFromProps(nextProps);

			this.setState(state);
		},
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: function () {
			ReactTemplates.TOOLS.selectHelper.updateValue.bind(this)();
			if (this.accountId !== this.state.accountId) {
				initSelect2(this, false);
			}
			this.accountId = this.state.accountId;
		},
		componentDidMount: function () {
			initSelect2(this, false);
			this.accountId = this.state.accountId;
		},
		render: render
	});
})();

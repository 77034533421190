'use strict';
import { openDrawer } from 'Services/Drawer';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

angular.module('upDirectives').directive('upVoiceRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		controller: [
			'$scope',
			'$translate',
			'$upModal',
			'AppService',
			'VoiceService',
			'$state',
			'PhoneCall',
			'RequestBuilder',
			'$q',
			'NotificationService',
			'StandardIntegration',
			'FeatureHelper',
			function (
				$scope,
				$translate,
				$upModal,
				AppService,
				VoiceService,
				$state,
				PhoneCall,
				RequestBuilder,
				$q,
				NotificationService,
				StandardIntegration,
				FeatureHelper
			) {
				var VoiceCtrl = this;
				var customerId;
				var rawNumber;
				var callTimeout;
				var pollIntervall;
				var pollTime = 10000;

				var open = function (data) {
					VoiceCtrl.rootData.minimized = true;
					VoiceCtrl.rootData.open = true;
					makeCall(data);
				};

				var onClose = function () {
					VoiceCtrl.rootData.open = false;
					$scope.$root.$broadcast('voiceClosed');

					VoiceCtrl.render();
				};

				var toggleMinimize = function () {
					VoiceCtrl.rootData.minimized = !VoiceCtrl.rootData.minimized;
					VoiceCtrl.render();
				};

				var hangupUI = function (fromCall) {
					// If we come from an activity we apparently don't want to show followup
					const hasRelatedActivity = _.find(VoiceCtrl.rootData.related, { type: 'Activity' });
					VoiceService.setCallInfo('idle');

					if (VoiceCtrl.rootData.callerId && fromCall && !hasRelatedActivity) {
						VoiceCtrl.rootData.state = 'followup';
						VoiceCtrl.render();
					} else {
						VoiceCtrl.rootData.state = 'idle';
						onClose();
					}

					Tools.$rootScope.$broadcast('phoneCall.hangup', VoiceCtrl.rootData.phoneCallId);
				};

				function hangUp(fromCall, skipReq) {
					VoiceService.callEnded();
					VoiceCtrl.rootData.callStart = null;
					VoiceCtrl.rootData.minimized = false;

					if (callTimeout) {
						clearTimeout(callTimeout);
					}

					var integration = AppService.getPhoneIntegration();

					if (VoiceCtrl.deregisterAll) {
						VoiceCtrl.deregisterAll();
					}

					if (!integration) {
						return;
					}

					if (skipReq) {
						return hangupUI(fromCall);
					}

					// Mock
					var reqData = {
						type: 'hangup',
						data: {
							callId: VoiceCtrl.rootData.phoneCallId
						},
						integrationId: integration.id
					};
					PhoneCall.runAction(reqData)
						.finally(function () {
							hangupUI(fromCall);
						})
						.catch(function () {});
				}

				var reDial = function (related) {
					open({
						client: VoiceCtrl.rootData.callerId.client,
						contact: VoiceCtrl.rootData.callerId.contact,
						number: rawNumber,
						related: related
					});
				};

				var doAction = function (action) {
					switch (action) {
						case 'activity':
							return newActivity();
						case 'order':
							return newOrder();
						case 'appointment':
							return newAppointment();
						case 'mail':
							return newMail();
						case 'opportunity':
							return newOpportunity();
						case 'goToAccount':
							return $state.go('account.dashboard', { id: VoiceCtrl.rootData.callerId.client.id });
						case 'goToContact':
							return $state.go('contact.dashboard', { id: VoiceCtrl.rootData.callerId.contact.id });
						case 'goTo':
							if (VoiceCtrl.rootData.callerId.contact) {
								return $state.go('contact.dashboard', { id: VoiceCtrl.rootData.callerId.contact.id });
							}
							return $state.go('account.dashboard', { id: VoiceCtrl.rootData.callerId.client.id });
					}
				};

				function newActivity(activityType) {
					var activity = {
						phoneCallId: VoiceCtrl.rootData.phoneCallId
					};

					if (VoiceCtrl.rootData.callerId?.client?.id) {
						activity.client = {
							id: VoiceCtrl.rootData.callerId.client.id
						};
					}
					if (VoiceCtrl.rootData.callerId?.contact?.id) {
						activity.contacts = [{ id: VoiceCtrl.rootData.callerId.contact.id }];
					}

					if (activityType) {
						activity.activityType = activityType;
					}

					$upModal.open('editActivity', { activity: activity });
				}

				function newPhoneCall() {
					openDrawer('CreateCall', {
						client: {
							id: VoiceCtrl.rootData.callerId.client ? VoiceCtrl.rootData.callerId.client.id : null
						},
						contacts: VoiceCtrl.rootData.callerId.contact
							? [{ id: VoiceCtrl.rootData.callerId.contact.id }]
							: null,
						phoneCallId: VoiceCtrl.rootData.phoneCallId
					});
				}

				function newOrder() {
					$upModal.open('editOrder', {
						clientId: VoiceCtrl.rootData.callerId.client ? VoiceCtrl.rootData.callerId.client.id : null,
						contactId: VoiceCtrl.rootData.callerId.contact ? VoiceCtrl.rootData.callerId.contact.id : null,
						phoneCallId: VoiceCtrl.rootData.phoneCallId
					});
				}

				function newAppointment() {
					$upModal.open('editAppointment', {
						appointment: {
							client: {
								id: VoiceCtrl.rootData.callerId.client ? VoiceCtrl.rootData.callerId.client.id : null
							},
							contacts: VoiceCtrl.rootData.callerId.contact
								? [{ id: VoiceCtrl.rootData.callerId.contact.id }]
								: null,
							phoneCallId: VoiceCtrl.rootData.phoneCallId
						}
					});
				}

				function newMail() {
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
						openNewMailWithContact(VoiceCtrl.rootData.callerId.contact);
					} else {
						$upModal.open('sendEmail', {
							type: 'mail',
							contactId: VoiceCtrl.rootData.callerId.contact
								? VoiceCtrl.rootData.callerId.contact.id
								: null,
							customerId: customerId
						});
					}
				}

				function newOpportunity() {
					$upModal.open('editOrder', {
						type: 'opportunity',
						clientId: VoiceCtrl.rootData.callerId.client ? VoiceCtrl.rootData.callerId.client.id : null,
						contactId: VoiceCtrl.rootData.callerId.contact ? VoiceCtrl.rootData.callerId.contact.id : null,
						phoneCallId: VoiceCtrl.rootData.phoneCallId
					});
				}

				var checkForOngoing = function () {
					var integration = AppService.getPhoneIntegration();
					if (!integration) {
						return $q.when({ data: null });
					}

					var reqData = {
						type: 'ongoing',
						data: {},
						integrationId: integration.id
					};

					return PhoneCall.runAction(reqData);
				};

				var endCallIfEnded = function () {
					if (pollIntervall) {
						clearTimeout(pollIntervall);
					}
					checkForOngoing()
						.then(function (res) {
							if (!res.data || !res.data.phoneNumber) {
								hangUp(true, true);
							} else {
								pollIntervall = setTimeout(endCallIfEnded, pollTime);
							}
						})
						.catch(function (error) {
							console.error('upVoiceRoot - endCallIfEnded', error);
						});
				};

				function transitionToPickup(config, phoneCall) {
					VoiceCtrl.rootData.state = 'inCall';
					VoiceService.setCallInfo('inCall', Date.now());
					VoiceCtrl.rootData.callStart = Date.now();
					VoiceCtrl.rootData.minimized = false;
					VoiceCtrl.rootData.open = true;

					VoiceCtrl.render();
					var integration = AppService.getPhoneIntegration();

					if (integration.capabilities && !integration.capabilities.pickupHook) {
						pollIntervall = setTimeout(endCallIfEnded, pollTime);
					}

					var hasRelatedActivity;

					if (phoneCall.data != null) {
						hasRelatedActivity =
							phoneCall.data.related &&
							phoneCall.data.related[0] &&
							phoneCall.data.related[0].id &&
							phoneCall.data.related[0].type === 'Activity';
					}

					if (config && config.AutoOpenActivity && !hasRelatedActivity) {
						if (FeatureHelper.hasSoftDeployAccess($scope, 'TODO_LIST')) {
							newPhoneCall();
						} else {
							newActivity(config.defaultActivityType);
						}
					}
				}

				function makeCall(data) {
					var integration = AppService.getPhoneIntegration();

					if (!integration || VoiceService.callInProgress()) {
						return;
					}

					try {
						VoiceCtrl.rootData.number = window.leodido.i18n.PhoneNumbers.formatNumber(data.number, 'SE', 1);
					} catch (error) {
						console.warn(error);
						if (!_.get(integration, 'capabilities.useCallToLinks')) {
							const notification = {
								style: Tools.NotificationService.style.ERROR,
								icon: 'times',
								title: 'default.error',
								body: 'default.invalidPhonenumber'
							};
							Tools.NotificationService.addNotification(notification);
						}
						return;
					}

					rawNumber = data.number;

					if (data.client || data.contact) {
						VoiceCtrl.rootData.callerId = {
							contact: data.contact || null,
							client: data.client || null
						};
						VoiceCtrl.rootData.related = data.related || null;
					} else {
						VoiceCtrl.rootData.callerId = null;
						VoiceCtrl.rootData.related = null;
					}

					if (_.get(integration, 'capabilities.useCallToLinks')) {
						VoiceCtrl.rootData.minimized = true;
						VoiceCtrl.rootData.open = false;

						setTimeout(() => {
							const reqData = {
								type: 'initiate',
								data: {
									number: VoiceCtrl.rootData.number.replace(/ /g, ''),
									clientId: VoiceCtrl.rootData.callerId?.client?.id ?? null,
									contactId: VoiceCtrl.rootData.callerId?.contact?.id ?? null,
									related: VoiceCtrl.rootData.related || null
								},
								integrationId: integration.id
							};

							PhoneCall.runAction(reqData).catch(function (error) {
								console.error('upVoiceRoot - makeCall', error);
								NotificationService.addNotification({
									style: NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'voice.errorMaikingCall'
								});
								hangUp();
							});

							VoiceCtrl.render();
						}, 0);
					} else {
						const reqData = {
							type: 'call',
							data: {
								number: data.number,
								clientId: VoiceCtrl.rootData.callerId.client
									? VoiceCtrl.rootData.callerId.client.id
									: null,
								contactId: VoiceCtrl.rootData.callerId.contact
									? VoiceCtrl.rootData.callerId.contact.id
									: null,
								related: VoiceCtrl.rootData.related || null
							},
							integrationId: integration.id
						};

						VoiceService.callStarted();
						VoiceCtrl.rootData.state = 'dialing';
						VoiceService.setCallInfo('dialing', null, {
							...VoiceCtrl.rootData.callerId,
							number: VoiceCtrl.rootData.number
						});

						setTimeout(() => {
							StandardIntegration.setting(customerId)
								.find({ integration_id: integration.id })
								.then(function (res) {
									try {
										return JSON.parse(res.data[0].configJson);
									} catch (e) {
										return null;
									}
								})
								.then(function (config) {
									if (data.related && data.related.closeDate) {
										reqData.data.related = null;
									}

									return PhoneCall.runAction(reqData).then(function (phoneCall) {
										VoiceCtrl.rootData.phoneCallId = phoneCall.data.id;
										registerHooks(config, phoneCall);
									});
								})
								.catch(function (error) {
									console.error('upVoiceRoot - makeCall', error);
									NotificationService.addNotification({
										style: NotificationService.style.ERROR,
										icon: 'times',
										title: 'default.error',
										body: 'voice.errorMaikingCall'
									});
									hangUp();
								});

							VoiceCtrl.render();
						}, 0);
					}
				}

				var showCallList = function () {
					$state.go('react-root-phonecalls');
				};

				function registerHooks(config, phoneCall) {
					var integration = AppService.getPhoneIntegration();

					var errorDeregister = $scope.$on('userPrivate.phone.error', function () {
						NotificationService.addNotification({
							style: NotificationService.style.ERROR,
							icon: 'times',
							title: 'default.error',
							body: 'voice.errorMaikingCall'
						});
						hangUp(false, true);
					});

					if (integration.capabilities && integration.capabilities.pickupHook) {
						var pickupDeregister = $scope.$on('userPrivate.phone.pickup', function () {
							transitionToPickup(config, phoneCall);
							pickupDeregister();
						});
					} else {
						if (VoiceCtrl.rootData.state !== 'inCall') {
							callTimeout = setTimeout(transitionToPickup.bind(null, config, phoneCall), 4000);
						}
					}

					if (integration.capabilities && integration.capabilities.hangupHook) {
						var hangupDeregister = $scope.$on('userPrivate.phone.hangup', function () {
							hangUp(true, true);
						});
					}

					if (VoiceCtrl.deregisterAll) {
						VoiceCtrl.deregisterAll();
					}

					VoiceCtrl.deregisterAll = function () {
						if (errorDeregister && typeof hangupDeregister === 'function') {
							errorDeregister();
						}

						if (hangupDeregister && typeof hangupDeregister === 'function') {
							hangupDeregister();
						}

						if (pickupDeregister && typeof pickupDeregister === 'function') {
							pickupDeregister();
						}
					};
				}

				function initCallToIntegration(integration) {
					StandardIntegration.setting(customerId)
						.find({ integration_id: integration.id })
						.then(function (res) {
							try {
								return JSON.parse(res.data[0].configJson);
							} catch (e) {
								return null;
							}
						})
						.then(function (config) {
							if (VoiceCtrl.deregisterAllCallToListeners) {
								VoiceCtrl.deregisterAllCallToListeners();
							}
							const callingDeregister = $scope.$on(
								'userPrivate.phone.calling',
								function (event, phoneCall) {
									VoiceCtrl.rootData.phoneCall = phoneCall;
									VoiceCtrl.rootData.phoneCallId = phoneCall.id;
									VoiceCtrl.rootData.number = phoneCall.phoneNumber;
									rawNumber = phoneCall.phoneNumber;
									VoiceCtrl.rootData.related = phoneCall.related;

									if (phoneCall.client || phoneCall.contact) {
										VoiceCtrl.rootData.callerId = {
											contact: phoneCall.contact || null,
											client: phoneCall.client || null
										};
									} else {
										VoiceCtrl.rootData.callerId = null;
									}

									VoiceService.callStarted();
									VoiceCtrl.rootData.state = 'dialing';
									VoiceCtrl.rootData.minimized = false;
									VoiceCtrl.rootData.open = true;
									VoiceService.setCallInfo('dialing');
									VoiceCtrl.render();
								}
							);

							const errorDeregister = $scope.$on('userPrivate.phone.error', function () {
								NotificationService.addNotification({
									style: NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'voice.errorMaikingCall'
								});
								hangUp(false, true);
							});

							const pickupDeregister = $scope.$on(
								'userPrivate.phone.pickup',
								function (event, phoneCall) {
									if (phoneCall.id) {
										$scope.$broadcast('userPrivate.phone.calling', phoneCall);
									}
									transitionToPickup(config, { data: VoiceCtrl.rootData.phoneCall });
								}
							);

							const hangupDeregister = $scope.$on('userPrivate.phone.hangup', function () {
								hangUp(true, true);
							});

							VoiceCtrl.deregisterAllCallToListeners = function () {
								errorDeregister();
								hangupDeregister();
								pickupDeregister();
								callingDeregister();
							};
						})
						.catch(function (error) {
							console.error('upVoiceRoot - init', error);
						});
				}

				$scope.$on('standardIntegrationUser.updated', function (e, data) {
					if (data && data.masterIntegration) {
						var isPhone = data.masterIntegration.standardIntegrationInit.some(function (init) {
							return init.type === 'phone';
						});

						if (!isPhone) {
							return;
						}

						if (data.masterIntegration.active && data.userSettings.active) {
							var phoneIntegration = {
								id: data.masterIntegration.id,
								name: data.masterIntegration.name
							};
							AppService.setPhoneIntegration([phoneIntegration]);
						} else {
							AppService.setPhoneIntegration(null);
						}

						$scope.$root.$broadcast('phoneIntegration.updated');
					}
				});

				$scope.$on('phoneIntegration.updated', () => {
					var integration = AppService.getPhoneIntegration();
					if (!integration) {
						return;
					}
					if (_.get(integration, 'capabilities.useCallToLinks')) {
						initCallToIntegration(integration);
					}
				});

				let unsubOpenEvent;

				var init = function () {
					var integration = AppService.getPhoneIntegration();
					if (!integration) {
						return;
					}
					customerId = AppService.getCustomerId();

					if (unsubOpenEvent) {
						unsubOpenEvent();
					}

					unsubOpenEvent = $scope.$root.$on('openVoice', function (e, data) {
						open(data);
					});

					$scope.$on('$destroy', function () {
						if (unsubOpenEvent) {
							unsubOpenEvent();
						}
					});

					if (_.get(integration, 'capabilities.useCallToLinks')) {
						VoiceCtrl.rootData.isCallTo = true;
						initCallToIntegration(integration);
					} else {
						// Check for active calls
						var phoneConfig;
						StandardIntegration.setting(customerId)
							.find({ integration_id: integration.id })
							.then(function (res) {
								try {
									return JSON.parse(res.data[0].configJson);
								} catch (e) {
									return null;
								}
							})
							.then(function (config) {
								phoneConfig = config;
							})
							.then(checkForOngoing)
							.then(function (res) {
								// If there is an ongoing call on init, we set the status
								if (res.data && res.data.phoneNumber) {
									var call = res.data;
									VoiceService.callStarted();

									VoiceCtrl.rootData.phoneCallId = res.data.id;
									VoiceCtrl.rootData.number = window.leodido.i18n.PhoneNumbers.formatNumber(
										call.phoneNumber,
										'SE',
										1
									);
									rawNumber = call.phoneNumber;

									VoiceCtrl.rootData.callerId = {
										contact: call.contact || null,
										client: call.client || null
									};

									VoiceCtrl.rootData.state = 'inCall';
									VoiceService.setCallInfo('inCall', moment(call.date).valueOf(), {
										...VoiceCtrl.rootData.callerId,
										number: VoiceCtrl.rootData.number
									});
									VoiceCtrl.rootData.callStart = moment(call.date).valueOf();
									VoiceCtrl.rootData.minimized = false;
									VoiceCtrl.rootData.open = true;

									registerHooks(phoneConfig, res.data);

									if (integration.capabilities && !integration.capabilities.pickupHook) {
										pollIntervall = setTimeout(endCallIfEnded, pollTime);
									}
									VoiceCtrl.render();
								}
							})
							.catch(function (error) {
								console.error('upVoiceRoot - init', error);
							});
					}

					VoiceCtrl.render();
				};

				VoiceCtrl.rootData = {
					state: 'idle',
					open: false,
					toggleMinimize: toggleMinimize,
					hangUp: hangUp,
					onClose: onClose,
					reDial: reDial,
					phoneCallId: null,
					phoneCall: null,
					callerId: null,
					number: null,
					minimized: false,
					callStart: null,
					isCallTo: false,
					doAction: doAction,
					showCallList: showCallList,
					related: null
				};

				// Injects (do not remove anything here!!!)
				VoiceCtrl.tools = {
					$translate: $translate.instant
				};

				$scope.$root.$on('AppService.loaded', init);
				$scope.$root.$on('hangUpVoice', () => hangUp(false, true));
				$scope.$root.$on('hangUpVoiceReq', () => hangUp(false, false));
			}
		],
		link: function ($scope, $element, $attr, VoiceCtrl) {
			let firstElement = $element[0];
			var renderTimeout;

			var render = function () {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}

				renderTimeout = setTimeout(function () {
					if (!firstElement) {
						return;
					}

					ReactDOM.render(
						React.createElement(ReactTemplates.voice.root, {
							// Data and stuff
							rootData: VoiceCtrl.rootData,
							tools: VoiceCtrl.tools
						}),
						firstElement,
						function () {}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			VoiceCtrl.render = render;
		}
	};
});

angular.module('services.voice', []).service('VoiceService', [
	'$rootScope',
	function ($rootScope) {
		var ongoingCall = false;
		//idle, dialing or inCall
		var callState = 'idle';
		var callStart = null;
		var calleeInfo = null;
		return {
			callStarted: function () {
				$rootScope.$broadcast('callStatusChanged', { ongoingCall: true });
				ongoingCall = true;
			},
			callEnded: function () {
				$rootScope.$broadcast('callStatusChanged', { ongoingCall: false });
				ongoingCall = false;
				callStart = null;
			},
			callInProgress: function () {
				return ongoingCall;
			},
			callClient: function (client, number, related) {
				if (!ongoingCall) {
					const onGoingCallInfo = { client, number, related };
					$rootScope.$broadcast('openVoice', onGoingCallInfo);
					this.setCallInfo(null, null, onGoingCallInfo);
				}
			},
			callContact: function (contact, number, related) {
				if (!ongoingCall) {
					const onGoingCallInfo = { contact, client: contact?.client, number, related };
					$rootScope.$broadcast('openVoice', onGoingCallInfo);
					this.setCallInfo(null, null, onGoingCallInfo);
				}
			},
			getCallInfo: function () {
				return { callState, callStart, calleeInfo };
			},
			setCallInfo: function (state, callStartTime, onGoingCallInfo) {
				callState = state || callState;
				if (callState === 'idle') {
					callStart = null;
					calleeInfo = null;
				} else {
					callStart = callStartTime || callStart;
					calleeInfo = onGoingCallInfo || calleeInfo;
				}
				$rootScope.$broadcast('callInfoChange', { callState, callStart, calleeInfo });
			},
			hangUp: function () {
				Tools.$rootScope.$broadcast('hangUpVoiceReq');
			}
		};
	}
]);

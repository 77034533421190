import { globalTracker } from 'App/babel/helpers/Tracker';

// VI SKA HA EN RESOURCE PER DATATYP! HÄR LIGGER DET BÅDE DET ENA OCH DE ANDRA :(

angular.module('upResources').factory('Ads', [
	'$resource',
	'ResourceHelper',
	'$q',
	'URL',
	'API',
	'AppService',
	'ParseGeneric',
	function ($resource, ResourceHelper, $q, URL, API, AppService, ParseGeneric) {
		var helper = new ResourceHelper();

		var eventPrefix = 'ads';

		helper.setDefaults({
			eventPrefix: eventPrefix,
			createSuccessBody: 'saved.ad',
			deleteSucccessBody: 'deleted.ad',
			createErrorBody: 'saveError.ad',
			updateErrorBody: 'saveError.ad',
			deleteErrorBody: 'deleteError.ad'
		});

		var EVENTS = {
			eventPrefix: eventPrefix,
			added: 'added',
			updated: 'updated',
			deleted: 'deleted',
			accountCreated: 'accountCreated',
			transactionCreated: 'transactionCreated',
			campaignCreated: 'campaignCreated',
			campaignUpdated: 'campaignUpdated',
			creativeCreated: 'creativeCreated'
		};

		var TARGET_TYPES = {
			campaign: 1,
			account: 2,
			client: 2,
			ignoreCampaign: 3,
			ignoreClient: 4,
			ignoreAccount: 4,
			locationCountry: 10,
			locationRegion: 11,
			locationCity: 12,
			officeHoursOffset: 20,
			webPage: 30,
			webPageWildCard: 31,
			ignoreWebPage: 32,
			ignoreWebPageWildCard: 33
		};

		var STATUSES = {
			error: -1,
			draft: 1,
			launch: 2,
			active: 3,
			paused: 4,
			finished: 5,
			done: 6,
			archived: 7
		};

		var dateFields = ['finishedTime', 'lastModificationTime', 'launchDate', 'regDate'];

		var Model = {
			newCampaign: function () {
				var campaign = {
					name: '',
					budget: 0,
					startDate: new Date(),
					endDate: moment().add(1, 'month'),
					status: STATUSES.draft,
					useUrlFromCreative: true,
					creative: [],
					siteTemplate: [],
					target: [],
					targetOfficeHours: 0,
					targetLocation: 0,
					targetRetargeting: 0
				};

				return campaign;
			},

			customer: function (customer) {
				var instance = {};
				var apiUrl = URL + API + customer;
				var baseUrl = apiUrl + '/engage/';

				var Ads = $resource(
					baseUrl + ':id',
					{},
					{
						// ad account enpoints
						createAccount: { method: 'POST', isArray: false, url: baseUrl + 'account/' },
						getAccount: { method: 'GET', isArray: false, url: baseUrl + 'account/' },

						// ad campaign endpoints
						updateCampaign: {
							method: 'PUT',
							isArray: false,
							url: baseUrl + 'campaign/:id',
							transformResponse: ParseGeneric('ParseAdCampaign', {
								isArray: false,
								dateFields: dateFields
							})
						},
						createCampaign: {
							method: 'POST',
							isArray: false,
							url: baseUrl + 'campaign/',
							transformResponse: ParseGeneric('ParseAdCampaign', {
								isArray: false,
								dateFields: dateFields
							})
						},
						listCampaign: {
							method: 'GET',
							url: baseUrl + 'campaign/',
							transformResponse: ParseGeneric('ParseAdCampaign', {
								isArray: true,
								dateFields: dateFields
							})
						},
						listCampaignWithMetadata: {
							method: 'GET',
							url: baseUrl + 'campaign/list',
							transformResponse: ParseGeneric('ParseAdCampaign', {
								isArray: true,
								dateFields: dateFields
							})
						},
						getCampaign: {
							method: 'GET',
							isArray: false,
							url: baseUrl + 'campaign/:id',
							transformResponse: ParseGeneric('ParseAdCampaign', {
								isArray: false,
								dateFields: dateFields
							})
						},
						deleteCampaign: { method: 'DELETE', isArray: false, url: baseUrl + 'campaign/:id' },

						// ad campaign relations
						listCampaignAccount: { method: 'GET', url: baseUrl + 'campaign/:id/client' },
						listCampaignDay: { method: 'GET', url: baseUrl + 'campaign/:id/day' },
						listCampaignSite: { method: 'GET', url: baseUrl + 'campaign/:id/site' },
						listCampaignLocation: { method: 'GET', url: baseUrl + 'campaign/:id/location' },
						listCampaignLanguage: { method: 'GET', url: baseUrl + 'campaign/:id/language' },
						listCampaignDevice: { method: 'GET', url: baseUrl + 'campaign/:id/device' },

						// ad reports
						reportDay: { method: 'GET', url: apiUrl + '/report/engage/stats/day' },
						reportSite: { method: 'GET', url: apiUrl + '/report/engage/stats/site' },
						reportClient: { method: 'GET', url: apiUrl + '/report/engage/stats/client' },
						reportClients: { method: 'GET', url: apiUrl + '/report/engage/stats/sumClients' },
						maDashboardStats: { method: 'GET', url: apiUrl + '/report/engage/stats/maDashboardStats' },

						// creative endpoints
						listCreative: { method: 'GET', url: baseUrl + 'creative/' },
						listGroupedCreative: { method: 'GET', url: baseUrl + 'creative/list' },
						getCreative: { method: 'GET', isArray: false, url: baseUrl + 'creative/:id' },
						createCreative: { method: 'POST', isArray: false, url: baseUrl + 'creative/' },
						deleteCreative: { method: 'DELETE', isArray: false, url: baseUrl + 'creative/:id' },

						// site template endpoints
						listSiteTemplate: { method: 'GET', url: baseUrl + 'siteTemplate/' },

						// credit endpoints
						getCredit: { method: 'GET', isArray: false, url: baseUrl + 'credit/' },
						listCreditTransactions: { method: 'GET', url: baseUrl + 'creditTransaction/' },
						getCreditTransactions: {
							method: 'GET',
							isArray: false,
							url: baseUrl + 'creditTransaction/:id'
						},
						createCreditTransactions: {
							method: 'POST',
							isArray: false,
							url: baseUrl + 'creditTransaction/'
						},

						// check client ip status/info
						getClientIpInfo: { method: 'POST', isArray: false, url: apiUrl + '/function/clientIpInfo' },

						listLocations: { method: 'GET', url: baseUrl + 'location/' }
					}
				);

				var setNewCampaignStatus = function (id, status) {
					var obj = {
						id: id,
						status: status
					};
					var options = {};
					var promise = Ads.updateCampaign({ id: obj.id }, obj).$promise;
					return promise
						.then(function (res) {
							helper.onSave(options, res.data, false);

							var statusKey = _.findKey(STATUSES, function (value) {
								return value === status;
							});

							globalTracker.track('Updated AdsCampaign status to ' + statusKey, {
								adsBudget: res.data.budget
							});
							return res;
						})
						.catch(function (err) {
							if (err && err.data && err.data.error && err.data.error.key === 'TooLowBudget') {
								options.createErrorBody = options.updateErrorBody = 'ads.errorTooLowBudget';
							}
							helper.onSaveError(options, false);
							return err;
						});
				};

				instance.stats = function (type, filter) {
					var method;
					if (type === 'client') {
						method = Ads.reportClient;
					}
					if (type === 'day') {
						method = Ads.reportDay;
					}
					if (type === 'site') {
						method = Ads.reportSite;
					}
					if (type === 'sumClients') {
						method = Ads.reportClients;
					}
					if (type === 'maDashboardStats') {
						method = Ads.maDashboardStats;
					}
					return method(filter || {}).$promise;
				};

				instance.find = function (filter) {
					return Ads.listCampaign(filter || {}).$promise;
				};

				instance.findWithMetadata = function (filter) {
					return Ads.listCampaignWithMetadata(filter || {}).$promise;
				};

				instance.get = function (id) {
					return Ads.getCampaign({ id: id }).$promise;
				};

				instance.getAccounts = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignAccount(opts).$promise;
				};
				instance.getDays = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignDay(opts).$promise;
				};
				instance.getSites = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignSite(opts).$promise;
				};
				instance.getLocations = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignLocation(opts).$promise;
				};
				instance.getLanguages = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignLanguage(opts).$promise;
				};
				instance.getDevices = function (id, opts) {
					if (!opts) {
						opts = {};
					}
					opts.id = id;
					return Ads.listCampaignDevice(opts).$promise;
				};

				instance.findCreative = function (filter) {
					return Ads.listCreative(filter || {}).$promise;
				};

				instance.findGroupedCreative = function (filter) {
					return Ads.listGroupedCreative(filter || {}).$promise;
				};

				instance.getCreative = function (id) {
					return Ads.getCreative({ id: id }).$promise;
				};

				instance.deleteCreative = function (creative) {
					return Ads.deleteCreative({ id: creative.id }, { creative: creative }).$promise;
				};

				instance.createCreative = function (obj, options) {
					var defered = $q.defer();
					if (obj.id) {
						return defered.reject('no id');
					}
					Ads.createCreative(obj, defered.resolve, defered.reject);

					defered.promise
						.then(function (res) {
							helper.successNotification('ads.creativeCreated', 'ads.creativeCreated', res);
							helper.triggerEvent(options, EVENTS.creativeCreated, res.data);
						})
						.catch(function (err) {
							helper.errorNotification('ads.creativeCreatedErr', 'ads.creativeCreatedErr', err);
						});
					return defered.promise;
				};

				instance.getSiteTemplates = function () {
					return Ads.listSiteTemplate({}).$promise;
				};

				instance.getCredits = function () {
					return Ads.getCredit().$promise;
				};

				instance.getCreditTransaction = function (id) {
					return Ads.listCreditTransaction({ id: id }).$promise;
				};
				instance.getCreditTransactions = function (filter) {
					return Ads.listCreditTransactions(filter || {}).$promise;
				};

				instance.saveTransaction = function (obj, options) {
					if (obj.id) {
						return;
					}
					var promise = Ads.createCreditTransactions(obj).$promise;
					return promise
						.then(function (res) {
							helper.onSave(options, res.data, true);
							return res;
						})
						.catch(function (err) {
							helper.onSaveError(options, true);
							return err;
						});
				};

				instance.launch = function (campaignId) {
					return setNewCampaignStatus(campaignId, STATUSES.launch);
				};

				instance.pause = function (campaignId) {
					return setNewCampaignStatus(campaignId, STATUSES.paused);
				};

				instance.resume = function (campaignId) {
					return setNewCampaignStatus(campaignId, STATUSES.active);
				};

				instance.archive = function (campaignId) {
					return setNewCampaignStatus(campaignId, STATUSES.archived);
				};

				instance.finish = function (campaignId) {
					return setNewCampaignStatus(campaignId, STATUSES.finished);
				};

				instance.getClientIpInfo = function (obj) {
					var promise = Ads.getClientIpInfo(obj).$promise;
					return promise.then(function (res) {
						return res;
					});
				};

				instance.listLocations = function (obj) {
					var promise = Ads.listLocations(obj).$promise;
					return promise.then(function (res) {
						return res;
					});
				};

				instance.save = function (obj, options) {
					var defered = $q.defer();
					if (obj.id) {
						Ads.updateCampaign({ id: obj.id }, obj, defered.resolve, defered.reject);
					} else {
						Ads.createCampaign(obj, defered.resolve, defered.reject);
					}
					defered.promise
						.then(function (res) {
							helper.onSave(options, res.data, !obj.id);
						})
						.catch(function () {
							helper.onSaveError(options, !obj.id);
						});
					return defered.promise;
				};

				instance.delete = function (obj, options) {
					var promise = Ads.deleteCampaign({ id: obj.id }).$promise;
					return promise
						.then(function () {
							helper.onDelete(options, obj);
							return obj;
						})
						.catch(function (err) {
							helper.onDeleteError(options);
							return err;
						});
				};

				instance.getAccount = function () {
					var defer = $q.defer();
					var promise = Ads.getAccount().$promise;

					promise.then(defer.resolve).catch(function (err) {
						if (err && err.status === 404) {
							defer.resolve({ data: null });
						} else {
							defer.reject(err);
						}
					});

					return defer.promise;
				};

				instance.saveAccount = function (obj, options) {
					var promise = Ads.createAccount(obj).$promise;
					return promise
						.then(function (res) {
							// Notify user
							helper.successNotification('ads.accountCreated', 'ads.accountCreated', res);

							helper.triggerEvent(options, EVENTS.accountCreated, res.data);
							AppService.setAdAccount(res.data);
							return res;
						})
						.catch(function (err) {
							// Notify user
							helper.errorNotification('default.error', 'ads.accountCreatedError', err);
							return err;
						});
				};

				return instance;
			},

			eventPrefix: eventPrefix,
			events: EVENTS,
			targetTypes: TARGET_TYPES,
			statuses: STATUSES
		};

		return Model;
	}
]);

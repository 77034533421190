import Project from 'App/resources/Project';
import Segment from 'App/resources/Segment';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { FlowStep } from 'App/resources/Model/Flow';

export async function fetchProjects(step: FlowStep) {
	if (Array.isArray(step.params)) {
		const removeProject = step.params.find(property => property.name === 'RemoveProject');
		const addProject = step.params.find(property => property.name === 'AddProject');
		const project = step.params.find(property => property.name === 'Project');
		const projectIds = [];

		if (removeProject && typeof removeProject.value === 'string') {
			projectIds.push(...removeProject.value.split(','));
		}
		if (addProject && typeof addProject.value === 'string') {
			projectIds.push(...addProject.value.split(','));
		}
		if (project) {
			projectIds.push(project.value as string);
		}

		if (!projectIds.length) {
			return [];
		}

		const rb = new RequestBuilder();
		rb.addFilter(Project.attr.id, comparisonTypes.Equals, projectIds);
		const { data: projects } = await Project.find(rb.build());

		return projects;
	} else {
		return [];
	}
}

export async function fetchSegments(step: FlowStep) {
	if (Array.isArray(step.params)) {
		const addSegment = step.params.find(property => property.name === 'AddSegments');

		if (!addSegment || !addSegment.value) {
			return [];
		}

		const rb = new RequestBuilder();
		rb.addFilter(Segment.attr.id, comparisonTypes.Equals, addSegment.value);
		const { data: segments } = await Segment.find(rb.build());

		return segments;
	} else {
		return [];
	}
}

export default function fetchRelationData(step: FlowStep) {
	return Promise.all([fetchProjects(step), fetchSegments(step)]);
}

import React from 'react';
import { Text, DropDownMenu, Block, Table, TableRow, TableColumn, Link } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import BEM from '@upsales/components/Utils/bemClass';

import './LimitSelect.scss';

type Props = {
	setLimit: (limit: number) => void;
	limit: number;
	limits: number[];
};

export default ({ setLimit, limit, limits }: Props) => {
	const classes = new BEM('LimitSelect');

	return (
		<Block className={classes.b()}>
			{`${translate('default.showing')} `}
			<DropDownMenu
				renderTrigger={(expanded, setExpanded) => (
					<Link onClick={event => setExpanded(event as any)}>{limit}</Link>
				)}
			>
				{setExpanded => (
					<Table>
						{limits.map(value => (
							<TableRow key={value}>
								<TableColumn
									size="sm"
									align="center"
									onClick={() => {
										setExpanded();
										setLimit(value);
									}}
								>
									<Text typeof="link" size="md" color={value === limit ? 'bright-blue' : 'black'}>
										{value}
									</Text>
								</TableColumn>
							</TableRow>
						))}
					</Table>
				)}
			</DropDownMenu>
			{` ${translate('filters.rowsPerPage').toLowerCase()}`}
		</Block>
	);
};

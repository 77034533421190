'use strict';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var OrderStage = window.Tools.OrderStage;

		var sortFn =
			self.props.sortFn ||
			function (data) {
				return _.sortByOrder(data, 'name');
			};

		var opts = {
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			disabled: self.props.disabled || false,
			ajax: false,
			asIds: false,
			onChange: self.props.onChange,
			idAttr: OrderStage.attr.id,
			titleAttr: OrderStage.attr.name,
			sorting: { field: OrderStage.attr.name, ascending: true },
			formatSelection: obj => {
				var entity = self.props.entity;
				var stage = window.Tools.AppService.getStages(entity, true).find(record => record.id === obj.id);
				if (stage) {
					return `${stage.name} (${stage.probability}%)`;
				}
				return obj.name ?? obj;
			},
			placeholder: self.props.placeholder,
			data: function () {
				var entity = self.props.entity;
				var data = window.Tools.AppService.getStages(entity, true).map(record => ({
					...record,
					name: `${record.name} (${record.probability}%)`
				}));
				if (self.props.hideZeroProbability) {
					data = _.filter(data, function (stage) {
						return stage.probability > 0;
					});
				}
				return sortFn(data);
			}
		};

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upStages = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

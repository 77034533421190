import OptOutModal from '../components/Admin/OptOutModal';

const validateStr = str => {
	if (!str || !str.length) {
		return false;
	}
	return true;
};

const validate = optOut => {
	let valid = true;
	const validated = optOut;
	if (!validateStr(optOut.title)) {
		optOut.$invalidTitle = true;
		valid = false;
	}
	if (!validateStr(optOut.description)) {
		optOut.$invalidDescription = true;
		valid = false;
	}

	validated.languages = optOut.languages.map(lang => {
		if (!validateStr(lang.title)) {
			lang.$invalidTitle = true;
			valid = false;
		}
		if (!validateStr(lang.description)) {
			lang.$invalidDescription = true;
			valid = false;
		}
		return lang;
	});
	return { valid, validated };
};

const map = optOut => {
	optOut.languages = optOut.languages.map(({ language, title, description }) => {
		return { language, title, description };
	});
	return optOut;
};

window.OptOutModalCtrl = (scope, optOut) => {
	let rootNode;
	let dataStore;

	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	const render = props => {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(React.createElement(OptOutModal, props), rootNode, () => scope.reloadModalPosition());
	};

	const actions = {
		onClose: () => scope.resolve(),
		onSave: () => {
			dataStore.set('saving', true);
			const optOut = dataStore.get('optOut');
			const { valid, validated } = validate(optOut);

			// Stop if invalid
			if (!valid) {
				return dataStore.setStore({ optOut: validated, saving: false });
			}

			// Map opt-out before save
			const mapped = map(optOut);

			Tools.GroupMailCategory.save(mapped)
				.then(() => scope.resolve())
				.catch(() => dataStore.set('saving', false));
		},
		onLanguageAdd: lang => {
			const { optOut, availableLanguages } = dataStore.pluck('optOut', 'availableLanguages');
			const code = lang.code.toLowerCase();

			_.pull(availableLanguages, code);

			optOut.languages.push({ language: code, title: '', description: '' });

			dataStore.setStore({ optOut, availableLanguages });
		},
		onLanguageRemove: language => {
			const { optOut, availableLanguages } = dataStore.pluck('optOut', 'availableLanguages');
			const i = _.findIndex(optOut.languages, { language });

			availableLanguages.push(language);

			optOut.languages.splice(i, 1);

			dataStore.setStore({ optOut, availableLanguages });
		},
		onLanguageChange: (language, data) => {
			let optOut = dataStore.get('optOut');
			if (data.title) {
				data.$invalidTitle = !validateStr(data.title);
			}
			if (data.description) {
				data.$invalidDescription = !validateStr(data.description);
			}

			if (language === 'en') {
				optOut = Object.assign(optOut, data);
			} else {
				const i = _.findIndex(optOut.languages, { language });
				optOut.languages[i] = Object.assign(optOut.languages[i], data);
			}

			dataStore.set('optOut', optOut);
		},
		activeChanged: value => dataStore.set('optOut.active', value),
		reloadModalPosition: () => scope.reloadModalPosition()
	};

	const init = () => {
		const availableLanguages = ['se', 'no', 'fi', 'dk', 'de', 'nl', 'pl', 'pt', 'es', 'jp', 'cn'];

		optOut.languages = optOut.languages || [];

		_.each(optOut.languages, lang => _.pull(availableLanguages, lang.language));

		const initialStore = {
			optOut,
			availableLanguages,
			saving: false
		};
		rootNode = document.getElementById('opt-out-modal');
		dataStore = new DataStore(render, actions, initialStore);
	};

	init();
};

import PropTypes from 'prop-types';
import React from 'react';
import ToggleList from './ToggleList';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import StackedDateInput from 'Components/Inputs/StackedDateInput/StackedDateInput';
import { Equals, NotEquals, EqualsAll } from 'Resources/ComparisonTypes';

export default class MailCampaignInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			all: Tools.$translate('default.allActions'),
			open: Tools.$translate('advancedSearch.open'),
			notopen: Tools.$translate('advancedSearch.notopen'),
			click: Tools.$translate('advancedSearch.click'),
			notclick: Tools.$translate('advancedSearch.notclick'),
			isNot: Tools.$translate('comparison.NotEquals'),
			placeholder: Tools.$translate('segment.anyMailcampaign'),
			equalsAll: Tools.$translate('default.all'),
			any: Tools.$translate('default.any')
		};

		this.onMailCampaignChanged = this.onChange.bind(this, 'mailCampaign');
		this.onTypeChanged = this.onChange.bind(this, 'type');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');

		this.typeOptons = [
			{ id: 'all', name: this.lang.all, not: false, value: null },
			{ id: 'open', name: this.lang.open, not: false, value: 'open' },
			{ id: 'notopen', name: this.lang.notopen, not: true, value: 'open' },
			{ id: 'click', name: this.lang.click, not: false, value: 'click' },
			{ id: 'notclick', name: this.lang.notclick, not: true, value: 'click' }
		];
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'mailCampaign':
				newFilter.value.MailCampaign.value = value;
				break;
			case 'type': {
				const option = _.find(this.typeOptons, { id: value.target.value });

				newFilter.value.EventType.value = option.value;
				newFilter.value.EventType.not = option.not;
				break;
			}
			case 'preset':
				newFilter.value.Date = value;
				break;
			case 'comperator':
				if (value === NotEquals) {
					newFilter.value.MailCampaign.comparisonType = Equals;
					newFilter.value.hasMailCampaign = false;
				} else {
					newFilter.value.MailCampaign.comparisonType = value;
					newFilter.value.hasMailCampaign = true;
				}
				break;
		}

		newFilter.inactive = false;

		onChange(newFilter.value, newFilter);
	}

	render() {
		const { filter, selectData, LIST_AJAX_LIMIT, fakeConfigs } = this.props;
		const typeValue = _.find(this.typeOptons, {
			value: filter.value.EventType.value,
			not: filter.value.EventType.not
		});

		const listProps = {
			placeholder: this.lang.placeholder,
			config: fakeConfigs.MailCampaign,
			defaultValue: filter.value.MailCampaign.value,
			onChange: this.onMailCampaignChanged,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.equalsAll
				},
				{
					value: NotEquals,
					text: this.lang.isNot
				}
			],
			onClick: this.onComperatorChange,
			value: filter.value.hasMailCampaign ? filter.value.MailCampaign.comparisonType || Equals : NotEquals
		};

		const isDynamicPreset =
			filter.value.Date?.value?.preset?.indexOf('lastX') === 0 ||
			filter.value.Date?.value?.preset?.indexOf('nextX') === 0;

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<ToggleList
						className={isDynamicPreset ? 'col-sm-4' : 'col-sm-5'}
						list={listProps}
						toggle={toggleProps}
						isListActive={() => true}
					/>
					<div className="col-sm-3">
						<ReactTemplates.INPUTS.upSelect
							className="form-control"
							required={true}
							data={this.typeOptons}
							defaultValue={typeValue}
							onChange={this.onTypeChanged}
							options={{ minimumResultsForSearch: -1 }}
						/>
					</div>
					<div className={isDynamicPreset ? 'col-sm-5' : 'col-sm-4'}>
						<StackedDateInput config={{}} filter={filter.value.Date} onChange={this.onPresetChange} />
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

MailCampaignInput.propTypes = {
	filter: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	fakeConfigs: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

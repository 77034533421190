import React from 'react';
import AnonListRow from './ListRow';
import PropTypes from 'prop-types';
import { isEqual } from '../Helpers/IsEqual';
import { Button } from '@upsales/components';
import AnonFieldPage from 'App/babel/components/anonymization/fieldPage/fieldPage';
import logError from 'Helpers/logError';
import openModal from 'App/services/Modal';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';

class AnonList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			contacts: [],
			page: props.rootData.pageData.currentPage,
			dialogOpen: false,
			sorting: ''
		};

		this.check = this.check.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
		this.setSort = this.setSort.bind(this);
	}

	toggleDialog() {
		this.setState({
			dialogOpen: !this.state.dialogOpen
		});
	}

	getDataAndUpdateState(query, page, stateToMerge) {
		const { rootData } = this.props;
		rootData.pageData
			.getDataFromQuery(query)
			.then(res => {
				const state = Object.assign({}, this.state, stateToMerge);

				state.contacts = res.data;
				if (page === true) {
					state.page = rootData.pageData.currentPage;
				}

				if (this.state.loading === true) {
					state.loading = false;
				}

				this.setState(state);
			})
			.catch(e => {
				logError(e, 'Failed to getDataAndUpdateState');
			});
	}

	whatIsQuery(q) {
		let query = {};

		if (Array.isArray(q)) {
			query.opts = JSON.stringify(q);
		} else {
			query = Object.assign({}, q);
		}

		return query;
	}

	setSort(sortKey) {
		const { rootData } = this.props;
		const query = this.whatIsQuery(rootData.pageData.query);

		query.sort = sortKey;
		query.desc = !this.state.desc;
		this.getDataAndUpdateState(query, false, { sorting: sortKey, desc: !this.state.desc });
	}

	componentDidMount() {
		const { rootData } = this.props;

		if (Object.keys(rootData.pageData.query).length > 0) {
			const query = this.whatIsQuery(rootData.pageData.query);

			query.limit = 50;
			query.offset = 0;

			this.getDataAndUpdateState(query);
		}
	}

	componentDidUpdate(prevProps) {
		const { rootData } = this.props;

		if (
			!isEqual(rootData.pageData, prevProps.rootData.pageData) ||
			rootData.pageData.currentPage !== this.state.page
		) {
			if (this.state.loading === false) {
				this.setState({ loading: true });
			}

			if (Object.keys(rootData.pageData.query).length > 0) {
				const query = this.whatIsQuery(rootData.pageData.query);
				query.offset = 0;

				if (rootData.pageData.currentPage > 1) {
					query.offset = 50 * (rootData.pageData.currentPage - 1);
				}

				query.limit = 50;
				this.getDataAndUpdateState(query, true);
			}
		}
	}

	check(item, checkAll) {
		if (item === null && checkAll === 'all') {
			return this.props.contactSelected(this.state.contacts, 'all');
		}

		if (item === null) {
			return this.props.contactSelected(this.state.contacts, checkAll);
		}

		this.props.contactSelected([item]);
	}

	renderRows() {
		const { rootData, selectedContacts, everyThingSelected } = this.props;
		const contacts = this.state.contacts;

		if (Object.keys(rootData.pageData.query).length === 0) {
			return <AnonListRow type="none" />;
		}

		if (Object.keys(rootData.pageData.query).length > 0 && this.state.loading) {
			return <AnonListRow type="loading" />;
		}

		if (Object.keys(rootData.pageData.query).length > 0 && !contacts.length) {
			return <AnonListRow type="compliant" />;
		}

		var returnContacts = contacts.map(contact => (
			<AnonListRow
				key={`contact-list-row_${contact.name}_${contact.id}`}
				selectedContacts={selectedContacts}
				everyThingSelected={everyThingSelected}
				check={this.check}
				item={contact}
			/>
		));

		return returnContacts;
	}

	onConfirmation = (fields, _selectedFields) => {
		const { rootData, selectedContacts } = this.props;
		rootData.pageData
			.startProcessing(selectedContacts, { fields: fields ? fields : _selectedFields })
			.then(() => {
				if (Object.keys(rootData.pageData.query).length > 0) {
					const query = this.whatIsQuery(rootData.pageData.query);

					query.limit = 50;
					query.offset = 0;

					this.props.deselectEverything();
					this.getDataAndUpdateState(query);
				}
			})
			.catch(e => {
				logError(e, 'Failed to startProcessing');
			});
	};

	render() {
		const {
			rootData,
			selectedContacts,
			selectedFields,
			obligatoryFields,
			selectField,
			selectAllFields,
			buttonTextNumber,
			everyThingSelected
		} = this.props;
		const _selectedFields = selectedFields.concat(obligatoryFields);
		const number = (rootData.pageData.currentPage - 1) * 50;
		const checkAllCheckbox =
			this.state.contacts.length > 0 &&
			selectedContacts.length >= rootData.pageData.currentPage * 50 &&
			selectedContacts[number] === this.state.contacts[0].id;

		const selectedContactsCount = everyThingSelected ? rootData.pageData.numContacts : selectedContacts.length;

		const alertConfirmOptions = {
			type: 'confirm',
			reactive: true,
			dialog: AlertConfirm,
			id: 'confirm-delete-fields',
			body:
				Tools.$translate('admin.anonymization.go.confirm.body_beforeNumber') +
				selectedContactsCount +
				Tools.$translate('admin.anonymization.go.confirm.body_afterNumber'),
			body2: Tools.$translate('admin.anonymization.go.confirm.body_afterNumber_2'),
			title: Tools.$translate('admin.anonymization.go.confirm.title'),
			confirmButtonText: Tools.$translate('admin.anonymization.startProcess'),
			confirmationMode: 'text',
			obligatoryFields: obligatoryFields,
			confirmText: Tools.AppService.getSelf().email
		};

		const openAlertConfirmModal = fields => {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					...alertConfirmOptions,
					onClose: confirmed => {
						if (confirmed) {
							this.onConfirmation(fields, _selectedFields);
						}
					}
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				Tools.$upModal.open('alert', alertConfirmOptions).then(() => {
					this.onConfirmation(fields, _selectedFields);
				});
			}
		};

		return (
			<div id="list">
				<div className="card">
					<h2>{Tools.$translate('admin.anonymization.listTitle')}</h2>
					<Button
						onClick={() => {
							const modalOpts = {
								type: 'confirm',
								id: 'must-have-fields',
								body: Tools.$translate('admin.anonymization.go.error.body'),
								title: Tools.$translate('admin.anonymization.go.error.title'),
								dialog: AnonFieldPage,
								selectedFields: _selectedFields,
								entities: rootData.pageData.entities,
								obligatoryFields: obligatoryFields,
								selectField: selectField,
								selectAllFields: selectAllFields,
								reactive: true,
								isModal: true
							};
							if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
								openModal('AnonFieldPage', {
									...modalOpts,
									onClose: response => {
										if (response?.type === 'gotFields' && response?.fields.length > 0) {
											openAlertConfirmModal(response.fields);
										}
									}
								});
							} else {
								// eslint-disable-next-line promise/catch-or-return
								Tools.$upModal
									.open('alert', {
										type: 'confirm',
										id: 'must-have-fields',
										body: Tools.$translate('admin.anonymization.go.error.body'),
										title: Tools.$translate('admin.anonymization.go.error.title'),
										dialog: AnonFieldPage,
										selectedFields: _selectedFields,
										entities: rootData.pageData.entities,
										obligatoryFields: obligatoryFields,
										selectField: selectField,
										selectAllFields: selectAllFields,
										reactive: true,
										isModal: true
									})
									.then(response => {
										if (response.type === 'gotFields' && response.fields.length > 0) {
											openAlertConfirmModal(response.fields);
										}
									});
							}
						}}
						className="header-button"
						disabled={selectedContactsCount === 0}
					>
						{selectedContactsCount === 1
							? Tools.$translate('admin.anonymization.list.actionButton.text_one', {
									count: selectedContactsCount
							  })
							: Tools.$translate('admin.anonymization.list.actionButton.text_other', {
									count: selectedContactsCount
							  })}
					</Button>

					<table>
						<thead>
							<AnonListRow
								checkCheckbox={everyThingSelected ? true : checkAllCheckbox}
								type="th"
								check={this.check}
								setSort={this.setSort}
								sorting={this.state.sorting}
								desc={this.state.desc}
								buttonTextNumber={buttonTextNumber}
								selectingAll={this.props.selectingAll}
							/>
						</thead>
						<tbody>{this.renderRows()}</tbody>
					</table>
				</div>
				{rootData.pageData.numContacts > 50 ? (
					<ReactTemplates.TOOLS.pagination
						itemsPerPage={50}
						totalItems={rootData.pageData.numContacts}
						onChange={function (str, page) {
							rootData.pageData.setPage(str, page);
						}}
						currentPage={rootData.pageData.currentPage}
						maxSize={8}
					/>
				) : null}
			</div>
		);
	}
}

AnonList.defaultProps = {
	fields: {
		selectedFields: []
	}
};

AnonList.propTypes = {
	rootData: PropTypes.shape({
		pageData: PropTypes.shape({
			currentPage: PropTypes.number
		})
	}),
	selectingAll: PropTypes.bool,
	selectedContacts: PropTypes.array,
	everyThingSelected: PropTypes.bool,
	contactSelected: PropTypes.func,
	selectedFields: PropTypes.array,
	obligatoryFields: PropTypes.array,
	selectField: PropTypes.func,
	selectAllFields: PropTypes.func,
	buttonTextNumber: PropTypes.any,
	deselectEverything: PropTypes.func
};
export default AnonList;

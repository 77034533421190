import { getLiveTags } from 'Store/actions/SocialEventActions';
import logError from 'App/babel/helpers/logError';
import FieldTranslation from 'App/babel/resources/FieldTranslations';

angular.module('upModals.controllers').controller('InsertTag', [
	'$scope',
	'TagService',
	'$modalParams',
	'TagsService',
	'SegmentHelper',
	'$safeApply',
	'RequestBuilder',
	function ($scope, TagService, $modalParams, TagsService, SegmentHelper, $safeApply, RequestBuilder) {
		var InsertTag = this;
		var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		InsertTag.tags = [];

		InsertTag.selectTag = function (tag) {
			SegmentHelper.track('Selected tag ' + tag.value, SegmentHelper.module.tagPicker);
			$scope.resolve(tag);
		};

		const getSocialEventTags = ({ isWebinar }) => {
			const socialEventTags = getLiveTags();
			socialEventTags['Mail.CanNotAttendLink'] = {
				name: Tools.$translate('socialEvent.canNotAttendLink'),
				value: `<a href="{{Mail.CanNotAttendLink}}" ng-non-bindable>${Tools.$translate(
					'socialEvent.canNotAttend'
				)}</a>`,
				html: true
			};
			socialEventTags['Mail.WebinarInviteLink'] = {
				name: isWebinar
					? Tools.$translate('socialEvent.webinar.link')
					: Tools.$translate('socialEvent.webinar.linkPlaceholder'),
				value: '<a href="{{Mail.WebinarInviteLink}}" ng-non-bindable>webinar</a>',
				html: true,
				isDisabled: !isWebinar
			};
			socialEventTags['Mail.OnDemandLink'] = {
				name: isWebinar
					? Tools.$translate('socialEvent.onDemandLink')
					: Tools.$translate('socialEvent.webinar.onDemandLinkPlaceholder'),
				value: `<a href="{{Mail.OnDemandLink}}" ng-non-bindable>${Tools.$translate(
					'socialEvent.onDemandLink'
				)}</a>`,
				html: true,
				isDisabled: !isWebinar
			};

			return {
				title: 'default.socialEvents',
				tags: Object.keys(socialEventTags).reduce((tags, key) => {
					const tag = socialEventTags[key];
					if (!tag.hidden) {
						tags.push(tag.html ? tag : { name: tag.name, value: `{{${key}}}` });
					}
					return tags;
				}, [])
			};
		};

		var init = function (languages, selectedLanguages) {
			if ($modalParams.tagEntity) {
				InsertTag.tagGroups = TagsService.getTagsByEntity2($modalParams.tagEntity);
			} else if ($modalParams.strictTagList) {
				InsertTag.tagGroups = $modalParams.strictTagList;
			} else {
				InsertTag.tagGroups = TagService.getMailTags(!!$modalParams.hideIdTags);
			}

			if ($modalParams.extraTagGroups) {
				InsertTag.tagGroups = InsertTag.tagGroups.concat($modalParams.extraTagGroups);
			}

			if ($modalParams.socialEvent) {
				InsertTag.tagGroups.push(getSocialEventTags($modalParams.socialEvent));
			}

			if (hasNewFields) {
				var contactGroupIndex = _.findIndex(InsertTag.tagGroups, { group: 'Contact' });

				if (contactGroupIndex > -1) {
					var contactGroup = InsertTag.tagGroups[contactGroupIndex];
					contactGroup.tags = _.filter(contactGroup.tags, function (tag) {
						return tag.value !== '{{Contact.SalutationValue}}';
					});

					var salutationTag = _.remove(contactGroup.tags, { value: '{{Contact.Salutation}}' });

					if (salutationTag.length) {
						_.each(InsertTag.tagGroups, function (tagGroup, index) {
							if (index >= contactGroupIndex + 2) {
								tagGroup.sort = tagGroup.sort + 1;
							}
						});

						var salutationGroup = {
							title: 'default.salutation',
							class: 'th-inner--translation',
							group: 'Contact',
							tags: [],
							sort: contactGroup.sort + 1
						};

						_.each(selectedLanguages, function (language) {
							var tag = '{{Translate_' + language + '_Contact.SalutationValue}}';
							var option = _.find(languages, { language: language });

							if (option) {
								var icon = option ? 'flag-icon flag-icon-' + option.country : '';
								salutationGroup.tags.push({
									value: tag,
									name: option.name,
									raw: false,
									group: 'Contact',
									icon: icon
								});
							}
						});

						InsertTag.tagGroups.splice(contactGroupIndex + 1, 0, salutationGroup);
					}
				}
			}

			InsertTag.originalTags = _.cloneDeep(InsertTag.tagGroups);

			$safeApply($scope);
		};

		InsertTag.searchUpdate = function () {
			if (InsertTag.search.length === 0) {
				InsertTag.tagGroups = InsertTag.originalTags;
				return;
			}

			InsertTag.tagGroups = _.reduce(
				InsertTag.originalTags,
				function (result, tagGroup) {
					var cloneGroup = _.cloneDeep(tagGroup);
					cloneGroup.tags = _.reduce(
						tagGroup.tags,
						function (result, tag) {
							if (cloneGroup.class && cloneGroup.class === 'th-inner--translation') {
								var cloneGroupTile = Tools.$translate(cloneGroup.title);
								var groupTileMatch =
									cloneGroupTile.toLowerCase().indexOf(InsertTag.search.toLowerCase()) !== -1;

								if (groupTileMatch) {
									result.push(tag);
								}
							} else if (tag.name.toLowerCase().indexOf(InsertTag.search.toLowerCase()) !== -1) {
								result.push(tag);
							}
							return result;
						},
						[]
					);

					if (cloneGroup.tags.length) {
						result.push(cloneGroup);
					}

					return result;
				},
				[]
			);
		};

		if (hasNewFields) {
			var rb = new RequestBuilder();
			rb.addFilter({ field: 'type' }, rb.comparisonTypes.Equals, 'salutation');

			Promise.all([Tools.AppService.getStaticValuesPromise('languages'), FieldTranslation.find(rb.build())])
				.then(function (res) {
					var languages = res[0] || [];
					var fieldTranslations = res[1] ? res[1].data : [];
					var selectedLanguages = _.unique(_.pluck(fieldTranslations, 'language'));

					init(languages, selectedLanguages);
				})
				.catch(e => logError(e, 'Failed to open insertTag modal'));
		} else {
			init();
		}
	}
]);

'use strict';

angular.module('services.dynamicBindings', []).service('$dynamicBindings', function () {
	var disableChildren = function (scope) {
		for (var childScope = scope.$$childHead; childScope; childScope = childScope.$$nextSibling) {
			childScope.$inactiveWatchers = childScope.$$watchers;
			childScope.$$watchers = [];
			disableChildren(childScope);
		}
	};

	var enableChildren = function (scope) {
		for (var childScope = scope.$$childHead; childScope; childScope = childScope.$$nextSibling) {
			childScope.$$watchers = childScope.$inactiveWatchers;
			childScope.$inactiveWatchers = [];
			enableChildren(childScope);
		}
	};

	// Service instance
	var Instance = {};

	Instance.off = function (scope, recursive) {
		// Discard if allready inactive (else we will loose all bindings)
		if (scope.$bindingsInactive) {
			return;
		}
		scope.$inactiveWatchers = scope.$$watchers;
		scope.$$watchers = [];
		scope.$bindingsInactive = true;

		if (recursive) {
			scope.$isRecursive = true;
			disableChildren(scope);
		} else {
			scope.$isRecursive = false;
		}
	};

	Instance.on = function (scope) {
		// Discard if not inactive (else we will loose all bindings)
		if (!scope.$bindingsInactive) {
			return;
		}
		scope.$$watchers = scope.$inactiveWatchers;
		scope.$inactiveWatchers = [];
		scope.$bindingsInactive = false;

		if (scope.$isRecursive) {
			enableChildren(scope);
		}
	};

	return Instance;
});

import Resource from './Resource';
import Attributes from 'Attributes/QuotaAttributes';
import { find } from 'lodash';

class Quota extends Resource {
	eventName: string;

	constructor() {
		super('quota', Attributes);
		this.eventName = 'quota';
	}

	new() {
		const masterCurrency = find(Tools.AppService.getMetadata().customerCurrencies, 'masterCurrency');
		const quota = {
			user: null,
			value: 0,
			month: new Date().getUTCMonth(),
			currencyRate: 1,
			currency: masterCurrency?.iso,
			year: new Date().getUTCFullYear(),
			date: new Date()
		};

		return quota;
	}
}

const resource = new Quota();

export default resource;

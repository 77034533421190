import React from 'react';
import { Button } from '@upsales/components';
import UpsalesSupportCard from 'Components/UpsalesSupportCard/UpsalesSupportCard';

ReactTemplates.admin.dashboard = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			licences: t('admin.licences'),
			questionsAboutUpsales: t('admin.questionsAboutUpsales'),
			pleaseContactMe: t('admin.pleaseContactMe'),
			shortcuts: t('admin.shortcuts'),
			createNewUser: t('admin.createNewUser'),
			createNewUserInfo: t('admin.createNewUserInfo'),
			addExtraFieldToCompany: t('admin.addExtraFieldToCompany'),
			addExtraFieldToCompanyInfo: t('admin.addExtraFieldToCompanyInfo'),
			setPointsToPages: t('admin.setPointsToPages'),
			setPointsToPagesInfo: t('admin.setPointsToPagesInfo'),
			createNewOppStage: t('admin.createNewOppStage'),
			createNewOppStageInfo: t('admin.createNewOppStageInfo'),
			areUMissingActivityTypes: t('admin.areUMissingActivityTypes'),
			areUMissingActivityTypesInfo: t('admin.areUMissingActivityTypesInfo'),
			searchSettings: t('admin.searchSettings'),
			upsalesApps: t('admin.upsalesApps'),
			appsInfo: t('admin.useAppsWithUpsales'),
			seeAllApps: t('admin.seeAllApps'),
			inviteCollegue: t('admin.inviteCollegue'),
			sendInvitesToCollegue: t('admin.sendInvitesToCollegue'),
			releaseNotesTitle: t('admin.dashboardWidget.releaseNotesTitle'),
			releaseNotesSubTitle: t('admin.dashboardWidget.releaseNotesSubTitle'),
			releaseNotes: t('default.releaseNotes'),
			upsalesCourses: t('admin.upsalesCourses'),
			upsalesCoursesInfo: t('admin.upsalesCoursesInfo'),
			showAll: t('default.showAll'),
			useUpsalesToTheFullest: t('admin.useUpsalesToTheFullest'),
			onboardingLeft: t('admin.onboardingLeft'),
			nextStep: t('onboarding.nextStep'),
			moreCoursesLink: t('onboarding.courses.moreLink'),
			with: t('default.with').toLowerCase(),
			customer: t('salesboard.customer')
		};
	},

	onSearch: function (e) {
		this.props.rootData.sidebarSearch(e.target.value);
	},

	inviteCollegue: function () {
		Tools.$state.go('administration.user');
	},

	getLinkToCourses: function (accountSelf) {
		if (accountSelf.language === 'sv-SE') {
			return 'https://upsales.com/sv/kunskapsbank/produktutbildning';
		}
		return 'https://www.upsales.com/learning-center/product-training';
	},

	runOnboardingAction: function (step) {
		Tools.Onboarding.stepAction[step]();
	},

	renderShortcut: function (title, subtitle, state, stateParams) {
		return (
			<div className="shortcut" key={title + '-' + state}>
				<a href={Tools.$state.href(state, stateParams)}>{title}</a>
				<span>{subtitle}</span>
			</div>
		);
	},

	render: function () {
		var rootData = this.props.rootData;
		var accountSelf = rootData.pageData.accountSelf;
		var profile = rootData.pageData.profile;
		var isAdmin = rootData.pageData.self.administrator;
		var unfinishedOnboarding = rootData.pageData.unfinishedOnboarding;
		var onboardingStyle = rootData.pageData.onboardingTrack
			? { width: (rootData.pageData.onboardingTrack.done / rootData.pageData.onboardingTrack.total) * 100 + '%' }
			: null;
		const canInvite = isAdmin;

		return (
			<div id="admin-page-dashboard">
				<div className="admin-page-header">
					<div className="header-overlay"></div>
					<div id="dashboard-header-info">
						{rootData.pageData.hasProfile ? (
							<div
								className="dashboard-header-logo"
								style={{ backgroundImage: 'url(' + profile.logo + ')' }}
							></div>
						) : (
							<h3 className="dashboard-header-name">{accountSelf.client.name}</h3>
						)}

						<span className="license-count">
							{'Upsales '}
							<b>{accountSelf.version}</b>
							{' ' + this.lang.with + ' '}
							{accountSelf.client.numberOfLicenses} {this.lang.licences}
							<p>{`${this.lang.customer} ID: ${accountSelf.client.id}`}</p>
						</span>

						<div className="dashboard-header-search" value={''}>
							<b className="fa fa-search" />
							<input defaultValue="" onChange={this.onSearch} placeholder={this.lang.searchSettings} />
						</div>
					</div>
				</div>

				<div id="admin-content">
					<div className="dashboard-content-inner">
						<div className="dashboard-content-right">
							<UpsalesSupportCard className="dashboard-widget" />

							{unfinishedOnboarding ? (
								<div className="dashboard-widget centered">
									<div className="widget-title">{this.lang.useUpsalesToTheFullest}</div>
									<div className="widget-content">
										{this.lang.onboardingLeft}
										<div className="progress">
											<div className="progress-bar bright-blue" style={onboardingStyle}></div>
										</div>
									</div>
									<button
										className="widget-btn next-onboarding-btn"
										onClick={this.runOnboardingAction.bind(
											null,
											rootData.pageData.onboardingTrack.next
										)}
									>
										<span className="btn-title">{this.lang.nextStep}</span>
										{Tools.Onboarding.stepMap[rootData.pageData.onboardingTrack.next].title}
										<b className="fa fa-angle-right" />
									</button>
								</div>
							) : null}
							{canInvite ? (
								<div className="dashboard-widget centered">
									<img className="widget-img" src="img/collegues.svg" />
									<div className="widget-title">{this.lang.inviteCollegue}</div>
									<div className="widget-content">
										<span className="faded-white">{this.lang.sendInvitesToCollegue}</span>
									</div>
									<button className="widget-btn" onClick={this.inviteCollegue}>
										{this.lang.inviteCollegue}
									</button>
								</div>
							) : null}
							<div className="dashboard-widget centered">
								<div className="widget-icon">
									<b className="fa fa-gift" />
								</div>
								<div className="widget-title">{this.lang.releaseNotesTitle}</div>
								<div className="widget-content">
									<span className="faded-white">{this.lang.releaseNotesSubTitle}</span>
								</div>
								<a href={'https://upsales.releasenotes.io/'} target="_blank" className="widget-btn">
									{this.lang.releaseNotes}
								</a>
							</div>
						</div>
						<div className="dashboard-content-left">
							<div className="dashboard-widget">
								<img className="widget-title-img" src="img/book.svg" />
								<div className="widget-title">
									{this.lang.upsalesCourses}
									<div className="widget-title-subtitle">{this.lang.upsalesCoursesInfo}</div>
								</div>
								<a
									href={this.getLinkToCourses(accountSelf)}
									target="_blank"
									className="widget-btn"
									id="docebo-btn"
								>
									{this.lang.moreCoursesLink}
								</a>
							</div>
							<div
								className="dashboard-widget widget-halfnhalf"
								style={{ backgroundImage: 'url(img/apps.svg)' }}
							>
								<div className="widget-title">
									<b className="fa fa-cloud" />
									{this.lang.upsalesApps}
								</div>
								<div className="widget-content app-widget-content">
									<div>{this.lang.appsInfo}</div>
									<Button
										shadow="none"
										type="lined"
										onClick={function () {
											Tools.$state.go('administration.integrations');
										}}
									>
										{this.lang.seeAllApps}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Flex, Icon } from '@upsales/components';
import './AlertIcon.scss';
import IconName from '@upsales/components/Icon/IconName';

const AlertIcon = ({ icon }: { icon: IconName }) => {
	const classes = new bemClass('AlertIcon');

	return (
		<Flex alignItems="center" className={classes.b()}>
			<Flex alignItems="center" justifyContent="center" className={classes.elem('icon-background').b()}>
				<Icon name={icon} color="black"></Icon>
			</Flex>
		</Flex>
	);
};

export default AlertIcon;

import User from 'App/resources/Model/User';

export const getInitials = (user?: Pick<Partial<User>, 'name'>) => {
	if (!user || !user.name) {
		return '';
	}
	let i1 = '',
		i2 = '',
		nameArray = [];
	if (user.name) {
		i1 = [...user.name.toString()][0];
		nameArray = user.name.split(' ');
		if (nameArray.length > 2) {
			i2 = [...nameArray[nameArray.length - 1]][0];
		} else if (nameArray.length === 2) {
			i2 = [...nameArray[1]][0];
		}
	}
	return (i1 || '') + (i2 || '');
};

'use strict';

ReactTemplates.upFilters.components.tabPage = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			activeTabIndex: 0
		};
	},
	getInitialState: function () {
		return {
			activeTabIndex: this.props.activeTabIndex
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;

		this.lang = {
			back: t('default.back')
		};
	},
	componentDidMount: function () {},
	onGoBack: function () {
		this.props.onPageChange(null, true);
	},
	changeTab: function (index) {
		this.setState({
			activeTabIndex: index
		});
	},
	render: function () {
		var self = this;
		var props = this.props;
		var headerExtension = props.headerExtension;

		var headerExtensionComponent = null;

		if (headerExtension) {
			var headerExtensionProps =
				typeof headerExtension.props === 'function' ? headerExtension.props(self) : headerExtension.props;

			headerExtensionComponent = (
				<div className="header-extension">
					{React.createElement(headerExtension.component, headerExtensionProps)}
				</div>
			);
		}

		var tabComponent = null;

		if (props.tabs) {
			var tabs = props.tabs.map(function (tabConfig, index) {
				var className =
					'header-tab light-grey' + (self.state.activeTabIndex === index ? ' header-tab-active' : '');
				var onClick = self.changeTab.bind(self, index);

				return (
					<div
						className={className}
						onClick={onClick}
						key={index}
						data-test-id={'listfilters-tabpage-tab-' + index}
					>
						<i className={tabConfig.icon}></i>
					</div>
				);
			});
			tabComponent = <div className="header-tab-wrap">{tabs}</div>;
		}

		var activeTab = props.tabs[this.state.activeTabIndex];
		var content = null;

		if (activeTab) {
			var activeTabProps = typeof activeTab.props === 'function' ? activeTab.props(self) : activeTab.props;
			content = React.createElement(activeTab.component, activeTabProps);
		}

		return (
			<div className="list-filter-page scroll-auto light-grey" key={props.filterName}>
				<div className="list-page">
					<div className="header no-shadow">
						<button className="back-button back-button-text" onClick={this.onGoBack}>
							<b className="fa fa-chevron-left"></b>
							{this.lang.back}
						</button>
						{headerExtensionComponent}
						{tabComponent}
					</div>
					<div>{content}</div>
				</div>
			</div>
		);
	}
});

import colorMappings from '@upsales/components/Utils/colorMappings';

export const getTextColor = hex => {
	hex = hex.substring(1);
	// We always want 6 digit hex
	if (hex.length === 3) {
		const r = hex[0];
		const g = hex[1];
		const b = hex[2];
		hex = r + r + g + g + b + b;
	}

	var rgb = parseInt(hex, 16); // convert rrggbb to decimal
	var r = (rgb >> 16) & 0xff; // extract red
	var g = (rgb >> 8) & 0xff; // extract green
	var b = (rgb >> 0) & 0xff; // extract blue

	var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
	if (luma < 150) {
		return colorMappings.get('white');
	}

	return colorMappings.get('black');
};

window.getTextColor = getTextColor;

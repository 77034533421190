'use strict';

angular.module('upResources').service('ParseStaticValues', [
	'$translate',
	function ($translate) {
		var skipTranslate = ['naicsCode'];

		var translateNames = function (options) {
			options = _.map(options, function (opt) {
				opt.name = $translate.instant(opt.name);
				return opt;
			});
			return options;
		};

		return function (staticValues) {
			if (Array.isArray(staticValues)) {
				return translateNames(staticValues);
			}

			var valuesMap = {};

			_.each(Object.keys(staticValues), function (type) {
				if (skipTranslate.indexOf(type) !== -1) {
					valuesMap[type] = staticValues[type];
				} else {
					valuesMap[type] = translateNames(staticValues[type]);
				}
			});

			return valuesMap;
		};
	}
]);

'use strict';
import ComparisonTypes from 'Resources/ComparisonTypes';

angular.module('upResources').factory('RequestBuilder', [
	'FilterType',
	'EntityType',
	'$injector',
	function (FilterType, EntityType, $injector) {
		var AggregationTypes = {
			Terms: 'terms',
			Sum: 'sum',
			Stats: 'stats',
			Missing: 'missing',
			Exists: 'exists',
			Range: 'range',
			Cardinality: 'cardinality'
		};

		var AggregationIntervals = {
			DAY: 'day',
			WEEK: 'week',
			MONTH: 'month',
			QUARTER: 'quarter',
			YEAR: 'year'
		};

		var checkValue = function (valueType) {
			try {
				switch (valueType) {
					case FilterType.Boolean:
						break;
					case FilterType.Date:
						break;
					case FilterType.Email:
						break;
					case FilterType.Float:
						break;
					case FilterType.Number:
						break;
					case FilterType.String:
						break;
				}
				return null;
			} catch (notValid) {
				return notValid;
			}
		};

		var orBuilder = function (parent, parentInstance) {
			var array = [];
			var index = -1;
			var instance = {};
			instance.comparisonTypes = ComparisonTypes;

			//Adds new or stmt and returns index of stmt
			instance.next = function () {
				array.push([]);
				index++;
			};

			//Adds a new filter in or stmt with given index
			instance.addFilter = function (attribute, comparison, value) {
				testValues(value, attribute);
				value = window.Tools.mapDate(value);

				array[index].push({ a: attribute.field, c: comparison, v: value });
			};

			//Returns parsed query object
			instance.getQuery = function () {
				var query = { or: { q: array } };
				return query;
			};

			instance.done = function () {
				parent.push(instance.getQuery());
			};

			instance.orBuilder = function () {
				return orBuilder(array[index], instance);
			};

			instance.groupBuilder = function () {
				return groupBuilder(array[index], instance);
			};

			instance.addExtraParam = function (key, value) {
				parentInstance.addExtraParam(key, value);
				return instance;
			};

			return instance;
		};

		function groupBuilder(parent, parentInstance) {
			var array = [];
			var instance = {};
			var isNotFilter = false;
			instance.comparisonTypes = ComparisonTypes;

			// Adds a new filter to group
			instance.addFilter = function (attribute, comparison, value) {
				testValues(value, attribute);
				value = window.Tools.mapDate(value);

				array.push({ a: attribute.field, c: comparison, v: value });
			};

			//Returns parsed query object
			instance.getQuery = function () {
				var query = { group: { not: isNotFilter, q: [array] } };
				return query;
			};

			instance.done = function () {
				parent.push(instance.getQuery());
			};

			instance.isNotFilter = function (val) {
				isNotFilter = val !== undefined ? val : true;
			};

			instance.findFilter = function (key) {
				var filter = _.find(self.queryArray, { a: key });
				return filter;
			};

			instance.groupBuilder = function () {
				return groupBuilder(array, instance);
			};

			instance.orBuilder = function () {
				return orBuilder(array, instance);
			};

			instance.addExtraParam = function (key, value) {
				parentInstance.addExtraParam(key, value);
				return instance;
			};

			return instance;
		}

		// sort is optional
		var aggregation = function (parent) {
			var subAggsArr = [];
			var aggObj = { type: null, field: null }; // use this later to build nested aggregations
			var instance = {};

			// Adds a new filter to group
			instance.addAggregation = function (type, field, sort) {
				if (typeof field === 'object' && field.field) {
					field = field.field;
				}

				// Add this aggregation
				aggObj.type = type;
				aggObj.field = field;

				if (sort) {
					aggObj.sort = sort;
				}
			};

			instance.aggregationInterval = function (interval) {
				aggObj.interval = interval;
			};

			instance.aggregationSize = function (size) {
				aggObj.size = size;
			};

			instance.aggregationName = function (name) {
				aggObj.aggName = name;
			};

			instance.aggregationOrder = function (attribute, ascending) {
				aggObj.order = { value: attribute, sort: ascending ? 'asc' : 'desc' };
			};

			instance.addFilter = function (attribute, comparison, value) {
				if (value === undefined) {
					return false;
				}

				testValues(value, attribute);
				value = window.Tools.mapDate(value);

				const filter = { a: attribute.field, c: comparison, v: value };

				if (aggObj.filter) {
					aggObj.filter.push(filter);
				} else {
					aggObj.filter = [filter];
				}
			};

			instance.done = function () {
				if (subAggsArr.length) {
					aggObj.aggs = subAggsArr;
				}
				parent.push(aggObj);
			};

			instance.aggregationBuilder = function () {
				return aggregation(subAggsArr);
			};

			return instance;
		};

		function testValues(value, attribute) {
			var testValues = value;

			if (!Array.isArray(value)) {
				testValues = [value];
			}
			testValues.forEach(function (arrayValue) {
				var error = checkValue(attribute.type, arrayValue);
				if (error) {
					throw Error(error + ', value:' + value);
				}
			});
		}

		var instance = function (entityType) {
			var entity = null;
			entityType = EntityType.findByString(entityType);
			if (entityType) {
				entity = $injector.get(entityType);
			}

			var self = {};

			self.groupFilter = function () {
				// return filter
			};

			self.addFilterGroup = function (filters) {
				var obj = {
					or: filters
				};

				self.queryArray.push(obj);
				return self;
			};

			self.removeFilter = function (key) {
				self.queryArray = _.reject(self.queryArray, { a: key });
				return self;
			};

			self.findDeleteFilter = function (key) {
				var filter = _.find(self.queryArray, { a: key });
				self.queryArray = _.reject(self.queryArray, { a: key });
				return filter;
			};

			self.findFilter = function (key) {
				var filter = _.find(self.queryArray, { a: key });
				return filter;
			};

			self.addFilter = function (attribute, comparison, value) {
				if (value === undefined) {
					return false;
				}

				testValues(value, attribute);
				value = window.Tools.mapDate(value);

				self.queryArray.push({ a: attribute.field, c: comparison, v: value });

				return self;
			};

			//A helper to build or-filters. Uses index in its addFilter function to add filters in
			self.orBuilder = function () {
				return orBuilder(self.queryArray, self);
			};

			self.groupBuilder = function () {
				return groupBuilder(self.queryArray, self);
			};

			self.addSort = function (attribute, ascending) {
				if (typeof attribute === 'object') {
					attribute = attribute.field;
				}
				self.sorting.push({ attribute: attribute, ascending: ascending });
				return self;
			};

			// Aggregation only works for some api endpoints
			self.aggregationBuilder = function () {
				return aggregation(self.aggsArray);
			};

			self.addExtraParam = function (key, value) {
				self.extraParams.push({ key: key, value: JSON.stringify(value) });
				return self;
			};

			self.limit = null;
			self.offset = null;
			self.sorting = [];
			self.fields = [];
			self.comparisonTypes = ComparisonTypes;
			self.aggregationTypes = AggregationTypes;
			self.aggregationIntervals = AggregationIntervals;
			self.queryArray = [];
			self.aggsArray = [];
			self.extraParams = [];

			self.clone = function () {
				const copy = instance(entityType);
				for (const [key, value] of Object.entries(self)) {
					if (typeof value !== 'function') {
						copy[key] = _.cloneDeep(value);
					}
				}
				return copy;
			};

			self.build = function () {
				var request = { q: [] };

				if (self.queryArray.length) {
					request.q = self.queryArray;
				}

				if (self.aggsArray.length) {
					request.aggs = self.aggsArray;
				}

				if (_.isFinite(self.limit)) {
					request.limit = self.limit;
				}

				if (self.sorting.length) {
					var sorting = [];
					for (var i = 0; i < self.sorting.length; i++) {
						var sort = self.sorting[i];
						var a = sort.attribute;
						var s = sort.ascending ? 'A' : 'Z';
						sorting.push({ a: a, s: s });
					}
					request.sort = sorting;
				}

				if (self.offset) {
					request.offset = self.offset;
				}

				if (Array.isArray(self.fields) && self.fields.length) {
					// this needs to be a copy
					request.f = [].concat(self.fields);
					if (entity && entity.requiredFields && entity.requiredFields.length) {
						request.f = _.union(request.f, entity.requiredFields);
					}
				}

				if (Array.isArray(self.extraParams) && self.extraParams.length) {
					self.extraParams.forEach(function (extraParam) {
						request[extraParam.key] = extraParam.value;
					});
				}

				return request;
			};

			return self;
		};

		return instance;
	}
]);

'use strict';

angular.module('upAttributes').service('ApiKeyAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				key: {
					title: 'admin.apiKey',
					field: 'key',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				active: {
					title: 'default.active',
					field: 'active',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean
				}
			};

			var standardFilters = {};

			var requiredFields = [];

			return {
				getAll: function () {
					return attributes;
				},
				standardFilters: standardFilters,
				attr: attributes,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					NAME: 'name',
					KEY: 'key',
					ACTIVE: 'active'
				}
			};
		};
	}
]);

import MailBounceLabel from 'App/babel/components/Misc/MailBounceLabel';
import { Card, Row, Column, Text, Title, Button, Icon, Tooltip, Headline } from '@upsales/components';
import { checkUnbounceContact, NOT_ABLE_TO_DEBOUNCE_REASONS } from 'App/babel/helpers/bounce';

ReactTemplates.mailCampaign.recipients = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			noRecipients: t('mail.noRecipients'),
			history: t('default.history'),
			submitedForm: t('event.form.submited'),
			noEvents: t('mail.noEvents'),
			bounce: t('event.bounce'),
			openedTheMail: t('mailCampaign.openedTheMail').toLowerCase(),
			clickedInMail: t('mail.clickedInMail').toLowerCase(),
			unsub: t('default.unsubscribedFrom').toLowerCase(),
			unsubAll: t('mail.mailUnsubscribed').toLowerCase(),
			notDelivered: t('mail.notDelivered'),
			missingEmail: t('mail.missingEmail'),
			invalidEmail: t('mail.invalidEmail'),
			unverifiedSender: t('mail.unverifiedSender'),
			prevBounce: t('event.prevBounce'),
			duplicate: t('default.thisIsADuplicate'),
			visit: t('visitor.didVisited'),
			pages: t('visitor.pages').toLowerCase(),
			page: t('visitor.page').toLowerCase(),
			didNotClick: t('mailCampaign.didNotClick'),
			didNotRead: t('mailCampaign.didNotRead'),
			composeEmail: t('mailCampaign.composeEmail'),
			maximizeImpact: t('mailCampaign.maximizeImpact'),
			maximizeImpactInfo: t('mailCampaign.maximizeImpactInfo'),
			at: t('default.at').toLowerCase()
		};

		this.columns = [
			{ title: t('default.contact'), sortable: 'contact.name' },
			{ title: t('default.email'), sortable: 'to' },
			{ title: t('default.history'), width: 100, elevioId: '251' },
			{ title: t('mail.action'), elevioId: '264' },
			{ title: '', width: 215 }
		];

		this.customerId = this.props.tools.AppService.getCustomerId();
		this.hasBounceReason = Tools.FeatureHelper.hasSoftDeployAccess('BOUNCE_REASONS');
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	getStateHref: function (name, params) {
		return this.props.tools.$state.href(name, params);
	},

	renderDrilldown: function (mail, i) {
		var self = this;
		var loader = null;
		var content = null;

		if (this.props.rootData.pageData.drilldownLoading) {
			loader = React.createElement(ReactTemplates.loader, {});
		} else {
			if (this.props.rootData.pageData.drilldownData.length) {
				content = (
					<div>
						<h3>{this.lang.history}</h3>

						<table>
							<tbody>
								{_.map(this.props.rootData.pageData.drilldownData, function (event, i) {
									var icon = null;
									var content = null;
									switch (event.type) {
										case 'formsubmit':
											icon = <b className="fa fa-wpforms" />;
											var formName = '';
											var formHref = null;
											if (event.formSubmit && event.formSubmit.form && event.formSubmit.form) {
												formName = event.formSubmit.form.title || '';
												formHref = event.formSubmit.form
													? `#/form-overview/${event.formSubmit.form.id}`
													: null;
											}
											content = (
												<span>
													{self.lang.submitedForm} <a href={formHref}>{formName}</a>
												</span>
											);
											break;
										case 'visit':
											icon = <b className="fa fa-globe" />;
											content = (
												<span>
													{self.lang.visit} {event.visit.pageCount}{' '}
													{event.visit.pageCount > 1 ? self.lang.pages : self.lang.page}{' '}
													{self.lang.at} {event.visit.domain}
												</span>
											);
											break;
										case 'open':
										case 'click':
										case 'unsub':
										case 'previous_unsubscribed':
											var contactLink = (
												<a
													href={self.getStateHref('contact.dashboard', {
														id: event.contact.id,
														customerId: self.customerId
													})}
												>
													{event.contact.name}
												</a>
											);

											if (event.type === 'open') {
												icon = <b className="fa fa-eye" />;
												content = (
													<span>
														{contactLink} {self.lang.openedTheMail}
													</span>
												);
											} else if (
												event.type === 'unsub' ||
												event.type === 'previous_unsubscribed'
											) {
												icon = <b className="fa fa-minus-circle text-red-i" />;
												content = (
													<span>
														{contactLink}{' '}
														{event.mail.groupMailCategoryTitle
															? self.lang.unsub + ' ' + event.mail.groupMailCategoryTitle
															: self.lang.unsubAll}
													</span>
												);
											} else {
												icon = <b className="fa fa-mouse-pointer" />;
												content = (
													<div style={{ lineHeight: '14px' }}>
														<span>
															{contactLink} {self.lang.clickedInMail}
														</span>
														<br />
														<span style={{ color: '#888' }}>{event.mail.link}</span>
													</div>
												);
											}
											break;
										case 'hard_bounce':
										case 'soft_bounce': {
											const bounceReason = event.mail?.bounceReason;
											icon = <b className="fa fa-times" />;
											content = (
												<span>{`${self.lang.bounce} ${
													bounceReason && self.hasBounceReason ? `(${bounceReason})` : ''
												}`}</span>
											);
											break;
										}
										case 'missing_email':
										case 'invalid_email':
										case 'previous_bounce':
										case 'duplicate_email':
											var reason = null;
											if (event.type === 'missing_email') {
												reason = self.lang.missingEmail;
											} else if (event.type === 'invalid_email') {
												reason = self.lang.invalidEmail;
											} else if (event.type === 'previous_bounce') {
												reason = self.lang.prevBounce;
											} else if (event.type === 'duplicate_email') {
												reason = self.lang.duplicate;
											}
											icon = <b className="fa fa-times" />;
											content = (
												<span>
													{self.lang.notDelivered}
													{' ('}
													{reason}
													{')'}
												</span>
											);
											break;
										case 'unverified_sender': {
											icon = <b className="fa fa-times" />;
											content = (
												<span>{`${self.lang.unverifiedSender} ${
													mail.from ? `(${mail.from})` : ''
												}`}</span>
											);
											break;
										}
										case 'unknown': {
											icon = <b className="fa fa-times" />;
											content = (
												<span>{`${self.lang.unknownError} ${
													mail.from ? `(${mail.from})` : ''
												}`}</span>
											);
											break;
										}
										default:
											content = <span>{event.type}</span>;
									}
									return (
										<tr key={'dd-data-' + i}>
											<td style={{ width: '50px', textAlign: 'center' }}>{icon}</td>
											<td
												style={{
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis'
												}}
											>
												{content}
											</td>
											<td style={{ textAlign: 'right', width: '120px' }}>
												{moment(event.date).format('L LT')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				);
			} else {
				content = (
					<h3 className="text-grey" style={{ textAlign: 'center' }}>
						{this.lang.noEvents}
					</h3>
				);
			}
		}
		return (
			<tr key={'drilldown-' + i}>
				<td colSpan={6} className="drilldown-td">
					{loader}
					{content}
				</td>
			</tr>
		);
	},

	onDrilldown: function (mail, openDrilldown) {
		// Open drilldown
		openDrilldown();

		// Get drilldown data
		this.props.rootData.pageData.onDrilldown(mail);
	},

	composeNewBtnCol: function (number, title, onClick) {
		return (
			<Column fixedWidth={160} className="send-to-section__btns">
				<Headline size="sm">{number}</Headline>
				<Text>{title}</Text>
				<Button type="link" onClick={onClick} color="bright-blue">
					{this.lang.composeEmail}
				</Button>
			</Column>
		);
	},
	getLinks: function () {
		var links = [];
		var urls = this.props.rootData.clicks;
		if (urls && urls.pages) {
			urls.pages.forEach(function (item, key) {
				var link = { id: key, name: item.value };
				links.push(link);
			});
		}
		return links;
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;

		var search = React.createElement(ReactTemplates.mailCampaign.tableSearch, {
			className: 'pull-right',
			onChange: rootData.pageData.onSearch,
			tools: tools
		});

		var table = React.createElement(ReactTemplates.mailCampaign.table, {
			rowComponent: ReactTemplates.mailCampaign.mailRow,
			columns: this.columns,
			rows: pageData.mail,
			loading: pageData.loading,
			total: pageData.total,
			multiActions: true,
			totalPages: pageData.totalPages,
			activePage: pageData.activePage,
			onChangePage: pageData.onChangePage,
			renderDrilldown: self.renderDrilldown,
			currentSorting: pageData.currentSorting,
			onSort: pageData.onSort,
			noResult: self.lang.noRecipients,
			onDrilldown: self.onDrilldown,
			dataTimestamp: pageData.dataTimestamp,
			rowTools: {
				onInactivateContact: rootData.pageData.onInactivateContact,
				onEditContact: rootData.pageData.onEditContact,
				onUnBounceContact: rootData.pageData.onUnBounceContact
			},
			currentlyUnBouncing: pageData.currentlyUnBouncing,
			tools: tools
		});

		var filters = React.createElement(ReactTemplates.mailCampaign.recipientFilters, {
			filters: pageData.filters,
			onFilterChange: this.props.rootData.pageData.onFilterChange,
			tools: tools,
			undelivered: rootData.undelivered,
			numRetryableErrors: rootData.numRetryableErrors,
			retryingErrors: rootData.mailCampaign.retryingErrors,
			onRetryErrors: rootData.onRetryErrors,
			delivered: rootData.recipients,
			onChangeUrl: pageData.onChangeUrl,
			clickedUrls: this.getLinks(),
			selectedLink: pageData.selectedLink
		});

		var sendToSection;
		if (rootData.stats) {
			var noClick = Math.max(0, rootData.stats.send - rootData.stats.click);
			var noOpen = Math.max(0, rootData.stats.send - rootData.stats.open);
			if (noClick || noOpen) {
				sendToSection = (
					<Card className="send-to-section">
						<Row>
							<Column className="send-to-section__info">
								<Title>{this.lang.maximizeImpact}</Title>
								<Text color="grey-10">{this.lang.maximizeImpactInfo}</Text>
							</Column>
							{this.composeNewBtnCol(noOpen, this.lang.didNotRead, pageData.sendToNonOpeners)}
							{this.composeNewBtnCol(noClick, this.lang.didNotClick, pageData.sendToNonClickers)}
						</Row>
					</Card>
				);
			}
		}
		return (
			<div>
				{sendToSection}
				<div id="page-tools">
					{search}
					<div className="clearfix" />
					{filters}
				</div>

				{table}
			</div>
		);
	}
});

ReactTemplates.mailCampaign.mailRow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			rejected: t('event.reject'),
			notDelivered: t('mail.notDelivered'),
			bounce: t('event.bounce'),
			inactivate: t('integration.inactivate'),
			prevUnsubscribed: t('mail.prevUnsubscribed'),
			prevBounce: t('event.prevBounce'),
			invalidEmail: t('mail.invalidEmail'),
			didUnsub: t('mail.didUnsub'),
			inactive: t('default.inactive'),
			duplicate: t('default.thisIsADuplicate'),
			missingEmail: t('mail.missingEmail'),
			inactiveContact: t('mail.inactiveContact'),
			unverifiedSender: t('mail.unverifiedSender'),
			unknownError: t('mail.status.ERROR_UNKNOWN'),
			unbounce: t('email.unbounce'),
			alreadyRemoved: t('email.unbounce.alreadyRemoved'),
			maxNumberRemovalsReached: t('email.unbounce.maxNumberRemovalsReached')
		};

		this.customerId = this.props.tools.AppService.getCustomerId();
		this.hasBounceReason = Tools.FeatureHelper.hasSoftDeployAccess('BOUNCE_REASONS');
	},

	getErrorMsg: function (mail) {
		switch (mail.errorCause) {
			case 'reject':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.rejected} {React.createElement(ReactTemplates.elevio, { articleId: 270 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'soft_bounce':
			case 'hard_bounce': {
				const event = mail.events?.find(event => event.type === mail.errorCause);

				return (
					<span className="delivery-status">
						{event && this.hasBounceReason ? (
							<MailBounceLabel type={mail.errorCause} reason={event.value} />
						) : (
							<>
								<span>
									<span className="text-orange-i">{this.lang.bounce}</span>{' '}
									{React.createElement(ReactTemplates.elevio, { articleId: 278 })}
								</span>
								<span className="subtitle">
									{mail.errorCause === 'soft_bounce' ? 'Soft bounce' : 'Hard bounce'}
								</span>
							</>
						)}
					</span>
				);
			}

			case 'previous_bounce':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.prevBounce} {React.createElement(ReactTemplates.elevio, { articleId: 268 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'previous_unsubscribed':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.prevUnsubscribed}{' '}
							{React.createElement(ReactTemplates.elevio, { articleId: 265 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'invalid_email':
				return (
					<span className="delivery-status">
						<span>
							<span className="text-orange-i">{this.lang.invalidEmail}</span>{' '}
							{React.createElement(ReactTemplates.elevio, { articleId: 267 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'duplicate_email':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.duplicate} {React.createElement(ReactTemplates.elevio, { articleId: 266 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'missing_email':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.missingEmail} {React.createElement(ReactTemplates.elevio, { articleId: 269 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'not_active':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.inactiveContact} {React.createElement(ReactTemplates.elevio, { articleId: 271 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);

			case 'unverified_sender':
				return (
					<span className="delivery-status">
						<span>
							{this.lang.unverifiedSender}{' '}
							{React.createElement(ReactTemplates.elevio, { articleId: 874 })}
						</span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);
			case 'unknown':
				return (
					<span className="delivery-status">
						<span>{this.lang.unknownError} </span>
						<span className="subtitle">{this.lang.notDelivered}</span>
					</span>
				);
			default:
		}
	},

	onInactivateContact: function (e) {
		e.stopPropagation();
		this.props.rowTools.onInactivateContact(this.props.item);
	},

	onUnBounceContact: function (e) {
		e.stopPropagation();
		this.props.rowTools.onUnBounceContact(this.props.item);
	},

	onEditContact: function (e) {
		e.stopPropagation();
		this.props.rowTools.onEditContact(this.props.item);
	},

	render: function () {
		var mail = this.props.item;
		var i = this.props.index;
		var isDrilled = this.props.isDrilled;

		var tools = this.props.tools;
		var clientStatus;

		if (mail.client) {
			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={mail.client.hasActivity}
					activityOld={mail.client.hadActivity}
					appointment={mail.client.hasAppointment}
					appointmentOld={mail.client.hadAppointment}
					opportunity={mail.client.hasOpportunity}
					opportunityOld={mail.client.hadOpportunity}
					order={mail.client.hasOrder}
					orderOld={mail.client.hadOrder}
				/>
			);
		} else {
			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={false}
					activityOld={false}
					appointment={false}
					appointmentOld={false}
					opportunity={false}
					opportunityOld={false}
					order={false}
					orderOld={false}
				/>
			);
		}

		var maStatus = null;
		var errorMsg = null;
		var rowTools = [];
		var unsubIcon = null;
		var invalidEmail = false;

		if (mail.errorCause) {
			errorMsg = this.getErrorMsg(mail);

			if (mail.errorCause.indexOf('_bounce') !== -1) {
				const active = mail?.contact?.active ?? false;

				rowTools.push(
					<Button
						key="inactivate"
						color="super-light-green"
						onClick={this.onInactivateContact}
						disabled={!active}
						size="xs"
						text={active ? this.lang.inactivate : this.lang.inactive}
						shadow="none"
					/>
				);

				if (['previous_bounce', 'hard_bounce'].includes(mail.errorCause)) {
					const { valid, reason } = checkUnbounceContact(mail.contact);
					const fixedEmail = mail.to.trim().toLowerCase();
					const loading = this.props.currentlyUnBouncing.includes(fixedEmail);
					const tooltipTitle = valid
						? ''
						: reason === NOT_ABLE_TO_DEBOUNCE_REASONS.MAX_REMOVALS_REACHED
						? this.lang.maxNumberRemovalsReached
						: this.lang.alreadyRemoved;

					rowTools.push(
						<Tooltip title={tooltipTitle} disabled={valid}>
							<Button
								key="unbounce"
								type="link"
								color="green"
								onClick={this.onUnBounceContact}
								size="xs"
								text={this.lang.unbounce}
								shadow="none"
								disabled={!valid}
								loading={loading}
							/>
						</Tooltip>
					);
				}
			} else if (mail.errorCause === 'duplicate_email' || mail.errorCause === 'invalid_email') {
				if (mail.errorCause === 'invalid_email') {
					invalidEmail = true;
				}
			} else if (mail.errorCause === 'previous_unsubscribed') {
				unsubIcon = <b className="unsub-icon fa fa-minus-circle text-red-i" />;
			}
		} else if (mail.events && _.find(mail.events, { type: 'unsub' })) {
			errorMsg = (
				<span className="delivery-status">
					<span>{this.lang.didUnsub}</span>
				</span>
			);
			unsubIcon = (
				<span className="unsub-icon text-grey-i">
					<b className="fa fa-envelope-open" />
					<b className="fa fa-times-circle" />
				</span>
			);
		} else {
			var formSubmitDate = null;
			if (mail.formSubmits.length) {
				formSubmitDate = mail.formSubmits[0].regDate;
			}
			maStatus = React.createElement(ReactTemplates.TOOLS.mailStats, {
				read: mail.lastReadDate || mail.lastClickDate,
				clicked: mail.lastClickDate,
				submitted: formSubmitDate,
				tools: tools
			});
		}

		rowTools.push(
			<Button color="green" type="link" shadow="none" size="xs" onClick={this.onEditContact}>
				<Icon name="edit" />
			</Button>
		);

		return (
			<tr key={'mail-' + i} onClick={this.props.openDrilldown} className={isDrilled ? 'drilldowned-row' : null}>
				<ReactTemplates.TOOLS.checkboxTD tools={this.props.tools} item={mail} />

				<td key="contact-client" className="account-contact-col">
					{mail.contact ? (
						<span className="ellipsis-content">
							<ReactTemplates.TOOLS.contactHref
								customerId={this.customerId}
								id={mail.contact.id}
								text={mail.contact.name}
								tools={tools}
							/>
						</span>
					) : null}
					{mail.client ? (
						<span className="account-subtitle ellipsis-content">
							<ReactTemplates.TOOLS.accountHref
								customerId={this.customerId}
								id={mail.client ? mail.client.id : '0'}
								text={mail.client ? mail.client.name : '-'}
								tools={tools}
							/>
						</span>
					) : null}
				</td>

				<td key="to">
					<span className="ellipsis-content">
						{unsubIcon}
						<span className={invalidEmail ? 'invalid-email' : null}>{mail.to}</span>
					</span>
				</td>

				<td key="status">{clientStatus}</td>

				<td key="action">
					{maStatus}
					{errorMsg}
				</td>

				<td key="tools" style={{ textAlign: 'right', paddingRight: '5px', whiteSpace: 'nowrap' }}>
					{rowTools}
				</td>
			</tr>
		);
	}
});

import _ from 'lodash';
import React from 'react';
import {
	Card,
	CardHeader,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Title,
	Help,
	Tooltip,
	Icon,
	ButtonSelect
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './EditRoleAccessTable.scss';
import { RolePermissionValues, AccessRolePermissionValues } from 'App/resources/Model/Role';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'Store/index';

const mapStateToProps = (state: RootState) => ({
	metadata: state.App.metadata,
	useDiscountParam: state.App.metadata?.params.UseDiscount || false
});

const connector = connect(mapStateToProps);

interface Access {
	selectedValue: string;
	values: RolePermissionValues | AccessRolePermissionValues;
}

interface Modify {
	selectedValue: string;
	values: RolePermissionValues;
}

interface Delete {
	selectedValue: string;
	values: RolePermissionValues;
}

export interface RoleAccess {
	name: string;
	namePlural: string;
	group: string;
	entity: string;
	access: Access;
	modify?: Modify;
	delete?: Delete;
}

type EditRoleAccessTableProps = ConnectedProps<typeof connector> & {
	title: string;
	access: RoleAccess[];
	parentId: number;
	onChange: (entity: string, type: string, value: string | number | boolean) => void;
	onDiscountChange?: (value: boolean) => void;
	hasDiscount?: boolean;
};

class EditRoleAccessTable extends React.Component<EditRoleAccessTableProps, {}> {
	static defaultProps = {
		access: [],
		onChange: () => {}
	};

	lang: {
		[key: string]: string;
	};
	accessTableColumns: { title: string | (() => JSX.Element) }[];
	hasDiscountFeatureFlag: boolean;

	constructor(props: EditRoleAccessTableProps) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			access: t('default.access'),
			change: t('default.change'),
			delete: t('soliditet.matcher.actionDelete'),
			discount: t('admin.discount'),
			yes: t('default.yes'),
			no: t('default.no')
		};

		this.hasDiscountFeatureFlag =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SET_DISCOUNT) &&
			Tools.FeatureHelper.hasSoftDeployAccess('SET_DISCOUNT');
		this.accessTableColumns = [
			{ title: '' },
			{
				title: () => (
					<span>
						{this.lang.access} <Help articleId={563} />
					</span>
				)
			},
			{
				title: () => (
					<span>
						{this.lang.change} <Help articleId={563} />
					</span>
				)
			},
			{
				title: () => (
					<span>
						{this.lang.delete} <Help articleId={563} />
					</span>
				)
			}
		];

		this.accessChanged = this.accessChanged.bind(this);
	}

	accessChanged(entity: string, type: string, value: string | number | boolean) {
		this.props.onChange(entity, type, value);
	}

	checkAccess() {
		const access = this.props.access;
		const reportAccess = access.find(type => type.entity === 'Report');
		let higherAccess = false;
		if (reportAccess?.access?.selectedValue === 'own') {
			return false;
		}
		access.forEach(entity => {
			if (entity.access?.selectedValue === 'own') {
				higherAccess = true;
			} else if (entity.access?.selectedValue === 'all' && reportAccess?.access?.selectedValue === 'top') {
				higherAccess = true;
			}
		});
		return higherAccess;
	}

	render() {
		const classes = new bemClass('EditRoleAccessTable');
		const topLevel = this.props.parentId === -1;
		const { useDiscountParam } = this.props;
		const rows = _.map(this.props.access, entity => {
			return (
				<TableRow key={entity.entity + '-row'}>
					<TableColumn>
						<Title size="sm" bold>
							{entity.name}
						</Title>
					</TableColumn>
					<TableColumn className={classes.elem('access-col').b()}>
						{entity.access ? (
							<div className={classes.elem('access-col-div').b()}>
								<ReactTemplates.admin.roleAccessInput
									key={entity.entity + '-access'}
									accessType={entity.entity}
									topLevel={topLevel}
									type="access"
									name={entity.namePlural}
									options={entity.access.values}
									value={entity.access.selectedValue}
									onChange={this.accessChanged.bind(this, entity.entity, 'access')}
								/>
								{entity.entity === 'Report' && this.checkAccess() ? (
									<Tooltip
										title={Tools.$translate('admin.accessReport.warning')}
										theme="orange"
										position="right"
									>
										<Icon name="exclamation-triangle" color="orange" />
									</Tooltip>
								) : null}
							</div>
						) : null}
					</TableColumn>
					<TableColumn className={classes.elem('access-col').b()}>
						{entity.modify ? (
							<ReactTemplates.admin.roleAccessInput
								key={entity.entity + '-modify'}
								topLevel={topLevel}
								type="modify"
								name={entity.namePlural}
								options={entity.modify.values}
								value={entity.modify.selectedValue}
								onChange={this.accessChanged.bind(this, entity.entity, 'modify')}
							/>
						) : null}
					</TableColumn>
					<TableColumn className={classes.elem('access-col').b()}>
						{entity.delete ? (
							<ReactTemplates.admin.roleAccessInput
								key={entity.entity + '-delete'}
								topLevel={topLevel}
								type="delete"
								name={entity.namePlural}
								options={entity.delete.values}
								value={entity.delete.selectedValue}
								onChange={this.accessChanged.bind(this, entity.entity, 'delete')}
								last={true}
							/>
						) : null}
					</TableColumn>
				</TableRow>
			);
		});

		return (
			<div className={classes.b()}>
				<Card>
					<CardHeader title={this.props.title} />
					<Table>
						<TableHeader columns={this.accessTableColumns} />
						{rows}
						{this.props.hasDiscount !== undefined && useDiscountParam && this.hasDiscountFeatureFlag ? (
							<TableRow>
								<TableColumn>
									<Title size="sm" bold>
										{this.lang.discount}
									</Title>
								</TableColumn>
								<TableColumn>
									<div className={classes.elem('access-col-div').b()}>
										<ButtonSelect
											onChange={value => this.props.onDiscountChange?.(value)}
											options={[
												{
													value: true,
													title: this.lang.yes
												},
												{
													value: false,
													title: this.lang.no
												}
											]}
											value={this.props.hasDiscount}
											size="sm"
										/>
									</div>
								</TableColumn>
							</TableRow>
						) : null}
					</Table>
				</Card>
			</div>
		);
	}
}

export default connector(EditRoleAccessTable);

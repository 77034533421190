import React from 'react';
import './AppointmentTodos.scss';
import QuickCreateTodoCard from 'Components/QuickCreateTodoCard';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import BemClass from '@upsales/components/Utils/bemClass';
import Appointment from 'App/resources/Model/Appointment';
import Activity from 'App/resources/Model/Activity';
interface Props {
	appointment: Appointment;
	canCreate?: boolean;
}
const AppointmentTodos = ({ appointment, canCreate = true }: Props) => {
	const onSave = (createData: Activity) => {
		let data;
		if (createData?.id) {
			data = { ...createData };
		} else {
			data = {
				...createData,
				client: appointment.client,
				contacts: appointment.contacts,
				activityType: { id: Tools.AppService.getTodoTypes().TODO.id },
				parentAppointmentId: appointment.id,
				users: [{ id: Tools.AppService.getSelf().id }]
			};
		}
		return Tools.Activity.customer(Tools.AppService.getCustomerId()).save(data);
	};
	const fetchTodos = () => {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'parentAppointmentId' }, comparisonTypes.Equals, appointment.id);
		rb.addSort({ field: 'priority' }, false);
		return Tools.Activity.customer(Tools.AppService.getCustomerId()).find(rb.build());
	};
	if (!appointment) {
		return null;
	}
	const classes = new BemClass('AppointmentTodos');
	return (
		<div className={classes.b()}>
			<QuickCreateTodoCard onSave={onSave} fetchTodos={fetchTodos} hideRelation canCreate={canCreate} />
		</div>
	);
};

export default AppointmentTodos;

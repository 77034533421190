import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

export default class ValueSugest extends React.Component {
	componentDidMount() {
		const { Lookup, ListAttributes, AppService } = Tools;
		var attrs = ListAttributes.get(this.props.entity.toLowerCase()).attr;
		var field = _.get(attrs, this.props.field);

		const options = {
			tags: [],
			multiple: true,
			allowClear: true,
			closeOnSelect: true,
			separator: '|',
			placeholder: this.props.placeholder,
			formatResult: item => {
				if (item.count && this.props.showCount) {
					return _.capitalize(_.escape(item.text)) + ' (' + item.count + ')';
				} else {
					return _.capitalize(_.escape(item.text));
				}
			},
			matcher: (term, undef, item) => {
				return item.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			formatSelection: (item, container, escape) => _.capitalize(escape(item.text) || ''),
			ajax: {
				quietMillis: 100,
				data: term => term,
				transport: query => {
					var searchTerm = (query.data || '').toLowerCase();
					var customerId = AppService.getCustomerId();

					Lookup.customer(customerId)
						.setType(this.props.entity)
						.findEnd(field, searchTerm, 10, [{ _count: 'desc' }, { _term: 'asc' }], this.props.defaultValue)
						.then(res => {
							var data = _.reduce(
								res.data,
								(res, item) => {
									if (item.value) {
										res.push({ id: item.value, text: item.value, count: item.count });
									}
									return res;
								},
								[]
							);

							query.success({ data: data });
						})
						.catch(e => console.log('lookup failed', e));
				},
				results: res => {
					return { results: res.data };
				}
			},
			formatNoMatches: function () {
				return Tools.$translate('default.noResults');
			}
		};

		const { onChange, defaultValue, eventListeners, autoFocus, autoOpen } = this.props;

		const input = jQuery(this.input);
		const select2 = input.select2(options).on('change', function (e) {
			onChange({ target: { value: e.val } });
		});

		if (Array.isArray(defaultValue) && defaultValue.length) {
			const values = defaultValue.map(value => ({ id: value, text: value }));
			input.select2('data', values);
		}

		_.each(eventListeners, function (fn, event) {
			select2.on(event, fn);
		});

		if (autoFocus) {
			input.select2('focus');
		}

		if (autoOpen) {
			input.select2('open');
		}
	}

	setRef(name, ref) {
		this[name] = ref;
	}

	componentWillUnmount() {
		const element = jQuery(this.input);
		element.select2('destroy');
		// Calling .off() with no arguments removes all handlers attached to the elements.
		element.off();
	}

	render() {
		return (
			<input
				id={this.props.id}
				disabled={this.props.disabled || false}
				className={this.props.className || 'form-control'}
				ref={this.setRef.bind(this, 'input')}
				type="hidden"
			/>
		);
	}
}

ValueSugest.propTypes = {
	field: PropTypes.string.isRequired,
	entity: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	showCount: PropTypes.bool,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.array,
	eventListeners: PropTypes.object,
	id: PropTypes.string,
	autoFocus: PropTypes.bool,
	autoOpen: PropTypes.bool
};

ValueSugest.defaultProps = {
	className: '',
	id: '',
	placeholder: '',
	showCount: false,
	onChange: _.noop,
	autoFocus: false,
	autoOpen: false
};

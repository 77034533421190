import React from 'react';
import colorMappings from '@upsales/components/Utils/colorMappings';
import './FinancialChart.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import Hs from 'highcharts';
import moment from 'moment';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';

type Props = {
	result: number[];
	turnover: number[];
	years: string[];
	placeholder: boolean;
	currency: string;
};

interface Chart {
	new (...args: any): any;
}
const Highcharts = (Hs as unknown) as {
	Chart: Chart;
};

class FinancialChart extends React.Component<Props> {
	_chart?: HTMLDivElement | null;
	years: Props['years'];
	result: Props['result'];
	turnover: Props['turnover'];

	constructor(props: Props) {
		super(props);
		this.years = props.years;
		this.result = props.result;
		this.turnover = props.turnover;

		while (this.years.length < 5) {
			this.years.push(
				moment(this.years[this.years.length - 1].toString() + '-01-01')
					.subtract(1, 'year')
					.year()
					.toString()
			);
			this.result.push(0);
			this.turnover.push(0);
		}
	}
	componentDidMount() {
		this.renderChart();
	}

	renderChart() {
		const currencyFormat = new CurrencyFormat(this.props.currency);
		if (this._chart) {
			let config;
			if (this.props.placeholder) {
				config = {
					tooltip: { enabled: false },
					chart: {
						type: 'column',
						backgroundColor: 'transparent',
						margin: [10, 30, 35, 60],
						height: 200,
						spacing: [0, 0, 0, 0]
					},
					title: { text: null },
					credits: { enabled: false },
					yAxis: {
						labels: {
							formatter: function () {
								return '';
							}
						},
						title: { text: null }
					},
					xAxis: {
						type: 'category',
						title: { text: null },
						lineWidth: 1,
						lineColor: colorMappings.get('grey-10'),
						tickWidth: 0,
						categories: [2020, 2019, 2018, 2017, 2016]
					},
					plotOptions: {
						series: {
							borderWidth: 0
						},
						column: {
							borderRadius: 3
						}
					},
					series: [
						{
							states: { hover: { enabled: false } },
							showInLegend: false,
							name: 'Turnover',
							color: colorMappings.get('grey-5'),
							data: [17, 13, 8, 7, 1],
							borderRadiusBottomLeft: '3px',
							borderRadiusBottomRight: '3px'
						},
						{
							states: { hover: { enabled: false } },
							showInLegend: false,
							name: 'Result',
							color: colorMappings.get('grey-5'),
							data: [10, 8, 7, 4, 1],
							borderRadiusBottomLeft: '3px',
							borderRadiusBottomRight: '3px'
						}
					]
				};
			} else {
				config = {
					chart: {
						type: 'column',
						backgroundColor: 'transparent',
						margin: [30, 30, 35, 100],
						spacingLeft: 100,
						height: 200,
						spacing: [0, 0, 0, 0]
					},
					title: { text: null },
					credits: { enabled: false },
					yAxis: {
						labels: {
							formatter: function () {
								if ((this as any).isFirst) {
									return '';
								}
								return currencyFormat.short((this as any).value);
							}
						},
						title: { text: null }
					},
					xAxis: {
						type: 'category',
						title: { text: null },
						lineWidth: 1,
						lineColor: colorMappings.get('grey-10'),
						tickWidth: 0,
						showEmpty: true,
						categories: this.years
					},
					plotOptions: {
						series: {
							borderWidth: 0
						},
						column: {
							borderRadius: 3
						}
					},
					tooltip: {
						borderColor: 'transparent',
						backgroundColor: '#1E252E',
						shadow: false,
						useHTML: true,
						formatter: function () {
							return `<div>
								<div class="Text Text--white">${currencyFormat.default((this as any).y)}</div>
							</div>`;
						},
						style: {
							zIndex: 100
						}
					},
					series: [
						{
							showInLegend: false,
							name: 'Turnover',
							color: colorMappings.get('website-visits-color'),
							data: this.turnover,
							borderRadiusBottomLeft: '3px',
							borderRadiusBottomRight: '3px'
						},
						{
							showInLegend: false,
							name: 'Result',
							color: colorMappings.get('bright-green'),
							negativeColor: colorMappings.get('red'),
							data: this.result,
							borderRadiusBottomLeft: '3px',
							borderRadiusBottomRight: '3px'
						}
					]
				};
			}
			new Highcharts.Chart(this._chart, config);
		}
	}

	render() {
		const classNames = new bemClass('FinancialChart');
		return <div className={classNames.b()} ref={r => (this._chart = r)} />;
	}
}

export default FinancialChart;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Text, Checkbox } from '@upsales/components';
import Order from 'App/resources/Model/Order';
import bemClass from '@upsales/components/Utils/bemClass';

import './OpportunityRow.scss';

type Props = {
	text: string;
	isWarning: boolean;
	opportunity: Order;
	overrideOpportunity: (obj: { confirmedBudget: number | null }) => void;
	disabled?: boolean;
};

class OpportunityRowBudget extends React.Component<Props> {
	static propTypes = {
		isWarning: PropTypes.bool.isRequired,
		text: PropTypes.string.isRequired,
		overrideOpportunity: PropTypes.func,
		opportunity: PropTypes.object,
		disabled: PropTypes.bool
	};

	render() {
		const classes = new bemClass('OpportunityRow');
		const { text, isWarning, opportunity, overrideOpportunity, disabled } = this.props;
		return (
			<Card
				className={classes.mod({ clickable: !disabled }).b()}
				onClick={
					disabled
						? undefined
						: async () => {
								await overrideOpportunity({ confirmedBudget: isWarning ? opportunity.value : null });
						  }
				}
			>
				<Checkbox disabled={disabled} space="mll" size="sm" checked={!isWarning} />
				<div className={classes.elem('con').b()}>
					<Text className={classes.elem('description').b()}>{text}</Text>
				</div>
			</Card>
		);
	}
}

export default OpportunityRowBudget;

import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { makeCancelable } from 'App/babel/helpers/promise';
import { Row, Text, Link } from '@upsales/components';
import { FlowStep } from 'App/resources/Model/Flow';
import CallList from 'App/babel/resources/CallList';
import logError from 'App/babel/helpers/logError';
import Project from 'App/resources/Model/User';
import User from 'App/resources/Model/User';
import Role from 'App/resources/Model/Role';
import FlowStepBase from '../FlowStepBase';

type Props = Omit<React.ComponentProps<typeof FlowStepBase>, 'borderColor'> &
	Required<Pick<React.ComponentProps<typeof FlowStepBase>, 'onClick' | 'onDelete' | 'editable' | 'isInvalid'>> & {
		step: FlowStep;
		placeholder: string;
	};

function getUserRole(step: FlowStep, targetProperty: 'Role'): Role | null;
function getUserRole(step: FlowStep, targetProperty: 'User'): User | null;
function getUserRole(step: FlowStep, targetProperty: 'User' | 'Role') {
	if (!Array.isArray(step.params)) {
		return null;
	}

	const foundProperty = step.params.find(property => property.name === targetProperty);

	if (!foundProperty) {
		return null;
	}

	let entityId = Array.isArray(foundProperty.value) ? foundProperty.value[0] : foundProperty.value;

	if (typeof entityId === 'string' && entityId.indexOf(',') === -1) {
		entityId = parseInt(entityId);
	}

	if (!entityId) {
		return null;
	}

	if (targetProperty === 'Role') {
		const entities = Tools.AppService.getRoles();
		return entities.find(entity => entity.id === entityId) ?? null;
	} else {
		const entities = Tools.AppService.getUsers();
		return entities.find(entity => entity.id === entityId) ?? null;
	}
}

const FlowStepUserRow = ({
	step,
	isInvalid,
	icon,
	title,
	maxHeight,
	placeholder,
	children,
	editable,
	...baseProps
}: Props) => {
	const user = useMemo(() => getUserRole(step, 'User'), [step.params]);
	const role = useMemo(() => getUserRole(step, 'Role'), [step.params]);
	const [callList, setCallist] = useState<Project | null>(null);
	const rowRef = useRef<HTMLDivElement>(null);
	const [hasSpaceForRole, setHasSpaceForRole] = useState<boolean>(true);
	const [calculateWidth, setCalculateWidth] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		setCalculateWidth(true);
	}, [rowRef.current, user?.role]);

	useEffect(() => {
		const callListProperty =
			Array.isArray(step.params) && step.params.find(property => property.name === 'CallList');

		if (!callListProperty) {
			return;
		}
		const rb = new RequestBuilder();
		rb.addFilter(CallList.attr.id, comparisonTypes.Equals, callListProperty.value);
		const cancelablePromise = makeCancelable(CallList.find(rb.build()));

		cancelablePromise.promise
			.then(({ data }: { data: Project[] }) => setCallist(data[0] ?? null))
			.catch(error => logError(error, 'FlowStepCreatePhoneCall - fetchCallist'));
		return () => cancelablePromise.cancel();
	}, [step.params]);

	useEffect(() => {
		if (calculateWidth) {
			const hasSpaceForRole =
				rowRef?.current && rowRef.current.scrollWidth <= rowRef.current.offsetWidth ? true : false;
			setHasSpaceForRole(hasSpaceForRole);
			setCalculateWidth(false);
		}
	}, [calculateWidth]);

	const showRole = hasSpaceForRole && user?.role ? true : false;
	const TextComponent = editable ? Link : Text;
	const placeholderColor = editable ? 'bright-blue' : 'grey-11';

	return (
		<FlowStepBase
			icon={icon}
			backgroundColor="white"
			borderColor="rose-4"
			title={title}
			maxHeight={maxHeight}
			isInvalid={isInvalid}
			editable={editable}
			{...baseProps}
		>
			<Row ref={rowRef}>
				{user ? (
					<>
						{showRole ? <Text>{`${user.role?.name}: `}</Text> : null}
						<TextComponent>{user.name}</TextComponent>
					</>
				) : null}
				{!user && role ? <TextComponent>{role.name}</TextComponent> : null}
				{!user && !role && callList ? (
					<TextComponent>{`${t('default.callList')}: ` + callList.name}</TextComponent>
				) : null}
				{!user && !role && !callList ? (
					<TextComponent color={placeholderColor}>{placeholder}</TextComponent>
				) : null}
			</Row>
			{callList && user ? (
				<Row>
					<Text size="sm">{`${t('default.callList')}: `}</Text>
					<Text size="sm">{callList.name}</Text>
				</Row>
			) : null}
			{children}
		</FlowStepBase>
	);
};

export default FlowStepUserRow;

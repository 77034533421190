import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';

var landingpageEditorCtrl = function (
	self,
	isModal,
	$scope,
	AppService,
	$templateParser,
	$upModal,
	$translate,
	$safeApply,
	Form,
	$state,
	FeatureHelper,
	NotificationService,
	$q,
	AccountProfile,
	meta,
	params
) {
	var LandingpageEditor = self;
	var modalMeta;
	var initialHash;
	var currentHash;
	var allowStateChange = false;
	var availableFields = [];
	var liveTags = params.liveTags || null;

	var multipleActions = ['SendEmail', 'SendWebhook'];

	const form = meta.form.data;
	const hasNameField = form.fields.find(field => field.name === 'Contact.name');
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

	var clientFields = [
		{ title: 'default.extraField', name: 'Extra', datatype: 'text', type: 'extra', disabled: false, options: null },
		{ title: 'tag.client.name', name: 'Client.name', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.phone', name: 'Client.phone', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.webpage', name: 'Client.webpage', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.address', name: 'Client.address', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.zipcode', name: 'Client.zipcode', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.city', name: 'Client.city', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.country', name: 'Client.country', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.Contact.email', name: 'Contact.email', datatype: 'text', type: 'standard', disabled: true },
		{ title: 'tag.Contact.title', name: 'Contact.title', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.Contact.phone', name: 'Contact.phone', datatype: 'text', type: 'standard', disabled: false },
		{
			title: 'tag.Contact.cellPhone',
			name: 'Contact.cellPhone',
			datatype: 'text',
			type: 'standard',
			disabled: false
		}
	];

	if (hasNewFields && !hasNameField) {
		clientFields.splice(
			8,
			0,
			{
				title: 'tag.Contact.firstname',
				name: 'Contact.firstname',
				datatype: 'text',
				type: 'standard',
				disabled: false
			},
			{
				title: 'tag.Contact.lastname',
				name: 'Contact.lastname',
				datatype: 'text',
				type: 'standard',
				disabled: false
			}
		);
	} else {
		clientFields.splice(8, 0, {
			title: 'tag.Contact.name',
			name: 'Contact.name',
			datatype: 'text',
			type: 'standard',
			disabled: false
		});
	}

	const standardFieldNames = [
		{ name: 'OrgNo', datatype: 'text' },
		{ name: 'NoEmployees', datatype: 'number' },
		{ name: 'Facebook', datatype: 'text' },
		{ name: 'Twitter', datatype: 'text' },
		{ name: 'LinkedIn', datatype: 'text' }
	];

	if (hasNewFields) {
		const standardFields = Tools.AppService.getMetadata().standardFields.Client || {};
		standardFieldNames.forEach(({ name, datatype }) => {
			const field = standardFields[name];
			if (!field || !field.active) return null;

			clientFields.push({
				title: field.nameTag,
				name: `Client.${field.field}`,
				type: 'standard',
				disabled: false,
				datatype
			});
		});
	}

	var actions = [
		{ title: 'automationAction.SendEmail', action: 'SendEmail' },
		{ title: 'automationAction.AssignToUser', action: 'AssignToUser' },
		{ title: 'automationAction.UpdateClient', action: 'UpdateClient' },
		{ title: 'automationAction.UpdateContact', action: 'UpdateContact' },
		{ title: 'automationAction.SendWebhook', action: 'SendWebhook' },
		{
			title: 'automationAction.CreateActivity',
			action: 'CreateActivity',
			available: () =>
				!(
					Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
					Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
				)
		},
		{
			title: 'automationAction.PlanPhonecalls',
			action: 'PlanPhonecalls',
			available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
		},
		{ title: 'automationAction.SendTemplate', action: 'SendMapMail' }
	];

	var generateNote = function (fields) {
		var keys = _.keys(fields);
		var output = '';
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			output += fields[field].title + ':\n';
			if (fields[field].text) {
				output += '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				output += '{{FormSubmit.' + fields[field].name + '}}';
			}
			output += '\n\n';
		});
		return output;
	};

	var generateJson = function (fields) {
		var keys = _.keys(fields);
		var output = '[';
		var noOfFields = 0;
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			if (noOfFields > 0) {
				output += ',';
			}
			output += '\n    {"' + fields[field].title + '": ';
			if (fields[field].text) {
				output += '"{{FormSubmit.' + fields[field].text + '}}"';
			} else {
				output += '"{{FormSubmit.' + fields[field].name + '}}"';
			}
			output += '}';
			noOfFields++;
		});
		output += '\n  ]';
		return output;
	};

	var generateEmail = function (header, fields, footer) {
		var keys = _.keys(fields);
		var output = '<font face="arial" style="font-family:arial;size:13px;" size=2>';
		output += header;
		output += '<br><br></font>';
		output += '<table border=0 width=400 cellpadding=0 cellspacing=0>';
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}

			if (fields[field].name.includes('Prefs.')) {
				return;
			}

			output +=
				'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:#002f65;color:#ffffff"><font face="arial" size=2>';
			output += fields[field].title;
			output += '</font></td></tr>';
			output +=
				'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:#dddddd"><font face="arial" size=2>';
			if (fields[field].text) {
				output += '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				output += '{{FormSubmit.' + fields[field].name + '}}';
			}
			output += '</font></td></tr>';
		});
		output += '</table>';
		output += '<font face="arial" size=2 style="font-family:arial;size:13px;">';
		output += '<br>';
		output += footer;
		output += '</font>';

		return output;
	};

	var generateExtraTags = function (fields, $translate) {
		var keys = _.keys(fields);
		var output = [];
		_.forEach(keys, function (field) {
			var text;
			var value;
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			text = $translate.instant('form.form') + ': ' + fields[field].title;
			if (fields[field].text) {
				value = '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				value = '{{FormSubmit.' + fields[field].name + '}}';
			}
			output.push({
				text: text,
				value: value
			});
		});
		return output;
	};

	var fixFields = function () {
		LandingpageEditor.rootData.form.fields = _.map(LandingpageEditor.rootData.form.fields, function (field) {
			// Extend the field in available fieldsArr and set it to use = true
			var availableField = _.find(clientFields, { name: field.name });
			if (availableField) {
				field = _.merge(_.clone(availableField), field);
				field.required = !!field.required;
				if (!field.datatype) {
					field.datatype = 'text';
				}
				var i = _.findIndex(clientFields, { text: field.name });
				if (i !== -1 && !clientFields[i].disabled) {
					clientFields[i].disabled = true;
				}
			} else if (field.name.indexOf('Extra.') === 0) {
				field.type = 'extra';
				field.required = !!field.required;
			} else if (field.name.indexOf('Prefs') === 0) {
				// availableField = _.findIndex(LandingpageEditor.prefs.data, {name: field.name});
				// if(availableField !== -1) {
				// 	LandingpageEditor.prefs.data[availableField].value = true;
				// }
			}
			return field;
		});
	};

	var updateAvailableFields = function () {
		availableFields = _.filter(clientFields, function (field) {
			if (field.type !== 'standard') {
				return field;
			}
			var usedField = _.find(LandingpageEditor.rootData.fields, { name: field.name });
			if (!usedField) {
				return field;
			}
		});
	};

	var getAvailableOptIns = function () {
		var out = {};
		var optInGroup = _.groupBy(meta.optIns.data, function (optIn) {
			return optIn.type;
		});
		_.forEach(optInGroup, function (arr, type) {
			out[type] = _.filter(arr, function (optIn) {
				return (
					!_.includes(_.pluck(LandingpageEditor.rootData.form.fields, 'options'), String(optIn.id)) &&
					optIn.type === type
				);
			});
		});
		return out;
	};

	function checkWidthValues(element) {
		if (element?.type === 'image' && element.value?.src) {
			if (element.value.naturalWidth === undefined) {
				const selector = element.id === 'logo' ? '.up-element#logo' : `[id="${element.id}"]`;
				const imgElem = document.querySelector(selector);
				element.value.naturalWidth = imgElem?.naturalWidth;
			}
			if (element.value.width === undefined && element.value.maxWidth === undefined) {
				element.value.width = element.value.naturalWidth;
			}
		}
	}

	var onSelectRow = function (data) {
		LandingpageEditor.rootData.selectedRowId = data.row;
		LandingpageEditor.rootData.selectedColumnId = data.column;

		LandingpageEditor.rootData.selectedRow = _.find(
			LandingpageEditor.rootData.form.landingPageBody.rows,
			function (row) {
				return row.id.toString() === data.row;
			}
		);

		if (LandingpageEditor.rootData.selectedRow && LandingpageEditor.rootData.selectedRow.special) {
			const logoElement = LandingpageEditor.rootData.selectedRow.elements.find(element => element.id === 'logo');
			checkWidthValues(logoElement);
			$safeApply($scope);
			return;
		}

		const column = _.find(LandingpageEditor.rootData.selectedRow.columns, function (column) {
			return column.id.toString() === data.column;
		});

		LandingpageEditor.rootData.selectedColumn = column;

		if (column) {
			const element = column.elements[0];
			checkWidthValues(element);
		}

		if (data.showRow) {
			onSetToolTab('row');
		} else {
			onSetToolTab('column');
		}
	};

	function onSetToolTab(type) {
		LandingpageEditor.rootData.toolTab = type;
		$safeApply($scope);
	}

	var onDeselectColumn = function () {
		LandingpageEditor.rootData.selectedRowId = null;
		LandingpageEditor.rootData.selectedColumnId = null;
		LandingpageEditor.rootData.selectedRow = null;
		LandingpageEditor.rootData.selectedColumn = null;
		$safeApply($scope);
	};

	var onRowBgChange = function (data) {
		LandingpageEditor.rootData.selectedRow.background = data;
		parseAll();
	};

	var onClearColumn = function () {
		$templateParser.clearColumn(
			LandingpageEditor.rootData.selectedRowId,
			LandingpageEditor.rootData.selectedColumnId,
			LandingpageEditor.rootData.form.landingPageBody,
			function (err, config) {
				LandingpageEditor.rootData.form.landingPageBody = config;
				parseAll();
			}
		);
	};

	var onDeleteRow = function (rowId) {
		$templateParser.deleteRow(
			rowId || LandingpageEditor.rootData.selectedRowId,
			LandingpageEditor.rootData.form.landingPageBody,
			function (err, config) {
				LandingpageEditor.rootData.form.landingPageBody = config;
				onDeselectColumn();
				parseAll();
			}
		);
	};

	var onAddRow = function (afterRowId, layout) {
		$templateParser.addRow(
			afterRowId,
			layout,
			LandingpageEditor.rootData.form.landingPageBody,
			function (err, config) {
				LandingpageEditor.rootData.form.landingPageBody = config;
				parseAll();
			}
		);
	};

	var onSetColumnType = function (type) {
		$templateParser.setColumnType(
			LandingpageEditor.rootData.selectedRowId,
			LandingpageEditor.rootData.selectedColumnId,
			LandingpageEditor.rootData.form.landingPageBody,
			type,
			LandingpageEditor.rootData.accountProfile,
			function (err, config) {
				LandingpageEditor.rootData.form.landingPageBody = config;
				parseAll();
			}
		);
	};

	var onElementChange = function (element) {
		if (
			LandingpageEditor.rootData.selectedColumn &&
			LandingpageEditor.rootData.selectedColumn.elements &&
			LandingpageEditor.rootData.selectedColumn.elements.length &&
			LandingpageEditor.rootData.selectedColumn.elements[0].id === element.id
		) {
			LandingpageEditor.rootData.selectedColumn.elements = [element];
			parseAll();
		}
	};

	var onPaddingChange = function (padding) {
		LandingpageEditor.rootData.selectedColumn.padding = padding;
		parseAll();
	};

	var toggleHeaderVisibility = function () {
		LandingpageEditor.rootData.selectedRow.visible = !LandingpageEditor.rootData.selectedRow.visible;
		parseAll();
	};

	var transparentHeaderChanged = function (value) {
		LandingpageEditor.rootData.selectedRow.transparentHeader = value;
		parseAll();
	};

	var onPaddingChangeRow = function (padding) {
		var rowIndex = _.findIndex(LandingpageEditor.rootData.form.landingPageBody.rows, function (row) {
			return row.id.toString() === LandingpageEditor.rootData.selectedRowId;
		});
		if (rowIndex !== -1) {
			LandingpageEditor.rootData.form.landingPageBody.rows[rowIndex].padding = padding;
			parseAll();
		}
	};

	var onTemplateSelect = function (template) {
		LandingpageEditor.rootData.editMode = true;
		var config = $templateParser.generateConfig(template.bodyJson, LandingpageEditor.rootData.accountProfile);
		LandingpageEditor.rootData.form = _.merge(LandingpageEditor.rootData.form, config);

		// eslint-disable-next-line no-unused-expressions
		LandingpageEditor.rootData.form?.landingPageBody?.rows?.some(row => {
			const logoElement = row.elements?.find(element => element.id === 'logo');
			if (logoElement) {
				logoElement.value.maxWidth = 200;
			}
			return !!logoElement;
		});

		parseAll(function () {
			initialHash = currentHash;
		});
	};

	var onFormChange = function (form) {
		LandingpageEditor.rootData.form = form;
		LandingpageEditor.rootData.form.fields = _.map(LandingpageEditor.rootData.form.fields, function (f, i) {
			f.sort = i + 1;
			return f;
		});
		LandingpageEditor.rootData.fields = LandingpageEditor.rootData.form.fields;
		updateAvailableFields();
		parseAll();
	};

	var onFieldsChange = function (fields) {
		LandingpageEditor.rootData.fields = fields;
		updateAvailableFields();
		parseAll();
	};

	var onRowElementChange = function (element) {
		var rowIndex = _.findIndex(LandingpageEditor.rootData.form.landingPageBody.rows, function (row) {
			return row.id.toString() === LandingpageEditor.rootData.selectedRowId;
		});
		var index = _.findIndex(LandingpageEditor.rootData.form.landingPageBody.rows[rowIndex].elements, {
			id: element.id
		});
		LandingpageEditor.rootData.form.landingPageBody.rows[rowIndex].elements[index] = element;
		parseAll();
	};

	var onEditAction = function (actionIndex, add) {
		if (add) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('list', {
					title: 'admin.addAction',
					hideHeader: true,
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: '', value: 'missingFeature' }
					],
					data: _.map(
						_.filter(actions, function (a) {
							var listObj = _.find(LandingpageEditor.rootData.form.actions, { action: a.action });
							if (
								(!listObj || multipleActions.indexOf(a.action) !== -1) &&
								!(a.available && !a.available())
							) {
								return a;
							}
						}),
						function (a) {
							var obj = { title: $translate.instant(a.title), action: a.action };
							if (
								a.action === 'SendWebhook' &&
								!FeatureHelper.isAvailable(FeatureHelper.Feature.TRIGGERS)
							) {
								obj.$$disabled = true;
								obj.title = '<span class="text-grey">' + $translate.instant(a.title) + '</span>';
								obj.missingFeature =
									'<span class="text-grey text-xs">' +
									$translate.instant('default.versionMissingFeature') +
									' ' +
									$translate.instant('feature.triggers').toLowerCase() +
									'</span>';
							}

							return obj;
						}
					)
				})
				.then(function (action) {
					onEditAction(action);
				});
			return;
		}

		var action = LandingpageEditor.rootData.form.actions[actionIndex];
		if (typeof actionIndex === 'object') {
			add = true;
			action = actionIndex;
		}

		// ModalParams
		var params = {
			accountCategories: modalMeta.accountCategories,
			accountCustomFields: modalMeta.accountCustomFields,
			contactCategories: modalMeta.contactCategories,
			contactCustomFields: modalMeta.contactCustomFields,
			activityCustomFields: modalMeta.activityCustomFields,
			campaigns: modalMeta.campaigns,
			leadSources: modalMeta.leadSources,
			activeUsers: modalMeta.activeUsers,
			roles: modalMeta.roles,
			activityTypes: modalMeta.activityTypes,
			action: action.action,
			entity: 'landingpage',
			hideDate: true,
			isTrigger: true,
			isAutomation: true
		};

		// Send current properties to modal
		if (!add) {
			params.properties = action.properties;
		}

		if (add) {
			if (!LandingpageEditor.rootData.fields) {
				LandingpageEditor.rootData.fields = LandingpageEditor.rootData.form.fields;
			}

			if (action.action === 'SendEmail') {
				params.prefill = {
					to: '',
					subject: $translate.instant('form.emailSubject') + ' {{Form.Name}}',
					body: generateEmail(
						$translate.instant('form.emailHeader'),
						LandingpageEditor.rootData.fields,
						$translate.instant('form.emailFooter')
					)
				};
				params.extraTags = generateExtraTags(LandingpageEditor.rootData.fields, $translate);
				params.extraTags.push({
					text: $translate.instant('form.form2') + ' ' + $translate.instant('default.name'),
					value: '{{Form.Name}}'
				});
			}
			if (action.action === 'SendWebhook') {
				params.prefill = {
					body:
						'{\n  "form": "{{Form.Name}}",\n  "fields": ' +
						generateJson(LandingpageEditor.rootData.fields) +
						'\n}',
					contentType: 'application/json',
					encoding: 'UTF-8',
					method: 'POST'
				};
			}
			if (action.action === 'CreateActivity') {
				params.prefill = {
					note: generateNote(LandingpageEditor.rootData.fields),
					description: $translate.instant('form.filledOut') + ' {{Form.Name}}'
				};
			}
		}

		const modalName = action.action + 'Action';

		let propertiesPromise;
		if (action.action === 'PlanPhonecalls') {
			propertiesPromise = new Promise(onSave => {
				openDrawer('PlanPhonecallsAction', {
					properties: add
						? [
								{ name: 'Description', value: $translate.instant('form.filledOut') + ' {{Form.Name}}' },
								{ name: 'Notes', value: generateNote(LandingpageEditor.rootData.fields) },
								{ name: 'User', value: Tools.AppService.getSelf().id }
						  ]
						: action.properties,
					assignOneUser: true,
					onSave
				});
			});
		} else {
			// Open modal
			if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modalName === 'SendWebhookAction') {
				propertiesPromise = new Promise((res, rej) => {
					openModal('SendWebhook', {
						tagEntity: 'landingpage',
						properties: params.properties || params.prefill,
						onClose: data => {
							if (data) {
								res(data);
							} else {
								rej();
							}
						}
					});
				});
			} else if (modalName === 'SendMapMailAction') {
				const properties = action.properties ?? [];
				let templateProperty = properties.find(property => property.name === 'MailTemplate');
				if (!templateProperty) {
					templateProperty = { name: 'MailTemplate', value: null };
					properties.push(templateProperty);
				}

				propertiesPromise = $upModal
					.open('mailTemplateBrowser', {
						selectedTemplateId:
							templateProperty && templateProperty.value ? parseInt(templateProperty.value) : null,
						requireUnsub: false
					})
					.then(selectedTemplate => {
						templateProperty.value = selectedTemplate.id;
						return properties;
					});
			} else {
				propertiesPromise = $upModal.open(modalName, params);
			}
		}
		// eslint-disable-next-line promise/catch-or-return
		propertiesPromise.then(properties => {
			// Update action or push new
			if (add) {
				LandingpageEditor.rootData.form.actions.push({
					action: action.action,
					properties: properties
				});
			} else {
				action.properties = properties;
			}
			$safeApply($scope);
		});
	};

	var beforeSave = function (name, form) {
		var defer = $q.defer();
		if (!name || !name.length) {
			$upModal
				.open('confirmLandingpage', {
					form: form
				})
				.then(formInput => {
					defer.resolve(formInput);
				})
				.catch(() => {
					defer.reject('CancelledFromModal');
				});
			return defer.promise;
		} else {
			defer.resolve({
				name: LandingpageEditor.rootData.form.name,
				internalName: LandingpageEditor.rootData.form.internalName
			});
			return defer.promise;
		}
	};

	var onSave = function () {
		var form = _.clone(LandingpageEditor.rootData.form);

		if (form.landingPageBody.hasForm && !form.thankYouElement && !form.landingPage) {
			// Show notification
			NotificationService.addNotification({
				style: NotificationService.style.WARN,
				icon: 'warning',
				title: 'form.enterThanksPage',
				body: 'form.formRequiresThanksPage'
			});

			var selectedCol;
			// Find form row and column
			LandingpageEditor.rootData.selectedRow = _.find(form.landingPageBody.rows, function (r) {
				var formCol = _.find(r.columns, function (c) {
					if (c.elements.length && c.elements[0].type === 'form') {
						return true;
					}
					return false;
				});

				if (formCol) {
					selectedCol = formCol;
					return true;
				}
				return false;
			});

			LandingpageEditor.rootData.selectedColumn = selectedCol;
			LandingpageEditor.rootData.selectedRowId = LandingpageEditor.rootData.selectedRow.id;
			LandingpageEditor.rootData.selectedColumnId = selectedCol.id;

			onSetToolTab('column');
			LandingpageEditor.rootData.thankUPaige = true;
			LandingpageEditor.rootData.submitted = true;
			LandingpageEditor.rootData.scrollToForm = true;
			return;
		}

		beforeSave(LandingpageEditor.rootData.form.name, form)
			.then(function (data) {
				LandingpageEditor.rootData.saving = true;
				form.name = data.name;
				form.internalName = data.internalName;

				// Set default btn text
				form.buttonText = form.buttonText || $translate.instant('mail.send');

				$templateParser.parseDist(
					form,
					LandingpageEditor.rootData.accountProfile,
					{
						optIns: meta.optIns.data,
						liveTags: liveTags,
						isSocialEvent: params.isSocialEvent,
						domains: meta.domains.data
					},
					function (err, html) {
						form.html = html;

						Form.save(form)
							.then(function (res) {
								localStorage.removeItem('forms.internalName');
								allowStateChange = true;
								// Go to form
								if (isModal) {
									$scope.resolve(res.data);
								} else {
									window.Tools.routerHistory.push(
										'/form-overview/' + res.data.id + '?tab=publishingOptions'
									);
								}
								LandingpageEditor.rootData.saving = false;
							})
							.catch(function () {
								allowStateChange = true;
								LandingpageEditor.rootData.saving = false;
							});
					}
				);
			})
			.catch(e => {
				if (e !== 'CancelledFromModal') {
					logError(e, 'Failed to save landing page');
				}
			});
	};

	var onSetupProfile = function () {
		$state.go('administration.accountProfile');
	};

	var onOpenSettings = function () {
		// eslint-disable-next-line promise/catch-or-return
		$upModal
			.open('landingpageSettings', {
				profileColors: LandingpageEditor.profileColors,
				form: LandingpageEditor.rootData.form
			})
			.then(function (form) {
				LandingpageEditor.rootData.form = form;
				parseAll();
			});
	};

	var onToggleFormPreview = function (type) {
		if (type === 'form') {
			LandingpageEditor.rootData.thankUPaige = false;
		} else {
			LandingpageEditor.rootData.thankUPaige = true;
		}
		$safeApply($scope);
	};

	var onSetThankYouElem = function (type) {
		LandingpageEditor.rootData.form.thankYouElement = $templateParser.getElementConfig(type);
		parseAll();
	};

	var onThankUChange = function (element) {
		LandingpageEditor.rootData.form.thankYouElement = element;
		parseAll();
	};

	var onThankYouTitleTextChange = function (field, value) {
		switch (field) {
			case 'thankYouTitle':
			case 'thankYouText':
				LandingpageEditor.rootData.form[field] = value;
				(() => {
					parseAll();
				})();
				break;
		}
	};

	var onClearThankPage = function () {
		LandingpageEditor.rootData.form.thankYouElement = null;
		parseAll();
	};

	var onMoveRow = function (direction, rowId) {
		if (direction === 'down') {
			LandingpageEditor.rootData.form.landingPageBody = $templateParser.moveRowDown(
				LandingpageEditor.rootData.form.landingPageBody,
				rowId || LandingpageEditor.rootData.selectedRowId
			);
		} else {
			LandingpageEditor.rootData.form.landingPageBody = $templateParser.moveRowUp(
				LandingpageEditor.rootData.form.landingPageBody,
				rowId || LandingpageEditor.rootData.selectedRowId
			);
		}
		parseAll();
	};

	var onSelectField = function () {
		// eslint-disable-next-line promise/catch-or-return
		$upModal
			.open('selectField', { availableFields: availableFields, availableOptIns: getAvailableOptIns() })
			.then(function (field) {
				if (field.type === 'extra') {
					field.name = 'Extra.' + Date.now();
				}
				field.required = false;
				field.sort = LandingpageEditor.rootData.form.fields.length + 1;

				if (field.datatype === 'optIn') {
					var optIn = _.first(LandingpageEditor.rootData.getAvailableOptIns(field.optInType));
					field.title = optIn.title;
					field.options = String(optIn.id);
					field.required = optIn.type === 'double';
				} else if (field.type === 'standard') {
					field.title = $translate.instant('form.' + field.name);
				} else if (field.type !== 'cf') {
					field.title = $translate.instant(
						'datatype.' + (field.datatype.charAt(0).toUpperCase() + field.datatype.slice(1))
					);
				}
				LandingpageEditor.rootData.form.fields.push(field);
				onFormChange(LandingpageEditor.rootData.form);
			});
	};

	var previewInNewWin = function () {
		$templateParser.parseDist(
			LandingpageEditor.rootData.form,
			LandingpageEditor.rootData.accountProfile,
			{
				isPreview: true,
				noTags: true,
				optIns: meta.optIns.data,
				liveTags: liveTags,
				isSocialEvent: params.isSocialEvent
			},
			function (err, html) {
				var toggleStyle =
					' style="width: 50%; height: 40px; line-height: 40px; background-color: #fff; border: 1px solid #ccc; color: #888;"';
				var toggles =
					'<div style="position: absolute; width: 100%; z-index: 10000; top: 0; left: 0; font-family: sans-serif;"><button type="button"' +
					toggleStyle +
					' onClick=document.getElementById("preview-iframe").style.width="100%"><b class="fa fa-desktop"></b> ' +
					$translate.instant('ads.desktop') +
					'</button>';
				toggles +=
					'<button type="button"' +
					toggleStyle +
					' onClick=document.getElementById("preview-iframe").style.width="400px"><b class="fa fa-mobile" style="margin-right: 3px;"></b>' +
					$translate.instant('default.phone') +
					'</button></div>';
				var preview = window.open(
					'',
					'',
					'height=768,width=1024,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
				);
				html = html.replace(
					'class="template-scoped-style" style="',
					'class="template-scoped-style" style="margin-top:30px;'
				);
				if (preview) {
					if (typeof preview.document.write === 'function') {
						preview.document.write(
							toggles +
								'<div style="width: 100%; height: 100%; display: flex; justify-content: center; position: absolute; left: 0;"><iframe id="preview-iframe" style="width: 100%; height: 100%;"></iframe></div>'
						);
						const iframe = preview.document.getElementById('preview-iframe');
						const script = preview.document.createElement('script');
						script.src = 'https://use.fontawesome.com/01a8d84c9a.js';
						preview.document.head.appendChild(script);
						iframe.contentWindow.document.open();
						iframe.contentWindow.document.write(html);
						iframe.contentWindow.document.close();
					} else {
						preview.close();
					}
				}
			}
		);
	};

	var addPreset = function (color) {
		LandingpageEditor.rootData.accountProfile.extraColors.push({ value: color });
		return AccountProfile.save(LandingpageEditor.rootData.accountProfile);
	};

	LandingpageEditor.rootData = {
		saving: false,
		selectedRowId: null,
		selectedColumnId: null,
		selectedRow: null,
		selectedColumn: null,
		toolTab: 'column',
		html: '',
		templates: [],
		editMode: false,
		thankUPaige: false,
		accountCustomFields: [],
		contactCustomFields: [],
		onSelectRow: onSelectRow,
		onSetToolTab: onSetToolTab,
		onDeselectColumn: onDeselectColumn,
		onRowBgChange: onRowBgChange,
		onClearColumn: onClearColumn,
		onDeleteRow: onDeleteRow,
		onSetColumnType: onSetColumnType,
		onAddRow: onAddRow,
		onElementChange: onElementChange,
		onPaddingChange: onPaddingChange,
		onPaddingChangeRow: onPaddingChangeRow,
		onTemplateSelect: onTemplateSelect,
		onFormChange: onFormChange,
		onEditAction: onEditAction,
		onFieldsChange: onFieldsChange,
		onSetupProfile: onSetupProfile,
		onOpenSettings: onOpenSettings,
		onSave: onSave,
		onToggleFormPreview: onToggleFormPreview,
		onSetThankYouElem: onSetThankYouElem,
		onThankUChange: onThankUChange,
		onThankYouTitleTextChange,
		onClearThankPage: onClearThankPage,
		onRowElementChange: onRowElementChange,
		onMoveRow: onMoveRow,
		onSelectField: onSelectField,
		transparentHeaderChanged: transparentHeaderChanged,
		toggleHeaderVisibility: toggleHeaderVisibility,
		previewInNewWin: previewInNewWin,
		addPreset: addPreset,
		isModal: isModal,
		strictTagList: params.strictTagList,
		hasVerifyDomainAccess: false,
		verifiedDomains: _.filter(meta.domains.data, { verified: true }),
		abort: function () {
			if (isModal) {
				$scope.reject();
			}
		},
		saveBtnText: $translate.instant(isModal ? params.saveBtnText || 'default.save' : 'default.save'),
		isSocialEvent: params.isSocialEvent
	};

	LandingpageEditor.rootData.getAvailableOptIns = function (type) {
		return _.filter(meta.optIns.data, function (optIn) {
			return (
				!_.includes(_.pluck(LandingpageEditor.rootData.fields, 'options'), String(optIn.id)) &&
				optIn.type === type
			);
		});
	};

	function parseAll(cb) {
		$safeApply($scope);
		$templateParser.parse(
			LandingpageEditor.rootData.form,
			LandingpageEditor.rootData.accountProfile,
			{ optIns: meta.optIns.data, liveTags: liveTags, isSocialEvent: params.isSocialEvent },
			function (err, html) {
				LandingpageEditor.rootData.html = html;
				currentHash = Tools.LZString.compressToBase64(JSON.stringify(html));
				$safeApply($scope);
				if (cb && typeof cb === 'function') {
					cb();
				}
			}
		);
	}

	var getResumeState = function (stateObj) {
		return function (mergeParams) {
			var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

			$state.go(stateObj.state.name, stateParams, stateObj.options);
		};
	};

	var translateCfDatatype = function (cf) {
		if (!cf.datatype || cf.datatype === 'String') {
			return 'text';
		} else if (cf.datatype === 'Date') {
			return 'date';
		} else if (cf.datatype === 'Email') {
			return 'email';
		} else if (cf.datatype === 'Currency' || cf.datatype === 'Integer') {
			return 'number';
		} else if (cf.datatype === 'Boolean') {
			return 'checkbox';
		} else if (cf.datatype === 'Select') {
			return 'select';
		} else if (cf.datatype === 'User') {
			return 'user';
		} else if (cf.datatype === 'Users') {
			return 'users';
		} else if (cf.datatype === '') {
			return 'textarea';
		}
		return 'text';
	};

	var getCfOptions = function (cf) {
		if (!cf['default'] || !Array.isArray(cf['default'])) {
			return null;
		}
		return cf['default'].map(encodeURIComponent).join(',');
	};

	// Init function for this page
	var init = function () {
		LandingpageEditor.rootData.form = meta.form.data;
		LandingpageEditor.rootData.fields = meta.form.data.fields;
		if (LandingpageEditor.rootData.form.hasOwnProperty('socialMediaTags') === false) {
			LandingpageEditor.rootData.form.socialMediaTags = null;
		}

		LandingpageEditor.rootData.accountProfile = meta.accountProfile.data;
		LandingpageEditor.rootData.optIns = meta.optIns.data;
		LandingpageEditor.rootData.hasVerifyDomainAccess = FeatureHelper.isAvailable(
			FeatureHelper.Feature.REQUIRE_BUSINESS_EMAIL
		);

		if (LandingpageEditor.rootData.form.id || params.copy) {
			LandingpageEditor.rootData.editMode = true;
			parseAll(function () {
				initialHash = currentHash;
			});
		} else if (params.templateName && meta.template) {
			onTemplateSelect(meta.template);
		}

		var accountCustomFields = AppService.getCustomFields('account');
		LandingpageEditor.rootData.accountCustomFields = accountCustomFields;

		if (accountCustomFields.length) {
			// add client customFields to select from
			accountCustomFields.forEach(function (cf) {
				if (!cf.editable) {
					return;
				}
				var tag = 'Client.custom_' + cf.id;
				clientFields.push({
					name: tag,
					title: cf.name,
					datatype: translateCfDatatype(cf),
					type: 'cf',
					options: getCfOptions(cf),
					disabled: false
				});
			});
		}

		var contactCustomFields = AppService.getCustomFields('contact');
		LandingpageEditor.rootData.contactCustomFields = contactCustomFields;

		if (contactCustomFields.length) {
			// add contact custom fields to select from
			contactCustomFields.forEach(function (cf) {
				if (!cf.editable) {
					return;
				}
				var tag = 'Contact.custom_' + cf.id;
				clientFields.push({
					name: tag,
					title: cf.name,
					datatype: translateCfDatatype(cf),
					type: 'cf',
					options: getCfOptions(cf),
					disabled: false
				});
			});
		}

		// Setup meta for action modals
		modalMeta = {
			accountCategories: AppService.getCategories('account'),
			accountCustomFields: accountCustomFields,
			contactCategories: AppService.getCategories('account'),
			contactCustomFields: contactCustomFields,
			activityCustomFields: AppService.getCustomFields('activity'),
			// campaigns: meta.campaigns.data,
			// leadSources: meta.leadSources.data,
			activeUsers: AppService.getActiveUsers(),
			roles: AppService.getRoles(),
			activityTypes: AppService.getActivityTypes('activity', true)
		};

		// Get all colors from the accountProfile
		var colorVariables = [
			{ title: $translate.instant('admin.colors'), colors: {} },
			{ title: $translate.instant('admin.extraColors'), colors: {} }
		];

		if (LandingpageEditor.rootData.accountProfile) {
			angular.forEach(LandingpageEditor.rootData.accountProfile.colors, function (value) {
				colorVariables[0].colors[value] = value;
			});
			angular.forEach(LandingpageEditor.rootData.accountProfile.extraColors, function (color) {
				colorVariables[1].colors[color.value] = color.value;
			});
		}

		LandingpageEditor.profileColors = colorVariables;

		fixFields();
		updateAvailableFields();

		LandingpageEditor.rootData.templates = meta.templates.data;

		var showWarning = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				return new Promise((resolve, reject) => {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								reject();
								return;
							}
							if (confirmed) {
								await onSave();
							}
							resolve();
						}
					});
				});
			}

			return $upModal.open('warningConfirm', {
				title: 'confirm.abortEdit',
				body: 'confirm.changesWillBeLost',
				resolveTrue: 'default.abortEdit',
				no: 'default.continueEdit'
			});
		};

		if (isModal) {
			$scope.$on('modal.rejected', function (e) {
				if (!allowStateChange && initialHash !== currentHash) {
					e.preventDefault();
					// eslint-disable-next-line promise/catch-or-return
					showWarning().then(function () {
						allowStateChange = true;
						$scope.reject();
						if (e.state) {
							e.resumeState();
						}
					});
				}
			});
		} else {
			$scope.$on(
				'$stateChangeStart',
				function (event, toState, toStateParams, fromState, fromStateParams, options) {
					if (!allowStateChange && initialHash !== currentHash) {
						event.preventDefault();
						// eslint-disable-next-line promise/catch-or-return
						showWarning().then(function () {
							var stateObj = {
								state: toState,
								stateParams: toStateParams,
								options: { ...options, location: true }
							};
							allowStateChange = true;
							getResumeState(stateObj)();
						});
					}
				}
			);
		}
	};

	AppService.loadedPromise.then(init).catch(e => logError(e, e.message));
};

angular.module('domain.form').controller('LandingpageEditor', [
	'$scope',
	'AppService',
	'$templateParser',
	'$upModal',
	'$translate',
	'$safeApply',
	'Form',
	'$state',
	'$stateParams',
	'FeatureHelper',
	'NotificationService',
	'$q',
	'AccountProfile',
	'meta',
	function (
		$scope,
		AppService,
		$templateParser,
		$upModal,
		$translate,
		$safeApply,
		Form,
		$state,
		$stateParams,
		FeatureHelper,
		NotificationService,
		$q,
		AccountProfile,
		meta
	) {
		landingpageEditorCtrl(
			this,
			false,
			$scope,
			AppService,
			$templateParser,
			$upModal,
			$translate,
			$safeApply,
			Form,
			$state,
			FeatureHelper,
			NotificationService,
			$q,
			AccountProfile,
			meta,
			$stateParams
		);
	}
]);

angular.module('domain.form').controller('LandingpageEditorModal', [
	'$scope',
	'AppService',
	'$templateParser',
	'$upModal',
	'$translate',
	'$safeApply',
	'Form',
	'$state',
	'FeatureHelper',
	'NotificationService',
	'$q',
	'AccountProfile',
	'$modalParams',
	function (
		$scope,
		AppService,
		$templateParser,
		$upModal,
		$translate,
		$safeApply,
		Form,
		$state,
		FeatureHelper,
		NotificationService,
		$q,
		AccountProfile,
		$modalParams
	) {
		landingpageEditorCtrl(
			this,
			true,
			$scope,
			AppService,
			$templateParser,
			$upModal,
			$translate,
			$safeApply,
			Form,
			$state,
			FeatureHelper,
			NotificationService,
			$q,
			AccountProfile,
			$modalParams.meta,
			$modalParams
		);
	}
]);

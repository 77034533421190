'use strict';

angular.module('domain.advancedSearch').directive('accountContactinfoSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/accountContactinfoSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					$scope.inited = false;
					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					var init = function () {
						$scope.Filters.Webpage.comparisonType = 'Wildcard';
						$scope.Filters.Phone.comparisonType = 'Wildcard';
						$scope.Filters.Fax.comparisonType = 'Wildcard';

						$scope.show = FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						$scope.showFax =
							!Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS) ||
							Tools.AppService.getMetadata().standardFields.Client.Fax.active;

						$scope.inited = true;
					};
					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);

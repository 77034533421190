'use strict';

angular.module('domain.account').factory('MergeClientsMeta', [
	'$q',
	'Account',
	function ($q, Account) {
		return function ($modalParams) {
			var customerId = $modalParams.customerId;
			var promises = {
				account: Account.customer(customerId).get($modalParams.id)
			};

			return $q.all(promises).then(
				function (results) {
					return results;
				},
				function (err) {
					return $q.reject(err);
				}
			);
		};
	}
]);

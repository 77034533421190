'use strict';

angular.module('upAttributes').service('ProductAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true
					// inputType: 'text',
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: false,
					selectableColumn: true,
					filterable: false
				},
				userId: {
					title: 'default.user',
					field: 'userId',
					type: FilterType.Number,
					displayType: DisplayType.RefObject,
					filterName: 'userId',
					refParams: {
						type: 'user',
						field: 'name',
						identifyer: 'id',
						refType: 'appointment'
					},
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					groupable: true,
					sortable: false,
					selectableColumn: true,
					filterable: true
				},
				categoryId: {
					field: 'category.id',
					type: FilterType.Number
				},
				active: {
					field: 'active',
					type: FilterType.Number
				},
				sortId: {
					field: 'sortId',
					type: FilterType.Number
				},
				tiers: {
					title: 'default.tiers',
					field: 'tier.start',
					parent: 'tiers',
					attr: {
						start: {
							field: 'tier.start',
							type: FilterType.Number
						}
					}
				},
				articleNo: {
					field: 'articleNo',
					type: FilterType.String
				}
			};

			return {
				getAll: function () {
					return attributes;
				}
			};
		};
	}
]);

import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import Client from 'App/resources/Model/Client';
import Activity from 'App/resources/Model/Activity';
import ActivityResource from 'Resources/Activity';
import { Checkbox, Column, Flex, Icon, IconName, Text, Tooltip } from '@upsales/components';
import { onClick, setTypeProps } from 'App/pages/TodoList/TodoListTableRow/TodoListTableRow';
import SimpleListView from '../../SimpleListView';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import Todo from 'App/resources/Model/Todo';
import './Activities.scss';
import { openDrawer } from 'Services/Drawer';
import Attribute, { DisplayType, Type } from 'App/babel/attributes/Attribute';
import { getContactLink } from 'App/pages/TodoList/TodoListTableRow/TodoListColumns';
import TodoListTableRowQuickActions from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowQuickActions';
import Button from '@upsales/components/Button/Button';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import Mail from 'App/resources/Model/Mail';
import CreateTaskButton from 'App/components/CreateTaskButton';

const COLUMNS = ['date', 'description', 'iconCol', 'type', 'contacts', 'users', 'todoTools'];

type TodoOrMail = Todo &
	OptionalPropGroup<{
		subject: string;
		icon: IconName;
		to: string;
		mailType: string;
	}> & {
		endDate?: string | Date;
	};

const activityToTodo: (a: Activity | Mail) => TodoOrMail = activity => {
	const todo = {
		...activity,
		type: 'appointment',
		hot: false,
		weblinkUrl: null,
		location: null
	} as TodoOrMail;

	const todoTypes = Tools.AppService.getTodoTypes();
	const activityTypes = Tools.AppService.getActivityTypes('activity');

	if ('to' in activity) {
		todo.description = todo.subject!;
		todo.time = moment(todo.date).format('HH:mm:ss') ?? null;
		todo.mailType = activity.type;
		todo.icon = 'envelope';
		todo.type = 'legacy';
	} else if (activity.activityType.id === todoTypes.TODO.id) {
		todo.type = 'todo';
	} else if (activity.activityType.id === todoTypes.PHONE_CALL.id) {
		todo.type = 'phonecall';
	} else if (activityTypes.map(a => a.id).includes(activity.activityType.id)) {
		todo.icon = 'activity';
		todo.type = 'legacy';
	}
	return todo;
};

const generateGetDataFn =
	(open: boolean) =>
	(
		requestBuilder: RequestBuilder,
		clientId: number
	): Promise<{
		data: Todo[];
		metadata: {
			total: number;
		};
	}> => {
		const resource = Tools.ActivityList;

		requestBuilder.addSort(resource.attr.date, false);
		requestBuilder.addSort(resource.attr.id, false);
		requestBuilder.addFilter(resource.attr.client.attr.id, ComparisonTypes.Equals, clientId);
		requestBuilder.addFilter(resource.attr.projectPlan.attr.id, ComparisonTypes.Equals, null);

		const orBuilder = requestBuilder.orBuilder();
		orBuilder.next();
		orBuilder.addFilter(resource.attr.isAppointment, ComparisonTypes.Equals, false);
		orBuilder.addFilter(resource.attr.closeDate, open ? ComparisonTypes.Equals : ComparisonTypes.NotEquals, null);
		orBuilder.next();
		orBuilder.addFilter(resource.attr.isAppointment, ComparisonTypes.Equals, true);
		orBuilder.addFilter(
			resource.attr.endDate,
			open ? ComparisonTypes.GreaterThanEquals : ComparisonTypes.LessThan,
			new Date()
		);

		// Sent emails
		if (!open) {
			orBuilder.next();
			orBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, false);
			orBuilder.next();

			let groupBuilder = orBuilder.groupBuilder();
			groupBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, true);
			groupBuilder.addFilter({ field: 'groupMailId' }, ComparisonTypes.Equals, null);
			groupBuilder.done();
			orBuilder.next();
			groupBuilder = orBuilder.groupBuilder();
			groupBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, true);
			groupBuilder.addFilter({ field: 'groupMailId' }, ComparisonTypes.Equals, 0);
			groupBuilder.done();
		}
		orBuilder.done();

		const built = requestBuilder.build();
		return resource.find(built).then(res => ({ ...res, data: res.data.map(activityToTodo) }));
	};

const isOverdue = (todo: TodoOrMail) => {
	const date = todo.endDate ?? todo.date;
	if (todo.type === 'appointment' || todo.time) {
		return moment(date).isBefore(moment());
	}
	return moment(date).isBefore(moment().subtract(1, 'days').endOf('day'));
};

const getDateFlags = (item: TodoOrMail) => {
	const hasDate = !!item.date;
	const future = hasDate && !isOverdue(item);
	const closed = !!item.closeDate || (item.type === 'appointment' && !future);
	const isMail = 'to' in item;
	return { hasDate, future, closed, isMail };
};

const getMailTitle = (item: TodoOrMail) => {
	switch (item.mailType) {
		case 'in':
			return T('mail.mailIn');
		case 'out':
			return T('mail.mailOut');
		case 'err':
			return `(${T('mail.status.ERROR')})`;
	}
	return T('mail.mail');
};

const customColumnRenders: GetProp<typeof SimpleListView<TodoOrMail>, 'customColumnRenders'> = {
	date: item => {
		const { future, hasDate, closed, isMail } = getDateFlags(item);
		const typeProps = setTypeProps(item);
		return (
			<Text color={future ? 'black' : hasDate ? 'red' : 'grey-9'} size="sm" italic={closed || !hasDate || isMail}>
				{typeProps.timeSubTitle || T('default.noDate')}
			</Text>
		);
	},
	description: item =>
		item.description.length < 34 ? (
			<Text size="sm">{item.description}</Text>
		) : (
			<Tooltip title={item.description ?? ''} disabled={item.description.length < 31}>
				<Text size="sm">{item.description.slice(0, 30) + '...'}</Text>
			</Tooltip>
		),
	type: item => {
		const { isMail } = getDateFlags(item);
		const typeProps = setTypeProps(item);
		return (
			<Column>
				<Text size="sm">
					{isMail ? getMailTitle(item) : typeProps.isTodo ? T('default.todo') : item.activityType.name}
				</Text>
				{item.notes ? (
					<Tooltip
						title={item.notes.slice(0, 400) + (item.notes.length > 400 ? '...' : '')}
						position="left"
						disabled={item.notes.length < 21}
					>
						<Text color="grey-10" italic>
							{item.notes.slice(0, 20) + (item.notes.length > 20 ? '...' : '')}
						</Text>
					</Tooltip>
				) : null}
			</Column>
		);
	},
	contacts: item =>
		getContactLink(item.contacts) ?? (
			<Text size="sm" italic color="grey-9">
				{T('default.noContact')}
			</Text>
		),
	todoTools: item => {
		const { closed, isMail } = getDateFlags(item);
		const typeProps = setTypeProps(item);
		return !isMail ? (
			<Flex alignItems="center" justifyContent="flex-end">
				{closed || item.type === 'appointment' ? null : (
					<Tooltip
						title={T(
							item.priority === 3
								? typeProps.isTodo
									? 'todo.prioTodo'
									: 'todo.prioCall'
								: 'activity.markAsImportant'
						)}
					>
						<Button
							type="link"
							color={item.priority === 3 ? 'red' : 'grey'}
							onClick={e => {
								e?.stopPropagation();
								ActivityResource.save({ id: item.id, priority: item.priority === 3 ? 0 : 3 });
							}}
						>
							<Icon name="flag" />
						</Button>
					</Tooltip>
				)}
				<TodoListTableRowQuickActions todo={item} />
			</Flex>
		) : null;
	},
	iconCol: item => {
		const typeProps = setTypeProps(item);
		if (item.type === 'legacy') {
			typeProps.icon = item.icon!;
		}

		return typeProps.isTodo ? (
			<Checkbox
				size="xs"
				checked={!!item.closeDate}
				onClick={e => {
					e.stopPropagation();
					ActivityResource.save({ id: item.id, closeDate: item.closeDate ? null : new Date() });
				}}
			/>
		) : (
			<Icon color="grey-13" name={typeProps.icon || ('' as IconName)} />
		);
	}
};

// For some attributes we only care about setting the column title
const TitleAttribute = (title: string = '', sortable: string | false = false) =>
	Attribute({
		field: '',
		title,
		type: Type.String,
		sortable
	});

const ClientCardActivityListView = ({ client, open }: { client: Client; open: boolean }) => {
	const classes = new BemClass('ClientCardActivities');
	return (
		<SimpleListView<Todo>
			classes={classes.mod(open ? 'open' : 'past')}
			getData={(rb: RequestBuilder) => generateGetDataFn(open)(rb, client.id)}
			columns={COLUMNS}
			customColumnRenders={customColumnRenders}
			renderNoData={() => <DefaultNoData subtitle="" formatNoData={() => T('default.noResults')} />}
			onRowClick={item => () => {
				const { isMail } = getDateFlags(item);
				if (isMail) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
						openDrawer('SentMail', { mail: { id: item.id } });
					} else {
						Tools.$upModal.open('sentMail', { id: item.id });
					}
				} else {
					onClick(item);
				}
			}}
			rowStyles={{
				inactive: () => !open,
				delayed: item => {
					const { hasDate, future, closed, isMail } = getDateFlags(item);
					return !closed && !future && hasDate && !isMail;
				}
			}}
			initialSorting={[{ attribute: 'date', ascending: false }]}
			onChange={prov => setTimeout(() => prov.reloadTable({ silent: true }), 1200)}
			broadcastType={['activity', 'appointment']}
			attributes={{
				users: Attribute({
					field: 'users',
					title: 'default.users',
					placeholder: 'default.noUser',
					type: Type.Array,
					displayType: DisplayType.Users,
					sortable: 'user.name'
				}),
				date: TitleAttribute('default.date', 'date'),
				iconCol: TitleAttribute(''),
				type: TitleAttribute('default.type', 'activityType.name'),
				todoTools: TitleAttribute(''),
				description: TitleAttribute('default.description', 'description'),
				contacts: TitleAttribute('default.contact', 'contact.name')
			}}
			renderHeader={provided => (
				<Flex className={classes.elem('header').b()} justifyContent="space-between">
					<Flex gap="u2">
						<Text className={classes.elem('headerTitle').b()} size="xl" bold>
							{open ? T('default.openActivities') : T('default.history')}
						</Text>
						<Text className={classes.elem('headerAmount').b()} size="lg" color="grey-10">
							({provided.total})
						</Text>
					</Flex>
					{open ? <CreateTaskButton size="sm" createArgs={{ client }} /> : null}
				</Flex>
			)}
		/>
	);
};

const ClientCardActivities = ({ client }: { client: Client }) => {
	if (!client) {
		return null;
	}

	return (
		<>
			<ClientCardActivityListView client={client} open={true} />
			<ClientCardActivityListView client={client} open={false} />
		</>
	);
};

export default ClientCardActivities;

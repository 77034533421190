import PropTypes from 'prop-types';
import React from 'react';
import './DummyComponent.scss';

const propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	options: PropTypes.array
};

class DummyComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedValue: '',
			iteration: 0
		};
	}

	selectItem(value) {
		const state = Object.assign({}, this.state);

		state.selectedValue = value;
		state.iteration += 1;

		this.setState(state);
	}

	generateSelectList(options) {
		const opts = [];

		for (let i = 0; i < options; i++) {
			opts.push(options[i]);
		}

		return (
			<select
				onChange={event => {
					this.selectItem(event.target.value);
				}}
			>
				{opts}
			</select>
		);
	}
	render() {
		return (
			<div className={`dummy ${this.props.className}`}>
				<h2>{this.props.title}</h2>

				{this.generateSelectList(this.props.options)}

				<p>Current iteration: {this.state.iteration}</p>
				<p className="selected">Current selection: {this.state.selectedValue}</p>
			</div>
		);
	}
}

DummyComponent.propTypes = propTypes;
export default DummyComponent;

// TODO: The contents of this file can be moved to AdminRoutes once all routes are ported to React and we have refactored sidebar.jsx
import { lazy } from 'react';

type Route = {
	path: string;
	hasAccess: boolean;
	Component?: any;
	oldRouteState?: string;
};

const AdminNotifications = lazy(() => import('./subPages/AdminNotifications'));
const AdminEmailSignatures = lazy(() => import('./subPages/AdminEmailSignatures'));
const AdminDashboard = lazy(() => import('./subPages/AdminDashboard'));
const AdminTriggers = lazy(() => import('./subPages/AdminTriggers'));
const AdminProfile = lazy(() => import('./subPages/AdminProfile'));

const portedAdminRoutes = () => {
	const hasEmailSignaturesAccess = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_EMAIL_SIGNATURES');
	const hasNotificationsAccess = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_NOTIFICATIONS');
	const hasDashboardAccess = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_DASHBOARD');
	const hasTriggersAccess = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_TRIGGERS');
	const hasProfileAccess = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ADMIN_PROFILE');

	const routes = [
		{
			path: '/admin/dashboard',
			Component: AdminDashboard,
			hasAccess: hasDashboardAccess,
			oldRouteState: 'dashboard'
		},
		{
			path: '/admin/notifications',
			Component: AdminNotifications,
			hasAccess: hasNotificationsAccess,
			oldRouteState: 'notificationCenter'
		},
		{
			path: '/admin/profile',
			Component: AdminProfile,
			hasAccess: hasProfileAccess,
			oldRouteState: 'profile'
		},
		{
			path: '/admin/email-signatures',
			Component: AdminEmailSignatures,
			hasAccess: hasEmailSignaturesAccess,
			oldRouteState: 'defaultMailSignature'
		},
		{
			path: '/admin/triggers',
			Component: AdminTriggers,
			hasAccess: hasTriggersAccess,
			oldRouteState: 'triggers'
		}
	].filter(route => route.hasAccess);

	const mappedRoutes = routes.reduce<{ [key: string]: Route }>((mapped, route) => {
		if (route.oldRouteState) {
			mapped[route.oldRouteState] = route;
		}
		return mapped;
	}, {});

	return { routes, mappedRoutes };
};

export default portedAdminRoutes;

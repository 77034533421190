import {
	getUsersByTag,
	prepDates,
	prepMultiSelect,
	getAvailableFields,
	formatDateType,
	parseDate,
	attachScopeFunctions,
	executeSave
} from 'App/babel/helpers/multiModals';

angular.module('domain.order').controller('UpdateOrderMulti', [
	'$scope',
	'$modalParams',
	'$timeout',
	'ActionProperties',
	'$multiSelect',
	'AppService',
	'FeatureHelper',
	function ($scope, $modalParams, $timeout, ActionProperties, $multiSelect, AppService, FeatureHelper) {
		var arrayProperties = [];
		const isOpportunity = $modalParams.entity === 'Opportunity';

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isTrigger = $modalParams.isTrigger;

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		const getOrderCustomStagesRequiredFields = (allFields, selected) => {
			return _.filter(allFields, field => {
				if (!field.editable) {
					return false;
				}
				if (field.obligatoryField) {
					return field;
				}
				return _.find(field.stages, stage => {
					return stage.id === selected.id && stage.required;
				});
			}).map(field => field.name);
		};

		const getRequiredFields = (allFields = []) => {
			return allFields.filter(field => field.editable && field.obligatoryField).map(field => field.name);
		};

		// Keep track of selected stage probability
		$scope.stageChange = function () {
			$timeout(() => {
				$scope.hasRequiredFields = false;
				if (!$scope.properties.Stage) {
					$scope.properties.Probability = null;
				} else {
					var selected = _.find($scope.stages, { id: parseInt($scope.properties.Stage) });
					if (selected) {
						$scope.properties.Probability = selected.probability;

						const hasOrderCustomStages = FeatureHelper.isAvailable(
							FeatureHelper.Feature.ORDER_CUSTOM_STAGES
						);

						if (hasOrderCustomStages) {
							$scope.hasRequiredFields = getOrderCustomStagesRequiredFields(
								$scope.availableFields,
								selected
							);
							$scope.hasRequiredOrderRowFields = getOrderCustomStagesRequiredFields(
								Tools.AppService.getCustomFields('orderrow'),
								selected
							);
						} else {
							$scope.hasRequiredFields = getRequiredFields($scope.availableFields);
							$scope.hasRequiredOrderRowFields = getRequiredFields(
								Tools.AppService.getCustomFields('orderrow')
							);
						}
					}
				}
			}, 10);
		};

		$scope.save = () =>
			executeSave({
				scope: $scope,
				modalParams: $modalParams,
				multiSelect: $multiSelect,
				entityType: isOpportunity ? 'Opportunity' : 'Order',
				method: 'updateOrder',
				arrayProperties,
				entityArg: isOpportunity ? 'Opportunity' : 'Order'
			});

		attachScopeFunctions($scope, ['addField', 'removeField', 'setDate', 'createCampaign', 'changeCustomDate']);

		var init = function () {
			const startingFields = $modalParams.meta.orderCustomFields.data;
			const entityName = $modalParams.entity || $modalParams.tagEntity;
			const { dateTags, dateTypes, customDateTypes } = prepDates(entityName);

			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.properties = {};
			$scope.stages = AppService.getStages($modalParams.stageType);

			$scope.availableFields = getAvailableFields($scope, startingFields, customDateTypes);
			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customDateTypes = formatDateType(customDateTypes);

			if ($scope.isTrigger) {
				$scope.dateTypes = formatDateType(dateTypes);
				$scope.date = parseDate($modalParams.properties, $scope.dateTypes.data, 'Date');
				$scope.setDate();
			}

			// Users
			$scope.users = angular.copy(AppService.getActiveUsers());
			$scope.users.unshift(...getUsersByTag($modalParams.tagEntity, true));

			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties);

			// parse custom
			$scope.customFields = [];
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				_.capitalize(entityName),
				dateTags
			);

			$multiSelect = prepMultiSelect($multiSelect, $modalParams.multiSelect);

			$scope.entityText = isOpportunity ? 'default.opportunities' : 'default.orders';
		};

		init();
	}
]);

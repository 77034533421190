import Resource from 'Resources/Resource';

class SalesCoachTitleCategoryRole extends Resource {
	constructor() {
		super('salesCoachTitleCategoryRole');
	}
}

const resource = new SalesCoachTitleCategoryRole();

export default resource;

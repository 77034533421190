import MailTemplateHeader from 'Components/MailTemplateHeader/MailTemplateHeader';

angular.module('upDirectives').directive('upMailTemplateHeader', function () {
	return {
		replace: true,
		template: '<div></div>',
		restrict: 'A',
		link: function ($scope, $element, $attr) {
			var renderTimeout;
			let firstElement = $element[0];

			var render = function () {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(function () {
					if (!firstElement) {
						return;
					}

					ReactDOM.render(
						React.createElement(MailTemplateHeader, $scope.EditMailTemplate),
						firstElement,
						function () {}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$watch(
				$attr.isFullscreen,
				function () {
					render();
				},
				true
			);

			$scope.$watch(
				$attr.template,
				function () {
					render();
				},
				true
			);

			render();
		}
	};
});

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('Currency', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function ($resource, $q, URL, API, ResourceHelper) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'currency',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings'
		});

		var Resource = $resource(
			URL + API + 'currencies/:iso',
			{},
			{
				find: { method: 'GET', isArray: false },
				findAvailable: { method: 'GET', isArray: false, url: URL + API + 'availableCurrencies/' },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			find: function () {
				return Resource.find({}).$promise;
			},

			findAvailable: function () {
				return Resource.findAvailable({}).$promise;
			},

			save: function (currency, options) {
				var defered = $q.defer();

				if (!currency || !currency.iso) {
					return defered.reject('No currency or missing iso');
				}

				Resource.update(
					{ iso: currency.iso },
					{ currency: currency },
					function (response) {
						if (response.error) {
							defered.reject(response);
						}
						defered.resolve(response);
					},
					defered.reject
				);

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, false);
						AllIWantDataCache.clearData();
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, false);
					});

				return defered.promise;
			},

			create: function (currency, options) {
				var defered = $q.defer();

				if (!currency || !currency.iso) {
					return defered.reject('No currency or missing iso');
				}

				Resource.create(
					null,
					{ currency: currency },
					function (response) {
						if (response.error) {
							defered.reject(response);
						}
						defered.resolve(response);
					},
					defered.reject
				);

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, false);
						AllIWantDataCache.clearData();
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, false);
					});

				return defered.promise;
			},

			delete: function () {
				if (console) {
					console.warn('missing this feature');
				}
			}
		};
		return Model;
	}
]);

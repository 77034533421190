import React from 'react';
import PropTypes from 'prop-types';

import StepPreview from './StepPreview';
import StepContactTable from '../Components/StepContactTable';

export default function ContactTablePreview(props) {
	return (
		<StepPreview {...props}>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={props.stats}
				types={['currentlyInStep', 'fallenOutOfStep']}
				flow={props.flow}
			/>
		</StepPreview>
	);
}

ContactTablePreview.propTypes = {
	stats: PropTypes.object.isRequired,
	popup: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired
};

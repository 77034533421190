'use strict';
import AgreementHistory from 'Resources/AgreementHistory';
import logError from 'App/babel/helpers/logError';

angular.module('domain.agreement').factory('EditAgreementMeta', [
	'$q',
	'$translate',
	'RequestBuilder',
	'Account',
	'Order',
	'AppService',
	'Contact',
	'Agreement',
	'CustomfieldMapper',
	'NotificationService',
	function (
		$q,
		$translate,
		RequestBuilder,
		Account,
		Order,
		AppService,
		Contact,
		Agreement,
		CustomfieldMapper,
		NotificationService
	) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				const agreementId = $modalParams.id || $modalParams.copyId || $modalParams.followUpId;

				var promises = {
					users: $q.when({ data: AppService.getActiveUsers() }),
					categories: $q.when({ data: AppService.getProductCategories() }),
					customFields: $q.when({ data: AppService.getCustomFields('agreement') }),
					orderrowCustomFields: $q.when({ data: AppService.getCustomFields('orderrow') }),
					orderCustomFields: $q.when({ data: AppService.getCustomFields('order') }),
					documentTemplates: $q.when({ data: AppService.getDocumentTemplates('agreement') })
				};

				if ($modalParams.contactId) {
					promises.contact = Contact.customer(customerId).get($modalParams.contactId);
				}

				// Get contacts based on accountId
				var getContacts = function (accountId, promise) {
					var contactFilter = new RequestBuilder();
					contactFilter.addFilter(
						Contact.attr.account.attr.id,
						contactFilter.comparisonTypes.Equals,
						accountId
					);
					contactFilter.addFilter(Contact.attr.active, contactFilter.comparisonTypes.Equals, 1);
					contactFilter.addSort(Contact.attr.name, true);

					return Contact.customer(customerId)
						.find(contactFilter.build())
						.then(function (res) {
							if (promise) {
								promise.resolve(res);
							}
							return res;
						});
				};

				const setupAgreementContacts = () => {
					var contactsDef = $q.defer();
					// Promise to get some contacts
					promises.contacts = contactsDef.promise;

					// then get some other stuff
					promises.agreement
						.then(function (res) {
							const clientId = res.data.client?.id || res.data.clientId;
							// Get client Contacts
							getContacts(clientId, contactsDef);
						})
						.catch(e => console.error(e));
				};

				// If this is an edit or copy
				if (agreementId) {
					// Get recurring order
					promises.agreement = Agreement.customer(customerId).get(agreementId);
					setupAgreementContacts();
				} else if ($modalParams.agreementVersionId) {
					promises.agreement = AgreementHistory.get($modalParams.agreementVersionId);
					setupAgreementContacts();
				} else if ($modalParams.createFromOrder) {
					promises.order = Order.customer(customerId).get($modalParams.orderId);
					promises.recurringProducts = promises.order
						.then(function ({ data: order }) {
							const recurringProductIds = order.orderRow.map(row => row.product.id);
							return window.BabelServices.getRecurringProducts(recurringProductIds);
						})
						.catch(e => logError(e, 'Failed getting order using $modalParams.orderId'));
				} else {
					// Get account if we have one
					if ($modalParams.accountId) {
						promises.account = Account.customer(customerId).get($modalParams.accountId);

						// Get all contacts for account
						promises.contacts = getContacts($modalParams.accountId);
					}
				}

				return $q.all(promises).then(
					function (res) {
						var self = AppService.getSelf();
						var metadata = AppService.getMetadata();
						var defaultCurrency =
							_.find(metadata.customerCurrencies, { masterCurrency: true }) ||
							metadata.customerCurrencies[0];

						var stages = AppService.getStages('won');
						res.stages = { data: stages };

						if (agreementId || $modalParams.agreementVersionId) {
							if (!res.agreement.data.currency) {
								res.agreement.data.currency = defaultCurrency.iso;
							}

							res.agreement.data.custom = CustomfieldMapper.parse(
								res.orderCustomFields.data,
								res.agreement.data.custom
							);
							res.agreement.data.metadata.custom = CustomfieldMapper.parse(
								res.customFields.data,
								res.agreement.data.metadata.custom
							);
							res.agreement.data.metadata.temporaryNextOrderDate =
								res.agreement.data.metadata.agreementNextOrderDate;
						} else {
							var today = new Date();
							var renewalDate = moment().add(metadata.params.SubscriptionDefaultPeriod || 12, 'months');

							var agreement = {
								metadata: {
									agreementIntervalPeriod: metadata.params.SubscriptionDefaultInterval || 1,
									periodLength: metadata.params.SubscriptionDefaultPeriod ?? 12,
									agreementStartdate: today,
									agreementInvoiceStartdate: today,
									agreementRenewalDate: renewalDate.toDate(),
									temporaryNextOrderDate: today,
									agreementOrderCreationTime: metadata.params.SubscriptionDefaultCreationTime || 0,
									agreementEnddate: null,
									agreementNotes: ''
								},
								custom: CustomfieldMapper.parse(res.orderCustomFields.data, []),
								description: '',
								user: self,
								orderRow: [{ listPrice: 0, price: 0, quantity: 0 }],
								currency: defaultCurrency.iso,
								currencyRate: defaultCurrency.rate
							};

							// Get account if we have one
							if ($modalParams.accountId) {
								agreement.client = res.account.data;
							} else {
								res.contacts = { data: [] };
							}

							if ($modalParams.contactId && res.contact) {
								agreement.contact = res.contact.data;
							}

							if ($modalParams.createFromOrder) {
								var order = res.order.data;
								agreement.metadata.agreementDescription = order.description;
								agreement.metadata.agreementNotes =
									$translate.instant('agreement.notesFromOrder') + ':\n\n' + order.notes;

								agreement.stage = _.find(AppService.getStages(), { id: order.stage.id });
								agreement.client = order.client;
								agreement.contact = order.contact;
								agreement.project = order.project;
								agreement.currency = order.currency;
								agreement.currencyRate = order.currencyRate;
								agreement.clientConnection = order.clientConnection;
								agreement.createdFromOrderId = order.id;
								if (order.recurringInterval) {
									agreement.metadata.agreementIntervalPeriod = order.recurringInterval;
									agreement.metadata.agreementIntervalPeriodOld = order.recurringInterval;
								}

								agreement.custom = CustomfieldMapper.parse(res.orderCustomFields.data, order.custom);

								agreement.custom = _.map(agreement.custom, cf => {
									if ((!cf.editable || !cf.visible) && cf.value) {
										delete cf.value;
									}
									return cf;
								});

								var recurringOrderProducts = _.filter(order.orderRow, function (row) {
									return _.find(res.recurringProducts, { id: row.product.id });
								});

								if (recurringOrderProducts.length) {
									agreement.orderRow = _.map(recurringOrderProducts, function (row) {
										delete row.id;
										return row;
									});
								}
							}

							res.agreement = { data: agreement };
						}

						res.orderrowCustomFields.data = CustomfieldMapper.parse(res.orderrowCustomFields.data, []);

						if (res.agreement.data.orderRow) {
							res.agreement.data.orderRow = res.agreement.data.orderRow.map(function (row) {
								row.custom = CustomfieldMapper.parse(
									angular.copy(res.orderrowCustomFields.data),
									row.custom
								);
								return row;
							});
						}

						if ($modalParams.copyId || $modalParams.followUpId) {
							res.agreement.data.metadata.agreementNextOrderDate = null;

							res.agreement.data.custom = _.map(res.agreement.data.custom, cf => {
								if ((!cf.editable || !cf.visible) && cf.value) {
									delete cf.value;
								}
								return cf;
							});

							const freshCurrency = metadata.customerCurrencies.find(
								currency => currency.iso === res.agreement.data.currency
							);
							const oldRate = res.agreement.data.currencyRate;
							res.agreement.data.currencyRate = freshCurrency
								? freshCurrency.rate
								: res.agreement.data.currencyRate;

							res.agreement.data.orderRow = _.map(res.agreement.data.orderRow, row => {
								row.listPrice = (row.listPrice * oldRate) / res.agreement.data.currencyRate;
								row.price = (row.price * oldRate) / res.agreement.data.currencyRate;

								delete row.id;
								return row;
							});

							delete res.agreement.data.id;
						}

						if ($modalParams.followUpId) {
							res.agreement.data.metadata.agreementStartdate =
								res.agreement.data.metadata.agreementEnddate;
							res.agreement.data.metadata.agreementInvoiceStartdate =
								res.agreement.data.metadata.agreementEnddate;
							res.agreement.data.metadata.agreementEnddate = undefined;
							res.agreement.data.metadata.agreementRenewalDate = undefined;
							res.agreement.data.isFollowUp = true;
						}

						return res;
					},
					function (err) {
						if (err !== 'abort') {
							NotificationService.addNotification({
								title: 'default.error',
								body: err && err.status === 404 ? 'errorNotFound.agreement' : 'openError.agreement',
								style: 'error',
								icon: 'times'
							});
						}
						return $q.reject(err);
					}
				);
			});
		};
	}
]);

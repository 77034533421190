'use strict';

angular.module('upDirectives').directive('upNotesRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			var root = $element[0];
			var hasNotes = Tools.FeatureHelper.hasSoftDeployAccess('NOTES');

			if (root && hasNotes) {
				window.SetupComponent(
					$scope,
					window.Notes,
					root,
					{ orderId: $scope.$eval($attr.orderId) },
					{ redux: true }
				);
			}
		}
	};
});

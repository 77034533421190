import { FlowStep } from 'App/resources/Model/Flow';
import T from 'Components/Helpers/translate';
import React from 'react';
import FlowStepUserRow from '../FlowStepUserRow';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
	isInvalid: boolean;
};

export const MAX_HEIGHT = 54;

const FlowStepAssign = (props: Props) => {
	return (
		<FlowStepUserRow
			icon={'user-plus'}
			title={T('flow.step.assign')}
			maxHeight={MAX_HEIGHT}
			placeholder={props.editable ? T('flow.step.selectUser') : T('flow.noUser')}
			{...props}
		/>
	);
};

export default FlowStepAssign;

export function determineCompanyDescription(company) {
	const aboutText = company.activityText || company.about;
	let text;
	if (aboutText) {
		text = aboutText.length > 1000 ? aboutText.substr(0, 1000) + '...' : aboutText;
	}
	const companyDescription = text;

	return companyDescription;
}

const termBlacklist = [' AB'];

export function createGoogleSearchURL(company) {
	const baseURL = 'https://www.google.com/search?q=';
	const name = company?.name;
	if (!name) {
		return '';
	}
	const term = termBlacklist.reduce((res, current) => res.replace(current, ''), name);
	return baseURL + encodeURIComponent(term);
}

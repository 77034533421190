export const findRoleUsers = role => {
	const allUsers = Tools.AppService.getUsers();
	const allRoles = Tools.AppService.getRoles();
	return [
		...allUsers.reduce((out, user) => (user.role && user.role.id === role.id ? out.concat(user) : out), []),
		...allRoles.reduce((out, r) => (r.parent && r.parent.id === role.id ? out.concat(findRoleUsers(r)) : out), [])
	];
};

export const findUserRoleInTree = (user, roleTree) => {
	for (var i = 0; i < roleTree.length; i++) {
		if (user.role && user.role.id === roleTree[i].id && roleTree[i].isRole) {
			return roleTree[i];
		} else if (roleTree[i].isRole) {
			const userRole = findUserRoleInTree(user, roleTree[i].children);
			if (userRole) {
				return userRole;
			}
		}
	}
};

export const findUsersInTreeRole = (roleTree, out = []) => [
	...out,
	...(roleTree.children || []).reduce((out, c) => out.concat(c.isRole ? findUsersInTreeRole(c) : c), [])
];

window.quotaHelper = {
	findRoleUsers,
	findUserRoleInTree,
	findUsersInTreeRole
};

// Import dependencies here that needs to be available on the global scope (window)
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import jQuery from 'jquery';
import moment from 'moment-timezone';
import async from 'async';
import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import markdown from 'markdown';
import Raphael from 'raphael';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';
import intlTelInput from 'intl-tel-input';
import ReactCreateClass from 'create-react-class';
import { renderToString, renderToStaticMarkup } from 'react-dom/server';
import colorMappings from '@upsales/components/Utils/colorMappings';
import * as cssjs from 'jotform-css.js';
import classNames from 'classnames';
import { phoneNumberHelper, calculateField, passwordHelper } from '@upsales/common';

window.jQuery = window.$ = jQuery;

// Angular and iframetracker must be loaded with require after jQuery is defined in the window
require('jquery.iframetracker');
const angular = require('angular');
require('angular-bindonce');
require('angular-animate');
require('angular-resource');
require('angular-cookies');
require('angular-sanitize');
require('angular-route');

window.calculateField = calculateField;
window.passwordHelper = passwordHelper;
window.phoneNumberHelper = phoneNumberHelper;
window.angular = angular;
window.async = async;
window.moment = moment;
window.React = React;
window.ReactDOM = ReactDOM;
window.ReactTransitionGroup = TransitionGroup;
window.ReactCSSTransition = CSSTransition;
window._ = _;
window.markdown = markdown.markdown;
window.Raphael = Raphael;
window.WebFont = WebFont;
window.intlTelInput = intlTelInput;
window.PropTypes = PropTypes;
window.ReactCreateClass = ReactCreateClass;
window.renderToString = renderToString;
window.renderToStaticMarkup = renderToStaticMarkup;
window.Colors = colorMappings.mappings;
window.cssjs = cssjs;
window.classNames = classNames;
window.TypedObject = window.Object;

const toJsonReplacer = function (key, value) {
	let val = value;

	if (typeof key === 'string' && key.charAt(0) === '$') {
		val = undefined;
	}

	return val;
};

const oldAngularToJson = angular.toJson;
window.angular.toJson = function (obj, pretty) {
	obj = JSON.parse(JSON.stringify(obj, toJsonReplacer));
	return oldAngularToJson(obj, pretty);
};

require('../bower_components/angular-pdf-viewer/dist/angular-pdf-viewer.min.js');
require('../bower_components/angular-file-upload/dist/angular-file-upload.min.js');
require('../upsales/common/components/ui/select2.js');
require('../upsales/common/directives/select2.js');
require('../bower_components/angular-ui-router/release/angular-ui-router.js');
require('../bower_components/angular-ui-utils/ui-utils.min.js');
require('../bower_components/angular-ui-sortable/sortable.min.js');
require('../bower_components/angular-local-storage/dist/angular-local-storage.js');
require('../bower_components/angular-strap/dist/angular-strap.js');
require('../bower_components/jquery-timepicker-jt/jquery.timepicker.min.js');
window.Highcharts = require('highcharts');
require('highcharts/modules/funnel')(Highcharts);
require('../bower_components/highcharts-ng/dist/highcharts-ng.js');
require('../bower_components/angular-ui-ace/ui-ace.js');
require('../bower_components/mjolnic-bootstrap-colorpicker/dist/js/bootstrap-colorpicker.js');
require('../bower_components/i18n-phonenumbers/dist/i18n.phonenumbers.min.js');
require('upsales-banner/parser.js');
const pdfJs = require('../bower_components/pdfjs-dist/build/pdf.js');
window.PDFJS = pdfJs;

require('../bower_components/jquery-ui/ui/minified/jquery-ui.min.js');
require('../bower_components/sass-bootstrap/dist/js/bootstrap.min.js');
require('../bower_components/typeahead.js/dist/typeahead.jquery.min.js');
require('../bower_components/dropzone/dist/min/dropzone.min.js');
const ace = require('../bower_components/ace-builds/src-min-noconflict/ace.js');
window.ace = ace;
ace.config.setModuleUrl(
	'ace/mode/javascript',
	require('../bower_components/ace-builds/src-noconflict/mode-javascript.js?file')
);
ace.config.setModuleUrl('ace/mode/json', require('../bower_components/ace-builds/src-noconflict/mode-json.js?file'));
ace.config.setModuleUrl('ace/mode/html', require('../bower_components/ace-builds/src-noconflict/mode-html.js?file'));
ace.config.setModuleUrl(
	'ace/theme/cobalt',
	require('../bower_components/ace-builds/src-noconflict/theme-cobalt.js?file')
);
ace.config.setModuleUrl(
	'ace/theme/monokai',
	require('../bower_components/ace-builds/src-noconflict/theme-monokai.js?file')
);
ace.config.setModuleUrl('ace/mode/css', require('../bower_components/ace-builds/src-noconflict/mode-css.js?file'));
ace.config.setModuleUrl(
	'ace/mode/markdown',
	require('../bower_components/ace-builds/src-noconflict/mode-markdown.js?file')
);
ace.config.setModuleUrl(
	'ace/mode/handlebars',
	require('../bower_components/ace-builds/src-noconflict/mode-handlebars.js?file')
);

ace.config.setModuleUrl('ace/mode/css_worker', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict/worker-css.js');
ace.config.setModuleUrl('ace/mode/html_worker', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict/worker-html.js');
ace.config.setModuleUrl(
	'ace/mode/javascript_worker',
	'https://unpkg.com/ace-builds@1.4.12/src-noconflict/worker-javascript.js'
);
ace.config.setModuleUrl('ace/mode/json_worker', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict/worker-json.js');

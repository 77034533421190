import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column, Link } from '@upsales/components';

import t from 'Components/Helpers/translate';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerInfoProgress from '../Components/DrawerInfoProgress';
import StepContactTable from '../Components/StepContactTable';
import StepEmailSubject from '../Components/StepEmailSubject';
import StepDrawer from '../StepDrawer';
import TemplateCard from 'Components/TemplateCard';
import { previewTemplate } from 'App/helpers/mailTemplateHelpers';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'App/babel/helpers/promise';

const COLOR = 'medium-purple';

const getPreviewData = async () => {
	const AccountProfile = getAngularModule('AccountProfile');
	const InkyParser = getAngularModule('InkyParser');

	try {
		const { data: accountProfile } = await AccountProfile.get();
		const { data: style } = await InkyParser.getCss(false);
		if (!accountProfile) {
			return null;
		}
		return { accountProfile, style: style || '' };
	} catch (e) {
		logError(e, 'Failed to get preview data');
		return null;
	}
};

export default function SendEmail(props) {
	const classes = new BemClass('StepDrawer');
	const stats = props.popup.step.stats;
	const [previewData, setPreviewData] = useState(null);
	const getStatsPercentage = num =>
		stats.hasCompletedStepUnique ? Math.min(Math.floor((num / stats.hasCompletedStepUnique) * 100), 100) : 0;
	const statsArray = [
		{
			color: COLOR,
			num: stats.readMail,
			format: num => `${getStatsPercentage(num)}% ${t('mailCampaign.openedMail')}`
		},
		{
			color: 'dark-purple',
			num: stats.clickedMail,
			format: num => `${getStatsPercentage(num)}% ${t('mail.clickedALink')}`
		}
	];

	useEffect(() => {
		const previewRequest = makeCancelable(getPreviewData());
		previewRequest.promise
			.then(profile => setPreviewData(profile))
			.catch(e => logError(e, 'Failed to get accountProfile'));

		return () => {
			previewRequest?.cancel();
		};
	}, []);

	return (
		<StepDrawer {...props} title={t('flow.action')} subtitle={t('mail.sendEmail')} color={COLOR} icon="paper-plane">
			<Card space="ptl prl pbxl pll" className={classes.elem('mail-content').b()}>
				{props.template || props.id ? (
					<StepEmailSubject title={props.template ? props.template.name : props.name} />
				) : null}
				<Row>
					{props.template || props.id ? (
						<Column>
							<TemplateCard
								key={props.id}
								background={props.$$thumbnailUrl}
								canDelete={false}
								canArchive={false}
								onPreview={
									previewData
										? event =>
												previewTemplate(
													event,
													props,
													previewData.accountProfile,
													previewData.style
												)
										: null
								}
							/>
						</Column>
					) : null}
					<Column>
						<Row>
							<Column>
								<DrawerInfoProgress
									total={stats.hasCompletedStep}
									percentageTotal={stats.hasCompletedStepUnique}
									statsArray={statsArray}
									icon="envelope-o"
								/>
							</Column>
							<Column>
								{props.popup.step.mailCampaignId ? (
									<Link
										href={props.getHref('mailCampaign.dashboard', {
											id: props.popup.step.mailCampaignId
										})}
									>
										{t('flow.showMailCampaign')}
									</Link>
								) : null}
							</Column>
						</Row>
						<Row className={classes.elem('drawer-info-row').b()}>
							<DrawerInfoTitle
								total={stats.hasCompletedStepUnique}
								title={'flow.contactsPassedThisStep'}
								statsArray={statsArray}
							/>
						</Row>
					</Column>
				</Row>
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={stats}
				types={['currentlyInStep', 'fallenOutOfStep']}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

SendEmail.propTypes = {
	getHref: PropTypes.func.isRequired,
	popup: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired,
	subject: PropTypes.string,
	template: PropTypes.object,
	$$thumbnailUrl: PropTypes.string,
	id: PropTypes.number,
	name: PropTypes.string
};

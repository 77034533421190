'use strict';

angular.module('upFilters').filter('customUsers', function () {
	return function (users) {
		var customFieldValuesArray = users.split(',');

		var userNames = customFieldValuesArray.map(function (value) {
			var newValue = Tools.AppService.getActiveUsers().find(function (user) {
				return user.id === Number(value);
			});

			if (newValue && newValue.name) {
				return newValue.name;
			}

			return newValue;
		});

		return userNames.join(', ');
	};
});

import Client from 'App/resources/Model/Client';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';
import AlertBody from 'Components/Dialogs/Body/AlertBody';
import React, { lazy } from 'react';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import config from 'App/babel/config';
import ClientCardUDO from './ContentPages/UDO/UDO';

export const calculateRelationsCount = (client: Client, subsidiaries: { metadata: { total: number } }) => {
	const inCompanyGroup = () => {
		const hasProspectingPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
		const hasProspectingBasic = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);
		const showCommercial = !hasProspectingPro && hasProspectingBasic;

		if (client.prospecting && (showCommercial || hasProspectingPro)) {
			if (client.prospecting.groupSize > 1) {
				return 1;
			}
		} else if (Tools.AppService.getSelf().userParams.soliditetIsActive && client.dunsNo) {
			const fetchSoliditetDuns = async () => {
				const res = await Tools.SoliditetClient.customer(Tools.AppService.getCustomerId())
					.find({ dunsNo: client.dunsNo })
					.catch(() => ({ metadata: { total: 0 } }));
				return res.metadata.total;
			};
			fetchSoliditetDuns();
		}

		return 0;
	};

	return client.connectedClients.length + subsidiaries.metadata.total + inCompanyGroup();
};

export const confirmDeleteClient = (client: Client) => {
	const customerId = Tools.AppService.getCustomerId();
	const alertOptions = {
		type: 'confirm',
		reactive: true,
		fullscreen: true,
		hideAbort: false,
		dialog: AlertConfirm,
		id: 'confirm-delete-fields',
		body: React.createElement(AlertBody, {
			account: client,
			customerId: client.id,
			entity: 'Client',
			numSelected: 1
		}),
		confirmButtonText: T('admin.modal.deleteEntityAndRelated', { entity: T('account') }),
		confirmClass: 'btn-red',
		confirmationMode: 'text',
		confirmText: client.name,
		confirmFn: () =>
			Tools.Account.customer(customerId)
				.delete(client)
				.then(() => {
					// Fine for now, should go somewhere else in final version
					Tools.$state.go('accounts', {});
				})
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		openModal('Alert', {
			...alertOptions,
			title: getConfirmationTitle('Client', 1, client.name),
			onClose: confirmed => {
				if (confirmed) {
					alertOptions.confirmFn();
				}
			}
		});
	} else {
		Tools.$upModal.open('alert', alertOptions);
	}
};

export const contentMap = {
	activities: lazy(() => import('./ContentPages/Activities')),
	contacts: lazy(() => import('./ContentPages/Contacts')),
	overview: lazy(() => import('./ContentPages/Overview')),
	sales: lazy(() => import('./ContentPages/Sales')),
	relations: lazy(() => import('./ContentPages/Relations')),
	marketing: lazy(() => import('./ContentPages/Marketing/Marketing')),
	opportunities: lazy(() => import('./ContentPages/Opportunities/Opportunities')),
	financial: lazy(() => import('./ContentPages/CompanyFinancials')),
	documents: lazy(() => import('./ContentPages/Documents')),
	apps: lazy(() => import('./ContentPages/Apps')),
	support: lazy(() => import('./ContentPages/Support')),
	projectPlan: lazy(() => import('./ContentPages/ProjectPlan')),
	'udo-1': ({ client }: { client: Client }) => <ClientCardUDO client={client} typeId={1} />,
	'udo-2': ({ client }: { client: Client }) => <ClientCardUDO client={client} typeId={2} />,
	'udo-3': ({ client }: { client: Client }) => <ClientCardUDO client={client} typeId={3} />,
	'udo-4': ({ client }: { client: Client }) => <ClientCardUDO client={client} typeId={4} />
};

export const reactToAngularRedirectMap = {
	overview: '',
	contacts: 'contacts/',
	activities: 'activities/',
	opportunities: 'opportunities/',
	sales: 'orders/',
	financial: 'financial/',
	marketing: 'market/',
	relations: 'relations/',
	documents: 'files/',
	udo: 'udo/1',
	'udo-1': 'udo/1',
	'udo-2': 'udo/2',
	'udo-3': 'udo/3',
	'udo-4': 'udo/4',
	apps: 'apps/',
	support: 'support/',
	projectPlan: 'projectplan/'
} as const;

export type PageName = keyof typeof contentMap | keyof typeof reactToAngularRedirectMap;

const featureMap: Partial<Record<PageName, () => boolean>> = {
	overview: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_OVERVIEW_DISABLE'),
	contacts: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_CONTACTS_DISABLE'),
	activities: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_ACTIVITIES_DISABLE'),
	opportunities: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_OPPORTUNITIES_DISABLE'),
	sales: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_SALES_DISABLE'),
	financial: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_FINANCIALS_DISABLE'),
	marketing: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_MARKETING_DISABLE'),
	relations: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_RELATIONS_DISABLE'),
	documents: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_DOCUMENTS_DISABLE'),
	udo: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_UDO_DISABLE'),
	apps: () => Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD_APPS_DISABLE'),
	support: () => !Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT),
};

Tools.clientCardDev = {
	get useAngular() {
		const ls = localStorage.getItem('cc.useAngular');
		if (ls === null) {
			return null;
		}
		return ls === 'true';
	},
	set useAngular(v) {
		if (v === null) {
			localStorage.removeItem('cc.useAngular');
			return;
		}
		localStorage.setItem('cc.useAngular', String(v));
	}
};

declare global {
	namespace Tools {
		let clientCardDev: {
			get useAngular(): boolean | null;
			set useAngular(v: boolean | null);
		};
	}
}

export const shouldUseAngularPage = (pageName: PageName) => {
	if (!Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')) {
		return true;
	}

	if (config.ENV === 'DEV' && Tools.clientCardDev.useAngular !== null) {
		return Tools.clientCardDev.useAngular;
	}

	if (!contentMap.hasOwnProperty(pageName)) {
		return true;
	}

	const featureName = pageName.startsWith('udo-') ? 'udo' : pageName;
	return featureMap[featureName]?.() ?? false;
};

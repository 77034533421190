import _ from 'lodash';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('Esign', [
	'$resource',
	'$q',
	'URL',
	'API',
	'EsignAttributes',
	'AppService',
	'ParseGeneric',
	'ResourceHelper',
	'MapGeneric',
	'localeService',
	'NotificationService',
	function Esign(
		$resource,
		$q,
		URL,
		API,
		EsignAttributes,
		AppService,
		ParseGeneric,
		ResourceHelper,
		MapGeneric,
		localeService,
		NotificationService
	) {
		var Attributes = EsignAttributes().attr;
		var Keys = EsignAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'esign',
			createSuccessBody: 'saved.esign',
			updateSuccessBody: 'updated.esign',
			deleteSucccessBody: 'deleted.esign',
			createErrorBody: 'saveError.esign',
			updateErrorBody: 'saveError.esign',
			deleteErrorBody: 'deleteError.esign',
			cancelSuccessTitle: 'esign.canceled',
			cancelSuccessBody: 'esign.wasCanceled',
			cancelErrorTitle: 'default.error',
			cancelErrorBody: 'esign.cancelError',
			reminderSuccessTitle: 'default.done',
			reminderSuccessBody: 'esign.reminderSent',
			reminderErrorTitle: 'default.error',
			reminderErrorBody: 'esign.reminderError'
		});

		var state = {
			0: 'DRAFT',
			10: 'PENDING',
			20: 'REJECTED',
			30: 'ALL_SIGNED',
			40: 'CANCELED'
		};

		var stateEnum = {
			DRAFT: 0,
			PENDING: 10,
			REJECTED: 20,
			ALL_SIGNED: 30,
			CANCELED: 40
		};

		var Resource = $resource(
			URL + API + 'esigns/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseEsign', { isArray: false, custom: false })
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseEsign', { isArray: true, custom: false })
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseEsign', { isArray: false, custom: false }),
					transformRequest: MapGeneric('MapEsign', { payloadName: 'esign' })
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseEsign', { isArray: false, custom: false }),
					transformRequest: MapGeneric('MapEsign', { payloadName: 'esign' })
				},
				cancel: {
					method: 'PUT',
					isArray: false,
					url: URL + API + 'function/cancelEsign/:id'
				},
				sendReminder: {
					method: 'PUT',
					isArray: false,
					url: URL + API + 'function/sendEsignReminder/:id'
				},
				runAction: {
					method: 'POST',
					isArray: false,
					url: URL + API + 'function/esign/'
				}
			}
		);

		var Model = {
			new: function () {
				var self = AppService.getSelf();

				var data = {
					user: {
						id: self.id,
						name: self.name,
						email: self.email
					},
					opportunity: null,
					upsalesStatus: 1,
					title: '',
					state: stateEnum.DRAFT,
					authorization: 1,
					seenBySignatory: null,
					mdate: null,
					sendToScrive: false, // set to false if only save and not send document
					file: null,
					client: null,
					involved: [],
					orderStage: null,
					localeLanguage: localeService.getEsignLang(),
					integrationId: 0
				};

				var parsed = ParseGeneric('ParseEsign', { isArray: false, custom: false, isNew: true })(
					{ data: data },
					null
				);

				return parsed.data;
			},
			newInvolved: function (opts) {
				opts = opts || {};
				var involved = {
					sign: null,
					seen: null,
					email: null,
					fstname: '',
					sndname: '',
					company: '',
					companynr: null,
					personalnr: null,
					userId: null,
					contactId: null,
					deliveryMethod: 'email',
					authMethod: 'standard'
				};

				// add some data
				var person = opts.user || opts.contact;
				if (person) {
					involved.email = person.email;

					// Name
					var split = person.name.split(' ');
					involved.fstname = split[0];
					involved.sndname = split.length > 1 ? split[split.length - 1] : '';

					if (opts.user) {
						var accountSelf = AppService.getAccountSelf();
						involved.company = accountSelf.client.name;
						involved.userId = person.id;
						involved.mobile = person.userCellPhone;
					} else {
						involved.company = person.client.name;
						involved.contactId = person.id;
						involved.mobile = person.cellPhone;
					}
				}

				return involved;
			},
			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (esign, options) {
				var defered = $q.defer();

				if (!esign) {
					return defered.reject('No esign');
				}

				if (esign.id) {
					Resource.update({ id: esign.id }, { esign: esign }, defered.resolve, defered.reject);
				} else {
					Resource.create({ esign: esign }, defered.resolve, defered.reject);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !esign.id);
					})
					.catch(function (err) {
						let type = TYPE.BODY;
						let autoHide = true;
						let title = 'default.error';
						let body = 'saveError.esign';
						let integration;
						try {
							// Try to find integration id from request
							const integrationId = esign.integrationId;
							if (_.get(err, 'data.error.standardIntegrationError')) {
								body = err.data.error.standardIntegrationError;
								title = 'saveError.esign';
							}
							// Find integration
							integration = _.find(Tools.AppService.getEsignIntegrations(), { id: integrationId });
							if (integration) {
								type = TYPE.INTEGRATION_ERROR;
								autoHide = false;
							}
						} catch (e) {
							// Using default message
						}

						// Notify user
						NotificationService.addNotification({
							title,
							body,
							style: NotificationService.style.ERROR,
							icon: 'times',
							type,
							autoHide,
							integration
						});
					});

				return defered.promise;
			},

			delete: function (esign, options) {
				return Resource.delete({ id: esign.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, esign);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			cancel: function (id, options) {
				options = _.defaults(options || {}, helper.defaults);

				return Resource.cancel({ id: id }, {})
					.$promise.then(function (res) {
						// Notify user
						helper.successNotification(options.cancelSuccessTitle, options.cancelSuccessBody, res.data);

						helper.triggerEvent(options, 'updated', res.data);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.errorNotification(options.cancelErrorTitle, options.cancelErrorBody, null);
					});
			},

			sendReminder: function (id, options) {
				options = _.defaults(options || {}, helper.defaults);

				return Resource.sendReminder({ id: id }, {})
					.$promise.then(function (res) {
						// Notify user
						helper.successNotification(options.reminderSuccessTitle, options.reminderSuccessBody, res.data);

						helper.triggerEvent(options, 'updated', res.data);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.errorNotification(options.reminderErrorTitle, options.reminderErrorBody, null);
					});
			},

			runAction: function (data, e) {
				if (e) {
					e.stopPropagation();
				}
				return Resource.runAction(data).$promise;
			},

			getDownloadUrl: function (esign) {
				if (esign.integrationId && esign.integrationId !== -1) {
					return URL + API + 'function/esign/download/' + esign.id;
				}
				var endPoint =
					parseInt(esign.documentId) && esign.integrationId !== -1
						? 'esigns/download/' + esign.documentId
						: 'resources/download/internal/' + esign.file.id;
				return URL + API + endPoint + '?inline=true';
			},

			attr: Attributes,
			keys: Keys,
			state: state,
			stateEnum: stateEnum
		};

		return Model;
	}
]);

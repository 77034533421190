import React, { Component } from 'react';
ReactTemplates.upFilters.filterTypes.range = class Range extends Component {
	static defaultProps = {
		maxValueDisplayLength: 10,
		msBeforeSearch: 800
	};
	didOpen = false;

	constructor(props) {
		super(props);
		this.clampValues = _.debounce(this.clampValues, props.msBeforeSearch ?? 800);

		const value = props.filter && props.filter.value ? props.filter.value : { start: null, end: null };

		this.state = {
			value,
			formattedValue: this.formatValue(value),
			expanded: props.expanded || false,
			focus: { start: false, end: false }
		};
	}

	formatValue = value => {
		const multiplier = this.props.filter.multiplier || 1;

		return {
			start: typeof value.start === 'number' ? Tools.$filter('numberFormat')(value.start / multiplier, true) : '',
			end: typeof value.end === 'number' ? Tools.$filter('numberFormat')(value.end / multiplier, true) : ''
		};
	};

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(newProps) {
		const value = newProps.filter && newProps.filter.value ? newProps.filter.value : { start: null, end: null };
		this.setState({
			value: value,
			formattedValue: this.formatValue(value)
		});
	}

	componentDidUpdate() {
		if (this.state.expanded && this.didOpen) {
			const input = this._input;
			input.focus();
		}
		this.didOpen = false;
	}

	toggleExpanded = () => {
		if (!this.state.expanded) {
			this.didOpen = true;
		}
		this.setState({
			expanded: !this.state.expanded
		});
	};

	search = options => {
		this.props.onChange(
			{
				filterName: this.props.filterName,
				comparisonType: 'Search',
				value: {
					start: this.state.value.start,
					end: this.state.value.end
				},
				inactive: !(this.state.value && (this.state.value.start || this.state.value.end)),
				type: 'text'
			},
			options
		);
	};

	clampValues = target => {
		let { start, end } = this.state.value;

		if (target === 'from' && end && start && start > end) {
			end = start;
		}

		if (target === 'to' && start && end && end < start) {
			start = end;
		}

		this.setState({
			value: {
				start,
				end
			},
			formattedValue: this.formatValue({ start, end })
		});

		this.search({
			action: 'add'
		});
	};

	onInputChange = event => {
		const oldFilterValue = this.state.value;
		const nextValue = {
			start: oldFilterValue?.start ?? null,
			end: oldFilterValue?.end ?? null
		};

		const multiplier = this.props.filter.multiplier || 1;

		if (event.target.name === 'from') {
			nextValue.start = event.target.value === '' ? null : parseInt(event.target.value) * multiplier;
		} else {
			nextValue.end = event.target.value === '' ? null : parseInt(event.target.value) * multiplier;
		}
		if (this.props.filter.min) {
			nextValue.start = nextValue.start !== null ? Math.max(nextValue.start, this.props.filter.min) : null;
			nextValue.end = nextValue.end !== null ? Math.max(nextValue.end, this.props.filter.min) : null;
		}

		this.setState({
			value: nextValue,
			formattedValue: this.formatValue(nextValue)
		});

		this.clampValues(event.target.name);
	};

	onRemoveFilter = event => {
		this.setState({
			expanded: false
		});

		this.search({
			action: 'remove'
		});

		ReactTemplates.TOOLS.stopProp(event);
	};

	formatValueString = () => {
		const multiplier = this.props.filter.multiplier || 1;
		const from =
			this.state.value !== null && this.state.value !== undefined && this.state.value.start
				? Math.floor(this.state.value.start / multiplier)
				: null;
		const to =
			this.state.value !== null && this.state.value !== undefined && this.state.value.end
				? Math.floor(this.state.value.end / multiplier)
				: null;
		let valueString = '';

		if (from !== null && from !== undefined && to !== null && to !== undefined) {
			valueString =
				from.length > this.props.maxValueDisplayLength
					? from.substring(0, this.props.maxValueDisplayLength) + '...'
					: from;
			valueString += ' - ';
			valueString +=
				to.length > this.props.maxValueDisplayLength
					? to.substring(0, this.props.maxValueDisplayLength) + '...'
					: to;
		} else if (from !== null && from !== undefined) {
			valueString = '≥';
			valueString +=
				from.length > this.props.maxValueDisplayLength
					? from.substring(0, this.props.maxValueDisplayLength) + '...'
					: from;
		} else if (to !== null && to !== undefined) {
			valueString = '≤ ';
			valueString +=
				to.length > this.props.maxValueDisplayLength
					? to.substring(0, this.props.maxValueDisplayLength) + '...'
					: to;
		}

		if (valueString.length && this.props.filter.valueSuffix) {
			valueString += ' ' + this.props.filter.valueSuffix(this.props);
		}

		return valueString;
	};

	onBlur = input => {
		this.setState({
			focus: { ...this.state.focus, [input]: false }
		});
	};

	onFocus = input => {
		this.setState({
			focus: { ...this.state.focus, [input]: true }
		});
	};

	setRef = (name, ref) => {
		this[name] = ref;
	};

	render() {
		const filter = this.props.filter;
		const multiplier = this.props.filter.multiplier || 1;

		const startActive = this.state.value?.start != null;
		const endActive = this.state.value?.end != null;
		const active = startActive || endActive;

		const to = this.state.value?.end ? Math.floor(parseInt(this.state.value.end) / multiplier) : '';
		const from = this.state.value?.start ? Math.floor(parseInt(this.state.value.start) / multiplier) : '';

		const titleString = Tools.$translate(filter.title);
		const toString = Tools.$translate('default.to');
		const fromString = Tools.$translate('default.from');

		const valueSuffix = this.props.filter.valueSuffix?.(this.props);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{titleString}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'drilldown range-drilldown'}>
						<input
							name="from"
							type={this.state.focus['start'] ? 'number' : 'text'}
							className="form-control input-sm"
							value={(this.state.focus['start'] ? from : this.state.formattedValue['start']) || ''}
							placeholder={fromString}
							onChange={this.onInputChange}
							onFocus={this.onFocus.bind(this, 'start')}
							onBlur={this.onBlur.bind(this, 'start')}
							ref={this.setRef.bind(this, '_input')}
						/>
						<span>{'-'}</span>
						<input
							name="to"
							type={this.state.focus['end'] ? 'number' : 'text'}
							className="form-control input-sm"
							value={(this.state.focus['end'] ? to : this.state.formattedValue['end']) || ''}
							placeholder={toString}
							onFocus={this.onFocus.bind(this, 'end')}
							onBlur={this.onBlur.bind(this, 'end')}
							onChange={this.onInputChange}
						/>
						{valueSuffix ? <span>{valueSuffix}</span> : null}
					</div>
				)}
			</div>
		);
	}
};

'use strict';
import { DateInput, TimeInput, NumberInput } from '@upsales/components';

ReactTemplates.INPUTS.customFieldInput = window.ReactCreateClass({
	// Stores the datepickerObject for.. yes.. datepickers
	datepicker: null,

	// Some value formatters
	formatters: {
		date: function (val) {
			if (val && moment(val).isValid()) {
				var v = moment(val).format('L');
				return v;
			}
			return val;
		},
		integer: function (val) {
			var parsed = parseInt(val);
			if (!isNaN(parsed)) {
				return parsed;
			}
			return val;
		},
		float: function (val) {
			var parsed = parseFloat(val);
			if (!isNaN(parsed)) {
				return parsed;
			}
			return val;
		}
	},

	// Set initial value (before render)
	getInitialState: function () {
		return { value: this.props.field.value };
	},

	// The onChange function for the field. Triggers onChange so the scope gets updated in the directive
	handleChange: function (event) {
		var val = event.target.value;

		if (this.props.field.datatype === 'Currency') {
			// eslint-disable-next-line no-useless-escape
			val = val.replace(/[^\d|\-+|\.+]/g, '');
			val = val || 0;
			val = this.formatters.integer(val);
		} else if (this.props.field.datatype === 'Boolean') {
			if (val === 'true' || val === true || val === 1) {
				val = false;
			} else {
				val = true;
			}
		} else if (this.props.field.datatype === 'Percent') {
			if (val) {
				val = Math.min(val, 100);
				val = Math.max(val, 0);
			}
		} else if (this.props.field.datatype === 'Link') {
			val = this.ensureHttpPrefix(val);
		}

		// Do formatting here
		this.setState({ value: val });
		this.props.onChange(val);
	},

	// Triggered if the field is updated from outside react and if this is a datepicker and u select a date in it
	UNSAFE_componentWillReceiveProps: function (props) {
		var self = this;
		// Set the state value if it was changed
		if (self.state.value !== props.field.value) {
			self.setState({ value: props.field.value });
		}
	},

	// Fired when react is done rendering the component after an update
	componentDidUpdate: function () {
		var self = this;

		// Update datepicker to initialize it
		if (self.props.field.datatype === 'Date' && self.datepicker) {
			self.datepicker.update();
		}

		if (self.props.field.datatype === 'Select') {
			jQuery(ReactDOM.findDOMNode(this)).select2('val', self.state.value);
		}
	},

	ngModel: function (onChange) {
		var self = this;

		self.$setViewValue = function (newValue) {
			onChange(newValue);
		};
		self.$render = function () {};

		self.$dateValue = null;
	},

	// Triggered when react is done creating the element
	// This is where we setup jQuery plugins on the input element
	componentDidMount: function () {
		var self = this;
		var field;
		// Setup some jquery stuffs

		if (this.props.field.datatype === 'User' || this.props.field.datatype === 'Users') {
			field = this.props.field;
			var data = Tools.AppService.getActiveUsers().map(function (user) {
				return { id: user.id, text: user.name };
			});

			jQuery(ReactDOM.findDOMNode(this))
				.select2({
					allowClear: !field.obligatoryField,
					data: data,
					minimumInputLength: -1,
					multiple: this.props.field.datatype === 'Users',
					placeholder: self.props.skipSelectPlaceholder ? '' : field.name,
					separator: ',',
					formatSelection: function (object, container, escape) {
						return escape(object.text);
					},
					formatResult: function (object, container, query, escape) {
						return escape(object.text);
					},
					id: function (object) {
						return object.id;
					},
					matcher: function (term, undef, item) {
						return item.text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
					}
				})
				.on('change', function (e) {
					if (self.props.field.datatype === 'Users') {
						if (jQuery(ReactDOM.findDOMNode(self)).val() === '') {
							self.handleChange({ target: { value: [] } });
							return;
						}
						var val = jQuery(ReactDOM.findDOMNode(self)).val();
						self.handleChange({ target: { value: val, data: data } });
					} else {
						self.handleChange({ target: { value: e.val } });
					}
				});
		} else if (this.props.field.datatype === 'Select') {
			// Select 2 for selects
			field = this.props.field;
			jQuery(ReactDOM.findDOMNode(this))
				.select2({
					allowClear: !field.obligatoryField,
					data: field.default,
					multiple: this.props.multiple,
					placeholder: self.props.skipSelectPlaceholder ? '' : field.name,
					separator: '|',
					formatSelection: function (object, container, escape) {
						return escape(object);
					},
					formatResult: function (object, container, query, escape) {
						return escape(object);
					},
					id: function (object) {
						return object;
					},
					matcher: function (term, undef, item) {
						return item.toLowerCase().indexOf(term.toLowerCase()) !== -1;
					}
				})
				.on('change', function (e) {
					if (self.props.multiple) {
						if (jQuery(ReactDOM.findDOMNode(self)).val() === '') {
							self.handleChange({ target: { value: [] } });
							return;
						}
						var val = jQuery(ReactDOM.findDOMNode(self)).val().split('|');
						self.handleChange({ target: { value: val } });
					} else {
						self.handleChange({ target: { value: e.val } });
					}
				});
		}

		// Date input gets
		if (self.props.field.datatype === 'Date' && !self.props.usenewdate) {
			var ngModel = new this.ngModel(self.props.onChange);
			self.datepicker = Tools.$datepicker(jQuery(ReactDOM.findDOMNode(self)), ngModel, {
				controller: ngModel,
				autoclose: true,
				container: 'body',
				scope: Tools.$rootScope
			});
			if (self.state.value) {
				self.datepicker.update(self.state.value);
			}
			self.datepicker.update();
		}

		// Time
		if (this.props.field.datatype === 'Time' && !self.props.useNewTime) {
			//jQuery timepicker config
			var pickerConfig = {};
			pickerConfig.step = 15;
			pickerConfig.appendTo = 'body';
			pickerConfig.timeFormat = 'H:i';
			pickerConfig.lang = { decimal: '.', mins: 'min', hr: 'h', hrs: 'h' };
			pickerConfig.forceRoundTime = false;
			pickerConfig.maxTime = '24:00';
			pickerConfig.scrollDefaultNow = true;

			jQuery(ReactDOM.findDOMNode(this))
				.timepicker(pickerConfig)
				.on('change', function (e) {
					self.handleChange(e);
				});
		}
	},

	ensureHttpPrefix: function (value) {
		var notValid =
			value !== undefined &&
			value !== null &&
			!(
				'http://'.indexOf(value.substring(0, 7)) === 0 ||
				'https://'.indexOf(value.substring(0, 8)) === 0 ||
				'ftp://'.indexOf(value.substring(0, 6)) === 0
			);

		if (notValid) {
			return 'http://' + value;
		} else {
			return value;
		}
	},

	// This builds the input element and runs when component is created/updated
	render: function () {
		var self = this;
		var field = self.props.field;
		var name = self.props.name;
		var value = self.state.value;
		var type = field.datatype.toLowerCase();
		var inputType = 'text';
		var min = null;
		var max = null;
		var step = null;
		var checked = null;
		var disabled = !field.editable || field.locked || self.props.disabled ? true : null;
		var required = field.obligatoryField ? true : null;
		var classNames = 'form-control up-input';

		if (self.props.filter) {
			disabled = false;
		}

		if (this.props.className === null) {
			classNames = '';
		} else if (this.props.className) {
			classNames += ' ' + this.props.className;
		}

		switch (type) {
			case 'link':
				inputType = 'url';
				break;
			case 'email':
				inputType = 'email';
				break;
			case 'integer':
				inputType = 'number';
				value = self.formatters.integer(value);
				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							disabled={disabled}
							onChange={updatedValue => this.handleChange({ target: { value: updatedValue ?? '' } })}
							allowEmpty
						/>
					);
				}
				break;
			case 'currency':
				inputType = 'text';
				var parsed = parseInt(value);
				if (value && !isNaN(parsed)) {
					value = Tools.$filter('number')(parsed);
				}
				break;
			case 'discount':
				inputType = 'number';
				value = self.formatters.float(value);
				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							decimals={10}
							disabled={disabled}
							onChange={updatedValue => this.handleChange({ target: { value: updatedValue ?? '' } })}
							allowEmpty
						/>
					);
				}
				break;
			case 'percent':
				inputType = 'number';
				min = 0;
				step = 'any';
				value = self.formatters.float(value);
				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							min={min}
							decimals={10}
							disabled={disabled}
							onChange={updatedValue => {
								this.handleChange({ target: { value: updatedValue ?? '' } });
							}}
							allowEmpty
						/>
					);
				}
				break;
			case 'date':
				inputType = 'text';
				classNames += ' date-icon-with-label';

				if (self.props.usenewdate) {
					value = typeof value === 'string' ? (value.length ? new Date(value) : undefined) : value;
					return (
						<DateInput
							closeOnSelect
							state={this.props.state || undefined}
							value={value}
							onChange={date => {
								this.handleChange({ target: { value: date.target.value?.toString() } });
							}}
							placeholder={this.props.placeholder}
							disabled={disabled}
							tabIndex={this.props.tabindex}
						/>
					);
				}
				value = self.formatters.date(value);

				break;
			case 'time':
				inputType = 'text';
				max = 8;

				if (value && moment(value).isValid()) {
					var mmt = moment(value);
					var mmtMidnight = mmt.clone().startOf('day');
					var diffMinutes = mmt.diff(mmtMidnight, 'seconds');

					if (!diffMinutes) {
						var hours = Math.floor(diffMinutes / 3600);
						var minutes = Math.floor(diffMinutes / 60) - hours * 60;

						value = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);

						value = value || '00:00';
					}
				}

				if (self.props.useNewTime) {
					const usesTwelveHourFormat = moment('13:00', 'LT').format('LT').length > 5;
					return (
						<TimeInput
							min={`${min}`}
							max={`${max}`}
							state={this.props.state || undefined}
							step={15}
							value={typeof value !== 'number' ? value || '' : value}
							onChange={this.handleChange}
							placeholder={this.props.placeholder}
							disabled={disabled}
							tabIndex={this.props.tabindex}
							timeFormat={usesTwelveHourFormat ? 'military' : undefined}
						/>
					);
				}

				break;
			case 'string':
				break;
			case 'boolean':
				inputType = 'checkbox';
				checked = !!value;
				classNames = 'up-input';
				break;
			case 'text':
				return (
					<textarea
						name={name}
						className={classNames}
						onChange={this.handleChange}
						placeholder={this.props.placeholder}
						disabled={disabled}
						required={required}
						value={value || ''}
					/>
				);
			case 'select':
				inputType = 'hidden';

				if (self.props.multiple) {
					value = (value || []).join('|');
				}
				break;
			case 'user':
				inputType = 'hidden';
				break;
			case 'users':
				inputType = 'hidden';
		}

		return (
			<input
				name={name}
				min={min}
				max={max}
				step={step}
				className={classNames}
				type={inputType}
				value={value ?? ''}
				onChange={this.handleChange}
				placeholder={this.props.placeholder}
				checked={checked}
				disabled={disabled}
				required={required}
				style={this.props.style}
			/>
		);
	}
});

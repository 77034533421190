import async from 'async';

angular.module('upModals.controllers').controller('AppWidget', [
	'$scope',
	'$q',
	'$modalParams',
	'AppService',
	'StandardIntegration',
	function ($scope, $q, $modalParams, AppService, StandardIntegration) {
		var metadata = AppService.getMetadata();
		var customerId = AppService.getCustomerId();
		var integration;

		$scope.icon = $modalParams.icon;
		$scope.title = $modalParams.title;
		$scope.obj = $modalParams.obj;
		$scope.name = $modalParams.name;
		$scope.utils = $modalParams.utils;

		if ($modalParams.integrationId) {
			integration = _.find(metadata.integrations.active, { id: $modalParams.integrationId });
		}

		async.series(
			[
				function (cb) {
					if (!$modalParams.logId) {
						return cb();
					}
					StandardIntegration.log(customerId)
						.get($modalParams.logId)
						.then(function (log) {
							log = log.data[0];
							integration = _.find(metadata.integrations.active, { id: log.integrationId });
							$scope.obj = log;
							cb();
						})
						.catch(function (e) {
							cb(e);
						});
				}
			],
			function (err) {
				$scope.loading = false;
				$scope.err = err;
				if (!integration) {
					$scope.integrationInactive = true;
				} else {
					$scope.uiElements = [
						{
							integrationId: integration.id,
							integrationName: integration.name,
							type: 'widget',
							name: $scope.name
						}
					];
				}
			}
		);
	}
]);

'use strict';

angular.module('upAttributes').service('ActivityQuotaAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					sortable: 'user.name',
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						},
						role: {
							field: 'user.role.id',
							type: FilterType.Number
						}
					}
				},
				activityType: {
					title: 'default.activityType',
					field: 'activityType.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					sortable: 'activityType.name',
					parent: 'activityType',
					attr: {
						id: {
							field: 'activityType.id',
							type: FilterType.Number
						},
						name: {
							field: 'activityType.name',
							type: FilterType.String
						}
					}
				},
				month: {
					title: 'default.month',
					field: 'month',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				year: {
					title: 'default.year',
					field: 'year',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				performed: {
					title: 'default.performed',
					field: 'performed',
					type: FilterType.String,
					displayType: DisplayType.Number
				},
				created: {
					title: 'default.created',
					field: 'created',
					type: FilterType.String,
					displayType: DisplayType.Number
				}
			};

			return {
				attr: attributes,
				keys: {
					ID: 'id',
					USER: 'user',
					MONTH: 'month',
					YEAR: 'year'
				}
			};
		};
	}
]);

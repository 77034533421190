import { Row, Column, Icon } from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';

ReactTemplates.mailCampaign.recipientFilters = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			notDelivered: t('mail.notDelivered'),
			opened: t('mail.opened'),
			clicked: t('event.mailClicked'),
			submits: t('defaultView.formSubmit'),
			unsubs: t('mail.unsubscribers'),
			delivered: t('mail.delivered'),
			bounce: t('event.bounce'),
			prevunsub: t('mail.prevUnsubscribed'),
			duplicates: t('default.duplicates'),
			prevBounce: t('mail.prevBounce'),
			invalidEmail: t('mail.invalidEmail'),
			all: t('default.all'),
			allLinks: t('default.allLinks')
		};
	},

	renderFilterBtn: function (key, text, checked) {
		var className = 'btn up-btn btn-sm no-border';
		if (checked) {
			className += ' btn-white-grey';
		} else {
			className += ' btn-light-grey no-shadow';
		}

		var btn = (
			<button className={className} onClick={this.props.onFilterChange.bind(this, key, !checked)}>
				{' '}
				{text}{' '}
			</button>
		);

		return ReactTemplates.TOOLS.withTooltip(btn, text, { placement: 'top', key: key });
	},

	setDeliveredGroup: function (deliveredGroup) {
		this.props.onFilterChange('deliveredGroup', deliveredGroup);
	},

	handleChangeUrl: function (e) {
		var selectdLink = _.find(this.props.clickedUrls, { id: parseInt(e.target.value) });
		this.props.onChangeUrl(selectdLink.name);
	},

	render: function () {
		var deliveredClass = 'btn-group';
		var undeliveredClass = 'btn-group undelivered';
		if (this.props.filters.deliveredGroup) {
			deliveredClass += ' active-group';
		} else {
			undeliveredClass += ' active-group';
		}

		var numberFormat = this.props.tools.$filter('numberFormat');
		var delivered = this.props.delivered ? numberFormat(this.props.delivered) : 0;
		var undelivered = this.props.undelivered ? numberFormat(this.props.undelivered) : 0;

		var className = 'btn up-btn btn-sm no-border';

		var activeFilters = _.filter(this.props.filters, function (f, k) {
			return f && k !== 'deliveredGroup';
		});
		if (!activeFilters.length) {
			className += ' btn-white-blue';
		} else {
			className += ' btn-light-grey no-shadow';
		}
		return (
			<div style={{ marginTop: '10px' }}>
				<Row>
					<Column>
						<div className={deliveredClass}>
							<button className={className} onClick={this.props.onFilterChange.bind(this, 'all', true)}>
								{' '}
								{this.lang.all}{' '}
							</button>
							{this.renderFilterBtn('open', this.lang.opened, this.props.filters.open)}
							{this.renderFilterBtn('click', this.lang.clicked, this.props.filters.click)}
							<button
								key="delivered"
								onClick={this.setDeliveredGroup.bind(this, true)}
								className="group-trigger btn up-btn btn-sm btn-green no-shadow no-border"
							>
								{this.lang.delivered} {delivered}
							</button>
							{this.renderFilterBtn('submit', this.lang.submits, this.props.filters.submit)}
							{this.renderFilterBtn('unsub', this.lang.unsubs, this.props.filters.unsub)}
						</div>

						<div className={undeliveredClass}>
							<button className={className} onClick={this.props.onFilterChange.bind(this, 'all', true)}>
								{' '}
								{this.lang.all}{' '}
							</button>
							{this.renderFilterBtn('duplicates', this.lang.duplicates, this.props.filters.duplicates)}
							{this.renderFilterBtn('bounce', this.lang.bounce, this.props.filters.bounce)}
							{this.renderFilterBtn('prevbounce', this.lang.prevBounce, this.props.filters.prevbounce)}
							<button
								key="undelivered"
								onClick={this.setDeliveredGroup.bind(this, false)}
								className="group-trigger btn up-btn btn-sm btn-green no-shadow no-border"
							>
								{this.lang.notDelivered} {undelivered}
							</button>
							{this.renderFilterBtn('prevunsub', this.lang.prevunsub, this.props.filters.prevunsub)}
							{this.renderFilterBtn(
								'invalidemail',
								this.lang.invalidEmail,
								this.props.filters.invalidemail
							)}
						</div>

						{this.props.filters.click ? (
							<div className={undeliveredClass} style={{ width: '30%' }}>
								<ReactTemplates.INPUTS.upSelect
									className="form-control"
									data={this.props.clickedUrls}
									defaultValue={{
										id: 0,
										name:
											this.props.selectedLink != null
												? this.props.selectedLink
												: this.lang.allLinks
									}}
									required={true}
									onChange={this.handleChangeUrl}
								/>
							</div>
						) : null}
					</Column>
					{this.props.numRetryableErrors && !this.props.filters.deliveredGroup ? (
						<Column align="right" fixedWidth={180}>
							<ThirdButton onClick={this.props.onRetryErrors} disabled={this.props.retryingErrors}>
								<Icon name="refresh" space="mrs" spin={this.props.retryingErrors} />
								{T('mailCampaign.retryXFailedEmails', { value: this.props.numRetryableErrors })}
							</ThirdButton>
						</Column>
					) : null}
				</Row>
			</div>
		);
	}
});

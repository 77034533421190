import logError from 'App/babel/helpers/logError';
import Prospecting from 'App/babel/resources/Prospecting';
import { getCustomFieldValue } from 'Services/ActionProperties';

angular.module('domain.soliditet').controller('ConfirmBuyProspects', [
	'$scope',
	'$modalParams',
	'$upModal',
	'ActionProperties',
	'SoliditetClient',
	'AppService',
	'$safeApply',
	function ($scope, $modalParams, $upModal, ActionProperties, SoliditetClient, AppService, $safeApply) {
		var Ctrl = this;
		Ctrl.isProspecting = !!$modalParams.isProspecting;
		Ctrl.close = $scope.close;
		Ctrl.resolve = $scope.resolve;
		Ctrl.reloadModalPosition = $scope.reloadModalPosition;
		var arrayProperties = ['Projects', 'Categories'];
		Ctrl.showCustomFields = $modalParams.customFields.length;
		Ctrl.credits = $modalParams.metadata.soliditetCredits;
		Ctrl.multipleAccountManagers = $modalParams.metadata.params.teamAccountManager;
		Ctrl.users = $modalParams.users;
		Ctrl.campaigns = $modalParams.campaigns;
		Ctrl.categories = $modalParams.categories;
		Ctrl.newAccounts = $modalParams.total - $modalParams.existing;
		Ctrl.total = $modalParams.total;
		Ctrl.existing = $modalParams.existing;
		Ctrl.translateNewAccounts = { value: Ctrl.newAccounts };
		Ctrl.translateExisting = { value: Ctrl.existing };
		Ctrl.updateExisting = Ctrl.existing > 0 ? true : false;
		Ctrl.field = null;
		Ctrl.customFields = _.cloneDeep($modalParams.customFields);
		Ctrl.requiredFields = Tools.AppService.getMetadata().requiredFields.Client;
		Ctrl.properties = {
			Project: [],
			Categories: []
		};
		Ctrl.options = {
			updateExisting: false,
			skipProjects: false,
			skipAccountManagers: false,
			skipAddresses: false,
			skipCategories: false
		};

		const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');

		Ctrl.userSelect = {
			data: Ctrl.users,
			allowClear: true,
			matcher: function (term, text, op) {
				return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
			},
			formatSelection: function (object, container, escape) {
				return escape(object.name);
			},
			formatResult: function (object, container, query, escape) {
				return escape(object.name);
			},
			id: function (object) {
				return object.id.toString();
			}
		};

		Ctrl.displayFields = _.filter(Ctrl.customFields, function (field) {
			return field.obligatoryField && field.editable;
		});

		Ctrl.selectableFields = _.filter(Ctrl.customFields, function (field) {
			return !field.obligatoryField && field.editable;
		});

		Ctrl.fieldSelect = {
			data: Ctrl.selectableFields,
			allowClear: 0,
			matcher: function (term, text, op) {
				return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
			},
			formatSelection: function (object, container, escape) {
				return escape(object.name);
			},
			formatResult: function (object, container, query, escape) {
				return escape(object.name);
			},
			id: function (object) {
				return object.id.toString();
			}
		};

		$scope.$watch(
			function () {
				return Ctrl.field;
			},
			function () {
				if (Ctrl.field) {
					var index = _.findIndex(Ctrl.selectableFields, { id: Ctrl.field.id });
					if (index > -1) {
						Ctrl.selectableFields.splice(index, 1);
						Ctrl.displayFields.push(Ctrl.field);
					}
					Ctrl.field = null;
				}
			}
		);

		if (Ctrl.multipleAccountManagers) {
			Ctrl.properties.User = [];
			arrayProperties.push('User');
		}

		Ctrl.cost = function () {
			return Ctrl.updateExisting ? $modalParams.total : Ctrl.newAccounts;
		};

		Ctrl.selectMe = function () {
			var user = _.find(Ctrl.users, function (user) {
				return user.id === $modalParams.self.id;
			});

			Ctrl.properties.User = Ctrl.multipleAccountManagers ? (user ? [user] : []) : user;
		};

		Ctrl.createCampaign = function () {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editCampaign', { customerId: $modalParams.customerId, noRedirect: true })
				.then(function (campaign) {
					Ctrl.campaigns.push(campaign);
					Ctrl.properties.Project.push(campaign.id);
				});
		};

		window.getProperties = Ctrl.getProperties = function () {
			var t = Tools.$translate;

			var properties = [
				{
					key: 'User',
					singular: 'import.fields.client.title',
					plural: 'import.fields.client.title'
				},
				{
					key: 'Project',
					singular: 'default.addToCampaign',
					plural: 'default.addToXCampaigns'
				},
				{
					key: 'Categories',
					singular: 'default.addToCategory',
					plural: 'default.addToXCategories'
				}
			].reduce(function (res, config) {
				var value = Ctrl.properties[config.key];
				var length = value ? value.length : 0;

				if (length) {
					var langTag =
						length > 1 ? t(config.plural, { value: length }) : t(config.singular, { value: length });
					res.push(langTag);
				}

				return res;
			}, []);

			return _.reduce(
				Ctrl.customFields,
				function (res, cf) {
					if (cf.value !== null && cf.value !== undefined) {
						properties.push(t('default.update') + ' ' + cf.name);
					}
					return res;
				},
				properties
			);
		};

		Ctrl.done = function () {
			Ctrl.options.updateExisting = Ctrl.updateExisting;
			Ctrl.options.runActionsForExisting = !Ctrl.updateExisting && Ctrl.existing;

			var properties = [];

			var fixedProperties = _.pick(Ctrl.properties, function (value) {
				if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
					return true;
				}
				return false;
			});

			if (fixedProperties.User) {
				if (Ctrl.multipleAccountManagers) {
					fixedProperties.AddUser = _.pluck(fixedProperties.User, 'id');
					delete fixedProperties.User;
				} else {
					fixedProperties.User = fixedProperties.User.id;
				}
			}

			// Send request
			Ctrl.saving = true;

			if (Ctrl.isProspecting) {
				const clientData = {
					custom: [],
					sourceType: 'prospecting-grouptree'
				};

				if (hasSubaccounts && $modalParams.operationalAccountId) {
					clientData.operationalAccountId = $modalParams.operationalAccountId;
				}
				const nameMap = {
					User: 'users',
					AddUser: 'users',
					Project: 'projects',
					Categories: 'categories'
				};

				Object.entries(fixedProperties).reduce((res, [name, value]) => {
					const prospectingName = nameMap[name];
					if (prospectingName) {
						res[prospectingName] = Array.isArray(value) ? value.map(id => ({ id })) : [{ id: value }];
					}
					return res;
				}, clientData);

				Ctrl.customFields.reduce((res, customField) => {
					const value = getCustomFieldValue(customField);
					if (value !== null) {
						res.custom.push({ fieldId: customField.id, value });
					}
					return res;
				}, clientData);
				const body = {
					filters: $modalParams.filters,
					activityProperties: [],
					country: $modalParams.country,
					clientData
				};
				Prospecting.saveBulk(body)
					.then(() => Ctrl.resolve())
					.catch(error => {
						Ctrl.saving = false;
						$safeApply($scope);
						logError(error, 'confirmBuyProspects - prospecting');
					});
			} else {
				// map custom
				ActionProperties.mapCustomFields(properties, Ctrl.customFields, 'Client');

				// Map properties
				ActionProperties.map(properties, fixedProperties, arrayProperties);

				SoliditetClient.customer($modalParams.customerId)
					.buyPreview($modalParams.filters, properties, Ctrl.options)
					.then(function () {
						var metadata = AppService.getMetadata();
						metadata.soliditetCredits = Math.max(metadata.soliditetCredits - Ctrl.cost(), 0);
						AppService.setMetadata(metadata);

						Ctrl.saving = false;
						Ctrl.resolve();
					})
					.catch(error => logError(error, 'confirmBuyProspects - soliditet'));
			}
		};
	}
]);

'use strict';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('Appointment', [
	'$resource',
	'$q',
	'AppointmentAttributes',
	'URL',
	'API',
	'ResourceHelper',
	'AppService',
	'ParseGeneric',
	'MapGeneric',
	function AppointmentService(
		$resource,
		$q,
		AppointmentAttributes,
		URL,
		API,
		ResourceHelper,
		AppService,
		ParseGeneric,
		MapGeneric
	) {
		var AA = AppointmentAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();
		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'appointment', // same as activity for now so we dont have to have x2 listeners in all lists
			createSuccessBody: 'saved.appointment',
			updateSuccessBody: 'updated.appointment',
			deleteSucccessBody: 'deleted.appointment',
			createErrorBody: 'saveError.appointment',
			updateErrorBody: 'saveError.appointment',
			deleteErrorBody: 'deleteError.appointment',
			onclickLink: savedObject => {
				Tools.$upModal.open('editAppointment', { id: savedObject.id });
			},
			notificationType: TYPE.GENERIC_ENTITY_ADDED,
			extraSeconds: 1000
		});

		var dateFields = ['indexDate', 'modDate', 'regDate', 'date', 'endDate'];

		var Model = {
			new: function () {
				var meta = AppService.getMetadata();
				var self = AppService.getSelf();
				var appointmentTypes = AppService.getActivityTypes('appointment', true);

				// Set start to closest future half hour
				var startDateMoment = moment().seconds(0);
				var minutesToNext = 30 - (startDateMoment.minute() % 30);

				var appointment = {
					client: null,
					users: [self],
					project: null,
					date: startDateMoment.add(minutesToNext, 'minutes').toDate(),
					endDate: startDateMoment.clone().add(1, 'hours').toDate(),
					description: '',
					notes: '',
					custom: [],
					outcome: 'planned',
					isAppointment: true,
					activityType:
						_.find(appointmentTypes, { id: meta.params.DefaultAppointmentTypeId }) ||
						_.find(appointmentTypes, { first: 1 }) ||
						appointmentTypes[0]
				};

				var parsed = ParseGeneric(null, {
					isArray: false,
					isNew: true,
					custom: 'appointment',
					skipDateConvert: true
				})(
					{
						data: appointment
					},
					null
				);

				return parsed;
			},
			customer: function (customer) {
				var instance = {};
				var Appointment = null;
				Appointment = $resource(
					URL + API + customer + '/appointments/:id',
					{},
					{
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'appointment',
								dateFields: dateFields
							})
						},
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: true,
								custom: 'appointment',
								dateFields: dateFields
							})
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'appointment',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric(null, {
								payloadName: 'appointment'
							})
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'appointment',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric(null, {
								payloadName: 'appointment'
							})
						}
					}
				);

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					delete params.mapCustom;

					return Appointment.find(params).$promise;
				};

				instance.get = function (id) {
					if (!id) {
						return $q.reject('no appointment id');
					}

					return Appointment.get({ id: id }).$promise;
				};

				instance.save = function (appointment, options) {
					var defered = $q.defer();

					if (!appointment) {
						return defered.reject('No appointment');
					}

					if (appointment.id) {
						Appointment.update(
							{ id: appointment.id },
							{ appointment: appointment },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Appointment.create(
							{ appointment: appointment },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !appointment.id);
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !appointment.id);
						});

					return defered.promise;
				};

				instance['delete'] = function (appointment, options) {
					return Appointment.delete({ id: appointment.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, appointment);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

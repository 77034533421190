import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { ButtonGroup, Button, Input } from '@upsales/components';
import './StepIntInput.scss';

class StepIntInput extends React.Component {
	constructor(p) {
		super(p);
		this.state = {
			value: this.props.value || 0
		};

		this.chart = null;
		this.holding = false;
		this.holdTimeout = null;

		this.getSafeValue = this.getSafeValue.bind(this);
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.setValue = this.setValue.bind(this);
		this.setValueHoldDown = this.setValueHoldDown.bind(this);
		this.setValueHoldUp = this.setValueHoldUp.bind(this);
		this.valueChange = this.valueChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (parseInt(nextProps.value) !== prevState.value && !isNaN(parseInt(nextProps.value))) {
			return { value: parseInt(nextProps.value) };
		}

		return null;
	}

	getSafeValue(value) {
		if (this.props.max !== undefined) {
			value = Math.min(this.props.max, value);
		}
		if (this.props.min !== undefined) {
			value = Math.max(this.props.min, value);
		}

		return value;
	}

	mouseDown(upDown) {
		this.holding = true;

		this.holdTimeout = setTimeout(() => {
			if (upDown === '-') {
				this.setValueHoldDown(this.state.value - 1);
			} else {
				this.setValueHoldUp(this.state.value + 1);
			}
		}, 50);
	}

	mouseUp() {
		const wasHolding = this.holding;
		this.holding = false;
		if (this.holdTimeout) {
			clearTimeout(this.holdTimeout);
		}
		if (!wasHolding) {
			this.valueChange(this.state.value);
		}
	}

	setValue(value) {
		this.valueChange(value);
	}

	setValueHoldDown(value) {
		this.valueChange(value);

		this.holdTimeout = setTimeout(() => {
			this.setValueHoldDown(value - 1);
		}, 30);
	}

	setValueHoldUp(value) {
		this.valueChange(value);

		this.holdTimeout = setTimeout(() => {
			this.setValueHoldUp(value + 1);
		}, 30);
	}

	valueChange(val) {
		if (isNaN(parseInt(val))) {
			return;
		}
		const value = this.getSafeValue(parseInt(val));
		if (value === this.state.value || value < 0) {
			return;
		}
		this.setState({ value });

		if (!this.holding) {
			this.props.onChange({ target: { value } });
		}
	}
	render() {
		// eslint-disable-next-line no-unused-vars
		const { className, onChange, size, ...props } = this.props;
		const classes = new bemClass('StepIntInput', className);

		return (
			<ButtonGroup className={classes.b()} block={true} size={size} {...props}>
				<Button
					color="light-grey"
					shadow="none"
					tabIndex="-1"
					disabled={this.state.value <= 0}
					onClick={() => this.setValue(parseInt(this.state.value) - 1)}
					onMouseDown={() => this.mouseDown('-')}
					onMouseUp={this.mouseUp}
					onMouseOut={this.mouseUp}
					className="ButtonGroup__no-flex"
					size={size}
				>
					{'-'}
				</Button>

				<Input
					type="number"
					size={size}
					value={this.state.value}
					onChange={e => this.valueChange(e.target.value)}
					data-testid="step-int-input"
				/>

				<Button
					color="light-grey"
					shadow="none"
					tabIndex="-1"
					onClick={() => this.setValue(parseInt(this.state.value) + 1)}
					onMouseDown={() => this.mouseDown('+')}
					onMouseUp={this.mouseUp}
					onMouseOut={this.mouseUp}
					className="ButtonGroup__no-flex"
					size={size}
				>
					{'+'}
				</Button>
			</ButtonGroup>
		);
	}
}

StepIntInput.propTypes = {
	value: PropTypes.number,
	max: PropTypes.number,
	min: PropTypes.number,
	onChange: PropTypes.func,
	className: PropTypes.string,
	size: PropTypes.string
};

export default StepIntInput;

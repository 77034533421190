import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup } from '@upsales/components';
import { getProductCategoryTree } from '../../helpers/product';

const propTypes = {
	resolve: PropTypes.func,
	reject: PropTypes.func
};

class MultiUpdateProductsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			active: null,
			roles: null,
			category: null
		};

		const t = Tools.$translate;

		this.lang = {
			update: t('default.update'),
			roles: t('default.roles'),
			cancel: t('default.abort'),
			placeInsideCategory: t('admin.products.setUnderCategory'),
			chooseCategory: t('admin.chooseCategory'),
			chooseRole: t('admin.documentTemplateModal.rolesPlaceholder'),
			edit: t('default.edit'),
			editProducts: `${t('default.edit')} ${t('default.products').toLowerCase()}`,
			active: t('default.active'),
			inactive: t('default.inactive'),
			setProductsTo: t('admin.products.setProductsTo')
		};

		this.productCategories = getProductCategoryTree();
		this.roles = Tools.AppService.getRoles();
	}

	toggleActive = () => {
		const value = this.state.active === true ? null : true;
		this.setState({ active: value });
	};

	toggleInActive = () => {
		const value = this.state.active === false ? null : false;
		this.setState({ active: value });
	};

	changeRoles = roles => {
		const ids = _.pluck(roles, 'id');
		this.setState({ roles: ids.length ? ids : null });
	};

	changeCategory = event => {
		const value = event.target.value ? parseInt(event.target.value) : null;
		this.setState({ category: value });
	};

	update = () => {
		const object = Object.entries(this.state).reduce((res, [key, value]) => {
			if (value !== null) {
				res[key] = value;
			}
			return res;
		}, {});

		this.props.resolve(object);
	};

	mapIdToObejct(type, ids) {
		const objects = this[type];
		return _.map(ids, id => _.find(objects, { id }));
	}

	render() {
		const { category, roles, active } = this.state;
		const hasUserPermissionsAdvanced = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
		);

		const selectedProductCategory = category ? _.find(this.productCategories, { id: category }) : '';
		const selectedRoles = roles ? roles.map(roleId => _.find(this.roles, { id: roleId })) : [];

		return (
			<div id="multiupdate-product-modal">
				<div className="header">
					<h2 className="title">{this.lang.editProducts}</h2>
					<span className="close" onClick={this.props.reject}>
						<b className="fa fa-times" />
					</span>
				</div>
				<div className="form-container">
					<div className="form-group">
						<label>{this.lang.placeInsideCategory}</label>
						<ReactTemplates.INPUTS.upSelect
							tools={Tools}
							options={{ allowClear: true, placeholder: this.lang.chooseCategory }}
							data={this.productCategories}
							multiple={false}
							required={false}
							className="form-control"
							onChange={this.changeCategory}
							matcher={this.matcher}
							defaultValue={selectedProductCategory}
						/>
					</div>
					{hasUserPermissionsAdvanced ? (
						<div className="form-group">
							<label>{this.lang.roles}</label>
							<ReactTemplates.INPUTS.upRoles
								tools={Tools}
								data={this.roles}
								multiple={true}
								placeholder={this.lang.chooseRoles}
								required={false}
								onChange={this.changeRoles}
								value={selectedRoles}
							/>
						</div>
					) : null}
					<div className="form-group">
						<label>{this.lang.setProductsTo}</label>
						<div>
							<ButtonGroup block={true}>
								<Button
									onClick={this.toggleActive}
									color={active === true ? 'bright-blue' : 'light-grey'}
									shadow="none"
									disabled={false}
								>
									{this.lang.active}
								</Button>
								<Button
									onClick={this.toggleInActive}
									color={active === false ? 'bright-blue' : 'light-grey'}
									shadow="none"
									disabled={false}
									style={{ marginTop: 0 }}
								>
									{this.lang.inactive}
								</Button>
							</ButtonGroup>
						</div>
					</div>
					<div className="form-group">
						<Button
							onClick={this.update}
							className="up-btn btn-bright-blue btn-block btn-lg no-border no-shadow"
							disabled={false}
						>
							{this.lang.update}
						</Button>
						<Button onClick={this.props.reject} className="btn btn-block no-border no-shadow btn-link">
							{this.lang.cancel}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

MultiUpdateProductsModal.propTypes = propTypes;

window.MultiUpdateProductsModal = MultiUpdateProductsModal;
export default MultiUpdateProductsModal;

'use strict';

ReactTemplates.form.fileContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			selectFile: t('file.selectFile'),
			header: t('landingpage.header'),
			style: t('landingpage.style'),
			textColor: t('form.textColor'),
			button: t('bannerEditor.button'),
			buttonText: t('form.buttonText'),
			background: t('bannerEditor.background'),
			image: t('bannereditor.image'),
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage'),
			toDownloadUsrClicksImg: t('landingpage.toDownloadUsrClicksImg')
		};
	},
	browseFiles: function () {
		var self = this;
		Tools.$upModal
			.open('fileBrowser', {
				public: true,
				selectOnUpload: true
			})
			.then(function (files) {
				var element = self.state.element;
				element.value.src = files[0].$$publicUrl;
				element.value.fileName = files[0].filename;
				self.setState({ element: element });
				self.props.onChange(element);
			});
	},
	browseImages: function () {
		var self = this;
		Tools.$upModal
			.open('fileBrowser', {
				types: ['image'],
				public: true,
				selectOnUpload: true
			})
			.then(function (files) {
				var element = self.state.element;
				element.value.imgSrc = files[0].$$publicUrl;
				self.setState({ element: element });
				self.props.onChange(element);
			});
	},
	removeFile: function () {
		var element = this.state.element;
		element.value.src = '';
		element.value.fileName = '';
		this.setState({ element: element });
		this.props.onChange(element);
	},
	valuePropChanged: function (key, e) {
		var element = this.state.element;
		element.value[key] = e.target.value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	colorChanged: function (key, color) {
		var element = this.state.element;
		element.value[key] = color;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	setRef: function (key, ref) {
		this[key] = ref;
	},
	setUseImg: function (value) {
		var element = this.state.element;
		element.value.asImg = value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	render: function () {
		var self = this;
		var element = this.state.element;

		var imgElem = null;
		var hasImg = self.state.element.value.imgSrc && self.state.element.value.imgSrc.length;

		if (element.value.asImg && hasImg) {
			var style = {
				backgroundImage: "url('" + self.state.element.value.imgSrc + "')"
			};
			imgElem = <div className="img-element" style={style}></div>;
		}

		var typeBtnClass = {
			btn: 'btn up-btn btn-sm btn-light-grey no-shadow',
			img: 'btn up-btn btn-sm btn-light-grey no-shadow'
		};

		if (element.value.asImg) {
			typeBtnClass.img += ' btn-green';
		} else {
			typeBtnClass.btn += ' btn-green';
		}

		var hasFile = element.value.src !== '';
		var titleClass = 'floating-label-input';
		var buttonClass = 'floating-label-input';

		if (element.value.title && element.value.title.length) {
			titleClass += ' has-value';
		}
		if (element.value.buttonText && element.value.buttonText.length) {
			buttonClass += ' has-value';
		}

		return (
			<div>
				<div className="tool-main-section">
					<div className="tool-section">
						{hasFile ? (
							<div>
								<a
									href={element.value.src}
									className="btn btn-bright-blue btn-link btn-sm up-btn"
									target="_blank"
								>
									{element.value.fileName}
								</a>
								<button
									type="button"
									className="up-btn btn-sm btn-grey btn-hover-red btn-link pull-right"
									onClick={self.removeFile}
								>
									<b className="fa fa-trash-o"></b>
								</button>
								<div className="clearfix"></div>
							</div>
						) : (
							<button
								type="button"
								onClick={self.browseFiles}
								className="btn up-btn btn-green btn-block no-shadow"
							>
								{self.lang.selectFile}
							</button>
						)}
					</div>
				</div>

				{hasFile ? (
					<div>
						<div className="tool-header">
							<h3>{this.lang.style}</h3>
						</div>

						<div className="tool-main-section no-margin">
							<div className="tool-section">
								<div className="btn-group" style={{ width: '100%' }}>
									<button
										type="button"
										onClick={self.setUseImg.bind(self, false)}
										style={{ width: '50%' }}
										className={typeBtnClass.btn}
									>
										{self.lang.button}
									</button>
									<button
										type="button"
										onClick={self.setUseImg.bind(self, true)}
										style={{ width: '50%' }}
										className={typeBtnClass.img}
									>
										{self.lang.image}
									</button>
								</div>
							</div>
						</div>
						{element.value.asImg ? (
							<div className="tool-main-section">
								<div className="tool-section">
									<i className="text-dark-grey">{self.lang.toDownloadUsrClicksImg}</i>
								</div>
								<div className="tool-section" key="img-row">
									<div className="img-preview">
										{imgElem}

										<button className="up-btn btn-green" onClick={self.browseImages}>
											{imgElem ? self.lang.changeImage : self.lang.setImage}
										</button>
									</div>
								</div>
							</div>
						) : (
							<div>
								<div className="tool-main-section no-margin">
									<div className="tool-header">
										<h3>{this.lang.header}</h3>
									</div>
									<div className="tool-section">
										<div className={titleClass}>
											<label>{self.lang.header}</label>
											<input
												type="text"
												value={element.value.title}
												onChange={self.valuePropChanged.bind(self, 'title')}
											/>
										</div>
									</div>
									<div className="tool-section">
										<label>{this.lang.textColor}</label>
										<div className="pull-right">
											{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
												value: element.value.textColor,
												isText: false,
												presets: self.props.colorVariables,
												addPreset: self.props.addPreset,
												onChange: self.colorChanged.bind(self, 'textColor')
											})}
										</div>
									</div>
								</div>
								<div className="tool-main-section no-margin">
									<div className="tool-header">
										<h3>{this.lang.button}</h3>
									</div>
									<div className="tool-section">
										<div className={buttonClass}>
											<label>{self.lang.buttonText}</label>
											<input
												type="text"
												value={element.value.buttonText}
												onChange={self.valuePropChanged.bind(self, 'buttonText')}
											/>
										</div>
									</div>
									<div className="tool-section">
										<label>{this.lang.textColor}</label>
										<div className="pull-right">
											{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
												value: element.value.buttonTextColor,
												isText: false,
												presets: self.props.colorVariables,
												addPreset: self.props.addPreset,
												onChange: self.colorChanged.bind(self, 'buttonTextColor')
											})}
										</div>
									</div>
									<div className="tool-section">
										<label>{this.lang.background}</label>
										<div className="pull-right">
											{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
												value: element.value.buttonBackgroundColor,
												isText: false,
												presets: self.props.colorVariables,
												addPreset: self.props.addPreset,
												onChange: self.colorChanged.bind(self, 'buttonBackgroundColor')
											})}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>
		);
	}
});

'use strict';

angular.module('upResources').factory('MailCampaignInfo', [
	'$resource',
	'$q',
	'URL',
	'API',
	'MailCampaignInfoAttributes',
	function MailCampaignInfo($resource, $q, URL, API, MailCampaignInfoAttributes) {
		var PA = MailCampaignInfoAttributes();

		var baseUrl = URL + API + 'mailCampaignInfo/';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false
				},
				getPreview: {
					method: 'GET',
					isArray: false,
					url: baseUrl + 'preview/:id'
				},
				getPreviewFilter: {
					method: 'GET',
					isArray: false,
					url: baseUrl + 'preview'
				},
				find: {
					method: 'GET',
					isArray: false
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				return Resource.find(angular.extend(filter, options)).$promise;
			},
			get: function (id) {
				if (!id) {
					return $q.reject('No mailCampaign id');
				}
				return Resource.get({ id: id }).$promise;
			},
			getPreview: function (id, categoryId) {
				if (!id) {
					return $q.reject('No mailCampaign id');
				}
				const data = { id };
				if (categoryId) {
					data.categoryId = categoryId;
				}
				return Resource.getPreview(data).$promise;
			},
			getPreviewFilter: function (filter, categoryId) {
				if (!filter) {
					return $q.reject('No mailCampaign filter');
				}
				if (categoryId) {
					filter.categoryId = categoryId;
				}
				return Resource.getPreviewFilter(filter).$promise;
			},
			attr: PA.attr
			// keys: PA.keys,
			// requiredFields: PA.requiredFields
		};

		return Model;
	}
]);

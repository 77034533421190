'use strict';

angular.module('domain.activity').controller('CloseActivityMulti', [
	'$scope',
	'$modalParams',
	'$multiSelect',
	'MultiActions',
	function ($scope, $modalParams, $multiSelect, MultiActions) {
		$scope.isAnActivity = $modalParams?.entity === 'Activity';

		$scope.save = function () {
			$scope.closing = true;
			const properties = [{ name: 'CloseDate', value: moment().format('YYYY-MM-DD') }];

			if ($modalParams.onSave) {
				return $modalParams
					.onSave(properties)
					.then($scope.resolve)
					.catch(() => {
						$scope.closing = false;
					});
			}

			var filters = $modalParams.filters;
			if ($modalParams.multiselect?.selectedIds.length) {
				filters.addFilter(
					{ field: 'id' },
					filters.comparisonTypes.Equals,
					$modalParams.multiselect.selectedIds
				);
			} else if ($multiSelect.selected && $multiSelect.selected.length) {
				filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, $multiSelect.selected);
			}

			MultiActions.customer($modalParams.customerId)
				.updateActivity($multiSelect.selected.length, properties, filters.build())
				.then(() => {
					$scope.closing = false;
					// Deselect all and close modal
					$multiSelect.selectNone();
					$scope.resolve();
				})
				.catch(() => ($scope.closing = false));
		};
	}
]);

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@upsales/components';
import CriteriaDrilldownWrapper from '../../../CriteriaDrilldownWrapper';
import NumberInput from '../../Number';
import Bem from '@upsales/components/Utils/bemClass';
import { cloneDeep } from 'lodash';

export default class FinancialsDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const { $translate: t, FeatureHelper } = Tools;

		this.lang = {
			revenue: t('default.turnover'),
			result: t('prospecting.filters.result'),
			ebitda: t('default.ebitda'),
			profitMargin: t('default.profitMargin'),
			solidity: t('default.equityRatio'),
			currentRatio: t('default.currentRatio'),
			revenueChange: t('prospecting.filters.revenueChanged'),
			proFilter: t('prospecting.filters.proFilter'),
			upgradeToProToUse: t('prospecting.filters.upgradeToProToUse'),
			closed: t('default.closed'),
			open: t('default.open')
		};

		this.onRevenueChanged = this.onChange.bind(this, 'revenue');
		this.onRevenueChangeChanged = this.onChange.bind(this, 'revenueChange');
		this.onResultChanged = this.onChange.bind(this, 'result');
		this.onEbitdaChanged = this.onChange.bind(this, 'ebitda');
		this.onProfitMarginChanged = this.onChange.bind(this, 'profitMargin');
		this.onCurrentRatioChanged = this.onChange.bind(this, 'currentRatio');
		this.onSolidityChanged = this.onChange.bind(this, 'solidity');

		this.hasPro = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);

		this.offsets = [
			{ name: t('prospecting.filters.last1Year'), id: 1 },
			{ name: t('prospecting.filters.last2Year'), id: 2 },
			{ name: t('prospecting.filters.last3Year'), id: 3 },
			{ name: t('prospecting.filters.last4Year'), id: 4 }
		];
	}

	onOffsetChange = event => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.value.revenueChange.value.offset = event.target.added.id;
		onChange(newFilter.filterName, newFilter);
	};

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.value[key].value = value;
		onChange(newFilter.filterName, newFilter);
	}

	render() {
		const { filter, config, open } = this.props;
		const offset = filter.value.revenueChange.value.offset;
		const offsetObject = this.offsets.find(option => option.id === offset);

		const classes = new Bem('ProspectingDrilldown');

		return (
			<CriteriaDrilldownWrapper
				key={filter.filterName + '-drilldown'}
				className={classes.mod('financials').b()}
				open={open}
			>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/* Revenue row */}
								{config.fields.indexOf('revenue') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.revenue}</div>
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={
															filter.value.currency
																? filter.value.currency.iso
																: config.baseCurrency
														}
														space={true}
														filter={filter.value.revenue}
														onChange={this.onRevenueChanged}
														autoFocus={true}
													/>
												</div>
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* Revenue changed row */}
								{config.fields.indexOf('revenueChange') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.revenueChange}</div>
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={'%'}
														space={true}
														filter={filter.value.revenueChange}
														onChange={this.onRevenueChangeChanged}
														autoFocus={false}
														disabled={!this.hasPro}
													/>
													<ReactTemplates.INPUTS.upSelect
														className="form-control"
														disabled={!this.hasPro}
														data={this.offsets}
														required={true}
														multiple={false}
														onChange={this.onOffsetChange}
														defaultValue={offsetObject}
														options={{ minimumResultsForSearch: -1 }}
													/>
												</div>
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* Result row */}
								{config.fields.indexOf('result') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.result}</div>
											{this.hasPro ? null : (
												<Text color="bright-blue" size="sm">
													{this.lang.proFilter}
												</Text>
											)}
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={
															filter.value.currency
																? filter.value.currency.iso
																: config.baseCurrency
														}
														space={true}
														disabled={!this.hasPro}
														filter={filter.value.result}
														onChange={this.onResultChanged}
														autoFocus={false}
													/>
												</div>
												{this.hasPro ? null : (
													<Text
														className={classes.elem('pro-filter').b()}
														color="bright-blue"
														size="sm"
														italic={true}
													>
														{this.lang.upgradeToProToUse}
													</Text>
												)}
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* ebitda row */}
								{config.fields.indexOf('ebitda') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.ebitda}</div>
											{this.hasPro ? null : (
												<Text color="bright-blue" size="sm">
													{this.lang.proFilter}
												</Text>
											)}
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={
															filter.value.currency
																? filter.value.currency.iso
																: config.baseCurrency
														}
														space={true}
														disabled={!this.hasPro}
														filter={filter.value.ebitda}
														onChange={this.onEbitdaChanged}
														autoFocus={false}
													/>
												</div>
												{this.hasPro ? null : (
													<Text
														className={classes.elem('pro-filter').b()}
														color="bright-blue"
														size="sm"
														italic={true}
													>
														{this.lang.upgradeToProToUse}
													</Text>
												)}
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* profitMargin row */}
								{config.fields.indexOf('profitMargin') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.profitMargin}</div>
											{this.hasPro ? null : (
												<Text color="bright-blue" size="sm">
													{this.lang.proFilter}
												</Text>
											)}
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={'%'}
														space={true}
														disabled={!this.hasPro}
														filter={filter.value.profitMargin}
														onChange={this.onProfitMarginChanged}
														autoFocus={false}
													/>
												</div>
												{this.hasPro ? null : (
													<Text
														className={classes.elem('pro-filter').b()}
														color="bright-blue"
														size="sm"
														italic={true}
													>
														{this.lang.upgradeToProToUse}
													</Text>
												)}
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* solidity row */}
								{config.fields.indexOf('solidity') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.solidity}</div>
											{this.hasPro ? null : (
												<Text color="bright-blue" size="sm">
													{this.lang.proFilter}
												</Text>
											)}
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={'%'}
														space={true}
														disabled={!this.hasPro}
														filter={filter.value.solidity}
														onChange={this.onSolidityChanged}
														autoFocus={false}
													/>
												</div>
												{this.hasPro ? null : (
													<Text
														className={classes.elem('pro-filter').b()}
														color="bright-blue"
														size="sm"
														italic={true}
													>
														{this.lang.upgradeToProToUse}
													</Text>
												)}
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/* currentRatio row */}
								{config.fields.indexOf('currentRatio') > -1 ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.currentRatio}</div>
											{this.hasPro ? null : (
												<Text color="bright-blue" size="sm">
													{this.lang.proFilter}
												</Text>
											)}
										</td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<NumberInput
														unit={'%'}
														space={true}
														disabled={!this.hasPro}
														filter={filter.value.currentRatio}
														onChange={this.onCurrentRatioChanged}
														autoFocus={false}
													/>
												</div>
												{this.hasPro ? null : (
													<Text
														className={classes.elem('pro-filter').b()}
														color="bright-blue"
														size="sm"
														italic={true}
													>
														{this.lang.upgradeToProToUse}
													</Text>
												)}
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

FinancialsDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

import Resource from './Resource';

class AccountInformation extends Resource {
	constructor() {
		super('accountInformation', null);
	}

	getInformation(credentials) {
		return super
			._postRequest('', credentials, { methodName: 'getInformation' })
			.then(res => ({
				error: null,
				data: res.data
			}))
			.catch(error => ({
				error,
				data: null
			}));
	}
}

const resource = new AccountInformation();
window.Tools.AccountInformation = resource;
export default resource;

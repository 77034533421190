'use strict';
import SelectUserGroup from '../../../../babel/components/Modals/SelectUserGroup/SelectUserGroup';

angular.module('upListFilter.controllers').controller('FilterController', [
	'$scope',
	'$modalParams',
	'$injector',
	'RequestBuilder',
	'$timeout',
	'$stateParams',
	'$parse',
	'$safeApply',
	'$element',
	function ($scope, $modalParams, $injector, RequestBuilder, $timeout, $stateParams, $parse, $safeApply, $element) {
		var filterObj = angular.copy($modalParams.filterObj);
		var filterConfig = $modalParams.filterConfig;
		$scope.SelectUserGroup = SelectUserGroup;
		$scope.selectedRows = [];

		// Inits date filters
		var setInitDate = function () {
			if (!filterObj.value) {
				filterObj.value = {
					start: null,
					end: null,
					preset: 'whenever'
				};

				filterObj.valueText = '';
				filterObj.inactive = false;
			}

			$scope.dateChange = function (preset, value, valueText) {
				filterObj.value.start = value.start;
				filterObj.value.end = value.end;
				filterObj.value.preset = preset;
				filterObj.valueText = valueText;

				if (preset === 'whenever' && (!filterObj.value.status || filterObj.value.status === 'all')) {
					filterObj.inactive = true;
				} else {
					filterObj.inactive = false;
				}

				$modalParams.change(filterObj);
			};
			$scope.value = filterObj.value;
			$modalParams.change(filterObj);

			// Custom datePresets
			$scope.presets = '';
			if (filterConfig.presets && filterConfig.presets.length) {
				$scope.presets = filterConfig.presets.join(',');
			}
		};

		// toggle multi comparitors
		var useComparitors = function () {
			if (filterConfig.multiComparitors) {
				$scope.multiCompare = true;
				$scope.selectedComparitor = filterObj.comparisonType;

				$scope.toggleComparitor = function () {
					if (filterObj.comparisonType === 'Equals') {
						filterObj.comparisonType = 'NotEquals';
					} else {
						filterObj.comparisonType = 'Equals';
					}
					$scope.selectedComparitor = filterObj.comparisonType;
					$modalParams.change(filterObj);
				};
			}
		};

		var setComparisonType = function (type) {
			filterObj.comparisonType = type;
			$scope.selectedComparitor = type;
		};

		var init = function () {
			var setFilter;
			var typeTimer;
			var getter;
			var resource;

			switch (filterConfig.inputType) {
				case 'date':
					setInitDate();
					break;
				case 'boolean':
					if (filterObj.value === undefined) {
						filterObj.value = true;
						filterObj.inactive = false;
					}

					$scope.boolValue = filterObj.value;

					$scope.boolChange = function () {
						if ($scope.boolValue === filterObj.value) {
							return;
						}
						filterObj.value = $scope.boolValue;
						$modalParams.change(filterObj);
					};

					break;
				case 'radio':
					$scope.inactive = filterObj.inactive;

					$scope.value = filterObj.value;
					$scope.comparisonType = filterObj.comparisonType;

					$scope.radioChange = function (option) {
						if ($scope.value === option.value && $scope.comparisonType === option.comparisonType) {
							return;
						}

						if (option.inactive) {
							filterObj.inactive = true;
							$scope.inactive = true;
							filterObj.value = option.value;
							$scope.value = option.value;
						} else {
							filterObj.inactive = false;
							$scope.inactive = false;
							filterObj.value = option.value;
							$scope.value = option.value;
							$scope.comparisonType = option.comparisonType;
							filterObj.comparisonType = option.comparisonType;
						}

						$modalParams.change(filterObj);
					};

					break;
				case 'text':
					typeTimer = null;

					if (!filterObj.value) {
						filterObj.comparisonType = 'Search';
						filterObj.value = '';
					}

					$scope.searchStr = filterObj.value;

					setFilter = function () {
						filterObj.inactive = !$scope.searchStr.length;
						filterObj.valueText = filterObj.inactive ? $scope.title : $scope.searchStr + ' *';
						filterObj.value = $scope.searchStr;
						$modalParams.change(filterObj);
					};

					$scope.textChange = function () {
						if (typeTimer) {
							$timeout.cancel(typeTimer);
						}

						typeTimer = $timeout(setFilter, 300);
					};

					$scope.textEnter = function () {
						setFilter(1);
						$scope.resolve();
					};
					break;
				case 'range':
					typeTimer = null;

					if (!filterObj.value) {
						filterObj.value = { start: null, end: null };
						filterObj.valueText = '';
					}

					$scope.range = filterObj.value || {};

					setFilter = function () {
						filterObj.inactive = $scope.range.start === null && $scope.range.end === null;
						filterObj.value.start = $scope.range.start;
						filterObj.value.end = $scope.range.end;

						$modalParams.change(filterObj);
					};

					$scope.textChange = function () {
						if (typeTimer) {
							$timeout.cancel(typeTimer);
						}

						typeTimer = $timeout(setFilter, 300);
					};

					$scope.textEnter = function () {
						setFilter(1);
						$scope.resolve();
					};
					break;
				case 'selectGroup':
					if (!filterObj.value) {
						filterObj.comparisonType = 'Equals';
						filterObj.value = [];
					}

					useComparitors();

					$scope.comparisonType = filterObj.comparisonType;
					$scope.isSearch = filterConfig.search;
					$scope.hideAllOption = filterConfig.hideAllOption ? true : false;
					$scope.multiple = filterConfig.multiple;
					$scope.searchField = filterConfig.searchField;
					$scope.displayText = $parse(filterConfig.displayText);
					$scope.key = filterConfig.key || 'id';
					$scope.filter = '';
					$scope.groupParent = filterConfig.groupParent;
					$scope.groupParentTitle = filterConfig.groupParentTitle;

					if (!filterConfig.dataPromise) {
						resource = $injector.get(filterConfig.resource).customer($stateParams.customerId);
					}

					if ($scope.isSearch) {
						typeTimer = null;
						$scope.results = [];

						setSelectedRows($modalParams.meta.data);

						$scope.search = function () {
							$timeout.cancel(typeTimer);

							if ($scope.searchStr.length < 1) {
								return;
							}

							typeTimer = $timeout(function () {
								var filters = new RequestBuilder();
								getter = resource;

								if (filterConfig.resourceType) {
									getter = resource.setType(filterConfig.resourceType);
								}

								if (filterConfig.filters) {
									angular.forEach(filterConfig.filters, function (filter) {
										filters.addFilter({ field: filter.a }, filter.c, filter.v);
									});
								}

								filters.addFilter(
									{ field: filterConfig.searchField, type: 'string' },
									filters.comparisonTypes.Search,
									$scope.searchStr
								);

								resource
									.find(filters.build())
									.then(function (res) {
										$scope.results = res.data;
									})
									.catch(err => console.error(err));
							}, 500);
						};

						// Get init selection
						if (filterObj.value && filterObj.value.length) {
							getter = resource;
							var idFilter = new RequestBuilder();
							idFilter.addFilter({ field: $scope.key }, idFilter.comparisonTypes.Equals, filterObj.value);

							if (filterConfig.resourceType) {
								getter = resource.setType(filterConfig.resourceType);
							}

							getter
								.find(idFilter.build())
								.then(function (res) {
									setSelectedRows(res.data);
									setValueText();
								})
								.catch(err => console.error(err));
						}
					} else {
						$scope.results = $modalParams.meta.data;
						setSelectedRows($scope.results);
						setValueText();

						if ($scope.$modalParams.filterConfig.field === 'user.id') {
							const maxDepth = item => {
								if (item.children && item.children.length) {
									const childrenDepths = item.children.map(child => maxDepth(child));
									return Math.max(...childrenDepths) + 1;
								}
								return 0;
							};
							const depth = maxDepth({ children: $scope.results });
							$element.css('width', 250 + depth * 25);
						}
					}

					$scope.selectOne = function (obj) {
						$scope.unselectAll($scope.results);
						$scope.selectedRows = [];
						if (obj.children) {
							$scope.selectGroup(obj, true);
						} else {
							$scope.select(obj);
						}
						$scope.resolve();
					};

					$scope.selectGroup = function (group, select) {
						var helper = function (group, select) {
							angular.forEach(group.children, function (child) {
								if (child.children) {
									helper(child, select);
								}

								if (child.$$selected !== select && !child.children) {
									$scope.select(child);
								}
								child.$$selected = select;
							});
						};
						helper(group, select || group.$$selected);
					};

					$scope.checkGroupSelect = function (obj) {
						var helper = function (parent) {
							angular.forEach(parent.children, function (child) {
								if (child.children) {
									helper(child);
								}
							});

							parent.$$selected = _.every(parent.children, { $$selected: true });
						};

						if (obj.$$parent) {
							$scope.checkGroupSelect(obj.$$parent);
						} else if (obj.children) {
							helper(obj);
						}
					};
					$scope.checkGroupSelect({ children: $scope.results });

					break;

				case 'select':
					if (!filterObj.value) {
						filterObj.comparisonType = filterConfig.comparisonType || 'Equals';
						filterObj.value = [];
					}

					useComparitors();

					if (filterConfig.search && typeof filterConfig.search === 'function') {
						$scope.isSearch = $injector.invoke(filterConfig.search);
					} else {
						$scope.isSearch = filterConfig.search;
					}

					$scope.comparisonType = filterObj.comparisonType;
					$scope.multiple = filterConfig.multiple;
					$scope.searchField = filterConfig.searchField;
					$scope.displayText = $parse(filterConfig.displayText);
					$scope.key = filterConfig.key || 'id';
					$scope.filter = '';

					var searchFunction = null;

					if (filterConfig.searchFn) {
						searchFunction = $injector.invoke(filterConfig.searchFn);
					}

					if (filterConfig.resource) {
						resource = $injector.get(filterConfig.resource).customer($stateParams.customerId);
					}

					if ($scope.isSearch) {
						typeTimer = null;
						$scope.results = $modalParams.meta?.data?.length ? $modalParams.meta.data : [];

						$scope.search = function () {
							$timeout.cancel(typeTimer);

							if ($scope.searchStr.length < 1) {
								return;
							}

							typeTimer = $timeout(function () {
								var promise = null;
								if (searchFunction) {
									promise = searchFunction($scope.searchStr, [], 0, 50);
								} else {
									var filters = new RequestBuilder();
									var getter = resource;

									if (filterConfig.resourceType) {
										getter = resource.setType(filterConfig.resourceType);
									}

									if (filterConfig.filters) {
										angular.forEach(filterConfig.filters, function (filter) {
											filters.addFilter({ field: filter.a }, filter.c, filter.v);
										});
									}

									filters.addFilter(
										{ field: filterConfig.searchField, type: 'string' },
										filters.comparisonTypes.WildcardEnd,
										$scope.searchStr
									);

									promise = getter.find(filters.build());
								}

								promise
									.then(function (res) {
										$scope.results = res.data;
									})
									.catch(err => console.error(err));
							}, 500);
						};

						// Get init selection
						if (filterObj.value && filterObj.value.length) {
							if (filterConfig.getSelected) {
								var getSelected = $injector.invoke(filterConfig.getSelected);
								getSelected(filterObj.value)
									.then(function (rows) {
										setSelectedRows(rows);
										setValueText();
									})
									.catch(err => console.error(err));
							} else {
								getter = resource;
								var getterIdFilter = new RequestBuilder();
								getterIdFilter.addFilter(
									{ field: $scope.key },
									getterIdFilter.comparisonTypes.Equals,
									filterObj.value
								);

								if (filterConfig.resourceType) {
									getter = resource.setType(filterConfig.resourceType);
								}

								getter
									.find(getterIdFilter.build())
									.then(function (res) {
										setSelectedRows(res.data);
										setValueText();
									})
									.catch(err => console.error(err));
							}
						}
					} else {
						$scope.results = $modalParams.meta.data;
						setSelectedRows($scope.results);
						setValueText();

						if ($scope.$modalParams.filterConfig.field === 'currency') {
							$element.addClass('salesboard-filter');
							$element.css('width', 200);
						}
					}

					$scope.selectOne = function (obj) {
						$scope.unselectAll($scope.results);
						$scope.selectedRows = [];
						$scope.select(obj);
						$scope.resolve();
					};

					break;

				case 'custom': {
					$scope.field = _.find($modalParams.customFields, { id: $modalParams.filterConfig.field });
					const users = Tools.AppService.getActiveUsers();

					var isNumRange =
						$scope.field.datatype === 'Integer' ||
						$scope.field.datatype === 'Percent' ||
						$scope.field.datatype === 'Currency';
					if (!filterObj.value && isNumRange) {
						filterObj.value = { start: null, end: null };
						filterObj.inactive = true;
						filterObj.valueText = '';
					} else if ($scope.field.datatype === 'Date') {
						setInitDate();
					}
					if (!filterObj.value && $scope.field.datatype === 'Time') {
						filterObj.value = { start: '', end: '' };
						filterObj.inactive = true;
						filterObj.valueText = '';
					} else if ($scope.field.datatype === 'User' || $scope.field.datatype === 'Users') {
						const mapped = [];
						(filterObj.value || []).forEach(id => {
							const user = users.find(u => u.id === parseInt(id));
							if (user) {
								mapped.push({ id: user.id, text: user.name });
							}
						});
						if ($scope.field.datatype === 'User') {
							$scope.field.value = mapped[0] || null;
						} else {
							$scope.field.value = mapped;
						}
					} else {
						$scope.field.value = filterObj.value || '';
					}

					setFilter = function () {
						filterObj.inactive = !$scope.field.value;

						if (
							(($scope.field.datatype === 'String' || $scope.field.datatype === 'Text') &&
								!$scope.field.value.length) ||
							(isNumRange && filterObj.value.start === null && filterObj.value.end === null)
						) {
							filterObj.inactive = true;
						}
						if ($scope.field.datatype === 'User') {
							filterObj.value = $scope.field.value ? [$scope.field.value.id.toString()] : [];
						} else if ($scope.field.datatype === 'Users') {
							filterObj.value = $scope.field.value.map(({ id }) => id.toString());
						} else {
							filterObj.value = $scope.field.value;
						}
						$modalParams.change(filterObj);
					};

					$scope.customChange = function () {
						setFilter();
					};

					$scope.customRadioChange = function (value) {
						filterObj.value = value;
						$scope.field.value = value;

						if (value === null) {
							$scope.inactive = true;
							filterObj.inactive = true;
						} else {
							$scope.inactive = false;
							filterObj.inactive = false;
						}

						$modalParams.change(filterObj);
					};

					$scope.textEnter = function () {
						setFilter();
						$scope.resolve();
					};

					if ($scope.field.datatype === 'Boolean') {
						$scope.customRadioChange(filterObj.value);
					}
					if ($scope.field.datatype === 'Select' && !$scope.field.value) {
						$scope.field.value = [];
					}
					break;
				}
			}
			$scope.selectedValue = filterObj.value;
			$scope.title = filterConfig.title;
			$scope.listTitle = filterConfig.listTitle;
			$scope.lockedFilter = filterObj.locked;
		};

		function setSelectedRows(rows) {
			var setSelectedRowsHelper = function (obj) {
				if (obj.children) {
					angular.forEach(obj.children, setSelectedRowsHelper);
				} else if (obj[$scope.key]) {
					var id = obj[$scope.key];
					var index = _.findIndex(filterObj.value, function (val) {
						return val === id || parseInt(val) === id;
					});

					obj.$$selected = index !== -1;
					if (obj.$$selected) {
						$scope.selectedRows.push(obj);
					}
				}
			};

			$scope.selectedRows = [];
			angular.forEach(rows, function (child) {
				setSelectedRowsHelper(child);
			});
		}

		$scope.getKey = function (obj) {
			var key = $parse($scope.key)(obj);
			return key ? key : undefined;
		};

		$scope.change = function () {
			// default change
		};

		$scope.selected = function (obj) {
			/* eslint-disable eqeqeq */
			if (obj == 0 && _.isArray(filterObj.value) && !filterObj.value.length) {
				/* eslint-enable eqeqeq */
				return true;
				/* eslint-disable eqeqeq */
			} else if (obj == 0) {
				/* eslint-enable eqeqeq */
				return false;
			}

			var id = obj[$scope.key].toString();
			var index = _.findIndex(filterObj.value, id);
			return index !== -1;
		};
		$scope.unselectAll = function (root) {
			angular.forEach(root, function (child) {
				if (child.children) {
					$scope.unselectAll(child.children);
				}
				if (!child.children && child.$$selected) {
					child.$$selected = false;
				}
			});
			$scope.selectedRows = [];
			filterObj.value = [];
			filterObj.valueText = '';
		};

		$scope.selectAll = function (value, keep) {
			$scope.unselectAll($scope.results);
			filterObj.valueText = '';
			filterObj.inactive = true;
			filterObj.value = [];
			$scope.selectedRows = [];
			setComparisonType(filterConfig.comparisonType || 'Equals');
			$modalParams.change(filterObj);
			$scope.allSelected = true;
			if (!keep) {
				$scope.resolve();
			}
		};

		function setValueText() {
			if (!filterObj.value.length) {
				$scope.selectAll('', true);
				$scope.selectedRows = [];
			} else {
				// set selected
				if (typeof filterConfig.searchField === 'function') {
					filterObj.valueText = filterConfig.searchField($scope.selectedRows[0]);
				} else {
					filterObj.valueText = _.get($scope.selectedRows[0], filterConfig.searchField, '');
				}
				$scope.firstSelectedText = _.get($scope.selectedRows[0], filterConfig.searchField, '');
			}
		}

		$scope.select = function (obj) {
			$scope.allSelected = false;
			filterObj.inactive = false;

			var key = $scope.getKey(obj);
			if ($scope.multiple) {
				// remove filter
				var valIndex = filterObj.value.indexOf(key);
				// If item is selected
				if (valIndex !== -1) {
					var filter = {};
					filter[$scope.key] = key;

					var index = _.findIndex($scope.selectedRows, filter);

					$scope.selectedRows.splice(index, 1);
					filterObj.value.splice(valIndex, 1);
					setValueText();
					$modalParams.change(filterObj);
					obj.$$selected = false;
				} else {
					filterObj.value.push(key);
					$scope.selectedRows.push(obj);
					setValueText();
					$modalParams.change(filterObj);
					obj.$$selected = true;
				}
			} else {
				filterObj.value = [key];
				$scope.selectedRows = [obj];
				setValueText();
				$modalParams.change(filterObj);
				$scope.resolve();
			}
			if (filterConfig.inputType === 'selectGroup') {
				$scope.checkGroupSelect(obj);
			}
			$safeApply($scope);
		};

		$scope.removeFilter = function () {
			$scope.$root.$broadcast('listView.filterRemoved', filterObj.filterName);
			$scope.reject();
			$safeApply($scope);
		};

		init();
	}
]);

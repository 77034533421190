import { Row } from '@upsales/components';
import React, { useEffect, useMemo, useState } from 'react';
import AccountListContacts from 'Components/Account/AccountListContacts/AccountListContacts';
import AccountListBoardOfDirectors from 'Components/Account/AccountListContacts/AccountListBoardOfDirectors';
import BemClass from '@upsales/components/Utils/bemClass';
import './Contacts.scss';
import type Client_ from 'App/resources/Model/Client';
import RequestBuilder from 'Resources/RequestBuilder';
import ContactResource from 'App/resources/Contact';
import type Contact_ from 'App/resources/Model/Contact';
import ContactAttributes from 'App/babel/attributes/Contact';
import logError from 'Helpers/logError';
import ComparisonTypes from 'Resources/ComparisonTypes';
import _ from 'lodash';

type Client = Pick<
	Client_,
	| 'id'
	| 'name'
	| 'journeyStep'
	| 'phone'
	| 'users'
	| 'active'
	| 'createRights'
	| 'prospectingId'
	| 'prospecting'
	| 'description'
>;

type Contact = {
	relatedDescription?: string;
	connectedClients?: Client[];
	relatedAccount?: Contact['client'];
	titleCategory?: {
		value?: string;
	};
} & Contact_;

type Delimiter = {
	delimiter: true;
	name: string;
};

const ClientCardContacts = ({ client, subAccountIds = [] }: { client: Client; subAccountIds?: number[] }) => {
	const accountId = client.id;

	const [sortByRelevance, setSortByRelevance] = useState(true);
	const [showInactive, setShowInactive] = useState(false);
	const [allContacts, setAllContacts] = useState<Contact[]>([]);
	const [searchStr, setSearchStr] = useState('');
	const [loading, setLoading] = useState(true);

	const shownContacts = useMemo(() => {
		const hasContactTitleCategoryAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		const search = searchStr.toLowerCase();
		const contacts = allContacts.filter(contact => {
			const nameCheck = contact.name.toLowerCase().includes(search);
			const titleCategoryCheck =
				hasContactTitleCategoryAccess && contact.titleCategory?.value?.toLowerCase().includes(search);
			const titleCheck = contact.title?.toLowerCase().includes(search);
			return (
				(showInactive || (!showInactive && contact.active)) &&
				(!search || (search.length && (nameCheck || titleCategoryCheck || titleCheck)))
			);
		});

		contacts.forEach(contact => {
			if (!contact.client.id || contact.client.id === accountId) {
				return;
			}
			const relatedClient = _.find(contact.connectedClients ?? [], { relatedToClientId: accountId });
			contact.relatedDescription = relatedClient?.description || '';
			contact.relatedAccount = contact.client;
		});

		const finalContacts: (Contact | Delimiter)[] = sortByRelevance
			? contacts
			: (() => {
					contacts.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
					const delimContacts: (Contact | Delimiter)[] = [];
					let lastDelim: string | undefined = undefined;
					contacts.forEach(contact => {
						const name = contact.name?.toLowerCase();
						if (name && lastDelim !== name[0]) {
							const delim = name[0];
							delimContacts.push({ delimiter: true, name: delim });
							lastDelim = delim;
						}
						delimContacts.push(contact);
					});
					return delimContacts;
			  })();
		return finalContacts;
	}, [allContacts, sortByRelevance, showInactive, searchStr]);

	const getContacts = async () => {
		const CHUNK_SIZE = 1000;

		setLoading(true);
		setAllContacts([]);

		// CONSIDER metadata should possibly already be calculated from header
		getContactPromise({ offset: 0, limit: 0 })
			.then(res => {
				const total = res.metadata.total;
				const chunkAmount = Math.ceil(total / CHUNK_SIZE);
				const promises = Array(chunkAmount)
					.fill(undefined)
					.map((_, i) => getContactPromise({ offset: i * CHUNK_SIZE, limit: CHUNK_SIZE }));
				return Promise.all(promises);
			})
			.then(res => {
				setAllContacts(res.map(obj => obj.data).flat());
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const updateIfRelevant = (e: unknown, contact: Contact) => {
		if (contact.client && (contact.client.id === accountId || subAccountIds?.includes(contact.client.id))) {
			getContacts();
		}
	};

	useEffect(() => {
		getContacts();
		const listeners = [
			Tools.$rootScope.$on('contact.added', updateIfRelevant),
			Tools.$rootScope.$on('contact.updated', updateIfRelevant),
			Tools.$rootScope.$on('contact.deleted', getContacts),
			Tools.$rootScope.$on('account.merged', function (e, res) {
				if (res.merged.id === accountId) {
					getContacts();
				}
			})
		];
		return () => listeners.forEach(unsub => unsub());
	}, []);

	useEffect(() => {
		getContacts();
	}, [sortByRelevance]);

	function getContactPromise(opts: { limit: number; offset: number }): Promise<any> {
		if (sortByRelevance) {
			const options = {
				limit: opts.limit,
				offset: opts.offset,
				accountId: accountId,
				subAccountIds
			};

			return ContactResource.findByRelevance(options);
		} else {
			const filter = new RequestBuilder();
			const or = filter.orBuilder();
			or.next();
			or.addFilter(ContactAttributes.account, ComparisonTypes.Equals, [accountId, ...(subAccountIds ?? [])]);
			or.next();
			or.addFilter(ContactAttributes.connectedClients, ComparisonTypes.Equals, [
				accountId,
				...(subAccountIds ?? [])
			]);
			or.done();
			filter.addSort(ContactAttributes.name, true);
			filter.addSort(ContactAttributes.active, false);
			filter.limit = opts.limit;
			filter.offset = opts.offset;

			return ContactResource.find(filter.build());
		}
	}

	const actions = {
		editContact: (contact: Contact) => Tools.$upModal.open('editContact', { id: contact.id }),
		removeContact: (contact: Contact) => {
			try {
				ContactResource.delete(contact.id);
			} catch (err) {
				logError(err, `[AccountListContacts] failed to remove the contact with id ${contact.id}`);
			}
		},
		openContact: (contactId: number) => Tools.$state.go('contact.dashboard', { id: contactId }),
		toogleSearchBy: (relevance: boolean) => {
			setSortByRelevance(() => relevance);
		},
		toogleshowInactiveContacts: (val: boolean) => {
			setShowInactive(() => val);
		},
		searchChanged: (str: string) => {
			setSearchStr(() => str);
		},
		createContact: function () {
			var params = {
				customerId: Tools.AppService.getCustomerId(),
				account: client
			};
			Tools.$upModal.open('editContact', params);
		}
	};

	const hasProspectingPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
	const canUpgradeToPro =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC) &&
		Tools.FeatureHelper.hasSoftDeployAccess('BILLING_ADDONS') &&
		!!Tools.AppService.getSelf().billingAdmin;
	const showDirectors =
		(hasProspectingPro || canUpgradeToPro) && client?.prospecting?.directors && client.prospectingId;

	return (
		<Row className={new BemClass('ClientCardContent').elem('Contacts').b()}>
			<AccountListContacts
				actions={actions}
				account={client}
				accountId={accountId}
				searchStr={searchStr}
				tableLoading={loading}
				searchByRelevance={sortByRelevance}
				showInactiveContacts={showInactive}
				accountContacts={shownContacts}
				subAccountIds={subAccountIds}
			/>
			{showDirectors ? (
				<AccountListBoardOfDirectors
					directors={client.prospecting?.directors}
					contact={ContactResource}
					accountId={accountId}
					accountProspectingId={client.prospectingId}
					accountName={client.name}
				/>
			) : null}
		</Row>
	);
};

export default ClientCardContacts;

import React from 'react';
import { Tabs, Tab } from '@upsales/components';

class NavTabs extends React.Component {
	constructor(props) {
		super(props);

		this.state = { selectedTab: 'address-tab' };
	}

	componentDidMount() {
		const element = document.getElementById('admin-root');

		if (element) {
			element.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillUnmount() {
		const element = document.getElementById('admin-root');

		if (element) {
			element.removeEventListener('scroll', this.handleScroll);
		}
	}

	handleScroll = () => {
		const container = document.getElementById('admin-root');
		const headerHeight = document.querySelector('.AdminHeader__text-wrap').offsetHeight;
		const tabContentItems = document.querySelectorAll('div[id*=tab-content]');
		// Get id of current scroll item
		const scrollItems = [].filter.call(
			tabContentItems,
			element => element.offsetTop + headerHeight - (window.innerWidth > 1600 ? 50 : 0) < container.scrollTop
		);
		// Get the id of the current element
		const currentSection = scrollItems[scrollItems.length - 1];
		const selectedTab = (currentSection ? currentSection.id : tabContentItems[0].getAttribute('id')).replace(
			'-content',
			''
		);

		if (this.state.selectedTab !== selectedTab) {
			this.setState({ selectedTab: selectedTab });
		}
	};

	handleTabChange = tab => {
		const rootEl = document.querySelector('#admin-root');
		const tabContent = document.querySelector(`#${tab}-content`);
		tabContent.scrollIntoView({ block: 'start', behavior: 'smooth' });

		const offsetTop =
			tabContent.offsetTop +
			document.querySelector('.AdminHeader__text-wrap').offsetHeight +
			(window.innerWidth > 1600 ? -40 : 17);

		rootEl.scrollTo({
			top: offsetTop,
			behavior: 'smooth'
		});

		this.setState({ selectedTab: tab });
	};

	render() {
		const t = Tools.$translate;

		return (
			<Tabs selected={this.state.selectedTab} onChange={this.handleTabChange}>
				<Tab id="address-tab" title={t('admin.accountProfile.location')} />
				<Tab id="logo-tab" title={t('admin.logo')} />
				<Tab id="colors-tab" title={t('admin.colors')} />
				<Tab id="custom-fonts-tab" title={t('admin.accountProfile.customFonts')} />
				<Tab id="typography-tab" title={t('admin.typography')} />
			</Tabs>
		);
	}
}

window.AdminAccountProfileNavTabs = NavTabs;

export default NavTabs;

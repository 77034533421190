'use strict';

(function () {
	var getData = function (type, showParents) {
		return function () {
			var categories = Tools.AppService.getCategories(type);

			if (!showParents) {
				return categories;
			}

			var categoryTypes = Tools.AppService.getCategoryTypes(type);
			var categoryTypeMap = categoryTypes.reduce(function (result, categoryType) {
				result[categoryType.id] = categoryType;
				categoryType.children = [];
				delete categoryType.id;
				return result;
			}, {});

			var result = [];
			categories.forEach(function (category) {
				var parent = categoryTypeMap[category.categoryType];

				if (category.categoryType === 0 || !parent) {
					result.push(category);
				} else if (parent) {
					parent.children.push(category);
				}
			});

			return result.concat(categoryTypes);
		};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var Category = Tools.Category;

		var opts = {
			allowClear: self.props.allowClear || false,
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			onChange: self.props.onChange,
			placeholder: function () {
				return self.props.placeholder;
			},
			ajax: false,
			asIds: false,
			idAttr: Category.attr.id,
			titleAttr: Category.attr.name,
			sorting: { field: Category.attr.name, ascending: true },
			filterType: 'category',
			data: getData(self.props.type, self.props.showParents)
		};

		if (self.props.data) {
			opts.data = undefined;
			opts.select2 = {};
			opts.select2.ajax = {
				data: function (term) {
					return term;
				},
				transport: function (query) {
					var data = self.props.data;
					if (query.data && query.data.length) {
						data = _.filter(self.props.data, function (item) {
							return item.name.toLowerCase().indexOf(query.data.toLowerCase()) !== -1;
						});
					}

					return query.success(data);
				},
				results: function (data) {
					return { results: data };
				}
			};
		}

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upCategories = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	text: PropTypes.string,
	type: PropTypes.string.isRequired,
	noLinks: PropTypes.bool,
	target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
	onClick: PropTypes.func,
	sub: PropTypes.any,
	style: PropTypes.object
};

const hrefTypes = {
	contact: 'contacts',
	contacts: 'contacts',
	account: 'accounts',
	accounts: 'accounts',
	campaign: 'campaigns',
	campaigns: 'campaigns'
};

const translates = {
	'default.noName': ['contact', 'contacts', 'account', 'accounts', 'campaign', 'campaigns'],
	'default.noInfo': []
};

class GenericHref extends React.Component {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(event) {
		if (event.stopPropagation) {
			event.stopPropagation();
		} else {
			event.returnValue = false;
		}

		if (this.props.onClick && typeof this.props.onClick === 'function') {
			this.props.onClick(event);
		}
	}

	getNoneTranslate() {
		_.each(translates, (list, key) => {
			if (list.indexOf(this.props.type) !== -1) {
				return "'" + key + "'";
			}
		});
	}

	formatText(text) {
		text = (text || '').trim();

		if ((!text.length || text === ' ') && !this.props.sub) {
			text = <i>{Tools.$translate(this.getNoneTranslate())}</i>;
		}

		return text;
	}

	render() {
		const text = this.formatText(this.props.text);
		const type = hrefTypes[this.props.type];

		const url = `#/${this.props.customerId || Tools.AppService.getCustomerId()}/${type}/${this.props.id}/${
			this.props.sub
		}`;

		if (typeof this.props.noLinks === 'boolean' && this.props.noLinks === true) {
			return (
				<a
					style={{ cursor: 'default' }}
					onClick={event => {
						if (event.stopPropagation) {
							event.stopPropagation();
						} else {
							event.returnValue = false;
						}
					}}
				>
					{text}
				</a>
			);
		}

		if (this.props.target) {
			return (
				<a href={url} onClick={this.onClick} style={this.props.style} target={this.props.target}>
					{text}
				</a>
			);
		}

		return (
			<a href={url} onClick={this.onClick} style={this.props.style}>
				{text}
			</a>
		);
	}
}

GenericHref.propTypes = propTypes;
GenericHref.defaultProps = {
	sub: '',
	target: '_self'
};
export default GenericHref;

'use strict';

angular.module('upResources').factory('InviteMeta', [
	'$q',
	'InviteCode',
	'$location',
	function ($q, InviteCode, $location) {
		return function () {
			var search = $location.search();
			if (search.code && typeof search.code === 'string' && search.code.length === 40) {
				var defer = $q.defer();
				InviteCode.get(search.customer, search.code)
					.then(function (res) {
						if (!res || !res.email) {
							return defer.resolve({ validCode: false, language: search.lang });
						}
						return defer.resolve({
							validCode: true,
							language: search.lang,
							user: res,
							customer: search.customer,
							code: search.code
						});
					})
					.catch(function () {
						// Parse different errors here
						return defer.resolve({ validCode: false, language: search.lang });
					});

				return defer.promise;
			} else {
				return $q.when({ validCode: false, language: search.lang });
			}
		};
	}
]);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, DropDownMenu, Icon, Table, TableRow, TableColumn, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import t from 'Components/Helpers/translate';

import './MailTemplateHeader.scss';

const baseClass = 'MailTemplateHeader';

class MailTemplateHeader extends Component {
	renderCancelButton = () => {
		const { template, editorMode, isModal, saving, abort } = this.props;
		const cancelButtonText = t(
			!template.id && template.bodyJson && editorMode === 'dragDrop' && !isModal
				? 'mail.changeTemplateConfirm'
				: 'default.abort'
		);
		return (
			<Button type="link" color="bright-blue" disabled={saving} onClick={abort}>
				{cancelButtonText}
			</Button>
		);
	};

	renderPreviewButton = () => {
		const { editorMode, template } = this.props;
		return editorMode === 'dragDrop' && template.bodyJson === null ? null : (
			<Button
				type="lined"
				color="bright-blue"
				disabled={this.props.saving || !this.props.template.from || this.props.uploader.isUploading}
				onClick={this.props.preview}
			>
				<Icon name="eye" className={new BemClass(baseClass).elem('icon').b()} />
				{t('mail.preview')}
			</Button>
		);
	};

	renderSaveButton = () => {
		const {
			saving,
			uploader,
			save,
			isModal,
			isFromSocialEvent,
			editorMode,
			template,
			removeDefaultTemplate
		} = this.props;
		const saveOptions = [];

		if (editorMode === 'dragDrop' && template.bodyJson === null && !isModal) {
			// Hide save when browsing templates, if not modal
			return null;
		}

		saveOptions.push({
			title: t('default.save'),
			subtitle: t('mail.saveAndKeepEditing'),
			onClick: () => save(null, true)
		});

		if (template.id && !isFromSocialEvent) {
			saveOptions.push({ title: t('mail.saveAsNew'), onClick: () => save('new') });
		}
		if (isFromSocialEvent && !template.isDefaultTemplate) {
			saveOptions.push({
				title: t('mail.saveAsDefaultTemplate'),
				subtitle: t('mail.saveforFutureEvents'),
				onClick: () => save(false, false, true)
			});
		}

		if (isFromSocialEvent) {
			saveOptions.push({
				title: t('mail.removeAsDefaultTemplate'),
				subtitle: t('mail.useStandardTemplate'),
				onClick: () => removeDefaultTemplate()
			});
		}

		const saveButton = (
			<Button color="bright-blue" onClick={() => save(null)}>
				{saving ? <Icon name="spinner" spin className={new BemClass(baseClass).elem('icon').b()} /> : null}
				{t(saving ? 'default.saving' : 'mail.saveAndExit')}
			</Button>
		);

		if (saveOptions.length) {
			return (
				<DropDownMenu
					align="right"
					renderTrigger={(isExpanded, setExpanded) => (
						<ButtonGroup>
							{saveButton}
							<Button
								disabled={saving || uploader.isUploading}
								onClick={setExpanded}
								color={isExpanded ? 'white' : 'bright-blue'}
								className={new BemClass(baseClass).elem('dropdown-caret').b()}
							>
								<Icon name={`caret-${isExpanded ? 'up' : 'down'}`} />
							</Button>
						</ButtonGroup>
					)}
				>
					{close => (
						<Table>
							{saveOptions.map(({ onClick, ...props }, i) => (
								<TableRow onClick={() => close() || onClick()} key={'save-option-' + i}>
									<TableColumn {...props} size="lg" />
								</TableRow>
							))}
						</Table>
					)}
				</DropDownMenu>
			);
		} else {
			return saveButton;
		}
	};

	renderExpandButton = () => (
		<Button type="link" color="bright-blue" onClick={this.props.toggleFullscreen}>
			<Icon name={this.props.isFullscreen ? 'compress' : 'expand'} />
		</Button>
	);

	render() {
		const { isModal, isFullscreen, template, isFromSocialEvent } = this.props;
		const classes = new BemClass(baseClass).mod({
			'border-top': isModal,
			fullscreen: isFullscreen || isModal,
			compiled: !isModal
		});
		return (
			<div className={classes.b()}>
				<div className={new BemClass(baseClass).elem('title').b()}>
					<Title color="blue">
						{isFromSocialEvent
							? t('socialEvent.automatedEmail.title')
							: `${t(template.id ? 'default.edit' : 'default.create')} ${t(
									'mail.template'
							  ).toLowerCase()}`}
					</Title>
				</div>
				<div className={new BemClass(baseClass).elem('actions').b()}>
					{this.renderCancelButton()}
					{this.renderPreviewButton()}
					{this.renderSaveButton()}
					{isModal ? null : this.renderExpandButton()}
				</div>
			</div>
		);
	}
}

MailTemplateHeader.propTypes = {
	save: PropTypes.func,
	abort: PropTypes.func,
	preview: PropTypes.func,
	saveAsSocialEventTemplate: PropTypes.func,
	removeDefaultTemplate: PropTypes.func,
	toggleFullscreen: PropTypes.func,
	template: PropTypes.object,
	uploader: PropTypes.object,
	saving: PropTypes.bool,
	isModal: PropTypes.bool,
	isFullscreen: PropTypes.bool,
	isFromSocialEvent: PropTypes.bool,
	editorMode: PropTypes.string
};

MailTemplateHeader.defaultProps = {
	template: {},
	uploader: {}
};

export default MailTemplateHeader;

'use strict';

angular.module('upResources').factory('ProductCategory', [
	'$resource',
	'$q',
	'ProductCategoryAttributes',
	'URL',
	'API',
	'CacheService',
	'ParseGeneric',
	'ResourceHelper',
	function Product($resource, $q, ProductCategoryAttributes, URL, API, CacheService, ParseGeneric, ResourceHelper) {
		var Attribute = ProductCategoryAttributes().getAll();

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'productCategory',
			createSuccessBody: 'saved.productCategory',
			updateSuccessBody: 'updated.productCategory',
			deleteSucccessBody: 'deleted.productCategory',
			createErrorBody: 'saveError.productCategory',
			updateErrorBody: 'saveError.productCategory',
			deleteErrorBody: 'deleteError.productCategory'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var ProductCategory = null;
				var Cache = CacheService.getCache(CacheService.cacheTypes.PRODUCTCATEGORY);
				ProductCategory = $resource(
					URL + API + customer + '/productCategories/:id',
					{},
					{
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseProductCategory', { isArray: true })
						},
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseProductCategory', { isArray: false })
						},
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.find = function (filter, options) {
					var defered = $q.defer();
					var params = angular.extend(filter, options);

					ProductCategory.find(params, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.get = function (id) {
					var defered = $q.defer();

					ProductCategory.get({ id: id }, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.save = function (product, options) {
					var defered = $q.defer();

					if (product && product.id) {
						ProductCategory.update(
							{ id: product.id },
							product,
							function (response) {
								defered.resolve(response);
							},
							function (response) {
								defered.reject(response);
							}
						);
					} else {
						ProductCategory.create(
							product,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							helper.onSave(options, res.data, !product.id);
						})
						.catch(function (err) {
							helper.onSaveError(options, !product.id, err);
						});

					return defered.promise;
				};

				instance.getCached = function () {
					var key = 'ProductCategories';
					var cached = Cache.get(key);

					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find({}).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				instance['delete'] = function (category, options) {
					return ProductCategory.delete({ id: category.id })
						.$promise.then(function (res) {
							helper.onDelete(options, category);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options);
							return err;
						});
				};

				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);

import React, { useState, useRef, useLayoutEffect } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { SlideFade, Fade } from '../animations';
import moment from 'moment';

import { Text, Toggle, Card, NumberInput, Tooltip, Block } from '@upsales/components';

import './NoticePeriod.scss';

const DEFAULT_NOTICEPERIOD = 0;

type NoticePeriodProps = {
	noticePeriod: number;
	setNoticePeriod: (noticePeriod: number) => void;
	agreementInvoiceStartDate: string;
	isValidNoticePeriod: boolean;
	agreementRenewalDate: string;
	agreementIntervalPeriod: number | string; // Angularjs select is converting value to string. When we move to react, we should enforce only number type.
	agreementPeriodLength: number;
};

const NoticePeriod = ({
	noticePeriod,
	setNoticePeriod,
	agreementRenewalDate,
	isValidNoticePeriod,
	agreementInvoiceStartDate,
	agreementIntervalPeriod,
	agreementPeriodLength
}: NoticePeriodProps) => {
	const classes = new BemClass('NoticePeriod');
	const [toggleActive, setToggleActive] = useState(noticePeriod > 0);
	const inputRef = React.useRef<HTMLInputElement>(null); // using React.useRef here to make it easier to mock in test
	const [displayedNoticePeriod, setDisplayedNoticePeriod] = useState(noticePeriod);
	const mounted = useRef(false);

	const updateNoticePeriod = (np: number) => {
		setDisplayedNoticePeriod(np);
		setNoticePeriod(np);
	};

	useLayoutEffect(() => {
		// we don't want to trigger this on mount
		if (toggleActive && mounted.current) {
			setTimeout(() => {
				inputRef.current?.focus();
				inputRef.current?.select();
			}, 200);
		}
		if (!mounted.current) {
			mounted.current = true;
		}
	}, [toggleActive]);

	const getNextNoticePeriod = () => {
		let endOfPeriodDate;
		if (agreementRenewalDate) {
			endOfPeriodDate = moment(agreementRenewalDate);
		} else {
			const intervalPeriod =
				typeof agreementIntervalPeriod === 'string'
					? parseInt(agreementIntervalPeriod)
					: agreementIntervalPeriod;
			endOfPeriodDate = moment(agreementInvoiceStartDate).add(intervalPeriod, 'M');
		}
		return endOfPeriodDate.subtract(displayedNoticePeriod, 'M').format('L');
	};

	const onToggle = () => {
		let newNoticePeriod = displayedNoticePeriod;
		if (!displayedNoticePeriod) {
			newNoticePeriod = 1;
			setDisplayedNoticePeriod(newNoticePeriod);
		}
		setNoticePeriod(!toggleActive ? newNoticePeriod : 0);
		setToggleActive(!toggleActive);
	};

	return (
		<Card
			onClick={!toggleActive ? () => setToggleActive(true) : undefined}
			className={classes.mod({ toggleClosed: !toggleActive }).b()}
		>
			<div className={classes.elem('addNoticePeriod').b()}>
				<Toggle checked={toggleActive} onChange={onToggle} />
				<Text onClick={() => onToggle()}>{T('agreement.noticePeriod.addNoticePeriod')}</Text>
			</div>
			<SlideFade visible={toggleActive} height maxHeight={150}>
				<Block space={toggleActive ? 'mtl' : undefined}>
					<div className={classes.elem('selectDate').b()}>
						<Text>{T('agreement.noticePeriod.mustGiveNoticeBy')}</Text>
						<Tooltip disabled={isValidNoticePeriod} title={T('agreement.noticePeriod.invalidNoticePeriod')}>
							<NumberInput
								min={1}
								max={99}
								align="center"
								ref={inputRef}
								state={isValidNoticePeriod ? undefined : 'error'}
								value={displayedNoticePeriod}
								onChange={e => {
									updateNoticePeriod(e || DEFAULT_NOTICEPERIOD);
								}}
							/>
						</Tooltip>
						<Text>
							{T(
								displayedNoticePeriod === 1
									? 'agreement.noticePeriod.monthsBeforeRenewalSingular'
									: 'agreement.noticePeriod.monthsBeforeRenewal'
							)}
						</Text>
					</div>
					<Fade visible={!isValidNoticePeriod}>
						<Text size="sm" color="red" italic>
							{T('agreement.noticePeriod.invalidNoticePeriod')}
						</Text>
					</Fade>
					{agreementPeriodLength > 0 ? (
						<div>
							<Text bold>{T('agreement.noticePeriod.lastDayOfNotice')}</Text>
							<Text bold size="xl">
								{getNextNoticePeriod()}
							</Text>
						</div>
					) : null}
				</Block>
			</SlideFade>
		</Card>
	);
};

export default NoticePeriod;

import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List, WindowScroller, ListRowRenderer, Index } from 'react-virtualized';

import 'react-virtualized/styles.css';
import './AccountList.scss';

type AccountListProps = {
	onScroll: (props: { scrollTop: number }) => void;
	size: number;
	marginTop: number;
	getRowHeight: (number | ((params: Index) => number)) & (number | ((info: Index) => number));
	rowRenderer: ListRowRenderer;
};

class AccountList extends React.Component<AccountListProps> {
	static propTypes = {
		size: PropTypes.number,
		marginTop: PropTypes.number.isRequired,
		getRowHeight: PropTypes.func.isRequired,
		rowRenderer: PropTypes.func.isRequired,
		onScroll: PropTypes.func
	};

	static defaultProps = {
		marginTop: 0
	};

	list: List | null | undefined;

	onScroll = () => {
		const { scrollTop } = document.documentElement;
		this.props.onScroll({ scrollTop });
	};

	componentDidUpdate(prevProps: AccountListProps) {
		if (this.props.size !== prevProps.size && this.props.size && this.list) {
			this.list.measureAllRows();
			this.list.recomputeRowHeights();
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}

	render() {
		const { marginTop, size, getRowHeight, rowRenderer } = this.props;
		return (
			<div className="AccountList" style={{ marginTop: `${marginTop}px` }}>
				<WindowScroller>
					{({ height, isScrolling, registerChild, scrollTop }) => (
						<AutoSizer disableHeight>
							{({ width }) => (
								<div ref={registerChild} className="TableChild">
									<List
										ref={r => (this.list = r)}
										autoHeight
										height={height}
										isScrolling={isScrolling}
										overscanRowCount={2}
										rowCount={size}
										rowHeight={getRowHeight}
										rowRenderer={r => rowRenderer(r)}
										scrollTop={scrollTop}
										width={width}
									/>
								</div>
							)}
						</AutoSizer>
					)}
				</WindowScroller>
			</div>
		);
	}
}

export default AccountList;

import ReduxComponentRoot from 'Components/ReduxComponentRoot';
angular.module('upDirectives').directive('reactDriver', function () {
	return {
		scope: {
			component: '=',
			props: '=',
			getNoneWatchedProps: '='
		},
		replace: true,
		template: '<div></div>',
		restrict: 'E',
		link: function ($scope, $element, $attrs) {
			let firstElement = $element[0];
			const redux = $attrs.hasOwnProperty('redux');
			const component =
				typeof $scope.component === 'string'
					? window[$scope.component]
					: redux
					? ReduxComponentRoot
					: $scope.component;

			if (redux) {
				$scope.props.component = $scope.component;
			}

			let renderTimeout;
			const render = props => {
				if (redux) {
					props.component = $scope.component;
				}
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(() => {
					if (!firstElement) {
						return;
					}
					if (!component) {
						return;
					}
					const finalProps = $scope.getNoneWatchedProps
						? Object.assign({}, props, $scope.getNoneWatchedProps())
						: props;
					ReactDOM.render(React.createElement(component, finalProps), firstElement, () => {});
				}, 10);
			};

			$scope.$on('$destroy', () => {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			if ($attrs.hasOwnProperty('watch')) {
				$scope.$watch('props', () => render($scope.props), true);
			} else {
				render($scope.props);
			}
		}
	};
});

import './AgreementHistory.scss';
import { capitalize, compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import AgreementHistoryResource from 'App/babel/resources/AgreementHistory';
import BemClass from '@upsales/components/Utils/bemClass';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import { Text, Block, Table, Button, TableRow, TableColumn, TableHeader, Tooltip, Icon } from '@upsales/components';
import Client from 'App/resources/Model/Client';
import AgreementHistoryVersion, { AgreementVersionsAndDates } from 'App/resources/Model/AgreementHistoryVersion';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';

type Props = {
	agreementId?: number;
	openUpAgreementVersion?: (historyRowId: number, formattedAgreementHistory: AgreementVersionsAndDates) => void;
};

const AgreementHistory = ({ agreementId, openUpAgreementVersion }: Props) => {
	const classes = new BemClass('AgreementHistory');
	const [historyRows, setHistoryRows] = useState<AgreementHistoryVersion[]>([]);
	const formatCurrency = Tools.$filter('currencyFormat');

	const getCompanies = async (companyIds: number[]) => {
		try {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'id' }, comparisonTypes.Equals, companyIds);
			const customerId = Tools.AppService.getAccountSelf().id;
			return (await Tools.Account.customer(customerId).find(rb.build())).data;
		} catch (err) {
			logError(err, 'Failed to get company');
			return [];
		}
	};

	const extendHistory = async (history: AgreementHistoryVersion[]) => {
		const movedHistoryRows = history.filter(row => row.type === 'moved');
		const companyIds: number[] = compact(movedHistoryRows.map(row => row.change));
		if (!companyIds.length) {
			return history;
		}
		const companies = await getCompanies(companyIds);
		movedHistoryRows.map(row => {
			row.fromCompany = companies.find(company => company.id === row.change);
			return row;
		});
		return history;
	};

	const goToCompany = (companyId?: number) => {
		if (!companyId) {
			return;
		}
		Tools.$state.go('account.dashboard', { id: companyId });
	};

	const fetchAgreementHistory = async () => {
		try {
			const history = await AgreementHistoryResource.find({ id: agreementId });
			if (!history.data) {
				return;
			}

			const extendedHistory = await extendHistory(history.data);
			setHistoryRows(extendedHistory);
		} catch (err) {
			logError(err, 'Failed to get agreement history');
		}
	};

	const toggleHidden = async (historyId: number) => {
		try {
			await AgreementHistoryResource.toggleHidden(historyId);
			fetchAgreementHistory();
			Tools.$rootScope.$broadcast('arrChange.updated');
		} catch (err) {
			logError(err, 'Failed to get agreement history');
		}
	};

	useEffect(() => {
		if (agreementId) {
			fetchAgreementHistory();
		}
	}, [agreementId]);

	const onRowClick = (historyRow: AgreementHistoryVersion) => {
		const omittedTypes = ['moved'];
		if (!openUpAgreementVersion || omittedTypes.includes(historyRow.type)) {
			return;
		}
		const formattedAgreementHistory = historyRows.reduce(
			(acc: AgreementVersionsAndDates, row: AgreementHistoryVersion) => {
				if (row.type === 'moved') {
					return acc;
				}
				const dates = {
					startDate: row.regDateTime,
					endDate: row.endDate
				};
				acc[row.id] = dates;
				return acc;
			},
			{}
		);
		openUpAgreementVersion(historyRow.id, formattedAgreementHistory);
	};

	const renderDescription = () => (
		<Block space="mbl">
			<Text className={classes.elem('description').b()}>{T('agreementHistory.description')}</Text>
		</Block>
	);

	const renderTableHeader = () => {
		const columns = [
			{ title: T('agreementHistory.columnTitleDateOfChange') },
			{ title: T('agreementHistory.columnTitleType') },
			{ title: T('agreementHistory.columnTitleChange') },
			{ title: '' }
		];
		return <TableHeader className={classes.elem('tableHeader').b()} columns={columns} />;
	};

	const renderColumnText = (
		text?: string | JSX.Element,
		color: React.ComponentProps<typeof Text>['color'] = 'inherit'
	) => (
		<Text size="sm" color={color}>
			{text}
		</Text>
	);

	const renderDateColumn = (historyRow: AgreementHistoryVersion) => {
		const formattedDate = moment(historyRow.regDateTime).format('L');
		return (
			<TableColumn color={historyRow.hidden ? 'grey-10' : 'black'} className={classes.elem('dateColumn').b()}>
				{renderColumnText(formattedDate)}
			</TableColumn>
		);
	};

	const renderTypeMoved = (title: string, fromCompany?: Client) => (
		<div className={classes.elem('typeColumn').b()}>
			{renderColumnText(title)}
			&nbsp;
			<Button
				type="link"
				onClick={() => goToCompany(fromCompany?.id)}
				className={classes.elem('companyLink').b()}
			>
				{renderColumnText(fromCompany?.name)}
			</Button>
		</div>
	);

	const renderTypeColumn = (historyRow: AgreementHistoryVersion) => {
		const { type, fromCompany } = historyRow;
		let title: string | JSX.Element = T(`agreementHistory.type${capitalize(type)}`);
		if (type === 'moved') {
			title = renderTypeMoved(title, fromCompany);
		}
		return <TableColumn color={historyRow.hidden ? 'grey-10' : 'black'}>{renderColumnText(title)}</TableColumn>;
	};

	const renderChangeColumn = (historyRow: AgreementHistoryVersion) => {
		let content = `${historyRow.change}`;
		let textColor: React.ComponentProps<typeof Text>['color'] = 'inherit';

		const displayInMrr =
			(Tools.AppService.getMetadata().params.SalesModel === 'rr' &&
				Tools.AppService.getMetadata().params.SalesModelOption === 'mrr') ||
			getCMWithRROption() === 'mrr';

		const recurringType = displayInMrr ? 'MRR' : 'ARR';

		switch (historyRow.type) {
			case 'moved':
				content = '';
				break;
			case 'increase':
				content = `+${formatCurrency(historyRow.change)} (${recurringType})`;
				textColor = 'medium-green';
				break;
			case 'contraction':
				content = `${formatCurrency(historyRow.change)} (${recurringType})`;
				textColor = 'red';
				break;
			case 'newAgreement':
				content = `${formatCurrency(historyRow.change)} (${recurringType})`;
				textColor = 'medium-green';
				break;
		}
		if (historyRow.hidden) {
			textColor = 'grey-10';
		}

		return (
			<TableColumn className={classes.elem('changeColumn').b()}>
				{renderColumnText(content, textColor)}
			</TableColumn>
		);
	};

	const renderViewVersionColumn = (historyRow: AgreementHistoryVersion) => {
		const hideButtonsForTypes = ['moved'];
		const hideButton = hideButtonsForTypes.includes(historyRow.type);
		const disableToggle = hideButtonsForTypes.includes(historyRow.type) || !historyRow.activeVersionId;
		const onClick = async (e: React.MouseEvent) => {
			e.stopPropagation();
			toggleHidden(historyRow.id);
		};
		const disabled = T('agreement.history.excludeDisabled');
		const include = T('agreement.history.include');
		const exclude = T('agreement.history.exclude');

		return (
			<TableColumn className={classes.elem('viewVersionColumn').b()} align="right">
				<Tooltip title={disableToggle ? disabled : historyRow.hidden ? include : exclude} position="left">
					<Button type="link" disabled={disableToggle} onClick={onClick}>
						<Icon
							name={historyRow.hidden ? 'eye' : 'eye-slash'}
							space="mrm"
							color={disableToggle ? 'grey-8' : 'black'}
						/>
					</Button>
				</Tooltip>
				{!hideButton ? (
					<Button
						className={classes.elem('viewVersionButton').b()}
						size="sm"
						color="super-light-green"
						onClick={() => onRowClick(historyRow)}
					>
						{renderColumnText(T('agreementHistory.viewVersion'))}
					</Button>
				) : null}
			</TableColumn>
		);
	};

	const renderHistoryRow = (historyRow: AgreementHistoryVersion, index: number) => (
		<TableRow
			key={`${historyRow.regDateTime}+${index}`}
			className={classes.elem('historyRow').mod({ hidden: historyRow.hidden }).b()}
		>
			{renderDateColumn(historyRow)}
			{renderTypeColumn(historyRow)}
			{renderChangeColumn(historyRow)}
			{renderViewVersionColumn(historyRow)}
		</TableRow>
	);

	return (
		<div className={classes.b()}>
			{renderDescription()}
			<Block space="mbxl pbxl" className={classes.elem('historyRows').b()}>
				<Table>
					{renderTableHeader()}
					{historyRows.map(renderHistoryRow)}
				</Table>
			</Block>
		</div>
	);
};

export default AgreementHistory;

import _ from 'lodash';

angular.module('domain.admin').controller('AccountProfileCtrl', [
	'$scope',
	'$q',
	'AppService',
	'AccountProfile',
	'$upModal',
	'StandardCreative',
	'$translate',
	function ($scope, $q, AppService, AccountProfile, $upModal, StandardCreative, $translate) {
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;
		var initialHash;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'accountProfile';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			profile: null,
			bannerTemplate: null
		};

		var showSaveIfChanged = function () {
			var hash = getCurrentHash();
			AdminSubCtrl.rootData.saveVisible = hash !== initialHash;

			if (!$scope.$$phase) {
				$scope.$apply();
			}
		};

		function getCurrentHash() {
			return Tools.LZString.compressToBase64(JSON.stringify(AdminSubCtrl.rootData.pageData.profile));
		}

		AdminSubCtrl.rootData.onProfilePropChanged = function (key, value) {
			AdminSubCtrl.rootData.pageData.profile[key] = value;
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.onFontsChanged = function (fonts) {
			AdminSubCtrl.rootData.pageData.profile.fonts = fonts;
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.onColorChanged = function (modelKey, color, value) {
			AdminSubCtrl.rootData.pageData.profile[modelKey][color] = value;
			if (
				modelKey !== 'colorsLandingPages' &&
				color in AdminSubCtrl.rootData.pageData.profile.colorsLandingPages
			) {
				AdminSubCtrl.rootData.pageData.profile.colorsLandingPages[color] = value;
			}
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.onFontRemoved = function (font) {
			var typographyLandingPages = AdminSubCtrl.rootData.pageData.profile.typographyLandingPages || {};

			Object.keys(typographyLandingPages).forEach(function (key) {
				if (typographyLandingPages[key].fontFamily === font.family) {
					typographyLandingPages[key].fontFamily = 'Arial, Helvetica, sans-serif';
				}
			});

			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.onTypographyChanged = function (modelKey, type, key, value) {
			AdminSubCtrl.rootData.pageData.profile[modelKey][type][key] = value;
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.toggleTypography = function (modelKey, element, attr) {
			AdminSubCtrl.rootData.pageData.profile[modelKey][element][attr] =
				!AdminSubCtrl.rootData.pageData.profile[modelKey][element][attr];
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;
			AccountProfile.save(AdminSubCtrl.rootData.pageData.profile)
				.then(res => {
					AdminSubCtrl.rootData.pageData.profile = res.data;
				})
				.catch(error => console.error(error))
				.finally(function () {
					AdminSubCtrl.rootData.saving = false;
					initialHash = getCurrentHash();
					AdminSubCtrl.rootData.saveVisible = false;
				});
		};

		AdminSubCtrl.rootData.selectImage = function (logo) {
			$upModal
				.open('fileBrowser', {
					types: ['image'],
					public: true,
					selectOnUpload: true
				})
				.then(function (files) {
					AdminSubCtrl.rootData.pageData.profile[logo] = files[0].$$publicUrl;
					showSaveIfChanged();
				})
				.catch(error => console.error(error));
		};

		AdminSubCtrl.rootData.setLogoUrl = function (logo) {
			$upModal
				.open('imageUrl')
				.then(function (url) {
					AdminSubCtrl.rootData.pageData.profile[logo] = url;
					showSaveIfChanged();
				})
				.catch(error => console.error(error));
		};

		AdminSubCtrl.rootData.deleteImage = function (logo) {
			AdminSubCtrl.rootData.pageData.profile[logo] = '';
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.saveColor = function (color) {
			AdminSubCtrl.rootData.pageData.profile.extraColors.push({
				value: color
			});
			showSaveIfChanged();
		};

		AdminSubCtrl.rootData.removeColor = function (index) {
			AdminSubCtrl.rootData.pageData.profile.extraColors.splice(index, 1);
			showSaveIfChanged();
		};

		var init = function () {
			$q.all({
				profile: AccountProfile.get(),
				bannerTemplates: StandardCreative.find()
			})
				.then(function (res) {
					// Set accountProfile if missing
					if (!res.profile.data) {
						AdminSubCtrl.rootData.pageData.profile = AccountProfile.new();
					} else {
						AdminSubCtrl.rootData.pageData.profile = res.profile.data;
					}

					if (res.bannerTemplates.data && res.bannerTemplates.data.length) {
						AdminSubCtrl.rootData.pageData.bannerTemplate = _.filter(
							res.bannerTemplates.data,
							function (banner) {
								if (banner.name === 'Template 1 - Hörnflagga') {
									banner.body = banner.body.replace(
										'Klicka här!',
										$translate.instant('admin.accountProfile.bannerExample.1.1')
									);
									banner.body = banner.body.replace(
										'Nu söker vi nya kollegor',
										$translate.instant('admin.accountProfile.bannerExample.1.2')
									);
									banner.body = banner.body.replace(
										'Jobba med det senaste inom IT och Marketing Automation, se våra lediga tjänster.',
										$translate.instant('admin.accountProfile.bannerExample.1.3')
									);
								}
								if (banner.name === 'Template 2 - Growth day') {
									banner.body = banner.body.replace(
										'Seminar 12 january',
										$translate.instant('admin.accountProfile.bannerExample.2.1')
									);
									banner.body = banner.body.replace(
										'Upsales Growth Day',
										$translate.instant('admin.accountProfile.bannerExample.2.2')
									);
									banner.body = banner.body.replace(
										'21/9 Boka idag!',
										$translate.instant('admin.accountProfile.bannerExample.2.3')
									);
									banner.body = banner.body.replace(
										'Smart tips from leading sales and marketing pro´s.',
										$translate.instant('admin.accountProfile.bannerExample.2.4')
									);
								}
								if (banner.name === 'Template 7 - 10 tips') {
									banner.body = banner.body.replace(
										'10 easy tips to improve your sales!',
										$translate.instant('admin.accountProfile.bannerExample.7.1')
									);
									banner.body = banner.body.replace(
										'Find out more more!',
										$translate.instant('admin.accountProfile.bannerExample.7.2')
									);
								}

								return (
									banner.name === 'Template 1 - Hörnflagga' ||
									banner.name === 'Template 2 - Growth day' ||
									banner.name === 'Template 7 - 10 tips'
								);
							}
						);
					}

					var profile = AdminSubCtrl.rootData.pageData.profile;

					if (_.isEmpty(profile.typographyLandingPages)) {
						profile.typographyLandingPages = _.cloneDeep(profile.typography);
					}

					if (_.isEmpty(profile.colorsLandingPages)) {
						profile.colorsLandingPages = _.cloneDeep(profile.colors);
					}

					AdminSubCtrl.rootData.pageLoading = false;

					initialHash = getCurrentHash();
				})
				.catch(error => {
					throw error;
				});
		};

		AppService.loadedPromise.then(init).catch(error => console.error(error));
	}
]);

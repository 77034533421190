'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';

angular.module('domain.advancedSearch').directive('accountHistorySearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/accountHistorySearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				selectedView: '=selectedView',
				view: '@',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					$scope.inited = false;
					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					$scope.toggleChange = function (visible, filter) {
						$scope.Filters[filter].inactive = !visible;
						$scope.ngChange();
					};

					$scope.togglePriority = () => {
						const { Priority } = $scope.Filters.ActivityFilters.value;

						Priority.value = Priority.value[0] ? [ACTIVITY_PRIORITY.NONE] : [ACTIVITY_PRIORITY.HIGH];

						$scope.ngChange();
					};

					var init = function () {
						$scope.showSalesFilters = !$scope.Filters.OrderFilters.inactive;
						$scope.priority = !!$scope.Filters.ActivityFilters.value.Priority.value[0];
						$scope.showOpportunityFilters = !$scope.Filters.OpportunityFilters.inactive;
						$scope.showActivityFilters = !$scope.Filters.ActivityFilters.inactive;
						$scope.priority = !!$scope.Filters.ActivityFilters.value[0];

						$scope.enableActivityFilters =
							$scope.view !== 'activity' &&
							$scope.view !== 'appointment' &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						$scope.enableOpportunityFilters =
							$scope.view !== 'order' &&
							$scope.view !== 'opportunity' &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE);
						$scope.enableSalesFilters =
							$scope.view !== 'order' &&
							$scope.view !== 'opportunity' &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS);

						$scope.show =
							FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM) &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);

						$scope.currencyIso = Tools.AppService.getMetadata().defaultCurrency.iso;

						$scope.inited = true;
					};

					$scope.$watch('selectedView', init);

					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);

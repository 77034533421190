import RequestChangePassword from 'Components/RequestChangePassword';
// Can be removed when useReactInit is live for every environment
angular.module('upDirectives').directive('upRequestChangePassword', [
	function () {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			scope: {
				changepassword: '=',
				oldpassword: '='
			},
			link: function ($scope, $element) {
				var renderTimeout;
				var firstElement = $element[0];

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}

						ReactDOM.render(
							React.createElement(RequestChangePassword, {
								changePassword: $scope.changepassword,
								oldPassword: $scope.oldpassword
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				render();
			}
		};
	}
]);

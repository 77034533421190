import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Title, Text } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';
import _ from 'lodash';

import t from 'Components/Helpers/translate';

import formatActionProperty, { sort } from '../../formatActionProperty';

import './DrawerActionList.scss';

const baseClass = 'DrawerActionList';

export default function DrawerActionList({ properties, type, relationData }) {
	const [showFirst, setShowFirst] = useState(true);
	const rows = _.sortBy(properties, sort.bind(null, type)).reduce((res, property, index, array) => {
		let config;

		if (property.name === 'NewMarketScore') {
			const marketDescription = _.find(array, { name: 'NewMarketDescription' });
			const marketDescriptionText = marketDescription ? marketDescription.value : '';

			config = {
				title: Tools.$translate('default.newMarketEvent'),
				text: `${marketDescriptionText} ${property.value}p`
			};
		} else if (property.name === 'NewMarketDescription') {
			return res;
		} else if (
			property.name === 'ActivityType' &&
			property.value === Tools.AppService.getTodoTypes()?.PHONE_CALL?.id
		) {
			return res;
		} else {
			config = formatActionProperty(type, property, relationData);
		}
		if (config) {
			res.push(
				<div key={property.name} className={new bem(baseClass).elem('action-row')}>
					<Title bold size="sm">
						{config.title}
					</Title>
					<Text>{config.text}</Text>
				</div>
			);
		}

		return res;
	}, []);

	return (
		<div className={new bem(baseClass).b()}>
			<div className={new bem(baseClass).elem('rows').mod({ showFirst }).b()}>
				{rows.slice(0, showFirst ? 1 : undefined)}
			</div>
			{properties.length > 1 ? (
				<Button type="link" block onClick={() => setShowFirst(!showFirst)}>
					{t(showFirst ? 'default.showMore' : 'default.showLess')}
				</Button>
			) : null}
		</div>
	);
}

DrawerActionList.propTypes = {
	properties: PropTypes.array.isRequired
};

DrawerActionList.defaultProps = {
	properties: []
};

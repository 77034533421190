import Resource from 'Resources/Resource';

class ResetPassword extends Resource {
	constructor() {
		super('resetPassword');
	}

	get(hash: string): Promise<void> {
		return this._getRequest(hash, { methodName: 'get' }).then(r => r.data);
	}

	requestPasswordResetLink(email: string): Promise<void> {
		return this._postRequest('', { email }, { methodName: 'requestPasswordResetLink' });
	}

	resetPassword(hash: string, password: string): Promise<void> {
		return this._postRequest(hash, { password }, { methodName: 'resetPassword' });
	}

	changePassword({
		email,
		password,
		newPassword
	}: {
		email: string;
		password: string;
		newPassword: string;
	}): Promise<void> {
		return this._postRequest(
			'',
			{ email, password, newPassword },
			{ _url: 'changePassword', methodName: 'changePassword' }
		);
	}
}

const resource = new ResetPassword();

export default resource;

ReactTemplates.segment.newSegmentModal = window.ReactCreateClass({
	getInitialState: function () {
		return {
			id: this.props.segment && this.props.segment.id ? this.props.segment.id : null,
			name: this.props.segment && this.props.segment.name ? this.props.segment.name : null,
			description: this.props.segment && this.props.segment.description ? this.props.segment.description : null,
			active: this.props.segment.active ? this.props.segment.active : true,
			invalidName: this.props.segment && this.props.segment.name ? false : null,
			invalidCss: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			name: t('default.name'),
			description: t('default.description'),
			newSegment: t('segment.newSegment'),
			nameOfSegment: t('segment.segmentName'),
			saving: t('default.saving'),
			continue: t('onboarding.continue'),
			abort: t('default.abort'),
			active: t('default.active'),
			invalidName: t('segment.newSegment.errorName'),
			right: {
				ingress1: t('segment.newSegment.right.ingress1'),
				ingress2: t('segment.newSegment.right.ingress2'),
				text1: t('segment.newSegment.right.text1'),
				text2: t('segment.newSegment.right.text2'),
				title1: t('segment.newSegment.right.title1'),
				title2: t('segment.newSegment.right.title2')
			},
			editSegment: t('segment.settings.edit.title'),
			save: t('default.save')
		};
	},
	componentDidUpdate: function () {
		if (this.state.invalidName === true) {
			this.setState(
				{
					invalidName: null,
					invalidCss: true
				},
				document.getElementById('name').focus()
			);
		}
	},
	componentDidMount: function () {
		var nameInput = document.getElementById('name');
		if (nameInput) {
			nameInput.focus();
			nameInput.setSelectionRange(nameInput.value.length, nameInput.value.length);
		}
	},
	setText: function (e) {
		switch (e.target.id) {
			case 'name':
				if (e.target.value.length) {
					this.setState({
						name: e.target.value,
						invalidName: false,
						invalidCss: false
					});
				} else {
					this.setState({
						name: e.target.value,
						invalidName: true
					});
				}

				break;

			case 'description':
				this.setState({
					description: e.target.value
				});
				break;
		}
	},
	setActive: function (bool) {
		this.setState({
			active: bool
		});
	},
	cleanStateBeforeSave: function (state) {
		var obj = _.cloneDeep(state);

		delete obj.invalidName;
		delete obj.invalidCss;

		if (obj.id === null) {
			delete obj.id;
		}

		if (obj.active) {
			obj.active = 1;
		} else {
			obj.active = 0;
		}

		return obj;
	},
	validateForm: function () {
		if (this.state.invalidName === null) {
			this.setState({
				invalidName: true
			});
		} else if (this.state.invalidName === false) {
			var isEdit = false;

			if (this.props.segment && this.props.segment.id) {
				isEdit = true;
			}

			this.props.onSave(this.cleanStateBeforeSave(this.state), isEdit, this.props.returnData);
		}
	},
	keyDown: function (e) {
		if (e.keyCode === 13) {
			e.preventDefault();
		}

		if (e.keyCode === 13 && this.state.name && this.state.name.length > 0) {
			var isEdit = false;

			if (this.props.segment && this.props.segment.id) {
				isEdit = true;
			}

			this.props.onSave(this.cleanStateBeforeSave(this.state), isEdit, this.props.returnData);
		} else if (e.keyCode === 13 && (this.state.name === null || this.state.name.length === 0)) {
			this.validateForm();
		}
	},
	render: function () {
		var self = this;

		return (
			<div className="col-container">
				<button
					type="button"
					className="up-btn btn-link modal-close btn-grey"
					onClick={function () {
						self.props.closeModal();
					}}
					style={{ position: 'absolute', top: '15px', right: '15px' }}
				>
					<i className="fa fa-times"></i>
				</button>
				<div className="col">
					<h2>{this.props.segment.id ? self.lang.editSegment : self.lang.newSegment}</h2>
					<div
						className={
							self.state.invalidCss
								? 'floating-label-input error-input'
								: self.state.name && self.state.name.length
								? 'floating-label-input has-value'
								: 'floating-label-input'
						}
					>
						<label>{self.state.invalidCss ? self.lang.invalidName : self.lang.nameOfSegment}</label>
						<input
							id="name"
							type="text"
							value={self.state.name}
							onChange={self.setText}
							onKeyDown={self.keyDown}
							data-test-id="segmentmodal-name"
							maxLength={45}
						/>
					</div>

					<div
						className={
							self.state.description && self.state.description.length
								? 'floating-label-input has-value'
								: 'floating-label-input'
						}
					>
						<label>{this.lang.description}</label>
						<input
							id="description"
							type="text"
							value={self.state.description}
							onChange={self.setText}
							onKeyDown={self.keyDown}
							maxLength={100}
						/>
					</div>

					<div className={'floating-label-input has-value floating-toggle'}>
						<label>{self.lang.active}</label>
						<ReactTemplates.upFilters.components.toggle
							className="toggle-lg toggle-bright-blue"
							checked={!!this.props.segment.active}
							id="active"
							onChange={self.setActive}
						/>
					</div>

					<div className="button-holder">
						<button
							type="submit"
							disabled={self.props.saving ? 'disabled' : false}
							className="btn up-btn btn-bright-blue no-shadow btn-block"
							style={{ marginTop: '20px' }}
							onClick={function () {
								self.validateForm();
							}}
							data-test-id="segmentmodal-submit"
						>
							{this.state.saving
								? this.lang.saving
								: this.props.segment.id
								? this.lang.save
								: this.lang.continue}
						</button>
						<button
							type="button"
							disabled={self.props.saving ? 'disabled' : false}
							className="btn up-btn btn-grey btn-link btn-block"
							onClick={function () {
								self.props.closeModal();
							}}
						>
							{this.lang.abort}
						</button>
					</div>
				</div>
				<div className="col dark-background">
					<div className="svg-holder">
						<div dangerouslySetInnerHTML={{ __html: this.getImage() }} />
					</div>

					<div className="text">
						<h2>{this.lang.right.title1}</h2>
						<p>
							<span className="ingress">{this.lang.right.ingress1}</span>
							{this.lang.right.text1}
						</p>

						<h2>{this.lang.right.title2}</h2>
						<p>
							<span className="ingress">{this.lang.right.ingress2}</span>
							{this.lang.right.text2}
						</p>
					</div>
				</div>
			</div>
		);
	},
	getImage: function () {
		return '<svg  \
			class="segment-flow" \
			xmlns="http://www.w3.org/2000/svg"  \
			width="292"  \
			height="680"  \
			viewBox="0 0 292 680"  \
			xmlns:xlink="http://www.w3.org/1999/xlink"> \
			\
			<defs> \
				<path  \
					id="a"  \
					d="M12.5,30 L12.5,30 L12.5,30 C16.7044144,30 20.1732121,33.2910305 20.3941912,37.4896337 L20.9560756,48.1654364 L20.9560756,48.1654364 C20.9815032,48.6485615 20.6574303,49.0806046 20.1864971,49.1914124 L5.28545701,52.6975395 L5.28545701,52.6975395 C4.74785355,52.8240345 4.20949544,52.4907652 4.08300051,51.9531617 C4.06200726,51.8639404 4.05338015,51.7722565 4.05736152,51.6806851 L4.67422082,37.4929212 L4.67422082,37.4929212 C4.85638844,33.3030658 8.30618631,30 12.5,30 Z"/> \
				<path  \
					id="b"  \
					d="M12,17 L13,17 L13,17 C18.5228475,17 23,21.4771525 23,27 L23,38 L23,38 C23,39.1045695 22.1045695,40 21,40 L4,40 L4,40 C2.8954305,40 2,39.1045695 2,38 L2,27 L2,27 C2,21.4771525 6.4771525,17 12,17 Z"/> \
				<path  \
					id="c"  \
					d="M12.5,-5.42101086e-20 L12.5,-5.42101086e-20 L12.5,0 C16.6421356,-7.60893085e-16 20,3.35786438 20,7.5 L20,15 L20,15 C20,15.5522847 19.5522847,16 19,16 L6,16 L6,16 C5.44771525,16 5,15.5522847 5,15 L5,7.5 L5,7.5 C5,3.35786438 8.35786438,7.60893085e-16 12.5,0 Z"/> \
				<path  \
					id="d"  \
					d="M13.5,17 L13.5,17 L13.5,17 C17.0898509,17 20,19.9101491 20,23.5 L20,46 L7,46 L7,23.5 L7,23.5 C7,19.9101491 9.91014913,17 13.5,17 Z"/> \
				<path  \
					id="e"  \
					d="M13,17 L14,17 L14,17 C19.5228475,17 24,21.4771525 24,27 L24,29 L24,29 C24,33.9705627 19.9705627,38 15,38 L12,38 L12,38 C7.02943725,38 3,33.9705627 3,29 L3,27 L3,27 C3,21.4771525 7.4771525,17 13,17 Z"/> \
				<path  \
					id="f"  \
					d="M12,0 L19,0 L19,0 C19.5522847,-1.08250786e-15 20,0.44771525 20,1 L20,7.5 L20,7.5 C20,11.0898509 17.0898509,14 13.5,14 L13.5,14 L13.5,14 C9.91014913,14 7,11.0898509 7,7.5 L7,5 L7,5 C7,2.23857625 9.23857625,5.07265313e-16 12,0 Z"/> \
				<path  \
					id="g"  \
					d="M13,17 L13,17 L13,17 C13.2838445,17 13.5215083,17.2150828 13.5497519,17.4975186 L15,32 L13,33 L11,32 L12.4502481,17.4975186 L12.4502481,17.4975186 C12.4784917,17.2150828 12.7161555,17 13,17 Z"/> \
				<path  \
					id="h"  \
					d="M12.5,30 L12.5,30 L12.5,30 C16.7202243,30 20.2174467,33.2725594 20.4972445,37.4834984 L20.9410912,44.1633638 L20.9410912,44.1633638 C20.9746903,44.6690274 20.624713,45.1198818 20.1265133,45.2127347 L5.25149068,47.9850957 L5.25149068,47.9850957 C4.70855523,48.0862864 4.18638787,47.7281814 4.08519723,47.185246 C4.07057718,47.1068026 4.06539856,47.0268912 4.06977186,46.9472169 L4.5889458,37.4887272 L4.5889458,37.4887272 C4.81952059,33.2880361 8.29298559,30 12.5,30 Z"/> \
				<path  \
					id="i"  \
					d="M12,17 L13,17 L13,17 C18.5228475,17 23,21.4771525 23,27 L23,29.5 L23,29.5 C23,35.2989899 18.2989899,40 12.5,40 L12.5,40 L12.5,40 C6.70101013,40 2,35.2989899 2,29.5 L2,27 L2,27 C2,21.4771525 6.4771525,17 12,17 Z"/> \
				<path  \
					id="j"  \
					d="M12.5,-5.42101086e-20 L12.5,-5.42101086e-20 L12.5,0 C16.6421356,-7.60893085e-16 20,3.35786438 20,7.5 L20,15 L20,15 C20,15.5522847 19.5522847,16 19,16 L6,16 L6,16 C5.44771525,16 5,15.5522847 5,15 L5,7.5 L5,7.5 C5,3.35786438 8.35786438,7.60893085e-16 12.5,0 Z"/> \
				<path  \
					id="k"  \
					d="M13.5,17 L13.5,17 L13.5,17 C17.6421356,17 21,20.3578644 21,24.5 L21,42.7193264 L21.0000159,42.7193264 C21.0000159,43.5502369 20.486266,44.2946007 19.7093765,44.5893126 C17.2302362,45.5297709 14.9937774,46 13,46 C11.0538809,46 9.10827679,45.5519824 7.16318763,44.6559473 L7.16318921,44.6559439 C6.45418117,44.3293285 6,43.6200471 6,42.8394255 L6,24.5 L6,24.5 C6,20.3578644 9.35786438,17 13.5,17 Z"/> \
				<path  \
					id="l"  \
					d="M13.5,19 L13.5,19 L13.5,19 C17.6421356,19 21,22.3578644 21,26.5 L21,40.1244836 L21,40.1244836 C21,40.6256862 20.6289697,41.0494707 20.1321637,41.1157115 L7.13216372,42.8490448 L7.13216372,42.8490448 C6.58472367,42.9220368 6.08176411,42.5374207 6.0087721,41.9899807 C6.00293046,41.9461684 6,41.902017 6,41.8578169 L6,26.5 L6,26.5 C6,22.3578644 9.35786438,19 13.5,19 Z"/> \
				<path  \
					id="m"  \
					d="M13,17 L14,17 L14,17 C19.5228475,17 24,21.4771525 24,27 L24,29.4521135 L24,29.4521135 C24,33.8179793 20.8666108,37.5541911 16.5674694,38.3145646 L15.5284982,38.4983236 L7,40 L6.56173762,40.547828 L6.56173762,40.547828 C5.87171852,41.4103518 4.61313378,41.5501946 3.7506099,40.8601755 C3.27617944,40.4806311 3,39.9060053 3,39.2984379 L3,27 L3,27 C3,21.4771525 7.4771525,17 13,17 Z"/> \
				<path  \
					id="n"  \
					d="M14,0 L18,0 L18,2 L18,2 C18,3.65685425 16.6568542,5 15,5 L9,5 L9,5 L9,5 C9,2.23857625 11.2385763,5.07265313e-16 14,0 Z"/> \
				<ellipse  \
					id="o"  \
					cx="26"  \
					cy="7.5"  \
					rx="26"  \
					ry="7.5"/> \
				<ellipse  \
					id="q"  \
					cx="26"  \
					cy="6.5"  \
					rx="26"  \
					ry="6.5"/> \
			</defs> \
			<g  \
				fill="none"  \
				fill-rule="evenodd"  \
				transform="translate(0 5)"> \
				<g  \
					transform="translate(52 341)"> \
					<path  \
						id="flow-stroke-dashed" \
						stroke="#A4B3C7"  \
						stroke-width="2"  \
						d="M85.9961243,4 L85.9961243,63 L149,63 L149,63 C152.313708,63 155,65.6862915 155,69 L155,122 L209.008333,122 L209.008333,122 C212.322041,122 215.008333,124.686292 215.008333,128 L215.008333,226 L215.008333,226 C215.008333,229.313709 212.322041,232 209.008333,232 L85.9961243,232 L85.9961243,299.02996"/> \
					<path  \
						id="flow-stroke" \
						stroke="#B04AE2"  \
						stroke-width="4"  \
						d="M85.9961243,4 L85.9961243,63 L149,63 L149,63 C152.313708,63 155,65.6862915 155,69 L155,122 L209.008333,122 L209.008333,122 C212.322041,122 215.008333,124.686292 215.008333,128 L215.008333,226 L215.008333,226 C215.008333,229.313709 212.322041,232 209.008333,232 L85.9961243,232 L85.9961243,289.02996"/> \
					<path  \
						id="stroke-shadow" \
						stroke="#4E0065"  \
						stroke-width="3"  \
						d="M85,30 L85,0 L85,30 Z"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"  \
						transform="matrix(-1 0 0 1 171 0)"/> \
					<text  \
						class="flow-check-top" \
						fill="#B04AE2"  \
						font-family="FontAwesome"  \
						font-size="16"> \
						<tspan  \
							x="112"  \
							y="48">&#xf00c;</tspan> \
					</text> \
					<text  \
						class="flow-check-bottom" \
						fill="#B04AE2"  \
						font-family="FontAwesome"  \
						font-size="16"> \
						<tspan  \
							x="182"  \
							y="107">&#xf00c;</tspan> \
					</text> \
					<text  \
						class="flow-times-top" \
						fill="#A4B3C7"  \
						font-family="FontAwesome"  \
						font-size="16"> \
						<tspan  \
							x="38.714"  \
							y="48"></tspan> \
					</text> \
					<text  \
						class="flow-times-bottom" \
						fill="#A4B3C7"  \
						font-family="FontAwesome"  \
						font-size="16"> \
						<tspan  \
							x="108.714"  \
							y="107"></tspan> \
					</text> \
					<path  \
						stroke="#A4B3C7"  \
						stroke-width="2"  \
						d="M85,232 L85,127 L85,127 C85,123.686292 87.6862915,121 91,121 L155,121"  \
						stroke-dasharray="5"/> \
					<path  \
						stroke="#A4B3C7"  \
						stroke-width="2"  \
						d="M15,62.9918038 L82.4571578,62.9918038 C84.4177687,62.9918038 86.0071578,64.7826648 86.0071578,66.9918038 L86.0071578,226 L86.0071578,226 C86.0071578,229.313708 83.3208663,232 80.0071578,232 L15,232"  \
						stroke-dasharray="5"  \
						transform="rotate(-180 50.504 147.496)"/> \
					<rect  \
						id="box-first" \
						width="30"  \
						height="30"  \
						x="70"  \
						y="49"  \
						fill="#B04AE2"  \
						rx="2"/> \
					<rect  \
						id="box-second" \
						width="30"  \
						height="30"  \
						x="140"  \
						y="106"  \
						fill="#B04AE2"  \
						rx="2"/> \
					<rect  \
						width="30"  \
						height="30"  \
						y="106"  \
						fill="#A4B3C7"  \
						rx="2"/> \
					<rect  \
						width="30"  \
						height="30"  \
						x="70"  \
						y="154"  \
						fill="#A4B3C7"  \
						rx="2"/> \
					<rect  \
						id="circle-first" \
						width="30"  \
						height="30"  \
						x="70"  \
						y="216"  \
						fill="#B04AE2"  \
						rx="15"/> \
					<rect  \
						id="circle-second" \
						width="50"  \
						height="50"  \
						x="61"  \
						y="263"  \
						fill="#B04AE2"  \
						rx="25"/> \
					<text  \
						id="circle-second-icon" \
						fill="#fff"  \
						font-family="FontAwesome"  \
						font-size="24"> \
						<tspan  \
							id="circle-second-icon-inside" \
							x="73.987"  \
							y="297.428"></tspan> \
					</text> \
				</g> \
				<g  \
					transform="translate(31 180)"> \
					<path  \
						id="second-level-floor-edge" \
						fill="#4E0065"  \
						d="M211.897309,101.096739 L211.897309,107.451485 C211.897309,107.988897 211.6282,108.490605 211.18049,108.787872 L106.027331,178.606638 L0.874172109,108.787872 C0.426462312,108.490605 0.157353034,107.988897 0.157353034,107.451485 L0.157353034,101.096739 L211.897309,101.096739 Z"/> \
					<path  \
						id="second-level-floor" \
						fill="#e3ceed"  \
						d="M106.301249,48.7197872 L212.171227,100.566856 L107.600115,167.622465 C106.808584,168.13003 105.793915,168.13003 105.002383,167.622465 L0.431271087,100.566856 L106.301249,48.7197872 Z"/> \
					<text  \
						id="check-second-level-right" \
						fill="#B04AE2"  \
						font-family="FontAwesome"  \
						font-size="26"> \
						<tspan  \
							x="181"  \
							y="52">&#xf00c;</tspan> \
					</text> \
					<text  \
						id="check-second-level-left" \
						fill="#B04AE2"  \
						font-family="FontAwesome"  \
						font-size="26"> \
						<tspan  \
							x="34"  \
							y="70">&#xf00c;</tspan> \
					</text> \
					<polygon  \
						id="second-level-floor-shadow" \
						fill="#4E0065"  \
						points="74.12 63.034 173.822 28.386 148.361 135.157 40.178 160.614"  \
						opacity=".2"  \
						transform="rotate(45 107 94.5)"/> \
					<ellipse  \
						id="second-level-ellipse" \
						cx="88"  \
						cy="117"  \
						fill="#e3ceed"  \
						rx="26"  \
						ry="9"/> \
					<ellipse  \
						id="second-level-ellipse" \
						cx="152"  \
						cy="100"  \
						fill="#e3ceed"  \
						rx="26"  \
						ry="8"/> \
				</g> \
				<rect  \
					width="52"  \
					height="169"  \
					x="93"  \
					y="123"  \
					fill="#FFFFFF"  \
					opacity=".1"/> \
				<g class="user_lower_level_first"  \
					transform="translate(110 130)"> \
					<rect  \
						width="52"  \
						height="142"  \
						x="48"  \
						fill="#FFFFFF"  \
						opacity=".1"/> \
					<polyline  \
						stroke="#4E0065"  \
						stroke-width="6"  \
						points="14 143 14 156 14 162"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<polyline  \
						stroke="#4E0065"  \
						stroke-width="6"  \
						points="7 143 7 156 7 162"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<path  \
						fill="#4E0065"  \
						d="M10.5,100 L10.5,100 L10.5,100 C14.7180299,100 18.2113007,103.275161 18.4828671,107.48444 L19.7426581,127.011201 L19.7426581,127.011201 C19.8493302,128.664618 18.5954458,130.091452 16.942029,130.198124 C16.659048,130.216381 16.3749076,130.194424 16.0980908,130.132909 L11,129 L4.83384418,130.233231 L4.83384418,130.233231 C3.20916492,130.558167 1.6286896,129.504517 1.30375375,127.879838 C1.2523327,127.622732 1.23483901,127.359995 1.25171985,127.098342 L2.51713292,107.48444 L2.51713292,107.48444 C2.78869929,103.275161 6.28197006,100 10.5,100 Z"/> \
					<path  \
						fill="#B04AE2"  \
						d="M10.5,129 L10.5,129 L10.5,129 C14.7077799,129 18.1826195,132.287151 18.4160251,136.488453 L18.9300295,145.740532 L18.9300295,145.740532 C18.9606648,146.291966 18.5384736,146.763827 17.9870392,146.794462 C17.9103491,146.798723 17.8334235,146.794134 17.7577839,146.780785 L2.88369031,144.155945 L2.88369031,144.155945 C2.38093386,144.067224 2.02573088,143.614037 2.05969048,143.104643 L2.50110742,136.483389 L2.50110742,136.483389 C2.78184929,132.272261 6.27952434,129 10.5,129 Z"/> \
					<path  \
						fill="#B04AE2"  \
						d="M10,118 L11,118 L11,118 C16.5228475,118 21,122.477153 21,128 L21,137 L21,137 C21,138.104569 20.1045695,139 19,139 L2,139 L2,139 C0.8954305,139 1.3527075e-16,138.104569 0,137 L0,128 L0,128 C-6.76353751e-16,122.477153 4.4771525,118 10,118 Z"/> \
					<path  \
						fill="#4E0065"  \
						d="M8,118 L13,118 L11.4946184,132.451663 L11.4946184,132.451663 C11.4373983,133.000976 10.9457065,133.399896 10.3963939,133.342676 C9.9260846,133.293685 9.55437214,132.921973 9.50538159,132.451663 L8,118 Z"/> \
					<path  \
						fill="#DDF0EC"  \
						d="M4 106C5.33333333 107.333333 6.66666667 108 8 108 9.33333333 108 12.3333333 107.666667 17 107L17 109.5 17 109.5C17 113.089851 14.0898509 116 10.5 116L10.5 116 10.5 116C6.91014913 116 4 113.089851 4 109.5L4 106zM18 138L21 138 21 141.5 21 141.5C21 142.328427 20.3284271 143 19.5 143L19.5 143 19.5 143C18.6715729 143 18 142.328427 18 141.5L18 138z"/> \
					<path  \
						fill="#DDF0EC"  \
						d="M-6.88106798e-14,138 L3,138 L3,139 L3,139 C3,140.656854 1.65685425,142 -6.86269753e-14,142 L-6.88106798e-14,142 L-6.88106798e-14,138 Z"  \
						transform="matrix(-1 0 0 1 3 0)"/> \
				</g> \
				<g class="user_lower_level_second"  \
					transform="translate(170 215)"> \
					<path  \
						stroke="#4E0065"  \
						stroke-width="6"  \
						d="M14 44L13 63M7 44L7.5 61"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<path  \
						fill="#B04AE2"  \
						d="M10.5,30 L10.5,30 L10.5,30 C14.7202243,30 18.2174467,33.2725594 18.4972445,37.4834984 L18.9410912,44.1633638 L18.9410912,44.1633638 C18.9746903,44.6690274 18.624713,45.1198818 18.1265133,45.2127347 L3.25149068,47.9850957 L3.25149068,47.9850957 C2.70855523,48.0862864 2.18638787,47.7281814 2.08519723,47.185246 C2.07057718,47.1068026 2.06539856,47.0268912 2.06977186,46.9472169 L2.5889458,37.4887272 L2.5889458,37.4887272 C2.81952059,33.2880361 6.29298559,30 10.5,30 Z"/> \
					<path  \
						fill="#B04AE2"  \
						d="M10,17 L11,17 L11,17 C16.5228475,17 21,21.4771525 21,27 L21,29.5 L21,29.5 C21,35.2989899 16.2989899,40 10.5,40 L10.5,40 L10.5,40 C4.70101013,40 2.48652828e-15,35.2989899 1.77635684e-15,29.5 L0,27 L0,27 C-6.76353751e-16,21.4771525 4.4771525,17 10,17 Z"/> \
					<path  \
						fill="#E4EDF7"  \
						d="M12.5,35 L13.0574682,34.6283545 L13.0574682,34.6283545 C13.5169969,34.3220021 14.1378663,34.4461759 14.4442187,34.9057046 C14.4672194,34.9402056 14.4880519,34.9761038 14.5065956,35.0131912 L16,38 L14.7102631,38.4299123 L14.7102631,38.4299123 C13.7005104,38.7664965 12.5904078,38.3856118 12,37.5 L12,37.5 L12,37.5 C11.4477153,36.6715729 11.6715729,35.5522847 12.5,35 Z"/> \
					<path  \
						fill="#E4EDF7"  \
						d="M5.02924647,34.8615214 L6.52924647,33.8615214 L8.01360486,36.8302382 L8.01360486,36.8302382 C8.26059411,37.3242167 8.06036976,37.9248897 7.56639127,38.171879 C7.52406798,38.1930406 7.48029606,38.2111715 7.43540544,38.2261351 L7.02924647,38.3615214 L7.02924647,38.3615214 C6.1436347,38.6567253 5.17001039,38.3226673 4.65218845,37.5459344 L4.52924647,37.3615214 L4.52924647,37.3615214 C3.97696172,36.5330943 4.20081935,35.4138062 5.02924647,34.8615214 Z"  \
							transform="rotate(-120 6.173 36.165)"/> \
					<path  \
						fill="#4E0065"  \
						d="M10.5,-5.42101086e-20 L10.5,-5.42101086e-20 L10.5,0 C14.6421356,-7.60893085e-16 18,3.35786438 18,7.5 L18,15 L18,15 C18,15.5522847 17.5522847,16 17,16 L4,16 L4,16 C3.44771525,16 3,15.5522847 3,15 L3,7.5 L3,7.5 C3,3.35786438 6.35786438,7.60893085e-16 10.5,0 Z"/> \
					<path  \
						fill="#E4EDF7"  \
						d="M5.26642848,6.42383723 C6.65605667,6.80794574 8.23391384,7 10,7 C11.9191471,7 13.8428112,6.77321377 15.7709924,6.3196413 L15.7709868,6.31961762 C16.3086107,6.19315058 16.8469627,6.52645901 16.9734297,7.06408284 C16.991084,7.139133 17,7.21597302 17,7.29307166 L17,11 L17,11 C17,13.7614237 14.7614237,16 12,16 L9,16 L9,16 C6.23857625,16 4,13.7614237 4,11 L4,7.38772247 L3.99997056,7.38772247 C3.99997056,6.83542146 4.44769899,6.38769302 5,6.38769302 C5.09003161,6.38769302 5.1796509,6.39985095 5.26642848,6.42383723 Z"/> \
				</g> \
				<path  \
					fill="#A4B3C7"  \
					d="M226.942526,40.0631918 L232.835897,45.9565625 L232.835897,45.9565625 C233.566715,46.6873805 233.869888,47.7429194 233.638279,48.7501667 L199.392441,197.682255 L199.392441,197.682255 C199.220802,198.428698 198.637939,199.011561 197.891496,199.1832 L48.9594075,233.429038 L48.9594075,233.429038 C47.9521602,233.660647 46.8966213,233.357474 46.1658033,232.626656 L40.2724326,226.733285 L226.942526,40.0631918 Z"  \
					transform="rotate(45 136.994 136.784)"/> \
				<path  \
					fill="#E4EAF0"  \
					d="M91.2872765,86.2872765 L230.330736,38.6606422 L194.448086,187.236212 L194.448086,187.236212 C194.183933,188.32996 193.32996,189.183933 192.236212,189.448086 L43.6606422,225.330736 L91.2872765,86.2872765 Z"  \
					transform="rotate(45 136.996 131.996)"/> \
				<g  \
					class="user_third"		 \
					transform="translate(85 73)"> \
					<ellipse  \
						cx="12"  \
						cy="66.5"  \
						fill="#CAD2DD"  \
						rx="12"  \
						ry="3.5"/> \
					<polyline  \
						fill="#6B7C93"  \
						stroke="#6B7C93"  \
						stroke-width="6"  \
						points="16 44 16 57 16 63"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<polyline  \
						fill="#6B7C93"  \
						stroke="#6B7C93"  \
						stroke-width="6"  \
						points="9 44 9 57 9 63"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#a"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12.5,30.5 C8.57388205,30.5 5.34428856,33.5922275 5.1737489,37.5146398 L4.5568896,51.7024037 C4.55489891,51.7481894 4.55921247,51.7940314 4.56970909,51.838642 C4.63295656,52.1074438 4.90213561,52.2740784 5.17093734,52.2108309 L20.0719774,48.7047039 C20.307444,48.6493 20.4694805,48.4332784 20.4567667,48.1917159 L19.8948823,37.5159131 C19.6878802,33.5828723 16.4384845,30.5 12.5,30.5 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#b"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12,17.5 C6.75329488,17.5 2.5,21.7532949 2.5,27 L2.5,38 C2.5,38.8284271 3.17157288,39.5 4,39.5 L21,39.5 C21.8284271,39.5 22.5,38.8284271 22.5,38 L22.5,27 C22.5,21.7532949 18.2467051,17.5 13,17.5 L12,17.5 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#c"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12.5,0.5 C8.63400675,0.5 5.5,3.63400675 5.5,7.5 L5.5,15 C5.5,15.2761424 5.72385763,15.5 6,15.5 L19,15.5 C19.2761424,15.5 19.5,15.2761424 19.5,15 L19.5,7.5 C19.5,3.63400675 16.3659932,0.5 12.5,0.5 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M7.26642848 6.42383723C8.65605667 6.80794574 10.2339138 7 12 7 13.9191471 7 15.8428112 6.77321377 17.7709924 6.3196413L17.7709868 6.31961762C18.3086107 6.19315058 18.8469627 6.52645901 18.9734297 7.06408284 18.991084 7.139133 19 7.21597302 19 7.29307166L19 11 19 11C19 13.7614237 16.7614237 16 14 16L11 16 11 16C8.23857625 16 6 13.7614237 6 11L6 7.38772247 5.99997056 7.38772247C5.99997056 6.83542146 6.44769899 6.38769302 7 6.38769302 7.09003161 6.38769302 7.1796509 6.39985095 7.26642848 6.42383723zM19 39L22 39 22 41 22 41C22 42.6568542 20.6568542 44 19 44L19 44 19 39z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M3,39 L6,39 L6,40 L6,40 C6,41.6568542 4.65685425,43 3,43 L3,43 L3,39 Z"  \
						transform="matrix(-1 0 0 1 9 0)"/> \
				</g> \
				<g  \
					class="user_first" \
					transform="translate(38 64)"> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#d"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M7.5,45.5 L19.5,45.5 L19.5,23.5 C19.5,20.1862915 16.8137085,17.5 13.5,17.5 C10.1862915,17.5 7.5,20.1862915 7.5,23.5 L7.5,45.5 Z"/> \
					<ellipse  \
						cx="13"  \
						cy="67.5"  \
						fill="#CAD2DD"  \
						rx="13"  \
						ry="3.5"/> \
					<path  \
						fill="#6B7C93"  \
						stroke="#6B7C93"  \
						stroke-width="6"  \
						d="M17 44L18 65M10 44L8 65"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#e"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M13,17.5 C7.75329488,17.5 3.5,21.7532949 3.5,27 L3.5,29 C3.5,33.6944204 7.30557963,37.5 12,37.5 L15,37.5 C19.6944204,37.5 23.5,33.6944204 23.5,29 L23.5,27 C23.5,21.7532949 19.2467051,17.5 14,17.5 L13,17.5 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M11.044423,17 L13,17 L14.955577,17 L14.955577,17 C15.5078618,17 15.955577,17.4477153 15.955577,18 C15.955577,18.0144842 15.9552623,18.0289667 15.9546332,18.0434372 L15.0415897,39.0434372 L15.0415897,39.0434372 C15.0183338,39.5783234 14.577925,40 14.0425335,40 L11.9574665,40 L11.9574665,40 C11.422075,40 10.9816662,39.5783234 10.9584103,39.0434372 L10.0453668,18.0434372 L10.0453668,18.0434372 C10.0213771,17.4916737 10.4492223,17.0249336 11.0009858,17.0009438 C11.0154563,17.0003147 11.0299388,17 11.044423,17 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#f"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12,0.5 C9.51471863,0.5 7.5,2.51471863 7.5,5 L7.5,7.5 C7.5,10.8137085 10.1862915,13.5 13.5,13.5 C16.8137085,13.5 19.5,10.8137085 19.5,7.5 L19.5,1 C19.5,0.723857625 19.2761424,0.5 19,0.5 L12,0.5 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M13.5,1 L13.5,1 L13.5,1 C17.0898509,1 20,3.91014913 20,7.5 L20,9.5 L20,9.5 C20,13.0898509 17.0898509,16 13.5,16 L13.5,16 L13.5,16 C9.91014913,16 7,13.0898509 7,9.5 L7,7.5 L7,7.5 C7,3.91014913 9.91014913,1 13.5,1 Z"/> \
					<g> \
						<use  \
							fill="#6B7C93"  \
							xlink:href="#g"/> \
						<path  \
						stroke="#6B7C93"  \
						stroke-width="2"  \
						d="M13,22.0498756 L12.0636104,31.4137712 L13,31.881966 L13.9363896,31.4137712 L13,22.0498756 Z"/> \
					</g> \
				</g> \
				<g  \
					class="user_third" \
					transform="translate(128 49)"> \
					<ellipse  \
						cx="12"  \
						cy="65.5"  \
						fill="#CAD2DD"  \
						rx="12"  \
						ry="3.5"/> \
					<path  \
						fill="#6B7C93"  \
						stroke="#6B7C93"  \
						stroke-width="6"  \
						d="M16 44L15 63M9 44L9.5 61"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#h"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12.5,30.5 C8.55848062,30.5 5.30421802,33.5805357 5.08819427,37.5161308 L4.56902034,46.9746205 C4.56683369,47.0144577 4.569423,47.0544133 4.57673302,47.0936351 C4.62732834,47.3651028 4.88841202,47.5441552 5.15987975,47.4935599 L20.0349024,44.7211989 C20.2840022,44.6747724 20.4589909,44.4493453 20.4421914,44.1965135 L19.9983446,37.516648 C19.7360017,33.5684042 16.45695,30.5 12.5,30.5 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#i"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M12,17.5 C6.75329488,17.5 2.5,21.7532949 2.5,27 L2.5,29.5 C2.5,35.0228475 6.9771525,39.5 12.5,39.5 C18.0228475,39.5 22.5,35.0228475 22.5,29.5 L22.5,27 C22.5,21.7532949 18.2467051,17.5 13,17.5 L12,17.5 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M14.5,35 L15.0574682,34.6283545 L15.0574682,34.6283545 C15.5169969,34.3220021 16.1378663,34.4461759 16.4442187,34.9057046 C16.4672194,34.9402056 16.4880519,34.9761038 16.5065956,35.0131912 L18,38 L16.7102631,38.4299123 L16.7102631,38.4299123 C15.7005104,38.7664965 14.5904078,38.3856118 14,37.5 L14,37.5 L14,37.5 C13.4477153,36.6715729 13.6715729,35.5522847 14.5,35 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M7.02924647,34.8615214 L8.52924647,33.8615214 L10.0136049,36.8302382 L10.0136049,36.8302382 C10.2605941,37.3242167 10.0603698,37.9248897 9.56639127,38.171879 C9.52406798,38.1930406 9.48029606,38.2111715 9.43540544,38.2261351 L9.02924647,38.3615214 L9.02924647,38.3615214 C8.1436347,38.6567253 7.17001039,38.3226673 6.65218845,37.5459344 L6.52924647,37.3615214 L6.52924647,37.3615214 C5.97696172,36.5330943 6.20081935,35.4138062 7.02924647,34.8615214 Z"  \
						transform="rotate(-120 8.173 36.165)"/> \
					<g> \
						<use  \
							fill="#6B7C93"  \
							xlink:href="#j"/> \
						<path  \
						stroke="#6B7C93"  \
						d="M12.5,0.5 C8.63400675,0.5 5.5,3.63400675 5.5,7.5 L5.5,15 C5.5,15.2761424 5.72385763,15.5 6,15.5 L19,15.5 C19.2761424,15.5 19.5,15.2761424 19.5,15 L19.5,7.5 C19.5,3.63400675 16.3659932,0.5 12.5,0.5 Z"/> \
					</g> \
					<path  \
						fill="#E4EAF0"  \
					d="M7.26642848,6.42383723 C8.65605667,6.80794574 10.2339138,7 12,7 C13.9191471,7 15.8428112,6.77321377 17.7709924,6.3196413 L17.7709868,6.31961762 C18.3086107,6.19315058 18.8469627,6.52645901 18.9734297,7.06408284 C18.991084,7.139133 19,7.21597302 19,7.29307166 L19,11 L19,11 C19,13.7614237 16.7614237,16 14,16 L11,16 L11,16 C8.23857625,16 6,13.7614237 6,11 L6,7.38772247 L5.99997056,7.38772247 C5.99997056,6.83542146 6.44769899,6.38769302 7,6.38769302 C7.09003161,6.38769302 7.1796509,6.39985095 7.26642848,6.42383723 Z"/> \
				</g> \
				<g  \
					class="user_fourth"		 \
					transform="translate(179 53)"> \
					<ellipse  \
						cx="13"  \
						cy="67.5"  \
						fill="#CAD2DD"  \
						rx="13"  \
						ry="3.5"/> \
					<path  \
						fill="#6B7C93"  \
						stroke="#6B7C93"  \
						stroke-width="6"  \
						d="M18 44C19.2110104 48.764552 19.8776771 52.4312186 20 55 20.1223229 57.5687814 19.4556563 60.9021147 18 65M9 44L9 65"  \
						stroke-linecap="round"  \
						stroke-linejoin="round"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#k"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M13.5,17.5 C9.63400675,17.5 6.5,20.6340068 6.5,24.5 L6.5,42.8394255 C6.5,43.4248911 6.84063568,43.9568531 7.37238978,44.2018169 C9.25341907,45.0683418 11.1279793,45.5 13,45.5 C14.9282391,45.5 17.1061164,45.042088 19.5320338,44.1218197 C20.1147032,43.9007849 20.5000119,43.3425126 20.5,42.7193264 L20.5,24.5 C20.5,20.6340068 17.3659932,17.5 13.5,17.5 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#l"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M13.5,19.5 C9.63400675,19.5 6.5,22.6340068 6.5,26.5 L6.5,41.8578169 C6.5,41.8799169 6.50146523,41.9019926 6.50438605,41.9238988 C6.54088205,42.1976188 6.79236183,42.3899269 7.06608186,42.3534309 L20.0660819,40.6200976 C20.3144849,40.5869772 20.5,40.3750849 20.5,40.1244836 L20.5,26.5 C20.5,22.6340068 17.3659932,19.5 13.5,19.5 Z"/> \
					<use  \
						fill="#6B7C93"  \
						xlink:href="#m"/> \
					<path  \
						stroke="#6B7C93"  \
						d="M13,17.5 C7.75329488,17.5 3.5,21.7532949 3.5,27 L3.5,39.2984379 C3.5,39.7541135 3.70713458,40.1850828 4.06295743,40.4697411 C4.70985033,40.9872554 5.65378889,40.8823734 6.17130321,40.2354805 L6.7274488,39.5402985 L6.91329508,39.5075751 L15.4414166,38.0059652 L16.4803878,37.8222062 C20.540688,37.1040757 23.5,33.5754312 23.5,29.4521135 L23.5,27 C23.5,21.7532949 19.2467051,17.5 14,17.5 L13,17.5 Z"/> \
					<path  \
						fill="#E4EAF0"  \
						d="M13.5 34L14.0574682 33.6283545 14.0574682 33.6283545C14.5169969 33.3220021 15.1378663 33.4461759 15.4442187 33.9057046 15.4672194 33.9402056 15.4880519 33.9761038 15.5065956 34.0131912L17 37 15.7102631 37.4299123 15.7102631 37.4299123C14.7005104 37.7664965 13.5904078 37.3856118 13 36.5L13 36.5 13 36.5C12.4477153 35.6715729 12.6715729 34.5522847 13.5 34zM13.5 1L13.5 1 13.5 1C17.0898509 1 20 3.91014913 20 7.5L20 12 20 12C20 14.209139 18.209139 16 16 16L11 16 11 16C8.790861 16 7 14.209139 7 12L7 7.5 7 7.5C7 3.91014913 9.91014913 1 13.5 1z"/> \
					<g> \
						<use  \
							fill="#6B7C93"  \
							xlink:href="#n"/> \
						<path  \
							stroke="#6B7C93"  \
							d="M9.52746439,4.5 L15,4.5 C16.3807119,4.5 17.5,3.38071187 17.5,2 L17.5,0.5 L14,0.5 C11.6837232,0.5 9.77618653,2.25002477 9.52746439,4.5 Z"/> \
					</g> \
				</g> \
				<text  \
					class="times-first" \
					fill="#6B7C93"  \
					font-family="FontAwesome"  \
					font-size="16"> \
					<tspan  \
						x="44.714"  \
						y="49"></tspan> \
				</text> \
				<text  \
					class="times-third"		 \
					fill="#6B7C93"  \
					font-family="FontAwesome"  \
					font-size="16"> \
					<tspan  \
						x="133.714"  \
						y="35"></tspan> \
				</text> \
				<text  \
					class="times-fourth"			 \
					fill="#6B7C93"  \
					font-family="FontAwesome"  \
					font-size="16"> \
					<tspan  \
						x="186.714"  \
						y="39"></tspan> \
				</text> \
				<text  \
					class="times-second"		 \
					fill="#6B7C93"  \
					font-family="FontAwesome"  \
					font-size="16"> \
					<tspan  \
						x="88.714"  \
						y="55"></tspan> \
				</text> \
				<g  \
					transform="translate(93 155)"> \
					<mask  \
						id="p"  \
						fill="white"> \
						<use  \
						xlink:href="#o"/> \
					</mask> \
					<use  \
						fill="#A4B3C7"  \
						xlink:href="#o"/> \
					<ellipse  \
						cx="26"  \
						cy="15.5"  \
						fill="#4B5562"  \
						mask="url(#p)"  \
						rx="26"  \
						ry="7.5"/> \
				</g> \
				<g  \
					transform="translate(157 140)"> \
					<mask  \
						id="r"  \
						fill="white"> \
						<use  \
						xlink:href="#q"/> \
					</mask> \
					<use  \
						fill="#A4B3C7"  \
						xlink:href="#q"/> \
					<ellipse  \
						cx="26"  \
						cy="14.5"  \
						fill="#4B5562"  \
						mask="url(#r)"  \
						rx="26"  \
						ry="6.5"/> \
				</g> \
			</g> \
		</svg>';
	}
});

import AdminHeader from 'Components/Admin/AdminHeader';
import { Icon, Tooltip, Button } from '@upsales/components';
import TriggerValidator from 'App/babel/helpers/triggerValidator';
import t from 'Components/Helpers/translate';
import _ from 'lodash';
import React from 'react';

ReactTemplates.admin.triggerTable = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		this.lang = {
			name: t('default.name'),
			newTrigger: t('admin.newTrigger'),
			noTriggers: t('admin.noTriggers'),
			trigger: t('admin.trigger'),
			and: t('default.and').toLowerCase(),
			more: t('filters.more').toLowerCase(),
			noActions: t('admin.noActions'),
			actions: t('admin.actions'),
			no: t('default.nothings'),
			remove: t('default.remove'),
			noName: t('default.noName'),
			noDescription: t('default.noDescription'),
			dontExecActivityTriggerTooltip: t('admin.trigger.dontExecActivityTrigger')
		};
	},
	getInitialState: function () {
		return {
			sort: 'name',
			descending: false
		};
	},
	getActionText: function (trigger) {
		if (!trigger.actions || !trigger.actions.length) {
			return null;
		}
		var t = window.Tools.$translate;
		var text = t('automationAction.' + trigger.actions[0].alias);

		if (trigger.actions.length > 1) {
			text += ' + ' + (trigger.actions.length - 1);
		}
		return text;
	},
	getActionTooltip: function (trigger) {
		var t = window.Tools.$translate;
		return _.map(trigger.actions, function (action) {
			return t('automationAction.' + action.alias);
		}).join('\n');
	},
	deleteTrigger: function (trigger, e) {
		e.stopPropagation();
		this.props.deleteTrigger(trigger);
	},
	getEventText: function (trigger) {
		var t = window.Tools.$translate;
		var entity = trigger.events[0].split('_')[1];

		if (entity === 'userdefobj') {
			var udoObj = trigger.events[0].slice(-1);
			var UDOs = Tools.AppService.getMetadata().params.UserDefinedObject;

			if (!/[2-4]/g.test(udoObj)) {
				udoObj = 1;
			} else {
				udoObj = parseInt(udoObj);
			}

			var udo = UDOs.find(function (udo) {
				return udo.id === udoObj;
			});

			return udo.name;
		} else {
			return t('default.' + entity);
		}
	},
	getEventSubText: function (trigger) {
		var t = window.Tools.$translate;
		var event = trigger.events[0].split('_')[2];
		var langMap = {
			insert: 'create',
			update: 'editing',
			delete: 'deleting'
		};

		if (trigger.events.length > 1) {
			return t('admin.triggerOn.createAndEditing');
		} else {
			return t('admin.triggerOn.' + langMap[event]);
		}
	},
	updateSort: function (sort) {
		var descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	},
	getSortClass: function (name, type) {
		var sort = this.state.sort;
		var descending = this.state.descending;

		if (sort !== name) {
			return 'fa fa-sort';
		} else if (type === 'number') {
			return descending ? 'fa fa-sort-numeric-desc' : 'fa fa-sort-numeric-asc';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	},
	getRow: function (trigger, i) {
		var editTrigger = this.props.editTrigger;
		let dontExec;
		let dontExecTooltip;

		if (
			Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
			Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
		) {
			dontExec =
				trigger.actions.some(t => t.alias === 'CreateActivity' || t.alias === 'UpdateActivity') ||
				trigger.events[0]?.includes('activity');
			dontExecTooltip = this.lang.dontExecActivityTriggerTooltip;
		}

		if (!dontExec) {
			const { isValid, errorLangTag } = TriggerValidator(trigger);
			if (!isValid && errorLangTag) {
				dontExec = true;
				dontExecTooltip = t(errorLangTag);
			}
		}

		var className = 'clickable two-lines';
		if (!trigger.active) {
			className += ' inactive-row';
		}
		if (dontExec) {
			className += ' dontexec';
		}
		var eventText = '',
			eventSubText = '';
		var hasEvents = trigger.events && trigger.events.length;

		if (hasEvents) {
			eventText = this.getEventText(trigger);
			eventSubText = this.getEventSubText(trigger);
		}

		var actionText = this.getActionText(trigger);
		var actionTooltip = this.getActionTooltip(trigger);
		const actionIcon = dontExec ? (
			<Tooltip title={dontExecTooltip}>
				<Icon color="orange" name="exclamation-triangle" />
			</Tooltip>
		) : null;
		var nameElement = trigger.name ? (
			<div className="ellipsis" style={{ top: '5px' }}>
				{trigger.name}
			</div>
		) : (
			<div className="ellipsis text-upsales-grey" style={{ top: '5px' }}>
				{this.lang.noName}
			</div>
		);

		var descriptionElement = trigger.description ? (
			<div className="subtitle ellipsis" style={{ bottom: 0 }}>
				{trigger.description}
			</div>
		) : (
			<div className="subtitle ellipsis text-upsales-grey" style={{ bottom: 0 }}>
				{this.lang.noDescription}
			</div>
		);

		return (
			<tr key={'trigger-' + i} className={className} onClick={editTrigger.bind(this, trigger)}>
				<td>
					<div className="ellipsis-wrap" style={{ height: '100%' }}>
						<div className={dontExec ? 'dontexec-titlecol' : ''}>
							{actionIcon}
							<div>
								{nameElement}
								{descriptionElement}
							</div>
						</div>
					</div>
				</td>
				<td>
					<div className="ellipsis-wrap" style={{ height: '100%' }}>
						{hasEvents ? (
							[
								<div key="text" className="ellipsis" style={{ top: '5px' }}>
									{eventText}
								</div>,
								<div key="subtext" className="subtitle ellipsis" style={{ bottom: 0 }}>
									{eventSubText}
								</div>
							]
						) : (
							<div className={'ellipsis text-upsales-grey'} style={{ top: '12px' }}>
								{this.lang.noEvents}
							</div>
						)}
					</div>
				</td>
				<td>
					{trigger.actions && trigger.actions.length > 1 ? (
						ReactTemplates.TOOLS.withTooltip(
							<span className={actionText ? '' : 'text-upsales-grey'}>{actionText}</span>,
							actionTooltip,
							{ placement: 'right' }
						)
					) : (
						<span className={actionText ? '' : 'text-upsales-grey'}>
							{actionText || this.lang.noActions}
						</span>
					)}
				</td>
				<td className="admin-row-tools-wrap">
					<div className="admin-row-tools">
						{ReactTemplates.TOOLS.withTooltip(
							<button
								className="up-btn btn-link btn-grey delete-trigger"
								onClick={this.deleteTrigger.bind(this, trigger)}
							>
								<b className="fa fa-trash-o"></b>
							</button>,
							this.lang.remove,
							{ placement: 'left' }
						)}
					</div>
				</td>
			</tr>
		);
	},
	render: function () {
		var self = this;

		var triggers = this.props.triggers;
		var editTrigger = this.props.editTrigger;

		var newTriggerButton = this.props.showNewTrigger ? (
			<div className="pull-right">
				<Button onClick={editTrigger} size="sm">
					{this.lang.newTrigger}
				</Button>
			</div>
		) : null;

		var rows = _.sortBy(triggers, function (t) {
			var sort = self.state.sort;

			if (sort === 'trigger') {
				if (t.events && t.events.length) {
					return self.getEventText(t);
				}
				return null;
			} else if (sort === 'action') {
				return self.getActionText(t) || '';
			}

			return (t[sort] || '').toLowerCase();
		}).map(this.getRow);

		if (this.state.descending) {
			rows = rows.reverse();
		}

		var content = triggers.length ? (
			<table>
				<thead>
					<tr>
						<th
							onClick={this.updateSort.bind(this, 'name')}
							className={'sortable' + (this.state.sort === 'name' ? ' active' : '')}
						>
							{this.lang.name} <i className={this.getSortClass('name')} />
						</th>
						<th
							onClick={this.updateSort.bind(this, 'trigger')}
							className={'sortable' + (this.state.sort === 'trigger' ? ' active' : '')}
						>
							{this.lang.trigger} <ReactTemplates.elevio articleId="483" />{' '}
							<i className={this.getSortClass('trigger')} />
						</th>
						<th
							onClick={this.updateSort.bind(this, 'action')}
							className={'sortable' + (this.state.sort === 'action' ? ' active' : '')}
						>
							{this.lang.actions} <ReactTemplates.elevio articleId="482" />{' '}
							<i className={this.getSortClass('action')} />
						</th>
						<th style={{ width: '60px' }}></th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		) : (
			<div className="table-no-result">{this.lang.no + ' ' + this.props.title.toLowerCase()}</div>
		);

		return (
			<div className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">{this.props.title}</span>
					{newTriggerButton}
				</div>
				{content}
			</div>
		);
	}
});

ReactTemplates.admin.triggers = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			triggers: t('feature.triggers'),
			triggersInfo: t('admin.triggersInfo'),
			active: t('admin.trigger.active'),
			inactive: t('admin.trigger.inactive')
		};
	},
	render: function () {
		var rootData = this.props.rootData;
		var separatedTriggers = _.reduce(
			rootData.pageData.triggers,
			function (res, trigger) {
				if (trigger.active) {
					res.active.push(trigger);
				} else {
					res.inactive.push(trigger);
				}
				return res;
			},
			{ active: [], inactive: [] }
		);

		return (
			<div id="admin-page-triggers">
				<AdminHeader
					title={this.lang.triggers}
					description={this.lang.triggersInfo}
					image="triggers.svg"
					articleId={452}
				/>
				<div id="admin-content">
					<ReactTemplates.admin.triggerTable
						title={this.lang.active}
						triggers={separatedTriggers.active}
						showNewTrigger={true}
						editTrigger={rootData.editTrigger}
						deleteTrigger={rootData.deleteTrigger}
					/>
					<ReactTemplates.admin.triggerTable
						title={this.lang.inactive}
						triggers={separatedTriggers.inactive}
						showNewTrigger={false}
						editTrigger={rootData.editTrigger}
						deleteTrigger={rootData.deleteTrigger}
					/>
				</div>
			</div>
		);
	}
});

'use strict';

ReactTemplates.form.imageContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element,
			linkHref: this.props.element.linkHref
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage'),
			opacity: t('bannerEditor.opacity'),
			imgPosition: t('landingpage.imgPosition'),
			link: t('default.link'),
			maxWidth: t('editor.imageWidthMax')
		};
	},
	cloneElement() {
		return { ...this.state.element, value: { ...this.state.element.value } };
	},
	calculateWidth(src) {
		return new Promise(resolve => {
			angular
				.element('<img/>')
				.attr('src', src)
				.load(function () {
					resolve(this.width);
				});
		});
	},
	browseImages: function () {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('fileBrowser', {
				types: ['image'],
				public: true,
				selectOnUpload: true
			})
			.then(files => {
				const src = files[0].$$publicUrl;
				return Promise.all([src, this.calculateWidth(src)]);
			})
			.then(([src, width]) => {
				const element = this.cloneElement();
				element.value.src = src;
				element.value.naturalWidth = width;
				element.value.width = width;

				//this.setState({ element: element });
				this.props.onChange(element);
			});
	},
	opacityChanged: function (value) {
		var element = this.state.element;

		element.value.opacity = value;

		this.setState({ element: element });
		this.props.onChange(element);
	},
	sizeChanged: function (value) {
		const element = this.cloneElement();
		element.value.width = value;
		this.setState({ element });
		this.props.onChange(element);
	},
	setAlignment: function (value) {
		var element = this.state.element;

		element.value.align = value;

		this.setState({ element: element });
		this.props.onChange(element);
	},
	setlinkHref: function (value) {
		const element = this.state.element;
		element.linkHref = value;
		element.linkTarget = '_blank';
		this.setState({ element, linkHref: value });
		this.props.onChange(element);
	},
	render: function () {
		var self = this;
		var imgElem = null;
		var hasImg = self.state.element.value.src && self.state.element.value.src.length;
		var alignTools;

		if (hasImg) {
			var alignClass = 'btn up-btn btn-xs no-shadow btn-light-grey';
			var selectedAlignClass = 'btn up-btn btn-xs no-shadow btn-green';
			var imgAlign = self.state.element.value.align;
			var style = {
				opacity: self.state.element.value.opacity / 100,
				backgroundImage: "url('" + self.state.element.value.src + "')"
			};
			imgElem = <div className="img-element" style={style}></div>;

			alignTools = (
				<div className="tool-section" key="align-row">
					<label>{self.lang.imgPosition}</label>
					<div className="pull-right btn-group">
						<button
							type="button"
							className={imgAlign === 'left' ? selectedAlignClass : alignClass}
							onClick={self.setAlignment.bind(self, 'left')}
						>
							<b className="fa fa-align-left"></b>
						</button>
						<button
							type="button"
							className={imgAlign === 'center' ? selectedAlignClass : alignClass}
							onClick={self.setAlignment.bind(self, 'center')}
						>
							<b className="fa fa-align-center"></b>
						</button>
						<button
							type="button"
							className={imgAlign === 'right' ? selectedAlignClass : alignClass}
							onClick={self.setAlignment.bind(self, 'right')}
						>
							<b className="fa fa-align-right"></b>
						</button>
					</div>
					<div className="image-link-section">
						<label>{self.lang.link}</label>
						<input
							className="Input__input"
							type="text"
							value={self.state.element.linkHref || ''}
							onChange={e => this.setlinkHref(e.target.value)}
							placeholder={'https://upsales.com'}
						/>
					</div>
				</div>
			);
		}

		return (
			<div className="tool-main-section">
				<div className="tool-section" key="img-row">
					<div className="img-preview">
						{imgElem}

						<button className="up-btn btn-green" onClick={self.browseImages}>
							{imgElem ? self.lang.changeImage : self.lang.setImage}
						</button>
					</div>
				</div>
				{hasImg ? (
					<div className="tool-section" key="opacity-row">
						<label>{self.lang.opacity}</label>
						<div className="pull-right">
							<ReactTemplates.upStepInt
								min={0}
								max={100}
								value={self.state.element.value.opacity}
								className="input-sm pull-right"
								onChange={self.opacityChanged}
							/>
						</div>
					</div>
				) : null}
				{hasImg ? (
					<div className="tool-section" key="size-row">
						<label>{this.lang.maxWidth}</label>
						<div className="pull-right">
							<ReactTemplates.upStepInt
								min={0}
								max={Infinity}
								value={self.state.element.value.width || 0}
								className="input-sm pull-right"
								onChange={self.sizeChanged}
							/>
						</div>
					</div>
				) : null}
				{alignTools}
			</div>
		);
	}
});

'use strict';

angular.module('domain.advancedSearch').controller('AdvancedSearchAppointment', [
	'AppService',
	'$scope',
	'$stateParams',
	function (AppService, $scope, $stateParams) {
		var AdvancedSearchAppointment = this;
		var AdvancedSearch = $scope.AdvancedSearch;

		var init = function () {
			AdvancedSearch.changeSearchType(AdvancedSearch.entity.APPOINTMENT, $stateParams.notificationEntityId);

			AdvancedSearch.loaded = true;
		};

		AppService.loadedPromise.then(init);
	}
]);

'use strict';

angular.module('upAttributes').service('DeleteLogAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				obj: {
					title: 'default.object',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				user: {
					title: 'default.user',
					field: 'email',
					type: FilterType.String,
					displayType: DisplayType.String,
					displayAttr: 'email',
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						},
						email: {
							field: 'user.email',
							type: FilterType.String
						},
						role: {
							field: 'user.role',
							type: FilterType.Object,
							attr: {
								id: {
									field: 'user.role.id',
									type: FilterType.Number
								}
							}
						}
					}
				},
				type: {
					title: 'default.type',
					field: 'type',
					displayType: DisplayType.String,
					type: FilterType.String
				}
			};

			var standardFilters = {};

			var requiredFields = [];

			return {
				getAll: function () {
					return attributes;
				},
				standardFilters: standardFilters,
				attr: attributes,
				requiredFields: requiredFields,
				keys: Object.keys(attributes)
			};
		};
	}
]);

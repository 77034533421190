import tippy from 'tippy.js';
import logError from 'App/babel/helpers/logError';
import { checkUnbounceContact, NOT_ABLE_TO_DEBOUNCE_REASONS } from 'App/babel/helpers/bounce';
import T from 'Components/Helpers/translate';
import entityName from 'Components/Helpers/EntityName';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { compressChanges } from 'App/components/ListView/ListViewHelpers';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import ValidationService from 'Services/ValidationService';

angular.module('domain.contact').controller('EditContact', [
	'$scope',
	'$modalParams',
	'$stateParams',
	'Contact',
	'AppService',
	'Lookup',
	'$timeout',
	'ParseHelpers',
	'ScriptService',
	'$upModal',
	'FeatureHelper',
	'SuggestEmail',
	'$state',
	'FilterHelper',
	'EmailDuplicates',
	'$q',
	'$safeApply',
	function (
		$scope,
		$modalParams,
		$stateParams,
		Contact,
		AppService,
		Lookup,
		$timeout,
		ParseHelpers,
		ScriptService,
		$upModal,
		FeatureHelper,
		SuggestEmail,
		$state,
		FilterHelper,
		EmailDuplicates,
		$q,
		$safeApply
	) {
		var EditContact = this;
		var customerId = Tools.AppService.getCustomerId();
		var meta = $modalParams.meta;
		var self;
		var typeTimer = null;
		let initialForm = [];
		let activeState = 0;

		EditContact.editable = true;
		EditContact.showSuggestion = false;
		$scope.showInlineAction = 'none';

		EditContact.save = function () {
			EditContact.saving = true;
			var contactToSave = EditContact.contactToSave || angular.copy(EditContact.contact);

			contactToSave.campaigns = contactToSave.projects;
			contactToSave.optins = contactToSave.manualOptins.concat(
				_.filter(contactToSave.optins, function (o) {
					return o.type !== 'manual';
				})
			);

			ScriptService.contact
				.save(contactToSave)
				.then(function () {
					Contact.customer(customerId)
						.save(contactToSave)
						.then(function (response) {
							$scope.resolve(response.data);
						})
						.catch(function () {
							EditContact.saving = false;
						});
				})
				.catch(function () {
					EditContact.saving = false;
				});
		};

		EditContact.addAccount = function () {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', {
					noRedirect: true,
					onClose: client => {
						if (client) {
							// Set account
							EditContact.contact.client = client;
							$safeApply($scope);
						}
					}
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editAccount', { customerId: customerId, fromModal: true }).then(function (res) {
					// Set account
					EditContact.contact.client = res;
				});
			}
		};

		EditContact.debounce = null;

		var suggestEmailFnDebounce = null;
		var suggestEmailFn = function (contact) {
			SuggestEmail.get(contact.client.id, contact.firstName, contact.lastName)
				.then(function (res) {
					EditContact.suggestEmail = res.data ? res.data : '';
					if (EditContact.contact.email !== EditContact.suggestEmail) {
						EditContact.showSuggestion = true;
						angular.element('#emailSuggestion').addClass('show-email-suggestion');
						angular.element('#emailSuggestion').css('display', 'block');
					}
				})
				.catch(e => {
					logError(e, 'Error fetching suggested email');
				});
		};

		EditContact.getSuggestion = function (contact = {}) {
			if (!Tools.AppService.getSelf().userParams.emailSuggestions) return;

			if (contact.client && contact.client.id && contact.firstName && contact.lastName) {
				if (!suggestEmailFnDebounce) {
					suggestEmailFnDebounce = _.debounce(suggestEmailFn, 500);
				}
				suggestEmailFnDebounce(contact);
			}
		};

		EditContact.insertSuggestEmail = function (suggestEmail) {
			EditContact.contact.email = suggestEmail;
			angular.element('#emailSuggestion').removeClass('show-email-suggestion');
			angular.element('#emailSuggestion').addClass('hide-email-suggestion');
			setTimeout(function () {
				angular.element('#emailSuggestion').css('display', 'none');
				angular.element('#emailSuggestion').removeClass('hide-email-suggestion');
			}, 1000);
			EditContact.clientSuggestionDuplicates = [];
			toggleEmailWarning(false);
		};

		EditContact.titleLookup = function (term) {
			if (term && term.length) {
				if (typeTimer) {
					$timeout.cancel(typeTimer);
				}

				typeTimer = $timeout(function () {
					return Lookup.customer(customerId)
						.setType(Lookup.allTypes.CONTACT)
						.setCaseSensitive()
						.findEnd(Contact.attr.title, term)
						.then(function (res) {
							return _.unique(_.map(_.pluck(res.data, 'value'), _.trim));
						});
				}, 200);

				return typeTimer;
			}

			return [];
		};

		$scope.closeModal = function (position) {
			initialForm.active = activeState;
			// The angular.copy removes internal angular properties (starting with $) which gives a more reliable comparison.
			// Remove client, empty categories and dates to prevent faulty compare. This is kind of a hack to compensate for angular's inability to work.
			const ignores = ['client', 'regDate', 'modDate', '$mappedCustom'];
			const omitKeys = obj =>
				Object.keys(angular.copy(obj)).reduce((memo, key) => {
					if (!ignores.includes(key)) {
						memo[key] = obj[key];
					}
					return memo;
				}, {});
			if (
				initialForm.custom &&
				!_.isEqual(
					{
						...omitKeys(initialForm),
						custom: initialForm.custom.map(c => ({ value: c.value ?? null }))
					},
					{
						...omitKeys(EditContact.contact),
						custom: EditContact.contact.custom.map(c => ({ value: c.value ?? null }))
					}
				) &&
				$scope.ContactForm.$valid
			) {
				$scope.showInlineAction = position;
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		};

		$scope.rejectChanges = function () {
			$scope.reject();
		};

		$scope.saveChanges = function () {
			EditContact.save();
		};

		$scope.closeInlineAction = function () {
			$scope.showInlineAction = 'none';
			$safeApply($scope);
		};

		EditContact.getContactLink = function (contact) {
			return $state.href('contact.dashboard', { customerId: customerId, id: contact.id });
		};

		EditContact.getCompanyLink = function (contact) {
			return $state.href('account.dashboard', { customerId: customerId, id: contact.client?.id });
		};

		EditContact.getFilteredListLink = function () {
			const filters = {
				Email: {
					filterName: 'Email',
					comparisonType: 'Search',
					inactive: false,
					value: EditContact.contact.email,
					type: 'text'
				}
			};
			const hasNewContactList = Tools.FeatureHelper.hasSoftDeployAccess('LIST_CONTACTS_REACT');

			const query = FilterHelper.convertForURL(filters, 'contact');
			let href = $state.href(hasNewContactList ? 'react-root-contacts' : 'contacts', {
				customerId: customerId,
				f: hasNewContactList ? encodeURIComponent(compressChanges({ filters })) : undefined
			});
			// Cannot be added above since q is not a param to angular states
			if (!hasNewContactList) {
				href += `?q=${query}`;
			}

			return href;
		};

		EditContact.editDuplicatedContact = function (contactId) {
			$upModal.open('editContact', { customerId: customerId, id: contactId, meta: {} });
			$scope.close();
		};

		var formatResult = function (obj, container, escape) {
			return escape(obj.name);
		};

		var id = function (obj) {
			return obj.id.toString();
		};

		var categoryMatcher = function (term, text, obj) {
			if (!obj.$hasAccess) {
				return false;
			}
			return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
		};

		var formatCategoryResult = function (obj, row, query, escape) {
			if (!obj.$hasAccess) {
				row.hide();
			}
			return escape(obj.name);
		};

		EditContact.categorySelect = {
			data: [],
			formatResult: formatCategoryResult,
			formatSelection: formatResult,
			matcher: categoryMatcher,
			id: id
		};

		EditContact.campaignSelect = {
			data: [],
			formatResult: (obj, container, query, escape) => formatResult(obj, container, escape),
			formatSelection: formatResult,
			matcher: function (term, text, op) {
				return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0 && op.active !== 0;
			},
			id: id
		};

		EditContact.changeCellPhone = function (phone, countryCode) {
			EditContact.contact.cellPhone = phone;
			EditContact.contact.cellPhoneCountryCode = countryCode;
			$safeApply($scope);
		};

		EditContact.changePhone = function (phone, countryCode) {
			EditContact.contact.phone = phone;
			EditContact.contact.phoneCountryCode = countryCode;
			$safeApply($scope);
		};

		EditContact.toggleActive = function () {
			if (EditContact.contact.id) {
				const active = $scope.activeToggle ? 1 : 0;
				activeState = active;
				EditContact.contact.active = active;
				const partialSave = {
					id: EditContact.contact.id,
					active
				};

				EditContact.saving = true;
				Contact.customer(customerId)
					.save(partialSave)
					.then(function () {
						EditContact.saving = false;
					})
					.catch(function () {
						EditContact.saving = false;
					});
			}
		};

		EditContact.hasCategories = function (categoryTypeId) {
			var categories = AppService.getCategories('contact');
			return (
				_.filter(categories, function (type) {
					return type.categoryType === categoryTypeId || type.categoryTypeId === categoryTypeId;
				}).length > 0
			);
		};

		EditContact.hasInitialEmailChanged = function () {
			return EditContact.initialEmail && EditContact.initialEmail !== EditContact.contact.email;
		};

		EditContact.isDuplicateConditionMet = function () {
			return (
				!FeatureHelper.hasSoftDeployAccess('ALLOW_DUPLICATE_CONTACTS') &&
				EditContact.clientSuggestionDuplicates?.length &&
				(!EditContact.edit || EditContact.hasInitialEmailChanged())
			);
		};

		EditContact.getTooltipMessageOrNull = function () {
			if (EditContact.isDuplicateConditionMet()) {
				return this.duplicateEmailMessage;
			}
			return null;
		};

		EditContact.onUnBounceContact = function () {
			EditContact.unBouncing = true;

			Contact.unbounce(EditContact.contact)
				.then(() => {
					EditContact.contact.mailBounces = EditContact.contact?.mailBounces?.filter(
						bounceType => bounceType !== 'hard_bounce'
					);
					// Don't want this to trigger the dirty check
					initialForm.mailBounces = EditContact.contact?.mailBounces?.filter(
						bounceType => bounceType !== 'hard_bounce'
					);
					EditContact.unBouncing = false;
					EditContact.showUnBounce = false;
				})
				.catch(error => {
					if (!error?.status || error.status >= 500) {
						logError(error, 'mailCampaignRecipients.js - onUnBounceContact');
					}
					EditContact.unBouncing = false;
				});
		};

		var initStandardFields = function (fields) {
			fields.social = _.filter(fields, function (field) {
				return field.group === 'social' && field.active;
			});
			fields.standard = _.filter(fields, function (field) {
				return field.group === 'standard' && field.canHide && field.active;
			});

			return fields;
		};

		var validateEmailFn = function (contact, email) {
			if (!ValidationService.validEmail(email)) {
				return;
			}
			getEmailDuplicates(contact, email)
				.then(function (data) {
					if (Array.isArray(data) && data.length === 1) {
						return Contact.customer(customerId)
							.get(data[0].id)
							.then(function (contactData) {
								return [contactData.data];
							});
					}
					return data;
				})
				.then(function (data) {
					EditContact.showSuggestionWarning = !!data.length;
					EditContact.suggestionDuplicates = data;

					const clientSuggestionDuplicates = data.filter(item => item.client.id === contact.client.id);
					EditContact.clientSuggestionDuplicates = clientSuggestionDuplicates;
					EditContact.duplicateEmailMessage =
						clientSuggestionDuplicates.length === 1
							? Tools.$translate('contact.saveDuplicateEmail.tooltip')
							: Tools.$translate('contact.saveDuplicateEmails.tooltip', {
									label: Tools.$translate('contact.viewContactsWithSuchEmail')
							  });
					toggleEmailWarning(EditContact.showSuggestionWarning);
				})
				.catch(e => {
					logError(e, 'Failed to get email duplicates');
				});
		};

		EditContact.validateEmail = _.debounce(validateEmailFn, 500);

		function getEmailDuplicates(contact, email) {
			var promise =
				email && contact.client
					? EmailDuplicates.get(contact.client.id, contact.id, email)
					: $q.when({ data: [] });

			return promise
				.then(function (res) {
					return res.data;
				})
				.catch(function () {
					return [];
				});
		}

		function toggleEmailWarning(isOpen) {
			angular.element('#emailSuggestionWarning').toggleClass('show-email-suggestion', isOpen);
			angular.element('#emailSuggestionWarning').toggleClass('hide-email-suggestion', !isOpen);

			if (!isOpen) {
				setTimeout(function () {
					angular.element('#emailSuggestionWarning').css('display', 'none');
				}, 1000);
			}
		}

		$scope.removeContact = function () {
			var customerId = AppService.getCustomerId();
			var confirmButtonText = T('admin.modal.deleteEntity', {
				entity: T(entityName('contact', 1)).toLowerCase()
			});
			var alertConfirmOptions = {
				type: 'confirm',
				reactive: true,
				fullscreen: true,
				hideAbort: false,
				dialog: AlertConfirm,
				id: 'confirm-delete-fields',
				body: React.createElement(AlertBody, {
					customerId: customerId,
					numSelected: 1,
					entity: 'contact'
				}),
				confirmButtonText: confirmButtonText,
				confirmClass: 'btn-red',
				confirmationMode: 'text',
				confirmText: AppService.getSelf().email,
				confirmFn: function () {
					// Delete
					return Contact.customer(customerId)
						['delete'](EditContact.contact)
						.then(function () {
							if (EditContact.contact.client) {
								$state.go('account.dashboard', {
									id: EditContact.contact.client.id,
									customerId: EditContact.customerId
								});
							}
						});
				}
			};

			if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				return openModal('Alert', {
					...alertConfirmOptions,
					title: getConfirmationTitle('contact', 1, EditContact.contact.name),
					onClose: confirmed => {
						if (confirmed) {
							alertConfirmOptions.confirmFn();
						}
					}
				});
			}

			return $upModal.open('alert', alertConfirmOptions);
		};

		var init = function () {
			var metadata = AppService.getMetadata();
			self = AppService.getSelf();

			$scope.requiredFields = metadata.requiredFields.Contact;
			if ($modalParams.forceRequiredEmail) {
				$scope.requiredFields.Email = true;
			}

			$scope.standardFields = initStandardFields(metadata.standardFields.Contact);

			EditContact.contact = meta.contact.data || {};
			EditContact.contact.manualOptins = _.filter(EditContact.contact.optins, function (o) {
				return o.type === 'manual';
			});
			EditContact.contact.campaigns = EditContact.contact.projects;
			EditContact.customFields = AppService.getCustomFields('contact');
			EditContact.canShowFormGroups = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS);
			if (EditContact.canShowFormGroups) {
				EditContact.formGroups = Array.from(
					EditContact.customFields
						.sort((a, b) => a.sortId - b.sortId)
						.reduce((previous, field) => {
							previous.add(field.formGroup);
							return previous;
						}, new Set())
				);
			} else {
				EditContact.formGroups = [null];
			}
			EditContact.customFieldsLength = _.filter(EditContact.customFields, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;
			EditContact.ownRightsId = self.createRights.Contact === 'OWN' ? self.id : undefined;
			EditContact.categoryTypes = AppService.getCategoryTypes('contact');
			EditContact.edit = !!meta.contact.data.id;
			EditContact.initialEmail = EditContact.edit && EditContact.contact.email;
			EditContact.hasOptInAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.OPT_IN);

			EditContact.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			EditContact.showSalutation =
				EditContact.hasNewFields &&
				metadata.standardFields.Contact.Salutation &&
				metadata.standardFields.Contact.Salutation.active;
			EditContact.showTitleCategory =
				EditContact.hasNewFields &&
				metadata.standardFields.Contact.TitleCategory &&
				metadata.standardFields.Contact.TitleCategory.active;
			EditContact.hasJourney = FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS);

			if (EditContact.hasJourney) {
				EditContact.journeySteps = Tools.AppService.getJourneySteps();
			}

			const { valid: canUnbounceContact, reason: unableToUnBounceReason } = checkUnbounceContact(
				EditContact.contact
			);
			EditContact.showUnBounce =
				canUnbounceContact || unableToUnBounceReason === NOT_ABLE_TO_DEBOUNCE_REASONS.MAX_REMOVALS_REACHED;
			EditContact.unBounceTooltip =
				unableToUnBounceReason === NOT_ABLE_TO_DEBOUNCE_REASONS.MAX_REMOVALS_REACHED
					? 'email.unbounce.maxNumberRemovalsReached'
					: '';
			EditContact.unBouncing = false;
			EditContact.originalEmail = EditContact.contact.email;

			EditContact.contact.custom = !EditContact.contact.custom ? [] : EditContact.contact.custom;
			ParseHelpers.parseCustom(EditContact.contact, EditContact.customFields);

			if (!EditContact.edit) {
				// FIXME: fixa detta
				if ($modalParams.account) {
					EditContact.contact.client = $modalParams.account;
				} else if ($modalParams.clientId && meta.client?.data) {
					EditContact.contact.client = meta.client.data;
				} else {
					EditContact.showAccount = true;
				}
				if ($modalParams.titleCategory) {
					EditContact.contact.titleCategory = $modalParams.titleCategory;
				}
				EditContact.contact.active = 1;
				activeState = 1;
				const shouldSyncWithAccount = metadata.params.SyncJourneyStatus;
				if (shouldSyncWithAccount) {
					EditContact.contact.journeyStep = EditContact.contact.client?.journeyStep;
				}
			} else {
				EditContact.editable = EditContact.contact.userEditable;
				EditContact.showAccount = false;
				activeState = meta.contact.data.active;
			}

			$scope.activeToggle = !!EditContact.contact.active;

			setTimeout(function () {
				tippy('#notes', {
					animation: 'fade',
					arrow: true,
					hideOnClick: false,
					interactive: true,
					maxWidth: '230px',
					placement: 'top',
					size: 'large',
					theme: 'standard-field',
					trigger: 'focus',
					zIndex: 11000
				});
			});

			ScriptService.contact.edit(EditContact.contact);

			validateEmailFn(EditContact.contact, EditContact.contact.email);
			//Adding a timeout because initialForm gets set with incorrect phone/cellphone values
			//due to a 2-way binding issue with the phone/cellphone fields
			setTimeout(function () {
				initialForm = _.cloneDeep(EditContact.contact);
			}, 200);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

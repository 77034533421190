'use strict';

angular.module('upResources').service('MapContact', [
	'MapHelpers',
	function (MapHelpers) {
		return function (payload) {
			var contact = payload.contact;

			MapHelpers.mapCategories(contact);

			if (contact.account) {
				contact.client = contact.account;
			}

			if (contact.campaigns) {
				contact.projects = contact.campaigns;
			}

			payload.contact = contact;
			return payload;
		};
	}
]);

'use strict';

angular.module('upResources').factory('Score', [
	'$resource',
	'$q',
	'ScoreAttributes',
	'URL',
	'API',
	'GenericMapper',
	function Client($resource, $q, ScoreAttributes, URL, API, GenericMapper) {
		var SA = ScoreAttributes();
		var Attribute = SA.attr;
		var Keys = SA.keys;

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Score = null;

				Score = $resource(
					URL + API + customer + '/scoreboard/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.find = function (filter, options) {
					var params = _.extend(filter, options || {});

					return Score.get(params).$promise;
				};

				instance.get = function (id) {
					return Score.get({ id: id }).$promise;
				};

				instance.save = function (account) {
					var defered = $q.defer();

					if (!account) return defered.reject('No account');

					GenericMapper.map(account, Attribute, function (err, mappedScore) {
						if (err) return defered.reject('Score mapping error');

						if (account.id) {
							return Score.update(
								{ id: account.id },
								{ account: mappedScore },
								function (response) {
									if (response.error) {
										defered.reject(response);
									}
									defered.resolve(response);
								},
								defered.reject
							);
						}

						Score.create(
							{ account: mappedScore },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					});

					return defered.promise;
				};

				instance['delete'] = function (id) {
					return Score.delete({ id: id }).$promise;
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upResources').service('ParseOrder', [
	'AppService',
	'ParseHelpers',
	function (AppService, ParseHelpers) {
		return function (order) {
			if (order.orderRow) {
				var customFields = AppService.getReadOnlyCustomFields('orderrow');
				angular.forEach(order.orderRow, function (row) {
					if (!row.listPrice && row.price) {
						row.listPrice = row.price;
					}

					ParseHelpers.parseCustom(row, customFields);
				});
			}
			if (order.periodization && order.periodization.data) {
				Object.keys(order.periodization.data).forEach(key => {
					order.periodization.data[key].forEach(entry => {
						entry.value = entry.value * (order.currencyRate || 1);
						entry.purchaseCost = entry.purchaseCost * (order.currencyRate || 1);
					});
				});
			}

			return order;
		};
	}
]);

import logError from 'App/babel/helpers/logError';
import AccountExtra from 'App/babel/resources/AccountExtra';

angular.module('upListFilter.controllers').controller('listViewCtrl2', [
	'$q',
	'$upModal',
	'ListViewService',
	'FilterParser',
	'$injector',
	'RequestBuilder',
	'$stateParams',
	'NotificationService',
	'$translate',
	'$location',
	'$scope',
	'$timeout',
	'FilterService',
	'$multiSelect',
	'$upAdvancedSearch',
	'$rootScope',
	'FilterHelper',
	'AppService',
	'ListAttributes',
	'AccountCategoryType',
	'CustomfieldMapper',
	'$cookies',
	'$safeApply',
	function (
		$q,
		$upModal,
		ListViewService,
		FilterParser,
		$injector,
		RequestBuilder,
		$stateParams,
		NotificationService,
		$translate,
		$location,
		$scope,
		$timeout,
		FilterService,
		$multiSelect,
		$upAdvancedSearch,
		$rootScope,
		FilterHelper,
		AppService,
		ListAttributes,
		AccountCategoryType,
		CustomfieldMapper,
		$cookies,
		$safeApply
	) {
		var listView = this,
			originalFilters,
			customerId = $stateParams.customerId,
			listHelper,
			originalSorting,
			originalColumns;

		listView.saveAs = '';

		listView.limits = [
			{ key: '50', val: 50 },
			{ key: '100', val: 100 },
			{ key: '250', val: 250 }
		];

		listView.useCompact = false;

		listView.filters = {};

		listView.page = 1;

		listView.isSearch = false;
		listView.isDisabled = false;

		listView.loading = false;

		var refreshSharedViews = function () {
			var object = {
				canBeSaved: listHelper.listOpts.editable,
				hasChanged: listView.selectedView.$$dirty,
				entityName: listHelper.entityName,
				title: listHelper.title,
				saveView: listView.saveView,
				changeView: listView.changeView,
				selectedView: listView.selectedView,
				total: listView.total,
				setAsDefault: listView.setAsDefault,
				type: listHelper.listType,
				filters: listView.filters,
				isChangingView: listView.isChangingView,
				formatTotal: listHelper.formatTotal,
				isDisabled: listView.isDisabled
			};

			listView.sharedViews = object;

			$scope.$broadcast('renderSharedViews', object);
			$safeApply($scope);
		};

		listView.refreshSharedViews = refreshSharedViews;
		listView.hasSharedViews = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS);

		$scope.$on('listView.updateTableData', function (e, data) {
			listView.entries = data.data;
			listView.total = data.total || data.data.length;
			refreshSharedViews();
		});

		$scope.$on('ListViewIsUpdated', function () {
			listView.sharedViews.list = Tools.AppService.getListViews(listHelper.listType);
			refreshSharedViews();
		});

		$scope.$on('$multiSelect.toggle', function (e, data) {
			listView.listActionData.selected = data.selected.length;
			$safeApply($scope);
		});

		$scope.$on('$multiSelect.selectAll', function (e, data) {
			listView.listActionData.selected = data.selected.length;
			listView.listActionData.allSelected = false;
			$safeApply($scope);
		});

		$scope.$on('$multiSelect.selectAllVisible', function (e, data) {
			listView.listActionData.selected = data.selected.length;
			listView.listActionData.allSelected = false;
			$safeApply($scope);
		});

		$scope.$on('$multiSelect.selectAllFilter', function () {
			listView.listActionData.selected = listHelper.total || listView.total;
			listView.listActionData.allSelected = true;
			$safeApply($scope);
		});

		$scope.$on('$multiSelect.selectNone', function () {
			listView.listActionData.selected = 0;
			listView.listActionData.allSelected = false;
			$safeApply($scope);
		});

		/**
		 * [removeEntry description]
		 * @return {[type]} [description]
		 */
		listView.removeEntry = function (entry) {
			var promise = listHelper.removeEntry(entry);
			if (promise && promise.then) {
				promise
					.then(function () {
						_.pull(listView.entries, entry);

						// var index = _.findIndex(listView.entries, {id: entry.id});

						listView.total--;
						refreshSharedViews();
						// listView.entries.splice(index, 1);
					})
					.catch(e => {
						logError(e, 'Failed to remove entry');
					});
			}
		};

		listView.createEntry = function ($event) {
			var promise = listHelper.createEntry($event);
			if (promise && promise.then) {
				promise.then(refresh).catch(e => {
					logError(e, 'Failed to create entry');
				});
			}
		};

		listView.clickedEntry = function ($row, entries, $event) {
			if (
				$event &&
				($event.target.type === 'checkbox' || angular.element($event.target).hasClass('checkbox-column'))
			) {
				return false;
			}
			var promise = listHelper.clickedEntry($row, entries, $event);
			if (promise && promise.then) {
				promise.then(refresh).catch(e => {
					logError(e, 'Failed to click entry');
				});
			}
		};

		listView.editEntry = function ($row, entries, $event) {
			var promise = listHelper.editEntry($row, listView.entries, $event);
			if (promise && promise.then) {
				promise.then(refresh).catch(e => {
					logError(e, 'Failed to edit entry');
				});
			}
		};

		function refresh(e, opts) {
			opts = opts || {};
			var rq = FilterParser(listView.selectedView);
			var query = rq.build();
			rq.fields = [];
			query.f = [];

			if (opts.skipTimeout) {
				listView.getData();
			} else {
				$timeout(function () {
					listView.getData();
				}, opts.timeout || 2000);
			}

			refreshSharedViews();
		}

		listView.removeSelected = function (removedEntities = []) {
			listView.total -= removedEntities.length;
			listView.entries = _.filter(listView.entries, function (item) {
				return removedEntities.indexOf(item.id) === -1;
			});
			refreshSharedViews();
		};

		listView.openAdvanced = function () {
			var type = $upAdvancedSearch.searchTypes[listHelper.listType.toUpperCase()];
			if (type) {
				$upAdvancedSearch.setSearchType(type);
			}
			$upAdvancedSearch.setFilters(listView.filters).open();
		};

		listView.setLimit = function (limit) {
			// set cookie and limit variable
			listView.limit = limit;
			$cookies.listLimit = limit;
		};

		listView.runMultiAction = function (action) {
			const rb = listView.getRequestBuilder();
			const runGetData =
				$multiSelect.selected.length && $multiSelect.selected.length <= 10 && !$multiSelect.allSelectedFilter
					? true
					: false;

			const runner = action.run(listView, rb.build(), rb, listView.selectedView.columns);
			if (runner && runner.then) {
				// eslint-disable-next-line promise/catch-or-return
				runner.then(result => {
					if (runGetData) {
						// Re run getData but skip setting loading bu passing the second argument as true
						listView.getData(false, true);
					}
					return result;
				});
			}
			return runner;
		};

		/**
		 * This event listener reloads the list with the
		 * current filter configuration. Trigger the event to refresh.
		 */
		$scope.$on('listView.refresh', refresh);

		// delete filter
		$scope.$on('listView.filterRemoved', function (e, filterName) {
			if (listView.filters[filterName]) {
				delete listView.filters[filterName];
			}
		});

		/**
		 * responsible for:
		 *
		 * - assigning {listView.selectedView} with {view}
		 * - re-render / get entries
		 * - updates url parameters
		 *
		 * @param {object} view The view that should be assigned
		 */
		listView.setView = function (view, refresh) {
			// Current hash
			var hash = $location.search();
			var dirty = false;

			// Do not set if already selected or wait it can be reset yes
			if (!listView.isSearch && listView.selectedView && listView.selectedView.id === view.id && !refresh) return;

			// Check if this is initView and if it's dirty
			if (!listView.selectedView && !listView.isSearch && hash.q) {
				dirty = true;
			}

			// Set view
			listView.selectedView = view;
			// Now this is clean
			listView.selectedView.$$dirty = dirty;

			// Set limit
			listView.limit = listView.limits[0].val;

			listView.page = 1;

			// Set org view to use as reference when setting $dirty on the view
			originalFilters = FilterHelper.parseViewFilters(listView.selectedView.filters, listHelper.listType);
			originalSorting = listView.selectedView.sorting;
			originalColumns = angular.copy(listView.selectedView.columns);

			if (hash.columns) {
				listView.selectedView.columns = parseJson(hash.columns, originalColumns);
			}

			// Parse away customfields we cannot see
			view.columns = _.filter(view.columns, function (col) {
				if (FilterHelper.isCustom(col)) {
					var type = listHelper.listType === 'campaign' ? 'project' : listHelper.listType;
					var field = FilterHelper.getConfig(col, type).$field;

					if (!field) {
						return false;
					}
					return CustomfieldMapper.hasAccess(field);
				}

				return true;
			});

			// Set filters (triggers GET)
			if (hash.q) {
				// If we have q params in hash
				listView.filters = FilterHelper.parseFromURL(listHelper.listType);
			} else {
				// Get view filters
				listView.filters = angular.copy(originalFilters);

				// Set id on hash
				$location.search('id', view.id);
				$location.replace();
			}

			if (hash.fromGroupMail) {
				listView.isDisabled = true;
			}

			listView.isChangingView = false;

			if (listHelper.headerExtension && listHelper.headerExtension.onViewChange) {
				listHelper.headerExtension.onViewChange(view, refresh, listView.selectedView.columns);
			}

			refreshSharedViews();
		};

		listView.changeLimit = function (limit) {
			listView.limit = limit;
			$location.search('limit', limit);
		};

		listView.changePage = function (page) {
			listView.page = page;
			$location.search('p', page);
			// We call this from react code so we need to run apply here
			$safeApply($scope);
		};

		listView.changeView = function (view) {
			listView.isChangingView = true;
			// Reset hash
			var hash = {
				columns: null,
				q: null
			};
			$location.search(hash);

			// Set view
			listView.setView(view, true);
		};

		listView.getBuiltFilters = function () {
			return FilterHelper.parseFilters(listView.filters, listHelper.listType).build();
		};

		listView.getRequestBuilder = function () {
			return FilterHelper.parseFilters(listView.filters, listHelper.listType);
		};

		var requestId = 0;
		var getDataTimer = null;

		listView.getData = function (resetMultiSelect, isMultiUpdate) {
			if (getDataTimer) {
				clearTimeout(getDataTimer);
			}

			getDataTimer = setTimeout(function () {
				requestId = requestId + 1;
				var currentRequestId = requestId;

				$scope.$broadcast('renderFilters');

				if (!isMultiUpdate) {
					listView.loading = true;
					listView.entries = [];
				}

				// Parse filters
				var requestBuilder = FilterHelper.parseFilters(
					listView.filters,
					listHelper.listType,
					null,
					null,
					listHelper.filterOpts
				);

				requestBuilder.limit = listView.limit;
				requestBuilder.offset = listView.limit * (listView.page - 1);

				if (listView.selectedView) {
					requestBuilder.sorting = FilterHelper.parseSort(listView.selectedView.sorting, listHelper.listType);
				}

				// Experimental feature to fetch correct columns
				if (listHelper.onlyFetchCorrectFields) {
					requestBuilder.fields = listView.selectedView.columns;
				}

				// Get data
				var rb = requestBuilder.build();

				listHelper
					.getEntries(rb, requestBuilder)
					.then(function (res) {
						if (requestId === currentRequestId) {
							listView.entries = res.data;
							listView.total = res.metadata.total;
							refreshSharedViews();

							if (resetMultiSelect) {
								$multiSelect.selectNone();
							}

							$multiSelect.setTotal(res.metadata.total);
							$multiSelect.setVisible(res.data.length);

							if (!isMultiUpdate) {
								// Scroll to top
								angular.element(window).scrollTop(0);
								listView.loading = false;
							}

							$safeApply($scope);

							// eslint-disable-next-line no-undef
							setImmediate(() => {
								listView.getAccountExtra();
							});
						}
					})
					.catch(e => {
						logError(e, 'Failed to get entries');
						listView.loading = false;
					});
			}, 50);
		};

		let getAccountExtraTimer = null;
		listView.getAccountExtra = function () {
			if (getAccountExtraTimer) {
				clearTimeout(getAccountExtraTimer);
			}
			getAccountExtraTimer = setTimeout(() => {
				if (
					listHelper.listType !== 'account' ||
					!listView.selectedView?.columns?.length ||
					!listView.entries?.length
				) {
					return;
				}

				const accountExtraFields = listView.selectedView.columns.reduce((fields, column) => {
					if (column.includes('Account_Extra')) {
						const field = column.split('_')[2];
						fields.push(field);
					}
					return fields;
				}, []);

				if (!accountExtraFields.length) {
					return;
				}

				AccountExtra.get(
					accountExtraFields,
					listView.entries.map(r => r.id)
				)
					.then(accountExtra => {
						listView.entries = listView.entries.map((entry, index) => {
							return {
								...entry,
								accountExtra: {
									...accountExtra.data[index]
								}
							};
						});
						$safeApply($scope);
					})
					.catch(err => logError(err, 'Could not get account extra'));
			}, 50);
		};

		listView.removeFilter = function (filterName) {
			if (listView.filters[filterName]) {
				delete listView.filters[filterName];
			}
		};

		listView.addFilter = function (filter) {
			// Find out why this is empty sumtimes
			if (!listView.filters[filter.filterName]) {
				listView.filters[filter.filterName] = filter;
			}

			listView.filters[filter.filterName].value = filter.value;
			listView.filters[filter.filterName].inactive = filter.inactive;

			if (filter.comparisonType) {
				listView.filters[filter.filterName].comparisonType = filter.comparisonType;
			}
		};

		listView.updateFilter = function (filterConfig, options) {
			if (options.action === 'remove') {
				listView.removeFilter(filterConfig.filterName);
			} else {
				//if (filterConfig.inactive === true) filterConfig.inactive = false;
				listView.addFilter(filterConfig);
			}

			refreshSharedViews();
			$safeApply($scope);
		};

		listView.clearFilters = function () {
			listView.filters = {};

			$safeApply($scope);
		};

		/**
		 * Deletes a view from the list after the user
		 * has comfimed that it should be deleted
		 *
		 * @param  {Object} view The object that should be deleted
		 */
		listView.deleteView = function (view) {
			// create confirmation dialog
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.create({
					templateUrl: require('App/upsales/common/components/ui/modals/templates/removeViewConfirm.tpl.html?file'),
					style: 'warning'
				})
				.then(function () {
					// remove view from list
					_.pull(listView.availableViews.private, view);

					// If we delete the current one...
					if (listView.selectedView.id === view.id) {
						listView.setView(findViewByIdentity());
					}

					// remove from server
					ListViewService['delete'](listHelper.listType, view, customerId);
				});
		};

		/**
		 * resets {listView.selectedView} to when it was last saved
		 */
		listView.resetView = function () {
			// Set view
			listView.changeView(findViewByIdentity({ id: listView.selectedView.id }));
		};

		/**
		 * resets {listView.myView} to when it was last saved
		 */
		// listView.resetView = function() {
		// 	listView.myView = findViewByIdentity({id: listView.myView.id});
		// 	$location.search({'id': listView.myView.id});
		// };

		// Set view as default
		listView.setAsDefault = function (view) {
			// Toggle this view
			if (view.default) {
				view.default = false;
				ListViewService.save(listHelper.listType, view, customerId);
			} else {
				view.default = true;

				// If we just set our new default view we need to unset the old one
				listView.availableViews.private.forEach(function (oldView) {
					if (oldView.id !== view.id && oldView.default) {
						oldView.default = false;
						ListViewService.save(listHelper.listType, oldView, customerId);
					}
				});
			}
			ListViewService.save(listHelper.listType, view, customerId);
		};

		/**
		 * Saves the current modifed view and flags that its beeing saved
		 * then it will updatet the {listHelper.listViews}
		 */
		listView.saveView = function (viewToSave) {
			// Prompt for name if this is a new view (saveAs)
			if (!viewToSave.private) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('infoPrompt', {
						title: 'filters.newView',
						placeholder: 'filters.viewName',
						maxLength: 100,
						yes: 'filters.saveView',
						icon: 'fa-plus'
					})
					.then(function (viewTitle) {
						// Save view
						listView.saveViewAs(viewTitle);
					});
				return;
			} else {
				viewToSave = null;
			}
			var saveThis = viewToSave || listView.selectedView;
			var tmpView = angular.copy(saveThis);
			tmpView.filters = [];
			angular.forEach(viewToSave ? viewToSave.filters : listView.filters, function (filter) {
				tmpView.filters.push(filter);
			});

			delete tmpView.p;
			delete tmpView.offset;

			if (!viewToSave) {
				listView.selectedView.$$dirty = false;
				originalFilters = listView.filters;
				originalSorting = listView.selectedView.sorting;
				originalColumns = angular.copy(listView.selectedView.columns);
				listView.selectedView.$$saving = true;
			}

			ListViewService.save(listHelper.listType, tmpView, customerId)
				.then(function (view) {
					if (!viewToSave) {
						listView.selectedView.$$saving = false;
					}

					if (!viewToSave) {
						$location.search({ id: view.id });
					}

					listView.listViews = AppService.getListViews(listHelper.listType);
				})
				.catch(e => {
					logError(e, 'Failed to save list view');
					listView.selectedView.$$saving = false;
				});
		};

		listView.editView = function (view) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editListView', {
					listType: view.type,
					view: view,
					customerId: customerId
				})
				.then(function (updatedView) {
					angular.extend(view, updatedView);

					if (listView.selectedView.id === view.id) {
						listView.selectedView = updatedView;
					}
				});
		};

		listView.filterLength = function () {
			return Object.keys(listView.filters).length;
		};

		/**
		 * Saves the current modified view as a new one
		 *
		 * @param  {name String} the name we are going to save it as
		 */
		listView.saveViewAs = function (name) {
			// bail if no name
			if (!name) {
				return;
			}

			// close list
			$('#view-select-wrap').removeClass('open');

			var tmpView = angular.copy(listView.selectedView);
			tmpView.filters = [];
			angular.forEach(listView.filters, function (filter) {
				tmpView.filters.push(filter);
			});

			listView.selectedView.$$saving = true;
			listView.selectedView.$$dirty = false;
			originalFilters = listView.filters;
			originalSorting = listView.selectedView.sorting;
			originalColumns = angular.copy(listView.selectedView.columns);

			// reset field
			listView.saveAs = '';

			tmpView.id = 0;
			tmpView.default = false;
			tmpView.private = true;
			tmpView.editable = true;
			tmpView.title = name;
			delete tmpView.p;
			delete tmpView.offset;

			listView.availableViews.private = listView.availableViews.private || [];

			// push into list
			listView.availableViews.private.push(tmpView);

			// set it to selected
			listView.selectedView = tmpView;

			// save the view
			ListViewService.save(listHelper.listType, tmpView, customerId)
				.then(function (view) {
					listView.selectedView.$$saving = false;

					// set the id of the created view
					tmpView.id = view.id;

					listView.listViews = AppService.getListViews(listHelper.listType);

					// set url hash
					$location.search({ id: view.id });
				})
				.catch(e => {
					logError(e, 'Failed to save list view');
					listView.selectedView.$$saving = false;
					listView.availableViews.private.pop();
				});
		};

		/**
		 * Finds a view from the unmodified {listHelper.listViews}
		 *
		 * @param  {Function|Object|string} identity The function called per iteration.
		 *                                           If a property name or object is provided it
		 *                                           will be used to create a ".pluck" or ".where"
		 *                                           style callback, respectively.
		 * @return {[type]}          a cloned view from {listHelper.listViews} that can be modifed
		 */
		function findViewByIdentity(identity) {
			var availableViews = listView.listViews;

			if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
				availableViews = Tools.AppService.getListViews(listHelper.listType);
			}

			var tmpView;

			if (identity) {
				tmpView = _.find(availableViews, identity);
			}
			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true, private: true });
			}
			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true, editable: true });
			}
			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true });
			}
			if (!tmpView) {
				tmpView = availableViews[0];
			}
			return angular.copy(tmpView);
		}

		function triggerdByExclude(hash, oldHash) {
			if (!hash || !hash.q || !oldHash || !oldHash.q) {
				return false;
			}

			var newFilters = FilterHelper.parseString(hash.q, listHelper.listType);
			var oldFilters = FilterHelper.parseString(oldHash.q, listHelper.listType);

			var newComopareFilters = getCompareFilters(_.omit(newFilters, ['ExcludeId']));
			var oldCompareFilters = getCompareFilters(_.omit(oldFilters, ['ExcludeId']));

			return _.isEqual(newComopareFilters, oldCompareFilters);
		}

		// Triggered on hash change
		function updateView(hash, oldHash) {
			var newView = null;

			if (hash.search) {
				// FOR ADVANCED SEARCH

				listView.filters = FilterHelper.parseFromURL(listHelper.listType);

				// Search view
				newView = ListViewService.getSearchView(
					$upAdvancedSearch.searchTypes[listHelper.listType.toUpperCase()],
					listView.filters
				);
			} else {
				// Check if the view has changed or if we dont have one
				if (
					!listView.selectedView || // We don't have a view set
					(oldHash && oldHash.id !== hash.id) || // The view did change
					(oldHash && oldHash.q && hash.q && !hash.q.length) // We had some filters in the URL but now we are back on the org view
				) {
					var id = ~~hash.id || hash.id;
					var identity = id ? { id: id } : null;

					newView = findViewByIdentity(identity) || listView.listViews[0];

					$multiSelect.selectNone();
				}
			}

			// Is search or not
			listView.isSearch = !!hash.search;

			// Has search btn
			listView.noSearchBtn = !!hash.nobtn;

			// Set new view if we have one
			if (newView) {
				listView.setView(newView, true);
			}

			// If the view dit not change but some filters did
			if (!newView) {
				if (hash.q && !listView.isSearch) {
					// Get the filters from the url (trigger get)
					listView.filters = FilterHelper.parseFromURL(listHelper.listType);
				}
				if (hash.columns) {
					listView.selectedView.columns = parseJson(hash.columns, originalColumns);
				}
			}

			if (
				oldHash &&
				(hash.sorting !== oldHash.sorting || (hash.q !== oldHash.q && !triggerdByExclude(hash, oldHash)))
			) {
				listView.page = 1;
			} else {
				listView.page = hash.p || 1;
			}

			listView.limit = hash.limit && !isNaN(parseInt(hash.limit)) ? parseInt(hash.limit) : listView.limits[0].val;

			if (hash.sorting) {
				listView.selectedView.sorting = parseJson(hash.sorting, originalSorting);
			}
		}

		// Get filterArray to compare for the dirty variable
		function getCompareFilters(filters, sorting, columns) {
			var strippedFilters = {};

			angular.forEach(filters, function (filter) {
				if (!filter.inactive) {
					var config;
					if (FilterHelper.isCustom(filter.filterName)) {
						config = FilterHelper.getConfig(filter.filterName, listHelper.listType);
					} else if (FilterHelper.isCategory(filter.filterName)) {
						config = listView.standardFilters.Category;
					} else {
						config = listView.standardFilters[filter.filterName];
					}
					var filterObject;
					if (config.type === 'dateRange') {
						filterObject = { value: {} };
						filterObject.value.preset = filter.value.preset;

						if (filterObject.value.preset === 'custom') {
							filterObject.value.start = filter.value.start;
							filterObject.value.end = filter.value.end;
						}
						if (filter.value.status) {
							filterObject.value.status = filter.value.status;
						}
					} else {
						filterObject = { comparisonType: filter.comparisonType, value: filter.value };
					}
					strippedFilters['FILTER_' + filter.filterName] = filterObject;
				}
			});

			strippedFilters.SORTING = sorting;

			strippedFilters.LENGTH = Object.keys(filters).length;

			strippedFilters.COLUMNS = columns;

			return strippedFilters;
		}

		function parseJson(data, defaultValue = null) {
			let result = defaultValue;
			if (data) {
				try {
					result = JSON.parse(data);
				} catch (err) {
					console.error('Failed to parse JSON', err);
				}
			}

			return result;
		}

		/**
		 * [init description]
		 * @param  {[type]} listViewHelper [description]
		 * @return {[type]}                [description]
		 */
		listView.init = function (listViewHelper) {
			listHelper = listViewHelper;

			listHelper.getFilters = function () {
				return listView.filters;
			};

			listView.listActionData = {
				selected: 0,
				actions: listHelper.multiActions,
				runAction: listView.runMultiAction,
				allSelected: false,
				selectNone: function () {
					$multiSelect.selectNone();
				}
			};

			$multiSelect.selectNone();

			// StandardFilters
			listView.standardFilters = ListViewService.getStandardFilters(listHelper.listType);

			if (listHelper.headerExtension) {
				listHelper.getReference(listView);
			}

			// Set up listView array
			if (listHelper.listViews) {
				listView.listViews = listHelper.listViews;
			} else {
				listView.listViews = AppService.getListViews(listHelper.listType);
			}

			// Related entities
			listView.relatedEntities = ListViewService.getRelatedEntities(listHelper.listType);

			// Filter service instance for all the filters
			listView.filterService = new FilterService(
				customerId,
				listView.standardFilters,
				listHelper.customFields,
				listView.relatedEntities
			);

			if (listHelper.filterMap && typeof listHelper.filterMap === 'function') {
				_.each(listView.filterService.filterConfigs, listHelper.filterMap);
			}

			// Set up multiActions or nah
			if (listViewHelper.useMultiActions) {
				listView.$multiSelect = $multiSelect;
			}

			// Set custom limit choices
			if (listHelper.limitArray) {
				listView.limits = listHelper.limitArray;
			}

			// Group views
			listView.availableViews = _.groupBy(listView.listViews, function (view) {
				return view.private ? 'private' : 'public';
			});

			// On hash change
			// Run the update view function to set all props
			var ogPath = null;
			$scope.$watch(
				function () {
					return {
						hash: $location.search(),
						path: $location.path()
					};
				},
				function (location, oldLocation) {
					var init = false;
					// Only trigger change if we still are on the same page
					if (!ogPath) {
						ogPath = location.path;
						init = true;
					}
					if (location.path === ogPath) {
						updateView(location.hash, init ? null : oldLocation.hash);
					}
				},
				true
			);

			listView.getData();
			listView.sharedViews = {
				list: [],
				total: 0,
				entityName: '',
				title: '',
				canBeSaved: false,
				hasChanged: null
			};

			// Watch for filter and prop changes
			$scope.$watch(
				function () {
					// IF {listView.isSearch}
					// Search can never be dirty, it´s so fresh and so clean, clean!
					// OR {!originalFilters}
					// We cannot compare nothing can we now
					if (listView.isSearch || !originalFilters) {
						listView.selectedView.$$dirty = false;
					} else {
						// Get compare filters
						var org = getCompareFilters(originalFilters, originalSorting, originalColumns);
						var current = getCompareFilters(
							listView.filters,
							listView.selectedView.sorting,
							listView.selectedView.columns
						);

						// Set dirty var on view
						listView.selectedView.$$dirty = !_.isEqual(current, org);
					}

					return {
						filters: listView.filters,
						limit: listView.limit,
						page: listView.page,
						sorting: listView.selectedView ? listView.selectedView.sorting : [],
						columns: listView.selectedView ? listView.selectedView.columns : []
					};
				},
				function (newValue, oldValue) {
					if (ogPath && $location.path() !== ogPath) {
						return;
					}

					// update hash
					if (listView.isSearch) {
						// THIS IS FOR WHEN WE HAVE ADVANCED SEARCH
						$location.search('q', FilterHelper.convertForURL(listView.filters, listHelper.listType));
					}

					// If any view is set
					if (listView.selectedView) {
						// Save snapshot of current settings in the url
						// Update the hash if the view is dirty and this is not a search
						if (listView.selectedView.$$dirty && !listView.isSearch) {
							$location.search('id', listView.selectedView.id);
							$location.search('columns', JSON.stringify(listView.selectedView.columns));
							$location.search('q', FilterHelper.convertForURL(listView.filters, listHelper.listType));
							$location.search('sorting', JSON.stringify(listView.selectedView.sorting));
							$location.search('limit', listView.limit);
							$location.search('p', listView.page);
						} else if (!listView.isSearch) {
							$location.search('id', listView.selectedView.id);
							$location.search('limit', listView.limit);
							$location.search('p', listView.page);
							$location.search('columns', null);
							$location.search('q', null);
							$location.search('sorting', null);
						}
					}

					var resetMultiSelect = !(
						_.isEqual(newValue.filters, oldValue.filters) && _.isEqual(newValue.sorting, oldValue.sorting)
					);

					var oldQS = FilterHelper.convertForURL(oldValue.filters, listHelper.listType, {
						skipInactive: true
					});
					var newQS = FilterHelper.convertForURL(newValue.filters, listHelper.listType, {
						skipInactive: true
					});

					var shallFetchData = !(
						_.isEqual(newValue.limit, oldValue.limit) &&
						_.isEqual(newValue.sorting, oldValue.sorting) &&
						newValue.page === oldValue.page &&
						oldQS === newQS
					);

					// Experimental feature to only fetch correct fields according to the columns in the view
					if (
						listHelper.onlyFetchCorrectFields &&
						!shallFetchData &&
						!_.isEqual(newValue.columns, oldValue.columns)
					) {
						shallFetchData = true;
					}

					if (shallFetchData) {
						listView.getData(resetMultiSelect);
					} else {
						$scope.$broadcast('renderTable');
					}

					refreshSharedViews();
				},
				true
			); // Watch deep
		};
	}
]);

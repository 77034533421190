import getAngularModule, { available } from 'App/babel/angularHelpers/getAngularModule';
import AppService from 'App/legacy/AppService';
import { getCategoryConfigsFromState } from './CategoryFilterConfig';

/** @deprecated replaced with useCategoryConfigs instead */
const getCategoryConfigs = function (type, prefix, overrides, defaultCategoryTitle) {
	const appService = available('AppService') ? getAngularModule('AppService') : AppService;

	if (!appService.loaded) {
		return {};
	}

	const isUDO = type && type.indexOf('userDefinedObject') !== -1;
	const skipAccess = isUDO || false;
	const fixedType = isUDO ? type.replace('Object', '') : type;

	return getCategoryConfigsFromState(
		appService.getCategoryTypes(fixedType),
		appService.getCategories(fixedType, skipAccess),
		type,
		prefix,
		overrides,
		defaultCategoryTitle
	);
};

export default getCategoryConfigs;

'use strict';

angular.module('upDirectives').directive('upMaskValue', [
	'$filter',
	function ($filter) {
		return {
			require: 'ngModel',
			link: function ($scope, $element, $attrs, $ctrl) {
				// Fix init format on existing value
				var listener = $scope.$watch($attrs.ngModel, function (value) {
					if (value !== null && value !== undefined) {
						$element.val($filter('number')(value));

						// Clear watch
						listener();
					}
				});

				// Model -> View
				$ctrl.$formatters.unshift(function () {
					return $filter('number')($ctrl.$modelValue);
				});

				// View -> Model
				$ctrl.$parsers.unshift(function (viewValue) {
					if (viewValue === null) {
						return viewValue;
					}
					if (viewValue === '' || viewValue === undefined) {
						return null;
					}
					var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
					plainNumber = plainNumber || 0;
					$element.val($filter('number')(plainNumber));
					return parseInt(plainNumber);
				});
			}
		};
	}
]);

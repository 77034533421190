'use strict';
import { Row, Column, Tooltip, Button, Icon, Text, Block } from '@upsales/components';

ReactTemplates.voice.dialing = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			dialing: t('voice.dialing'),
			calling: t('voice.calling'),
			hungUp: t('voice.hungUp'),
			showMore: t('voice.showMaximized')
		};
	},

	render: function () {
		var self = this;
		var callerId = this.props.callerId;

		if (!this.props.number) {
			return null;
		}
		return (
			<div>
				<div id="caller-id">
					{this.props.minimized ? (
						<Row className="dial-info">
							<Column className="caller-name clickable" size={6} onClick={self.props.toggleWindow.bind()}>
								<Tooltip title={this.lang.showMore} position="top">
									<Block space="ptl plxl">
										{callerId ? (
											<Text bold size="md" color="grey-10">
												{callerId.contact ? callerId.contact.name : callerId.client.name}
											</Text>
										) : null}
										<Text size="sm" color="grey-10">
											{this.lang.calling}
										</Text>
									</Block>
								</Tooltip>
							</Column>
							<Column className="caller-options" size={6} align="center">
								<Block space="ptl">
									<Button color="red" onClick={self.props.hangUp.bind(this, true, false)}>
										<Text bold color="white">
											<Icon name="phone-fail" className="hangup-icon" /> {this.lang.hungUp}
										</Text>
									</Button>
								</Block>
							</Column>
						</Row>
					) : (
						<React.Fragment>
							{callerId ? (
								<React.Fragment>
									<h2 className="clickable" onClick={self.props.doAction.bind(self, 'goTo')}>
										{self.lang.dialing}{' '}
										{callerId.contact ? callerId.contact.name : callerId.client.name}
									</h2>
									{callerId.contact && callerId.client ? (
										<div
											className="subtitle clickable"
											onClick={self.props.doAction.bind(self, 'goToAccount')}
										>
											{callerId.client.name}
										</div>
									) : null}
								</React.Fragment>
							) : null}

							<div className="number-row">
								<b className="fa fa-volume-control-phone"></b> {self.props.number}
							</div>

							<button
								type="button"
								className="phone-btn"
								onClick={self.props.hangUp.bind(this, true, false)}
							>
								<b className="fa fa-phone"></b>
							</button>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
});

'use strict';

angular.module('upResources').factory('StandardField', [
	'$resource',
	'URL',
	'API',
	'$q',
	'ResourceHelper',
	'ParseGeneric',
	function Contact($resource, URL, API, $q, ResourceHelper, ParseGeneric) {
		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'standardField',
			updateSuccessBody: 'updated.standardField',
			updateErrorBody: 'saveError.standardField'
		});

		var Model = {
			type: null,
			allTypes: {
				CLIENT: 'Client',
				CONTACT: 'Contact',
				ACTIVITY: 'Activity',
				APPOINTMENT: 'Appointment',
				ORDER: 'Order',
				AGREEMENT: 'Agreement'
			}
		};

		var StandardField = $resource(
			URL + API + 'standardField/:type/:id',
			{},
			{
				update: {
					method: 'PUT'
				},
				list: {
					method: 'GET',
					transformResponse: ParseGeneric('ParseStandardField', { isArray: false })
				},
				get: {
					method: 'GET',
					transformResponse: ParseGeneric('ParseStandardField', { isArray: false })
				}
			}
		);

		Model.list = function (filter, options) {
			var params = _.assign({}, filter, options, { type: this.type });

			return StandardField.list(params).$promise;
		};

		Model.get = function (id) {
			return StandardField.get({ id: id, type: Model.type }).$promise;
		};

		Model.save = function (field, options) {
			var defered = $q.defer();

			StandardField.update(
				{ id: field.id },
				field,
				function (response) {
					if (response.error) {
						defered.reject(response);
					}
					defered.resolve(response);
				},
				defered.reject
			);

			defered.promise
				.then(function (res) {
					helper.onSave(options, res);
				})
				.catch(function () {
					helper.onSaveError(options);
				});

			return defered.promise;
		};

		Model.setType = function (type) {
			Model.type = type;
			return Model;
		};

		return Model;
	}
]);

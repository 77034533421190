import React from 'react';
import PropTypes from 'prop-types';

type Props = {
	id: number;
	title: string;
	onClick?: (id: number) => void;
};

const AdminOptOutRow = (props: Props) => {
	const className = [];

	if (props.onClick) {
		className.push('clickable');
	}
	return (
		<tr className={className.join(' ')} onClick={props.onClick ? props.onClick.bind(null, props.id) : undefined}>
			<td>{props.title}</td>
			<td />
		</tr>
	);
};

AdminOptOutRow.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default AdminOptOutRow;

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('StandardIntegration', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'ResourceHelper',
	'NotificationService',
	'ParseGeneric',
	function ($resource, $q, URL, API, GenericMapper, ResourceHelper, NotificationService, ParseGeneric) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'standardIntegration',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings'
		});

		var helperUserConfig = new ResourceHelper();

		// Set entity-specific defaults here
		helperUserConfig.setDefaults({
			eventPrefix: 'standardIntegrationUser',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings'
		});

		var transformRequest = function (data, getHeaders) {
			var headers = getHeaders();
			if (!data.file) {
				headers['Content-Type'] = 'application/json';
				return JSON.stringify(data);
			}

			delete headers['Content-Type'];

			var formData = new FormData();

			var json = JSON.stringify(_.omit(data, ['file']));
			formData.append('data', json);
			formData.append('file', data.file);

			return formData;
		};

		var Resource = $resource(
			URL + API + 'standardIntegration/:id',
			{},
			{
				query: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				create: {
					method: 'POST',
					isArray: false,
					headers: { 'Content-Type': undefined },
					transformResponse: ParseGeneric(),
					transformRequest: transformRequest
				},
				update: {
					method: 'PUT',
					isArray: false,
					headers: { 'Content-Type': undefined },
					transformResponse: ParseGeneric(),
					transformRequest: transformRequest
				}
			}
		);

		var ErrorNotification = function (title, body) {
			NotificationService.addNotification({
				title: title,
				body: body,
				style: 'ERROR',
				icon: 'times'
			});
		};

		var Model = {
			new: function () {
				return {
					name: '',
					appColor: '#ffffff',
					standardIntegrationInit: [],
					standardIntegrationTag: [],
					userOnly: false,
					configJson: JSON.stringify(
						{
							fields: { account: [], user: [] },
							uiElements: {},
							requirements: []
						},
						null,
						4
					),
					category: []
				};
			},
			data: function (customer) {
				var instance = {};

				var IntegrationTesting = $resource(
					URL + API + customer + '/function/standardIntegrationData/',
					{},
					{
						create: {
							method: 'POST',
							isArray: false,
							transformRequest: function (payload) {
								return angular.toJson(payload);
							}
						}
					}
				);
				instance.test = function (obj) {
					obj.type = 'test';
					return IntegrationTesting.create(obj).$promise;
				};
				instance.values = function (obj) {
					obj.type = 'values';
					return IntegrationTesting.create(obj).$promise;
				};
				instance.validateField = function (obj) {
					obj.type = 'validateField';
					return IntegrationTesting.create(obj).$promise;
				};
				instance.oauth = function (obj) {
					obj.type = 'oauth';
					return IntegrationTesting.create(obj).$promise;
				};
				instance.run = function (obj) {
					return IntegrationTesting.create(obj).$promise;
				};
				return instance;
			},
			log: function (customer) {
				var instance = {};

				var IntegrationLog = $resource(
					URL + API + customer + '/integrationLog/',
					{},
					{
						query: { method: 'GET', isArray: true }
					}
				);
				instance.get = function (id) {
					return IntegrationLog.get({ id: id }).$promise;
				};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return IntegrationLog.get(params).$promise;
				};
				return instance;
			},
			setting: function (customer) {
				var instance = {};

				var IntegrationSetting = $resource(
					URL + API + customer + '/standardIntegrationSettings/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false },
						testConfig: {
							method: 'POST',
							isArray: false,
							url: URL + API + customer + '/standardIntegrationSettings/test'
						},
						getExternalConfig: {
							method: 'POST',
							isArray: false,
							url: URL + API + customer + '/standardIntegrationSettings/integrationConfig'
						}
					}
				);

				instance.get = function (id) {
					return IntegrationSetting.get({ id: id }).$promise;
				};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return IntegrationSetting.get(params).$promise;
				};

				instance.save = function (obj, options) {
					var promise;
					if (!obj.id) {
						promise = IntegrationSetting.create(obj).$promise;
					}
					promise = IntegrationSetting.update({ id: obj.id }, obj).$promise;

					promise.catch(function (err) {
						helper.onSaveError(options, false, err);
						return err;
					});

					return promise.then(function (res) {
						helper.onSave(options, res.data, false);
						AllIWantDataCache.clearData();
						return res;
					});
				};

				instance['delete'] = function () {
					if (console) {
						console.warn('missing this feature');
					}
				};

				instance.testConfig = function (config) {
					var defer = $q.defer();

					IntegrationSetting.testConfig(config)
						.$promise.then(defer.resolve)
						.catch(function (error) {
							ErrorNotification(
								'integration.configErrorTitle',
								error.data?.error?.standardIntegrationError ?? 'integration.configErrorBody'
							);
							return defer.reject(error);
						});

					return defer.promise;
				};

				instance.getExternalConfig = function (integrationId) {
					var defer = $q.defer();

					IntegrationSetting.getExternalConfig({ integrationId: integrationId })
						.$promise.then(defer.resolve)
						.catch(function () {
							ErrorNotification('integration.configErrorTitle', 'integration.configErrorBody');
							return defer.reject();
						});

					return defer.promise;
				};

				return instance;
			},
			userSetting: function (customer) {
				var instance = {};

				var IntegrationSetting = $resource(
					URL + API + customer + '/standardIntegrationUserSettings/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false },
						testConfig: {
							method: 'POST',
							isArray: false,
							url: URL + API + customer + '/standardIntegrationUserSettings/test'
						},
						getExternalConfig: {
							method: 'POST',
							isArray: false,
							url: URL + API + customer + '/standardIntegrationUserSettings/integrationConfig'
						}
					}
				);

				instance.get = function (id) {
					return IntegrationSetting.get({ id: id }).$promise;
				};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return IntegrationSetting.get(params).$promise;
				};

				instance.save = function (obj, options) {
					var promise;
					options = options || {};
					if (!obj.id) {
						promise = IntegrationSetting.create(obj).$promise;
					}
					promise = IntegrationSetting.update({ id: obj.id }, obj).$promise;

					promise.catch(function (err) {
						helperUserConfig.onSaveError(options, false, err);
						return err;
					});

					return promise.then(function (res) {
						helperUserConfig.onSave(
							options,
							{ userSettings: res.data, masterIntegration: options.masterIntegration },
							false
						);
						AllIWantDataCache.clearData();
						return res;
					});
				};

				instance['delete'] = function () {
					if (console) {
						console.warn('missing this feature');
					}
				};

				instance.testConfig = function (config) {
					var defer = $q.defer();

					IntegrationSetting.testConfig(config)
						.$promise.then(defer.resolve)
						.catch(function () {
							ErrorNotification('integration.configErrorTitle', 'integration.configErrorBody');
							return defer.reject();
						});

					return defer.promise;
				};

				instance.getExternalConfig = function (integrationId) {
					var defer = $q.defer();

					IntegrationSetting.getExternalConfig({ integrationId: integrationId })
						.$promise.then(defer.resolve)
						.catch(function () {
							ErrorNotification('integration.configErrorTitle', 'integration.configErrorBody');
							return defer.reject();
						});

					return defer.promise;
				};

				return instance;
			},

			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);
				return Resource.get(params).$promise;
			},

			save: function (obj) {
				AllIWantDataCache.clearData();
				if (!obj.id) {
					return Resource.create({ upload: !!obj.file }, obj).$promise;
				}
				return Resource.update({ id: obj.id, upload: !!obj.file }, obj).$promise;
			},

			delete: function () {
				if (console) {
					console.warn('missing this feature');
				}
			}
		};
		return Model;
	}
]);

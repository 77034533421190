'use strict';

angular.module('upResources').factory('Lead', [
	'$resource',
	'$q',
	'URL',
	'API',
	'LeadAttributes',
	'GenericMapper',
	'ResourceHelper',
	'ParseGeneric',
	function Lead($resource, $q, URL, API, LeadAttributes, GenericMapper, ResourceHelper, ParseGeneric) {
		var Attributes = LeadAttributes().attr;
		var Keys = LeadAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'lead',
			createSuccessBody: 'saved.lead',
			updateSuccessBody: 'updated.lead',
			deleteSucccessBody: 'deleted.lead',
			createErrorBody: 'saveError.lead',
			updateErrorBody: 'saveError.lead',
			deleteErrorBody: 'deleteError.lead'
		});

		var Model = {
			new: function () {
				var lead = {
					name: null,
					client: {},
					contact: null,
					addresses: null,
					phone: null,
					email: null,
					source: {},
					webpage: null,
					state: null,
					zipcode: null,
					notes: null,
					project: {},
					custom: [],
					user: {}
				};

				var parsed = ParseGeneric(null, { isArray: false, custom: 'lead', isNew: true })({ data: lead }, null);

				return parsed.data;
			},
			customer: function (customer) {
				var instance = {};

				var Lead = $resource(
					URL + API + customer + '/leads/:id',
					{},
					{
						get: {
							method: 'GET',
							transformResponse: ParseGeneric(null, { isArray: false, custom: 'lead' })
						},
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.find = function (filter) {
					return Lead.get(filter).$promise;
				};

				instance.get = function (id) {
					return Lead.get({ id: id }).$promise;
				};

				instance.save = function (lead, options) {
					var defered = $q.defer();

					if (!lead) {
						return defered.reject('No lead');
					}

					GenericMapper.map(lead, Attributes, function (err, mappedLead) {
						if (err) {
							return defered.reject('lead mapping error');
						}

						if (lead.id) {
							return Lead.update({ id: lead.id }, { lead: mappedLead }, defered.resolve, defered.reject);
						}

						Lead.create({ lead: mappedLead }, defered.resolve, defered.reject);
					});

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !lead.id);
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !lead.id);
						});

					return defered.promise;
				};

				instance['delete'] = function (lead, options) {
					return Lead.delete({ id: lead.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, lead);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},
			attr: Attributes,
			keys: Keys,
			requiredFields: LeadAttributes().requiredFields
		};

		return Model;
	}
]);

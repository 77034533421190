'use strict';

ReactTemplates.voice = {};
ReactTemplates.voice.root = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			maximize: t('voice.maximize'),
			minimize: t('voice.minimize')
		};
	},

	getStateFromProps: function (props) {
		return {
			viewState: props.rootData.state
		};
	},

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState(this.getStateFromProps(nextProps));
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	answered: function (newState) {
		this.setState({
			viewState: newState
		});
	},

	getClass: function (rootData) {
		var classnames = '';

		if (rootData.open) {
			classnames += 'open';
		}

		if (rootData.minimized) {
			classnames += ' minimized';
		}

		return classnames;
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;

		var rootClassNames = this.getClass(rootData);
		var viewClass = 'voice-view';
		var visibleViewClass = 'voice-view view-visible';

		var deviceStyle = {};
		var toolbarClasses = null;

		if (self.state.viewState === 'inCall') {
			toolbarClasses = 'light';
		}

		var dialingView = React.createElement(ReactTemplates.voice.dialing, {
			callerId: rootData.callerId,
			number: rootData.number,
			hangUp: rootData.hangUp,
			doAction: rootData.doAction,
			tools: tools,
			minimized: rootData.minimized,
			toggleWindow: rootData.toggleMinimize
		});

		var inCallView = React.createElement(ReactTemplates.voice.inCall, {
			caller: rootData.callerId,
			number: rootData.number,
			callStart: rootData.callStart,
			hangUp: rootData.hangUp,
			doAction: rootData.doAction,
			tools: tools,
			minimized: rootData.minimized,
			toggleWindow: rootData.toggleMinimize
		});

		var followupView = React.createElement(ReactTemplates.voice.followup, {
			callerId: rootData.callerId,
			reDial: rootData.reDial.bind(this, rootData.related),
			doAction: rootData.doAction,
			showCallList: rootData.showCallList,
			number: rootData.number,
			isCallTo: rootData.isCallTo,
			tools: tools,
			minimized: rootData.minimized
		});

		var closeMinimize = null;
		var minimize = null;
		if (self.state.viewState === 'followup') {
			closeMinimize = (
				<button id="voice-close" className="pull-right" type="button" onClick={rootData.onClose}>
					<b className="fa fa-times"></b>
				</button>
			);

			minimize = (
				<button id="voice-minimize" type="button" onClick={rootData.toggleMinimize}>
					{rootData.minimized ? <b className="fa fa-expand"></b> : <b className="fa fa-compress"></b>}
				</button>
			);

			if (rootData.minimized) {
				visibleViewClass += ' minimized';
			}
		} else if (self.state.viewState === 'inCall' || self.state.viewState === 'dialing') {
			closeMinimize = (
				<button
					id="voice-minimize"
					className="pull-right with-text"
					type="button"
					onClick={rootData.toggleMinimize}
				>
					{rootData.minimized ? (
						<b className="fa fa-expand">
							<p className="minimize-text">{this.lang.maximize}</p>
						</b>
					) : (
						<span>
							<b className="fa fa-expand"></b>
							<p className="minimize-text">{this.lang.minimize}</p>
						</span>
					)}
				</button>
			);
			visibleViewClass += ' empty-top';
		}

		return (
			<div id="voice-wrap" ref={self.setRef.bind(self, '_root')} className={rootClassNames}>
				<div id="voice-device-wrapper" style={deviceStyle}>
					{self.state.viewState === 'followup' || !rootData.minimized ? (
						<div id="voice-tools" className={toolbarClasses}>
							{minimize}
							{closeMinimize}
						</div>
					) : null}
					<div id="dialing" className={self.state.viewState === 'dialing' ? visibleViewClass : viewClass}>
						{dialingView}
					</div>

					<div id="in-call" className={self.state.viewState === 'inCall' ? visibleViewClass : viewClass}>
						{inCallView}
					</div>

					<div id="followup" className={self.state.viewState === 'followup' ? visibleViewClass : viewClass}>
						{followupView}
					</div>
				</div>
			</div>
		);
	}
});

'use strict';

ReactTemplates.flash.resultView = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			row1: t('flash.activityListDone')
		};
	},
	render: function () {
		return (
			<div id={this.props.id}>
				<div id="flash-result-view-icon-row">
					<b className="up-icon-shield"></b>
					<i className="fa fa-trophy"></i>
				</div>

				<div id="flash-result-view-text-row-1">{this.lang.row1}</div>
			</div>
		);
	}
});

'use strict';
import { TYPE } from '../../../babel/store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('ResourceHelper', [
	'NotificationService',
	'$rootScope',
	'ENV',
	'SegmentHelper',
	function (NotificationService, $rootScope, ENV, SegmentHelper) {
		var notification = function (title, body, error, customBody = {}) {
			NotificationService.addNotification({
				title: title,
				body: body,
				style: NotificationService.style[error ? 'ERROR' : 'SUCCESS'],
				icon: error ? 'times' : 'check',
				...(!_.isEmpty(customBody) && { customBody: customBody }),
				...(customBody.type && { type: customBody.type })
			});
		};

		var getOption = function (opt, obj, isNew, parsedError) {
			if (typeof opt === 'function') {
				return opt(obj, isNew, parsedError);
			}
			return opt;
		};

		var broadcastEvent = function (event, data) {
			if (ENV === 'DEV') {
				if (console && console.log) {
					console.log('broadcasting:', event);
				}
			}
			$rootScope.$broadcast(event, data);
		};

		var parseError = function (error) {
			if (_.get(error, 'data.error.translated')) {
				return error.data.error.translated;
			} else if (_.get(error, 'data.error.key') === 'FailedStandardIntegrationValidation') {
				return error.data.error.msg;
			} else if (_.get(error, 'data.error.key') === 'StandardIntegrationError') {
				return error.data.error.standardIntegrationError;
			} else {
				return null;
			}
		};

		// The helper instance
		return function () {
			var Instance = this;

			// Merges options with defaults
			var mergeOpts = function (options) {
				return _.defaults(options || {}, Instance.defaults);
			};

			// Super defaults can be overwritten per instance
			Instance.defaults = {
				skipEvent: false,
				skipNotification: false,
				skipErrorNotification: false,
				eventPrefix: '',
				createSuccessTitle: 'default.saved',
				createSuccessBody: 'default.created',
				updateSuccessTitle: 'default.updated',
				updateSuccessBody: 'default.updated',
				deleteSucccessTitle: 'default.deleted',
				deleteSucccessBody: '',
				createErrorTitle: 'default.error',
				createErrorBody: 'default.error',
				updateErrorTitle: 'default.error',
				updateErrorBody: 'default.error',
				deleteErrorTitle: 'default.error',
				deleteErrorBody: 'default.error',
				attr: null,
				analyticsProps: () => {}
			};

			Instance.setDefaults = function (options) {
				Instance.defaults = _.defaults(options || {}, Instance.defaults);
			};

			Instance.onUpdate = function (options, data) {
				options = mergeOpts(options);

				var featureName = _.isFunction(options.eventPrefix) ? options.eventPrefix(data) : options.eventPrefix;
				var analyticsProps = _.isFunction(options.analyticsProps)
					? options.analyticsProps(options, 'updated', { data })
					: {};

				var moduleName = SegmentHelper.module[featureName];

				if (moduleName) {
					SegmentHelper.track('Updated ' + featureName, { ...analyticsProps, module: moduleName });
				} else {
					SegmentHelper.track('Updated ' + featureName, analyticsProps);
				}
				// Broadcast event
				if (!getOption(options.skipEvent, data)) {
					Instance.triggerEvent(options, 'updated', data);
				}

				// Show notification
				if (!getOption(options.skipNotification, data)) {
					Instance.successNotification(options.updateSuccessTitle, options.updateSuccessBody, data);
				}
			};

			Instance.onCreate = function (options, data) {
				options = mergeOpts(options);

				var featureName = _.isFunction(options.eventPrefix) ? options.eventPrefix(data) : options.eventPrefix;

				var moduleName = SegmentHelper.module[featureName];

				if (moduleName) {
					SegmentHelper.track('Created ' + featureName, { module: moduleName });
				} else {
					SegmentHelper.track('Created ' + featureName);
				}

				// Broadcast event
				if (!getOption(options.skipEvent, data)) {
					Instance.triggerEvent(options, 'added', data);
				}

				// Show notification
				if (!getOption(options.skipNotification, data)) {
					let customBody = {};
					if (options.notificationType === TYPE.GENERIC_ENTITY_ADDED) {
						customBody = {
							data: data,
							onclickLink: {
								text: 'noti.entity.Created',
								clicked: options.onclickLink
							},
							type: options.notificationType,
							extraSeconds: options.extraSeconds || 0
						};
					}
					Instance.successNotification(
						options.createSuccessTitle,
						options.createSuccessBody,
						data,
						true,
						customBody
					);
				}
			};

			// Triggers the correct method based on isNew variable
			Instance.onSave = function (options, data, isNew) {
				if (isNew) {
					Instance.onCreate(options, data);
				} else {
					Instance.onUpdate(options, data);
				}
			};

			// Triggers the correct method based on isNew variable
			Instance.onSaveError = function (options, isNew, err) {
				options = mergeOpts(options);

				// Show notification
				if (!getOption(options.skipErrorNotification, err, isNew)) {
					var parsedError = parseError(err, options.attr, options.eventPrefix);
					var title = isNew ? options.createErrorTitle : options.updateErrorTitle;
					var body = isNew ? options.createErrorBody : options.updateErrorBody;

					if (parsedError && typeof body !== 'function') {
						body = parsedError;
					}

					Instance.errorNotification(title, body, err, true, parsedError);
				}
			};

			Instance.onDelete = function (options, data) {
				options = mergeOpts(options);

				// Broadcast event
				if (!getOption(options.skipEvent, data)) {
					Instance.triggerEvent(options, 'deleted', data);
				}

				// Show notification
				if (!getOption(options.skipNotification, data)) {
					Instance.successNotification(options.deleteSucccessTitle, options.deleteSucccessBody, data);
				}
			};

			// Triggers the correct method based on data.id
			Instance.onDeleteError = function (options, response) {
				options = mergeOpts(options);

				// Show notification
				if (!getOption(options.skipErrorNotification, null)) {
					var parsedError = parseError(response, options.attr, options.eventPrefix);
					var body = parsedError ? parsedError : options.deleteErrorBody;

					Instance.errorNotification(options.deleteErrorTitle, body, null, true, parsedError);
				}
			};

			Instance.onFindError = function (options, err) {
				options = mergeOpts(options);

				// Show notification
				if (!getOption(options.skipErrorNotification, err)) {
					const parsedError = parseError(err, options.attr, options.eventPrefix);
					const title = options.findErrorTitle || 'default.error';
					let body = options.findErrorBody || 'default.error';

					if (parsedError && typeof body !== 'function') {
						body = parsedError;
					}
					Instance.errorNotification(title, body, err, true, parsedError);
				}
			};

			Instance.successNotification = function (title, body, data, isNew, customBody) {
				notification(getOption(title, data, isNew), getOption(body, data, isNew), false, customBody);
			};

			Instance.errorNotification = function (title, body, data, isNew, parsedError) {
				notification(
					getOption(title, data, isNew, parsedError),
					getOption(body, data, isNew, parsedError),
					true
				);
			};

			Instance.triggerEvent = function (options, event, data) {
				options = mergeOpts(options);

				broadcastEvent(getOption(options.eventPrefix, data) + '.' + event, data);
			};
		};
	}
]);

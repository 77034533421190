import React from 'react';
import { Row, Column } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import MailContentEditorAlign from './MailContentEditorAlign';

const MailContentEditorText = ({ column, onChange }) => {
	const classes = new bemClass('MailContentEditor');
	return (
		<div>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<MailContentEditorAlign value={column.align} onChange={align => onChange({ ...column, align })} />
				</Column>
			</Row>
		</div>
	);
};

export default MailContentEditorText;

import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

export default () => {
	const { metadata, mailIntegration } = useSelector(({ App }: RootState) => App);
	if (!Tools.FeatureHelper.hasSoftDeployAccess('MAIL_TEMPLATES_V2')) {
		return false;
	}
	if (
		(metadata?.map.mailActivated || mailIntegration) &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL)
	) {
		return true;
	}
	return false;
};

import QuotaTable from 'Components/QuotaTable';
import AdminHeader from 'Components/Admin/AdminHeader';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';

ReactTemplates.admin.activityQuota = window.ReactCreateClass({
	getInitialState: function () {
		return {
			fullscreen: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			activityQuota: t('admin.activityQuota'),
			activityQuotaDescription: t('admin.activityQuotaDescription'),
			user: t('default.user'),
			showPreviousYear: t('admin.showPreviousYearActivity'),
			activityType: t('default.activityType'),
			year: t('date.year'),
			allUsers: t('filters.allUsers'),
			allActivityTypes: t('filters.allActivityTypes'),
			createdCompleted: t('admin.createdCompleted'),
			created: t('report.created'),
			completed: t('report.completed'),
			role: t('default.role'),
			fullscreen: t('default.fullscreen'),
			showInactiveUsers: t('admin.includeInactiveAndDeletedUsers')
		};
	},
	showPreviousYear: function () {
		this.props.rootData.filterChange('showPreviousYear', !this.props.rootData.pageData.showPreviousYear);
	},
	toggleShowInactiveUsers: function () {
		this.props.rootData.filterChange('showInactiveUsers', !this.props.rootData.pageData.showInactiveUsers);
	},
	userChange: function (user) {
		this.props.rootData.filterChange('user', user);
	},
	activityTypeChange: function (activityType) {
		this.props.rootData.filterChange('activityType', activityType);
	},
	yearChange: function (e) {
		this.props.rootData.filterChange('year', e.target.value);
	},
	quotaTypeChange: function (e) {
		this.props.rootData.filterChange('quotaType', e.target.value);
	},
	toggleFullScreen: function () {
		this.setState({ fullscreen: !this.state.fullscreen });
	},
	getTypeToggleClass: function (type) {
		if (this.props.rootData.pageData.quotaType === type) {
			return 'btn up-btn btn-green';
		}
		return 'btn up-btn btn-light-grey';
	},
	render: function () {
		var thisYear = new Date().getUTCFullYear();
		var years = [(thisYear - 1).toString(), thisYear.toString(), (thisYear + 1).toString()];

		var loader = <ReactTemplates.loader />;

		return (
			<div id="admin-page-activity-quota" className={this.state.fullscreen ? 'fullscreen visible' : 'visible'}>
				<AdminHeader
					title={this.lang.activityQuota}
					description={this.lang.activityQuotaDescription}
					image="sales-goals.svg"
					articleId={593}
				/>
				<div id="admin-content">
					<div className="admin-section">
						<div className="admin-table">
							{!this.props.rootData.pageLoading && (
								<div className="table-tools">
									<div className="tool-filter" key="user">
										<label>
											{this.lang.user}
											{'/'}
											{this.lang.role}
										</label>
										<ReactTemplates.INPUTS.upUsers
											value={this.props.rootData.pageData.user}
											data={this.props.rootData.pageData.roleTree}
											tools={this.props.tools}
											placeholder={this.lang.allUsers}
											onChange={this.userChange}
										/>
									</div>
									<div className="tool-filter" key="activityType">
										<label>{this.lang.activityType}</label>
										<UpActivityTypes
											value={this.props.rootData.pageData.activityType}
											tools={this.props.tools}
											placeholder={this.lang.allActivityTypes}
											onChange={this.activityTypeChange}
											required={true}
										/>
									</div>
									<div className="tool-filter quotaType" key="quotaType">
										<label style={{ visibility: 'hidden' }}>{this.lang.createdCompleted}</label>
										<div className="btn-group">
											<button
												type="button"
												value="created"
												onClick={this.quotaTypeChange}
												className={this.getTypeToggleClass('created')}
											>
												{this.lang.created}
											</button>
											<button
												type="button"
												value="performed"
												onClick={this.quotaTypeChange}
												className={this.getTypeToggleClass('performed')}
											>
												{this.lang.completed}
											</button>
										</div>
									</div>
									<div className="tool-filter year" key="year">
										<label>{this.lang.year}</label>
										<ReactTemplates.INPUTS.upSelect
											className="form-control"
											data={_.map(years, function (y) {
												return { id: y, name: y };
											})}
											defaultValue={this.props.rootData.pageData.year}
											required={true}
											onChange={this.yearChange}
										/>
									</div>
									<div className="toggle-wrap">
										<ReactTemplates.upFilters.components.toggle
											className="toggle-bright-blue toggle-sm"
											value={this.props.rootData.pageData.showInactiveUsers}
											onChange={this.toggleShowInactiveUsers}
										/>
										<label>{this.lang.showInactiveUsers}</label>
									</div>
									<div className="toggle-wrap">
										<ReactTemplates.upFilters.components.toggle
											className="toggle-bright-blue toggle-sm"
											value={this.props.rootData.pageData.showPreviousYear}
											onChange={this.showPreviousYear}
										/>
										<label>{this.lang.showPreviousYear}</label>
									</div>
									{ReactTemplates.TOOLS.withTooltip(
										<button
											id="toggle-fullscreen"
											type="button"
											className="up-btn btn-grey btn-link"
											onClick={this.toggleFullScreen}
										>
											{!this.state.fullscreen ? (
												<b className="fa fa-expand" />
											) : (
												<b className="fa fa-compress" />
											)}
										</button>,
										this.lang.fullscreen,
										{
											placement: 'left',
											style: { top: '10px', right: '10px', position: 'absolute' }
										}
									)}
								</div>
							)}
							{!this.props.rootData.pageData.tableLoading ? <QuotaTable {...this.props} /> : null}
							<div className={!this.props.rootData.pageData.tableLoading ? 'hide' : ''}>{loader}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text, Button, Card, Icon, Title, Block } from '@upsales/components';
import './ToggleFiscalYearModal.scss';
interface ToggleFiscalYearModalProps {
	reject: () => void;
	resolve: () => void;
}
const ToggleFiscalYearModal = ({ reject, resolve }: ToggleFiscalYearModalProps) => {
	const classNames = new BemClass('ToggleFiscalYearModal');
	return (
		<Block className={classNames.b()}>
			<Card space="prl ptm pll pbm" className={classNames.elem('top').b()}>
				<Title>{T('admin.languageSettings.fiscalYearModalTitle')}</Title>
				<Button onClick={reject} color="grey" type="link">
					<Icon className={classNames.elem('cancel').b()} name="times" />
				</Button>
			</Card>
			<Block space="prxl plxl ptxl pbxl">
				<Text>{T('admin.languageSettings.fiscalYearModalBody')}</Text>
			</Block>
			<Card space="pll ptl pbl" className={classNames.elem('bottom').b()}>
				<Button onClick={resolve} text={T('default.confirm')} />
				<Button color="grey" text={T('default.cancel')} type="link" onClick={reject} />
			</Card>
		</Block>
	);
};
export default ToggleFiscalYearModal;

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('CustomField', [
	'$resource',
	'$q',
	'CustomFieldAttributes',
	'URL',
	'API',
	'CacheService',
	'AppService',
	'ParseGeneric',
	'ResourceHelper',
	function CustomField(
		$resource,
		$q,
		CustomFieldAttributes,
		URL,
		API,
		CacheService,
		AppService,
		ParseGeneric,
		ResourceHelper
	) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'customField',
			deleteSucccessBody: 'deleted.field'
		});

		var Attribute = CustomFieldAttributes().getAll();

		var EnumType = {
			AGREEMENT: 'agreement',
			ORDER: 'order',
			ORDERROW: 'orderrow',
			ACTIVITY: 'activity',
			TODO: 'todo',
			APPOINTMENT: 'appointment',
			OPPORTUNITY: 'opportunity',
			CLIENT: 'account',
			CONTACT: 'contact',
			ACCOUNT: 'account',
			PROJECT: 'project',
			PROJECT_PLAN: 'projectPlan',
			LEAD: 'lead',
			TICKET: 'ticket',
			USER: 'user',
			USER_DEF: 'userDefinedObject',
			USER_DEF1: 'userDefined1',
			USER_DEF2: 'userDefined2',
			USER_DEF3: 'userDefined4',
			USER_DEF4: 'userDefined4'
		};

		var Model = {
			customer: function (customer) {
				var instance = {};
				var baseUrl = URL + API + customer + '/customfields/';
				var CustomField = null;
				var Cache = CacheService.getCache(CacheService.cacheTypes.CUSTOMFIELD);
				instance.type = null;
				CustomField = $resource(
					baseUrl + ':customFieldType/:udoFieldId/:id',
					{},
					{
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCustom', { isArray: true, skipDateConvert: true })
						},
						update: {
							method: 'PUT',
							transformResponse: ParseGeneric('ParseCustom', { isArray: false, skipDateConvert: true })
						},
						updateMulti: {
							method: 'PUT',
							isArray: true
						},
						create: {
							method: 'POST',
							transformResponse: ParseGeneric('ParseCustom', { isArray: false, skipDateConvert: true })
						},
						delete: {
							method: 'DELETE'
						},
						findUdo: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCustom', { isArray: true, skipDateConvert: true }),
							url: baseUrl + 'userDefinedObject/:udoFieldId'
						}
					}
				);
				instance.find = function (filter, options) {
					if (!instance.type) {
						throw new Error('No type set for customField resource');
					}

					var params = angular.extend(filter, options);
					params.customFieldType = instance.type;
					instance.type = null;

					return CustomField.find(params).$promise;
				};
				instance.findUdo = function (filter, options) {
					if (!instance.udoFieldId) {
						throw new Error('No id set for customField UDO resource');
					}

					var params = angular.extend(filter, options);
					params.udoFieldId = instance.udoFieldId;
					instance.type = null;
					instance.udoFieldId = null;

					return CustomField.findUdo(params).$promise;
				};

				instance.findIdMap = function (filter, options) {
					var defered = $q.defer();

					// eslint-disable-next-line promise/catch-or-return
					instance.find(filter, options).then(function (response) {
						var map = {};
						angular.forEach(response.data, function (field) {
							map[field.id] = field;
						});

						defered.resolve({ data: map });
					}, defered.reject);

					return defered.promise;
				};

				instance.get = function (id) {
					if (!instance.type) {
						throw new Error('No type set for customField resource');
					}

					return CustomField.get({ id: id, customFieldType: instance.type }).$promise.then(
						function (response) {
							return response.data;
						},
						function (response) {
							return response.status;
						}
					);
				};

				instance.setType = function (type) {
					instance.type = type;

					return instance;
				};

				instance.setId = function (udoFieldId) {
					instance.udoFieldId = udoFieldId;

					return instance;
				};

				instance.getCached = function () {
					var type = instance.type; // Fix
					var cached = Cache.get(type);

					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find({}).then(function (res) {
							Cache.put(type, res);
							return res;
						});
					}
				};

				instance['delete'] = function (customField, options) {
					var params = { id: customField.id, customFieldType: instance.type };

					if (
						_.includes(
							['userDefined1', 'userDefined2', 'userDefined3', 'userDefined4'],
							params.customFieldType
						)
					) {
						params.customFieldType = 'userDefinedObject';
						params.udoFieldId = parseInt(instance.type.substr(instance.type.length - 1));
					}

					const promise = CustomField.delete(params).$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, customField);
						return res;
					});
					promise.catch(function (err) {
						// Notify user
						helper.onDeleteError(options, err);
					});
					return promise;
				};

				instance.saveMulti = function (customFields, options) {
					var defered = $q.defer();

					if (!Array.isArray(customFields)) {
						return defered.reject('No customFields');
					}

					var params = { customFieldType: instance.type };

					if (
						_.includes(
							['userDefined1', 'userDefined2', 'userDefined3', 'userDefined4'],
							params.customFieldType
						)
					) {
						params.customFieldType = 'userDefinedObject';
						params.udoFieldId = parseInt(instance.type.substr(instance.type.length - 1));
					}

					CustomField.updateMulti(
						params,
						customFields,
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);

					defered.promise
						.then(function (res) {
							helper.onSave(options, res.data);
							AllIWantDataCache.clearData();
						})
						.catch(function () {
							helper.onSaveError(options);
						});

					return defered.promise;
				};

				instance.save = function (customField, options) {
					var defered = $q.defer();

					if (!customField) {
						return defered.reject('No customfield');
					}

					var params = { customFieldType: instance.type };

					if (
						_.includes(
							['userDefined1', 'userDefined2', 'userDefined3', 'userDefined4'],
							params.customFieldType
						)
					) {
						params.customFieldType = 'userDefinedObject';
						params.udoFieldId = parseInt(instance.type.substr(instance.type.length - 1));
					}

					if (customField.id) {
						params.id = customField.id;

						CustomField.update(
							params,
							customField,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						CustomField.create(
							params,
							customField,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !customField.id);
							AllIWantDataCache.clearData();
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !customField.id);
						});

					return defered.promise;
				};

				return instance;
			},
			attr: Attribute,
			allTypes: EnumType
		};

		return Model;
	}
]);

'use strict';

angular.module('domain.admin').controller('CurrencySettings', [
	'$scope',
	'$q',
	'AppService',
	'FeatureHelper',
	'Currency',
	'ClientParam',
	function ($scope, $q, AppService, FeatureHelper, Currency, ClientParam) {
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;

		var rootData = (AdminSubCtrl.rootData = AdminCtrl.getRootObject());
		AdminSubCtrl.rootData.pageComponent = 'currencies';
		AdminSubCtrl.rootData.pageLoading = true;
		var pageData = (rootData.pageData = {});

		var newMasterCurrency = null;
		pageData.onMasterCurrencyChange = function (currency) {
			rootData.saveVisible = true;
			newMasterCurrency = currency;
		};

		rootData.onSave = function saveMasterCurrency() {
			if (!newMasterCurrency) {
				return;
			}

			var saveCurrency = {
				iso: newMasterCurrency.iso,
				rate: 1,
				active: true,
				masterCurrency: true
			};

			rootData.saving = false;

			Currency.create(saveCurrency)
				.then(function (res) {
					if (res && !res.error) {
						var metadata = AppService.getMetadata();
						metadata.customerCurrencies = [saveCurrency];
						AppService.setMetadata(metadata);
						pageData.currencies = AppService.getMetadata().customerCurrencies;
					}
				})
				.finally(function () {
					rootData.saveVisible = false;
					rootData.saving = false;
					pageData.canChangeMasterCurrency = false;
				});
		};

		pageData.onCurrencyActiveChange = function (iso, active) {
			rootData.saving = true;

			Currency.save({ iso: iso, active: active }).then(function (res) {
				if (res && !res.error) {
					var metadata = AppService.getMetadata();
					var index = _.findIndex(metadata.customerCurrencies, { iso: iso });

					if (index > -1) {
						metadata.customerCurrencies[index] = res.data;
						AppService.setMetadata(metadata);
						pageData.currencies = AppService.getMetadata().customerCurrencies;
					}
				}
				rootData.saving = false;
			});
		};

		pageData.onSaveCurrency = function (currency, isUpdate) {
			rootData.loading = true;

			var action = isUpdate ? 'save' : 'create';

			var saveCurrency = _.pick(currency, ['iso', 'active', 'masterCurrency']);

			if (currency.rateChanged) {
				currency.rate = currency.rate.replace(',', '.');
				var number = Number(currency.rate);
				saveCurrency.rate = 1.0 / number;
			} else {
				saveCurrency.rate = currency.originalRate;
			}

			if (!isUpdate) {
				saveCurrency.active = true;
			}

			return Currency[action](saveCurrency)
				.then(function (res) {
					if (res && !res.error) {
						var metadata = AppService.getMetadata();

						if (!isUpdate) {
							metadata.customerCurrencies.push(res.data);
						} else {
							var index = _.findIndex(metadata.customerCurrencies, { iso: res.data.iso });

							if (index > -1) {
								metadata.customerCurrencies[index] = res.data;
							}
						}
						AppService.setMetadata(metadata);
						pageData.currencies = AppService.getMetadata().customerCurrencies;
					}
				})
				.finally(function () {
					rootData.loading = false;
				});
		};

		pageData.toggleMultiCurrency = function (value) {
			var saveValue = value ? 1 : 0;

			ClientParam.save(193, saveValue).then(function (res) {
				if (res && res.data && res.data.value === saveValue) {
					var metadata = AppService.getMetadata();
					metadata.params.MultiCurrency = value;
					AppService.setMetadata(metadata);
					pageData.hasMultiCurrencies = value;
				}
			});
		};

		var init = function () {
			Currency.find().then(function (res) {
				/*
				If you dont get any currencies from the currency endpoint metadata.customerCurrencies witll still have a currency
				that it get from spcrm_b.client.currency and sets as master currency.
				You are only allowed to change master currency if this is the case. (Reason: this is how it is in classic)
			*/
				pageData.canChangeMasterCurrency = !(res && res.data && res.data.length);
				pageData.currencies = AppService.getMetadata().customerCurrencies;
				pageData.hasMultiCurrencies = AppService.getMetadata().params.MultiCurrency;
				rootData.pageLoading = false;
			});
		};

		AppService.loadedPromise.then(init);
	}
]);

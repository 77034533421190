import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import delayedUnmount from 'Components/Helpers/delayedUnmount';
import './DrawerWrap.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { Card } from '@upsales/components';
import { makeCancelable } from 'App/babel/helpers/promise';

class DrawerWrap extends React.Component {
	constructor(props) {
		super(props);
		this.modalRoot = document.getElementById('react-root');
		this.el = document.createElement('div');
		this.state = { mounted: false };
	}

	componentDidMount() {
		this.modalRoot.appendChild(this.el);

		document.getElementsByTagName('body')[0].style.overflow = 'hidden';

		this._mountedPromise = makeCancelable(
			new Promise(r => {
				setTimeout(() => {
					this.setState({ mounted: true });
					r();
				}, 0);
			})
		);
		this._mountedPromise.promise.catch(() => {});
	}

	componentWillUnmount() {
		this._mountedPromise.cancel();
		this.modalRoot.removeChild(this.el);

		document.getElementsByTagName('body')[0].style.overflow = null;
	}

	render() {
		const { isMounted, className, onClose, children } = this.props;
		const classes = new bemClass('DrawerWrap').mod({ open: isMounted && this.state.mounted });
		return ReactDOM.createPortal(
			<div className={classes.b()} ref={r => (this._ref = r)}>
				<div className={classes.elem('curtain').b()} onClick={onClose} />
				<Card color="grey-1" className={classes.elem('drawer', className).b()}>
					{children}
				</Card>
			</div>,
			this.el
		);
	}
}

DrawerWrap.propTypes = {
	children: PropTypes.any,
	onClose: PropTypes.func,
	className: PropTypes.string,
	isMounted: PropTypes.bool.isRequired
};

DrawerWrap.defaultProps = {
	isMounted: true
};

export default delayedUnmount(DrawerWrap);

angular.module('upDirectives').directive('upFileStorageAppsRoot', [
	function () {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				var props = {};

				var render = function (props) {
					window.SetupComponent($scope, window.FileStorageApps, $element[0], props, { redux: true });
				};

				$scope.$watch(
					$attr.upFileStorageAppsRoot,
					function (p) {
						props = p;
						render(props);
					},
					true
				);
			}
		};
	}
]);

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';
import { Toggle } from '@upsales/components';

const propTypes = {
	msBeforeSearch: PropTypes.number,
	minimumSearchKeyLength: PropTypes.number,
	dataCache: PropTypes.object,
	filterName: PropTypes.string,
	activeFilters: PropTypes.any,
	filter: PropTypes.object,
	onPageChange: PropTypes.func,
	onChange: PropTypes.func,
	customerId: PropTypes.number
};

class VirtualizedListPage extends Component {
	/* REACT METHODS */
	constructor(props) {
		super(props);

		this.state = {
			pendingAJAXrequest: false,
			itemSearchString: '',
			data: [],
			selectedData: [],
			showInactive: false
		};

		this.onChange = this.onChange.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.onSearch = _.debounce(this.onSearch, this.props.msBeforeSearch);

		// eslint-disable-next-line promise/catch-or-return
		this.props.dataCache.get(this.props.filterName + 'dataPromise').then(res => this.handleNewData(res));
	}

	componentDidMount() {
		ReactDOM.findDOMNode(this.refs['search-input']).focus();
	}

	getChildValues(arr) {
		return _.reduce(
			arr,
			function (values, item) {
				if (item.isRole) {
					return values.concat(this.getChildValues(item.children));
				} else {
					values.push(item.id.toString());
					return values;
				}
			}.bind(this),
			[]
		);
	}

	isChecked(item) {
		var values = [];
		if (this.props.activeFilters[this.props.filterName]) {
			values = this.props.activeFilters[this.props.filterName].value;
		}
		values = _.sortBy(values);

		var checked = false;

		if (item.isRole) {
			var childIds = this.getChildValues(item.children)
				.map(Number)
				.sort(function (a, b) {
					return a - b;
				});
			var childItr = 0;
			var valuesItr = 0;

			while (valuesItr < values.length && childItr < childIds.length) {
				if (values[valuesItr] === childIds[childItr]) {
					++childItr;
				} else {
					++valuesItr;
				}
			}
			checked = childItr === childIds.length;
		} else {
			var value = _.find(values, function (value) {
				return item.id === value;
			});
			checked = value !== undefined ? true : false;
		}

		return checked;
	}

	displayRow(item) {
		if (this.props.filter.showInactiveToggle && !this.state.showInactive && !item.active && !this.isChecked(item)) {
			return false;
		}
		if (this.props.filter.search) {
			return true;
		}
		if (this.state.itemSearchString === '') {
			return true;
		}
		if (item.name && item.name.toLowerCase().indexOf(this.state.itemSearchString.toLowerCase()) !== -1) {
			return true;
		}
		return false;
	}

	getRow(item, options, style) {
		var checked;
		var disabled = false;
		var filter = this.props.filter;
		item.isRole = !!item.isRole;
		checked = this.isChecked(item);

		var key = options.keyPrefix ? options.keyPrefix : '';
		key += item.id || item.$id;
		if (item.isRole) {
			key += '-role';
		}
		key += '-row';
		var onChange = options.onChange ? options.onChange : this.onChange;
		var showButton = checked;

		/** Text **/
		var displayText = filter.displayText || 'name';

		var text = typeof displayText === 'function' ? displayText(item) : item[displayText];
		var secondaryText =
			options.secondary && options.secondary.text ? item[options.secondary.text] + options.secondary.suffix : '';

		return React.createElement(ReactTemplates.upFilters.components.itemRow, {
			isGroup: item.isRole,
			checked: checked,
			disabled: disabled,
			onChange: onChange,
			showButton: showButton,
			item: item,
			text: text,
			secondaryText: secondaryText,
			value: item.id,
			key: key,
			style,
			tooltipIfOverflow: true
		});
	}

	getRows(array, options) {
		var filter = this.props.filter;

		var rows = _.reduce(
			array,
			(values, item) => {
				let disabled, checked;
				item.isRole = !!item.isRole;
				checked = this.isChecked(item);

				if (item.isRole) {
					if (item.children && item.children.length) {
						var childValues = this.getRows(item.children, options);

						var display = this.displayRow(item);

						if (childValues && childValues.length) {
							display = true;
						}

						if (display) {
							values.push({
								type: 'row',
								item,
								options,
								checked,
								disabled
							});
							values = values.concat(childValues);
						}
					}
				} else {
					if (item.isGroup && item.title && item.title.length) {
						values.push({
							type: 'title',
							item,
							checked,
							disabled
						});
					}

					if (item.data && Array.isArray(item.data)) {
						_.each(item.data, itm => {
							if (this.displayRow(itm)) {
								values.push({
									type: 'row',
									item,
									options,
									checked,
									disabled
								});
							}
						});
					} else if (this.displayRow(item)) {
						values.push({
							type: 'row',
							item,
							options,
							checked,
							disabled
						});
					}
				}
				return values;
			},
			[]
		);

		if (filter.selectMissingRow) {
			var item = filter.selectMissingRow.item;

			rows.unshift({
				type: 'missing-row',
				item
			});
		}

		return rows;
	}
	handleNewData(res) {
		this.setState({
			pendingAJAXrequest: false,
			data: res.data || res || []
		});
	}
	onGoBack() {
		this.props.onPageChange(null, true);
	}
	onSelectAll() {
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: 'Equals',
			inactive: true,
			value: [],
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	}
	getIsNotValue() {
		const currentFilter = this.props.activeFilters[this.props.filterName];

		return currentFilter?.comparisonType === 'NotEquals';
	}
	onChange(newValues, action) {
		var filter = this.props.filter;
		var comparisonType = this.getIsNotValue() ? 'NotEquals' : 'Equals';

		if (this.props.filter.comparisonType) {
			comparisonType = this.props.filter.comparisonType;
		}

		var oldFilter = this.props.activeFilters[this.props.filterName];
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: true,
			value: [],
			type: 'list'
		};

		if (oldFilter && oldFilter.value) {
			newFilter.value = oldFilter.value;
		}

		if (filter.selectMissingRow) {
			var selectMissingRowId = filter.selectMissingRow.item.id;

			newFilter.value = _.filter(newFilter.value, function (value) {
				return value !== selectMissingRowId;
			});
		}

		if (action === 'add') {
			newFilter.value = _.uniq(newFilter.value.concat(newValues));
		} else if (action === 'replace') {
			newFilter.value = newValues;
		} else {
			newFilter.value = _.filter(newFilter.value, function (value) {
				return newValues.indexOf(value) === -1;
			});
		}
		newFilter.inactive = newFilter.value.length > 0 ? false : true;

		this.props.onChange(newFilter, {
			action: 'add'
		});
	}
	onSelectMissingRow(newValues, action) {
		var comparisonType = this.getIsNotValue() ? 'NotEquals' : 'Equals';

		if (this.props.filter.comparisonType) {
			comparisonType = this.props.filter.comparisonType;
		}

		var value = action === 'add' ? newValues : [];
		var inactive = action === 'add' ? false : true;

		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: inactive,
			value: value,
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	}
	onSearchKey(event) {
		var value = event.target.value;
		this.onSearch(value);
	}
	onSearch() {
		var searchString = ReactDOM.findDOMNode(this.refs['search-input']).value;
		var filter = this.props.filter;

		if (filter.search) {
			if (searchString.length >= this.props.minimumSearchKeyLength) {
				var $injector = Tools.$injector;
				var searchFn = $injector.invoke(
					filter.searchFn,
					{},
					{ customerId: this.props.customerId, filterConfig: filter, filterName: this.props.filterName }
				);

				var promise = searchFn(searchString, filter.includeFields);

				this.setState({
					pendingAJAXrequest: true
				});

				// eslint-disable-next-line promise/catch-or-return
				promise.then(res => this.handleNewData(res));

				this.setState({
					itemSearchString: searchString
				});
			} else {
				this.setState({
					itemSearchString: searchString,
					data: []
				});
			}
		} else {
			this.setState({
				itemSearchString: searchString
			});
		}
	}
	onToggleComperator(value) {
		var newFilter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var newComparisionType = value ? 'NotEquals' : 'Equals';

		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;

			this.props.onChange(newFilter, {
				action: 'add'
			});
		}
	}

	onShowInactiveToggle(value) {
		this.setState({ showInactive: value });
	}

	/* RENDER */
	renderRow(key, index, style, rows) {
		const maRow = rows[index];

		if (maRow.type === 'row') {
			return this.getRow(maRow.item, maRow.options, style);
		} else if (maRow.type === 'title') {
			return (
				<div style={style} className="list-row list-row-separator" key={maRow.item.title + '-separator'}>
					<div className="separator">
						<span className="title">{maRow.item.title}</span>
					</div>
				</div>
			);
		} else if (maRow.type === 'missing-row') {
			return React.createElement(ReactTemplates.upFilters.components.itemRow, {
				isGroup: false,
				checked: this.isChecked(maRow.item),
				disabled: false,
				onChange: this.onSelectMissingRow,
				showButton: false,
				item: maRow.item,
				text: maRow.item.name,
				value: maRow.item.id,
				key: 'select-missing-row',
				className: 'list-row-missing-row',
				style: style
			});
		} else if (maRow.type === 'no-results-row') {
			const noResultsString = Tools.$translate('default.noResults');

			return (
				<div style={style} key={'no-results-row'} className={'list-row list-row-item'}>
					<span className={'list-text list-text-item'}>{noResultsString}</span>
				</div>
			);
		} else if (maRow.type === 'not-selected-label') {
			var notSelectedString = Tools.$translate('default.notSelected');

			return (
				<div style={style} key={'not-selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{notSelectedString}</span>
				</div>
			);
		} else if (maRow.type === 'selected-label') {
			var selectedString = Tools.$translate('default.selected');

			return (
				<div style={style} key={'selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{selectedString}</span>
				</div>
			);
		}
	}

	render() {
		var filter = this.props.filter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var rows = [];
		var selectedRows = [];

		if (this.state.data.length) {
			rows = this.getRows(this.state.data, this.state.data.options ? this.state.data.options : {});

			selectedRows = _.chain(rows)
				.filter(function (row) {
					return row && row.checked && row.checked === true ? true : false;
				})
				.filter(function (row) {
					return row && row.isRole && row.isRole === true ? false : true;
				})
				.value();

			rows = _.filter(rows, function (row) {
				return row && row.checked && row.checked === true ? false : true;
			});
		}

		if (
			rows.length === 0 &&
			selectedRows &&
			selectedRows.length < 1 &&
			this.state.itemSearchString !== '' &&
			!this.state.pendingAJAXrequest
		) {
			rows.unshift({
				type: 'no-results-row'
			});
		} else if (selectedRows && selectedRows.length) {
			rows.unshift({
				type: 'not-selected-label'
			});
		}

		if (selectedRows && selectedRows.length) {
			selectedRows.unshift({
				type: 'selected-label'
			});
		}

		var nrSelected = currentFilter && currentFilter.value ? currentFilter.value.length : 0;

		var isIsNotString = Tools.$translate('filters.excludeSelected');
		var deselectAllString = ' ' + Tools.$translate('default.deselectAll');

		var defaultValue = this.props.dataCache.get(this.props.filterName + 'searchstring')
			? this.props.dataCache.get(this.props.filterName + 'searchstring')
			: '';
		var placeholder = filter.search
			? Tools.$translate('default.typeToSearch')
			: Tools.$translate('filters.doFilter');

		var comparitorToggle = null;
		var showInactiveToggle = null;
		var hasToggle = false;
		// it can only be one toggle
		if (filter.multiComparitors) {
			hasToggle = true;
			var checked = false;

			if (currentFilter && currentFilter.comparisonType === 'NotEquals') {
				checked = true;
			}
			comparitorToggle = (
				<span>
					<span style={{ marginLeft: '10px', display: 'inline-block' }}>
						<Toggle checked={checked} onChange={value => this.onToggleComperator(value)} />
					</span>
					<span style={{ paddingLeft: '5px' }}>{isIsNotString}</span>
				</span>
			);
		} else if (filter.showInactiveToggle) {
			hasToggle = true;
			showInactiveToggle = (
				<span className="show-inactive-toggle">
					<span style={{ marginLeft: '10px', display: 'inline-block' }}>
						<Toggle
							checked={this.state.showInactive}
							onChange={value => this.onShowInactiveToggle(value)}
						/>
					</span>
					<span style={{ paddingLeft: '5px' }}>{Tools.$translate('default.showInactive')}</span>
				</span>
			);
		}

		rows = selectedRows.concat(rows);

		return (
			<div className="list-filter-page" key={this.props.filterName}>
				<div className="list-page">
					<div className="header">
						<button className="back-button" onClick={() => this.onGoBack()}>
							<b className="fa fa-chevron-left" />
						</button>
						<div className="search-input-wrap">
							<input
								ref="search-input"
								className="search-input"
								type="text"
								placeholder={placeholder}
								defaultValue={defaultValue}
								onChange={e => this.onSearchKey(e)}
							/>
							<b className="fa fa-search" />
						</div>

						{this.state.pendingAJAXrequest && (
							<i className="fa fa-refresh fa-spin fa-fw list-page-spinner" />
						)}

						<div className={'toolbar'}>
							{comparitorToggle}
							{showInactiveToggle}

							<button
								disabled={nrSelected === 0}
								className={
									'btn up-btn no-shadow btn-link btn-bright-blue pull-right toolbar-deselect-all-button' +
									(!hasToggle ? ' btn-block' : '')
								}
								onClick={() => this.onSelectAll()}
							>
								<i className="fa fa-times" aria-hidden="true" /> {deselectAllString}
							</button>
						</div>
					</div>

					<AutoSizer>
						{({ height, width }) => (
							<List
								className="list-body"
								height={height || 100}
								rowCount={rows.length}
								rowHeight={34}
								rowRenderer={({ key, index, style }) => this.renderRow(key, index, style, rows)}
								width={width || 100}
							/>
						)}
					</AutoSizer>
				</div>
			</div>
		);
	}
}

VirtualizedListPage.defaultProps = {
	minimumSearchKeyLength: 1,
	msBeforeSearch: 500
};

VirtualizedListPage.propTypes = propTypes;

window.VirtualizedListPage = VirtualizedListPage;
export default VirtualizedListPage;

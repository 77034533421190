'use strict';

const {
	currencyDisplayDeprecated,
	currencyFormat,
	currencyRange,
	numberFormat
} = require('Components/Filters/Currencies');

angular
	.module('upFilters')
	.filter('currencyDisplayDeprecated', [
		'$translate',
		function ($translate) {
			return currencyDisplayDeprecated;
		}
	])
	.filter('currencyFormat', [
		'$filter',
		function ($filter) {
			return currencyFormat;
		}
	])
	.filter('currencyRange', [
		'$filter',
		function ($filter) {
			return currencyRange;
		}
	])
	.filter('numberFormat', [
		'$filter',
		function ($filter) {
			return numberFormat;
		}
	])
	.filter('percentageFormat', [
		'$filter',
		function ($filter) {
			return function (number, verbose, fractionDigits) {
				if (isNaN(number)) {
					number = 0;
				}

				var locales = window.userLocale ? [window.userLocale, 'sv-SE', 'en-GB'] : ['sv-SE', 'en-GB'];
				var options = {
					style: 'percent',
					minimumFractionDigits: fractionDigits,
					maximumFractionDigits: fractionDigits || 2
				};

				if (!verbose && Math.abs(number) > 1000000) {
					var currencyDisplay = $filter('currencyDisplayDeprecated');
					return currencyDisplay(number, false, true, true);
				}

				return new Intl.NumberFormat(locales, options).format(number).replace(/\s/g, '');
			};
		}
	]);

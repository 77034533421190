'use strict';

ReactTemplates.bannerEditor.fakeNewsSite = window.ReactCreateClass({
	getBanner: function (format, second) {
		var iframe = null;
		var size = format.toLowerCase().split('x');
		var style = {
			width: size[0] + 'px',
			height: size[1] + 'px'
		};

		if (this.props.formats.indexOf(format) !== -1) {
			var frameStyle = {
				border: 0
			};
			iframe = (
				<iframe
					style={frameStyle}
					src=""
					border="0"
					scrolling="no"
					allowTransparency="true"
					width="100%"
					height="100%"
				></iframe>
			);
		}
		var formatName = second ? format + '-2' : format;
		return (
			<div ref={this.props.setRef.bind(self, '_frame-' + formatName)} className="news-banner-wrap" style={style}>
				{iframe}
			</div>
		);
	},
	render: function () {
		var self = this;

		var date = moment().add(1, 'day').format('dddd DD MMMM');
		var accountSelf = Tools.AppService.getAccountSelf();
		var userName = accountSelf.name;
		var clientName = accountSelf.client.name;

		return (
			<div id="fake-news-site">
				<div className="browser-wrapper">
					<div className="browser-header">
						<div className="browser-circle"></div>
						<div className="browser-circle"></div>
						<div className="browser-circle"></div>
						<div className="url">{'morgondagensindustri.se/'}</div>
					</div>

					<div className="container">
						<div className="row top-row">
							<div className="col-xs-1 header-sidebar">
								<h2>{'Mi'}</h2>

								<div className="sidebar-stock">{'+3.2%'}</div>
								<div className="sidebar-stock">{'+46%'}</div>
							</div>
							<div className="col-xs-11 header-creative">{self.getBanner('980x240')}</div>

							<div className="header-top">
								<div className="header-main">
									<div className="header-left">
										<h2>{'Morgondagens Industri'}</h2>
										<br />
										<div id="tomorrow-date">{date}</div>
									</div>
									<div className="header-sidebar-article">
										<p>
											<span style={{ color: '#F7EAE4', fontSize: '10px' }}>{'EXPERTEN:'}</span>
											<br />
											{'Genialiska marknadsdrag från '}
											{userName}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container main-container">
						<div className="row">
							<div className="col-sm-8 main-article-col">
								<div className="main-article">
									{self.getBanner('728x90')}
									<h3>
										{'Det går sjukt bra nu för '}
										{clientName}
									</h3>
									<p>
										{
											'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit liberos.'
										}
										<br />
										{
											'Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
										}
									</p>
									<div className="timestamp"></div>
								</div>

								<div className="col-sm-9 sub-article-col">
									<div className="sub-article" id="first-sub">
										{self.getBanner('480x280')}
										<h3>
											{clientName}
											{' tar digital annonsering till nästa nivå'}
										</h3>
										<p>
											{
												'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
											}
										</p>
										<div className="timestamp"></div>
									</div>

									<div className="sub-article">
										<div className="mobile-banner">{self.getBanner('300x250', true)}</div>
										<h3>
											{'Läs hur '}
											{clientName}
											{' fick mer leads än någonsin'}
										</h3>
										<p>
											{
												'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.'
											}
										</p>

										<div className="timestamp"></div>
									</div>

									<div className="mobile-banner">{self.getBanner('300x600', true)}</div>
								</div>

								<div className="col-sm-3 most-read-col">
									<h5>{'Mest läst'}</h5>

									<div className="article-small">
										<h5>
											{clientName}
											{', vilken börsraket!'}
										</h5>
										<p>
											{
												'Etiam porta sem malesuada magna mollis euismod. Maecenas sed diam eget risus varius blandit sit amet non magna.'
											}
										</p>
									</div>

									<div className="article-small">
										<h5>{'Så ökade företaget sin försäljnig med 200%'}</h5>
										<p>{'Nullam id dolor id nibh ultricies vehicula ut id elit.'}</p>
									</div>

									{self.getBanner('160x600')}
								</div>
							</div>

							<div className="col-sm-4 main-sidebar">
								{self.getBanner('300x250')}

								<h4>{'Senaste nytt'}</h4>
								<div className="article-small">
									<h5>{'Headline'}</h5>
									<p>
										{
											'Etiam porta sem malesuada magna mollis euismod. Maecenas sed diam eget risus varius blandit sit amet non magna.'
										}
									</p>
								</div>
								<div className="article-small">
									<h5>{'Headline'}</h5>
									<p>{'Nullam id dolor id nibh ultricies vehicula ut id elit.'}</p>
								</div>
								<div className="article-small">
									<h5>{'Headline'}</h5>
									<p>{'Etiam porta sem malesuada magna mollis euismod. Maecenas sed diam.'}</p>
								</div>
								{self.getBanner('300x600')}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline, Text, Card, Button } from '@upsales/components';
import { selectRole, toggleDesiredOutcome, save } from 'Store/reducers/DesiredOutcomeReducer';
import T from '../Helpers/translate';
import './DesiredOutcomeSelect.scss';

const mapDispatchToProps = {
	selectRole,
	save,
	toggleDesiredOutcome
};

class DesiredOutcomeSelect extends React.PureComponent {
	constructor(p) {
		super(p);

		this.lang = {
			mainGoal: T('desiredoutcome.outcome.mainGoal'),
			selectAllThatApply: T('desiredoutcome.outcome.selectAllThatApply'),
			startUsingUpsales: T('desiredoutcome.outcome.startUsingUpsales'),
			change: T('admin.change')
		};
	}

	deSelectRole = () => {
		this.props.selectRole(null);
	};

	render() {
		const { icon, desiredOutcomes, title } = this.props.selectedRole;
		const { selectedDesiredOutcomes, toggleDesiredOutcome, saving } = this.props;
		const classes = new bemClass('DesiredOutcomeSelect');
		const gridItemClasses = new bemClass('DesiredOutcomeGridItem');

		const gridItems = desiredOutcomes.map(item => {
			const isSelected = selectedDesiredOutcomes.indexOf(item.id) > -1;
			const cardColor = isSelected ? 'bright-blue' : 'white';
			const textColor = isSelected ? 'white' : 'black';

			return (
				<Card
					key={item.id}
					onClick={() => toggleDesiredOutcome(item.id)}
					className={gridItemClasses.mod('center-text').b()}
					color={cardColor}
				>
					<Text center={true} size="xl" color="grey-10" color={textColor}>
						{T(item.text)}
					</Text>
				</Card>
			);
		});

		const saveDisabled = selectedDesiredOutcomes.length === 0;
		const saveBtnColor = saveDisabled ? 'light-grey' : 'bright-blue';

		return (
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>
					<img src={`img/${icon}`} />
					<div className={classes.elem('header-text').b()}>
						<div className={classes.elem('overflow-wrap').b()}>
							<Text size="xl" color="grey-10">
								{`”${T(title)}”`}
							</Text>
							<Button disabled={saving} onClick={this.deSelectRole} type="link">
								{this.lang.change}
							</Button>
						</div>
						<Headline size="sm">{this.lang.mainGoal}</Headline>
						<Text size="xl" color="grey-10">
							{this.lang.selectAllThatApply}
						</Text>
					</div>
				</div>
				<div className={classes.elem('grid').b()}>{gridItems}</div>
				<Button
					disabled={saveDisabled}
					color={saveBtnColor}
					size="lg"
					block={true}
					onClick={this.props.save}
					loading={saving}
				>
					{this.lang.startUsingUpsales}
				</Button>
			</div>
		);
	}
}

DesiredOutcomeSelect.defaultProps = {};

export const detached = DesiredOutcomeSelect;

const Component = connect(null, mapDispatchToProps)(DesiredOutcomeSelect);

export default Component;

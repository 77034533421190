'use strict';

import logError from 'App/babel/helpers/logError';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var Product = window.Tools.Product;
		var dontUseCachedProducts = window.Tools.AppService.getTotals('products') > 4000;
		var customerId = Tools.AppService.getCustomerId();

		var defaultSelect2Options = {
			// eslint-disable-next-line no-shadow-restricted-names
			matcher: function (term, undefined, object) {
				return object.name && object.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			initSelection: function (element, callback) {
				let value;
				const selectedOption = element.data?.('select2')?.selection?.data?.('select2-data') ?? self.props.value;
				if (typeof selectedOption === 'object' && selectedOption && selectedOption.id) {
					if (
						selectedOption.name &&
						selectedOption.currencies &&
						selectedOption.tiers &&
						selectedOption.listPrice !== undefined
					) {
						// We have all fields that we need so we can skip the fetch
						callback(selectedOption);
						return;
					}
					value = selectedOption.id;
				} else if (typeof element.val() === 'string') {
					// eslint-disable-next-line no-useless-escape
					value = element.val().replace(/\"/g, '');
				}

				if (value) {
					Product.customer(customerId)
						.get(value, self.props.usePriceLists)
						.then(function (res) {
							if (res && res.data) {
								callback(res.data);
							}
						})
						.catch(err => logError(err, 'Could not get product'));
				}
			}
		};

		var opts = {
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			disabled: self.props.disabled || false,
			ajax: false,
			asIds: false,
			onChange: function (value) {
				if (Array.isArray(value)) {
					value = _.map(value, function (v) {
						return _.omit(v, ['$$parent']);
					});
				} else if (value && value.$$parent) {
					value = _.omit(value, ['$$parent']);
				}
				self.props.onChange(value);
			},
			idAttr: Product.attr.id,
			titleAttr: Product.attr.name,
			sorting: { field: Product.attr.name, ascending: true },
			placeholder: self.props.placeholder,
			select2: Object.assign(defaultSelect2Options, self.props.select2),
			excludeBundles: self.props.excludeBundles,
			excludeRecurring: self.props.excludeRecurring,
			excludeOneOffs: self.props.excludeOneOffs,
			fields: ['id', 'name', 'currencies', 'tiers', 'listPrice']
		};

		if (dontUseCachedProducts) {
			opts.ajax = true;
			opts.resource = Product;
			opts.getter = function (customerId, filter) {
				const excludeBundlesQuery = opts.excludeBundles
					? [{ a: 'bundlePriceAdjustment', c: 'eq', v: null }]
					: [];
				return Product.customer(customerId).find({
					...filter,
					usePriceLists: self.props.usePriceLists,
					q: [...(filter?.q ?? [])].concat(excludeBundlesQuery)
				});
			};
		} else {
			opts.data = function () {
				var onlyActive = true;
				var skipAuth = false;
				var selectableCategories = self.props.selectableCategories || false;
				var data = new window.Tools.ProductTree(
					onlyActive,
					skipAuth,
					selectableCategories,
					self.props.usePriceLists,
					opts.excludeBundles
				);
				return data.data;
			};
		}

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			const removeExcludedProducts = data => {
				return data
					.filter(product => {
						if (product.children) {
							return true;
						}
						if (opts.excludeRecurring && product.isRecurring) {
							return false;
						}
						if (opts.excludeOneOffs && !product.isRecurring) {
							return false;
						}
						if (opts.excludeBundles && product.bundlePriceAdjustment !== null) {
							return false;
						}
						return true;
					})
					.map(option => {
						if (option.children) {
							return { ...option, children: removeExcludedProducts(option.children) };
						}
						return option;
					})
					.filter(option => {
						if (option.children && !option.children.length) {
							return false;
						}
						return true;
					});
			};
			if (options.data) {
				options.data = removeExcludedProducts(options.data);
			}
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upProducts = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

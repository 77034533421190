'use strict';

angular.module('services.countryCodes', []).factory('CountryCodes', [
	'$q',
	function ($q) {
		var codes = {
			AF: { name: 'Afghanistan', code: 'AF' },
			AX: { name: 'Åland Islands', code: 'AX' },
			AL: { name: 'Albania', code: 'AL' },
			DZ: { name: 'Algeria', code: 'DZ' },
			AS: { name: 'American Samoa', code: 'AS' },
			AD: { name: 'AndorrA', code: 'AD' },
			AO: { name: 'Angola', code: 'AO' },
			AI: { name: 'Anguilla', code: 'AI' },
			AQ: { name: 'Antarctica', code: 'AQ' },
			AG: { name: 'Antigua and Barbuda', code: 'AG' },
			AR: { name: 'Argentina', code: 'AR' },
			AM: { name: 'Armenia', code: 'AM' },
			AW: { name: 'Aruba', code: 'AW' },
			AU: { name: 'Australia', code: 'AU' },
			AT: { name: 'Austria', code: 'AT' },
			AZ: { name: 'Azerbaijan', code: 'AZ' },
			BS: { name: 'Bahamas', code: 'BS' },
			BH: { name: 'Bahrain', code: 'BH' },
			BD: { name: 'Bangladesh', code: 'BD' },
			BB: { name: 'Barbados', code: 'BB' },
			BY: { name: 'Belarus', code: 'BY' },
			BE: { name: 'Belgium', code: 'BE' },
			BZ: { name: 'Belize', code: 'BZ' },
			BJ: { name: 'Benin', code: 'BJ' },
			BM: { name: 'Bermuda', code: 'BM' },
			BT: { name: 'Bhutan', code: 'BT' },
			BO: { name: 'Bolivia', code: 'BO' },
			BA: { name: 'Bosnia and Herzegovina', code: 'BA' },
			BW: { name: 'Botswana', code: 'BW' },
			BV: { name: 'Bouvet Island', code: 'BV' },
			BR: { name: 'Brazil', code: 'BR' },
			IO: { name: 'British Indian Ocean Territory', code: 'IO' },
			BN: { name: 'Brunei Darussalam', code: 'BN' },
			BG: { name: 'Bulgaria', code: 'BG' },
			BF: { name: 'Burkina Faso', code: 'BF' },
			BI: { name: 'Burundi', code: 'BI' },
			KH: { name: 'Cambodia', code: 'KH' },
			CM: { name: 'Cameroon', code: 'CM' },
			CA: { name: 'Canada', code: 'CA' },
			CV: { name: 'Cape Verde', code: 'CV' },
			KY: { name: 'Cayman Islands', code: 'KY' },
			CF: { name: 'Central African Republic', code: 'CF' },
			TD: { name: 'Chad', code: 'TD' },
			CL: { name: 'Chile', code: 'CL' },
			CN: { name: 'China', code: 'CN' },
			CX: { name: 'Christmas Island', code: 'CX' },
			CC: { name: 'Cocos (Keeling) Islands', code: 'CC' },
			CO: { name: 'Colombia', code: 'CO' },
			KM: { name: 'Comoros', code: 'KM' },
			CG: { name: 'Congo', code: 'CG' },
			CD: { name: 'Congo, The Democratic Republic of the', code: 'CD' },
			CK: { name: 'Cook Islands', code: 'CK' },
			CR: { name: 'Costa Rica', code: 'CR' },
			CI: { name: "Cote D'Ivoire", code: 'CI' },
			HR: { name: 'Croatia', code: 'HR' },
			CU: { name: 'Cuba', code: 'CU' },
			CY: { name: 'Cyprus', code: 'CY' },
			CZ: { name: 'Czech Republic', code: 'CZ' },
			DK: { name: 'Denmark', code: 'DK' },
			DJ: { name: 'Djibouti', code: 'DJ' },
			DM: { name: 'Dominica', code: 'DM' },
			DO: { name: 'Dominican Republic', code: 'DO' },
			EC: { name: 'Ecuador', code: 'EC' },
			EG: { name: 'Egypt', code: 'EG' },
			SV: { name: 'El Salvador', code: 'SV' },
			GQ: { name: 'Equatorial Guinea', code: 'GQ' },
			ER: { name: 'Eritrea', code: 'ER' },
			EE: { name: 'Estonia', code: 'EE' },
			ET: { name: 'Ethiopia', code: 'ET' },
			FK: { name: 'Falkland Islands (Malvinas)', code: 'FK' },
			FO: { name: 'Faroe Islands', code: 'FO' },
			FJ: { name: 'Fiji', code: 'FJ' },
			FI: { name: 'Finland', code: 'FI' },
			FR: { name: 'France', code: 'FR' },
			GF: { name: 'French Guiana', code: 'GF' },
			PF: { name: 'French Polynesia', code: 'PF' },
			TF: { name: 'French Southern Territories', code: 'TF' },
			GA: { name: 'Gabon', code: 'GA' },
			GM: { name: 'Gambia', code: 'GM' },
			GE: { name: 'Georgia', code: 'GE' },
			DE: { name: 'Germany', code: 'DE' },
			GH: { name: 'Ghana', code: 'GH' },
			GI: { name: 'Gibraltar', code: 'GI' },
			GR: { name: 'Greece', code: 'GR' },
			GL: { name: 'Greenland', code: 'GL' },
			GD: { name: 'Grenada', code: 'GD' },
			GP: { name: 'Guadeloupe', code: 'GP' },
			GU: { name: 'Guam', code: 'GU' },
			GT: { name: 'Guatemala', code: 'GT' },
			GG: { name: 'Guernsey', code: 'GG' },
			GN: { name: 'Guinea', code: 'GN' },
			GW: { name: 'Guinea-Bissau', code: 'GW' },
			GY: { name: 'Guyana', code: 'GY' },
			HT: { name: 'Haiti', code: 'HT' },
			HM: { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
			VA: { name: 'Holy See (Vatican City State)', code: 'VA' },
			HN: { name: 'Honduras', code: 'HN' },
			HK: { name: 'Hong Kong', code: 'HK' },
			HU: { name: 'Hungary', code: 'HU' },
			IS: { name: 'Iceland', code: 'IS' },
			IN: { name: 'India', code: 'IN' },
			ID: { name: 'Indonesia', code: 'ID' },
			IR: { name: 'Iran, Islamic Republic Of', code: 'IR' },
			IQ: { name: 'Iraq', code: 'IQ' },
			IE: { name: 'Ireland', code: 'IE' },
			IM: { name: 'Isle of Man', code: 'IM' },
			IL: { name: 'Israel', code: 'IL' },
			IT: { name: 'Italy', code: 'IT' },
			JM: { name: 'Jamaica', code: 'JM' },
			JP: { name: 'Japan', code: 'JP' },
			JE: { name: 'Jersey', code: 'JE' },
			JO: { name: 'Jordan', code: 'JO' },
			KZ: { name: 'Kazakhstan', code: 'KZ' },
			KE: { name: 'Kenya', code: 'KE' },
			KI: { name: 'Kiribati', code: 'KI' },
			KP: { name: "Korea, Democratic People'S Republic of", code: 'KP' },
			KR: { name: 'Korea, Republic of', code: 'KR' },
			KW: { name: 'Kuwait', code: 'KW' },
			KG: { name: 'Kyrgyzstan', code: 'KG' },
			LA: { name: "Lao People'S Democratic Republic", code: 'LA' },
			LV: { name: 'Latvia', code: 'LV' },
			LB: { name: 'Lebanon', code: 'LB' },
			LS: { name: 'Lesotho', code: 'LS' },
			LR: { name: 'Liberia', code: 'LR' },
			LY: { name: 'Libyan Arab Jamahiriya', code: 'LY' },
			LI: { name: 'Liechtenstein', code: 'LI' },
			LT: { name: 'Lithuania', code: 'LT' },
			LU: { name: 'Luxembourg', code: 'LU' },
			MO: { name: 'Macao', code: 'MO' },
			MK: { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
			MG: { name: 'Madagascar', code: 'MG' },
			MW: { name: 'Malawi', code: 'MW' },
			MY: { name: 'Malaysia', code: 'MY' },
			MV: { name: 'Maldives', code: 'MV' },
			ML: { name: 'Mali', code: 'ML' },
			MT: { name: 'Malta', code: 'MT' },
			MH: { name: 'Marshall Islands', code: 'MH' },
			MQ: { name: 'Martinique', code: 'MQ' },
			MR: { name: 'Mauritania', code: 'MR' },
			MU: { name: 'Mauritius', code: 'MU' },
			YT: { name: 'Mayotte', code: 'YT' },
			MX: { name: 'Mexico', code: 'MX' },
			FM: { name: 'Micronesia, Federated States of', code: 'FM' },
			MD: { name: 'Moldova, Republic of', code: 'MD' },
			MC: { name: 'Monaco', code: 'MC' },
			MN: { name: 'Mongolia', code: 'MN' },
			MS: { name: 'Montserrat', code: 'MS' },
			MA: { name: 'Morocco', code: 'MA' },
			MZ: { name: 'Mozambique', code: 'MZ' },
			MM: { name: 'Myanmar', code: 'MM' },
			NA: { name: 'Namibia', code: 'NA' },
			NR: { name: 'Nauru', code: 'NR' },
			NP: { name: 'Nepal', code: 'NP' },
			NL: { name: 'Netherlands', code: 'NL' },
			AN: { name: 'Netherlands Antilles', code: 'AN' },
			NC: { name: 'New Caledonia', code: 'NC' },
			NZ: { name: 'New Zealand', code: 'NZ' },
			NI: { name: 'Nicaragua', code: 'NI' },
			NE: { name: 'Niger', code: 'NE' },
			NG: { name: 'Nigeria', code: 'NG' },
			NU: { name: 'Niue', code: 'NU' },
			NF: { name: 'Norfolk Island', code: 'NF' },
			MP: { name: 'Northern Mariana Islands', code: 'MP' },
			NO: { name: 'Norway', code: 'NO' },
			OM: { name: 'Oman', code: 'OM' },
			PK: { name: 'Pakistan', code: 'PK' },
			PW: { name: 'Palau', code: 'PW' },
			PS: { name: 'Palestinian Territory, Occupied', code: 'PS' },
			PA: { name: 'Panama', code: 'PA' },
			PG: { name: 'Papua New Guinea', code: 'PG' },
			PY: { name: 'Paraguay', code: 'PY' },
			PE: { name: 'Peru', code: 'PE' },
			PH: { name: 'Philippines', code: 'PH' },
			PN: { name: 'Pitcairn', code: 'PN' },
			PL: { name: 'Poland', code: 'PL' },
			PT: { name: 'Portugal', code: 'PT' },
			PR: { name: 'Puerto Rico', code: 'PR' },
			QA: { name: 'Qatar', code: 'QA' },
			RE: { name: 'Reunion', code: 'RE' },
			RO: { name: 'Romania', code: 'RO' },
			RU: { name: 'Russian Federation', code: 'RU' },
			RW: { name: 'RWANDA', code: 'RW' },
			SH: { name: 'Saint Helena', code: 'SH' },
			KN: { name: 'Saint Kitts and Nevis', code: 'KN' },
			LC: { name: 'Saint Lucia', code: 'LC' },
			PM: { name: 'Saint Pierre and Miquelon', code: 'PM' },
			VC: { name: 'Saint Vincent and the Grenadines', code: 'VC' },
			WS: { name: 'Samoa', code: 'WS' },
			SM: { name: 'San Marino', code: 'SM' },
			ST: { name: 'Sao Tome and Principe', code: 'ST' },
			SA: { name: 'Saudi Arabia', code: 'SA' },
			SN: { name: 'Senegal', code: 'SN' },
			CS: { name: 'Serbia and Montenegro', code: 'CS' },
			SC: { name: 'Seychelles', code: 'SC' },
			SL: { name: 'Sierra Leone', code: 'SL' },
			SG: { name: 'Singapore', code: 'SG' },
			SK: { name: 'Slovakia', code: 'SK' },
			SI: { name: 'Slovenia', code: 'SI' },
			SB: { name: 'Solomon Islands', code: 'SB' },
			SO: { name: 'Somalia', code: 'SO' },
			ZA: { name: 'South Africa', code: 'ZA' },
			GS: { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
			ES: { name: 'Spain', code: 'ES' },
			LK: { name: 'Sri Lanka', code: 'LK' },
			SD: { name: 'Sudan', code: 'SD' },
			SR: { name: 'Suriname', code: 'SR' },
			SJ: { name: 'Svalbard and Jan Mayen', code: 'SJ' },
			SZ: { name: 'Swaziland', code: 'SZ' },
			SE: { name: 'Sweden', code: 'SE' },
			CH: { name: 'Switzerland', code: 'CH' },
			SY: { name: 'Syrian Arab Republic', code: 'SY' },
			TW: { name: 'Taiwan, Province of China', code: 'TW' },
			TJ: { name: 'Tajikistan', code: 'TJ' },
			TZ: { name: 'Tanzania, United Republic of', code: 'TZ' },
			TH: { name: 'Thailand', code: 'TH' },
			TL: { name: 'Timor-Leste', code: 'TL' },
			TG: { name: 'Togo', code: 'TG' },
			TK: { name: 'Tokelau', code: 'TK' },
			TO: { name: 'Tonga', code: 'TO' },
			TT: { name: 'Trinidad and Tobago', code: 'TT' },
			TN: { name: 'Tunisia', code: 'TN' },
			TR: { name: 'Turkey', code: 'TR' },
			TM: { name: 'Turkmenistan', code: 'TM' },
			TC: { name: 'Turks and Caicos Islands', code: 'TC' },
			TV: { name: 'Tuvalu', code: 'TV' },
			UG: { name: 'Uganda', code: 'UG' },
			UA: { name: 'Ukraine', code: 'UA' },
			AE: { name: 'United Arab Emirates', code: 'AE' },
			GB: { name: 'United Kingdom', code: 'GB' },
			US: { name: 'United States', code: 'US' },
			UM: { name: 'United States Minor Outlying Islands', code: 'UM' },
			UY: { name: 'Uruguay', code: 'UY' },
			UZ: { name: 'Uzbekistan', code: 'UZ' },
			VU: { name: 'Vanuatu', code: 'VU' },
			VE: { name: 'Venezuela', code: 'VE' },
			VN: { name: 'Viet Nam', code: 'VN' },
			VG: { name: 'Virgin Islands, British', code: 'VG' },
			VI: { name: 'Virgin Islands, U.S.', code: 'VI' },
			WF: { name: 'Wallis and Futuna', code: 'WF' },
			EH: { name: 'Western Sahara', code: 'EH' },
			YE: { name: 'Yemen', code: 'YE' },
			ZM: { name: 'Zambia', code: 'ZM' },
			ZW: { name: 'Zimbabwe', code: 'ZW' }
		};

		var countries = {
			AF: { lang: 'countries.afghanistan', code: 'AF', id: 'AF' },
			AX: { lang: 'countries.alandIslands', code: 'AX', id: 'AX' },
			AL: { lang: 'countries.albania', code: 'AL', id: 'AL' },
			DZ: { lang: 'countries.algeria', code: 'DZ', id: 'DZ' },
			AS: { lang: 'countries.americanSamoa', code: 'AS', id: 'AS' },
			AD: { lang: 'countries.andorra', code: 'AD', id: 'AD' },
			AO: { lang: 'countries.angola', code: 'AO', id: 'AO' },
			AI: { lang: 'countries.anguilla', code: 'AI', id: 'AI' },
			AQ: { lang: 'countries.antarctica', code: 'AQ', id: 'AQ' },
			AG: { lang: 'countries.antiguaAndBarbuda', code: 'AG', id: 'AG' },
			AR: { lang: 'countries.argentina', code: 'AR', id: 'AR' },
			AM: { lang: 'countries.armenia', code: 'AM', id: 'AM' },
			AW: { lang: 'countries.aruba', code: 'AW', id: 'AW' },
			AU: { lang: 'countries.australia', code: 'AU', id: 'AU' },
			AT: { lang: 'countries.austria', code: 'AT', id: 'AT' },
			AZ: { lang: 'countries.azerbaijan', code: 'AZ', id: 'AZ' },
			BS: { lang: 'countries.bahamas', code: 'BS', id: 'BS' },
			BH: { lang: 'countries.bahrain', code: 'BH', id: 'BH' },
			BD: { lang: 'countries.bangladesh', code: 'BD', id: 'BD' },
			BB: { lang: 'countries.barbados', code: 'BB', id: 'BB' },
			BY: { lang: 'countries.belarus', code: 'BY', id: 'BY' },
			BE: { lang: 'countries.belgium', code: 'BE', id: 'BE' },
			BZ: { lang: 'countries.belize', code: 'BZ', id: 'BZ' },
			BJ: { lang: 'countries.benin', code: 'BJ', id: 'BJ' },
			BM: { lang: 'countries.bermuda', code: 'BM', id: 'BM' },
			BT: { lang: 'countries.bhutan', code: 'BT', id: 'BT' },
			BO: { lang: 'countries.bolivia', code: 'BO', id: 'BO' },
			BA: { lang: 'countries.bosniaAndHerzegovina', code: 'BA', id: 'BA' },
			BW: { lang: 'countries.botswana', code: 'BW', id: 'BW' },
			BR: { lang: 'countries.brazil', code: 'BR', id: 'BR' },
			IO: { lang: 'countries.britishIndianOceanTerritory', code: 'IO', id: 'IO' },
			BN: { lang: 'countries.bruneiDarussalam', code: 'BN', id: 'BN' },
			BG: { lang: 'countries.bulgaria', code: 'BG', id: 'BG' },
			BF: { lang: 'countries.burkinaFaso', code: 'BF', id: 'BF' },
			BI: { lang: 'countries.burundi', code: 'BI', id: 'BI' },
			KH: { lang: 'countries.cambodia', code: 'KH', id: 'KH' },
			CM: { lang: 'countries.cameroon', code: 'CM', id: 'CM' },
			CA: { lang: 'countries.canada', code: 'CA', id: 'CA' },
			CV: { lang: 'countries.capeVerde', code: 'CV', id: 'CV' },
			KY: { lang: 'countries.caymanIslands', code: 'KY', id: 'KY' },
			CI: { lang: 'countries.ivoryCoast', code: 'CI', id: 'CI' },
			CF: { lang: 'countries.centralAfricanRepublic', code: 'CF', id: 'CF' },
			TD: { lang: 'countries.chad', code: 'TD', id: 'TD' },
			CL: { lang: 'countries.chile', code: 'CL', id: 'CL' },
			CN: { lang: 'countries.china', code: 'CN', id: 'CN', language: 'language.chinese' },
			CX: { lang: 'countries.christmasIsland', code: 'CX', id: 'CX' },
			CC: { lang: 'countries.cocosKeelingIslands', code: 'CC', id: 'CC' },
			CO: { lang: 'countries.colombia', code: 'CO', id: 'CO' },
			KM: { lang: 'countries.comoros', code: 'KM', id: 'KM' },
			CD: { lang: 'countries.congo', code: 'CD', id: 'CD' },
			CK: { lang: 'countries.cookIslands', code: 'CK', id: 'CK' },
			CR: { lang: 'countries.costaRica', code: 'CR', id: 'CR' },
			HR: { lang: 'countries.croatia', code: 'HR', id: 'HR' },
			CU: { lang: 'countries.cuba', code: 'CU', id: 'CU' },
			CY: { lang: 'countries.cyprus', code: 'CY', id: 'CY' },
			CZ: { lang: 'countries.czechRepublic', code: 'CZ', id: 'CZ' },
			DK: { lang: 'countries.denmark', code: 'DK', id: 'DK', language: 'language.danish' },
			DJ: { lang: 'countries.djibouti', code: 'DJ', id: 'DJ' },
			DM: { lang: 'countries.dominica', code: 'DM', id: 'DM' },
			DO: { lang: 'countries.dominicanRepublic', code: 'DO', id: 'DO' },
			EC: { lang: 'countries.ecuador', code: 'EC', id: 'EC' },
			EG: { lang: 'countries.egypt', code: 'EG', id: 'EG' },
			SV: { lang: 'countries.elSalvador', code: 'SV', id: 'SV' },
			GQ: { lang: 'countries.equatorialGuinea', code: 'GQ', id: 'GQ' },
			ER: { lang: 'countries.eritrea', code: 'ER', id: 'ER' },
			EE: { lang: 'countries.estonia', code: 'EE', id: 'EE' },
			ET: { lang: 'countries.ethiopia', code: 'ET', id: 'ET' },
			FK: { lang: 'countries.falklandIslandsMalvinas', code: 'FK', id: 'FK' },
			FO: { lang: 'countries.faroeIslands', code: 'FO', id: 'FO' },
			FJ: { lang: 'countries.fiji', code: 'FJ', id: 'FJ' },
			FI: { lang: 'countries.finland', code: 'FI', id: 'FI', language: 'language.finish' },
			FR: { lang: 'countries.france', code: 'FR', id: 'FR' },
			PF: { lang: 'countries.frenchPolynesia', code: 'PF', id: 'PF' },
			TF: { lang: 'countries.frenchSouthernTerritories', code: 'TF', id: 'TF' },
			GA: { lang: 'countries.gabon', code: 'GA', id: 'GA' },
			GM: { lang: 'countries.gambia', code: 'GM', id: 'GM' },
			GE: { lang: 'countries.georgia', code: 'GE', id: 'GE' },
			DE: { lang: 'countries.germany', code: 'DE', id: 'DE', language: 'language.german' },
			GH: { lang: 'countries.ghana', code: 'GH', id: 'GH' },
			GI: { lang: 'countries.gibraltar', code: 'GI', id: 'GI' },
			GR: { lang: 'countries.greece', code: 'GR', id: 'GR' },
			GL: { lang: 'countries.greenland', code: 'GL', id: 'GL' },
			GD: { lang: 'countries.grenada', code: 'GD', id: 'GD' },
			GP: { lang: 'countries.guadeloupe', code: 'GP', id: 'GP' },
			GU: { lang: 'countries.guam', code: 'GU', id: 'GU' },
			GT: { lang: 'countries.guatemala', code: 'GT', id: 'GT' },
			GG: { lang: 'countries.guernsey', code: 'GG', id: 'GG' },
			GN: { lang: 'countries.guinea', code: 'GN', id: 'GN' },
			GW: { lang: 'countries.guineaBissau', code: 'GW', id: 'GW' },
			GY: { lang: 'countries.guyana', code: 'GY', id: 'GY' },
			HT: { lang: 'countries.haiti', code: 'HT', id: 'HT' },
			HM: { lang: 'countries.heardIslandAndMcdonaldIslands', code: 'HM', id: 'HM' },
			VA: { lang: 'countries.holySeeVaticanCityState', code: 'VA', id: 'VA' },
			HN: { lang: 'countries.honduras', code: 'HN', id: 'HN' },
			HK: { lang: 'countries.hongKong', code: 'HK', id: 'HK' },
			HU: { lang: 'countries.hungary', code: 'HU', id: 'HU' },
			IS: { lang: 'countries.iceland', code: 'IS', id: 'IS' },
			IN: { lang: 'countries.india', code: 'IN', id: 'IN' },
			ID: { lang: 'countries.indonesia', code: 'ID', id: 'ID' },
			IR: { lang: 'countries.iranIslamicRepublicOf', code: 'IR', id: 'IR' },
			IQ: { lang: 'countries.iraq', code: 'IQ', id: 'IQ' },
			IE: { lang: 'countries.ireland', code: 'IE', id: 'IE' },
			IM: { lang: 'countries.isleOfMan', code: 'IM', id: 'IM' },
			IL: { lang: 'countries.israel', code: 'IL', id: 'IL' },
			IT: { lang: 'countries.italy', code: 'IT', id: 'IT' },
			JM: { lang: 'countries.jamaica', code: 'JM', id: 'JM' },
			JP: { lang: 'countries.japan', code: 'JP', id: 'JP', language: 'language.japanese' },
			JE: { lang: 'countries.jersey', code: 'JE', id: 'JE' },
			JO: { lang: 'countries.jordan', code: 'JO', id: 'JO' },
			KZ: { lang: 'countries.kazakhstan', code: 'KZ', id: 'KZ' },
			KE: { lang: 'countries.kenya', code: 'KE', id: 'KE' },
			KI: { lang: 'countries.kiribati', code: 'KI', id: 'KI' },
			KP: { lang: 'countries.koreaDemocraticPeoplesRepublicOf', code: 'KP', id: 'KP' },
			KR: { lang: 'countries.koreaRepublicOf', code: 'KR', id: 'KR' },
			KW: { lang: 'countries.kuwait', code: 'KW', id: 'KW' },
			KG: { lang: 'countries.kyrgyzstan', code: 'KG', id: 'KG' },
			LA: { lang: 'countries.laoPeoplesDemocraticRepublic', code: 'LA', id: 'LA' },
			LV: { lang: 'countries.latvia', code: 'LV', id: 'LV' },
			LB: { lang: 'countries.lebanon', code: 'LB', id: 'LB' },
			LS: { lang: 'countries.lesotho', code: 'LS', id: 'LS' },
			LR: { lang: 'countries.liberia', code: 'LR', id: 'LR' },
			LY: { lang: 'countries.libya', code: 'LY', id: 'LY' },
			LI: { lang: 'countries.liechtenstein', code: 'LI', id: 'LI' },
			LT: { lang: 'countries.lithuania', code: 'LT', id: 'LT' },
			LU: { lang: 'countries.luxembourg', code: 'LU', id: 'LU' },
			MO: { lang: 'countries.macao', code: 'MO', id: 'MO' },
			MK: { lang: 'countries.macedoniaTheFormerYugoslavRepublicOf', code: 'MK', id: 'MK' },
			MG: { lang: 'countries.madagascar', code: 'MG', id: 'MG' },
			MW: { lang: 'countries.malawi', code: 'MW', id: 'MW' },
			MY: { lang: 'countries.malaysia', code: 'MY', id: 'MY' },
			MV: { lang: 'countries.maldives', code: 'MV', id: 'MV' },
			ML: { lang: 'countries.mali', code: 'ML', id: 'ML' },
			MT: { lang: 'countries.malta', code: 'MT', id: 'MT' },
			MH: { lang: 'countries.marshallIslands', code: 'MH', id: 'MH' },
			MQ: { lang: 'countries.martinique', code: 'MQ', id: 'MQ' },
			MR: { lang: 'countries.mauritania', code: 'MR', id: 'MR' },
			MU: { lang: 'countries.mauritius', code: 'MU', id: 'MU' },
			YT: { lang: 'countries.mayotte', code: 'YT', id: 'YT' },
			MX: { lang: 'countries.mexico', code: 'MX', id: 'MX' },
			FM: { lang: 'countries.micronesiaFederatedStatesOf', code: 'FM', id: 'FM' },
			MD: { lang: 'countries.moldovaRepublicOf', code: 'MD', id: 'MD' },
			MC: { lang: 'countries.monaco', code: 'MC', id: 'MC' },
			MN: { lang: 'countries.mongolia', code: 'MN', id: 'MN' },
			ME: { lang: 'countries.montenegro', code: 'ME', id: 'ME' },
			MS: { lang: 'countries.montserrat', code: 'MS', id: 'MS' },
			MA: { lang: 'countries.morocco', code: 'MA', id: 'MA' },
			MZ: { lang: 'countries.mozambique', code: 'MZ', id: 'MZ' },
			MM: { lang: 'countries.myanmar', code: 'MM', id: 'MM' },
			NA: { lang: 'countries.namibia', code: 'NA', id: 'NA' },
			NR: { lang: 'countries.nauru', code: 'NR', id: 'NR' },
			NP: { lang: 'countries.nepal', code: 'NP', id: 'NP' },
			NL: { lang: 'countries.netherlands', code: 'NL', id: 'NL', language: 'language.netherlands' },
			NC: { lang: 'countries.newCaledonia', code: 'NC', id: 'NC' },
			NZ: { lang: 'countries.newZealand', code: 'NZ', id: 'NZ' },
			NI: { lang: 'countries.nicaragua', code: 'NI', id: 'NI' },
			NE: { lang: 'countries.niger', code: 'NE', id: 'NE' },
			NG: { lang: 'countries.nigeria', code: 'NG', id: 'NG' },
			NU: { lang: 'countries.niue', code: 'NU', id: 'NU' },
			NFK: { lang: 'countries.norfolkIsland', code: 'NFK', id: 'NFK' },
			MP: { lang: 'countries.northernMarianaIslands', code: 'MP', id: 'MP' },
			NO: { lang: 'countries.norway', code: 'NO', id: 'NO', language: 'language.norweigan' },
			OM: { lang: 'countries.oman', code: 'OM', id: 'OM' },
			PK: { lang: 'countries.pakistan', code: 'PK', id: 'PK' },
			PW: { lang: 'countries.palau', code: 'PW', id: 'PW' },
			PAL: { lang: 'countries.palestinianTerritoryOccupied', code: 'PAL', id: 'PAL' },
			PA: { lang: 'countries.panama', code: 'PA', id: 'PA' },
			PG: { lang: 'countries.papuaNewGuinea', code: 'PG', id: 'PG' },
			PY: { lang: 'countries.paraguay', code: 'PY', id: 'PY' },
			PE: { lang: 'countries.peru', code: 'PE', id: 'PE' },
			PH: { lang: 'countries.philippines', code: 'PH', id: 'PH' },
			PN: { lang: 'countries.pitcairn', code: 'PN', id: 'PN' },
			PL: { lang: 'countries.poland', code: 'PL', id: 'PL', language: 'language.polish' },
			PT: { lang: 'countries.portugal', code: 'PT', id: 'PT', language: 'language.portuguese' },
			PR: { lang: 'countries.puertoRico', code: 'PR', id: 'PR' },
			QA: { lang: 'countries.qatar', code: 'QA', id: 'QA' },
			RE: { lang: 'countries.reunion', code: 'RE', id: 'RE' },
			RO: { lang: 'countries.romania', code: 'RO', id: 'RO' },
			RU: { lang: 'countries.russianFederation', code: 'RU', id: 'RU' },
			RW: { lang: 'countries.rwanda', code: 'RW', id: 'RW' },
			BL: { lang: 'countries.saintBarthelemy', code: 'BL', id: 'BL' },
			SH: { lang: 'countries.saintHelenaAscensionAndTristanDaCunha', code: 'SH', id: 'SH' },
			KN: { lang: 'countries.saintKittsAndNevis', code: 'KN', id: 'KN' },
			LC: { lang: 'countries.saintLucia', code: 'LC', id: 'LC' },
			MF: { lang: 'countries.saintMartinFrenchPart', code: 'MF', id: 'MF' },
			PM: { lang: 'countries.saintPierreAndMiquelon', code: 'PM', id: 'PM' },
			VC: { lang: 'countries.saintVincentAndTheGrenadines', code: 'VC', id: 'VC' },
			WS: { lang: 'countries.samoa', code: 'WS', id: 'WS' },
			SM: { lang: 'countries.sanMarino', code: 'SM', id: 'SM' },
			ST: { lang: 'countries.saoTomeAndPrincipe', code: 'ST', id: 'ST' },
			SA: { lang: 'countries.saudiArabia', code: 'SA', id: 'SA' },
			SN: { lang: 'countries.senegal', code: 'SN', id: 'SN' },
			RS: { lang: 'countries.serbia', code: 'RS', id: 'RS' },
			SC: { lang: 'countries.seychelles', code: 'SC', id: 'SC' },
			SL: { lang: 'countries.sierraLeone', code: 'SL', id: 'SL' },
			SG: { lang: 'countries.singapore', code: 'SG', id: 'SG' },
			SK: { lang: 'countries.slovakia', code: 'SK', id: 'SK' },
			SI: { lang: 'countries.slovenia', code: 'SI', id: 'SI' },
			SB: { lang: 'countries.solomonIslands', code: 'SB', id: 'SB' },
			SO: { lang: 'countries.somalia', code: 'SO', id: 'SO' },
			ZA: { lang: 'countries.southAfrica', code: 'ZA', id: 'ZA' },
			GS: { lang: 'countries.southGeorgiaAndTheSouthSandwichIslands', code: 'GS', id: 'GS' },
			ES: { lang: 'countries.spain', code: 'ES', id: 'ES', language: 'language.spanish' },
			LK: { lang: 'countries.sriLanka', code: 'LK', id: 'LK' },
			SD: { lang: 'countries.sudan', code: 'SD', id: 'SD' },
			SR: { lang: 'countries.suriname', code: 'SR', id: 'SR' },
			SJ: { lang: 'countries.svalbardAndJanMayen', code: 'SJ', id: 'SJ' },
			SZ: { lang: 'countries.swaziland', code: 'SZ', id: 'SZ' },
			SE: { lang: 'countries.sweden', code: 'SE', id: 'SE', language: 'language.swedish' },
			CH: { lang: 'countries.switzerland', code: 'CH', id: 'CH' },
			SY: { lang: 'countries.syrianArabRepublic', code: 'SY', id: 'SY' },
			TW: { lang: 'countries.taiwanProvinceOfChina', code: 'TW', id: 'TW' },
			TJ: { lang: 'countries.tajikistan', code: 'TJ', id: 'TJ' },
			TZ: { lang: 'countries.tanzaniaUnitedRepublicOf', code: 'TZ', id: 'TZ' },
			TH: { lang: 'countries.thailand', code: 'TH', id: 'TH' },
			TL: { lang: 'countries.timorLeste', code: 'TL', id: 'TL' },
			TG: { lang: 'countries.togo', code: 'TG', id: 'TG' },
			TK: { lang: 'countries.tokelau', code: 'TK', id: 'TK' },
			TO: { lang: 'countries.tonga', code: 'TO', id: 'TO' },
			TT: { lang: 'countries.trinidadAndTobago', code: 'TT', id: 'TT' },
			TN: { lang: 'countries.tunisia', code: 'TN', id: 'TN' },
			TR: { lang: 'countries.turkey', code: 'TR', id: 'TR' },
			TM: { lang: 'countries.turkmenistan', code: 'TM', id: 'TM' },
			TC: { lang: 'countries.turksAndCaicosIslands', code: 'TC', id: 'TC' },
			TV: { lang: 'countries.tuvalu', code: 'TV', id: 'TV' },
			UG: { lang: 'countries.uganda', code: 'UG', id: 'UG' },
			UA: { lang: 'countries.ukraine', code: 'UA', id: 'UA' },
			AE: { lang: 'countries.unitedArabEmirates', code: 'AE', id: 'AE' },
			GB: { lang: 'countries.unitedKingdom', code: 'GB', id: 'GB', language: 'language.english' },
			US: { lang: 'countries.unitedStates', code: 'US', id: 'US' },
			UM: { lang: 'countries.unitedStatesMinorOutlyingIslands', code: 'UM', id: 'UM' },
			UY: { lang: 'countries.uruguay', code: 'UY', id: 'UY' },
			UZ: { lang: 'countries.uzbekistan', code: 'UZ', id: 'UZ' },
			VU: { lang: 'countries.vanuatu', code: 'VU', id: 'VU' },
			VE: { lang: 'countries.venezuelaBolivarianRepublicOf', code: 'VE', id: 'VE' },
			VN: { lang: 'countries.vietNam', code: 'VN', id: 'VN' },
			VG: { lang: 'countries.virginIslandsBritish', code: 'VG', id: 'VG' },
			VI: { lang: 'countries.virginIslandsUs', code: 'VI', id: 'VI' },
			WF: { lang: 'countries.wallisAndFutuna', code: 'WF', id: 'WF' },
			EH: { lang: 'countries.westernSahara', code: 'EH', id: 'EH' },
			YE: { lang: 'countries.yemen', code: 'YE', id: 'YE' },
			ZM: { lang: 'countries.zambia', code: 'ZM', id: 'ZM' },
			ZW: { lang: 'countries.zimbabwe', code: 'ZW', id: 'ZW' }
		};

		var locales = {
			'en-AU': { locale: 'en-AU', country: 'AU', code: 'AU', language: 'en', lang: 'countries.australia' },
			'de-AT': { locale: 'de-AT', country: 'AT', code: 'AT', language: 'de', lang: 'countries.austria' },
			'pt-BR': { locale: 'pt-BR', country: 'BR', code: 'BR', language: 'pt', lang: 'countries.brazil' },
			'en-CA': { locale: 'en-CA', country: 'CA', code: 'CA', language: 'en', lang: 'countries.canada' },
			'zh-CN': { locale: 'zh-CN', country: 'CN', code: 'CN', language: 'zh', lang: 'countries.china' },
			'da-DK': { locale: 'da-DK', country: 'DK', code: 'DK', language: 'da', lang: 'countries.denmark' },
			'et-EE': { locale: 'et-EE', country: 'EE', code: 'EE', language: 'et', lang: 'countries.estonia' },
			'fi-FI': { locale: 'fi-FI', country: 'FI', code: 'FI', language: 'fi', lang: 'countries.finland' },
			'fr-FR': { locale: 'fr-FR', country: 'FR', code: 'FR', language: 'fr', lang: 'countries.france' },
			'de-DE': { locale: 'de-DE', country: 'DE', code: 'DE', language: 'de', lang: 'countries.germany' },
			'el-GR': { locale: 'el-GR', country: 'GR', code: 'GR', language: 'el', lang: 'countries.greece' },
			'zh-HK': { locale: 'zh-HK', country: 'HK', code: 'HK', language: 'zh', lang: 'countries.hongKong' },
			'hu-HU': { locale: 'hu-HU', country: 'HU', code: 'HU', language: 'hu', lang: 'countries.hungary' },
			'is-IS': { locale: 'is-IS', country: 'IS', code: 'IS', language: 'is', lang: 'countries.iceland' },
			'hi-IN': { locale: 'hi-IN', country: 'IN', code: 'IN', language: 'hi', lang: 'countries.india' },
			'en-IE': { locale: 'en-IE', country: 'IE', code: 'IE', language: 'en', lang: 'countries.ireland' },
			'he-IL': { locale: 'he-IL', country: 'IL', code: 'IL', language: 'he', lang: 'countries.israel' },
			'it-IT': { locale: 'it-IT', country: 'IT', code: 'IT', language: 'it', lang: 'countries.italy' },
			'ja-JP': { locale: 'ja-JP', country: 'JP', code: 'JP', language: 'ja', lang: 'countries.japan' },
			'ko-KR': { locale: 'ko-KR', country: 'KR', code: 'KR', language: 'ko', lang: 'countries.koreaRepublicOf' },
			'lv-LV': { locale: 'lv-LV', country: 'LV', code: 'LV', language: 'lv', lang: 'countries.latvia' },
			'lt-LT': { locale: 'lt-LT', country: 'LT', code: 'LT', language: 'lt', lang: 'countries.lithuania' },
			'es-MX': { locale: 'es-MX', country: 'MX', code: 'MX', language: 'es', lang: 'countries.mexico' },
			'nl-NL': { locale: 'nl-NL', country: 'NL', code: 'NL', language: 'nl', lang: 'countries.netherlands' },
			'en-NZ': { locale: 'en-NZ', country: 'NZ', code: 'NZ', language: 'en', lang: 'countries.newZealand' },
			'nb-NO': { locale: 'nb-NO', country: 'NO', code: 'NO', language: 'nb', lang: 'countries.norway' },
			'ru-RU': {
				locale: 'ru-RU',
				country: 'RU',
				code: 'RU',
				language: 'ru',
				lang: 'countries.russianFederation'
			},
			'af-ZA': { locale: 'af-ZA', country: 'ZA', code: 'ZA', language: 'af', lang: 'countries.southAfrica' },
			'es-ES': { locale: 'es-ES', country: 'ES', code: 'ES', language: 'es', lang: 'countries.spain' },
			'sv-SE': { locale: 'sv-SE', country: 'SE', code: 'SE', language: 'sv', lang: 'countries.sweden' },
			'de-CH': { locale: 'de-CH', country: 'CH', code: 'CH', language: 'de', lang: 'countries.switzerland' },
			'en-GB': { locale: 'en-GB', country: 'GB', code: 'GB', language: 'en', lang: 'countries.unitedKingdom' },
			'en-US': { locale: 'en-US', country: 'US', code: 'US', language: 'en', lang: 'countries.unitedStates' }
		};

		var zones = [
			'Africa/Abidjan',
			'Africa/Accra',
			'Africa/Addis_Ababa',
			'Africa/Algiers',
			'Africa/Asmara',
			'Africa/Bamako',
			'Africa/Bangui',
			'Africa/Banjul',
			'Africa/Bissau',
			'Africa/Blantyre',
			'Africa/Brazzaville',
			'Africa/Bujumbura',
			'Africa/Cairo',
			'Africa/Casablanca',
			'Africa/Ceuta',
			'Africa/Conakry',
			'Africa/Dakar',
			'Africa/Dar_es_Salaam',
			'Africa/Djibouti',
			'Africa/Douala',
			'Africa/El_Aaiun',
			'Africa/Freetown',
			'Africa/Gaborone',
			'Africa/Harare',
			'Africa/Johannesburg',
			'Africa/Juba',
			'Africa/Kampala',
			'Africa/Khartoum',
			'Africa/Kigali',
			'Africa/Kinshasa',
			'Africa/Lagos',
			'Africa/Libreville',
			'Africa/Lome',
			'Africa/Luanda',
			'Africa/Lubumbashi',
			'Africa/Lusaka',
			'Africa/Malabo',
			'Africa/Maputo',
			'Africa/Maseru',
			'Africa/Mbabane',
			'Africa/Mogadishu',
			'Africa/Monrovia',
			'Africa/Nairobi',
			'Africa/Ndjamena',
			'Africa/Niamey',
			'Africa/Nouakchott',
			'Africa/Ouagadougou',
			'Africa/Porto-Novo',
			'Africa/Sao_Tome',
			'Africa/Tripoli',
			'Africa/Tunis',
			'Africa/Windhoek',
			'America/Adak',
			'America/Anchorage',
			'America/Anguilla',
			'America/Antigua',
			'America/Araguaina',
			'America/Argentina/Buenos_Aires',
			'America/Argentina/Catamarca',
			'America/Argentina/Cordoba',
			'America/Argentina/Jujuy',
			'America/Argentina/La_Rioja',
			'America/Argentina/Mendoza',
			'America/Argentina/Rio_Gallegos',
			'America/Argentina/Salta',
			'America/Argentina/San_Juan',
			'America/Argentina/San_Luis',
			'America/Argentina/Tucuman',
			'America/Argentina/Ushuaia',
			'America/Aruba',
			'America/Asuncion',
			'America/Atikokan',
			'America/Bahia',
			'America/Bahia_Banderas',
			'America/Barbados',
			'America/Belem',
			'America/Belize',
			'America/Blanc-Sablon',
			'America/Boa_Vista',
			'America/Bogota',
			'America/Boise',
			'America/Cambridge_Bay',
			'America/Campo_Grande',
			'America/Cancun',
			'America/Caracas',
			'America/Cayenne',
			'America/Cayman',
			'America/Chicago',
			'America/Chihuahua',
			'America/Costa_Rica',
			'America/Creston',
			'America/Cuiaba',
			'America/Curacao',
			'America/Danmarkshavn',
			'America/Dawson',
			'America/Dawson_Creek',
			'America/Denver',
			'America/Detroit',
			'America/Dominica',
			'America/Edmonton',
			'America/Eirunepe',
			'America/El_Salvador',
			'America/Fortaleza',
			'America/Glace_Bay',
			'America/Godthab',
			'America/Goose_Bay',
			'America/Grand_Turk',
			'America/Grenada',
			'America/Guadeloupe',
			'America/Guatemala',
			'America/Guayaquil',
			'America/Guyana',
			'America/Halifax',
			'America/Havana',
			'America/Hermosillo',
			'America/Indiana/Indianapolis',
			'America/Indiana/Knox',
			'America/Indiana/Marengo',
			'America/Indiana/Petersburg',
			'America/Indiana/Tell_City',
			'America/Indiana/Vevay',
			'America/Indiana/Vincennes',
			'America/Indiana/Winamac',
			'America/Inuvik',
			'America/Iqaluit',
			'America/Jamaica',
			'America/Juneau',
			'America/Kentucky/Louisville',
			'America/Kentucky/Monticello',
			'America/La_Paz',
			'America/Lima',
			'America/Los_Angeles',
			'America/Maceio',
			'America/Managua',
			'America/Manaus',
			'America/Martinique',
			'America/Matamoros',
			'America/Mazatlan',
			'America/Menominee',
			'America/Merida',
			'America/Metlakatla',
			'America/Mexico_City',
			'America/Miquelon',
			'America/Moncton',
			'America/Monterrey',
			'America/Montevideo',
			'America/Montreal',
			'America/Montserrat',
			'America/Nassau',
			'America/New_York',
			'America/Nipigon',
			'America/Nome',
			'America/Noronha',
			'America/North_Dakota/Beulah',
			'America/North_Dakota/Center',
			'America/North_Dakota/New_Salem',
			'America/Ojinaga',
			'America/Panama',
			'America/Pangnirtung',
			'America/Paramaribo',
			'America/Phoenix',
			'America/Port-au-Prince',
			'America/Port_of_Spain',
			'America/Porto_Velho',
			'America/Puerto_Rico',
			'America/Rainy_River',
			'America/Rankin_Inlet',
			'America/Recife',
			'America/Regina',
			'America/Resolute',
			'America/Rio_Branco',
			'America/Santa_Isabel',
			'America/Santarem',
			'America/Santiago',
			'America/Santo_Domingo',
			'America/Sao_Paulo',
			'America/Scoresbysund',
			'America/Sitka',
			'America/St_Johns',
			'America/St_Kitts',
			'America/St_Lucia',
			'America/St_Thomas',
			'America/St_Vincent',
			'America/Swift_Current',
			'America/Tegucigalpa',
			'America/Thule',
			'America/Thunder_Bay',
			'America/Tijuana',
			'America/Toronto',
			'America/Tortola',
			'America/Vancouver',
			'America/Whitehorse',
			'America/Winnipeg',
			'America/Yakutat',
			'America/Yellowknife',
			'Antarctica/Casey',
			'Antarctica/Davis',
			'Antarctica/DumontDUrville',
			'Antarctica/Macquarie',
			'Antarctica/Mawson',
			'Antarctica/McMurdo',
			'Antarctica/Palmer',
			'Antarctica/Rothera',
			'Antarctica/Syowa',
			'Antarctica/Vostok',
			'Europe/Oslo',
			'Asia/Aden',
			'Asia/Almaty',
			'Asia/Amman',
			'Asia/Anadyr',
			'Asia/Aqtau',
			'Asia/Aqtobe',
			'Asia/Ashgabat',
			'Asia/Baghdad',
			'Asia/Bahrain',
			'Asia/Baku',
			'Asia/Bangkok',
			'Asia/Beirut',
			'Asia/Bishkek',
			'Asia/Brunei',
			'Asia/Choibalsan',
			'Asia/Chongqing',
			'Asia/Colombo',
			'Asia/Damascus',
			'Asia/Dhaka',
			'Asia/Dili',
			'Asia/Dubai',
			'Asia/Dushanbe',
			'Asia/Gaza',
			'Asia/Harbin',
			'Asia/Hebron',
			'Asia/Ho_Chi_Minh',
			'Asia/Hong_Kong',
			'Asia/Hovd',
			'Asia/Irkutsk',
			'Asia/Jakarta',
			'Asia/Jayapura',
			'Asia/Jerusalem',
			'Asia/Kabul',
			'Asia/Kamchatka',
			'Asia/Karachi',
			'Asia/Kashgar',
			'Asia/Kathmandu',
			'Asia/Khandyga',
			'Asia/Kolkata',
			'Asia/Krasnoyarsk',
			'Asia/Kuala_Lumpur',
			'Asia/Kuching',
			'Asia/Kuwait',
			'Asia/Macau',
			'Asia/Magadan',
			'Asia/Makassar',
			'Asia/Manila',
			'Asia/Muscat',
			'Asia/Nicosia',
			'Asia/Novokuznetsk',
			'Asia/Novosibirsk',
			'Asia/Omsk',
			'Asia/Oral',
			'Asia/Phnom_Penh',
			'Asia/Pontianak',
			'Asia/Pyongyang',
			'Asia/Qatar',
			'Asia/Qyzylorda',
			'Asia/Rangoon',
			'Asia/Riyadh',
			'Asia/Sakhalin',
			'Asia/Samarkand',
			'Asia/Seoul',
			'Asia/Shanghai',
			'Asia/Singapore',
			'Asia/Taipei',
			'Asia/Tashkent',
			'Asia/Tbilisi',
			'Asia/Tehran',
			'Asia/Thimphu',
			'Asia/Tokyo',
			'Asia/Ulaanbaatar',
			'Asia/Urumqi',
			'Asia/Ust-Nera',
			'Asia/Vientiane',
			'Asia/Vladivostok',
			'Asia/Yakutsk',
			'Asia/Yekaterinburg',
			'Asia/Yerevan',
			'Atlantic/Azores',
			'Atlantic/Bermuda',
			'Atlantic/Canary',
			'Atlantic/Cape_Verde',
			'Atlantic/Faroe',
			'Atlantic/Madeira',
			'Atlantic/Reykjavik',
			'Atlantic/South_Georgia',
			'Atlantic/St_Helena',
			'Atlantic/Stanley',
			'Australia/Adelaide',
			'Australia/Brisbane',
			'Australia/Broken_Hill',
			'Australia/Currie',
			'Australia/Darwin',
			'Australia/Eucla',
			'Australia/Hobart',
			'Australia/Lindeman',
			'Australia/Lord_Howe',
			'Australia/Melbourne',
			'Australia/Perth',
			'Australia/Sydney',
			'CET',
			'CST6CDT',
			'EET',
			'EST',
			'EST5EDT',
			'HST',
			'MET',
			'MST',
			'MST7MDT',
			'PST8PDT',
			'WET',
			'Etc/GMT',
			'Etc/GMT+1',
			'Etc/GMT+10',
			'Etc/GMT+11',
			'Etc/GMT+12',
			'Etc/GMT+2',
			'Etc/GMT+3',
			'Etc/GMT+4',
			'Etc/GMT+5',
			'Etc/GMT+6',
			'Etc/GMT+7',
			'Etc/GMT+8',
			'Etc/GMT+9',
			'Etc/GMT-1',
			'Etc/GMT-10',
			'Etc/GMT-11',
			'Etc/GMT-12',
			'Etc/GMT-13',
			'Etc/GMT-14',
			'Etc/GMT-2',
			'Etc/GMT-3',
			'Etc/GMT-4',
			'Etc/GMT-5',
			'Etc/GMT-6',
			'Etc/GMT-7',
			'Etc/GMT-8',
			'Etc/GMT-9',
			'Etc/UCT',
			'Etc/UTC',
			'Europe/Amsterdam',
			'Europe/Andorra',
			'Europe/Athens',
			'Europe/Belgrade',
			'Europe/Berlin',
			'Europe/Prague',
			'Europe/Brussels',
			'Europe/Bucharest',
			'Europe/Budapest',
			'Europe/Zurich',
			'Europe/Chisinau',
			'Europe/Copenhagen',
			'Europe/Dublin',
			'Europe/Gibraltar',
			'Europe/London',
			'Europe/Helsinki',
			'Europe/Istanbul',
			'Europe/Kaliningrad',
			'Europe/Kiev',
			'Europe/Lisbon',
			'Europe/Luxembourg',
			'Europe/Madrid',
			'Europe/Malta',
			'Europe/Minsk',
			'Europe/Monaco',
			'Europe/Moscow',
			'Europe/Paris',
			'Europe/Riga',
			'Europe/Rome',
			'Europe/Samara',
			'Europe/Simferopol',
			'Europe/Sofia',
			'Europe/Stockholm',
			'Europe/Tallinn',
			'Europe/Tirane',
			'Europe/Uzhgorod',
			'Europe/Vaduz',
			'Europe/Vienna',
			'Europe/Vilnius',
			'Europe/Volgograd',
			'Europe/Warsaw',
			'Europe/Zaporozhye',
			'Indian/Antananarivo',
			'Indian/Chagos',
			'Indian/Christmas',
			'Indian/Cocos',
			'Indian/Comoro',
			'Indian/Kerguelen',
			'Indian/Mahe',
			'Indian/Maldives',
			'Indian/Mauritius',
			'Indian/Mayotte',
			'Indian/Reunion',
			'Pacific/Apia',
			'Pacific/Auckland',
			'Pacific/Chatham',
			'Pacific/Chuuk',
			'Pacific/Easter',
			'Pacific/Efate',
			'Pacific/Enderbury',
			'Pacific/Fakaofo',
			'Pacific/Fiji',
			'Pacific/Funafuti',
			'Pacific/Galapagos',
			'Pacific/Gambier',
			'Pacific/Guadalcanal',
			'Pacific/Guam',
			'Pacific/Honolulu',
			'Pacific/Johnston',
			'Pacific/Kiritimati',
			'Pacific/Kosrae',
			'Pacific/Kwajalein',
			'Pacific/Majuro',
			'Pacific/Marquesas',
			'Pacific/Midway',
			'Pacific/Nauru',
			'Pacific/Niue',
			'Pacific/Norfolk',
			'Pacific/Noumea',
			'Pacific/Pago_Pago',
			'Pacific/Palau',
			'Pacific/Pitcairn',
			'Pacific/Pohnpei',
			'Pacific/Port_Moresby',
			'Pacific/Rarotonga',
			'Pacific/Saipan',
			'Pacific/Tahiti',
			'Pacific/Tarawa',
			'Pacific/Tongatapu',
			'Pacific/Wake',
			'Pacific/Wallis'
		];

		var instance = {};

		instance.zones = zones;

		instance.all = function () {
			return Object.values(codes);
		};

		instance.customer = function () {
			return instance;
		};

		instance.get = function (code) {
			return codes[code];
		};

		instance.getCountry = function (code) {
			return countries[code];
		};

		instance.getLocale = function (locale) {
			return locales[locale] || locales['sv-SE'];
		};

		instance.find = function () {
			return $q.when({ data: Object.values(countries) });
		};

		instance.countries = function () {
			return Object.values(countries);
		};

		instance.locales = function () {
			return Object.values(locales);
		};

		return instance;
	}
]);

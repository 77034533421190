import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { Icon, Title, Text, EllipsisTooltip, Tooltip } from '@upsales/components';
import ClientPlanListElement from 'App/components/ClientPlanListElement';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import ClientPlanAttributes from 'App/babel/attributes/ClientPlan';
import ClientPlanResource from 'App/babel/resources/ClientPlan';
import { useTranslation } from 'Components/Helpers/translate';
import ClientPlanType from 'App/resources/Model/ClientPlan';
import React, { useEffect, useRef, useState } from 'react';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import moment from 'moment';

import './AccountPlanSidebar.scss';

type Props = {
	clientName: string;
	clientId: number;
	plans: ClientPlanType[];
	inEditOrder?: boolean;
	userEditable: boolean;
};

const AccountPlanSidebar = ({
	clientName,
	clientId,
	plans: initialPlans = [],
	inEditOrder = false,
	userEditable
}: Props) => {
	const { t } = useTranslation();
	const bemClassName = inEditOrder ? 'AccountPlanSidebarInEditOrder' : 'AccountPlanSidebar';
	const classes = new BemClass(bemClassName);
	const [plans, setPlans] = useState<ClientPlanType[]>(initialPlans);
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	const [maxHeight, setMaxHeight] = useState<number>(0);
	const promiseRef = useRef<CancelablePromise | null>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const hasPlans = plans.length > 0;
	const title = plans.length > 1 ? t('accountPlans') : t('accountPlan');

	useEffect(() => {
		setPlans(initialPlans);
	}, [initialPlans]);

	useEffect(() => {
		setMaxHeight(containerRef.current?.getBoundingClientRect().height ?? 0);
	}, [plans]);

	useEffect(() => {
		const fetchPlans = () => {
			const filter = new RequestBuilder();
			filter.addFilter(ClientPlanAttributes.id, comparisonTypes.Equals, clientId);
			filter.addFilter(ClientPlanAttributes.status, comparisonTypes.Equals, null);
			promiseRef.current = makeCancelable(ClientPlanResource.find(filter.build()));
			promiseRef.current.promise
				.then(({ data }: { data: ClientPlanType[] }) => {
					setPlans(data);
				})
				.catch((err: Error) => logError(err));
		};
		const removeDeleteListener = Tools.$rootScope.$on('contact.deleted', fetchPlans);
		const removeStatusListener = Tools.$rootScope.$on('clientPlan.statusChanged', (e, update) => {
			setPlans(old => old.filter(p => p.id !== update.id));
		});
		const removeUpdateListener = Tools.$rootScope.$on('clientPlan.updated', (e, update) => {
			setPlans(old => old.map(p => (p.id === update.id ? update : p)));
		});
		const removeAddListener = Tools.$rootScope.$on('clientPlan.added', (e, added) => {
			setPlans(old => [...old, added]);
		});
		return () => {
			promiseRef.current?.cancel?.();
			removeDeleteListener();
			removeStatusListener();
			removeUpdateListener();
			removeAddListener();
		};
	}, []);

	const openPlanModal = () =>
		openModal('EditAccountPlan', {
			clientName,
			clientId,
			cameFrom: 'clientCard',
			userEditable
		});

	return (
		<div className={classes.b()}>
			<div className={classes.elem('addPlan').b()}>
				<Title className={classes.elem('addPlan').elem('title').b()}>{title}</Title>
				{userEditable ? (
					<Tooltip title={hasPlans ? t('accountPlan.add') : t('accountPlan.create')}>
						<Icon name="plus" onClick={openPlanModal} />
					</Tooltip>
				) : null}
				{hasPlans ? (
					<Tooltip title={!isExpanded ? t('default.showMore') : t('default.showLess')}>
						<Icon
							name={!isExpanded ? 'chevron-down' : 'chevron-up'}
							onClick={() => setIsExpanded(old => !old)}
						/>
					</Tooltip>
				) : null}
			</div>
			<SlideFade visible={isExpanded} direction="top" height maxHeight={maxHeight}>
				<div className={classes.elem('plansCollapsible').b()}>
					<div ref={containerRef}>
						{plans
							.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
							.map(plan => {
								const isLate = moment(plan.date).isBefore(moment(), 'day');
								const dateComponent = (
									<Text size="sm" color={isLate ? 'red' : undefined}>
										{moment(plan.date).format('L')}
									</Text>
								);
								return (
									<div
										className={classes.elem('plan').b()}
										key={'plan' + plan.id}
										onClick={() =>
											openModal('EditAccountPlan', {
												clientName,
												clientId,
												plan,
												cameFrom: 'clientCard',
												userEditable
											})
										}
									>
										<div className={classes.elem('plan').elem('info').b()}>
											<div className={classes.elem('plan').elem('info').elem('name').b()}>
												<EllipsisTooltip title={plan.description}>
													<Text>{plan.description}</Text>
												</EllipsisTooltip>
												<Icon name="pencil" />
											</div>
											<div className={classes.elem('plan').elem('info').elem('valueAndDate').b()}>
												<ClientPlanListElement plan={plan} size={inEditOrder ? 'md' : 'sm'} />
												{inEditOrder ? null : dateComponent}
											</div>
											{inEditOrder ? dateComponent : null}
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</SlideFade>
		</div>
	);
};

export default AccountPlanSidebar;

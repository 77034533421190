import store from 'Store';
import { setOrder } from 'Store/reducers/PeriodizationReducer';

angular.module('upDirectives').directive('upPeriodiseRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			var root = $element[0];
			var hasPeriodization = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PERIODIZATION);

			if (root && hasPeriodization) {
				/*
					If someone wonders why I dispatch setOrder here: 
						ui/app/babel/components/Periodization/index.js was built a little bit wierd 
						it had a mapStateToProps with {order: state.Periodization.order} and then it dispatched 
						setOrder with this.props order. So it basicly sat the order in the reducer to the value it 
						allready had. The reason it worked from the edit order was that periodization-chart also did setOrder
						and it was rendered before periodization in editOrder.html. However this is no longer the case when i open the modal with 
						the periodization showing from start. I could not be bothered to rewrite the window.Periodization to handle 
						the case when this.props.order === undefined so I took the easy way out and just set it here instead of in the constructor. 
				*/
				const order = $scope.$eval($attr.order);
				store.dispatch(setOrder(order));

				window.SetupComponent(
					$scope,
					window.Periodization,
					root,
					{
						toggle: $scope.$eval($attr.toggle)
					},
					{ redux: true }
				);
			}
		}
	};
});

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('User', [
	'$resource',
	'$q',
	'UserAttributes',
	'URL',
	'API',
	'AppService',
	'Self',
	'$rootScope',
	'ResourceHelper',
	function User($resource, $q, UserAttributes, URL, API, AppService, Self, $rootScope, ResourceHelper) {
		var Attribute = UserAttributes().getAll();
		var Resource = $resource(
			URL + API + 'users/:id',
			{},
			{
				query: { method: 'GET', isArray: false },
				save: { method: 'POST', isArray: false, url: URL + API + 'master/users/' },
				update: { method: 'PUT', isArray: false, url: URL + API + 'master/users/:id' },
				getMasteruser: { method: 'GET', isArray: false, url: URL + API + 'master/users/:id' },
				updateUserParams: { method: 'POST', isArray: false, url: URL + API + 'master/userParam' },
				inviteUsers: { method: 'POST', isArray: false, url: URL + API + 'userInvites' },
				getInvites: { method: 'GET', isArray: false, url: URL + API + 'userInvites/' },
				removeInvite: { method: 'DELETE', isArray: false, url: URL + API + 'userInvites/:id' }
			}
		);

		var helper = new ResourceHelper();
		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'user',
			createSuccessBody: 'saved.user',
			updateSuccessBody: 'saved.user',
			deleteSucccessBody: 'deleted.user',
			createErrorBody: 'saveError.user',
			updateErrorBody: error => {
				if (error && error.key === 'NoAdminLeft') {
					return 'error.noAdminLeft';
				}
				if (error && error.key === 'NoBillingAdminLeft') {
					return 'error.noBillingAdminLeft';
				}
				if (error && error.metadata && error.metadata.key === 'OldPasswordIncorrect') {
					return 'error.oldPasswordIncorrect';
				}
				if (error && error.key === 'NotEnoughSupportLicenses') {
					return 'user.notEnoughSupportLicenses';
				}
				if (error && error.key === 'NotEnoughLicenses') {
					return 'user.notEnoughLicenses';
				}
				if (error && error.key === 'AdminRequiresCrm') {
					return 'user.adminRequiresCrm';
				}
				return 'saveError.user';
			},
			deleteErrorBody: 'deleteError.user'
		});

		var Model = {
			getMasteruser: function (id) {
				return Resource.getMasteruser({ id: id }).$promise;
			},
			getMap: function () {
				return Resource.get({ id: 'all' }).$promise;
			},
			updateUserParams: function (obj) {
				return Resource.updateUserParams({}, obj).$promise;
			},
			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.query(params).$promise;
			},
			update: function (data) {
				var defer = $q.defer();

				var promise = Resource.update({ id: data.id }, data).$promise;

				var self = AppService.getSelf();
				// var customerId = AppService.getCustomerId();
				promise
					.then(function (res) {
						if (data.id === self.id) {
							// Self.getCustomerSelf(customerId).then(function(selfRes) {
							$rootScope.$broadcast('self.updated', _.merge(self, res.data));
							defer.resolve(res);
							// }).catch(defer.reject);
						} else {
							var userMap = Tools.AppService.getUserMap();
							const nextUserMap = Object.keys(userMap).reduce((result, type) => {
								let foundInTypeIndex = -1;
								result[type] = userMap[type].map((user, i) => {
									if (user.id === data.id) {
										foundInTypeIndex = i;
										return _.merge(user, res.data);
									} else {
										return user;
									}
								});

								const addOrRemoveUserFromArray = (arr, user, currentlyInArrayIndex, isMatching) => {
									if (isMatching && currentlyInArrayIndex === -1) {
										return [...arr, user];
									} else if (!isMatching && currentlyInArrayIndex !== -1) {
										return arr.toSpliced(currentlyInArrayIndex, 1);
									}
									// else: no change
									return arr;
								};
								// Lets make sure that user is added/removed from the map if it now matches/doesn't match the criteria of the map
								// See api/src/controller/customer/user.js for the logic
								if (type === 'deleted') {
									result[type] = addOrRemoveUserFromArray(
										result[type],
										res.data,
										foundInTypeIndex,
										!res.data.active && !res.data.ghost
									);
								} else if (type === 'active') {
									result[type] = addOrRemoveUserFromArray(
										result[type],
										res.data,
										foundInTypeIndex,
										res.data.active
									);
								} else if (type === 'inactive') {
									result[type] = addOrRemoveUserFromArray(
										result[type],
										res.data,
										foundInTypeIndex,
										res.data.ghost
									);
								} else {
									// Type is one of the different accessTypes and should only contain active or ghost users. At the moment they are the following:
									// client, activity, project, report, opportunity, contact, order, document, userdefobj1, userdefobj2, userdefobj3, userdefobj4, exchange, agreement, clientquotas, appointment
									result[type] = addOrRemoveUserFromArray(
										result[type],
										res.data,
										foundInTypeIndex,
										res.data.active || res.data.ghost
									);
								}

								return result;
							}, {});

							Tools.AppService.setUserMap(nextUserMap);
							AllIWantDataCache.clearData();

							setTimeout(function () {
								defer.resolve(res);
							}, 0);
						}
					})
					.catch(defer.reject);

				defer.promise
					.then(function (res) {
						helper.onSave({}, res.data, !data.id);
					})
					.catch(function (res) {
						helper.onSaveError({}, !data.id, res.data.error);
					});

				return defer.promise;
			},
			save: function (data) {
				return Resource.save(data).$promise;
			},
			inviteUsers: function (users) {
				return Resource.inviteUsers({}, users).$promise;
			},
			getInvites: function () {
				return Resource.getInvites().$promise;
			},
			removeInvite: function (id) {
				return Resource.removeInvite({ id: id }).$promise;
			},
			attr: Attribute
		};

		return Model;
	}
]);

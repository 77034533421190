import { DocumentTemplate } from 'Components/DocumentTemplateEditor/DocumentTemplateEditor';
import Resource from './Resource';

type PreviewMetaType = {
	useDiscount: boolean;
	showProductDescription: boolean;
};

class pdfTemplate extends Resource {
	constructor() {
		super('pdftemplatess');
	}

	getPdf(
		templateId: number,
		orderId: number,
		translateTo = 'en',
		isCustom = false,
		isSkeleton = false,
		previewMeta: PreviewMetaType,
		template: DocumentTemplate
	) {
		return this._postRequest(`${templateId}/${orderId}`, {
			methodName: 'getPdf',
			translateTo: translateTo,
			isCustom: isCustom,
			isSkeleton: isSkeleton,
			previewMeta: previewMeta,
			template: template
		});
	}
}

const resource = new pdfTemplate();

export default resource;

import Resource from './Resource';
import Attributes from 'Attributes/JourneyStepHistory';

class JourneyStepHistory extends Resource {
	constructor() {
		super('journeyStepHistory', Attributes);
		this.dateFields = ['date'];
	}

	find(id, type) {
		return this._getRequest(`${type}/${id}`, { methodName: 'findPages' }).then(res => res.data.data);
	}
}

const resource = new JourneyStepHistory();
export default resource;

import AdminHeader from 'Components/Admin/AdminHeader';
import { Button } from '@upsales/components';

ReactTemplates.admin.marketRejectlist = window.ReactCreateClass({
	getInitialState: function () {
		return {
			searchStr: '',
			sortAscending: true
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			rejectList: t('default.rejectList'),
			rejectListDescription: t('default.rejectListInfo'),
			addAccount: t('default.addAccount'),
			name: t('default.name'),
			noAccounts: t('default.noAccounts'),
			search: t('default.search')
		};
	},
	removeAccount: function (key, e) {
		e.stopPropagation();
		this.props.rootData.removeFromRejectlist(key);
	},
	onSearch: function (e) {
		this.setState({ searchStr: e.target.value });
	},
	renderTableHeader: function (title, attr, style, icon) {
		var classNames = '';
		var arrow = null;
		var currentSorting = this.props.rootData.pageData.currentSorting;

		classNames += 'sortable';
		if (attr === currentSorting.attribute) {
			classNames += ' active';

			arrow = <b className={'fa fa-sort-' + icon + (currentSorting.ascending ? '-asc' : '-desc')} />;
		} else {
			arrow = <b className="fa fa-sort" />;
		}

		return (
			<th style={style || null} className={classNames} onClick={attr ? this.onSort.bind(this, attr) : null}>
				{title} {arrow}
			</th>
		);
	},
	onSort: function () {
		this.setState({
			sortAscending: !this.state.sortAscending
		});
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;

		var rejectlist = _.chain(rootData.pageData.rejectlist)
			.filter(function (account) {
				var name = (account.name || '').toLowerCase();
				var searchStr = self.state.searchStr.toLowerCase();
				return !self.state.searchStr || name.indexOf(searchStr) > -1;
			})
			.sortBy('name');

		if (!this.state.sortAscending) {
			rejectlist = rejectlist.reverse();
		}

		rejectlist = rejectlist
			.map(function (account, i) {
				return (
					<tr key={'account-' + i}>
						<td>{account.name}</td>
						<td className="admin-row-tools-wrap">
							<div className="admin-row-tools">
								<button
									className="up-btn btn-link btn-grey delete-trigger"
									onClick={self.removeAccount.bind(self, account)}
								>
									<b className="fa fa-trash-o"></b>
								</button>
							</div>
						</td>
					</tr>
				);
			})
			.value();

		return (
			<div id="admin-page-market-reject-list">
				<AdminHeader
					title={this.lang.rejectList}
					description={this.lang.rejectListDescription}
					image="market-reject-list.svg"
					articleId={469}
				/>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{self.lang.rejectList}</span>
							<div className="pull-right add-account-wrap">
								<Button onClick={rootData.addAccount} size="sm">
									{self.lang.addAccount}
								</Button>
							</div>
							<div className="pull-right quick-search-wrap">
								<div className="table-search">
									<input
										type="text"
										value={self.state.searchStr}
										onChange={self.onSearch}
										placeholder={self.lang.search}
									/>
									<b className="fa fa-search"></b>
								</div>
							</div>
						</div>

						{rejectlist.length ? (
							<table>
								<thead>
									<tr>
										<th className="sortable active" onClick={this.onSort}>
											{self.lang.name}{' '}
											<b
												className={
													'fa fa-sort-alpha-' + (this.state.sortAscending ? 'asc' : 'desc')
												}
											/>
										</th>
										<th style={{ width: '60px' }}></th>
									</tr>
								</thead>
								<tbody>{rejectlist}</tbody>
							</table>
						) : null}
						{!rejectlist.length ? <div className="table-no-result">{self.lang.noAccounts}</div> : null}
					</div>
				</div>
			</div>
		);
	}
});

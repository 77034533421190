import UserInvite from 'Components/UserInvite/UserInvite';

angular.module('domain.invite', []).config([
	'$stateProvider',
	function ($stateProvider) {
		$stateProvider.state('invite', {
			url: '/invite',
			template: '<div id="userinvite-holder"></div>',
			controller: function ($scope, $element, meta, security, CacheService) {
				window.SetupComponent(
					$scope,
					UserInvite,
					$element[0],
					{
						meta: meta,
						security: security,
						CacheService: CacheService
					},
					{ redux: false, ignoreAppService: true }
				);
			},
			resolve: {
				meta: [
					'InviteMeta',
					function (InviteMeta) {
						return InviteMeta();
					}
				]
			}
		});
	}
]);

'use strict';

var optinSelectDir = 'upOptins';
angular
	.module('upDirectives')
	.directive(optinSelectDir, function (OptIn, selectHelper) {
		return selectHelper.getDirectiveObject(optinSelectDir, {
			resource: OptIn,
			ajax: true,
			asIds: false,
			idAttr: OptIn.attr.id,
			titleAttr: OptIn.attr.title,
			addEvent: 'OptIn.added',
			sorting: { field: OptIn.attr.title, ascending: true },
			filterType: 'OptIn',
			filters: function (rb, $attrs) {
				if ($attrs.optinType === 'manual') {
					rb.addFilter(OptIn.attr.type, rb.comparisonTypes.Equals, $attrs.optinType);
				}
			}
		});
	});

'use strict';

angular.module('upResources').factory('Document', [
	'$resource',
	'URL',
	'API',
	function Document($resource, URL, API) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var Document = null;
				var baseUrl = URL + API + customer + '/:type/:entityId/documents/';

				Document = $resource(
					baseUrl + ':templateId',
					{},
					{
						get: { method: 'GET' }
					}
				);

				instance.get = function (entityId, templateId) {
					if (!instance.type) {
						throw new Error('No type set for document resource');
					}
					var params = {};
					params.type = instance.type;
					params.entityId = entityId;
					params.templateId = templateId;
					instance.type = null;

					return Document.get(params).$promise.then(
						function (response) {
							return response;
						},
						function (response) {
							return response.status;
						}
					);
				};

				instance.getUrl = function (entityId, templateId, fileFormat) {
					if (!instance.type) {
						throw new Error('No type set for document resource');
					}
					var type = instance.type;
					instance.type = null;
					fileFormat = fileFormat || 'pdf';
					var contactParam = instance.contactId ? '&contactId=' + instance.contactId : '';

					return (
						URL +
						API +
						customer +
						'/' +
						type +
						'/' +
						entityId +
						'/documents/' +
						templateId +
						'?fileFormat=' +
						fileFormat +
						contactParam
					);
				};

				instance.setContactId = function (contactId) {
					if (contactId) {
						instance.contactId = contactId;
					}
					return instance;
				};

				instance.setType = function (type) {
					switch (type) {
						case 'client':
						case 'account':
							instance.type = 'accounts';
							break;
						case 'order':
							instance.type = 'order';
							break;
						case 'agreement':
							instance.type = 'agreement';
							break;
						case 'activity':
							instance.type = 'activities';
							break;
						case 'appointment':
							instance.type = 'appointments';
							break;
						case 'object1':
						case 'object2':
						case 'object3':
						case 'object4':
							instance.type = type;
							break;
					}
					return instance;
				};

				return instance;
			},
			fileTypes: {
				PDF: 'pdf',
				DOC: 'doc'
			}
		};

		return Model;
	}
]);

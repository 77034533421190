import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import GenericHref from '../../../Helpers/Hrefs/GenericHref';
import { Icon, Tooltip } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';

import './Name.scss';

const propTypes = {
	accountId: PropTypes.number.isRequired,
	contact: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired
};

const defaultProps = {
	accountId: -1,
	contact: {},
	client: {}
};

class Name extends React.Component {
	constructor(props) {
		super(props);

		const standardFields = Tools.AppService.getMetadata().standardFields.Contact;
		const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		this.showSalutations = hasNewFields && standardFields.Salutation && standardFields.Salutation.active;
	}

	shouldComponentUpdate(newProps) {
		const { contact } = this.props;
		const { contact: newContact } = newProps;
		if (contact.name !== newContact.name) {
			return true;
		} else if (contact.title !== newContact.title) {
			return true;
		} else if (JSON.stringify(contact.titleCategory) !== JSON.stringify(newContact.titleCategory)) {
			return true;
		} else if (JSON.stringify(contact.salutation) !== JSON.stringify(newContact.salutation)) {
			return true;
		}
		return false;
	}

	render() {
		const {
			contact: {
				name: contactName,
				id: contactId,
				title: contactTitle,
				titleCategory,
				relatedAccount,
				salutation
			},
			client,
			accountId
		} = this.props;
		const nameText = this.showSalutations && salutation ? `${salutation.value} ${contactName}` : contactName;
		const hasSoftDeployContactTitleCategoryAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		const classNames = new Bem('AccountListContacts');
		const isTitleCategory = hasSoftDeployContactTitleCategoryAccess && titleCategory && titleCategory.value;

		return (
			<div
				className={classNames
					.elem('Name')
					.mod({ hasTitle: !!contactTitle || !!isTitleCategory })
					.b()}
			>
				<span>
					{accountId !== client.id && !client.operationalAccount?.id ? (
						<Tooltip title={`${Tools.$translate('contact.belongsTo')} ${relatedAccount?.name}`}>
							<Icon name="sitemap" />
						</Tooltip>
					) : null}
					<GenericHref type="contact" text={nameText} id={contactId} />
				</span>
				<div className="Name__titles">
					{titleCategory && hasSoftDeployContactTitleCategoryAccess ? (
						<Fragment>
							<span className="Name__subtitle">{titleCategory.value} </span>
							<span className="Name__subtitle Name__subtitle--category">
								{contactTitle ? `(${contactTitle})` : null}
							</span>
						</Fragment>
					) : (
						<span className="Name__subtitle">{contactTitle ? contactTitle : null}</span>
					)}
				</div>
			</div>
		);
	}
}

Name.propTypes = propTypes;
Name.defaultProps = defaultProps;
export default Name;

'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';

angular.module('services.customer', ['upResources']).factory('EventService', function () {
	var EventService = {
		extend: {},
		create: {
			Activity: function (activity) {
				return EventService.extend.Activity(newEvent(), activity);
			},
			Contact: function (contact) {
				return EventService.extend.Contact(newEvent(), contact);
			},
			Order: function (order) {
				return EventService.extend.Order(newEvent(), order);
			},
			Client: function (client) {
				return EventService.extend.Client(newEvent(), client);
			},
			Agreement: function (client) {
				return EventService.extend.Agreement(newEvent(), client);
			},
			Mail: function (client) {
				return EventService.extend.Mail(newEvent(), client);
			},
			Manual: function (client) {
				return EventService.extend.Manual(newEvent(), client);
			},
			Esign: function (esign) {
				return EventService.extend.Esign(newEvent(), esign);
			},
			Lead: function (lead) {
				return EventService.extend.Lead(newEvent(), lead);
			},
			Comment: function (comment) {
				return EventService.extend.Comment(newEvent(), comment);
			},
			News: function (news) {
				return EventService.extend.News(newEvent(), news);
			},
			ClientPlan: function (plan, subType) {
				return EventService.extend.ClientPlan(newEvent(), plan, subType);
			},
			Ticket: function (ticket, subType) {
				return EventService.extend.Ticket(newEvent(), ticket, subType);
			}
		}
	};

	function newEvent() {
		return {
			client: null,
			contact: null,
			date: moment().format(),
			entityId: 0,
			entityType: '',
			score: 0,
			subType: '',
			user: null,
			opportunityId: 0,
			form: null,
			value: ''
		};
	}

	EventService.extend.Mail = function (event, mail) {
		event.client = mail.client;
		event.contacts = [mail.contact];
		event.entityId = mail.id;
		event.users = mail.users;

		event.entityType = 'Mail';
		event.subType = 'send';
		event.mail = mail;

		return event;
	};

	EventService.extend.Esign = function (event, esign) {
		event.client = esign.client;
		// event.contacts = [esign.contact];
		event.entityId = esign.id;
		event.users = [esign.user];

		event.entityType = 'Esign';
		event.subType = 'Sent';
		event.esign = esign;

		return event;
	};

	EventService.extend.Manual = function (event, manualEvent) {
		event.client = manualEvent.client;
		event.contacts = [manualEvent.contact];
		event.entityId = 0;
		event.users = manualEvent.users;
		event.score = manualEvent.score;
		event.entityType = manualEvent.entityType;
		event.subType = manualEvent.subType;
		event.value = manualEvent.value;

		return event;
	};

	EventService.extend.Activity = function (event, activity) {
		event.client = activity.client;
		event.contacts = activity.contacts;
		event.entityId = activity.id;
		event.users = activity.users;

		if (activity.isAppointment) {
			event.date = new Date(activity.date);
			event.entityType = 'Appointment';
			event.subType = moment().isAfter(activity.date) ? 'Passed' : 'Created';
			event.appointment = {};
			event.appointment.id = activity.id;
			event.appointment.activityType = activity.activityType;
			event.appointment.description = activity.description;
			event.appointment.outcome = activity.outcome;
			event.appointment.notes = activity.notes;
		} else {
			event.entityType = 'Activity';
			event.subType = activity.closeDate ? 'Closed' : 'Created';
			event.activity = {};
			event.activity.id = activity.id;
			event.activity.activityType = activity.activityType;
			event.activity.description = activity.description;
			event.activity.hasHighPriority = activity.priority === ACTIVITY_PRIORITY.HIGH;
			event.activity.notes = activity.notes;
			if (activity.closeDate) {
				event.date = activity.closeDate;
			} else {
				event.date = activity.date;
			}
		}

		return event;
	};

	EventService.extend.Contact = function (event, contact) {
		event.client = contact.client;

		event.contacts = [
			{
				id: contact.id,
				name: contact.name,
				email: contact.email
			}
		];

		event.date = new Date(contact.regDate);
		event.entityId = contact.id;
		event.entityType = 'Contact';
		event.subType = 'Created';

		if (contact.regBy) {
			event.users = [contact.regBy];
		} else {
			event.users = [];
		}

		return event;
	};

	EventService.extend.Order = function (event, order) {
		event.client = order.client;

		if (order.contact) {
			event.contacts = [order.contact];
		}

		event.entityId = order.id;
		event.entityType = 'Opportunity';
		event.subType = 'Created';

		// eslint-disable-next-line eqeqeq
		if (order.probability == 100) {
			event.subType = 'Won';
			event.entityType = 'Order';
			// eslint-disable-next-line eqeqeq
		} else if (order.probability == 0) {
			event.subType = 'Lost';
			event.entityType = 'Order';
		}

		if (event.entityType === 'Order') {
			var time = moment(order.modDate).format('HH:mm:ss');
			event.date = new Date(moment(order.closeDate).format('YYYY-MM-DD ' + time));
		} else {
			event.date = new Date(order.regDate);
		}

		if (order.user) {
			event.users = [order.user];
		} else {
			event.users = [];
		}

		event.opportunity = {
			...order,
			orderValue: order.value
		};

		return event;
	};

	EventService.extend.Client = function (event, client) {
		event.client = client;

		event.date = new Date(client.regDate);
		event.entityId = client.id;
		event.entityType = 'Client';
		event.subType = 'Created';

		if (client.regBy) {
			event.users = [client.regBy];
		} else {
			event.users = [];
		}

		return event;
	};

	EventService.extend.Lead = function (event, client) {
		event.client = client;
		event.date = new Date();
		if (client.assigned) {
			event.users = [client.assigned.user];
		}
		event.entityId = 0;
		event.entityType = 'Lead';
		event.subType = 'Created';
		event.value = client.name;
		return event;
	};

	EventService.extend.Agreement = function (event, agreement) {
		event.client = agreement.client;

		if (agreement.contact) {
			event.contacts = [agreement.contact];
		}

		event.entityId = agreement.id;
		event.entityType = 'Agreement';
		event.subType = 'Created';
		event.date = new Date(agreement.regDate);

		if (agreement.user) {
			event.users = [agreement.user];
		} else {
			event.users = [];
		}
		event.agreement = {};
		event.agreement.orderValue = agreement.value;
		event.agreement.yearlyValue = agreement.yearlyValue;
		event.agreement.currency = agreement.currency;
		event.agreement.description = agreement.description;

		return event;
	};

	EventService.extend.Comment = function (event, comment) {
		event.comment = comment;
		event.client = comment.client;
		event.contacts = comment.contact ? [comment.contact] : [];
		event.entityId = comment.id;
		event.entityType = 'Comment';
		event.date = new Date(comment.regDate);
		if (comment.user) {
			event.users = [comment.user];
		} else {
			event.users = [];
		}
		event.files = comment.files;
		event.value = comment.description;
		return event;
	};

	EventService.extend.ClientPlan = function (event, plan, subType) {
		let value;

		if (subType === 'Created') {
			value = plan.description;
		} else if (subType === 'StatusChanged') {
			value = plan.status;
		}

		event.plan = plan;
		event.client = plan.clientId;
		event.entityId = plan.id;
		event.entityType = 'ClientPlan';
		event.date = new Date();
		event.subType = subType;
		event.value = value;
		event.users = [Tools.AppService.getSelf()];
		event.value = value;

		return event;
	};

	EventService.extend.Ticket = function (event, ticket, subType) {
		event.value = JSON.stringify({ title: ticket.title, status: ticket.status });
		event.date = new Date();
		event.subType = subType;
		event.client = ticket.client;
		event.contacts = ticket.contact ? [ticket.contact] : null;
		event.entityType = 'Ticket';
		event.entityId = ticket.id;
		event.users = [ticket.regBy];

		return event;
	};

	EventService.groupEvents = function (data, events = []) {
		events.push(...data);
		return events.sort((a, b) => b.date - a.date);
	};

	return EventService;
});

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@upsales/components';
import './FloatingSaveButton.scss';

interface FloatingSaveButtonProps {
	text?: string;
	tooltip?: string;
	visible?: boolean;
	disabled?: boolean;
	className?: string;
	loading?: boolean;
}
const FloatingSaveButton = ({
	text,
	tooltip,
	visible,
	disabled,
	className,
	loading,
	...props
}: FloatingSaveButtonProps) => {
	const classList = (className || '').split(' ');
	classList.push('FloatingSaveButton');
	if (visible) {
		classList.push('FloatingSaveButton--visible');
	}
	const button = (
		<Button color="green" size="xl" shadow="high" disabled={!visible || disabled} loading={loading} {...props}>
			{text}
		</Button>
	);
	if (tooltip) {
		return (
			<Tooltip className={classList.join(' ').trim()} title={tooltip} distance={20}>
				{button}
			</Tooltip>
		);
	} else {
		return <div className={classList.join(' ').trim()}>{button}</div>;
	}
};

FloatingSaveButton.propTypes = {
	text: PropTypes.string,
	tooltip: PropTypes.string,
	visible: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	className: PropTypes.string
};

export default FloatingSaveButton;

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Title, Card, Icon, Text } from '@upsales/components';
import { navigateStep, initImportApp } from 'Store/reducers/OnboardingReducer';
import ImportApp from '../ImportApp';

const ImportClass = new Bem('ImportStep');

const mapStateToProps = state => ({
	importApps: state.Onboarding.importApps,
	currentStepId: state.Onboarding.currentStepId,
	imports: state.Onboarding.imports,
	importsLoading: state.Onboarding.importsLoading,
	importAppsLoading: state.Onboarding.importAppsLoading
});

const mapDispatchToProps = {
	navigateStep,
	initImportApp
};

const isPowerImport = anImport => {
	return anImport.hasOwnProperty('numRows');
};

class Import extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.import.title'),
			importManually: T('onboarding.step.import.importManually'),
			importFromFile: T('onboarding.step.import.importFromFile'),
			importedFromFile: T('onboarding.step.import.importedFromFile'),
			importComplete: T('import.importComplete'),
			importStarted: T('import.importStarted'),
			noName: T('default.noName')
		};

		this.renderImportApps = this.renderImportApps.bind(this);
	}

	goToCustomStep(app) {
		const { navigateStep, initImportApp } = this.props;
		const step = app.startStep;
		initImportApp(step);
		navigateStep(step.substeps[0]);
	}

	disableImports() {
		const { imports, importsLoading } = this.props;

		return (
			importsLoading ||
			_.some(imports, anImport => {
				return isPowerImport(anImport)
					? anImport.status === 'START' || anImport.status === 'STARTED'
					: anImport.endDate === null && anImport.progress !== -1;
			})
		);
	}

	displayApps() {
		const { importApps } = this.props;
		const disabled = this.disableImports();

		return importApps.map(app => {
			return <ImportApp key={app.id} onClick={() => this.goToCustomStep(app)} app={app} disabled={disabled} />;
		});
	}

	renderImportApps(importApps) {
		if (!importApps.length) {
			return null;
		}

		return (
			<div id="ImportApps">
				<Title size="xl">{this.lang.title}</Title>
				<div className={ImportClass.elem('Container').b()}>{this.displayApps()}</div>
			</div>
		);
	}

	renderImports() {
		const { importAppsLoading, importApps, imports } = this.props;

		if (!imports.length || importAppsLoading) {
			return null;
		}

		const importCards = imports.map(anImport => {
			const OnboardingImportCard = new Bem('OnboardingImportCard');

			const powerimport = isPowerImport(anImport);
			const app = powerimport ? null : _.find(importApps, { id: anImport.integrationId });
			const style =
				powerimport || !app
					? {}
					: { backgroundColor: app.color ? app.color : '#fff', backgroundImage: `url(${app.imageLink})` };
			const name = powerimport ? this.lang.importedFromFile : app ? app.name : this.lang.noName;
			const isFinished = powerimport ? anImport.status === 'FINISHED' : anImport.endDate;
			const date = powerimport ? anImport.regDate : isFinished ? anImport.endDate : anImport.startDate;

			return (
				<Card key={anImport.id} className={OnboardingImportCard.mod({ powerimport })}>
					<div style={style} className={OnboardingImportCard.elem('AppLogo').b()}>
						<Icon name="file-excel" />
					</div>
					<div className={OnboardingImportCard.elem('ImportInfo')}>
						<Text>{name}</Text>
						<Text bold>{isFinished ? this.lang.importComplete : this.lang.importStarted}</Text>
						<Text size="sm">
							{isFinished && <Icon color="bright-green" name="check-circle" />}
							{moment(date).format('YYYY-MM-DD HH:mm')}
						</Text>
					</div>
				</Card>
			);
		});

		return <div className={ImportClass.elem('ContainerStarted')}>{importCards}</div>;
	}

	render() {
		const { importApps, currentStepId } = this.props;
		const ImportAppClass = new Bem('ImportApp');
		const disabled = this.disableImports();

		return (
			<div className={ImportClass}>
				{this.renderImports()}
				{this.renderImportApps(importApps)}
				<Title size="xl">{this.lang.importManually}</Title>
				<div className={ImportClass.elem('Container').b()}>
					<div className={ImportAppClass.mod({ disabled, manual: true }).b()}>
						<Card
							onClick={() => Tools.$state.go('administration.imports', { stepId: currentStepId })}
							style={{
								backgroundColor: 'transparent'
							}}
						>
							<Icon name="file-excel" />
						</Card>
						<Title size="sm">{this.lang.importFromFile}</Title>
					</div>
				</div>
			</div>
		);
	}
}

Import.propTypes = {
	importApps: PropTypes.array,
	currentStepId: PropTypes.number,
	navigateStep: PropTypes.func,
	initImportApp: PropTypes.func,
	imports: PropTypes.array,
	importsLoading: PropTypes.bool,
	importAppsLoading: PropTypes.bool
};

export const detached = Import;
export default {
	default: connect(mapStateToProps, mapDispatchToProps)(Import)
};

'use strict';

angular.module('upResources').factory('MailAccount', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'MailAccountAttributes',
	'ResourceHelper',
	'AppService',
	function ($resource, $q, URL, API, GenericMapper, MailAccountAttributes, ResourceHelper, AppService) {
		var Attributes = MailAccountAttributes().attr;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'mailAccount',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings'
		});

		var Resource = $resource(
			URL + API + 'master/mailAccount/:id',
			{},
			{
				query: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false },
				domains: { method: 'GET', url: URL + API + 'mail/domains/' },
				addDomain: { method: 'POST', isArray: false, url: URL + API + 'mail/domains/' },
				deleteDomain: { method: 'DELETE', isArray: false, url: URL + API + 'mail/domains/:id' }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			get: function () {
				return Resource.get({}).$promise;
			},

			save: function (mailAccount, options) {
				var defered = $q.defer();
				if (!mailAccount) {
					return defered.reject('No mail account');
				}

				GenericMapper.map(mailAccount, Attributes, function (err, mappedMailAccount) {
					if (err) {
						return defered.reject('Mail account mapping error');
					}

					Resource.create({ mailAccount: mappedMailAccount }, defered.resolve, defered.reject);
				});

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !mailAccount.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !mailAccount.id);
					});

				return defered.promise;
			},

			update: function (mailAccount, options) {
				var defered = $q.defer();
				if (!mailAccount) {
					return defered.reject('No mail account');
				}

				GenericMapper.map(mailAccount, Attributes, function (err, mappedMailAccount) {
					if (err) {
						return defered.reject('Mail account mapping error');
					}

					var customerId = AppService.getCustomerId();

					Resource.update(
						{ id: customerId },
						{ mailAccount: mappedMailAccount },
						defered.resolve,
						defered.reject
					);
				});

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !mailAccount.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !mailAccount.id);
					});

				return defered.promise;
			},

			delete: function () {
				if (console) {
					console.warn('missing this feature');
				}
			},

			domains: function () {
				return Resource.domains({}).$promise;
			},

			addDomain: function (obj, options) {
				var defered = $q.defer();

				var customerId = AppService.getCustomerId();
				Resource.addDomain({ customerId: customerId }, obj, defered.resolve, defered.reject);

				defered.promise
					.then(function (res) {
						helper.onSave(options, res.data, !obj.id);
					})
					.catch(function () {
						helper.onSaveError(options, !obj.id);
					});
				return defered.promise;
			},

			deleteDomain: function (id, options) {
				var defered = $q.defer();
				Resource.deleteDomain({ id }, defered.resolve, defered.reject);

				defered.promise
					.then(function (res) {
						helper.onDelete(options, res.data);
					})
					.catch(function (err) {
						helper.onDeleteError(options, err);
					});
				return defered.promise;
			}
		};
		return Model;
	}
]);

'use strict';

angular.module('upResources').service('ParseStandardMailTemplate', [
	'URL',
	'API',
	function (URL, API) {
		return function (template) {
			// TemplateThumb url
			template.$$thumbnailUrl =
				URL + API + 'thumbnail/standardTemplate/' + template.id + '?timestamp=' + Date.now();

			return template;
		};
	}
]);

import ClientPlanType, { ClientPlanTypeInfo } from 'App/enum/ClientPlanType';
import { currencyFormat } from 'Components/Filters/Currencies';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientPlan from 'App/resources/Model/ClientPlan';
import { Currency } from 'App/resources/AllIWant';
import { AssistChip, Text } from '@upsales/components';
import { t } from 'i18next';
import React from 'react';

import './ClientPlanListElement.scss';

type ClientPlanListElementProps = {
	className?: string;
	plan: Pick<ClientPlan, 'type' | 'value'>;
	currency?: Currency;
	size?: React.ComponentProps<typeof Text>['size'];
};

const ClientPlanListElement = ({ className, plan, currency, size }: ClientPlanListElementProps) => {
	const classes = new BemClass('ClientPlanListElement', className);

	return (
		<div className={classes.mod({ danger: plan.type === ClientPlanType.RISK }).b()}>
			<AssistChip
				type={ClientPlanTypeInfo[plan.type].chipType}
				title={t(ClientPlanTypeInfo[plan.type].title)}
				icon={ClientPlanTypeInfo[plan.type].icon}
			/>
			{plan.value ? <Text size={size}>{currencyFormat(Number(plan.value), currency?.iso ?? '')}</Text> : null}
		</div>
	);
};

export default ClientPlanListElement;

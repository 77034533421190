'use strict';

angular.module('upDirectives').directive('upFindProspectsRoot', [
	'$translate',
	'AppService',
	'$timeout',
	'$rootScope',
	'FilterHelper',
	'$multiSelect',
	'$filter',
	'$upModal',
	'$state',
	'$parse',
	'avatarService',
	function (
		$translate,
		AppService,
		$timeout,
		$rootScope,
		FilterHelper,
		$multiSelect,
		$filter,
		$upModal,
		$state,
		$parse,
		avatarService
	) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;

				var render = function (rootData) {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}

						ReactDOM.render(
							React.createElement(ReactTemplates.findProspect.root, {
								rootData: rootData,
								tools: {
									AppService: AppService,
									FilterHelper: FilterHelper,
									$translate: $translate.instant,
									$rootScope: $rootScope,
									$filter: $filter,
									$multiSelect: $multiSelect,
									$upModal: $upModal,
									$state: $state,
									$parse: $parse,
									avatarService: avatarService
								}
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					clearTimeout(renderTimeout);
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$on('renderFindProspects', function () {
					var rootData = $parse($attr.upFindProspectsRoot)($scope);
					render(rootData);
				});

				$scope.$watch(
					$attr.upFindProspectsRoot,
					function (rootData) {
						render(rootData);
					},
					true
				);
			}
		};
	}
]);

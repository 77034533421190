'use strict';

angular.module('upResources').factory('AccountProfile', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AccountProfileAttributes',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	'AppService',
	'Onboarding',
	function AccountProfile(
		$resource,
		$q,
		URL,
		API,
		AccountProfileAttributes,
		ResourceHelper,
		ParseGeneric,
		MapGeneric,
		AppService,
		Onboarding
	) {
		var AA = AccountProfileAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'accountProfile',
			createSuccessBody: 'saved.accountProfile',
			createErrorBody: 'saveError.accountProfile'
		});

		var baseUrl = URL + API + 'accountProfile/';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseAccountProfile', { isArray: false, custom: false })
				},
				save: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseAccountProfile', { isArray: false, custom: false }),
					transformRequest: MapGeneric(null)
				},
				update: {
					method: 'PUT',
					params: {
						id: '@id'
					},
					isArray: false,
					transformResponse: ParseGeneric('ParseAccountProfile', { isArray: false, custom: false }),
					transformRequest: MapGeneric(null)
				}
			}
		);

		var Model = {
			new: function () {
				var accountSelf = AppService.getAccountSelf();

				var profile = {
					name: accountSelf.client.name,
					address: '',
					zipcode: '',
					city: '',
					country: '',
					email: '',
					phone: '',
					logo: '',
					darkLogo: '',
					btnBorderRadius: 3,
					extraColors: [
						{ value: '#002f65' },
						{ value: '#4a90e2' },
						{ value: '#F6F9FB' },
						{ value: '#E4EAF0' }
					],
					colors: {
						mainBg: '#002f65',
						mainText: '#2F3947',
						bodyBg: '#E4EAF0',
						contentBg: '#ffffff',
						contentText: '#6B7C93',
						contentTextIngress: '#2F3947',
						buttonBg: '#F5A623',
						buttonText: '#ffffff',
						secondaryBg: '#A4B3C7',
						secondaryText: '#002f65',
						linkText: '#4A90E2',
						footerLinkText: '#4A90E2',
						footerText: '#000000'
					},
					typography: {
						h2: {
							size: 32,
							bold: false,
							underline: false,
							italic: false,
							fontFamily: 'Arial, Helvetica, sans-serif'
						},
						h3: {
							size: 20,
							bold: false,
							underline: false,
							italic: false,
							fontFamily: 'Arial, Helvetica, sans-serif'
						},
						ingress: {
							size: 16,
							bold: false,
							underline: false,
							italic: false,
							fontFamily: 'Arial, Helvetica, sans-serif'
						},
						p: {
							size: 14,
							bold: false,
							underline: false,
							italic: false,
							fontFamily: 'Arial, Helvetica, sans-serif'
						},
						link: {
							size: 14,
							bold: false,
							underline: false,
							italic: true,
							fontFamily: 'Arial, Helvetica, sans-serif'
						}
					}
				};

				return profile;
			},

			get: function () {
				return Resource.get({}).$promise;
			},

			save: function (accountProfile, options) {
				var defered = $q.defer();

				if (!accountProfile) {
					return defered.reject('No accountProfile');
				}
				var method = Tools.FeatureHelper.hasSoftDeployAccess('MULTIPLE_ACCOUNT_PROFILE')
					? accountProfile.id
						? 'update'
						: 'save'
					: 'save';

				Resource[method](
					accountProfile,
					function (response) {
						if (response.error) {
							defered.reject(response);
						}

						Onboarding.updateStep('accountProfile');
						defered.resolve(response);
					},
					defered.reject
				);

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !accountProfile.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !accountProfile.id);
					});

				return defered.promise;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

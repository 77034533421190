'use strict';

angular.module('upResources').factory('AgreementSettings', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function AgreementSettings($resource, $q, URL, API, ResourceHelper) {
		var Resource = $resource(
			URL + API + 'master/agreementSettings',
			{},
			{
				get: { method: 'GET', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'agreementSettings',
			createSuccessBody: 'saved.agreementSettings',
			updateSuccessBody: 'updated.agreementSettings',
			deleteSucccessBody: 'deleted.agreementSettings',
			createErrorBody: 'saveError.agreementSettings',
			updateErrorBody: 'saveError.agreementSettings',
			deleteErrorBody: 'deleteError.agreementSettings'
		});

		var Model = {
			get: function () {
				return Resource.get().$promise;
			},
			save: function (object, options) {
				var defered = $q.defer();

				if (!object) {
					return defered.reject('No settings provided');
				}

				return Resource.update({ id: object.id }, object)
					.$promise.then(function (res) {
						helper.onSave(options, res.data, false);
						return res;
					})
					.catch(function (err) {
						helper.onSaveError(options, false);
						return $q.reject(err);
					});
			}
		};

		return Model;
	}
]);

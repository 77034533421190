import { Block, Button, ButtonGroup, ButtonSelect, Label, Toggle } from '@upsales/components';
import React from 'react';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { useDispatch } from 'react-redux';
import { setAssignData } from 'Store/reducers/AssignModalReducer';
import AssignModalLeadSelectAdvanced from './AssignModalLeadSelectAdvanced';
import { FormField } from 'App/resources/Model/Form';
import './AssignModalLeadSelect.scss';

const LEAD_TABS = {
	USER: 'user',
	ROLE: 'role'
} as const;

const MODE_TABS = {
	SIMPLE: 'simple',
	ADVANCED: 'advanced'
} as const;

type Props = {
	classNames: BemClass;
	currentTab: 'user' | 'role';
	handleTabChange: Function;
	nextStep: number;
	allowAdvanced?: boolean;
};

const AssignModalLeadSelect = ({
	classNames,
	currentTab,
	handleTabChange,
	nextStep = 2,
	allowAdvanced = false
}: Props) => {
	const lang = {
		toUserOrRole: T('activity.toUserOrRole'),
		user: T('user'),
		role: T('default.role'),
		assignToAccountManager: T('activity.assignToAccountManager'),
		settUserAsAccountManager: T('processedBy.settUserAsAccountManager'),
		chooseUser: T('assign.chooseSeller'),
		selectUser: T('default.selectUser'),
		selectRole: T('default.selectRole'),
		searchRoles: T('activity.searchRoles'),
		searchUsers: T('activity.searchUsers')
	};

	const modalClass = new BemClass('AssignModalLead'); // This classname was left as is when breaking out code from AssignModalLead.
	const selectClass = new BemClass('AssignModalLeadSelect');
	const dispatch = useDispatch();
	const assign = useSelector((state: RootState) => state.AssignModal.assign);
	const formFields: FormField[] = useSelector((state: RootState) => state.FormEditor.fields);

	const modeTab = assign.advanced ? MODE_TABS.ADVANCED : MODE_TABS.SIMPLE;

	const advancedAvailable = formFields?.some(field => ['select', 'radio'].includes(field.datatype));

	if (!advancedAvailable && assign.advanced) {
		dispatch(setAssignData({ advanced: false }));
	}

	const SimpleTab = () => (
		<>
			<Label>
				<strong>{lang.toUserOrRole}</strong>
			</Label>
			<ButtonGroup className={modalClass.elem('Tabs').b()}>
				{Object.keys(LEAD_TABS).map(tab => {
					const tabVal = LEAD_TABS[tab as keyof typeof LEAD_TABS];
					return (
						<Button
							key={tab}
							size="sm"
							onClick={() => handleTabChange(tabVal)}
							color={currentTab === tabVal ? 'bright-blue' : 'light-grey'}
						>
							{lang[tabVal]}
						</Button>
					);
				})}
			</ButtonGroup>

			<Label className={classNames.elem('manager-toggle-row').b()}>
				<Toggle
					size="md"
					color="bright-blue"
					onChange={v => dispatch(setAssignData({ assignToManager: v }))}
					checked={assign.assignToManager}
				/>{' '}
				{lang.assignToAccountManager}
			</Label>

			<Label className={classNames.elem('manager-toggle-row').b()}>
				<Toggle
					size="md"
					color="bright-blue"
					onChange={v => dispatch(setAssignData({ setUserAsAccountManager: v }))}
					checked={assign.setUserAsAccountManager}
				/>{' '}
				{lang.settUserAsAccountManager}
			</Label>

			{currentTab === LEAD_TABS.USER ? (
				<div className={classNames.elem('user-role-row').b()}>
					<Label required>
						<strong>{lang.chooseUser}</strong>
					</Label>
					<ReactTemplates.INPUTS.upUsers
						className={classNames.elem('user-select').b()}
						placeholder={lang.searchUsers}
						onChange={user => {
							const data = { user, role: null, step: assign.step };
							if (user && user.id) {
								data.step = nextStep;
							}

							dispatch(setAssignData(data));
						}}
						value={assign.user}
						data={Tools.AppService.getActiveUsers()}
					/>
				</div>
			) : null}

			{currentTab === LEAD_TABS.ROLE ? (
				<div className={classNames.elem('user-role-row').b()}>
					<Label>{lang.selectRole}</Label>
					<ReactTemplates.INPUTS.upRoles
						id="assign-role-input"
						placeholder={lang.searchRoles}
						data={Tools.AppService.getRoles()}
						value={assign.role}
						onChange={role => {
							const data = { user: null, role, step: assign.step };
							if (role && role.id) {
								data.step = nextStep;
							}

							dispatch(setAssignData(data));
						}}
					/>
				</div>
			) : null}
		</>
	);

	return (
		<div className={selectClass.b()}>
			<Block space="mtl mbl">
				{allowAdvanced ? (
					<ButtonSelect
						className={selectClass.elem('tabs').b()}
						options={[
							{ value: MODE_TABS.SIMPLE, title: T('assign.basicAssign') },
							{
								value: MODE_TABS.ADVANCED,
								title: T('assign.advancedAssign'),
								icon: !advancedAvailable ? 'lock' : undefined,
								tooltip: !advancedAvailable ? T('assign.advancedUnavailableTooltip') : undefined
							}
						]}
						value={modeTab}
						onChange={val => {
							if (!advancedAvailable && val === MODE_TABS.ADVANCED) {
								return;
							}
							dispatch(setAssignData({ advanced: val === MODE_TABS.ADVANCED }));
						}}
					/>
				) : null}
			</Block>
			{modeTab === MODE_TABS.SIMPLE ? <SimpleTab /> : <AssignModalLeadSelectAdvanced />}
		</div>
	);
};

export default AssignModalLeadSelect;

'use strict';

angular.module('domain.agreement', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$routeGuardProvider',
	'$upModalProvider',
	function ($stateProvider, $routeGuardProvider, $upModalProvider) {
		$stateProvider.state('agreements', {
			templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
			url: '/:customerId/agreement/',
			controller: 'ListAgreements as listViewHelper',
			section: 'sale',
			resolve: {
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				agreementActivated: [
					'AppService',
					'$q',
					'FeatureHelper',
					function (AppService, $q, FeatureHelper) {
						return AppService.loadedPromise.then(function () {
							var metadata = AppService.getMetadata();
							var self = AppService.getSelf();
							if (
								(self.administrator &&
									!FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER)) ||
								(metadata.params.AgreementEnabled && AppService.getAccessRights().Agreement)
							) {
								return $q.when(true);
							}
							return $q.reject(false);
						});
					}
				],
				isAvailable: [
					'FeatureHelper',
					function (FeatureHelper) {
						return FeatureHelper.placeholder(FeatureHelper.Feature.RECURRING_ORDER);
					}
				],
				listViews: [
					'AppService',
					function (AppService) {
						return AppService.loadedPromise.then(function () {
							return AppService.getListViews('agreement');
						});
					}
				],
				columnTemplate: [
					'TemplateCacher',
					function (TemplateCacher) {
						return TemplateCacher.cache('/upsales/common/components/columnTemplates/listAgreements.html');
					}
				]
			}
		});

		$upModalProvider.modal('editAgreement', {
			templateUrl: require('App/upsales/domain/agreement/views/editAgreement.html?file'),
			controller: 'EditAgreement as EditAgreement',
			style: 'form-wide default',
			hash: true,
			closeOnEscape: false,
			closeOnCurtain: true,
			rememberHeight: true,
			resolve: {
				authUser: function ($routeGuard) {
					return $routeGuard.requireUser();
				},
				meta: function ($modalParams, EditAgreementMeta) {
					return EditAgreementMeta($modalParams);
				}
			}
		});
	}
]);

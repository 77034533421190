import React from 'react';
import PropTypes from 'prop-types';
import {
	Title,
	Sidebar,
	SidebarWidget,
	SidebarWidgetTitle,
	SidebarWidgetInfoRow,
	Link,
	Avatar,
	Icon
} from '@upsales/components';
import t from '../Helpers/translate';
import './AssignModalSidebar.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';

type Props = {
	client: Client;
};

const AssignModalSidebar = ({ client }: Props) => {
	const phone = ReactTemplates.TOOLS.upTel(client.phone);
	const visitAddress = client.addresses?.find(a => a.type === 'Visit');
	let addressRow = null;

	if (visitAddress) {
		const icon = visitAddress.country ? (
			<b className={`flag-icon flag-icon-${visitAddress.country.toLowerCase()}`} />
		) : (
			<Icon name="map-marker" />
		);
		addressRow = (
			<SidebarWidgetInfoRow
				key="address"
				value={
					<span>
						{icon} {visitAddress.city}
					</span>
				}
			/>
		);
	}

	const classNames = new BemClass('AssignModalSidebar');

	return (
		<Sidebar size="sm" className={classNames.b()}>
			<SidebarWidget key="title">
				<Title>
					<Link
						color="black"
						href={Tools.$state.href('account.dashboard', {
							id: client.id,
							customerId: Tools.AppService.getCustomerId()
						})}
					>
						{client.name}
					</Link>
				</Title>

				<div key="crm" className={classNames.elem('client-label').b()}>
					<ReactTemplates.TOOLS.leadStatus
						theKey={client.id}
						activity={client.hasActivity}
						activityOld={client.hadActivity}
						appointment={client.hasAppointment}
						appointmentOld={client.hadAppointment}
						opportunity={client.hasOpportunity}
						opportunityOld={client.hadOpportunity}
						order={client.hasOrder}
						orderOld={client.hadOrder}
					/>
				</div>

				<div key="market" className={classNames.elem('client-label').b()}>
					<ReactTemplates.TOOLS.eventTypes theKey={client.id} account={client} />
				</div>

				<div key="rating" className={classNames.elem('client-label').b()}>
					<ReactTemplates.TOOLS.upRatingLabel account={client} />
				</div>

				<SidebarWidgetTitle title={t('default.accountManagers')} />
				{client.users && client.users.length ? (
					client.users.map(user => (
						<SidebarWidgetInfoRow
							key={'user-' + user.id}
							placeholder={t('default.noAccountManager')}
							value={
								<div>
									<Avatar
										size="sm"
										initials={user.name}
										email={user.email}
										style={{ verticalAlign: 'middle' }}
									/>{' '}
									{user.name}
								</div>
							}
						/>
					))
				) : (
					<SidebarWidgetInfoRow placeholder={t('default.noAccountManager')} />
				)}
			</SidebarWidget>

			<SidebarWidget key="client-info">
				<SidebarWidgetTitle title={t('admin.clientInfo')} />
				{addressRow}
				<SidebarWidgetInfoRow
					key="phone"
					color="bright-blue"
					icon="phone"
					placeholder={t('default.noPhone')}
					value={
						phone.href ? (
							<Link href={phone.href} target={phone.target}>
								{client.phone}
							</Link>
						) : null
					}
				/>
				<SidebarWidgetInfoRow
					key="website"
					color="bright-blue"
					icon="globe"
					placeholder={t('default.noWebsite')}
					value={
						client.webpage ? (
							<Link href={client.webpage} target="_blank">
								{client.webpage}
							</Link>
						) : null
					}
				/>
			</SidebarWidget>
		</Sidebar>
	);
};

AssignModalSidebar.propTypes = {
	client: PropTypes.object.isRequired
};

export default AssignModalSidebar;

import SocialEventAttributes from 'App/babel/attributes/SocialEventAttributes';

angular
	.module('domain.campaign')
	.controller(
		'CampaignSocialEvents',
		function ($scope, $state, $stateParams, RequestBuilder, AppService, $safeApply, FilterHelper) {
			var customerId;
			var currentFilter;
			var CampaignCtrl = $scope.CampaignCtrl;

			var CampaignSocialEventsCtrl = this;

			CampaignSocialEventsCtrl.error = false;
			CampaignSocialEventsCtrl.limit = 50;
			CampaignSocialEventsCtrl.loading = false;
			CampaignSocialEventsCtrl.currentPage = 1;
			$scope.socialEventsPages = 0;

			$scope.$on('socialEvent.updated', function (e, updated) {
				var index = _.findIndex(CampaignSocialEventsCtrl.socialEvents, { id: updated.id });
				if (FilterHelper.match(currentFilter.q, updated, 'socialEvent')) {
					if (index !== -1) {
						CampaignSocialEventsCtrl.socialEvents[index] = updated;
					} else {
						CampaignSocialEventsCtrl.socialEvents.push(updated);
					}
				} else if (index !== -1) {
					CampaignSocialEventsCtrl.socialEvents.splice(index, 1);
				}
			});

			CampaignSocialEventsCtrl.getSocialEvents = function ($page) {
				CampaignSocialEventsCtrl.error = false;
				CampaignSocialEventsCtrl.loading = true;

				var filter = new RequestBuilder();
				filter.addFilter(SocialEventAttributes.projects, filter.comparisonTypes.Equals, $stateParams.id);
				filter.addSort('name', true);
				filter.limit = CampaignSocialEventsCtrl.limit;
				filter.offset = CampaignSocialEventsCtrl.limit * ($page - 1);
				currentFilter = filter.build();

				window.Tools.SocialEvents.find(currentFilter)
					.then(function (res) {
						CampaignSocialEventsCtrl.loading = false;
						CampaignSocialEventsCtrl.socialEvents = res.data;
						CampaignCtrl.socialEventsTotal = res.metadata.total;

						if (CampaignCtrl.socialEventsTotal !== 0) {
							$scope.socialEventsPages = Math.ceil(
								CampaignCtrl.socialEventsTotal / CampaignSocialEventsCtrl.limit
							);
						}
						$safeApply($scope);
					})
					.catch(function () {
						CampaignSocialEventsCtrl.loading = false;
						CampaignSocialEventsCtrl.error = true;
					});
			};

			CampaignSocialEventsCtrl.removeFromProject = function (socialEvent) {
				_.remove(socialEvent.projects, { id: CampaignCtrl.campaign.id });
				window.Tools.SocialEvents.save({ id: socialEvent.id, projects: socialEvent.projects });
			};

			CampaignSocialEventsCtrl.goToSocialEvent = function (socialEvent) {
				$state.go('react-root-editSocialEvent', { customerId: customerId, id: socialEvent.id });
			};

			var init = function () {
				customerId = AppService.getCustomerId();
				CampaignSocialEventsCtrl.getSocialEvents(1);
			};

			// eslint-disable-next-line promise/catch-or-return
			AppService.loadedPromise.then(init);
		}
	);

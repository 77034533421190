ReactTemplates.upFilters.components.visitRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			isGroup: false,
			value: null,
			text: '',
			checked: false,
			disabled: false,
			showCheckBox: true,
			showButton: true
		};
	},
	onOnlyButtonClick: function (event) {
		event.stopPropagation();

		var values;
		if (this.props.isGroup) {
			values = _.chain(this.props.item.children)
				.pluck('id')
				.filter(_.isNumber)
				.map(function (id) {
					return id;
				})
				.value();
		} else {
			values = [this.props.value];
		}

		this.props.onChange(values, 'replace');
	},
	getChildValues: function (arr) {
		return _.reduce(
			arr,
			function (values, item) {
				if (item.isRole) {
					return values.concat(this.getChildValues(item.children));
				} else {
					values.push(item.id);
					return values;
				}
			}.bind(this),
			[]
		);
	},
	onClick: function () {
		if (this.props.disabled === false) {
			var values = this.props.isGroup ? this.getChildValues(this.props.item.children) : [this.props.value];

			if (this.props.checked) {
				this.props.onChange(values, 'remove');
			} else {
				this.props.onChange(values, 'add');
			}
		}
	},
	render: function () {
		var item = this.props.item;
		var text = item.name;
		var buttonText = Tools.$translate('default.only');

		if (!text) {
			text = '<< ' + Tools.$translate('default.noTitle') + ' >>';
		}

		var urlText = item.url;

		var maxLength = 40;

		var textContent = <div className="list-text-wrap">{text}</div>;

		var urlContent = <div className="list-text-wrap url-row">{urlText}</div>;

		var tooltipOptions = {
			placement: 'left',
			distance: 20,
			popperOptions: {
				modifiers: {
					preventOverflow: {
						boundariesElement: 'window'
					}
				}
			}
		};

		if (text.length > maxLength) {
			textContent = ReactTemplates.TOOLS.withTooltip(textContent, text, tooltipOptions);
		}

		if (urlText.length > maxLength) {
			urlText = urlText.substr(0, 19) + '...' + urlText.substr(urlText.length - 19, urlText.length);
			urlContent = ReactTemplates.TOOLS.withTooltip(urlContent, item.url, tooltipOptions);
		}

		var $filter = Tools.$injector.get('$filter');
		var numberFormat = $filter('numberFormat');

		var impressionsText = numberFormat(item.pageImpression);

		return (
			<div className="visit-row" onClick={this.onClick}>
				{this.props.showCheckBox && (
					<div className="list-input-wrap">
						<input
							className="list-input"
							type="checkbox"
							disabled={this.props.disabled}
							readOnly
							checked={this.props.checked}
						/>
					</div>
				)}
				<div className="text-container">
					{textContent}
					{urlContent}
					<div className="list-text-wrap">
						<i style={{ marginRight: '5px' }} className="fa fa-eye" aria-hidden="true" /> {impressionsText}
					</div>
					{this.props.disabled === false && this.props.showButton && (
						<button className="list-button list-button-item" onClick={this.onOnlyButtonClick}>
							{buttonText}
						</button>
					)}
				</div>
			</div>
		);
	}
});

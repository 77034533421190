import './File.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Text } from '@upsales/components';
import { getIconAndColor } from 'App/babel/helpers/file';
import { useDrag } from 'react-dnd';

type Props = {
	id: number;
	name: string;
	fileType: string;
	isInEditMode: boolean;
	deleteFile: (id: number) => void;
};

const File = ({ id, name, fileType, isInEditMode, deleteFile }: Props) => {
	const fileSrc = 'img/files.svg';
	const classes = new BemClass('File');

	const [iconName, color] = getIconAndColor(fileType);

	const downloadFile = (fileId: number) => {
		if (fileId) {
			window.Tools.File.download(fileId);
		}
	};

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: 'ALL',
			item: { id, isFile: true },
			canDrag: !isInEditMode,
			collect: monitor => ({ isDragging: !!monitor.isDragging() })
		}),
		[isInEditMode]
	);

	return (
		<div
			ref={drag}
			onClick={!isInEditMode ? () => downloadFile(id) : undefined}
			className={classes.mod({ isInEditMode, isDragging }).b()}
		>
			<img src={fileSrc} />
			<Text size="sm">
				<Icon name={iconName} color={color} />
				{name}
			</Text>
			{isInEditMode ? (
				<Icon onClick={() => deleteFile(id)} className={classes.elem('trash').b()} name="trash" />
			) : null}
		</div>
	);
};

export default File;

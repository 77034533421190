import React, { useMemo } from 'react';
import { markdown as MarkdownService } from 'markdown';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	markdown: string;
	linkTarget?: '_blank' | '_parent' | '_self' | '_top';
};

const Markdown = (props: Props) => {
	const { markdown, linkTarget, ...divProps } = props;

	const HTML = useMemo(() => {
		const HTML = MarkdownService.toHTML(markdown);
		return linkTarget ? HTML.replace(/<a/g, `<a target="${linkTarget}"`) : HTML;
	}, [markdown]);

	return <div dangerouslySetInnerHTML={{ __html: HTML }} {...divProps} />;
};

export default Markdown;

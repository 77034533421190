import CalendarWrapper from 'Components/CalendarWrapper/CalendarWrapper';
import { Link, Flex, Icon, Text } from '@upsales/components';
import Appointment from 'App/resources/Model/Appointment';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSelf } from 'App/components/hooks/appHooks';
import moment from 'moment';
import React from 'react';

import './AppointmentSidebarCalendar.scss';

type ExternalCalendarEvent = {
	id: string;
	description?: string;
	date: Date;
	endDate: Date;
	isEvent: boolean;
};

const mapEventsToCalendar = (events: ExternalEvent[]): ExternalCalendarEvent[] => {
	return events?.map(event => {
		return {
			...event,
			date: new Date(event.startDateTime),
			endDate: new Date(event.endDateTime),
			isEvent: true
		};
	});
};

type ExternalEvent = {
	id: string;
	description?: string;
	startDateTime: string;
	endDateTime: string;
};

type Props = {
	date: string;
	loading: boolean;
	onChangeDate: (next: boolean) => void;
	onChangeTime: (next: boolean) => void;
	appointment: Appointment;
	appointments: Appointment[];
	externalEvents: {
		events: ExternalEvent[];
		otherUsers: {
			[userId: string]: ExternalEvent[];
		};
	};
};

export default function AppointmentSidebarCalendar(props: Props) {
	const { date, loading, onChangeDate, onChangeTime, appointment, appointments, externalEvents } = props;
	const classes = new BemClass('AppointmentSidebarCalendar');

	const self = useSelf();

	const { events, otherUsers } = externalEvents;

	const calendarEvents = mapEventsToCalendar(events);

	const formattedDate = `${moment(date).format('dddd')}, ${moment(date).format('LL')}`;

	const myAppointments = appointments.filter(appointment => appointment.users.some(user => user.id === self?.id));

	const otherAppointments = appointments.filter(appointment => !appointment.users.some(user => user.id === self?.id));

	const otherUserEvents = Object.values(otherUsers ?? {}).reduce((acc, events) => {
		return [...acc, ...mapEventsToCalendar(events)];
	}, [] as ExternalCalendarEvent[]);

	return (
		<div className={classes.b()}>
			<Flex
				alignItems="center"
				space="ptm prm pbm pls"
				justifyContent="space-between"
				className={classes.elem('header').b()}
			>
				<Flex>
					<Link type="link" onClick={() => onChangeDate(false)}>
						<Text size="xl">
							<Icon name={'caret-left'} />
						</Text>
					</Link>
					<Link type="link" onClick={() => onChangeDate(true)}>
						<Text size="xl">
							<Icon name={'caret-right'} />
						</Text>
					</Link>
				</Flex>
				<Text size="sm">{formattedDate}</Text>
			</Flex>
			<CalendarWrapper
				view={'day'}
				dayStartHour={7}
				dayEndHour={19}
				events={loading ? [] : calendarEvents}
				otherEvents={loading ? [] : otherUserEvents}
				appointment={{ ...appointment, isEvent: true }}
				onHourClick={onChangeTime}
				appointments={loading ? [] : [...myAppointments, ...otherAppointments]}
				selectedMonth={new Date(date)}
			/>
		</div>
	);
}

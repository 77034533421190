'use strict';

angular.module('upResources').factory('ExportService', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ExportService($resource, $q, URL, API) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var Export = $resource(
					URL + API + customer + '/function/export',
					{},
					{
						create: { method: 'POST', isArray: false }
					}
				);

				instance.create = function (entity, filters, columns, opts) {
					var data = {
						entity: entity,
						columns: columns,
						filters: filters,
						includes: opts.includes || undefined,
						name: opts.name || undefined
					};

					if (opts.extraParams) {
						for (var key in opts.extraParams) {
							data[key] = opts.extraParams[key];
						}
					}

					return Export.create(data).$promise;
				};

				return instance;
			}
		};

		return Model;
	}
]);

'use strict';

(function () {
	// We need to return the resource in a function because somebody made a bad resource!!!
	var formatResults = function (obj, container, query, escape) {
		return (
			'<div>' +
			escape(obj.name ? obj.name : obj.subject) +
			(obj.subject && obj.name ? '<div class="subtitle">' + escape(obj.subject) + '</div>' : '') +
			'</div>'
		);
	};
	var formatSelection = function (obj, container, escape) {
		return escape(obj.name ? obj.name : obj.subject);
	};
	var matchers = function (term, undefined, object) {
		return (
			object.name.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
			object.subject.toLowerCase().indexOf(term.toLowerCase()) !== -1
		);
	};
	var mailCampaignSelectDir = 'upMailCampaignSelect';
	angular
		.module('upDirectives')
		.directive(mailCampaignSelectDir + 'Ids', function (MailCampaign, selectHelper) {
			return selectHelper.getDirectiveObjectIds(mailCampaignSelectDir + 'Ids', {
				resource: function () {
					return MailCampaign;
				},
				ajax: true,
				asIds: true,
				idAttr: MailCampaign.attr.id,
				titleAttr: MailCampaign.attr.subject,
				sorting: { field: MailCampaign.attr.subject, ascending: true },
				formatResult: formatResults,
				formatSelection: formatSelection,
				matcher: matchers,
				fields: ['name']
			});
		});
})();

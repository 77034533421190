angular.module('upAttributes').service('AdCampaignAttributes', [
	'FilterType',
	'DisplayType',
	'Ads',
	function (FilterType, DisplayType, Ads) {
		return function () {
			const attr = {
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'name'
				},
				status: {
					title: 'ads.campaignStatus',
					field: 'status',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'status'
				},
				target: {
					title: 'ads.targets',
					field: 'target',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false
				},
				budget: {
					title: 'ads.budget',
					field: 'budget',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false,
					sortable: 'budget'
				},
				spent: {
					title: 'ads.spent',
					field: 'spent',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false,
					sortable: 'spent'
				},
				startDate: {
					title: 'ads.startDate',
					field: 'startDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: false,
					sortable: 'startDate'
				},
				endDate: {
					title: 'default.endDate',
					field: 'endDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: false,
					sortable: 'endDate'
				},
				impressions: {
					title: 'form.views',
					field: 'impressions',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false,
					sortable: 'impressions'
				},
				clicks: {
					title: 'ads.click',
					field: 'clicks',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false,
					sortable: 'clicks'
				}
			};

			const standardFilters = {
				Name: {
					field: 'name',
					type: 'raw',
					title: 'default.name',
					isInactive: function (filter) {
						return !filter || filter.inactive || !filter.value;
					},
					generate: function () {
						return { value: '', comparisonType: 'src' };
					},
					build: function (filter, rb) {
						if (filter.value) {
							rb.addFilter({ field: 'name' }, filter.comparisonType, filter.value);
						}
					}
				},
				Archived: {
					field: 'status',
					type: 'raw',
					title: 'ads.status7',
					isInactive: function (filter) {
						return !filter || filter.inactive || filter.value;
					},
					generate: function () {
						return { value: false };
					},
					build: function (filter, rb) {
						if (!filter.value) {
							rb.addFilter({ field: 'status' }, rb.comparisonTypes.NotEquals, Ads.statuses.archived);
						}
					}
				}
			};

			return {
				getAll: () => attr,
				attr,
				standardFilters,
				requiredFields: [],
				relatedEntities: [],
				keys: {}
			};
		};
	}
]);

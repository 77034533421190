'use strict';

angular.module('upAttributes').service('FlowAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'id'
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true
				},
				regDate: {
					title: 'default.appointmentCreateDate',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'regDate'
				},
				regBy: {
					title: 'default.createdBy',
					field: 'regBy',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					sortable: 'regBy'
				},
				segment: {
					title: 'default.segment',
					field: 'segmentId',
					displayType: DisplayType.Number,
					groupable: false,
					sortable: 'segmentId',
					selectableColumn: false
				},
				status: {
					title: 'default.status',
					field: 'status',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'status',
					selectableColumn: true
				}
			};

			var standardFilters = {
				Name: {
					title: 'default.name',
					field: 'name',
					columnPath: 'name',
					inputType: 'text',
					type: 'text'
				},
				RegBy: {
					title: 'default.createdBy',
					field: 'regBy',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: [
						'UserTreeFilterMeta',
						'customerId',
						'AppService',
						function (UserTreeFilterMeta, customerId, AppService) {
							return UserTreeFilterMeta(AppService.AccessType.ACCOUNT);
						}
					],
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name')
				},
				Active: {
					title: 'default.status',
					type: 'radio',
					field: 'status',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.active', value: 'active', comparisonType: 'Equals' },
						{ text: 'mail.draft', value: 'draft', comparisonType: 'Equals' },
						{ text: 'mail.paused', value: 'paused', comparisonType: 'Equals' }
					]
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				keys: {
					ID: 'id'
				}
			};
		};
	}
]);

import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import { Button, Icon, Text } from '@upsales/components';

ReactTemplates.ROWSTATICS.agreementRow = function (tools) {
	var statics = {};
	statics.deleteTool = tools.$translate('default.delete');
	statics.editTool = tools.$translate('default.edit');
	statics.noCampaign = tools.$translate('default.noCampaign');
	statics.endDatePassed = tools.$translate('agreement.endDatePassed');
	statics.noNextOrderDate = tools.$translate('agreement.noNextOrderDate');
	statics.today = tools.$translate('date.today');
	statics.untilFurtherNotice = tools.$translate('agreement.periodLength.untilFurtherNotice');

	return statics;
};

ReactTemplates.ROW.agreementRow = window.ReactCreateClass({
	displayName: 'agreementRow',

	getDate: function (date) {
		if (date) {
			return ReactTemplates.TOOLS.dateCalendar(date, 'L');
		}

		return '';
	},

	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;

		const hasRecurringOrder = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
		const hasRecurringSalesModel = Tools.AppService.getMetadata().params.SalesModel === 'rr';
		const isMRR = Tools.AppService.getMetadata().params.SalesModelOption === 'mrr';
		const changeYearlyToMonthly = hasRecurringOrder && hasRecurringSalesModel && isMRR;

		var getColumn = function (column, item) {
			var key = 'column-' + column;

			if (_.includes(column, 'Custom_') || _.includes(column, 'CustomAgreement_')) {
				var customArr = column.indexOf('CustomAgreement_') === 0 ? item.metadata.custom : item.custom;
				var field = Number(column.split('_')[1]);
				var agreementCf = _.find(customArr, function (c) {
					return c.fieldId === field;
				});

				if (agreementCf) {
					if (agreementCf.datatype === 'Date') {
						if (!agreementCf.value) {
							return ReactTemplates.TOOLS.emptyTD();
						}
						var m = moment(agreementCf.value);
						if (!m.isValid() || !agreementCf.value) {
							return ReactTemplates.TOOLS.emptyTD();
						}
						return <td key={key}>{m.format('L')}</td>;
					}

					if (agreementCf.datatype === 'Boolean') {
						return (
							<td key={key}>
								{agreementCf.value ? tools.$translate('default.yes') : tools.$translate('default.no')}
							</td>
						);
					}

					if (_.includes(['Users', 'User'], agreementCf.datatype)) {
						var customFieldValuesArray = Tools.ActionProperties.parseCustomFieldUserValues(
							agreementCf.value
						);

						return <td key={key}>{customFieldValuesArray}</td>;
					}

					if (agreementCf.value) {
						return <td key={key}>{agreementCf.value}</td>;
					}
				}

				return <td key={key} />;
			}

			const displayValue = (item, displayInMasterCurrency) => {
				var currency;

				if (item.currency && !displayInMasterCurrency) {
					currency = item.currency;
				} else {
					var masterCurrency = _.find(tools.AppService.getMetadata().customerCurrencies, {
						masterCurrency: true
					});
					currency = masterCurrency.iso;
				}

				var value;

				if (item[column] !== null && item[column] !== undefined) {
					value = Math.round(item[column] * 100) / 100;
					value = changeYearlyToMonthly ? value / 12 : value;
				} else {
					value = '';
				}

				var currencyFormat = tools.$filter('currencyFormat');

				var displayString = currencyFormat(value, currency);

				return <td key={key}>{displayString}</td>;
			};

			switch (column) {
				case 'client':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						</td>
					);

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText([item.user], tools)}</td>;
					}
				case 'project':
				case 'campaign':
					if (!item.project) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaign}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText([item.project], tools)}</td>;
					}

				case 'contact':
					if (!item.contact) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noContact')}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>{ReactTemplates.TOOLS.contactsText(customerId, [item.contact], tools)}</td>
						);
					}

				case 'description':
					return <td key={key}>{item.description ? item.description : ''}</td>;

				case 'id':
					return <td key={key}>{item.id ? item.id : ''}</td>;

				case 'date':
				case 'closeDate':
					return (
						<td key={key} className="column-date">
							{self.getDate(item.date)}
						</td>
					);

				case 'startDate':
					return <td key={key}>{self.getDate(item.metadata.agreementStartdate)}</td>;

				case 'endDate':
					return <td key={key}>{self.getDate(item.metadata.agreementEnddate)}</td>;

				case 'renewalDate':
					return <td key={key}>{self.getDate(item.metadata.agreementRenewalDate)}</td>;

				case 'nextOrderDate':
					var nextOrderDate = moment(item.metadata.agreementNextOrderDate);

					if (!item.metadata.willCreateMoreOrders) {
						return (
							<td key={key}>
								<i className="text-grey">{statics.noNextOrderDate}</i>
							</td>
						);
					}

					if (
						item.metadata.willCreateMoreOrders &&
						item.metadata.agreementNextOrderDate &&
						nextOrderDate.isBefore(moment())
					) {
						return <td key={key}>{statics.today}</td>;
					}

					return <td key={key}>{self.getDate(item.metadata.agreementNextOrderDate)}</td>;

				case 'intervalPeriod':
					var recurringInterval = _.find(tools.AppService.getStaticValues('recurringInterval'), {
						id: item.metadata.agreementIntervalPeriod + ''
					});
					return <td key={key}>{recurringInterval && recurringInterval.name}</td>;

				case 'yearlyValue':
				case 'value':
				case 'contributionMarginInAgreementCurrency':
				case 'yearlyContributionMarginInAgreementCurrency':
					return displayValue(item);
				case 'contributionMargin':
				case 'yearlyContributionMargin':
					return displayValue(item, true);
				case 'stage':
					return <td key={key}>{item.stage && item.stage.name ? item.stage.name : ''}</td>;

				case 'invoiceStartDate':
					if (!item.metadata.agreementInvoiceStartdate) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key} className="column-date">
							{self.getDate(item.metadata.agreementInvoiceStartdate)}
						</td>
					);

				case 'periodLength':
					return (
						<td key={key}>
							{item.metadata.periodLength === 0 ? statics.untilFurtherNotice : item.metadata.periodLength}
						</td>
					);

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep = item.contact ? item.contact.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);

				case 'noticePeriod':
					return (
						<td key={key}>
							{item.metadata.noticePeriod > 0 ? (
								<Text size="sm">{`${item.metadata.noticePeriod} ${tools.$translate(
									item.metadata.noticePeriod === 1
										? 'agreement.noticePeriod.month'
										: 'agreement.noticePeriod.months'
								)}`}</Text>
							) : (
								<Text size="sm" italic color="grey-10">
									{tools.$translate('default.none')}
								</Text>
							)}
						</td>
					);
				case 'noticeDate':
					return (
						<td key={key}>
							{item.metadata.noticeDate && item.metadata.noticePeriod > 0 ? (
								<Text size="sm">{moment(item.metadata.noticeDate).format('L')}</Text>
							) : null}
						</td>
					);
				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		return (
			<tr key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className="column-tools" style={{ width: '40px' }}>
					<window.InlineConfirm
						show={item.userRemovable}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.agreement'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</window.InlineConfirm>
				</td>
			</tr>
		);
	}
});

import React from 'react';

const QuickNavigation = ({
	lang
}: {
	lang: {
		quickNav: string;
		quickNavExplanation1: string;
		quickNavExplanation2: string;
		quickNavExplanation3: string;
	};
}) => {
	return (
		<div className="search-tips-column">
			<div className="search-tips-column-title">{lang.quickNav}</div>
			<div>
				{lang.quickNavExplanation1}
				<span style={{ paddingLeft: '8px', paddingRight: '8px' }} className="search-tips-keycontainer">
					{':'}
				</span>
				{lang.quickNavExplanation2}
				<span style={{ paddingLeft: '8px', paddingRight: '8px' }} className="search-tips-keycontainer">
					{':go report'}
				</span>
				{/*                     {lang.quickNavExplanation3} //list coming soon!
                    <span
                        style={{paddingLeft: '8px', paddingRight: '8px'}}
                        className="search-tips-keycontainer"
                    >
                        {':commandList'}
                    </span> */}
			</div>
		</div>
	);
};

const SupersearchNoResults = () => {
	const t = Tools.$translate;

	const lang = {
		command: t('TD command'),
		placeholder: t('default.searchUpsales'),
		advancedTitle: t('filters.advancedSearch'),
		noResults: t('default.noResults'),
		typeToSearch: t('default.typeToSearch'),
		client: t('default.accounts'),
		contact: t('default.contacts'),
		activity: t('default.activities'),
		opportunity: t('default.opportunities'),
		order: t('default.orders'),
		name: t('default.name'),
		email: t('default.email'),
		appointment: t('default.appointments'),
		and: t('default.and').toLowerCase(),
		more: t('filters.more').toLowerCase(),
		accountHasActivity: t('search.accountHasActivity'),
		accountHasNoActivity: t('search.accountHasNoActivity'),
		showAll: t('default.showAll'),
		result: {
			client: t('default.accountResult').toLowerCase(),
			contact: t('default.contactResult').toLowerCase(),
			activity: t('default.activityResult').toLowerCase(),
			opportunity: t('default.opportunityResult').toLowerCase(),
			order: t('default.orderResult').toLowerCase(),
			appointment: t('default.appointmentResult').toLowerCase()
		},
		thatMatches: t('default.thatMatches').toLowerCase(),
		inactive: t('default.inactive').toLowerCase(),
		description: t('default.description'),
		get: t('soliditet.get'),
		results: t('default.results').toLowerCase(),
		resultSingular: {
			client: t('default.account').toLowerCase(),
			contact: t('default.contact').toLowerCase(),
			activity: t('default.activity').toLowerCase(),
			opportunity: t('default.opportunity').toLowerCase(),
			order: t('default.order').toLowerCase(),
			appointment: t('default.appointment').toLowerCase()
		},
		searchError: t('listError.default'),
		searchSmartInUpsales: t('navbar.quickSearch.searchSmartInUpsales'),
		phonenumberExplanation: t('navbar.quickSearch.phonenumberExplanation'),
		phone: t('tag.contact.phone'),
		shortcut: t('navbar.quickSearch.shortcut'),
		shortcutExplanation: t('navbar.quickSearch.shortcutExplanation'),
		quickNav: t('navbar.quickSearch.quickNav'),
		quickNavExplanation1: t('navbar.quickSearch.quickNavExplanation.1'),
		quickNavExplanation2: t('navbar.quickSearch.quickNavExplanation.2'),
		quickNavExplanation3: t('navbar.quickSearch.quickNavExplanation.3')
	};

	return (
		<div>
			<span className="search-noresults">{lang.noResults}</span>
			<div className="search-tips">
				<h4 className="search-tips-title">{lang.searchSmartInUpsales}</h4>
				<div className="search-tips-column">
					<div className="search-tips-column-title">{lang.phone}</div>
					<div>{lang.phonenumberExplanation}</div>
				</div>
				<div className="search-tips-column">
					<div className="search-tips-column-title">{lang.shortcut}</div>
					<div>
						{lang.shortcutExplanation}{' '}
						<span
							style={{ width: Tools.browserService.isOSX() ? '30px' : '46px' }}
							className="search-tips-keycontainer"
						>
							{Tools.browserService.isOSX() ? '⌘' : 'CTRL'}
						</span>
						{' + '}
						<span
							style={{ width: Tools.browserService.isOSX() ? '30px' : '40px' }}
							className="search-tips-keycontainer"
						>
							{Tools.browserService.isOSX() ? '⇧' : 'Shift'}
						</span>
						{' + '}
						<span style={{ width: '30px', paddingLeft: '1px' }} className="search-tips-keycontainer">
							{'7'}
						</span>
					</div>
				</div>

				{Tools.FeatureHelper.hasSoftDeployAccess('QUICK_NAVIGATION') ? <QuickNavigation lang={lang} /> : null}
			</div>
		</div>
	);
};

export default SupersearchNoResults;

import JourneyStepDisplay from 'Components/JourneyStepDisplay';

angular.module('upDirectives').directive('upJourneyStepDisplayRoot', [
	'$parse',
	function ($parse) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;
				var entity = null;
				var onSetJourneyStep = $parse($attr.onSetJourneyStep)($scope);

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					if (!entity) {
						return;
					}
					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}

						ReactDOM.render(
							React.createElement(JourneyStepDisplay, {
								id: entity.id,
								disableActions: !entity.userEditable,
								journeyStep: entity.journeyStep,
								onSetJourneyStep: onSetJourneyStep,
								isSubaccount: (!$attr.type || $attr.type === 'client') && !!entity.operationalAccount,
								isInactive: !entity.active,
								type: $attr.type
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch($attr.upJourneyStepDisplayRoot, function (data) {
					entity = data;
					render();
				});

				render();
			}
		};
	}
]);

import moment from 'moment';

angular.module('upFilters').filter('upsalesDate', function () {
	return function (date, hours, tooltip, longMonthName, notZero) {
		var momentDate = moment(date);
		if (momentDate.isValid()) {
			var d = momentDate.calendar();
			var timeFormat = longMonthName ? 'LL' : 'll';

			var output = d;
			if (d === '0') {
				// d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
				if (tooltip) {
					output = momentDate.fromNow();
					return output;
				}
				output = momentDate.format('L');
			} else {
				// day is today, tomorrow or yesterday
				if (tooltip) {
					output = momentDate.format(timeFormat);
				}
			}

			if (hours === 'a') {
				output = output + ' ' + momentDate.format('hh:mm A');
			} else if (hours === 'today') {
				if (d !== '0') {
					var time = momentDate.format('HH:mm');
					if (!notZero || time !== '00:00') {
						output = output + ' ' + time;
					}
				}
			} else if (hours === 'atoday') {
				if (d !== '0') {
					output = output + ' ' + momentDate.format('hh:mm A');
				}
			} else if (hours) {
				output = output + ' ' + momentDate.format('HH:mm');
			}
			return output;
		}
		return date;
	};
});

'use strict';

angular.module('upResources').factory('PhoneCall', [
	'$resource',
	'$q',
	'URL',
	'API',
	'PhoneCallAttributes',
	'GenericMapper',
	'ResourceHelper',
	'ParseGeneric',
	function PhoneCall($resource, $q, URL, API, PhoneCallAttributes, GenericMapper, ResourceHelper, ParseGeneric) {
		var PA = PhoneCallAttributes();
		var Attribute = PA.attr;

		var helper = new ResourceHelper();
		var baseUrl = URL + API + 'phoneCall/';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				delete: {
					method: 'DELETE',
					isArray: false
				},
				runAction: {
					method: 'POST',
					isArray: false,
					url: URL + API + 'function/voice/'
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				return Resource.find(angular.extend(filter, options)).$promise;
			},
			get: function (id) {
				if (!id) {
					return $q.reject('No call id');
				}
				return Resource.get({ id: id }).$promise;
			},
			save: function (call, options) {
				var defered = $q.defer();

				if (!call) {
					return defered.reject('no call');
				}

				if (call.id) {
					Resource.update(
						{ id: call.id },
						{ voice: call },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Resource.create(
						call,
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						helper.onSave(options, res.data, !call.id);
					})
					.catch(function () {
						helper.onSaveError(options, !call.id);
					});

				return defered.promise;
			},
			delete: function (call, options) {
				return Resource.delete({ id: call.id })
					.$promise.then(function (res) {
						helper.onDelete(options, call);
						return res;
					})
					.catch(function () {
						helper.onDeleteError(options);
					});
			},
			runAction: function (data) {
				return Resource.runAction(data).$promise;
			},
			attr: Attribute,
			keys: PA.keys,
			requiredFields: PA.requiredFields
		};

		return Model;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SystemNotification, Progressbar, Text, Button, Icon, ButtonGroup, Block } from '@upsales/components';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	removeNotification,
	holdHideNotification,
	resumeHideNotification,
	TYPE
} from 'Store/reducers/SystemNotificationReducer';

import './SystemNotifications.scss';
import { PrimaryButton, ThirdButton, DefaultButton, DangerButton } from '@upsales/components/Buttons';

const mapStateToProps = state => ({
	notifications: state.SystemNotification.notifications,
	total: state.SystemNotification.total
});

const mapDispatchToProps = {
	removeNotification,
	holdHideNotification,
	resumeHideNotification
};

class SystemNotifications extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hidden: false };
	}
	toggleNotifications = () => {
		this.setState({ hidden: !this.state.hidden });
	};
	render() {
		const classes = new BemClass('SystemNotifications').mod({
			hidden: this.state.hidden
		});
		return (
			<div className={classes.b()}>
				<div className={classes.elem('list').b()}>
					{this.props.notifications.map(
						({
							id,
							title,
							style,
							icon,
							body,
							type,
							entity,
							progress,
							description,
							autoHide,
							buttonText,
							onClick,
							onClose = () => {},
							snoozeTime,
							onSnooze,
							updateOutcome,
							integration,
							customBody,
							isSaving,
							visible,
							error
						}) => {
							let notification = null;
							const classes = new BemClass('SystemNotificationWrap').mod({ visible });
							if (type === TYPE.PROGRESS) {
								notification = (
									<SystemNotification
										color={style}
										icon={icon}
										title={title}
										onClose={() => this.props.removeNotification(id)}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id)}
									>
										<Progressbar color="green" size="sm" value={progress} />
									</SystemNotification>
								);
							} else if (type === TYPE.INTEGRATION_ERROR) {
								const showLink =
									integration &&
									(integration.userConfigurable || Tools.AppService.getSelf().administrator);
								const goToLog = () => {
									this.props.removeNotification(id);
									Tools.$state.go('administration.integration', {
										id: integration.id,
										configure: integration.userConfigurable ? 'user' : 'client',
										tab: 'support'
									});
								};
								notification = (
									<SystemNotification
										color={style}
										icon={icon}
										title={title}
										onClose={() => this.props.removeNotification(id)}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id)}
										body={body}
									>
										{showLink ? (
											<DangerButton onClick={goToLog}>{`${T('integration.status.showLog')} ${T(
												'default.for'
											).toLowerCase()} ${integration.name}`}</DangerButton>
										) : null}
									</SystemNotification>
								);
							} else if (type === TYPE.REMINDER) {
								const snoozeText = T('default.snooze') + ' ' + snoozeTime + ' ' + T('default.min');
								const isAppointmentOutcome = entity === 'appointmentOutcome';
								const doClose = () => {
									onClose();
									this.props.removeNotification(id);
								};

								notification = (
									<SystemNotification
										color={style}
										title={title}
										onClose={() => {
											doClose();
										}}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id, autoHide)}
										icon={icon}
									>
										<Block space={!isAppointmentOutcome || description ? 'mbm' : undefined}>
											{!isAppointmentOutcome ? <Text color="grey-11">{body}</Text> : null}
											{description ? (
												<Text ellipsis color="grey-11">
													{description}
												</Text>
											) : null}
										</Block>
										{isAppointmentOutcome ? (
											<React.Fragment>
												<Block space="mbm">
													<ButtonGroup block>
														<DefaultButton
															disabled={isSaving}
															size="sm"
															onClick={() => {
																updateOutcome(id, true, doClose);
															}}
														>
															<Icon name="check" space="mrs" />
															{T('appointment.outcome.wasCompleted')}
														</DefaultButton>
														<DefaultButton
															disabled={isSaving}
															size="sm"
															onClick={() => {
																updateOutcome(id, false, doClose);
															}}
														>
															<Icon name="ban" space="mrs" />
															{T('appointment.outcome.wasNotCompleted')}
														</DefaultButton>
													</ButtonGroup>
												</Block>
												<ThirdButton
													size="sm"
													onClick={() => {
														onClick();
														doClose();
													}}
												>
													{buttonText}
												</ThirdButton>
												<ThirdButton
													size="sm"
													type="link"
													onClick={() => {
														onSnooze();
														doClose();
													}}
												>
													{snoozeText}
												</ThirdButton>
											</React.Fragment>
										) : (
											<React.Fragment>
												<PrimaryButton
													size="sm"
													onClick={() => {
														onClick();
														doClose();
													}}
												>
													{buttonText}
												</PrimaryButton>
												<ThirdButton
													size="sm"
													type="link"
													onClick={() => {
														onSnooze();
														doClose();
													}}
												>
													{snoozeText}
												</ThirdButton>
											</React.Fragment>
										)}
									</SystemNotification>
								);
							} else if (type === TYPE.GENERIC_ENTITY_ADDED) {
								classes.add('SystemNotification_genericAdd');
								notification = (
									<SystemNotification
										color={style}
										title={title}
										onClose={() => this.props.removeNotification(id)}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id)}
										icon={icon}
										onClick={() => customBody.onclickLink.clicked(customBody.data)}
										body={body}
									>
										<PrimaryButton size="sm">{T(customBody.onclickLink.text)}</PrimaryButton>
									</SystemNotification>
								);
							} else if (type === TYPE.MISSING_ACCOUNT_PROFILE) {
								notification = (
									<SystemNotification
										color={style}
										title={title}
										onClose={() => this.props.removeNotification(id)}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id)}
										icon={icon}
										onClick={() => customBody.onclickLink.clicked(customBody.data)}
										body={body}
									>
										<PrimaryButton size="sm">{T(customBody.onclickLink.text)}</PrimaryButton>
									</SystemNotification>
								);
							} else {
								let onClickHandler,
									bodyMsg = body;
								if (error?.key === 'DeleteForbiddenRelatedEntities') {
									onClickHandler = () => openModal('DeleteForbiddenAlert', { error });
									bodyMsg = T(error.msg);
								} else if (error?.key === 'RemovedConnectedUnsub') {
									onClickHandler = () => openModal('DeleteForbiddenAlert', { error });
									bodyMsg = T(error.msg);
								}

								notification = (
									<SystemNotification
										color={style}
										title={title}
										onClick={onClickHandler}
										onClose={() => this.props.removeNotification(id)}
										onMouseEnter={() => this.props.holdHideNotification(id)}
										onMouseLeave={() => this.props.resumeHideNotification(id)}
										icon={icon}
										body={bodyMsg}
									/>
								);
							}

							return (
								<div className={classes.b()} key={'notification-' + id}>
									{notification}
								</div>
							);
						}
					)}
				</div>
				<Button
					className={classes
						.elem('hideButton')
						.mod({ visible: this.props.notifications.length > 0 })
						.b()}
					color="white"
					onClick={this.toggleNotifications}
				>
					<Icon name="bell" space="mrs"></Icon>
					{this.state.hidden ? T('notifications.showNotifications') : T('notifications.hideNotifications')}
					<Icon name={this.state.hidden ? 'chevron-up' : 'chevron-down'} space="mls"></Icon>
				</Button>
			</div>
		);
	}
}

SystemNotifications.propTypes = {
	notifications: PropTypes.array.isRequired,
	removeNotification: PropTypes.func,
	holdHideNotification: PropTypes.func,
	resumeHideNotification: PropTypes.func,
	integration: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.number,
		userConfigurable: PropTypes.bool
	})
};

SystemNotifications.defaultProps = {
	notifications: []
};

export const detached = SystemNotifications;
export default connect(mapStateToProps, mapDispatchToProps)(SystemNotifications);

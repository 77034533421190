import React, { useState, useEffect } from 'react';
import { Card, Button, Icon, Text, Loader, Checkbox, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { getFileIcon } from 'App/babel/helpers/file';
import translate from 'Components/Helpers/translate';
import './FileBrowserFile.scss';
import LazyLoadBackgroundImg from 'Components/Helpers/LazyLoadBackgroundImg';
import File from 'App/resources/Model/File';

interface FileBrowserFileProps {
	file: File;
	deleteFile: (file: File) => void;
	onSelect: (file: File) => void;
	isChecked: boolean;
	checkFile: (checked: boolean, file: File) => void;
}

const FileBrowserFile: React.FC<FileBrowserFileProps> = ({ file, deleteFile, onSelect, isChecked, checkFile }) => {
	const [dimensions, setDimensions] = useState<string>('');
	const [isMounted, setIsMounted] = useState<boolean>(false);
	const hasFileLabel = Tools.FeatureHelper.hasSoftDeployAccess('FILE_METADATA_LABEL');

	useEffect(() => {
		setIsMounted(true);
		return function cleanup() {
			setIsMounted(false);
		};
	}, []);

	const labels = file.labels ? [...file.labels] : [];

	if (file.entity === 'Contact' || file.entity === 'Client') {
		labels.push({ name: translate('file.label.standard.customerCard'), entity: 'FileMetadata' });
	} else if (file.entity === 'ProfileImage') {
		labels.push({ name: translate('file.label.standard.profilePictures'), entity: 'FileMetadata' });
	}

	const classes = new bemClass('FileBrowserFile');
	const isImage = file.mimetype && file.mimetype.split('/')[0] === 'image';
	const preview = isImage ? (
		<LazyLoadBackgroundImg
			className={classes.elem('preview').b()}
			background={file.$$previewUrl}
			onLoad={meta => {
				if (isMounted) {
					setDimensions(`${meta.naturalWidth}x${meta.naturalHeight}`);
				}
			}}
			asImg
			preventLoad={file.preventLoad}
		/>
	) : (
		<div className={classes.elem('preview').b()}>
			<Icon name={getFileIcon(file.mimetype, '', '')} />
		</div>
	);

	return (
		<Card className={classes.b()}>
			{file.loading ? (
				<div className={classes.elem('loader').b()}>
					<Loader size="xs" />
				</div>
			) : null}
			{!file.loading ? preview : null}

			{!file.loading ? (
				<div className={isChecked ? classes.elem('checked').b() : classes.elem('hover-tools').b()}>
					{hasFileLabel ? (
						<Checkbox
							color="green"
							checked={isChecked}
							className={classes.elem('select').b()}
							onChange={() => checkFile(!isChecked, file)}
							size="sm"
						/>
					) : null}
					<Button
						type="link"
						color="white"
						className={classes.elem('delete').b()}
						onClick={e => {
							e.stopPropagation();
							deleteFile(file);
						}}
					>
						<Icon name="trash" />
					</Button>

					<Button
						className={classes.elem('select').b()}
						gradient={true}
						block={true}
						onClick={!file.loading ? () => onSelect(file) : () => {}}
					>
						{translate('mail.selectImage')}
					</Button>
				</div>
			) : null}

			<div className={classes.elem('info').b()}>
				<Text bold={true}>{file.filename}</Text>
				{hasFileLabel ? (
					labels.length ? (
						<div className={classes.elem('extra-info').b()}>
							{labels.map(label => {
								return (
									<div key={label.name} className={classes.elem('label').b()}>
										<Icon name="tag" />
										<Tooltip title={label.name} position="bottom">
											<Text color="grey-11" size="sm">
												{label.name}
											</Text>
										</Tooltip>
									</div>
								);
							})}
						</div>
					) : null
				) : null}
			</div>

			{dimensions ? (
				<div className={classes.elem('dimensions').b()}>
					<Text color="grey-10" size="sm">
						{dimensions}
					</Text>
				</div>
			) : null}
		</Card>
	);
};

export default FileBrowserFile;

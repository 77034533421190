import Subscriptions from '../../../../../babel/components/Admin/Subscriptions/Subscriptions';

angular.module('domain.admin').controller('AgreementAdmin', [
	'$scope',
	'AppService',
	'AgreementSettings',
	'$q',
	'$safeApply',
	function ($scope, AppService, AgreementSettings, $q, $safeApply) {
		var AdminCtrl = $scope.AdminCtrl,
			orginalObject,
			orderCustomFields,
			orderrowCustomFields;
		var AdminSubCtrl = this;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = Subscriptions;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.saveVisible = false;
		AdminSubCtrl.rootData.pageData = {
			itr: 0
		};

		var setCustomFields = function (domain) {
			switch (domain) {
				case 'ORDER':
					AdminSubCtrl.rootData.pageData.customFields = orderCustomFields;
					break;
				case 'ORDER_PRODUCT':
					AdminSubCtrl.rootData.pageData.customFields = orderrowCustomFields;
					break;
				default:
					AdminSubCtrl.rootData.pageData.customFields = [];
			}
		};

		AdminSubCtrl.rootData.pageData.onChange = function (setting, value) {
			switch (setting) {
				case 'startdate':
					AdminSubCtrl.rootData.pageData.settings.customStartDate =
						value.target.value !== '' ? value.target.value : null;
					break;
				case 'enddate':
					AdminSubCtrl.rootData.pageData.settings.customEndDate =
						value.target.value !== '' ? value.target.value : null;
					break;
				case 'domain':
					AdminSubCtrl.rootData.pageData.settings.customDateDomain = value;
					AdminSubCtrl.rootData.pageData.settings.customStartDate = null;
					AdminSubCtrl.rootData.pageData.settings.customEndDate = null;
					setCustomFields(value);
					++AdminSubCtrl.rootData.pageData.itr;
					break;
				case 'period':
					AdminSubCtrl.rootData.pageData.settings.defaultPeriod = value.target.value;

					if (
						AdminSubCtrl.rootData.pageData.settings.defaultPeriod <
						AdminSubCtrl.rootData.pageData.settings.defaultNoticePeriod
					) {
						const smaller = AdminSubCtrl.rootData.pageData.settings.noticePeriod.filter(
							v => v <= AdminSubCtrl.rootData.pageData.settings.defaultPeriod
						);

						AdminSubCtrl.rootData.pageData.settings.defaultNoticePeriod = Math.max(...smaller);
					}

					break;
				case 'interval':
					AdminSubCtrl.rootData.pageData.settings.defaultInterval = value.target.value;
					break;
				case 'creationTime':
					AdminSubCtrl.rootData.pageData.settings.defaultCreationTime = value.target.value;
					break;
				case 'enabled':
					AdminSubCtrl.rootData.pageData.settings.enabled = value;
					AdminSubCtrl.rootData.onSave();
					break;
				case 'stage':
					AdminSubCtrl.rootData.pageData.settings.defaultStage = value.id;
					break;
				case 'lockedStages':
					AdminSubCtrl.rootData.pageData.settings.lockedStages = value;
					break;
				case 'adminCanEditLockedStage':
					AdminSubCtrl.rootData.pageData.settings.adminCanEditLockedStage = value;
					break;
				case 'noticePeriod':
					AdminSubCtrl.rootData.pageData.settings.noticePeriod = value;
					break;
				case 'defaultNoticePeriod':
					AdminSubCtrl.rootData.pageData.settings.defaultNoticePeriod = value;
					break;
				case 'defaultCreationTime':
					AdminSubCtrl.rootData.pageData.settings.defaultCreationTime = value;
					break;
				case 'inAdvance':
					AdminSubCtrl.rootData.pageData.settings.inAdvance = value;
					break;
				case 'preCreate':
					AdminSubCtrl.rootData.pageData.settings.preCreate = value;
					break;
			}

			var dirty = !_.isEqual(orginalObject, AdminSubCtrl.rootData.pageData.settings);
			AdminSubCtrl.rootData.saveVisible = dirty ? true : false;
			$safeApply($scope);
		};

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;
			AgreementSettings.save(AdminSubCtrl.rootData.pageData.settings)
				.then(function () {
					orginalObject = _.cloneDeep(AdminSubCtrl.rootData.pageData.settings);

					var metadata = AppService.getMetadata();
					metadata.params.AgreementEnabled = orginalObject.enabled;
					metadata.params.SubscriptionDefaultInterval = orginalObject.defaultInterval;
					metadata.params.SubscriptionDefaultPeriod = orginalObject.defaultPeriod;
					metadata.params.SubscriptionDefaultCreationTime = orginalObject.defaultCreationTime;
					metadata.params.SubscriptionDefaultStageId = orginalObject.defaultStage;
					metadata.params.SubscriptionLockedStages = orginalObject.lockedStages;
					metadata.params.SubscriptionAdminCanEditLockedStage = orginalObject.adminCanEditLockedStage;
					metadata.params.SubscriptionNoticePeriod = orginalObject.noticePeriod;
					metadata.params.SubscriptionDefaultNoticePeriod = orginalObject.defaultNoticePeriod;
					metadata.params.SubscriptionInAdvanceOptions = orginalObject.inAdvance;
					metadata.params.SubscriptionDefaultCreationTime = orginalObject.defaultCreationTime;
					metadata.params.AgreementPrecreate = orginalObject.preCreate;

					AppService.setMetadata(metadata);

					AdminSubCtrl.rootData.saving = false;
					AdminSubCtrl.rootData.saveVisible = false;
				})
				.catch(function () {
					AdminSubCtrl.rootData.saving = false;
				});
		};

		$q.all({
			settings: AgreementSettings.get(),
			appService: AppService.loadedPromise
		})
			.then(function (res) {
				var settings = res.settings.data;
				orginalObject = _.cloneDeep(settings);
				orderCustomFields = _.filter(AppService.getCustomFields('order'), { datatype: 'Date' }).map(function (
					cf
				) {
					return _.pick(cf, ['id', 'name']);
				});
				orderrowCustomFields = _.filter(AppService.getCustomFields('orderrow'), { datatype: 'Date' }).map(
					function (cf) {
						return _.pick(cf, ['id', 'name']);
					}
				);

				setCustomFields(settings.customDateDomain);

				AdminSubCtrl.rootData.pageData.settings = settings;
				AdminSubCtrl.rootData.pageLoading = false;
			})
			.catch(e => console.log('Failed to init agreement ctrl', e));
	}
]);

import MailBounceLabel from 'App/babel/components/Misc/MailBounceLabel';
import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';

angular.module('upDirectives').directive('upEventMail', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventMail.tpl.html?file'),
		require: 'upEventMail',
		scope: {
			event: '=upEventMail'
		},
		controller: [
			'$scope',
			'$stateParams',
			'RequestBuilder',
			'$upModal',
			'Mail',
			function ($scope, $stateParams, RequestBuilder, $upModal, Mail) {
				$scope.customerId = Tools.AppService.getCustomerId();
				$scope.event.$$expand = 1;
				$scope.ifMailBounced = ['hard_bounce', 'soft_bounce', 'unsub'].includes($scope.event.subType);
				$scope.event.mailSender = { email: $scope.event.mail.from, name: $scope.event.mail.fromName };
				$scope.event.sentWithAlias =
					($scope.event.users?.length === 1 &&
						$scope.event.users[0].email !== $scope.event.mailSender.email) ||
					!$scope.event.users;

				$scope.MailBounceLabel = MailBounceLabel;
				$scope.showBounceLabel =
					Tools.FeatureHelper.hasSoftDeployAccess('BOUNCE_REASONS') && !!$scope.event.value;

				$scope.openMail = function () {
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
						openDrawer('SentMail', {
							mail: { id: $scope.event.entityId }
						});
					} else {
						$upModal.open('sentMail', { id: $scope.event.entityId, customerId: $stateParams.customerId });
					}
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}

					$scope.event.$$loading = true;
					var mailFilter = RequestBuilder();
					mailFilter.addFilter(Mail.attr.id, mailFilter.comparisonTypes.Equals, $scope.event.entityId);

					Mail.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data || !res.data.events || !res.data.events.length) {
								$scope.event.$$loading = false;
								return;
							}
							$scope.event.$$eventDetails = _.sortBy(res.data.events).reverse();
							$scope.event.users = res.data.users;
							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
						})
						.catch(error => logError(error));
				};
			}
		]
	};
});

ReactTemplates.esignAppPlaceholder = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			availableEsignApps: t('esign.availableEsignApps'),
			esignInfoTitle: t('onboarding.list.esign.title'),
			esignInfoBody: t('onboarding.list.esign.body'),
			whatService: t('esign.whatService'),
			appMissing: t('esign.yourAppMissing'),
			readMoreAbout: t('esign.readMoreAbout'),
			ourAppsHere: t('esign.ourAppsHere').toLowerCase()
		};
	},

	onClick: function (integration) {
		if (this.props.selectMode) {
			this.props.onClick(integration);
		} else {
			Tools.$state.go('administration.integration', { id: integration.id });
		}
	},

	render: function () {
		if (!this.props.visible) {
			return null;
		}

		if (this.props.loading) {
			return (
				<div className="esign-app-placeholder-inner">
					<ReactTemplates.loader />
				</div>
			);
		}

		var self = this;
		var integrations = _.map(this.props.integrations, function (integration, i) {
			return (
				<ReactTemplates.appPreview
					key={'integration-' + i}
					integration={integration}
					onClick={function () {
						self.onClick(integration);
					}}
				/>
			);
		});

		return (
			<div className="esign-app-placeholder-inner">
				{this.props.selectMode ? (
					<div className="esign-app-placeholder-header select-mode-header">{this.lang.whatService}</div>
				) : (
					<div>{this.lang.availableEsignApps}</div>
				)}
				<div>{integrations}</div>
				{!this.props.selectMode && (
					<div>
						<div className="esign-app-placeholder-header">{this.lang.esignInfoTitle}</div>
						<div className="esign-app-placeholder-info">{this.lang.esignInfoBody}</div>
						<div>
							{this.lang.appMissing} {this.lang.readMoreAbout}{' '}
							<a
								href={Tools.$state.href('administration.integrations', {
									customerId: Tools.AppService.getCustomerId()
								})}
							>
								{this.lang.ourAppsHere}
							</a>
						</div>
					</div>
				)}
			</div>
		);
	}
});

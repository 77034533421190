'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('AppointmentType', [
	'$resource',
	'$q',
	'AppointmentTypeAttributes',
	'URL',
	'API',
	'CacheService',
	'ResourceHelper',
	function AppointmentType($resource, $q, AppointmentTypeAttributes, URL, API, CacheService, ResourceHelper) {
		var Attribute = AppointmentTypeAttributes().getAll();
		var methods = {
			query: {
				method: 'GET',
				isArray: false
			},
			put: {
				method: 'PUT',
				isArray: false
			},
			post: {
				method: 'POST',
				isArray: false
			},
			merge: {
				method: 'PUT',
				isArray: false,
				url: URL + API + 'activitytypes/appointment/merge/:keep/with/:merge'
			}
		};

		var Resource = $resource(URL + API + 'activitytypes/appointment/:id', {}, methods);
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'appointmentType'
		});

		var Model = {
			find: function (request) {
				return Resource.get(request).$promise.then(
					function (response) {
						return response;
					},
					function (response) {
						return response.status;
					}
				);
			},

			save: function (appointment, options) {
				var promise;

				if (appointment.id) {
					var params = { id: appointment.id };
					promise = Resource.put(params, appointment).$promise;
				} else {
					promise = Resource.post(appointment).$promise;
				}

				promise
					.then(function (res) {
						helper.onSave(options, res.data, !appointment.id);
						AllIWantDataCache.clearData();
					})
					.catch(function () {
						helper.onSaveError(options, !appointment.id);
					});

				return promise;
			},

			merge: function (keepId, mergeId, options) {
				var params = {
					keep: keepId,
					merge: mergeId
				};
				var promise = Resource.merge(params, {}).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res, false);
						helper.onDelete(options, { id: mergeId }, false);
						AllIWantDataCache.clearData();
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, false, err);
					});

				return promise;
			},

			customer: function () {
				var instance = {};
				var Cache = CacheService.getCache(CacheService.cacheTypes.APPOINTMENTTYPE);

				instance.find = function (request) {
					var defered = $q.defer();

					Resource.get(request, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.get = function (id) {
					var defered = $q.defer();

					Resource.get({ id: id }, function (response) {
						defered.resolve(response.data);
					});

					return defered.promise;
				};

				instance.save = function () {};

				instance['delete'] = function () {};

				instance.getCached = function () {
					var key = 'AppointmentTypes';
					var cached = Cache.get(key);

					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find({}).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);

import { Button, Help, Title, Text } from '@upsales/components';
import PropTypes from 'prop-types';
import React from 'react';
import AnonCheckBox from './CheckBox';
import AnonFieldPage from './fieldPage/fieldPage';
import SettingsToggle from 'App/components/SettingsToggle';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';

const mapTypeToElevio = type => {
	const map = {
		client: 569,
		activity: 571,
		appointment: 572,
		opportunity: 573,
		optin: 574,
		recent: 575
	};

	return map[type];
};

const getCheckObject = type => {
	return {
		type: type,
		title: T('admin.anonymization.list.checks.' + type + '.title'),
		text: T('admin.anonymization.list.checks.' + type + '.text'),
		elevio: mapTypeToElevio(type)
	};
};

class AnonCard extends React.Component {
	constructor(props) {
		super(props);
		this.list = [
			getCheckObject('client'),
			getCheckObject('activity'),
			getCheckObject('appointment'),
			getCheckObject('opportunity'),
			getCheckObject('optin'),
			getCheckObject('recent')
		];
		this.state = {
			emailSuggestions: Tools.AppService.getSelf().userParams.emailSuggestions
		};
	}

	toggleChange = async () => {
		const self = Tools.AppService.getSelf();
		const receiveSuggestions = self.userParams.emailSuggestions;

		try {
			await Tools.UserParam.save(72, !receiveSuggestions, {
				skipNotification: true,
				skipErrorNotification: false
			});
			Tools.AppService.setSelf({
				...self,
				userParams: {
					...self.userParams,
					emailSuggestions: !receiveSuggestions
				}
			});
			this.setState({
				emailSuggestions: !receiveSuggestions
			});
		} catch (err) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
		}
	};

	generateInput(number, type, changeFunc) {
		if (number === '') {
			number = 0;
		}

		return (
			<input
				key={type}
				className="form-control input-sm"
				style={{
					display: 'inline-block',
					width: '50px',
					padding: '3px 6px',
					height: '30px',
					textAlign: 'center'
				}}
				value={number}
				onChange={event => {
					changeFunc(type, event.target.value);
				}}
			/>
		);
	}

	generateCheckList() {
		const { config, onRecentChange } = this.props;
		return _.map(this.list, item => {
			let title = item.title,
				text = item.text;

			if (item.type === 'recent' || item.type === 'client') {
				if (title.includes('###')) {
					const titleArray = title.split('###');
					const customItem = this.generateInput(
						config && config[item.type].selected,
						item.type,
						onRecentChange
					);
					titleArray.splice(1, 0, customItem);
					title = titleArray;
				} else {
					const errorMsg = 'Something wrong with the language tag';
					logError(new Error(errorMsg), errorMsg);
				}
				if (text.includes('###')) {
					let newTextReplacer = config && config[item.type].selected;
					if (config && config[item.type].selected === '') {
						newTextReplacer = 0;
					}
					text = text.replace('###', newTextReplacer);
				} else {
					const errorMsg = 'Something wrong with the language tag';
					logError(new Error(errorMsg), errorMsg);
				}
			}

			return (
				<AnonCheckBox
					key={item.type}
					customTitle={title}
					customText={text}
					config={this.props.config}
					updateConfig={this.props.updateConfig}
					onChange={() => this.props.onChange(item.type)}
					checked={this.props.checks[item.type]}
					{...item}
				/>
			);
		});
	}

	render() {
		const { switchTab, buttonTextNumber, rootData, fields, selectField, selectAllFields, loading } = this.props;

		const rightColumn = <img src="img/email-suggestions-illustration.svg" alt="" />;

		return (
			<div className="card-holder">
				<div className="card no-padding">
					<SettingsToggle
						title={T('admin.anonymization.emailSuggestions')}
						descriptionText={T('admin.anonymization.emailSuggestionsDesc')}
						toggleText={T('admin.anonymization.turnOffEmailSuggestions')}
						onToggleChange={this.toggleChange}
						toggleChecked={!this.state.emailSuggestions}
						rightColumn={rightColumn}
						articleId={1005}
						articleText={T('default.readMore')}
					/>
				</div>
				<div className="card">
					<Title className="regular-table-title main-paragraph">
						{Tools.$translate('admin.anonymization.contactsThat')}
						<span className="important">{Tools.$translate('admin.anonymization.doesNotMeet')}</span>
						{Tools.$translate('admin.anonymization.ofTheRequirementsBelow')}
					</Title>
					<Text className="subheader" color="grey-11">
						{T('admin.anonymization.subheader')}
					</Text>
					<div className="list-of-checks">{this.generateCheckList()}</div>
					<Button
						className="huge-button-full-width"
						size="xl"
						block
						loading={loading}
						disabled={!Object.keys(rootData.pageData.query).length}
						onClick={function () {
							switchTab('contacts');
						}}
					>
						{`${Tools.$translate('admin.anonymization.contactsToAnonymize_button')} (${
							Object.keys(rootData.pageData.query).length ? buttonTextNumber : 0
						})`}
					</Button>
				</div>

				<div className="card fields">
					<h2 className="regular-table-title">
						{Tools.$translate('admin.anonymization.whatFieldsToMask')}
						<Help articleId={576} sidebar />
					</h2>
					<AnonFieldPage
						selectedFields={fields && fields.selectedFields}
						obligatoryFields={fields && fields.obligatoryFields}
						entities={rootData.pageData.entities}
						selectField={selectField}
						selectAllFields={selectAllFields}
					/>
				</div>
			</div>
		);
	}
}
AnonCard.defaultProps = {
	fields: {
		selectedFields: []
	},
	config: {
		recent: {
			selected: 30
		},
		client: {
			available: true
		}
	},
	rootData: {
		pageData: {}
	}
};
AnonCard.propTypes = {
	checks: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	switchTab: PropTypes.func.isRequired,
	onRecentChange: PropTypes.func,
	updateConfig: PropTypes.func,
	buttonTextNumber: PropTypes.number,
	selectField: PropTypes.func,
	loading: PropTypes.bool,
	selectAllFields: PropTypes.func,
	onChange: PropTypes.func,
	rootData: PropTypes.shape({
		pageData: PropTypes.shape({
			currentPage: PropTypes.number
		})
	}),
	fields: PropTypes.shape({
		selectedFields: PropTypes.array,
		obligatoryField: PropTypes.array
	})
};
export default AnonCard;

'use strict';

angular.module('upDirectives').directive('upLoadRow', [
	'$filter',
	function ($filter) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var colspan = attrs.colspan || 100;
				// create row
				var column = angular.element('<td colspan=' + colspan + '><b class="fa fa-refresh fa-spin">');

				element.addClass('load-row').append(column);

				attrs.upLoadRow &&
					scope.$watch(attrs.upLoadRow, function (loading) {
						element[loading ? 'show' : 'hide']();
					});
			}
		};
	}
]);

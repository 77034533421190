'use strict';

angular.module('upDirectives').directive('upMailCampaignRoot', [
	'$translate',
	'$upModal',
	'EditorCk',
	'$datepicker',
	'$timepicker',
	'$rootScope',
	'CountryCodes',
	'AppService',
	'$location',
	'ActivityType',
	'User',
	'Order',
	'Campaign',
	'Account',
	'OrderStage',
	'RequestBuilder',
	'$q',
	'AppointmentType',
	'Role',
	'CustomField',
	'$state',
	'$filter',
	'avatarService',
	'$multiSelect',
	'API',
	'URL',
	function (
		$translate,
		$upModal,
		EditorCk,
		$datepicker,
		$timepicker,
		$rootScope,
		CountryCodes,
		AppService,
		$location,
		ActivityType,
		User,
		Order,
		Campaign,
		Account,
		OrderStage,
		RequestBuilder,
		$q,
		AppointmentType,
		Role,
		CustomField,
		$state,
		$filter,
		avatarService,
		$multiSelect,
		API,
		URL
	) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;
				var staticData = {};
				var rootData = {};

				var tools = {
					$translate: $translate.instant,
					$multiSelect: $multiSelect,
					$upModal: $upModal,
					EditorCk: EditorCk,
					$datepicker: $datepicker,
					$timepicker: $timepicker,
					$rootScope: $rootScope,
					CountryCodes: CountryCodes,
					AppService: AppService,
					$location: $location,
					ActivityType: ActivityType,
					User: User,
					Order: Order,
					Campaign: Campaign,
					Account: Account,
					OrderStage: OrderStage,
					RequestBuilder: RequestBuilder,
					$q: $q,
					AppointmentType: AppointmentType,
					Role: Role,
					CustomField: CustomField,
					$state: $state,
					$filter: $filter,
					avatarService: avatarService,
					API: API,
					URL: URL
				};

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}

						ReactDOM.render(
							React.createElement(ReactTemplates.mailCampaign.root, {
								// Data and stuff
								pageComponent: rootData.pageComponent,
								rootData: rootData,
								staticData: staticData,
								tools: tools
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				// Update data that will not be watched
				$scope.$on('setStaticData', function (e, data) {
					staticData = data;
					render();
				});

				$scope.$watch(
					$attr.upMailCampaignRoot,
					function (data) {
						if (data) {
							rootData = data;
							render();
						}
					},
					true
				);

				render();
			}
		};
	}
]);

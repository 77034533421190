import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline, Button, Row, Column, Label, Toggle } from '@upsales/components';
import { DocumentTemplateState, RowType } from './DocumentTemplateEditor';

interface Props {
	state: DocumentTemplateState;
	row: RowType;
	onChange: Function;
	onClear: Function;
	onDelete: Function;
}

const DocumentContentEditorRowSettings = ({ state, row, onChange, onClear, onDelete }: Props) => {
	const { t } = useTranslation();
	const lang = {
		padding: {
			wPaddings: t('mail.wPaddings'),
			woPaddings: t('mail.woPaddings')
		},
		clearRow: t('mail.clearRow'),
		rowTools: t('mail.rowTools'),
		backgroundColor: t('mailTemplate.rowBackgroundColor'),
		fullWidth: t('default.fullWidth'),
		rowMargins: t('mail.rowMargins'),
		deleteRow: t('editor.deleteRow'),
		noCollapse: t('editor.noCollapse'),
		collapseToRows: t('editor.collapseToRows'),
		keepAsColumns: t('editor.keepAsColumns')
	};
	const classes = new bemClass('DocumentTemplateEditorDesign');
	return (
		<div>
			<div className={classes.elem('sidebar-section').b()}>
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Headline size="xs">{lang.rowTools}</Headline>
					</Column>
					<Column align="right">
						<Button type="link" color="grey" onClick={() => onClear()}>
							{lang.clearRow}
						</Button>
					</Column>
				</Row>

				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Label>{lang.backgroundColor}</Label>
					</Column>
					<Column align="right" data-testid="colorpicker-column">
						<ReactTemplates.bannerEditor.colorPicker
							value={row.backgroundColor}
							presets={(state.accountProfile && state.accountProfile.colorVariables) || []}
							onChange={color => onChange({ ...row, backgroundColor: color })}
						/>
					</Column>
				</Row>

				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Label>{lang.fullWidth}</Label>
					</Column>
					<Column align="right" data-testid="toggle-fullwidth-column">
						<Toggle
							color="medium-green"
							checked={row.fullWidth}
							onChange={val => onChange({ ...row, fullWidth: val })}
						/>
					</Column>
				</Row>

				<Button block={true} className={classes.elem('btn-danger').b()} onClick={() => onDelete(row.id)}>
					{lang.deleteRow}
				</Button>
			</div>
		</div>
	);
};

export default DocumentContentEditorRowSettings;

import ReduxRootComponent from 'Components/ReduxComponentRoot';
import SharedViews from 'Components/SharedViews';

angular.module('upDirectives').directive('upSharedViewsRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			let firstElement = $element[0];
			var renderTimeout;
			var dataObject = {};
			let destroyed = false;

			var render = function (dataObject) {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}

				var selectedView = dataObject.selectedView;
				if (selectedView) {
					selectedView.filters = [];
					_.each(dataObject.filters, function (filter) {
						selectedView.filters.push(filter);
					});
				}

				renderTimeout = setTimeout(function () {
					if (!firstElement) {
						return;
					}

					ReactDOM.render(
						React.createElement(
							ReduxRootComponent,
							_.assign(
								{},
								{
									selectedView: selectedView,
									list: Tools.AppService.getListViews(dataObject.type),
									title: dataObject.title,
									total: dataObject.total,
									canBeSaved: dataObject.canBeSaved,
									hasChanged: dataObject.hasChanged,
									listTitle: dataObject.listTitle,
									saveViewAs: dataObject.saveView,
									changeView: dataObject.changeView,
									setAsDefault: dataObject.setAsDefault,
									type: dataObject.type,
									formatTotal: dataObject.formatTotal,
									hideSubtitle: dataObject.hideSubtitle,
									isDisabled: dataObject.isDisabled,
									component: SharedViews
								}
							)
						),
						firstElement,
						function () {}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				destroyed = true;
				clearTimeout(renderTimeout);
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$on('listView.formatTotal', function () {
				/* Dont ask me why angular tries to run these after scope.destory... */
				if (!destroyed) {
					render(dataObject);
				}
			});

			/*
				There seems to be some problem when Filter2.refreshSharedViews runs and there allready is 
				a digest cyckle running, then the watch wont be triggere with the new data. 
				So i changed the safeDiged is Filter2.refreshSharedViews to broadcasting this event.
				I did not want to use listView.formatTotal as it: 
					A: Naming did not realy coincide with what it did anymore then
					B: listView.formatTotal was used on some more places and I wanted to keep the changes as small as possible
					C: listView.formatTotal used stale dataObject so I wanted to pass data in the event.
			*/
			$scope.$on('renderSharedViews', function (event, data) {
				/* Dont ask me why angular tries to run these after scope.destory... */
				if (!destroyed) {
					dataObject = data;
					render(data);
				}
			});

			$scope.$watch(
				$attr.upSharedViewsRoot,
				function (data) {
					dataObject = data;
					render(data);
				},
				!$attr.hasOwnProperty('useShallowWatch')
			);

			render(dataObject);
		}
	};
});

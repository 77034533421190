const createOpportunity = (customerId, appointment, isOrder) => {
	return new Promise((resolve, reject) => {
		if (!appointment.client) {
			return reject('You must select a company before you can create an opportunity from this button.');
		}

		const clientId = appointment.client && appointment.client.id ? appointment.client.id : null;
		const campaign = appointment.project ? appointment.project : null;
		const contactId = appointment.contacts && appointment.contacts.length ? appointment.contacts[0].id : null;

		const OPTIONS = {
			customerId,
			clientId,
			campaign,
			contactId,
			source: {
				type: 'appointment',
				id: appointment.id
			},
			notes: appointment.notes,
			type: isOrder ? 'order' : 'opportunity',
			resolveOnSave: true,
			afterSave: opportunity => resolve(opportunity)
		};
		Tools.$upModal.open('editOrder', OPTIONS).catch(err => reject(err));
	});
};

export default createOpportunity;

import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import getAppSyncStatusFilter from 'App/babel/filters/AppSyncStatus';
import FieldTranslations from 'Resources/FieldTranslations';
import Competitor from 'Resources/Competitor';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';

angular.module('upAttributes').service('OpportunityAttributes', [
	'FilterType',
	'DisplayType',
	'OrderRowAttributes',
	'AppService',
	'FeatureHelper',
	'$translate',
	function (FilterType, DisplayType, OrderRowAttributes, AppService, FeatureHelper, $translate) {
		return function () {
			var metaData = AppService.getMetadata();
			var syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
			var attributes = {
				id: {
					title: 'order.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'id'
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'description',
					selectableColumn: true
				},
				date: {
					title: 'order.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'date',
					selectableColumn: true
				},
				value: {
					title: 'default.value',
					field: 'value',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'valueInMasterCurrency',
					elevioId: 965
				},
				contributionMargin: {
					title: 'default.contributionMargin',
					field: 'contributionMargin',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'contributionMargin'
				},
				contributionMarginInRoleCurrency: {
					title: 'default.contributionMargin',
					field: 'contributionMarginInRoleCurrency',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'contributionMarginInRoleCurrency'
				},
				weightedContributionMarginInRoleCurrency: {
					title: 'default.weightedContributionMarginInRoleCurrency',
					field: 'weightedContributionMarginInRoleCurrency',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'weightedContributionMarginInRoleCurrency'
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'user',
					sortable: 'user.name',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					selectableColumn: true
				},
				users: {
					title: 'default.accountManager',
					field: 'client.user.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					filterName: 'userId',
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'client.user.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'contacts',
					groupable: false,
					sortable: 'contact.name',
					selectableColumn: true,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				probability: {
					title: 'default.probability',
					field: 'probability',
					inputType: 'text',
					type: FilterType.Number,
					displayType: DisplayType.String,
					filterName: 'probability',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'probability',
					selectableColumn: true
				},
				notes: {
					field: 'notes',
					type: FilterType.String
				},
				project: {
					title: 'default.campaigns',
					field: 'project.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'project',
					sortable: 'project',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				product: {
					title: 'default.product',
					field: 'orderRow.product.id',
					inputType: 'select',
					type: FilterType.String,
					displayType: DisplayType.String,
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					filter: {
						multiple: true,
						resource: 'Product',
						type: 'list',
						searchField: 'name',
						displayText: _.property('name'),
						title: 'default.account'
					},
					selectableColumn: true
				},
				orderRow: {
					parent: 'orderRow',
					attr: OrderRowAttributes.attr
				},
				stage: {
					title: 'default.stage',
					field: 'stage.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'orderStage',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: true,
					sortable: 'stage.name',
					selectableColumn: true,
					parent: 'stage',
					attr: {
						id: {
							field: 'stage.id',
							type: FilterType.Number
						},
						name: {
							field: 'stage.name',
							type: FilterType.String
						}
					}
				},
				currency: {
					title: 'default.currency',
					field: 'currency',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				currencyRate: {
					title: 'default.currencyRate',
					field: 'currencyRate',
					type: FilterType.Float,
					displayType: DisplayType.Number
				},
				clientConnection: {
					title: 'default.clientConnection',
					field: 'clientConnection.id',
					displayType: DisplayType.String,
					parent: 'clientConnection',
					attr: {
						id: {
							field: 'clientConnection.id',
							type: FilterType.Number
						},
						name: {
							field: 'clientConnection.name',
							type: FilterType.String
						}
					}
				},
				custom: {},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: syncJourneyStatus
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: !syncJourneyStatus
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: !syncJourneyStatus
				},
				valueInRoleCurrency: {
					title: 'default.valueInRoleCurrency',
					field: 'valueInRoleCurrency',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'valueInRoleCurrency'
				},
				weightedValueInRoleCurrency: {
					title: 'default.weightedValueInRoleCurrency',
					field: 'weightedValueInRoleCurrency',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'weightedValueInRoleCurrency'
				},
				lostReason: {
					title: 'order.lostReason',
					field: 'lostReasonTagId',
					displayAttr: 'value',
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'lostReason.tagId',
					attr: {
						tagId: {
							field: 'lostReason.tagId',
							type: FilterType.Number
						},
						value: {
							field: 'lostReason.value',
							type: FilterType.String
						},
						language: {
							field: 'lostReason.language',
							type: FilterType.String
						}
					}
				},
				competitorId: {
					title: 'order.competitorId',
					field: 'competitorId',
					type: DisplayType.Number,
					selectableColumn: false,
					sortable: 'competitorId',
					attr: {
						id: {
							field: 'competitor.id',
							type: FilterType.Number
						},
						name: {
							field: 'competitor.name',
							type: FilterType.String
						}
					}
				},
				competitor: {
					title: 'order.competitorId',
					field: 'competitor.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'competitor.name',
					attr: {
						id: {
							field: 'competitor.id',
							type: FilterType.Number
						},
						name: {
							field: 'competitor.name',
							type: FilterType.String
						}
					}
				}
			};
			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColumn = true;
			attributes.campaign = angular.copy(attributes.project);

			var dateFilterPresets = [
				'whenever',
				'nextDay',
				'currentDay',
				'nextWeek',
				'currentWeek',
				'nextMonth',
				'currentMonth',
				'nextQuarter',
				'currentQuarter',
				'nextYear',
				'currentYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var standardFilters = {
				Date: {
					field: 'date',
					title: 'order.date',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				Notes: {
					field: 'notes',
					inputType: 'text',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(
								getField('comment.description'),
								rb.comparisonTypes.Search,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter(getField('notes'), rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					},
					title: 'default.notes',
					search: true,
					displayType: 'text'
				},
				Currency: {
					field: 'currency',
					title: 'default.currency',
					inputType: 'select',
					multiple: false,
					// For the old filter (remove when salesboard uses filter-sidebar)
					dataPromise: function ($q, AppService) {
						var currencies = _.sortBy(AppService.getMetadata().customerCurrencies, 'iso');
						return $q.when({
							data: _.map(currencies, function (c) {
								return { id: c.iso, name: c.iso };
							})
						});
					},
					options: function () {
						var currencies = _.sortBy(Tools.AppService.getMetadata().customerCurrencies, [
							'masterCurrency',
							'iso'
						]);
						return _.map(currencies, function (c) {
							return { value: c.iso, text: c.iso };
						});
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: false,
					comparisonType: 'Match',
					selectAllText: function () {
						var currency;
						var role = Tools.AppService.getMetadata().role;
						if (role && role.defaultCurrency) {
							currency = _.find(Tools.AppService.getMetadata().customerCurrencies, {
								iso: role.defaultCurrency
							});
						}

						if (!currency) {
							currency = _.find(Tools.AppService.getMetadata().customerCurrencies, 'masterCurrency');
						}

						return Tools.$translate('default.all') + ' (' + currency.iso + ')';
					},
					hide: true
				},
				ValueRange: {
					field: 'value',
					title: 'default.value',
					type: 'raw',
					inputType: 'range',
					displayType: 'range',
					toUrl: function (filter) {
						var f = { c: filter.comparisonType, v: filter.value };
						if (filter.inactive) {
							f.i = 1;
						} else if (Array.isArray(filter.value) && !filter.value.length) {
							f = undefined;
						}
						return f;
					},
					generate: function () {
						return { value: { start: null, end: null }, comparisonType: null };
					},
					valueSuffix: function (filterProps) {
						var activeFilters = filterProps.activeFilters;
						var suffix = metaData.defaultCurrency.iso.toUpperCase();
						if (
							activeFilters.Currency &&
							activeFilters.Currency.value &&
							activeFilters.Currency.value.length
						) {
							suffix = activeFilters.Currency.value[0];
						}
						return suffix;
					},
					build: function (filter, rb, getField, useTags, activeFilters) {
						var selectedCurrency;
						var metadata = AppService.getMetadata();

						if (
							activeFilters &&
							activeFilters.Currency &&
							activeFilters.Currency.value &&
							activeFilters.Currency.value.length
						) {
							selectedCurrency = _.find(metadata.customerCurrencies, function (currency) {
								return currency.iso.toLowerCase() === activeFilters.Currency.value[0].toLowerCase();
							});
						} else {
							selectedCurrency = metadata.defaultCurrency;
						}

						// start
						if (filter.value.start || filter.value.start === 0) {
							var startOr = rb.orBuilder();
							startOr.next();
							var startMyCurrency = startOr.groupBuilder();
							startMyCurrency.addFilter(
								getField('value'),
								rb.comparisonTypes.GreaterThanEquals,
								filter.value.start
							);
							startMyCurrency.addFilter(
								getField('currency'),
								rb.comparisonTypes.Equals,
								selectedCurrency.iso
							);
							startMyCurrency.done();

							startOr.next();

							var startOtherCurrencies = startOr.groupBuilder();
							startOtherCurrencies.addFilter(
								getField('valueInMasterCurrency'),
								rb.comparisonTypes.GreaterThanEquals,
								Math.round(filter.value.start / selectedCurrency.rate)
							);
							startOtherCurrencies.addFilter(
								getField('currency'),
								rb.comparisonTypes.NotEquals,
								selectedCurrency.iso
							);
							startOtherCurrencies.done();
							startOr.done();
						}

						// end
						if (filter.value.end || filter.value.end === 0) {
							var endOr = rb.orBuilder();
							endOr.next();

							var endMyCurrency = endOr.groupBuilder();
							endMyCurrency.addFilter(
								getField('value'),
								rb.comparisonTypes.LessThanEquals,
								filter.value.end
							);
							endMyCurrency.addFilter(
								getField('currency'),
								rb.comparisonTypes.Equals,
								selectedCurrency.iso
							);
							endMyCurrency.done();

							endOr.next();

							var endOtherCurrencies = endOr.groupBuilder();
							endOtherCurrencies.addFilter(
								getField('valueInMasterCurrency'),
								rb.comparisonTypes.LessThanEquals,
								Math.round(filter.value.end / selectedCurrency.rate)
							);
							endOtherCurrencies.addFilter(
								getField('currency'),
								rb.comparisonTypes.NotEquals,
								selectedCurrency.iso
							);
							endOtherCurrencies.done();
							endOr.done();
						}
					}
				},
				OrderRowProduct: {
					field: 'orderRow.product.id',
					title: 'default.products',
					type: 'raw',
					multiple: true,
					displayText: _.property('name'),
					showInactiveToggle: true,
					toUrl: function (filter) {
						return { v: filter.value, c: filter.comparisonType };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v,
							comparisonType: rawFilter.c
						};
					},
					isInactive: function (filter) {
						return !(filter && filter.value && filter.value.length);
					},
					generate: function () {
						return {
							value: null,
							comparisonType: 'Equals'
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value && filter.value.length) {
							if (filter.comparisonType === 'NotEquals') {
								var groupFilter = rb.groupBuilder();
								groupFilter.isNotFilter();
								groupFilter.addFilter(
									getField('orderRow.product.id'),
									rb.comparisonTypes.Equals,
									filter.value
								);
								groupFilter.done();
							} else {
								rb.addFilter(getField('orderRow.product.id'), rb.comparisonTypes.Equals, filter.value);
							}
						}
					}
				},
				Stage: {
					field: 'stage.id',
					title: 'default.stages',
					inputType: 'select',
					dataPromise: function (customerId, AppService, $q) {
						var opts = {
							secondary: {
								text: 'probability',
								suffix: '%'
							}
						};

						return $q.when([
							{
								data: AppService.getStages('opportunity'),
								title: Tools.$translate('default.opportunity.singular'),
								options: opts
							},
							{ data: AppService.getStages('won'), title: Tools.$translate('order.order'), options: opts }
						]);
					},
					sortOrder: ['probability', 'name'],
					multiple: true,
					multiComparitors: true,
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					listType: 'opportunity'
				},
				ProbabilityRange: {
					field: 'probability',
					title: 'default.probability',
					type: 'range',
					inputType: 'range',
					listType: 'opportunity'
				},
				Probability: {
					field: 'probability',
					title: 'default.probability',
					type: 'text',
					inputType: 'text',
					hide: true
				},
				Campaign: {
					field: 'project.id',
					search: true,
					title: 'default.campaign',
					inputType: 'select',
					multiple: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					initAll: true,
					type: 'list',
					displayType: 'listLazy',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name')
				},
				User: generateUserFilter(AppService.AccessType.ORDER, {
					field: 'user.id',
					title: 'default.users',
					parent: 'default.user'
				}),
				UserRole: {
					field: 'user.role.id',
					title: 'default.role',
					inputType: 'select',
					multiple: true,
					type: 'list',
					dataPromise: function ($q, AppService) {
						return $q.when({ data: AppService.getRoles('order') });
					},
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.user'
				},
				Account: {
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					type: 'list',
					displayType: 'listLazy',
					search: true,
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					title: 'default.account',
					parent: 'default.account'
				},
				AccountManager: generateUserFilter(AppService.AccessType.ACCOUNT, {
					field: 'client.user.id',
					title: 'default.accountManagers',
					parent: 'default.account'
				}),
				AccountCategory: {
					field: 'client.category.id',
					title: 'default.accountCategories',
					inputType: 'select',
					multiple: true,
					dataPromise: function (customerId, $q, filterName, AppService) {
						var defered = $q.defer();
						var categories = AppService.getCategories('account');

						var categoryMap = _.groupBy(categories, 'categoryType');
						if (filterName.indexOf('Category_') === 0) {
							var categoryId = filterName.split('_')[1];
							defered.resolve({ data: categoryMap[categoryId] });
						} else {
							defered.resolve({ data: categoryMap[0] });
						}

						return defered.promise;
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.account'
				},
				Contact: {
					field: 'contact.id',
					inputType: 'select',
					multiple: true,
					resource: 'Contact',
					includeFields: ['name'],
					searchFn: function (Contact, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Contact.attr.name, true);

							if (term) {
								filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
							}

							return Contact.customer(cId).find(filter.build());
						};
					},
					type: 'list',
					displayType: 'listLazy',
					search: true,
					searchField: 'name',
					multiComparitors: true,
					displayText: function (res) {
						return res.name;
					},
					title: 'default.contacts',
					parent: 'filter.orderOther'
				},
				Description: {
					field: 'description',
					title: 'default.description',
					type: 'text',
					inputType: 'text',
					parent: 'filter.orderOther'
				},
				Id: {
					title: 'order.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'number',
					type: 'text',
					parent: 'filter.orderOther'
				},
				OrderRowProductCategory: {
					field: 'product.category.id',
					title: 'default.products',
					multiple: true,
					hide: true,
					type: 'list',
					inputType: 'select'
				},
				AdvancedDate: {
					title: 'default.date',
					type: 'raw',
					hide: true,
					fromUrl: function (f) {
						return {
							value: {
								field: f.v.field,
								start: f.v.start ? new Date(f.v.start) : null,
								end: f.v.end ? new Date(f.v.end) : null,
								preset: 'custom'
							}
						};
					},
					generate: function () {
						return {
							value: {
								field: 'date',
								start: null,
								end: null,
								preset: 'custom'
							}
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;
						var field = filter.value.field;

						if (val.start) {
							rb.addFilter(getField(field), rb.comparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter(getField(field), rb.comparisonTypes.LowerThanEquals, val.end);
						}

						return;
					}
				},
				Age: {
					title: 'default.age',
					type: 'raw',
					hide: true,
					generate: function () {
						return {
							value: {
								value: null,
								comp: 'gte'
							}
						};
					},
					build: function (filter, rb, getField) {
						var value = filter.value.value;
						var comp = filter.value.comp;

						if (value !== undefined && value !== null) {
							var date = moment().utc().subtract(value, 'day').format();

							rb.addFilter(getField('regDate'), comp, date);
						}
					}
				},
				// TODO(Ändra till radio)
				Status: {
					title: 'default.status',
					type: 'raw',
					hide: true,
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: 'all' },
						{ text: 'default.hasOrder', value: 'active' },
						{ text: 'default.hasNoOrder', value: 'lost' }
					],
					generate: function () {
						return {
							value: 'all'
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value === 'all') {
							return;
						} else if (filter.value === 'active') {
							rb.addFilter(getField('probability'), rb.comparisonTypes.GreaterThan, 0);
						} else if (filter.value === 'lost') {
							rb.addFilter(getField('probability'), rb.comparisonTypes.Equals, 0);
						}
					}
				},
				IsOpportunity: {
					title: 'default.probability',
					type: 'raw',
					hide: true,
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value === null) {
							return;
						} else if (filter.value) {
							rb.addFilter(getField('probability'), rb.comparisonTypes.LessThan, 100);
						} else {
							rb.addFilter(getField('probability'), rb.comparisonTypes.Equals, 100);
						}
					}
				},
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'orderRow.product.name' },
								rb.comparisonTypes.Match,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter({ field: 'user.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'description' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				LostReason: {
					field: 'lostReason.tagId',
					title: 'order.lostReason',
					inputType: 'select',
					multiple: true,
					type: 'list',
					selectableColumn: true,
					parent: 'filter.orderOther',
					displayText: _.property('value'),
					dataPromise: function (customerId, $q) {
						var defered = $q.defer();
						var filter = new RequestBuilder();

						filter.addFilter({ field: 'type' }, comparisonTypes.Equals, 'orderlostreason');
						FieldTranslations.find(filter.build())
							.then(response => {
								const translations = response.data.map(translation => ({
									...translation,
									id: translation.tagId
								}));
								defered.resolve({ data: translations });
							})
							.catch(err => {
								logError(err, 'Failed to fetch lost reasons for filter');
								defered.resolve({ data: [] });
							});

						return defered.promise;
					}
				},
				Competitor: {
					field: 'competitorId',
					title: 'order.competitorId',
					inputType: 'select',
					multiple: true,
					type: 'list',
					selectableColumn: true,
					parent: 'filter.orderOther',
					displayText: _.property('value'),
					dataPromise: function (customerId, $q) {
						var defered = $q.defer();
						Competitor.find()
							.then(response => {
								defered.resolve({ data: response.data });
							})
							.catch(err => {
								logError(err, 'Failed to fetch competitors for filter');
								defered.resolve({ data: [] });
							});

						return defered.promise;
					}
				}
			};
			var requiredFields = ['custom'];

			if (AppService.loaded && Tools.AppService.getTotals('products') > 4000) {
				var searchConfig = {
					displayType: 'listLazy',
					search: true,
					searchField: 'name',
					resource: 'Product',
					inputType: 'select',
					searchFn: function () {
						var cId = Tools.AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new Tools.RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Tools.Product.attr.name, true);

							if (term) {
								filter.addFilter(Tools.Product.attr.name, filter.comparisonTypes.Search, term);
							}

							return Tools.Product.customer(cId).find(filter.build());
						};
					}
				};

				standardFilters.OrderRowProduct = _.assign(standardFilters.OrderRowProduct, searchConfig);
			} else {
				var dataPromiseConfig = {
					displayType: 'list',
					inputType: 'selectGroup',
					dataPromise: function (ProductTreeFilterMeta) {
						return ProductTreeFilterMeta(true, '');
					}
				};

				standardFilters.OrderRowProduct = _.assign(standardFilters.OrderRowProduct, dataPromiseConfig);
			}

			if (AppService.loaded) {
				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'nextQuarter', 'nextFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'nextYear', 'nextFiscalYear');
				}

				const orderStatusApps = AppService.getMetadata().integrations.inits.app_status_order;
				if (orderStatusApps?.length) {
					orderStatusApps.forEach(app => {
						attributes[`AppSyncStatus_${app.id}`] = {
							title: $translate.instant('integrations.appSyncStatus', { name: app.name }),
							field: `AppSyncStatus_${app.id}`,
							sortable: `AppSyncStatus_${app.id}`,
							type: FilterType.String,
							displayType: DisplayType.String,
							selectableColumn: true
						};
						standardFilters[`AppSyncStatus_${app.id}`] = getAppSyncStatusFilter(app, 'opportunity');
					});
				}
			}

			var metadata = AppService.getMetadata();
			var currencies = metadata.customerCurrencies;

			if (currencies && currencies.length > 1 && metadata.params.MultiCurrency) {
				standardFilters.Currency.hide = false;
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					DESCRIPTION: 'description',
					DATE: 'date',
					USER: 'user',
					CONTACT: 'contact',
					ACCOUNT: 'account',
					PROBABILITY: 'probability',
					STAGE: 'stage',
					PRODUCT: 'product',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

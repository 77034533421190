import React from 'react';

const CompanyHistory = ({ history, showMarketHistory, theKey }) => {
	const { sales } = history;

	return (
		<div id="company-history">
			<ReactTemplates.TOOLS.leadStatus
				theKey={theKey}
				tools={Tools}
				activity={sales.has.activity}
				activityOld={sales.had.activity}
				appointment={sales.has.appointment}
				appointmentOld={sales.had.appointment}
				opportunity={sales.has.opportunity}
				opportunityOld={sales.had.opportunity}
				order={sales.has.order}
				orderOld={sales.had.order}
			/>
		</div>
	);
};

export default CompanyHistory;

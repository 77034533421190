'use strict';

angular.module('upModals.controllers').controller('UploadFileCtrl', [
	'$scope',
	'$upModal',
	'$modalParams',
	'URL',
	'API',
	'AppService',
	'File',
	function ($scope, $upModal, $modalParams, URL, API, AppService, File) {
		var UploadFileCtrl = this;

		var init = function () {
			var customerId = AppService.getCustomerId();
			var url = URL + API + customerId;
			var eventName = 'file.uploaded';

			if ($modalParams.accountId) {
				url += '/resources/upload/internal/client/' + $modalParams.accountId + '/';
				if ($modalParams.folderId) {
					url += $modalParams.folderId + '/';
				}
			} else if ($modalParams.contactId) {
				url += '/resources/upload/internal/contact/' + $modalParams.contactId + '/';
			} else if ($modalParams.orderId) {
				url += '/resources/upload/internal/order/' + $modalParams.orderId + '/';
			} else if ($modalParams.isCreative) {
				url += '/resources/upload/external/ads/creative/';
			} else if ($modalParams.library) {
				url += '/resources/upload/internal/general/0';
			}

			// Skriv om detta så att det inte är ett specialfall för engage utan snarare en option för att bara välja en fil och sen stänga
			$scope.$on('file.uploaded', function (e, file) {
				// If the public option is set we make file public after it is uploaded
				if ($modalParams.public) {
					File.customer(customerId).makePublic(file.id);
				}
				if ($modalParams.closeOnUpload) {
					$scope.resolve(file);
				}
			});

			$scope.$on('file.uploadFailed', function (e, error) {
				if ($modalParams.closeOnUpload) {
					$scope.reject(error);
				}
			});

			UploadFileCtrl.isCreative = $modalParams.isCreative;

			UploadFileCtrl.title = $modalParams.title || 'default.uploadFile';

			UploadFileCtrl.config = {
				url: url,
				event: eventName
			};
		};

		init();
	}
]);

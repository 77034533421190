import React from 'react';

ReactTemplates.upFilters.filterTypes.text = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			placeholder: 'default.search',
			maxValueDisplayLength: 20,
			msBeforeSearch: 300
		};
	},
	writeTimeout: null,
	writeLock: false,
	didOpen: false,
	getInitialState: function () {
		var value = this.props.filter && this.props.filter.value ? this.props.filter.value : '';
		return {
			value: value,
			expanded: this.props.expanded || false
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		if (this.writeLock === false) {
			var value = newProps.filter && newProps.filter.value ? newProps.filter.value : '';
			this.setState({
				value: value
			});
		}
	},
	UNSAFE_componentWillMount: function () {
		this.search = _.debounce(this.search, this.props.msBeforeSearch);
	},
	componentDidUpdate: function () {
		if (this.state.expanded && this.didOpen) {
			var input = this._input;
			input.focus();
		}
		this.didOpen = false;
	},
	toggleExpanded: function () {
		if (!this.state.expanded) {
			this.didOpen = true;
		}
		this.setState({
			expanded: !this.state.expanded
		});
	},
	search: function (filter, options) {
		this.props.onChange(filter, options);
	},
	onInputChange: function (event) {
		var self = this;
		var value = event.target.value;

		if (this.props.filter.inputType === 'number') {
			value = value.replace(/\D/g, '');
		}

		var active = value ? true : false;
		self.writeLock = true;

		if (self.writeTimeout) {
			clearTimeout(self.writeTimeout);
		}

		self.writeTimeout = setTimeout(function () {
			self.writeLock = false;
		}, 400);

		self.setState({
			value: value
		});

		self.search(
			{
				filterName: self.props.filterName,
				comparisonType: 'Search',
				inactive: !active,
				value: value,
				type: 'text'
			},
			{
				action: 'add'
			}
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.search(this.props.filter, {
			action: 'remove'
		});

		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var valueString = '';

		if (this.props.filter.value) {
			valueString = this.props.filter.value;

			if (valueString.length > this.props.maxValueDisplayLength) {
				valueString = valueString.substring(0, this.props.maxValueDisplayLength) + '...';
			}
		}

		return valueString;
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var filter = this.props.filter;
		var active = this.props.filter.value ? true : false;

		var title = filter.title ? Tools.$translate(filter.title) : this.props.filterName;
		var placeholder = Tools.$translate(this.props.placeholder);
		const type = filter.inputType && filter.inputType !== 'number' ? filter.inputType : 'text';

		return (
			<div data-test-id={this.props['data-test-id']} className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'drilldown text-drilldown'}>
						<input
							maxLength="120"
							type={type}
							className="form-control input-sm"
							placeholder={placeholder}
							value={this.state.value}
							onChange={this.onInputChange}
							ref={this.setRef.bind(this, '_input')}
						/>
					</div>
				)}
			</div>
		);
	}
});

import T from 'Components/Helpers/translate';
import _ from 'lodash';

export const generateProductTableColumns = () => {
	const lang = {
		empty: T('default.empty'),
		deleteRow: T('mailTemplate.deleteRow'),
		duplicateRow: T('mailTemplate.duplicateRow'),
		deleteConfirm: T('mail.deleteConfirm'),
		row: T('mail.deleteBtnRow'),
		addFirstCol: T('mailTemplate.addFirstCol'),
		// Product table
		product: T('default.product'),
		priceOneOff: T('admin.documentTemplateEditor.productTable.priceOneOff'),
		priceOneOffInfo: T('admin.documentTemplateEditor.productTable.priceOneOffInfo'),
		priceRecurring: T('admin.documentTemplateEditor.productTable.priceRecurring'),
		priceRecurringInfo: T('admin.documentTemplateEditor.productTable.priceRecurringInfo'),
		quantity: T('default.quantity'),
		summary: T('admin.documentTemplateEditor.productTable.summary'),
		totalRecurringPrice: T('admin.documentTemplateEditor.productTable.totalPriceRecurring.defaultValue'),
		totalOneOffPrice: T('admin.documentTemplateEditor.productTable.totalPriceOneOff.defaultValue')
	};

	const ProductTableColumns = [
		{
			name: lang.product,
			isActive: true,
			tag: '<p><strong>{{trimString product.name 0 50}}</strong></p>{{#if ../previewMeta.showProductDescription}}{{#if product.description}}<p style="color: rgb(75,85,98);">{{product.description}}</p>{{/if}}{{/if}}',
			type: 'product',
			id: _.uniqueId(),
			title: lang.product,
			align: 'left'
		},
		{
			name: lang.priceRecurring,
			isActive: true,
			tag: '{{#if product.isRecurring}}{{#if ../previewMeta.useDiscount}}{{#if (isDiscount listPrice price)}}<p style="margin: 0;"><s><strong>{{currencyFormatter (math listPrice "*" ../order.currencyRate) ../order.currency}}</strong></s></p>{{/if}}<p style="margin: 0;">{{currencyFormatter (math price "*" ../order.currencyRate) ../order.currency}}</p>{{/if}}{{#unless ../previewMeta.useDiscount}}<p>{{currencyFormatter (math listPrice "*" ../order.currencyRate) ../order.currency}}</p>{{/unless}}{{/if}}',
			description: lang.priceRecurringInfo,
			type: 'priceRecurring',
			id: _.uniqueId(),
			title: lang.priceRecurring,
			align: 'right'
		},
		{
			name: lang.priceOneOff,
			isActive: true,
			tag: '{{#unless product.isRecurring}}{{#if ../previewMeta.useDiscount}}{{#if (isDiscount listPrice price)}}<p style="margin: 0;"><s><strong>{{currencyFormatter (math listPrice "*" ../order.currencyRate) ../order.currency}}</strong></s></p>{{/if}}<p style="margin: 0;">{{currencyFormatter (math price "*" ../order.currencyRate) ../order.currency}}</p>{{/if}}{{#unless ../previewMeta.useDiscount}}<p>{{currencyFormatter (math listPrice "*" ../order.currencyRate) ../order.currency}}</p>{{/unless}}{{/unless}}',
			description: lang.priceOneOffInfo,
			type: 'priceOneOff',
			id: _.uniqueId(),
			title: lang.priceOneOff,
			align: 'right'
		},
		{
			name: lang.quantity,
			isActive: true,
			tag: '<p>{{quantity}}</p>',
			type: 'quantity',
			id: _.uniqueId(),
			title: lang.quantity,
			align: 'right'
		},
		{
			name: lang.summary,
			isActive: true,
			tag: '{{#if ../previewMeta.useDiscount}}{{#if (isDiscount listPrice price)}}<p style="margin: 0;"><s><strong>{{currencyFormatter (math (math listPrice "*" ../order.currencyRate) "*" quantity) ../order.currency}}</strong></s></p><p style="color: red; margin: 0;">{{currencyFormatter (math (math (math listPrice "*" ../order.currencyRate) "*" quantity) "-" (math (math price "*" ../order.currencyRate) "*" quantity)) ../order.currency}}</p>{{/if}}{{/if}}<p style="margin: 0;"><strong>{{currencyFormatter (math (math price "*" ../order.currencyRate) "*" quantity) ../order.currency}}</strong></p>',
			type: 'summary',
			id: _.uniqueId(),
			title: lang.summary,
			align: 'right'
		}
	];

	const ProductTableTotalPrice = {
		recurring: lang.totalRecurringPrice,
		oneOff: lang.totalOneOffPrice
	};

	return {
		ProductTableColumns,
		showPriceSummation: true,
		showProductDescription: true,
		showDiscounts: true,
		ProductTableTotalPrice
	};
};

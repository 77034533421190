import React from 'react';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { /*Progressbar, Text,*/ Logo, Loader, Button, Row } from '@upsales/components';
import { init, selectRole, toggleDesiredOutcome, closeModal, save } from 'Store/reducers/DesiredOutcomeReducer';
import RoleSelect from './RoleSelect';
//import DesiredOutcomeSelect from './DesiredOutcomeSelect';
import NoMatch from './NoMatch';
import Feedback from './Feedback';
import T from '../Helpers/translate';
import './DesiredOutcome.scss';

/*
	I will keep the commented code in this file as we will add it soon again
*/

const mapStateToProps = state => state.DesiredOutcome;

const mapDispatchToProps = {
	init,
	selectRole,
	toggleDesiredOutcome,
	closeModal,
	save
};

const LoaderContent = () => {
	const classes = new bemClass('DesiredOutcome');
	return (
		<div className={classes.elem('loader-wrap').b()}>
			<Loader size="lg" />
		</div>
	);
};

class DesiredOutcome extends React.PureComponent {
	constructor(p) {
		super(p);

		this.lang = {
			of: T('default.of').toLowerCase(),
			abort: T('default.abort'),
			noOneFitsMe: T('default.noOneFitsMe'),
			startUsingUpsales: T('desiredoutcome.outcome.startUsingUpsales')
		};

		this.props.init(this.props.showFeedback, this.props.resolve);
	}

	selectNoMatch = () => {
		this.props.selectRole({ id: -1 });
	};

	render() {
		const { selectedRole, /*selectedDesiredOutcomes, */ loading, saving, feedbackVisible } = this.props;
		const classes = new bemClass('DesiredOutcome', 'FullScreenPage FullScreenPage--open');

		let content;
		let showHeaderButtons = false;

		if (loading) {
			content = <LoaderContent />;
		} else if (feedbackVisible) {
			content = <Feedback />;
		} else if (selectedRole && selectedRole.id === -1) {
			content = <NoMatch saving={saving} />;
		}
		// else if (selectedRole) {
		// 	content = (
		// 		<DesiredOutcomeSelect
		// 			selectedRole={selectedRole}
		// 			selectedDesiredOutcomes={selectedDesiredOutcomes}
		// 			saving={saving}
		// 		/>
		// 	);
		// }
		else {
			showHeaderButtons = true;
			content = <RoleSelect />;
		}

		// const progress = feedbackVisible
		// 	? null
		// 	:  (
		// 		<div className={classes.elem('progressbar-wraper').b()}>
		// 			<Progressbar size="sm" color="bright-blue" value={selectedRole ? 100 : 50} />
		// 			<Text size="xl" color="grey-10">{`${selectedRole ? 2 : 1} ${this.lang.of} ${2}`}</Text>
		// 		</div>
		// 	);

		return (
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>
					<Logo color="green" />
					{/* {progress} */}
					{showHeaderButtons ? (
						<Row>
							<Button type="link" size="lg" onClick={this.selectNoMatch}>
								{this.lang.noOneFitsMe}
							</Button>
							<Button onClick={this.props.save} size="lg" disabled={!selectedRole} loading={saving}>
								{this.lang.startUsingUpsales}
							</Button>
						</Row>
					) : null}
				</div>
				<div className={classes.elem('container').b()}>
					{content}
					{/* <Button style={{ marginTop: 5 }} type="link" color="grey" block={true} onClick={this.props.closeModal}>
						{this.lang.abort}
					</Button> */}
				</div>
			</div>
		);
	}
}

DesiredOutcome.defaultProps = {};

export const detached = DesiredOutcome;

export default connect(mapStateToProps, mapDispatchToProps)(DesiredOutcome);

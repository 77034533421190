import { globalTracker } from 'App/babel/helpers/Tracker';
import openModal from 'App/services/Modal';

const { default: logError } = require('App/babel/helpers/logError');

angular.module('domain.admin').controller('ImportCtrl', [
	'$scope',
	'$rootScope',
	'$safeApply',
	'AppService',
	'$state',
	'$upModal',
	'$translate',
	'TagsService',
	'Import',
	'NotificationService',
	'Campaign',
	'$q',
	'RequestBuilder',
	'OptIn',
	function (
		$scope,
		$rootScope,
		$safeApply,
		AppService,
		$state,
		$upModal,
		$translate,
		TagsService,
		Import,
		NotificationService,
		Campaign,
		$q,
		RequestBuilder,
		OptIn
	) {
		var ImportCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		var initialHash;
		var allowStateChange = false;

		ImportCtrl.rootData = AdminCtrl.getRootObject();
		ImportCtrl.rootData.useWebPack = true;
		ImportCtrl.rootData.pageComponent = 'ImportPage';
		ImportCtrl.rootData.pageLoading = true;
		ImportCtrl.rootData.pageData = {
			keys: [],
			obj: null,
			importStarting: false,
			tags: [],
			customFields: {
				client: [],
				contact: []
			},
			columnErrors: {
				missingRequiredMatches: null
			}
		};

		ImportCtrl.rootData.changeMatch = function (id, value) {
			setColProp(id, 'isMatch', value);
			ImportCtrl.rootData.clearImportPreview();
			validateColumns();
		};

		ImportCtrl.rootData.importChange = function (id, value) {
			if (value) {
				setColProp(id, 'field', '');
				setColProp(id, 'isMatch', false);
			}
			setColProp(id, 'skip', value);
			ImportCtrl.rootData.clearImportPreview();
			validateColumns();
		};

		ImportCtrl.rootData.setField = function (obj, id) {
			setColProp(id, 'field', obj.tag);

			var columns = ImportCtrl.rootData.pageData.obj.columns;

			// Reset all match fields on client or contact if changedfield is Client.Id or Contact.Id
			const tagIsClientId = obj.tag === 'Client.Id';
			const tagIsContactId = obj.tag === 'Contact.Id';
			if (tagIsClientId || tagIsContactId) {
				_.each(columns, function (col) {
					if (
						col &&
						col.isMatch &&
						((col.field.indexOf('Client.') === 0 && tagIsClientId) ||
							(col.field.indexOf('Contact.') === 0 && tagIsContactId))
					) {
						setColProp(col.id, 'isMatch', false);
					}
				});
				setColProp(id, 'isMatch', true);
			} else {
				// If Client.Id is present and we changed to a clientField (from contact field prob.)
				// and isMatch was checked, we uncheck it
				var clientIdPresent = !!_.find(columns, { field: 'Client.Id' });
				var column = _.find(columns, { id: parseInt(id) });
				if (column && clientIdPresent && obj.tag.indexOf('Client.') === 0 && column.isMatch) {
					setColProp(id, 'isMatch', false);
				}
			}

			ImportCtrl.rootData.clearImportPreview();
			validateColumns();
		};

		ImportCtrl.rootData.setMultiMatch = function (value) {
			ImportCtrl.rootData.pageData.obj.onMultiMatch = value;
			ImportCtrl.rootData.clearImportPreview();
			$safeApply($scope);
		};

		ImportCtrl.rootData.changeBisnode = function () {
			ImportCtrl.rootData.pageData.obj.addBisnodeInfo = ImportCtrl.rootData.pageData.obj.addBisnodeInfo =
				!ImportCtrl.rootData.pageData.obj.addBisnodeInfo;
			$safeApply($scope);
		};

		ImportCtrl.rootData.setOnlyUpdate = function (value) {
			ImportCtrl.rootData.pageData.obj.onlyUpdate = value;
			if (value) {
				ImportCtrl.rootData.pageData.obj.onMultiMatch = 'skip';
			}
			ImportCtrl.rootData.pageData.selectedUpdateOption = true;
			validateColumns();
			ImportCtrl.rootData.clearImportPreview();
			$safeApply($scope);
		};

		ImportCtrl.rootData.setImportType = function (value) {
			ImportCtrl.rootData.pageData.obj.type = value;
			ImportCtrl.rootData.pageData.obj.columns.forEach(col => (col.field = ''));
			validateColumns();
			ImportCtrl.rootData.clearImportPreview();
			$safeApply($scope);
		};

		ImportCtrl.rootData.runImportPreview = function () {
			ImportCtrl.rootData.pageData.obj.preview = 'START_PREVIEW';
			save(false, true).catch(err => {
				console.error('Error to start import preview', err);
				NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'import.error.failedToStartPreview'
				});
			});
			$safeApply($scope);
		};

		ImportCtrl.rootData.clearImportPreview = function () {
			ImportCtrl.rootData.pageData.obj.preview = '';
			$safeApply($scope);
		};

		ImportCtrl.rootData.getAvailableCustomFields = function (type) {
			return _.filter(ImportCtrl.rootData.pageData.customFields[type] || [], function (cf) {
				var fieldName = (type === 'client' ? 'Client' : 'Contact') + '.custom_' + cf.id;
				var usedFieldIndex = _.findIndex(ImportCtrl.rootData.pageData.obj.fields, { field: fieldName });
				return usedFieldIndex === -1;
			});
		};

		ImportCtrl.rootData.addField = function (field, value, forceRemove, action) {
			var fields = ImportCtrl.rootData.pageData.obj.fields;
			if (value.length || (field.indexOf('.custom_') !== -1 && !forceRemove)) {
				// add custom anyway
				var obj = _.find(fields, function (f) {
					return f.field === field;
				});

				if (obj) {
					_.map(fields, function (f) {
						if (f.field === field) {
							f.value = value;
						}

						return f;
					});
				} else {
					fields.push({
						field: field,
						value: value,
						action: action || null
					});
				}
			} else if (field.indexOf('.custom_') === -1 || forceRemove) {
				// exept custom
				fields = _.remove(fields, function (f) {
					return f.field === field;
				});
			}

			$safeApply($scope);
		};

		ImportCtrl.rootData.setOnlyNew = function (key, value) {
			var field = _.find(ImportCtrl.rootData.pageData.obj.fields, { field: key });

			if (field) {
				field.onlyNew = value;
			}

			$safeApply($scope);
		};

		ImportCtrl.rootData.setFieldAction = function (key, action) {
			var obj = _.find(ImportCtrl.rootData.pageData.obj.fields, { field: key });

			if (obj) {
				obj.action = action;
			} else {
				ImportCtrl.rootData.pageData.obj.fields.push({
					field: key,
					action: action,
					value: []
				});
			}

			$safeApply($scope);
		};

		ImportCtrl.rootData.onUploadNewFile = function (file) {
			ImportCtrl.rootData.uploading = true;

			return new Promise(resolve => {
				save(false)
					.then(() => {
						Import.upload(file, true, ImportCtrl.rootData.pageData.obj)
							.then(importData => {
								const columns = importData.data.columns.map(column => ({
									...column,
									sampleValues: JSON.parse(column.sampleValues)
								}));
								ImportCtrl.rootData.pageData.obj.columns = columns;
								ImportCtrl.rootData.pageData.obj.numRows = importData.data.numRows;
								ImportCtrl.rootData.pageData.obj.file = importData.data.file;

								ImportCtrl.rootData.clearImportPreview();

								ImportCtrl.rootData.uploading = false;
								validateColumns();
							})
							.catch(() => {
								ImportCtrl.rootData.uploading = false;
								NotificationService.addNotification({
									style: Tools.NotificationService.style.ERROR,
									icon: 'times',
									title: 'default.error',
									body: 'file.uploadFailed'
								});
							})
							.finally(resolve);
					})
					.catch(err => {
						console.error('Unable to save import:', err);
						NotificationService.addNotification({
							style: Tools.NotificationService.style.ERROR,
							icon: 'times',
							title: 'default.error',
							body: 'default.unableToSave'
						});
						resolve();
					});
			});
		};
		ImportCtrl.rootData.onSave = function () {
			allowStateChange = true;
			return save(false);
		};

		ImportCtrl.rootData.onImportStart = function () {
			allowStateChange = true;
			save(true);
		};

		ImportCtrl.rootData.setStatusEmail = function (val) {
			ImportCtrl.rootData.pageData.obj.statusEmail = val;
		};

		ImportCtrl.rootData.addCustomField = function (client) {
			var fields = _.filter(
				ImportCtrl.rootData.getAvailableCustomFields(client ? 'client' : 'contact'),
				function (cf) {
					return cf.$hasAccess && cf.visible && cf.editable;
				}
			);
			$upModal
				.open('list', {
					title: 'form.addField',
					columns: [{ title: '', value: 'name' }],
					hideHeader: true,
					data: _.map(fields, function (field) {
						return { id: field.id, name: field.name };
					})
				})
				.then(function (selected) {
					var fieldName = (client ? 'Client' : 'Contact') + '.custom_' + selected.id;
					ImportCtrl.rootData.addField(fieldName, '');
				})
				.catch(e => {
					console.error(e);
				});
		};

		const disableEmptyColumn = function (column, rows) {
			if (column.field === '' && !column.skip) {
				ImportCtrl.rootData.importChange(column.id, column.emptyRows === rows);
			}
		};

		function setColProp(id, prop, value) {
			var col = _.find(ImportCtrl.rootData.pageData.obj.columns, { id: parseInt(id) });
			if (col) {
				col[prop] = value;
				$safeApply($scope);
			}
		}

		var getCurrentHash = function () {
			return Tools.LZString.compressToBase64(JSON.stringify(ImportCtrl.rootData.pageData.obj));
		};

		function validateColumns() {
			var columnErrors = {};

			if (ImportCtrl.rootData.pageData.obj.columns.length < 1) {
				columnErrors.noColumnsInFirstRow = true;
			}

			const missingRequiredMatches = [];
			switch (ImportCtrl.rootData.pageData.obj.type) {
				case 'PRODUCT':
					if (!ImportCtrl.rootData.pageData.obj.onlyUpdate) {
						var productNameCol = _.find(ImportCtrl.rootData.pageData.obj.columns, {
							field: 'Product.Name'
						});
						if (!productNameCol) {
							missingRequiredMatches.push($translate.instant('tag.product.name'));
						}
					}
					break;
				default:
					if (!ImportCtrl.rootData.pageData.obj.onlyUpdate) {
						var clientNameCol = _.find(ImportCtrl.rootData.pageData.obj.columns, { field: 'Client.Name' });
						if (!clientNameCol) {
							missingRequiredMatches.push($translate.instant('tag.Client.name'));
						}
					}

					var contactAndClientMatch = _.reduce(
						ImportCtrl.rootData.pageData.obj.columns,
						function (result, col) {
							if (result.hasContactMatch && result.hasClientMatch) {
								// quick exit
								return result;
							}

							result.hasClientMatch =
								result.hasClientMatch || (col && col.field.indexOf('Client.') === 0);
							result.hasContactMatch =
								result.hasContactMatch || (col && col.field.indexOf('Contact.') === 0);

							return result;
						},
						{ hasContactMatch: false, hasClientMatch: false }
					);
					columnErrors.hasContactMatchButNoClientMatch =
						contactAndClientMatch.hasContactMatch && !contactAndClientMatch.hasClientMatch;
			}
			if (missingRequiredMatches.length) {
				columnErrors.missingRequiredMatches = missingRequiredMatches;
			}

			ImportCtrl.rootData.pageData.columnErrors = columnErrors;
		}

		async function save(start, startPreview) {
			// By dubbelklicking the button you can make multipple imports
			if (ImportCtrl.rootData.pageData.importStarting) {
				return;
			}
			ImportCtrl.rootData.pageData.importStarting = true;
			var data = _.cloneDeep(ImportCtrl.rootData.pageData.obj);
			// Fix data before save
			if (data.columns && Array.isArray(data.columns)) {
				data.columns = data.columns.map(function (col) {
					globalTracker.track('Import_column', {
						name: col.name.toLowerCase(),
						field: col.field
					});
					if (col.sampleValues) {
						try {
							col.sampleValues = JSON.stringify(col.sampleValues);
						} catch (e) {
							col.sampleValues = [];
						}
					}
					return col;
				});
			}
			if (data.fields && Array.isArray(data.fields)) {
				data.fields = data.fields.map(function (field) {
					if (field.field.indexOf('.custom_') !== -1) {
						return field;
					}
					field.value = _.pluck(field.value, 'id').join(',');
					return field;
				});
			}

			if (Array.isArray(data.statusEmail)) {
				data.statusEmail = data.statusEmail.join(';');
			}

			if (start) {
				data.status = Import.status.START;
			}
			if (startPreview) {
				delete data.status;
				delete data.file;
			} else if (typeof data.preview === 'object') {
				delete data.preview;
			}

			return Import.save(data, { onboardingStep: Tools.$state.params.stepId, skipNotification: true })
				.then(function () {
					if (start) {
						NotificationService.addNotification({
							title: 'import.importStarted',
							body: $translate.instant('import.importStartedAlt'),
							icon: 'file-excel',
							style: NotificationService.style.SUCCESS
						});
						$state.go('administration.imports');
					}
				})
				.finally(function () {
					ImportCtrl.rootData.pageData.importStarting = false;
				});
		}

		var init = function () {
			var customerId = AppService.getCustomerId();
			var metadata = AppService.getMetadata();
			var campaignPromise = $q.when({ data: [] });

			// get tags
			var tags = {
				contact: [],
				client: [],
				product: []
			};

			_.each(tags, function (value, tag) {
				_.each(TagsService.getImportTags(tag), function (t) {
					var entity = t.tag.split('.')[0].toLowerCase();

					if (entity === tag) {
						t.parent = entity;
						tags[entity].push(t);
					}
				});
			});

			const unsubImportPreview = $rootScope.$on('userPrivate.importPreview', (e, data) => {
				if ($state.params.id === data.importId.toString()) {
					if (ImportCtrl.rootData.pageData.obj.preview) {
						if (data.progress !== 1) {
							ImportCtrl.rootData.pageData.obj.preview = {
								done: false,
								running: true,
								progress: data.progress
							};
							$safeApply($scope);
						} else {
							Import.get($state.params.id)
								.then(i => {
									ImportCtrl.rootData.pageData.obj.preview = JSON.parse(i.data.preview);
									$safeApply($scope);
								})
								.catch(e => {
									console.error('Unable to fetch import preview:', e);
									NotificationService.addNotification({
										style: Tools.NotificationService.style.ERROR,
										icon: 'times',
										title: 'default.error',
										body: 'import.error.failedToFetchPreview'
									});
								});
						}
					}
				}
			});

			const unsubDataHealth = $rootScope.$on('userPrivate.importDataHealth', (e, data) => {
				if (
					$state.params.id === data.importId.toString() &&
					ImportCtrl.rootData.pageData.obj?.file.id === data.fileId
				) {
					Import.get($state.params.id)
						.then(i => {
							if (ImportCtrl.rootData.pageData.obj.file.id === data.fileId) {
								ImportCtrl.rootData.pageData.obj.columns.forEach((column, index) => {
									const newCol = i.data.columns[index];
									if (newCol?.emptyRows != null && newCol?.emptyRows !== column.emptyRows) {
										column.emptyRows = newCol.emptyRows;
										disableEmptyColumn(column, ImportCtrl.rootData.pageData.obj.numRows);
									}
								});
							}
						})
						.catch(e => console.log('Unable to fetch import:', e));
				}
			});

			// Get import obj
			Import.get($state.params.id)
				.then(function (i) {
					// parse sampleValues
					if (i.data && i.data.columns && Array.isArray(i.data.columns)) {
						i.data.columns = i.data.columns.map(function (col) {
							if (col.sampleValues) {
								try {
									col.sampleValues = JSON.parse(col.sampleValues);
								} catch (e) {
									col.sampleValues = [];
								}
							}
							return col;
						});
					}

					// parse import preview
					if (i?.data?.preview) {
						if (i.data.preview !== 'START_PREVIEW') {
							try {
								i.data.preview = JSON.parse(i.data.preview);
							} catch (e) {
								i.data.preview = '';
							}
						}
					}

					// Get campaigns from fields and fetch them from api
					if (i.data && i.data.fields) {
						var campaignFieldClient = _.find(i.data.fields, { field: 'Client.Project' });
						var campaignFieldContact = _.find(i.data.fields, { field: 'Contact.Project' });
						var campaignIds = [];

						if (campaignFieldClient) {
							var clientCampaignIds = campaignFieldClient.value.split(',');
							if (clientCampaignIds.length) {
								campaignIds.push(clientCampaignIds);
							}
						}
						if (campaignFieldContact) {
							var contactCampaignIds = campaignFieldContact.value.split(',');
							if (contactCampaignIds.length) {
								campaignIds.push(contactCampaignIds);
							}
						}
						campaignIds = _.flatten(campaignIds);

						if (campaignIds.length) {
							var filter = new RequestBuilder();
							filter.addFilter(Campaign.attr.id, filter.comparisonTypes.Equals, campaignIds);
							campaignPromise = Campaign.customer(customerId).find(filter.build());
						}
					}

					// Parse notification mails
					i.data.statusEmail = i.data.statusEmail.split(';') || [];

					campaignPromise
						.then(function (campaignRes) {
							OptIn.find()
								.then(function (optinRes) {
									// parse field values
									if (i.data && i.data.fields && Array.isArray(i.data.fields)) {
										i.data.fields = i.data.fields.map(function (field) {
											if (!field.value || !field.value.length) {
												return field;
											}

											// Array values
											var val = field.value.split(',');
											if (!val.length || field.field.indexOf('.custom_') !== -1) {
												return field;
											}
											switch (field.field) {
												case 'Client.UserId':
													var users = AppService.getActiveUsers();
													field.value = _.map(val, function (id) {
														return _.find(users, { id: parseInt(id) });
													});
													break;
												case 'Client.Category':
												case 'Contact.Category':
													var cats = AppService.getCategories(
														field.field === 'Client.Category' ? 'account' : 'contact'
													);
													field.value = _.map(val, function (id) {
														return _.find(cats, { id: parseInt(id) });
													});
													break;
												case 'Client.Project':
												case 'Contact.Project':
													field.value = _.map(val, function (id) {
														return _.find(campaignRes.data, { id: parseInt(id) });
													});
													break;

												case 'Contact.Optins':
													field.value = _.map(val, function (id) {
														return _.find(optinRes.data, { id: parseInt(id) });
													});
													break;
											}

											return field;
										});
									}

									ImportCtrl.rootData.pageData.tags = tags;
									ImportCtrl.rootData.pageData.obj = i.data;
									if ($state.params.isNew) {
										ImportCtrl.rootData.pageData.selectedUpdateOption = false;
									} else {
										ImportCtrl.rootData.pageData.selectedUpdateOption = true;
									}
									ImportCtrl.rootData.pageLoading = false;
									ImportCtrl.rootData.pageData.customFields.contact =
										AppService.getCustomFields('contact');
									ImportCtrl.rootData.pageData.customFields.client =
										AppService.getCustomFields('account');
									ImportCtrl.rootData.pageData.teamAccountManager =
										metadata.params.teamAccountManager;

									// Don't import empty columns
									for (const column of ImportCtrl.rootData.pageData.obj.columns) {
										disableEmptyColumn(column, ImportCtrl.rootData.pageData.obj.numRows);
									}

									// Validate dem columns
									validateColumns();

									$safeApply($scope);

									// save initial state
									initialHash = Tools.LZString.compressToBase64(
										JSON.stringify(ImportCtrl.rootData.pageData.obj)
									);
								})
								.catch(e => {
									logError(e, 'Failed to find optIn promise');
								});
						})
						.catch(e => {
							logError(e, 'Failed to resolve campaign promise');
						});
				})
				.catch(e => {
					allowStateChange = true;
					$state.go('administration.imports');
					// User could've removed the import and then navigated using the browser arrows, we dont want that in sentry though
					if (e.status !== 404) {
						logError(e, 'Failed to get import object');
					}
				});

			// Set state change listener
			$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromStateParams, options) {
				if ((fromStateParams.isNew || initialHash !== getCurrentHash()) && !allowStateChange) {
					allowStateChange = true;
					event.preventDefault();

					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('UnsavedChangesAlert', {
							onClose: confirmed => {
								if (confirmed === undefined) {
									return;
								}
								if (confirmed) {
									save()
										.then(function () {
											$state.go(toState, toParams, options);
										})
										.catch(e => {
											logError(e, 'Unable to save import');
										});
								} else {
									//If file was created just now, delete it otherwise just go back
									if (fromStateParams.isNew) {
										const imprt = ImportCtrl.rootData.pageData.obj;
										Import.delete(imprt)
											.then(function () {
												options.location = 'replace';
												$state.go(toState, toParams, options);
											})
											.catch(e => {
												logError(e, 'Unable to delete draft');
											});
									} else {
										$state.go(toState, toParams, options);
									}
								}
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: 'confirm.abortEdit',
							body: 'confirm.changesWillBeLost',
							resolveTrue: 'import.saveImport',
							resolveTrueBtnClass: 'btn-bright-blue',
							resolveFalse: 'default.dontSave',
							no: 'default.abort'
						})
						.then(
							function (doSave) {
								options.location = true;

								// DO SAVE HERE
								if (doSave) {
									save()
										.then(function () {
											$state.go(toState, toParams, options);
										})
										.catch(e => {
											logError(e, 'Unable to save import');
										});
								} else {
									//If file was created just now, delete it otherwise just go back
									if (fromStateParams.isNew) {
										const imprt = ImportCtrl.rootData.pageData.obj;
										Import.delete(imprt)
											.then(function () {
												options.location = 'replace';
												$state.go(toState, toParams, options);
											})
											.catch(e => {
												logError(e, 'Unable to delete draft');
											});
									} else {
										$state.go(toState, toParams, options);
									}
								}
							},
							function () {
								allowStateChange = false;
							}
						);
				} else {
					unsubDataHealth();
					unsubImportPreview();
				}
			});
		};
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

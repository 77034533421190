'use strict';

angular.module('domain.engage').controller('ViewAdCreatives', [
	'$scope',
	'$stateParams',
	'AppService',
	function ($scope, $stateParams, AppService) {
		var ViewAdCreativesCtrl = this;
		ViewAdCreativesCtrl.creatives = angular.copy($scope.ViewAdCtrl.activeCreatives);
		var init = function () {};

		AppService.loadedPromise.then(init);
	}
]);

import CompanyProfile from './StepTypes/CompanyProfile';
import Import from './StepTypes/Import';
import ImportConfig from './StepTypes/ImportConfig';
import Script from './StepTypes/Script';
import Domain from './StepTypes/Domain';

export default {
	CompanyProfile,
	Import,
	ImportConfig,
	Script,
	Domain
};

import { Button, Tooltip, Icon } from '@upsales/components';
import AdminHeader from 'Components/Admin/AdminHeader';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import openModal from 'App/services/Modal';
import RoleSelect from 'Components/RoleSelect';

ReactTemplates.inlineEditDelete = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			save: t('default.save')
		};
	},
	componentDidMount: function () {
		if (this.props.focus) {
			var input = this.input;
			input.focus();
		}
	},
	getInitialState: function () {
		var state = {
			active: false
		};

		if (!this.props.value) {
			state.value = this.props.defaultValue || '';
		}

		return state;
	},
	onSave: function () {
		if (this.props.value) {
			this.props.onSave(this.props.value);
		} else {
			this.props.onSave(this.state.value);
		}
	},
	onFocus: function () {
		this.setState({ active: true });
	},
	onBlur: function () {
		var self = this;
		setTimeout(function () {
			self.setState({ active: false });
		}, 200);
	},
	onKeyDown: function (event) {
		if (event.charCode === 13) {
			this.input.blur();
			this.onSave();
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	onChange: function (event) {
		var value = event.target.value;

		this.setState({
			value: value
		});
	},
	saveDisabled: function () {
		if (this.props.value) {
			return !this.props.value;
		} else {
			return !this.state.value;
		}
	},
	render: function () {
		var className = this.state.active ? 'inline-edit-delete inline-edit-delete--active' : 'inline-edit-delete';
		var input;

		if (this.props.value) {
			input = (
				<input
					className="inline-edit-delete-input"
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onChange={this.props.onChange}
					onKeyPress={this.onKeyDown}
					value={this.props.value}
					type="text"
					maxLength={this.props.maxLength}
					placeholder={this.props.placeholder}
				/>
			);
		} else {
			input = (
				<input
					className="inline-edit-delete-input"
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onKeyPress={this.onKeyDown}
					onChange={this.onChange}
					value={this.state.value}
					type="text"
					maxLength={this.props.maxLength}
					ref={this.setRef.bind(this, 'input')}
					placeholder={this.props.placeholder}
				/>
			);
		}

		return (
			<div className={className}>
				<div className="inline-edit-delete-input-wrap">
					{input}
					<i className="fa fa-pencil-square-o inline-edit-delete-edit"></i>
				</div>
				<button
					className="btn up-btn no-shadow btn-bright-blue inline-edit-delete-save"
					onClick={this.onSave}
					disabled={this.saveDisabled()}
				>
					{this.lang.save}
				</button>
				<button className="up-btn btn-link inline-edit-delete-delete" onClick={this.props.onDelete}>
					<i className="fa fa-trash-o"></i>
				</button>
			</div>
		);
	}
});

ReactTemplates.admin.categoryMerge = window.ReactCreateClass({
	getInitialState: function () {
		return {
			to: null,
			from: null,
			name: ''
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			value: t('default.value'),
			mergeValues: t('admin.category.mergeCategories'),
			cancel: t('default.abort'),
			mergeInfo: t('admin.category.merge.info'),
			choose: t('default.choose'),
			newName: t('default.newName')
		};

		this.nameMaxLength = 100;
	},
	nameChanged: function (event) {
		this.setState({
			name: event.target.value
		});
	},
	categoryChanged: function (key, category) {
		var state = {};
		state[key] = category;

		this.setState(state);
	},
	validate: function () {
		var self = this;

		var duplicateName =
			_.find(this.props.categoryType.children, function (category) {
				return (
					(category.name || '').toLowerCase() === self.state.name.toLowerCase() &&
					category.id !== (self.state.to && self.state.to.id)
				);
			}) !== undefined;

		return (
			this.state.to &&
			this.state.from &&
			this.state.to.id !== this.state.from.id &&
			this.state.name &&
			!duplicateName
		);
	},
	merge: function () {
		this.props.merge({
			name: this.state.name,
			to: this.state.to.id,
			from: this.state.from.id,
			categoryType: this.props.categoryType.id
		});
	},
	abort: function () {
		this.props.abort(null);
	},
	render: function () {
		var self = this;
		var type = this.props.type;
		var categoryType = this.props.categoryType;

		var categories = Tools.AppService.getCategories(type).filter(function (category) {
			return category.categoryType === categoryType.id;
		});

		var toData = _.filter(categories, function (category) {
			var from = self.state.from;
			return category.id !== (from && from.id);
		});

		toData = _.sortBy(toData, function (category) {
			return (category.name || '').toLowerCase();
		});

		var fromData = _.filter(categories, function (category) {
			var to = self.state.to;
			return category.id !== (to && to.id);
		});

		fromData = _.sortBy(fromData, function (category) {
			return (category.name || '').toLowerCase();
		});

		return (
			<div className="merge-wrap">
				<div className="merge-description">{this.lang.mergeInfo}</div>
				<div className="merge-item merge-item-1">
					<label>{this.lang.value + ' 1'}</label>
					<ReactTemplates.INPUTS.upCategories
						data={toData}
						value={this.state.to}
						onChange={this.categoryChanged.bind(this, 'to')}
						placeholder={this.lang.choose + ' ' + this.lang.value}
						disabled={this.props.isMerging}
					/>
				</div>
				<div className="merge-item">
					<label>{this.lang.value + ' 2'}</label>
					<ReactTemplates.INPUTS.upCategories
						data={fromData}
						value={this.state.from}
						onChange={this.categoryChanged.bind(this, 'from')}
						placeholder={this.lang.choose + ' ' + this.lang.value}
						disabled={this.props.isMerging}
					/>
				</div>

				<label>{this.lang.newName}</label>
				<input
					type="text"
					className="form-control"
					value={this.state.name}
					onChange={this.nameChanged}
					maxLength={this.nameMaxLength}
					disabled={this.props.isMerging}
				/>

				<div className="btn-wrap">
					<button
						onClick={this.merge}
						className="btn up-btn btn-bright-blue no-shadow btn-sm"
						disabled={!this.validate() || this.props.isMerging}
					>
						{this.lang.mergeValues}
					</button>
					<button onClick={this.abort} className="btn up-btn no-shadow btn-sm btn-grey btn-link">
						{this.lang.cancel}
					</button>
				</div>
			</div>
		);
	}
});

ReactTemplates.admin.categoryTable = window.ReactCreateClass({
	defaultLimit: 50,
	getInitialState: function () {
		return {
			sortDescending: false,
			edit: null,
			merge: false,
			focusField: null,
			touched: {
				title: false,
				editRowName: false
			},
			page: 1,
			limit: this.defaultLimit
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			new2: t('default.new2'),
			value: t('default.value'),
			mergeValues: t('admin.category.mergeCategories'),
			name: t('default.name'),
			roles: t('default.roles'),
			save: t('default.save'),
			cancel: t('default.abort'),
			noName: t('default.noName'),
			mergeInfo: t('admin.category.merge.info'),
			choose: t('default.choose'),
			newName: t('default.newName'),
			noCategoriesAdded: t('admin.category.noCategoriesAdded'),
			remove: t('default.remove'),
			category: t('default.category'),
			removeCategoryBody: t('admin.category.removeCategoryBody')
		};

		this.roles = Tools.AppService.getRoles();
		this.nameMaxLength = 100;
		this.hasRoleFeature = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED);
	},
	componentDidUpdate: function (prevProps, prevState) {
		if (this.state.edit && this.state.edit !== prevState.edit) {
			if (this.state.focusField === 'roles') {
				var roleinput = this._roleinput;
				setTimeout(function () {
					$(roleinput).select2('open');
				}, 100);
			} else {
				var nameinput = this._nameinput;
				nameinput.focus();
				nameinput.setSelectionRange(this.nameMaxLength, this.nameMaxLength);
			}
		}
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var categoryType = nextProps.categoryType;
		var numberOfCategories = Array.isArray(categoryType.children) ? categoryType.children.length : 0;

		const maxNumberOfPages = Math.ceil(
			numberOfCategories > 0 ? numberOfCategories / (this.state.limit || this.defaultLimit) : 1
		);

		if (this.state.page > maxNumberOfPages) {
			this.setState({
				page: maxNumberOfPages
			});
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	toggleSort: function () {
		this.setState(function (prevState) {
			return {
				sortDescending: !prevState.sortDescending
			};
		});
	},
	getSortClass: function () {
		return this.state.sortDescending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
	},
	newCategory: function () {
		this.setState({
			edit: {
				name: '',
				categoryType: this.props.categoryType.id,
				roles: []
			},
			touched: {
				editRowName: false
			}
		});
	},
	startEdit: function (category, focusField) {
		var newState = _.pick(this.state, ['touched']);
		newState.touched.editRowName = false;
		newState.edit = _.cloneDeep(category);
		newState.focusField = focusField;

		this.setState(newState);
	},
	cancelEdit: function () {
		this.setState({ edit: null });
	},
	saveEdit: function () {
		if (this.state.edit && this.state.edit.name) {
			var saveCategory = this.props.actions.saveCategory;
			saveCategory(this.state.edit);

			this.setState({ edit: null });
		}
	},
	onKeyDown: function (event) {
		if (event.charCode === 13) {
			this.saveEdit();
		}
	},
	editNameChanged: function (event) {
		var newState = _.pick(this.state, ['edit', 'touched']);
		newState.edit.name = event.target.value.substring(0, this.nameMaxLength);
		newState.touched.editRowName = true;

		this.setState(newState);
	},
	editRolesChanged: function (value) {
		this.state.edit.roles = value;
		this.setState({
			edit: this.state.edit
		});
	},
	removeCategory: function (category) {
		var removeCategory = this.props.actions.removeCategory;
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('RemoveAlert', {
				body: this.lang.removeCategoryBody,
				title: 'default.category',
				onClose: confirmed => {
					if (confirmed) {
						removeCategory(category);
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('warningConfirm', {
				body: this.lang.removeCategoryBody,
				title: this.lang.remove + ' ' + this.lang.category.toLowerCase(),
				resolveTrue: this.lang.remove
			})
			.then(function () {
				removeCategory(category);
			});
	},
	row: function (category) {
		return (
			<tr className="clickable" key={category.id}>
				<td onClick={this.startEdit.bind(this, category, 'name')}>{category.name}</td>
				{this.hasRoleFeature ? (
					<td onClick={this.startEdit.bind(this, category, 'roles')} className="document-cell-ellipsis">
						<ReactTemplates.admin.tableTokens items={category.roles} />
					</td>
				) : null}
				<td className="admin-row-tools-wrap">
					<div className="admin-row-tools">
						<button
							type="button"
							className="up-btn btn-link btn-grey"
							onClick={this.removeCategory.bind(this, category)}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					</div>
				</td>
			</tr>
		);
	},
	editRow: function () {
		var category = this.state.edit;

		var nameClasses = 'floating-label-input';

		if (category.name) {
			nameClasses = nameClasses + ' has-value';
		} else if (this.state.touched.editRowName) {
			nameClasses = nameClasses + ' has-error';
		}

		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		return (
			<tr key={category.id || 'new'} className="edit-row">
				<td>
					<div className={nameClasses}>
						<label>{this.lang.name}</label>
						<input
							type="text"
							onChange={this.editNameChanged}
							onKeyPress={this.onKeyDown}
							value={category.name}
							maxLength={this.nameMaxLength}
							ref={this.setRef.bind(this, '_nameinput')}
						/>
					</div>
				</td>
				<td colSpan={2}>
					<div className="column-wrap">
						{this.hasRoleFeature ? (
							<div className="select-wrap">
								{hasTreeSelect ? (
									<RoleSelect
										roles={this.roles}
										selectedRoles={category.roles}
										onChange={this.editRolesChanged}
									/>
								) : (
									<ReactTemplates.INPUTS.upRoles
										className="form-control"
										data={this.roles}
										value={category.roles}
										multiple={true}
										onChange={this.editRolesChanged}
										ref={this.setRef.bind(this, '_roleinput')}
									/>
								)}
							</div>
						) : null}
						<div className="button-wrap">
							<Tooltip title={this.lang.save} position="top" distance={24}>
								<Button key="preview-save" size="sm" color="green" onClick={this.saveEdit}>
									<Icon name="check"></Icon>
								</Button>
							</Tooltip>

							<Tooltip title={this.lang.cancel} position="top" distance={24}>
								<Button
									key="preview-cancel"
									size="sm"
									type="link"
									color="grey"
									onClick={this.cancelEdit}
								>
									<Icon name="times"></Icon>
								</Button>
							</Tooltip>
						</div>
					</div>
				</td>
			</tr>
		);
	},
	removeCategoryType: function () {
		this.props.actions.removeCategoryType(this.props.categoryType);
	},
	saveCategoryType: function (newName) {
		var categoryType = _.cloneDeep(this.props.categoryType);
		categoryType.name = newName;
		this.props.actions.saveCategoryType(categoryType);
	},
	toggleMerge: function () {
		var categoryType = this.props.categoryType;
		this.props.actions.toggleMerge(categoryType.id);
	},
	onChangePage: function (page) {
		this.setState({
			page: page
		});
	},
	render: function () {
		var self = this;
		var categoryType = this.props.categoryType;
		var mergeOpen = this.props.merge === categoryType.id;
		var content;

		if (!mergeOpen) {
			var categories = _.sortBy(categoryType.children, function (category) {
				return category.name.toLowerCase();
			});

			if (this.state.sortDescending) {
				categories = categories.reverse();
			}

			var from = (this.state.page - 1) * this.state.limit;
			var to = this.state.page * this.state.limit;

			var rows = categories.slice(from, to).map(function (category) {
				if (category.id === (self.state.edit && self.state.edit.id)) {
					return self.editRow(category);
				} else {
					return self.row(category);
				}
			});

			if (this.state.edit && !this.state.edit.id) {
				rows.unshift(this.editRow(this.state.edit));
			}

			if (rows.length === 0) {
				rows = [
					<DefaultNoData
						key="no-data-row"
						subtitle=""
						formatNoData={() => this.lang.noCategoriesAdded}
						colSpan={this.hasRoleFeature ? 3 : 2}
					/>
				];
			}

			content = (
				<table>
					<thead>
						<tr>
							<th className={'sortable active'} onClick={this.toggleSort}>
								{this.lang.name} <i className={this.getSortClass()} />
							</th>
							{this.hasRoleFeature ? <th>{this.lang.roles}</th> : null}
							<th></th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</table>
			);
		} else {
			content = (
				<ReactTemplates.admin.categoryMerge
					merge={this.props.actions.mergeCategories}
					type={this.props.type}
					categoryType={categoryType}
					abort={this.props.actions.toggleMerge}
					isMerging={this.props.isMerging}
				/>
			);
		}

		var numberPages = Math.ceil(categoryType.children.length / this.state.limit) || 1;

		return (
			<div className="admin-table-wrap">
				<div className="admin-table">
					<div className="admin-table-top">
						<div className="edit-category-type">
							{categoryType.id === 0 ? (
								<span className="admin-table-title">{categoryType.name}</span>
							) : (
								<ReactTemplates.inlineEditDelete
									defaultValue={categoryType.name}
									onSave={this.saveCategoryType}
									onDelete={this.removeCategoryType}
									focus={categoryType.id ? false : true}
									placeholder={this.lang.noName}
									maxLength={255}
								/>
							)}
						</div>
						<div className="pull-right">
							{categoryType.hasOwnProperty('id') && categoryType.children.length > 1 ? (
								<button
									className="btn btn-link btn-sm"
									onClick={this.toggleMerge}
									disabled={this.state.edit || this.state.merge || this.props.isMerging}
								>
									{this.lang.mergeValues}
								</button>
							) : null}
							{categoryType.hasOwnProperty('id') ? (
								<Button size="sm" onClick={this.newCategory} disabled={this.state.edit || mergeOpen}>
									{this.lang.new2 + ' ' + this.lang.value.toLowerCase()}
								</Button>
							) : null}
						</div>
					</div>
					{content}
				</div>
				{numberPages > 1 ? (
					<ReactTemplates.TOOLS.Paginator
						currentPage={this.state.page}
						max={numberPages}
						onChange={this.onChangePage}
					/>
				) : null}
			</div>
		);
	}
});

ReactTemplates.admin.category = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			headerInfo: t('admin.category.headerInfo'),
			extra: t('default.extra'),
			new: t('default.new'),
			new2: t('default.new2'),
			value: t('default.value').toLowerCase(),
			category: t('default.category'),
			accountCategories: t('default.accountCategories'),
			contactCategories: t('default.contactCategories'),
			mergeValues: t('admin.category.mergeCategories'),
			name: t('default.name'),
			roles: t('default.roles'),
			contactHeaderInfo: t('admin.category.contactHeaderInfo')
		};
	},
	hasUnsavedCategoryType: function () {
		return (
			_.find(this.props.rootData.pageData.categoryTypes, function (categoryType) {
				return categoryType.id === undefined;
			}) !== undefined
		);
	},
	render: function () {
		var pageData = this.props.rootData.pageData;
		var defaultCategoryType = _.find(pageData.categoryTypes, { id: 0 });
		var customCategoryTypes = _.filter(pageData.categoryTypes, function (categoryType) {
			return categoryType.id !== 0;
		});

		var defaultCategoryTypeTable = (
			<ReactTemplates.admin.categoryTable
				isMerging={pageData.isMerging}
				merge={pageData.merge}
				type={pageData.type}
				key={0}
				actions={pageData.actions}
				categoryType={defaultCategoryType}
			/>
		);
		var customCategoryTypeTables = customCategoryTypes.map(function (categoryType) {
			return (
				<ReactTemplates.admin.categoryTable
					isMerging={pageData.isMerging}
					merge={pageData.merge}
					type={pageData.type}
					key={categoryType.id}
					actions={pageData.actions}
					categoryType={categoryType}
				/>
			);
		});

		var title = pageData.type === 'account' ? this.lang.accountCategories : this.lang.contactCategories;
		var description = pageData.type === 'account' ? this.lang.headerInfo : this.lang.contactHeaderInfo;
		var extraCategoryTitle =
			this.lang.extra +
			' ' +
			(pageData.type === 'account' ? this.lang.accountCategories : this.lang.contactCategories).toLowerCase();
		var newExtraCategoryText =
			this.lang.new + ' ' + this.lang.extra.toLowerCase() + ' ' + this.lang.category.toLowerCase();
		return (
			<div id="admin-page-category">
				<AdminHeader title={title} description={description} image="company-categories.svg" articleId={579} />
				<div id="admin-content">
					{defaultCategoryTypeTable}
					<div className="extra-category-section">
						<span>{extraCategoryTitle}</span>
						<button
							className="pull-right btn btn-link"
							onClick={this.props.rootData.pageData.actions.onNewActivityType}
							disabled={this.hasUnsavedCategoryType()}
						>
							{newExtraCategoryText}
						</button>
					</div>
					{customCategoryTypeTables.length ? customCategoryTypeTables : null}
				</div>
			</div>
		);
	}
});

'use strict';
import React from 'react';
import _ from 'lodash';

(function () {
	var UNSAFE_componentWillMount = function () {
		var t = Tools.$translate;

		this.lang = {
			account: t('default.account'),
			latestAccounts: t('default.latestAccounts'),
			noName: t('default.noName')
		};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		//var Role = Tools.Role;

		var Role = {
			attr: {
				id: {
					title: 'default.id',
					field: 'id',
					// type: FilterType.Number,
					// displayType: DisplayType.String,
					type: 'Number',
					displayType: 'String',
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					//type: FilterType.String,
					type: 'String',
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				}
			}
		};

		var getTreeRoles = function (roles) {
			var out = [];
			_.forEach(roles, function (role) {
				out.push(role);
				out = out.concat(getTreeRoles(role.children));
			});
			return out;
		};

		var opts = {
			multiple: self.props.multiple || false,
			ajax: false,
			asIds: false,
			required: self.props.required || false,
			idAttr: Role.attr.id,
			placeholder: function () {
				return self.props.placeholder;
			},
			titleAttr: Role.attr.name,
			sorting: { field: Role.attr.name, ascending: true },
			onChange: function (arr) {
				if (self.props.asTree) {
					// Bättre hantering av lägga till och ta bort
					if (arr.length > self.props.value.length) {
						// Role added

						const addedRole = arr.filter(
							({ id: newId }) => !self.props.value.some(({ id: oldId }) => newId === oldId)
						);
						const treeRoles = getTreeRoles(addedRole);
						const newValue = _.uniq([...arr, ...treeRoles], 'id');

						return self.props.onChange(newValue);
					}
				}
				// Not as tree, or tree role removed
				self.props.onChange(arr);
			},
			data: function () {
				if (self.props.asTree) {
					var data = _.cloneDeep(self.props.data);
					// Populate roles with children
					_.forEach(data, function (role) {
						var children = _.filter(data, function (child) {
							return child.parent && child.parent.id === role.id;
						});
						role.children = _.sortByOrder(children, 'name');
					});
					// Remove from root role list if role has a parent
					data = data.filter(role => !role.parent);
					return _.sortByOrder(data, 'name');
				}
				return _.sortByOrder(self.props.data, 'name');
			},
			value: self.props.value
		};

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			var select2 = input.select2(options);
			if (self.props.eventListeners) {
				_.each(self.props.eventListeners, function (fn, event) {
					select2.on(event, fn);
				});
			}
		});
	};

	ReactTemplates.INPUTS.upRoles = window.ReactCreateClass({
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		render: render
	});
})();

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button } from '@upsales/components';
import {
	importConfigChanged,
	navigateNextImport,
	onRequestOauth,
	onRequestOauth2
} from 'Store/reducers/OnboardingReducer';
import IntegrationHelper from 'App/helpers/IntegrationHelper';

import './OnboardingImport.scss';

const OnboardingImport = new Bem('OnboardingImport');
const StepWrapClass = new Bem('StepWrap');

const mapStateToProps = state => ({
	importFields: state.Onboarding.importFields,
	importConfig: state.Onboarding.importConfig,
	importStepLoading: state.Onboarding.importStepLoading,
	gettingOauth: state.Onboarding.gettingOauth,
	oauthErr: state.Onboarding.oauthErr,
	oauth: state.Onboarding.oauth,
	gettingOauthErr: state.Onboarding.gettingOauthErr
});

const mapDispatchToProps = {
	importConfigChanged,
	navigateNextImport,
	onRequestOauth,
	onRequestOauth2
};

class ImportCredentials extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			next: T('default.next')
		};

		this.getIntegrationLang = IntegrationHelper.getIntegrationLang.bind('onboarding-import', '');
	}

	specialButton(disabled) {
		const { navigateNextImport, importStepLoading } = this.props;

		return (
			<Button
				block
				size="xl"
				onClick={() => navigateNextImport({ phase: 1 })}
				className={StepWrapClass.elem('Button')}
				disabled={disabled}
				loading={importStepLoading}
			>
				{this.lang.next}
			</Button>
		);
	}

	render() {
		const {
			importFields,
			importConfig,
			importConfigChanged,
			gettingOauth,
			oauthErr,
			oauth,
			gettingOauthErr,
			onRequestOauth,
			onRequestOauth2
		} = this.props;
		const authFields = importFields.filter(field => field.section === 'auth');

		const disabled = authFields.reduce((disabled, field) => {
			if (disabled) {
				return true;
			}
			if (field.externalValidation) {
				return !(field.$tested && field.$tested.valid);
			} else if (field.$required) {
				const value = importConfig[field.name];
				return !value || (Array.isArray(value) && !value.length);
			}
			return false;
		}, false);

		const nextButton = this.specialButton(disabled);

		return (
			<div className={OnboardingImport.b()}>
				<ReactTemplates.admin.standardIntegrations.fields
					getIntegrationLang={this.getIntegrationLang}
					config={importConfig}
					onChange={importConfigChanged}
					fields={authFields}
					showHero={true}
					gettingOauth={gettingOauth}
					oauthErr={oauthErr}
					oauth={oauth}
					gettingOauthErr={gettingOauthErr}
					onRequestOauth={onRequestOauth}
					onRequestOauth2={onRequestOauth2}
				/>
				{nextButton}
			</div>
		);
	}
}

ImportCredentials.propTypes = {
	importFields: PropTypes.array,
	importConfig: PropTypes.object,
	importConfigChanged: PropTypes.func,
	navigateNextImport: PropTypes.func,
	importStepLoading: PropTypes.bool,
	onRequestOauth: PropTypes.func,
	onRequestOauth2: PropTypes.func,
	gettingOauth: PropTypes.bool,
	oauthErr: PropTypes.object,
	oauth: PropTypes.object,
	gettingOauthErr: PropTypes.object
};

export const detached = ImportCredentials;
export default connect(mapStateToProps, mapDispatchToProps)(ImportCredentials);

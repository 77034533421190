export const fetchAll = function (Resource, requestBuilder, options) {
	const limit = 1000;
	requestBuilder.limit = limit;

	const getPartial = function (currentData, offset, options) {
		requestBuilder.offset = offset;

		return Resource.find(requestBuilder.build(), options).then(function (res) {
			const mergedData = currentData.concat(res.data);

			return res.data.length < limit ? mergedData : getPartial(mergedData, offset + limit, options);
		});
	};

	return getPartial([], 0, options).then(function (data) {
		return { data: data };
	});
};

window.fetchAll = fetchAll;

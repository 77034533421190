import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import ReduxComponentRoot from './components/ReduxComponentRoot';
import logError from 'App/babel/helpers/logError';
import DelayedLoader from 'Components/DelayedLoader';

const init = (Component, props, rootNode, useSuspense) => {
	if (useSuspense) {
		Component = (
			<Suspense fallback={<DelayedLoader />}>
				<Component {...props} />
			</Suspense>
		);
	} else {
		Component = <Component {...props} />;
	}
	ReactDOM.render(Component, rootNode);
};

const setupComponent = (scope, Component, rootNode, props = {}, opts = {}) => {
	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
	});

	if (opts.watch) {
		if (!Array.isArray(opts.watch)) {
			opts.watch = [opts.watch];
		}
		for (const w of opts.watch) {
			scope.$watch(w.attr, data => {
				props[w.prop] = data;
				init(opts.redux ? ReduxComponentRoot : Component, props, rootNode, false);
			});
		}
	}

	if (opts.redux) {
		props.component = Component;
	}

	if (opts.modal) {
		scope.$on('modal.ready', (event, data) => {
			rootNode = data.element[0];
			props.resolve = scope.resolve;
			props.reject = scope.reject;
			let reloadTimeout = null;
			props.reloadModalPosition = timeout => {
				if (reloadTimeout) {
					clearTimeout(reloadTimeout);
				}
				return new Promise(resolve => {
					reloadTimeout = setTimeout(() => {
						scope.reloadModalPosition();
						resolve();
					}, timeout || 200);
				});
			};
			init(opts.redux ? ReduxComponentRoot : Component, props, rootNode, true);
		});
	} else {
		if (opts.ignoreAppService) {
			init(opts.redux ? ReduxComponentRoot : Component, props, rootNode, false);
		} else {
			Tools.AppService.loadedPromise
				.then(() => init(opts.redux ? ReduxComponentRoot : Component, props, rootNode, !!opts.useSuspense))
				.catch(e => {
					if (e) {
						logError(e, 'Failed to load AppService');
					}
				});
		}
	}
};

window.SetupComponent = setupComponent;

export default setupComponent;

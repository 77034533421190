import React from 'react';
import PropTypes from 'prop-types';
import CriteriaRowWrapper from '../CriteriaRowWrapper';

const BooleanInput = props => {
	const { filter, onChange, options } = props;
	return (
		<CriteriaRowWrapper>
			<div className="btn-group btn-block">
				{_.map(options, option => {
					const classes =
						'btn up-btn no-shadow ' +
						(filter.value === option.value ? 'btn-bright-blue' : ' btn-light-grey');
					return (
						<button
							key={'input-boolean-' + option.key}
							onClick={onChange.bind(null, option.value)}
							className={classes}
							style={{ width: '33.333%' }}
						>
							{option.key}
						</button>
					);
				})}
			</div>
		</CriteriaRowWrapper>
	);
};

BooleanInput.propTypes = {
	filter: PropTypes.object.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired
};

export default BooleanInput;

'use strict';

angular.module('upAttributes').service('UserDefinedObjectAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				regDate: {
					title: 'default.date',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					displayType: DisplayType.String,
					displayAttr: 'name',
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String,
							skipMap: true
						}
					}
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				campaign: {
					title: 'default.campaign',
					field: 'project.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'projects',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				notes1: {
					field: 'notes1',
					type: FilterType.String,
					title: 'default.notes' + 1,
					inputType: 'select'
				},
				notes2: {
					field: 'notes2',
					type: FilterType.String,
					title: 'default.notes' + 2,
					inputType: 'select'
				},
				notes3: {
					field: 'notes3',
					type: FilterType.String,
					title: 'default.notes' + 3,
					inputType: 'select'
				},
				note4: {
					field: 'notes4',
					type: FilterType.String,
					title: 'default.notes' + 4,
					inputType: 'select'
				},
				clientUsers: {
					field: 'client.users',
					type: FilterType.String,
					title: 'default.accountManager',
					inputType: 'select',
					selectableColumn: true
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				custom: {}
			};

			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColumn = true;

			var filters = [];
			var standardFilters = {
				Account: {
					field: 'client.id',
					columnPath: 'account',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					displayType: 'listLazy',
					search: true,
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.accounts',
					hide: function (AppService, $stateParams) {
						// Check if visible --> return true || false..
						var udo = AppService.getMetadata().params.UserDefinedObject;

						var udoId = parseInt($stateParams.typeId);

						var enableFilter = _.find(udo, function (obj) {
							return obj.id === udoId && (obj.link === 4 || obj.link === 6);
						});

						if (enableFilter) {
							return false;
						}
						return true;
					},
					multiComparitors: true
				},
				Campaign: {
					field: 'project.id',
					columnPath: 'campaigns',
					resource: 'Campaign',
					inputType: 'select',
					type: 'list',
					displayType: 'listLazy',
					hide: function (AppService, $stateParams) {
						// Check if visible --> return true || false..
						var udo = AppService.getMetadata().params.UserDefinedObject;
						var udoId = parseInt($stateParams.typeId);
						var enableFilter = _.find(udo, { id: udoId, link: 1 });
						if (enableFilter) {
							return false;
						}
						return true;
					},
					search: true,
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term) {
							var filter = new RequestBuilder();
							filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.campaigns',
					multiComparitors: true,
					multiple: true
				},
				Category: {
					field: 'category.id',
					column: 'categories',
					inputType: 'select',
					multiple: true,
					hide: true,
					multiComparitors: true,
					dataPromise: function ($stateParams, $q, filterName, UserDefinedCategory) {
						var defered = $q.defer();
						var udoId = parseInt($stateParams.typeId);
						UserDefinedCategory.setId(udoId)
							.find({})
							.then(function (res) {
								var categoryMap = _.groupBy(res.data, 'categoryTypeId');
								if (filterName.indexOf('Category_') === 0) {
									var categoryId = filterName.split('_')[1];
									defered.resolve({ data: categoryMap[categoryId] });
								} else {
									defered.resolve({ data: categoryMap[0] });
								}
							});

						return defered.promise;
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.categories',
					type: 'list',
					columnPath: 'categories'
				},
				User: {
					field: 'user.id',
					title: 'default.users',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta, customerId, $stateParams) {
						return UserTreeFilterMeta('USERDEFOBJ' + $stateParams.typeId);
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true
				},
				AccountManager: {
					field: 'client.users.id',
					title: 'default.accountManagers',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta, customerId, $stateParams) {
						return UserTreeFilterMeta('USERDEFOBJ' + $stateParams.typeId);
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true
				},
				Contact: {
					field: 'contact.id',
					multiple: true,
					title: 'default.contacts',
					inputType: 'select',
					resource: 'Contact',
					type: 'list',
					search: true,
					multiComparitors: true,
					searchField: 'name',
					// displayText: function(res) {
					// 	return res.name + (res.client ? ' <i class="gray">(' + res.client.name + ')</i>' : '');
					// },
					displayType: 'listLazy',
					searchFn: function (Contact, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Contact.attr.name, true);

							if (term) {
								filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
							}

							return Contact.customer(cId).find(filter.build());
						};
					},
					hide: function (AppService, $stateParams) {
						// Check if visible --> return true || false..
						var udo = AppService.getMetadata().params.UserDefinedObject;
						var udoId = parseInt($stateParams.typeId);
						var enableFilter = _.find(udo, { id: udoId, link: 6 });

						if (enableFilter) {
							return false;
						}
						return true;
					}
				},
				Notes1: {
					field: 'notes1',
					multiple: false,
					resource: 'userDefinedObject',
					type: 'normal',
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					hide: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo) {
							return false;
						} else {
							return true;
						}
					},
					title: 'default.notes',
					inputType: 'text'
				},
				Notes2: {
					field: 'notes2',
					multiple: false,
					resource: 'userDefinedObject',
					type: 'normal',
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					hide: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var notes = udo.notes.split(';');
							return notes.length === 1;
						} else {
							return true;
						}
					},
					titleFn: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var note = udo.notes.split(';');
							if (note.length > 1) {
								return note[1];
							} else {
								return null;
							}
						} else {
							return null;
						}
					},
					title: 'default.notes',
					inputType: 'text'
				},
				Notes3: {
					field: 'notes3',
					multiple: false,
					resource: 'userDefinedObject',
					type: 'normal',
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					hide: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var notes = udo.notes.split(';');
							return notes.length <= 2;
						} else {
							return true;
						}
					},
					titleFn: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var note = udo.notes.split(';');
							if (note.length > 2) {
								return note[2];
							} else {
								return null;
							}
						} else {
							return null;
						}
					},
					title: 'default.notes',
					inputType: 'text'
				},
				Notes4: {
					field: 'notes4',
					multiple: false,
					resource: 'userDefinedObject',
					type: 'normal',
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					hide: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var notes = udo.notes.split(';');
							return notes.length <= 3;
						} else {
							return true;
						}
					},
					titleFn: function (AppService, $stateParams) {
						var metadata = AppService.getMetadata();
						var udo = _.find(metadata.params.UserDefinedObject, { id: parseInt($stateParams.typeId) });

						if (udo && udo.notes) {
							var note = udo.notes.split(';');
							if (note.length > 3) {
								return note[3];
							} else {
								return null;
							}
						} else {
							return null;
						}
					},
					title: 'default.notes',
					inputType: 'text'
				},
				custom: []
			};

			var requiredFields = ['custom'];

			return {
				getAll: function () {
					return attributes;
				},
				requiredFields: requiredFields,
				filters: filters,
				standardFilters: standardFilters,
				attr: attributes,
				keys: {
					ID: 'id',
					CONFIG: 'config',
					CATEGORIES: 'categories',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

import AnonFieldPage from 'Components/anonymization/fieldPage/fieldPage';
import SetupComponent from '../SetupRootComponent';

const ctrl = (
	$scope,
	$element,
	$modalParams,
	AppService,
	ContactAttributes,
	ActivityAttributes,
	AppointmentAttributes,
	OrderAttributes,
	OpportunityAttributes,
	MailAttributes,
	EventAttributes,
	AgreementAttributes
) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('ADMIN_CONTACT_AUTOMATION')) {
		const metadata = AppService.getMetadata();

		const entities = {
			udos: [...metadata.params.UserDefinedObject],
			contact: ContactAttributes().attr,
			activity: ActivityAttributes().attr,
			appointment: AppointmentAttributes().attr,
			order: OrderAttributes().attr,
			opportunity: OpportunityAttributes().attr,
			mail: MailAttributes().attr,
			formsubmit: ['fields'],
			esign: ['fields'],
			document: ['fields'],
			event: EventAttributes().attr,
			agreement: AgreementAttributes().attr
		};

		const resolve = $scope.resolve;
		$scope.resolve = res => resolve(res.fields);

		const props = {
			type: 'confirm',
			id: 'must-have-fields',
			body: Tools.$translate('admin.anonymization.go.error.body'),
			title: Tools.$translate('admin.anonymization.go.error.title'),
			selectedFields: $modalParams.properties || [],
			entities,
			obligatoryFields,
			reactive: true,
			isModal: true
		};
		SetupComponent($scope, AnonFieldPage, $element[0], props, { modal: true });
	}
};

const obligatoryFields = [
	{ name: 'column.name', field: 'name', parent: 'contact' },
	{ name: 'default.title', field: 'title', parent: 'contact' },
	{ name: 'default.phone', field: 'phone', parent: 'contact' },
	{ name: 'default.cellPhone', field: 'cellPhone', parent: 'contact' },
	{ name: 'default.email', field: 'email', parent: 'contact' },
	{ name: 'default.notes', field: 'notes', parent: 'contact' },
	{ name: 'admin.anonymization.customFields', field: 'custom', parent: 'contact' }
];

export default ctrl;
window.AnonymizeContactController = ctrl;

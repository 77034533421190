'use strict';

angular.module('domain.campaign').factory('EditCampaignMeta', [
	'$q',
	'Campaign',
	'CustomfieldMapper',
	'NotificationService',
	'AppService',
	function ($q, Campaign, CustomfieldMapper, NotificationService, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();

				var promises = {
					customFields: $q.when({ data: AppService.getCustomFields('project') })
				};

				if ($modalParams.id) {
					promises.campaign = Campaign.customer(customerId).get($modalParams.id);
				} else if ($modalParams.campaign) {
					promises.campaign = $q.when({ data: $modalParams.campaign });
				} else {
					promises.campaign = $q.when({ data: {} });
				}

				return $q
					.all(promises)
					.then(function (response) {
						response.campaign.data.custom = CustomfieldMapper.parse(
							response.customFields.data,
							response.campaign.data.custom
						);
						return response;
					})
					.catch(function (err) {
						NotificationService.addNotification({
							title: 'default.error',
							body: 'openError.campaign',
							style: 'error',
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

'use strict';

const { formatWithSubAccounts } = require('App/helpers/accountsHelper');

(function () {
	// eslint-disable-next-line camelcase
	var UNSAFE_componentWillMount = function () {
		this.t = Tools.$translate;
		this.lang = {
			account: this.t('default.account'),
			latestAccounts: this.t('default.latestAccounts'),
			noName: this.t('default.noName')
		};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self, asIds) {
		var Account = Tools.Account;
		const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		var opts = {
			resource: Account,
			required: self.props.required || false,
			multiple: self.props.multiple || false,
			ajax: true,
			asIds: asIds,
			idAttr: Account.attr.id,
			titleAttr: Account.attr.name,
			sorting: { field: Account.attr.name, ascending: true },
			emptyMsg: self.lang.noName,
			addEvent: 'account.added',
			filterType: 'account',
			onChange: self.props.onChange,
			select2: self.props.select2 || {},
			fields: hasSubaccounts ? [...(self.props.fields || []), 'operationalAccount'] : self.props.fields || null,
			filters: rb => {
				if (self.props.ignoreIds) {
					rb.addFilter({ field: 'id' }, rb.comparisonTypes.NotEquals, self.props.ignoreIds);
				}
				rb.addFilter({ field: 'active' }, rb.comparisonTypes.Equals, true);
				return rb.build();
			},
			placeholder: function () {
				return self.props.placeholder;
			},
			goTo: function () {
				return Tools.$state.go('account.dashboard', { id: self.props.value.id }, { reload: true });
			},
			formatData: function (data) {
				if (!hasSubaccounts) {
					return data;
				}
				return formatWithSubAccounts(data, self.t);
			},
			formatResult: function (account, container, query, escape) {
				let title = '';
				if (hasSubaccounts && account.operationalAccount) {
					title +=
						'<div class="subtitle grey">' +
						self.t('account.subaccount.tooltip', {
							parent: account.operationalAccount.name
						}) +
						'</div>';
				}
				return (
					'<div style="position:relative;">' +
					(account.name ? escape(account.name) : self.t('default.noName')) +
					title +
					'</div>'
				);
			},
			data: function () {
				var initData = [];
				if (Tools.LatestAccountsService) {
					var AppService = Tools.AppService;

					let latestAccounts = Tools.LatestAccountsService.get(
						AppService.getCustomerId(),
						AppService.getSelf().id
					);
					if (self.props.ignoreIds) {
						latestAccounts = latestAccounts.filter(
							account => !self.props.ignoreIds.includes(account.id) && account.active
						);
					}

					if (!hasSubaccounts) {
						initData.push({
							name: self.lang.latestAccounts,
							children: latestAccounts
						});
					} else {
						initData.push(...formatWithSubAccounts(latestAccounts, self.t, true));
					}
				}
				return initData;
			}
		};

		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upAccounts = window.ReactCreateClass({
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		render: render,
		componentWillUnmount: function () {
			var input = jQuery(this._input);
			input.select2('destroy');
		}
	});

	ReactTemplates.INPUTS.upAccountsIds = window.ReactCreateClass({
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		componentDidMount: function () {
			componentDidMount(this, true);
		},
		render: render,
		componentWillUnmount: function () {
			var input = jQuery(this._input);
			input.select2('destroy');
		}
	});
})();

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import './google.scss';

const GoogleButton = ({ dark = true, text = '', ...btnProps }) => {
	const classes = new Bem('btn');

	return (
		<Button {...btnProps} className={classes.mod({ google: true, 'google-dark': dark, 'google-light': !dark }).b()}>
			<div className={classes.elem('google-logo').b()}></div>
			<Text bold={true} color={dark ? 'white' : 'black'}>
				{text}
			</Text>
		</Button>
	);
};

GoogleButton.propTypes = {
	text: PropTypes.string,
	dark: PropTypes.bool,
	btnProps: PropTypes.object
};

export default GoogleButton;

'use strict';

angular.module('domain.contact').controller('EditContactRelation', [
	'$rootScope',
	'$scope',
	'$modalParams',
	'$upModal',
	'$translate',
	'ContactRelation',
	'Contact',
	'Account',
	function ($rootScope, $scope, $modalParams, $upModal, $translate, ContactRelation, Contact, Account) {
		$scope.loading = true;
		$scope.error = false;

		$scope.save = function () {
			$scope.relation.contactId = $scope.contact.id;
			$scope.relation.relatedToClientId = $scope.client.id;
			ContactRelation.customer($modalParams.customerId)
				.save($scope.relation)
				.then(function () {
					$scope.resolve();
				});
		};

		$scope.select = function (account) {
			if (!account || !account.id) {
				$scope.client = null;
				$scope.alreadyRelated = false;
				return;
			}
			var alreadyRelated = _.find($scope.contact.connectedClients, { relatedToClientId: account.id });
			if (alreadyRelated) {
				$scope.alreadyRelated = true;
			} else {
				$scope.alreadyRelated = false;
				$scope.client = account;
			}
		};

		$scope.startOver = function () {
			$scope.client = undefined;
		};

		$scope.setTitleAsDescription = function () {
			$scope.relation.description = $scope.contact.title;
		};

		$scope.move = function () {
			$scope.resolve();
			$upModal.open('moveContact', {
				contact: $scope.contact
			});
		};

		var init = function () {
			$scope.relation = $modalParams.relation || {};
			$scope.contact = $modalParams.contact || null;
			//$scope.client = $modalParams.account || null;
			$scope.edit = $scope.relation && $scope.relation.id;

			if (!$scope.edit) {
				$scope.loading = false;
				return;
			}

			ContactRelation.customer($modalParams.customerId)
				.get($scope.relation.id)
				.then(function (res) {
					$scope.relation = res.data;
					Account.customer($modalParams.customerId)
						.get($scope.relation.relatedToClientId)
						.then(function (res) {
							$scope.client = res.data;
							if ($scope.contact) {
								$scope.loading = false;
								return;
							}
							Contact.customer($modalParams.customerId)
								.get($scope.relation.contactId)
								.then(function (res) {
									$scope.contact = res.data;
									$scope.loading = false;
								});
						});
				})
				.catch(function () {
					$scope.error = true;
				});
		};

		init();
	}
]);

'use strict';

angular.module('upAttributes').service('StaticValueLookUpFilter', [
	'$q',
	'AppService',
	'Lookup',
	function ($q, AppService, Lookup) {
		return function (options) {
			var DEFAULT_OPTIONS = {
				type: 'list',
				displayType: 'listLazy',
				comparisonType: 'MatchExact',
				unreleasedFeature: 'NEW_FIELDS',
				parent: 'filters.columns.account',
				field: options.field,
				columnPath: options.field,
				title: 'tag.account.' + options.field
			};

			var staticValuePromise = AppService.getStaticValuesPromise(options.field).then(function (result) {
				return _.reduce(
					result,
					function (idMap, staticValue) {
						idMap[staticValue.id] = staticValue;
						return idMap;
					},
					{}
				);
			});

			var filter = {
				resource: function () {
					return function (customerId, values) {
						return staticValuePromise.then(function (result) {
							var data = _.map(values, function (id) {
								var staticValue = result[id];
								var name = staticValue ? staticValue.name : '';
								return { id: id, name: name };
							});
							return { data: data, metadata: { total: 0 } };
						});
					};
				},
				searchFn: function () {
					return function (term, fields, offset, limit) {
						var customerId = AppService.getCustomerId();
						var lookUpPromise = Lookup.customer(customerId)
							.setType('account')
							.findEnd(options.field, '', 1000);

						var promises = {
							staticValues: staticValuePromise,
							lookUp: lookUpPromise
						};

						return $q.all(promises).then(function (result) {
							var searchTerm = (term || '').trim().toLowerCase();

							var mappedData = _.map(result.lookUp.data, function (item) {
								var id = item.value;
								var staticValue = result.staticValues[id];
								var name = staticValue ? staticValue.name : id;
								return { id: id, name: name };
							}).filter(function (item) {
								return (item.name || '').toLowerCase().indexOf(searchTerm) > -1;
							});
							var data = _.sortBy(mappedData, 'name').slice(offset, offset + limit);
							var metadata = { total: mappedData.length, offset: offset, limit: limit };
							return { data: data, metadata: metadata };
						});
					};
				}
			};

			return _.assign(filter, DEFAULT_OPTIONS, options);
		};
	}
]);

angular.module('upDirectives').directive('upCurrency', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attr, ngModel) {
			var rate = scope.$eval(attr.upCurrency);
			var decimals = scope.$eval(attr.decimals);

			var numOfDecimals = 2;
			if (decimals && !isNaN(parseInt(decimals))) {
				numOfDecimals = parseInt(decimals);
			}

			ngModel.$parsers.push(function (inputValue) {
				if (scope.$parent.convert) {
					return inputValue / rate;
				}
				return inputValue;
			});

			ngModel.$formatters.push(function (val) {
				if (scope.$parent.convert) {
					return parseFloat(val * rate).toFixed(numOfDecimals);
				}
				return parseFloat(parseFloat(val).toFixed(numOfDecimals));
			});
		}
	};
});

import LanguageSelect from 'App/components/Inputs/Selects/LanguageSelect';
import store from 'Store/index';
import { Provider } from 'react-redux';

angular.module('upDirectives').directive('upLanguage', [
	function () {
		return {
			scope: {
				ngModel: '=ngModel',
				onSelect: '=',
				languages: '=',
				ngRequired: '='
			},
			require: 'ngModel',
			replace: true,
			restrict: 'A',
			link: function ($scope, $element) {
				const firstElement = $element[0];
				const required = $scope.ngRequired;
				let supportedLanguages = $scope.languages || [];

				const onChange = function (value) {
					$scope.onSelect(value);
				};

				const ReduxComponent = p => (
					<Provider store={store}>
						<LanguageSelect {...p} />
					</Provider>
				);

				const render = function () {
					if (!firstElement) {
						return;
					}

					const props = {
						required,
						onChange,
						value: $scope.ngModel,
						options: supportedLanguages,
						anchor: firstElement
					};

					ReactDOM.render(React.createElement(ReduxComponent, props), firstElement, function () {});
				};

				$scope.$watch('languages', function (newval, oldval) {
					if (newval !== oldval) {
						supportedLanguages = newval || [];
						ReactDOM.unmountComponentAtNode(firstElement);
						render();
					}
				});

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
				});

				$scope.$watch(
					'ngModel',
					function () {
						render();
					},
					true
				);
			}
		};
	}
]);

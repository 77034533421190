'use strict';

angular.module('domain.engage').factory('SelectCreativeTemplateMeta', [
	'$q',
	'AppService',
	'AccountProfile',
	function ($q, AppService, AccountProfile) {
		return function () {
			return AppService.loadedPromise.then(function () {
				var promises = {
					accountProfile: AccountProfile.get()
				};

				return $q.all(promises);
			});
		};
	}
]);

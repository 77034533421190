'use strict';

ReactTemplates.voice.followup = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			callEnded: t('voice.callEnded'),
			howWasTheCall: t('voice.howWasTheCall'),
			callAgain: t('voice.callAgain'),
			showCallList: t('voice.showCallList'),
			activity: t('default.activity'),
			appointment: t('default.appointment'),
			opportunity: t('default.opportunity'),
			order: t('default.order'),
			mail: t('mail.mail'),
			goToContact: t('voice.goToContact'),
			goToClient: t('voice.goToClient')
		};
	},

	render: function () {
		var self = this;
		var callerId = self.props.callerId;

		if (!callerId) {
			return null;
		}

		var name = callerId.contact ? callerId.contact.name : callerId.client.name;
		if (this.props.minimized) {
			return (
				<div>
					<div className="meta">
						<h2 style={{ lineHeight: '15px', height: '15px' }}>
							{callerId.contact ? callerId.contact.name : callerId.client.name}
						</h2>
						{callerId.contact && callerId.client ? (
							<div className="subtitle">{callerId.client.name}</div>
						) : null}
					</div>
				</div>
			);
		}

		let reDial;

		if (this.props.isCallTo) {
			const tel = ReactTemplates.TOOLS.upVoice(null, null, this.props.number);
			reDial = (
				<a
					className="Text--center up-btn btn-link btn-block btn-sm btn-bright-blue"
					target={tel.target}
					href={tel.href}
					onClick={self.props.reDial}
				>
					{self.lang.callAgain}
				</a>
			);
		} else {
			reDial = (
				<button
					type="button"
					className="up-btn btn-link btn-block btn-sm btn-bright-blue"
					onClick={self.props.reDial}
				>
					{self.lang.callAgain}
				</button>
			);
		}

		return (
			<div>
				<div id="followup-icon">
					<b className="fa fa-phone"></b>
					<b className="fa fa-times"></b>
				</div>
				<div className="subtitle">{self.lang.callEnded}</div>
				<h2>
					{self.lang.howWasTheCall}{' '}
					<span onClick={self.props.doAction.bind(this, 'goTo')} className="clickable">
						{name}
					</span>
					{'?'}
				</h2>
				{reDial}
				<div id="call-actions">
					<div className="action" onClick={self.props.doAction.bind(this, 'activity')}>
						<b className="up-icon-activity"></b>
						{self.lang.activity}
					</div>
					<div className="action" onClick={self.props.doAction.bind(this, 'appointment')}>
						<b className="fa fa-calendar"></b>
						{self.lang.appointment}
					</div>
					<div className="action" onClick={self.props.doAction.bind(this, 'opportunity')}>
						<b className="up-icon-pipeline"></b>
						{self.lang.opportunity}
					</div>
					<div className="action" onClick={self.props.doAction.bind(this, 'order')}>
						<b className="fa fa-dollar"></b>
						{self.lang.order}
					</div>
					<div className="action" onClick={self.props.doAction.bind(this, 'mail')}>
						<b className="fa fa-envelope"></b>
						{self.lang.mail}
					</div>
					<div className="action" onClick={self.props.doAction.bind(this, 'goTo')}>
						{callerId.contact ? <b className="fa fa-user"></b> : <b className="fa fa-building-o"></b>}
						{callerId.contact ? self.lang.goToContact : self.lang.goToClient}
					</div>
					<button
						type="button"
						className="up-btn btn-link btn-block btn-sm btn-bright-blue"
						onClick={self.props.showCallList}
					>
						{self.lang.showCallList}
					</button>
				</div>
			</div>
		);
	}
});

import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import ValueSugest from 'Components/Inputs/ValueSugest';
import { ComparisionToggle } from 'Components/Inputs/ComparisonTypeToggle';

export default class LookupInput extends React.Component {
	constructor(props) {
		super(props);

		const { $translate } = Tools;

		this.lang = {
			is: $translate('comparison.Equals'),
			isNot: $translate('comparison.NotEquals')
		};

		this.onComperatorChange = this.onChange.bind(this, 'comperator');
		this.onValuesChange = e => this.onChange('values', e.target.value);
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'values':
				newFilter.value = value;
				break;
			case 'comperator':
				newFilter.comparisonType = value;
				break;
		}

		newFilter.inactive = !this.isFilterActive(newFilter);

		onChange(newFilter.value, newFilter);
	}

	isFilterActive(filter) {
		return Array.isArray(filter.value) && filter.value.length;
	}

	getPlaceholder() {
		const { config } = this.props;
		const { $translate } = Tools;

		switch (config.filterName) {
			case 'TitleList':
			case 'City':
			case 'State':
				return $translate('segment.placeholder.' + config.filterName);
			default:
				return $translate('segment.selectCriteriaPlaceholder');
		}
	}

	render() {
		const { filter, config, autoFocus } = this.props;
		const type = config.entity || 'contact';
		const field = config.searchField || config.field;
		const classNames = this.isFilterActive(filter) ? 'multi-comparitors-wrap active' : 'multi-comparitors-wrap';

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<div className={classNames}>
						<ComparisionToggle
							trueValue={'MatchExact'}
							falseValue={'NotMatchExact'}
							trueText={this.lang.is}
							falseText={this.lang.isNot}
							onClick={this.onComperatorChange}
							value={filter.comparisonType}
						/>
						<ValueSugest
							placeholder={this.getPlaceholder()}
							field={field}
							entity={type}
							className="form-control"
							defaultValue={filter.value}
							onChange={this.onValuesChange}
							autoOpen={autoFocus && true}
						/>
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

LookupInput.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool.isRequired
};

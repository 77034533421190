import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';
import t from 'Components/Helpers/translate';

import './Changes.scss';

// These properties are hard coded and can't be changed by the user so we sould not count them
const ignoreFromCount = ['ForceInvalidPropertyChange'];

export default class ChangesInfo extends React.Component {
	render() {
		const classes = new BemClass('ChangesInfo');
		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>{`${
						this.props.config.properties.filter(p => !ignoreFromCount.includes(p.name)).length
					} ${t('default.changes').toLowerCase()}`}</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

ChangesInfo.propTypes = {
	config: PropTypes.object.isRequired
};

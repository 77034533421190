'use strict';

angular.module('domain.campaign').controller('CampaignContacts', [
	'$scope',
	'$state',
	'FilterHelper',
	'$upModal',
	'RequestBuilder',
	'Contact',
	'AppService',
	function ($scope, $state, FilterHelper, $upModal, RequestBuilder, Contact, AppService) {
		var customerId;
		var CampaignCtrl = $scope.CampaignCtrl;
		var currentFilter;

		var CampaignContactsCtrl = this;

		CampaignContactsCtrl.limit = 50;
		CampaignContactsCtrl.error = false;
		CampaignContactsCtrl.loading = false;
		CampaignContactsCtrl.currentPage = 1;

		$scope.$on('contact.added', function (e, added) {
			if (added.projects && _.findIndex(added.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				CampaignContactsCtrl.contacts.push(added);
			}
		});

		$scope.$on('contact.updated', function (e, updated) {
			var index = _.findIndex(CampaignContactsCtrl.contacts, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'contact')) {
				if (index !== -1) {
					CampaignContactsCtrl.contacts[index] = updated;
				} else {
					CampaignContactsCtrl.contacts.push(updated);
				}
			} else if (index !== -1) {
				CampaignContactsCtrl.contacts.splice(index, 1);
			}
		});

		$scope.$on('contact.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignContactsCtrl.contacts, { id: deleted.id });

			if (index !== -1) {
				CampaignContactsCtrl.contacts.splice(index, 1);
			}
		});

		CampaignContactsCtrl.getContacts = function ($page) {
			CampaignContactsCtrl.error = false;
			CampaignContactsCtrl.loading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Contact.attr.campaigns, filter.comparisonTypes.Equals, CampaignCtrl.campaign.id);
			filter.addSort('name', true);
			filter.limit = CampaignContactsCtrl.limit;
			filter.offset = CampaignContactsCtrl.limit * ($page - 1);
			currentFilter = filter.build();

			Contact.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					CampaignContactsCtrl.loading = false;
					CampaignContactsCtrl.contacts = res.data;
					CampaignCtrl.contactsTotal = res.metadata.total;
				})
				.catch(function () {
					CampaignContactsCtrl.loading = false;
					CampaignContactsCtrl.error = true;
				});
		};

		CampaignContactsCtrl.moveContact = function (contact) {
			_.remove(contact.projects, { id: CampaignCtrl.campaign.id });
			Contact.customer(customerId).save({ id: contact.id, campaigns: contact.projects });
		};

		CampaignContactsCtrl.openContact = function (id) {
			$state.go('contact.dashboard', { customerId: customerId, id: id });
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			CampaignContactsCtrl.getContacts(1);
		};

		AppService.loadedPromise.then(init);
	}
]);

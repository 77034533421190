import PropTypes from 'prop-types';
import { Component } from 'react';
import T from 'Components/Helpers/translate';

class UpFlow extends Component {
	componentDidUpdate() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;

		if (!this.props.uncontrolled) {
			selectHelper.updateValue.call(this);
		}
	}

	componentDidMount() {
		const Flow = Tools.Flow;
		let opts = {
			getter: (customerId, filter) => Flow.find(filter, { skipStats: true }),
			required: this.props.required || false,
			multiple: this.props.multiple || false,
			placeholder: this.props.placeholder,
			onChange: this.props.onChange,
			ajax: true,
			asIds: false,
			idAttr: Flow.attr.id,
			titleAttr: Flow.attr.name,
			sorting: { field: Flow.attr.name, ascending: true },
			emptyMsg: this.props.emptyMsg || T('default.noName'),
			filterType: 'flow',
			formatResult: this.props.formatResult || ((object, container, query, escape) => escape(object.name)),
			formatSelection: this.props.formatSelection || ((object, container, escape) => escape(object.name))
		};

		const input = jQuery(this._input);

		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		opts = _.merge({}, opts, this.props.options);

		selectHelper.getSelect2Options(this, opts, input, options => {
			if (this.props.onlyWithoutSegments) {
				options.data = _.filter(options.data, function (o) {
					return !o.segment;
				});
			}

			const select2 = input.select2(options);
			if (this.props.eventListeners) {
				_.each(this.props.eventListeners, function (fn, event) {
					select2.on(event, fn);
				});
			}

			if (typeof this.props.onReady === 'function') {
				this.props.onReady();
			}
		});
	}

	render() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		return selectHelper.getInputComponent(this);
	}
}

UpFlow.propTypes = {
	onlyWithoutSegments: PropTypes.bool,
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	emptyMsg: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.object,
	eventListeners: PropTypes.object,
	formatResult: PropTypes.func,
	formatSelection: PropTypes.func,
	uncontrolled: PropTypes.bool,
	onReady: PropTypes.func
};

UpFlow.defaultProps = {
	uncontrolled: false
};

window.UpFlow = UpFlow;

export default UpFlow;

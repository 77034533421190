import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';
import t from 'Components/Helpers/translate';
import entityName from 'Components/Helpers/EntityName';

import './Segment.scss';

export default class SegmentInfo extends React.Component {
	render() {
		const classes = new BemClass('SegmentInfo');
		const numSegments = this.props.config.properties.length;
		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>{`${numSegments} ${t(
						entityName('segment', numSegments)
					).toLowerCase()}`}</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

SegmentInfo.propTypes = {
	config: PropTypes.object.isRequired
};

'use strict';

var leadSourceSelectDir = 'upLeadSourceSelect';
angular
	.module('upDirectives')
	.directive(leadSourceSelectDir, function (LeadSource, selectHelper) {
		return selectHelper.getDirectiveObject(leadSourceSelectDir, {
			resource: LeadSource,
			ajax: true,
			asIds: false,
			idAttr: LeadSource.attr.id,
			titleAttr: LeadSource.attr.name,
			sorting: { field: LeadSource.attr.name, ascending: true }
		});
	});

import _ from 'lodash';

import openModal, { shouldOpenModal } from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import AdvancedSearchFooter from 'Components/AdvancedSearch/AdvancedSearchFooter';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

angular.module('domain.advancedSearch').controller('AdvancedSearch', [
	'AppService',
	'UserTreeFilterMeta',
	'$scope',
	'$q',
	'$safeApply',
	'Contact',
	'Account',
	'Activity',
	'Appointment',
	'Opportunity',
	'Order',
	'Segment',
	'RequestBuilder',
	'FilterHelper',
	'$upModal',
	'$translate',
	'$state',
	'$safeDigest',
	'$multiSelect',
	'$timeout',
	'ListAttributes',
	'$location',
	'MultiRunnerActions',
	'FeatureHelper',
	function (
		AppService,
		UserTreeFilterMeta,
		$scope,
		$q,
		$safeApply,
		Contact,
		Account,
		Activity,
		Appointment,
		Opportunity,
		Order,
		Segment,
		RequestBuilder,
		FilterHelper,
		$upModal,
		$translate,
		$state,
		$safeDigest,
		$multiSelect,
		$timeout,
		ListAttributes,
		$location,
		MultiRunnerActions,
		FeatureHelper
	) {
		var AdvancedSearch = this;
		var customerId;
		var GET_TIMEOUT = 200;
		var getTimeout = null;
		var getDryTimeout = null;
		var filterTimeout = null;
		var mainContent = document.getElementById('main-content');
		mainContent.classList.add('loading-view');
		var filterConfigs = {};

		AdvancedSearch.expanded = true;
		AdvancedSearch.AdvancedSearchFooterComponent = AdvancedSearchFooter;

		// Root data for react
		AdvancedSearch.rootData = {
			loading: false,
			viewLoading: true,
			searchView: null,
			numResults: {
				ACCOUNT: 0,
				CONTACT: 0,
				ACTIVITY: 0,
				APPOINTMENT: 0,
				OPPORTUNITY: 0,
				ORDER: 0
			},
			setResultType: function (type) {
				AdvancedSearch.setResultEntity(type);
				AdvancedSearch.resetPaginator();
				AdvancedSearch.search();
			}
		};

		var lastUsedCols = {
			ACCOUNT: [],
			CONTACT: [],
			ACTIVITY: [],
			APPOINTMENT: [],
			OPPORTUNITY: [],
			ORDER: []
		};

		var attrs = {
			ACCOUNT: ListAttributes.get('account'),
			CONTACT: ListAttributes.get('contact'),
			ACTIVITY: ListAttributes.get('activity'),
			APPOINTMENT: ListAttributes.get('appointment'),
			OPPORTUNITY: ListAttributes.get('opportunity'),
			ORDER: ListAttributes.get('order'),
			SEGMENT: ListAttributes.get('segment')
		};

		var filterNames = {
			ACCOUNT: [
				'Id',
				'Name',
				'User',
				'ParentId',
				'AddressFilters',
				'OrderFilters',
				'OpportunityFilters',
				'ActivityFilters',
				'Fax',
				'Phone',
				'Webpage',
				'Notes',
				'Campaign',
				'Category',
				'Score',
				'MailCampaignFilters',
				'VisitorFilters',
				'FormSubmitFilters',
				'AccountManagerFilters',
				'Status',
				'RegistrationDate',
				'CompanyForm',
				'CreditRating',
				'Turnover',
				'Profit',
				'NoEmployees',
				'SniCode',
				'SniCodeRange',
				'OrgNoLookup',
				'SicCode',
				'NaicsCode',
				'UKSicCode',
				'NaceCode',
				'JourneyStep',
				'RegDate',
				'SubAccounts',
				'Active'
			],
			CONTACT: [
				'Name',
				'Title',
				'Active',
				'Campaign',
				'Category',
				'Notes',
				'Phone',
				'CellPhone',
				'Unsubscribed',
				'UnsubscribedList',
				'MailBounced',
				'Score',
				'MailCampaignFilters',
				'VisitorFilters',
				'FormSubmitFilters',
				'AccountManagerFilters',
				'Id',
				'Segment',
				'Salutation',
				'JourneyStep',
				'RegDate',
				'EmailMissingAt'
			],
			ACTIVITY: [
				'Notes',
				'Description',
				'Status',
				'AdvancedDate',
				'CloseDate',
				'Date',
				'RegDate',
				'User',
				'ActivityType',
				'Priority',
				'Project',
				'Id',
				'Campaign',
				'CallList'
			],
			APPOINTMENT: [
				'Notes',
				'Description',
				'Status',
				'AdvancedDate',
				'User',
				'AppointmentType',
				'Campaign',
				'Id',
				'AppointmentOutcome'
			],
			OPPORTUNITY: [
				'Notes',
				'AdvancedDate',
				'ValueRange',
				'Stage',
				'Id',
				'Campaign',
				'OrderRowProduct',
				'OrderRowProductCategory',
				'IsOpportunity',
				'Age',
				'Status',
				'User',
				'Description'
			],
			ORDER: [
				'Notes',
				'AdvancedDate',
				'ValueRange',
				'Stage',
				'Id',
				'Campaign',
				'OrderRowProduct',
				'OrderRowProductCategory',
				'IsOpportunity',
				'AgreementId',
				'FromOpportunity',
				'User',
				'Description'
			],
			ORDERROW: []
		};

		var lastUsedColumns = function (type, set) {
			if (set && type) {
				lastUsedCols[type] = set;
			}
			var cols = lastUsedCols[type];
			// We want dem cols everytime
			if (!cols.length) {
				return lastUsedColumns(type, AdvancedSearch.columns[type]);
			}
			return angular.copy(lastUsedCols[type]);
		};

		AdvancedSearch.customFields = {};

		$scope.$on('$multiSelect.toggle', function (e, data) {
			AdvancedSearch.listActionData.selected = data.selected.length;
			$safeDigest($scope);
		});

		$scope.$on('$multiSelect.selectAll', function (e, data) {
			AdvancedSearch.listActionData.selected = data.selected.length;
			AdvancedSearch.listActionData.allSelected = false;
			$safeDigest($scope);
		});

		$scope.$on('$multiSelect.selectAllVisible', function (e, data) {
			AdvancedSearch.listActionData.selected = data.selected.length;
			AdvancedSearch.listActionData.allSelected = false;
			$safeDigest($scope);
		});

		$scope.$on('$multiSelect.selectAllFilter', function () {
			AdvancedSearch.listActionData.selected =
				AdvancedSearch.rootData.numResults[AdvancedSearch.rootData.searchView.resultsAs];
			AdvancedSearch.listActionData.allSelected = true;
			$safeDigest($scope);
		});

		$scope.$on('$multiSelect.selectNone', function () {
			AdvancedSearch.listActionData.selected = 0;
			AdvancedSearch.listActionData.allSelected = false;
			$safeDigest($scope);
		});

		$scope.$on('listView.refresh', function () {
			AdvancedSearch.search();
		});

		// ExtraSelectables
		AdvancedSearch.extraSelectables = {};

		// List opts
		AdvancedSearch.listOpts = {
			ACCOUNT: {
				type: 'account',
				hasCheck: true,
				editable: true,
				clickedEntry: function (account) {
					$state.go('account.dashboard', { id: account.id, customerId: customerId });
				},
				editEntry: function (account) {
					if (shouldOpenModal('EditClient')) {
						openModal('EditClient', { id: account.id });
					} else {
						var options = {
							customerId: customerId,
							id: account.id
						};

						$upModal.open('editAccount', options);
					}
				},
				removeEntry: function (account) {
					var confirmButtonText =
						$translate.instant('admin.modal.deleteEntity', {
							entity: $translate.instant('account').toLowerCase()
						}) + $translate.instant('admin.modal.delete.andRelatedObjects');
					var alertConfirmOptions = {
						type: 'confirm',
						reactive: true,
						fullscreen: true,
						hideAbort: false,
						dialog: AlertConfirm,
						id: 'confirm-delete-fields',
						body: React.createElement(AlertBody, {
							account: account,
							customerId: customerId,
							entity: 'Client',
							numSelected: 1
						}),
						confirmButtonText: confirmButtonText,
						confirmClass: 'btn-red',
						confirmationMode: 'text',
						confirmText: account.name,
						confirmFn: function () {
							return Account.customer(customerId)
								['delete'](account)
								.then(function () {
									AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: account.id });
								});
						}
					};

					if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							...alertConfirmOptions,
							title: getConfirmationTitle('Client', 1, account.name),
							onClose: confirmed => {
								if (confirmed) {
									alertConfirmOptions.confirmFn();
								}
							}
						});
					} else {
						$upModal.open('alert', alertConfirmOptions);
					}
				}
			},
			CONTACT: {
				type: 'contact',
				hasCheck: true,
				editable: true,
				clickedEntry: function (contact) {
					$state.go('contact.dashboard', { id: contact.id, customerId: customerId });
				},
				editEntry: function (contact) {
					var options = {
						customerId: customerId,
						id: contact.id
					};

					$upModal.open('editContact', options);
				},
				removeEntry: function (contact) {
					return Contact.customer(customerId)
						['delete'](contact)
						.then(function () {
							AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: contact.id });
						});
				}
			},
			ACTIVITY: {
				type: 'activity',
				hasCheck: true,
				editable: true,
				clickedEntry: function (activity) {
					$upModal.open('editActivity', { id: activity.id });
				},
				openFlash: function (item) {
					var filters = getFilters(AdvancedSearch.entity.ACTIVITY, AdvancedSearch.entity.ACTIVITY).build();
					$scope.$root.$broadcast('openFlash', { currentId: item.id, filter: filters });
				},
				removeEntry: function (activity) {
					return Activity.customer(customerId)
						['delete'](activity)
						.then(function () {
							AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: activity.id });
						});
				},
				editOrder: function (order, e) {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();

					var type = order.probability === 0 || order.probabilit === 100 ? 'order' : 'opportunity';
					$upModal.open('editOrder', {
						id: order.id,
						customerId: customerId,
						type: type
					});
				},
				closeActivity: function (activity) {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('moveOrCloseActivity', {
							icon: 'fa-check-square-o',
							id: activity.id,
							notes: activity.notes,
							description: activity.description,
							isClose: true
						})
						.then(function (res) {
							var data = {
								id: activity.id,
								closeDate: new Date(),
								notes: res.notes
							};

							Activity.customer(customerId).save(data, { updateSuccessBody: 'default.activityClosed' });

							if (res.followUp) {
								var followUpData = Activity.new();
								followUpData.client = activity.client;
								followUpData.users = activity.users;
								followUpData.contacts = activity.contacts;
								followUpData.notes = res.notes;
								followUpData.description = activity.description;
								followUpData.activityType = { id: res.activityType };
								followUpData.date = res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD');

								Activity.customer(customerId).save(followUpData, {
									updateSuccessBody: 'default.didCreateActivity'
								});
							}
						});
				},
				moveActivity: function (activity) {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('moveOrCloseActivity', {
							icon: 'fa-long-arrow-right',
							id: activity.id,
							notes: activity.notes,
							description: activity.description,
							isMove: true
						})
						.then(function (res) {
							var data = {
								id: activity.id,
								notes: res.notes,
								date: res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD')
							};

							Activity.customer(customerId).save(data, { updateSuccessBody: 'default.moveActivity' });
						});
				},
				setPriority: function (item) {
					const { priority } = item;

					return Activity.customer(customerId).save({
						...item,
						priority: priority ? ACTIVITY_PRIORITY.NONE : ACTIVITY_PRIORITY.HIGH
					});
				}
				// hasDocumentTemplates: !!(meta.documentTemplates.data && meta.documentTemplates.data.length),
				// createDocument: function(activity) {
				// 	$upModal.open('createDocument', {type: 'activity', id: activity.id, accountId: activity.client.id, templates: documentTemplates});
				// }
			},
			APPOINTMENT: {
				type: 'appointment',
				hasCheck: true,
				editable: true,
				clickedEntry: function (appointment) {
					var options = {
						customerId: customerId,
						id: appointment.id
					};
					$upModal.open('editAppointment', options);
				},
				editOrder: function (order, e) {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();

					var type = order.probability === 0 || order.probabilit === 100 ? 'order' : 'opportunity';
					$upModal.open('editOrder', {
						id: order.id,
						customerId: customerId,
						type: type
					});
				},
				removeEntry: function (appointment) {
					return Appointment.customer(customerId)
						['delete'](appointment)
						.then(function () {
							AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: appointment.id });
						});
				}
			},
			OPPORTUNITY: {
				type: 'order',
				hasCheck: true,
				editable: true,
				removeEntry: function (order) {
					return Order.customer(customerId)
						['delete'](order)
						.then(function () {
							AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: order.id });
						});
				},
				clickedEntry: function (order, entries, e) {
					$upModal.open('editOrder', { id: order.id, customerId: customerId }, e);
				}
			},
			ORDER: {
				type: 'order',
				hasCheck: true,
				editable: true,
				removeEntry: function (order) {
					return Order.customer(customerId)
						['delete'](order)
						.then(function () {
							AdvancedSearch.results = _.reject(AdvancedSearch.results, { id: order.id });
						});
				},
				clickedEntry: function (order, entries, e) {
					$upModal.open('editOrder', { id: order.id, customerId: customerId }, e);
				}
			}
		};

		// ENUM FOR THEM ENTITTIES
		AdvancedSearch.entity = {
			ACCOUNT: 'ACCOUNT',
			CONTACT: 'CONTACT',
			ACTIVITY: 'ACTIVITY',
			APPOINTMENT: 'APPOINTMENT',
			OPPORTUNITY: 'OPPORTUNITY',
			ORDER: 'ORDER'
		};

		AdvancedSearch.resources = {
			ACCOUNT: Account,
			CONTACT: Contact,
			ACTIVITY: Activity,
			APPOINTMENT: Appointment,
			OPPORTUNITY: Opportunity,
			ORDER: Order
		};

		AdvancedSearch.searchViews = {
			ACCOUNT: {
				title: 'default.accounts',
				icon: 'fa-home',
				resultTypes: ['ACCOUNT'],
				sorting: [{ attribute: 'name', ascending: true }],
				defaultSorting: [{ attribute: 'name', ascending: true }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS)
			},
			CONTACT: {
				title: 'default.contacts',
				icon: 'fa-user',
				resultTypes: ['CONTACT', 'ACCOUNT'],
				sorting: [{ attribute: 'name', ascending: true }],
				defaultSorting: [{ attribute: 'name', ascending: true }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS)
			},
			ACTIVITY: {
				title: 'default.activity',
				icon: 'fa-list',
				resultTypes: ['ACTIVITY', 'ACCOUNT'],
				sorting: [{ attribute: 'date', ascending: false }],
				defaultSorting: [{ attribute: 'date', ascending: true }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS)
			},
			APPOINTMENT: {
				title: 'default.appointment',
				icon: 'fa-calendar',
				resultTypes: ['APPOINTMENT', 'ACCOUNT'],
				sorting: [{ attribute: 'date', ascending: false }],
				defaultSorting: [{ attribute: 'date', ascending: false }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS)
			},
			OPPORTUNITY: {
				title: 'default.opportunities',
				icon: 'up-icon-pipeline',
				resultTypes: ['OPPORTUNITY', 'ACCOUNT'],
				sorting: [{ attribute: 'date', ascending: false }],
				defaultSorting: [{ attribute: 'date', ascending: false }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)
			},
			ORDER: {
				title: 'default.order',
				icon: 'fa-usd',
				resultTypes: ['ORDER', 'ACCOUNT'],
				sorting: [{ attribute: 'date', ascending: false }],
				defaultSorting: [{ attribute: 'date', ascending: false }],
				show: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS)
			}
		};

		AdvancedSearch.tableTemplates = {
			ACCOUNT: 'accountRow',
			CONTACT: 'contactRow',
			ACTIVITY: 'activityRow',
			APPOINTMENT: 'appointmentRow',
			OPPORTUNITY: 'orderRow',
			ORDER: 'orderRow'
		};

		AdvancedSearch.limit = 100;
		AdvancedSearch.$multiSelect = $multiSelect;
		AdvancedSearch.results = [];
		AdvancedSearch.selected = 0;
		AdvancedSearch.currentPage = 1;
		AdvancedSearch.offset = 0;

		// Columns for listViews
		AdvancedSearch.columns = {
			ACCOUNT: ['name', 'users', 'Address_Visit_address'],
			CONTACT: ['name', 'title', 'client'],
			ACTIVITY: ['date', 'client', 'description', 'contacts', 'users', 'activityType'],
			APPOINTMENT: ['date', 'client', 'description', 'contact', 'users', 'activityType'],
			OPPORTUNITY: ['date', 'client', 'description', 'value', 'stage'],
			ORDER: ['date', 'client', 'description', 'value', 'stage']
		};

		// Addresses
		AdvancedSearch.availableAddresses = {
			Visit: 'Visit',
			Invoice: 'Invoice',
			Mail: 'Mail',
			Delivery: 'Delivery',
			Other: 'Other'
		};

		// Some models that are not linked directly to a filter
		AdvancedSearch.models = {
			ACCOUNT: {
				showSalesFilters: false,
				showActivityFilters: false,
				showOpportunityFilters: false,
				showMarketMailHistory: false,
				showMarketVisitHistory: false,
				showMarketFormHistory: false
			},
			CONTACT: {},
			ACTIVITY: {},
			APPOINTMENT: {},
			OPPORTUNITY: {},
			ORDER: {}
		};

		AdvancedSearch.multiActions = {
			ACCOUNT: {},
			CONTACT: {},
			ACTIVITY: {},
			APPOINTMENT: {},
			OPPORTUNITY: {},
			ORDER: {}
		};

		AdvancedSearch.showResults = function () {
			AdvancedSearch.expanded = false;
			$location.search('results', '1');
			$location.replace();
		};

		AdvancedSearch.showSearch = function () {
			AdvancedSearch.expanded = true;
			$location.search('results', null);
			$location.replace();
		};

		AdvancedSearch.search = function () {
			setColumnsFromFilters();
			//resetPaginator();
			AdvancedSearch.showResults();
			AdvancedSearch.getResult();
		};

		AdvancedSearch.updateMultiselect = function () {
			var total = AdvancedSearch.rootData.numResults[AdvancedSearch.rootData.searchView.resultsAs];
			var visible = Array.isArray(AdvancedSearch.results) ? AdvancedSearch.results.length : 0;

			$multiSelect.setTotal(total);
			$multiSelect.setVisible(visible);
		};

		AdvancedSearch.changeViewStart = function (toEntity) {
			if (toEntity === AdvancedSearch.rootData.searchView.type) {
				return;
			}

			AdvancedSearch.changeViewTo = toEntity;

			const viewName = toEntity.toLowerCase();
			const filterName = viewName.charAt(0).toUpperCase() + viewName.slice(1);
			const viewType = `advancedSearch${filterName}`;
			AdvancedSearch.nextSearchView = findViewByIdentity(viewType);

			AdvancedSearch.rootData.viewLoading = true;

			$state.go('advancedSearch.' + toEntity.toLowerCase());
		};

		AdvancedSearch.isCurrentPage = entity => {
			return AdvancedSearch.changeViewTo
				? AdvancedSearch.changeViewTo === entity
				: AdvancedSearch.rootData.searchView && AdvancedSearch.rootData.searchView.type === entity
				? true
				: false;
		};

		function waitForViewToLoad() {
			// Try to do the timeout dynamically instead of using a fixed value
			if (performance && performance.now) {
				const measureLag = t0 => {
					setTimeout(() => {
						const t1 = performance.now();
						if (t1 - t0 > 500) {
							measureLag(t1);
						} else {
							mainContent.classList.remove('loading-view');
							AdvancedSearch.rootData.viewLoading = false;
							$safeDigest($scope);
						}
					}, 50);
				};
				measureLag(performance.now());
			} else {
				$timeout(function () {
					mainContent.classList.remove('loading-view');
					AdvancedSearch.rootData.viewLoading = false;
				}, 500);
			}
		}

		AdvancedSearch.changeSearchType = function (entity, filterId) {
			if (AdvancedSearch.rootData.searchView && entity === AdvancedSearch.rootData.searchView.type) {
				return;
			}

			AdvancedSearch.nextSearchView = null;
			//The timeout is needed so that the class is set before the subview is changed
			AdvancedSearch.rootData.searchView = AdvancedSearch.searchViews[entity];
			AdvancedSearch.rootData.searchView.sorting = AdvancedSearch.searchViews[entity].defaultSorting;
			AdvancedSearch.rootData.searchView.type = entity;
			AdvancedSearch.rootData.searchView.resultsAs = entity;
			AdvancedSearch.listActionData.actions = AdvancedSearch.multiActions[entity];

			// Reset columns here
			//AdvancedSearch.rootData.searchView.columns = AdvancedSearch.columns[entity];

			// Set last used cols
			//lastUsedColumns(entity, AdvancedSearch.rootData.searchView.columns);

			AdvancedSearch.rootData.searchView.columns = lastUsedColumns(entity);

			// Reset filters
			resetFilters();

			AdvancedSearch.selectedView = null;
			const viewName = AdvancedSearch.rootData.searchView.resultsAs.toLowerCase();
			const filterName = viewName.charAt(0).toUpperCase() + viewName.slice(1);
			const viewType = `advancedSearch${filterName}`;
			const selectedView = findViewByIdentity(viewType, { id: Number(filterId || $location.search().id) });

			AdvancedSearch.viewType = viewType;

			selectView(selectedView);

			waitForViewToLoad();
		};

		AdvancedSearch.setResultEntity = function (entity) {
			if (AdvancedSearch.rootData.searchView.resultsAs === entity) {
				return;
			}
			AdvancedSearch.rootData.searchView.sorting = AdvancedSearch.searchViews[entity].defaultSorting;
			AdvancedSearch.rootData.searchView.resultsAs = entity;
			AdvancedSearch.listActionData.actions = AdvancedSearch.multiActions[entity];

			// Use last known columns here
			AdvancedSearch.rootData.searchView.columns = lastUsedColumns(entity);
			$location.search('as', entity);
		};

		AdvancedSearch.setLimit = function (limit) {
			AdvancedSearch.limit = limit;
			resetPaginator();
			AdvancedSearch.getResult();
		};

		AdvancedSearch.pageChange = function () {
			AdvancedSearch.getResult();
		};

		AdvancedSearch.filterChange = function () {};

		AdvancedSearch.getDry = function () {
			if (!AdvancedSearch.loaded) {
				return;
			}

			if (getDryTimeout) {
				$timeout.cancel(getDryTimeout);
				getDryTimeout = null;
			}

			getDryTimeout = $timeout(function () {
				AdvancedSearch.rootData.loading = true;

				var gets = {};
				angular.forEach(AdvancedSearch.rootData.searchView.resultTypes, function (type) {
					var filters = getFilters(AdvancedSearch.rootData.searchView.type, type);
					filters.limit = 0;
					filters.fields = ['id'];

					// This should not be needed if this function only is used to get the total number of entities that matches the current query.
					// if(AdvancedSearch.rootData.searchView.sorting && AdvancedSearch.rootData.searchView.sorting.length) {
					// 	var sort = FilterHelper.parseSort(AdvancedSearch.rootData.searchView.sorting, type.toLowerCase());
					// 	filters.addSort(sort[0].attribute, sort[0].ascending);
					// }

					if (
						type === AdvancedSearch.entity.ACCOUNT &&
						AdvancedSearch.rootData.searchView.type !== AdvancedSearch.entity.ACCOUNT
					) {
						filters.addExtraParam('groupChilds', true);
					}

					gets[type] = AdvancedSearch.resources[type].customer(customerId).find(filters.build());
				});

				$q.all(gets)
					.then(function (res) {
						angular.forEach(AdvancedSearch.rootData.searchView.resultTypes, function (type) {
							AdvancedSearch.rootData.numResults[type] =
								res[type] && res[type].metadata ? res[type].metadata.total : 0;
						});

						AdvancedSearch.updateMultiselect();
					})
					.catch(function () {})
					.finally(function () {
						AdvancedSearch.rootData.loading = false;
					});
			}, GET_TIMEOUT);
		};

		AdvancedSearch.hasRemoveFrameFlag = function () {
			return FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME');
		};

		AdvancedSearch.getResult = function () {
			if (AdvancedSearch.expanded) {
				return;
			}
			AdvancedSearch.results = [];
			AdvancedSearch.rootData.loading = true;

			if (getTimeout) {
				$timeout.cancel(getTimeout);
				getTimeout = null;
			}

			getTimeout = $timeout(function () {
				var get;
				var filter = getFilters(
					AdvancedSearch.rootData.searchView.type,
					AdvancedSearch.rootData.searchView.resultsAs
				);
				switch (AdvancedSearch.rootData.searchView.resultsAs) {
					case AdvancedSearch.entity.ACCOUNT:
						get = Account.customer(customerId);
						break;
					case AdvancedSearch.entity.CONTACT:
						get = Contact.customer(customerId);
						break;
					case AdvancedSearch.entity.ACTIVITY:
						get = Activity.customer(customerId);
						break;
					case AdvancedSearch.entity.APPOINTMENT:
						get = Appointment.customer(customerId);
						break;
					case AdvancedSearch.entity.OPPORTUNITY:
						get = Opportunity.customer(customerId);
						break;
					case AdvancedSearch.entity.ORDER:
						get = Order.customer(customerId);
						break;
				}

				filter.limit = AdvancedSearch.limit;
				filter.offset = AdvancedSearch.offset;

				if (AdvancedSearch.rootData.searchView.sorting && AdvancedSearch.rootData.searchView.sorting.length) {
					var sort = FilterHelper.parseSort(
						AdvancedSearch.rootData.searchView.sorting,
						AdvancedSearch.rootData.searchView.resultsAs.toLowerCase()
					);
					filter.addSort(sort[0].attribute, sort[0].ascending);
				}

				if (
					AdvancedSearch.rootData.searchView.resultsAs === AdvancedSearch.entity.ACCOUNT &&
					AdvancedSearch.rootData.searchView.type !== AdvancedSearch.entity.ACCOUNT
				) {
					filter.addExtraParam('groupChilds', true);
				}

				// eslint-disable-next-line promise/catch-or-return
				get.find(filter.build(), { mapCustom: true }).then(function (res) {
					AdvancedSearch.results = res.data;
					AdvancedSearch.rootData.numResults[AdvancedSearch.rootData.searchView.resultsAs] =
						res.metadata.total;

					AdvancedSearch.updateMultiselect();
					AdvancedSearch.rootData.loading = false;
				});
			}, GET_TIMEOUT);
		};

		AdvancedSearch.onSort = function () {
			resetPaginator();
			$multiSelect.selectNone();
			AdvancedSearch.getResult();
		};

		AdvancedSearch.editColumns = function (tableType) {
			var selectables = [];
			var categoryTypes = AppService.getCategoryTypes(tableType);
			var cased = tableType.toUpperCase();

			angular.forEach(attrs[AdvancedSearch.rootData.searchView.resultsAs].attr, function (attr, key) {
				if (attr.selectableColumn) {
					attr.key = key;
					selectables.push(attr);
				}
			});

			// Add customCategory types to selection
			if (categoryTypes.length) {
				angular.forEach(categoryTypes, function (cat) {
					selectables.push({
						field: 'Category_' + cat.id,
						key: 'Category_' + cat.id,
						title: cat.name
					});
				});
			}

			if (attrs[cased].extraSelectables) {
				angular.forEach(attrs[cased].extraSelectables, function (sel) {
					selectables.push({
						field: sel.key,
						key: sel.key,
						title: sel.title
					});
				});
			}

			selectables = _.uniq(selectables, 'field');

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('EditColumns', {
					columns: AdvancedSearch.rootData.searchView.columns,
					selectables: selectables,
					customfields: AdvancedSearch.customFields[AdvancedSearch.rootData.searchView.resultsAs],
					tableType: tableType
				})
				.then(function (columns) {
					AdvancedSearch.rootData.searchView.columns = columns;
					lastUsedColumns(AdvancedSearch.rootData.searchView.resultsAs, columns);
				});
		};

		AdvancedSearch.resetFilters = function () {
			resetFilters();
		};

		AdvancedSearch.searchOnEnter = function (e) {
			if (e.keyCode === 13 && AdvancedSearch.expanded) {
				AdvancedSearch.search();
			}
		};

		AdvancedSearch.planPhoneCalls = () => {
			if (['ACCOUNT', 'CONTACT'].indexOf(AdvancedSearch.rootData.searchView.type) === -1) {
				return;
			}
			AdvancedSearch.runMultiAction(
				AdvancedSearch.multiActions[AdvancedSearch.rootData.searchView.resultsAs].planPhoneCalls
			);
		};

		AdvancedSearch.runMultiAction = function (action) {
			const selectedIds = AdvancedSearch.$multiSelect.selected;
			var rb = getFilters(AdvancedSearch.rootData.searchView.type, AdvancedSearch.rootData.searchView.resultsAs);

			var type = AdvancedSearch.rootData.searchView.resultsAs;
			if (
				type === AdvancedSearch.entity.ACCOUNT &&
				AdvancedSearch.rootData.searchView.type !== AdvancedSearch.entity.ACCOUNT
			) {
				rb.addExtraParam('groupChilds', true);
			}

			return action.run(
				{
					total: AdvancedSearch.rootData.numResults[AdvancedSearch.rootData.searchView.resultsAs],
					removeSelected: function () {
						AdvancedSearch.results = _.filter(AdvancedSearch.results, function (r) {
							return !_.includes(selectedIds, r.id);
						});
					}
				},
				rb.build(),
				rb,
				AdvancedSearch.rootData.searchView.columns
			);
		};

		AdvancedSearch.resetPaginator = function () {
			resetPaginator();
		};

		var onHashChange = function (location, oldLocation) {
			if (_.isEqual(_.omit(location.hash, 'p'), _.omit(oldLocation.hash, 'p')) === false) {
				$multiSelect.selectNone();
				AdvancedSearch.selected = 0;
			}

			// Set filters
			angular.forEach(AdvancedSearch.rootData.searchView.resultTypes, function (type) {
				if (location.hash[type + '-Q']) {
					var filters = FilterHelper.parseString(location.hash[type + '-Q'], type.toLowerCase(), {
						getConfig: getFilterConfig
					});
					angular.forEach(filters, function (filter, name) {
						AdvancedSearch.filters[type][name] = filter;
					});
				}
			});

			/*
				When you press one of the tabs AdvancedSearch.changeViewStart will run and call state.go with the target state
				This will change the url to advanced-search/:type and trigger onHashChange in AdvancedSearch.js
				The new ui-router-state will load the controller AdvancedSearch[Type] and tempate views/advancedSearch[Type].html
				that in turn will run AdvancedSearch.changeSearchType that ultimatly runs resetFilters and updates AdvancedSearch.Filters
				That in turn triggers the watch on AdvancedSearch.Filters that updates the hash in the url.
				This will again trigger onHashChange.
				Thats why we skip the first trigger of onHashChange when we dont have the hash in the url.
			*/

			if (Object.keys(location.hash).length) {
				if (location.hash.results) {
					// Show results
					AdvancedSearch.showResults();

					// Do search
					AdvancedSearch.search();
				} else {
					// Show filters
					AdvancedSearch.showSearch();

					// Do drySearch
					AdvancedSearch.getDry();
				}

				if (location.hash.as) {
					AdvancedSearch.setResultEntity(location.hash.as);
				}
			}
		};

		var filterCustomFields = function (cf) {
			return cf.visible && cf.$hasAccess;
		};

		var init = function () {
			AdvancedSearch.listActionData = {
				selected: 0,
				actions: AdvancedSearch.multiActions.ACCOUNT,
				runAction: AdvancedSearch.runMultiAction,
				allSelected: false,
				selectNone: function () {
					$multiSelect.selectNone();
				}
			};

			AdvancedSearch.hasRemoveActivities =
				FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				FeatureHelper.hasSoftDeployAccess('TODO_LIST');

			// Get customerId
			$multiSelect.selectNone();
			customerId = AppService.getCustomerId();

			// eslint-disable-next-line promise/catch-or-return
			UserTreeFilterMeta().then(function (res) {
				AdvancedSearch.userTree = res.data;
			});

			// Get categoryTypes
			AdvancedSearch.categoryTypes = {
				ACCOUNT: AppService.getCategoryTypes('account'),
				CONTACT: AppService.getCategoryTypes('contact')
			};

			// Listen for hash change
			$scope.$watch(
				function () {
					return {
						hash: $location.search(),
						path: $location.$$path
					};
				},
				function (location, oldLocation) {
					onHashChange(location, oldLocation);
				},
				true
			);

			$scope.$watch(
				function () {
					return AdvancedSearch.filters;
				},
				function () {
					if (filterTimeout) {
						$timeout.cancel(filterTimeout);
						filterTimeout = null;
					}
					filterTimeout = $timeout(function () {
						if ($location.url().indexOf('/advanced-search/') === -1) {
							return;
						}

						// Set new hash
						angular.forEach(AdvancedSearch.rootData.searchView.resultTypes, function (type) {
							var q = FilterHelper.convertForURL(AdvancedSearch.filters[type], type.toLowerCase(), {
								skipInactive: true,
								getConfig: getFilterConfig
							});
							if (!q.length) {
								$location.search(type + '-Q', null);
							} else {
								$location.search(type + '-Q', q);
							}

							if (type === AdvancedSearch.entity.ORDER || type === AdvancedSearch.entity.OPPORTUNITY) {
								q = FilterHelper.convertForURL(AdvancedSearch.filters.ORDERROW, 'orderrow', {
									skipInactive: true,
									getConfig: getFilterConfig
								});
								if (!q.length) {
									$location.search(AdvancedSearch.entity.ORDERROW + '-Q', null);
								} else {
									$location.search(AdvancedSearch.entity.ORDERROW + '-Q', q);
								}
							}
						});

						if (AdvancedSearch.selectedView) {
							$location.search('id', AdvancedSearch.selectedView.id);
						}

						// Replace the prev hash to not get a new history post for each filter change
						$location.replace();
					}, 200);
				},
				true
			);

			// Get customFields
			AdvancedSearch.customFields.ACCOUNT = _.filter(AppService.getCustomFields('account'), filterCustomFields);
			AdvancedSearch.customFields.CONTACT = _.filter(AppService.getCustomFields('contact'), filterCustomFields);
			AdvancedSearch.customFields.ACTIVITY = _.filter(AppService.getCustomFields('activity'), filterCustomFields);
			AdvancedSearch.customFields.APPOINTMENT = _.filter(
				AppService.getCustomFields('appointment'),
				filterCustomFields
			);
			AdvancedSearch.customFields.OPPORTUNITY = _.filter(
				AppService.getCustomFields('opportunity'),
				filterCustomFields
			);
			AdvancedSearch.customFields.ORDER = _.filter(AppService.getCustomFields('order'), filterCustomFields);
			AdvancedSearch.customFields.ORDERROW = _.filter(AppService.getCustomFields('orderrow'), filterCustomFields);

			initFilterConfigs();

			// Reset(set) all filters
			resetFilters();

			// Set multiActions that require language to be loaded
			AdvancedSearch.multiActions.ACCOUNT = MultiRunnerActions.get(MultiRunnerActions.type.ACCOUNT);
			// These segments multiactions does not work properly from advanced search
			AdvancedSearch.multiActions.CONTACT = _.omit(MultiRunnerActions.get(MultiRunnerActions.type.CONTACT), [
				'sendGroupMail',
				'addContactsToSegment'
			]);
			AdvancedSearch.multiActions.ACTIVITY = MultiRunnerActions.get(MultiRunnerActions.type.ACTIVITY);
			AdvancedSearch.multiActions.APPOINTMENT = MultiRunnerActions.get(MultiRunnerActions.type.APPOINTMENT);
			AdvancedSearch.multiActions.OPPORTUNITY = MultiRunnerActions.get(MultiRunnerActions.type.OPPORTUNITY);
			AdvancedSearch.multiActions.ORDER = MultiRunnerActions.get(MultiRunnerActions.type.ORDER);
		};

		function findViewByIdentity(viewType, identity) {
			let tmpView;
			const availableViews = AppService.getListViews(viewType);

			if (identity) {
				tmpView = _.find(availableViews, identity);
			}

			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true, private: true });
			}
			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true, editable: true });
			}
			if (!tmpView) {
				tmpView = _.find(availableViews, { default: true });
			}
			if (!tmpView) {
				tmpView = availableViews[0];
			}

			return _.cloneDeep(tmpView);
		}

		function selectView(view) {
			const selectedView = _.cloneDeep(view);

			if (selectedView.filters.length) {
				const [firstFilter] = selectedView.filters;

				if (firstFilter) {
					if (firstFilter.value && firstFilter.filterName === 'advancedSearch') {
						const filters = firstFilter.value;

						angular.forEach(AdvancedSearch.rootData.searchView.resultTypes, type => {
							Object.values(filters[type]).forEach(filter => {
								if (filter.dataType === 'dateRange') {
									if (filter.value.start) {
										filter.value.start = new Date(filter.value.start);
									}
									if (filter.value.end) {
										filter.value.end = new Date(filter.value.end);
									}
								}
							});

							const customFields = AdvancedSearch.customFields[type] || [];
							const customFieldsMap = customFields.reduce((fields, field) => {
								fields[field.id] = field;
								return fields;
							}, {});

							const filteredFilters = Object.entries(filters[type]).reduce((filtered, [name, filter]) => {
								if (name?.startsWith('Custom')) {
									// Filter is for a custom field
									const id = filter?.field;
									// Make sure custom field still exists (could be removed)
									if (id && customFieldsMap[id]) {
										filtered[name] = filter;
									}
								} else {
									filtered[name] = filter;
								}
								return filtered;
							}, {});

							AdvancedSearch.filters[type] = { ...AdvancedSearch.filters[type], ...filteredFilters };
						});
					} else {
						// Old way of storing filters
						const filters = selectedView.filters.reduce((accumulator, currentValue) => {
							const filter = _.pick(currentValue, [
								'columnPath',
								'comparisonType',
								'dataType',
								'field',
								'filterName',
								'value'
							]);

							if (!isNaN(+filter.field)) {
								filter.field = +filter.field;
							}

							if (filter.dataType === 'dateRange') {
								if (filter.value.start) {
									filter.value.start = new Date(filter.value.start);
								}
								if (filter.value.end) {
									filter.value.end = new Date(filter.value.end);
								}
							}

							return { ...accumulator, [currentValue.filterName]: filter };
						}, {});

						AdvancedSearch.filters[AdvancedSearch.rootData.searchView.resultsAs] = _.cloneDeep(filters);
						selectedView.filters = [
							{
								filterName: 'advancedSearch',
								dataType: 'raw',
								value: _.cloneDeep(AdvancedSearch.filters)
							}
						];
					}
				}
			}

			AdvancedSearch.selectedView = selectedView;
			AdvancedSearch.rootData.searchView.columns = _.cloneDeep(selectedView.columns);
			AdvancedSearch.rootData.searchView.sorting = _.cloneDeep(selectedView.sorting);
		}

		AdvancedSearch.onViewChange = selectedView => {
			selectView(selectedView);

			if (!selectedView.filters.length) {
				// Reset(set) all filters
				resetFilters(selectedView.default);
			}

			$safeApply($scope);
		};

		function getFilterConfig(name, type) {
			const config = filterConfigs[type][name];

			if (config) {
				return config;
			} else {
				const configName = FilterHelper.getConfigName(name);
				return FilterHelper.getConfig(configName, type);
			}
		}

		function initFilterConfigs() {
			angular.forEach(filterNames, function (filters, name) {
				var entity = name.toLowerCase();
				filterConfigs[entity] = {};
				angular.forEach(filters, function (filterName) {
					var config = FilterHelper.getConfig(filterName, entity);
					if (config) {
						filterConfigs[entity][filterName] = config;
					}
				});

				angular.forEach(AdvancedSearch.customFields[name], function (field) {
					var filterName = 'Custom_' + field.id;
					filterConfigs[entity][filterName] = FilterHelper.getConfig(filterName, entity);
				});
			});

			angular.forEach(AdvancedSearch.categoryTypes, function (types, name) {
				var entity = name.toLowerCase();
				angular.forEach(types, function (type) {
					filterConfigs[entity]['Category_' + type.id] = FilterHelper.getConfig(
						'Category_' + type.id,
						entity
					);
				});
			});
		}

		function resetFilters(isDefault = false) {
			var newFilters = {};

			// Setup standard filters for all entities
			angular.forEach(filterNames, function (filters, name) {
				var entity = name.toLowerCase();
				newFilters[name] = {};
				angular.forEach(filters, function (filterName) {
					var foundFilter = FilterHelper.filter(filterName, entity, { getConfig: getFilterConfig });
					if (foundFilter) {
						newFilters[name][filterName] = foundFilter;
					}
				});

				angular.forEach(AdvancedSearch.customFields[name], function (field) {
					var filterName = 'Custom_' + field.id;
					newFilters[name][filterName] = FilterHelper.filter(filterName, entity, {
						getConfig: getFilterConfig
					});
				});
			});

			// Setup models for categoryTypes
			angular.forEach(AdvancedSearch.categoryTypes, function (types, name) {
				var entity = name.toLowerCase();
				angular.forEach(types, function (type) {
					newFilters[name]['Category_' + type.id] = FilterHelper.filter('Category_' + type.id, entity, {
						getConfig: getFilterConfig
					});
				});
			});

			// Default active filter to true
			newFilters.CONTACT.Active.inactive = false;
			newFilters.CONTACT.Active.value = true;
			newFilters.CONTACT.Unsubscribed.inactive = true;
			newFilters.CONTACT.Unsubscribed.value = null;
			newFilters.CONTACT.MailBounced.inactive = true;

			if (FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') && newFilters.ACCOUNT.SubAccounts) {
				newFilters.ACCOUNT.SubAccounts.inactive = true;
				newFilters.ACCOUNT.SubAccounts.value = null;
			}

			newFilters.ORDER.IsOpportunity.value = false;

			newFilters.OPPORTUNITY.IsOpportunity.value = true;

			// Only enable this on accunt page by default as adding a parent/child filter makes the query take 20 times longer time
			if (
				AdvancedSearch.isCurrentPage(AdvancedSearch.entity.ACCOUNT) &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES)
			) {
				newFilters.ACCOUNT.Active.inactive = false;
				newFilters.ACCOUNT.Active.value = true;
			} else {
				newFilters.ACCOUNT.Active.inactive = true;
				newFilters.ACCOUNT.Active.value = false;
			}
			//if current page is tasks/activities and has remove activities set

			if (isDefault) {
				newFilters.ACCOUNT.RegDate.value.preset = 'custom';
			}

			AdvancedSearch.filters = newFilters;
		}

		function resetPaginator() {
			AdvancedSearch.currentPage = 1;
			AdvancedSearch.offset = 0;
			$location.search('p', null);
		}

		var getFixedAccountFilters = function (filters) {
			var filtersToParse = angular.copy(filters);

			// Fix order products value
			if (filtersToParse.OrderFilters.value.OrderRowProduct.value.length) {
				// Remove product categories and save to variable
				var categoriesOrder = _.remove(filtersToParse.OrderFilters.value.OrderRowProduct.value, function (obj) {
					return obj.isCategory;
				});

				if (filtersToParse.OrderFilters.value.OrderRowProduct.value.length) {
					filtersToParse.OrderFilters.value.OrderRowProduct.value = _.pluck(
						filtersToParse.OrderFilters.value.OrderRowProduct.value,
						'id'
					);
				}

				if (categoriesOrder.length) {
					filtersToParse.OrderFilters.value.OrderRowProductCategory.value = _.pluck(categoriesOrder, 'id');
				}
			}

			// Fix opportunity products value
			if (filtersToParse.OpportunityFilters.value.OrderRowProduct.value.length) {
				// Remove product categories and save to variable
				var categoriesOpportunity = _.remove(
					filtersToParse.OpportunityFilters.value.OrderRowProduct.value,
					function (obj) {
						return obj.isCategory;
					}
				);

				if (filtersToParse.OpportunityFilters.value.OrderRowProduct.value.length) {
					filtersToParse.OpportunityFilters.value.OrderRowProduct.value = _.pluck(
						filtersToParse.OpportunityFilters.value.OrderRowProduct.value,
						'id'
					);
				}

				if (categoriesOpportunity.length) {
					filtersToParse.OpportunityFilters.value.OrderRowProductCategory.value = _.pluck(
						categoriesOpportunity,
						'id'
					);
				}
			}

			return filtersToParse;
		};

		var copyFilters = function (filters) {
			return angular.copy(filters);
		};

		var findUsers = function (array, roleIds, selectAllChilds, output) {
			array.forEach(function (obj) {
				if (obj.isRole && obj.children.length && (_.contains(roleIds, obj.$id) || selectAllChilds)) {
					findUsers(obj.children, roleIds, true, output);
				} else if (obj.isRole && obj.children.length) {
					findUsers(obj.children, roleIds, false, output);
				} else if (!obj.isRole && selectAllChilds) {
					output.push(obj.id);
				}
			});
		};

		var fixUserFilters = function (filters) {
			if (filters.User && filters.User.value.length) {
				var roleIds = filters.User.value
					.filter(function (id) {
						return typeof id === 'string';
					})
					.map(function (id) {
						return parseInt(id.split('-')[1]);
					});

				filters.User.value = filters.User.value.filter(function (id) {
					return typeof id !== 'string';
				});

				var userIds = [];

				findUsers(AdvancedSearch.userTree, roleIds, false, userIds);

				filters.User.value = filters.User.value.concat(userIds);
			}

			return filters;
		};

		var fixTitleFilter = function (filters) {
			if (filters.Title) {
				filters.Title.comparisonType = 'MatchExact';
				filters.Title.forceType = 'list';
			}

			return filters;
		};

		var fixAccountUserFilters = function (filters) {
			if (filters.AccountManagerFilters.value.User && filters.AccountManagerFilters.value.User.value.length) {
				var roleIds = filters.AccountManagerFilters.value.User.value
					.filter(function (id) {
						return typeof id === 'string';
					})
					.map(function (id) {
						return parseInt(id.split('-')[1]);
					});

				const selfIndex = filters.AccountManagerFilters.value.User.value.indexOf('self');

				if (selfIndex > -1) {
					filters.AccountManagerFilters.value.User.value[selfIndex] = Tools.AppService.getSelf().id;
				}

				filters.AccountManagerFilters.value.User.value = filters.AccountManagerFilters.value.User.value.filter(
					function (id) {
						return typeof id !== 'string';
					}
				);

				var userIds = [];

				findUsers(AdvancedSearch.userTree, roleIds, false, userIds);

				filters.AccountManagerFilters.value.User.value =
					filters.AccountManagerFilters.value.User.value.concat(userIds);
			}

			return filters;
		};

		var getFixedOrderFilters = function (filters) {
			var filtersToParse = angular.copy(filters);

			// Fix order products value
			if (filtersToParse.OrderRowProduct.value && filtersToParse.OrderRowProduct.value.length) {
				// Remove product categories and save to variable
				var categoriesOrder = _.remove(filtersToParse.OrderRowProduct.value, function (obj) {
					return obj.isCategory;
				});

				if (filtersToParse.OrderRowProduct.value.length) {
					filtersToParse.OrderRowProduct.value = _.pluck(filtersToParse.OrderRowProduct.value, 'id');
				}

				if (categoriesOrder.length) {
					categoriesOrder.forEach(function (productCategory) {
						var productsInCategory = _.filter(AppService.getProducts(null, true), function (product) {
							return product.category && product.category.id === productCategory.id;
						});
						filtersToParse.OrderRowProduct.value = filtersToParse.OrderRowProduct.value.concat(
							_.pluck(productsInCategory, 'id')
						);
					});
				}
			}

			return filtersToParse;
		};

		function getFilters(entity, resultsAs) {
			var filters;
			var fieldPrefix = null;
			var filtersToParse;
			var orderrowPrefix;
			var orderrowFilters;

			switch (entity) {
				case AdvancedSearch.entity.ACCOUNT:
					if (entity !== resultsAs) {
						fieldPrefix = 'client';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					filtersToParse = fixAccountUserFilters(filtersToParse);

					// Parse dem filters
					filters = FilterHelper.parseFilters(filtersToParse, 'account', null, fieldPrefix);

					break;

				case AdvancedSearch.entity.CONTACT:
					if (entity !== resultsAs) {
						fieldPrefix = 'contact';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = copyFilters(AdvancedSearch.filters.CONTACT);
					filtersToParse = fixUserFilters(filtersToParse);
					filtersToParse = fixTitleFilter(filtersToParse);

					if (filtersToParse.Id && filtersToParse.Id.value) {
						filtersToParse.Id.value = [filtersToParse.Id.value];
					}

					// Parse contact filters
					filters = FilterHelper.parseFilters(filtersToParse, 'contact', null, fieldPrefix, {
						getConfig: getFilterConfig
					});

					var accountFilters = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					accountFilters = fixAccountUserFilters(accountFilters);

					// Parse account filters
					var clientPrefix = null;
					if (resultsAs !== AdvancedSearch.entity.ACCOUNT) {
						clientPrefix = 'client';
					}
					filters = FilterHelper.parseFilters(accountFilters, 'account', filters, clientPrefix, {
						getConfig: getFilterConfig
					});

					break;

				case AdvancedSearch.entity.ACTIVITY:
					if (entity !== resultsAs) {
						fieldPrefix = 'activity';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = copyFilters(AdvancedSearch.filters.ACTIVITY);
					filtersToParse = fixUserFilters(filtersToParse);

					// Parse contact filters
					filters = FilterHelper.parseFilters(filtersToParse, 'activity', null, fieldPrefix, {
						getConfig: getFilterConfig
					});

					accountFilters = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					accountFilters = fixAccountUserFilters(accountFilters);

					// Parse account filters
					clientPrefix = null;
					if (resultsAs !== AdvancedSearch.entity.ACCOUNT) {
						clientPrefix = 'client';
					}
					filters = FilterHelper.parseFilters(accountFilters, 'account', filters, clientPrefix, {
						getConfig: getFilterConfig
					});

					break;

				case AdvancedSearch.entity.APPOINTMENT:
					if (entity !== resultsAs) {
						fieldPrefix = 'appointment';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = copyFilters(AdvancedSearch.filters.APPOINTMENT);
					filtersToParse = fixUserFilters(filtersToParse);

					// Parse contact filters
					filters = FilterHelper.parseFilters(filtersToParse, 'appointment', null, fieldPrefix, {
						getConfig: getFilterConfig
					});

					accountFilters = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					accountFilters = fixAccountUserFilters(accountFilters);

					// Parse account filters
					clientPrefix = null;
					if (resultsAs !== AdvancedSearch.entity.ACCOUNT) {
						clientPrefix = 'client';
					}
					filters = FilterHelper.parseFilters(accountFilters, 'account', filters, clientPrefix, {
						getConfig: getFilterConfig
					});

					break;

				case AdvancedSearch.entity.OPPORTUNITY:
					if (entity !== resultsAs) {
						fieldPrefix = 'order';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = getFixedOrderFilters(AdvancedSearch.filters.OPPORTUNITY);
					filtersToParse = fixUserFilters(filtersToParse);

					// Parse opportunity filters
					filters = FilterHelper.parseFilters(filtersToParse, 'opportunity', null, fieldPrefix, {
						getConfig: getFilterConfig
					});

					orderrowPrefix = entity !== resultsAs ? 'order.orderRow' : 'orderRow';
					orderrowFilters = FilterHelper.parseFilters(
						AdvancedSearch.filters.ORDERROW,
						'orderrow',
						null,
						orderrowPrefix,
						{ getConfig: getFilterConfig }
					);

					filters.queryArray = filters.queryArray.concat(orderrowFilters.queryArray);

					accountFilters = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					accountFilters = fixAccountUserFilters(accountFilters);

					// Parse account filters
					clientPrefix = null;
					if (resultsAs !== AdvancedSearch.entity.ACCOUNT) {
						clientPrefix = 'client';
					}
					filters = FilterHelper.parseFilters(accountFilters, 'account', filters, clientPrefix, {
						getConfig: getFilterConfig
					});

					break;

				case AdvancedSearch.entity.ORDER:
					if (entity !== resultsAs) {
						fieldPrefix = 'order';
					}

					// Here we can fix filters before the parsing without messing up the model
					filtersToParse = getFixedOrderFilters(AdvancedSearch.filters.ORDER);
					filtersToParse = fixUserFilters(filtersToParse);

					// Parse opportunity filters
					filters = FilterHelper.parseFilters(filtersToParse, 'order', null, fieldPrefix, {
						getConfig: getFilterConfig
					});

					orderrowPrefix = entity !== resultsAs ? 'order.orderRow' : 'orderRow';
					orderrowFilters = FilterHelper.parseFilters(
						AdvancedSearch.filters.ORDERROW,
						'orderrow',
						null,
						orderrowPrefix,
						{ getConfig: getFilterConfig }
					);

					filters.queryArray = filters.queryArray.concat(orderrowFilters.queryArray);

					accountFilters = getFixedAccountFilters(AdvancedSearch.filters.ACCOUNT);
					accountFilters = fixAccountUserFilters(accountFilters);

					// Parse account filters
					clientPrefix = null;
					if (resultsAs !== AdvancedSearch.entity.ACCOUNT) {
						clientPrefix = 'client';
					}
					filters = FilterHelper.parseFilters(accountFilters, 'account', filters, clientPrefix, {
						getConfig: getFilterConfig
					});

					break;
			}

			return filters;
		}

		AdvancedSearch.getFilters = () =>
			getFilters(AdvancedSearch.rootData.searchView.type, AdvancedSearch.rootData.searchView.resultsAs);

		function setColumnsFromFilters() {
			// Start with standard columns for the entity
			//var columns = _.cloneDeep(AdvancedSearch.columns[AdvancedSearch.rootData.searchView.resultsAs]);

			var columns = lastUsedColumns(AdvancedSearch.rootData.searchView.resultsAs);

			// Add filter columns if we can
			angular.forEach(AdvancedSearch.filters[AdvancedSearch.rootData.searchView.resultsAs], function (filter) {
				var filterConfig = FilterHelper.getConfig(
					filter.filterName,
					AdvancedSearch.rootData.searchView.resultsAs.toLowerCase()
				);
				if (
					filterConfig.columnPath &&
					!FilterHelper.isInactiveValue(filter, AdvancedSearch.rootData.searchView.resultsAs.toLowerCase())
				) {
					columns.push(filterConfig.columnPath);
				}
			});

			// Only one of each please and max 8
			columns = _.unique(columns).slice(0, 8);

			// Set em'
			AdvancedSearch.rootData.searchView.columns = columns;
		}

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

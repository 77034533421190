import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	Tabs,
	Tab,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Loader,
	Input,
	Button,
	Icon,
	Text
} from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';

import t from 'Components/Helpers/translate';

import './StepTable.scss';
import { makeCancelable } from 'Helpers/promise';

const baseClass = 'StepTable';

export default class StepTable extends PureComponent {
	constructor(props) {
		super(props);

		this.hasLoopFlows = Tools.FeatureHelper.hasSoftDeployAccess('LOOP_FLOW');
	}
	state = {
		type: this.props.types[0],
		data: null,
		tabsHidden: false,
		searchStr: '',
		prevOffset: 0,
		isFetching: false,
		tableHeight: 0
	};

	inputRef = createRef();

	handleEscape = e => {
		if (e.keyCode === 27) {
			this.setState({
				tabsHidden: false,
				searchStr: '',
				prevOffset: 0
			});
		}
	};

	renderRows = data => {
		if (!data || !data.length) {
			return (
				<TableRow>
					<TableColumn align="center">
						{!data ? (
							<Loader size="sm" />
						) : (
							<Text color="grey-10" center italic>
								{t(this.props.noResultText)}
							</Text>
						)}
					</TableColumn>
				</TableRow>
			);
		}

		const rows = data.map(this.props.rowRender);

		if (this.state.isFetching) {
			rows.push(
				<TableRow key="loader">
					<TableColumn align="center">
						<Loader size="sm" />
					</TableColumn>
				</TableRow>
			);
		}

		return rows;
	};

	fetchData = callback => {
		this.fetchPromise = makeCancelable(this.props.fetchFn(this.props, this.state));
		this.fetchPromise.promise
			.then(typeof callback === 'function' ? callback : data => this.setState({ data }))
			.catch(error =>
				logError(error, 'ui/app/babel/components/Flow/StepDrawer/Components/StepTable.js - fetchData')
			);
	};

	onChangeTab = type => this.setState({ type, data: null, prevOffset: 0 }, this.fetchData);

	onChangeSearch = e => this.setState({ searchStr: e.target.value });

	onToggleSearch = () => {
		this.setState({
			tabsHidden: !this.state.tabsHidden,
			searchStr: '',
			prevOffset: 0
		});
	};

	fetchMore = () => {
		const { prevOffset, data, isFetching } = this.state;

		if (!isFetching) {
			const offset = prevOffset + 50;
			if (data && data.length === offset) {
				this.setState({ prevOffset: offset, isFetching: true }, () => {
					this.fetchData(d => {
						this.setState({
							data: data.concat(d),
							isFetching: false
						});
					});
				});
			}
		}
	};

	searchTimeout;
	fetchSearchResults = () => {
		this.setState({ data: null });
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({ prevOffset: 0 }, this.fetchData);
		}, 600);
	};

	focusSearch = () => this.inputRef.current._input.focus();

	getElementHeight = target => {
		const selector = '.StepDrawer' + (target ? ` .Drawer__children ${target}` : '');
		const elem = document.querySelector(selector);
		return elem ? elem.clientHeight : 0;
	};

	calculateTableHeight = () => {
		const drawerHeight = this.getElementHeight();
		const drawerHeaderHeight = this.getElementHeight('.DrawerHeader');
		const drawerCardHeight = this.getElementHeight('.Card');
		const drawerTabsHeight = this.getElementHeight('.StepTable > .StepTable__tab-wrapper');
		this.setState({ tableHeight: drawerHeight - (drawerHeaderHeight + drawerCardHeight + drawerTabsHeight) });
	};

	isInViewport = elem => {
		const bounding = elem.getBoundingClientRect();
		return (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
			bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		);
	};

	tableScrollTimeout;
	onTableScroll = () => {
		clearTimeout(this.tableScrollTimeout);
		this.tableScrollTimeout = setTimeout(() => {
			const selector = '.StepTable > .TableWrapper > .Table > tbody > .TableRow:last-child';
			const lastTableRow = document.querySelector(selector);
			if (this.isInViewport(lastTableRow)) {
				this.fetchMore();
			}
		}, 300);
	};

	componentDidUpdate(prevProps, prevState) {
		const { searchStr, tabsHidden } = this.state;

		if (prevState.searchStr !== searchStr && searchStr.length !== 1) {
			this.fetchSearchResults();
		}
		if (this.inputRef.current && !prevState.tabsHidden && tabsHidden) {
			this.focusSearch();
		}
	}

	componentDidMount() {
		this.fetchData();
		this.calculateTableHeight();
		window.addEventListener('resize', this.calculateTableHeight);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.calculateTableHeight);
		this.fetchPromise?.cancel();
	}

	render() {
		const { className, types, stats, flow } = this.props;
		const { tabsHidden, type, data, searchStr, tableHeight } = this.state;
		const tabTypes = [...types];
		if (this.hasLoopFlows && flow.loop) {
			tabTypes.push('hasLoopedStep');
		}

		return (
			<div className={new bem(baseClass, className).mod({ high: tabTypes?.length > 3 }).b()}>
				<div className={new bem(baseClass).elem('tab-wrapper').b()}>
					<div className={new bem(baseClass).elem('tabs').mod({ tabsHidden }).b()}>
						{tabTypes.length ? (
							<Tabs selected={type} onChange={this.onChangeTab} align="left">
								{tabTypes.map(type => (
									<Tab
										key={'type-' + type}
										id={type}
										title={t('flow.step.' + type)}
										subtitle={'' + stats[type]}
									/>
								))}
							</Tabs>
						) : null}
					</div>
					<div className={new bem(baseClass).elem('search').b()}>
						<Input
							ref={this.inputRef}
							onKeyDown={this.handleEscape}
							onChange={this.onChangeSearch}
							value={searchStr}
							placeholder={t('default.searchContact')}
							inline
						/>
						<Button type="link" size="lg" color="grey" onClick={this.onToggleSearch}>
							<Icon name={tabsHidden ? 'times' : 'search'} size="lg" />
						</Button>
					</div>
				</div>
				<div onScroll={this.onTableScroll} className="TableWrapper" style={{ height: tableHeight }}>
					<Table>
						<TableHeader color="white" columns={this.props.header} />
						{this.renderRows(data)}
					</Table>
				</div>
			</div>
		);
	}
}

StepTable.propTypes = {
	rowRender: PropTypes.func.isRequired,
	fetchFn: PropTypes.func.isRequired,
	noResultText: PropTypes.string.isRequired,
	stats: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired,
	className: PropTypes.string,
	header: PropTypes.array,
	types: PropTypes.array
};

StepTable.defaultProps = {
	stats: {},
	types: []
};

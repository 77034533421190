import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
import { getTierFromOrderRow, isTieredTotalOrderRow } from 'App/babel/helpers/order';
import { get } from 'lodash';
import { ProductDescriptionTooltip } from 'App/components/OrderRows/OrderRow/ProductCategorySelect/ProductDescriptionTooltip';
import MaxDiscountInfo from 'App/components/OrderRows/OrderRow/MaxDiscountInfo';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';
import getSpeedTracker from 'App/helpers/speedTracker';

angular.module('upDirectives').directive('upOrderRow', [
	'$translate',
	'$safeApply',
	'$rootScope',
	'FeatureHelper',
	function ($translate, $safeApply, $rootScope, FeatureHelper) {
		// Used to cache the tree for the non search select (less than 500 products)
		var tree = null;
		var categoryTree = null;
		var type = 'product';
		var selectedCategory = null;

		var setTree = function (newTree) {
			tree = newTree;
		};

		var select2Data = [];

		$rootScope.$on('cacheRefresher.productCategories', function () {
			tree = null;
		});

		$rootScope.$on('cacheRefresher.products', function () {
			tree = null;
		});

		// we may login to another account so we need to reset tree
		$rootScope.$on('upsales.logout', function () {
			tree = null;
		});

		var productCategoryMagic = function ($scope, categories) {
			if ($scope.hasProductCategories) {
				var row = $scope.orderRow;
				var categoryId = row.product && row.product.category && row.product.category.id;
				var category = _.find(categories, { id: categoryId });
				$scope.hasCategoryWithFields = category && category.orderRowFields && category.orderRowFields.length;

				row.custom = _.map(row.custom, function (c) {
					var isVisible = $scope.hasCategoryWithFields
						? !!_.find(category.orderRowFields, { id: c.id })
						: true;
					if (!isVisible) {
						$scope.orderRow.$mappedCustom[c.id].value = '';
					}
					c.tempHide = !isVisible;

					return c;
				});

				row.calculatingField = false;
				if ($scope.hasCalculatingOrderValue && category && category.calculatingField) {
					if (category.calculatingField) {
						row.calculatingField = _.find(Tools.AppService.getCustomFields('orderrow'), function (field) {
							if (!FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
								return false;
							}

							if (field.id !== category.calculatingField.id) {
								return false;
							}
							if (!field.formula) {
								return false;
							}
							return window.calculateField.isValidAsValue(field.formula);
						});

						if (row.calculatingField) {
							var price = window.calculateField.calculate(row.calculatingField.formula, $scope.orderRow);
							row.$listPrice = price;
							if (row.$discount && $scope.discountChange) {
								$scope.discountChange();
							} else if ($scope.discountPercentChange) {
								$scope.discountPercentChange();
							}
						}
					}
				}
			}
		};

		return {
			restrict: 'A',
			templateUrl: require('App/upsales/common/directives/templates/upOrderRow.tpl.html?file'),
			replace: true,
			scope: {
				orderRow: '=ngModel',
				rowEditable: '=',
				$productChange: '&productChange',
				$discountPercentChange: '&discountPercentChange',
				$discountChange: '&discountChange',
				$deleteRow: '&deleteRow',
				$copyRow: '&copyRow',
				$quantityChange: '&quantityChange',
				$priceChange: '&priceChange',
				isAgreement: '=',
				currency: '=',
				currencyRate: '=',
				recurringInterval: '=',
				hasCustom: '=',
				saving: '=',
				customFields: '=',
				orderForm: '=',
				order: '&order',
				useDiscount: '=',
				hasContributionMargin: '=',
				decimalsPrice: '=',
				highlightedFields: '=',
				fieldsSyncDir: '=',
				syncedToApp: '=',
				sortUp: '&',
				sortDown: '&',
				fieldsDescription: '=',
				isProductBundle: '=',
				$openEditProductBundle: '&openEditProductBundle',
				isVisibleField: '=',
				isDisabledField: '=',
				saveDisabled: '=',
				onCustomFieldChange: '=',
				cachedProductSearches: '=',
				cacheProductSearch: '='
			},
			controller: [
				'$scope',
				'AppService',
				'$element',
				'ParseFormula',
				function ($scope, AppService, $element, ParseFormula) {
					const ctrl = this;

					ctrl.metadata = AppService.getMetadata();
					ctrl.self = Tools.AppService.getSelf();
					ctrl.categories = AppService.getProductCategories();
					ctrl.customerId = Tools.AppService.getCustomerId();

					const productCustomFields = Tools.AppService.getReadOnlyCustomFields('product');

					$scope.parseFormula = ParseFormula;

					const searchableProducts = _.filter(productCustomFields, { searchable: 1 }).map(cf => cf.id);
					const standardFields = _.reduce(
						ctrl.metadata.standardFields.Product,
						(result, value) => {
							if (value.active && value.type === 'String') {
								result.push(value);
							}
							return result;
						},
						[]
					);

					$scope.hasProductCategories = Tools.FeatureHelper.isAvailable(
						Tools.FeatureHelper.Feature.ROW_CUSTOM_VISIBILITY
					);
					$scope.ProductDescriptionTooltipComponent = ProductDescriptionTooltip;
					$scope.MaxDiscountComponent = MaxDiscountInfo;
					$scope.isAdmin = ctrl.self.administrator;

					$scope.hasCalculatingOrderValue =
						$scope.hasProductCategories &&
						Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CALCULATING_ROW_VALUE);

					$scope.hasOrderRowFieldsDebounce =
						Tools.FeatureHelper.hasSoftDeployAccess('ORDER_ROW_FIELDS_DEBOUNCE');

					const cmWithrrOption = getCMWithRROption(ctrl.metadata);
					const salesModel = ctrl.metadata.params.SalesModel;
					$scope.salesModelOption = cmWithrrOption ?? ctrl.metadata.params.SalesModelOption;

					$scope.hasRecurringInterval =
						(salesModel === 'rr' &&
							$scope.recurringInterval &&
							['arr', 'mrr'].indexOf($scope.salesModelOption) >= 0) ||
						!!cmWithrrOption;

					$scope.numOfDecimalsPrice = ctrl.metadata.params.OrderedProductPriceDecimals || 2;

					productCategoryMagic($scope, ctrl.categories);

					$scope.isDisabledRowField = (field, isDisabled) => {
						var row = $scope.orderRow;
						const fieldName = `orderrow.${field}`;
						const orderRowFieldName = `orderrow@${row.id}.${field}`;
						const orderRowSortFieldName = `orderrow#${row.sortId}.${field}`;
						return $scope.isDisabledField(fieldName, isDisabled, orderRowFieldName, orderRowSortFieldName);
					};

					$scope.isVisibleRowField = (field, isVisible) => {
						var row = $scope.orderRow;
						const fieldName = `orderrow.${field}`;
						const orderRowFieldName = `orderrow@${row.id}.${field}`;
						const orderRowSortFieldName = `orderrow#${row.sortId}.${field}`;
						const isCustomField = field.startsWith('custom.');
						const productCategoryId = row.product?.category?.id;

						if (isCustomField && productCategoryId) {
							const productCategory = ctrl.categories.find(category => {
								return category.id === productCategoryId;
							});

							if (productCategory?.orderRowFields?.length) {
								const customFieldId = parseInt(field.split('.')[1]);
								const visibleByProductCategory = productCategory.orderRowFields.some(
									field => field.id === customFieldId
								);

								const hiddenByProductCategory = !visibleByProductCategory;

								return $scope.isVisibleField(
									fieldName,
									isVisible,
									orderRowFieldName,
									hiddenByProductCategory,
									orderRowSortFieldName
								);
							}
						}

						return $scope.isVisibleField(
							fieldName,
							isVisible,
							orderRowFieldName,
							undefined,
							orderRowSortFieldName
						);
					};

					$scope.getQuantity = row => {
						if ($scope.selectedTier && $scope.selectedTier.tier && $scope.selectedTier.tier.isTotalPrice) {
							return 1;
						}
						return row.quantity || 0;
					};

					$scope.getMonthlyValue = row => {
						const isAgreement = $scope.isAgreement;
						const isRecurring = row.product?.isRecurring;
						if (!isRecurring && !isAgreement) {
							return 0;
						}
						return ((row.price || 0) / $scope.recurringInterval) * $scope.getQuantity(row);
					};

					$scope.getAnnualValue = row => {
						return $scope.getMonthlyValue(row) * 12;
					};

					$scope.getOneOffValue = row => {
						const isAgreement = $scope.isAgreement;
						const isRecurring = row.product?.isRecurring;
						if (isAgreement || isRecurring) {
							return 0;
						}
						return (row.price || 0) * $scope.getQuantity(row);
					};

					ctrl.setSelectedTier = (orderRow = $scope.orderRow) => {
						$scope.selectedTier = getTierFromOrderRow({ ...orderRow, tierQuantity: orderRow.quantity });
					};

					$scope.getRowContributionMargin = () => {
						if (isTieredTotalOrderRow($scope.orderRow)) {
							return (
								($scope.orderRow.price || 0) -
								($scope.orderRow.purchaseCost || 0) * $scope.orderRow.quantity
							);
						}
						return (
							(($scope.orderRow.price || 0) - ($scope.orderRow.purchaseCost || 0)) *
							($scope.orderRow.quantity || 0)
						);
					};

					$scope.getRowContributionMarginPercentage = () => {
						const price = parseFloat($scope.orderRow.price);
						const quantity = $scope.orderRow.quantity || 0;
						if (!price || Math.abs(price) < 1e-10 || !quantity) {
							return 0;
						}

						const purchaseCost = $scope.orderRow.purchaseCost || 0;
						const quantityToCalculateWith = $scope.selectedTier?.tier?.isTotalPrice ? quantity : 1;

						const per = (price - purchaseCost * quantityToCalculateWith) / price;
						return !isNaN(per) ? (per * 100).toFixed(2) : 0;
					};

					// Set initial selected tier
					ctrl.setSelectedTier();

					// Find parents for an array of categories, recusive for the found parents
					var findParents = function (categories, foundParents, root) {
						var newParents = [];
						_.each(foundParents, function (category) {
							// If root
							if (category.parentId === 0) {
								root.push(category);
							} else {
								var parent = _.find(categories, function (cat) {
									return category.parentId === cat.id || category.parentId === cat.$id;
								});

								if (parent) {
									if (!parent.children) {
										newParents.push(parent);
										parent.children = [];
									}
									parent.children.push(category);
								}
							}

							category.$id = category.id;

							delete category.id; // don't want select2 to be able to select a optgroup
						});

						if (newParents.length) {
							findParents(categories, newParents, root);
						}
					};

					// Recusive function for sorting down the tree children on all branches
					var sortChildren = function (children) {
						var itemsWithChildren = [];
						var result = _.chain(children)
							.sortBy('name')
							.sortBy('sortId')
							.filter(function (item) {
								if (item.children && item.children.length) {
									item.children = sortChildren(item.children);
									itemsWithChildren.push(item);
									return false;
								}
								return true;
							})
							.value();

						if (itemsWithChildren.length) {
							result = result.concat(itemsWithChildren);
						}
						return result;
					};

					/**
					 * Converts categories array to a hierarchy based on parentId
					 * while it do so it appends the belonging products into the category
					 * @param  {array} categories [categories to convert]
					 * @return {array}            [a combined hierarchy of categories and products]
					 */
					var buildProductTree = function (resultProducts) {
						var root = [];
						const catCopy = ctrl.categories.map(category => _.clone(category));
						var foundParents = [];

						_.each(resultProducts, function (product) {
							// If root
							if (!product.category || product.category.id === 0) {
								root.push(product);
							} else {
								var parent = _.find(catCopy, { id: product.category.id });

								if (parent) {
									if (!parent.children) {
										foundParents.push(parent);
										parent.children = [];
									}
									parent.children.push(product);
								}
							}
						});

						if (foundParents.length) {
							findParents(catCopy, foundParents, root);
						}

						// Sort it recusive from the root
						root = sortChildren(root);

						return root;
					};

					var rebuildTree = function () {
						var newTree = buildProductTree(AppService.getProducts(true, false, true));
						setTree(newTree);
					};

					ctrl.buildCategoryTree = function (resultCategories) {
						var root = [];
						const catCopy = (resultCategories || ctrl.categories).map(category => _.clone(category));

						_.each(catCopy, function (category) {
							// If root
							if (category.parentId === 0) {
								root.push(category);
							} else {
								var parent = _.find(catCopy, { id: category.parentId });

								if (parent) {
									if (!parent.children) {
										parent.children = [];
									}
									parent.children.push(category);
								}
							}
						});

						return root;
					};

					// Filter active and accessable products
					var products = AppService.getProducts(true, false, true);
					$scope.discountPercentChange = function () {
						if (!$scope.orderRow.$discountPercent || $scope.orderRow.$discountPercent === '') {
							$scope.orderRow.$discountPercent = 0;
						}
						$scope.$discountPercentChange({ $orderRow: $scope.orderRow });
					};

					$scope.onWheel = function (e) {
						e.preventDefault();
					};

					$scope.onFocus = function (e) {
						e.target.addEventListener('wheel', $scope.onWheel, { passive: false });
					};

					$scope.onBlur = function (e) {
						e.target.removeEventListener('wheel', $scope.onWheel, { passive: false });
					};

					$scope.discountChange = function () {
						if (!$scope.orderRow.$discount || $scope.orderRow.$discount === '') {
							$scope.orderRow.$discount = 0;
						}
						$scope.$discountChange({ $orderRow: $scope.orderRow });
					};

					$scope.quantityChange = function () {
						ctrl.setSelectedTier();
						$scope.$quantityChange({ $orderRow: $scope.orderRow });
					};

					$scope.priceChange = function () {
						$scope.$priceChange({ $orderRow: $scope.orderRow });
					};

					$scope.deleteRow = function () {
						$scope.$deleteRow({ $orderRow: $scope.orderRow });
					};

					$scope.copyRow = function () {
						$scope.$copyRow({ $orderRow: $scope.orderRow });
					};

					$scope.openEditProductBundle = function (event) {
						event.stopPropagation();
						$scope.$openEditProductBundle();
					};

					var preventInput = function (e) {
						if (e.keyCode === 27) {
							// ESCAPE
							$scope.abortPurchaseCost();
						}
					};

					$scope.purchaseCost = 0;
					$scope.editingPurchaseCost = false;
					$scope.editPurchaseCost = function () {
						if (!$scope.orderRow.product) {
							return;
						}
						$scope.editingPurchaseCost = true;
						$scope.purchaseCost = $scope.orderRow.purchaseCost || '';
						setTimeout(function () {
							$element.find('.edit-purchase-price-input').on('keydown', preventInput);
						}, 300);
					};

					// I want blur to work the same way as when you press enter i.e. show the error message if invalid
					$scope.blurPurchaseCost = function (e) {
						const form = e.target.parentElement;

						if (form.checkValidity()) {
							$scope.savePurchaseCost(e);
						} else {
							form.reportValidity();
							e.stopPropagation();
							e.preventDefault();
						}
					};

					$scope.savePurchaseCost = function (e) {
						e.stopPropagation();
						var val = $scope.purchaseCost;
						if (val === '' || isNaN(parseFloat(val))) {
							val = 0;
						}
						$scope.orderRow.purchaseCost = parseFloat(val);
						$scope.editingPurchaseCost = false;
					};

					$scope.abortPurchaseCost = function () {
						$scope.editingPurchaseCost = false;
						$element.find('.edit-purchase-price-input').off('keydown', preventInput);
					};

					$scope.rowIndex = $scope.$parent.$index;

					ctrl.searchProducts = function (term, cb) {
						var searchTerm = term.toLowerCase();

						var res = _.filter(products, function (product) {
							var customFields = _.filter(product.custom, function (customField) {
								return searchableProducts.indexOf(customField.fieldId) > -1;
							});

							var cfMatch = _.some(customFields, function (customField) {
								if (typeof customField.value !== 'string') {
									return false;
								}

								return customField.value.toLowerCase().indexOf(searchTerm) !== -1;
							});

							const isMatchStandard = standardFields.some(
								({ field }) => (product[field] || '').toLowerCase().indexOf(searchTerm) !== -1
							);

							return product.name.toLowerCase().indexOf(searchTerm) !== -1 || cfMatch || isMatchStandard;
						}).slice(0, 500);

						// Limit res to 500
						cb({ results: buildProductTree(res) });
					};

					ctrl.searchCategories = function (term, cb) {
						var res = _.filter(ctrl.categories, function (cat) {
							return cat.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
						}).slice(0, 500);

						// Limit res to 500
						cb({ results: res });
					};

					ctrl.getActiveTree = function () {
						if (!tree) {
							rebuildTree();
						}

						return tree;
					};

					var getTreeSize = function (array) {
						return _.reduce(
							array,
							function (sum, productOrCategory) {
								var isCategory = productOrCategory.hasOwnProperty('$id');

								if (isCategory) {
									return sum + getTreeSize(productOrCategory.children);
								} else {
									return sum + 1;
								}
							},
							0
						);
					};

					ctrl.isSearch = function () {
						return AppService.getTotals('products') > 4000 || getTreeSize(ctrl.getActiveTree()) > 2000;
					};

					ctrl.searchProductsAjax = function (term, callback) {
						if (type !== 'product') {
							return ctrl.searchCategories(term, callback);
						}

						var rb = new Tools.RequestBuilder();
						rb.limit = 500;
						rb.addFilter(Tools.Product.attr.active, rb.comparisonTypes.Equals, 1);
						rb.addExtraParam('usePriceLists', true);
						if (Tools.FeatureHelper.hasSoftDeployAccess('PRODIUCT_PATTERN_SEARCH')) {
							var termGroup = rb.groupBuilder();
							term.split(' ').forEach(splitTerm => {
								var orBuilder = termGroup.orBuilder();
								orBuilder.next();
								orBuilder.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, splitTerm);
								orBuilder.next();
								orBuilder.addFilter(Tools.Product.attr.articleNo, rb.comparisonTypes.Search, splitTerm);

								if (searchableProducts.length) {
									orBuilder.next();
									var groupFilter = orBuilder.groupBuilder();
									groupFilter.addFilter(
										{ field: 'custom.fieldId' },
										rb.comparisonTypes.Equals,
										searchableProducts
									);
									groupFilter.addFilter(
										{ field: 'custom.value' },
										rb.comparisonTypes.Search,
										splitTerm
									);
									groupFilter.done();
								}
								orBuilder.done();
							});
							termGroup.done();
						} else {
							var orBuilder = rb.orBuilder();
							orBuilder.next();
							orBuilder.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, term);
							orBuilder.next();
							orBuilder.addFilter(Tools.Product.attr.articleNo, rb.comparisonTypes.Search, term);

							if (searchableProducts.length) {
								orBuilder.next();
								var groupFilter = orBuilder.groupBuilder();
								groupFilter.addFilter(
									{ field: 'custom.fieldId' },
									rb.comparisonTypes.Equals,
									searchableProducts
								);
								groupFilter.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Search, term);
								groupFilter.done();
							}
							orBuilder.done();
						}

						if (selectedCategory) {
							rb.addFilter(Tools.Product.attr.categoryId, rb.comparisonTypes.Equals, selectedCategory);
						}

						Tools.Product.customer(ctrl.customerId)
							.find(rb.build())
							.then(function (res) {
								var data = _.filter(res.data, function (product) {
									return product.$hasAccess;
								});
								callback({ results: data });
							})
							.catch(e => {
								logError(e, 'Failed to fetch products');
								callback({ results: [] });
							});
					};

					$scope.$on('cacheRefresher.products', function () {
						rebuildTree();
					});

					$scope.$on('cacheRefresher.productCategories', function () {
						ctrl.categories = AppService.getProductCategories();
						rebuildTree();
					});

					// Old matcher for category search (maybe laters?)
					// 	matcher: function(term, undef, obj) {
					// 		return obj.name.toUpperCase().indexOf(term.toUpperCase()) !== -1 && obj.id;
					// 	}
				}
			],
			link: function ($scope, rowElement, $attrs, ctrl) {
				var parentDiv = rowElement.parents('.order-rows');
				var priceInput = rowElement.find('.price-input');
				var fixElement = rowElement.find('.multiply-fix');
				var quantity = rowElement.find('.quantity');
				var discountInput = rowElement.find('.discount-input');
				var discountPercentInput = rowElement.find('.discount-percent-input');
				var fakeProductWrapper = rowElement.find('.product');
				var fakeProduct = fakeProductWrapper.find('.fake-product-select');
				var productSelectWrapper = parentDiv.find('.real-product-select-wrapper');
				var productSelect = productSelectWrapper.find('input.real-product-select');
				var modal = parentDiv.parents('.up-modal');
				var catBtn;
				var prodBtn;
				var togglesWrap;
				const productStandardFields = ctrl.metadata.standardFields.Product;
				const isActiveArticleNo =
					get(productStandardFields, 'ArticleNo.active', false) &&
					FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
				$scope.hasProductNoField = isActiveArticleNo;
				const hasProductBundles =
					FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_BUNDLES);

				var getToggleBtn = function () {
					var btn = angular.element('<button type="button"></button>');
					btn.addClass('btn btn-sm up-btn btn-green btn-lined no-shadow');
					btn.css('width', '50%');
					return btn;
				};

				// Disable or allow changing discount on order
				const selfRoleId = Tools.AppService.getSelf().role?.id || null;
				const myRole = Tools.AppService.getRoles().find(role => role.id === selfRoleId);
				$scope.hasDiscount =
					!myRole || myRole?.hasDiscount || !FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT');

				var onProductChange = function (e) {
					// Set product model value
					$scope.orderRow.product = e.added;

					productCategoryMagic($scope, ctrl.categories);

					$scope.$digest();

					// Reset vaue of select2
					productSelect.select2('val', null);

					$scope.$productChange({ $orderRow: $scope.orderRow });

					ctrl.setSelectedTier($scope.orderRow);

					$safeApply($scope);
				};

				var onProductClose = function () {
					productSelectWrapper.hide();

					setTimeout(function () {
						productSelect.off('change', onProductChange);
						productSelect.select2('val', null);
					});

					// Set focus to fake element
					fakeProduct.focus();
				};

				var setSelect2Data = function (data) {
					select2Data = data;
					productSelect.select2('search', '');
				};

				var showProductSelect = function () {
					if (!$scope.rowEditable || $scope.isDisabledRowField('product')) {
						return;
					}
					// Set the value of the product on the select2 before we open
					if ($scope.orderRow.product) {
						productSelect.select2('data', $scope.orderRow.product);
					}

					// Set new position, show and open the select2
					productSelectWrapper.appendTo(fakeProductWrapper).show();
					productSelect.select2('open');

					// On close we hide it
					productSelect.one('select2-close', onProductClose);

					productSelect.one('change', onProductChange);
				};

				// Build select2 product select one time only
				if (!productSelectWrapper.length) {
					var placeholder =
						$translate.instant('default.select') +
						' ' +
						$translate.instant('default.product').toLowerCase();
					productSelect = angular.element('<input class="real-product-select" type="hidden" />');
					productSelectWrapper = angular.element('<div class="real-product-select-wrapper"></div>');
					productSelectWrapper.append(productSelect);
					parentDiv.append(productSelectWrapper);

					var select2opts = {
						placeholder: placeholder,
						initSelection: _.noop,
						formatResult: function (obj, el, x, encode) {
							let data = '';
							let tier = '';
							let articleNo = '';
							if (!obj.id) {
								if (!obj.children || !obj.children.length) {
									el.parent().hide();
								} else {
									data = ` data-category-id="${obj.$id}"`;
									el.parent().addClass('select2-result-with-children');
								}
							} else {
								if (obj.tiers && obj.tiers.length) {
									tier = `<div class="order-row-select-product-type-icon Icon Icon-tiers"></div>`;
								}
								if (hasProductBundles && obj.bundle && obj.bundle.length) {
									tier = `<div class="order-row-select-product-type-icon Icon Icon-bundle"></div>`;
								}
								if (obj.articleNo && isActiveArticleNo) {
									articleNo = `<div class="order-row-select-sub-text">${obj.articleNo}</div>`;
								}
							}
							return `<div${data} class="order-row-select">
										${encode(obj.name)}${tier}${articleNo}
									</div>`;
						},
						formatSelection: function (obj, x, encode) {
							var cat = '';
							if (obj.category) {
								cat = '<div class="product-category-label">' + encode(obj.category.name) + '</div>';
							}
							return cat + '<div class="product-name-label">' + encode(obj.name) + '</div>';
						},
						quietMillis: 200
					};

					const hasProductSearchOptimization = Tools.FeatureHelper.hasSoftDeployAccess(
						'SPEED_EDIT_ORDER_PRODUCT_SEARCH'
					);

					let cacheProductSearchQuery = null;

					const isProductCacheEnabled = () =>
						selectedCategory === null && hasProductSearchOptimization && type === 'product';

					select2opts.ajax = {
						data: function (term) {
							return term;
						},
						transport: function (q) {
							if (q.data === '') {
								const speedTracker = getSpeedTracker('EditOrderProductSelect', true);
								speedTracker?.startTracking();

								const queryCacheResults = $scope.cachedProductSearches[q.data];
								if (isProductCacheEnabled()) {
									if (queryCacheResults) {
										return q.success(queryCacheResults);
									}
									// Can be extended further so it is caching the search result for all search queries
									cacheProductSearchQuery = '';
								}
							} else {
								const speedTracker = getSpeedTracker('EditOrderProductSelect');
								speedTracker?.endTracking();
							}
							return ctrl.searchProductsAjax(q.data, q.success);
						},
						results: function (res) {
							const speedTracker = getSpeedTracker('EditOrderProductSelect');
							speedTracker?.publishInteractable();
							speedTracker?.sendResults(hasProductSearchOptimization);

							if (isProductCacheEnabled() && cacheProductSearchQuery !== null) {
								$scope.cacheProductSearch(cacheProductSearchQuery, res);
								cacheProductSearchQuery = null;
							}
							return res;
						}
					};

					var isSearch = ctrl.isSearch();

					// If less than 500 products we set the data to be all the products. No minimumInputLength
					if (!isSearch) {
						select2opts.minimumInputLength = 0;

						// Set initial selectdata
						select2Data = ctrl.getActiveTree();

						// Use query-fn to be able to change the data with the toggle buttons
						select2opts.query = function (q) {
							if (q.term === '') {
								return q.callback({ results: select2Data });
							}

							if (type === 'product') {
								ctrl.searchProducts(q.term, q.callback);
							} else {
								ctrl.searchCategories(q.term, q.callback);
							}
						};
					} else {
						select2opts.minimumInputLength = 0;
					}

					productSelect.select2(select2opts);

					// Hide select2 container
					productSelectWrapper.hide();

					// Make the product select bigger
					productSelect
						.on('select2-open', function () {
							angular.element('.select2-results').css('max-height', modal / 2 - 100);
						})
						.on('select2-close', function () {
							angular.element('.select2-results').css('max-height', '');
						});

					// Setup custom buttons
					if (ctrl.categories.length) {
						// build category tree
						if (!categoryTree) {
							categoryTree = ctrl.buildCategoryTree();
						}

						type = 'product';
						catBtn = getToggleBtn().append(
							'<div class="product-category-outer"><span class="text-span">' +
								$translate.instant('default.categories') +
								'</span></span>'
						);
						var clearBtn = angular.element('<i class="fa fa-times"></i>');
						var clearSelectedCategory = function () {
							selectedCategory = null;
							catBtn.find('.text-span').text($translate.instant('default.categories'));
							clearBtn.hide();
						};
						clearBtn.on('click', clearSelectedCategory);
						clearBtn.hide();
						catBtn.find('.product-category-outer').append(clearBtn);

						prodBtn = getToggleBtn().text($translate.instant('default.products')).removeClass('btn-lined');
						togglesWrap = angular
							.element('<div class="btn-group select2-toggle"/>')
							.append(catBtn, prodBtn);
						togglesWrap.css({
							width: '100%',
							padding: '5px'
						});

						var showCategories = function () {
							catBtn.removeClass('btn-lined');
							prodBtn.addClass('btn-lined');
							type = 'category';
							setSelect2Data(categoryTree);
						};

						var showProducts = function () {
							prodBtn.removeClass('btn-lined');
							catBtn.addClass('btn-lined');
							type = 'product';
							setSelect2Data(tree);
						};

						// category-btn click
						catBtn.on('click', showCategories);

						// products-btn click
						prodBtn.on('click', showProducts);

						// Listen for open event
						productSelect.on('select2-open', function () {
							angular.element('#select2-drop').prepend(togglesWrap);
						});

						// Listen for select event so we can prevent default befaviour if a category was selected
						productSelect.on('select2-selecting', function (e) {
							// If a category was clicked we want to show all products in that category
							if (e.object.category === undefined) {
								e.preventDefault();
								showProducts();

								selectedCategory = e.val;
								if (isSearch) {
									var category = _.find(ctrl.categories, { id: e.val });
									catBtn.find('.text-span').text(category.name);
									clearBtn.show();
								}
								// Find category element in tree
								var categoryElem = angular
									.element('#select2-drop')
									.find('[data-category-id="' + e.val + '"]');

								if (categoryElem.length) {
									categoryElem[0].scrollIntoView();
								}
							}
						});
					}
				}

				$scope.$watch(
					'orderRow',
					function (orderRow) {
						if (orderRow) {
							productCategoryMagic($scope, ctrl.categories);
							ctrl.setSelectedTier(orderRow);
						}
					},
					true
				);

				$scope.$watch(
					'orderRow.$mappedCustom',
					function (customField) {
						if ($scope.onCustomFieldChange) {
							$scope.onCustomFieldChange($scope.orderRow.id, $scope.orderRow.sortId, customField);
						}
					},
					true
				);

				// move out real select if row is removed
				$scope.$on('$destroy', function () {
					if (productSelectWrapper.find('.real-product-select-wrapper')) {
						productSelectWrapper.appendTo(parentDiv);
					}
				});

				// Since we dont have prev sibling selectors in css yet this is a fix
				priceInput
					.focus(function () {
						quantity.addClass('focus');
						fixElement.addClass('focus');
					})
					.blur(function () {
						quantity.removeClass('focus');
						fixElement.removeClass('focus');
					});

				// Fix discount input arrow change
				discountInput.add(discountPercentInput).on('keydown', function (e) {
					// 38 up, 40 ner
					if ((e.keyCode === 38 || e.keyCode === 40) && this.value === '') {
						var current = parseFloat(this.placeholder);
						if (isNaN(current)) {
							current = 0;
						}
						this.value = current;
					}
				});

				// Show the real select when we click on product or enter/space/up/ner when focused
				fakeProduct.on('click', showProductSelect);
				fakeProduct.on('keydown', function (e) {
					if (e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
						e.preventDefault();
						showProductSelect();
					}
				});
			}
		};
	}
]);

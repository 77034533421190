import React from 'react';
import PropTypes from 'prop-types';
import ModalWrap from 'Components/ModalWrap';
import { Modal, ModalHeader, ModalContent, ModalControls, Button } from '@upsales/components';
import translate from 'Components/Helpers/translate';

const Confirm = ({
	color,
	title,
	icon,
	loading,
	children,
	onConfirm,
	onAbort,
	yes,
	visible,
	abort,
	yesDisabled,
	size = 'sm'
}) => {
	return (
		<ModalWrap isMounted={visible}>
			<Modal size={size}>
				<ModalHeader color={color} title={translate(title)} icon={icon} />
				<ModalContent>{children}</ModalContent>
				<ModalControls>
					{onConfirm ? (
						<Button disabled={yesDisabled} loading={loading} color={color} onClick={onConfirm}>
							{translate(yes)}
						</Button>
					) : null}
					{onAbort ? (
						<Button disabled={loading} color="grey" type="link" onClick={onAbort}>
							{translate(abort)}
						</Button>
					) : null}
				</ModalControls>
			</Modal>
		</ModalWrap>
	);
};

Confirm.propTypes = {
	title: PropTypes.string,
	yes: PropTypes.string,
	icon: PropTypes.string,
	abort: PropTypes.string,
	children: PropTypes.any,
	color: PropTypes.oneOf(['orange', 'red', 'bright-blue', 'green']),
	size: PropTypes.oneOf(['sm', 'md']),
	onConfirm: PropTypes.func,
	onAbort: PropTypes.func,
	loading: PropTypes.bool,
	yesDisabled: PropTypes.bool,
	visible: PropTypes.bool
};

Confirm.defaultProps = {
	title: '',
	color: 'orange',
	size: 'sm',
	yes: 'default.yes',
	abort: 'default.abort',
	yesDisabled: false,
	loading: false
};

export default Confirm;

'use strict';

angular.module('upResources').factory('DocumentTemplate', [
	'$resource',
	'URL',
	'API',
	'CacheService',
	'$q',
	'ResourceHelper',
	function DocumentTemplate($resource, URL, API, CacheService, $q, ResourceHelper) {
		// var AA = DocumentTemplateAttributes();
		// var Attribute = AA.attr;
		// var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'documentTemplate',
			createSuccessBody: 'saved.documentTemplate',
			updateSuccessBody: 'updated.documentTemplate',
			deleteSucccessBody: 'deleted.documentTemplate',
			createErrorBody: 'saveError.documentTemplate',
			updateErrorBody: 'saveError.documentTemplate',
			deleteErrorBody: 'deleteError.documentTemplate'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var DocumentTemplate = null;
				var baseUrl = URL + API + customer + '/documentTemplates/';
				var Cache;
				DocumentTemplate = $resource(
					baseUrl + ':type/:id',
					{},
					{
						get: { method: 'GET' },
						create: {
							method: 'POST',
							isArray: false,
							url: baseUrl + 'upload'
						},
						update: {
							method: 'PUT',
							isArray: false,
							url: baseUrl + 'upload/:id'
						},
						delete: {
							method: 'DELETE',
							isArray: false,
							url: baseUrl + ':id'
						}
					}
				);

				instance.find = function (filter, options) {
					if (!instance.type) {
						throw new Error('No type set for documentTemplates resource');
					}

					var params = angular.extend(filter, options);
					params.type = instance.type;
					instance.type = null;

					return DocumentTemplate.get(params).$promise.then(
						function (response) {
							return response;
						},
						function (response) {
							return response.status;
						}
					);
				};

				instance.setType = function (type) {
					instance.type = type;
					Cache = CacheService.getCache(CacheService.cacheTypes.DOCUMENTTEMPLATE + '.' + type);
					return instance;
				};

				instance.getCached = function () {
					var key = CacheService.cacheTypes.DOCUMENTTEMPLATE + '.' + instance.type;
					if (Cache) {
						var cached = Cache.get(key);
						if (cached) {
							return $q.when(cached);
						}
					}
					return instance.find({}).then(function (res) {
						Cache.put(key, res);
						return res;
					});
				};

				instance.delete = function (documentTemplate, options) {
					return DocumentTemplate.delete({ id: documentTemplate.id })
						.$promise.then(function (res) {
							helper.onDelete(options, documentTemplate);
							return res;
						})
						.catch((response) => {
							helper.onDeleteError(options, response);
							if(options?.propagateError) {
								throw response;
							}
						});
				};

				instance.save = function (documentTemplate, options) {
					var defered = $q.defer();

					if (!documentTemplate) {
						return defered.reject('No document template');
					}

					if (documentTemplate.id) {
						DocumentTemplate.update(
							{ id: documentTemplate.id },
							documentTemplate,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						DocumentTemplate.create(
							documentTemplate,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							helper.onSave(options, res.data, !documentTemplate.id);
						})
						.catch(function () {
							helper.onSaveError(options, !documentTemplate.id);
						});

					return defered.promise;
				};

				return instance;
			}
			// attr: Attribute,
			// keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('services.dateFormat', []).factory('DateFormat', [
	'$filter',
	function ($filter) {
		var monthNames = [
			'date.january',
			'date.february',
			'date.march',
			'date.april',
			'date.may',
			'date.june',
			'date.july',
			'date.august',
			'date.september',
			'date.october',
			'date.november',
			'date.december'
		];
		var instance = {
			format: function (format, date) {
				var dateString = format;
				if (!date) {
					date = new Date(this.toTimeZone().format());
				}
				var month = '' + (date.getMonth() + 1);
				if (month.length == 1) {
					month = '0' + month;
				}

				var day = '' + date.getDate();
				if (day.length == 1) {
					day = '0' + day;
				}

				var monthName = monthNames[date.getMonth()];

				dateString = dateString.replace('yyyy', date.getFullYear());
				dateString = dateString.replace('mm', month);
				dateString = dateString.replace('dd', day);
				dateString = dateString.replace('month', $filter('translate')(monthName));

				return dateString;
			},
			toTimeZone: function (date) {
				return moment(date).tz('Europe/Stockholm');
			},
			toUTC: function (date) {
				return moment(new Date(date)).utc().format();
			}
		};

		return instance;
	}
]);

import {
	ListView,
	SalesboardListView,
	SalesboardStandardListView,
	SalesboardUserListView,
	StandardListView,
	UserListView,
	isSalesboardUserListView,
	isStandardListView,
	isStandardSalesboardListView
} from 'App/resources/AllIWant';

export const listViewSplitter = (listViews: ListView[]) => {
	return listViews.reduce(
		(res, obj) => {
			if (isStandardListView(obj)) {
				res.defaultList.push(obj);
			} else if (obj.shared) {
				res.sharedList.push(obj);
			} else {
				res.userList.push(obj);
			}
			return res;
		},
		{ userList: [] as UserListView[], sharedList: [] as UserListView[], defaultList: [] as StandardListView[] }
	);
};

export const salesboardListViewSplitter = (listViews: SalesboardListView[]) => {
	return listViews.reduce(
		(res, obj) => {
			if (isStandardSalesboardListView(obj)) {
				res.defaultList.push(obj);
			} else if (isSalesboardUserListView(obj)) {
				res.sharedList.push(obj);
			} else {
				res.userList.push(obj);
			}
			return res;
		},
		{
			userList: [] as SalesboardUserListView[],
			sharedList: [] as SalesboardUserListView[],
			defaultList: [] as SalesboardStandardListView[]
		}
	);
};

import async from 'async';

angular.module('upResources').factory('GenericMapper', [
	function GenericMapper() {
		var mapper = {
			number: function (value, attr, callback) {
				var num = value === null || value === '' ? 0 : parseInt(value);
				callback(isNaN(num) ? "'" + value + "' is not a number for " + attr.field : null, num);
			},
			float: function (value, attr, callback) {
				var num = value === null ? 0 : parseFloat(value);
				callback(isNaN(num) ? "'" + value + "' is not a float for " + attr.field : null, num);
			},
			string: function (value, attr, callback) {
				value = value === null || value === undefined ? '' : value;
				callback(null, value.toString());
			},
			boolean: function (value, attr, callback) {
				value = Boolean(value);
				if (typeof value !== 'boolean') {
					return callback("'" + value + "' is not a valid boolean for field" + attr.field);
				}
				callback(null, value);
			},
			reference: function (obj, attr, callback) {
				var id = obj !== null && obj.hasOwnProperty('id') ? obj.id : obj;
				if (obj.id && (isNaN(id) || id === null)) {
					return callback('Missing id for', attr.field, 'in mapper.', null);
				} else {
					var mappedObj = {};

					if (typeof id == 'number') {
						var num = parseInt(id);
						mappedObj.id = num;
						//	return callback(null, {id: num});
					}

					async.each(
						Object.keys(obj),
						function (key, cb) {
							var attribute = attr.attr[key];

							if (!attribute) {
								return cb(null);
							}

							var value = obj[key];

							var type = attribute ? attribute.type.toLowerCase() : null;

							if (Array.isArray(value)) {
								var mappedArray = [];
								angular.forEach(value, function (val) {
									mapper[type](val, attribute, function (err, value) {
										mappedArray.push(value);
									});
								});
								mappedObj[key] = mappedArray;
							} else if (
								attribute !== undefined &&
								typeof mapper[attribute.type.toLowerCase()] === 'function'
							) {
								mapper[type](value, attribute, function (err, value) {
									mappedObj[key] = value;
								});
							}
							cb(null);
						},
						function (err) {
							return callback(err, mappedObj);
						}
					);
				}
			}
		};

		var instance = {
			map: function (obj, attributes, callback) {
				var mapped = {};
				async.eachSeries(
					Object.keys(obj),
					function (key, cb) {
						if (attributes[key] === undefined) {
							return cb(null);
						}

						var attribute = attributes[key];

						// crap on customfield mapping for now
						if (key === 'custom' && attribute !== undefined) {
							mapped[key] = obj[key];
							return cb(null);
						}

						// check for reference object
						if (attribute.skippMapping !== true && attribute.attr !== undefined && obj[key]) {
							// if multiple
							if (Array.isArray(obj[key])) {
								mapped[attribute.parent] = [];
								async.eachSeries(
									obj[key],
									function (item, chewbacca) {
										mapper.reference(item, attribute, function (err, value) {
											mapped[attribute.parent].push(value);
											chewbacca(err);
										});
									},
									cb
								);
							} else {
								mapper.reference(obj[key], attribute, function (err, value) {
									mapped[attribute.parent] = value;
									cb(err);
								});
							}
						} else if (
							attribute.skippMapping !== true &&
							attribute.type &&
							typeof mapper[attribute.type.toLowerCase()] === 'function'
						) {
							var type = attribute.type.toLowerCase();
							mapper[type](obj[key], attribute, function (err, value) {
								mapped[key] = value;
								cb(err);
							});
						} else {
							mapped[key] = obj[key];
							cb(null);
						}
					},
					function (err) {
						callback(err, mapped);
					}
				);
			}
		};

		return instance;
	}
]);

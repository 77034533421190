export default {
	client: {
		title: ' default.client',
		field: 'client.name',
		locked: true
	},
	contact: {
		title: ' default.contact',
		field: 'contact.name'
	},
	country: {
		title: 'visit.country',
		field: 'country'
	},
	score: {
		title: 'default.leadScore',
		field: 'score'
	},
	startDate: {
		title: 'default.date',
		field: 'startDate'
	},
	pages: {
		title: 'visitor.pages',
		field: 'pages.url'
	},
	leadSourceType: {
		title: 'default.leadsourceType',
		field: 'leadSource.type'
	},
	leadSource: {
		title: 'default.leadsource',
		field: 'leadSource.source'
	}
};

'use strict';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';

var countrySelectDir = 'upCountrySelect';

angular
	.module('upDirectives')
	.directive(countrySelectDir, function (Account, CountryCodes, selectHelper, AppService, $q, $translate) {
		return selectHelper.getDirectiveObject(countrySelectDir, {
			resource: Account,
			ajax: false,
			asIds: false,
			idAttr: { field: 'code' },
			titleAttr: { field: 'translated' },
			matcher: function (term, arg2, object) {
				console.log('term and stuff', term, '..', arg2, '..', object);

				return object.translated.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			formatSelection: function (country, container, escape) {
				return escape(country.translated);
			},
			formatResult: function (country, container, query, escape) {
				return escape(country.translated);
			},
			getter: function () {
				return CountryCodes.customer(AppService.getCustomerId())
					.find({})
					.then(function (res) {
						var mapped = _.map(res.data, function (cc) {
							cc.translated = $translate.instant(cc.lang);
							return cc;
						});

						var language = AppService.getSelf().language;

						if (language) {
							mapped.sort(sortAlphabetically('translated', false, language));
						}

						return { data: mapped, metadata: { total: mapped.length } };
					});
			}
		});
	})
	.directive(countrySelectDir + 'Ids', function (Account, CountryCodes, selectHelper, AppService, $q, $translate) {
		return selectHelper.getDirectiveObjectIds(countrySelectDir + 'Ids', {
			resource: Account,
			ajax: false,
			asIds: true,
			idAttr: { field: 'code' },
			titleAttr: { field: 'translated' },
			matcher: function (term, arg2, object) {
				return object.translated.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			formatSelection: function (country, container, escape) {
				return escape(country.translated);
			},
			formatResult: function (country, container, query, escape) {
				return escape(country.translated);
			},
			getter: function () {
				return CountryCodes.customer(AppService.getCustomerId())
					.find({})
					.then(function (res) {
						var mapped = _.map(res.data, function (cc) {
							cc.translated = $translate.instant(cc.lang);
							return cc;
						});

						var language = AppService.getSelf().language;

						if (language) {
							mapped.sort(sortAlphabetically('translated', false, language));
						}

						return { data: mapped, metadata: { total: mapped.length } };
					});
			}
		});
	})
	.directive(countrySelectDir + 'React', function () {
		return {
			scope: {
				ngModel: '=ngModel',
				onSelect: '='
			},
			require: 'ngModel',
			replace: true,
			restrict: 'A',
			link: function ($scope, $element) {
				const firstElement = $element[0];

				const flagFormat = (obj, container, escape) => {
					var translated = obj.translated || Tools.$translate(obj.lang);
					var countryCode = obj.code || obj.country;
					return (
						'<span><i style="margin-right: 5px;" class="flag-icon flag-icon-' +
						escape(countryCode.toLowerCase()) +
						'"></i>' +
						escape(translated) +
						'</span>'
					);
				};

				const onChange = value => {
					$scope.onSelect(value);
				};

				const render = () => {
					if (!firstElement) {
						return;
					}

					const upCountryRegion = {
						getter: function () {
							let locales = Tools.CountryCodes.locales();

							locales = _.map(_.cloneDeep(locales), function (loc) {
								loc.translated = Tools.$translate(loc.lang);
								return loc;
							});

							locales = _.sortBy(locales, 'translated');
							return Promise.resolve({ data: locales, metadata: { total: locales.length } });
						},
						countryCodes: Tools.CountryCodes.locales(),
						formatSelection: flagFormat,
						formatResult: (obj, container, query, escape) => flagFormat(obj, container, escape)
					};

					const props = {
						value: $scope.ngModel,
						onChange: onChange,
						options: upCountryRegion,
						required: true
					};

					ReactDOM.render(
						React.createElement(ReactTemplates.INPUTS.upCountry, props),
						firstElement,
						function () {}
					);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
				});

				$scope.$watch(
					'ngModel',
					function () {
						render();
					},
					true
				);
			}
		};
	});

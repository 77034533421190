'use strict';

ReactTemplates.upFilters.filterTypes.radioSearch = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			placeholder: 'default.search',
			maxValueDisplayLength: 20,
			msBeforeSearch: 300
		};
	},
	writeTimeout: null,
	writeLock: false,
	didOpen: false,

	getInitialState: function () {
		var value = this.props.filter && this.props.filter.value ? this.props.filter.value : '';
		return {
			value: value,
			expanded: this.props.expanded || false
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		if (this.writeLock === false) {
			var value = newProps.filter && newProps.filter.value ? newProps.filter.value : '';
			this.setState({
				value: value
			});
		}
	},
	UNSAFE_componentWillMount: function () {
		this.search = _.debounce(this.search, this.props.msBeforeSearch);
	},
	componentDidUpdate: function () {
		if (this.state.expanded && this.didOpen) {
			var input = this._input;
			input.focus();
		}
		this.didOpen = false;
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onInputChange: function (event) {
		var oldOption = this.findSelectedOption();
		var newOption = this.props.filter.options[event.target.value];

		if (oldOption !== newOption) {
			this.props.onChange(
				{
					filterName: this.props.filterName,
					comparisonType: newOption.comparisonType,
					inactive: newOption.inactive ? true : false,
					value: newOption.value,
					type: 'radio'
				},
				{
					action: 'add'
				}
			);
		}
	},
	onTextInputChange: function (event) {
		var self = this;
		var value = event.target.value;
		var active = value ? true : false;

		self.writeLock = true;

		if (self.writeTimeout) {
			clearTimeout(self.writeTimeout);
		}

		self.writeTimeout = setTimeout(function () {
			self.writeLock = false;
		}, 400);

		self.setState({
			value: value
		});

		self.search(
			{
				filterName: self.props.filterName,
				comparisonType: 'Search',
				inactive: !active,
				value: value,
				type: 'text'
			},
			{
				action: 'add'
			}
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true
			},
			{
				action: 'remove'
			}
		);

		ReactTemplates.TOOLS.stopProp(event);
	},
	findSelectedOption: function () {
		var filter = this.props.filter;

		var selectedOption = _.find(filter.options, function (option) {
			return (
				option.value === filter.value &&
				(option.comparisonType || 'Equals') === (filter.comparisonType || 'Equals')
			);
		});

		if (selectedOption === undefined) {
			return filter.options[0];
		}
		return selectedOption;
	},
	formatValueString: function () {
		var valueString = '';

		var selectedOption = this.findSelectedOption();

		if (this.state.value && typeof this.state.value === 'string') {
			valueString = this.state.value;
		} else if (selectedOption) {
			valueString = Tools.$translate(selectedOption.text);
		}

		return valueString;
	},
	search: function (filter, options) {
		this.props.onChange(filter, options);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var filter = this.props.filter;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		var selectedOption = this.findSelectedOption();
		var active = null;
		if (this.state.value && typeof this.state.value === 'string' && this.state.value !== '') {
			active = true;
		} else {
			active = selectedOption.inactive === true ? false : true;
		}
		var btnWidth = 100 / filter.options.length + '%';

		var options = _.map(
			filter.options,
			function (option, index) {
				var text = Tools.$translate(option.text);
				var classes = 'btn up-btn btn-sm no-shadow btn-light-grey';

				if (option === selectedOption) {
					classes = 'btn up-btn btn-sm no-shadow btn-bright-blue';
				}

				return (
					<button
						key={'option-' + index}
						value={index}
						className={classes}
						onClick={this.onInputChange}
						style={{ width: btnWidth, padding: '4px' }}
					>
						{text}
					</button>
				);
			}.bind(this)
		);

		var placeholder = Tools.$translate(this.props.placeholder);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>

				{this.state.expanded && (
					<div>
						<div className={'btn-group btn-block drilldown radio-drilldown'}>{options}</div>
						<div className={'drilldown text-drilldown'}>
							<input
								maxLength="120"
								type={filter.inputType || 'text'}
								className="form-control input-sm"
								placeholder={placeholder}
								value={typeof this.state.value === 'string' ? this.state.value : ''}
								onChange={this.onTextInputChange}
								ref={this.setRef.bind(this, '_input')}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
});

import { globalTracker } from 'App/babel/helpers/Tracker';
import Tip from 'Resources/Tip';
import ResetPassword from 'Resources/ResetPassword';
import logError from 'App/babel/helpers/logError';
import WhatsIncludedCard from 'App/babel/components/CreateAccount/WhatsIncludedCard';
import openModal from 'App/services/Modal/Modal';

angular.module('security.service').controller('LoginCtrl', [
	'$scope',
	'$state',
	'security',
	'Session',
	'Self',
	'localStorageService',
	'$upModal',
	'$cookies',
	'browserService',
	'$location',
	'CacheService',
	'ENV',
	'Statuspage',
	'$sce',
	'CLASSIC_URL',
	'URL',
	'$safeApply',
	'$rootScope',
	function (
		$scope,
		$state,
		security,
		Session,
		Self,
		localStorageService,
		$upModal,
		$cookies,
		browserService,
		$location,
		CacheService,
		ENV,
		Statuspage,
		$sce,
		CLASSIC_URL,
		URL,
		$safeApply,
		$rootScope
	) {
		$scope.isTwoFactorAuth = false;
		$scope.randomTip = null;
		$scope.isSSO = false;
		$scope.reset = {
			email: '',
			sent: false,
			hash: null,
			newPassword: '',
			newPassword2: '',
			loading: false,
			passwordHasBeenReset: false,
			passwordNotMatch: false,
			sendResetLinkFailed: false,
			passwordRulesNotMet: false
		};
		$scope.createAccountActive = false;
		$rootScope.$watch('fortnoxActivate', fortnoxActivate => {
			$scope.createAccountActive = fortnoxActivate;
		});

		var Cookie = localStorageService.cookie;
		var savedUserEmailKey = 'Upsales.crm.savedEmail';
		var savedUserNameKey = 'Upsales.crm.savedName';

		var forgetUser = function () {
			Cookie.remove(savedUserEmailKey);
			Cookie.remove(savedUserNameKey);
		};

		$scope.credentials = {
			email: '',
			password: ''
		};

		$scope.twoFACredentials = {
			validationKey: '',
			key: ''
		};

		$scope.samlUrl = null;

		$scope.WhatsIncludedCard = WhatsIncludedCard;

		// temporary classic alert
		$scope.hideClassicAlert = $cookies.hideClassicAlert;
		$scope.removeClassicAlert = function () {
			$scope.hideClassicAlert = true;
			$cookies.hideClassicAlert = true;
		};

		// determine if classic or power should be default
		$scope.useClassic = false;
		if ($cookies.loginVersion === 'power') {
			$scope.useClassic = false;
			$scope.hideClassicAlert = true;
		} else if ($cookies.loginVersion === 'classic') {
			$scope.showClassic = true;
			$scope.useClassic = true;
			$scope.hideClassicAlert = true;
		} else if ($location.search() && $location.search().useClassic) {
			$scope.useClassic = true;
			$cookies.loginVersion = 'classic';
		}

		if (
			$location.search() &&
			($location.search().classic || $location.search().useClassic) &&
			$cookies.loginVersion !== 'power'
		) {
			$scope.showClassic = true;
		}

		if ($location.search() && $location.search().resetPassword) {
			$scope.loginVisible = false;
			$scope.reset.hash = $location.search().resetPassword;
			$location.search('resetPassword', null);

			// Check if reset is still valid
			ResetPassword.get($scope.reset.hash)
				.then(function (res) {
					// hash was valid
					if (!res.data) {
						// hash was invalid, show login and error
						$scope.resetHashInvalid = true;
						$scope.showLogin(true);
					}
				})
				.catch(function () {
					// hash was invalid, show login and error
					$scope.resetHashInvalid = true;
					$scope.showLogin(true);
				});
		} else {
			$scope.loginVisible = true;
		}

		$scope.showLogin = function (val) {
			$scope.loginVisible = val;
			$scope.reset.hash = null;
			$scope.reset.sent = false;
			$scope.reset.sendResetLinkFailed = false;
			$scope.createAccountActive = false;
		};

		var passwordValid = function (p) {
			return window.passwordHelper.validate(p).valid;
		};

		$scope.createAccount = () => {
			openModal('CreateUpsalesAccount', {
				onClose: () => {
					$scope.showLogin(true);
					$scope.createAccountActive = false;
					$rootScope.fortnoxActivate = false;
				}
			});
		};

		$scope.resetPassword = function () {
			$scope.reset.passwordNotMatch = false;
			$scope.reset.passwordRulesNotMet = false;
			if ($scope.reset.newPassword !== $scope.reset.newPassword2) {
				$scope.reset.passwordNotMatch = true;
				return;
			}
			if (!passwordValid($scope.reset.newPassword)) {
				$scope.reset.passwordRulesNotMet = true;
				return;
			}

			$scope.reset.loading = true;
			ResetPassword.resetPassword({
				password: $scope.reset.newPassword,
				hash: $scope.reset.hash
			})
				.then(function () {
					$scope.reset.loading = false;
					$scope.reset.passwordHasBeenReset = true;
					$safeApply($scope);
				})
				.catch(e => logError(e, 'Failed to reset password'));
		};

		$scope.sendResetLink = function () {
			$scope.reset.sendResetLinkFailed = false;
			ResetPassword.sendResetLink($scope.reset.email)
				.then(function () {
					$scope.reset.sent = true;
					$safeApply($scope);
				})
				.catch(function () {
					$scope.reset.sendResetLinkFailed = true;
					$safeApply($scope);
				});
		};

		$scope.classicUrl = CLASSIC_URL + 'setSessionServlet.jsp';
		$scope.classicUrl = $sce.trustAsResourceUrl($scope.classicUrl);

		var split = CLASSIC_URL.split('/');
		split.pop();
		split.pop();

		$scope.requestPasswordChangeUrl = split.join('/') + '/external/requestPasswordChangeServlet.jsp';
		$scope.requestPasswordChangeUrl = $sce.trustAsResourceUrl($scope.requestPasswordChangeUrl);

		$scope.error = false;
		$scope.savedUser = Cookie.get(savedUserEmailKey);
		$scope.savedUserName = Cookie.get(savedUserNameKey);
		$scope.rememberMe = true;
		$scope.focusEmail = !$scope.savedUser;
		$scope.credentials.email = $scope.savedUser || '';
		$scope.ipBlocked = false;
		$scope.missingIndex = $cookies.failed === 'missingIndex' ? true : false;
		$cookies.failed = undefined;
		$scope.isIE = browserService.isIE();
		$scope.latestIncident = null;
		$scope.nextPlannedIncident = null;

		$scope.changeShowClassic = function () {
			$scope.showClassic = true;
			$scope.useClassic = true;
			$scope.hideClassicAlert = true;
		};

		$scope.changeClassic = function () {
			$scope.useClassic = !$scope.useClassic;
		};

		$scope.changeRememberMe = function () {
			$scope.rememberMe = !$scope.rememberMe;
			if (!$scope.rememberMe) {
				forgetUser();
			}
		};

		// Display env message
		if (ENV === 'DEV' || ENV === 'ALPHA' || ENV === 'BETA') {
			$scope.EnvMsg = ENV;

			if (ENV === 'BETA' && URL.indexOf('sandbox') !== -1) {
				$scope.EnvMsg = 'SANDBOX';
			}
		}

		$scope.removeSavedUser = function () {
			$scope.savedUser = null;
			$scope.savedUserName = '';
			$scope.focusEmail = true;
			$scope.credentials.email = '';
			$scope.rememberMe = null;
			$scope.samlUrl = null; // Reset saml url here in case user removes saved user
			forgetUser();
		};

		$scope.loginClassic = function ($event) {
			$cookies.loginVersion = 'classic';
			// check if the user wants to be remembered
			if ($scope.rememberMe) {
				// set the cookies to remember the username
				Cookie.set(savedUserEmailKey, $scope.credentials.email);
			} else {
				// forget the user (remove cookies)
				forgetUser();
			}
			$event.target.submit();
		};

		$scope.getLoginMethod = function (noRedirect = false) {
			var hash = $location.search();
			$scope.loadingMethod = true;
			// eslint-disable-next-line promise/catch-or-return
			Session.getLoginMethod($scope.credentials.email, hash.samlBypass, noRedirect).then(function (response) {
				// Only do this if noRedirect is true to make sure that the ui works as before
				if (noRedirect && response?.method === 'saml') {
					$scope.samlUrl = response.url;
				}
				$scope.loadingMethod = false;
			});
		};

		$scope.changePassword = function (newPassword) {
			return ResetPassword.changePassword({
				email: $scope.credentials.email,
				password: $scope.credentials.password,
				newPassword: newPassword
			}).then(function () {
				$scope.credentials.password = newPassword;
				$scope.login({ preventDefault: _.noop });
			});
		};

		$scope.login = function ($event) {
			$event.preventDefault();
			$scope.error = false;
			$cookies.loginVersion = 'power'; // set to remember power as default login

			$scope.loading = true;

			// If username was saved and we have gotten a saml url we redirect here
			if ($scope.savedUser && $scope.samlUrl) {
				window.location = $scope.samlUrl;
				return;
			}

			if ($location.search().samlBypass) {
				$scope.credentials.samlBypass = $location.search().samlBypass;
			}
			// Try to login
			// eslint-disable-next-line promise/catch-or-return
			Session.login($scope.credentials)
				.then(
					function (success) {
						if (success.data.isTwoFactorAuth) {
							$scope.isTwoFactorAuth = true;
							$scope.twoFACredentials.validationKey = success.data.token;
							return;
						}

						// get self
						// eslint-disable-next-line promise/catch-or-return
						Self.get().then(
							function (res) {
								var self = res.data;
								// set logged in variable on the app
								security.loggedIn();

								// check if the user wants to be remembered
								if ($scope.rememberMe) {
									// set the cookies to remember the username and name
									Cookie.set(savedUserEmailKey, $scope.credentials.email);
									Cookie.set(savedUserNameKey, self.name);
								} else {
									// forget the user (remove cookies)
									forgetUser();
								}

								var customerId = self.clients[0].clientId;

								var hash = $location.search();

								// Redirect
								if (hash && hash.externalSSO) {
									$state.go('externalSSO', { site: hash.externalSSO });
								} else if (hash && hash.returnTo) {
									var params = { customerId: customerId };

									if (hash.params) {
										params = JSON.parse(hash.params);
									}
									$state.go(hash.returnTo, params).catch(function () {
										$state.go('tmpStart', { customerId: customerId });
									});
								} else if ($cookies.loginReturnUrl && $cookies.loginReturnUrl !== 'undefined') {
									// eslint-disable-next-line promise/catch-or-return
									$state.go('tmpStart', { customerId: customerId }).then(function () {
										// eslint-disable-next-line promise/catch-or-return
										Tools.AppService.loadedPromise.then(function () {
											window.location.href = $cookies.loginReturnUrl;
											delete $cookies.loginReturnUrl;
										});
									});
								} else {
									$state.go('tmpStart', { customerId: customerId });
								}
							},
							function (res) {
								var missingIndex = res.data && res.data.error && res.data.error.key === 'MissingIndex';
								var isTrial = res.data && res.data.client && res.data.client.provisioningTrial;
								if (missingIndex && isTrial) {
									$scope.missingIndexTrial = true;
								} else if (missingIndex) {
									$scope.missingIndex = true;
								} else {
									$upModal.open('errorConfirm', {
										title: 'default.error',
										body: 'login.anErrorOccured',
										icon: 'fa-warning'
									});
								}
							}
						);
					},
					function (reason) {
						if (reason.status === 403 && reason.data.errorCode === 113) {
							$scope.noValidBrand = true;
						} else if (reason.status === 403 && reason.data.errorCode === 140) {
							$scope.accountLocked = true;
						} else if (reason.status === 403) {
							$scope.ipBlocked = true;
						}
						// if error is 402 there is no active agreement
						else if (reason.status === 402) {
							return $state.go('manageAccount', { credentials: $scope.credentials });
						} else if (reason.status === 401 && reason.data.error && reason.data.error.errorCode === 94) {
							$scope.twoFactorNoPhone = true;
						} else if (_.get(reason, 'data.errorCode') === 116) {
							$scope.forceResetPassword = true;
						} else {
							// else just show error invalid pass/username
							$scope.error = true;
						}
					}
				)
				.finally(function () {
					$scope.loading = false;
				});
		};

		$scope.onlyNumbers = function () {
			if ($scope.twoFACredentials.key) {
				$scope.twoFACredentials.key = $scope.twoFACredentials.key.replace(/[^0-9]/g, '');
			}
		};

		$scope.backToLoging = function () {
			$scope.isTwoFactorAuth = false;
		};

		$scope.trackTipClick = function () {
			globalTracker.track('login-tip-click-' + $scope.randomTip.id, { type: 'login-tip-click' });
		};

		$scope.loginTwoFA = function ($event) {
			$event.preventDefault();

			$scope.loading = true;

			// Try to login
			Session.loginTwoFA($scope.twoFACredentials)
				.then(function () {
					// get self
					Self.get()
						.then(function (res) {
							var self = res.data;
							// set logged in variable on the app
							security.loggedIn();

							// check if the user wants to be remembered
							if ($scope.rememberMe) {
								// set the cookies to remember the username and name
								Cookie.set(savedUserEmailKey, $scope.credentials.email);
								Cookie.set(savedUserNameKey, self.name);
							} else {
								// forget the user (remove cookies)
								forgetUser();
							}

							var customerId = self.clients[0].clientId;

							var hash = $location.search();

							// Restore cache
							CacheService.restore();

							// Redirect
							if (hash && hash.returnTo) {
								var params = { customerId: customerId };

								if (hash.params) {
									params = JSON.parse(hash.params);
								}
								$state.go(hash.returnTo, params).catch(function () {
									$state.go('tmpStart', { customerId: customerId });
								});
							} else {
								$state.go('tmpStart', { customerId: customerId });
							}
						})
						.catch(function () {
							// eslint-disable-next-line promise/catch-or-return
							$upModal.open('errorConfirm', {
								title: 'default.error',
								body: 'login.anErrorOccured',
								icon: 'fa-warning'
							});
						});
				})
				.catch(function (reason) {
					// Validation key disabled
					if (reason.data.errorCode === 96) {
						$scope.isTwoFactorAuth = false;
						$scope.twoFACredentials = {
							validationKey: '',
							key: ''
						};
						$scope.twoFAError = true;
						return;
					}
					// else just show error invalid SMS code
					$scope.error = true;
				})
				.finally(function () {
					$scope.loading = false;
				});
		};

		var getCRMStatus = function () {
			// Check status for POWER
			Statuspage.getPage()
				.then(function (page) {
					// If we have any issues
					if (page.status !== 'operational') {
						// Get incidents
						Statuspage.getUnresolvedIncidents()
							.then(function (incidents) {
								$scope.latestIncident = incidents[0];
							})
							.catch(e => {
								console.log('Failed to fetch getUnresolvedIncidents from statuspage', e);
							});
					}
				})
				.catch(e => {
					console.log('Failed to fetch page from statuspage', e);
				});

			// Get scheduled incidents
			Statuspage.getScheduledIncidents()
				.then(function (incidents) {
					$scope.nextPlannedIncident = incidents[0];
				})
				.catch(e => {
					console.log('Failed to fetch getScheduledIncidents from statuspage', e);
				});
		};

		var getTip = function () {
			// Get random tip
			Tip.getRandomTip()
				.then(function (tip) {
					$scope.randomTip =
						tip && tip.data
							? tip.data
							: {
									type: '',
									title: 'Find out more about the Upsales revenue engine',
									description:
										'The fastest growing B2B companies use CRM, Marketing Automation and Analytics from Upsales to reach new customers and win more deals.',
									link: 'https://www.upsales.com'
							  };
					$safeApply($scope);
				})
				.catch(e => logError(e, 'Failed to fetch random tip'));
		};

		var currentBrowser = function (supported, browser, cb) {
			var mobile = ['Android', 'iPhone'];
			var activeBrowser = true,
				isMobileBrowser = false,
				mobileType = null;

			var i;

			for (i = 0; i < supported.length; i++) {
				if (browser.indexOf(supported[i]) !== -1) {
					// activeBrowser = supported[i];
					activeBrowser = false;
					break;
				}
			}

			for (i = 0; i < mobile.length; i++) {
				if (browser.indexOf(mobile[i]) !== -1) {
					isMobileBrowser = true;
					mobileType = mobile[i];
					break;
				}
			}

			return cb(activeBrowser, isMobileBrowser, mobileType);
		};

		var checkBrowser = function () {
			var supportedBrowsers = ['Chrome', 'Firefox'];

			currentBrowser(supportedBrowsers, navigator.userAgent, function (browser, mobile, mobileType) {
				if (browser) {
					$scope.notSupportedBrowser = browser;
				}

				if (mobile) {
					$scope.isCurrentMobile = mobile;
					$scope.isCurrentMobileType = mobileType;
				}
			});
		};

		$scope.acceptBrowserCheck = function () {
			localStorageService.set('acceptBrowserCheck', true);
			$scope.notSupportedBrowser = false;
			$scope.isCurrentMobile = false;
		};

		var init = function () {
			// Check for session and force logout if we have one
			// This prevents users to go to /login and sign in with another account and get wierd data
			security.logout();

			if (localStorageService.get('acceptBrowserCheck') !== true) {
				checkBrowser();
			}

			$scope.backgroundUrl = URL + 'external/login/image';
			getCRMStatus();
			getTip();

			if ($location.search().twoFaKey) {
				$scope.isTwoFactorAuth = true;
				$scope.twoFACredentials.validationKey = $location.search().twoFaKey;
				$location.search('twoFaKey', null);
			}

			// If we have a saved user we need to check for sso login on init. Make sure noRedirect is true or else we will be logged in when logging out
			if ($scope.savedUser) {
				$scope.getLoginMethod(true);
			}
		};

		init();
	}
]);

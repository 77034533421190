import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { globalTracker } from 'App/babel/helpers/Tracker';
import TableHeaderDropdown from 'Components/MultiSelect/TableHeaderDropdown';

const propTypes = {
	tools: PropTypes.shape({
		sort: PropTypes.func,
		opts: PropTypes.object,
		$multiSelect: PropTypes.shape({
			getTotal: PropTypes.func,
			allSelected: PropTypes.bool,
			selectArray: PropTypes.func,
			selectAllFilter: PropTypes.func,
			selectNone: PropTypes.func,
			selected: PropTypes.array,
			allSelectedFilter: PropTypes.bool
		})
	}),
	actions: PropTypes.shape({
		runAction: PropTypes.func,
		actions: PropTypes.any
	}),
	data: PropTypes.array,
	template: PropTypes.string,
	loading: PropTypes.bool,
	length: PropTypes.number,
	columns: PropTypes.arrayOf(PropTypes.string),
	categoryTypes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})
	),
	columnTemplates: PropTypes.object,
	currentSorting: PropTypes.shape({
		ascending: PropTypes.bool,
		attribute: PropTypes.string
	})
};
class TableHead extends Component {
	static propTypes = propTypes;

	closeMultiSelect = () => {
		$(ReactDOM.findDOMNode(this.refs['dropdown'])).removeClass('open').click();
		$('body').trigger('click');
	};

	sort = (attr, asc) => {
		this.props.tools.sort({ attribute: attr, ascending: asc });
	};

	selectAll = () => {
		this.closeMultiSelect();
		//this.props.tools.$multiSelect.selectAll(true);
		var idField = this.props.tools.opts.idField || 'id';
		var total = this.props.tools.$multiSelect.getTotal();
		var selected = _.pluck(this.props.data, idField);

		if (selected.length === total) {
			this.props.tools.$multiSelect.allSelected = true;
		}

		this.props.tools.$multiSelect.selectArray(selected);
	};

	selectAllFilter = () => {
		this.closeMultiSelect();
		this.props.tools.$multiSelect.selectAllFilter();
	};

	selectNone = () => {
		this.closeMultiSelect();
		this.props.tools.$multiSelect.selectNone();
	};

	selectToggle = () => {
		if (this.props.tools.$multiSelect.allSelected) {
			this.selectNone();
		} else {
			this.selectAll();
		}
	};

	getClass = () => {
		var toggleClass = '';
		if (!this.props.tools.$multiSelect.allSelected && this.props.tools.$multiSelect.selected.length) {
			toggleClass = 'Icon-minus-square-o';
		} else if (this.props.tools.$multiSelect.allSelected || this.props.tools.$multiSelect.allSelectedFilter) {
			toggleClass = 'Icon-check-square';
		} else {
			toggleClass = 'Icon-square-o';
		}
		return toggleClass;
	};

	setClasses = () => {
		var btn = $(ReactDOM.findDOMNode(this.refs['indicator']));
		btn.removeClass('fa-square-o fa-minus-square-o fa-check-square');
		var toggleClass = this.getClass();
		btn.addClass(toggleClass);
	};

	doExport = options => {
		if (options.selectAllFilter) {
			this.selectAllFilter();
		} else {
			this.selectAll();
		}
		var self = this;

		globalTracker.track('experimental-export-button', { type: this.props.tools.opts.type, location: 'list' });
		return this.props.actions.runAction(this.props.actions.actions.export).finally(function () {
			self.props.tools.$multiSelect.selectNone();
		});
	};

	componentDidMount() {
		this.unsubSelectAll = Tools.$rootScope.$on('$multiSelect.selectAll', this.setClasses);
		this.unsubSelectAllFilter = Tools.$rootScope.$on('$multiSelect.selectAllFilter', this.setClasses);
		this.unsubSelectNone = Tools.$rootScope.$on('$multiSelect.selectNone', this.setClasses);
		this.unsubToggle = Tools.$rootScope.$on('$multiSelect.toggle', this.setClasses);
	}

	componentWillUnmount() {
		this.unsubSelectAll();
		this.unsubSelectAllFilter();
		this.unsubSelectNone();
		this.unsubToggle();
	}

	render() {
		var tools = this.props.tools;
		var classes = this.getClass();
		var total = tools.$multiSelect.getTotal();
		var canExport = Tools.AppService.getSelf().export;
		var hasData = (this.props.data || []).length;
		var hasExportAction = this.props.actions && this.props.actions.actions && this.props.actions.actions.export;
		var hasExport = !!(hasData && canExport);
		var toolsColumnClasses = this.props.template === 'leadRow' ? 'column-tools column-tools-sm' : 'column-tools';
		if (this.props.template === 'activityRow') {
			toolsColumnClasses += ' responsive';
		}
		var checkAllElem = null;

		var defaultOptions = {
			selectNone: true,
			selectAll: true,
			selectAllFilter: true
		};

		var options = tools.opts.multiSelectOptions || defaultOptions;

		var selectOptions = [];

		if (options.selectAllFilter) {
			var text = Tools.$translate('default.selectAll');
			if (total !== undefined && total !== null && !this.props.loading) {
				var displayTotal = total;

				if (tools && tools.$filter) {
					var numberFormat = tools.$filter('numberFormat');
					displayTotal = numberFormat(displayTotal);
				}

				text = text + ' (' + displayTotal + ')';
			}

			selectOptions.push({ onClick: this.selectAllFilter, text });
		}
		if (options.selectAll) {
			selectOptions.push({ onClick: this.selectAll, text: Tools.$translate('default.selectAllOnPage') });
		}
		if (options.selectNone) {
			selectOptions.push({ onClick: this.selectNone, text: Tools.$translate('default.deselectAll') });
		}

		if (this.props.length === total) {
			checkAllElem = (
				<div id="check-btn" className="btn btn-sm" disabled={!total ? true : false} onClick={this.selectToggle}>
					<b className={'Icon ' + classes} ref="indicator" />
				</div>
			);
		} else {
			checkAllElem = (
				<TableHeaderDropdown
					items={selectOptions}
					selected={tools.$multiSelect.allSelected || tools.$multiSelect.allSelectedFilter}
				/>
			);
		}

		return (
			<thead>
				<tr>
					{tools.opts.hasCheck ? (
						<th className="checkbox-column" key="th-check">
							<div className="th-inner">{checkAllElem}</div>
						</th>
					) : null}

					{this.props.columns.map(
						function (key) {
							var column;
							if (Tools.FilterHelper.isCustom(key)) {
								column = Tools.FilterHelper.getCustomConfig(key, tools.customFields);
								column.sortable = key;
							} else if (Tools.FilterHelper.isCategory(key)) {
								var category = _.find(this.props.categoryTypes, { id: parseInt(key.split('_')[1]) });
								column = {
									title: category ? category.name : '',
									field: key
								};
							} else if (Tools.FilterHelper.parseNested(key)) {
								column = {
									title: 'nested.' + Tools.FilterHelper.parseNested(key).child,
									field: key,
									sortable: key
								};
							} else if (Tools.FilterHelper.isAddress(key)) {
								var address = Tools.FilterHelper.getAddress(key);
								var fields = address.title.split('.');
								var type = fields.length === 3 ? fields[2] : fields[1];
								var field = fields.length === 3 ? fields[1] : 'address';

								column = {
									title: address.title,
									field: key,
									sortable: 'addresses.type_' + type + '.' + field
								};
							} else {
								column = this.props.columnTemplates[key];
							}
							if (column) {
								return (
									<ReactTemplates.TOOLS.tableTH
										key={'th-' + key}
										column={column}
										tools={tools}
										sort={this.sort}
										currentSorting={this.props.currentSorting}
									/>
								);
							}

							return null;
						}.bind(this)
					)}

					{tools.opts.editable ? (
						<th className={toolsColumnClasses} key="th-edit">
							{hasData && hasExportAction ? (
								hasExport ? (
									<div
										className="th-inner"
										style={{
											paddingRight: '47px',
											fontSize: '18px',
											cursor: 'pointer',
											color: '#4A90E2'
										}}
									>
										{ReactTemplates.TOOLS.withTooltip(
											<b
												data-test-id="file-download"
												ref="file-download"
												className="fa fa-file-excel-o"
												onClick={this.doExport.bind(this, options)}
											/>,
											Tools.$translate('default.export') || '',
											{
												placement: 'left',
												style: { display: 'block', height: '100%', marginLeft: '-10px' }
											}
										)}
									</div>
								) : (
									<div
										className="th-inner"
										style={{
											paddingRight: '47px',
											fontSize: '18px',
											cursor: 'not-allowed',
											color: '#9BAABE'
										}}
									>
										{ReactTemplates.TOOLS.withTooltip(
											<b
												data-test-id="file-download"
												ref="file-download"
												className="fa fa-file-excel-o"
											/>,
											Tools.$translate('default.noExportRights') || '',
											{
												placement: 'left',
												style: { display: 'block', height: '100%', marginLeft: '-10px' }
											}
										)}
									</div>
								)
							) : null}
							<div className="th-inner">
								{ReactTemplates.TOOLS.withTooltip(
									<b
										data-test-id="edit-columns"
										ref="cog"
										className="fa fa-cog edit-cog"
										onClick={tools.editColumns}
									/>,
									Tools.$translate('filters.editColumns') || '',
									{
										placement: 'left',
										style: { display: 'block', height: '100%', marginLeft: '-10px' }
									}
								)}
							</div>
						</th>
					) : (
						<th />
					)}
				</tr>
			</thead>
		);
	}
}

export default TableHead;

'use strict';

function whoCalledCtrl(num) {
	const startPage = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? 'react-root-todo' : 'tmpStart';

	const showNoResultsModal = function () {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('warningAlert', {
				icon: 'fa-user-times',
				title: 'noResult.contact',
				body: 'whoCalled.noContactsByPhone',
			})
			.finally(() => Tools.$state.go(startPage));
	};

	const showTooManyResultsModal = function (total) {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('warningAlert', {
				icon: 'fa-users',
				title: 'whoCalled.tooManyResults',
				body: Tools.$translate('whoCalled.tooManyResultsInfo', { total: total }),
			})
			.finally(() => Tools.$state.go(startPage));
	};

	const showResultsModal = function (contacts) {
		Tools.$upModal
			.open('list', {
				title: 'whoCalled.matchedContacts',
				columns: [
					{ title: 'default.name', value: 'name' },
					{ title: 'default.phone', value: 'phone' },
					{ title: 'default.cellPhone', value: 'cellPhone' },
				],
				data: contacts,
			})
			.then(function (row) {
				Tools.$state.go('contact.dashboard', { id: row.id });
			})
			.catch(() => Tools.$state.go(startPage));
	};

	const showErrorModal = function () {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('errorAlert', {
				icon: 'fa-times',
				title: 'default.error',
				body: 'whoCalled.anErrorOccured',
			})
			.finally(() => Tools.$state.go(startPage));
	};

	const search = function (number) {
		return Tools.Quicksearch.search(number, Date.now(), { type: 'contact' }).then(function (res) {
			return res.data.contact;
		});
	};

	// Wait for application
	// eslint-disable-next-line promise/catch-or-return
	Tools.AppService.loadedPromise.then(async () => {
		try {
			// parse number with googles i18n phone-number tool here
			// eslint-disable-next-line no-useless-escape
			const number = window.leodido.i18n.PhoneNumbers.formatNumber(num, 'SE', 4).replace(/\ /g, '');
			const res = await search(number);
			const total = res.metadata.total;
			if (total === 0) {
				// If no results, show no results modal
				showNoResultsModal();
			} else if (total === 1) {
				// Go to contact
				Tools.$state.go('contact.dashboard', { id: res.data[0].id });
			} else if (total > 5) {
				// Show modal if more than 5 contacts, we only show results up to five
				showTooManyResultsModal(total);
			} else {
				// Show list modal with results
				showResultsModal(res.data);
			}
		} catch (e) {
			showErrorModal();
		}
	});
}

angular.module('upsalesApp').config([
	'$stateProvider',
	function ($stateProvider) {
		$stateProvider.state('whoCalled', {
			url: '/who-called/:number',
			controller: [
				'$stateParams',
				function ($stateParams) {
					new whoCalledCtrl($stateParams.number);
				},
			],
		});
	},
]);

import {
	FILE_STORAGE_FETCHING,
	STOP_FILE_STORAGE_FETCHING,
	SET_FILE_STORAGE_FOLDER_SUCCESS,
	REMOVE_FILE_STORAGE_FOLDER_SUCCESS,
	RESET_STATE
} from 'Store/reducers/FileStorageAppsReducer';

const fileStorageFetching = () => ({ type: FILE_STORAGE_FETCHING });
const resetFileStorageStore = () => ({ type: RESET_STATE });
const stopFileStorageFetching = () => ({ type: STOP_FILE_STORAGE_FETCHING });
const setFileStorageFolderSuccess = value => ({ type: SET_FILE_STORAGE_FOLDER_SUCCESS, value });
const removeFileStorageFolderSuccess = value => ({ type: REMOVE_FILE_STORAGE_FOLDER_SUCCESS, value });

export const selectFolder = (integrationId, data) => {
	return dispatch => {
		dispatch(fileStorageFetching());
		return Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run({
				typeId: '',
				data,
				type: 'folders',
				integrationId
			})
			.then(res => {
				res.data.integrationId = integrationId;
				dispatch(setFileStorageFolderSuccess(res.data));
			})
			.catch(() => {
				dispatch(stopFileStorageFetching());
			});
	};
};

export const checkFolder = (integrationId, data) => {
	return dispatch => {
		dispatch(fileStorageFetching());
		return Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run({
				typeId: '',
				data,
				type: 'foldersCheck',
				integrationId
			})
			.then(res => {
				res.data.integrationId = integrationId;
				dispatch(setFileStorageFolderSuccess(res.data));
			})
			.catch(() => {
				dispatch(stopFileStorageFetching());
			});
	};
};

export const removeFolder = (integrationId, data) => {
	return dispatch => {
		dispatch(fileStorageFetching());
		return Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run({
				typeId: '',
				data,
				type: 'foldersRemove',
				integrationId
			})
			.then(res => {
				res.data.integrationId = integrationId;
				dispatch(removeFileStorageFolderSuccess(res.data));
			})
			.catch(() => {
				dispatch(stopFileStorageFetching());
			});
	};
};

export const resetFileStorageApps = () => {
	return dispatch => {
		dispatch(resetFileStorageStore());
	};
};

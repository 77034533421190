import React from 'react';
import { Icon, Headline, Text, Button } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import '../MailContentEditor/MailContentEditor.scss';
import { ColumnTypeName, DocumentTemplateState } from './DocumentTemplateEditor';

interface DocumentContentEditorEmptyProps {
	onSelect: (type: ColumnTypeName) => void;
	state: DocumentTemplateState;
}

const DocumentContentEditorEmpty = ({ onSelect, state }: DocumentContentEditorEmptyProps) => {
	const classes = new bemClass('DocumentTemplateEditorDesign');
	const { t } = useTranslation();
	const lang = {
		productTableDoesNotFit: t('admin.documentTemplateEditor.productTable.productTableDoesNotFit'),
		maxAmountOfProductTables: t('admin.documentTemplateEditor.productTable.maxAmountOfProductTables'),
		productTable: t('admin.documentTemplateEditor.productTable'),
		text: t('mail.text'),
		image: t('default.image'),
		selectContent: t('mail.selectContent')
	};
	// Only allow one product table in a document
	const containsProductTable = state.config.rows.some(row =>
		row.columns.some(column => column.type === 'PRODUCTTABLE')
	);
	return (
		<div className={classes.elem('empty').b()}>
			<Headline size="xs">{lang.selectContent}</Headline>

			<div className={classes.elem('empty-selector').b()}>
				<div>
					<Button color="white" onClick={() => onSelect('TEXT')}>
						<Icon name="align-center" />
						<Text>{lang.text}</Text>
					</Button>
				</div>
				<div>
					<Button color="white" onClick={() => onSelect('IMAGE')}>
						<Icon name="image" />
						<Text>{lang.image}</Text>
					</Button>
				</div>
				{state.selectedColumn && state.selectedColumn.size === 12 && !containsProductTable ? (
					<div>
						<Button color="white" onClick={() => onSelect('PRODUCTTABLE')}>
							<Icon name="list" />
							<Text>{lang.productTable}</Text>
						</Button>
					</div>
				) : containsProductTable ? (
					<div>
						{/* Temporary solution, until updated ButtonBox is available in Storybook */}
						{/* Max tables reached */}
						<Button disabled={true} color="grey-1" shadow="none">
							<Icon name="list" />
							<Text color="grey-10" italic={true}>
								{lang.productTable}
							</Text>
							<Text color="grey-10" italic={true} size="sm">
								{lang.maxAmountOfProductTables}
							</Text>
						</Button>
					</div>
				) : (
					<div>
						{/* Temporary solution, until updated ButtonBox is available in Storybook */}
						{/* Product table doesn't fit */}
						<Button disabled={true} color="grey-1" shadow="none" style={{ whiteSpace: 'normal' }}>
							<Icon name="list" />
							<Text color="grey-10" italic={true}>
								{lang.productTable}
							</Text>
							<Text color="grey-10" italic={true} size="sm">
								{lang.productTableDoesNotFit}
							</Text>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default DocumentContentEditorEmpty;

'use strict';

angular.module('domain.advancedSearch').directive('appointmentInfoSearchSection', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/domain/advanced-search/directives/appointmentInfoSearchSection.tpl.html?file'),
		scope: {
			Filters: '=filters',
			onChange: '&',
			onKeyup: '&'
		},
		controller: [
			'$scope',
			'AppService',
			'FeatureHelper',
			function ($scope, AppService, FeatureHelper) {
				$scope.inited = false;
				$scope.ngChange = function () {
					if ($scope.onChange) {
						$scope.onChange();
					}
				};

				$scope.ngKeyup = function (e) {
					if ($scope.onKeyup) {
						$scope.onKeyup({ $event: e });
					}
				};

				var init = function () {
					$scope.hasAppointmentOutcome = FeatureHelper.isAvailable(FeatureHelper.Feature.APPOINTMENT_OUTCOME);
					$scope.Filters.Description.comparisonType = 'Wildcard';
					$scope.Filters.Notes.comparisonType = 'Wildcard';
					$scope.inited = true;
				};

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var unWatchInit = $scope.$watch('Filters', function (filters) {
						if (filters !== undefined) {
							unWatchInit();
							init();
						}
					});
				});
			}
		]
	};
});

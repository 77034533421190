import { Icon } from '@upsales/components';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';

ReactTemplates.upFilters.components.listLeadSourcePage = window.ReactCreateClass({
	/* REACT METHODS */
	getDefaultProps: function () {
		return {
			minimumSearchKeyLength: 1,
			msBeforeSearch: 500
		};
	},
	getInitialState: function () {
		return {
			pendingAJAXrequest: true,
			itemSearchString: '',
			data: {},
			selectedData: [],
			showState: []
		};
	},
	UNSAFE_componentWillMount: function () {
		this.onSearch = _.debounce(this.onSearch, this.props.msBeforeSearch);

		var filter = this.props.filter;

		var dataFunction = Tools.$injector.invoke(
			filter.dataPromise,
			{},
			{ customerId: this.props.customerId, filterConfig: filter, filterName: this.props.filterName }
		);
		dataFunction(this.props.activeFilters)
			.then(this.handleNewData)
			.catch(error => logError(error, 'listLeadSourcePage dataFuction failed'));
	},
	componentDidMount: function () {
		ReactDOM.findDOMNode(this.refs['search-input']).focus();
	},
	isChecked: function (item) {
		var values = [];
		if (this.props.activeFilters[this.props.filterName]) {
			values = this.props.activeFilters[this.props.filterName].value;
		}

		var value = _.find(values, function (value) {
			if (!value.source) {
				return item.type === value.type;
			} else if (!value.value) {
				return item.source === value.source && item.type === value.type;
			} else {
				return item.value === value.value && item.source === value.source && item.type === value.type;
			}
		});

		return value ? true : false;
	},
	handleNewData: function (res) {
		var data = {};

		if (res.data.type.aggs) {
			res.data.type.aggs.doc_count = res.data.type.doc_count;
			res.data.type = res.data.type.aggs;
		}

		_.each(res.data.type.buckets, function (typeObj) {
			data[typeObj.key] = {};

			_.each(typeObj.source.buckets, function (sourceObj) {
				data[typeObj.key][sourceObj.key] = _.map(sourceObj.value.buckets, function (valueObj) {
					var item = {
						type: typeObj.key,
						source: sourceObj.key,
						value: valueObj.key,
						count: valueObj.doc_count
					};

					if (sourceObj.key === 'upsalesads' || sourceObj.key === 'upsalesmail') {
						if (valueObj.title && valueObj.title.buckets && valueObj.title.buckets.length) {
							item.title = valueObj.title.buckets[0].key;
						}
					}

					return item;
				});

				data[typeObj.key][sourceObj.key].unshift({
					type: typeObj.key,
					source: sourceObj.key,
					value: null,
					count: sourceObj.doc_count
				});
			});
		});

		if (res.data.extra) {
			if (res.data.extra.aggs) {
				res.data.extra.buckets = res.data.extra.aggs.buckets;
			}

			data['utm_campaign'] = {};

			_.each(res.data.extra.buckets, function (valueObj) {
				data['utm_campaign'][valueObj.key] = [
					{
						type: 'utm_campaign',
						source: valueObj.key,
						value: null,
						count: valueObj.doc_count
					}
				];
			});
		}

		var currentFilter = this.props.activeFilters[this.props.filterName];

		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			_.each(currentFilter.value, function (obj) {
				if (!data[obj.type]) {
					data[obj.type] = {};
					data[obj.type][obj.source] = [{ type: obj.type, source: obj.source, value: obj.value, count: 0 }];
				} else {
					if (!data[obj.type][obj.source]) {
						data[obj.type][obj.source] = [
							{ type: obj.type, source: obj.source, value: obj.value, count: 0 }
						];
					} else {
						var haveValue = _.find(data[obj.type][obj.source], function (value) {
							return value.value === obj.value;
						});

						if (haveValue === undefined) {
							data[obj.type][obj.source].push({
								type: obj.type,
								source: obj.source,
								value: obj.value,
								count: 0
							});
						}
					}
				}
			});
		}

		this.setState({
			pendingAJAXrequest: false,
			data: data
		});
	},
	onGoBack: function () {
		this.props.onPageChange(null, true);
	},
	onSelectAll: function () {
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: 'Equals',
			inactive: true,
			value: [],
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onClickSelectedItem: function (clickedValue, action) {
		this.onChange(clickedValue, action);
	},
	onChange: function (newValues, action) {
		var comparisonType = this.refs.isIsNot.getValue() ? 'NotEquals' : 'Equals';

		var oldFilter = this.props.activeFilters[this.props.filterName];
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: true,
			value: [],
			type: 'list'
		};

		if (oldFilter && oldFilter.value) {
			newFilter.value = oldFilter.value;
		}

		if (action === 'add') {
			newFilter.value = newFilter.value.concat(newValues);
		} else if (action === 'replace') {
			newFilter.value = newValues;
		} else {
			if (newValues.length) {
				var item = newValues[0];
				// Type
				if (!item.source && !item.value) {
					newFilter.value = _.filter(newFilter.value, function (value) {
						return !(item.type === value.type);
					});
				}
				// Source
				else if (!item.value) {
					var parentIsSelected = _.find(newFilter.value, function (value) {
						return !value.value && !value.source && item.type === value.type;
					});

					newFilter.value = _.filter(newFilter.value, function (value) {
						// Removes parent type and source
						return (
							!(item.source === value.source && item.type === value.type) &&
							!(!value.value && !value.source && item.type === value.type)
						);
					});

					if (parentIsSelected !== undefined) {
						var sourceValues = [];

						Object.keys(this.state.data[item.type]).forEach(function (source) {
							if (source !== item.source) {
								sourceValues.push({
									source: source,
									type: item.type
								});
							}
						});

						newFilter.value = newFilter.value.concat(sourceValues);
					}
				} else {
					newFilter.value = _.filter(newFilter.value, function (value) {
						return !(
							item.source === value.source &&
							item.type === value.type &&
							item.value === value.value
						);
					});
				}
			}
		}

		newFilter.value = _.unique(newFilter.value, function (value) {
			return value.type + '-' + value.source + '-' + value.value;
		});

		newFilter.inactive = newFilter.value.length > 0 ? false : true;

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onSearchKey: function (event) {
		var value = event.target.value;
		this.onSearch(value);
	},
	onSearch: function () {
		var searchString = ReactDOM.findDOMNode(this.refs['search-input']).value;

		this.setState({
			itemSearchString: searchString
		});
	},
	onToggleComperator: function (value) {
		var newFilter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var newComparisionType = value ? 'NotEquals' : 'Equals';

		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;

			this.props.onChange(newFilter, {
				action: 'add'
			});
		}
	},

	toggleHeader: function (item) {
		var toShow = this.state.showState;
		var index = _.findIndex(toShow, function (i) {
			return i.type === item.type && i.source === item.source;
		});

		if (index === -1) {
			toShow.push(item);
		} else {
			toShow.splice(index, 1);
		}

		this.setState({ showState: toShow });
	},

	/* RENDER */

	onSelectType: function (item, action) {
		this.onChange([{ type: item.type }], action);
	},
	onSelectSource: function (item, action) {
		this.onChange([{ type: item.type, source: item.source }], action);
	},
	onSelectValue: function (item, action) {
		this.onChange(item, action);
	},
	setFilter: function (currentFilter, checkHelperTypes, checkHelperSources) {
		var ref;
		_.each(currentFilter.value, function (filterValue) {
			if (filterValue.source && !filterValue.value) {
				ref = checkHelperTypes[filterValue.type];

				if (ref === undefined) {
					checkHelperTypes[filterValue.type] = 1;
				} else {
					checkHelperTypes[filterValue.type]++;
				}
			} else if (filterValue.value) {
				ref = checkHelperSources[filterValue.source];

				if (ref === undefined) {
					checkHelperSources[filterValue.source] = 1;
				} else {
					checkHelperSources[filterValue.source]++;
				}
			}
		});
	},

	render: function () {
		var self = this;
		var filter = this.props.filter;
		var currentFilter = this.props.activeFilters[this.props.filterName];

		var obj = this.state.data;

		var checkHelperTypes = {};
		var checkHelperSources = {};

		if (currentFilter && currentFilter.value) {
			self.setFilter(currentFilter, checkHelperTypes, checkHelperSources);
		}

		// TYPES
		var rows = Object.keys(obj)
			.sort()
			.map(function (type) {
				var nrSources = Object.keys(obj[type]).length;
				var typeIsChecked = nrSources > 0 && (checkHelperTypes[type] || 0) === nrSources ? true : false;

				// This is the case when the type is selected
				if (currentFilter && currentFilter.value) {
					var typeValueIsSelected = _.find(currentFilter.value, function (value) {
						return !value.value && !value.source && type === value.type;
					});

					if (typeValueIsSelected !== undefined) {
						typeIsChecked = true;
					}
				}
				// SOURCES
				var sources = Object.keys(obj[type])
					.filter(function () {
						if (!type) {
							return false;
						}

						var isVisible = _.findIndex(self.state.showState, function (ss) {
							return ss.type === type && !ss.source;
						});

						return isVisible !== -1 || self.state.itemSearchString.length > 0;
					})
					.map(function (source) {
						var sourceValuesIsDisabled = false;

						var isSourceOpen = _.findIndex(self.state.showState, function (item) {
							return item.type === type && item.source === source;
						});

						var length = _.reduce(
							obj[type][source],
							function (sum, value) {
								return value.value ? sum + 1 : sum;
							},
							0
						);

						// This is the case when all children is selected
						var sourceIsChecked = !length ? false : (checkHelperSources[source] || 0) === length;

						// This is the case when the source is selected
						if (currentFilter && currentFilter.value) {
							var sourceValueIsSelected = _.find(currentFilter.value, function (value) {
								return !value.value && source === value.source && type === value.type;
							});

							if (sourceValueIsSelected !== undefined) {
								sourceIsChecked = true;
								sourceValuesIsDisabled = true;
							}
						}

						if (typeIsChecked) {
							sourceIsChecked = true;
							sourceValuesIsDisabled = true;
						}

						var values = [];

						// VALUES
						if (self.state.itemSearchString.length > 0 || isSourceOpen !== -1) {
							values = obj[type][source]
								.filter(function (value) {
									if (value && value.value) {
										var matchString = value.value;

										if (value.source === 'upsalesads' || value.source === 'upsalesmail') {
											matchString = value.title || '';
										}

										return matchString.toLowerCase().indexOf(self.state.itemSearchString) > -1;
									} else {
										return false;
									}
								})
								.map(function (value) {
									var checked = self.isChecked(value);

									value.$depth = 2;

									var text = value.value;

									if (value.source === 'upsalesads' || value.source === 'upsalesmail') {
										text = value.title || '';
									}

									return React.createElement(ReactTemplates.upFilters.components.leadSourceRow, {
										isGroup: false,
										checked: checked,
										indentWhileChecked: true,
										disabled: sourceValuesIsDisabled,
										onChange: self.onSelectValue,
										showButton: false,
										item: value,
										text: text,
										value: value,
										key: value.type + '-' + value.source + '-' + value.value,
										tooltip: value.value && value.value.length > 35,
										count: value.count
									});
								});
						}

						var matchString = source;

						if (source === 'upsalesads') {
							matchString = Tools.$translate('leadSource.upsalesads');
						} else if (source === 'upsalesmail') {
							matchString = Tools.$translate('leadSource.upsalesmail');
						}

						if (
							!values.length &&
							matchString.toLowerCase().indexOf(self.state.itemSearchString.toLowerCase()) === -1
						) {
							return null;
						}

						var count = 0;

						var sourceObj = _.find(obj[type][source], function (value) {
							return value.value === null;
						});

						if (sourceObj) {
							count = sourceObj.count;
						}

						if (self.state.itemSearchString.length && values.length) {
							isSourceOpen = 1;
						}

						var sourceShowSelectIndicator = false;

						if (currentFilter && currentFilter.value) {
							sourceShowSelectIndicator =
								_.find(currentFilter.value, function (obj) {
									return obj.source === source || (!obj.source && obj.type === type);
								}) !== undefined;
						}

						var headerSource = React.createElement(ReactTemplates.upFilters.components.headerItem, {
							onChange: self.onSelectSource,
							item: { type: type, source: source },
							toggleHeader: self.toggleHeader,
							isOpen: isSourceOpen !== -1,
							haveChildren: length > 0,
							leadSources: Tools.LEAD_SOURCE,
							checked: sourceIsChecked,
							count: count,
							showActiveIndicator: sourceShowSelectIndicator
						});

						return (
							<div key={type + '-' + source}>
								{headerSource}
								{values}
							</div>
						);
					});

				var haveSources = Object.keys(obj[type]).length > 0;

				if (!haveSources && type.toLowerCase().indexOf(self.state.itemSearchString.toLowerCase()) === -1) {
					return null;
				}

				var isOpen = _.findIndex(self.state.showState, function (i) {
					return i.type === type && !i.source;
				});

				if (self.state.itemSearchString.length && sources.length) {
					isOpen = 1;
				}

				var typeShowSelectIndicator = false;

				if (currentFilter && currentFilter.value) {
					typeShowSelectIndicator =
						_.find(currentFilter.value, function (obj) {
							return obj.type === type;
						}) !== undefined;
				}

				var headerType = React.createElement(ReactTemplates.upFilters.components.headerItem, {
					onChange: self.onSelectType,
					item: { type: type, source: null },
					toggleHeader: self.toggleHeader,
					isOpen: isOpen !== -1,
					haveChildren: haveSources,
					checked: typeIsChecked,
					showActiveIndicator: typeShowSelectIndicator
				});

				return (
					<div key={type}>
						{headerType}
						{sources}
					</div>
				);
			});

		if (!rows.length) {
			var noResultsString = Tools.$translate('default.noResults');
			rows.push(
				<div key={'no-results-row'} className={'list-row list-row-item no-result-row'}>
					<span className={'list-text list-text-item'}>{noResultsString}</span>
				</div>
			);
		}

		var nrSelected = currentFilter && currentFilter.value ? currentFilter.value.length : 0;

		var checked = false;
		if (currentFilter && currentFilter.comparisonType === 'NotEquals') {
			checked = true;
		}

		var isIsNotString = Tools.$translate('filters.excludeSelected');
		var deselectAllString = ' ' + Tools.$translate('default.deselectAll');
		var defaultValue = this.props.dataCache.get(this.props.filterName + 'searchstring')
			? this.props.dataCache.get(this.props.filterName + 'searchstring')
			: '';
		var placeholder = filter.search
			? Tools.$translate('default.typeToSearch')
			: Tools.$translate('filters.doFilter');

		return (
			<div className="list-filter-page" key={this.props.filterName}>
				<div className="list-page">
					<div className="header">
						<button className="back-button" onClick={this.onGoBack}>
							<b className="fa fa-chevron-left" />
						</button>
						<div className="search-input-wrap">
							<input
								ref="search-input"
								className="search-input"
								type="text"
								placeholder={placeholder}
								defaultValue={defaultValue}
								onChange={this.onSearchKey}
							/>
							<b className="fa fa-search" />
						</div>

						{this.state.pendingAJAXrequest && (
							<i className="fa fa-refresh fa-spin fa-fw list-page-spinner" />
						)}

						<div className={'toolbar'}>
							<span style={{ marginLeft: '10px' }}>
								<ReactTemplates.upFilters.components.toggle
									checked={checked}
									onChange={this.onToggleComperator}
									ref={'isIsNot'}
								/>
							</span>
							<span style={{ paddingLeft: '5px' }}>{isIsNotString}</span>

							<button
								disabled={nrSelected === 0}
								className={
									'btn up-btn no-shadow btn-link btn-bright-blue pull-right toolbar-deselect-all-button'
								}
								onClick={this.onSelectAll}
							>
								<i className="fa fa-times" aria-hidden="true" /> {deselectAllString}
							</button>
						</div>
					</div>

					<div className="list-body lead-sources">
						{this.state.pendingAJAXrequest ? (
							<div key={'pending-request'} className={'list-row list-row-item'}>
								<i
									style={{ position: 'relative', left: '48%' }}
									className="fa fa-refresh fa-spin fa-lg fa-fw list-page-spinner"
								/>
							</div>
						) : (
							rows
						)}
					</div>
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.components.headerItem = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	toggleItem: function () {
		this.props.toggleHeader(this.props.item);
	},
	selectGroup: function (e) {
		e.stopPropagation();

		var action = e.target.checked ? 'add' : 'remove';

		this.props.onChange(this.props.item, action);
	},
	render: function () {
		let iconElement = null;

		const renderOpts = Tools.LEAD_SOURCE.getConfig(this.props.item, { iconType: 'none', iconProps: {} });
		if (this.props.item.type === 'direct') {
			renderOpts.title = this.props.item.source ?? renderOpts.title;
		}

		if (renderOpts.iconType !== 'none') {
			iconElement =
				renderOpts.iconType === 'img' ? <img {...renderOpts.iconProps} /> : <Icon {...renderOpts.iconProps} />;
			if (renderOpts.url) {
				iconElement = <span style={{ marginRight: '3px' }}>{iconElement}</span>;
			}
		}

		const arrow = !this.props.isOpen ? 'fa fa-angle-down' : 'fa fa-angle-up';
		let classes = 'list-row list-row-item';
		classes += this.props.isOpen ? ' showing' : '';
		classes += this.props.item.source ? ' source-row' : ' type-row';

		if (this.props.showActiveIndicator) {
			classes += ' checked';
		}

		let element;

		const title = this.props.item.source ? (
			<div className="source-text">{renderOpts.title}</div>
		) : (
			<span className="source-text">{renderOpts.title}</span>
		);

		if (renderOpts.title.length > 36) {
			element = ReactTemplates.TOOLS.withTooltip(title, renderOpts.title, {
				placement: 'top',
				delayShow: 500
			});
		} else {
			element = title;
		}
		const filter = getAngularModule('$filter');

		return (
			<div onClick={this.props.haveChildren ? this.toggleItem : null} className={classes}>
				<input
					type="checkbox"
					checked={this.props.checked}
					style={{ marginRight: '5px' }}
					onClick={this.selectGroup}
				/>
				{this.props.haveChildren ? <i className={arrow} /> : null}
				{iconElement}
				{element}
				{this.props.count != null ? (
					<span className="source-count text-ellipsis">{filter('numberFormat')(this.props.count)}</span>
				) : null}
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.listLeadSource = window.ReactCreateClass({
	getInitialState: function () {
		return {
			data: null
		};
	},
	componentDidMount: function () {
		var promise = null;

		this.setState({
			data: promise
		});
	},
	toggleExpanded: function () {
		this.props.onPageChange(
			{
				component: ReactTemplates.upFilters.components.listLeadSourcePage,
				getTitle: function () {
					return Tools.$translate(this.props.filter.title);
				}.bind(this),
				props: {
					data: this.state.data,
					filterName: this.props.filterName,
					filter: this.props.filter
				}
			},
			false
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			data: null
		});
		this.props.onChange(
			{
				filterName: this.props.filterName
			},
			{
				action: 'remove'
			}
		);
		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var filter = this.props.filter;
		var valueString = '';

		if (filter.value && filter.value.length) {
			valueString = filter.value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
		} else {
			valueString = Tools.$translate('default.all');
		}
		return valueString;
	},
	render: function () {
		var filter = this.props.filter;
		var active = filter.value && filter.value.length > 0;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>
			</div>
		);
	}
});

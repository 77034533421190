import React from 'react';
import T from 'Components/Helpers/translate';
import { Text, Headline, Card, Title, ButtonBox, Link, Tooltip, Help, Flex, Block } from '@upsales/components';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { Fade } from 'App/components/animations/index';
import TranslateField from 'Components/Admin/TranslateField';
import StandardField from 'Resources/StandardField';
import logError from 'App/babel/helpers/logError';

import {
	setDecisionMakersType,
	setFields,
	saveDecisionMakers,
	setDecisionMakersActive
} from 'Store/reducers/SalesCoachReducer';
import { cloneDeep } from 'lodash';

import './DecisionMakersPro.scss';

type Fields = Array<{ rowId: number; active: boolean; activeStages: number[] }>;

type Props = {
	reject: () => void;
	saveDecisionMakers: () => void;
	setDecisionMakersType: (type: string) => void;
	setFields: (fields: Fields) => void;
	setDecisionMakersActive: (value: boolean) => void;
	decisionMakersType: string;
	id: number;
	fields: Fields;
};

type State = {
	fields: Fields;
	decisionMakersType: string;
};

const mapStateToProps = (state: any) => ({
	decisionMakersType: state.SalesCoachReducer.decisionMakersPro.typeOfDecisionMakers,
	fields: state.SalesCoachReducer.decisionMakersPro.fields,
	id: state.SalesCoachReducer.id
});

const mapDispatchToProps = { setDecisionMakersType, setFields, saveDecisionMakers, setDecisionMakersActive };

class DecisionMakersPro extends React.Component<Props, State> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			cancel: T('default.cancel'),
			save: T('default.save'),
			readMore: T('default.readMore'),
			decisionMakers: T('admin.newSalesProcess.decisionMakers'),
			talkingToTheRightPerson: T('admin.newSalesProcess.talkingToTheRightPerson'),
			categoriseDecisionMakers: T('admin.newSalesProcess.categoriseDecisionMakers'),
			stakeholders: T('admin.newSalesProcess.stakeholders'),
			stakeholdersText: T('admin.newSalesProcess.stakeholdersText'),
			titleCategories: T('admin.newSalesProcess.titleCategories'),
			titleCategoriesText: T('admin.newSalesProcess.titleCategoriesText'),
			stakeholdersDescription: T('admin.newSalesProcess.stakeholdersDescription'),
			selectTitleBeforeSaving: T('admin.newSalesProcess.selectTitleBeforeSaving'),
			selectStakeholderBeforeSaving: T('admin.newSalesProcess.selectStakeholderBeforeSaving')
		};
		this.state = {
			fields: cloneDeep(p.fields),
			decisionMakersType: cloneDeep(p.decisionMakersType)
		};
	}

	flipActivateTag = async (tagId: number) => {
		const { fields } = this.state;
		const idx = fields.findIndex(field => field.rowId === tagId);
		if (idx === -1) {
			fields.push({ rowId: tagId, active: true, activeStages: [] });
		} else {
			fields[idx].active = !fields[idx].active;
		}
		this.setState({ fields });
		await this.activateTitleCategoryIfInactive();
	};

	activateTitleCategoryIfInactive = async () => {
		const metadata = Tools.AppService.getMetadata();
		if (!metadata.standardFields.Contact.TitleCategory?.active) {
			const titleCategoryField = {
				...metadata.standardFields.Contact.TitleCategory,
				active: true
			};

			try {
				await StandardField.save(titleCategoryField);
				metadata.standardFields.Contact.TitleCategory.active = true;
				Tools.AppService.setMetadata(metadata);
			} catch (e) {
				logError(e, 'Failed to activate title category');
			}
		}
	};

	haveNoTitlesSelected() {
		const someAreActive = this.state.fields.some(field => field?.active);
		return !someAreActive;
	}

	render() {
		const { reject, setDecisionMakersType, setFields, id, saveDecisionMakers, setDecisionMakersActive } =
			this.props;

		const { fields, decisionMakersType } = this.state;

		const usingTitleCategories = decisionMakersType === 'titleCategories';
		const classNames = new BemClass('DecisionMakersPro');

		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.decisionMakers}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={() => {
										reject();
									}}
									supertitle={undefined}
									className={undefined}
									noIcon
									next={false}
								/>
								<Tooltip
									title={
										usingTitleCategories
											? this.lang.selectTitleBeforeSaving
											: this.lang.selectStakeholderBeforeSaving
									}
									disabled={!this.haveNoTitlesSelected()}
								>
									<EditorHeaderButton
										title={this.lang.save}
										supertitle={undefined}
										disabled={this.haveNoTitlesSelected()}
										onClick={() => {
											setDecisionMakersActive(true);
											setFields(fields);
											setDecisionMakersType(decisionMakersType);
											saveDecisionMakers();
											reject();
										}}
										next
										className="DecisionMakers__EditorHeaderButton"
										noIcon={false}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Card>
					<div className={classNames.elem('content').b()}>
						<Headline>{this.lang.decisionMakers}</Headline>
						<Text color="grey-11">{this.lang.talkingToTheRightPerson}</Text>

						<Block space="mtxl">
							<Title size="lg">{this.lang.categoriseDecisionMakers}</Title>
						</Block>
						<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
							<ButtonBox
								selected={usingTitleCategories}
								onClick={() => {
									this.setState({ fields: [], decisionMakersType: 'titleCategories' });
								}}
								title={this.lang.titleCategories}
								subtitle={this.lang.titleCategoriesText}
							/>
							<ButtonBox
								selected={!usingTitleCategories}
								onClick={() => {
									this.setState({ fields: [], decisionMakersType: 'stakeholders' });
								}}
								title={this.lang.stakeholders}
								subtitle={this.lang.stakeholdersText}
							/>
						</Flex>

						{usingTitleCategories ? (
							<Card borderRadius space="mtl">
								<TranslateField
									fieldName={'titlecategory'}
									editMode={true}
									shownInStages
									setFields={(fields: Fields) => this.setState({ fields })}
									fields={fields}
									activate={this.flipActivateTag}
								/>
							</Card>
						) : (
							<Block space="mtl">
								<Text color="grey-11">{this.lang.stakeholdersDescription}</Text>
								<Help articleId={1006} sidebar>
									<Link>{this.lang.readMore}</Link>
								</Help>
								<Card borderRadius space="mtl">
									<TranslateField
										fieldName={'stakeholder'}
										editMode={true}
										shownInStages
										setFields={(fields: Fields) => this.setState({ fields })}
										activate={this.flipActivateTag}
										fields={fields}
										removeFromFields={(tagId: number) =>
											this.setState({
												fields: fields.filter(field => field.rowId !== tagId)
											})
										}
										salesCoachId={id}
									/>
								</Card>
							</Block>
						)}
					</div>
				</Flex>
			</Fade>
		);
	}
}
export const detached = DecisionMakersPro;

const Component = connect(mapStateToProps, mapDispatchToProps)(DecisionMakersPro);

export default Component;

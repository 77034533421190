import React from 'react';
import './FinancialTab.scss';
import { Icon, Text, Tabs, Tab, Card, Row, Column, Title, Loader } from '@upsales/components';
import FinancialChart from './FinancialChart';
import FinancialCard from './FinancialCard';
import bemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import BillingAddonCard, { NEXT_STEPS } from '../Billing/BillingAddonCard';
import ProvisioningResource from 'App/babel/resources/Provisioning';
import { makeCancelable } from 'App/babel/helpers/promise';
import { Provider } from 'react-redux';
import store from 'Store';
import logError from 'App/babel/helpers/logError';
import BillingAddonBanner from 'App/components/BillingAddonBanner';
import T from 'Components/Helpers/translate';

class FinancialTab extends React.Component {
	alias = 'PROSPECTING_PRO_SIGNALS';
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.showPlaceholder = !Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
		this.currency = this.props.companyData?.[0]?.nAccountCurrencyInfo || 'SEK';
		this.balanceRef = React.createRef();
		this.incomeRef = React.createRef();
		this.keyRef = React.createRef();
		this.state = {
			tab: 'incomeStatement',
			sticky: false,
			width: null,
			availableAddons: [],
			failedToFetchAddons: false
		};
		this.turnover = [];
		this.result = [];
		this.years = [];
		this.columns = [{ title: '' }];
		if (this.props.companyData && this.props.companyData.length !== 0) {
			this.sortedAccounts = this.props.companyData.sort((a, b) => (moment(a.dateTo) < moment(b.dateTo) ? 1 : -1));
			if (this.sortedAccounts.length > 5) {
				this.sortedAccounts = this.sortedAccounts.slice(0, 5);
			}
			for (const account of this.sortedAccounts) {
				this.turnover.push(account.plNetOperatingIncome * 1000);
				this.result.push(account.plOperatingResult * 1000);
				const currentYear = moment(account.dateTo).format('YYYY');
				this.years.push(currentYear);
				this.columns.push({ title: currentYear });
			}
		}
		this.scrollDiff = 60;
		this.stickyPos = this.showPlaceholder ? 440 : 185;

		this.lang = {
			revenue: t('financials.revenue'),
			keyFigures: t('financials.keyFigures'),
			incomeStatement: t('financials.incomeStatement'),
			balanceSheet: t('financials.balanceSheet'),
			annualResult: t('financials.annualResult', { year: this.years[0] })
		};
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll.bind(this), true);
		if (!Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CEO_ON_BOARD')) {
			this.getPromise = makeCancelable(ProvisioningResource.getAvailableAddons());
			this.getPromise.promise
				.then(res => res.data)
				.then(res => this.setState({ availableAddons: res }))
				.catch(e => {
					this.setState({ failedToFetchAddons: true });
					logError(e, 'Failed fetch addons');
				});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll.bind(this), true);
		if (this.getPromise) {
			this.getPromise.cancel();
		}
	}
	handleScroll() {
		const scrollTop = window.pageYOffset;

		if (this.state.tab !== 'incomeStatement' && scrollTop < this.balanceRef.current?.offsetTop - this.scrollDiff) {
			this.setState({
				tab: 'incomeStatement'
			});
		} else if (
			this.state.tab !== 'balanceSheet' &&
			scrollTop >= this.balanceRef.current?.offsetTop - this.scrollDiff &&
			scrollTop < this.keyRef.current?.offsetTop - this.scrollDiff
		) {
			this.setState({
				tab: 'balanceSheet'
			});
		} else if (this.state.tab !== 'keyFigures' && scrollTop >= this.keyRef.current?.offsetTop - this.scrollDiff) {
			this.setState({
				tab: 'keyFigures'
			});
		}
		if (!this.state.sticky && scrollTop > this.stickyPos) {
			const element = document.getElementById('Tabs');
			if (!element) {
				return;
			}
			const width = element.getBoundingClientRect().width;
			this.setState({ sticky: true, width });
		} else if (this.state.sticky && scrollTop <= this.stickyPos) {
			this.setState({ sticky: false });
		}
	}

	onChangeTab(tab) {
		switch (tab) {
			case 'balanceSheet':
				window.scrollTo({
					top: this.balanceRef.current.offsetTop - this.scrollDiff,
					behavior: 'smooth',
					block: 'start'
				});
				break;
			case 'incomeStatement':
				window.scrollTo({
					top: this.incomeRef.current.offsetTop - this.scrollDiff,
					behavior: 'smooth',
					block: 'start'
				});
				break;
			case 'keyFigures':
				window.scrollTo({
					top: this.keyRef.current.offsetTop - this.scrollDiff,
					behavior: 'smooth',
					block: 'start'
				});
				break;
		}
	}
	formattedValue(value) {
		const formatted = Tools.$filter('currencyFormat')(value, this.currency);
		return [formatted.replace(this.currency, ''), this.currency];
	}

	render() {
		if (
			(!this.props.companyData && !this.showPlaceholder) ||
			(!this.showPlaceholder && this.props.companyData && this.props.companyData.length === 0) ||
			!this.props.headquarters
		) {
			return null;
		}

		const prospectingProAddon = _.find(this.state.availableAddons, addon => {
			return addon.alias === this.alias;
		});

		const classNames = new bemClass('FinancialTab');
		let formattedTurnoverValue = null,
			formattedTurnoverUnit = null,
			formattedResultValue = null,
			formattedResultUnit = null;
		if (!this.showPlaceholder) {
			[formattedTurnoverValue, formattedTurnoverUnit] = this.formattedValue(this.turnover[0]);
			[formattedResultValue, formattedResultUnit] = this.formattedValue(this.result[0]);
		}
		return (
			<div className={classNames.b()}>
				{this.showPlaceholder ? (
					Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CEO_ON_BOARD') ? (
						<div className={classNames.elem('addon-banner').b()}>
							<BillingAddonBanner
								alias={this.alias}
								chipText={T('admin.billing.addon.PROSPECTING_PRO.name')}
								text={T('prospecting.financialDataDescription')}
								title={T('prospecting.showFinancialDataFor', { companyName: this.props.accountName })}
								location="clientFinancials"
								nextSteps={[
									{
										title: T('prospecting.showFinancialDataFor', {
											companyName: this.props.accountName
										}),
										// reloading the page is the easiest way to update the page right now since everything is fetched from the angular controller
										action: () =>
											Tools.$state.go(Tools.$state.current.name, Tools.$state.params, {
												reload: true
											})
									},
									...(NEXT_STEPS[this.alias] || [])
								]}
							></BillingAddonBanner>
						</div>
					) : prospectingProAddon ? (
						<div className={classNames.elem('addon').b()}>
							<Provider store={store}>
								<BillingAddonCard
									key={prospectingProAddon.alias}
									addon={prospectingProAddon}
									bought={false}
									viewPlan={() => {}}
									location="clientFinancials"
								/>
							</Provider>
						</div>
					) : this.state.failedToFetchAddons ? null : (
						<div className={classNames.elem('addon').b()}>
							<Loader size="sm" />
						</div>
					)
				) : null}
				{!this.showPlaceholder ? (
					<React.Fragment>
						<Card color={this.showPlaceholder ? 'grey-1' : 'white'}>
							<Row className={classNames.elem('finacialInformation').b()}>
								<Column className={classNames.elem('keyFigures').b()}>
									<div className={classNames.elem('revenue').b()}>
										<Text color="grey-10">
											<Icon
												name="square"
												color={this.showPlaceholder ? 'grey-5' : 'bright-blue'}
											/>
											{this.lang.revenue}
										</Text>
										<div>
											{this.showPlaceholder ? (
												<Text
													className={classNames.elem('greyLineNumber').b()}
													color="grey-5"
													size="xl"
													bold={true}
												>
													{'------'}
												</Text>
											) : (
												<Title className={classNames.elem('bigNumbers').b()} size="lg">
													{formattedTurnoverValue}
												</Title>
											)}
											<Text
												className={classNames.elem('smallUnits').b()}
												size="sm"
												color="grey-10"
											>
												{this.showPlaceholder ? null : formattedTurnoverUnit}
											</Text>
										</div>
									</div>
									<div>
										<Text color="grey-10">
											<Icon
												name="square"
												color={
													this.showPlaceholder
														? 'grey-5'
														: formattedResultValue.indexOf('-') !== -1
														? 'red'
														: 'bright-green'
												}
											/>
											{this.lang.annualResult}
										</Text>
										<div>
											{this.showPlaceholder ? (
												<Text
													className={classNames.elem('greyLineNumber').b()}
													color={'grey-5'}
													size="xl"
													bold={true}
												>
													{'------'}
												</Text>
											) : (
												<Title className={classNames.elem('bigNumbers').b()} size="lg">
													{formattedResultValue}
												</Title>
											)}
											<Text
												className={classNames.elem('smallUnits').b()}
												size="sm"
												color="grey-10"
											>
												{this.showPlaceholder ? null : formattedResultUnit}
											</Text>
										</div>
									</div>
								</Column>
								<Column>
									<FinancialChart
										placeholder={this.showPlaceholder}
										turnover={this.turnover}
										result={this.result}
										years={this.years}
										currency={this.currency}
									/>
								</Column>
							</Row>
							<Tabs
								id="Tabs"
								style={this.state.sticky ? { width: this.state.width } : null}
								className={
									this.state.sticky
										? classNames.elem('tabs').elem('sticky').b()
										: classNames.elem('tabs').elem('normal').b()
								}
								selected={this.state.tab}
								onChange={tab => this.onChangeTab(tab)}
							>
								<Tab
									key="1"
									id="incomeStatement"
									className={classNames.elem('tabs').mod({ grey: this.showPlaceholder }).b()}
								>
									{this.lang.incomeStatement}
								</Tab>
								<Tab
									key="2"
									id="balanceSheet"
									className={classNames.elem('tabs').mod({ grey: this.showPlaceholder }).b()}
								>
									{this.lang.balanceSheet}
								</Tab>
								<Tab
									key="3"
									id="keyFigures"
									className={classNames.elem('tabs').mod({ grey: this.showPlaceholder }).b()}
								>
									{this.lang.keyFigures}
								</Tab>
							</Tabs>
							{this.state.sticky ? (
								<React.Fragment>
									<div
										className={classNames
											.elem('tabs')
											.elem('filler')
											.mod({ grey: this.showPlaceholder })
											.b()}
									></div>
									<div className={classNames.elem('tabs').elem('placeholder').b()}></div>
								</React.Fragment>
							) : null}
						</Card>

						<FinancialCard
							type="incomeStatement"
							accounts={this.sortedAccounts}
							years={this.years}
							reference={this.incomeRef}
							showPlaceholder={this.showPlaceholder}
							currency={this.currency}
						/>
						<FinancialCard
							type="balanceSheet"
							accounts={this.sortedAccounts}
							years={this.years}
							reference={this.balanceRef}
							showPlaceholder={this.showPlaceholder}
							currency={this.currency}
						/>
						<FinancialCard
							type="keyFigures"
							accounts={this.sortedAccounts}
							years={this.years}
							reference={this.keyRef}
							showPlaceholder={this.showPlaceholder}
							currency={this.currency}
						/>
					</React.Fragment>
				) : null}
			</div>
		);
	}
}
FinancialTab.propTypes = {
	accountName: PropTypes.string,
	companyData: PropTypes.array,
	revenue: PropTypes.number,
	headquarters: PropTypes.number
};
export default FinancialTab;

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('Category', [
	'$resource',
	'$q',
	'CategoryAtributes',
	'URL',
	'API',
	'ResourceHelper',
	'AppService',
	function Category($resource, $q, CategoryAtributes, URL, API, ResourceHelper, AppService) {
		var Attribute = CategoryAtributes().getAll();

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'category',
			createSuccessBody: 'saved.category',
			updateSuccessBody: 'updated.category',
			deleteSucccessBody: 'deleted.category',
			createErrorBody: 'saveError.category',
			updateErrorBody: 'saveError.category',
			deleteErrorBody: 'deleteError.category'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Category = null;
				instance.type = null;

				Category = $resource(
					URL + API + customer + '/:categoryType/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.getType = function () {
					switch (instance.type) {
						case 'client':
						case 'account':
							return 'clientCategories';
						case 'contact':
							return 'contactCategories';
						case 'product':
							return 'productCategories';
					}
				};

				instance.find = function (filter, options) {
					if (!instance.type) {
						throw new Error('No type set for category resource');
					}

					var defered = $q.defer();
					var params = angular.extend(filter, options);

					params.categoryType = instance.getType();

					Category.get(params, function (response) {
						defered.resolve(response);
					});

					instance.type = null;

					return defered.promise;
				};

				instance.get = function (id) {
					return Category.get({ id: id }).$promise(
						function (response) {
							return response.data;
						},
						function (response) {
							return response.status;
						}
					);
				};

				instance.save = function (category, options) {
					var defered = $q.defer();

					if (!category) {
						return defered.reject('No category');
					}

					if (category.id) {
						Category.update(
							{ id: category.id, categoryType: instance.getType() },
							category,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Category.create(
							{ categoryType: instance.getType() },
							category,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							var categories = AppService.getCategories(instance.type);
							var index = _.findIndex(categories, { id: res.data.id });

							if (index > -1) {
								categories[index] = _.cloneDeep(res.data);
							} else {
								categories.unshift(_.cloneDeep(res.data));
							}

							AppService.setCategories(instance.type, categories);
							AllIWantDataCache.clearData();

							helper.onSave(options, res.data, !category.id);
						})
						.catch(function (err) {
							if (_.get(err, 'data.error.errorCode') === 25) {
								helper.errorNotification(
									'default.error',
									'default.nameAlreadyInUse',
									null,
									!category.id
								);
								options = options || {};
								options.skipErrorNotification = true;
							}
							helper.onSaveError(options, !category.id);
							return $q.reject(err);
						});

					return defered.promise;
				};

				instance.setType = function (type) {
					instance.type = type;
					return instance;
				};

				instance.delete = function (category, options) {
					return Category.delete({ id: category.id, categoryType: instance.getType() })
						.$promise.then(function (res) {
							var categories = AppService.getCategories(instance.type);
							categories = _.filter(categories, function (_category) {
								return _category.id !== category.id;
							});
							AppService.setCategoryTypes(instance.type, categories);
							AllIWantDataCache.clearData();

							helper.onDelete(options, category);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options);
							return $q.reject(err);
						});
				};

				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);

import type Category from 'App/resources/Model/Category';
import type CustomField from 'App/resources/Model/CustomField';
import type ActivityType from 'App/resources/Model/ActivityType';

import _ from 'lodash';
import moment from 'moment';

let lang: { [key: string]: string } | null = null;

const getLang = () => {
	if (!lang) {
		const t = Tools.$translate;

		const add = t('default.add');
		const remove = t('default.remove');
		const campaigns = t('default.campaigns').toLowerCase();
		const cateogries = t('default.categories').toLowerCase();
		const segment = t('default.segment').toLowerCase();

		lang = {
			Notes: t('default.notes'),
			Description: t('default.description'),
			ActivityDelayInDays: t('activity.createAfter'),
			ActivityType: t('default.activityType'),
			Date: t('default.date'),
			Project: t('default.project'),
			User: t('default.user'),
			Role: t('default.role'),
			AddSegments: `${add} ${segment}`,
			AddProject: `${add} ${campaigns}`,
			RemoveProject: `${remove} ${campaigns}`,
			Unsubscribe: t('default.unsubFromMassMail'),
			Categories: `${add} ${cateogries}`,
			AddCategories: `${add} ${cateogries}`,
			RemoveCategories: `${remove} ${cateogries}`,
			NewMarketScore: t('default.newMarketEvent'),
			today: t('tag.general.today'),
			action: t('flow.action'),
			edit: t('default.edit'),
			assignedUser: t('automationTerms.assignedUser'),
			AccountManager: t('default.accountManager'),
			day: t('calendar.day').toLowerCase(),
			days: t('calendar.days').toLowerCase(),
			yes: t('default.yes'),
			no: t('default.no'),
			sendEmail: t('automationAction.SendMail'),
			AssignToManager: t('automationTerms.assignToManager'),
			currentUser: t('tag.general.currentuser'),
			JourneyStep: t('default.journeyStep'),
			regBy: t('default.regBy'),
			clearfield: t('tag.general.clearfield'),
			Name: t('default.name')
		};
	}
	return lang;
};

const getCustomField = (tag: string): CustomField | undefined => {
	const AppService = Tools.AppService;
	const parts = tag.replace(/[{}]/g, '').split(/[._]/g);
	const type =
		parts[0].toLowerCase() === 'client'
			? 'account'
			: (parts[0].toLowerCase() as Parameters<(typeof Tools)['AppService']['getCustomFields']>[0]);
	const id = parseInt(parts[2]);

	const customFields = AppService.getCustomFields(type);
	return _.find(customFields, { id: id });
};

type ActionEntity = 'updateClientPreview' | 'account' | 'updateContactPreview' | 'contact' | 'activity';

export type PropertyType = {
	name: string;
	value: string | number | number[] | boolean | null;
};
type IdNameType = {
	id: number | string;
	name: string;
};

export type RelationDataType = {
	segment?: IdNameType[];
	campaign?: IdNameType[];
};

const format = (entity: ActionEntity, property: PropertyType, relationData: RelationDataType) => {
	const lang = getLang();
	const AppService = Tools.AppService;
	const { value, name } = property;
	const valueString = typeof value === 'string' ? value : String(value);

	if (name.indexOf('custom_') > -1) {
		const customField = getCustomField(name);
		let cfValue = value;

		if (customField && customField.datatype === 'Boolean') {
			cfValue = value ? lang.yes : lang.no;
		} else if (typeof cfValue === 'string' && cfValue.length > 50) {
			cfValue = `${cfValue.substring(0, 50)}...`;
		} else if (customField && customField.datatype === 'Date') {
			if (value === '{{General.Today}}') {
				cfValue = lang.today;
			} else if (value === '{{General.ClearField}}') {
				cfValue = lang.clearfield;
			} else if (typeof value === 'string' && value.indexOf('custom_') > -1) {
				const targetCustomFieldName = value.replace(/[{}]/g, '');
				const targetCustomField = getCustomField(targetCustomFieldName);
				cfValue = targetCustomField?.name || '';
			} else if (typeof value === 'string') {
				cfValue = moment(value).format('L');
			}
		} else if (customField && ['User', 'Users'].includes(customField.datatype)) {
			if (typeof value === 'string' && value.length) {
				const users = AppService.getUsers();
				const userIds = value.split(',').map(id => parseInt(id));
				const userNames = userIds.reduce<string[]>((userNames, userId) => {
					const user = users.find(user => user.id === userId);
					if (user) {
						userNames.push(user.name);
					}
					return userNames;
				}, []);
				cfValue = userNames.join(', ');
			} else {
				cfValue = '';
			}
		}

		return {
			title: customField ? customField.name : '',
			text: cfValue
		};
	}

	switch (name) {
		case 'ActivityType': {
			const activityTypes: ActivityType[] = AppService.getActivityTypes();
			const activityType = _.find(activityTypes, { id: value });

			return {
				title: lang[name],
				text: activityType ? activityType.name : ''
			};
		}
		case 'Date': {
			let text;
			const offset = valueString.replace(/[{}]/g, '').split(':')[1];

			if (valueString.indexOf('Today') > -1) {
				text = lang.today;
			} else {
				const fixedValue = valueString.substring(0, valueString.indexOf(':')) + '}}';
				const customField = getCustomField(fixedValue);
				text = customField ? customField.name : '';
			}

			return {
				title: lang[name],
				text: `${text} + ${offset} ${offset === '1' ? lang.day : lang.days}`
			};
		}
		case 'AddSegments': {
			const selectedSegments = (relationData && relationData.segment ? relationData.segment : []).filter(
				segment => valueString.indexOf(String(segment.id)) > -1
			);

			return {
				title: lang[name],
				text: selectedSegments.map(segment => segment.name).join(', ')
			};
		}
		case 'Project':
		case 'RemoveProject':
		case 'AddProject': {
			const selectedCampaigns = (relationData && relationData.campaign ? relationData.campaign : []).filter(
				campaign => {
					if (typeof value === 'number') {
						return value === campaign.id;
					} else {
						return valueString.indexOf(String(campaign.id)) > -1;
					}
				}
			);

			return {
				title: lang[name],
				text: selectedCampaigns.map(campaign => campaign.name).join(', ')
			};
		}
		case 'Categories':
		case 'RemoveCategories':
		case 'AddCategories': {
			if (entity === 'activity') {
				// This will never happen, but as long as what properties are allowed for each entity is typed this is needed
				return { title: lang[name], text: '' };
			}

			const categories: Category[] = AppService.getCategories(
				{
					updateClientPreview: 'account',
					account: 'account',
					updateContactPreview: 'contact',
					contact: 'contact'
				}[entity]
			);

			const selectedCategories = categories.filter(category => valueString.indexOf(String(category.id)) > -1);

			return {
				title: lang[name],
				text: selectedCategories.map(category => category.name).join(', ')
			};
		}
		case 'AccountManager':
		case 'User': {
			let text;

			if (typeof value === 'string') {
				text = {
					'{{Client.UserId}}': lang.AccountManager,
					'{{Client.AssignedUserId}}': lang.assignedUser,
					'{{General.CurrentUserId}}': lang.currentUser,
					'{{Contact.RegById}}': lang.regBy,
					'{{Client.RegById}}': lang.regBy
				}[value];
			}

			if (!text) {
				const users = AppService.getUsers();
				const user: { name: string } | undefined = _.find(users, { id: parseInt(String(value)) });
				text = user ? user.name : '';
			}

			return {
				title: lang[name],
				text: text
			};
		}
		case 'Role': {
			const roles = AppService.getRoles();
			const role: { name: string } | undefined = _.find(roles, { id: parseInt(String(value)) });
			const text = role ? role.name : '';

			return {
				title: lang[name],
				text: text
			};
		}
		case 'ActivityDelayInDays': {
			return {
				title: lang[name],
				text: `${value} ${value === 1 ? lang.day : lang.days}`
			};
		}
		case 'Name':
		case 'Notes':
		case 'Description': {
			const trimmedValue = valueString.length > 50 ? `${valueString.substring(0, 50)}...` : value;

			return {
				title: lang[name],
				text: trimmedValue
			};
		}
		case 'AssignToManager':
		case 'sendEmail':
		case 'Unsubscribe': {
			return {
				title: lang[name],
				text: value ? lang.yes : lang.no
			};
		}
		case 'NewMarketScore': {
			return {
				title: lang[name],
				text: value
			};
		}
		case 'JourneyStep': {
			let text = valueString.toUpperCase();
			if (text.length > 1) {
				text = text.charAt(0) + text.substring(1).toLowerCase();
			}

			return { title: lang[name], text };
		}
	}
};

export const sort = (type: string, property: { name: string }) => {
	switch (property.name) {
		case 'Notes':
		case 'Description':
			return 0;
		case 'ActivityType':
			return 1;
		case 'Date':
			return 2;
		case 'User':
			return type === 'activity' ? 3 : 8;
		case 'Project':
		case 'RemoveProject':
		case 'AddProject':
			return 4;
		case 'Categories':
		case 'RemoveCategories':
		case 'AddCategories':
			return 5;
		case 'AddSegments':
			return 6;
		case 'ActivityDelayInDays':
			return 7;
		default:
			return 9;
	}
};

export default format;

import logError from 'App/babel/helpers/logError';
// The only place using this directive is in the old advanced search. Remove this whnen ADVANCED_SEARCH_REACT is released.
angular.module('upDirectives').directive('upProductSelect', [
	'$compile',
	'$translate',
	'AppService',
	function ($compile, $translate, AppService) {
		return {
			restrict: 'A',
			replace: true,
			priority: 1000,
			scope: true,
			require: 'ngModel',
			controller: [
				'$scope',
				'$q',
				function ($scope, $q) {
					$scope.init = function () {
						var categories = angular.copy(AppService.getProductCategories());

						var products;
						if ($scope.onlyActive) {
							products = AppService.getProducts(true);
						} else {
							products = AppService.getProducts();
						}

						var root = [];

						$scope.products = products;

						_.each(products, function (product) {
							// If root
							if (!product.category || product.category.id === 0) {
								root.push(product);
							} else {
								var parent = _.find(categories, { id: product.category.id });

								if (parent) {
									if (!parent.children) {
										parent.children = [];
									}
									parent.children.push(product);
								}
							}
						});

						$scope.categories = categories;

						_.each(categories, function (category) {
							// If root
							if (category.parentId === 0) {
								root.push(category);
							} else {
								var parent = _.find(categories, function (cat) {
									return category.parentId === cat.id || category.parentId === cat.$id;
								});

								if (parent) {
									if (!parent.children) {
										parent.children = [];
									}
									parent.children.push(category);
								}
							}
							if ($scope.selectableCategories) {
								category.isCategory = true;
							} else {
								category.$id = category.id;
								delete category.id; // don't want select2 to be able to select a optgroup
							}
						});

						$scope.tree = root;

						return $q.when();
					};
				}
			],
			compile: function compile($element, $attrs) {
				// Set select2 directive on element
				$attrs.$set('ui-select2', 'select2config');

				// Re-set this directive so that we do not get a inf-loop
				$attrs.$set('up-product-select');

				return {
					post: function (scope, element, attrs, ngModelCtrl) {
						// Wait for app to load
						AppService.loadedPromise
							.then(function () {
								// Set multiple
								var multiple = attrs.multiple !== undefined;

								if (attrs.map !== undefined) {
									ngModelCtrl.$formatters.unshift(function (value) {
										var newValue = [];

										angular.forEach(value, function (id) {
											if (typeof id !== 'object') {
												var product = _.find(scope.products, { id: id });
												if (product) {
													newValue.push(product);
												}
											}
										});

										if (newValue.length) {
											ngModelCtrl.$setViewValue(newValue);
											return newValue;
										}

										return value;
									});
								}

								// Fix empty value
								ngModelCtrl.$parsers.unshift(function (value) {
									// If this is a multiple and nothing is selected (null) we set it to []
									if (multiple && value === null) {
										value = [];
									}

									// If not multi and nothing is selected we set it to null
									if (!multiple && value === '') {
										value = [];
									}

									return value;
								});

								// Set onlyActive and selectable-categories boolean on scope
								scope.onlyActive = attrs.onlyActive !== undefined;
								scope.selectableCategories = attrs.selectableCategories !== undefined;

								var searchableProducts = _.filter(AppService.getCustomFields('product'), {
									searchable: 1
								}).map(function (cf) {
									return cf.id;
								});

								// Init ctrl
								scope
									.init()
									.then(function () {
										if (scope.products && AppService.getTotals('products') < 4000) {
											//The select2 config
											scope.select2config = {
												data: function () {
													return { results: scope.tree };
												},
												formatSelection: function (obj, el, escape) {
													if (obj.isCategory) {
														return '<b>' + escape(obj.name) + '</b>';
													}
													return escape(obj.name);
												},
												formatResult: (obj, container, query, escape) =>
													escape(_.property('name')(obj)),
												matcher: function (term, undef, obj) {
													return (
														obj.name.toUpperCase().indexOf(term.toUpperCase()) !== -1 &&
														obj.id
													);
												},
												id: 'id'
											};
										} else {
											scope.select2config = {
												formatSelection: function (obj, el, escape) {
													if (obj.isCategory) {
														return '<b>' + escape(obj.name) + '</b>';
													}
													return escape(obj.name);
												},
												formatResult: function (obj, container, query, escape) {
													if (obj.isCategory) {
														return '<b>' + escape(obj.name) + '</b>';
													}
													return escape(obj.name);
												},
												minimumInputLength: 1,
												allowClear: true,
												data: [],
												ajax: {
													data: function (term) {
														return term;
													},
													transport: function (q) {
														var rb = new Tools.RequestBuilder();
														rb.limit = 500;
														rb.addFilter(
															Tools.Product.attr.active,
															rb.comparisonTypes.Equals,
															1
														);

														if (searchableProducts.length) {
															var orBuilder = rb.orBuilder();
															orBuilder.next();
															orBuilder.addFilter(
																Tools.Product.attr.name,
																rb.comparisonTypes.Search,
																q.data
															);
															orBuilder.next();
															var groupFilter = orBuilder.groupBuilder();
															groupFilter.addFilter(
																{ field: 'custom.fieldId' },
																rb.comparisonTypes.Equals,
																searchableProducts
															);
															groupFilter.addFilter(
																{ field: 'custom.value' },
																rb.comparisonTypes.Search,
																q.data
															);
															groupFilter.done();
															orBuilder.done();
														} else {
															rb.addFilter(
																Tools.Product.attr.name,
																rb.comparisonTypes.Search,
																q.data
															);
														}

														const categories = _.cloneDeep(
															Tools.AppService.getProductCategories(false)
														);

														const filteredCategories = categories.filter(category =>
															(category.name || '')
																.toLowerCase()
																.includes((q.data ?? '').toLowerCase())
														);

														const updatedCategories = filteredCategories.map(category => {
															category.isCategory = true;
															return category;
														});

														Tools.Product.customer(Tools.AppService.getCustomerId())
															.find(rb.build())
															.then(function (res) {
																var data = _.filter(res.data, function (product) {
																	return product.$hasAccess;
																});
																q.success({ results: data.concat(updatedCategories) });
															})
															.catch(error =>
																logError(
																	error,
																	'upProductSelect: Failed to load products'
																)
															);
													},
													results: function (res) {
														return res;
													}
												}
											};
										}

										// Set multiple
										multiple = attrs.multiple !== undefined;

										// Set clear x
										if (attrs.required === undefined && !multiple) {
											scope.select2config.allowClear = true;
										}

										// Set placeholder
										if (!attrs.placeholder) {
											scope.select2config.placeholder = multiple
												? $translate.instant('default.products')
												: $translate.instant('default.product');
										} else {
											scope.select2config.placeholder = attrs.placeholder;
										}

										// Re-compile element
										$compile(element)(scope);
									})
									.catch(error => logError(error, 'upProductSelect: Failed to run script'));
							})
							.catch(error => logError(error, 'upProductSelect: Failed to load AppService'));
					}
				};
			},
			template: '<input class="form-control" type="hidden" />'
		};
	}
]);

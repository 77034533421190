'use strict';

angular.module('upResources').factory('Trigger', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'ResourceHelper',
	function Trigger($resource, $q, URL, API, GenericMapper, ResourceHelper) {
		// var AA = TriggerAttributes();
		// var Attribute = AA.attr;
		// var Keys = AA.keys;
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'trigger',
			createSuccessBody: 'saved.trigger',
			updateSuccessBody: 'updated.trigger',
			deleteSucccessBody: 'deleted.trigger',
			createErrorBody: 'saveError.trigger',
			updateErrorBody: 'saveError.trigger',
			deleteErrorBody: 'deleteError.trigger'
		});

		var baseUrl = URL + API;
		var Resource = $resource(
			baseUrl + 'triggers/:id',
			{},
			{
				find: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false },
				getTriggerAttributes: { method: 'GET', isArray: false, url: baseUrl + 'triggerAttributes' }
			}
		);

		var Model = {
			new: function () {
				var obj = {
					name: '',
					entity: 'client',
					triggers: ['create'],
					active: true,
					criterias: [],
					actions: []
				};
				return obj;
			},

			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (trigger, options = {}) {
				var promise;

				if (trigger.id) {
					promise = Resource.update({ id: trigger.id }, { trigger: trigger }).$promise;
				} else {
					promise = Resource.create({ trigger: trigger }).$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !trigger.id);

						return res;
					})
					.catch(function (err) {
						// Notify user
						if (err.data?.error?.translated) {
							options[trigger.id ? 'updateErrorBody' : 'createErrorBody'] = err.data.error.translated;
						}
						helper.onSaveError(options, !trigger.id);
					});

				return promise;
			},

			delete: function (trigger, options) {
				return Resource['delete']({ id: trigger.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, trigger);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			getTriggerAttributes: function () {
				return Resource.getTriggerAttributes().$promise;
			}
			// attr: Attribute,
			// keys: Keys
		};

		return Model;
	}
]);

'use strict';
angular.module('upAttributes').service('ScoreAttributes', [
	'FilterType',
	'DisplayType',
	'$translate',
	function (FilterType, DisplayType, $translate) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				clientId: {
					title: 'default.clientId',
					field: 'clientId',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				clientScore: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'clientScore',
					title: 'default.totalScore',
					sortable: 'clientScore',
					selectableColumn: true
				},
				scoreTrend: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'scoreTrend',
					title: 'default.scoreTrend',
					sortable: 'scoreTrend',
					selectableColumn: true
				},
				reference: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'reference',
					title: function () {
						return (
							$translate.instant('default.account') +
							'/' +
							$translate.instant('default.contact').toLowerCase()
						);
					}
				},
				assigned: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'assigned',
					title: 'scoreboard.assignedUser',
					sortable: 'assigned',
					selectableColumn: true
				},
				city: {
					title: 'address.city',
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'city',
					selectableColumn: true
				},
				leadId: {
					type: FilterType.Boolean,
					DisplayType: DisplayType.Boolean,
					field: 'leadId'
				},
				leadRegDate: {
					title: 'column.date',
					field: 'leadRegDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: true,
					filterable: true,
					sortable: 'leadRegDate'
				},
				scoreUpdate: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'scoreUpdate',
					title: 'default.scoreUpdate',
					sortable: 'scoreUpdate',
					selectableColumn: true
				},
				status: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'status',
					title: 'default.status',
					selectableColumn: true
				},
				eventTypes: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'eventTypes',
					title: 'scoreboard.eventTypes',
					selectableColumn: true
				},
				accountManagers: {
					title: 'default.accountManagers',
					field: 'accountManagers.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					filterName: 'accountManager',
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'accountManagers',
					attr: {
						id: {
							field: 'accountManagers.id',
							type: FilterType.Number
						},
						name: {
							field: 'accountManagers.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: 'users.name',
					selectableColumn: true
				},
				custom: {}
			};

			var standardFilters = {
				IsAssigned: {
					title: 'scoreboard.assigned',
					field: 'lead.id',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.assigned', value: null, comparisonType: 'NotEquals' },
						{ text: 'scoreboard.unassigned', value: null, comparisonType: 'Equals' }
					]
				},
				HasActivity: {
					field: 'activity.id',
					listTitle: 'default.activity',
					title: 'default.activity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.hasActivity', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.hasNoActivity', value: null, comparisonType: 'Equals' }
					]
				},
				HasOpportunity: {
					field: 'opportunity.id',
					listTitle: 'default.opportunity',
					title: 'default.opportunity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.hasOpportunity', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.hasNoOpportunity', value: null, comparisonType: 'Equals' }
					]
				},
				HasOrder: {
					field: 'order.id',
					listTitle: 'default.order',
					title: 'default.order',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.hasOrder', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.hasNoOrder', value: null, comparisonType: 'Equals' }
					]
				},
				IsExternal: {
					title: 'scoreboard.existingClient',
					field: 'isExternal',
					listTitle: 'scoreboard.existingClient',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.wAccount', value: false, comparisonType: 'Equals' },
						{ text: 'scoreboard.woAccount', value: true, comparisonType: 'Equals' }
					]
				},
				LeadUser: {
					title: 'scoreboard.assignedUser',
					field: 'lead.user',
					multiple: true,
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta();
					},
					type: 'list',
					inputType: 'selectGroup',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true
				},
				EventTypes: {
					title: 'scoreboard.eventTypes',
					field: 'eventTypes',
					multiple: true,
					type: 'list',
					dataPromise: function ($q) {
						return $q.when({
							data: [
								{ name: $translate.instant('event.types.Visit'), id: 'visit' },
								{ name: $translate.instant('liveFeed.mailClick'), id: 'mail' },
								{ name: $translate.instant('form.form'), id: 'form' }
							]
						});
					},
					inputType: 'select',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true
				},
				ClientScore: {
					title: 'default.totalScore',
					field: 'clientScore',
					type: 'range',
					inputType: 'range'
				},
				LeadRegDate: {
					title: 'automationTerms.leadCreated',
					field: 'leadRegDate',
					inputType: 'date',
					type: 'dateRange'
				},
				User: {
					title: 'default.accountManagers',
					field: 'client.user',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta();
					},
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name'),
					columnPath: 'accountManager'
				},
				Country: {
					field: 'country',
					inputType: 'select',
					searchField: function (obj) {
						return $translate.instant(obj.lang);
					},
					displayText: function (obj) {
						return $translate.instant(obj.lang);
					},
					dataPromise: function (customerId, $q, CountryCodes) {
						var defered = $q.defer();

						var translateResult = function (obj) {
							angular.forEach(obj, function (item) {
								item.lang = $translate.instant(item.lang);
								item.id = item.id.toLowerCase();
							});
						};

						CountryCodes.customer(customerId)
							.find({})
							.then(function (res) {
								translateResult(res.data);
								defered.resolve({ data: res.data });
							})
							.catch(function (error) {
								console.error(error);
								defered.resolve({ data: [] });
							});

						return defered.promise;
					},
					title: 'address.countries',
					listTitle: 'address.country',
					multiComparitors: true,
					type: 'list'
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				keys: {
					ID: 'id',
					clientId: 'clientId',
					TOTALSCORE: 'totalScore',
					REFERENCE: 'reference',
					ACTIVITYDATE: 'activityDate',
					OPPORTUNITYID: 'opportunityId',
					SCOREUPDATE: 'scoreUpdate',
					STATUS: 'status',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

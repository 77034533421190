import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';
import t from 'Components/Helpers/translate';

import './Assign.scss';

export default class AssignInfo extends React.Component {
	render() {
		const classes = new BemClass('AssignInfo');
		const users = Tools.AppService.getUsers();
		const userProp = this.props.config.properties.find(p => p.name === 'User');
		let user = null;
		if (userProp) {
			user = users.find(u => u.id === parseInt(userProp.value));
		}

		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>{t('processedBy.AssignUser')}</div>
					<div className={classes.elem('sub-title').b()}>{user ? user.name : null}</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

AssignInfo.propTypes = {
	config: PropTypes.object.isRequired
};

'use strict';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';
import openModal from 'App/services/Modal';

angular.module('domain.account').controller('AccountAddresses', [
	'$rootScope',
	'$scope',
	'$stateParams',
	'$translate',
	'Account',
	'SaveInline',
	'CountryCodes',
	'AppService',
	'$upModal',
	function ($rootScope, $scope, $stateParams, $translate, Account, SaveInline, CountryCodes, AppService, $upModal) {
		const AccountCtrl = $scope.AccountCtrl;

		$scope.labels = {
			Visit: 'A',
			Mail: 'B',
			Invoice: 'C',
			Delivery: 'D',
			Other: 'E'
		};

		$scope.addresses = {};
		$scope.newAddresses = {};
		$scope.nrOfAddresses = 0;
		$scope.activeAddress = null;
		$scope.editMode = null;
		$scope.mapConfig = {};
		$scope.requiredFields = Tools.AppService.getMetadata().requiredFields.Client ?? {};

		function getAddresses() {
			$scope.$watch('AccountCtrl.account', function (account) {
				if (!account) {
					return;
				}

				const addresses = account.addresses.reduce((res, address) => {
					if (address.type) {
						res.push({ ...address, label: $scope.labels[address.type] });
					}
					return res;
				}, []);

				if (addresses.length) {
					$scope.nrOfAddresses = addresses.length;

					addresses.forEach(address => ($scope.addresses[address.type] = address));
					$scope.newAddresses = _.cloneDeep($scope.addresses);
				}
				$scope.userEditable = account.userEditable;
			});
		}

		function getCountries() {
			$scope.countries = _.sortBy(
				CountryCodes.countries().map(c => {
					c.$translated = $translate.instant(c.lang);
					return c;
				}),
				['$translated']
			);
		}

		function validateAddress(address) {
			return address && (address.address || address.zipcode || address.city || address.country);
		}

		$scope.validateAddress = validateAddress;

		function mapNewAddresses() {
			return Object.keys($scope.newAddresses).map(type => {
				const address = $scope.newAddresses[type];
				address.type = type;
				address.label = $scope.labels[type];
				return address;
			});
		}

		$scope.cancelEdit = () => {
			$scope.editMode = null;
		};

		$scope.editAddress = type => {
			if (!$scope.userEditable) {
				return;
			}
			if (type) {
				$scope.editMode = type;
				$rootScope.$broadcast('addresses.editAddress');
			}
		};

		$scope.updateAddress = (promise, type) => {
			if (validateAddress($scope.newAddresses[type])) {
				const newAddresses = mapNewAddresses();
				SaveInline(newAddresses, promise, 'addresses', AccountCtrl.account.id, 'Account', {
					eventPrefix: 'addresses'
				});

				$scope.nrOfAddresses = newAddresses.length;
				$scope.addresses[type] = _.cloneDeep($scope.newAddresses[type]);
				AccountCtrl.account.addresses = newAddresses;
			} else {
				$scope.removeAddress(type);
			}

			$scope.editMode = null;
		};

		const onRemove = type => {
			const newAddresses = mapNewAddresses().filter(a => a.type !== type);

			const account = {
				id: AccountCtrl.account.id,
				addresses: newAddresses
			};

			const options = {
				updateSuccessTitle: 'default.wasDeleted',
				updateSuccessBody: `address.${type.toLowerCase()}`,
				skipEvent: true
			};

			Account.customer($stateParams.customerId)
				.save(account, options)
				.then(() => {
					if ($scope.activeAddress === type) {
						$scope.activeAddress = null;
					}

					delete $scope.newAddresses[type];
					delete $scope.addresses[type];

					$scope.nrOfAddresses = newAddresses.length;
					AccountCtrl.account.addresses = newAddresses;

					$rootScope.$broadcast('addresses.removed', type);
				})
				.catch(err => console.error(err));
		};

		$scope.removeAddress = type => {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					body: 'confirm.address',
					onClose: confirmed => {
						if (confirmed) {
							onRemove(type);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'default.remove',
					body: 'confirm.address',
					resolveTrue: 'default.remove',
					icon: 'fa-warning',
					focus: 1
				})
				.then(() => {
					onRemove(type);
				});
		};

		$scope.zoomAddress = type => {
			if ($scope.edit || !$scope.nrOfAddresses) {
				return;
			}

			$scope.activeAddress = type;
			const coordinates = $scope.mapConfig.coordinates || [];
			const address = coordinates.find(c => c.type === type);

			if (address) {
				loader
					.load()
					.then(() => {
						$scope.mapConfig.response.map.panTo(new google.maps.LatLng(address.lat, address.lng));
						$scope.mapConfig.response.map.setZoom(15);
					})
					.catch(e => logError(e, 'Failed to load Google'));
			}
		};

		$scope.addressRemovable = addressType => {
			return !$scope.requiredFields[addressType === 'Mail' ? 'Adress' : `${addressType}Address`];
		};

		$scope.getTranslatedType = type => type && $translate.instant(`address.${type.toLowerCase()}`);
		$scope.getAddressTypes = () => Object.keys($scope.labels);

		AppService.loadedPromise
			.then(() => {
				getAddresses();
				getCountries();
			})
			.catch(err => console.error(err));
	}
]);

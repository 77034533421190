import React from 'react';
import { Row, Column } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './MailTemplatePreviewFooter.scss';

const MailTemplatePreviewFooter = ({ selected, readOnly, config, accountProfile, onClick }) => {
	const classes = new bemClass('MailTemplatePreviewFooter');
	const footerMargin = config.footerMargin ? config.footerMargin : 0;
	const footerStyle = {
		backgroundColor: config.bodyBg,
		color: config.footerTextColor || accountProfile.colors.footerText,
		fontFamily: accountProfile.typography.p.fontFamily,
		marginTop: footerMargin + 'px'
	};
	const footerLinkStyle = {
		color: config.footerLinkTextColor || accountProfile.colors.footerLinkText
	};
	const unsubLinkStyle = {
		color: config.footerLinkTextColor || accountProfile.colors.footerLinkText
	};

	return (
		<div
			className={classes.mod({ selected, 'read-only': readOnly }).b()}
			style={footerStyle}
			onClick={onClick}
			data-testid="footer"
		>
			<div className={classes.elem('inner').b()}>
				<Row>
					<Column>
						{accountProfile.name ? (
							<strong>
								{accountProfile.name || ''}
								<br />
							</strong>
						) : null}
						{accountProfile.address ? (
							<span>
								{accountProfile.address}
								<br />
							</span>
						) : null}
						{accountProfile.zipcode || accountProfile.city ? (
							<span>
								{accountProfile.zipcode || ''} {accountProfile.city || ''}
								<br />
							</span>
						) : null}
						<span>{accountProfile.country || ''}</span>
					</Column>
					<Column align="right">
						<a style={footerLinkStyle} onClick={e => e.preventDefault()}>
							{accountProfile.email || ''}
						</a>
						<br />
						<a style={footerLinkStyle} onClick={e => e.preventDefault()}>
							{accountProfile.phone || ''}
						</a>
					</Column>
				</Row>
				{config.unsubText ? (
					<Row className={classes.elem('unsub').b()}>
						<Column align="center">
							<a style={unsubLinkStyle} onClick={e => e.preventDefault()}>
								{config.unsubText}
							</a>
						</Column>
					</Row>
				) : null}
			</div>
		</div>
	);
};

export default MailTemplatePreviewFooter;

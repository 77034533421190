'use strict';

angular.module('domain.contact').factory('ContactMeta', [
	'$q',
	'CustomField',
	'Contact',
	'Account',
	'AppService',
	'NotificationService',
	'User',
	'avatarService',
	function ($q, CustomField, Contact, Account, AppService, NotificationService, User, avatarService) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = $stateParams.customerId;

				if (!$stateParams.id) {
					return $q.reject();
				}

				var promises = {
					contact: Contact.customer(customerId)
						.get($stateParams.id)
						.then(function (contact) {
							var firstTouch = (contact.data || {}).firstTouch;

							if (!firstTouch || firstTouch.type !== 'Offline') {
								return contact;
							}

							if (
								firstTouch.primary === 'ManuallyCreated' ||
								firstTouch.primary === 'ProspectingExtension'
							) {
								// eslint-disable-next-line eqeqeq
								const user = AppService.getUsers().find(u => u.id == firstTouch.secondary) || {};

								return avatarService
									.getAvatar(user)
									.then(function (avatar) {
										contact.data.firstTouch.primary = user.name;
										contact.data.firstTouch.icon = avatar.url;
										return contact;
									})
									.catch(function () {
										return contact;
									});
							}

							return contact;
						}),
					documentTemplates: $q.when({ data: AppService.getDocumentTemplates('client') }),
					documentTemplatesActivity: $q.when({ data: AppService.getDocumentTemplates('activity') }),
					documentTemplatesOrder: $q.when({ data: AppService.getDocumentTemplates('order') }),
					documentTemplatesAgreement: $q.when({ data: AppService.getDocumentTemplates('agreement') })
				};

				promises.account = promises.contact.then(function (contact) {
					if (!contact.data.client) {
						return {};
					}

					return Account.customer(customerId).get(contact.data.client.id);
				});

				return $q
					.all(promises)
					.then(function (response) {
						return response;
					})
					.catch(function (err) {
						var msg = 'openError.contact';

						if (err && err.status === 404) {
							if (err.data && err.data.metadata && err.data.metadata.missingRights) {
								msg = 'errorMissingRights.contact';
							} else {
								msg = 'errorNotFound.contact';
							}
						}

						NotificationService.addNotification({
							title: 'default.error',
							body: msg,
							style: NotificationService.style.ERROR,
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

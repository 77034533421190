import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

class MultipleSelectedValue extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			firstBackspace: false
		};
	}

	render() {
		const { data } = this.props;
		const OPTION_CLASSES = ['Select-value'];

		if (data.data) {
			if (data.data.hasOwnProperty('role')) {
				OPTION_CLASSES.push('user-token');
			} else {
				OPTION_CLASSES.push('contact-token');
			}
		}

		if (data.data.isInRemoveState || this.state.firstBackspace) {
			OPTION_CLASSES.push('remove-state');
		}

		return (
			<div className={OPTION_CLASSES.join(' ')}>
				<components.MultiValueContainer {...this.props} />
			</div>
		);
	}
}

MultipleSelectedValue.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object
};

export default MultipleSelectedValue;

import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter, getLoggedInUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';

angular.module('upAttributes').service('Lead2Attributes', [
	'FilterType',
	'DisplayType',
	'$q',
	'AppService',
	'FeatureHelper',
	'$translate',
	function (FilterType, DisplayType, $q, AppService, FeatureHelper, $translate) {
		return function () {
			var metaData = AppService.getMetadata();
			var syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				clientName: {
					title: 'default.name',
					field: 'clientName',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					locked: true,
					sortable: 'clientName'
				},
				contactName: {
					title: 'default.contactName',
					field: 'contactName',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					locked: true,
					sortable: 'contactName'
				},
				scoreTotal: {
					title: 'default.scoreTotal',
					field: 'scoreTotal',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'scoreTotal'
				},
				scoreVisits: {
					title: 'default.scoreVisits',
					field: 'scoreVisits',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'scoreVisits'
				},
				scoreMail: {
					title: 'default.scoreMail',
					field: 'scoreMail',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'scoreMail'
				},
				scoreForm: {
					title: 'default.scoreForm',
					field: 'scoreForm',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'scoreForm'
				},
				scoreMarketingCustom: {
					title: 'default.scoreMarketingCustom',
					field: 'scoreMarketingCustom',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'scoreMarketingCustom'
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'journeyStep',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: syncJourneyStatus
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'journeyStep',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: !syncJourneyStatus
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contactJourneyStep',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: !syncJourneyStatus
				},
				countTotal: {
					title: 'default.countTotal',
					field: 'countTotal',
					type: FilterType.Number,
					displayType: DisplayType.String,
					selectableColumn: true,
					locked: true,
					sortable: 'countTotal'
				},
				countVisits: {
					title: 'default.countVisits',
					field: 'countVisits',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'countVisits'
				},
				countMail: {
					title: 'default.countMail',
					field: 'countMail',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'countMail'
				},
				countForm: {
					title: 'default.countForm',
					field: 'countForm',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'countForm'
				},
				countMarketingCustom: {
					title: 'default.countMarketingCustom',
					field: 'countMarketingCustom',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'countMarketingCustom'
				},
				contactCount: {
					title: 'default.contactCount',
					field: 'contactCount',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					locked: true,
					sortable: 'contactCount'
				},
				isExternal: {
					title: 'default.isExternal',
					field: 'isExternal',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'isExternal'
				},
				assignedSalesperson: {
					title: 'assign.assignedTo',
					field: 'assignedSalesperson',
					type: FilterType.String,
					displayType: DisplayType.String,
					elevioId: 242,
					selectableColumn: true
				},
				turnover: {
					title: 'default.turnover',
					field: 'turnover',
					type: FilterType.String,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'turnover'
				},
				groupStructure: {
					title: 'account.relations.groupSize.groupSize',
					field: 'groupStructure',
					displayType: DisplayType.None,
					selectableColumn: true,
					groupable: false
				},
				sniText: {
					title: 'default.primary_line_of_business_text',
					field: 'sniText',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'sniText'
				},
				state: {
					title: 'default.state',
					field: 'state',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'state'
				},
				status: {
					title: 'default.clientHistory',
					field: 'status',
					type: FilterType.String,
					selectableColumn: true
				},
				date: {
					field: 'date',
					title: 'leads.eventDate',
					type: FilterType.Date,
					sortable: 'date'
				}
			};

			var filters = [];

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var userDataPromise = function (UserTreeFilterMeta) {
				var defer = $q.defer();
				var language = AppService.getSelf().language;
				var sortFn = sortAlphabetically('name', false, language);

				function sort(arr) {
					arr.sort(function (a, b) {
						if ((a.isRole && b.isRole) || (!a.isRole && !b.isRole)) {
							return sortFn(a, b);
						} else {
							return a.isRole ? 1 : -1;
						}
					});

					arr.forEach(function (obj) {
						if (obj.isRole && obj.children && obj.children.length) {
							sort(obj.children);
						}
					});
				}

				// eslint-disable-next-line promise/catch-or-return
				UserTreeFilterMeta(AppService.AccessType.ACCOUNT).then(function (res) {
					sort(res.data);
					defer.resolve(res);
				});

				return defer.promise;
			};

			var standardFilters = {
				TotalScore: {
					filterName: 'TotalScore',
					field: 'scoreTotal',
					title: 'default.totalScore',
					inputType: 'range',
					type: 'range',
					noclear: true
				},
				CountTotal: {
					filterName: 'CountTotal',
					field: 'countTotal',
					title: 'leads.filterTitle.listNumberOfMarketingEvents',
					inputType: 'range',
					type: 'range',
					min: 1
				},
				Date: {
					field: 'date',
					title: 'default.date',
					grouping: 'account',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				ContactName: {
					field: 'contactName',
					grouping: 'contact',
					inputType: 'text',
					type: 'text',
					title: 'leads.contactName'
				},
				ClientName: {
					field: 'clientName',
					grouping: 'account',
					inputType: 'text',
					type: 'text',
					title: 'leads.clientName',
					parent: 'filters.columns.leads2Company'
				},
				User: generateUserFilter(null, {
					field: 'userId',
					title: 'leads.clientUser',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					includeFields: ['name'],
					multiComparitors: false,
					dataPromise: function (UserTreeFilterMeta) {
						return userDataPromise(UserTreeFilterMeta).then(resp => {
							resp.data.unshift(getLoggedInUserFilter());

							return resp;
						});
					},
					selectMissingRow: {
						item: {
							id: null,
							name: $translate.instant('default.noAccountManager')
						}
					}
				}),
				Assigned: {
					type: 'list',
					filterName: 'Assigned',
					hide: true,
					field: 'assignedSalespersonId',
					title: 'leads.clientAssigned',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					includeFields: ['name'],
					dataPromise: userDataPromise
				},
				CompanyBranch: {
					filterName: 'CompanyBranch',
					field: 'sniText',
					type: 'list',
					title: 'leads.clientBranch',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					displayType: 'listLazy',
					resource: function (SniCodes, $q) {
						return function (customerId, values) {
							var filterData = _.chain(SniCodes.get('sv_SE'))
								.filter(function (val) {
									return values.indexOf(val.toLowerCase()) !== -1;
								})
								.map(function (val) {
									return { id: val, name: val };
								})
								.value();

							return $q.when({ data: filterData, metadata: { total: filterData.length } });
						};
					},
					searchFn: function (SniCodes, $q) {
						return function (term, fields, offset, limit) {
							var filterData = _.filter(SniCodes.get('sv_SE'), function (val) {
								return val.toLowerCase().indexOf(term) !== -1;
							});

							var metadata = { total: filterData.length, offset: offset, limit: limit };

							var resultData = _.chain(filterData)
								.slice(offset, offset + limit)
								.map(function (val) {
									return { id: val, name: val };
								})
								.value();

							return $q.when({ data: resultData, metadata: metadata });
						};
					}
				},
				HasActivity: {
					title: 'leads.clientHasActivity',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					field: 'hasActivity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.with', value: 1, comparisonType: 'Equals' },
						{ text: 'default.without', value: 0, comparisonType: 'Equals' }
					]
				},
				HasOpportunity: {
					title: 'leads.clientHasOpportunity',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					field: 'hasOpportunity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.with', value: 1, comparisonType: 'Equals' },
						{ text: 'default.without', value: 0, comparisonType: 'Equals' }
					]
				},
				HasOrder: {
					title: 'leads.clientHasSales',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					field: 'hasOrder',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.with', value: 1, comparisonType: 'Equals' },
						{ text: 'default.without', value: 0, comparisonType: 'Equals' }
					]
				},
				IsAssigned: {
					title: 'leads.clientAssigned',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					hide: true,
					field: 'assignedSalesperson',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.assigned', value: 'none', comparisonType: 'NotEquals' },
						{ text: 'scoreboard.unassigned', value: 'none', comparisonType: 'Equals' }
					]
				},
				Campaign: {
					filterName: 'Campaign',
					field: 'project',
					title: 'leads.clientCampaign',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					columnPath: 'campaigns',
					resource: 'Campaign',
					includeFields: ['name'],
					type: 'list',
					displayType: 'listLazy',
					search: true,
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}
							return Campaign.customer(cId).find(filter.build());
						};
					}
				},
				JourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					field: 'journeyStep',
					title: 'default.journeyStep',
					parent: 'filters.columns.leads2Company',
					grouping: 'account',
					dataPromise: function () {
						return $q.when({
							data: _.map(Tools.AppService.getJourneySteps(), function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					}
				}
			};

			FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PIPELINE).catch(function () {
				standardFilters.HasOpportunity.hide = true;
			});

			FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ORDERS).catch(function () {
				standardFilters.HasOrder.hide = true;
			});

			FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS).catch(function () {
				standardFilters.HasActivity.hide = true;
			});

			FeatureHelper.isAvailablePromise(FeatureHelper.Feature.JOURNEY_STATUS).catch(function () {
				standardFilters.JourneyStep.hide = true;
			});

			if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO)) {
				attributes.groupStructure.hide = true;
			}

			if (AppService.loaded) {
				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}
			}

			for (const entity of ['account', 'contact']) {
				const relatedCustomFields = _.sortBy(AppService.getCustomFields(entity), 'sortId').filter(
					cf => cf.visible !== 0
				);
				const customFieldConfigs = Tools.FilterHelper.getConfigsFromCustomFields(entity, relatedCustomFields);
				Object.assign(standardFilters, customFieldConfigs);
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: filters,
				standardFilters: standardFilters,
				keys: {
					ID: 'id',
					NAME: 'name',
					USER: 'user',
					SOURCE: 'source',
					ACCOUNT: 'account',
					CLIENT: 'client'
				}
			};
		};
	}
]);

ReactTemplates.segment.flowEditor = window.ReactCreateClass({
	getInitialState: function () {
		return {
			fullscreen: false,
			hasErrors: false
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			editFlow: t('flow.editFlow'),
			saveAsDraft: t('mail.saveAsDraft'),
			abort: t('default.abort'),
			segment: t('default.segment'),
			saveFlowTooltip: t('flow.yourFlowIsSavedInNextStep')
		};
	},

	componentDidMount: function () {},

	componentWillUnmount: function () {},

	componentDidUpdate: function () {},

	toggleFullscreen: function () {
		this.setState({ fullscreen: !this.state.fullscreen });
	},

	onErrorChange: function (hasErrors) {
		if (hasErrors !== this.state.hasErrors) {
			this.setState({ hasErrors: hasErrors });
		}
	},

	render: function () {
		var self = this;
		var content = null;

		var loader = (
			<div id="flow-editor-loader" className={this.props.loading ? 'visible' : null}>
				{React.createElement(ReactTemplates.loader, {})}
			</div>
		);

		if (!this.props.loading) {
			var header = (
				<div id="flow-editor-header">
					<div className="header-title">
						<h2>{self.lang.editFlow}</h2>
						<span className="subtitle" data-test-id="flow-editor-segment-name">
							{self.props.flow
								? self.props.flow.name
								: self.lang.segment + ': ' + self.props.segment.name}
						</span>
					</div>
					<div className="header-tools">
						<button type="button" onClick={this.toggleFullscreen} data-test-id="flow-editor-toggle-fs">
							{this.state.fullscreen ? (
								<b className="fa fa-compress"></b>
							) : (
								<b className="fa fa-expand"></b>
							)}
						</button>
						<button type="button" onClick={this.props.abortEdit}>
							{self.lang.abort}
						</button>
						{ReactTemplates.TOOLS.withTooltip(
							<button type="button" className="save" onClick={() => this.props.save()}>
								{self.lang.saveAsDraft}
							</button>,
							this.lang.saveFlowTooltip,
							{ placement: 'bottom' }
						)}
					</div>
				</div>
			);

			content = (
				<div>
					{header}
					<div id="flow-wrap">
						<ReactTemplates.segment.flow
							editable
							flow={this.props.flow}
							contacts={this.props.contacts}
							getStep={this.props.getStep}
							onAddStep={this.props.onAddStep}
							onRemoveStep={this.props.onRemoveStep}
							showEmailTemplateModal={this.props.showEmailTemplateModal}
							onErrorChange={this.onErrorChange}
							optOutCategories={this.props.optOutCategories}
							tools={this.props.tools}
							onFlowPropChange={this.props.onFlowPropChange}
							saveFlow={this.props.save}
						/>
					</div>
				</div>
			);
		}

		return (
			<div id="flow-editor" className={this.state.fullscreen ? 'fullscreen' : null}>
				{content}

				{loader}
			</div>
		);
	}
});

import moment from 'moment';
import CallListResource from 'Resources/CallList';

angular.module('domain.campaign').controller('EditCampaign', [
	'$scope',
	'$modalParams',
	'Campaign',
	'CustomfieldMapper',
	'$state',
	'AppService',
	'ScriptService',
	'$safeApply',
	function ($scope, $modalParams, Campaign, CustomfieldMapper, $state, AppService, ScriptService, $safeApply) {
		var EditCampaign = this;
		var customerId;
		var meta = $modalParams.meta;
		$scope.showInlineAction = 'none';
		EditCampaign.showInlineActionTop = false;
		let initialFrom = [];
		let activeState = false;

		EditCampaign.editable = true;

		EditCampaign.onClose = function () {
			if (EditCampaign.saving) {
				return;
			}
			$scope.closeModal('top');
		};

		EditCampaign.save = function () {
			if (EditCampaign.isCallList) {
				EditCampaign.campaign.isCallList = true;
			}
			EditCampaign.saving = true;
			EditCampaign.campaign.custom = CustomfieldMapper.map($scope.CampaignForm);

			const saveFunc = EditCampaign.isCallList
				? data => CallListResource.save(data)
				: Campaign.customer(customerId).save;

			return ScriptService.project.save(EditCampaign.campaign).then(function () {
				saveFunc(EditCampaign.campaign)
					.then(function (response) {
						if (!EditCampaign.campaign.id && !$modalParams.noRedirect) {
							// Go to account
							setTimeout(function () {
								$state.go('campaign.dashboard', { id: response.data.id, customerId: customerId });
							}, 2000);
						} else {
							$scope.resolve(response.data);
						}
					})
					.catch(function () {
						EditCampaign.saving = false;
					});
			});
		};

		EditCampaign.startDateChange = function () {
			var momentStart = moment(EditCampaign.campaign.startDate);
			EditCampaign.minEndDate = momentStart.startOf('day').toDate();
			if (EditCampaign.campaign.endDate && momentStart.isAfter(EditCampaign.campaign.endDate)) {
				EditCampaign.campaign.endDate = momentStart.toDate();
			}
		};

		EditCampaign.endDateChange = function () {
			if (!EditCampaign.campaign.endDate) {
				return;
			}

			var momentEnd = moment(EditCampaign.campaign.endDate);
			if (momentEnd.isBefore(EditCampaign.campaign.startDate)) {
				EditCampaign.campaign.startDate = momentEnd.toDate();
			}
		};

		EditCampaign.initDates = function () {
			if (EditCampaign.campaign.startDate) {
				EditCampaign.campaign.startDate = new Date(EditCampaign.campaign.startDate);
			}
			if (EditCampaign.campaign.endDate) {
				EditCampaign.campaign.endDate = new Date(EditCampaign.campaign.endDate);
			}
		};

		$scope.closeModal = function (position) {
			if (EditCampaign.campaign.id) {
				initialFrom.active = !!activeState;
				EditCampaign.campaign.active = !!activeState;
			}
			const ignores = ['regDate', 'modDate', '$mappedCustom'];
			const omitKeys = obj =>
				Object.keys(angular.copy(obj)).reduce((memo, key) => {
					if (!ignores.includes(key)) {
						memo[key] = obj[key];
					}
					return memo;
				}, {});
			// The angular.copy removes internal angular properties (starting with $) which gives a more reliable comparison.
			// This is kind of a hack to compensate for angular's inability to work.
			if (
				!_.isEqual(
					{
						...omitKeys(initialFrom),
						startDate: initialFrom.startDate ? moment(initialFrom.startDate).format('YYYY-MM-DD') : null,
						endDate: initialFrom.endDate ? moment(initialFrom.endDate).format('YYYY-MM-DD') : null,
						custom: initialFrom.custom.map(c => ({ value: c.value ?? null }))
					},
					{
						...omitKeys(EditCampaign.campaign),
						startDate: EditCampaign.campaign.startDate
							? moment(EditCampaign.campaign.startDate).format('YYYY-MM-DD')
							: null,
						endDate: EditCampaign.campaign.endDate
							? moment(EditCampaign.campaign.endDate).format('YYYY-MM-DD')
							: null,
						custom: EditCampaign.campaign.custom?.map?.(c => ({ value: c.value ?? null }))
					}
				)
			) {
				$scope.showInlineAction = position;
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		};

		$scope.rejectChanges = function () {
			$scope.reject();
		};

		$scope.saveChanges = function () {
			EditCampaign.save();
		};

		$scope.closeInlineAction = function () {
			$scope.showInlineAction = 'none';
			$safeApply($scope);
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			var self = AppService.getSelf();
			EditCampaign.campaign = meta.campaign.data || {};
			EditCampaign.isCallList = $modalParams.isCallList || EditCampaign.campaign.isCallList;
			EditCampaign.hasSharedCallLists = Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');

			if (EditCampaign.campaign.$mappedCustom) {
				EditCampaign.campaign.custom = Object.values(EditCampaign.campaign.$mappedCustom);
			}
			EditCampaign.edit = !!meta.campaign.data.id;
			EditCampaign.customFieldsLength = _.filter(EditCampaign.campaign.custom, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;
			EditCampaign.users = AppService.getActiveUsers();

			var id = function (obj) {
				return obj.id.toString();
			};
			var formatName = function (obj, container, escape) {
				return escape(obj.name);
			};

			EditCampaign.userSelect = {
				formatSelection: formatName,
				formatResult: (obj, container, query, escape) => formatName(obj, container, escape),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: id,
				data: EditCampaign.users
			};

			// if edit
			if (EditCampaign.edit) {
				EditCampaign.editable = EditCampaign.campaign.userEditable;
				activeState = meta.campaign.data.active;
				EditCampaign.initDates();
			} else {
				// if new
				EditCampaign.campaign.startDate = new Date();
				EditCampaign.campaign.active = true;
				activeState = true;
				EditCampaign.campaign.users = [self];
			}

			EditCampaign.startDateChange();

			// set custom field default values
			EditCampaign.campaign.custom.forEach(function (custom) {
				if (!EditCampaign.edit) {
					if (custom.datatype === 'Select') {
						custom.value = custom.dropdownDefault ? custom.dropdownDefault : '';
					} else if (
						custom['default'] !== null &&
						custom['default'] !== undefined &&
						custom['default'] !== ''
					) {
						custom.value = custom['default'];
					}
				}
				if (custom.datatype === 'Integer') {
					custom.value = parseInt(custom.value);
				}

				if (custom.datatype === 'Users' && !_.isEmpty(custom.value)) {
					var valueArray = custom.value ? custom.value.split(',') : [];
					var customValue = [];
					AppService.getActiveUsers().forEach(function (user) {
						if (valueArray.includes(String(user.id))) {
							customValue.push({ id: user.id, text: user.name });
						}
					});
					custom.value = customValue;
				}
			});
			//Adding a timeout because initialForm gets set with incorrect values
			//due to a 2-way binding issue with the phone/cellphone fields
			setTimeout(function () {
				initialFrom = _.cloneDeep(EditCampaign.campaign);
			}, 200);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);

		ScriptService.project.init(EditCampaign.campaign);
	}
]);

'use strict';

import React from 'react';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { Icon, Text, Block } from '@upsales/components';
import { connect } from 'react-redux';

const mapStateToProps = ({ Billing }) => {
	return {
		ongoingTrialAddons: Billing.ongoingTrialAddons
	};
};

class SubMenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.setRef = this.setRef.bind(this);
		this.getDDItem = this.getDDItem.bind(this);
		this.track = this.track.bind(this);
		this.getItem = this.getItem.bind(this);
		this.setDropdown = this.setDropdown.bind(this);
	}
	componentDidMount() {
		this.setDropdown();
	}
	setRef(name, ref) {
		this[name] = ref;
	}
	setDropdown() {
		var settingsDrop = this._dropdownToggle;
		if (settingsDrop) {
			jQuery(settingsDrop).dropdown();
		}
	}
	getItem(state, text, icon, stateParams) {
		return (
			<li key={'admin-drop-' + state}>
				<a href={this.props.getStateHref(state, stateParams)}>
					{icon ? (
						<span>
							<b className={'fa ' + icon} />{' '}
						</span>
					) : null}{' '}
					{text}
				</a>
			</li>
		);
	}
	getDDItem(group, i) {
		var self = this;
		var divider = i !== 0 ? <li key={'divider-' + i} className="divider" /> : null;
		var t = Tools.$translate;

		if (group.type === 'header') {
			return this.props.dropdown.filter(item => item.type === 'header').length > 1
				? [
						divider,
						<Block space="mlxl mtl" key={'title' + i}>
							<Text size="xl" bold>
								{group.title}
							</Text>
						</Block>
				  ]
				: null;
		}
		if (group.items) {
			return [
				<li key={'header-' + i} className="dropdown-header">
					{group.title}
				</li>,
				_.reduce(
					group.items,
					function (memo, view, vi) {
						if (view.hidden) {
							return memo;
						}

						var a;
						if (view.state && view.stateParams) {
							a = (
								<a
									onClick={() => self.track(view.state, true)}
									href={self.props.getStateHref(view.state, view.stateParams)}
								>
									{t(view.title)}
								</a>
							);
						} else if (view.state) {
							a = (
								<a
									onClick={() => self.track(view.state, true)}
									href={self.props.getStateHref(view.state)}
								>
									{t(view.title)}
								</a>
							);
						} else if (view.stateParams) {
							a = (
								<a
									onClick={() => self.track(group.state, true)}
									href={self.props.getStateHref(group.state, view.stateParams)}
								>
									{t(view.title)}
								</a>
							);
						} else {
							a = (
								<a
									onClick={() => self.track(group.state, true)}
									href={self.props.getStateHref(group.state, group.stateParams) + '?id=' + view.id}
								>
									{t(view.title)}
								</a>
							);
						}
						memo.push(<li key={'view-' + i + '-' + vi}>{a}</li>);
						return memo;
					},
					[]
				)
			];
		} else {
			return [
				divider,
				<li key={'item-' + i}>
					<a
						onClick={() => self.track(group.state, true)}
						href={self.props.getStateHref(group.state, group.stateParams)}
					>
						{t(group.title)}
					</a>
				</li>
			];
		}
	}
	track(state, isDropdown) {
		globalTracker.track(`navbar${isDropdown ? '.dropdown' : ''}.${state}`);
	}
	render() {
		var self = this;

		var getStateHref = self.props.getStateHref;
		var classNames = 'sub-menu-item';
		// eslint-disable-next-line security/detect-non-literal-regexp
		const stateAgainstUrlRegex = new RegExp(`^/\\d+/${self.props.state}/`); // need to disable eslint in order for \d+ to be parsed correct.

		if (
			Tools.$state.is(self.props.state, self.props.stateParams) ||
			(!Tools.$location.$$path.includes('advanced-search') && stateAgainstUrlRegex.exec(Tools.$location.$$path))
		) {
			classNames += ' active';
		} else if (
			self.props.secondActiveState &&
			Tools.$state.includes(self.props.secondActiveState, self.props.stateParams)
		) {
			classNames += ' active';
		}

		var dropdown = null;

		if (self.props.dropdown) {
			var style = { textAlign: 'left' };
			var extraClasses = self.props.className;
			classNames += ' has-dd'; // :)
			dropdown = (
				<div className={'btn-group widget-list-wrap dropdown ' + extraClasses}>
					<button
						className="dropdown-toggle"
						ref={self.setRef.bind(self, '_dropdownToggle')}
						data-toggle="dropdown"
					>
						<i className="fa fa-angle-down" />
						<i className="fa fa-angle-up" />
					</button>

					<ul className="dropdown-menu" style={style}>
						{_.map(self.props.dropdown, self.getDDItem)}
					</ul>
				</div>
			);
		}
		return (
			<span>
				<a
					id={this.props.id}
					href={getStateHref(self.props.state, self.props.stateParams)}
					className={classNames}
					onClick={() => this.track(self.props.state, false)}
				>
					{self.props.showTrialBanner?.(this.props.ongoingTrialAddons) ? (
						<Icon name="pro" space="mrs" />
					) : null}
					{self.props.title}
				</a>
				{dropdown}
			</span>
		);
	}
}

export default connect(mapStateToProps)(SubMenuItem);

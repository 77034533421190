import React from 'react';
import { Text } from '@upsales/components';
import './FileStorageAppView.scss';

const NoPermissionView = () => (
	<div className="FileStorageView__noPermission">
		<img src="img/documents-locked-icon.svg" />
		<div className="messageWrapper">
			<Text size="xl">{Tools.$translate('account.files.permissionErrorHeader')}</Text>
			<Text className="text" color="grey-11">
				{Tools.$translate('account.files.permissionErrorSubHeader')}
			</Text>
		</div>
	</div>
);

export default NoPermissionView;

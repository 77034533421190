import { globalTracker } from 'App/babel/helpers/Tracker';

ReactTemplates.form.selectColumnContent = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			addContentToColumn: t('landingpage.addContentToColumn'),
			existingForm: t('landingpage.existingForm'),
			aboutThanku: t('landingpage.aboutThanku'),
			aboutThankuBody: t('landingpage.aboutThankuBody'),
			aboutThankuExample: t('landingpage.aboutThankuExample')
		};
		this.types = [
			{ icon: 'fa-image', type: 'image', title: t('bannereditor.image') },
			{ icon: 'fa-play', type: 'video', title: t('landingpage.video') },
			{ icon: 'fa-align-center', type: 'text', title: t('mail.text') },
			{ icon: 'fa-cloud-download', type: 'file', title: t('landingpage.downloadFile') },
			{ icon: 'fa-hand-pointer-o', type: 'button', title: t('admin.button') },
			{ icon: 'fa-share', type: 'share', title: t('landingpage.shareSocial') }
		];

		if (!this.props.isThankUElem) {
			this.types = this.types.concat([
				{ icon: 'fa-clock-o', type: 'countdown', title: t('landingpage.countdown') },
				{ icon: 'fa-map', type: 'map', title: t('landingpage.map') },
				{ icon: 'fa-address-card-o', type: 'card', title: t('landingpage.card') }
			]);

			var form = { icon: 'fa-file-text', type: 'form', title: t('form.form') };
			if (this.props.hasForm) {
				form.disabled = true;
			}
			this.types.push(form);
		} else {
			this.types.push({ icon: 'fa-globe', type: 'landingPage', title: t('form.redirectToExternalPage') });
		}
	},
	selectType: function (type) {
		if (type === 'file') {
			globalTracker.track('Forms and landingpages download file button');
		}
		this.props.onSelect(type);
	},
	render: function () {
		var self = this;

		var types = _.map(self.types, function (typeObj, i) {
			var classes = 'content-type-card-inner';
			var itemInner;
			var content = (
				<span>
					<b className={'fa ' + typeObj.icon} /> {typeObj.title}
				</span>
			);
			if (typeObj.disabled) {
				classes += ' disabled';
				itemInner = ReactTemplates.TOOLS.withTooltip(
					<div className={classes}>{content}</div>,
					self.lang.existingForm,
					{ placement: 'left' }
				);
			} else {
				itemInner = <div className={classes}>{content}</div>;
			}

			return (
				<div
					key={'type-' + i}
					data-test-id={'form-landing-' + typeObj.type}
					className="content-type-card"
					onClick={typeObj.disabled ? null : self.selectType.bind(self, typeObj.type)}
				>
					{itemInner}
				</div>
			);
		});

		var info = null;
		if (this.props.isThankUElem) {
			info = (
				<div className="thanku-element-info">
					<h3>{self.lang.aboutThanku}</h3>
					<p>
						{self.lang.aboutThankuBody}{' '}
						<ReactTemplates.elevio articleId="255" text={self.lang.aboutThankuExample} sidebar="true" />
					</p>
				</div>
			);
		}

		return (
			<div className="content-type-card-wrapper">
				{types}
				{info}
			</div>
		);
	}
});

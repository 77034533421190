import SocialEvent from 'Resources/SocialEvents';
import EasyBookingSettings from 'Resources/EasyBookingSettings';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

const getFakeFilterConfigs = () => {
	const configs = {
		User: {
			filterName: 'User',
			type: 'list',
			dataPromise: function ($q) {
				const options = {
					accessType: Tools.AppService.AccessType.CONTACT,
					roleIdPrefix: 'role-'
				};

				return $q.when({ data: window.UserRoleTree(options) });
			}
		},
		SocialEvent: {
			filterName: 'SocialEvent',
			type: 'list',
			includeFields: ['name', 'id'],
			searchField: ['name', 'internalName'],
			initialData: values => {
				const rb = new RequestBuilder();
				rb.addFilter({ field: 'id' }, comparisonTypes.Equals, values);
				return SocialEvent.find(rb.build());
			},
			displayText: function (obj, isSelection) {
				if (isSelection) {
					return obj.name ? obj.name : obj.internalName;
				}
				return (
					'<div>' +
					(obj.name ? obj.name : obj.internalName) +
					(obj.internalName && obj.name ? '<div class="subtitle">' + obj.internalName + '</div>' : '') +
					'</div>'
				);
			},

			searchFn: function (RequestBuilder) {
				return function (term, fields, offset, limit) {
					var filter = new RequestBuilder();
					filter.fields = fields;
					filter.offset = offset;
					filter.limit = limit;
					filter.addSort(SocialEvent.attr.internalName, true);

					if (term) {
						const or = filter.orBuilder();
						or.next();
						or.addFilter(SocialEvent.attr.name, filter.comparisonTypes.Wildcard, term);
						or.next();
						or.addFilter(SocialEvent.attr.internalName, filter.comparisonTypes.Wildcard, term);
						or.done();
					}

					return SocialEvent.find(filter.build());
				};
			},
			allowAllComperator: true
		},
		Page: {
			filterName: 'Page',
			resource: 'LeaderPage',
			type: 'list',
			idAttr: Tools.LeaderPage.attr.url,
			searchFn: function (LeaderPage, RequestBuilder) {
				return function (term, fields, offset, limit) {
					const self = Tools.AppService.getSelf();
					if (!self.administrator && !self.userParams.mailAdmin) {
						return Promise.resolve();
					}

					var filter = new RequestBuilder();
					filter.offset = offset;
					filter.limit = limit;
					filter.addSort(LeaderPage.attr.pageImpression, false);

					if (term) {
						var Or = filter.orBuilder();
						Or.next();
						Or.addFilter(LeaderPage.attr.url, filter.comparisonTypes.Search, term);
						Or.next();
						Or.addFilter(LeaderPage.attr.name, filter.comparisonTypes.Search, term);
						Or.done();
					}

					return LeaderPage.find(filter.build());
				};
			},
			allowAllComperator: true
		},
		MailCampaign: {
			filterName: 'MailCampaign',
			includeFields: ['name', 'subject', 'id'],
			resource: 'MailCampaign',
			type: 'list',
			displayText: function (obj, isSelection) {
				if (isSelection) {
					return obj.name ? obj.name : obj.subject;
				}
				return (
					'<div>' +
					(obj.name ? obj.name : obj.subject) +
					(obj.subject && obj.name ? '<div class="subtitle">' + obj.subject + '</div>' : '') +
					'</div>'
				);
			},
			searchFn: function (MailCampaign, RequestBuilder) {
				return function (term, fields, offset, limit) {
					var filter = new RequestBuilder();
					filter.fields = fields;
					filter.offset = offset;
					filter.limit = limit;
					filter.addSort(MailCampaign.attr.subject, true);

					if (term) {
						const or = filter.orBuilder();
						or.next();
						or.addFilter(MailCampaign.attr.subject, filter.comparisonTypes.Wildcard, term);
						or.next();
						or.addFilter(MailCampaign.attr.name, filter.comparisonTypes.Wildcard, term);
						or.done();
					}

					return MailCampaign.find(filter.build());
				};
			},
			allowAllComperator: true
		},
		Form: {
			filterName: 'Form',
			includeFields: ['name', 'id'],
			resource: 'Form',
			type: 'list',
			searchFn: function (Form, RequestBuilder) {
				return function (term, fields, offset, limit) {
					var filter = new RequestBuilder();
					filter.fields = fields;
					filter.offset = offset;
					filter.limit = limit;
					filter.addSort(Form.attr.name, true);

					if (term) {
						filter.addFilter(Form.attr.name, filter.comparisonTypes.Search, term);
					}

					return Form.find(filter.build());
				};
			},
			allowAllComperator: true
		},
		AppointmentOutcome: {
			filterName: 'AppointmentOutcome',
			type: 'list',
			dataPromise: function () {
				return Tools.AppService.getStaticValuesPromise('appointmentOutcome').then(data => ({ data }));
			}
		},
		EasyBooking: {
			filterName: 'EasyBooking',
			type: 'list',
			displayText: 'internalName',
			includeFields: ['internalName', 'id'],
			searchField: 'internalName',
			dataPromise: function () {
				const rb = new RequestBuilder();
				rb.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
				rb.addSort({ field: 'internalName' }, true);
				return EasyBookingSettings.find(rb.build());
			}
		}
		// Should be added later

		// Callist: {
		// 	filterName: 'Callist',
		// 	includeFields: ['name', 'id'],
		// 	resource: 'Project',
		// 	type: 'list',
		// 	searchFn: function (Project, RequestBuilder) {
		// 		return function (term, fields, offset, limit) {
		// 			var filter = new RequestBuilder();
		// 			filter.fields = fields;
		// 			filter.offset = offset;
		// 			filter.limit = limit;
		// 			filter.addSort({ field: 'name' }, true);

		// 			if (term) {
		// 				filter.addFilter(Project.attr.name, filter.comparisonTypes.Search, term);
		// 			}

		// 			const customerId = Tools.AppService.getCustomerId();
		// 			return Project.customer(customerId).find(filter.build());
		// 		};
		// 	}
		// }
	};

	if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS)) {
		delete configs.SocialEvent;
	}
	if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EASY_BOOKING_PRO)) {
		delete configs.EasyBooking;
	}

	if (Tools.AppService.getTotals('products') > 4000) {
		configs.ProductProductCategory = {
			filterName: 'ProductProductCategory',
			type: 'list',
			resource: 'Product',
			searchFn: function () {
				var cId = Tools.AppService.getCustomerId();

				return function (term, fields, offset, limit) {
					var filter = new Tools.RequestBuilder();
					filter.fields = fields;
					filter.offset = offset;
					filter.limit = limit;

					filter.addSort(Tools.Product.attr.name, true);

					if (term) {
						filter.addFilter(Tools.Product.attr.name, filter.comparisonTypes.Search, term);
					}

					filter.addFilter(Tools.Product.attr.active, filter.comparisonTypes.Equals, 1);

					const categories = _.cloneDeep(Tools.AppService.getProductCategories(false));

					const filteredCategories = categories.filter(category =>
						(category.name || '').toLowerCase().includes(term.toLowerCase())
					);

					const updatedCategories = filteredCategories.map(category => {
						category.id = `category-${category.id}`;
						return category;
					});

					return new Promise((resolve, reject) => {
						Tools.Product.customer(cId)
							.find(filter.build())
							.then(res => {
								res.data = res.data.concat(updatedCategories);
								res.metadata.total = res.metadata.total + updatedCategories.length;
								resolve(res);
							})
							.catch(() => reject());
					});
				};
			}
		};
	} else {
		configs.ProductProductCategory = {
			filterName: 'ProductProductCategory',
			type: 'list',
			dataPromise: function () {
				var onlyActive = true;
				var skipAuth = false;
				var selectableCategories = true;
				var data = window.Tools.ProductTree(onlyActive, skipAuth, selectableCategories);
				return Tools.$q.when(data);
			},
			allowAllComperator: true
		};
	}

	return configs;
};

window.Tools.getFakeFilterConfigs = getFakeFilterConfigs;

export default getFakeFilterConfigs;

import async from 'async';
import { preview } from 'App/helpers/creatives';

angular.module('domain.engage').controller('ViewAdDashboard', [
	'$scope',
	'$state',
	'$stateParams',
	'AppService',
	'Ads',
	'File',
	'$translate',
	'AdService',
	'RequestBuilder',
	'Account',
	'FilterHelper',
	function (
		$scope,
		$state,
		$stateParams,
		AppService,
		Ads,
		File,
		$translate,
		AdService,
		RequestBuilder,
		Account,
		FilterHelper
	) {
		var ViewAdDashboardCtrl = this;
		var ViewAdCtrl = $scope.ViewAdCtrl;
		var now = moment();
		var endDate = moment(ViewAdCtrl.ad.endDate);

		ViewAdDashboardCtrl.graphMinDate = moment(ViewAdCtrl.ad.startDate).subtract(1, 'days');
		ViewAdDashboardCtrl.graphMaxDate = endDate.isBefore(now) ? endDate.endOf('day') : moment().endOf('day');
		ViewAdDashboardCtrl.loadingCreative = true;
		ViewAdDashboardCtrl.loadingSites = true;
		ViewAdDashboardCtrl.loadingClients = true;
		ViewAdDashboardCtrl.loadingChart = true;
		ViewAdDashboardCtrl.customerId = $stateParams.customerId;
		ViewAdDashboardCtrl.graph = {};

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			if (ViewAdCtrl.ad.id === updated.id) {
				ViewAdCtrl.ad = updated;
				init();
			}
		});

		$scope.$on('account.added', function (e, added) {
			var existingIndex = _.findIndex(ViewAdDashboardCtrl.client, { id: added.id });
			if (existingIndex !== -1) {
				ViewAdDashboardCtrl.client[existingIndex] = added;
				return;
			}
		});

		ViewAdDashboardCtrl.changeDate = function (type) {
			var start, end;
			ViewAdDashboardCtrl.loadingChart = true;
			if (type === 'start' || type === 'end') {
				ViewAdDashboardCtrl.selectedDate = 'customDate';
				start = moment(ViewAdDashboardCtrl.graph.startDate).startOf('day');
				end = moment(ViewAdDashboardCtrl.graph.endDate).endOf('day');
				if (type === 'start') {
					if (end.isBefore(start)) {
						ViewAdDashboardCtrl.graph.endDate = start.toDate();
					}
				} else if (type === 'end') {
					if (end.isBefore(start)) {
						ViewAdDashboardCtrl.graph.startDate = end.toDate();
					}
				}
			} else {
				ViewAdDashboardCtrl.graph.startDate = type.startDate;
				ViewAdDashboardCtrl.graph.endDate = type.endDate;
				ViewAdDashboardCtrl.selectedDate = type.name;
				ViewAdDashboardCtrl.graph.startDate = moment(ViewAdDashboardCtrl.graph.startDate)
					.startOf('day')
					.toDate();
				ViewAdDashboardCtrl.graph.endDate = moment(ViewAdDashboardCtrl.graph.endDate).endOf('day').toDate();
			}
			var filter = {};
			Ads.customer(ViewAdDashboardCtrl.customerId)
				.getDays(ViewAdCtrl.ad.id, filter)
				.then(function (res) {
					ViewAdDashboardCtrl.statsPerDay = res.data;
					_.remove(ViewAdDashboardCtrl.chartConfig.xAxis[0].categories, function () {
						return true;
					});
					_.remove(ViewAdDashboardCtrl.chartConfig.series[0].data, function () {
						return true;
					});
					_.remove(ViewAdDashboardCtrl.chartConfig.series[1].data, function () {
						return true;
					});
					ViewAdDashboardCtrl.loadingChart = false;
					ViewAdDashboardCtrl.createGraph();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingChartErr = err;
					ViewAdDashboardCtrl.loadingChart = false;
				});
		};

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			if (ViewAdCtrl && ViewAdCtrl.id === updated.id) {
				ViewAdCtrl = updated;
				init();
			}
		});
		ViewAdDashboardCtrl.createGraph = function () {
			if (!ViewAdDashboardCtrl.statsPerDay || !ViewAdDashboardCtrl.statsPerDay.length) {
				return;
			}
			var sd = moment(ViewAdDashboardCtrl.graph.startDate);
			var plotStartDate = Date.UTC(sd.format('YYYY'), sd.format('M') - 1, sd.format('D'));
			var a = angular.copy(sd);
			var b = ViewAdDashboardCtrl.graph.endDate;
			ViewAdDashboardCtrl.chartConfig.series[0].pointStart = plotStartDate;
			ViewAdDashboardCtrl.chartConfig.series[1].pointStart = plotStartDate;
			for (var m = a; m.isBefore(b); m.add('days', 1)) {
				var impressions = 0;
				var clicks = 0;
				for (var j = 0; j < ViewAdDashboardCtrl.statsPerDay.length; j++) {
					var tmp = moment(ViewAdDashboardCtrl.statsPerDay[j].date).format('YYYYMMDD');
					if (tmp === m.format('YYYYMMDD')) {
						impressions = ViewAdDashboardCtrl.statsPerDay[j].impressions || 0;
						clicks = ViewAdDashboardCtrl.statsPerDay[j].clicks || 0;
					}
				}
				ViewAdDashboardCtrl.chartConfig.series[0].data.push(impressions);
				ViewAdDashboardCtrl.chartConfig.series[1].data.push(clicks);
			}
		};

		ViewAdDashboardCtrl.previewPopup = function (creative) {
			preview({ id: creative.creativeId, isGroup: false });
		};

		ViewAdDashboardCtrl.loadCreatives = function (cb) {
			ViewAdDashboardCtrl.loadingCreative = true;
			Ads.customer($scope.customerId)
				.findCreative({})
				.then(function (res) {
					var creatives = res.data;
					_.forEach(ViewAdCtrl.ad.creative, function (creative) {
						var found = _.find(creatives, { id: creative.creativeId });
						if (found) {
							creative.name = found.name;
							creative.format = found.format;
							creative.width = found.width;
							creative.height = found.height;
						}
					});
					ViewAdDashboardCtrl.loadingCreative = false;
					return cb();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingCreativeErr = err;
					ViewAdDashboardCtrl.loadingCreative = false;
					return cb(err);
				});
		};

		ViewAdDashboardCtrl.loadSites = function (cb) {
			ViewAdDashboardCtrl.loadingSites = true;
			Ads.customer(ViewAdDashboardCtrl.customerId)
				.getSites(ViewAdCtrl.ad.id, { limit: 10, sort: 'impressions', desc: true })
				.then(function (res) {
					ViewAdDashboardCtrl.sites = res.data;
					ViewAdDashboardCtrl.loadingSites = false;
					return cb();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingSitesErr = err;
					ViewAdDashboardCtrl.loadingSites = false;
				});
		};

		ViewAdDashboardCtrl.loadDevices = function (cb) {
			ViewAdDashboardCtrl.loadingDevices = true;
			Ads.customer(ViewAdDashboardCtrl.customerId)
				.getDevices(ViewAdCtrl.ad.id, { limit: 2, sort: 'impressions', desc: true })
				.then(function (res) {
					ViewAdDashboardCtrl.devices = res.data;
					ViewAdDashboardCtrl.loadingDevices = false;
					return cb();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingDevicesErr = err;
					ViewAdDashboardCtrl.loadingDevices = false;
				});
		};

		ViewAdDashboardCtrl.loadLanguages = function (cb) {
			ViewAdDashboardCtrl.loadingLanguages = true;
			Ads.customer(ViewAdDashboardCtrl.customerId)
				.getLanguages(ViewAdCtrl.ad.id, { limit: 5, sort: 'impressions', desc: true })
				.then(function (res) {
					ViewAdDashboardCtrl.languages = res.data;
					ViewAdDashboardCtrl.loadingLanguages = false;
					return cb();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingLanguagesErr = err;
					ViewAdDashboardCtrl.loadingLanguages = false;
				});
		};

		ViewAdDashboardCtrl.loadLocations = function (cb) {
			ViewAdDashboardCtrl.loadingLocations = true;
			Ads.customer(ViewAdDashboardCtrl.customerId)
				.getLocations(ViewAdCtrl.ad.id, { limit: 5, sort: 'impressions', desc: true })
				.then(function (res) {
					ViewAdDashboardCtrl.locations = res.data;
					ViewAdDashboardCtrl.loadingLocations = false;
					return cb();
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingLocationsErr = err;
					ViewAdDashboardCtrl.loadingLocations = false;
				});
		};

		ViewAdDashboardCtrl.loadClients = function (sortByClick, cb) {
			if (typeof sortByClick === 'function') {
				cb = sortByClick;
				sortByClick = false;
			}

			var filters = new RequestBuilder();
			var groupBuilder = filters.groupBuilder();
			groupBuilder.addFilter({ field: 'adCampaign.id' }, filters.comparisonTypes.Equals, ViewAdCtrl.ad.id);
			groupBuilder.done();
			filters.limit = 15;
			filters.offset = 0;
			ViewAdDashboardCtrl.sortClientByClick = !!sortByClick;
			if (ViewAdDashboardCtrl.sortClientByClick) {
				filters.sorting = [
					{
						attribute: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'clicks'),
						ascending: false
					}
				];
			} else {
				filters.sorting = [
					{
						attribute: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'impressions'),
						ascending: false
					}
				];
			}

			ViewAdDashboardCtrl.loadingClients = true;

			Account.customer(ViewAdCtrl.customerId)
				.find(filters.build(), { mapCustom: true, includeExternal: true })
				.then(function (res) {
					ViewAdDashboardCtrl.client = res.data;
					ViewAdDashboardCtrl.client = _.filter(ViewAdDashboardCtrl.client, function (client) {
						if (ViewAdDashboardCtrl.sortClientByClick) {
							return client.$adCampaign[ViewAdCtrl.ad.id].clicks !== 0;
						}
						return client.$adCampaign[ViewAdCtrl.ad.id].impressions !== 0;
					});
					ViewAdDashboardCtrl.loadingClients = false;
					if (cb) {
						return cb();
					}
				})
				.catch(function (err) {
					ViewAdDashboardCtrl.loadingClientsErr = err;
					ViewAdDashboardCtrl.loadingClients = false;
					if (cb) {
						return cb(err);
					}
				});
		};

		function init() {
			var now = moment();
			var useAsEndDate = moment(ViewAdCtrl.ad.endDate).endOf('day').isBefore(now) ? ViewAdCtrl.ad.endDate : now;

			if (!ViewAdCtrl.userEditable) {
				// don't show dash if you don't have permissions
				return $state.go('viewAd.creatives', ViewAdCtrl.ad);
			}

			ViewAdDashboardCtrl.chartConfig = {
				chart: {
					zoomType: 'xy'
				},
				options: {
					chart: {
						backgroundColor: '#F6F9FB',
						plotBackgroundColor: '#F6F9FB',
						style: {
							fontFamily: '"Roboto"'
						}
					}
				},
				credits: {
					enabled: false
				},
				title: {
					text: ''
				},
				size: {
					height: 250
				},
				xAxis: [
					{
						crosshair: true,
						type: 'datetime',
						dateTimeLabelFormats: {
							day: '%e %b'
						}
					}
				],
				yAxis: [
					{
						// Primary yAxis
						floor: 0,
						title: {
							text: $translate.instant('ads.clicks'),
							style: {
								color: '#9933C0'
							}
						}
					},
					{
						// Secondary yAxis
						floor: 0,
						title: {
							text: $translate.instant('ads.impressions'),
							style: {
								color: '#B254E0'
							}
						},
						opposite: true
					}
				],
				tooltip: {
					shared: true
				},
				legend: {
					layout: 'vertical',
					align: 'left',
					x: 120,
					verticalAlign: 'top',
					y: 100,
					floating: true
				},
				series: [
					{
						name: $translate.instant('ads.impressions'),
						type: 'column',
						yAxis: 1,
						data: [],
						endOnTick: true,
						color: '#E1C2F1',
						pointStart: Date.UTC(2010, 0, 1),
						pointInterval: 24 * 3600 * 1000, // one day
						tooltip: {
							valueSuffix: ''
						}
					},
					{
						name: $translate.instant('ads.clicks'),
						type: 'spline',
						data: [],
						endOnTick: true,
						color: '#721A94',
						pointStart: Date.UTC(2010, 0, 1),
						pointInterval: 24 * 3600 * 1000, // one day
						tooltip: {
							valueSuffix: ''
						}
					}
				]
			};
			async.series([
				ViewAdDashboardCtrl.loadClients,
				ViewAdDashboardCtrl.loadCreatives,
				ViewAdDashboardCtrl.loadSites,
				ViewAdDashboardCtrl.loadDevices
			]);
			ViewAdDashboardCtrl.customDates = AdService.customReportDates(ViewAdCtrl.ad.startDate, useAsEndDate);
			ViewAdDashboardCtrl.changeDate(ViewAdDashboardCtrl.customDates[0]);
		}

		AppService.loadedPromise.then(init).catch(e => console.log('load engage viewDashboard error', e));
	}
]);

import React, { ReactNode } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Card, Headline, Row, Column, Button, Text } from '@upsales/components';
import './MailTemplatePreviewAddMenu.scss';

const columnLayouts = [12, 6, 4, 3, 2.4, 2, '70/30', '30/70'] as const;

type Props = {
	onSelect: (value: number | string) => void;
	onClose?: () => void;
	header: ReactNode;
	className: string;
};

const MailTemplatePreviewAddMenu = ({ onSelect, onClose, header, className }: Props) => {
	const classes = new bemClass('MailTemplatePreviewAddMenu', className);

	return (
		<Card color="medium-green" className={classes.b()} data-testid="MailTemplatePreviewAddMenu">
			<Headline size="xs" color="super-light-green">
				{header}
				{onClose ? (
					<Button color="super-light-green" hoverColor="white" type="link" onClick={() => onClose()}>
						<Icon name="times" />
					</Button>
				) : null}
			</Headline>
			{columnLayouts.map(num => {
				let cols = null;
				const c = 'medium-green';
				if (num === '70/30') {
					cols = (
						<React.Fragment>
							<Column size={8}>
								<Text color={c}>{'70%'}</Text>
							</Column>
							<Column>
								<Text color={c}>{'30%'}</Text>
							</Column>
						</React.Fragment>
					);
				} else if (num === '30/70') {
					cols = (
						<React.Fragment>
							<Column>
								<Text color={c}>{'30%'}</Text>
							</Column>
							<Column size={8}>
								<Text color={c}>{'70%'}</Text>
							</Column>
						</React.Fragment>
					);
				} else {
					const t = <Text color={c}>{parseInt(String((num / 12) * 100)) + '%'}</Text>;
					cols = Array.from(Array(12 / num)).map((_col, i) => <Column key={i}>{t}</Column>);
				}
				return (
					<Row
						key={num}
						onClick={() => {
							if (onClose) {
								onClose();
							}
							onSelect(num);
						}}
					>
						{cols}
					</Row>
				);
			})}
		</Card>
	);
};

export default MailTemplatePreviewAddMenu;

import Attribute from './Attribute';

const JourneyStepHistoryAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	clientId: new Attribute({
		type: Attribute.types.Number,
		field: 'client_id'
	}),
	contactId: new Attribute({
		type: Attribute.types.Number,
		field: 'contact_id'
	}),
	userId: new Attribute({
		type: Attribute.types.Number,
		field: 'user_id'
	}),
	value: new Attribute({
		type: Attribute.types.String,
		field: 'value'
	}),
	oldValue: new Attribute({
		type: Attribute.types.String,
		field: 'old_value'
	}),
	date: new Attribute({
		type: Attribute.types.DateTime,
		field: 'date'
	})
};

export default JourneyStepHistoryAttributes;

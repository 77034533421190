import Resource from './Resource';

class StandardField extends Resource {
	constructor() {
		super('standardField', null, { notificationTitle: 'admin.standardfield' });
	}
}

const resource = new StandardField();

export default resource;

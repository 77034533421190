import React from 'react';
import { Headline } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import FileBrowserFile from './FileBrowserFile';
import './FileBrowserUploadedFiles.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import File from 'App/resources/Model/File';

interface FileBrowserUploadedFilesProps {
	files: File[];
	deleteFile: (file: File) => void;
	onSelect: (file: File) => void;
	checkedUploadedFiles: File[];
	setCheckedUploadedFiles: (files: File[]) => void;
}

const FileBrowserUploadedFiles: React.FC<FileBrowserUploadedFilesProps> = ({
	files,
	deleteFile,
	onSelect,
	checkedUploadedFiles,
	setCheckedUploadedFiles
}) => {
	if (!files.length) {
		return null;
	}

	const updateCheckedFiles = (checked: boolean, file: File) => {
		if (checked) {
			setCheckedUploadedFiles([...checkedUploadedFiles, file]);
		} else {
			setCheckedUploadedFiles(checkedUploadedFiles.filter(f => f.id !== file.id));
		}
	};

	const classes = new bemClass('FileBrowserUploadedFiles');

	return (
		<div className={classes.b()}>
			<Headline size="xs">{translate('file.uploadedPlural')}</Headline>

			<div className={classes.elem('thumbnails').b()}>
				{files.map(f => (
					<FileBrowserFile
						key={f.id || f.filename}
						file={f}
						deleteFile={deleteFile}
						onSelect={onSelect}
						checkFile={updateCheckedFiles}
						isChecked={checkedUploadedFiles.some(file => file.id === f.id)}
					/>
				))}
			</div>
		</div>
	);
};

export default FileBrowserUploadedFiles;

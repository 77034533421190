'use strict';

angular
	.module('upDirectives')

	.controller('PaginationController', [
		'$scope',
		'$attrs',
		'$parse',
		'$location',
		function ($scope, $attrs, $parse, $location) {
			var self = this,
				ngModelCtrl = {
					$setViewValue: angular.noop
				}, // nullModelCtrl
				setNumPages = $attrs.numPages ? $parse($attrs.numPages).assign : angular.noop,
				setOffset = $attrs.offset ? $parse($attrs.offset).assign : angular.noop;

			this.init = function (ngModelCtrl_, config) {
				ngModelCtrl = ngModelCtrl_;
				this.config = config;

				ngModelCtrl.$render = function () {
					self.render();
				};

				if ($attrs.itemsPerPage) {
					$scope.$parent.$watch($parse($attrs.itemsPerPage), function (value) {
						self.itemsPerPage = parseInt(value, 10);
						$scope.totalPages = self.calculateTotalPages();
					});
				} else {
					this.itemsPerPage = config.itemsPerPage;
				}
			};

			this.calculateTotalPages = function () {
				var totalPages = this.itemsPerPage < 1 ? 1 : Math.ceil($scope.totalItems / this.itemsPerPage);
				return Math.max(totalPages || 0, 1);
			};

			this.render = function () {
				$scope.page = parseInt(ngModelCtrl.$viewValue, 10) || 1;
			};

			$scope.selectPage = function (page) {
				if ($scope.page !== page && page > 0 && page <= $scope.totalPages) {
					if ($attrs.disableUrlHash === undefined) {
						$location.search('p', page);
					}
					setOffset($scope.$parent, self.itemsPerPage * (page - 1)); // Readonly variable

					if ($attrs.scroll !== undefined) {
						angular.element(window).scrollTop(0);
					}

					ngModelCtrl.$setViewValue(page);
					ngModelCtrl.$render();
					$scope.ngChange({ $page: page });
				}
			};

			if ($attrs.disableUrlHash === undefined) {
				$scope.$watch(function () {
					return ~~$location.search().p;
				}, $scope.selectPage);
			}

			$scope.getText = function (key) {
				return $scope[key + 'Text'] || self.config[key + 'Text'];
			};
			$scope.noPrevious = function () {
				return $scope.page === 1;
			};
			$scope.noNext = function () {
				return $scope.page === $scope.totalPages;
			};

			$scope.$watch('totalItems', function () {
				$scope.totalPages = self.calculateTotalPages();
			});

			$scope.$watch('totalPages', function (value) {
				setNumPages($scope.$parent, value); // Readonly variable

				if ($scope.page > value) {
					$scope.selectPage(value);
				} else {
					ngModelCtrl.$render();
				}
			});
		}
	])

	.constant('paginationConfig', {
		itemsPerPage: 10,
		// boundaryLinks: true,
		// directionLinks: true,
		firstText: '1',
		previousText: '‹',
		nextText: '›',
		lastText: '53',
		rotate: true
	})

	.directive('pagination', [
		'$parse',
		'paginationConfig',
		function ($parse, paginationConfig) {
			return {
				restrict: 'EA',
				scope: {
					totalItems: '=',
					firstText: '@',
					previousText: '@',
					nextText: '@',
					lastText: '@',
					ngChange: '&ngChange'
				},
				require: ['pagination', '?ngModel'],
				controller: 'PaginationController',
				templateUrl: require('App/upsales/common/directives/templates/upPaginator.tpl.html?file'),
				replace: true,
				link: function (scope, element, attrs, ctrls) {
					var paginationCtrl = ctrls[0],
						ngModelCtrl = ctrls[1];

					// if (!ngModelCtrl) {
					// 	return; // do nothing if no ng-model
					// }

					// Setup configuration parameters
					var maxSize = angular.isDefined(attrs.maxSize)
							? scope.$parent.$eval(attrs.maxSize)
							: paginationConfig.maxSize,
						rotate = angular.isDefined(attrs.rotate)
							? scope.$parent.$eval(attrs.rotate)
							: paginationConfig.rotate;

					// scope.boundaryLinks = angular.isDefined(attrs.boundaryLinks) ? scope.$parent.$eval(attrs.boundaryLinks) : paginationConfig.boundaryLinks;
					// scope.directionLinks = angular.isDefined(attrs.directionLinks) ? scope.$parent.$eval(attrs.directionLinks) : paginationConfig.directionLinks;

					paginationCtrl.init(ngModelCtrl, paginationConfig);

					if (attrs.maxSize) {
						scope.$parent.$watch($parse(attrs.maxSize), function (value) {
							maxSize = parseInt(value, 10);
							paginationCtrl.render();
						});
					}

					// Create page object used in template
					function makePage(number, text, isActive) {
						return {
							number: number,
							text: text,
							active: isActive
						};
					}

					function getPages(currentPage, totalPages) {
						var pages = [];
						// Default page limits
						var startPage = 1,
							endPage = totalPages;
						var isMaxSized = angular.isDefined(maxSize) && maxSize < totalPages;

						// recompute if maxSize
						if (isMaxSized) {
							if (rotate) {
								// Current page is displayed in the middle of the visible ones
								startPage = Math.max(currentPage - Math.floor(maxSize / 2), 1);
								endPage = startPage + maxSize - 1;

								// Adjust if limit is exceeded
								if (endPage > totalPages) {
									endPage = totalPages;
									startPage = endPage - maxSize + 1;
								}
							} else {
								// Visible pages are paginated with maxSize
								startPage = (Math.ceil(currentPage / maxSize) - 1) * maxSize + 1;

								// Adjust last page if limit is exceeded
								endPage = Math.min(startPage + maxSize - 1, totalPages);
							}
						}

						if (startPage === 2) {
							pages.push(makePage(1, 1, false));
						}
						if (startPage > 2) {
							pages.push(makePage(1, 1, false));
							pages.push(makePage(startPage - 1, '...', false));
						}

						// Add page number links
						for (var number = startPage; number <= endPage; number++) {
							pages.push(makePage(number, number, number === currentPage));
						}

						if (endPage < totalPages - 1) {
							pages.push(makePage(endPage + 1, '...', false));
							pages.push(makePage(totalPages, totalPages, false));
						}
						if (endPage === totalPages - 1) {
							pages.push(makePage(totalPages, totalPages, false));
						}

						// Add links to move between page sets
						if (isMaxSized && !rotate) {
							if (startPage > 1) {
								// prevPageSet
								pages.unshift(makePage(startPage - 1, '...', false));
							}

							if (endPage < totalPages) {
								// nextPageSet
								pages.push(makePage(endPage + 1, '...', false));
							}
						}

						return pages;
					}

					var originalRender = paginationCtrl.render;
					paginationCtrl.render = function () {
						originalRender();
						if (scope.page > 0 && scope.page <= scope.totalPages) {
							scope.pages = getPages(scope.page, scope.totalPages);
						}
					};
				}
			};
		}
	]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Bem from '@upsales/components/Utils/bemClass';
import { Title, Label, Input, Row, Column } from '@upsales/components';
import { setCompanyProfileData } from 'Store/reducers/OnboardingReducer';

const mapDispatchToProps = {
	setCompanyProfileData
};

const mapStateToProps = state => ({
	companyProfile: state.Onboarding.companyProfile,
	currentStepId: state.Onboarding.currentStepId,
	currentSubstepId: state.Onboarding.currentSubstepId
});

class CompanyProfileContacts extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			contactTitle: T('onboarding.step.companyProfile.contact.maintitle'),
			socialTitle: T('onboarding.step.companyProfile.socialaccounts.maintitle'),
			input: {
				phone: T('tag.client.phone'),
				email: T('tag.general.companyemail'),
				linkedin: T('account.socialLinkedIn'),
				facebook: T('default.facebook'),
				twitter: T('default.twitter')
			}
		};

		this.defaultChange = this.defaultChange.bind(this);
	}

	defaultChange(value, key) {
		if (typeof value !== 'string' || typeof value !== 'number') {
			value = value.target.value;
		}

		const { setCompanyProfileData } = this.props;
		setCompanyProfileData(key, value);
	}

	render() {
		const MainClass = new Bem('CompanyProfile');
		const { companyProfile } = this.props;

		const rowKeysAbove = ['phone', 'email'];
		const rowKeysBelow = ['linkedin', 'facebook', 'twitter'];

		const content1 = rowKeysAbove.map(key => {
			return (
				<Row key={key}>
					<Column>
						<Label required={true}>{this.lang.input[key]}</Label>
						<Input value={companyProfile[key]} onChange={value => this.defaultChange(value, key)} />
					</Column>
				</Row>
			);
		});

		const content2 = rowKeysBelow.map(key => {
			return (
				<Row key={key}>
					<Column>
						<Label>{this.lang.input[key]}</Label>
						<Input value={companyProfile[key]} onChange={value => this.defaultChange(value, key)} />
					</Column>
				</Row>
			);
		});

		return (
			<div className={MainClass}>
				<Title>{this.lang.contactTitle}</Title>
				{content1}

				<Title className={MainClass.elem('SocialTitle').b()}>{this.lang.socialTitle}</Title>

				{content2}
			</div>
		);
	}
}

CompanyProfileContacts.propTypes = {
	setCompanyProfileData: PropTypes.func,
	companyProfile: PropTypes.object,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number
};

CompanyProfileContacts.setAsCompleteOnNext = true;
CompanyProfileContacts.buttonDisabledCriterias = ['email', 'phone'];

export const detached = CompanyProfileContacts;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileContacts);

import React from 'react';
import Timepicker from 'App/upsales/common/components/react/inputs/timepicker';
import logError from 'Helpers/logError';

ReactTemplates.admin.triggerCriteriaSelect = window.ReactCreateClass({
	componentDidMount: function () {
		var self = this;

		if (self['_select']) {
			var t = window.Tools.$translate;

			var element = self._select;
			var options = { placeholder: t('admin.trigger.chooseAnOption') };

			if (self.props.hideSerch) {
				options.minimumResultsForSearch = -1;
			}

			jQuery(element)
				.select2(options)
				.on('change', function (e) {
					self.props.onChange('value', { target: { value: e.val } });
				});
		}
	},
	componentDidUpdate: function () {
		var val = this.props.value;
		var input = jQuery(this._select);
		input.select2('val', val);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		return (
			<select
				className="form-control"
				value={this.props.value}
				disabled={this.props.disabled}
				onChange={this.props.onChange}
				ref={this.setRef.bind(this, '_select')}
			>
				{this.props.options}
			</select>
		);
	}
});

ReactTemplates.admin.triggerCriteriaOperatorSelect = window.ReactCreateClass({
	componentDidMount: function () {
		var self = this;

		if (self['_select']) {
			var element = self._select;

			jQuery(element)
				.select2()
				.on('change', function (e) {
					self.props.onChange('operator', { target: { value: e.val } });
				});
		}
	},
	componentDidUpdate: function () {
		var element = this._select;
		var select = $(element).select2();

		if (select.val() !== this.props.value) {
			select.select2('val', this.props.value);
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var t = Tools.$translate;
		var attribute = this.props.attribute;

		var options = _.map(attribute.operators, function (op, i) {
			return (
				<option key={'op-' + i} value={op}>
					{t('comparison.' + op)}
				</option>
			);
		});

		return (
			<select
				className="form-control"
				disabled={this.props.disabled}
				value={this.props.value}
				ref={this.setRef.bind(this, '_select')}
			>
				{options}
			</select>
		);
	}
});

ReactTemplates.admin.triggerCriteria = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		var attribute = (this.props.criteria && this.props.criteria.attribute) || '';
		this.lang = {
			yes: t('default.yes'),
			no: t('default.no'),
			open: t('trigger.criteria.open'),
			closed: t('trigger.criteria.closed'),
			empty: t('default.empty'),
			simplePlaceholder: t('admin.trigger.simplePlaceholder'),
			selectPlaceholder: t('admin.trigger.chooseAnOption'),
			criteriaRemoved: t('admin.automationCriteraConditionRemoved', { tag: attribute })
		};
	},
	componentDidMount: function () {
		var self = this;

		jQuery(self._attrSelect)
			.select2({
				allowClear: false,
				multiple: false,
				data: self.props.criterias,
				dropdownCssClass: 'select2-bigger-dropdown'
			})
			.on('change', function (e) {
				self.propChanged('attribute', { target: { value: e.val } });
			});
	},
	propChanged: function (key, e) {
		var cri = _.clone(this.props.criteria);
		cri[key] = e.target.value;
		cri.valueText = e.target.value;

		this.props.onChange(cri);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	itemChanged: function (item) {
		var cri = _.clone(this.props.criteria);
		cri.value = item ? item.id : null;
		cri.valueText = item ? item.id : null;

		this.props.onChange(cri);
	},
	booleanChanged: function (value, isCustom) {
		var cri = _.clone(this.props.criteria);
		var yes = isCustom ? 'on' : 1;
		var no = isCustom ? '' : 0;

		cri.value = value ? yes : no;
		cri.valueText = value ? 'checked' : 'unchecked';
		this.props.onChange(cri);
	},
	dateChange: function (value) {
		var cri = _.clone(this.props.criteria);
		cri.value = value;
		try {
			var date = moment(value);
			if (date.isValid()) {
				cri.valueText = date.format('L');
				cri.value = date.format('YYYY-MM-DD');
			} else {
				cri.valueText = '';
				cri.value = '';
			}
		} catch (e) {
			cri.valueText = '';
			cri.value = '';
		}
		this.props.onChange(cri);
	},
	timeChange: function (value) {
		var cri = _.clone(this.props.criteria);
		cri.value = value;
		try {
			cri.valueText = value.toISOString();
		} catch (e) {
			cri.valueText = '';
		}
		this.props.onChange(cri);
	},
	getItem: function (Resource) {
		return function (element, callback) {
			// eslint-disable-next-line no-useless-escape
			var value = typeof element.val() === 'string' ? element.val().replace(/\"/g, '') : '';

			if (value) {
				Resource.customer(Tools.AppService.getCustomerId())
					.get(value)
					.then(function (res) {
						if (res && res.data) {
							callback(res.data);
						}
					})
					.catch(logError);
			}
		};
	},
	getValueInput: function (selectedAttribute) {
		var disabled = this.props.disabled;
		var criteria = this.props.criteria;
		var tools = this.props.tools;
		var t = tools.$translate;
		var key = 'value-select-' + criteria.attribute;
		var options;

		if (selectedAttribute.alias === 'TURNOVER') {
			options = _.map(selectedAttribute.selectOptions, function (op, i) {
				var text = op[selectedAttribute.selectText];
				if (selectedAttribute.type === 'country') {
					text = t(text);
				}

				return (
					<option key={'opt-' + i} value={op.id}>
						{text}
					</option>
				);
			});
			var valueIsPredefinedOption = _.find(selectedAttribute.selectOptions, { id: parseInt(criteria.value) });
			var selectValue = valueIsPredefinedOption ? valueIsPredefinedOption.id : null;

			var select = (
				<ReactTemplates.admin.triggerCriteriaSelect
					key={key + '-select'}
					onChange={this.propChanged}
					disabled={disabled}
					options={options}
					value={selectValue}
					hideSerch={true}
				/>
			);

			return (
				<div className="select2-number-suggestion">
					{select}
					{/* eslint-disable-next-line react/jsx-no-undef */}
					<Input.input
						key={key + '-value'}
						type="number"
						placeholder={this.lang.simplePlaceholder}
						className="form-control"
						value={criteria.value}
						onChange={this.propChanged.bind(this, 'value')}
						disabled={disabled}
					/>
				</div>
			);
		}

		switch (selectedAttribute.type) {
			case 'number':
			case 'integer':
			case 'currency':
			case 'id':
			case 'percent':
				return (
					// eslint-disable-next-line react/jsx-no-undef
					<Input.input
						maxLength="128"
						key={key}
						min={selectedAttribute.min}
						max={selectedAttribute.max}
						type="number"
						placeholder={this.lang.simplePlaceholder}
						className="form-control"
						value={criteria.value}
						onChange={this.propChanged.bind(this, 'value')}
						disabled={disabled}
					/>
				);
			case 'string':
			case 'email':
			case 'link':
				return (
					// eslint-disable-next-line react/jsx-no-undef
					<Input.input
						maxLength="128"
						key={key}
						type="text"
						placeholder={this.lang.simplePlaceholder}
						className="form-control"
						value={criteria.value}
						onChange={this.propChanged.bind(this, 'value')}
						disabled={disabled}
					/>
				);

			case 'openOrClosed':
			case 'boolean':
				var truthy = ['on', '1', 1, true, 'true'];
				var falsey = ['', '0', 0, false, 'false'];
				var yesClass =
					truthy.indexOf(criteria.value) >= 0
						? 'btn up-btn btn-bright-blue no-shadow'
						: 'btn up-btn btn-light-grey no-shadow';
				var noClass =
					falsey.indexOf(criteria.value) >= 0
						? 'btn up-btn btn-bright-blue no-shadow'
						: 'btn up-btn btn-light-grey no-shadow';

				return (
					<div className="btn-group boolean-critera">
						<button
							className={yesClass}
							onClick={this.booleanChanged.bind(this, true, selectedAttribute.customfield)}
							disabled={disabled}
						>
							{selectedAttribute.type === 'openOrClosed' ? this.lang.open : this.lang.yes}
						</button>
						<button
							className={noClass}
							onClick={this.booleanChanged.bind(this, false, selectedAttribute.customfield)}
							disabled={disabled}
						>
							{selectedAttribute.type === 'openOrClosed' ? this.lang.closed : this.lang.no}
						</button>
					</div>
				);
			case 'time':
				return (
					<Timepicker
						onChange={this.timeChange}
						className={'form-control'}
						value={criteria.value}
						disabled={disabled}
						placeholder={this.lang.selectPlaceholder}
						tools={tools}
						hideOnBlur={true}
					/>
				);
			case 'date':
				return React.createElement(ReactTemplates.INPUTS.datepicker, {
					onChange: this.dateChange,
					className: 'form-control',
					value: criteria.value,
					disabled: disabled,
					placeholder: this.lang.empty,
					tools: tools
				});

			case 'client':
				return React.createElement(ReactTemplates.INPUTS.upAccountsIds, {
					onChange: this.itemChanged,
					className: 'form-control',
					placeholder: this.lang.selectPlaceholder,
					value: criteria.value,
					disabled: disabled,
					tools: tools,
					select2: {
						initSelection: this.getItem(Tools.Account)
					}
				});

			case 'project':
				var campaignProps = {
					onChange: this.itemChanged,
					className: 'form-control',
					value: criteria.value || '',
					placeholder: this.lang.selectPlaceholder,
					select2: {
						initSelection: this.getItem(Tools.Campaign)
					}
				};

				return React.createElement(ReactTemplates.INPUTS.upCampaigns, campaignProps);

			case 'product':
				var productProps = {
					onChange: this.itemChanged,
					className: 'form-control',
					value: criteria.value || '',
					placeholder: this.lang.selectPlaceholder
				};

				return React.createElement(ReactTemplates.INPUTS.upProducts, productProps);
			case 'journeyStep':
				options = [
					<option key="journey-unset" value=""></option>,
					...Tools.AppService.getJourneySteps().map(step => (
						<option key={'journey-' + step.value} value={step.value}>
							{Tools.$translate(step.name)}
						</option>
					))
				];

				return (
					<ReactTemplates.admin.triggerCriteriaSelect
						key={key}
						onChange={this.propChanged}
						disabled={disabled}
						options={options}
						value={criteria.value}
					/>
				);

			default:
				var data;

				if (selectedAttribute.customfield) {
					data = selectedAttribute.selectOptions;
				} else {
					data = this.props.selectOptions[selectedAttribute.selectOptions || selectedAttribute.type];
				}

				options = _.map(data, function (op, i) {
					var idAttribute = selectedAttribute.idAttribute ? selectedAttribute.idAttribute : 'id';
					var text = op[selectedAttribute.selectText || 'name'];

					if (selectedAttribute.type === 'country') {
						text = t(text);
					}

					return (
						<option key={'opt-' + i} value={op[idAttribute]}>
							{text}
						</option>
					);
				});

				options.unshift(<option key={'opt-empty'} />);

				return (
					<ReactTemplates.admin.triggerCriteriaSelect
						key={key}
						onChange={this.propChanged}
						disabled={disabled}
						options={options}
						value={criteria.value}
					/>
				);
		}
	},
	render: function () {
		var self = this;
		var criteria = self.props.criteria;
		var selectedAttribute;
		var disabled = self.props.disabled;

		if (criteria.attribute) {
			selectedAttribute = self.props.getSelectedCriteriaObject(criteria.attribute);
		}

		// Legacy triggers that can't be selected
		if (!selectedAttribute) {
			selectedAttribute = self.props.getCriteriaUnfiltered(criteria.attribute);
		}

		var operatorSelect = null;

		if (selectedAttribute) {
			operatorSelect = (
				<div key="op-select" className="operator-select">
					<ReactTemplates.admin.triggerCriteriaOperatorSelect
						disabled={disabled}
						value={criteria.operator}
						onChange={this.propChanged}
						attribute={selectedAttribute}
					/>
				</div>
			);
		}

		var className = selectedAttribute ? 'criteria-row' : 'criteria-row unsupported-criteria';

		return (
			<div className={className}>
				{selectedAttribute ? (
					<div className="attr-select" key="attr-select">
						<input
							type="hidden"
							className="form-control"
							disabled={disabled}
							value={criteria.attribute}
							ref={self.setRef.bind(self, '_attrSelect')}
						/>
					</div>
				) : (
					<div className="attr-select" key="attr-select">
						<span className="text-danger">{self.lang.criteriaRemoved}</span>
					</div>
				)}

				{operatorSelect}

				{selectedAttribute ? (
					<div key={'value-select' + criteria.attribute} className="value-select">
						{self.getValueInput(selectedAttribute)}
					</div>
				) : null}

				{!disabled && (
					<button
						type="button"
						className="up-btn btn-link delete-trigger btn-grey"
						onClick={self.props.onDelete}
						disabled={disabled}
					>
						<b className="fa fa-times-circle" />
					</button>
				)}
			</div>
		);
	}
});

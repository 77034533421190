import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text, Block, Icon, Link } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import InactiveLabel from 'Components/Misc/InactiveLabel';
import T from 'Components/Helpers/translate';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

const getPhoneNr = (contact, searchStr, key) => {
	const tel = ReactTemplates.TOOLS.upVoice(contact, 'contact', contact[key]);
	return tel ? (
		<Link target={tel.target} href={tel.href} onClick={tel.onClick}>
			<Icon name="phone" space="mrs" />
			<SupersearchHighlight
				searchStr={searchStr}
				text={contact[key]}
				type={key === 'cellPhone' ? 'phone' : 'phone'}
			/>
		</Link>
	) : null;
};

const sendEmail = (contact, e) => {
	e.stopPropagation();
	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
		openNewMailWithContact(contact);
	} else {
		Tools.$upModal.open('sendEmail', {
			contact,
			contactId: contact.id
		});
	}
};

// eslint-disable-next-line no-unused-vars
const SupersearchResultRowContact = ({ className, item: contact, searchStr, closeSearch, ...props }) => {
	const hasMarket = Tools.FeatureHelper.isAvailableProduct('MA');
	const { name } = contact;
	const classes = new bemClass('SupersearchResultRowContact', className);

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={contact.client && contact.client.users ? contact.client.users : []}
			inactive={!contact.active}
			hideUsers={!contact.active}
			searchStr={searchStr}
			customValues={contact.custom}
			{...props}
		>
			<Column size={4}>
				<Block>
					<Text
						className="inline"
						italic={!contact.active}
						bold={true}
						color={!contact.active ? 'grey-10' : 'black'}
						size="md"
					>
						<SupersearchHighlight searchStr={searchStr} text={name} type="string" />
						{contact.score ? (
							<Text className="inline score" color="purple" size="sm">
								{`${contact.score}p`}
							</Text>
						) : null}
					</Text>
				</Block>

				<Text className="inline" color="grey-12" size="sm" italic={!contact.client}>
					{hasMarket ? (
						<Block className="inline" space="mrm">
							<ReactTemplates.TOOLS.eventTypes account={contact} />
						</Block>
					) : null}
					{contact.client ? contact.client.name : T('default.noAccount')}
				</Text>
			</Column>
			{contact.active ? (
				<Fragment>
					<Column fixedWidth={120}>
						{contact.phone ? <Text size="sm">{getPhoneNr(contact, searchStr, 'phone')}</Text> : null}
						{contact.cellPhone ? (
							<Text size="sm">{getPhoneNr(contact, searchStr, 'cellPhone')}</Text>
						) : null}
					</Column>
					<Column>
						{contact.email ? (
							<Text size="sm">
								<Link onClick={sendEmail.bind(null, contact)}>
									<SupersearchHighlight searchStr={searchStr} text={contact.email} type="email" />
								</Link>
							</Text>
						) : null}
					</Column>
				</Fragment>
			) : (
				<Fragment>
					<Column />
					<Column fixedWidth={100} align="right">
						<InactiveLabel />
					</Column>
				</Fragment>
			)}
		</SupersearchResultRow>
	);
};

SupersearchResultRowContact.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object
};

export default SupersearchResultRowContact;

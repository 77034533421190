'use strict';

angular.module('upFilters').filter('groupBy', [
	'$parse',
	function ($parse) {
		return function (collection, field) {
			if (!collection) return [];

			if (!(field || {}).groupOn) return [{ items: collection, title: false }];

			var textOnFn = _.isString(field.textOn) ? $parse(field.textOn) : field.textOn;

			return _.chain(collection)
				.groupBy($parse(field.groupOn))
				.map(function (value, key) {
					return {
						items: value,
						title: (field.textOn && textOnFn(value[0])) || key
					};
				})
				.value();
		};
	}
]);

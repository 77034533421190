import React, { Component } from 'react';
import AdminHeader from 'Components/Admin/AdminHeader';
import {
	Table,
	TableRow,
	TableColumn,
	Button,
	Icon,
	Card,
	CardHeader,
	TableHeader,
	Tooltip
} from '@upsales/components';
import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
import t from 'Components/Helpers/translate';
import UserInvite from 'App/resources/Model/UserInvite';

type Props = {
	rootData: {
		pageData: { removeInvite: (invite: UserInvite) => void; invites: UserInvite[] };
	};
};

class ActiveInvites extends Component<Props> {
	lang: { [key: string]: string };

	constructor(props: Props) {
		super(props);
		this.lang = {
			activeInvites: t('admin.activeInvites'),
			noActiveInvites: t('admin.noActiveInvites'),
			email: t('default.email'),
			title: t('admin.usersAndRoles'),
			copyInviteLink: t('admin.copyInviteLink'),
			desc: t('admin.usersAndRoles.description')
		};
	}

	removeInvite(invite: UserInvite) {
		this.props.rootData.pageData.removeInvite(invite);
	}

	copyLink(link: string) {
		(this as any)._copy.value = link;
		(this as any)._copy.select();

		try {
			document.execCommand('copy');
			Tools.NotificationService.addNotification({
				title: 'default.copiedToClipboard',
				style: Tools.NotificationService.style.SUCCESS
			});
		} catch (err) {
			logError(err, 'Copy Error');
		}
	}

	render() {
		const self = this;
		const rootData = this.props.rootData;
		const invites = _.map(rootData.pageData.invites, function (invite) {
			return (
				<TableRow key={'invite-' + invite.id}>
					<TableColumn title={invite.email} subtitle={invite.link} />
					<TableColumn>
						<Tooltip title={self.lang.copyInviteLink} position="left">
							<Button
								type="link"
								color="grey"
								hoverColor="bright-blue"
								onClick={self.copyLink.bind(self, invite.link)}
							>
								<Icon name="link" />
							</Button>
						</Tooltip>
						<Button
							type="link"
							color="grey"
							hoverColor="red"
							onClick={self.removeInvite.bind(self, invite)}
						>
							<Icon name="trash-o" />
						</Button>
					</TableColumn>
				</TableRow>
			);
		});

		return (
			<div>
				<input
					type="text"
					ref={function (ref) {
						(self as any)._copy = ref;
					}}
					style={{
						position: 'absolute',
						top: -9999,
						left: -9999,
						opacity: 0
					}}
				/>

				<AdminHeader title={self.lang.title} description={self.lang.desc} articleId={919} image="users.svg" />

				<div id="admin-content">
					<Card>
						<CardHeader title={self.lang.activeInvites} />

						<Table>
							{!!invites.length && (
								<TableHeader>
									<th>{self.lang.email}</th>
									<th style={{ width: '100px' }}></th>
								</TableHeader>
							)}
							{invites}
						</Table>
						{!invites.length && <div className="table-no-result">{self.lang.noActiveInvites}</div>}
					</Card>
				</div>
			</div>
		);
	}
}

export default ActiveInvites;

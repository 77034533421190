angular.module('upDirectives').directive('upEventSignals', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventSignals.tpl.html?file'),
		require: 'upEventSignals',
		scope: {
			event: '=upEventSignals',
			noIcon: '@'
		},
		controller: [
			'$scope',
			function ($scope) {
				$scope.onClick = function (clickState) {
					if (!clickState) {
						return;
					}
					const customerId = Tools.AppService.getCustomerId();
					Tools.$state.go(clickState, { customerId, id: $scope.event.client.id });
				};

				// Map director events like we want them
				const [directorEvent] = _.remove($scope.event.value, event => event.event === 'directorsChanged');

				if (directorEvent && directorEvent.value !== null) {
					const { added, removed } = directorEvent.value;
					const roleCodeMap = {};
					for (const { roleCode } of added) {
						roleCodeMap[roleCode] = (roleCodeMap[roleCode] ?? 0) + 1;
					}
					for (const { roleCode } of removed) {
						roleCodeMap[roleCode] = (roleCodeMap[roleCode] ?? 0) - 1;
					}
					for (const director of added) {
						if (roleCodeMap[director.roleCode] === 0) {
							const [removedDirector] = _.remove(
								removed,
								removedDirector => removedDirector.roleCode === director.roleCode
							);
							$scope.event.value.push({
								event: 'directorReplaced',
								value: { added: director, removed: removedDirector }
							});
						} else {
							$scope.event.value.push({ event: 'directorAdded', value: director });
						}
					}
					for (const director of removed) {
						$scope.event.value.push({ event: 'directorRemoved', value: director });
					}
				}

				const statusEvent = _.find($scope.event.value, event => event.event === 'statusChanged');

				if (statusEvent) {
					statusEvent.value.status = Tools.$translate(
						`default.companyStatus.${statusEvent.value.status.toUpperCase()}`
					).toLowerCase();
				}

				for (const event of $scope.event.value) {
					switch (event.event) {
						case 'accountsNew':
						case 'accountsChanged':
						case 'listingNew':
						case 'listingRemoved':
						case 'shareIssueNew': {
							event.clickState = 'account.financial';
							event.clickLangTag = 'event.signals.click.financial';
							break;
						}
						case 'directorAdded':
						case 'directorRemoved':
						case 'directorReplaced': {
							event.clickState = 'account.contacts';
							event.clickLangTag = 'event.signals.click.board';
							break;
						}
					}
				}
			}
		]
	};
});

import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'App/components/hooks';
import React, { Suspense } from 'react';

import { AdminProvider } from 'App/pages/Admin/AdminContext';
import { useSoftDeployAccess } from 'App/components/hooks';
import AdminSidebar from './AdminSidebar';
import Page from 'App/pages/Page';
import LoaderPage from '../routes/LoaderPage';

import AllAdminRoutes from './allAdminRoutes';

const AdminRoutes = () => {
	const self = useSelector(({ App }) => App.self);

	const hasDashboardAccess = useSoftDeployAccess('REACT_ADMIN_DASHBOARD');
	const hasProfileAccess = useSoftDeployAccess('REACT_ADMIN_PROFILE');
	const routes = AllAdminRoutes();

	const isAdmin = self?.administrator;
	const isMarketAdmin = self?.userParams.mailAdmin;

	return (
		<AdminProvider>
			<Page sidebar={() => <AdminSidebar />} fullHeight overflow>
				<Switch>
					{routes.map(({ path, Component, exact = false }) => (
						<Route
							key={path}
							path={path}
							exact={exact}
							render={(props: any) => (
								<Suspense fallback={<LoaderPage />}>
									<Component {...props} />
								</Suspense>
							)}
						/>
					))}
					<Redirect
						from="/"
						to={
							(isAdmin || isMarketAdmin) && hasDashboardAccess
								? '/admin/dashboard'
								: hasProfileAccess
								? '/admin/profile'
								: '/admin/'
						}
					/>
				</Switch>
			</Page>
		</AdminProvider>
	);
};

export const routesToCoverInAngular = [
	'/admin/customer-support/rules/:id',
	'/admin/email-signatures',
	'/admin/profile',
	'/admin/dashboard',
	'/admin/notifications',
	'/admin/projectplan'
];

export default AdminRoutes;

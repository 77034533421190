'use strict';

angular.module('upFilters').filter('duration', [
	'$filter',
	function ($filter) {
		return function (seconds) {
			var duration = moment().startOf('day').add('s', seconds);
			var format = '';

			if (duration.hour() > 0) {
				format += 'H[h] ';
			}

			if (duration.minute() > 0) {
				format += 'm[m] ';
			}

			format += 's[s]';

			return duration.format(format);
		};
	}
]);

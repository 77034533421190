import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';
import './StepEmailSubject.scss';

export default function StepEmailSubject({ title }) {
	return <Title className={new bem('StepEmailSubject').b()}>{title}</Title>;
}

StepEmailSubject.propTypes = {
	title: PropTypes.string.isRequired
};

import React from 'react';
import { Title, Text, Link } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import './FileUploadDropzone.scss';
import bemClass from '@upsales/components/Utils/bemClass';

interface FileUploadDropzoneProps {
	title: string;
	onClick: () => void;
}

const FileUploadDropzone = ({ title, onClick }: FileUploadDropzoneProps) => (
	<div className={new bemClass('FileUploadDropzone').b()} onClick={onClick}>
		<Title>{title || translate('default.uploadFile')}</Title>
		<Text color="grey-10">
			<Link>{translate('file.uploadHelpText_split1')}</Link>
			{' ' + translate('file.uploadHelpText_split2')}
		</Text>
	</div>
);

export default FileUploadDropzone;

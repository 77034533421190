import React from 'react';
import PropTypes from 'prop-types';
import CriteriaDrilldownWrapper from '../CriteriaDrilldownWrapper';
import OrderValueSelect from './OrderValueSelect';
import ToggleList from './ToggleList';
import { Equals, EqualsAll } from 'Resources/ComparisonTypes';
import ListInput from './ListInput';
import DateColumn from './Shared/DateColumn';
import { Tooltip, Select, Button, Icon } from '@upsales/components';
import InputType from '../InputType';

const mapIdsToObject = (sourceObjects, ids) => {
	var objects = _.map(ids, function (objectId) {
		return _.find(sourceObjects, { id: objectId });
	});

	return _.compact(objects);
};

export default class OrderDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const { AppService } = Tools;

		this.lang = {
			date: Tools.$translate('default.date') + ':',
			products: Tools.$translate('default.products') + ':',
			users: Tools.$translate('default.users') + ':',
			stages: Tools.$translate('default.stages') + ':',
			orderValue: Tools.$translate('field.order.value') + ':',
			min: Tools.$translate('default.min'),
			max: Tools.$translate('default.max'),
			selectProduct: Tools.$translate('default.select') + ' ' + Tools.$translate('default.product').toLowerCase(),
			selectStage: Tools.$translate('default.select') + ' ' + Tools.$translate('default.stage').toLowerCase(),
			selectField: Tools.$translate('default.select') + ' ' + Tools.$translate('default.field').toLowerCase(),
			all: Tools.$translate('default.all'),
			any: Tools.$translate('default.any'),
			selectUser: Tools.$translate('default.choose') + ' ' + Tools.$translate('default.user').toLowerCase(),
			onlyActiveUsers: Tools.$translate('admin.automation.journeyStep.onlyActiveUsers'),
			remove: Tools.$translate('filters.removeFilter'),
			customFields: Tools.$translate('filters.columns.other') + ':'
		};

		this.onUserChange = this.onChange.bind(this, 'user');
		this.onProductChange = this.onChange.bind(this, 'product');
		this.onProductComparisonTypeChange = this.onChange.bind(this, 'productComparisonType');
		this.onStageChange = this.onChange.bind(this, 'stage');
		this.onCurrencyChange = this.onChange.bind(this, 'currency');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onDateStartChange = this.onChange.bind(this, 'dateStart');
		this.onDateEndChange = this.onChange.bind(this, 'dateEnd');
		this.onValueChange = this.onChange.bind(this, 'value');

		const { filter } = this.props;
		const isOpportunity = filter.filterName.toLowerCase().indexOf('opportunity') !== -1;
		const stageKey = isOpportunity ? 'opportunity' : 'won';
		const stages = AppService.getStages(stageKey, 'true');
		this.mapStageIdsToObjects = mapIdsToObject.bind(null, stages);
		this.wrapperRef = React.createRef();
		this.orderCustomFields = Tools.AppService.getCustomFields('order');
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'user':
				newFilter.value.User.value = value;
				break;
			case 'product':
				newFilter.value.OrderRowProduct.value = value;
				break;
			case 'productComparisonType':
				newFilter.value.OrderRowProduct.comparisonType = value;
				break;
			case 'stage':
				newFilter.value.Stage.value = _.pluck(value, 'id');
				break;
			case 'value':
				newFilter.value = Object.assign(newFilter.value, value);
				break;
			case 'custom':
				if (!newFilter.value.Custom) {
					newFilter.value.Custom = {};
				}
				newFilter.value.Custom.value = value;
				break;
			default:
				throw new Error('Should never get here');
		}

		onChange(newFilter.filterName, newFilter);
	}

	onDateChange(value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);
		newFilter.value.Date.value = value;
		onChange(newFilter.filterName, newFilter);
	}

	render() {
		const { filter, config, open, fakeConfigs, selectData, filterConfigs } = this.props;
		const isOpportunity = filter.filterName.toLowerCase().indexOf('opportunity') !== -1;
		const entity = isOpportunity ? 'opportunity' : 'order';
		const productSelect = {
			placeholder: this.lang.selectProduct,
			config: fakeConfigs.ProductProductCategory,
			defaultValue: filter.value.OrderRowProduct.value,
			onChange: this.onProductChange,
			selectData: selectData
		};
		const userSelect = {
			placeholder: this.lang.selectUser,
			config: fakeConfigs.User,
			defaultValue: filter.value.User.value,
			onChange: this.onUserChange,
			selectData: selectData,
			select2: {
				formatSelection: function (obj, el, escape) {
					const escapedName = escape(obj.name);
					return obj.isRole ? `<b>${escapedName}</b>` : escapedName;
				}
			}
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.all
				}
			],
			onClick: this.onProductComparisonTypeChange,
			value: filter.value.OrderRowProduct.comparisonType || Equals
		};

		return (
			<CriteriaDrilldownWrapper key={filter.filterName + '-drilldown'} className="order-drilldown" open={open}>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/*Date row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.date}</div>
									</td>
									<DateColumn
										config={config}
										filter={filter.value.Date}
										onChange={({ value }) => this.onDateChange(value)}
									/>
									<td className="remove-filter-col" />
								</tr>
								{/*User row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.users}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<Tooltip title={this.lang.onlyActiveUsers}>
													<ListInput {...userSelect} />
												</Tooltip>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{/*Product row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.products}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ToggleList
													list={productSelect}
													toggle={toggleProps}
													isListActive={() => filter.value.OrderRowProduct.value.length}
												/>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{/*Stage row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.stages}</div>
									</td>
									<td>
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ReactTemplates.INPUTS.upStages
													onChange={this.onStageChange}
													placeholder={this.lang.selectStage}
													entity={entity}
													hideZeroProbability={!isOpportunity}
													multiple={true}
													value={this.mapStageIdsToObjects(filter.value.Stage.value)}
												/>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{/*Ordervalue row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.orderValue}</div>
									</td>
									<td className="table-col">
										<OrderValueSelect
											onChange={this.onValueChange}
											value={_.pick(filter.value, ['Value', 'Currency'])}
										/>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{filter.value.Custom?.value.map(custom => (
									<tr key={custom.field}>
										<td className="title-col">
											<div className="drilldown-row">{custom.name}:</div>
										</td>
										<td>
											<div className="drilldown-row">
												<div className="col-lg-6">
													<InputType
														type={filterConfigs[`order.Custom_` + custom.field].displayType}
														config={filterConfigs[`order.Custom_` + custom.field]}
														field={{
															...this.orderCustomFields.find(a => a.id === custom.field),
															value: custom.value
														}}
														filter={custom}
														autoFocus={false}
														onChange={(value, newFilter) => {
															this.onChange(
																'custom',
																filter.value.Custom.value.map(field => {
																	if (field.field !== custom.field) {
																		return field;
																	}
																	return { ...newFilter, value };
																})
															);
														}}
													/>
												</div>
											</div>
										</td>
										<td className="remove-filter-col">
											<Tooltip title={this.lang.remove} position="left">
												<Button
													type="link"
													color="grey"
													onClick={e =>
														e.stopPropagation() ||
														this.onChange(
															'custom',
															filter.value.Custom.value.filter(
																f => f.field !== custom.field
															)
														)
													}
												>
													<Icon name="times-circle" />
												</Button>
											</Tooltip>
										</td>
									</tr>
								))}
								{this.orderCustomFields.length ? (
									<tr style={{ height: '44px' }}>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.customFields}</div>
										</td>
										<td>
											<div className="drilldown-row">
												<div
													className="col-lg-6"
													style={{ position: 'relative' }}
													ref={this.wrapperRef}
												>
													<Select
														options={this.orderCustomFields
															.map(f => ({
																id: f.id,
																title: f.name
															}))
															.filter(
																f =>
																	!filter.value.Custom?.value.some(
																		a => a.field === f.id
																	)
															)}
														placeholder={this.lang.selectField}
														onChange={value => {
															const newFilter = Tools.FilterHelper.filter(
																`order.Custom_` + value.id,
																'contact',
																{
																	getConfig: name => filterConfigs[name],
																	useTags: true
																}
															);
															const config = filterConfigs[`order.Custom_` + value.id];
															if (
																config.type === 'radio' ||
																config.displayType === 'radio' ||
																config.displayType === 'listShortSingle'
															) {
																var firstInactiveValue = _.find(
																	config.options,
																	function (option) {
																		return !option.inactive;
																	}
																);
																if (firstInactiveValue) {
																	newFilter.value = firstInactiveValue.value;
																	newFilter.comparisonType =
																		firstInactiveValue.comparisonType;
																	newFilter.inactive = false;
																}
															}
															this.onChange('custom', [
																...(filter.value.Custom?.value ?? []),
																{ name: value.title, ...newFilter }
															]);
														}}
														anchor={this.wrapperRef.current}
														value={null}
													/>
												</div>
											</div>
										</td>
									</tr>
								) : null}
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

OrderDrilldown.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectData: PropTypes.object.isRequired,
	fakeConfigs: PropTypes.object.isRequired,
	filterConfigs: PropTypes.object.isRequired
};

'use strict';

angular.module('upDirectives').directive('upEventOrder', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventOrder.tpl.html?file'),
		require: 'upEventOrder',
		scope: {
			event: '=upEventOrder',
			defaultCurrency: '=defaultCurrency'
		},
		controller: [
			'$scope',
			'$stateParams',
			'$upModal',
			'$translate',
			'Order',
			function ($scope, $stateParams, $upModal, $translate, Order) {
				$scope.customerId = Tools.AppService.getCustomerId();
				const metadata = Tools.AppService.getMetadata();
				const defaultCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });

				const formatCurrency = function (value, currency) {
					return Tools.$filter('currencyFormat')(value, currency || defaultCurrency);
				};

				var opportunity = $scope.event.opportunity;
				var salesModel = metadata.params.SalesModel;
				var salesModelOption = metadata.params.SalesModelOption;
				var showContributionMargin = salesModel === 'cm' && salesModelOption === 'cm';
				var showRecurring = salesModel === 'rr';
				var showARR = showRecurring && salesModelOption === 'arr';

				var recurringValue = 0;
				var oneOffValue = 0;
				var formatted, displayARR, displayMRR, displayOneOff;

				$scope.displayValue = formatCurrency(opportunity.orderValue, opportunity.currency);

				if (showContributionMargin) {
					formatted = formatCurrency(opportunity.contributionMarginLocalCurrency, opportunity.currency);
					$scope.displayValue = `${formatted} (${$translate.instant('default.contributionMarginShort')})`;
				} else if (showRecurring) {
					if (showARR) {
						recurringValue = opportunity.annualValue;
						formatted = formatCurrency(opportunity.annualValue, opportunity.currency);
						displayARR = recurringValue
							? `${formatted} (${$translate.instant('default.annualValue')})`
							: null;
					} else {
						recurringValue = opportunity.monthlyValue;
						formatted = formatCurrency(opportunity.monthlyValue, opportunity.currency);
						displayMRR = recurringValue
							? `${formatted} (${$translate.instant('default.monthlyValue')})`
							: null;
					}

					oneOffValue = opportunity.oneOffValue;
					if (oneOffValue) {
						formatted = formatCurrency(opportunity.oneOffValue, opportunity.currency);
						displayOneOff = oneOffValue
							? `${formatted} (${$translate.instant('default.oneOffValue')})`
							: null;
					}

					$scope.displayValue = displayARR || displayMRR;
					if (displayOneOff) {
						if ($scope.displayValue) {
							$scope.displayValue = $scope.displayValue + ' & ' + displayOneOff;
						} else {
							$scope.displayValue = displayOneOff;
						}
					}
				}

				$scope.openOrder = function () {
					var options = {
						customerId: $stateParams.customerId,
						type: 'order',
						id: $scope.event.entityId
					};

					$upModal.open('editOrder', options);
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}
					$scope.event.$$loading = true;

					return Order.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}
							$scope.event.$$eventDetails = res.data;
							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
						});
				};
			}
		]
	};
});

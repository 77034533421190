'use strict';

angular.module('domain.admin').controller('Settings', [
	'$scope',
	'$stateParams',
	'$http',
	'Self',
	'$sce',
	'CLASSIC_URL',
	function ($scope, $stateParams, $http, Self, $sce, CLASSIC_URL) {
		var customerId = $stateParams.customerId;
		$scope.showLogin = true;
		$scope.showSettings = false;
		$scope.error = false;
		$scope.firstVisit = true;
		$scope.adminPath = $sce.trustAsResourceUrl(CLASSIC_URL + 'admin/admin.jsp');
		$scope.credentials = {
			password: ''
		};

		$scope.login = function () {
			Self.getCustomerSelf(customerId).then(function (user) {
				var req = {
					method: 'POST',
					url: CLASSIC_URL + 'setSessionServlet.jsp',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: $.param({
						str_email: user.email,
						str_password: $scope.credentials.password,
						ignoreBlock: true
					})
				};
				$http(req)
					.success(function (data) {
						if (data.indexOf('Invalid username or password') != -1) {
							// bad
							$scope.error = true;
							$scope.credentials.password = '';
						} else {
							$scope.error = false;
							$scope.showLogin = false;
							$scope.showSettings = true;
						}
					})
					.error(function () {
						$scope.error = true;
					});
			});
		};
	}
]);

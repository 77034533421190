import T from 'Components/Helpers/translate';

angular.module('services.inkyService', []).service('InkyService', [
	'InkyParser',
	function (InkyParser) {
		var Service = {};

		var containerWidth = 580;
		var colPadding = 16;

		Service.elements = {
			ROW: 'ROW',
			BUTTON: 'BUTTON',
			IMAGE: 'IMAGE',
			MENU: 'MENU',
			CONTAINER: 'CONTAINER',
			COLUMN: 'COLUMN',
			TEXT: 'TEXT',
			CALLOUT: 'CALLOUT',
			EMPTY: 'EMPTY',
			H2: 'H2',
			H3: 'H3',
			ROOT: 'ROOT',
			HR: 'HR'
		};

		var getMdElemByRegExp = function (re, parsedElement, parent) {
			if (!parsedElement || !parsedElement.length) {
				return null;
			}
			var classes = parsedElement[0].className;
			var match = classes.match(re);
			var elem = null;

			if (match && match.length) {
				var found = parent.find('.' + match[0]);
				if (found.length) {
					elem = found.eq(0);
				}
			}

			return elem;
		};

		var outerHTML = function (e) {
			return angular.element('<div>').append(e.eq(0).clone()).html();
		};

		Service.getMdColumnByElem = function (parsedElement, markdownElem) {
			return getMdElemByRegExp(/col-id-([0-9]*)-id/g, parsedElement, markdownElem);
		};

		Service.getMdRowByElem = function (parsedElement, markdownElem) {
			return getMdElemByRegExp(/row-id-([0-9]*)-id/g, parsedElement, markdownElem);
		};

		Service.parseSettings = function (markdown, accountProfile) {
			var settings = {
				hasHeader: false,
				headerLogoAlign: 'left',
				hasFooter: false,
				fullWidthHeader: false,
				bordered: false,
				borderColor: '#000',
				headerBg: accountProfile.colors.mainBg,
				bodyBg: accountProfile.colors.bodyBg,
				previewText: '',
				unsubText: ''
			};

			var headerElem = markdown.find('header');
			if (headerElem.length) {
				settings.hasHeader = true;

				var align = headerElem.attr('data-align');

				if (align) {
					settings.headerLogoAlign = align;
				}

				var bgColor = headerElem.attr('data-bgcolor');

				if (bgColor) {
					settings.headerBg = bgColor;
				}
			}

			if (markdown.hasClass('full-width')) {
				settings.fullWidthHeader = true;
			}

			if (markdown.find('header').hasClass('dark-header')) {
				settings.darkHeader = true;
			}

			if (markdown.find('footer').length) {
				settings.hasFooter = true;
			}

			if (markdown.find('.main-container.bordered').length) {
				settings.bordered = true;
				var color = Service.dataStyle(markdown.find('.main-container.bordered'), 'border');

				if (color) {
					settings.borderColor = color;
				}
			}

			var bodyBg = markdown.attr('data-bodybg');

			if (bodyBg) {
				settings.bodyBg = bodyBg;
			}

			var previewText = markdown.attr('data-previewtext');
			settings.previewText = previewText || '';

			const hasOptionalUnsubLink = Tools.FeatureHelper.hasSoftDeployAccess('OPTIONAL_UNSUB');
			var unsubText = markdown.attr('data-unsubtext');
			settings.unsubText =
				unsubText !== undefined
					? unsubText
					: T(hasOptionalUnsubLink ? 'mail.subscriptionsLink' : 'mailTags.unsubscribeValue');

			var headerMargin = markdown.attr('data-headermargin');
			if (headerMargin) {
				settings.headerMargin = parseInt(headerMargin);
			}
			if (!headerMargin || isNaN(settings.headerMargin)) {
				settings.headerMargin = 15;
			}

			return settings;
		};

		Service.compileAndReplaceRow = function (mdElement, html, profile, cb) {
			var md = outerHTML(mdElement);
			var id = mdElement.data('id');

			// Get new html
			InkyParser.parsePreview(md, { profile: profile }).then(function (res) {
				var htmlObj = angular.element('<div>' + html + '</div>');
				var row = htmlObj.find('.row-id-' + id + '-id');

				row.replaceWith(res.data);

				cb(htmlObj.html());
			});
		};

		var getColumnAttributes = function (containerWidth, colPadding, columnSize) {
			var column1Width = (containerWidth / 12) * columnSize;
			var columns = 12 / columnSize;

			return {
				'data-size': columnSize,
				'data-width': column1Width - (colPadding * (columns + 1)) / columns,
				'data-collapsed-width': column1Width
			};
		};

		Service.createElement = function (type, opts) {
			opts = opts || {};
			if (!Service.elements[type]) {
				return null;
			}

			var element;

			switch (type) {
				case Service.elements.ROW:
					element = angular.element('<container><row/></container>');
					var rowId = Date.now() + '';
					var idClass = 'row-id-' + rowId + '-id';
					var rowAttr = {
						'data-id': rowId
					};

					element.attr(rowAttr);
					var rowElement = element;

					element.addClass(idClass + ' editable-row');

					if (!element.is('row')) {
						rowElement = element.find('row');
					}

					if (opts.columns === 70) {
						// 8 4
						var column1 = Service.createElement(Service.elements.COLUMN, { size: 8, id: Date.now() + 1 });
						var placeholder1 = Service.createElement(Service.elements.EMPTY, {});
						column1.attr(getColumnAttributes(containerWidth, colPadding, 8));

						column1.append(placeholder1);
						rowElement.append(column1);

						var column2 = Service.createElement(Service.elements.COLUMN, { size: 4, id: Date.now() + 2 });
						var placeholder2 = Service.createElement(Service.elements.EMPTY, {});
						column2.attr(getColumnAttributes(containerWidth, colPadding, 4));

						column2.append(placeholder2);
						rowElement.append(column2);
					} else if (opts.columns === 30) {
						// 4 8
						column1 = Service.createElement(Service.elements.COLUMN, { size: 4, id: Date.now() + 1 });
						placeholder1 = Service.createElement(Service.elements.EMPTY, {});
						column1.attr(getColumnAttributes(containerWidth, colPadding, 4));

						column1.append(placeholder1);
						rowElement.append(column1);

						column2 = Service.createElement(Service.elements.COLUMN, { size: 8, id: Date.now() + 2 });
						placeholder2 = Service.createElement(Service.elements.EMPTY, {});
						column2.attr(getColumnAttributes(containerWidth, colPadding, 8));

						column2.append(placeholder2);
						rowElement.append(column2);
					} else {
						var size = 12 / opts.columns;
						var colAttrs = getColumnAttributes(containerWidth, colPadding, size);

						for (var i = 0; i < opts.columns; i++) {
							var id = Date.now() + i + '';
							var column = Service.createElement(Service.elements.COLUMN, { size: size, id: id });
							var placeholder = Service.createElement(Service.elements.EMPTY, {});
							column.attr(colAttrs);
							column.append(placeholder);
							rowElement.append(column);
						}
					}

					break;

				case Service.elements.BUTTON:
					element = angular.element('<center><button><span></span></button></center>');

					element.find('button').attr('href', '#').addClass('radius center email-btn');

					break;

				case Service.elements.COLUMN:
					var colClass = 'col-id-' + opts.id + '-id';
					element = angular.element('<columns class="editable-column" large="' + opts.size + '"></columns>');

					element.attr({
						'data-id': opts.id,
						'data-type': 'EMPTY'
					});

					element.addClass(colClass);

					break;

				case Service.elements.CONTAINER:
					element = angular.element('<wrapper><container/></wrapper>');

					break;

				case Service.elements.MENU:
					element = angular.element(
						'<menu><item href="one.html">Item One</item><item href="one.html">Item Two</item><item href="one.html">Item Three</item></menu>'
					);

					break;

				case Service.elements.IMAGE:
					element = angular.element('<center><img src="" class="thumbnail" /></center>');

					break;

				case Service.elements.TEXT:
					element = angular.element('<div><p class="text-left"></p></div>');
					element.attr('data-align', 'left');

					break;

				case Service.elements.CALLOUT:
					element = angular.element('<callout class="column-content"></callout>');

					break;

				case Service.elements.EMPTY:
					element = Service.createElement(Service.elements.CALLOUT);
					var text = Service.createElement(Service.elements.TEXT);

					element.addClass('editor-column-placeholder');

					text.find('p').html(T('mail.noContent'));

					element.append(text);

					break;

				case Service.elements.H2:
					element = angular.element('<h2></h2>');
					var align = opts.align || 'left';

					element.addClass('heading text-' + align);

					break;

				case Service.elements.H3:
					element = angular.element('<h3></h3>');
					align = opts.align || 'left';

					element.addClass('heading text-' + align);

					break;

				case Service.elements.ROOT:
					element = angular.element(
						'<table class="body"><tr><td class="center" align="center" valign="top"><center class="top-center"></center></td></tr></table>'
					);
					var container = Service.createElement(Service.elements.CONTAINER);
					container.attr('id', 'main-container');
					container.attr('class', 'main-container');

					element.find('.top-center').append(container);

					break;

				case Service.elements.HR:
					element = angular.element(
						'<table style="height: 1px" cellpadding="0" cellspacing="0"><tr style="height: 1px"><td class="horizontal-row">&nbsp;</td></tr></table>'
					);

					break;
			}

			return element;
		};

		Service.dataStyle = function (mdElement, attr, value, selector) {
			var currentStyle = {};
			var objStyle = mdElement.attr('data-style');

			selector = selector || 'self';
			if (objStyle) {
				try {
					currentStyle = JSON.parse(objStyle);
				} catch (e) {
					currentStyle = {};
				}
			}

			if (value === null && currentStyle[selector]) {
				delete currentStyle[selector][attr];
			} else if (value === undefined && currentStyle[selector]) {
				// Get
				return currentStyle[selector][attr] || null;
			} else {
				// Set
				if (!currentStyle[selector]) {
					currentStyle[selector] = {};
				}
				currentStyle[selector][attr] = value;
			}

			mdElement.attr('data-style', JSON.stringify(currentStyle));
		};

		Service.getMdColumnById = function (id, markdown) {
			return markdown.find('.col-id-' + id + '-id');
		};

		Service.getMdRowById = function (id, markdown) {
			return markdown.find('.row-id-' + id + '-id');
		};

		return Service;
	}
]);

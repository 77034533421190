import React, { useState, useMemo } from 'react';
import logError from 'App/babel/helpers/logError';
import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import PropTypes from 'prop-types';
import { Text, Card, CardContent, Icon, Link, Title, Block, Button, Tooltip } from '@upsales/components';
import { statusDisqualifiable, DISQUALIFIED, CUSTOMER } from 'Components/Helpers/journeyStep';
import { openDrawer } from 'Services/Drawer';
import { getMainClass, getButtonMainText } from '../Helpers';
import { appointmentOutcomeTracker } from 'App/babel/helpers/Tracker';
import CommentResource from 'Resources/Comment';
import CommentInput from 'App/components/Inputs/CommentInput';
import T from 'Components/Helpers/translate';
import { TYPES } from 'Components/Helpers/SourceHelper';
import AppointmentEvents from 'App/resources/AppointmentEvents';

const createEvents = async (appointmentId, events) => {
	for (const event of events) {
		await AppointmentEvents.createEvent(appointmentId, event);
	}
};

const Actions = props => {
	const {
		complete,
		onClick,
		disqualifyCompany,
		followUp,
		createOpportunity,
		appointment,
		saving,
		originalOutcome,
		disableOutcomeClick,
		save,
		saveAppointmentWithAction,
		rescheduleAppointment,
		isRescheduling
	} = props;
	const [comment, setComment] = useState('');
	const [savingOutcome, setSavingOutcome] = useState(false);
	const [disqualified, setDisqualified] = useState(appointment.client?.journeyStep === DISQUALIFIED);
	const saveBtnDisabled = (!comment && originalOutcome === appointment.outcome) || savingOutcome;

	const hasFeatureTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
	const hasContactEmail = (appointment.contacts ?? []).find(contact => contact.email) !== undefined;
	const standardFields = useMemo(() => Tools.AppService.getMetadata().standardFields.Activity, []);
	const [selectedAction, setSelectedAction] = useState(null);

	const isSelectedAction = code => {
		return code === selectedAction;
	};
	const reschedulingIsHighlighted = isRescheduling() && isSelectedAction('rescheduleAppointment');

	const trackAction = action => {
		appointmentOutcomeTracker.track(appointmentOutcomeTracker.events.TAKE_ACTION, {
			location: 'modal',
			action
		});
	};
	const getSaveButtonText = () => {
		if (originalOutcome !== appointment.outcome) {
			if (comment) {
				return T('appointment.outcome.saveOutcomeAndComment');
			} else {
				return T('appointment.outcome.saveOutcome');
			}
		} else {
			return T('appointment.outcome.saveComment');
		}
	};
	const saveComment = async outcomeType => {
		const { data: createdComment } = await CommentResource.save({
			user: { id: Tools.AppService.getSelf().id },
			description: comment,
			client: appointment.client,
			appointment,
			outcomeType
		});
		return createdComment;
	};

	const saveCommentAndOutcome = async () => {
		if (
			Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
			Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
		) {
			setSavingOutcome(true);
			let outcomeCommentId;
			if (comment) {
				try {
					({ id: outcomeCommentId } = await saveComment(appointment.outcome));
				} catch (error) {
					logError(error, 'Error saving comment');
				}
			}
			return save(outcomeCommentId)
				.then(() => {
					setComment('');
					setSavingOutcome(false);
				})
				.catch(error => {
					logError(error, 'Error saving outcome');
				});
		}
	};

	const onEmailSent = async mails => {
		if (mails?.length) {
			await saveCommentAndOutcome();
			await createEvents(
				appointment.id,
				mails
					.filter(mail => mail?.contact)
					.map(({ id, subject, contact }) => {
						return {
							action: EVENT_TYPES.MAILSENT,
							fields: {
								mail: { id, subject, contact }
							}
						};
					})
			);
		}
	};

	const openEmailModal = () => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
			const { id, description, date, contacts } = appointment;
			const contactsWithEmail = contacts.filter(c => c.email);
			openDrawer('NewSingleMail', {
				appointment: { id, description, date },
				subject: description,
				recipients: {
					to: contactsWithEmail.length
						? contactsWithEmail.map(c => ({
								name: c.name,
								email: c.email,
								id: c.id,
								type: 'contact',
								client: c.client
						  }))
						: [],
					cc: [],
					bcc: []
				},
				onSend: mail => {
					onEmailSent(Array.isArray(mail) ? mail : [mail]);
				}
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('sendEmail', {
					type: 'mail',
					subject: appointment.description,
					recipients: appointment.contacts.length ? appointment.contacts : null
				})
				.then(mails => onEmailSent(mails));
		}
	};

	let actions = [
		{
			code: 'sendMail',
			mainText: getButtonMainText(
				complete ? 'appointment.outcome.sendSummary' : 'default.sendEmail',
				'envelope',
				'black',
				false
			),
			onClick: () => {
				openEmailModal();
				trackAction('sendMail');
			},
			disabled: !hasContactEmail,
			tooltipTitle: !hasContactEmail ? 'mail.noValidRecipient' : ''
		},
		{
			code: hasFeatureTodoList ? 'createCall' : 'createActivity',
			mainText: getButtonMainText(
				hasFeatureTodoList ? 'todo.planACall' : 'create_activity',
				'phone',
				'black',
				false
			),
			onClick: async () => {
				let outcomeCommentId;
				if (hasFeatureTodoList) {
					openDrawer('CreateCall', {
						client: appointment.client,
						contact: appointment.contacts?.[0] ?? null,
						notes: appointment.notes,
						appointment,
						project: standardFields.Project.active && appointment.project ? appointment.project : undefined,
						source: { id: appointment.id, type: TYPES.APPOINTMENT },
						onSave: async call => {
							if (
								Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
								Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
							) {
								if (comment) {
									({ id: outcomeCommentId } = await saveComment(appointment.outcome));
									setComment('');
								}
								saveAppointmentWithAction(
									{
										id: appointment.id,
										outcome: appointment.outcome,
										outcomeAction: EVENT_TYPES.CALLCREATED,
										outcomeExtra: JSON.stringify({ id: call.id, description: call.description }),
										...(outcomeCommentId ? { outcomeCommentId } : {})
									},
									{ params: { saveOutcomeAction: true } }
								);
							} else {
								createEvents(appointment.id, [
									{
										action: EVENT_TYPES.CALLCREATED,
										fields: { call: { id: call.id, description: call.description } }
									}
								]);
							}
						}
					});
				} else {
					// will resolve if activity created
					// eslint-disable-next-line promise/catch-or-return
					followUp(true, comment).then(() => {
						setComment('');
					});
				}
				trackAction('createActivity');
			}
		}
	];

	if (!complete) {
		const { journeyStep, name: clientName } = appointment.client ?? {};
		const disqualifiable = statusDisqualifiable(journeyStep) && journeyStep !== CUSTOMER;

		actions = actions.concat([
			{
				code: 'rescheduleAppointment',
				mainText: getButtonMainText(
					'appointment.outcome.action.reschedule',
					'calendar-arrow-right-o',
					'black',
					false
				),
				onClick: () => {
					rescheduleAppointment(true);
					setSelectedAction('rescheduleAppointment');
					trackAction('rescheduleAppointment');
				},
				isSelected: reschedulingIsHighlighted,
				tooltipTitle: 'activity.outcome.rescheduleTooltip'
			},
			{
				code: 'disqualifyCompany',
				mainText: getButtonMainText(
					`${Tools.$translate('default.disqualify')} ${clientName}`,
					'thumbs-down',
					'black',
					false
				),
				onClick: () => {
					setDisqualified(true);
					disqualifyCompany();
					trackAction('disqualifyCompany');
				},
				disabled: disqualified || !disqualifiable,
				tooltipTitle: disqualified
					? 'default.journeyAlreadyDisqualified'
					: !disqualifiable
					? 'default.journeyDisqualifiedForbidden'
					: '',
				tooltipTitleProps: { status: journeyStep }
			}
		]);
	}

	actions.push({
		code: 'createAppointment',
		mainText: getButtonMainText('create_appointment', 'calendar', 'black', false),
		onClick: () => {
			// will resolve if appointment created
			// eslint-disable-next-line promise/catch-or-return
			followUp(false, comment).then(() => {
				setComment('');
			});
			trackAction('createAppointment');
		},
		tooltipTitle: 'activity.outcome.bringInfoToNewAppointment'
	});

	if (!appointment.opportunity) {
		actions = actions.concat([
			{
				code: 'createOpportunity',
				mainText: getButtonMainText('activity.outcome.createOpportunity', 'opportunity', 'black', false),
				onClick: async () => {
					// will resolve if opportunity created
					// eslint-disable-next-line promise/catch-or-return
					createOpportunity(false, comment).then(() => {
						setComment('');
					});
					trackAction('createOpportunity');
				}
			},
			{
				code: 'createOrder',
				mainText: getButtonMainText('activity.outcome.createOrder', 'sales', 'black', false),
				onClick: async () => {
					// will resolve if order created
					// eslint-disable-next-line promise/catch-or-return
					createOpportunity(true, comment).then(() => {
						setComment('');
					});
					trackAction('createOrder');
				}
			}
		]);
	}

	const MainClass = getMainClass();

	const onOutcomeClick = () => {
		if (disableOutcomeClick) {
			return;
		}
		rescheduleAppointment(false);
		onClick();
	};

	return (
		<Card className={MainClass.elem('actions').b()}>
			<Link onClick={onOutcomeClick}>
				<Text>
					{!disableOutcomeClick ? <Icon name="chevron-left" /> : null}
					<Icon
						name={`calendar-${complete ? 'check-o' : 'times-o'}`}
						color={complete ? 'medium-green' : 'red'}
						size="sm"
					/>
					{Tools.$translate(`appointment.outcome.marked${complete ? 'Completed' : 'NotCompleted'}`)}
				</Text>
			</Link>

			<CardContent className={MainClass.elem('waterfall-menu').b()}>
				{Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? (
					<React.Fragment>
						<CommentInput
							commentLabel={T('appointment.outcome.action.addNote.label')}
							placeholder={T('appointment.outcome.action.addNote')}
							onChange={setComment}
							saveComment={saveCommentAndOutcome}
							value={comment}
						/>
					</React.Fragment>
				) : null}
				<Title>
					{`${Tools.$translate(
						complete ? 'appointment.outcome.nextStep' : 'appointment.outcome.nextStepNotCompleted'
					)}`}
				</Title>

				<Block>
					{actions.map(
						({ code, mainText, onClick, disabled, tooltipTitle, tooltipTitleProps, isSelected }) => (
							<Tooltip
								key={code}
								title={Tools.$translate(tooltipTitle, tooltipTitleProps) || ''}
								disabled={!tooltipTitle}
							>
								<Button
									id={code}
									onClick={onClick}
									disabled={saving || disabled}
									color={
										disabled || (reschedulingIsHighlighted && !isSelected)
											? 'light-grey'
											: 'super-light-green'
									}
								>
									{mainText}
								</Button>
							</Tooltip>
						)
					)}
				</Block>
				{Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? (
					<Button
						disabled={saveBtnDisabled}
						color={saveBtnDisabled ? 'light-grey' : 'green'}
						className={MainClass.elem('save-button').mod({ disabled: saveBtnDisabled }).b()}
						text={getSaveButtonText()}
						onClick={async () => {
							setSavingOutcome(true);
							let outcomeCommentId;
							if (comment) {
								const outcomeType =
									originalOutcome !== appointment.outcome ? appointment.outcome : undefined;
								try {
									({ id: outcomeCommentId } = await saveComment(outcomeType));
								} catch (error) {
									logError(error, 'Error saving comment');
								}
							}
							save(outcomeCommentId)
								.then(() => {
									setComment('');
									setSavingOutcome(false);
								})
								.catch(() => {
									logError('Error saving outcome');
								});
						}}
					/>
				) : null}
			</CardContent>
		</Card>
	);
};
Actions.propTypes = {
	complete: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	todos: PropTypes.array.isRequired,
	fetchTodos: PropTypes.func.isRequired,
	disqualifyCompany: PropTypes.func.isRequired,
	followUp: PropTypes.func.isRequired,
	createOpportunity: PropTypes.func.isRequired,
	appointment: PropTypes.object.isRequired,
	disableOutcomeClick: PropTypes.bool,
	saving: PropTypes.bool,
	save: PropTypes.func,
	originalOutcome: PropTypes.string,
	saveAppointmentWithAction: PropTypes.func,
	rescheduleAppointment: PropTypes.func,
	isRescheduling: PropTypes.func
};

export default Actions;

import React from 'react';
import {
	ButtonGroup,
	Button,
	Input,
	Text,
	Toggle,
	Card,
	CardHeader,
	CardContent,
	ModalHeader,
	ModalContent,
	ModalControls,
	Modal,
	Select
} from '@upsales/components';
import PropTypes from 'prop-types';
import moveDealSVG from './moveDeal.svg';

import './CloseOpportunityModal.scss';

const isInvalid = field => {
	return field.value === '';
};

class CloseOpportunityModal extends React.Component {
	constructor(props) {
		super(props);

		const t = window.Tools.$translate;
		this.lang = {
			closeDeal: t('default.closeDeal'),
			moveDeal: t('default.moveDeal'),
			moveDealDescription: t('salesboard.moveDealDescription'),
			closeDealDescription: t('salesboard.closeDealDescription'),
			editOpportunity: t('edit_opportunity'),
			doYouWantCloseDateToday: t('default.setCloseDateTOday'),
			closeTodayShort: t('default.setCloseDateTOdayShort'),
			createSubscription: t('salesboard.closeOpportunityModal.createSubscription'),
			yes: t('default.yes'),
			no: t('default.no'),
			cancel: t('cancel')
		};
		this.modalRef = React.createRef();

		this.state = {
			saveFailed: false,
			doCreateSubscription: false,
			doSetDateToday: true,
			requiredFields: this.props.requiredFields,
			modalRef: this.modalRef
		};
	}

	componentDidMount() {
		if (this.modalRef.current) {
			this.setState({ modalRef: this.modalRef });
		}
	}

	toggleSubscription = () => {
		this.setState({
			doCreateSubscription: !this.state.doCreateSubscription
		});
	};

	toggleSetToday = val => {
		this.setState({
			doSetDateToday: val
		});
	};

	onFieldChange(fieldId, value) {
		var requiredFields = this.state.requiredFields;

		var fieldIndex = _.findIndex(requiredFields, f => {
			return f.id === fieldId;
		});
		var field = requiredFields[fieldIndex];

		if (field) {
			field.value = value;
			requiredFields[fieldIndex] = field;

			this.setState({
				requiredFields
			});
		}
	}

	renderStandardField(field, index) {
		if (field.field === 'salesCoach') {
			return (
				<Select
					anchor={this.state.modalRef.current}
					value={field.value}
					options={field.selectOptions.map(salesCoach => ({
						title: salesCoach.name,
						...salesCoach
					}))}
					onChange={option => {
						this.onFieldChange(field.id, option);
					}}
				/>
			);
		}

		return (
			<Input
				key={'sf-' + index}
				name={field.name}
				value={field.value}
				disabled={!field.editable}
				onChange={e => this.onFieldChange(field.id, e.target.value)}
			/>
		);
	}

	renderRequiredFields() {
		const { requiredFields } = this.state;
		if (!requiredFields) {
			return;
		}
		var self = this;

		return _.chain(self.state.requiredFields)
			.sortBy('sortId')
			.map(function (field, index) {
				field.value = field.value || '';

				var fieldElement =
					field.group === 'standard' ? (
						self.renderStandardField(field)
					) : (
						<ReactTemplates.INPUTS.customFieldInput
							key={'cf-' + index}
							field={field}
							name={field.name}
							disabled={!field.editable}
							multiple={false}
							className={'form-control up-input'}
							tools={Tools}
							onChange={val => self.onFieldChange(field.id, val)}
						/>
					);

				var classes = 'form-group';

				if (self.state.saveFailed && isInvalid(field)) {
					classes += ' has-error';
				}

				var wrapperClasses = ['half'];

				return (
					<div className={wrapperClasses.join(' ')} key={'fieldElement-' + index + '-col'}>
						<div className={classes}>
							<label>
								{field.name} <b className="text-danger">{'*'}</b>
							</label>
							<div className="input">{fieldElement}</div>
						</div>
					</div>
				);
			})
			.sortBy('sortId')
			.value();
	}

	doSave = () => {
		const { resolve } = this.props;
		const invalidField = _.find(this.state.requiredFields, field => {
			return isInvalid(field);
		});

		if (invalidField) {
			this.setState({
				saveFailed: true
			});
		} else {
			resolve({
				setToday: this.state.doSetDateToday,
				createSubscription: this.state.doCreateSubscription,
				requiredFields: this.state.requiredFields
			});
		}
	};

	render() {
		const { reject, showToggle, stage, description = '' } = this.props;
		const isOrder = !stage || stage.probability === 0 || stage.probability === 100;
		const styleContent = { position: 'relative' };
		const renderedFields = this.renderRequiredFields();

		return (
			<div className="CloseOpportunityModal" ref={this.modalRef}>
				<Modal>
					<ModalHeader
						className=""
						icon="pencil-square-o"
						title={this.lang.editOpportunity + ': ' + description}
						onClose={() => reject()}
					/>
					<ModalContent style={styleContent}>
						<Card className="CloseOpportunityModal--Card-Header" slice="bright-blue">
							<img src={moveDealSVG} />
							<CardHeader title={isOrder ? this.lang.closeDeal : this.lang.moveDeal} />
							<CardContent>
								<Text>
									{this.state.requiredFields
										? isOrder
											? this.lang.closeDealDescription
											: this.lang.moveDealDescription
										: this.lang.doYouWantCloseDateToday}
								</Text>
							</CardContent>
						</Card>
						<div className="CloseOpportunityModal--Actual-content">
							{this.state.requiredFields && renderedFields}
							{isOrder && (
								<div className="CloseOpportunityModal--Set-today">
									<label>{this.lang.closeTodayShort}</label>
									<ButtonGroup block>
										<Button
											onClick={() => this.toggleSetToday(true)}
											color={this.state.doSetDateToday ? 'blue' : 'light-grey'}
										>
											{this.lang.yes}
										</Button>
										<Button
											onClick={() => this.toggleSetToday(false)}
											color={!this.state.doSetDateToday ? 'blue' : 'light-grey'}
										>
											{this.lang.no}
										</Button>
									</ButtonGroup>
								</div>
							)}
						</div>
					</ModalContent>
					<ModalControls>
						<div>
							{isOrder && showToggle && (
								<div className="Toggle-Wrapper">
									<Toggle
										size="lg"
										onChange={this.toggleSubscription}
										checked={this.state.doCreateSubscription}
									/>
									<Text size="sm">{this.lang.createSubscription}</Text>
								</div>
							)}
							<Button color="bright-blue" onClick={this.doSave}>
								{isOrder ? this.lang.closeDeal : this.lang.moveDeal}
							</Button>
							<Button color="grey" type="link" onClick={() => reject()}>
								{this.lang.cancel}
							</Button>
						</div>
					</ModalControls>
				</Modal>
			</div>
		);
	}
}

CloseOpportunityModal.propTypes = {
	resolve: PropTypes.func,
	reject: PropTypes.func,
	showToggle: PropTypes.bool,
	requiredFields: PropTypes.array,
	stage: PropTypes.object,
	description: PropTypes.string
};

window.CloseOpportunityModal = CloseOpportunityModal;
export default CloseOpportunityModal;

'use strict';

angular.module('upDirectives').directive('upSoliditetMatcher', [
	'$translate',
	'AppService',
	'$timeout',
	'$rootScope',
	'FilterHelper',
	'$state',
	'$upModal',
	'CountryCodes',
	function ($translate, AppService, $timeout, $rootScope, FilterHelper, $state, $upModal, CountryCodes) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				var firstElement = $element[0];
				var renderTimeout;

				var render = function (rootData) {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}
						ReactDOM.render(
							React.createElement(ReactTemplates.soliditetMatcher.root, {
								rootData: rootData,
								tools: {
									$state: $state,
									customerId: AppService.getCustomerId(),
									userEmail: AppService.getSelf().email,
									$translate: $translate.instant,
									$upModal: $upModal,
									CountryCodes: CountryCodes
								}
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					$attr.upSoliditetMatcher,
					function (rootData) {
						render(rootData);
					},
					true
				);
			}
		};
	}
]);

import SalesCoachTitleCategoryRole from 'App/resources/SalesCoachTitleCategoryRole';
import { Text, Title, Button, Icon, Link, Tooltip } from '@upsales/components';
import SalesCoachStakeholder from 'Resources/SalesCoachStakeholder';
import { makeCancelable } from 'App/babel/helpers/promise';
import { SlideFade } from '@upsales/components/animations';
import SelectHelper from '../Helpers/SelectHelper';
import logError from 'App/babel/helpers/logError';
import translate from '../Helpers/translate';
import UpSelect from '../Inputs/UpSelect';
import PropTypes from 'prop-types';
import React from 'react';

import './DecisionMakersSidebar.scss';

const formatResult = function (contact, clientId) {
	if (!contact.id) {
		return '<i class="grey">' + translate('default.noContact') + '</i>';
	}
	return SelectHelper.clientContactsAndRelations.formatResult(contact, clientId);
};

const matcher = (term, undef, contact) => {
	return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
};

class DecisionMakersSidebar extends React.PureComponent {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.state = {
			roleMap: {},
			accountContacts: [],
			isExpanded: true
		};
		this.contactListener = Tools.$rootScope.$on('contact.added', this.updateContact.bind(this));

		this.lang = {
			stakeholders: t('default.stakeholder'),
			titleCategories: t('default.titleCategories'),
			less: t('default.showLess'),
			more: t('default.showMore'),
			noTitle: t('default.noTitle'),
			add: t('default.add'),
			selectContact: t('default.selectContact'),
			remove: t('default.remove'),
			addOneMore: t('opportunity.addOneMore'),
			createNewContact: t('opportunity.createNewContact'),
			createNew: t('opportunity.createNew')
		};
	}

	componentDidMount() {
		this.init(false);
	}

	componentDidUpdate(prevProps) {
		// When you change sales process
		if (prevProps.salesCoachDecisionMaker.salesCoachId !== this.props.salesCoachDecisionMaker.salesCoachId) {
			this.init(true);
		}
		// When you create a call/appointment with a title category contact
		else if (prevProps.newTitleCategories !== this.props.newTitleCategories) {
			this.updateNewTitleCategories(this.props.newTitleCategories);
		}
	}

	updateContact(e, contact) {
		this.setState({ accountContacts: [...this.state.accountContacts, contact] });
	}

	componentWillUnmount() {
		if (this.contactListener) {
			this.contactListener();
		}
		if (this.cancelablePromiseStakeholdersCoach) {
			this.cancelablePromiseStakeholdersCoach.cancel();
		}
		if (this.cancelablePromiseTitleCategories) {
			this.cancelablePromiseTitleCategories.cancel();
		}
	}

	toggleExpanded() {
		this.setState({ isExpanded: !this.state.isExpanded });
	}

	addContactToggle(tagId) {
		const { roleMap } = this.state;
		const role = roleMap[tagId];
		if (!role) {
			return;
		}

		role.addingContact = !role.addingContact;
		roleMap[tagId] = role;
		this.setState({ roleMap: { ...roleMap } });
	}

	updateNewTitleCategories(newTitleCategories) {
		const roleMap = this.updateRoleMap(newTitleCategories, this.state.roleMap, this.state.accountContacts);
		this.setState({ roleMap: { ...roleMap } });
	}

	updateRoleMap(newDecisionMakers, roleMap, contacts) {
		const newRoleMap = roleMap;
		newDecisionMakers.forEach(decisionMaker => {
			if ((decisionMaker.role?.tagId || decisionMaker.tagId) && decisionMaker.contactId) {
				const tagId = decisionMaker.role?.tagId || decisionMaker.tagId;
				const role = newRoleMap[tagId];

				const isAccountContact = _.find(contacts, { id: decisionMaker.contact.id });
				if (role && isAccountContact) {
					role.hasContacts = true;
					role.contactMap[decisionMaker.contact.id] = decisionMaker.contact;
					newRoleMap[tagId] = role;
				}
			}
		});
		return newRoleMap;
	}

	async initTitleCategories(titleCategories = [], contacts = [], salesCoachId) {
		this.cancelablePromiseTitleCategories = makeCancelable(SalesCoachTitleCategoryRole.find({ salesCoachId }));
		const translations = await this.cancelablePromiseTitleCategories.promise;
		const roleMap = {};
		contacts = _.filter(contacts, contact => contact.id);
		translations.data.forEach(role => {
			roleMap[role.tagId] = {
				tagId: role.tagId,
				name: role.value,
				contactMap: {}
			};
		});
		const updatedRoleMap = this.updateRoleMap(titleCategories, roleMap, contacts);
		this.setState({ roleMap: { ...updatedRoleMap }, accountContacts: [...contacts] });
	}

	async initStakeholders(salesProcessId, stakeholders = [], contacts = [], salesCoachId) {
		let translations;
		if (salesCoachId) {
			this.cancelablePromiseStakeholdersCoach = makeCancelable(SalesCoachStakeholder.find({ salesCoachId }));
			translations = await this.cancelablePromiseStakeholdersCoach.promise;
			translations.data = translations.data.filter(stakeholder => stakeholder.active);
		}
		const roleMap = {};
		contacts = _.filter(contacts, contact => contact.id);

		translations.data?.forEach(role => {
			roleMap[role.tagId] = {
				tagId: role.tagId,
				name: role.value,
				contactMap: {}
			};
		});

		const updatedRoleMap = this.updateRoleMap(stakeholders, roleMap, contacts);
		this.setState({ roleMap: { ...updatedRoleMap }, accountContacts: [...contacts] });
	}

	init(changedSalesProcess) {
		if (this.props.salesCoachDecisionMaker?.hasTitleCategories) {
			this.initTitleCategories(
				changedSalesProcess ? [] : this.props.titleCategories,
				this.props.contacts,
				this.props.salesCoachDecisionMaker.salesCoachId
			);
		} else {
			this.initStakeholders(
				this.props.salesProcessId,
				changedSalesProcess ? [] : this.props.stakeholders,
				this.props.contacts,
				this.props.salesCoachDecisionMaker?.salesCoachId
			);
		}
	}

	updateDecisionMakers() {
		const currentDecisionMakers = _.reduce(
			Object.values(this.state.roleMap),
			(res, role) => {
				const contacts = Object.values(role.contactMap);
				contacts.forEach(contact => {
					res.push({
						contactId: contact.id,
						tagId: role.tagId,
						contact: { id: contact.id, name: contact.name }
					});
				});

				return res;
			},
			[]
		);

		if (this.props.salesCoachDecisionMaker?.hasTitleCategories) {
			this.props.updateTitleCategories(currentDecisionMakers);
		} else {
			this.props.updateStakeholders(currentDecisionMakers);
		}
	}

	addContact(tagId, contact) {
		const roleMap = this.state.roleMap;
		const role = roleMap[tagId];
		if (!role) {
			return;
		}

		role.contactMap[contact.id] = contact;
		role.hasContacts = true;
		roleMap[tagId] = role;

		this.setState({ roleMap: { ...roleMap } });
		this.updateDecisionMakers();
	}

	removeContact(tagId, contactId) {
		const roleMap = this.state.roleMap;
		const role = roleMap[tagId];
		if (!role) {
			return;
		}

		delete role.contactMap[contactId];
		if (Object.keys(role.contactMap).length === 0) {
			role.hasContacts = false;
		}
		roleMap[tagId] = role;
		this.setState({ roleMap: { ...roleMap } });

		this.updateDecisionMakers();
	}

	openContactModal(clientId, role) {
		let titleCategory = null;
		if (this.props.salesCoachDecisionMaker?.hasTitleCategories) {
			titleCategory = {
				tagId: role.tagId,
				value: role.name
			};
		}
		Tools.$upModal
			.open('editContact', { clientId, titleCategory })
			.then(contact => {
				if (this.props.salesCoachDecisionMaker?.hasTitleCategories) {
					if (role.tagId === contact.titleCategory?.tagId) {
						this.addContact(role.tagId, contact);
					}
				} else {
					this.addContact(role.tagId, contact);
				}
			})
			.catch(err => {
				logError(err, 'Could not add the contact');
			});
	}

	renderRoleContacts(role) {
		const { disabled } = this.props;
		let contacts = Object.values(role.contactMap);
		contacts = _.sortBy(contacts, 'name');
		const customerId = Tools.AppService.getCustomerId();

		return contacts.map(contact => {
			return (
				<div key={contact.id} className="DecisionMakersSidebar--Role-Contact">
					<Icon name="user" />
					<div>
						<Link
							onClick={e => e.stopPropagation()}
							href={Tools.$state.href('contact.dashboard', {
								customerId: customerId,
								id: contact.id
							})}
						>
							<Text>{contact.name}</Text>
						</Link>
						<Text className="DecisionMakersSidebar--Role-Contact_title">{role.name}</Text>
						{disabled ? null : (
							<Tooltip title={this.lang.remove}>
								<Button
									type="link"
									size="sm"
									onClick={() => this.removeContact(role.tagId, contact.id)}
								>
									<Icon name={'trash-o'} color="grey-10" />
								</Button>
							</Tooltip>
						)}
					</div>
				</div>
			);
		});
	}

	renderRoles() {
		const { roleMap, accountContacts } = this.state;
		const { disabled } = this.props;
		let roles = Object.values(roleMap);
		roles = _.sortBy(roles, 'name');
		return roles.map(role => {
			const roleContacts = Object.values(role.contactMap);
			let atLeastOneIsChosen = false;
			let contactsToChooseFrom = accountContacts;

			let createNewButtonText = this.lang.createNewContact;
			if (this.props.salesCoachDecisionMaker?.hasTitleCategories) {
				contactsToChooseFrom = accountContacts.filter(contact => contact.titleCategory?.tagId === role.tagId);
				createNewButtonText = this.lang.createNew + role.name;
			}
			const staticAddButton = {
				onClick: () => this.openContactModal(this.props.clientId, role),
				label: createNewButtonText
			};
			const availableContacts = _.reduce(
				contactsToChooseFrom,
				(res, contact) => {
					const alreadySelected = _.find(roleContacts, { id: contact.id });
					if (!alreadySelected) {
						res.push(contact);
					} else {
						atLeastOneIsChosen = true;
					}
					return res;
				},
				[]
			);
			const renderedContacts = this.renderRoleContacts(role);
			var eventListeners = {
				'select2-close': () => {
					setTimeout(() => {
						this.addContactToggle(role.tagId);
					}, 0);
				}
			};
			return (
				<div key={role.tagId} className="DecisionMakersSidebar--Role">
					<div className="DecisionMakersSidebar--Role_Content">
						{renderedContacts}
						{!role.addingContact && !atLeastOneIsChosen && (
							<Button type="link" onClick={() => this.addContactToggle(role.tagId)} disabled={disabled}>
								<Icon name="plus" />
								<Text>{this.lang.add + ' ' + role.name} </Text>
							</Button>
						)}
						{role.addingContact && (
							<UpSelect
								className="form-control"
								placeholder={this.lang.selectContact}
								eventListeners={eventListeners}
								dropdownCssClass={'DecisionMakersSidebar--Select2-dropdown'}
								formatResult={contact => formatResult(contact, this.props.contactId)}
								matcher={matcher}
								autoOpen={true}
								autoFocus={true}
								data={availableContacts}
								required={true}
								onChange={e => this.addContact(role.tagId, e.target.added)}
								staticAddButton={staticAddButton}
							/>
						)}
						{atLeastOneIsChosen && !!availableContacts.length && (
							<Button
								className="DecisionMakersSidebar--Role_Content_more"
								type="link"
								onClick={() => this.addContactToggle(role.tagId)}
							>
								<Text>
									<Icon name="plus" />
									{this.lang.addOneMore}
								</Text>
							</Button>
						)}
					</div>
				</div>
			);
		});
	}

	render() {
		const { isExpanded, roleMap } = this.state;
		const renderedRoles = this.renderRoles();
		const maxHeight = 112 * Object.values(roleMap).length;

		return (
			<div className="DecisionMakersSidebar">
				<Title size="md">
					{this.props.salesCoachDecisionMaker?.hasTitleCategories
						? this.lang.titleCategories
						: this.lang.stakeholders}
					<Tooltip type="link" title={isExpanded ? this.lang.less : this.lang.more}>
						<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} onClick={() => this.toggleExpanded()} />
					</Tooltip>
				</Title>

				<SlideFade visible={isExpanded} direction="top" height maxHeight={maxHeight}>
					<div className="DecisionMakers--Roles">{renderedRoles}</div>
				</SlideFade>
			</div>
		);
	}
}

DecisionMakersSidebar.propTypes = {
	salseProcessId: PropTypes.number,
	salesCoachId: PropTypes.number,
	updateStakeholders: PropTypes.func,
	updateTitleCategories: PropTypes.func,
	stakeholders: PropTypes.array,
	titleCategories: PropTypes.array,
	contacts: PropTypes.array,
	clientId: PropTypes.number,
	salesCoachDecisionMaker: PropTypes.object,
	disabled: PropTypes.bool
};

export default DecisionMakersSidebar;

'use strict';

angular.module('upDirectives').directive('upValueSuggest', [
	'$compile',
	function ($compile) {
		return {
			restrict: 'A',
			priority: 1000,
			scope: {
				lowerCaseSearch: '@'
			},
			require: ['ngModel', 'upValueSuggest'],
			controller: [
				'$scope',
				'$timeout',
				'Lookup',
				'AppService',
				'ListAttributes',
				'$parse',
				function ($scope, $timeout, Lookup, AppService, ListAttributes, $parse) {
					var typeTimer;
					var customerId;
					var type;
					var field;

					AppService.loadedPromise.then(function () {
						customerId = AppService.getCustomerId();
					});

					$scope.doLookup = function (term) {
						if (term && term.length) {
							if (typeTimer) {
								$timeout.cancel(typeTimer);
							}

							typeTimer = $timeout(function () {
								var searchTerm = term;

								if ($scope.lowerCaseSearch && searchTerm) {
									searchTerm = searchTerm.toLowerCase();
								}

								return Lookup.customer(customerId)
									.setType(type)
									.findEnd(field, searchTerm)
									.then(function (res) {
										var results = _.unique(_.map(_.pluck(res.data, 'value'), _.trim));
										return _.filter(results, function (val) {
											return val.toLowerCase().indexOf(term.toLowerCase()) !== -1;
										});
									});
							}, 200);

							return typeTimer;
						}

						return [];
					};

					this.init = function (sType, sField) {
						if (!sType) {
							throw new Error('Missing type for upValueSuggest directive');
						} else if (!Lookup.allTypes[sType]) {
							throw new Error(sType + ' is not a valid type for upValueSuggest directive');
						}
						type = Lookup.allTypes[sType];

						var attrs = ListAttributes.get(sType.toLowerCase()).attr;

						field = $parse(sField)(attrs);

						if (!sField || !field) {
							throw new Error('Invalid field upValueSuggest directive');
						}
					};
				}
			],
			compile: function ($cElement, $cAttrs) {
				// Re-set this directive so that we do not get a inf-loop
				$cAttrs.$set('up-value-suggest');

				return {
					post: function ($scope, $element, $attrs, requires) {
						var ngModel = requires[0];
						var ctrl = requires[1];

						// Init ctrl
						ctrl.init($attrs.entity, $attrs.field);

						$compile($element)($scope);

						$element.ready(function () {
							$element.typeahead(
								{
									highlight: true,
									classNames: {
										input: 'typeahead-input',
										hint: 'typeahead-hint',
										menu: 'typeahead-menu',
										dataset: 'typeahead-dataset',
										suggestion: 'typeahead-suggestion',
										cursor: 'typeahead-selected'
									}
								},
								{
									async: true,
									limit: Infinity,
									source: function (query, cb, cbAsync) {
										$scope.doLookup(query).then(cbAsync);
									}
								}
							);

							$element.on('typeahead:select', function (ev, suggestion) {
								ngModel.$setViewValue(suggestion);
							});
						});

						// When the model is updated from the outside
						ngModel.$render = function () {
							setTimeout(function () {
								$element.typeahead('val', ngModel.$viewValue);
							}, 20);
						};
					}
				};
			}
		};
	}
]);

angular.module('upDirectives').directive('upValueSuggestTags', [
	'Lookup',
	'$translate',
	'AppService',
	'ListAttributes',
	'$parse',
	function (Lookup, $translate, AppService, ListAttributes, $parse) {
		return {
			restrict: 'A',
			replace: true,
			require: '^ngModel',
			link: function ($scope, $element, $attrs, ngModel) {
				var customerId = AppService.getCustomerId();

				if (!$attrs.entity || !$attrs.field) {
					throw new Error('Invalid configuration of upValueSuggest directive');
				}

				var entity = Lookup.allTypes[$attrs.entity];
				var attrs = ListAttributes.get($attrs.entity.toLowerCase()).attr;
				var field = $parse($attrs.field)(attrs);
				var placeholder = $attrs.placeholder ? $translate.instant($attrs.placeholder) : '';
				var standardField = $attrs.standardField || null;

				var getName = function (standardFieldOptions, value) {
					if (standardField) {
						var selectedOption = _.find(standardFieldOptions, { id: value });
						return selectedOption ? selectedOption.name : value;
					} else {
						return value;
					}
				};

				$element.removeAttr('placeholder');

				if (!field || !entity) {
					throw new Error('Invalid configuration of upValueSuggest directive');
				}

				$($element)
					.select2({
						tags: [],
						multiple: true,
						closeOnSelect: false,
						placeholder: placeholder,
						formatResult: function (item) {
							if (item.count && !$attrs.hasOwnProperty('hideCount')) {
								return _.capitalize(_.escape(item.text)) + ' (' + item.count + ')';
							} else {
								return _.capitalize(_.escape(item.text));
							}
						},
						formatSelection: function (item, container, escape) {
							return _.capitalize(escape(item.text) || '');
						},
						ajax: {
							quietMillis: 100,
							data: function (term) {
								return term;
							},
							transport: function (query) {
								var searchTerm = (query.data || '').toLowerCase();

								var exlucdeValues = _.map(ngModel.$modelValue, function (value) {
									return value;
								});

								var standardFieldOptions = standardField
									? Tools.AppService.getStaticValues(standardField)
									: [];

								Lookup.customer(customerId)
									.setType(entity)
									.findEnd(
										field,
										searchTerm,
										10,
										[{ _count: 'desc' }, { _term: 'asc' }],
										exlucdeValues
									)
									.then(function (res) {
										var data = _.reduce(
											res.data,
											function (res, item) {
												if (item.value) {
													res.push({
														id: item.value,
														text: getName(standardFieldOptions, item.value),
														count: item.count
													});
												}
												return res;
											},
											[]
										);

										query.success({
											data: data
										});
									});
							},
							results: function (res) {
								return {
									results: res.data
								};
							}
						},
						formatNoMatches: function () {
							return $translate.instant('default.noResults');
						}
					})
					.on('change', function (event) {
						if ($scope.$$phase || $scope.$root.$$phase) {
							return;
						}
						$scope.$apply(function () {
							ngModel.$setViewValue(event.val);
							ngModel.$render();
						});
					});

				$scope.$watch($attrs.ngModel, function (val) {
					if (!val || !val.length) {
						ngModel.$setViewValue(val);
						ngModel.$render();
						$element.select2('val', '');
					} else {
						var standardFieldOptions = standardField ? Tools.AppService.getStaticValues(standardField) : [];

						var data = _.map(val, function (value) {
							return {
								id: value,
								text: getName(standardFieldOptions, value)
							};
						});
						$element.select2('data', data);
					}
				});
			}
		};
	}
]);

angular.module('upDirectives').directive('upValueSuggestSniCode', [
	'Lookup',
	'$translate',
	'AppService',
	'ListAttributes',
	'$parse',
	'ORG_NUMBER_MAP',
	function (Lookup, $translate, AppService, ListAttributes, $parse, ORG_NUMBER_MAP) {
		return {
			restrict: 'A',
			replace: true,
			require: '^ngModel',
			link: function ($scope, $element, $attrs, ngModel) {
				var customerId = AppService.getCustomerId();

				if (!$attrs.entity || !$attrs.field) {
					throw new Error('Invalid configuration of upValueSuggest directive');
				}

				var entity = Lookup.allTypes[$attrs.entity];
				var attrs = ListAttributes.get($attrs.entity.toLowerCase()).attr;
				var field = $parse($attrs.field)(attrs);
				var placeholder = $attrs.placeholder ? $translate.instant($attrs.placeholder) : '';
				var standardField = $attrs.standardField || null;
				var dataTree = {};
				var flatTree = [];
				var selectedGroups = [];

				var getFlatSniCodes = function (values) {
					var sniCodeArray = [];
					selectedGroups = [];
					_.each(values, function (value) {
						var sniGroup = dataTree[value];
						if (sniGroup) {
							selectedGroups.push(sniGroup);
							var children = _.map(sniGroup.children, function (child) {
								return child.id;
							});
							sniCodeArray = sniCodeArray.concat(children);
						} else {
							sniCodeArray.push(value);
						}
					});
					return sniCodeArray;
				};

				var getSniGroup = function (sniCode) {
					var key = parseInt(sniCode.id).toString();

					if (key.length === 4) {
						key = key.substr(0, 1);
					} else {
						key = key.substr(0, 2);
					}

					var keyAsInt = parseInt(key);

					return _.find(ORG_NUMBER_MAP, function (group) {
						return group.isInGroup(keyAsInt);
					});
				};

				var getName = function (standardFieldOptions, value) {
					if (standardField) {
						var selectedOption = _.find(standardFieldOptions, { id: value });
						if (selectedOption) {
							return selectedOption.name;
						}
						var sniGroup = dataTree[value];
						if (sniGroup) {
							return sniGroup.text;
						}
					}
					return value;
				};

				var buildTree = function (sniCodes) {
					_.each(sniCodes, function (code) {
						var sniGroup = getSniGroup(code);
						if (sniGroup) {
							if (!dataTree[sniGroup.id]) {
								dataTree[sniGroup.id] = {
									id: sniGroup.id,
									text: $translate.instant(sniGroup.name),
									gte: sniGroup.gte,
									lt: sniGroup.lt,
									count: 0,
									isParent: true,
									children: []
								};
							}
							code.parent = sniGroup.id;
							dataTree[sniGroup.id].count += code.count;
							dataTree[sniGroup.id].children.push(code);
						}
					});

					flatTree = _.map(Object.values(dataTree), function (group) {
						return group;
					});
					return flatTree;
				};

				$element.removeAttr('placeholder');

				if (!field || !entity) {
					throw new Error('Invalid configuration of upValueSuggest directive');
				}

				$($element)
					.select2({
						tags: [],
						multiple: true,
						closeOnSelect: false,
						placeholder: placeholder,
						formatResult: function (item) {
							var value = _.capitalize(_.escape(item.text));
							if (item.count && !$attrs.hasOwnProperty('hideCount')) {
								value = _.capitalize(_.escape(item.text)) + ' (' + item.count + ')';
							}
							if (item.isParent) {
								value = '<b>' + value + '</b>';
							}
							return '<span style="font-size: 12px">' + value + '</span>';
						},
						formatSelection: function (item, container, escape) {
							return _.capitalize(escape(item.text) || '');
						},
						ajax: {
							quietMillis: 100,
							data: function (term) {
								return term;
							},
							transport: function (query) {
								var standardFieldOptions = standardField
									? Tools.AppService.getStaticValues(standardField)
									: [];
								if (flatTree.length) {
									var result = _.cloneDeep(flatTree);

									if (query.data) {
										var searchTerm = query.data.toLowerCase();
										result = _.filter(result, function (sniGroup) {
											var matchesGroup =
												sniGroup.text && sniGroup.text.toLowerCase().indexOf(searchTerm) >= 0;
											if (matchesGroup) {
												return true;
											}
											var childMatches = _.filter(sniGroup.children, function (child) {
												return child.text && child.text.toLowerCase().indexOf(searchTerm) >= 0;
											});
											if (childMatches.length) {
												sniGroup.children = childMatches;
												return true;
											}
											return false;
										});
									}
									return query.success({
										data: result
									});
								}

								Lookup.customer(customerId)
									.setType(entity)
									.findEnd(field, '', 2000, [{ _term: 'asc' }])
									.then(function (res) {
										var sniCodes = _.reduce(
											res.data,
											function (res, item) {
												if (item.value) {
													res.push({
														id: item.value,
														text: getName(standardFieldOptions, item.value),
														count: item.count
													});
												}
												return res;
											},
											[]
										);

										query.success({
											data: buildTree(sniCodes)
										});
									});
							},
							results: function (res) {
								var result = res.data;
								if (ngModel.$modelValue && ngModel.$modelValue.length) {
									var sniCodes = [];
									var sniGroups = [];
									_.each(ngModel.$modelValue, function (exclude) {
										var sniGroup = dataTree[exclude];
										if (sniGroup) {
											sniGroups.push(sniGroup);
										} else {
											sniCodes.push(exclude);
										}
									});
									result = _.reject(result, function (sniCode) {
										var sniCodeReject = _.contains(sniCodes, sniCode.id);
										if (sniCodeReject) {
											return true;
										}
										var sniGroupReject = _.find(sniGroups, function (group) {
											var id = parseInt(sniCode.id);
											return group.gte <= id && id < group.lt;
										});
										return !!sniGroupReject;
									});
								}
								return {
									results: result
								};
							}
						},
						formatNoMatches: function () {
							return $translate.instant('default.noResults');
						}
					})
					.on('change', function (event) {
						if ($scope.$$phase || $scope.$root.$$phase) {
							return;
						}
						$scope.$apply(function () {
							var flat = getFlatSniCodes(event.val);
							ngModel.$setViewValue(flat);
							ngModel.$render();
						});
					});

				$scope.$watch($attrs.ngModel, function (val) {
					if (selectedGroups.length) {
						val = _.reject(val, function (value) {
							var foundInGroup = _.find(selectedGroups, function (group) {
								return _.find(group.children, { id: value });
							});
							return !!foundInGroup;
						});
						val = val.concat(
							_.map(selectedGroups, function (group) {
								return group.id;
							})
						);
					}

					if (!val || !val.length) {
						ngModel.$setViewValue(val);
						ngModel.$render();
						$element.select2('val', '');
					} else {
						var standardFieldOptions = standardField ? Tools.AppService.getStaticValues(standardField) : [];

						var data = _.map(val, function (value) {
							return {
								id: value,
								text: getName(standardFieldOptions, value)
							};
						});
						$element.select2('data', data);
					}
				});
			}
		};
	}
]);

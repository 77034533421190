import { Icon, Toggle, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { connect } from 'react-redux';
import { updateForm } from 'Store/actions/FormEditorActions';

const mapStateToProps = {
	updateForm
};

ReactTemplates.form.formContentType = connect(
	null,
	mapStateToProps
)(
	window.ReactCreateClass({
		getInitialState: function () {
			return {
				element: this.props.element,
				form: this.props.form,
				activeTab: 'fields',
				replaceForm: null
			};
		},
		UNSAFE_componentWillReceiveProps: function (nextProps) {
			this.setState({ element: nextProps.element, form: nextProps.form });
		},
		UNSAFE_componentWillMount: function () {
			this.lang = {
				title: T('default.title'),
				useRecaptcha: T('form.Prefs.reCaptcha'),
				whatsgonahappend: T('admin.whatsgonahappend'),
				fields: T('form.fields'),
				description: T('default.description'),
				buttonText: T('form.buttonText'),
				giveContactScore: T('form.giveContactScore'),
				text: T('bannerEditor.text'),
				linkColor: T('form.linkColor'),
				header: T('landingpage.header'),
				style: T('landingpage.style'),
				color: T('mail.color'),
				button: T('bannerEditor.button'),
				background: T('bannerEditor.background'),
				backgroundColor: T('form.backgroundColor'),
				actionTooltip: T('info.formAction'),
				recaptchaTooltip: T('form.Prefs.reCaptcha.Tooltip'),
				recaptchaWarning: T('form.reCaptcha.warning'),
				settings: T('navbar.settings'),
				addField: T('form.addField'),
				addAction: T('form.addAction'),
				replaceFormWith: T('form.replaceWith'),
				fetchFieldsFrom: T('form.fetchFieldsFrom'),
				replace: T('default.replace'),
				abort: T('default.abort'),
				useDoubleOptin: T('form.Prefs.doubleOptIn'),
				eventRegistration: T('socialEvent.eventRegistration')
			};

			this.prefs = {
				reCaptcha: { title: T('form.Prefs.reCaptchaFake'), name: 'Prefs.reCaptcha', type: 'checkbox' },
				dontMatchOnClientName: {
					title: T('form.Prefs.dontMatchOnClientName'),
					name: 'Prefs.dontMatchOnClientName',
					type: 'checkbox'
				},
				bisnodeAutocomplete: {
					title: 'form.Prefs.bisnodeAutocomplete',
					name: 'Prefs.bisnodeAutocomplete',
					type: 'checkbox'
				},
				verifyEmailDomain: {
					title: 'form.Prefs.verifyEmailDomain',
					name: 'Prefs.verifyEmailDomain',
					type: 'checkbox'
				},
				doubleOptIn: {
					title: T('form.Prefs.doubleOptInDefaultValue'),
					name: 'Prefs.doubleOptIn',
					defaultValue: 'form.Prefs.doubleOptInDefaultValue',
					type: 'checkbox'
				}
			};

			this.fieldsToExclude = [
				'Prefs.reCaptcha',
				'Prefs.bisnodeAutocomplete',
				'Prefs.verifyEmailDomain',
				'Prefs.js',
				'Prefs.css'
			];
		},
		enableSortable: function () {
			var self = this;
			var list = window.jQuery(this._fieldsList);
			var options = {
				containment: 'parent',
				cursor: 'move',
				disabled: false,
				scroll: false,
				axis: 'y',
				items: '> .field-row',
				handle: '.drag-handle',
				tolerance: 'pointer',
				start: function (e, ui) {
					var fillOut = '<div class="sort-fillout"></div>';
					list.append(fillOut).prepend(fillOut);
					ui.helper.css('height', ui.helper.height() + 40 + 'px');
					ui.helper.css('width', ui.helper.width() + 40 + 'px');
				},
				stop: function (e, ui) {
					list.find('.sort-fillout').remove();

					var form = _.cloneDeep(self.state.form);
					var prefs = _.remove(form.fields, function (f) {
						return f.name.indexOf('Prefs.') === 0;
					});

					var index = ui.item.data('sort');
					var moved = _.remove(form.fields, form.fields[index]);

					var newIndex = ui.item.index();
					form.fields.splice(newIndex, 0, moved[0]);
					form.fields = form.fields.concat(prefs);

					self.setState({ form: form });
					self.props.onFormChange(form);
				}
			};

			list.sortable(options).disableSelection();
		},
		componentDidMount: function () {
			this.enableSortable();
		},
		rootPropChange: function (key, e) {
			var form = this.state.form;

			form[key] = e.target.value;

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		prefChanged: function (pref) {
			var form = this.state.form;
			var captchaFieldIndex = _.findIndex(form.fields, { name: pref.name });
			if (captchaFieldIndex !== -1) {
				form.fields.splice(captchaFieldIndex, 1);
			} else {
				form.fields.push(pref);
			}

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		removeAction: function (actionIndex, e) {
			e.stopPropagation();
			var form = this.state.form;
			form.actions.splice(actionIndex, 1);
			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		editAction: function (actionIndex, add) {
			this.props.updateForm(_.cloneDeep(this.state.form));
			this.props.onEditAction(actionIndex, add);
		},
		addField: function () {
			this.props.onSelectField();
		},
		removeField: function (fieldName) {
			var form = this.state.form;
			_.remove(form.fields, { name: fieldName });
			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		onFieldChange: function (fieldIndex, newField) {
			var form = _.cloneDeep(this.state.form);
			var i = _.findIndex(this.state.form.fields, { name: newField.name });
			form.fields[i] = newField;

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		setRef: function (name, ref) {
			this[name] = ref;
		},
		colorChange: function (key, value) {
			var form = this.state.form;

			form[key] = value;

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		suggestBisnodeChanged: function () {
			var form = this.state.form;

			var index = _.findIndex(this.state.form.fields, { name: 'Prefs.bisnodeAutocomplete' });

			if (index === -1) {
				form.fields.push(this.prefs.bisnodeAutocomplete);
			} else {
				form.fields.splice(index, 1);
			}

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		verifyEmailDomain: function () {
			var form = this.state.form;

			var index = _.findIndex(this.state.form.fields, { name: 'Prefs.verifyEmailDomain' });

			if (index === -1) {
				form.fields.push(this.prefs.verifyEmailDomain);
			} else {
				form.fields.splice(index, 1);
			}

			this.setState({ form: form });
			this.props.onFormChange(form);
		},
		useBgChanged: function () {
			if (this.state.form.backgroundColor === 'transparent') {
				this.colorChange('backgroundColor', '#ffffff');
			} else {
				this.colorChange('backgroundColor', 'transparent');
			}
		},
		fixScore: function (e) {
			if (e.target.value === '') {
				this.rootPropChange('score', { target: { value: 0 } });
			}
		},
		setActiveTab: function (tab) {
			this.setState({ activeTab: tab });
		},
		copyForm: function (form) {
			this.setState({
				replaceForm: form
			});
		},
		abortCopyForm: function () {
			this.setState({
				replaceForm: null
			});
		},
		confirmCopyForm: function () {
			var self = this;
			var form = self.state.form;

			form.fields = _.map(self.state.replaceForm.fields, function (field) {
				if (field.name.indexOf('Extra.') !== -1) {
					field.type = 'extra';
				}

				return field;
			});

			self.setState({
				form: form,
				replaceForm: null
			});
			self.props.onFormChange(form);
		},
		render: function () {
			var self = this;
			var t = Tools.$translate;
			var form = self.state.form;
			var titleClass = 'floating-label-input';
			var descClass = 'floating-label-textarea';
			var buttonTextClass = 'floating-label-input';
			var tabs = null;
			var fieldsHeader = null;

			if (form.title && form.title.length) {
				titleClass += ' has-value';
			}
			if (form.description && form.description.length) {
				descClass += ' has-value';
			}
			if (form.buttonText && form.buttonText.length) {
				buttonTextClass += ' has-value';
			}

			var hasRecaptcha = _.find(form.fields, { name: self.prefs.reCaptcha.name });
			var selectedDomain = _.find(this.props.verifiedDomains, { name: form.domain });
			var domainMissingCaptchaKeys =
				!selectedDomain || !selectedDomain.reCaptchaKey || !selectedDomain.reCaptchaSecret;
			var warnRecaptcha = hasRecaptcha && form.domain && domainMissingCaptchaKeys;
			var eventRegistrationAction = _.find(form.actions, { action: 'EventRegistration' });

			var actions = _.map(form.actions, function (action, i) {
				if (action.action === 'EventRegistration') {
					return null;
				}
				let isCreatingPrioCalls = false;
				if (action.action === 'PlanPhonecalls' && Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
					isCreatingPrioCalls = !!action.properties.find(
						p => p.name === 'Priority' && parseInt(p.value) === 3
					);
				}
				return (
					<div
						key={'action-' + i}
						className="tool-section action-row"
						onClick={self.editAction.bind(self, i, false)}
					>
						<span className="number">{i + 2}</span>
						{t('automationAction.' + action.action)}
						{isCreatingPrioCalls ? (
							<Tooltip title={T('form.actionCreatesPrioCalls')}>
								<Icon name="flag" color="red" space="mrl mll" />
							</Tooltip>
						) : null}
						<button
							type="button"
							className="up-btn btn-sm btn-grey btn-hover-red btn-link"
							onClick={self.removeAction.bind(self, i)}
						>
							<b className="fa fa-trash-o" />
						</button>
					</div>
				);
			});

			var fields = [];
			_.each(
				_.filter(form.fields, function (f) {
					return self.fieldsToExclude.indexOf(f.name) === -1;
				}),
				function (field, i) {
					fields.push(
						React.createElement(ReactTemplates.form.editFieldRow, {
							key: 'field-' + i + '_' + field.name,
							index: i,
							field: field,
							form: form,
							suggestBisnodeChanged: self.suggestBisnodeChanged,
							verifyEmailDomain: self.verifyEmailDomain,
							onRemoveField: self.removeField.bind(self, field.name),
							onChange: self.onFieldChange.bind(self, i),
							accountCustomFields: self.props.accountCustomFields,
							contactCustomFields: self.props.contactCustomFields,
							hasVerifyDomainAccess: self.props.hasVerifyDomainAccess,
							optIns: self.props.optIns,
							getAvailableOptIns: self.props.getAvailableOptIns
						})
					);
				}
			);

			fields = _.sortBy(fields, 'sort');

			var useBg = true;

			if (form.backgroundColor === 'transparent') {
				useBg = false;
			}

			if (self.state.element) {
				tabs = (
					<ul className="tool-tabs">
						<li
							className={this.state.activeTab === 'fields' ? 'selected-tab' : ''}
							onClick={self.setActiveTab.bind(self, 'fields')}
						>
							{self.lang.fields}
						</li>
						<li
							className={this.state.activeTab === 'style' ? 'selected-tab' : ''}
							onClick={self.setActiveTab.bind(self, 'style')}
						>
							{self.lang.style}
						</li>
					</ul>
				);
			} else {
				fieldsHeader = (
					<div className="tool-header">
						<h3>
							{self.lang.fields} <ReactTemplates.elevio articleId="240" />
						</h3>
					</div>
				);
			}

			var whatsgonahappendTooltip = <ReactTemplates.elevio articleId="237" />;
			var recaptchaTooltip = <ReactTemplates.elevio articleId="238" />;

			return (
				<div>
					<div className="tool-main-section">
						<div className="tool-section">
							<div className={titleClass}>
								<label>{self.lang.title}</label>
								{/* eslint-disable-next-line react/jsx-no-undef */}
								<Input.input
									data-test-id="form-title"
									type="text"
									value={form.title}
									onChange={self.rootPropChange.bind(self, 'title')}
									maxLength={255}
								/>
							</div>
						</div>

						<div className="tool-section">
							<div className={descClass}>
								<label>{self.lang.description}</label>
								{/* eslint-disable-next-line react/jsx-no-undef */}
								<Input.textarea
									data-test-id="form-description"
									value={form.description}
									floating
									onChange={self.rootPropChange.bind(self, 'description')}
									maxLength={255}
								/>
							</div>
						</div>

						<div className="tool-section">
							<div className={buttonTextClass}>
								<label>{self.lang.buttonText}</label>
								{/* eslint-disable-next-line react/jsx-no-undef */}
								<Input.input
									data-test-id="form-submit-button"
									type="text"
									value={form.buttonText}
									onChange={self.rootPropChange.bind(self, 'buttonText')}
									maxLength={255}
								/>
							</div>
						</div>

						<div className="tool-section" key="captcha-row">
							<label style={{ lineHeight: '30px' }}>
								{self.lang.useRecaptcha} {recaptchaTooltip}
							</label>
							<div className="pull-right">
								<Toggle
									checked={!!hasRecaptcha}
									onChange={self.prefChanged.bind(self, self.prefs.reCaptcha)}
									color="medium-green"
								/>
							</div>
							{warnRecaptcha ? (
								<div className="Text Text--orange">
									{this.lang.recaptchaWarning}
									<a
										href={Tools.$state.href('administration.domains', {
											customerId: Tools.AppService.getCustomerId()
										})}
									>
										{this.lang.settings.toLowerCase()}
									</a>
								</div>
							) : null}
						</div>
					</div>

					<div className="tool-header">
						<h3>
							{self.lang.whatsgonahappend} {whatsgonahappendTooltip}
						</h3>
					</div>

					<div className="tool-main-section no-padding no-border transparent">
						<div key="score" className="tool-section action-row no-hover">
							<span className="number">{'1'}</span>
							{self.lang.giveContactScore}
							<input
								type="number"
								value={form.score}
								onChange={self.rootPropChange.bind(self, 'score')}
								onBlur={self.fixScore}
								className="form-control pull-right input-sm"
								id="score-input"
							/>
						</div>
						{eventRegistrationAction ? (
							<div key="event-register" className="tool-section action-row no-hover">
								<span className="number">{'2'}</span>
								{self.lang.eventRegistration}
							</div>
						) : null}
						{actions}
						<button
							className="up-btn btn-link btn-bright-blue btn-sm btn-block"
							onClick={self.editAction.bind(self, null, true)}
						>
							<b className="fa fa-plus" /> {self.lang.addAction}
						</button>
					</div>

					{tabs}

					{fieldsHeader}

					{self.state.activeTab === 'fields' ? (
						<div className="tool-main-section no-padding no-border transparent">
							{self.props.enableFormCopy && (
								<div className="form__select-wrap">
									{React.createElement(ReactTemplates.form.formSelect, {
										onChange: self.copyForm,
										placeholder: self.lang.fetchFieldsFrom,
										fields: ['fields', 'description'],
										containerClass: 'form__select'
									})}
									<ReactTemplates.elevio articleId={439} sidebar={true} />
								</div>
							)}
							{self.state.replaceForm && (
								<div className="replace-form-overlay">
									<div>
										<p style={{ fontStyle: 'italic' }}>
											{self.lang.replaceFormWith} {self.state.replaceForm.name}
										</p>
										<button
											className="up-btn btn-bright-blue btn-block"
											onClick={self.confirmCopyForm}
										>
											{self.lang.replace}
										</button>
										<button
											className="up-btn btn-link btn-bright-blue"
											onClick={self.abortCopyForm}
										>
											{self.lang.abort}
										</button>
									</div>
								</div>
							)}
							<div ref={self.setRef.bind(self, '_fieldsList')}>{fields}</div>
							<button
								className="up-btn btn-link btn-bright-blue btn-sm btn-block"
								onClick={self.addField}
							>
								<b className="fa fa-plus" /> {self.lang.addField}
							</button>
						</div>
					) : null}

					{self.state.activeTab === 'style' ? (
						<div>
							<div className="tool-main-section">
								<div className="tool-header">
									<h3>{this.lang.text}</h3>
								</div>
								<div className="tool-section" key="text-color">
									<label>{this.lang.color}</label>
									<div className="pull-right">
										{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
											selfContained: true,
											align: 'right',
											value: form.textColor,
											isText: false,
											presets: self.props.colorVariables,
											addPreset: self.props.addPreset,
											onChange: self.colorChange.bind(self, 'textColor')
										})}
									</div>
								</div>
								<div className="tool-section" key="link-color">
									<label>{this.lang.linkColor}</label>
									<div className="pull-right">
										{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
											selfContained: true,
											align: 'right',
											value: form.linkColor,
											isText: false,
											presets: self.props.colorVariables,
											addPreset: self.props.addPreset,
											onChange: self.colorChange.bind(self, 'linkColor')
										})}
									</div>
								</div>
								<div className="tool-header">
									<h3>{this.lang.button}</h3>
								</div>
								<div className="tool-section" key="btn-text">
									<label>{this.lang.text}</label>
									<div className="pull-right">
										{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
											selfContained: true,
											align: 'right',
											value: form.buttonTextColor,
											isText: false,
											presets: self.props.colorVariables,
											addPreset: self.props.addPreset,
											onChange: self.colorChange.bind(self, 'buttonTextColor')
										})}
									</div>
								</div>
								<div className="tool-section" key="btn-bg">
									<label>{this.lang.backgroundColor}</label>
									<div className="pull-right">
										{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
											selfContained: true,
											align: 'right',
											value: form.buttonBgColor,
											isText: false,
											presets: self.props.colorVariables,
											addPreset: self.props.addPreset,
											onChange: self.colorChange.bind(self, 'buttonBgColor')
										})}
									</div>
								</div>
								<div className="tool-header">
									<h3>{this.lang.background}</h3>
									<div className="pull-right">
										<ReactTemplates.upFilters.components.toggle
											checked={useBg}
											onChange={self.useBgChanged}
											className="toggle-bright-blue"
										/>
									</div>
								</div>
								{useBg ? (
									<div className="tool-section" key="bg">
										<label>{this.lang.backgroundColor}</label>
										<div className="pull-right">
											{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
												selfContained: true,
												align: 'right',
												value: form.backgroundColor,
												isText: false,
												presets: self.props.colorVariables,
												addPreset: self.props.addPreset,
												onChange: self.colorChange.bind(self, 'backgroundColor')
											})}
										</div>
									</div>
								) : null}
							</div>
						</div>
					) : null}
					<window.CssEditor
						form={self.state.form}
						apply={self.props.onFormChange}
						isVisible={self.state.activeTab === 'style'}
					/>
				</div>
			);
		}
	})
);

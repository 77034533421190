'use strict';

angular.module('domain.campaign').controller('CampaignMailinglists', [
	'$scope',
	'$stateParams',
	'$upModal',
	'RequestBuilder',
	'MailCampaign',
	'$state',
	'NotificationService',
	function ($scope, $stateParams, $upModal, RequestBuilder, MailCampaign, $state, NotificationService) {
		var customerId = $stateParams.customerId;
		var CampaignCtrl = $scope.CampaignCtrl;

		var CampaignMailinglistsCtrl = this;

		CampaignCtrl.CampaignMailinglistsCtrl = CampaignMailinglistsCtrl;

		$scope.limit = 50;
		$scope.mailCampaignsError = false;
		$scope.mailCampaignsLoading = false;
		$scope.mailCampaignsCurrent = 1;
		$scope.mailCampaignsPages = 0;

		$scope.$on('campaign.refreshCampaigntMailList', function () {
			setTimeout(function () {
				CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
			}, 1200);
		});

		$scope.$on('mailCampaign.updated', function () {
			setTimeout(function () {
				CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
			}, 1200);
		});

		$scope.$on('mailCampaign.added', function () {
			setTimeout(function () {
				CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
			}, 1200);
		});

		$scope.$on('listView.refresh', function () {
			setTimeout(function () {
				CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
			}, 1200);
		});


		CampaignMailinglistsCtrl.deleteMailCampaign = function (mailCampaign) {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'default.remove',
					body: 'confirm.removeMailCampaign',
					icon: 'fa-warning',
					resolveTrue: 'default.remove'
				})
				.then(function () {
					mailCampaign.$processing = true;

					// eslint-disable-next-line promise/catch-or-return
					MailCampaign.customer(customerId)
						.delete(mailCampaign)
						.then(function () {
							setTimeout(function () {
								CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
							}, 1200);
						});
				});
		};

		CampaignMailinglistsCtrl.archiveMailCampaign = function (mailCampaign) {
			mailCampaign.$processing = true;

			MailCampaign.customer(customerId)
				.save(mailCampaign)
				.then(function () {
					setTimeout(function () {
						NotificationService.addNotification({
							title: 'default.archived',
							body: 'mail.mailCampaignArchived',
							style: 'Success',
							icon: 'check'
						});
						CampaignMailinglistsCtrl.getMailCampaigns($scope.mailCampaignsCurrent);
					}, 1200);
				})
				.catch(function () {
					NotificationService.addNotification({
						title: 'default.error',
						body: 'mail.mailCampaignArchivedError',
						style: 'Error',
						icon: 'times'
					});
				});
		};

		CampaignMailinglistsCtrl.getMailCampaigns = function (page) {
			var filter = RequestBuilder();
			filter.addFilter(MailCampaign.attr.campaign, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addFilter(MailCampaign.attr.isArchived, filter.comparisonTypes.Equals, false);
			filter.addSort('status', true);
			filter.addSort('sendDate', false);
			filter.limit = $scope.limit;
			filter.offset = $scope.limit * (page - 1);

			MailCampaign.customer(customerId)
				.find(filter.build())
				.then(function (res) {
					$scope.mailCampaignsLoading = false;
					CampaignCtrl.mailCampaignsTotal = res.metadata.total;
					CampaignMailinglistsCtrl.mailCampaigns = res.data;

					/* eslint-disable eqeqeq */
					if (CampaignCtrl.mailCampaignsTotal != 0) {
						/* eslint-enable eqeqeq */
						$scope.mailCampaignsPages = Math.ceil(CampaignCtrl.mailCampaignsTotal / $scope.limit);
					}
				})
				.catch(function () {
					$scope.mailCampaignsError = true;
					$scope.mailCampaignsLoading = false;
				});
		};

		CampaignMailinglistsCtrl.openMailCampaign = function (mailCampaign) {
			if (mailCampaign.status === 'SCHEDULED' || mailCampaign.status === 'DRAFT') {
				Tools.routerHistory.push(`/group-mail-editor/${mailCampaign.id}`);
			} else {
				$state.go('mailCampaign.dashboard', { customerId: customerId, id: mailCampaign.id });
			}
		};

		var init = function () {
			CampaignMailinglistsCtrl.getMailCampaigns(1);
		};

		init();
	}
]);

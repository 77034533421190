'use strict';

angular.module('upResources').factory('FilePublic', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	function FilePublic($resource, $q, URL, API, GenericMapper) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var FilePublic = null;

				FilePublic = $resource(
					URL + API + customer + '/files/public',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.save = function (id) {
					var data = { ids: id };
					return FilePublic.create(data).$promise;
				};

				return instance;
			}
		};

		return Model;
	}
]);

'use strict';
import React from 'react';

ReactTemplates.elevio = window.ReactCreateClass({
	render: function () {
		var style = { borderBottom: 'none' };
		if (this.props.sidebar) {
			if (this.props.text) {
				return (
					<span data-elevio-article={this.props.articleId} style={this.props.style}>
						{this.props.text}
					</span>
				);
			} else if (this.props.children) {
				return (
					<span data-elevio-article={this.props.articleId} style={style}>
						{this.props.children}
					</span>
				);
			}
			return (
				<b
					data-elevio-article={this.props.articleId}
					style={this.props.style}
					className="fa fa-question-circle"
				/>
			);
		}
		if (this.props.text) {
			return <span data-elevio-inline={this.props.articleId}>{this.props.text}</span>;
		} else if (this.props.children) {
			return (
				<span data-elevio-inline={this.props.articleId} style={style}>
					{this.props.children}
				</span>
			);
		}
		return (
			<b data-elevio-inline={this.props.articleId} style={this.props.style} className="fa fa-question-circle" />
		);
	}
});

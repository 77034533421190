import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column, Label, Headline, Toggle } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';

const MailContentEditorCellSettings = ({ column, onChange, accountProfile }) => {
	const classes = new bemClass('MailContentEditor');
	const componentClasses = new bemClass('MailContentEditorCellSettings', classes.elem('sidebar-section').b());

	return (
		<div className={componentClasses.b()}>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Headline size="xs">{translate('mail.cellSettings')}</Headline>
				</Column>
			</Row>
			<Row className={classes.elem('sidebar-section-input-row').b()}>
				<Column>
					<Label>{translate('mail.cellBackgroundEnable')}</Label>
				</Column>
				<Column align="right">
					<Toggle
						color="medium-green"
						checked={column.enableCellBackground}
						onChange={val => onChange({ ...column, enableCellBackground: val })}
					/>
				</Column>
			</Row>
			{column.enableCellBackground ? (
				<Row className={classes.elem('sidebar-section-input-row').b()}>
					<Column>
						<Label>{translate('mail.cellBackground')}</Label>
					</Column>
					<Column align="right">
						<ReactTemplates.bannerEditor.colorPicker
							value={column.backgroundColor}
							presets={accountProfile.colorVariables}
							onChange={color => onChange({ ...column, backgroundColor: color })}
						/>
					</Column>
				</Row>
			) : null}
		</div>
	);
};

MailContentEditorCellSettings.propTypes = {
	column: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

MailContentEditorCellSettings.defaultProps = {
	onChange: () => {}
};

export default MailContentEditorCellSettings;

'use strict';

angular.module('upAttributes').service('BannerGroupAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				draft: {
					title: 'default.draft',
					field: 'draft',
					type: FilterType.Boolean,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				}
			};

			var standardFilters = {};

			var requiredFields = [];

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id'
				}
			};
		};
	}
]);

angular
	.module('up.datepicker', ['up.position'])

	.constant('datepickerConfig', {
		dayFormat: 'dd',
		monthFormat: 'MMMM',
		yearFormat: 'yyyy',
		dayHeaderFormat: 'EEE',
		dayTitleFormat: 'MMMM yyyy',
		monthTitleFormat: 'yyyy',
		showWeeks: true,
		startingDay: 0,
		yearRange: 20,
		minDate: null,
		maxDate: null
	})

	.controller('DatepickerController', [
		'$scope',
		'$attrs',
		'dateFilter',
		'datepickerConfig',
		function ($scope, $attrs, dateFilter, dtConfig) {
			var format = {
					day: getValue($attrs.dayFormat, dtConfig.dayFormat),
					month: getValue($attrs.monthFormat, dtConfig.monthFormat),
					year: getValue($attrs.yearFormat, dtConfig.yearFormat),
					dayHeader: getValue($attrs.dayHeaderFormat, dtConfig.dayHeaderFormat),
					dayTitle: getValue($attrs.dayTitleFormat, dtConfig.dayTitleFormat),
					monthTitle: getValue($attrs.monthTitleFormat, dtConfig.monthTitleFormat)
				},
				startingDay = getValue(
					$attrs.startingDay,
					(moment.localeData()._week && moment.localeData()._week.dow) || 0
				),
				yearRange = getValue($attrs.yearRange, dtConfig.yearRange);

			this.minDate = dtConfig.minDate ? new Date(dtConfig.minDate) : null;
			this.maxDate = dtConfig.maxDate ? new Date(dtConfig.maxDate) : null;

			function getValue(value, defaultValue) {
				return angular.isDefined(value) ? $scope.$parent.$eval(value) : defaultValue;
			}

			function getDaysInMonth(year, month) {
				return new Date(year, month, 0).getDate();
			}

			function getDates(startDate, n) {
				var dates = new Array(n);
				var current = startDate,
					i = 0;
				while (i < n) {
					dates[i++] = new Date(current);
					current.setDate(current.getDate() + 1);
				}
				return dates;
			}

			function makeDate(date, format, isSelected, isSecondary) {
				return {
					date: date,
					label: dateFilter(date, format),
					selected: !!isSelected,
					secondary: !!isSecondary
				};
			}

			this.modes = [
				{
					name: 'day',
					getVisibleDates: function (date, selected) {
						var year = date.getFullYear(),
							month = date.getMonth(),
							firstDayOfMonth = new Date(year, month, 1);
						var difference = startingDay - firstDayOfMonth.getDay(),
							numDisplayedFromPreviousMonth = difference > 0 ? 7 - difference : -difference,
							firstDate = new Date(firstDayOfMonth),
							numDates = 0;

						if (numDisplayedFromPreviousMonth > 0) {
							firstDate.setDate(-numDisplayedFromPreviousMonth + 1);
							numDates += numDisplayedFromPreviousMonth; // Previous
						}
						numDates += getDaysInMonth(year, month + 1); // Current
						numDates += (7 - (numDates % 7)) % 7; // Next

						var days = getDates(firstDate, numDates),
							labels = new Array(7);
						for (var i = 0; i < numDates; i++) {
							var dt = new Date(days[i]);
							days[i] = makeDate(
								dt,
								format.day,
								selected &&
									selected.getDate() === dt.getDate() &&
									selected.getMonth() === dt.getMonth() &&
									selected.getFullYear() === dt.getFullYear(),
								dt.getMonth() !== month
							);
						}
						for (var j = 0; j < 7; j++) {
							labels[j] = dateFilter(days[j].date, format.dayHeader);
						}
						return { objects: days, title: dateFilter(date, format.dayTitle), labels: labels };
					},
					compare: function (date1, date2) {
						return (
							new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
							new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
						);
					},
					split: 7,
					step: { months: 1 }
				},
				{
					name: 'month',
					getVisibleDates: function (date, selected) {
						var months = new Array(12),
							year = date.getFullYear();
						for (var i = 0; i < 12; i++) {
							var dt = new Date(year, i, 1);
							months[i] = makeDate(
								dt,
								format.month,
								selected && selected.getMonth() === i && selected.getFullYear() === year
							);
						}
						return { objects: months, title: dateFilter(date, format.monthTitle) };
					},
					compare: function (date1, date2) {
						return (
							new Date(date1.getFullYear(), date1.getMonth()) -
							new Date(date2.getFullYear(), date2.getMonth())
						);
					},
					split: 3,
					step: { years: 1 }
				},
				{
					name: 'year',
					getVisibleDates: function (date, selected) {
						var years = new Array(yearRange),
							year = date.getFullYear(),
							startYear = parseInt((year - 1) / yearRange, 10) * yearRange + 1;
						for (var i = 0; i < yearRange; i++) {
							var dt = new Date(startYear + i, 0, 1);
							years[i] = makeDate(
								dt,
								format.year,
								selected && selected.getFullYear() === dt.getFullYear()
							);
						}
						return { objects: years, title: [years[0].label, years[yearRange - 1].label].join(' - ') };
					},
					compare: function (date1, date2) {
						return date1.getFullYear() - date2.getFullYear();
					},
					split: 5,
					step: { years: yearRange }
				}
			];

			this.isDisabled = function (date, mode) {
				var currentMode = this.modes[mode || 0];
				return (
					(this.minDate && currentMode.compare(date, this.minDate) < 0) ||
					(this.maxDate && currentMode.compare(date, this.maxDate) > 0) ||
					($scope.dateDisabled && $scope.dateDisabled({ date: date, mode: currentMode.name }))
				);
			};
		}
	])

	.directive('datepicker', [
		'dateFilter',
		'$parse',
		'datepickerConfig',
		'$log',
		'$state',
		'AppService',
		function (dateFilter, $parse, datepickerConfig, $log, $state) {
			return {
				restrict: 'EA',
				replace: true,
				templateUrl: require('App/upsales/common/components/ui/datepicker/templates/datepicker.html?file'),
				scope: {
					dateDisabled: '&'
				},
				require: ['datepicker', '?^ngModel'],
				controller: 'DatepickerController',
				link: function (scope, element, attrs, ctrls) {
					var datepickerCtrl = ctrls[0],
						ngModel = ctrls[1];

					if (!ngModel) {
						return; // do nothing if no ng-model
					}

					// Configuration parameters
					var mode = 0,
						selected = new Date(),
						showWeeks = datepickerConfig.showWeeks;

					if (attrs.showWeeks) {
						scope.$parent.$watch($parse(attrs.showWeeks), function (value) {
							showWeeks = !!value;
							updateShowWeekNumbers();
						});
					} else {
						updateShowWeekNumbers();
					}

					if (attrs.min) {
						scope.$parent.$watch($parse(attrs.min), function (value) {
							datepickerCtrl.minDate = value ? new Date(value) : null;
							refill();
						});
					}
					if (attrs.max) {
						scope.$parent.$watch($parse(attrs.max), function (value) {
							datepickerCtrl.maxDate = value ? new Date(value) : null;
							refill();
						});
					}

					function updateShowWeekNumbers() {
						scope.showWeekNumbers = mode === 0 && showWeeks;
					}

					// Split array into smaller arrays
					function split(arr, size) {
						var arrays = [];
						while (arr.length > 0) {
							arrays.push(arr.splice(0, size));
						}
						return arrays;
					}

					function refill(updateSelected) {
						var date = null,
							valid = true;

						if (ngModel.$modelValue) {
							date = new Date(ngModel.$modelValue);

							if (isNaN(date)) {
								valid = false;
								$log.error(
									'Datepicker directive: "ng-model" value must be a Date object, a number of milliseconds since 01.01.1970 or a string representing an RFC2822 or ISO 8601 date.'
								);
							} else if (updateSelected) {
								selected = date;
							}
						}
						ngModel.$setValidity('date', valid);

						var currentMode = datepickerCtrl.modes[mode],
							data = currentMode.getVisibleDates(selected, date);
						angular.forEach(data.objects, function (obj) {
							obj.disabled = datepickerCtrl.isDisabled(obj.date, mode);
						});

						ngModel.$setValidity('date-disabled', !date || !datepickerCtrl.isDisabled(date));

						scope.rows = split(data.objects, currentMode.split);
						scope.labels = data.labels || [];
						scope.title = data.title;
					}

					function setMode(value) {
						mode = value;
						updateShowWeekNumbers();
						refill();
					}

					ngModel.$render = function () {
						refill(true);
					};

					scope.select = function (date) {
						if (mode === 0) {
							var dt = ngModel.$modelValue
								? new Date(ngModel.$modelValue)
								: new Date(0, 0, 0, 0, 0, 0, 0);
							dt.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
							ngModel.$setViewValue(dt);
							refill(true);
						} else {
							selected = date;
							setMode(mode - 1);
						}
					};
					scope.move = function (direction) {
						var step = datepickerCtrl.modes[mode].step;
						selected.setMonth(selected.getMonth() + direction * (step.months || 0));
						selected.setFullYear(selected.getFullYear() + direction * (step.years || 0));
						refill();
					};

					scope.selectWeek = function (row) {
						scope.select(row[0].date);
						$state.go('react-root-calendar', { date: row[0].date.toJSON(), selected: 'workWeek' });
					};

					scope.toggleMode = function () {
						setMode((mode + 1) % datepickerCtrl.modes.length);
					};
					scope.getWeekNumber = function (row) {
						return mode === 0 && scope.showWeekNumbers && row.length === 7
							? getISO8601WeekNumber(row[0].date)
							: null;
					};

					function getISO8601WeekNumber(date) {
						var checkDate = new Date(date);
						checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7)); // Thursday
						var time = checkDate.getTime();
						checkDate.setMonth(0); // Compare with Jan 1
						checkDate.setDate(1);
						return Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
					}
				}
			};
		}
	])

	.constant('datepickerPopupConfig', {
		dateFormat: 'yyyy-MM-dd',
		currentText: 'Today',
		toggleWeeksText: 'Weeks',
		clearText: 'Clear',
		closeText: 'Done',
		closeOnDateSelection: true,
		appendToBody: false,
		showButtonBar: false
	})

	.directive('datepickerPopupWrap', function () {
		return {
			restrict: 'EA',
			replace: true,
			transclude: true,
			templateUrl: require('App/upsales/common/components/ui/datepicker/templates/popup.html?file'),
			link: function (scope, element) {
				element.bind('click', function (event) {
					event.preventDefault();
					event.stopPropagation();
				});
			}
		};
	});

'use strict';

ReactTemplates.mailCampaign.dashboard = window.ReactCreateClass({
	getInitialState: function () {
		return {
			showMoreStats: false,
			toggleDropdown: false,
			hasClickedToggle: false
		};
	},
	toggleSmallToggle: function () {
		this.setState({
			hasClickedToggle: (this.state.hasClickedToggle = !this.state.hasClickedToggle)
		});
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			deliveredMail: t('mailCampaign.deliveredMail'),
			openedMail: t('mailCampaign.openedMail'),
			click: t('ads.clicks'),
			conversions: t('defaultView.formSubmit'),
			statsRow: {
				more: t('mailCampaign.moreStats'),
				less: t('mailCampaign.lessStats')
			},
			mailOpened: t('mail.opened'),
			all: t('default.all'),
			adsClick: t('ads.click'),
			formLatestSubmits: t('form.latestSubmits'),
			mailUnsubscribers: t('mail.unsubscribers'),
			eventBounces: t('event.bounces'),
			notDeliveredEmails: t('mailCampaign.notDeliveredMail'),
			Events: t('default.countTotal'),
			mostClickedLinks: t('mailCampaign.mostClickedLinks'),
			formConversions: t('mailCampaign.formConversions'),
			latestClick: t('form.lastSubmit') + ' ' + t('ads.topAccountsClick'),
			contactsThatConverted: t('mailCampaign.contactsThatConverted'),
			targetLocation: t('ads.targetLocation'),
			devices: t('device.devices'),
			openFilter: t('mailCampaign.openFilter'),
			whatIsStat: t('mailCampaign.whatIsStat'),
			noDataCountries: t('mailCampaignNoData.countries'),
			noDataDevices: t('mailCampaignNoData.devices'),
			noDataClickedLinks: t('mailCampaignNoData.clickedLinks'),
			noDataSubmits: t('mailCampaignNoData.submits'),
			unknown: t('mailCampaign.dashboard.unknown')
		};
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	showStats: function () {
		this.setState({
			showMoreStats: (this.state.showMoreStats = !this.state.showMoreStats)
		});
	},

	generateMoreStatsContent: function (o) {
		var self = this;
		var colors = ['#256C76', '#256C76cc', '#256C7699', '#256C7666', '#256C7633'];
		var returnValue = null;

		if (o.entity) {
			var total = 0;
			var subtype = o.type.indexOf(':') >= 0 ? _.last(o.type.split(':')) : null;

			o.type = o.type.indexOf(':') >= 0 ? o.type.split(':')[0] : o.type;

			switch (o.type) {
				case 'chart':
					var listOptions = [];

					if (o.data && Array.isArray(o.data)) {
						var data = _.cloneDeep(o.data.slice(0, 4));

						data.sort(function (a, b) {
							if ((a.total || a.count) < (b.total || b.count)) return 1;
							if ((a.total || a.count) < (b.total || b.count)) return -1;
							return 0;
						});

						var cc = this.props.tools.CountryCodes;
						var t = Tools.$translate;
						var countries = cc.countries();

						_.map(data, function (obj) {
							if (obj.country) {
								var c = _.filter(countries, function (country) {
									return country.code === obj.country;
								});
								// self.lang.unknown
								if (c && c.length) {
									obj.country = t(c[0].lang);
									obj.countryIcon = c[0].code.toLowerCase();
								} else {
									obj.country = self.lang.unknown;
								}
							}

							if (obj.hasOwnProperty('device')) {
								if (obj.device === 'device.null') {
									obj.device = self.lang.unknown;
								} else {
									obj.device = t(obj.device);
								}
							}

							obj.total = obj.total || obj.count;
							return (total += obj.total || obj.count);
						});

						listOptions = _.map(data, function (obj, i) {
							var icon = null;
							if (obj[o.titleProperty + 'Icon']) {
								icon = (
									<b
										style={{ marginRight: '3px' }}
										className={'flag-icon flag-icon-' + obj[o.titleProperty + 'Icon']}
									></b>
								);
							} else if (o.titleProperty === 'country') {
								icon = (
									<b style={{ marginRight: '3px' }} className="flag-icon unknown-flag">
										{'?'}
									</b>
								);
							}

							return (
								<div key={obj[o.titleProperty] + i}>
									<span className="name">
										<span className="circle" style={{ backgroundColor: colors[i] }}></span>
										<span className="opt-text">
											{Math.round((obj.total / total) * 100) + '% '}
											{icon}
											{obj[o.titleProperty] || self.lang.unknown}
										</span>
									</span>
								</div>
							);
						});
					}

					returnValue = (
						<div style={{ padding: '1em' }}>
							<h3>{o.name}</h3>
							<div style={{ float: 'left', margin: '2.5em 0 0' }}>{listOptions}</div>
							<div className="graph">
								{React.createElement(ReactTemplates.mailCampaign[o.entity + o.type], {
									data: data,
									chartType: subtype,
									colors: colors,
									tools: this.props.tools,
									name: o.name,
									noDataMsg: o.noDataMsg,
									noDataImg: o.noDataImg
								})}
							</div>
						</div>
					);
					break;

				case 'list':
					returnValue = React.createElement(ReactTemplates.mailCampaign[o.entity + o.type], {
						name: o.name,
						data: o.data,
						dataProperty: o.dataProperty,
						title: o.titleProperty,
						label: o.label,
						value: o.value,
						count: o.count,
						tools: this.props.tools,
						linkTo: o.linkTo,
						noDataMsg: o.noDataMsg,
						noDataImg: o.noDataImg,
						trailingSlash: o.trailingSlash
					});
					break;

				case 'row':
					returnValue = React.createElement(ReactTemplates.mailCampaign[o.entity + o.type], {
						name: o.name,
						data: o.data,
						dataProperty: o.dataProperty,
						title: o.titleProperty,
						getStateHref: this.props.rootData.getStateHref,
						tools: this.props.tools,
						noDataImg: o.noDataImg
					});
					break;
			}
		}

		return returnValue;
	},

	formatPercent: function (val) {
		var num = parseFloat(val);
		if (isNaN(num)) {
			return 0;
		}

		num = Math.min(num, 100);
		num = Math.max(num, 0);

		num = Math.round(num * 10) / 10;

		return num;
	},

	getStats: function () {
		var rootData = this.props.rootData;
		var stats;
		if (rootData.stats) {
			stats = {
				deliveredMail: {
					value: rootData.recipients,
					percent: (rootData.recipients / rootData.stats.total) * 100,
					green: rootData.recipients === rootData.stats.total
				},
				openedMail: {
					value: rootData.stats.open,
					percent: (rootData.stats.open / rootData.recipients) * 100,
					green: rootData.stats.open === rootData.recipients
				},
				click: {
					value: rootData.stats.click,
					percent: (rootData.stats.click / rootData.recipients) * 100,
					green: rootData.stats.click === rootData.recipients
				},
				conversions: {
					value: rootData.stats.formsubmit,
					percent: (rootData.stats.formsubmit / rootData.recipients) * 100,
					green: rootData.stats.formsubmit === rootData.recipients
				},
				mailUnsubscribers: {
					value: rootData.stats.unsub,
					percent: (rootData.stats.unsub / rootData.recipients) * 100,
					green: rootData.stats.unsub === 0
				}
			};
		} else {
			stats = {
				deliveredMail: {
					value: 0,
					percent: 0,
					green: true
				},
				openedMail: {
					value: 0,
					percent: 0,
					green: true
				},
				click: {
					value: 0,
					percent: 0,
					green: true
				},
				conversions: {
					value: 0,
					percent: 0,
					green: true
				},
				mailUnsubscribers: {
					value: 0,
					percent: 0,
					green: true
				}
			};
		}
		return stats;
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var dataStats = self.getStats();

		let clicks = null;
		if (rootData.info.clicks) {
			clicks = { ...rootData.info.clicks };
			if (rootData.info.clicks.pages) {
				clicks.pages = rootData.info.clicks.pages.slice(0, 5);
			}
		}

		var dataInfo = [
			{
				type: 'chart:column',
				name: this.lang.targetLocation,
				data: rootData.countries ? rootData.countries : null,
				entity: 'clients',
				titleProperty: 'country',
				specialWidth: 25,
				specialClass: '',
				noDataMsg: self.lang.noDataCountries,
				noDataImg: 'icon_globe.png'
			},
			{
				type: 'chart:column',
				name: this.lang.devices,
				data: rootData.devices ? rootData.devices : null,
				entity: 'clients',
				titleProperty: 'device',
				specialWidth: 25,
				specialClass: 'no-border-right',
				noDataMsg: self.lang.noDataDevices,
				noDataImg: 'icon_device.png'
			},
			{
				type: 'list:links',
				name: this.lang.mostClickedLinks,
				data: clicks,
				dataProperty: 'pages',
				titleProperty: this.lang.latestClick + ' ' + moment(rootData.info.clicks.latestClick).format('L LT'),
				entity: 'clients',
				specialWidth: 25,
				specialClass: '',
				value: 'value',
				label: 'value',
				count: 'count',
				linkTo: 'external',
				noDataMsg: self.lang.noDataClickedLinks,
				noDataImg: 'icon_link.png'
			},
			{
				type: 'list:links',
				name: this.lang.formConversions,
				data: rootData.info.forms && rootData.info.forms.length ? rootData.info.forms : null,
				dataProperty: null,
				titleProperty: this.lang.contactsThatConverted + ':',
				entity: 'clients',
				specialWidth: 25,
				specialClass: '',
				value: 'id', //{id: 131, name: adada, submits: 100}
				label: 'name',
				count: 'submits',
				linkTo: 'forms',
				noDataMsg: self.lang.noDataSubmits,
				noDataImg: 'icon_form.png',
				trailingSlash: true
			}
		];

		var numberFormat = tools.$filter('numberFormat');
		var topbarItems = _.map(dataStats, function (data, key) {
			var percentClass = 'percent ' + (data.green ? 'green' : 'black');
			return (
				<div key={key} className="topbar-item">
					<h2>
						{numberFormat(data.value)}
						<span className={percentClass}>
							{self.formatPercent(data.percent)}
							{'%'}
						</span>
					</h2>
					<span className="small">{self.lang[key]}</span>
				</div>
			);
		});

		var moreStats = null;

		var isNewMailCampaign = moment(this.props.rootData.mailCampaign.sendDate).isAfter(moment('2017-06-01'));
		if (isNewMailCampaign) {
			// om utskicket är gjort efter 1 juni 2017
			moreStats = _.map(dataInfo, function (obj, key) {
				var classNames = 'more-stats-item';

				if (obj.type === 'chart:column') classNames += ' no-padding-right';
				return (
					<div key={key} className={classNames} style={{ width: obj.specialWidth + '%' }}>
						{self.generateMoreStatsContent(obj)}
						<span className="elevio-btn">
							<ReactTemplates.elevio sidebar="true" articleId="274" />
						</span>
					</div>
				);
			});
		}

		var search = React.createElement(ReactTemplates.mailCampaign.tableSearch, {
			className: 'pull-right',
			onChange: rootData.pageData.onSearch,
			tools: tools
		});

		var propertyTypes = rootData.pageData.types;
		var buttonToggles = _.map(propertyTypes, function (type, key) {
			return (
				<div
					key={key}
					onClick={rootData.pageData.toggleType.bind(self, type.type)}
					className={type.isActive ? 'active' : ''}
				>
					{self.lang[type.name]}
				</div>
			);
		});

		var hasClickedToggle = {};
		if (this.state.hasClickedToggle) {
			hasClickedToggle.display = 'block';
		}

		return (
			<div id="dashboard">
				<div className="topbar">{topbarItems}</div>
				<span className="elevio-btn">
					<ReactTemplates.elevio sidebar="true" text={self.lang.whatIsStat} articleId="273" />
				</span>
				<div className="more-stats is-visible">{moreStats}</div>
				<div id="page-tools">
					<h2>{this.lang.Events}</h2>
					<div className="button-toggle pull-right">
						<div
							className="dd-filter up-btn btn-sm btn-bright-blue no-shadow"
							onClick={this.toggleSmallToggle}
						>
							{this.lang.openFilter}
						</div>
						<div className="button-toggle-wrapper" style={hasClickedToggle}>
							{buttonToggles}
						</div>
					</div>
					{search}
					<div className="clearfix"></div>
				</div>
				<ReactTemplates.TIMELINE.root
					loading={rootData.pageData.loading}
					total={rootData.pageData.total}
					mailevents={rootData.pageData.mails}
					maildata={rootData.pageData.mailMeta}
					events={rootData.pageData.mailEvents}
					tools={tools}
					totalPages={rootData.pageData.totalPages}
					activePage={rootData.pageData.activePage}
					onChangePage={rootData.pageData.onChangePage}
				/>
			</div>
		);
	}
});

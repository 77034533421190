'use strict';

angular
	.module('domain.appointment', [
		'ngRoute',
		'upResources',
		'security.authorization',
		'services.customer',
		'services.templateCacher'
	])
	.config([
		'$stateProvider',
		'$routeGuardProvider',
		'$httpProvider',
		'$upModalProvider',
		function ($stateProvider, $routeGuardProvider, $httpProvider, $upModalProvider) {
			$stateProvider.state('appointments', {
				templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
				url: '/:customerId/appointments/',
				controller: 'ListAppointment as listViewHelper',
				section: 'sale',
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					listViews: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise.then(function () {
								return AppService.getListViews('appointment');
							});
						}
					]
				}
			});

			$upModalProvider
				.modal('editAppointment', {
					template: '<div></div>',
					style: 'form default dynamic',
					controller: function ($scope, $modalParams, utils) {
						return new window.AppointmentCtrl($scope, $modalParams, utils);
					},
					resolve: {
						meta: function ($modalParams, EditAppointmentMeta) {
							return EditAppointmentMeta($modalParams);
						}
					}
				})
				.modal('updateAppointmentMulti', {
					templateUrl: require('App/upsales/domain/appointment/views/updateAppointmentMulti.html?file'),
					style: 'form-md default',
					controller: 'UpdateAppointmentMulti',
					resolve: {
						meta: function ($modalParams, UpdateAppointmentMultiMeta) {
							return UpdateAppointmentMultiMeta($modalParams);
						}
					}
				});
		}
	]);

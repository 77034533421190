'use strict';

angular.module('domain.scoreboard').controller('ResetScore', [
	'$scope',
	'$modalParams',
	'Event',
	'ResetScore',
	'Account',
	'Contact',
	'MarketRejectlist',
	'AppService',
	function ($scope, $modalParams, Event, ResetScore, Account, Contact, MarketRejectlist, AppService) {
		var account = $modalParams.account;
		var contact = $modalParams.contact;
		var customerId = $modalParams.customerId;
		$scope.account = account;
		$scope.contact = contact;

		$scope.rejectlistToggle = function () {
			$scope.rejectlist = !$scope.rejectlist;
		};

		$scope.resetScore = function () {
			$scope.resetting = true;
			if (account && account.id) {
				Account.customer(customerId)
					.get(account.id)
					.then(function (res) {
						account = res.data;
						ResetScore.customer(customerId)
							.reset({
								clientId: account.id,
								userId: $modalParams.userId,
								synch: $modalParams.synch || false
							})
							.then(function (res) {
								Account.customer(customerId)
									.unassign(account.id)
									.then(function () {
										account.score = 0;
										account.assigned = null;
										if (!$scope.rejectlist) {
											return $scope.resolve();
										}
										MarketRejectlist.customer(customerId)
											.save({ clientId: account.id, name: account.name, dunsNo: account.dunsNo })
											.then(function () {
												$scope.resolve();
											})
											.catch(function () {
												$scope.resetting = false;
											});
									})
									.catch(function (err) {
										$scope.resetting = false;
									});
							})
							.catch(function (err) {
								$scope.resetting = false;
							});
					})
					.catch(function () {
						$scope.resetting = false;
					});
			}

			if (contact && contact.id) {
				Contact.customer(customerId)
					.get(contact.id)
					.then(function (res) {
						contact = res.data;
						ResetScore.customer(customerId)
							.reset({
								contactId: contact.id,
								userId: $modalParams.userId,
								synch: $modalParams.synch || false
							})
							.then(function (res) {
								return $scope.resolve();
							})
							.catch(function (err) {
								$scope.resetting = false;
							});
					})
					.catch(function () {
						$scope.resetting = false;
					});
			}
		};

		AppService.loadedPromise.then(function () {
			$scope.isAdmin = AppService.getSelf().administrator;
		});
	}
]);

'use strict';
import openModal from 'App/services/Modal';
import logError from 'App/babel/helpers/logError';
import fileSizeFilter from 'App/babel/helpers/fileSize';

var FILE_SIZE_LIMIT = 150000;

var calculateFileSize = function (creative) {
	var size = 5000; // allocate 5Kb for html,css and text
	for (var ip = 0; ip < creative.pages.length; ip++) {
		var page = creative.pages[ip];
		if (page.background.img && page.background.fileSize) {
			size += page.background.fileSize;
		}
		for (var i = 0; i < page.elements.length; i++) {
			var element = page.elements[i];
			if (element.fileSize) {
				size += element.fileSize;
			}
		}
	}

	return size;
};

ReactTemplates.bannerEditor.root = window.ReactCreateClass({
	frameRenderTimeout: null,
	initialHash: null,
	currentHash: null,
	submitted: false,
	getInitialState: function () {
		this.initialHash = Tools.LZString.compressToBase64(JSON.stringify(this.props.rootData.creative));
		return {
			creative: this.props.rootData.creative,
			view: 'editor',
			resolveKey: Tools.$stateParams.resolveKey,
			saving: false,
			saveAsDraft: false,
			submitted: false,
			errors: {},
			saveId: 0,
			preview: 'normal',
			progress: 0,
			progressTask: null,
			colorVariables: [],
			fileSize: 0
		};
	},
	setPreview: function (preview) {
		if (this.state.preview === preview) {
			return;
		}
		this.setState({ preview: preview });
	},
	elementChanged: function (pageIndex, elemIndex, element) {
		var creative = this.state.creative;
		creative.pages[pageIndex].elements[elemIndex] = element;
		this.setState({ creative: creative });
		this.onChange();
	},
	renderFrames: function () {
		var self = this;
		if (self.frameRenderTimeout) {
			clearTimeout(self.frameRenderTimeout);
		}
		self.frameRenderTimeout = setTimeout(function () {
			window.BannerParser.parse(
				self.state.creative,
				self.props.rootData.markdown,
				self.props.rootData.accountProfile,
				function (err, html) {
					if (err) {
						return;
					}

					html = 'data:text/html;charset=utf-8,' + html;
					self.state.creative.availableFormats.forEach(function (format) {
						if (self.state.preview === 'normal' || self.state.preview === 'news') {
							if (self['_frame-' + format] && self['_frame-' + format].querySelector('iframe')) {
								var contentWindow = self['_frame-' + format].querySelector('iframe').contentWindow;
								if (contentWindow) {
									contentWindow.document.open();
									contentWindow.document.write(html);
									contentWindow.document.close();
								}
							}
							if (
								self['_frame-' + format + '-2'] &&
								self['_frame-' + format + '-2'].querySelector('iframe')
							) {
								var contentWindow2 = self['_frame-' + format + '-2'].querySelector('iframe')
									.contentWindow;
								if (contentWindow2) {
									contentWindow2.document.open();
									contentWindow2.document.write(html);
									contentWindow2.document.close();
								}
							}
						}
					});
				}
			);
		}, 100);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			title: t('default.title'),
			landingPage: t('bannerEditor.landingPage'),
			exclude: t('bannerEditor.excludeFormat'),
			include: t('bannerEditor.includeFormat'),
			abort: t('default.abort'),
			generateBanners: t('bannerEditor.generateBanners'),
			saveAsDraft: t('mail.saveAsDraft'),
			createAds: t('ads.createAds'),
			bannersDone: t('bannerEditor.bannersDone'),
			bannersDoneUseInCampaign: t('bannerEditor.bannersDoneUseInCampaign'),
			bannersDoneBody: t('ads.createOrAddAdsInCampaignBody'),
			createNewAdCampaign: t('ads.createNewAdCampaign'),
			goToBannerList: t('bannerEditor.goToBannerList'),
			goBackToEditingCampaign: t('bannerEditor.goBackToEditingCampaign'),
			excluded: t('bannerEditor.excluded'),
			saving: t('default.saving'),
			uploadingBanners: t('bannerEditor.uploadingBanners'),
			saveThumb: t('bannerEditor.saveThumb'),
			generatingBanners: t('bannerEditor.generatingBanners'),
			creatingFiles: t('bannerEditor.creatingFiles'),
			downloadingImages: t('bannerEditor.downloadingImages'),
			compressingFiles: t('bannerEditor.compressingFiles'),
			generatingPreviews: t('bannerEditor.generatingPreviews'),
			totalSize: t('bannerEditor.totalSize')
		};

		this.setupColorVariables();
	},
	setupColorVariables: function () {
		var t = Tools.$translate;
		// Setup profileColors
		var colorVariables = [
			{ title: t('admin.colors'), colors: {} },
			{ title: t('admin.extraColors'), colors: {} }
		];
		angular.forEach(this.props.rootData.accountProfile.colors, function (value) {
			colorVariables[0].colors[value] = value;
		});
		angular.forEach(this.props.rootData.accountProfile.extraColors, function (color) {
			colorVariables[1].colors[color.value] = color.value;
		});

		this.setState({
			colorVariables: colorVariables
		});
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (
			nextProps.rootData.accountProfile.extraColors.length !==
			this.props.rootData.accountProfile.extraColors.length
		) {
			this.setupColorVariables();
		}
	},
	onBannerProgress: function (e, data) {
		var self = this;
		self.setState({ progress: data.progress, progressTask: data.step });
		if (data.progress >= 100) {
			self.setState({ view: 'saved', saving: false, saveAsDraft: false });
		} else if (data.progress === -1) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'saveError.bannerGroup'
			});
			self.setState({ saving: false, saveAsDraft: true, view: 'editor' });
		}
	},
	componentWillUnmount: function () {
		this.unsubUserEvent();
	},
	componentDidMount: function () {
		this.renderFrames();

		this.unsubUserEvent = Tools.$rootScope.$on('userPrivate.generateBanners', this.onBannerProgress);
		var fileSize = calculateFileSize(this.state.creative);
		this.setState({ fileSize: fileSize });
	},
	componentDidUpdate: function () {
		this.renderFrames();

		// Scroll first error-input into view and set focus to it
		if (this.submitted) {
			var inputs = this._root.querySelectorAll('.has-error');
			if (inputs.length) {
				inputs[0].scrollIntoView();
				inputs[0].querySelector('input').focus();
			}
		}

		this.submitted = false;
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	onChange: function (saved) {
		var fileSize = calculateFileSize(this.state.creative);
		this.setState({ fileSize: fileSize });
		this.currentHash = Tools.LZString.compressToBase64(JSON.stringify(this.state.creative));
		this.props.rootData.onChange(this.currentHash, saved);
	},
	ensureHttpPrefix: function (value) {
		var notValid =
			value !== undefined &&
			value !== null &&
			!(
				'http://'.indexOf(value.substring(0, 7)) === 0 ||
				'https://'.indexOf(value.substring(0, 8)) === 0 ||
				'ftp://'.indexOf(value.substring(0, 6)) === 0
			);

		if (notValid) {
			return 'http://' + value;
		} else {
			return value;
		}
	},
	rootStringChange: function (key, e) {
		var creative = this.state.creative;
		var errors = this.validate();

		if (key === 'landingPage') {
			creative[key] = this.ensureHttpPrefix(e.target.value);
		} else {
			creative[key] = e.target.value;
		}

		this.setState({ creative: creative, errors: errors });
		this.onChange();
	},
	backgroundChange: function (pageIndex, key, value) {
		var creative = this.state.creative;
		creative.pages[pageIndex][key] = value;
		this.setState({ creative: creative });
		this.onChange();
	},
	excludeFormat: function (format) {
		var creative = this.state.creative;
		var available = _.clone(creative.availableFormats);
		var i = creative.formats.indexOf(format);
		creative.formats.splice(i, 1);
		creative.availableFormats = available;
		this.setState({ creative: creative });
	},
	includeFormat: function (format) {
		var creative = this.state.creative;
		creative.formats.push(format);
		this.setState({ creative: creative });
	},
	getFrame: function (format, i, enabled) {
		var self = this;
		var size = format.toLowerCase().split('x');
		var classes = 'frame-wrap';
		var key = 'frame-' + i;
		if (!enabled) {
			classes += ' inactive';
			key += '-inactive';
		}
		var style = {
			width: parseInt(size[0]),
			height: parseInt(size[1])
		};
		var frameStyle = {
			border: 0
		};

		return (
			<div key={key} ref={self.setRef.bind(self, '_frame-' + format)} className={classes}>
				<span className="format-string">{format}</span>
				<div style={style} className="inner-wrap">
					<iframe
						style={frameStyle}
						src=""
						border="0"
						scrolling="no"
						allowTransparency="true"
						width="100%"
						height="100%"
					></iframe>
					{self.state.creative.formats.length !== 1 || !enabled ? (
						<div className="overlay">
							<span className="overlay-info">{self.lang.excluded}</span>
							<button
								className="exclude-btn up-btn btn btn-sm btn-lined btn-bright-blue"
								onClick={
									enabled
										? self.excludeFormat.bind(self, format)
										: self.includeFormat.bind(self, format)
								}
							>
								{enabled ? self.lang.exclude : self.lang.include}
							</button>
						</div>
					) : null}
				</div>
			</div>
		);
	},
	validate: function (cb) {
		var hasErrors = false;
		var errors = {
			title: false,
			landingPage: false,
			fileSize: false
		};

		if (!this.state.creative.title || !_.trim(this.state.creative.title.length)) {
			errors.title = true;
			hasErrors = true;
		}
		if (!this.state.creative.landingPage || !_.trim(this.state.creative.landingPage.length)) {
			errors.landingPage = true;
			hasErrors = true;
		}
		if (this.state.creative.landingPage && this.state.creative.landingPage.indexOf('.') === -1) {
			errors.landingPage = true;
			hasErrors = true;
		}
		if (this.state.fileSize > FILE_SIZE_LIMIT) {
			errors.fileSize = true;
			hasErrors = true;
		}

		if (cb) {
			cb(hasErrors, errors);
		} else {
			return errors;
		}
	},
	save: function (isDraft) {
		var self = this;
		self.submitted = true;

		self.validate(function (hasErrors, errors) {
			if (hasErrors) {
				self.setState({ submitted: true, errors: errors });

				// Show notification
				if (errors.fileSize) {
					Tools.NotificationService.addNotification({
						title: 'default.error',
						body: Tools.$translate('bannerEditor.fileTooLargeMaxSizeIs', {
							size: Tools.$filter('fileSize')(FILE_SIZE_LIMIT, true)
						}),
						style: Tools.NotificationService.style.ERROR,
						icon: 'times'
					});
				} else {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.WARN,
						icon: 'warning',
						title: 'default.error',
						body: 'default.youHaveFormErrors'
					});
				}

				return;
			}

			if (isDraft && self.state.resolveKey) {
				const onConfirm = () => {
					self.setState({ saving: true, saveAsDraft: isDraft });
					self.props.rootData
						.onSave(self.state.creative, true)
						.then(function () {
							self.onChange(true);
							var adCampaign = self.getCampaignFromStorage();
							self.returnToCampaign(adCampaign);
						})
						.finally(function () {
							self.setState({ saving: false, saveAsDraft: false });
						})
						.catch(function (err) {
							logError(err, 'Failed to save');
						});
				};

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'mail.saveAsDraft',
						body: 'bannerEditor.saveDraftWarning',
						confirmButtonText: 'mail.saveAsDraft',
						onClose: confirmed => {
							if (confirmed) {
								onConfirm();
							}
						}
					});
					return;
				}

				Tools.$upModal
					.open('warningConfirm', {
						title: 'mail.saveAsDraft',
						body: 'bannerEditor.saveDraftWarning',
						no: 'default.abort',
						resolveTrue: 'mail.saveAsDraft'
					})
					.then(function () {
						onConfirm();
					})
					.catch(function (err) {
						logError(err, 'Failed to open warningConfirm modal');
					});
			} else {
				self.setState({ saving: true, saveAsDraft: isDraft });
				self.props.rootData
					.onSave(self.state.creative, isDraft)
					.then(function (res) {
						self.onChange(true);
						if (isDraft) {
							Tools.$state.go('listAds', { tab: 'banners' });
						} else {
							self.setState({ saveId: res.data.id });
						}
					})
					.catch(function (err) {
						logError(err, 'Failed to save');
					});
			}
		});
	},
	returnToCampaign: function (adCampaign) {
		var self = this;
		if (self.state.saveId) {
			Tools.Ads.customer(Tools.AppService.getCustomerId())
				.findCreative({ creative_group_id: self.state.saveId })
				.then(function (res) {
					if (!adCampaign.creative) {
						adCampaign.creative = [];
					}
					_.forEach(res.data, function (c) {
						adCampaign.creative.push({
							creativeId: c.id,
							active: true
						});
					});
					Tools.localStorageService.add('activeAdCampaign', adCampaign);
					Tools.$state.go('editAd', { fromStorage: true, id: null });
				})
				.catch(function (err) {
					logError(err, 'Could not find editor');
				});
		} else {
			Tools.localStorageService.add('activeAdCampaign', adCampaign);
			Tools.$state.go('editAd', { fromStorage: true, id: null });
		}
	},
	getCampaignFromStorage: function () {
		return Tools.localStorageService.get('adCampaign-snapshot-' + Tools.$stateParams.resolveKey);
	},
	createAdCampaign: function () {
		var adCampaign = Tools.Ads.newCampaign();
		this.returnToCampaign(adCampaign);
	},
	goBackToCampaign: function () {
		var adCampaign = this.getCampaignFromStorage();
		// modify here
		this.returnToCampaign(adCampaign);
	},
	goToBannerList: function () {
		var self = this;

		if (self.state.resolveKey) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.abortEdit',
					body: 'bannerEditor.goToBannerListWarning',
					confirmButtonText: 'bannerEditor.goToBannerList',
					onClose: confirmed => {
						if (confirmed) {
							Tools.$state.go('listAds', { tab: 'banners' });
						}
					}
				});
				return;
			}

			Tools.$upModal
				.open('warningConfirm', {
					title: 'default.abortEdit',
					body: 'bannerEditor.goToBannerListWarning',
					no: 'default.abort',
					resolveTrue: 'bannerEditor.goToBannerList'
				})
				.then(function () {
					Tools.$state.go('listAds', { tab: 'banners' });
				})
				.catch(function (err) {
					logError(err, 'Failed to open warningConfirm modal');
				});
		} else {
			Tools.$state.go('listAds', { tab: 'banners' });
		}
	},
	abort: function () {
		if (this.state.resolveKey) {
			var adCampaign = this.getCampaignFromStorage();
			this.returnToCampaign(adCampaign);
		} else if (Tools.$stateParams.id) {
			Tools.$state.go('listAds', { tab: 'banners' });
		} else {
			Tools.$state.go('selectCreativeTemplate', {});
		}
	},
	addPreset: function (color) {
		var self = this;
		return self.props.rootData.addPreset(color).then(function () {
			self.setupColorVariables();
		});
	},
	render: function () {
		var self = this;
		var creative = this.state.creative;
		var titleClass = 'floating-label-input';
		var landingPageClass = 'floating-label-input';
		var mainView;
		var sidebar = null;
		var footer = null;
		var classes = 'card full-size';

		if (self.state.view === 'saved') {
			mainView = (
				<div className="card-content">
					<div className="placeholder">
						<div className="inner-wrap">
							<img src="img/banners-done.svg" />
							<h2>
								{self.state.resolveKey ? self.lang.bannersDone : self.lang.bannersDoneUseInCampaign}
							</h2>
							{self.state.resolveKey ? (
								<div className="btn-wrap btn-wrap-2">
									<div className="btn-inner-wrap">
										<button
											type="button"
											className="up-btn btn-bright-blue"
											onClick={self.goBackToCampaign}
										>
											{self.lang.goBackToEditingCampaign}
										</button>
									</div>
									<div className="btn-inner-wrap">
										<button
											type="button"
											className="up-btn btn-white-blue"
											onClick={self.goToBannerList}
										>
											{self.lang.goToBannerList}
										</button>
									</div>
								</div>
							) : (
								<div>
									<p>{self.lang.bannersDoneBody}</p>
									<div className="btn-wrap btn-wrap-2">
										<div className="btn-inner-wrap">
											<button
												type="button"
												className="up-btn btn-bright-blue"
												onClick={self.createAdCampaign}
											>
												{self.lang.createNewAdCampaign}
											</button>
										</div>
										<div className="btn-inner-wrap">
											<button
												type="button"
												className="up-btn btn-white-blue"
												onClick={self.goToBannerList}
											>
												{self.lang.goToBannerList}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		} else {
			classes += ' has-footer has-sidebar';
			if (creative.title && creative.title.length) {
				titleClass += ' has-value';
			}
			if (self.state.errors.title) {
				titleClass += ' has-error';
			}
			if (creative.landingPage && creative.landingPage.length) {
				landingPageClass += ' has-value';
			}
			if (self.state.errors.landingPage) {
				landingPageClass += ' has-error';
			}

			var components = creative.pages[0].elements.map(function (element, i) {
				return React.createElement(ReactTemplates.bannerEditor.editorElement, {
					element: element,
					key: 'elem-' + i,
					elementChanged: self.elementChanged.bind(self, 0, i),
					colorVariables: self.state.colorVariables,
					addPreset: self.props.rootData.addPreset ? self.addPreset : null
				});
			});

			var background = React.createElement(ReactTemplates.bannerEditor.editorBackgroundElement, {
				background: creative.pages[0].background,
				backgroundChange: self.backgroundChange.bind(self, 0),
				colorVariables: self.colorVariables
			});

			var previewBtnClasses = 'btn up-btn no-shadow';
			var normalPBtnClass = previewBtnClasses;
			var newsPBtnClass = previewBtnClasses;
			var preview;
			var fileSize = fileSizeFilter(this.state.fileSize, false, true);

			if (self.state.preview === 'normal') {
				normalPBtnClass += ' btn-white-blue';
				newsPBtnClass += ' btn-light-grey';

				var iFrames = [];
				creative.formats.forEach(function (format, i) {
					var frame = self.getFrame(format, i, true);

					iFrames.push(frame);
				});
				creative.availableFormats.forEach(function (format, i) {
					if (creative.formats.indexOf(format) === -1) {
						var frame = self.getFrame(format, i, false);

						iFrames.push(frame);
					}
				});

				preview = iFrames;
			} else {
				normalPBtnClass += ' btn-light-grey';
				newsPBtnClass += ' btn-white-blue';

				preview = React.createElement(ReactTemplates.bannerEditor.fakeNewsSite, {
					formats: this.state.creative.formats,
					setRef: self.setRef
				});
			}

			mainView = (
				<div className="card-content">
					<div className="btn-group" id="preview-btns">
						<button
							type="button"
							className={normalPBtnClass}
							onClick={self.setPreview.bind(self, 'normal')}
						>
							<b className="fa fa-th-list"></b> {'Lista'}
						</button>
						<button type="button" className={newsPBtnClass} onClick={self.setPreview.bind(self, 'news')}>
							<b className="fa fa-desktop"></b> {'Webbsida'}
						</button>
					</div>

					{preview}
				</div>
			);

			sidebar = (
				<div id="card-sidebar">
					<div id="hero-section" className="sidebar-section">
						<div className={titleClass}>
							<label>{self.lang.title}</label>
							<input
								type="text"
								value={creative.title}
								onChange={self.rootStringChange.bind(self, 'title')}
							/>
						</div>
						<div className={landingPageClass}>
							<label>{self.lang.landingPage}</label>
							<input
								type="text"
								value={creative.landingPage}
								onChange={self.rootStringChange.bind(self, 'landingPage')}
							/>
						</div>
					</div>
					{components}
					{background}
				</div>
			);

			footer = (
				<div id="card-footer">
					<div className="size-counter">
						{this.lang.totalSize}
						{': '}
						{fileSize}
						{' / 150 Kb'}
					</div>
					<div className="pull-right" id="buttons">
						<button
							className="btn up-btn btn-white-blue btn-sm no-shadow"
							disabled={this.state.saving}
							onClick={self.save.bind(self, true)}
						>
							{this.state.saving && self.state.saveAsDraft
								? self.lang.saving + '...'
								: self.lang.saveAsDraft}
						</button>
						<button
							className="btn up-btn btn-bright-blue btn-sm no-shadow"
							disabled={this.state.saving}
							onClick={self.save.bind(self, false)}
						>
							{this.state.saving && !self.state.saveAsDraft
								? self.lang.saving + '...'
								: self.lang.generateBanners}
						</button>
						<button
							className="btn up-btn btn-grey btn-link"
							disabled={this.state.saving}
							onClick={self.abort}
						>
							{self.lang.abort}
						</button>
					</div>
				</div>
			);

			var savingCurtain = null;
			if (self.state.saving) {
				var text = self.lang.generatingBanners;
				switch (self.state.progressTask) {
					case 'CREATING_FILES':
						text = self.lang.creatingFiles;
						break;
					case 'DOWNLOADING_IMG':
						text = self.lang.downloadingImages;
						break;
					case 'GENERATE_ZIP':
						text = self.lang.compressingFiles;
						break;
					case 'UPLOADING':
						text = self.lang.uploadingBanners;
						break;
					case 'SAVE_THUMB':
						text = self.lang.saveThumb;
						break;
					case 'GENERATE_SCREENSHOT':
						text = self.lang.generatingPreviews;
						break;
					default:
						text = self.lang.generatingBanners;
						break;
				}

				savingCurtain = (
					<div id="saving-curtain">
						<div id="progress-wrap">
							<div className="progress">
								<div
									className="progress-bar bright-blue"
									style={{ width: self.state.progress + '%' }}
								></div>
							</div>
							<b className="fa fa-refresh fa-spin"></b> {text}
						</div>
					</div>
				);
			}
		}

		return (
			<div id="creative-editor" className={classes} ref={self.setRef.bind(self, '_root')}>
				{savingCurtain}

				<div className="up-header up-header-sm border-top fixed">
					<div id="title">
						<h2>{self.lang.createAds}</h2>
					</div>
				</div>

				{sidebar}

				<div id="card-main-view">{mainView}</div>

				{footer}
			</div>
		);
	}
});

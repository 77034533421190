const createCampaign = customerId => {
	const OPTIONS = {
		customerId,
		noRedirect: true
	};

	return new Promise(resolve => {
		Tools.$upModal.open('editCampaign', OPTIONS).then(campaign => {
			return resolve(campaign);
		});
	});
};

export default createCampaign;

import React, { useState, useEffect } from 'react';
import {
	Headline,
	Text,
	ButtonSelect,
	Button,
	TableHeader,
	Table,
	Card,
	Title,
	Icon,
	DropDownMenu,
	Tooltip
} from '@upsales/components';
import translate from 'Components/Helpers/translate';
import FileBrowserFile from './FileBrowserFile';
import FileBrowserTableRow from './FileBrowserTableRow';
import './FileBrowserFiles.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import FileBrowserUploadedFiles from './FileBrowserUploadedFiles';
import Label from 'App/resources/Model/Label';
import File from 'App/resources/Model/File';

interface FileBrowserFilesProps {
	uploadedFiles: File[];
	files: File[];
	imageOnly: boolean;
	onDelete: (file: File) => void;
	onSelect: (file: File) => void;
	onSortChange: (sort: { field: string; asc: boolean }) => void;
	currentSort: string | null;
	currentDirection: boolean;
	loading: boolean;
	labels: Label[];
	onAddFileLabel: (files: File[], label: Label | []) => void;
	selectedLabel: Label;
}

const FileBrowserFiles = ({
	uploadedFiles,
	files,
	imageOnly,
	onDelete,
	onSelect,
	onSortChange,
	currentSort,
	currentDirection,
	loading,
	labels,
	onAddFileLabel,
	selectedLabel
}: FileBrowserFilesProps) => {
	const [view, setView] = useState('thumbnail');
	const title = imageOnly ? translate('default.images') : translate('file.files');
	const [checkedFiles, setCheckedFiles] = useState<File[]>([]);
	const [checkedUploadedFiles, setCheckedUploadedFiles] = useState<File[]>([]);
	const [checkedFilesHasLabel, setCheckedFilesHasLabel] = useState(false);
	const hasFileLabel = Tools.FeatureHelper.hasSoftDeployAccess('FILE_METADATA_LABEL');
	const classes = new bemClass('FileBrowserFiles');

	useEffect(() => {
		const newCheckedFiles: File[] = [];
		checkedFiles.forEach((file: File) => {
			let newFile = files.find(f => f.id === file.id);

			if (newFile) newCheckedFiles.push(newFile);

			newFile = uploadedFiles.find(f => f.id === file.id);

			if (newFile) newCheckedFiles.push(newFile);
		});
		const hasLabel = newCheckedFiles.some(file => {
			if (!file.labels) {
				return false;
			} else if (file.labels.length > 0) {
				return true;
			}
			return false;
		});

		setCheckedFilesHasLabel(hasLabel);
		setCheckedFiles(newCheckedFiles);
	}, [files]);

	const updateCheckedFiles = (checked: boolean, file: File) => {
		let newCheckedFiles;
		if (checked) {
			newCheckedFiles = [...checkedFiles, file];
		} else {
			newCheckedFiles = checkedFiles.filter(f => f.id !== file.id);
		}
		const hasLabel = newCheckedFiles.some(file => {
			if (!file.labels) {
				return false;
			} else if (file.labels.length > 0) {
				return true;
			}
			return false;
		});

		setCheckedFilesHasLabel(hasLabel);
		setCheckedFiles(newCheckedFiles);
	};

	const addLabelToFiles = (label: Label) => {
		onAddFileLabel(checkedFiles.concat(checkedUploadedFiles), label);
		setCheckedFiles([]);
		setCheckedUploadedFiles([]);
	};

	const removeLabelFromFiles = () => {
		onAddFileLabel(checkedFiles, []);
		setCheckedFiles([]);
	};

	const deleteFile = (file: File) => {
		updateCheckedFiles(false, file);
		setCheckedUploadedFiles(checkedUploadedFiles.filter(f => f.id !== file.id));
		onDelete(file);
	};

	let content;

	const columns = [
		{ title: translate('file.fileName'), sort: 'filename' },
		{ title: translate('file.dimensions') },
		{ title: translate('file.fileType'), sort: 'mimetype' },
		{ title: translate('file.uploadedDate'), sort: 'uploadDate', sortType: 'numeric' },
		{ title: '' } // actions
	];

	if (hasFileLabel) {
		columns.unshift({ title: '' });
		columns.splice(4, 0, { title: translate('file.label') });
	}

	if (!loading && !files.length) {
		content = (
			<Title color="grey-10" className={classes.elem('no-files').b()}>
				{translate('file.noFiles')}
			</Title>
		);
	} else if (view === 'thumbnail') {
		content = (
			<div className={classes.elem('thumbnails').b()}>
				{files.map(file => (
					<FileBrowserFile
						checkFile={updateCheckedFiles}
						isChecked={checkedFiles.some(f => f.id === file.id)}
						key={file.id}
						file={file}
						deleteFile={deleteFile}
						onSelect={onSelect}
					/>
				))}
			</div>
		);
	} else {
		content = (
			<Card className={classes.elem('table').b()}>
				<Table>
					<TableHeader
						sorting={currentSort}
						asc={currentDirection}
						columns={columns}
						onSortChange={onSortChange}
					/>
					{files.map(f => (
						<FileBrowserTableRow
							key={f.id}
							file={f}
							deleteFile={deleteFile}
							checkFile={updateCheckedFiles}
							isChecked={checkedFiles.some(file => file.id === f.id)}
						/>
					))}
				</Table>
			</Card>
		);
	}

	return (
		<div>
			<FileBrowserUploadedFiles
				files={uploadedFiles}
				onSelect={onSelect}
				deleteFile={deleteFile}
				checkedUploadedFiles={checkedUploadedFiles}
				setCheckedUploadedFiles={setCheckedUploadedFiles}
			/>
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>
					<div>
						<Headline size="xs">{title}</Headline>
						<Text color="grey-10">{`${selectedLabel.numEntities} ${title.toLowerCase()}`}</Text>
					</div>
					{hasFileLabel ? (
						checkedFiles.length || checkedUploadedFiles.length ? (
							<div className={classes.elem('checkedButtons').b()}>
								<DropDownMenu
									renderTrigger={(expanded, setExpanded) => (
										<Tooltip title={translate('file.label.info')} disabled={!!labels.length}>
											<Button
												disabled={!labels.length}
												color="super-light-green"
												shadow="none"
												size="md"
												onClick={setExpanded}
											>
												{expanded ? (
													<Icon name="caret-up"></Icon>
												) : (
													<Icon name="caret-down"></Icon>
												)}
												{translate('file.addLabel')}
											</Button>
										</Tooltip>
									)}
								>
									{close => (
										<Card className={classes.elem('dropdown-wrapper').b()}>
											{labels.map(label => {
												return (
													<Text
														key={label.id}
														size="md"
														onClick={() => {
															addLabelToFiles(label);
															close();
														}}
													>
														{label.name}
													</Text>
												);
											})}
										</Card>
									)}
								</DropDownMenu>

								{checkedFilesHasLabel ? (
									<Button
										color="super-light-green"
										shadow="none"
										size="md"
										onClick={() => removeLabelFromFiles()}
									>
										<Icon name="trash"></Icon>
										{translate('file.label.remove')}
									</Button>
								) : null}
							</div>
						) : null
					) : null}

					<ButtonSelect
						options={[
							{
								icon: 'th-large',
								value: 'thumbnail'
							},
							{
								icon: 'bars',
								value: 'table'
							}
						]}
						onChange={e => setView(e)}
						value={view}
					></ButtonSelect>
				</div>

				{content}
			</div>
		</div>
	);
};

export default FileBrowserFiles;

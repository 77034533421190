'use strict';

angular.module('upResources').factory('Opportunity', [
	'$resource',
	'$q',
	'URL',
	'API',
	'OrderAttributes',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	function Opportunity($resource, $q, URL, API, OrderAttributes, ResourceHelper, ParseGeneric, MapGeneric) {
		var Attribute = OrderAttributes().getAll();

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'opportunity',
			createSuccessBody: 'saved.opportunity',
			updateSuccessBody: 'updated.opportunity',
			deleteSucccessBody: 'deleted.opportunity',
			createErrorBody: 'saveError.opportunity',
			updateErrorBody: 'saveError.opportunity',
			deleteErrorBody: 'deleteError.opportunity'
		});

		var dateFields = [
			'date',
			'closeDate',
			'modDate',
			'regDate',
			'periodization.startDate',
			'periodization.endDate'
		];

		var baseUrl = URL + API + 'opportunities/';
		var methods = {
			get: {
				method: 'GET',
				transformResponse: ParseGeneric('ParseOrder', { isArray: false, custom: 'opportunity', dateFields })
			},
			query: {
				method: 'GET',
				isArray: false,
				transformResponse: ParseGeneric('ParseOrder', { isArray: true, custom: 'opportunity', dateFields })
			},
			put: {
				method: 'PUT',
				transformResponse: ParseGeneric('ParseOrder', { isArray: false, custom: 'opportunity', dateFields }),
				transformRequest: MapGeneric('MapOrder')
			},
			post: {
				method: 'POST',
				transformResponse: ParseGeneric('ParseOrder', { isArray: false, custom: 'opportunity', dateFields }),
				transformRequest: MapGeneric('MapOrder')
			},
			multi: { method: 'POST', isArray: false, url: baseUrl + 'multi/:type' }
		};
		var Resource = $resource(baseUrl + ':id', {}, methods);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options || {});
				delete params.mapCustom;

				return Resource.query(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (opportunity, options) {
				var promise = Resource[opportunity && opportunity.id ? 'update' : 'save'](
					{ id: opportunity.id },
					{ opportunity: opportunity }
				).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !opportunity.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !opportunity.id);
					});

				return promise;
			},

			delete: function (opportunity, options) {
				return Resource.delete({ id: opportunity.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, opportunity);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},
			attr: Attribute,
			requiredFields: OrderAttributes().requiredFields
		};

		return Model;
	}
]);

'use strict';

angular.module('domain.admin').factory('MailAdminMeta', [
	'$q',
	'MailAccount',
	'AppService',
	function ($q, MailAccount, AppService) {
		return function () {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();

				var response = {
					metadata: AppService.getMetadata()
				};

				if (response.metadata.map.mail) {
					return MailAccount.customer(customerId)
						.get()
						.then(function (account) {
							response.mailAccount = account.data;
							return response;
						});
				}
				return response;
			});
		};
	}
]);

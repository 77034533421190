import logError from 'App/babel/helpers/logError';
import Prospecting from 'App/babel/resources/Prospecting';
import { getAvailableCountries as getAvailableProspectingCountries } from 'App/services/prospectingService';

angular.module('domain.soliditet').controller('SoliditetMatcherCtrl', [
	'AppService',
	'$q',
	'$stateParams',
	'RequestBuilder',
	'SoliditetClient',
	'Account',
	'Campaign',
	'FeatureHelper',
	'PushNotifications',
	function (
		AppService,
		$q,
		$stateParams,
		RequestBuilder,
		SoliditetClient,
		Account,
		Campaign,
		FeatureHelper,
		PushNotifications
	) {
		const SoliditetMatcherCtrl = this;
		const customerId = AppService.getCustomerId();
		const self = AppService.getSelf();
		const hasSoliditet = self.userParams.soliditetIsActive;
		const hasProspecting = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);
		const hiddenFileterValue = '-1';

		SoliditetMatcherCtrl.rootData = {
			limit: 50
		};

		if ($stateParams.campaignId) {
			SoliditetMatcherCtrl.rootData.campaign = {
				id: $stateParams.campaignId
			};
		}

		function getAvailableCountries(includeSoliditet = true, includeProspecting = true) {
			const countries = [];

			if (includeSoliditet && hasSoliditet) {
				countries.push('dk', 'fi', 'no', 'se');
			}
			if (includeProspecting) {
				const prospectingCountries = getAvailableProspectingCountries().map(country => country.toLowerCase());
				countries.push(...prospectingCountries);
			}

			return [...new Set(countries)];
		}

		function addCountryFilter(requestBuilder, countries) {
			const orFilter = requestBuilder.orBuilder();

			orFilter.next();
			// Either the country is one of the ones we ask for
			const countryGroupBuilder = orFilter.groupBuilder();
			countryGroupBuilder.addFilter({ field: 'address.type' }, orFilter.comparisonTypes.Equals, 'Visit');
			countryGroupBuilder.addFilter({ field: 'address.country' }, orFilter.comparisonTypes.Equals, countries);
			countryGroupBuilder.done();

			orFilter.next();
			// Or the country is null
			const nullGroupBuilder = orFilter.groupBuilder();
			nullGroupBuilder.addFilter({ field: 'address.type' }, orFilter.comparisonTypes.Equals, 'Visit');
			nullGroupBuilder.addFilter({ field: 'address.country' }, orFilter.comparisonTypes.Equals, null);
			nullGroupBuilder.done();

			orFilter.next();
			// Or it is an empty string
			const emptyGroupBuilder = orFilter.groupBuilder();
			emptyGroupBuilder.addFilter({ field: 'address.type' }, orFilter.comparisonTypes.Equals, 'Visit');
			emptyGroupBuilder.addFilter({ field: 'address.country' }, orFilter.comparisonTypes.Equals, '');
			emptyGroupBuilder.done();

			orFilter.done();
		}

		function getMatches(uiFilter) {
			SoliditetMatcherCtrl.rootData.data = [];
			SoliditetMatcherCtrl.rootData.tableLoading = true;

			const filters = new RequestBuilder();
			filters.limit = SoliditetMatcherCtrl.rootData.limit;
			filters.addSort(Account.attr.name, true);
			filters.addFilter({ field: 'isExternal' }, filters.comparisonTypes.Equals, 0);

			if (SoliditetMatcherCtrl.rootData.campaign) {
				filters.addFilter(
					Account.attr.campaigns.attr.id,
					filters.comparisonTypes.Equals,
					SoliditetMatcherCtrl.rootData.campaign.id
				);
			}
			if (uiFilter?.ignoreIds) {
				filters.addFilter(Account.attr.id, filters.comparisonTypes.NotEquals, uiFilter.ignoreIds);
			}
			if (uiFilter?.showMineOnly) {
				filters.addFilter(Account.attr.users.attr.id, filters.comparisonTypes.Equals, self.id);
			}

			const orFilter = filters.orBuilder();

			/*
				If you have both prosepcting and bisnode you will get companies that have:
				prospecting_id: null, duns_no: not null 
				But you will not get companies with:
				prospecting_id: not null, duns_no: null
			*/
			if (hasSoliditet) {
				orFilter.next();
				orFilter.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, null);

				if (hasProspecting) {
					orFilter.addFilter(Account.attr.prospectingId, filters.comparisonTypes.Equals, null);
					addCountryFilter(orFilter, getAvailableCountries());
				} else {
					addCountryFilter(orFilter, getAvailableCountries(true, false));
				}

				orFilter.next();

				orFilter.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, '0');
				if (hasProspecting) {
					orFilter.addFilter(Account.attr.prospectingId, filters.comparisonTypes.Equals, null);
					addCountryFilter(orFilter, getAvailableCountries());
				} else {
					addCountryFilter(orFilter, getAvailableCountries(true, false));
				}
			}

			if (hasProspecting) {
				orFilter.next();
				orFilter.addFilter(Account.attr.prospectingId, filters.comparisonTypes.Equals, null);
				addCountryFilter(orFilter, getAvailableCountries(false, true));
			}

			if (uiFilter?.showHidden) {
				orFilter.next();
				orFilter.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, hiddenFileterValue);
				addCountryFilter(orFilter, getAvailableCountries());
			} else {
				filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.NotEquals, hiddenFileterValue);
			}

			orFilter.done();

			SoliditetClient.customer(customerId)
				.findMatches(filters.build())
				.then(function (res) {
					SoliditetMatcherCtrl.rootData.data = res.data;
					SoliditetMatcherCtrl.rootData.totalMatches = res.metadata.total;
					SoliditetMatcherCtrl.rootData.totalHidden = res.metadata.hidden;
					SoliditetMatcherCtrl.rootData.tableLoading = false;
				})
				.catch(function () {
					SoliditetMatcherCtrl.rootData.tableLoading = false;
					SoliditetMatcherCtrl.rootData.error = true;
				});
		}

		var updateMatches = function (data) {
			var deleteArray = _.pluck(_.filter(data, { action: 'delete' }), 'id');
			var hide = _.pluck(_.filter(data, { action: 'hide' }), 'id');
			var buy = _.filter(data, { action: 'buy' });
			var updateProspecting = _.filter(data, { action: 'updateProspecting' });
			var merge = _.filter(data, { action: 'merge' });

			var matches = {
				buy: buy,
				hide: hide,
				merge: merge,
				delete: deleteArray,
				updateProspecting: updateProspecting
			};

			// This promise is handled in the react component. This view should be rebuilt with redux
			return SoliditetClient.customer(customerId).updateMatches(matches);
		};

		function clientSearch(id, searchString, countryCode) {
			const rb = new RequestBuilder();
			rb.limit = 20;
			rb.extraParams.push({ key: 'id', value: id });
			rb.extraParams.push({ key: 'searchString', value: searchString });
			const countries = countryCode ? [countryCode] : getAvailableCountries();
			rb.extraParams.push({ key: 'countries', value: countries });
			return SoliditetClient.customer(customerId).searchMatches(rb.build());
		}

		var getCampaign = function () {
			if (SoliditetMatcherCtrl.rootData.campaign) {
				Campaign.customer(customerId)
					.get(SoliditetMatcherCtrl.rootData.campaign.id)
					.then(function (res) {
						if (res.data) {
							SoliditetMatcherCtrl.rootData.campaign = res.data;
						}
					})
					.catch(e => console.log('Error fetching campaign in matcher', e));
			}
		};

		function checkForMatchAllJob() {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'action' }, rb.comparisonTypes.Equals, 'multi-ProspectingMatcher-Client');
			rb.addFilter({ field: 'userIds.userId' }, rb.comparisonTypes.Equals, 1);
			rb.addFilter({ field: 'status' }, rb.comparisonTypes.NotEquals, '100');
			rb.addFilter({ field: 'status' }, rb.comparisonTypes.NotEquals, '-1');

			PushNotifications.customer(customerId)
				.find(rb.build())
				.then(({ data }) => {
					SoliditetMatcherCtrl.rootData.ongoingMatchAllJob = data.length > 0;
				})
				.catch(error => logError(error));
		}

		function setLimit(limit) {
			SoliditetMatcherCtrl.rootData.limit = limit;
		}

		function matchAll() {
			Prospecting.matchAll().catch(error => logError(error));
			SoliditetMatcherCtrl.rootData.ongoingMatchAllJob = true;
		}

		SoliditetMatcherCtrl.rootData.ongoingMatchAllJob = true;
		checkForMatchAllJob();
		getCampaign();
		SoliditetMatcherCtrl.rootData.submit = updateMatches;
		SoliditetMatcherCtrl.rootData.getMatches = getMatches;
		SoliditetMatcherCtrl.rootData.clientSearch = clientSearch;
		SoliditetMatcherCtrl.rootData.setLimit = setLimit;
		SoliditetMatcherCtrl.rootData.matchAll = matchAll;
	}
]);

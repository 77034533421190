'use strict';

import SystemMail from 'App/babel/resources/SystemMail';
import logError from 'App/babel/helpers/logError';

angular.module('domain.admin').controller('VisitSettings', [
	'$scope',
	'$upModal',
	'Account',
	'RequestBuilder',
	'LeaderAccount',
	'LeaderPage',
	'AppService',
	'$q',
	'$translate',
	'ClientParam',
	function (
		$scope,
		$upModal,
		Account,
		RequestBuilder,
		LeaderAccount,
		LeaderPage,
		AppService,
		$q,
		$translate,
		ClientParam
	) {
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;
		var offset = 0;
		var LIMIT = 50;
		var searchTimeout;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'visitSettings';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			pages: [],
			script: '',
			currentSorting: {
				attribute: 'pageImpression',
				ascending: false
			},
			thereIsMore: false,
			savingScore: false,
			savingHide: false,
			showAll: false,
			hasHiddenPages: false
		};

		const getPages = function (searchStr, loadMore) {
			AdminSubCtrl.rootData.pageData.loading = true;
			const requestBuilder = new RequestBuilder();
			requestBuilder.limit = LIMIT;
			requestBuilder.addSort(
				AdminSubCtrl.rootData.pageData.currentSorting.attribute,
				AdminSubCtrl.rootData.pageData.currentSorting.ascending
			);

			if (loadMore) {
				offset += 50;
				AdminSubCtrl.rootData.pageData.loadingMore = true;
			} else {
				offset = 0;
			}

			requestBuilder.offset = offset;

			// If we have a search string
			if (searchStr && searchStr.length) {
				const orBuilder = requestBuilder.orBuilder();

				// Name
				orBuilder.next();
				orBuilder.addFilter(LeaderPage.attr.name, requestBuilder.comparisonTypes.Wildcard, searchStr);

				// Url
				orBuilder.next();
				orBuilder.addFilter(LeaderPage.attr.url, requestBuilder.comparisonTypes.Wildcard, searchStr);

				orBuilder.done();
			}
			if (!AdminSubCtrl.rootData.pageData.showAll) {
				requestBuilder.addFilter(LeaderPage.attr.hide, requestBuilder.comparisonTypes.Equals, 0);
			}

			const query = requestBuilder.build();
			LeaderPage.find(query)
				.then(res => {
					AdminSubCtrl.rootData.pageData.total = res.metadata.total;

					if (loadMore) {
						AdminSubCtrl.rootData.pageData.pages = AdminSubCtrl.rootData.pageData.pages.concat(res.data);
						AdminSubCtrl.rootData.pageData.loadingMore = false;
					} else {
						AdminSubCtrl.rootData.pageData.pages = res.data;
					}

					AdminSubCtrl.rootData.pageData.thereIsMore =
						AdminSubCtrl.rootData.pageData.pages.length < res.metadata.total;

					AdminSubCtrl.rootData.pageData.loading = false;
				})
				.catch(err => logError(err));
		};

		const getHiddenPageCount = function () {
			return LeaderPage.find({
				q: [
					{
						a: LeaderPage.attr.hide.field,
						c: 'eq',
						v: 1
					}
				],
				limit: 0
			}).then(function (res) {
				AdminSubCtrl.rootData.pageData.hasHiddenPages = res.metadata.total > 0;
				if (!AdminSubCtrl.rootData.pageData.hasHiddenPages) {
					AdminSubCtrl.rootData.pageData.showAll = false;
				}
			});
		};

		$scope.$on('leaderPage.updated', function (e, updated) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.pages, { id: updated.id });
			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.pages.splice(index, 1, updated);
			}
		});

		$scope.$on('leaderPage.deleted', function (e, deleted) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.pages, { id: deleted.id });
			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.pages.splice(index, 1);
			}
		});

		AdminSubCtrl.rootData.onSearch = function (str) {
			str = str.trim();
			if (searchTimeout) {
				clearTimeout(searchTimeout);
				searchTimeout = null;
			}

			searchTimeout = setTimeout(function () {
				if (str.length < 2 && str.length !== 0) {
					return;
				} else if (str.length === 0) {
					getPages();
				}

				getPages(str);
			}, 400);
		};

		AdminSubCtrl.rootData.onSort = function (current) {
			AdminSubCtrl.rootData.pageData.currentSorting = current;
			getPages();
		};

		AdminSubCtrl.rootData.onShowMore = function () {
			getPages(null, true);
		};

		AdminSubCtrl.rootData.toggleRetargeting = function (value) {
			// eslint-disable-next-line promise/catch-or-return
			ClientParam.save(226, !value).then(function () {
				var metadata = AppService.getMetadata();
				metadata.params.ExcludeRetargetingScript = !value;
				AppService.setMetadata(metadata);
				AdminSubCtrl.rootData.pageData.includeRetargeting = value;
				LeaderAccount.getScript();
			});
		};

		AdminSubCtrl.rootData.onDeletePage = function (page) {
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('visitor.page').toLowerCase(),
					body: 'confirm.removePage',
					resolveTrue: 'default.remove',
					resolveTrueBtnClass: 'btn-red',
					icon: 'fa-warning'
				})
				.then(function () {
					LeaderPage.delete(page);
				});
		};

		AdminSubCtrl.rootData.onPageScoreUpdate = function (page, score) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.pages, { id: page.id });
			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.pages[index].score = score;
			}

			AdminSubCtrl.rootData.pageData.savingScore = true;
			var updatePage = {
				id: page.id,
				score: score
			};
			LeaderPage.save(updatePage).finally(function () {
				AdminSubCtrl.rootData.pageData.savingScore = false;
			});
		};

		AdminSubCtrl.rootData.onPageHideUpdate = function (page, hide) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.pages, { id: page });

			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.pages[index].hide = hide;
			}

			AdminSubCtrl.rootData.pageData.savingHide = true;

			LeaderPage.save({
				id: page,
				hide: hide
			}).finally(function () {
				AdminSubCtrl.rootData.pageData.savingHide = false;
				getHiddenPageCount();
			});
		};

		AdminSubCtrl.rootData.sendSystemMail = function (email) {
			return SystemMail.send('installingScript', email);
		};

		AdminSubCtrl.rootData.onToggleShowAll = function (searchStr) {
			AdminSubCtrl.rootData.pageData.showAll = !AdminSubCtrl.rootData.pageData.showAll;
			getPages(searchStr);
		};

		var getInitialData = function () {
			return $q
				.all({
					pages: getPages(),
					script: LeaderAccount.getScript()
				})
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.script = res.script.data;
					AdminSubCtrl.rootData.pageData.includeRetargeting = !AppService.getMetadata().params
						.ExcludeRetargetingScript;
					AdminSubCtrl.rootData.pageLoading = false;
				});
		};

		var init = function () {
			AdminSubCtrl.rootData.pageData.activated = true;
			getInitialData();
			getHiddenPageCount();
		};

		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';
import t from 'Components/Helpers/translate';

import './LockedJourney.scss';

export default class LockedJourney extends React.Component {
	constructor(props) {
		super(props);

		this.journeySteps = Tools.AppService.getJourneySteps();
	}

	render() {
		const classes = new BemClass('LockedJourney');
		if (!this.props.config.properties.length) {
			return null;
		}

		const selectedStatus = this.journeySteps.find(s => s.value === this.props.config.properties[0].value);

		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>
						{`${t('default.update')} ${t('default.journeyStep').toLowerCase()} ${t(
							'default.to'
						).toLowerCase()}:`}
						<br />
						<span style={{ color: selectedStatus.color }}>{t(selectedStatus.name)}</span>
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

LockedJourney.propTypes = {
	config: PropTypes.object.isRequired
};

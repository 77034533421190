'use strict';

angular.module('upResources').factory('Report', [
	'$resource',
	'$q',
	'GenericQuota',
	'URL',
	'API',
	function Report($resource, $q, GenericQuota, URL, API) {
		var TYPE = {
			ARRCHANGES: 'annualvaluechange',
			ORDER: 'order',
			OPPORTUNITY: 'order',
			AGREEMENT: 'agreement',
			ACTIVITY: 'activity',
			APPOINTMENT: 'appointment',
			QUOTA: 'quota',
			ACTIVITYQUOTA: 'activityQuota',
			VISIT: 'visit',
			SOLIDITET: 'soliditetClient',
			FORMSUBMIT: 'formSubmit',
			CLIENT: 'client',
			CONTACT: 'contact',
			GENERICQUOTA: 'genericQuota',
			CONTRIBUTIONMARGINQUOTA: 'contributionMargin',
			RECURRINGQUOTA: 'recurring',
			ONEOFFQUOTA: 'oneOff',
			FILEMETADATA: 'fileMetadata',
			MAILTEMPLATE: 'mailTemplate',
			FORM: 'form',
			MAILCAMPAIGN: 'mailCampaign'
		};

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Report = $resource(
					URL + API + customer + '/report/api/:entity/',
					{},
					{
						find: { method: 'GET', isArray: false, skipDateConvert: true }
					}
				);
				instance.type = null;
				instance.quotaType = null;

				instance.find = function (filter, options) {
					if (!instance.type) {
						throw new Error('No type set for Report resource');
					}

					if (instance.quotaType) {
						if (!filter) {
							filter = {};
						}
						if (!filter.q) {
							filter.q = [];
						}
						filter.q.push({ a: GenericQuota.attr.type.field, c: 'm', v: instance.quotaType });
					}
					var defered = $q.defer();
					var params = angular.extend(filter, options);
					params.entity = instance.type;

					Report.find(params, function (response) {
						if (response.error) {
							defered.reject(response.error);
						} else {
							defered.resolve(response);
						}
					});

					instance.type = null;

					return defered.promise;
				};

				instance.setType = function (type) {
					if ([TYPE.CONTRIBUTIONMARGINQUOTA, TYPE.RECURRINGQUOTA, TYPE.ONEOFFQUOTA].indexOf(type) >= 0) {
						instance.type = TYPE.GENERICQUOTA;
						instance.quotaType = type;
					} else {
						instance.type = type;
					}

					return instance;
				};

				return instance;
			},
			type: TYPE
		};

		return Model;
	}
]);

'use strict';

angular.module('upResources').factory('LandingPageTemplate', [
	'$resource',
	'$q',
	'URL',
	'API',
	function LandingPageTemplate($resource, $q, URL, API) {
		var Resource = $resource(
			URL + API + 'landingPageTemplate/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false
				},
				find: {
					method: 'GET',
					isArray: false
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			}
		};

		return Model;
	}
]);

'use strict';

angular.module('domain.activity').factory('UpdateActivityMultiMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function () {
			var promises = {
				activeUsers: $q.when({ data: AppService.getActiveUsers() }),
				activityTypes: $q.when({ data: AppService.getActivityTypes('activity', true) }),
				activityCustomFields: $q.when({ data: AppService.getCustomFields('activity') })
			};

			return $q.all(promises);
		};
	}
]);

import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import T from 'Components/Helpers/translate';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { FilterPreset, generateFilter } from '../filterGenerators';

export default (): { [key: string]: FilterConfig } => ({
	User: generateFilter(FilterPreset.User, {
		field: 'user.id',
		title: 'default.users'
	}),
	Name: generateFilter(FilterPreset.Text, {
		field: 'name',
		columnPath: 'name',
		title: 'default.internalName'
	}),
	SendDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'sendDate',
			title: 'column.date'
		},
		'standard'
	),
	Template: generateFilter(FilterPreset.List, {
		field: 'templateId',
		title: 'mail.template',
		inputType: 'select',
		resource: 'MailTemplate',
		multiple: false
	}),
	Status: generateFilter(FilterPreset.List, {
		field: 'status',
		title: 'default.status',
		inputType: 'select',
		dataPromise: $q =>
			$q.when({
				data: [
					{ id: 'SENT', name: T('mail.sent') },
					{ id: 'SCHEDULED', name: T('mail.scheduled') },
					{ id: 'DRAFT', name: T('mail.status.DRAFT') },
					{ id: 'DEL_PROJECT', name: T('mail.status.DEL_PROJECT') }
				]
			})
	}),
	Archived: generateFilter(FilterPreset.Radio, {
		field: 'isArchived',
		title: 'default.archived',
		listTitle: 'default.showArchived',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.yes', value: true, comparisonType: 'Equals' },
			{ text: 'default.no', value: false, comparisonType: 'Equals' }
		]
	}),
	// Quick search
	ListSearch: generateFilter(FilterPreset.ListSearch, {}, ['project.name', 'subject', 'fromName', 'name']),
	Label: generateFilter(FilterPreset.RawValue, {
		build: function (filter, rb) {
			if (filter.value === 'unlabeled') {
				rb.addFilter({ field: 'labels.id' }, comparisonTypes.Equals, null);
			} else if (typeof filter.value === 'number') {
				rb.addFilter({ field: 'labels.id' }, comparisonTypes.Equals, [filter.value]);
			}
		}
	})
});

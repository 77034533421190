import React from 'react';
import PropTypes from 'prop-types';
import AnonList from './List';
import AnonCard from './Card';

class AnonPageSwitcher extends React.Component {
	render() {
		const {
			activeTab,
			CheckboxChange,
			selectField,
			contactSelected,
			rootData,
			selectedContacts,
			updateConfig,
			fields,
			switchTab,
			selectAllFields,
			deselectEverything,
			everyThingSelected,
			buttonTextNumber,
			selectingAll
		} = this.props;

		if (activeTab === 'contacts') {
			return (
				<AnonList
					rootData={rootData}
					switchTab={switchTab}
					selectField={selectField}
					contactSelected={contactSelected}
					selectAllFields={selectAllFields}
					selectedContacts={selectedContacts}
					selectedFields={fields.selectedFields}
					deselectEverything={deselectEverything}
					obligatoryFields={fields.obligatoryFields}
					everyThingSelected={everyThingSelected}
					buttonTextNumber={buttonTextNumber}
					selectingAll={selectingAll}
				/>
			);
		}

		return <AnonCard onChange={CheckboxChange} updateConfig={updateConfig} {...this.props} />;
	}
}

AnonPageSwitcher.defaultProps = {
	fields: {
		selectedFields: [],
		obligatoryField: []
	}
};

AnonPageSwitcher.propTypes = {
	rootData: PropTypes.shape({
		pageData: PropTypes.shape({
			currentPage: PropTypes.number
		})
	}),
	fields: PropTypes.shape({
		selectedFields: PropTypes.array,
		obligatoryField: PropTypes.array
	}),
	selectingAll: PropTypes.bool,
	activeTab: PropTypes.string,
	selectedContacts: PropTypes.array,
	everyThingSelected: PropTypes.bool,
	buttonTextNumber: PropTypes.any,
	CheckboxChange: PropTypes.func,
	updateConfig: PropTypes.func,
	switchTab: PropTypes.func,
	contactSelected: PropTypes.func,
	selectedFields: PropTypes.array,
	obligatoryFields: PropTypes.array,
	selectField: PropTypes.func,
	selectAllFields: PropTypes.func,
	deselectEverything: PropTypes.func
};
export default AnonPageSwitcher;

'use strict';

angular.module('upResources').factory('WhatIsMyIp', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ApiKey($resource, $q, URL, API) {
		var Resource = $resource(
			URL + API + 'function/whatismyip',
			{},
			{
				get: { method: 'GET', isArray: false }
			}
		);

		return {
			get: function () {
				return Resource.get().$promise;
			}
		};
	}
]);

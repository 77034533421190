import moment from 'moment';

const validateProp = (data, prop) => {
	if (!data[prop]) {
		throw new Error('DateChange got invalid data: missing ' + prop + ' got: ' + JSON.stringify(data));
	}
};

const DateChange = (DATA, VALUE, OPTIONS = {}) => {
	if (!moment(VALUE).isValid() && !(VALUE instanceof moment) && !moment(VALUE, 'L').isValid()) {
		throw new Error('Invalid date');
	}
	// Calidate that we got all we need
	validateProp(DATA, 'date');
	validateProp(DATA, 'endDate');
	validateProp(DATA, 'currentAppointmentLength');

	// Create moment instans of the new date value
	const NEW_DATE = VALUE instanceof moment ? VALUE : moment(VALUE).isValid() ? moment(VALUE) : moment(VALUE, 'L');

	const res = {};

	if (OPTIONS && OPTIONS.setTime) {
		if (OPTIONS.setEnd) {
			const END_MOMENT = ChangeDate(NEW_DATE, getFormated(DATA.endDate), true);

			res.date = moment(DATA.date).clone().toDate();
			res.endDate = END_MOMENT.toDate();

			if (END_MOMENT.isBefore(DATA.date)) {
				res.date = END_MOMENT.clone().subtract(DATA.currentAppointmentLength, 'minutes').toDate();
			}

			res.currentAppointmentLength = moment(res.endDate).diff(res.date, 'minutes');
		} else {
			const newDate = ChangeDate(NEW_DATE, moment(DATA.date), true);

			res.date = newDate.toDate();
			res.endDate = newDate.clone().add(DATA.currentAppointmentLength, 'minutes').toDate();
			res.currentAppointmentLength = moment(res.endDate).diff(res.date, 'minutes');
		}
	} else if (OPTIONS && OPTIONS.setEnd) {
		const END_MOMENT = ChangeDate(NEW_DATE, getFormated(DATA.endDate));

		res.date = moment(DATA.date).clone().toDate();
		res.endDate = END_MOMENT.toDate();

		if (END_MOMENT.isBefore(res.date)) {
			res.date = END_MOMENT.clone().subtract(DATA.currentAppointmentLength, 'minutes').toDate();
		}

		res.currentAppointmentLength = moment(res.endDate).diff(res.date, 'minutes');
	} else if (OPTIONS && OPTIONS.setBoth) {
		const bothDate = ChangeDate(NEW_DATE, getFormated(DATA.date), true, true);
		res.date = bothDate.toDate();
		res.endDate = bothDate.clone().add(DATA.currentAppointmentLength, 'minutes').toDate();
		res.currentAppointmentLength = moment(res.endDate).diff(res.date, 'minutes');
	} else {
		const DA = ChangeDate(NEW_DATE, moment(DATA.date));

		res.date = DA.toDate();
		res.endDate = DA.clone().add(DATA.currentAppointmentLength, 'minutes').toDate();
		res.currentAppointmentLength = moment(res.endDate).diff(res.date, 'minutes');
	}

	res.dateAvailability = DATA.date;
	if (res.currentAppointmentLength <= 0) {
		res.endDate = moment(res.date).clone().add(15, 'minutes').toDate();
		res.currentAppointmentLength = 15;
	}

	return res;
};

const ChangeDate = (NEW_DATE, CURRENT_DATE, SET_TIME, SET_BOTH) => {
	let date;
	let hours;
	let minutes;
	if (SET_TIME) {
		date = CURRENT_DATE.clone();
		hours = NEW_DATE.hour();
		minutes = NEW_DATE.minute();
	} else {
		date = NEW_DATE.clone();
		hours = CURRENT_DATE.hour();
		minutes = CURRENT_DATE.minute();
	}

	if (SET_BOTH) {
		date = NEW_DATE.clone();
	}

	return date.set('hours', hours).set('minutes', minutes);
};

const getFormated = (DATE, FORMAT) => {
	if (FORMAT) {
		return moment(DATE).format(FORMAT);
	}

	return moment(DATE);
};

export default DateChange;

'use strict';

angular.module('domain.esign', []).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		$stateProvider.state('esign', {
			templateUrl: require('App/upsales/domain/esign/views/listEsign.tpl.html?file'),
			url: '/:customerId/esign/',
			controller: 'ListEsign as ListEsign',
			section: 'sale',
			resolve: {
				authUser: [
					'$routeGuard',
					'$stateParams',
					function ($routeGuard, $stateParams) {
						return $routeGuard.requireUser($stateParams);
					}
				],
				esignActivated: [
					'AppService',
					'$q',
					function (AppService, $q) {
						return AppService.loadedPromise.then(function () {
							var esignIntegrations = AppService.getEsignIntegrations();
							var esignActive = !!esignIntegrations.length;

							if (esignActive) {
								return $q.when(true);
							}
							return $q.reject(false);
						});
					}
				],
				isAvailable: [
					'FeatureHelper',
					function (FeatureHelper) {
						return FeatureHelper.placeholder(FeatureHelper.Feature.ESIGN);
					}
				]
			}
		});

		$upModalProvider
			.modal('editEsign', {
				templateUrl: require('App/upsales/domain/esign/views/editEsign.tpl.html?file'),
				style: 'form default dynamic pattern no-border wide-sidebar',
				controller: 'EditEsign as EditEsign',
				resolve: {
					hideModal: function ($preCompileElement) {
						$preCompileElement.hide();
					},
					isAvailable: function (FeatureHelper) {
						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ESIGN);
					},
					meta: function ($modalParams, EditEsignMeta, $preCompileElement) {
						return EditEsignMeta($modalParams, $preCompileElement);
					}
				}
			})
			.modal('confirmEsign', {
				templateUrl: require('App/upsales/domain/esign/views/confirmEsign.tpl.html?file'),
				style: 'form-sm info',
				controller: 'ConfirmEsign as ConfirmEsign',
				resolve: {
					isAvailable: function (FeatureHelper) {
						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ESIGN);
					},
					meta: function ($modalParams, ConfirmEsignMeta) {
						return ConfirmEsignMeta($modalParams);
					}
				}
			});
	}
]);

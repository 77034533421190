import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import { connect } from 'react-redux';
import { setCompanyProfileData } from 'Store/reducers/OnboardingReducer';
import { Title, Label, Input, Row, Column } from '@upsales/components';
import UpSelect from '../../../Inputs/UpSelect';

const mapDispatchToProps = {
	setCompanyProfileData
};

const mapStateToProps = state => ({
	companyProfile: state.Onboarding.companyProfile,
	currentStepId: state.Onboarding.currentStepId,
	currentSubstepId: state.Onboarding.currentSubstepId
});

class CompanyProfile extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.companyProfile.address.maintitle'),
			input: {
				name: T('tag.client.name'),
				address: T('onboarding.step.companyProfile.streetAddress'),
				zipCode: T('address.zip'),
				city: T('city'),
				country: T('country'),
				website: T('default.webpage')
			},
			selectCountry: `${T('default.choose')} ${T('address.country')}`
		};
	}

	defaultChange(value, key) {
		if (typeof value !== 'string' || typeof value !== 'number') {
			value = value.target.value;
		}

		const { setCompanyProfileData } = this.props;

		setCompanyProfileData(key, value);
	}

	render() {
		const { companyProfile } = this.props;

		const CompanyProfileClass = new Bem('CompanyProfile');
		return (
			<div className={CompanyProfileClass.b()}>
				<Title>{this.lang.title}</Title>
				<Row>
					<Column>
						<Label>{this.lang.input['name']}</Label>
						<Input onChange={value => this.defaultChange(value, 'name')} value={companyProfile.name} />
					</Column>
				</Row>
				<Row>
					<Column>
						<Label>{this.lang.input['address']}</Label>
						<Input
							onChange={value => this.defaultChange(value, 'address')}
							value={companyProfile.address}
						/>
					</Column>
				</Row>
				<Row>
					<Column>
						<Label>{this.lang.input['zipCode']}</Label>
						<Input
							onChange={value => this.defaultChange(value, 'zipCode')}
							value={companyProfile.zipCode}
						/>
					</Column>
					<Column>
						<Label>{this.lang.input['city']}</Label>
						<Input onChange={value => this.defaultChange(value, 'city')} value={companyProfile.city} />
					</Column>
				</Row>
				<Row>
					<Column>
						<Label>{this.lang.input['country']}</Label>
						<UpSelect
							className="form-control"
							placeholder={this.lang.selectCountry}
							defaultValue={companyProfile.country || null}
							formatSelection={(item, container, escape) => escape(item.name)}
							formatResult={(item, container, query, escape) => escape(item.name)}
							matcher={(term, undef, item) => {
								const name = Tools.$translate(item.name).toLowerCase();
								return name.indexOf(term.toLowerCase()) !== -1;
							}}
							data={Tools.CountryCodes.all()}
							onChange={event => {
								this.defaultChange(event, 'country');
							}}
							getId={item => item.code}
						/>
					</Column>
				</Row>
				<Row>
					<Column>
						<Label>{this.lang.input['website']}</Label>
						<Input
							onChange={value => this.defaultChange(value, 'website')}
							value={companyProfile.website}
						/>
					</Column>
				</Row>
			</div>
		);
	}
}

CompanyProfile.propTypes = {
	setCompanyProfileData: PropTypes.func,
	companyProfile: PropTypes.object,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number
};

CompanyProfile.setAsCompleteOnNext = true;

export const detached = CompanyProfile;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);

import React from 'react';
import PropTypes from 'prop-types';
import urlSlug from 'slugify';
import Bem from '@upsales/components/Utils/bemClass';
import { Tooltip, Icon, Row, Column, Input, Link, Loader, Text } from '@upsales/components';
import DomainsResource from 'App/babel/resources/Domains';
import UpSelect from '../Inputs/UpSelect';
import './PageDomainInput.scss';
import logError from 'Helpers/logError';
import { makeCancelable } from 'Helpers/promise';

const getValidatedStr = (domain, urlName) => {
	return `${domain}:${urlName}`;
};

class PageDomainInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			valid: false,
			loading: false,
			nameTouched: false,
			validated: ''
		};

		const t = Tools.$translate;
		this.lang = {
			invalidUrl: t('socialEvent.invalidUrl'),
			invalidUrlEmpty: t('socialEvent.invalidUrlEmpty'),
			selectDomain: t('socialEvent.selectDomain'),
			urlFriendlyName: t('socialEvent.urlFriendlyName'),
			setupCustomDomains: t('socialEvent.setupCustomDomains'),
			urlPath: t('socialEvent.urlPath')
		};

		this.validateDomain = async (domain, urlName) => {
			if (this.fetchValidationPromise) {
				this.fetchValidationPromise.cancel();
			}

			this.setState({ loading: true, validated: getValidatedStr(domain, urlName) });
			this.fetchValidationPromise = makeCancelable(
				this.props.validateDomain(domain, urlName, this.props.socialEventId, this.props.landingpageId)
			);

			try {
				const valid = await this.fetchValidationPromise.promise;
				this.setState({ loading: false, valid });
				return valid;
			} catch (e) {
				logError(e, 'Error validating domain');
			}
		};

		this.domainChanged = async domain => {
			if (domain && this.props.value.urlName) {
				this.props.onChange({
					urlName: this.props.value.urlName,
					valid: false,
					domain,
					nameTouched: this.state.nameTouched
				});
				const valid = await this.validateDomain(domain, this.props.value.urlName);
				this.props.onChange({
					urlName: this.props.value.urlName,
					valid,
					domain,
					nameTouched: this.state.nameTouched
				});
			} else {
				if (Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')) {
					// The user had a domain, but cleared it, so we clear the urlName as well and "check" that the url is now valid (it will be since its empty)
					this.props.onChange({
						urlName: '',
						valid: false,
						domain,
						nameTouched: this.state.nameTouched
					});
					this.domainUrlChanged('');
				} else {
					this.props.onChange({
						urlName: this.props.value.urlName,
						valid: this.state.valid,
						domain,
						nameTouched: this.state.nameTouched
					});
				}
			}
		};

		let timer = null;

		this.domainUrlChanged = urlName => {
			if (urlName && [' ', '-'].every(s => s !== urlName[urlName.length - 1])) {
				urlName = urlSlug(urlName.toLowerCase());
			}

			this.setState({ nameTouched: true });
			this.props.onChange({
				urlName,
				valid: false,
				domain: this.props.value.domain,
				nameTouched: true
			});

			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(async () => {
				let valid;
				if (Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')) {
					const validName = this.props.value.domain ? urlName.length > 0 : true;
					const validUrl = await this.validateDomain(this.props.value.domain, urlName);
					valid = validName && validUrl;
				} else {
					valid = await this.validateDomain(this.props.value.domain, urlName);
				}
				this.props.onChange({
					urlName,
					valid,
					domain: this.props.value.domain,
					nameTouched: true
				});
			}, 200);
		};
	}
	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		if (this.props.value.urlName) {
			this.setState({ nameTouched: true });
		}
		if (this.props.value.domain && this.props.value.urlName) {
			this.validateDomain(this.props.value.domain, this.props.value.urlName);
		}
	}

	componentDidUpdate(oldProps) {
		if (oldProps.value.urlName !== this.props.value.urlName) {
			this.setState({ nameTouched: true });
		}
		if (getValidatedStr(this.props.value.domain, this.props.value.urlName) !== this.state.validated) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')) {
				this.validateDomain(this.props.value.domain, this.props.value.urlName)
					.then(valid => {
						if (!valid) {
							this.props.onChange({
								urlName: this.props.value.urlName,
								valid: false,
								domain: this.props.value.domain,
								nameTouched: false
							});
						}
					})
					.catch(e => {
						logError(e, "Couldn't validate domain");
					});
			} else {
				this.validateDomain(this.props.value.domain, this.props.value.urlName);
			}
		}
	}

	componentWillUnmount() {
		if (this.fetchValidationPromise) {
			this.fetchValidationPromise.cancel();
		}
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const { domains, disabled, className, value, onChange, ...props } = this.props;
		const { valid, loading } = this.state;
		const classes = new Bem('PageDomainInput', className);

		let isInvalid;
		let isInvalidOccupied;
		let isInvalidEmpty;
		if (Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')) {
			isInvalidOccupied = !valid && value.domain && value.urlName && !loading;
			isInvalidEmpty = value.domain && !value.urlName && !loading;
			isInvalid = isInvalidOccupied || isInvalidEmpty;
		} else {
			isInvalid = !valid && value.domain && value.urlName && !loading;
		}

		let fullUrl = value.domain ? value.domain : '';
		fullUrl += value.urlName ? '/' + value.urlName : '';

		return (
			<div className={classes.b()}>
				{domains.length ? (
					<div>
						<Row className={classes.elem('row').b()}>
							<Column className={classes.elem('col').b()}>
								<UpSelect
									className="form-control"
									placeholder={this.lang.selectDomain}
									defaultValue={value.domain}
									formatSelection={(d, container, escape) => escape(d.name)}
									formatResult={(d, container, query, escape) => escape(d.name)}
									data={() => ({ results: domains })}
									getId={d => d.name}
									onChange={e => this.domainChanged(e.target.value)}
									disabled={disabled}
								/>
							</Column>
							<Column className={classes.elem('col').b()}>
								<Input
									className={classes.elem('input').b()}
									placeholder={this.lang.urlPath}
									value={value.urlName}
									state={isInvalid ? 'error' : null}
									onChange={e => this.domainUrlChanged(e.target.value)}
									disabled={
										disabled ||
										(Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS') &&
											!value.domain)
									}
									maxLength={255}
								/>
								{isInvalid ? (
									<Tooltip
										title={
											Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')
												? isInvalidOccupied
													? this.lang.invalidUrl
													: this.lang.invalidUrlEmpty
												: this.lang.invalidUrl
										}
										position="left"
										className={classes.elem('invalid').b()}
									>
										<Icon name="exclamation-circle" color="red" />
									</Tooltip>
								) : null}
								{loading ? <Loader noU size="xs" className={classes.elem('loader').b()} /> : null}
							</Column>
						</Row>
						<Row>
							<Text color="grey-11" italic size="sm" className={classes.elem('domainName').b()}>
								{Tools.FeatureHelper.hasSoftDeployAccess('RESTRICT_MULTIPLE_SAME_EVENT_URLS')
									? value.domain
										? fullUrl
										: null
									: fullUrl}
							</Text>
						</Row>
					</div>
				) : (
					<Link
						className="EditSocialEventDetails__form-link"
						href={Tools.$state.href('administration.domains', {
							customerId: Tools.AppService.getCustomerId()
						})}
					>
						{this.lang.setupCustomDomains}
					</Link>
				)}
			</div>
		);
	}
}

PageDomainInput.propTypes = {
	domains: PropTypes.array,
	className: PropTypes.string,
	value: PropTypes.shape({
		domain: PropTypes.string,
		urlName: PropTypes.string
	}),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	validateDomain: PropTypes.func,
	socialEventId: PropTypes.number,
	landingpageId: PropTypes.number
};

PageDomainInput.defaultProps = {
	domains: [],
	disabled: false,
	value: {},
	onChange: () => {},
	validateDomain: async (domain, urlName, socialEventId, landingpageId) => {
		if (domain && urlName) {
			const { data } = await DomainsResource.checkAvailable(domain, urlName, socialEventId, landingpageId);
			return data.available;
		}
		return true;
	}
};

export default PageDomainInput;

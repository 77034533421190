import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import RequestBuilder, { comparisonTypes, AggregationTypes } from 'App/babel/resources/RequestBuilder';
import Flow from 'App/resources/Flow';
import MarketStats from 'App/resources/MarketStats';
import logError from 'App/babel/helpers/logError';
import { removeItem, replaceItem } from '../helpers/array';
import { marketingBoardTracker } from 'App/babel/helpers/Tracker';
import moment from 'moment';
import {
	SET_BOARD_LOADING,
	RESET,
	SET_SELECTED_DATE_PRESET,
	SET_STATS_LOADING,
	DATE_PRESETS,
	SET_DATE_RANGE,
	SET_BOARD_OFFSET,
	SET_BOARD_LOADING_MORE,
	SET_BOARD_DATA,
	SET_BOARD_FILTERS,
	SET_STATS,
	SET_BOARD_HAS_MORE_DATA,
	SET_HAS_FLOWS
} from 'Store/reducers/MarketingBoardReducer';

const BOARD_LIMIT = 20;

export const datePresets = {
	[DATE_PRESETS.today]: {
		now: () => ({
			start: moment().startOf('day').format(),
			end: moment().endOf('day').format()
		}),
		prev: () => ({
			start: moment().add(-1, 'day').startOf('day').format(),
			end: moment().add(-1, 'day').endOf('day').format()
		})
	},
	[DATE_PRESETS.yesterday]: {
		now: () => ({
			start: moment().add(-1, 'day').startOf('day').format(),
			end: moment().add(-1, 'day').endOf('day').format()
		}),
		prev: () => ({
			start: moment().add(-2, 'day').startOf('day').format(),
			end: moment().add(-2, 'day').endOf('day').format()
		})
	},
	[DATE_PRESETS['7days']]: {
		now: () => ({
			start: moment().add(-7, 'day').startOf('day').format(),
			end: moment().endOf('day').format()
		}),
		prev: () => ({
			start: moment().add(-14, 'day').startOf('day').format(),
			end: moment().add(-7, 'day').endOf('day').format()
		})
	},
	[DATE_PRESETS['30days']]: {
		now: () => ({
			start: moment().add(-30, 'day').startOf('day').format(),
			end: moment().endOf('day').format()
		}),
		prev: () => ({
			start: moment().add(-60, 'day').startOf('day').format(),
			end: moment().add(-30, 'day').endOf('day').format()
		})
	},
	[DATE_PRESETS['90days']]: {
		now: () => ({
			start: moment().add(-90, 'day').startOf('day').format(),
			end: moment().endOf('day').format()
		}),
		prev: () => ({
			start: moment().add(-180, 'day').startOf('day').format(),
			end: moment().add(-90, 'day').endOf('day').format()
		})
	}
};

const getDiff = (curr, prev) => {
	if (prev === 0) {
		return 0;
	}
	const diff = (100 * (curr - prev)) / ((curr + prev) / 2);
	return diff;
};

const setTracking = type => {
	marketingBoardTracker.track(marketingBoardTracker.events.CLICK_EVENT, { clickOn: type });
};

const getPercent = (num, total) => {
	let percent = num / total;
	if (isNaN(percent) || !isFinite(percent)) {
		percent = 0;
	} else {
		percent = percent * 100;
	}
	return percent.toFixed(1) + '%';
};

export const getDateFilter = (type, selectedDatePreset, fromDate, toDate) => {
	if (selectedDatePreset === DATE_PRESETS.custom) {
		return {
			start: moment(fromDate).startOf('day').format(),
			end: moment(toDate).endOf('day').format()
		};
	}

	return datePresets[selectedDatePreset][type]();
};

const getAdStats = prevPeriod => (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	const datePreset = getDateFilter(prevPeriod ? 'prev' : 'now', selectedDatePreset, fromDate, toDate);
	const Ads = getAngularModule('Ads');
	const customerId = getAngularModule('AppService').getCustomerId();
	const filter = new RequestBuilder();
	filter.addFilter({ field: 'date' }, comparisonTypes.GreaterThanEquals, datePreset.start);
	filter.addFilter({ field: 'date' }, comparisonTypes.LessThanEquals, datePreset.end);
	return Ads.customer(customerId).stats('maDashboardStats', filter.build());
};

const getFormSubmitForms = () => (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	const datePreset = getDateFilter('now', selectedDatePreset, fromDate, toDate);
	const FormSubmit = getAngularModule('FormSubmit');
	const Report = getAngularModule('Report');
	const customerId = getAngularModule('AppService').getCustomerId();

	const filter = new RequestBuilder();

	filter.addFilter(FormSubmit.attr.regDate, comparisonTypes.GreaterThanEquals, datePreset.start);
	filter.addFilter(FormSubmit.attr.regDate, comparisonTypes.LessThanEquals, datePreset.end);
	const aggForms = filter.aggregationBuilder();
	aggForms.addAggregation(AggregationTypes.Cardinality, 'form.id');
	aggForms.aggregationName('forms');
	aggForms.aggregationSize(1);
	aggForms.done();
	return Report.customer(customerId).setType(Report.type.FORMSUBMIT).find(filter.build());
};

const sumEvents = (event, prevPeriod) => (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	const datePreset = getDateFilter(prevPeriod ? 'prev' : 'now', selectedDatePreset, fromDate, toDate);
	const Event = getAngularModule('Event');

	const filter = new RequestBuilder();
	filter.limit = 0;

	filter.addFilter(Event.attr.subType, comparisonTypes.Equals, event);
	filter.addFilter(Event.attr.date, comparisonTypes.GreaterThanEquals, datePreset.start);
	filter.addFilter(Event.attr.date, comparisonTypes.LessThanEquals, datePreset.end);
	if (event === 'open' || event === 'click') {
		filter.addFilter(Event.attr.groupMailId, comparisonTypes.NotEquals, 0);
	}
	const built = filter.build();

	if (event === 'submit') {
		built.uniqueContact = true;
	} else {
		built.uniqueEntity = true;
	}

	return Event.find(built);
};

const getMail = prevPeriod => (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	const Mail = getAngularModule('Mail');
	const datePreset = getDateFilter(prevPeriod ? 'prev' : 'now', selectedDatePreset, fromDate, toDate);

	const filter = new RequestBuilder();

	filter.limit = 0;
	filter.addFilter(Mail.attr.date, comparisonTypes.GreaterThanEquals, datePreset.start);
	filter.addFilter(Mail.attr.date, comparisonTypes.LessThanEquals, datePreset.end);
	filter.addFilter(Mail.attr.type, comparisonTypes.Equals, 'out');
	filter.addFilter(Mail.attr.groupMailId, comparisonTypes.NotEquals, 0);

	return Mail.find(filter.build());
};

const getVisits = prevPeriod => (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	const Report = getAngularModule('Report');
	const Visitor = getAngularModule('Visitor');
	const customerId = getAngularModule('AppService').getCustomerId();
	const datePreset = getDateFilter(prevPeriod ? 'prev' : 'now', selectedDatePreset, fromDate, toDate);

	const filter = new RequestBuilder();

	filter.addFilter(Visitor.attr.startDate, comparisonTypes.GreaterThanEquals, datePreset.start);
	filter.addFilter(Visitor.attr.startDate, comparisonTypes.LessThanEquals, datePreset.end);
	const aggForms = filter.aggregationBuilder();
	aggForms.addAggregation(AggregationTypes.Cardinality, 'client.id');
	aggForms.aggregationName('clients');
	aggForms.aggregationSize(1);
	aggForms.done();
	return Report.customer(customerId).setType(Report.type.VISIT).find(filter.build());
};

export const getStats = () => async (dispatch, getState) => {
	const { selectedDatePreset, fromDate, toDate } = getState().MarketingBoard;
	dispatch(setStatsLoading(true));

	const featureHelper = getAngularModule('FeatureHelper');

	if (featureHelper.hasSoftDeployAccess('MARKET_STATS_ENDPOINT')) {
		let filters = {};

		if (selectedDatePreset === DATE_PRESETS.custom) {
			const Event = getAngularModule('Event');
			const { start, end } = getDateFilter('now', selectedDatePreset, fromDate, toDate);

			const rb = new RequestBuilder();
			rb.addFilter(Event.attr.date, comparisonTypes.GreaterThanEquals, start);
			rb.addFilter(Event.attr.date, comparisonTypes.LessThanEquals, end);

			filters = rb.build();
		}

		const {
			data: {
				uniqueVisitors,
				totalVisitors,
				totalMailSent,
				mailClicked,
				mailOpened,
				uniqueSubmitters,
				uniqueForms,
				adClients,
				impressions,
				visitorDiff,
				totalMailSentDiff,
				uniqueSubmittersDiff,
				adClientsDiff
			}
		} = await MarketStats.getStats(selectedDatePreset, filters);

		const stats = {
			uniqueVisits: uniqueVisitors,
			visits: totalVisitors,
			visitsDiff: visitorDiff,
			mail: totalMailSent,
			mailDiff: totalMailSentDiff,
			clicked: mailClicked,
			opened: mailOpened,
			formSubmits: uniqueSubmitters,
			formSubmitDiff: uniqueSubmittersDiff,
			formSubmitForms: uniqueForms,
			impressions,
			adClients,
			adClientsDiff
		};

		dispatch(setStats(stats));
	} else {
		const promises = [
			dispatch(getVisits()),
			dispatch(getMail()),
			dispatch(sumEvents('click')),
			dispatch(sumEvents('open')),
			dispatch(sumEvents('submit')),
			dispatch(getFormSubmitForms()),
			dispatch(getAdStats())
		];

		// Add prev-period data if not custom
		if (selectedDatePreset !== 'custom') {
			promises.push(dispatch(getVisits(true)));
			promises.push(dispatch(getMail(true)));
			promises.push(dispatch(sumEvents('submit', true)));
			promises.push(dispatch(getAdStats(true)));
		} else {
			promises.push(Promise.resolve({ data: { clients: { value: 0 } } }));
			promises.push(Promise.resolve({ metadata: { total: 0 } }));
			promises.push(Promise.resolve({ metadata: { total: 0 } }));
			promises.push(Promise.resolve({ data: { clients: { value: 0 } } }));
		}

		const [
			{ data: visits },
			{ metadata: mail },
			{ metadata: click },
			{ metadata: open },
			{ metadata: submit },
			{ data: forms },
			{ data: adStats },
			{ data: prevVisits },
			{ metadata: prevMail },
			{ metadata: prevSubmit },
			{ data: prevAdStats }
		] = await Promise.all(promises);

		const stats = {
			uniqueVisits: visits.clients.value,
			visits: visits.clients.doc_count || 0,
			visitsDiff: getDiff(visits.clients.value, prevVisits.clients.value),
			mail: mail.total,
			mailDiff: getDiff(mail.total, prevMail.total),
			clicked: getPercent(click.total, mail.total),
			opened: getPercent(open.total, mail.total),
			formSubmits: submit.total,
			formSubmitDiff: getDiff(submit.total, prevSubmit.total),
			formSubmitForms: forms.forms.value || 0,
			impressions: adStats.impressions || 0,
			adClients: adStats.clients.value,
			adClientsDiff: getDiff(adStats.clients.value, prevAdStats.clients.value)
		};

		dispatch(setStats(stats));
	}

	dispatch(setStatsLoading(false));
};

const getColumnData = step => (dispatch, getState) => {
	const { boardOffset, boardFilters } = getState().MarketingBoard;
	const Account = getAngularModule('Account');
	const FilterHelper = getAngularModule('FilterHelper');
	const customerId = getAngularModule('AppService').getCustomerId();
	const rb = FilterHelper.parseFilters(boardFilters, 'account');

	rb.fields = ['name', 'score', 'isExternal', 'clientId', 'dunsNo', 'journeyStep'];
	rb.limit = BOARD_LIMIT;
	rb.offset = boardOffset;

	rb.addFilter(Account.attr.journeyStep, comparisonTypes.Equals, step);
	rb.addSort(Account.attr.name, true);

	// Only allow externals in lead column
	if (step === 'lead') {
		// We don't want unidentified/external clients with no name
		const nameOr = rb.orBuilder();
		nameOr.next();
		nameOr.addFilter(Account.attr.name, comparisonTypes.NotEquals, null);
		nameOr.addFilter(Account.attr.isExternal, comparisonTypes.Equals, 1);
		nameOr.next();
		nameOr.addFilter(Account.attr.isExternal, comparisonTypes.Equals, 0);
		nameOr.done();
	} else {
		// We do not want any external accounts here
		rb.addFilter(Account.attr.isExternal, comparisonTypes.Equals, 0);
	}
	return Account.customer(customerId)
		.find(rb.build(), { includeExternal: true })
		.then(({ data, metadata }) => {
			return {
				step,
				data,
				total: metadata.total
			};
		});
};

export const getBoardData = getMore => async (dispatch, getState) => {
	const { boardOffset, boardData } = getState().MarketingBoard;

	if (getMore) {
		setTracking('Show more');
		dispatch(setBoardLoadingMore(true));
	} else {
		dispatch(setBoardLoading(true));
	}
	const newOffset = getMore ? boardOffset + BOARD_LIMIT : 0;
	dispatch(setBoardOffset(newOffset));

	const journeySteps = ['lead', 'mql', 'assigned', 'sql', 'pipeline', 'customer'];

	let hasMoreData = false;

	const res = await Promise.all(journeySteps.map(step => dispatch(getColumnData(step))));

	const newBoardData = res.reduce((res, colData) => {
		if (colData.total > colData.data.length + newOffset) {
			hasMoreData = true;
		}
		if (getMore) {
			res[colData.step] = {
				...colData,
				data: boardData[colData.step].data.concat(colData.data)
			};
		} else {
			res[colData.step] = colData;
		}
		return res;
	}, {});

	dispatch(setBoardData(newBoardData));

	dispatch(setBoardLoadingMore(false));
	dispatch(setBoardLoading(false));
	dispatch(setBoardHasMoreData(hasMoreData));
};

export const fetchFlows = () => async (dispatch, getState) => {
	try {
		const self = getState().App.self;
		const {
			metadata: { total }
		} = await Flow.find({ limit: 0, status: 'active', regBy: self.id, skipStats: true });
		const hasFlows = total === 0 ? false : true;
		dispatch(setHasFlows(hasFlows));
	} catch (error) {
		logError(error, 'Failed to fetch flows');
	}
};

export const init = () => async dispatch => {
	return Promise.all([dispatch(getStats()), dispatch(getBoardData()), dispatch(fetchFlows())]);
};
export const onDateFilterChange = (selectedDatePreset, from, to) => dispatch => {
	setTracking('Change date filter');
	dispatch(setSelectedDatePreset(selectedDatePreset));
	dispatch(setDateRange(from, to));

	return dispatch(getStats());
};

export const assign = client => (dispatch, getState) => {
	setTracking('Assign lead');
	const currentColumn = client.journeyStep;
	const $upModal = getAngularModule('$upModal');
	// eslint-disable-next-line promise/catch-or-return
	$upModal.open('processedBy', { client, from: 'listLeads' }).then(data => {
		const { boardData } = getState().MarketingBoard;
		if (currentColumn && boardData[currentColumn]) {
			const i = boardData[currentColumn].data.findIndex(c => {
				return c.id === client.id;
			});

			if (i !== -1) {
				const newCol = {
					...boardData[currentColumn],
					data: removeItem(boardData[currentColumn].data, i)
				};
				const updatedClient = {
					...client,
					journeyStep: 'assigned',
					processedBy: {
						user: data.user,
						entityType: 'Activity',
						id: data.activity,
						date: new Date(),
						time: null
					}
				};
				const assigned = {
					...boardData.assigned,
					data: [...boardData.assigned.data, updatedClient]
				};
				dispatch(setBoardData({ ...boardData, [currentColumn]: newCol, assigned }));
			}
		}
	});
};

const updateBoardItem = (status, client) => (dispatch, getState) => {
	const { boardData } = getState().MarketingBoard;

	const i = boardData[status].data.findIndex(c => {
		return c.id === client.id;
	});

	if (i === -1) {
		return;
	}

	const data = replaceItem(boardData[status].data, i, { ...boardData[status].data[i], ...client });

	dispatch(setBoardData({ ...boardData, [status]: { ...boardData[status], data } }));
};

const addExternal = (customerId, { id }) => {
	const Account = getAngularModule('Account');
	return Account.customer(customerId).save({ id, isExternal: false });
};

const addFromSoliditet = (customerId, clientId) => {
	const soliditetOptions = {
		updateExisting: false,
		skipProjects: false,
		skipAccountManagers: false,
		skipAddresses: false,
		skipCategories: false
	};

	const SoliditetClient = getAngularModule('SoliditetClient');
	return SoliditetClient.customer(customerId).refresh(clientId, [], soliditetOptions, {
		skipErrorNotification: true
	});
};

export const addClient = client => async dispatch => {
	const customerId = getAngularModule('AppService').getCustomerId();
	dispatch(updateBoardItem('lead', { id: client.id, $adding: true }));
	let promise;
	if (!client.dunsNo) {
		promise = addExternal(customerId, client);
	} else {
		promise = addFromSoliditet(customerId, client.id).catch(() => {
			addExternal(customerId, client);
		});
	}
	// eslint-disable-next-line promise/catch-or-return
	promise.finally(() => {
		dispatch(updateBoardItem('lead', { id: client.id, isExternal: false, $adding: false }));
	});

	return promise;
};

export const disqualifyClient = client => async (dispatch, getState) => {
	const { boardData } = getState().MarketingBoard;
	const customerId = getAngularModule('AppService').getCustomerId();
	const Account = getAngularModule('Account');
	dispatch(updateBoardItem('lead', { id: client.id, $disqualifying: true }));

	return Account.customer(customerId)
		.save({
			id: client.id,
			journeyStep: 'disqualified'
		})
		.then(() => {
			const i = boardData.lead.data.findIndex(c => {
				return c.id === client.id;
			});

			if (i !== -1) {
				const newCol = {
					...boardData.lead,
					data: removeItem(boardData.lead.data, i)
				};
				dispatch(setBoardData({ ...boardData, lead: newCol }));
			}
		})
		.catch(() => {
			dispatch(updateBoardItem('lead', { id: client.id, $disqualifying: false }));
		});
};

export function onFilterChange(filters) {
	setTracking('Change filter');
	return dispatch => {
		dispatch(setBoardFilters(filters));
		dispatch(getBoardData());
	};
}

export function setSelectedDatePreset(selectedDatePreset) {
	return { type: SET_SELECTED_DATE_PRESET, selectedDatePreset };
}
export function setDateRange(fromDate, toDate) {
	return { type: SET_DATE_RANGE, fromDate, toDate };
}
export function setBoardLoading(boardLoading) {
	return { type: SET_BOARD_LOADING, boardLoading };
}
export function setBoardLoadingMore(boardLoadingMore) {
	return { type: SET_BOARD_LOADING_MORE, boardLoadingMore };
}
export function setStatsLoading(statsLoading) {
	return { type: SET_STATS_LOADING, statsLoading };
}
export function setStats(stats) {
	return { type: SET_STATS, stats };
}
export function setBoardData(boardData) {
	return { type: SET_BOARD_DATA, boardData };
}
export function setBoardOffset(boardOffset) {
	return { type: SET_BOARD_OFFSET, boardOffset };
}
export function setBoardFilters(boardFilters) {
	return { type: SET_BOARD_FILTERS, boardFilters };
}
export function setBoardHasMoreData(boardHasMoreData) {
	return { type: SET_BOARD_HAS_MORE_DATA, boardHasMoreData };
}
export function setHasFlows(hasFlows) {
	return { type: SET_HAS_FLOWS, hasFlows };
}
export const reset = () => ({ type: RESET });

// For testing
export const _getDiff = getDiff;
export const _getPercent = getPercent;

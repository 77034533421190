import { globalTracker } from 'App/babel/helpers/Tracker';
import logError from 'App/babel/helpers/logError';
import MailIntegration from 'App/babel/resources/MailIntegration';
import moment from 'moment';

angular.module('domain.mail').controller('SendEmail', [
	'FileUploader',
	'$scope',
	'$upModal',
	'$modalParams',
	'$translate',
	'$stateParams',
	'$safeApply',
	'$q',
	'$parse',
	'NotificationService',
	'RequestBuilder',
	'Mail',
	'MailCampaign',
	'Contact',
	'Campaign',
	'API',
	'URL',
	'EditorCk',
	'ScriptService',
	'AppService',
	'MailTemplate',
	'InkyParser',
	'AccountProfile',
	'$filter',
	'FeatureHelper',
	function (
		FileUploader,
		$scope,
		$upModal,
		$modalParams,
		$translate,
		$stateParams,
		$safeApply,
		$q,
		$parse,
		NotificationService,
		RequestBuilder,
		Mail,
		MailCampaign,
		Contact,
		Campaign,
		API,
		URL,
		EditorCk,
		ScriptService,
		AppService,
		MailTemplate,
		InkyParser,
		AccountProfile,
		$filter,
		FeatureHelper
	) {
		var meta = $modalParams.meta;
		var customerId = AppService.getCustomerId();
		var prevent = true;
		var attachments = [];
		var sizeOfAllFiles = 0;
		var self;

		$scope.hasMultipleContacts = Array.isArray($modalParams.recipients);
		$scope.from = null;
		$scope.sendAndCreateActivity = false;
		$scope.hasRemoveActivities = FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');

		$scope.saveAstemplate = false;
		$scope.showTemplateNameField = false;
		$scope.showTemplateNameDropdwon = false;
		$scope.allTemplates = null;
		$scope.privateTemplates = document.createElement('button');
		$scope.publicTemplates = document.createElement('button');
		$scope.savingAsTemplate = false;
		$scope.mailIntegration = meta.mailIntegration;
		$scope.integrationProfile = meta.integrationProfile;

		$scope.shouldShowCC = false;
		$scope.shouldShowBCC = false;
		$scope.showCC = () => ($scope.shouldShowCC = true);
		$scope.showBCC = () => ($scope.shouldShowBCC = true);

		$scope.uploader = new FileUploader({
			url: URL + API + customerId + '/file/upload',
			withCredentials: true,
			onErrorItem: function (item) {
				item.cancel();
				item.remove();

				// update modal position
				$scope.reloadModalPosition();

				return $upModal.open('errorAlert', {
					title: 'default.error',
					body: 'mail.uploadFail',
					icon: 'fa-times',
					yes: 'default.close'
				});
			},

			onBeforeUploadItem: function () {},
			onSuccessItem: function (item, response) {
				if (response.error || !response.data) {
					item.cancel();
					item.remove();
					return $upModal.open('warningAlert', {
						title: 'mail.invalidFileType',
						body: 'mail.invalidFileTypeChose',
						icon: 'fa-times',
						yes: 'default.close'
					});
				}
				attachments.push({ value: response.data.id, filename: response.data.filename });
				// update modal position
				$scope.reloadModalPosition();
			},
			onAfterAddingFile: function (item) {
				if (item.file.size > 8388608 || sizeOfAllFiles + item.file.size > 8388608) {
					item.cancel();
					item.remove();

					$upModal.open('warningAlert', {
						title: $translate.instant('default.fileSizeTooLarge'),
						//body: $translate.instant('mail.unsubscribeLink') + ' <small>{{UNSUB}}</small>'
						body: $translate.instant('default.fileLimit')
					});
				} else {
					sizeOfAllFiles += item.file.size;
					item.upload();
					globalTracker.track('File attachment email');
				}
			},

			onCompleteAll: function () {},
			onProgressItem: function () {}
		});

		$scope.$on('modal.rejected', function (e, arg) {
			if ($scope.saveAstemplate) {
				return;
			}

			if (arg === 'delete') {
				e.preventDefault();
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'default.delete',
						body: 'confirm.mailWillBeDeleted',
						resolveTrue: 'default.delete',
						icon: 'fa-warning'
					})
					.then(function () {
						$scope.$root.$broadcast('listView.refresh');
						prevent = false;
						$scope.reject();
					});
				return;
			}

			if (
				prevent &&
				(($scope.mail.subject && $scope.mail.subject.length) || ($scope.mail.body && $scope.mail.body.length))
			) {
				e.preventDefault();
				if ($scope.mail.status !== 'DEL_PROJECT' && ($modalParams.type === 'campaign' || meta.campaign)) {
					var opts = {
						title: $translate.instant('confirm.abortMail') + '?',
						body: 'confirm.changesWillBeLost',
						resolveFalse: 'default.abortEdit',
						resolveFalseBtnClass: 'btn-orange',
						no: 'mail.returnToMail',
						icon: 'fa-warning'
					};

					if (!$scope.MailForm.$invalid) {
						opts.title = 'confirm.doYouWantToSave';
						opts.body = 'confirm.saveDraft';
						opts.resolveTrue = 'mail.saveAsDraft';
						opts.resolveTrueBtnClass = 'btn-green btn-lined';
					}

					// eslint-disable-next-line promise/catch-or-return
					$upModal.open('warningConfirm', opts).then(function (saveAsDraft) {
						if (saveAsDraft) {
							saveDraft();
						} else {
							if ($scope.originalMail.status !== 'DRAFT' && meta.edit) {
								$scope.resolve();
							} else {
								prevent = false;
								$scope.reject();
							}
						}
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: $translate.instant('confirm.abortMail') + '?',
							body: 'confirm.changesWillBeLost',
							resolveTrue: 'confirm.abortMail',
							no: 'mail.returnToMail',
							icon: 'fa-warning'
						})
						.then(function () {
							$scope.$root.$broadcast('listView.refresh');
							prevent = false;
							$scope.reject();
						});
				}
			}
		});

		$scope.templateChange = function () {
			if ($scope.template && typeof $scope.template === 'object') {
				if ($scope.templateModified) {
					$upModal
						.open('warningConfirm', {
							title: 'mail.changeTemplateConfirm',
							body: 'mail.changeTemplateConfirmBody',
							resolveTrue: 'default.yes',
							no: 'default.no',
							icon: 'fa-warning'
						})
						.then(replaceContent)
						.catch(function () {
							$scope.template = $scope.currentTemplate;
						});
				} else {
					replaceContent();
				}
			}
		};

		$scope.mailContentChange = function () {
			if ($scope.MailForm && $scope.MailForm.field_subject) {
				if (
					$scope.MailForm.field_subject.$modelValue &&
					$scope.MailForm.field_subject.$modelValue.replace(
						// eslint-disable-next-line no-control-regex,no-useless-escape
						/[^\x20-\xFF\x0A\™\€\u3040-\u319f\uff00-\uffef]/g,
						''
					) !== ''
				) {
					// eslint-disable-line
					$scope.MailForm.field_subject.$setValidity('Invalid string', true);
				} else {
					$scope.MailForm.field_subject.$setValidity('Invalid string', false);
				}
			}

			if ($scope.template) {
				if ($scope.template.$loaded) {
					$scope.template.$loaded = false;
					$safeApply($scope);
					return;
				}
				$scope.templateModified = true;
				$scope.template.changed = true;
			}
			$safeApply($scope);
		};

		$scope.preview = function () {
			var attachmentsCopy = angular.copy(attachments);

			var mail = angular.copy($scope.mail);
			if (!mail.attachments) {
				mail.attachments = [];
			}
			attachmentsCopy.push(mail.attachments);
			mail.attachments = _.flatten(attachmentsCopy);

			if (typeof mail.from === 'object' && mail.from.email) {
				mail.from = mail.from.email;
				mail.fromName = mail.from.fromName;
			}

			// Set to mailaccount mail and name if default
			if (mail.from === '{{General.CompanyEmail}}') {
				mail.from = $scope.mailAccount.defaultEmail;
				mail.fromName = $scope.mailAccount.defaultFrom;
			}

			mail.template = $scope.template;
			var params = {
				customerId: $modalParams.customerId,
				mailIntegration: $scope.mailIntegration,
				contact: meta.contact,
				mail: mail,
				userId: self.id
			};

			$upModal.open('previewInfo', params);
		};

		$scope.fromChange = function (from) {
			if ($scope.onlyFromSelf) {
				$scope.from = _.find(meta.users.data, { id: self.id });
				$scope.mail.from = $scope.from.email;
				$scope.mail.fromName = $scope.from.name;
				return;
			}
			if (typeof from !== 'object') {
				var value = _.find($scope.fromSelect.data, { email: from });
				if (!value) {
					value = _.find(meta.users.data, { email: from });
				}

				if (value) {
					from = value;
				} else {
					from = _.find(meta.users.data, { id: self.id });
				}
			}
			$scope.from = from;

			$scope.mail.from = $scope.from.email;
			$scope.mail.fromName = $scope.from.fromName;
		};

		$scope.confirmSend = function (scheduled) {
			$scope.mail.isDraft = false;
			if (
				($modalParams.type === 'campaign' || (!meta.contact && $modalParams.type !== 'mail')) &&
				!~$scope.mail.body.indexOf('{{UNSUB}}')
			) {
				$upModal.open('warningAlert', {
					title: 'mail.mailRequires',
					body: $translate.instant('mail.unsubscribeLink') + ' <small>{{UNSUB}}</small>',
					icon: 'fa-warning'
				});
				return;
			}

			if (!$scope.mail.attachments) {
				$scope.mail.attachments = [];
			}
			attachments.push($scope.mail.attachments);

			$scope.mail.attachments = _.flatten(attachments);

			if (!scheduled) {
				delete $scope.mail.sendDate;
			}

			var recv = $parse('meta.campaignContacts.metadata.total')($modalParams);

			if (recv === undefined) {
				save(scheduled);
				return;
			}
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('startMailCampaign', {
					mail: $scope.mail,
					recievers: $modalParams.meta.campaignContacts.metadata.total
				})
				.then(function () {
					save(scheduled);
				});
		};

		$scope.removeItem = function (item, index) {
			attachments.splice(index, 1);
			item.remove();

			// update modal position
			setTimeout(function () {
				$scope.reloadModalPosition();
			}, 10);
		};

		$scope.removeAttachment = function (index) {
			$scope.mail.attachments.splice(index, 1);
			$scope.attachmentCopy.splice(index, 1);

			// update modal position
			setTimeout(function () {
				$scope.reloadModalPosition();
			}, 10);
		};

		$scope.formatRecipientsTooltip = function () {
			return $modalParams.recipients
				.slice(1)
				.map(contact => contact.email)
				.join('\n');
		};

		function saveDraft() {
			if (!$scope.mail.attachments) {
				$scope.mail.attachments = [];
			}
			attachments.push($scope.mail.attachments);

			$scope.mail.attachments = _.flatten(attachments);

			$scope.mail.isDraft = true;
			save();
		}

		function save(scheduled) {
			$scope.sending = true;

			$scope.fromChange($scope.from);

			var mail = angular.copy($scope.mail);

			if (!mail.isDraft) {
				mail.isDraft = false;
			}

			if (!scheduled && mail.sendDate) {
				mail.sendDate = undefined;
			}

			//Creating user
			mail.user = { id: self.id, name: self.name };

			if (meta.contact) {
				var contact = meta.contact.data;
				mail.to = contact.email;
				mail.date = mail.date || new Date();
				mail.contact = contact.id;
				mail.client = contact.client.id;
			}

			if (mail.contact) {
				// Fix attachments type = Template with missing contactId
				angular.forEach(mail.attachments, function (attachment) {
					if (attachment.type === 'Template') {
						var attachmentValues = attachment.value.split('&');
						attachmentValues = attachmentValues.map(function (val) {
							if (val.indexOf('contactId=') !== -1) {
								return null;
							}
							return val;
						});

						attachmentValues = _.compact(attachmentValues);
						attachmentValues.push('contactId=' + mail.contact);
						attachmentValues = _.uniq(attachmentValues);
						attachment.value = attachmentValues.join('&');
					}
				});
			}
			if ($scope.hasMultipleContacts) {
				mail.date = mail.date || new Date();
				mail.recipients = _.map($modalParams.recipients, contact => ({
					client: contact.client.id,
					contact: contact.id,
					to: contact.email
				}));
			}

			if (meta.copy && !scheduled) {
				mail.date = new Date();
			}

			mail.template = $scope.template || 0;

			if (meta.copy || $scope.mail.contact || $modalParams.isEdit) {
				delete mail.id;
			}

			// If MailCampaign
			if ($modalParams.type !== 'mail' && !mail.isDraft && !mail.isArchived) {
				mail.doSend = true;
				mail.sendDate = mail.sendDate ? mail.sendDate : new Date();
			}

			// Set to mailaccount mail and name if default

			var resource = meta.contact || $modalParams.type === 'mail' ? Mail : MailCampaign;
			mail.cc = _.map(mail.cc, function (value) {
				if (typeof value === 'string') {
					return value;
				} else {
					return value.email;
				}
			});
			mail.cc = _.uniq(_.compact(mail.cc));
			mail.bcc = _.map(mail.bcc, function (value) {
				if (typeof value === 'string') {
					return value;
				} else {
					return value.email;
				}
			});
			mail.bcc = _.uniq(_.compact(mail.bcc));

			const method = $scope.hasMultipleContacts ? 'saveMulti' : 'save';

			const promise = $scope.mailIntegration
				? MailIntegration[method](mail, {
						params: { integrationId: $scope.mailIntegration.id },
						skipEvent: true
				  })
				: resource.customer($modalParams.customerId)[method](mail, { skipEvent: true });

			promise
				.then(function (res) {
					// Trigger event
					if ($scope.hasMultipleContacts) {
						for (const mail of res.data) {
							$scope.$root.$broadcast('mail.added', mail);
						}
					} else {
						var eventType = $scope.mail.id ? 'updated' : 'added';
						var eventMail = res.data;
						if ($scope.mail.id !== res.data.id) {
							eventMail.$oldId = $scope.mail.id;
						}
						if (meta.contact || $modalParams.type === 'mail') {
							$scope.$root.$broadcast('mail.' + eventType, res.data);
						} else {
							$scope.$root.$broadcast('mailCampaign.' + eventType, res.data);
						}
					}

					// Close modal
					if ($scope.fromActivityOutcome) {
						$scope.resolve({ ...res.data, postponeDate: $scope.postponeDate });
					} else {
						$scope.resolve(res);
					}

					// Open activity model
					if ($scope.sendAndCreateActivity) {
						$upModal.open('editActivity', {
							activity: {
								contacts: { id: mail.contact },
								description: $filter('translate')('mail.followUp') + ': ' + eventMail.subject
							}
						});
					}
				})
				.catch(function (error) {
					console.error(error.stack);
					$scope.sending = false;
				});
		}

		function replaceContent() {
			// Check for id
			if (!$scope.template || !$scope.template.id) {
				return NotificationService.addNotification({
					title: 'default.error',
					body: 'error.generateTemplate',
					icon: 'times',
					style: 'error'
				});
			}

			MailTemplate.customer($stateParams.customerId)
				.get($scope.template.id)
				.then(function (res) {
					$scope.template = res.data;

					if (!res || !res.data) {
						return NotificationService.addNotification({
							title: 'default.error',
							body: 'error.generateTemplate',
							icon: 'times',
							style: 'error'
						});
					}
					if (res.data.bodyJson) {
						var bodyJson = res.data.bodyJson;
						InkyParser.getCss(false)
							.then(function (res) {
								var style = res.data;

								AccountProfile.get()
									.then(function (res) {
										var profile = res.data;
										InkyParser.parse(bodyJson, {
											extraCss: style,
											profile: profile
										})
											.then(function (res) {
												$scope.mail.body = res.data;
											})
											.catch(function (error) {
												logError(error, 'Inkparser parse error', error);
											});
									})
									.catch(function (error) {
										logError(error, 'Get account profile error', error);
									});
							})
							.catch(function (error) {
								logError(error, 'Inkparser get css error', error);
							});
					} else {
						$scope.mail.body = $scope.template.body || $scope.standardMailSignature?.body || '';
					}

					$scope.mail.subject = $scope.template.subject || '';

					if (!$scope.onlyFromSelf && !$scope.mailIntegration) {
						$scope.mail.from = $scope.template.from;
						$scope.mail.fromName = $scope.template.fromName;
						var foundTag = _.find($scope.fromSelect.data, { email: $scope.template.from });
						if (foundTag) {
							$scope.from = foundTag;
						} else {
							var foundUser = _.find($scope.fromSelect.data[$scope.fromSelect.data.length - 1].children, {
								email: $scope.template.from
							});
							if (foundUser) {
								$scope.from = foundUser;
							}
						}
					}

					var attachments = [];
					if ($modalParams.attachments && $modalParams.attachments.length) {
						attachments = $modalParams.attachments;
					}

					$scope.currentTemplate = $scope.template;
					if ($scope.template.attachments && $scope.template.attachments.length) {
						attachments = attachments.concat($scope.template.attachments);
					}

					$scope.mail.attachments = attachments;
					$scope.attachmentCopy = angular.copy(attachments);

					$scope.templateModified = false;
					$scope.template.changed = false;
					$scope.template.$loaded = true;

					// eslint-disable-next-line no-undef
					setImmediate(() => {
						$scope.mailContentChange();
					});
				})
				.catch(function () {
					return NotificationService.addNotification({
						title: 'default.error',
						body: 'error.generateTemplate',
						icon: 'times',
						style: 'error'
					});
				});
		}

		var getStandardSignature = function (res) {
			if (!res || !res.data) {
				return;
			}
			const signature = res.data;
			const startDate = signature.startDate;
			const endDate = signature.endDate;

			if (startDate) {
				if (endDate) {
					if (moment().isBefore(startDate) || moment().isAfter(endDate)) {
						return;
					}
				} else {
					if (moment().isBefore(startDate)) {
						return;
					}
				}
			}

			let body = signature.body;
			body = body.replace('{{General.CurrentUserName}}', self.name);
			body = body.replace('{{General.CurrentUserTitle}}', self.userTitle || '');
			body = body.replace('{{General.CurrentUserCellPhone}}', self.userCellPhone || '');
			body = body.replace('{{General.CurrentUserEmail}}', self.email);
			body = body.replace('{{General.CurrentUserPhone}}', self.userPhone);
			body = body.replace('{{General.CompanyName}}', $scope.mailAccount ? $scope.mailAccount.name : '');
			body = body.replace('{{General.CompanyAddress}}', $scope.mailAccount ? $scope.mailAccount.address : '');
			body = body.replace('{{General.CompanyZipCode}}', $scope.mailAccount ? $scope.mailAccount.zipCode : '');
			body = body.replace('{{General.CompanyCity}}', $scope.mailAccount ? $scope.mailAccount.city : '');
			body = body.replace('{{General.CompanyCountry}}', $scope.mailAccount ? $scope.mailAccount.country : '');

			body = body.replace(
				'<figure class="image">',
				'<figure class="image" style="background-color: transparent; border: none; padding: 0; margin: 1em 0;">'
			);
			body = body.replace(/<p>/g, '<p style="margin: 0 0 10px 0;">');
			body = body.replace(/<p class="small">/g, '<p class="small" style="margin: 0 0 10px 0;">');
			body = body.replace(/<h3>/g, '<h3 style="margin: 0 0 10px 0;"><span style="font-size:24px;">');
			body = body.replace(/<\/h3>/g, '</span></h3>');

			$scope.mail.body =
				($scope.mail.body ? $scope.mail.body + '<p class="small" style="margin: 0 0 10px 0;"></p>' : '') + body;
			signature.body = body;
			$scope.standardMailSignature = signature;
		};

		$scope.schedule = function () {
			let reciver = '';
			if ($modalParams.meta.campaign) {
				reciver = $scope.mail.project.name;
			} else if ($scope.hasMultipleContacts) {
				const contactCount = $scope.$modalParams.recipients.length;
				reciver = `${contactCount} ${(contactCount === 1
					? $translate.instant('default.contact')
					: $translate.instant('default.contacts')
				).toLowerCase()}`;
			} else {
				reciver = $modalParams.meta.contact.data.name;
			}

			var opts = {
				reciver: reciver,
				subject: $scope.mail.subject
			};

			if ($scope.mail.status === 'SCHEDULED') {
				opts.date = $scope.mail.sendDate;
			} else {
				opts.date = $scope.mail.date;
			}
			// eslint-disable-next-line promise/catch-or-return
			$upModal.open('scheduleMail', opts).then(function (date) {
				if ($modalParams.meta.campaign) {
					$scope.mail.sendDate = date;
				} else {
					$scope.mail.type = 'sch';
					$scope.mail.date = date;
				}
				$scope.confirmSend(true);
			});
		};

		var fromFormat = function (obj, container, escape) {
			if (obj.$isTag && (obj.email === null || obj.email === '{{General.CompanyEmail}}')) {
				return (
					escape($scope.mailAccount.defaultFrom) +
					'<i style="float:right;">[' +
					escape($scope.mailAccount.defaultEmail) +
					']</i>'
				);
			}
			return (
				escape(obj.name) +
				(escape(obj.email) && !obj.$isTag ? ' <i style="float:right;">[' + escape(obj.email) + ']</i>' : '')
			);
		};

		var init = function () {
			self = AppService.getSelf();
			$scope.mail = {
				type: 'out',
				body: $modalParams.body || '',
				subject: $modalParams.subject || ''
			};

			if (meta.campaign) {
				$scope.mail.project = { id: meta.campaign.data.id, name: meta.campaign.data.name };
				$scope.mail.isDraft = false;
			}

			var extraCKEditorOptions = {
				height: '400px',
				fileEntity: 'mail',
				extraPlugins: 'lineheight'
			};

			if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE)) {
				extraCKEditorOptions.mailSignatures = meta.mailsignatures.data;

				if (!meta.copy) {
					var defaultSignature = _.find(meta.mailsignatures.data, function (sig) {
						return sig.id === Tools.AppService.getSelf().userParams.defaultSignature;
					});

					getStandardSignature({ data: defaultSignature });
				}
			}

			// CKeditor options
			$scope.editorOptions = EditorCk.getOptions(extraCKEditorOptions);
			$scope.editorOptions.forceSimpleAmpersand = true;
			$scope.editorOptions.enterMode = CKEDITOR.ENTER_BR;
			$scope.editorOptions.shiftEnterMode = CKEDITOR.ENTER_BR;
			$scope.editorOptions.removePlugins = 'image';

			$scope.templateModified = false;

			$scope.mailAccount = meta.mailAccount;
			$scope.onlyFromSelf = !self.administrator && !self.userParams.mailAdmin;
			$scope.self = self;

			$scope.currentTemplate = $scope.template;
			if ($scope.template) {
				meta.templates.data.push($scope.template);
			}

			var privateTemplates = [];
			var publicTemplates = _.filter(meta.templates.data, function (template) {
				if (template.private) {
					privateTemplates.push(template);
					return false;
				}
				return true;
			});

			var templates = [];
			if (privateTemplates.length) {
				templates.push({ name: $translate.instant('mail.privateTemplates'), children: privateTemplates });
			}
			templates.push({ name: $translate.instant('mail.publicTemplates'), children: publicTemplates });

			$scope.allTemplates = templates;

			$scope.templateSelect = {
				data: templates,
				formatSelection: function (template, elm, encode) {
					if (template.changed) {
						return encode(template.name) + ' <i><b>(' + $translate.instant('default.changed') + ')</b></i>';
					}
					return encode(template.name);
				},
				formatResult: function (template, elm, x, encode) {
					var sub = template.subject ? ' (' + encode(template.subject) + ')' : '';
					return encode(template.name) + sub;
				},
				matcher: function (term, shit, template) {
					return template.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				allowClear: true
			};

			var accountManagerOption = {
				name: $translate.instant('default.accountManager'),
				email: '{{Client.UserEmail}}',
				fromName: '{{Client.UserName}}',
				$isTag: true
			};

			var defaultSender = {
				email: '{{General.CompanyEmail}}',
				fromName: '{{General.CompanySender}}',
				name: $translate.instant('mail.default'),
				$isTag: true
			};

			var signedInSender = {
				name: $translate.instant('default.signedInUser'),
				email: '{{General.CurrentUserEmail}}',
				fromName: '{{General.CurrentUserName}}',
				$isTag: true
			};

			var users = meta.users.data;

			angular.forEach(users, function (user) {
				user.fromName = user.name;
			});
			// fix name of account manager here later
			if (meta.contact) {
				// accountManagerOption.name += ' '+meta.contact.data.client.users[0].name;
				$scope.mail.to = meta.contact.data.email;
			}
			$scope.fromSelect = {
				formatSelection: fromFormat,
				formatResult: (obj, container, query, escape) => fromFormat(obj, container, escape),
				id: 'email',
				data: [
					accountManagerOption,
					signedInSender,
					defaultSender,
					{
						name: $translate.instant('default.users'),
						children: users
					}
				],
				matcher: function (term, sumthing, object) {
					return (
						object.name.toUpperCase().indexOf(term.toUpperCase()) >= 0 ||
						(object.email && object.email.toUpperCase().indexOf(term.toUpperCase()) >= 0)
					);
				}
			};

			if (meta.copy || meta.edit) {
				$scope.mail = meta.copy ? meta.copy.data : meta.edit.data;
				$scope.mail.type = 'out';
				$scope.template = $scope.mail.template;

				if (meta.copy) {
					delete $scope.mail.date;
					delete $scope.mail.sendDate;
				}
				if ($scope.mail.from && !$scope.mailIntegration) {
					$scope.from = $scope.mail.from;
				}

				// fix previous cc if this is an edit
				if (meta.edit && $scope.mail.cc && $scope.mail.cc.length) {
					$scope.mail.cc = _.map($scope.mail.cc, function (email) {
						if (typeof email === 'string') {
							return { name: email, email: email };
						}
						return email;
					});
				}
			} else {
				if ($scope.template) {
					replaceContent();
				}
			}

			if ($scope.mailIntegration) {
				$scope.from = {
					email: $scope.integrationProfile.email,
					fromName: $scope.integrationProfile.name || ''
				};
			}
			if (!$scope.from) {
				$scope.from = _.find(meta.users.data, { id: self.id });
			}

			$scope.fromChange($scope.from);

			if ($modalParams.attachments) {
				$scope.mail.attachments = $modalParams.attachments;
			}

			$scope.attachmentCopy = angular.copy($scope.mail.attachments);
			$scope.originalMail = angular.copy($scope.mail);

			$scope.hadReciver =
				$modalParams.email ||
				(($modalParams.contactId || $scope.mail.to) && !meta.contacts) ||
				$modalParams.campaignId;

			if ($modalParams.type === 'mail' || $modalParams.type === 'email') {
				ScriptService.mail.init($scope.mail);
			}

			setTimeout(function () {
				$('#field_template_original').select2('open');

				const templatesww = [];
				$scope.allTemplates.forEach(element => {
					if (element.name === $translate.instant('mail.privateTemplates')) {
						templatesww.push(element);
					}
				});

				$scope.setTemplateDropdown(templatesww, true);

				const privateTemplatesText = document.createTextNode($translate.instant('mail.privateTemplates'));
				const publicTemplatesText = document.createTextNode($translate.instant('mail.publicTemplates'));

				$scope.privateTemplates.appendChild(privateTemplatesText);
				$scope.publicTemplates.appendChild(publicTemplatesText);

				$scope.publicTemplates.setAttribute('class', 'template-list-btn-public');
				$scope.publicTemplates.setAttribute('id', 'publicTemplates');
				$scope.privateTemplates.setAttribute('class', 'template-list-btn-private template-list-btn-active');
				$scope.privateTemplates.setAttribute('id', 'privateTemplates');

				$('#select2-drop').prepend($scope.publicTemplates);
				$('#select2-drop').prepend($scope.privateTemplates);
				$('#field_template_original').select2('close');
			}, 100);

			$scope.fromActivityOutcome =
				FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES) && $modalParams.fromActivity;
			$scope.postponeDate = $scope.fromActivityOutcome;
			$scope.isWeekend = [5, 6].includes(moment().get('day')); // 5 - Friday, 6 - Saturday;
		};

		$scope.select2to = {
			minimumInputLength: 2,
			quietMillis: 100,
			initSelection: _.noop,
			ajax: {
				data: function (term) {
					return term;
				},
				results: function (res) {
					return { results: res.data };
				}
			}
		};

		if ($modalParams.type === 'mail') {
			if ($modalParams.contacts) {
				delete $scope.select2to.ajax;
				delete $scope.select2to.quietMillis;
				delete $scope.select2to.initSelection;
				$scope.select2to.minimumInputLength = -1;
				$scope.select2to.data = $modalParams.contacts;
				$scope.select2to.matcher = function (term, undef, contact) {
					return (
						contact.name.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
						(contact.email && contact.email.toLowerCase().indexOf(term.toLowerCase()) !== -1)
					);
				};
			} else {
				$scope.select2to.ajax.transport = function (query) {
					if (query.data) {
						var filter = new RequestBuilder();
						filter.addFilter(Contact.attr.email, filter.comparisonTypes.NotEquals, null);
						filter.addFilter(Contact.attr.active, filter.comparisonTypes.Equals, true);

						var or = filter.orBuilder();
						or.next();
						or.addFilter(Contact.attr.name, filter.comparisonTypes.Wildcard, query.data);
						or.next();
						or.addFilter(Contact.attr.email, filter.comparisonTypes.Wildcard, query.data);
						or.done();

						filter.limit = 10;

						return Contact.customer($stateParams.customerId)
							.find(filter.build())
							.then(function (res) {
								res.data.forEach(function (item) {
									if (!item.email) {
										delete item.id;
										item.email = '';
									}
								});
								query.success(res);
							});
					}
					return $q.when({ data: [] });
				};
			}

			$scope.select2to.formatResult = function (obj, elm, x, encode) {
				return encode(obj.name) + '<i class="pull-right">' + encode(obj.email) + '</i>';
			};

			$scope.select2to.formatSelection = function (obj) {
				return _.escape(obj.name);
			};
		} else {
			$scope.select2to.ajax.transport = function (query) {
				if (query.data) {
					var filter = new RequestBuilder();
					filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Wildcard, query.data);
					filter.addFilter(Campaign.attr.active, filter.comparisonTypes.Equals, true);
					filter.limit = 10;

					return Campaign.customer($stateParams.customerId)
						.find(filter.build())
						.then(function (res) {
							query.success(res);
						});
				}
				return $q.when({ data: [] });
			};

			$scope.select2to.formatResult = function (obj, elm, x, encode) {
				return encode(obj.name);
			};

			$scope.select2to.formatSelection = function (obj) {
				if (obj.id) {
					var filter = { limit: 0 };
					filter['project.id'] = obj.id;
					Contact.customer($stateParams.customerId)
						.find(filter)
						.then(function (res) {
							$modalParams.meta.campaignContacts = res;
						})
						.catch(function (error) {
							logError(error, 'Get customer contact error');
						});
					meta.campaign = obj;
				}
				return _.escape(obj.name);
			};
		}

		/**
		 * Show template name input field
		 */
		$scope.activateTemplateName = function () {
			var mail = angular.copy($scope.mail);
			$scope.showTemplateNameDropdwon = false;
			if (mail.body) {
				$scope.showTemplateNameField = true;
				setTimeout(function () {
					angular.element('#template_name_for_save').focus();
				}, 200);
			} else {
				$scope.showTemplateNameField = false;
			}
		};

		/**
		 * Save template
		 */
		$scope.saveAsTemplate = function () {
			$scope.savingAsTemplate = true;
			var mail = angular.copy($scope.mail);

			if ($scope.template) {
				mail.id = $scope.template.id;
				mail.name = mail.name ? mail.name : $scope.template.name;
				mail.subject = mail.subject ? mail.subject : $scope.template.subject;
				mail.body = mail.body ? mail.body : $scope.template.body;
				mail.private = mail.private ? mail.private : $scope.template.private;
			} else {
				mail.private = true;
			}

			if (mail.name && mail.body && mail.subject) {
				MailTemplate.customer($stateParams.customerId)
					.save(mail)
					.then(function (res) {
						$scope.savingAsTemplate = false;
						const template = res.data;
						const addedTemplate = {
							id: template.id,
							name: template.name,
							subject: template.subject,
							private: template.private,
							userRemovable: template.userRemovable,
							userEditable: template.userEditable,
							$$thumbnailUrl: template.$$thumbnailUrl
						};
						if ($scope.template) {
							$scope.templateSelect.data[0].children.forEach(templateItem => {
								if (templateItem.id === template.id) {
									templateItem.name = template.name;
									templateItem.subject = template.subject;
									templateItem.body = template.body;
								}
							});
						} else {
							if ($scope.templateSelect.data.length > 0) {
								if (
									$scope.templateSelect.data.some(
										templateTypes =>
											templateTypes.name === $translate.instant('mail.privateTemplates')
									)
								) {
									$scope.templateSelect.data[0].children.push(addedTemplate);
								} else {
									const firstChild = [addedTemplate];
									const templates = {
										name: $translate.instant('mail.privateTemplates'),
										children: firstChild
									};
									const tempPublicTemplates = $scope.templateSelect.data[0];
									$scope.templateSelect.data = [];
									$scope.templateSelect.data.push(templates);
									$scope.templateSelect.data.push(tempPublicTemplates);
									$scope.setTemplateDropdown($scope.templateSelect.data, false);
								}
							}
							$scope.template = template;
						}
						$scope.templateModified = false;
						$scope.saveAstemplate = true;
						$scope.showTemplateNameField = false;
						$scope.showTemplateNameDropdwon = false;
					})
					.catch(function (error) {
						logError(error, 'Save mail template error');
					});
			} else {
				if (!mail.subject) {
					angular.element('#field_subject').focus();
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.WARN,
						icon: 'warning',
						title: 'default.error',
						body: `${Tools.$translate('default.youHaveFormErrorsMissing')} ${Tools.$translate(
							'mail.subject'
						).toLowerCase()}`
					});
				}
				$scope.savingAsTemplate = false;
			}
		};

		/**
		 * Cancel save as template
		 */
		$scope.cancelTemplateNameSave = function () {
			$scope.mail.name = null;
			$scope.showTemplateNameField = false;
		};

		/**
		 * private templates onclick listner to filter dropdown options
		 */
		$scope.privateTemplates.addEventListener('click', function (e) {
			document.getElementById('publicTemplates').classList.remove('template-list-btn-active');
			document.getElementById('privateTemplates').classList.add('template-list-btn-active');
			e.preventDefault();
			const templates = [];
			$scope.allTemplates.forEach(element => {
				if (element.name === $translate.instant('mail.privateTemplates')) {
					templates.push(element);
				}
			});

			$scope.setTemplateDropdown(templates, true);
		});

		/**
		 * public templates onclick listner to filter dropdown options
		 */
		$scope.publicTemplates.addEventListener('click', function () {
			document.getElementById('privateTemplates').classList.remove('template-list-btn-active');
			document.getElementById('publicTemplates').classList.add('template-list-btn-active');
			const templates = [];
			$scope.allTemplates.forEach(element => {
				if (element.name === $translate.instant('mail.publicTemplates')) {
					templates.push(element);
				}
			});

			$scope.setTemplateDropdown(templates, true);
		});

		/**
		 * Set select2 dropdown options
		 * @params {templates - dropdown options, shouldOpenSelect2 - boolean to open dropdwon or not}
		 */
		$scope.setTemplateDropdown = function (templates, shouldOpenSelect2) {
			$scope.templateSelect = {
				data: templates,
				formatSelection: function (template, elm, encode) {
					if (template.changed) {
						return encode(template.name) + ' <i><b>(' + $translate.instant('default.changed') + ')</b></i>';
					}
					return encode(template.name);
				},
				formatResult: function (template, elm, x, encode) {
					var sub = template.subject ? ' (' + encode(template.subject) + ')' : '';
					return encode(template.name) + sub;
				},
				matcher: function (term, shit, template) {
					return template.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				allowClear: true
			};
			if (shouldOpenSelect2) {
				$('#field_template_original').select2($scope.templateSelect);
				$('#field_template_original').select2('open');
				$('#select2-drop').prepend($scope.publicTemplates);
				$('#select2-drop').prepend($scope.privateTemplates);
			}
		};

		$scope.togglePostpone = function () {
			$scope.postponeDate = !$scope.postponeDate;
		};

		// Change form submit type so that activity model can be opened
		$scope.activateCreateActivity = function () {
			$scope.sendAndCreateActivity = true;
		};
		init();
	}
]);

import React from 'react';
import { Card, Row, Column } from '@upsales/components';
import t from 'Components/Helpers/translate';
import StepDrawer from '../StepDrawer';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import StepContactTable from '../Components/StepContactTable';
import Flow from 'App/resources/Model/Flow';

type Props = {
	flow: Flow;
	closePopup: () => void;
};

export default function End(props: Props) {
	const stats = {
		reachedGoal: props.flow.reachedGoal,
		hasLoopedStep: props.flow.hasLoopedFlow,
		currentlyInStep: props.flow.completedContactCountInSegment,
		fallenOutOfStep: props.flow.completedContactCountNotInSegment
	};
	const hasGoal = !!props.flow.endCriterias.find(c => c.isGoal);
	const types = ['currentlyInStep', 'fallenOutOfStep'];
	if (hasGoal) {
		types.push('reachedGoal');
	}

	return (
		<StepDrawer
			{...props}
			title={t('flow.finishedTitle')}
			color="black"
			icon="flag-checkered"
			closePopup={props.closePopup}
		>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<Row>
					<Column>
						<DrawerInfoTitle total={props.flow.completedContactCount} title={'flow.finishedInfoTitle'} />
					</Column>
					{hasGoal ? (
						<Column>
							<DrawerInfoTitle
								total={props.flow.reachedGoal}
								title="flow.contactsReachedGoal"
								subtitle="flow.goal.bookedAppointment"
							/>
						</Column>
					) : null}
				</Row>
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={'end'}
				segmentId={props.flow.segmentId ?? null}
				stats={stats}
				types={types}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Icon } from '@upsales/components';
import OutcomeTypeButton from 'Components/Activity/ActivityOutcomes/OutcomeTypeButton';
import { outcomeTypes, getButtonMainText, getOutcomeStatus } from '../Helpers';

const ChooseOutcome = props => {
	const { outcome, answerOutcome, onClick, saving } = props;
	const { outcomeCompleted, outcomeNotCompleted } = getOutcomeStatus(outcome);

	const buttons = [
		{
			code: outcomeTypes.NOTCOMPLETED,
			mainText: getButtonMainText('appointment.outcome.wasNotCompleted', 'calendar-times-o', 'red', true),
			onClick: () => {
				if (!outcomeNotCompleted) {
					answerOutcome(false);
				}
				onClick();
			}
		},
		{
			code: outcomeTypes.COMPLETED,
			mainText: getButtonMainText('appointment.outcome.wasCompleted', 'calendar-check-o', 'medium-green', true),
			onClick: () => {
				if (!outcomeCompleted) {
					answerOutcome(true);
				}
				onClick();
			}
		}
	];

	return (
		<Card>
			<CardHeader title={Tools.$translate('appointment.outcome.whatResult')}>
				<ReactTemplates.elevio articleId={970} sidebar>
					<Icon name="info-circle" />
				</ReactTemplates.elevio>
			</CardHeader>

			<CardContent>
				{buttons.map(({ code, mainText, onClick }) => (
					<OutcomeTypeButton
						key={code}
						mainText={mainText}
						activeText={mainText}
						onClick={onClick}
						disabled={saving}
					/>
				))}
			</CardContent>
		</Card>
	);
};

ChooseOutcome.propTypes = {
	outcome: PropTypes.string,
	answerOutcome: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	saving: PropTypes.bool
};

export default ChooseOutcome;

'use strict';

import openModal from 'App/services/Modal';
import DynamicLinks from 'Components/Admin/DynamicLinks';
import { setSidebarLinks } from 'Store/actions/AppActions';
import Store from 'Store';

angular.module('domain.admin').controller('DynamicLinksCtrl', [
	'$scope',
	'$upModal',
	'Links',
	'$safeApply',
	'$translate',
	'AppService',
	'FeatureHelper',
	function ($scope, $upModal, Links, $safeApply, $translate, AppService, FeatureHelper) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = DynamicLinks;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			links: []
		};

		const getSidebarLinks = () => {
			return AdminSubCtrl.rootData.pageData.links.filter(link => link.entity === 'sidebar');
		};

		$scope.$on('dynamiclink.added', function (e, added) {
			AdminSubCtrl.rootData.pageData.links.push(added);
			$safeApply($scope);
		});

		$scope.$on('dynamiclink.updated', function (e, updated) {
			var foundIndex = _.findIndex(AdminSubCtrl.rootData.pageData.links, { id: updated.id });
			if (foundIndex !== -1) {
				AdminSubCtrl.rootData.pageData.links.splice(foundIndex, 1, updated);
			} else {
				AdminSubCtrl.rootData.pageData.links.push(updated);
			}
			$safeApply($scope);
		});

		$scope.$on('dynamiclink.deleted', function (e, deleted) {
			var foundIndex = _.findIndex(AdminSubCtrl.rootData.pageData.links, { id: deleted.id });
			if (foundIndex !== -1) {
				AdminSubCtrl.rootData.pageData.links.splice(foundIndex, 1);
				if (deleted.entity === 'sidebar') {
					Store.dispatch(setSidebarLinks(getSidebarLinks()));
				}
			}
			$safeApply($scope);
		});

		AdminSubCtrl.rootData.editDynamiclink = function (link) {
			if (FeatureHelper.hasSoftDeployAccess('EDIT_DYNAMIC_LINK_MODAL_REACT')) {
				openModal('EditDynamicLink', {
					link: link?.id ? link : undefined
				});
			} else {
				var options = {};
				if (link && link.id) {
					options.id = link.id;
				}

				$upModal.open('editDynamiclink', options);
			}
		};

		AdminSubCtrl.rootData.removeLink = function (link) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'default.link',
					body: 'confirm.removeLink',
					onClose: confirmed => {
						if (confirmed) {
							Links.admin().delete(link);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('default.link').toLowerCase(),
					body: 'confirm.removeLink',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Links.admin().delete(link);
				});
		};

		var getLinks = function () {
			return Links.admin()
				.list()
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.links = res.data;
					Store.dispatch(setSidebarLinks(res.data.filter(l => l.entity === 'sidebar')));
				});
		};

		var init = function () {
			// eslint-disable-next-line promise/catch-or-return
			getLinks().then(function () {
				AdminSubCtrl.rootData.pageLoading = false;
			});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

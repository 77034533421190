'use strict';

angular.module('domain.lead').controller('ResetLeadMulti', [
	'$scope',
	'$modalParams',
	'ActionProperties',
	'MultiActions',
	'NotificationService',
	'$multiSelect',
	'$rootScope',
	function ($scope, $modalParams, ActionProperties, MultiActions, NotificationService, $multiSelect, $rootScope) {
		$scope.rejectlist = false;
		$scope.entity = $modalParams.entity;

		var closeAfterSave = function () {
			$scope.resetting = false;
			$multiSelect.selectNone();
			$scope.resolve();
		};

		$scope.resetScore = function () {
			$scope.resetting = true;
			var properties = [];

			//ActionProperties.mapCustomFields(properties, $scope.customFields, 'Client');

			if ($scope.rejectlist === true) {
				properties.push({ name: 'ResetScore', value: ['AddToRejectlist'] });
			} else {
				properties.push({ name: 'ResetScore', value: true });
			}

			var filters = $modalParams.filters;

			if ($multiSelect.selected && $multiSelect.selected.length) {
				filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, $multiSelect.selected);
			}
			ActionProperties.map(properties, $scope.properties, ['ResetScore']);

			var method = $modalParams.entity === 'Client' ? 'updateAccount' : 'updateContact';
			var extraParams = $modalParams.extraParams || null;

			MultiActions.customer($modalParams.customerId)
				[method]($modalParams.entity, $multiSelect.selected.length, properties, filters.build(), extraParams)
				.then(function () {
					// If we selected less than 20, then wait for index
					if ($multiSelect.selected.length < 20) {
						setTimeout(function () {
							NotificationService.addNotification({
								style: NotificationService.style.SUCCESS,
								icon: 'check',
								title: 'default.saved',
								body: 'saved.accounts'
							});
							$rootScope.$broadcast('listView.refresh');
							closeAfterSave();
						}, 2000);
					} else {
						// Else a job will be created
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.saved',
							body: 'updateQued.accounts'
						});
						closeAfterSave();
					}
				})
				.catch(function () {
					$scope.resetting = false;
					NotificationService.addNotification({
						style: NotificationService.style.ERROR,
						icon: 'times',
						title: 'default.error',
						body: 'saveError.accounts'
					});

					closeAfterSave();
				});
		};
	}
]);

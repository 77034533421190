export default {
	id: {
		title: 'default.id',
		field: 'id'
	},
	name: {
		title: 'default.name',
		field: 'name',
		locked: true
	},
	articleNo: {
		title: 'product.articleNo',
		field: 'articleNo'
	},
	category: {
		field: 'category.name',
		title: 'default.productCategory'
	},
	active: {
		field: 'active',
		title: 'default.active'
	},
	listPrice: {
		field: 'listPrice',
		title: 'default.listPrice'
	},
	purchaseCost: {
		field: 'purchaseCost',
		title: 'default.purchasedAtCost'
	},
	description: {
		field: 'description',
		title: 'default.description'
	}
};

'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('userDefinedDefinition', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	'AppService',
	function userDefinedDefinition($resource, $q, URL, API, ResourceHelper, AppService) {
		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'userDefinedDefinition/';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				create: { method: 'POST', isArray: false },
			},
		);

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'userDefinedDefinition',
			createSuccessBody: 'saved.userDefinedDefinition',
			updateSuccessBody: 'updated.userDefinedDefinition',
			deleteSucccessBody: 'deleted.userDefinedDefinition',
			createErrorBody: 'saveError.userDefinedDefinition',
			updateErrorBody: 'saveError.userDefinedDefinition',
			deleteErrorBody: 'deleteError.userDefinedDefinition',
		});

		var Model = {
			delete: function (udo, options) {
				var promise = Resource.delete({ id: udo.id }).$promise;

				promise
					.then(function (res) {
						var metadata = AppService.getMetadata();
						metadata.params.UserDefinedObject = _.filter(
							metadata.params.UserDefinedObject,
							function (metaUdo) {
								return metaUdo.id !== udo.id;
							},
						);
						AppService.setMetadata(metadata);
						AllIWantDataCache.clearData();
						helper.onDelete(options, udo);
						return res;
					})
					.catch(function (err) {
						helper.onDeleteError(options);
						return $q.reject(err);
					});

				return promise;
			},

			save: function (udo, options) {
				var promise = Resource.create({}, udo).$promise;

				promise
					.then(function (res) {
						var metadata = AppService.getMetadata();
						var index = _.findIndex(metadata.params.UserDefinedObject, { id: res.data.id });

						if (index > -1) {
							metadata.params.UserDefinedObject[index] = res.data;
						} else {
							metadata.params.UserDefinedObject.unshift(res.data);
						}

						AppService.setMetadata(metadata);
						AllIWantDataCache.clearData();
						helper.onSave(options, res.data, !udo.id);
					})
					.catch(function (err) {
						helper.onSaveError(options, !udo.id);
						return $q.reject(err);
					});

				return promise;
			},
		};

		return Model;
	},
]);

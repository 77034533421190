'use strict';

angular
	.module('security.authorization', ['security.service', 'services.customer', 'services.search'])

	.provider('$routeGuard', {
		requireUser: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireUser();
			}
		],

		mapMailActivated: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.mapMailActivated();
			}
		],

		mapTrackingActivated: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.mapTrackingActivated();
			}
		],

		requireAdmin: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireAdmin();
			}
		],

		requireBillingAdmin: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireBillingAdmin();
			}
		],

		requireAdminOrMailAdmin: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireAdminOrMailAdmin();
			}
		],

		requireAdminOrSoliditetMulti: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireAdminOrSoliditetMulti();
			}
		],

		requireMailAdmin: [
			'$routeGuard',
			function (routeGuard) {
				return routeGuard.requireMailAdmin();
			}
		],

		$get: [
			'security',
			'Metadata',
			'$q',
			'AppService',
			function (security, Metadata, q, AppService) {
				var service = {
					requireUser: function () {
						var e = security.getUser();
						e.catch(security.showLogin);
						return e;
					},
					mapMailActivated: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return Metadata.customer(AppService.getCustomerId()).get();
							})
							.then(function (metaData) {
								if (metaData.map.mailActivated) {
									defered.resolve(true);
								} else {
									defered.reject(false);
								}
							})
							.catch(function () {
								defered.reject(false);
							});

						return defered.promise;
					},
					mapTrackingActivated: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return Metadata.customer(AppService.getCustomerId()).get();
							})
							.then(function (metaData) {
								return metaData.map.tracking ? defered.resolve(true) : defered.reject(false);
							})
							.catch(function () {
								return defered.reject(false);
							});

						return defered.promise;
					},
					requireAdmin: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return security.getCustomerUser(AppService.getCustomerId());
							})
							.then(function (user) {
								return user.administrator ? defered.resolve(true) : defered.reject('requireAdmin');
							})
							.catch(function () {
								return defered.reject('requireAdmin');
							});

						return defered.promise;
					},
					requireBillingAdmin: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return security.getCustomerUser(AppService.getCustomerId());
							})
							.then(function (user) {
								return user.billingAdmin
									? defered.resolve(true)
									: defered.reject('requireBillingAdmin');
							})
							.catch(function () {
								return defered.reject('requireBillingAdmin');
							});

						return defered.promise;
					},
					requireAdminOrMailAdmin: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return security.getCustomerUser(AppService.getCustomerId());
							})
							.then(function (user) {
								return user.userParams.mailAdmin || user.administrator
									? defered.resolve(true)
									: defered.reject('requireAdminOrMailAdmin');
							})
							.catch(function () {
								return defered.reject('requireAdminOrMailAdmin');
							});

						return defered.promise;
					},
					requireAdminOrSoliditetMulti: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return security.getCustomerUser(AppService.getCustomerId());
							})
							.then(function (user) {
								return user.userParams.multiSoliditetAccess || user.administrator
									? defered.resolve(true)
									: defered.reject('requireAdminOrSoliditetMulti');
							})
							.catch(function () {
								return defered.reject('requireAdminOrSoliditetMulti');
							});

						return defered.promise;
					},
					requireMailAdmin: function () {
						var defered = q.defer();

						AppService.loadedPromise
							.then(function () {
								return security.getCustomerUser(AppService.getCustomerId());
							})
							.then(function (user) {
								return user.userParams.mailAdmin
									? defered.resolve(true)
									: defered.reject('requireMailAdmin');
							})
							.catch(function () {
								return defered.reject('requireMailAdmin');
							});

						return defered.promise;
					}
				};
				return service;
			}
		]
	});

'use strict';

angular.module('upAttributes').service('MailCampaignInfoAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	function (FilterType, DisplayType, FeatureHelper, AppService) {
		return function () {
			var attributes = {
				groupMailId: {
					title: 'mail.mailing',
					field: 'mail.groupMailId',
					displayType: DisplayType.Number
				},
				type: {
					title: 'default.type',
					field: 'type',
					displayType: DisplayType.String
				},
				clientName: {
					title: 'form.Client.name',
					field: 'client.name',
					displayType: DisplayType.String
				},
				contactName: {
					title: 'form.Contact.name',
					field: 'contact.name',
					displayType: DisplayType.String
				},
				clientAddress: {
					title: 'form.Contact.email',
					field: 'contact.email',
					displayType: DisplayType.String
				},
				unsubscribed: {},
				hardBounce: {},
				softBounce: {},
				notActive: {},
				duplicateEmail: {},
				missingEmail: {},
				invalidEmail: {},
				reject: {},
				otherError: {},
				open: {},
				send: {},
				click: {}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes
			};
		};
	}
]);

export default {
	client: {
		title: 'default.account',
		field: 'client.name',
		locked: true
	},
	contacts: {
		title: 'default.contact',
		field: 'contact.name',
		locked: true
	},
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	description: {
		title: 'default.description',
		field: 'description',
		locked: true
	},
	date: {
		title: 'default.date',
		field: 'date'
	},
	closeDate: {
		title: 'default.closeDate',
		field: 'closeDate'
	},
	priority: {
		title: 'default.prio',
		field: 'priority'
	},
	modDate: {
		title: 'default.modDate',
		field: 'modDate'
	},
	regDate: {
		title: 'default.regDate',
		field: 'regDate'
	},
	users: {
		title: 'default.user',
		field: 'user.name'
	},

	activityType: {
		title: 'default.activityType',
		field: 'activityType.name'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},
	project: {
		title: 'default.project',
		field: 'project.name'
	},
	opportunity: {
		title: 'default.opportunity',
		field: 'opportunity.description'
	},
	outcome: {
		title: 'default.lastOutcome',
		field: 'lastOutcome.outcome'
	},
	outcomeDate: {
		title: 'default.lastOutcomeDate',
		field: 'lastOutcome.date'
	}
};

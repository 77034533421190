'use strict';

ReactTemplates.form.landingPageTemplate = window.ReactCreateClass({
	getInitialState: function () {
		return {
			loaded: false
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			use: t('default.use')
		};
	},
	selectTemplate: function () {
		this.props.onTemplateSelect(this.props.template);
	},
	render: function () {
		var self = this;
		var props = self.props;

		var templateCSS = {
			width: '30%',
			cursor: 'pointer',
			maxWidth: '300px',
			minHeight: '300px'
		};

		var Tools = window.Tools;
		var customerId = Tools.AppService.getCustomerId();
		var imgUrl =
			Tools.URL + Tools.API + customerId + '/files/static?path=landingPage/' + props.template.id + '.jpg';

		return (
			<div className="package-box cover-img" style={templateCSS} onClick={self.selectTemplate}>
				<div className="landing-img">
					<img src={imgUrl} />
				</div>
				<div className="overlay">
					<button type="button" className="btn up-btn btn-block btn-bright-blue no-shadow">
						{self.lang.use}
					</button>
				</div>
				<div className="package-box-footer lined">
					<div className="package-box-title">
						<h2>{props.template.name}</h2>
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';

import { connect } from 'react-redux';
import { setCompanyProfileData } from 'Store/reducers/OnboardingReducer';
import { Title, Text, Icon, Button, Card, CardContent, Column, ColorSelect } from '@upsales/components';
import AccountProfilePreview from '../../../AccountProfilePreview';

const mapDispatchToProps = {
	setCompanyProfileData
};

const mapStateToProps = state => ({
	companyProfile: state.Onboarding.companyProfile,
	currentStepId: state.Onboarding.currentStepId,
	currentSubstepId: state.Onboarding.currentSubstepId
});

class CompanyProfileColors extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.companyProfile.colors.maintitle'),
			preview: T('default.preview'),
			description: T('onboarding.step.companyProfile.colors.description'),
			colors: {
				main: T('admin.mainColor'),
				button: T('form.buttonColor'),
				content: T('admin.contentBg'),
				background: T('bannerEditor.background')
			}
		};

		this.state = {
			previewOpen: false
		};

		this.config = {
			main: { profileKey: 'mainBg' },
			button: { profileKey: 'buttonBg' },
			content: { profileKey: 'contentBg' },
			background: { profileKey: 'bodyBg' }
		};

		this.openPreview = this.openPreview.bind(this);
		this.closePreview = this.closePreview.bind(this);
		this.renderAccountProfile = this.renderAccountProfile.bind(this);
	}

	renderContent() {
		const renderer = [];
		const { config } = this;
		const { companyProfile } = this.props;

		for (const key in config) {
			const cfg = config[key];
			const colors = companyProfile.colors;

			renderer.push(
				<CardContent key={key} style={{ display: 'flex', alignItems: 'center' }}>
					<Column size={9}>
						<Text>{this.lang.colors[key]}</Text>
					</Column>
					<Column>
						<ColorSelect
							className="CardContent__Color"
							color={colors[cfg.profileKey]}
							style={{ boxShadow: '0 0 1px rgba(0,0,0,0.1)' }}
							onChange={value => this.defaultChange(value, `colors.${cfg.profileKey}`)}
							align="right"
						/>
					</Column>
				</CardContent>
			);
		}

		return renderer;
	}

	closePreview() {
		this.setState({
			previewOpen: false
		});
	}

	openPreview() {
		const { previewOpen } = this.state;

		if (!previewOpen) {
			this.setState({
				previewOpen: true
			});
		}
	}

	renderAccountProfile() {
		const { previewOpen } = this.state;
		const { companyProfile } = this.props;

		if (previewOpen) {
			return <AccountProfilePreview onClose={this.closePreview} profile={companyProfile} />;
		}

		return null;
	}

	defaultChange(value, key) {
		if (typeof value === 'object') {
			value = value.target.value;
		}

		const { setCompanyProfileData } = this.props;
		setCompanyProfileData(key, value);
	}

	render() {
		const MainClass = new Bem('CompanyProfile');

		return (
			<div className={MainClass}>
				<Title>{this.lang.title}</Title>
				<Text>{this.lang.description}</Text>

				<div className={MainClass.elem('Card').b()}>
					<Button type="link" onClick={this.openPreview}>
						<Icon name="eye" />
						<Text color="bright-blue">{this.lang.preview}</Text>
					</Button>

					<Card>{this.renderContent()}</Card>
				</div>
				{this.renderAccountProfile()}
			</div>
		);
	}
}

CompanyProfileColors.propTypes = {
	setCompanyProfileData: PropTypes.func,
	companyProfile: PropTypes.object,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number
};

CompanyProfileColors.setAsCompleteOnNext = true;

export const detached = CompanyProfileColors;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileColors);

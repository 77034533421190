import React from 'react';
import PropTypes from 'prop-types';
import './FileStorageAppView.scss';
import FileStorageAppHeader from 'Components/FileStorageAppHeader';
import IntegrationIframe from 'Components/IntegrationIframe';
import NoPermissionView from './NoPermissionView';

class FileStorageAppView extends React.Component {
	state = { displayType: 'list' };

	render() {
		const { app, objectId } = this.props;
		const { displayType } = this.state;

		return (
			<div className="FileStorageView">
				<FileStorageAppHeader
					app={app}
					displayType={displayType}
					onDisconnect={this.props.onDisconnect(app.id)}
					onChangeDisplayType={displayType => this.setState({ displayType })}
				/>
				{app.permissionDenied ? (
					<NoPermissionView />
				) : (
					<IntegrationIframe
						type={displayType}
						integrationId={app.id}
						objectId={objectId}
						name="FileStorageDisplayFiles"
					/>
				)}
			</div>
		);
	}
}

FileStorageAppView.propTypes = {
	app: PropTypes.object.isRequired,
	objectId: PropTypes.number.isRequired,
	onDisconnect: PropTypes.func.isRequired
};

export default FileStorageAppView;

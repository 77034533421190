'use strict';

angular.module('upDirectives').directive('upPlaceholder', function () {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			setTimeout(function () {
				element.ready(function () {
					var text = attrs.upPlaceholder;
					var dim = element.position();
					dim.width = element.outerWidth();
					dim.height = element.outerHeight();
					var placeholder = angular.element('<div/>');
					var colon = angular.element('<span/>').text(':').addClass('colon');

					element.attr('placehloder', '');
					element.addClass('up-placeholder-element');

					placeholder.addClass('up-placeholder');

					placeholder.text(text);

					if (attrs.upPlaceholderIcon) {
						var icon = angular.element('<i/>').addClass(attrs.upPlaceholderIcon).css({
							'padding-right': '5px'
						});
						placeholder.prepend(icon);
					}

					placeholder.append(colon);
					placeholder.css({
						'line-height': dim.height + 'px',
						'font-size': element.css('font-size')
					});
					placeholder.on('click', function () {
						element.focus();
					});

					element.after(placeholder);

					// Dont ask me why i need to do this two times. But it have to be like to or some placeholders are positioned wrong
					placeholder.position({
						of: element,
						my: 'left top',
						at: 'left top'
					});

					placeholder.position({
						of: element,
						my: 'left top',
						at: 'left top'
					});

					var width = placeholder.outerWidth();
					element.css('padding-left', width + 'px');

					var checkValue = function () {
						if (element.val().length) {
							placeholder.addClass('not-empty');
						} else {
							placeholder.removeClass('not-empty');
						}

						// sett error class
						if (element.hasClass('ng-invalid') && element.hasClass('ng-dirty')) {
							placeholder.addClass('error');
						} else {
							placeholder.removeClass('error');
						}
					};

					checkValue();

					element
						.focus(function () {
							placeholder.addClass('focus');
						})
						.blur(function () {
							placeholder.removeClass('focus');
						})
						.change(checkValue);
				});
			}, 200);
		}
	};
});

import T from 'Components/Helpers/translate';
import React, { Component } from 'react';
import { Toggle, Card, CardHeader, CardContent, Row, Column, Text, Block, Label } from '@upsales/components';
import PropTypes from 'prop-types';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AdminHeader from '../AdminHeader';
import BemClass from '@upsales/components/Utils/bemClass';
import SubscriptionAdvanceSettings from 'App/components/SubscriptionAdvanceSettings';
import SubscriptionsDefaultSettings from './SubscriptionsDefaultSettings';
import SubscriptionsOrderSettings from './SubscriptionsOrderSettings';
import logError from 'App/babel/helpers/logError';

class Subscriptions extends Component {
	static propTypes = {
		rootData: PropTypes.shape({
			pageData: PropTypes.shape({
				onChange: PropTypes.func.isRequired,
				itr: PropTypes.number.isRequired,
				settings: PropTypes.shape({
					enabled: PropTypes.bool.isRequired,
					customDateDomain: PropTypes.oneOf(['ORDER', 'ORDER_PRODUCT']),
					customStartDate: PropTypes.string,
					customEndDate: PropTypes.string,
					defaultInterval: PropTypes.string,
					defaultPeriod: PropTypes.string,
					defaultStage: PropTypes.number,
					defaultCreationTime: PropTypes.number,
					lockedStages: PropTypes.arrayOf(PropTypes.number),
					adminCanEditLockedStage: PropTypes.bool,
					noticePeriod: PropTypes.arrayOf(PropTypes.number),
					defaultNoticePeriod: PropTypes.number,
					inAdvance: PropTypes.arrayOf(PropTypes.number),
					preCreate: PropTypes.bool
				}),
				customFields: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.number.isRequired,
						name: PropTypes.string
					})
				)
			}),
			pageLoading: PropTypes.bool.isRequired
		})
	};

	constructor(props) {
		super(props);
		var t = getAngularModule('$translate');
		this.lang = {
			description: t('admin.agreement.description'),
			title: t('default.agreement'),
			no: t('default.no'),
			descriptionLeadTime: t('admin.agreement.default.leadTime'),
			startFieldTitle: t('admin.agreement.startField.title'),
			endFieldTitle: t('admin.agreement.endField.title'),
			selectPlaceholder: t('admin.agreement.select.placeholder'),
			untilFurtherNotice: t('order.ongoing'),
			everyFourthMonths: t('order.recurringInterval.fourMonths'),
			leadByDays: t('calendar.days') + ' ' + t('default.early'),
			activate: t('default.activate'),
			cardCustomer: t('agreement.cardCustomer'),
			lockedStages: t('agreement.lockedStages'),
			lockedStagesInfo: t('agreement.lockedStagesInfo'),
			adminCanEditLocked: t('agreement.adminCanEditLocked')
		};
		var onChange = props.rootData.pageData.onChange;

		this.onStartDateChange = onChange.bind(null, 'startdate');
		this.onEndDateChange = onChange.bind(null, 'enddate');
		this.onDomainChangeNull = onChange.bind(null, 'domain', null);
		this.onDomainChangeOrder = onChange.bind(null, 'domain', 'ORDER');
		this.onDomainChangeOrderRow = onChange.bind(null, 'domain', 'ORDER_PRODUCT');
		this.onCreationTimeChange = onChange.bind(null, 'creationTime');

		const metadata = Tools.AppService.getMetadata();
		const { EnableCreditOrders } = metadata.params;

		this.state = { EnableCreditOrders };

		this.classes = new BemClass('Subscriptions');
	}

	onCreditOrderSettingChange = async value => {
		try {
			await this.saveClientParam(256, 'EnableCreditOrders', value);
			this.setState({ EnableCreditOrders: value });
		} catch (e) {
			logError(e, 'Error while saving EnableCreditOrders param');
		}
	};

	saveClientParam = async (id, clientParam, value) => {
		await Tools.ClientParam.save(id, value);
		const metadata = Tools.AppService.getMetadata();
		Tools.AppService.setMetadata({
			...metadata,
			params: {
				...metadata.params,
				[clientParam]: value
			}
		});
	};

	onIntervalChange = e => {
		var value = e.target.value;
		var onChange = this.props.rootData.pageData.onChange;
		var settings = this.props.rootData.pageData.settings;

		onChange('interval', e);

		if (parseInt(settings.defaultPeriod) % parseInt(value) !== 0 && settings.defaultPeriod !== '0') {
			onChange('period', e);
		}
	};

	onPeriodChange = e => {
		var value = e.target.value;
		var onChange = this.props.rootData.pageData.onChange;
		var settings = this.props.rootData.pageData.settings;

		onChange('period', e);

		if (parseInt(value) % parseInt(settings.defaultInterval) !== 0 && settings.defaultPeriod !== '0') {
			onChange('interval', e);
		}
	};

	onStageChange = e => {
		var onChange = this.props.rootData.pageData.onChange;

		onChange('stage', e ?? {});
	};

	onChange = (setting, value, defaultValue) => {
		const onChange = this.props.rootData.pageData.onChange;

		onChange(setting, value ?? defaultValue);
	};

	render() {
		var self = this,
			content;

		const hasEnableCreditOrdersSetting =
			Tools.FeatureHelper.hasSoftDeployAccess('EDIT_SUBSCRIPTIONS') &&
			Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_CREDIT_ORDER') &&
			Tools.AppService.getSelf().administrator;

		if (this.props.rootData.pageLoading || !this.props.rootData.pageData.settings.enabled) {
			content = <div />;
		} else if (this.props.rootData.pageData.settings.enabled) {
			var domain = this.props.rootData.pageData.settings.customDateDomain;

			const select2StartOptions = {
				data: this.props.rootData.pageData.customFields,
				placeholder: this.lang.selectPlaceholder,
				minimumResultsForSearch: -1
			};

			const select2EndOptions = {
				data: this.props.rootData.pageData.customFields,
				placeholder: this.lang.selectPlaceholder,
				minimumResultsForSearch: -1
			};

			content = (
				<div>
					<SubscriptionsDefaultSettings
						onIntervalChange={this.onIntervalChange}
						defaultInterval={this.props.rootData.pageData.settings.defaultInterval}
						defaultPeriod={this.props.rootData.pageData.settings.defaultPeriod}
						defaultStage={this.props.rootData.pageData.settings.defaultStage}
						onPeriodChange={this.onPeriodChange}
						onStageChange={this.onStageChange}
					/>

					<SubscriptionsOrderSettings
						className="Subscriptions"
						domain={domain}
						itr={this.props.rootData.pageData.itr}
						onDomainChange={v => this.props.rootData.pageData.onChange('domain', v)}
						select2StartOptions={select2StartOptions}
						select2EndOptions={select2EndOptions}
						onStartDateChange={this.onStartDateChange}
						onEndDateChange={this.onEndDateChange}
						customEndDate={this.props.rootData.pageData.settings.customEndDate}
						customStartDate={this.props.rootData.pageData.settings.customStartDate}
					/>

					<Card space="mtxl mbxl">
						<CardHeader title={this.lang.cardCustomer} />
						<CardContent>
							<Row>
								<Column>
									<Block space="mbxl">
										<Row>
											<Text className="lockedStagesInfo" color="grey-11">
												{this.lang.lockedStagesInfo}
											</Text>
										</Row>
										<Row>
											<Column size={6}>
												<Block space="mtxl">
													<Label>{this.lang.lockedStages}</Label>
													<ReactTemplates.INPUTS.upStages
														value={window.Tools.AppService.getStages('order', true).filter(
															s =>
																self.props.rootData.pageData.settings.lockedStages.indexOf(
																	s.id
																) !== -1
														)}
														placeholder={this.lang.selectWonStage}
														className="form-control"
														onChange={v =>
															this.props.rootData.pageData.onChange(
																'lockedStages',
																v.map(s => s.id)
															)
														}
														entity="order"
														hideZeroProbability={true}
														multiple={true}
													/>
												</Block>
											</Column>
										</Row>
									</Block>
									<Block>
										<Toggle
											color="bright-blue"
											size="lg"
											checked={this.props.rootData.pageData.settings.adminCanEditLockedStage}
											onChange={v =>
												this.props.rootData.pageData.onChange('adminCanEditLockedStage', v)
											}
											data-test-id="agreement-pre-create-toggle"
										/>
										<Block className="admin-page-agreement-display-inline" space="mlm">
											<Label>{this.lang.adminCanEditLocked}</Label>
										</Block>
									</Block>
								</Column>
							</Row>
						</CardContent>
					</Card>

					<SubscriptionAdvanceSettings
						defaultOption={self.props.rootData.pageData.settings.defaultCreationTime}
						options={self.props.rootData.pageData.settings.inAdvance}
						enabled={self.props.rootData.pageData.settings.preCreate}
						onDefaultChange={value => this.onChange('defaultCreationTime', value)}
						onEnableChange={value => this.onChange('preCreate', value, false)}
						onChange={value => this.onChange('inAdvance', value)}
						showEnabledToggle
						articleId={726}
						max={365}
						langMap={{
							title: T('agreement.enableCreateOrdersInAdvance'),
							subtitle: T('agreement.enableCreateOrdersInAdvanceDescription'),
							optionZero: T('agreement.optionFor0DaysInAdvance'),
							optionOne: interval => T('agreement.createOrderDaysInAdvance', { interval }),
							optionPlural: interval => T('agreement.createOrderDaysInAdvancePlural', { interval }),
							addOption: {
								placeholder: T('agreement.addCreateOrdersInAdvanceOptionInputPlaceholder'),
								textAfterInput: T('agreement.addCreateOrdersInAdvanceOptionTextAfterInput'),
								textAfterInputPlural: T('agreement.addCreateOrdersInAdvanceOptionTextAfterInputPlural'),
								textBeforeInput: T('agreement.addCreateOrdersInAdvanceOptionTextBeforeInput')
							}
						}}
					/>
					{Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL') ? (
						<SubscriptionAdvanceSettings
							options={self.props.rootData.pageData.settings.noticePeriod}
							defaultOption={self.props.rootData.pageData.settings.defaultNoticePeriod}
							onChange={value => this.onChange('noticePeriod', value, [])}
							onDefaultChange={value => this.onChange('defaultNoticePeriod', value, 0)}
							maxPreset={parseInt(self.props.rootData.pageData.settings.defaultPeriod)}
							langMap={{
								title: T('agreement.noticePeriod.title'),
								subtitle: T('agreement.noticePeriod.subtitle'),
								optionZero: T('agreement.noticePeriod.optionZero'),
								optionOne: interval => T('agreement.noticePeriod.optionOne', { interval }),
								optionPlural: interval => T('agreement.noticePeriod.optionPlural', { interval }),
								addOption: {
									placeholder: T('date.months').toLowerCase(),
									textAfterInput: T('agreement.noticePeriod.textAfterInput'),
									textAfterInputPlural: T('agreement.noticePeriod.textAfterInputPlural'),
									textBeforeInput: T('agreement.noticePeriod.textBeforeInput')
								}
							}}
						/>
					) : null}
					{hasEnableCreditOrdersSetting ? (
						<Card space="mbxl">
							<CardHeader
								title={T('reminders.enableSubscriptionCredit.title')}
								subtitle={T('reminders.enableSubscriptionCredit.description')}
								className={'creditOrderSetting'}
							>
								<Block space="mrxl">
									<Toggle
										color="medium-green"
										checked={this.state.EnableCreditOrders}
										onChange={this.onCreditOrderSettingChange}
									/>
								</Block>
							</CardHeader>
						</Card>
					) : null}
				</div>
			);
		}

		return (
			<div id="admin-page-agreement">
				<AdminHeader
					title={this.lang.title}
					description={this.lang.description}
					image="agreement.svg"
					toggle={true}
					toggleChecked={
						this.props.rootData.pageLoading ? false : this.props.rootData.pageData.settings.enabled
					}
					toggleDisabled={this.props.rootData.pageLoading}
					onToggleChange={() =>
						this.props.rootData.pageData.onChange('enabled', !this.props.rootData.pageData.settings.enabled)
					}
					toggleLabel={this.lang.activate}
					toggleArticleId={726}
				/>
				<div id="admin-content">{content}</div>
			</div>
		);
	}
}

export default Subscriptions;

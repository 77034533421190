import { preview } from 'App/helpers/creatives';

angular.module('upModals.controllers').controller('SelectCreatives', [
	'$scope',
	'$q',
	'$modalParams',
	'File',
	'AppService',
	'$upModal',
	'Ads',
	'BannerGroup',
	'FilterHelper',
	function ($scope, $q, $modalParams, File, AppService, $upModal, Ads, BannerGroup, FilterHelper) {
		var SelectCreativesCtrl = this;
		SelectCreativesCtrl.creatives = [];
		SelectCreativesCtrl.loading = true;
		SelectCreativesCtrl.showSelected = false;
		SelectCreativesCtrl.resolveKey = $modalParams.resolveKey;
		SelectCreativesCtrl.customerId = AppService.getCustomerId();
		SelectCreativesCtrl.page = 1;
		SelectCreativesCtrl.offset = 0;
		SelectCreativesCtrl.limit = 25;
		SelectCreativesCtrl.total = 0;
		SelectCreativesCtrl.filters = {
			Name: FilterHelper.filter('Name', 'banner')
		};

		SelectCreativesCtrl.selectedCreatives = _.reduce(
			$modalParams.creatives,
			(res, creative) => {
				const isGroup = creative.creativeGroupId !== 0 ? 1 : 0;

				if (isGroup) {
					if (!creative.creativeGroupId) {
						return res;
					}
					const key = `${creative.creativeGroupId}-1`;
					if (res[key]) {
						res[key].children.push(creative);
					} else {
						res[key] = {
							id: creative.creativeGroupId,
							isGroup,
							children: [creative]
						};
					}
				} else {
					const key = `${creative.id}-0`;
					res[key] = creative;
				}

				return res;
			},
			{}
		);

		SelectCreativesCtrl.save = function () {
			const creatives = [];

			_.each(SelectCreativesCtrl.selectedCreatives, creative => {
				if (creative.children) {
					for (const child of creative.children) {
						creatives.push(child);
					}
				} else {
					creatives.push(creative);
				}
			});

			$scope.resolve(creatives);
		};

		SelectCreativesCtrl.getNumberOfSelectedCreatives = function () {
			return Object.keys(SelectCreativesCtrl.selectedCreatives).length;
		};

		SelectCreativesCtrl.selectCreative = function (creative) {
			const key = `${creative.id}-${creative.isGroup}`;
			const isSelected = SelectCreativesCtrl.selectedCreatives[key];
			if (isSelected) {
				delete SelectCreativesCtrl.selectedCreatives[key];
			} else {
				SelectCreativesCtrl.selectedCreatives[key] = creative;
			}
		};

		SelectCreativesCtrl.isSelected = function (creative) {
			const key = `${creative.id}-${creative.isGroup}`;
			return SelectCreativesCtrl.selectedCreatives[key] ? true : false;
		};

		SelectCreativesCtrl.previewPopup = function (creative) {
			preview(creative);
		};

		SelectCreativesCtrl.uploadFile = function () {
			$upModal.open('uploadCreative', {});
		};

		$scope.$on(Ads.events.eventPrefix + '.' + Ads.events.creativeCreated, function (e, files) {
			if (!files || !files.length) {
				return;
			}
			const rb = FilterHelper.parseFilters({}, 'banner');
			rb.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, files[0].id);
			Ads.customer(SelectCreativesCtrl.customerId)
				.findGroupedCreative(rb.build())
				.then(function ({ data }) {
					if (data && data.length === 1) {
						calculatePreviewSize(data);
						const file = data[0];
						const key = `${file.id}-0`;
						SelectCreativesCtrl.selectedCreatives[key] = file;
						SelectCreativesCtrl.creatives.unshift(file);
					}
				})
				.catch(function (error) {
					console.error('selectCreatives.js - $on', error);
				});
		});

		function calculatePreviewSize(items) {
			items.forEach(item => {
				if (item.children) {
					calculatePreviewSize(item.children);
				} else {
					item.previewSize = {
						width: item.width / 40,
						height: item.height / 40
					};
				}
			});
		}

		SelectCreativesCtrl.filtersChanged = _.debounce(function () {
			SelectCreativesCtrl.total = 0;
			SelectCreativesCtrl.page = 1;
			SelectCreativesCtrl.fetchData();
		}, 300);

		SelectCreativesCtrl.toggleShowSelected = function () {
			if (SelectCreativesCtrl.showSelected) {
				SelectCreativesCtrl.filters.UniqueId = FilterHelper.filter('UniqueId', 'banner');
				SelectCreativesCtrl.filters.UniqueId.value = _.map(SelectCreativesCtrl.selectedCreatives, creative => {
					return `${creative.id}-${creative.isGroup}`;
				});
			} else {
				delete SelectCreativesCtrl.filters.UniqueId;
			}
			SelectCreativesCtrl.filtersChanged();
		};

		SelectCreativesCtrl.fetchData = function () {
			SelectCreativesCtrl.loading = true;
			SelectCreativesCtrl.creatives = [];

			const rb = FilterHelper.parseFilters(SelectCreativesCtrl.filters, 'banner');
			rb.addFilter(BannerGroup.attr.draft, rb.comparisonTypes.Equals, false);
			rb.offset = (SelectCreativesCtrl.page - 1) * SelectCreativesCtrl.limit;
			rb.limit = SelectCreativesCtrl.limit;
			rb.addSort('name', true);

			Ads.customer(SelectCreativesCtrl.customerId)
				.findGroupedCreative(rb.build())
				.then(function ({ data, metadata }) {
					calculatePreviewSize(data);
					SelectCreativesCtrl.total = metadata.total;
					SelectCreativesCtrl.creatives = data;
					SelectCreativesCtrl.loading = false;
				})
				.catch(function (error) {
					console.error('selectCreatives.js - init', error);
					SelectCreativesCtrl.loading = false;
					SelectCreativesCtrl.loadingErr = error;
				});
		};

		SelectCreativesCtrl.fetchData();
	}
]);

import React, { Component } from 'react';
import { Icon, Tooltip, Button } from '@upsales/components';
import t from 'Components/Helpers/translate';
import _ from 'lodash';
import Automation from 'App/resources/Model/Automation';

type Props = {
	automations: Automation[];
	hideNewButton?: boolean;
	editAutomation: (automation?: Automation) => void;
	deleteAutomation: (automation: Automation, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type Sort = 'name' | 'action' | 'type' | 'brandId';

type State = {
	sort: Sort;
	descending: boolean;
};

class AutomationTable extends Component<Props, State> {
	lang: { [key: string]: string | { [key: string]: string } };

	constructor(p: Props) {
		super(p);
		this.lang = {
			automations: t('admin.automations'),
			automationsInfo: t('admin.automationsInfo'),
			name: t('default.name'),
			brand: t('default.brand'),
			inactivePlural: t('default.inactivePlural'),
			actions: t('admin.actions'),
			inactive: t('default.inactive'),
			newAutomation: t('admin.newAutomation'),
			noAutomations: t('admin.noAutomations'),
			noActions: t('admin.noActions'),
			automationType: t('admin.automationType'),
			and: t('default.and').toLowerCase(),
			more: t('filters.more').toLowerCase(),
			types: {
				'automation-clients': t('default.accounts'),
				'automation-contacts': t('default.contacts')
			}
		};

		this.state = {
			sort: 'name',
			descending: false
		};
	}

	getType(type: string) {
		return (this.lang.types as { [key: string]: string })[type] || type;
	}

	getActionText(automation: Automation) {
		if (!automation.actions || !automation.actions.length) {
			return null;
		}
		let text = t('automationAction.' + automation.actions[0].action);

		if (automation.actions.length > 1) {
			text += ' ' + this.lang.and + ' ' + (automation.actions.length - 1) + ' ' + this.lang.more;
		}

		return text;
	}

	getActionTooltip(automation: Automation) {
		return automation.actions
			.map(action => {
				return t('automationAction.' + action.action);
			})
			.join('\n');
	}

	updateSort(sort: Sort) {
		const descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	}

	getSortClass(name: Sort) {
		const { sort, descending } = this.state;

		if (sort !== name) {
			return 'fa fa-sort';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	}

	render() {
		const { editAutomation, deleteAutomation, hideNewButton } = this.props;
		const hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
		const brands = Tools.AppService.getBrands().filter(b => b.active);
		const selectedBrandId = Tools.AppService.getSelectedBrand();

		let automations = _.sortBy(this.props.automations, a => {
			switch (this.state.sort) {
				case 'action':
					return this.getActionText(a);
				case 'type':
					return this.getType(a.job);
				default:
					return (a.name || '').toLowerCase();
			}
		});

		if (this.state.descending) {
			automations = automations.reverse();
		}

		const rows = automations.map((automation, i) => {
			const type = this.getType(automation.job);
			let className = 'clickable';
			if (!automation.active) {
				className += ' inactive-row';
			}
			let dontExec;
			if (
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			) {
				dontExec = automation.actions.some(a => a.action === 'CreateActivity' || a.action === 'UpdateActivity');
			}
			if (dontExec) {
				className += ' dontexec';
			}
			const actionText = this.getActionText(automation);
			const actionTooltip = this.getActionTooltip(automation);
			const actionIcon = dontExec ? (
				<Tooltip title={t('admin.automation.dontExecActivityAutomation')}>
					<Icon color="orange" name="exclamation-triangle" />
				</Tooltip>
			) : null;
			const automationBrand = _.find(brands, { id: automation.brandId }) || brands[0];
			const isSelectedBrand = selectedBrandId === automation.brandId;

			return (
				<tr key={'automation-' + i} className={className} onClick={() => editAutomation(automation)}>
					<td className={dontExec ? 'dontexec-titlecol' : ''}>
						{actionIcon}
						{automation.name}
					</td>
					<td>{type}</td>
					<td>
						{automation.actions && automation.actions.length > 1 ? (
							ReactTemplates.TOOLS.withTooltip(<span>{actionText}</span>, actionTooltip, {
								placement: 'right'
							})
						) : (
							<span className={actionText ? '' : 'text-upsales-grey'}>
								{actionText || this.lang.noActions}
							</span>
						)}
					</td>
					{hasBrands && <td>{automationBrand.name}</td>}
					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							{isSelectedBrand || !hasBrands ? (
								<button
									className="up-btn btn-link btn-grey delete-trigger"
									onClick={e => deleteAutomation(automation, e)}
								>
									<b className="fa fa-trash-o" />
								</button>
							) : null}
						</div>
					</td>
				</tr>
			);
		});

		let newButton = null;

		if (!hideNewButton) {
			newButton = (
				<div className="pull-right">
					<Button onClick={() => editAutomation()} size="sm">
						{this.lang.newAutomation}
					</Button>
				</div>
			);
		}

		return (
			<div className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">
						{(this.props.hideNewButton ? this.lang.inactivePlural + ' ' : '') + this.lang.automations}
					</span>
					{newButton}
				</div>

				{rows.length ? (
					<table>
						<thead>
							<tr>
								<th
									onClick={() => this.updateSort('name')}
									className={'sortable' + (this.state.sort === 'name' ? ' active' : '')}
								>
									{this.lang.name} <i className={this.getSortClass('name')} />
								</th>
								<th
									onClick={() => this.updateSort('type')}
									className={'sortable' + (this.state.sort === 'type' ? ' active' : '')}
								>
									{this.lang.automationType} <ReactTemplates.elevio articleId="486" />{' '}
									<i className={this.getSortClass('type')} />
								</th>
								<th
									onClick={() => this.updateSort('action')}
									className={'sortable' + (this.state.sort === 'action' ? ' active' : '')}
								>
									{this.lang.actions} <ReactTemplates.elevio articleId="485" />{' '}
									<i className={this.getSortClass('action')} />
								</th>
								{hasBrands && (
									<th
										onClick={() => this.updateSort('brandId')}
										className={'sortable' + (this.state.sort === 'brandId' ? ' active' : '')}
									>
										{this.lang.brand} <i className={this.getSortClass('brandId')} />
									</th>
								)}
								<th style={{ width: '60px' }} />
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				) : null}
				{!rows.length ? <div className="table-no-result">{this.lang.noAutomations}</div> : null}
			</div>
		);
	}
}

export default AutomationTable;

'use strict';

angular.module('upDirectives').directive('upEventContact', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventContact.tpl.html?file'),
		require: 'upEventContact',
		scope: {
			event: '=upEventContact',
			firstTouch: '='
		},
		controller: [
			'$scope',
			function ($scope) {
				$scope.customerId = Tools.AppService.getCustomerId();
				$scope.deleted =
					!$scope.event.contacts || !$scope.event.contacts.length || $scope.event.contacts[0].name === null;

				if ($scope.event.value) {
					var split = $scope.event.value.split(';');
					$scope.clientId = split[0];
					$scope.clientName = split.slice(1).join(';');
				}
			}
		]
	};
});

import { Equals } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';
import { findAllChildren } from './treeHelpers';

const dateFields = [
	{ field: 'StartDate', attr: 'agreement.metadata.agreementStartdate' },
	{ field: 'InvoiceStartdate', attr: 'agreement.metadata.agreementInvoiceStartdate' },
	{ field: 'RenewalDate', attr: 'agreement.metadata.agreementRenewalDate' },
	{ field: 'Enddate', attr: 'agreement.metadata.agreementEnddate' },
	{ field: 'NextOrderDate', attr: 'agreement.metadata.agreementNextOrderDate' }
];

export const newFilter = () => ({
	inactive: true,
	value: {
		hasAgreement: true,
		StartDate: { value: { start: null, end: null, preset: 'whenever' } },
		InvoiceStartdate: { value: { start: null, end: null, preset: 'whenever' } },
		RenewalDate: { value: { start: null, end: null, preset: 'whenever' } },
		Enddate: { value: { start: null, end: null, preset: 'whenever' } },
		NextOrderDate: { value: { start: null, end: null, preset: 'whenever' } }, // needs 'metadata.willCreateMoreOrders'
		AgreementRowProduct: { value: [], comparisonType: Equals },
		Value: { value: { start: null, end: null } },
		Currency: { value: null },
		Active: { value: null },
		User: { value: [] }
	}
});

const agreementFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasAgreement;
		f.i = filter.inactive;

		if (val.AgreementRowProduct.value) {
			f.v.rowp = val.AgreementRowProduct.value;
		}
		if (val.AgreementRowProduct.comparisonType) {
			f.v.rowpc = val.AgreementRowProduct.comparisonType;
		}

		dateFields.forEach(function (field) {
			if (
				val[field.field].value.start ||
				val[field.field].value.end ||
				val[field.field].value.preset !== 'whenever'
			) {
				const shortField = field.field.substr(0, 2).toLowerCase();
				f.v[shortField] = {
					p: val[field.field].value.preset,
					s: val[field.field].value.start,
					e: val[field.field].value.end
				};
			}
		});

		if (val.Value.value.start || val.Value.value.end) {
			f.v.va = { s: val.Value.value.start, e: val.Value.value.end };
		}
		if (val.Currency.value) {
			f.v.cu = val.Currency.value;
		}

		if (val.Active && val.Active.value !== null) {
			f.v.ac = val.Active.value;
		}
		if (val.User?.value) {
			f.v.us = val.User.value;
		}

		return f;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasAgreement = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.rowp) {
			filter.value.AgreementRowProduct.value = val.rowp;
		}
		if (val.rowpc) {
			filter.value.AgreementRowProduct.comparisonType = val.rowpc;
		}

		dateFields.forEach(function (f) {
			const shortField = f.field.substr(0, 2).toLowerCase();
			if (val[shortField]) {
				filter.value[f.field] = {
					value: {
						start: val[shortField].s ? new Date(val[shortField].s) : val[shortField].s,
						end: val[shortField].e ? new Date(val[shortField].e) : val[shortField].e,
						preset: val[shortField].p
					}
				};
			}
		});

		if (val.va) {
			filter.value.Value = { value: { start: val.va.s, end: val.va.e } };
		}
		if (val.cu) {
			filter.value.Currency = { value: val.cu };
		}
		if (val.ac) {
			filter.value.Active = { value: val.ac };
		}
		if (val.us) {
			filter.value.User = { value: val.us };
		}

		return filter;
	},
	build:
		(options = {}) =>
		(filter, rb, filterHelperGetField, useTags) => {
			const val = filter.value;

			if (filter.inactive || !val) {
				return;
			}

			let products;
			let productCategories = [];

			if (options.mixedProductsAndCategories) {
				var ids = _.reduce(
					val.AgreementRowProduct.value,
					function (result, id) {
						if (typeof id === 'string') {
							result.categoryIds.push(id.split('-')[1]);
						} else {
							result.productIds.push(id);
						}
						return result;
					},
					{ productIds: [], categoryIds: [] }
				);

				products = ids.productIds;
				productCategories = ids.categoryIds;
			} else {
				products = val.AgreementRowProduct.value;
			}

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			const hasDateValue = dateFields.some(function (f) {
				return val[f.field].value.preset !== 'whenever';
			});

			// If empty filter
			if (
				!val.hasAgreement &&
				!products.length &&
				!productCategories.length &&
				!val.Currency.value &&
				!hasDateValue &&
				val.Active.value === null &&
				!val.User.value.length
			) {
				return rb.addFilter(getField('agreement.id'), rb.comparisonTypes.Equals, null);
			}

			const groupBuilder = rb.groupBuilder();
			// We always needs to add at least one filter that is not contained in an group or or group, so that the "if (element instanceof GroupQuery && !element.segment) { ... }" block in groupedChildGroup
			// can put the correct type on the agreement filter.
			groupBuilder.addFilter(getField('agreement.id'), rb.comparisonTypes.NotEquals, null);

			if (!val.hasAgreement) {
				groupBuilder.isNotFilter();
			}

			const productOrCategoryBuilder = groupBuilder.orBuilder();

			// Products
			if (products.length) {
				if (val.AgreementRowProduct.comparisonType === rb.comparisonTypes.EqualsAll) {
					for (const product of products) {
						const productGroupBuilder = groupBuilder.groupBuilder();
						const innerOrBuilder = productGroupBuilder.orBuilder();
						for (const field of [
							'agreement.orderRow.product.id',
							'agreement.children.orderRow.product.id'
						]) {
							innerOrBuilder.next();
							innerOrBuilder.addFilter(getField(field), rb.comparisonTypes.Equals, product);
						}
						innerOrBuilder.done();
						productGroupBuilder.done();
					}
				} else {
					const productOrBuilder =
						products.length && productCategories.length
							? productOrCategoryBuilder
							: groupBuilder.orBuilder();
					for (const field of ['agreement.orderRow.product.id', 'agreement.children.orderRow.product.id']) {
						productOrBuilder.next();
						productOrBuilder.addFilter(getField(field), rb.comparisonTypes.Equals, products);
					}
					if (!productCategories.length) {
						productOrBuilder.done();
					}
				}
			}

			// Product categories
			if (productCategories.length) {
				const allCategories = [
					...productCategories,
					...findAllChildren(Tools.AppService.getProductCategories(), productCategories)
				];

				if (val.AgreementRowProduct.comparisonType === rb.comparisonTypes.EqualsAll) {
					for (const category of allCategories) {
						const categoryGroupBuilder = groupBuilder.groupBuilder();
						const innerOrBuilder = categoryGroupBuilder.orBuilder();
						for (const field of [
							'agreement.orderRow.product.categoryId',
							'agreement.children.orderRow.product.categoryId'
						]) {
							innerOrBuilder.next();
							innerOrBuilder.addFilter(getField(field), rb.comparisonTypes.Equals, category);
						}
						innerOrBuilder.done();
						categoryGroupBuilder.done();
					}
				} else {
					const categoryOrBuilder =
						products.length && productCategories.length
							? productOrCategoryBuilder
							: groupBuilder.orBuilder();
					for (const field of [
						'agreement.orderRow.product.categoryId',
						'agreement.children.orderRow.product.categoryId'
					]) {
						categoryOrBuilder.next();
						categoryOrBuilder.addFilter(getField(field), rb.comparisonTypes.Equals, productCategories);
					}
					categoryOrBuilder.done();
				}
			}

			// User
			if (val.User?.value.length) {
				groupBuilder.addFilter(getField('agreement.user.id'), rb.comparisonTypes.Equals, val.User.value);
			}

			if (val.Active) {
				if (val.Active.value) {
					if (!val.hasAgreement) {
						groupBuilder.addFilter(getField('agreement.id'), rb.comparisonTypes.NotEquals, null);
					}

					var orGroup = groupBuilder.orBuilder();
					orGroup.next();
					orGroup.addFilter(getField('agreement.metadata.agreementEnddate'), rb.comparisonTypes.Equals, null);
					orGroup.next();
					orGroup.addFilter(
						getField('agreement.metadata.agreementEnddate'),
						rb.comparisonTypes.GreaterThan,
						DatePreset.getDatesFromValue({ preset: 'fromToday' }, useTags).start
					);
					orGroup.done();
				} else {
					if (val.Active.value === false) {
						if (!val.hasAgreement) {
							groupBuilder.addFilter(getField('agreement.id'), rb.comparisonTypes.NotEquals, null);
						}

						groupBuilder.addFilter(
							getField('agreement.metadata.agreementEnddate'),
							rb.comparisonTypes.LessThan,
							DatePreset.getDatesFromValue({ preset: 'fromToday' }, useTags).start
						);
					}
				}
			}

			dateFields.forEach(function (f) {
				if (val[f.field].value.preset !== 'whenever') {
					const dates = DatePreset.getDatesFromValue(val[f.field].value, useTags);

					// start
					if (dates.start) {
						groupBuilder.addFilter(getField(f.attr), rb.comparisonTypes.GreaterThanEquals, dates.start);
					}

					// end
					if (dates.end) {
						groupBuilder.addFilter(getField(f.attr), rb.comparisonTypes.LowerThanEquals, dates.end);
					}
					if (f.field === 'NextOrderDate') {
						groupBuilder.addFilter(
							getField('agreement.metadata.willCreateMoreOrders'),
							rb.comparisonTypes.Equals,
							true
						);
					}
				}
			});

			const yearlyValueField = val.Currency.value
				? 'agreement.yearlyValue'
				: 'agreement.yearlyValueInMasterCurrency';

			if (val.Currency.value) {
				groupBuilder.addFilter(getField('agreement.currency'), rb.comparisonTypes.Equals, val.Currency.value);
			}

			// YearlyValue start
			if (val.Value.value.start) {
				groupBuilder.addFilter(
					getField(yearlyValueField),
					rb.comparisonTypes.GreaterThanEquals,
					val.Value.value.start.toString()
				);
			}

			// YearlyValue end
			if (val.Value.value.end) {
				groupBuilder.addFilter(
					getField(yearlyValueField),
					rb.comparisonTypes.LowerThanEquals,
					val.Value.value.end.toString()
				);
			}

			groupBuilder.done();
		}
};

export default agreementFilter;

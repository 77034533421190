import Attribute, { DisplayType } from './Attribute';

const account = new Attribute({
	title: 'default.account',
	field: 'client.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'client.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'client.name',
			type: Attribute.types.String
		}
	}
});

const accountManager = new Attribute({
	title: 'default.accountManager',
	field: 'user.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'user.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'user.name',
			type: Attribute.types.String
		}
	}
});

const Attributes = {
	id: new Attribute({
		title: 'default.id',
		field: 'id',
		type: Attribute.types.Number
	}),
	date: new Attribute({
		title: 'default.date',
		field: 'date',
		type: Attribute.types.Date,
		displayType: DisplayType.Date,
		sortable: 'date'
	}),
	type: new Attribute({
		title: 'default.type',
		field: 'type',
		type: Attribute.types.String,
		displayType: DisplayType.ARRChange,
		sortable: 'type'
	}),
	monthlyValueInMasterCurrency: new Attribute({
		title: 'arrchange.value.change',
		field: 'monthlyValueInMasterCurrency',
		type: Attribute.types.Number,
		displayKey: ['monthlyValueInMasterCurrency', 'currency'],
		displayKeyMap: { monthlyValueInMasterCurrency: 'value' },
		displayType: DisplayType.Currency,
		currencyFallback: 'master',
		colorize: true,
		sortable: 'monthlyValueInMasterCurrency'
	}),
	monthlyValueInRoleCurrency: new Attribute({
		title: 'arrchange.value.change',
		field: 'monthlyValueInRoleCurrency',
		type: Attribute.types.Number
	}),
	annualValueInMasterCurrency: new Attribute({
		title: 'arrchange.value.change',
		field: 'annualValueInMasterCurrency',
		type: Attribute.types.Number,
		displayKey: ['annualValueInMasterCurrency', 'currency'],
		displayKeyMap: { annualValueInMasterCurrency: 'value' },
		displayType: DisplayType.Currency,
		currencyFallback: 'master',
		colorize: true,
		sortable: 'annualValueInMasterCurrency'
	}),
	annualValueInRoleCurrency: new Attribute({
		title: 'arrchange.value.change',
		field: 'annualValueInRoleCurrency',
		type: Attribute.types.Number
	}),
	accountManager: accountManager,
	users: new Attribute({
		displayType: DisplayType.Users,
		type: Attribute.types.Array,
		title: 'default.accountManager',
		field: 'users',
		attr: {
			id: new Attribute({
				field: 'users.id',
				type: Attribute.types.Number
			}),
			name: new Attribute({
				field: 'users.name',
				type: Attribute.types.String
			})
		}
	}),
	account: account,
	client: account
};

window.ARRChangeAttributes = Attributes;

export default Attributes;

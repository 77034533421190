'use strict';

angular.module('domain.admin').controller('OptInAdmin', [
	'$scope',
	function ($scope) {
		var OptInAdminCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		OptInAdminCtrl.rootData = AdminCtrl.getRootObject();
		OptInAdminCtrl.rootData.pageComponent = 'optIns';
	}
]);

'use strict';

export const BreadcrumbItem = window.ReactCreateClass({
	render: function () {
		var text = this.props.text || '';
		if (!this.props.state && !this.props.onClick) {
			return <div className="Breadcrumbs__item Breadcrumbs__item--current">{text}</div>;
		}
		return (
			<div className="Breadcrumbs__item">
				<a href={Tools.$state.href(this.props.state)} onClick={this.props.onClick}>
					{text}
				</a>
			</div>
		);
	}
});

const Breadcrumbs = window.ReactCreateClass({
	render: function () {
		var items = [];
		var children = _.compact(this.props.children);
		_.each(children, function (item, i) {
			if (!item) {
				return null;
			}
			if (i !== 0) {
				items.push(
					<div key={i + '-separator'} className="Breadcrumbs__separator">
						{'/'}
					</div>
				);
			}
			if (i === children.length - 1) {
				items.push(<BreadcrumbItem key={i} text={item.props.text} />);
			} else {
				items.push(item);
			}
		});
		var className = ['Breadcrumbs'];
		if (this.props.light) {
			className.push('Breadcrumbs--light');
		}
		return <div className={className.join(' ')}>{items}</div>;
	}
});

export default Breadcrumbs;

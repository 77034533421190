'use strict';
import { openDrawer } from 'Services/Drawer';
import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
angular.module('domain.activity').factory('EditActivityMeta', [
	'$q',
	'Order',
	'Account',
	'RequestBuilder',
	'User',
	'Contact',
	'Campaign',
	'Activity',
	'NotificationService',
	'$parse',
	'$upModal',
	'DocumentTemplate',
	'AppService',
	function (
		$q,
		Order,
		Account,
		RequestBuilder,
		User,
		Contact,
		Campaign,
		Activity,
		NotificationService,
		$parse,
		$upModal,
		DocumentTemplate,
		AppService
	) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				// The metadata $modalParams.activity object is used to preset data to the modal-form for edit's

				var customerId = AppService.getCustomerId();
				var account = $q.defer();
				var contact = $q.defer();
				var contactRemoved = false;
				var contactMissingRights = false;
				var promises = {
					users: $q.when({ data: AppService.getActiveUsers() }),
					account: account.promise,
					contact: contact.promise,
					documentTemplates: $q.when({ data: AppService.getDocumentTemplates('activity') })
				};
				// if we have some preset data
				if ($modalParams.id) {
					promises.activity = Activity.customer(customerId)
						.get($modalParams.id)
						.then(function (res) {
							//if activityType is todoType then reject and open corresponding drawer
							const todoTypes = Tools.AppService.getTodoTypes();
							const {
								data: { activityType }
							} = res;
							if (activityType && _.some(todoTypes, { id: activityType.id })) {
								if (activityType.id === todoTypes.TODO.id) {
									openDrawer('EditTodo', { todo: res.data });
									return $q.reject('abort');
								} else if (activityType.id === todoTypes.PHONE_CALL.id) {
									openDrawer('EditPhonecall', { activity: res.data });
									return $q.reject('abort');
								}
							}
							if (res.data.contacts && res.data.contacts.length && res.data.contacts[0].id) {
								Contact.customer(customerId)
									.get(res.data.contacts[0].id, {})
									.then(contact.resolve)
									.catch(function (err) {
										if (
											err.status &&
											err.status === 404 &&
											err.data &&
											err.data.metadata &&
											err.data.metadata.missingRights
										) {
											contactMissingRights = true;
											contact.resolve(null);
										} else if (err.status && err.status === 404) {
											contactRemoved = true;
											contact.resolve(null);
										} else {
											contact.reject(err);
										}
									});
							} else {
								contact.resolve(null);
							}
							return res;
						});
					account.resolve({ data: null });
				} else if ($modalParams.activity) {
					// if this is an edit
					var activity = Activity.new();
					// Save custom before merge so we do not loose it
					var custom = $modalParams.activity.custom || activity.data.custom;
					activity.data = _.merge(activity.data, $modalParams.activity);
					activity.data.custom = custom;
					promises.activity = $q.when(activity);
				} else {
					// create new activity
					if (Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
						logError(
							new Error('User with REMOVE_ACTIVITIES opened editActivity'),
							'User with REMOVE_ACTIVITIES opened editActivity'
						);
						openDrawer('CreateCall');
						return $q.reject('abort');
					} else {
						promises.activity = $q.when(Activity.new());
					}
				}

				// get the activity account and contacts if we got some presets
				if (!$modalParams.id) {
					// Will be handled by $q.all
					// eslint-disable-next-line promise/catch-or-return
					promises.activity.then(function (activity) {
						var noAccount = true;
						// If the appointment has contact or account
						if (activity.data.client && typeof activity.data.client === 'object') {
							Account.customer(customerId)
								.get(activity.data.client.id, {})
								.then(account.resolve)
								.catch(account.reject);
							noAccount = false;
						}

						if (Array.isArray(activity.data.contacts)) {
							activity.data.contacts = activity.data.contacts[0];
						}
						if (activity.data.contacts && typeof activity.data.contacts === 'object') {
							Contact.customer(customerId)
								.get(activity.data.contacts.id, {})
								.then(function (res) {
									contact.resolve(res);
									// Get contact account
									if (noAccount) {
										Account.customer(customerId)
											.get(res.data.client.id)
											.then(account.resolve)
											.catch(account.reject);
										noAccount = false;
									}
									return res.data;
								})
								.catch(contact.reject);
						} else {
							contact.resolve(null);
							if (noAccount) {
								account.resolve({ data: null });
							}
						}
					});
				}

				return $q.all(promises).then(
					function (results) {
						// Fix for when a user are selected and no longer exist
						var users = $parse('activity.data.users')(results);

						if (users && Array.isArray(users)) {
							users.forEach(function (user) {
								if (user && !_.some(results.users.data, { id: user.id })) {
									results.users.data.push(user);
								}
							});
						}

						if (results.account && results.account.data) {
							results.activity.data.client = results.account.data;
						}

						if (results.contact && results.contact.data) {
							results.activity.data.contacts = [results.contact.data];
						}

						results.contactRemoved = false;
						if (contactRemoved) {
							results.contactRemoved = results.activity.data.contacts[0];
							results.activity.data.contacts = [];
						}

						results.contactMissingRights = false;
						if (contactMissingRights) {
							results.contactMissingRights = true;
						}

						return results;
					},
					function (err) {
						if (err !== 'abort') {
							NotificationService.addNotification({
								title: 'default.error',
								body: err && err.status === 404 ? 'errorNotFound.activity' : 'openError.activity',
								style: 'error',
								icon: 'times'
							});
						}
						return $q.reject(err);
					}
				);
			});
		};
	}
]);

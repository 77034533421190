'use strict';

ReactTemplates.INPUTS.datepicker = window.ReactCreateClass({
	datepicker: null,
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getInitialState: function () {
		return {
			value: this.props.value
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		let value = newProps.value;

		if ((this.props.required && (!value || !moment(value).isValid())) || (value && !moment(value).isValid())) {
			value = new Date();
		}

		// Update picker if real change
		if (this.state.value !== value && !moment(this.state.value).isSame(value)) {
			if (typeof value === 'string') {
				value = moment(value).toDate();
			}
			this.setState({ value }, () => {
				if (value && this.datepicker) {
					this.datepicker.update(value);
					this.datepicker.select(value);
				}
			});
		}
	},
	ngModel: function (onChange) {
		var self = this;

		self.$setViewValue = function (newValue) {
			onChange(newValue);
		};
		self.$render = function () {};
	},
	format: function (val) {
		if (val && moment(val).isValid()) {
			return moment(val).format('L');
		}
		return '';
	},
	inputChange: function (event) {
		if (!_.trim(event.target.value).length || !moment(event.target.value).isValid()) {
			return this.onChange(null);
		}
		this.onChange(new Date(event.target.value));
	},
	onChange: function (newValue) {
		if (newValue !== null) {
			if (this.props.minDate && moment(newValue).isBefore(this.props.minDate, 'day')) {
				this.setState({ value: this.props.minDate });
				this.props.onChange(this.props.minDate);
				return;
			}
			if (this.props.maxDate && moment(newValue).isAfter(this.props.maxDate, 'day')) {
				this.setState({ value: this.props.maxDate });
				this.props.onChange(this.props.maxDate);
				return;
			}
			newValue = new Date(newValue);
			this.datepicker.update(newValue);
		} else if (!newValue && this.props.required) {
			newValue = new Date();
			this.datepicker.update(newValue);
		} else {
			this.datepicker.update(null);
		}
		if (this.state.value !== newValue) {
			this.props.onChange(newValue);
		}
		this.setState({ value: newValue });
	},
	componentDidMount: function () {
		var ngModel = new this.ngModel(this.onChange);

		this.datepicker = window.Tools.$datepicker(jQuery(this._input), ngModel, {
			controller: ngModel,
			autoclose: true,
			container: 'body',
			scope: window.Tools.$rootScope
		});

		this.datepicker.update(this.state.value);
		this.datepicker.select(this.state.value);
	},
	render: function () {
		var self = this;
		var value = self.format(self.state.value);

		return (
			<input
				ref={self.setRef.bind(self, '_input')}
				name={self.props.name || null}
				className={self.props.className || 'form-control'}
				type="text"
				value={value}
				disabled={self.props.disabled || false}
				data-placement={self.props.placement || 'bottom'}
				onChange={self.inputChange}
				placeholder={self.props.placeholder || ''}
				tabIndex={self.props.tabIndex || null}
				readOnly={self.props.readOnly || null}
			/>
		);
	}
});

import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Block,
	Row,
	Label,
	ButtonSelect,
	Column,
	Text,
	Link
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './SubscriptionsOrderSettings.scss';

interface Props {
	className?: string;
	domain?: string | null;
	itr: number;
	onDomainChange: React.ComponentProps<typeof ButtonSelect>['onChange'];
	select2StartOptions: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['options'];
	select2EndOptions: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['options'];
	onStartDateChange: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['onChange'];
	onEndDateChange: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['onChange'];
	customStartDate: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['defaultValue'];
	customEndDate: React.ComponentProps<typeof ReactTemplates.INPUTS.upSelect>['defaultValue'];
}
const SubscriptionsOrderSettings = ({
	className,
	domain,
	itr,
	onDomainChange,
	select2StartOptions,
	select2EndOptions,
	onStartDateChange,
	onEndDateChange,
	customEndDate,
	customStartDate
}: Props) => {
	const classes = new BemClass('SubscriptionsOrderSettings', className);
	return (
		<Card space="mbxl">
			<CardHeader title={T('admin.agreement.chooseField.title')} />
			<CardContent>
				<Row className={classes.elem('earlyCreation').b()}>
					<Block space="mbxl">
						<Text color="grey-11">{T('admin.agreement.chooseField.description1')}</Text>
						<ButtonSelect
							color="green"
							value={domain || ''}
							onChange={onDomainChange}
							options={[
								{ value: '', title: T('admin.agreement.chooseField.noField') },
								{ value: 'ORDER', title: T('admin.agreement.chooseField.orderField') },
								{ value: 'ORDER_PRODUCT', title: T('admin.agreement.chooseField.orderRowField') }
							]}
						/>
					</Block>
				</Row>

				{domain ? (
					<>
						<Block className={classes.elem('period-dates').b()} space="mbxl">
							<Row>
								<Column>
									<Label>{T('admin.agreement.startField.title')}</Label>
									<ReactTemplates.INPUTS.upSelect
										id="admin-agreement-startdate"
										key={'selectStart' + domain + itr}
										defaultValue={customStartDate}
										className="form-control"
										onChange={onStartDateChange}
										options={select2StartOptions}
									/>
								</Column>
								<Column>
									<Label>{T('admin.agreement.endField.title')}</Label>
									<ReactTemplates.INPUTS.upSelect
										id="admin-agreement-enddate"
										key={'selectStart' + domain + itr}
										defaultValue={customEndDate}
										className="form-control"
										onChange={onEndDateChange}
										options={select2EndOptions}
									/>
								</Column>
							</Row>
						</Block>
						<Block className={classes.elem('setup-fields-text').b()} space="mbm">
							<Text color="grey-11">{T('admin.agreement.chooseField.description2') + ' '}</Text>
							<Link
								onClick={() =>
									Tools.$state.go('administration.fields', { type: 'orderAndOpportunities' })
								}
							>
								{T('admin.agreement.chooseField.orderOpportunitiesSettings').toLowerCase()}
							</Link>
						</Block>
					</>
				) : null}
			</CardContent>
		</Card>
	);
};

export default SubscriptionsOrderSettings;

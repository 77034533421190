angular.module('upDirectives').directive('inlineDeleteModal', function () {
	return {
		scope: {
			entity: '=',
			onConfirm: '=',
			tooltip: '=',
			show: '=',
			buttonClass: '=',
			entityObject: '='
		},
		replace: true,
		template: '<div></div>',
		restrict: 'A',
		link: function ($scope, $element) {
			let firstElement = $element[0];
			if (!firstElement) {
				return;
			}

			ReactDOM.render(
				React.createElement(
					window.InlineConfirm,
					{
						entity: $scope.entity,
						onConfirm: function () {
							return $scope.onConfirm($scope.entityObject);
						},
						tooltip: $scope.tooltip,
						show: $scope.show
					},
					React.createElement('button', { className: $scope.buttonClass })
				),
				firstElement,
				function () {}
			);

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});
		}
	};
});

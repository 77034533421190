'use strict';

angular.module('upDirectives').directive('upFocus', function () {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			var options = {
				timeout: attrs.timeout || 200,
				persistent: attrs.persistent !== undefined,
				cursorAtBeginning: attrs.cursorAtBeginning !== undefined,
				onEvent: attrs.onEvent || null
			};

			var doFocus = function () {
				var isSelect2 = element.attr('ui-select2');
				if (isSelect2) {
					element.select2('open');
				} else {
					element.focus();
				}
			};

			// Listen for event if we have one
			if (options.onEvent) {
				scope.$on(options.onEvent, function () {
					setTimeout(function () {
						doFocus();
					}, options.timeout);
				});
			} else {
				element.ready(function () {
					setTimeout(function () {
						if (attrs.upFocus) {
							var focus = scope.$eval(attrs.upFocus);
							if (focus) {
								doFocus();
							}
						} else {
							element.focus();
						}

						if (options.cursorAtBeginning) {
							element[0].setSelectionRange(0, 0);
						}
					}, options.timeout);
				});

				if (options.persistent) {
					element.on('blur', function () {
						setTimeout(function () {
							doFocus();
						}, 0);
					});
				}
			}
		}
	};
});

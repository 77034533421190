import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import './NavbarDropdown.scss';
import { OutsideClick, Card } from '@upsales/components';

type ChildFnProps = {
	open: boolean;
	setOpen: (val: boolean) => void;
};

type NavbarDropdownProps = {
	children: (childProps: ChildFnProps) => JSX.Element | JSX.Element[];
	className?: string;
	renderTrigger: (childProps: ChildFnProps) => JSX.Element;
	align?: 'left' | 'center' | 'right';
};

const NavbarDropdown = ({ children, className, renderTrigger, align = 'center' }: NavbarDropdownProps) => {
	const classes = new bemClass('NavbarDropdown', className).mod(align);
	const [open, setOpen] = useState(false);
	const [opened, setOpened] = useState<number | null>(null);
	useEffect(() => {
		if (open) {
			setOpened(Date.now());
		} else {
			setOpened(null);
		}
	}, [open]);
	const fnArgs = { open, setOpen };
	const ref = useRef(null);
	return (
		<OutsideClick
			className={classes.b()}
			targetRef={() => ref}
			outsideClick={() => {
				if (typeof opened === 'number' && Date.now() - opened > 10) {
					setOpen(false);
				}
			}}
			listen={open}
			ref={ref}
		>
			{renderTrigger(fnArgs)}
			<Card className={classes.elem('menu').mod({ open }).b()}>{children(fnArgs)}</Card>
		</OutsideClick>
	);
};

NavbarDropdown.propTypes = {
	children: PropTypes.func.isRequired,
	renderTrigger: PropTypes.func.isRequired
};

export default NavbarDropdown;

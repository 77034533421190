import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { Icon, Text } from '@upsales/components';
import _ from 'lodash';

import './BrokenFiscalYearSelect.scss';
const CSS = new Bem('BrokenFiscalYearSelect');

const GridItem = ({ month, offset, onSelect, selected, disabled }) => {
	const onClick = disabled ? _.noop : () => onSelect(offset);

	return (
		<div className={CSS.elem('grid-item').mod({ selected, disabled }).b()} onClick={onClick}>
			{month}
		</div>
	);
};

export default class BrokenFiscalYearSelect extends React.Component {
	static propTypes = {
		offset: PropTypes.number,
		onSelect: PropTypes.func
	};

	static defaultProps = {
		offset: 0,
		onSelect: () => {}
	};

	months = moment.months();
	years = [moment().subtract(1, 'year').year(), moment().year()];

	incrementYear = () => this.setState({ yearOffset: Math.min(this.state.yearOffset + 1, 1) });
	decrementYear = () => this.setState({ yearOffset: Math.max(this.state.yearOffset - 1, 0) });

	constructor(props) {
		super(props);

		this.state = {
			yearOffset: props.offset < 0 ? 0 : 1
		};
	}

	render() {
		const { offset: selectedOffset, onSelect } = this.props;
		const { yearOffset } = this.state;

		const gridItems = this.months.map((month, index) => {
			const offset = -12 * (1 - yearOffset) + index;
			const disabled = offset < -11 || offset > 11 || offset === 0;
			const selected = offset === selectedOffset && !disabled;

			return (
				<GridItem
					key={offset}
					offset={offset}
					month={month}
					selected={selected}
					disabled={disabled}
					onSelect={onSelect}
				/>
			);
		});

		return (
			<div className={CSS.b()}>
				<div className={CSS.elem('navigation').b()}>
					<div
						className={CSS.elem('chevron').mod({ disabled: !yearOffset }).b()}
						onClick={this.decrementYear}
					>
						<Icon name="chevron-left" />
					</div>
					<Text bold={true} size="lg">
						{this.years[yearOffset]}
					</Text>
					<div className={CSS.elem('chevron').mod({ disabled: yearOffset }).b()} onClick={this.incrementYear}>
						<Icon name="chevron-right" />
					</div>
				</div>
				<div className={CSS.elem('grid').b()}>{gridItems}</div>
			</div>
		);
	}
}

import { Block, Column, Icon, Link, Row, Text, Tooltip } from '@upsales/components';
import React, { useState } from 'react';
import FlowStepBase, { getSnoozeUnitText } from '../FlowStepBase';
import T from 'Components/Helpers/translate';
import { FlowStep } from 'App/resources/Model/Flow';
import BemClass from '@upsales/components/Utils/bemClass';
import './FlowStepSendMail.scss';

type Props = Pick<
	React.ComponentProps<typeof FlowStepBase>,
	'onDelete' | 'onClick' | 'editable' | 'onClickBehaviour'
> & {
	step: FlowStep;
	onAddBehavior: (type: string) => void;
	onDeleteBehavior: () => void;
};

export const MAX_HEIGHT = 100;

const behaviorSteps = ['readMail', 'clickedMail'];

const getNextStepData = (type: 'readMail' | 'clickedMail') =>
	({
		readMail: { icon: 'eye' as const, text: 'flow.step.sendMail.openText' },
		clickedMail: { icon: 'mouse-pointer' as const, text: 'flow.step.sendMail.clickText' },
		unknown: { icon: '', text: '' }
	}[type || 'unknown']);

const FlowStepSendMail = ({ step, onAddBehavior, onClickBehaviour, onDeleteBehavior, ...props }: Props) => {
	const classes = new BemClass('FlowStepSendMail');
	const params = Array.isArray(step.params) ? ({} as { [key: string]: any }) : step.params;
	const [isbehaviorSelectVisible, setIsbehaviorSelectVisible] = useState(false);
	let behaviorStep = null;

	if (
		step.childYes &&
		step.childYes.waitTime &&
		step.childYes.waitUnit &&
		behaviorSteps.includes(step.childYes.type)
	) {
		behaviorStep = {
			...step.childYes,
			waitTime: step.childYes.waitTime,
			waitUnit: step.childYes.waitUnit,
			...getNextStepData(step.childYes.type as 'readMail' | 'clickedMail')
		};
	}

	let behaviorText = null;
	let behaviorTooltipText = '';

	if (behaviorStep) {
		behaviorTooltipText = behaviorStep.link || T('flow.allLinks').toLowerCase();
		const text = T(behaviorStep.text, {
			link: behaviorStep.link || T('flow.allLinks').toLowerCase(),
			num: behaviorStep.waitTime,
			unit: getSnoozeUnitText(behaviorStep.waitTime, behaviorStep.waitUnit)?.toLowerCase()
		});

		behaviorText = (
			<>
				<Icon name={behaviorStep.icon} space="mrs" />
				{text}
			</>
		);
	}

	return (
		<FlowStepBase
			icon="paper-plane"
			backgroundColor="white"
			borderColor="rose-4"
			title={T('flow.step.sendMail.title')}
			maxHeight={MAX_HEIGHT}
			className={classes.b()}
			isSplit={!!behaviorStep}
			isInvalid={!step.templateId || !params.category}
			onClickBehaviour={onClickBehaviour}
			{...props}
		>
			<Text className={classes.elem('templateName').b()} color={!step.templateId ? 'grey-11' : 'black'}>
				{props.editable ? (
					<Link>{step.template?.name ? step.template?.name : T('form.chooseTemplate')}</Link>
				) : null}
				{!props.editable ? (step.template?.name ? step.template?.name : T('flow.noTemplate')) : null}
			</Text>
			{behaviorStep ? (
				<Block
					space="mts"
					className={classes.elem('behaviorBlock').b()}
					onClick={e => {
						e.stopPropagation();
						onClickBehaviour!();
					}}
				>
					{props.editable ? (
						<>
							<Tooltip title={behaviorTooltipText} theme="dark break-all">
								<Text size="sm">
									<Link color="inherit">{behaviorText}</Link>
								</Text>
							</Tooltip>
							<Tooltip className={classes.elem('deleteTooltip').b()} title={T('flow.removeStep')}>
								<Icon
									name="trash"
									onClick={e => {
										e.stopPropagation();
										onDeleteBehavior();
									}}
									className={classes.elem('trash').b()}
									color="grey-9"
								/>
							</Tooltip>
						</>
					) : (
						<Tooltip title={behaviorTooltipText} theme="dark break-all">
							<Text size="sm">{behaviorText}</Text>
						</Tooltip>
					)}
				</Block>
			) : step.templateId && props.editable ? (
				<Block space="mts">
					<Text size="sm">
						{isbehaviorSelectVisible ? (
							<Row>
								<Column>
									<Link
										onClick={e => {
											e.stopPropagation();
											setIsbehaviorSelectVisible(false);
											onAddBehavior('readMail');
										}}
									>
										<Icon name="eye" space="mrs" />
										{T('mailCampaign.openedMail')}
									</Link>
								</Column>
								<Column>
									<Link
										onClick={e => {
											e.stopPropagation();
											setIsbehaviorSelectVisible(false);
											onAddBehavior('clickedMail');
										}}
									>
										<Icon name="mouse-pointer" space="mrs" />
										{T('flow.clickedMail')}
									</Link>
								</Column>
							</Row>
						) : (
							<Link
								onClick={e => {
									e.stopPropagation();
									setIsbehaviorSelectVisible(true);
								}}
							>
								<Icon name="plus" space="mrs" />
								{T('flow.addBehavior')}
							</Link>
						)}
					</Text>
				</Block>
			) : null}
		</FlowStepBase>
	);
};

export default FlowStepSendMail;

'use strict';

angular.module('upDirectives').directive('upTypeAhead', [
	'$q',
	function ($q) {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				changeFunction: '=upTypeAhead'
			},
			link: function ($scope, $element, $attr, ngModel) {
				var drilldown = angular.element('<ul/>').addClass('up-type-ahead-items');
				var dontShowDropdown = false;

				$element.bind('keydown keypress', function (event) {
					if (!event.shiftKey && (event.which === 13 || event.which === 38 || event.which === 40)) {
						event.preventDefault();
						var activeElement = drilldown.find('.active');

						if (!activeElement.length) {
							return;
						}

						switch (event.which) {
							// Enter
							case 13:
								var value = activeElement.text();

								dontShowDropdown = true;
								ngModel.$setViewValue(value);
								ngModel.$render();
								break;
							// Up
							case 38:
								var nextElement = $(activeElement).prev('li');

								if (nextElement && nextElement[0]) {
									activeElement.removeClass('active');
									nextElement.addClass('active');
								}
								break;
							// Down
							case 40:
								var nextElement = $(activeElement).next('li');

								if (nextElement && nextElement[0]) {
									activeElement.removeClass('active');
									nextElement.addClass('active');
								}
								break;
						}
					}
				});

				$element.addClass('up-type-ahead-wrapper');
				$element.parent().append(drilldown);

				$element
					.focus(function () {
						drilldown.addClass('visible');
					})
					.blur(function () {
						drilldown.removeClass('visible');
					});

				ngModel.$viewChangeListeners.push(function () {
					$q.when($scope.changeFunction(ngModel.$modelValue)).then(function (res) {
						drilldown.empty();

						if ((res.length === 1 && res[0] === ngModel.$modelValue) || dontShowDropdown) {
							dontShowDropdown = false;
							return;
						}

						var first = true;

						res.forEach(function (value) {
							if (value === $element[0].value) {
								return;
							}

							var row = angular
								.element('<li/>')
								.addClass('type-ahead-item')
								.mousedown(function () {
									ngModel.$setViewValue(value);
									ngModel.$render();
								})
								.mouseover(function () {
									$element.parent().find('.active').removeClass('active');
									angular.element(this).addClass('active');
								})
								.text(value);

							if (first) {
								first = false;
								row.addClass('active');
							}

							drilldown.append(row);
						});
					});
				});
			}
		};
	}
]);

'use strict';

angular.module('upResources').factory('EmailDuplicates', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ApiKey($resource, $q, URL, API) {
		var Resource = $resource(
			URL + API + 'function/email-duplicates/:clientId',
			{ clientId: '@id' },
			{
				get: { method: 'GET', isArray: false }
			}
		);

		return {
			get: function (clientId, excludedId, email) {
				return Resource.get({ clientId: clientId, excludedid: excludedId, email: email }).$promise;
			}
		};
	}
]);

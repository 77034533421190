import './OpportunityWinLossButtons.scss';
import React, { useMemo, useState } from 'react';

import OrderCloseDatePicker from 'App/components/OrderCloseDatePicker';
import { OutsideClick, Card, Button, Icon, Text, Row, Column, Block, Tooltip, IconName } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import OrderStage from 'App/resources/Model/OrderStage';

type Props = {
	onSave?: (stage?: OrderStage | null, date?: Date | null) => void;
	onClick?: () => boolean;
	getPastDate?: () => Date;
	skipStageOption?: boolean;
	buttonsDisabled?: boolean;
	loading?: boolean;
	buttonsDisabledTooltip?: string;
};

type OutcomeModel = {
	title: string;
	color: string;
	iconName: IconName;
	elemName: string;
	titleText: string;
	noStageTooltip: string;
	openState?: boolean;
	dropdownItems?: JSX.Element[];
	stage?: OrderStage | null;
	setOpenHandler?: (openState: boolean) => void;
	openDatePicker?: () => void;
	datePickerOpen?: boolean;
	onDateChange?: (date: Date | null) => void;
	onClickHandler?: () => void;
};

type Outcomes = {
	[key: string]: OutcomeModel;
};

const OpportunityWinLossButtons = ({
	onSave,
	onClick,
	getPastDate,
	skipStageOption,
	buttonsDisabled,
	buttonsDisabledTooltip,
	loading
}: Props) => {
	const outcomes: Outcomes = {
		won: {
			title: T('opportunity.outcome.won'),
			color: 'green',
			iconName: 'check',
			elemName: 'wonButton',
			titleText: T('opportunity.outcome.wonSelectStage'),
			noStageTooltip: T('opportunity.outcome.noWonStageAvailable')
		},
		lost: {
			title: T('opportunity.outcome.lost'),
			color: 'super-light-red',
			iconName: 'times-circle',
			elemName: 'lostButton',
			titleText: T('opportunity.outcome.lostSelectStage'),
			noStageTooltip: T('opportunity.outcome.noLostStageAvailable')
		}
	};

	const [wonStagesOpen, setWonStagesOpen] = useState(false);
	const [lostStagesOpen, setLostStagesOpen] = useState(false);
	const [orderDate, setOrderDate] = useState<Date | null | undefined>();
	const [wonDatePickerOpen, setWonDatePickerOpen] = useState(false);
	const [lostDatePickerOpen, setLostDatePickerOpen] = useState(false);
	const classes = new BemClass('OpportunityWinLossButtons');
	const wonStages = window.Tools.AppService.getStages('won');
	const lostStages = window.Tools.AppService.getStages('lost');

	const renderColumnText = (text: string, isTitle?: boolean) => {
		let space = 'ptl pll prl ';
		space += isTitle ? 'pbs' : 'pbl';

		return (
			<Block className={isTitle ? classes.elem('title').b() : ''} space={space}>
				<Text bold={isTitle}>{text}</Text>
			</Block>
		);
	};

	const dropdownStageItem = (stage: OrderStage) => (
		<Row className={classes.elem('item').b()} key={stage.id} onClick={() => onSave?.(stage, orderDate)}>
			<Column align="left" className={classes.elem('columnStageName').b()}>
				{renderColumnText(stage.name)}
			</Column>
			<Column fixedWidth={50}>{renderColumnText(`${stage.probability}%`)}</Column>
		</Row>
	);

	const wonStagesDropdownItems = useMemo(() => {
		return wonStages.map(stage => dropdownStageItem(stage));
	}, [wonStages]);

	const lostStagesDropdownItems = useMemo(() => {
		return lostStages.map(stage => dropdownStageItem(stage));
	}, [lostStages]);

	const openDatePicker = (defaultDate?: Date, outcome?: string) => {
		setOrderDate(defaultDate);
		if (outcome === 'won') {
			setWonDatePickerOpen(true);
		} else {
			setLostDatePickerOpen(true);
		}
	};

	const closeDatePicker = (outcome: string) => {
		if (outcome === 'won') {
			setWonDatePickerOpen(false);
		} else {
			setLostDatePickerOpen(false);
		}
	};

	const getOutcomeModel = (outcome: string) => {
		const wonOutcome = outcome === 'won';
		const outcomeModel = outcomes[outcome.toString()];

		outcomeModel.openState = wonOutcome ? wonStagesOpen : lostStagesOpen;
		outcomeModel.dropdownItems = wonOutcome ? wonStagesDropdownItems : lostStagesDropdownItems;

		const stages = wonOutcome ? wonStages : lostStages;
		outcomeModel.stage = stages[0];

		outcomeModel.setOpenHandler = wonOutcome ? setWonStagesOpen : setLostStagesOpen;
		const pastDate = getPastDate?.();
		outcomeModel.openDatePicker = () => openDatePicker(pastDate, wonOutcome ? 'won' : 'lost');
		outcomeModel.datePickerOpen = wonOutcome ? wonDatePickerOpen : lostDatePickerOpen;

		const closeDealHandler = (date?: Date | null) => {
			if ((outcomeModel.dropdownItems?.length ?? 0) > 1 && !skipStageOption) {
				return outcomeModel.setOpenHandler?.(!outcomeModel.openState);
			}
			return onSave?.(outcomeModel.stage, date || orderDate);
		};

		outcomeModel.onDateChange = (date: Date | null) => {
			setOrderDate(date);
			closeDatePicker(outcome);
			closeDealHandler(date);
		};

		outcomeModel.onClickHandler = () => {
			const handler = pastDate && wonOutcome ? outcomeModel.openDatePicker : () => closeDealHandler();

			if (!onClick || onClick()) {
				return handler?.();
			}
		};

		return outcomeModel;
	};

	const renderDatePicker = (outcome: string, outcomeModel: OutcomeModel) => (
		<OutsideClick
			key={outcome}
			className={classes.b()}
			targetClass="OrderCloseDatePicker"
			outsideClick={() => closeDatePicker(outcome)}
			listen={outcomeModel.datePickerOpen}
		>
			<OrderCloseDatePicker
				date={orderDate}
				onChange={outcomeModel.onDateChange}
				visible={!!outcomeModel.datePickerOpen}
			/>
		</OutsideClick>
	);

	const renderButton = (outcome: string) => {
		const outcomeModel = getOutcomeModel(outcome);
		const disabled = !outcomeModel.stage;
		return (
			<OutsideClick
				key={outcome}
				className={classes.b()}
				targetClass="OpportunityWinLossButtons"
				outsideClick={() => outcomeModel.setOpenHandler?.(false)}
				listen={outcomeModel.openState}
			>
				<Tooltip
					distance={22}
					disabled={!disabled && !buttonsDisabled}
					theme={outcomeModel.color}
					title={buttonsDisabled ? buttonsDisabledTooltip : outcomeModel.noStageTooltip}
				>
					<Button
						color={outcomeModel.color}
						className={classes.elem(outcomeModel.elemName).b()}
						onClick={() => {
							setWonStagesOpen(false);
							setLostStagesOpen(false);
							outcomeModel.onClickHandler?.();
						}}
						shadow="none"
						loading={loading}
						disabled={buttonsDisabled || disabled}
					>
						<Icon name={disabled ? 'warning' : outcomeModel.iconName} space="mrl" />
						<span>{outcomeModel.title}</span>
					</Button>
				</Tooltip>

				<Card className={classes.elem('menu').mod({ open: outcomeModel.openState }).b()}>
					{renderColumnText(outcomeModel.titleText, true)}
					{outcomeModel.dropdownItems}
				</Card>

				{renderDatePicker(outcome, outcomeModel)}
			</OutsideClick>
		);
	};

	return (
		<div className={classes.b()}>
			{renderButton('lost')}
			{renderButton('won')}
		</div>
	);
};

export default OpportunityWinLossButtons;

import './WonOpportunity.scss';
import moment from 'moment';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Text, Block, Button, Headline, ModalHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import UpsalesAI from 'App/babel/resources/UpsalesAI';
import { sortBy } from 'lodash';
import logError from 'App/babel/helpers/logError';
import { connect } from 'react-redux';
import Client from 'App/resources/Model/Client';
import RecommendedLeads from 'App/components/RecommendedLeads';
import SalesTargets from 'App/components/SalesTargets';
import Lottie from 'react-lottie';
import OrderCloseDatePicker from 'App/components/OrderCloseDatePicker';
import Order from 'App/resources/Model/Order';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { SalesWidget } from 'App/components/SalesTargets/SalesTargetWidget';
import { useAnimationData } from '../hooks';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';
import { getCountryFromProspectingId, validateOrgNumberForCountry } from 'App/services/prospectingService';

export type WonOpportunityProps = {
	closeModal?: (position: string) => void;
	onCreateSubscription: Function;
	order: Order;
	visible: boolean;
	reloadModalPosition: Function;
	animate: boolean;
	isModal: boolean;
	reject?: () => void;
	userWidgets: SalesWidget[];
	changeOrderCloseDate: Function;
};

const mapStateToProps = (state: { Goals: { userWidgets: SalesWidget[] } }) => ({
	userWidgets: state.Goals.userWidgets
});

const WonOpportunity: React.FC<WonOpportunityProps> = ({
	closeModal,
	onCreateSubscription,
	order,
	visible,
	reloadModalPosition,
	animate,
	isModal,
	reject,
	userWidgets,
	changeOrderCloseDate
}) => {
	const closeTimer = useRef<ReturnType<typeof setTimeout>>();
	const classes = new BemClass('WonOpportunity');
	const [fadeIn, setFadeIn] = useState(() => false);
	const companyName = order?.client?.name ?? '';
	const [recommendedLeads, setRecommendedLeads] = useState<ProspectingClient[]>(() => []);
	const [recommendedLeadsFetched, setRecommendedLeadsFetched] = useState(false);
	const [salesWidgets, setSalesWidgets] = useState<SalesWidget[]>([]);
	const [salesWidgetsFetched, setSalesWidgetsFetched] = useState(false);
	const [showCloseDatePicker, setShowCloseDatePicker] = useState(false);
	const orderCloseDate = order?.closeDate || order?.date;
	const closeDateWithinCurrentMonth =
		orderCloseDate && moment(orderCloseDate).isSame(moment().format('YYYY-MM-DD'), 'month');

	const getSalesWidgetsWithBudget = (widgets: SalesWidget[]) => {
		return widgets?.filter(widget => {
			return ['sales', 'rr'].includes(widget?.data?.type?.family) && widget?.data?.total?.goal > 0;
		});
	};

	const { animationData } = useAnimationData(() => import('./confetti.json'));

	useEffect(() => {
		if (userWidgets) {
			const salesWidgetsWithoutBudget = getSalesWidgetsWithBudget(userWidgets);
			if (salesWidgetsWithoutBudget.length) {
				setSalesWidgets(salesWidgetsWithoutBudget);
			} else {
				setSalesWidgetsFetched(true);
			}
		} else {
			setSalesWidgetsFetched(true);
		}
	}, [userWidgets]);

	useEffect(() => {
		if (salesWidgets.length) {
			setSalesWidgetsFetched(true);
		}
	}, [salesWidgets]);

	useEffect(() => {
		if (visible && recommendedLeadsFetched && salesWidgetsFetched) {
			setFadeIn(true);
		}
	}, [visible, recommendedLeadsFetched, salesWidgetsFetched]);

	useEffect(() => {
		if (fadeIn && reloadModalPosition) {
			reloadModalPosition();
		}
	}, [fadeIn, reloadModalPosition]);

	const onClose = closeModal ? () => closeModal('top') : reject;

	useEffect(() => {
		const componentsLoaded = recommendedLeadsFetched && salesWidgetsFetched;
		const componentsNotRendered = !recommendedLeads.length && !salesWidgets.length;
		if (visible && componentsLoaded && componentsNotRendered) {
			if (closeTimer.current) {
				clearTimeout(closeTimer.current);
			}
			const closeDelay = 4000;
			if (onClose && !onCreateSubscription) {
				closeTimer.current = setTimeout(() => onClose(), closeDelay);
			}
		}
	}, [recommendedLeads, recommendedLeadsFetched, salesWidgets, salesWidgetsFetched, onClose, visible]);

	const sortLeads = (leads: ProspectingClient[]) => sortBy(leads, 'name');
	const fetchRecommendedLeads = async () => {
		setRecommendedLeadsFetched(false);

		if (!order?.client) {
			setRecommendedLeadsFetched(true);
			return;
		}

		const client = order.client as Client;
		const prospectingId = client.prospectingId;
		const orgNumber = client.orgNo;

		if (!prospectingId || !orgNumber) {
			setRecommendedLeadsFetched(true);
			return;
		}

		const country = getCountryFromProspectingId(prospectingId) ?? 'SE';
		const cleanOrgNumber = orgNumber.replace(/[^A-Za-z0-9]/g, '');
		const isValidOrgNumber = validateOrgNumberForCountry(country, cleanOrgNumber);

		if (!isValidOrgNumber) {
			setRecommendedLeadsFetched(true);
			return;
		}

		try {
			const res = await UpsalesAI.find({ orgNumbers: [cleanOrgNumber], country });
			const prospects = res?.data?.length > 0 ? res.data : [];
			const similarCompanies = prospects.length > 4 ? prospects.slice(0, 10) : prospects;
			setRecommendedLeads(sortLeads(similarCompanies as ProspectingClient[]));
		} catch (err) {
			logError(err, 'Failed to fetch similar companies');
		}
		setRecommendedLeadsFetched(true);
	};

	const onCloseDateChange = (date: Date | null) => {
		if (!date) {
			setShowCloseDatePicker(false);
			return;
		}

		setShowCloseDatePicker(false);
		if (changeOrderCloseDate && typeof changeOrderCloseDate === 'function') {
			changeOrderCloseDate(date);
		}
	};

	useEffect(() => {
		fetchRecommendedLeads();
	}, [order?.client]);

	const renderHeader = () => (
		<ModalHeader
			className={classes.elem('header').mod({ isModal }).b()}
			title={T('order.opportunityWithCompany', { companyName })}
			icon="opportunity"
			onClose={onClose}
		/>
	);

	const renderCongratulationsText = () => {
		const name = order?.user?.name ?? '';
		return (
			<Block space="mtxl mbxl">
				<Headline className={classes.elem('congratulationsText').b()} size="xs">
					{T('order.celebration.congratulationsText', { name })}
				</Headline>
			</Block>
		);
	};

	const getNbrOfReachedTargets = () => {
		const oneOffValue = order?.oneOffValue ?? 0;
		const monthlyValue = order?.monthlyValue ?? 0;

		let prevReachedTargets = 0;
		let newReachedTargets = 0;

		// CALCULATES NUMBER OF PREVIOUSLY AND NEWLY REACHED BUDGETS
		salesWidgets.forEach(widget => {
			const remainingTarget = widget?.data?.total?.remaining;
			const salesType = widget?.data?.type?.name; // 'SALES' || 'ONEOFF' || 'RECURRING'
			const goal = widget?.data?.total?.goal;
			const progress = widget?.data?.total?.progress;

			if (salesType === 'ONEOFF' || salesType === 'SALES') {
				if (progress - oneOffValue >= goal) {
					prevReachedTargets++;
				} else if (progress >= goal) {
					newReachedTargets++;
				}
			} else if (salesType === 'RECURRING') {
				if (remainingTarget <= 0) {
					prevReachedTargets++;
				} else if (progress + monthlyValue >= goal) {
					newReachedTargets++;
				}
			}
		});

		return { prevReachedTargets: prevReachedTargets, newReachedTargets: newReachedTargets };
	};

	const getTargetStatus = () => {
		const targets = salesWidgets.length;
		let targetStatus = '';
		const { prevReachedTargets, newReachedTargets } = getNbrOfReachedTargets();

		if (targets < 1) {
			targetStatus = T('order.celebration.targetNotSet');
		} else if (newReachedTargets) {
			// NEW TARGET REACHED
			if (newReachedTargets + prevReachedTargets === targets) {
				//ALL TARGETS REACHED
				targetStatus =
					targets > 1 ? T('order.celebration.targetsReached') : T('order.celebration.targetReachedOne');
			} else {
				// LESS THAN ALL TARGETS REACHED
				targetStatus = T('order.celebration.targetReachedMul');
			}
		} else if (prevReachedTargets === targets) {
			// ALL TARGETS HAVE ALREADY BEEN REACHED
			const nbrOfTextVariations = 3; //probably shouldn't be hardcoded but unsure how to get the number of variations...
			const textVariation = Math.floor(Math.random() * nbrOfTextVariations + 1);
			targetStatus = T(`order.celebration.targetsAlreadyReached${textVariation}`);
		} else {
			//NO NEW TARGET REACHED
			targetStatus =
				targets > 1 ? T('order.celebration.closerToTargetsText') : T('order.celebration.closerToTargetText');
		}

		return targetStatus;
	};

	const renderTargetStatusText = () => (
		<Block space="mbxl">
			<Headline className={classes.elem('targetStatusText').b()} size="sm">
				{getTargetStatus()}
			</Headline>
		</Block>
	);

	const renderCreateSubscriptionButton = () => (
		<Block space="mtxl">
			<Button
				className={classes.elem('createSubscriptionButton').b()}
				onClick={() => {
					onCreateSubscription();
					onClose?.();
				}}
				size="lg"
				text={T('order.celebration.continueToCreateSubscription')}
			/>
		</Block>
	);

	const renderCloseButton = () => (
		<Block space="mtl pbxl">
			<Button
				className={classes.elem('closeButton').b()}
				onClick={onClose}
				text={T('close')}
				type="link"
				color="grey"
			/>
		</Block>
	);

	const defaultOptions = {
		loop: false,
		autoplay: fadeIn,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
			className: classes.elem('confetti').b()
		}
	};

	const renderConfetti = () => (
		<div className={classes.elem('confetti').b()}>
			<Lottie options={defaultOptions} isClickToPauseDisabled={true} />
		</div>
	);

	const renderChangeCloseDate = () => (
		<Block className={classes.elem('changeCloseDateBlock').b()}>
			<Text color="grey-11" className={classes.elem('closeDateNotAffectingSalesTargets').b()}>
				{T('order.celebration.closeDateNotAffectingSalesTargets', {
					closeDate: orderCloseDate ? moment(orderCloseDate).format('L') : null
				})}

				<Block className={classes.elem('changeCloseDateButtonContainer').b()}>
					<Button
						type="link"
						className={classes.elem('changeCloseDateButton').b()}
						onClick={() => setShowCloseDatePicker(!showCloseDatePicker)}
					>
						&nbsp;{T('order.celebration.changeCloseDateButtonText')}
					</Button>
					<OrderCloseDatePicker visible={showCloseDatePicker} onChange={onCloseDateChange} />
				</Block>
			</Text>
		</Block>
	);

	const salesPrognosis = useMemo(() => {
		if (onCreateSubscription && order) {
			const cmWithRROption = getCMWithRROption();
			const recurringSalesModelActive =
				Tools.AppService.getMetadata().params.SalesModel === 'rr' || !!cmWithRROption;
			if (!recurringSalesModelActive) {
				return 0;
			}
			const recurringSalesModelType = Tools.AppService.getMetadata().params.SalesModelOption;
			if (recurringSalesModelType === 'mrr' || cmWithRROption === 'mrr') {
				return order.monthlyValue;
			}
			return order.annualValue;
		}
	}, [onCreateSubscription, order]);

	return (
		<div className={classes.mod({ visible, animate }).b()}>
			{renderHeader()}

			<Block
				space="ptxl pbxl prxl plxl"
				className={classes
					.elem('content')
					.mod({
						isModal,
						fadeIn
					})
					.b()}
			>
				{fadeIn && renderConfetti()}
				<div
					className={classes
						.elem('contentText')
						.mod({
							fadeIn,
							pushUp: !isModal && !recommendedLeads.length,
							small:
								recommendedLeadsFetched &&
								salesWidgetsFetched &&
								!recommendedLeads.length &&
								salesWidgets.length === 1
						})
						.b()}
				>
					{renderCongratulationsText()}
					{renderTargetStatusText()}

					{onCreateSubscription && renderCreateSubscriptionButton()}
					{onClose && renderCloseButton()}

					<SalesTargets visible={visible} salesWidgets={salesWidgets} salesPrognosis={salesPrognosis} />
					{closeDateWithinCurrentMonth ? null : renderChangeCloseDate()}
					<RecommendedLeads order={order} recommendedLeads={recommendedLeads} />
				</div>
			</Block>
		</div>
	);
};

export const detached = WonOpportunity;

export default connect(mapStateToProps)(WonOpportunity);

'use strict';

angular.module('upResources').factory('ListView', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'ResourceHelper',
	'ParseGeneric',
	'AppService',
	'UdoLink',
	'$translate',
	'FeatureHelper',
	function ListView(
		$resource,
		$q,
		URL,
		API,
		GenericMapper,
		ResourceHelper,
		ParseGeneric,
		AppService,
		UdoLink,
		$translate,
		FeatureHelper
	) {
		var visibilityTypes = {
			OWN: 'own',
			PUBLIC: 'public',
			ROLE: 'role'
		};

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'listView',
			createSuccessBody: 'saved.listView',
			updateSuccessBody: 'updated.listView',
			deleteSucccessBody: 'deleted.listView',
			createErrorBody: 'saveError.listView',
			updateErrorBody: 'saveError.listView',
			deleteErrorBody: 'deleteError.listView'
		});

		var getUdoId = function (str) {
			var res = parseInt(str[str.length - 1]);
			return isNaN(res) ? 1 : res;
		};

		var parseUdo = function (res) {
			try {
				res = JSON.parse(res);
			} catch (e) {
				return res;
			}

			if (!res.data || !res.data.length) {
				return res;
			}
			var udoId = getUdoId(res.data[0].type);
			var cacheKey = 'userDefined' + udoId;
			var cFields = AppService.getCustomFields(cacheKey);
			var c2Fields = AppService.getCategoryTypes(cacheKey);
			var metadata = AppService.getMetadata();
			var udoObj = _.find(metadata.params.UserDefinedObject, { id: udoId });

			cFields = _.sortBy(cFields, 'sortId');

			angular.forEach(res.data, function (view) {
				if (view.columns && !view.columns.length) {
					angular.forEach(cFields, function (field) {
						if (field.visible && field.datatype !== 'Text') {
							view.columns.push('Custom_' + field.id);
						}
					});
					angular.forEach(c2Fields, function (field) {
						view.columns.push('Category_' + field.id);
					});
				}
				if (udoObj) {
					if (!view.private) {
						view.title =
							udoObj && udoObj.name && udoObj.name.length
								? udoObj.name
								: '<<' + $translate.instant('default.noName') + '>>';
					}
				}
				view.columns = view.columns.slice(0, 6);
			});
			return res;
		};

		var Model = {
			customer: function (customer) {
				var instance = {};
				instance.visibilityType = null;
				instance.entityType = null;

				var methods = {
					get: {
						method: 'GET',
						isArray: false,
						skipDateConvert: true,
						transformResponse: ParseGeneric('ParseListView', { isArray: false, custom: false })
					},
					find: {
						method: 'GET',
						isArray: false,
						skipDateConvert: true,
						transformResponse: ParseGeneric('ParseListView', { isArray: true, custom: false })
					},
					findUdo: { method: 'GET', isArray: false, skipDateConvert: true, transformResponse: parseUdo },
					create: { method: 'POST', isArray: false, skipDateConvert: true },
					update: { method: 'PUT', isArray: false, skipDateConvert: true }
				};

				var Resource = $resource(URL + API + customer + '/listViews/:entityType/:id', {}, methods);

				var OldResource = $resource(
					URL + API + customer + '/list/:visibilityType/:entityType/:id',
					{},
					methods
				);

				var getResource = function () {
					if (
						!FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS) ||
						(instance.entityType && instance.entityType.toLowerCase() === 'salesboard')
					) {
						return OldResource;
					}
					return Resource;
				};

				instance.get = function (type, id) {
					var params = {
						id: id,
						entityType: type
					};
					instance.entityType = type;

					return getResource().get(params).$promise;
				};

				instance.find = function (filter, options) {
					if (!instance.entityType) {
						throw new Error('No entity type set for list view resource');
					}
					if (!instance.visibilityType) {
						throw new Error('No visibility type set for list view resource');
					}

					var params = angular.extend(filter, options);
					params.visibilityType = instance.visibilityType;
					params.entityType = instance.entityType;
					instance.visibilityType = null;
					instance.entityType = null;

					if (
						params.entityType.indexOf('userdefobj') !== -1 ||
						params.entityType.indexOf('userDefinedObject') !== -1
					) {
						return getResource().findUdo(params).$promise;
					}

					return getResource().find(params).$promise;
				};

				instance.save = function (view, options) {
					var defered = $q.defer();

					if (!instance.entityType) {
						throw new Error('No entity type set for list view resource');
					}
					if (!instance.visibilityType) {
						throw new Error('No visibility type set for list view resource');
					}

					if (!view) {
						return defered.reject('No view');
					}

					var promise;

					if (
						!FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS) &&
						instance.entityType.indexOf('userDefinedObject') === 0
					) {
						var nr = parseInt(instance.entityType[instance.entityType.length - 1]);
						if (!isNaN(nr) && nr > 1 && nr < 5) {
							instance.entityType = 'userdefobj' + nr;
						} else {
							instance.entityType = 'userdefobj';
						}
					}

					// update
					if (view.id) {
						promise = getResource().update(
							{ id: view.id, visibilityType: instance.visibilityType, entityType: instance.entityType },
							view
						).$promise;
					} else {
						promise = getResource().create(
							{ visibilityType: instance.visibilityType, entityType: instance.entityType },
							view
						).$promise;
					}

					promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !view.id);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !view.id);
						});

					instance.visibilityType = null;
					instance.entityType = null;

					return promise;
				};

				instance['delete'] = function (view, options) {
					if (!instance.entityType) {
						throw new Error('No entity type set for list view resource');
					}

					if (instance.entityType.indexOf('userDefinedObject') === 0) {
						var nr = parseInt(instance.entityType[instance.entityType.length - 1]);
						if (!isNaN(nr) && nr > 1 && nr < 5) {
							instance.entityType = 'userdefobj' + nr;
						} else {
							instance.entityType = 'userdefobj';
						}
					}

					return getResource()
						.delete({ id: view.id, visibilityType: 'whatever', entityType: instance.entityType })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, view);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				instance.setVisibilityType = function (type) {
					instance.visibilityType = type;
					return instance;
				};

				instance.setEntityType = function (type) {
					if (FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS)) {
						if (type === 'client') {
							type = 'account';
						}
					}

					instance.entityType = type;
					return instance;
				};

				return instance;
			},

			visibilityTypes: visibilityTypes
		};
		return Model;
	}
]);

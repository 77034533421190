'use strict';

angular.module('upDirectives').directive('upEsignProgress', [
	'$state',
	function ($state) {
		return {
			restrict: 'A',
			template: '<div class="up-esign-progress"></div>',
			replace: true,
			link: function ($scope, $element, $attrs) {
				var getInitials = function (p) {
					return (p.fstname[0] || '') + (p.sndname[0] || '');
				};

				var render = function (involved) {
					$element.html('');

					if (involved.length > 8) {
						var signed = _.filter(involved, function (p) {
							return !!p.sign;
						}).length;
						var string = signed + '<span class="total-involvees">/' + involved.length + '</span>';
						var span = angular.element('<span class="progress-string">' + string + '</span>');

						if (signed === involved.length) {
							span.addClass('all-signed');
						} else if (
							_.find(involved, function (p) {
								return p.declineDate;
							})
						) {
							span.addClass('rejected');
						} else if (signed > 0) {
							span.addClass('signed');
						}

						$element.append(span);
					} else {
						var future = moment().add(1, 'year').toDate();
						involved = _.chain(involved)
							.sortBy(function (p) {
								if (!p.sign) {
									return future;
								}
								return p.sign;
							})
							.sortBy(function (person) {
								return person.userId ? 0 : 1;
							})
							.value();
						angular.forEach(involved, function (person) {
							var initials = getInitials(person);
							var ball = angular.element('<div class="ball"><span>' + initials + '</span></div>');
							ball.tooltip({
								title: person.fstname + ' ' + person.sndname,
								container: 'body',
								placement: 'top',
								animation: false
							});

							if (person.contactId) {
								ball.addClass('contact');
							}

							ball.on('click', function (e) {
								e.stopPropagation();
								if (person.contactId) {
									ball.tooltip('hide');
									ball.tooltip('destroy');
									$state.go('contact.dashboard', { id: person.contactId });
								}
							});

							if (person.undelivered) {
								ball.append('<b class="fa fa-warning text-orange"></b>');
							}

							if (person.sign) {
								ball.addClass('signed');
							} else if (person.declineDate) {
								ball.addClass('rejected');
							}
							$element.append(ball);
						});
					}
				};

				$scope.$watch($attrs.upEsignProgress, function (involved) {
					if (involved) {
						render(involved);
					}
				});
			}
		};
	}
]);

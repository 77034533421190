'use strict';

angular.module('upResources').factory('LinkedIn', [
	'$resource',
	'$q',
	'URL',
	'API',
	'$routeParams',
	function LinkedIn($resource, $q, URL, API, $routeParams) {
		var apiKey = '75d1zhwcrqplxi';
		var customerId = $routeParams.customerId;

		var instance = {};
		var LinkedIn = $resource('https://www.linkedin.com/uas/oauth2/:path', {}, { save: {} });
		instance.authorize = function () {
			var defered = $q.defer();
			LinkedIn.save(
				{
					path: 'authorization',
					response_type: 'code',
					client_id: apiKey,
					state: '89s7dIVV6796s8JRy986',
					redirect_uri: 'http://localhost:3000/#/' + customerId + '/'
				},
				function (response) {
					defered.resolve(response);
				},
				function (response) {
					defered.reject(response.status);
				}
			);

			return defered.promise;
		};

		return instance;
	}
]);

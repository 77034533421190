export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	account: {
		title: 'default.account',
		field: 'client.name'
	},
	subject: {
		title: 'mail.subject',
		field: 'subject'
	},
	to: {
		title: 'mail.mail',
		field: 'to',
		locked: true
	},
	clickedLinks: {
		title: 'mail.clickedLinks',
		field: 'events.value'
	},
	from: {
		title: 'default.from',
		field: 'from'
	},
	date: {
		title: 'default.date',
		field: 'date'
	}
};

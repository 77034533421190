import React from 'react';
import PropTypes from 'prop-types';
import './SnoozeBall.scss';

type Props = {
	text?: string;
	size?: string;
};
const SnoozeBall = (props: Props) => {
	const { text = '', size = 'medium' } = props;

	return (
		<div className={`snooze-ball ${size}`}>
			{text}
			<b className="up-icon-snooze" />
		</div>
	);
};

SnoozeBall.propTypes = {
	text: PropTypes.string,
	size: PropTypes.string
};

export default SnoozeBall;

import { Checkbox } from '@upsales/components';
import React from 'react';

class AnonCheckBox extends React.Component {
	generateText(type, text) {
		return <span key={type}>{text}</span>;
	}

	render() {
		const { customTitle, customText, elevio, checked, onChange, type } = this.props;
		return (
			<div className="checker-item">
				<div className="checkbox-holder">
					<Checkbox checked={checked} onChange={onChange} />
				</div>
				<div className="text-content">
					<h3 className="regular-table-title">
						{customTitle}
						{elevio ? <ReactTemplates.elevio articleId={elevio} sidebar={true} /> : null}
					</h3>
					<p key={`generate-text_${type}`}>{this.generateText(type, customText)}</p>
				</div>
			</div>
		);
	}
}

export default AnonCheckBox;

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';

const getter = options => {
	options = options || {};
	const AppService = Tools.AppService;
	const CountryCodes = Tools.CountryCodes;

	var countries = CountryCodes.countries();
	const langKey = options.isLanguage ? 'language' : 'lang';
	const countryCodes = options.countryCodes;
	if (countryCodes && countryCodes.length) {
		countries = _.filter(countries, function (country) {
			return countryCodes.indexOf(country.code.toLowerCase()) > -1;
		});
	} else if (options.countryCodes && options.countryCodes.length === 0) {
		countries = [];
	}

	const mapped = _.map(_.cloneDeep(countries), function (cc) {
		cc.translated = Tools.$translate(cc[langKey]);
		return cc;
	});

	const language = AppService.getSelf().language;

	if (language) {
		mapped.sort(sortAlphabetically('translated', false, language));
	}

	return Promise.resolve({ data: mapped, metadata: { total: mapped.length } });
};

const componentDidMount = function (self) {
	const opts = {
		ajax: false,
		asIds: false,
		multiple: self.props.multiple || false,
		required: self.props.required || false,
		idAttr: { field: 'code' },
		titleAttr: { field: 'translated' },
		onChange: self.props.onChange,
		emptyMsg: '',
		matcher: function (term, arg2, object) {
			return object.translated.toLowerCase().indexOf(term.toLowerCase()) !== -1;
		},
		formatSelection: function (country) {
			const options = self.props.options || {};
			const langKey = options.isLanguage ? 'language' : 'lang';
			return Tools.$translate(country[langKey]);
		},
		formatResult: function (country, container, query, escape) {
			return escape(country.translated);
		},
		getter: function () {
			return getter(self.props.options);
		}
	};

	const _opts = _.assign({}, opts, self.props.options);

	const input = jQuery(self._input);
	ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, _opts, input, function (options) {
		self.options = options;
		input.select2(options);
	});
};

class UpCountry extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {};

		componentDidMount(this);
	}
	componentDidUpdate() {
		if (this.props.asIds) {
			let data;
			const input = jQuery(this._input);
			const values = this.props.value;

			if (this.props.value) {
				data = _.filter(this.data, function (country) {
					return values.indexOf(country.code) > -1;
				});
			} else {
				data = [];
			}

			input.select2('data', data);
		} else {
			ReactTemplates.TOOLS.selectHelper.updateValue.call(this);
		}
	}

	componentDidMount() {
		componentDidMount(this);
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (
			this.props.options &&
			this.props.options.updateOnDataChange &&
			newProps.options.countryCodes.length !== this.props.options.countryCodes
		) {
			const input = jQuery(this._input);
			getter(newProps.options)
				.then(codes => {
					this.options.data = codes.data;

					input.select2(this.options);
				})
				.catch(e => console.log('falied to fetch countries', e));
		}
	}

	render() {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	}
}

window.UpCountry = UpCountry;

UpCountry.defaultProps = {
	onChange: () => {},
	options: {}
};

UpCountry.propTypes = {
	value: PropTypes.object,
	asIds: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.object
};

export default UpCountry;

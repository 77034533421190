'use strict';

angular
	.module('upDirectives')

	.directive('hideOnClose', function () {
		return {
			restrict: 'A',
			link: function ($scope, element) {
				element.on('select2-close', function () {
					element.parent().hide();
				});
			}
		};
	})

	.directive('showSelect2', function () {
		return {
			restrict: 'A',
			link: function ($scope, element, attrs) {
				element.on('click', function (e) {
					e.preventDefault();
					e.stopPropagation();
					var el = element.find('#' + attrs.showSelect2);

					// Do not show if disabled
					if (el.is(':disabled')) {
						return;
					}

					el.parent().show();
					el.select2('open');
				});
			}
		};
	});

import React, { useState } from 'react';
import User from 'App/resources/Model/User';
import T from 'Components/Helpers/translate';
import { getInitials } from 'App/helpers/getInitials';
import OutsideClick from '@upsales/components/OutsideClick';

type Props = {
	profileImageId?: number;
	user: User;
	hasGravatar: boolean;
	removeImage: () => void;
	fileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ProfilePicture = ({ profileImageId, user, hasGravatar, removeImage, fileUpload }: Props) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const usingGravatar = hasGravatar && !profileImageId;
	const avatar: { gravatar: true; profileImg: true; initials: string; url?: string } = {
		gravatar: true,
		profileImg: true,
		initials: getInitials(user)
	};

	if (profileImageId) {
		avatar.url =
			Tools.URL + Tools.API + Tools.AppService.getCustomerId() + '/resources/download/internal/' + profileImageId;
	}

	const openDropdown = () => {
		if (dropdownOpen) {
			return;
		}
		if (!profileImageId) {
			return;
		}

		setDropdownOpen(true);
	};

	const profileInput = (
		<input
			type="file"
			name="file"
			id="file-uploader"
			accept=".png, .jpg, .jpeg, .gif"
			onChange={e => fileUpload(e)}
		/>
	);

	return (
		<div className="image-placeholder">
			<div className="profile-img" onClick={openDropdown}>
				<ReactTemplates.TOOLS.avatar
					user={user}
					avatar={avatar}
					size={90}
					style={{ margin: 0 }}
					forceGravatar={usingGravatar}
				/>
				<div className="edit-curtain">
					<b className="Icon Icon-edit" />
				</div>
				{!profileImageId ? <div className="dd-row">{profileInput}</div> : null}
			</div>
			<OutsideClick targetId="profile-img-dropdown" outsideClick={() => dropdownOpen && setDropdownOpen(false)}>
				<div id="profile-img-dropdown" className={dropdownOpen ? 'open' : undefined}>
					<div className="dropdown-inner">
						<div className="dd-row">
							{T('default.uploadImage')}
							{profileInput}
						</div>
						{hasGravatar && !usingGravatar ? (
							<div className="dd-row" onClick={removeImage}>
								{T('admin.useGravatar')}
							</div>
						) : null}
						{!hasGravatar && profileImageId ? (
							<div className="dd-row" onClick={removeImage}>
								{T('admin.useInitials')}
							</div>
						) : null}
					</div>
				</div>
			</OutsideClick>
		</div>
	);
};

export default ProfilePicture;

import React, { useEffect, useState } from 'react';
import { Loader } from '@upsales/components';
import './DelayedLoader.scss';

const DelayedLoader = () => {
	const [shouldRender, setShouldRender] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setShouldRender(true);
		}, 750);
	}, []);

	return shouldRender ? <Loader className="DelayedLoader" /> : <div />;
};

export default DelayedLoader;

'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.form').controller('EditFormSubmit', [
	'$scope',
	'$modalParams',
	'FormSubmit',
	'NotificationService',
	'AppService',
	'$upModal',
	'$translate',
	'Account',
	function ($scope, $modalParams, FormSubmit, NotificationService, AppService, $upModal, $translate, Account) {
		var EditFormSubmit = this;
		var customerId;

		EditFormSubmit.saving = false;

		EditFormSubmit.save = function () {
			EditFormSubmit.saving = true;

			// Map submit values
			var values = [];

			angular.forEach(EditFormSubmit.values, function (value, name) {
				if (Array.isArray(value)) {
					value = value.join(',');
				}
				values.push({
					name: name,
					value: value === null || value === undefined ? '' : value.toString()
				});
			});

			var submit = {
				id: $modalParams.submit.id,
				fieldValues: values
			};

			// eslint-disable-next-line promise/catch-or-return
			FormSubmit.customer(customerId)
				.save(submit)
				.then(function () {
					$scope.resolve(submit);
				})
				.finally(function () {
					EditFormSubmit.saving = false;
				});
		};

		EditFormSubmit.toggleCheckbox = function (value, model, fieldName) {
			if (!model) {
				return (EditFormSubmit.values[fieldName] = value);
			}

			if (typeof model === 'string') {
				model = model.split(',');
			}
			var index = model.indexOf(value);

			if (index === -1) {
				model.push(value);
			} else {
				model.splice(index, 1);
			}

			EditFormSubmit.values[fieldName] = model;
		};

		EditFormSubmit.toggleSingleCheckbox = function (value, name) {
			if (value === 'on') {
				EditFormSubmit.values[name] = '';
			} else {
				EditFormSubmit.values[name] = 'on';
			}
		};

		EditFormSubmit.removeEntry = function () {
			var submit = $modalParams.submit.id;

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'default.formSubmit',
					body: <div dangerouslySetInnerHTML={{ __html: $translate.instant('formSubmit.deleteInfo')}} />,
					confirmButtonText: 'form.deleteFormSubmit',
					cancelButtonText: 'form.deleteFormSubmitWithAll',
					onClose: onlyDeleteForm => {
						if (onlyDeleteForm === undefined) {
							return;
						}
						if (onlyDeleteForm === false && $modalParams.submit.client) {
							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('errorPrompt', {
									title: 'default.enterEmail',
									body: 'confirm.removeFormSubmitConfirm',
									placeholder: 'default.email',
									required: true,
									type: 'email'
								})
								.then(function (email) {
									var userEmail = AppService.getSelf().email;

									if (email && email === userEmail) {
										// All formSubmits from this client will be deleted..
										$scope.resolve();
										Account.customer(customerId)['delete']($modalParams.submit.client);
									} else {
										NotificationService.addNotification({
											title: 'login.anErrorOccured',
											body: 'confirm.removeFormSubmitConfirmFail',
											style: 'error',
											icon: 'times'
										});
									}
								});
						} else {
							$scope.resolve();
							FormSubmit.customer(customerId).delete(submit);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'confirm.removeFormSubmit',
					body: 'formSubmit.deleteInfo',
					resolveTrue: $translate.instant('form.deleteFormSubmit'), //'Ta bort formulärsubmit',
					resolveTrueBtnClass: 'btn-orange',
					resolveFalse: $translate.instant('form.deleteFormSubmitWithAll'),
					resolveFalseBtnClass: 'btn-lined btn-red',
					no: 'default.abort',
					icon: 'fa-warning'
				})
				.then(function (onlyDeleteForm) {
					if (onlyDeleteForm === false && $modalParams.submit.client) {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('errorPrompt', {
								title: 'default.enterEmail',
								body: 'confirm.removeFormSubmitConfirm',
								placeholder: 'default.email',
								required: true,
								type: 'email'
							})
							.then(function (email) {
								var userEmail = AppService.getSelf().email;

								if (email && email === userEmail) {
									// All formSubmits from this client will be deleted..
									$scope.resolve();
									Account.customer(customerId)['delete']($modalParams.submit.client);
								} else {
									NotificationService.addNotification({
										title: 'login.anErrorOccured',
										body: 'confirm.removeFormSubmitConfirmFail',
										style: 'error',
										icon: 'times'
									});
								}
							});
					} else {
						$scope.resolve();
						FormSubmit.customer(customerId).delete(submit);
					}
				});
		};

		EditFormSubmit.getOptInTitle = function (field) {
			return $translate.instant('form.' + field.name.replace(/\d*\.?(\d|[a-zA-Z])+$/, ''));
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			// Parse values
			EditFormSubmit.values = {};
			EditFormSubmit.fields = $modalParams.form.fields.reduce(function (fields, field) {
				if (field.name.indexOf('Prefs.') === 0 || field.datatype === 'password' || field.datatype === 'info') {
					return fields;
				}

				if (field.options && typeof field.options === 'string') {
					field.options = field.options.split(',').reduce(function (options, option) {
						if (option) {
							options.push(decodeURIComponent(option).trim());
						}
						return options;
					}, []);
				}

				var fieldValue = $modalParams.submit.fieldValues.find(function (fv) {
					return fv.name === field.name;
				});

				if (fieldValue) {
					var value = fieldValue.value ? fieldValue.value.trim() : '';

					if (field.options && Array.isArray(field.options)) {
						if (value.indexOf('admin.anonymization') > -1) {
							value = $translate.instant(value);
						}
					} else if (field.datatype === 'number') {
						value = parseInt(value);

						if (isNaN(value)) {
							value = '';
						}
					} else if (field.datatype === 'date') {
						value = moment(value).isValid() ? moment(value).toDate() : null;
					}

					EditFormSubmit.values[field.name] = value;
				}

				fields.push(field);
				return fields;
			}, []);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

'use strict';

angular.module('upResources').factory('FindProspect', [
	'$resource',
	'$q',
	'URL',
	'API',
	'LeadSourceAttributes',
	function FindProspect($resource, $q, URL, API, LeadSourceAttributes) {
		var LA = LeadSourceAttributes();
		var Attribute = LA.attr;
		var Keys = LA.keys;

		var baseUrl = URL + API + 'findProspect';

		var LeadSource = $resource(
			baseUrl,
			{},
			{
				get: { method: 'GET', isArray: false },
				getAll: { method: 'GET', isArray: false, url: baseUrl + 2 }
			}
		);

		var Model = {
			getAllSources: function (filter, options) {
				var params = angular.extend(filter, options);
				return LeadSource.getAll(params).$promise;
			},
			get: function (id) {
				return LeadSource.get(id).$promise;
			},
			find: function (filter, options) {
				var params = angular.extend(filter, options);
				return LeadSource.get(params).$promise;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

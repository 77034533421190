import { useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import React, { useCallback } from 'react';

import { useAdminContext } from 'App/pages/Admin/AdminContext';
import 'App/upsales/common/components/react/admin/sidebar';
import { useSelector, useForceRender } from 'App/components/hooks';
import i18next from 'i18next';
import history from 'App/pages/routes/history';
import AllAdminRoutes from '../allAdminRoutes';

import './AdminSidebar.scss';

const AdminSidebar = () => {
	const { metadata, self } = useSelector(({ App }) => App);
	const { forced, triggerRender } = useForceRender();
	const location = useLocation();
	const routes = AllAdminRoutes();
	const currentRoute = routes.find(route => route.path === location.pathname);
	const { sidebarSearch } = useAdminContext();
	const setPage = (state: any, params: any) => {
		// When REACT_ADMIN is released, this should be replaced with history.push
		if (state === 'ticketRules') {
			history.push('/admin/customer-support/rules');
		} else if (state === 'customerSupport') {
			history.push('/admin/customer-support/verify');
		} else if (state === 'projectplan') {
			history.push('/admin/projectplan');
		} else if (state === 'ticketFields') {
			history.push('/admin/customer-support/fields');
		} else if (state === 'ticketResponseTemplates') {
			history.push('/admin/customer-support/responsetemplates');
		} else if (state !== currentRoute?.oldRouteState) {
			Tools.$state.go('administration.' + state, params);
		}
	};

	const _setForceRender = useCallback(() => {
		triggerRender();
	}, []);

	React.useEffect(() => {
		i18next.on('languageChanged', _setForceRender);
		return () => {
			i18next.off('languageChanged', _setForceRender);
		};
	}, []);

	// TODO: Implement this when porting a page with subpages
	var isSelectedState = function (stateName: string, params: any, opts: any) {
		if (!currentRoute) {
			return false;
		}
		const currentRouteState = `administration.${currentRoute.oldRouteState}`;
		return stateName === currentRouteState;
	};

	const Sidebar = React.createElement(ReactTemplates.admin.sidebar, {
		key: 'admin-sidebar-' + forced,
		rootData: {
			state: currentRoute?.oldRouteState ?? '',
			setPage: setPage,
			isSelectedState: isSelectedState,
			currentUrl: '',
			integrations: {
				userConfigurable: sortBy(metadata?.integrations.userConfigurable ?? [], 'name'),
				global: sortBy(metadata?.integrations.active ?? [], 'name').filter(
					integration => !integration.userOnly
				),
				mainApps: metadata?.mainApps ?? []
			},
			outsideSearch: sidebarSearch,
			self
		}
	});

	return <>{Sidebar}</>;
};

export default AdminSidebar;

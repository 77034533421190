import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import T from 'Components/Helpers/translate';

const currencyFormat = (v, c) => Tools.$filter('currencyFormat')(v, c);

// eslint-disable-next-line no-unused-vars
const SupersearchResultRowOrder = ({ className, item: order, searchStr, closeSearch, defaultCurrency, ...props }) => {
	const classes = new bemClass('SupersearchResultRowOrder', className);

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={order.user ? [order.user] : []}
			searchStr={searchStr}
			customValues={order.custom}
			{...props}
		>
			<Column>
				<Text bold={true} color="black" size="md">
					<SupersearchHighlight searchStr={searchStr} text={order.description} type="string" />
				</Text>
				<Text color="grey-12" size="sm">
					{order.client ? order.client.name : T('default.noAccount')}
				</Text>
			</Column>
			<Column>
				<Text color="black" size="sm">
					{currencyFormat(
						Math.round(order.orderValue * 100) / 100,
						order.currency ? order.currency : defaultCurrency
					)}
				</Text>
				<Text color="grey-12" size="sm">
					{moment(order.date).format('L')}
				</Text>
			</Column>
		</SupersearchResultRow>
	);
};

SupersearchResultRowOrder.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	defaultCurrency: PropTypes.string.isRequired
};

export default SupersearchResultRowOrder;

import React from 'react';
import { Toggle, Label, Title, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './SettingsToggle.scss';

type ToggleProps = { toggleDisabled?: boolean; toggleText: string; onToggleChange: () => void; toggleChecked: boolean };

type Props = ToggleProps & {
	title: string;
	rightColumn: JSX.Element;
	articleId?: number;
	articleText?: string;
	descriptionText: string;
	extraToggle?: Omit<ToggleProps, 'toggleDisabled'>;
};

const SettingsToggle = ({
	title,
	articleId,
	toggleText,
	rightColumn,
	articleText,
	toggleChecked,
	onToggleChange,
	descriptionText,
	toggleDisabled = false,
	extraToggle
}: Props) => {
	const classes = new bemClass('SettingsToggle');
	return (
		<div className={classes.mod({ toggleDisabled }).b()}>
			<div className={classes.elem('left-column').mod({ toggleDisabled }).b()}>
				<Title color="black">{title}</Title>
				<Text color="grey-10">{descriptionText}</Text>
				<Label>
					<Toggle
						size="lg"
						color="medium-green"
						disabled={toggleDisabled}
						onChange={onToggleChange}
						checked={toggleChecked}
					/>
					{toggleText}
				</Label>
				{extraToggle ? (
					<Label>
						<Toggle
							size="lg"
							color="medium-green"
							onChange={extraToggle.onToggleChange}
							checked={extraToggle.toggleChecked}
						/>
						{extraToggle!.toggleText}
					</Label>
				) : null}
				{articleId && articleText ? (
					<ReactTemplates.elevio articleId={articleId} sidebar text={articleText} />
				) : null}
				<div className={classes.elem('card-slice').b()} />
			</div>
			<div className={classes.elem('right-column').b()}>{rightColumn}</div>
		</div>
	);
};

export default SettingsToggle;

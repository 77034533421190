import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import { setContactSearch, setAssignData } from 'Store/reducers/AssignModalReducer';
import './AssignModalContacts.scss';
import { Button, TableColumn, TableRow, Table, Input, Card, TableHeader, Title, Icon } from '@upsales/components';

const mapStateToProps = state => ({
	contacts: state.AssignModal.contacts,
	contactSearch: state.AssignModal.contactSearch,
	selectedContact: state.AssignModal.assign.contact
});

const mapDispatchToProps = {
	setContactSearch,
	setAssignData
};

class AssignModalContacts extends React.Component {
	constructor(props) {
		super(props);
		const t = window.Tools.$translate;

		this.lang = {
			searchContact: t('default.searchContact'),
			select: t('default.select'),
			selected: t('default.selectedOne'),
			noContactsFound: t('noResult.contact'),
			noContactsFoundOnClient: t('default.noContactsOnClient')
		};

		this.columns = [{ title: t('default.name') }, { title: t('default.scoreTotal') }, { title: '' }];

		this.numberFormat = Tools.$filter('numberFormat');
	}

	render() {
		const { setContactSearch, contactSearch, contacts, setAssignData, selectedContact } = this.props;
		const { searchContact, select, noContactsFound, noContactsFoundOnClient, selected } = this.lang;
		const noContactsOnClient = !contacts.length && !contactSearch;

		const classNames = new BemClass('AssignModalContacts');

		return (
			<div className={classNames}>
				<Card>
					{!noContactsOnClient ? (
						<div className={classNames.elem('tools')}>
							<Input
								icon="search"
								placeholder={searchContact}
								value={contactSearch}
								onChange={e => setContactSearch(e.target.value)}
								clear
							/>
						</div>
					) : null}

					<Table>
						{contacts.length ? <TableHeader color="white" columns={this.columns} /> : null}
						{contacts.map(contact => {
							const isSelected = selectedContact && selectedContact.id === contact.id;
							return (
								<TableRow
									key={contact.id}
									onClick={() => setAssignData({ contact, step: 3 })}
									disabled={!isSelected && selectedContact && selectedContact.id !== 0}
								>
									<TableColumn title={contact.name} subtitle={contact.title} />
									<TableColumn title={this.numberFormat(contact.score)} />
									<TableColumn align="right">
										<Button
											type="link"
											color={isSelected ? 'bright-green' : 'bright-blue'}
											size="sm"
										>
											{isSelected ? <Icon name="check" /> : null} {isSelected ? selected : select}
										</Button>
									</TableColumn>
								</TableRow>
							);
						})}
						{!contacts.length ? (
							<TableRow>
								<TableColumn colSpan={3} align="center">
									<Title color="grey-10">
										{noContactsOnClient ? noContactsFoundOnClient : noContactsFound}
									</Title>
								</TableColumn>
							</TableRow>
						) : null}
					</Table>
				</Card>
			</div>
		);
	}
}

AssignModalContacts.propTypes = {
	contacts: PropTypes.array,
	selectedContact: PropTypes.object,
	setContactSearch: PropTypes.func,
	setAssignData: PropTypes.func,
	contactSearch: PropTypes.string
};

export const detached = AssignModalContacts;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalContacts);
export default Component;

import React from 'react';
import PropTypes from 'prop-types';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { CardHeader, Table, TableHeader, TableColumn, TableRow, Card, Text, Icon } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import './AppsHistory.scss';
import moment from 'moment';

const propTypes = {
	order: PropTypes.object,
	apps: PropTypes.array
};

const defaultProps = {
	order: {},
	apps: []
};

class AppsHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			error: false,
			logs: null
		};
	}

	componentDidMount() {
		this.getLogs();
	}

	async getLogs() {
		const StandardIntegration = getAngularModule('StandardIntegration');
		const AppService = getAngularModule('AppService');
		const customerId = AppService.getCustomerId();

		let appsSyncLogs = {};
		const promises = this.props.apps.map(async app => {
			const RequestBuilder = getAngularModule('RequestBuilder');
			const requestBuilder = new RequestBuilder();

			requestBuilder.addFilter(
				{ field: 'initObjectId' },
				requestBuilder.comparisonTypes.Equals,
				this.props.order.id
			);

			requestBuilder.addFilter({ field: 'integrationId' }, requestBuilder.comparisonTypes.Equals, app.id);

			requestBuilder.addFilter({ field: 'initType' }, requestBuilder.comparisonTypes.Equals, [
				'orderinsert',
				'orderupdate'
			]);

			requestBuilder.limit = 10;

			requestBuilder.addSort({ field: 'startDate' }, false);

			await StandardIntegration.log(customerId)
				.find(requestBuilder.build())
				.then(res => {
					if (res.data) {
						res.data = this.parseMessage(res.data);
						res.data = this.filterOnMessage(res.data);
						appsSyncLogs = { ...appsSyncLogs, [app.id]: res.data };
					}
				})
				.catch(err => logError(err, 'Failed to fetch app sync logs'));
		});

		await Promise.all(promises);
		this.setState({ loading: false });
		this.setState({ logs: appsSyncLogs });
	}

	parseMessage = data => {
		const logs = [];

		data.forEach(log => {
			if (log.callbackStatus === -1) return;

			let cbMsg = log.callbackMessage;
			if (cbMsg) {
				try {
					cbMsg = JSON.parse(cbMsg);
					if (cbMsg?.includes('userMessage')) {
						cbMsg = JSON.parse(cbMsg);
						cbMsg = cbMsg.userMessage;
					}
				} catch (err) {
					logError(err, `Failed to parse app callback message as JSON: ${log}`);
					cbMsg = '';
				}
			} else if (log.callbackStatus === 0) {
				cbMsg = T('integration.orderSyncProgressing');
			} else {
				return;
			}

			logs.push({ ...log, callbackMessage: cbMsg });
		});

		return logs;
	};

	filterOnMessage = data => {
		return data.filter(log => log.callbackMessage);
	};

	render() {
		const { apps, order } = this.props;
		const { logs, loading } = this.state;
		const colors = ['yellow', 'success-4', 'red'];
		const getStatus = status => (status === 0 || status === 1 ? status : 2);
		const getColor = status => colors[getStatus(status)];

		const status = (order.lastIntegrationStatus || []).reduce((prev, lastStatus) => {
			prev[lastStatus.integrationId] = lastStatus.callbackStatus;
			return prev;
		}, {});

		return (
			<div>
				{apps &&
					apps.map(app => (
						<Card className="AppSyncLogCard" key={app.id}>
							<CardHeader title={T('integrations.appsHistoryTitle', { app: app.name })} icon="exchange" />
							{logs && logs[app.id]?.length > 0 ? (
								<Table loading={loading}>
									<TableHeader
										columns={[{ title: T('default.date') }, { title: T('default.status') }]}
									/>
									{logs[app.id].map(({ id, startDate, callbackMessage, callbackStatus }) => (
										<TableRow
											key={id}
											className={`TableRow--sync TableRow--sync-${getColor(callbackStatus)}`}
										>
											<TableColumn
												className="TableColumn--dateColumn"
												title={moment(startDate).format('L LT')}
												size="lg"
											/>
											<TableColumn
												className="TableColumn--statusColumn"
												title={
													<Text color="black" title={callbackMessage}>
														{callbackMessage}
													</Text>
												}
												icon="circle"
												color={getColor(callbackStatus)}
												size="lg"
											/>
										</TableRow>
									))}
								</Table>
							) : status[app.id] === 1 ? (
								<div className="AppSyncLogCard--noSync">
									<Text center size="xl" color="grey-12">
										<Icon name="circle" color="success-4" space="mrl" />
										{T('integration.tooltip.success', {
											entity: T('default.order'),
											app: app.name
										})}
									</Text>
								</div>
							) : (
								<div className="AppSyncLogCard--noSync">
									<Text center size="xl">
										{T('integration.orderNotSynced')}
									</Text>
									<Text center size="lg" color="grey-10">
										{T('integration.orderWillSyncWhen', { thisApp: app.name })}
									</Text>
								</div>
							)}
						</Card>
					))}
			</div>
		);
	}
}

AppsHistory.propTypes = propTypes;
AppsHistory.defaultProps = defaultProps;

export default AppsHistory;

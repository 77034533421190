import React from 'react';
import T from 'Components/Helpers/translate';
import { Card, CardHeader, CardContent, Label, Block, Text, Icon, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import UpSelect from 'Components/Inputs/UpSelect';

import './SubscriptionsDefaultSettings.scss';

interface UpSelectOption {
	id: number;
	value?: string;
	name?: string;
}
type UpSelectEscape = (value: any) => string;
const selectFormat: React.ComponentProps<typeof UpSelect>['formatSelection'] = (
	option: UpSelectOption,
	container: unknown,
	escape: UpSelectEscape
) => {
	return '<span>' + escape(option.value || option.name) + '</span>';
};
const matcher: React.ComponentProps<typeof UpSelect>['matcher'] = function (
	term: string,
	undef: unknown,
	item: UpSelectOption
) {
	const value = (item.value || item.name)?.toLowerCase();
	return value?.indexOf(term.toLowerCase()) !== -1;
};

interface Props {
	onIntervalChange: React.ComponentProps<typeof UpSelect>['onChange'];
	onPeriodChange: React.ComponentProps<typeof UpSelect>['onChange'];
	onStageChange: React.ComponentProps<typeof ReactTemplates.INPUTS.upStages>['onChange'];
	defaultInterval: React.ComponentProps<typeof UpSelect>['defaultValue'];
	defaultPeriod: React.ComponentProps<typeof UpSelect>['defaultValue'];
	defaultStage: React.ComponentProps<typeof ReactTemplates.INPUTS.upStages>['value'];
}
const TooltipHelpIcon = ({ tooltip }: { tooltip: string }) => (
	<Tooltip title={tooltip}>
		<Icon name="question-circle" />
	</Tooltip>
);

const SubscriptionsDefaultSettings = ({
	onIntervalChange,
	defaultInterval,
	defaultPeriod,
	onPeriodChange,
	onStageChange,
	defaultStage
}: Props) => {
	const validIntervals = Tools.AppService.getStaticValues('recurringInterval');
	const orderStages = Tools.AppService.getStages('order', true);
	const validPeriods = [
		{ id: 3, value: '3 ' + T('default.months') },
		{ id: 6, value: '6 ' + T('default.months') },
		{ id: 12, value: '12 ' + T('default.months') },
		{ id: 24, value: '24 ' + T('default.months') },
		{ id: 36, value: '36 ' + T('default.months') },
		{ id: 48, value: '48 ' + T('default.months') },
		{ id: 60, value: '60 ' + T('default.months') },
		{ id: 0, value: T('order.ongoing') }
	];
	const classNames = new BemClass('SubscriptionsDefaultSettings');
	return (
		<Card className={classNames.b()} space="mtxl mbxl">
			<CardHeader title={T('admin.agreement.default.title')} />
			<CardContent>
				<Text color="grey-11" className={classNames.elem('description').b()}>
					{T('admin.agreement.default.description')}
				</Text>

				<div className={classNames.elem('input-wrap').b()}>
					<Block space="mbxl">
						<div className={classNames.elem('label').b()}>
							<Label>{T('order.recurringIntervalLabel')}</Label>
							<TooltipHelpIcon tooltip={T('admin.agreement.default.interval')} />
						</div>
						<UpSelect
							defaultValue={defaultInterval}
							className="form-control"
							options={{
								data: validIntervals
							}}
							onChange={onIntervalChange}
						/>
					</Block>
					<Block space="mbxl">
						<div className={classNames.elem('label').b()}>
							<Label>{T('order.recurringPeriod')}</Label>
							<TooltipHelpIcon tooltip={T('admin.agreement.default.period')} />
						</div>
						<UpSelect
							matcher={matcher}
							defaultValue={defaultPeriod}
							className="form-control"
							onChange={onPeriodChange}
							options={{
								data: validPeriods
							}}
							formatResult={(
								obj: UpSelectOption,
								container: unknown,
								query: unknown,
								escape: UpSelectEscape
							) => selectFormat(obj, container, escape)}
							formatSelection={selectFormat}
						/>
					</Block>
					<Block space="mbxl">
						<div className={classNames.elem('label').b()}>
							<Label>{T('order.defaultStage')}</Label>
							<TooltipHelpIcon tooltip={T('order.defaultStageDescription')} />
						</div>
						<ReactTemplates.INPUTS.upStages
							value={orderStages.find(s => s.id === defaultStage)}
							className="form-control"
							onChange={onStageChange}
							entity="order"
							hideZeroProbability={true}
						/>
					</Block>
				</div>
			</CardContent>
		</Card>
	);
};

export default SubscriptionsDefaultSettings;

import async from 'async';
import AccountApps from 'Components/AccountApps';

angular.module('domain.account').controller('AccountApps', [
	'$scope',
	'AppService',
	'StandardIntegration',
	'$location',
	function ($scope, AppService, StandardIntegration, $location) {
		let element = document.getElementById('account-apps-holder');
		var AccountCtrl = $scope.AccountCtrl;
		const Ctrl = this;
		Ctrl.apps = AccountCtrl.Apps;
		var rawIntegrationsData = function (iterator) {
			var array = [];
			return new Promise(function (resolve) {
				async.each(
					iterator,
					function (item, cb) {
						if (item.userConfigurable === true) {
							StandardIntegration.userSetting(Tools.AppService.getCustomerId())
								.get(item.id)
								.then(function (userSettingResponse) {
									if (userSettingResponse.data && userSettingResponse.data.active) {
										StandardIntegration.get(item.id)
											.then(function (integration) {
												array.push(integration.data);
												cb();
											})
											.catch(e => console.log('get standard integration error', e));
									}
								})
								.catch(e => console.log('get userSetting error error', e));
						} else {
							StandardIntegration.get(item.id)
								.then(function (integration) {
									array.push(integration.data);
									cb();
								})
								.catch(e => console.log('get standard integration error', e));
						}
					},
					function () {
						return resolve(array);
					}
				);
			});
		};

		var loadIntegrations = function () {
			var activeIntegrations = Tools.AppService.getMetadata().integrations.active;
			rawIntegrationsData(activeIntegrations)
				.then(function (raw) {
					var mappedData = _.map(raw, function (item) {
						var cfg = {};

						try {
							cfg = JSON.parse(item.configJson);
						} catch (error) {
							console.log('couldnt parse config');

							try {
								cfg = JSON.parse(JSON.stringify(item.configJson));
							} catch (error2) {
								console.log('couldnt parse a stringifed config');
							}
						}

						if (typeof cfg.iframe === 'boolean' && cfg.iframe === true) {
							return {
								id: item.id,
								integrationId: item.id,
								name: item.name,
								color: item.color,
								imageLink: item.imageLink
							};
						}

						return null;
					});

					Ctrl.apps = [].concat(_.compact(mappedData));
					render();
				})
				.catch(e => console.log('load integrations error', e));
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(element);
			element = null;
		});

		var renderTimeout;
		function render(initialAppId) {
			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}
			renderTimeout = setTimeout(function () {
				if (!element) {
					return;
				}

				ReactDOM.render(
					React.createElement(AccountApps, {
						initialAppId,
						apps: Ctrl.apps,
						account: AccountCtrl.account
					}),
					element
				);
			}, 20);
		}

		var init = function () {
			if (Ctrl.apps.length === 0) {
				loadIntegrations();
			}

			let initialAppId = parseInt($location.search().appId);
			if (initialAppId && !isNaN(initialAppId)) {
				$location.search('appId', null);
			} else {
				initialAppId = null;
			}

			render(initialAppId);
		};

		AppService.loadedPromise.then(init).catch(e => console.log('load account apps error', e));
	}
]);

'use strict';

angular.module('upAttributes').service('EsignAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false
				},
				mdate: {
					title: 'default.date',
					field: 'mdate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'mdate',
					selectableColumn: false
				},
				sortDate: {
					title: 'default.date',
					field: 'sortDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'sortDate',
					selectableColumn: true
				},
				client: {
					title: 'default.account',
					field: 'client',
					type: FilterType.String,
					displayType: DisplayType.String,
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						}
					},
					sortable: 'client.name',
					selectableColumn: true
				},
				file: {
					title: 'file.fileName',
					field: 'file',
					type: FilterType.String,
					displayType: DisplayType.String,
					attr: {
						id: {
							field: 'file.id',
							type: FilterType.Number
						},
						filename: {
							field: 'file.filename',
							type: FilterType.String
						}
					},
					selectableColumn: true
				},
				opportunity: {
					title: 'default.opportunity',
					field: 'opportunity.id',
					displayAttr: 'description',
					displayType: DisplayType.String,
					inputType: 'select',
					selectableColumn: true,
					parent: 'opportunity',
					sortable: false,
					attr: {
						id: {
							field: 'opportunity.id',
							type: FilterType.Number
						},
						description: {
							field: 'opportunity.description',
							type: FilterType.String
						}
					}
				},
				state: {
					title: 'default.status',
					field: 'state',
					type: FilterType.Number,
					selectableColumn: true,
					sortable: 'state'
				},
				involved: {
					title: 'esign.signingParties',
					field: 'involved',
					type: FilterType.Number,
					parent: 'involved',
					attr: {
						contactId: {
							field: 'involved.contactId',
							type: FilterType.Number
						}
					},
					selectableColumn: true
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					sortable: 'user.name',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'user',
					minWidth: 130,
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				}
			};

			// Alt.namespace
			attributes.account = angular.copy(attributes.client);
			attributes.account.selectableColumn = false;

			var standardFilters = {};

			var requiredFields = [];

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					ACCOUNT: 'account'
				}
			};
		};
	}
]);

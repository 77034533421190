export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	description: {
		title: 'default.description',
		field: 'metadata.agreementDescription',
		locked: true
	},
	intervalPeriod: {
		title: 'agreement.intervalPeriod',
		field: 'metadata.agreementIntervalPeriod'
	},
	periodLength: {
		title: 'agreement.periodLength',
		field: 'metadata.periodLength'
	},
	value: {
		title: 'default.value',
		field: 'value'
	},
	contributionMargin: {
		title: 'default.contributionMargin',
		field: 'contributionMargin'
	},
	contributionMarginInAgreementCurrency: {
		title: 'default.contributionMarginInAgreementCurrency',
		field: 'contributionMarginInAgreementCurrency'
	},
	yearlyContributionMargin: {
		title: 'default.yearlyContributionMargin',
		field: 'yearlyContributionMargin'
	},
	yearlyContributionMarginInAgreementCurrency: {
		title: 'default.yearlyContributionMarginInAgreementCurrency',
		field: 'yearlyContributionMarginInAgreementCurrency'
	},
	yearlyValue: {
		title: 'default.yearlyValue',
		field: 'yearlyValue'
	},
	project: {
		title: 'default.campaigns',
		field: 'project.name'
	},
	startDate: {
		title: 'default.startDate',
		field: 'metadata.agreementStartdate'
	},
	endDate: {
		title: 'default.endDate',
		field: 'metadata.agreementEnddate'
	},
	invoiceStartDate: {
		title: 'agreement.startDateCurrentPeriod',
		field: 'metadata.agreementInvoiceStartdate'
	},
	nextOrderDate: {
		title: 'agreement.nextOrderDate',
		field: 'metadata.agreementNextOrderDate'
	},
	renewalDate: {
		title: 'agreement.renewalDate',
		field: 'metadata.agreementRenewalDate'
	},
	user: {
		title: 'default.user',
		field: 'user.name'
	},
	contact: {
		title: 'default.contact',
		field: 'contact.name'
	},
	account: {
		title: 'default.account',
		field: 'client.name'
	},
	probability: {
		title: 'default.probability',
		field: 'probability'
	},
	notes: {
		field: 'metadata.agreementNotes',
		title: 'default.notes'
	},
	product: {
		title: 'default.product',
		field: 'orderRow.product.name'
	},
	quantity: {
		title: 'default.quantity',
		field: 'orderRow.quantity'
	},
	price: {
		title: 'default.price',
		field: 'orderRow.price'
	},
	stage: {
		title: 'default.stage',
		field: 'stage.name'
	},
	currency: {
		title: 'default.currency',
		field: 'currency'
	},
	currencyRate: {
		title: 'default.currencyRate',
		field: 'currencyRate'
	},
	clientConnection: {
		title: 'default.clientConnection',
		field: 'clientConnection.name'
	},
	noticePeriod: {
		title: 'agreement.noticePeriod',
		field: 'metadata.noticePeriod'
	},
	noticeDate: {
		title: 'agreement.noticeDate',
		field: 'metadata.noticeDate'
	}
};

import React, { useMemo } from 'react';
import Client from 'App/resources/Model/Client';
import SimpleListView from '../../SimpleListView/SimpleListView';
import { UserDefinedObjectTypeId } from 'App/resources/Model/UserDefinedObjectType';
import BemClass from '@upsales/components/Utils/bemClass';
import UserDefinedObjectAttributes from 'App/babel/attributes/UserDefinedObjectAttributes';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import UserDefinedObjectResource, { UserDefinedObject } from 'App/resources/UserDefinedObject';
import RequestBuilder from 'Resources/RequestBuilder';
import { useCustomFields } from 'App/components/hooks/appHooks';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { Headline, Icon, Row } from '@upsales/components';
import { removeItem } from 'Store/helpers/array';
import logError from 'Helpers/logError';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';

const getUDOs = (clientId: number, typeId: UserDefinedObjectTypeId, rb: RequestBuilder) => {
	rb.addFilter(UserDefinedObjectAttributes.client.attr.id, ComparisonTypes.Equals, clientId);
	const query = rb.build();
	return UserDefinedObjectResource.find(typeId, query);
};

const ClientCardUDO = ({ client, typeId }: { client: Client; typeId: UserDefinedObjectTypeId }) => {
	const udoMetadata = useSelector(({ App }: RootState) =>
		App.metadata?.params.UserDefinedObject.find(udo => udo.id === typeId)
	);
	const { t } = useTranslation();

	const cacheKey = `userDefined${typeId}` as const;
	const customFields = useCustomFields(cacheKey);

	const className = 'ClientCardUDO';

	const columns = useMemo(() => {
		const fields: { id: number }[] = customFields
			.filter(field => field.$hasAccess)
			.filter(field => field.visible && field.datatype !== 'Text')
			.sort((a, b) => (a.sortId < b.sortId ? -1 : 1));
		return fields.map(f => `Custom_${f.id}`).concat(['delete']);
	}, [typeId]);

	if (!udoMetadata || !client) {
		return null;
	}

	return (
		<SimpleListView<UserDefinedObject>
			classes={new BemClass(className)}
			getData={rb => getUDOs(client.id, typeId, rb)}
			renderHeader={({ reloadTable }) => (
				<Row align="center" className="SimpleListView__header">
					<Headline space="plm" size="sm">
						{udoMetadata.name}
					</Headline>
					<div className="FlexPadder" />
					<PrimaryButton
						onClick={() => {
							return Tools.$upModal
								.open('editUserDefinedObject', { typeId, object: { client } })
								.then(() => {
									reloadTable({ silent: true, debounce: true });
								});
						}}
						size="sm"
					>
						<Icon name="plus" space="mrs" />
						{`${t('default.add')} ${t('default.objects')}`}
					</PrimaryButton>
				</Row>
			)}
			columns={columns.length > 0 ? columns : ['', 'delete']}
			attributes={UserDefinedObjectAttributes}
			listType={`userDefinedObject${typeId}`}
			onRowClick={(item, provided) => () => {
				return Tools.$upModal.open('editUserDefinedObject', { id: item.id, typeId }).then(() => {
					provided.reloadTable({ silent: true });
				});
			}}
			deleteFn={async (item, { getTableData, setTableData }) => {
				try {
					await UserDefinedObjectResource.delete(typeId, item.id);

					const data = getTableData();
					const index = data.findIndex(userDefinedObject => userDefinedObject.id === item.id);

					if (index !== -1) {
						const final = removeItem(data, index);
						setTableData(final);
					}
				} catch (error) {
					logError(error, 'Failed to delete udo');
				}
			}}
			entityName={'default.userdefinedobject'}
			isDeletable={item => item.userRemovable}
			broadcastType={`userDefinedObject${typeId}`}
		/>
	);
};

export default ClientCardUDO;

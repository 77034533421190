import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { Progressbar, Column, Text, Icon, IconName } from '@upsales/components';
import './QuotaWidget.scss';
import PropTypes from 'prop-types';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';

const icons: { [key: string]: IconName } = {
	SALES: 'sales',
	CONTRIBUTION_MARGIN: 'sales', // placeholder until new icon
	CREATED_PIPELINE: 'opportunity',
	RECURRING: 'recurring-order',
	BOOKED_APPOINTMENT: 'calendar',
	BOOKED_FIRST_APPOINTMENT: 'calendar',
	COMPLETED_APPOINTMENT: 'calendar-check-o',
	COMPLETED_FIRST_APPOINTMENT: 'calendar-check-o',
	REACHED_CLIENTS: 'phone',
	ONEOFF: 'cube'
};

type Props = {
	type: keyof typeof icons;
	progress: number;
	goal: number;
	currency: string;
};

const getText = (type: Props['type'], progress: number, goal: number, currency: string) => {
	const cf = new CurrencyFormat(currency);
	switch (type) {
		case 'SALES':
		case 'CREATED_PIPELINE':
		case 'CONTRIBUTION_MARGIN':
		case 'RECURRING':
		case 'ONEOFF':
			return cf.short(progress);
		case 'BOOKED_APPOINTMENT':
		case 'BOOKED_FIRST_APPOINTMENT':
		case 'COMPLETED_APPOINTMENT':
		case 'COMPLETED_FIRST_APPOINTMENT':
		case 'REACHED_CLIENTS':
			return goal > 0 ? `${progress}/${goal}` : progress;
		default:
			break;
	}
};

const QuotaWidget = (props: Props) => {
	const MainClass = new Bem('QuotaWidget');
	const { type, progress, goal, currency } = props;
	const actualProgress = (progress / goal) * 100;
	const iconName = icons[type];
	const goalExists = goal > 0;
	const hasNewBranding = Tools.FeatureHelper.hasSoftDeployAccess('NEW_BRANDING');
	const reachedGoal = actualProgress >= 100;
	return (
		<Column className={MainClass.b()}>
			<Text color={hasNewBranding ? 'super-light-green' : 'white'} size="sm">
				<Icon name={iconName} space="mrs" />
				{getText(type, progress, goal, currency)}
			</Text>
			{goalExists ? (
				<Progressbar
					className={MainClass.elem('progressbar').b()}
					value={actualProgress}
					color={reachedGoal ? 'bright-green' : 'green'}
					containerColor={progress ? (reachedGoal ? 'bright-green' : 'grey-9') : 'greyeen'}
					type="inset"
					size="sm"
				/>
			) : null}
		</Column>
	);
};

QuotaWidget.propTypes = {
	type: PropTypes.string,
	progress: PropTypes.number,
	goal: PropTypes.number,
	currency: PropTypes.string
};
export default QuotaWidget;

'use strict';

angular.module('domain.campaign').controller('NewSegment', [
	'$scope',
	'AppService',
	'$state',
	'$modalParams',
	'Segment',
	function ($scope, AppService, $state, $modalParams, Segment) {
		var NewSegment = this;
		NewSegment.name = '';
		NewSegment.description = '';
		NewSegment.active = true;
		NewSegment.isUpdate = false;
		NewSegment.saving = false;

		NewSegment.doContinue = function () {
			NewSegment.saving = true;
			var body = {};

			if ($modalParams.segment) {
				body = $modalParams.segment;
			}

			body.name = NewSegment.name;
			body.description = NewSegment.description;
			body.active = NewSegment.active;

			Segment.save(body)
				.then(function (res) {
					var options = {
						id: res.data.id
					};

					if (NewSegment.isUpdate || $modalParams.noRedirect) {
						return $scope.resolve(res.data);
					}

					$state.go('segment.contacts', options);
				})
				.catch(function () {
					NewSegment.saving = false;
				});
		};
		var init = function () {
			if ($modalParams.segment) {
				NewSegment.name = $modalParams.segment.name;
				NewSegment.description = $modalParams.segment.description;
				NewSegment.isUpdate = !!$modalParams.segment.id;
				NewSegment.active = !!$modalParams.segment.active;
			}
		};
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import { Text, Button, Icon } from '@upsales/components';

import './AccountProfilePreview.scss';
type AccountProfilePreviewProps = {
	profile: ProfileType;
	onClose: () => void;
};
type banner = {
	body: string;
	name: string;
};
type AccountProfilePreviewState = {
	banners: banner[];
};
type Typography = {
	bold: boolean;
	italic: boolean;
	fontFamily: string;
	underline: boolean;
	size: string;
};
type ProfileType = {
	logo: string;
	colors: {
		mainText: string;
		secondaryText: string;
		contentTextIngress: string;
		contentText: string;
		buttonText: string;
		buttonBg: string;
		linkText: string;
		bodyBg: string;
		mainBg: string;
		contentBg: string;
		footerLinkText: string;
		footerText: string;
	};
	typography: {
		ingress: Typography;
		link: Typography;
		p: Typography;
		h2: Typography;
		h3: Typography;
	};
	btnBorderRadius: string;
	name: string;
	email: string;
	phone: string;
	address: string;
	city: string;
	country: string;
};

class AccountProfilePreview extends React.Component<AccountProfilePreviewProps, AccountProfilePreviewState> {
	lang: { [key: string]: string };
	'_banner1': HTMLIFrameElement;
	static propTypes = {
		profile: PropTypes.object.isRequired,
		onClose: PropTypes.func
	};
	constructor(props: AccountProfilePreviewProps) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			mailExample: T('admin.accountProfile.mailExample'),
			preambleExampleText: T('admin.preambleExampleText'),
			paragraphExampleText: T('admin.paragraphExampleText'),
			bannerExample: T('Banner exemple'),
			contactUs: T('admin.contactUs'),
			footerLinkText: T('admin.footerLinkText'),
			mainTitle: T('admin.mainTitle'),
			secondaryTitle: T('admin.secondaryTitle'),
			preamble: T('admin.preamble'),
			paragraph: T('admin.paragraph'),
			link: T('admin.link'),
			button: T('admin.button'),
			close: T('default.close')
		};

		this.state = {
			banners: []
		};

		this.setRef = this.setRef.bind(this);
		this.renderBanner = this.renderBanner.bind(this);
	}

	async componentDidMount() {
		const response = await Tools.StandardCreative.find({});
		this.setState({
			banners: response.data
		});

		this.renderBanner();
	}

	componentDidUpdate() {
		this.renderBanner();
	}

	// here
	setRef(name: '_banner1', ref: HTMLIFrameElement) {
		this[name] = ref;
	}

	renderBanner() {
		const { banners } = this.state;
		const { profile } = this.props;

		for (const banner of banners) {
			const config = window.BannerParser.generateConfig(banner.body, profile);

			window.BannerParser.parse(config, banner.body, profile, (error, html) => {
				if (error) {
					return;
				}

				html = 'data:text/html;charset=utf-8,' + html;
				let id;

				if (banner.name === 'Template 1 - Hörnflagga') {
					id = 1;
				}

				if (id === undefined) {
					return;
				}

				const iframe = this['_banner1'];

				if (iframe && iframe.contentWindow) {
					iframe.contentWindow.document.open();
					iframe.contentWindow.document.write(html);
					iframe.contentWindow.document.close();
				}
			});
		}
	}

	styles(
		profile: ProfileType
	): {
		[key: string]: {
			color?: string;
			fontSize?: string;
			fontWeight?: 'bold' | 'normal';
			textDecoration?: 'underline' | 'none';
			fontStyle?: 'italic' | 'normal';
			fontFamily?: string;
			backgroundColor?: string;
			borderRadius?: string;
		};
	} {
		return {
			h2Style: {
				color: profile.colors.mainText,
				fontSize: profile.typography.h2.size,
				fontWeight: profile.typography.h2.bold ? 'bold' : 'normal',
				textDecoration: profile.typography.h2.underline ? 'underline' : 'none',
				fontStyle: profile.typography.h2.italic ? 'italic' : 'normal',
				fontFamily: profile.typography.h2.fontFamily
			},
			h3Style: {
				color: profile.colors.secondaryText,
				fontSize: profile.typography.h3.size,
				fontWeight: profile.typography.h3.bold ? 'bold' : 'normal',
				textDecoration: profile.typography.h3.underline ? 'underline' : 'none',
				fontStyle: profile.typography.h3.italic ? 'italic' : 'normal',
				fontFamily: profile.typography.h3.fontFamily
			},
			ingressStyle: {
				color: profile.colors.contentTextIngress,
				fontSize: profile.typography.ingress.size,
				fontWeight: profile.typography.ingress.bold ? 'bold' : 'normal',
				textDecoration: profile.typography.ingress.underline ? 'underline' : 'none',
				fontStyle: profile.typography.ingress.italic ? 'italic' : 'normal',
				fontFamily: profile.typography.ingress.fontFamily
			},
			pStyle: {
				color: profile.colors.contentText,
				fontSize: profile.typography.p.size,
				fontWeight: profile.typography.p.bold ? 'bold' : 'normal',
				textDecoration: profile.typography.p.underline ? 'underline' : 'none',
				fontStyle: profile.typography.p.italic ? 'italic' : 'normal',
				fontFamily: profile.typography.p.fontFamily
			},
			btnStyle: {
				color: profile.colors.buttonText,
				backgroundColor: profile.colors.buttonBg,
				borderRadius: profile.btnBorderRadius
			},
			linkStyle: {
				color: profile.colors.linkText,
				fontSize: profile.typography.link.size,
				fontWeight: profile.typography.link.bold ? 'bold' : 'normal',
				textDecoration: profile.typography.link.underline ? 'underline' : 'none',
				fontStyle: profile.typography.link.italic ? 'italic' : 'normal',
				fontFamily: profile.typography.link.fontFamily
			}
		};
	}

	render() {
		const { profile, onClose } = this.props;
		const PreviewClass = new Bem('AccountProfilePreview');
		const { h2Style, h3Style, ingressStyle, pStyle, btnStyle, linkStyle } = this.styles(profile);

		return (
			<div className={PreviewClass.b()}>
				<div className={PreviewClass.elem('Body').b()}>
					{onClose && (
						<Button type="link" onClick={onClose}>
							<Icon name="times" space="mrm" />
							{this.lang.close}
						</Button>
					)}
					<Text size="lg">{this.lang.mailExample}</Text>

					<div
						id="FakeContentWrapper"
						className="hover-element-body"
						style={{ backgroundColor: profile.colors.bodyBg }}
					>
						<div
							className="profile-header hover-element-main"
							style={{ backgroundColor: profile.colors.mainBg }}
						>
							<img src={profile.logo} height="30" />
							<div
								id="contact-us"
								className="hover-element-button"
								style={{ backgroundColor: profile.colors.buttonBg, color: profile.colors.buttonText }}
							>
								{this.lang.contactUs}
							</div>
						</div>

						<div
							className="profile-content hover-element-content"
							style={{ backgroundColor: profile.colors.contentBg }}
						>
							<h2 style={h2Style} className="hover-element-h2">
								{this.lang.mainTitle}
							</h2>
							<h3 style={h3Style} className="hover-element-h3">
								{this.lang.secondaryTitle}
							</h3>

							<p style={ingressStyle} className="ingress hover-element-ingress">
								{this.lang.preambleExampleText}
							</p>

							<p style={pStyle} className="hover-element-p">
								{this.lang.paragraphExampleText}{' '}
								<a style={linkStyle} className="hover-element-a">
									{'Lorem ipsum'}
								</a>
							</p>

							<button type="button" className="profile-button hover-element-button" style={btnStyle}>
								{this.lang.button}
							</button>
						</div>

						<div className="profile-footer" style={{ color: profile.colors.footerText }}>
							<div className="pull-left">
								<b className="hover-element-footerText">{profile.name}</b>
								<br />
								{profile.email ? (
									<a
										style={{ color: profile.colors.footerLinkText }}
										className="hover-element-footerLink"
									>
										<b className="fa fa-envelope" /> <span>{profile.email}</span>
									</a>
								) : null}
								<br />
								{profile.phone ? (
									<a
										style={{ color: profile.colors.footerLinkText }}
										className="hover-element-footerLink"
									>
										<b className="fa fa-phone" /> <span>{profile.phone}</span>
									</a>
								) : null}
							</div>
							<div className="pull-right hover-element-footerText">
								<span>{profile.address}</span>
								<br />
								<span>{profile.city}</span>
								<br />
								<span>{profile.country}</span>
							</div>
							<div className="clearfix" />
						</div>
					</div>

					<Text size="lg">{this.lang.bannerExample}</Text>
					<div className="fake-banner-wrap">
						<div className="fake-banner" style={{ height: 250, width: 300 }}>
							<iframe
								src=""
								scrolling="no"
								allowTransparency={true}
								width="100%"
								height="100%"
								ref={this.setRef.bind(self, '_banner1')}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountProfilePreview;

import React from 'react';
import { Tabs, Tab, OutsideClick, Column, Flex, ButtonSelect } from '@upsales/components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import Sidebar from './AppointmentModalSidebar';
import AppointmentSidebarCalendar from './AppointmentSidebarCalendar';
import Fields from './AppointmentModalFields';
import Footer from './AppointmentModalFooter';
import ModalHeader from '../ModalHeader';
import AvailabilityTable from '../../AvailabilityTable/AvailabilityTable';
import FilesTabContent from '../FilesTabContent/FilesTabContent';
import buildTitle from './AppointmentController/buildTitle';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import AppointmentTodos from './AppointmentTodos';
import EditPhonecallNotes from 'Components/EditPhonecall/EditPhonecallNotes';

import RelateTo from 'App/components/RelateTo';
import { SlideFade } from 'App/components/animations';
import { Speed } from 'App/components/animations/Animate/Animate';
import PopupPortalAnchors from 'App/helpers/PopupPortalAnchors';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import TicketWidget from 'App/components/TicketWidget';
import AppointmentOutcome from 'App/resources/Model/AppointmentOutcome';

const TAB = {
	DETAILS: 'details',
	FILES: 'files'
};

const propTypes = {
	account: PropTypes.shape({
		userEditable: PropTypes.bool
	}),
	className: PropTypes.string,
	id: PropTypes.string,
	appointment: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dateAvailability: PropTypes.instanceOf(Date),
	standardFields: PropTypes.object.isRequired,
	savingType: PropTypes.string,
	hasDocumentTemplates: PropTypes.bool,
	documentTemplates: PropTypes.array,
	isAvailable: PropTypes.object,
	saving: PropTypes.bool,
	created: PropTypes.bool,
	hideOutcome: PropTypes.bool,
	requiredFields: PropTypes.object.isRequired,
	fieldErrors: PropTypes.object.isRequired,
	promptRemoval: PropTypes.bool,
	outcomeAnswered: PropTypes.bool,
	currentAppointmentLength: PropTypes.number,
	contactOpened: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	isDirty: PropTypes.bool,
	parentElem: PropTypes.object,
	invitedContacts: PropTypes.object,
	invitedEmailAttendees: PropTypes.object,
	existingAppointment: PropTypes.object
};

class AppointmentModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: TAB.DETAILS,
			conflicting: [],
			files: [],
			newFiles: [],
			deletedFiles: [],
			allOtherAppointments: [],
			externalEvents: {
				events: [],
				otherUsers: {}
			},
			isCancelled:
				props.appointment?.outcome === AppointmentOutcome.NOT_COMPLETED &&
				moment(props.appointment?.date).isAfter(moment()),
			showCalendar: !props.id && !props.appointment?.id,
			availabilityTableVisible: false,
			dateAvailability: props.dateAvailability,
			showInlineAction: 'none',
			notesFilterActive: false,
			showSelectOpportunity: false
		};

		this.openContact = this.openContact.bind(this);
		this.timelineClick = this.timelineClick.bind(this);
		this.checkConflicting = this.checkConflicting.bind(this);

		this.saveOpportunityTimeout = undefined;

		this.loggedInUserId = Tools.AppService.getSelf().id;
		this.loggedInCustomerId = Tools.AppService.getCustomerId();
		this.calendarIntegrations = Tools.AppService.getCalendarIntegrations();
		this.hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		this.hasSupportAccess = Tools.FeatureHelper.hasSoftDeployAccess('SUPPORT_SERVICE_V2');
		this.wrapperRef = React.createRef();
		this.hasDocuments = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS);
		this.hasSupport = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT);
		this.hasCalendar = Tools.FeatureHelper.hasSoftDeployAccess('APPOINTMENT_CALENDAR');
	}

	async getExternalEventsAndFindOverlap(date, userIds, appointment) {
		let events = [];
		const otherUsers = {};
		if (!this.calendarIntegrations.length) {
			return { conflictingEvent: null, events };
		}

		try {
			for (const integration of this.calendarIntegrations) {
				const res = await Tools.StandardIntegration.data(this.loggedInCustomerId).run({
					data: {
						startDateTime: moment.utc(date.start).format(),
						endDateTime: moment.utc(date.end).format()
					},
					userIds,
					type: 'events',
					integrationId: integration.id
				});

				if (res.data) {
					if (res.data.events) {
						events = res.data.events;
					}
					if (res.data.otherUsers) {
						for (const userId in res.data.otherUsers) {
							otherUsers[userId] = res.data.otherUsers[userId];
						}
					}
				}
			}
		} catch (e) {
			console.error('Could not fetch external events', e);
			return { conflictingEvent: null, events };
		}

		const dateStart = moment(date.start);
		const dateEnd = moment(date.end).subtract(1, 'minute');
		// Formula source: https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
		const dateRangesOverlap = (start, end) =>
			moment.max([moment(start).subtract(1, 'minute'), dateStart]) < moment.min([moment(end), dateEnd]);
		const isThisAppointment = e =>
			(appointment.id && appointment.id === e.upsalesId) || (e.id && e.id === appointment.externalCalendarId);
		const isConflicting = events =>
			events.find(e => dateRangesOverlap(e.startDateTime, e.endDateTime) && !isThisAppointment(e));

		const conflictingEvent = { users: [] };
		if (isConflicting(events)) {
			conflictingEvent.users.push({ id: this.loggedInUserId });
		}

		for (const userId in otherUsers) {
			if (isConflicting(otherUsers[userId])) {
				conflictingEvent.users.push({ id: parseInt(userId) });
			}
		}

		return {
			conflictingEvent,
			otherUsers,
			events
		};
	}

	getAppointments(date, userIds, appointmentId) {
		const rb = new Tools.RequestBuilder();
		const AppointmentAttributes = Tools.Appointment.attr;

		rb.addFilter(AppointmentAttributes.users, rb.comparisonTypes.Equals, userIds);
		if (appointmentId) {
			rb.addFilter(AppointmentAttributes.id, rb.comparisonTypes.NotEquals, appointmentId);
		}
		// Get overlapping appointments
		rb.addFilter(AppointmentAttributes.date, rb.comparisonTypes.LessThan, date.end);
		rb.addFilter(AppointmentAttributes.endDate, rb.comparisonTypes.GreaterThan, date.start);

		return Tools.Appointment.customer(this.loggedInCustomerId).find(rb.build());
	}

	getAllAppointments(props) {
		const { dateAvailability, currentAppointmentLength, appointment } = props;

		const appointmentDate = {
			start: moment(dateAvailability).toDate(),
			end: moment(dateAvailability).add(currentAppointmentLength, 'minutes').toDate()
		};

		const appointmentDay = {
			start: moment(appointmentDate.start).startOf('day').toDate(),
			end: moment(appointmentDate.end).endOf('day').toDate()
		};

		const userIds = appointment.users.map(o => o.id);

		Promise.all([
			this.getAppointments(appointmentDate, userIds, appointment.id),
			this.getExternalEventsAndFindOverlap(appointmentDate, userIds, appointment),
			this.getAppointments(appointmentDay, userIds, appointment.id)
		])
			.then(res => {
				const conflicting = res[0].data;
				const external = res[1];
				const otherAppointments = this.addNonDuplicateExternalEvents(res[2].data, external);

				if (external.conflictingEvent?.users?.length) {
					conflicting.push(external.conflictingEvent);
				}

				const isNew = !appointment.id;

				this.setState({
					conflicting,
					changingDate: false,
					otherAppointments,
					allOtherAppointments: res[2].data,
					externalEvents: external,
					dateAvailability,
					availabilityTableVisible:
						isNew && conflicting.length > 0 ? true : this.state.availabilityTableVisible
				});
			})
			.catch(e => {
				console.error('Error while getting appointments:', e);
				this.setState({
					changingDate: false
				});
			});
	}

	componentDidMount() {
		this.getAllAppointments(this.props);
		this.loadFiles();
	}

	componentWillUnmount() {
		if (this.saveOpportunityTimeout) {
			clearTimeout(this.saveOpportunityTimeout);
		}
		if (this.reloadModalPosition) {
			clearTimeout(this.reloadModalPosition);
		}
	}

	dateCompare(date1, date2) {
		const time1 = date1 instanceof Date && date1.getTime();
		const time2 = date2 instanceof Date && date2.getTime();
		return time1 === time2;
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		const dateAvailabilityChanged = !this.dateCompare(this.props.dateAvailability, nextProps.dateAvailability);
		const usersChanged =
			JSON.stringify(this.props.appointment.users) !== JSON.stringify(nextProps.appointment.users);

		if (dateAvailabilityChanged || usersChanged) {
			this.getAllAppointments(nextProps);
		}
	}

	loadFiles() {
		const { appointment } = this.props;
		const fileFilter = new Tools.RequestBuilder();

		if (!appointment.id) {
			return;
		}

		fileFilter.addFilter(
			Tools.File.attr.entity,
			fileFilter.comparisonTypes.Equals,
			Tools.File.entityTypes.APPOINTMENT
		);
		fileFilter.addFilter(Tools.File.attr.entityId, fileFilter.comparisonTypes.Equals, appointment.id);

		Tools.File.customer(this.loggedInCustomerId)
			.find(fileFilter.build())
			.then(response => {
				this.setState({ files: response.data });
			})
			.catch(err => console.error('Error while loading files:', err));
	}

	openContact(user) {
		const { contactOpened, actions } = this.props;

		if (contactOpened === user.id) {
			actions.setContactOpened(null);
		} else {
			actions.setContactOpened(user.id);
		}
	}

	addNonDuplicateExternalEvents(appointments, { events, otherUsers }) {
		const otherAppointments = [...appointments];

		const addIfNotFound = (userId, events) => {
			events.forEach(event => {
				if (
					!appointments.find(
						o =>
							moment(o.date).isSame(event.startDateTime) &&
							moment(o.endDate).isSame(event.endDateTime) &&
							o.users.find(u => u.id === userId)
					)
				) {
					otherAppointments.push({
						date: event.startDateTime,
						endDate: event.endDateTime,
						users: [{ id: userId }]
					});
				}
			});
		};

		addIfNotFound(this.loggedInUserId, events);

		for (const userId in otherUsers) {
			addIfNotFound(parseInt(userId), otherUsers[userId]);
		}

		return otherAppointments;
	}

	timelineClick(hoverDate) {
		if (this.props.appointment.userEditable) {
			this.props.actions.changeStartDate(moment(hoverDate), { setBoth: true });
		}
	}

	checkConflicting() {
		const state = Object.assign({}, this.state);

		if (state.availabilityTableVisible) {
			state.availabilityTableVisible = false;
		} else {
			state.availabilityTableVisible = true;
		}

		this.setState(state);
	}

	handleTabChange = selectedTab => {
		this.reloadModalPosition = setTimeout(() => this.props.actions.reloadModalPosition(), 50);

		this.setState({ selectedTab });
	};

	handleFileDrop = files => {
		this.setState(({ newFiles }) => ({ newFiles: [...newFiles, ...files] }));
	};

	handleFileRemoval = file => {
		if (file.id) {
			const { files, deletedFiles } = this.state;

			deletedFiles.push(file);
			files.splice(files.indexOf(file), 1);

			this.setState({ files, deletedFiles });
		} else {
			const { newFiles } = this.state;
			newFiles.splice(newFiles.indexOf(file), 1);

			this.setState({ newFiles });
		}
	};

	saveAppointment = inviteContacts => {
		const { newFiles, deletedFiles } = this.state;
		this.props.actions
			.saveAppointmentWithFiles(newFiles, deletedFiles, inviteContacts)
			.then(({ openSelectOpportunity }) => {
				if (openSelectOpportunity) {
					setTimeout(this.openSelectOpportunity, 1500);
				}
			})
			.catch(err => console.error(err));
	};

	saveSelectedOpportunity = v => {
		this.saveOpportunityTimeout = setTimeout(() => {
			if (v && v.opportunityId) {
				this.props.actions.saveSelectedOpportunity({ id: v.opportunityId });
			} else {
				this.props.actions.resolve(this.props.appointment);
			}
		}, Speed.normal);
	};

	openSelectOpportunity = () => {
		this.setState({ showSelectOpportunity: true });
	};

	renderDragOverlay() {
		return (
			<div className="drag-overlay">
				<img src="/img/drop-file.png" alt="Drop a file" />
				<div>{Tools.$translate('file.dropToUpload')}</div>
			</div>
		);
	}

	toggleInlineAction = position => {
		this.setState({ showInlineAction: position });
	};

	cancelAppointment = () => {
		this.props.actions.changeAppointmentParam('outcome', AppointmentOutcome.NOT_COMPLETED);
		this.setState({ isCancelled: true });
	};

	closeModal = () => {
		const { actions, isDirty } = this.props;
		const { showSelectOpportunity } = this.state;

		if (isDirty) {
			this.toggleInlineAction('top');
			return;
		}

		if (showSelectOpportunity) {
			this.saveSelectedOpportunity();
		} else {
			actions.close();
		}
	};

	renderModal() {
		const { appointment, actions, standardFields, fieldErrors, account } = this.props;
		const { selectedTab, files, newFiles, showSelectOpportunity } = this.state;

		const onChangeDate = next => {
			const date = this.state.dateAvailability;
			const newDate = moment(date).add(next ? 1 : -1, 'days');

			this.setState({ changingDate: true });

			this.props.actions.changeStartDate(newDate, { setBoth: true });
		};

		return (
			<>
				{this.state.showInlineAction === 'top' ? (
					<InlineAction
						toggleInlineAction={this.toggleInlineAction}
						onReject={actions.discardFromInline}
						onConfirm={() => this.saveAppointment(false)}
						showTop
					/>
				) : null}

				<ModalHeader
					icon="fa fa-calendar"
					className="white-header"
					title={buildTitle({ Tools, appointment, actions })}
					appointmentId={appointment.id}
					close={this.closeModal}
				/>

				<SlideFade visible={showSelectOpportunity} direction="bottom" speed="slow" complete>
					<RelateTo
						className="relate-to"
						entityLabel={'default.appointment'}
						client={account}
						contact={appointment}
						availableEntities={{ opportunity: true, activity: false, appointment: false }}
						user={{ id: this.loggedInUserId }}
						visible={showSelectOpportunity}
						onChange={this.saveSelectedOpportunity}
					></RelateTo>
				</SlideFade>

				<Dropzone onDrop={this.handleFileDrop} noClick disabled={!this.hasDocuments}>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<div
							{...{
								...getRootProps({
									className: classNames(
										'up-modal-content has-sidebar-x2 dropzone appointment-content',
										{
											'drag-active': isDragActive
										}
									)
								}),
								onBlur: () => null,
								onClick: () => null,
								onFocus: () => null,
								onKeyDown: () => null,
								tabIndex: null
							}}
						>
							<input {...getInputProps()} />
							{isDragActive && this.renderDragOverlay()}
							<Flex direction="column" className="appointment-sidebar">
								{this.hasCalendar ? (
									<Flex justifyContent="center" space="mtm mbm">
										<ButtonSelect
											size="sm"
											value={this.state.showCalendar ? 'availability' : 'participants'}
											onChange={val => this.setState({ showCalendar: val === 'availability' })}
											options={[
												{
													value: 'participants',
													title: Tools.$translate('default.participantsClean')
												},
												{
													value: 'availability',
													title: Tools.$translate('default.availability')
												}
											]}
										/>
									</Flex>
								) : null}
								{this.hasCalendar && this.state.showCalendar ? (
									<AppointmentSidebarCalendar
										appointment={appointment}
										onChangeDate={onChangeDate}
										onChangeTime={this.timelineClick}
										loading={this.state.changingDate}
										date={this.state.dateAvailability}
										appointments={this.state.allOtherAppointments}
										externalEvents={this.state.externalEvents}
									/>
								) : (
									<Sidebar
										{...this.props}
										{...this.state}
										actions={actions}
										openContact={this.openContact}
										activeContact={this.props.contactOpened}
										checkConflicting={this.checkConflicting}
									/>
								)}
							</Flex>
							<div className="flex-container">
								<Column className="AppointmentTabs" id="AppointmentTabs">
									<div className="AppointmentTabs__TabsContainer">
										<Tabs
											color="white"
											selected={selectedTab}
											onChange={this.handleTabChange}
											noFlex={true}
										>
											<Tab id={TAB.DETAILS} title={Tools.$translate('socialEvent.details')} />
											<Tab
												id={TAB.FILES}
												title={`${Tools.$translate('file.files')} (${
													files.length + newFiles.length
												})`}
											/>
										</Tabs>
									</div>

									<div
										className={classNames('AppointmentTabs__TabsContent', {
											'AppointmentTabs__TabsContent--active': TAB.DETAILS === selectedTab
										})}
									>
										<Fields
											standardFields={standardFields}
											fieldErrors={fieldErrors}
											{...this.props}
											{...this.state}
											contactOpened={this.props.contactOpened}
											openContact={this.openContact}
										/>
									</div>
									<div
										className={classNames('AppointmentTabs__TabsContent', {
											'AppointmentTabs__TabsContent--active': TAB.FILES === selectedTab
										})}
									>
										<FilesTabContent
											files={files}
											newFiles={newFiles}
											onDrop={this.handleFileDrop}
											onRemove={this.handleFileRemoval}
										/>
									</div>
								</Column>
							</div>

							<Column className={'appointment-notes appointment-history-log'}>
								{this.hasTodoList && appointment.id ? (
									<AppointmentTodos
										appointment={this.props.appointment}
										canCreate={appointment.userEditable}
									/>
								) : null}

								{this.hasSupportAccess && account?.id && this.hasSupport ? (
									<TicketWidget clientId={account.id} />
								) : null}

								<EditPhonecallNotes
									notes={appointment.notes}
									onChange={value => {
										actions.changeAppointmentParam('notes', value);
									}}
									onAutoSave={
										appointment?.id > 0
											? value => this.props.actions.saveAppointmentNotes(value)
											: undefined
									}
									textAreaProps={{
										required: this.props.requiredFields.Notes,
										disabled: !appointment.userEditable
									}}
									autosave={true}
									hasError={fieldErrors.Notes}
									textareaTooltipText={
										Tools.AppService.getMetadata().standardFields.Appointment.Notes.tooltip
									}
								/>
							</Column>
						</div>
					)}
				</Dropzone>

				<Footer
					savingType={this.props.savingType}
					setSavingType={actions.setSavingType}
					dirty={this.props.isDirty}
					appointment={appointment}
					hasDocumentTemplates={this.props.hasDocumentTemplates}
					documentTemplates={this.props.documentTemplates}
					isAvailable={this.props.isAvailable}
					actions={actions}
					remove={() => actions.promptRemoval({})}
					saveAppointment={this.saveAppointment}
					created={this.props.created}
					cancel={this.cancelAppointment}
					followUp={isActivity => actions.createFollowUp(isActivity)}
					close={() => actions.close()}
					onDrop={this.handleFileDrop}
					saving={this.props.saving}
					toggleInlineAction={value => this.toggleInlineAction(value)}
					showInlineAction={this.state.showInlineAction}
					discard={() => actions.discardFromInline()}
					invitedContacts={this.props.invitedContacts}
					invitedEmailAttendees={this.props.invitedEmailAttendees}
					existingAppointment={this.props.existingAppointment}
				/>
				<OutsideClick
					targetClass="up-availability-table"
					outsideClick={() => {
						if (this.state.availabilityTableVisible) {
							this.setState({ availabilityTableVisible: false });
						}
					}}
				>
					{this.hasCalendar ? null : (
						<AvailabilityTable
							date={this.state.dateAvailability}
							users={appointment.users}
							endDate={appointment.endDate}
							conflicts={this.state.conflicting}
							appointment={this.props.appointment}
							visible={this.state.availabilityTableVisible}
							otherAppointments={this.state.otherAppointments}
							onDateChange={actions.changeDayInAvailabilityTable}
							onTimelineClick={this.timelineClick}
							close={() => {
								if (this.state.availabilityTableVisible) {
									this.setState({ availabilityTableVisible: false });
								}
							}}
						/>
					)}
				</OutsideClick>
				{this.props.promptRemoval ? (
					<div className="fullmodal-container">
						<div className="fullscreen-content up-modal-content">
							<AlertBody entity="appointment" numSelected={1} />
							<div className="fullmodal-control">
								<button
									className="btn up-btn btn-lg btn-red btn-block"
									onClick={() => actions.deleteAppointment()}
								>
									{Tools.$translate('default.delete')}
								</button>
								<button
									className="btn up-btn btn-link btn-grey btn-block"
									onClick={() => actions.promptRemoval({ closePrompt: true })}
								>
									{Tools.$translate('cancel')}
								</button>
							</div>
						</div>
					</div>
				) : null}
			</>
		);
	}

	render() {
		const { id, className } = this.props;

		return (
			<div id={id || 'appointment-modal'} className={`appointment-modal ${className}`} ref={this.wrapperRef}>
				<PopupPortalAnchors anchor={this.wrapperRef.current} scrollContainerSelector=".AppointmentTabs">
					{this.renderModal()}
				</PopupPortalAnchors>
			</div>
		);
	}
}

AppointmentModal.propTypes = propTypes;
window.AppointmentModal = AppointmentModal;
export default window.AppointmentModal;

import { ModalHeader, ModalContent, ModalControls, Row, Column, Button, Text, Block, Label } from '@upsales/components';
import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { chunk, cloneDeep } from 'lodash';
import Prospecting from 'Resources/Prospecting';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';

import './ConfirmParentProspecting.scss';

type ConfirmParentProspectingProps = {
	$modalParams: {
		name: string;
		orgNumber: number;
		prospectingId: string;
	};
	resolve: (account: any) => void;
	reject: (error: any) => void;
};

const ConfirmParentProspecting: React.FC<ConfirmParentProspectingProps> = ({ $modalParams, resolve, reject }) => {
	const classes = new BemClass('ConfirmParentProspecting');
	const customFields: CustomFieldWithValue[] = Tools.AppService.getCustomFields('account').reduce(
		(res: CustomFieldWithValue[], field: CustomField) => {
			if (field.obligatoryField && field.editable && field.alias !== 'ORG_NO') {
				res.push({ ...field, fieldId: field.id, value: null });
			}
			return res;
		},
		[]
	);

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState(customFields);

	const t = Tools.$translate;

	const lang = {
		title: t('prospecting.parentCompanyModal.title'),
		confirm: t('prospecting.parentCompanyModal.fetch', { company: $modalParams.name }),
		abort: t('default.abort'),
		text: t('prospecting.parentCompanyModal.text', { company: $modalParams.name }),
		customFields: t('default.youNeedToFillRequiredFields')
	};

	const customFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		const f = cloneDeep(fields);
		const item = f.find(f => f.id === field.id);
		if (item) {
			item.value = value;
			setFields(f);
		}
	};

	const confirm = async () => {
		setLoading(true);

		const properties: any[] = [];
		Tools.ActionProperties.mapCustomFields(properties, fields, 'Client');
		const res = await Prospecting.save({
			prospectingId: $modalParams.prospectingId,
			customValues: properties
		});

		if (!res.error) {
			resolve(res.data);
		}
		setLoading(false);
	};

	const close = () => {
		reject(null);
	};

	const valid = () => {
		return fields.every(f => f.value);
	};

	const customFieldRows = chunk(fields, 2).map(([fieldA, fieldB]) => {
		const key = `${fieldA.id}-${fieldB ? fieldB.id : 'field-select'}`;
		return (
			<Block space="mtl mbl" key={key}>
				<Row>
					<Column size={6} className={classes.elem('field-column').b()}>
						<Label required={!!fieldA.obligatoryField}>{fieldA.name}</Label>
						<ReactTemplates.INPUTS.customFieldInput
							field={fieldA}
							name={fieldA.name}
							onChange={(value: any) => customFieldValueChange(fieldA, value)}
						/>
					</Column>
					{fieldB ? (
						<Column size={6} className={classes.elem('field-column').b()}>
							<React.Fragment>
								<Label required={!!fieldB.obligatoryField}>{fieldB.name}</Label>
								<ReactTemplates.INPUTS.customFieldInput
									field={fieldB}
									name={fieldB.name}
									onChange={(value: any) => customFieldValueChange(fieldB, value)}
								/>
							</React.Fragment>
						</Column>
					) : null}
				</Row>
			</Block>
		);
	});

	return (
		<div className={classes.b()}>
			<ModalHeader title={lang.title} />
			<ModalContent>
				<Text>{lang.text}</Text>
				<br></br>
				{fields.length ? (
					<React.Fragment>
						<Text>{lang.customFields}</Text>
						{customFieldRows}
					</React.Fragment>
				) : null}
			</ModalContent>
			<ModalControls>
				<Row>
					<Column align="right">
						<Button
							onClick={confirm}
							loading={loading}
							shadow="none"
							disabled={!valid()}
							className={classes.elem('confirm-btn').b()}
						>
							{lang.confirm}
						</Button>
						<Button onClick={close} color="grey" className={classes.elem('close-btn').b()} type="link">
							{lang.abort}
						</Button>
					</Column>
				</Row>
			</ModalControls>
		</div>
	);
};

export default ConfirmParentProspecting;

'use strict';

angular.module('upDirectives').directive('upEventManual', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventManual.tpl.html?file'),
		require: 'upEventManual',
		scope: {
			event: '=upEventManual'
		},
		controller: [
			'$scope',
			function ($scope) {
				$scope.customerId = Tools.AppService.getCustomerId();
				$scope.expand = function () {
					if ($scope.event.$$expand) {
						$scope.event.$$expand = false;
						return;
					}

					$scope.event.$$expand = true;
				};
			}
		]
	};
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import Confirm from './Confirm';

const Prompt = ({ label, loading, onConfirm, ...props }) => {
	const [value, setValue] = useState('');

	return (
		<Confirm {...props} yesDisabled={!value} loading={loading} onConfirm={() => onConfirm(value)}>
			<Label>{translate(label)}</Label>
			<Input value={value} onChange={e => setValue(e.target.value)} disabled={loading} />
		</Confirm>
	);
};

Prompt.propTypes = {
	label: PropTypes.string,
	loading: PropTypes.bool,
	onConfirm: PropTypes.func
};

Prompt.defaultProps = {
	label: 'false',
	loading: false,
	onConfirm: () => {}
};

export default Prompt;

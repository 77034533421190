import React, { useEffect, useRef } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import TextEditor from 'Components/TextEditor';
import './MailTemplatePreviewText.scss';
import logError from 'Helpers/logError';
import { getColumnInnerMaxWidth } from 'Services/MailTemplate';

const getImgSize = async src =>
	new Promise(resolve => {
		const img = new Image();
		img.src = src;
		img.onload = function () {
			resolve({ width: img.width, height: img.height });
		};
	});

const MailTemplatePreviewText = ({
	column,
	readOnly,
	onChange,
	selected,
	accountProfile,
	isSocialEvent = false,
	isWebinar = false,
	rowId,
	isFirstRow,
	isDoc = false,
	textEditorHeight
}) => {
	const classes = new bemClass('MailTemplatePreviewText');
	const editorRef = useRef();
	const toolbarId = `${rowId}-ck`;
  
	useEffect(() => {
		if (!readOnly && editorRef.current) {
			if (selected) {
				editorRef.current.element.focus();
			} else {
				editorRef.current.element.blur();
			}
		}
	}, [readOnly, selected]);

	const getTagsPluginParams = () => (isSocialEvent ? { socialEvent: { isWebinar } } : null);
	const toolbar = [
		{
			name: 'basicstyles',
			items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']
		},
		{ name: 'colors', items: ['TextColor'] },
		{ name: 'styles', items: ['Format', 'FontSize', 'lineheight'] },
		{ name: 'fonts', items: ['Font'] },
		{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
		{ name: 'links', items: ['Link', 'Unlink'] }
	];
	if (!isDoc) toolbar.push({ name: 'signatures', items: ['signatures'] });
	return (
		<div
			className={classes.b()}
			style={{
				textAlign: column.align
			}}
			data-testid="textEditor"
		>
			{readOnly ? (
				<div
					dangerouslySetInnerHTML={{
						__html: column.content
					}}
				/>
			) : (
				<TextEditor
					type="inline"
					instanceRef={editorRef}
					value={column.content}
					onChange={e =>
						onChange({
							...column,
							content: e.value
						})
					}
					config={{
						forcePasteAsPlainText: true,
						extraPlugins: 'tags,signatures,lineheight,sharedspace',
						pluginParams: {
							tags: getTagsPluginParams()
						},
						signaturePlugin: {
							// This will try to transform any images so they are not wider than the container they are added to.
							transformer: async body => {
								try {
									// Find any img-tag in the signature and iterate them to find the width.
									const tmpElement = document.createElement('div');
									tmpElement.innerHTML = body;
									const images = tmpElement.querySelectorAll('img');
									const colMaxWidth = getColumnInnerMaxWidth(column);
									let contentWasModified = false;

									for (let i = 0; i < images.length; i++) {
										const image = images[i];
										const src = image.getAttribute('src');
										if (src) {
											const { width } = await getImgSize(src);
											if (width > colMaxWidth) {
												image.setAttribute('width', colMaxWidth);
												contentWasModified = true;
											}
										}
									}

									if (contentWasModified) {
										body = tmpElement.innerHTML;
									}

									tmpElement.remove();
								} catch (e) {
									logError(e, 'Failed to transform signature, adding as is');
								}
								return body;
							}
						},
						toolbar: toolbar,
						format_maintitle: { name: translate('admin.mainTitle'), element: 'h2' },
						format_secondarytitle: { name: translate('admin.secondaryTitle'), element: 'h3' },
						format_tags: 'maintitle;secondarytitle;ingress;paragraph',
						format_paragraph: {
							name: translate('admin.paragraph'),
							element: 'p',
							attributes: {
								class: 'paragraph'
							}
						},
						format_ingress: {
							name: translate('admin.preamble'),
							element: 'p',
							attributes: {
								class: 'ingress'
							}
						},
						sharedSpaces: {
							top: toolbarId
						}
					}}
					textEditorHeight={textEditorHeight}
					accountProfile={accountProfile}
					onFocus={(show, editorClass) => {
						const toolbar = document.getElementById(toolbarId);
						if (!toolbar) return;
						if (show) {
							toolbar.classList.add('ck-inline-toolbar--visible');
							const rowElem = document.getElementById(rowId);
							const editor = document.querySelector(`.${editorClass}`);
							setTimeout(() => {
								// when adding a new row, we create a new editor
								// in this case, we need to wait a few ms for the editor to be added to the DOM so we can get its height
								editor.style.top = `-${editor.clientHeight}px`;
								const stickyTop = 90 + editor.clientHeight;
								const y = rowElem.getBoundingClientRect().y;
								if (isFirstRow) {
									rowElem.style.marginTop = show ? editor.clientHeight + 'px' : '0';
								} else if (y < stickyTop) {
									rowElem.style.marginTop = show ? `${stickyTop - y}px` : '0';
								} else {
									rowElem.style.marginTop = '';
								}
							}, 10);
						} else {
							toolbar.classList.remove('ck-inline-toolbar--visible');
						}
					}}
				/>
			)}
		</div>
	);
};
export default MailTemplatePreviewText;

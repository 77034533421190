'use strict';

angular.module('domain.soliditet').factory('ConfirmSoliditetUpdateMeta', [
	'$q',
	'AppService',
	'SoliditetClient',
	'RequestBuilder',
	'Account',
	function ($q, AppService, SoliditetClient, RequestBuilder, Account) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var existingDefer = $q.defer();
				var promises = {
					metadata: $q.when(AppService.getMetadata()),
					existingAccounts: existingDefer.promise
				};

				// Get suggestions based on name
				var nameFilter = new RequestBuilder();
				nameFilter.addFilter(SoliditetClient.attr.name, nameFilter.comparisonTypes.Search, $modalParams.name);
				nameFilter.limit = 250;
				nameFilter.addSort('_score', false);
				nameFilter.addSort(SoliditetClient.attr.headquarters, false);
				promises.accounts = SoliditetClient.customer(customerId)
					.find(nameFilter.build())
					.then(function (res) {
						var dunsFilter = new RequestBuilder();
						var duns = res.data.map(function (soliditetClient) {
							return soliditetClient.dunsNo;
						});
						dunsFilter.addFilter(Account.attr.dunsNo, nameFilter.comparisonTypes.Equals, duns);
						dunsFilter.addFilter(
							Account.attr.id,
							nameFilter.comparisonTypes.NotEquals,
							$modalParams.accountId
						);
						dunsFilter.limit = 250;
						Account.customer(customerId)
							.find(dunsFilter.build())
							.then(existingDefer.resolve)
							.then(function () {
								return res.data;
							});
						return res.data;
					});

				if ($modalParams.duns && $modalParams.duns !== '0' && $modalParams.duns !== '-1') {
					var dunsFilter = new RequestBuilder();
					dunsFilter.addFilter(
						SoliditetClient.attr.dunsNo,
						dunsFilter.comparisonTypes.Search,
						$modalParams.duns
					);
					dunsFilter.limit = 250;
					promises.currentAccount = SoliditetClient.customer(customerId)
						.find(dunsFilter.build())
						.then(function (res) {
							if (!res.data || !res.data.length) {
								return null;
							}
							return res.data[0];
						});
				}

				return $q.all(promises);
			});
		};
	}
]);

'use strict';

angular.module('upDirectives').directive('upTruncate', function () {
	return {
		restrict: 'A',
		scope: {
			text: '='
		},
		template: '<span>{{text}}</span>',
		link: function (scope, element, attrs) {
			// This directive makes the browser crash.. fix before use. Returning now
			return;
			var parent = element.parent();
			element.css({ display: 'inline-block' });
			scope.$watch('text', function (value) {
				if (!value) {
					return;
				}
				var originalText = value;
				var width = 0;
				parent.children().each(function (idx, child) {
					if (element.index() !== idx) {
						width += angular.element(child).outerWidth();
					}
				});
				var addToolip = false;
				var finalWidth = parent.width() - width;
				while (finalWidth < element.outerWidth()) {
					addToolip = true;
					value = value.slice(0, value.length - 6) + '...';
					element.text(value);
				}

				if (addToolip) {
					attrs.$set('bs-tooltip', '');
					attrs.$set('data-title', originalText);
				}

				//element.css('width',finalWidth+'px');
			});
		}
	};
});

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberInput from './Number';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import UpSelect from 'Components/Inputs/UpSelect';

type Value = {
	Currency?: { value?: string | null };
	Value?: { value: { start?: number | null; end?: number | null } };
};

type Props = {
	value: Value;
	onChange: (value: Partial<Value>) => void;
};

const OrderValueSelect: React.FC<Props> = ({ value, onChange }) => {
	const { currencies, showCurrencySelect } = useSelector(({ App }: RootState) => {
		const masterCurrency = App.metadata?.customerCurrencies?.find(c => c.masterCurrency)?.iso || 'SEK';
		return {
			currencies: [
				{ id: null, name: `${T('default.all')} (${masterCurrency})` },
				...(App.metadata?.customerCurrencies?.map(currency => ({ id: currency.iso, name: currency.iso })) || [])
			],
			showCurrencySelect: !!(App.metadata?.params.MultiCurrency || value.Currency?.value)
		};
	});

	const onValueChange = (event: { start: number; end: number }) => {
		onChange({ Value: { ...value.Value, value: { start: event.start, end: event.end } } });
	};
	const onCurrencyChange = (event: { target: { value: string } }) => {
		onChange({ Currency: { ...value.Currency, value: event.target.value } });
	};

	const defaultValueCurrency = useMemo(() => {
		return currencies.find(currency =>
			value.Currency?.value ? currency.id === value.Currency.value : currency.id === null
		);
	}, [value.Currency?.value]);

	return (
		<div className="OrderValueSelect drilldown-row">
			<div key="valueSelect" className="col-lg-6 col-sm-8">
				<NumberInput
					autoFocus={false}
					filter={{ value: { start: value.Value?.value.start || null, end: value.Value?.value.end || null } }}
					onChange={onValueChange}
				/>
			</div>
			{showCurrencySelect ? (
				<div key="currencySelect" className="col-lg-6 col-sm-4">
					<UpSelect
						key="currencySelect"
						className="form-control"
						required
						data={currencies}
						defaultValue={defaultValueCurrency}
						onChange={onCurrencyChange}
						minimumResultsForSearch={-1}
					/>
				</div>
			) : null}
		</div>
	);
};

OrderValueSelect.propTypes = {
	value: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

export default OrderValueSelect;

import async from 'async';
import logError from 'App/babel/helpers/logError';

/*
	Use resolveClientIds to resolve the id of the related client
	instead of the id of the relation.
*/

angular.module('services.accountRelations', []).service('accountRelations', [
	'$q',
	'Account',
	'AppService',
	'RequestBuilder',
	'SoliditetClient',
	'FeatureHelper',
	function ($q, Account, AppService, RequestBuilder, SoliditetClient, FeatureHelper) {
		var instance = {};

		var mapRelatedClient = function (relClient, type, relObj, resolveClientIds) {
			var relationSort = {
				parent: 1,
				sibling: 2,
				subsidiary: 3,
				connected: 4
			};
			relObj = relObj || {};
			relObj[type] = true;
			relObj.sort = relationSort[type];
			relObj.relatedToClientId = relClient.id;
			relObj.name = relClient.name;
			relObj.hasActivity = relClient.hasActivity;
			relObj.hasOpportunity = relClient.hasOpportunity;
			relObj.hasOrder = relClient.hasOrder;

			if (resolveClientIds) {
				relObj.id = relClient.id;
			}

			return relObj;
		};

		instance.mapCustomRelation = function (customerId, relation) {
			return Account.customer(customerId)
				.get(relation.relatedToClientId)
				.then(function (res) {
					return mapRelatedClient(res.data, 'connected', {
						descriptionChildParent: relation.descriptionChildParent,
						id: relation.id // id of the relation
					});
				});
		};

		instance.getCount = function (account, subAccountIds) {
			var subsidiaries;
			var customerId = AppService.getCustomerId();
			const accountIds = [account.id];
			const hasSubAccountsEnabled = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
			if (hasSubAccountsEnabled && subAccountIds?.length) {
				accountIds.push(...subAccountIds);
			}

			var filters = new RequestBuilder();
			filters.addFilter(Account.attr.parent.attr.id, filters.comparisonTypes.Equals, accountIds);
			filters.fields = ['id'];
			subsidiaries = Account.customer(customerId)
				.find(filters.build())
				.then(function (res) {
					return res.data.length;
				});

			let groupTree = 0;

			const hasGroupSize = FeatureHelper.isAvailable('GROUP_SIZE');
			const hasProspectingPro = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);
			const hasProspectingBasic = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);
			const showCommercial = !hasGroupSize && !hasProspectingPro && hasProspectingBasic;

			if (account.prospecting && (showCommercial || hasProspectingPro)) {
				groupTree = account.prospecting.groupSize > 1 ? 1 : 0;
			} else if (
				account.dunsNo &&
				account.dunsNo !== -1 &&
				account.dunsNo !== ' ' &&
				!isNaN(account.dunsNo) &&
				AppService.getSelf().userParams.soliditetIsActive &&
				hasGroupSize
			) {
				groupTree = SoliditetClient.customer(customerId)
					.find({ dunsNo: account.dunsNo })
					.then(res => (res.data?.length ? 1 : 0))
					.catch(e => {
						logError(e, 'Failed to load account relations', { id: account.id });
						return 0;
					});
			}

			return $q
				.all({
					subsidiaries: subsidiaries,
					customConnections: account.connectedClients ? account.connectedClients.length : 0,
					groupTree
				})
				.then(function (res) {
					return res.subsidiaries + res.customConnections + res.groupTree;
				});
		};

		instance.get = function (account, resolveClientIds) {
			var defer = $q.defer();
			var parent;
			var customerId = AppService.getCustomerId();
			var relatedClients = [];

			async.series(
				[
					// find parent
					function (cb) {
						if (!account?.parent || !account?.parent?.id) {
							return cb();
						}
						parent = account.parent.id;
						Account.customer(customerId)
							.get(parent)
							.then(function (response) {
								if (!response.data.name) {
									return cb();
								}
								relatedClients.push(mapRelatedClient(response.data, 'parent', null, resolveClientIds));
								return cb();
							})
							.catch(e => console.log('get account error', e));
					},
					// find siblings
					function (cb) {
						if (!parent) {
							return cb();
						}
						var filters = new RequestBuilder();
						filters.addFilter(Account.attr.parent.attr.id, filters.comparisonTypes.Equals, parent);
						Account.customer(customerId)
							.find(filters.build())
							.then(function (response) {
								if (!response.data || !response.data.length) {
									return cb();
								}
								angular.forEach(response.data, function (sibling) {
									if (sibling.id === account.id) {
										return;
									}
									relatedClients.push(mapRelatedClient(sibling, 'sibling', null, resolveClientIds));
								});
								return cb();
							})
							.catch(e => console.log('find account error', e));
					},
					// find subsidiaries
					function (cb) {
						if (!account) {
							return cb();
						}
						var filters = new RequestBuilder();
						filters.addFilter(Account.attr.parent.attr.id, filters.comparisonTypes.Equals, account.id);
						Account.customer(customerId)
							.find(filters.build())
							.then(function (response) {
								if (!response.data || !response.data.length) {
									return cb();
								}
								angular.forEach(response.data, function (subsidiary) {
									if (subsidiary.id === account.id) {
										return;
									}
									relatedClients.push(
										mapRelatedClient(subsidiary, 'subsidiary', null, resolveClientIds)
									);
								});
								return cb();
							})
							.catch(e => console.log('find account error', e));
					},
					// fetch relation info for connections
					function (cb) {
						if (!account) {
							return cb();
						}
						async.eachLimit(
							account.connectedClients || [],
							5,
							function (relatedClient, eachCb) {
								Account.customer(customerId)
									.get(relatedClient.relatedToClientId)
									.then(function (response) {
										relatedClients.push(
											mapRelatedClient(
												response.data,
												'connected',
												{
													descriptionChildParent: relatedClient.descriptionChildParent,
													id: relatedClient.id // id of the relation
												},
												resolveClientIds
											)
										);
										return eachCb();
									})
									.catch(function (err) {
										// Let the rest of the relations map if one was bad
										if (err.data && err.data.error && err.data.error.key === 'NoSuchEntity') {
											console.log('Related client was not found', err);
											return eachCb();
										}
										return eachCb(err);
									});
							},
							function (err) {
								cb(err);
							}
						);
					}
				],
				function (err) {
					if (err) {
						return defer.reject(err);
					}
					relatedClients = _.sortBy(relatedClients, 'name');
					defer.resolve(relatedClients);
				}
			);
			return defer.promise;
		};

		return instance;
	}
]);

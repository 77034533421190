import async from 'async';
import openModal from 'App/services/Modal';

angular.module('domain.engage').controller('ViewAd', [
	'$scope',
	'$rootScope',
	'$translate',
	'$state',
	'$stateParams',
	'meta',
	'$upModal',
	'NotificationService',
	'RequestBuilder',
	'Account',
	'Campaign',
	'AppService',
	'Ads',
	'AdService',
	'SoliditetClient',
	function (
		$scope,
		$rootScope,
		$translate,
		$state,
		$stateParams,
		meta,
		$upModal,
		NotificationService,
		RequestBuilder,
		Account,
		Campaign,
		AppService,
		Ads,
		AdService,
		SoliditetClient
	) {
		var ViewAdCtrl = this;
		var customerId;

		ViewAdCtrl.changingStatus = false;
		ViewAdCtrl.ad = meta.ad.data;
		ViewAdCtrl.credits = meta.credits.data;
		ViewAdCtrl.statuses = Ads.statuses;
		ViewAdCtrl.actions = AdService.allowedActions;
		ViewAdCtrl.hasActions = AdService.hasActions;
		ViewAdCtrl.activeCreatives = [];

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			if (ViewAdCtrl.ad.id === updated.id) {
				// onyl abm targets can be changed in this view atm
				$rootScope.$broadcast('adCampaign.targetChange', {
					type: 'targetAbm',
					target: updated.target,
					subtype: updated.targetAbm === 2 ? 2 : null
				});
				init();
			}
		});

		$scope.$on('account.updated', function () {
			ViewAdCtrl.getNumberOfAccounts();
		});

		$scope.$on('account.deleted', function () {
			ViewAdCtrl.getNumberOfAccounts();
		});

		$scope.$on('account.added', function () {
			ViewAdCtrl.getNumberOfAccounts();
		});

		$scope.$on('account.merged', function (e, res) {
			var isMergedInAdCampaign = ViewAdCtrl.isAccountActive(res.merged);
			var isDeletedInAdCampaign = ViewAdCtrl.isAccountActive(res.deleted);
			if (isMergedInAdCampaign || isDeletedInAdCampaign) {
				init();
			}
		});

		ViewAdCtrl.deleteAd = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmDeleteAdTitle',
					body: 'ads.confirmDeleteAdBody',
					confirmButtonText: 'ads.confirmDeleteAdTitle',
					headerIcon: 'trash',
					onClose: confirmed => {
						if (confirmed) {
							Ads.customer(customerId)
							.delete(ViewAdCtrl.ad)
							.then(function () {
								$state.go('listAds', ViewAdCtrl.ad);
							})
							.catch(e => console.log('delete ad error', e));
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('errorConfirm', {
					title: 'ads.confirmDeleteAdTitle',
					body: 'ads.confirmDeleteAdBody',
					resolveTrue: 'ads.confirmDeleteAdTitle',
					icon: 'fa-trash'
				})
				.then(function () {
					Ads.customer(customerId)
						.delete(ViewAdCtrl.ad)
						.then(function () {
							$state.go('listAds', ViewAdCtrl.ad);
						})
						.catch(e => console.log('delete ad error', e));
				});
		};

		ViewAdCtrl.copyAd = function () {
			$state.go('editAd', {
				id: ViewAdCtrl.ad.id,
				copy: true
			});
		};

		const onLaunch = () => {
			ViewAdCtrl.changingStatus = true;
			Ads.customer(customerId)
				.launch(ViewAdCtrl.ad.id)
				.then(function () {
					ViewAdCtrl.changingStatus = false;
				})
				.catch(function () {
					ViewAdCtrl.changingStatus = false;
				});
		};

		ViewAdCtrl.launch = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmLaunchTitle',
					body: (
						<>
							{$translate.instant('ads.confirmLaunchBody')}{' '}
							<b>{moment(ViewAdCtrl.ad.startDate).format('DD MMMM YYYY')}</b>
						</>
					),
					confirmButtonText: 'ads.confirmLaunchTitle',
					headerIcon: 'play',
					onClose: confirmed => {
						if (confirmed) {
							onLaunch();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('defaultConfirm', {
					title: 'ads.confirmLaunchTitle',
					body:
						$translate.instant('ads.confirmLaunchBody') +
						' <b>' +
						moment(ViewAdCtrl.ad.startDate).format('DD MMMM YYYY') +
						'</b>',
					resolveTrue: 'ads.confirmLaunchTitle',
					icon: 'fa-play'
				})
				.then(function () {
					onLaunch();
				});
		};

		const onResume = () => {
			ViewAdCtrl.changingStatus = true;
			Ads.customer(customerId)
				.resume(ViewAdCtrl.ad.id)
				.then(function () {
					ViewAdCtrl.changingStatus = false;
				})
				.catch(function () {
					ViewAdCtrl.changingStatus = false;
				});
		};

		ViewAdCtrl.resume = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmLaunchTitle',
					body: $translate.instant('ads.confirmResumeBody'),
					confirmButtonText: 'ads.confirmLaunchTitle',
					headerIcon: 'play',
					onClose: confirmed => {
						if (confirmed) {
							onResume();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('defaultConfirm', {
					title: 'ads.confirmLaunchTitle',
					body: $translate.instant('ads.confirmResumeBody'),
					resolveTrue: 'ads.confirmLaunchTitle',
					icon: 'fa-play'
				})
				.then(function () {
					onResume();
				});
		};

		const onPause = async () => {
			ViewAdCtrl.changingStatus = true;
			Ads.customer(customerId)
				.pause(ViewAdCtrl.ad.id)
				.then(function () {
					ViewAdCtrl.changingStatus = false;
				})
				.catch(function () {
					ViewAdCtrl.changingStatus = false;
				});
		};

		ViewAdCtrl.pause = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmPauseTitle',
					body: 'ads.confirmPauseBody',
					confirmButtonText: 'ads.confirmPauseTitle',
					icon: 'pause',
					onClose: (confirmed) => {
						if (confirmed) {
							onPause();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('infoConfirm', {
					title: 'ads.confirmPauseTitle',
					body: 'ads.confirmPauseBody',
					resolveTrue: 'ads.confirmPauseTitle',
					icon: 'fa-pause'
				})
				.then(function () {
					onPause();
				});
		};

		ViewAdCtrl.finish = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmFinishTitle',
					body: 'ads.confirmFinishBody',
					confirmButtonText: 'ads.confirmFinishTitle',
					headerIcon: 'check',
					onClose: confirmed => {
						if (confirmed) {
							ViewAdCtrl.changingStatus = true;
							Ads.customer(customerId)
								.finish(ViewAdCtrl.ad.id)
								.then(function () {
									ViewAdCtrl.changingStatus = false;
								})
								.catch(function () {
									ViewAdCtrl.changingStatus = false;
								});
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'ads.confirmFinishTitle',
					body: 'ads.confirmFinishBody',
					resolveTrue: 'ads.confirmFinishTitle',
					icon: 'fa-check'
				})
				.then(function () {
					ViewAdCtrl.changingStatus = true;
					Ads.customer(customerId)
						.finish(ViewAdCtrl.ad.id)
						.then(function () {
							ViewAdCtrl.changingStatus = false;
						})
						.catch(function () {
							ViewAdCtrl.changingStatus = false;
						});
				});
		};

		ViewAdCtrl.archive = function () {
			ViewAdCtrl.changingStatus = true;
			Ads.customer(customerId)
				.archive(ViewAdCtrl.ad.id)
				.then(function () {
					ViewAdCtrl.changingStatus = false;
				})
				.catch(function () {
					ViewAdCtrl.changingStatus = false;
				});
		};

		ViewAdCtrl.save = function (cb, dontSync) {
			if (dontSync) {
				ViewAdCtrl.ad.dontSync = true;
			}
			Ads.customer(customerId)
				.save(ViewAdCtrl.ad)
				.then(function () {
					if (cb) {
						return cb();
					}
				})
				.catch(function (err) {
					if (cb) {
						return cb(err);
					}
				});
		};

		ViewAdCtrl.saveExternal = function (client) {
			var soliditetOptions = {
				updateExisting: false,
				skipProjects: false,
				skipAccountManagers: false,
				skipAddresses: false,
				skipCategories: false
			};

			return SoliditetClient.customer(customerId)
				.buy(client.dunsNo, [], soliditetOptions)
				.then(function () {
					$scope.$broadcast('renderTable', {});
				})
				.catch(function () {
					Account.customer(customerId)
						.save({ id: client.id, isExternal: false })
						.then(function () {
							$scope.$broadcast('renderTable', {});
						})
						.catch(function () {
							$scope.$broadcast('renderTable', {});
						});
				});
		};

		ViewAdCtrl.removeClientFromAds = function (client) {
			var campaignIndexToRemove = [];
			var clientProjectsIds = _.map(client.projects || [], 'id');

			for (var i = 0; i < clientProjectsIds.length; i++) {
				if (ViewAdCtrl.targetIds.projectIds.indexOf(clientProjectsIds[i]) !== -1) {
					campaignIndexToRemove.push(i);
				}
			}

			async.series([
				function (cb) {
					if (!campaignIndexToRemove.length) {
						return cb();
					}
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							title: 'ads.removeAccountFromCampaignTitle',
							body: 'ads.removeAccountFromCampaignBody',
							confirmButtonText: 'ads.removeAccountFromCampaignResolve',
							icon: 'times-circle',
							onClose: (confirmed) => {
								if (confirmed) {
									return cb();
								}
							}
						});
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('infoConfirm', {
								title: 'ads.removeAccountFromCampaignTitle',
								body: 'ads.removeAccountFromCampaignBody',
								resolveTrue: 'ads.removeAccountFromCampaignResolve',
								icon: 'fa-times-circle'
							})
							.then(function () {
								return cb();
							});
						}
				},
				function (cb) {
					var isInCampaignAsClient = _.findIndex(ViewAdCtrl.ad.target, {
						targetType: Ads.targetTypes.account,
						targetId: client.id
					});
					if (isInCampaignAsClient === -1) {
						return cb();
					}
					_.pullAt(ViewAdCtrl.ad.target, isInCampaignAsClient);
					ViewAdCtrl.save(cb, true);
				},
				function (cb) {
					if (!campaignIndexToRemove.length) {
						return cb();
					}
					_.pullAt(client.projects, campaignIndexToRemove);
					Account.customer(customerId)
						.save(client)
						.then(function () {
							return cb();
						})
						.catch(e => console.log('get account error', e));
				}
			]);
		};

		ViewAdCtrl.getTargetIds = function () {
			var projectIds = _.map(_.where(ViewAdCtrl.ad.target, { targetType: Ads.targetTypes.campaign }), 'targetId');
			var clientIds = _.map(_.where(ViewAdCtrl.ad.target, { targetType: Ads.targetTypes.account }), 'targetId');
			ViewAdCtrl.targetIds = {
				projectIds: projectIds,
				clientIds: clientIds
			};
			return ViewAdCtrl.targetIds;
		};

		ViewAdCtrl.isAccountActive = function (client) {
			if (!ViewAdCtrl.ad.targetAbm) {
				return false;
			}
			if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
				return false;
			}
			if (ViewAdCtrl.targetIds.clientIds.indexOf(client.id) !== -1) {
				return true;
			}
			var clientProjectsIds = _.map(client.projects || [], 'id');
			for (var i = 0; i < clientProjectsIds.length; i++) {
				if (ViewAdCtrl.targetIds.projectIds.indexOf(clientProjectsIds[i]) !== -1) {
					return true;
				}
			}
			return false;
		};

		ViewAdCtrl.getFilter = function (opts) {
			var filters = new RequestBuilder();
			var orBuilder = filters.orBuilder();
			var ids = ViewAdCtrl.getTargetIds();
			var groupBuilder;

			if (!opts) {
				opts = {};
			}

			// filter with the target settings
			if (ids.projectIds.length) {
				orBuilder.next();
				orBuilder.addFilter(Account.attr.campaigns, filters.comparisonTypes.Equals, ids.projectIds);
			}
			orBuilder.next();
			orBuilder.addFilter(Account.attr.id, filters.comparisonTypes.Equals, ids.clientIds);

			if (opts.includeInactive || ViewAdCtrl.statuses > ViewAdCtrl.statuses.paused || !ViewAdCtrl.ad.targetAbm) {
				// when the ad campaign is done, include inactive (everyone is inactive)
				// this will include targets that has been in the ad campaign but does not match current settings
				orBuilder.next();
				orBuilder.addFilter(Account.attr.adCampaign.attr.id, filters.comparisonTypes.Equals, ViewAdCtrl.ad.id);
			}

			orBuilder.done();

			if (opts.ipStatus) {
				switch (opts.ipStatus) {
					case 'hasIp':
						groupBuilder = filters.groupBuilder();
						groupBuilder.addFilter(
							Account.attr.adCampaign.attr.id,
							filters.comparisonTypes.Equals,
							ViewAdCtrl.ad.id
						);
						groupBuilder.addFilter(
							Account.attr.adCampaign.attr.hasIp,
							filters.comparisonTypes.Equals,
							true
						);
						groupBuilder.done();
						break;
					case 'hasNoIp': {
						const orBuilder = filters.orBuilder();
						orBuilder.next();
						orBuilder.addFilter(Account.attr.dunsNo, filters.comparisonTypes.GreaterThan, 0);
						orBuilder.next();
						orBuilder.addFilter(Account.attr.prospectingId, filters.comparisonTypes.NotEquals, null);
						orBuilder.done();

						groupBuilder = filters.groupBuilder();
						groupBuilder.addFilter(
							Account.attr.adCampaign.attr.id,
							filters.comparisonTypes.Equals,
							ViewAdCtrl.ad.id
						);
						groupBuilder.addFilter(
							Account.attr.adCampaign.attr.hasIp,
							filters.comparisonTypes.Equals,
							false
						);
						groupBuilder.done();
						break;
					}
					case 'hasNoDuns':
						filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, null);
						filters.addFilter(Account.attr.prospectingId, filters.comparisonTypes.Equals, null);
						break;
				}
			}

			if (opts.nameSearch) {
				filters.addFilter(Account.attr.name, filters.comparisonTypes.Search, opts.nameSearch);
			}

			return filters;
		};

		ViewAdCtrl.getNumberOfAccounts = function (cb) {
			var opts = {};
			if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
				// always include inactive when finished
				opts.includeInactive = true;
			}
			var filters = ViewAdCtrl.getFilter(opts);
			filters.limit = 0;
			filters.offset = 0;
			Account.customer(customerId)
				.find(filters.build(), { mapCustom: true, includeExternal: true })
				.then(function (res) {
					ViewAdCtrl.clients = res.metadata.total;
					if (cb) {
						return cb();
					}
				})
				.catch(function (err) {
					if (cb) {
						return cb(err);
					}
				});
		};

		ViewAdCtrl.openAccount = function (account) {
			$state.go('account.dashboard', { id: account.id });
		};

		ViewAdCtrl.editAd = function () {
			$state.go('editAd', ViewAdCtrl.ad);
		};

		ViewAdCtrl.getSiteTemplates = function (cb) {
			ViewAdCtrl.loadingSiteTemplates = true;
			Ads.customer(customerId)
				.getSiteTemplates()
				.then(function (res) {
					ViewAdCtrl.siteTemplates = res.data;
					_.forEach(ViewAdCtrl.ad.siteTemplate, function (st) {
						var found = _.find(ViewAdCtrl.siteTemplates, { externalId: st.sitetemplateId });
						if (found) {
							st.name = found.name;
						}
					});
					ViewAdCtrl.loadingSiteTemplates = false;
					return cb();
				})
				.catch(function (e) {
					ViewAdCtrl.siteTemplatesErr = e;
					ViewAdCtrl.loadingSiteTemplates = false;
					return cb();
				});
		};

		function init() {
			ViewAdCtrl.customerId = customerId = AppService.getCustomerId();
			ViewAdCtrl.adAccount = AppService.getAdAccount();
			ViewAdCtrl.userEditable = AppService.getSelf().administrator || AppService.getSelf().userParams.mailAdmin;
			ViewAdCtrl.ad.siteTemplate = _.where(ViewAdCtrl.ad.siteTemplate, { active: true });
			ViewAdCtrl.activeCreatives = _.where(ViewAdCtrl.ad.creative, { active: true });
			if (ViewAdCtrl.ad.status === ViewAdCtrl.statuses.draft) {
				var newAd = angular.copy(ViewAdCtrl.ad);
				var oldAd = angular.copy(ViewAdCtrl.ad);
				newAd.status = ViewAdCtrl.statuses.launch;
				ViewAdCtrl.invalidLaunch = AdService.invalidUpdates(
					oldAd,
					newAd,
					ViewAdCtrl.credits,
					ViewAdCtrl.adAccount?.values,
					true
				);
			}
			ViewAdCtrl.getNumberOfAccounts(function () {
				ViewAdCtrl.getSiteTemplates(function () {});
			});
		}

		AppService.loadedPromise.then(init).catch(e => console.log('load engage view error', e));
	}
]);

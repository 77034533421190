'use strict';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var User = window.Tools.User;
		var AppService = window.Tools.AppService;

		var dataFn = function () {
			if (self.props.data) {
				return self.props.data;
			}
			var users = [];
			if (self.props.userType) {
				users = AppService.getUsers(self.props.userType);
			} else {
				users = AppService.getActiveUsers();
			}
			return users;
		};

		var defaultSelectOptions = {
			formatResultCssClass: function (item) {
				return item.isRole ? 'font-weight-bold' : 'font-weight-normal';
			}
		};

		var opts = {
			resource: User,
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			onChange: self.props.onChange,
			placeholder: function () {
				return self.props.placeholder;
			},
			ajax: false,
			asIds: false,
			idAttr: User.attr.id,
			titleAttr: User.attr.name,
			sorting: { field: User.attr.name, ascending: true },
			dropdownCssClass: self.props.dropdownCssClass || '',
			filterType: 'user',
			data: self.props.data || dataFn,
			select2: Object.assign({}, defaultSelectOptions, self.props.select2 || {})
		};

		var input = $(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			var select2 = input.select2(options);

			if (self.props.eventListeners) {
				_.each(self.props.eventListeners, function (fn, event) {
					select2.on(event, fn);
				});
			}
		});
	};

	ReactTemplates.INPUTS.upUsers = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Row, Column, Loader, Headline, Text } from '@upsales/components';
import './FeatureIntroModal.scss';
import bemClass from '@upsales/components/Utils/bemClass';

const FeatureIntroModal = ({
	renderIllustration,
	onClose,
	className,
	children,
	loading,
	title,
	description,
	...props
}) => {
	const classes = new bemClass('FeatureIntroModal', className);

	const close = (
		<Button className={classes.elem('close', 'exit-button').b()} onClick={() => onClose()} shadow="none">
			<Icon name="times" />
		</Button>
	);

	if (loading) {
		return (
			<div {...props} className={classes.b()}>
				{close}
				<Loader className={classes.elem('loader').b()} />
			</div>
		);
	}

	return (
		<div {...props} className={classes.b()}>
			{close}
			<div className={classes.elem('inner').b()}>
				<Row>
					<Column>
						<Headline size="sm" className={classes.elem('title').b()}>
							{title}
						</Headline>
						<Text color="grey-10" size="xl" className={classes.elem('description').b()}>
							{description}
						</Text>
						{children}
					</Column>
					{renderIllustration ? <Column fixedWidth={405}>{renderIllustration()}</Column> : null}
				</Row>
			</div>
		</div>
	);
};

FeatureIntroModal.propTypes = {
	renderIllustration: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	className: PropTypes.string,
	loader: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.any
};

FeatureIntroModal.defaultProps = {
	renderIllustration: () => {},
	onClose: () => {}
};

export default FeatureIntroModal;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@upsales/components/Button';
import Help from '@upsales/components/Help';
import Input from '@upsales/components/Input';
import Label from '@upsales/components/Label';
import ModalContent from '@upsales/components/ModalContent';
import ModalControls from '@upsales/components/ModalControls';
import ModalHeader from '@upsales/components/ModalHeader';
import Progress from '@upsales/components/Progress';

import './EditStandardFieldModal.scss';

class EditStandardFieldModal extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			title: t('admin.standardfields'),
			body: t('admin.editStandardField.body').replace('. ', '.\n'),
			fieldName: t('admin.customfieldName'),
			permissionToRole: t('default.permissionToRole'),
			helpText: t('default.helpText'),
			nothing: t('default.nothing'),
			save: t('default.save'),
			cancel: t('cancel')
		};

		this.state = {
			fieldName: props.name || props.field.name,
			permissionToRole: this.lang.nothing,
			tooltip: props.field.tooltip || '',
			helpTextLenght: 100
		};
		this.changeHelpText = this.changeHelpText.bind(this);
	}

	changeHelpText(value) {
		if (value.split('\n').length <= 3 && value.length <= this.state.helpTextLenght) {
			this.setState({ tooltip: value });
		}
	}

	render() {
		const { onClose, onSave } = this.props.actions;
		const { tooltip, helpTextLenght } = this.state;
		const { title, body, fieldName, permissionToRole, helpText, save, cancel } = this.lang;
		const helpTextPercentage = (tooltip.length / helpTextLenght) * 100;
		return (
			<div className="EditStandardFieldModal">
				<ModalHeader className="ModalHeader--no-border-bottom" color="blue" title={title} onClose={onClose} />
				<ModalContent className="ModalContent--no-padding">
					<div className="EditStandardFieldModal__title-body">{body}</div>
					<div className="EditStandardFieldModal__input-wrapper">
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>{fieldName}</Label>
							<Input value={this.state.fieldName} disabled />
						</div>
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>{permissionToRole}</Label>
							<Input value={this.state.permissionToRole} disabled />
						</div>
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>
								<div className="EditStandardFieldModal__input-label">
									{helpText}
									<Help articleId={552} sidebar={true} />
									<Progress
										className="EditStandardFieldModal__progress"
										percentage={helpTextPercentage}
										strokeWidth={12}
										hideText
										state={helpTextPercentage < 100 ? undefined : 'error'}
									/>
								</div>
							</Label>
							<textarea
								className="EditStandardFieldModal__textarea"
								value={tooltip}
								onChange={e => this.changeHelpText(e.target.value)}
								rows={3}
							/>
						</div>
					</div>
				</ModalContent>
				<ModalControls>
					<Button
						color="bright-blue"
						shadow="none"
						onClick={() => onSave(Object.assign({}, this.props.field, { tooltip }))}
					>
						{save}
					</Button>
					<Button color="grey" type="link" onClick={onClose}>
						{cancel}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

EditStandardFieldModal.propTypes = {
	actions: PropTypes.object.isRequired,
	field: PropTypes.object.isRequired,
	name: PropTypes.string
};

export default EditStandardFieldModal;

'use strict';

angular.module('upFilters').filter('momentFormat', function () {
	return function (date, format) {
		var momentDate = moment(date, format);
		if (date && momentDate.isValid()) {
			return momentDate.format(format);
		}
		return '';
	};
});

'use strict';
ReactTemplates.admin.fields = window.AdminFieldPage;
ReactTemplates.admin.newFieldsModal = window.ReactCreateClass({
	timeout: null,
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			title: t(this.props.title),
			save: t('default.save'),
			abort: t('default.abort'),
			edit: t('default.edit'),
			new: t('default.new2'),
			alternativesToChooseFrom: t('admin.fields.alternativesToChooseFrom'),
			usersCanSelectMultipleUsers: t('admin.fields.usersCanSelectMultipleUsers'),
			customFieldName: t('admin.customfieldName'),
			permission: t('default.permissionToRole'),
			formRequired: t('form.required'),
			preDefinedField_description: t('admin.fields.bisnodeAliasText'),
			preDefinedField_elevio_linkText: t('admin.fields.typeElevioText'),
			oneOptionPerRow: t('admin.fields.oneItemPerRow'),
			clickHere: t('default.clickHere'),
			requires: t('apps.requires'),
			upsalesPlus: t('admin.upsalesVersion.plus')
		};

		this.hasRoleFeature = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	generatePREDEFINEDdata: function (cfs, def) {
		var aliases = [
			'TURNOVER',
			'NO_EMPLOYEES_SITE',
			'NO_EMPLOYEES_COMPANY',
			'PRIMARY_LINE_OF_BUSINESS',
			'RATING',
			'LEGALFORM',
			'ORG_NO',
			'COMPANY_TYPE'
		];

		var language = (Tools.AppService.getAccountSelf().language || '').replace('-', '_');
		var sniCodeOptions = this.props.SniCodes.get(language);

		return _.filter(aliases, function (alias) {
			return _.find(cfs, { alias: alias }) === undefined;
		}).map(function (alias) {
			var opts;

			switch (alias) {
				case 'TURNOVER':
					opts = {
						name: Tools.$translate('default.turnover'),
						datatype: 'Currency',
						alias: 'TURNOVER'
					};
					break;
				case 'NO_EMPLOYEES_SITE':
					opts = {
						name: Tools.$translate('default.numOfEmployees'),
						default: [
							'0',
							'1-4',
							'5-9',
							'10-19',
							'20-49',
							'50-99',
							'100-199',
							'200-499',
							'500-999',
							'1000-1999',
							'2000-2999',
							'3000-3999',
							'4000-4999',
							'5000-9999',
							'10000-'
						],
						datatype: 'Select',
						alias: 'NO_EMPLOYEES_SITE'
					};
					break;
				case 'NO_EMPLOYEES_COMPANY':
					opts = {
						name:
							Tools.$translate('default.numOfEmployees') +
							' (' +
							Tools.$translate('default.client') +
							')',
						default: [
							'0',
							'1-4',
							'5-9',
							'10-19',
							'20-49',
							'50-99',
							'100-199',
							'200-499',
							'500-999',
							'1000-1999',
							'2000-2999',
							'3000-3999',
							'4000-4999',
							'5000-9999',
							'10000-'
						],
						datatype: 'String',
						alias: 'NO_EMPLOYEES_COMPANY'
					};
					break;
				case 'PRIMARY_LINE_OF_BUSINESS':
					opts = {
						name: Tools.$translate('default.companyBranch'),
						datatype: 'Select',
						alias: 'PRIMARY_LINE_OF_BUSINESS',
						default: _.map(sniCodeOptions, function (name, code) {
							return code + ' ' + name;
						})
					};
					break;
				case 'RATING':
					var options = [
						'admin.customfieldCreditRatingAAA',
						'admin.customfieldCreditRatingAA',
						'admin.customfieldCreditRatingA',
						'admin.customfieldCreditRatingNewCompany',
						'admin.customfieldCreditRatingB',
						'admin.customfieldCreditRatingC',
						'admin.customfieldCreditRatingUnknown'
					].map(function (option) {
						return Tools.$translate(option);
					});

					opts = {
						name: Tools.$translate('default.creditRating'),
						default: options,
						datatype: 'Select',
						alias: 'RATING'
					};

					break;

				case 'LEGALFORM':
					opts = {
						name: Tools.$translate('soliditet.corporateForm'),
						alias: 'LEGALFORM'
					};
					break;
				case 'ORG_NO':
					opts = {
						name: Tools.$translate('default.orgNumber'),
						alias: 'ORG_NO'
					};
					break;
				case 'COMPANY_TYPE':
					opts = {
						name: Tools.$translate('soliditet.companyType'),
						default: ['soliditet.headquarters', 'soliditet.establishments'].map(function (option) {
							return Tools.$translate(option);
						}),
						datatype: 'Select',
						alias: 'COMPANY_TYPE'
					};
					break;
			}

			return _.assign({}, def, opts);
		});
	},
	getInitialState: function () {
		var sortId;

		if (this.props.field === null) {
			sortId = _.reduce(
				this.props.customFields,
				function (maxSortId, cf) {
					if (cf.sortId > maxSortId) return cf.sortId;
					return maxSortId;
				},
				-1
			);
		} else {
			sortId = this.props.field.sortId;
		}

		var field = _.pick(this.props.field, [
			'name',
			'id',
			'datatype',
			'sortId',
			'default',
			'obligatoryField',
			'roles',
			'visible',
			'editable',
			'dropdownDefault'
		]);

		this.defaultField = {
			name: '',
			id: null,
			sortId: sortId,
			default: '',
			datatype: 'String',
			obligatoryField: false,
			visible: 1,
			editable: 1,
			dropdownDefault: []
		};

		var preDefinedFieldsData =
			this.props.type === 'account'
				? this.generatePREDEFINEDdata(this.props.customFields, this.defaultField)
				: null;

		var _field = _.assign({}, this.defaultField, field);

		return {
			field: _field,
			datatypes: [
				{ type: 'String', icon: 'A' },
				{ type: 'Date', icon: <i className="fa fa-calendar" /> },
				{ type: 'Integer', icon: '42', class: 'icon number' },
				{ type: 'Boolean', icon: 'Yes/No', class: 'icon yn' },
				{ type: 'Percent', icon: <i className="fa fa-percent" /> },
				{ type: 'Time', icon: <i className="fa fa-clock-o" /> },
				{ type: 'Currency', icon: <i className="fa fa-usd" /> },
				{ type: 'Select', icon: <i className="fa fa-list-ul" /> },
				{ type: 'Email', icon: <i className="fa fa-at" /> },
				{ type: 'Link', icon: <i className="fa fa-link" /> },
				{ type: 'Text', icon: <i className="fa fa-pencil" /> },
				{ type: 'Discount', icon: '25%', class: 'icon number' },
				{ type: 'User', icon: <i className="fa fa-user" /> }
			],
			preDefinedField: null,
			preDefinedFieldsData: preDefinedFieldsData
		};
	},
	predefinedChanged: function (event) {
		var config = _.find(this.state.preDefinedFieldsData, { alias: event.target.value });

		this.setState({
			field: _.cloneDeep(config ? config : this.defaultField)
		});
	},
	typeChange: function (type) {
		var field = _.cloneDeep(this.state.field);

		field.datatype = type;
		field.default = '';
		field.dropdownDefault = [];
		field.name = this.state.field.name || '';

		this.setState({
			field: field
		});
	},
	selectUpdate: function (values) {
		var field = _.cloneDeep(this.state.field);
		field.default = values.split('\n');

		this.setState({
			field: field
		});
	},
	usersUpdate: function () {
		var field = _.cloneDeep(this.state.field);
		field.canSelectMultipleUsers = field.datatype === 'Users';

		this.setState({
			field: field
		});
	},
	initAce: function (setValue) {
		if ((this.state.field.datatype === 'User' || this.state.field.datatype === 'Users') && setValue) {
			this.usersUpdate();
		} else if (this.state.field.datatype === 'Select') {
			this._editor = ace.edit('editor');
			this._editor.renderer.setShowGutter(true);
			this._editor.renderer.setPadding(3);

			if (setValue) {
				if (Array.isArray(this.state.field.default)) {
					this._editor.setValue(this.state.field.default.join('\n'));
				}
				this._editor.clearSelection();
			}

			this._editor.getSession().on('change', () => {
				if (this.timeout) {
					clearTimeout(this.timeout);
					this.timeout = null;
				}

				this.timeout = setTimeout(() => {
					this.selectUpdate(this._editor.getValue());
				}, 200);
			});
		}
	},
	componentWillUnmount: function () {
		if (this._editor) {
			this._editor.destroy();
		}
	},
	componentDidMount: function () {
		this.initAce(true);
	},
	shouldComponentUpdate: function (nextProps, nextState) {
		return JSON.stringify(this.state.field) !== JSON.stringify(nextState.field);
	},
	componentDidUpdate: function () {
		if (this.state.field.alias && this.state.field.alias.length) {
			this.initAce(true);
		} else {
			this.initAce(false);
		}
	},
	generateTypes: function () {
		var self = this;

		var rows = _.map(this.state.datatypes, function (dt) {
			var _class = self.state.field.datatype === dt.type ? 'type-select active' : 'type-select';
			return (
				<td key={'td-type-select-' + dt.type} className={_class} onClick={self.typeChange.bind(self, dt.type)}>
					<div className={dt.class ? dt.class : 'icon'}>{dt.icon}</div>
					<div className="text">{Tools.$translate('admin.customfieldType.' + dt.type)}</div>
				</td>
			);
		});

		var chunks = _.chunk(rows, 6);

		var _rows = (
			<tbody>
				{chunks.map(function (c, i) {
					return <tr key={i}>{c}</tr>;
				})}
			</tbody>
		);

		return (
			<div
				className="type-select-container"
				style={{
					position: 'relative',
					borderBottom: '1px solid rgba(0,0,0,0.1)'
				}}
			>
				{this.state.preDefinedField !== null && (
					<div
						style={{
							cursor: 'no-drop',
							position: 'absolute',
							left: 0,
							bottom: 0,
							right: 0,
							top: 0,
							backgroundColor: 'rgba(0,0,0,0.075)'
						}}
					/>
				)}
				<table
					style={{
						width: '100%',
						borderCollapse: 'separate'
					}}
				>
					{_rows}
				</table>
			</div>
		);
	},
	nameChange: function (e) {
		var field = _.cloneDeep(this.state.field);
		field.name = e.target.value;

		this.setState({
			field: field
		});
	},
	rolesChange: function (roles) {
		var field = _.cloneDeep(this.state.field);
		field.roles = roles;

		this.setState({
			field: field
		});
	},
	requiredFieldChange: function (required) {
		var field = _.cloneDeep(this.state.field);
		field.obligatoryField = required ? 1 : 0;
		this.setState({
			field: field
		});
	},
	handleCanSelectMultipleChange: function (checked) {
		var field = _.cloneDeep(this.state.field);
		field.canSelectMultipleUsers = Number(checked);
		this.setState({
			field: field
		});
	},
	preFilledValueChange: function (event) {
		var value = event.target.value;
		var field = _.cloneDeep(this.state.field);

		if (!value) {
			field.dropdownDefault = [];
		} else {
			field.dropdownDefault = [value];
		}

		this.setState({
			field: field
		});
	},
	validate: function () {
		var field = _.cloneDeep(this.state.field);
		if (field.hasOwnProperty('canSelectMultipleUsers')) {
			field.datatype = field.canSelectMultipleUsers ? 'Users' : 'User';
		}
		this.props.confirm(field, this.props.type);
	},
	render: function () {
		var roleselect = (
			<div>
				<ReactTemplates.INPUTS.upRoles
					multiple={true}
					disabled={!this.hasRoleFeature}
					key={'select-not-active'}
					className="form-control multi-comparitors"
					onChange={this.rolesChange}
					data={this.props.roles}
					value={this.state.field.roles}
					placeholder={this.lang.permission}
				/>
			</div>
		);

		return (
			<div className="edit-custom-fields-modal">
				<div className="up-modal-header">
					<h4 className="title">
						{this.state.field.id ? this.lang.edit : this.lang.new} {this.lang.title.toLowerCase()}
					</h4>

					<a className="btn btn-link modal-close" onClick={this.props.close}>
						<i className="fa fa-times" />
					</a>
				</div>
				<div className="up-modal-content no-padding">
					{this.props.type === 'account' ? (
						<div className="textify">
							<p>{this.lang.preDefinedField_description}</p>
							<ReactTemplates.INPUTS.upSelect
								key={'select-preDefined'}
								allowClear={true}
								className={'select-textselect'}
								placeholder={this.lang.clickHere}
								data={this.state.preDefinedFieldsData}
								minimumResultsForSearch={-1}
								onChange={this.predefinedChanged}
								matcher={function (term, text, field) {
									return field.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
								}}
								formatSelection={function (field, container, escape) {
									return escape(field.name);
								}}
								formatResult={function (field, container, query, escape) {
									return escape(field.name);
								}}
								getId={function (field) {
									return field.alias;
								}}
							/>
						</div>
					) : null}
					<p className="link-alike">
						<ReactTemplates.elevio
							sidebar={true}
							articleId={597}
							text={this.lang.preDefinedField_elevio_linkText}
						/>
					</p>

					{!this.state.field.id && this.generateTypes()}
					<div style={{ margin: '15px 0' }}>
						<div className="col-xs-6">
							<label>
								{this.lang.customFieldName}
								<b
									style={{
										fontSize: '12px',
										lineHeight: '1.8em',
										color: '#b94a48',
										marginLeft: '3px'
									}}
									className="text-danger"
								>
									{'*'}
								</b>
							</label>
							<input
								required
								value={this.state.field.name}
								onChange={this.nameChange}
								className="form-control"
								placeholder={this.lang.customFieldName}
								type="text"
								maxLength="128"
								style={{
									borderRadius: '2px'
								}}
							/>

							{this.state.field.datatype === 'Select' && (
								<div
									key="permission-setting"
									style={{
										marginTop: '10px'
									}}
								>
									<label>{this.lang.permission}</label>
									<ReactTemplates.INPUTS.upRoles
										multiple={true}
										disabled={!this.hasRoleFeature}
										key={'select-active'}
										ref={this.setRef.bind(this, '_selectRole')}
										className="form-control multi-comparitors"
										onChange={this.rolesChange}
										data={this.props.roles}
										value={this.state.field.roles}
										placeholder={this.lang.permission}
									/>
								</div>
							)}

							{this.state.field.datatype === 'Select' && (
								<div style={{ marginTop: '10px' }}>
									<label>{Tools.$translate('admin.fields.prefilledValues')}</label>
									<ReactTemplates.INPUTS.upSelect
										key={(this.state.field.default || '').toString()}
										placeholder={Tools.$translate('admin.fields.prefilledValues')}
										formatSelection={function (o, container, escape) {
											return escape(o);
										}}
										formatResult={function (o, container, query, escape) {
											return escape(o);
										}}
										getId={function (value) {
											return value;
										}}
										className="form-control"
										multiple={false}
										data={Array.isArray(this.state.field.default) ? this.state.field.default : []}
										onChange={this.preFilledValueChange}
										defaultValue={
											Array.isArray(this.state.field.dropdownDefault) &&
											this.state.field.dropdownDefault.length
												? this.state.field.dropdownDefault[0]
												: ''
										}
									/>
								</div>
							)}

							<div key="requiredField-toggle" style={{ marginTop: 10 }}>
								<label>{this.lang.formRequired}</label>
								<ReactTemplates.upFilters.components.toggle
									checked={this.state.field.obligatoryField}
									className="toggle-bright-blue toggle-lg"
									onChange={this.requiredFieldChange}
								/>
							</div>
						</div>

						{this.state.field.datatype === 'Select' && (
							<div className="col-xs-6">
								<label>{this.lang.alternativesToChooseFrom}</label>
								{ReactTemplates.TOOLS.withTooltip(<div id="editor" />, this.lang.oneOptionPerRow, {
									placement: 'top',
									key: 'editor-tool-tip'
								})}
							</div>
						)}

						{this.state.field.datatype !== 'Select' && (
							<div className="col-xs-6">
								<label>{this.lang.permission}</label>
								{!this.hasRoleFeature
									? ReactTemplates.TOOLS.withTooltip(
											roleselect,
											this.lang.requires + ' ' + this.lang.upsalesPlus,
											{ placement: 'top', key: 'upRoles-tool-tip' }
									  )
									: roleselect}
								{(this.state.field.datatype === 'Users' || this.state.field.datatype === 'User') && (
									<div key="canSelectMultipleUsers-toggle" style={{ marginTop: 10 }}>
										<label>{this.lang.usersCanSelectMultipleUsers}</label>
										<ReactTemplates.upFilters.components.toggle
											checked={this.state.field.canSelectMultipleUsers || false}
											className="toggle-bright-blue toggle-lg"
											onChange={this.handleCanSelectMultipleChange}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="up-modal-controls">
					<button
						type="submit"
						className="btn btn-sm up-btn btn-bright-blue no-shadow"
						disabled={this.props.saving || !this.state.field.name}
						onClick={this.validate}
					>
						{this.lang.save}
						{this.props.saving && <span className="fa fa-refresh" />}
					</button>
					<button
						style={{ color: 'grey' }}
						type="button"
						className="btn btn-link btn-sm"
						onClick={this.props.close}
					>
						{this.lang.abort}
					</button>
				</div>
			</div>
		);
	}
});

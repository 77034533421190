import { insertAfter } from 'App/babel/store/helpers/array';
import { getLoggedInUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import logError from 'App/babel/helpers/logError';
import { isCustom, getCustomConfig, getFieldWithPrefix, getConfigName } from 'App/helpers/filterHelper';
import { mergeFilters as mergeSegmentFilters } from 'App/services/SegmentService';
import ComparisonTypes from 'Resources/ComparisonTypes';
import buildCustomFilter from './buildCustomFilter';
import parse from './filterParse';

angular.module('upUi').service('FilterHelper', [
	'ListAttributes',
	'$location',
	'RequestBuilder',
	'$parse',
	'FilterType',
	'AppService',
	'ENV',
	'FeatureHelper',
	function (ListAttributes, $location, RequestBuilder, $parse, FilterType, AppService, ENV, FeatureHelper) {
		var Instance = {};

		// Make this one global and shared with requestbuilder later
		var ComparisonType = {
			Equals: 'eq',
			NotEquals: 'ne',
			GreaterThan: 'gt',
			GreaterThanEquals: 'gte',
			LessThan: 'lt',
			LessThanEquals: 'lte',
			LowerThan: 'lt',
			LowerThanEquals: 'lte',
			Like: 'like',
			Between: 'bt',
			NotBetween: 'nbt',
			Wildcard: 'wc',
			WildcardEnd: 'wce',
			Match: 'm',
			MatchPhrase: 'mp',
			Search: 'src'
		};

		var matchValues = function (objVal, filterVal, op, attr) {
			var compVal = objVal;
			var match = false;
			var mVal;
			var mFilt;

			if (attr.type === FilterType.Date) {
				// moment(null) is invalid, but moment(undefined) is todays date
				mFilt = moment(filterVal ?? null);
				mVal = moment(compVal ?? null);
			} else if (attr.type === 'string' && compVal) {
				//we convert strings to lowercase because the filter from the UI is all lowercased and we want to be case insensitive
				compVal = compVal.toLowerCase();
			}

			switch (op) {
				default:
				case 'eq':
					if (attr.type === FilterType.Date && mVal.isValid()) {
						match = mVal.isSame(mFilt, 'day');
					} else if (_.isArray(filterVal)) {
						match = filterVal.indexOf(compVal) !== -1;
					} else {
						// eslint-disable-next-line eqeqeq
						match = compVal == filterVal; // no tripple operator here 0 needs to match false
					}
					break;

				case 'ne':
					if (_.isArray(filterVal)) {
						match = filterVal.indexOf(compVal) === -1;
					} else {
						// eslint-disable-next-line eqeqeq
						match = compVal != filterVal; // no tripple operator here 0 needs to match false
					}
					break;

				case 'lte':
					if (attr.type === FilterType.Date) {
						match = mVal.isSame(mFilt, 'day') || mVal.isBefore(mFilt, 'day');
					} else {
						match = compVal <= filterVal;
					}
					break;

				case 'lt':
					if (attr.type === FilterType.Date) {
						match = mVal.isBefore(mFilt, 'day');
					} else {
						match = compVal < filterVal;
					}
					break;

				case 'gte':
					if (attr.type === FilterType.Date) {
						match = mVal.isSame(mFilt, 'day') || mVal.isAfter(mFilt, 'day');
					} else {
						match = compVal >= filterVal;
					}
					break;

				case 'gt':
					if (attr.type === FilterType.Date) {
						match = mVal.isAfter(mFilt, 'day');
					} else {
						match = compVal > filterVal;
					}
					break;
				// we can possibly remove the following 3 compVal.toLowerCase()
				case 'src':
					if (Array.isArray(filterVal)) {
						match = filterVal.some(val => ('' + compVal).indexOf(val.toLowerCase()) !== -1);
					} else {
						match = ('' + compVal).toLowerCase().indexOf(filterVal.toLowerCase()) !== -1;
					}
					break;
				case 'wc':
					match = ('' + compVal).toLowerCase().indexOf(filterVal.toLowerCase()) !== -1;
					break;
				case 'wce':
					match = ('' + compVal).toLowerCase().endsWith(filterVal.toLowerCase());
					break;
			}

			// Good for debug do not remove
			// console.log('matching', compVal, 'against', filterVal, 'op:', op, 'match:', match);

			return match;
		};

		var matchFilter = function (filter, obj, attrs, throwIfNoAttr) {
			var superattr;
			_.forEach(attrs, function (subAttr) {
				if (superattr) {
					return;
				}

				if (subAttr.field === filter.a) {
					superattr = subAttr;
					return;
				}
				if (subAttr.hasOwnProperty('attr')) {
					superattr = _.find(_.values(subAttr.attr), { field: filter.a });
					if (superattr) {
						superattr.parent = subAttr.parent;
						return;
					}
				}
				superattr = null;
			});

			if (!superattr) {
				if (console) {
					console.warn('No such attr in filter:', filter);
				}
				if (throwIfNoAttr) {
					throw new Error('No such attr in filter');
				}
				if (filter.v === null || filter.v === undefined) {
					return true;
				}
				return false;
			}

			if (superattr.parent) {
				var split = filter.a.split('.');
				var parent = $parse(superattr.parent)(obj);
				var match = false;

				// Remove first split element if it is the same as the parent, for example
				//   contact uses users.id instead of client.users.id, where client is the parent
				if (split[0] === superattr.parent) {
					split.shift();
				}

				if (_.isArray(parent)) {
					angular.forEach(parent, function (sub) {
						if (matchValues(sub[split[split.length - 1]], filter.v, filter.c, superattr)) {
							match = true;
						}
					});
					return match;
				} else {
					if (parent) {
						if (_.isArray(parent[split[0]])) {
							angular.forEach(parent[split[0]], function (sub) {
								if (matchValues(sub[split[split.length - 1]], filter.v, filter.c, superattr)) {
									match = true;
								}
							});
							return match;
						} else {
							return matchValues(parent[split[0]], filter.v, filter.c, superattr);
						}
					} else {
						return matchValues(null, filter.v, filter.c, superattr);
					}
				}
			} else {
				return matchValues($parse(filter.a)(obj), filter.v, filter.c, superattr);
			}
		};

		Instance.getConfig = function (name, type) {
			var config, customType;
			customType = type + '';
			// Maybe this can be done in some other place
			if (type === 'assignedLeads' || type === 'unassignedLeads') {
				type = 'account';
			}

			if (type === 'opportunity') {
				type = 'order';
			}

			if (type.indexOf('userDefinedObject') === 0) {
				var nr = parseInt(type[type.length - 1]);
				nr = isNaN(nr) ? '' : nr;
				customType = 'userDefined' + nr;
				type = type.slice(0, -1);
			}

			if (Instance.isCustom(name)) {
				var nameType = name.split('.')[0];

				if (customType === 'agreement' && name.indexOf('Agreement') === -1) {
					customType = 'order';
				} else if (
					type.indexOf('userDefinedObject') === -1 &&
					name.indexOf('Agreement') === -1 &&
					name.indexOf('Custom_') !== 0 &&
					nameType !== type
				) {
					customType = nameType;
				} else if (customType === 'campaign') {
					customType = 'project';
				}

				config = Instance.getCustomConfig(name, AppService.getCustomFields(customType));
			} else if (ListAttributes.get(type).standardFilters[name] instanceof window.Filter) {
				var filterConfig = ListAttributes.get(type).standardFilters[name];
				config = Object.assign(Object.create(Object.getPrototypeOf(filterConfig)), filterConfig);
			} else {
				config = _.cloneDeep(ListAttributes.get(type).standardFilters[name]);
			}

			if (config) {
				config.filterName = name;
				if (config.isExcludeFilter) {
					config.comparisonType = 'NotEquals';
				}
			} else if (ENV === 'DEV' && console && console.warn) {
				console.warn('No filterconfig found named "' + name + '" in type', type);
			}

			return config;
		};

		var mapSort = function (sort, type) {
			if (Instance.isCustom(sort.attribute)) {
				var configName = Instance.getConfigName(sort.attribute);
				var config = Instance.getConfig(configName, type);

				if (config && config.$field) {
					switch (config.$field.datatype) {
						case 'Date':
							sort.attribute = sort.attribute + '.valueDate';
							break;
						case 'Time':
							sort.attribute = sort.attribute + '.valueTime';
							break;
						case 'Boolean':
							sort.attribute = sort.attribute + '.valueBoolean';
							break;
						case 'Integer':
						case 'Calculation':
							sort.attribute = sort.attribute + '.valueInteger';
							break;
						case 'Currency':
						case 'Percent':
						case 'Discount':
							sort.attribute = sort.attribute + '.valueDouble';
							break;
					}
				}
			}
			return sort;
		};

		Instance.parseSort = function (sort, type) {
			var sortCopy = _.cloneDeep(sort);

			if (Array.isArray(sortCopy)) {
				return _.map(sortCopy, function (sort) {
					return mapSort(sort, type);
				});
			} else {
				return mapSort(sortCopy, type);
			}
		};

		Instance.getFilterName = function (filterName, config) {
			return config.allowMultipleSelections ? filterName + '_fix_' + new Date().valueOf() : filterName;
		};

		Instance.getConfigName = getConfigName;

		// Parse filters for request
		Instance.parseFilters = function (filters, type, requestBuilder, fieldPrefix, options) {
			if (!requestBuilder) {
				requestBuilder = new RequestBuilder(type);
			}

			options = options || {};

			var categories = {};
			var prefix = '';
			var getConfig = _.get(options, 'getConfig') || Instance.getConfig;

			if (fieldPrefix) {
				prefix = fieldPrefix + '.';
			}

			angular.forEach(filters, function (filter, filterName) {
				const configName = Instance.getConfigName(filterName);
				const filterConfig = getConfig(configName, type);
				// custom filters are already grouped so need to wrap them.
				const useLocalBuilder = _.get(options, 'groupAllFilters', false) && !Instance.isCustom(configName);
				const localBuilder = useLocalBuilder ? requestBuilder.groupBuilder() : requestBuilder;

				// If we do not find the filter we skip it
				if (!filterConfig) {
					return;
				}

				var field = prefix + filterConfig.field;

				// switch filter type
				switch (filter.forceType || filterConfig.type) {
					// RAW-filter (can do whatever the fuck it wants :D )
					case 'raw':
						if (filterConfig.build && typeof filterConfig.build === 'function') {
							// Run the filterConstructor function
							filterConfig.build(
								filter,
								localBuilder,
								getFieldWithPrefix(fieldPrefix),
								options.useTags,
								filters,
								getConfig
							);
						}
						break;

					// list
					case 'idList':
					case 'list':
					case 'listShort':
						if (Instance.isCategory(filterName) && filter.value && filter.value.length) {
							if (!categories[filter.comparisonType]) {
								categories[filter.comparisonType] = { field: filter.field, value: [] };
							}
							categories[filter.comparisonType].value.push(filter.value);
						} else if (
							!filter.inactive &&
							!(filterConfig.isExcludeFilter && options && options.idListIsInactive)
						) {
							parse.list(localBuilder, field, filter.value, ComparisonTypes[filter.comparisonType]);
						}
						break;

					// normal
					case 'text':
					case 'normal':
						parse.string(localBuilder, field, filter.value, ComparisonTypes[filter.comparisonType]);
						break;

					case 'boolean':
						parse.boolean(localBuilder, field, filter.value);
						break;

					case 'radio':
						if (!filter.inactive) {
							var comparisonType = filter.comparisonType
								? ComparisonTypes[filter.comparisonType]
								: ComparisonTypes.Equals;
							localBuilder.addFilter({ field: field }, comparisonType, filter.value);
						}
						break;

					// not equals null (only for bool filterType)
					case 'notNull':
						if (filter.value) {
							localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, null);
						} else {
							if (filterConfig.inactiveOnTrue) {
								// Do nothing.
							} else {
								localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, null);
							}
						}
						break;
					// not equals 0 (only for bool filterType)
					case 'notZero':
						if (filter.value) {
							localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, 0);
						} else {
							localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, 0);
						}
						break;

					// not equals empty array (only for bool filterType)
					case 'notEmptyArray':
						if (filter.value) {
							localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, []);
						} else {
							localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, []);
						}
						break;

					// range
					case 'dateRange':
					case 'datePreset': {
						const exclude = filter.comparisonType === 'NotEquals';
						parse.dateRange(localBuilder, field, filter.value, null, options.useTags, exclude);

						if (filter.value && filter.value.status) {
							switch (filter.value.status) {
								case 'open':
									localBuilder.addFilter(
										{ field: 'closeDate', type: 'date' },
										ComparisonTypes.Equals,
										null
									);
									localBuilder.addFilter(
										{ field: 'isAppointment', type: 'boolean' },
										ComparisonTypes.Equals,
										false
									);
									break;
								case 'closed':
									localBuilder.addFilter(
										{ field: 'closeDate', type: 'date' },
										ComparisonTypes.NotEquals,
										null
									);
									localBuilder.addFilter(
										{ field: 'isAppointment', type: 'boolean' },
										ComparisonTypes.Equals,
										false
									);
									break;
							}
						}
						break;
					}
					case 'range':
						parse.range(localBuilder, field, filter.value);
						break;

					// custom field filter
					case 'custom':
						buildCustomFilter(filter, filterName, filterConfig, type, localBuilder, prefix, options);
						break;
				}

				if (useLocalBuilder) {
					const groupFilter = localBuilder.getQuery();
					const innerFilters = _.get(groupFilter, 'group.q[0]', []);
					if (innerFilters.length) {
						if (innerFilters.length === 1 && !groupFilter.group.not) {
							requestBuilder.queryArray.push(innerFilters[0]);
						} else {
							localBuilder.done();
						}
					}
				}
			});

			angular.forEach(categories, function (cat, comparisonType) {
				parse.list(requestBuilder, prefix + cat.field, _.flatten(cat.value), ComparisonTypes[comparisonType]);
			});

			return requestBuilder;
		};

		Instance.isInactiveValue = function (filter, type, filterConfig, options = {}) {
			if (!filter || !type) {
				return true;
			}

			// Get config
			if (!filterConfig) {
				const configName = Instance.getConfigName(filter.filterName);
				const getConfig = _.get(options, 'getConfig') || Instance.getConfig;
				filterConfig = getConfig(configName, type);
			}

			// Raw filters
			if (filterConfig.type === 'raw') {
				if (filterConfig.isInactive) {
					return filterConfig.isInactive(filter);
				} else {
					return filter.inactive;
				}
			}

			if (filter.inactive) {
				return true;
			}

			// change to only type when all old filters are gone
			var inputType = (filterConfig.inputType || filterConfig.type)?.toLowerCase();

			if (!inputType) {
				return true;
			}

			if (Instance.isCustom(filterConfig.filterName) && filterConfig.$field && filterConfig.$field.datatype) {
				inputType = filterConfig.$field.datatype.toLowerCase();
			}

			// Empty multi selects
			if (_.isArray(filter.value) && !filter.value.length) {
				return true;
			}

			// Inactive booleans
			if (inputType === 'boolean') {
				if (filter.value === null) {
					return true;
				}

				// Active false-booleans
				if (filter.value === false) {
					return false;
				}

				return false;
			}
			if (inputType === 'radio') {
				// If this gets here it is active
				return false;
			}

			if (inputType === 'time') {
				if (!filter.value.start && !filter.value.end) {
					return true;
				}
			}

			// Empty range filters
			if (
				(inputType === 'date' ||
					inputType === 'integer' ||
					inputType === 'calculation' ||
					inputType === 'currency' ||
					inputType === 'discount' ||
					inputType === 'percent' ||
					inputType === 'dateRange' ||
					inputType === 'range') &&
				(filter.value.preset === 'whenever' || (filter.value.start === null && filter.value.end === null))
			) {
				return true;
			}

			// Empty text filters and other filters
			if (filter.value === '' || !filter.value) {
				return true;
			}

			return false;
		};

		// Converts filterObject to url friendly filters
		Instance.convertForURL = function (rawFilters, type, options) {
			options = options || {};
			var getConfig = _.get(options, 'getConfig') || Instance.getConfig;
			var filters = [];

			angular.forEach(rawFilters, function (filter, name) {
				var configName = Instance.getConfigName(name);
				var filterConfig = getConfig(configName, type);
				if (!(options.skipInactive && Instance.isInactiveValue(filter, type, filterConfig))) {
					var f;

					// switch filter type
					switch (filterConfig.type) {
						case 'raw':
							if (filterConfig.toUrl) {
								f = filterConfig.toUrl(filter);
							} else {
								f = { v: filter.value };
							}
							break;

						// normal
						//case 'list':
						//	filters.push(JSON.stringify({n: name, c: filter.comparisonType, v: filter.value}));
						//	break;
						case 'dateRange':
							var value = {};
							value.preset = filter.value.preset;
							if (value.preset === 'custom') {
								value.start = filter.value.start;
								value.end = filter.value.end;
							}
							if (filter.value.status) {
								value.status = filter.value.status;
							}
							if (value.preset?.startsWith('lastX') || value.preset?.startsWith('nextX')) {
								value.start = filter.value.start;
							}

							f = { v: value };
							break;

						case 'text':
							if (filter.value) {
								f = { c: filter.comparisonType, v: filter.value };
							}
							break;

						default:
						case 'list':
						case 'listShort':
						case 'normal':
						case 'range':
						case 'boolean':
							f = { c: filter.comparisonType, v: filter.value };
							if (filter.inactive) {
								f.i = 1;
							} else if (Array.isArray(filter.value) && !filter.value.length) {
								f = undefined;
							}
							break;

						case 'radio':
							f = { c: filter.comparisonType, v: filter.value };
							if (filter.inactive) {
								f.i = 1;
							}
							break;

						case 'notNull':
							// if nothing is selected
							// if( (filterConfig.filterType === 'dateRange' && filter.value.preset == 'whenever') || (!filter.value.start && !filter.value.end) ) {
							// 	return;
							// }
							f = { c: filter.comparisonType, v: filter.value };
							break;

						case 'custom':
							f = { c: filter.comparisonType, v: filter.value, d: filter.dataType };
							if (filter.inactive) {
								f.i = 1;
							}
							break;
					}

					if (f) {
						f.n = name;
						filters.push(f);
					}
				}
			});

			if (!filters.length) {
				filters = '';
			} else {
				filters = JSON.stringify(filters);
				filters = Tools.LZString.compressToBase64(filters);
				filters = encodeURIComponent(filters);
			}

			return filters;
		};

		// Parses any-url string to filters and returns them
		Instance.parseString = function (string, type, options) {
			var filters = {};
			var getConfig = _.get(options, 'getConfig') || Instance.getConfig;
			var query = [];

			if (!string || string === '') {
				return filters;
			}

			string = decodeURIComponent(string);
			const decompressedString = Tools.LZString.decompressFromBase64(string);

			if (decompressedString && typeof decompressedString === 'string') {
				try {
					query = JSON.parse(decompressedString);
				} catch (e) {
					logError(e, 'Failed to parse decompressed string');
				}
			}

			if (!Array.isArray(query)) {
				query = [query];
			}

			angular.forEach(query, function (filter) {
				var configName = Instance.getConfigName(filter.n);
				var c = getConfig(configName, type);
				if (!c) {
					return; // continue if filter was not found
				}
				var newFilter = Instance.filter(filter.n, type);
				if (c.type === 'raw' && c.fromUrl) {
					newFilter = c.fromUrl(filter);
				} else {
					if (
						(c.type === 'dateRange' || (c.type === 'custom' && c.displayType === 'dateRange')) &&
						filter.v.preset === 'custom'
					) {
						if (filter.v.start) {
							newFilter.value.start = new Date(filter.v.start);
						}
						if (filter.v.end) {
							newFilter.value.end = new Date(filter.v.end);
						}
						newFilter.value.preset = filter.v.preset;

						if (filter.v.status) {
							newFilter.value.status = filter.v.status;
						}
					} else {
						newFilter.value = filter.v;
					}
					if (filter.c) {
						newFilter.comparisonType = filter.c;
					}
					if (
						(c.type === 'idList' && filter.i) ||
						(c.type === 'list' && filter.v.length === 0) ||
						(c.type === 'listShort' && filter.v.length === 0) ||
						(c.type === 'radio' && filter.i) ||
						(c.type === 'custom' && filter.i)
					) {
						newFilter.inactive = true;
					}
					if (filter.d) {
						newFilter.dataType = filter.d;
					}
				}
				newFilter.filterName = filter.n;
				filters[filter.n] = newFilter;
			});

			return filters;
		};

		// Returns final filters based on hash
		Instance.parseFromURL = function (type) {
			var hash = $location.search();

			return Instance.parseString(hash.q, type);
		};

		// Returns new raw filter
		Instance.filter = function (name, type, options) {
			// Get config
			var getConfig = _.get(options, 'getConfig') || Instance.getConfig;
			var configName = Instance.getConfigName(name);
			var filterConfig = getConfig(configName, type);
			if (!filterConfig) {
				return null;
			}

			var filter;

			// switch filter type
			switch (filterConfig.type) {
				// RAW
				case 'raw':
					filter = filterConfig.generate();
					break;

				// normal
				default:
				case 'normal':
					filter = { value: '', comparisonType: 'Equals' };
					break;

				case 'custom':
					if (filterConfig.$field) {
						filter = { value: null, comparisonType: 'Equals' };

						switch (filterConfig.$field.datatype) {
							case 'Integer':
							case 'Calculation':
							case 'Currency':
							case 'Discount':
							case 'Percent':
							case 'Time':
								filter.value = { start: null, end: null };
								break;

							case 'Date':
								filter.value = { start: null, end: null, preset: 'whenever' };
								filter.comparisonType = null;
								break;

							case 'Boolean':
								filter.value = null;
								break;
							case 'User':
								filter.value = '';
								break;
							case 'Users':
								filter.value = [];
								break;

							case 'Text':
							case 'String':
							case 'Link':
							case 'Email':
								filter.comparisonType = 'Wildcard';
								break;
						}

						filter.dataType = filterConfig.$field.datatype;
					} else {
						filter = { value: '', comparisonType: 'Equals' };
					}
					break;

				case 'list':
				case 'listShort':
					filter = { value: [], comparisonType: filterConfig.comparisonType || 'Equals' };
					break;

				case 'dateRange':
					filter = { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
					break;

				case 'range':
					filter = { value: { start: null, end: null }, comparisonType: null };
					break;

				case 'radio':
					filter = { comparisonType: null };
					break;

				case 'boolean':
				case 'notNull':
					filter = { value: true, comparisonType: null };
					break;
			}

			filter.filterName = Instance.getFilterName(name, filterConfig);
			filter.columnPath = filterConfig.columnPath;

			if (Instance.isCustomCategory(name) || Instance.isCustom(name)) {
				filter.columnPath = name;
			}

			if (!filter.dataType) {
				filter.dataType = filterConfig.type;
			}

			if (filterConfig.type !== 'raw') {
				filter.field = filterConfig.field;
			}

			return filter;
		};

		// Parse filters from a view
		Instance.parseViewFilters = function (viewFilters, type) {
			var filters = {};
			angular.forEach(_.cloneDeep(viewFilters), function (filter) {
				var configName = Instance.getConfigName(filter.filterName);
				var filterConfig = Instance.getConfig(configName, type);
				var newFilter = Instance.filter(filter.filterName, type);

				if (!filterConfig || !newFilter) {
					return;
				}

				newFilter.value = filter.value;
				if (filter.comparisonType) {
					newFilter.comparisonType = filter.comparisonType;
				}

				if ((filterConfig.type === 'list' || filterConfig.type === 'listShort') && filter.value.length === 0) {
					newFilter.inactive = true;
				} else {
					newFilter.inactive = filter.inactive !== undefined ? filter.inactive : false;
				}
				filters[filter.filterName] = newFilter;
			});

			return filters;
		};

		var groupIsCustom = function (filterGroup) {
			var idFilterIndex = _.find(filterGroup, { a: 'custom.fieldId' });
			if (idFilterIndex) {
				return true;
			}
			return false;
		};

		var matchCustomFilter = function (filterGroup, obj, notFilter) {
			// Remove id filter to get value filter
			var filters = _.cloneDeep(filterGroup);
			var idFilter = _.remove(filters, { a: 'custom.fieldId' });

			// If we did find idFilter and value filter we match it
			if (idFilter && filters[0] && obj.custom) {
				const objValue = _.find(obj.custom, { fieldId: Number(idFilter[0].v) });
				if (notFilter && !objValue) {
					return true;
				}

				if (objValue) {
					if (filters[0].a === 'custom.valueArray') {
						// we had a sentry error here due to objValue.value being undefined (LINE-9293)
						// couldn't reproduce it so added a check for undefined
						const objValueArray = objValue.value ? objValue.value.split(',') : [];
						return notFilter
							? objValueArray.every(v =>
									matchValues(v, filters[0].v, ComparisonType.NotEquals, { type: 'string' })
							  )
							: objValueArray.some(v => matchValues(v, filters[0].v, filters[0].c, { type: 'string' }));
					}

					var type;
					switch (filters[0].a) {
						case 'custom.valueDouble':
						case 'custom.valueInteger':
							type = FilterType.Number;
							break;
						case 'custom.valueDate':
							type = FilterType.Date;
							break;
						case 'custom.valueBoolean':
							type = FilterType.Boolean;
							break;
						default:
							type = 'string';
					}

					return matchValues(objValue.value, filters[0].v, filters[0].c, { type: type });
				}
			}
			return false;
		};

		// Returns true/false if an object matches some filters
		// Useful when a object in a list is updated and you want to check if the object still fits the requirements of the list
		// TODO: finish it
		Instance.match = function (filters, obj, type, throwIfNoAttr) {
			if (!filters || !filters.length) {
				return true;
			}
			var attrs = ListAttributes.get(type).attr;
			var match = true;

			angular.forEach(filters, function (filter) {
				if (filter.hasOwnProperty('or')) {
					var orFilters = filter.or;
					var orMatch = false;
					if (!orFilters.q || !orFilters.q.length) {
						orMatch = true;
					} else {
						angular.forEach(orFilters.q, function (orFilter) {
							if (!orFilter || !orFilter.length) {
								orMatch = true;
							} else {
								var matches = 0;
								angular.forEach(orFilter, function (of) {
									if (
										(of.group && Instance.match([of], obj, type)) ||
										(!of.group && matchFilter(of, obj, attrs, throwIfNoAttr))
									) {
										matches++;
									}
								});
								// console.log('OR', matches, '/', orFilter.length);
								if (matches === orFilter.length) {
									orMatch = true;
								}
							}
						});
						if (!orMatch) {
							match = false;
						}
					}
				} else if (filter.hasOwnProperty('group')) {
					var groupFilter = filter.group;
					var groupMatch = false;
					const notFilter = groupFilter.not;
					if (!groupFilter.q || !groupFilter.q.length) {
						groupMatch = true;
					} else {
						angular.forEach(groupFilter.q, function (groupFilter) {
							if (!groupFilter || !groupFilter.length) {
								groupMatch = true;
							} else {
								if (groupIsCustom(groupFilter)) {
									groupMatch = matchCustomFilter(groupFilter, obj, notFilter);
								} else {
									var matches = 0;
									angular.forEach(groupFilter, function (of) {
										if (matchFilter(of, obj, attrs)) {
											matches++;
										}
									});
									// console.log('OR', matches, '/', groupFilter.length);
									if (matches === groupFilter.length) {
										groupMatch = true;
									}
								}
							}
						});
						if (!groupMatch) {
							match = false;
						}
					}
				} else {
					if (!matchFilter(filter, obj, attrs, throwIfNoAttr)) {
						match = false;
					}
				}
			});

			return match;
		};

		// Please keep moving logic to this typed file ui/app/helpers/filterHelper.ts
		Instance.isCustom = isCustom;

		Instance.isCategory = function (name) {
			return name === 'Category' || name.indexOf('Category_') !== -1;
		};

		Instance.isCustomCategory = function (name) {
			return name.indexOf('Category_') !== -1;
		};

		Instance.isAddress = function (name) {
			return name.indexOf('Address_') === 0;
		};

		Instance.isAdCampaign = function (name) {
			return name.indexOf('adCampaign.id_') === 0;
		};

		Instance.useIcon = function (name) {
			var icons = {
				'default.scoreVisits': { icon: 'Icon Icon-globe', tooltip: 'leads.scoreVisitsTooltip' },
				'default.scoreMail': { icon: 'Icon Icon-envelope', tooltip: 'leads.scoreMailTooltip' },
				'default.scoreForm': { icon: 'Icon Icon-form', tooltip: 'leads.scoreFormTooltip' },
				'default.scoreMarketingCustom': { icon: 'Icon Icon-bullseye', tooltip: 'leads.scoreMarketingCustom' },
				'default.countVisits': { icon: 'Icon Icon-globe', tooltip: 'leads.countVisitsTooltip' },
				'default.countMail': { icon: 'Icon Icon-envelope', tooltip: 'leads.countMailTooltip' },
				'default.countForm': { icon: 'Icon Icon-form', tooltip: 'leads.countFormTooltip' },
				'default.countMarketingCustom': { icon: 'Icon Icon-bullseye', tooltip: 'leads.countMarketingCustom' },
				'default.contactCount': { icon: 'Icon Icon-users', tooltip: 'leads.contactCountTooltip' }
			};
			return icons[name];
		};

		Instance.parseNested = function (str) {
			var re = /([A-z]{1,}[.][a-z]{1,})_(\d{0,}).(\w{1,})/;
			var m = re.exec(str);
			var parent, parentId, child;

			if (m !== null) {
				if (m.index === re.lastIndex) {
					re.lastIndex++;
				}
				parent = m[1];
				parentId = parseInt(m[2]);
				child = m[3];
				if (parent && parentId && child) {
					return {
						parent: parent,
						parentId: parentId,
						child: child
					};
				}
			}

			return null;
		};

		// e.g., adCampaign.id_30.impressions
		Instance.createNested = function (type, typeEntity, entityId, child) {
			return type + '.' + typeEntity + '_' + entityId + '.' + child;
		};

		Instance.getAddress = function (name) {
			var spl = name.split('_');
			var type = spl[1];
			var field = '';
			if (spl.length === 3) {
				field = spl[2] + '.';
			}
			return {
				title: 'address.' + field + type.toLowerCase(),
				key: name
			};
		};

		Instance.getCustomConfig = function (filterName, customFields) {
			// This needs to be moved to the listUdo component when we port it
			if (
				filterName &&
				!customFields &&
				filterName.indexOf('.') !== -1 &&
				filterName.indexOf('userDefinedObject') === -1
			) {
				const customType = filterName.split('.')[0];
				customFields = AppService.getCustomFields(customType);
			}

			return getCustomConfig(filterName, customFields);
		};

		Instance.getCustomFieldConfig = function (options) {
			if (!options) {
				options = {};
			}

			var config = {
				type: 'custom',
				inputType: 'custom',
				filterName: options.name,
				field: options.fieldId,
				parent: 'default.field.other'
			};

			if (options.entityType) {
				config.entity = options.entityType;
			}

			if (options.field) {
				var cf = options.field;

				if (config.entity === 'agreement') {
					if (cf.nameType === 'Agreement') {
						config.field = 'metadata.custom';
						config.fieldOverride = 'metadata.custom';
						config.comparisonType = 'Wildcard';
						config.filterName = 'CustomAgreement_' + cf.id;
						config.parent = 'order.periodAndBillingOther';
					} else {
						config.parent = 'order.orderDetailsOther';
					}
				}

				config.title = cf.name;
				config.$field = cf;

				switch (cf.datatype) {
					case 'String':
					case 'Text':
					case 'Link':
					case 'Email':
						config.displayType = 'text';
						break;
					case 'Boolean':
						config.displayType = 'radio';
						config.options = [
							{ text: 'default.all', inactive: true },
							{ text: 'default.with', value: true, comparisonType: 'Equals' },
							{ text: 'default.without', value: false, comparisonType: 'Equals' }
						];

						break;
					case 'Checkbox':
						config.displayType = 'radio';

						break;
					case 'Currency':
					case 'Percent':
					case 'Integer':
					case 'Discount':
					case 'Calculation':
						config.displayType = 'range';

						break;
					case 'Date': {
						config.presets = [
							'whenever',
							'currentDay',
							'lastDay',
							'currentWeek',
							'lastWeek',
							'currentMonth',
							'lastMonth',
							'currentQuarter',
							'lastQuarter',
							'currentYear',
							'lastYear',
							'last7days',
							'last14days',
							'last30days',
							'lastXdays',
							'custom'
						];

						const brokenFiscalYearEnabled =
							AppService.getMetadata().params.brokenFiscalYearEnabled &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR);

						if (brokenFiscalYearEnabled) {
							insertAfter(config.presets, 'currentQuarter', 'currentFiscalQuarter');
							insertAfter(config.presets, 'lastQuarter', 'lastFiscalQuarter');
							insertAfter(config.presets, 'currentYear', 'currentFiscalYear');
							insertAfter(config.presets, 'lastYear', 'lastFiscalYear');
						}

						config.displayType = 'dateRange';

						break;
					}
					case 'Time':
						config.displayType = 'time';

						break;
					case 'Select':
						config.displayType = 'listLazy';

						config.resource = function () {
							return function (customerId, selectedValues) {
								var items = selectedValues.map(function (selectedValue) {
									const name =
										cf?.default?.find(cfValue => cfValue.toLowerCase() === selectedValue) ||
										selectedValue;
									return { id: selectedValue, name };
								});

								return Tools.$q.when({ data: items });
							};
						};

						config.searchFn = function () {
							var myData;

							/* Should this not filter on term before slicing to? */
							return function (term, fields, offset, limit) {
								if (myData) {
									var filtered = myData;
									if (term) {
										filtered = _.filter(myData, function (item) {
											return (
												item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
											);
										});
									}
									return Tools.$q.when({
										data: filtered.slice(offset, offset + limit),
										metadata: {
											total: filtered.length,
											offset: offset,
											limit: limit
										}
									});
								}

								return Tools.Lookup.customer(Tools.AppService.getCustomerId())
									.setType(options.entityType)
									.findCustomValues(cf.id, term, 10000, config.fieldOverride)
									.then(function (res) {
										var items = _.map(res.data, function (val) {
											const name =
												cf?.default?.find(cfValue => cfValue.toLowerCase() === val.value) ||
												val.value;
											return { id: val.value, name };
										});
										if (!myData) {
											myData = items;
										}
										return {
											data: items.slice(offset, offset + limit),
											metadata: {
												total: items.length,
												offset: offset,
												limit: limit
											}
										};
									});
							};
						};

						break;
					case 'User': {
						const activeUsers = Tools.AppService.getActiveUsers();

						config.displayType = 'listLazy';

						config.resource = function () {
							return function (customerId, selectedValues) {
								var items = selectedValues.map(function (selectedValue) {
									if (selectedValue === 'self') {
										return {
											id: 'self',
											name: Tools.AppService.getSelf().name
										};
									}

									return {
										id: selectedValue,
										name: activeUsers.find(function (user) {
											return user.id === Number(selectedValue);
										}).name
									};
								});

								return Tools.$q.when({ data: items });
							};
						};

						config.searchFn = function () {
							var myData;

							/* Should this not filter on term before slicing to? */
							return function (term, fields, offset, limit) {
								if (myData) {
									var filtered = myData;
									if (term) {
										filtered = _.filter(myData, function (item) {
											return (
												item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
											);
										});
									}
									return Tools.$q.when({
										data: filtered.slice(offset, offset + limit),
										metadata: {
											total: filtered.length,
											offset: offset,
											limit: limit
										}
									});
								}

								return Tools.Lookup.customer(Tools.AppService.getCustomerId())
									.setType(options.entityType)
									.findCustomValues(cf.id, term, 10000, config.fieldOverride)
									.then(function (res) {
										const items = [];
										_.forEach(res.data, function (fieldUser) {
											const activeUser = activeUsers.find(function (user) {
												return user.id === Number(fieldUser.value);
											});
											if (activeUser) {
												items.push({
													id: fieldUser.value,
													name: activeUser.name
												});
											}
										});

										items.unshift(getLoggedInUserFilter());

										if (!myData) {
											myData = items;
										}
										return {
											data: items.slice(offset, offset + limit),
											metadata: {
												total: items.length,
												offset: offset,
												limit: limit
											}
										};
									});
							};
						};

						break;
					}
					case 'Users': {
						const activeUsers = Tools.AppService.getActiveUsers();

						config.displayType = 'listLazy';

						config.resource = function () {
							return function (customerId, selectedValues) {
								var items = selectedValues.map(function (selectedValue) {
									return {
										id: selectedValue,
										name: activeUsers.find(function (user) {
											return user.id === Number(selectedValue);
										}).name
									};
								});

								return Tools.$q.when({ data: items });
							};
						};

						config.searchFn = function () {
							var myData;

							/* Should this not filter on term before slicing to? */
							return function (term, fields, offset, limit) {
								if (myData) {
									var filtered = myData;
									if (term) {
										filtered = _.filter(myData, function (item) {
											return (
												item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
											);
										});
									}
									return Tools.$q.when({
										data: filtered.slice(offset, offset + limit),
										metadata: {
											total: filtered.length,
											offset: offset,
											limit: limit
										}
									});
								}

								return Tools.Lookup.customer(Tools.AppService.getCustomerId())
									.setType(options.entityType)
									.findCustomValues(cf.id, term, 10000, config.fieldOverride)
									.then(function (res) {
										var items = [];

										_.forEach(res.data, function (val) {
											var valueArray = val.value.split(',');
											_.forEach(valueArray, function (v) {
												const user = activeUsers.find(function (user) {
													return user.id === Number(v);
												});
												if (user) {
													items.push({
														id: v,
														name: user.name
													});
												}
											});
										});

										items = _.uniq(items, 'id');

										if (!myData) {
											myData = items;
										}
										return {
											data: items.slice(offset, offset + limit),
											metadata: {
												total: items.length,
												offset: offset,
												limit: limit
											}
										};
									});
							};
						};

						break;
					}
					default:
						if (cf.datatype) {
							config.type = cf.datatype.toLowerCase();
						}

						break;
				}
			}

			return config;
		};

		Instance.getConfigsFromCustomFields = function (entityType, customFields) {
			return _.reduce(
				customFields,
				function (res, cf) {
					const filterName = (entityType ? entityType + '.Custom_' : 'Custom_') + cf.id;
					const config = Instance.getCustomFieldConfig({
						entityType: entityType,
						name: filterName,
						field: cf,
						fieldId: cf.id
					});
					res[config.filterName] = config;
					return res;
				},
				{}
			);
		};

		Instance.mergeSegmentFilters = mergeSegmentFilters;

		Instance.ComparisonType = ComparisonType;

		return Instance;
	}
]);

import React from 'react';
import { Table, TableColumn, TableRow, TableHeader, Button, Icon } from '@upsales/components';
import PropTypes from 'prop-types';

import FontLocationModal from './FontLocationModal';
import GoogleFontsModal from './GoogleFontsModal';

import './CustomFonts.scss';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

class CustomFonts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showFontLocationModal: false,
			showGoogleFontsModal: false,
			fonts: props.fonts || []
		};
	}

	handleFontSelect = newFont => {
		const fonts = [...this.state.fonts, newFont].sort((a, b) => (a.family < b.family ? -1 : 1));

		this.setState({
			fonts,
			showGoogleFontsModal: false
		});

		this.props.onChange(fonts);
	};

	removeFont(font) {
		const { fonts } = this.state;

		fonts.splice(fonts.indexOf(font), 1);

		this.setState({ fonts });
		this.props.onChange(fonts);
		this.props.onRemove(font);
	}

	handleFontLocationSelect = () => {
		this.setState({
			showFontLocationModal: false,
			showGoogleFontsModal: true
		});
	};

	handleModalClose = () => {
		this.setState({
			showFontLocationModal: false,
			showGoogleFontsModal: false
		});
	};

	render() {
		const t = Tools.$translate;
		const { showFontLocationModal, showGoogleFontsModal, fonts } = this.state;

		return (
			<div className="CustomFonts" id="custom-fonts-tab-content">
				<div className="admin-section admin-card CustomFonts">
					{showGoogleFontsModal && (
						<GoogleFontsModal
							exclude={fonts}
							resolve={this.handleFontSelect}
							reject={this.handleModalClose}
						/>
					)}
					<FontLocationModal
						show={showFontLocationModal}
						resolve={this.handleFontLocationSelect}
						reject={this.handleModalClose}
					/>

					<div className="row CustomFonts__header">
						<div className="col-xs-9">
							<h2>{t('admin.accountProfile.customFonts')}</h2>
							<p className="text-grey">{t('admin.accountProfile.customFontsRestrictions')}</p>
						</div>
						<div className="col-xs-3 text-right">
							<Button size="sm" onClick={() => this.setState({ showGoogleFontsModal: true })}>
								{t('admin.accountProfile.addFont')}
							</Button>
						</div>
					</div>

					{!!fonts.length && (
						<Table>
							<TableHeader columns={[{ title: 'Font name' }, { title: 'Hosted by' }]} />
							{fonts.map(font => (
								<TableRow key={font.family}>
									<TableColumn title={font.family} />
									<TableColumn
										title={
											font.lastModified
												? t('admin.accountProfile.googleFonts')
												: t('admin.accountProfile.selfHostedFonts')
										}
									/>
									<TableColumn>
										<div className="text-right">
											<InlineConfirm
												show
												tooltip={Tools.$translate('default.delete')}
												tooltipDistance={30}
												onConfirm={() => Promise.resolve(this.removeFont(font))}
												entity="Font"
											>
												<Button type="link" color="grey">
													<Icon name="trash" />
												</Button>
											</InlineConfirm>
										</div>
									</TableColumn>
								</TableRow>
							))}
						</Table>
					)}
				</div>
			</div>
		);
	}
}

CustomFonts.propTypes = {
	fonts: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

window.AdminAccountProfileCustomFonts = CustomFonts;
export default CustomFonts;

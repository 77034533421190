'use strict';

angular.module('upResources').service('ParseAdCampaign', function () {
	return function (adCampaign) {
		adCampaign.startDate = window.Tools.parseDate(adCampaign.startDate && adCampaign.startDate.substring(0, 10));
		adCampaign.endDate = window.Tools.parseDate(adCampaign.endDate && adCampaign.endDate.substring(0, 10));

		return adCampaign;
	};
});

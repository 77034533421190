'use strict';

ReactTemplates.upFilters.filterTypes.datePreset = window.ReactCreateClass({
	clearable: true,
	setDefaultValue: function () {
		if (
			this.props.filter.defaultValue &&
			this.props.filter.presets.indexOf(this.props.filter.defaultValue) !== -1
		) {
			this.defaultValue = this.props.filter.defaultValue;
		} else if (this.props.filter.presets.indexOf('whenever') !== -1) {
			this.defaultValue = 'whenever';
		} else {
			this.defaultValue = this.props.filter.presets[0];
		}
	},
	componentDidMount: function () {
		this.setDefaultValue();
	},
	componentDidUpdate: function () {
		this.setDefaultValue();
	},
	setClearable: function (presets) {
		this.clearable = presets.indexOf('whenever') !== -1;
	},
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onInputChange: function (event) {
		var oldOption = this.findSelectedOption();
		var selectedPreset = this.props.filter.presets[event.target.value];

		if (oldOption !== selectedPreset) {
			this.props.onChange(
				{
					filterName: this.props.filterName,
					inactive: false,
					value: { preset: selectedPreset },
					type: 'datePreset'
				},
				{
					action: 'add'
				}
			);
		}
	},
	onRemoveFilter: function (event) {
		var self = this;
		this.setState({
			expanded: false
		});

		this.onInputChange({ target: { value: self.defaultValue } });

		ReactTemplates.TOOLS.stopProp(event);
	},
	findSelectedOption: function () {
		var filter = this.props.filter;

		if (!filter.value) {
			return null;
		}

		var selectedOption = _.find(filter.presets, function (option) {
			return option === filter.value.preset;
		});

		if (selectedOption === undefined) {
			return filter.presets[0];
		}
		return selectedOption;
	},
	formatValueString: function () {
		var valueString = '';

		var selectedOption = this.findSelectedOption();

		if (selectedOption) {
			valueString = Tools.$translate('date.' + selectedOption);
		}

		return valueString;
	},
	render: function () {
		var filter = this.props.filter;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		var selectedOption = this.findSelectedOption();
		var active = selectedOption && selectedOption.inactive === true ? false : true;
		this.setClearable(filter.presets);

		var options = _.map(
			filter.presets,
			function (option, index) {
				var text = Tools.$translate('date.' + option);
				var classes = 'drilldown-select-row';

				if (option === selectedOption) {
					classes = 'drilldown-select-row selected-row';
				}

				return (
					<button key={'option-' + index} value={index} className={classes} onClick={this.onInputChange}>
						{text}
					</button>
				);
			}.bind(this)
		);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && this.clearable ? (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					) : null}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'btn-group btn-block drilldown date-preset-drilldown'}>{options}</div>
				)}
			</div>
		);
	}
});

import ListViewActions from 'App/components/ListViewActions';

ReactTemplates.mailCampaign = {};
ReactTemplates.mailCampaign.root = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			overview: t('mailCampaign.overview'),
			recipients: t('groupMail.recipients'),
			visits: t('default.siteVisits'),
			copy: t('default.copy'),
			archive: t('default.archive'),
			unArchive: t('default.unArchive'),
			formSubmits: t('defaultView.formSubmit')
		};
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	getHrefFromState: function (stateName, params) {
		return this.props.tools.$state.href(stateName, params || {});
	},

	getTabClass: function (state, params) {
		if (this.props.rootData.isSelectedState(state, params)) {
			return 'current';
		}
		return null;
	},

	renderTab: function (text, state, meta) {
		var numberFormat = this.props.tools.$filter('numberFormat');

		return (
			<li>
				<a key={state} className={this.getTabClass(state)} href={this.getHrefFromState(state)}>
					{text}
					{' (' + numberFormat(meta) + ')'}
				</a>
			</li>
		);
	},

	onCopy: function (e) {
		e.preventDefault();

		this.props.rootData.onCopy();
	},

	onArchive: function (e) {
		e.preventDefault();

		this.props.rootData.onArchive();
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;

		if (!self.props.pageComponent) {
			return null;
		}

		var sidebar = React.createElement(ReactTemplates.mailCampaign.sidebar, {
			rootData: rootData,
			tools: tools
		});

		var pageComponent = React.createElement(ReactTemplates.mailCampaign[self.props.pageComponent], {
			rootData: rootData,
			staticData: this.props.staticData,
			tools: tools
		});

		var multiSelect = null;

		if (rootData.hasMultiSelect) {
			multiSelect = React.createElement(ListViewActions, {
				actions: rootData.listActionData.actions,
				runAction: rootData.listActionData.runAction,
				selectNone: rootData.listActionData.selectNone,
				selected: rootData.listActionData.selected,
				allSelected: rootData.listActionData.allSelected
			});
		}

		return (
			<div>
				{sidebar}
				<div className="card-header">
					<ul className="nav">
						{self.renderTab(self.lang.overview, 'mailCampaign.dashboard', rootData.events)}
						{self.renderTab(self.lang.recipients, 'mailCampaign.recipients', rootData.recipients)}
						{self.renderTab(self.lang.visits, 'mailCampaign.visits', rootData.visits)}
						{self.renderTab(self.lang.formSubmits, 'mailCampaign.submits', rootData.formSubmits)}

						<li className="tool-link">
							<a onClick={self.onArchive}>
								{ReactTemplates.TOOLS.withTooltip(
									<b className="fa fa-archive"></b>,
									rootData.mailCampaign.isArchived ? self.lang.unArchive : self.lang.archive,
									{ placement: 'bottom', key: 'archive', offset: '0, 10' }
								)}
							</a>
						</li>
						{rootData.mailCampaign.socialEventId ? null : (
							<li className="tool-link">
								<a onClick={self.onCopy}>
									{ReactTemplates.TOOLS.withTooltip(<b className="fa fa-copy"></b>, self.lang.copy, {
										placement: 'bottom',
										key: 'copy',
										offset: '0, 10'
									})}
								</a>
							</li>
						)}
					</ul>
				</div>

				<div id="card-main-view" style={multiSelect ? { paddingBottom: '70px' } : null}>
					{pageComponent}
				</div>

				{multiSelect}
			</div>
		);
	}
});

import Resource from './Resource';

class FlowStepContacts extends Resource {
	constructor(flowId, stepId, type) {
		super(`flows/${flowId}/${stepId}/contacts/${type}`);
		this._flowId = flowId;
		this._stepId = stepId;
		this._type = type;
	}

	flow(flowId) {
		this._flowId = flowId;
		return new FlowStepContacts(this._flowId, this._stepId, this._type);
	}

	step(stepId) {
		this._stepId = stepId;
		return new FlowStepContacts(this._flowId, this._stepId, this._type);
	}

	type(type) {
		this._type = type;
		return new FlowStepContacts(this._flowId, this._stepId, this._type);
	}

	_getUrl(path) {
		if (!this._flowId) {
			throw new Error('missing flowId run .flow() before your method');
		} else if (!this._stepId) {
			throw new Error('missing stepId run .step() before your method');
		}
		return super._getUrl(path);
	}
}

const resource = new FlowStepContacts();

window.Tools.FlowStepContacts = resource;

export default resource;

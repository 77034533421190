'use strict';

angular.module('upAttributes').service('EventAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				clientId: {
					title: 'default.account',
					field: 'client.id',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				date: {
					title: 'default.date',
					field: 'date',
					selectableColumn: true,
					sortable: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date
				},
				score: {
					title: 'default.score',
					field: 'score',
					sortable: 'score',
					selectableColumn: true,
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				entityType: {
					title: 'default.type',
					field: 'entityType',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				subType: {
					title: 'event.subType',
					field: 'subType',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				entityId: {
					title: 'event.entityId',
					field: 'entityId',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				opportunityId: {
					title: 'event.opportunityId',
					field: 'opportunityId',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				opportunity: {
					title: 'event.opportunity',
					field: 'opportunity',
					type: FilterType.Number,
					displayType: DisplayType.String,
					attr: {
						id: {
							field: 'opportunity.id',
							type: FilterType.Number
						}
					}
				},
				groupMailId: {
					title: 'default.groupMailId',
					field: 'mail.groupMailId',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				client: {
					title: 'default.account',
					field: 'client',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					link: 'accounts',
					//sortable: 'client.name',
					selectableColumn: true,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				users: {
					title: 'default.user',
					field: 'users',
					displayAttr: 'name',
					displayType: DisplayType.String,
					selectableColumn: true,
					parent: 'users',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					}
				},
				contacts: {
					title: 'default.contacts',
					field: 'contacts',
					displayAttr: 'name',
					displayType: DisplayType.String,
					selectableColumn: true,
					parent: 'contacts',
					attr: {
						id: {
							field: 'contacts.id',
							type: FilterType.Number
						},
						name: {
							field: 'contacts.name',
							type: FilterType.String
						}
					}
				},
				mail: {
					title: 'default.mail',
					field: 'mail',
					displayAttr: 'mail',
					displayType: DisplayType.String,
					parent: 'mail',
					attr: {
						id: {
							field: 'mail.id',
							type: FilterType.Number
						},
						groupMailId: {
							field: 'mail.groupMailId',
							type: FilterType.Number
						}
					}
				},
				feed: {
					title: 'feed',
					field: 'feed',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					sortable: false
					// inputType: 'text',
				},
				feedContact: {
					title: 'feedContact',
					field: 'feedContact',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					sortable: false
					// inputType: 'text',
				},
				feedContact2: {
					title: 'feedContact2',
					field: 'feedContact2',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					sortable: false
				},
				value: {
					title: 'default.value',
					field: 'value',
					type: FilterType.String,
					displayType: DisplayType.String
				}
			};

			var standardFilters = {
				HasContact: {
					title: 'default.hasContact',
					field: 'contacts',
					inputType: 'boolean',
					type: 'notNull'
				},
				Score: {
					title: 'default.score',
					field: 'score',
					type: 'range',
					inputType: 'range'
				},
				Type: {
					title: 'default.type',
					field: 'entityType',
					type: 'list',
					multiple: true,
					dataPromise: function ($q, $translate) {
						return $q.when({
							data: [
								{ name: $translate.instant('event.types.Visit'), id: 'Visit' },
								{ name: $translate.instant('liveFeed.mailClick'), id: 'Mail' },
								{ name: $translate.instant('form.form'), id: 'Form' }
							]
						});
					},
					inputType: 'select',
					searchField: 'name',
					displayText: 'name'
				},
				User: {
					title: 'default.accountManagers',
					field: 'client.user.id',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta();
					},
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name'),
					columnPath: 'accountManager'
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				standardFilters: standardFilters,
				attr: attributes,
				keys: {
					CLIENTID: 'clientId',
					CONTACTID: 'contactId',
					DATE: 'date',
					SCORE: 'score'
				}
			};
		};
	}
]);

import { Icon, Button } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

ReactTemplates.ROWSTATICS.mailTemplateRow = function (tools) {
	var statics = {};
	statics.editTool = tools.$translate('default.edit');
	statics.deleteTool = tools.$translate('default.delete');
	statics.noAccountManager = tools.$translate('default.noAccountManager');
	statics.noCampaigns = tools.$translate('default.noCampaigns');
	statics.noCategories = tools.$translate('default.noCategories');
	statics.noName = tools.$translate('default.noName');
	statics.addExternal = tools.$translate('default.addExternal');
	statics.defaultSender = tools.$translate('mail.defaultSender');
	statics.signedInUser = tools.$translate('default.signedInUser');
	statics.accountManager = tools.$translate('default.accountManager');

	return statics;
};

ReactTemplates.ROW.mailTemplateRow = window.ReactCreateClass({
	displayName: 'mailTemplateRow',
	click: function () {
		this.props.tools.opts.clickedEntry(this.props.item);
	},
	edit: function () {
		this.props.tools.opts.editEntry(this.props.item);
	},
	saveExternal: function (item) {
		this.props.tools.opts.saveExternal(item || this.props.item);
	},
	refreshSoliditet: function (e) {
		if (e) {
			ReactTemplates.TOOLS.stopProp(e);
		}
		this.props.tools.opts.refreshSoliditet(this.props.item);
	},
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var statics = this.props.statics;
		var attrs = this.props.attrs;
		var columnTools = this.props.tools.opts.columnTools || {};

		var getColumn = function (column, item) {
			var key = 'column-' + column;

			switch (column) {
				case 'user':
					return <td key={key}>{item.user ? item.user.name : ''}</td>;

				case 'subject':
					return (
						<td key={key} className="column-subject overflow-controller">
							{item.subject}
						</td>
					);

				case 'usedCounter':
					return <td key={key}>{item.usedCounter ? item.usedCounter : 0}</td>;

				case 'from':
					switch (item.from) {
						case '{{General.CompanyEmail}}':
							return <td key={key}>{statics.defaultSender}</td>;

						case '{{General.CurrentUserEmail}}':
							return <td key={key}>{statics.signedInUser}</td>;

						case '{{Client.UserEmail}}':
							return <td key={key}>{statics.accountManager}</td>;

						default:
							return <td key={key}>{item.from}</td>;
					}
				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		if (!columnTools.extraColumn1) {
			columnTools.extraColumn1 = {
				show: function () {
					return false;
				}
			};
		}
		// TODO Only show if userRemovable, show tooltip
		return (
			<tr key={'item-' + item.id} onClick={this.click} ref="row">
				{tds}
				<td className="column-tools">
					<InlineConfirm
						show={!item.isExternal && item.userRemovable && columnTools.remove !== false}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.template'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from '@upsales/components/Toggle';
import Text from '@upsales/components/Text';

import BemClass from '@upsales/components/Utils/bemClass';
import { getTextColor } from '../../helpers/color';

import './ShareContentType.scss';

const getMaxOccurences = suitableBgColor => {
	const distribution = {};
	let max = 0;
	let results = [];

	suitableBgColor.forEach(function (a) {
		distribution[a] = (distribution[a] || 0) + 1;
		if (distribution[a] > max) {
			max = distribution[a];
			results = [a];
			return;
		}
		if (distribution[a] === max) {
			results.push(a);
		}
	});

	return results;
};

const getInitialBgColor = element => {
	var hexCodes = element.value.text.match(/#([a-fA-F0-9]{3}){1,2}\b/gi);
	var suitableBgColor = _.map(hexCodes, hex => getTextColor(hex));

	var results = getMaxOccurences(suitableBgColor);

	return results[0];
};

class ShareContentType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			element: this.props.element,
			bgColor: getInitialBgColor(props.element)
		};

		this.onChangeText = this.onChangeText.bind(this);
		this.changeBackground = this.changeBackground.bind(this);
	}

	getTextElement(color) {
		const { ckPosition, element } = this.state;
		return `<div id="tmp" style="background: ${color};width: 100%; height: 100%;${ckPosition}">${element.value.text}</div>`;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ element: nextProps.element });
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (nextProps.element.id !== this.props.element.id) {
			this.mountEditor(nextProps.element.value.text);
		}
	}

	componentWillUnmount() {
		this.editor.destroy();
	}

	mountEditor(initialData) {
		if (this.editor) {
			this.editor.destroy();
		}

		this.editor = CKEDITOR.replace(this._textarea, this.editorOptions);

		if (initialData) {
			this.editor.setData(initialData);
		}

		this.editor.on('change', this.onChangeText);
		this.editor.on('keyup', this.onChangeText);
		this.editor.on('blur', this.onChangeText);

		this.editor.on('instanceReady', editor =>
			this.editorOptions.init ? this.editorOptions.init(editor) : this.setEditorBackground.bind(this, editor)
		);
	}

	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		var self = this;
		this.lang = {
			iconAlignment: t('landingpage.iconAlignment'),
			iconSize: t('landingpage.iconSize'),
			darkMode: t('form.editor.darkMode'),
			linkedin: t('leadSource.linkedin'),
			facebook: t('leadSource.facebook'),
			twitter: t('leadSource.twitter'),
			columnText: t('landingpage.columnText'),
			selectPlatforms: t('landingpage.selectPlatforms'),
			videoAddress: t('landingpage.videoAddress')
		};

		this.alignmentOpts = ['left', 'center', 'right'].map((value, i) => (
			<option key={'size' + i} value={value}>
				{t('landingpage.align.' + value)}
			</option>
		));

		this.alignmentSize = [
			<option key={'size-sm'} value="24px">
				{t('landingpage.size.sm')}
			</option>,
			<option key={'size-md'} value="34px">
				{t('landingpage.size.md')}
			</option>,
			<option key={'size-lg'} value="44px">
				{t('landingpage.size.lg')}
			</option>,
			<option key={'size-xl'} value="54px">
				{t('landingpage.size.xl')}
			</option>
		];

		// CKeditor options
		this.editorOptions = Tools.EditorCk.getOptions({
			height: '150px',
			hideImage: true,
			hideTags: !this.props.strictTagList,
			strictTagList: this.props.strictTagList
		});
		this.editorOptions.toolbar = [
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', 'Align', 'TextColor', 'RemoveFormat', 'Format', 'FontSize']
			},
			{ name: 'styles', items: ['Font'] },
			{
				name: 'paragraph',
				items: ['-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Link', 'Unlink']
			}
		];

		// Colors
		var colorsArr = _.map(_.values(this.props.accountProfile.colors), function (color) {
			return color.substring(1);
		});
		var extraColors = _.map(_.values(this.props.accountProfile.extraColors), function (color) {
			return color.value.substring(1);
		});
		this.editorOptions.colorButton_colors = colorsArr.concat(extraColors).join(',');
		this.editorOptions.colorButton_enableAutomatic = false;
		this.editorOptions.forcePasteAsPlainText = true;

		// Setup format tags
		// For inline style definition.
		this.editorOptions.format_tags =
			'paragraph;ingress;maintitle;maintitlelarge;secondarytitle;secondarytitlelarge';
		this.editorOptions.format_paragraph = { name: 'Paragraph', element: 'p', attributes: { class: 'paragraph' } };
		this.editorOptions.format_ingress = { name: 'Ingress', element: 'p', attributes: { class: 'ingress' } };
		this.editorOptions.format_maintitle = { name: 'Main title', element: 'h2' };
		this.editorOptions.format_maintitlelarge = {
			name: 'Main title large',
			element: 'h2',
			attributes: { class: 'large' }
		};
		this.editorOptions.format_secondarytitle = { name: 'Title', element: 'h3' };
		this.editorOptions.format_secondarytitlelarge = {
			name: 'Title large',
			element: 'h3',
			attributes: { class: 'large' }
		};

		// Remove ck-footer
		this.editorOptions.removePlugins = 'elementspath,contextmenu,scayt,tabletools,liststyle';

		this.editorOptions.font_names = CKEDITOR.config.font_names;

		const { fonts } = this.props.accountProfile;
		if (fonts && fonts.length) {
			var googleFonts = [];

			fonts.forEach(function (font) {
				googleFonts.push(font.family);
				self.editorOptions.font_names = font.family + '/' + font.family + ';' + self.editorOptions.font_names;
			});

			self.editorOptions.contentsCss = googleFonts.map(function (font) {
				return 'https://fonts.googleapis.com/css?display=swap&family=' + encodeURI(font);
			});

			window.WebFont.load({
				google: {
					families: googleFonts
				}
			});
		}

		// Set enter-key behaviour
		this.editorOptions.enterMode = CKEDITOR.ENTER_BR;
		this.editorOptions.shiftEnterMode = CKEDITOR.ENTER_BR;
		this.editorOptions.fontSize_sizes =
			'8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;26/26px;27/27px;28/28px;29/29px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;42/42px;44/44px;46/46px;48/48px;50/50px;52/52px;54/54px;56/56px;58/58px;60/60px;';
	}

	componentDidMount() {
		this.mountEditor();
	}

	onChangeText(e) {
		var element = this.state.element;
		element.value.text = this.editor.getData();

		var tmpDiv = document.createElement('div');
		tmpDiv.innerHTML = element.value.text;
		var backGroundDiv = tmpDiv.querySelector('#tmp');

		element.value.text = backGroundDiv ? backGroundDiv.innerHTML : tmpDiv.innerHTML;

		this.setState({ element });
		this.props.onChange(element);
		if (Tools.browserService.isFirefox) {
			this.setEditorBackground(e);
		}
	}

	onPlatformChange(platform, value) {
		const element = this.state.element;
		element.value = { ...element.value, [platform]: value };

		this.setState({ element });
		this.props.onChange(element);
	}

	onAlignmentChange(e) {
		const element = this.state.element;
		element.value.align = e.target.value;
		this.setState({ element });
		this.props.onChange(element);
	}

	onSizeChange(e) {
		const element = this.state.element;
		element.value.size = e.target.value;
		this.setState({ element });
		this.props.onChange(element);
	}

	setRef(name, ref) {
		this[name] = ref;
	}

	setEditorBackground(e) {
		var editorElements = document.getElementsByClassName(e.editor.id);
		if (editorElements.length > 0) {
			var iframe = editorElements[0].getElementsByTagName('iframe')[0].contentWindow;
			iframe.document.getElementsByTagName('body')[0].style.background = this.state.bgColor || '#FFFFFF';
		}
	}

	changeBackground() {
		let nextColor = '';
		if (['#222222', '#1E252E'].indexOf(this.state.bgColor) >= 0) {
			nextColor = '#FFFFFF';
		} else {
			nextColor = '#222222';
		}
		const nextText = this.getTextElement(nextColor);
		this.editor.editable().setHtml(nextText);
		this.setState({
			bgColor: nextColor
		});
	}

	renderSocialPlatformSection(platform) {
		const className = new BemClass('ShareContentType');
		const { color, active } = this.state.element.value[platform];
		return (
			<div className={className.elem('social-media-platform', 'tool-section').b()}>
				<Toggle
					color="bright-blue"
					size="lg"
					checked={active}
					onChange={active => this.onPlatformChange(platform, { active, color })}
				/>
				<Text>{this.lang[platform]}</Text>
				<div className={className.elem('color-select-wrapper')}>
					<ReactTemplates.bannerEditor.colorPicker
						value={color || '#ffffff'}
						isText={false}
						presets={this.props.colorVariables}
						addPreset={this.props.addPreset}
						onChange={color => this.onPlatformChange(platform, { active, color })}
					/>
				</div>
			</div>
		);
	}

	render() {
		const className = new BemClass('ShareContentType');
		const { bgColor } = this.state;
		const textValue = this.getTextElement(bgColor);
		return (
			<div className={className.b()}>
				<div className="tool-header">
					<h3>{this.lang.columnText}</h3>
				</div>
				<div className="tool-main-section no-padding">
					<div className="tool-section" key="column-text">
						<textarea ref={r => this.setRef('_textarea', r)}>{textValue}</textarea>
						<div
							className={className.elem('content-text', 'tool-section').b()}
							id="change-background"
							style={{ backgroundColor: '#fafafa', padding: '10px 15px' }}
						>
							<Toggle
								color="medium-green"
								checked={bgColor === '#222222'}
								onChange={this.changeBackground}
							/>
							<label className="pull-right">{this.lang.darkMode}</label>
						</div>
					</div>
				</div>
				<div className="tool-header">
					<h3>{this.lang.selectPlatforms}</h3>
				</div>
				<div className="tool-main-section no-padding">
					{this.renderSocialPlatformSection('linkedin')}
					{this.renderSocialPlatformSection('facebook')}
					{this.renderSocialPlatformSection('twitter')}
					<div className={className.elem('icon-alignment', 'tool-section').b()}>
						<Text>{this.lang.iconAlignment}</Text>
						<select
							className="form-control inline-select input-sm pull-right"
							value={this.state.element.value.align}
							onChange={e => this.onAlignmentChange(e)}
						>
							{this.alignmentOpts}
						</select>
					</div>
					<div className={className.elem('icon-size', 'tool-section').b()}>
						<Text>{this.lang.iconSize}</Text>
						<select
							className="form-control inline-select input-sm pull-right"
							value={this.state.element.value.size}
							onChange={e => this.onSizeChange(e)}
						>
							{this.alignmentSize}
						</select>
					</div>
				</div>
			</div>
		);
	}
}

ShareContentType.propTypes = {
	onChange: PropTypes.func.isRequired,
	element: PropTypes.shape({
		id: PropTypes.number,
		value: PropTypes.shape({
			text: PropTypes.string.isRequired,
			align: PropTypes.string.isRequired,
			twitter: PropTypes.object.isRequired,
			facebook: PropTypes.object.isRequired,
			linkedin: PropTypes.object.isRequired
		})
	}).isRequired,
	accountProfile: PropTypes.object,
	strictTagList: PropTypes.array,
	colorVariables: PropTypes.array,
	addPreset: PropTypes.func
};

ShareContentType.defaultProps = {
	accountProfile: {}
};

window.ShareContentType = ShareContentType;
export default ShareContentType;

import React from 'react';
import PropTypes from 'prop-types';
import './OptOutLangSection.css';
import { Button, Input, Title, Progress } from '@upsales/components';

class OptOutLangSection extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			defaultLanguage: Tools.$translate('default.defaultLanguage'),
			title: Tools.$translate('default.title'),
			description: Tools.$translate('default.description')
		};

		this.onChange = (field, e) => {
			this.props.onChange({ [field]: e.target.value });
		};

		this.renderInputWrap = this.renderInputWrap.bind(this);
	}

	renderInputWrap(field, maxLength, invalid) {
		const value = this.props[field] || '';

		const percentage = (value.length / parseInt(maxLength)) * 100;
		let state = null;
		if (percentage === 100) {
			state = 'error';
		}
		return (
			<div className="OptOutLangSection__input-wrap" key={field}>
				<div className="OptOutLangSection__input-title">
					{this.lang[field]}
					<Progress state={state} percentage={percentage} hideText className="OptOutLangSection__progress" />
				</div>
				<Input
					value={value}
					state={invalid ? 'error' : null}
					onChange={this.onChange.bind(this, field)}
					maxLength={maxLength}
				/>
			</div>
		);
	}

	render() {
		let removeBtn = null;
		let defaultIndicator = null;

		if (!this.props.default) {
			removeBtn = (
				<Button
					className="OptOutLangSection__input-remove"
					type="link"
					color="grey"
					onClick={this.props.onRemove}
				>
					<b className="fa fa-trash" />
				</Button>
			);
		} else {
			defaultIndicator = <div className="OptOutLangSection__default-lang">{this.lang.defaultLanguage}</div>;
		}
		return (
			<div className="OptOutLangSection">
				<Title className="OptOutLangSection__title">{this.props.languageName}</Title>
				{defaultIndicator}

				<div className="OptOutLangSection__input-row">
					{this.renderInputWrap('title', '40', this.props.invalidTitle)}

					{this.renderInputWrap('description', '50', this.props.invalidDescription)}

					{removeBtn}
				</div>
			</div>
		);
	}
}

OptOutLangSection.defaultProps = {
	onChange: () => {},
	onRemove: () => {},
	title: '',
	description: ''
};

OptOutLangSection.propTypes = {
	languageName: PropTypes.string.isRequired,
	default: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	invalidTitle: PropTypes.bool,
	invalidDescription: PropTypes.bool
};

window.OptOutLangSection = OptOutLangSection;

export default OptOutLangSection;

import InAppChat from 'App/babel/services/InAppChat';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { openDrawer } from 'Services/Drawer';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import SupportToken from 'App/babel/resources/SupportToken';
import { SidebarSavedModalButton } from 'App/components/SidebarSavedModalButton';

angular.module('upsalesApp').controller('SidebarCtrl', [
	'$scope',
	'$state',
	'$upModal',
	'AppService',
	'Links',
	'FeatureHelper',
	'$safeApply',
	function ($scope, $state, $upModal, AppService, Links, FeatureHelper, $safeApply) {
		var SidebarCtrl = this;
		var customerId;

		SidebarCtrl.loaded = false;

		SidebarCtrl.selectedDate = new Date();
		SidebarCtrl.SavedModalButton = SidebarSavedModalButton;

		SidebarCtrl.gotoCalendar = function (e) {
			var tar = angular.element(e.target);
			var name = e.target.nodeName.toLowerCase();

			e.preventDefault();
			e.stopPropagation();

			if (
				tar.hasClass('glyphicon') ||
				name === 'th' ||
				name === 'strong' ||
				(tar.attr('ng-click') &&
					(tar.attr('ng-click').indexOf('move') !== -1 || tar.attr('ng-click').indexOf('toggleMode') !== -1))
			) {
				return;
			}

			// if a month is clicked, we cant know across all languages which one so have to return here
			if (name === 'span' && isNaN(tar.text())) {
				return;
			}

			var view = 'day';

			// If a week is clicked
			if (name === 'em') {
				view = 'workWeek';
			}

			// Go to calendar
			$state.go('react-root-calendar', {
				date: SidebarCtrl.selectedDate.toJSON(),
				selected: view
			});

			// Reset sidebar cal to today
			SidebarCtrl.selectedDate = new Date();
		};

		SidebarCtrl.add = function (type, e) {
			var options = { customerId: customerId };
			globalTracker.track('add', { type: type, location: 'sidebar' });

			switch (type) {
				case 'account':
					if (shouldOpenModal('CreateAccount')) {
						openModal('CreateAccount');
					} else {
						$upModal.open('createAccount', options, e);
					}
					break;

				case 'contact':
					$upModal.open('editContact', options, e);
					break;

				case 'campaign':
					$upModal.open('editCampaign', options, e);
					break;

				case 'mail':
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
						openDrawer('NewSingleMail');
					} else {
						options.type = 'mail';
						$upModal.open('sendEmail', options, e);
					}
					break;

				case 'activity':
					$upModal.open('editActivity', {}, e);
					break;

				case 'todo':
					openDrawer('CreateTodo');
					break;

				case 'phonecall':
					openDrawer('CreateCall');
					break;

				case 'appointment':
					$upModal.open('editAppointment', {}, e);
					break;

				case 'opportunity':
					options.type = 'opportunity';
					$upModal.open('editOrder', options, e);
					break;

				case 'order':
					options.type = 'order';
					$upModal.open('editOrder', options, e);
					break;

				case 'agreement':
					if (Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
						const dontWait = Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');
						openModal('CreateSubscription', { createdFrom: 'sidebar', dontWait });
					} else {
						$upModal.open('editAgreement', options, e);
					}
					break;

				case 'mailCampaign':
					$upModal.open('createGroupMail');
					break;

				case 'findProspects':
					if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC)) {
						$state.go('react-root-prospecting');
						break;
					}
					$state.go('findProspects');
					break;

				case 'form':
					$upModal.open('createForm', { formType: 'form' });
					break;

				case 'mailTemplate':
					$upModal.open('createMailTemplate');
					break;

				case 'engage':
					$state.go('editAd');
					break;

				case 'landingpage':
					$upModal.open('createForm', { formType: 'landing' });
					break;

				case 'utm':
					openDrawer('UtmLinkGenerator');
					break;

				case 'support':
					openDrawer('EditTicket');
					break;
			}
		};

		SidebarCtrl.openHelp = function () {
			if (SidebarCtrl.hasHelp) {
				window._elev.openModule('articles');
			}
		};

		SidebarCtrl.openChat = function () {
			if (SidebarCtrl.hasChat && !InAppChat.isOpened()) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('CHAT_SESSION')) {
					SupportToken.hasSession()
						.then(data => {
							if (!data) {
								openModal('AllowAccess', {
									onClose: InAppChat.open
								});
							} else {
								InAppChat.open();
							}
						})
						.catch(() => {
							InAppChat.open();
						});
				} else {
					InAppChat.open();
				}
			}
		};

		var getLinks = function (customerId, user) {
			SidebarCtrl.links = [];
			SidebarCtrl.loadingLinks = true;
			Links.customer(customerId)
				.get('sidebar', user.id)
				.then(function (response) {
					SidebarCtrl.links = response.data;
					SidebarCtrl.loadingLinks = false;
				})
				.catch(function (err) {
					SidebarCtrl.loadingLinks = false;
					SidebarCtrl.linksErr = err;
				});
		};

		var init = function () {
			var metadata = AppService.getMetadata();
			var self = AppService.getSelf();

			customerId = AppService.getCustomerId();

			SidebarCtrl.loaded = true;

			SidebarCtrl.displayCalendar = FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
			SidebarCtrl.displayLatestUsedCompanies =
				FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS) &&
				!FeatureHelper.hasSoftDeployAccess('LATEST_CLIENTS_MOVE');

			if (metadata.integrations && metadata.integrations.uiElements) {
				SidebarCtrl.uiElements = metadata.integrations.uiElements.sidebar;
			}

			var showEmail =
				FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) &&
				(metadata.user.administrator ||
					metadata.map.mailActivated ||
					(metadata.integrations.inits.mail && metadata.integrations.inits.mail.length));
			var showGroupMail =
				FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL) &&
				(metadata.user.administrator || metadata.map.mailActivated);
			var showMailTemplate =
				FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL) &&
				(metadata.user.administrator || metadata.map.mailActivated);

			const hasTodoList =
				FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);

			const showSupportTicket =
				FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT) && self?.support;
			SidebarCtrl.addActions = [
				{
					title: 'default.account',
					type: 'account',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
					icon: 'building-o'
				},
				{
					title: 'default.contact',
					type: 'contact',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
					icon: 'user'
				},
				{
					title: 'default.activity',
					type: 'activity',
					show:
						FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
						!(
							Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						),
					icon: 'Icon Icon-activity Icon--margin-right-medium',
					replaceClass: true
				},
				{
					title: 'todo.createTodo',
					type: 'todo',
					show: hasTodoList,
					icon: 'Icon Icon-activity Icon--margin-right-medium',
					replaceClass: true
				},
				{
					title: 'todo.planACall',
					type: 'phonecall',
					show: hasTodoList,
					icon: 'Icon Icon-phone Icon--margin-right-medium',
					replaceClass: true
				},
				{
					title: 'todo.bookAppointment',
					type: 'appointment',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
					icon: 'calendar'
				},
				{
					title: 'default.opportunity',
					type: 'opportunity',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE),
					icon: 'up-icon-pipeline',
					replaceClass: true
				},
				{
					title: 'default.order',
					type: 'order',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS),
					icon: 'dollar'
				},
				{
					title: 'default.agreement',
					type: 'agreement',
					show:
						metadata.params.AgreementEnabled &&
						FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER) &&
						AppService.getAccessRights().Agreement,
					icon: 'up-icon-recurring-order',
					replaceClass: true
				},
				{ title: 'default.email', type: 'mail', show: showEmail, icon: 'envelope-o' },
				{
					title: 'default.mailinglist',
					type: 'mailCampaign',
					show: showGroupMail && (self.administrator || self.userParams.mailAdmin),
					icon: 'envelope'
				},
				{
					title: 'mail.template',
					type: 'mailTemplate',
					show: showMailTemplate,
					icon: 'up-icon-edit-mail',
					replaceClass: true
				},
				{
					title: 'default.campaign',
					type: 'campaign',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECTS),
					icon: 'bullhorn'
				},
				{
					title: 'default.findProspects',
					type: 'findProspects',
					show:
						(FeatureHelper.isAvailable(FeatureHelper.Feature.SOLIDITET_MULTI_MARKET) &&
							self.userParams.multiSoliditetAccess) ||
						metadata.user.administrator,
					icon: 'search'
				},
				{
					title: 'form.form',
					type: 'form',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS),
					icon: 'up-icon-form',
					replaceClass: true
				},
				{
					title: 'form.landingPage',
					type: 'landingpage',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS),
					icon: 'file-text'
				},
				{
					title: 'default.adCampaign',
					type: 'engage',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.ADS),
					icon: 'list-alt'
				},
				{
					title: 'utm.generator',
					type: 'utm',
					show: FeatureHelper.isAvailable(FeatureHelper.Feature.UTM_GENERATOR),
					icon: 'link'
				},
				{
					title: 'ticket.createTicket',
					type: 'support',
					show: showSupportTicket,
					icon: 'Icon Icon-customer-support Icon--margin-right-medium',
					replaceClass: true
				}
			];
			getLinks(customerId, self);

			$scope.$on('dynamiclink.updated', function () {
				getLinks(customerId, self);
			});

			$scope.$on('dynamiclink.added', function () {
				getLinks(customerId, self);
			});

			$scope.$on('dynamiclink.deleted', function () {
				getLinks(customerId, self);
			});

			SidebarCtrl.hasHelp = window._elev !== undefined;
			SidebarCtrl.hasChat = InAppChat.isEnabledAndLoaded();
			SidebarCtrl.chatUnread = 0;

			if (SidebarCtrl.hasChat) {
				InAppChat.on('unreadCount', function (unreadCount) {
					SidebarCtrl.chatUnread = unreadCount;
					$safeApply($scope);
				});
			}
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

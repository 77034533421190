'use strict';
ReactTemplates.form.formSelect = window.ReactCreateClass({
	getInitialState: function () {
		return {
			isOpen: false
		};
	},
	componentDidMount: function () {
		var self = this;
		var Form = Tools.Form;

		var opts = {
			resource: Form,
			ajax: true,
			asIds: true,
			onChange: function () {},
			idAttr: Form.attr.id,
			titleAttr: Form.attr.name,
			placeholder: self.props.placeholder,
			fields: self.props.fields,
			formatResult: self.formatFormRow,
			sorting: { field: Form.attr.name, ascending: true }
		};

		var input = jQuery(self._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
		input
			.on('select2-open', function () {
				self.setState({
					isOpen: true
				});
			})
			.on('select2-close', function () {
				var data = jQuery(self._input).select2('data');
				if (data) {
					self.props.onChange(data);
				}
				self.setState({
					isOpen: false
				});
				jQuery(self._input).select2('data', null);
			});
	},
	formatFormRow: function (form, container, query, escape) {
		var description = escape(form.description) || 'Ingen beskrivning';
		var leftStyle =
			'display: inline-block; width: 80%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;';
		var rightStyle = 'display: inline-block; width: 20%; top: 50%; transform: translateY(-50%); position:relative;';
		var html =
			'' +
			'<div style="' +
			leftStyle +
			'">' +
			'<span>' +
			escape(form.name) +
			'</span><br/>' +
			'<i>' +
			description +
			'</i>' +
			'</div>' +
			'<div style="' +
			rightStyle +
			'">' +
			'<span>' +
			form.fields.length +
			' fält</span>' +
			'</div>';

		return html;
	},
	render: function () {
		var self = this;
		var select2 = ReactTemplates.TOOLS.selectHelper.getInputComponent(self);
		var className = [this.props.containerClass];
		if (!self.state.isOpen) {
			className.push('form__select--closed');
		}
		return <div className={className.join(' ')}>{select2}</div>;
	}
});

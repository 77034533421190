'use strict';

angular.module('upDirectives').directive('cardNav', function () {
	return {
		restrict: 'A',
		link: function ($scope, navElem) {
			const OFFSET = 30;
			let navCopy;

			function cloneElem() {
				var tmp = navElem.clone().removeClass('nav-compact').addClass('clone').removeAttr('id');

				if (navCopy) {
					navCopy.replaceWith(tmp);
				} else {
					tmp.insertAfter(navElem);
				}
				navCopy = tmp;
			}

			if (window.ResizeObserver && window.MutationObserver) {
				const domElement = navElem[0];

				const calculateOverflow = () => {
					if (!navCopy) {
						cloneElem();
					}
					if (!navCopy) {
						return;
					}

					let tabWith = 0;
					navCopy.find('.card-nav').each(function (i, li) {
						tabWith += angular.element(li).outerWidth();
					});

					if (navCopy.innerWidth() <= tabWith + OFFSET) {
						navElem.addClass('nav-compact');
					} else {
						navElem.removeClass('nav-compact');
					}
				};

				// we only need to do a new clone when a tab is removed or added, i.e. a childList mutation happen
				const mutationObserver = new MutationObserver(
					_.debounce(() => {
						cloneElem();
						calculateOverflow();
					}, 250)
				);
				mutationObserver.observe(domElement, { childList: true });

				const resizeObserver = new ResizeObserver(() => {
					// We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
					window.requestAnimationFrame(() => {
						calculateOverflow();
					});
				});
				resizeObserver.observe(domElement);

				$scope.$on('$destroy', () => {
					resizeObserver.disconnect();
					mutationObserver.disconnect();
				});
			} else {
				const winElem = angular.element(window);
				let calculating;

				const getDimentions = function () {
					cloneElem();
					// Create copy for scale
					var tot = 0;
					navCopy.find('.card-nav').each(function (i, li) {
						tot += angular.element(li).outerWidth();
					});
					return {
						content: tot + OFFSET,
						nav: navCopy.innerWidth()
					};
				};

				const calculate = function (width) {
					if (calculating) {
						clearTimeout(calculating);
					}
					calculating = setTimeout(function () {
						// Set compact class to nav if content is too wide
						if (width.content >= width.nav) {
							navElem.addClass('nav-compact');
						} else {
							navElem.removeClass('nav-compact');
						}
					}, 50);
				};

				const onResize = function () {
					calculate(getDimentions());
				};

				//Listen for changes when the scope is digested
				$scope.$watch(getDimentions, calculate, true);

				// Listen for window resize
				winElem.on('resize', onResize);

				$scope.$on('$destroy', function () {
					winElem.off('resize', onResize);
				});
			}
		}
	};
});

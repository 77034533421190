'use strict';

const ColorPicker =
	(ReactTemplates.bannerEditor.colorPicker =
	window.ColorPicker =
		window.ReactCreateClass({
			getInitialState: function () {
				var value = this.props.value || '#333333';
				return {
					value: value
				};
			},
			UNSAFE_componentWillReceiveProps: function (nextProps) {
				if (JSON.stringify(this.props.presets) !== JSON.stringify(nextProps.presets)) {
					this.addColorPresets(nextProps.presets);
				}
				this.setState({ value: nextProps.value || '#333333' });
			},
			componentWillUnmount: function () {
				jQuery(this._input).off('changeColor', this.pickerChanged);
				jQuery(this._input).off('hidePicker', this.closePicker);
				this.Colorpicker.destroy();
			},
			componentDidMount: function () {
				var self = this;
				var slidersHeight = 130;
				var pickerOpts = {
					format: 'hex',
					sliders: {
						saturation: {
							maxLeft: slidersHeight,
							maxTop: slidersHeight,
							callLeft: 'setSaturation',
							callTop: 'setBrightness'
						},
						hue: {
							maxTop: slidersHeight,
							maxLeft: 0,
							callLeft: false,
							callTop: 'setHue'
						}
					}
				};

				if (this.props.selfContained) {
					pickerOpts.container = this._wrapper;
				}

				if (self.props.align) {
					pickerOpts.align = self.props.align;
				}

				var input = jQuery(this._input);
				input.colorpicker(pickerOpts);
				this.Colorpicker = input.data('colorpicker');
				input.on('changeColor', this.pickerChanged);
				input.on('hidePicker', this.closePicker);
				input.on('showPicker', () => {
					if (this.props.direction === 'up' && this.props.selfContained) {
						const picker = this.Colorpicker?.picker?.[0];
						if (picker) {
							picker.style.top = `-${picker.offsetHeight}px`;
						}
					}
				});

				self.addColorPresets();
			},
			addColorPresets: function (colorPresets) {
				var self = this;
				var presets = colorPresets || this.props.presets;
				var picker = this.Colorpicker.picker;
				picker.find('.colorpicker-selectors').html('');
				if (presets && presets.length) {
					angular.forEach(presets, function (group) {
						// Do not show group if empty
						if (!group.colors || !Object.keys(group.colors).length) {
							return;
						}

						if (group.title) {
							picker
								.find('.colorpicker-selectors')
								.append('<div class="colors-title">' + group.title + '</div>');
						}

						$.each(group.colors, function (name, color) {
							var $btn = $('<i />').css('background-color', color).data('class', name);
							$btn.click(function () {
								var input = jQuery(self._input);
								input.colorpicker('setValue', color);
							});
							picker.find('.colorpicker-selectors').append($btn);
						});
					});
				}
				picker.find('.colorpicker-selectors').show();
				if (self.props.addPreset) {
					self.saveBtn = $('<i />').addClass('fa fa-plus').css({
						'padding-top': '2px',
						'text-align': 'center'
					});
					self.saveBtn.click(function () {
						self.props.addPreset(self.state.value);
					});
					self.Colorpicker.picker.find('.colorpicker-selectors').append(self.saveBtn);
				}
			},
			pickerChanged: function () {
				var val = this.Colorpicker.getValue();
				this.setState({ value: val });
				this.props.onChange(val);
			},
			pickerInputChanged: function (e) {
				var val = e.target.value;
				if ((val.length && val.slice(0, 1) !== '#') || val.length > 7) {
					return;
				}
				this.setState({ value: val });
				this.props.onChange(val);
			},
			rgbToHex: function (rgbString) {
				if (rgbString.slice(0, 1) === '#' || !rgbString.length) {
					return rgbString;
				}
				var match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
				if (!match) {
					return rgbString;
				}
				var rgb = match[3] | (match[2] << 8) | (match[1] << 16);
				return '#' + (0x1000000 + rgb).toString(16).slice(1);
			},
			setRef: function (name, ref) {
				this[name] = ref;
			},
			openPicker: function () {
				this.Colorpicker.show();
				this._wrapper.classList.add('open');
				this._input.focus();
			},
			closePicker: function () {
				this._wrapper.classList.remove('open');
			},
			render: function () {
				var self = this;
				var style = {
					backgroundColor: self.state.value,
					borderColor: this.props.border ? null : self.state.value
				};
				var isText = this.props.isText;
				var faStyle = null;
				var isTransparent = !self.state.value || self.state.value === 'transparent';
				var triggerClass = 'trigger';

				if (isTransparent) {
					triggerClass += ' transparent';
				}

				if (!isTransparent) {
					var color = window.getTextColor(self.rgbToHex(self.state.value));
					faStyle = {
						color: color
					};

					style.color = color;
				}

				if (self.saveBtn) {
					self.saveBtn.css({
						'background-color': style.backgroundColor,
						color: style.color,
						display:
							!self.props.presets ||
							!self.props.presets[1].colors ||
							!self.props.presets[1].colors[self.state.value]
								? 'block'
								: 'none'
					});
				}

				var inputClass = 'color-input';
				if (this.props.className) {
					inputClass += ' ' + this.props.className;
				}

				return (
					<div
						className={'color-picker' + (this.props.selfContained ? ' colorpicker-self-contained' : '')}
						ref={self.setRef.bind(self, '_wrapper')}
						style={{ position: 'relative' }}
						data-testid="testing"
					>
						<input
							style={style}
							className={inputClass}
							type="text"
							ref={self.setRef.bind(self, '_input')}
							value={self.rgbToHex(self.state.value)}
							onChange={this.pickerInputChanged}
						/>
						<div className={triggerClass} style={style} onClick={self.openPicker}>
							{isText ? <b className="fa fa-font" style={faStyle} /> : null}
						</div>
					</div>
				);
			}
		}));

export default ColorPicker;

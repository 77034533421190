import Resource from 'Resources/Resource';
import UserDefinedObject from './Model/UserDefinedObject';
import { UserDefinedObjectTypeId } from './Model/UserDefinedObjectType';
import Attributes from 'App/babel/attributes/UserDefinedObjectAttributes';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import T from 'Components/Helpers/translate';

export type { UserDefinedObject };

type FindReturnType = Promise<{
	data: UserDefinedObject[];
	metadata: {
		offset: number;
		limit: number;
		total: number;
	};
}>;

const getUserDefinedObjectType = (typeId: number) => {
	const userDefinedObjectTypes = Tools.AppService.getMetadata().params.UserDefinedObject;
	return userDefinedObjectTypes.find(userDefinedObjectType => userDefinedObjectType.id === typeId) ?? null;
};

type ErrorResult = { error: { translated?: string } };
type RequestOpts = { urlParams: { typeId: number } };

class UserDefinedObjectResource extends Resource {
	constructor() {
		super('userDefinedObjects/:typeId', Attributes);

		this.dateFields = [Attributes.regDate.field, Attributes.modDate.field];

		this.notifications = {
			// @ts-expect-error
			save: (data: UserDefinedObject, requestOpts: RequestOpts) => {
				const {
					urlParams: { typeId }
				} = requestOpts;
				const title = getUserDefinedObjectType(typeId)?.name ?? T('default.object');

				return {
					title,
					body: 'default.wasSaved',
					style: STYLE.SUCCESS,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			saveError: ({ error }: ErrorResult, requestOpts: RequestOpts) => {
				const {
					urlParams: { typeId }
				} = requestOpts;
				const title = getUserDefinedObjectType(typeId)?.name ?? T('default.object');

				return {
					title,
					body: error.translated || 'default.unableToSave',
					style: STYLE.ERROR,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			// @ts-expect-error
			delete: (data: UserDefinedObject, requestOpts: RequestOpts) => {
				const {
					urlParams: { typeId }
				} = requestOpts;
				const title = getUserDefinedObjectType(typeId)?.name ?? T('default.object');

				return {
					title,
					body: 'default.wasDeleted',
					style: STYLE.SUCCESS,
					icon: 'trash',
					type: TYPE.BODY
				};
			},
			// @ts-expect-error
			deleteError: ({ error }: ErrorResult, requestOpts: RequestOpts) => {
				const {
					urlParams: { typeId }
				} = requestOpts;
				const title = getUserDefinedObjectType(typeId)?.name ?? T('default.object');

				return {
					title,
					body: error.translated || 'default.unableToDelete',
					style: STYLE.ERROR,
					icon: 'trash',
					type: TYPE.BODY
				};
			}
		};
	}

	eventName(requestOpts: RequestOpts) {
		const {
			urlParams: { typeId }
		} = requestOpts;
		return `userDefined${typeId}`;
	}

	find(typeId: UserDefinedObjectTypeId, filters: object = {}, opts: object = {}): FindReturnType {
		return super.find(filters, { ...opts, urlParams: { typeId } });
	}

	get(typeId: UserDefinedObjectTypeId, id: number, opts: object = {}): Promise<{ data: UserDefinedObject }> {
		return super.get(id, { ...opts, urlParams: { typeId } });
	}

	save(
		typeId: UserDefinedObjectTypeId,
		data: Partial<UserDefinedObject>,
		opts: object = {}
	): Promise<{ data: UserDefinedObject }> {
		return super.save(data, { ...opts, urlParams: { typeId } });
	}

	delete(typeId: UserDefinedObjectTypeId, id: number, opts: object = {}): Promise<void> {
		return super.delete(id, { ...opts, urlParams: { typeId } });
	}
}

const resource = new UserDefinedObjectResource();

export default resource;

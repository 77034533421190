import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import { globalTracker } from 'App/babel/helpers/Tracker';
import entityName from 'Components/Helpers/EntityName';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import history from 'App/pages/routes/history';

angular.module('domain.segment').controller('EditFlow', [
	'AppService',
	'$translate',
	'$scope',
	'Flow',
	'$stateParams',
	'$upModal',
	'$q',
	'$filter',
	'$state',
	'FeatureHelper',
	function (AppService, $translate, $scope, Flow, $stateParams, $upModal, $q, $filter, $state, FeatureHelper) {
		var dataStore;
		var rootNode = document.getElementById('edit-flow-root');

		var render = function (props, callback) {
			if (!rootNode) {
				return;
			}

			ReactDOM.render(
				React.createElement(
					ReactTemplates.flow.root,
					Object.assign({}, props, { renderCallback: callback || function () {} })
				),
				rootNode,
				function () {}
			);
		};

		$scope.$on('$destroy', () => {
			ReactDOM.unmountComponentAtNode(rootNode);
			rootNode = undefined;
		});

		var removeAttributeFromPath = function (flow, attribute) {
			// should remove id from all steps.
			var cleanAttribute = function (obj, attr) {
				delete obj[attr];

				if (obj.childYes) {
					obj.childYes = cleanAttribute(obj.childYes, attr);
				}

				if (obj.childNo) {
					obj.childNo = cleanAttribute(obj.childNo, attr);
				}

				return obj;
			};

			if (!flow.path) {
				return flow;
			}

			flow.path = cleanAttribute(flow.path, attribute);
			return flow;
		};

		const onSetFlowStatus = status => {
			var flowComponent = dataStore.get('flowComponent');
			flowComponent.flow.status = status;
			if (status === 'active') {
				flowComponent.activatingFlow = true;
			}
			dataStore.setStore({ flowComponent: flowComponent });

			Flow.save(flowComponent.flow)
				.then(function () {
					globalTracker.track('Updated flow status to ' + status);
					if (status === 'active') {
						setTimeout(function () {
							var flowComponent = dataStore.get('flowComponent');
							// fake loading for at least 1 sec
							flowComponent.activatingFlow = false;
							flowComponent.flowActivatedInfo = true;
							dataStore.setStore({ flowComponent: flowComponent });
							setTimeout(function () {
								dataStore.set('flowComponent.flowActivatedInfo', false);
							}, 1800);
						}, 1800);
					}
				})
				.catch(e => {
					flowComponent.activatingFlow = false;
					dataStore.setStore({ flowComponent });
					logError(e, 'Failed to set flow status');
				});
		};

		var actions = {
			setSegmentToFlow: function (segment) {
				var flow = dataStore.get('flowComponent.flow');

				flow.segmentId = segment ? segment.id : null;
				Flow.save(flow)
					.then(function (res) {
						dataStore.set('flowComponent.flow', res.data);
						$state.go('segment.flow', { id: flow.segmentId });
					})
					.catch(e => logError(e, 'Error setting segment to flow'));
			},
			deleteFlow: function (flow) {
				if (!flow || !flow.id) {
					return;
				}

				var customerId = AppService.getCustomerId();
				var confirmButtonText = $translate.instant('admin.modal.deleteEntity', {
					entity: $translate.instant(entityName('flow', 1)).toLowerCase()
				});

				var alertConfirmOptions = {
					type: 'confirm',
					reactive: true,
					fullscreen: true,
					hideAbort: false,
					dialog: AlertConfirm,
					id: 'confirm-delete-fields',
					body: React.createElement(AlertBody, {
						customerId: customerId,
						numSelected: 1,
						entity: 'flow'
					}),
					confirmButtonText: confirmButtonText,
					confirmClass: 'btn-red',
					confirmationMode: 'text',
					confirmText: Tools.AppService.getSelf().email,
					confirmFn: function () {
						return Flow.delete(flow.id).then(function () {
							history.push('/flows');
						});
					}
				};

				if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					return openModal('Alert', {
						...alertConfirmOptions,
						title: getConfirmationTitle('flow', 1),
						onClose: confirmed => {
							if (confirmed) {
								alertConfirmOptions.confirmFn();
							}
						}
					});
				}

				return $upModal.open('alert', alertConfirmOptions);
			},
			copyFlow: function (flow) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('infoPrompt', {
						title: 'flow.copyFlow.title',
						body: 'default.name',
						ok: 'flow.copyFlow.title',
						icon: 'fa-save'
					})
					.then(function (name) {
						delete flow.id;
						delete flow.segmentId;
						flow.status = 'draft';
						flow.name = name;
						flow = removeAttributeFromPath(flow, 'id');
						flow = removeAttributeFromPath(flow, 'mailCampaignId');

						Flow.save(flow)
							.then(function (savedFlow) {
								Tools.$state.go('flow', { id: savedFlow.data.id });
							})
							.catch(e => logError(e, 'Failed to copy flow'));
					});
			},
			editFlow: function (flow) {
				openModal('CreateFlow', {
					flow,
					onClose: updatedFlow => {
						if (updatedFlow) {
							const flowComponent = dataStore.get('flowComponent');
							flowComponent.flow = { ...updatedFlow };
							dataStore.setStore({ flowComponent });
						}
					}
				});
			},
			setFlowStatus: function (status) {
				var prompt = $q.when();
				// Ask user with popup then do request
				if (status === 'active') {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						prompt = new Promise(resolve => {
							openModal('Alert', {
								title: 'flow.confirmActivate',
								body: 'flow.confirmActivateBody',
								headerIcon: 'play',
								alertType: 'info',
								confirmButtonText: 'flow.yesActivate',
								cancelButtonText: 'default.update',
								onClose: confirmed => {
									if (confirmed) {
										onSetFlowStatus(status);
										resolve();
									}
								}
							});
						});
					} else {
						prompt = $upModal.open('infoConfirm', {
							title: 'flow.confirmActivate',
							body: 'flow.confirmActivateBody',
							icon: 'fa-play',
							resolveTrue: 'flow.yesActivate',
							no: 'default.abort'
						});
					}
				}

				// eslint-disable-next-line promise/catch-or-return
				prompt.then(function () {
					onSetFlowStatus(status);
				});

				return prompt;
			}
		};

		var initialStore = {
			tools: {
				$translate: $translate,
				$state: $state,
				formatNumber: $filter('numberFormat'),
				AppService: AppService
			},
			flowComponent: {
				isStandalone: true,
				activatingFlow: false,
				flowActivatedInfo: false,
				flow: null,
				loading: false,
				loadingNumberOfContacts: true,
				numberOfContacts: 0,
				showTooltip: false,
				editFlow: function () {
					var openPromise = $q.when();
					var flowComponent = dataStore.get('flowComponent');
					if (flowComponent.flow.status === 'active') {
						openPromise = $upModal
							.open('warningConfirm', {
								icon: 'fa-warning',
								title: 'flow.activeFlow',
								body: 'flow.activeFlowWarning',
								resolveTrue: 'flow.pauseAndEdit',
								no: 'default.abort'
							})
							.then(function () {
								flowComponent.loading = true;
								flowComponent.flow.status = 'paused';
								dataStore.setStore({ flowComponent: flowComponent });

								return Flow.save(flowComponent.flow);
							});
					}
					// eslint-disable-next-line promise/catch-or-return
					openPromise.then(function () {
						$state.go('editFlowStandalone', {
							id: dataStore.get('flowComponent.flow.id')
						});
					});
				},

				hideTooltip: function () {
					dataStore.set('flowComponent.showTooltip', false);
				}
			}
		};

		var init = function () {
			Flow.get($stateParams.id)
				.then(function (res) {
					const flow = res.data;
					initialStore.flowComponent.flow = flow;

					dataStore = new DataStore(render, actions, initialStore, function () {
						/*
					React.createElement is sync so if we defined the "callback function" here dataStore wont
					initialized when it is run, hence the timeout.
				*/
						setTimeout(function () {
							dataStore.set('filterSelection.state.autoFocus', true);
						}, 0);
					});

					if (flow.segment) {
						Tools.Segments.get(flow.segment.id, { params: { mapContactCount: true } })
							.then(function (result) {
								if (result && result.data && result.data.nrOfContacts) {
									dataStore.set('flowComponent.numberOfContacts', result.data.nrOfContacts);
								}
								dataStore.set('flowComponent.loadingNumberOfContacts', false);
							})
							.catch(error => {
								console.error('editFlow.js - loading number of contacts', error);
								dataStore.set('flowComponent.loadingNumberOfContacts', false);
							});
					} else {
						dataStore.set('flowComponent.loadingNumberOfContacts', false);
					}
				})
				.catch(function () {
					history.push('/flows');
				});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

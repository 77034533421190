'use strict';

angular.module('upResources').service('MapOrder', [
	'MapHelpers',
	function (MapHelpers) {
		return function (order) {
			// OrderRow custom
			if (order.orderRow && order.orderRow.length) {
				angular.forEach(order.orderRow, function (row) {
					if (row.custom) {
						row.custom = MapHelpers.mapCustom(row.custom);
					}
				});
			}

			if (order.account) {
				order.account = undefined;
			}

			if (order.client) {
				order.client = _.pick(order.client, 'id');
			}

			if (order.contact) {
				order.contact = _.pick(order.contact, 'id');
			}

			return order;
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import './BehaviorDiamond.scss';

const BehaviorDiamond = props => {
	const { size = 'medium' } = props;

	return (
		<div className={`behavior-diamond ${size}`}>
			<b className="fa fa-eye" />
		</div>
	);
};

BehaviorDiamond.propTypes = {
	size: PropTypes.string
};

export default BehaviorDiamond;

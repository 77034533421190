import React from 'react';
import { Tabs, Tab, DropDownButton, Button, Column, Icon } from '@upsales/components';
import './InsightsNavbar.scss';
import Input from '@upsales/components/Input';

const filterFunction = (dashboard, dropdownSearch) => {
	if (dropdownSearch === '') {
		return true;
	}

	return dashboard.title.toLowerCase().indexOf(dropdownSearch.toLowerCase()) !== -1;
};

const renderDropdown = ({
	dropdown,
	reports,
	favorites,
	mine,
	shared,
	standard,
	basicReporting,
	goToDashboard,
	goToBasicReport,
	goToReport,
	dropdownSearch,
	updateDropdownSearch
}) => {
	const t = Tools.$translate;
	let hasResults = false;
	switch (dropdown) {
		case 'basicreporting':
			return (
				<div className="InsightsNavbarDropdown dropdown open">
					<ul className="dropdown-menu">
						<li className="InsightsNavbarDropdown--Search">
							<Input
								icon="search"
								color="grey-2"
								placeholder={t('default.search')}
								type="text"
								value={dropdownSearch}
								autoFocus
								onChange={e => updateDropdownSearch(e.target.value)}
							/>
						</li>
						{[
							{
								key: 'sales',
								label: t('default.sales'),
								icon: 'dollar',
								dashboards: basicReporting.filter(
									dashboard =>
										dashboard.id.indexOf('basic_reporting::br_sales_') === 0 &&
										filterFunction(dashboard, dropdownSearch)
								)
							},
							{
								key: 'appointments',
								label: t('default.appointments'),
								icon: 'calendar',
								dashboards: basicReporting.filter(
									dashboard =>
										dashboard.id.indexOf('basic_reporting::br_app_') === 0 &&
										filterFunction(dashboard, dropdownSearch)
								)
							},
							{
								key: 'activities',
								label: t('default.activities'),
								icon: 'activity',
								dashboards: basicReporting.filter(
									dashboard =>
										dashboard.id.indexOf('basic_reporting::br_act_') === 0 &&
										filterFunction(dashboard, dropdownSearch)
								)
							},
							{
								key: 'market',
								icon: 'marketing-automation',
								label: t('default.market'),
								dashboards: basicReporting.filter(
									dashboard =>
										dashboard.id.indexOf('basic_reporting::br_marketing_') === 0 &&
										filterFunction(dashboard, dropdownSearch)
								)
							},
							{
								key: 'pipeline',
								label: t('report.pipeline'),
								icon: 'opportunity',
								dashboards: basicReporting.filter(
									dashboard =>
										dashboard.id.indexOf('basic_reporting::br_pipe_') === 0 &&
										filterFunction(dashboard, dropdownSearch)
								)
							}
						].map(({ key, label, icon, dashboards }) => {
							if (dashboards.length > 0) {
								hasResults = true;
							}

							return (
								dashboards.length > 0 && [
									<li className="InsightsNavbarDropdown--Header" key={key}>
										<Icon name={icon} /> {label}
									</li>,
									...dashboards.map(dashboard => (
										<li className="InsightsNavbarDropdown--Item" key={dashboard.id}>
											<a
												className="InsightsNavbarDropdown--Link"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													goToBasicReport(dashboard.id);
												}}
											>
												{dashboard.title}
											</a>
										</li>
									))
								]
							);
						})}
						{!hasResults && <li className="InsightsNavbarDropdown--Header">{t('default.noResults')}</li>}
					</ul>
				</div>
			);
		case 'dashboard':
			return (
				<div className="InsightsNavbarDropdown dropdown open">
					<ul className="dropdown-menu">
						<li className="InsightsNavbarDropdown--Search">
							<Input
								icon="search"
								color="grey-2"
								placeholder={t('default.search')}
								type="text"
								value={dropdownSearch}
								autoFocus
								onChange={e => updateDropdownSearch(e.target.value)}
							/>
						</li>
						{[
							{
								key: 'favorites',
								label: t('looker.favorites'),
								icon: 'heart',
								dashboards: favorites.filter(dashboard => filterFunction(dashboard, dropdownSearch))
							},
							{
								key: 'mine',
								label: t('looker.createdByMe'),
								icon: 'user',
								dashboards: mine.filter(dashboard => filterFunction(dashboard, dropdownSearch))
							},
							{
								key: 'shared',
								label: t('looker.sharedWithMe'),
								icon: 'users',
								dashboards: shared.filter(dashboard => filterFunction(dashboard, dropdownSearch))
							},
							{
								key: 'standard',
								label: t('looker.standard'),
								icon: 'upsales-u',
								dashboards: standard.filter(dashboard => filterFunction(dashboard, dropdownSearch))
							}
						].map(({ key, label, icon, dashboards }) => {
							if (dashboards.length > 0) {
								hasResults = true;
							}

							return (
								dashboards.length > 0 && [
									<li className="InsightsNavbarDropdown--Header" key={key}>
										<Icon name={icon} /> {label}
									</li>,
									...dashboards.map(dashboard => (
										<li className="InsightsNavbarDropdown--Item" key={dashboard.id}>
											<a
												className="InsightsNavbarDropdown--Link"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													goToDashboard(dashboard.id, dashboard.useNext);
												}}
											>
												{dashboard.title}
											</a>
										</li>
									))
								]
							);
						})}
						{!hasResults && <li className="InsightsNavbarDropdown--Header">{t('default.noResults')}</li>}
					</ul>
				</div>
			);
		case 'look':
			return (
				<div className="InsightsNavbarDropdown dropdown open">
					<ul className="dropdown-menu">
						<li className="InsightsNavbarDropdown--Search">
							<Input
								icon="search"
								color="grey-2"
								placeholder={t('default.search')}
								type="text"
								value={dropdownSearch}
								autoFocus
								onChange={e => updateDropdownSearch(e.target.value)}
							/>
						</li>
						{[
							{
								key: 'looks',
								label: t('looker.looks'),
								dashboards: reports.filter(dashboard => filterFunction(dashboard, dropdownSearch)),
								last: true
							}
						].map(({ key, label, dashboards }) => {
							if (dashboards.length > 0) {
								hasResults = true;
							}

							return (
								dashboards.length > 0 && [
									<li className="InsightsNavbarDropdown--Header" key={key}>
										{label}
									</li>,
									...dashboards.map(dashboard => (
										<li className="InsightsNavbarDropdown--Item" key={dashboard.id}>
											<a
												className="InsightsNavbarDropdown--Link"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													goToReport(dashboard.id);
												}}
											>
												{dashboard.title}
											</a>
										</li>
									))
								]
							);
						})}
						{!hasResults && <li className="InsightsNavbarDropdown--Header">{t('default.noResults')}</li>}
					</ul>
				</div>
			);
		default:
			return null;
	}
};

const InsightsNavbar = ({
	dropdown,
	goToHome,
	openDropdown,
	reports,
	favorites,
	mine,
	shared,
	standard,
	basicReporting,
	goToDashboard,
	goToBasicReport,
	goToReport,
	stateParamsType,
	dropdownSearch,
	updateDropdownSearch,
	hasExplores
}) => {
	const t = Tools.$translate;

	const onTabChange = id => {
		if (typeof id !== 'string') {
			return;
		}
		if (id === 'home') {
			goToHome();
		} else {
			openDropdown(id);
		}
	};

	let selected = dropdown || stateParamsType || 'home';
	if (selected === 'explore') {
		selected = 'nope';
	}
	const tabsProps = {
		onChange: onTabChange,
		className: 'InsightsNavbar--Tabs',
		selected
	};

	if (selected === 'nope') {
		tabsProps.className = `${tabsProps.className} InsightsNavbar--Tabs__nope`;
	}

	return (
		<div className="InsightsNavbar">
			<div className="InsightsNavbar--Wrapper">
				<Column size={4}>
					<Tabs {...tabsProps}>
						<Tab
							key="home"
							id="home"
							icon="home"
							title={t('default.start')}
							className="InsightsNavbar--Tab"
							style={{ padding: '0 14px' }}
						/>
						<Tab key="dashboard" id="dashboard" className="InsightsNavbar--Tab">
							<DropDownButton
								size="sm"
								title={t('looker.dashboards')}
								expanded={dropdown === 'dashboard'}
							/>
							{dropdown === 'dashboard' &&
								renderDropdown({
									dropdown,
									reports,
									favorites,
									mine,
									shared,
									standard,
									goToDashboard,
									goToReport,
									dropdownSearch,
									updateDropdownSearch
								})}
						</Tab>
						{reports.length ? (
							<Tab key="look" id="look" className="InsightsNavbar--Tab">
								<DropDownButton size="sm" title={t('looker.looks')} expanded={dropdown === 'look'} />
								{dropdown === 'look' &&
									renderDropdown({
										dropdown,
										reports,
										favorites,
										mine,
										shared,
										standard,
										goToDashboard,
										goToReport,
										dropdownSearch,
										updateDropdownSearch
									})}
							</Tab>
						) : null}
						{basicReporting.length ? (
							<Tab key="basicReporting" id="basicreporting" className="InsightsNavbar--Tab">
								<DropDownButton
									size="sm"
									title={t('looker.basicReporting')}
									expanded={dropdown === 'basicreporting'}
								/>
								{dropdown === 'basicreporting' &&
									renderDropdown({
										dropdown,
										basicReporting,
										goToBasicReport,
										dropdownSearch,
										updateDropdownSearch
									})}
							</Tab>
						) : null}
					</Tabs>
				</Column>

				<Tabs className="InsightsNavbar--Tabs--right">
					{[
						// Hides button if Insights is accessed through LookerDashboardExeptionIds param.
						Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LOOKER) && hasExplores ? (
							<Tab key="create-btn" className="InsightsNavbar--Tab">
								<Button color="bright-blue" onClick={() => Tools.$upModal.open('createLookerReport')}>
									{t('default.create')}
								</Button>
							</Tab>
						) : null
					]}
				</Tabs>
			</div>
		</div>
	);
};

export default InsightsNavbar;

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button, Text } from '@upsales/components';
import { navigateNextImport } from 'Store/reducers/OnboardingReducer';

const OnboardingImport = new Bem('OnboardingImport');
const StepWrapClass = new Bem('StepWrap');

const mapStateToProps = state => ({
	importApps: state.Onboarding.importApps,
	currentSubstepId: state.Onboarding.currentSubstepId
});

const mapDispatchToProps = {
	navigateNextImport
};

class StartSync extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.importStarted'),
			description: T('onboarding.importStartedDescription'),
			next: T('default.next')
		};
	}

	specialButton() {
		const { navigateNextImport } = this.props;

		return (
			<Button
				block
				size="xl"
				onClick={() => navigateNextImport({ phase: 3 })}
				className={StepWrapClass.elem('Button')}
			>
				{this.lang.next}
			</Button>
		);
	}

	render() {
		const nextButton = this.specialButton();

		return (
			<div className={OnboardingImport.b()}>
				<div className={OnboardingImport.elem('Title')}>{this.lang.title}</div>
				<Text className={OnboardingImport.elem('Description')}>{this.lang.description}</Text>
				{nextButton}
			</div>
		);
	}
}

StartSync.propTypes = {
	importApps: PropTypes.array,
	currentSubstepId: PropTypes.number,
	navigateNextImport: PropTypes.func
};

export const detached = StartSync;
export default connect(mapStateToProps, mapDispatchToProps)(StartSync);

import React from 'react';
import { Button, Text, ModalHeader, ModalContent, ModalControls, Modal } from '@upsales/components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import WebFont from 'webfontloader';
import logError from 'App/babel/helpers/logError';
import config from 'App/babel/config';
import fontWeights from './fontWeights';
import { reactSelectComponents, noOptionsMessage } from 'App/helpers/reactSelectSettings';
import './GoogleFontsModal.scss';

class GoogleFontsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fonts: [],
			selectedFont: null
		};
	}

	componentDidMount() {
		const { exclude } = this.props;

		axios
			.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${config.GOOGLE_FONTS_KEY}`)
			.then(response => {
				const fonts = response.data.items.filter(
					font => !exclude.find(excludedFont => excludedFont.family === font.family)
				);

				this.setState({ fonts });
			})
			.catch(e => logError(e, 'Failed to fetch fonts'));
	}

	handleFontChange = selectedFont => {
		WebFont.load({
			google: {
				families: [selectedFont.family]
			},
			active: () => {
				this.setState({ selectedFont });
			}
		});
	};

	render() {
		const t = Tools.$translate;
		const { resolve, reject } = this.props;
		const { fonts, selectedFont } = this.state;

		return (
			<div className="GoogleFontsModal">
				<Modal>
					<ModalHeader color="blue" title={t('admin.accountProfile.googleFonts')} onClose={() => reject()} />
					<ModalContent>
						<div className="GoogleFontsModal__FontSelect">
							<label htmlFor="font-family">{t('admin.accountProfile.font')}</label>
							<Select
								id="font-family"
								getOptionLabel={option => option.family}
								getOptionValue={option => option.family}
								isClearable={false}
								name="selected-font"
								value={selectedFont}
								placeholder={t('admin.accountProfile.fontName')}
								options={fonts}
								onChange={this.handleFontChange}
								noOptionsMessage={noOptionsMessage}
								components={reactSelectComponents()}
							/>

							{!selectedFont && (
								<a href="https://fonts.google.com/" target="_blank">
									{t('admin.accountProfile.browseGoogleFonts')}
								</a>
							)}
						</div>

						<div className="GoogleFontsModal__FontPreview">
							{selectedFont ? (
								<div style={{ fontFamily: selectedFont.family }}>
									{selectedFont.variants.map(variant => (
										<div className="GoogleFontsModal__PreviewText" key={variant}>
											<div
												style={{
													fontWeight: variant.replace('italic', ''),
													fontStyle: variant.includes('italic') ? 'italic' : 'normal'
												}}
											>
												{t('admin.accountProfile.fontPreviewText')}
											</div>
											<Text size="sm" color="grey-11">
												{fontWeights[variant] || variant}
											</Text>
										</div>
									))}
								</div>
							) : (
								<div className="GoogleFontsModal__NoFont">
									<img src="img/adwords.svg" />
									<Text class="" size="xl" color="grey-10">
										{t('admin.accountProfile.startBySelectingFont')}
									</Text>
								</div>
							)}
						</div>
					</ModalContent>
					<ModalControls>
						<Button disabled={!selectedFont} color="bright-blue" onClick={() => resolve(selectedFont)}>
							{t('default.save')}
						</Button>
						<Button color="grey" type="link" onClick={() => reject()}>
							{t('cancel')}
						</Button>
					</ModalControls>
				</Modal>
			</div>
		);
	}
}

GoogleFontsModal.propTypes = {
	resolve: PropTypes.func,
	reject: PropTypes.func,
	exclude: PropTypes.array
};

export default GoogleFontsModal;

'use strict';

angular.module('domain.account').factory('UpdateClientMultiMeta', [
	'$q',
	'Campaign',
	'AppService',
	function ($q, Campaign, AppService) {
		return function () {
			var customerId = AppService.getCustomerId();

			var promises = {
				activeUsers: $q.when({ data: AppService.getActiveUsers() }),
				campaigns: Campaign.customer(customerId).find({}),
				roles: $q.when({ data: AppService.getRoles() }),
				accountCustomFields: $q.when({ data: AppService.getCustomFields('account') })
			};

			return $q.all(promises);
		};
	}
]);

import Attribute from './Attribute';

const ListViewAttributes = {
	id: {
		field: 'id',
		type: Attribute.types.Number
	},
	description: {
		field: 'description',
		type: Attribute.types.String
	},
	uuid: {
		field: 'uuid',
		type: Attribute.types.String
	},
	listType: {
		field: 'listType',
		type: Attribute.types.String
	},
	grouping: {
		field: 'grouping',
		type: Attribute.types.String
	},
	tableGrouping: {
		field: 'tableGrouping',
		type: Attribute.types.String
	},
	type: {
		field: 'type',
		type: Attribute.types.String
	},
	title: {
		field: 'title',
		type: Attribute.types.String
	},
	regDate: new Attribute({
		title: 'default.regDate',
		type: Attribute.types.Date,
		field: 'regDate'
	}),
	modDate: new Attribute({
		title: 'default.modDate',
		type: Attribute.types.Date,
		field: 'modDate'
	}),
	sorting: {
		field: 'sorting',
		type: Attribute.types.Array
	},
	limit: {
		field: 'limit',
		type: Attribute.types.Number
	},
	regBy: {
		field: 'regBy',
		type: Attribute.types.Number
	},
	columns: {
		field: 'columns',
		type: Attribute.types.String
	},
	users: {
		field: 'users',
		type: Attribute.types.Array
	},
	roles: {
		field: 'roles',
		type: Attribute.types.Array
	},
	filters: {
		field: 'filters',
		type: Attribute.types.Array
	}
};

window.ListViewAttributes = ListViewAttributes;
export default ListViewAttributes;

import React, { Component } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text } from '@upsales/components';
import './AboutDropDown.scss';

type Props = {
	aboutText: string;
	isSubaccount?: boolean;
	isInactive?: boolean;
};

type State = {
	expanded: boolean;
};

class AboutDropDown extends Component<Props, State> {
	constructor(p: Props) {
		super(p);
		this.state = {
			expanded: false
		};
	}

	render() {
		const { aboutText, isInactive = false, isSubaccount = false } = this.props;
		const { expanded } = this.state;

		if (!aboutText) {
			return null;
		}

		const classes = new BemClass('AboutDropDown');
		return (
			<div
				className={classes.mod({ expanded, isSubaccount, isInactive }).b()}
				onClick={() => this.setState({ expanded: !this.state.expanded })}
			>
				<Text className={classes.elem('aboutText').mod({ expanded }).b()} size="sm">
					{aboutText}
				</Text>
			</div>
		);
	}
}

export default AboutDropDown;

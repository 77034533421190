import React from 'react';
import { Text, Headline, ModalContent, Modal, Card, Icon } from '@upsales/components';
import PropTypes from 'prop-types';

import './FontLocationModal.scss';

const FontLocationModal = ({ show, reject, resolve }) => {
	const t = Tools.$translate;

	return show ? (
		<div className="FontLocationModal">
			<Modal>
				<button className="ModalHeader__close Button up-btn btn-grey btn-link" type="button" onClick={reject}>
					<div className="Icon Icon-times Icon--inherit" />
				</button>
				<ModalContent>
					<div className="FontLocationModal__container">
						<Headline size="sm">{t('admin.accountProfile.fontLocation')}</Headline>
						<Text color="grey-11">{t('admin.accountProfile.customFontsRestrictionsInterstitial')}</Text>
						<div className="FontLocationModal__options">
							<Card className="col-xs-6" onClick={resolve.bind(null, 'google')}>
								<img className="FontLocationModal__OptionIcon" src="img/fonts.png" width="40" />
								<Text size="lg" color="black">
									{t('admin.accountProfile.googleFonts')}
								</Text>
								<Text color="grey-10">{t('admin.accountProfile.selectFromGoogleFonts')}</Text>
							</Card>
							<Card className="col-xs-6" onClick={resolve.bind(null, 'selfHosted')}>
								<div className="FontLocationModal_OptionIcon">
									<Icon name="code" color="grey-11" />
								</div>
								<Text size="lg" color="black">
									{t('admin.accountProfile.hostingMyself')}
								</Text>
								<Text color="grey-10">{t('admin.accountProfile.placeOnOwnServer')}</Text>
							</Card>
						</div>
					</div>
				</ModalContent>
			</Modal>
		</div>
	) : null;
};

FontLocationModal.propTypes = {
	show: PropTypes.bool,
	resolve: PropTypes.func,
	reject: PropTypes.func
};

export default FontLocationModal;

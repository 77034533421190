import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import { phoneNumberHelper } from '@upsales/common';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import { Button, Icon } from '@upsales/components';
import MailBounceLabel from 'Components/Misc/MailBounceLabel';

ReactTemplates.ROWSTATICS.contactRow = function (tools) {
	var statics = {};
	statics.editTool = tools.$translate('default.edit');
	statics.deleteTool = tools.$translate('default.delete');
	statics.unsubscribed = tools.$translate('default.unsubscribedFromGroupMail');
	statics.noCampaigns = tools.$translate('default.noCampaigns');
	statics.noCategories = tools.$translate('default.noCategories');
	statics.noSegments = tools.$translate('default.noSegments');
	statics.exclude = tools.$translate('default.exclude');
	statics.excluded = tools.$translate('bannerEditor.excluded');
	statics.include = tools.$translate('segment.include');
	statics.contact = tools.$translate('tag.contact');
	statics.unsubscribedFrom = tools.$translate('default.unsubscribedFrom');

	return statics;
};

ReactTemplates.ROW.contactRow = window.ReactCreateClass({
	displayName: 'contactRow',

	click: function () {
		this.props.tools.opts.clickedEntry(this.props.item);
	},
	edit: function () {
		this.props.tools.opts.editEntry(this.props.item);
	},
	exclude: function (e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.tools.opts.excludeContact(this.props.item);
	},
	include: function (e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.tools.opts.includeContact(this.props.item);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			scoreBoard: t('scoreboard.CreateAccount'),
			noContacts: t('default.noContact'),
			noSet: t('default.noSet'),
			cellphone: t('default.cellPhone').toLowerCase(),
			phone: t('default.phone').toLowerCase(),
			contactWillBeExcluded: t('segment.row.contactWillBeExcluded')
		};
	},
	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var standardFields = Tools.AppService.getMetadata().standardFields.Contact;
		var showSalutation =
			Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') &&
			standardFields.Salutation &&
			standardFields.Salutation.active;

		var getColumn = function (column, item) {
			var key = 'column-' + column;
			switch (column) {
				case 'clientText':
					var client = item.client;
					if (!client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					var text = _.trim(client.name);
					if (!text.length || text === ' ') {
						return (
							<td key={key}>
								<i>
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={client.id}
										text={statics.noName}
										tools={tools}
									/>
								</i>
							</td>
						);
					} else if (client.isExternal) {
						return (
							<td key={key}>
								<span className="grey">
									{client.name}
									&nbsp;{'('}
									<a onClick={options.saveExternal.bind(self, client)}>{self.lang.scoreBoard}</a>
									{')'}
								</span>
							</td>
						);
					} else {
						return (
							<td key={key}>
								<ReactTemplates.TOOLS.accountHref
									customerId={customerId}
									id={client.id}
									text={client.name}
									tools={tools}
								/>
							</td>
						);
					}

				case 'contactText':
					if (!item.name) {
						return (
							<td key={key}>
								<i className="grey">{self.lang.noContacts}</i>
							</td>
						);
					} else {
						return (
							<td
								key={key}
								onClick={function () {
									self.props.tools.opts.clickedEntry(self.props.item);
								}}
							>
								{ReactTemplates.TOOLS.contactsText(customerId, [item], tools)}
							</td>
						);
					}

				case 'history':
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.leadStatus
								theKey={item.id}
								isContact={true}
								tools={tools}
								activity={item.hasActivity}
								activityOld={item.hadActivity}
								appointment={item.hasAppointment}
								appointmentOld={item.hadAppointment}
								opportunity={item.hasOpportunity}
								opportunityOld={item.hadOpportunity}
								order={item.hasOrder}
								orderOld={item.hadOrder}
							/>
						</td>
					);

				case 'markethistory':
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.eventTypes account={item} theKey="marketActivity" tools={tools} />
						</td>
					);

				case 'name':
					var contactHrefText =
						showSalutation && item.salutation ? item.salutation.value + ' ' + item.name : item.name;

					return (
						<td key={key} className="account-contact-col">
							<ReactTemplates.TOOLS.contactHref
								customerId={customerId}
								id={item.id}
								text={contactHrefText}
								tools={tools}
							/>
							<span className="account-subtitle">
								<ReactTemplates.TOOLS.accountHref
									customerId={customerId}
									id={item.client ? item.client.id : '0'}
									text={item.client ? item.client.name : '-'}
									tools={tools}
								/>
							</span>
						</td>
					);

				case 'client':
				case 'account':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						</td>
					);

				case 'email':
					var style = { marginRight: '5px' };
					var tooltip;
					var isMailBounced = item.mailBounces && item.mailBounces.length;
					var hasIcon = false;
					if (item.unsubscribed) {
						style.color = 'red';
						hasIcon = true;
						tooltip = statics.unsubscribed + ' ' + moment(item.unsubscribed).format('L');
					} else if (!item.unsubscribed && item.optOut && item.optOut.length) {
						hasIcon = true;
						style.color = 'orange';
						tooltip =
							statics.unsubscribedFrom +
							':\n' +
							_.map(item.optOut, function (cat) {
								return cat.title;
							}).join('\n');
					}

					var icon = null;
					if (hasIcon) {
						icon = ReactTemplates.TOOLS.withTooltip(
							<i className="fa fa-minus-circle" style={style}></i>,
							tooltip,
							{ placement: 'left' }
						);
					}

					if (isMailBounced) {
						if (_.contains(item.mailBounces, 'hard_bounce')) {
							style = {
								color: 'red'
							};
							tooltip = tools.$translate('event.email_hard_bounce');
						} else if (_.contains(item.mailBounces, 'soft_bounce')) {
							style = {
								color: 'orange'
							};
							tooltip = tools.$translate('event.email_soft_bounce');
						}

						return (
							<td key={key}>
								{ReactTemplates.TOOLS.withTooltip(
									ReactTemplates.TOOLS.mailTo(customerId, item, style),
									tooltip
								)}
							</td>
						);
					}

					return (
						<td key={key}>
							{icon}
							{ReactTemplates.TOOLS.mailTo(customerId, item, style)}
						</td>
					);

				case 'phone':
					var phone = ReactTemplates.TOOLS.upVoice(item, 'contact', item.phone);
					var noPhone = self.lang.noSet + ' ' + self.lang.phone;
					return (
						<td key={key}>
							{phone ? (
								<a target={phone.target} href={phone.href} onClick={phone.onClick}>
									{phoneNumberHelper.formatNumber(item.phone)}
								</a>
							) : (
								<i className="grey">{noPhone}</i>
							)}
						</td>
					);

				case 'cellPhone':
					var cellTel = ReactTemplates.TOOLS.upVoice(item, 'contact', item.cellPhone);
					var noCellPhone = self.lang.noSet + ' ' + self.lang.cellphone;
					return (
						<td key={key}>
							{cellTel ? (
								<a target={cellTel.target} href={cellTel.href} onClick={cellTel.onClick}>
									{item.cellPhone}
								</a>
							) : (
								<i className="grey">{noCellPhone}</i>
							)}
						</td>
					);

				case 'actualphone':
					var actualTel = ReactTemplates.TOOLS.upVoice(item, 'contact', item.phone);
					var noActualPhone = self.lang.noSet + ' ' + self.lang.phone;
					return (
						<td key={key}>
							{actualTel ? (
								<a target={actualTel.target} href={actualTel.href} onClick={actualTel.onClick}>
									{item.phone}
								</a>
							) : (
								<i className="grey">{noActualPhone}</i>
							)}
						</td>
					);

				case 'score':
					return (
						<td key={key}>
							<span>{item.score + 'p'}</span>
						</td>
					);

				case 'campaigns':
					if (!item.projects || !item.projects.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaigns}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText(item.projects, tools)}</td>;
					}

				case 'categories':
					if (!item.$standardCategories || !item.$standardCategories.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCategories}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>{ReactTemplates.TOOLS.categoriesText(item.$standardCategories, tools)}</td>
						);
					}

				case 'segments':
					if (!item.segments || !item.segments.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noSegments}</i>
							</td>
						);
					}

					return <td key={key}>{ReactTemplates.TOOLS.segmentText(item.segments, tools)}</td>;
				case 'journeyStep':
					return (
						<td key={key}>
							<JourneyStepIcon id={item.journeyStep} />
						</td>
					);

				case 'emailBounceReason': {
					const type = _.find(item.mailBounces, event => event === 'hard_bounce') || 'soft_bounce';

					return (
						<td key={key}>
							{item.emailBounceReason ? (
								<MailBounceLabel type={type} reason={item.emailBounceReason} />
							) : null}
						</td>
					);
				}

				default:
					if (tools.FilterHelper.isCustomCategory(column)) {
						var id = column.split('_')[1];
						if (!item.$extraCategories[id] || !item.$extraCategories[id].length) {
							return (
								<td key={key}>
									<i className="grey">{statics.noCategories}</i>
								</td>
							);
						} else {
							return (
								<td key={key}>
									{ReactTemplates.TOOLS.categoriesText(item.$extraCategories[id], tools)}
								</td>
							);
						}
					}

					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var classes = !item.active ? 'inactive' : '';

		var toolsComponents = [];
		var excludeIds;
		var isExcluded;
		if (options.selectMode) {
			excludeIds = options.getExcludeIds();
			isExcluded = excludeIds.indexOf(item.id) !== -1;
			classes = 'segment-row';

			if (options.isSpecialExcludeFilter) {
				toolsComponents.push(
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userRemovable}
						icon="times-circle"
						onClick={this.exclude}
					/>
				);
			} else if (isExcluded) {
				classes += ' excluded';

				if (!tools.opts.isExcludeMode) {
					toolsComponents.push(
						<ReactTemplates.TOOLS.rowTool
							key={item.id + '-isExcluded-' + isExcluded}
							item={item}
							show={item.userRemovable}
							icon="times-circle"
							onClick={this.exclude}
						>
							<span
								className="segment-row-excludetext"
								key={item.id + '-isExcluded_sub-' + isExcluded}
								onClick={this.exclude}
							>
								{statics.excluded}
							</span>
						</ReactTemplates.TOOLS.rowTool>
					);
				}
			} else {
				if (!tools.opts.isExcludeMode) {
					toolsComponents.push(
						<ReactTemplates.TOOLS.rowTool
							key={item.id + '-isExcluded-' + isExcluded}
							item={item}
							show={item.userRemovable}
							icon="times-circle"
							tooltip={this.contactWillBeExcluded}
							onClick={this.exclude}
						>
							<span
								className="segment-row-excludetext bbb"
								key={item.id + '-isExcluded_sub-' + isExcluded}
								onClick={this.exclude}
							>
								{statics.exclude}
							</span>
						</ReactTemplates.TOOLS.rowTool>
					);
				} else {
					toolsComponents.push(
						<ReactTemplates.TOOLS.rowTool
							key={item.id + '-isExcluded-' + isExcluded}
							item={item}
							show={item.userRemovable}
							icon="plus-circle"
							onClick={this.include}
						>
							<span
								className="segment-row-excludetext"
								key={item.id + '-isExcluded_sub-' + isExcluded}
								onClick={this.include}
							>
								{statics.include}
							</span>
						</ReactTemplates.TOOLS.rowTool>
					);
				}
			}
		} else {
			toolsComponents.push(
				<ReactTemplates.TOOLS.rowTool
					key="edit"
					item={item}
					show={item.userEditable}
					icon="edit"
					tooltip={statics.editTool}
					onClick={this.edit}
				/>
			);
			toolsComponents.push(
				<InlineConfirm
					show={item.userRemovable}
					key={'InlineConfirm'}
					tooltip={statics.deleteTool}
					onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
					entity={'default.contact'}
				>
					<Button type="link" color="grey" className="row-tool">
						<Icon name="trash" />
					</Button>
				</InlineConfirm>
			);
		}
		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item, isExcluded);
		});

		if (options.selectMode) {
			return (
				<tr className={classes} key={'item-' + item.id} ref="row">
					{options.hasCheck && <ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />}
					{tds}
					<td className="column-tools">{toolsComponents}</td>
				</tr>
			);
		}

		return (
			<tr className={classes} key={'item-' + item.id} onClick={this.click} ref="row">
				{options.hasCheck && <ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />}
				{tds}
				<td className="column-tools">{toolsComponents}</td>
			</tr>
		);
	}
});

ReactTemplates.ROW.SegmentInfoRow = window.ReactCreateClass({
	displayName: 'segmentInfoRow',
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			events: t('default.countTotal'),
			segmentTitle: t('segment.infoRowTitle'),
			hide: t('default.hide'),
			step1: t('segment.infoRowTextStep1'),
			step2: t('segment.infoRowTextStep2'),
			step3: t('segment.infoRowTextStep3')
		};
	},
	closeRow: function () {
		this.props.closeRow();
	},
	render: function () {
		return (
			<tr id="segment-info-row">
				<td colSpan="100">
					<div className="title-text">{this.lang.segmentTitle}</div>
					<div className="info-content">
						<span className="tab-style">
							<div className="icon">{'1'}</div>
							<div className="info-text">{this.lang.step1}</div>
						</span>
						<span className="tab-style">
							<div className="icon">{'2'}</div>
							<div className="info-text">{this.lang.step2}</div>
						</span>
						<span className="tab-style">
							<div className="icon">{'3'}</div>
							<div className="info-text">{this.lang.step3}</div>
						</span>
					</div>
					<div className="close-btn" onClick={this.closeRow}>
						<b className="fa fa-chevron-up"></b>
						<span>{this.lang.hide}</span>
					</div>
				</td>
			</tr>
		);
	}
});

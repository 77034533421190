'use strict';

import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';

angular.module('upAttributes').service('SegmentAttributes', [
	'FilterType',
	'DisplayType',
	'AppService',
	function (FilterType, DisplayType, AppService) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'id'
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true
				},
				createDate: {
					title: 'default.appointmentCreateDate',
					field: 'createDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'createDate'
				},
				regBy: {
					title: 'default.createdBy',
					field: 'regBy',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true
				},
				active: {
					title: 'default.status',
					field: 'active',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					groupable: false,
					selectableColumn: true,
					sortable: 'active'
				},
				flowStatus: {
					title: 'flow.flowStatus',
					field: 'flow.status',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				custom: {}
			};

			var standardFilters = {
				Name: {
					title: 'default.name',
					field: 'name',
					columnPath: 'name',
					inputType: 'text',
					type: 'text'
				},
				RegBy: generateUserFilter(AppService.AccessType.ACCOUNT, {
					title: 'default.createdBy',
					field: 'regBy'
				}),
				Active: {
					title: 'default.status',
					type: 'radio',
					field: 'active',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.active', value: 1, comparisonType: 'Equals' },
						{ text: 'default.inactive', value: 0, comparisonType: 'Equals' }
					]
				}
			};

			if (AppService.loaded) {
				attributes.flowStatus.selectableColumn = Tools.FeatureHelper.isAvailable(
					Tools.FeatureHelper.Feature.FLOW
				);
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				keys: {
					ID: 'id',
					NAME: 'name',
					PHONE: 'phone',
					FAX: 'fax'
				}
			};
		};
	}
]);

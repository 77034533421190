import _ from 'lodash';
import Category from '../Model/Category';

type RawCategory = Optional<Category, '$hasAccess'> & { userUsable?: boolean };

// Return true ore false if the user can se the stage
const hasRoleAccess = function (field: RawCategory, roleId: number | null, admin: boolean) {
	if (admin || !field.roles?.length || _.find(field.roles, { id: roleId })) {
		return true;
	}

	return false;
};

export default function (category: RawCategory, hasBackendAccess = false) {
	const self = Tools.AppService.getSelf();
	category.$hasAccess = hasBackendAccess
		? category.userUsable
		: hasRoleAccess(category, self.role?.id || null, self.administrator);

	return category as Category;
}

import React from 'react';
import PropTypes from 'prop-types';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import ToggleList from './ToggleList';

export default class List extends React.Component {
	constructor(props) {
		super(props);

		const { $translate } = Tools;
		const propsPlaceholder = props.config.placeholder;

		this.lang = {
			placeholder: propsPlaceholder || this.getPlaceholder(),
			is: $translate('comparison.Equals'),
			isNot: $translate('comparison.NotEquals'),
			all: $translate('default.all'),
			any: $translate('default.any')
		};

		this.onValueChange = this.onChange.bind(this, 'value');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');
	}

	getPlaceholder() {
		const { config } = this.props;
		const { $translate } = Tools;

		if (config.filterName.indexOf('Category') !== -1) {
			return $translate('default.choose') + ' ' + $translate('default.category').toLowerCase();
		}

		switch (config.filterName) {
			case 'OptIn':
				return $translate('default.choose') + ' ' + $translate('default.optIn').toLowerCase();
			case 'Country':
				return $translate('default.choose') + ' ' + $translate('address.country').toLowerCase();
			case 'Campaign':
				return $translate('default.choose') + ' ' + $translate('default.campaign').toLowerCase();
			case 'ParentClient':
				return $translate('default.choose') + ' ' + $translate('default.parentCompany').toLowerCase();
			case 'AccountManager':
				return $translate('default.choose') + ' ' + $translate('default.accountManager').toLowerCase();
			default:
				return $translate('segment.selectCriteriaPlaceholder');
		}
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'value':
				newFilter.value = value;
				break;
			case 'comperator':
				newFilter.comparisonType = value;
				break;
		}

		newFilter.inactive = !(Array.isArray(newFilter.value) && newFilter.value.length);
		onChange(newFilter.value, newFilter);
	}

	getToggleOptions() {
		const { config } = this.props;

		if (config.comparisonType === 'MatchExact') {
			return [
				{
					value: 'MatchExact',
					text: this.lang.is
				},
				{
					value: 'NotMatchExact',
					text: this.lang.isNot
				}
			];
		} else if (config.allowAllComperator) {
			return [
				{
					value: 'Equals',
					text: this.lang.any
				},
				{
					value: 'EqualsAll',
					text: this.lang.all
				},
				{
					value: 'NotEquals',
					text: this.lang.isNot
				}
			];
		} else {
			return [
				{
					value: 'Equals',
					text: this.lang.is
				},
				{
					value: 'NotEquals',
					text: this.lang.isNot
				}
			];
		}
	}

	render() {
		const { filter, config, selectData, LIST_AJAX_LIMIT, autoFocus } = this.props;

		const listProps = {
			config: config,
			defaultValue: filter.value,
			onChange: this.onValueChange,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT,
			placeholder: this.lang.placeholder,
			autoOpen: !!autoFocus,
			locked: filter.locked
		};

		const toggleProps = {
			options: this.getToggleOptions(),
			onClick: this.onComperatorChange,
			value: filter.comparisonType,
			locked: filter.locked
		};

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<ToggleList list={listProps} toggle={toggleProps} />
				</div>
			</CriteriaRowWrapper>
		);
	}
}

List.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool
};

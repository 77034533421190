'use strict';
import React from 'react';
import { Toggle, Button } from '@upsales/components';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import ActivityOutcomes from 'Components/Activity/ActivityOutcomes';
import ActivityHistoryLog from 'App/components/HistoryLog/ActivityHistoryLog';
import { get } from 'lodash';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import PropTypes from 'prop-types';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import Timepicker from 'App/upsales/common/components/react/inputs/timepicker';
class BaseInfo extends React.Component {
	static propTypes = {
		activity: PropTypes.any,
		formInvalid: PropTypes.any,
		onChange: PropTypes.func.isRequired,
		tools: PropTypes.any,
		onCustomChange: PropTypes.func.isRequired,
		isOutcomeEnabled: PropTypes.bool,
		disabled: PropTypes.bool,
		next: PropTypes.any,
		bookAppointment: PropTypes.any,
		createOpportunityAndSave: PropTypes.func.isRequired,
		createOrderAndSave: PropTypes.func.isRequired,
		setContact: PropTypes.func.isRequired
	};

	state = {
		phoneCallId: null,
		conversationUrl: null,
		callsCollapsed: true
	};

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		this.lang = {
			userWarning: t('flash.userWarning'),
			chooseAnAccount: t('default.chooseAnAccount'),
			description: t('default.description'),
			describeActivity: t('activity.describeActivity'),
			when: t('activity.when'),
			today: t('activity.today'),
			tomorrow: t('activity.tomorrow'),
			sevenDays: t('activity.sevenDays'),
			oneMonth: t('activity.oneMonth'),
			time: t('default.time'),
			activityType: t('default.activityType'),
			campaign: t('default.campaign'),
			connectActivity: t('activity.connectActivity'),
			new: t('default.new'),
			opportunity: t('default.opportunity'),
			connectOpportunity: t('activity.connectOpportunity'),
			otherInfo: t('default.otherInfo'),
			phoneCalls: t('voice.phoneCalls'),
			to: t('default.to'),
			loadMoreRows: t('default.showAll'),
			priority: t('activity.priority'),
			disabledTooltip: t('default.youHaveFormErrorsRequiredBody')
		};
	}

	isInvalid = (key, isRequired) => {
		if (
			isRequired &&
			(!this.props.activity[key] ||
				(typeof this.props.activity[key] === 'string' && !this.props.activity[key].length)) &&
			this.props.formInvalid
		) {
			return true;
		}
		return false;
	};

	strChange = (key, e) => {
		this.props.onChange(key, e.target.value);
	};

	typeChange = activityType => {
		this.props.onChange('activityType', activityType);
	};

	togglePriority = checked => {
		this.props.onChange('priority', checked ? ACTIVITY_PRIORITY.HIGH : ACTIVITY_PRIORITY.NONE);
	};

	dateChange = date => {
		var activity = this.props.activity;
		// Set time in dateObject if we have a time set
		var time = activity.time;
		if (date && time) {
			date = new Date(date);
			if (typeof time !== 'object') {
				time = new Date(time);
			}
			date.setHours(time.getHours());
			date.setMinutes(time.getMinutes());
		}
		this.props.onChange('date', date);
	};

	onPresetDateChange = date => {
		this.props.onChange('time', null);
		this.props.onChange('date', date);
	};

	timeChange = time => {
		var date = this.props.activity.date;
		if (date && typeof date !== 'object') {
			date = new Date(date);
		}

		if (date && time) {
			date.setHours(time.getHours(), time.getMinutes(), 0, 0);
			this.props.onChange('date', date);
		}

		this.props.onChange('time', time);
	};

	setDateFromPreset = preset => {
		var date = {
			today: moment().startOf('day').toDate(),
			tomorrow: moment().add(1, 'day').startOf('day').toDate(),
			sevenDays: moment().add(7, 'day').startOf('day').toDate(),
			oneMonth: moment().add(1, 'month').startOf('day').toDate(),
			halfYear: moment().add(6, 'month').startOf('day').toDate()
		}[preset];

		this.dateChange(date);
	};

	openPhoneCall = (call, e) => {
		var classList = e.target.classList;

		if (!classList.contains('recipent') && !classList.contains('call-date')) {
			return;
		}

		var id;
		var tools = this.props.tools;
		var integration = Tools.AppService.getPhoneIntegration();

		if (!integration || (integration.capabilities && integration.capabilities.noRecordings)) {
			return;
		}

		var url;
		if (this.state.phoneCallId === call.id) {
			id = null;
		} else {
			id = call.id;
			if (call.conversationUrl) {
				url =
					tools.URL +
					tools.API +
					'function/voice/recording/?id=' +
					call.conversationUrl +
					'&type=recording&integrationId=' +
					integration.id;
			}
		}

		this.setState({
			phoneCallId: id,
			conversationUrl: url
		});
	};

	campaignChange = campaign => {
		this.props.onChange('project', campaign);
	};

	opportunityChange = opportunity => {
		this.props.onChange('opportunity', opportunity);
	};

	onCustomChange = (id, value) => {
		this.props.onCustomChange(id, value);
	};

	customInvalid = cf => {
		var invalid = false;
		if (
			cf.editable &&
			cf.obligatoryField &&
			(cf.value === null || cf.value === undefined || cf.value === '' || cf.value === false)
		) {
			invalid = true;
		} else if (cf.value && cf.value.length && cf.datatype === 'Email') {
			var re =
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
			var emailValid = re.test(cf.value);
			if (!emailValid) {
				invalid = true;
			}
		} else if (cf.value && cf.datatype === 'Date') {
			if (!moment(cf.value).isValid()) {
				invalid = true;
			}
		}
		return invalid;
	};

	retrieveSoundFile = call => {
		var self = this;
		var tools = self.props.tools;
		var integration = tools.AppService.getPhoneIntegration();

		if (
			!integration ||
			(integration.capabilities && integration.capabilities.noRecordings) ||
			!call.conversationUrl
		) {
			return;
		}

		var url =
			tools.URL +
			tools.API +
			'function/voice/recording/?id=' +
			call.conversationUrl +
			'&type=recording&integrationId=' +
			integration.id;
		var req = new XMLHttpRequest();
		req.open('GET', url);
		req.responseType = 'blob';
		req.withCredentials = true;
		req.onload = function () {
			var URL = window.URL || window.webkitURL;
			var objUrl = URL.createObjectURL(req.response);

			var audio = ReactDOM.findDOMNode(self.refs['audio-' + call.id]);
			var source = audio.firstElementChild;

			source.src = objUrl;
			source.onload = function () {
				URL.revokeObjectURL(objUrl);
			};
			audio.load();
		};
		req.send(null);
	};

	toggleCallsCollapsed = () => {
		this.setState({ callsCollapsed: !this.state.callsCollapsed });
	};

	openContactDropdown = () => {
		jQuery('#contact-select').select2('open');
	};

	setOutcome = (outcome, cb) => {
		const { activity } = this.props;
		const dateTimeStr = moment.utc().format();
		const clientJourney = get(activity, 'client.journeyStep');

		const updated = outcome ? { ...outcome, date: dateTimeStr, clientJourney } : null;

		this.props.onChange('outcome', updated);
		cb?.();
	};

	render() {
		var self = this;
		var tools = self.props.tools;
		var activity = self.props.activity;
		var disabled = self.props.disabled;
		var requiredFields = self.props.requiredFields;
		var descClass = 'form-group';
		var presetClasses = 'up-btn btn btn-xs btn-link btn-bright-blue';
		var dateStr = moment(activity.date).format('L');
		var customFields = null;
		const isPriorityEnable = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);
		const contact = get(activity, 'contacts[0]');

		if (self.isInvalid('description', requiredFields.Description)) {
			descClass += ' has-error';
		}

		if (activity.custom) {
			customFields = _.chain(activity.custom)
				.map((field, index) => {
					if (field.$hasAccess && (field.visible || field.editable)) {
						var cf = React.createElement(ReactTemplates.INPUTS.customFieldInput, {
							key: 'cf-' + index,
							field: field,
							name: name,
							disabled: disabled || !field.editable,
							multiple: false,
							className: 'form-control up-input',
							filter: false,
							tools: tools,
							onChange: self.onCustomChange.bind(self, field.id)
						});

						var classes = 'form-group';

						if (self.props.formInvalid && self.customInvalid(field)) {
							classes += ' has-error';
						}

						return (
							<div className="flex-col" key={'cf-' + index + '-col'}>
								<div className={classes}>
									<label>
										{field.name}{' '}
										{field.obligatoryField ? <b className="text-danger">{'*'}</b> : null}
									</label>
									<div className="input">{cf}</div>
								</div>
							</div>
						);
					}
					return null;
				})
				.sortBy('sortId')
				.value();
		}

		var datepicker = React.createElement(ReactTemplates.INPUTS.datepicker, {
			onChange: self.dateChange,
			className: 'date-input',
			value: activity.date,
			name: 'date',
			disabled: disabled,
			tabIndex: 4,
			readOnly: true,
			tools: tools
		});

		var timepicker = (
			<Timepicker
				onChange={self.timeChange}
				className={'form-control time-input'}
				value={activity.time}
				disabled={disabled}
				tabIndex={5}
				placeholder={self.lang.time}
				tools={tools}
			/>
		);

		var activityTypeSelect = React.createElement(UpActivityTypes, {
			entity: 'activity',
			className: 'form-control expand align-right',
			value: activity.activityType,
			onChange: self.typeChange,
			disabled: disabled,
			required: true,
			tabIndex: 6,
			id: 'activity-type',
			tools: tools
		});

		var campaignSelect = React.createElement(ReactTemplates.INPUTS.upCampaigns, {
			className: 'form-control expand',
			value: activity.project,
			onChange: self.campaignChange,
			disabled: disabled,
			required: requiredFields.Project,
			tabIndex: 7,
			id: 'campaign-select',
			placeholder: self.lang.connectActivity,
			linked: true,
			tools: tools
		});

		var opportunitySelect = React.createElement(ReactTemplates.INPUTS.upOpportunities, {
			className: 'form-control expand align-right',
			value: activity.opportunity,
			onChange: self.opportunityChange,
			disabled: disabled,
			required: requiredFields.Opportunity,
			tabIndex: 8,
			id: 'opportunity-select',
			placeholder: self.lang.connectOpportunity,
			accountId: activity.client ? activity.client.id : null,
			linked: true,
			tools: tools
		});

		var phoneCall = null;

		if (activity.phoneCalls && activity.phoneCalls.length) {
			var filter = self.props.tools.$filter;
			var calls = activity.phoneCalls
				.map(function (call, index) {
					if (!call.client || !call.phoneNumber) {
						return null;
					}

					var callName = call.client.name;
					if (call.contact && call.contact.name) {
						callName = call.contact.name;
					}

					var classes = 'phone-card';

					if (self.state.phoneCallId === call.id && call.durationInS) {
						classes += ' open';
					}

					if (!call.durationInS) {
						classes += ' no-audio';
					}

					var audioElement = null;
					if (self.state.phoneCallId === call.id && self.state.conversationUrl && call.durationInS) {
						audioElement = React.createElement(ReactTemplates.INPUTS.upAudio, {
							src: self.state.conversationUrl,
							tools: tools
						});
					}

					var integration = Tools.AppService.getPhoneIntegration();

					return (
						<div key={index} className={classes} onClick={self.openPhoneCall.bind(self, call)}>
							<div className="recipent">
								{self.lang.to}
								{':'} {callName}
								<span className="phone-number">{call.phoneNumber}</span>
							</div>
							<div className="call-date">{filter('upsalesDate')(call.date)}</div>
							<span className="play-btn">
								{filter('secondsToTime')(call.durationInS)}
								{integration &&
								(!integration.capabilities || !integration.capabilities.noRecordings) ? (
									<i className="fa fa-play outer" aria-hidden="true"></i>
								) : null}
							</span>
							<div className="user">
								<ReactTemplates.TOOLS.avatar
									user={call.user}
									size={25}
									avatarService={self.props.tools.avatarService}
								/>
							</div>

							<div className="hidden-stuff">{audioElement}</div>
						</div>
					);
				})
				.filter(function (c) {
					return c;
				});

			var phoneContentClasses = ['phone-content'];
			if (calls.length > 3 && this.state.callsCollapsed) {
				phoneContentClasses.push('phone-content__collapsed');
			}
			phoneCall = (
				<div className="phone-calls">
					<h2>{self.lang.phoneCalls}</h2>
					<div className={phoneContentClasses.join(' ')}>{calls}</div>
					{calls.length > 3 && this.state.callsCollapsed && (
						<div className="show-all-calls">
							<Button
								className="btn-block"
								type="link"
								size="sm"
								color="bright-blue"
								onClick={self.toggleCallsCollapsed.bind(self)}
							>
								{this.lang.loadMoreRows}
							</Button>
						</div>
					)}
				</div>
			);
		}

		return (
			<div className="modal-center" ref={self.props.ref}>
				<div id="select-account-cover" className="hide-when-account">
					<b className="fa fa-long-arrow-left"></b> <i>{self.lang.chooseAnAccount}</i>
				</div>

				<div className="form-flex-group">
					<div className="description-wrapper">
						<label htmlFor="description">
							{self.lang.description}{' '}
							{requiredFields.Description ? <b className="text-danger">{'*'}</b> : null}
						</label>

						<div className={descClass}>
							{/* eslint-disable-next-line react/jsx-no-undef */}
							<Input.input
								id="description"
								className="form-control"
								value={activity.description}
								onChange={self.strChange.bind(self, 'description')}
								placeholder={self.lang.describeActivity}
								disabled={disabled}
								required={requiredFields.Description}
								name="description"
								tabIndex="3"
								maxLength={100}
							></Input.input>
						</div>
					</div>
					{isPriorityEnable ? (
						<div className="activity-priority-wrapper">
							<label>{self.lang.priority}</label>
							<div className="activity-priority-field">
								<Toggle
									className="col-md-6"
									color="red"
									size="lg"
									onChange={this.togglePriority}
									disabled={disabled}
									checked={activity.priority === ACTIVITY_PRIORITY.HIGH}
								/>
							</div>
						</div>
					) : null}
				</div>

				{this.props.isOutcomeEnabled && !activity.closeDate ? (
					<div className="form-flex-group" style={{ marginBottom: '15px' }}>
						<ReduxRootComponent
							component={ActivityOutcomes}
							activity={activity}
							company={activity.client}
							contact={contact}
							disabled={this.props.disabled}
							onPresetDateChange={this.onPresetDateChange}
							onDateChange={self.dateChange}
							onTimeChange={self.timeChange}
							onChange={this.setOutcome}
							confirm={this.props.next}
							bookAppointment={this.props.bookAppointment}
							changeContact={this.openContactDropdown}
							createOpportunity={this.props.createOpportunityAndSave}
							createOrder={this.props.createOrderAndSave}
							setContact={this.props.setContact}
							dateAnchor={document.getElementsByClassName('modal-center')[0]}
							disabledOutcome={this.props.formInvalid}
							disabledTooltip={this.props.formInvalid ? self.lang.disabledTooltip : null}
						/>
					</div>
				) : null}

				<div className="form-flex-group">
					{!this.props.isOutcomeEnabled || !contact ? (
						<div className="flex-col">
							<label>{self.lang.when}</label>

							{!activity.closeDate ? (
								<div className="pull-right">
									<div id="date-presets" className="btn-group pull-right">
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'today')}
											disabled={disabled}
										>
											{self.lang.today}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'tomorrow')}
											disabled={disabled}
										>
											{self.lang.tomorrow}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'sevenDays')}
											disabled={disabled}
										>
											{self.lang.sevenDays}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'oneMonth')}
											disabled={disabled}
										>
											{self.lang.oneMonth}
										</button>
									</div>
									<div id="date-presets-min" className="btn-group pull-right">
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'today')}
											disabled={disabled}
										>
											{self.lang.today}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'tomorrow')}
											disabled={disabled}
										>
											{'+1'}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'sevenDays')}
											disabled={disabled}
										>
											{'+7'}
										</button>
										&nbsp;
										<button
											type="button"
											className={presetClasses}
											onClick={self.setDateFromPreset.bind(self, 'oneMonth')}
											disabled={disabled}
										>
											{'+30'}
										</button>
									</div>
								</div>
							) : null}

							<div className="date-time">
								<b className="date-icon fa fa-calendar-o"></b>
								<b className="time-icon fa fa-clock-o"></b>

								{datepicker}

								<div className="input-group">
									<span className="fake-date-input form-control" disabled={disabled}>
										{dateStr}
									</span>

									{timepicker}
								</div>
							</div>
						</div>
					) : null}

					<div className="flex-col">
						<label htmlFor="activity-type">{self.lang.activityType}</label>

						{activityTypeSelect}
					</div>

					<div className="flex-col">
						<label htmlFor="campaign-select">
							{self.lang.campaign} {requiredFields.Project ? <b className="text-danger">{'*'}</b> : null}
						</label>
						{!activity.closeDate ? (
							<button
								type="button"
								onClick={self.props.onCreateCampaign}
								className="label-add pull-right up-btn btn-bright-blue btn-link btn btn-sm"
								disabled={disabled}
							>
								{self.lang.new}
							</button>
						) : null}

						{campaignSelect}
					</div>

					{self.props.isAvailable.opportunity ? (
						<div className="flex-col">
							<label htmlFor="opportunity-select">
								{self.lang.opportunity}{' '}
								{requiredFields.Opportunity ? <b className="text-danger">{'*'}</b> : null}
							</label>
							{!activity.closeDate ? (
								<button
									type="button"
									onClick={self.props.onCreateOpportunity}
									className="label-add pull-right up-btn btn-bright-blue btn-link btn btn-sm"
									disabled={disabled}
								>
									{self.lang.new}
								</button>
							) : null}

							{opportunitySelect}
						</div>
					) : null}
				</div>

				{phoneCall}
				<div dangerouslySetInnerHTML={{ __html: '<div id="center-script-container"></div>' }}></div>

				{customFields && customFields.length ? (
					<>
						<h2>{self.lang.otherInfo}</h2>
						<div className="form-flex-group">{customFields}</div>
					</>
				) : null}
				{hasActivityOutcomeAccess ? (
					<div className="form-flex-group">
						<ReduxRootComponent component={ActivityHistoryLog} activity={activity} />
					</div>
				) : null}
			</div>
		);
	}
}

export default BaseInfo;

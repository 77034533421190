import React from 'react';
import { connect } from 'react-redux';
import { Text, Input } from '@upsales/components';
import UserRoleList from 'Components/Inputs/UserRoleList';
import { MappedSalesCoach } from 'App/resources/Model/SalesCoach';
import { setName, setUsers, setRoles, setAssignmentType } from 'Store/reducers/SalesCoachReducer';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './UsersAndRoles.scss';

type ReducerProps = {
	users: number[];
	roles: number[];
	assignmentType: 'users' | 'roles';
	name: string;
	isNameUnique: boolean;
};

const mapStateToProps = ({ SalesCoachReducer }: { SalesCoachReducer: ReducerProps }) => ({
	name: SalesCoachReducer.name,
	users: SalesCoachReducer.users,
	roles: SalesCoachReducer.roles,
	isNameUnique: SalesCoachReducer.isNameUnique,
	assignmentType: SalesCoachReducer.assignmentType
});

const mapDispatchToProps = { setName, setUsers, setRoles, setAssignmentType };

type Props = ReducerProps & {
	setName: (name: string, salesCoaches?: MappedSalesCoach[] | undefined) => void;
	setUsers: (users: number[]) => void;
	setRoles: (roles: number[]) => void;
	setAssignmentType: (type: string) => void;
	salesCoaches: MappedSalesCoach[] | undefined;
};

class UsersAndRoles extends React.Component<Props> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			perhapsNameWhosGoingToUseIt: T('admin.newSalesProcess.perhapsNameWhosGoingToUseIt'),
			salesProcessName: T('admin.newSalesProcess.salesProcessName'),
			whosGoingToUseIt: T('admin.newSalesProcess.whosGoingToUseIt'),
			nameMustBeUnique: T('admin.newSalesProcess.nameMustBeUnique')
		};
	}

	changeAssignmentType = (assignmentType: 'users' | 'roles') => {
		const { setUsers, setRoles, setAssignmentType } = this.props;
		setAssignmentType(assignmentType);
		setRoles([]);
		setUsers([]);
	};

	setSelectedUsersRoles = (selectedUsersRoles: Array<number>) => {
		const { assignmentType, setUsers, setRoles } = this.props;
		if (assignmentType === 'users') {
			setUsers(selectedUsersRoles);
		} else {
			setRoles(selectedUsersRoles);
		}
	};

	toggleUsersRoles = (id: number) => {
		const { assignmentType, setUsers, setRoles, users, roles } = this.props;
		const [setFunction, currentIds] = assignmentType === 'users' ? [setUsers, [...users]] : [setRoles, [...roles]];
		const index = currentIds.indexOf(id);
		if (index === -1) {
			setFunction([...currentIds, id]);
		} else {
			currentIds.splice(index, 1);
			setFunction(currentIds);
		}
	};

	render() {
		const classNames = new BemClass('UsersAndRoles');
		const { setName, name, users, roles, assignmentType, isNameUnique, salesCoaches } = this.props;
		return (
			<div className={classNames.b()}>
				<Text className={classNames.elem('name').b()} bold>
					{this.lang.salesProcessName}
				</Text>
				<Text color="red" bold className={classNames.elem('asterisk').b()}>
					{'*'}
				</Text>
				<Input
					autofocus
					placeholder={this.lang.perhapsNameWhosGoingToUseIt}
					onChange={e => setName(e.target.value, salesCoaches)}
					state={isNameUnique ? 'focus' : 'warning'}
					value={name}
				/>
				{isNameUnique ? null : (
					<Text color="red" bold size="sm">
						{this.lang.nameMustBeUnique}
					</Text>
				)}
				<Text className={classNames.elem('whoIsItFor').b()} bold>
					{this.lang.whosGoingToUseIt}
				</Text>
				<UserRoleList
					space="mtm"
					usersOnly={false}
					assignOneUser={false}
					selectedUsersRoles={assignmentType === 'users' ? users : roles}
					setSelectedUsersRoles={this.setSelectedUsersRoles}
					assignmentType={assignmentType}
					changeAssignmentType={this.changeAssignmentType}
					toggleUserRole={this.toggleUsersRoles}
					allAsDefault={true}
				/>
			</div>
		);
	}
}

export const detached = UsersAndRoles;

const Component = connect(mapStateToProps, mapDispatchToProps)(UsersAndRoles);

export default Component;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, OutsideClick, Icon } from '@upsales/components';
class DocumentTemplates extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	render() {
		return (
			<div className="dropdown document-templates-outside-click show dropup btn-group pull-left">
				<Button
					color="super-light-green"
					shadow="none"
					onClick={() => this.setState({ open: !this.state.open })}
					className="dropdown-toggle"
					disabled={this.props.disabled}
				>
					<Icon name="file-o" space="mrs" />
					{Tools.$translate('default.create') + ' ' + Tools.$translate('default.document').toLowerCase()}
					<Icon name="caret-up" space="mls" />
				</Button>

				{this.state.open && (
					<OutsideClick
						targetClass="document-templates-outside-click"
						outsideClick={() => this.setState({ open: false })}
					>
						<ul className="document-templates-dropdown-holder document-templates-outside-click dropdown-menu show up-dropdown-white limited">
							{_.sortBy(this.props.documentTemplates, 'name').map((template, i) => {
								return (
									<li key={'document-template-' + i}>
										<Button
											type="lined"
											color="super-light-green"
											shadow="none"
											style={{
												display: 'block',
												border: 'none',
												width: '100%',
												margin: '0',
												textAlign: 'left'
											}}
											onClick={() => this.props.actions.createDocument(template)}
										>
											{template.name}
										</Button>
									</li>
								);
							})}
						</ul>
					</OutsideClick>
				)}
			</div>
		);
	}
}

DocumentTemplates.propTypes = {
	disabled: PropTypes.bool,
	documentTemplates: PropTypes.array,
	actions: PropTypes.object.isRequired
};

export default DocumentTemplates;

import React from 'react';
import { Title, Card, ButtonGroup, Button, Icon, Text } from '@upsales/components';
import './FileStorageAppHeader.scss';

const FileStorageAppHeader = ({ app, displayType, onChangeDisplayType, onDisconnect }) => (
	<div className="FileStorageApps__app-header">
		<Card
			style={{
				backgroundImage: `url(${app.imageLink})`,
				color: app.color
			}}
		/>
		<Title>{app.name}</Title>
		<ButtonGroup>
			<div onClick={onDisconnect} className="disconnect">
				<Icon name="link" color="bright-blue" />
				<Text color="bright-blue">{Tools.$translate('default.disconnect')}</Text>
			</div>
			<Button
				size="sm"
				color={displayType === 'list' ? 'light-grey' : 'bright-blue'}
				onClick={() => onChangeDisplayType('grid')}
			>
				<Icon name="th-large" />
			</Button>
			<Button
				size="sm"
				color={displayType !== 'list' ? 'light-grey' : 'bright-blue'}
				onClick={() => onChangeDisplayType('list')}
			>
				<Icon name="list" />
			</Button>
		</ButtonGroup>
	</div>
);

export default FileStorageAppHeader;

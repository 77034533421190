import BemClass from '@upsales/components/Utils/bemClass';
import React, { useRef, useState } from 'react';
import ResetPasswordResource from 'App/resources/ResetPassword';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestChangePassword from 'Components/RequestChangePassword';
import logError from 'Helpers/logError';
import { login } from 'App/helpers/loginHelper';

type Props = {
	email: string;
	oldPassword: string;
	on2fa: (token: string) => void;
	showLogin: () => void;
	rememberMe: boolean;
};

const LoginForceResetPassword = ({ email, oldPassword, showLogin, on2fa, rememberMe }: Props) => {
	const classes = new BemClass('LoginForceResetPassword');
	const [, setLoading] = useState(false);
	const requestPromise = useRef<null | CancelablePromise<any>>(null);

	const doResetPassword = (newPassword: string) => {
		setLoading(true);
		requestPromise.current = makeCancelable(
			ResetPasswordResource.changePassword({
				email,
				password: oldPassword,
				newPassword
			})
		);
		requestPromise.current.promise
			.then(() => {
				requestPromise.current = makeCancelable(login(email, newPassword, rememberMe));

				requestPromise.current.promise
					.then(res => {
						if (res.isTwoFactorAuth) {
							on2fa(res.token);
						}
					})
					.catch(err => {
						logError(err, 'Failed to login after password was reset (force reset))');
						showLogin();
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch(err => {
				logError(err, 'Failed to reset password (force reset))');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className={classes.b()}>
			<RequestChangePassword
				oldPassword={oldPassword}
				changePassword={newPassword => doResetPassword(newPassword)}
			/>
		</div>
	);
};

export default LoginForceResetPassword;

import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import Timepicker from 'App/upsales/common/components/react/inputs/timepicker';
export default class TimeInput extends React.Component {
	constructor(props) {
		super(props);

		const { onChange, filter } = this.props;

		this.state = {
			start: filter.value.start ? filter.value.start : null,
			end: filter.value.end ? filter.value.end : null,
			error: false
		};

		this.lang = {
			from: Tools.$translate('default.from'),
			to: Tools.$translate('default.to')
		};

		this.onStartChanged = this.onChange.bind(this, 'start');
		this.onEndChanged = this.onChange.bind(this, 'end');
		this.onBlur = this.onBlur.bind(this);
		this.onDebounceOnChange = _.debounce(onChange, 250);
	}

	onChange(key, date) {
		const { filter } = this.props;
		const time = moment(date).isValid() ? moment(date).format('HH:mm') : null;
		const newState = { start: this.state.start, end: this.state.end, [key]: time };

		const error =
			newState.start &&
			newState.end &&
			moment('1990-01-01T' + newState.end).isBefore('1990-01-01T' + newState.start)
				? true
				: false;

		if (this.state.error && !error) {
			newState.error = false;
		}

		this.setState(newState, () => {
			const newFilter = _.cloneDeep(filter);
			const newValue = { start: this.state.start, end: this.state.end };
			newFilter.inactive = newValue.start === null && newValue.end === null;

			this.onDebounceOnChange(newValue, newFilter);
		});
	}

	onBlur() {
		const error =
			this.state.start &&
			this.state.end &&
			moment('1990-01-01T' + this.state.end).isBefore('1990-01-01T' + this.state.start)
				? true
				: false;
		this.setState({ error: error });
	}

	render() {
		const className = this.state.error
			? 'flex-input-group invalid drilldown time-drilldown'
			: 'flex-input-group drilldown time-drilldown';

		var fromTimepicker = (
			<Timepicker
				onChange={this.onStartChanged}
				className={'form-control time-input'}
				value={this.state.start}
				placeholder={this.lang.from}
				invalidTimeValue={null}
				onBlur={this.onBlur}
				autoFocus={true}
			/>
		);

		var toTimepicker = (
			<Timepicker
				onChange={this.onEndChanged}
				className={'form-control time-input'}
				value={this.state.end}
				placeholder={this.lang.to}
				invalidTimeValue={null}
				onBlur={this.onBlur}
			/>
		);

		return (
			<CriteriaRowWrapper>
				<div className={className}>
					{fromTimepicker}
					{toTimepicker}
				</div>
			</CriteriaRowWrapper>
		);
	}
}

TimeInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired
};

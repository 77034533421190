import GroupSizeMerge from 'Components/GroupSizeMerge';
import { replaceItem } from 'Store/helpers/array';
import { bisnodeTracker } from 'App/babel/helpers/Tracker';

angular.module('domain.soliditet').controller('FindProspectsCtrl', [
	'AppService',
	'ListViewService',
	'FilterHelper',
	'$location',
	'$scope',
	'$timeout',
	'$upModal',
	'FindProspect',
	'$multiSelect',
	'RequestBuilder',
	'Report',
	'ORG_NUMBER_MAP',
	'$safeApply',
	'CompanyTypeReport',
	'$filter',
	'$q',
	'ratioOfAccountsWithDunsNos',
	'PushNotifications',
	'clientForms',
	'Metadata',
	'FeatureHelper',
	'SoliditetClient',
	'Account',
	function (
		AppService,
		ListViewService,
		FilterHelper,
		$location,
		$scope,
		$timeout,
		$upModal,
		FindProspect,
		$multiSelect,
		RequestBuilder,
		Report,
		ORG_NUMBER_MAP,
		$safeApply,
		CompanyTypeReport,
		$filter,
		$q,
		ratioOfAccountsWithDunsNos,
		PushNotifications,
		clientForms,
		Metadata,
		FeatureHelper,
		SoliditetClient,
		Account
	) {
		var FindProspectsCtrl = this;
		var selectedViewFilterHash = '';
		var allSniCodesForSelectedCountry = null;
		var productCategories;
		var products;

		FindProspectsCtrl.init = false;
		FindProspectsCtrl.type = 'soliditet';
		FindProspectsCtrl.customerId = null;

		var employeesRanges = [
			{ to: 0, value: 0 },
			{ from: 3, to: 7, value: 5 },
			{ from: 7, to: 13, value: 10 },
			{ from: 13, to: 32, value: 25 },
			{ from: 32, to: 58, value: 50 },
			{ from: 58, to: 125, value: 100 },
			{ from: 125, to: 325, value: 250 },
			{ from: 325, to: 625, value: 500 },
			{ from: 625, value: 1000 }
		];

		var turnoverRanges = [
			{ to: 0, value: 0 },
			{ from: 5000, to: 15000, value: 10000 },
			{ from: 15000, to: 32000, value: 25000 },
			{ from: 32000, to: 62000, value: 50000 },
			{ from: 62000, to: 125000, value: 100000 },
			{ from: 125000, to: 325000, value: 250000 },
			{ from: 325000, to: 625000, value: 500000 },
			{ from: 625000, value: 1000000 }
		];

		var allCountries = [
			{ value: 'SE', name: 'countries.sweden', currency: 'SEK' },
			{ value: 'NO', name: 'countries.norway', currency: 'NOK' },
			{ value: 'FI', name: 'countries.finland', currency: 'EUR' },
			{ value: 'DK', name: 'countries.denmark', currency: 'DKK' }
		];

		var singleSelectEmployees = [
			{ id: 1, title: '1-4', start: 1, end: 4 },
			{ id: 2, title: '5-9', start: 5, end: 9 },
			{ id: 3, title: '10-19', start: 10, end: 19 },
			{ id: 4, title: '20-49', start: 20, end: 49 },
			{ id: 5, title: '50-99', start: 50, end: 99 },
			{ id: 6, title: '100-199', start: 100, end: 199 },
			{ id: 7, title: '200-499', start: 200, end: 499 },
			{ id: 8, title: '500-999', start: 500, end: 999 },
			{ id: 9, title: '1000-1499', start: 1000, end: 1499 },
			{ id: 10, title: '1500-1999', start: 1500, end: 1999 },
			{ id: 11, title: '2000-2999', start: 2000, end: 2999 },
			{ id: 12, title: '3000-3999', start: 3000, end: 3999 },
			{ id: 13, title: '4000-4999', start: 4000, end: 4999 },
			{ id: 14, title: '5000-9999', start: 5000, end: 9999 }
		];

		var reRender = function () {
			$scope.$broadcast('renderFindProspects');
		};

		var extraSelectData = {};

		$scope.$on('$multiSelect.toggle', reRender);
		$scope.$on('$multiSelect.selectAll', reRender);
		$scope.$on('$multiSelect.selectAllFilter', reRender);
		$scope.$on('$multiSelect.selectNone', reRender);

		function updateExtraSelectData(event, $multiSelect) {
			var currentClientsMap = _.reduce(
				FindProspectsCtrl.rootData.data,
				function (res, item) {
					res[item.dunsNo] = item;
					return res;
				},
				{}
			);

			extraSelectData = _.reduce(
				$multiSelect.selected,
				function (res, dunsNo) {
					var soliditetClient = currentClientsMap[dunsNo];

					if (soliditetClient) {
						res[dunsNo] = soliditetClient.upsalesClient ? true : false;
						return res;
					}

					var prevSelected = extraSelectData[dunsNo];

					if (prevSelected !== undefined) {
						res[dunsNo] = prevSelected;
					} else {
						// This should never happen
						res[dunsNo] = false;
					}

					return res;
				},
				{}
			);
		}

		$scope.$on('$multiSelect.toggle', updateExtraSelectData);
		$scope.$on('$multiSelect.selectAll', updateExtraSelectData);
		$scope.$on('$multiSelect.selectNone', function () {
			extraSelectData = {};
		});

		var viewMethods = {
			createView: function (name) {
				var view = _.cloneDeep(FindProspectsCtrl.rootData.selectedView);

				delete view.p;
				delete view.offset;

				view.id = 0;
				view.default = false;
				view.private = true;
				view.editable = true;
				view.title = name;
				view.description = '';
				view.filters = _.cloneDeep(FindProspectsCtrl.rootData.filters);

				viewMethods.saveView(view);
			},
			updateView: function () {
				var view = _.cloneDeep(FindProspectsCtrl.rootData.selectedView);

				delete view.p;
				delete view.offset;

				view.filters = _.cloneDeep(FindProspectsCtrl.rootData.filters);

				viewMethods.saveView(view);
			},
			deleteView: function (view) {
				_.pull(FindProspectsCtrl.rootData.availableViews, view);

				/* eslint-disable eqeqeq */
				if (FindProspectsCtrl.rootData.selectedView.id == view.id) {
					/* eslint-enable eqeqeq */
					viewMethods.selectView(viewMethods.findViewByIdentity());
				}

				ListViewService.delete(FindProspectsCtrl.type, view, FindProspectsCtrl.customerId);
			},
			toggleDefaultView: function (view) {
				if (view.default) {
					view.default = false;
				} else {
					view.default = true;

					FindProspectsCtrl.rootData.availableViews.forEach(function (oldView) {
						if (oldView.id !== view.id && oldView.default && oldView.private) {
							oldView.default = false;
							ListViewService.save(FindProspectsCtrl.type, oldView, FindProspectsCtrl.customerId);
						}
					});
				}
				ListViewService.save(FindProspectsCtrl.type, view, FindProspectsCtrl.customerId);
			},
			saveView: function (view) {
				ListViewService.save(FindProspectsCtrl.type, view, FindProspectsCtrl.customerId).then(function (view) {
					FindProspectsCtrl.rootData.availableViews = AppService.getListViews(FindProspectsCtrl.type);
					viewMethods.selectView(view);
				});
			},
			resetView: function () {
				viewMethods.selectView(FindProspectsCtrl.rootData.selectedView);
			},
			selectView: function (view) {
				FindProspectsCtrl.rootData.selectedView = view;

				_.each(view.filters, function (filter, filterName) {
					if (!filter.filterName) {
						filter.filterName = filterName;
					}
				});

				FindProspectsCtrl.rootData.filters = FilterHelper.parseViewFilters(
					view.filters,
					FindProspectsCtrl.type
				);

				// If we dont have a country filter in the view we default to the mainmarket
				if (!FindProspectsCtrl.rootData.filters.Country) {
					var self = AppService.getSelf();

					var mainmarket = self.userParams.dnbCountryCodes;

					if (!mainmarket) {
						var enabledCountries = AppService.getMetadata().params.DnBCountryCodes || 'SE';

						enabledCountries = enabledCountries.split(',').map(function (countryCode) {
							return countryCode.trim();
						});

						if (_.contains(enabledCountries, 'SE')) {
							mainmarket = 'SE';
						} else {
							mainmarket = enabledCountries[0];
						}
					}
					if (!_.contains(['SE', 'DK', 'FI', 'NO'], mainmarket)) {
						mainmarket = 'SE';
					}
					// TODO(Change to use Requestbuilder.comparisionTypes)
					FindProspectsCtrl.rootData.filters.Country = {
						value: mainmarket,
						comparisonType: 'Equals'
					};
				}

				if (!FindProspectsCtrl.rootData.filters.Headquarters) {
					FindProspectsCtrl.rootData.filters.Headquarters = {
						value: false
					};
				}

				// If no company type is set as default...
				if (!FindProspectsCtrl.rootData.filters.CompanyType) {
					var ab = _.find(clientForms, function (cc) {
						return cc.key === 'AB';
					});

					// set AB as default..
					FindProspectsCtrl.rootData.filters.CompanyType = {
						value: ab ? ab.value : clientForms[0].value,
						comparisonType: 'Equals'
					};
				}

				selectedViewFilterHash = FilterHelper.convertForURL(
					FindProspectsCtrl.rootData.filters,
					FindProspectsCtrl.type
				);

				FindProspectsCtrl.rootData.sorting = view.sorting;

				$location.search({ id: view.id });
				$safeApply($scope);
			},
			findViewByIdentity: function (identity) {
				var tmpView;
				var views = FindProspectsCtrl.rootData.availableViews;

				if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
					views = Tools.AppService.getListViews('soliditet');
				}

				if (identity) {
					tmpView = _.find(views, identity);
				}
				if (!tmpView) {
					tmpView = _.find(views, { default: true, private: true });
				}
				if (!tmpView) {
					tmpView = _.find(views, { default: true, editable: true });
				}
				if (!tmpView) {
					tmpView = _.find(views, { default: true });
				}
				if (!tmpView) {
					tmpView = views[0];
				}

				return _.cloneDeep(tmpView);
			},
			editView: function (view) {
				$upModal
					.open('editListView', {
						view: view,
						skipSave: true
					})
					.then(function (updatedView) {
						viewMethods.saveView(updatedView);
					});
			}
		};

		var onUrlChange = function (location, oldLocation) {
			var hash = location.hash;
			var oldHash = oldLocation && oldLocation.hash;

			var noViewSet = !FindProspectsCtrl.rootData.selectedView;
			var viewChanged = oldHash && (oldHash.id || '').toString() !== (hash.id || '').toString();
			var filtersRemoved = oldHash && oldHash.q && hash.q && !hash.q.length;

			if (noViewSet || viewChanged || filtersRemoved) {
				var id = hash.id && hash.id.length !== 32 ? parseInt(hash.id) : hash.id;
				var identity = hash.id ? { id: id } : null;

				FindProspectsCtrl.rootData.viewIsDirty = false;

				var view = viewMethods.findViewByIdentity(identity);
				viewMethods.selectView(view);
			} else {
				if (hash.q) {
					FindProspectsCtrl.rootData.filters = FilterHelper.parseFromURL(FindProspectsCtrl.type);
					FindProspectsCtrl.rootData.viewIsDirty = true;
				} else {
					FindProspectsCtrl.rootData.filters = FilterHelper.parseString(
						selectedViewFilterHash,
						FindProspectsCtrl.type
					);
					FindProspectsCtrl.rootData.viewIsDirty = false;
				}
			}

			// Dont do this if pagae or sorting changed
			if (!FindProspectsCtrl.init || (oldHash && (hash.id !== oldHash.id || hash.q !== oldHash.q))) {
				updateFilterData();
			}

			if (hash.featchingData) {
				FindProspectsCtrl.rootData.featchingData = true;
			} else {
				FindProspectsCtrl.rootData.featchingData = false;
			}

			var resetMultiSelect =
				oldHash && (hash.q !== oldHash.q || hash.id !== oldHash.id || hash.sorting !== oldHash.sorting);

			getData(resetMultiSelect);

			FindProspectsCtrl.init = true;
		};

		var watchTimeout;
		var onSettingsChange = function (obj) {
			if (!FindProspectsCtrl.init) {
				return;
			}
			if (watchTimeout) {
				$timeout.cancel(watchTimeout);
				watchTimeout = null;
			}

			watchTimeout = $timeout(function () {
				if (Object.keys(obj.filters).length) {
					var filterHash = FilterHelper.convertForURL(obj.filters, FindProspectsCtrl.type);

					if (filterHash !== selectedViewFilterHash) {
						$location.search('q', filterHash);
					} else {
						$location.search('q', null);
					}
				} else {
					$location.search('q', null);
				}

				$location.search('sorting', JSON.stringify(obj.sorting));
				$location.search('p', FindProspectsCtrl.rootData.page);
				$location.search('id', FindProspectsCtrl.rootData.selectedView.id);
			}, 20);
		};

		var mapSoliditetToExistingUpsales = function (soliditetAccounts) {
			var existing = 0;
			var dunses = _.map(soliditetAccounts, 'dunsNo');
			var dunsFilter = new RequestBuilder();
			if (!dunses.length) {
				return soliditetAccounts;
			}
			dunsFilter.addFilter(Account.attr.dunsNo, dunsFilter.comparisonTypes.Equals, dunses);
			dunsFilter.addFilter(Account.attr.isExternal, dunsFilter.comparisonTypes.Equals, false);
			return Tools.findAll(Account.customer(FindProspectsCtrl.customerId).find, dunsFilter.build()).then(
				function (upsalesAccounts) {
					_.forEach(upsalesAccounts, function (upsalesClient) {
						var index = _.findIndex(soliditetAccounts, function (soliditetClient) {
							return parseInt(upsalesClient.dunsNo) === soliditetClient.dunsNo;
						});

						if (index !== -1) {
							upsalesClient.existing = true;
							existing++;
							soliditetAccounts[index] = Object.assign({}, soliditetAccounts[index], upsalesClient);
						}
					});

					return { data: soliditetAccounts, existing: existing };
				}
			);
		};

		var openBuyModal = function (requestBuilder, total, existing) {
			var blurWrap = document.getElementById('blur-wrap');
			blurWrap.classList.add('is-blurred');
			return $upModal
				.open('confirmBuyProspects', {
					customerId: FindProspectsCtrl.customerId,
					existing: existing,
					total: total,
					customFields: _.filter(FindProspectsCtrl.rootData.customFields, function (customField) {
						return customField.alias !== 'ORG_NO' && customField.$hasAccess;
					}),
					metadata: AppService.getMetadata(),
					users: AppService.getUsers(),
					self: AppService.getSelf(),
					campaigns: [],
					categories: [],
					filters: requestBuilder.build()
				})
				.then(function () {
					blurWrap.classList.remove('is-blurred');
					$location.search('featchingData', true);
				})
				.catch(function () {
					blurWrap.classList.remove('is-blurred');
				});
		};

		var dataMethods = {
			sort: function (sort) {
				FindProspectsCtrl.rootData.page = 1;
				FindProspectsCtrl.rootData.sorting = [sort];
				$safeApply($scope);
			},
			showGroupTree: function (client) {
				FindProspectsCtrl.rootData.selectedGroupRootDuns = client.rootParentDuns
					? client.rootParentDuns
					: client.dunsNo;
				FindProspectsCtrl.rootData.openingGroup = client.dunsNo;

				var rb = new RequestBuilder();
				var or = rb.orBuilder();
				or.next();
				or.addFilter(
					SoliditetClient.attr.rootParentDuns,
					rb.comparisonTypes.Equals,
					FindProspectsCtrl.rootData.selectedGroupRootDuns
				);
				or.next();
				or.addFilter(
					SoliditetClient.attr.dunsNo,
					rb.comparisonTypes.Equals,
					FindProspectsCtrl.rootData.selectedGroupRootDuns
				);
				or.done();

				$safeApply($scope);

				Tools.findAll(SoliditetClient.customer(FindProspectsCtrl.customerId).find, rb.build())
					.then(mapSoliditetToExistingUpsales)
					.then(function (res) {
						FindProspectsCtrl.rootData.selectedGroupClient = client;
						FindProspectsCtrl.rootData.selectedGroupData = res.data;
						FindProspectsCtrl.rootData.selectedGroupExisting = res.existing;
					})
					.finally(function () {
						FindProspectsCtrl.rootData.openingGroup = null;
						$safeApply($scope);
					});
			},
			buyClient: function (client) {
				var rb = RequestBuilder();

				// TODO(use attribute)
				rb.addFilter({ field: 'dunsNo' }, rb.comparisonTypes.Equals, client.dunsNo);

				dataMethods.buy(rb, client);
			},
			updateClient: function (item) {
				$upModal
					.open('confirmSoliditetUpdate', {
						account: item.upsalesClient,
						name: item.name,
						customerId: FindProspectsCtrl.customerId,
						accountId: item.upsalesClient.id,
						duns: item.dunsNo
					})
					.then(function () {
						FindProspectsCtrl.rootData.tableOptions.boughtDunsNos.push(item.dunsNo);
					});
			},
			updateClientWithDunsNo: function (item) {
				$upModal.open('UpdateClientDuns', {
					name: item.name,
					customerId: FindProspectsCtrl.customerId,
					dunsNo: item.dunsNo
				});
			},
			buySelected: function () {
				var requestBuilder = FilterHelper.parseFilters(
					FindProspectsCtrl.rootData.filters,
					FindProspectsCtrl.type
				);
				var total = FindProspectsCtrl.rootData.totalClientMatches;
				var existing = FindProspectsCtrl.rootData.totalExistingClientMatches;

				if ($multiSelect.selected.length) {
					requestBuilder.addFilter(
						{ field: 'dunsNo' },
						requestBuilder.comparisonTypes.Equals,
						$multiSelect.selected
					);
					total = $multiSelect.selected.length;
					existing = _.reduce(
						extraSelectData,
						function (sum, value) {
							return sum + value;
						},
						0
					);
				}

				var keyMap = [
					{ key: 'companyType.id', value: 'company_form' },
					{ key: 'noOfEmployeesExact', value: 'employeesExact' },
					{ key: 'noOfEmployeesStart', value: 'employeesStart' },
					{ key: 'noOfEmployeesEnd', value: 'employeesEnd' }
				];

				keyMap.forEach(function (obj) {
					var filter = requestBuilder.findFilter(obj.key);

					if (filter !== undefined) {
						filter.a = obj.value;
					}
				});

				openBuyModal(requestBuilder, total, existing).then(() => {
					bisnodeTracker.track(bisnodeTracker.events.BUY_MULTI, { from: 'findProspects', total });
				});
			},
			buyFromTree: function (selected) {
				var requestBuilder = new RequestBuilder();
				var total = FindProspectsCtrl.rootData.selectedGroupData.length;
				var existing = FindProspectsCtrl.rootData.selectedGroupExisting;

				requestBuilder.addFilter(
					{ field: 'dunsNo' },
					requestBuilder.comparisonTypes.Equals,
					selected.length ? selected : _.pluck(FindProspectsCtrl.rootData.selectedGroupData, 'dunsNo')
				);
				total = selected.length ? selected.length : FindProspectsCtrl.rootData.selectedGroupData.length;
				existing = _.reduce(
					FindProspectsCtrl.rootData.selectedGroupData,
					function (sum, client) {
						if (!selected.length || (selected.length && selected.indexOf(parseInt(client.dunsNo)) !== -1)) {
							return sum + (client.id ? 1 : 0);
						} else if (!selected.length) {
							return sum + (client.id ? 1 : 0);
						}
						return sum;
					},
					0
				);

				openBuyModal(requestBuilder, total, existing).then(() => {
					bisnodeTracker.track(bisnodeTracker.events[selected.length ? 'BUY_MULTI' : 'BUY_ALL_GROUP'], {
						from: 'findProspectsTree',
						total
					});
				});
			},
			buy: function (requestBuilder, client) {
				var blurWrap = document.getElementById('blur-wrap');
				blurWrap.classList.add('is-blurred');

				$upModal
					.open('confirmBuyProspect', {
						customerId: FindProspectsCtrl.customerId,
						existing: 0,
						total: FindProspectsCtrl.rootData.totalClientMatches,
						customFields: _.filter(FindProspectsCtrl.rootData.customFields, function (customField) {
							return customField.alias !== 'ORG_NO' && customField.$hasAccess;
						}),
						metadata: AppService.getMetadata(),
						users: AppService.getUsers(),
						self: AppService.getSelf(),
						campaigns: [],
						categories: [],
						filters: requestBuilder.build()
					})
					.then(function () {
						FindProspectsCtrl.rootData.tableOptions.boughtDunsNos.push(client.dunsNo);

						bisnodeTracker.track(bisnodeTracker.events.BUY_SINGLE, { from: 'findProspects' });

						blurWrap.classList.remove('is-blurred');
					})
					.catch(function () {
						blurWrap.classList.remove('is-blurred');
					});
			}
		};

		var paginatorChange = function (page) {
			FindProspectsCtrl.rootData.page = page;
			$safeApply($scope);
		};

		var timeout = null;

		function getData(resetMultiSelect) {
			if (timeout) {
				clearTimeout(timeout);
			}

			timeout = setTimeout(function () {
				FindProspectsCtrl.rootData.tableLoading = true;

				if (resetMultiSelect) {
					$multiSelect.selectNone();
				}

				var requestBuilder = FilterHelper.parseFilters(
					FindProspectsCtrl.rootData.filters,
					FindProspectsCtrl.type
				);
				// TODO(use attribute)
				requestBuilder.limit = FindProspectsCtrl.rootData.limit;
				requestBuilder.offset = (FindProspectsCtrl.rootData.page - 1) * FindProspectsCtrl.rootData.limit;
				requestBuilder.sorting = FilterHelper.parseSort(
					FindProspectsCtrl.rootData.sorting,
					FindProspectsCtrl.type
				);

				if (
					requestBuilder.sorting &&
					requestBuilder.sorting.length &&
					requestBuilder.sorting[0].attribute === 'noOfEmployeesExact'
				) {
					requestBuilder.sorting[0].attribute = 'noOfEmployeesStart';
				}

				if (FindProspectsCtrl.rootData.NotInUpsales) {
					requestBuilder.addExtraParam('NotInUpsales', true);
				}

				FindProspect.find(requestBuilder.build())
					.then(function (res) {
						FindProspectsCtrl.rootData.data = res.data;
						FindProspectsCtrl.rootData.totalClientMatches = res.metadata.total;
						FindProspectsCtrl.rootData.totalExistingClientMatches = res.metadata.existing;

						if (
							FindProspectsCtrl.rootData.totalClientMatches > FindProspectsCtrl.rootData.NotInUpsalesLimit
						) {
							FindProspectsCtrl.rootData.NotInUpsales = false;
						}

						FindProspectsCtrl.rootData.tableLoading = false;
					})
					.catch(function () {
						FindProspectsCtrl.rootData.tableLoading = false;
						FindProspectsCtrl.rootData.error = true;
					});
			}, 50);
		}

		var getDatePresets = function () {
			var translate = $filter('translate');
			var presets = ['whenever', 'prev12Month', 'prev3Year'];

			return presets.map(function (preset) {
				var name = translate('date.' + preset);

				return {
					name: name,
					id: preset
				};
			});
		};

		var toggleGeoSort = function () {
			FindProspectsCtrl.rootData.geoSort = FindProspectsCtrl.rootData.geoSort ? false : true;
			FindProspectsCtrl.rootData.stateAndCity = getStateAndCity();
		};

		function getStateAndCity() {
			var filters = _.omit(FindProspectsCtrl.rootData.filters, ['CityAndState']);
			var requestBuilder = new RequestBuilder();
			FilterHelper.parseFilters(filters, FindProspectsCtrl.type, requestBuilder);

			var stateAggs = requestBuilder.aggregationBuilder();
			stateAggs.aggregationSize(1000);
			stateAggs.addAggregation(requestBuilder.aggregationTypes.Terms, 'state');
			//stateAggs.aggregationOrder(FindProspectsCtrl.rootData.geoSortType, !FindProspectsCtrl.rootData.geoSort);
			stateAggs.aggregationOrder('_count', !FindProspectsCtrl.rootData.geoSort);

			var cityAggs = stateAggs.aggregationBuilder();
			cityAggs.aggregationSize(1000);
			cityAggs.addAggregation(requestBuilder.aggregationTypes.Terms, 'city');
			//cityAggs.aggregationOrder(FindProspectsCtrl.rootData.geoSortType, !FindProspectsCtrl.rootData.geoSort);
			cityAggs.aggregationOrder('_count', !FindProspectsCtrl.rootData.geoSort);
			cityAggs.done();
			stateAggs.done();

			return Report.customer(FindProspectsCtrl.customerId)
				.setType(Report.type.SOLIDITET)
				.find(requestBuilder.build())
				.then(function (res) {
					var data = _.map(res.data.group_by_state.buckets, function (state) {
						var item = {
							stateName: state.key,
							amount: state.doc_count
						};

						item.children = _.map(state.group_by_city.buckets, function (city) {
							return { cityName: city.key, amount: city.doc_count };
						});

						item.children = _.filter(item.children, function (cc) {
							return cc.cityName && cc.cityName.length;
						});

						return item;
					});

					data = _.filter(data, function (ii) {
						return ii.stateName && ii.stateName.length;
					});

					return { data: data };
				});
		}

		var rangeGraphConfig = {
			chart: {
				type: 'areaspline',
				height: 150,
				backgroundColor: 'transparent',
				marginLeft: -9
			},
			title: {
				text: ''
			},
			tooltip: {
				enabled: false
			},
			credits: { enabled: false },
			legend: {
				enabled: false
			},
			xAxis: {
				allowDecimals: false,
				lineColor: 'transparent',
				tickLength: 1,
				tickWidth: 1,

				labels: {
					y: 30,
					autoRotation: false
				}
			},
			yAxis: {
				gridLineWidth: 0,
				title: {
					text: ''
				},
				labels: {
					enabled: false
				}
			},
			plotOptions: {
				areaspline: {
					pointStart: 0,
					marker: {
						enabled: false
					}
				}
			},
			series: [
				{
					name: 'Range-input',
					color: 'blue',
					data: [],
					zoneAxis: 'x',
					enableMouseTracking: false,
					zones: [
						{
							value: 2,
							color: '#ccc'
						},
						{
							value: 4,
							color: '#4591E3',
							fillColor: '#4591E3'
						},
						{
							color: '#ccc'
						}
					]
				}
			]
		};

		var getRanges = function () {
			/***** Employee filter *****/

			var employeeFilters = _.omit(FindProspectsCtrl.rootData.filters, ['Employees']);
			var rbEmployees = new RequestBuilder();
			FilterHelper.parseFilters(employeeFilters, FindProspectsCtrl.type, rbEmployees);

			var employeeAggs = rbEmployees.aggregationBuilder();
			employeeAggs.aggregationSize(1000);
			employeeAggs.addAggregation(rbEmployees.aggregationTypes.Range, 'noOfEmployeesExact');
			employeeAggs.aggregationInterval(employeesRanges);
			employeeAggs.done();

			/***** Turnover filter *****/

			var turnoverFilters = _.omit(FindProspectsCtrl.rootData.filters, ['Turnover']);
			var rbTurnover = new RequestBuilder();
			FilterHelper.parseFilters(turnoverFilters, FindProspectsCtrl.type, rbTurnover);
			var turnoverAggs = rbTurnover.aggregationBuilder();
			turnoverAggs.aggregationSize(1000);
			turnoverAggs.addAggregation(rbTurnover.aggregationTypes.Range, 'turnover');
			turnoverAggs.aggregationInterval(turnoverRanges);
			turnoverAggs.done();
			turnoverAggs.done();

			var promises = {
				employees: Report.customer(FindProspectsCtrl.customerId)
					.setType(Report.type.SOLIDITET)
					.find(rbEmployees.build()),
				turnover: Report.customer(FindProspectsCtrl.customerId)
					.setType(Report.type.SOLIDITET)
					.find(rbTurnover.build())
			};

			return $q.all(promises).then(function (res) {
				var result = {};

				if (
					res.employees.data &&
					res.employees.data.range_noOfEmployeesExact &&
					res.employees.data.range_noOfEmployeesExact.buckets
				) {
					result.employees = _.cloneDeep(rangeGraphConfig);
					result.employees.series = mapSeries(
						result.employees.series,
						res.employees.data.range_noOfEmployeesExact.buckets
					);
					result.employees.xAxis.categories = mapCategories(employeesRanges);
				}

				if (res.turnover.data && res.turnover.data.range_turnover && res.turnover.data.range_turnover.buckets) {
					result.turnover = _.cloneDeep(rangeGraphConfig);
					result.turnover.series = mapSeries(
						result.turnover.series,
						res.turnover.data.range_turnover.buckets
					);
					result.turnover.xAxis.categories = mapCategories(turnoverRanges, true);
				}

				return result;
			});
		};

		function mapSeries(series, buckets) {
			var docCount = 0;
			var res = _.map(buckets, function (bucket) {
				docCount += bucket.doc_count;
				return bucket.doc_count;
			});
			series[0].data = res;

			if (docCount < 1) {
				series.push({
					data: _.range(1, series[0].data.length, 0),
					lineWidth: 0,
					fillOpacity: 0,
					enableMouseTracking: false
				});
			}

			return series;
		}

		function mapCategories(ranges, convertToMillion) {
			return _.map(ranges, function (range) {
				var value = range.value;
				if (convertToMillion) {
					value = value / 1000;
				}

				if (!range.to && range.from) {
					return value + '+';
				}
				return value;
			});
		}

		var getCountries = function () {
			var enabledCountries = AppService.getMetadata().params.DnBCountryCodes;

			if (!enabledCountries) {
				return allCountries;
			} else {
				enabledCountries = enabledCountries.split(',').map(function (countryCode) {
					return countryCode.trim();
				});

				var filteredCountries = allCountries.filter(function (country) {
					return _.includes(enabledCountries, country.value);
				});

				return filteredCountries.length ? filteredCountries : _.filter(allCountries, { value: 'SE' });
			}
		};

		var filterChanges = function (filterName, filter) {
			if (filterName === 'clearZipCodeAndState') {
				delete FindProspectsCtrl.rootData.filters.CityAndState;
				delete FindProspectsCtrl.rootData.filters.ZipCode;

				$safeApply($scope);
				return;
			}

			if (filter.value && filter.value.start === 0 && !filter.value.end) {
				filter.value.start = undefined;
			}

			if (filterName === 'Country' || filterName === 'CompanyType') {
				for (var key in FindProspectsCtrl.rootData.filters) {
					if (key !== 'Country' && key !== 'CompanyType') {
						delete FindProspectsCtrl.rootData.filters[key];
					}
				}
			}

			FindProspectsCtrl.rootData.filters[filterName] = filter;
			FindProspectsCtrl.rootData.page = 1;
			if (FindProspectsCtrl.rootData.initialRender) {
				FindProspectsCtrl.rootData.initialRender = false; // Only once
			}

			$safeApply($scope);
		};

		var getSniGroup = function (sniCode) {
			var key = parseInt(sniCode).toString();

			if (key.length === 4) {
				key = key.substr(0, 1);
			} else {
				key = key.substr(0, 2);
			}

			var keyAsInt = parseInt(key);

			return _.findIndex(ORG_NUMBER_MAP, function (group) {
				return group.isInGroup(keyAsInt);
			});
		};

		var waitingForData = false;
		var pendingRequest = false;
		function updateFilterData() {
			if (waitingForData) {
				pendingRequest = true;
				return;
			}

			waitingForData = true;

			var sniCodes = $q.defer();
			var sniCodesWithSales = $q.defer();
			var stateAndCity = $q.defer();
			var employeeAndTurnoverRanges = $q.defer();

			FindProspectsCtrl.rootData.sniCodes = sniCodes.promise;
			FindProspectsCtrl.rootData.sniCodesWithSales = sniCodesWithSales.promise;
			FindProspectsCtrl.rootData.stateAndCity = stateAndCity.promise;
			FindProspectsCtrl.rootData.employeeAndTurnoverRanges = employeeAndTurnoverRanges.promise;

			var promises = {
				sniCodes: getSniCodes(),
				sniCodesWithSales: getSniCodesWithSales(),
				stateAndCity: getStateAndCity(),
				employeeAndTurnoverRanges: getRanges()
			};

			$q.all(promises).then(function (res) {
				sniCodes.resolve(res.sniCodes);
				sniCodesWithSales.resolve(res.sniCodesWithSales);
				stateAndCity.resolve(res.stateAndCity);
				employeeAndTurnoverRanges.resolve(res.employeeAndTurnoverRanges);

				waitingForData = false;

				if (pendingRequest) {
					pendingRequest = false;
					updateFilterData();
				}
			});
		}

		var mapStringToClients = function (string) {
			var nameSeperator = '<ns>';
			var idSeperator = '<is>';

			return string.split(nameSeperator).map(function (client) {
				var nameId = client.split(idSeperator);

				return {
					name: nameId[0],
					id: nameId[1] || null
				};
			});
		};

		function getSniCodesWithSales() {
			var filters = _.omit(FindProspectsCtrl.rootData.filters, ['SniCode']);
			var requestBuilder = FilterHelper.parseFilters(filters, FindProspectsCtrl.type);
			FilterHelper.parseFilters(FindProspectsCtrl.rootData.sniFilters, FindProspectsCtrl.type, requestBuilder);

			return CompanyTypeReport.customer()
				.find(requestBuilder.build())
				.then(function (res) {
					var currencies = _.reduce(
						AppService.getMetadata().customerCurrencies,
						function (obj, currency) {
							obj[currency.iso] = currency;
							return obj;
						},
						{}
					);

					var masterCurrency = _.find(AppService.getMetadata().customerCurrencies, { masterCurrency: true });

					var data = _.reduce(
						res.data,
						function (res, value) {
							if (!(value.sni_code > 0)) {
								return res;
							}

							var currency = value.currency || masterCurrency.iso;

							if (res[value.sni_code]) {
								res[value.sni_code].names = res[value.sni_code].names.concat(
									mapStringToClients(value.names)
								);
								res[value.sni_code].orderValue += value.orderValue / currencies[currency].rate;
							} else {
								res[value.sni_code] = {
									names: mapStringToClients(value.names),
									orderValue: value.orderValue,
									sni_code: value.sni_code,
									sni_text: value.sni_text
								};
							}

							return res;
						},
						{}
					);

					_.each(data, function (obj) {
						obj.names = _.unique(obj.names, function (client) {
							return client.id;
						});

						obj.names = _.sortBy(obj.names, 'name');
					});

					return data;
				});
		}

		var getAllSniCodesForCountry = function () {
			var allSniCodesRb = new RequestBuilder();

			var countryAggs = allSniCodesRb.aggregationBuilder();
			countryAggs.addAggregation(allSniCodesRb.aggregationTypes.Terms, 'country');

			var sniCodeAggs = countryAggs.aggregationBuilder();
			sniCodeAggs.aggregationSize(1500);
			sniCodeAggs.addAggregation(allSniCodesRb.aggregationTypes.Terms, 'sniCode');

			var sniTextAggs = sniCodeAggs.aggregationBuilder();
			sniTextAggs.addAggregation(allSniCodesRb.aggregationTypes.Terms, 'sniText');

			sniTextAggs.done();
			sniCodeAggs.done();
			countryAggs.done();

			return Report.customer(FindProspectsCtrl.customerId)
				.setType(Report.type.SOLIDITET)
				.find(allSniCodesRb.build())
				.then(function (res) {
					var mappedData = {};

					_.each(res.data?.group_by_country?.buckets ?? [], function (obj) {
						mappedData[obj.key.toUpperCase()] = obj.group_by_sniCode.buckets;
					});

					return mappedData;
				});
		};

		function getSniCodes() {
			var filters = _.omit(FindProspectsCtrl.rootData.filters, ['SniCode']);
			var sniFilters = _.pick(FindProspectsCtrl.rootData.filters, ['SniCode']);

			var requestBuilder = null;

			if (sniFilters && Array.isArray(sniFilters.value)) {
				var rb = new RequestBuilder();
				var OrBuilder = rb.orBuilder();

				OrBuilder.next();
				OrBuilder.addFilter({ field: 'sniCode' }, rb.comparisonTypes.Equals, sniFilters.value);

				OrBuilder.next();
				FilterHelper.parseFilters(filters, FindProspectsCtrl.type, OrBuilder);

				OrBuilder.done();

				requestBuilder = rb;
			} else {
				requestBuilder = FilterHelper.parseFilters(filters, FindProspectsCtrl.type);
			}

			// TODO(Use attributes)
			// Max number if sniCodes -> 1234
			var sniCodeAggs = requestBuilder.aggregationBuilder();
			sniCodeAggs.aggregationSize(1500);
			sniCodeAggs.addAggregation(requestBuilder.aggregationTypes.Terms, 'sniCode');

			var sniTextAggs = sniCodeAggs.aggregationBuilder();
			sniTextAggs.addAggregation(requestBuilder.aggregationTypes.Terms, 'sniText');

			sniTextAggs.done();
			sniCodeAggs.done();

			var promises = {
				filtered: Report.customer(FindProspectsCtrl.customerId)
					.setType(Report.type.SOLIDITET)
					.find(requestBuilder.build()),
				all: allSniCodesForSelectedCountry
			};

			return $q.all(promises).then(function (res) {
				var data = _.map(ORG_NUMBER_MAP, function (orgGroup) {
					return {
						name: orgGroup.name,
						children: [],
						value: 0
					};
				});

				_.each(res.filtered.data.group_by_sniCode.buckets, function (sniCode) {
					var index = getSniGroup(sniCode.key);

					if (index !== -1 && sniCode.group_by_sniText.buckets[0]) {
						const sniCodeKey = sniCode.key || '';
						const sniCodeArr = Array.from(sniCodeKey);
						let translatedName = Tools.$translate('default.sniCode') + ' ' + sniCodeKey;

						for (let i = sniCodeArr.length - 1; i >= 0; i--) {
							const code = sniCodeArr.join('');
							const sniTranslation = Tools.$translate(`default.sniCode.${code}`);
							if (sniTranslation.startsWith('default.sniCode.')) {
								// no translation exists for this code, remove last digit and try again
								sniCodeArr[i] = '0';
							} else {
								translatedName = sniTranslation.substring(8, sniTranslation.length);
								break;
							}
						}
						data[index].children.push({
							name: translatedName,
							value: sniCode.doc_count,
							id: sniCode.key
						});
					} else {
						//console.log('[Error] Could not find group for snicode: ', sniCode);
					}
				});

				var currentCountry = FindProspectsCtrl.rootData.filters.Country
					? FindProspectsCtrl.rootData.filters.Country.value
					: 'SE';

				_.each(res.all[currentCountry], function (sniCode) {
					var index = getSniGroup(sniCode.key);

					if (index !== -1 && sniCode.group_by_sniText.buckets[0]) {
						var found = _.find(data[index].children, { id: sniCode.key });

						if (found === undefined) {
							data[index].children.push({
								name: sniCode.group_by_sniText.buckets[0].key,
								value: 0,
								id: sniCode.key
							});
						}
					}
				});

				_.each(data, function (orgGroup) {
					orgGroup.value = _.reduce(
						orgGroup.children,
						function (sum, orgNo) {
							return sum + orgNo.value;
						},
						0
					);
				});

				return data;
			});
		}

		var buildParentRoleTree = function () {
			var roles = AppService.getRoles();
			var rolesById = _.reduce(
				roles,
				function (rolesById, role) {
					rolesById[role.id] = {
						id: role.id,
						parent: null
					};

					return rolesById;
				},
				{}
			);

			_.each(roles, function (role) {
				if (role.parent && rolesById[role.id]) {
					rolesById[role.id].parent = rolesById[role.parent.id];
				}
			});

			return _.filter(rolesById, function (role) {
				return role.parent === null;
			});
		};

		var buildRoleTree = function () {
			var roles = AppService.getRoles();
			var rolesById = _.reduce(
				roles,
				function (rolesById, role) {
					rolesById[role.id] = {
						name: role.name,
						id: role.id,
						parent: role.parent ? role.parent.id : null,
						children: []
					};

					return rolesById;
				},
				{}
			);

			_.each(roles, function (role) {
				if (role.parent && rolesById[role.parent.id]) {
					rolesById[role.parent.id].children.push(rolesById[role.id]);
				}
			});

			return _.filter(rolesById, function (role) {
				return role.parent === null;
			});
		};

		var productSearch = function (searchString) {
			if (searchString) {
				var filteredProducts = _.filter(products, function (pro) {
					return pro.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
				});
				FindProspectsCtrl.rootData.productTree = buildProductTree(filteredProducts, searchString);
			} else {
				FindProspectsCtrl.rootData.productTree = [];
			}

			$safeApply($scope);
		};

		var filterProductCategories = function (array, searchString) {
			return array.filter(function (obj) {
				if (obj.type === 'product') {
					return true;
				} else {
					obj.children = filterProductCategories(obj.children, searchString);

					if (obj.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || obj.children.length) {
						return true;
					} else {
						return false;
					}
				}
			});
		};

		function buildProductTree(products, searchString) {
			var productCategoriesById = _.reduce(
				productCategories,
				function (productCategoriesById, category) {
					productCategoriesById[category.id] = {
						id: category.id,
						type: 'productCategory',
						name: category.name,
						sortId: category.sortId,
						children: [],
						parentId: category.parentId
					};

					return productCategoriesById;
				},
				{}
			);

			var topLvlProducts = [];

			_.each(products, function (product) {
				if (product.category && productCategoriesById[product.category.id]) {
					productCategoriesById[product.category.id].children.push({
						id: product.id,
						type: 'product',
						name: product.name,
						sortId: product.sortId
					});
				} else if (!product.category || product.category.id === 0) {
					topLvlProducts.push({
						id: product.id,
						type: 'product',
						name: product.name,
						sortId: product.sortId
					});
				}
			});

			_.each(productCategoriesById, function (category) {
				if (category.parentId && productCategoriesById[category.parentId]) {
					productCategoriesById[category.parentId].children.push(category);
				}
			});

			var topLvlCategories = _.filter(productCategoriesById, function (category) {
				return category.parentId === 0;
			});

			if (searchString) {
				topLvlCategories = filterProductCategories(topLvlCategories, searchString);
			}

			return topLvlProducts.concat(topLvlCategories);
		}

		function merge(addingAccount, upsalesAccount, pricingKey, resolve) {
			const customerId = AppService.getCustomerId();
			const action = {
				action: 'buy',
				id: upsalesAccount.id,
				dunsNo: addingAccount.account.dunsNo
			};

			bisnodeTracker.track(bisnodeTracker.events.MERGE_SINGLE, { from: 'findProspects' });

			return SoliditetClient.customer(customerId)
				.updateMatches({ buy: [action] })
				.then(() => {
					FindProspectsCtrl.rootData.addingAccount = {
						externalId: addingAccount.account.dunsNo,
						existing: true,
						id: upsalesAccount.id
					};
					const i = FindProspectsCtrl.rootData.selectedGroupData.findIndex(
						c => parseInt(c.dunsNo) === parseInt(addingAccount.account.dunsNo)
					);
					if (i !== -1) {
						FindProspectsCtrl.rootData.selectedGroupData = replaceItem(
							FindProspectsCtrl.rootData.selectedGroupData,
							i,
							{
								...FindProspectsCtrl.rootData.selectedGroupData[i],
								existing: true,
								id: upsalesAccount.id
							}
						);
					}
					$safeApply($scope);
					if (resolve) {
						resolve();
					}
				});
		}

		FindProspectsCtrl.rootData = {
			mainMarket: null,
			/** For view picker **/
			viewMethods: viewMethods,
			selectedView: null,
			availableViews: null,
			viewIsDirty: false,

			/** For table **/
			dataMethods: dataMethods,
			attributes: null,
			tableLoading: false,
			error: false,
			data: [],
			tableOptions: {
				type: 'soliditet',
				hasCheck: true,
				editable: false,
				buyClient: dataMethods.buyClient,
				updateClient: dataMethods.updateClient,
				updateClientWithDunsNo: dataMethods.updateClientWithDunsNo,
				showGroupTree: dataMethods.showGroupTree,
				boughtDunsNos: [],
				multiSelectOptions: {
					selectNone: true,
					selectAll: true
				},
				idField: 'dunsNo'
			},
			customFields: null,
			sorting: null,
			boughtClients: [],
			totalClientMatches: 10000,
			totalExistingClientMatches: 1000,
			page: 1,
			limit: 100,

			/** sidebar */
			filters: {},
			sniFilters: {
				Date: {
					value: {
						preset: 'prev12Month'
					}
				}
			},
			filterChange: filterChanges,
			getFilterData: function (filterName) {
				return FindProspectsCtrl.rootData.filters[filterName];
			},
			sniFilterChange: function (filterName, filter) {
				FindProspectsCtrl.rootData.sniFilters[filterName] = filter;
				FindProspectsCtrl.rootData.sniCodesWithSales = getSniCodesWithSales();
			},
			countries: getCountries(),
			corporateForm: clientForms,
			datePresets: null,
			roleTree: null,
			productTree: null,
			productSearch: productSearch,
			useProductSearch: false,
			sniCodes: null,
			sniCodesWithSales: null,
			showStartPlaceholder: false,
			toggleGeoSort: toggleGeoSort,
			geoSort: true,
			smallSize: false,
			//geoSortType: '_term',
			stateAndCity: null,
			paginatorChange: paginatorChange,
			employeesRanges: employeesRanges,
			turnoverRanges: turnoverRanges,
			singleSelectEmployees: singleSelectEmployees,
			NotInUpsales: false,
			NotInUpsalesLimit: 10000,
			updateNotInUpsales: function (value) {
				FindProspectsCtrl.rootData.NotInUpsales = value;
				FindProspectsCtrl.rootData.page = 1;
				getData();
			},
			featchingData: false,
			ratioOfAccountsWithDunsNos: ratioOfAccountsWithDunsNos,
			acceptedRatioOfAccountsWithDunsNos: 0.99,
			acceptedToProceedWithLowDunsNoRatio: false,
			acceptToProcedWithLowDunsNoRatio: function () {
				FindProspectsCtrl.rootData.acceptedToProceedWithLowDunsNoRatio = true;
			},
			employeeAndTurnoverRanges: null,
			columns: null,
			openingGroup: null,
			selectedGroupClient: null,
			selectedGroupData: [],
			selectedGroupRootDuns: null,
			initialRender: true,
			closeGroup: function () {
				FindProspectsCtrl.rootData.selectedGroupClient = null;
				FindProspectsCtrl.rootData.selectedGroupData = [];
				FindProspectsCtrl.rootData.selectedGroupRootDuns = null;
				$safeApply($scope);
			},
			beginMerge: function (dataSourceId, newAccount) {
				$upModal.open('generic', {
					addingAccount: { account: newAccount, dataSourceId },
					accounts: {
						soliditet: {
							pricing: null
						}
					},
					className: 'group-size-merge',
					fullscreen: true,
					Component: GroupSizeMerge,
					actions: {
						merge: merge
					}
				});
			}
		};

		var updateColumns = function () {
			FindProspectsCtrl.rootData.width = $(window).width();
			FindProspectsCtrl.rootData.columns = ['name', 'turnover', 'noOfEmployeesExact', 'sniText', 'city'];

			if (FindProspectsCtrl.rootData.width < 1500) {
				FindProspectsCtrl.rootData.columns = FindProspectsCtrl.rootData.columns.filter(function (column) {
					return column !== 'sniText';
				});
			}
			if (FindProspectsCtrl.rootData.width < 1300) {
				FindProspectsCtrl.rootData.smallSize = true;
				rangeGraphConfig.xAxis.labels.style = { fontSize: '8px' };
			} else {
				rangeGraphConfig.xAxis.labels.style = { fontSize: '11px' };
			}
		};

		var resizeTimeout = null;
		var resizeThrottler = function () {
			if (!resizeTimeout) {
				resizeTimeout = setTimeout(function () {
					resizeTimeout = null;
					updateColumns();

					$safeApply($scope);
				}, 66);
			}
		};

		var init = function () {
			$multiSelect.selectNone();

			productCategories = AppService.getProductCategories();
			products = _.filter(AppService.getProducts(), function (product) {
				return product.active === 1;
			});

			FindProspectsCtrl.customerId = AppService.getCustomerId();
			FindProspectsCtrl.rootData.attributes = ListViewService.getAttrs(FindProspectsCtrl.type);
			FindProspectsCtrl.rootData.availableViews = AppService.getListViews(FindProspectsCtrl.type);
			FindProspectsCtrl.rootData.customFields = AppService.getCustomFields('account');
			FindProspectsCtrl.rootData.datePresets = getDatePresets();
			FindProspectsCtrl.rootData.hasBranchWithSalesRights = FeatureHelper.isAvailable(
				FeatureHelper.Feature.SOLIDITET_AUTOMATION
			);
			if (!FindProspectsCtrl.rootData.hasBranchWithSalesRights) {
				var accountSelf = AppService.getAccountSelf();
				FindProspectsCtrl.rootData.accountManager = accountSelf.accountManager;
			}

			allSniCodesForSelectedCountry = getAllSniCodesForCountry();

			if (products.length > 1000) {
				FindProspectsCtrl.rootData.useProductSearch = true;
			} else {
				FindProspectsCtrl.rootData.productTree = buildProductTree(products);
			}

			FindProspectsCtrl.rootData.roleTree = buildRoleTree();
			FindProspectsCtrl.rootData.roleParentTree = buildParentRoleTree();

			var notificationFilters = new RequestBuilder();
			notificationFilters.addFilter(
				{ field: 'date' },
				notificationFilters.comparisonTypes.GreaterThanEquals,
				moment().utc().startOf('day').format('YYYY-MM-DD')
			);

			PushNotifications.customer(FindProspectsCtrl.customerId)
				.find(notificationFilters.build())
				.then(function (res) {
					var shown = false;

					_.each(res.data, function (notification) {
						if (notification.action === 'soliditet-buy-multiple-clients' && notification.status !== 100) {
							if (!shown) {
								shown = true;

								var translate = $filter('translate');
								var name = notification.registeredBy ? notification.registeredBy.name : '';

								$upModal.open('warningAlert', {
									title: 'default.warning',
									body: name + translate('soliditet.soliditetWarning')
								});
							}
						}
					});

					$scope.$on('soliditetBuyMultipleClientsNotification', function (event, notification) {
						if (notification.status === 100) {
							Metadata.customer(FindProspectsCtrl.customerId)
								.get()
								.then(function (res) {
									AppService.setMetadata(res);
								});
						}
					});
				});

			updateColumns();

			window.addEventListener('resize', resizeThrottler);

			$scope.$on('$destroy', function () {
				window.removeEventListener('resize', resizeThrottler);
			});

			// Watch for filter and sort changes and then update url
			$scope.$watch(
				function () {
					return {
						filters: FindProspectsCtrl.rootData.filters,
						sorting: FindProspectsCtrl.rootData.sorting,
						page: FindProspectsCtrl.rootData.page
					};
				},
				onSettingsChange,
				true
			);

			var ogPath = null;
			// This is triggered when the url hash updates
			$scope.$watch(
				function () {
					return {
						hash: $location.search(),
						path: $location.$$path
					};
				},
				function (location, oldLocation) {
					// Only trigger change if we still are on the same page
					if (!ogPath) {
						ogPath = location.path;
						oldLocation = null;
					}
					if (location.path === ogPath) {
						onUrlChange(location, oldLocation);
					}
				},
				true
			);
		};

		AppService.loadedPromise.then(init);
	}
]);

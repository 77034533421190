import React, { useRef, useState, MutableRefObject, useEffect } from 'react';
import { Row, Column, DateInput, Button, TimeInput, Icon, Tooltip, Label, IconName } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';

import T from 'Components/Helpers/translate';

import { extendMoment } from 'moment-range';
import moment, * as Moment from 'moment';
const range = extendMoment(Moment);

import './DateRangeInput.scss';
import { SlideFade } from '../animations';

interface CalendarEvent {
	start: Date;
	end: Date;
}

interface DateRangeInputProps {
	start: Date;
	end: Date;
	onChangeStart: (type: 'date' | 'time', date: Date) => void;
	onChangeEnd: (type: 'date' | 'time', date: Date) => void;
	anchor?: HTMLElement;
	scrollContainer?: HTMLElement;
	events?: CalendarEvent[];
	fetchEvents?: (month: Date) => void;
	disabled?: boolean;
	autofocus?: boolean;
	startDateRef?: React.ForwardedRef<HTMLInputElement>;
}

const IconButton: React.FC<{ icon: IconName; onClick: (e: any) => void; disabled?: boolean }> = ({
	icon,
	onClick,
	disabled = false
}) => {
	return (
		<Button size="sm" color="grey" type="link" disabled={disabled} onClick={onClick}>
			<Icon name={icon} />
		</Button>
	);
};

const DateRangeInput: React.FC<DateRangeInputProps> = ({
	start,
	end,
	onChangeStart,
	onChangeEnd,
	anchor,
	scrollContainer,
	events,
	fetchEvents,
	disabled = false,
	autofocus = false,
	startDateRef
}) => {
	const classNames = new bemClass('DateRangeInput');
	const [showEndDate, setShowEndDate] = useState(!moment(start).isSame(moment(end), 'day'));
	const startTimeRef = useRef<HTMLInputElement>();
	const endTimeRef = useRef<HTMLInputElement>();

	useEffect(() => {
		setShowEndDate(!moment(start).isSame(moment(end), 'day'));
	}, [start, end]);

	const lang = {
		date: T('datatype.Date'),
		close: T('default.close'),
		startDate: T('datetimepicker.startDate'),
		endDate: T('datetimepicker.endDate'),
		startTime: T('datetimepicker.startTime'),
		endTime: T('datetimepicker.endTime'),
		tooltip: T('datetimepicker.calendarTooltip')
	};

	const changeStart = (type: 'time' | 'date', event: any) => {
		const date: Date = event.target.value;
		onChangeStart(type, date);
		if (startTimeRef?.current && type === 'date') {
			startTimeRef.current.focus();
		}
	};

	const changeEnd = (type: 'time' | 'date', event: any) => {
		const date: Date = event.target.value;
		onChangeEnd(type, date);
		if (endTimeRef?.current && type === 'date') {
			endTimeRef.current.focus();
		}
	};

	const toggleShowEnd = (value: boolean) => {
		setShowEndDate(value);

		if (!value) {
			const timeIsAfter =
				end.getHours() > start.getHours() ||
				(start.getHours() === end.getHours() && end.getMinutes() > end.getMinutes());
			/*
				If we close toggleShowEnd we set the dates to the same date again. 
				If this puts the end date before the start date we have to put it after the start date.
			*/
			if (timeIsAfter) {
				changeEnd('date', { target: { value: start } });
			} else {
				const newEndDate = new Date(start);
				const startHour = start.getHours();

				if (start.getHours() < 23) {
					newEndDate.setHours(startHour + 1);
				} else {
					const newStart = new Date(start);
					newStart.setHours(22);
					changeStart('time', { target: { value: newStart } });
				}

				changeEnd('time', { target: { value: newEndDate } });
				changeEnd('date', { target: { value: newEndDate } });
			}
		}
	};

	const dateInputProps: { [key: string]: any } = {};
	if (events && events.length) {
		dateInputProps.renderDay = (currentDate: Date) => {
			// Render a dot in the calendar if the user is busy that day
			const busy = events.some((a: CalendarEvent) => {
				const eventRange = range.range(moment(a.start).startOf('day'), moment(a.end).endOf('day'));
				return eventRange.contains(moment(currentDate));
			});
			return busy ? (
				<Icon name="circle" color="green" className={classNames.elem('appointment-indicator').b()} />
			) : null;
		};
		// It the month has changed, fetch new events
		if (fetchEvents) {
			dateInputProps.displayDateChange = (date: Date) => {
				if (!moment(start).isSame(date, 'month')) {
					fetchEvents(date);
				}
			};
		}
	}
	return (
		<div className={classNames.b()}>
			<Row>
				<Column className={classNames.elem('section').b()}>
					<div className={classNames.elem('date').b()}>
						<Label>{showEndDate ? lang.startDate : lang.date}</Label>
						<DateInput
							anchor={anchor}
							scrollContainer={scrollContainer}
							autofocus={autofocus && !disabled}
							closeOnSelect
							value={start}
							onChange={(event: any) => {
								changeStart('date', event);
							}}
							disabled={disabled}
							inputRef={r => {
								if (typeof startDateRef === 'function') {
									startDateRef(r);
								} else if (startDateRef) {
									(startDateRef as MutableRefObject<HTMLDivElement>).current = r;
								}
							}}
							{...dateInputProps}
						></DateInput>
					</div>
					<div className={classNames.elem('time').b()}>
						<Label>{lang.startTime}</Label>
						<TimeInput
							anchor={anchor}
							scrollContainer={scrollContainer}
							asDate
							step={15}
							value={start}
							onChange={(event: any) => changeStart('time', event)}
							disabled={disabled}
							inputRef={r => (startTimeRef.current = r)}
						></TimeInput>
					</div>
					{showEndDate ? <Column fixedWidth={39}></Column> : null}
					<SlideFade visible={!showEndDate} direction="right">
						<div className={classNames.elem('date-toggle').b()}>
							<div className={classNames.elem('time').b()}>
								<Label>{lang.endTime}</Label>
								<TimeInput
									anchor={anchor}
									scrollContainer={scrollContainer}
									asDate
									step={15}
									value={end}
									onChange={(event: any) => changeEnd('time', event)}
									disabled={disabled}
									inputRef={r => {
										if (!showEndDate) endTimeRef.current = r;
									}}
								></TimeInput>
							</div>
							<Column fixedWidth={39} className={classNames.elem('icon-btn').b()}>
								<Tooltip title={lang.tooltip} position="top" distance={24}>
									<IconButton
										disabled={disabled}
										icon="calendar-plus-o"
										onClick={() => toggleShowEnd(true)}
									></IconButton>
								</Tooltip>
							</Column>
						</div>
					</SlideFade>
				</Column>
			</Row>
			<SlideFade visible={showEndDate} direction="top" bounce height maxHeight={showEndDate ? 56 : 0}>
				<Row>
					<Column className={classNames.elem('section').b()}>
						<div className={classNames.elem('date').b()}>
							<Label>{lang.endDate}</Label>

							<DateInput
								anchor={anchor}
								scrollContainer={scrollContainer}
								closeOnSelect
								value={end}
								onChange={(event: any) => changeEnd('date', event)}
								disabled={disabled}
								{...dateInputProps}
							></DateInput>
						</div>
						<div className={classNames.elem('time').b()}>
							<Label>{lang.endTime}</Label>
							<TimeInput
								anchor={anchor}
								scrollContainer={scrollContainer}
								asDate
								step={15}
								value={end}
								onChange={(event: any) => changeEnd('time', event)}
								disabled={disabled}
								inputRef={r => {
									if (showEndDate) endTimeRef.current = r;
								}}
							></TimeInput>
						</div>

						<Column fixedWidth={39} className={classNames.elem('icon-btn').b()}>
							<Tooltip title={lang.close} position="top" distance={24}>
								<IconButton icon="times" onClick={() => toggleShowEnd(false)}></IconButton>
							</Tooltip>
						</Column>
					</Column>
				</Row>
			</SlideFade>
		</div>
	);
};

export default React.forwardRef<HTMLInputElement, DateRangeInputProps>((props, ref) => (
	<DateRangeInput startDateRef={ref} {...props} />
));

import { Input, Icon, OutsideClick } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import React, { useRef } from 'react';

import './SearchInput.scss';

type Props = {
	searchString: string | null;
	setSearchString: (text: string | null) => void;
	changeFolder: (folderId?: number | null) => void;
};

const SearchInput = ({ searchString, setSearchString, changeFolder }: Props) => {
	const classes = new BemClass('SearchInput');
	const inputRef = useRef<HTMLInputElement>(null);
	const active = searchString !== null;

	return (
		<OutsideClick outsideClick={() => setSearchString(null)} targetClass={'AccountFiles'}>
			<div className={classes.mod({ active }).b()}>
				<Icon
					onClick={() => {
						setSearchString(null);
					}}
					name="times"
				/>
				<Icon
					onClick={() => {
						setSearchString('');
						changeFolder(null);
						inputRef.current?.focus();
					}}
					name="search"
				/>
				<Input
					value={searchString ?? ''}
					onChange={e => setSearchString(e.target.value.toLowerCase())}
					inputRef={inputRef}
					placeholder={T('default.search')}
				/>
			</div>
		</OutsideClick>
	);
};

export default SearchInput;

import React from 'react';
import PropTypes from 'prop-types';
import { Label, Block, Row, Input, Icon, Column, Button, Text, Tooltip, Help } from '@upsales/components';
import { TIER_TYPE } from 'Store/reducers/AdminEditProductReducer';
import { replaceItem } from 'Store/helpers/array';
import T from 'Components/Helpers/translate';
import { productTier as productTierShape } from 'App/babel/propTypes/ProductShape';
import './EditProductTierRow.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { displayMaxDiscount } from './EditProductPriceCard';

const EditProductTierRow = ({
	tier,
	isFirst,
	onChange,
	currency,
	onRemove,
	disabled,
	errors,
	isLast,
	extendTierDiscount
}) => {
	const classes = new BemClass('EditProductTierRow');
	const currencyObjI = tier.currencies.findIndex(obj => obj.currency === currency);
	const currencyObj = tier.currencies[currencyObjI];
	const isLastAndOpen = isLast && tier.end == null;
	const hasMaxDiscount =
		Tools.FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAX_DISCOUNT);

	let error = null;
	if (errors && errors.gap) {
		error = T('product.tierGap');
	} else if (errors && errors.overlap) {
		error = T('product.tierOverlap');
	}

	const changeTierMaxDiscount = e => {
		const newValue = e.target.value;
		if (isNaN(newValue)) {
			return;
		}

		const newMaxDiscount = newValue === '' ? null : Number(newValue) / 100;

		onChange({
			...tier,
			maxDiscount: newMaxDiscount
		});
	};

	return (
		<React.Fragment>
			{error ? (
				<Text className="EditProductTierErrorRow" color="orange" size="sm">
					<Icon name="exclamation-triangle" space="mrs" />
					{error}
				</Text>
			) : null}
			<Row className={classes.mod({ hasMaxDiscount }).b()}>
				<Column size={hasMaxDiscount ? 2 : 3}>
					<Block space="pbl">
						{isFirst ? <Label>{T('product.tierStart')}</Label> : null}
						<Input
							type="number"
							value={tier.start == null ? '' : tier.start}
							onChange={e => onChange({ ...tier, start: e.target.value })}
							disabled={isFirst || disabled}
							state={
								(errors && errors.missingStart) || (errors && errors.startEndOverlap) ? 'error' : null
							}
						/>
					</Block>
				</Column>
				<Column size={hasMaxDiscount ? 2 : 3}>
					<Block space="pbl">
						{isFirst ? <Label>{T('product.tierEnd')}</Label> : null}
						<Input
							type="number"
							value={tier.end == null ? '' : tier.end}
							onChange={e => onChange({ ...tier, end: e.target.value })}
							disabled={disabled}
							state={errors && errors.startEndOverlap ? 'error' : null}
							placeholder={isLastAndOpen ? '∞' : ''}
						/>
					</Block>
				</Column>
				<Column size={hasMaxDiscount ? 3.5 : 3}>
					<Block space="pbl">
						{isFirst ? (
							<Label>{`${T(
								tier.isTotalPrice ? 'product.totalPrice' : 'product.unitPrice'
							)} (${currency})`}</Label>
						) : null}
						<Input
							type="number"
							value={currencyObj.value == null ? '' : currencyObj.value}
							onChange={e => {
								onChange({
									...tier,
									currencies: replaceItem(tier.currencies, currencyObjI, {
										...currencyObj,
										value: e.target.value
									})
								});
							}}
							disabled={disabled}
							placeholder="0"
						/>
					</Block>
				</Column>
				{hasMaxDiscount ? (
					<Column size={3.5}>
						<Block space="pbl">
							{isFirst ? (
								<>
									<Label>{T('default.maxDiscount')}</Label>{' '}
									<Help className={classes.elem('max-discount-help').b()} articleId={1472} />
								</>
							) : null}
							<div className={classes.elem('tier-discount-input').b()}>
								<Input
									value={displayMaxDiscount(tier.maxDiscount)}
									placeholder="%"
									onChange={changeTierMaxDiscount}
									disabled={disabled}
								/>
								{!isLast ? (
									<Tooltip title={T('product.tier.extendDiscount')}>
										<Icon name="arrow-circle-down" onClick={extendTierDiscount} />
									</Tooltip>
								) : null}
							</div>
						</Block>
					</Column>
				) : null}
				<Column size={hasMaxDiscount ? 1 : 2}>
					<Block space="pbl">
						{!isFirst ? (
							<Button
								type="link"
								color="grey"
								hoverColor="red"
								onClick={onRemove}
								disabled={disabled}
								tabIndex={-1}
							>
								<Icon name="trash" />
							</Button>
						) : null}
					</Block>
				</Column>
			</Row>
		</React.Fragment>
	);
};

EditProductTierRow.defaultProps = {
	tier: {
		start: null,
		end: null,
		type: TIER_TYPE.UNIT,
		currencies: []
	}
};

EditProductTierRow.propTypes = {
	tier: productTierShape,
	disabled: PropTypes.bool,
	currency: PropTypes.string,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
	extendTierDiscount: PropTypes.func,
	errors: PropTypes.shape({
		gap: PropTypes.bool
	})
};

export default EditProductTierRow;

'use strict';

angular.module('upResources').service('MapAccount', [
	'MapHelpers',
	function (MapHelpers) {
		return function (payload) {
			var account = payload.account;

			MapHelpers.mapCategories(account);

			if (account.campaigns) {
				account.projects = account.campaigns;
			}

			payload.account = account;
			return payload;
		};
	}
]);

import logError from 'App/babel/helpers/logError';

angular.module('domain.appointment').factory('EditAppointmentMeta', [
	'$q',
	'Account',
	'Contact',
	'Appointment',
	'NotificationService',
	'$parse',
	'AppService',
	'RequestBuilder',
	function ($q, Account, Contact, Appointment, NotificationService, $parse, AppService, RequestBuilder) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				// The metadata $modalParams.appointment object is used to preset data to the modal-form for edit's
				var customerId = AppService.getCustomerId();
				var account = $q.defer();
				var contactDefer = $q.defer();
				var promises = {
					users: $q.when({ data: AppService.getActiveUsers() }),
					account: account.promise,
					documentTemplates: $q.when({ data: AppService.getDocumentTemplates('activity') }),
					contacts: contactDefer.promise
				};

				// if we have some preset data
				if ($modalParams.id) {
					promises.appointment = Appointment.customer(customerId).get($modalParams.id);
					account.resolve({ data: null });
					contactDefer.resolve({ data: null });
				} else {
					var appointment = Appointment.new();
					if ($modalParams.appointment) {
						appointment.data = _.merge(appointment.data, $modalParams.appointment);
					}
					promises.appointment = $q.when(appointment);
				}

				// get the appointment account and contacts if we got some presets
				if (!$modalParams.id) {
					promises.appointment
						.then(function (appointment) {
							var noAccount = true;
							// If the appointment has contact or account
							if (appointment.data.client && typeof appointment.data.client === 'object') {
								Account.customer(customerId)
									.get(appointment.data.client.id, {})
									.then(account.resolve)
									.catch(account.reject);
								noAccount = false;
							}

							if (
								appointment.data.contacts &&
								_.isArray(appointment.data.contacts) &&
								appointment.data.contacts.length
							) {
								var ids = _.pluck(appointment.data.contacts, 'id');
								var contactFilter = new RequestBuilder();
								contactFilter.addFilter(Contact.attr.id, contactFilter.comparisonTypes.Equals, ids);
								// Get all connected contacts
								Contact.find(contactFilter.build())
									.then(function (contacts) {
										// Get contact account
										if (noAccount && contacts.data && contacts.data.length) {
											Account.customer(customerId)
												.get(contacts.data[0].client.id)
												.then(account.resolve)
												.catch(account.reject);
											noAccount = false;
										}

										contactDefer.resolve(contacts.data);
									})
									.catch(function (err) {
										contactDefer.reject(err);
									});
							} else {
								if (noAccount) {
									account.resolve({ data: null });
								}

								contactDefer.resolve({ data: null });
							}
						})
						.catch(function (err) {
							logError(err, 'Error getting appointment');
						});
				}

				return $q.all(promises).then(
					function (results) {
						// Fix for when a user are selected and no longer exist
						var users = $parse('appointment.data.users')(results);

						if (users && Array.isArray(users)) {
							users.forEach(function (user) {
								if (user && !_.some(results.users.data, { id: user.id })) {
									results.users.data.push(user);
								}
							});
						}

						if (results.account && results.account.data) {
							results.appointment.data.client = results.account.data;
						}

						if (results.contacts && results.contacts.data) {
							results.appointment.data.contacts = results.contacts.data;
						}

						if ($modalParams.preSelectedOutcome) {
							results.preSelectedOutcome = $modalParams.preSelectedOutcome;
						}

						return results;
					},
					function (err) {
						if (err !== 'abort') {
							NotificationService.addNotification({
								title: 'default.error',
								body: err && err.status === 404 ? 'errorNotFound.appointment' : 'openError.appointment',
								style: 'error',
								icon: 'times'
							});
						}
						return $q.reject(err);
					}
				);
			});
		};
	}
]);

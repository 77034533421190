import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Text, Link } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './OpportunityRow.scss';

type Props = {
	title: string;
	isWarning: boolean;
	decisionMakerId?: number;
	decisionMakerName?: string;
};

class OpportunityRowCEO extends React.Component<Props> {
	static propTypes = {
		isWarning: PropTypes.bool.isRequired,
		title: PropTypes.string.isRequired,
		decisionMakerId: PropTypes.number,
		decisionMakerName: PropTypes.string
	};

	render() {
		const classes = new bemClass('OpportunityRow');
		const { title, isWarning, decisionMakerId, decisionMakerName } = this.props;
		return (
			<Card className={classes.b()}>
				<Icon
					className={classes.elem('iconTitle').b()}
					color={'green'}
					name={isWarning ? 'lightbulb-o' : 'check'}
				/>
				{isWarning ? (
					<React.Fragment>
						<div className={classes.elem('con').b()}>
							<Text className={classes.elem('description').b()}>
								{`${T('opportunity.notIncluded1')} ${title} ${T('opportunity.notIncluded2')}`}
							</Text>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className={classes.elem('con').b()}>
							<Link
								className={classes.elem('CEOLink').b()}
								onClick={() =>
									Tools.$state.go('contact.dashboard', {
										id: decisionMakerId
									})
								}
							>
								{`${T('opportunity.the')} ${title}`}
							</Link>
							<Text>{T('opportunity.isIncluded')}</Text>
							{decisionMakerName ? (
								<Text className={classes.elem('decisionMakerName').b()} italic>
									{decisionMakerName}
								</Text>
							) : null}
						</div>
					</React.Fragment>
				)}
			</Card>
		);
	}
}

export default OpportunityRowCEO;

import './MoveSubscriptionButton.scss';

import React from 'react';
import { Icon, Button, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

type Props = {
	onClick?: () => void;
};

const MoveSubscriptionButton = ({ onClick }: Props) => {
	const classes = new BemClass('MoveSubscriptionButton');
	return (
		<Tooltip position="top" title={T('agreement.moveSubscriptionButtonTooltip')} distance={28}>
			<Button className={classes.b()} type="lined" onClick={onClick} shadow="none">
				<Icon space="mrm" name="arrow-right" />
				{T('agreement.moveSubscriptionButton')}
			</Button>
		</Tooltip>
	);
};

export default MoveSubscriptionButton;

'use strict';

angular.module('upResources').factory('ApiKey', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ApiKeyAttributes',
	'GenericMapper',
	'CustomFieldMapper',
	'ResourceHelper',
	function ApiKey($resource, $q, URL, API, ApiKeyAttributes, GenericMapper, CustomFieldMapper, ResourceHelper) {
		var Attributes = ApiKeyAttributes().attr;
		var keys = ApiKeyAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'apiKey',
			createSuccessBody: 'saved.apiKey',
			updateSuccessBody: 'updated.apiKey',
			deleteSucccessBody: 'deleted.apiKey',
			createErrorBody: 'saveError.apiKey',
			updateErrorBody: 'saveError.apiKey',
			deleteErrorBody: 'deleteError.apiKey'
		});

		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'apiKeys/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: { method: 'GET', isArray: false },
				find: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options || {});

				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (apiKey, options) {
				var defered = $q.defer();

				if (!apiKey) {
					return defered.reject('No apiKey');
				}

				if (apiKey.id) {
					Resource.update({ id: apiKey.id }, { ApiKey: apiKey }, defered.resolve, defered.reject);
				} else {
					Resource.create({ ApiKey: apiKey }, defered.resolve, defered.reject);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !apiKey.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !apiKey.id);
					});

				return defered.promise;
			},

			delete: function (apiKey, options) {
				return Resource.delete({ id: apiKey.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, apiKey);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},
			attr: Attributes,
			keys: keys,
			requiredFields: ApiKeyAttributes().requiredFields
		};

		return Model;
	}
]);

const _ = require('lodash');

const utils = {
	clientContactsAndRelations: {
		formatSelection: function (contact, clientId) {
			if (contact.client && clientId && contact.client.id !== clientId) {
				return (
					'<i class="fa fa-sitemap related-contact-select-icon" style="font-size:10px;"></i> ' +
					_.escape(contact.name)
				);
			}

			return _.escape(contact.name);
		},
		formatResult: function (contact, clientId) {
			var title = '';

			if (contact.title) {
				title +=
					'<span class="subtitle grey" style="display:block;text-transform:uppercase;">' +
					_.escape(contact.title) +
					'</span>';
			}
			if (contact.client && clientId && contact.client.id !== clientId) {
				title +=
					'<span style="font-size:9px;display:block;" class="related-client-name"><i class="Icon Icon-sitemap"></i> ' +
					_.escape(contact.client.name) +
					'</span>';
			}

			var info =
				'<div class="icons">' +
				'<b class="Icon' +
				(contact.email ? ' Icon-envelope icon-selected' : ' Icon-envelope-o') +
				'"></b>' +
				'<b class="Icon Icon-phone' +
				(contact.phone ? ' icon-selected' : '') +
				'"></b>' +
				'<b class="Icon Icon-mobile' +
				(contact.cellPhone ? ' icon-selected' : '') +
				'"></b>' +
				'</div>';
			return '<div style="position:relative;">' + _.escape(contact.name) + title + info + '</div>';
		}
	},
	formatParticipantSelection: function (person, clientId, jqObj) {
		if (person.phone === undefined) {
			jqObj.parent().addClass('user-token');
			return _.escape(person.name);
		}

		jqObj.parent().addClass('contact-token');
		if (person.client && clientId && person.client.id !== clientId) {
			return (
				'<i class="fa fa-sitemap related-contact-select-icon" style="font-size:10px;"></i> ' +
				_.escape(person.name)
			);
		}
		return _.escape(person.name);
	},
	formatParticipantResult: function (person, clientId, jqObj) {
		// For categories
		if (person.children) {
			if (!person.children.length) {
				jqObj.css('display', 'none');
			}
			return _.escape(person.name);
		}
		var title = '';

		if (person.title) {
			title +=
				'<span class="subtitle grey" style="display:block;text-transform:uppercase;">' +
				_.escape(person.title) +
				' </span>';
		}
		if (person.client && clientId && person.client.id !== clientId) {
			title +=
				'<span style="font-size:9px;display:block;" class="related-client-name"><i class="Icon Icon-sitemap"></i> ' +
				_.escape(person.client.name) +
				'</span>';
		}

		var info =
			'<div class="icons">' +
			'<b class="Icon' +
			(person.email ? ' Icon-envelope icon-selected' : ' Icon-envelope-o') +
			'"></b>' +
			'<b class="Icon Icon-phone' +
			(person.phone ? ' icon-selected' : '') +
			'"></b>' +
			'<b class="Icon Icon-mobile' +
			(person.cellPhone ? ' icon-selected' : '') +
			'"></b>' +
			'</div>';
		return '<div style="position:relative;">' + _.escape(person.name) + title + info + '</div>';
	}
};

export default utils;

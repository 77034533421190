import React, { Component } from 'react';
import { Link, Text, Title, Input, Label, Toggle } from '@upsales/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setValue, load, filterConfigs, save } from 'Store/reducers/JourneyEditorReducer';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriasCard from './CriteriasCard';
import ActionsCard from './ActionsCard';
import FloatingSaveButton from 'Components/FloatingSaveButton';
import './JourneyEditor.scss';

const propTypes = {
	automation: PropTypes.object.isRequired,
	setValue: PropTypes.func.isRequired,
	load: PropTypes.func.isRequired,
	id: PropTypes.string,
	type: PropTypes.oneOf(['mql', 'lost_customer']),
	saving: PropTypes.bool,
	save: PropTypes.func.isRequired
};

const defaultProps = {
	automation: {},
	save: () => {}
};

const mapStateToProps = state => ({
	automation: state.JourneyEdit.automation,
	filters: state.JourneyEdit.filters,
	saving: state.JourneyEdit.saving
});

const mapDispatchToProps = {
	setValue,
	load,
	save
};

class JourneyEditor extends Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			journeyStatuses: t('default.journeyStatuses'),
			newStatus: {
				mql: t('default.newMQLStatus'),
				lost_customer: t('default.newLostCustomerStatus')
			},
			criterias: t('admin.criterias'),
			statusName: t('default.journeyStatusName'),
			active: t('default.active'),
			criteria: t('admin.criteria'),
			actions: t('admin.actions'),
			save: t('default.save')
		};

		props.load(props.type, props.id);

		this.filterConfigs = filterConfigs;
	}

	render() {
		const { setValue, automation, saving, save, type } = this.props;
		const classNames = new BemClass('JourneyEditor');

		return (
			<div id="admin-root" className={classNames.b()}>
				<div className="admin-page-header">
					<div className="page-header-icon">
						<div className="header-icon-img" style={{ backgroundImage: 'url(img/admin-journey.png)' }} />
					</div>
					<div className="page-header-text-wrap">
						<div className={classNames.elem('Breadcrum').b()}>
							<Link href={Tools.$state.href('administration.journeyStatuses')}>
								{this.lang.journeyStatuses}
							</Link>
							<Text>{`/ ${this.lang.newStatus[type]}`}</Text>
						</div>
						<div className={classNames.elem('StatusName').b()}>
							<div className={classNames.elem('StatusNameInputWrapper').b()}>
								<Label color="grey-10" required>
									{this.lang.statusName}
								</Label>
								<Input
									inline
									value={automation.name}
									onChange={e => setValue('name', e.target.value)}
								/>
							</div>
							<Toggle
								className={classNames.elem('StatusNameToggle')}
								onChange={active => setValue('active', active)}
								size="lg"
								color="medium-green"
								checked={automation.active}
								space="mll mrl"
							/>
							<Text className={classNames.elem('StatusNameToggleText')}>{this.lang.active}</Text>
						</div>
					</div>
				</div>

				<div id="admin-content">
					<Title size="lg" color="grey-11" className={classNames.elem('title').b()}>
						{this.lang.criterias}
					</Title>

					<CriteriasCard type="and" filterConfigs={this.filterConfigs} />

					<CriteriasCard type="or" filterConfigs={this.filterConfigs} />

					<Title size="lg" color="grey-11" className={classNames.elem('title').b()}>
						{this.lang.actions}
					</Title>

					<ActionsCard />
				</div>
				<FloatingSaveButton visible loading={saving} onClick={() => save()} text={this.lang.save} />
			</div>
		);
	}
}

JourneyEditor.propTypes = propTypes;
JourneyEditor.defaultProps = defaultProps;

export const detached = JourneyEditor;
const ConnectedJourneyEditor = connect(mapStateToProps, mapDispatchToProps)(JourneyEditor);
export default ConnectedJourneyEditor;
window.JourneyEditor = ConnectedJourneyEditor;

import ListViewActions from 'App/components/ListViewActions';

angular.module('upDirectives').directive('upListViewActions', [
	'$multiSelect',
	function ($multiSelect) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;
				var rootData = $attr.upListViewActions;

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}
						ReactDOM.render(
							React.createElement(ListViewActions, {
								actions: rootData.actions,
								runAction: action => {
									const runner = rootData.runAction(action);
									if (runner && runner.then) {
										// eslint-disable-next-line promise/catch-or-return
										runner.then(() => $multiSelect.selectNone());
									} else {
										$multiSelect.selectNone();
									}
									return runner;
								},
								selectNone: rootData.selectNone,
								selected: rootData.selected,
								allSelected: rootData.allSelected
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					$attr.upListViewActions,
					function (data) {
						rootData = data;
						render();
					},
					true
				);

				render();
			}
		};
	}
]);

'use strict';

angular.module('upResources').factory('DeleteLog', [
	'$resource',
	'$q',
	'URL',
	'API',
	'DeleteLogAttributes',
	'ParseGeneric',
	function DeleteLog($resource, $q, URL, API, DeleteLogAttributes, ParseGeneric) {
		var Attributes = DeleteLogAttributes().attr;
		var keys = DeleteLogAttributes().keys;

		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'deleteLog/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric()
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				var params = _.extend(filter, options || {});

				return Resource.find(params).$promise;
			},
			attr: Attributes,
			keys: keys,
			requiredFields: DeleteLogAttributes().requiredFields
		};

		return Model;
	}
]);

'use strict';

angular.module('upDirectives').directive('upReactFilterTable', [
	'$translate',
	'$injector',
	function ($translate, $injector) {
		return {
			restrict: 'A',
			scope: {
				tableData: '=',
				tableColumns: '=',
				tableLoading: '=',
				filterConfig: '=',
				selected: '=',
				select: '&',
				selectOne: '&',
				selectAll: '&',
				filter: '='
			},
			templateUrl: require('App/upsales/common/directives/templates/upReactFilterTable.tpl.html?file'),
			link: function ($scope, $element) {
				var isSearch = false;
				let firstElement = $element[0];

				var getFilteredData = function (filter) {
					return _.filter($scope.tableData, function (item) {
						if (typeof $scope.filterConfig.displayText === 'function') {
							return (
								$scope.filterConfig.displayText(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1
							);
						} else {
							return (
								item[$scope.filterConfig.displayText].toLowerCase().indexOf(filter.toLowerCase()) !== -1
							);
						}
					});
				};

				if ($scope.filterConfig.search && typeof $scope.filterConfig.search === 'function') {
					isSearch = $injector.invoke($scope.filterConfig.search);
				} else {
					isSearch = $scope.filterConfig.search;
				}

				var renderTable = function (data, selected) {
					if (data && Array.isArray(data) && firstElement) {
						ReactDOM.render(
							React.createElement(ReactTemplates.OTHER.filterTable, {
								// Table data and stuff
								data: _.sortByOrder(data, $scope.filterConfig.displayText),
								filterConfig: $scope.filterConfig,
								selected: selected || [],
								isSearch: isSearch,

								// Injects
								tools: {
									$translate: $translate.instant,
									// This is triggered when the array is changed in react
									onChange: function (newVal) {
										angular.copy(newVal, $scope.tableData);
									},
									select: function (item) {
										$scope.select({ $item: item });
									},
									selectOne: function (item) {
										$scope.selectOne({ $item: item });
									},
									selectAll: function () {
										$scope.selectAll();
									}
								}
							}),
							firstElement,
							function () {}
						);
					}

					$scope.$on('$destroy', function () {
						if (firstElement) {
							ReactDOM.unmountComponentAtNode(firstElement);
						}
						firstElement = undefined;
					});
				};

				$scope.$watchCollection('tableData', function (newValue) {
					// | orderBy:($modalParams.filterConfig.sortOrder || displayText)
					renderTable(newValue, $scope.selected);
				});

				$scope.$watchCollection('selected', function (newValue) {
					renderTable($scope.tableData, newValue);
				});

				$scope.$watch('filter', function (filter) {
					var data = getFilteredData(filter);
					renderTable(data, $scope.selected);
				});
			}
		};
	}
]);

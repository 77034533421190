import './SalesTargets.scss';

import React, { useMemo, useEffect, useState, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block } from '@upsales/components';
import { isEqual } from 'lodash';
import SalesTargetWidget, { SalesWidget } from './SalesTargetWidget';

type Props = {
	salesWidgets?: SalesWidget[];
	visible?: boolean;
	salesPrognosis?: number;
};

const SalesTargets = ({ salesWidgets, visible, salesPrognosis }: Props) => {
	const [salesWidgetsSnapshots, setSalesWidgetsSnapshot] = useState<SalesWidget[]>([]);
	const [latestSalesWidgetsSnapshot, setLatestSalesWidgetsSnapshot] = useState<SalesWidget[]>([]);
	const animationTimer = useRef<NodeJS.Timeout>();
	const progressBarAnimationDelay = 1000;

	useEffect(() => {
		return () => {
			if (animationTimer.current) {
				clearTimeout(animationTimer.current);
			}
		};
	});

	const latestDiffsAgainstWidgetSnapshot = useMemo(() => {
		return !isEqual(salesWidgetsSnapshots, latestSalesWidgetsSnapshot);
	}, [salesWidgetsSnapshots, latestSalesWidgetsSnapshot]);

	const updateSnapshots = () => {
		if (!visible) {
			setSalesWidgetsSnapshot(salesWidgets || []);
		} else {
			setLatestSalesWidgetsSnapshot(salesWidgets || []);
		}
	};

	useEffect(() => {
		if (salesWidgets) {
			updateSnapshots();
		}
	}, [salesWidgets, visible]);

	useEffect(() => {
		if (latestDiffsAgainstWidgetSnapshot && latestSalesWidgetsSnapshot?.length && visible) {
			animationTimer.current = setTimeout(() => {
				setSalesWidgetsSnapshot(latestSalesWidgetsSnapshot);
			}, progressBarAnimationDelay);
		}

		return () => {
			if (animationTimer.current) {
				clearTimeout(animationTimer.current);
			}
		};
	}, [latestDiffsAgainstWidgetSnapshot, latestSalesWidgetsSnapshot, visible]);

	const widgetsToRender = salesWidgetsSnapshots.length ? salesWidgetsSnapshots : salesWidgets || [];

	if (!widgetsToRender.length) {
		return null;
	}

	const classes = new BemClass('SalesTargets');
	return (
		<Block space="mbxl" className={classes.b()}>
			{widgetsToRender.map(widget => (
				<SalesTargetWidget
					key={widget.data.type.name}
					widget={widget}
					salesPrognosis={salesPrognosis}
					visible={visible}
					progressBarAnimationDelay={progressBarAnimationDelay}
				/>
			))}
		</Block>
	);
};

export default SalesTargets;

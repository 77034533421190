'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('ContactCategoryType', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AccountCategoryTypeAttributes',
	'CacheService',
	'ResourceHelper',
	'AppService',
	function ContactCategoryType(
		$resource,
		$q,
		URL,
		API,
		AccountCategoryTypeAttributes,
		CacheService,
		ResourceHelper,
		AppService
	) {
		var AA = AccountCategoryTypeAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'contactCategoryTypes',
			createSuccessBody: 'saved.contactCategoryTypes',
			updateSuccessBody: 'updated.contactCategoryTypes',
			deleteSucccessBody: 'deleted.contactCategoryTypes',
			createErrorBody: 'saveError.contactCategoryTypes',
			updateErrorBody: 'saveError.contactCategoryTypes',
			deleteErrorBody: 'deleteError.contactCategoryTypes'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Category = null;
				var Cache = CacheService.getCache(CacheService.cacheTypes.CONTACTCATEGORYTYPE);
				Category = $resource(
					URL + API + customer + '/contactCategoryTypes/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.find = function (filter, options) {
					var defered = $q.defer();
					var params = angular.extend(filter, options);

					Category.get(params, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.get = function (id) {
					if (!id) {
						return $q.reject('no category type id');
					}

					return Category.get({ id: id }).$promise;
				};

				instance.getCached = function () {
					var key = 'ContactCategoryTypes';
					var cached = Cache.get(key);

					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find({}).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				instance.save = function (categoryType, options) {
					var defered = $q.defer();

					if (!categoryType) {
						return defered.reject('No category type');
					}

					if (categoryType.id) {
						Category.update(
							{ id: categoryType.id },
							categoryType,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Category.create(
							categoryType,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							var categoryTypes = AppService.getCategoryTypes('contact');
							var index = _.findIndex(categoryTypes, { id: res.data.id });

							if (index > -1) {
								categoryTypes[index] = _.cloneDeep(res.data);
							} else {
								categoryTypes.unshift(_.cloneDeep(res.data));
							}

							AppService.setCategoryTypes('contact', categoryTypes);
							AllIWantDataCache.clearData();

							helper.onSave(options, res.data, !categoryType.id);
						})
						.catch(function (err) {
							helper.onSaveError(options, !categoryType.id);
							return $q.reject(err);
						});

					return defered.promise;
				};

				instance.delete = function (categoryType, options) {
					return Category.delete({ id: categoryType.id })
						.$promise.then(function (res) {
							var categoryTypes = AppService.getCategoryTypes('contact');
							categoryTypes = _.filter(categoryTypes, function (_categoryType) {
								return _categoryType.id !== categoryType.id;
							});
							AppService.setCategoryTypes('contact', categoryTypes);
							AllIWantDataCache.clearData();

							var categories = AppService.getCategories('contact');
							categories = _.filter(categories, function (category) {
								return category.categoryType !== categoryType.id;
							});
							AppService.setCategories('contact', categories);

							helper.onDelete(options, categoryType);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options);
							return $q.reject(err);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upModals.controllers').controller('EditListView', [
	'$scope',
	'$modalParams',
	'ListViewService',
	'$safeApply',
	function ($scope, $modalParams, ListViewService, $safeApply) {
		$scope.saving = false;
		$scope.view = angular.copy($modalParams.view);

		var listType = $modalParams.listType;

		$scope.save = function () {
			if ($modalParams.skipSave) {
				$scope.resolve($scope.view);
				return;
			}

			$scope.saving = true;

			ListViewService.save(listType, $scope.view, $modalParams.customerId).then(
				function (view) {
					$scope.resolve(view);
				},
				function (err) {
					$scope.reject(err);
				}
			);
		};

		var init = function () {
			setTimeout(function () {
				$safeApply($scope);
			});
		};

		init();
	}
]);

'use strict';
import React from 'react';

import ActivityOutcomesStatistic from 'Components/ActivityOutcomesStatistic';
import TopList from 'App/upsales/common/components/react/flash/topList';
import { Icon } from '@upsales/components';

ReactTemplates.flash = {};
ReactTemplates.flash.root = window.ReactCreateClass({
	activityId: null,
	navEnabledTimeout: null,
	getInitialState: function () {
		return {
			navDisabled: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			close: t('flash.close'),
			minimize: t('flash.minimize'),
			activitiesLeft: t('flash.activitiesLeft'),
			nextArrow: t('flash.saveandGoToNextActivity'),
			prevArrow: t('flash.saveandGoToPreviousActivity'),
			infoIconTooltip: t('flash.activityModalInfoTooltip')
		};
	},
	setBlur: function () {
		// Toggle blur if open/closed
		var blurWrap = document.getElementById('blur-wrap');
		if (this.props.rootData.open) {
			blurWrap.classList.add('is-blurred');
		} else {
			blurWrap?.classList?.remove?.('is-blurred');
		}
	},
	updateSize: function () {
		var self = this;
		if (self.resizeTimer) {
			clearTimeout(self.resizeTimer);
			self.resizeTimer = null;
		}

		self.resizeTimer = setTimeout(function () {
			var modal = self._root.querySelector('.up-modal');
			if (modal) {
				var wrapper = self._arrows;
				var width = modal.clientWidth + 150;

				wrapper.style.width = width + 'px';
				wrapper.style.height = modal.clientHeight + 'px';
				wrapper.style.marginLeft = -(width / 2) + 'px';
			}
		}, 20);
	},
	beforeGoTo: function () {
		var defered = Tools.$q.defer();
		if (this.props.rootData.dirty === true) {
			// eslint-disable-next-line promise/catch-or-return
			this.props.rootData.onSaveSimple(this.props.rootData.activity).then(function () {
				defered.resolve();
			});
		} else {
			defered.resolve();
		}

		return defered.promise;
	},
	closeOnHref: function (e) {
		const metadata = this.props.tools.AppService.getMetadata();
		const phoneIntegration = Tools.AppService.getPhoneIntegration();

		let callto = 'callto:';
		if (_.get(phoneIntegration, 'capabilities.useCallToLinks')) {
			callto = phoneIntegration.capabilities.useCallToLinks;
		} else if (metadata.params.callToType) {
			callto = metadata.params.callToType;
		}

		const isNotCalltoLink = e.target.href === undefined || e.target.href.indexOf(callto) === -1;
		const isNotWebLink = e.target.target !== '_blank';

		if (e.target && e.target.tagName.toLowerCase() === 'a' && isNotWebLink && isNotCalltoLink) {
			this.hide();
		}
	},
	componentWillUnmount: function () {
		// Unsubscribe events here
		window.removeEventListener('resize', this.updateSize);

		this._root.removeEventListener('click', this.closeOnHref);
	},
	componentDidMount: function () {
		// Subscribe events here
		window.addEventListener('resize', this.updateSize);

		this._root.addEventListener('click', this.closeOnHref);

		this.setBlur();
		this.updateSize();
	},
	componentDidUpdate: function () {
		var self = this;

		self.setBlur();
		self.updateSize();

		if (self.navEnabledTimeout) {
			clearTimeout(self.navEnabledTimeout);
		}

		if (self.state.navDisabled && self.props.rootData.hasNext) {
			self.navEnabledTimeout = setTimeout(function () {
				self.setState({ navDisabled: false });
			}, 200);
		}

		if (self.state.navDisabled) {
			self.navEnabledTimeout = setTimeout(function () {
				self.setState({ navDisabled: false });
			}, 500);
		}
	},
	disableNav: function () {
		var btn = this._arrows.querySelector('#flash-nav-forward');
		if (btn) {
			btn.disabled = true;
		}

		var btn2 = this._arrows.querySelector('#flash-nav-back');
		if (btn2) {
			btn2.disabled = true;
		}

		this.setState({ navDisabled: true });
	},
	goForward: function (e) {
		if (!e.currentTarget.disabled) {
			this.props.rootData.next();
			this.disableNav();
		}
	},
	goBack: function (e) {
		if (!e.currentTarget.disabled) {
			this.props.rootData.prev();
			this.disableNav();
		}
	},
	onSave: function (activity, closeActivity, fromArrow, followup) {
		this.disableNav();
		this.props.rootData.onSave(activity, closeActivity, fromArrow, followup);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	close: function () {
		this.props.rootData.onClose();
	},
	hide: function () {
		var self = this;

		if (this.props.rootData.dirty === true) {
			// eslint-disable-next-line promise/catch-or-return
			this.props.rootData.onSaveSimple(this.props.rootData.activity).then(function () {
				self.props.rootData.onHide();
			});
		} else {
			this.props.rootData.onHide();
		}
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var classes = '';
		var arrowClassLeft = 'nav-arrow';
		var arrowClassRight = 'nav-arrow';
		if (!self.state.navDisabled && !rootData.phoneIsActive) {
			arrowClassLeft = 'nav-arrow move-in-left';
			arrowClassRight = 'nav-arrow move-in-right';
		}
		const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);

		var prevBtn = rootData.hasPrev ? (
			<button
				type="button"
				key="back"
				disabled={self.state.navDisabled || rootData.phoneIsActive}
				id="flash-nav-back"
				className={arrowClassLeft}
				onClick={self.goBack}
			>
				{ReactTemplates.TOOLS.withTooltip(<b className="fa fa-angle-left" />, self.lang.prevArrow, {
					placement: 'right',
					distance: 20,
					key: 'prev-tooltip'
				})}
			</button>
		) : null;

		var nextBtn = rootData.hasNext ? (
			<button
				type="button"
				key="fwd"
				id="flash-nav-forward"
				disabled={self.state.navDisabled || rootData.phoneIsActive}
				className={arrowClassRight}
				onClick={self.goForward}
			>
				{ReactTemplates.TOOLS.withTooltip(<b className="fa fa-angle-right" />, self.lang.nextArrow, {
					placement: 'left',
					distance: 20,
					key: 'next-tooltip'
				})}
			</button>
		) : null;

		if (rootData.open) {
			classes += ' open';
		}

		var content = null;

		if (rootData.isFinished) {
			content = (
				<div>
					{React.createElement(ReactTemplates.flash.resultView, {
						id: 'flash-result-view'
					})}

					{hasActivityOutcomeAccess ? (
						<div id="flash-footer-wrap">
							<div id="flash-footer">
								<ActivityOutcomesStatistic tools={tools} rootData={rootData} />
							</div>
						</div>
					) : null}
				</div>
			);
		} else {
			var arrowTransition = 'arrow-wrapper';

			if (rootData.transitioning) {
				arrowTransition += ' transitioning';
			}

			content = (
				<div>
					<div className={arrowTransition} ref={self.setRef.bind(self, '_arrows')}>
						<div id="nr-of-activity-label">
							<b>{rootData.nrOfActivities} </b>
							<span>{self.lang.activitiesLeft.toLowerCase()}</span>
						</div>
						{prevBtn}
						{nextBtn}
					</div>
					<div id="flash-activity-wrap">
						{rootData.activity && rootData.open
							? React.createElement(ReactTemplates.editActivity.root, {
									activity: rootData.activity,
									links: rootData.activity.links,
									flash: true,
									users: rootData.users,
									documentTemplates: rootData.documentTemplates,
									onCreateFollowup: rootData.onCreateFollowup,
									bookAppointment: rootData.bookAppointment,
									onCreateDocument: rootData.onCreateDocument,
									onChange: rootData.onActivityChange,
									next: rootData.next,
									onCreateContact: rootData.onCreateContact,
									onEmailContact: rootData.onEmailContact,
									onContactSave: rootData.onContactSave,
									onCreateCampaign: rootData.onCreateCampaign,
									onCreateOpportunity: rootData.onCreateOpportunity,
									createOpportunityAndSave: rootData.createOpportunityAndSave,
									createOrderAndSave: rootData.createOrderAndSave,
									setOutcome: rootData.setOutcome,
									onEditAccount: rootData.onEditAccount,
									onSave: self.onSave,
									onSetJourneyStepClient: rootData.onSetJourneyStepClient,
									onSetJourneyStepContact: rootData.onSetJourneyStepContact,
									setContact: rootData.setContact,
									saving: rootData.saving,
									submitted: rootData.submitted,
									formInvalid: rootData.formInvalid,
									transitioning: rootData.transitioning,
									closing: rootData.closing,
									topMargin: 70,
									bottomMargin: 160,
									isAvailable: rootData.isAvailable,
									tools: tools,
									beforeGoTo: self.beforeGoTo,
									closeOnHref: self.closeOnHref,
									dirty: rootData.dirty,
									disqualifyClient: rootData.disqualifyClient,
									disqualifyContact: rootData.disqualifyContact,
									contactSaving: rootData.contactSaving,
									goToUrl: rootData.goToUrl
							  })
							: null}
					</div>

					<div id="flash-footer-wrap">
						{hasActivityOutcomeAccess ? (
							<div id="flash-footer">
								<ActivityOutcomesStatistic tools={tools} rootData={rootData} />
							</div>
						) : (
							<div id="flash-footer">
								<TopList
									id="footer-left-top-list"
									title="flash.closedActivitiesToday"
									settingsTitle="default.activityTypes"
									entity="activity"
									settings={rootData.activityListWidget}
									placeholder="flash.noClosedActivitiesToDay"
									roles={rootData.roles.activity}
									tools={tools}
									inlineStyle={{ width: '300px', float: 'left' }}
								/>

								{React.createElement(ReactTemplates.flash.shield, {
									id: 'footer-left-shield-widget',
									settings: rootData.activityShieldWidget,
									title: 'flash.closedActivities',
									placeholder: 'flash.noClosedActivities',
									tools: tools
								})}

								{React.createElement(ReactTemplates.flash.shield, {
									id: 'footer-right-shield-widget',
									settings: rootData.appointmentShieldWidget,
									title: 'flash.closedAppointments',
									placeholder: 'flash.noClosedAppointments',
									tools: tools
								})}

								<TopList
									id="footer-right-top-list"
									title="flash.closedAppointmentsToday"
									settingsTitle="default.appointmentTypes"
									entity="appointment"
									settings={rootData.appointmentListWidget}
									placeholder="flash.noBookedAppointmentsToDay"
									roles={rootData.roles.appointment}
									tools={tools}
								/>
								<ReactTemplates.flash.clockWidget
									tools={tools}
									time={rootData.time}
									open={rootData.open}
								/>
							</div>
						)}
					</div>
				</div>
			);
		}

		return (
			<div id="flash-wrap" className={classes} ref={self.setRef.bind(self, '_root')}>
				<div id="flash">
					<div id="flash-tools-wrap">
						{!rootData.isFinished ? (
							<React.Fragment>
								<div id="info-icon">
									<ReactTemplates.elevio articleId={885} sidebar>
										<b className="fa fa-info-circle" aria-hidden="true" />
									</ReactTemplates.elevio>
								</div>
								<button
									data-test-id="minimize-flash"
									type="button"
									className="up-btn btn-white no-shadow"
									onClick={self.hide}
								>
									<Icon name="minimize-updated" /> {self.lang.minimize}
								</button>
							</React.Fragment>
						) : null}
						<button
							data-test-id="close-flash"
							type="button"
							className="up-btn btn-white no-shadow"
							onClick={self.close}
						>
							<b className="fa fa-times" /> {self.lang.close}
						</button>
					</div>

					{content}
				</div>
			</div>
		);
	}
});

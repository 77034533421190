'use strict';

ReactTemplates.creativeTemplates = {};
ReactTemplates.creativeTemplates.root = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			selectBannerLayout: t('ads.selectBannerLayout'),
			selectBannerBody: t('ads.selectBannerBody'),
			doYouHaveBanners: t('ads.doYouHaveBanners'),
			uploadThemHere: t('ads.uploadThemHere'),
			uploadYourOwnBanner: t('ads.uploadYourOwnBanner'),
			setupAccountProfile: t('admin.setupAccountProfile'),
			abort: t('default.abort')
		};
	},
	selectTemplate: function (templateId) {
		var opts = { templateId: templateId };
		if (this.props.tools.$stateParams.resolveKey) {
			opts.resolveKey = this.props.tools.$stateParams.resolveKey;
		}
		this.props.tools.$state.go('creativeEditor', opts);
	},
	selectBanners: function () {
		var self = this;
		var adCampaign = self.getCampaignFromStorage();
		self.props.tools.$upModal
			.open('selectCreatives', { creatives: adCampaign.creative })
			.then(function (selectedCreatives) {
				adCampaign.creative = [];
				_.forEach(selectedCreatives, function (c) {
					adCampaign.creative.push({
						creativeId: c.id,
						active: true
					});
				});
				self.returnToCampaign(adCampaign);
			});
	},
	returnToCampaign: function (adCampaign) {
		this.props.tools.localStorageService.add('activeAdCampaign', adCampaign);
		this.props.tools.$state.go('editAd', { fromStorage: true, id: null });
	},
	getCampaignFromStorage: function () {
		return this.props.tools.localStorageService.get(
			'adCampaign-snapshot-' + this.props.tools.$stateParams.resolveKey
		);
	},
	goBackToCampaign: function () {
		var adCampaign = this.getCampaignFromStorage();
		this.returnToCampaign(adCampaign);
	},
	uploadBanners: function () {
		var self = this;
		self.props.tools.$upModal.open('uploadCreative', {}).then(function (uploadedCreative) {
			if (self.props.tools.$stateParams.resolveKey) {
				var adCampaign = self.getCampaignFromStorage();
				adCampaign.creative.push({
					creativeId: uploadedCreative.id,
					active: true
				});
				self.returnToCampaign(adCampaign);
			} else {
				self.props.tools.$state.go('listAds', { tab: 'banners' });
			}
		});
	},
	selectOrUpload: function () {
		var self = this;
		// If we have a resolveKey we can select existing banners
		// if(self.props.tools.$stateParams.resolveKey) {
		// self.selectBanners();
		// } else {
		self.uploadBanners();
		// }
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	setupProfile: function () {
		this.props.tools.$state.go('administration.accountProfile');
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var templates = [];
		var cardContent;
		var footer;
		var classes = 'grey';

		if (!rootData.accountProfile) {
			cardContent = (
				<div className="card-content">
					<div className="placeholder">
						<div className="inner-wrap">
							<h2>{self.lang.setupAccountProfile}</h2>
							<p>
								{self.lang.selectBannerBody}
								<br />
								<br />
								{self.lang.doYouHaveBanners}
								<button className="up-btn btn-link btn-bright-blue" onClick={self.selectOrUpload}>
									{self.lang.uploadThemHere}
								</button>
							</p>

							<div className="btn-wrap btn-wrap-3">
								<div className="btn-inner-wrap"></div>
								<div className="btn-inner-wrap">
									<button
										type="button"
										className="up-btn btn-bright-blue"
										onClick={self.setupProfile}
									>
										{self.lang.setupAccountProfile}
									</button>
								</div>
								<div className="btn-inner-wrap"></div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			if (rootData.templates) {
				templates = rootData.templates.map(function (template, i) {
					return React.createElement(ReactTemplates.creativeTemplates.template, {
						template: template,
						key: 'template-' + i,
						selectTemplate: self.selectTemplate,
						accountProfile: rootData.accountProfile,
						tools: tools
					});
				});

				var uploadElement = (
					<div
						className="frame-wrap package-box cover-img upload-package"
						key="upload"
						onClick={self.selectOrUpload}
					>
						<b className="fa fa-cloud-upload"></b>
						{self.lang.uploadYourOwnBanner}
					</div>
				);
				templates.push(uploadElement);
			}

			cardContent = (
				<div className="card-content">
					<h2>{self.lang.selectBannerLayout}</h2>

					<p>
						{self.lang.selectBannerBody}
						<br />
						<br />
						{self.lang.doYouHaveBanners}
						<button className="up-btn btn-link btn-bright-blue" onClick={self.selectOrUpload}>
							{self.lang.uploadThemHere}
						</button>
					</p>

					<div>{templates}</div>
				</div>
			);

			if (this.props.tools.$stateParams.resolveKey) {
				classes += ' has-footer';
				footer = (
					<div id="card-footer">
						<div className="pull-right" id="buttons">
							<button className="up-btn btn-grey btn-link" onClick={self.goBackToCampaign}>
								{self.lang.abort}
							</button>
						</div>
					</div>
				);
			}
		}

		return (
			<div id="card-main-view" className={classes}>
				{cardContent}
				{footer}
			</div>
		);
	}
});

'use strict';

ReactTemplates.admin.accountSettings = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			accountSettings: t('admin.accountSettings'),
			credits: t('mail.credits'),
			creditsInfo: t('admin.creditsInfo'),
			availableCredits: t('admin.availableCredits'),
			service: t('admin.service'),
			refilledEveryMonth: t('admin.refilledEveryMonth'),
			billing: t('admin.billing'),
			invoiceHistory: t('admin.invoiceHistory'),
			dueDate: t('admin.dueDate'),
			invoiceAddress: t('admin.invoiceAddress'),
			streetAddress: t('address.streetAddress'),
			city: t('address.city2'),
			zipCode: t('address.zip'),
			makeContact: t('default.makeContact'),
			billingQuestion: t('admin.billingQuestion')
		};
	},
	invoicePropChanged: function (key, e) {
		this.props.rootData.invoicePropChanged(key, e.target.value);
	},
	number: function (n) {
		return this.props.tools.$filter('number')(n);
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var invoiceInfo = rootData.pageData.invoiceInfo;

		// var invoices = _.map(rootData.pageData.invoices, function(invoice, i) {
		// 	return <tr key={'invoice-'+i}>
		// 		<td>{invoice.dueDate}</td>
		// 	</tr>;
		// });

		var services = _.map(rootData.pageData.services, function (service, i) {
			var classNames = null;
			if (!service.credits) {
				classNames = 'text-red';
			}
			return (
				<tr key={'service-' + i}>
					<td>{service.name}</td>
					<td className={classNames}>{self.number(service.credits)}</td>
					<td>
						{service.autoRefill ? (
							<i className="text-upsales-grey">{self.lang.refilledEveryMonth}</i>
						) : null}
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-accountsettings">
				<div className="admin-page-header">
					<div className="page-header-text-wrap">
						<div className="page-header-title">{self.lang.accountSettings}</div>
					</div>
				</div>

				<div id="admin-content">
					<div className="admin-section">
						<h2>{self.lang.billing}</h2>

						<div className="row">
							<div className="col-md-5">
								<span className="text-upsales-grey" style={{ fontSize: '12px' }}>
									{self.lang.invoiceAddress.toUpperCase()}:
								</span>

								<div className="row form-group">
									<div className="col-md-12">
										<label>{self.lang.streetAddress}</label>
										<input
											type="text"
											value={invoiceInfo.address}
											className="form-control"
											onChange={self.invoicePropChanged.bind(self, 'address')}
										/>
									</div>
								</div>

								<div className="row form-group">
									<div className="col-md-8">
										<label>{self.lang.city}</label>
										<input
											type="text"
											value={invoiceInfo.state}
											className="form-control"
											onChange={self.invoicePropChanged.bind(self, 'state')}
										/>
									</div>
									<div className="col-md-4">
										<label>{self.lang.zipCode}</label>
										<input
											type="text"
											value={invoiceInfo.zipCode}
											className="form-control"
											onChange={self.invoicePropChanged.bind(self, 'zipCode')}
										/>
									</div>
								</div>

								<div className="text-upsales-grey">{self.lang.billingQuestion}</div>
								<div>
									<span className="text-upsales-grey">{self.lang.makeContact}</span>{' '}
									<a href="mailto:support@upsales.com" target="_top">
										{'support@upsales.com'}
									</a>
								</div>
							</div>

							{false ? (
								<div className="col-md-7">
									<div className="admin-table">
										<div className="admin-table-top">
											<span className="admin-table-title">{self.lang.invoiceHistory}</span>
										</div>

										<table>
											<thead>
												<tr>
													<th>{self.lang.dueDate}</th>
												</tr>
											</thead>
											<tbody>{invoices}</tbody>
										</table>
									</div>
								</div>
							) : null}
						</div>
					</div>

					<div className="admin-section">
						<h2>{self.lang.credits}</h2>
						<i className="text-upsales-grey">{self.lang.creditsInfo}</i>
					</div>

					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{self.lang.availableCredits}</span>
						</div>

						<table>
							<thead>
								<tr>
									<th>{self.lang.service}</th>
									<th>{self.lang.availableCredits}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{services}</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
});

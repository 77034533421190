import React from 'react';
import PropTypes from 'prop-types';
import CriteriaDrilldownWrapper from '../CriteriaDrilldownWrapper';
import OrderValueSelect from './OrderValueSelect';
import t from 'Components/Helpers/translate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { insertAfter } from 'App/babel/store/helpers/array';
import BooleanInput from './Boolean';
import ToggleList from './ToggleList';
import { Equals, EqualsAll } from 'Resources/ComparisonTypes';
import ListInput from './ListInput';
import DateColumn from './Shared/DateColumn';

const dateFields = ['StartDate', 'InvoiceStartdate', 'RenewalDate', 'Enddate', 'NextOrderDate'];
const futureDates = ['RenewalDate', 'NextOrderDate'];

export default class AgreementDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const AppService = getAngularModule('AppService');
		const FeatureHelper = getAngularModule('FeatureHelper');

		const translateMap = {
			StartDate: 'default.startDate',
			InvoiceStartdate: 'agreement.startDateCurrentPeriod',
			RenewalDate: 'agreement.renewalDate',
			Enddate: 'default.endDate',
			NextOrderDate: 'agreement.nextOrderDate',
			date: 'default.date',
			products: 'default.products',
			yearlyValue: 'default.yearlyValue'
		};
		this.lang = {
			...Object.keys(translateMap).reduce((acc, f) => ({ ...acc, [f]: t(translateMap[f]) + ':' }), {}),
			min: t('default.min'),
			max: t('default.max'),
			from: t('default.from'),
			to: t('default.to'),
			selectProduct: t('default.select') + ' ' + t('default.product').toLowerCase(),
			selectUser: Tools.$translate('default.choose') + ' ' + Tools.$translate('default.user').toLowerCase(),
			active: t('default.active'),
			inactive: t('default.inactive'),
			all: t('default.all'),
			any: t('default.any'),
			users: Tools.$translate('default.users') + ':'
		};

		this.onUserChange = this.onChange.bind(this, 'user');
		this.onProductChange = this.onChange.bind(this, 'product');
		this.onProductComparisonTypeChange = this.onChange.bind(this, 'productComparisonType');
		this.onCurrencyChange = this.onChange.bind(this, 'currency');
		this.onValueChange = this.onChange.bind(this, 'value');

		this.datePresets = props.config.presets;

		const futurePresets = [
			'whenever',
			'fromToday',
			'currentWeek',
			'currentMonth',
			'currentQuarter',
			'currentYear',
			'nextDay',
			'nextWeek',
			'nextMonth',
			'nextQuarter',
			'nextYear',
			'nextXdays',
			'nextXweeks',
			'nextXmonths',
			'nextXyears',
			'custom'
		];

		const brokenFiscalYearEnabled =
			AppService.getMetadata().params.brokenFiscalYearEnabled &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR);

		if (brokenFiscalYearEnabled) {
			insertAfter(futurePresets, 'currentQuarter', 'currentFiscalQuarter');
			insertAfter(futurePresets, 'currentYear', 'currentFiscalYear');
			insertAfter(futurePresets, 'nextQuarter', 'nextFiscalQuarter');
			insertAfter(futurePresets, 'nextYear', 'nextFiscalYear');
		}

		this.datePresetsFuture = _.filter(this.datePresets, preset => {
			return futurePresets.indexOf(preset) >= 0;
		});

		this.onActiveChange = this.onChange.bind(this, 'active');
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'user':
				if (newFilter.value.User) {
					newFilter.value.User.value = value;
				} else {
					newFilter.value.User = { value };
				}
				break;
			case 'product':
				newFilter.value.AgreementRowProduct.value = value;

				break;
			case 'productComparisonType':
				newFilter.value.AgreementRowProduct.comparisonType = value;

				break;
			case 'value':
				newFilter.value = _.assign(newFilter.value, value);

				break;
			case 'active':
				newFilter.value.Active.value = value;

				break;
			default:
				throw new Error('Should never get here');
		}

		onChange(newFilter.filterName, newFilter);
	}

	onDateChange(field, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);
		newFilter.value[field].value = value;
		onChange(newFilter.filterName, newFilter);
	}

	renderDateRow(field) {
		const { filter } = this.props;
		const isFutureDate = futureDates.indexOf(field) >= 0;
		const presets = isFutureDate ? this.datePresetsFuture : this.datePresets;

		return (
			<tr key={'date-row-' + field}>
				<td className="title-col">
					<div className="drilldown-row">{this.lang[field] || field}</div>
				</td>
				<DateColumn
					config={{ presets }}
					filter={filter.value[field]}
					onChange={({ value }) => this.onDateChange(field, value)}
				/>
				<td className="remove-filter-col" />
			</tr>
		);
	}

	render() {
		const { filter, open, fakeConfigs, selectData } = this.props;
		const productSelect = {
			placeholder: this.lang.selectProduct,
			config: fakeConfigs.ProductProductCategory,
			defaultValue: filter.value.AgreementRowProduct.value,
			onChange: this.onProductChange,
			selectData: selectData
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.all
				}
			],
			onClick: this.onProductComparisonTypeChange,
			value: filter.value.AgreementRowProduct.comparisonType || Equals
		};

		const userSelect = {
			placeholder: this.lang.selectUser,
			config: fakeConfigs.User,
			defaultValue: filter.value.User?.value || [],
			onChange: this.onUserChange,
			selectData: selectData,
			select2: {
				formatSelection: function (obj, el, escape) {
					const escapedName = escape(obj.name);
					return obj.isRole ? `<b>${escapedName}</b>` : escapedName;
				}
			}
		};

		return (
			<CriteriaDrilldownWrapper
				key={filter.filterName + '-drilldown'}
				className="agreement-drilldown"
				open={open}
			>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/*Active row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.active}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<BooleanInput
													onChange={this.onActiveChange}
													options={[
														{ key: this.lang.all, value: null },
														{ key: this.lang.active, value: true },
														{ key: this.lang.inactive, value: false }
													]}
													filter={filter.value.Active || { value: null }}
												/>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{dateFields.map(field => this.renderDateRow(field))}
								{/*Yearly value row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.yearlyValue}</div>
									</td>
									<td className="table-col">
										<OrderValueSelect
											onChange={this.onValueChange}
											value={_.pick(filter.value, ['Value', 'Currency'])}
										/>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{/*User row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.users}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ListInput {...userSelect} />
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
								{/*Product row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.products}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ToggleList
													list={productSelect}
													toggle={toggleProps}
													isListActive={() => filter.value.AgreementRowProduct.value.length}
												/>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

AgreementDrilldown.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectData: PropTypes.object.isRequired,
	fakeConfigs: PropTypes.object.isRequired
};

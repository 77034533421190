import React from 'react';
import moment from 'moment';
import { Card, CardContent, Link, Title, Row, Button, Text } from '@upsales/components';
import './UpsalesSupportCard.scss';
import callFromWebConfigHelper from 'Components/Helpers/CallFromWebConfigHelper';
import InAppChat from 'App/babel/services/InAppChat';
import { phoneNumberHelper } from '@upsales/common';

const formatPhone = nr => {
	return phoneNumberHelper.formatNumber(nr, 'SE', 1);
};

const inAppChat = () => {
	InAppChat.open();
};

const UpsalesSupportCard = ({ style, className = '' }) => {
	const t = Tools.$translate;

	var supportNr = '+468 505 806 01';
	var supportEmail = 'support@upsales.com';
	var now = moment();
	var supportOpen =
		now.isAfter(moment().set('hours', '08').set('minutes', '00')) &&
		now.isBefore(moment().set('hours', '17').set('minutes', '00')) &&
		InAppChat.isEnabledAndLoaded();
	const phoneProps = callFromWebConfigHelper('Upsales Support', 'account', supportNr);

	return (
		<Card className={`${className} UpsalesSupportCard`} style={{ ...style }}>
			<div className="CardHeader CardHeader--has-subtitle">
				<div className="CardHeader__title-wrap">
					<Title>{t('admin.support')}</Title>
					<Text color="grey-10">{`${t('date.weekDays')} ${t('default.workHours')}`}</Text>
				</div>
			</div>

			<CardContent className="UpsalesSupportCard--CardContent">
				<Row>
					<Link className="UpsaleSupportCard--Link" href={'mailto:' + supportEmail} target="_top">
						{supportEmail}
					</Link>
				</Row>
				<Row>
					<Link
						className="UpsaleSupportCard--Link"
						href={phoneProps.href}
						onClick={phoneProps.onClick}
						target="_top"
					>
						{formatPhone(supportNr)}
					</Link>
				</Row>
			</CardContent>
			{supportOpen && (
				<Button block={true} shadow="none" color="white" onClick={inAppChat} className="widget-btn">
					{t('onboarding.chattWithUpsales')}
				</Button>
			)}
		</Card>
	);
};

export default UpsalesSupportCard;

import Resource from './Resource';

class SupportToken extends Resource {
	constructor() {
		super('supportToken');
	}

	hasSession() {
		return super._getRequest('hasSession/').then(d => d.data);
	}
}

const resource = new SupportToken();

export default resource;

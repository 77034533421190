import React from 'react';
import { connect } from 'react-redux';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { currencyFormat, numberFormatShort } from '../Filters/Currencies';
import { setOrder } from 'Store/reducers/PeriodizationReducer';
import './PeriodizationChart.scss';
import { Label, Text, Button } from '@upsales/components';
import Order from 'App/resources/Model/Order';
import Hs from 'highcharts';
import moment from 'moment';

interface Chart {
	new (...args: any): any;
}
const Highcharts = (Hs as unknown) as {
	Chart: Chart;
};

type State = {
	Periodization: {
		startDate: Date;
		endDate: Date;
		chartData: { month: number; purchaseCost: number; value: number; year: number }[];
	};
};

type Props = {
	chartData: { month: number; purchaseCost: number; value: number; year: number }[];
	edit: (saveFirst?: boolean) => void;
	endDate: Date;
	order: Order;
	setOrder: (order: Order) => void;
	startDate: Date;
};

const mapStateToProps = (state: State) => ({
	startDate: state.Periodization.startDate,
	endDate: state.Periodization.endDate,
	chartData: state.Periodization.chartData
});

const mapDispatchToProps = {
	setOrder
};

class PeriodizationChart extends React.Component<Props> {
	hasContributionMargin: boolean;
	_chart?: HTMLDivElement | null;
	lang?: { [k: string]: string };
	constructor(props: Props) {
		super(props);
		this.props.setOrder(this.props.order);

		this.hasContributionMargin =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CONTRIBUTION_MARGIN) &&
			Tools.AppService.getMetadata().params.UseContributionMargin;
	}

	componentDidMount() {
		this.renderChart();
	}

	componentDidUpdate() {
		this.renderChart();
	}

	renderChart() {
		if (this._chart) {
			const { order, chartData } = this.props;
			const series: { data: { y: number; color: string }[]; pointPadding?: number }[] = [{ data: [] }];

			if (this.hasContributionMargin) {
				series.push({ data: [], pointPadding: 0.2 });
			}

			let greatestNumber = 0;

			chartData.forEach(entry => {
				greatestNumber = entry.value > greatestNumber ? entry.value : greatestNumber;
				series[0].data.push({
					y: entry.value,
					color: colorMappings.get('bright-blue')
				});
				if (this.hasContributionMargin) {
					series[1].data.push({
						y: entry.purchaseCost,
						color: colorMappings.get('red')
					});
				}
			});

			const config = {
				chart: {
					type: 'column',
					backgroundColor: 'transparent',
					margin: [10, 0, 3, 40],
					height: 80,
					spacing: [0, 0, 0, 0]
				},
				title: { text: null },
				credits: { enabled: false },
				yAxis: {
					title: { text: null },
					gridLineWidth: 0,
					tickInterval: greatestNumber / 3,
					max: greatestNumber,
					endOnTick: false,
					startOnTick: false,
					labels: {
						rotation: 0,
						y: 0,
						useHTML: true,
						formatter: function (this: { value: number }): string {
							return `<div class="PeriodizationChart__value-label">
								<div class="Text Text--left Text--grey-10">${numberFormatShort(this.value)}</div>
							</div>`;
						}
					}
				},
				xAxis: {
					type: 'category',
					title: { text: null },
					tickWidth: 0
				},
				legend: { enabled: false },
				plotOptions: {
					series: {
						borderWidth: 0
					},
					column: {
						grouping: false,
						shadow: false,
						borderRadius: 2,
						opacity: 0.5,
						states: {
							hover: {
								brightness: 0,
								halo: false
							}
						}
					}
				},
				tooltip: {
					borderColor: 'transparent',
					backgroundColor: '#1E252E',
					shadow: false,
					useHTML: true,
					formatter: function (this: { x: number; y: number }): string {
						const year = chartData[this.x].year;
						const month = chartData[this.x].month;
						const monthText = moment(year + ' ' + month, 'YYYY MM').format('MMM YYYY');

						return `<div class="PeriodizationChart__tooltip">
							<div class="Text Text--white">${monthText + ': ' + currencyFormat(this.y, order.currency)}</div>
						</div>`;
					}
				},
				series
			};
			new Highcharts.Chart(this._chart, config);
		}
	}

	render() {
		const t = Tools.$translate;
		this.lang = {
			periodization: t('default.periodization'),
			edit: t('default.edit')
		};

		const { startDate, endDate, chartData } = this.props;
		const hasData = chartData && chartData.length ? true : false;
		const isLoaded = !!startDate && hasData;

		if (!isLoaded) {
			return <div />;
		}

		return (
			<div>
				<div className="PeriodizationChart__header">
					<Label className="pull-left PeriodizationChart__title">{this.lang.periodization}</Label>
					<Button
						type="link"
						className="pull-right PeriodizationChart__edit-button"
						shadow="none"
						onClick={() => {
							this.props.edit();
						}}
					>
						{this.lang.edit}
					</Button>
				</div>
				<div className="PeriodizationChart" ref={r => (this._chart = r)} />
				<div>
					<Text className="PeriodizationChart__legend">
						{moment(startDate).format('D MMM YYYY') + '  >  ' + moment(endDate).format('D MMM YYYY')}
					</Text>
				</div>
			</div>
		);
	}
}

export const detached = PeriodizationChart;
const Component = connect(mapStateToProps, mapDispatchToProps)(PeriodizationChart);

export default Component;

'use strict';
import ActivityHistoryLog from 'App/components/HistoryLog/ActivityHistoryLog';
import setupComponent from 'App/babel/SetupRootComponent';

angular.module('upDirectives').directive('upActivityTimelineRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			const firstElement = $element[0];

			if (firstElement) {
				setupComponent(
					$scope,
					ActivityHistoryLog,
					firstElement,
					{
						activity: $scope.$eval($attr.upActivityTimelineRoot)
					},
					{
						redux: true,
						watch: [
							{
								attr: $attr.upActivityTimelineRoot,
								prop: 'activity'
							}
						]
					}
				);
			}
		}
	};
});

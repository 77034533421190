import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Card,
	ButtonGroup,
	ButtonSelect,
	Label,
	Block,
	Row,
	Input,
	Icon,
	Help,
	Text,
	Column,
	Title,
	Button,
	RadioList,
	RadioItem,
	Select,
	Tooltip
} from '@upsales/components';
import EditProductInputWrap from './EditProductInputWrap';
import EditProductTierRow from './EditProductTierRow';
import EditProductTierEndRow from './EditProductTierEndRow';
import T from 'Components/Helpers/translate';
import {
	setPriceType,
	setProduct,
	setTierType,
	setPriceList,
	addTierRow,
	removeTierRow,
	updateTierRow
} from 'Store/actions/AdminEditProductActions';
import UpSelect from 'Components/Inputs/UpSelect';
import { PRICE_TYPE, TIER_TYPE } from 'Store/reducers/AdminEditProductReducer';

import './EditProductPriceCard.scss';
import bemClass from '../../../../../node_modules/@upsales/components/Utils/bemClass';

const PRICE = 'price';
const CONTRIBUTION_MARGIN = 'purchaseCost';

const mapStateToProps = ({ AdminEditProduct }) => ({
	product: AdminEditProduct.product,
	currencies: AdminEditProduct.currencies,
	priceList: AdminEditProduct.priceList,
	priceType: AdminEditProduct.priceType,
	masterCurrency: AdminEditProduct.masterCurrency,
	multiCurrency: AdminEditProduct.multiCurrency,
	tierType: AdminEditProduct.tierType,
	canAddTierRow: AdminEditProduct.canAddTierRow,
	contributionMarginActive: AdminEditProduct.contributionMarginActive,
	errors: AdminEditProduct.errors
});

const mapDispatchToProps = {
	setPriceType,
	setProduct,
	setTierType,
	setPriceList,
	addTierRow,
	removeTierRow,
	updateTierRow
};

class EditProductPriceCard extends React.Component {
	constructor(props) {
		super(props);
		this.priceTypes = {
			[PRICE]: { value: PRICE, title: T('default.price') },
			[CONTRIBUTION_MARGIN]: { value: CONTRIBUTION_MARGIN, title: T('default.purchasedAtCost') }
		};
		this.priceTypeOptions = Object.keys(this.priceTypes).map(k => this.priceTypes[k]);
		this.tierTypeOptions = [
			{ value: TIER_TYPE.UNIT, title: T('product.unitPrice') },
			{ value: TIER_TYPE.TOTAL, title: T('product.totalPrice') }
		];

		this.priceLists = Tools.AppService.getPriceLists();

		this.state = {
			activePriceType: this.priceTypes[PRICE],
			editedCurrency: this.props.masterCurrency.iso,
			mounted: false
		};

		this.otherCurrencies = _.filter(this.props.currencies, currency => {
			return !currency.masterCurrency && currency.active;
		});
	}

	componentDidMount() {
		this.selectAnchor = document.querySelector('.EditProduct__content-wrapper');
		this.setState({ mounted: true });
	}

	changePriceList = ({ id }) => {
		this.props.setPriceList(this.priceLists.find(priceList => priceList.id === id));
	};

	changeMaxDiscount = e => {
		const newValue = e.target.value;
		if (isNaN(newValue)) {
			return;
		}

		const newMaxDiscount = newValue === '' ? null : Number(newValue) / 100;

		const product = this.props.product;
		const updatedCurrencies = product.currencies[this.props.priceList.id].map(currency => ({
			...currency,
			maxDiscount: newMaxDiscount
		}));

		this.props.setProduct({
			...product,
			currencies: { ...product.currencies, [this.props.priceList.id]: updatedCurrencies }
		});
	};

	extendTierDiscount = editedIndex => {
		const product = this.props.product;
		const currentTiers = product.tiers[this.props.priceList.id];
		const discountToExtend = currentTiers[editedIndex].maxDiscount;

		const updatedTiers = currentTiers.map((tier, index) => {
			if (index <= editedIndex) return tier;

			return {
				...tier,
				maxDiscount: discountToExtend
			};
		});

		this.props.setProduct({
			...product,
			tiers: { ...product.tiers, [this.props.priceList.id]: updatedTiers }
		});
	};

	changeValue = (currency, field, e) => {
		const product = { ...this.props.product };
		const currencyObj = _.find(this.props.currencies, { iso: currency });
		const value = Number(e.target.value);
		const displayValue = e.target.value;
		const priceOrCost = field === 'price' ? 'listPrice' : 'purchaseCost';

		let priceOrCostValue = product[priceOrCost];
		if (currencyObj.masterCurrency && this.props.priceList.isDefault) {
			priceOrCostValue = value;
		}

		let priceListCurrencies = [...product.currencies[this.props.priceList.id]];
		const i = _.findIndex(priceListCurrencies, { currency });

		if (i !== -1) {
			priceListCurrencies[i] = {
				...priceListCurrencies[i],
				[field]: value,
				['display' + _.capitalize(field)]: displayValue
			};
		} else {
			const c = {
				currency,
				[field]: value,
				['display' + _.capitalize(field)]: displayValue,
				priceListId: this.props.priceList.id
			};
			priceListCurrencies = [...priceListCurrencies, c];
		}
		this.props.setProduct({
			...product,
			[priceOrCost]: priceOrCostValue,
			currencies: { ...product.currencies, [this.props.priceList.id]: priceListCurrencies }
		});
	};

	renderCurrencyRow(currency, i) {
		const { product, disabled, priceList } = this.props;
		const { activePriceType } = this.state;
		const isMasterCurrency = currency.masterCurrency;
		const productCurrency = product.currencies[this.props.priceList.id].find(c => c.currency === currency.iso);

		const fallbackValue = isMasterCurrency && priceList.isDefault ? product[activePriceType.value] ?? 0 : 0;
		const value = productCurrency?.[activePriceType.value] || fallbackValue;
		const displayValue = productCurrency?.['display' + _.capitalize(activePriceType.value)];

		return (
			<Column
				size={isMasterCurrency ? 12 : 6}
				className="EditProductPriceCard__currency"
				key={'currency-row-' + activePriceType.value + i}
			>
				<EditProductInputWrap>
					<Label required={isMasterCurrency && activePriceType.value === PRICE}>
						{activePriceType.title} {currency.iso}
					</Label>
					<ButtonGroup block={true}>
						<Input
							type="number"
							state={isMasterCurrency && value == null ? 'error' : null}
							onChange={e => this.changeValue(currency.iso, activePriceType.value, e)}
							value={displayValue ?? value}
							disabled={disabled}
							placeholder={'0'}
						/>
						<Block
							className="EditProductPriceCard__currency-addon"
							border="ts rs bs"
							borderColor="grey-6"
							backgroundColor="grey-4"
						>
							<Text color="grey-10" size="sm">
								{currency.iso}
							</Text>
						</Block>
					</ButtonGroup>
				</EditProductInputWrap>
			</Column>
		);
	}

	renderPriceTypeSwitcher() {
		return (
			<Block space="mbl">
				<ButtonSelect
					value={this.state.activePriceType.value}
					options={this.priceTypeOptions}
					onChange={t => this.setState({ activePriceType: this.priceTypes[t] })}
					disabled={this.props.disabled}
				/>
			</Block>
		);
	}

	renderTierTypeSwitcher() {
		return (
			<Block space="mbl">
				<ButtonSelect
					value={this.props.tierType}
					options={this.tierTypeOptions}
					onChange={t => this.props.setTierType(t)}
					disabled={this.props.disabled}
				/>
			</Block>
		);
	}

	render() {
		const classes = new bemClass('EditProductPriceCard');
		const hasTiers = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_TIERS);
		const hasPriceLists =
			Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);
		const hasMaxDiscount =
			Tools.FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAX_DISCOUNT);

		const {
			masterCurrency,
			multiCurrency,
			contributionMarginActive,
			priceType,
			setPriceType,
			currencies,
			addTierRow,
			product,
			canAddTierRow,
			disabled,
			updateTierRow,
			removeTierRow,
			errors,
			tierType,
			priceList
		} = this.props;
		const isCardLayout = hasTiers || contributionMarginActive;
		const currentTiers = product.tiers[priceList.id] || [];
		const showEndPlaceholder = currentTiers.length && currentTiers[currentTiers.length - 1].end;
		const productCurrency = product.currencies[priceList.id].find(c => c.currency === this.state.editedCurrency);

		const fallbackValue =
			this.state.editedCurrency === masterCurrency.iso && priceList.isDefault ? product.purchaseCost : 0;
		const contributionMarginValue = productCurrency?.[CONTRIBUTION_MARGIN] || fallbackValue;

		const activeCurrencies = currencies.filter(currency => currency.active);

		const lockTier = !!(product.category && product.category.calculatingField);
		return (
			<Card className={classes.b()} space="mlxl" color={isCardLayout ? 'white' : 'transparent'}>
				{hasTiers ? (
					<Block border="bs" borderColor="grey-4" space="ptxl prxl pbl plxl">
						<Title>{T('product.pricing')}</Title>
						<RadioList size="sm" onChange={value => setPriceType(value)}>
							<RadioItem
								checked={priceType === PRICE_TYPE.PER_UNIT}
								value={PRICE_TYPE.PER_UNIT}
								disabled={disabled}
							>
								<Text color="black">{T('product.perUnit')}</Text>
							</RadioItem>
							<RadioItem
								checked={priceType === PRICE_TYPE.TIERED}
								value={PRICE_TYPE.TIERED}
								disabled={disabled || lockTier}
							>
								<Tooltip disabled={!lockTier} title={T('product.tieredCalcCategoryWarning')}>
									<Text color={lockTier ? 'grey-10' : 'black'}>{T('product.tiered')}</Text>
								</Tooltip>
							</RadioItem>
						</RadioList>
						{hasPriceLists ? (
							<Block space="mts">
								<Title>{T('default.priceList')}</Title>
								<Select
									className={classes.elem('price-list').b()}
									options={this.priceLists
										.filter(priceList => priceList.active)
										.map(priceList => ({ id: priceList.id, title: priceList.name }))}
									value={{ id: priceList.id, title: priceList.name }}
									onChange={this.changePriceList}
									state={errors.tier ? 'warning' : ''}
									disabled={disabled}
									anchor={this.selectAnchor}
								/>
								{errors.priceLists.length ? (
									<Text color="red" size="sm">
										<Icon name="exclamation-triangle" space="mrs" />
										{T('product.priceListsWithErrors', { errorPriceLists: errors.priceLists })}
									</Text>
								) : null}
							</Block>
						) : null}
					</Block>
				) : null}
				<Block space={isCardLayout ? 'ptxl prxl plxl' : 'prxl plxl'}>
					{priceType === PRICE_TYPE.PER_UNIT ? (
						<div>
							{contributionMarginActive ? this.renderPriceTypeSwitcher() : null}
							<Row>{this.renderCurrencyRow(masterCurrency, -1)}</Row>
							{this.otherCurrencies.length && multiCurrency ? (
								<Row>{this.otherCurrencies.map((c, i) => this.renderCurrencyRow(c, i))}</Row>
							) : null}
							{hasMaxDiscount ? (
								<EditProductInputWrap>
									<Label>{T('default.maxDiscount')}</Label>
									<Help className={classes.elem('max-discount-help').b()} articleId={1472} />
									<Input
										value={displayMaxDiscount(
											this.props.product.currencies[this.props.priceList.id][0]?.maxDiscount
										)}
										placeholder="%"
										onChange={this.changeMaxDiscount}
										clear
										disabled={disabled}
									/>
								</EditProductInputWrap>
							) : null}
						</div>
					) : (
						<div>
							{this.renderTierTypeSwitcher()}
							<Block space="mbl">
								<Text color="grey-10">
									{T(tierType === TIER_TYPE.UNIT ? 'product.tierInfoUnit' : 'product.tierInfoTotal')}
								</Text>
								<Help sidebar={true} articleId={1133}>
									{T('default.learnMore')}
								</Help>
							</Block>
							<Row>
								<Column className={classes.elem('padded-col').b()} size={6}>
									<EditProductInputWrap>
										<Label>{T('default.currency')}</Label>
										<UpSelect
											className="form-control"
											onChange={e => {
												this.setState({ editedCurrency: e.target.value });
											}}
											data={activeCurrencies}
											defaultValue={this.state.editedCurrency}
											formatSelection={(o, _c, escape) => escape(o.iso)}
											formatResult={(o, _c, _q, escape) => escape(o.iso)}
											getId={o => o.iso}
											disabled={disabled}
											required
										/>
									</EditProductInputWrap>
								</Column>
								{contributionMarginActive ? (
									<Column
										className={classes
											.elem('padded-col', 'EditProductPriceCard__tier-contribution-margin')
											.b()}
										size={4}
									>
										<EditProductInputWrap>
											<Label>{T('default.purchasedAtCost')}</Label>
											<Input
												type="number"
												value={contributionMarginValue || ''}
												placeholder="0"
												onChange={e =>
													this.changeValue(this.state.editedCurrency, 'purchaseCost', e)
												}
											/>
										</EditProductInputWrap>
									</Column>
								) : null}
							</Row>
							{currentTiers.map((tier, i) => (
								<EditProductTierRow
									key={`tier-${tier.priceListId}-${i}`}
									tier={tier}
									isFirst={i === 0}
									isLast={i === currentTiers.length - 1}
									onChange={obj => updateTierRow(i, obj)}
									currency={this.state.editedCurrency}
									onRemove={() => removeTierRow(i)}
									disabled={disabled}
									errors={errors.tier ? errors.tier[i] : null}
									extendTierDiscount={() => this.extendTierDiscount(i)}
								/>
							))}
							{showEndPlaceholder ? (
								<EditProductTierEndRow
									lastTier={currentTiers[currentTiers.length - 1]}
									currency={this.state.editedCurrency}
								/>
							) : null}
							<Block border="ts" borderColor="grey-4">
								<Button
									size="lg"
									type="link"
									block={true}
									color={canAddTierRow ? 'bright-blue' : 'grey'}
									disabled={disabled || !canAddTierRow}
									onClick={addTierRow}
								>
									<Icon name="plus-circle" /> {T('product.addTierRow')}
								</Button>
							</Block>
						</div>
					)}
				</Block>
			</Card>
		);
	}
}

export function displayMaxDiscount(maxDiscount) {
	if (maxDiscount === null || maxDiscount === undefined) {
		return '';
	}

	return +(maxDiscount * 100).toFixed(2);
}

EditProductPriceCard.propTypes = {
	setProduct: PropTypes.func.isRequired,
	setPriceType: PropTypes.func.isRequired,
	setTierType: PropTypes.func.isRequired,
	setPriceList: PropTypes.func.isRequired,
	addTierRow: PropTypes.func.isRequired,
	removeTierRow: PropTypes.func.isRequired,
	updateTierRow: PropTypes.func.isRequired,
	product: PropTypes.object.isRequired,
	masterCurrency: PropTypes.object.isRequired,
	priceList: PropTypes.object.isRequired,
	priceType: PropTypes.oneOf([PRICE_TYPE.PER_UNIT, PRICE_TYPE.TIERED]).isRequired,
	tierType: PropTypes.oneOf([TIER_TYPE.UNIT, TIER_TYPE.TOTAL]).isRequired,
	currencies: PropTypes.array,
	contributionMarginActive: PropTypes.bool,
	disabled: PropTypes.bool,
	canAddTierRow: PropTypes.bool,
	multiCurrency: PropTypes.bool,
	errors: PropTypes.object
};
EditProductPriceCard.defaultProps = {
	currencies: [],
	masterCurrency: {},
	contributionMarginActive: false,
	disabled: false,
	multiCurrency: false,
	errors: {}
};

export const detached = EditProductPriceCard;
const Component = connect(mapStateToProps, mapDispatchToProps)(EditProductPriceCard);

export default Component;

import ProjectPlan from 'App/pages/ClientCard/ContentPages/ProjectPlan/ProjectPlan';

type Scope = {
	AccountProjectPlan: typeof ProjectPlan;
};

angular.module('domain.account').controller('AccountProjectPlan', [
	'$scope',
	function ($scope: Scope) {
		$scope.AccountProjectPlan = ProjectPlan;
	}
]);

import React, { useState, useEffect } from 'react';
import { getFileIcon, getFileMeta } from 'App/babel/helpers/file';
import { TableRow, TableColumn, Tooltip, Avatar, Icon, Button, Checkbox, Text } from '@upsales/components';
import fileSize from 'App/babel/helpers/fileSize';
import translate from 'Components/Helpers/translate';
import File from 'App/resources/Model/File';
import Label from 'App/resources/Model/Label';
import moment from 'moment';

interface FileBrowserTableRowProps {
	file: File;
	deleteFile: (file: File) => void;
	isChecked: boolean;
	checkFile: (checked: boolean, file: File) => void;
}

const FileBrowserTableRow = ({ file, deleteFile, isChecked, checkFile }: FileBrowserTableRowProps) => {
	const [dimensions, setDimensions] = useState('');
	const hasFileLabel = Tools.FeatureHelper.hasSoftDeployAccess('FILE_METADATA_LABEL');

	let labels: Label[] = [];
	if (file.labels) {
		labels = [...file.labels];
	}

	if (file.entity === 'Contact' || file.entity === 'Client') {
		labels.push({ name: translate('file.label.standard.customerCard'), entity: 'FileMetadata' });
	} else if (file.entity === 'ProfileImage') {
		labels.push({ name: translate('file.label.standard.profilePictures'), entity: 'FileMetadata' });
	}

	useEffect(() => {
		let isMounted = true;
		let img: HTMLImageElement;
		if (file.mimetype && file.mimetype.split('/')[0] === 'image') {
			img = getFileMeta(file.$$previewUrl, meta => {
				if (isMounted) {
					setDimensions(`${meta.naturalWidth}x${meta.naturalHeight}`);
				}
			});
		}
		return function cleanup() {
			isMounted = false;
			if (img) {
				img.src = '';
			}
		};
	}, []);

	return (
		<TableRow>
			{hasFileLabel ? (
				<TableColumn style={{ width: '20px' }}>
					<Checkbox
						color="green"
						size="sm"
						checked={isChecked}
						onChange={() => checkFile(!isChecked, file)}
					/>
				</TableColumn>
			) : null}
			<TableColumn title={file.filename} />
			<TableColumn>{dimensions}</TableColumn>
			<TableColumn>
				<Icon name={getFileIcon(file.mimetype, '', '')} /> {file.mimetype.split('/')[1].toUpperCase()}{' '}
				{`(${fileSize(file.size)})`}
			</TableColumn>
			{hasFileLabel ? (
				<TableColumn>
					{labels.map(label => {
						return (
							<Text key={label.name} size="sm">
								{label.name}
							</Text>
						);
					})}
				</TableColumn>
			) : null}
			<TableColumn className="align-middle">
				<Tooltip title={file.user.name} distance={20}>
					<Avatar
						initials={file.user.name}
						email={file.user.email}
						size="sm"
						style={{ verticalAlign: 'middle' }}
					/>
				</Tooltip>
				{' ' + moment(file.uploadDate).format('LL')}
			</TableColumn>
			<TableColumn>
				<Button type="link" color="black" onClick={() => deleteFile(file)}>
					<Icon name="trash" />
				</Button>
			</TableColumn>
		</TableRow>
	);
};

export default FileBrowserTableRow;

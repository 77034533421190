import React, { useEffect, useState } from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { Icon, Text } from '@upsales/components';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import NavbarDropdown from 'App/navbar/NavbarDropdown';
import NavbarButton from 'App/navbar/NavbarButton';

import './NavbarBrandDropdown.scss';
import { useSelectedBrandId, useSelector } from 'App/components/hooks';
import { Brand } from 'App/resources/AllIWant';

const NavbarBrandDropdown = () => {
	const { brands } = useSelector(({ App }) => ({
		brands: App.brands.filter(b => b.active).sort(sortAlphabetically('name', false))
	}));
	const selectedBrandId = useSelectedBrandId();
	const selectedBrand = brands.find(brand => brand.id === selectedBrandId) || brands[0];
	const [brandIsChanging, setBrandIsChanging] = useState(false);

	useEffect(() => {
		const changeStartListener = Tools.$rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
			if (toState.name === 'react-root-change-brand' && !toParams.brandChangeInitiated) {
				event.preventDefault();
				window.history.back();
			}
		});

		const changeSuccessListener = Tools.$rootScope.$on(
			'$stateChangeSuccess',
			async (event, toState, toParams, fromState, fromParams) => {
				if (toState.name === 'react-root-change-brand') {
					await Tools.UserParam.save(59, toParams.id, {
						skipNotification: true,
						skipErrorNotification: false
					});
					Tools.$rootScope.$broadcast('selectedBrand.changed', { id: parseInt(toParams.id) });
					Tools.$state.go(fromState, fromParams, { location: 'replace' });
				}
			}
		);
		return () => {
			changeStartListener();
			changeSuccessListener();
		};
	}, []);

	const selectBrand = async (brand: Brand) => {
		setBrandIsChanging(true);
		const defaultPrevented = Tools.$rootScope.$broadcast(
			'$stateChangeStart',
			Tools.$state.get('react-root-change-brand'),
			{ id: brand.id, brandChangeInitiated: true },
			Tools.$state.current,
			Tools.$state.params,
			{ location: 'replace' }
		).defaultPrevented;

		if (!defaultPrevented) {
			await Tools.UserParam.save(59, brand.id, { skipNotification: true, skipErrorNotification: false });
			Tools.$rootScope.$broadcast('selectedBrand.changed', { id: brand.id });

			/*
				I did not want to have to make special rules for different states but I did not find simple way to fix 
				these cases. listening for selectedBrand.changed in the controllers and doing a state changed there did not 
				work as it bugged out with the reload that was happening in this file;
			*/
			if (Tools.$state.current.name === 'account.market') {
				Tools.$state.go('account.dashboard', Tools.$state.params, { reload: true });
			} else if (Tools.$state.current.name === 'contact.market') {
				Tools.$state.go('contact.dashboard', Tools.$state.params, { reload: true });
			} else {
				Tools.$state.reload();
			}
		}
		setBrandIsChanging(false);
	};

	useEffect(() => {
		if (brands?.length === 1 && brands[0].id !== selectedBrandId && !brandIsChanging) {
			selectBrand(brands[0]);
		}
	}, [brands, selectedBrandId, brandIsChanging]);

	const MainClass = new Bem('NavbarBrandDropdown');

	if (!selectedBrand) {
		return null;
	}

	return (
		<NavbarDropdown
			align="right"
			className={MainClass.b()}
			renderTrigger={({ open, setOpen }) => (
				<NavbarButton
					onMouseDown={() => setOpen(!open)}
					className={MainClass.elem('trigger').mod({ open }).b()}
				>
					<Text color="super-light-green">{selectedBrand.name}</Text>
					{brands.length > 1 ? <Icon name="caret-down" color="super-light-green" /> : null}
				</NavbarButton>
			)}
		>
			{({ setOpen }) => (
				<div className={MainClass.elem('dropdown-menu').b()}>
					{brands.map(brand => {
						if (brand.id === selectedBrandId) {
							return null;
						}
						brand.logo = undefined; //Ignoring logo for now
						const logoStyle = brand.logo ? { backgroundImage: `url(${brand.logo})` } : {};

						return (
							<div
								key={brand.id}
								onClick={() => {
									setOpen(false);
									selectBrand(brand);
								}}
								className={MainClass.elem('dropdown-row').b()}
							>
								<div className={MainClass.elem('dropdown-row-logo').b()} style={logoStyle}>
									{brand.logo ? null : <Text>{brand.name}</Text>}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</NavbarDropdown>
	);
};

export default NavbarBrandDropdown;

import React from 'react';
import moment from 'moment';
import BEM from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { Row, Column, Title, Text, Card, Link, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';

import './InactivationInfo.scss';

type Props = {
	deactivatedBy: number | null;
	deactivationDate: string | null;
	deactivationReason: string | null;
	editAccount: () => void;
};

const InactivationInfo = ({ editAccount, deactivatedBy, deactivationDate, deactivationReason }: Props) => {
	const AppService = getAngularModule('AppService');
	const avatarService = getAngularModule('avatarService');
	const user = AppService.getUsers().find(user => user.id === deactivatedBy);

	const Avatar = ReactTemplates.TOOLS.avatar;
	const CSS = new BEM('AccountInactivationInfo');

	const lang = {
		title: T('account.inactiveInfoTitle'),
		description: T('account.inactiveInfoDescription'),
		companySettings: T('account.inactiveInfoCompanySettings'),
		inactivatedBy: T('account.inactiveInfoInactivatedBy', { date: moment(deactivationDate).format('YYYY-MM-DD') })
	};
	const maxCutofLength = 70;
	// Old inactive accounts may not have this
	const showCard = deactivatedBy && deactivationDate ? true : false;
	let deactivationReasonText = '';
	let showTooltip = false;

	if (showCard && deactivationReason) {
		deactivationReasonText =
			deactivationReason.length > maxCutofLength
				? `"${deactivationReason.substring(0, maxCutofLength)}..."`
				: `"${deactivationReason}"`;
		showTooltip = deactivationReason.length > maxCutofLength;
	}

	return (
		<Card slice={showCard ? 'grey-10' : undefined} className={CSS.b()}>
			<Row>
				<Column className={CSS.elem('left-side').b()}>
					<Title size="lg">{lang.title}</Title>
					<Text color="grey-11">
						<span>{lang.description}</span>
						<Link onClick={editAccount}>{lang.companySettings}</Link>
						<span>{'.'}</span>
					</Text>
				</Column>
				{showCard ? (
					<Column className={CSS.elem('right-side').b()} fixedWidth={300}>
						<Card>
							<Text color="grey-10">{lang.inactivatedBy}</Text>
							{user ? (
								<Row>
									<Avatar size={28} user={user} avatarService={avatarService} />
									<Text>{user.name}</Text>
								</Row>
							) : null}
							<div className={CSS.elem('reason-wrap').b()}>
								{showTooltip ? (
									<Tooltip title={deactivationReason ?? ''} position="left">
										<Text italic={true}>{deactivationReasonText}</Text>
									</Tooltip>
								) : (
									<Text italic={true}>{deactivationReasonText}</Text>
								)}
							</div>
						</Card>
					</Column>
				) : null}
			</Row>
		</Card>
	);
};

export default InactivationInfo;

import Resource from './Resource';

class News extends Resource {
	constructor() {
		super('news');
	}

	getClientNews(clientId: number, params: { subAccountIds?: number[] } = {}) {
		return this._getRequest(`${clientId}`, {
			methodName: 'getClientNews',
			params
		}).then(response => response.data);
	}
}

export default new News();

import logError from 'App/babel/helpers/logError';
import TimelineEntityNote from 'Components/TimelineRow/TimelineEntityNote';

angular.module('domain.contact').controller('ContactMarket', [
	'$scope',
	'$q',
	'$stateParams',
	'RequestBuilder',
	'Event',
	'EventService',
	'AppService',
	'Account',
	'$filter',
	'$translate',
	function ($scope, $q, $stateParams, RequestBuilder, Event, EventService, AppService, Account, $filter, $translate) {
		var ContactCtrl = $scope.ContactCtrl;

		var customerId = $stateParams.customerId;
		var eventOffset = 0;
		var eventLimit = 100;
		var chart, weekPrefix, marketGrouping, nrOfMonths;

		$scope.eventsLoading = false;
		$scope.events = [];
		$scope.totalEvents = 0;
		$scope.eventsLength = 0;

		$scope.totalEventMail = 0;
		$scope.totalEventForm = 0;
		$scope.totalEventVisit = 0;
		$scope.totalAll = 0;

		ContactCtrl.TimelineEntityNote = TimelineEntityNote;

		var makeLegendItem = function (name, kpi, noP, color, icon) {
			if (kpi) {
				icon = '<i class="fa fa-' + icon + '" style="color:' + color + ';"></i>';
			} else {
				icon = '<i class="fa fa-' + icon + '"></i>';
			}
			return (
				'<span style="font-size:18px;">' +
				kpi +
				($scope.marketGrouping === 'score' && !noP ? 'p' : '') +
				'</span><span><br />' +
				icon +
				' ' +
				$translate.instant(name) +
				'</span>'
			);
		};

		var buildChart = function (serieData) {
			var series = [
				{
					name: makeLegendItem('leads.fromForms', $scope.totalForm, false, '#4E0065', 'file-text'),
					type: 'column',
					color: '#4E0065',
					data: serieData.serieForm,
					visible: !$scope.totalLeadScore || !!$scope.totalForm // if score is 0, make the lines visible for the graph to show as a placeholder (looks better...) // else make this column hidden by default
				},
				{
					name: makeLegendItem('flow.openedAndClickedEmail', $scope.totalMail, false, '#721A94', 'envelope'),
					type: 'column',
					color: '#721A94',
					data: serieData.serieMail,
					visible: !$scope.totalLeadScore || !!$scope.totalMail
				},
				{
					name: makeLegendItem('default.siteVisits', $scope.totalVisit, false, '#B254E0', 'globe'),
					type: 'column',
					borderRadiusTopLeft: 5,
					borderRadiusTopRight: 5,
					color: '#B254E0',
					data: serieData.serieVisit,
					visible: !$scope.totalLeadScore || !!$scope.totalVisit
				},
				{
					name: makeLegendItem(
						'leads.fromMarketingCustom',
						$scope.totalMarketingCustom,
						false,
						'#9933C0',
						'bullseye'
					),
					type: 'column',
					borderRadiusTopLeft: 5,
					borderRadiusTopRight: 5,
					color: '#9933C0',
					data: serieData.serieMarketingCustom,
					visible: !$scope.totalLeadScore || !!$scope.totalMarketingCustom
				}
			];

			// attribute is serieData and categories
			$scope.chartConfig = {
				chart: {
					type: 'column',
					marginTop: 60,
					height: 200,
					borderRadius: 5,
					backgroundColor: 'transparent',
					style: {
						fontFamily: '"Roboto"'
					},
					events: {
						init: function () {
							chart = this;
						}
					}
				},
				credits: { enabled: false },
				title: {
					text: ''
				},
				xAxis: {
					categories: serieData.categories,
					lineColor: 'transparent',
					tickLength: 0,
					tickWidth: 0,
					labels: {
						formatter: function () {
							if ($scope.nrOfMonths === 3) {
								var date = moment().week(this.value.substring(5, 7));
								if (this.chart.chartWidth < 1250) {
									return weekPrefix + date.isoWeek();
								}
								return date.startOf('w').format('DD MMM') + ' - ' + date.endOf('w').format('DD MMM');
							} else {
								if (this.chart.chartWidth < 750) {
									return moment(this.value).format("MMM 'YY");
								}
								return moment(this.value).format('MMM YYYY');
							}
						},
						overflow: false,
						rotation: false
					}
				},
				yAxis: [
					{
						min: 0,
						maxPadding: 0.01,
						title: {
							text: ''
						},
						labels: {
							formatter: function () {
								if (this.value > 0) {
									return $filter('numberFormat')(this.value);
								}
							},
							style: {
								color: ' #6B7C93'
							}
						}
					},
					{
						title: {
							text: '',
							style: {
								color: Highcharts.getOptions().colors[2]
							}
						},
						opposite: true,
						labels: {
							formatter: function () {
								if (this.value > 0) {
									return $filter('numberFormat')(this.value);
								}
							},
							style: {
								color: ' #6B7C93'
							}
						}
					}
				],
				legend: {
					align: 'center',
					x: 0,
					verticalAlign: 'top',
					y: -10,
					//layout: 'vertical',
					floating: false,
					backgroundColor: 'transparent',
					borderColor: '#A4B3C7',
					borderWidth: 0,
					symbolWidth: 0,
					symbolHeight: 0,
					symbolRadius: 0,
					shadow: false,
					useHTML: true,
					itemStyle: {
						fontWeight: 'normal',
						fontSize: '11px',
						fontFamily: 'Roboto'
					}
				},
				tooltip: {
					enabled: false
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						borderWidth: 0,
						marker: {
							radius: 2
						}
					},
					series: {
						marker: {
							radius: 2
						},
						point: {
							events: {
								mouseOver: function () {
									if (chart && chart.yAxis[1]) {
										if (this.series.userOptions.yAxis === 1) {
											chart.yAxis[1].update({
												labels: {
													style: {
														color: '#6B7C93',
														fontWeight: 'bold'
													}
												}
											});
										}
									}
								},
								mouseOut: function () {
									if (chart && chart.yAxis[1]) {
										chart.yAxis[1].update({
											labels: {
												style: {
													color: ' #6B7C93',
													fontWeight: 'normal'
												}
											}
										});
									}
								}
							}
						}
					}
				},
				series: series
			};
			$scope.graphLoading = false;
		};

		var mapGraphData = function (data) {
			var keys = Object.keys(data);
			var categories;
			var serieForm = _.range(0, 12, 0);
			var serieMail = _.range(0, 12, 0);
			var serieVisit = _.range(0, 12, 0);
			var serieMarketingCustom = _.range(0, 12, 0);

			if ($scope.nrOfMonths === 12) {
				var month = new Date().getMonth();
				var year = 1900 + new Date().getYear();
				categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (m) {
					var mon = month - m;
					if (mon < 0) {
						return (
							year -
							1 +
							'-' +
							moment()
								.month(mon + 12)
								.format('MM')
						);
					}
					return year + '-' + moment().month(mon).format('MM');
				});
			} else {
				// first day three months ago..
				categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (m) {
					var dateStart = moment().subtract(m, 'w');
					var isoWeek = dateStart.isoWeeks();
					if (isoWeek < 10) {
						isoWeek = '0' + isoWeek;
					}
					return dateStart.format('YYYY') + '-' + isoWeek;
				});
			}

			var countOrScore = $scope.marketGrouping === 'event' ? 'Count' : 'Score';

			_.each(keys, function (key) {
				var index;
				index = categories.indexOf(key);
				if (index !== -1) {
					serieForm[index] = data[key]['form' + countOrScore];
					serieMail[index] = data[key]['mail' + countOrScore];
					serieVisit[index] = data[key]['visit' + countOrScore];
					serieMarketingCustom[index] = data[key]['marketingcustom' + countOrScore];
				}
			});

			buildChart({
				categories: categories,
				serieForm: serieForm,
				serieMail: serieMail,
				serieVisit: serieVisit,
				serieMarketingCustom: serieMarketingCustom
			});
		};

		var getData = function () {
			$scope.graphLoading = true;
			// get data with zero events

			var options = {
				contactId: ContactCtrl.contact.id,
				interval: $scope.nrOfMonths === 3 ? 'week' : 'month',
				startDate: moment().subtract($scope.nrOfMonths, 'month').format('YYYY-MM-DD'),
				endDate: moment().add(1, 'day').format('YYYY-MM-DD')
			};

			Account.customer(customerId)
				.getAccountMarket(options)
				.then(function (results) {
					if (results && results.data) {
						$scope.totalVisit =
							$scope.marketGrouping === 'event'
								? results.metadata.visitCount
								: results.metadata.visitScore;
						$scope.totalMail =
							$scope.marketGrouping === 'event' ? results.metadata.mailCount : results.metadata.mailScore;
						$scope.totalForm =
							$scope.marketGrouping === 'event' ? results.metadata.formCount : results.metadata.formScore;
						$scope.totalLeadScore = results.metadata.totalScore;
						$scope.totalMarketingCustom =
							$scope.marketGrouping === 'event'
								? results.metadata.marketingcustomCount
								: results.metadata.marketingcustomScore;
						$scope.totalCount =
							(results.metadata.visitCount || 0) +
							(results.metadata.mailCount || 0) +
							(results.metadata.formCount || 0) +
							(results.metadata.marketingcustomCount || 0) +
							(results.metadata.manualCount || 0);

						mapGraphData(results.data);
					} else {
						$scope.totalCount = 0;
						$scope.totalMailCount = 0;
						$scope.totalFormCount = 0;
						$scope.totalLeadScore = 0;
						mapGraphData([]);
					}
				})
				.catch(function (err) {
					logError(err, 'Could not get market');
				});
		};

		$scope.$on('market.added', function (e, res) {
			if (res && res.contacts && _.find(res.contacts, { id: ContactCtrl.contact.id })) {
				var event = EventService.create.Manual(res);
				$scope.events.unshift(event);
				getData();
			}
		});

		var getEvents = function (historyType, isMetaData, onlyZero) {
			// $scope.eventsLoading = true;
			var eventFilter = new RequestBuilder();
			eventFilter.addFilter(
				Event.attr.contacts.attr.id,
				eventFilter.comparisonTypes.Equals,
				ContactCtrl.contact.id
			);
			if (!$scope.allowZeroEvents && !onlyZero) {
				eventFilter.addFilter({ field: 'feedContactMarket' }, eventFilter.comparisonTypes.Equals, null);
			} else {
				eventFilter.addFilter(
					{ field: 'feedContactMarketWithNoScore' },
					eventFilter.comparisonTypes.Equals,
					null
				);
			}

			if (onlyZero) {
				eventFilter.addFilter(Event.attr.score, eventFilter.comparisonTypes.Equals, 0);
			}

			eventFilter.addSort(Event.attr.date, false);
			eventFilter.limit = isMetaData ? 0 : eventLimit;
			eventFilter.offset = eventOffset;

			switch (historyType) {
				case 'form':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Form']);
					break;
				case 'visit':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Visit']);
					break;
				case 'marketingCustom':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'MarketingCustom'
					]);
					break;
				case 'mail':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Mail']);
					break;
			}

			return Event.customer(customerId)
				.find(eventFilter.build())
				.then(function (res) {
					if (res.data.length) {
						// Group Events
						var events = EventService.groupEvents(res.data);
						if (eventOffset === 0) {
							$scope.events = events;
						} else {
							EventService.groupEvents(res.data, $scope.events);
						}
						$scope.totalEvents = res.metadata.total;
						eventOffset += eventLimit;
						$scope.eventsLength += res.data.length;
					} else {
						if (eventOffset === 0) {
							$scope.events = [];
							$scope.totalEvents = 0;
							$scope.eventsLength = 0;
							ContactCtrl.marketActivities = 0;
						}
					}
					switch (historyType) {
						case 'form':
							$scope.totalForm = res.metadata.total;
							break;
						case 'visit':
							$scope.totalVisit = res.metadata.total;
							break;
						case 'marketingCustom':
							$scope.totalMarketingCustom = res.metadata.total;
							break;
						case 'mail':
							$scope.totalMail = res.metadata.total;
							break;
						default:
							$scope.totalAll = res.metadata.total;
							break;
					}
					$scope.eventsLoading = false;

					return res;
				})
				.catch(function (err) {
					logError(err, 'Could not find events');
				});
		};

		var getEventMetaData = function () {
			$q.all([
				getEvents('mail', true),
				getEvents('form', true),
				getEvents('visit', true),
				getEvents('marketingCustom', true),
				getEvents(null, true)
			])
				.then(function (res) {
					$scope.totalEventMail = res[0].metadata.total;
					$scope.totalEventForm = res[1].metadata.total;
					$scope.totalEventVisit = res[2].metadata.total;
					$scope.totalEventMarketingCustom = res[3].metadata.total;
					$scope.totalAll = res[4].metadata.total;
				})
				.catch(function (err) {
					logError(err, 'Could not get Event Meta Data');
				});
		};

		$scope.changeHistoryType = function (type) {
			if (type === $scope.historyType) {
				return;
			}
			$scope.historyType = type;
			$scope.getEvents(true);
		};

		$scope.getEvents = function (resetOffset) {
			$scope.eventsLoading = true;
			if (resetOffset) {
				$scope.eventOffset = 0;
				eventOffset = 0;
			}
			getEvents($scope.historyType);
		};

		$scope.setGrouping = function (updateEvents) {
			if ($scope.marketGrouping !== marketGrouping || $scope.nrOfMonths !== nrOfMonths) {
				marketGrouping = $scope.marketGrouping;
				nrOfMonths = $scope.nrOfMonths;
				getData();
			}

			if (updateEvents) {
				$scope.getEvents(true);
				getEventMetaData();
			}
		};

		var getStuffWithNoScore = function () {
			$q.all([getEvents('form', true, true), getEvents('visit', true, true)])
				.then(function (res) {
					$scope.nrFormsNoScore = res[0].metadata.total;
					$scope.nrVisitsNoScore = res[1].metadata.total;
				})
				.catch(function (err) {
					logError(err, 'Could not get items without score');
				});
		};

		var init = function () {
			weekPrefix = $translate.instant('calendar.weekPrefix') + '.';
			$scope.nrOfMonths = 3;
			nrOfMonths = 3;
			$scope.marketGrouping = 'event';
			marketGrouping = 'event';
			$scope.allowZeroEvents = false;

			getEventMetaData();
			$scope.getEvents();
			getStuffWithNoScore();
			getData();

			$scope.contactEvent = EventService.create.Contact(ContactCtrl.contact);
		};
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

export default () => {
	const FeatureHelper = getAngularModule('FeatureHelper');

	return (
		FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO) &&
		FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_SIGNALS)
	);
};

'use strict';

// Like ng-bind but inserts message if empty
angular.module('upDirectives').directive('emptyBind', [
	'$translate',
	'AppService',
	function ($translate, AppService) {
		return {
			restrict: 'A',
			link: function ($scope, $element, $attrs) {
				AppService.loadedPromise.then(function () {
					// Message to show if binding is empty
					var msg = $attrs.msg ? $scope.$eval($attrs.msg) : $translate.instant('default.empty');
					$scope.$watch($attrs.emptyBind, function (text) {
						text = _.trim(text);
						if (!text.length || text === ' ') {
							$element.text(msg);
						} else {
							$element.text(text);
						}
					});
				});
			}
		};
	}
]);

import logError from 'App/babel/helpers/logError';

const isActiveTab = () => {
	return !window.document.hidden;
};

const doShow = (notification, options, title, body) => {
	var tag = title + body;

	var browserNotification = new window.Notification(title, {
		body: body,
		tag: tag,
		icon: options.hasIcon ? 'img/browser_notification_icon.png' : '',
		requireInteraction: options.autoHide ? false : true
	});

	browserNotification.onclick = function () {
		options.onClick(notification);
		window.focus();
		this.close();
	};
};

/**********************************/

export const hasPermission = () => {
	return (window.Notification && window.Notification.permission === 'granted') || false;
};

export const requestPermission = () => {
	// Make sure feature is available in browser
	if (!window.Notification) {
		return;
	}
	if (!hasPermission()) {
		window.Notification.requestPermission();
	}
};

export const parseAppMessage = notification => {
	if (notification.message?.includes('"easybooking"')) {
		try {
			const message = JSON.parse(notification.message);
			const { contact, appointment } = message.easybooking;
			return (
				`${contact.name} ${Tools.$translate('default.booked').toLowerCase()} ${appointment.description}` +
				` ${Tools.$translate('notifications.sharedView.withYou')} @ ${moment(appointment.date).format(
					'YYYY-MM-DD - HH:mm'
				)}`
			);
		} catch (e) {
			logError(e, 'Could not parse json');
		}
	}
	return notification.message ? Tools.$translate(notification.message) : '';
};

const showStandardNotification = (notification, options) => {
	const Lang = {
		closedDeal: Tools.$translate('default.closedDeal').toLowerCase(),
		defaultTitle: Tools.$translate('notifications.browser.defaultTitle'),
		defaultBody: Tools.$translate('notifications.browser.defaultBody'),
		sharedView: Tools.$translate('notifications.sharedView').toLowerCase(),
		theView: Tools.$translate('filters.theView'),
		wasShared: Tools.$translate('notifications.wasShared').toLowerCase(),
		sharedViewWithYou: Tools.$translate('notifications.sharedView.withYou').toLowerCase(),
		createdAgreement: Tools.$translate('default.createdAgreement').toLowerCase(),
		purchasedFrom: Tools.$translate('default.purchasedFrom').toLowerCase(),
		yearlyValue: Tools.$translate('default.yearlyValue').toLowerCase(),
		important: Tools.$translate('default.important'),
		message: Tools.$translate('default.message').toLowerCase(),
		youBeenAssigned: Tools.$translate('system.you_been_assigned'),
		didAssign: Tools.$translate('default.didAssign').toLowerCase(),
		toYou: Tools.$translate('default.toYou').toLowerCase(),
		at: Tools.$translate('default.at').toLowerCase(),
		visitedTitle: Tools.$translate('event.visited'),
		visited: Tools.$translate('event.visited').toLowerCase(),
		formSubmitedTitle: Tools.$translate('event.form.submited'),
		formSubmited: Tools.$translate('event.form.submited').toLowerCase(),
		exportTitle: Tools.$translate('export.export'),
		export: Tools.$translate('export.export').toLowerCase(),
		completed: Tools.$translate('default.done').toLowerCase(),
		failed: Tools.$translate('noti.failed').toLowerCase(),
		hadMarketingEvent: Tools.$translate('event.hadMarketingEvent'),
		marketingEvent: Tools.$translate('default.marketEvent'),
		esign: Tools.$translate('default.eSign'),
		didSign: Tools.$translate('esign.didSign').toLowerCase(),
		viewed: Tools.$translate('esign.viewed').toLowerCase(),
		rejected: Tools.$translate('esign.rejected').toLowerCase(),
		undelivered: Tools.$translate('esign.undelivered').toLowerCase(),
		contributionMargin: Tools.$translate('default.contributionMarginShort'),
		monthlyValue: Tools.$translate('default.monthlyValue'),
		annualValue: Tools.$translate('default.annualValue'),
		oneOffValue: Tools.$translate('default.oneOffValue'),
		worth: Tools.$translate('notifications.order.value.worth').toLowerCase(),
		with: Tools.$translate('default.with').toLowerCase()
	};

	const NotificationType = Tools.PushNotifications.Types;
	const currencyFormat = Tools.$filter('currencyFormat');
	const defaultCurrencyIso = _.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true }).iso;
	const fullName = notification.registeredBy ? notification.registeredBy.name : '';
	const nameSplit = fullName.split(' ');
	const firstName = nameSplit[0] + (nameSplit.length > 1 ? ' ' + nameSplit[1][0] : '');
	const clientName = notification.client && notification.client.name;
	const contactName = notification.contact && notification.contact.name;
	const metadata = Tools.AppService.getMetadata();

	let title = Lang.defaultTitle;
	let body = Lang.defaultBody;

	switch (notification.type) {
		case NotificationType.ORDER: {
			const salesModel = metadata.params.SalesModel;
			const salesModelOption = metadata.params.SalesModelOption;
			const showContributionMargin = salesModel === 'cm' && salesModelOption === 'cm';
			const showRecurring = salesModel === 'rr';
			const showARR = showRecurring && salesModelOption === 'arr';

			let recurringValue = 0;
			let orderValue = 0;
			let oneOffValue = 0;
			let displayRecurring, displayARR, displayMRR, displayOneOff, displayCM;

			if (notification.order) {
				let formatted;
				if (showContributionMargin) {
					formatted = currencyFormat(
						notification.order.contributionMarginLocalCurrency,
						notification.order.currency || defaultCurrencyIso
					);
					displayCM = `${formatted} (${Lang.contributionMargin})`;
				} else if (showRecurring) {
					if (showARR) {
						recurringValue = notification.order.annualValue;
						formatted = currencyFormat(
							notification.order.annualValue,
							notification.order.currency || defaultCurrencyIso
						);
						displayARR = recurringValue ? `${formatted} (${Lang.annualValue})` : null;
					} else {
						recurringValue = notification.order.monthlyValue;
						formatted = currencyFormat(
							notification.order.monthlyValue,
							notification.order.currency || defaultCurrencyIso
						);
						displayMRR = recurringValue ? `${formatted} (${Lang.monthlyValue})` : null;
					}
					oneOffValue = notification.order.oneOffValue;
					formatted = currencyFormat(
						notification.order.oneOffValue,
						notification.order.currency || defaultCurrencyIso
					);
					displayOneOff = oneOffValue ? `${formatted} (${Lang.oneOffValue})` : null;

					displayRecurring = displayARR || displayMRR;
					if (displayOneOff) {
						if (displayRecurring) {
							displayRecurring = displayRecurring + ' & ' + displayOneOff;
						} else {
							displayRecurring = displayOneOff;
						}
					}
				}
				orderValue = currencyFormat(
					notification.order ? notification.order.orderValue : 0,
					(notification.order && notification.order.currency) || defaultCurrencyIso
				);
			}
			title = `${firstName} ${Lang.closedDeal}!`;

			body =
				`${notification.registeredBy ? notification.registeredBy.name : ''} ${Lang.closedDeal} ${
					Lang.with
				} ${clientName}` +
				`${' ' + Lang.worth} ${showRecurring ? displayRecurring : ''}` +
				`${showContributionMargin ? displayCM : ''}` +
				`${!showRecurring && !showContributionMargin ? orderValue : ''}.`;
			break;
		}
		case 'ReportView':
		case 'ListView':
			title = `${firstName} ${Lang.sharedView}`;
			body = `${Lang.theView} '${notification.message}' ${Lang.wasShared} ${Lang.sharedViewWithYou}.`;
			break;

		case NotificationType.AGREEMENT: {
			title = `${firstName} ${Lang.createdAgreement}!`;
			const value = currencyFormat(
				notification.agreement ? notification.agreement.yearlyValue : 0,
				(notification.agreement && notification.agreement.currency) || defaultCurrencyIso
			);
			body = `${value} (${Lang.yearlyValue})! ${clientName} ${Lang.purchasedFrom} ${fullName}.`;
			break;
		}
		case NotificationType.SYSTEM:
			title = `${Lang.important} ${Lang.message}`;
			body = notification.message;
			break;
		case NotificationType.APP:
			title = notification.action;
			body = parseAppMessage(notification);
			break;
		case NotificationType.ASSIGNED:
			title = Lang.youBeenAssigned;
			body = `${fullName} ${Lang.didAssign} ${clientName} ${Lang.toYou}.`;
			break;
		case NotificationType.VISIT:
			title = Lang.visitedTitle;
			body = `${clientName} ${Lang.visited}.`;
			if (contactName) {
				body = `${contactName} ${Lang.at} ` + body;
			}
			break;
		case NotificationType.SUBMIT:
			title = Lang.formSubmitedTitle;
			body = `${contactName} ${Lang.at} ${clientName} ${Lang.formSubmited} ${notification.form.name}.`;
			break;
		case NotificationType.EXPORT:
			if (notification.status !== 100 && notification.status !== -1) {
				// Returning because we only want browser notification for completed/failed exports.
				return;
			}
			if (notification.status === 100) {
				title = `${Lang.exportTitle} ${Lang.completed}`;
				body = notification.name
					? `${notification.name} ${Lang.export} ${Lang.done}`
					: Tools.$translate('noti.export.' + notification.action + '-done');
			}
			if (notification.status === -1) {
				title = `${Lang.exportTitle} ${Lang.failed}`;
				body = notification.name
					? `${notification.name} ${Lang.export} ${Lang.failed}`
					: Tools.$translate('noti.export.' + notification.action + '-failed');
			}
			break;
		case NotificationType.IMPORT:
			if (notification.status !== 100 && notification.status !== -1) {
				// Returning because we only want browser notification for completed/failed imports.
				return;
			}

			title = body =
				notification.status === 100
					? Tools.$translate('noti.import-done')
					: Tools.$translate('noti.import-failed');
			break;
		case NotificationType.MARKETING:
			title = Lang.marketingEvent;
			body = `${clientName} ${Lang.hadMarketingEvent} ${notification.message || ''}.`;
			if (contactName) {
				body = `${contactName} ${Lang.at} ` + body;
			}
			break;
		case NotificationType.ESIGN: {
			var status = '';
			switch (notification.action) {
				case 'signed':
					status = Lang.didSign;
					break;
				case 'seen':
					status = Lang.viewed;
					break;
				case 'declined':
					status = Lang.rejected;
					break;
				case 'undelivered':
					status = Lang.undelivered;
					break;
			}
			var fileName = '';
			if (notification.esign) {
				if (notification.esign.file) {
					fileName = notification.esign.file.filename;
				} else if (notification.esign.title) {
					fileName = notification.esign.title;
				}
			}
			title = Lang.esign;
			body = `${contactName || firstName} ${status} ${fileName}.`;
			break;
		}
		case NotificationType.JOB:
			if (notification.status !== 100 && notification.status !== -1) {
				// Returning because we only want browser notification for completed/failed jobs.
				return;
			}
			var udoName = notification.udo ? notification.udo.name : '';
			if (notification.name) {
				title = body = `${Tools.$translate(notification.action)} ${notification.name} ${
					notification.status === 100 ? Lang.completed : Lang.failed
				}`;
			} else {
				if (notification.status === 100) {
					var split = (notification.message || '').toString().split(':');
					if (split && split.length === 2) {
						title = Tools.$translate('noti.' + notification.action + '-partial-done', {
							changed: split[0],
							total: split[1],
							name: udoName
						});
						body = title + ' ' + Tools.$translate('default.missingRights');
					} else if (notification.message === 0) {
						title = Tools.$translate('noti.' + notification.action + '-noop-done', {
							total: notification.message,
							name: udoName
						});
						body = title + ' ' + Tools.$translate('default.missingRights');
					} else {
						title = body = Tools.$translate('noti.' + notification.action + '-done', {
							total: notification.message,
							name: udoName
						});
					}
				} else {
					title = body = Tools.$translate('noti.' + notification.action + '-failed', { name: udoName });
				}
			}
			break;
		default:
			return;
	}

	doShow(notification, options, title, body);
};

export const show = (notification, options) => {
	// Make sure feature is available in browser
	if (!window.Notification) {
		return;
	}
	if (!hasPermission()) {
		requestPermission();
	} else if (options.forceShow || !isActiveTab()) {
		if (notification.type) {
			return showStandardNotification(notification, options);
		}

		doShow(notification, options, notification.title, notification.body);
	}
};

export const periodLengthOptions = [3, 6, 12, 24, 36, 48, 60];

export const resetCustomPeriodToCompatiblePeriodLength = (customPeriodLength: number, intervalPeriod: number) => {
	// Check if custom period length exists as a period length option
	const existingPeriodLengthOption = periodLengthOptions.find(option => option === customPeriodLength);
	if (existingPeriodLengthOption) {
		return null;
	}

	// Find nearest period length that is smaller/equal to period length and dividable with order interval
	const relevantPeriodLengths = periodLengthOptions
		.filter(option => option <= customPeriodLength)
		.sort((a, b) => b - a);

	const highestCompatiblePeriodLength = relevantPeriodLengths.find(option => option % intervalPeriod === 0);
	if (highestCompatiblePeriodLength) {
		return highestCompatiblePeriodLength;
	}

	// Default to first period length option
	return periodLengthOptions[0];
};

export const getIntervalPeriodBasedOnPeriodLength = (periodLength: number, intervalPeriod: number) => {
	const intervalPeriodCompatibleWithPeriodLength = periodLength % intervalPeriod === 0;
	if (intervalPeriodCompatibleWithPeriodLength) {
		return null;
	}

	const intervalOptions = Tools.AppService.getStaticValues('recurringInterval').map(interval =>
		parseInt(interval.id)
	);
	const compatibleOptionsDesc = intervalOptions.filter(option => option <= periodLength).sort((a, b) => b - a);
	const highestCompatibleIntervalOption = compatibleOptionsDesc.find(option => {
		return periodLength % option === 0;
	});
	return highestCompatibleIntervalOption;
};

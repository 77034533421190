import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { Title, Icon } from '@upsales/components';
import { OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';
import TopList from 'App/upsales/common/components/react/flash/topList';

import './index.scss';
import Role from 'App/resources/Model/Role';

type Props = {
	totalOutcomes: number;
	tools: {};
	rootData: {
		activityOutcomesListWidget: {
			// eslint-disable-next-line camelcase
			data: Array<{ doc_count: number; email: string }>;
		};
		activityOutcomesWidget: {
			// eslint-disable-next-line camelcase
			data: Array<{ doc_count: number; key: string }>;
		};
		appointmentShieldWidget: {
			data: number;
		};
		appointmentListWidget: {
			// eslint-disable-next-line camelcase
			data: Array<{ doc_count: number; email: string; key: number; name: string }>;
		};
		totalOrders: number;
		totalOpportunities: number;
		roles: {
			activity: Array<Role>;
			appointment: Array<Role>;
		};
	};
};

class ActivityOutcomesStatistic extends Component<Props> {
	static propTypes = {
		totalOutcomes: PropTypes.number,
		tools: PropTypes.object,
		rootData: PropTypes.object
	};

	lang: {
		topDials: string;
		yourProgress: string;
		reached: string;
		notReached: string;
		appointments: string;
		orders: string;
		opportunities: string;
	};

	statistic = {
		[OUTCOME_TYPES.NO_ANSWER]: 0,
		[OUTCOME_TYPES.ANSWER]: 0,
		total: 0
	};

	constructor(props: Props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			topDials: t('activity.statistic.topDials'),
			yourProgress: t('activity.statistic.yourProgress'),
			reached: t('activity.outcome.statistic.reached'),
			notReached: t('activity.outcome.statistic.notReached'),
			appointments: t('default.appointments'),
			orders: t('default.orders'),
			opportunities: t('default.opportunities')
		};
	}

	render() {
		const classNames = new BemClass('ActivityOutcomesStatistic');
		const { rootData, tools } = this.props;
		const {
			activityOutcomesListWidget,
			activityOutcomesWidget,
			appointmentShieldWidget,
			totalOrders,
			totalOpportunities
		} = rootData;
		const outcomesCount = activityOutcomesWidget.data.reduce((acc, cur) => {
			const { key, doc_count: docCount } = cur;

			return {
				...acc,
				[key]: docCount,
				total: docCount + (acc.total || 0)
			};
		}, this.statistic);

		return (
			<div className={classNames.b()}>
				<div className={classNames.elem('side').b()}>
					<TopList
						id="footer-left-top-list"
						title="activity.statistic.topDialsDay"
						settingsTitle="default.activityTypes"
						entity="activity"
						settings={activityOutcomesListWidget}
						placeholder="activity.noOutcome"
						roles={rootData.roles.activity}
						tools={tools}
					/>
				</div>
				<div className={classNames.elem('main').b()}>
					<Title size="sm" color="white">
						{this.lang.yourProgress}
					</Title>
					<div className={classNames.elem('progress-block').b()}>
						<ul className={classNames.elem('progress-list').b()}>
							<li>
								<div
									className={classNames
										.elem('progress-item')
										.mod({ empty: !outcomesCount.total })
										.b()}
								>
									<div className={classNames.elem('progress-item-total').b()}>
										<span className={classNames.elem('progress-item-num').b()}>
											{outcomesCount.total}
										</span>
										<ul className={classNames.elem('progress-info').b()}>
											<li>
												<Icon name="phone-fail" color="red" space="mrs" />
												<span className={classNames.elem('progress-info-item').b()}>
													{outcomesCount[OUTCOME_TYPES.NO_ANSWER]} {this.lang.notReached}
												</span>
											</li>
											<li>
												<Icon name="phone-success" color="medium-green" space="mrs" />
												<span className={classNames.elem('progress-info-item').b()}>
													{outcomesCount[OUTCOME_TYPES.ANSWER]} {this.lang.reached}
												</span>
											</li>
										</ul>
									</div>
									<span className={classNames.elem('progress-item-name').b()}>
										{this.lang.topDials}
									</span>
								</div>
							</li>
							<li>
								<div
									className={classNames
										.elem('progress-item')
										.mod({ empty: !appointmentShieldWidget.data })
										.b()}
								>
									<span className={classNames.elem('progress-item-num').b()}>
										{appointmentShieldWidget.data}
									</span>
									<span className={classNames.elem('progress-item-name').b()}>
										{this.lang.appointments}
									</span>
								</div>
							</li>
							<li>
								<div
									className={classNames.elem('progress-item').mod({ empty: !totalOpportunities }).b()}
								>
									<span className={classNames.elem('progress-item-num').b()}>
										{totalOpportunities}
									</span>
									<span className={classNames.elem('progress-item-name').b()}>
										{this.lang.opportunities}
									</span>
								</div>
							</li>
							<li>
								<div className={classNames.elem('progress-item').mod({ empty: !totalOrders }).b()}>
									<span className={classNames.elem('progress-item-num').b()}>{totalOrders}</span>
									<span className={classNames.elem('progress-item-name').b()}>
										{this.lang.orders}
									</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className={classNames.elem('side').b()}>
					<TopList
						id="footer-right-top-list"
						title="flash.closedAppointmentsToday"
						settingsTitle="default.appointmentTypes"
						entity="appointment"
						settings={rootData.appointmentListWidget}
						placeholder="flash.noBookedAppointmentsToDay"
						roles={rootData.roles.appointment}
						tools={tools}
					/>
				</div>
			</div>
		);
	}
}

export default ActivityOutcomesStatistic;

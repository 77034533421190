import React, { useEffect, useRef, useState } from 'react';
import { Block, Text, Input, OutsideClick, Loader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from '../animations';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import T from 'Components/Helpers/translate';
import './Select.scss';

const MIN_LENGTH = 2;

type SelectProps<T> = {
	onChange?: (value: T | null) => void;
	disabled?: boolean;
	autofocus?: boolean;
	icon?: string;
	idKey?: keyof T;
	titleKey?: keyof T;
	placeholder?: string;
	className?: string;
	inputRef?: (r: HTMLInputElement) => void;
	fetchData?: (searchString: string) => Promise<T[]>;
	onInputBlur?: () => void;
	renderResult?: (result: T) => JSX.Element;
	noDataText?: string;
	renderNoData?: () => JSX.Element;
};

const header = (classes: BemClass, title: string, white?: boolean) => (
	<Block
		className={classes.elem('header').b()}
		backgroundColor={white ? 'white' : 'grey-2'}
		border="bs"
		borderColor="grey-4"
		space="ptm prm pbm plm"
	>
		<Text size="sm" bold>
			{T(title)}
		</Text>
	</Block>
);

// Feel free to move if needed elseware
function isElementInViewport(list: HTMLDivElement, el: HTMLDivElement) {
	const rect = el.getBoundingClientRect();
	const viewTop = list.scrollTop;
	const viewBottom = viewTop + list.offsetHeight;
	const _top = el.offsetTop;
	const _bottom = _top + rect.height;
	const compareTop = _top;
	const compareBottom = _bottom;

	return compareBottom <= viewBottom && compareTop >= viewTop;
}

/*
Below is really <GenericSelect />

Built this to cover all use cases in this ticket.
I would like to expand this to be a super generic search that can be used to create more specific inputs.
This is more of a search-select that can not display a selected value (has no selected value yet)
TODO: <SearchSelect /> should inherit/construct from <GenericSelect />
TODO: <Select /> should inherit/construct from <GenericSelect />

*/
const Select = <T extends { [key: string]: any } = { id: number; name: string }>({
	onInputBlur,
	inputRef,
	onChange = () => {},
	fetchData,
	className,
	titleKey = 'name',
	renderResult = (r: T) => <Text>{r[titleKey]}</Text>,
	idKey = 'id',
	placeholder,
	icon,
	noDataText = T('default.noResults'),
	disabled = false,
	autofocus = false,
	renderNoData,
	...props
}: SelectProps<T>) => {
	const classes = new BemClass('Select', className);
	const [open, setOpen] = useState(false);
	const mainRef = useRef<HTMLDivElement>();
	const listRef = useRef<HTMLDivElement>();
	const localInputRef = useRef<HTMLInputElement | null>();
	const [searchString, setSearchString] = useState('');
	const [searching, setSearching] = useState(false);
	const [results, setResults] = useState<T[]>([]);
	const [keys, setKeys] = useState<string[]>([]);
	const [highlighted, setHighlighted] = useState<string | null>(null);

	const resetSearch = () => {
		setSearchString('');
		setResults([]);
		setOpen(false);
	};

	const scrollIntoView = (key: string | null) => {
		const element = listRef.current?.querySelectorAll<HTMLDivElement>(`[data-id="${key}"]`)[0];
		if (listRef.current && element && !isElementInViewport(listRef.current, element)) {
			element.scrollIntoView({ block: 'nearest' });
		}
	};

	const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		// Do nothing if no keys or if pressed key not interesting
		if (
			(!keys.length && ['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) ||
			!['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab'].includes(e.key)
		) {
			return;
		}
		e.preventDefault();
		const key = highlighted || keys[0];
		const currentI = keys.indexOf(key);
		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowDown': {
				let newKey = null;
				if (e.key === 'ArrowUp') {
					if (currentI === 0) {
						newKey = keys[keys.length - 1]; // go to end
					} else {
						newKey = keys[currentI - 1]; // go to prev
					}
				} else if (e.key === 'ArrowDown') {
					if (currentI === keys.length - 1) {
						newKey = keys[0]; // go to beginning
					} else {
						newKey = keys[currentI + 1]; // go to next
					}
				}
				setHighlighted(newKey);
				scrollIntoView(newKey);
				break;
			}
			case 'Tab':
			case 'Escape':
				resetSearch();
				localInputRef.current?.blur();
				break;
			case 'Enter':
				if (highlighted) {
					if (highlighted.startsWith('res-')) {
						const result = results.find(r => r[idKey] === highlighted.replace('res-', '')) || null;
						if (result) {
							onChange(result);
							resetSearch();
						}
					}
				}
				break;
		}
	};

	useEffect(() => {
		if (fetchData) {
			let searchPromise: CancelablePromise<T[]> | null = null;
			setSearching(searchString.length >= MIN_LENGTH);

			const debounce = setTimeout(() => {
				if (searchString.length >= MIN_LENGTH) {
					searchPromise = makeCancelable(fetchData(searchString));
					searchPromise.promise
						.then(res => {
							setResults(res);
							const keys = [...res.map(r => `res-${r[idKey]}`)];
							setKeys(keys);
							setHighlighted(keys[0] || null);
							setSearching(false);
						})
						.catch(() => {});
				}
			}, 300);
			return () => {
				if (debounce) {
					clearTimeout(debounce);
				}
				if (searchPromise) {
					searchPromise.cancel();
				}
			};
		}
	}, [searchString]);

	return (
		<Block {...props} className={classes.b()}>
			<OutsideClick
				targetRef={() => mainRef.current || null}
				listen={open}
				outsideClick={() => {
					setOpen(false);
					onInputBlur?.();
				}}
			>
				<div ref={(r: HTMLInputElement) => (mainRef.current = r)}>
					<Input
						inputRef={r => {
							localInputRef.current = r;
							if (r) {
								inputRef?.(r);
							}
						}}
						icon={icon}
						placeholder={placeholder}
						value={searchString}
						onFocus={() => setOpen(true)}
						onKeyDown={keyDown}
						onChange={e => setSearchString(e.target.value)}
						disabled={disabled}
						autofocus={autofocus}
					/>
					<SlideFade direction="top" bounce visible={open}>
						<div className={classes.elem('list').b()} ref={(r: HTMLInputElement) => (listRef.current = r)}>
							{!searching && searchString.length < MIN_LENGTH && !results.length
								? header(classes, T('default.typeToSearch'), true)
								: null}
							{searching ? <Loader size="sm" /> : null}
							{!searching && results.length
								? results.map(row => {
										const key = `res-${row[idKey]}`;
										return (
											<div
												key={key}
												className={classes
													.elem('result')
													.mod({ highlighted: key === highlighted })
													.b()}
												onClick={() => {
													resetSearch();
													onChange(row);
												}}
												data-id={key}
											>
												{renderResult(row)}
											</div>
										);
								  })
								: null}
							{searchString.length >= MIN_LENGTH && !searching && !results.length ? (
								renderNoData ? (
									renderNoData()
								) : (
									<Text className={classes.elem('no-results').b()} center>
										{noDataText}
									</Text>
								)
							) : null}
						</div>
					</SlideFade>
				</div>
			</OutsideClick>
		</Block>
	);
};

export default Select;

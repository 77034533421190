'use strict';

angular.module('domain.mail').controller('MailCampaignVisits', [
	'$scope',
	'AppService',
	'Visitor',
	'RequestBuilder',
	'MultiRunnerActions',
	'$multiSelect',
	'Account',
	function ($scope, AppService, Visitor, RequestBuilder, MultiRunnerActions, $multiSelect, Account) {
		var MailCampaignCtrl = $scope.MailCampaignCtrl;
		var getTimeout;
		var limit = 50;
		var searchStr = '';
		var customerId = '';

		MailCampaignCtrl.rootData.pageComponent = 'visits';
		MailCampaignCtrl.rootData.hasMultiSelect = true;
		MailCampaignCtrl.rootData.listActionData.actions = MultiRunnerActions.get(MultiRunnerActions.type.VISIT);
		MailCampaignCtrl.rootData.pageData = {
			visits: [],
			loading: true,
			total: 0,
			totalPages: 1,
			activePage: 1,
			currentSorting: { attr: 'startDate', asc: false },
			onSearch: function (str) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				searchStr = str;
				getVisits();
			},
			onChangePage: function (page) {
				MailCampaignCtrl.rootData.pageData.activePage = page;
				getVisits();
			},
			onSort: function (attr) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				if (MailCampaignCtrl.rootData.pageData.currentSorting.attr === attr) {
					// Flip it
					MailCampaignCtrl.rootData.pageData.currentSorting.asc = !MailCampaignCtrl.rootData.pageData
						.currentSorting.asc;
				} else {
					MailCampaignCtrl.rootData.pageData.currentSorting = { attr: attr, asc: true };
				}
				getVisits();
			},
			drilldownLoading: false,
			onDrilldown: function (visit) {
				if (visit.client) {
					MailCampaignCtrl.rootData.pageData.drilldownLoading = true;
					var filters = new RequestBuilder();

					filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, visit.client.id);

					Account.customer(customerId)
						.find(filters.build())
						.then(function (res) {
							MailCampaignCtrl.rootData.pageData.drilldownData = { visit: visit, client: res.data };
							MailCampaignCtrl.rootData.pageData.drilldownLoading = false;
						});
				} else {
					MailCampaignCtrl.rootData.pageData.drilldownData = { visit: visit, client: null };
				}
			}
		};

		var getVisits = function () {
			MailCampaignCtrl.rootData.pageData.loading = true;

			if (getTimeout) {
				clearTimeout(getTimeout);
			}

			getTimeout = setTimeout(function () {
				var rb = new RequestBuilder();
				rb.limit = limit;
				rb.offset = limit * (MailCampaignCtrl.rootData.pageData.activePage - 1);

				if (searchStr && searchStr.length) {
					var orBuilder = rb.orBuilder();

					orBuilder.next();
					orBuilder.addFilter(Visitor.attr.client.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(Visitor.attr.contact.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.done();
				}

				rb.addFilter({ field: 'leadSource.source' }, rb.comparisonTypes.Equals, 'upsalesMail');
				rb.addFilter({ field: 'leadSource.type' }, rb.comparisonTypes.Equals, 'email');
				rb.addFilter(
					{ field: 'leadSource.value' },
					rb.comparisonTypes.Equals,
					MailCampaignCtrl.rootData.mailCampaign.id.toString()
				);

				rb.addSort(
					MailCampaignCtrl.rootData.pageData.currentSorting.attr,
					MailCampaignCtrl.rootData.pageData.currentSorting.asc
				);

				MailCampaignCtrl.rootData.currentRb = rb;
				Visitor.find(rb.build()).then(function (res) {
					MailCampaignCtrl.rootData.pageData.visits = res.data;
					MailCampaignCtrl.rootData.pageData.total = res.metadata.total;
					MailCampaignCtrl.rootData.pageData.loading = false;

					MailCampaignCtrl.rootData.pageData.totalPages = MailCampaignCtrl.calculateTotalPages(
						limit,
						MailCampaignCtrl.rootData.pageData.total
					);
				});
			}, 20);
		};

		var init = function () {
			$multiSelect.selectNone();
			customerId = AppService.getCustomerId();
			getVisits();
		};

		AppService.loadedPromise.then(init);
	}
]);

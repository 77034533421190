'use strict';

angular.module('domain.activity').controller('CreateActivityMulti', [
	'$scope',
	'$modalParams',
	'$translate',
	'$multiSelect',
	'ActionProperties',
	'MultiActions',
	'AppService',
	function ($scope, $modalParams, $translate, $multiSelect, ActionProperties, MultiActions, AppService) {
		var arrayProperties = [];

		$scope.save = function () {
			$scope.saving = true;

			var activity = angular.copy($scope.properties);
			// map properties
			var properties = [];

			if (!activity.Time && activity.Date) {
				activity.Date = moment(activity.Date).format('YYYY-MM-DD');
			} else {
				activity.Time = moment(activity.Time).format('HH:mm:ss');
			}
			if (!activity.Description) {
				activity.Description = $scope.defDesc;
			}

			var filters = $modalParams.filters;

			if ($multiSelect.selected && $multiSelect.selected.length) {
				filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, $multiSelect.selected);
			}

			var extraParams = $modalParams.extraParams ? $modalParams.extraParams : {};

			ActionProperties.map(properties, activity, arrayProperties);
			MultiActions.customer($modalParams.customerId)
				.createActivity(
					$modalParams.entity,
					$multiSelect.selected.length,
					properties,
					filters.build(),
					$modalParams.name,
					extraParams
				)
				.then(() => {
					$scope.saving = false;
					// Deselect all
					$multiSelect.selectNone();
					$scope.resolve();
				})
				.catch(() => {
					$scope.saving = false;
				});
		};

		$scope.updateDefDesc = function () {
			var found = _.find($scope.activityTypes, { id: $scope.properties.ActivityType });
			$scope.defDesc = found ? found.name : '';
		};

		$scope.addTime = function (time) {
			$scope.properties.Date.setHours(time.getHours(), time.getMinutes(), 0, 0);
		};

		var init = function () {
			// parse properties
			$scope.properties = {};

			// Set activity type
			$scope.activityTypes = AppService.getActivityTypes('activity', true);
			$scope.properties.ActivityType = $scope.activityTypes[0].id;
			$scope.updateDefDesc();

			// Date
			$scope.properties.Date = new Date();
			if ($scope.properties.ShowTime) {
				$scope.time = $scope.properties.Date;
			}

			// Users
			$scope.users = AppService.getActiveUsers();
			$scope.users.unshift({ id: '{{Client.UserId}}', name: $translate.instant('default.accountManager') });
			$scope.users.unshift({
				id: '{{Client.AssignedUserId}}',
				name: $translate.instant('automationTerms.assignedUser')
			});
			$scope.properties.User = AppService.getSelf().id;
		};

		init();
	}
]);

'use strict';

ReactTemplates.form.editFieldRow = window.ReactCreateClass({
	setFocusToOpt: false,
	getInitialState: function () {
		return {
			field: _.cloneDeep(this.props.field),
			open: false
		};
	},
	UNSAFE_componentWillReceiveProps: function (props) {
		var nextState = { field: _.cloneDeep(props.field) };
		if (props.field.hasError && !this.state.field.hasError) {
			nextState.open = true;
		}
		this.setState(nextState);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			addContentToColumn: t('landingpage.addContentToColumn'),
			required: t('form.required'),
			title: t('default.title'),
			suggestBisnode: t('form.suggestCompany'),
			bisnodeTooltip: t('form.bisnodeTooltip'),
			open: t('default.open'),
			close: t('default.close'),
			extraFieldType: t('form.extraFieldType'),
			contactCustom: t('form.contact.cf'),
			clientCustom: t('form.client.cf'),
			allowOnlyCompanyAddresses: t('form.allowOnlyCompanyAddresses'),
			edit: t('default.edit'),
			readMore: t('mail.btnPlaceholder'),
			option: t('default.option')
		};
	},
	componentDidUpdate: function () {
		if (this.setFocusToOpt) {
			this.setFocusToOpt = false;
			this._addOption.previousSibling.focus();
		}
	},
	getTitle: function () {
		var field = this.state.field;
		var cf;

		if (field.type === 'extra') {
			return this.lang.extraFieldType;
		}

		if (field.type === 'cf') {
			var fieldId = parseInt(field.name.split('_')[1]);
			if (field.name.indexOf('Contact') === 0) {
				cf = _.find(this.props.contactCustomFields, { id: fieldId });
				return this.lang.contactCustom + ': ' + (cf ? cf.name : '');
			}

			if (field.name.indexOf('Client') === 0) {
				cf = _.find(this.props.accountCustomFields, { id: fieldId });
				return this.lang.clientCustom + ': ' + (cf ? cf.name : '');
			}
		}

		var t = Tools.$translate;

		if (field.datatype === 'optIn') {
			var optIn = _.find(this.props.optIns, { id: parseInt(field.options, 10) });
			return t('form.' + optIn.type + 'OptIn');
		}

		return t('tag.' + field.name);
	},
	requiredChanged: function () {
		var field = this.state.field;
		field.required = !field.required;
		this.setState({ field: field });
		this.props.onChange(field);
	},
	titleChanged: function (e) {
		var field = this.state.field;
		field.title = e.target.value;
		this.setState({ field: field });
		this.props.onChange(field);
	},
	optInChanged: function (e) {
		var optIn = _.find(this.props.optIns, { id: parseInt(e.target.value, 10) });
		var field = this.state.field;
		field.options = e.target.value;
		field.title = optIn.title;
		this.setState({ field: field });
		this.props.onChange(field);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	optionBlur: function (index, e) {
		var value = _.trim(e.target.value);
		// Remove input if blurred with no value
		if (!value.length) {
			var field = this.state.field;
			var options = field.options.split(',');
			options.splice(index, 1);

			if (!options.length) {
				field.options = null;
			} else {
				field.options = options.join(',');
			}

			this.setState({ field: field });
			this.props.onChange(field);
		} else if (value !== e.target.value) {
			this.optionChange(index, { target: { value: value } });
		}
	},
	optionChange: function (index, e) {
		var field = this.state.field;
		var isValid = false;
		var targetValue = e.target.value;
		do {
			var value = encodeURIComponent(targetValue).substring(0, 255);
			try {
				decodeURIComponent(value);
				isValid = true;
			} catch (e) {
				targetValue = targetValue.substring(0, targetValue.length - 1);
			}
		} while (!isValid && targetValue.length);

		var options = field.options.split(',');

		options[index] = value;

		field.options = options.join(',');
		this.setState({ field: field });
		this.props.onChange(field);
	},
	addOption: function () {
		this.setFocusToOpt = true;
		var field = this.state.field;

		if (field.options !== null) {
			field.options += ',';
		} else {
			field.options = '';
		}

		this.setState({ field: field });
		this.props.onChange(field);
	},
	suggestBisnodeChanged: function () {
		this.props.suggestBisnodeChanged();
	},
	verifyEmailDomain: function () {
		this.props.verifyEmailDomain();
	},
	openVerfifyDomainModal: function () {
		Tools.$upModal.open('validDomains', {});
	},
	toggleRow: function () {
		this.setState({ open: !this.state.open });
	},
	getAvailableOptIns: function (optIn) {
		var optIns = this.props.getAvailableOptIns(optIn.type);

		return {
			results: _.map(optIns, function (optIn) {
				return { id: optIn.id, name: optIn.title };
			})
		};
	},
	render: function () {
		var self = this;
		var field = self.state.field;
		var title = self.getTitle();
		var fieldIsRequired = false;
		var emailVerifyToggle = null;
		if (field.name === 'Contact.email') {
			fieldIsRequired = true;
			var hasVerifyDomain = _.find(self.props.form.fields, { name: 'Prefs.verifyEmailDomain' });
			var className = this.props.hasVerifyDomainAccess
				? 'field-row-section field-toggle-row'
				: 'field-row-section field-toggle-row disabled';
			emailVerifyToggle = (
				<div className={className}>
					<ReactTemplates.upFilters.components.toggle
						data-test-id="edit-email-toggle"
						checked={hasVerifyDomain}
						disabled={!this.props.hasVerifyDomainAccess}
						onChange={self.verifyEmailDomain}
						className="toggle-sm toggle-bright-blue"
					/>
					<label>{self.lang.allowOnlyCompanyAddresses}</label>
					<span className="edit-company-addresses">
						{this.props.hasVerifyDomainAccess ? (
							<a
								data-test-id="edit-email-domains"
								className={'edit-company-addresses'}
								onClick={self.openVerfifyDomainModal}
							>
								{self.lang.edit}
							</a>
						) : (
							<ReactTemplates.elevio articleId="431" text={self.lang.readMore} sidebar="true" />
						)}
					</span>
				</div>
			);
		}

		var requiredDisabled = false;
		var bisnodeAutocomplete = null;

		var optionsSelect = null;
		if (field.datatype === 'checkbox' || field.datatype === 'radio' || field.datatype === 'select') {
			var options;
			if (field.options !== null) {
				options = field.options.split(',');
			} else {
				options = [];
			}

			var optionInputs = _.map(options, function (o, i) {
				return (
					<input
						type="text"
						value={decodeURIComponent(o)}
						className="form-control input-sm option-input"
						key={i}
						onChange={self.optionChange.bind(self, i)}
						onBlur={self.optionBlur.bind(self, i)}
					/>
				);
			});

			if (field.datatype === 'checkbox' && options.length) {
				requiredDisabled = true;
			}

			var addBtn = (
				<button
					type="button"
					className="up-btn btn-sm btn-light-grey no-shadow add-option-btn"
					key="add-opt"
					onClick={self.addOption}
					ref={self.setRef.bind(self, '_addOption')}
				>
					{self.lang.option} {options.length + 1}
				</button>
			);
			// add-btn
			optionInputs.push(addBtn);
			optionsSelect = <div className="field-row-section">{optionInputs}</div>;
		}

		if (
			field.name === 'Client.name' &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BISNODE_AUTOCOMPLETE)
		) {
			var hasAutocomplete = _.find(self.props.form.fields, { name: 'Prefs.bisnodeAutocomplete' }) ? true : false;

			var tooltip = ReactTemplates.TOOLS.withTooltip(
				<b className="fa fa-info-circle" />,
				self.lang.bisnodeTooltip,
				{ placement: 'left' }
			);
			bisnodeAutocomplete = (
				<div className="field-row-section field-toggle-row">
					<ReactTemplates.upFilters.components.toggle
						checked={hasAutocomplete}
						onChange={self.suggestBisnodeChanged}
						className="toggle-sm toggle-bright-blue"
					/>
					<label>
						{self.lang.suggestBisnode} {tooltip}
					</label>
				</div>
			);
		}

		var requiredToggle = null;
		if (!fieldIsRequired) {
			requiredToggle = (
				<div className="field-row-section field-toggle-row">
					{!requiredDisabled && (
						<span>
							<ReactTemplates.upFilters.components.toggle
								checked={field.required}
								onChange={self.requiredChanged}
								className="toggle-sm toggle-bright-blue"
							/>
							<label>{self.lang.required}</label>
						</span>
					)}
					<button
						type="button"
						className="up-btn btn-xs btn-grey btn-hover-red btn-link pull-right"
						onClick={self.props.onRemoveField}
					>
						<b className="fa fa-trash-o" />
					</button>
					<div className="clearfix" />
				</div>
			);
		}

		var subtitle = field.title;
		var optInSelect;
		if (field.datatype === 'optIn') {
			var optIn = _.find(self.props.optIns, { id: parseInt(field.options, 10) });
			subtitle = optIn.title;
			optInSelect = (
				<ReactTemplates.INPUTS.upSelect
					className="form-control"
					data={this.getAvailableOptIns.bind(null, optIn)}
					initSelection={function (input, cb) {
						cb({ id: optIn.id, name: optIn.title });
					}}
					defaultValue={{ id: optIn.id, name: optIn.title }}
					onChange={self.optInChanged}
					matcher={function (term, undef, item) {
						return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
					}}
					required={true}
				/>
			);
		}

		return (
			<div className="tool-section field-row" data-sort={self.props.index}>
				<div className="drag-handle">
					<b className="fa fa-arrows-v text-grey" />
				</div>
				<div data-test-id="section-row-toggle" className="field-row-header" onClick={self.toggleRow}>
					<span className={field.hasError ? 'header-title has-error' : 'header-title'}>
						{title}
						{field.required ? <b className="fa fa-circle" /> : null}
					</span>
					<span className="header-subtitle">{subtitle.replace('&amp;', '&')}</span>
					<div className="toggle-field-open">
						<span className="desc-text">{self.state.open ? self.lang.close : self.lang.open}</span>{' '}
						<b className={self.state.open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
					</div>
				</div>

				{self.state.open ? (
					field.datatype !== 'optIn' ? (
						<div>
							<div className="field-row-section field-title-row">
								<input
									type="text"
									className="form-control input-sm"
									value={field.title.replace('&amp;', '&')}
									onChange={self.titleChanged}
								/>
							</div>
							<div className="field-row-section">{optionsSelect}</div>

							{requiredToggle}

							{bisnodeAutocomplete}
							{emailVerifyToggle}
						</div>
					) : (
						<div>
							<div className="field-row-section">{optInSelect}</div>
							{requiredToggle}
						</div>
					)
				) : null}
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import t from 'Components/Helpers/translate';
import { Icon } from '@upsales/components';
import './ComparisonToggle.scss';
import bemClass from '@upsales/components/Utils/bemClass';

const ComparisonToggle = ({ value, onChange, yes = t('default.with'), no = t('default.without'), locked }) => {
	const classes = new bemClass('ComparisonToggle').mod({ locked });

	return (
		<div className={classes.b()} onClick={!locked ? () => onChange(!value) : null}>
			{(value ? yes : no).toLowerCase()}
			{!locked ? <Icon name="sort" /> : null}
		</div>
	);
};

ComparisonToggle.defaultProps = {
	onChange: () => {},
	value: false,
	locked: false
};

ComparisonToggle.propTypes = {
	value: PropTypes.bool,
	locked: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};

export default ComparisonToggle;

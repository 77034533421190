'use strict';

import { getRatingTranslation } from 'Components/Misc/RatingLabel/RatingLabel';

ReactTemplates.TOOLS.upRatingLabel = window.ReactCreateClass({
	getRatingLabel: function (account) {
		if (!account) {
			return;
		}
		if (window.Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && account.creditRating) {
			var values = window.Tools.AppService.getStaticValues('creditRating');
			var creditRating = _.find(values, { id: account.creditRating });

			if (!creditRating) {
				creditRating = _.find(values, { id: 'unknown' });
			}

			return {
				split: creditRating.name.split(' - ')[0],
				full: creditRating.name
			};
		} else if (account.soliditet && account.soliditet.financialData && account.soliditet.financialData.rating) {
			return {
				split: account.soliditet.financialData.rating.split(' - ')[0],
				full: getRatingTranslation(account.soliditet.financialData.rating)
			};
		} else {
			var ratingCustomField = _.find(account.custom, { alias: 'RATING' });

			if (ratingCustomField && ratingCustomField.value) {
				return {
					split: ratingCustomField.value.split(' - ')[0],
					full: ratingCustomField.value
				};
			}
		}
		return null;
	},
	render: function () {
		var self = this;
		var ratingLabel = null;
		var ratingObj = self.getRatingLabel(self.props.account);

		if (ratingObj && self.props.account) {
			var classes = 'label label-default up-rating-label';

			if (ratingObj.split === 'AAA' || ratingObj.split === 'AA' || ratingObj.split === 'A') {
				classes += ' label-success';
			} else if (ratingObj.split === 'B') {
				classes += ' label-warning';
			} else if (ratingObj.split === 'C') {
				classes += ' label-danger';
			}

			ratingLabel = ReactTemplates.TOOLS.withTooltip(
				<div className={classes}>{ratingObj.split}</div>,
				ratingObj.full,
				{ placement: 'right' }
			);
		}

		return ratingLabel;
	}
});

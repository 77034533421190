export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	account: {
		title: 'default.account',
		field: 'client.name',
		locked: true
	},
	categories: {
		title: 'default.categories',
		field: 'category.name'
	},
	campaigns: {
		title: 'default.campaigns',
		field: 'project.name'
	},
	name: {
		title: 'column.name',
		field: 'name',
		locked: true
	},
	firstName: {
		title: 'default.firstName',
		field: 'firstName',
		unreleasedFeature: 'NEW_FIELDS'
	},
	lastName: {
		title: 'default.lastName',
		field: 'lastName',
		unreleasedFeature: 'NEW_FIELDS'
	},
	title: {
		title: 'default.title',
		field: 'title'
	},
	titleCategory: {
		title: 'default.titlecategory',
		field: 'titleCategory',
		unreleasedFeature: 'NEW_FIELDS'
	},
	phone: {
		title: 'default.phone',
		field: 'phone'
	},
	cellPhone: {
		title: 'default.cellPhone',
		field: 'cellPhone'
	},
	email: {
		field: 'email',
		title: 'default.email'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},
	score: {
		field: 'score',
		title: 'default.score'
	},
	optInTitle: {
		field: 'optIn.title',
		title: 'admin.optInTitle'
	},
	optInRegdate: {
		field: 'optIn.regDate',
		title: 'admin.optInDate'
	},
	optInType: {
		field: 'optIn.type',
		title: 'admin.optInType'
	},
	unsubscribed: {
		title: 'mail.mailEvent.unsub',
		field: 'unsubscribed'
	},
	scoreUpdateDate: {
		title: 'default.scoreUpdate',
		field: 'scoreUpdateDate'
	},
	salutation: {
		title: 'default.salutation',
		field: 'salutation',
		unreleasedFeature: 'NEW_FIELDS'
	},
	active: {
		title: 'default.active',
		field: 'active'
	},
	linkedin: {
		title: 'account.socialLinkedIn',
		field: 'linkedin',
		unreleasedFeature: 'NEW_FIELDS'
	},
	segments: {
		title: 'segment.segments',
		field: 'segment.name'
	},
	journey: {
		title: 'default.journeyStep',
		field: 'journeyStep'
	}
};

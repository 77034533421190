'use strict';

angular.module('upResources').service('ParseGeneric', [
	'$injector',
	'AppService',
	'ParseHelpers',
	'ResourceHelper',
	function ($injector, AppService, ParseHelpers, ResourceHelper) {
		return function (parserName, opts) {
			opts = opts || { isArray: false, custom: false, isNew: false };

			return function (res, headers) {
				var parser = parserName && $injector.get(parserName);

				// If we get broken data
				if (typeof res === 'string') {
					try {
						res = JSON.parse(res);
					} catch (e) {
						return res;
					}
				}

				// Ignore parsing errors
				if (res.error) {
					if (res.error.errorCode === 26 && res.error.translated) {
						const resourceHelper = new ResourceHelper();
						resourceHelper.onFindError({}, { data: res });
					}
					return res;
				}

				if (!opts.skipDateConvert) {
					res.data = window.Tools.parseDates(res.data, opts.dateFields);
				}

				var customFields;

				if (typeof opts.custom === 'function') {
					var cfStr = opts.custom();
					customFields = AppService.getReadOnlyCustomFields(cfStr) || {};
				} else {
					customFields = opts.custom ? AppService.getReadOnlyCustomFields(opts.custom) : {};
				}

				if (opts.isArray) {
					res.data = _.map(res.data, function (item) {
						if (opts.custom && item.custom) {
							ParseHelpers.parseCustom(item, customFields);
						}
						return parser ? parser(item, headers) : item;
					});
				} else {
					if (opts.custom && res.data.custom) {
						ParseHelpers.parseCustom(res.data, customFields);
					}

					res.data = parser ? parser(res.data, headers, opts.isNew) : res.data;
				}

				return res;
			};
		};
	}
]);

angular.module('upResources').service('ParseGenericAsync', [
	'$injector',
	'AppService',
	'ParseHelpers',
	'$q',
	function ($injector, AppService, ParseHelpers, $q) {
		return function (parserName, opts) {
			opts = opts || { isArray: false, custom: false };

			return function (response, headers, isNew) {
				var parser = $injector.get(parserName);

				var defered = $q.defer();
				var res = response.data;

				setTimeout(function () {
					// Ignore parsing errors
					if (res.error) {
						return defered.resolve(res);
					}

					var customFields;

					if (typeof opts.custom === 'function') {
						var cfStr = opts.custom();
						customFields = AppService.getReadOnlyCustomFields(cfStr) || {};
					} else {
						customFields = opts.custom ? AppService.getReadOnlyCustomFields(opts.custom) : {};
					}

					if (opts.isArray) {
						res.data = _.map(res.data, function (item) {
							if (opts.custom && item.custom) {
								ParseHelpers.parseCustom(item, customFields);
							}
							return parser(item, headers);
						});
					} else {
						if (opts.custom && res.data.custom) {
							ParseHelpers.parseCustom(res.data, customFields);
						}

						res.data = parser(res.data, headers, isNew);
					}

					return defered.resolve(res);
				}, 0);

				return defered.promise;
			};
		};
	}
]);

'use strict';

angular
	.module('upDirectives', [])
	.directive('input', [
		'$compile',
		'$datepicker',
		'$parse',
		'$filter',
		function ($compile, $datepicker, $parse, $filter) {
			var inputType = {
				phone: function (scope, element, attr, ngModel) {
					if (attr.type !== 'phone') {
						return;
					}

					// Override the input event and add custom 'path' logic
					element.unbind('input');
					element.bind('input', function () {
						// eslint-disable-next-line
						var path = this.value.replace(/[^0-9\s\.\-\+]/g, '');

						ngModel.$setViewValue(path);
						ngModel.$render();
					});
				},
				zipcode: function (scope, element, attr, ngModel) {
					if (attr.type !== 'zipcode') {
						return;
					}
					// Override the input event and add custom 'path' logic
					element.unbind('input');
					element.bind('input', function () {
						var path = this.value.replace(/[^0-9]/g, '');
						ngModel.$setViewValue(path);
						ngModel.$render();
					});
				},

				text: function () {
					// body...
				},
				'url-link': function ($scope, $element, $attr, $ctrl) {
					function ensureHttpPrefix(value) {
						var notValid =
							value !== undefined &&
							value !== null &&
							!(
								'http://'.indexOf(value.substring(0, 7)) === 0 ||
								'https://'.indexOf(value.substring(0, 8)) === 0 ||
								'mailto:'.indexOf(value.substring(0, 7)) === 0 ||
								'ftp://'.indexOf(value.substring(0, 6)) === 0
							);

						if (notValid) {
							$ctrl.$setViewValue('http://' + value);
							$ctrl.$render();
							return $ctrl.$viewValue;
						} else {
							return value;
						}
					}

					$ctrl.$formatters.unshift(ensureHttpPrefix);
					$ctrl.$parsers.unshift(ensureHttpPrefix);
				},
				url: function ($scope, $element, $attr, $ctrl) {
					function ensureHttpPrefix(value) {
						var notValid =
							value !== undefined &&
							value !== null &&
							!(
								'http://'.indexOf(value.substring(0, 7)) === 0 ||
								'https://'.indexOf(value.substring(0, 8)) === 0 ||
								'ftp://'.indexOf(value.substring(0, 6)) === 0
							);

						if (notValid) {
							$ctrl.$setViewValue('http://' + value);
							$ctrl.$render();
							return $ctrl.$viewValue;
						} else {
							return value;
						}
					}

					$ctrl.$formatters.unshift(ensureHttpPrefix);
					$ctrl.$parsers.unshift(ensureHttpPrefix);
				},
				'url-text': function ($scope, $element, $attr, $ctrl) {
					var allowTags = $attr.allowTag !== undefined;

					function ensureHttpPrefix(value) {
						var notValid =
							value !== undefined &&
							value !== null &&
							!(
								'http://'.indexOf(value.substring(0, 7)) === 0 ||
								'https://'.indexOf(value.substring(0, 8)) === 0 ||
								'ftp://'.indexOf(value.substring(0, 6)) === 0 ||
								'mailto:'.indexOf(value.substring(0, 7)) === 0
							);

						if ((value || '').indexOf('{') === 0 && allowTags) {
							return value;
						} else if (notValid) {
							$ctrl.$setViewValue('http://' + value);
							$ctrl.$render();
							return $ctrl.$viewValue;
						} else {
							return value;
						}
					}

					$ctrl.$formatters.unshift(ensureHttpPrefix);
					$ctrl.$parsers.unshift(ensureHttpPrefix);

					if (allowTags) {
						$element.attr({ type: 'text' });
					}
				},

				date: function ($scope, $element, $attr) {
					if ('bsDatepicker' in $attr) return;

					var getDateFormat = function () {
						var format = moment.localeData().longDateFormat('L');
						format = format.replace('DD', 'dd');
						format = format.replace('YYYY', 'yyyy');
						return format;
					};

					var weekStart = (moment.localeData()._week && moment.localeData()._week.dow) || 0;

					$element.attr({
						container: 'body',
						'bs-datepicker': '',
						'date-type': 'date',
						'date-format': $attr.dateFormat || getDateFormat(),
						'use-native': true,
						type: 'text',
						'data-start-week': weekStart,
						autoclose: true,
						'data-placement': $attr.placement || 'auto',
						'ng-focus': $attr.ngFocus
					});

					var clone = $element.clone(true);

					clone.on('keydown', function (event) {
						if (event.altKey && event.keyCode === 40) {
							event.preventDefault();
						}
					});

					$element.replaceWith(clone);

					$compile(clone)($scope);
				},

				time: function ($scope, $element, $attr, $ctrl) {
					var getTimeFormat = function () {
						var format = moment.localeData()._longDateFormat.LT;
						format = format.replace('mm', 'i');
						format = format.replace('HH', 'H');
						format = format.replace('h', 'g');
						return format;
					};

					//jQuery timepicker config
					var pickerConfig = {};
					pickerConfig.step = $attr.step !== undefined ? $attr.step : 15;
					pickerConfig.appendTo = $attr.appendTo !== undefined ? $attr.appendTo : 'body';
					pickerConfig.timeFormat = getTimeFormat();
					pickerConfig.lang = { decimal: '.', mins: 'min', hr: 'h', hrs: 'h' };
					pickerConfig.forceRoundTime = false;
					pickerConfig.maxTime = '24:00';
					pickerConfig.scrollDefaultNow = true;

					$element.attr({
						maxlength: 8,
						type: 'text'
					});

					$ctrl.$parsers = [
						function (val) {
							const value = val
								? moment(`2021-01-01 ${val.replace('.', ':')}`).diff('2021-01-01 00:00', 'seconds')
								: null;

							if (value === null || isNaN(value)) {
								if ($attr.required) {
									$ctrl.$setValidity('required', false);
								}
								return '';
							}
							var hours = Math.floor(value / 3600) % 24;
							var minutes = (Math.floor(value / 60) - hours * 60) % 60;

							if ($attr.required) {
								$ctrl.$setValidity('required', true);
							}

							return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
						},
						function (val) {
							if (val === '' && $attr.asString !== undefined) return '';
							if (val === '') return undefined;
							val = val || '00:00';
							if ($attr.asString !== undefined) {
								return val;
							}
							var theDate = moment($ctrl.$modelValue).format('YYYY-MM-DD');
							val = moment(theDate + ' ' + val).toDate();

							return val;
						}
					];

					$ctrl.$formatters = [
						function (val) {
							if (val === undefined) return;
							if ($attr.asString !== undefined) {
								return val;
							}

							if (val !== moment($element.timepicker('getTime')).format('HH:mm')) {
								$element.timepicker('setTime', val);
							}
							val = $filter('momentFormat')(val, 'LT');
							return val;
						}
					];

					$element.timepicker(pickerConfig);

					$element.on('timeFormatError', function (evt) {
						evt.stopPropagation();
					});
				},

				'date-time': function () {
					// body...
				},

				email: function ($scope, $element, $attr, $ctrl) {
					// Save formatters and clear them
					var oldFormatters = $ctrl.$formatters;
					var oldParsers = $ctrl.$parsers;
					$ctrl.$formatters = [];
					$ctrl.$parsers = [];

					// Set temporary parser to disable validation on init
					$ctrl.$formatters = [
						function (val) {
							// Enable validation again
							$ctrl.$formatters = oldFormatters;
							$ctrl.$parsers = oldParsers;
							var isValid = !val || !val.length || oldFormatters[0](val) !== undefined;
							$ctrl.$setValidity('email', isValid);

							return val;
						}
					];
				}
			};

			return {
				restrict: 'E',
				require: '?ngModel',
				priority: 100, // execute after angular
				link: function ($scope, $element, $attr, $ctrl) {
					if ($ctrl) {
						// run type specific mods
						(inputType[angular.lowercase($attr.type)] || inputType.text)($scope, $element, $attr, $ctrl);
					}
				}
			};
		}
	])
	.directive('maxlength', function () {
		// Maxlength for number inputs
		return {
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, input, $attrs, $ctrl) {
				if ($ctrl && $attrs.type === 'number' && !isNaN($attrs.maxlength)) {
					$ctrl.$formatters = Array.isArray($ctrl.$formatters) ? $ctrl.$formatters : [];
					$ctrl.$parsers = Array.isArray($ctrl.$parsers) ? $ctrl.$parsers : [];

					var limitLength = function (value) {
						if (value !== undefined && value !== null && value.length > $attrs.maxlength) {
							$ctrl.$setViewValue(value.slice(0, $attrs.maxlength));
							$ctrl.$render();
							return $ctrl.$viewValue;
						} else {
							return value;
						}
					};

					$ctrl.$formatters.push(limitLength);
					$ctrl.$parsers.push(limitLength);
				}
			}
		};
	})
	.constant('dropdownConfig', {
		openClass: 'open'
	})
	.service('dropdownService', [
		'$document',
		function ($document) {
			var openScope = null;

			this.open = function (dropdownScope) {
				if (!openScope) {
					$document.bind('click', closeDropdown);
					$document.bind('keydown', escapeKeyBind);
				}

				if (openScope && openScope !== dropdownScope) {
					openScope.isOpen = false;
				}

				openScope = dropdownScope;
			};

			this.close = function (dropdownScope) {
				if (openScope === dropdownScope) {
					openScope = null;
					$document.unbind('click', closeDropdown);
					$document.unbind('keydown', escapeKeyBind);
				}
			};

			var closeDropdown = function (evt) {
				var toggleElement = openScope.getToggleElement();
				if (evt && toggleElement && toggleElement[0].contains(evt.target)) {
					return;
				}

				openScope.$apply(function () {
					openScope.isOpen = false;
				});
			};

			var escapeKeyBind = function (evt) {
				if (evt.which === 27) {
					openScope.focusToggleElement();
					closeDropdown();
				}
			};
		}
	])
	.controller('DropdownController', [
		'$scope',
		'$attrs',
		'$parse',
		'dropdownConfig',
		'dropdownService',
		'$animate',
		function ($scope, $attrs, $parse, dropdownConfig, dropdownService, $animate) {
			var self = this,
				scope = $scope.$new(), // create a child scope so we are not polluting original one
				openClass = dropdownConfig.openClass,
				getIsOpen,
				setIsOpen = angular.noop,
				toggleInvoker = $attrs.onToggle ? $parse($attrs.onToggle) : angular.noop;

			this.init = function (element) {
				self.$element = element;

				if ($attrs.isOpen) {
					getIsOpen = $parse($attrs.isOpen);
					setIsOpen = getIsOpen.assign;

					$scope.$watch(getIsOpen, function (value) {
						scope.isOpen = !!value;
					});
				}
			};

			this.toggle = function (open) {
				return (scope.isOpen = arguments.length ? !!open : !scope.isOpen);
			};

			// Allow other directives to watch status
			this.isOpen = function () {
				return scope.isOpen;
			};

			scope.getToggleElement = function () {
				return self.toggleElement;
			};

			scope.focusToggleElement = function () {
				if (self.toggleElement) {
					self.toggleElement[0].focus();
				}
			};

			scope.$watch('isOpen', function (isOpen, wasOpen) {
				$animate[isOpen ? 'addClass' : 'removeClass'](self.$element, openClass);

				if (isOpen) {
					scope.focusToggleElement();
					dropdownService.open(scope);
				} else {
					dropdownService.close(scope);
				}

				setIsOpen($scope, isOpen);
				if (angular.isDefined(isOpen) && isOpen !== wasOpen) {
					toggleInvoker($scope, {
						open: !!isOpen
					});
				}
			});

			$scope.$on('$locationChangeSuccess', function () {
				scope.isOpen = false;
			});

			$scope.$on('$destroy', function () {
				scope.$destroy();
			});
		}
	])
	.directive('dropdown', function () {
		return {
			restrict: 'CA',
			controller: 'DropdownController',
			link: function (scope, element, attrs, dropdownCtrl) {
				dropdownCtrl.init(element);
			}
		};
	})
	.directive('toggle', dropdownToggle)
	.directive('dropdownToggle', dropdownToggle);

function dropdownToggle() {
	return {
		restrict: 'CA',
		require: '?^dropdown',
		link: function (scope, element, attrs, dropdownCtrl) {
			if (!dropdownCtrl) {
				return;
			}

			dropdownCtrl.toggleElement = element;

			var toggleDropdown = function (event) {
				event.preventDefault();
				event.stopPropagation();

				if (!element.hasClass('disabled') && !attrs.disabled) {
					scope.$apply(function () {
						dropdownCtrl.toggle();
					});
				}
			};

			element.bind('click', toggleDropdown);

			// WAI-ARIA
			element.attr({
				'aria-haspopup': true,
				'aria-expanded': false
			});
			scope.$watch(dropdownCtrl.isOpen, function (isOpen) {
				element.attr('aria-expanded', !!isOpen);
			});

			scope.$on('$destroy', function () {
				element.unbind('click', toggleDropdown);
			});
		}
	};
}

'use strict';

import { Icon, Button, DropDownMenu, Card, Table, TableRow, TableColumn } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import classNames from 'classnames';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { OUTCOME_TYPES, SET_DATE, POSTPONED } from 'App/babel/enum/activityOutcome';
import { phoneNumberHelper } from '@upsales/common';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';

ReactTemplates.ROWSTATICS.activityRow = function (tools) {
	var statics = {};
	statics.createDocTool =
		tools.$translate('default.create') + ' ' + tools.$translate('default.document').toLowerCase();
	statics.closeTool = tools.$translate('default.close');
	statics.deleteTool = tools.$translate('default.delete');
	statics.noCampaign = tools.$translate('default.noCampaign');
	statics.move = tools.$translate('activity.move');
	statics.openInFlashMode = tools.$translate('activity.openInFlashMode');
	statics.markAsImportant = tools.$translate('activity.markAsImportant');
	statics.removeAsImportant = tools.$translate('activity.removeAsImportant');
	statics.openOnCompanyCard = tools.$translate('activity.openOnCompanyCard');

	return statics;
};

ReactTemplates.ROW.activityRow = window.ReactCreateClass({
	displayName: 'activityRow',
	getInitialState: function () {
		return {
			isUpdating: false
		};
	},
	close: function () {
		this.props.tools.opts.closeActivity(this.props.item);
	},
	move: function () {
		this.props.tools.opts.moveActivity(this.props.item);
	},
	openFlash: function () {
		this.props.tools.opts.openFlash(this.props.item);
	},
	createDocument: function () {
		this.props.tools.opts.createDocument(this.props.item);
	},
	onToolsOpen: function (e) {
		e.preventDefault();
		e.stopPropagation();
	},
	onSelectTool: function (fn, e) {
		e.stopPropagation();

		fn();
	},
	setPriority: async function () {
		this.setState({ isUpdating: true });
		await this.props.tools.opts.setPriority(this.props.item);
		this.setState({ isUpdating: false });
	},

	setOutcome: function (outcome) {
		let outcomeDate = '';
		if (outcome.date) {
			const formatDate = new Date(outcome.date);
			outcomeDate = moment(formatDate).format('L LT');
		}

		let outcomeType = this.props.tools.$translate('activity.outcome.type.${' + outcome.type + '}') + ':';
		let outcomeTitle = this.props.tools.$translate('activity.outcome.${' + outcome.outcome + '}');

		if (outcome.type === OUTCOME_TYPES.POSTPONE && outcome.outcome === POSTPONED) {
			outcomeType = '';
		}

		if (outcome.type === SET_DATE) {
			outcomeTitle = '';
		}

		return (
			<div>
				<span>{outcomeType}</span>
				<span>{outcomeTitle}</span>
				<div>
					<span>{outcomeDate} </span> <br />
				</div>
			</div>
		);
	},

	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		const hasActivities = !Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
		const hasFlash =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FLASH) &&
			item.client &&
			hasActivities &&
			!(item.activityType?.name === 'Todo');
		const isPriorityEnable = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		const hasPriority = item.priority > ACTIVITY_PRIORITY.NONE;
		const isShowingOutcomes = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);

		const getTooltipContent = str => {
			return (str || '').substr(0, 400) + (str.length > 400 ? '...' : '');
		};
		const tooltipOpts = {
			placement: 'left',
			delayShow: 1000,
			theme: 'blue'
		};

		var isPassed = function (date) {
			return moment(date).endOf('day').isBefore();
		};

		var getColumn = function (column, item) {
			var key = 'column-' + column;

			switch (column) {
				case 'date':
					if (item.date && item.time) {
						return (
							<td key={key} className="column-date">
								{ReactTemplates.TOOLS.dateCalendar(item.date, 'L')} {moment(item.date).format('LT')}
							</td>
						);
					} else {
						return (
							<td key={key} className="column-date">
								{item.date ? (
									ReactTemplates.TOOLS.dateCalendar(item.date, 'L')
								) : (
									<i className="grey">{tools.$translate('default.noDate')}</i>
								)}
							</td>
						);
					}

				case 'phone':
					var val = null;
					var obj = null;
					var type = 'contact';
					if (item.contacts && item.contacts.length) {
						val = item.contacts[0].cellPhone || item.contacts[0].phone;
						obj = item.contacts[0];
						obj.client = item.client;
					}
					if (!val || val === '') {
						val = item.client ? item.client.phone : null;
						type = 'client';
						obj = item.client;
					}
					var tel;

					var related = {
						type: 'Activity',
						id: item.id
					};

					if (val && obj) {
						tel = ReactTemplates.TOOLS.upVoice(obj, type, val, related);
					}
					return (
						<td key={key}>
							{tel ? (
								<a target={tel.target} href={tel.href} onClick={tel.onClick}>
									{phoneNumberHelper.formatNumber(val)}
								</a>
							) : null}
						</td>
					);

				case 'opportunity':
					return (
						<td key={key}>
							{item.opportunity ? (
								<a onClick={options.editOrder.bind(this, item.opportunity)}>
									{item.opportunity.description ||
										tools.$translate('default.noDescription') +
											' (' +
											item.opportunity.probability +
											'%)'}
								</a>
							) : (
								''
							)}
						</td>
					);

				case 'priority':
					if (!Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION')) {
						return null;
					}

					return (
						<td className="column-prio" key={key}>
							{item.priority ? (
								<div className="important">
									<Icon color="red" name="circle" />
									{tools.$translate('default.important')}
								</div>
							) : null}
						</td>
					);

				case 'client':
					if (!item.client) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noAccount')}</i>
							</td>
						);
					}
					return (
						<td key={key}>
							{ReactTemplates.TOOLS.withTooltip(
								<ReactTemplates.TOOLS.accountHref
									customerId={customerId}
									activityId={item.id}
									id={item.client.id}
									text={item.client.name}
									tools={tools}
								/>,
								getTooltipContent(isShowingOutcomes ? statics.openOnCompanyCard : ''),
								{
									placement: 'left',
									theme: 'green'
								}
							)}
						</td>
					);

				case 'activityType':
					return (
						<td key={key}>
							{item.activityType
								? item.notes
									? ReactTemplates.TOOLS.withTooltip(
											<span>{item.activityType.name}</span>,
											getTooltipContent(item.notes),
											tooltipOpts
									  )
									: item.activityType.name
								: ''}
						</td>
					);

				case 'description':
					return (
						<td key={key}>
							{item.description
								? item.notes
									? ReactTemplates.TOOLS.withTooltip(
											<span>{item.description}</span>,
											getTooltipContent(item.notes),
											tooltipOpts
									  )
									: item.description
								: ''}
						</td>
					);

				case 'contacts':
					if (!item.contacts || !item.contacts.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noContact')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.contactsText(customerId, item.contacts, tools)}</td>;
					}

				case 'users':
					if (!item.users || !item.users.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText(item.users, tools)}</td>;
					}

				case 'campaign':
					if (!item.project) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaign}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText([item.project], tools)}</td>;
					}

				case 'accountManager':
					if (!item.client || !item.client.users || !item.client.users.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noAccountManager')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText(item.client.users, tools)}</td>;
					}

				case 'contactAndClient':
					return (
						<td key={key} className="account-contact-col">
							{item.contacts && item.contacts.length ? (
								<ReactTemplates.TOOLS.contactHref
									customerId={customerId}
									id={item.contacts[0].id}
									text={item.contacts[0].name}
									tools={tools}
								/>
							) : (
								<i className="text-placeholder">{tools.$translate('default.noContact')}</i>
							)}
							{item.client ? (
								<span className="account-subtitle">
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.client.id}
										text={item.client.name}
										tools={tools}
									/>
								</span>
							) : null}
						</td>
					);

				case 'activity':
					var content = (
						<div>
							{item.description ? item.description : ''}
							{item.activityType ? (
								<span className="account-subtitle">{item.activityType.name}</span>
							) : null}
						</div>
					);

					return (
						<td key={key} className="account-contact-col">
							{item.notes
								? ReactTemplates.TOOLS.withTooltip(content, getTooltipContent(item.notes), tooltipOpts)
								: content}
						</td>
					);

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep =
						item.contacts && item.contacts.length ? item.contacts[0].journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);

				case 'outcome':
					var outcome = (
						<div>
							{item.lastOutcome && item.lastOutcome.type ? (
								ReactTemplates.TOOLS.withTooltip(
									self.setOutcome(item.lastOutcome),
									getTooltipContent(
										item.lastOutcome.user
											? tools.$translate('activity.outcome.by') + ' ' + item.lastOutcome.user.name
											: ''
									)
								)
							) : (
								<i className="grey"> {tools.$translate('activity.noOutcome')} </i>
							)}
						</div>
					);
					if (isShowingOutcomes) {
						return (
							<td key={key} className="account-contact-col">
								{outcome}
							</td>
						);
					} else {
						return false;
					}

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var isInactive = item.closeDate || (item.isAppointment && +item.endDate < +new Date());
		const classes = classNames('', {
			inactive: isInactive,
			passed: !item.isAppointment && !item.closeDate && isPassed(item.date),
			'has-priority': isPriorityEnable,
			'high-priority': isPriorityEnable && item.priority === ACTIVITY_PRIORITY.HIGH
		});

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		var toolClassName = classNames('column-tools responsive', { 'has-priority': isPriorityEnable });
		const dropdown = (
			<DropDownMenu
				align="right"
				renderTrigger={(_e, setOpen) => (
					<Icon
						name="bars"
						onClick={e => {
							setOpen(e, true);
						}}
					/>
				)}
			>
				<Card>
					<Table>
						{hasFlash && !item.closeDate && (
							<TableRow onClick={self.onSelectTool.bind(self, self.openFlash)} key="flash">
								<TableColumn size="sm" icon="flash" title={statics.openInFlashMode} />
							</TableRow>
						)}
						{item.userEditable && !isInactive && (
							<TableRow onClick={self.onSelectTool.bind(self, self.move)} key="move">
								<TableColumn size="sm" icon="long-arrow-right" title={statics.move} />
							</TableRow>
						)}
						{item.userEditable && !item.closeDate && !item.isAppointment && !item.subject && (
							<TableRow onClick={self.onSelectTool.bind(self, self.close)} key="close">
								<TableColumn size="sm" icon="check-square-o" title={statics.closeTool} />
							</TableRow>
						)}
						{item.userRemovable && (
							<TableRow
								onClick={self.onSelectTool.bind(
									self,
									this.props.tools.opts.removeEntry.bind(null, this.props.item, true)
								)}
								key="delete"
							>
								<TableColumn size="sm" icon="trash-o" title={statics.deleteTool} />
							</TableRow>
						)}
					</Table>
				</Card>
			</DropDownMenu>
		);

		return (
			<tr className={classes} key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className={toolClassName}>
					{dropdown}
					<ReactTemplates.TOOLS.rowTool
						className={hasPriority && 'priority-active'}
						loading={this.state.isUpdating}
						item={item}
						show={isPriorityEnable && !item.closeDate}
						icon="flag"
						tooltip={hasPriority ? statics.removeAsImportant : statics.markAsImportant}
						onClick={this.setPriority}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={hasFlash && !item.closeDate}
						icon="flash"
						tooltip={statics.openInFlashMode}
						onClick={this.openFlash}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userEditable && !isInactive}
						icon="long-arrow-right"
						tooltip={statics.move}
						onClick={this.move}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userEditable && !item.closeDate && !item.isAppointment && !item.subject}
						icon="check-square-o"
						tooltip={statics.closeTool}
						onClick={this.close}
					/>
					<InlineConfirm
						show={item.userRemovable}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.activitySinglar'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

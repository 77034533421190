import FieldTranslation from 'App/babel/resources/FieldTranslations';

const formatData = function (data) {
	data.sort(function (a, b) {
		const valueA = a.value.toLowerCase();
		const valueB = b.value.toLowerCase();
		if (valueA < valueB) return -1;
		if (valueA > valueB) return 1;
		return 0;
	});
	return data;
};

angular
	.module('upDirectives')
	.directive('upFieldTranslation', [
		'selectHelper',
		function (selectHelper) {
			return selectHelper.getDirectiveObject('upFieldTranslation', {
				resource: FieldTranslation,
				limit: 50,
				ajax: true,
				asIds: false,
				idAttr: { field: 'tagId' },
				titleAttr: { field: 'value' },
				sorting: { field: { field: 'value' }, ascending: true },
				filters: function (rb, $attrs) {
					rb.addFilter({ field: 'type' }, rb.comparisonTypes.Equals, $attrs.fieldType);
				},
				formatData: formatData
			});
		}
	])
	.directive('upFieldTranslationIds', [
		'selectHelper',
		function (selectHelper) {
			return selectHelper.getDirectiveObjectIds('upFieldTranslationIds', {
				resource: FieldTranslation,
				limit: 50,
				ajax: true,
				asIds: true,
				idAttr: { field: 'tagId' },
				titleAttr: { field: 'value' },
				sorting: { field: { field: 'value' }, ascending: true },
				filters: function (rb, $attrs) {
					rb.addFilter({ field: 'type' }, rb.comparisonTypes.Equals, $attrs.fieldType);
				},
				formatData: formatData
			});
		}
	]);

'use strict';

ReactTemplates.upFilters.filterTypes.idList = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			placeholder: 'default.add'
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			exclude: t('default.exclude')
		};
	},

	getInitialState: function () {
		var value = this.props.filter && this.props.filter.value ? this.props.filter.value : '';
		return {
			value: value,
			expanded: this.props.expanded || false
		};
	},

	toggleExpanded: function () {
		if (this.props.filter.noExpand) {
			return;
		}

		this.setState({
			expanded: !this.state.expanded
		});
	},

	onChange: function (filter, options) {
		this.props.onChange(filter, options);
	},

	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.onChange(this.props.filter, {
			action: 'remove'
		});

		ReactTemplates.TOOLS.stopProp(event);
	},

	formatValueString: function () {
		var filter = this.props.filter;
		var valueString = '';

		if (filter.value && filter.value.length) {
			valueString = filter.value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
		} else {
			valueString = Tools.$translate('default.all');
		}
		return valueString;
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	onKeyDown: function (e) {
		if (e.keyCode === 13 && e.target.value && e.target.value.length) {
			var inactive = this.props.opts && this.props.opts.idListIsInactive ? true : false;
			var value = this.props.filter.value || [];
			var val = parseInt(e.target.value);
			if (!isNaN(val) && value.indexOf(val) === -1) {
				value.push(val);
				var cf;

				if (this.props.filter.excludeType) {
					cf = this.props.filter.excludeType;
				} else if (this.props.filter.isExcludeFilter) {
					cf = 'NotEquals';
				} else {
					cf = this._isIsNot.getValue() ? 'NotEquals' : 'Equals';
				}
				this.onChange(
					{
						filterName: this.props.filterName,
						comparisonType: cf,
						inactive: !value.length || inactive,
						value: value,
						type: 'text'
					},
					{
						action: 'add'
					}
				);
				var input = this._input;
				if (input) {
					input.value = '';
					input.focus();
				}
			}
		}
	},

	removeValue: function (val) {
		var value = this.props.filter.value || [];
		var inactive = this.props.opts && this.props.opts.idListIsInactive ? true : false;
		var i = value.indexOf(val);
		if (i !== -1) {
			value.splice(i, 1);
			this.onChange(
				{
					filterName: this.props.filterName,
					comparisonType: this.props.filter.comparisonType,
					inactive: !value.length || inactive,
					value: value,
					type: 'text'
				},
				{
					action: 'add'
				}
			);
		}
	},

	onToggleComperator: function (value) {
		var newFilter;
		var currentFilter = this.props.filter;
		var newComparisionType = value ? 'NotEquals' : 'Equals';
		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;
			this.onChange(newFilter, {
				action: 'add'
			});
		}
	},

	render: function () {
		var self = this;
		var filter = this.props.filter;
		var value = this.props.filter.value || [];
		var active = value.length ? true : false;

		var title = filter.title ? Tools.$translate(filter.title) : this.props.filterName;
		var placeholder = Tools.$translate(this.props.placeholder);

		var checked = false;
		if (filter && filter.comparisonType === 'NotEquals') {
			checked = true;
		}

		var classNames = 'drilldown token-drilldown';
		var isExcludeFilter = this.props.filter.isExcludeFilter;
		if (isExcludeFilter) {
			classNames += ' is-exclude-filter';
		}

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className={classNames}>
						<input
							maxLength="120"
							type={filter.inputType || 'text'}
							className="form-control input-sm"
							placeholder={placeholder}
							ref={this.setRef.bind(this, '_input')}
							onKeyDown={this.onKeyDown}
						/>
						{!isExcludeFilter && (
							<span>
								<label>
									{self.lang.exclude}
									{':'}
								</label>
								<ReactTemplates.upFilters.components.toggle
									className="toggle-bright-blue"
									checked={checked}
									onChange={this.onToggleComperator}
									ref={this.setRef.bind(this, '_isIsNot')}
								/>
							</span>
						)}
						<div className="token-values">
							{value.map(function (val) {
								return (
									<div className="token-item" key={val}>
										{val}
										<button type="button" onClick={self.removeValue.bind(self, val)}>
											<b className="fa fa-times"></b>
										</button>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
});

import { Paginator } from '@upsales/components';

ReactTemplates.form.formDashboard = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			newLandingPage: t('form.newLandingPage'),
			landingPageInfo: t('form.landingPageInfo'),
			nrOfSubmits: t('form.nrOfSubmits'),
			nrOfVisits: t('form.nrOfVisits'),
			createdScore: t('form.createdScore'),
			submits: t('defaultView.formSubmit'),
			visits: t('form.views'),
			exporting: t('default.export'),
			show: t('default.show'),
			landingLink: t('form.landingLink'),
			landingLinkInfo: t('form.landingLinkInfo'),
			noResult: t('noResult.default'),
			noSubmitOrVisits: t('form.noSubmitOrVisits'),
			noRightsToExport: t('form.noRightsToExport')
		};
	},
	onChangePage: function (page, offset) {
		this.props.rootData.getListData(offset);
	},
	changeTab: function (tab) {
		if (this.props.rootData.tab !== tab) {
			this.props.rootData.changeTab(tab);
		}
	},
	exportClick: function (e) {
		if (!window.Tools.AppService.getSelf().export) {
			return e.preventDefault();
		}

		this.props.rootData.exportFn();
	},
	sort: function () {
		self.props.rootData.tools.sort();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var self = this;
		var rootData = self.props.rootData;
		var tableColumns = {
			visit: ['startDate', 'account', 'status', 'location', 'assigned', 'score', 'leadSource'],
			submit: ['processedDate', 'contactAndClient', 'clientStatus', 'leadSource']
		};

		var template = rootData.tab === 'submit' ? 'formSubmitRow' : 'visitorRow';
		var statics = ReactTemplates.ROWSTATICS[template] ? ReactTemplates.ROWSTATICS[template](self.props.tools) : {};
		var attributes = rootData.visitAttributes;
		// var length = rootData.tableData ? rootData.tableData.length : 0;
		if (rootData.tab !== 'visit') {
			attributes = rootData.formSubmitAttributes;
		}

		var tableRows = null;
		var thead = null;
		if (!rootData.tableLoading) {
			if (!rootData.tableData.length) {
				tableRows = (
					<tr className="no-result-row">
						<td colSpan={'100'}>{self.lang.noResult}</td>
					</tr>
				);
			} else {
				tableRows = _.map(rootData.tableData, function (item) {
					return React.createElement(ReactTemplates.ROW[template], {
						item: item,
						tools: self.props.tools,
						columns: tableColumns[rootData.tab],
						statics: statics
					});
				});
			}
		} else {
			tableRows = (
				<tr className="loading-bar">
					<td colSpan="100">
						<i className="fa fa-spin fa-refresh spinner"></i>
					</td>
				</tr>
			);
		}
		if (attributes && rootData.currentSorting) {
			thead = React.createElement(ReactTemplates.TOOLS.thead, {
				columns: tableColumns[rootData.tab],
				tools: self.props.tools,
				columnTemplates: attributes,
				currentSorting: rootData.currentSorting
			});
		}

		var topBar = React.createElement(ReactTemplates.form.formDashboardTopbar, {
			tools: self.props.tools,
			statics: this.lang,
			isLandingPage: rootData.isLandingPage,
			graphConfig: rootData.graphConfig,
			totalVisits: rootData.totalVisits,
			totalSubmits: rootData.totalSubmits,
			totalScore: rootData.totalScore,
			loading: rootData.reportLoading,
			noData: rootData.noData,
			url: rootData.url,
			isVisitHidden: rootData.isVisitHidden,
			noVisitOrSubmitLabel: self.lang.noSubmitOrVisits,
			html: rootData.previewHtml,
			form: rootData.form,
			saveTags: rootData.saveTags
		});

		var visitTabClasses = 'tab',
			submitTabClasses = 'tab';

		// if(rootData.tableLoading){
		// 	visitTabClasses  += ' disabled';
		// 	submitTabClasses += ' disabled';
		// }
		var exportBtn = null;
		var exportTextSpan = null;
		if (!self.props.tools.AppService.getSelf().export) {
			exportTextSpan = ReactTemplates.TOOLS.withTooltip(
				<div className={'export-btn disabled'} onClick={self.exportClick}>
					<i className="fa fa-file-text-o"></i>
					{<span>{self.lang.exporting}</span>}
				</div>,
				this.lang.noRightsToExport,
				{ placement: 'left', key: 'export-rights-tooltip' }
			);
		} else {
			exportTextSpan = (
				<div className={'export-btn'} onClick={self.exportClick}>
					<i className="fa fa-file-text-o"></i>
					{<span>{self.lang.exporting}</span>}
				</div>
			);
		}

		if (rootData.tab === 'submit') {
			submitTabClasses += ' current';
			exportBtn = exportTextSpan;
		} else {
			visitTabClasses += ' current';
		}

		var submitTab = (
			<div className={submitTabClasses} onClick={self.changeTab.bind(self, 'submit')}>
				{self.lang.submits}
			</div>
		);
		var visitTab = null;

		if (rootData.isLandingPage) {
			visitTab = (
				<div className={visitTabClasses} onClick={self.changeTab.bind(self, 'visit')}>
					{self.lang.visits}
				</div>
			);
		}

		var nrOfPage = rootData.tableTotal && rootData.limit ? Math.ceil(rootData.tableTotal / rootData.limit) : 0;

		var paginator2 = (
			<Paginator
				align="center"
				offset={rootData.offset}
				limit={rootData.limit}
				total={rootData.tableTotal}
				onChange={function (offset, page) {
					self.onChangePage(page, offset);
				}}
				style={{ padding: '15px 0' }}
			/>
		);

		return (
			<div id="form-dashboard" className="" ref={self.setRef.bind(self, '_dashboard')}>
				{topBar}

				<div className="lists">
					{submitTab}
					{visitTab}
					{exportBtn}

					<div className="list">
						<table className="main-table light-version">
							{thead}
							<tbody key="main-tbody">{tableRows}</tbody>
						</table>
						{nrOfPage > 1 ? paginator2 : null}
					</div>
				</div>
			</div>
		);
	}
});

'use strict';

angular.module('upUi').service('$multiSelect', [
	'$rootScope',
	'$safeApply',
	function ($rootScope, $safeApply) {
		var $multiSelect = this;
		var total = 0;
		var visible = 0;

		var triggerEvent = function (event) {
			$rootScope.$broadcast('$multiSelect.' + event, {
				selected: $multiSelect.selected,
				allSelected: $multiSelect.allSelected,
				allSelectedFilter: $multiSelect.allSelectedFilter
			});

			$safeApply($rootScope);
		};

		$multiSelect.selected = [];

		$multiSelect.allSelected = false;
		$multiSelect.allSelectedFilter = false;

		$multiSelect.selectAll = function () {
			$multiSelect.allSelected = true;
			$multiSelect.allSelectedFilter = false;
			$multiSelect.selected = [];
			triggerEvent('selectAll');
		};

		$multiSelect.selectAllFilter = function () {
			$multiSelect.allSelectedFilter = true;
			$multiSelect.selected = [];
			triggerEvent('selectAllFilter');
		};

		$multiSelect.selectAllVisible = function () {
			$multiSelect.allSelectedFilter = false;
			triggerEvent('selectAllVisible');
		};

		$multiSelect.selectNone = function () {
			$multiSelect.allSelected = false;
			$multiSelect.allSelectedFilter = false;
			$multiSelect.selected = [];
			triggerEvent('selectNone');
		};

		$multiSelect.isChecked = function (id) {
			return $multiSelect.selected.indexOf(id) !== -1;
		};

		$multiSelect.setTotal = function (tot) {
			total = tot;
		};

		$multiSelect.setVisible = function (tot) {
			// eslint-disable-next-line no-unused-vars
			visible = tot;
		};

		$multiSelect.getTotal = function () {
			return total;
		};

		$multiSelect.toggle = function (id) {
			var index = $multiSelect.selected.indexOf(id);

			if (index !== -1) {
				$multiSelect.allSelected = false;
				$multiSelect.allSelectedFilter = false;

				$multiSelect.selected.splice(index, 1);
			} else {
				$multiSelect.selected.push(id);

				if ($multiSelect.selected.length === total) {
					$multiSelect.allSelected = true;
				}
			}

			triggerEvent('toggle');
		};

		$multiSelect.toggleArray = function (idArray) {
			_.each(idArray, function (id) {
				var index = $multiSelect.selected.indexOf(id);

				if (index !== -1) {
					$multiSelect.allSelected = false;
					$multiSelect.allSelectedFilter = false;

					$multiSelect.selected.splice(index, 1);
				} else {
					$multiSelect.selected.push(id);

					if ($multiSelect.selected.length === total) {
						$multiSelect.allSelected = true;
					}
				}
			});

			triggerEvent('toggle');
		};

		$multiSelect.selectArray = function (idArray) {
			$multiSelect.allSelectedFilter = false;
			$multiSelect.selected = _.union($multiSelect.selected, idArray);

			triggerEvent('selectAll');
		};

		return $multiSelect;
	}
]);

'use strict';

import { phoneNumberHelper } from '@upsales/common';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import { Button, Icon } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

ReactTemplates.ROWSTATICS.appointmentRow = function (tools) {
	var statics = {};
	statics.createDocTool =
		tools.$translate('default.create') + ' ' + tools.$translate('default.document').toLowerCase();
	statics.closeTool = tools.$translate('default.close');
	statics.deleteTool = tools.$translate('default.delete');
	statics.noCampaign = tools.$translate('default.noCampaign');

	return statics;
};

ReactTemplates.ROW.appointmentRow = window.ReactCreateClass({
	displayName: 'appointmentRow',
	close: function () {
		this.props.tools.opts.closeActivity(this.props.item);
	},
	createDocument: function () {
		this.props.tools.opts.createDocument(this.props.item);
	},
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;

		const getTooltipContent = str => {
			return (str || '').substr(0, 400) + (str.length > 400 ? '...' : '');
		};
		const tooltipOpts = {
			placement: 'left',
			delayShow: 1000,
			theme: 'blue'
		};

		var isPassed = function (date) {
			return moment(date).endOf('day').isBefore();
		};

		var getColumn = function (column, item) {
			var key = 'column-' + column;
			switch (column) {
				case 'date':
					return (
						<td key={key} className="column-date">
							{ReactTemplates.TOOLS.dateCalendar(item.date, 'L')} {moment(item.date).format('LT')}
						</td>
					);

				case 'phone':
					var val = null;
					var obj = null;
					var type = 'contact';
					if (item.contacts && item.contacts.length) {
						val = item.contacts[0].cellPhone || item.contacts[0].phone;
						obj = item.contacts[0];
						obj.client = item.client;
					}
					if (!val || val === '') {
						val = item.client ? item.client.phone : null;
						type = 'client';
						obj = item.client;
					}
					var tel;
					if (val && obj) {
						tel = ReactTemplates.TOOLS.upVoice(obj, type, val);
					}
					return (
						<td key={key}>
							{tel ? (
								<a target={tel.target} href={tel.href} onClick={tel.onClick}>
									{phoneNumberHelper.formatNumber(val)}
								</a>
							) : null}
						</td>
					);

				case 'opportunity':
					return (
						<td key={key}>
							{item.opportunity ? (
								<a onClick={options.editOrder.bind(this, item.opportunity)}>
									{item.opportunity.description ||
										tools.$translate('default.noDescription') +
											' (' +
											item.opportunity.probability +
											'%)'}
								</a>
							) : (
								''
							)}
						</td>
					);

				case 'client':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						</td>
					);

				case 'activityType':
					return (
						<td key={key}>
							{item.activityType
								? item.notes
									? ReactTemplates.TOOLS.withTooltip(
											<span>{item.activityType.name}</span>,
											getTooltipContent(item.notes),
											tooltipOpts
									  )
									: item.activityType.name
								: ''}
						</td>
					);

				case 'description':
					return (
						<td key={key}>
							{item.description
								? item.notes
									? ReactTemplates.TOOLS.withTooltip(
											<span>{item.description}</span>,
											getTooltipContent(item.notes),
											tooltipOpts
									  )
									: item.description
								: ''}
						</td>
					);

				case 'contact':
				case 'contacts':
					if (!item.contacts || !item.contacts.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noContact')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.contactsText(customerId, item.contacts, tools)}</td>;
					}

				case 'users':
					if (!item.users || !item.users.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText(item.users, tools)}</td>;
					}

				case 'regBy':
					if (!item.regBy) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{item.regBy.name}</td>;
					}

				case 'project':
					if (!item.project) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaign}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText([item.project], tools)}</td>;
					}

				case 'accountManager':
					if (!item.client || !item.client.users || !item.client.users.length) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noAccountManager')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText(item.client.users, tools)}</td>;
					}

				case 'contactAndClient':
					return (
						<td key={key} className="account-contact-col">
							{item.contacts && item.contacts.length ? (
								ReactTemplates.TOOLS.contactsText(customerId, item.contacts, tools)
							) : (
								<i className="text-placeholder">{tools.$translate('default.noContact')}</i>
							)}
							{item.client ? (
								<span className="account-subtitle">
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.client.id}
										text={item.client.name}
										tools={tools}
									/>
								</span>
							) : null}
						</td>
					);

				case 'appointment':
					var content = (
						<div>
							{item.description ? item.description : ''}
							{item.activityType ? (
								<span className="account-subtitle">{item.activityType.name}</span>
							) : null}
						</div>
					);

					return (
						<td key={key} className="account-contact-col">
							{item.notes
								? ReactTemplates.TOOLS.withTooltip(content, getTooltipContent(item.notes), tooltipOpts)
								: content}
						</td>
					);

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep =
						item.contacts && item.contacts.length ? item.contacts[0].journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);
				case 'outcome':
					return (
						<td key={key}>
							<span>{item.outcome ? Tools.$translate(`appointment.outcome.${item.outcome}`) : ''}</span>
						</td>
					);
				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var isInactive = item.closeDate || (item.isAppointment && moment(item.endDate).isBefore(moment()));
		var classes = isInactive ? 'inactive' : '';

		if (!item.isAppointment && !item.closeDate && isPassed(item.date)) {
			classes += ' passed';
		}

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		return (
			<tr className={classes} key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className="column-tools">
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userEditable && !item.closeDate && !item.isAppointment && !item.subject}
						icon="check-square-o"
						tooltip={statics.closeTool}
						onClick={this.close}
					/>
					<InlineConfirm
						show={item.userRemovable}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.appointment'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

'use strict';

angular.module('domain.soliditet').factory('ConfirmSoliditetBuyParentMeta', [
	'$q',
	'AppService',
	'Account',
	'RequestBuilder',
	function ($q, AppService, Account, RequestBuilder) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();

				var filter = new RequestBuilder();
				filter.addFilter(Account.attr.name, filter.comparisonTypes.Wildcard, $modalParams.name);
				filter.fields = ['id', 'name'];
				filter.limit = 1;

				var promises = {
					matches: Account.customer(customerId).find(filter.build())
				};

				return $q.all(promises);
			});
		};
	}
]);

'use strict';

angular.module('services.automationTerms', []).factory('AutomationTerms', [
	'AppService',
	'TriggerHelper',

	function (AppService, TriggerHelper) {
		var operators = {
			GreaterThanEquals: 'GreaterThanEquals',
			LessThanEquals: 'LessThanEquals',
			Equals: 'Equals',
			NotEquals: 'NotEquals',
			GreaterThan: 'GreaterThan',
			LessThan: 'LessThan'
		};
		var terms = {
			'automation-clients': [
				{
					id: 'ClientScore.Score',
					title: 'default.leadScore',
					type: 'integer',
					operators: [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThanEquals,
						operators.LessThanEquals
					]
				},
				//{id: 'ClientScore.Source', title: 'automationTerms.leadSource', type: 'select', operators: [operators.Equals, operators.NotEquals], selectOptions: 'leadSources', selectText: 'name'},
				//{id: 'ClientScore.Keywords', title: 'admin.keywords', type: 'string', operators: [operators.Equals, operators.NotEquals]},
				//{id: 'LeaderClient.Country', title: 'address.country', type: 'select', operators: [operators.Equals, operators.NotEquals], selectOptions: 'countries', selectText: 'name'},
				//{id: 'LeaderClient.City', title: 'address.city', type: 'string', operators: [operators.Equals, operators.NotEquals]},
				{
					id: 'Client.UserId',
					title: 'default.accountManager',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'activeUsers',
					selectText: 'name'
				},
				{
					id: 'Client.UserRole',
					title: 'automationTerms.accountManagersRole',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'roles',
					selectText: 'name'
				},
				{
					id: 'Client.AssignedUserId',
					title: 'automationTerms.assignedUser',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'activeUsers',
					selectText: 'name'
				},
				{
					id: 'Client.AssignedUserRole',
					title: 'automationTerms.assignedUsersRole',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'roles',
					selectText: 'name'
				},
				//{id: 'Lead.Source', title: 'automationTerms.lastLeadSource', type: 'select', operators: [operators.Equals, operators.NotEquals], selectOptions: 'leadSources', selectText: 'name'},
				{
					id: 'Client.City',
					title: 'address.city',
					type: 'string',
					operators: [operators.Equals, operators.NotEquals]
				},
				{
					id: 'Client.ZipCode',
					title: 'address.zip',
					type: 'string',
					operators: [operators.Equals, operators.NotEquals]
				},
				{
					id: 'Client.Country',
					title: 'address.country',
					operators: [operators.Equals, operators.NotEquals],
					type: 'select',
					selectOptions: 'countries',
					selectText: 'lang'
				},
				{
					id: 'Client.RelatedCategory',
					title: 'default.category',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'accountCategories',
					selectText: 'name'
				},
				{
					id: 'Client.RelatedProject',
					title: 'default.project',
					type: 'project',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'campaigns',
					selectText: 'name'
				},
				//{id: 'Client.LastModificationTime', title: 'automationTerms.lastModificationTime', type: 'string', operators: [operators.GreaterThanEquals, operators.LessThanEquals]},
				{ standardFieldId: 15, type: 'string', operators: [operators.Equals, operators.NotEquals] }, //OrgNo
				{
					standardFieldId: 16,
					type: 'date',
					operators: [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThan,
						operators.GreaterThanEquals,
						operators.LessThan,
						operators.LessThanEquals
					]
				}, //RegistrationDate
				{ standardFieldId: 17, type: 'select', operators: [operators.Equals, operators.NotEquals] }, //SniCode
				{ standardFieldId: 18, type: 'select', operators: [operators.Equals, operators.NotEquals] }, //CompanyForm
				{ standardFieldId: 19, type: 'select', operators: [operators.Equals, operators.NotEquals] }, //CreditRating
				{ standardFieldId: 27, type: 'select', operators: [operators.Equals, operators.NotEquals] }, // status
				{
					standardFieldId: 20,
					type: 'integer',
					operators: [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThan,
						operators.GreaterThanEquals,
						operators.LessThan,
						operators.LessThanEquals
					]
				}, //Turnover
				{
					standardFieldId: 21,
					type: 'integer',
					operators: [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThan,
						operators.GreaterThanEquals,
						operators.LessThan,
						operators.LessThanEquals
					]
				}, //Profit
				{
					standardFieldId: 22,
					type: 'integer',
					operators: [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThan,
						operators.GreaterThanEquals,
						operators.LessThan,
						operators.LessThanEquals
					]
				}, //NoEmployees
				{ standardFieldId: 23, type: 'string', operators: [operators.Equals, operators.NotEquals] }, //Facebook
				{ standardFieldId: 24, type: 'string', operators: [operators.Equals, operators.NotEquals] }, //Twitter
				{ standardFieldId: 25, type: 'string', operators: [operators.Equals, operators.NotEquals] }, //LinkedIn
				{ id: 'Client.custom', title: 'default.otherAccountFields', customFields: 'accountCustomFields' } // Is this line still needed anywhere? seems to cause errors
			],
			'automation-contacts': [
				{
					id: 'Contact.Name',
					title: 'default.name',
					type: 'string',
					operators: [operators.Equals, operators.NotEquals]
				},
				{ standardFieldId: 32, type: 'string', operators: [operators.Equals, operators.NotEquals] }, // Title
				{ standardFieldId: 33, type: 'string', operators: [operators.Equals, operators.NotEquals] }, // Phone
				{ standardFieldId: 34, type: 'string', operators: [operators.Equals, operators.NotEquals] }, // CellPhone
				{ id: 'Contact.Active', title: 'default.active', type: 'boolean', operators: [operators.Equals] },
				{
					id: 'Contact.RelatedCategory',
					title: 'default.category',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'contactCategories',
					selectText: 'name'
				},
				{
					id: 'Contact.RelatedProject',
					title: 'default.project',
					type: 'select',
					operators: [operators.Equals, operators.NotEquals],
					selectOptions: 'campaigns',
					selectText: 'name'
				}
			]
		};

		var addCriteriasFromTrigger = function (type, currentCriterias) {
			// Add extra client terms/criterias that are available in trigger
			// and format them to fit automations
			// Currently only imports a limited set of criteria-/term-types,
			// but can be extended to handle more types.
			if (type !== 'automation-clients') {
				// only importing for client-fields right now
				return currentCriterias;
			}

			var triggerHelperCriterias = TriggerHelper.getEntityCriterias('client');

			// Avoid duplicates and only import types we can handle
			var ids = new Set(currentCriterias.map(criteria => criteria.id));
			var handledTypes = ['string', 'country', 'user', 'role'];
			var newCriteriasFromTriggerHelper = triggerHelperCriterias.filter(newCriteria => {
				if (!ids.has(newCriteria.id) && handledTypes.includes(newCriteria.type)) {
					return true;
				}
				return false;
			});

			// Format to fit automations.
			var formattedCriteriasFromTriggerHelper = newCriteriasFromTriggerHelper.map(baseCriteria => {
				// Basic values
				var formattedCriteria = {
					id: baseCriteria.id,
					title: baseCriteria.title,
					type: baseCriteria.type,
					operators: baseCriteria.operators
				};

				// Type specific values
				if (baseCriteria.type === 'string') {
					// No change
				} else if (baseCriteria.type === 'country') {
					formattedCriteria = {
						...formattedCriteria,
						type: 'select',
						selectText: baseCriteria.selectText,
						selectOptions: 'countries'
					};
				} else if (baseCriteria.type === 'user') {
					formattedCriteria = {
						...formattedCriteria,
						type: 'select',
						selectText: baseCriteria.selectText,
						selectOptions: 'activeUsers'
					};
				} else if (baseCriteria.type === 'role') {
					formattedCriteria = {
						...formattedCriteria,
						type: 'select',
						selectText: baseCriteria.selectText,
						selectOptions: 'roles'
					};
				}

				return formattedCriteria;
			});

			// return updated list
			var updatedCriterias = [...currentCriterias, ...formattedCriteriasFromTriggerHelper];
			return updatedCriterias;
		};

		// eslint-disable-next-line promise/catch-or-return
		Tools.FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.JOURNEY_STATUS).then(function () {
			terms['automation-clients'].push({
				id: 'Client.JourneyStep',
				title: 'default.journeyStep',
				type: 'select',
				operators: [operators.Equals, operators.NotEquals],
				selectOptions: 'journeySteps',
				selectText: 'lang',
				idAttribute: 'value'
			});
		});

		var mapStandardFields = function (type, entity) {
			var clientFields = AppService.getMetadata().standardFields[entity];

			return _.filter(terms[type], function (term) {
				if (!term.standardFieldId) {
					return true;
				}

				var field = _.find(clientFields, { id: term.standardFieldId });
				if (field && field.active) {
					term.title = field.nameTag;
					term.id = entity + '.' + field.name;
					term.selectOptions = field.selectOptions;

					return true;
				}
				return false;
			});
		};

		var instance = {};

		instance.all = function (type, entity) {
			var termsWithStandardFields = mapStandardFields(type, entity);
			var termsWithFieldsFromTrigger = addCriteriasFromTrigger(type, termsWithStandardFields);
			return termsWithFieldsFromTrigger;
		};

		instance.operators = operators;

		return instance;
	}
]);

'use strict';

ReactTemplates.mailCampaign.visits = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			noContact: t('default.noContact'),
			noVisits: t('default.noVisits'),
			infoVisits: t('mailCampaign.mailToVisit'),
			time: t('default.time'),
			pageTitle: t('visitor.pageTitle'),
			pageUrl: t('visitor.pageUrl'),
			score: t('default.score'),
			pageVisits: t('visitor.pageVisits'),
			leadScore: t('default.leadScore'),
			accountManager: t('default.accountManager'),
			numOfEmployees: t('default.numOfEmployees'),
			companyBranch: t('default.companyBranch'),
			noData: t('default.noData')
		};

		this.columns = [
			{ title: t('default.date'), width: 120, sortable: 'startDate' },
			{ title: t('default.account'), sortable: 'client.name' },
			{ title: t('default.history'), width: 100 },
			{ title: t('address.city'), sortable: 'client.mailAddress.city' }
		];
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	renderRow: function (visit, i, openDrilldown, isDrilled) {
		var contactClient;
		var client;
		var clientStatus;
		var location;

		if (visit.client) {
			client = (
				<span className="account-subtitle">
					<a href={this.props.rootData.getStateHref('account.dashboard', { id: visit.client.id })}>
						{visit.client.name}
					</a>
				</span>
			);

			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={visit.client.hasActivity}
					activityOld={visit.client.hadActivity}
					appointment={visit.client.hasAppointment}
					appointmentOld={visit.client.hadAppointment}
					opportunity={visit.client.hasOpportunity}
					opportunityOld={visit.client.hadOpportunity}
					order={visit.client.hasOrder}
					orderOld={visit.client.hadOrder}
				/>
			);

			var address = visit.client.mailAddress;

			if (address.city || address.country) {
				var txt = '';
				var style = { width: '30px', textAlign: 'center' };
				var icon = <b className="icon fa fa-map-marker" style={style}></b>;
				if (address.city && address.city.length) {
					txt = address.city;
				}

				if (address.country) {
					icon = (
						<b className={'icon flag-icon flag-icon-' + address.country.toLowerCase()} style={style}></b>
					);
				}
				location = (
					<span>
						{icon}
						{txt}
					</span>
				);
			}
		} else {
			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={false}
					activityOld={false}
					appointment={false}
					appointmentOld={false}
					opportunity={false}
					opportunityOld={false}
					order={false}
					orderOld={false}
				/>
			);
		}

		contactClient = (
			<td key="contactClient" className="account-contact-col">
				{visit.contact ? (
					<a href={this.props.rootData.getStateHref('contact.dashboard', { id: visit.contact.id })}>
						{visit.contact.name}
					</a>
				) : (
					<i className="grey">{this.lang.noContact}</i>
				)}
				{client}
			</td>
		);

		return (
			<tr key={'visit-' + i} onClick={openDrilldown} className={isDrilled ? 'drilldowned-row' : null}>
				<ReactTemplates.TOOLS.checkboxTD tools={this.props.tools} item={visit} />

				<td key="date">
					{ReactTemplates.TOOLS.dateCalendar(visit.startDate, 'L')} {moment(visit.startDate).format('LT')}
				</td>

				{contactClient}

				<td key="clientStatus">{clientStatus}</td>

				<td key="location">{location}</td>
			</tr>
		);
	},

	onDrilldown: function (visit, openDrilldown) {
		// Open drilldown
		openDrilldown();

		// Get drilldown data
		this.props.rootData.pageData.onDrilldown(visit);
	},

	toTime: function (seconds) {
		var duration = moment().startOf('day').add('s', seconds);
		var format = '';

		if (!duration.hour() && !duration.minute() && !duration.second()) {
			return '-';
		}
		if (duration.hour() > 0) {
			format += 'H[h] ';
		}
		if (duration.minute() > 0) {
			format += 'm[m] ';
		}

		format += 's[s]';

		return duration.format(format);
	},

	getSniString: function (client) {
		var self = this.props.tools.AppService.getSelf();
		var cfs = this.props.tools.AppService.getCustomFields('account');
		var cf = _.find(cfs, { alias: 'PRIMARY_LINE_OF_BUSINESS' });

		if (
			client &&
			client.externalClientData &&
			((client.externalClientData.sniCode && self.language == 'sv-SE') ||
				client.externalClientData.sicDescription)
		) {
			if (client.externalClientData.sniCode && self.language == 'sv-SE') {
				var code = (client.externalClientData.sniCode + '00000').substr(0, 5);
				return sniCodes[code];
			} else {
				return client.externalClientData.sicDescription;
			}
		} else if (
			client.soliditet &&
			client.soliditet.profileData &&
			client.soliditet.profileData.primaryLineOfBusinessText
		) {
			return client.soliditet.profileData.primaryLineOfBusinessText;
		} else if (cf !== undefined) {
			var sniCodeField = _.find(client.custom, { id: cf.id });

			if (sniCodeField) {
				return sniCodeField.value;
			}
		}

		return '';
	},

	renderDrilldown: function (visit, i) {
		var self = this;
		var loader = React.createElement(ReactTemplates.loader, {});
		var loading = this.props.rootData.pageData.drilldownLoading;
		var table = null;

		if (!loading) {
			var visit = this.props.rootData.pageData.drilldownData.visit;
			var client =
				this.props.rootData.pageData.drilldownData.client &&
				this.props.rootData.pageData.drilldownData.client.length
					? this.props.rootData.pageData.drilldownData.client[0]
					: null;

			var rows = _.map(visit.pages, function (page, i) {
				return (
					<tr key={'dd-data-' + i}>
						<td style={{ paddingLeft: '10px' }} className="col1">
							{self.toTime(page.durationSeconds)}
						</td>
						<td className="col2">{page.page}</td>
						<td className="col3">
							<a href="http://{{page.url}}" target="_blank">
								{page.url}
							</a>
						</td>
						<td className="col4">{page.score}</td>
					</tr>
				);
			});

			table = (
				<div>
					{visit.pages.length ? (
						<div>
							<span style={{ fontSize: '14px' }}>{self.lang.pageVisits}</span>{' '}
							<span className="grey">({visit.pages.length})</span>
							<table style={{ tableLayout: 'auto', width: '100%', maxWidth: '1300px' }}>
								<thead>
									<th style={{ paddingLeft: '10px' }} className="col1">
										{self.lang.time}
										<ReactTemplates.elevio articleId="286" />
									</th>
									<th className="col2">{self.lang.pageTitle}</th>
									<th className="col3">{self.lang.pageUrl}</th>
									<th className="col4">{self.lang.score}</th>
								</thead>
								<tbody>{rows}</tbody>
							</table>
						</div>
					) : null}

					{client ? (
						<div className="visit-drilldown-client">
							<span>{self.lang.leadScore}</span> <b className="score-indicator fa fa-tachometer"></b>{' '}
							<span>{client.score}</span>
							{client.users && client.users.length ? (
								<div>
									<span>{self.lang.accountManager}</span>{' '}
									<span>{_.pluck(client.users, 'name').join(', ')}</span>
								</div>
							) : null}
							{client.externalClientData && client.externalClientData.employees ? (
								<div>
									<span>{self.lang.numOfEmployees}</span>
									<span>{client.externalClientData.employees}</span>
								</div>
							) : null}
							<div>
								<span>{self.lang.companyBranch}</span> <span>{self.getSniString(client) || '-'}</span>
							</div>
						</div>
					) : null}

					{!client && !visit.pages.length ? (
						<h3 className="text-grey" style={{ textAlign: 'center' }}>
							{this.lang.noData}
						</h3>
					) : null}
				</div>
			);
		}

		return (
			<tr className="mailcampaign-visit-drilldown" key={'drilldown-' + i}>
				<td colSpan={5} className="drilldown-td">
					{loading ? loader : table}
				</td>
			</tr>
		);
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;

		var search = React.createElement(ReactTemplates.mailCampaign.tableSearch, {
			className: 'pull-right',
			onChange: rootData.pageData.onSearch,
			tools: tools
		});

		var table = React.createElement(ReactTemplates.mailCampaign.table, {
			renderRow: self.renderRow,
			columns: this.columns,
			rows: pageData.visits,
			loading: pageData.loading,
			total: pageData.total,
			multiActions: true,
			totalPages: pageData.totalPages,
			activePage: pageData.activePage,
			onChangePage: pageData.onChangePage,
			currentSorting: pageData.currentSorting,
			onSort: pageData.onSort,
			noResult: self.lang.noVisits,
			tools: tools,
			onDrilldown: self.onDrilldown,
			renderDrilldown: self.renderDrilldown
		});

		var elevioStyle = {
			verticalAlign: 'middle',
			lineHeight: '30px',
			color: '#999'
		};

		return (
			<div>
				<div id="page-tools">
					{search}
					<span style={elevioStyle}>
						{React.createElement(ReactTemplates.elevio, {
							articleId: 284,
							text: self.lang.infoVisits,
							sidebar: true
						})}
					</span>
					<div className="clearfix"></div>
				</div>

				{table}
			</div>
		);
	}
});

'use strict';

angular.module('upResources').factory('Project', [
	'$resource',
	'ProjectAttributes',
	'$q',
	'URL',
	'API',
	'AppService',
	function Project($resource, ProjectAttributes, $q, URL, API, AppService) {
		var Attribute = ProjectAttributes().getAll();

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Project = null;
				customer = customer;
				Project = $resource(
					URL + API + customer + '/projects/:id',
					{},
					{ query: { method: 'GET', isArray: false } }
				);
				instance.find = function (filter, options) {
					var defered = $q.defer();
					var params = angular.extend(filter, options);

					Project.get(params, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.get = function (id) {
					var defered = $q.defer();

					Project.get(
						{ id: id },
						function (response) {
							defered.resolve(response.data);
						},
						function () {
							defered.reject(response.status);
						}
					);

					return defered.promise;
				};

				instance.save = function () {};

				instance['delete'] = function () {};
				instance.attr = Attribute;
				return instance;
			},
			attr: Attribute
		};

		return Model;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';

const CodeEditorClass = new Bem('CodeEditor');

class CodeEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			code: props.value
		};
	}

	setData(key, value) {
		const { state, props } = this;
		const { onChange } = props;

		state[key] = value;
		if (onChange) {
			onChange(state);
		}

		this.setState(state);
	}

	updateEditorFromProps(props) {
		if (!window.ace) {
			return;
		}

		const { readOnly, value, showGutter, theme, mode, useWorker, maxLines } = props;
		const editor = window.ace.edit(this._code);
		editor.setTheme(`ace/theme/${theme}`);
		editor.setShowPrintMargin(false);
		editor.$blockScrolling = Infinity;

		editor.setOptions({
			minLines: 4,
			maxLines: maxLines,
			highlightActiveLine: false,
			wrapBehavioursEnabled: true,
			useWorker: useWorker,
			showGutter: showGutter,
			readOnly: readOnly
		});

		var editorSession = editor.getSession();
		editorSession.setUseWrapMode(true);
		editorSession.setMode(`ace/mode/${mode}`);
		editorSession.on('change', () => {
			this.setData('code', editor.getValue());
		});
		if (value !== this.state.code) {
			editor.setValue(value, -1);
		}
		editor.resize();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const oldProps = _.pick(
			this.props,
			'readOnly',
			'value',
			'showGutter',
			'theme',
			'mode',
			'useWorker',
			'maxLines'
		);
		const newProps = _.pick(nextProps, 'readOnly', 'value', 'showGutter', 'theme', 'mode', 'useWorker', 'maxLines');
		if (!_.isEqual(oldProps, newProps)) {
			this.updateEditorFromProps(nextProps);
		}
	}

	componentDidMount() {
		this.updateEditorFromProps(this.props);
	}

	componentWillUnmount() {
		if (!window.ace) {
			return;
		}
		window.ace.edit(this._code).destroy();
	}

	render() {
		return <div className={CodeEditorClass.b()} ref={editor => (this._code = editor)} id="editor-container" />;
	}
}

CodeEditor.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	showGutter: PropTypes.bool,
	fixToHeight: PropTypes.bool,
	mode: PropTypes.string,
	useWorker: PropTypes.bool,
	maxLines: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

CodeEditor.defaultProps = {
	readOnly: false,
	showGutter: false,
	theme: 'cobalt',
	fixToHeight: false,
	mode: 'javascript',
	useWorker: false,
	maxLines: 5
};

export default CodeEditor;

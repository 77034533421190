import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './AppOnboarding.scss';
import { Headline, Block, Text, Button, Icon, Column, Row } from '@upsales/components';
import T from 'Components/Helpers/translate';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import Prospecting from 'Resources/Prospecting';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import Select from 'App/components/Select';
import UpsalesAI from 'Resources/UpsalesAI';
import AppOnboardingSimilarClients from './AppOnboardingSimilarClients';
import ValidationService from 'Services/ValidationService';
import { isKeywordsSupportedForCountry } from 'App/services/prospectingService';

type AppOnbaordingFindLeadsProps = {
	onBack: () => void;
	onConfirm: (prospectingIds: string[]) => void;
};

const getKeywordsForCountry = (country: 'SE' | 'GB' | 'NO') => {
	if (!isKeywordsSupportedForCountry(country)) {
		return [];
	}

	switch (country) {
		case 'GB':
			return [
				'SaaS',
				'Education',
				'Transportation',
				'Recruitment',
				'Bookkeeping',
				'Construction',
				'E-commerce',
				'IT Consulting',
				'B2B'
			];
		case 'NO':
			return [
				'SaaS',
				'Opplæring',
				'Transportere',
				'Rekruttering',
				'Regnskap',
				'Bygge',
				'E-handel',
				'IT konsulent',
				'B2B'
			];
		case 'SE':
		default:
			return [
				'SaaS',
				'Utbildning',
				'Transport',
				'Rekrytering',
				'Bokföring',
				'Bygg',
				'E-handel',
				'IT-konsult',
				'B2B'
			];
	}
};

const findInProspectingClient = async (searchTerm: string): Promise<ProspectingClient[]> => {
	const country = Tools.AppService.getMetadata().params.CustomerRegion;
	const isValidOrgNumber = ValidationService.validateOrgNumber(searchTerm, country);

	const rb = new RequestBuilder();
	rb.addFilter({ field: 'headquarters' }, ComparisonTypes.Equals, true);
	const or = rb.orBuilder();
	or.addFilter({ field: 'name' }, ComparisonTypes.Search, searchTerm);
	if (isValidOrgNumber) {
		or.next();
		or.addFilter({ field: 'orgNumber' }, ComparisonTypes.Equals, searchTerm);
	}
	or.done();
	rb.extraParams.push({ key: 'country', value: country });

	const { data } = await Prospecting.find(rb.build());

	return data;
};

const AppOnboardingFindLeads = ({ onBack, onConfirm }: AppOnbaordingFindLeadsProps) => {
	const [showSimilarFor, setShowSimilarFor] = useState<null | { name: string; orgNumber?: string; count?: number }>(
		null
	);

	const country = Tools.AppService.getMetadata().params.CustomerRegion;
	const keywords = getKeywordsForCountry(country);

	const classes = new BemClass('AppOnboardingTopClients');
	return (
		<div className={classes.b()}>
			{!showSimilarFor ? (
				<div>
					<Block space="mbxl">
						<Button
							color="white"
							onClick={() => {
								onBack();
							}}
						>
							<Icon name="chevron-left" space="mrs" />
							{T('onboarding.erp.back')}
						</Button>
					</Block>
					<Block space="mbxl">
						<Headline>{T('onboarding.erp.letsFindLeads')}</Headline>
					</Block>
					<Block space="mbxl">
						<Text>{T('onboarding.erp.searchCompaniesAndGetSimilar')}</Text>
					</Block>
					<Block space="mbxl ptxl pbxl">
						<Row>
							<Column size={6}>
								<Text bold>{T('onboarding.erp.searchCompanies')}</Text>
								<Select<ProspectingClient>
									onChange={async client => {
										if (client) {
											const similar = await UpsalesAI.find({
												orgNumbers: [client.orgNumber],
												country: Tools.AppService.getMetadata().params.CustomerRegion
											});
											setShowSimilarFor({
												orgNumber: client.orgNumber,
												name: client.name,
												count: similar.data.length
											});
										} else {
											setShowSimilarFor(null);
										}
									}}
									fetchData={term => findInProspectingClient(term)}
									idKey="prospectingId"
									placeholder={T('onboarding.erp.searchCompaniesOrOrgNo')}
									icon="search"
									autofocus
									noDataText={T('onboarding.erp.noCompaniesFound')}
								/>
							</Column>
							<Column size={6} />
						</Row>
					</Block>
					{keywords.length > 0 ? (
						<>
							<Block space="mbl">
								<Text>{T('onboarding.erp.orFindCompaniesByKeywords')}</Text>
							</Block>
							<Block>
								{keywords.map(keyword => (
									<Button
										style={{ marginRight: '5px' }}
										shadow="none"
										color="super-light-green"
										key={keyword}
										onClick={async () => {
											setShowSimilarFor({
												name: keyword
											});
										}}
									>
										{keyword}
									</Button>
								))}
							</Block>
						</>
					) : null}
				</div>
			) : (
				<div>
					<AppOnboardingSimilarClients
						orgNo={showSimilarFor.orgNumber}
						name={showSimilarFor.name}
						onBack={() => setShowSimilarFor(null)}
						onConfirm={onConfirm}
					/>
				</div>
			)}
		</div>
	);
};

export default AppOnboardingFindLeads;

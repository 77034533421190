import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { FilterPreset, generateFilter } from '../filterGenerators';

export default (): { [key: string]: FilterConfig } => ({
	Name: generateFilter(FilterPreset.Text, {
		title: 'filters.title.mailTemplateName',
		field: 'name'
	}),
	User: generateFilter(FilterPreset.User, {
		field: 'user.id',
		title: 'default.users'
	}),
	Private: generateFilter(FilterPreset.Radio, {
		title: 'mail.privateTemplate',
		field: 'private',
		type: 'boolean',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.yes', value: true },
			{ text: 'default.no', value: false }
		]
	}),
	Label: generateFilter(FilterPreset.RawValue, {
		build: function (filter, rb) {
			if (filter.value === 'unlabeled') {
				rb.addFilter({ field: 'labels.id' }, comparisonTypes.Equals, null);
			} else if (typeof filter.value === 'number') {
				rb.addFilter({ field: 'labels.id' }, comparisonTypes.Equals, [filter.value]);
			}
		}
	}),

	// Quick search
	ListSearch: generateFilter(
		FilterPreset.ListSearch,
		{
			title: 'filters.title.mailTemplateName'
		},
		['user.name', 'name', 'subject']
	)
});

import React from 'react';
import PropTypes from 'prop-types';
import './LookerWrapper.scss';

class LookerWrapper extends React.Component {
	static propTypes = {
		closeDropdown: PropTypes.func.isRequired,
		signedUrl: PropTypes.string.isRequired
	};

	componentDidMount() {
		$('.looker-wrapper').iframeTracker({
			blurCallback: () => {
				this.props.closeDropdown();
			}
		});
	}

	render() {
		return <iframe className="LookerWrapper" src={this.props.signedUrl} />;
	}
}

export default LookerWrapper;

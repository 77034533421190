import PropTypes from 'prop-types';
import React from 'react';
import { Input } from '@upsales/components';
import CriteriaDrilldownWrapper from '../CriteriaDrilldownWrapper';
import ListInput from './ListInput';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';

const mapIdsToObject = (sourceObjects, ids) => {
	var objects = _.map(ids, function (objectId) {
		return _.find(sourceObjects, { id: objectId });
	});

	return _.compact(objects);
};

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export default class AppointmentDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const { $translate, AppService } = Tools;

		this.lang = {
			date: $translate('default.date') + ':',
			type: $translate('default.type') + ':',
			outcome: $translate('default.appointmentOutcome') + ':',
			easyBooking: $translate('default.easyBooking') + ':',
			orderValue: $translate('field.order.value') + ':',
			from: $translate('default.from'),
			to: $translate('default.to'),
			all: $translate('default.all'),
			closed: $translate('default.closed'),
			open: $translate('default.open'),
			numOfDays: $translate('default.numOfDays'),
			users: $translate('default.users') + ':',
			selectActivityType: $translate('default.select') + ' ' + $translate('default.activityType').toLowerCase(),
			selectUsers: $translate('default.select') + ' ' + $translate('default.users').toLowerCase(),
			selectOutcome: $translate('default.select') + ' ' + $translate('default.appointmentOutcome').toLowerCase(),
			selectEasyBooking: $translate('default.select') + ' ' + $translate('default.easyBooking').toLowerCase()
		};

		this.onEasyBookingChanged = this.onChange.bind(this, 'easyBooking');
		this.onOutcomeChanged = this.onChange.bind(this, 'outcome');
		this.onTypeChange = this.onChange.bind(this, 'type');
		this.onUserChange = this.onChange.bind(this, 'user');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onDateStartChange = this.onChange.bind(this, 'dateStart');
		this.onDateEndChange = this.onChange.bind(this, 'dateEnd');

		const { config } = this.props;
		const activityTypes = AppService.getActivityTypes();
		const users = AppService.getUsers();

		this.mapActivityTypesObjects = mapIdsToObject.bind(null, activityTypes);
		this.mapUserObjects = mapIdsToObject.bind(null, users);

		this.datePresets = _.map(config.presets, function (preset) {
			return {
				id: preset,
				name: capitalizeFirstLetter($translate('date.' + preset))
			};
		});

		this.statusOptions = [
			{ key: this.lang.all, value: null },
			{ key: this.lang.open, value: 'open' },
			{ key: this.lang.closed, value: 'closed' }
		];

		this.showEasyBookingFilter = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EASY_BOOKING_PRO);
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'outcome':
				newFilter.value.Outcome.value = value;
				break;
			case 'easyBooking':
				if (!newFilter.value.SourceType) {
					newFilter.value.SourceType = { value: [] };
				}
				if (!newFilter.value.SourceId) {
					newFilter.value.SourceId = { value: [] };
				}
				newFilter.value.SourceType.value = value.length ? ['easybooking'] : [];
				newFilter.value.SourceId.value = value;
				break;
			case 'type':
				newFilter.value.ActivityType.value = _.pluck(value, 'id');
				break;
			case 'user':
				newFilter.value.User.value = value;
				break;
			case 'preset':
				var preset = value.target.value;
				newFilter.value.Date.value.preset = preset;
				newFilter.value.Date.value.start = null;
				newFilter.value.Date.value.end = null;
				break;
			case 'dateStart':
				newFilter.value.Date.value.start = value;
				break;
			case 'dateEnd':
				newFilter.value.Date.value.end = value;
				break;
			default:
				throw new Error('Should never get here');
		}

		onChange(newFilter.filterName, newFilter);
	}

	render() {
		const { filter, open, fakeConfigs, selectData } = this.props;
		const preset = filter.value.Date.value.preset;
		const presetValue = _.find(this.datePresets, { id: preset });
		const showDatepickers = preset === 'custom';
		const showDayInput = preset === 'lastXdays';

		const outcomeSelect = {
			placeholder: this.lang.selectOutcome,
			config: fakeConfigs.AppointmentOutcome,
			defaultValue: filter.value.Outcome.value,
			onChange: this.onOutcomeChanged,
			selectData: selectData
		};

		const easyBookingSelect = {
			placeholder: this.lang.selectEasyBooking,
			config: fakeConfigs.EasyBooking,
			defaultValue: filter.value.SourceId?.value ?? [],
			onChange: this.onEasyBookingChanged,
			selectData: selectData
		};

		const userSelect = {
			placeholder: this.lang.selectUsers,
			config: fakeConfigs.User,
			defaultValue: filter.value.User.value,
			onChange: this.onUserChange,
			selectData: selectData
		};

		return (
			<CriteriaDrilldownWrapper key={filter.filterName + '-drilldown'} className="activity-drilldown" open={open}>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/*Date row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.date}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className={showDatepickers ? 'col-sm-6 col-lg-6' : 'col-lg-6'}>
												<ReactTemplates.INPUTS.upSelect
													className="form-control"
													key={'datepicker-select'}
													data={this.datePresets}
													required={true}
													multiple={false}
													onChange={this.onPresetChange}
													defaultValue={presetValue}
													options={{ minimumResultsForSearch: -1 }}
												/>
											</div>
											{showDatepickers ? (
												<div className="col-sm-3">
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.from}
														name={'startDate'}
														className="form-control"
														value={filter.value.Date.value.start}
														onChange={this.onDateStartChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showDatepickers ? (
												<div className={'col-sm-3'}>
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.to}
														name={'endDate'}
														className="form-control"
														value={filter.value.Date.value.end}
														onChange={this.onDateEndChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showDayInput ? (
												<div className="col-sm-3">
													<Input
														type="number"
														placeholder={this.lang.numOfDays}
														value={filter.value.Date.value.start}
														onChange={e => this.onDateStartChange(e.target.value)}
													/>
												</div>
											) : null}
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/*Users row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.users}</div>
									</td>
									<td>
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ListInput {...userSelect} />
											</div>
										</div>
									</td>
									<td className="CriteriaRow__remove" />
								</tr>

								{/*Outcome row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.outcome}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ListInput {...outcomeSelect} />
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/*Easy booking row*/}
								{this.showEasyBookingFilter ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.easyBooking}</div>
										</td>
										<td className="table-col">
											<div className="drilldown-row">
												<div className="col-lg-6">
													<ListInput {...easyBookingSelect} />
												</div>
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/*Activitytype row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.type}</div>
									</td>
									<td>
										<div className="drilldown-row">
											<div className="col-lg-6">
												<UpActivityTypes
													onChange={this.onTypeChange}
													placeholder={this.lang.selectActivityType}
													entity={'appointment'}
													multiple={true}
													value={this.mapActivityTypesObjects(
														filter.value.ActivityType.value
													)}
												/>
											</div>
										</div>
									</td>
									<td className="CriteriaRow__remove" />
								</tr>
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

AppointmentDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	selectData: PropTypes.object.isRequired,
	fakeConfigs: PropTypes.object.isRequired
};

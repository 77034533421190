import React from 'react';
import PropTypes from 'prop-types';
import './SalesModelSettings.scss';
import {
	Block,
	Button,
	Card,
	Icon,
	Loader,
	CardContent,
	CardHeader,
	Text,
	ButtonSelect,
	Help,
	Label,
	Title,
	Flex,
	ButtonBox,
	AssistChip,
	Toggle,
	RadioItem
} from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import BillingAddonCard from '../Billing/BillingAddonCard';
import BillingAddonShape from 'App/babel/propTypes/BillingAddonShape';
import { connect } from 'react-redux';
import { retrieveData } from 'Store/reducers/BillingReducer';
import InAppChat from 'App/babel/services/InAppChat';
import T from 'Components/Helpers/translate';
import FlexItem from 'App/components/FlexItem';

const mapStateToProps = state => ({
	availableAddons: state.Billing.availableAddons,
	loadingAddons: state.Billing.loadingAddons,
	boughtAddons: (Array.isArray(state.Billing.addons) ? state.Billing.addons : []).reduce((res, a) => {
		res[a.alias] = a;
		return res;
	}, {})
});

const mapDispatchToProps = {
	retrieveData
};

const renderToggleRow = ({ title, subtitle, checked, onChange, disabled, size = 'md', Component = Toggle }) => (
	<Flex gap="u4" space="mtl" alignItems="center">
		<Flex flex={[0, 0, '50px']} justifyContent="center">
			<Component size={size} onChange={onChange} checked={checked} disabled={disabled} />
		</Flex>
		<div>
			<Label>{title}</Label>
			<Text color="grey-11">{subtitle}</Text>
		</div>
	</Flex>
);

class SalesModelSettings extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			readMore: t('default.readMore'),
			salesModelTitle: t('admin.accountSettings.salesModelTitle', {
				client: Tools.AppService.getAccountSelf().client
			}),
			salesModel: t('admin.accountSettings.salesModel'),
			actualOrderValue: t('admin.accountSettings.actualOrderValue'),
			actualOrderValueDescription: t('admin.accountSettings.actualOrderValueDescription'),
			contributionMargin: t('admin.accountSettings.contributionMargin'),
			contributionMarginCardTitle1: t('admin.accountSettings.contributionMarginCardTitle-1'),
			contributionMarginCardTitle2: t('admin.accountSettings.contributionMarginCardTitle-2'),
			contributionMarginCardDescription1: t('admin.accountSettings.contributionMarginCardDescription-1'),
			contributionMarginCardDescription2: t('admin.accountSettings.contributionMarginCardDescription-2'),
			contributionMarginCardCombinedARRTitle: t('admin.accountSettings.contributionMarginCardCombinedARRTitle'),
			contributionMarginCardCombinedARRSubitle: t(
				'admin.accountSettings.contributionMarginCardCombinedARRSubtitle'
			),
			contributionMarginCardCombinedMRRTitle: t('admin.accountSettings.contributionMarginCardCombinedMRRTitle'),
			contributionMarginCardCombinedMRRSubitle: t(
				'admin.accountSettings.contributionMarginCardCombinedMRRSubtitle'
			),
			noSubscriptionsButton: t('admin.accountSettings.noSubscriptionsButton'),
			noSubscriptionsDescription: t('admin.accountSettings.noSubscriptionsDescription'),
			noSubscriptionsHelp: t('admin.accountSettings.noSubscriptionsHelp'),
			noSubscriptionsTitle: t('admin.accountSettings.noSubscriptionsTitle'),
			recurringRevenue: t('admin.accountSettings.recurringRevenue'),
			recurringRevenueCardTitle1: t('admin.accountSettings.recurringRevenueCardTitle1'),
			recurringRevenueCardTitle2: t('admin.accountSettings.recurringRevenueCardTitle2'),
			recurringRevenueCardTitle3Part1: t('admin.accountSettings.recurringRevenueCardTitle3Part1'),
			recurringRevenueCardTitle3Part2: t('admin.accountSettings.recurringRevenueCardTitle3Part2'),
			recurringRevenueCardTitle4Part1: t('admin.accountSettings.recurringRevenueCardTitle4Part1'),
			recurringRevenueCardTitle4Part2: t('admin.accountSettings.recurringRevenueCardTitle4Part2'),
			recurringRevenueCardCombinedTitle: t('admin.accountSettings.recurringRevenueCardCombinedTitle'),
			recurringRevenueCardCombinedSubtitle: t('admin.accountSettings.recurringRevenueCardCombinedSubtitle'),
			recurringRevenueCardSubtitle1: t('admin.accountSettings.recurringRevenueCardSubtitle-1').toUpperCase(),
			recurringRevenueCardSubtitle2: t('admin.accountSettings.recurringRevenueCardSubtitle-2').toUpperCase(),
			recurringRevenueCardDescription1: t('admin.accountSettings.recurringRevenueCardDescription-1'),
			recurringRevenueCardDescription2: t('admin.accountSettings.recurringRevenueCardDescription-2'),
			recurringRevenueCardDescriptionNewCustomers: t(
				'admin.accountSettings.recurringRevenueCardDescription.newCustomers'
			),
			recurringRevenueCardDescriptionUpsell: t('admin.accountSettings.recurringRevenueCardDescription.upsell'),
			recurringRevenueCardDescriptionContractions: t(
				'admin.accountSettings.recurringRevenueCardDescription.contractions'
			),
			recurringRevenueCardDescriptionChurn: t('admin.accountSettings.recurringRevenueCardDescription.churn'),
			recurringRevenueSectionSubtitle: t('admin.accountSettings.recurringRevenueSectionSubtitle'),
			subscriptionsActive: t('admin.accountSettings.recurringRevenueSubscriptionsActive'),
			salesModelActive: t('admin.accountSettings.salesModelActive'),
			aboutSalesModels: t('admin.accountSettings.aboutSalesModels'),
			activateSalesModel: t('admin.accountSettings.activateSalesModel'),
			saveChanges: t('default.saveChanges')
		};

		this.classNames = new Bem('SalesModelSettings');
	}

	componentDidMount() {
		this.props.retrieveData();
	}

	openChat = () => {
		InAppChat.open();
	};

	render() {
		const classNames = this.classNames;
		const agreementsIsAvailable =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
			Tools.AppService.getAccessRights().Agreement;
		const hasAgreementsEnabled = agreementsIsAvailable && Tools.AppService.getMetadata().params.AgreementEnabled;
		const hasNewAddons = Tools.FeatureHelper.hasSoftDeployAccess('BILLING_ADDONS');
		const hasCombinedRRCM = Tools.FeatureHelper.hasSoftDeployAccess('COMBINE_RR_AND_CM');
		const hasCombinedCMRR =
			Tools.FeatureHelper.hasSoftDeployAccess('COMBINE_CM_AND_RR') &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
		const showDisabledView = this.props.selectedSalesModel === 'rr' && !agreementsIsAvailable;
		const showActivateSalesModelBtn = this.props.activeSalesModel !== this.props.selectedSalesModel;

		const showSaveChangesBtn =
			(this.props.selectedSalesModelOption !== this.props.activeSalesModelOption ||
				this.props.selectedSalesModelOption2 !== this.props.activeSalesModelOption2 ||
				this.props.selectedSalesModelOption3 !== this.props.activeSalesModelOption3 ||
				this.props.selectedGroupARRChangesByUser !== this.props.activeGroupARRChangesByUser) &&
			!showActivateSalesModelBtn;

		const subscriptionAddon = this.props.availableAddons.find(addon => {
			return addon.alias === 'SUBSCRIPTIONS';
		});

		const getRRSelects = (useThirdOption = false) => {
			const getModel = () => {
				if (!useThirdOption) {
					return this.props.selectedSalesModelOption.toUpperCase();
				}

				if (this.props.selectedSalesModelOption3 === 'cmCombinedWithARR') {
					return 'ARR';
				} else if (this.props.selectedSalesModelOption3 === 'cmCombinedWithMRR') {
					return 'MRR';
				}
			};

			if (
				useThirdOption &&
				this.props.selectedSalesModelOption3 !== 'cmCombinedWithARR' &&
				this.props.selectedSalesModelOption3 !== 'cmCombinedWithMRR'
			) {
				return null;
			}

			return (
				<>
					<Block space="mtxl mbl">
						<CardHeader
							className={
								showDisabledView
									? classNames.elem('section-header').mod('disabled').b()
									: classNames.elem('section-header').b()
							}
							title={Tools.$translate('admin.accountSettings.recurringRevenueSectionTitle', {
								model: getModel()
							})}
							subtitle={this.lang.recurringRevenueSectionSubtitle}
						/>
					</Block>

					{renderToggleRow({
						title:
							this.lang.recurringRevenueCardTitle3Part1 +
							getModel() +
							this.lang.recurringRevenueCardTitle3Part2,
						subtitle: `${this.lang.recurringRevenueCardDescriptionNewCustomers} + ${
							this.lang.recurringRevenueCardDescriptionUpsell
						} - ${this.lang.recurringRevenueCardDescriptionContractions} - ${
							this.lang.recurringRevenueCardDescriptionChurn
						} = ${getModel()}`,
						checked: this.props.selectedSalesModelOption2 === 'net',
						onChange: showDisabledView
							? undefined
							: () => this.props.actions.selectSalesModelOption2('net'),
						disabled: showDisabledView,
						Component: RadioItem
					})}

					{renderToggleRow({
						title:
							this.lang.recurringRevenueCardTitle4Part1 +
							getModel() +
							this.lang.recurringRevenueCardTitle4Part2,
						subtitle: `${this.lang.recurringRevenueCardDescriptionNewCustomers} + ${
							this.lang.recurringRevenueCardDescriptionUpsell
						} = ${getModel()}`,
						checked: this.props.selectedSalesModelOption2 === 'gross',
						onChange: showDisabledView
							? undefined
							: () => this.props.actions.selectSalesModelOption2('gross'),
						disabled: showDisabledView,
						Component: RadioItem
					})}

					<Block space="mtxl mbl">
						<CardHeader
							className={
								showDisabledView
									? classNames.elem('section-header').mod('disabled').b()
									: classNames.elem('section-header').b()
							}
							title={Tools.$translate('admin.accountSettings.recurringRevenue.groupBy.title', {
								model: getModel()
							})}
							subtitle={Tools.$translate('admin.accountSettings.recurringRevenue.groupBy.subTitle', {
								model: getModel()
							})}
						/>
					</Block>
					<Flex gap="u2">
						<FlexItem
							flex={1}
							component={ButtonBox}
							disabled={showDisabledView}
							selected={!this.props.selectedGroupARRChangesByUser}
							onClick={() => this.props.actions.setGroupARRChangesByUser(false)}
							title={T('admin.accountSettings.recurringRevenue.groupBy.accountManager', {
								model: getModel()
							})}
						/>
						<FlexItem
							flex={1}
							component={ButtonBox}
							disabled={showDisabledView}
							selected={this.props.selectedGroupARRChangesByUser}
							onClick={() => this.props.actions.setGroupARRChangesByUser(true)}
							title={T('admin.accountSettings.recurringRevenue.groupBy.user', {
								model: getModel()
							})}
						/>
					</Flex>
				</>
			);
		};

		return (
			<Card space="mbxl" className={classNames.b()}>
				<CardHeader className={classNames.elem('header').b()} title={this.lang.salesModelTitle}>
					{hasAgreementsEnabled && this.props.selectedSalesModel === 'rr' ? (
						<AssistChip type="success" title={this.lang.subscriptionsActive} icon="check" />
					) : null}
				</CardHeader>
				{this.props.loadingAddons ? (
					<div style={{ marginTop: '150px' }}>
						<Loader size="lg" style={{ margin: 'auto' }} />
					</div>
				) : null}
				{!this.props.loadingAddons ? (
					<CardContent>
						<Flex justifyContent="space-between" space="mtl mbs">
							<Label>{this.lang.salesModel}</Label>
							<Help articleId={1173} sidebar>
								<Text color="green">
									<Icon name="question-circle" space="mrs" />
									{this.lang.aboutSalesModels}
								</Text>
							</Help>
						</Flex>

						<ButtonSelect
							block
							value={this.props.selectedSalesModel}
							options={[
								{ value: 'sales', title: this.lang.actualOrderValue },
								{ value: 'cm', title: this.lang.contributionMargin },
								{ value: 'rr', title: this.lang.recurringRevenue }
							]}
							onChange={this.props.actions.selectSalesModel}
						></ButtonSelect>
						{showDisabledView ? (
							subscriptionAddon && hasNewAddons ? (
								<Block space="mtxl" className={classNames.elem('billing-card-wrapper').b()}>
									<BillingAddonCard
										key={subscriptionAddon.alias}
										addon={subscriptionAddon}
										bought={!!this.props.boughtAddons[subscriptionAddon.alias]}
										viewPlan={() => {
											Tools.$state.go('administration.billing');
										}}
										location="salesModelSettings"
									/>
								</Block>
							) : (
								<Block space="mtxl" className={classNames.elem('addon-info-wrapper').b()}>
									<Title color="black" size="md">
										{this.lang.noSubscriptionsTitle}
									</Title>
									<Text color="grey-12">{this.lang.noSubscriptionsDescription}</Text>
									<Help articleId={1151} sidebar>
										<Text color="green">
											<Icon name="question-circle" space="mrm" />
											{this.lang.noSubscriptionsHelp}
										</Text>
									</Help>
									{InAppChat.isEnabledAndLoaded() ? (
										<div>
											<Button size="lg" onClick={this.openChat}>
												<Icon space="mrs" name="comments" />
												{this.lang.noSubscriptionsButton}
											</Button>
										</div>
									) : null}
								</Block>
							)
						) : null}

						{this.props.selectedSalesModel === 'sales' ? (
							<Block space="mtxl">
								<Text color="grey-10">{this.lang.actualOrderValueDescription}</Text>
							</Block>
						) : this.props.selectedSalesModel === 'cm' ? (
							<>
								<Flex gap="u2" space="mtxl">
									<FlexItem
										flex={1}
										component={ButtonBox}
										selected={this.props.selectedSalesModelOption === 'cm'}
										title={this.lang.contributionMarginCardTitle1}
										subtitle={this.lang.contributionMarginCardDescription1}
										onClick={() => this.props.actions.selectSalesModelOption('cm')}
									/>
									<FlexItem
										flex={1}
										component={ButtonBox}
										selected={this.props.selectedSalesModelOption === 'sales'}
										title={this.lang.contributionMarginCardTitle2}
										subtitle={this.lang.contributionMarginCardDescription2}
										onClick={() => this.props.actions.selectSalesModelOption('sales')}
									/>
								</Flex>

								{hasCombinedCMRR ? (
									<>
										{renderToggleRow({
											title: this.lang.contributionMarginCardCombinedARRTitle,
											subtitle: this.lang.contributionMarginCardCombinedARRSubitle,
											checked: this.props.selectedSalesModelOption3 === 'cmCombinedWithARR',
											size: 'lg',
											onChange: value =>
												this.props.actions.selectSalesModelOption3(
													value ? 'cmCombinedWithARR' : 'onlyCM'
												)
										})}
										{renderToggleRow({
											title: this.lang.contributionMarginCardCombinedMRRTitle,
											subtitle: this.lang.contributionMarginCardCombinedMRRSubitle,
											checked: this.props.selectedSalesModelOption3 === 'cmCombinedWithMRR',
											size: 'lg',
											onChange: value =>
												this.props.actions.selectSalesModelOption3(
													value ? 'cmCombinedWithMRR' : 'onlyCM'
												)
										})}
									</>
								) : null}
							</>
						) : (
							<>
								<Flex gap="u2" space="mtxl">
									<FlexItem
										flex={1}
										component={ButtonBox}
										selected={this.props.selectedSalesModelOption === 'arr'}
										onClick={() => this.props.actions.selectSalesModelOption('arr')}
										className={classNames.elem('sales-model-box').b()}
									>
										<Title size="lg" bold>
											{this.lang.recurringRevenueCardTitle1}
										</Title>
										<Text
											size="sm"
											color="grey-11"
											className={classNames.elem('sales-model-box-subtitle').b()}
										>
											{this.lang.recurringRevenueCardSubtitle1}
										</Text>
										<Text>{this.lang.recurringRevenueCardDescription1}</Text>
									</FlexItem>
									<FlexItem
										flex={1}
										component={ButtonBox}
										selected={this.props.selectedSalesModelOption === 'mrr'}
										onClick={() => this.props.actions.selectSalesModelOption('mrr')}
										className={classNames.elem('sales-model-box').b()}
									>
										<Title size="lg" bold>
											{this.lang.recurringRevenueCardTitle2}
										</Title>
										<Text
											size="sm"
											color="grey-11"
											className={classNames.elem('sales-model-box-subtitle').b()}
										>
											{this.lang.recurringRevenueCardSubtitle2}
										</Text>
										<Text>{this.lang.recurringRevenueCardDescription2}</Text>
									</FlexItem>
								</Flex>
								{hasCombinedRRCM
									? renderToggleRow({
											title: this.lang.recurringRevenueCardCombinedTitle,
											subtitle: this.lang.recurringRevenueCardCombinedSubtitle,
											checked: this.props.selectedSalesModelOption3 === 'combinedWithCM',
											size: 'lg',
											onChange: value =>
												this.props.actions.selectSalesModelOption3(
													value ? 'combinedWithCM' : 'onlyRR'
												)
									  })
									: null}
								<Block space="mtxl">{getRRSelects()}</Block>
							</>
						)}
						<Block space="mtxl">
							{!showDisabledView && (showSaveChangesBtn || showActivateSalesModelBtn) ? (
								<Button
									id="salesModelSettingsSaveButton"
									block={true}
									onClick={this.props.actions.saveSalesModelSettings}
								>
									{showSaveChangesBtn ? this.lang.saveChanges : this.lang.activateSalesModel}
								</Button>
							) : null}
						</Block>
					</CardContent>
				) : null}
			</Card>
		);
	}
}

SalesModelSettings.propTypes = {
	loadingAddons: PropTypes.bool,
	actions: PropTypes.object,
	activeSalesModel: PropTypes.string,
	activeSalesModelOption: PropTypes.string,
	availableAddons: PropTypes.arrayOf(BillingAddonShape),
	activeSalesModelOption2: PropTypes.string,
	activeSalesModelOption3: PropTypes.string,
	selectedSalesModel: PropTypes.string,
	selectedSalesModelOption: PropTypes.string,
	selectedSalesModelOption2: PropTypes.string,
	selectedSalesModelOption3: PropTypes.string,
	selectedGroupARRChangesByUser: PropTypes.bool,
	activeGroupARRChangesByUser: PropTypes.bool,
	retrieveData: PropTypes.func,
	boughtAddons: PropTypes.object
};

export const detached = SalesModelSettings;
export default connect(mapStateToProps, mapDispatchToProps)(SalesModelSettings);

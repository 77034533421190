'use strict';

angular.module('upFilters').filter('fromNow', function () {
	return function (date, postfix, prefix) {
		var format = 'YYYY-MM-DD';

		prefix = prefix || '(';
		postfix = postfix || ')';
		var d = moment(date).format(format);
		var fromNow = moment(d, format).calendar();

		if (fromNow === '0') {
			fromNow = moment(d, format).fromNow();
		}
		return prefix + fromNow + postfix;
	};
});

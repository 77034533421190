import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import UpSelect from 'Components/Inputs/UpSelect';
import { Button, ModalHeader, ModalContent, ModalControls, Row, Column, Label, Help } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import EditRoleAccessTable, { RoleAccess } from './EditRoleAccessTable';
import './EditRole.scss';
import Role from 'App/resources/Model/Role';
import { RoleSelectSingle } from 'Components/RoleSelect/RoleSelect';

type Currency = {
	active: boolean;
	iso: string;
	masterCurrency: boolean;
	rate: number;
};

interface SalesboardChangedEvent {
	target: {
		added: FormatedSalesboard;
		removed: FormatedSalesboard;
		value: number;
	};
}
interface FormatedSalesboard {
	id: number | string | null;
	name: string;
}

interface EditRoleProps {
	actions: {
		rootPropChanged: (key: string, value: string | number | FormatedSalesboard | boolean) => void;
		accessChanged: (entity: string, type: string, value: string | number | boolean) => void;
		close: () => void;
		save: () => void;
	};
	saving: boolean;
	currencies: Currency[];
	defaultCurrency: string;
	hasMultiCurrency: boolean;
	name: string;
	description: string;
	parentId: number;
	roleId: string;
	roleAccess: RoleAccess[];
	parsingRoleSettings: boolean;
	salesboards: FormatedSalesboard[];
	defaultSalesboard: FormatedSalesboard;
	hasMultiSalesboards: boolean;
	hasDiscount: boolean;
}

class EditRole extends React.Component<EditRoleProps> {
	static proptypes = {
		actions: PropTypes.object,
		saving: PropTypes.bool,
		currencies: PropTypes.array,
		defaultCurrency: PropTypes.string,
		hasMultiCurrency: PropTypes.bool,
		name: PropTypes.string,
		description: PropTypes.string,
		parentId: PropTypes.number,
		roleId: PropTypes.number,
		roleAccess: PropTypes.array,
		parsingRoleSettings: PropTypes.bool,
		salesboards: PropTypes.array,
		defaultSalesboard: PropTypes.object,
		hasMultiSalesboards: PropTypes.bool,
		hasDiscount: PropTypes.bool
	};

	lang: { [key: string]: string };
	nameField: HTMLInputElement | null = null;

	constructor(props: EditRoleProps) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			new: t('default.new'),
			edit: t('default.edit'),
			role: t('default.role'),
			name: t('default.name'),
			description: t('default.description'),
			reportTo: t('admin.reportTo'),
			setRoleAccessSales: t('admin.setRoleAccessSales'),
			setRoleAccessMarket: t('admin.setRoleAccessMarket'),
			abort: t('default.abort'),
			saving: t('default.saving'),
			save: t('default.save'),
			topLevelUsers: t('admin.topLevelUsers'),
			currency: t('default.currency'),
			defaultSalesboard: t('default.defaultSalesboard'),
			defaultSalesboardPlaceholder: t('default.defaultSalesboardPlaceholder')
		};

		this.textChanged = this.textChanged.bind(this);
		this.parentChanged = this.parentChanged.bind(this);
		this.accessChanged = this.accessChanged.bind(this);
		this.currencyChanged = this.currencyChanged.bind(this);
		this.salesboardChanged = this.salesboardChanged.bind(this);
		this.onDiscountChange = this.onDiscountChange.bind(this);
	}

	componentDidMount() {
		if (this.nameField) {
			this.nameField.focus();
		}
	}

	textChanged(key: string, e: React.ChangeEvent<HTMLInputElement>) {
		this.props.actions.rootPropChanged(key, e.target.value);
	}

	parentChanged(value: Pick<Role, 'id'>) {
		this.props.actions.rootPropChanged('parentId', value.id);
	}

	accessChanged(entity: string, type: string, value: string) {
		this.props.actions.accessChanged(entity, type, value);
	}

	currencyChanged(e: React.ChangeEvent<HTMLInputElement>) {
		this.props.actions.rootPropChanged('defaultCurrency', e.target.value);
	}

	salesboardChanged(e: SalesboardChangedEvent) {
		this.props.actions.rootPropChanged('defaultSalesboard', e.target.added);
	}
	onDiscountChange(value: boolean) {
		this.props.actions.rootPropChanged('hasDiscount', value);
	}

	render() {
		const classes = new bemClass('EditRole');
		let nameClass = 'floating-label-input';
		let nameStyle: React.CSSProperties = { fontSize: '18px' };
		if (this.props.name.length) {
			nameClass += ' has-value';
			nameStyle = { fontSize: '0.8em', top: '2px' };
		}
		let descriptionClass = 'floating-label-input';
		let descriptionStyle: React.CSSProperties = { fontSize: '14px' };
		if (this.props.description.length) {
			descriptionClass += ' has-value';
			descriptionStyle = { fontSize: '0.8em', top: '2px' };
		}

		const parentRoles = Tools.AppService.getRoleMap().all;
		parentRoles.unshift({
			id: -1,
			name: this.lang.topLevelUsers,
			defaultCurrency: '',
			description: '',
			parent: null,
			accessTemplate: 0,
			defaultSalesboardId: null,
			hasDiscount: false
		});
		const selectedParent = _.find(parentRoles, { id: this.props.parentId });

		if (this.props.roleId) {
			const removeIndex = _.findIndex(parentRoles, { id: parseInt(this.props.roleId) });
			if (removeIndex !== -1) {
				parentRoles.splice(removeIndex, 1);
			}
		}

		const marketAccessItems = this.props.roleAccess.filter(access => access.group === 'market');
		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		return (
			<div className={classes.b()} id="add-role-modal">
				<ModalHeader
					className="ModalHeader--no-border-bottom"
					title={(this.props.roleId ? this.lang.edit : this.lang.new) + ' ' + this.lang.role.toLowerCase()}
					onClose={this.props.actions.close}
				/>
				<ModalContent className="ModalContent--no-padding">
					<div className={classes.elem('top-section').b()}>
						<Row>
							<Column>
								<div className={nameClass}>
									<label style={nameStyle}>{this.lang.name}</label>
									<input
										type="text"
										value={this.props.name}
										style={{ fontSize: '18px' }}
										ref={r => {
											this.nameField = r;
										}}
										maxLength={50}
										onChange={this.textChanged.bind(null, 'name')}
										className={classes.elem('nameInput').b()}
									/>
								</div>
							</Column>
							<Column>
								<div className={descriptionClass}>
									<label style={descriptionStyle}>{this.lang.description}</label>
									<input
										type="text"
										value={this.props.description}
										style={{ fontSize: '18px' }}
										maxLength={65535}
										onChange={this.textChanged.bind(null, 'description')}
										className={classes.elem('descriptionInput').b()}
									/>
								</div>
							</Column>
						</Row>

						<Row>
							<Column size={6}>
								<Label>{this.lang.reportTo}</Label>
								<div style={{ marginTop: '5px', width: '400px' }}>
									{!this.props.parsingRoleSettings ? (
										hasTreeSelect ? (
											<RoleSelectSingle
												required
												roles={parentRoles}
												selectedRole={selectedParent}
												onChange={role => this.parentChanged({ id: role?.id ?? -1 })}
											/>
										) : (
											<ReactTemplates.INPUTS.upRoles
												value={selectedParent}
												required={true}
												data={parentRoles}
												onChange={this.parentChanged}
											/>
										)
									) : null}
								</div>
							</Column>
							{this.props.hasMultiCurrency ? (
								<Column size={3} className={classes.elem('columnMultiCurrency').b()}>
									<Label>{this.lang.currency}</Label>
									<div style={{ marginTop: '5px', width: '200px' }}>
										<UpSelect
											className="form-control"
											onChange={this.currencyChanged}
											data={this.props.currencies}
											defaultValue={this.props.defaultCurrency}
											formatSelection={(
												o: Currency,
												container: JQuery,
												escape: (value: string) => string
											) => escape(o.iso)}
											formatResult={(
												o: Currency,
												container: JQuery,
												query: string,
												escape: (value: string) => string
											) => escape(o.iso)}
											getId={(o: Currency) => o.iso}
											required={true}
										/>
									</div>
								</Column>
							) : null}
							{this.props.hasMultiSalesboards ? (
								<Column size={3} className={classes.elem('columnMultiSalesboards').b()}>
									<Label className={classes.elem('defaultSalesboardLabel').b()}>
										{this.lang.defaultSalesboard}
										<Help articleId={1428} />
									</Label>
									<div className={classes.elem('selectContainer').b()}>
										<UpSelect
											className="form-control"
											onChange={this.salesboardChanged}
											options={{
												data: this.props.salesboards
											}}
											defaultValue={this.props.defaultSalesboard?.id ?? undefined}
											placeholder={this.lang.defaultSalesboardPlaceholder}
										/>
									</div>
								</Column>
							) : null}
						</Row>
					</div>

					<div className={classes.elem('access-wrap').b()}>
						<EditRoleAccessTable
							key="sales"
							title={this.lang.setRoleAccessSales}
							access={this.props.roleAccess.filter(access => access.group === 'sales')}
							onChange={this.props.actions.accessChanged}
							parentId={this.props.parentId}
							onDiscountChange={this.onDiscountChange}
							hasDiscount={this.props.hasDiscount}
						/>

						{marketAccessItems.length ? (
							<EditRoleAccessTable
								key="market"
								title={this.lang.setRoleAccessMarket}
								access={marketAccessItems}
								onChange={this.props.actions.accessChanged}
								parentId={this.props.parentId}
							/>
						) : null}
					</div>
				</ModalContent>

				<ModalControls>
					<Button shadow="none" loading={this.props.saving} onClick={this.props.actions.save}>
						{this.lang.save} {this.lang.role.toLowerCase()}
					</Button>
					<Button type="link" color="grey" onClick={this.props.actions.close}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

export default EditRole;

import { Select } from '@upsales/components';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import { ResourceIdSelect } from 'App/components/ResourceSelect';

ReactTemplates.upFilters.filterTypes.socialEvent = window.ReactCreateClass({
	getDefaultProps: function () {
		return {};
	},
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	getValue: function (filter, path, undefinedValue) {
		var keys = path.split('.');

		var current = filter,
			key;

		for (var itr = 0, length = keys.length; itr < length; ++itr) {
			if (typeof current !== 'object') {
				return undefinedValue || undefined;
			}

			key = keys[itr];

			if (itr === length - 1) {
				return current[key];
			} else {
				current = current[key];
			}
		}
	},

	UNSAFE_componentWillMount: function () {
		this.search = _.debounce(this.search, 300);

		var translate = Tools.$translate;
		this.lang = {
			eventName: translate('socialEvent.eventName'),
			contactStatus: translate('socialEvent.contactsEventStatus'),
			all: translate('default.all'),
			filter: translate('filters.filter'),
			filters: translate('filters.filters'),
			searching: translate('default.searching'),
			noResults: translate('default.noResults')
		};

		this.contactStatus = [
			{ id: 'added', title: translate('socialEvent.status.added') },
			{ id: 'invited', title: translate('socialEvent.status.invited') },
			{ id: 'attending', title: translate('socialEvent.status.attending') },
			{ id: 'attended', title: translate('socialEvent.status.attended') },
			{ id: 'declined', title: translate('socialEvent.status.declined') },
			{ id: 'attendingOnDemand', title: translate('socialEvent.status.attendingOnDemand') },
			{ id: 'attendedOnDemand', title: translate('socialEvent.status.attendedOnDemand') }
		];
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	search: function (filter) {
		this.props.onChange(
			{
				filterName: this.props.filterName,
				value: filter
			},
			{
				action: 'add'
			}
		);
	},
	onChange: function (action, value) {
		var filter = this.props.filter.value ? this.props.filter.value : this.props.filter.generate().value;

		switch (action) {
			case 'SocialEvent':
				filter.SocialEvent.value = value;
				break;
			case 'ContactStatus':
				filter.ContactStatus.value = value;
				break;
			case 'remove':
				filter = this.props.filter.generate().value;
				break;
		}

		this.search(filter);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.onChange('remove');

		ReactTemplates.TOOLS.stopProp(event);
	},
	getNumFilters: function (val) {
		var num = 0;
		if (val.SocialEvent.value) {
			num++;
		}
		if (val.ContactStatus.value) {
			num++;
		}
		return num;
	},
	formatValueString: function () {
		var number = this.props.filter.value ? this.getNumFilters(this.props.filter.value) : 0;

		switch (number) {
			case 0:
				return '';
			case 1:
				return number + ' ' + this.lang.filter;
			default:
				return number + ' ' + this.lang.filters;
		}
	},
	getAnchor: function () {
		if (!this.anchor) {
			this.anchor = document.querySelector('#list-filters-wrap');
		}
		return this.anchor;
	},
	render: function () {
		const filter = this.props.filter;
		const active = this.props.filter.value && this.getNumFilters(this.props.filter.value) > 0;
		const title = filter.title ? Tools.$translate(filter.title) : this.props.filterName;
		const eventStatus = _.get(this.props.filter, ['value', 'ContactStatus', 'value'], null);
		const eventId = _.get(this.props.filter, ['value', 'SocialEvent', 'value'], null);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'drilldown address-drilldown'}>
						<div style={{ marginBottom: '10px' }}>
							<div className="input-wrap">
								<label>{this.lang.eventName}</label>
								<ResourceIdSelect
									resource={SocialEventResource}
									anchor={this.getAnchor()}
									value={eventId}
									placeholder={this.lang.all}
									onChange={id => this.onChange('SocialEvent', id)}
									onClear={() => this.onChange('SocialEvent', null)}
								/>
							</div>
						</div>
						<div style={{ marginBottom: '10px' }}>
							<div className="input-wrap">
								<label>{this.lang.contactStatus}</label>
								<Select
									onChange={event => this.onChange('ContactStatus', event ? event.id : null)}
									onClear={() => this.onChange('ContactStatus', null)}
									placeholder={this.lang.all}
									value={
										eventStatus
											? this.contactStatus.find(({ id }) => id === eventStatus) ?? null
											: null
									}
									options={this.contactStatus}
									anchor={this.getAnchor()}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
});

'use strict';

(function () {
	var UNSAFE_componentWillMount = function () {
		this.lang = {};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var $translate = Tools.$translate;
		var currenciesISO4217 = Tools.currenciesISO4217;
		var $q = Tools.$q;

		var format = function (currency) {
			var icon = null;

			var config = currenciesISO4217[currency.iso];

			if (config && config.icon) {
				icon = <img style={config.style || {}} src={'./img/' + config.icon} />;
			} else if (config && config.countries && config.countries.length) {
				icon = (
					<i
						style={{ marginRight: 5 }}
						className={'flag-icon flag-icon-' + config.countries[0].toLowerCase()}
					></i>
				);
			}

			var text = '';
			var name = config ? config.name : '';

			if (name && currency.iso) {
				text = name + ' (' + currency.iso + ')';
			} else if (name && !currency.iso) {
				text = name;
			} else if (!name && currency.iso) {
				text = currency.iso;
			} else {
				text = $translate('default.noName');
			}

			return window.renderToStaticMarkup(
				<span>
					{icon}
					{text}
				</span>
			);
		};

		var sortedData = _.sortBy(currenciesISO4217, 'name');

		var opts = {
			ajax: false,
			asIds: false,
			required: self.props.required || false,
			idAttr: { field: 'iso' },
			onChange: self.props.onChange,
			emptyMsg: '',
			placeholder: self.props.placeholder || $translate('admin.currencies.placeholder'),
			select2: {
				matcher: function (term, undefined, object) {
					var searchTerm = term.toLowerCase();
					var iso = object.iso.toLowerCase();
					var name = object.name.toLowerCase();

					return iso.indexOf(searchTerm) !== -1 || name.indexOf(searchTerm) !== -1;
				},
				ajax: {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						var term = query.data;

						var data = _.filter(sortedData, function (object) {
							var searchTerm = term.toLowerCase();
							var iso = object.iso.toLowerCase();
							var name = object.name.toLowerCase();
							var isExcluded = false;

							if (self.props.exclude && Array.isArray(self.props.exclude)) {
								isExcluded = self.props.exclude.indexOf(object.iso) !== -1;
							}

							return (iso.indexOf(searchTerm) !== -1 || name.indexOf(searchTerm) !== -1) && !isExcluded;
						});

						return query.success(data);
					},
					results: function (data) {
						return { results: data };
					}
				}
			},
			formatSelection: format,
			formatResult: format,
			getter: function (/*customerId, filter*/) {
				var data = _.sortBy(currenciesISO4217, 'name');
				return $q.when({ data: data });
			}
		};

		var _opts = _.assign({}, opts, self.props.options);

		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, _opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upCurrency = window.ReactCreateClass({
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		componentDidMount: function () {
			componentDidMount(this);
		},
		render: render
	});
})();

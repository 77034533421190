import { globalTracker } from 'App/babel/helpers/Tracker';

angular.module('upHelpers').factory('SegmentHelper', function () {
	var modules = {
		account: 'Account & Contacts',
		advancedSearch: 'Advanced Search',
		contact: 'Account & Contacts',
		activity: 'Activity Management',
		appointment: 'Activity Management',
		opportunity: 'Pipeline Management',
		order: 'Order management',
		leads: 'Leads',
		visits: 'Visits',
		forms: 'forms',
		bisnode: 'Bisnode',
		mailCampaign: 'E-mail marketing',
		mailTemplate: 'E-mail marketing',
		form: 'Forms & Landing pages',
		ads: 'Engage advertising',
		bannerGroup: 'Engage advertising',
		esign: 'E-sign',
		reportView: 'Reporting & Analytics',
		listView: 'Custom List Views',
		tagPicker: 'Insert tags modal'
	};
	return {
		module: modules,
		track: function (event, module) {
			if (module) {
				globalTracker.track(event, module);
			} else {
				globalTracker.track(event);
			}
		}
	};
});

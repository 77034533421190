'use strict';

angular.module('upResources').factory('ResetScore', [
	'$rootScope',
	'$resource',
	'ResourceHelper',
	'$q',
	'URL',
	'API',
	function ContactCategory($rootScope, $resource, ResourceHelper, $q, URL, API) {
		var helper = new ResourceHelper();

		var Model = {
			customer: function (customer) {
				var instance = {};

				var ResetScore = $resource(
					URL + API + customer + '/function/resetScore/:id',
					{},
					{
						create: { method: 'POST', isArray: false }
					}
				);
				instance.reset = function (params) {
					return ResetScore.create(params)
						.$promise.then(function (res) {
							$rootScope.$broadcast('account.resetScore', params.clientId);
							return res;
						})
						.catch(function () {
							helper.errorNotification('default.error', 'saveError.unassign', null);
						});
				};

				return instance;
			}
		};

		return Model;
	}
]);

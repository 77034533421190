'use strict';

angular.module('domain.mail').controller('startMailCampaign', [
	'$scope',
	'$modalParams',
	function ($scope, $modalParams) {
		$scope.mail = $modalParams.mail;
		$scope.recievers = $modalParams.recievers;
		$scope.socialEvent = $modalParams.socialEvent;
		$scope.socialEventStatus = $modalParams.socialEventStatus;
	}
]);

import React, { useMemo } from 'react';
import { capitalize, cloneDeep, find } from 'lodash';
import moment from 'moment';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { Input } from '@upsales/components';

type Filter = {
	inactive: boolean;
	value: {
		preset: string;
		start: Date | number | null | undefined;
		end: Date | null | undefined;
	};
};

type Props = {
	config: {
		presets: string[];
	};
	filter: Filter;
	onChange: (filter: Filter) => void;
};

function getDynamicInputPlaceholder(lang: { [key: string]: string }, preset: string) {
	const [, unit] = preset.split('X');
	return lang[`numOf${capitalize(unit)}`];
}

function isPresetDynamic(preset: string) {
	return preset?.indexOf('lastX') === 0 || preset?.indexOf('nextX') === 0;
}

function isFilterInactive(filter: Filter) {
	return !(
		filter.value.start ||
		filter.value.end ||
		(filter.value.preset !== 'whenever' && !isPresetDynamic(filter.value.preset))
	);
}

export default function DateColumn({ config: { presets }, filter, onChange }: Props) {
	const presetValues = useMemo(() => {
		const $translate = getAngularModule('$translate');

		return presets.map(preset => ({
			id: preset,
			name: capitalize($translate(`date.${preset}`))
		}));
	}, presets);

	const lang = useMemo(() => {
		const $translate = getAngularModule('$translate');

		return {
			start: $translate('default.from'),
			end: $translate('default.to'),
			numOfDays: $translate('default.numOfDays'),
			numOfWeeks: $translate('default.numOfWeeks'),
			numOfMonths: $translate('default.numOfMonths'),
			numOfYears: $translate('default.numOfYears')
		};
	}, []);

	const presetValue = find(presetValues, { id: filter.value.preset });
	const showDatepickers = filter.value.preset === 'custom';
	const showDynamicInput = isPresetDynamic(filter.value.preset);

	function onPresetChanged(value: string) {
		const newFilter = cloneDeep(filter);
		newFilter.value.preset = value || 'whenever';
		newFilter.value.start = undefined;
		newFilter.value.end = undefined;
		newFilter.inactive = isFilterInactive(newFilter);
		onChange(newFilter);
	}

	function onStartChanged(value: Date | null) {
		const newFilter = cloneDeep(filter);
		newFilter.value.start = value ? value : null;
		newFilter.inactive = isFilterInactive(newFilter);
		onChange(newFilter);
	}

	function onEndChanged(value: Date | null) {
		const newFilter = cloneDeep(filter);
		newFilter.value.end = value ? moment(value).endOf('day').toDate() : null;
		newFilter.inactive = isFilterInactive(newFilter);
		onChange(newFilter);
	}

	function onDynamicValueChanged(value: string) {
		const newFilter = cloneDeep(filter);
		const numberValue = parseInt(value);
		const clampedValue = Math.min(1000, numberValue);
		newFilter.value.start = Math.abs(clampedValue) || undefined;
		newFilter.inactive = isFilterInactive(newFilter);
		onChange(newFilter);
	}

	const classNames = showDatepickers ? 'col-sm-6 col-lg-6' : showDynamicInput ? 'col-sm-9 col-lg-6' : 'col-lg-6';

	return (
		<td className="table-col">
			<div className="drilldown-row">
				<div className={classNames}>
					<ReactTemplates.INPUTS.upSelect
						className="form-control"
						key={'datepicker-select'}
						data={presetValues}
						required={true}
						multiple={false}
						onChange={(event: { target: { value: string } }) => onPresetChanged(event.target.value)}
						defaultValue={presetValue}
						options={{ minimumResultsForSearch: -1 }}
					/>
				</div>
				{showDatepickers ? (
					<div className="col-sm-3">
						<ReactTemplates.upFilters.components.datepicker
							placeholder={lang.start}
							name={'startDate'}
							className="form-control"
							value={filter.value.start as Date | null}
							onChange={(value: Date | null) => onStartChanged(value)}
							container="body"
							placement="auto bottom-auto"
						/>
					</div>
				) : null}
				{showDatepickers ? (
					<div className={'col-sm-3'}>
						<ReactTemplates.upFilters.components.datepicker
							placeholder={lang.end}
							name={'endDate'}
							className="form-control"
							value={filter.value.end}
							onChange={(value: Date | null) => onEndChanged(value)}
							container="body"
							placement="auto bottom-auto"
						/>
					</div>
				) : null}
				{showDynamicInput ? (
					<div className="col-sm-3">
						<Input
							type="number"
							placeholder={getDynamicInputPlaceholder(lang, filter.value.preset)}
							value={typeof filter.value.start === 'number' ? filter.value.start?.toString() : ''}
							onChange={(e: any) => onDynamicValueChanged(e.target.value)}
						/>
					</div>
				) : null}
			</div>
		</td>
	);
}

import * as Sentry from '@sentry/browser';
import _ from 'lodash';

angular.injector(['config']).invoke(function (SENTRY) {
	window.SENTRY_ENABLED = SENTRY.enable;

	var sentryOptions = {
		dsn: SENTRY.dsn,
		environment: SENTRY.env,
		// eslint-disable-next-line no-undef
		release: process.env.SENTRY_RELEASE,
		allowUrls: SENTRY.allowUrls,
		denyUrls: SENTRY.denyUrls,
		ignoreErrors: [
			'Network Error',
			'NetworkError',
			'State changed',
			'escaped',
			'Non-Error promise rejection captured with value: undefined',
			'timeout of 0ms exceeded',
			'ResizeObserver loop limit exceeded' // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
		],
		beforeSend(sentryEvent, hint) {
			if (
				hint &&
				hint.originalException &&
				(hint.originalException.status === 401 || hint.originalException.status === -1)
			) {
				// Discard 401 and -1 errors served from a custom event
				return null;
			}

			if (
				_.get(hint, 'originalException.data.error.key') === 'FailedValidation' &&
				_.get(hint, 'originalException.data.error.translated')
			) {
				// Skipp FailedValidation events that have a translation. Those are known and we do show an error to the user
				return null;
			}

			if (!sentryEvent.user || !sentryEvent.user.id) {
				// Discard messages non logged in users
				return null;
			}

			if (
				sentryEvent.extra &&
				sentryEvent.extra.__serialized__ &&
				(sentryEvent.extra.__serialized__.status === 401 || sentryEvent.extra.__serialized__.status === -1)
			) {
				// Discard 401 errors and custom object errors
				return null;
			}

			// Show user feedback modal if this error was an exception and we made it pass all the checks above
			if (sentryEvent.exception && Tools.FeatureHelper.hasSoftDeployAccess('SENTRY_MODAL')) {
				const user = Tools.AppService.getSelf();
				if (user) {
					Sentry.showReportDialog({
						user: {
							name: user.name,
							email: user.email
						},
						eventId: sentryEvent.event_id,
						title: Tools.$translate('sentry.modal.title'),
						subtitle: Tools.$translate('sentry.modal.subtitle'),
						subtitle2: '',
						labelComments: Tools.$translate('sentry.modal.labelComments'),
						labelClose: Tools.$translate('sentry.modal.labelClose'),
						labelSubmit: Tools.$translate('sentry.modal.labelSubmit'),
						successMessage: Tools.$translate('sentry.modal.successMessage')
					});
				}
			}

			return sentryEvent;
		},
		beforeBreadcrumb(breadcrumb, hint) {
			if (breadcrumb.category.startsWith('ui')) {
				const target = hint.event.target;
				let text = target?.textContent || target?.innerText;
				if (text) {
					if (text.length > 100) {
						text = text.substring(0, 100) + '...';
					}
					breadcrumb.message += ` > "${text.trim()}"`;
				}
			}
			return breadcrumb;
		}
	};

	if (!SENTRY.enable) {
		sentryOptions.sampleRate = 0.0;
	}

	Sentry.init(sentryOptions);
});

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './AccountListBoardOfDirectors.scss';
import logError from 'App/babel/helpers/logError';
import bemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'App/babel/helpers/promise';
import {
	Icon,
	Card,
	Text,
	Button,
	Column,
	Row,
	DropDownMenu,
	TableRow,
	Table,
	TableColumn,
	DropDownButton,
	Link,
	Help,
	Loader,
	AssistChip,
	Block
} from '@upsales/components';
import Prospecting from 'App/babel/resources/Prospecting';
import AccountListBoardOfDirectorsCompanyCard from './AccountListBoardOfDirectorsCompanyCard';
import { Provider } from 'react-redux';
import store from 'Store';
import BillingAddonCard, { NEXT_STEPS } from '../../Billing/BillingAddonCard';
import ProvisioningResource from 'App/babel/resources/Provisioning';
import ProspectingResource from 'Resources/Prospecting';
import { globalTracker } from 'Helpers/Tracker';
import T from 'Components/Helpers/translate';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import BillingAddonBanner from 'App/components/BillingAddonBanner';

const trialAddonsArray = ['PROSPECTING_PRO_SIGNALS', 'PROSPECTING_PRO_PROFESSIONAL', 'PROSPECTING_PRO_ENTERPRISE'];

const mapStateToProps = ({ Billing }) => ({
	hasProspectingProTrial: Object.keys(Billing.ongoingTrialAddons).find(addon =>
		trialAddonsArray.includes(addon.value)
	)
});

const mapDispatchToProps = {};

const NAME_MAXLENGTH = 50;
const TITLE_MAXLENGTH = 128;

class AccountListBoardOfDirectors extends React.Component {
	alias = 'PROSPECTING_PRO_SIGNALS';
	constructor(props) {
		super(props);
		this.directors = {};
		this.limit = 10;
		this.showPlaceholder = !Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
		this.prospectingProAddon = null;
		this.lang = {
			add: T('add_to_upsales'),
			additionalBoardAssignments: T('account.additionalBoardAssignments'),
			name: T('account.name'),
			memberSince: T('account.memberSince'),
			showCompanies: T('account.showCompanies'),
			boardOfDirectors: T('account.boardOfDirectors'),
			prospecting: T('financials.prospecting'),
			pro: T('financials.pro'),
			merge: T('contact.mergeWithOtherContact'),
			showMore: T('default.showMore'),
			alreadyInUpsales: T('account.alreadyExisting'),
			showInList: T('prospecting.mergeWithContact.showInList'),
			noAdditionalBoardAssignments: T('account.noAdditionalBoardAssignments'),
			close: T('close')
		};
		this.addedProspectingIds = {};
		const temp = {};
		if (this.props.directors && !this.showPlaceholder) {
			for (const director of this.props.directors) {
				temp[director.prospectingId] = { show: false, loading: false, count: null };
				if (this.directors.hasOwnProperty(director.prospectingId)) {
					this.directors[director.prospectingId].roles.push(director.roleName);
					if (moment(this.directors[director.prospectingId].fromDate).isAfter(director.fromDate)) {
						this.directors[director.prospectingId].fromDate = director.fromDate;
					}
				} else {
					director.roles = [director.roleName];
					this.directors[director.prospectingId] = director;
				}
			}
		}

		this.state = {
			showCompanies: temp,
			companies: {},
			sticky: false,
			y: 0,
			currentCompanies: {},
			addedContacts: {},
			promoData: { ceoCount: 0, clientCount: 0, prospectingIdClientCount: 0, loading: true },
			availableAddons: [],
			failedToFetchAddons: false
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true);
		this.getPromise = makeCancelable(ProvisioningResource.getAvailableAddons());
		this.getPromise.promise
			.then(res => res.data)
			.then(res => {
				this.prospectingProAddon = res.find(addon => {
					return addon.alias === this.alias;
				});

				if (
					this.showPlaceholder &&
					this.prospectingProAddon &&
					Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CEO_ON_BOARD')
				) {
					this.getDirectorsPromo = makeCancelable(
						ProspectingResource.getPromoData('ceoOnBoard', {
							prospectingId: this.props.accountProspectingId
						})
					);
					this.getDirectorsPromo.promise
						.then(promoData => this.setState({ promoData: { ...promoData, loading: false } }))
						.catch(e => logError(e, 'Failed to fetch promo data'));
				}
				this.setState({ availableAddons: res });
			})
			.catch(e => {
				this.setState({ failedToFetchAddons: true });
				logError(e, 'Failed fetch addons');
			});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll, true);
		this.savePromise?.cancel();
		this.getPromise?.cancel();
		this.getDirectorsPromo?.cancel();
	}

	handleScroll = ({ target }) => {
		if (this.showPlaceholder) {
			return;
		}
		const header = target.querySelector('.AccountListContacts__TableHeader');
		const boardHeader = target.querySelector('#columnHeader');
		if (header && boardHeader) {
			const { bottom } = header.getBoundingClientRect();
			const { top } = boardHeader.getBoundingClientRect();
			if (this.state.sticky && window.pageYOffset <= this.state.y) {
				this.setState({
					sticky: false,
					y: 0
				});
			} else {
				this.setState({
					sticky: top <= bottom,
					y: top
				});
			}
		}
	};

	getCompanies(prospectingId) {
		const RequestBuilder = Tools.RequestBuilder;
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'directors.prospectingId' }, rb.comparisonTypes.Equals, prospectingId);
		rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, true);
		rb.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.NotEquals, this.props.accountProspectingId);
		rb.addFilter({ field: 'directors.toDate' }, rb.comparisonTypes.Equals, null);
		rb.limit = this.limit;
		rb.extraParams.push({
			key: 'country',
			value: Prospecting.getCountryFromProspectingId(this.props.accountProspectingId)
		});
		return Prospecting.find(rb.build());
	}

	async listCompanies(companies, prospectingId, count) {
		const allCompanies = [];
		const director = this.directors[prospectingId];

		const cleanCompanies = [];
		if (!this.addedProspectingIds[prospectingId]) {
			this.addedProspectingIds[prospectingId] = [];
		}
		for (const company of companies) {
			if (!this.addedProspectingIds[prospectingId].includes(company.prospectingId)) {
				this.addedProspectingIds[prospectingId].push(company.prospectingId);
				cleanCompanies.push(company);
			}
		}
		for (const company of cleanCompanies) {
			allCompanies.push(
				<AccountListBoardOfDirectorsCompanyCard
					key={'card' + company.orgNumber}
					company={company}
					prospectingId={prospectingId}
					name={director.firstName || director.name}
					matchInUpsales={company.matchInUpsales}
				/>
			);
		}
		const tempCompanies = this.state.companies;
		if (tempCompanies[prospectingId]) {
			tempCompanies[prospectingId] = tempCompanies[prospectingId].concat(allCompanies);
		} else {
			tempCompanies[prospectingId] = allCompanies;
		}
		const tempShowCompanies = this.state.showCompanies;
		tempShowCompanies[prospectingId].loading = false;
		tempShowCompanies[prospectingId].count = count;
		tempShowCompanies[prospectingId].showMoreLoading = false;
		this.setState({ showCompanies: tempShowCompanies, companies: tempCompanies });
	}

	updateMetadata(prospectingId, metadata) {
		if (metadata.limit + metadata.offset < metadata.total) {
			const currentCompanies = this.state.currentCompanies;
			if (!currentCompanies[prospectingId]) {
				currentCompanies[prospectingId] = {};
			}
			currentCompanies[prospectingId].hasMore = true;
			currentCompanies[prospectingId].metadata = metadata;
			this.setState({ currentCompanies });
		} else {
			const currentCompanies = this.state.currentCompanies;
			if (!currentCompanies[prospectingId]) {
				currentCompanies[prospectingId] = {};
			}
			currentCompanies[prospectingId].hasMore = false;
			this.setState({ currentCompanies });
		}
	}

	showMoreCompanies(prospectingId) {
		const showCompanies = this.state.showCompanies;
		showCompanies[prospectingId].showMoreLoading = true;
		this.setState({ showCompanies });
		const metadata = this.state.currentCompanies[prospectingId].metadata;
		const RequestBuilder = Tools.RequestBuilder;
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'directors.prospectingId' }, rb.comparisonTypes.Equals, prospectingId);
		rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, true);
		rb.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.NotEquals, this.props.accountProspectingId);
		rb.addFilter({ field: 'directors.toDate' }, rb.comparisonTypes.Equals, null);
		rb.limit = this.limit;
		rb.offset = metadata.offset + this.limit;
		rb.extraParams.push({
			key: 'country',
			value: Prospecting.getCountryFromProspectingId(this.props.accountProspectingId)
		});

		return Prospecting.find(rb.build()).then(res => {
			this.updateMetadata(prospectingId, res.metadata);
			this.listCompanies(res.data, prospectingId, res.metadata.total);
		});
	}

	flipShowCompanies(director) {
		const showCompanies = this.state.showCompanies;
		if (showCompanies[director.prospectingId].show) {
			showCompanies[director.prospectingId] = { show: false, loading: false, count: null };
			this.setState(showCompanies);
			return;
		}
		showCompanies[director.prospectingId] = { show: true, loading: true };
		this.setState(showCompanies);
		return this.getCompanies(director.prospectingId).then(res => {
			this.updateMetadata(director.prospectingId, res.metadata);
			this.listCompanies(res.data, director.prospectingId, res.metadata.total);
		});
	}

	addIdToAddedContacts(prospectingId, id) {
		const addedContacts = this.state.addedContacts;
		addedContacts[prospectingId] = { id };
		this.setState({ addedContacts });
	}

	async addContact(director) {
		const contact = this.props.contact.new();
		contact.ssn = director.prospectingId;

		const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

		if (hasNewFields) {
			if (director.firstName && director.lastName) {
				contact.firstName = director.firstName?.substring(0, NAME_MAXLENGTH);
				contact.lastName = director.lastName?.substring(0, NAME_MAXLENGTH);
			} else {
				contact.firstName = director.name?.substring(0, NAME_MAXLENGTH);
			}
		} else {
			contact.name = director.name?.substring(0, NAME_MAXLENGTH);
		}

		contact.title = director.roles.join(', ')?.substring(0, TITLE_MAXLENGTH);
		contact.client = { id: this.props.accountId };
		this.savePromise = makeCancelable(
			this.props.contact.save(contact, { params: { usingFirstnameLastname: hasNewFields } })
		);

		return this.savePromise.promise
			.then(res => {
				this.addIdToAddedContacts(director.prospectingId, res.data.id);
				globalTracker.track('Prospecting - Added contact from board');
			})
			.catch(error => logError(error, 'Failed to save contact'));
	}

	mergeContact(director) {
		Tools.$upModal.open('mergeWithBoardmember', {
			accountId: this.props.accountId,
			id: Tools.AppService.getSelf().id,
			director,
			accountName: this.props.accountName,
			addIdToAddedContacts: this.addIdToAddedContacts.bind(this)
		});
	}

	showInList(id) {
		const row = document.getElementById(id);
		const y = row.getBoundingClientRect().top + window.pageYOffset - 234;
		window.scrollTo({ top: y, behavior: 'smooth' });
	}

	getTableRows() {
		const t = Tools.$translate;
		const rows = [];

		for (const [prospectingId, director] of Object.entries(this.directors)) {
			const { name, firstName } = director;
			const { show, loading, count, showMoreLoading } = this.state.showCompanies[director.prospectingId];

			const nameToRender = firstName || name;
			if (!nameToRender) {
				continue;
			}
			const lastChar = nameToRender.substr(nameToRender.length - 1);
			const boardAssignmentsLangTag = ['s', 'x', 'z'].includes(lastChar)
				? 'account.boardAssignmentsNonGenitive'
				: 'account.boardAssignments';

			rows.push(
				<div key={'row' + rows.length}>
					<Card className={'AccountListBoardOfDirectors__listEntry'}>
						<Row key={prospectingId}>
							<Column>
								{director.matchInUpsales || this.state.addedContacts.hasOwnProperty(prospectingId) ? (
									<Link
										onClick={() =>
											Tools.$state.go('contact.dashboard', {
												id:
													director.matchInUpsales ||
													this.state.addedContacts[prospectingId].id
											})
										}
									>
										{name}
									</Link>
								) : (
									<Text bold>{name}</Text>
								)}

								<Text size="sm" color="grey-11">
									{director.roles.join(', ')}
								</Text>
							</Column>
							<Column>
								<Text className={'AccountListBoardOfDirectors__listEntry__memberSince'}>
									{moment(director.fromDate).year()}
								</Text>
							</Column>
							<Column>
								<Button
									className={
										!loading && show
											? 'AccountListBoardOfDirectors__listEntry__hideButton'
											: 'AccountListBoardOfDirectors__listEntry__showButton'
									}
									loading={loading}
									type="link"
									onClick={() => this.flipShowCompanies(director)}
								>
									{!loading && count
										? t('account.companyCount', { count: count })
										: show
										? this.lang.close
										: this.lang.showCompanies}
									{show ? <Icon name="times" /> : null}
								</Button>
							</Column>

							<Column className={'AccountListBoardOfDirectors__listEntry__buttonGroup'}>
								{director.matchInUpsales || this.state.addedContacts.hasOwnProperty(prospectingId) ? (
									<React.Fragment>
										<Link
											size="sm"
											className={'AccountListBoardOfDirectors__listEntry__link'}
											onClick={() =>
												this.showInList(
													director.matchInUpsales ||
														this.state.addedContacts[prospectingId].id
												)
											}
										>
											{this.lang.showInList}
										</Link>
										<div className={'AccountListBoardOfDirectors__alreadyInUpsales'}>
											<Icon name="check" color="grey-11" space="mrm"></Icon>
											<Text size="sm" color="grey-10">
												{this.lang.alreadyInUpsales}
											</Text>
										</div>
									</React.Fragment>
								) : (
									<DropDownMenu
										align="right"
										renderTrigger={(expanded, setExpanded) => (
											<div>
												<Button
													className={'AccountListBoardOfDirectors__addContactBtn'}
													onClick={() => this.addContact(director)}
												>
													<Icon name="plus"></Icon> {this.lang.add}
												</Button>
												<DropDownButton
													onClick={e => {
														e.stopPropagation();
														setExpanded(e);
													}}
													expanded={expanded}
												/>
											</div>
										)}
									>
										<Table>
											<TableRow>
												<TableColumn
													className={'AccountListBoardOfDirectors__listEntry__merge'}
													onClick={() => this.mergeContact(director)}
													color="green"
												>
													<Icon name="merge"></Icon>
													<Text color="white">{this.lang.merge}</Text>
												</TableColumn>
											</TableRow>
										</Table>
									</DropDownMenu>
								)}
							</Column>
						</Row>
					</Card>
					{show && !loading ? (
						<div className={'AccountListBoardOfDirectors__additionalCompanies'}>
							{this.state.companies[director.prospectingId].length > 0 ? (
								<React.Fragment>
									<Text
										size="xl"
										className={
											'AccountListBoardOfDirectors__additionalCompanies__additionalAssignments'
										}
									>
										{t(boardAssignmentsLangTag, { name: nameToRender })}
									</Text>
									{this.state.companies[director.prospectingId]}
								</React.Fragment>
							) : (
								<Text size="xl" center>
									{this.lang.noAdditionalBoardAssignments}
								</Text>
							)}
							{this.state.currentCompanies[director.prospectingId] &&
							this.state.currentCompanies[director.prospectingId].hasMore ? (
								<Row align="center">
									<Button
										loading={showMoreLoading}
										className={'AccountListBoardOfDirectors__additionalCompanies__showMoreBtn'}
										onClick={() => this.showMoreCompanies(director.prospectingId)}
									>
										{this.lang.showMore}
									</Button>
								</Row>
							) : null}
						</div>
					) : null}
				</div>
			);
		}
		return rows;
	}

	render() {
		const classNames = new bemClass('AccountListBoardOfDirectors');

		let promoTitle = null;

		// If we have a ceoOnBoard connection for this client
		if (this.state.promoData.prospectingIdClientCount) {
			promoTitle = T('prospecting.ceoBoardMembers.promo.ceoOnThisCompanyIsOnBoardOf', {
				companyName:
					this.props.accountName.length > 30
						? this.props.accountName.substring(0, 30) + '.'
						: this.props.accountName,
				num: numberFormat(this.state.promoData.prospectingIdClientCount)
			});
		} else if (this.state.promoData.clientCount) {
			// If we have any ceo connections on any client
			promoTitle = T('prospecting.ceoBoardMembers.promo.thereAreXWaysIntoOtherCompaniesThroughCeos', {
				num: numberFormat(this.state.promoData.ceoCount)
			});
		} else {
			// If we have no ceo connections
			promoTitle = T('prospecting.ceoBoardMembers.promo.findLeadsFromExistingCustomers');
		}

		const t = Tools.$translate;
		return (
			<div className={classNames.b()}>
				{this.showPlaceholder ? (
					this.prospectingProAddon ? (
						Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CEO_ON_BOARD') ? (
							<div className={classNames.elem('addonCon').b()}>
								<Provider store={store}>
									<BillingAddonBanner
										alias={this.alias}
										loading={this.state.promoData.loading}
										chipText={T('admin.billing.addon.PROSPECTING_PRO.name')}
										title={promoTitle}
										location="clientContacts"
										nextSteps={[
											{
												title: T('prospecting.showBoardMembersForName', {
													companyName: this.props.accountName
												}),
												// reloading the page is the easiest way to update the list of board members right now since they are fetched from the angular controller
												action: () =>
													Tools.$state.go(Tools.$state.current.name, Tools.$state.params, {
														reload: true
													})
											},
											...(NEXT_STEPS[this.alias] || [])
										]}
									>
										{/* Only show this if the headline is about this company */}
										{this.state.promoData.prospectingIdClientCount ? (
											<Block space="mbm">
												<Text color="green" className={classNames.elem('breaking-text').b()}>
													{T(
														'prospecting.ceoBoardMembers.promo.thereAreXCeoBoardMembersOnOtherCompanies',
														{
															numCeos: numberFormat(this.state.promoData.ceoCount),
															numCompanies: numberFormat(this.state.promoData.clientCount)
														}
													)}
												</Text>
											</Block>
										) : null}
										<Text
											loading={this.state.promoData.loading}
											color="green"
											className={classNames.elem('breaking-text').b()}
										>
											{T('prospecting.ceoBoardMembers.promo.description')}
										</Text>
									</BillingAddonBanner>
								</Provider>
							</div>
						) : (
							<div className={classNames.elem('addonCon').b()}>
								<Provider store={store}>
									<BillingAddonCard
										key={this.alias}
										addon={this.prospectingProAddon}
										bought={false}
										viewPlan={() => {}}
										location="clientContacts"
									/>
								</Provider>
							</div>
						)
					) : this.state.failedToFetchAddons ? null : (
						<div className={classNames.elem('addonCon').b()}>
							<Loader size="sm" />
						</div>
					)
				) : (
					<div>
						<Card
							className={
								this.showPlaceholder
									? classNames.elem('headerPlaceHolder').b()
									: classNames.elem('header').b()
							}
						>
							<Text className={classNames.elem('header').elem('title').b()} bold size="lg">
								{this.lang.boardOfDirectors}
							</Text>
							<Help articleId={1194} />
							{this.showPlaceholder ? null : (
								<React.Fragment>
									<Text className={classNames.elem('header').elem('contacts').b()} color="grey-10">
										{t('account.numberOfContacts', {
											count: Object.keys(this.directors).length.toString()
										})}
									</Text>

									{this.props.hasProspectingProTrial ? (
										<div className={classNames.elem('header').elem('pro').b()}>
											<AssistChip
												type="green"
												title={T('admin.billing.addon.PROSPECTING_PRO.name')}
												icon="pro"
											/>
										</div>
									) : null}
								</React.Fragment>
							)}
						</Card>

						<Row id="columnHeader" className={classNames.elem('columns').b()}>
							<Column>
								<Text bold>{this.lang.name.toUpperCase()}</Text>
							</Column>
							<Column>
								<Text bold>{this.lang.memberSince.toUpperCase()}</Text>
							</Column>
							<Column size={6}>
								<Text bold>{this.lang.additionalBoardAssignments.toUpperCase()}</Text>
							</Column>
						</Row>
						{this.state.sticky ? (
							<Row className={classNames.elem('sticky').b()}>
								<Column>
									<Text bold>{this.lang.name.toUpperCase()}</Text>
								</Column>
								<Column>
									<Text bold>{this.lang.memberSince.toUpperCase()}</Text>
								</Column>
								<Column size={6}>
									<Text bold>{this.lang.additionalBoardAssignments.toUpperCase()}</Text>
								</Column>
							</Row>
						) : null}

						{this.getTableRows()}
					</div>
				)}
			</div>
		);
	}
}
AccountListBoardOfDirectors.propTypes = {
	directors: PropTypes.array,
	contact: PropTypes.object,
	accountId: PropTypes.number,
	accountProspectingId: PropTypes.string,
	accountName: PropTypes.string,
	hasProspectingProTrial: PropTypes.bool
};

export const detached = AccountListBoardOfDirectors;
export default connect(mapStateToProps, mapDispatchToProps)(AccountListBoardOfDirectors);

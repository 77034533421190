'use strict';

ReactTemplates.findProspect.innerCompanyLocationRow = window.ReactCreateClass({
	onChange: function (checked) {
		var action = checked ? 'add' : 'remove';
		this.props.onChange(
			this.props.item.cityName,
			action,
			_.pluck(this.props.cities, 'cityName'),
			this.props.parent
		);
	},
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	render: function () {
		var checked = this.props.isChecked;
		var cityName = this.props.item.cityName.charAt(0).toUpperCase() + this.props.item.cityName.slice(1);
		return (
			<div className="inner-row">
				<div className="row-input" onClick={ReactTemplates.TOOLS.stopProp}>
					<ReactTemplates.checkbox
						className="toggle-sm checkbox-fix"
						checked={checked}
						onChange={this.onChange}
					/>
				</div>
				<div className="overflow-ellipsis row-name">
					<span>{cityName}</span>
				</div>
				<div className="overflow-ellipsis row-value">
					<span>{this.formatNumber(this.props.item.amount)}</span>
				</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.companyLocationRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			open: false,
			checked: false,
			data: null,
			onChange: function () {}
		};
	},
	getInitialState: function () {
		return {
			open: this.props.open
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.open !== this.props.open) {
			this.setState({
				open: nextProps.open
			});
		}
	},
	toggleOpen: function () {
		this.setState({
			open: !this.state.open
		});
	},
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	onChange: function (checked) {
		// Only set the state
		var action = !checked ? 'remove' : 'add';
		this.props.onChange(this.props.data.stateName, action, _.pluck(this.props.data.children, 'cityName'), null);
	},
	isCityChecked: function (item) {
		var self = this;
		if (!self.props.filters || !self.props.filters.CityAndState) {
			return false;
		}

		var notTheCity = _.find(self.props.filters.CityAndState.value, function (filter) {
			return filter.notEquals && filter.state === self.props.data.stateName && filter.city === item.cityName;
		});

		var thisState = _.find(self.props.filters.CityAndState.value, function (filter) {
			return (
				(filter.state === self.props.data.stateName && !filter.city) ||
				(!filter.notEquals && self.props.data.stateName === filter.state && item.cityName === filter.city) ||
				(filter.notEquals && self.props.data.stateName === filter.state && item.cityName !== filter.city)
			);
		});

		return !notTheCity && thisState;
	},
	render: function () {
		var self = this;
		var rows = null;
		var hasCheckedChild = false;
		if (self.state.open && self.props.data && Array.isArray(self.props.data.children)) {
			rows = _.chain(self.props.data.children)
				.filter(function (value) {
					var cityChecked = self.isCityChecked(value);
					var showCity =
						value.cityName.indexOf(self.props.itemSearchString) > -1 ||
						cityChecked ||
						self.props.itemSearchString === '';

					if (cityChecked) {
						hasCheckedChild = true;
					}

					return showCity;
				})
				.map(function (item, i) {
					var cityChecked = self.isCityChecked(item);
					return React.createElement(ReactTemplates.findProspect.innerCompanyLocationRow, {
						filters: self.props.filters,
						item: item,
						cities: self.props.data.children,
						parent: self.props.data.stateName,
						onChange: self.props.onChange,
						isChecked: cityChecked,
						key: item.cityName + '-' + i
					});
				})
				.value();
		} else {
			for (var i = 0, j = self.props.data.children.length; i < j; i++) {
				if (self.isCityChecked(self.props.data.children[i])) {
					hasCheckedChild = true;
					break;
				}
			}
		}

		var stateName = this.props.data.stateName.charAt(0).toUpperCase() + this.props.data.stateName.slice(1);
		var intermediate = !self.props.isChecked && hasCheckedChild;

		return (
			<div className="list-row">
				<div className="outer-row" onClick={this.toggleOpen}>
					<div className="row-input" onClick={ReactTemplates.TOOLS.stopProp}>
						<ReactTemplates.checkbox
							intermediate={intermediate}
							className="toggle-sm checkbox-fix"
							checked={self.props.isChecked}
							onChange={this.onChange}
						/>
					</div>
					<div className="row-name">
						<span className="overflow-ellipsis row-name-text">{stateName}</span>
						<i
							style={{ color: '#d4d4d4' }}
							className={this.state.open ? 'fa fa-minus-square' : 'fa fa-plus-square'}
						/>
					</div>
					<div className="overflow-ellipsis row-value">{this.formatNumber(this.props.data.amount)}</div>
				</div>
				{rows}
			</div>
		);
	}
});

ReactTemplates.findProspect.companyLocationFilter = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			data: null,
			open: false,
			onChange: function () {},
			//geoSortType: '_term',
			geoSort: true,
			msBeforeSearch: 500
		};
	},
	getInitialState: function () {
		return {
			open: this.props.open,
			itemSearchString: '',
			//itemZipCode: null,
			usedZipCodes: null,
			zipCodeOptions: [],
			sort: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			sortBy: t('groupMail.sortBy'),
			popoverCompanyInfo: t('soliditet.popoverCompanyInfo'),
			locationFilter: t('advancedSearch.location'),
			alphOrder: t('soliditet.alphabeticOrder'),
			companyAmountOrder: t('default.numOfCompanies'),
			searchForLocation: t('soliditet.searchForLocation'),
			usedZipcode: t('soliditet.usedZipcode'),
			pressEnterToSearchForZipCode: t('soliditet.pressEnterToSearchForZipCode')
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var self = this;
		if (nextProps.stateAndCity !== self.props.stateAndCity) {
			nextProps.stateAndCity.then(function (data) {
				self.setState({
					data: data.data
				});
			});
		}
	},
	resizeTimeout: null,
	resizeThrottler: function () {
		var self = this;

		if (!self.resizeTimeout) {
			self.resizeTimeout = setTimeout(function () {
				self.resizeTimeout = null;
				self.calculateHeight();
				self.setState({});
			}, 66);
		}
	},
	height: null,
	calculateHeight: function () {
		var sidebar = document.getElementById('find-prospects-sidebar');
		if (sidebar) {
			this.height = sidebar.offsetHeight - 318;
		}
	},
	componentDidMount: function () {
		var self = this;

		self.calculateHeight();
		window.addEventListener('resize', self.resizeThrottler);

		self.props.stateAndCity.then(function (stateAndCity) {
			self.setState({
				data: stateAndCity.data
			});
		});
	},
	componentWillUnmount: function () {
		window.removeEventListener('resize', this.resizeThrottler);
	},
	toggleOpen: function () {
		var view = !this.props.open ? 'companyLocation' : undefined;
		this.props.toggleOpen(view);
	},
	toggleSort: function () {
		this.props.toggleGeoSort();
	},
	onSearchKey: function (event) {
		var value = event.target.value;
		var key = event.key;

		this.onSearch(value, key);
	},
	searchTimeout: null,
	onSearch: function (searchString, key) {
		var self = this;

		var onlyNumberRegExp = /([\s,-.])+/g;
		var trimmedValue = searchString.replace(onlyNumberRegExp, '');
		var isNumber = trimmedValue && !isNaN(parseInt(trimmedValue));

		if (key === 'Enter' && isNumber) {
			if (
				!self.props.filters.ZipCode ||
				(Array.isArray(self.props.filters.ZipCode.value) &&
					self.props.filters.ZipCode.value.indexOf(trimmedValue) === -1)
			) {
				self.props.onChange([trimmedValue], 'add', null, null, true);
			}

			var searchElement = ReactDOM.findDOMNode(this.refs['search-input']);
			searchElement.value = '';
			searchElement.blur();

			self.setState({
				itemSearchString: ''
			});
		} else {
			self.setState({
				itemSearchString: searchString
			});
		}
	},
	isChecked: function (data) {
		if (!this.props.filters.CityAndState || !this.props.filters.CityAndState.value) {
			return false;
		}

		var hasFilterOnState = _.find(this.props.filters.CityAndState.value, function (filter) {
			return filter.state === data.stateName && !filter.city;
		});

		return hasFilterOnState ? true : false;
	},
	isCityChecked: function (state, city) {
		if (!this.props.filters.CityAndState || !this.props.filters.CityAndState.value) {
			return false;
		}

		var notTheCity = _.find(this.props.filters.CityAndState.value, function (filter) {
			return filter.notEquals && filter.state === state.stateName && filter.city === city.cityName;
		});

		var thisState = _.find(this.props.filters.CityAndState.value, function (filter) {
			return (
				(filter.state === state.stateName && !filter.city) ||
				(!filter.notEquals && state.stateName === filter.state && city.cityName === filter.city) ||
				(filter.notEquals && state.stateName === filter.state && city.cityName !== filter.city)
			);
		});

		return !notTheCity && thisState;
	},
	isNumber: function (value) {
		if (!value || typeof value !== 'string') {
			return false;
		}

		var onlyNumberRegExp = /([\s,-.])+/g;
		var parsedValue = value.replace(onlyNumberRegExp, '');

		return parsedValue && !isNaN(parseInt(parsedValue));
	},
	removeZipCode: function (token) {
		this.props.onChange([token], 'remove', null, null, true);
	},
	checkNrOfFilters: function (filters) {
		var nrOfHits = 0;

		if (filters.CityAndState && filters.CityAndState.value) {
			nrOfHits += filters.CityAndState.value.length;
		}

		if (filters.ZipCode && filters.ZipCode.value) {
			nrOfHits += filters.ZipCode.value.length;
		}

		return nrOfHits ? nrOfHits : null;
	},
	deleteAllFilter: function () {
		this.setState({
			usedZipCodes: null,
			itemSearchString: ''
		});

		this.props.onChange(1, 'clearAll');
	},
	render: function () {
		var self = this;
		var rowHeaderClasses = self.props.open ? 'row-header open' : 'row-header';
		var rows = null;
		var list = null;

		//var geoOrder = this.props.geoSortType === '_term' ? this.lang.alphOrder : this.lang.companyAmountOrder;

		var searchStringIsNumber = self.isNumber(self.state.itemSearchString);

		if (this.props.open) {
			if (Array.isArray(self.state.data)) {
				rows = _.chain(self.state.data)
					.filter(function (state) {
						if (!self.state.itemSearchString.length || searchStringIsNumber) {
							return true;
						}

						var checked = self.isChecked(state);
						var searchKey = state.stateName;
						var children = _.filter(state.children, function (ff) {
							return (
								ff.cityName.toLowerCase().indexOf(self.state.itemSearchString.toLowerCase()) > -1 ||
								self.isCityChecked(state, ff)
							);
						});

						return searchKey.indexOf(self.state.itemSearchString) > -1 || checked || children.length > 0;
					})
					.map(function (stateItem, i) {
						var isChecked = self.isChecked(stateItem);
						return React.createElement(ReactTemplates.findProspect.companyLocationRow, {
							data: stateItem,
							open: self.state.itemSearchString && !searchStringIsNumber ? true : false,
							filters: self.props.filters,
							onChange: self.props.onChange,
							key: stateItem.stateName + '-' + i,
							itemSearchString: searchStringIsNumber ? '' : self.state.itemSearchString,
							isChecked: isChecked
						});
					})
					.value();
			} else {
				rows = (
					<div style={{ width: '100%', padding: '5px 15px', textAlign: 'center' }} key="loading">
						<i className="fa fa-refresh fa-spin fa-fw" />
					</div>
				);
			}

			list = [];

			var zipCodeTokens = null;

			if (
				self.props.filters.ZipCode &&
				Array.isArray(self.props.filters.ZipCode.value) &&
				self.props.filters.ZipCode.value.length
			) {
				var tokens = _.map(self.props.filters.ZipCode.value, function (token) {
					return (
						<span key={token} className="label label-default zipcode-tokens">
							<span>{token}</span>
							<i className="fa fa-times" onClick={self.removeZipCode.bind(self, token)} />
						</span>
					);
				});

				zipCodeTokens = (
					<div className="zipcode-filters" style={{ marginTop: '10px', lineHeight: '25px' }}>
						<div style={{ paddingBottom: '3px' }}>
							{self.lang.usedZipcode}
							{':'}
						</div>
						{tokens}
					</div>
				);
			}

			list.push(
				<div key="add-zipcode" className="form-group" style={{ position: 'relative' }}>
					<input
						ref="search-input"
						style={{ borderRadius: '2px' }}
						className="form-control"
						onKeyUp={this.onSearchKey}
						type="text"
						placeholder={self.lang.searchForLocation}
					/>
					{zipCodeTokens}
					{searchStringIsNumber && (
						<div
							className="input-dropdown"
							onClick={self.onSearch.bind(self, self.state.itemSearchString, 'Enter')}
						>
							{self.lang.pressEnterToSearchForZipCode}
						</div>
					)}
				</div>
			);

			var deleteBtn = null;
			if (
				(self.props.filters.CityAndState && self.props.filters.CityAndState.value.length) ||
				(self.props.filters.ZipCode && self.props.filters.ZipCode.value.length)
			) {
				deleteBtn = (
					<div className="delete-btn">
						<button className="up-btn btn-grey btn-link btn-hover-red" onClick={self.deleteAllFilter}>
							<i className="fa fa-trash" />
						</button>
					</div>
				);
			}

			list.push(
				<div key="sort-location" id="sort-location">
					<span>
						{self.lang.sortBy}
						{':'}
					</span>
					<span className="sort-toggle" onClick={self.toggleSort}>
						<span>{this.lang.companyAmountOrder}</span>
						<i className={self.props.geoSort ? ' fa fa-caret-down' : 'fa fa-caret-up'} />
					</span>
					{deleteBtn}
				</div>
			);

			var rowContainerStyle = {
				width: '100%',
				padding: '5px 15px',
				paddingBottom: '15px',
				overflowY: 'scroll',
				maxHeight: this.height,
				borderBottom: '1px solid rgba(0,0,0,0.05)',
				borderTop: '1px solid rgba(0,0,0,0.05)'
			};

			list.push(
				<div key="location-data" style={rowContainerStyle}>
					{rows}
				</div>
			);
		}

		var value = self.checkNrOfFilters(self.props.filters);

		return (
			<div key="location-filter" id="company-type-filter">
				<div className={rowHeaderClasses} onClick={self.toggleOpen}>
					<span className="row-header-icon-wrap">
						<i className="fa fa-map-marker row-header-icon" />
					</span>

					<span className="row-header-title">{self.lang.locationFilter}</span>
					<span className="row-header-value">
						<span>{value}</span>
					</span>
				</div>

				{list}
			</div>
		);
	}
});

'use strict';

ReactTemplates.table3 = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var self = this;
		var t = Tools.$translate;
		this.lang = {
			tryChangeFilters: t('noMatches.tryChangeFilters'),
			addScriptToGetVisits: t('noMatches.addScriptToGetVisits')
		};

		this.udoMap = {};
		_.each(Tools.AppService.getMetadata().params.UserDefinedObject, function (udo) {
			self.udoMap[udo.id] = udo.name;
		});
	},
	getSort: function (val, type, id, time) {
		if (val === null || val === undefined) {
			return null;
		}

		if (type === 'Date' && typeof val === 'string') {
			val = new Date(val);
		}

		if (type === 'Date' && val.getTime) {
			if (this.props.template !== 'activityRow') {
				return val.getTime();
			}

			// Solution if you want activities in ascending order with no time last
			// if(this.props.currentSorting.ascending){
			// 	if(!time){
			// 		val.setHours(23);
			// 		val.setMinutes(59);
			// 	}
			// 	return val.getTime();
			// }

			var hours = val.getHours();
			var minutes = val.getMinutes();

			if (!time) {
				hours = 23;
				minutes = 59;
			}

			var year = val.getFullYear() - 1969;
			var month = val.getMonth() + 1;
			var day = val.getDate();
			var dd = (year * 10000 + month * 100 + day) * 10000;
			var sortVal = (dd + (2359 - hours * 100 - minutes)) * 1000000 + id;

			return sortVal;
		}

		if (type === 'String' && val.toLowerCase) {
			return val.toLowerCase();
		}

		if ((type === 'Currency' || type === 'Percent' || type === 'Discount') && !isNaN(parseFloat(val))) {
			return parseFloat(val);
		}

		if ((type === 'Number' || type === 'Integer') && !isNaN(parseInt(val))) {
			return parseInt(val);
		}

		return val;
	},
	sortFn: function (item, col) {
		var FilterHelper = this.props.tools.FilterHelper;
		var $parse = this.props.tools.$parse;
		var customFields = this.props.tools.customFields;

		if (FilterHelper.isCustom(col)) {
			var cf = FilterHelper.getCustomConfig(col, customFields);
			var mappedCustom =
				cf.filterName.indexOf('CustomAgreement_') === 0 ? item.metadata.$mappedCustom : item.$mappedCustom;

			if (!mappedCustom || mappedCustom[cf.field] === null || mappedCustom[cf.field] === undefined) {
				return null;
			}

			return this.getSort(mappedCustom[cf.field].value, cf.$field.datatype);
		} else {
			var a = $parse(col)(item);

			return this.getSort(a, this.props.attrs[col].type, item.id, item.time);
		}
	},
	visitScripClick: function (e) {
		e.target.select();
		var copied = false;
		try {
			document.execCommand('copy');
			copied = true;
		} catch (err) {
			console.log(err);
		}

		if (copied) {
			this.props.tools.NotificationService.addNotification({
				title: 'default.copiedToClipboard',
				style: this.props.tools.NotificationService.style.SUCCESS
			});
		}
	},
	doSort: function (rows) {
		// eslint-disable-line no-unused-vars
		var self = this;
		if (!this.props.currentSorting) {
			return;
		}

		var col = this.props.currentSorting.attribute;

		if (this.props.attrs[col] || this.props.tools.FilterHelper.isCustom(col)) {
			var ascending = this.props.currentSorting.ascending;
			rows = rows.sort(function (A, B) {
				var itemA = A.props.item;
				var itemB = B.props.item;

				var aValue = self.sortFn(itemA, col),
					bValue = self.sortFn(itemB, col);

				if (aValue === null) {
					return 1;
				}
				if (bValue === null) {
					return -1;
				}

				if (aValue === bValue) {
					aValue = itemA.id;
					bValue = itemB.id;
				}
				if (ascending) {
					if (typeof aValue === 'string') {
						return aValue.localeCompare(bValue, 'sv');
					}
					return aValue > bValue ? 1 : -1;
				}
				if (typeof bValue === 'string') {
					return bValue.localeCompare(aValue, 'sv');
				}
				return aValue > bValue ? -1 : 1;
			});
		}
	},
	render: function () {
		var statics = ReactTemplates.ROWSTATICS[this.props.template]
			? ReactTemplates.ROWSTATICS[this.props.template](this.props.tools)
			: {};
		var rows;

		if (this.props.loading) {
			rows = (
				<tr className="load-row">
					<td colSpan={'100'}>
						<b className="fa fa-refresh fa-spin"></b>
					</td>
				</tr>
			);
		} else if (this.props.data && this.props.data.length) {
			var now = moment();
			rows = this.props.data.map(
				function (item) {
					var rowProps = {
						key: 'row-' + item.id,
						item: item,
						columns: this.props.columns,
						tools: this.props.tools,
						statics: statics,
						now: now,
						attrs: this.props.attrs
					};
					if (this.props.templateComponent) {
						rowProps.onClick = this.props.tools.opts.onRowClick;
						return this.props.templateComponent(rowProps);
					}
					return React.createElement(ReactTemplates.ROW[this.props.template], rowProps);
				}.bind(this)
			);

			this.doSort(rows);
		} else {
			var t = Tools.$translate;
			// Show script if no visitors
			var scriptBox = null;
			if (this.props.tools.opts.type === 'visitor' && this.props.tools.opts.script) {
				scriptBox = (
					<div id="no-result-script-box">
						<textarea readOnly onClick={this.visitScripClick}>
							{this.props.tools.opts.script}
						</textarea>
						<span>
							{this.lang.addScriptToGetVisits} <ReactTemplates.elevio articleId={272} />
						</span>
					</div>
				);
			}
			var type = this.props.tools.opts.type;
			if (type === 'account') {
				type = 'client';
			}
			var h3;

			if (type.indexOf('userDefinedObject') === 0) {
				var id = type.split('userDefinedObject')[1];
				h3 = <h3>{t('noMatches.userDefinedObject', { NAME: (this.udoMap[id] || '').toLowerCase() })}</h3>;
			} else {
				h3 = <h3>{t('noMatches.' + type)}</h3>;
			}

			rows = (
				<tr className="no-result-row new no-click">
					<td colSpan={'100'}>
						<img src="img/empty-briefcase.png" />
						{h3}
						<p>{this.lang.tryChangeFilters}</p>
						{scriptBox}
					</td>
				</tr>
			);
		}

		var classes = 'main-table';

		if (this.props.sticky) {
			classes += ' fixed-header';
		}

		if (this.props.tools.opts && this.props.tools.opts.infoRow) {
			if (!rows.length) {
				rows = [];
			}
			rows.unshift(
				React.createElement(ReactTemplates.ROW[this.props.tools.opts.infoRow + 'InfoRow'], {
					closeRow: this.props.tools.opts.closeInfoRow,
					tools: this.props.tools
				})
			);
		}

		return (
			<table className={classes} key="main-table">
				<ReactTemplates.TOOLS.thead
					loading={this.props.loading}
					data={this.props.data}
					actions={this.props.actions}
					template={this.props.template}
					columns={this.props.columns}
					tools={this.props.tools}
					columnTemplates={this.props.columnTemplates}
					currentSorting={this.props.currentSorting}
					length={this.props.data.length}
					categoryTypes={this.props.categoryTypes}
				/>

				<tbody key="main-tbody">{rows}</tbody>
			</table>
		);
	}
});

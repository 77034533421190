import React from 'react';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline, Text, Button, Icon, Card, Textarea, Checkbox, Label, Row } from '@upsales/components';
import { saveFeedback, saveFeedbackAndSwapRole } from 'Store/reducers/DesiredOutcomeReducer';
import T from '../Helpers/translate';
import getAngularModule from '../../angularHelpers/getAngularModule';
import './Feedback.scss';

const mapStateToProps = state => ({
	desiredOutcomes: state.DesiredOutcome.desiredOutcomes,
	desiredOutcomesUser: state.DesiredOutcome.desiredOutcomesUser,
	saving: state.DesiredOutcome.saving
});

const mapDispatchToProps = {
	saveFeedback,
	saveFeedbackAndSwapRole
};

const classes = new bemClass('DesiredOutcomeFeedback');

const GridItem = ({ icon, text, onClick, selected }) => {
	const cardColor = selected ? 'bright-blue' : 'white';
	const textColor = selected ? 'white' : 'grey-10';

	return (
		<Card color={cardColor} onClick={onClick} className={classes.elem('gridItem').mod({ selected }).b()}>
			<div>{icon}</div>
			<Text color={textColor} size="xl">
				{text}
			</Text>
		</Card>
	);
};

class Feedback extends React.PureComponent {
	constructor(p) {
		super(p);

		const T = getAngularModule('$translate');

		const { desiredOutcomesUser, desiredOutcomes } = this.props;

		this.options = [
			[
				{ icon: '🤦‍', text: T('desiredoutcome.no1') },
				{ icon: '🤷‍', text: T('desiredoutcome.maby1') },
				{ icon: '🙌', text: T('default.yes') + '!' }
			],
			[
				{ icon: '🥶', text: T('desiredoutcome.no2') },
				{ icon: '😒', text: T('desiredoutcome.maby2') },
				{ icon: '😄', text: T('default.absolutely') }
			],
			[
				{ icon: '👾', text: T('default.no') },
				{ icon: '😑', text: T('desiredoutcome.maby3') },
				{ icon: '🎉', text: T('default.yes') }
			],
			[
				{ icon: '⛈', text: T('desiredoutcome.no3') },
				{ icon: '🌥', text: T('desiredoutcome.maby4') },
				{ icon: '☀️', text: T('default.yes') }
			],
			[
				{ icon: '👎', text: T('desiredoutcome.no2') },
				{ icon: '👊', text: T('desiredoutcome.maby1') },
				{ icon: '👍', text: T('default.yes') }
			],
			[
				{ icon: '🤖', text: T('default.no') },
				{ icon: '😑', text: T('desiredoutcome.maby2') },
				{ icon: '🥳', text: T('default.yes') }
			]
		];

		this.state = {
			currentIndex: 0,
			answers: []
		};

		if (desiredOutcomesUser) {
			const AppService = getAngularModule('AppService');
			const userName = AppService.getSelf().name;
			const selectedRole = _.find(desiredOutcomes, { id: desiredOutcomesUser.roleId });

			this.state.questions = desiredOutcomesUser.desiredOutcomes.reduce((res, desiredOutcomeId) => {
				const selectedOutCome = _.find(selectedRole.desiredOutcomes, { id: desiredOutcomeId });

				if (selectedOutCome) {
					res.push({
						desiredOutcomeId,
						title: T(selectedOutCome.feedbackTitle, { userName }),
						subtitle: T(selectedOutCome.feedbackSubtitle, { userName })
					});
				}
				return res;
			}, []);
		} else {
			this.state.questions = [];
		}

		this.lang = {
			lastQuestionTitle: T('desiredoutcome.finalQuestionTitle'),
			lastQuestionSubtitle: T('desiredoutcome.finalQuestionSubtitle'),
			notLikelyAtAll: T('default.notLikelyAtAll'),
			extremelyLikely: T('default.extremelyLikely'),
			changeRoleAndGoals: T('desiredoutcome.changeRoleAndGoals'),
			startUsingUpsales: T('desiredoutcome.outcome.startUsingUpsales'),
			placeholder: T('default.writeHere'),
			anyAdditionalThoughts: T('desiredoutcome.anyAdditionalThoughts')
		};
	}

	updateAnswer = (desiredOutcomeId, key, value, incrementCurrentIndex = false) => {
		const currentIndex = this.state.currentIndex;
		const answers = [...this.state.answers];

		const answer = answers[currentIndex]
			? { ...answers[currentIndex] }
			: { desiredOutcomeId, value: null, notes: '' };
		answer[key] = value;
		answers[currentIndex] = answer;
		const nextState = { answers };

		if (incrementCurrentIndex) {
			nextState.currentIndex = currentIndex + 1;
		}

		this.setState(nextState);
	};

	selectValue = (desiredOutcomeId, value, incrementCurrentIndex = false) => {
		this.updateAnswer(desiredOutcomeId, 'value', value, incrementCurrentIndex);
	};

	writeNote = event => {
		this.updateAnswer(0, 'notes', event.target.value);
	};

	goBack = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	saveFeedback = () => {
		this.props.saveFeedback(this.state.answers);
	};

	saveFeedbackAndSwapRole = () => {
		this.props.saveFeedbackAndSwapRole(this.state.answers);
	};

	getQuestionContent = desiredOutcomeId => {
		const currentIndex = this.state.currentIndex;
		const option = this.options[currentIndex];
		const selectedValue = this.state.answers[currentIndex] ? this.state.answers[currentIndex].value : null;

		return (
			<div className={classes.elem('grid').b()}>
				{[0, 1, 2].map(i => (
					<GridItem
						key={i}
						selected={selectedValue === i}
						onClick={this.selectValue.bind(null, desiredOutcomeId, i, true)}
						icon={option[i].icon}
						text={T(option[i].text)}
					/>
				))}
			</div>
		);
	};

	getLastQuestionContent = () => {
		const saving = this.props.saving;
		const currentIndex = this.state.currentIndex;
		const answer = this.state.answers[currentIndex] || { value: null, notes: '' };
		const saveDisabled = answer.value === null || (answer.notes && answer.notes.length > 256) ? true : false;
		const saveBtnColor = saveDisabled ? 'light-grey' : 'bright-blue';

		return (
			<div className={classes.elem('last-question-wrap').b()}>
				<div className={classes.elem('checkbox-wrap').b()}>
					<Row>
						<Text size="lg" color={'grey-10'}>
							{'1'}
						</Text>
						<Text size="lg" color={'grey-10'}>
							{'10'}
						</Text>
					</Row>
					<Row>
						{_.times(10, i => {
							const checked = answer.value === i;
							return <Checkbox key={i} onChange={() => this.selectValue(0, i)} checked={checked} />;
						})}
					</Row>
					<Row>
						<Text color={'grey-10'}>{this.lang.notLikelyAtAll}</Text>
						<Text color={'grey-10'}>{this.lang.extremelyLikely}</Text>
					</Row>
				</div>
				<Label value={answer.notes} maxLength={256}>
					{this.lang.anyAdditionalThoughts}
				</Label>
				<Textarea placeholder={this.lang.placeholder} value={answer.notes} onChange={this.writeNote} />
				<Button
					loading={saving}
					disabled={saveDisabled}
					color={saveBtnColor}
					size="lg"
					block={true}
					onClick={this.saveFeedback}
				>
					{this.lang.startUsingUpsales}
				</Button>
				<Button
					type="link"
					disabled={saveDisabled || saving}
					block={true}
					onClick={this.saveFeedbackAndSwapRole}
				>
					{this.lang.changeRoleAndGoals}
				</Button>
			</div>
		);
	};

	render() {
		const { questions, currentIndex } = this.state;

		const nrQuestions = questions.length + 1;

		const currentQuestion = questions[currentIndex];
		const title = currentQuestion ? currentQuestion.title : this.lang.lastQuestionTitle;
		const subtitle = currentQuestion ? currentQuestion.subtitle : this.lang.lastQuestionSubtitle;

		const content = currentQuestion
			? this.getQuestionContent(currentQuestion.desiredOutcomeId)
			: this.getLastQuestionContent();

		return (
			<div className={classes.b()}>
				<div className={classes.elem('chevron-container').b()}>
					{currentIndex > 0 && <Icon onClick={this.goBack} color="bright-blue" name="chevron-left" />}
				</div>
				<div className={classes.elem('content').b()}>
					<div className={classes.elem('text').b()}>
						<Headline size="sm">{title}</Headline>
						<Text center={true} className={classes.elem('subtitle').b()} color="grey-10">
							{subtitle}
						</Text>
					</div>
					{content}
					{nrQuestions > 1 ? (
						<div className={classes.elem('progress-wrap').b()}>
							{_.times(nrQuestions, i => (
								<div
									key={i}
									className={classes
										.elem('progress')
										.mod({ current: i === currentIndex })
										.b()}
								/>
							))}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

Feedback.defaultProps = {};

export const detached = Feedback;

const Component = connect(mapStateToProps, mapDispatchToProps)(Feedback);

export default Component;

import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import './AppOnboardingCompanyConfirm.scss';
import { Row, Column, Headline, Block, Text, Button, Icon, Tooltip } from '@upsales/components';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { getDefaultCurrencyFromProspectingId } from 'App/services/prospectingService';

type AppOnboardingCompanyConfirmProps = {
	visible: boolean;
	onBack: () => void;
	onConfirm: () => void;
	companyData: ProspectingClient | null;
};

const bar = (
	classes: BemClass,
	highestValue: number,
	baselinePercent: number,
	scaleRange: number,
	value: number,
	color: string
) => {
	const valuePercent = (value / scaleRange) * 100;
	return (
		<Block
			className={classes.elem('result-bar').b()}
			backgroundColor={color}
			style={{
				bottom: value < 0 ? '' : `${baselinePercent}%`,
				top: value < 0 ? `${(highestValue / scaleRange) * 100}%` : '',
				left: color === 'green' ? 'initial' : '4px',
				right: color !== 'green' ? 'initial' : '4px',
				height: `${Math.abs(valuePercent)}%`
			}}
		/>
	);
};

const AppOnboardingCompanyConfirm = ({ visible, onBack, companyData, onConfirm }: AppOnboardingCompanyConfirmProps) => {
	const [sortedAccountData, setSortedAccountData] = useState<ProspectingClient['accounts']>([]);
	const [currency, setCurrency] = useState<string>('SEK');

	let lowestValue = 0;
	let highestValue = 0;
	useEffect(() => {
		if (companyData) {
			setCurrency(getDefaultCurrencyFromProspectingId(companyData.prospectingId));
			setSortedAccountData(
				companyData.accounts
					.sort((a, b) => {
						return moment(a.dateTo) < moment(b.dateTo) ? 1 : -1;
					})
					.slice(0, 5)
			);
		}
	}, [companyData?.prospectingId]);

	sortedAccountData.forEach((a, b) => {
		lowestValue = Math.min(lowestValue, a.plNetOperatingIncome, a.plProfLossAfterFinItems);
		highestValue = Math.max(highestValue, a.plNetOperatingIncome, a.plProfLossAfterFinItems);
	});

	const classes = new BemClass('AppOnboardingCompanyConfirm');
	const scaleRange = Math.abs(lowestValue) + Math.abs(highestValue);
	const baselinePercent = (Math.abs(lowestValue) / scaleRange) * 100;

	return (
		<div className={classes.b()}>
			<SlideFade visible={visible} direction="top" delayInMs={1000} delayOutInMs={0}>
				<div className={classes.elem('arrow').b()}>
					<img src="../../img/arrow-down-left.svg" />
					<Text color="grey-11">{T('onboarding.erp.oneOfAllYouCanFindWithUpsales')}</Text>
				</div>
			</SlideFade>
			<Row>
				<Column>
					<Block space="mbxl">
						<Headline>{T('onboarding.erp.isThisYourCompany')}</Headline>
					</Block>

					<Button size="xl" block onClick={() => onConfirm()}>
						<Icon name="check" space="mrs" />
						{T('onboarding.erp.yesItIsMyCompany')}
					</Button>
					<Button size="lg" block type="link" onClick={() => onBack()}>
						{T('onboarding.erp.noChangeSearch')}
					</Button>
				</Column>
				<Column fixedWidth={40} />
				<Column>
					<SlideFade direction="right" visible={visible} delayInMs={800} delayOutInMs={0}>
						<Block
							className={classes.elem('company-info').b()}
							backgroundColor="white"
							border="s"
							borderColor="grey-6"
							space="plxl prxl ptxl pbxl"
						>
							<Block space="mbm">
								<Headline size="xs">{companyData?.name}</Headline>
							</Block>
							<Block space="mbxl">
								<Text color="grey-11">
									{companyData?.visitingCountry ? (
										<b
											className={`flag-icon flag-icon-${companyData?.visitingCountry.toLowerCase()}`}
										/>
									) : null}
									{!companyData?.visitingCountry && companyData?.postCountry ? (
										<b className={`flag-icon flag-icon-${companyData.postCountry.toLowerCase()}`} />
									) : null}
									{companyData?.sniText}
								</Text>
							</Block>
							<Block space="mbxl">
								<Row>
									<Column>
										<Text size="lg">
											{companyData?.visitingTown || companyData?.postTown || '-'}
										</Text>
										<Text color="grey-11" size="sm">
											{T('address.city2')}
										</Text>
									</Column>
									<Column>
										<Text size="lg">{companyData?.noEmployees || '-'}</Text>
										<Text color="grey-11" size="sm">
											{T('default.employees')}
										</Text>
									</Column>
									<Column>
										<Text size="lg">{companyData?.groupSize}</Text>
										<Text color="grey-11" size="sm">
											{T('onboarding.erp.companiesInTheGroup')}
										</Text>
									</Column>
								</Row>
							</Block>
							{scaleRange && sortedAccountData.length ? (
								<Row>
									<Column>
										<Block className={classes.elem('legend').b()} space="mbxl">
											<Block
												className={classes.elem('legend-block').b()}
												backgroundColor="grey-8"
											/>
											<Text size="lg">
												{currencyFormat(
													sortedAccountData[0].plNetOperatingIncome ?? 0,
													currency
												)}
											</Text>
											<Text color="grey-11" size="sm">
												{`${T('default.turnover')} ${moment(sortedAccountData[0].dateTo).format(
													'YYYY'
												)}`}
											</Text>
										</Block>
										<Block className={classes.elem('legend').b()}>
											<Block
												className={classes.elem('legend-block').b()}
												backgroundColor="green"
											/>
											<Text size="lg">
												{currencyFormat(sortedAccountData[0].plProfLossAfterFinItems, currency)}
											</Text>
											<Text color="grey-11" size="sm">
												{`${T('default.profit')} ${moment(sortedAccountData[0].dateTo).format(
													'YYYY'
												)}`}
											</Text>
										</Block>
									</Column>
									<Column size={7}>
										<Row>
											{sortedAccountData.map((a, i) => (
												<Column key={a.dateTo} align="center">
													<Tooltip
														title={`${moment(sortedAccountData[i].dateTo).format(
															'YYYY'
														)}\n<b class="Icon Icon-circle Icon--grey-8" style="font-size:10px"></b> ${currencyFormat(
															sortedAccountData[i].plNetOperatingIncome ?? 0,
															currency
														)}\n<b class="Icon Icon-circle Icon--green" style="font-size:10px"></b> ${currencyFormat(
															sortedAccountData[i].plProfLossAfterFinItems ?? 0,
															currency
														)}`}
														theme="white"
													>
														<Block className={classes.elem('chart').b()}>
															<div
																className={classes.elem('baseline').b()}
																style={{ bottom: `${baselinePercent}%` }}
															/>
															{bar(
																classes,
																highestValue,
																baselinePercent,
																scaleRange,
																a.plNetOperatingIncome,
																'grey-8'
															)}
															{bar(
																classes,
																highestValue,
																baselinePercent,
																scaleRange,
																a.plProfLossAfterFinItems,
																'green'
															)}
														</Block>
														<Text color="grey-11" size="sm">
															{moment(a.dateTo).format('YYYY')}
														</Text>
													</Tooltip>
												</Column>
											))}
										</Row>
									</Column>
								</Row>
							) : null}
						</Block>
					</SlideFade>
				</Column>
			</Row>
		</div>
	);
};

export default AppOnboardingCompanyConfirm;

import { Block, Card, Link, Text, Title } from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';
import StatuspageResource, { Incident } from 'App/resources/Statuspage';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import logError from 'Helpers/logError';
import { useTranslation } from 'Components/Helpers/translate';
import { dateCalendar } from 'App/helpers/DateHelpers';

type IncidentBlockProps = {
	incident: Incident | null;
	scheduled?: boolean;
};

const IncidentBlock = ({ incident, scheduled }: IncidentBlockProps) => {
	const { t } = useTranslation();
	return incident ? (
		<Card color={scheduled ? 'bright-blue' : 'yellow-3'} borderRadius space="ptxl prxl pbxl plxl">
			<Block space="mbm">
				<Title color={scheduled ? 'white' : 'black'}>{incident.name}</Title>
				<Text color={scheduled ? 'white' : 'yellow-6'} size="sm">
					{scheduled ? null : dateCalendar(new Date(incident.updated_at), true, 'L LT', false)}
				</Text>
			</Block>
			<Text color={scheduled ? 'white' : 'yellow-6'}>
				{incident.incident_updates[0].body}{' '}
				<Link href={incident.shortlink} target="_blank" color={scheduled ? 'white' : undefined}>
					{t('beta.readMoreHere')}
				</Link>
			</Text>
		</Card>
	) : null;
};

const LoginAppStatus = () => {
	const getPageRequest = useRef<null | CancelablePromise<any>>(null);
	const getUnresolvedRequest = useRef<null | CancelablePromise<any>>(null);
	const getPlannedRequest = useRef<null | CancelablePromise<any>>(null);
	const [unresolvedIncident, setUnresolvedIncident] = useState<null | Incident>(null);
	const [scheduledIncident, setScheduledIncident] = useState<null | Incident>(null);

	useEffect(() => {
		// Get page to check current status
		getPageRequest.current = makeCancelable(StatuspageResource.getPage());

		// Get any scheduled incidents
		getPlannedRequest.current = makeCancelable(StatuspageResource.getScheduledIncidents());
		getPlannedRequest.current.promise
			.then(incidents => setScheduledIncident(incidents[0]))
			.catch(e => logError(e, 'Failed to fetch getUnresolvedIncidents from statuspage'));

		getPageRequest.current.promise
			.then(page => {
				// If we have any issues, get unresolved incidents
				if (page.status !== 'operational') {
					getUnresolvedRequest.current = makeCancelable(StatuspageResource.getUnresolvedIncidents());

					getUnresolvedRequest.current.promise
						.then(incidents => setUnresolvedIncident(incidents[0]))
						.catch(e => logError(e, 'Failed to fetch getUnresolvedIncidents from statuspage'));
				}
			})
			.catch(err => {
				logError(err, 'Failed to get statuspage page');
			});

		return () => {
			getPageRequest.current?.cancel();
			getUnresolvedRequest.current?.cancel();
			getPlannedRequest.current?.cancel();
		};
	}, []);

	const hasAnyIncidents = !!unresolvedIncident || !!scheduledIncident;

	return hasAnyIncidents ? (
		<Block space={unresolvedIncident || scheduledIncident ? 'mbxl' : undefined}>
			<Block space={unresolvedIncident && scheduledIncident ? 'mbxl' : undefined}>
				<IncidentBlock incident={scheduledIncident} scheduled />
			</Block>
			<IncidentBlock incident={unresolvedIncident} />
		</Block>
	) : null;
};

export default LoginAppStatus;

'use strict';

angular.module('services.notifications', []).service('NotificationService', function () {
	var instance = {
		addNotification: function (config) {
			return window.NotificationService.add(config);
		},
		updateNotification: function (config) {
			window.NotificationService.update(config);
		},
		removeNotification: function (id) {
			window.NotificationService.remove(id);
		},
		style: window.NotificationService.style
	};

	return instance;
});

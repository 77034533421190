import React from 'react';
import PropTypes from 'prop-types';
import { Card, Text, Checkbox } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import Order from 'App/resources/Model/Order';
import { SalesCoachChecklistItem } from 'App/resources/Model/SalesCoach';

type Props = {
	isWarning: boolean;
	checklistItem: SalesCoachChecklistItem;
	opportunity: Order;
	overrideOpportunity: (obj: {}) => void;
	disabled?: boolean;
};

class OpportunityRowCustom extends React.Component<Props> {
	static propTypes = {
		checklistItem: PropTypes.object.isRequired,
		opportunity: PropTypes.object.isRequired,
		overrideOpportunity: PropTypes.func.isRequired,
		isWarning: PropTypes.bool.isRequired,
		disabled: PropTypes.bool
	};

	onChange = (value: boolean) => {
		const { opportunity, overrideOpportunity, checklistItem } = this.props;
		if (Array.isArray(opportunity.checklist)) {
			const checklist = value
				? [...opportunity.checklist, { id: checklistItem.id }]
				: opportunity.checklist.filter(orderChecklistItem => orderChecklistItem.id !== checklistItem.id);

			overrideOpportunity({ checklist });
		} else {
			const checklist = value ? [{ id: checklistItem.id }] : [];
			overrideOpportunity({ checklist });
		}
	};

	render() {
		const classes = new bemClass('OpportunityRow');
		const { isWarning, checklistItem, disabled } = this.props;

		return (
			<Card
				className={classes.mod({ clickable: !disabled }).b()}
				onClick={disabled ? undefined : () => this.onChange(isWarning)}
			>
				<Checkbox disabled={disabled} space="mll" size="sm" checked={!isWarning} />

				<div className={classes.elem('con').b()}>
					<Text className={classes.elem('description').b()}>{checklistItem.title}</Text>
				</div>
			</Card>
		);
	}
}

export default OpportunityRowCustom;

'use strict';

angular.module('domain.scoreboard').controller('SelectUser', [
	'$scope',
	'$modalParams',
	'$translate',
	'Event',
	'$q',
	'RequestBuilder',
	'Account',
	'AppService',
	function ($scope, $modalParams, $translate, Event, $q, RequestBuilder, Account, AppService) {
		var client, object;
		$scope.properties = {};

		$scope.save = function () {
			// Do all saving of lead and assign and close modal? also update listView
			var customerId = $modalParams.customerId;
			var user = _.find($modalParams.users, { id: parseInt($scope.properties.user) });
			var metadata = AppService.getMetadata();

			if (!user) {
				return $scope.reject('No user found!');
			}

			var eventFilter = new RequestBuilder();
			eventFilter.addFilter(Event.attr.clientId, eventFilter.comparisonTypes.Equals, client.id);
			eventFilter.addFilter(Event.attr.date, eventFilter.comparisonTypes.LessThanEquals, moment().format());
			eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Form', 'Visit']);
			eventFilter.addFilter(Event.attr.subType, eventFilter.comparisonTypes.NotEquals, ['send']);

			eventFilter.addSort(Event.attr.date, false);
			eventFilter.addSort(Event.attr.entityType, false);

			eventFilter.limit = 8;
			eventFilter.offset = 0;

			Event.customer(customerId)
				.find(eventFilter.build())
				.then(function (res) {
					var eventsData, visitsLeft;
					eventsData = _.groupBy(res.data, 'entityType');

					if (eventsData.Visit && eventsData.Visit.length > 5) {
						visitsLeft = eventsData.Visit.splice(5, eventsData.Visit.length);
						eventsData.visitLeftOver = visitsLeft.length;
					}

					var params = {
						activityDescription: $translate.instant('default.from') + ' ' + metadata.user.name,
						events: Object.keys(eventsData).length ? eventsData : null
					};

					if (client.assigned && client.assigned.user && client.assigned.user.id === user.id) {
						return $scope.resolve(user);
					}

					Account.customer(customerId)
						.assign({ id: client.id }, { id: user.id }, params)
						.then(function () {
							$scope.resolve(user);
						});
				});
		};

		var init = function () {
			object = $modalParams.object;
			client = $modalParams.clientField ? object[$modalParams.clientField] : object; // find client
			if (client.assigned && client.assigned.user) {
				$scope.properties.user = client.assigned.user.id;
			}
		};

		init();
	}
]);

'use strict';
import AdminRoot from '../../components/react/admin/root.jsx';
import { Title, Row, Icon, Text } from '@upsales/components';
import openModal from 'App/services/Modal';

angular.module('upDirectives').directive('upAdminRoot', [
	'$translate',
	'$upModal',
	'EditorCk',
	'$datepicker',
	'$timepicker',
	'$rootScope',
	'CountryCodes',
	'AppService',
	'$location',
	'ActivityType',
	'User',
	'Order',
	'Campaign',
	'Account',
	'OrderStage',
	'RequestBuilder',
	'$q',
	'AppointmentType',
	'Role',
	'CustomField',
	'$state',
	'$filter',
	'avatarService',
	'Category',
	'$injector',
	'FeatureHelper',
	'URL',
	'API',
	function (
		$translate,
		$upModal,
		EditorCk,
		$datepicker,
		$timepicker,
		$rootScope,
		CountryCodes,
		AppService,
		$location,
		ActivityType,
		User,
		Order,
		Campaign,
		Account,
		OrderStage,
		RequestBuilder,
		$q,
		AppointmentType,
		Role,
		CustomField,
		$state,
		$filter,
		avatarService,
		Category,
		$injector,
		FeatureHelper,
		URL,
		API
	) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				var firstElement = $element[0];
				var renderTimeout;
				var staticData = {};
				var rootData = $attr.upAdminRoot;
				var tools = {
					$translate: $translate.instant,
					$upModal: $upModal,
					EditorCk: EditorCk,
					$datepicker: $datepicker,
					$timepicker: $timepicker,
					$rootScope: $rootScope,
					CountryCodes: CountryCodes,
					AppService: AppService,
					$location: $location,
					ActivityType: ActivityType,
					User: User,
					Order: Order,
					Campaign: Campaign,
					Account: Account,
					OrderStage: OrderStage,
					RequestBuilder: RequestBuilder,
					$q: $q,
					AppointmentType: AppointmentType,
					Role: Role,
					CustomField: CustomField,
					$state: $state,
					$filter: $filter,
					avatarService: avatarService,
					URL: URL,
					API: API,
					Category: Category,
					$injector: $injector,
					FeatureHelper: FeatureHelper
				};

				const t = tools.$translate;
				let masterIntegration = null,
					importTasks = null;

				const translateTask = task => {
					const entitiesWithTags = [
						'contacts',
						'currencies',
						'customers',
						'employees',
						'invoices',
						'orders',
						'products',
						'users',
						'offers'
					];

					const entities = task.entities
						.map(entity => {
							entity = entity.toLowerCase().trim();
							const foundEntity = entitiesWithTags.find(enti => enti.indexOf(entity) >= 0);

							if (foundEntity) {
								return t(`integration.import.entity.${foundEntity}`);
							}
							return entity;
						})
						.join(', ');

					switch (task.type) {
						case 'configured':
							return t('integration.import.task.configured', { entities });
						case 'drafted':
							return t('integration.import.task.drafted', { entities });
						default:
							return t('integration.import.task.normal', { entities });
					}
				};

				const body = () => (
					<div className="integration-import-modal">
						<Title size="md">{t('integration.import.title', { app: masterIntegration?.name })}</Title>
						<div className="integration-import-content">
							{(importTasks || []).map((task, i) => (
								<Row key={i} className="integration-import-modal-row">
									<div className="integration-import-modal-icon">
										<Icon name="check" color="green" />
									</div>
									<div className="integration-import-modal-text">
										<Text>{translateTask(task)}</Text>
									</div>
								</Row>
							))}
						</div>
						<Text color="grey-11" bold>
							{t('integration.import.thisCanTakeAWhile')}
						</Text>
					</div>
				);

				const openSaveModal = () => {
					if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							title: t('import.startImport'),
							body: body(),
							confirmButtonText: t('integration.import.saveAndStartImport'),
							onClose: confirmed => {
								if (confirmed) {
									rootData.onSave();
								}
							}
						});
						return;
					}

					$upModal
						.open('defaultConfirm', {
							title: 'import.startImport',
							body: window.renderToStaticMarkup(body()),
							resolveTrue: 'integration.import.saveAndStartImport',
							resolveTrueBtnClass: 'btn-bright-blue',
							no: 'default.abort'
						})
						.then(save => save && rootData.onSave())
						.catch(() => {});
				};

				const getInitialImport = () => {
					const apps = AppService.getMetadata().integrations.active;

					const isRoute = $location.$$path.includes('configure');
					const isActive = apps.some(app => app.id === masterIntegration.id);
					importTasks = masterIntegration.configParsed.importTasks;

					if (isRoute && isActive && importTasks?.length) {
						return {
							saveBtnText: t('default.continue'),
							onSave: openSaveModal,
							saving: false
						};
					}

					return false;
				};

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (!rootData) {
							return;
						}
						masterIntegration = rootData.pageData?.masterIntegration;
						const initialImport = masterIntegration && getInitialImport();

						if (firstElement) {
							ReactDOM.render(
								React.createElement(AdminRoot, {
									// Data and stuff and more stuff
									pageComponent: rootData.pageComponent,
									rootData,
									staticData,
									tools,
									initialImport
								}),
								firstElement,
								function () {}
							);
						}
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				// Update data that will not be watched
				$scope.$on('setStaticData', function (e, data) {
					staticData = data;
					render();
				});

				$scope.$on('forceRender', function () {
					render();
				});

				$scope.$watch(
					$attr.upAdminRoot,
					function (data) {
						rootData = data;
						render();
					},
					true
				);

				render();
			}
		};
	}
]);

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './NavbarAddMenu.scss';
import { Icon, Text, Row, Column } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import NavbarDropdown from '../NavbarDropdown';
import openModal from 'App/services/Modal';

// const hotkeyMap = {};

const onItemClick = type => {
	switch (type) {
		case 'client':
			if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_ACCOUNT_REACT')) {
				openModal('CreateAccount');
			} else {
				Tools.$upModal.open('createAccount', {});
			}
			break;
		case 'contact':
			Tools.$upModal.open('editContact', {});
			break;
		case 'todo':
			openDrawer('CreateTodo');
			break;
		case 'phonecall':
			openDrawer('CreateCall');
			break;
		case 'activity':
			Tools.$upModal.open('editActivity', {});
			break;
		case 'appointment':
			Tools.$upModal.open('editAppointment', {});
			break;
		case 'opportunity':
			Tools.$upModal.open('editOrder', {});
			break;
		case 'email':
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openDrawer('NewSingleMail');
			} else {
				Tools.$upModal.open('sendEmail', { type: 'mail' });
			}
			break;
		case 'utm':
			openDrawer('UtmLinkGenerator');
			break;
	}
};

const item = (type, title, icon) => ({ title, icon, type });

const getItems = () => {
	const items = [];
	const hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
	const metadata = Tools.AppService.getMetadata();
	const showEmail =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL) &&
		(metadata.user.administrator || metadata.map.mailActivated || metadata.integrations?.inits?.mail?.length);

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANIES_AND_CONTACTS)) {
		items.push(item('client', 'account.createNewCompany', 'building'));
		items.push(item('contact', 'default.createAContact', 'user'));
	}

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS)) {
		if (hasTodoList) {
			items.push(item('todo', 'todo.createTodo', 'activity'));
			items.push(item('phonecall', 'todo.planACall', 'phone'));
		}
		if (!Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
			items.push(item('activity', 'default.createAnActivity', 'activity'));
		}

		items.push(item('appointment', 'todo.bookAppointment', 'calendar'));
	}

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PIPELINE)) {
		items.push(item('opportunity', 'activity.noGoodCreateOpportunity', 'opportunity'));
	}

	if (showEmail) {
		items.push(item('email', 'default.sendEmail', 'envelope'));
	}

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.UTM_GENERATOR)) {
		items.push(item('utm', 'utm.generator', 'link'));
	}

	return items;
};

const NavbarAddMenu = () => {
	const classes = new bemClass('NavbarAddMenu');
	const items = getItems();
	return (
		<NavbarDropdown
			className={classes.b()}
			renderTrigger={({ open, setOpen }) => (
				<button className={classes.elem('trigger').mod({ open }).b()} onMouseDown={() => setOpen(!open)}>
					<Icon name="plus" />
				</button>
			)}
		>
			{({ setOpen }) =>
				items.map(item => (
					<Row
						className={classes.elem('item').b()}
						key={item.title}
						onClick={() => {
							setOpen(false);
							onItemClick(item.type);
						}}
					>
						<Column fixedWidth={50} align="center">
							<Icon name={item.icon} />
						</Column>
						<Column>
							<Text>{T(item.title)}</Text>
						</Column>
						{/* <Column fixedWidth={40} align="center">
							<div className={classes.elem('hot-key').b()}>{item.hotKey}</div>
						</Column> */}
					</Row>
				))
			}
		</NavbarDropdown>
	);
};

export default NavbarAddMenu;

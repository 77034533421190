'use strict';

const LinkButtonGroup = require('Components/Buttons/LinkButtonGroup').default;
const determineCompanyDescription = require('Components/Prospecting/utils').determineCompanyDescription;
const createGoogleSearchURL = require('Components/Prospecting/utils').createGoogleSearchURL;

angular.module('domain.campaign').controller('CampaignAccounts', [
	'$scope',
	'$state',
	'$stateParams',
	'$upModal',
	'$translate',
	'RequestBuilder',
	'Account',
	'FilterHelper',
	'AppService',
	'FeatureHelper',
	function (
		$scope,
		$state,
		$stateParams,
		$upModal,
		$translate,
		RequestBuilder,
		Account,
		FilterHelper,
		AppService,
		FeatureHelper
	) {
		var customerId;
		var currentFilter;
		var CampaignCtrl = $scope.CampaignCtrl;

		const lang = {
			website: $translate.instant('website')
		};

		$scope.LinkButtonGroup = LinkButtonGroup;

		var CampaignAccountsCtrl = this;

		CampaignAccountsCtrl.error = false;
		CampaignAccountsCtrl.limit = 50;
		CampaignAccountsCtrl.loading = false;
		CampaignAccountsCtrl.currentPage = 1;
		$scope.accountsPages = 0;

		$scope.$on('account.added', function (e, added) {
			if (added.projects && _.findIndex(added.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				const withLinkButtonProps = CampaignAccountsCtrl.mergeWithLinkButtonProps(added);
				CampaignAccountsCtrl.accounts.push(withLinkButtonProps);
				CampaignCtrl.accounts = CampaignAccountsCtrl.accounts;
			}
		});

		$scope.$on('account.updated', function (e, updated) {
			var index = _.findIndex(CampaignAccountsCtrl.accounts, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'account')) {
				const withLinkButtonProps = CampaignAccountsCtrl.mergeWithLinkButtonProps(updated);
				if (index !== -1) {
					CampaignAccountsCtrl.accounts[index] = withLinkButtonProps;
				} else {
					CampaignAccountsCtrl.accounts.push(withLinkButtonProps);
				}
			} else if (index !== -1) {
				CampaignAccountsCtrl.accounts.splice(index, 1);
			}
			CampaignCtrl.accounts = CampaignAccountsCtrl.accounts;
		});

		$scope.$on('account.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignAccountsCtrl.accounts, { id: deleted.id });

			if (index !== -1) {
				CampaignAccountsCtrl.accounts.splice(index, 1);
				CampaignCtrl.accounts = CampaignAccountsCtrl.accounts;
			}
		});

		CampaignAccountsCtrl.getAccounts = function ($page) {
			CampaignAccountsCtrl.error = false;
			CampaignAccountsCtrl.loading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Account.attr.campaigns, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addSort('name', true);
			filter.limit = CampaignAccountsCtrl.limit;
			filter.offset = CampaignAccountsCtrl.limit * ($page - 1);
			currentFilter = filter.build();

			Account.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					CampaignAccountsCtrl.loading = false;
					CampaignAccountsCtrl.accounts = res.data.map(account =>
						CampaignAccountsCtrl.mergeWithLinkButtonProps(account)
					);
					CampaignCtrl.accountsTotal = res.metadata.total;
					CampaignCtrl.accounts = CampaignAccountsCtrl.accounts;

					if (CampaignCtrl.accountsTotal !== 0) {
						$scope.accountsPages = Math.ceil(CampaignCtrl.accountsTotal / CampaignAccountsCtrl.limit);
					}
				})
				.catch(function () {
					CampaignAccountsCtrl.loading = false;
					CampaignAccountsCtrl.error = true;
				});
		};

		CampaignAccountsCtrl.addContact = function (account, e) {
			const params = { customerId, account };
			$upModal.open('editContact', params, e);
		};

		CampaignAccountsCtrl.moveAccount = function (account) {
			_.remove(account.projects, { id: CampaignCtrl.campaign.id });
			Account.customer(customerId).save({ id: account.id, campaigns: account.projects });
		};

		CampaignAccountsCtrl.mergeWithLinkButtonProps = function (account) {
			const socialButtons = [
				account.webpage
					? { service: lang.website, href: account.webpage, icon: 'globe' }
					: { service: 'Google', href: createGoogleSearchURL(account), icon: 'globe', search: true },
				{ service: 'LinkedIn', href: account.linkedin, icon: 'linkedin' }
			];

			const description = determineCompanyDescription(account);
			return {
				...account,
				linkButtonProps: {
					description,
					buttons: socialButtons
				}
			};
		};

		CampaignAccountsCtrl.openAccount = function (account) {
			$state.go('account.dashboard', { customerId: customerId, id: account.id });
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			CampaignAccountsCtrl.getAccounts(1);
			CampaignAccountsCtrl.hasInactiveAccounts = FeatureHelper.isAvailable(
				FeatureHelper.Feature.INACTIVE_COMPANIES
			);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

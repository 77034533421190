import { Checkbox } from '@upsales/components';

ReactTemplates.upFilters.filterTypes.listShort = window.ReactCreateClass({
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onSelect: function (option) {
		var value = this.props.filter.value;
		if (!value) {
			value = [];
		}

		var i = value.indexOf(option.value);
		if (option.inactive) {
			value = [];
		} else if (i !== -1) {
			value.splice(i, 1);
		} else {
			if (this.props.filter.multiple) {
				value.push(option.value);
			} else {
				value = [option.value];
			}
		}

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: value.length ? true : false,
				comparisonType: this.props.filter.comparisonType || 'Equals',
				value: value
			},
			{
				action: 'add'
			}
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true
			},
			{
				action: 'remove'
			}
		);

		ReactTemplates.TOOLS.stopProp(event);
	},

	selectAll: function () {
		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true
			},
			{
				action: 'remove'
			}
		);
	},

	formatValueString: function () {
		var value = this.props.filter.value || [];

		if (!value.length) {
			return Tools.$translate('default.all');
		}

		if (value.length === 1) {
			var filter = this.props.filter;
			var data = typeof filter.options === 'function' ? filter.options() : filter.options;
			var opt = _.find(data, { value: value[0] });
			return opt ? Tools.$translate(opt.text) : Tools.$translate('default.selectedOne').toLowerCase();
		}

		return value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
	},

	isSelected: function (opt) {
		var value = this.props.filter.value || [];
		if (!value || !value.length) {
			return !!opt.inactive;
		}

		return value.indexOf(opt.value) !== -1;
	},

	render: function () {
		var self = this;
		var filter = this.props.filter;
		var value = filter.value || [];

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		var active = !!value.length;

		var data = typeof filter.options === 'function' ? filter.options() : filter.options;

		var options = _.map(data, function (option, index) {
			var text = Tools.$translate(option.text);
			var classes = 'list-row list-row-item';

			return (
				<div
					key={'option-' + index}
					className={classes}
					onClick={function () {
						self.onSelect(option);
					}}
				>
					<Checkbox checked={self.isSelected(option)} disabled={option.inactive && !value.length} />
					{text}
				</div>
			);
		});

		var selectedValuesClass = 'selected-values pull-right';
		if (!active) {
			selectedValuesClass += ' default';
		}

		var selectAllText = null;
		if (filter.selectAllText && typeof filter.selectAllText === 'function') {
			var text = filter.selectAllText();
			if (text) {
				selectAllText = (
					<div className="list-row list-row-item" onClick={self.selectAll}>
						<Checkbox checked={!active} disabled={!active} />
						{text}
					</div>
				);
			}
		}

		return (
			<div className={active ? 'list-filters-row active-border' : 'list-filters-row'}>
				<div onClick={this.toggleExpanded} className="header">
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={selectedValuesClass}>{valueString}</span>
				</div>

				{this.state.expanded && (
					<div className="list-short-filter-wrap">
						{selectAllText}
						{options}
					</div>
				)}
			</div>
		);
	}
});

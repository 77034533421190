'use strict';

ReactTemplates.upFilters.filterTypes.listShortSingle = window.ReactCreateClass({
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onSelect: function (option) {
		var value = this.props.filter.value;
		var inactive = !!option.inactive;
		if (value === undefined) {
			value = null;
		}

		value = option.value;

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: inactive,
				comparisonType: this.props.filter.comparisonType || 'Equals',
				value: value
			},
			{
				action: 'add'
			}
		);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true,
				value: null
			},
			{
				action: 'remove'
			}
		);

		ReactTemplates.TOOLS.stopProp(event);
	},

	selectAll: function () {
		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true,
				value: null
			},
			{
				action: 'remove'
			}
		);
	},

	formatValueString: function () {
		var filter = this.props.filter;

		if (this.props.filter.value === null || filter.inactive) {
			return Tools.$translate('default.all');
		}

		var data = typeof filter.options === 'function' ? filter.options() : filter.options;
		var opt = _.find(data, { value: this.props.filter.value });
		return opt ? Tools.$translate(opt.text) : Tools.$translate('default.selectedOne').toLowerCase();
	},

	isSelected: function (opt) {
		if (this.props.filter.value === null) {
			return !!opt.inactive;
		}

		return this.props.filter.value === opt.value;
	},

	render: function () {
		var self = this;
		var filter = this.props.filter;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		var active = filter.hasOwnProperty('inactive') && !filter.inactive;

		var data = typeof filter.options === 'function' ? filter.options() : filter.options;

		var options = _.map(data, function (option, index) {
			var text = Tools.$translate(option.text);
			var classes = 'list-row list-row-item';

			return (
				<div
					key={'option-' + index}
					className={classes}
					onClick={function () {
						self.onSelect(option);
					}}
				>
					<window.CheckboxComponent size="sm" checked={self.isSelected(option)} disabled={option.inactive} />
					{text}
				</div>
			);
		});

		var selectedValuesClass = 'selected-values pull-right';
		if (!active) {
			selectedValuesClass += ' default';
		}

		var selectAllText = null;
		if (filter.selectAllText && typeof filter.selectAllText === 'function') {
			var text = filter.selectAllText();
			if (text) {
				selectAllText = (
					<div className="list-row list-row-item" onClick={self.selectAll}>
						<window.CheckboxComponent size="sm" checked={!active} disabled={!active} />
						{text}
					</div>
				);
			}
		}

		return (
			<div className={active ? 'list-filters-row active-border' : 'list-filters-row'}>
				<div onClick={this.toggleExpanded} className="header">
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={selectedValuesClass}>{valueString}</span>
				</div>

				{this.state.expanded && (
					<div className="list-short-filter-wrap">
						{selectAllText}
						{options}
					</div>
				)}
			</div>
		);
	}
});

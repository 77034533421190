'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { ActivityDateSorter } from 'Components/Helpers/Sorters';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';
import CreateTaskButton from 'App/components/CreateTaskButton';

angular.module('domain.contact').controller('ContactActivities', [
	'$scope',
	'$location',
	'$stateParams',
	'RequestBuilder',
	'ActivityList',
	'$upModal',
	'$translate',
	'$filter',
	'Mail',
	'MailBodyParser',
	'AppService',
	'$state',
	'FeatureHelper',
	'Activity',
	function (
		$scope,
		$location,
		$stateParams,
		RequestBuilder,
		ActivityList,
		$upModal,
		$translate,
		$filter,
		Mail,
		MailBodyParser,
		AppService,
		$state,
		FeatureHelper,
		Activity
	) {
		var activities;
		var ContactCtrl = $scope.ContactCtrl;
		let customerId;

		ContactCtrl.CreateTaskButton = CreateTaskButton;

		var $date = $filter('date');

		var goToDashboardIfNoData = function () {
			// If no more activities - go to dashboard
			if (!activities.length) {
				$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
			}
		};
		$scope.isTodoEnable = FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		$scope.groupable2 = {
			data: [],
			allowClear: true,
			placeholder: $translate.instant('default.groupBy')
		};

		var updatedCb = function (e, updated) {
			var activity = _.find(activities, function (obj) {
				if (!obj.to && obj.id === updated.id) {
					return true;
				}
				return false;
			});

			if (activity) {
				angular.extend(activity, updated);
				$scope.groupChange();
			}
		};

		$scope.$on('activity.updated', updatedCb);
		$scope.$on('appointment.updated', updatedCb);

		const extendActivity = activity => {
			const activityTypes = Tools.AppService.getTodoTypes();

			if (activity.type) {
				activity.icon = 'envelope-o';
			} else if (_.some(activityTypes, { id: activity.activityType?.id })) {
				if (activity.activityType.id === activityTypes.TODO.id) {
					activity.isTodo = true;
					activity.icon = 'activity';
				} else if (activity.activityType.id === activityTypes.PHONE_CALL.id) {
					activity.isPhonecall = true;
					activity.icon = 'phone';
				}
			} else if (_.some(Tools.AppService.getActivityTypes('appointment', true))) {
				activity.icon = 'calendar';
			}
			return activity;
		};

		var addedCb = function (e, added) {
			var activity = _.find(activities, function (obj) {
				if (!obj.to && obj.id === added.id) {
					return true;
				}
				return false;
			});

			if (!activity) {
				activities.push(extendActivity(added));
				$scope.groupChange();
			}
		};

		$scope.$on('activity.added', addedCb);
		$scope.$on('appointment.added', addedCb);

		$scope.$on('mail.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				added.contacts = [added.contact];
				activities.push(added);
				$scope.groupChange();
			}
		});

		$scope.createActivity = function (activity) {
			var params = {
				activity: {
					contacts: { id: ContactCtrl.contact.id }
				}
			};

			if (activity) {
				// Because editActivity fn is used in the combined lists
				if (activity.isAppointment) {
					ContactCtrl.editAppointment(activity);
					return;
				}
				params.id = activity.id;
			}

			$upModal.open('editActivity', params);
		};

		$scope.createAppointment = function (appointment) {
			var params = {
				appointment: {
					contacts: [ContactCtrl.contact]
				}
			};

			if (appointment) {
				params.id = appointment.id;
			}

			$upModal.open('editAppointment', params);
		};

		$scope.$on('mail.updated', function (e, updated) {
			var mail = _.find(activities, function (obj) {
				if (obj.id === updated.$oldId || obj.id === updated.id) {
					return true;
				}
				return false;
			});
			// Check if it should be in this list
			if (updated.contact && updated.contact.id === ContactCtrl.contact.id) {
				updated.contacts = [updated.contact];

				// Do we have it?
				if (mail) {
					angular.extend(mail, updated);
				} else {
					activities.push(updated);
				}
				$scope.groupChange();
			} else if (mail) {
				var index = _.findIndex(activities, { id: updated.id });

				if (index !== -1) {
					activities.splice(index, 1);
					$scope.groupChange();
				}
			}
		});

		var deletedCb = function (e, activity) {
			var index = _.findIndex(activities, function (obj) {
				if (!obj.to && obj.id === activity.id) {
					return true;
				}
				return false;
			});
			if (index !== -1) {
				activities.splice(index, 1);
				$scope.groupChange();
				goToDashboardIfNoData();
			}
		};

		$scope.$on('activity.deleted', deletedCb);
		$scope.$on('appointment.deleted', deletedCb);

		$scope.$on('mail.deleted', function (e, deleted) {
			var index = _.findIndex(activities, function (obj) {
				if (obj.id === deleted.id && obj.to) {
					return true;
				}
				return false;
			});
			if (index !== -1) {
				activities.splice(index, 1);
				$scope.groupChange();
				goToDashboardIfNoData();
			}
		});

		$scope.parseSubject = function (mail) {
			return MailBodyParser.parseSubject(mail);
		};

		$scope.getTitle = title => {
			if (!title || title === 'null') {
				return Tools.$translate('todo.noDate');
			}
			return Tools.$translate(title);
		};

		$scope.groupChange = function () {
			if (activities) {
				let changedActivities = activities.sort(ActivityDateSorter);
				changedActivities = $filter('groupBy')(changedActivities, $scope.grouping);
				const { closeDate, subject, type, isAppointment, date } = changedActivities[0]?.items?.[0] ?? {};

				if (
					$scope.grouping &&
					$scope.grouping.hash === 'activeInactive' &&
					(closeDate || // closed activity
						(subject !== undefined && type !== 'sch') || // email
						(isAppointment && moment().isAfter(date))) // passed appointment
				) {
					changedActivities.reverse();
				}

				// TODO (jimmy): Remove when merged Date branch
				changedActivities.forEach(function (collection) {
					collection.items.forEach(function (activity) {
						if (activity.date) {
							activity.date = new Date(activity.date);
						} // happens only to jimmy?
						if (activity.closeDate === undefined) {
							activity.closeDate = null;
						}
					});
				});
				$scope.activities = changedActivities;
				$location.search('groupBy', $scope.grouping ? $scope.grouping.hash : false);
			}
		};

		$scope.openRow = function (event, item) {
			if (angular.element(event.target).is('a,button')) {
				return;
			}

			if (item.type) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openDrawer('SentMail', { mail: { id: item.id } });
				} else {
					$upModal.open('sentMail', { customerId: $stateParams.customerId, id: item.id });
				}
			} else {
				ContactCtrl.editActivity(item);
			}
		};

		$scope.removeEmail = function (mail) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'mail.mail',
					body: 'confirm.removeScheduledEmail',
					confirmButtonText: 'default.yes',
					onClose: confirmed => {
						if (confirmed) {
							Mail.customer($scope.customerId).delete(mail);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: $translate.instant('default.remove') + ' ' + $translate.instant('mail.mail').toLowerCase(),
					body: $translate.instant('confirm.removeScheduledEmail'),
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Mail.customer($scope.customerId).delete(mail);
				});
		};

		$scope.getUsers = function (activity) {
			return _.pluck(activity.users.slice(1), 'name').join('\n');
		};

		$scope.setPriority = function (item) {
			const { priority } = item;

			return Activity.customer(customerId).save({
				id: item.id,
				priority: priority ? ACTIVITY_PRIORITY.NONE : ACTIVITY_PRIORITY.HIGH
			});
		};

		$scope.getPriorityTooltip = function (activity) {
			if (activity.priority === ACTIVITY_PRIORITY.HIGH) {
				return $translate.instant('activity.removeAsImportant');
			}
			return $translate.instant('activity.markAsImportant');
		};

		$scope.isHighPriority = function (activity) {
			return activity.priority === ACTIVITY_PRIORITY.HIGH;
		};

		var getActivities = function (delay) {
			var ms = delay ? 2000 : 1;
			$scope.tableLoading = true;

			setTimeout(function () {
				ActivityList.customer($scope.customerId)
					.find($scope.filter)
					.then(function (response) {
						activities = response.data.map(extendActivity);

						if (activities.length) {
							$scope.groupChange();
						}

						$scope.tableLoading = false;
					})
					.catch(function () {
						$scope.tableLoading = false;
					});
			}, ms);
		};

		var getGroupingFromHash = function () {
			var hash = $location.search();
			var groupBy = hash.groupBy ? hash.groupBy : 'activeInactive';

			return _.find($scope.groupable2.data, { hash: groupBy });
		};

		var printHistoryOrActivity = function (printHistory) {
			const hasNoActivities =
				FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
				FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
			const activityText = hasNoActivities ? 'default.openTasks' : 'default.openActivities';

			return printHistory ? $translate.instant('default.history') : $translate.instant(activityText);
		};

		var init = function () {
			var filter = new RequestBuilder();
			var or = filter.orBuilder();
			customerId = AppService.getCustomerId();

			or.next();
			or.addFilter({ field: 'groupMailId' }, filter.comparisonTypes.Equals, null);
			or.next();
			or.addFilter({ field: 'groupMailId' }, filter.comparisonTypes.Equals, 0);
			or.done();
			filter.addFilter({ field: 'contact.id' }, filter.comparisonTypes.Equals, ContactCtrl.contact.id);
			filter.addFilter(ActivityList.attr.projectPlan.attr.id, filter.comparisonTypes.Equals, null);
			filter.addSort(ActivityList.attr.date);
			$scope.filter = filter.build();

			$scope.userWhen = {
				other: '& {} ' + $translate.instant('filters.more').toLowerCase()
			};

			$scope.groupable2.data.push.apply($scope.groupable2.data, [
				{
					id: 1,
					hash: 'month',
					text: $translate.instant('date.month'),
					groupOn: function (obj) {
						return $date(obj.date, 'MMMM yyyy');
					}
				},
				{
					id: 2,
					hash: 'year',
					text: $translate.instant('date.year'),
					groupOn: function (obj) {
						return $date(obj.date, 'yyyy');
					}
				},
				{
					id: 3,
					hash: 'type',
					text: $translate.instant('default.activityType'),
					groupOn: function (activity) {
						// Email
						if (activity.type) {
							if (activity.type === 'sch') {
								return (
									$translate.instant('event.types.Mail') +
									' ' +
									$translate.instant('mail.scheduled').toLowerCase()
								);
							} else {
								return $translate.instant('mail.mailOut');
							}
						} else {
							// Activity
							return activity.activityType.id;
						}
					},
					textOn: 'activityType.name'
				},
				{
					id: 4,
					hash: 'activeInactive',
					text: $translate.instant('default.activeOrEnded'),
					groupOn: function (obj) {
						if (obj.isAppointment) {
							return printHistoryOrActivity(moment(obj.endDate).diff(moment()) < 0);
						} else if (obj.hasOwnProperty('subject')) {
							return printHistoryOrActivity(obj.type !== 'sch');
						} else {
							return printHistoryOrActivity(obj.closeDate);
						}
					}
				}
			]);

			$scope.grouping = getGroupingFromHash();
			getActivities();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

'use strict';

angular.module('domain.account').factory('EditAccountMeta', [
	'$q',
	'Account',
	'CustomField',
	'CustomfieldMapper',
	'User',
	'RequestBuilder',
	'NotificationService',
	'AppService',
	function ($q, Account, CustomField, CustomfieldMapper, User, RequestBuilder, NotificationService, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();

				const hasFeatureAnyAccountmanager = Tools.FeatureHelper.hasSoftDeployAccess('ANY_ACCOUNTMANAGER');
				var promises = {
					users: $q.when({
						data: hasFeatureAnyAccountmanager ? AppService.getActiveUsers() : AppService.getUsers('ACCOUNT')
					})
				};

				// Get account if we need to
				if ($modalParams.account) {
					if ($modalParams.account.id) {
						promises.account = Account.customer(customerId).get($modalParams.account.id);
					} else {
						promises.account = $q.when({ data: $modalParams.account });
					}
					$modalParams.id = $modalParams.account.id;
				} else if ($modalParams.id) {
					promises.account = Account.customer(customerId).get($modalParams.id);
				} else {
					promises.account = $q.when({ data: Account.new() });
				}

				return $q
					.all(promises)
					.then(function (response) {
						response.account.data.campaigns =
							response.account.data.campaigns || response.account.data.projects;
						response.account.data.users = response.account.data.users || [];

						// Fix for when a user are selected and no longer exist
						if (response.account) {
							response.account.data.users.forEach(function (user) {
								if (user && !_.some(response.users.data, { id: user.id })) {
									response.users.data.push(user);
								}
							});
						}

						return response;
					})
					.catch(function (err) {
						NotificationService.addNotification({
							title: 'default.error',
							body: 'openError.account',
							style: 'error',
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

'use strict';

angular.module('domain.admin').controller('Admin', [
	'AppService',
	function (AppService) {
		var Admin = this;

		var init = function () {
			Admin.ready = true; // must be undefind until now
		};

		AppService.loadedPromise.then(init);
	}
]);

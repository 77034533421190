'use strict';

ReactTemplates.mailCampaign.submits = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			noContact: t('default.noContact'),
			noFormSubmits: t('default.noFormSubmits'),
			infoSubmit: t('mailCampaign.mailToSubmit')
		};

		this.columns = [
			{ title: t('default.date'), width: 120, sortable: 'processedDate' },
			{ title: t('default.contactAndClient'), sortable: 'contact.name' },
			{ title: t('form.form'), sortable: 'form.name' },
			{ title: t('default.history'), width: 100 }
		];

		this.customerId = this.props.tools.AppService.getCustomerId();
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	renderRow: function (submit, i) {
		var tools = this.props.tools;
		var contactClient;
		var clientStatus;
		var onClick = this.props.rootData.pageData.onClick.bind(this, submit);

		if (!submit.contact) {
			contactClient = (
				<td key="contactClient">
					<i className="grey">{this.lang.noContact}</i>
				</td>
			);
		} else {
			contactClient = (
				<td key="contactClient" className="account-contact-col">
					<ReactTemplates.TOOLS.contactHref
						customerId={this.customerId}
						id={submit.contact.id}
						text={submit.contact.name}
						tools={tools}
					/>
					{submit.client ? (
						<span className="account-subtitle">
							<ReactTemplates.TOOLS.accountHref
								customerId={this.customerId}
								id={submit.client.id}
								text={submit.client.name}
								tools={tools}
							/>
						</span>
					) : null}
				</td>
			);
		}

		if (submit.client) {
			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={submit.client.hasActivity}
					activityOld={submit.client.hadActivity}
					appointment={submit.client.hasAppointment}
					appointmentOld={submit.client.hadAppointment}
					opportunity={submit.client.hasOpportunity}
					opportunityOld={submit.client.hadOpportunity}
					order={submit.client.hasOrder}
					orderOld={submit.client.hadOrder}
				/>
			);
		} else {
			clientStatus = (
				<ReactTemplates.TOOLS.leadStatus
					tools={this.props.tools}
					activity={false}
					activityOld={false}
					appointment={false}
					appointmentOld={false}
					opportunity={false}
					opportunityOld={false}
					order={false}
					orderOld={false}
				/>
			);
		}

		return (
			<tr onClick={onClick} key={'submit-' + i}>
				<ReactTemplates.TOOLS.checkboxTD tools={this.props.tools} item={submit} />

				<td key="date">{ReactTemplates.TOOLS.dateCalendar(submit.processedDate, 'L', true)}</td>

				{contactClient}

				<td key="form">
					{submit.form
						? ReactTemplates.TOOLS.withTooltip(
								<a href={'#/form-overview/' + submit.form.id} onClick={ReactTemplates.TOOLS.stopProp}>
									{submit.form.name}
								</a>,
								submit.form.name,
								{ placement: 'top' }
						  )
						: null}
				</td>

				<td key="status">{clientStatus}</td>
			</tr>
		);
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;

		var search = React.createElement(ReactTemplates.mailCampaign.tableSearch, {
			className: 'pull-right',
			onChange: rootData.pageData.onSearch,
			tools: tools
		});

		var table = React.createElement(ReactTemplates.mailCampaign.table, {
			renderRow: self.renderRow,
			columns: this.columns,
			rows: pageData.submits,
			loading: pageData.loading,
			total: pageData.total,
			multiActions: true,
			totalPages: pageData.totalPages,
			activePage: pageData.activePage,
			onChangePage: pageData.onChangePage,
			currentSorting: pageData.currentSorting,
			onSort: pageData.onSort,
			noResult: self.lang.noFormSubmits,
			tools: tools
		});

		var elevioStyle = {
			verticalAlign: 'middle',
			lineHeight: '30px',
			color: '#999'
		};

		return (
			<div>
				<div id="page-tools">
					<span style={elevioStyle}>
						{React.createElement(ReactTemplates.elevio, {
							articleId: 283,
							text: self.lang.infoSubmit,
							sidebar: true
						})}
					</span>
					{search}
					<div className="clearfix"></div>
				</div>

				{table}
			</div>
		);
	}
});

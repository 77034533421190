'use strict';

angular.module('domain.engage').factory('CreativeEditorMeta', [
	'$q',
	'AppService',
	'StandardCreative',
	'AccountProfile',
	'BannerGroup',
	function ($q, AppService, StandardCreative, AccountProfile, BannerGroup) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var promises = {
					accountProfile: AccountProfile.get()
				};

				if ($stateParams.id) {
					// get drafted creative here
					promises.bannerGroup = BannerGroup.get($stateParams.id);
				} else {
					promises.template = StandardCreative.get($stateParams.templateId);
				}

				return $q.all(promises).then(function (res) {
					if (res.template) {
						res.config = window.BannerParser.generateConfig(
							res.template.data.body,
							res.accountProfile.data
						);
					} else {
						res.config = res.bannerGroup.data;
					}

					return res;
				});
			});
		};
	}
]);

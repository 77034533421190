'use strict';

angular.module('upResources').service('ParseAccount', [
	'AppService',
	'ParseHelpers',
	'$rootScope',
	function (AppService, ParseHelpers, $rootScope) {
		var self = null;
		var categoryTypes = null;
		var categories = null;

		const clearCategoryTypes = () => {
			categoryTypes = null;
		};
		$rootScope.$on('accountCategoryType.added', clearCategoryTypes);
		$rootScope.$on('accountCategoryType.updated', clearCategoryTypes);
		$rootScope.$on('accountCategoryType.deleted', clearCategoryTypes);

		const clearCategories = () => {
			categories = null;
		};

		$rootScope.$on('category.added', clearCategories);
		$rootScope.$on('category.updated', clearCategories);
		$rootScope.$on('category.deleted', clearCategories);

		return function (account) {
			if (!categoryTypes) {
				categoryTypes = AppService.getCategoryTypes('account');
			}
			if (!categories) {
				categories = AppService.getCategories('account');
			}
			if (!self) {
				self = AppService.getSelf();
			}

			ParseHelpers.parseCategories(account, categories, categoryTypes);

			ParseHelpers.parseAddresses(account);

			account.$adCampaign = {};

			if (account.adCampaign && account.adCampaign.length) {
				for (var i = 0; i < account.adCampaign.length; i++) {
					account.$adCampaign[account.adCampaign[i].id] = account.adCampaign[i];
				}
			}

			var accountOwner = false;
			_.each(account.users, function (user) {
				if (user.id === self.id) {
					accountOwner = true;
				}
			});

			var createRights = {};
			_.each(self.createRights, function (value, type) {
				createRights[type] =
					value === 'ALL' ||
					(value === 'OWN' && accountOwner) ||
					(value === 'OWN' &&
						_.contains(['Activity', 'Appointment', 'Opportunity', 'Order', 'Agreement', 'Contact'], type));
			});

			account.createRights = createRights;
			return account;
		};
	}
]);

'use strict';

ReactTemplates.creativeTemplates.template = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			select: t('default.select'),
			contains: t('ads.contains'),
			sizes: t('ads.sizes').toLowerCase(),
			bannergroup: t('ads.bannergroup').toUpperCase()
		};
	},
	componentDidMount: function () {
		var self = this;
		var config = window.BannerParser.generateConfig(self.props.template.body, self.props.accountProfile);
		self._size.innerHTML = config.availableFormats.length;
		window.BannerParser.parse(config, self.props.template.body, self.props.accountProfile, function (err, html) {
			if (err) {
				console.log('ERROR NOOO');
				return;
			}
			html = 'data:text/html;charset=utf-8,' + html;
			var iframe = self._frame;
			if (!iframe.contentWindow) {
				return;
			}

			iframe.contentWindow.document.open();
			iframe.contentWindow.document.write(html);
			iframe.contentWindow.document.close();
		});
	},
	componentDidUpdate: function () {},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	selectTemplate: function (id) {
		this.props.selectTemplate(id);
	},
	render: function () {
		var self = this;
		var template = this.props.template;

		return (
			<div className="frame-wrap package-box cover-img no-responsive">
				<iframe
					src=""
					border="0"
					scrolling="no"
					allowTransparency="true"
					width="100%"
					height="100%"
					ref={self.setRef.bind(self, '_frame')}
				></iframe>
				<div className="overlay">
					<button
						type="button"
						className="btn up-btn btn-block btn-bright-blue no-shadow"
						onClick={self.selectTemplate.bind(self, template.id)}
					>
						{self.lang.select}
					</button>
				</div>
				<div className="package-box-footer show-on-hover">
					<div className="text-center">
						{self.lang.contains} <span ref={self.setRef.bind(self, '_size')}></span> {self.lang.sizes}
					</div>
					<div className="text-grey text-center">{self.lang.bannergroup}</div>
				</div>
			</div>
		);
	}
});

'use strict';

angular
	.module('services.$safeApply', [])
	.service('$safeApply', function () {
		return function ($scope, fn) {
			var phase = $scope.$root ? $scope.$root.$$phase : null;
			if (phase && (phase === '$apply' || phase === '$digest')) {
				if (fn && typeof fn === 'function') {
					fn();
				}
			} else {
				$scope.$apply(fn);
			}
		};
	})
	.service('$safeDigest', function () {
		return function ($scope, fn) {
			var phase = $scope.$root ? $scope.$root.$$phase : null;
			if (phase && (phase === '$apply' || phase === '$digest')) {
				if (fn && typeof fn === 'function') {
					fn();
				}
			} else {
				$scope.$digest(fn);
			}
		};
	});

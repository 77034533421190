import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, Icon, Card, Button, DropDownMenu, Title, Block } from '@upsales/components';
import { onNordicChoiseClick, onNordicMainClick, initAccount } from 'Store/reducers/BuyFromDataSourceReducer';

const mapStateToProps = (state, ownProps) => {
	const storeObj = state.BuyFromDataSourceInline.accounts[ownProps.account.id];
	return storeObj || { loading: false, accountsWithSimilarDuns: [] };
};

const mapDispatchToProps = {
	onNordicMainClick,
	onNordicChoiseClick,
	initAccount
};

class NordicButton extends React.Component {
	constructor(props) {
		super(props);

		const t = window.Tools.$translate;
		this.lang = {
			add: t('default.add'),
			doCreate: t('scoreboard.CreateAccount'),
			dunsMatchesExisting: t('BuyFromDataSourceInline.dunsMatchesExisting')
		};
	}

	componentDidMount() {
		this.props.initAccount(this.props.account);
	}

	render() {
		const {
			account,
			loading,
			accountsWithSimilarDuns,
			onNordicMainClick,
			onNordicChoiseClick,
			onConfirm
		} = this.props;

		const { add, doCreate, dunsMatchesExisting } = this.lang;

		return (
			<DropDownMenu
				renderTrigger={(isExpanded, setExpanded) => {
					return (
						<Tooltip title={doCreate} className="BuyFromDataSourceInline__nordic">
							<Button
								size="sm"
								type="link"
								onClick={event => onNordicMainClick(event, setExpanded, account).then(onConfirm)}
								loading={loading}
							>
								<Icon name="plus" style={{ marginRight: '5px' }} />
								{add}
							</Button>
						</Tooltip>
					);
				}}
			>
				{closeDropDownMenu => (
					<Card>
						<Block>
							<Block space="ptl pbl pll prl" backgroundColor="super-light-blue">
								<Title size="sm">{dunsMatchesExisting}</Title>
							</Block>
							{accountsWithSimilarDuns.map(mergeAccount => (
								<Block key={mergeAccount.id} border="bs" borderColor="grey-4">
									<Button
										shadow={false}
										color="white"
										block={true}
										onClick={() =>
											onNordicChoiseClick(closeDropDownMenu, account, mergeAccount).then(
												onConfirm
											)
										}
									>
										{mergeAccount.name}
									</Button>
								</Block>
							))}
						</Block>
					</Card>
				)}
			</DropDownMenu>
		);
	}
}

NordicButton.propTypes = {
	onNordicMainClick: PropTypes.func,
	onNordicChoiseClick: PropTypes.func,
	initAccount: PropTypes.func,
	account: PropTypes.object,
	loading: PropTypes.bool,
	accountsWithSimilarDuns: PropTypes.array,
	onConfirm: PropTypes.func
};

export const detached = NordicButton;
export default connect(mapStateToProps, mapDispatchToProps)(NordicButton);

import React, { useEffect, useState } from 'react';
import { Block, Help, Text, Title } from '@upsales/components';
import { makeCancelable } from 'Helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'Helpers/logError';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import t from 'Components/Helpers/translate';
import type ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import ProjectPlanOrderHandlerCard from './ProjectPlanOrderHandlerCard';
import { findAndReplaceOrInsertItem } from 'App/babel/store/helpers/array';
import { ProjectPlanOption } from 'App/resources/Model/Order';
import Product from 'App/resources/Model/Product';
import './ProjectPlanOrderHandler.scss';

type Props = {
	onChange: (value: ProjectPlanOption[]) => void;
	projectPlanOptions: ProjectPlanOption[];
	products: Product[];
};

const ProjectPlanOrderHandler = ({ onChange, products, projectPlanOptions }: Props) => {
	const classes = new BemClass('ProjectPlanOrderHandler');
	const [projectPlanTemplates, setProjectPlanTemplates] = useState<ProjectPlanTemplate[]>([]);

	useEffect(() => {
		const projectPlanTemplateIds = products.map((product: Product) => product.projectPlanTemplate?.id);
		const { promise, cancel } = makeCancelable(
			ProjectPlanTemplateResource.find({ id: projectPlanTemplateIds, active: true })
		);
		promise
			.then(({ data: responseProjectPlanTemplates }) => setProjectPlanTemplates(responseProjectPlanTemplates))
			.catch(logError);
		return () => cancel();
	}, [products]);

	const lang = {
		readMore: t('default.readMore'),
		secondTitle: t('editOrder.projectPlanHandler.secondTitle'),
		subtitle: t('editOrder.projectPlanHandler.subtitle'),
		title: t('editOrder.projectPlanHandler.title'),
		titleSingle: t('editOrder.projectPlanHandler.titleSingle')
	};

	const onOptionsChange = (projectPlanTemplateId: number, projectPlanOption: any) => {
		const updatedProjectPlanOptions = findAndReplaceOrInsertItem(projectPlanOptions, projectPlanOption, {
			projectPlanTemplateId
		});
		onChange(updatedProjectPlanOptions);
	};

	const getOption = (templateId: number) => {
		const [option] = projectPlanOptions.filter(option => option.projectPlanTemplateId === templateId);
		return option ?? { projectPlanTemplateId: templateId, notes: '', startDate: null, endDate: null, userId: null };
	};

	const productLength = products.length;

	return (
		<Block className={classes.b()} space="ptl pbl pll prl">
			<Block space="mbxl">
				<Title>
					{productLength} {productLength > 1 ? lang.title : lang.titleSingle}
				</Title>
				<Title>{lang.secondTitle}</Title>
				<Text space="mtm">{lang.subtitle}</Text>
				<Text color="medium-blue">
					<Help articleId={1504} sidebar>
						{lang.readMore}
					</Help>
				</Text>
			</Block>
			{projectPlanTemplates.map((template: ProjectPlanTemplate) => (
				<ProjectPlanOrderHandlerCard
					key={template.id}
					onChange={projectPlanOption => onOptionsChange(template.id, projectPlanOption)}
					value={getOption(template.id)}
					template={template}
					productsNames={template.products
						.filter(product => products.some((p: { id: number }) => p.id === product.id))
						.map(product => product.name)
						.join(', ')}
				/>
			))}
		</Block>
	);
};

export default ProjectPlanOrderHandler;

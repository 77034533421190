import './AgreementHistoryFooter.scss';

import PropType from 'prop-types';
import React, { useMemo } from 'react';

import T from 'Components/Helpers/translate';
import { Icon, Text, Block, Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { AgreementVersionsAndDates } from 'App/resources/Model/AgreementHistoryVersion';

type Props = {
	agreementVersionId?: number;
	agreementVersionsAndDates?: AgreementVersionsAndDates;
	onNavigate?: (versionId: number, agreementVersionsAndDates: any) => void;
	closeModal?: () => void;
};

const AgreementHistoryFooter = ({ agreementVersionId, agreementVersionsAndDates, onNavigate, closeModal }: Props) => {
	const classes = new BemClass('AgreementHistoryFooter');
	const formatDate = (date: string | Date | null) => (date ? moment(date).format('L') : '');

	const sortedAgreementVersionsAndDateEntries = useMemo(() => {
		if (!agreementVersionsAndDates) {
			return [];
		}
		const versionDateEntries = Object.entries(agreementVersionsAndDates);
		return versionDateEntries.sort(([, aVersionDates], [, bVersionDates]) => {
			return new Date(aVersionDates.startDate).getTime() - new Date(bVersionDates.startDate).getTime();
		});
	}, [agreementVersionsAndDates]);

	const [nextVersionId, previousVersionId] = useMemo(() => {
		if (!sortedAgreementVersionsAndDateEntries?.length || !agreementVersionId) {
			return [null, null];
		}

		const versionIndex = sortedAgreementVersionsAndDateEntries.findIndex(([versionId]) => {
			return `${versionId}` === `${agreementVersionId}`;
		});
		let nextIndex: number | null = versionIndex + 1;
		if (nextIndex === sortedAgreementVersionsAndDateEntries.length) {
			nextIndex = null;
		}
		let prevIndex: number | null = versionIndex - 1;
		if (prevIndex < 0) {
			prevIndex = null;
		}
		return [
			nextIndex !== null ? sortedAgreementVersionsAndDateEntries[nextIndex][0] : null,
			prevIndex !== null ? sortedAgreementVersionsAndDateEntries[prevIndex][0] : null
		];
	}, [agreementVersionId, agreementVersionsAndDates]);

	const [fromDate, toDate] = useMemo(() => {
		const noVersionIds = !nextVersionId && !previousVersionId;
		if (agreementVersionId && agreementVersionsAndDates) {
			return [
				formatDate(agreementVersionsAndDates[agreementVersionId].startDate),
				agreementVersionsAndDates[agreementVersionId].endDate
					? formatDate(agreementVersionsAndDates[agreementVersionId].endDate)
					: ''
			];
		} else if (noVersionIds || !agreementVersionId || !agreementVersionsAndDates) {
			return ['', ''];
		}

		const formattedFromDate = formatDate(agreementVersionsAndDates[agreementVersionId].startDate);
		const nextVersionDate = nextVersionId ? agreementVersionsAndDates[parseInt(nextVersionId)].startDate : null;
		const formattedToDate = nextVersionDate && formatDate(nextVersionDate);
		return [formattedFromDate, formattedToDate];
	}, [nextVersionId, previousVersionId, agreementVersionsAndDates]);

	const onVersionButtonClick = (version: string | null) => {
		if (!onNavigate || !version) {
			return;
		}
		onNavigate(parseInt(version, 10), agreementVersionsAndDates);
		closeModal?.();
	};

	const isDateInThePast = () => {
		return toDate && new Date(toDate) < new Date();
	};

	return (
		<Block space="ptm prxl pbm plxl" backgroundColor="green" className={classes.b()}>
			<div className={classes.elem('leftSide').b()}>
				<Icon space="mrl" color="white" name="clock-o" className={classes.elem('clockIcon').b()} />
				<div className={classes.elem('textContainer').b()}>
					<Text color="white" className={classes.elem('snapshotText').b()}>
						{isDateInThePast()
							? T('agreementHistoryFooter.snapshotBetweenDates', { fromDate, toDate })
							: T('agreementHistoryFooter.snapshotFromDate', { fromDate })}
					</Text>
					<Text size="sm" color="super-light-green" className={classes.elem('editDisabledText').b()}>
						{T('agreementHistoryFooter.editDisabledText')}
					</Text>
				</div>
			</div>

			<div className={classes.elem('rightSide').b()}>
				<Button
					color="white"
					type="lined"
					disabled={!previousVersionId}
					onClick={() => onVersionButtonClick(previousVersionId)}
					className={classes.elem('previousVersionButton').b()}
				>
					{T('agreementHistoryFooter.previousVersionButton')}
				</Button>
				<Button
					color="white"
					type="lined"
					disabled={!nextVersionId}
					onClick={() => onVersionButtonClick(nextVersionId)}
					className={classes.elem('nextVersionButton').b()}
				>
					{T('agreementHistoryFooter.nextVersionButton')}
				</Button>
				<Button color="white" className={classes.elem('returnButton').b()} onClick={closeModal}>
					{T('agreementHistoryFooter.returnButton')}
				</Button>
			</div>
		</Block>
	);
};

AgreementHistoryFooter.propTypes = {
	agreementVersionId: PropType.number,
	agreementVersionsAndDates: PropType.object,
	closeModal: PropType.func,
	onNavigate: PropType.func
};

export default AgreementHistoryFooter;

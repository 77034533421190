import React from 'react';
import withDrilldown from './withDrilldown';
import OrderDrilldown from './OrderOpportunityDrilldown.js';

const InputWithDrillDown = withDrilldown(OrderDrilldown, { field: 'hasOrder', text: 'default.sales' });

export default function SegmentOrderInput(props) {
	return <InputWithDrillDown {...props} />;
}

window.SegmentOrderInput = SegmentOrderInput;

'use strict';

angular.module('services.templateCacher', []).provider('TemplateCacher', {
	cache: [
		'TemplateCacher',
		function (TemplateCacher) {
			return TemplateCacher.cache();
		}
	],

	$get: [
		'$http',
		'$templateCache',
		function ($http, $templateCache) {
			return {
				cache: function (href) {
					return $http.get(href, { cache: $templateCache });
				}
			};
		}
	]
});

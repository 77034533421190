'use strict';

angular.module('upDirectives').directive('upCustomFilterInput', [
	function () {
		return {
			restrict: 'A',
			replace: true,
			templateUrl: require('App/upsales/common/directives/templates/upCustomFilterInput.tpl.html?file'),
			scope: {
				ngModel: '=ngModel',
				ngChange: '&',
				field: '=upCustomFilterInput'
			},
			require: 'ngModel',
			controller: ['$scope', 'AppService', function () {}],
			link: function ($scope, element, $attrs, ngModelCtrl) {
				if ($attrs.lookup) {
					$scope.lookup = $attrs.lookup;
				}
				$scope.$evalAsync(function () {
					$scope.customRadioChange = function (value) {
						$scope.ngModel.value = value;
						$scope.ngModel.isBoolean = true;

						if (value === null) {
							$scope.ngModel.inactive = true;
						} else {
							$scope.ngModel.inactive = false;
						}

						$scope.ngChange();
					};

					$scope.customDateChange = function (preset, value) {
						$scope.ngModel.value.start = value.start;
						$scope.ngModel.value.end = value.end;
						$scope.ngModel.value.preset = preset;

						if (preset === 'whenever') {
							$scope.ngModel.inactive = true;
						} else {
							$scope.ngModel.inactive = false;
						}

						$scope.ngChange();
					};

					$scope.selectChange = function () {
						ngModelCtrl.$modelValue.value = $scope.field.value;
						$scope.ngChange();
					};

					if ($scope.ngModel === undefined) {
						switch ($scope.field.datatype) {
							case 'Calculation':
							case 'Integer':
							case 'Currency':
							case 'Time':
							case 'Percent':
								$scope.ngModel = { value: { start: '', end: '' } };
								break;
							case 'Date':
								$scope.ngModel = { value: { start: null, end: null, preset: 'whenever' } };
								break;

							case 'Select':
								$scope.ngModel = { value: [] };
								break;
							case 'Users':
								$scope.ngModel = { value: [] };
								break;

							default:
								$scope.ngModel = { value: null };
						}
					}

					if ($scope.field.datatype === 'Boolean') {
						$scope.customRadioChange($scope.ngModel.value);
					}
				});
			}
		};
	}
]);

ReactTemplates.upFilters.filterTypes.historyFilterActiveIndicator = window.ReactCreateClass({
	onRemove: function (event) {
		ReactTemplates.TOOLS.stopProp(event);

		var props = this.props;
		props.onRemove(props.filterName);
	},
	onClick: function (event) {
		var props = this.props;

		if (event && props.onClick) {
			ReactTemplates.TOOLS.stopProp(event);
			props.onClick(null, props.filterName);
		}
	},
	render: function () {
		return (
			<div className="history-filter-active-indicator">
				<div className="icon-warp" onClick={this.onClick}>
					<i className={this.props.icon} />
				</div>
				<div className="icon-warp" onClick={this.onRemove}>
					<i className="fa fa-times-circle" aria-hidden="true" />
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.historyFilterActiveIndicatorGroup = window.ReactCreateClass({
	onRemoveFilter: function (filterName) {
		this.props.onRemoveFilter(filterName);
	},
	render: function () {
		var filters = this.props.filters;
		var filterConfigs = this.props.filterConfigs;
		var onRemoveFilter = this.onRemoveFilter;
		var onClick = this.props.onClick;

		var indicators = _.reduce(
			filters,
			function (res, filter, filterName) {
				var isInactive = filter.value ? filter.isInactive(filter) : true;

				if (!isInactive) {
					var icon = filterConfigs[filterName].icon;

					res.push(
						React.createElement(ReactTemplates.upFilters.filterTypes.historyFilterActiveIndicator, {
							filterName: filterName,
							icon: icon,
							onRemove: onRemoveFilter,
							key: filterName,
							onClick: onClick
						})
					);
				}

				return res;
			},
			[]
		);

		return <div className="history-filter-active-indicator-group">{indicators}</div>;
	}
});

ReactTemplates.upFilters.filterTypes.history = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		this.lang = {
			title: t(this.props.filter.title)
		};
	},
	toggleExpanded: function (event, filterName) {
		var self = this;
		var title = this.lang.title;
		var filter = this.props.filter;

		var activeTabIndex = 0;

		if (filterName === 'OpportunityFilters') {
			activeTabIndex = 1;
		} else if (filterName === 'ClientOrderFilters') {
			activeTabIndex = 2;
		}

		this.props.onPageChange(
			{
				component: ReactTemplates.upFilters.components.tabPage,
				getTitle: function () {
					return title;
				},
				props: {
					activeTabIndex: activeTabIndex,
					filterName: this.props.filterName,
					headerExtension: {
						component: ReactTemplates.upFilters.filterTypes.historyFilterActiveIndicatorGroup,
						props: function (tabPageThis) {
							return {
								onRemoveFilter: self.onRemoveFilter,
								filterConfigs: filter.combinationFilter,
								filters: self.props.filter.filters,
								onClick: function (event, filterName) {
									var activeTabIndex = 0;

									if (filterName === 'OpportunityFilters') {
										activeTabIndex = 1;
									} else if (filterName === 'ClientOrderFilters') {
										activeTabIndex = 2;
									}

									tabPageThis.setState({
										activeTabIndex: activeTabIndex
									});
								}
							};
						}
					},
					tabs: [
						{
							icon: 'up-icon-activity',
							component: self.props.filter.filters.ContactOnlyActivityFilters
								? ReactTemplates.upFilters.filterTypes.historyActivityOnlyPage
								: ReactTemplates.upFilters.filterTypes.historyActivityPage,
							props: function () {
								return {
									filter:
										self.props.filter.filters.ClientActivityFilters ||
										self.props.filter.filters.ContactOnlyActivityFilters,
									onChange: self.props.onChange
								};
							}
						},
						{
							icon: 'up-icon-pipeline',
							component: ReactTemplates.upFilters.filterTypes.historyOpportunityPage,
							props: function () {
								return {
									filter:
										self.props.filter.filters.OpportunityFilters ||
										self.props.filter.filters.ContactOpportunityFilters,
									onChange: self.props.onChange
								};
							}
						},
						{
							icon: 'fa fa-usd',
							component: ReactTemplates.upFilters.filterTypes.historyOrderPage,
							props: function () {
								return {
									filter:
										self.props.filter.filters.ClientOrderFilters ||
										self.props.filter.filters.ContactOrderFilters,
									onChange: self.props.onChange
								};
							}
						}
					]
				}
			},
			false
		);
	},
	onRemoveFilter: function (filterName) {
		this.props.onChange({ filterName: filterName }, { action: 'remove' });
	},
	formatValue: function () {
		if (this.isActive()) {
			var filter = this.props.filter;

			return (
				<ReactTemplates.upFilters.filterTypes.historyFilterActiveIndicatorGroup
					onRemoveFilter={this.onRemoveFilter}
					filters={filter.filters}
					filterConfigs={filter.combinationFilter}
					onClick={this.toggleExpanded}
				/>
			);
		} else {
			return window.Tools.$translate('default.all');
		}
	},
	isActive: function () {
		return _.reduce(
			this.props.filter.filters,
			function (res, filter) {
				if (res) {
					return res;
				}
				return !(filter.value ? filter.isInactive(filter) : true);
			},
			false
		);
	},
	render: function () {
		var filter = this.props.filter;
		var active = this.isActive();

		var title = Tools.$translate(filter.title);
		var value = this.formatValue();

		return (
			<div data-test-id={this.props['data-test-id']} className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{value}
					</span>
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.HistoryFilter = window.HistoryFilter;

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
	Button,
	ModalHeader,
	ModalControls,
	Row,
	Column,
	Toggle,
	Help,
	Block,
	ModalContent,
	Input,
	Label,
	ButtonSelect,
	Loader,
	Text,
	Progress,
	Textarea
} from '@upsales/components';
import EditProductPriceCard from './EditProductPriceCard';
import bemClass from '@upsales/components/Utils/bemClass';
import './EditProduct.scss';
import EditProductCustom from './EditProductCustom';
import EditProductInputWrap from './EditProductInputWrap';
import UpSelect from 'Components/Inputs/UpSelect';
import { init, setProduct, save, reset } from 'Store/actions/AdminEditProductActions';
import { makeCancelable } from 'App/babel/helpers/promise';
import { PRICE_TYPE } from 'Store/reducers/AdminEditProductReducer';
import Icon from '@upsales/components/Icon';
import { hasCMWithRR } from 'App/helpers/salesModelHelpers';
import { getRolesFromState } from 'Store/selectors/AppSelectors';
import RoleSelect from 'Components/RoleSelect';
import { getRecurringProductDefaultInterval } from 'App/helpers/subscriptionHelper';

const mapStateToProps = ({ AdminEditProduct, App }) => ({
	loading: AdminEditProduct.loading,
	saving: AdminEditProduct.saving,
	roles: getRolesFromState(App, 'all'),
	intervals: AdminEditProduct.intervals,
	categories: AdminEditProduct.categories,
	product: AdminEditProduct.product,
	customFields: App.customFields.product,
	errors: AdminEditProduct.errors,
	priceType: AdminEditProduct.priceType,
	metadata: App.metadata
});

const mapDispatchToProps = {
	init,
	setProduct,
	save,
	reset
};

class EditProduct extends React.PureComponent {
	constructor(props) {
		super(props);

		props.init(props.editProduct);

		const appsWDisableProducts = this.props.metadata.integrations.active.filter(app => {
			return app.inits.includes('disable_products');
		});
		this.disableProducts = appsWDisableProducts.length > 0;
		this.disabledProductApps = appsWDisableProducts.map(app => app.name).join(', ');

		const t = Tools.$translate;

		this.lang = {
			create: t('default.create'),
			new: t('default.new'),
			product: t('default.product'),
			recurring: t('admin.products.recurring'),
			oneOff: t('admin.products.oneOff'),
			recurringInterval: t('admin.products.recurringInterval'),
			productType: t('admin.products.type'),
			nameOfProduct: t('admin.products.thename'),
			save: t('default.save'),
			roles: t('default.roles'),
			cancel: t('default.abort'),
			placeInsideCategory: t('admin.products.setUnderCategory'),
			chooseCategory: t('admin.chooseCategory'),
			chooseRole: t('admin.documentTemplateModal.rolesPlaceholder'),
			edit: t('default.edit'),
			active: t('admin.products.isActive'),
			pricing: t('product.pricing'),
			customFieldsOnProducts: t('admin.products.customFields'),
			articleNumber: t('product.articleNo'),
			disabledByApps: t('product.disabledByApps', { apps: this.disabledProductApps }),
			description: t('default.description')
		};

		const productStandardFields = this.props.metadata.standardFields.Product;
		this.isActiveArticleNo =
			_.get(productStandardFields, '.ArticleNo.active', false) &&
			Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		this.isRequiredArticleNo = _.get(productStandardFields, '.ArticleNo.required', false);
		this.isActiveDescription =
			_.get(productStandardFields, '.Description.active', false) &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_DESCRIPTION);
		this.isRequiredDescription = _.get(productStandardFields, '.Description.required', false);
		this.hasRoleTree = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');
	}

	changeParent = e => {
		if (this.props.priceType === PRICE_TYPE.TIERED && e.target.added && e.target.added.calculatingField) {
			return;
		}
		this.props.setProduct({
			...this.props.product,
			category: e.target.added
				? {
						name: e.target.added.name,
						id: e.target.added.id,
						calculatingField: e.target.added.calculatingField
				  }
				: null
		});
	};

	changeRoles = e => {
		const product = { ...this.props.product };

		if (e.target.added) {
			product.roles.push(e.target.added);
		}

		if (e.target.removed) {
			const i = product.roles.findIndex(role => role.id === e.target.removed.id);
			if (i !== -1) {
				product.roles.splice(i, 1);
			}
		}

		this.props.setProduct(product);
	};

	changeInterval = e => {
		this.props.setProduct({ ...this.props.product, recurringInterval: parseInt(e.target.value) });
	};

	changeName = e => {
		this.props.setProduct({ ...this.props.product, name: e.target.value });
	};

	changeArticleNumber = e => {
		this.props.setProduct({ ...this.props.product, articleNo: e.target.value || null });
	};

	changeDescription = e => {
		this.props.setProduct({ ...this.props.product, description: e.target.value });
	};

	activeSwitch = val => {
		this.props.setProduct({ ...this.props.product, active: val ? 1 : 0 });
	};

	recurringSwitch = isRecurring => {
		this.props.setProduct({ ...this.props.product, isRecurring });
	};

	customChanged = (customFieldId, value) => {
		const product = { ...this.props.product };

		const matchingCustomField = _.find(product.custom, { fieldId: customFieldId });

		if (matchingCustomField) {
			matchingCustomField.value = value;
		} else {
			product.custom.push({
				fieldId: customFieldId,
				value: value
			});
		}

		this.props.setProduct(product);
	};

	save = () => {
		this._savePromise = makeCancelable(this.props.save());
		// eslint-disable-next-line promise/catch-or-return
		this._savePromise.promise
			.then(() => {
				this.props.reject();
			})
			.catch(() => {}); // promise caught in reducer
	};

	componentWillUnmount() {
		this.props.reset();
		if (this._savePromise) {
			this._savePromise.cancel();
		}
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	render() {
		const {
			roles,
			intervals,
			categories,
			product,
			customFields,
			errors,
			loading,
			saving,
			reject: closeModal,
			priceType,
			metadata
		} = this.props;

		const classes = new bemClass('EditProduct');

		if (loading) {
			return (
				<div className={classes.mod('loading').b()}>
					<Loader />
				</div>
			);
		}

		let createOrEditTxt = product.id ? this.lang.edit : this.lang.create + ' ' + this.lang.new.toLowerCase();
		createOrEditTxt += ' ' + this.lang.product.toLowerCase();

		let rolePicker = null;
		let productType = null;
		let recurringInterval = null;
		const hasRecurringOrder = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
		const hasRecurringModel = this.props.metadata.params.SalesModel === 'rr' || hasCMWithRR(this.props.metadata);
		const hasProductInterval = hasRecurringModel && hasRecurringOrder && product.isRecurring;

		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED)) {
			rolePicker = (
				<EditProductInputWrap>
					<Label>{this.lang.roles}</Label>
					{!this.hasRoleTree ? (
						<UpSelect
							className="form-control"
							data={roles}
							defaultValue={product.roles ? product.roles : []}
							onChange={this.changeRoles}
							placeholder={this.lang.chooseRole}
							multiple={true}
							getId={role => role.id}
							formatSelection={(role, c, escape) => escape(role.name)}
							formatResult={(role, c, q, escape) => escape(role.name)}
							matcher={(term, u, role) => role.name.toLowerCase().indexOf(term.toLowerCase()) !== -1}
							disabled={saving}
						/>
					) : (
						<RoleSelect
							roles={roles}
							selectedRoles={product.roles ?? []}
							disabled={saving}
							onChange={roles => {
								this.props.setProduct({ ...this.props.product, roles });
							}}
						/>
					)}
				</EditProductInputWrap>
			);
		}

		if (hasRecurringOrder) {
			productType = (
				<EditProductInputWrap>
					<Label>{this.lang.productType}</Label>
					<ButtonSelect
						value={!!product.isRecurring}
						options={[
							{ value: false, title: this.lang.oneOff },
							{ value: true, title: this.lang.recurring }
						]}
						onChange={this.recurringSwitch}
						disabled={this.disableProducts || saving}
						data-testid="typeField"
					/>
				</EditProductInputWrap>
			);
		}

		if (hasProductInterval) {
			const defaultInterval = getRecurringProductDefaultInterval(metadata)?.toString();

			recurringInterval = (
				<EditProductInputWrap className={classes.elem('recurring-interval-wrap').b()}>
					<Label>
						<Block space="mrm">{this.lang.recurringInterval}</Block>
						<Help articleId={1069} />
					</Label>
					<UpSelect
						className="form-control"
						data={intervals}
						defaultValue={product.recurringInterval ?? defaultInterval}
						onChange={this.changeInterval}
						getId={interval => interval.id}
						formatSelection={(interval, c, escape) => escape(interval.name)}
						formatResult={(interval, c, q, escape) => escape(interval.name)}
						matcher={(term, u, interval) => interval.name.toLowerCase().indexOf(term.toLowerCase()) !== -1}
						disabled={this.disableProducts || saving}
						required
					/>
				</EditProductInputWrap>
			);
		}

		const hasTiers = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_TIERS);
		const inputSize = this.hasRoleTree ? 5 : hasTiers ? 4 : null;
		const priceSize = this.hasRoleTree ? 7 : hasTiers ? 8 : null;

		return (
			<div className={classes.b()}>
				<ModalHeader title={createOrEditTxt} onClose={closeModal} />
				<ModalContent className="ModalContent--no-padding">
					<Block className={classes.elem('content-wrapper').b()}>
						<Block space="ptxl prxl pbxl plxl" backgroundColor="grey-1">
							<Row>
								<Column size={inputSize}>
									<EditProductInputWrap>
										<Label className={classes.elem('nameLabel').b()} required={true}>
											{this.lang.nameOfProduct}
										</Label>
										<Progress percentage={(product.name.length / 255) * 100} hideText />
										<Input
											type="text"
											onChange={this.changeName}
											value={product.name}
											state={errors.name ? 'error' : null}
											disabled={this.disableProducts || saving}
											data-testid="nameField"
											maxLength={255}
										/>
									</EditProductInputWrap>
									{this.isActiveArticleNo ? (
										<EditProductInputWrap>
											<Label required={this.isRequiredArticleNo}>
												{this.lang.articleNumber}{' '}
												<Progress
													percentage={((product.articleNo?.length ?? 0) / 100) * 100}
													hideText
												/>
											</Label>
											<Input
												type="text"
												onChange={this.changeArticleNumber}
												value={product.articleNo || ''}
												state={errors.articleNo ? 'error' : null}
												disabled={this.disableProducts || saving}
												data-testid="articleNumField"
												maxLength={100}
											/>
										</EditProductInputWrap>
									) : null}
									<EditProductInputWrap>
										<Label>{this.lang.placeInsideCategory}</Label>
										<UpSelect
											key={`category-select-${priceType}`}
											className={classes.elem('category-select', 'form-control').b()}
											data={categories}
											defaultValue={
												product.parentId
													? product.parentId
													: product.category
													? product.category.id
													: null
											}
											onChange={this.changeParent}
											placeholder={this.lang.chooseCategory}
											getId={cat => cat.id}
											formatSelection={(cat, c, escape) => escape(cat.name)}
											formatResult={(cat, c, q, escape) => {
												if (!hasTiers || !cat.calculatingField) {
													return escape(cat.name);
												}

												return `<span><span class="Icon Icon-calculator Icon--grey-10 Icon--padding-right-small"></span>${escape(
													cat.name
												)}</span>`;
											}}
											matcher={(term, u, cat) =>
												cat.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
											}
											allowClear={true}
											disabled={saving}
										/>
									</EditProductInputWrap>
									{rolePicker}
									{productType}
									{recurringInterval}
									{this.isActiveDescription ? (
										<EditProductInputWrap>
											<Label required={this.isRequiredDescription}>
												{this.lang.description}{' '}
												<Progress
													percentage={((product.description?.length ?? 0) / 512) * 100}
													hideText
												/>
											</Label>
											<Textarea
												value={product.description}
												onChange={this.changeDescription}
												state={errors.description ? 'error' : null}
												disabled={this.disableProducts || saving}
												maxLength={512}
											/>
										</EditProductInputWrap>
									) : null}
									<EditProductInputWrap className={classes.elem('active-toggle-wrap').b()}>
										<Label>
											<Block space="mrm">{this.lang.active}</Block>
											<Help articleId={473} />
										</Label>
										<Toggle
											size="lg"
											color="medium-green"
											checked={!!product.active}
											onChange={this.activeSwitch}
											className={classes.elem('active-toggle').b()}
											disabled={this.disableProducts}
											data-testid="activeField"
										/>
									</EditProductInputWrap>
								</Column>
								<Column size={priceSize}>
									<EditProductPriceCard disabled={this.disableProducts || saving} />
								</Column>
							</Row>
							{this.disableProducts && (
								<Row className={classes.elem('disabled-product-text').b()}>
									<Icon name="info-circle" color="grey-10" />
									<Text color="grey-11" bold>
										{this.lang.disabledByApps}
									</Text>
								</Row>
							)}
						</Block>
						<EditProductCustom
							title={this.lang.customFieldsOnProducts}
							customFields={customFields}
							values={product.custom}
							onChange={this.customChanged}
							disabled={saving}
						/>
					</Block>
				</ModalContent>

				<ModalControls>
					<Row>
						<Column align="right">
							<Button
								onClick={this.save}
								size="sm"
								shadow="none"
								className="main-action"
								loading={saving}
							>
								{this.lang.save}
							</Button>
							<Button onClick={closeModal} color="grey" type="link" disabled={saving}>
								{this.lang.cancel}
							</Button>
						</Column>
					</Row>
				</ModalControls>
			</div>
		);
	}
}

export const detached = EditProduct;
const Component = connect(mapStateToProps, mapDispatchToProps)(EditProduct);

export default Component;

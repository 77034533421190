'use strict';

var orderStageSelectDir = 'upOrderStage';
angular
	.module('upDirectives')
	.directive(orderStageSelectDir, function (AppService, OrderStage, selectHelper, $parse) {
		return selectHelper.getDirectiveObject(orderStageSelectDir, {
			ajax: false,
			asIds: false,
			idAttr: OrderStage.attr.id,
			titleAttr: OrderStage.attr.name,
			sorting: { field: OrderStage.attr.name, ascending: true },
			formatResult: function (stage, container, query, escape) {
				return escape(stage.name) + ' <span class="pull-right grey">' + escape(stage.probability) + '%</span>';
			},
			formatSelection: function (stage, container, escape) {
				return (
					escape(stage.name) +
					' <span style="font-size: 12px; margin-left: 5px;" class="upsales-bright-blue up-opacity-60">' +
					escape(stage.probability) +
					'%</span>'
				);
			},
			data: function ($attrs, $scope) {
				var stages;

				// No type? then show all standard
				if ($attrs.stageType !== undefined) {
					var type = $parse($attrs.stageType)($scope);
					stages = AppService.getStages(type);
				} else if ($attrs.stages) {
					stages = $parse($attrs.stages)($scope);
				} else {
					stages = AppService.getStages('all');
				}

				// Sort it
				if ($attrs.sortKey) {
					var sort;
					try {
						sort = JSON.parse($attrs.sortKey);
					} catch (e) {
						sort = [$attrs.sortKey];
					}
					stages = stages.sort(function (A, B) {
						for (var i = 0; i < sort.length; i++) {
							var sortKey = sort[i];
							var isReverse = false;
							if (sortKey[0] === '-') {
								isReverse = true;
								sortKey = sortKey.substring(1);
							}

							var aAttr = typeof A[sortKey] === 'string' ? A[sortKey].trim().toLowerCase() : A[sortKey];
							var bAttr = typeof B[sortKey] === 'string' ? B[sortKey].trim().toLowerCase() : B[sortKey];
							if (aAttr < bAttr) {
								return isReverse ? 1 : -1;
							} else if (aAttr > bAttr) {
								return isReverse ? -1 : 1;
							}
						}
						return 0;
					});
				} else {
					stages = _.sortBy(stages, '$sortId');
				}

				return stages;
			}
		});
	})
	.directive(orderStageSelectDir + 'Ids', function (AppService, OrderStage, selectHelper, $parse) {
		return selectHelper.getDirectiveObjectIds(orderStageSelectDir + 'Ids', {
			ajax: false,
			asIds: true,
			idAttr: OrderStage.attr.id,
			titleAttr: OrderStage.attr.name,
			sorting: { field: OrderStage.attr.name, ascending: true },
			formatResult: function (stage, container, query, escape) {
				return escape(stage.name) + ' <span class="pull-right grey">' + escape(stage.probability) + '%</span>';
			},
			formatSelection: function (stage, container, escape) {
				return (
					escape(stage.name) +
					' <span style="font-size: 12px; margin-left: 5px;" class="upsales-bright-blue up-opacity-60">' +
					escape(stage.probability) +
					'%</span>'
				);
			},
			data: function ($attrs, $scope) {
				var stages;

				// No type? then show all standard
				if ($attrs.stageType !== undefined) {
					var type = $parse($attrs.stageType)($scope);
					stages = AppService.getStages(type || $attrs.stageType);
				} else if ($attrs.stages) {
					stages = $parse($attrs.stages)($scope);
				} else {
					stages = AppService.getStages('all');
				}

				// Sort it
				stages = _.sortBy(stages, '$sortId');

				return stages;
			}
		});
	});

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';

import './CKEditorWrapper.scss';

export const divarea = {
	afterInit: function (editor) {
		// Add the "wysiwyg" mode.
		// Do that in the afterInit function, so it'll eventually overwrite
		// the mode defined by the wysiwygarea plugin.
		editor.addMode('wysiwyg', function (callback) {
			var editingBlock = CKEDITOR.dom.element.createFromHtml(
				'<div class="cke_wysiwyg_div cke_reset cke_enable_context_menu" hidefocus="true"></div>'
			);

			var contentSpace = editor.ui.space('contents');
			contentSpace.append(editingBlock);

			editingBlock = editor.editable(editingBlock);

			editingBlock.detach = CKEDITOR.tools.override(editingBlock.detach, function (origFn) {
				return function () {
					origFn.apply(this, arguments);
					this.remove();
				};
			});

			editor.setData(editor.getData(1), callback);
			editor.fire('contentDom');
		});
	}
};

if (typeof CKEDITOR !== 'undefined') {
	CKEDITOR.plugins.add('divarea', divarea);
}

const defaultEditorOptions = {
	language: 'en',
	uiColor: '#fafafa',
	height: '250px',
	resize_enabled: false,
	allowedContent: true,
	extraPlugins: 'divarea',
	entities: false,
	enterMode: CKEDITOR.ENTER_BR,
	shiftEnterMode: CKEDITOR.ENTER_BR
};

class CKEditorWrapper extends Component {
	constructor(props) {
		super(props);

		const { lang, toolbar, options } = props.editorOptions;
		// CKeditor options
		const [selfLang] = Tools.AppService.getSelf().language.split('-');
		this.editorOptions = {
			...defaultEditorOptions,
			...options,
			language: selfLang || lang,
			toolbar: props.toolbar || toolbar,
			readOnly: props.disabled
		};

		this.onChange = () => this.props.onChange({ target: { value: this.valuePadding(this.editor.getData()) } });
	}

	componentWillUnmount() {
		this.editor.destroy();
	}

	componentDidMount() {
		const textarea = ReactDOM.findDOMNode(this._textarea);

		this.editor = CKEDITOR.replace(textarea, this.editorOptions);

		this.editor.on('change', this.onChange);
		this.editor.on('keyup', this.onChange);
		this.editor.on('blur', () => {
			this.onChange();
			this.props.onBlur();
		});
		this.editor.on('focus', this.props.onFocus);
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (this.props.disabled !== nextProps.disabled) {
			this.editor.setReadOnly(nextProps.disabled);
		}
	}

	trimValuePadding(value) {
		if (this.props.paddValue) {
			const [start, end] = this.props.paddValue;
			const vStart = value.indexOf(start) === 0 ? value.substr(start.length) : value;
			return vStart.substr(vStart.length - end.length, vStart.length) === end
				? vStart.substr(0, vStart.length - end.length)
				: vStart;
		} else {
			return value;
		}
	}

	valuePadding(value) {
		if (this.props.paddValue) {
			const [start, end] = this.props.paddValue;
			return start + value + end;
		} else {
			return value;
		}
	}

	render() {
		const className = new BemClass('CKEditorWrapper', 'tool-main-section no-padding');
		return (
			<div className={className.mod({ 'hide-bottom': this.props.hideBottom }).b()}>
				<textarea
					value={this.trimValuePadding(this.props.value)}
					ref={r => (this['_textarea'] = r)}
					onChange={() => {}}
				/>
			</div>
		);
	}
}

CKEditorWrapper.propTypes = {
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	onFocus: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	toolbar: PropTypes.array.isRequired,
	editorOptions: PropTypes.object,
	hideBottom: PropTypes.bool,
	disabled: PropTypes.bool,
	paddValue: PropTypes.array
};

CKEditorWrapper.defaultProps = {
	value: '',
	toolbar: [
		{
			name: 'basicstyles',
			items: ['Bold', 'Italic', 'Underline']
		}
	],
	editorOptions: {},
	onBlur: () => {},
	onFocus: () => {}
};

window.CKEditorWrapper = CKEditorWrapper;
export default CKEditorWrapper;

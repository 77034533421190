'use strict';

angular.module('domain.appointment').controller('CreateAppointmentAction', [
	'$scope',
	'$modalParams',
	'$translate',
	'$parse',
	'$multiSelect',
	'$q',
	'ActionProperties',
	'MultiActions',
	'NotificationService',
	'AppService',
	'TagsService',
	function (
		$scope,
		$modalParams,
		$translate,
		$parse,
		$multiSelect,
		$q,
		ActionProperties,
		MultiActions,
		NotificationService,
		AppService,
		TagsService
	) {
		var arrayProperties = [];

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.save = function () {
			$scope.saving = true;

			// map properties
			var properties = [];

			if ($scope.properties.StartTime) {
				$scope.properties.StartTime = moment($scope.properties.StartTime).format('HH:mm');
			}
			if ($scope.properties.EndTime) {
				$scope.properties.EndTime = moment($scope.properties.EndTime).format('HH:mm');
			}

			ActionProperties.mapCustomFields(properties, $scope.customFields, 'Appointment');

			ActionProperties.map(properties, $scope.properties, arrayProperties);

			return $scope.resolve(properties);
		};

		$scope.addField = function (field) {
			_.remove($scope.availableFields, {
				id: field.id
			});
			$scope.customFields.push(field);
			$scope.fieldToAdd = null;
		};

		$scope.removeField = function (field) {
			_.remove($scope.customFields, {
				id: field.id
			});
			$scope.availableFields.push(field);
			$scope.reloadModalPosition();
		};

		$scope.setDate = function () {
			if ($scope.hideDateSelect) {
				if ($scope.startDate.type && $scope.startDate.type.tag) {
					$scope.properties.StartDate =
						'{{' + $scope.startDate.type.tag + ':' + ($scope.startDate.offset || 0) + '}}';
				}
				if ($scope.endDate.type && $scope.endDate.type.tag) {
					$scope.properties.EndDate =
						'{{' + $scope.startDate.type.tag + ':' + ($scope.startDate.offset || 0) + '}}';
				}
			} else {
				if ($scope.startDate.type && $scope.startDate.type.tag) {
					$scope.properties.StartDate =
						'{{' + $scope.startDate.type.tag + ':' + ($scope.startDate.offset || 0) + '}}';
				}
				if ($scope.endDate.type && $scope.endDate.type.tag) {
					$scope.properties.EndDate =
						'{{' + $scope.endDate.type.tag + ':' + ($scope.endDate.offset || 0) + '}}';
				}
			}
		};

		$scope.changeCustomDate = function (field) {
			if (field.value && field.value.tag === 'RegularDate') {
				field.dates = undefined;
			}
		};

		var parseDate = function (tags, property) {
			var res = {
				type: null,
				offset: null
			};

			if (!$modalParams.properties) {
				return res;
			}

			var date = $modalParams.properties.find(function (prop) {
				return prop.name === property;
			});
			if (date && date.value) {
				var value = date.value;
				value = value.replace(/[{}]/g, '');
				var split = value.split(':');
				res.type = _.reduce(
					tags,
					function (result, group) {
						if (result) {
							return result;
						}

						return _.find(group.children, {
							tag: split[0]
						});
					},
					null
				);
				res.offset = parseInt(split[1]);
			}

			return res;
		};

		var parseTime = function (property) {
			var value = property === 'StartTime' ? '12:00' : '13:00';
			const found = _.find($modalParams.properties, {
				name: property
			});
			if ($modalParams.properties && found?.value) {
				value = found.value;
			}
			const formatted = moment(value, 'HH:mm').toString();
			if (formatted === 'Invalid date' && moment(value).isValid()) {
				// The value is not in HH:mm but a valid date
				return value;
			}
			return formatted;
		};

		var init = function () {
			$scope.isTrigger = $modalParams.isTrigger || false;
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.availableFields = angular.copy($modalParams.appointmentCustomFields);
			if (!$scope.isTrigger) {
				$scope.availableFields = _.filter($scope.availableFields, 'editable');
			}

			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customFields = [];

			// parse properties
			$scope.properties = {};

			// Set activity type
			$scope.appointmentTypes = AppService.getActivityTypes('appointment', true);
			$scope.properties.AppointmentType = $scope.appointmentTypes[0].id;

			var today = null;
			var dateTags = [];
			var dateTypes = TagsService.getTagsByEntity($modalParams.tagEntity).reduce(function (result, value) {
				if (value.type !== 'Date') {
					return result;
				}

				if (value.tag === 'General.Today') {
					today = value;
				}

				if (!result[value.entity]) {
					result[value.entity] = {
						name: $translate.instant('default.' + value.entity),
						children: []
					};
				}

				result[value.entity].children.push(value);
				dateTags.push(value);
				return result;
			}, {});

			var customDateTypes = _.cloneDeep(dateTypes);
			if (customDateTypes.general && customDateTypes.general.children) {
				var clearField = { name: $translate.instant('tag.general.clearfield'), tag: 'General.ClearField' };
				customDateTypes.general.children.push(clearField);
				customDateTypes.general.children.push({
					name: $translate.instant('tag.special.regularDate'),
					tag: 'RegularDate'
				});
				dateTags.push(clearField);
			}

			$scope.availableFields = _.map($scope.availableFields, function (field) {
				if (customDateTypes && field.datatype === 'Date') {
					field.dates = _.values(customDateTypes);
				}
				return field;
			});

			$scope.customDateTypes = {
				data: _.values(customDateTypes),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('tag')
			};

			$scope.dateTypes = {
				data: _.values(dateTypes),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('tag')
			};

			var startDateProperty = _.find($modalParams.properties, {
				name: 'StartDate'
			});
			var endDateProperty = _.find($modalParams.properties, {
				name: 'EndDate'
			});
			var hasStartTimeOtherThanToday =
				startDateProperty &&
				startDateProperty.value &&
				startDateProperty.value.indexOf('{{General.Today') !== 0;
			var hasEndTimeOtherThanToday =
				endDateProperty && endDateProperty.value && endDateProperty.value.indexOf('{{General.Today') !== 0;

			if (
				$modalParams.openedFrom !== 'editTrigger' ||
				!today ||
				hasStartTimeOtherThanToday ||
				hasEndTimeOtherThanToday
			) {
				$scope.hideDateSelect = false;
				$scope.startDate = parseDate(dateTypes, 'StartDate');
				$scope.endDate = parseDate(dateTypes, 'EndDate');
			} else {
				$scope.hideDateSelect = true;
				$scope.startDate = startDateProperty ? parseDate(dateTypes, 'StartDate') : { type: today, offset: 0 };
				$scope.endDate = endDateProperty ? parseDate(dateTypes, 'EndDate') : { type: today, offset: 0 };
			}

			$scope.setDate();

			$scope.properties.StartTime = parseTime('StartTime');
			$scope.properties.EndTime = parseTime('EndTime');

			if ($scope.isEdit) {
				const indexStartTime = _.findIndex($modalParams.properties, {
					name: 'StartTime'
				});
				if (indexStartTime) {
					$modalParams.properties[indexStartTime].value = parseTime('StartTime');
				}
				const indexEndTime = _.findIndex($modalParams.properties, {
					name: 'EndTime'
				});
				if (indexEndTime) {
					$modalParams.properties[indexEndTime].value = parseTime('EndTime');
				}
			}

			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties);

			// parse custom
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Appointment',
				dateTags
			);

			// Users
			$scope.users = AppService.getActiveUsers();

			$scope.users.unshift({
				id: '{{Client.UserId}}',
				name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.accountManager')
			});

			switch ($modalParams.tagEntity) {
				case 'client':
					$scope.users.unshift({
						id: '{{Client.RegById}}',
						name:
							$translate.instant('tag.group.Client') + ': ' + $translate.instant('tag.account.regbyname')
					});
					break;

				case 'contact':
					$scope.users.unshift({
						id: '{{Client.RegById}}',
						name:
							$translate.instant('tag.group.Client') + ': ' + $translate.instant('tag.account.regbyname')
					});
					$scope.users.unshift({
						id: '{{Contact.RegById}}',
						name:
							$translate.instant('tag.group.Contact') + ': ' + $translate.instant('tag.contact.regbyname')
					});
					break;

				case 'order':
					$scope.users.unshift({
						id: '{{Client.RegById}}',
						name:
							$translate.instant('tag.group.Client') + ': ' + $translate.instant('tag.account.regbyname')
					});
					$scope.users.unshift({
						id: '{{Contact.RegById}}',
						name:
							$translate.instant('tag.group.Contact') + ': ' + $translate.instant('tag.contact.regbyname')
					});
					$scope.users.unshift({
						id: '{{Order.UserId}}',
						name: $translate.instant('tag.group.Order') + ': ' + $translate.instant('default.salesRep')
					});
					break;

				case 'activity':
					$scope.users.unshift({
						id: '{{Client.RegById}}',
						name:
							$translate.instant('tag.group.Client') + ': ' + $translate.instant('tag.account.regbyname')
					});
					$scope.users.unshift({
						id: '{{Contact.RegById}}',
						name:
							$translate.instant('tag.group.Contact') + ': ' + $translate.instant('tag.contact.regbyname')
					});
					$scope.users.unshift({
						id: '{{Activity.RegById}}',
						name:
							$translate.instant('tag.group.Activity') +
							': ' +
							$translate.instant('tag.activity.regbyname')
					});
					$scope.users.unshift({
						id: '{{Activity.UserId}}',
						name:
							$translate.instant('tag.group.Activity') +
							': ' +
							$translate.instant('tag.activity.username')
					});
					break;
			}
			$scope.users.unshift({
				id: '{{General.CurrentUser}}',
				name: $translate.instant('tag.general.currentuser')
			});

			if (!$scope.properties.User) {
				$scope.properties.User = AppService.getSelf().id;
			}
		};

		AppService.loadedPromise.then(init);
	}
]);

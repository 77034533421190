import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './AppOnboarding.scss';
import {
	Headline,
	Block,
	Text,
	Button,
	Icon,
	Table,
	Card,
	TableHeader,
	TableRow,
	TableColumn,
	Loader
} from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import Prospecting from 'Resources/Prospecting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import UpsalesAI from 'Resources/UpsalesAI';
import AppOnboardingSimilarClients from './AppOnboardingSimilarClients';
import T from 'Components/Helpers/translate';

type ERPClient = { name: string; orgNo: string; value: number };

type AppOnboardingTopClientsProps = {
	erpClients: ERPClient[];
	onBack: () => void;
	onConfirm: (prospectingIds: string[]) => void;
};

type MappedClient = ERPClient & ProspectingClient & { similarCount: number };

const findInProspecting = async (erpClients: ERPClient[]): Promise<MappedClient[]> => {
	const country = Tools.AppService.getMetadata().params.CustomerRegion;
	const rb = new RequestBuilder();
	const orgNumbers = erpClients.map(c => c.orgNo.replace('-', ''));
	rb.addFilter({ field: 'orgNumber' }, comparisonTypes.Equals, orgNumbers);
	rb.addFilter({ field: 'headquarters' }, comparisonTypes.Equals, true);
	rb.extraParams.push({ key: 'country', value: country });

	const [{ data: prospectingData }, ...aiData] = await Promise.all<{ data: ProspectingClient[] }>([
		Prospecting.find(rb.build()),
		...orgNumbers.map(nr => UpsalesAI.find({ orgNumbers: [nr], country }))
	]);

	return erpClients.reduce<MappedClient[]>((res, erpClient, index) => {
		const found = prospectingData.find(
			c => parseInt(c.orgNumber.replace('-', '')) === parseInt(erpClient.orgNo.replace('-', ''))
		);
		if (found) {
			res.push({ ...erpClient, ...found, similarCount: aiData[index].data.length });
		}
		return res;
	}, []);
};

const AppOnboardingTopClients = ({ erpClients, onBack, onConfirm }: AppOnboardingTopClientsProps) => {
	const [mappedClients, setMappedClients] = useState<MappedClient[]>([]);
	const [loadingClients, setLoadingClients] = useState(true);
	const [showSimilarFor, setShowSimilarFor] = useState<null | { name: string; orgNumber: string; count: number }>(
		null
	);
	useEffect(() => {
		if (!erpClients.length) {
			setMappedClients([]);
			setLoadingClients(false);
		} else {
			setLoadingClients(true);
			findInProspecting(erpClients)
				.then(data => {
					setMappedClients(data);
					setLoadingClients(false);
				})
				.catch(e => {
					logError(e, 'Failed to fetch prospecting data');
					// Allow entering upsales
				});
		}
	}, [erpClients.map(c => c.orgNo).join(',')]);

	const classes = new BemClass('AppOnboardingTopClients');
	return (
		<div className={classes.b()}>
			{!showSimilarFor ? (
				<div>
					<Block space="mbxl">
						<Button
							color="white"
							onClick={() => {
								setShowSimilarFor(null);
								onBack();
							}}
						>
							<Icon name="chevron-left" space="mrs" />
							{T('onboarding.erp.back')}
						</Button>
					</Block>
					<Block space="mbxl">
						<Headline>{T('onboarding.erp.hereAreYourBiggestClientsClickToFindSimilar')}</Headline>
					</Block>
					{loadingClients ? (
						<Loader size="sm" />
					) : (
						<Card space="mbxl">
							<Table>
								<TableHeader
									columns={[
										{ title: T('default.name') },
										{ title: T('onboarding.erp.sales12Months') },
										{ title: '' }
									]}
								/>
								{mappedClients.map((client, i) => (
									<TableRow key={`${client.name}${i}`}>
										<TableColumn>
											<Text bold>{client.name}</Text>
										</TableColumn>
										<TableColumn>
											<Text>{numberFormat(client.value)}</Text>
										</TableColumn>
										<TableColumn align="right">
											<Button
												size="sm"
												color="super-light-green"
												disabled={!client.similarCount}
												onClick={() =>
													setShowSimilarFor({
														orgNumber: client.orgNumber,
														name: client.name,
														count: client.similarCount
													})
												}
											>
												{client.similarCount
													? T('onboarding.erp.showCountSimilarCompanies', {
															COUNT: client.similarCount
													  })
													: T('onboarding.erp.noSimilarCompaniesFound')}
											</Button>
										</TableColumn>
									</TableRow>
								))}
							</Table>
						</Card>
					)}
				</div>
			) : (
				<div>
					{showSimilarFor ? (
						<AppOnboardingSimilarClients
							orgNo={showSimilarFor.orgNumber}
							name={showSimilarFor.name}
							onBack={() => setShowSimilarFor(null)}
							onConfirm={onConfirm}
						/>
					) : null}
				</div>
			)}
		</div>
	);
};

export default AppOnboardingTopClients;

'use strict';
angular.module('upResources').factory('Leadchannels', [
	'$resource',
	'$q',
	'URL',
	'API',
	'LeadchannelsAttributes',
	'CacheService',
	function ($resource, $q, URL, API, LeadchannelsAttributes, CacheService) {
		var LA = LeadchannelsAttributes();
		var Attribute = LA.attr;
		var Keys = LA.keys;

		var Model = {
			customer: function (customer) {
				var Cache = CacheService.getCache(CacheService.cacheTypes.LEADCHANNEL);
				var instance = {};

				var LeadChannels = $resource(
					URL + API + ':customerId/leadchannels',
					{},
					{
						get: { method: 'GET', isArray: false }
					}
				);

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return LeadChannels.get({ customerId: customer }, params).$promise;
				};

				instance.get = function (id) {
					return LeadChannels.get({ customerId: customer }, id).$promise;
				};

				instance.getCached = function () {
					var key = 'Leadchannels';
					var cached = Cache.get(key);

					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find({}).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

import React from 'react';

const CompanyWebsite = ({ website }) => {
	return (
		<div id="company-website" className="company-util">
			<i className={website && website.length ? 'fa fa-globe fa-lg bright-blue' : 'fa fa-globe fa-lg'} />
			{website && website.length ? (
				<a href={website.indexOf('http') === 0 ? website : `http://${website}`} target="_blank">
					{website.replace(/https?:\/\//g, '')}
				</a>
			) : (
				<span className="grey italic">{Tools.$translate('default.noWebsite')}</span>
			)}
		</div>
	);
};

export default CompanyWebsite;

'use strict';
import { openDrawer } from 'Services/Drawer';

angular.module('domain.mail').controller('SentMail', [
	'$scope',
	'$modalParams',
	'$upModal',
	'$filter',
	'MailBodyParser',
	'FeatureHelper',
	'Mail',
	'$sce',
	'urlParser',
	'URL',
	'API',
	function ($scope, $modalParams, $upModal, $filter, MailBodyParser, FeatureHelper, Mail, $sce, urlParser, URL, API) {
		var customerId = $modalParams.customerId;
		var isMailCampaign = !!$modalParams.meta.mail.project;
		var prettyMailEvents = function (events) {
			// group opens
			var opens = [];
			var displayEvents = [];
			var firstOpen;
			var sentEvent;
			var i;
			if (!events) {
				events = [];
			}

			for (i = 0; i < events.length; i++) {
				if (events[i].type === 'send' && !sentEvent) {
					sentEvent = events[i];
				}
				if (events[i].type !== 'open') {
					continue;
				}
				if (!firstOpen) {
					firstOpen = i;
				}

				opens.push(events[i].date);
			}
			if (sentEvent) {
				displayEvents.push(sentEvent);
			}
			for (i = 0; i < events.length; i++) {
				if (events[i].type === 'send') {
					continue;
				}
				if (events[i].type !== 'open') {
					if (events[i].type === 'click') {
						events[i].parsedUrl = urlParser.parseUrl(events[i].value);
					}
					displayEvents.push(events[i]);
					continue;
				}
				if (i !== firstOpen) {
					continue;
				}

				events[i].total = opens;
				events[i].totalParsed = '';
				events[i].total.forEach(function (open) {
					var dateFilter = $filter('upsalesDate');
					events[i].totalParsed += dateFilter(open, false, true) + '\n';
				});
				displayEvents.push(events[i]);
			}

			displayEvents = _.sortBy(displayEvents, 'date');
			events = displayEvents.reverse();
			displayEvents = null;
			return events;
		};

		$scope.buildUrl = function (attachment) {
			if (attachment.type === 'Template') {
				var params = attachment.value.split('&').reduce(function (params, keyValue) {
					var values = keyValue.split('=');
					params[values[0]] = values[1];
					return params;
				}, {});

				var url =
					URL +
					API +
					customerId +
					'/order/' +
					params.entityId +
					'/documents/' +
					params.templateId +
					'?fileFormat=pdf&filename=' +
					attachment.filename;

				if (params.contactId) {
					url += '&contactId=' + params.contactId;
				}
				return url;
			} else {
				return URL + API + customerId + '/resources/download/internal/' + attachment.value + '?inline=true';
			}
		};

		$scope.copy = function () {
			var params = {
				customerId: customerId,
				copy: $scope.mail.id,
				campaignId: ($scope.mail.campaign || {}).id,
				type: $scope.mail.campaign ? 'campaign' : 'mail'
			};

			$scope.reject($upModal.open('sendEmail', params));
		};

		$scope.quickCreateActivity = function () {
			$upModal.open('editActivity', {
				activity: {
					contacts: $scope.mail.contact,
					description: $filter('translate')('mail.followUp') + ': ' + $scope.mail.subject
				}
			});
			$scope.reject();
		};

		$scope.quickCreatePhonecall = function () {
			openDrawer('CreateCall', { contact: $scope.mail.contact, client: $scope.mail.client });
			$scope.reject();
		};

		$scope.edit = function () {
			var params = {
				customerId: customerId,
				edit: $scope.mail.id
			};
			if (isMailCampaign) {
				params.campaignId = $scope.mail.project.id;
			} else {
				params.contactId = $scope.mail.contact.id;
			}
			$scope.reject($upModal.open('sendEmail', params));
		};

		$scope.getSender = function () {
			if ($scope.mailAccount && $scope.mail.from === $scope.mailAccount.defaultEmail) {
				return $scope.mailAccount.defaultFrom;
			}
			return $scope.user.length === 1 ? $scope.user[0].name : undefined;
		};

		$scope.delete = function () {
			return $upModal
				.open('warningConfirm', {
					title: 'confirm.removeScheduledEmail',
					body: 'confirm.removeScheduledEmailExplain',
					resolveTrue: 'default.delete',
					no: 'default.abort',
					icon: 'fa-warning'
				})
				.then(function () {
					Mail.customer(customerId)
						.delete($scope.mail)
						.then(function () {
							$scope.reject();
						})
						.catch(err => console.error(err));
				});
		};

		const wrapMailBody = body => {
			return `<html><head>
				<style>
					html {
						font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
					}
				</style>
			</head><body>${body}</body></html>`;
		};

		var init = function () {
			var meta = $modalParams.meta;
			$scope.mail = meta.mail;
			const isHtmlRegex = /^<[a-z!/][\s\S]*>/i;
			const mailBody = wrapMailBody($scope.mail.body.trim());
			$scope.hasActivities = !FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
			$scope.hasPhonecall = FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			$scope.mail.body = isHtmlRegex.test(mailBody) ? mailBody : mailBody.replace(/\n/g, '<br />');
			$scope.mailAccount = meta.mailAccount.data;
			$scope.user = meta.user.data;
			$scope.sender = $scope.getSender();
			$scope.mail.events = prettyMailEvents($scope.mail.events);
			$scope.body = $sce.trustAsHtml(MailBodyParser.parse($scope.mail));
			$scope.scheduled = $scope.mail.type === 'sch' || $scope.mail.status === 'SCHEDULED';
			$scope.draft = $scope.mail.status === 'DRAFT';
			$scope.template = meta.template.data;
			$scope.parsedSubject = MailBodyParser.parseSubject($scope.mail);
		};

		init();
	}
]);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	filter: PropTypes.object,
	dataCache: PropTypes.object,
	filterName: PropTypes.string,
	customerId: PropTypes.any,
	onPageChange: PropTypes.func,
	onChange: PropTypes.func,
	'data-test-id': PropTypes.any
};

class VirtualizedList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null
		};
	}

	componentDidMount() {
		var filter = this.props.filter;
		var promise = null;

		if (filter.dataPromise) {
			promise = this.props.dataCache.get(this.props.filterName + 'dataPromise');

			if (promise === undefined) {
				promise = Tools.$injector.invoke(
					filter.dataPromise,
					{},
					{ customerId: this.props.customerId, filterConfig: filter, filterName: this.props.filterName }
				);
				this.props.dataCache.set(this.props.filterName + 'dataPromise', promise);
			}
		} else if (filter.resource) {
			var resource = Tools.$injector.get(filter.resource);

			if (resource.customer) {
				resource = resource.customer(this.props.customerId);
			}

			if (filter.resourceType) {
				resource = resource.setType(filter.resourceType);
			}

			promise = resource.find({});

			this.props.dataCache.set(this.props.filterName + 'dataPromise', promise);
		}

		this.setState({
			data: promise
		});
	}

	toggleExpanded() {
		this.props.onPageChange(
			{
				component: ReactTemplates.upFilters.components.virtualizedListPage,
				getTitle: function () {
					return Tools.$translate(this.props.filter.title);
				}.bind(this),
				props: {
					data: this.state.data,
					filterName: this.props.filterName,
					filter: this.props.filter,
					dataCache: this.props.dataCache
				}
			},
			false
		);
	}

	onRemoveFilter(event) {
		this.setState({
			data: null
		});
		this.props.onChange(
			{
				filterName: this.props.filterName
			},
			{
				action: 'remove'
			}
		);
		ReactTemplates.TOOLS.stopProp(event);
	}

	formatValueString() {
		var filter = this.props.filter;
		var valueString = '';

		if (filter.value && filter.value.length) {
			valueString = filter.value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
		} else {
			valueString = Tools.$translate('default.all');
		}
		return valueString;
	}

	render() {
		var filter = this.props.filter;
		var active = filter.value && filter.value.length > 0;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		return (
			<div data-test-id={this.props['data-test-id']} className="list-filters-row">
				<div onClick={() => this.toggleExpanded()} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span
							data-test-id="listfilters-filter-clearfilter"
							className="delete-button pull-right"
							onClick={e => this.onRemoveFilter(e)}
						>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>
			</div>
		);
	}
}

VirtualizedList.propTypes = propTypes;

window.VirtualizedList = VirtualizedList;
export default VirtualizedList;

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from 'Store';

import { stopEdit } from 'Store/reducers/MailSignatureReducer';
import EditMailSignatureModal from './EditMailSignatureModal';

class EditMailSignatureRoot extends React.Component {
	constructor(props) {
		super(props);
	}

	componentWillUnmount() {
		store.dispatch(stopEdit());
	}

	render() {
		const { reject, edit, onSave } = this.props;

		return (
			<Provider store={store}>
				<EditMailSignatureModal
					onClose={reject}
					data={edit}
					onSave={data => {
						onSave(data);
						reject();
					}}
				/>
			</Provider>
		);
	}
}

EditMailSignatureRoot.propTypes = {
	reject: PropTypes.func,
	resolve: PropTypes.func,
	onSave: PropTypes.func,
	edit: PropTypes.object
};

export default EditMailSignatureRoot;

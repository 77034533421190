import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';

import { connect } from 'react-redux';
import {
	Loader,
	Title,
	Text,
	Button,
	Input,
	Label,
	Row,
	Column,
	Card,
	CardHeader,
	Table,
	TableHeader,
	TableRow,
	TableColumn
} from '@upsales/components';
import { navigateNext } from 'Store/reducers/OnboardingReducer';
import { makeCancelable } from '../../../helpers/promise';

const mapStateToProps = state => ({
	isLastUnfinishedStep: state.Onboarding.isLastUnfinishedStep
});

const mapDispatchToProps = {
	navigateNext
};

class Domain extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			termsAccepted: false,
			domains: [],
			domain: '',
			email: '',
			name: '',
			loading: false,
			mailAccount: null
		};

		this.customButton = true;

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.domain.title'),
			terms: {
				title: T('onboarding.step.domain.terms.title'),
				subtitle: T('onboarding.step.domain.terms.subtitle'),
				description1: T('onboarding.step.domain.terms.desc1'),
				description2: T('onboarding.step.domain.terms.desc2'),
				list: [
					{
						point: T('onboarding.step.domain.terms.list.1'),
						sub: [T('onboarding.step.domain.terms.list.1.1'), T('onboarding.step.domain.terms.list.1.2')]
					},
					{
						point: T('onboarding.step.domain.terms.list.2'),
						sub: [
							T('onboarding.step.domain.terms.list.2.1'),
							T('onboarding.step.domain.terms.list.2.2'),
							T('onboarding.step.domain.terms.list.2.3'),
							T('onboarding.step.domain.terms.list.2.4')
						]
					}
				]
			},
			next: T('default.next'),
			acceptTerms: T('mail.termsAccept'),
			domainDescription: T('onboarding.step.domain.domain.title'),
			domainSubtitle: T('onboarding.step.domain.domain.subtitle'),
			senderProfileDescription: T('mail.defaultSenderInfo'),
			presetEmail: T('mail.presetEmail'),
			presetSender: T('mail.presetSender'),
			placeholder: {
				email: T('info@company.com'),
				user: T('segment.placeholder.NameList'),
				domain: T('company.com')
			},
			add: T('add'),
			update: T('default.update'),
			back: T('default.back'),
			cname: {
				title: T('onboarding.step.domain.cname.title'),
				description: T('onboarding.step.domain.cname.description'),
				cardHeader: T('onboarding.step.domain.cname.cardHeader'),
				changeDomain: T('onboarding.step.domain.cname.changeDomain'),
				table: {
					header: {
						host: T('onboarding.step.domain.cname.tableHeader.host'),
						data: T('onboarding.step.domain.cname.tableHeader.data')
					}
				}
			},
			saveAndFinish: T('default.saveAndFinish')
		};

		this.updateOrCreateMailAccount = this.updateOrCreateMailAccount.bind(this);
		this.renderCNAME = this.renderCNAME.bind(this);
		this.specialButton = this.specialButton.bind(this);
		this.onClickAcceptTerms = this.onClickAcceptTerms.bind(this);
		this.onClickRegular = this.onClickRegular.bind(this);
	}

	componentWillUnmount() {
		this.cancelablePromise.cancel();
	}

	componentDidMount() {
		const promise = Promise.all([
			Tools.MailAccount.get().catch(() => null),
			Tools.MailAccount.domains().catch(() => null)
		]);

		this.cancelablePromise = makeCancelable(promise);

		this.cancelablePromise.promise
			.then(([mailAccountResult, domainResult]) => {
				const mailAccount = mailAccountResult && mailAccountResult.data ? mailAccountResult.data : null;
				const domains = domainResult && domainResult.data ? domainResult.data : [];
				const newState = { domains, mailAccount };
				newState.state = !mailAccount ? 'terms' : !(domains && domains.length) ? 'config' : 'list';

				if (mailAccount) {
					newState.email = mailAccount.defaultEmail;
					newState.name = mailAccount.defaultFrom;
				}
				this.setState(newState);
			})
			.catch(error => {
				if (!error.isCanceled) {
					console.error(error);
				}
			});
	}

	renderTerms() {
		const TermsClass = new Bem('DomainTerms');
		const langTerms = this.lang.terms;

		return (
			<div className={TermsClass}>
				<Title>{langTerms.title}</Title>
				<Text>{langTerms.description1}</Text>
				<Text>{langTerms.description2}</Text>
				<Title className={TermsClass.elem('Subtitle').b()}>{langTerms.subtitle}</Title>
				<ol className={TermsClass.elem('List').b()}>
					{langTerms.list.map((item, index) => {
						return (
							<li key={`subpoint-${index}-${item.title}`}>
								{item.point}
								<ol>
									{item.sub.map((subitem, subindex) => {
										return <li key={subindex}>{subitem}</li>;
									})}
								</ol>
							</li>
						);
					})}
				</ol>
			</div>
		);
	}

	onClickAcceptTerms() {
		this.setState({ state: 'config' });
	}

	onClickRegular() {
		const { navigateNext } = this.props;
		navigateNext({ setAsComplete: true });
	}

	onChange(key, value) {
		const state = Object.assign({}, this.state);

		state[key] = value;

		this.setState(state);
	}

	getDefaultMailAccount() {
		const customerId = Tools.AppService.getCustomerId();

		return {
			acceptedTermsDate: true,
			activeDays: '',
			name: '',
			phone: '',
			address: '',
			city: '',
			zipCode: '',
			country: '',
			customerId: customerId,
			defaultEmail: '',
			defaultFrom: '',
			disclaimer: '',
			endTime: 15,
			startTime: 7,
			id: null,
			mailClickScore: 1,
			mailOpenScore: 0,
			mailUnsubscribeScore: -5
		};
	}

	updateOrCreateMailAccount() {
		this.setState({ loading: true });

		const { email, name, mailAccount, domain } = this.state;
		const isNew = !mailAccount;
		const accountToSave = isNew
			? { ...this.getDefaultMailAccount(), defaultEmail: email, defaultFrom: name }
			: { ...mailAccount, defaultEmail: email, defaultFrom: name };
		const method = isNew ? 'save' : 'update';

		const promise = Tools.MailAccount[method](accountToSave).then(({ data: mailAccount }) => {
			const metadata = Tools.AppService.getMetadata();
			metadata.map.mail = true;
			metadata.map.mailActivated = true;
			Tools.AppService.setMetadata(metadata);

			const state = { loading: false, mailAccount, state: 'list' };

			if (isNew) {
				return Tools.MailAccount.addDomain({ domain: domain, adding: true, valid: false })
					.then(() => Tools.MailAccount.domains()) // Need to fetch domains as addDomain does not return the newly created domain
					.then(({ data: domains }) => ({ ...state, domains }));
			} else {
				return state;
			}
		});

		this.cancelablePromise = makeCancelable(promise);

		this.cancelablePromise.promise
			.then(newState => {
				this.setState(newState);
			})
			.catch(error => {
				if (!error.isCanceled) {
					this.setState({ loading: false });
					console.error(error);
				}
			});
	}

	renderCNAME() {
		const ContentClass = new Bem('DomainContent');
		const domains = this.state.domains;
		const dns = domains.length ? domains[0].dns : {};
		const tablesRows = [];

		for (const row in dns) {
			tablesRows.push(
				<TableRow key={`dns-row-${row}`}>
					<TableColumn>{dns[row].host}</TableColumn>
					<TableColumn>{dns[row].data}</TableColumn>
				</TableRow>
			);
		}

		return (
			<div className={ContentClass.b()}>
				<Title>{this.lang.cname.title}</Title>
				<Text style={{ margin: '30px 0' }}>{this.lang.cname.description}</Text>

				<Card>
					<CardHeader title={this.lang.cname.cardHeader}>
						<Button onClick={() => this.setState({ state: 'config' })} type="link">
							{this.lang.cname.changeDomain}
						</Button>
					</CardHeader>
					<Table>
						<TableHeader
							columns={[
								{ title: this.lang.cname.table.header.host },
								{ title: this.lang.cname.table.header.data }
							]}
						/>
						{tablesRows}
					</Table>
				</Card>
			</div>
		);
	}

	renderContent() {
		const { email, name, loading, domains, domain, mailAccount } = this.state;
		const ContentClass = new Bem('DomainContent');

		const addButtonDisabled = (email, name) => (email && name && (mailAccount || domain) ? false : true);

		return (
			<div className={ContentClass} style={{ maxWidth: '510px' }}>
				<Title>{this.lang.title}</Title>
				<Text>{this.lang.senderProfileDescription}</Text>
				<Row>
					<Column>
						<Label>{this.lang.presetEmail}</Label>
						<Input
							value={email}
							onChange={e => this.onChange('email', e.target.value)}
							placeholder={this.lang.placeholder.email}
						/>
					</Column>
					<Column>
						<Label>{this.lang.presetSender}</Label>
						<Input
							value={name}
							onChange={e => this.onChange('name', e.target.value)}
							placeholder={this.lang.placeholder.user}
						/>
					</Column>
				</Row>
				{!mailAccount ? (
					<Row>
						<Column>
							<Label>{this.lang.domainSubtitle}</Label>
							<Input
								value={domain}
								onChange={e => this.onChange('domain', e.target.value)}
								placeholder={this.lang.placeholder.domain}
							/>
						</Column>
					</Row>
				) : null}
				{domains && domains.length ? (
					<Row className={ContentClass.elem('Domains').mod({ 'have-domain': true }).b()}>
						<Column>
							<Button
								shadow="none"
								color="light-grey"
								onClick={() => this.setState({ state: 'list' })}
								className={ContentClass.elem('AddButton')}
							>
								{this.lang.back}
							</Button>
							<Button
								shadow="none"
								onClick={this.updateOrCreateMailAccount}
								className={ContentClass.elem('AddButton')}
								disabled={addButtonDisabled(email, name)}
								loading={loading}
							>
								{this.lang.update}
							</Button>
						</Column>
					</Row>
				) : (
					<Button
						size="xl"
						onClick={this.updateOrCreateMailAccount}
						style={{ width: '100%', marginTop: '40px' }}
						className="StepWrap__Button"
						disabled={addButtonDisabled(email, name)}
						loading={loading}
					>
						{this.lang.next}
					</Button>
				)}
			</div>
		);
	}

	specialButton() {
		const { state, domains } = this.state;
		const isLastUnfinishedStep = this.props.isLastUnfinishedStep;
		let buttonText, onClick;

		if (domains.length) {
			buttonText = isLastUnfinishedStep ? this.lang.saveAndFinish : this.lang.next;
			onClick = this.onClickRegular;
		}

		if (state === 'terms') {
			buttonText = this.lang.acceptTerms;
			onClick = this.onClickAcceptTerms;
		}

		if (state === 'terms' || (state === 'list' && domains.length)) {
			return (
				<Button block size="xl" className="StepWrap__Button" onClick={onClick}>
					{buttonText}
				</Button>
			);
		}

		return null;
	}

	getContent() {
		const { state } = this.state;
		const DomainClass = new Bem('Domain');

		switch (state) {
			case 'list':
				return this.renderCNAME();
			case 'config':
				return this.renderContent();
			case 'terms':
				return this.renderTerms();
			case 'loading':
			default:
				return (
					<div className={DomainClass.elem('Loading').b()}>
						<Loader />
					</div>
				);
		}
	}

	render() {
		const DomainClass = new Bem('Domain');

		return (
			<div className={DomainClass.b()}>
				{this.getContent()}
				{this.specialButton()}
			</div>
		);
	}
}

Domain.propTypes = {
	navigateNext: PropTypes.func,
	isLastUnfinishedStep: PropTypes.bool
};

export const detached = Domain;
export default {
	default: connect(mapStateToProps, mapDispatchToProps)(Domain)
};

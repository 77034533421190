import LandingpageSettings from 'Components/LandingpageSettings';

angular.module('domain.form', ['ngRoute', 'upResources', 'security.authorization', 'ui.router']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		var listTemplateProvider = function ($http, AppService) {
			return AppService.loadedPromise.then(function () {
				var path = 'upsales/common/directives/templates/upListView2.tpl.html';

				return $http.get(path).then(function (res) {
					return res.data;
				});
			});
		};
		listTemplateProvider.$inject = ['$http', 'AppService'];

		$stateProvider
			.state('landingpageEditor', {
				templateUrl: require('App/upsales/domain/form/views/landingpageEditor.html?file'),
				url: '/:customerId/forms/landingpageEditor/:id?copy',
				controller: 'LandingpageEditor as LandingpageEditor',
				section: 'market',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							// TODO: Remove the first check when this has been live for a while (scopes need to be refrshed)
							return (
								FeatureHelper.placeholder(FeatureHelper.Feature.FORMS) ||
								FeatureHelper.placeholder(FeatureHelper.Feature.FORMS_BACKEND)
							);
						}
					],
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					authRights: [
						'$routeGuard',
						'$stateParams',
						function ($routeGuard, $stateParams) {
							return $routeGuard.requireAdminOrMailAdmin($stateParams);
						}
					],
					meta: [
						'$stateParams',
						'LandingpageEditorMeta',
						'$state',
						function ($stateParams, LandingpageEditorMeta) {
							var promise = LandingpageEditorMeta($stateParams);
							promise.catch(function () {
								history.push('/forms');
							});
							return promise;
						}
					]
				}
			})
			.state('form.submits', {
				templateUrl: require('App/upsales/domain/form/views/formSubmits.html?file'),
				section: 'market',
				url: 'submits',
				controller: 'FormSubmit as FormSubmit'
			});

		$upModalProvider
			.modal('createForm', {
				template: '<div></div>',
				noCloseOnStateChange: true,
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, window.CreateForm, $element[0], $modalParams, { modal: true });
				},
				style: 'form-wide default new-full-screen'
			})
			.modal('editFormSubmit', {
				templateUrl: require('App/upsales/domain/form/views/editFormSubmit.html?file'),
				controller: 'EditFormSubmit as EditFormSubmit',
				resolve: {
					optIns: function (OptIn) {
						return OptIn.find();
					}
				},
				style: 'default'
			})
			.modal('confirmLandingpage', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					window.SetupComponent($scope, window.ConfirmLandingPage, null, $modalParams, { modal: true });
				},
				style: 'confirm'
			})
			.modal('landingpageSettings', {
				template: '<div></div>',
				controllerProvider: function () {
					return function ($scope, $modalParams) {
						window.SetupComponent($scope, LandingpageSettings, null, $modalParams, {
							modal: true,
							redux: true
						});
					};
				},
				style: function () {
					return 'form-sm no-modal-overflow';
				}
			})
			.modal('selectField', {
				templateUrl: require('App/upsales/domain/form/views/selectField.tpl.html?file'),
				controller: 'SelectField as SelectField',
				style: 'confirm default bg-gray'
			})
			.modal('validDomains', {
				template: '<div></div>',
				controller: function ($scope, $translate, BusinessEmail, DEFAULT_INVALID_DOMAINS) {
					new window.ValidDomainController($scope, $translate, BusinessEmail, DEFAULT_INVALID_DOMAINS);
				},
				style: 'default form-sm'
			})
			.modal('landingpageEditor', {
				templateUrl: require('App/upsales/domain/form/views/landingpageEditor.html?file'),
				controller: 'LandingpageEditorModal as LandingpageEditor',
				resolve: {
					isAvailable: function (FeatureHelper, $q) {
						// TODO: Remove the first check when this has been live for a while (scopes need to be refrshed)
						if (
							!FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS) &&
							!FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS_BACKEND)
						) {
							return $q.reject();
						}
					},
					meta: function ($modalParams, LandingpageEditorMeta) {
						return LandingpageEditorMeta($modalParams);
					}
				},
				style: 'form-wide default new-full-screen'
			});
	}
]);

'use strict';

angular.module('upDirectives').directive('upCreativeTemplatesRoot', [
	'$translate',
	'$parse',
	'$upModal',
	'$state',
	'localStorageService',
	'$stateParams',
	function ($translate, $parse, $upModal, $state, localStorageService, $stateParams) {
		return {
			restrict: 'A',
			template: '<div></div>',
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var rootData;

				var renderTimeout;
				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (rootData && firstElement) {
							ReactDOM.render(
								React.createElement(ReactTemplates.creativeTemplates.root, {
									// Data and stuff
									rootData: rootData,

									// Injects (do not remove anything here!!!)
									tools: {
										$translate: $translate.instant,
										$upModal: $upModal,
										$state: $state,
										localStorageService: localStorageService,
										$stateParams: $stateParams
									}
								}),
								firstElement,
								function () {}
							);
						}
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					$attr.upCreativeTemplatesRoot,
					function (data) {
						rootData = data;
						render();
					},
					true
				);
			}
		};
	}
]);

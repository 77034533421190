'use strict';

angular.module('upDirectives').directive('upRecipientSelect', [
	'$q',
	'Contact',
	'RequestBuilder',
	'AppService',
	'selectHelper',
	'$translate',
	'$parse',
	function ($q, Contact, RequestBuilder, AppService, selectHelper, $translate, $parse) {
		var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
		var contactDictTag = $translate.instant('default.contacts');
		var userDictTag = $translate.instant('default.users');
		var clientId = null;

		return selectHelper.getDirectiveObject('upRecipientSelect', {
			resource: Contact,
			limit: 1, // force ajax so clientId is fresh...
			ajax: true,
			asIds: false,
			idAttr: Contact.attr.id,
			titleAttr: Contact.attr.name,
			sorting: { field: Contact.attr.name, ascending: true },
			emptyMsg: $translate.instant('default.noEmail'),
			select2: {
				minimumInputLength: 0
			},
			getter: function (customerId, filter, query, scope, attrs) {
				var contactBuilder = new RequestBuilder();
				contactBuilder.limit = 10;
				contactBuilder.addSort(Contact.attr.active);
				contactBuilder.addSort(Contact.attr.name, true);

				if (query) {
					var or = contactBuilder.orBuilder();
					or.next();
					or.addFilter(Contact.attr.name, contactBuilder.comparisonTypes.Wildcard, query);
					or.next();
					or.addFilter(Contact.attr.email, contactBuilder.comparisonTypes.Wildcard, query);
					or.done();
				}

				contactBuilder.addFilter(Contact.attr.email, contactBuilder.comparisonTypes.NotEquals, null);
				contactBuilder.addFilter(Contact.attr.active, contactBuilder.comparisonTypes.Equals, true);

				if (attrs && attrs.clientId) {
					clientId = $parse(attrs.clientId)(scope);
					if (clientId) {
						var clientRelationOr = contactBuilder.orBuilder();
						clientRelationOr.next();
						clientRelationOr.addFilter(
							Contact.attr.account,
							contactBuilder.comparisonTypes.Equals,
							clientId
						);
						clientRelationOr.next();
						clientRelationOr.addFilter(
							Contact.attr.connectedClients,
							contactBuilder.comparisonTypes.Equals,
							clientId
						);
						clientRelationOr.done();
					}
				}

				var queries = [Contact.customer(customerId).find(contactBuilder.build())];

				return $q.all(queries).then(function (values) {
					var dataResult = [];

					if (values[0].data.length) {
						dataResult.push({ name: contactDictTag, children: values[0].data });
					}

					var users = AppService.getActiveUsers();

					if (query) {
						users = _.chain(users)
							.reduce(function (result, user) {
								user.score = 0;
								var searchTermIndexName = user.name?.toLowerCase().indexOf(query.toLowerCase());
								var searchTermIndexEmail = user.email?.toLowerCase().indexOf(query.toLowerCase());

								user.score += Math.max(searchTermIndexName, searchTermIndexEmail) > -1 ? 1 : 0; // Name contains search term
								user.score += searchTermIndexName === 0 || searchTermIndexEmail === 0 ? 3 : 0; // Search term is first in name
								user.score +=
									searchTermIndexName !== 0 && user.name[searchTermIndexName - 1] === ' ' ? 2 : 0; // Search term is before a space
								if (user.score) {
									result.push(user);
								}

								return result;
							}, [])
							.value();

						users.sort(function (user1, user2) {
							if (user1.score < user2.score) {
								return 1;
							} else if (user2.score < user1.score) {
								return -1;
							}

							if (user1.name < user2.name) {
								return 1;
							} else if (user2.name < user1.name) {
								return -1;
							}

							return 0;
						});
					}

					if (users.length) {
						dataResult.push({ name: userDictTag, children: users });
					}

					var total = values[0].metadata.total + users.length;
					return $q.when({ data: dataResult, metadata: { total: total } });
				});
			},
			formatResult: function (obj, elm, x, encode) {
				if (obj.isEmail) {
					return $translate.instant('default.add') + ': ' + _.escape(obj.name);
				} else if (obj.id && !obj.isEmail) {
					var relatedContact =
						obj.connectedClients && _.find(obj.connectedClients, { relatedToClientId: clientId });
					var prefix = relatedContact ? '<i class="fa fa-sitemap" style="margin-right:5px;"></i>' : '';
					return prefix + encode(obj.name) + '<i class="pull-right">' + encode(obj.email) + '</i>';
				}
				return obj.name;
			},
			createSearchChoice: function (term) {
				if (emailRegex.test(term)) {
					return { id: term, name: term, email: term, isEmail: true };
				} else {
					return null;
				}
			},
			data: function () {
				return [];
			}
		});
	}
]);

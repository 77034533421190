'use strict';

angular.module('domain.form').controller('SelectField', [
	'$scope',
	'$translate',
	'$modalParams',
	function ($scope, $translate, $modalParams) {
		var SelectField = this;

		SelectField.datatypes = [
			{ title: 'datatype.Text', value: 'text', classNames: { 'fa fa-font': true } },
			{ title: 'datatype.Date', value: 'date', classNames: { 'fa fa-calendar-o': true } },
			{ title: 'datatype.Number', value: 'number', classNames: { 'fa fa-dollar': true } },
			{ title: 'datatype.Checkbox', value: 'checkbox', classNames: { 'fa fa-check-square-o': true } },
			{ title: 'datatype.Select', value: 'select', classNames: { 'fa fa-caret-square-o-down': true } },
			{ title: 'datatype.Radio', value: 'radio', classNames: { 'fa fa-dot-circle-o': true } },
			{ title: 'datatype.Textarea', value: 'textarea', classNames: { 'fa fa-square-o': true } }
		];

		if (Tools.FeatureHelper.hasSoftDeployAccess('FORM_PASSWORD')) {
			SelectField.datatypes.push({
				title: 'datatype.Password',
				value: 'password',
				classNames: { 'fa fa-lock': true }
			});
		}

		SelectField.selectType = function (typeObj) {
			var field = {
				title: $translate.instant(typeObj.title),
				name: 'Extra',
				datatype: typeObj.value,
				type: 'extra',
				disabled: false,
				options: null
			};

			$scope.resolve(field);
		};

		SelectField.selectField = function (field) {
			$scope.resolve(field);
		};

		var init = function () {
			var groups = {
				Client: { title: 'form.client.standard', fields: [], sort: 1 },
				ClientCustom: { title: 'form.client.cf', fields: [], sort: 2 },
				Contact: { title: 'form.contact.standard', fields: [], sort: 3 },
				ContactCustom: { title: 'form.contact.cf', fields: [], sort: 4 },
				NotAvailable: { title: 'form.contact.notAvailable.forms', fields: [], sort: 5, disabled: true }
			};

			if (_.some(_.map($modalParams.availableOptIns, 'length'))) {
				var fields = _.chain($modalParams.availableOptIns)
					.map(function (arr, type) {
						if (arr.length > 0) {
							return {
								title: 'form.' + type + 'OptIn',
								name: type + 'OptIn.' + Date.now(),
								datatype: 'optIn',
								type: 'standard',
								optInType: type,
								disabled: false
							};
						}
					})
					.sortBy(function (optin) {
						return optin && optin.optInType === 'single';
					})
					.reverse()
					.value();
				groups.OptIn = { title: 'form.optIn', fields: _.filter(fields), sort: 0 };
			}

			_.each($modalParams.availableFields, function (field) {
				if (field.datatype === 'user' || field.datatype === 'users') {
					groups.NotAvailable.fields.push(field);
				} else if (field.name.indexOf('Client.custom_') === 0) {
					groups.ClientCustom.fields.push(field);
				} else if (field.name.indexOf('Client.') === 0) {
					groups.Client.fields.push(field);
				} else if (field.name.indexOf('Contact.custom_') === 0) {
					groups.ContactCustom.fields.push(field);
				} else if (field.name.indexOf('Contact') === 0) {
					groups.Contact.fields.push(field);
				}
			});

			SelectField.groups = _.values(groups);
		};

		init();
	}
]);

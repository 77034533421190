import React from 'react';
import { connect } from 'react-redux';
import OrderStage from 'App/resources/Model/OrderStage';
import {
	Toggle,
	Card,
	Headline,
	Text,
	Table,
	TableRow,
	TableHeader,
	TableColumn,
	Link,
	Button,
	Icon,
	Title,
	Help,
	Tooltip
} from '@upsales/components';
import {
	save,
	setSolution,
	setBudget,
	setNextStep,
	setDecisionMakersActive,
	setTimeframe,
	toggleChecklistItem,
	removeChecklistItem,
	loadCheckboxItems
} from 'Store/reducers/SalesCoachReducer';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import {
	Budget,
	Solution,
	NextStep,
	DecisionMakers,
	Timeframe,
	DecisionMakersPro,
	SalesCoachChecklistItem,
	SalesCoachChecklistItemStage
} from 'App/resources/Model/SalesCoach';
import ShownInStages from 'Components/Admin/ShownInStages';
import { isEqual } from 'lodash';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

import './Checklist.scss';

type Row = {
	text: string;
	id: string;
	modalName: string;
	active: boolean;
	shownsIn?: string[];
	entireSalesProcess?: boolean;
	toggle: (value: boolean) => void;
};

const mapStateToProps = ({
	SalesCoachReducer
}: {
	SalesCoachReducer: {
		id: number | null;
		budget: Budget;
		solution: Solution;
		nextStep: NextStep;
		decisionMakers: DecisionMakers;
		decisionMakersPro: DecisionMakersPro;
		timeframe: Timeframe;
		checklist: SalesCoachChecklistItem[];
	};
}) => ({
	id: SalesCoachReducer.id,
	budget: SalesCoachReducer.budget,
	solution: SalesCoachReducer.solution,
	nextStep: SalesCoachReducer.nextStep,
	decisionMakers: SalesCoachReducer.decisionMakers,
	decisionMakersPro: SalesCoachReducer.decisionMakersPro,
	timeframe: SalesCoachReducer.timeframe,
	checklist: SalesCoachReducer.checklist
});

const mapDispatchToProps = {
	save,
	setSolution,
	setBudget,
	setNextStep,
	setDecisionMakersActive,
	setTimeframe,
	toggleChecklistItem,
	removeChecklistItem,
	loadCheckboxItems
};

type Props = {
	id: number | null;
	save: () => void;
	budget: Budget;
	solution: Solution;
	nextStep: NextStep;
	decisionMakers: DecisionMakers;
	decisionMakersPro: DecisionMakersPro;
	timeframe: Timeframe;
	setBudget: (budget: object) => void;
	setSolution: (solution: object) => void;
	setNextStep: (nextStep: object) => void;
	setDecisionMakersActive: (value: boolean) => void;
	setTimeframe: (timeframe: object) => void;
	checklist: SalesCoachChecklistItem[];
	toggleChecklistItem: (checklistItem: SalesCoachChecklistItem) => void;
	removeChecklistItem: (checklistItem: SalesCoachChecklistItem) => void;
	loadCheckboxItems: () => void;
};
class Checklist extends React.Component<Props> {
	lang: { [key: string]: string };
	hasSalesProcessPro: boolean;
	stages: OrderStage[];
	fakeIdItr: number;

	constructor(p: Props) {
		super(p);

		this.lang = {
			alwaysHavingANextStep: T('admin.newSalesProcess.alwaysHavingANextStep'),
			verifyBudget: T('admin.newSalesProcess.verifyBudget'),
			verifyOurSolution: T('admin.newSalesProcess.verifyOurSolution'),
			identifyDescisionMakers: T('admin.newSalesProcess.identifyDescisionMakers'),
			entireSalesProcess: T('admin.newSalesProcess.entireSalesProcess'),
			title: T('admin.newSalesProcess.title'),
			shownsIn: T('admin.newSalesProcess.shownsIn'),
			status: T('admin.newSalesProcess.status'),
			viewAddOn: T('admin.newSalesProcess.viewAddOn'),
			salesProcessChecklist: T('admin.newSalesProcess.salesProcessChecklist'),
			thingsToVerify: T('admin.newSalesProcess.thingsToVerify'),
			salesCoachingFromUpsales: T('admin.newSalesProcess.salesCoachingFromUpsales'),
			createdByYou: T('admin.newSalesProcess.createdByYou'),
			addChecklistItem: T('admin.newSalesProcess.addChecklistItem'),
			readMore: T('default.readMore'),
			verifyTimeframe: T('admin.newSalesProcess.verifyTimeframe'),
			active: T('admin.newSalesProcess.active'),
			inactive: T('admin.newSalesProcess.inactive'),
			billingAdminRequired: T('admin.newSalesProcess.billingAdminRequired'),
			checklistAdText: T('admin.newSalesProcess.createOwnChecklistAdText'),
			createYourOwnChecklist: T('admin.newSalesProcess.createYourOwnChecklist'),
			salesProcessPro: T('admin.newSalesProcess.salesProcessPro')
		};
		this.hasSalesProcessPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SALES_PROCESS_PRO);
		this.stages = Tools.AppService.getStages();
		this.fakeIdItr = -2;
	}

	componentDidMount() {
		this.props.loadCheckboxItems();
	}

	getNameForStages(stages: { [id: string]: { active?: boolean } }) {
		const allStages = Tools.AppService.getStages();
		const active = [];
		for (const [key, value] of Object.entries(stages)) {
			if (value.active) {
				active.push(parseInt(key));
			}
		}
		return active.map((activeId: number) => allStages.find(({ id }) => id === activeId)?.name ?? '');
	}

	getStagesNames(stages: SalesCoachChecklistItemStage[]) {
		return stages.reduce((res: string[], { stageId }) => {
			const stage = this.stages.find(stage => stage.id === stageId);
			if (stage) {
				res.push(stage.name);
			}
			return res;
		}, []);
	}

	showInStages(row: Row) {
		const { shownsIn, entireSalesProcess, modalName } = row;

		if (entireSalesProcess && modalName !== 'DecisionMakersPro') {
			return <Text>{this.lang.entireSalesProcess}</Text>;
		}

		if (!shownsIn) {
			return;
		}

		if (modalName === 'DecisionMakersPro') {
			const {
				decisionMakersPro: { fields }
			} = this.props;

			const uniqueStages = new Set();
			fields
				.filter(field => field.active)
				.forEach(field => field.activeStages.forEach(stage => uniqueStages.add(stage.stageId)));

			const allStages = Tools.AppService.getStages();
			const mapped = Array.from(uniqueStages)
				.map(stageId => allStages.find(stage => stage.id === stageId)?.name)
				.filter(Boolean) as Array<string>;

			if (fields.find(field => field.active && field.activeStages.length === 0)) {
				mapped.push(this.lang.entireSalesProcess);
			}
			if (isEqual(mapped, [this.lang.entireSalesProcess])) {
				return <Text>{this.lang.entireSalesProcess}</Text>;
			}
			return <ShownInStages stages={mapped} maxCharCount={30} />;
		}
		return <ShownInStages stages={shownsIn} />;
	}

	getRow(row: Row) {
		return (
			<TableRow
				key={row.id}
				inactive={!row.active}
				onClick={() => (row.modalName ? this.openModal(row.modalName) : null)}
			>
				<TableColumn>
					<Text>{row.text}</Text>
					<Icon color="grey-8" name="edit" />
				</TableColumn>
				<TableColumn className="Checklist__rowStages">{this.showInStages(row)}</TableColumn>
				<TableColumn className="Checklist__rowStatus">
					<div
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							row.toggle?.(!row.active);
						}}
					>
						<Toggle checked={row.active} />
						<Text>{row.active ? this.lang.active : this.lang.inactive}</Text>
					</div>
				</TableColumn>
			</TableRow>
		);
	}

	getRowCustom(checklistItem: SalesCoachChecklistItem) {
		return (
			<TableRow
				key={checklistItem.id}
				inactive={!checklistItem.active}
				onClick={() => this.openModal('ChecklistItem', { checklistItem })}
			>
				<TableColumn>
					<Text>{checklistItem.title}</Text>
					<Icon color="grey-8" name="edit" />
				</TableColumn>
				<TableColumn className="Checklist__rowStages">
					<Text>
						{!checklistItem.stages.length ? (
							this.lang.entireSalesProcess
						) : (
							<ShownInStages stages={this.getStagesNames(checklistItem.stages)} />
						)}
					</Text>
				</TableColumn>
				<TableColumn className="Checklist__rowStatus">
					<div
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							this.props.toggleChecklistItem(checklistItem);
						}}
					>
						<Toggle checked={checklistItem.active} />
						<Text>{checklistItem.active ? this.lang.active : this.lang.inactive}</Text>
					</div>
					<InlineConfirm
						show
						onConfirm={() => {
							this.props.removeChecklistItem(checklistItem);
						}}
					>
						<Button type="link" color="grey" size="md">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</TableColumn>
			</TableRow>
		);
	}

	openModal = async (name: string, params = {}) => {
		const { id, save } = this.props;

		if (!id && name === 'DecisionMakersPro') {
			await save();
		}

		Tools.$upModal.open(name, params);
	};

	render() {
		const {
			budget,
			solution,
			nextStep,
			decisionMakers,
			decisionMakersPro,
			timeframe,
			setBudget,
			setSolution,
			setNextStep,
			setDecisionMakersActive,
			setTimeframe,
			checklist
		} = this.props;

		const salesCoachRows: Array<Row> = [
			{
				text: this.lang.alwaysHavingANextStep,
				id: 'nextStep',
				modalName: 'NextStep',
				active: nextStep.active,
				entireSalesProcess: true,
				toggle: (value: boolean) => {
					if (nextStep.active === null) {
						this.openModal('NextStep');
					} else {
						setNextStep({ ...nextStep, active: value });
					}
				}
			},
			{
				text: this.lang.verifyBudget,
				id: 'budget',
				modalName: 'VerifyBudget',
				active: budget.active,
				shownsIn: this.getNameForStages(budget.stages),
				entireSalesProcess: budget.entireSalesProcess,
				toggle: (value: boolean) => {
					if (budget.active === null) {
						this.openModal('VerifyBudget');
					} else {
						setBudget({ ...budget, active: value });
					}
				}
			},
			{
				text: this.lang.verifyOurSolution,
				id: 'solution',
				modalName: 'Solution',
				active: solution.active,
				shownsIn: this.getNameForStages(solution.stages),
				entireSalesProcess: solution.entireSalesProcess,
				toggle: (value: boolean) => {
					if (solution.active === null) {
						this.openModal('Solution');
					} else {
						setSolution({ ...solution, active: value });
					}
				}
			},
			{
				text: this.lang.verifyTimeframe,
				id: 'verifyTimeframe',
				active: timeframe.active,
				modalName: 'VerifyTimeframe',
				shownsIn: this.getNameForStages(timeframe.stages),
				entireSalesProcess: timeframe.entireSalesProcess,
				toggle: (value: boolean) => {
					if (timeframe.active === null) {
						this.openModal('VerifyTimeframe');
					} else {
						setTimeframe({ ...timeframe, active: value });
					}
				}
			},
			{
				text: this.lang.identifyDescisionMakers,
				id: 'descisionMakers',
				active: this.hasSalesProcessPro
					? decisionMakers.active && decisionMakersPro.fields.length > 0
					: decisionMakers.active && decisionMakers.titleCategories?.length !== 0,
				modalName: this.hasSalesProcessPro ? 'DecisionMakersPro' : 'DecisionMakers',
				shownsIn: this.getNameForStages(decisionMakers.stages),
				entireSalesProcess: decisionMakers.entireSalesProcess,
				toggle: (value: boolean) => {
					if (this.hasSalesProcessPro) {
						if (decisionMakers.active === null || decisionMakersPro.fields.length === 0) {
							this.openModal('DecisionMakersPro');
						} else {
							setDecisionMakersActive(value);
						}
						return;
					}
					if (
						!Tools.AppService.getMetadata().standardFields.Contact.TitleCategory?.active ||
						decisionMakers.titleCategories?.length === 0 ||
						decisionMakers.active === null
					) {
						this.openModal('DecisionMakers');
					} else {
						setDecisionMakersActive(value);
					}
				}
			}
		];
		const classNames = new BemClass('Checklist');
		const isBillingAdmin = Tools.AppService.getSelf().billingAdmin;
		const columns = [{ title: this.lang.title }, { title: this.lang.shownsIn }, { title: this.lang.status }];

		return (
			<div className={classNames.b()}>
				<Headline>{this.lang.salesProcessChecklist}</Headline>
				<div className={classNames.elem('description').b()}>
					<Text size="xl">{this.lang.thingsToVerify}</Text>
					<Help articleId={this.hasSalesProcessPro ? 1155 : 1266} sidebar>
						<Link>{this.lang.readMore}</Link>
					</Help>
				</div>
				<Card space="mtxl">
					<Title size="md">{this.lang.salesCoachingFromUpsales}</Title>
					<Table className={classNames.elem('rowHeader').b()}>
						<TableHeader columns={columns}></TableHeader>
						{salesCoachRows.map(row => this.getRow(row))}
					</Table>

					{this.hasSalesProcessPro ? (
						<>
							<div className={classNames.elem('middleRow').b()}>
								<Title size="md">{this.lang.createdByYou}</Title>
								<Button
									onClick={() =>
										this.openModal('ChecklistItem', {
											checklistItem: { id: this.fakeIdItr--, title: '', stages: [], active: true }
										})
									}
								>
									<Icon color="super-light-green" space="mrm" name="plus" />
									<Text color="super-light-green">{this.lang.addChecklistItem}</Text>
								</Button>
							</div>
							<Table className={classNames.elem('rowHeader').b()}>
								<TableHeader columns={columns}></TableHeader>
								{checklist.map(row => this.getRowCustom(row))}
							</Table>
						</>
					) : null}
				</Card>
				{!this.hasSalesProcessPro ? (
					<div className={classNames.elem('ownChecklistAd').mod({ disabled: !isBillingAdmin }).b()}>
						<div className="AdTexts">
							<Headline size="sm">{this.lang.createYourOwnChecklist}</Headline>
							<Text size="lg">{this.lang.checklistAdText}</Text>
							<div className="BoxText">{this.lang.salesProcessPro}</div>
						</div>
						<Tooltip title={this.lang.billingAdminRequired} position="top" disabled={isBillingAdmin}>
							<Button
								disabled={!isBillingAdmin}
								onClick={async () => {
									await save();
									Tools.$state.go('administration.billing', { initialTab: 'addons' });
								}}
							>
								{!isBillingAdmin ? <Icon name="ban" /> : null}
								{this.lang.viewAddOn}
							</Button>
						</Tooltip>
						<Help sidebar={true} articleId={1155}>
							<Icon name="question-circle" />
							<Text>{this.lang.readMore}</Text>
						</Help>
					</div>
				) : null}
			</div>
		);
	}
}

export const detached = Checklist;

const Component = connect(mapStateToProps, mapDispatchToProps)(Checklist);

export default Component;

import logError from 'Helpers/logError';

angular.module('domain.campaign', ['upResources', 'security.authorization', 'ui.router']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		mapMailActivated.$inject = ['$routeGuard', '$stateParams'];
		function mapMailActivated($routeGuard, $stateParams) {
			return $routeGuard.mapMailActivated($stateParams);
		}

		function sectionFn(accountSelf, self) {
			const products = accountSelf.products;
			const isAdmin = self.administrator;
			const marketAdmin = self.userParams.mailAdmin;
			const hasSegment = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SEGMENT);
			const showCampaignsInSalesSection =
				products.crm && (hasSegment || !products.ma || !(isAdmin || marketAdmin));
			return showCampaignsInSalesSection ? 'sale' : 'market';
		}

		$stateProvider
			.state('campaign', {
				abstract: true,
				templateUrl: require('App/upsales/domain/campaign/views/campaign.html?file'),
				url: '/:customerId/campaigns/:id/',
				controller: 'Campaign as CampaignCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.placeholder(FeatureHelper.Feature.PROJECTS);
						}
					],
					meta: [
						'$stateParams',
						'CampaignMeta',
						function ($stateParams, CampaignMeta) {
							return CampaignMeta($stateParams);
						}
					]
				}
			})
			.state('campaign.dashboard', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignDashboard.html?file'),
				url: '',
				controller: 'CampaignDashboard',
				section: 'sale',
				sectionFn: sectionFn
			})
			.state('campaign.contacts', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignContacts.html?file'),
				url: 'contacts/',
				controller: 'CampaignContacts as CampaignContactsCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('campaign.activities', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignActivities.html?file'),
				url: 'activities/',
				controller: 'CampaignActivities as CampaignActivitiesCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			})
			.state('campaign.appointments', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignAppointments.html?file'),
				url: 'appointments/',
				controller: 'CampaignAppointments as CampaignAppointmentsCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			})
			.state('campaign.opportunities', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignOpportunities.html?file'),
				url: 'opportunities/',
				controller: 'CampaignOpportunities',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PIPELINE);
						}
					]
				}
			})
			.state('campaign.order', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignOrder.html?file'),
				url: 'order/',
				controller: 'CampaignOrder as CampaignOrderCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ORDERS);
						}
					]
				}
			})
			.state('campaign.mailinglists', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignMailinglists.html?file'),
				controller: 'CampaignMailinglists as CampaignMailinglistsCtrl',
				url: 'mailinglists/',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					mapMailActivated: mapMailActivated,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.GROUP_MAIL);
						}
					]
				}
			})
			.state('campaign.accounts', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignAccounts.html?file'),
				url: 'accounts/',
				controller: 'CampaignAccounts as CampaignAccountsCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('campaign.socialEvents', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignSocialEvents.html?file'),
				url: 'socialEvents/',
				controller: 'CampaignSocialEvents as CampaignSocialEventsCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: function (FeatureHelper) {
						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.SOCIAL_EVENTS);
					}
				}
			})
			.state('campaign.sentMails', {
				templateUrl: require('App/upsales/domain/campaign/views/sentMails.html?file'),
				url: 'sentMails/',
				sectionFn: sectionFn,
				controller: [
					'Mail',
					'$stateParams',
					'$scope',
					function (Mail, $stateParams, $scope) {
						Mail.customer($stateParams.customerId)
							.find({ type: 'out', 'project.id': ~~$stateParams.id })
							.then(function (res) {
								$scope.mails = res.data;
							})
							.catch(logError);
					}
				],
				section: 'sale'
			})
			.state('campaign.forms', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignForms.html?file'),
				url: 'forms/',
				controller: 'CampaignForms as CampaignFormsCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.FORMS);
						}
					]
				}
			})
			.state('campaign.udo', {
				templateUrl: require('App/upsales/domain/campaign/views/campaignUdo.html?file'),
				url: 'udo/:udoId',
				controller: 'CampaignUdo as CampaignUdoCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.UDO);
						}
					]
				}
			});

		$upModalProvider.modal('editCampaign', {
			templateUrl: require('App/upsales/domain/campaign/views/editCampaign.html?file'),
			style: 'form default',
			controller: 'EditCampaign as EditCampaign',
			openAnimation: true,
			closeAnimation: true,
			rememberHeight: true,
			resolve: {
				meta: function ($modalParams, EditCampaignMeta) {
					return EditCampaignMeta($modalParams);
				}
			}
		});
	}
]);

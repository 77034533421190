import pdfTemplate from 'App/babel/resources/pdfTemplates';
import { SET_EDITOR_BODY, SET_PREVIEW, RESET_EDITOR, SET_INITIAL_HASH } from 'Store/reducers/TemplateEditorReducer';

// Show system notification
const showSystemNotification = (style, icon, title, body) => {
	Tools.NotificationService.addNotification({
		style: style,
		icon: icon,
		title: title,
		body: body
	});
};

// Get pdf from backend service and create url
const getPdfTemplate =
	(uuid, isSkeleton = false, type = 'custom') =>
	dispatch => {
		return new Promise(resolve => {
			const isCustom = type === 'custom';
			pdfTemplate
				.getPdf(uuid, '0', 'en', isCustom, isSkeleton)
				.then(response => {
					const bytes = new Uint8Array(response.data.body.data);
					const blob = new Blob([bytes], { type: 'application/pdf' });
					const link = window.URL.createObjectURL(blob);
					dispatch({ type: SET_PREVIEW, payload: { url: link, loading: false, error: null } });
					resolve();
				})
				.catch(error => {
					if (error.response.status === 400 && error.response.data.error.errorCode === 126) {
						showSystemNotification(
							Tools.NotificationService.style.ERROR,
							'error',
							'default.error',
							'admin.documentTemplate.standardTemplatesModal.errors.badData'
						);
					}
					dispatch({ type: SET_PREVIEW, payload: { url: '', loading: false, error: error.response } });
					resolve();
				});
		});
	};

// Validate editor body before sending to backend
const validateTemplateBody = body => {
	let isValid = true;
	try {
		if (body.json) {
			JSON.parse(body.json);
		}
		if (body.language) {
			JSON.parse(body.language);
		}
	} catch (error) {
		isValid = false;
		showSystemNotification(
			Tools.NotificationService.style.ERROR,
			'error',
			'default.error',
			'admin.documentTemplate.standardTemplatesModal.errors.invalidJson'
		);
	}
	return isValid;
};

// Clear editor on modal close
export const clearEditor = () => dispatch => {
	const editor = {
		templateId: 0,
		templateName: '',
		userRoles: [],
		html: '',
		css: '',
		json: '',
		language: '',
		uuid: 0,
		active: false,
		isSkeleton: false,
		isCustom: false
	};
	const preview = {
		url: '',
		loading: false,
		error: null
	};
	const initState = {
		editor: editor,
		preview: preview
	};
	dispatch({ type: RESET_EDITOR, payload: initState });
};

// Get template detail by its id
export const getTemplate = (templateUuid, createFromAnother = false) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch({ type: SET_PREVIEW, payload: { url: '', loading: true, error: null } });
			pdfTemplate
				.get(templateUuid, {
					params: {
						newGet: true
					}
				})
				.then(response => {
					const template = JSON.parse(response.data);
					const userRoles = Tools.AppService.getRoles();
					const mappedRoles = [];
					if (template.roles) {
						template.roles.forEach(templateRole => {
							userRoles.forEach(role => {
								if (templateRole.roleId === role.id) {
									mappedRoles.push(role);
								}
							});
						});
					}
					const editor = {
						templateId: template.id,
						templateName: template.templateData.template_name,
						userRoles: mappedRoles,
						html: template.templateData.template_details.body,
						css: template.templateData.template_details.css || '',
						json: template.templateData.template_details.dataJson
							? JSON.stringify(template.templateData.template_details.dataJson, null, '\t')
							: '',
						language: template.templateData.template_details.language
							? JSON.stringify(template.templateData.template_details.language, null, '\t')
							: '',
						uuid: template.uuid,
						active: createFromAnother ? false : template.active,
						isSkeleton: template.isSkeleton,
						isCustom: template.type === 'custom',
						isEditorFormat: template.isEditorFormat,
						configHash: template.templateData.template_details.templateConfigHash
					};
					dispatch({ type: SET_EDITOR_BODY, payload: editor });
					dispatch({ type: SET_INITIAL_HASH, payload: editor });
					resolve();
				})
				.catch(error => {
					dispatch({ type: SET_PREVIEW, payload: { url: '', loading: false, error: error } });
					reject(error);
				});
		});
	};
};

// Get template detail by its id
// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' feature flag is fully implemented
export const getTemplateOld =
	(templateToEdit, createFromAnother = false) =>
	dispatch => {
		dispatch({ type: SET_PREVIEW, payload: { url: '', loading: true, error: null } });
		pdfTemplate
			.get(templateToEdit.uuid, {
				params: { isSkeleton: templateToEdit.isSkeleton || false, isCustom: templateToEdit.type === 'custom' }
			})
			.then(response => {
				const userRoles = Tools.AppService.getRoles();
				const mappedRoles = [];
				if (templateToEdit.roles) {
					templateToEdit.roles.forEach(templateRole => {
						userRoles.forEach(role => {
							if (templateRole.roleId === role.id) {
								mappedRoles.push(role);
							}
						});
					});
				}
				const template = JSON.parse(response.data);
				const editor = {
					templateId: templateToEdit.id,
					templateName: template.data.template_name,
					userRoles: mappedRoles,
					html: template.data.template_details.body,
					css: template.data.template_details.css || '',
					json: template.data.template_details.dataJson
						? JSON.stringify(template.data.template_details.dataJson, null, '\t')
						: '',
					language: template.data.template_details.language
						? JSON.stringify(template.data.template_details.language, null, '\t')
						: '',
					uuid: template.data.id,
					active: createFromAnother ? false : template.data.published === 'true' ? true : false,
					isSkeleton: template.data.template_details.isSkeleton,
					isCustom: template.data.template_details.type === 'custom'
				};
				dispatch(
					getPdfTemplate(
						template.data.id,
						template.data.template_details.isSkeleton,
						template.data.template_details.type
					)
				);
				dispatch({ type: SET_EDITOR_BODY, payload: editor });
				dispatch({ type: SET_INITIAL_HASH, payload: editor });
			})
			.catch(error => {
				dispatch({ type: SET_PREVIEW, payload: { url: '', loading: false, error: error } });
			});
	};
//

// Set editor value in state depending on editor type
export const setEditorBody = (tabId, currentStateOrig, body) => dispatch => {
	const currentState = { ...currentStateOrig };

	if (tabId === 'html') {
		currentState.html = body;
	} else if (tabId === 'css') {
		currentState.css = body;
	} else if (tabId === 'json') {
		currentState.json = body;
	} else if (tabId === 'language') {
		currentState.language = body;
	} else if (tabId === 'active') {
		currentState.active = body;
	} else if (tabId === 'userRoles') {
		currentState.userRoles = body;
	} else if (tabId === 'templateName') {
		currentState.templateName = body;
	}
	dispatch({ type: SET_EDITOR_BODY, payload: currentState });
};

// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' feature flag is fully implemented
export const saveTemplateOld = (body, saveAndClose = false, reject = null) => {
	return dispatch => {
		const template = body;
		const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; // eslint-disable-line no-useless-escape
		if (!template.templateName) {
			showSystemNotification(
				Tools.NotificationService.style.ERROR,
				'error',
				'default.error',
				'admin.documentTemplate.standardTemplatesModal.errors.nameRequired'
			);
		} else if (specialCharRegex.test(template.templateName)) {
			showSystemNotification(
				Tools.NotificationService.style.ERROR,
				'error',
				'default.error',
				'admin.documentTemplate.standardTemplatesModal.errors.invalidName'
			);
		} else if (!template.html) {
			showSystemNotification(
				Tools.NotificationService.style.ERROR,
				'error',
				'default.error',
				'admin.documentTemplate.standardTemplatesModal.errors.invalidHtml'
			);
		} else {
			const isValid = validateTemplateBody(template);
			if (isValid) {
				dispatch({ type: SET_PREVIEW, payload: { url: '', loading: true, error: null } });
				let id = template.templateId === 0 ? null : template.templateId;
				if (template.isSkeleton) {
					template.templateId = 0;
					id = null;
					template.uuid = 0;
					template.isSkeleton = false;
				}

				if (!template.isCustom) {
					template.templateId = 0;
					id = null;
					template.uuid = 0;
				}
				const updateObj = {
					dbUpdateOnly: false,
					entity: template
				};
				pdfTemplate
					.save({ id: id, ...updateObj })
					.then(response => {
						response.data.type = 'custom';
						if (saveAndClose) {
							reject();
						} else {
							dispatch(getTemplateOld(response.data));
						}
					})
					.catch(error => {
						dispatch({
							type: SET_PREVIEW,
							payload: { url: '', loading: false, error: error.response.data.error }
						});
						showSystemNotification(
							Tools.NotificationService.style.ERROR,
							'error',
							'default.error',
							error.response.data.error.msg
						);
					});
			}
		}
	};
};
//

export const saveTemplate =
	(body, saveAndClose = false, rejectClose, onCallbackError) =>
	dispatch => {
		return new Promise((resolve, reject) => {
			const template = body;
			template.templateName = template.templateName.trim();
			const specialCharRegex = /[^A-Za-z0-9\såäöÅÄÖ]+/;
			if (!template.templateName) {
				showSystemNotification(
					Tools.NotificationService.style.ERROR,
					'error',
					'default.error',
					'admin.documentTemplate.standardTemplatesModal.errors.nameRequired'
				);
				resolve();
				return;
			} else if (specialCharRegex.test(template.templateName)) {
				showSystemNotification(
					Tools.NotificationService.style.ERROR,
					'error',
					'default.error',
					'admin.documentTemplate.standardTemplatesModal.errors.invalidName'
				);
				resolve();
				return;
			} else if (!template.html) {
				showSystemNotification(
					Tools.NotificationService.style.ERROR,
					'error',
					'default.error',
					'admin.documentTemplate.standardTemplatesModal.errors.invalidHtml'
				);
				resolve();
				return;
			} else {
				const isValid = validateTemplateBody(template);
				if (isValid) {
					dispatch({ type: SET_PREVIEW, payload: { url: '', loading: true, error: null } });
					let id = template.templateId === 0 ? null : template.templateId;
					if (template.isSkeleton) {
						template.templateId = 0;
						id = null;
						template.uuid = 0;
						template.isSkeleton = false;
					}

					if (!template.isCustom) {
						template.templateId = 0;
						id = null;
						template.uuid = 0;
					}
					const updateObj = {
						dbUpdateOnly: false,
						entity: template
					};

					try {
						pdfTemplate
							.save({ id: id, ...updateObj })
							.then(response => {
								if (saveAndClose) {
									rejectClose();
									showSystemNotification(
										Tools.NotificationService.style.SUCCESS,
										'success',
										'admin.documentTemplate.savedTemplate'
									);
								} else {
									dispatch(getTemplate(response.data.uuid))
										.then(() => {
											resolve(response.data);
										})
										.catch(error => {
											reject(error);
										});
								}
							})
							.catch(error => {
								if (error.response.data.error?.code === 413) {
									showSystemNotification(
										Tools.NotificationService.style.ERROR,
										'error',
										'default.error',
										'document.templateTooLargeToSave'
									);
								} else {
									showSystemNotification(
										Tools.NotificationService.style.ERROR,
										'error',
										'default.error',
										'admin.documentTemplate.standardTemplatesModal.errors.failedToSavePdfTemplate'
									);
								}
								onCallbackError();
							});
					} catch (error) {
						showSystemNotification(
							Tools.NotificationService.style.ERROR,
							'error',
							'default.error',
							'admin.documentTemplate.standardTemplatesModal.errors.failedToSavePdfTemplate'
						);
					}
				} else {
					showSystemNotification(
						Tools.NotificationService.style.ERROR,
						'error',
						'default.error',
						'admin.documentTemplate.standardTemplatesModal.errors.invalidTemplate'
					);
					resolve();
				}
			}
		});
	};

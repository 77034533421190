'use strict';

ReactTemplates.TOOLS.viewPicker = window.ReactCreateClass({
	getInitialState: function () {
		return {
			open: false,
			saveName: '',
			selected: null
		};
	},
	getDefaultProps: function () {
		return {
			id: ''
		};
	},
	componentWillUnmount: function () {
		if (this.state.open) {
			window.removeEventListener('click', this.close);
		}
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			savePlaceholder: t('filters.saveViewAs'),
			myViews: t('filters.myViews'),
			sharedViews: t('filters.sharedViews'),
			description: t('default.description'),
			save: t('default.save'),
			saveSearch: t('default.saveSearch'),
			changedView: t('report.changedView'),
			resetView: t('filters.resetView')
		};
	},
	stopProp: function (e) {
		if (e.target.tagName.toLowerCase() !== 'li') {
			e.stopPropagation();
		}
	},
	open: function (e) {
		e.stopPropagation();

		// bind click to window so the dropdown closes on click outside
		window.addEventListener('click', this.close);

		this.setState({ open: true });
	},
	close: function () {
		window.removeEventListener('click', this.close);

		this.setState({
			open: false,
			saveName: ''
		});
	},
	toggle: function (e) {
		if (this.state.open) {
			this.close();
		} else {
			this.open(e);
		}
	},
	setView: function (view) {
		this.props.setView(view);
		this.close();
		this.setState({ selected: view });
	},
	saveViewAs: function (e) {
		this.stopDefaultPreventAll(e);
		this.props.saveViewAs(this.state.saveName);
		this.close();
	},
	saveView: function (view, e) {
		this.stopDefaultPreventAll(e);
		this.props.saveView();
		this.close();
	},
	save: function () {
		var self = this;

		if (self.props.selected && self.props.selected.private) {
			self.saveView();
		} else {
			var $upModal = self.props.tools.$upModal;

			$upModal
				.open('infoPrompt', {
					title: 'filters.newView',
					placeholder: 'filters.viewName',
					maxLength: 100,
					yes: 'filters.saveView',
					icon: 'fa-plus'
				})
				.then(function (name) {
					self.setState(
						{
							saveName: name
						},
						function () {
							self.saveViewAs();
						}
					);
				});
		}
	},
	stopDefaultPreventAll: function (e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	},
	saveNameChange: function (e) {
		this.setState({ saveName: e.target.value });
	},
	deleteView: function (view, e) {
		this.stopDefaultPreventAll(e);
		this.props.deleteView(view);
		this.close();
	},
	setAsDefault: function (view, e) {
		this.stopDefaultPreventAll(e);
		this.props.setAsDefault(view);
		this.close();
	},
	editView: function (view, e) {
		this.stopDefaultPreventAll(e);
		this.props.editView(view);
		this.close();
	},
	mapViewRow: function (view) {
		var t = Tools.$translate;
		var defaultClass = 'fa fa-star-o';
		var selected = false;
		var changed = null;
		if (view.default) {
			defaultClass = 'fa fa-star';
		}
		if (this.props.selected && this.props.selected.id === view.id) {
			selected = true;

			var changedStyle = {};

			if (this.props.viewChanged) {
				changedStyle.fontStyle = 'italic';
				changed = <span>*</span>;
			}
		}
		return (
			<li className={selected ? 'active' : ''} key={(view.private ? 'private-' : '') + 'view-' + view.id}>
				<a onClick={this.setView.bind(this, view)}>
					<span style={changedStyle}>
						{t(view.title)} {changed}
					</span>
					{view.editable ? (
						<span className="pull-right">
							<b className={defaultClass} onClick={this.setAsDefault.bind(this, view)}></b>
							<b className="fa fa-cog normal-trigger" onClick={this.editView.bind(this, view)}></b>
							<b className="fa fa-trash-o warning-trigger" onClick={this.deleteView.bind(this, view)}></b>
						</span>
					) : null}
				</a>
			</li>
		);
	},
	render: function () {
		var self = this;
		var t = Tools.$translate;
		var dropdownStyle = {};
		var changedStyle = {};
		var text;

		if (this.props.viewChanged) {
			changedStyle.fontStyle = 'italic';

			if (self.props.selected.private) {
				text = (
					<span>
						{t(self.props.selected.title)} <span>*</span>
					</span>
				);
			} else {
				text = this.lang.changedView;
			}
		} else {
			text = t(self.props.selected.title);
		}

		if (this.state.open) {
			dropdownStyle.display = 'block';
		}

		var ownViews = _.filter(self.props.views, { private: true });
		var ownViewRows = _.map(ownViews, self.mapViewRow);

		var publicViews = _.filter(self.props.views, { private: false });
		var publicViewRows = _.map(publicViews, self.mapViewRow);

		var dropdown = (
			<ul className="dropdown-menu" onClick={self.stopProp} style={dropdownStyle}>
				{ownViewRows.length ? (
					<li key="header-private" className="dropdown-header">
						{self.lang.myViews}
					</li>
				) : null}

				{ownViewRows}

				{ownViewRows.length
					? [
							<li key="divider" className="divider"></li>,
							<li key="header-shared" className="dropdown-header">
								{self.lang.sharedViews}
							</li>
					  ]
					: null}

				{publicViewRows}

				<li className="divider"></li>

				<li className="input-row" onClick={self.stopProp}>
					<form autoComplete="off" onSubmit={self.saveViewAs}>
						<input
							type="text"
							className="form-control"
							autoComplete="view-picker-name"
							onChange={self.saveNameChange}
							value={self.state.saveName}
							placeholder={self.lang.savePlaceholder}
						/>
					</form>
				</li>
			</ul>
		);

		var saveButtonText =
			this.props.selected && this.props.selected.private ? this.lang.saveSearch : this.lang.savePlaceholder;

		return (
			<div id={this.props.id} className="view-picker">
				<div
					onClick={self.toggle}
					className={self.state.open ? 'open view-picker-text-wrap' : 'view-picker-text-wrap'}
				>
					<h2 style={changedStyle}>{text}</h2>
					<b className={this.state.open ? 'fa fa-angle-up' : 'fa fa-angle-down'}></b>
				</div>

				{this.props.viewChanged ? (
					<div className="view-picker-save-search">
						<button className="btn up-btn btn-bright-blue btn-lined no-shadow" onClick={self.save}>
							{saveButtonText}
						</button>

						<button style={{ marginTop: '-8px' }} onClick={this.props.resetView} className="btn btn-link">
							{this.lang.resetView}
						</button>
					</div>
				) : null}

				{dropdown}
			</div>
		);
	}
});

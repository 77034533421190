import CreateMailTemplate from 'Components/CreateMailTemplate';
import ChooseMailIntegration from 'Components/Modals/ChooseMailIntegration';
import history from 'App/pages/routes/history';

angular.module('domain.mail', ['upResources', 'security.authorization', 'ui.router']).config([
	'$routeGuardProvider',
	'$stateProvider',
	'$upModalProvider',
	function ($routeGuardProvider, $stateProvider, $upModalProvider) {
		authUser.$inject = ['$routeGuard'];
		mapMailActivated.$inject = ['$routeGuard', '$stateParams', '$upModal', '$state', '$q'];
		function authUser($routeGuard) {
			return $routeGuard.requireUser();
		}

		function mapMailActivated($routeGuard, $stateParams, $upModal, $state, $q) {
			var promise = $q.defer();

			$routeGuard
				.mapMailActivated($stateParams)
				.then(function () {
					promise.resolve();
				})
				.catch(function () {
					// eslint-disable-next-line
					$upModal
						.open('warningConfirm', {
							icon: 'fa-warning',
							title: 'default.warning',
							body: 'default.featureNotActivated',
							resolveTrue: 'navbar.settings'
						})
						.then(function () {
							$state.go('administration.mailSettings', {});
						});

					promise.reject();
				});

			return promise.promise;
		}

		$stateProvider
			.state('mailCampaign', {
				abstract: true,
				templateUrl: require('App/upsales/domain/mail/views/mailCampaign.html?file'),
				url: '/:customerId/mail-campaign/:id/',
				controller: 'MailCampaign as MailCampaignCtrl',
				section: 'market',
				resolve: {
					authUser: authUser,
					app: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise;
						}
					],
					authRights: [
						'$routeGuard',
						'$stateParams',
						function ($routeGuard, $stateParams) {
							return $routeGuard.requireAdminOrMailAdmin($stateParams);
						}
					],
					meta: [
						'MailCampaignMeta',
						'$stateParams',
						'$state',
						function (MailCampaignMeta, $stateParams) {
							var promise = MailCampaignMeta($stateParams);
							promise.catch(function () {
								history.replace('/mail-campaigns');
							});
							return promise;
						}
					],
					campaignMeta: [
						'MailCampaignInfo',
						'$stateParams',
						function (MailCampaignInfo, $stateParams) {
							return MailCampaignInfo.get($stateParams.id);
						}
					]
				}
			})
			.state('mailCampaign.dashboard', {
				template: '',
				url: '',
				controller: 'MailCampaignDashboard as MailCampaignSub',
				section: 'market'
			})
			.state('mailCampaign.visits', {
				template: '',
				url: 'visits',
				controller: 'MailCampaignVisits as MailCampaignSub',
				section: 'market'
			})
			.state('mailCampaign.submits', {
				template: '',
				url: 'submits',
				controller: 'MailCampaignSubmits as MailCampaignSub',
				section: 'market'
			})
			.state('mailCampaign.recipients', {
				template: '',
				url: 'recipients?filter&drill',
				controller: 'MailCampaignRecipients as MailCampaignSub',
				section: 'market'
			})
			.state('listMailTemplates', {
				templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
				url: '/:customerId/mail/templates/',
				controller: 'ListMailTemplates as listViewHelper',
				section: 'market',
				resolve: {
					authUser: authUser,
					mapMailActivated: mapMailActivated,
					authRights: [
						'$routeGuard',
						'$stateParams',
						function ($routeGuard, $stateParams) {
							return $routeGuard.mapMailActivated($stateParams);
						}
					],
					listViews: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise.then(function () {
								return AppService.getListViews('mailTemplate');
							});
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.placeholder(FeatureHelper.Feature.GROUP_MAIL);
						}
					]
				}
			});

		$upModalProvider
			.modal('previewInfo', {
				templateUrl: require('App/upsales/domain/mail/views/previewInfo.html?file'),
				controller: 'PreviewInfo',
				style: 'confirm info'
			})
			.modal('sendEmail', {
				templateUrl: require('App/upsales/domain/mail/views/sendEmail.html?file'),
				controller: 'SendEmail',
				openAnimation: true,
				closeAnimation: true,
				rememberHeight: true,
				resolve: {
					authUser: authUser,
					meta: function ($modalParams, SendEmailMeta) {
						return SendEmailMeta($modalParams);
					}
				},
				style: 'form default'
			})
			.modal('sentMail', {
				templateUrl: require('App/upsales/domain/mail/views/sentMail.html?file'),
				controller: 'SentMail',
				rememberHeight: true,
				resolve: {
					authUser: function ($routeGuard) {
						return $routeGuard.requireUser();
					},
					meta: function ($modalParams, SentMailMeta) {
						return SentMailMeta($modalParams);
					}
				},
				style: 'default'
			})
			.modal('scheduleMail', {
				templateUrl: require('App/upsales/domain/mail/views/scheduleMail.html?file'),
				controller: 'scheduleMail',
				resolve: {
					authUser: authUser
				},
				style: 'confirm'
			})
			.modal('startMailCampaign', {
				templateUrl: require('App/upsales/domain/mail/views/startMailCampaign.html?file'),
				controller: 'startMailCampaign',
				resolve: {
					authUser: authUser
				},
				style: 'prompt info no-border no-modal-overflow'
			})
			.modal('mailTemplateBrowser', {
				template: '<div id="mailTemplateBrowser"></div>',
				controller: function (
					$scope,
					$translate,
					MailTemplate,
					avatarService,
					RequestBuilder,
					$modalParams,
					$rootScope
				) {
					let rootNode;
					var renderTimeout;
					var tools = {
						$translate: $translate,
						MailTemplate: MailTemplate,
						avatarService: avatarService,
						RequestBuilder: RequestBuilder,
						$rootScope: $rootScope
					};

					var render = function () {
						if (renderTimeout) {
							clearTimeout(renderTimeout);
						}
						renderTimeout = setTimeout(function () {
							rootNode = document.getElementById('mailTemplateBrowser');
							if (!rootNode) {
								return;
							}

							ReactDOM.render(
								React.createElement(ReactTemplates.MODALS.MailTemplateBrowser, {
									// Data and stuff
									tools: tools,
									closeModal: $scope.reject,
									onSelect: $scope.resolve,
									$modalParams: $modalParams
								}),
								rootNode,
								function () {}
							);
						}, 20);
					};

					$scope.$on('$destroy', function () {
						ReactDOM.unmountComponentAtNode(rootNode);
						rootNode = undefined;
					});

					render();
				},
				style: 'form default bg-gray'
			})
			.modal('createGroupMail', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, window.CreateGroupMail, $element[0], $modalParams, { modal: true });
				},
				style: 'form-wide default new-full-screen'
			})
			.modal('createMailTemplate', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, CreateMailTemplate, $element[0], $modalParams, {
						modal: true
					});
				},
				style: 'form-wide default new-full-screen',
				noCloseOnStateChange: true
			})
			.modal('chooseMailIntegration', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					window.SetupComponent($scope, ChooseMailIntegration, $element[0], $modalParams, {
						modal: true,
						redux: true
					});
				},
				constantHeight: 475,
				style: 'form-sm no-border default'
			});
	}
]);

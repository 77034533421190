const mccsDir = 'upMailCampaignCategorySelect';

angular.module('upDirectives').directive(mccsDir + 'Ids', function (GroupMailCategory, selectHelper) {
	return selectHelper.getDirectiveObjectIds(mccsDir + 'Ids', {
		resource: GroupMailCategory,
		ajax: true,
		asIds: true,
		idAttr: GroupMailCategory.attr.id,
		titleAttr: GroupMailCategory.attr.title,
		sorting: { field: GroupMailCategory.attr.title, ascending: true },
		filters: function (rb, $attrs) {
			if ($attrs.showInactive === undefined) {
				rb.addFilter(GroupMailCategory.attr.active, rb.comparisonTypes.Equals, 1);
			}
		}
	});
});

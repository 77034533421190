'use strict';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';

ReactTemplates.form.editFormRoot = window.ReactCreateClass({
	submitted: false,
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	getStateFromProps: function (props) {
		var view;
		if (!props.rootData.accountProfile) {
			view = 'noAccountProfile';
		} else {
			view = 'edit';
		}
		return {
			view: view
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			name: t('default.name'),
			saving: t('default.saving'),
			clearColumn: t('mail.clearColumn'),
			deleteRow: t('editor.deleteRow'),
			selectRowLayout: t('mail.selectRowLayout'),
			form: t('form.form'),
			createForm: t('form.createForm'),
			editForm: t('form.editForm'),
			setupAccountProfile: t('admin.setupAccountProfile'),
			thanksPage: t('form.thanksPage'),
			style: t('landingpage.style'),
			useRecaptcha: t('form.Prefs.reCaptcha'),
			text: t('bannerEditor.text'),
			button: t('bannerEditor.button'),
			background: t('bannerEditor.background'),
			backgroundColor: t('form.backgroundColor'),
			textColor: t('form.textColor'),
			linkColor: t('form.linkColor'),
			addContentToThanksPage: t('landingpage.addContentToThanksPage'),
			abort: t('default.abort'),
			preview: t('form.preview')
		};

		this.setupColorVariables();
	},
	setupColorVariables: function () {
		var t = Tools.$translate;
		if (this.props.rootData.accountProfile) {
			// Setup profileColors
			var colorVariables = [
				{ title: t('admin.colors'), colors: {} },
				{ title: t('admin.extraColors'), colors: {} }
			];

			angular.forEach(this.props.rootData.accountProfile.colors, function (value) {
				colorVariables[0].colors[value] = value;
			});
			angular.forEach(this.props.rootData.accountProfile.extraColors, function (color) {
				colorVariables[1].colors[color.value] = color.value;
			});

			this.setState({
				colorVariables: colorVariables
			});
		}
	},
	updateMaps: function () {
		if (this._preview) {
			var preview = jQuery(this._preview);

			var maps = preview.find('.type-map');
			maps.each(function () {
				var elem = jQuery(this);
				var mapWrapper = elem.find('.map');
				if (mapWrapper.length) {
					var data = elem.data();
					var pos = { lat: data.lat, lng: data.lng };
					var mapOpts = {
						zoom: 16,
						center: pos,
						disableDefaultUI: true,
						mapTypeControl: data.showtools,
						zoomControl: data.showtools,
						scrollwheel: data.allowscrollzoom
					};

					mapWrapper.html('');
					loader
						.load()
						.then(() => {
							var map = new google.maps.Map(mapWrapper[0], mapOpts);
							elem.data('map', map);
							new google.maps.Marker({
								position: pos,
								map: map
							});
						})
						.catch(e => {
							logError(e, 'Failed to load Google');
						});
				}
			});
		}
	},
	setupProfile: function () {
		this.props.rootData.onSetupProfile();
	},
	UNSAFE_componentWillReceiveProps: function (props) {
		this.setState(this.getStateFromProps(props));
	},
	componentDidMount: function () {
		this.updateMaps();
	},
	componentDidUpdate: function () {
		this.updateMaps();

		if (this._preview) {
			var preview = jQuery(this._preview);
			if (this.props.rootData.thankUPreview) {
				preview.find('#up-form').hide();
				preview.find('#up-form-thanks').show();
			} else {
				preview.find('#up-form').show();
				preview.find('#up-form-thanks').hide();
			}
		}

		// Scroll first error-input into view and set focus to it
		if (this.submitted) {
			var inputs = this._root.querySelectorAll('.has-error');
			if (inputs.length) {
				inputs[0].scrollIntoView();
				var input = inputs[0].querySelector('input');
				if (input) {
					input.focus();
				}
			}
		}

		this.submitted = false;
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	setToolTab: function (type) {
		this.props.rootData.onSetToolTab(type);
	},
	onSave: function () {
		this.submitted = true;
		this.props.rootData.onSave();
	},
	rootPropChange: function (key, e) {
		var form = this.props.rootData.form;

		form[key] = e.target.value;

		this.props.rootData.onFormChange(form);
	},
	getFormTools: function () {
		var rootData = this.props.rootData;
		var props = {
			accountProfile: rootData.accountProfile,
			element: null,
			onChange: rootData.onElementChange,
			form: rootData.form,
			onFormChange: rootData.onFormChange,
			onEditAction: rootData.onEditAction,
			colorVariables: this.state.colorVariables,
			onSelectField: rootData.onSelectField,
			accountCustomFields: rootData.accountCustomFields,
			contactCustomFields: rootData.contactCustomFields,
			hasVerifyDomainAccess: rootData.hasVerifyDomainAccess,
			optIns: rootData.optIns,
			getAvailableOptIns: rootData.getAvailableOptIns,
			verifiedDomains: rootData.verifiedDomains
		};

		var tools = React.createElement(ReactTemplates.form.formContentType, props);
		var nameClass = 'floating-label-input';
		if (rootData.form.name && rootData.form.name.length) {
			nameClass += ' has-value';
		} else if (rootData.submitted) {
			nameClass += ' has-error';
		}

		return (
			<div>
				<div className="tool-main-section no-margin no-border">
					<div className="tool-section">
						<div className={nameClass}>
							<label>{this.lang.name}</label>
							{/* eslint-disable-next-line react/jsx-no-undef */}
							<Input.input
								data-test-id="form-name"
								type="text"
								value={rootData.form.name}
								onChange={this.rootPropChange.bind(this, 'name')}
								maxLength={255}
							/>
						</div>
					</div>
				</div>
				{tools}
			</div>
		);
	},
	colorChange: function (key, value) {
		var form = this.props.rootData.form;

		form[key] = value;

		this.props.rootData.onFormChange(form);
	},
	useBgChanged: function () {
		if (this.props.rootData.form.backgroundColor === 'transparent') {
			this.colorChange('backgroundColor', '#ffffff');
		} else {
			this.colorChange('backgroundColor', 'transparent');
		}
	},
	toggleThankUPreview: function (type) {
		var rootData = this.props.rootData;
		if ((rootData.thankUPreview && type === 'thanksPage') || (!rootData.thankUPreview && type === 'form')) {
			return;
		}

		rootData.onTogglePreviewType();
	},
	addPreset: function (color) {
		var self = this;
		return self.props.rootData.addPreset(color).then(function () {
			self.setupColorVariables();
		});
	},
	getToolsContentByType: function (type) {
		var tools = null;
		var rootData = this.props.rootData;
		var props = {
			accountProfile: rootData.accountProfile,
			element: rootData.form.thankYouElement,
			onChange: rootData.onThankUChange,
			addPreset: this.props.rootData.addPreset ? this.addPreset : null
		};

		switch (type) {
			case 'text':
				tools = React.createElement(ReactTemplates.form.textContentType, props);
				break;
			case 'image':
				tools = React.createElement(ReactTemplates.form.imageContentType, props);
				break;
			case 'video':
				tools = React.createElement(ReactTemplates.form.videoContentType, props);
				break;
			case 'countdown':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.countdownContentType, props);
				break;
			case 'file':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.fileContentType, props);
				break;
			case 'map':
				props.skipSearch = ['{{Event.Location}}'];
				tools = React.createElement(ReactTemplates.form.mapContentType, props);
				break;
			case 'landingPage':
				tools = React.createElement(ReactTemplates.form.landingPage, props);
				break;
			case 'card':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.cardContentType, props);
				break;
			case 'button':
				props.colorVariables = this.state.colorVariables;
				tools = React.createElement(ReactTemplates.form.buttonContentType, props);
				break;
		}
		return tools;
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var form = rootData.form;
		var classes = 'card full-size tools-visible';
		var cardStyle = {};
		var mainView = null;
		if (rootData.isModal) {
			cardStyle = {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0
			};
		}

		if (self.state.view === 'noAccountProfile') {
			mainView = (
				<div className="placeholder">
					<div className="inner-wrap">
						<h2>{self.lang.setupAccountProfile}</h2>
						<p />

						<div className="btn-wrap btn-wrap-3">
							<div className="btn-inner-wrap" />
							<div className="btn-inner-wrap">
								<button type="button" className="up-btn btn-bright-blue" onClick={self.setupProfile}>
									{self.lang.setupAccountProfile}
								</button>
							</div>
							<div className="btn-inner-wrap" />
						</div>
					</div>
				</div>
			);
		} else {
			var previewHtml = { __html: rootData.html };
			var toolsContent = null;

			var toolContent = null;

			if (rootData.toolTab === 'form') {
				if (rootData.thankUPreview) {
					if (rootData.form.thankYouElement) {
						toolContent = (
							<div>
								{this.getToolsContentByType(rootData.form.thankYouElement.type)}
								<div className="tool-main-section">
									<button
										className="btn up-btn btn-grey btn-hover-red btn-link btn-block"
										onClick={rootData.onClearThankPage}
									>
										{self.lang.clearColumn}
									</button>
								</div>
							</div>
						);
					} else {
						toolContent = (
							<div>
								<div className="tool-main-section no-margin">
									<div className="tool-header">
										<b className="fa fa-plus-circle" />
										<h3 key="col-title">{self.lang.addContentToThanksPage}</h3>
									</div>
								</div>
								{React.createElement(ReactTemplates.form.selectColumnContent, {
									hasForm: true,
									isThankUElem: true,
									onSelect: rootData.onSetThankYouElem
								})}
							</div>
						);
					}
				} else {
					toolContent = self.getFormTools();
				}
			} else {
				var useBg = true;

				if (form.backgroundColor === 'transparent') {
					useBg = false;
				}

				toolContent = (
					<div>
						<div className="tool-main-section">
							<div className="tool-section" key="text-color">
								<label>{this.lang.textColor}</label>
								<div className="pull-right">
									{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
										value: form.textColor,
										isText: false,
										presets: self.state.colorVariables,
										addPreset: self.props.rootData.addPreset ? self.addPreset : null,
										onChange: self.colorChange.bind(self, 'textColor')
									})}
								</div>
							</div>
							<div className="tool-section" key="link-color">
								<label>{this.lang.linkColor}</label>
								<div className="pull-right">
									{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
										value: form.linkColor,
										isText: false,
										presets: self.state.colorVariables,
										addPreset: self.props.rootData.addPreset ? self.addPreset : null,
										onChange: self.colorChange.bind(self, 'linkColor')
									})}
								</div>
							</div>
						</div>
						<div className="tool-header">
							<h3>{this.lang.button}</h3>
						</div>
						<div className="tool-main-section">
							<div className="tool-section" key="btn-text">
								<label>{this.lang.textColor}</label>
								<div className="pull-right">
									{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
										value: form.buttonTextColor,
										isText: false,
										presets: self.state.colorVariables,
										addPreset: self.props.rootData.addPreset ? self.addPreset : null,
										onChange: self.colorChange.bind(self, 'buttonTextColor')
									})}
								</div>
							</div>
							<div className="tool-section" key="btn-bg">
								<label>{this.lang.backgroundColor}</label>
								<div className="pull-right">
									{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
										value: form.buttonBgColor,
										isText: false,
										presets: self.state.colorVariables,
										addPreset: self.props.rootData.addPreset ? self.addPreset : null,
										onChange: self.colorChange.bind(self, 'buttonBgColor')
									})}
								</div>
							</div>
						</div>
						<div className="tool-header">
							<h3>{this.lang.background}</h3>
							<div className="pull-right">
								<ReactTemplates.upFilters.components.toggle
									checked={useBg}
									onChange={self.useBgChanged}
									className="toggle-bright-blue"
								/>
							</div>
						</div>
						{useBg ? (
							<div className="tool-main-section">
								<div className="tool-section" key="bg">
									<label>{this.lang.backgroundColor}</label>
									<div className="pull-right">
										{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
											value: form.backgroundColor,
											isText: false,
											presets: self.state.colorVariables,
											addPreset: self.props.rootData.addPreset ? self.addPreset : null,
											onChange: self.colorChange.bind(self, 'backgroundColor')
										})}
									</div>
								</div>
							</div>
						) : null}
					</div>
				);
			}

			toolsContent = (
				<div className="editor-tools-panel">
					{!rootData.thankUPreview ? (
						<ul className="tool-tabs">
							<li
								className={rootData.toolTab === 'form' ? 'selected-tab' : ''}
								onClick={self.setToolTab.bind(self, 'form')}
							>
								{self.lang.form}
							</li>
							<li
								className={rootData.toolTab === 'style' ? 'selected-tab' : ''}
								onClick={self.setToolTab.bind(self, 'style')}
							>
								{self.lang.style}
							</li>
						</ul>
					) : null}

					{toolContent}

					<window.CssEditor
						form={rootData.form}
						apply={rootData.onFormChange}
						isVisible={rootData.toolTab === 'style'}
					/>
				</div>
			);

			var selectedClass = 'btn btn-sm up-btn btn-bright-blue';
			var unselectedClass = 'btn btn-sm up-btn btn-light-grey';
			var missingThanksPage = !form.thankYouElement && !form.landingPage;
			var warningSelectedClass = 'btn btn-sm up-btn btn-orange';

			mainView = (
				<div className="card-content">
					<div
						className="preview static"
						onClick={self.previewClick}
						ref={self.setRef.bind(self, '_preview')}
					>
						<div id="preview-toggles" className="btn-group">
							<button
								type="button"
								className={rootData.thankUPreview ? unselectedClass : selectedClass}
								onClick={self.toggleThankUPreview.bind(self, 'form')}
							>
								{self.lang.form}
							</button>
							<button
								type="button"
								className={
									rootData.thankUPreview
										? rootData.thankUPreview && rootData.submitted && missingThanksPage
											? warningSelectedClass
											: selectedClass
										: unselectedClass
								}
								onClick={self.toggleThankUPreview.bind(self, 'thanksPage')}
							>
								{self.lang.thanksPage}
								{rootData.submitted && missingThanksPage && (
									<b
										id="missing-thanks-page"
										className={
											'fa fa-warning ' + (rootData.thankUPreview ? 'text-white' : 'text-red')
										}
									/>
								)}
							</button>
						</div>
						<div key="fdoihfdsoihdfwouh" dangerouslySetInnerHTML={previewHtml} />
					</div>

					<div id="editor-toolbox" className="editor-tools">
						{toolsContent}
					</div>
				</div>
			);
		}

		var upLoader = null;
		if (rootData.saving) {
			upLoader = React.createElement(ReactTemplates.loader, { dark: true });
		}

		return (
			<div id="form-editor" className={classes} ref={self.setRef.bind(self, '_root')} style={cardStyle}>
				<div className={'up-header up-header-sm' + (rootData.isModal ? '' : ' border-top fixed')}>
					<div id="title">
						<h2>
							{rootData.form.id ? self.lang.editForm : self.lang.createForm}{' '}
							<ReactTemplates.elevio sidebar="true" articleId="252" />
						</h2>
					</div>
					<div className="actions">
						{rootData.isModal ? (
							<button
								type="button"
								className="up-btn btn-link btn-grey"
								disabled={rootData.saving}
								onClick={rootData.abort}
							>
								{self.lang.abort}
							</button>
						) : null}
						<button
							data-test-id="form-save"
							type="button"
							className="up-btn btn-bright-blue no-shadow"
							disabled={rootData.saving}
							onClick={self.onSave}
						>
							{rootData.saving ? (
								<span>
									<b className="fa fa-refresh fa-spin" /> {self.lang.saving}
								</span>
							) : (
								rootData.saveBtnText
							)}
						</button>
					</div>
				</div>

				<div id="card-main-view">
					{mainView}
					{rootData.saving ? <div id="save-curtain">{upLoader}</div> : null}
				</div>
			</div>
		);
	}
});

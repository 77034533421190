'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.account').controller('AccountUdo', [
	'$scope',
	'AppService',
	'$stateParams',
	'UserDefinedObject',
	'RequestBuilder',
	'$upModal',
	function ($scope, AppService, $stateParams, UserDefinedObject, RequestBuilder, $upModal) {
		var AccountCtrl = $scope.AccountCtrl;
		var AccountUdoCtrl = this;

		AccountUdoCtrl.items = [];

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.added', function (e, added) {
			if (added.client && added.client.id === AccountCtrl.account.id) {
				AccountUdoCtrl.items.push(added);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.deleted', function (e, deleted) {
			var index = _.findIndex(AccountUdoCtrl.items, { id: deleted.id });

			if (index !== -1) {
				AccountUdoCtrl.items.splice(index, 1);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.updated', function (e, updated) {
			var index = AccountUdoCtrl.items.findIndex(item => item.id === updated.id);

			if (index !== -1) {
				if (updated.client && updated.client.id === AccountCtrl.account.id) {
					AccountUdoCtrl.items[index] = updated;
				} else {
					AccountUdoCtrl.items.splice(index, 1);
					AccountCtrl.udoTotal[$stateParams.udoId]--;
				}
			}
		});

		var preventEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();
		};

		AccountUdoCtrl.removeItem = function (item, e) {
			preventEvent(e);

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					body: 'confirm.userDefinedObject',
					onClose: confirmed => {
						if (confirmed) {
							UserDefinedObject.setId(AccountUdoCtrl.udo.id).delete(item);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'default.remove',
					body: 'confirm.userDefinedObject',
					resolveTrue: 'default.remove',
					icon: 'fa-warning',
					focus: 1
				})
				.then(function () {
					// Delete item here
					UserDefinedObject.setId(AccountUdoCtrl.udo.id).delete(item);
				});
		};

		AccountUdoCtrl.editItem = function (item, e) {
			preventEvent(e);

			$upModal.open('editUserDefinedObject', {
				typeId: AccountUdoCtrl.udo.id,
				id: item.id
			});
		};

		AccountUdoCtrl.selectLink = function (url, e) {
			e.stopPropagation();
			window.open(url, '_blank');
		};

		AccountUdoCtrl.createItem = function () {
			$upModal.open('editUserDefinedObject', {
				typeId: AccountUdoCtrl.udo.id,
				object: {
					client: { id: AccountCtrl.account.id }
				}
			});
		};

		var getItems = function () {
			if (!AccountUdoCtrl.udo) {
				return false;
			}
			AccountUdoCtrl.loading = true;
			var filters = new RequestBuilder();
			filters.addFilter(UserDefinedObject.attr.account.attr.id, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...(AccountCtrl.subAccountIds ?? [])
			]);
			filters.addSort(UserDefinedObject.attr.regDate, true);

			return UserDefinedObject.setId(AccountUdoCtrl.udo.id)
				.find(filters.build())
				.then(function (res) {
					AccountUdoCtrl.items = res.data;
					AccountCtrl.udoTotal[AccountUdoCtrl.udo.id] = res.metadata.total;
					AccountUdoCtrl.loading = false;
				});
		};

		var init = function () {
			var cacheKey = 'userDefined' + $stateParams.udoId;
			var fields = AppService.getCustomFields(cacheKey).filter(a => a.$hasAccess);
			var categoryTypes = AppService.getCategoryTypes(cacheKey);

			AccountUdoCtrl.udo = _.find(AccountCtrl.udo, { id: parseInt($stateParams.udoId) });

			// Set up columns
			AccountUdoCtrl.columns = [];

			angular.forEach(fields, function (field) {
				if (field.visible && field.datatype !== 'Text') {
					AccountUdoCtrl.columns.push(field);
				}
			});

			AccountUdoCtrl.columns = _.sortBy(AccountUdoCtrl.columns, 'sortId');

			if (categoryTypes.length) {
				angular.forEach(categoryTypes, function (type) {
					AccountUdoCtrl.columns.push(type);
				});
			}
			AccountUdoCtrl.columns = AccountUdoCtrl.columns.slice(0, 6);

			getItems();
		};
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

'use strict';

import ARRChanges from 'Resources/ARRChanges';
import InlineChangeHistory from 'App/components/InlineChangeHistory';
import AgreementList from 'App/components/AgreementList';
import SubscriptionCards from 'App/components/SubscriptionCards';
import { renderSubscriptionPromo } from 'App/components/EditSubscription/SubscriptionPromo';
import ClientSales from 'App/components/ClientSales';
import colorMappings from '@upsales/components/Utils/colorMappings';
import openModal from 'App/services/Modal';
import { hasCMWithRR } from 'App/helpers/salesModelHelpers';

angular.module('domain.account').controller('AccountOrders', [
	'$scope',
	'$rootScope',
	'$location',
	'$filter',
	'$stateParams',
	'Order',
	'$q',
	'$upModal',
	'SaveInline',
	'$translate',
	'FixOrder',
	'AppService',
	'RequestBuilder',
	'FilterHelper',
	'Agreement',
	'Report',
	'meta',
	'avatarService',
	'$timeout',
	'$safeApply',
	'$state',
	function (
		$scope,
		$rootScope,
		$location,
		$filter,
		$stateParams,
		Order,
		$q,
		$upModal,
		SaveInline,
		$translate,
		FixOrder,
		AppService,
		RequestBuilder,
		FilterHelper,
		Agreement,
		Report,
		meta,
		avatarService,
		$timeout,
		$safeApply,
		$state
	) {
		var AccountOrderCtrl = this;
		var AccountCtrl = $scope.AccountCtrl;
		var customerId;
		var accountOrders = [];
		var arrChanges = [];
		var currentOrderFilter;
		var currentYear = moment().format('YYYY');
		var excludedStages = [];
		$scope.InlineChangeHistory = InlineChangeHistory;
		$scope.AgreementList = AgreementList;
		$scope.SubscriptionCards = SubscriptionCards;
		$scope.ClientSales = ClientSales;

		AccountOrderCtrl.loading = false;

		AccountOrderCtrl.limit = 50;
		AccountOrderCtrl.offset = 0;
		AccountOrderCtrl.page = 1;
		AccountOrderCtrl.futureSubscriptionsTitle = $translate.instant('agreement.futureSubscriptions');

		const metadata = AppService.getMetadata();

		var noData = function () {
			return (
				!accountOrders.length &&
				(!AccountOrderCtrl.accountAgreements || !AccountOrderCtrl.accountAgreements.length) &&
				(!AccountOrderCtrl.accountAgreementsInactive || !AccountOrderCtrl.accountAgreementsInactive.length)
			);
		};

		$scope.$on('order.deleted', function (e, deleted) {
			var index = _.findIndex(accountOrders, { id: deleted.id });

			if (index !== -1) {
				accountOrders.splice(index, 1);
				AccountOrderCtrl.nrOfOrders--;

				// If no more orders or agreements - go to dashboard
				if (noData()) {
					$state.go('account.dashboard', { id: AccountCtrl.account.id });
				} else {
					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				if (FilterHelper.match(currentOrderFilter.q, added, 'order')) {
					const order = _.find(accountOrders, { id: added.id });

					if (order) {
						angular.extend(order, added);
					} else {
						accountOrders.push(added);
						AccountOrderCtrl.nrOfOrders++;
					}

					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			}
		});

		$scope.$on('order.updated', function (e, updated) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(updated.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(updated.clientConnection)
			) {
				var order = _.find(accountOrders, { id: updated.id });

				if (order) {
					if (FilterHelper.match(currentOrderFilter.q, updated, 'order')) {
						angular.extend(order, updated);
					} else {
						_.remove(accountOrders, order);
						AccountOrderCtrl.nrOfOrders--;
					}

					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			}
		});

		$scope.createOrder = function () {
			var options = {
				customerId: customerId
			};
			options.clientId = AccountCtrl.account.id;

			$upModal.open('editOrder', options);
		};

		$scope.createAgreement = function () {
			var options = {
				customerId: customerId,
				accountId: AccountCtrl.account.id
			};

			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				return $upModal.open('editAgreement', options);
			}

			openModal('CreateSubscription', {
				client: AccountCtrl.account,
				createdFrom: 'accountSalesAddButton',
				dontWait: Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS')
			});
		};

		$scope.$on('account.merged', function (e, res) {
			if (AccountCtrl.isThisAccountOrSubaccounts(res.merged)) {
				// Reload stuff
				AccountOrderCtrl.getOrders();
				AccountOrderCtrl.getAgreements();
			}
		});

		$scope.$on('agreement.deleted', function (e, deleted) {
			var found = false;
			var activeIndex = _.findIndex(AccountOrderCtrl.accountAgreements, { id: deleted.id });
			if (activeIndex !== -1) {
				AccountOrderCtrl.accountAgreements.splice(activeIndex, 1);
				found = true;
			}

			var inactiveIndex = _.findIndex(AccountOrderCtrl.accountAgreementsInactive, { id: deleted.id });
			if (inactiveIndex !== -1) {
				AccountOrderCtrl.accountAgreementsInactive.splice(inactiveIndex, 1);
				found = true;
			}

			const futureIndex = _.findIndex(AccountOrderCtrl.futureAccountAgreements, { id: deleted.id });
			if (futureIndex !== -1) {
				AccountOrderCtrl.futureAccountAgreements.splice(futureIndex, 1);
				found = true;
			}

			if (found) {
				AccountOrderCtrl.agreementTotal--;
				// If no more orders or agreements - go to dashboard
				if (noData()) {
					$state.go('account.dashboard', { id: AccountCtrl.account.id });
				}
			}
		});

		$scope.$on('agreement.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				var [addedFixed] = checkInactiveDates([added]);
				if (
					addedFixed.metadata.agreementEnddate === null ||
					moment().isBefore(addedFixed.metadata.agreementEnddate)
				) {
					addAgreement(addedFixed);
				} else {
					AccountOrderCtrl.accountAgreementsInactive.push(addedFixed);
				}

				AccountOrderCtrl.agreementTotal++;
			}
		});

		$scope.$on('ARRChanges.updated', (e, { clientIds }) => {
			if (clientIds?.length && clientIds.findIndex(id => AccountCtrl.isThisAccountOrSubaccounts({ id })) >= 0) {
				AccountOrderCtrl.getArrChanges();
			}
		});

		$scope.$on('agreement.updated', function (e, updated) {
			var activeIndex = _.findIndex(AccountOrderCtrl.accountAgreements, { id: updated.id });
			if (activeIndex !== -1) {
				AccountOrderCtrl.accountAgreements.splice(activeIndex, 1);
			}

			var inactiveIndex = _.findIndex(AccountOrderCtrl.accountAgreementsInactive, { id: updated.id });
			if (inactiveIndex !== -1) {
				AccountOrderCtrl.accountAgreementsInactive.splice(inactiveIndex, 1);
			}

			const futureIndex = _.findIndex(AccountOrderCtrl.futureAccountAgreements, { id: updated.id });
			if (futureIndex !== -1) {
				AccountOrderCtrl.futureAccountAgreements.splice(futureIndex, 1);
			}

			if (
				AccountCtrl.isThisAccountOrSubaccounts(updated.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(updated.clientConnection)
			) {
				if (
					updated.metadata.agreementEnddate === null ||
					moment().isBefore(updated.metadata.agreementEnddate)
				) {
					addAgreement(updated);
				} else {
					AccountOrderCtrl.accountAgreementsInactive.push(updated);
				}
			}
		});

		$rootScope.$on('arrChange.updated', function () {
			setTimeout(function () {
				AccountOrderCtrl.getArrChanges();
			}, 100);
		});

		var groupable = {
			year: {
				id: 'year',
				text: $translate.instant('date.year'),
				groupOn: function (obj) {
					return moment(obj.date).format('YYYY');
				}
			},
			month: {
				id: 'month',
				text: $translate.instant('date.month'),
				groupOn: function (obj) {
					return moment(obj.date).format('YYYY-MM');
				}
			},
			user: { id: 'user', text: $translate.instant('default.user'), groupOn: 'user.id', textOn: 'user.name' },
			stage: {
				id: 'stage',
				text: $translate.instant('default.stage'),
				groupOn: 'stage.id',
				textOn: 'stage.name'
			},
			client: {
				id: 'client',
				text: $translate.instant('default.client'),
				groupOn: 'client.id',
				textOn: 'client.name'
			}
		};

		AccountOrderCtrl.setType = function (key) {
			AccountOrderCtrl.type = key;
			if (['year', 'month'].indexOf(AccountOrderCtrl.grouping.id) < 0) {
				AccountOrderCtrl.setGroupable('year');
			}

			updateFlags();

			$location.search('type', AccountOrderCtrl.type);

			createSeries();
		};

		AccountOrderCtrl.setGroupable = function (key) {
			AccountOrderCtrl.page = 1;
			AccountOrderCtrl.offset = 0;
			AccountOrderCtrl.grouping = groupable[key];

			if (AccountOrderCtrl.nrOfOrders > AccountOrderCtrl.limit) {
				AccountOrderCtrl.getOrders();
			} else {
				groupChange();
			}
		};

		function groupChange() {
			AccountOrderCtrl.accountOrders = $filter('groupBy')(accountOrders, AccountOrderCtrl.grouping);
			AccountOrderCtrl.arrChanges = $filter('groupBy')(arrChanges, AccountOrderCtrl.grouping);

			if (AccountOrderCtrl.grouping && AccountOrderCtrl.grouping.id === 'month') {
				AccountOrderCtrl.accountOrders = _.sortBy(AccountOrderCtrl.accountOrders, function (order) {
					return +moment(order.title);
				});
				AccountOrderCtrl.arrChanges = _.sortBy(AccountOrderCtrl.arrChanges, function (order) {
					return +moment(order.title);
				});
			} else {
				AccountOrderCtrl.accountOrders = _.sortBy(AccountOrderCtrl.accountOrders, 'title');
			}

			// sort function..
			if (
				AccountOrderCtrl.grouping &&
				(AccountOrderCtrl.grouping.id === 'year' || AccountOrderCtrl.grouping.id === 'month')
			) {
				AccountOrderCtrl.accountOrders.reverse();
				AccountOrderCtrl.arrChanges.reverse();
			}

			createSeries();

			$location.search('groupBy', AccountOrderCtrl.grouping ? AccountOrderCtrl.grouping.id : 'year');
		}

		function getAnnualValueSeries() {
			var categories, data, year;
			var rb = new RequestBuilder();

			rb.addFilter(ARRChanges.attr.account.attr.id, rb.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...meta.subAccountIds
			]);

			var grouping = AccountOrderCtrl.grouping.id;

			const field =
				AccountCtrl.salesModelOption === 'mrr' ? 'monthlyValueInRoleCurrency' : 'annualValueInRoleCurrency';

			let agg;

			switch (grouping) {
				case 'year':
					year = 1900 + new Date().getYear();
					categories = _.range(year - 11, year + 1).map(year => year + '');
					categories.reverse();
					for (const year of categories) {
						agg = rb.aggregationBuilder();
						const endOfYear = moment().year(year).endOf('year');

						agg.addFilter(ARRChanges.attr.date, rb.comparisonTypes.LessThanEquals, endOfYear);
						agg.addAggregation(rb.aggregationTypes.Sum, ARRChanges.attr[field].field);
						agg.aggregationName(year + '');
						agg.done();
					}
					break;

				case 'month':
					var currentMonth = new Date().getMonth();
					year = 1900 + new Date().getYear();
					categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (m) {
						var mon = currentMonth - m;
						if (mon < 0) {
							return (
								year -
								1 +
								'-' +
								moment()
									.month(mon + 12)
									.format('MM') +
								'-01'
							);
						}
						return year + '-' + moment().month(mon).format('MM') + '-01';
					});

					for (const date of categories) {
						agg = rb.aggregationBuilder();
						const endOfMonth = moment(date).endOf('month');

						agg.addFilter(ARRChanges.attr.date, rb.comparisonTypes.LessThanEquals, endOfMonth);
						agg.addAggregation(rb.aggregationTypes.Sum, ARRChanges.attr[field].field);
						agg.aggregationName(date);
						agg.done();
					}

					break;
			}

			Report.customer(customerId)
				.setType(Report.type.ARRCHANGES)
				.find(rb.build())
				.then(function (res) {
					data = _.range(0, 12, 0);
					_.each(Object.keys(res.data), function (key) {
						var index = categories.indexOf(key);
						if (index !== -1) {
							data[index] = res.data[key].value;
						}
					});

					buildChart({ categories: categories, data: data });
				})
				.catch(error => console.error(error));
		}

		function createSeries(onlyUpdateYearSum) {
			if (AccountOrderCtrl.showRecurring) {
				return getAnnualValueSeries();
			}

			var categories, data, cmData, year;
			var rb = getFilters();
			rb.addFilter(Order.attr.probability, rb.comparisonTypes.Equals, 100);
			rb.addFilter(Order.attr.stage, rb.comparisonTypes.NotEquals, excludedStages);
			var agg = rb.aggregationBuilder();

			var grouping = onlyUpdateYearSum ? 'month' : AccountOrderCtrl.grouping.id;

			switch (grouping) {
				case 'year':
					year = 1900 + new Date().getYear();
					categories = _.range(year - 11, year + 1);
					categories.reverse();
					agg.addAggregation(rb.aggregationTypes.Terms, 'date');
					agg.aggregationInterval(rb.aggregationIntervals.YEAR);
					break;

				case 'month':
					var month = new Date().getMonth();
					year = 1900 + new Date().getYear();
					categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (m) {
						var mon = month - m;
						if (mon < 0) {
							return (
								year -
								1 +
								'-' +
								moment()
									.month(mon + 12)
									.format('MM') +
								'-01'
							);
						}
						return year + '-' + moment().month(mon).format('MM') + '-01';
					});

					agg.addAggregation(rb.aggregationTypes.Terms, 'date');
					agg.aggregationInterval(rb.aggregationIntervals.MONTH);
					break;
				case 'stage':
					agg.addAggregation(rb.aggregationTypes.Terms, 'stage.id');
					break;
				case 'user':
					agg.addAggregation(rb.aggregationTypes.Terms, 'user.id');
					break;
			}
			agg.aggregationName('grouping');
			agg.aggregationOrder('valueSum', false);

			var subAgg = agg.aggregationBuilder();
			subAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInRoleCurrency.field);
			subAgg.aggregationName('valueSum');
			subAgg.done();
			var subAgg2 = agg.aggregationBuilder();
			subAgg2.addAggregation(rb.aggregationTypes.Sum, Order.attr.contributionMarginInRoleCurrency.field);
			subAgg2.aggregationName('cmSum');
			subAgg2.done();
			agg.done();
			Report.customer(customerId)
				.setType(Report.type.ORDER)
				.find(rb.build())
				.then(function (res) {
					switch (grouping) {
						case 'year':
							data = _.range(0, 12, 0);
							cmData = _.range(0, 12, 0);
							_.each(res.data.grouping.buckets, function (item) {
								// map data to right year..
								var year = moment(item.key).format('YYYY');
								var index = categories.indexOf(parseInt(year));
								if (index !== -1 && item.valueSum) {
									data[index] = item.valueSum.value;
									cmData[index] = item.cmSum.value;
								}
							});

							break;

						case 'month':
							data = _.range(0, 12, 0);
							cmData = _.range(0, 12, 0);

							_.each(res.data.grouping.buckets, function (item) {
								// map data to right year..
								var month = moment(item.key).format('YYYY-MM') + '-01';
								var index = categories.indexOf(month);

								if (index !== -1 && item.valueSum) {
									data[index] = item.valueSum.value;
									cmData[index] = item.cmSum.value;
								}
							});
							break;

						case 'user':
							categories = _.map(res.data.grouping.buckets, function (user) {
								var u = _.find(AppService.getActiveUsers(), { id: user.key });
								return u
									? { name: u.name, email: u.email }
									: { name: $translate.instant('default.inactiveUser'), email: '' };
							});

							data = _.map(res.data.grouping.buckets, function (item) {
								return item.valueSum.value;
							});
							cmData = _.map(res.data.grouping.buckets, function (item) {
								return item.cmSum.value;
							});
							// Max 12 users
							if (categories.length > 11) {
								categories = categories.slice(0, 11);
								data = data.slice(0, 11);
							}

							break;

						case 'stage':
							categories = _.map(res.data.grouping.buckets, function (stage) {
								var stageName = _.find(meta.orderStages.data, { id: stage.key });
								return stageName ? stageName.name : null;
							});

							data = _.map(res.data.grouping.buckets, function (item) {
								return item.valueSum.value;
							});
							cmData = _.map(res.data.grouping.buckets, function (item) {
								return item.cmSum.value;
							});
							if (categories.length > 11) {
								categories = categories.slice(0, 11);
								data = data.slice(0, 11);
							}

							break;
					}

					if (onlyUpdateYearSum) {
						return;
					}

					buildChart({ categories: categories, data: data, cmData: cmData });
				})
				.catch(error => console.error(error));
		}

		function buildChart(graphData) {
			if (graphData.categories?.length && _.head(graphData.categories).name) {
				graphData.categories = graphData.categories.map(function (category) {
					return category.name;
				});
			}

			if (!AccountOrderCtrl.grouping) {
				AccountOrderCtrl.grouping = groupable.year;
			}

			if (!graphData || !graphData.data || !graphData.data.length || !graphData.categories.length) {
				AccountOrderCtrl.showChart = false;
			} else {
				graphData.data = graphData.data.map(function (number) {
					return Number(number.toFixed(2));
				});
				AccountOrderCtrl.showChart = true;
				if (AccountCtrl.salesModel === 'cm') {
					graphData.cmData = graphData.cmData?.map(function (number) {
						return Number(number.toFixed(2));
					});

					AccountOrderCtrl.chart = {
						chart: {
							type: 'column',
							height: 216,
							backgroundColor: 'transparent',
							style: {
								fontFamily: '"Roboto"'
							},
							marginBottom: 35
						},
						colors: [colorMappings.get('bright-green'), colorMappings.get('green')],
						title: {
							text: ''
						},
						subtitle: {
							text: null
						},
						credits: { enabled: false },
						legend: {
							enabled: false
						},
						xAxis: {
							lineColor: 'transparent',
							tickLength: 0,
							categories: graphData.categories,
							labels: {
								overflow: false,
								rotation: false,
								style: {
									textOverflow: 'none',
									whiteSpace: 'nowrap'
								},
								formatter: function () {
									var compact = document.body.clientWidth <= 1180;
									var label = this.value;
									var prefix = '<span style="font-size: 10px;">';
									var postfix = '</span>';
									if (compact) {
										prefix = '<span style="font-size: 8px;">';
										postfix = '</span>';
									}
									switch (AccountOrderCtrl.grouping.id) {
										case 'year':
											label = compact ? moment('01-01-' + this.value).format("'YY") : this.value;
											break;
										case 'month':
											var format = this.value.indexOf(currentYear) !== -1 ? 'MMM' : "MMM 'YY";
											label = moment(this.value).format(format);
											break;
										case 'user':
											var initials = avatarService.getInitials({ name: this.value });
											label = compact ? initials : this.value;
											break;
									}

									return prefix + label + postfix;
								}
							}
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							gridLineColor: '#E4EAF0',
							endOnTick: true,
							maxPadding: 0.02,
							labels: {
								formatter: function () {
									if (this.value > 0) {
										return $filter('numberFormat')(this.value);
									}
								}
							}
						},
						tooltip: {
							enabled: true
						},
						plotOptions: {
							column: {
								pointPadding: 0,
								borderWidth: 0,
								grouping: false
							}
						},
						series: [
							{
								name: $translate.instant('default.order'),
								data: graphData.data,
								borderRadius: 4
							},
							{
								name: $translate.instant('default.contributionMargin'),
								data: graphData.cmData
							}
						]
					};
				} else {
					let name = 'default.order';
					if (AccountOrderCtrl.showRecurring) {
						if (AccountCtrl.salesModelOption === 'mrr') {
							name = 'default.monthlyValue';
						} else {
							name = 'default.annualValue';
						}
					}
					name = $translate.instant(name);

					AccountOrderCtrl.chart = {
						chart: {
							type: 'column',
							height: 171,
							backgroundColor: 'transparent',
							style: {
								fontFamily: '"Roboto"'
							},
							marginBottom: 35
						},
						colors: ['#5cb85c'],
						title: {
							text: ''
						},
						subtitle: {
							text: null
						},
						credits: { enabled: false },
						legend: {
							enabled: false
						},
						xAxis: {
							lineColor: 'transparent',
							tickLength: 0,
							categories: graphData.categories,
							labels: {
								overflow: false,
								rotation: false,
								style: {
									textOverflow: 'none',
									whiteSpace: 'nowrap'
								},
								formatter: function () {
									var compact = document.body.clientWidth <= 1180;
									var label = this.value;
									var prefix = '<span style="font-size: 10px;">';
									var postfix = '</span>';
									if (compact) {
										prefix = '<span style="font-size: 8px;">';
										postfix = '</span>';
									}
									switch (AccountOrderCtrl.grouping.id) {
										case 'year':
											label = compact ? moment('01-01-' + this.value).format("'YY") : this.value;
											break;
										case 'month':
											var format = this.value.indexOf(currentYear) !== -1 ? 'MMM' : "MMM 'YY";
											label = moment(this.value).format(format);
											break;
										case 'user':
											var initials = avatarService.getInitials({ name: this.value });
											label = compact ? initials : this.value;
											break;
									}

									return prefix + label + postfix;
								}
							}
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							gridLineColor: '#E4EAF0',
							endOnTick: true,
							maxPadding: 0.02,
							labels: {
								formatter: function () {
									if (this.value > 0) {
										return $filter('numberFormat')(this.value);
									}
								}
							}
						},
						tooltip: {
							enabled: true
						},
						plotOptions: {
							column: {
								pointPadding: 0,
								borderWidth: 0,
								borderRadius: 4
							}
						},
						series: [
							{
								name: name,
								data: graphData.data,
								pointWidth: 70
							}
						]
					};
				}
			}
		}

		// order inline save
		AccountOrderCtrl.saveInlineOrder = function (value, promise, field, orderId) {
			SaveInline(value, promise, field, orderId, 'Order');
		};

		AccountOrderCtrl.getOrders = function () {
			AccountOrderCtrl.ordersLoading = true;

			var rb = new RequestBuilder();

			if (AccountOrderCtrl.grouping.id === 'user') {
				rb.addSort(Order.attr.user.attr.name, true);
			} else if (AccountOrderCtrl.grouping.id === 'stage') {
				rb.addSort(Order.attr.stage.attr.name, true);
			}

			var filter = getFilters(rb);
			filter.addFilter(Order.attr.probability, filter.comparisonTypes.Equals, 100);
			filter.addFilter(Order.attr.stage, filter.comparisonTypes.NotEquals, excludedStages);
			filter.limit = AccountOrderCtrl.limit;
			filter.offset = AccountOrderCtrl.offset;

			currentOrderFilter = filter.build();

			Order.customer(customerId)
				.find(currentOrderFilter)
				.then(function (response) {
					AccountOrderCtrl.nrOfOrders = response.metadata.total;
					//AccountCtrl.orderTotal = response.metadata.total;
					accountOrders = response.data;
					var sumOrder = 0;
					// sum order rows
					angular.forEach(accountOrders, function (order) {
						order = FixOrder.print(order);
						if (moment(order.date).add(1, 'year').isAfter()) {
							sumOrder += order.valueInMasterCurrency;
						}
					});

					groupChange();
					// Scroll to top
					angular.element(window).scrollTop(0);

					AccountOrderCtrl.sales12Months = sumOrder;
					AccountOrderCtrl.ordersLoading = false;
				})
				.catch(error => console.error(error));
		};

		AccountOrderCtrl.getArrChanges = function () {
			if (!AccountOrderCtrl.isRecurringModel) {
				return;
			}
			AccountOrderCtrl.arrChangesLoading = true;

			var filters = new RequestBuilder();
			filters.addFilter(ARRChanges.attr.account.attr.id, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...meta.subAccountIds
			]);
			filters.addSort(ARRChanges.attr.date, false);
			filters.addSort(ARRChanges.attr.id, false);

			filters.limit = AccountOrderCtrl.limit;
			filters.offset = AccountOrderCtrl.offset;

			ARRChanges.find(filters.build())
				.then(function (response) {
					AccountOrderCtrl.nrOfArrChanges = response.metadata.total;
					arrChanges = response.data.filter(change => Math.abs(change.annualValueInMasterCurrency) > 1);
					let sumARR = 0;
					let sumMRR = 0;
					let sumARRThisYear = 0;
					let sumMRRThisYear = 0;

					angular.forEach(arrChanges, function (change) {
						if (moment(change.date).add(1, 'year').isAfter() && moment(change.date).isBefore()) {
							sumARR += change.annualValueInMasterCurrency;
							sumMRR += change.monthlyValueInMasterCurrency;
						}
						if (moment(change.date).year() === moment().year()) {
							sumARRThisYear += change.annualValueInMasterCurrency;
							sumMRRThisYear += change.monthlyValueInMasterCurrency;
						}
					});

					groupChange();
					// Scroll to top
					angular.element(window).scrollTop(0);

					const defaultCurrency = metadata.defaultCurrency;

					AccountOrderCtrl.arr12Months = sumARR * defaultCurrency.rate;
					AccountOrderCtrl.mrr12Months = sumMRR * defaultCurrency.rate;
					AccountOrderCtrl.arrThisYear = sumARRThisYear * defaultCurrency.rate;
					AccountOrderCtrl.mrrThisYear = sumMRRThisYear * defaultCurrency.rate;
					AccountOrderCtrl.arrChangesLoading = false;
				})
				.catch(error => console.error(error));
		};

		AccountOrderCtrl.getAgreements = function () {
			AccountOrderCtrl.agreementsLoading = true;
			var activeFilter = getFilters();
			var nd = new Date();

			var orGroup = activeFilter.orBuilder();
			orGroup.next();
			orGroup.addFilter(Agreement.attr.metadata.attr.agreementEnddate, activeFilter.comparisonTypes.Equals, null);
			orGroup.next();
			orGroup.addFilter(
				Agreement.attr.metadata.attr.agreementEnddate,
				activeFilter.comparisonTypes.GreaterThan,
				nd
			);
			orGroup.done();
			activeFilter.addSort(Agreement.attr.metadata.attr.agreementStartdate, false);

			var inactiveFilter = getFilters();
			inactiveFilter.addFilter(
				Agreement.attr.metadata.attr.agreementEnddate,
				inactiveFilter.comparisonTypes.LessThan,
				nd
			);
			inactiveFilter.addSort(Agreement.attr.metadata.attr.agreementStartdate, false);

			$q.all({
				active: Agreement.customer(customerId).find(activeFilter.build()),
				inactive: Agreement.customer(customerId).find(inactiveFilter.build())
			})
				.then(function (res) {
					AccountOrderCtrl.accountAgreements = checkInactiveDates(res.active.data);
					if (AccountOrderCtrl.hasFutureAgreementsList) {
						[AccountOrderCtrl.accountAgreements, AccountOrderCtrl.futureAccountAgreements] =
							splitAgreements(AccountOrderCtrl.accountAgreements);
					}
					AccountOrderCtrl.accountAgreementsInactive = checkInactiveDates(res.inactive.data);
					AccountOrderCtrl.agreementTotal =
						AccountOrderCtrl.accountAgreements.length + AccountOrderCtrl.accountAgreementsInactive.length;

					let setNextReOrder = false;

					if (AccountOrderCtrl.accountAgreements && AccountOrderCtrl.accountAgreements.length) {
						var nextPayDate = moment('199999-12-31'); // This is the largest moment value i could create
						var nextPayValue = 0;
						var nextPayValueMasterCurrency = 0;
						var nextPayCurrency = '';

						// Try to find closer one
						_.each(AccountOrderCtrl.accountAgreements, function (agr) {
							var thisIsBetter = false;
							// If this one has a nextOrderDate closer in time
							if (
								moment(agr.metadata.agreementNextOrderDate).isValid() &&
								moment(agr.metadata.agreementNextOrderDate).isBefore(nextPayDate)
							) {
								// If not same day or worth more than current (so same-day-orders shows the one with most value)
								if (
									!moment(agr.metadata.agreementNextOrderDate).isSame(nextPayDate, 'day') ||
									nextPayValueMasterCurrency > agr.valueInMasterCurrency
								) {
									thisIsBetter = true;
								}
							}

							if (thisIsBetter) {
								setNextReOrder = true;
								nextPayDate = agr.metadata.agreementNextOrderDate;
								nextPayValue = agr.value;
								nextPayValueMasterCurrency = agr.valueInMasterCurrency;
								nextPayCurrency = agr.currency;
							}
						});
						AccountOrderCtrl.nextReOrder = setNextReOrder ? nextPayDate : '';
						var defaultCurrency = metadata.defaultCurrency;
						if ((nextPayCurrency || '').toLowerCase() !== defaultCurrency.iso.toLowerCase()) {
							AccountOrderCtrl.nextReOrderValue = Math.round(
								nextPayValueMasterCurrency * defaultCurrency.rate
							);
						} else {
							AccountOrderCtrl.nextReOrderValue = nextPayValue;
						}
					}
					AccountOrderCtrl.agreementsLoading = false;
				})
				.catch(error => console.error(error));
		};

		AccountOrderCtrl.getSumOfChanges = function (changes) {
			if (!changes) {
				changes = arrChanges;
			}
			const field =
				AccountCtrl.salesModelOption === 'mrr' ? 'monthlyValueInMasterCurrency' : 'annualValueInMasterCurrency';
			const defaultCurrency = metadata.defaultCurrency;
			return _.reduce(
				changes,
				function (sum, change) {
					return sum + change[field] * defaultCurrency.rate;
				},
				0
			);
		};

		AccountOrderCtrl.onHistoryUserChange = change => {
			// Update array
			const i = arrChanges.findIndex(c => c.id === change.id);
			if (i !== -1) {
				arrChanges[i].users = change.users;
				$safeApply($scope);
			}
		};

		AccountOrderCtrl.onARRChangeClick = (date, type, change) => {
			const cid = change.client?.id || AccountCtrl.account.id;
			openModal('ARRChangeDrawerV2', { clientId: cid, date, type });
		};

		AccountOrderCtrl.getSalesThisYear = cm => {
			const thisYear = accountOrders.filter(order => moment(order.date).year() === moment().year());
			const defaultCurrency = metadata.defaultCurrency;

			return (thisYear ?? []).reduce((sum, order) => {
				if ((order.currency || '').toLowerCase() === defaultCurrency.iso.toLowerCase()) {
					return sum + order[cm ? 'contributionMarginLocalCurrency' : 'value'];
				} else {
					return (
						sum +
						Math.round(order[cm ? 'contributionMargin' : 'valueInMasterCurrency'] * defaultCurrency.rate)
					);
				}
			}, 0);
		};

		AccountOrderCtrl.getSumOfSales = function (orders) {
			var key = 'valueInMasterCurrency';
			var keyInOwnCurrency = 'value';

			if (orders === 1 && AccountOrderCtrl.accountAgreements?.length) {
				// agreements
				orders = AccountOrderCtrl.accountAgreements;
				key = 'yearlyValueInMasterCurrency';
				keyInOwnCurrency = 'yearlyValue';
			} else if (orders === 2 && AccountOrderCtrl.accountOrders?.length) {
				// First sale -->
				orders = AccountOrderCtrl.accountOrders[0].items;
			} else if (orders === 3 && AccountOrderCtrl.futureAccountAgreements) {
				orders = AccountOrderCtrl.futureAccountAgreements;
				key = 'yearlyValueInMasterCurrency';
				keyInOwnCurrency = 'yearlyValue';
			}
			var defaultCurrency = metadata.defaultCurrency;
			return _.reduce(
				orders,
				function (sum, order) {
					if (order.metadata && !AccountOrderCtrl.hasFutureAgreementsList) {
						// If subscription
						const startDate = moment(order.metadata.agreementStartdate);
						if (startDate.isAfter(moment())) {
							return sum;
						}
					}

					if ((order.currency || '').toLowerCase() === defaultCurrency.iso.toLowerCase()) {
						return sum + order[keyInOwnCurrency];
					} else {
						return sum + Math.round(order[key] * defaultCurrency.rate);
					}
				},
				0
			);
		};

		function checkInactiveDates(agreements) {
			if (AccountOrderCtrl.hasSubscriptionCardCards) {
				return agreements;
			}

			angular.forEach(agreements, function (agreement) {
				var nextOrderDate = moment(agreement.metadata.agreementNextOrderDate);

				if (agreement.metadata.agreementOrderCreationTime) {
					nextOrderDate.add(agreement.metadata.agreementOrderCreationTime, 'd');
				}
				if (
					(!agreement.metadata.willCreateMoreOrders && agreement.id) ||
					(agreement.metadata.agreementEnddate &&
						!moment(nextOrderDate).isBefore(agreement.metadata.agreementEnddate))
				) {
					agreement.metadata.agreementNextOrderDate = null;
				}
			});
			return agreements;
		}

		function splitAgreements(agreements) {
			const future = [];
			const active = [];

			for (const agreement of agreements) {
				if (moment(agreement.metadata.agreementStartdate).isAfter(moment())) {
					future.push(agreement);
				} else {
					active.push(agreement);
				}
			}

			return [active, future];
		}

		function addAgreement(agreement) {
			if (!AccountOrderCtrl.hasFutureAgreementsList) {
				AccountOrderCtrl.accountAgreements.push(agreement);
				return;
			}

			if (moment(agreement.metadata.agreementStartdate).isSameOrBefore(moment())) {
				AccountOrderCtrl.accountAgreements.push(agreement);
			} else {
				AccountOrderCtrl.futureAccountAgreements.push(agreement);
			}
		}

		var getGroupingFromHash = function () {
			var hash = $location.search();
			const validGroupings = new Set(Object.keys(groupable));
			var groupBy = hash.groupBy && validGroupings.has(hash.groupBy) ? hash.groupBy : 'year';
			return groupable[groupBy];
		};

		var getTypeFromHash = function () {
			var hash = $location.search();
			return hash.type || (AccountCtrl.salesModel === 'rr' ? 'recurring' : 'cashflow');
		};

		function getFilters(rb) {
			var filters = rb || new RequestBuilder();

			var clientOr = filters.orBuilder();
			clientOr.next();
			clientOr.addFilter(Order.attr.account.attr.id, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...meta.subAccountIds
			]);
			clientOr.next();
			clientOr.addFilter(Order.attr.clientConnection, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...meta.subAccountIds
			]);
			clientOr.done();

			filters.addSort(Order.attr.date, false);

			return filters;
		}

		function updateFlags() {
			AccountOrderCtrl.showRecurring = AccountOrderCtrl.isRecurringModel && AccountOrderCtrl.type === 'recurring';
			AccountOrderCtrl.hideOrders = AccountOrderCtrl.showRecurring;
			AccountOrderCtrl.hideAgreements =
				AccountOrderCtrl.isRecurringModel && AccountOrderCtrl.type !== 'recurring';
		}

		var init = function () {
			excludedStages = AppService.getStages('excludedIds', true);
			customerId = AppService.getCustomerId();
			AccountOrderCtrl.isRecurringModel = AccountCtrl.salesModel === 'rr' || hasCMWithRR();
			AccountOrderCtrl.grouping = getGroupingFromHash();
			AccountOrderCtrl.type = getTypeFromHash();
			updateFlags();

			AccountOrderCtrl.groupARRchangesByUser = metadata.params.GroupARRChangesByUser;
			AccountOrderCtrl.showInactive = false;
			AccountOrderCtrl.hasAgreements =
				metadata.params.AgreementEnabled &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
				AppService.getAccessRights().Agreement;
			AccountOrderCtrl.hasFutureAgreementsList =
				Tools.FeatureHelper.hasSoftDeployAccess('FUTURE_AGREEMENTS_LIST');
			AccountOrderCtrl.hasSubscriptionCardCards =
				Tools.FeatureHelper.hasSoftDeployAccess('AGREEMENT_GROUP_CARDS');
			AccountOrderCtrl.hasClientTarget = Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_TARGET');

			// make sure we sort by date when no grouping is selected
			AccountOrderCtrl.getOrders();

			if (AccountOrderCtrl.hasAgreements) {
				AccountOrderCtrl.getAgreements();
			}

			AccountOrderCtrl.getArrChanges();
			AccountOrderCtrl.isAdmin = AppService.getSelf().administrator;

			renderSubscriptionPromo(Tools.AppService.getAccountSelf(), AccountCtrl.account);

			createSeries();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import { OutsideClick } from '@upsales/components';
import { insertAfter } from 'Store/helpers/array';
import DatePreset from 'App/upsales/common/services/datePresetService';

ReactTemplates.upFilters.components.select = window.ReactCreateClass({
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	handleClickOutside: function () {
		this.setState({
			expanded: false
		});
	},
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	onSelect: function (event) {
		const value = event.target.getAttribute('data-value');
		this.toggleMenu();
		this.props.onSelect(value);
	},
	toggleMenu: function () {
		if (this.state.expanded) {
			this.refs.button.blur();
		}

		this.setState({
			expanded: !this.state.expanded
		});
	},
	render: function () {
		const hasValue = this.props.selected !== null && this.props.selected !== undefined ? true : false;
		const title = hasValue ? this.props.selected.text : this.props.placeholder;
		let buttonClasses = 'btn btn-default btn-sm react-select-button';

		if (hasValue) {
			buttonClasses += ' has-value';
		}

		const selectItems = this.props.items.map(
			function (item, index) {
				return (
					<li
						key={index}
						className="react-select-dropdown-row"
						data-value={item.value}
						onClick={this.onSelect}
					>
						{item.text}
					</li>
				);
			}.bind(this)
		);

		return (
			<div ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick targetRef={this.getRef} className="react-select" outsideClick={this.handleClickOutside}>
					<button ref="button" className={buttonClasses} onClick={this.toggleMenu}>
						{title}
					</button>
					{this.state.expanded && <ul className={'react-select-dropdown'}>{selectItems}</ul>}
				</OutsideClick>
			</div>
		);
	}
});

ReactTemplates.upFilters.components.datepicker = window.ReactCreateClass({
	datepicker: null,
	getDefaultProps: function () {
		return {
			value: undefined,
			name: '',
			placeholder: '',
			className: 'form-control input-sm',
			container: '#datepicker-container',
			placement: 'bottom-left'
		};
	},
	getInitialState: function () {
		return {
			value: this.format(this.props.value)
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		this.setState({
			value: this.format(newProps.value)
		});
	},
	ngModel: function (onChange) {
		const self = this;

		self.$setViewValue = function (newValue) {
			onChange(newValue);
		};
		self.$render = function () {};
	},
	format: function (val) {
		if (val && moment(val).isValid()) {
			return moment(val).format('L');
		}
		return val;
	},
	inputChange: function (event) {
		this.setState({ value: event.target.value });
	},
	onChange: function (newValue) {
		let value;

		if (newValue === '') {
			value = null;
		} else if (typeof newValue === 'string' && moment(newValue, 'L').isValid()) {
			value = moment(newValue, 'L').toDate();
		} else if (typeof newValue !== 'string' && moment(newValue).isValid()) {
			value = new Date(newValue);
		} else if (moment(this.props.value).isValid()) {
			value = new Date(this.props.value);
		} else {
			value = null;
		}

		this.datepicker.update(value);
		this.setState({ value: this.format(value) });

		const outValue = this.props.utc && value ? moment.utc(moment(value).format('YYYY-MM-DD')).toJSON() : value;
		this.props.onChange(outValue);
	},
	componentDidMount: function () {
		const ngModel = new this.ngModel(this.onChange);
		const $datepicker = window.Tools.$injector.get('$datepicker');
		const $rootScope = window.Tools.$injector.get('$rootScope');
		const weekStart = (moment.localeData()._week && moment.localeData()._week.dow) || 0;

		this.datepicker = $datepicker(jQuery(ReactDOM.findDOMNode(this)), ngModel, {
			controller: ngModel,
			autoclose: true,
			startWeek: weekStart,
			container: this.props.container,
			scope: $rootScope,
			placement: this.props.placement
		});

		this.datepicker.update(this.state.value);
	},
	onKeyPress: function (event) {
		if (event.key === 'Enter') {
			this.onChange(event.target.value);
		}
	},
	onBlur: function (event) {
		this.onChange(event.target.value);
	},
	render: function () {
		return (
			<input
				disabled={this.props.disabled}
				name={this.props.name}
				className={this.props.className}
				type={'text'}
				value={this.state.value || ''}
				onChange={this.inputChange}
				onBlur={this.onBlur}
				onKeyPress={this.onKeyPress}
				placeholder={this.props.placeholder}
				autoComplete="off"
			/>
		);
	}
});

ReactTemplates.upFilters.filterTypes.dateRange = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			msBeforeSearch: 300
		};
	},
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	UNSAFE_componentWillMount: function () {
		this.search = _.debounce(this.search, this.props.msBeforeSearch);
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	search: function (filter, options) {
		this.props.onChange(filter, options);
	},
	onPresetChange: function (event) {
		const filter = {
			filterName: this.props.filterName,
			comparisonType: this.props.filter.comparisonType ?? 'Equals',
			value: {
				preset: event.target.value
			},
			type: 'dateRange'
		};

		this.search(filter, {
			action: 'add'
		});
	},
	onInputChange: function (target, dateObj) {
		const oldFilter = this.props.filter;

		const filter = {
			filterName: this.props.filterName,
			comparisonType: oldFilter.comparisonType ?? 'Equals',
			value: {
				preset: 'custom',
				start: oldFilter && oldFilter.value ? oldFilter.value.start : null,
				end: oldFilter && oldFilter.value ? oldFilter.value.end : null
			},
			type: 'dateRange'
		};

		if (dateObj) {
			if (target === 'start') {
				filter.value.start = dateObj;

				if (filter.value.end && filter.value.end < filter.value.start) {
					filter.value.end = filter.value.start;
				}
			} else {
				filter.value.end = dateObj;
				if (filter.value.start && filter.value.start > filter.value.end) {
					filter.value.start = filter.value.end;
				}
			}

			if (filter.value.end) {
				filter.value.end = moment(filter.value.end).endOf('day').toDate();
			}

			this.search(filter, {
				action: 'add'
			});
		} else {
			if (target === 'start') {
				filter.value.start = null;
			} else {
				filter.value.end = null;
			}

			let action = 'add';

			if (filter.value.start === null && filter.value.end === null) {
				action = 'remove';
			}

			this.search(filter, {
				action: action
			});
		}
	},
	getPresetValues: function (preset) {
		const presetConfig = DatePreset.get(preset);
		let presetValues = {
			start: null,
			end: null
		};

		if (presetConfig !== undefined && typeof presetConfig.get === 'function') {
			presetValues = presetConfig.get();
		}

		let start = presetValues.start || null;
		let end = presetValues.end || null;

		if (start !== null && moment(start).isValid()) {
			start = moment(start);
		}
		if (end !== null && moment(end).isValid()) {
			end = moment(end);
		}

		return {
			start: start,
			end: end
		};
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.search(this.props.filter, {
			action: 'remove'
		});

		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		const filter = this.props.filter;
		const format = 'L';

		let valueString = '';
		valueString += filter.value.start ? moment(filter.value.start).format(format) : '*';
		valueString += ' - ';
		valueString += filter.value.end ? moment(filter.value.end).format(format) : '*';

		return valueString;
	},

	onToggleComperator: function (value) {
		let newFilter;
		const currentFilter = this.props.filter;
		const newComparisionType = value ? 'NotEquals' : 'Equals';

		if (currentFilter && currentFilter.value) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;

			this.search(newFilter, {
				action: 'add'
			});
		}
	},

	render: function () {
		const filter = this.props.filter;
		let preset;

		if (!filter.value) {
			preset = this.props.preset || filter.presets[0];
			filter.value = {
				preset: preset
			};
		} else {
			preset = filter.value.preset;
		}

		let start, end;
		if (preset !== 'custom') {
			const presetValues = this.getPresetValues(preset);
			start = presetValues.start;
			end = presetValues.end;
			filter.value.start = start ? start.toDate() : null;
			filter.value.end = end ? end.toDate() : null;
		} else {
			start = moment(filter.value.start).isValid() ? moment(filter.value.start) : null;
			end = moment(filter.value.end).isValid() ? moment(filter.value.end) : null;
		}

		if (start) {
			start = start.toDate();
		}
		if (end) {
			end = end.toDate();
		}

		const active = preset !== 'whenever';

		const presets = [...filter.presets];

		// Insert some brokenFiscalYear presets if enabled and not already added in old attribute file
		if (
			Tools.AppService.getMetadata().params.brokenFiscalYearEnabled &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
		) {
			if (presets.includes('currentQuarter') && !presets.includes('currentFiscalQuarter')) {
				insertAfter(presets, 'currentQuarter', 'currentFiscalQuarter');
			}
			if (presets.includes('lastQuarter') && !presets.includes('lastFiscalQuarter')) {
				insertAfter(presets, 'lastQuarter', 'lastFiscalQuarter');
			}
			if (presets.includes('currentYear') && !presets.includes('currentFiscalYear')) {
				insertAfter(presets, 'currentYear', 'currentFiscalYear');
			}
			if (presets.includes('lastYear') && !presets.includes('lastFiscalYear')) {
				insertAfter(presets, 'lastYear', 'lastFiscalYear');
			}
		}

		const options = presets
			.filter(function (preset) {
				return preset !== 'custom' && preset !== 'lastXdays';
			})
			.map(
				function (option) {
					const text = Tools.$translate('date.' + option);
					let classes = 'drilldown-select-row';

					if (option === preset) {
						classes = 'drilldown-select-row selected-row';
					}

					return (
						<button
							key={'option-' + option}
							value={option}
							className={classes}
							onClick={this.onPresetChange}
						>
							{text}
						</button>
					);
				}.bind(this)
			);

		const titleString = Tools.$translate(filter.title);
		const endString = Tools.$translate('default.to') + ':';
		const startString = Tools.$translate('default.from') + ':';
		const valueString = preset !== 'custom' ? Tools.$translate('date.' + preset) : this.formatValueString();

		let comparitorToggle = null;

		if (filter.multiComparitors) {
			var checked = false;
			if (filter && filter.comparisonType === 'NotEquals') {
				checked = true;
			}
			comparitorToggle = (
				<div style={{ marginBottom: '8px' }}>
					<span>
						<ReactTemplates.upFilters.components.toggle
							checked={checked}
							onChange={this.onToggleComperator}
							ref={'isIsNot'}
						/>
					</span>
					<span style={{ paddingLeft: '5px' }}>{Tools.$translate('default.exclude')}</span>
				</div>
			);
		}

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{titleString}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'drilldown date-drilldown'}>
						{comparitorToggle}
						<div id="datepicker-container">
							<span className={'datepicker-wrap'}>
								<ReactTemplates.upFilters.components.datepicker
									placeholder={startString}
									name={'startDate'}
									value={start}
									onChange={this.onInputChange.bind(this, 'start')}
								/>
							</span>
							<span style={{ textAlign: 'center' }}>{' - '}</span>
							<span className={'datepicker-wrap pull-right'}>
								<ReactTemplates.upFilters.components.datepicker
									placeholder={endString}
									name={'endDate'}
									value={end}
									onChange={this.onInputChange.bind(this, 'end')}
								/>
							</span>
						</div>

						<div className={'btn-group btn-block'}>{options}</div>
					</div>
				)}
			</div>
		);
	}
});

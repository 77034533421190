'use strict';
import React from 'react';
import classnames from 'classnames';
import { Button, OutsideClick, Toggle } from '@upsales/components';
import SystemMail from 'Components/SystemMail';
import { makeCancelable } from '../../../../../../babel/helpers/promise';
import AdminHeader from 'Components/Admin/AdminHeader';
import moment from 'moment';

ReactTemplates.admin.visitSettings = window.ReactCreateClass({
	getInitialState: function () {
		return {
			searchStr: '',
			scores: {},
			hidden: {},
			hasCopied: false,
			showAll: false,
			showMailInstruction: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			siteVisits: t('default.siteVisits'),
			addAccount: t('default.addAccount'),
			name: t('default.name'),
			visits: t('default.visits'),
			copy: t('default.copy'),
			copied: t('default.copiedToClipboard'),
			noPages: t('default.noPages'),
			showMore: t('default.showMore'),
			score: t('default.score'),
			loadingEllipsis: t('default.loadingEllipsis'),
			includeRetargeting: t('admin.visits.includeRetargeting'),
			search: t('default.search'),
			setPageScores: t('admin.setPageScores'),
			setPageScoresInfo: t('admin.setPageScoresInfo'),
			scriptForSite: t('admin.scriptForSite'),
			scriptForSiteInfo: t('admin.scriptForSiteInfo'),
			rejectList: t('default.rejectList'),
			description: t('admin.visits.description'),
			scoringDescription: t('admin.visits.scoreDescription'),
			hide: t('default.hide'),
			showAll: t('default.showAll'),
			date: t('default.date'),
			sendInstructionByEmail: t('admin.sendInstructionByEmail'),
			visitsHide: t('admin.visits.hide'),
			visitsShow: t('admin.visits.show')
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setScores(nextProps);
		this.setHidden(nextProps);
		this.editor.setValue(this.props.rootData.pageData.script, 1);
	},
	componentDidMount: function () {
		this.editor = window.ace.edit(this._scriptContainer);
		this.editor.setTheme('ace/theme/monokai');
		this.editor.setShowPrintMargin(false);
		this.editor.setReadOnly(true);
		this.editor.renderer.setShowGutter(false);
		this.editor.renderer.setPadding(10);

		var session = this.editor.getSession();
		session.setMode('ace/mode/html');
		session.setUseWrapMode(true);
		session.setUseWorker(false);

		this.editor.container.style.lineHeight = 2;
		this.editor.renderer.updateFontSize();
		this.editor.setValue(this.props.rootData.pageData.script, 1);
		this.editor.$blockScrolling = Infinity;
		// Disable search
		this.editor.commands.addCommands([
			{
				name: 'unfind',
				bindKey: {
					win: 'Ctrl-F',
					mac: 'Command-F'
				},
				exec: function () {
					return false;
				},
				readOnly: true
			}
		]);
		this.setScores();
		this.setHidden();
	},
	componentWillUnmount: function () {
		if (this.editor) {
			this.editor.destroy();
		}
		if (this.hideTimer) {
			clearTimeout(this.hideTimer);
		}
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	},
	setScores: function (nextProps) {
		var scores = {};
		_.each((nextProps || this.props).rootData.pageData.pages, function (p) {
			scores[p.id] = p.score || 0;
		});
		this.setState({ scores: scores });
	},
	setHidden: function (nextProps) {
		var hidden = {};
		_.each((nextProps || this.props).rootData.pageData.pages, function (p) {
			hidden[p.id] = p.hide || 0;
		});
		this.setState({ hidden: hidden });
	},
	removeAccount: function (key, e) {
		e.stopPropagation();
		this.props.rootData.removeFromRejectlist(key);
	},
	onSearch: function (e) {
		this.props.rootData.onSearch(e.target.value);
		this.setState({ searchStr: e.target.value });
	},
	renderTableHeader: function (title, attr, style, icon, elevio) {
		var classNames = '';
		var arrow = null;
		var currentSorting = this.props.rootData.pageData.currentSorting;
		var help = null;

		if (attr) {
			classNames += 'sortable';
			if (attr === currentSorting.attribute) {
				classNames += ' active';

				arrow = <b className={'fa fa-sort-' + icon + (currentSorting.ascending ? '-asc' : '-desc')} />;
			} else {
				arrow = <b className="fa fa-sort" />;
			}
		}
		if (elevio) {
			help = <ReactTemplates.elevio articleId={elevio} sidebar={true} />;
		}

		return (
			<th style={style || null} className={classNames} onClick={attr ? this.onSort.bind(this, attr) : null}>
				{title} {help} {arrow}
			</th>
		);
	},
	onSort: function (attr) {
		var currentSorting = this.props.rootData.pageData.currentSorting;
		var newSort = {
			attribute: attr,
			ascending: attr === currentSorting.attribute ? !currentSorting.ascending : false
		};
		this.props.rootData.onSort(newSort);
	},
	updateScore: function (page, e) {
		var value = parseInt(e.target.value.replace(/ /g, ''));

		if (page.score !== value) {
			var scores = this.state.scores;

			scores[page.id] = value;

			this.props.rootData.onPageScoreUpdate(page, value);
			this.setState({ scores: scores });
		}
	},
	setScoreState: function (id, e) {
		var scores = this.state.scores;
		scores[id] = parseInt(e.target.value.replace(/ /g, ''));
		this.setState({ scores: scores });
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	copyToClipboard: function () {
		var self = this;
		this._hiddenScript.select();

		try {
			document.execCommand('copy');
			this.setState({
				hasCopied: true
			});

			setTimeout(function () {
				self.setState({
					hasCopied: false
				});
			}, 5000);
		} catch (err) {
			// Do nothing
		}
	},
	handleKeyDown: function (page, event) {
		var scores = this.state.scores;
		if (event.key === 'ArrowUp') {
			event.preventDefault();
			scores[page.id] += 1;
		} else if (event.key === 'ArrowDown') {
			event.preventDefault();
			scores[page.id] -= 1;
			if (scores[page.id] < 0) {
				scores[page.id] = 0;
			}
		} else if (event.key === 'Enter') {
			event.preventDefault();
			this.props.rootData.onPageScoreUpdate(page, scores[page.id]);
		} else {
			return;
		}

		this.setState({ scores: scores });
	},
	setHideState: function (page) {
		var hidden = this.state.hidden;
		hidden[page] = hidden[page] === 0 ? 1 : 0;
		this.props.rootData.onPageHideUpdate(page, hidden[page]);
		this.setState({ hidden: hidden });
	},
	onToggleShowAll: function () {
		this.props.rootData.onToggleShowAll(this.state.searchStr);
	},
	formatDate: function (date) {
		return moment(date).format('YYYY-MM-DD');
	},
	setToggleState: function () {
		this.setState({
			showMailInstruction: !this.state.showMailInstruction
		});
	},
	sendMail: function (email) {
		this.cancelablePromise = makeCancelable(this.props.rootData.sendSystemMail(email));
		return this.cancelablePromise.promise.then(() => {
			this.hideTimer = setTimeout(() => {
				this.setState({
					showMailInstruction: false
				});
			}, 2000);
		});
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var numberFormat = Tools.$filter('numberFormat');

		const pages = rootData.pageData.pages.map(page => {
			if (!rootData.pageData.showAll && self.state.hidden[page.id]) return false;
			return (
				<tr
					className={'two-lines'}
					key={'page-' + page.id}
					style={self.state.hidden[page.id] ? { backgroundColor: '#EDF1F5' } : null}
				>
					<td>
						<div className="ellipsis-wrap" style={{ height: '45px' }}>
							<div className="ellipsis" style={{ top: '5px' }}>
								{page.name}
							</div>
							<div className="subtitle ellipsis" style={{ bottom: 0 }}>
								{page.url}
							</div>
						</div>
					</td>
					<td>{numberFormat(page.pageImpression)}</td>
					<td>{self.formatDate(page.lastUpdateDate)}</td>
					<td>
						<input
							type="text"
							style={{ width: '60px' }}
							disabled={rootData.pageData.savingScore}
							className="form-control input-sm"
							value={numberFormat(self.state.scores[page.id] || 0)}
							onChange={self.setScoreState.bind(self, page.id)}
							onKeyDown={self.handleKeyDown.bind(self, page)}
							onBlur={self.updateScore.bind(self, page)}
						/>
					</td>
					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							{ReactTemplates.TOOLS.withTooltip(
								<button
									data-test-id="delete-stage-button"
									className="up-btn btn-link btn-grey delete-stage"
									onClick={self.setHideState.bind(self, page.id)}
								>
									<i className={self.state.hidden[page.id] ? 'fa fa-eye' : 'fa fa-eye-slash'} />
								</button>,
								self.state.hidden[page.id] ? self.lang.visitsShow : self.lang.visitsHide,
								{ placement: 'top', delayShow: 200 }
							)}
						</div>
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-visit">
				<AdminHeader
					title={this.lang.siteVisits}
					description={this.lang.description}
					image="website-visits.svg"
				/>

				<div id="admin-content">
					<div className="admin-card script-card">
						<div className="admin-info-row">
							<h2>
								{self.lang.scriptForSite}{' '}
								<ReactTemplates.elevio sidebar={self.lang.scoringDescription} articleId={272} />
							</h2>
							<div className="info-row-info">{self.lang.scriptForSiteInfo}</div>
							<div className="info-row-content">
								<div style={{ position: 'relative', zIndex: 1000, height: 60 }}>
									<div
										ref={self.setRef.bind(self, '_scriptContainer')}
										style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 1000 }}
									/>
									<Button className="copy-btn" size="sm" onClick={self.copyToClipboard}>
										{self.state.hasCopied ? self.lang.copied : self.lang.copy}
									</Button>
									<input
										type="text"
										ref={self.setRef.bind(self, '_hiddenScript')}
										className="hidden-input"
										value={rootData.pageData.script}
									/>
								</div>
							</div>
						</div>
						<div className="admin-info-row">
							<div className="info-row-content">
								<div className="toggle-retargeting">
									<label>{this.lang.includeRetargeting}</label>
									<Toggle
										color="medium-green"
										checked={rootData.pageData.includeRetargeting}
										onChange={rootData.toggleRetargeting}
									/>
								</div>
							</div>
						</div>
						<div className="border-top">
							<OutsideClick
								targetClass="SystemMail"
								className={classnames('send-mail-wrapper', {
									'show-mail': this.state.showMailInstruction
								})}
								outsideClick={this.setToggleState}
								listen={this.state.showMailInstruction}
							>
								<Button onClick={this.setToggleState} type="link">
									{this.lang.sendInstructionByEmail}
								</Button>
								<SystemMail sendMail={this.sendMail} />
							</OutsideClick>
						</div>
					</div>
					<span className="page-subtitle show-no-score-pages">
						<a onClick={self.props.tools.$state.go.bind(this, 'administration.marketRejectlist')}>
							{self.lang.rejectList}
						</a>
					</span>
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{self.lang.siteVisits}</span>
							<div className="pull-right">
								<div className="table-search">
									<input
										type="text"
										value={self.state.searchStr}
										onChange={self.onSearch}
										placeholder={self.lang.search}
									/>
									<b className="fa fa-search" />
								</div>
							</div>
							{rootData.pageData.hasHiddenPages ? (
								<div style={{ display: 'inline' }}>
									<div className="pull-right" style={{ marginRight: '10px' }}>
										<Toggle
											color="medium-green"
											size="md"
											checked={rootData.pageData.showAll}
											onChange={self.onToggleShowAll}
										/>
									</div>
									<div className="pull-right" style={{ marginRight: '5px' }}>
										{self.lang.showAll}
									</div>
								</div>
							) : null}
							<div className="pull-right scoring-description btn-link">
								<ReactTemplates.elevio text={self.lang.scoringDescription} articleId={514} />
							</div>
						</div>

						{pages.length ? (
							<table>
								<thead>
									<tr>
										{self.renderTableHeader(self.lang.name, 'name', null, 'alpha')}
										{self.renderTableHeader(
											self.lang.visits,
											'pageImpression',
											{ width: '110px' },
											'numeric',
											null
										)}
										{self.renderTableHeader(
											self.lang.date,
											'lastUpdateDate',
											{ width: '140px' },
											'numeric',
											null
										)}
										{self.renderTableHeader(
											self.lang.score,
											'score',
											{ width: '165px' },
											'numeric',
											817
										)}

										{self.renderTableHeader(null, null, { width: '60px' }, null, null)}
									</tr>
								</thead>
								<tbody>{pages}</tbody>
							</table>
						) : null}
						{rootData.pageData.thereIsMore ? (
							<button
								type="button"
								onClick={rootData.onShowMore}
								className="table-btn table-show-more"
								disabled={
									rootData.pageData.loadingMore ||
									rootData.pageData.savingScore ||
									rootData.pageData.savingHide
								}
							>
								{rootData.pageData.loadingMore ? self.lang.loadingEllipsis : self.lang.showMore}
							</button>
						) : null}
						{!pages.length ? <div className="table-no-result">{self.lang.noPages}</div> : null}
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import './ActionSquare.scss';

const ActionSquare = props => {
	const { size = 'medium' } = props;

	return (
		<div className={`action-square ${size}`}>
			<b className="fa fa-plus" />
		</div>
	);
};

ActionSquare.propTypes = {
	size: PropTypes.string
};

export default ActionSquare;

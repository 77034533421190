'use strict';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';

angular.module('upDirectives').directive('upBigAddressMap', [
	'$q',
	'GeocodeService',
	function ($q, GeocodeService) {
		const getMap = () => {
			const element = angular.element('<div/>');
			const map = new google.maps.Map(element[0]);
			return { map, element };
		};

		const formatAddress = ({ address, city, zipcode, country }) => {
			const street = address && address.split(',')[0];

			let addressString = '';
			if (street && street.toLowerCase().indexOf('box') === -1) {
				addressString += street + ', ';
				addressString += zipcode ? zipcode + ' ' : '';
			}
			addressString += city || '';

			return {
				addressString,
				country: country || 'SE',
				url: 'http://www.google.com/maps/place/' + addressString.replace(/ /g, '+')
			};
		};

		return {
			restrict: 'A',
			replace: true,
			scope: {
				config: '=config',
				addresses: '=upBigAddressMap',
				labels: '=labels'
			},
			link: ($scope, element) => {
				$scope.$watch(
					'addresses',
					addresses => {
						if (typeof addresses === 'undefined') {
							return;
						}

						const urls = {};
						const promises = [];

						Object.keys(addresses).forEach(key => {
							const address = addresses[key];
							const { addressString, country, url } = formatAddress(address);

							urls[key] = url;
							promises.push(GeocodeService.getCoordinates(addressString, country, address.type));
						});

						return $q.all(promises).then(coordinates => {
							loader
								.load()
								.then(() => {
									const res = getMap();

									res.element.addClass('big-map-canvas');
									element.html('').append(res.element[0]);
									google.maps.event.trigger(res.map, 'resize');

									coordinates = coordinates.filter(c => c.found);
									if (coordinates.length) {
										res.map.setCenter(
											new google.maps.LatLng(coordinates[0].lat, coordinates[0].lng)
										);
									}

									const markerBounds = new google.maps.LatLngBounds();
									coordinates.forEach(c => {
										const position = new google.maps.LatLng(c.lat, c.lng);
										const icon = `img/map_label_${$scope.labels[c.type]}.png`;
										const marker = new google.maps.Marker({
											position,
											icon,
											map: res.map
										});
										google.maps.event.addListener(marker, 'click', () => {
											window.open(urls[c.type]);
										});
										markerBounds.extend(position);
									});

									if (markerBounds.isEmpty()) {
										res.map.setZoom(4);
										res.map.setCenter(new google.maps.LatLng(62.1983366, 17.5671981));
									} else {
										res.map.fitBounds(markerBounds);
										google.maps.event.addListenerOnce(res.map, 'bounds_changed', function () {
											if (this.getZoom() > 15) {
												this.setZoom(15);
											}
										});
									}

									const styles = [
										{
											featureType: 'poi',
											stylers: [{ visibility: 'off' }]
										}
									];

									const styledMap = new google.maps.StyledMapType(styles, { name: 'Styled Map' });

									res.map.mapTypes.set('map_style', styledMap);
									res.map.setMapTypeId('map_style');

									$scope.config.coordinates = coordinates;
									$scope.config.response = res;
								})
								.catch(e => {
									logError(e, 'Failed to load Google');
								});
						});
					},
					true
				);
			}
		};
	}
]);

import React, { useRef, useState } from 'react';

import './EditNote.scss';

import BemClass from '@upsales/components/Utils/bemClass';
import {
	Button,
	Column,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row,
	Textarea,
	Icon,
	Tooltip
} from '@upsales/components';
import insertSignatureOnRef from 'App/helpers/insertSignatureOnRef';

type EditNoteProps = {
	$modalParams: {
		notes: string;
		title?: string;
		icon?: React.ComponentProps<typeof Icon>['name'];
		saveText?: string;
		closeText?: string;
		mutable: boolean;
		textareaTooltip?: string;
	};
	resolve: (notes: string) => void;
	reject: () => void;
};

const EditNote: React.FC<EditNoteProps> = ({ $modalParams, resolve, reject }) => {
	const classes = new BemClass('EditNote');
	const textarea = useRef<HTMLTextAreaElement>();
	const [notes, setNotes] = useState($modalParams.notes);

	const t = Tools.$translate;

	const lang = {
		title: t($modalParams.title || 'default.notes'),
		save: t($modalParams.saveText || 'default.save'),
		close: t($modalParams.closeText || ($modalParams.mutable ? 'default.abort' : 'default.close')),
		signature: t('default.insertSignature')
	};

	const close = () => reject();

	const save = () => resolve(notes);

	return (
		<div className={classes.b()}>
			<ModalHeader title={lang.title} icon={$modalParams.icon} />
			<ModalContent>
				<Tooltip title={$modalParams.textareaTooltip ?? ''} position="top">
					<Textarea
						className={classes.elem('text').b()}
						textareaRef={r => (textarea.current = r)}
						value={notes || ''}
						disabled={!$modalParams.mutable}
						inputClassName={classes.elem('input').b()}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
					/>
				</Tooltip>
			</ModalContent>
			<ModalControls>
				<Row>
					{$modalParams.mutable ? (
						<Column align="left">
							<Button
								onClick={() =>
									textarea.current ? insertSignatureOnRef(textarea.current, setNotes) : null
								}
								data-testid="signature-btn"
								type="link"
							>
								{lang.signature}
							</Button>
						</Column>
					) : null}
					<Column align="right">
						{$modalParams.mutable ? (
							<Button onClick={save} shadow="none" data-testid="save-btn" className="main-action">
								{lang.save}
							</Button>
						) : null}
						<Button onClick={close} color="grey" data-testid="close-btn" type="link">
							{lang.close}
						</Button>
					</Column>
				</Row>
			</ModalControls>
		</div>
	);
};

export default EditNote;

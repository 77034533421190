'use strict';

angular.module('upAttributes').service('GroupMailCategoryAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'id'
				},
				title: {
					title: 'default.title',
					field: 'title',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'title'
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'description'
				},
				active: {
					title: 'default.active',
					field: 'active',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'active'
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: {},
				keys: {
					ID: 'id',
					TITLE: 'title',
					DESCRIPTION: 'description',
					ACTIVE: 'active'
				}
			};
		};
	}
]);

'use strict';

angular.module('services.ad', []).service('AdService', [
	'$translate',
	'Ads',
	'AppService',
	'Campaign',
	'Account',
	'LeaderPage',
	'RequestBuilder',
	function ($translate, Ads, AppService, Campaign, Account, LeaderPage, RequestBuilder) {
		var instance = {};
		var statuses = Ads.statuses;
		var adAccount = AppService.getAdAccount();
		var status = {
			isRunning: function (adCampaign) {
				return adCampaign.status > statuses.draft && adCampaign.status < statuses.done;
			}
		};

		instance.isRunning = status.isRunning;

		instance.disableEditing = function (status) {
			return {
				name: false,
				budget: status !== Ads.statuses.draft,
				dates: status !== Ads.statuses.draft,
				targets: status > Ads.statuses.paused,
				range: status !== Ads.statuses.draft,
				templates: status !== Ads.statuses.draft,
				creatives: status !== Ads.statuses.draft
			};
		};

		instance.allowedActions = function (status) {
			return {
				launch: status === Ads.statuses.draft || status === Ads.statuses.paused,
				pause: status === Ads.statuses.active,
				finish: status === Ads.statuses.paused || status === Ads.statuses.active,
				archive: status === Ads.statuses.done,
				remove: status === Ads.statuses.draft
			};
		};

		instance.hasActions = function (status) {
			return (
				status === Ads.statuses.draft ||
				status === Ads.statuses.active ||
				status === Ads.statuses.paused ||
				status === Ads.statuses.done
			);
		};

		instance.customReportDates = function (startDate, endDate) {
			var out = [];
			var now = moment();
			if (endDate && moment(endDate).endOf('day').isBefore(now)) {
				out = [];
			} else {
				out = [
					{
						name: 'currentWeek',
						startDate: moment().isoWeekday(1),
						endDate: moment()
					},
					{
						name: 'currentMonth',
						startDate: moment().startOf('month'),
						endDate: moment()
					},
					{
						name: 'lastMonth',
						startDate: moment().subtract(1, 'month').startOf('month'),
						endDate: moment().subtract(1, 'month').endOf('month')
					},
					{
						name: 'thisYear',
						startDate: moment(startDate).startOf('year'),
						endDate: moment()
					}
				];
			}
			if (startDate) {
				out.push({
					name: 'beginningOfTime',
					startDate: moment(startDate).startOf('day'),
					endDate: endDate ? moment(endDate) : moment()
				});
			}
			return out;
		};

		instance.validateLaunch = function (campaign, credits) {
			adAccount = adAccount && adAccount.active ? adAccount : AppService.getAdAccount();

			var adCampaign;
			var targets;
			var templates;
			var res = {
				budget: false,
				creatives: false,
				dates: false,
				templates: false,
				targets: false,
				valid: true
			};

			if (campaign.ad) {
				// from edit controller
				adCampaign = campaign.ad;
				targets = campaign.campaigns.concat(campaign.accounts);
				templates = campaign.siteTemplates;
			} else {
				adCampaign = campaign;
				targets = campaign.target;
				templates = campaign.siteTemplate;
			}

			if (!adCampaign.budget) {
				res.budget = 'ads.validateLaunch.noBudget';
			} else if (isNaN(adCampaign.budget)) {
				res.budget = 'ads.validateLaunch.budgetIsNaN';
			} else if (adCampaign.budget < adAccount.values.minBudgetInSek) {
				res.budget = 'ads.validateLaunch.BudgetIsTooLow';
			} else if (adCampaign.budget > adAccount.values.maxBudgetInSek) {
				res.budget = 'ads.validateLaunch.BudgetIsTooHigh';
			} else if (adCampaign.budget > credits) {
				res.budget = 'ads.validateLaunch.BudgetIsMoreThanCredits';
			} else {
				res.budget = true;
			}

			if (!adCampaign.creative || !adCampaign.creative.length) {
				res.creatives = 'ads.validateLaunch.noCreatives';
			} else {
				res.creatives = true;
			}

			if (!adCampaign.startDate || !adCampaign.endDate) {
				res.dates = 'ads.validateLaunch.noDates';
			} else if (moment(adCampaign.startDate).isBefore(moment().startOf('day'))) {
				res.dates = 'ads.validateLaunch.startDateHasPassed';
			} else if (moment(adCampaign.endDate).isBefore(moment().startOf('day'))) {
				res.dates = 'ads.validateLaunch.endDateHasPassed';
			} else if (moment(adCampaign.endDate).isBefore(moment(adCampaign.startDate))) {
				res.dates = 'ads.validateLaunch.endDateIsBeforeStartDate';
			} else {
				res.dates = true;
			}

			if (campaign.ad) {
				if (!templates || !templates.length || !_.find(templates, { active: true })) {
					res.templates = 'ads.validateLaunch.noTemplates';
				} else {
					res.templates = true;
				}
			} else if (!campaign.siteTemplate || !campaign.siteTemplate.length) {
				res.templates = 'ads.validateLaunch.noTemplates';
			} else {
				res.templates = true;
			}

			if (!targets || !targets.length) {
				res.targets = 'ads.validateLaunch.noTargets';
			} else {
				res.targets = true;
			}

			if (adCampaign.status === Ads.statuses.paused) {
				// if it is paused, all is assumed to be valid
				res.budget = true;
				res.creatives = true;
				res.dates = true;
				res.templates = true;
				res.targets = true;
				res.valid = true;
			}
			_.forEach(_.keys(res), function (key) {
				if (key === 'valid') {
					return;
				}
				if (res.valid && res[key] === true) {
					return;
				}
				res.valid = false;
			});
			return res;
		};

		instance.mapCampaigns = function (customerId, adCampaignTargets, outputArray, cb) {
			var targets = _.where(adCampaignTargets, { targetType: Ads.targetTypes.campaign });
			targets = targets.concat(_.where(adCampaignTargets, { targetType: Ads.targetTypes.ignoreCampaign }));
			var targetIds = _.map(targets, 'targetId');
			var filter = new RequestBuilder();
			filter.addFilter(Campaign.attr.id, filter.comparisonTypes.Equals, targetIds);

			if (!targetIds.length) {
				return cb();
			}
			Campaign.customer(customerId)
				.find(filter.build())
				.then(function (res) {
					if (!res || !res.data) {
						return cb();
					}
					_.forEach(targets, function (t) {
						var found = _.find(res.data, { id: t.targetId });
						if (found) {
							t = _.extend(t, found);
							outputArray.push(t);
						}
					});
					return cb();
				})
				.catch(function (err) {
					if (err.status === 404) {
						return cb();
					}
					cb(err);
				});
		};

		instance.mapAccounts = function (customerId, adCampaignTargets, outputArray, cb) {
			var targets = _.where(adCampaignTargets, { targetType: Ads.targetTypes.account });
			targets = targets.concat(_.where(adCampaignTargets, { targetType: Ads.targetTypes.ignoreAccount }));
			var targetIds = _.map(targets, 'targetId');
			var filter = new RequestBuilder();
			filter.addFilter(Account.attr.id, filter.comparisonTypes.Equals, targetIds);

			Account.customer(customerId)
				.find(filter.build())
				.then(function (res) {
					if (!res || !res.data) {
						return cb();
					}
					_.forEach(targets, function (t) {
						var found = _.find(res.data, { id: t.targetId });
						if (found) {
							t = _.extend(t, found);
							outputArray.push(t);
						}
					});
					return cb();
				})
				.catch(function (err) {
					if (err.status === 404) {
						return cb();
					}
					cb(err);
				});
		};

		instance.parseCountries = function (countries) {
			var output = [];
			var single;
			var hiddenCountries = ['Anonymous Proxy', 'Satellite Provider'];
			if (!Array.isArray(countries)) {
				countries = [countries];
				single = true;
			}
			countries.forEach(function (c) {
				var translateStr = 'countries.' + c.name.toLowerCase().replace(/ /g, '');
				c.parsedName = $translate.instant(translateStr);

				if (c.parsedName === translateStr) {
					c.parsedName = c.name;
				}

				c.flag = c.country_code.toLowerCase();

				if (hiddenCountries.indexOf(c.parsedName) !== -1) {
					return;
				}

				output.push(c);
			});
			if (single) {
				return output[0];
			}
			return output;
		};

		instance.mapCountries = function (customerId, adCampaignTargets, outputArray, cb) {
			var targets = _.where(adCampaignTargets, { targetType: Ads.targetTypes.locationCountry });
			var targetIds = _.map(targets, 'targetId');

			if (!targetIds.length) {
				return cb();
			}

			Ads.customer(customerId)
				.listLocations({ id: targetIds })
				.then(function (res) {
					if (!res || !res.data) {
						return cb();
					}
					_.forEach(targets, function (t) {
						var found = _.find(res.data, { id: t.targetId });
						if (found) {
							t = _.extend(t, found);
							t.selected = true;
							outputArray.push(t);
						}
					});
					outputArray = instance.parseCountries(outputArray);
					return cb();
				})
				.catch(function (err) {
					if (err.status === 404) {
						return cb();
					}
					cb(err);
				});
		};

		instance.mapRegions = function (customerId, adCampaignTargets, outputArray, cb) {
			var targets = _.where(adCampaignTargets, { targetType: Ads.targetTypes.locationRegion });
			var targetIds = _.map(targets, 'targetId');

			if (!targetIds.length) {
				return cb();
			}

			Ads.customer(customerId)
				.listLocations({ id: targetIds, type: 'region' })
				.then(function (res) {
					if (!res || !res.data) {
						return cb();
					}
					_.forEach(targets, function (t) {
						var found = _.find(res.data, { id: t.targetId });
						if (found) {
							t = _.extend(t, found);
							t.flag = t.country_code.toLowerCase();
							t.selected = true;
							outputArray.push(t);
						}
					});
					return cb();
				})
				.catch(function (err) {
					if (err.status === 404) {
						return cb();
					}
					cb(err);
				});
		};

		instance.mapPages = function (customerId, adCampaignTargets, outputArray, cb) {
			var targets = _.where(adCampaignTargets, { targetType: Ads.targetTypes.webPage });
			targets = targets.concat(_.where(adCampaignTargets, { targetType: Ads.targetTypes.webPageWildCard }));
			targets = targets.concat(_.where(adCampaignTargets, { targetType: Ads.targetTypes.ignoreWebPage }));
			targets = targets.concat(_.where(adCampaignTargets, { targetType: Ads.targetTypes.ignoreWebPageWildCard }));
			var targetIds = _.map(targets, 'targetId');
			var filter = new RequestBuilder();
			filter.addFilter(LeaderPage.attr.id, filter.comparisonTypes.Equals, targetIds);
			LeaderPage.customer(customerId)
				.find(filter.build())
				.then(function (res) {
					if (!res || !res.data) {
						return cb();
					}
					_.forEach(targets, function (t) {
						var found = _.find(res.data, { id: t.targetId });
						if (found) {
							t = _.extend(t, found);
							t.type = t.targetType;
							outputArray.push(t);
						}
					});
					return cb();
				})
				.catch(function (err) {
					if (err.status === 404) {
						return cb();
					}
					cb(err);
				});
		};

		instance.invalidUpdates = function (adCampaign, adCampaignChanges, credits, values, collect) {
			var endDate = moment(adCampaign.endDate);
			var startDate = moment(adCampaign.startDate);
			var newEndDate = adCampaignChanges.endDate ? moment(adCampaignChanges.endDate) : moment(adCampaign.endDate);
			var newStartDate = adCampaignChanges.startDate
				? moment(adCampaignChanges.startDate)
				: moment(adCampaign.startDate);
			var errors = [];
			var toCharge;
			var siteTemplates = adCampaignChanges.siteTemplate
				? adCampaignChanges.siteTemplate
				: adCampaign.siteTemplate;
			var creatives = adCampaignChanges.creative ? adCampaignChanges.creative : adCampaign.creative;
			var startDateChanges = newStartDate.startOf('day').diff(startDate.startOf('day'));
			var endDateChanges = newEndDate.startOf('day').diff(endDate.startOf('day'));

			siteTemplates = _.where(siteTemplates, { active: true });
			creatives = _.where(creatives, { active: true });

			// VALIDATE STATUS
			if (adCampaign.status === statuses.error) {
				errors.push('CantUpdateAdCampaignWhenStatusIsError');
				if (!collect) {
					return errors[0];
				}
			}
			if (adCampaign.status === statuses.launch) {
				errors.push('CantUpdateAdCampaignWhenStatusIsActivating');
			}

			// VALIDATE STATUS CHANGES
			// can't change finished status from controller
			if (adCampaign.status === statuses.finished && adCampaignChanges.status !== statuses.finished) {
				errors.push('CantChangeAdCampaignStatusWhenFinished');
				if (!collect) {
					return errors[0];
				}
			}
			// can only change between done and archived when done and archived
			if (adCampaign.status >= statuses.done && adCampaignChanges.status < statuses.done) {
				errors.push('InvalidStatusChange');
				if (!collect) {
					return errors[0];
				}
			}
			// can nonly change between active, paused and finished when active and paused
			if (
				(adCampaign.status === statuses.active || adCampaign.status === statuses.paused) &&
				(adCampaignChanges.status < statuses.active || adCampaignChanges.status > statuses.finished)
			) {
				errors.push('InvalidStatusChange');
				if (!collect) {
					return errors[0];
				}
			}

			// VALIDATE DATE CHANGES WHEN ACTIVE OR LAUNCHING
			if (status.isRunning(adCampaign)) {
				if (startDateChanges && newStartDate.isBefore(moment().startOf('day'))) {
					errors.push('CantSetSAdCampaignStartDateToAPassedDate');
					if (!collect) {
						return errors[0];
					}
				}
				if (startDateChanges && startDate.isBefore(moment())) {
					errors.push('CantChangeActiveStartDateOnceItHasPassed');
					if (!collect) {
						return errors[0];
					}
				}
				if (endDateChanges && newEndDate.isBefore(startDate)) {
					errors.push('EndDateIsBeforeStartDate');
					if (!collect) {
						return errors[0];
					}
				}
				if (endDateChanges && newEndDate.isBefore(moment().endOf('day'))) {
					errors.push('CantSetEndDateToEarlierOnceAdCampaignIsActive');
					if (!collect) {
						return errors[0];
					}
				}
			}

			// VALIDATE BUDGET CHANGES
			if (
				adCampaign.status !== statuses.draft &&
				adCampaignChanges.budget < adCampaign.budget &&
				startDate.isBefore(moment())
			) {
				errors.push('CantDecreaseActiveAdCampaignBudget');
				if (!collect) {
					return errors[0];
				}
			}
			if (adCampaign.status === statuses.draft && adCampaignChanges.status > statuses.draft && values) {
				toCharge = adCampaignChanges.budget || adCampaign.budget || 0;
				if (toCharge < values.minBudgetInSek) {
					errors.push('TooLowBudget');
					if (!collect) {
						return errors[0];
					}
				}
			}
			if (status.isRunning(adCampaign)) {
				toCharge = adCampaignChanges.budget - adCampaign.budget;
			}

			if (toCharge && toCharge > credits) {
				errors.push('NotEnoughAdCredits');
				if (!collect) {
					return errors[0];
				}
			} else if (values && toCharge && adCampaignChanges.budget > values.maxBudgetInSek) {
				errors.push('TooHighBudget');
				if (!collect) {
					return errors[0];
				}
			}

			// VALIDATE CREATIVES
			if (status.isRunning(adCampaign) && !creatives.length) {
				errors.push('ActiveAdCampaignIsMissingCreatives');
				if (!collect) {
					return errors[0];
				}
			}
			if (adCampaignChanges.status === statuses.launch && !creatives.length) {
				errors.push('CantLaunchAdCampaignWithoutCreative');
				if (!collect) {
					return errors[0];
				}
			}

			// VALIDATE SITE TEMPLATES
			if (adCampaignChanges.status === statuses.launch && !siteTemplates.length) {
				errors.push('CantLaunchAdCampaignWithoutSiteTemplates');
				if (!collect) {
					return errors[0];
				}
			}

			if (status.isRunning(adCampaign) && !siteTemplates.length) {
				errors.push('ActiveAdCampaignIsMissingSiteTemplate');
				if (!collect) {
					return errors[0];
				}
			}

			if (!collect) {
				return errors[0];
			}

			return errors;
		};

		instance.countries = [
			{ id: 533, country_code: 'AW', iso3: 'abw', iso2: 'aw', name: 'aruba' },
			{ id: 4, country_code: 'AF', iso3: 'afg', iso2: 'af', name: 'afghanistan' },
			{ id: 24, country_code: 'AO', iso3: 'ago', iso2: 'ao', name: 'angola' },
			{ id: 660, country_code: 'AI', iso3: 'aia', iso2: 'ai', name: 'anguilla' },
			{ id: 248, country_code: 'AX', iso3: 'ala', iso2: 'ax', name: 'aland islands' },
			{ id: 8, country_code: 'AL', iso3: 'alb', iso2: 'al', name: 'albania' },
			{ id: 20, country_code: 'AD', iso3: 'and', iso2: 'ad', name: 'andorra' },
			{ id: 530, country_code: 'AN', iso3: 'ant', iso2: 'an', name: 'netherlands antilles' },
			{ id: 784, country_code: 'AE', iso3: 'are', iso2: 'ae', name: 'united arab emirates' },
			{ id: 32, country_code: 'AR', iso3: 'arg', iso2: 'ar', name: 'argentina' },
			{ id: 51, country_code: 'AM', iso3: 'arm', iso2: 'am', name: 'armenia' },
			{ id: 994, country_code: 'AP', iso3: 'asi', iso2: 'ap', name: 'asia (unknown country)' },
			{ id: 16, country_code: 'AS', iso3: 'asm', iso2: 'as', name: 'american samoa' },
			{ id: 10, country_code: 'AQ', iso3: 'ata', iso2: 'aq', name: 'antarctica' },
			{ id: 260, country_code: 'TF', iso3: 'atf', iso2: 'tf', name: 'french southern territories' },
			{ id: 28, country_code: 'AG', iso3: 'atg', iso2: 'ag', name: 'antigua and barbuda' },
			{ id: 36, country_code: 'AU', iso3: 'aus', iso2: 'au', name: 'australia' },
			{ id: 40, country_code: 'AT', iso3: 'aut', iso2: 'at', name: 'austria' },
			{ id: 31, country_code: 'AZ', iso3: 'aze', iso2: 'az', name: 'azerbaijan' },
			{ id: 108, country_code: 'BI', iso3: 'bdi', iso2: 'bi', name: 'burundi' },
			{ id: 56, country_code: 'BE', iso3: 'bel', iso2: 'be', name: 'belgium' },
			{ id: 204, country_code: 'BJ', iso3: 'ben', iso2: 'bj', name: 'benin' },
			{ id: 535, country_code: 'BQ', iso3: 'bes', iso2: 'bq', name: 'bonaire/sint eustatius/saba' },
			{ id: 854, country_code: 'BF', iso3: 'bfa', iso2: 'bf', name: 'burkina faso' },
			{ id: 50, country_code: 'BD', iso3: 'bgd', iso2: 'bd', name: 'bangladesh' },
			{ id: 100, country_code: 'BG', iso3: 'bgr', iso2: 'bg', name: 'bulgaria' },
			{ id: 48, country_code: 'BH', iso3: 'bhr', iso2: 'bh', name: 'bahrain' },
			{ id: 44, country_code: 'BS', iso3: 'bhs', iso2: 'bs', name: 'bahamas' },
			{ id: 70, country_code: 'BA', iso3: 'bih', iso2: 'ba', name: 'bosnia and herzegowina' },
			{ id: 652, country_code: 'BL', iso3: 'blm', iso2: 'bl', name: 'saint barthelemy' },
			{ id: 112, country_code: 'BY', iso3: 'blr', iso2: 'by', name: 'belarus' },
			{ id: 84, country_code: 'BZ', iso3: 'blz', iso2: 'bz', name: 'belize' },
			{ id: 60, country_code: 'BM', iso3: 'bmu', iso2: 'bm', name: 'bermuda' },
			{ id: 68, country_code: 'BO', iso3: 'bol', iso2: 'bo', name: 'bolivia' },
			{ id: 76, country_code: 'BR', iso3: 'bra', iso2: 'br', name: 'brazil' },
			{ id: 52, country_code: 'BB', iso3: 'brb', iso2: 'bb', name: 'barbados' },
			{ id: 96, country_code: 'BN', iso3: 'brn', iso2: 'bn', name: 'brunei darussalam' },
			{ id: 64, country_code: 'BT', iso3: 'btn', iso2: 'bt', name: 'bhutan' },
			{ id: 74, country_code: 'BV', iso3: 'bvt', iso2: 'bv', name: 'bouvet island' },
			{ id: 72, country_code: 'BW', iso3: 'bwa', iso2: 'bw', name: 'botswana' },
			{ id: 140, country_code: 'CF', iso3: 'caf', iso2: 'cf', name: 'central african republic' },
			{ id: 124, country_code: 'CA', iso3: 'can', iso2: 'ca', name: 'canada' },
			{ id: 166, country_code: 'CC', iso3: 'cck', iso2: 'cc', name: 'cocos (keeling) islands' },
			{ id: 756, country_code: 'CH', iso3: 'che', iso2: 'ch', name: 'switzerland' },
			{ id: 152, country_code: 'CL', iso3: 'chl', iso2: 'cl', name: 'chile' },
			{ id: 156, country_code: 'CN', iso3: 'chn', iso2: 'cn', name: 'china' },
			{ id: 384, country_code: 'CI', iso3: 'civ', iso2: 'ci', name: 'cote d ivoire' },
			{ id: 120, country_code: 'CM', iso3: 'cmr', iso2: 'cm', name: 'cameroon' },
			{ id: 180, country_code: 'CD', iso3: 'cod', iso2: 'cd', name: 'democratic republic of the congo' },
			{ id: 178, country_code: 'CG', iso3: 'cog', iso2: 'cg', name: 'congo' },
			{ id: 184, country_code: 'CK', iso3: 'cok', iso2: 'ck', name: 'cook islands' },
			{ id: 170, country_code: 'CO', iso3: 'col', iso2: 'co', name: 'colombia' },
			{ id: 174, country_code: 'KM', iso3: 'com', iso2: 'km', name: 'comoros' },
			{ id: 132, country_code: 'CV', iso3: 'cpv', iso2: 'cv', name: 'cape verde' },
			{ id: 188, country_code: 'CR', iso3: 'cri', iso2: 'cr', name: 'costa rica' },
			{ id: 192, country_code: 'CU', iso3: 'cub', iso2: 'cu', name: 'cuba' },
			{ id: 531, country_code: 'CW', iso3: 'cuw', iso2: 'cw', name: 'curacao' },
			{ id: 162, country_code: 'CX', iso3: 'cxr', iso2: 'cx', name: 'christmas island' },
			{ id: 136, country_code: 'KY', iso3: 'cym', iso2: 'ky', name: 'cayman islands' },
			{ id: 196, country_code: 'CY', iso3: 'cyp', iso2: 'cy', name: 'cyprus' },
			{ id: 203, country_code: 'CZ', iso3: 'cze', iso2: 'cz', name: 'czech republic' },
			{ id: 276, country_code: 'DE', iso3: 'deu', iso2: 'de', name: 'germany' },
			{ id: 262, country_code: 'DJ', iso3: 'dji', iso2: 'dj', name: 'djibouti' },
			{ id: 212, country_code: 'DM', iso3: 'dma', iso2: 'dm', name: 'dominica' },
			{ id: 208, country_code: 'DK', iso3: 'dnk', iso2: 'dk', name: 'denmark' },
			{ id: 214, country_code: 'DO', iso3: 'dom', iso2: 'do', name: 'dominican republic' },
			{ id: 12, country_code: 'DZ', iso3: 'dza', iso2: 'dz', name: 'algeria' },
			{ id: 218, country_code: 'EC', iso3: 'ecu', iso2: 'ec', name: 'ecuador' },
			{ id: 818, country_code: 'EG', iso3: 'egy', iso2: 'eg', name: 'egypt' },
			{ id: 232, country_code: 'ER', iso3: 'eri', iso2: 'er', name: 'eritrea' },
			{ id: 732, country_code: 'EH', iso3: 'esh', iso2: 'eh', name: 'western sahara' },
			{ id: 724, country_code: 'ES', iso3: 'esp', iso2: 'es', name: 'spain' },
			{ id: 233, country_code: 'EE', iso3: 'est', iso2: 'ee', name: 'estonia' },
			{ id: 231, country_code: 'ET', iso3: 'eth', iso2: 'et', name: 'ethiopia' },
			{ id: 995, country_code: 'EU', iso3: 'eur', iso2: 'eu', name: 'europe (unknown country)' },
			{ id: 246, country_code: 'FI', iso3: 'fin', iso2: 'fi', name: 'finland' },
			{ id: 242, country_code: 'FJ', iso3: 'fji', iso2: 'fj', name: 'fiji' },
			{ id: 238, country_code: 'FK', iso3: 'flk', iso2: 'fk', name: 'falkland islands (malvinas)' },
			{ id: 250, country_code: 'FR', iso3: 'fra', iso2: 'fr', name: 'france' },
			{ id: 234, country_code: 'FO', iso3: 'fro', iso2: 'fo', name: 'faroe islands' },
			{ id: 583, country_code: 'FM', iso3: 'fsm', iso2: 'fm', name: 'federated states of micronesia' },
			{ id: 266, country_code: 'GA', iso3: 'gab', iso2: 'ga', name: 'gabon' },
			{ id: 826, country_code: 'UK', iso3: 'gbr', iso2: 'uk', name: 'united kingdom' },
			{ id: 268, country_code: 'GE', iso3: 'geo', iso2: 'ge', name: 'georgia' },
			{ id: 831, country_code: 'GG', iso3: 'ggy', iso2: 'gg', name: 'guernsey' },
			{ id: 288, country_code: 'GH', iso3: 'gha', iso2: 'gh', name: 'ghana' },
			{ id: 292, country_code: 'GI', iso3: 'gib', iso2: 'gi', name: 'gibraltar' },
			{ id: 324, country_code: 'GN', iso3: 'gin', iso2: 'gn', name: 'guinea' },
			{ id: 312, country_code: 'GP', iso3: 'glp', iso2: 'gp', name: 'guadeloupe' },
			{ id: 270, country_code: 'GM', iso3: 'gmb', iso2: 'gm', name: 'gambia' },
			{ id: 624, country_code: 'GW', iso3: 'gnb', iso2: 'gw', name: 'guinea-bissau' },
			{ id: 226, country_code: 'GQ', iso3: 'gnq', iso2: 'gq', name: 'equatorial guinea' },
			{ id: 300, country_code: 'GR', iso3: 'grc', iso2: 'gr', name: 'greece' },
			{ id: 308, country_code: 'GD', iso3: 'grd', iso2: 'gd', name: 'grenada' },
			{ id: 304, country_code: 'GL', iso3: 'grl', iso2: 'gl', name: 'greenland' },
			{ id: 320, country_code: 'GT', iso3: 'gtm', iso2: 'gt', name: 'guatemala' },
			{ id: 254, country_code: 'GF', iso3: 'guf', iso2: 'gf', name: 'french guiana' },
			{ id: 316, country_code: 'GU', iso3: 'gum', iso2: 'gu', name: 'guam' },
			{ id: 328, country_code: 'GY', iso3: 'guy', iso2: 'gy', name: 'guyana' },
			{ id: 344, country_code: 'HK', iso3: 'hkg', iso2: 'hk', name: 'hong kong' },
			{ id: 334, country_code: 'HM', iso3: 'hmd', iso2: 'hm', name: 'heard and mc donald islands' },
			{ id: 340, country_code: 'HN', iso3: 'hnd', iso2: 'hn', name: 'honduras' },
			{ id: 191, country_code: 'HR', iso3: 'hrv', iso2: 'hr', name: 'croatia' },
			{ id: 332, country_code: 'HT', iso3: 'hti', iso2: 'ht', name: 'haiti' },
			{ id: 348, country_code: 'HU', iso3: 'hun', iso2: 'hu', name: 'hungary' },
			{ id: 360, country_code: 'ID', iso3: 'idn', iso2: 'id', name: 'indonesia' },
			{ id: 833, country_code: 'IM', iso3: 'imn', iso2: 'im', name: 'isle of man' },
			{ id: 356, country_code: 'IN', iso3: 'ind', iso2: 'in', name: 'india' },
			{ id: 86, country_code: 'IO', iso3: 'iot', iso2: 'io', name: 'british indian ocean territory' },
			{ id: 372, country_code: 'IE', iso3: 'irl', iso2: 'ie', name: 'ireland' },
			{ id: 364, country_code: 'IR', iso3: 'irn', iso2: 'ir', name: 'iran (islamic republic of)' },
			{ id: 368, country_code: 'IQ', iso3: 'irq', iso2: 'iq', name: 'iraq' },
			{ id: 352, country_code: 'IS', iso3: 'isl', iso2: 'is', name: 'iceland' },
			{ id: 376, country_code: 'IL', iso3: 'isr', iso2: 'il', name: 'israel' },
			{ id: 380, country_code: 'IT', iso3: 'ita', iso2: 'it', name: 'italy' },
			{ id: 388, country_code: 'JM', iso3: 'jam', iso2: 'jm', name: 'jamaica' },
			{ id: 832, country_code: 'JE', iso3: 'jey', iso2: 'je', name: 'jersey' },
			{ id: 400, country_code: 'JO', iso3: 'jor', iso2: 'jo', name: 'jordan' },
			{ id: 392, country_code: 'JP', iso3: 'jpn', iso2: 'jp', name: 'japan' },
			{ id: 398, country_code: 'KZ', iso3: 'kaz', iso2: 'kz', name: 'kazakhstan' },
			{ id: 404, country_code: 'KE', iso3: 'ken', iso2: 'ke', name: 'kenya' },
			{ id: 417, country_code: 'KG', iso3: 'kgz', iso2: 'kg', name: 'kyrgyzstan' },
			{ id: 116, country_code: 'KH', iso3: 'khm', iso2: 'kh', name: 'cambodia' },
			{ id: 296, country_code: 'KI', iso3: 'kir', iso2: 'ki', name: 'kiribati' },
			{ id: 659, country_code: 'KN', iso3: 'kna', iso2: 'kn', name: 'saint kitts and nevis' },
			{ id: 410, country_code: 'KR', iso3: 'kor', iso2: 'kr', name: 'south korea' },
			{ id: 414, country_code: 'KW', iso3: 'kwt', iso2: 'kw', name: 'kuwait' },
			{ id: 418, country_code: 'LA', iso3: 'lao', iso2: 'la', name: 'lao peoples democratic republic' },
			{ id: 422, country_code: 'LB', iso3: 'lbn', iso2: 'lb', name: 'lebanon' },
			{ id: 430, country_code: 'LR', iso3: 'lbr', iso2: 'lr', name: 'liberia' },
			{ id: 434, country_code: 'LY', iso3: 'lby', iso2: 'ly', name: 'libyan arab jamahiriya' },
			{ id: 662, country_code: 'LC', iso3: 'lca', iso2: 'lc', name: 'saint lucia' },
			{ id: 438, country_code: 'LI', iso3: 'lie', iso2: 'li', name: 'liechtenstein' },
			{ id: 144, country_code: 'LK', iso3: 'lka', iso2: 'lk', name: 'sri lanka' },
			{ id: 426, country_code: 'LS', iso3: 'lso', iso2: 'ls', name: 'lesotho' },
			{ id: 440, country_code: 'LT', iso3: 'ltu', iso2: 'lt', name: 'lithuania' },
			{ id: 442, country_code: 'LU', iso3: 'lux', iso2: 'lu', name: 'luxembourg' },
			{ id: 428, country_code: 'LV', iso3: 'lva', iso2: 'lv', name: 'latvia' },
			{ id: 446, country_code: 'MO', iso3: 'mac', iso2: 'mo', name: 'macau' },
			{ id: 663, country_code: 'MF', iso3: 'maf', iso2: 'mf', name: 'saint martin' },
			{ id: 504, country_code: 'MA', iso3: 'mar', iso2: 'ma', name: 'morocco' },
			{ id: 492, country_code: 'MC', iso3: 'mco', iso2: 'mc', name: 'monaco' },
			{ id: 498, country_code: 'MD', iso3: 'mda', iso2: 'md', name: 'moldova' },
			{ id: 450, country_code: 'MG', iso3: 'mdg', iso2: 'mg', name: 'madagascar' },
			{ id: 462, country_code: 'MV', iso3: 'mdv', iso2: 'mv', name: 'maldives' },
			{ id: 484, country_code: 'MX', iso3: 'mex', iso2: 'mx', name: 'mexico' },
			{ id: 584, country_code: 'MH', iso3: 'mhl', iso2: 'mh', name: 'marshall islands' },
			{ id: 807, country_code: 'MK', iso3: 'mkd', iso2: 'mk', name: 'macedonia' },
			{ id: 466, country_code: 'ML', iso3: 'mli', iso2: 'ml', name: 'mali' },
			{ id: 470, country_code: 'MT', iso3: 'mlt', iso2: 'mt', name: 'malta' },
			{ id: 104, country_code: 'MM', iso3: 'mmr', iso2: 'mm', name: 'myanmar' },
			{ id: 499, country_code: 'ME', iso3: 'mne', iso2: 'me', name: 'montenegro' },
			{ id: 496, country_code: 'MN', iso3: 'mng', iso2: 'mn', name: 'mongolia' },
			{ id: 580, country_code: 'MP', iso3: 'mnp', iso2: 'mp', name: 'northern mariana islands' },
			{ id: 508, country_code: 'MZ', iso3: 'moz', iso2: 'mz', name: 'mozambique' },
			{ id: 478, country_code: 'MR', iso3: 'mrt', iso2: 'mr', name: 'mauritania' },
			{ id: 500, country_code: 'MS', iso3: 'msr', iso2: 'ms', name: 'montserrat' },
			{ id: 474, country_code: 'MQ', iso3: 'mtq', iso2: 'mq', name: 'martinique' },
			{ id: 480, country_code: 'MU', iso3: 'mus', iso2: 'mu', name: 'mauritius' },
			{ id: 454, country_code: 'MW', iso3: 'mwi', iso2: 'mw', name: 'malawi' },
			{ id: 458, country_code: 'MY', iso3: 'mys', iso2: 'my', name: 'malaysia' },
			{ id: 175, country_code: 'YT', iso3: 'myt', iso2: 'yt', name: 'mayotte' },
			{ id: 516, country_code: 'NA', iso3: 'nam', iso2: 'na', name: 'namibia' },
			{ id: 540, country_code: 'NC', iso3: 'ncl', iso2: 'nc', name: 'new caledonia' },
			{ id: 562, country_code: 'NE', iso3: 'ner', iso2: 'ne', name: 'niger' },
			{ id: 574, country_code: 'NF', iso3: 'nfk', iso2: 'nf', name: 'norfolk island' },
			{ id: 566, country_code: 'NG', iso3: 'nga', iso2: 'ng', name: 'nigeria' },
			{ id: 558, country_code: 'NI', iso3: 'nic', iso2: 'ni', name: 'nicaragua' },
			{ id: 570, country_code: 'NU', iso3: 'niu', iso2: 'nu', name: 'niue' },
			{ id: 528, country_code: 'NL', iso3: 'nld', iso2: 'nl', name: 'netherlands' },
			{ id: 578, country_code: 'NO', iso3: 'nor', iso2: 'no', name: 'norway' },
			{ id: 524, country_code: 'NP', iso3: 'npl', iso2: 'np', name: 'nepal' },
			{ id: 520, country_code: 'NR', iso3: 'nru', iso2: 'nr', name: 'nauru' },
			{ id: 554, country_code: 'NZ', iso3: 'nzl', iso2: 'nz', name: 'new zealand' },
			{ id: 512, country_code: 'OM', iso3: 'omn', iso2: 'om', name: 'oman' },
			{ id: 586, country_code: 'PK', iso3: 'pak', iso2: 'pk', name: 'pakistan' },
			{ id: 591, country_code: 'PA', iso3: 'pan', iso2: 'pa', name: 'panama' },
			{ id: 612, country_code: 'PN', iso3: 'pcn', iso2: 'pn', name: 'pitcairn' },
			{ id: 604, country_code: 'PE', iso3: 'per', iso2: 'pe', name: 'peru' },
			{ id: 608, country_code: 'PH', iso3: 'phl', iso2: 'ph', name: 'philippines' },
			{ id: 585, country_code: 'PW', iso3: 'plw', iso2: 'pw', name: 'palau' },
			{ id: 598, country_code: 'PG', iso3: 'png', iso2: 'pg', name: 'papua new guinea' },
			{ id: 616, country_code: 'PL', iso3: 'pol', iso2: 'pl', name: 'poland' },
			{ id: 630, country_code: 'PR', iso3: 'pri', iso2: 'pr', name: 'puerto rico' },
			{ id: 408, country_code: 'KP', iso3: 'prk', iso2: 'kp', name: 'north korea' },
			{ id: 620, country_code: 'PT', iso3: 'prt', iso2: 'pt', name: 'portugal' },
			{ id: 600, country_code: 'PY', iso3: 'pry', iso2: 'py', name: 'paraguay' },
			{ id: 275, country_code: 'PS', iso3: 'pse', iso2: 'ps', name: 'palestinian territories' },
			{ id: 258, country_code: 'PF', iso3: 'pyf', iso2: 'pf', name: 'french polynesia' },
			{ id: 634, country_code: 'QA', iso3: 'qat', iso2: 'qa', name: 'qatar' },
			{ id: 638, country_code: 'RE', iso3: 'reu', iso2: 're', name: 'reunion' },
			{ id: 642, country_code: 'RO', iso3: 'rou', iso2: 'ro', name: 'romania' },
			{ id: 643, country_code: 'RU', iso3: 'rus', iso2: 'ru', name: 'russian federation' },
			{ id: 646, country_code: 'RW', iso3: 'rwa', iso2: 'rw', name: 'rwanda' },
			{ id: 682, country_code: 'SA', iso3: 'sau', iso2: 'sa', name: 'saudi arabia' },
			{ id: 736, country_code: 'SD', iso3: 'sdn', iso2: 'sd', name: 'sudan' },
			{ id: 686, country_code: 'SN', iso3: 'sen', iso2: 'sn', name: 'senegal' },
			{ id: 702, country_code: 'SG', iso3: 'sgp', iso2: 'sg', name: 'singapore' },
			{ id: 239, country_code: 'GS', iso3: 'sgs', iso2: 'gs', name: 'south georgia / south sandwich isl' },
			{ id: 654, country_code: 'SH', iso3: 'shn', iso2: 'sh', name: 'st. helena' },
			{ id: 744, country_code: 'SJ', iso3: 'sjm', iso2: 'sj', name: 'svalbard and jan mayen islands' },
			{ id: 90, country_code: 'SB', iso3: 'slb', iso2: 'sb', name: 'solomon islands' },
			{ id: 694, country_code: 'SL', iso3: 'sle', iso2: 'sl', name: 'sierra leone' },
			{ id: 222, country_code: 'SV', iso3: 'slv', iso2: 'sv', name: 'el salvador' },
			{ id: 674, country_code: 'SM', iso3: 'smr', iso2: 'sm', name: 'san marino' },
			{ id: 706, country_code: 'SO', iso3: 'som', iso2: 'so', name: 'somalia' },
			{ id: 666, country_code: 'PM', iso3: 'spm', iso2: 'pm', name: 'st. pierre and miquelon' },
			{ id: 688, country_code: 'RS', iso3: 'srb', iso2: 'rs', name: 'serbia' },
			{ id: 728, country_code: 'SS', iso3: 'ssd', iso2: 'ss', name: 'south sudan' },
			{ id: 678, country_code: 'ST', iso3: 'stp', iso2: 'st', name: 'sao tome and principe' },
			{ id: 740, country_code: 'SR', iso3: 'sur', iso2: 'sr', name: 'suriname' },
			{ id: 703, country_code: 'SK', iso3: 'svk', iso2: 'sk', name: 'slovakia (slovak republic)' },
			{ id: 705, country_code: 'SI', iso3: 'svn', iso2: 'si', name: 'slovenia' },
			{ id: 752, country_code: 'SE', iso3: 'swe', iso2: 'se', name: 'sweden' },
			{ id: 748, country_code: 'SZ', iso3: 'swz', iso2: 'sz', name: 'swaziland' },
			{ id: 534, country_code: 'SX', iso3: 'sxm', iso2: 'sx', name: 'sint maarten' },
			{ id: 690, country_code: 'SC', iso3: 'syc', iso2: 'sc', name: 'seychelles' },
			{ id: 760, country_code: 'SY', iso3: 'syr', iso2: 'sy', name: 'syrian arab republic' },
			{ id: 796, country_code: 'TC', iso3: 'tca', iso2: 'tc', name: 'turks and caicos islands' },
			{ id: 148, country_code: 'TD', iso3: 'tcd', iso2: 'td', name: 'chad' },
			{ id: 768, country_code: 'TG', iso3: 'tgo', iso2: 'tg', name: 'togo' },
			{ id: 764, country_code: 'TH', iso3: 'tha', iso2: 'th', name: 'thailand' },
			{ id: 762, country_code: 'TJ', iso3: 'tjk', iso2: 'tj', name: 'tajikistan' },
			{ id: 772, country_code: 'TK', iso3: 'tkl', iso2: 'tk', name: 'tokelau' },
			{ id: 795, country_code: 'TM', iso3: 'tkm', iso2: 'tm', name: 'turkmenistan' },
			{ id: 626, country_code: 'TL', iso3: 'tls', iso2: 'tl', name: 'timor-leste' },
			{ id: 776, country_code: 'TO', iso3: 'ton', iso2: 'to', name: 'tonga' },
			{ id: 780, country_code: 'TT', iso3: 'tto', iso2: 'tt', name: 'trinidad and tobago' },
			{ id: 788, country_code: 'TN', iso3: 'tun', iso2: 'tn', name: 'tunisia' },
			{ id: 792, country_code: 'TR', iso3: 'tur', iso2: 'tr', name: 'turkey' },
			{ id: 798, country_code: 'TV', iso3: 'tuv', iso2: 'tv', name: 'tuvalu' },
			{ id: 158, country_code: 'TW', iso3: 'twn', iso2: 'tw', name: 'taiwan province of china' },
			{ id: 834, country_code: 'TZ', iso3: 'tza', iso2: 'tz', name: 'tanzania' },
			{ id: 800, country_code: 'UG', iso3: 'uga', iso2: 'ug', name: 'uganda' },
			{ id: 804, country_code: 'UA', iso3: 'ukr', iso2: 'ua', name: 'ukraine' },
			{ id: 581, country_code: 'UM', iso3: 'umi', iso2: 'um', name: 'us minor outlying islands' },
			{ id: 858, country_code: 'UY', iso3: 'ury', iso2: 'uy', name: 'uruguay' },
			{ id: 840, country_code: 'US', iso3: 'usa', iso2: 'us', name: 'united states' },
			{ id: 860, country_code: 'UZ', iso3: 'uzb', iso2: 'uz', name: 'uzbekistan' },
			{ id: 336, country_code: 'VA', iso3: 'vat', iso2: 'va', name: 'holy see (vatican city state)' },
			{ id: 670, country_code: 'VC', iso3: 'vct', iso2: 'vc', name: 'saint vincent and the grenadines' },
			{ id: 862, country_code: 'VE', iso3: 'ven', iso2: 've', name: 'venezuela' },
			{ id: 92, country_code: 'VG', iso3: 'vgb', iso2: 'vg', name: 'british virgin islands' },
			{ id: 850, country_code: 'VI', iso3: 'vir', iso2: 'vi', name: 'us virgin islands' },
			{ id: 704, country_code: 'VN', iso3: 'vnm', iso2: 'vn', name: 'viet nam' },
			{ id: 548, country_code: 'VU', iso3: 'vut', iso2: 'vu', name: 'vanuatu' },
			{ id: 876, country_code: 'WF', iso3: 'wlf', iso2: 'wf', name: 'wallis and futuna islands' },
			{ id: 882, country_code: 'WS', iso3: 'wsm', iso2: 'ws', name: 'samoa' },
			{ id: 887, country_code: 'YE', iso3: 'yem', iso2: 'ye', name: 'yemen' },
			{ id: 710, country_code: 'ZA', iso3: 'zaf', iso2: 'za', name: 'south africa' },
			{ id: 894, country_code: 'ZM', iso3: 'zmb', iso2: 'zm', name: 'zambia' },
			{ id: 716, country_code: 'ZW', iso3: 'zwe', iso2: 'zw', name: 'zimbabwe' }
		];

		return instance;
	}
]);

'use strict';

angular.module('domain.onboarding', []).config([
	'$stateProvider',
	function ($stateProvider) {
		$stateProvider.state('onboarding', {
			template: '<div></div>',
			url: '/:customerId/onboarding',
			params: {
				openFullScreen: null,
				skipInit: null,
				initialImportApp: null,
				initialSubstepId: null,
				oauthCode: null
			},
			controller: [
				'$scope',
				'$element',
				'$stateParams',
				function ($scope, $element, $stateParams) {
					window.SetupComponent($scope, window.Onboarding, $element[0], $stateParams, { redux: true });
				}
			]
		});
	}
]);

'use strict';

angular.module('upDirectives').directive('validNumber', function () {
	return {
		require: '?ngModel',
		link: function (scope, element, attrs, ngModelCtrl) {
			if (!ngModelCtrl) {
				return;
			}

			var numOfDecimals = 2;
			if (attrs.decimals && !isNaN(parseInt(attrs.decimals))) {
				numOfDecimals = parseInt(attrs.decimals);
			}

			ngModelCtrl.$parsers.push(function (val) {
				if (angular.isUndefined(val) || val === null) {
					val = '';
				}
				val = val.toString();
				var negative = val.indexOf('-') === 0;
				var clean = val.replace(/[^0-9\.]/g, '');
				var decimalCheck = clean.split('.');

				if (!angular.isUndefined(decimalCheck[1])) {
					decimalCheck[1] = decimalCheck[1].slice(0, numOfDecimals);
					clean = decimalCheck[0] + '.' + decimalCheck[1];
				}

				if (negative) {
					clean = '-' + clean;
				}

				if (val !== clean) {
					ngModelCtrl.$setViewValue(clean);
					ngModelCtrl.$render();
				}

				return clean;
			});

			element.bind('keypress', function (event) {
				if (event.keyCode === 32) {
					event.preventDefault();
				}
			});
		}
	};
});

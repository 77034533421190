import React from 'react';
import PropTypes from 'prop-types';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import Tooltip from '@upsales/components/Tooltip';
import { DropDownMenu, Button, Card, Toggle, Label, Icon, Text } from '@upsales/components';
import './FieldSync.scss';

class FieldSync extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			integrations: props.apps.map(app => {
				app.isSyncOn = false;
				return app;
			})
		};
	}

	toggleFieldSync(checked, integration) {
		this.setState(
			{
				integrations: this.state.integrations.map(i => {
					if (i.id === integration.id) i.isSyncOn = checked;
					else i.isSyncOn = false;
					return i;
				})
			},
			() => {
				if (checked) this.props.toggleSync(integration);
				else this.props.toggleSync(null);
			}
		);
	}

	render() {
		const classes = new BemClass('FieldSyncLogs');
		return (
			<DropDownMenu
				className={classes.elem('menu').b()}
				align={'right'}
				verticalAlign={'bottom'}
				renderTrigger={(is, set) => (
					<Tooltip title={T('integration.showSyncedFields')} position="left" distance={20}>
						<Button
							className={classes.elem('tab-btn').b()}
							color="light-grey"
							hoverColor="white"
							shadow="none"
							onClick={set}
						>
							<Icon name="ellipsis-h" color="grey-10" />
						</Button>
					</Tooltip>
				)}
			>
				<Card className={classes.elem('dropdown-card').b()}>
					<h3 className={classes.elem('card-header').b()}>{T('integration.fieldSync')}</h3>
					{this.state.integrations.length > 0 ? (
						this.state.integrations.map(integration => (
							<div key={integration.id} className={classes.elem('toggle-wrap').b()}>
								<Toggle
									className={classes.elem('toggle').b()}
									color="bright-blue"
									checked={integration.isSyncOn}
									onChange={checked => this.toggleFieldSync(checked, integration)}
								/>
								<Label className={classes.elem('label').b()}>{integration.name}</Label>
							</div>
						))
					) : (
						<Text className={classes.elem('no-integrations').b()}>
							{T('integration.noAppsWithFieldSync')}
						</Text>
					)}
				</Card>
			</DropDownMenu>
		);
	}
}

FieldSync.propTypes = {
	apps: PropTypes.array,
	toggleSync: PropTypes.func
};

FieldSync.defaultProps = {
	apps: [],
	toggleSync: () => {}
};

export default FieldSync;

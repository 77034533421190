import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import SubscriptionCards from 'App/components/SubscriptionCards';
import { renderSubscriptionPromo } from 'App/components/EditSubscription/SubscriptionPromo';

angular.module('domain.contact').controller('ContactOrder', [
	'$scope',
	'RequestBuilder',
	'FilterHelper',
	'$upModal',
	'$translate',
	'$stateParams',
	'Order',
	'AppService',
	'$filter',
	'Agreement',
	'$q',
	'$location',
	'avatarService',
	'Report',
	'$state',
	'$timeout',
	function (
		$scope,
		RequestBuilder,
		FilterHelper,
		$upModal,
		$translate,
		$stateParams,
		Order,
		AppService,
		$filter,
		Agreement,
		$q,
		$location,
		avatarService,
		Report,
		$state,
		$timeout
	) {
		var ContactOrderCtrl = this;
		var ContactCtrl = $scope.ContactCtrl;
		var orders;
		var customerId;
		var orderStages;
		var users;
		var excludedStages = [];
		var currentOrderFilter;
		var currentYear = moment().format('YYYY');

		$scope.SubscriptionCards = SubscriptionCards;
		ContactOrderCtrl.limit = 50;
		ContactOrderCtrl.offset = 0;
		ContactOrderCtrl.page = 1;

		var noData = function () {
			return (
				!orders.length &&
				!ContactOrderCtrl.contactAgreements?.length &&
				!ContactOrderCtrl.contactAgreementsInactive?.length
			);
		};

		$scope.$on('order.deleted', function (e, deleted) {
			var index = _.findIndex(orders, { id: deleted.id });

			if (index !== -1) {
				orders.splice(index, 1);
				ContactOrderCtrl.nrOfOrders--;

				// If no more orders or agreements - go to dashboard
				if (noData()) {
					$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
				} else {
					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			}
		});

		$scope.createOrder = function () {
			var opts = {
				customerId: $stateParams.customerId
			};

			opts.clientId = ContactCtrl.contact.client.id;
			opts.contactId = ContactCtrl.contact.id;

			$upModal.open('editOrder', opts);
		};

		$scope.createAgreement = function () {
			var options = {
				customerId: ContactCtrl.customerId,
				accountId: ContactCtrl.contact.client.id,
				contactId: ContactCtrl.contact.id
			};

			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				return $upModal.open('editAgreement', options);
			}

			openModal('CreateSubscription', {
				client: ContactCtrl.contact.client,
				contact: ContactCtrl.contact,
				createdFrom: 'contactSalesAddButton',
				dontWait: Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS')
			});
		};

		$scope.$on('order.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				if (FilterHelper.match(currentOrderFilter.q, added, 'order')) {
					orders.push(added);
					ContactOrderCtrl.nrOfOrders++;

					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			}
		});

		$scope.$on('order.updated', function (e, updated) {
			const order = _.find(orders, { id: updated.id });
			if (updated.contact && updated.contact.id === ContactCtrl.contact.id) {
				if (order) {
					if (FilterHelper.match(currentOrderFilter.q, updated, 'order')) {
						angular.extend(order, updated);
					} else {
						_.remove(orders, order);
						ContactOrderCtrl.nrOfOrders--;
					}

					$scope.$evalAsync(function () {
						groupChange();
					});

					$timeout(function () {
						createSeries(true);
					}, 3000);
				}
			} else if (order) {
				// Check if the updated order was in the list but shuld be removed now since contact changed
				_.remove(orders, order);
				ContactOrderCtrl.nrOfOrders--;

				$scope.$evalAsync(function () {
					groupChange();
				});
			}
		});

		$scope.$on('agreement.deleted', function (e, deleted) {
			var found = false;
			var activeIndex = _.findIndex(ContactOrderCtrl.contactAgreements, { id: deleted.id });
			if (activeIndex !== -1) {
				ContactOrderCtrl.contactAgreements.splice(activeIndex, 1);
				found = true;
			}

			var inactiveIndex = _.findIndex(ContactOrderCtrl.contactAgreementsInactive, { id: deleted.id });
			if (inactiveIndex !== -1) {
				ContactOrderCtrl.contactAgreementsInactive.splice(inactiveIndex, 1);
				found = true;
			}

			if (found) {
				ContactOrderCtrl.agreementTotal--;
				// If no more orders or agreements - go to dashboard
				if (noData()) {
					$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
				}

				setTimeout(function () {
					getAgreements();
				}, 2000);
			}
		});

		$scope.$on('agreement.added', function (e, added) {
			if (added.contact && added.contact.id === ContactCtrl.contact.id) {
				ContactOrderCtrl.agreementTotal++;
				setTimeout(function () {
					getAgreements();
				}, 2000);
			}
		});

		$scope.$on('agreement.updated', function (e, updated) {
			var activeIndex = _.findIndex(ContactOrderCtrl.contactAgreements, { id: updated.id });
			if (activeIndex !== -1) {
				ContactOrderCtrl.contactAgreements.splice(activeIndex, 1);
			}

			var inactiveIndex = _.findIndex(ContactOrderCtrl.contactAgreementsInactive, { id: updated.id });
			if (inactiveIndex !== -1) {
				ContactOrderCtrl.contactAgreementsInactive.splice(inactiveIndex, 1);
			}

			if (updated.contact && updated.contact.id === ContactCtrl.contact.id) {
				if (
					updated.metadata.agreementEnddate === null ||
					moment().isBefore(updated.metadata.agreementEnddate)
				) {
					ContactOrderCtrl.contactAgreements.push(updated);
				} else {
					ContactOrderCtrl.contactAgreementsInactive.push(updated);
				}

				setTimeout(function () {
					getAgreements();
				}, 2000);
			}
		});

		var groupable = {
			year: {
				id: 'year',
				text: $translate.instant('date.year'),
				groupOn: function (obj) {
					return moment(obj.date).format('YYYY');
				}
			},
			month: {
				id: 'month',
				text: $translate.instant('date.month'),
				groupOn: function (obj) {
					return moment(obj.date).format('YYYY-MM');
				}
			},
			user: { id: 'user', text: $translate.instant('default.user'), groupOn: 'user.id', textOn: 'user.name' },
			stage: { id: 'stage', text: $translate.instant('default.stage'), groupOn: 'stage.id', textOn: 'stage.name' }
		};

		ContactOrderCtrl.removeOrder = function (order, e) {
			if (e) {
				e.stopPropagation();
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'default.order',
					body: 'confirm.removeOrder',
					onClose: confirmed => {
						if (confirmed) {
							Order.customer(customerId)['delete'](order);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('default.order').toLowerCase(),
					body: 'confirm.removeOrder',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Order.customer(customerId)['delete'](order);
				});
		};

		ContactOrderCtrl.getOrders = function () {
			ContactOrderCtrl.ordersLoading = true;

			var rb = new RequestBuilder();

			if (ContactOrderCtrl.grouping.id === 'user') {
				rb.addSort(Order.attr.user.attr.name, true);
			} else if (ContactOrderCtrl.grouping.id === 'stage') {
				rb.addSort(Order.attr.stage.attr.name, true);
			}

			var filter = getFilters(rb);
			filter.addFilter(Order.attr.probability, filter.comparisonTypes.Equals, 100);
			filter.addFilter(Order.attr.stage, filter.comparisonTypes.NotEquals, excludedStages);
			filter.limit = ContactOrderCtrl.limit;
			filter.offset = ContactOrderCtrl.offset;

			currentOrderFilter = filter.build();

			Order.customer(customerId)
				.find(currentOrderFilter)
				.then(function (response) {
					ContactOrderCtrl.nrOfOrders = response.metadata.total;
					orders = response.data;

					groupChange();
					// Scroll to top
					angular.element(window).scrollTop(0);

					ContactOrderCtrl.ordersLoading = false;
				})
				.catch(e => {
					logError(e, 'Failed to get orders');
					ContactOrderCtrl.ordersLoading = false;
				});
		};

		ContactOrderCtrl.setType = function (key) {
			ContactOrderCtrl.type = key;
			if (['year', 'month'].indexOf(ContactOrderCtrl.grouping.id) < 0) {
				ContactOrderCtrl.setGroupable('year');
			}

			$location.search('type', ContactOrderCtrl.type);

			createSeries();
		};

		ContactOrderCtrl.setGroupable = function (key) {
			ContactOrderCtrl.page = 1;
			ContactOrderCtrl.offset = 0;
			ContactOrderCtrl.grouping = groupable[key];
			if (ContactOrderCtrl.nrOfOrders > ContactOrderCtrl.limit) {
				ContactOrderCtrl.getOrders();
			} else {
				groupChange();
			}
		};

		function createSeries(onlyUpdateYearSum) {
			var categories, data, cmData, year;
			var rb = getFilters();
			rb.addFilter(Order.attr.probability, rb.comparisonTypes.Equals, 100);
			rb.addFilter(Order.attr.stage, rb.comparisonTypes.NotEquals, excludedStages);
			var agg = rb.aggregationBuilder();

			var grouping = onlyUpdateYearSum ? 'month' : ContactOrderCtrl.grouping.id;

			switch (grouping) {
				case 'year':
					year = 1900 + new Date().getYear();
					categories = _.range(year - 11, year + 1);
					categories.reverse();
					agg.addAggregation(rb.aggregationTypes.Terms, 'date');
					agg.aggregationInterval(rb.aggregationIntervals.YEAR);
					break;

				case 'month':
					var month = new Date().getMonth();
					year = 1900 + new Date().getYear();
					categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (m) {
						var mon = month - m;
						if (mon < 0) {
							return (
								year -
								1 +
								'-' +
								moment()
									.month(mon + 12)
									.format('MM') +
								'-01'
							);
						}
						return year + '-' + moment().month(mon).format('MM') + '-01';
					});

					agg.addAggregation(rb.aggregationTypes.Terms, 'date');
					agg.aggregationInterval(rb.aggregationIntervals.MONTH);
					break;
				case 'stage':
					agg.addAggregation(rb.aggregationTypes.Terms, 'stage.id');
					break;
				case 'user':
					agg.addAggregation(rb.aggregationTypes.Terms, 'user.id');
					break;
			}
			agg.aggregationName('grouping');
			agg.aggregationOrder('valueSum', false);

			var subAgg = agg.aggregationBuilder();
			subAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInRoleCurrency.field);
			subAgg.aggregationName('valueSum');
			subAgg.done();
			var subAgg2 = agg.aggregationBuilder();
			subAgg2.addAggregation(rb.aggregationTypes.Sum, Order.attr.contributionMarginInRoleCurrency.field);
			subAgg2.aggregationName('cmSum');
			subAgg2.done();
			agg.done();

			Report.customer(customerId)
				.setType(Report.type.ORDER)
				.find(rb.build())
				.then(function (res) {
					switch (grouping) {
						case 'year':
							data = _.range(0, 12, 0);
							cmData = _.range(0, 12, 0);
							_.each(res.data.grouping.buckets, function (item) {
								// map data to right year..
								var year = moment(item.key).format('YYYY');
								var index = categories.indexOf(parseInt(year));
								if (index !== -1 && item.valueSum) {
									data[index] = item.valueSum.value;
									cmData[index] = item.cmSum.value;
								}
							});

							break;

						case 'month':
							data = _.range(0, 12, 0);
							cmData = _.range(0, 12, 0);
							_.each(res.data.grouping.buckets, function (item) {
								// map data to right year..
								var month = moment(item.key).format('YYYY-MM') + '-01';
								var index = categories.indexOf(month);
								if (index !== -1 && item.valueSum) {
									data[index] = item.valueSum.value;
									cmData[index] = item.cmSum.value;
								}
							});
							ContactOrderCtrl.sales12Months = _.sum(data);
							break;

						case 'user':
							categories = _.map(res.data.grouping.buckets, function (user) {
								var u = _.find(users, { id: user.key });
								return u
									? { name: u.name, email: u.email }
									: { name: $translate.instant('default.inactiveUser'), email: '' };
							});

							data = _.map(res.data.grouping.buckets, function (item) {
								return item.valueSum.value;
							});
							cmData = _.map(res.data.grouping.buckets, function (item) {
								return item.cmSum.value;
							});

							// Max 12 users
							if (categories.length > 11) {
								categories = categories.slice(0, 11);
								data = data.slice(0, 11);
								cmData = cmData.slice(0, 11);
							}

							break;

						case 'stage':
							categories = _.map(res.data.grouping.buckets, function (stage) {
								var stageName = _.find(orderStages, { id: stage.key });
								return stageName ? stageName.name : null;
							});

							data = _.map(res.data.grouping.buckets, function (item) {
								return item.valueSum.value;
							});
							cmData = _.map(res.data.grouping.buckets, function (item) {
								return item.cmSum.value;
							});

							if (categories.length > 11) {
								categories = categories.slice(0, 11);
								data = data.slice(0, 11);
								cmData = cmData.slice(0, 11);
							}

							break;
					}

					if (onlyUpdateYearSum) {
						return;
					}

					buildChart({ categories: categories, data: data, cmData: cmData });
				})
				.catch(e => {
					logError(e, 'Failed to get and build report');
				});
		}

		function buildChart(graphData) {
			if (!ContactOrderCtrl.grouping) {
				ContactOrderCtrl.grouping = groupable.year;
			}

			if (!graphData || !graphData.data || !graphData.data.length || !graphData.categories.length) {
				ContactOrderCtrl.showChart = false;
			} else {
				ContactOrderCtrl.showChart = true;
				if (ContactCtrl.salesModel === 'cm') {
					ContactOrderCtrl.chart = {
						chart: {
							type: 'column',
							height: 216,
							backgroundColor: 'transparent',
							style: {
								fontFamily: '"Roboto"'
							},
							marginBottom: 35
						},
						colors: ['#5cb85c', '#2B7B2B'],
						title: {
							text: ''
						},
						subtitle: {
							text: null
						},
						credits: { enabled: false },
						legend: {
							enabled: false
						},
						xAxis: {
							lineColor: 'transparent',
							tickLength: 0,
							categories: graphData.categories,
							labels: {
								overflow: false,
								rotation: false,
								style: {
									textOverflow: 'none',
									whiteSpace: 'nowrap'
								},
								formatter: function () {
									var compact = document.body.clientWidth <= 1180;
									var label = this.value;
									var prefix = '<span style="font-size: 10px;">';
									var postfix = '</span>';
									if (compact) {
										prefix = '<span style="font-size: 8px;">';
										postfix = '</span>';
									}
									switch (ContactOrderCtrl.grouping.id) {
										case 'year':
											label = compact ? moment('01-01-' + this.value).format("'YY") : this.value;
											break;
										case 'month':
											var format = this.value.indexOf(currentYear) !== -1 ? 'MMM' : "MMM 'YY";
											label = moment(this.value).format(format);
											break;
										case 'user':
											var initials = avatarService.getInitials(this.value);
											label = compact ? initials : this.value.name;
											break;
									}

									return prefix + label + postfix;
								}
							}
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							gridLineColor: '#e0e0e0',
							endOnTick: true,
							maxPadding: 0.02,
							// tickPositioner: function(){
							//     return [0, Math.ceil(this.dataMax/3),2*Math.ceil(this.dataMax/3), Math.ceil(this.dataMax)];
							// 		},
							labels: {
								formatter: function () {
									if (this.value > 0) {
										return $filter('numberFormat')(this.value);
									}
								}
							}
						},
						tooltip: {
							enabled: true
						},
						plotOptions: {
							column: {
								pointPadding: 0,
								borderWidth: 0,
								grouping: false
							}
						},
						series: [
							{
								name: $translate.instant('default.order'),
								data: graphData.data,
								borderRadius: 4
							},
							{
								name: $translate.instant('default.contributionMargin'),
								data: graphData.cmData
							}
						]
					};
				} else {
					const name = $translate.instant('default.order');

					ContactOrderCtrl.chart = {
						chart: {
							type: 'column',
							height: 166,
							backgroundColor: 'transparent',
							style: {
								fontFamily: '"Roboto"'
							},
							marginBottom: 35
						},
						colors: ['#5cb85c'],
						title: {
							text: ''
						},
						subtitle: {
							text: null
						},
						credits: { enabled: false },
						legend: {
							enabled: false
						},
						xAxis: {
							lineColor: 'transparent',
							tickLength: 0,
							categories: graphData.categories,
							labels: {
								overflow: false,
								rotation: false,
								style: {
									textOverflow: 'none',
									whiteSpace: 'nowrap'
								},
								formatter: function () {
									var compact = document.body.clientWidth <= 1180;
									var label = this.value;
									var prefix = '<span style="font-size: 10px;">';
									var postfix = '</span>';
									if (compact) {
										prefix = '<span style="font-size: 8px;">';
										postfix = '</span>';
									}
									switch (ContactOrderCtrl.grouping.id) {
										case 'year':
											label = compact ? moment('01-01-' + this.value).format("'YY") : this.value;
											break;
										case 'month':
											var format = this.value.indexOf(currentYear) !== -1 ? 'MMM' : "MMM 'YY";
											label = moment(this.value).format(format);
											break;
										case 'user':
											var initials = avatarService.getInitials(this.value);
											label = compact ? initials : this.value.name;
											break;
									}

									return prefix + label + postfix;
								}
							}
						},
						yAxis: {
							min: 0,
							title: {
								text: ''
							},
							gridLineColor: '#e0e0e0',
							endOnTick: true,
							maxPadding: 0.02,
							// tickPositioner: function(){
							//     return [0, Math.ceil(this.dataMax/3),2*Math.ceil(this.dataMax/3), Math.ceil(this.dataMax)];
							// 		},
							labels: {
								formatter: function () {
									if (this.value > 0) {
										return $filter('numberFormat')(this.value);
									}
								}
							}
						},
						tooltip: {
							enabled: true
						},
						plotOptions: {
							column: {
								pointPadding: 0,
								borderWidth: 0,
								borderRadius: 4
							}
						},
						series: [
							{
								name: name,
								data: graphData.data,
								pointWidth: 70
							}
						]
					};
				}
			}
		}

		function groupChange() {
			ContactOrderCtrl.contactOrders = $filter('groupBy')(orders, ContactOrderCtrl.grouping);

			if (ContactOrderCtrl.grouping && ContactOrderCtrl.grouping.id === 'month') {
				ContactOrderCtrl.contactOrders = _.sortBy(ContactOrderCtrl.contactOrders, function (order) {
					return +moment(order.title);
				});
			} else {
				ContactOrderCtrl.contactOrders = _.sortBy(ContactOrderCtrl.contactOrders, 'title');
			}

			// sort function..
			if (
				ContactOrderCtrl.grouping &&
				(ContactOrderCtrl.grouping.id === 'year' || ContactOrderCtrl.grouping.id === 'month')
			) {
				ContactOrderCtrl.contactOrders.reverse();
			}

			createSeries();

			$location.search('groupBy', ContactOrderCtrl.grouping ? ContactOrderCtrl.grouping.id : 'year');
		}

		function getAgreements() {
			ContactOrderCtrl.agreementsLoading = true;
			var activeFilter = getFilters();
			var nd = new Date();

			var orGroup = activeFilter.orBuilder();
			orGroup.next();
			orGroup.addFilter(Agreement.attr.metadata.attr.agreementEnddate, activeFilter.comparisonTypes.Equals, null);
			orGroup.next();
			orGroup.addFilter(
				Agreement.attr.metadata.attr.agreementEnddate,
				activeFilter.comparisonTypes.GreaterThan,
				nd
			);
			orGroup.done();
			activeFilter.addSort(Agreement.attr.metadata.attr.agreementStartdate, false);

			var inactiveFilter = getFilters();
			inactiveFilter.addFilter(
				Agreement.attr.metadata.attr.agreementEnddate,
				inactiveFilter.comparisonTypes.LessThan,
				nd
			);
			inactiveFilter.addSort(Agreement.attr.metadata.attr.agreementStartdate, false);

			$q.all({
				active: Agreement.customer(customerId).find(activeFilter.build()),
				inactive: Agreement.customer(customerId).find(inactiveFilter.build())
			})
				.then(function (res) {
					ContactOrderCtrl.contactAgreements = res.active.data;
					ContactOrderCtrl.contactAgreementsInactive = checkInactiveDates(res.inactive.data);
					ContactOrderCtrl.agreementTotal =
						ContactOrderCtrl.contactAgreements.length + ContactOrderCtrl.contactAgreementsInactive.length;

					if (ContactOrderCtrl.contactAgreements && ContactOrderCtrl.contactAgreements.length) {
						// Set first as next
						var nextPayDate = ContactOrderCtrl.contactAgreements[0].metadata.agreementNextOrderDate;
						var nextPayValueMasterCurrency = ContactOrderCtrl.contactAgreements[0].valueInMasterCurrency;
						var nextPayValue = ContactOrderCtrl.contactAgreements[0].value;
						var nextPayCurrency = ContactOrderCtrl.contactAgreements[0].currency;

						// Try to find closer one
						_.each(ContactOrderCtrl.contactAgreements, function (agr) {
							var thisIsBetter = false;
							// If this one has a nextOrderDate closer in time
							if (moment(agr.metadata.agreementNextOrderDate).isBefore(nextPayDate)) {
								// If not same day or worth more than current (so same-day-orders shows the one with most value)
								if (
									!moment(agr.metadata.agreementNextOrderDate).isSame(nextPayDate, 'day') ||
									nextPayValueMasterCurrency > agr.valueInMasterCurrency
								) {
									thisIsBetter = true;
								}
							}

							if (thisIsBetter) {
								nextPayDate = agr.metadata.agreementNextOrderDate;
								nextPayValueMasterCurrency = agr.valueInMasterCurrency;
								nextPayValue = agr.value;
								nextPayCurrency = agr.currency;
							}
						});

						ContactOrderCtrl.nextReOrder = nextPayDate;
						var defaultCurrency = AppService.getMetadata().defaultCurrency;
						if ((nextPayCurrency || '').toLowerCase() !== defaultCurrency.iso.toLowerCase()) {
							ContactOrderCtrl.nextReOrderValue = Math.round(
								nextPayValueMasterCurrency * defaultCurrency.rate
							);
						} else {
							ContactOrderCtrl.nextReOrderValue = nextPayValue;
						}
					}
					ContactOrderCtrl.agreementsLoading = false;
				})
				.catch(e => {
					ContactOrderCtrl.agreementsLoading = false;
					logError(e, 'Failed to get agreements');
				});
		}

		ContactOrderCtrl.getSumOfSales = function (orders) {
			if (!ContactOrderCtrl.contactOrders || !ContactOrderCtrl.contactOrders.length) {
				return;
			}

			var key = 'valueInMasterCurrency';
			var keyInOwnCurrency = 'value';

			if (orders === 1 && ContactOrderCtrl.contactAgreements?.length) {
				// agreements
				orders = ContactOrderCtrl.contactAgreements;
				key = 'yearlyValueInMasterCurrency';
				keyInOwnCurrency = 'yearlyValue';
			} else if (orders === 2 && ContactOrderCtrl.contactOrders?.length) {
				// First sale -->
				orders = ContactOrderCtrl.contactOrders[0].items;
			}

			var defaultCurrency = AppService.getMetadata().defaultCurrency;
			return _.reduce(
				orders,
				function (sum, order) {
					if ((order.currency || '').toLowerCase() === defaultCurrency.iso.toLowerCase()) {
						return sum + order[keyInOwnCurrency];
					} else {
						return sum + Math.round(order[key] * defaultCurrency.rate);
					}
				},
				0
			);
		};

		function checkInactiveDates(agreements) {
			angular.forEach(agreements, function (agreement) {
				if (!moment(agreement.metadata.agreementEnddate).isAfter(agreement.metadata.agreementNextOrderDate)) {
					agreement.metadata.agreementNextOrderDate = null;
				}
			});
			return agreements;
		}

		var getTypeFromHash = function () {
			var hash = $location.search();
			return hash.type || 'recurring';
		};

		var getGroupingFromHash = function () {
			var hash = $location.search();
			var groupBy = hash.groupBy ? hash.groupBy : 'year';
			return groupable[groupBy];
		};

		function getFilters(rb) {
			var filters = rb || new RequestBuilder();
			filters.addFilter(Order.attr.contact, filters.comparisonTypes.Equals, ContactCtrl.contact.id);

			filters.addSort(Order.attr.date, false);

			return filters;
		}

		var init = function () {
			excludedStages = AppService.getStages('excludedIds', true);
			customerId = AppService.getCustomerId();
			orderStages = AppService.getStages('won');
			users = AppService.getUsers('order');
			ContactOrderCtrl.grouping = getGroupingFromHash();
			ContactOrderCtrl.type = getTypeFromHash();
			ContactOrderCtrl.isRecurringModel = ContactCtrl.salesModel === 'rr';

			ContactOrderCtrl.showInactive = false;
			ContactOrderCtrl.hasAgreements =
				AppService.getMetadata().params.AgreementEnabled &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
				AppService.getAccessRights().Agreement;

			ContactOrderCtrl.hasSubscriptionCardCards =
				Tools.FeatureHelper.hasSoftDeployAccess('AGREEMENT_GROUP_CARDS');

			if (ContactOrderCtrl.hasAgreements) {
				getAgreements();
			}

			ContactOrderCtrl.getOrders();

			renderSubscriptionPromo(Tools.AppService.getAccountSelf(), ContactCtrl.account, ContactCtrl.contact);

			createSeries();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

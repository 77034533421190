'use strict';

ReactTemplates.reportAccountSales = window.ReactCreateClass({
	chart: null,
	shouldComponentUpdate: function (nextProps, nextState) {
		return !_.isEqual(nextProps.config.series, this.props.config.series);
	},
	renderChart: function () {
		var self = this;
		var config = self.props.config;
		var container = self.refs.container;

		if (config.chart) {
			config.chart.animation = false;
		}
		new Highcharts.Chart(container, config, function () {
			self.chart = this;
			self.reflow();
		});
	},
	reflow: function () {
		if (this.chart) {
			var config = this.props.config;
			var container = this.refs.container;
			var reflow = false;

			if (
				config.chart &&
				config.chart.type === 'column' &&
				config.series.length &&
				config.series[0].data.length
			) {
				var newWidth = config.series[0].data.length * 150;

				if (Tools.AppService.getMetadata().params.SalesModel === 'cm') {
					const pt = container.parentNode.clientWidth / 20;
					this.chart.series.forEach(item => {
						item.update({ pointWidth: pt });
					});
				} else {
					const pt = container.parentNode.clientWidth / 15;
					this.chart.series[0].update({
						pointWidth: pt
					});
				}
				reflow = true;

				if (newWidth > container.parentNode.clientWidth) {
					// Set new width for chart
					//container.style.width = newWidth +'px';

					reflow = true;
				} else {
					container.style.width = null;
					reflow = true;
				}
			} else {
				container.style.width = null;
				reflow = true;
			}

			if (reflow) {
				this.chart.reflow(); // reflow chart
			}
		}
	},
	componentDidMount: function () {
		window.addEventListener('resize', this.renderChart);
		this.renderChart();
	},

	componentWillUnmount: function () {
		window.removeEventListener('resize', this.renderChart);
	},
	componentDidUpdate: function () {
		this.renderChart();
	},
	render: function () {
		return <div ref="container"></div>;
	}
});

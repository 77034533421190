import CompanyProfile from './CompanyProfile';
import Contacts from './CompanyProfileContacts';
import Colors from './CompanyProfileColors';
import Logotypes from './CompanyProfileLogotypes';
import Typography from './CompanyProfileTypography';

export default {
	default: CompanyProfile,
	contacts: Contacts,
	logotypes: Logotypes,
	colors: Colors,
	typography: Typography
};

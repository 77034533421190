'use strict';

angular.module('upResources').service('MapActivity', function () {
	return function (activity) {
		if (activity.activity && activity.activity.client) {
			var clientId = activity.activity.client.id;
			delete activity.activity.client;

			activity.activity.client = { id: clientId };
			return activity;
		}

		return activity;
	};
});

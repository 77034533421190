import React from 'react';
import { Checkbox, Label } from '@upsales/components';
import T from 'Components/Helpers/translate';

type Props = {
	subscriptionChecked: boolean;
	oneOffChecked: boolean;
	bundleChecked: boolean;
	inactiveChecked: boolean;
	onChange: (type: string) => void;
};
const ProductListFilters = ({
	subscriptionChecked,
	oneOffChecked,
	bundleChecked,
	inactiveChecked,
	onChange
}: Props) => {
	const hasSubscriptions = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
	const hasProductBundle =
		Tools.FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_BUNDLES);

	return (
		<div className="admin-products-filters">
			{hasSubscriptions ? (
				<>
					<div className="admin-products-filter">
						<Checkbox
							size="sm"
							checked={subscriptionChecked}
							onChange={() => onChange('subscriptionProducts')}
						/>
						<Label>{T('agreement.agreements')}</Label>
					</div>
					<div className="admin-products-filter">
						<Checkbox size="sm" checked={oneOffChecked} onChange={() => onChange('oneOffProducts')} />
						<Label>{T('admin.products.oneOff')}</Label>
					</div>
				</>
			) : hasProductBundle ? (
				<div className="admin-products-filter">
					<Checkbox
						size="sm"
						checked={oneOffChecked}
						onChange={() => {
							onChange('allProducts');
						}}
					/>
					<Label>{T('default.products')}</Label>
				</div>
			) : null}
			{hasProductBundle ? (
				<div className="admin-products-filter">
					<Checkbox size="sm" checked={bundleChecked} onChange={() => onChange('productBundles')} />
					<Label>{T('admin.products.bundles')}</Label>
				</div>
			) : null}
			<div className="admin-products-filter">
				<Checkbox size="sm" checked={inactiveChecked} onChange={() => onChange('onlyActive')} />
				<Label>{T('default.inactivePlural')}</Label>
			</div>
		</div>
	);
};

export default ProductListFilters;

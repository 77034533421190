import React, { useState, useRef } from 'react';
import {
	Label,
	Button,
	Icon,
	Title,
	Modal,
	Input,
	OutsideClick,
	ModalControls,
	Card,
	Progress,
	Text
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './FileBrowserLabels.scss';
import T from 'Components/Helpers/translate';
import LabelModel from 'App/resources/Model/Label';
import FileBrowserLabel from './FileBrowserLabel';
import Tracker from 'App/babel/helpers/Tracker';

interface Props {
	labels: LabelModel[];
	onSaveLabel: (label: LabelModel) => void;
	onDeleteLabel: (label: LabelModel) => void;
	onSelectLabel: (label: LabelModel) => void;
	filterLabel: LabelModel;
	entityType: LabelModel['entity'];
	unlabeled: LabelModel;
	allEntitiesLabel: LabelModel;
	tracker?: Tracker;
}
const FileBrowserLabels = ({
	labels,
	onSaveLabel,
	onDeleteLabel,
	onSelectLabel,
	filterLabel,
	entityType,
	unlabeled,
	allEntitiesLabel,
	tracker
}: Props) => {
	const classes = new bemClass('FileBrowserLabels');
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);
	const [selectedLabel, setSelectedLabel] = useState<LabelModel>({ name: '', entity: entityType, id: -1 });
	const mainRef = useRef<HTMLDivElement>(null);
	const maxLength = 30;

	const setTracking = (event: string) => {
		if (tracker) {
			tracker.track(event);
		}
	};

	const saveLabel = () => {
		if (selectedLabel.id) {
			onSaveLabel(selectedLabel);
		} else {
			if (tracker) {
				setTracking(tracker.events.CREATED_LABEL);
			}
			onSaveLabel({ name: selectedLabel.name, entity: entityType });
		}
		setModalOpen(false);
	};

	const inputValidation = (label: LabelModel) => {
		if (!!label.name.replace(/\s/g, '').length && !labels.some(l => l.name === label.name && l.id !== label.id)) {
			setEnableSaveButton(true);
		} else {
			setEnableSaveButton(false);
		}
	};

	const editLabel = (label: LabelModel) => {
		setSelectedLabel(label);
		inputValidation(label);
		setModalOpen(true);
	};

	const labelRows = labels.map(label => {
		return (
			<FileBrowserLabel
				key={label.id}
				editLabel={() => editLabel(label)}
				onSelect={() => {
					onSelectLabel(label);
					if (tracker) {
						setTracking(tracker.events.CLICKED_LABEL);
					}
				}}
				onDelete={() => onDeleteLabel(label)}
				selected={filterLabel.id === label.id}
				numEntities={label.numEntities || 0}
				title={label.name}
			/>
		);
	});

	return (
		<div className={classes.elem('wrapper').b()}>
			<div className={classes.elem('header').b()}>
				<Label className={classes.elem('label').b()}>{T('file.labels')}</Label>
				<Button type="link" disabled={modalOpen}>
					<Icon
						className={classes.elem('icon').b()}
						name="plus"
						onClick={() => editLabel({ name: '', entity: entityType })}
					></Icon>
				</Button>
			</div>
			{modalOpen ? (
				<Modal className={classes.elem('modal').b()}>
					<div ref={mainRef} className={classes.elem('modal').b()}>
						<OutsideClick targetRef={() => mainRef.current} outsideClick={() => setModalOpen(false)}>
							<Card className={classes.elem('modalCard').b()}>
								<form>
									<Title>{selectedLabel.id ? T('file.editLabel') : T('file.newLabel')} </Title>
									<Label required>
										{T('default.name')}
										<Progress
											hideText
											percentage={(selectedLabel.name.length / maxLength) * 100}
											className={classes.elem('progress').b()}
										/>
									</Label>
									<Input
										autoFocus
										onChange={e => {
											setSelectedLabel({ ...selectedLabel, name: e.target.value });
											inputValidation({ ...selectedLabel, name: e.target.value });
										}}
										defaultValue={selectedLabel.name}
										onClear={function noRefCheck() {}}
										maxLength={maxLength}
										required
										state={enableSaveButton || !selectedLabel.name.length ? 'focus' : 'error'}
									></Input>
									{enableSaveButton || !selectedLabel.name.length ? null : (
										<Text color="red" bold size="sm">
											{T('saveError.nameNotUnique')}
										</Text>
									)}

									<ModalControls>
										<Button
											submit
											onClick={() => saveLabel()}
											size="sm"
											disabled={!enableSaveButton}
										>
											{T('default.save')}
										</Button>
										<Button type="link" size="sm" onClick={() => setModalOpen(false)}>
											{T('default.cancel')}
										</Button>
									</ModalControls>
								</form>
							</Card>
						</OutsideClick>
					</div>
				</Modal>
			) : null}
			<FileBrowserLabel
				onSelect={() => onSelectLabel(allEntitiesLabel)}
				selected={filterLabel?.type === allEntitiesLabel.type}
				title={allEntitiesLabel.name}
				numEntities={allEntitiesLabel.numEntities}
			/>
			{labelRows}
			<FileBrowserLabel
				title={unlabeled.name}
				selected={filterLabel?.type === unlabeled.type}
				numEntities={unlabeled.numEntities}
				onSelect={() => onSelectLabel(unlabeled)}
			></FileBrowserLabel>
		</div>
	);
};

export default FileBrowserLabels;

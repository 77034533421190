import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import './EventActivity.scss';
import JourneyStepDisplay from '../JourneyStepDisplay';
import { Button, Row, Column } from '@upsales/components';
import EventTitles from './EventTitles';
import EventContacts from './EventContacts';
import EventNotes from './EventNotes';
import ActivityOutcomes from '../Activity/ActivityOutcomes';
import ActivityHistoryLog from 'App/components/HistoryLog/ActivityHistoryLog';
import ReduxRootComponent from 'Components/ReduxComponentRoot';

class EventActivity extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			openActivity: t('default.openActivity'),
			and: t('default.and').toLowerCase(),
			more: t('account.more').toLowerCase(),
			noOptInYet: t('default.noOptInYet'),
			noPhone: t('default.noPhone'),
			notes: t('default.notes'),
			selectContact: t('default.selectContact'),
			noEmail: t('default.noEmail'),
			new: t('default.new'),
			edit: t('default.edit')
		};
	}

	updateContact = (type, value) => {
		this.props.updateContact(value, type);
	};

	render() {
		const { eventDetails, company } = this.props;
		const classes = new BemClass('ActivityWrapper');
		const contact = eventDetails?.contact;
		const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);
		const isLockedActivity = !!eventDetails?.closeDate;

		return (
			<div className={classes.b()}>
				<div className={classes.elem('contactDetails')}>
					{eventDetails && (
						<form>
							<Column>
								<Row className="contactOptionWrapper">
									<div className={classes.elem('contactSelectOptions').mod({ empty: !contact }).b()}>
										<Row className="ContactSelect">
											<ReactTemplates.INPUTS.upContacts
												id="contact-select"
												name="contact"
												value={contact}
												disabled={isLockedActivity}
												linked={true}
												onlyActive={true}
												tabIndex={2}
												onChange={this.props.saveContact}
												className="form-control no-transition"
												placeholder={this.lang.selectContact}
												accountId={eventDetails.client ? eventDetails.client.id : null}
												formatResult={function (contact) {
													return window.Tools.utils.select2.clientContactsAndRelations.formatResult(
														contact,
														eventDetails.client ? eventDetails.client.id : null
													);
												}}
												fields={['title', 'phone', 'cellPhone', 'email']}
											/>
										</Row>
									</div>
									<Row className="contactOptions">
										{!isLockedActivity
											? ReactTemplates.TOOLS.withTooltip(
													<Button
														color="green"
														onClick={this.props.createContact}
														size={'sm'}
													>
														<b className="fa fa-user-plus"></b> {this.lang.new}
													</Button>,
													eventDetails.client
														? Tools.$translate('default.activityAdd', {
																companyName: eventDetails.client.name
														  })
														: '',
													{
														placement: 'top'
													}
											  )
											: null}
										{contact && !isLockedActivity
											? ReactTemplates.TOOLS.withTooltip(
													<Button
														className="edit"
														color={'white'}
														shadow="none"
														onClick={this.props.editContact}
														size={'sm'}
													>
														<b className="fa fa-edit"></b> {this.lang.edit}
													</Button>,
													Tools.$translate('default.activityEdit', {
														contactName: contact.name
													}),
													{
														placement: 'top'
													}
											  )
											: null}
									</Row>
									<div className={classes.elem('clear')}></div>
								</Row>

								<EventTitles {...this.props} />

								<Column className={classes.elem('contactGroup')}>
									{contact && (
										<React.Fragment>
											<EventContacts
												type="cellphone"
												value={contact.cellPhone}
												saveContacts={this.updateContact}
												contact={contact}
											/>
											<EventContacts
												type="phone"
												value={contact.phone}
												saveContacts={this.updateContact}
												contact={contact}
											/>
										</React.Fragment>
									)}
								</Column>
								{contact && (
									<Column className={classes.elem('contactGroup')}>
										<div className={classes.elem('journeyStep')}>
											<JourneyStepDisplay
												id={contact.id}
												disableActions={!contact.userEditable}
												type="contact"
												journeyStep={contact.journeyStep}
												onSetJourneyStep={step => this.props.setJourneyStep(step)}
											/>
										</div>
										<div className={classes.elem('contactOptions')}>
											<EventContacts
												type="email"
												value={contact.email}
												contact={contact}
												saveContacts={this.updateContact}
											/>
										</div>
									</Column>
								)}
								<div className={classes.elem('clear')}></div>
							</Column>
						</form>
					)}

					{hasActivityOutcomeAccess && this.props.isOutcomeEnabled ? (
						<div>
							<EventNotes
								notes={eventDetails?.notes}
								updateNotes={this.props.updateNotes}
								disabled={isLockedActivity}
							/>
						</div>
					) : null}
				</div>

				<div className={classes.elem('outcomes')}>
					{!hasActivityOutcomeAccess ? (
						<div>
							<EventNotes notes={eventDetails?.notes} updateNotes={this.props.updateNotes} />
						</div>
					) : this.props.isOutcomeEnabled ? (
						<ReduxRootComponent
							component={ActivityOutcomes}
							activity={eventDetails}
							company={company ?? eventDetails.client}
							contact={eventDetails?.contact}
							confirm={this.props.confirm}
							onChange={this.props.setOutcome}
							onPresetDateChange={this.props.onPresetDateChange}
							onDateChange={this.props.addDate}
							onTimeChange={this.props.updateTime}
							disabled={this.props.disabled}
							bookAppointment={this.props.bookAppointment}
							changeContact={this.props.openContactDropdown}
							createOrder={this.props.createOrderAndSave}
							createOpportunity={this.props.createOpportunityAndSave}
							createActivityFollowUp={this.props.createActivityFollowUp}
							createTodoFollowUp={this.props.createTodoFollowUp}
							createCallFollowUp={this.props.createCallFollowUp}
							setAppointment={this.props.setAppointment}
							setContact={this.props.saveContact}
							planPhonecall={this.props.planPhonecall}
							dateAnchor={
								document.getElementById('account-card') || document.getElementById('contact-card')
							}
						/>
					) : (
						<EventNotes
							notes={eventDetails?.notes}
							updateNotes={this.props.updateNotes}
							disabled={isLockedActivity}
						/>
					)}

					<div className={hasActivityOutcomeAccess ? 'open-activity-btn' : ''}>
						<Button
							onClick={this.props.openActivity}
							className="btn up-btn btn-bright-blue no-shadow btn-lined"
						>
							{this.lang.openActivity}
						</Button>
					</div>
					<div>
						<ReduxRootComponent component={ActivityHistoryLog} activity={eventDetails} showLeading />
					</div>
				</div>

				<div className={classes.elem('clear')}></div>
			</div>
		);
	}
}

EventActivity.propTypes = {
	company: PropTypes.shape({}),
	openActivity: PropTypes.func,
	showMoreOptIns: PropTypes.func,
	createContact: PropTypes.func,
	editContact: PropTypes.func,
	saveContact: PropTypes.func,
	setJourneyStep: PropTypes.func,
	eventDetails: PropTypes.object,
	updateNotes: PropTypes.func,
	updateContact: PropTypes.func,
	confirm: PropTypes.func,
	setOutcome: PropTypes.func,
	updateTime: PropTypes.func,
	addDate: PropTypes.func,
	onPresetDateChange: PropTypes.func,
	bookAppointment: PropTypes.func,
	disabled: PropTypes.bool,
	openContactDropdown: PropTypes.func,
	addNewContactButton: PropTypes.func,
	createOrderAndSave: PropTypes.func,
	createOpportunityAndSave: PropTypes.func,
	isOutcomeEnabled: PropTypes.bool,
	setAppointment: PropTypes.func,
	setContact: PropTypes.func,
	planPhonecall: PropTypes.func,
	createActivityFollowUp: PropTypes.func,
	createTodoFollowUp: PropTypes.func,
	createCallFollowUp: PropTypes.func
};

export default EventActivity;

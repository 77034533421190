import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalContent, Label, Text, ModalControls, Button, Card } from '@upsales/components';
import AccountSelect from '../AccountSelect/AccountSelect';
import './AccountMergeModal.scss';

class AccountMergeModal extends Component {
	static propTypes = {
		reject: PropTypes.func.isRequired,
		resolve: PropTypes.func.isRequired,
		$modalParams: PropTypes.object.isRequired
	};

	state = {
		isSaving: false,
		selectedAccount: null
	};

	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			mergeClients: t('account.mergeClients'),
			merge: t('default.merge'),
			from: t('default.from'),
			mergeInfo1: t('account.mergeInfo1'),
			mergeInfo2: t('account.mergeInfo2'),
			abort: t('default.abort')
		};
	}

	onChangeAccountSelect = account => {
		this.setState({ selectedAccount: account });
	};

	merge = () => {
		this.setState({ isSaving: true });

		const { resolve, reject, $modalParams } = this.props;
		const account = $modalParams.meta.account.data;

		Tools.Account.customer($modalParams.customerId)
			.merge(account, this.state.selectedAccount)
			.then(() => resolve())
			.catch(err => reject(err));
	};

	render() {
		const { isSaving, selectedAccount } = this.state;
		const { reject, $modalParams } = this.props;
		const account = $modalParams.meta.account.data;
		const t = Tools.$translate;
		const mergeBtnText = t('account.mergeDataTo', { companyName: selectedAccount ? account.name : '' });

		return (
			<div className="AccountMergeModal">
				<ModalHeader icon="exchange" title={this.lang.mergeClients} onClose={reject} />
				<ModalContent>
					<Label>
						{this.lang.merge} {account.name} {this.lang.from.toLowerCase()}
					</Label>
					<AccountSelect
						customerId={$modalParams.customerId}
						accountId={account.id}
						onChange={this.onChangeAccountSelect}
						autoFocus={true}
					/>
					<Text>{`${this.lang.mergeInfo1} ${account.name}.`}</Text>
					<Card borderColor="bright-blue" border="lm">
						<Text>{this.lang.mergeInfo2}</Text>
					</Card>
				</ModalContent>
				<ModalControls>
					<Button
						color="bright-blue"
						onClick={this.merge}
						loading={isSaving}
						disabled={isSaving || !selectedAccount}
					>
						{mergeBtnText}
					</Button>
					<Button type="link" color="grey" onClick={reject}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

window.AccountMergeModal = AccountMergeModal;
export default AccountMergeModal;

const getComponent = isPhonecall =>
	window.ReactCreateClass({
		getInitialState: function () {
			return {
				datepickerSelectOpen: false
			};
		},
		UNSAFE_componentWillMount: function () {
			var t = window.Tools.$translate;

			this.lang = {
				phonecall: t('default.phonecall'),
				todo: t('default.todo'),
				all: t('default.all'),
				has: t('advancedSearch.has'),
				hasNot: t('advancedSearch.hasNot'),
				open: t('default.open'),
				close: t('default.closed'),
				status: t('default.status'),
				from: t('date.from'),
				to: t('date.to'),
				date: t('default.date'),
				outcome: t('activity.outcome'),
				reached: t('activity.outcome.phonecall.reached'),
				notReached: t('activity.outcome.phonecall.notReached')
			};

			this.datePresets = _.map(this.props.filter.presets, function (preset) {
				return {
					id: preset,
					name: t('date.' + preset)
				};
			});
		},
		toggleDatepickerSelect: function () {
			this.setState(function (prevState) {
				return {
					datepickerSelectOpen: !prevState.datepickerSelectOpen
				};
			});
		},
		onDatePresetChanged: function (event) {
			var value = event.target.value;
			var filterValue = this.getFilterValue();

			if (value !== filterValue.Date.value.preset) {
				this.onChange([
					{ path: 'value.Date.value.preset', value: value },
					{ path: 'value.Date.value.start', value: null },
					{ path: 'value.Date.value.end', value: null }
				]);
			}
		},
		onHasInactive: function () {
			this.onChange([{ path: 'inactive', value: true }]);
		},
		onHasChanged: function (value) {
			this.onChange([
				{ path: 'inactive', value: false },
				{ path: 'value.has', value: value }
			]);
		},
		onStatusChanged: function (value) {
			this.onChange([{ path: 'value.Status.value', value: value }]);
		},
		onOutcomeChanged: function (value) {
			this.onChange([{ path: 'value.Outcome.value', value }]);
		},
		onFromDateChanged: function (date) {
			this.onChange([{ path: 'value.Date.value.start', value: date }]);
		},
		onToDateChanged: function (date) {
			this.onChange([{ path: 'value.Date.value.end', value: date }]);
		},
		getFilterWithValue: function (filter) {
			var newFilter = _.cloneDeep(filter);

			if (newFilter.value) {
				return newFilter;
			} else {
				return _.assign(newFilter, newFilter.generate());
			}
		},
		getFilterValue: function () {
			var filter = this.props.filter;

			return filter.value ? _.cloneDeep(filter.value) : filter.generate().value;
		},
		onChange: function (updates) {
			var props = this.props;
			var newFilter = this.getFilterWithValue(props.filter);

			updates.forEach(function (update) {
				_.set(newFilter, update.path, update.value);
			});

			props.onChange(newFilter, {
				action: 'add'
			});
		},
		classes: function (classes) {
			return _.map(classes, function (expression, classes) {
				return expression ? classes : '';
			}).join(' ');
		},
		render: function () {
			var classes = this.classes;
			var filterValue = this.getFilterValue();
			var filter = this.props.filter;

			var isInactive = filter.value ? filter.isInactive(filter) : true;

			return (
				<div className="history-filter">
					<div className="title">{isPhonecall ? this.lang.phonecall : this.lang.todo}</div>
					<div className="history-filter-row extra-thin-row">
						<div className="flex-btn-group">
							<button
								data-test-id="listfilters-history-activity-inactive"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': isInactive,
									'btn up-btn btn-light-grey no-shadow': !isInactive
								})}
								onClick={() => this.onHasInactive('inactive')}
							>
								{this.lang.all}
							</button>
							<button
								data-test-id="listfilters-history-activity-has"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': filterValue.has && !isInactive,
									'btn up-btn btn-light-grey no-shadow': !filterValue.has || isInactive
								})}
								onClick={() => this.onHasChanged(true)}
							>
								{this.lang.has}
							</button>
							<button
								data-test-id="listfilters-history-activity-hasNot"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': !filterValue.has && !isInactive,
									'btn up-btn btn-light-grey no-shadow': filterValue.has || isInactive
								})}
								onClick={() => this.onHasChanged(false)}
							>
								{this.lang.hasNot}
							</button>
						</div>
					</div>
					<div className="history-filter-row">
						<label className="secondary-title">{this.lang.status}</label>
						<div className="flex-btn-group">
							<button
								data-test-id="listfilters-history-activity-all"
								disabled={isInactive}
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === null,
									'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== null
								})}
								onClick={() => this.onStatusChanged(null)}
							>
								{this.lang.all}
							</button>
							<button
								data-test-id="listfilters-history-activity-open"
								disabled={isInactive}
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === 'open',
									'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== 'open'
								})}
								onClick={() => this.onStatusChanged('open')}
							>
								{this.lang.open}
							</button>
							<button
								data-test-id="listfilters-history-activity-closed"
								disabled={isInactive}
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === 'closed',
									'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== 'closed'
								})}
								onClick={() => this.onStatusChanged('closed')}
							>
								{this.lang.close}
							</button>
						</div>
					</div>
					{isPhonecall ? (
						<div className="history-filter-row">
							<label className="secondary-title">{this.lang.outcome}</label>
							<div className="flex-btn-group">
								<button
									data-test-id="listfilters-history-activity-all"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Outcome.value === null,
										'btn up-btn btn-light-grey no-shadow': filterValue.Outcome.value !== null
									})}
									onClick={() => this.onOutcomeChanged(null)}
								>
									{this.lang.all}
								</button>
								<button
									data-test-id="listfilters-history-activity-open"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Outcome.value === 'reached',
										'btn up-btn btn-light-grey no-shadow': filterValue.Outcome.value !== 'reached'
									})}
									onClick={() => this.onOutcomeChanged('reached')}
								>
									{this.lang.reached}
								</button>
								<button
									data-test-id="listfilters-history-activity-closed"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow':
											filterValue.Outcome.value === 'not_reached',
										'btn up-btn btn-light-grey no-shadow':
											filterValue.Outcome.value !== 'not_reached'
									})}
									onClick={() => this.onOutcomeChanged('not_reached')}
								>
									{this.lang.notReached}
								</button>
							</div>
						</div>
					) : null}

					<div className="history-filter-row">
						<label className="secondary-title">{this.lang.date}</label>
						<div
							className={
								'datepicker-group' +
								(filterValue.Date.value.preset === 'custom' ? ' datepicker-custom-visible' : '')
							}
						>
							<div
								className="datepicker-select-wrap"
								data-test-id="listfilters-history-activity-dateselect"
							>
								<ReactTemplates.INPUTS.upSelect
									key={'datepicker-select'}
									disabled={isInactive}
									data={this.datePresets}
									required={true}
									multiple={false}
									className="datepicker-select"
									onChange={this.onDatePresetChanged}
									defaultValue={filterValue.Date.value.preset}
									matcher={function (term, undef, item) {
										return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
									}}
								/>
							</div>
							{filterValue.Date.value.preset === 'custom' ? (
								<div className="datepicker-wrap" key="datepicker-wrap">
									<div id="datepicker-container">
										<ReactTemplates.upFilters.components.datepicker
											disabled={isInactive}
											placeholder={this.lang.from}
											name={'startDate'}
											className="form-control"
											value={filterValue.Date.value.start}
											onChange={this.onFromDateChanged}
										/>
										<ReactTemplates.upFilters.components.datepicker
											disabled={isInactive}
											placeholder={this.lang.to}
											name={'endDate'}
											className="form-control"
											value={filterValue.Date.value.end}
											onChange={this.onToDateChanged}
										/>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			);
		}
	});

ReactTemplates.upFilters.filterTypes.historyTodoPage = getComponent(false);
ReactTemplates.upFilters.filterTypes.historyPhonecallPage = getComponent(true);

'use strict';

angular.module('upResources').factory('CompanyTypeReport', [
	'$resource',
	'$q',
	'URL',
	'API',
	function Report($resource, $q, URL, API) {
		var Model = {
			customer: function () {
				var instance = {};
				var canceler = $q.defer();

				var Resource = $resource(
					URL + API + 'report/clientCompanyType/',
					{},
					{
						find: { method: 'GET', isArray: false, timeout: canceler.promise }
					}
				);

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);

					return Resource.find(params).$promise;
				};

				instance.cancel = function () {
					canceler.resolve();
				};

				return instance;
			}
		};

		return Model;
	}
]);

import { PrimaryButton } from '@upsales/components/Buttons';
import { Provider } from 'react-redux';
import store from 'Store/index';
import { Text } from '@upsales/components';
import ReportcenterWidgetWithFetch from 'App/pages/Reportcenter/ReportcenterWidgetWithFetch/ReportcenterWidgetWithFetch';

ReactTemplates.segment = {};

ReactTemplates.segment.root = window.ReactCreateClass({
	getStateFromProps: function () {
		return {
			cogOpen: false,
			targetElement: null
		};
	},
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			reachedTheFlowGoal: t('flow.reachedTheFlowGoal'),
			bookedAppointment: t('flow.goal.bookedAppointment'),
			created: t('default.created'),
			by: t('default.of').toLowerCase(),
			contacts: t('default.contacts2'),
			clients: t('default.clients'),
			mailcampaign: t('default.mailing'),
			selection: t('groupMail.selection'),
			edit: t('default.edit'),
			nocontacts: t('segment.sidebar.nocontacts'),
			addselection: t('segment.sidebar.addselection'),
			status: t('default.status'),
			active: t('default.active'),
			inactive: t('default.inactive'),
			change: t('default.change'),
			archived: t('default.archived'),
			createdBy: t('default.createdBy'),
			save: t('default.save'),
			days: t('flow.days'),
			workDays: t('flow.workDays'),
			anyDay: t('flow.anyDay'),
			times: t('flow.times'),
			timezone: t('flow.timezone'),
			mailsAreSent: t('flow.mailsAreSent')
		};
	},
	componentDidMount: function () {
		this.props.renderCallback();
	},
	componentDidUpdate: function () {
		this.props.renderCallback();
	},
	addSelection: function () {
		this.props.filterComponent.addFilter();
	},
	openContactTab: function () {
		this.props.tools.$state.go('segment.contacts');
	},
	editDropdown: function () {
		this.setState({
			cogOpen: !this.state.cogOpen
		});
	},
	render: function () {
		var self = this;
		var segment = self.props.segment;
		var tools = self.props.tools;
		var flow = self.props.flowComponent.flow;

		var createdBy = _.find(Tools.AppService.getUsers(), { id: segment.regBy });
		const formatNumber = value => (typeof value === 'number' ? tools.formatNumber(value) : '');
		const flowHasGoal = !!flow?.endCriterias.find(c => c.isGoal);

		var sidebar = (
			<div id="edit-segment-sidebar">
				<div className="section">
					<div className="section-name" data-test-id="segment-name">
						{segment.name}
					</div>
					<div className="section-description" data-test-id="segment-description">
						{segment.description}
					</div>
					<div className="section-created">
						<span className="title">{self.lang.createdBy}</span>
						<div className="user-holder">
							<div className="user-image">
								<ReactTemplates.TOOLS.avatar
									size={31}
									user={createdBy}
									avatarService={tools.avatarService}
								/>
							</div>
							<div className="user-user">
								<span className="user-name">{createdBy && createdBy.name}</span>
								<span className="creation-date">{moment(segment.createDate).format('L LT')}</span>
							</div>
						</div>
					</div>
					<div className="section-status">
						<span>{self.lang.status}</span>
						<div className="status-text">
							{segment.active ? (
								self.lang.active
							) : (
								<div className="status-holder">
									{self.lang.archived + ' ' + moment(segment.modDate).format('L LT')}
									<span
										id="change-segment-status-sidebar"
										onClick={function () {
											self.editDropdown();
										}}
										className="link"
									>
										{self.lang.change}
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="section">
					<div className="section-selection">
						<span>
							{self.lang.selection + ' '}
							<ReactTemplates.elevio sidebar="true" articleId="401" />
						</span>
						{self.props.totalContacts > 0 && this.props.activeTab !== 'contacts' && (
							<button className="btn btn-link" onClick={self.openContactTab}>
								{self.lang.edit}
							</button>
						)}
					</div>
					{self.props.hasIncludeFilter || segment.version === 2 ? (
						<div className="section-contacts">
							<Text size="xl">{formatNumber(self.props.totalContacts)}</Text>
							<Text size="xl">{formatNumber(self.props.totalClients)}</Text>
							<Text size="sm" color="grey-11">
								{self.lang.contacts.toUpperCase()}
							</Text>
							<Text size="sm" color="grey-11">
								{self.lang.clients.toUpperCase()}
							</Text>
						</div>
					) : (
						<div className="section-nocontacts">
							{self.lang.nocontacts}
							<button className="btn btn-link btn-block" onClick={self.addSelection}>
								{self.lang.addselection}
							</button>
						</div>
					)}
				</div>
				{flow ? (
					<>
						<div className="section">
							<div className="section-selection">
								<span>{self.lang.mailsAreSent}</span>
							</div>
							<div className="section-mail-settings">
								<div>
									<div>
										{self.lang.days}
										<b>{flow.skipWeekends ? self.lang.workDays : self.lang.anyDay}</b>
									</div>
									{flow.startTime && flow.endTime && (
										<div>
											{self.lang.times}
											<b>
												{moment.locale() === 'en'
													? moment(flow.startTime, 'LT').format('hh:mm A') +
													  ' - ' +
													  moment(flow.endTime, 'LT').format('hh:mm A')
													: moment(flow.startTime, 'LT').format('HH:mm') +
													  ' - ' +
													  moment(flow.endTime, 'LT').format('HH:mm')}
											</b>
										</div>
									)}
									{flow.timezone && (
										<div>
											{self.lang.timezone}
											<b>{flow.timezone}</b>
										</div>
									)}
								</div>
							</div>
						</div>
						{flowHasGoal ? (
							<div className="section">
								<div className="section-selection">
									<span>{this.lang.reachedTheFlowGoal}</span>
									<Text size="sm" color="grey-11">
										{this.lang.bookedAppointment}
									</Text>
								</div>
								<ReportcenterWidgetWithFetch
									config={{
										id: 0,
										filters: [{ attribute: 'flow', comparison: 'eq', value: flow.id }],
										legend: false,
										width: 1,
										x: 0,
										goal: false,
										displayType: 'bigNumber',
										groupBy: ['flow'],
										height: 1,
										type: 'FLOW_GOAL_HIT_RATE',
										title: '',
										y: 1
									}}
									type="FLOW_GOAL_HIT_RATE"
								/>
							</div>
						) : null}
					</>
				) : null}
			</div>
		);

		var body = React.createElement(
			ReactTemplates.segment.body,
			Object.assign({}, self.props, {
				cogOpen: self.state.cogOpen,
				editDropdown: self.editDropdown
			})
		);

		var classNames = ['card', 'has-sidebar-sm', 'full-size'];

		if (!segment.active) {
			classNames.push('inactive-segment');
		}

		return (
			<Provider store={store}>
				<div className={classNames.join(' ')} data-test-id="segment-root">
					{sidebar}
					{body}
					<PrimaryButton
						id="segment-save-btn"
						size="lg"
						shadow="high"
						disabled={this.props.listState.loading}
						className={
							this.props.filterSelection.dirty && this.props.activeTab === 'contacts' ? 'visible' : null
						}
						onClick={this.props.actions.saveSegment}
					>
						{this.lang.save}
					</PrimaryButton>
				</div>
			</Provider>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	visible: PropTypes.bool,
	date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	appointment: PropTypes.object,
	onDateChange: PropTypes.func,
	onTimelineClick: PropTypes.func,
	close: PropTypes.func,
	otherAppointments: PropTypes.array,
	users: PropTypes.array,
	conflicts: PropTypes.array
};

class AvailabilityTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hovering: false,
			minimized: false,
			visible: props.visible,
			day: {
				current: moment(props.date).startOf('day'),
				start: moment(props.date),
				end: moment(props.endDate)
			},
			hoverMoment: moment(props.date),
			date: props.date
		};

		this.toggleView = this.toggleView.bind(this);
		this.hoverAreaOut = this.hoverAreaOut.bind(this);
		this.hoverAreaMove = this.hoverAreaMove.bind(this);
		this.hoverAreaOver = this.hoverAreaOver.bind(this);
		this.hoverAreaClick = this.hoverAreaClick.bind(this);
		this.getBlockPosition = this.getBlockPosition.bind(this);
		this.generateTableRows = this.generateTableRows.bind(this);
		this.getBlockAppointment = this.getBlockAppointment.bind(this);

		this.scrollBarWidth = Tools.browserService.scrollbarWidth;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const STATE = Object.assign({}, this.state);

		STATE.day.current = moment(nextProps.date).startOf('day');
		STATE.day.start = moment(nextProps.date);
		STATE.day.end = moment(nextProps.endDate);
		STATE.hoverMoment = moment(nextProps.date);

		this.setState(STATE);
	}

	componentDidMount() {
		const HOVER_INDICATOR = $('.hover-indicator');
		const CURRENT_HOVER_TIME = HOVER_INDICATOR.find('.current-time');
		const APPOINTMENT_START_DATE = moment(this.props.appointment.date);
		const APPOINTMENT_END_DATE = moment(this.props.appointment.endDate);
		const CURRENT_POSITION = this.getBlockPosition(APPOINTMENT_START_DATE, APPOINTMENT_END_DATE);
		const APPOINTMENT_BLOCK_CURRENT = $('.appointment-block.current');

		if (APPOINTMENT_BLOCK_CURRENT.length) {
			const PX_WIDTH = APPOINTMENT_BLOCK_CURRENT[0].offsetWidth;

			if (CURRENT_POSITION.leftPercent > 0.9) {
				CURRENT_HOVER_TIME.addClass('left');
			} else {
				CURRENT_HOVER_TIME.removeClass('left');
			}

			HOVER_INDICATOR.css('left', CURRENT_POSITION.leftPercent);
			HOVER_INDICATOR.find('.appointment-length').css('width', PX_WIDTH + 'px');

			const hoverMoment = this.updateHoverMoment(CURRENT_POSITION.leftPercent);
			CURRENT_HOVER_TIME.text(hoverMoment.format('LT'));
		}
	}

	toggleView() {
		this.setState({
			minimized: !this.state.minimized
		});
	}

	// if direction equals false -> set to previous day
	// if direction equals true -> set to next day
	changeDay(direction) {
		let HOVER_MOMENT = this.state.hoverMoment;

		if (direction) {
			HOVER_MOMENT = HOVER_MOMENT.add(1, 'day');
		} else {
			HOVER_MOMENT = HOVER_MOMENT.subtract(1, 'day');
		}

		this.props.onDateChange(HOVER_MOMENT.toDate());
	}

	generateAvailabilityHours() {
		const returnArray = [];
		const MAX = 24;

		returnArray.push(
			<div key={'hour-first'} className="faded">
				<span />
			</div>
		);
		for (let i = 1; i < MAX; i++) {
			const doubleDigits = i.toString().length === 1 ? '0' + i.toString() : i.toString();
			const div =
				i >= 8 && i <= 17 ? (
					<div key={`hour-${doubleDigits}`}>
						<span>{doubleDigits}</span>
					</div>
				) : (
					<div key={`offhour-${doubleDigits}`} className="faded">
						<span>{doubleDigits}</span>
					</div>
				);
			returnArray.push(div);
		}

		returnArray.push(
			<div key={'hour-last'} className="faded">
				<span />
			</div>
		);
		return returnArray;
	}

	getBlockPosition(start, end) {
		const MINUTES_LENGTH = end.diff(start, 'minutes');
		const MINUTES_START = start.diff(this.state.day.current, 'minutes');
		const MINUTES_PER_DAY = 1440;
		return {
			percent: (MINUTES_LENGTH / MINUTES_PER_DAY) * 100,
			leftPercent: (MINUTES_START / MINUTES_PER_DAY) * 100
		};
	}

	getBlockAppointment(user, otherAppointments) {
		const RETURN_ARRAY = [];
		if (Array.isArray(otherAppointments)) {
			_.each(otherAppointments, appointment => {
				const usersAppointment = _.find(appointment.users, o => {
					return o.id === user.id;
				});

				if (usersAppointment) {
					const START = moment(appointment.date);
					const END = moment(appointment.endDate);
					const POSITION = this.getBlockPosition(START, END);

					RETURN_ARRAY.push(
						<div
							className="appointment-block"
							key={`other-appointment-block-${appointment.id}`}
							style={{
								width: POSITION.percent + '%',
								left: POSITION.leftPercent + '%'
							}}
						/>
					);
				}
			});
		}

		return RETURN_ARRAY;
	}

	generateTableRows(users, conflicts) {
		let CURRENT_POSITION;
		if (this.props.appointment) {
			const APPOINTMENT_START_DATE = moment(this.props.appointment.date);
			const APPOINTMENT_END_DATE = moment(this.props.appointment.endDate);
			CURRENT_POSITION = this.getBlockPosition(APPOINTMENT_START_DATE, APPOINTMENT_END_DATE);
		}

		return _.map(users, user => {
			const OTHER_APPOINTMENTS = this.props.otherAppointments
				? this.getBlockAppointment(user, this.props.otherAppointments)
				: [];

			let USERS = [];
			_.each(conflicts, item => {
				USERS = USERS.concat(item.users);
			});

			const foundConflict = _.find(USERS, co => co && co.id === user.id);
			const CURRENT_BLOCK = CURRENT_POSITION ? (
				<div
					className={'appointment-block current' + (foundConflict ? ' warn' : '')}
					style={{
						width: CURRENT_POSITION.percent + '%',
						left: CURRENT_POSITION.leftPercent + '%'
					}}
				>
					{foundConflict ? <i className="fa fa-exclamation-triangle" /> : null}
				</div>
			) : null;

			if (foundConflict) {
				return (
					<tr key={user.name} className="user-row warning">
						<td className="name-col">
							<i className="fa fa-exclamation-triangle" />
							<span className="user-name">{user.name}</span>
							<span className="subtitle">
								<i className="fa fa-exclamation-sign" />
							</span>
						</td>
						<td className="appointments">
							{OTHER_APPOINTMENTS}
							{CURRENT_BLOCK}
						</td>
					</tr>
				);
			}

			return (
				<tr key={user.name} className="user-row">
					<td className="name-col">
						<span className="user-name">{user.name}</span>
						<span className="subtitle" />
					</td>
					<td className="appointments">
						{OTHER_APPOINTMENTS}
						{CURRENT_BLOCK}
					</td>
				</tr>
			);
		});
	}

	hoverAreaOver() {
		this.setState({
			hovering: true,
			hoverAreaWidth: $('.hover-area').width(),
			heightElem: $('.availability-main')[0].scrollHeight
		});
	}

	updateHoverMoment(percent) {
		let hoverMoment = this.state.hoverMoment;
		let dayMinutes = 1440 * percent;
		dayMinutes = Math.round(dayMinutes / 15) * 15;

		hoverMoment = hoverMoment.startOf('day').minutes(dayMinutes);
		this.setState({
			hoverMoment: hoverMoment
		});

		return hoverMoment;
	}

	hoverAreaMove(evt) {
		const HOVER_INDICATOR = $('.hover-indicator');
		const CURRENT_HOVER_TIME = HOVER_INDICATOR.find('.current-time');
		const PERCENT = evt.nativeEvent.offsetX / this.state.hoverAreaWidth;
		const PX_WIDTH = document.querySelector('.appointment-block.current').offsetWidth;

		if (PERCENT > 0.9) {
			CURRENT_HOVER_TIME.addClass('left');
		} else {
			CURRENT_HOVER_TIME.removeClass('left');
		}

		HOVER_INDICATOR.css('left', evt.nativeEvent.offsetX || 0);
		HOVER_INDICATOR.find('.appointment-length').css('width', PX_WIDTH + 'px');

		const hoverMoment = this.updateHoverMoment(PERCENT);
		CURRENT_HOVER_TIME.text(hoverMoment.format('LT'));
	}

	hoverAreaClick(evt) {
		const PERCENT = evt.nativeEvent.offsetX / this.state.hoverAreaWidth;
		const hoverMoment = this.updateHoverMoment(PERCENT);

		this.props.onTimelineClick(hoverMoment.clone().format());
	}

	hoverAreaOut() {
		this.setState({
			hovering: false
		});
	}

	render() {
		const { visible, users, conflicts, close } = this.props;
		const { hoverMoment, hovering, heightElem } = this.state;
		const rowHeight = 34;

		return (
			<div
				className={`up-availability-table ${visible ? 'partly-visible' : ''}`}
				style={{
					height: `${rowHeight * users.length + 60}px`
				}}
			>
				<div className="availability-header">
					<div className="left">
						<h2>{Tools.$translate('appointment.availability')}</h2>
						<button onClick={close} className="up-btn btn-bright-blue btn-link btn btn-sm">
							{Tools.$translate('default.close')}
						</button>
					</div>
					<div className="right">
						<button onClick={() => this.changeDay(false)}>
							<b className="fa fa-chevron-left" />
						</button>

						<h2>{hoverMoment.format('dddd D MMMM YYYY')}</h2>

						<button onClick={() => this.changeDay(true)}>
							<b className="fa fa-chevron-right" />
						</button>

						<div style={{ right: this.scrollBarWidth + 'px' }} className="availability-hours">
							{this.generateAvailabilityHours()}
						</div>
					</div>
				</div>
				<div style={{ right: this.scrollBarWidth + 'px' }} className="availability-hover-area-wrap">
					<div className={`hover-indicator ${hovering ? 'visible' : ''}`}>
						<div className="current-time" />
						<div className="appointment-length" />
					</div>

					<div
						className="hover-area"
						onMouseOver={this.hoverAreaOver}
						onMouseMove={this.hoverAreaMove}
						onMouseOut={this.hoverAreaOut}
						onClick={this.hoverAreaClick}
					>
						<div style={{ height: heightElem }} className="height-elem" />
					</div>
				</div>
				<div className="availability-main">
					<table>
						<tbody>{this.generateTableRows(users, conflicts)}</tbody>
					</table>
				</div>
			</div>
		);
	}
}

AvailabilityTable.propTypes = propTypes;
export default AvailabilityTable;

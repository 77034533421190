'use strict';

angular.module('domain.admin').controller('DeleteLogCtrl', [
	'AppService',
	'$scope',
	'DeleteLog',
	'RequestBuilder',
	'$q',
	function (AppService, $scope, DeleteLog, RequestBuilder, $q) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		var searchTimeout;
		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'deleteLog';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			log: [],
			search: '',
			page: 1,
			searching: false,
			activePage: 1,
			totalPages: 0,
			fromDate: null,
			toDate: null,
			type: null,
			user: null,
			customerId: AppService.getCustomerId(),
			limit: 50,
			total: 0,
			sort: 'date',
			desc: false
		};

		AdminSubCtrl.rootData.getLog = function (instant) {
			AdminSubCtrl.rootData.pageData.searching = true;

			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}

			var defered = $q.defer();

			searchTimeout = setTimeout(
				function () {
					var rb = new RequestBuilder();
					rb.addSort(
						DeleteLog.attr[AdminSubCtrl.rootData.pageData.sort],
						AdminSubCtrl.rootData.pageData.desc
					);
					rb.limit = AdminSubCtrl.rootData.pageData.limit;
					rb.offset = (AdminSubCtrl.rootData.pageData.activePage - 1) * AdminSubCtrl.rootData.pageData.limit;
					if (AdminSubCtrl.rootData.pageData.search && AdminSubCtrl.rootData.pageData.search.length) {
						rb.addFilter(
							DeleteLog.attr.obj,
							rb.comparisonTypes.Equals,
							AdminSubCtrl.rootData.pageData.search
						);
					}

					if (_.get(AdminSubCtrl.rootData.pageData, 'user.email')) {
						rb.addFilter(
							DeleteLog.attr.user,
							rb.comparisonTypes.Equals,
							_.get(AdminSubCtrl.rootData.pageData, 'user.email')
						);
					}

					if (_.get(AdminSubCtrl.rootData.pageData, 'type.type')) {
						rb.addFilter(
							DeleteLog.attr.type,
							rb.comparisonTypes.Equals,
							_.get(AdminSubCtrl.rootData.pageData, 'type.type')
						);
					}

					if (AdminSubCtrl.rootData.pageData.fromDate) {
						rb.addFilter(
							DeleteLog.attr.date,
							rb.comparisonTypes.GreaterThanEquals,
							moment(AdminSubCtrl.rootData.pageData.fromDate).startOf('day').toDate()
						);
					}

					if (AdminSubCtrl.rootData.pageData.toDate) {
						rb.addFilter(
							DeleteLog.attr.date,
							rb.comparisonTypes.LessThanEquals,
							moment(AdminSubCtrl.rootData.pageData.toDate).endOf('day').toDate()
						);
					}

					return DeleteLog.find(rb.build())
						.then(function (result) {
							AdminSubCtrl.rootData.pageData.log = result.data;
							AdminSubCtrl.rootData.pageData.totalPages = Math.ceil(
								result.metadata.total / AdminSubCtrl.rootData.pageData.limit
							);
							AdminSubCtrl.rootData.pageData.searching = false;
							AdminSubCtrl.rootData.pageLoading = false;
							AdminSubCtrl.rootData.pageData.total = result.metadata.total;
							defered.resolve();
						})
						.catch(function (e) {
							defered.reject(e);
						});
				},
				instant ? 0 : 300
			);

			return defered.promise;
		};

		AdminSubCtrl.rootData.setSort = function (sort, desc) {
			AdminSubCtrl.rootData.pageData.sort = sort;
			AdminSubCtrl.rootData.pageData.desc = desc;
			AdminSubCtrl.rootData.pageData.activePage = 1;
			AdminSubCtrl.rootData.getLog(true);
		};

		AdminSubCtrl.rootData.setUser = function (user) {
			AdminSubCtrl.rootData.pageData.user = user;
			AdminSubCtrl.rootData.onChangePage(true, 1);
		};

		AdminSubCtrl.rootData.setType = function (type) {
			AdminSubCtrl.rootData.pageData.type = type;
			AdminSubCtrl.rootData.onChangePage(true, 1);
		};

		AdminSubCtrl.rootData.setFromDate = function (date) {
			// If fromDate is after toDate we push toDate to new fromDate
			if (
				AdminSubCtrl.rootData.pageData.toDate &&
				moment(date).isAfter(AdminSubCtrl.rootData.pageData.toDate, 'day')
			) {
				AdminSubCtrl.rootData.pageData.toDate = date;
			}

			AdminSubCtrl.rootData.pageData.fromDate = date;
			AdminSubCtrl.rootData.onChangePage(true, 1);
		};

		AdminSubCtrl.rootData.setToDate = function (date) {
			// If fromDate is before toDate we push fromDate to new toDate
			if (
				AdminSubCtrl.rootData.pageData.fromDate &&
				moment(date).isBefore(AdminSubCtrl.rootData.pageData.fromDate, 'day')
			) {
				AdminSubCtrl.rootData.pageData.fromDate = date;
			}
			AdminSubCtrl.rootData.pageData.toDate = date;
			AdminSubCtrl.rootData.onChangePage(true, 1);
		};

		AdminSubCtrl.rootData.onChangePage = function (instant, page) {
			AdminSubCtrl.rootData.pageData.activePage = page;
			AdminSubCtrl.rootData.getLog(instant);
		};

		AdminSubCtrl.rootData.onSearch = function () {
			AdminSubCtrl.rootData.pageData.activePage = 1;
			AdminSubCtrl.rootData.getLog(false);
		};

		var init = function () {
			AdminSubCtrl.rootData.pageData.users = _.filter(AppService.getActiveUsers(), function (user) {
				return user.email !== undefined && user.email !== '';
			});

			AdminSubCtrl.rootData.getLog();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import history from 'App/pages/routes/history';
import PropTypes from 'prop-types';
import OptOutLangSection from './OptOutLangSection';
import UpCountry from '../Inputs/UpCountry';
import './OptOutModal.scss';
import { ModalHeader, ModalControls, ModalContent, Toggle, Label, Block, Icon, Text, Link } from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

class OptOutModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			isCurrentlyActive: props.optOut.active
		};

		this.lang = {
			editOptOutCategory: T('admin.editOptOutCategory'),
			save: T('default.save'),
			abort: T('default.abort'),
			addLanguage: T('default.addLanguage'),
			selectLanguage: T('default.selectLanguage'),
			se: T('language.swedish'),
			no: T('language.norweigan'),
			fi: T('language.finish'),
			dk: T('language.danish'),
			de: T('language.german'),
			en: T('language.english'),
			nl: T('language.netherlands'),
			pl: T('language.polish'),
			pt: T('language.portuguese'),
			es: T('language.spanish'),
			jp: T('language.japanese'),
			cn: T('language.chinese'),
			active: T('default.active'),
			warning: T('admin.editOptOutCategory.warning'),
			seeList: T('admin.editOptOutCategory.seeList'),
			mailCampaigns: T('default.mailings'),
			flows: T('default.flows')
		};
	}

	render() {
		const classes = new BemClass('OptOutModal');
		const flagFormat = (country, container, escape) => {
			return (
				'<span><i style="margin-right: 5px;" class="flag-icon flag-icon-' +
				escape(country.code).toLowerCase() +
				'"></i>' +
				escape(country.translated) +
				'</span>'
			);
		};

		const upCountryProps = {
			formatSelection: flagFormat,
			formatResult: (obj, container, query, escape) => flagFormat(obj, container, escape),
			countryCodes: this.props.availableLanguages,
			isLanguage: true,
			placeholder: this.lang.selectLanguage,
			updateOnDataChange: true
		};

		const canDeactivate =
			this.props.optOut.relatedMailCampaigns?.length > 0 &&
			this.props.optOut.active &&
			this.state.isCurrentlyActive;
		const usedInFlows = [];
		let moreFlows = 0;
		let moreMailCampaigns = 0;
		const usedInMailCampaigns = [];
		(this.props.optOut.relatedMailCampaigns || []).forEach(item => {
			if (item.entity === 'MailCampaign') {
				if (usedInMailCampaigns.length > 4) {
					moreMailCampaigns++;
				} else {
					if (usedInMailCampaigns.length > 0) {
						usedInMailCampaigns.push(', ');
					}
					usedInMailCampaigns.push(
						<Link
							key={item.value.id}
							onClick={() => history.push(`/group-mail-editor/${item.value.id}/settings`)}
						>
							{item.value.name}
						</Link>
					);
				}
			} else {
				if (usedInFlows.length > 4) {
					moreFlows++;
				} else {
					if (usedInFlows.length > 0) {
						usedInFlows.push(', ');
					}
					usedInFlows.push(
						<Link
							key={item.value.id}
							href={
								item.value.segmentId
									? `#/${Tools.AppService.getCustomerId()}/segments/${item.value.segmentId}/flow/`
									: `#/${Tools.AppService.getCustomerId()}/flows/${item.value.id}/`
							}
						>
							{item.value.name}
						</Link>
					);
				}
			}
		});

		const languages = (this.props.optOut.languages || []).map(langObj => {
			const languageName = this.lang[langObj.language.toLowerCase()];
			return (
				<OptOutLangSection
					key={langObj.language}
					languageName={languageName}
					title={langObj.title}
					description={langObj.description}
					invalidTitle={langObj.$invalidTitle}
					invalidDescription={langObj.$invalidDescription}
					onChange={this.props.actions.onLanguageChange.bind(null, langObj.language)}
					onRemove={this.props.actions.onLanguageRemove.bind(null, langObj.language)}
				/>
			);
		});

		return (
			<div className={classes.b()}>
				<ModalHeader
					title={this.lang.editOptOutCategory}
					icon="edit"
					onClose={this.props.actions.onClose}
					articleId={549}
				/>

				<ModalContent className="ModalContent--no-padding">
					<OptOutLangSection
						default={true}
						languageName={this.lang.en}
						title={this.props.optOut.title}
						description={this.props.optOut.description}
						invalidTitle={this.props.optOut.$invalidTitle}
						invalidDescription={this.props.optOut.$invalidDescription}
						onChange={this.props.actions.onLanguageChange.bind(null, 'en')}
					/>

					{languages}

					{this.props.availableLanguages.length ? (
						<div className={classes.elem('add-language').b()}>
							<div>{this.lang.addLanguage}</div>
							<UpCountry
								value={null}
								onChange={this.props.actions.onLanguageAdd}
								options={upCountryProps}
								className={classes.elem('add-language-select').b()}
							/>
						</div>
					) : null}
					<div className={classes.elem('add-active').b()}>
						<Label size="sm">{this.lang.active}</Label>
						<Toggle
							checked={this.props.optOut.active}
							color="bright-blue"
							onChange={this.props.actions.activeChanged}
							disabled={canDeactivate}
						/>
						{canDeactivate && this.state.isCurrentlyActive ? (
							<>
								<Block className={classes.elem('warning').b()}>
									<Icon name="warning" />
									<Text>{this.lang.warning}</Text>
								</Block>
								<Link
									onClick={() => {
										this.setState({ open: !this.state.open }, () =>
											this.props.actions.reloadModalPosition()
										);
									}}
								>
									{this.lang.seeList}
									{this.state.open ? (
										<Icon name="angle-up" space="mlm" />
									) : (
										<Icon name="angle-down" space="mlm" />
									)}
								</Link>
								{this.state.open ? (
									<Block className={classes.elem('related-content')}>
										{usedInMailCampaigns.length > 0 ? (
											<>
												<Text>{`${this.lang.mailCampaigns}:`}</Text>
												{usedInMailCampaigns}
												{moreMailCampaigns > 0
													? T('admin.editOptOutCategory.listMailCampaigns', {
															count: moreMailCampaigns
													  })
													: '.'}{' '}
											</>
										) : null}
										{usedInFlows.length > 0 ? (
											<>
												<Text>{`${this.lang.flows}:`}</Text>
												{usedInFlows}
												{moreFlows > 0
													? T('admin.editOptOutCategory.listMailCampaigns', {
															count: moreFlows
													  })
													: '.'}{' '}
											</>
										) : null}
									</Block>
								) : null}
							</>
						) : null}
					</div>
				</ModalContent>

				<ModalControls>
					<PrimaryButton loading={this.props.loading} onClick={this.props.actions.onSave}>
						{this.lang.save}
					</PrimaryButton>
					<ThirdButton onClick={this.props.actions.onClose}>{this.lang.abort}</ThirdButton>
				</ModalControls>
			</div>
		);
	}
}

OptOutModal.propTypes = {
	actions: PropTypes.object.isRequired,
	optOut: PropTypes.object.isRequired,
	onLanguageChange: PropTypes.func,
	availableLanguages: PropTypes.array,
	loading: PropTypes.bool
};

OptOutModal.defaultProps = {
	availableLanguages: []
};

export default OptOutModal;

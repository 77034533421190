import Resource from './Resource';
import Attributes from 'Attributes/ListViewAttributes';

const handleDataArray = (parser, res) => {
	return { ...res.data, data: res.data.data.map(parser) };
};

class ListViews extends Resource {
	constructor() {
		super('listViews', Attributes);
	}

	getShared() {
		return this._getRequest('all/', { methodName: 'getShared' }).then(
			handleDataArray.bind(this, this._parse.bind(this))
		);
	}
}

const resource = new ListViews();
window.Tools.ListViews = resource;
export default resource;

import { Title, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect } from 'react';
import CommentResource from 'Resources/Comment';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Comment from 'App/resources/Model/Comment';
import './DashboardPinnedComments.scss';
import T from 'Components/Helpers/translate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import TimelineGenericRender, { TimelineConfig } from 'Components/TimelineRow/TimelineGeneric/TimelineCardConfigs';

interface Props {
	clientId: number;
	contactId?: number;
}
const DashboardPinnedComments = ({ clientId, contactId }: Props) => {
	const [comments, setComments] = React.useState([]);
	const EventService = React.useMemo(() => getAngularModule('EventService'), []);
	useEffect(() => {
		// BUG: The user on comments in CommentResource is simplified, and is missing information needed for avatar,
		// so user avatars do not work in this component
		const fetchPinnedComments = async () => {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'isPinned' }, comparisonTypes.Equals, 1);
			rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
			if (contactId) {
				rb.addFilter({ field: 'contact.id' }, comparisonTypes.Equals, contactId);
			} else {
				//don't show pinned comments on contacts in company card
				rb.addFilter({ field: 'contact.id' }, comparisonTypes.Equals, null);
			}
			rb.addSort({ field: 'regDate' }, false);
			const { data: fetchedComments } = await CommentResource.find(rb.build());
			setComments(fetchedComments);
		};
		fetchPinnedComments();
		const unlistenCommentUpdate = Tools.$rootScope.$on('comment.updated', fetchPinnedComments);
		const unlistenCommentDelete = Tools.$rootScope.$on('comment.deleted', fetchPinnedComments);
		const unlistenClientMerge = Tools.$rootScope.$on('account.merged', fetchPinnedComments);
		const unlistenContacttMerge = Tools.$rootScope.$on('contact.merged', fetchPinnedComments);

		return () => {
			unlistenCommentUpdate();
			unlistenCommentDelete();
			unlistenClientMerge();
			unlistenContacttMerge();
		};
	}, [clientId, contactId]);
	const classes = new BemClass('DashboardPinnedComments', 'timeline');
	if (!comments.length) {
		return null;
	}
	return (
		<div className={classes.b()}>
			<table key={comments.length} id="card-timeline" className="timeline">
				<tbody>
					<tr>
						<td className={classes.elem('first-td').b()}>
							<div className="HistoryLog__row">
								<div className={'HistoryLog__icon-wrapper'}>
									<div className="HistoryLog__icon" />
									<div className="HistoryLog__line" />
								</div>
							</div>
						</td>
						<td>
							<Title className={classes.elem('title').b()} size="lg">
								{T('pinnedComments.title')}
							</Title>
						</td>
					</tr>
					{comments.map((c: Comment, i) => (
						<tr key={i}>
							<td className="icon-td line-break">
								<div className="icon comment">
									<Icon name="comment" />
								</div>
								<div className="line"></div>
							</td>
							<td>
								<div className="details-table-arrow" />{' '}
								<TimelineGenericRender
									type={TimelineConfig.Comment}
									event={EventService.create.Comment(c)}
									hasAvatar
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
export default DashboardPinnedComments;

'use strict';

angular.module('upResources').service('ParseContact', [
	'AppService',
	'ParseHelpers',
	function (AppService, ParseHelpers) {
		var categoryTypes = null;
		var categories = null;

		return function (contact) {
			if (!categoryTypes) {
				categoryTypes = AppService.getCategoryTypes('contact');
			}
			if (!categories) {
				categories = AppService.getCategories('contact');
			}

			ParseHelpers.parseCategories(contact, categories, categoryTypes);

			return contact;
		};
	}
]);

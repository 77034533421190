import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import UpSelect from 'Components/Inputs/UpSelect';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';
import { Text, Button, Card, Icon, Title } from '@upsales/components';
import PropTypes from 'prop-types';
import './MergeWithBoardmember.scss';
class MergeWithBoardmember extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			cancel: t('cancel'),
			dataIncludes: t('prospecting.mergeWithContact.dataIncludes'),
			into: t('prospecting.mergeWithContact.into'),
			mergeFrom: t('prospecting.mergeWithContact.mergeFrom'),
			mergeWithContact: t('prospecting.mergeWithContact.mergeWithContact'),
			mergeData: t('prospecting.mergeWithContact.mergeData'),
			selectFromCompany: t('prospecting.mergeWithContact.selectFromCompany', { company: props.accountName })
		};
		this.state = { contact: null };
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	async updateContact() {
		const { director, addIdToAddedContacts, reject } = this.props;
		const { Contact } = Tools;
		this.cancelablePromise = makeCancelable(Contact.get(this.state.contact.id));
		return this.cancelablePromise.promise
			.then(res => {
				const contact = res.data;
				contact.ssn = director.prospectingId;

				if (director.firstName) {
					contact.firstName = director.firstName;
				}
				if (director.lastName) {
					contact.lastName = director.lastName;
				}
				if (director.roles) {
					contact.title = director.roles.join(', ');
				}
				Contact.customer(Tools.AppService.getCustomerId())
					.save(contact, { usingFirstnameLastname: true })
					.then(res => {
						addIdToAddedContacts(director.prospectingId, res.data.id);
						reject();
					})
					.catch(error => logError(error, 'Failed to update contact'));
			})
			.catch(error => {
				if (
					!error.isCanceled &&
					/* You get an SynteticEvent if you cancel with the x button */ !error.isDefaultPrevented
				) {
					console.error(error);
				}
			});
	}

	async fetchData(filter, limit, offset) {
		const { Contact, RequestBuilder } = Tools;
		const rb = new RequestBuilder();
		rb.addFilter(Contact.attr.name, rb.comparisonTypes.Search, filter);
		rb.addFilter(Contact.attr.id, rb.comparisonTypes.NotEquals, this.props.id);
		rb.addFilter(Contact.attr.client.attr.id, rb.comparisonTypes.Equals, this.props.accountId);
		rb.addSort(Contact.attr.name, true);
		rb.offset = offset;
		rb.limit = limit;
		return Contact.customer(this.props.accountId)
			.find(rb.build())
			.catch(e => logError(e, 'Failed fetch contacts'));
	}

	render() {
		const classNames = new BemClass('MergeWithBoardmember');
		const limit = 50;
		return (
			<div className={classNames.b()}>
				<Card className={classNames.elem('topLabel').b()}>
					<Icon className={classNames.elem('topLabel').elem('merge').b()} name="merge"></Icon>
					<Title size="md">{this.lang.mergeWithContact}</Title>
					<Button onClick={() => this.props.reject()} color="grey" type="link">
						<Icon className={classNames.elem('topLabel').elem('cancel').b()} name="times"></Icon>
					</Button>
				</Card>
				<div className={classNames.elem('input').b()}>
					<div className={classNames.elem('description').b()}>
						<Text>{this.lang.mergeFrom}</Text>
						<Text bold className={classNames.elem('description').elem('name').b()}>
							{this.props.director.name}
						</Text>
						<Text>{this.lang.into}</Text>
					</div>
					<UpSelect
						className={classNames.elem('up-select').b()}
						onChange={e => this.setState({ contact: e.target.added })}
						placeholder={this.lang.selectFromCompany}
						options={{
							ajax: {
								data: (term, page) => ({ term, page }),
								results: r => r,
								transport: query => {
									this.fetchData(query.data.term, limit, (query.data.page - 1) * limit)
										.then(results => {
											query.success({
												results: results.data,
												more:
													results.metadata.offset + results.metadata.limit <
													results.metadata.total
											});
										})
										.catch(query.failure);
								}
							}
						}}
					/>
					<Text color="grey-11" italic className={classNames.elem('info').b()}>
						{this.lang.dataIncludes}
					</Text>
				</div>
				<Card className={classNames.elem('bottomLabel').b()}>
					<Button
						color={this.state.contact ? 'bright-blue' : 'light-grey'}
						text={this.lang.mergeData}
						onClick={() => this.updateContact()}
						disabled={!this.state.contact}
					/>
					<Button color="grey" text={this.lang.cancel} type="link" onClick={() => this.props.reject()} />
				</Card>
			</div>
		);
	}
}
MergeWithBoardmember.propTypes = {
	director: PropTypes.object.isRequired,
	reject: PropTypes.func,
	accountId: PropTypes.number,
	accountName: PropTypes.string,
	id: PropTypes.number,
	addIdToAddedContacts: PropTypes.func,
	required: PropTypes.any,
	multiple: PropTypes.any,
	formatResult: PropTypes.any,
	formatSelection: PropTypes.any,
	options: PropTypes.any
};
export default MergeWithBoardmember;

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startEditColumn, stopEditColumn } from 'Store/reducers/AdminEditSalesboardReducer';

import EditSalesboardColumn from './EditSalesboardColumn';
import PreviewSalesboardColumn from './PreviewSalesboardColumn';
import DragHandle from './DragHandle';

import './SalesboardColumn.scss';

const mapStateToProps = state => ({ openColumns: state.AdminEditSalesboard.openColumns });
const mapDispatchToProps = { startEditColumn, stopEditColumn };

class SalesboardColumn extends React.Component {
	render() {
		const { startEditColumn, stopEditColumn, column, openColumns, isDragging } = this.props;

		const isInEditMode = openColumns[column.$id] ? true : false;
		const Component = isInEditMode ? EditSalesboardColumn : PreviewSalesboardColumn;
		const AdminSalesboardColumnBem = new Bem('AdminSalesboardColumn');

		return (
			<div className={AdminSalesboardColumnBem.mod({ isDragging }).b()}>
				<DragHandle />
				<Component key={column.$id} column={column} startEdit={startEditColumn} stopEdit={stopEditColumn} />
			</div>
		);
	}
}

SalesboardColumn.propTypes = {
	column: PropTypes.object,
	openColumns: PropTypes.object,
	startEditColumn: PropTypes.func,
	stopEditColumn: PropTypes.func,
	isDragging: PropTypes.bool
};

export const detached = SalesboardColumn;
const Component = connect(mapStateToProps, mapDispatchToProps)(SalesboardColumn);
export default Component;

'use strict';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';

angular.module('services.geocode', []).factory('GeocodeService', [
	'$q',
	function ($q) {
		const instance = {};
		let geocoder = null;

		instance.getCoordinates = (address, country, type) => {
			const deferred = $q.defer();

			loader
				.load()
				.then(() => {
					if (!geocoder) {
						geocoder = new google.maps.Geocoder();
					}

					geocoder.geocode(
						{
							address,
							componentRestrictions: {
								country
							}
						},
						(results, status) => {
							if (status === google.maps.GeocoderStatus.OK) {
								return deferred.resolve({
									lat: results[0].geometry.location.lat(),
									lng: results[0].geometry.location.lng(),
									found: true,
									type
								});
							}

							if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
								return deferred.resolve({
									lat: 62.1983366,
									lng: 17.5671981,
									found: false,
									type
								});
							}

							return deferred.reject({ err: status });
						}
					);
				})
				.catch(e => {
					deferred.reject({ err: e });
					logError(e, 'Failed to load Google');
				});

			return deferred.promise;
		};

		return instance;
	}
]);

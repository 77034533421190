import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column, Link } from '@upsales/components';

import t from 'Components/Helpers/translate';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerInfoProgress from '../Components/DrawerInfoProgress';
import DrawerInfoSnooze from '../Components/DrawerInfoSnooze';
import StepContactTable from '../Components/StepContactTable';
import StepEmailSubject from '../Components/StepEmailSubject';
import StepDrawer from '../StepDrawer';

const COLOR = 'medium-turcose';

export default function ClickedMail(props) {
	const stats = props.popup.step.stats;
	const getStatsPercentage = num =>
		stats.hasCompletedStepUnique ? Math.floor((num / stats.hasCompletedStepUnique) * 100) : 0;
	const clamp = (num, max = Infinity, min = -Infinity) => Math.max(Math.min(num, max), min);
	const hasLink = !!props.popup.step.link;
	const clickedField = hasLink ? 'clickedSpecificLinkInTime' : 'clickedAnyLinkInTime';
	const notClickedField = hasLink ? 'notClickedSpecificLinkInTime' : 'notClickedAnyLinkInTime';
	const statsArray = [
		{
			color: COLOR,
			num: stats[clickedField],
			format: num => `${clamp(getStatsPercentage(num), 100, 0)}% ${t('mail.clicked')}`
		},
		{
			color: null,
			num: stats[notClickedField],
			format: num => `${clamp(getStatsPercentage(num), 100, 0)}% ${t('flow.didNotClick')}`
		}
	];

	return (
		<StepDrawer
			{...props}
			title={t('flow.behavior')}
			subtitle={t('flow.clickedMail')}
			color={COLOR}
			icon="mouse-pointer"
		>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<StepEmailSubject title={props.name || t('mail.mailings')} />
				<Row>
					<Column fixedWidth={90}>
						<DrawerInfoProgress
							total={Math.max(stats.hasCompletedStepUnique, stats[clickedField])}
							statsArray={statsArray}
						/>
						{props.popup.step.mailCampaignId ? (
							<Link
								href={props.getHref('mailCampaign.dashboard', { id: props.popup.step.mailCampaignId })}
							>
								{t('flow.showMailCampaign')}
							</Link>
						) : null}
					</Column>
					<Column>
						<DrawerInfoTitle
							total={Math.max(stats.hasCompletedStepUnique, stats[clickedField])}
							title={'flow.contactsPassedThisStep'}
							statsArray={statsArray}
						/>
					</Column>
				</Row>
				<DrawerInfoSnooze step={props.popup.step} />
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={stats}
				types={['currentlyInStep', 'fallenOutOfStep', clickedField, notClickedField]}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

ClickedMail.propTypes = {
	getHref: PropTypes.func.isRequired,
	popup: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired,
	name: PropTypes.string
};

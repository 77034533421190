import OnboardingCompletedResource from '../../resources/OnboardingCompleted';

const OnboardingHelpers = {
	setEntireOnboardingDone: onboardingCompletedId => {
		const metadata = Object.assign({}, Tools.AppService.getMetadata());
		metadata.onboarding = [];

		Tools.AppService.setMetadata(metadata);

		/* yes i dont wait for the request here, dont think it is needed */
		OnboardingCompletedResource.save({ id: onboardingCompletedId, hasCompleted: 1, completedDate: new Date() });

		Tools.$rootScope.$broadcast('onboarding.completed', {});
	},
	setMetaDataCompletedStep: stepId => {
		const metadata = Object.assign({}, Tools.AppService.getMetadata());
		let metadataHasChanged = false;

		stepId = parseInt(stepId);

		metadata.onboarding = metadata.onboarding.map(onboarding => {
			onboarding.steps = onboarding.steps.map(step => {
				if (step.id === stepId) {
					step.completed = true;
					metadataHasChanged = true;

					return step;
				}

				if (step.hasSubSteps) {
					step.substeps.map(substep => {
						if (substep.id === stepId) {
							substep.completed = true;
							metadataHasChanged = true;
						}
						return substep;
					});
				}

				return step;
			});

			return onboarding;
		});

		if (metadataHasChanged) {
			Tools.AppService.setMetadata(metadata);
			// Well yes technically it is not complete here but i will use it anyways!
			Tools.$rootScope.$broadcast('onboarding.completed', {});
		}
	},
	updateOnboardingCompletedStep: (stepId, onboardingObject) => {
		onboardingObject.steps.forEach(step => {
			if (step.hasSubSteps) {
				step.substeps.forEach(substep => {
					if (substep.id === stepId) {
						substep.completed = true;
					}
				});
			}

			if (step.id === stepId) {
				step.completed = true;
			}
		});

		return onboardingObject;
	}
};

Tools.OnboardingHelpers = OnboardingHelpers;
export default OnboardingHelpers;

import { Block, Text, Link } from '@upsales/components';
import React from 'react';
import FlowStepBase, { getSnoozeUnitText } from '../FlowStepBase';
import T from 'Components/Helpers/translate';
import { FlowStep } from 'App/resources/Model/Flow';
import BemClass from '@upsales/components/Utils/bemClass';
import './FlowStepFormSubmit.scss';

type Props = Pick<React.ComponentProps<typeof FlowStepBase>, 'onDelete' | 'onClick' | 'editable'> & {
	step: FlowStep;
};

export const MAX_HEIGHT = 100;

const FlowStepFormSubmit = ({ step, ...props }: Props) => {
	const classes = new BemClass('FlowStepFormSubmit');
	return (
		<FlowStepBase
			icon="form"
			backgroundColor="white"
			borderColor="bright-green"
			title={T('flow.step.formSubmit.title')}
			maxHeight={MAX_HEIGHT}
			className={classes.b()}
			isSplit
			isInvalid={!step.formId}
			{...props}
		>
			<Text className={classes.elem('formName').b()} color={!step.formId ? 'grey-11' : 'black'}>
				{step.form?.name ? (
					step.form.name
				) : props.editable ? (
					<Link>{T('segment.chooseForm')}</Link>
				) : (
					T('flow.noForm')
				)}
			</Text>
			{step.waitUnit && step.waitTime ? (
				<Block space="mts">
					<Text size="sm">
						{T('flow.step.formSubmit.snooze', {
							num: step.waitTime,
							unit: getSnoozeUnitText(step.waitTime, step.waitUnit)?.toLowerCase()
						})}
					</Text>
				</Block>
			) : null}
		</FlowStepBase>
	);
};

export default FlowStepFormSubmit;

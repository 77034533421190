import { PromoButton } from 'App/components/EditSubscription/SubscriptionPromo/SubscriptionPromo';
import { DropDownMenu, Icon, Text, Toggle } from '@upsales/components';
import { ClientIdNamePriceList } from 'App/resources/Model/Client';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { PrimaryButton } from '@upsales/components/Buttons';
import { ContactIdName } from 'App/resources/Model/Contact';
import BemClass from '@upsales/components/Utils/bemClass';
import { OldOrNewAgreement } from '../SubscriptionCards';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import moment from 'moment';
import React from 'react';

import './CardsHeader.scss';

type Props = {
	showInactive: boolean;
	setShowInactive: (value: boolean | ((prevValue: boolean) => boolean)) => void;
	client: ClientIdNamePriceList;
	contact?: ContactIdName;
	agreementGroups: OldOrNewAgreement[];
	hasMRR: boolean;
};

const createSubscription = (client: ClientIdNamePriceList) => {
	if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
		const options = { accountId: client.id, customerId: Tools.AppService.getCustomerId() };
		return Tools.$upModal.open('editAgreement', options);
	}

	const dontWait = Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');
	openModal('CreateSubscription', { client, dontWait, createdFrom: 'accountSalesAddButton' });
};

const createOrder = (clientId: number) => {
	Tools.$upModal.open('editOrder', { clientId });
};

const CardsHeader = ({ showInactive, setShowInactive, client, contact, agreementGroups, hasMRR }: Props) => {
	const classes = new BemClass('CardsHeader');

	const defaultCurrency = useSelector((state: RootState) => state.App?.metadata?.defaultCurrency);
	const totalSum = agreementGroups.reduce((sum, current) => {
		const agreement = current.currentAgreement;
		const currentEndDate = agreement?.metadata.agreementEnddate;
		const startDate = agreement?.metadata.agreementStartdate;
		const isInactive = (!!currentEndDate && moment(currentEndDate).isBefore()) || moment(startDate).isAfter();

		if (!agreement || isInactive) return sum;

		const valueInLocalCurrency = (agreement.yearlyValue ?? 0) / (hasMRR ? 12 : 1);
		const valueInMasterCurrency = valueInLocalCurrency / agreement.currencyRate;
		return sum + valueInMasterCurrency;
	}, 0);

	const showToggle = agreementGroups.length > 0;
	const currencyFormat = new CurrencyFormat(defaultCurrency?.iso, undefined, 0);
	const value = `${currencyFormat.short(totalSum)} (${hasMRR ? 'MRR' : 'ARR'})`;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('leftSide').b()}>
				<Text size="xl" bold>
					{T('default.agreement')}
					<Text color="grey-10" size="xl">
						{value}
					</Text>
				</Text>
			</div>
			<div className={classes.elem('rightSide').b()}>
				{showToggle ? (
					<>
						<Toggle checked={showInactive} onChange={() => setShowInactive(active => !active)}></Toggle>
						<Text>{`${T('default.show')} ${T('default.agreementsInactive').toLowerCase()}`}</Text>
					</>
				) : null}
				<PromoButton client={client} contact={contact} />
				<DropDownMenu
					align="right"
					useAnimation
					noMinWidth
					renderTrigger={(expanded, setExpanded) => (
						<PrimaryButton className={classes.elem('buttonTrigger').b()} onClick={setExpanded}>
							<Icon color="super-light-green" name="plus" space="mrs" />
							{T('default.add')}
						</PrimaryButton>
					)}
				>
					<div className={classes.elem('buttonDropDown').b()}>
						<Text onClick={() => createOrder(client.id)}>
							<Icon name="dollar" />
							{T('default.sales')}
						</Text>
						<Text onClick={() => createSubscription(client)}>
							<Icon name="subscription" />
							{T('default.agreement')}
						</Text>
					</div>
				</DropDownMenu>
			</div>
		</div>
	);
};

export default CardsHeader;

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Store/index';
import BemClass from '@upsales/components/Utils/bemClass';
import { Fade, SlideFade } from 'App/components/animations';
import './AppOnboarding.scss';
import { Row, Column, Headline, Block, Text, Input, Button, Icon, Loader, Title, BadgeLogo } from '@upsales/components';
import Prospecting from 'Resources/Prospecting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';
import AppOnboardingTopClients from './AppOnboardingTopClients';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';
import StandardIntegration from 'Resources/StandardIntegration';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import Select from 'App/components/Select';
import AppOnboardingFindLeads from './AppOnboardingFindLeads';
import { globalTracker } from 'App/babel/helpers/Tracker';
import AppOnboardingCompanyConfirm from './AppOnboardingCompanyConfirm';
import { setShowOnboarding } from 'Store/actions/AppActions';
import Lottie from 'react-lottie';
import OnboardingStepCompletedResource from 'Resources/OnboardingStepCompleted';
import ProjectResource from 'App/resources/Project';
import OnboardingCompletedResource from 'Resources/OnboardingCompleted';
import OnboardingHelpers from 'App/babel/components/Helpers/OnboardingHelpers';
import { useAnimationData } from 'App/components/hooks';
import ValidationService from 'Services/ValidationService';

enum Stages {
	LOADING,
	SEARCH,
	CONFIRM_COMPANY,
	SELECT_ERP,
	CONNECT_ERP,
	ERP_FETCHING,
	LAST_YEAR,
	TOP_CLIENTS,
	SIMILAR_SEARCH,
	ADDING_COMPANIES
}

type ERPConfig = {
	id: number;
	name: string;
	isImplemented: boolean;
	activateInstructions?: string;
	region: string;
	type?: string;
	keyField?: string;
	imgLink?: string;
};

const findInProspecting = async (name: string): Promise<ProspectingClient[]> => {
	const rb = new RequestBuilder();

	const country = Tools.AppService.getMetadata().params.CustomerRegion;
	const isValidOrgNumber = ValidationService.validateOrgNumber(name, country);
	const field = isValidOrgNumber ? 'orgNumber' : 'name';

	rb.addFilter({ field: field }, comparisonTypes.Search, name);
	rb.addFilter({ field: 'headquarters' }, comparisonTypes.Equals, true);
	rb.extraParams.push({ key: 'country', value: country });
	const { data } = await Prospecting.find(rb.build());

	return data;
};

type AppOnboardingProps = {
	visible: boolean;
	providedClientName: string | null;
	setShowOnboarding: (show: boolean) => void;
	userId: number | null;
};

const mapStateToProps = ({ App }: RootState) => {
	return {
		providedClientName: App.accountSelf?.client?.name ?? null,
		userId: App.self?.id ?? null,
		visible: App.showOnboarding
	};
};

type ERPData = {
	sums: {
		lastYearTotal: number;
		thisYearTotal: number;
		lostCustomers: number;
		existingCustomers: number;
		newCustomers: number;
	};
	topClients: { name: string; orgNo: string; value: number }[];
	currency?: string;
};

const AppOnboarding = ({ visible, providedClientName, setShowOnboarding, userId }: AppOnboardingProps) => {
	if (!visible) {
		return null;
	}

	const availableERPs: ERPConfig[] = [
		{
			id: 74,
			name: 'Fortnox',
			activateInstructions: 'onboarding.erp.fortnoxInstructions',
			isImplemented: true,
			region: 'SE',
			type: Tools.FeatureHelper.hasSoftDeployAccess('FORTNOX_OAUTH') ? 'oauth' : 'api-key',
			keyField: Tools.FeatureHelper.hasSoftDeployAccess('FORTNOX_OAUTH') ? 'oauth' : 'authCode'
		},
		{ id: 364, name: 'Visma.NET', isImplemented: false, region: 'SE' },
		{ id: 541, name: 'p:e accounting', isImplemented: false, region: 'SE' },
		{ id: 628, name: 'Xero', isImplemented: false, region: 'GB', type: 'oauth', keyField: 'oauth' },
		{ id: 383, name: 'Quickbooks', isImplemented: false, region: 'GB' },
		{
			id: 583,
			name: 'Sage',
			isImplemented: false,
			region: 'GB',
			imgLink: 'https://img.upsales.com/app/583/logo.png'
		}
	];

	const [stage, setStage] = useState(Stages.LOADING);
	const [selectedERP, setSelectedERP] = useState<ERPConfig | null>(null);
	const [companyData, setCompanyData] = useState<ProspectingClient | null>(null);
	const [erpData, setErpData] = useState<ERPData | null>(null);
	const [apiKey, setApiKey] = useState('');
	const [integrationData, setIntegrationData] = useState<{
		[id: string]: {
			img: string;
			configJson: {
				fields: {
					account: [{ type: string; endpoint: string }];
				};
			};
			externalConfig: boolean;
		};
	}>({});
	const [erpAuthenticating, setErpAuthenticating] = useState(false);
	const { animationData: FindNewLeads } = useAnimationData(() => import('./FindNewLeads.json'));

	// Do initial loading here if needed
	useEffect(() => {
		if (!providedClientName) {
			setStage(Stages.SEARCH);
		} else {
			findInProspecting(providedClientName)
				.then(data => {
					if (data.length === 1) {
						setCompanyData(data[0]);
						setStage(Stages.CONFIRM_COMPANY);
					} else {
						setStage(Stages.SEARCH);
					}
				})
				.catch(e => {
					logError(e, 'Failed to fetch prospecting data');
					// Allow entering upsales
				});
		}

		StandardIntegration.find({ id: availableERPs.map(app => app.id) })
			.then(
				(integrations: {
					data: { id: number; imageLink: string; configJson: string; externalConfig: boolean }[];
				}) => {
					setIntegrationData(
						integrations.data.reduce<{
							[id: string]: {
								img: string;
								configJson: {
									fields: {
										account: [{ type: string; endpoint: string }];
									};
								};
								externalConfig: boolean;
							};
						}>((memo, app) => {
							memo[app.id] = {
								img: '' + app.imageLink,
								configJson: JSON.parse(app.configJson) as unknown as {
									fields: {
										account: [{ type: string; endpoint: string }];
									};
								},
								externalConfig: app.externalConfig
							};
							return memo;
						}, {})
					);
				}
			)
			.catch(e => logError(e, 'Failed to load integrations'));
	}, []);

	const fetchErpData = async (integrationId: number) => {
		const { data } = await Tools.StandardIntegration.data(Tools.AppService.getCustomerId()).run<{ data: ERPData }>({
			type: 'onboardingStats',
			integrationId: integrationId
		});
		setErpData(data);
		setStage(Stages.LAST_YEAR);
	};

	useEffect(() => {
		if (!['LOADING', 'ERP_FETCHING', 'ADDING_COMPANIES'].includes(Stages[stage])) {
			globalTracker.track(`Onboarding show page ${Stages[stage]}`);
		}

		if (stage === Stages.ERP_FETCHING) {
			fetchErpData(selectedERP!.id);
		}
	}, [stage]);

	const activateApp = async (integrationId: number, value: any) => {
		setErpAuthenticating(true);
		const baseConfig = { [selectedERP?.keyField || 'apiKey']: value };
		const req = {
			id: integrationId,
			integrationId,
			configJson: JSON.stringify(baseConfig),
			active: true
		};

		await Tools.StandardIntegration.setting(Tools.AppService.getCustomerId()).testConfig(req);

		const options = {
			masterIntegration: { id: integrationId },
			skipNotification: true
		};

		await Tools.StandardIntegration.setting(Tools.AppService.getCustomerId()).save(req, options);
		await Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run({
				type: 'onboardingDefaultConfig',
				integrationId: integrationId
			})
			.then((config: any) => {
				req.configJson = JSON.stringify(Object.assign({}, baseConfig, config.data));
				return Tools.StandardIntegration.setting(Tools.AppService.getCustomerId()).save(req, options);
			})
			.catch((e: Error) => {
				logError(e, 'Failed to get default config');
			});
		setStage(Stages.ERP_FETCHING);
		setErpAuthenticating(false);
	};

	const markOnboardingAsDoneAndClose = async () => {
		const onboarding = Tools.AppService.getMetadata().onboarding[0];
		const { data: result } = await OnboardingCompletedResource.save({ hasStarted: 1, onboardingId: onboarding.id });
		await Promise.all([
			...onboarding.steps.map(
				step => OnboardingStepCompletedResource.save({ stepId: step.id, completedDate: new Date() }),
				OnboardingHelpers.setEntireOnboardingDone(result.id)
			)
		]);
		globalTracker.track(`Onboarding completed`);
		setShowOnboarding(false);
	};

	const onAddCompanies = async (prospectingIds: string[]) => {
		const projectRes = await ProjectResource.save({
			name: T('onboarding.erp.defaultCampaignName')
		});
		const data = {
			filters: {
				q: [{ a: 'prospectingId', c: 'eq', v: prospectingIds }],
				sort: [],
				country: Tools.AppService.getMetadata().params.CustomerRegion
			},
			clientData: {
				custom: [],
				sourceType: 'prospecting-manual',
				distributeAmongUsers: false,
				setAccountManager: true,
				users: [{ id: userId }],
				projects: [{ id: projectRes.data.id }]
			},
			activityProperties: [],
			country: Tools.AppService.getMetadata().params.CustomerRegion
		};
		setStage(Stages.ADDING_COMPANIES);
		await Prospecting.saveBulk(data);
		await Tools.$state.go('accounts');

		const urlFilters = Tools.FilterHelper.convertForURL({ Campaign: { value: [projectRes.data.id] } }, 'account');
		Tools.$location.search({
			q: urlFilters,
			id: 2
		});

		await markOnboardingAsDoneAndClose();
	};

	const classes = new BemClass('AppOnboarding');

	const erpsToShow = availableERPs.filter(erp => erp.region === Tools.AppService.getMetadata().params.CustomerRegion);

	return (
		<Fade visible={visible}>
			<div className={classes.b()}>
				<BadgeLogo color="black" size="sm" />
				{stage === Stages.LOADING ? <Loader /> : null}

				<Block space="mtl mrl" className={classes.elem('skip').b()}>
					<Button type="link" color="grey" onClick={() => markOnboardingAsDoneAndClose()}>
						{T('onboarding.erp.skipOnboarding')}
					</Button>
				</Block>

				<SlideFade
					direction={stage < Stages.SEARCH ? 'top' : 'left'}
					visible={stage === Stages.SEARCH}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').mod('no-scroll').b()}>
						<Row>
							<Column />
							<Column size={6}>
								<Block space="mbs">
									<Title color="grey-11">{T('onboarding.erp.welcomeToUpsales')}</Title>
								</Block>
								<Block space="mbxl">
									<Headline>{T('onboarding.erp.whatIsTheNameOfYourCompany')}</Headline>
								</Block>
								<Select<ProspectingClient>
									onChange={row => {
										setCompanyData(row);
										setStage(Stages.CONFIRM_COMPANY);
									}}
									fetchData={term => findInProspecting(term)}
									idKey="prospectingId"
									placeholder={T('onboarding.erp.searchCompaniesOrOrgNo')}
									icon="search"
									autofocus
									renderNoData={() => (
										<Block space="mtl mbl">
											<Text color="grey-11" align="center">
												{T('onboarding.erp.noCompaniesFound')}
											</Text>
											<Block space="mtm">
												<Row>
													<Column align="center">
														<Button
															type="lined"
															onClick={() => setStage(Stages.SELECT_ERP)}
															size="sm"
														>
															{T('onboarding.erp.iCantFindMyCompanyMoveOn')}
														</Button>
													</Column>
												</Row>
											</Block>
										</Block>
									)}
								/>
								<Button type="link" onClick={() => setStage(Stages.SELECT_ERP)}>
									{T('onboarding.erp.iCantFindMyCompanyMoveOn')}
								</Button>
							</Column>
							<Column />
						</Row>
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.CONFIRM_COMPANY ? 'right' : 'left'}
					visible={stage === Stages.CONFIRM_COMPANY}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').b()}>
						<AppOnboardingCompanyConfirm
							visible={stage === Stages.CONFIRM_COMPANY}
							companyData={companyData}
							onBack={() => {
								setStage(Stages.SEARCH);
								setCompanyData(null);
							}}
							onConfirm={() => setStage(Stages.SELECT_ERP)}
						/>
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.SELECT_ERP ? 'right' : 'left'}
					visible={stage === Stages.SELECT_ERP}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').b()}>
						<Block space="mbxl">
							<Headline>{T('onboarding.erp.connectErpForGrowth')}</Headline>
						</Block>
						<Block space="mbxl">
							<Text>{T('onboarding.erp.connectErpForGrowthInfo')}</Text>
						</Block>
						<Row className={classes.elem('erp-list').b()}>
							{erpsToShow.map((erp, index) => (
								<Fragment key={erp.id}>
									<Column>
										<div
											className={classes.elem('box-choice').mod('white').b()}
											onClick={() => {
												globalTracker.track(`Onboarding selected ERP`, { name: erp.name });
												setSelectedERP(erp);
												if (erp.isImplemented) {
													setStage(Stages.CONNECT_ERP);
												} else {
													setStage(Stages.SIMILAR_SEARCH);
												}
											}}
										>
											<img
												className={classes.elem('erp-image').b()}
												src={integrationData[erp.id]?.img ?? erp.imgLink}
												alt={erp.name}
											/>
											<Text center size="lg" bold>
												{erp.name}
											</Text>
										</div>
									</Column>
									{index < erpsToShow.length - 1 ? <Column fixedWidth={20} /> : null}
								</Fragment>
							))}
						</Row>
						<Row>
							<Column>
								<div
									className={classes.elem('box-choice').b()}
									onClick={() => setStage(Stages.SIMILAR_SEARCH)}
								>
									<Text center size="xl">
										<Icon name="times" />
									</Text>
									<Text center size="lg" bold color="grey-10">
										{T('onboarding.erp.skipAnalysis')}
									</Text>
								</div>
							</Column>
						</Row>
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.CONNECT_ERP ? 'right' : 'left'}
					visible={stage === Stages.CONNECT_ERP}
					delayInMs={500}
					delayOutInMs={0}
				>
					<Row className={classes.elem('inner').b()}>
						<Column>
							<Block space="mbxl">
								<Button
									color="white"
									onClick={() => {
										setStage(Stages.SELECT_ERP);
									}}
								>
									<Icon name="chevron-left" space="mrs" />
									{T('onboarding.erp.back')}
								</Button>
							</Block>
							<Block space="mbxl">
								<Text size="xl">
									{integrationData[selectedERP?.id ?? -1] ? (
										<img
											className={classes
												.elem('erp-image')
												.mod({ small: true, 'has-shadow': true })
												.b()}
											src={integrationData[selectedERP!.id].img}
										/>
									) : null}{' '}
									{selectedERP?.name}
								</Text>
							</Block>
							<Block space="mbxl">
								<Headline>{`${T('onboarding.erp.connect')} ${selectedERP?.name}`}</Headline>
							</Block>
							{selectedERP?.activateInstructions ? (
								<Block space="mbxl">
									<Text size="xl">{T(selectedERP.activateInstructions)}</Text>
								</Block>
							) : null}
							{selectedERP?.type === 'api-key' ? (
								<>
									<Block space="mbs">
										<Text bold>{T('onboarding.erp.enterApiKey', { name: selectedERP?.name })}</Text>
									</Block>
									<Block space="mbxl">
										<Input
											value={apiKey}
											onChange={e => setApiKey(e.target.value)}
											placeholder={'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'}
										/>
										<Button
											color={!apiKey.length ? 'light-grey' : 'green'}
											disabled={!apiKey.length || erpAuthenticating}
											loading={erpAuthenticating}
											size="lg"
											onClick={() => activateApp(selectedERP!.id, apiKey)}
										>
											<Icon
												className={classes.elem('animated-icon').b()}
												style={{
													width: apiKey.length ? '14px' : 0,
													opacity: apiKey.length ? 1 : 0
												}}
												name="check"
												space="mrm"
											/>
											{T('onboarding.erp.connect', { name: selectedERP?.name })}
										</Button>
									</Block>
								</>
							) : null}
							{selectedERP?.type === 'oauth' ? (
								<>
									<Block space="mbs">
										<Text bold>{T('onboarding.erp.loginTo', { name: selectedERP?.name })}</Text>
									</Block>
									<Block space="mbxl">
										<Button
											disabled={erpAuthenticating}
											loading={erpAuthenticating}
											onClick={async () => {
												setErpAuthenticating(true);

												const config = (
													integrationData[selectedERP!.id].externalConfig
														? await Tools.StandardIntegration.setting(
																Tools.AppService.getCustomerId()
														  ).getExternalConfig(selectedERP?.id)
														: integrationData[selectedERP!.id].configJson
												) as {
													fields: {
														account: [
															{
																type: string;
																endpoint: string;
																clientId: string;
																callbackUri: string;
															}
														];
													};
												};
												const oauthField = config.fields.account.find(
													f => f.type === 'oauth2'
												)!;

												const theWindow = window.open(
													`${oauthField.endpoint}${
														oauthField.endpoint.includes('?') ? '&' : '?'
													}response_type=code&client_id=${
														oauthField.clientId
													}&redirect_uri=${encodeURIComponent(
														oauthField.callbackUri
													)}&state=${Math.floor(Math.random() * 1000000000)}-${
														selectedERP!.id
													}`,
													'oauthwindow',
													'width=300,height=600'
												);
												let code: string | undefined | null;
												theWindow!.onclose = () => {
													if (!code) {
														setErpAuthenticating(false);
													}
												};
												do {
													try {
														code = new URLSearchParams(
															theWindow?.location.href.split('#')[1].split('?')[1]
														).get('code');
													} catch {
														await new Promise(resolve => setTimeout(resolve, 300));
													}
												} while (!code);
												if (theWindow) {
													theWindow.close();
												}
												const response = await Tools.StandardIntegration.data(
													Tools.AppService.getCustomerId()
												).oauth({
													integrationId: selectedERP?.id,
													code: code
												});

												activateApp(selectedERP?.id, response.data);
											}}
										>
											{T('onboarding.erp.connect', { name: selectedERP?.name })}
										</Button>
									</Block>
								</>
							) : null}
						</Column>
						<Column></Column>
					</Row>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.ERP_FETCHING ? 'right' : 'left'}
					visible={stage === Stages.ERP_FETCHING}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').mod({ center: true }).b()}>
						<Loader size="sm" />
						<Block space="mtxl mbxl ptxl">
							<Headline>{T('onboarding.erp.fetchingData', { name: selectedERP?.name })}</Headline>
						</Block>
						<Block space="mbxl">
							<Text>{T('onboarding.erp.fetchingDataInfo')}</Text>
						</Block>
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.LAST_YEAR ? 'bottom' : 'top'}
					bounce
					visible={stage === Stages.LAST_YEAR}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').mod('large').b()}>
						{erpData ? (
							<>
								<Block space="mbxl">
									<Headline>{T('onboarding.erp.thisIsLastYear')}</Headline>
								</Block>
								<Block space="mbxl">
									<Headline size="sm">{`${T('onboarding.erp.during')} ${moment()
										.add(-1, 'year')
										.format('YYYY')} ${
										erpData!.sums?.lastYearTotal < erpData!.sums?.thisYearTotal
											? T('onboarding.erp.turnoverIncreased')
											: T('onboarding.erp.turnoverDecreased')
									} ${T('default.to').toLowerCase()} ${currencyFormat(
										erpData!.sums?.thisYearTotal,
										erpData!.currency || 'SEK'
									)} (${
										erpData!.sums?.lastYearTotal < erpData!.sums?.thisYearTotal ? '+' : '-'
									}${Math.abs(
										Math.round(
											((erpData!.sums?.lastYearTotal - erpData!.sums?.thisYearTotal) /
												erpData!.sums?.lastYearTotal) *
												100
										)
									)}%)`}</Headline>
								</Block>

								<Block space="mbxl">
									<Row>
										<Column
											className={classes.elem('turnover-bar').mod('lost').b()}
											style={{
												flex: `0 1 ${
													(erpData!.sums?.lostCustomers /
														(erpData!.sums?.lostCustomers +
															erpData!.sums?.existingCustomers +
															erpData!.sums?.newCustomers)) *
													100
												}%`
											}}
										>
											<Text color="red">{`-${currencyFormat(
												erpData!.sums?.lostCustomers,
												erpData!.currency || 'SEK'
											)}`}</Text>
										</Column>
										<Column
											className={classes.elem('turnover-bar').b()}
											style={{
												flex: `0 1 ${
													(erpData!.sums?.existingCustomers /
														(erpData!.sums?.lostCustomers +
															erpData!.sums?.existingCustomers +
															erpData!.sums?.newCustomers)) *
													100
												}%`
											}}
										>
											<Text color="white">{`${currencyFormat(
												erpData!.sums?.existingCustomers,
												erpData!.currency || 'SEK'
											)}`}</Text>
										</Column>
										<Column className={classes.elem('turnover-bar').mod('new').b()}>
											<Text color="white">{`${currencyFormat(
												erpData!.sums?.newCustomers,
												erpData!.currency || 'SEK'
											)}`}</Text>
										</Column>
									</Row>
								</Block>

								<Block space="mbxl">
									<Row>
										<Column align="left">
											<Title>
												<Icon name="circle" space="mrm" color="red" />
												{`${T('onboarding.erp.lostCustomers')} ${currencyFormat(
													erpData!.sums?.lostCustomers,
													erpData!.currency || 'SEK',
													true
												)}`}
											</Title>
										</Column>
										<Column align="center">
											<Title>
												<Icon name="circle" space="mrm" color="green" />
												{`${T('onboarding.erp.existingCustomers')} ${currencyFormat(
													erpData!.sums?.existingCustomers,
													erpData!.currency || 'SEK',
													true
												)}`}
											</Title>
										</Column>
										<Column align="right">
											<Title>
												<Icon name="circle" space="mrm" color="medium-green" />
												{`${T('onboarding.erp.newCustomers')} ${currencyFormat(
													erpData!.sums?.newCustomers,
													erpData!.currency || 'SEK',
													true
												)}`}
											</Title>
										</Column>
									</Row>
								</Block>

								<Block className={classes.elem('identified-growth-headline').b()} space="mtxl mbm">
									<Title size="lg">{T('onboarding.erp.upsalesHasIdentifiedGrowth')}</Title>
								</Block>

								<Row>
									<Column>
										<div
											className={classes.elem('box-choice').b()}
											onClick={() => {
												setStage(Stages.TOP_CLIENTS);
											}}
										>
											<Row>
												<Column>
													<Block space="mbl">
														<Title color="black" bold size="xl">
															{T('onboarding.erp.findNewCustomersSimilar.title')}
														</Title>
													</Block>
													<Text size="xl">
														{T('onboarding.erp.findNewCustomersSimilar.description')}
													</Text>
												</Column>
												<Column fixedWidth={130}>
													<Lottie
														style={{ transform: 'scale(1.2)' }}
														options={{ animationData: FindNewLeads }}
														height={100}
														width={100}
													/>
												</Column>
											</Row>
										</div>
									</Column>
									{/* <Column fixedWidth={20} />
							<Column>
								<div
									className={classes.elem('box-choice').b()}
									onClick={() => {
										setErpData(mockData);
										setStage(Stages.TOP_CLIENTS);
									}}
								>
									<Title bold center>
										{'Jag vill hitta merförsäljningsmöjligheter hos mina kunder'}
									</Title>
								</div>
							</Column> */}
								</Row>
							</>
						) : null}
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.TOP_CLIENTS ? 'right' : 'left'}
					visible={stage === Stages.TOP_CLIENTS}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').b()}>
						<AppOnboardingTopClients
							erpClients={erpData?.topClients || []}
							onBack={() => setStage(Stages.LAST_YEAR)}
							onConfirm={onAddCompanies}
						/>
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.SIMILAR_SEARCH ? 'right' : 'left'}
					visible={stage === Stages.SIMILAR_SEARCH}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').b()}>
						<AppOnboardingFindLeads onBack={() => setStage(Stages.SELECT_ERP)} onConfirm={onAddCompanies} />
					</div>
				</SlideFade>

				<SlideFade
					direction={stage < Stages.ADDING_COMPANIES ? 'right' : 'left'}
					visible={stage === Stages.ADDING_COMPANIES}
					delayInMs={500}
					delayOutInMs={0}
				>
					<div className={classes.elem('inner').mod('adding-companies').mod('center').b()}>
						<Block space="mbxl">
							<Loader />
						</Block>
						<Headline>{T('onboarding.erp.addingCompaniesToUpsales')}</Headline>
					</div>
				</SlideFade>
			</div>
		</Fade>
	);
};

export const detached = AppOnboarding;

export default connect(mapStateToProps, { setShowOnboarding })(AppOnboarding);

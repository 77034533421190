'use strict';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

angular.module('upModals.controllers').controller('PdfPreview', [
	'$scope',
	'pdfDelegate',
	'Document',
	'$modalParams',
	'AppService',
	'$upModal',
	'browserService',
	'$timeout',
	function ($scope, pdfDelegate, Document, $modalParams, AppService, $upModal, browserService, $timeout) {
		var customerId = AppService.getCustomerId();
		$scope.loading = true;
		$scope.pages = 0;
		$scope.currentPage = 1;
		$scope.scale = 1;
		$scope.noPreview = browserService.isUnsupported() || browserService.isIE9();
		$scope.pdfPath = Document.customer(customerId)
			.setType($modalParams.type)
			.setContactId($modalParams.contactId)
			.getUrl($modalParams.entityId, $modalParams.templateId, Document.fileTypes.PDF);
		$scope.docPath = Document.customer(customerId)
			.setType($modalParams.type)
			.setContactId($modalParams.contactId)
			.getUrl($modalParams.entityId, $modalParams.templateId, Document.fileTypes.DOC);
		$scope.hasEmail = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL);

		var pdfHelper = pdfDelegate.$getByHandle('preview-pdf');

		$scope.next = function () {
			pdfHelper.next();
			$scope.currentPage = pdfHelper.getCurrentPage();
		};

		$scope.prev = function () {
			pdfHelper.prev();
			$scope.currentPage = pdfHelper.getCurrentPage();
		};

		$scope.zoomIn = function () {
			$scope.scale = pdfHelper.zoomIn();
			setTimeout(function () {
				$scope.reloadModalPosition();
			}, 1);
		};

		$scope.zoomOut = function () {
			if ($scope.scale !== 1) {
				$scope.scale = pdfHelper.zoomOut();
				setTimeout(function () {
					$scope.reloadModalPosition();
				}, 1);
			}
		};

		$scope.sendEmail = function () {
			var fileFormat = 'pdf';
			var attachment = {
				filename: $modalParams.name + '.' + fileFormat,
				type: 'Template',
				url: $scope.pdfPath,
				value:
					'templateId=' +
					$modalParams.templateId +
					'&entityId=' +
					$modalParams.entityId +
					'&fileFormat=' +
					fileFormat
			};

			attachment.value += '&contactId=' + ($modalParams.contactId || '0');
			attachment.value += '&clientId=' + ($modalParams.accountId || '0');

			$scope.reject();
			$timeout(function () {
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openNewMailWithContact($modalParams.contact, { attachments: [attachment] });
				} else {
					var opts = {
						customerId: customerId,
						type: 'mail',
						attachments: [attachment]
					};

					if ($modalParams.contactId) {
						opts.contactId = $modalParams.contactId;
					}

					if ($modalParams.accountId) {
						opts.accountId = $modalParams.accountId;
					}

					$upModal.open('sendEmail', opts);
				}
			}, 100);
		};

		if (!$scope.noPreview) {
			var kill = $scope.$watch(
				function () {
					return pdfHelper.getPageCount();
				},
				function (pages) {
					if (pages) {
						$scope.loading = false;
						$scope.pages = pages;
						$timeout(function () {
							$scope.reloadModalPosition();
						}, 100);
						kill();
					}
				}
			);
		} else {
			$scope.loading = false;
		}
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import logError from 'App/babel/helpers/logError';

class ListInput extends React.Component {
	static DELIMITER = '|';

	filter(term, array) {
		const { config } = this.props;
		return array.reduce((res, item) => {
			/*
				This now uses the same searchField for parent and children,
				but the only time we have this case in contats is for users and roles
				where both have name as key
			*/
			const key = config.searchField || 'name';
			let itemIsMatching;
			if (Array.isArray(key)) {
				itemIsMatching = key.some(k => item[k]?.toLowerCase().includes(term.toLowerCase()));
			} else {
				const itemString = (item[key] || '').toLowerCase();
				itemIsMatching = itemString.indexOf(term.toLowerCase()) > -1;
			}
			if (item.children) {
				item.children = this.filter(term, item.children);

				if (itemIsMatching || item.children.length) {
					res.push(item);
				}
			} else if (itemIsMatching) {
				res.push(item);
			}
			return res;
		}, []);
	}

	findValues(values, array) {
		return array.reduce((res, item) => {
			const key = 'id';
			const itemKeyValue = item[key];

			if (itemKeyValue) {
				/* Yes i deliberately used == here and it does exacly what i want */
				// eslint-disable-next-line eqeqeq
				if (values.findIndex(value => value == itemKeyValue) > -1) {
					res.push(item);
				}
			}
			if (item.children) {
				res = res.concat(this.findValues(values, item.children));
			}

			return res;
		}, []);
	}

	setRef(name, r) {
		this[name] = r;
	}

	componentWillUnmount() {
		const element = $(this._input);
		element.select2('destroy');
		// Calling .off() with no arguments removes all handlers attached to the elements.
		element.off();
	}

	componentDidMount() {
		const {
			config,
			selectData,
			LIST_AJAX_LIMIT,
			onChange,
			select2,
			placeholder = '',
			autoFocus,
			autoOpen
		} = this.props;
		const { AppService, RequestBuilder, $injector, FilterHelper, $translate } = Tools;
		const customerId = AppService.getCustomerId();
		const fields = config.includeFields || [];
		const idKey = config.idAttr ? config.idAttr.field : 'id';
		const isCustom = FilterHelper.isCustom(config.filterName);

		let options = {
			multiple: true,
			required: false,
			quietMillis: 250,
			placeholder: placeholder,
			separator: ListInput.DELIMITER,
			formatResult: (obj, el, x, encode) => {
				const displayText = config.displayText || 'name';
				const isFunction = typeof displayText === 'function';
				const text = isFunction ? displayText(obj) : obj[displayText];

				return isFunction ? text : encode(text || '-');
			},
			formatSelection: (obj, x, encode) => {
				const displayText = config.displayText || 'name';
				const isFunction = typeof displayText === 'function';
				const text = isFunction ? displayText(obj, true) : obj[displayText];

				return isFunction ? text : encode(text || '-');
			},
			id: function (obj) {
				return obj ? obj[idKey] : null;
			}
		};

		if (isCustom) {
			options.formatNoMatches = term => {
				const entity =
					config.entity === 'account'
						? $translate('default.clients').toLowerCase()
						: $translate('default.contacts').toLowerCase();
				return term
					? $translate('segment.custom.noMatches.term', { term: term })
					: $translate('segment.custom.noMatches.empty', { entity: entity });
			};
		}

		if (config.dataPromise) {
			let dataPromise;

			if (selectData && selectData.hasOwnProperty(config.filterName)) {
				dataPromise = selectData[config.filterName];
			} else {
				dataPromise = Tools.$injector.invoke(
					config.dataPromise,
					{},
					{ customerId: customerId, filterConfig: config, filterName: config.filterName }
				);
			}

			options.ajax = {
				data: term => term,
				transport: query => {
					dataPromise
						.then(res => {
							if (query.data) {
								/*
								The reason for this is in the filte function we are modifing
								item.children so we have to clone it so we dont lose information
							*/
								const term = query.data;
								const clone = _.cloneDeep(res.data);
								query.success(this.filter(term, clone));
							} else {
								query.success(res.data);
							}
						})
						.catch(e => logError(e, 'Failed to fetch data'));
				},
				results: data => {
					return { results: data };
				}
			};
			options.initSelection = (element, callback) => {
				const values = element
					.val()
					.replace(/[\]\[]/g, '') //eslint-disable-line
					.split(ListInput.DELIMITER);
				if (values.length) {
					dataPromise
						.then(res => {
							if (this._input) {
								callback(this.findValues(values, res.data));
							}
						})
						.catch(e => logError(e, 'Failed to fetch data'));
				} else {
					callback([]);
				}
			};
		} else {
			options.ajax = {
				data: (term, page) => ({ term: term, page: page }),
				transport: query => {
					const offset = (query.data.page - 1) * LIST_AJAX_LIMIT;

					const ajaxRequest = () => {
						const request = $injector.invoke(config.searchFn, {});
						request(query.data.term, fields, offset, LIST_AJAX_LIMIT)
							.then(res => {
								query.success(res);
							})
							.catch(() => {
								query.success([]);
							});
					};

					if (selectData && selectData.hasOwnProperty(config.filterName)) {
						selectData[config.filterName]
							.then(res => {
								if (res.metadata && res.metadata.total < LIST_AJAX_LIMIT) {
									const term = query.data.term;
									const clone = _.cloneDeep(res.data);
									const filteredData = this.filter(term, clone);

									query.success({
										data: filteredData,
										metadata: { total: filteredData.length, offset: offset, limit: LIST_AJAX_LIMIT }
									});
								} else if (offset === 0 && !query.data.term) {
									const clone = _.cloneDeep(res.data);
									const total = (res.metadata?.total ? res.metadata.total : clone.length) ?? 0;

									query.success({
										data: clone,
										metadata: { total, offset, limit: LIST_AJAX_LIMIT }
									});
								} else {
									ajaxRequest();
								}
							})
							.catch(e => logError(e, 'Failed to fetch data'));
					} else {
						ajaxRequest();
					}
				},
				results: (data, page) => {
					const offset = (page - 1) * LIST_AJAX_LIMIT;
					const more = data.metadata?.total ? offset + LIST_AJAX_LIMIT < data.metadata.total : false;
					return { results: data.data, more: more };
				}
			};

			options.initSelection = (element, callback) => {
				const values = element
					.val()
					.replace(/[\]\[]/g, '') //eslint-disable-line
					.split(ListInput.DELIMITER);

				if (values.length) {
					const customerId = AppService.getCustomerId();

					if (typeof config.resource === 'function') {
						const Resource = $injector.invoke(config.resource, {});
						Resource(customerId, values)
							.then(res => {
								if (this._input) {
									callback(res.data);
								}
							})
							.catch(e => logError(e, 'Failed to fetch initial data for ListInput (injected resource)'));
					} else if (config.resource) {
						let Resource = $injector.get(config.resource);

						if (Resource.hasOwnProperty('customer')) {
							Resource = Resource.customer(customerId);
						}

						const rb = new RequestBuilder();

						const categoryValues = [];
						const idValues = [];
						for (const value of values) {
							if (value.includes('category-')) {
								const categoryId = value.split('-')[1];
								categoryValues.push(categoryId);
							} else {
								idValues.push(value);
							}
						}

						if (categoryValues.length) {
							const or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'category.id' }, rb.comparisonTypes.Equals, categoryValues);
							or.next();
							or.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, idValues);
							or.done();
						} else {
							rb.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, values);
						}

						rb.fields = fields;

						Resource.find(rb.build())
							.then(res => {
								if (this._input) {
									if (config.resource === 'Product') {
										const categories = _.cloneDeep(Tools.AppService.getProductCategories(false));
										const updatedCategories = categories.map(category => {
											category.id = `category-${category.id}`;
											return category;
										});
										callback(this.findValues(values, res.data.concat(updatedCategories)));
									} else {
										callback(res.data);
									}
								}
							})
							.catch(e => logError(e, 'Failed to fetch initial data for ListInput (resource)'));
					} else if (config.initialData && typeof config.initialData === 'function') {
						config
							.initialData(values)
							.then(res => {
								if (this._input) {
									callback(res.data);
								}
							})
							.catch(e => logError(e, 'Failed to fetch initial data for ListInput (initialData)'));
					}
				}
			};
		}

		if (select2) {
			options = _.assign(options, select2);
		}

		const input = $(this._input);
		input.select2(options).on('change', function () {
			const data = $(this).select2('data') || [];
			const ids = _.pluck(data, idKey);
			onChange(ids);
		});

		if (autoFocus) {
			input.select2('focus');
		}

		if (autoOpen) {
			input.select2('open');
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.placeholder !== this.props.placeholder &&
			!(Array.isArray(this.props.defaultValue) && this.props.defaultValue.length)
		) {
			const element = $(this._input).parent().find('.select2-input');

			if (element.length && !element.is(':focus')) {
				element[0].value = this.props.placeholder;
			}
		}
	}

	render() {
		const { defaultValue, placeholder = '' } = this.props;

		// We merge the values into a string, delimited with pipes, since that delimiter
		// is more uncommon and to avoid the default merging into a comma-separated string by select2
		const delimitedDefaultValues =
			defaultValue && defaultValue.length ? [defaultValue.join(ListInput.DELIMITER)] : [];

		return (
			<input
				type="hidden"
				ref={this.setRef.bind(this, '_input')}
				className={'form-control'}
				defaultValue={delimitedDefaultValues}
				placeholder={placeholder}
				disabled={this.props.locked}
			/>
		);
	}
}

ListInput.propTypes = {
	config: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultValue: PropTypes.array,
	placeholder: PropTypes.string,
	select2: PropTypes.object,
	autoFocus: PropTypes.bool,
	autoOpen: PropTypes.bool,
	locked: PropTypes.bool
};

ListInput.defaultProps = {
	LIST_AJAX_LIMIT: 50
};
export default ListInput;

import React, { useMemo } from 'react';
import { Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './RangeInput.scss';

interface RangeInputProps {
	startPlaceholder?: string;
	onStartChange?: (value: string) => void;
	startValue: string;

	endPlaceholder?: string;
	onEndChange?: (value: string) => void;
	endValue: string;

	numeric?: boolean;
}

const RangeInput: React.FC<RangeInputProps> = ({
	startPlaceholder,
	endPlaceholder,
	onStartChange,
	onEndChange,
	startValue,
	endValue,
	numeric = false
}) => {
	const bem = new BemClass('RangeInput');

	const type: string = useMemo(() => (numeric ? 'number' : 'text'), [numeric]);

	return (
		<div className={bem.b()}>
			<Input
				className={bem.elem('start').b()}
				size="md"
				type={type}
				value={startValue ?? ''}
				placeholder={startPlaceholder}
				onChange={e => onStartChange?.(e.target?.value)}
			/>
			<Input
				className={bem.elem('end').b()}
				size="md"
				type={type}
				value={endValue ?? ''}
				placeholder={endPlaceholder}
				onChange={e => onEndChange?.(e.target?.value)}
			/>
		</div>
	);
};

export default RangeInput;

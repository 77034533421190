'use strict';

angular.module('upResources').factory('ReportView', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AppService',
	'ParseGeneric',
	'ResourceHelper',
	'FeatureHelper',
	function ReportView($resource, $q, URL, API, AppService, ParseGeneric, ResourceHelper, FeatureHelper) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'reportView',
			createSuccessBody: 'saved.listView',
			updateSuccessBody: 'updated.listView',
			deleteSucccessBody: 'deleted.listView',
			createErrorBody: 'saveError.listView',
			updateErrorBody: 'saveError.listView',
			deleteErrorBody: 'deleteError.listView'
		});

		var Resource;
		var OldResource;

		AppService.loadedPromise.then(function () {
			var methods = {
				get: { method: 'GET', isArray: false },
				find: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false },
				delete: {
					method: 'DELETE',
					isArray: false,
					url: FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS)
						? URL + API + 'reportViews/:entity/:id'
						: URL + API + 'reportview/:id'
				}
			};
			Resource = $resource(URL + API + 'reportViews/:entity/:id', {}, methods);
			OldResource = $resource(URL + API + 'reportview/:entity/:visibilityType/:id', {}, methods);
		});

		function getResource() {
			if (!FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS)) {
				return OldResource;
			}
			return Resource;
		}

		var Model = {
			visibilityType: null,
			entity: null,
			find: function (filter, options) {
				if (!this.entity) {
					throw new Error('No entity type set for list view resource');
				}
				if (!this.visibilityType) {
					throw new Error('No visibility type set for list view resource');
				}
				var params = angular.extend(filter || {}, options);
				params.visibilityType = this.visibilityType;
				params.entity = this.entity;
				this.visibilityType = null;
				this.entity = null;
				return getResource().find(params).$promise;
			},

			get: function (id, options) {
				var params = angular.extend({}, options);
				params.visibilityType = this.visibilityType;
				params.entity = params.entity || this.entity;
				this.visibilityType = null;
				this.entity = null;

				return getResource().get({ id: id, entity: params.entity }, params).$promise;
			},

			save: function (view, options) {
				if (!this.entity) {
					throw new Error('No entity type set for list view resource');
				}
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.SHARED_VIEWS)) {
					if (!this.visibilityType) {
						throw new Error('No visibility type set for list view resource');
					}
				} else {
					this.visibilityType = 'own';
				}

				var promise;

				if (!view) {
					return $q.reject('No view');
				}

				if (view.id) {
					promise = getResource().update(
						{ id: view.id, entity: this.entity, visibilityType: this.visibilityType },
						view
					).$promise;
				} else {
					promise = getResource().create({ entity: this.entity, visibilityType: this.visibilityType }, view)
						.$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !view.id);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !view.id);
					});

				this.visibilityType = null;
				this.entity = null;

				return promise;
			},

			delete: function (view, options) {
				return getResource()
					.delete({ id: view.id, entity: this.entity })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, view);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			setVisibilityType: function (type) {
				this.visibilityType = type;
				return this;
			},

			setEntityType: function (type) {
				this.entity = type;
				return this;
			}
		};

		return Model;
	}
]);

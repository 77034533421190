import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Icon, Title, Text, Row, Column } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './ActionsCard.scss';
import { useTranslation } from '../../Helpers/translate';
import CriteriaInfo from '../../Criteria/CriteriaRow/CriteriaInfo';
import CriteriaPlaceholder from '../../Criteria/CriteriaPlaceholder';
import { addAction, editAction, removeAction, openAction } from 'Store/reducers/JourneyEditorReducer';
import { DefaultButton } from '@upsales/components/Buttons';

const mapStateToProps = state => ({
	actions: state.JourneyEdit.actions
});

const mapDispatchToProps = {
	openAction,
	addAction,
	editAction,
	removeAction
};

const ActionsCard = props => {
	const classes = new BemClass('ActionsCard');
	const footerClasses = classes.elem('footer');
	const { t } = useTranslation();
	return (
		<Card className={classes.b()}>
			<Title className={classes.elem('title').b()}>{t('automation.thenDoActions')}</Title>
			<div className={classes.elem('CriteriaTable').b()}>
				{props.actions.length ? (
					<table>
						<tbody>
							{props.actions.map((action, index) => (
								<CriteriaInfo
									key={`${action.action}-${index}`}
									config={{ entity: 'action', ...action }}
									action={action.action}
									removeCriteria={() => props.removeAction(index)}
									onChange={newAction => props.editAction(index, newAction)}
									onClick={() => props.openAction(action, index)}
								/>
							))}
						</tbody>
					</table>
				) : (
					<CriteriaPlaceholder text={t('admin.addActionsHere')} />
				)}
				<Row className={footerClasses.b()}>
					<Column>
						<Text>
							<Icon name="plus-circle" color="grey-10" space="mrm" />
							{t('default.add')}
						</Text>
					</Column>
					<Column>
						<DefaultButton size="sm" onClick={() => props.addAction()}>
							{t('admin.actions')}
						</DefaultButton>
					</Column>
				</Row>
			</div>
		</Card>
	);
};

ActionsCard.propTypes = {
	actions: PropTypes.array.isRequired,
	addAction: PropTypes.func.isRequired,
	editAction: PropTypes.func.isRequired,
	removeAction: PropTypes.func.isRequired,
	openAction: PropTypes.func.isRequired
};

export const detached = ActionsCard;
const ConnectedActionsCard = connect(mapStateToProps, mapDispatchToProps)(ActionsCard);
export default ConnectedActionsCard;

import logError from 'App/babel/helpers/logError';
import SupersearchHistory from 'Components/Supersearch/SupersearchHistory';
import SupersearchResultHeader from 'Components/Supersearch/SupersearchResultHeader';
import {
	SupersearchResultRowClient,
	SupersearchResultRowContact,
	SupersearchResultRowActivity,
	SupersearchResultRowAppointment,
	SupersearchResultRowOrder,
	SupersearchResultRowOpportunity,
	SupersearchResultRowCommand
} from 'Components/Supersearch/SupersearchResultRow';
import { Icon } from '@upsales/components';
import { handleCommand, getCommandMap } from './commandHandler';
import SupersearchNoResults from './SupersearchNoResults';
import history from 'App/pages/routes/history';

const onSearch = function (searchStr, ts, options) {
	var searchValue = searchStr.split(' ');
	searchValue.length = 15;
	searchValue = searchValue.join(' ');
	searchValue = searchValue.trim();
	const customerId = Tools.AppService.getCustomerId();

	return Tools.SearchService.search(searchValue, customerId, null, ts, options).then(function (results) {
		var res = { numResults: 0, data: {}, ts: results.ts };
		angular.forEach(results, function (entity) {
			var data = entity.data;
			var type = entity.type;
			if (/^(client|activity|opportunity|appointment|order|contact|subAccount)$/.test(type)) {
				res.data[type] = entity;
			}

			if (data) {
				res.numResults += data.length;
			}
		});

		return res;
	});
};

const rowComponents = {
	command: { component: SupersearchResultRowCommand, fieldKey: 'command' },
	client: { component: SupersearchResultRowClient, fieldKey: 'account' },
	subAccount: { component: SupersearchResultRowClient, fieldKey: 'account' },
	contact: { component: SupersearchResultRowContact, fieldKey: 'contact' },
	activity: { component: SupersearchResultRowActivity, fieldKey: 'activity' },
	appointment: { component: SupersearchResultRowAppointment, fieldKey: 'appointment' },
	order: { component: SupersearchResultRowOrder, fieldKey: 'order' },
	opportunity: { component: SupersearchResultRowOpportunity, fieldKey: 'opportunity' }
};

var superSearchTimeout = null;
ReactTemplates.navbar.supersearch = window.ReactCreateClass({
	getInitialState: function () {
		return {
			open: false,
			value: '',
			highlightedResult: 0,
			numResults: 0,
			loading: false,
			error: false,
			results: {},
			showMoreOf: null,
			limits: this.resetLimits()
		};
	},
	setLanguage: function () {
		var t = Tools.$translate;
		this.lang = {
			command: t('default.command'),
			placeholder: t('default.searchUpsales'),
			advancedTitle: t('filters.advancedSearch'),
			noResults: t('default.noResults'),
			typeToSearch: t('default.typeToSearch'),
			client: t('default.accounts'),
			subAccount: t('account.subaccounts'),
			contact: t('default.contacts'),
			activity: t('default.activities'),
			opportunity: t('default.opportunities'),
			order: t('default.orders'),
			name: t('default.name'),
			email: t('default.email'),
			appointment: t('default.appointments'),
			and: t('default.and').toLowerCase(),
			more: t('filters.more').toLowerCase(),
			accountHasActivity: t('search.accountHasActivity'),
			accountHasNoActivity: t('search.accountHasNoActivity'),
			showAll: t('default.showAll'),
			result: {
				subAccount: t('default.subaccountResult').toLowerCase(),
				client: t('default.accountResult').toLowerCase(),
				contact: t('default.contactResult').toLowerCase(),
				activity: t('default.activityResult').toLowerCase(),
				opportunity: t('default.opportunityResult').toLowerCase(),
				order: t('default.orderResult').toLowerCase(),
				appointment: t('default.appointmentResult').toLowerCase()
			},
			thatMatches: t('default.thatMatches').toLowerCase(),
			inactive: t('default.inactive').toLowerCase(),
			description: t('default.description'),
			get: t('soliditet.get'),
			results: t('default.results').toLowerCase(),
			resultSingular: {
				client: t('default.account').toLowerCase(),
				contact: t('default.contact').toLowerCase(),
				activity: t('default.activity').toLowerCase(),
				opportunity: t('default.opportunity').toLowerCase(),
				order: t('default.order').toLowerCase(),
				appointment: t('default.appointment').toLowerCase()
			},
			searchError: t('listError.default'),
			searchSmartInUpsales: t('navbar.quickSearch.searchSmartInUpsales'),
			phonenumberExplanation: t('navbar.quickSearch.phonenumberExplanation'),
			phone: t('tag.contact.phone'),
			shortcut: t('navbar.quickSearch.shortcut'),
			shortcutExplanation: t('navbar.quickSearch.shortcutExplanation'),
			quickNav: t('navbar.quickSearch.quickNav'),
			quickNavExplanation1: t('navbar.quickSearch.quickNavExplanation.1'),
			quickNavExplanation2: t('navbar.quickSearch.quickNavExplanation.2'),
			quickNavExplanation3: t('navbar.quickSearch.quickNavExplanation.3')
		};
	},
	UNSAFE_componentWillMount: function () {
		this.setLanguage();
		this.commandMap = getCommandMap();

		var meta = Tools.AppService.getMetadata();
		this.currency = _.find(meta.customerCurrencies, { masterCurrency: true }).iso;

		this.self = Tools.AppService.getSelf();

		this.moreTypes = {
			client: this.lang.name.toLowerCase(),
			contact: this.lang.name.toLowerCase(),
			activity: this.lang.description.toLowerCase(),
			opportunity: this.lang.description.toLowerCase(),
			order: this.lang.description.toLowerCase(),
			appointment: this.lang.description.toLowerCase()
		};
		this.hasLatestClient = Tools.FeatureHelper.hasSoftDeployAccess('LATEST_CLIENTS_MOVE');
	},
	componentDidMount: function () {
		document.addEventListener('keydown', this.openOnShortCut);
		document.addEventListener('openSearch', this.open);
	},
	componentWillUnmount: function () {
		document.removeEventListener('keydown', this.openOnShortCut);
		document.removeEventListener('openSearch', this.open);
	},
	UNSAFE_componentWillUpdate: function () {
		this.setLanguage();
	},
	componentDidUpdate: function () {
		var self = this;
		var field = self.getField();
		if (self.state.open) {
			field.focus();

			var form = self.getForm();
			var containerHeight = window.innerHeight - 10;
			var resultWrap = this.getResults();

			if (form.scrollHeight > containerHeight && !form.classList.contains('large')) {
				form.classList.add('large');
			}
			resultWrap.style.maxHeight = window.innerHeight - 10 - 16 - 40 + 'px';
		} else {
			field.blur();
		}
	},
	openOnShortCut: function (e) {
		const isOpenCommand = Tools.browserService.isFirefox()
			? Tools.browserService.isOptionCombinedKey(e, 56, true) // Cannot override cmd+shift+7 in firefox
			: Tools.browserService.isOptionCombinedKey(e, 55, true);
		if (isOpenCommand && !document.body.classList.contains('modal-open')) {
			this.open();
		}
	},
	reFocus: function () {
		if (this.state.open) {
			this.getField().focus();
		}
	},
	getField: function () {
		return this.refs['search-field'];
	},
	getResults: function () {
		return this.refs['results-wrap'];
	},
	getForm: function () {
		return this.refs['form'];
	},
	getCurtain: function () {
		return this.refs['curtain'];
	},
	getValue: function () {
		return this.refs['search-field'].value;
	},
	moveDown: function () {
		let rowSelector;
		let rowSelectedClass;
		if (this.hasLatestClient) {
			rowSelector = '.SupersearchResultRow';
			rowSelectedClass = 'SupersearchResultRow--selected';
		} else {
			rowSelector = '.search-result';
			rowSelectedClass = 'selected';
		}
		const resultWrap = jQuery(this.getResults());
		const selected = resultWrap.find(`${rowSelector}.${rowSelectedClass}`);
		const results = resultWrap.find(rowSelector);
		const index = jQuery(rowSelector).index(selected);

		// check if there is one more
		if (index !== results.length - 1) {
			selected.removeClass(rowSelectedClass);
			jQuery(results[index + 1]).addClass(rowSelectedClass);
		} else {
			selected.removeClass(rowSelectedClass);
			jQuery(results[0]).addClass(rowSelectedClass);
		}
	},
	moveUp: function () {
		let rowSelector;
		let rowSelectedClass;
		if (this.hasLatestClient) {
			rowSelector = '.SupersearchResultRow';
			rowSelectedClass = 'SupersearchResultRow--selected';
		} else {
			rowSelector = '.search-result';
			rowSelectedClass = 'selected';
		}
		const resultWrap = jQuery(this.getResults());
		const selected = resultWrap.find(`${rowSelector}.${rowSelectedClass}`);
		const results = resultWrap.find(rowSelector);
		const index = jQuery(rowSelector).index(selected);

		// check if there is one more
		if (index !== 0) {
			selected.removeClass(rowSelectedClass);
			jQuery(results[index - 1]).addClass(rowSelectedClass);
		} else {
			selected.removeClass(rowSelectedClass);
			jQuery(results[results.length - 1]).addClass(rowSelectedClass);
		}
	},
	goToAdvanced: function () {
		this.close();
		if (Tools.FeatureHelper.hasSoftDeployAccess('ADVANCED_SEARCH_REACT')) {
			history.push('/advanced-search/accounts');
		} else {
			Tools.$state.go('advancedSearch.account', { customerId: Tools.customerId });
		}
	},
	close: function () {
		if (!this.state.open) {
			return;
		}
		this.setState({ open: false });
	},
	open: function () {
		if (this.state.open) {
			return;
		}
		$(this.getField()).select();
		this.setState({ open: true });
	},
	resultClick: function (row, type) {
		this.close();
		this.setState({
			value: ''
		});

		var options = {};
		switch (type) {
			case 'client':
			case 'subAccount':
				return Tools.$state.go('account.dashboard', { id: row.id });
			case 'contact':
				return Tools.$state.go('contact.dashboard', { id: row.id });
			case 'activity':
				options.id = row.id;
				return Tools.$upModal.open('editActivity', options);
			case 'opportunity':
			case 'order':
				options.id = row.id;
				return Tools.$upModal.open('editOrder', options);
			case 'appointment':
				options.id = row.id;
				return Tools.$upModal.open('editAppointment', options);
			case 'command':
				return handleCommand(row.command);
		}
	},
	resetLimits: function () {
		return {
			client: 5,
			subAccount: 5,
			contact: 5,
			activity: 5,
			appointment: 5,
			order: 5,
			opportunity: 5
		};
	},
	showMore: function (type) {
		var self = this;

		if (superSearchTimeout) {
			clearTimeout(superSearchTimeout);
			superSearchTimeout = null;
		}

		superSearchTimeout = setTimeout(function () {
			var limits = self.state.limits;
			limits[type] += 5;

			var options = { type: type };
			options[type + 'Limit'] = limits[type];

			onSearch(self.state.value, self.searchTimestamp, options)
				.then(function (res) {
					self.state.results[type] = res.data[type];
					self.setState({
						results: self.state.results
					});
				})
				.catch(e => {
					logError(e, 'Failed searching');
				});

			self.setState({
				limits: limits
			});
		}, 200);
	},
	searchTimestamp: null,
	search: function () {
		const isCommand = Tools.FeatureHelper.hasSoftDeployAccess('QUICK_NAVIGATION') && this.getValue()[0] === ':';
		this.searchTimestamp = Date.now();
		var self = this;
		self.setState({
			limits: this.resetLimits(),
			value: self.getValue() || '',
			loading: true,
			error: false,
			numResults: 0,
			results: {},
			highlightedResult: null
		});

		if (isCommand) {
			const trimString = str => str.toLowerCase().replace(/\s+/g, '');
			const commandArray = Array.from(this.commandMap);
			const visibleCommands = commandArray.filter(([key, value]) => {
				return (
					!value.unavailable &&
					(trimString(key).includes(trimString(this.getValue())) ||
						trimString(value.altCommands?.toString()).includes(trimString(this.getValue())))
				);
			});
			const commandsAsClientData = visibleCommands.map(command => ({
				id: command[0],
				command: command[0],
				altCommands: command[1].altCommands,
				name: command[1].name
			}));
			self.setState({
				numResults: commandsAsClientData.length,
				loading: false,
				results: {
					command: {
						data: commandsAsClientData,
						metadata: { total: commandsAsClientData.length, limit: 5, offset: 0 },
						sortOrder: 1,
						type: 'command'
					}
				}
			});
		} else {
			if (superSearchTimeout) {
				clearTimeout(superSearchTimeout);
				superSearchTimeout = null;
			}

			superSearchTimeout = setTimeout(function () {
				onSearch(self.state.value, self.searchTimestamp)
					.then(function (res) {
						if (!self.searchTimestamp || self.searchTimestamp === res.ts) {
							var selected = null;
							var selectedSort = 0;
							_.each(res.data, function (sectionRes, type) {
								if ((!selected || sectionRes.sortOrder < selectedSort) && sectionRes.data.length) {
									selected = type + '-' + sectionRes.data[0].id;
									selectedSort = sectionRes.sortOrder;
								}
							});

							self.setState({
								loading: false,
								results: res.data,
								numResults: res.numResults,
								highlightedResult: selected
							});
						} else {
							self.setState({ loading: false });
						}
					})
					.catch(function () {
						self.setState({
							loading: false,
							error: true
						});
					});
			}, 500);
		}
	},
	keyDown: function (e) {
		// Prevent tab
		if (e.keyCode === 9) {
			e.preventDefault();
			e.stopPropagation();
			if (e.shiftKey) {
				this.moveUp();
			} else {
				this.moveDown();
			}
		}

		// escape
		if (e.keyCode === 27) {
			this.close();
		}

		// enter
		if (e.keyCode === 13) {
			e.preventDefault();
			e.stopPropagation();
			let rowSelector;
			if (this.hasLatestClient) {
				rowSelector = '.SupersearchResultRow--selected';
			} else {
				rowSelector = '.search-result.selected';
			}

			// open selected result
			var resultWrap = jQuery(this.getResults());
			resultWrap.find(rowSelector).click();
		}

		// up or down arrow
		if (e.keyCode === 38 || (e.keyCode === 40 && this.state.numResults)) {
			// preventDefault
			e.preventDefault();

			// up
			if (e.keyCode === 38) {
				this.moveUp();
			} else if (e.keyCode === 40) {
				// down
				this.moveDown();
			}
		}
	},
	escapeRegex: function (text) {
		return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	},
	isMatch: function (text) {
		if (text) {
			text = text.toString();
			var escapedText = this.escapeRegex(this.state.value.toString());
			var reg = new RegExp(escapedText, 'gi');
			var matches = text.match(reg);
			return !!matches && matches.length;
		} else {
			return false;
		}
	},
	diacritic: function (text) {
		return text
			.split('')
			.map(function (char) {
				if ('aâàá'.indexOf(char) > -1) {
					return '[aâàá]';
				}
				if ('eêëèé'.indexOf(char) > -1) {
					return '[eêëèé]';
				}
				if ('[iîïìí]'.indexOf(char) > -1) {
					return '[iîïìí]';
				}
				if ('[oôòó]'.indexOf(char) > -1) {
					return '[oôòó]';
				}
				if ('uûüùú'.indexOf(char) > -1) {
					return '[uûüùú]';
				}
				if ('[wŵẅẁẃ]'.indexOf(char) > -1) {
					return '[wŵẅẁẃ]';
				}
				if ('[yŷÿỳý]'.indexOf(char) > -1) {
					return '[yŷÿỳý]';
				}
				return char;
			})
			.join('');
	},
	highlight: function (text, field) {
		if (text) {
			text = text.toString();
			var escapedText = this.escapeRegex(this.state.value.toString().trim());
			var segments, matches, reg;

			var value = this.state.value;

			if (field === 'email' && value[0] !== '@') {
				escapedText = this.diacritic(escapedText);
				reg = new RegExp('(^|\\.|\\-|@)' + escapedText, 'gi');
				segments = text.split(reg);
				matches = text.match(reg);

				matches = _.map(matches, function (match) {
					if (
						(value[0] !== '.' && match[0] === '.') ||
						(value[0] !== '@' && match[0] === '@') ||
						(value[0] !== '-' && match[0] === '-')
					) {
						var splitString = match.split('');
						splitString.shift();
						return splitString.join('');
					}
					return match;
				});

				segments = _.reduce(
					segments,
					function (res, match, pos) {
						if (match === '@' || match === '-' || match === '.') {
							var index = res.length - 1;
							var last = res[index];
							if (last) {
								res[index] = res[index] + match;
							} else {
								res.push(match);
							}
						} else if (match === '' && res[res.length - 1] === '' && pos !== segments.length - 1) {
							// skipp
						} else {
							res.push(match);
						}

						return res;
					},
					[]
				);
			} else if ((field === 'phone' || field === 'cellPhone') && /[0-9 -+()]{5,}/.test(escapedText)) {
				var phoneRegexp =
					escapedText
						.split('')
						.filter(function (char) {
							return char >= '0' && char <= '9';
						})
						.map(function (char) {
							return '.*' + char;
						})
						.join('') + '.*';

				reg = new RegExp(phoneRegexp, 'gi');

				segments = text.split(reg);
				matches = text.match(reg);
			} else if (field === 'name') {
				escapedText = this.diacritic(escapedText);

				reg = new RegExp('\\b' + escapedText, 'gi');
				segments = text.split(reg);
				matches = text.match(reg);
			} else {
				reg = new RegExp(escapedText, 'gi');
				segments = text.split(reg);
				matches = text.match(reg);
			}

			var res = [];
			_.each(segments, function (segment, i) {
				res.push(<span key={'nomatch-' + i}>{segment}</span>);
				if (segments.length === i + 1) {
					return;
				}
				res.push(
					<span key={'match-' + i} className="ui-match">
						{matches[i]}
					</span>
				);
			});

			return res;
		} else {
			return text;
		}
	},

	onHover: function (e) {
		var sel = this.getResults().querySelector('.search-result.selected');
		if (sel) {
			sel.classList.remove('selected');
		}
		e.currentTarget.classList.add('selected');
	},

	render: function () {
		var self = this;
		var features = Tools.AppService.getAccountSelf().features;
		var results = this.state.results;
		var minCharForSearch = 2;
		var noResults =
			!this.state.loading && this.state.numResults === 0 && this.state.value.length >= minCharForSearch;
		var showSpinner = this.state.loading && this.state.value.length >= minCharForSearch;

		var headerIcons = {
			command: 'terminal',
			client: 'home',
			subAccount: 'subaccounts',
			contact: 'user',
			activity: 'activity',
			appointment: 'calendar',
			opportunity: 'pipeline',
			order: 'dollar'
		};

		var formClass = 'search-form';

		if (this.state.open) {
			formClass += ' focused';
		}
		if (this.props.size === 'md') {
			formClass += ' md';
		}

		var resRows = [];
		if (
			this.state.value.length >= minCharForSearch &&
			this.state.numResults !== 0 &&
			!this.state.loading &&
			!this.state.error
		) {
			var res = _.chain(results).sortBy('sortOrder').values().value();
			_.each(res, sectionRes => {
				var type = sectionRes.type;

				var numberOfLoadingRows = 0;
				if (type !== 'command' && self.state.limits[type] - sectionRes.metadata.limit !== 0) {
					numberOfLoadingRows = Math.min(
						self.state.results[type].metadata.total - sectionRes.metadata.limit,
						5
					);
				}

				if (sectionRes.metadata.total) {
					var section = [
						<SupersearchResultHeader
							key={'header-' + type}
							title={`${sectionRes.metadata.total} ${self.lang[type].toLowerCase()}`}
							icon={headerIcons[type]}
						/>,
						_.map(sectionRes.data, (row, i) => {
							if (this.hasLatestClient && rowComponents[type]) {
								const NewComponent = rowComponents[type].component;
								const selected =
									type !== 'command' ? type + '-' + row.id === this.state.highlightedResult : i === 0;
								return (
									<NewComponent
										key={'results-' + type + '-' + i}
										item={row}
										onClick={() => this.resultClick(row, type)}
										searchStr={this.state.value}
										customFields={this.props.searchableFields[rowComponents[type].fieldKey]}
										closeSearch={this.close}
										defaultCurrency={this.currency}
										selected={selected}
									/>
								);
							}
							const Component = ReactTemplates.navbar.searchRows[type];
							return (
								<Component
									obj={row}
									resultClick={self.resultClick}
									highlight={self.highlight}
									isMatch={self.isMatch}
									lang={self.lang}
									key={'results-' + type + '-' + i}
									customFields={sectionRes.customFields}
									selected={type + '-' + row.id === self.state.highlightedResult}
									currency={self.currency}
									selfId={self.self.id}
									searchableFields={self.props.searchableFields}
									close={self.close}
									onHover={self.onHover}
								/>
							);
						})
					];
					if (numberOfLoadingRows > 0) {
						var height = 50 * numberOfLoadingRows + 'px';
						section.push(
							<div style={{ height: height }} className="loading" key="loading-more">
								<ReactTemplates.loader />
							</div>
						);
					} else if (sectionRes.metadata.total > results[type].data.length) {
						var numberOfRowsToFetch = Math.min(
							self.state.results[type].metadata.total - sectionRes.metadata.limit,
							5
						);
						section.push(
							<div
								className="search-result more-results"
								onClick={self.showMore.bind(self, type, null)}
								key={`show-more-${type}`}
							>
								{self.lang.get} {numberOfRowsToFetch} {self.lang.more}{' '}
								{numberOfRowsToFetch === 1 ? self.lang.resultSingular[type] : self.lang.result[type]}
							</div>
						);
					}

					resRows.push(section);
				}
			});
		}

		var advancedSearchToggle = null;
		if (features.advancedSearch) {
			advancedSearchToggle = (
				<button id="advanced-toggle" className="btn btn-link" type="button" onClick={this.goToAdvanced}>
					<Icon name="sliders" />
					<span>{self.lang.advancedTitle}</span>
				</button>
			);
		}

		const renderMediumSearchBar = this.props.size === 'md';
		return (
			<form autoComplete="off" className={formClass} role="search" ref="form">
				<div
					id="search-curtain"
					ref="curtain"
					className={this.state.open ? 'visible' : ''}
					onClick={this.close}
				/>
				<ul className="nav-menu small-search">
					<li className="menu-item">
						<a id="search-toggle" onClick={this.open}>
							<b className="icon fa fa-search" />
						</a>
					</li>
				</ul>
				<div className={`navbar-search ${renderMediumSearchBar ? 'medium-search' : 'large-search'}`}>
					<b className="fa fa-search" />
					<input
						autoComplete="upsales-quicksearch"
						type="text"
						className="nav-search-input"
						ref="search-field"
						max="30"
						placeholder={self.lang.placeholder}
						value={this.state.value}
						onChange={this.search}
						onFocus={this.open}
						onKeyDown={this.keyDown}
						data-test-id="quicksearch-input"
					/>
					{advancedSearchToggle}
				</div>
				<div id="search-results" ref="results-wrap">
					{noResults && !this.state.error ? <SupersearchNoResults lang={self.lang} /> : null}

					{this.state.error && (
						<span className="search-error">
							<b className="fa fa-warning" /> {self.lang.searchError}
						</span>
					)}

					{showSpinner ? (
						<div className="loading">
							<ReactTemplates.loader />
						</div>
					) : null}

					{resRows}
					{this.hasLatestClient && !showSpinner && !resRows.length && !noResults && !this.state.error ? (
						<SupersearchHistory
							closeSearch={() => {
								this.close();
								this.setState({ value: '' });
							}}
						/>
					) : null}
				</div>
			</form>
		);
	}
});

ReactTemplates.navbar.searchRows.client = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser')
		};
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	parseAddress: function (o) {
		if (o) {
			return (
				<span className="address-holder">
					{o.country ? <b className={'flag-icon flag-icon-' + o.country.toLowerCase()} /> : null}
					{o.city}
				</span>
			);
		}
	},
	getPhoneNr: function (key, icon) {
		var hl = this.props.highlight;
		var client = this.props.obj;
		var tel = ReactTemplates.TOOLS.upVoice(client, 'client', client[key]);
		return tel ? (
			<a target={tel.target} href={tel.href} onClick={tel.onClick}>
				{icon}
				{hl(client[key], key)}
			</a>
		) : null;
	},
	render: function () {
		var client = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');
		var rawAddress = _.find(client.addresses, { type: 'Visit' });
		var parsedAddress = this.parseAddress(rawAddress);
		var hasMarket = Tools.FeatureHelper.isAvailableProduct('MA');
		const hasInactiveAccounts = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);

		if (hasInactiveAccounts && !client.active) {
			className += ' inactive-result';
		}

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, client, 'client')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4 style={{ marginBottom: '3px' }}>{hl(client.name, 'name')}</h4>
					<span style={{ marginRight: '5px', display: 'inline-block' }}>
						<ReactTemplates.TOOLS.leadStatus
							activity={client.hasActivity}
							activityOld={client.hadActivity}
							appointment={client.hasAppointment}
							appointmentOld={client.hadAppointment}
							opportunity={client.hasOpportunity}
							opportunityOld={client.hadOpportunity}
							order={client.hasOrder}
							orderOld={client.hadOrder}
						/>
					</span>
					{hasMarket ? (
						<span style={{ display: 'inline-block' }}>
							<ReactTemplates.TOOLS.eventTypes account={client} theKey={client.id} />
						</span>
					) : null}
				</div>
				<div className="result-section section-40">
					<div>{parsedAddress}</div>
					<div>
						<span>
							{client.phone ? (
								<span>{this.getPhoneNr('phone', <b className="fa fa-phone fa-fw" />)}</span>
							) : (
								''
							)}
						</span>
					</div>
				</div>
				{hasInactiveAccounts && !client.active ? (
					<div className="result-section section-25">
						<window.InactiveLabel />
					</div>
				) : (
					<ReactTemplates.navbar.userComponent
						users={client.users ? client.users : []}
						noResultLabel={this.lang.noUser}
						title={this.lang.user}
						selfId={this.props.selfId}
					/>
				)}

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.account}
					values={client.custom}
					isMatch={this.props.isMatch}
					hl={hl}
					alwaysShow={true}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.searchRows.contact = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser'),
			noAccount: t('default.noAccount'),
			inactive: t('default.inactive')
		};
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	sendEmail: function (e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.close();
		Tools.$upModal.open('sendEmail', {
			contact: this.props.obj,
			contactId: this.props.obj.id
		});
	},
	getPhoneNr: function (key, icon) {
		var hl = this.props.highlight;
		var contact = this.props.obj;
		var tel = ReactTemplates.TOOLS.upVoice(contact, 'contact', contact[key]);
		return tel ? (
			<a target={tel.target} href={tel.href} onClick={tel.onClick}>
				{icon}
				{hl(contact[key], key)}
			</a>
		) : null;
	},
	render: function () {
		var contact = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');
		var hasMarket = Tools.FeatureHelper.isAvailableProduct('MA');

		if (!contact.active) {
			className += ' inactive-result';
		}

		var eventTypeProps = {
			account: contact,
			theKey: contact.id
		};

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, contact, 'contact')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4>
						<span className="contact-name">{hl(contact.name, 'name')}</span>
						{hasMarket ? <ReactTemplates.TOOLS.eventTypes {...eventTypeProps} /> : null}
						<span className="contact-score">{contact.score + 'p'}</span>
					</h4>
					<span className="result-subtitle uppercase">
						{contact.client ? contact.client.name : this.lang.noAccount}
					</span>
				</div>
				{contact.active ? (
					<div className="result-section section-20">
						<span>
							{contact.phone ? (
								<span>{this.getPhoneNr('phone', <b className="fa fa-phone fa-fw" />)}</span>
							) : (
								''
							)}
						</span>
						<span>
							{contact.cellPhone ? (
								<span>{this.getPhoneNr('cellPhone', <b className="fa fa-mobile fa-fw" />)}</span>
							) : (
								''
							)}
						</span>
					</div>
				) : null}
				{contact.active ? (
					<div className="result-section section-20">
						<span className="single-row">
							{contact.email ? (
								<a onClick={this.sendEmail}>
									<b className="fa fa-envelope" /> {hl(contact.email, 'email')}
								</a>
							) : (
								''
							)}
						</span>
					</div>
				) : null}
				{contact.active ? (
					<ReactTemplates.navbar.userComponent
						users={contact.client && contact.client.users ? contact.client.users : []}
						noResultLabel={this.lang.noUser}
						title={this.lang.user}
						selfId={this.props.selfId}
					/>
				) : (
					<div className="result-section section-65">
						<window.InactiveLabel />
					</div>
				)}

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.contact}
					values={contact.custom}
					isMatch={this.props.isMatch}
					hl={hl}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.searchRows.activity = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser'),
			noAccount: t('default.noAccount'),
			at: t('default.at').toLowerCase(),
			ended: t('default.ended'),
			for: t('default.for')
		};

		this.customerId = Tools.AppService.getCustomerId();
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	getDate: function (momentDate) {
		var str = '';
		if (momentDate.isValid()) {
			// If date is in future we display date calendar
			var today = new Date();
			if (
				momentDate.isSame(today, 'day') ||
				momentDate.isAfter(today) /* || (momentDate.isBefore(today) && momentDate.diff(today, 'months') >= 0)*/
			) {
				var d = momentDate.calendar();

				// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
				str = <span>{d !== '0' ? <b>{d}</b> : momentDate.format('L')}</span>; // {' '}{momentDate.format('HH:mm')}
			} else {
				// check for IE since moment obj becomes weird in IE..
				str = Tools.browserService.isIE()
					? moment(momentDate.format('YYYY-MM-DD')).fromNow()
					: momentDate.fromNow(); // else we display ex. "4 years ago"
				str = this.lang.ended + ' ' + this.lang.for.toLowerCase() + ' ' + str;
			}
		}

		return <span>{str}</span>;
	},
	render: function () {
		var activity = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');
		var dateStr = this.getDate(moment(activity.date));

		if (activity.closeDate) {
			className += ' inactive-result';
		}

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, activity, 'activity')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4 style={{ marginBottom: '3px' }}>{hl(activity.description)}</h4>
					<span className="result-subtitle">
						<b className="fa fa-calendar-o" /> {dateStr}
					</span>
				</div>
				<div className="result-section section-40">
					<span>
						{activity.contacts && activity.contacts.length ? (
							<span>
								<ReactTemplates.TOOLS.contactHref
									onClick={this.props.close}
									customerId={this.customerId}
									id={activity.contacts[0].id}
									text={activity.contacts[0].name}
								/>{' '}
								{this.lang.at}
							</span>
						) : (
							this.lang.noContacts
						)}
					</span>
					<span className="result-subtitle">
						{activity.client ? activity.client.name : this.lang.noAccount}
					</span>
				</div>
				<ReactTemplates.navbar.userComponent
					users={activity.users ? activity.users : []}
					noResultLabel={this.lang.noUser}
					title={this.lang.user}
					selfId={this.props.selfId}
				/>

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.activity}
					values={activity.custom}
					isMatch={this.props.isMatch}
					hl={hl}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.searchRows.opportunity = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser'),
			noAccount: t('default.noAccount')
		};
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	render: function () {
		var opportunity = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');

		if (opportunity.probability === 0) {
			className += ' inactive-result';
		}

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, opportunity, 'opportunity')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4>{hl(opportunity.description)}</h4>
					<span className="result-subtitle uppercase">
						{opportunity.client ? opportunity.client.name : this.lang.noAccount}
					</span>
				</div>
				<div className="result-section section-20">
					<span className="single-row">
						{Tools.$filter('currencyFormat')(
							Math.round(opportunity.orderValue * 100) / 100,
							opportunity.currency ? opportunity.currency : this.props.currency
						)}
					</span>
				</div>
				<div className="result-section section-20">
					<span>{opportunity.stage ? opportunity.stage.name : ''}</span>
					<span className="result-subtitle">{opportunity.stage ? opportunity.probability + '%' : ''}</span>
				</div>
				<ReactTemplates.navbar.userComponent
					users={opportunity.user ? [opportunity.user] : []}
					noResultLabel={this.lang.noUser}
					title={this.lang.user}
					selfId={this.props.selfId}
				/>

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.opportunity}
					values={opportunity.custom}
					isMatch={this.props.isMatch}
					hl={hl}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.searchRows.order = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser'),
			noAccount: t('default.noAccount')
		};
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	render: function () {
		var order = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, order, 'order')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4>{hl(order.description)}</h4>
					<span className="result-subtitle uppercase">
						{order.client ? order.client.name : this.lang.noAccount}
					</span>
				</div>
				<div className="result-section section-40">
					<span>
						{Tools.$filter('currencyFormat')(
							Math.round(order.orderValue * 100) / 100,
							order.currency ? order.currency : this.props.currency
						)}
					</span>
					<span className="result-subtitle">{moment(order.date).format('L')}</span>
				</div>
				<ReactTemplates.navbar.userComponent
					users={order.user ? [order.user] : []}
					noResultLabel={this.lang.noUser}
					title={this.lang.user}
					selfId={this.props.selfId}
				/>

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.order}
					values={order.custom}
					isMatch={this.props.isMatch}
					hl={hl}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.searchRows.appointment = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			noUser: t('default.noUser'),
			noAccount: t('default.noAccount'),
			noContacts: t('default.noContacts'),
			more: t('default.more2').toLowerCase()
		};

		this.customerId = Tools.AppService.getCustomerId();
	},
	resultClick: function (item, type) {
		this.props.resultClick(item, type);
	},
	getDate: function (momentDate) {
		var str = '';
		if (momentDate.isValid()) {
			// If date is in future we display date calendar
			var today = new Date();
			if (
				momentDate.isSame(today, 'day') ||
				momentDate.isAfter(today) /* || (momentDate.isBefore(today) && momentDate.diff(today, 'months') >= 0)*/
			) {
				var d = momentDate.calendar();

				// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
				str = <span>{d !== '0' ? <b>{d}</b> : momentDate.format('L LT')}</span>;
			} else {
				// check for IE since moment obj becomes weird in IE..
				str = Tools.browserService.isIE()
					? moment(momentDate.format('YYYY-MM-DD')).fromNow()
					: momentDate.fromNow(); // else we display ex. "4 years ago"
			}
		}

		return <span>{str}</span>;
	},
	render: function () {
		var appointment = this.props.obj;
		var hl = this.props.highlight;
		var className = 'search-result' + (this.props.selected ? ' selected' : '');
		var contactText = '';
		var appointmentDate = moment(appointment.date);
		var dateStr = this.getDate(appointmentDate);
		if (appointment.contacts.length > 1) {
			contactText = ' +' + (appointment.contacts.length - 1) + ' ' + this.lang.more;
		}

		if (moment().isAfter(appointmentDate)) {
			className += ' inactive-result';
		}

		return (
			<div
				className={className}
				onClick={this.resultClick.bind(this, appointment, 'appointment')}
				onMouseOver={this.props.onHover}
			>
				<div className="result-section section-35">
					<h4>{hl(appointment.description)}</h4>
					<span className="result-subtitle">
						{dateStr}
						{appointment.location ? ' - ' + appointment.location : null}
					</span>
				</div>
				<div className="result-section section-40">
					<span>
						{appointment.contacts.length ? (
							<span>
								<ReactTemplates.TOOLS.contactHref
									onClick={this.props.close}
									customerId={this.customerId}
									id={appointment.contacts[0].id}
									text={appointment.contacts[0].name}
								/>{' '}
								{contactText}
							</span>
						) : (
							this.lang.noContacts
						)}
					</span>
					<span className="result-subtitle uppercase">
						{appointment.client ? appointment.client.name : this.lang.noAccount}
					</span>
				</div>
				<ReactTemplates.navbar.userComponent
					users={appointment.users || []}
					noResultLabel={this.lang.noUser}
					title={this.lang.user}
					selfId={this.props.selfId}
				/>

				<ReactTemplates.navbar.cfResults
					customFields={this.props.searchableFields.appointment}
					values={appointment.custom}
					isMatch={this.props.isMatch}
					hl={hl}
				/>
			</div>
		);
	}
});

ReactTemplates.navbar.userComponent = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			and: t('default.and').toLowerCase(),
			more: t('default.more2').toLowerCase()
		};
	},
	setWidth: function () {
		if (!this._hovTrig) {
			return;
		}
		var hovTriggerWidth = this._hovTrig.clientWidth;
		var totWidth = this._labelWrap.clientWidth;
		var label = this._label;
		label.style.width = totWidth - hovTriggerWidth - 1 + 'px';
	},
	componentDidMount: function () {
		this.setWidth();
	},
	componentDidUpdate: function () {
		this.setWidth();
	},
	render: function () {
		var self = this;
		var users = this.props.users;
		var meInArray = _.find(users, { id: this.props.selfId });
		var user = null;
		var label = '';
		var classNames = 'result-section section-25 user-section';
		var andMore = null;
		var tooltip = null;

		if (meInArray) {
			user = meInArray;
			label = user.name;
			classNames += ' blue';
		} else if (users.length) {
			user = users[0];
			label = user.name;
		}
		if (user) {
			if (users.length > 1) {
				andMore = (
					<span
						className="and-more"
						ref={function (ref) {
							self._hovTrig = ref;
						}}
					>
						{' ' + this.lang.and + ' ' + (users.length - 1) + ' ' + this.lang.more}
					</span>
				);

				tooltip = _.map(
					_.filter(users, function (u) {
						return u.id !== self.props.selfId;
					}),
					function (u) {
						return u.name;
					}
				).join('\n');
			}
		}

		var content = (
			<div>
				<ReactTemplates.TOOLS.avatar user={user} size={20} avatarService={Tools.avatarService} />
				<span
					ref={function (ref) {
						self._labelWrap = ref;
					}}
					className={user ? 'name-wrap' : 'no-user'}
				>
					<label className="uppercase">{this.props.title}</label>
					{user ? (
						<span>
							<span
								ref={function (ref) {
									self._label = ref;
								}}
								className="name-label"
							>
								{label}
							</span>
							{andMore}
						</span>
					) : (
						this.props.noResultLabel
					)}
				</span>
				<div className="clearfix" />
			</div>
		);

		return (
			<div className={classNames}>
				{andMore ? ReactTemplates.TOOLS.withTooltip(content, tooltip, { placement: 'left' }) : content}
			</div>
		);
	}
});

ReactTemplates.navbar.cfResults = window.ReactCreateClass({
	render: function () {
		var self = this;
		var matches = [];

		if (self.props.customFields) {
			_.each(self.props.customFields, function (field, i) {
				var valObj = _.find(self.props.values, { fieldId: field.id });
				if (valObj && (self.props.isMatch(valObj.value) || self.props.alwaysShow)) {
					matches.push(
						<span className="field" key={'result-custom-' + i}>
							<span>{field.name}</span>
							{': '}
							<span>{self.props.hl(valObj.value)}</span>
						</span>
					);
				}
			});
		}

		return matches.length ? (
			<div className="custom-fields">
				{matches}
				<div className="clearfix" />
			</div>
		) : null;
	}
});

'use strict';

angular.module('services.mailBodyParser', []).service('MailBodyParser', [
	function () {
		var instance = {};

		var parse = function (text, mail) {
			if (!text) {
				text = '';
			}
			if (!mail.tags) return text;

			var validTags = mail.tags;

			var re = /{{(.*?)}}/g; // match {{*.*}}
			var tags = text.match(re, text); // all tags with a dot

			// remove unsub in previews for now
			text = text.replace(/{{UNSUB}}/g, '');

			if (tags && tags.length) {
				angular.forEach(tags, function (tag) {
					var parsedTag = tag.replace('{{', '').replace('}}', '');
					// validate tag
					var validTag = _.find(validTags, { tag: parsedTag });

					if (validTag) {
						text = text.replace(tag, validTag.value);
					} else {
						text = text.replace(tag, '');
					}
				});
			}

			return text;
		};

		instance.parse = function (mail) {
			var parsed = parse(mail.body, mail);
			//If snap shot id is missing, this is an 'old' mail. Assume non-html content.
			if (!mail.isMap) {
				parsed = '<div>' + parsed + '</div>';
			}

			// Fix links
			var wrapper = angular.element('<div>' + parsed + '</div>');
			var links = wrapper.find('a');
			if (links.length) {
				links.attr('target', '_blank');
				parsed = wrapper.html();
			}
			return parsed;
		};

		instance.parseSubject = function (mail) {
			var parsed = parse(mail.subject, mail);

			return parsed;
		};

		return instance;
	}
]);

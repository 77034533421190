'use strict';

angular.module('domain.userdefobj').factory('EditUserDefinedObjectMeta', [
	'$q',
	'UserDefinedObject',
	'AppService',
	function ($q, UserDefinedObject, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var promises = {};

				if (!$modalParams.typeId) {
					return $q.reject('Missing UDO typeId');
				}
				promises.documentTemplates = $q.when({
					data: AppService.getDocumentTemplates('object' + $modalParams.typeId)
				});

				if ($modalParams.id) {
					promises.object = UserDefinedObject.setId($modalParams.typeId).get($modalParams.id);
				} else {
					var object = UserDefinedObject.setId($modalParams.typeId).new();
					if ($modalParams.object) {
						object = _.merge(object, $modalParams.object);
					}
					promises.object = $q.when({ data: object });
				}

				return $q.all(promises);
			});
		};
	}
]);

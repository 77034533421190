'use strict';

angular.module('upResources').factory('SoliditetClient', [
	'$resource',
	'$q',
	'SoliditetClientAttributes',
	'URL',
	'API',
	'ResourceHelper',
	'ParseGeneric',
	'AppService',
	'$translate',
	function Client(
		$resource,
		$q,
		SoliditetClientAttributes,
		URL,
		API,
		ResourceHelper,
		ParseGeneric,
		AppService,
		$translate
	) {
		var SA = SoliditetClientAttributes();
		var Attribute = SA.attr;
		var Keys = SA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'account',
			createSuccessTitle: 'default.accountCreated',
			createSuccessBody: function () {
				var metadata = AppService.getMetadata();
				metadata.soliditetCredits -= 1;
				AppService.setMetadata(metadata);
				return metadata.soliditetCredits + ' ' + $translate.instant('ads.yourCredits').toLowerCase();
			},
			updateSuccessBody: 'updated.account',
			deleteSucccessTitle: 'default.saved',
			deleteSucccessBody: 'deleted.account',
			createErrorBody: 'saveError.account',
			updateErrorBody: 'saveError.account',
			deleteErrorBody: 'deleteError.account'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var SoliditetClient = null;
				var baseUrl = URL + API + customer;
				var soliditetUrl = URL + API + customer + '/soliditet/';

				SoliditetClient = $resource(
					soliditetUrl + 'clients/:id',
					{},
					{
						buy: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', { isArray: false, custom: 'account' })
						},
						refresh: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', { isArray: false, custom: 'account' })
						},
						preview: { method: 'GET', isArray: false, url: soliditetUrl + 'search' },
						searchBuy: { method: 'POST', isArray: false, url: soliditetUrl + 'search/buy' },
						pending: { method: 'GET', isArray: false, url: baseUrl + '/worker/status/soliditet/pending' },
						findMatches: { method: 'GET', isArray: false, url: soliditetUrl + 'matcher' },
						updateMatches: { method: 'PUT', isArray: false, url: soliditetUrl + 'matcher' },
						searchMatches: { method: 'GET', isArray: false, url: soliditetUrl + 'matcher/search' }
					}
				);

				instance.findMatches = function (filter, options) {
					var params = _.extend(filter, options);
					return SoliditetClient.findMatches(params).$promise;
				};

				instance.searchMatches = function (filter, options) {
					const params = _.extend(filter, options);
					return SoliditetClient.searchMatches(params).$promise;
				};

				instance.updateMatches = function (filter, options) {
					var params = _.extend(filter, options);
					var promise = SoliditetClient.updateMatches(params).$promise;
					promise
						.then(function (res) {
							Tools.$rootScope.$broadcast('matcher.updated', res);
							return res;
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, true, err);
						});
					return promise;
				};

				instance.find = function (filter, options) {
					var params = _.extend(filter, options);
					return SoliditetClient.get(params).$promise;
				};

				instance.preview = function (filter, options) {
					var params = _.extend(filter, options);
					return SoliditetClient.preview(params).$promise;
				};

				instance.get = function (id) {
					return SoliditetClient.get({ id: id }).$promise;
				};

				instance.buy = function (duns, properties, options) {
					const promise = SoliditetClient.buy({
						duns: duns,
						properties: properties,
						options: options || {}
					}).$promise;

					promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, true);
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, true, err);
						});

					return promise;
				};

				instance.refresh = function (clientId, properties, options, opts) {
					const promise = SoliditetClient.refresh(
						{ id: clientId },
						{ properties: properties, options: options || {} }
					).$promise;

					promise
						.then(function (res) {
							// Notify user
							helper.onSave(opts, res.data, false);
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(opts, false, err);
						});

					return promise;
				};

				instance.buyPreview = function (filters, properties, options) {
					return SoliditetClient.searchBuy({ filters: filters, properties: properties, options: options })
						.$promise;
				};

				instance.pending = function () {
					return SoliditetClient.pending({}).$promise;
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upDirectives').directive('upFormSubmitFields', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/templates/upFormSubmitFields.tpl.html?file'),
		require: 'upFormSubmitFields',
		scope: {
			input: '=upFormSubmitFields',
			contact: '=contact',
			indent: '=indent',
			refererBreakLine: '=refererBreakLine'
		},
		controller: [
			'$scope',
			'$translate',
			'AppService',
			function ($scope, $translate, AppService) {
				// $scope.input = { submit, form }

				var translate = function (value, dt) {
					if (dt === 'checkbox' && value === 'on') {
						return $translate.instant('formSubmit.on');
					}
					return value;
				};

				var findValue = function (field) {
					var name = field.name;
					var value = _.find($scope.input.submit.fieldValues, { name: name });
					if (!value && field.datatype !== 'optIn') {
						return '';
					} else if (field.approvedDate) {
						return $translate.instant('contact.optin.general.approval') + ' ' + field.approvedDate;
					} else if (field.datatype === 'optIn') {
						return $translate.instant(
							'contact.optin.general.' + (value && value.value === 'on' ? 'pending' : 'decline')
						);
					}
					return translate(value.value, field.datatype);
				};

				$scope.mappedSubmit = {
					meta: {
						referer: findValue({ name: 'Meta.referer' })
					},
					fields: []
				};

				_.forEach($scope.input.form.fields, function (field) {
					if (field.name.indexOf('Prefs.') === 0 || field.datatype === 'password') {
						// remove prefs field, we dont need them
						return;
					} else if (field.datatype === 'optIn') {
						var optinApproved = _.find($scope.input.approvedOptins, { options: field.options });
						if (optinApproved) {
							field.approvedDate = moment(optinApproved.regDate).format('L LT');
						}
					} else if (field.name.indexOf('.custom_')) {
						const [type, , fieldId] = field.name.split(/[._]/);
						const id = parseInt(fieldId);
						const customFields = AppService.getCustomFields(type.toLowerCase()) ?? [];
						const customField = customFields.find(customField => customField.id === id);
						field.customField = customField;
					}

					$scope.mappedSubmit.fields.push({
						title: field.title,
						name: field.name,
						value: findValue(field),
						datatype: field.datatype,
						customField: field.customField || null
					});
				});
			}
		]
	};
});

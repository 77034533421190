const Config = () => ({
	client: {
		selected: 12
	},
	activity: { active: true },
	appointment: { active: true },
	opportunity: { active: true },
	optin: { active: true },
	recent: {
		selected: 30
	}
});

export default Config;

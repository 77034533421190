import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, IconName, Link, Text, Tooltip } from '@upsales/components';
import './TodoListTableRowActionButton.scss';
import T from 'Components/Helpers/translate';
import { phoneNumberHelper } from '@upsales/common';
import Todo from 'App/resources/Model/Todo';
import { DefaultButton } from '@upsales/components/Buttons';
import { globalTracker } from 'Helpers/Tracker';
import Contact from 'App/resources/Model/Contact';

type Props = {
	todo: Todo;
};

const TodoListSmallActionButton: React.FC<Props> = ({ todo }) => {
	const classes = new BemClass('TodoListTableRowActionButton');
	const contact = todo.contacts && todo.contacts.length ? todo.contacts[0] : null;
	let linkProps,
		number: string | null = null,
		content: string | null = null,
		icon: IconName | null = null;
	if (todo.type === 'phonecall') {
		if (contact && (contact.phone || contact.cellPhone) && todo.client) {
			icon = 'phone';
			number = contact.cellPhone || contact.phone;
			linkProps = number
				? ReactTemplates.TOOLS.upVoice(
						{ ...contact, client: todo.client as Contact['client'] },
						'contact',
						number
				  )
				: {};
		} else if (todo.client && todo.client.phone) {
			icon = 'phone';
			number = todo.client.phone;
			linkProps = ReactTemplates.TOOLS.upVoice(todo.client, 'client', number);
		}

		if (number) {
			content = `${T('default.call')}`;
		}
	} else if (todo.type === 'appointment' && todo.weblinkUrl) {
		icon = 'video';
		linkProps = { href: todo.weblinkUrl, target: '_blank' };
		content = T('todoListTableRow.join');
	}

	const tooltip = (() => {
		if (number) {
			const num = phoneNumberHelper.format(
				number,
				phoneNumberHelper.getCountryCodeForNumber(number),
				'INTERNATIONAL'
			);
			return `${T('default.call')} ${num}`;
		}
	})();

	return (
		<>
			{content ? (
				<Tooltip title={tooltip} disabled={!tooltip} distance={28}>
					<Link className={classes.b()} {...linkProps}>
						<DefaultButton
							size="sm"
							onClick={() => {
								if (icon === 'phone') {
									globalTracker.track('Todo list - Call action button');
								} else if (icon === 'video') {
									globalTracker.track('Todo list - Join web link action button');
								}
							}}
						>
							<div className={classes.elem('content-wrapper').b()}>
								<Text color="inherit" size="md">
									{icon ? <Icon name={icon} space="mrs" /> : null}
									{content}
								</Text>
							</div>
						</DefaultButton>
					</Link>
				</Tooltip>
			) : null}
		</>
	);
};

export default TodoListSmallActionButton;

import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { init, updateRelations, resetGroupSize } from 'Store/reducers/AccountReducer';
import Bem from '@upsales/components/Utils/bemClass';
import T from '../../Helpers/translate';
import GenericRelationTable from './GenericRelationTable';
import GroupSize from './GroupSize';
import './AccountRelations.scss';

const mapStateToProps = state => ({
	loading: state.Account.loading,
	relations: state.Account.relations,
	customRelations: state.Account.customRelations,
	siblings: state.Account.siblings,
	subsidiaries: state.Account.subsidiaries,
	hasLoaded: state.Account.hasLoaded,
	showGroupSize: state.Account.showGroupSize
});

const mapDispatchToProps = {
	init,
	resetGroupSize,
	updateRelations
};

class AccountRelations extends React.Component {
	constructor(props) {
		super(props);

		const { scope, init, updateRelations } = this.props;

		this.listeners = [
			Tools.$rootScope.$on('account.merged', () => {
				init(scope.AccountCtrl.account);
			}),

			Tools.$rootScope.$on('accountRelation.added', (_e, added) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					scope.AccountCtrl.account.connectedClients.push(added);
				}
			}),

			Tools.$rootScope.$on('accountRelation.updated', (_e, updated) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					if (
						updated.clientId === scope.AccountCtrl.account.id ||
						updated.relatedToClientId === scope.AccountCtrl.account.id
					) {
						const index = _.findIndex(scope.AccountCtrl.account.connectedClients, { id: updated.id });

						if (index !== -1) {
							scope.AccountCtrl.account.connectedClients[index] = updated;
						} else {
							scope.AccountCtrl.account.connectedClients.push(updated);
						}
					}
				}
			}),

			Tools.$rootScope.$on('accountRelation.deleted', (_e, deleted) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					const index = _.findIndex(scope.AccountCtrl.account.connectedClients, { id: deleted.id });

					if (index !== -1) {
						scope.AccountCtrl.account.connectedClients.splice(index, 1);
					}
				}
			})
		];
	}

	componentDidMount() {
		const { init, scope } = this.props;
		init(scope.AccountCtrl.account);
	}

	componentWillUnmount() {
		this.listeners.forEach(listener => listener());
		this.props.resetGroupSize();
	}

	componentDidUpdate(prevProps) {
		const { init, scope } = this.props;

		if (scope.AccountCtrl.account.id !== prevProps.scope.AccountCtrl.account.id) {
			init(scope.AccountCtrl.account);
		}
	}

	render() {
		const MainClass = new Bem('AccountRelations');
		const { subsidiaries, showGroupSize, scope, customRelations, hasLoaded } = this.props;

		if (hasLoaded === false) {
			return null;
		}

		const TableList = [
			<GenericRelationTable
				key="connected-clients"
				account={scope.AccountCtrl}
				data={customRelations}
				title={T('client.connectedClients')}
				buttonText={`${T('add')} ${T('default.relation').toLowerCase()}`}
				openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
				columns={[
					{ title: T('default.name') },
					{ title: T('default.relation') },
					{ title: T('default.status') }
				]}
			/>
		];

		if (subsidiaries.length) {
			TableList.unshift(
				<GenericRelationTable
					key="subsidiaries"
					account={scope.AccountCtrl}
					data={subsidiaries}
					title={T('client.subsidiaries')}
					hideButton
					isSubsidiary
					columns={[{ title: T('default.name') }, { title: T('default.status') }]}
					openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
				/>
			);
		}

		if (showGroupSize) {
			TableList.push(<GroupSize key={'group-tree'} />);
		}

		return <div className={MainClass.b()}>{TableList}</div>;
	}
}

AccountRelations.propTypes = {
	scope: PropType.object,
	init: PropType.func,
	subsidiaries: PropType.array,
	customRelations: PropType.array,
	hasLoaded: PropType.bool,
	updateRelations: PropType.func,
	resetGroupSize: PropType.func,
	showGroupSize: PropType.bool
};

export const detached = AccountRelations;
const Component = connect(mapStateToProps, mapDispatchToProps)(AccountRelations);
window.AccountRelations = Component;
export default Component;

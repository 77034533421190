'use strict';
import { parseCategories } from 'App/resources/parsers/client';

angular.module('upResources').service('ParseHelpers', function () {
	var Instance = {};

	Instance.parseCustom = function (data, customFields) {
		if (!data.custom) {
			return data;
		}

		data.$mappedCustom = {};
		var cfCopy = angular.copy(customFields);
		angular.forEach(cfCopy, function (cf) {
			var valueObj = _.find(data.custom, { fieldId: cf.id });
			var id = parseInt(cf.id);
			cf.fieldId = cf.id;

			// Check default value if array --> make it a string
			if (cf.datatype === 'Select') {
				cf.dropdownDefault = Array.isArray(cf.dropdownDefault) ? cf.dropdownDefault[0] : cf.dropdownDefault;
			} else if (cf.datatype !== 'Calculation') {
				cf.default = Array.isArray(cf.default) ? cf.default[0] : cf.default;
			}

			if (valueObj) {
				cf.value = valueObj.value;
				switch (cf.datatype) {
					case 'Boolean':
						cf.value = cf.value ? true : false;
						break;
					case 'Date':
						cf.value = window.Tools.parseDate(cf.value);
						break;
				}
			}
			data.$mappedCustom[id] = cf;
			data.$mappedCustom[id].id = id;
			data.$mappedCustom[id].visible = cf.visible ? true : false;
			data.$mappedCustom[id].editable = cf.editable ? true : false;
		});

		data.custom = cfCopy;

		return data;
	};

	Instance.parseCategories = parseCategories;

	Instance.parseAddresses = function (obj) {
		obj.$addresses = {};

		if (obj.hasOwnProperty('addresses')) {
			angular.forEach(obj.addresses, function (address) {
				obj.$addresses[address.type] = address;
			});
		}
	};

	return Instance;
});

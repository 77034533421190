'use strict';

ReactTemplates.findProspect.rangeGraph = window.ReactCreateClass({
	chart: null,
	getDefaultProps: function () {
		return {
			startValue: -1,
			endValue: -2
		};
	},
	shouldComponentUpdate: function (nextProps) {
		var shallUpdate =
			nextProps.startValue !== this.props.startValue ||
			nextProps.endValue !== this.props.endValue ||
			this.props.config !== nextProps.config;
		return shallUpdate;
	},

	renderChart: function () {
		var self = this;
		var config = self.props.config;
		var container = self.refs.container;

		if (config.chart) {
			config.chart.animation = false;
		}

		config.series[0].name = this.props.name;

		new Highcharts.Chart(container, config, function () {
			self.chart = this;
			self.setFillZone();
		});
	},
	setFillZone: function () {
		if (this.chart) {
			var config = this.props.config;
			var zones = this.props.config.series[0].zones;
			zones[0].value = this.props.startValue / this.props.stepSize;
			zones[1].value = this.props.endValue / this.props.stepSize;
			this.chart.series[0].update({
				zones: zones
			});
		}
	},
	reflow: function () {
		if (this.chart) {
			var config = this.props.config;
			var container = this.refs.container;
			var reflow = false;
			if (
				config.chart &&
				config.chart.type === 'column' &&
				config.series.length &&
				config.series[0].data.length
			) {
				var newWidth = config.series[0].data.length * 150;
				var pt = container.parentNode.clientWidth / 50;

				this.chart.series[0].update({
					pointWidth: pt
				});
				reflow = true;

				if (newWidth > container.parentNode.clientWidth) {
					// Set new width for chart

					//container.style.width = newWidth +'px'
					reflow = true;
				} else {
					container.style.width = null;
					reflow = true;
				}
			} else {
				container.style.width = null;
				reflow = true;
			}

			if (reflow) {
				this.chart.reflow(); // reflow chart
			}
		}
	},
	componentDidMount: function () {
		this.renderChart();
	},
	componentDidUpdate: function () {
		this.renderChart();
		//this.setFillZone();
	},
	render: function () {
		return <div ref="container" key={this.props.name}></div>;
	}
});

import FindProspectsGroupTree from 'Domain/crm/client/findProspects/FindProspectsGroupTree';
import { Button, ButtonGroup, Icon, Tooltip, Input, Block } from '@upsales/components';
import SharedViews from 'Components/SharedViews';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';

ReactTemplates.findProspect = {};

ReactTemplates.findProspect.root = window.ReactCreateClass({
	/** Custom functions **/
	setRef: function (name, ref) {
		this[name] = ref;
	},
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	/** React functions **/
	getInitialState: function () {
		var rootData = this.props.rootData;

		return {
			sidebarMinimized: false,
			showInitialPlaceholder:
				rootData.selectedView && rootData.selectedView.id === 'standard1' && !rootData.viewIsDirty
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		var rootData = newProps.rootData;
		var showInitialPlaceholder =
			rootData.selectedView &&
			rootData.selectedView.id === 'standard1' &&
			!rootData.viewIsDirty &&
			rootData.initialRender;
		if (showInitialPlaceholder !== this.state.showInitialPlaceholder) {
			this.setState({
				showInitialPlaceholder: showInitialPlaceholder
			});
		}
	},
	UNSAFE_componentWillMount: function () {
		this.lang = {
			findProspects: T('default.findProspects'),
			yourSelection: T('soliditet.yourSelection'),
			add: T('default.add'),
			addAll: T('soliditet.addAll'),
			addSelected: T('soliditet.addSelected'),
			allMatches: T('soliditet.allMatches'),
			doesNotExistinUpales: T('soliditet.doesNotExistinUpales'),
			needUpdate: T('soliditet.needUpdate'),
			startInfo: T('soliditet.startInfo'),
			startInfoTitle: T('soliditet.startInfoTitle'),
			client: T('default.client').toLowerCase(),
			clients: T('default.clients').toLowerCase(),
			tooManyResultsToShowOnlyNew: T('soliditet.tooManyResultsToShowOnlyNew'),
			clientSearch: T('client.search')
		};

		this.hasGroupTree = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_TREE);
	},
	componentWillUnmount: function () {
		// Unsubscribe events here
		if (this._tableWrapper) {
			var tableWrapper = this._tableWrapper;
			tableWrapper.removeEventListener('scroll', this.scrollTable);
			window.removeEventListener('resize', this.resize);
		}
	},
	resizeTimeout: null,
	tableHasInit: false,
	componentDidMount: function () {
		this.doFastSearch = _.debounce(this.doFastSearch, 300);

		if (this._tableWrapper) {
			this.tableHasInit = true;

			var tableWrapper = this._tableWrapper;
			tableWrapper.addEventListener('scroll', this.scrollTable);
			window.addEventListener('resize', this.resize);

			var table = ReactDOM.findDOMNode(this._table);
			if (table) {
				var ths = table.querySelectorAll('.th-inner');
				// calc initial left for each th data-init-left
				_.each(ths, function (th) {
					th.style.left = '';
					th.dataset.initLeft = th.offsetLeft;
				});
			}
		}
	},
	componentDidUpdate: function () {
		if (this._tableWrapper) {
			if (!this.tableHasInit) {
				var tableWrapper = this._tableWrapper;
				tableWrapper.addEventListener('scroll', this.scrollTable);
				window.addEventListener('resize', this.resize);

				this.tableHasInit = true;
			}

			var table = ReactDOM.findDOMNode(this._table);
			if (table) {
				var ths = table.querySelectorAll('.th-inner');
				tableWrapper = this._tableWrapper;
				// calc initial l0eft for each th data-init-left

				_.each(ths, function (th) {
					th.style.left = '';
					th.dataset.initLeft = th.offsetLeft;
					th.style.left = parseInt(th.dataset.initLeft) - tableWrapper.scrollLeft + 'px';
				});
			}
		}
	},
	resize: function () {
		var self = this;

		if (self.resizeTimeout) {
			clearTimeout(self.resizeTimeout);
		}
		self.resizeTimeout = setTimeout(function () {
			var table = ReactDOM.findDOMNode(self._table);
			if (table) {
				var ths = table.querySelectorAll('.th-inner');
				var tableWrapper = self._tableWrapper;
				// calc initial left for each th data-init-left
				tableWrapper.scrollLeft = 0;
				_.each(ths, function (th) {
					th.style.left = '';
					th.dataset.initLeft = th.offsetLeft;
				});
			}
		}, 100);
	},
	scrollTable: function () {
		// update th left
		var tableWrapper = this._tableWrapper;
		var table = ReactDOM.findDOMNode(this._table);
		if (table) {
			var ths = table.querySelectorAll('.th-inner');
			_.each(ths, function (th) {
				th.style.left = parseInt(th.dataset.initLeft) - tableWrapper.scrollLeft + 'px';
			});
		}
	},
	toggleSidebarMinimized: function () {
		this.setState({
			sidebarMinimized: !this.state.sidebarMinimized
		});
	},
	enableNotInUpsales: function () {
		this.props.rootData.updateNotInUpsales(true);
	},
	disableNotInUpsales: function () {
		this.props.rootData.updateNotInUpsales(false);
	},
	searchCompany: function (event) {
		var name = event.target.value;
		this.doFastSearch(name);
	},
	doFastSearch: function (name) {
		if (name && name.length > 2) {
			this.props.rootData.filterChange('Client', { value: name.toLowerCase(), comparisonType: 'WildcardEnd' });
		} else if (!name) {
			this.props.rootData.filterChange('Client', { value: null });
		}
	},
	addFromTree: function (selected) {
		this.props.rootData.dataMethods.buyFromTree(selected);
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var currency = rootData.filters.Country
			? _.find(rootData.countries, { value: rootData.filters.Country.value })
			: null;
		currency = currency ? currency.currency : 'SEK'; // fallback

		var viewPicker = null;

		if (rootData.selectedView) {
			viewPicker = React.createElement(ReactTemplates.TOOLS.viewPicker, {
				views: rootData.availableViews,
				selected: rootData.selectedView,
				viewChanged: rootData.viewIsDirty,
				setView: rootData.viewMethods.selectView,
				saveViewAs: rootData.viewMethods.createView,
				saveView: rootData.viewMethods.updateView,
				deleteView: rootData.viewMethods.deleteView,
				setAsDefault: rootData.viewMethods.toggleDefaultView,
				editView: rootData.viewMethods.editView,
				resetView: rootData.viewMethods.resetView,
				tools: tools
			});

			if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
				var selectedView = Object.assign(rootData.selectedView, { filters: rootData.filters });

				viewPicker = React.createElement(ReduxRootComponent, {
					hasChanged: rootData.viewIsDirty,
					changeView: rootData.viewMethods.selectView,
					type: 'soliditet',
					component: SharedViews,
					selectedView: selectedView,
					list: [],
					hideSubtitle: true,
					wrapperId: 'find-prospects-picker'
				});
			}
		}

		var addButtonText = tools.$multiSelect.selected.length
			? this.lang.add +
			  ' ' +
			  tools.$multiSelect.selected.length +
			  ' ' +
			  (tools.$multiSelect.selected.length === 1 ? this.lang.client : this.lang.clients)
			: this.lang.addAll;

		var header = (
			<div id="find-prospects-header" className="up-header up-header-sm border-top fixed">
				{viewPicker}
				{this.hasGroupTree && !this.state.showInitialPlaceholder ? (
					<Button
						disabled={rootData.tableLoading}
						color="green"
						shadow="none"
						onClick={rootData.dataMethods.buySelected}
						className="find-prospects-header__add-btn"
					>
						<Icon name="check" space="mrs" />
						{addButtonText}
					</Button>
				) : null}
			</div>
		);

		var washWarning = false;
		if (
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOLIDITET_MATCHER) &&
			rootData.ratioOfAccountsWithDunsNos.ratio < rootData.acceptedRatioOfAccountsWithDunsNos
		) {
			washWarning = true;
		}

		var sidebar = React.createElement(ReactTemplates.findProspect.sidebar, {
			rootData: rootData,
			tools: tools,
			className: this.state.sidebarMinimized ? 'minimized' : '',
			washWarning: washWarning
		});

		var columnTemplates = _.cloneDeep(rootData.attributes);

		var tableTools = _.clone(tools);
		tableTools.opts = rootData.tableOptions;
		tableTools.opts.currency = currency;
		tableTools.hasGroupTree = this.hasGroupTree;
		tableTools.openingGroup = rootData.openingGroup;
		tableTools.sort = rootData.dataMethods.sort;

		var table = React.createElement(ReactTemplates.table, {
			data: rootData.data,
			currentSorting: rootData.sorting[0],
			columns: rootData.columns,
			columnTemplates: columnTemplates,
			template: 'soliditetRow',
			categoryTypes: [],
			attrs: rootData.attributes,
			sticky: true,
			tools: tableTools,
			loading: rootData.tableLoading,
			useReactSupportRows: true,
			ref: this.setRef.bind(this, '_table'),
			skipSort: true
		});

		var searchCompany = (
			<div id="company-search">
				<Input
					icon="search"
					onChange={self.searchCompany}
					placeholder={self.lang.clientSearch}
					color="grey-2"
					noborder={true}
					clear={true}
				/>
			</div>
		);

		var body;

		if (this.state.showInitialPlaceholder) {
			body = React.createElement(ReactTemplates.findProspect.startInfo, {
				infoTitle: self.lang.startInfoTitle,
				infoText: self.lang.startInfo,
				key: 'startInfo'
			});
		} else {
			var pages = Math.max(Math.ceil(rootData.totalClientMatches / rootData.limit), 1);
			const notInUpsalesLimitReached = rootData.totalClientMatches > rootData.NotInUpsalesLimit;

			body = (
				<div id="find-prospects-body">
					<div id="find-prospects-body-header">
						<div style={{ display: 'inline-block', marginTop: '-3px' }}>
							<h2 style={{ marginRight: '10px' }}>{self.lang.yourSelection}</h2>

							<br />

							{!rootData.tableLoading && (
								<span className="color-grey" style={{ marginRight: '10px', fontSize: '14px' }}>
									{self.formatNumber(rootData.totalClientMatches)}
								</span>
							)}
						</div>

						{this.hasGroupTree ? null : (
							<button
								style={{ verticalAlign: 'top' }}
								disabled={rootData.tableLoading}
								className="btn up-btn btn-bright-blue no-shadow"
								onClick={rootData.dataMethods.buySelected}
							>
								<i style={{ marginRight: '5px' }} className="fa fa-check" />
								{addButtonText}
							</button>
						)}

						<div className="pull-right">
							{searchCompany}
							<Block space="mlm">
								<ButtonGroup>
									<Tooltip
										title={this.lang.tooManyResultsToShowOnlyNew}
										position="top"
										disabled={!notInUpsalesLimitReached}
									>
										<Button
											shadow="none"
											color={rootData.NotInUpsales ? 'green' : 'light-grey'}
											disabled={rootData.tableLoading || notInUpsalesLimitReached}
											onClick={self.enableNotInUpsales}
										>
											{this.lang.doesNotExistinUpales}
										</Button>
									</Tooltip>
									<Button
										shadow="none"
										color={rootData.NotInUpsales ? 'light-grey' : 'green'}
										disabled={rootData.tableLoading || notInUpsalesLimitReached}
										onClick={self.disableNotInUpsales}
									>
										{this.lang.allMatches}
									</Button>
								</ButtonGroup>
							</Block>
						</div>
					</div>

					<div id="find-prospects-tabel-wrapper" ref={self.setRef.bind(self, '_tableWrapper')}>
						<div id="find-prospects-header-background" />
						{table}

						{pages > 1 && (
							<ReactTemplates.TOOLS.Paginator
								currentPage={rootData.page}
								max={pages}
								onChange={rootData.paginatorChange}
							/>
						)}
					</div>
				</div>
			);
		}

		const classes = new BemClass('find-prospects').mod({
			'remove-frame': Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME')
		});

		var component;
		if (rootData.featchingData) {
			component = (
				<div id="find-prospects" className={classes.b() + ' card has-sidebar-sm full-size'}>
					<ReactTemplates.findProspect.fetchingView tools={tools} />
				</div>
			);
		} else {
			component = (
				<div id="find-prospects" className={classes.b() + ' card has-sidebar-sm full-size'}>
					{header}
					{sidebar}
					{body}

					<div
						className={this.state.sidebarMinimized ? 'minimize-button minimized' : 'minimize-button'}
						onClick={self.toggleSidebarMinimized}
					>
						{this.state.sidebarMinimized ? (
							<i className="fa fa-arrow-right" />
						) : (
							<i className="fa fa-arrow-left" />
						)}
					</div>

					{this.hasGroupTree ? (
						<FindProspectsGroupTree
							mounted={!!rootData.selectedGroupClient}
							rootDuns={rootData.selectedGroupRootDuns}
							client={rootData.selectedGroupClient}
							clients={rootData.selectedGroupData}
							addingAccount={rootData.addingAccount}
							onClose={rootData.closeGroup}
							onSelect={this.addFromTree}
							onMerge={rootData.beginMerge}
						/>
					) : null}
				</div>
			);
		}

		return component;
	}
});

ReactTemplates.findProspect.dunsWashWarning = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			clientsWithDunsNoInfo: t('soliditet.clientsWithDunsNoInfo'),
			yourClientDataNeedsImprovment: t('soliditet.yourClientDataNeedsImprovment'),
			clientRatioWithDunsNoInfo: t('soliditet.clientRatioWithDunsNoInfo'),
			improveClientData: t('soliditet.improveClientData'),
			iUnderstandTheRisks: t('soliditet.iUnderstandTheRisks'),
			iUnderstandTheRisksInfo: t('soliditet.iUnderstandTheRisksInfo'),
			matchedCompany: t('soliditet.matchedCompany'),
			notMatchedCompany: t('soliditet.notMatchedCompany')
		};
	},
	goToDunsWash: function () {
		this.props.tools.$state.go('matcher');
	},
	render: function () {
		/*<div id='row-7'>
			{ReactTemplates.TOOLS.withTooltip(
				<button className='btn btn-link' onClick={this.props.acceptToProcedWithLowDunsNoRatio}>
					{this.lang.iUnderstandTheRisks}
				</button>,
				this.lang.iUnderstandTheRisksInfo, {placement: 'bottom'
			})}
		</div>*/

		return (
			<div id="duns-wash-warning-view">
				<div id="row-0">
					{ReactTemplates.TOOLS.withTooltip(
						<i className="fa fa-question-circle" />,
						this.lang.clientRatioWithDunsNoInfo,
						{ placement: 'bottom' }
					)}{' '}
					{Math.round(this.props.ratioOfAccountsWithDunsNos.ratio * 100)}
					{'%'}
				</div>

				<div id="row-1">
					<div style={{ width: '200px', marginTop: '5px', height: '5px', display: 'inline-block' }}>
						<ReactTemplates.findProspect.progressbar
							tools={this.props.tools}
							progress={this.props.ratioOfAccountsWithDunsNos.ratio}
						/>
					</div>
				</div>

				<div id="row-2">{this.lang.yourClientDataNeedsImprovment}</div>

				<div id="row-3">{this.lang.clientsWithDunsNoInfo}</div>

				<div id="row-4">
					<i className="fa fa-check-circle" /> {this.props.ratioOfAccountsWithDunsNos.withDunsNo}{' '}
					{this.lang.matchedCompany.toLowerCase()}
				</div>

				<div id="row-5">
					<i className="fa fa-question-circle" />{' '}
					{this.props.ratioOfAccountsWithDunsNos.all - this.props.ratioOfAccountsWithDunsNos.withDunsNo}{' '}
					{this.lang.notMatchedCompany.toLowerCase()}
				</div>

				<div id="row-6" style={{ paddingBottom: '20px' }}>
					<button onClick={this.goToDunsWash} className="btn up-btn btn-bright-blue no-shadow">
						{this.lang.improveClientData}
					</button>
				</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.progressbar = window.ReactCreateClass({
	setRef: function (name, ref) {
		this[name] = ref;
	},
	calculateWidth: function () {
		var totalWidth = this._progressbar.offsetWidth;
		var leftWidth = Math.round(totalWidth * this.props.progress);

		this._progressbar_left.style.width = leftWidth + 'px';
		//this._progressbar_left.style.height = height + 'px';
	},
	componentDidMount: function () {
		this.calculateWidth();
	},
	render: function () {
		return (
			<span ref={this.setRef.bind(this, '_progressbar')} className="progressbar">
				<span ref={this.setRef.bind(this, '_progressbar_left')} className="progressbar-left" />
			</span>
		);
	}
});

ReactTemplates.findProspect.fetchingView = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			fetchingClients: t('soliditet.fetchingClients'),
			goToClientList: t('soliditet.goToClientList'),
			fetchingInfo: t('soliditet.fetchingInfo')
		};
	},
	goToClientList: function () {
		this.props.tools.$state.go('accounts');
	},
	render: function () {
		return (
			<div id="fetching-view">
				<img src="img/loading-briefcase.png" />

				<div id="title">{this.lang.fetchingClients}</div>

				<div id="subtitle">{this.lang.fetchingInfo}</div>

				<Button shadow="none" onClick={this.goToClientList}>
					{this.lang.goToClientList}
				</Button>
			</div>
		);
	}
});

import t from 'Components/Helpers/translate';

export const getSnoozeText = (text, step) => {
	const unitMapArray = [
		{ day: t('calendar.days'), week: t('date.weeks'), month: t('date.months') },
		{ day: t('calendar.day'), week: t('calendar.week'), month: t('calendar.month') }
	];
	const waitTime = step.waitTime;
	const waitUnit = step.waitUnit;
	const unit = (unitMapArray[waitTime] ? unitMapArray[waitTime][waitUnit] : unitMapArray[0][waitUnit]).toLowerCase();
	return t(text, { num: waitTime, unit });
};

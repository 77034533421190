export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	description: {
		title: 'default.description',
		field: 'description',
		locked: true
	},
	date: {
		title: 'default.date',
		field: 'date'
	},
	endDate: {
		title: 'default.endDate',
		field: 'endDate'
	},
	modDate: {
		title: 'default.modDate',
		field: 'modDate'
	},
	regDate: {
		title: 'default.regDate',
		field: 'regDate'
	},
	users: {
		title: 'default.users',
		field: 'user.name',
		locked: true
	},
	contacts: {
		title: 'default.contact',
		field: 'contact.name'
	},
	account: {
		title: 'default.account',
		field: 'client.name'
	},
	activityType: {
		title: 'default.activityType',
		field: 'activityType.name'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},
	project: {
		title: 'default.project',
		field: 'project.name'
	},
	opportunity: {
		title: 'default.opportunity',
		field: 'opportunity.id'
	},
	outcome: {
		title: 'default.outcome',
		field: 'outcome'
	},
	booker: {
		title: 'report.booker',
		field: 'booker'
	}
};

import _ from 'lodash';
import async from 'async';
import React from 'react';
import ExportContactData, { ExportDataPrintFormat } from 'Components/ExportContactData/ExportContactData';
import logError from '../helpers/logError';

window.ExportContactDataCtrl = $scope => {
	const rootNode = document.getElementById('export-contact-data-modal');
	const t = Tools.$translate;
	const fields = ['title', 'phone', 'cellPhone', 'email'];
	let store;

	const props = {
		t,
		contact: $scope.$modalParams.contact,
		data: [],
		generalData: _.map(_.pick($scope.$modalParams.contact, fields), (value, key) => ({
			title: t('default.' + key),
			value
		})),
		fetching: true,
		fetchingPrintable: false
	};

	const itemMap = {
		// number of activities
		activity: {
			title: t('default.activity'),
			attr: Tools.Activity.attr.contacts,
			find: Tools.Activity.customer($scope.$modalParams.customerId).find,
			fields: ['date', 'description', 'notes'],
			format: row => (
				<div>
					<p>{`${row.date}: ${(row.description || '').replace(/[<>]/g, '')}`}</p>
					<i>
						{_.map((row.notes || '').split('\n'), line => (
							<p>{line}</p>
						))}
					</i>
				</div>
			)
		},
		// number of meetings
		appointment: {
			title: t('default.appointment'),
			attr: Tools.Appointment.attr.contact.attr.id,
			find: Tools.Appointment.customer($scope.$modalParams.customerId).find,
			fields: ['regDate', 'description', 'notes'],
			format: row => (
				<div>
					<p>{`${row.regDate}: ${(row.description || '').replace(/[<>]/g, '')}`}</p>
					<i>
						{_.map((row.notes || '').split('\n'), line => (
							<p>{line}</p>
						))}
					</i>
				</div>
			)
		},
		// number of opportunities/sales
		order: {
			title: t('default.order'),
			attr: Tools.Order.attr.contact,
			find: Tools.Order.customer($scope.$modalParams.customerId).find,
			fields: ['date', 'description'],
			format: row => (
				<p>{`${row.date ? row.date.toLocaleDateString() : ''}: ${(row.description || '').replace(
					/[<>]/g,
					''
				)}`}</p>
			)
		},
		// number of emails
		email: {
			title: t('default.mail'),
			attr: Tools.Mail.attr.contact.attr.id,
			find: Tools.Mail.find,
			fields: ['date', 'subject'],
			format: row => (
				<p>{`${row.date ? moment(row.date).toDate().toLocaleDateString() : ''}: ${(row.subject || '').replace(
					/[<>]/g,
					''
				)}`}</p>
			)
		},
		// number of web site visits
		visit: {
			title: t('default.visit'),
			attr: Tools.Visitor.attr.contact,
			find: Tools.Visitor.find,
			fields: ['startDate'],
			format: row => <p>{row.startDate ? moment(row.startDate).toDate().toLocaleDateString() : ''}</p>
		},
		// number of form submits
		formSubmits: {
			title: t('default.formSubmits'),
			attr: Tools.FormSubmit.attr.contact,
			find: Tools.FormSubmit.find,
			fields: ['regDate', 'form'],
			format: row => (
				<p>{`${row.regDate ? row.regDate.toLocaleDateString() : ''}: ${
					row.form && row.form.title ? row.form.title.replace(/[<>]/g, '') : ''
				}`}</p>
			)
		}
	};

	// Add udo to item map
	_.reduce(
		$scope.$modalParams.UserDefinedObject,
		(res, udo) => {
			res['udo' + udo.id] = {
				title: udo.name,
				attr: Tools.UserDefinedObject.attr.contact,
				find: Tools.UserDefinedObject.setId(udo.id).find,
				fields: ['regDate'],
				format: row => <p>{row.regDate ? row.regDate.toLocaleDateString() : ''}</p>
			};
			return res;
		},
		itemMap
	);

	const fetchAll = (item, rb) => {
		const promise = new Promise((resolve, reject) => {
			let fetch = true;
			const allResults = {
				metadata: {
					total: 0
				},
				data: []
			};
			async.whilst(
				() => fetch,
				callback => {
					rb.offset = allResults.data.length;
					item.find(rb.build())
						.then(result => {
							fetch = result.metadata.offset + result.data.length < result.metadata.total;
							allResults.metadata.total = result.metadata.total;
							allResults.data = allResults.data.concat(result.data);
							callback();
						})
						.catch(err => {
							allResults.error = err;
							callback(err);
						});
				},
				err => {
					if (err) {
						return reject(err);
					}
					resolve(allResults);
				}
			);
		});
		return promise;
	};

	const getDataPromises = onlyMeta => {
		return _.map(itemMap, item => {
			const rb = new Tools.RequestBuilder();
			rb.addFilter(item.attr, rb.comparisonTypes.Equals, $scope.$modalParams.contact.id);
			if (onlyMeta) {
				// If only meta, return requests
				rb.limit = 0;
				return item.find(rb.build());
			} else {
				rb.fields = item.fields;
			}
			// Else fetch all
			return fetchAll(item, rb);
		});
	};

	const actions = {
		openPrintable: () => {
			store.set('fetchingPrintable', true);
			Promise.all(getDataPromises())
				.then(results => {
					const generalData = store.get('generalData');
					const keys = Object.keys(itemMap);
					const entityData = _.reduce(
						results,
						(outArr, entity, i) => {
							const item = itemMap[keys[i]];
							outArr.push({
								title: item.title,
								num: entity.data.length,
								rows: _.map(entity.data, item.format)
							});
							return outArr;
						},
						[]
					);

					const elem = React.createElement(ExportDataPrintFormat, { t, generalData, entityData });
					const iframe = document.createElement('iframe');
					const element = document.getElementById('export-data-printarea');
					element.appendChild(iframe);
					const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
					iframedoc.body.innerHTML = window.renderToStaticMarkup(elem);
					iframe.contentWindow.print();
					setTimeout(() => $scope.resolve(), 100);
				})
				.catch(err => logError(err));
		},
		closeModal: () => {
			$scope.reject();
		}
	};

	const render = store => {
		if (rootNode) {
			ReactDOM.render(React.createElement(ExportContactData, store), rootNode);
		}
	};

	store = new DataStore(render, actions, props);

	$scope.$on('$destroy', function () {
		ReactDOM.unmountComponentAtNode(rootNode);
	});

	const init = () => {
		Promise.all(getDataPromises(true))
			.then(results => {
				const keys = Object.keys(itemMap);
				const data = _.map(keys, (key, index) => {
					return { title: itemMap[key].title, value: results[index].metadata.total };
				});
				store.setStore({ data, fetching: false });
			})
			.catch(() => {
				store.setStore({ data: [], fetching: false });
			});
	};

	init();
};

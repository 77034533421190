import React, { useEffect } from 'react';
import './BillingAddonModal.scss';
import ModalWrap from 'Components/ModalWrap';
import { Modal, ModalHeader, ModalContent, ModalControls, Button, Block, Text, Column, Row } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import T from 'Components/Helpers/translate';
import { Addon } from './Addon';
import { billingTracker } from 'App/babel/helpers/Tracker';

type BillingAddonCardModalProps = {
	abort: string;
	children: React.ReactNode;
	color: 'orange' | 'red' | 'bright-blue' | 'green';
	onConfirm: () => void;
	onAbort: () => void;
	loading: boolean;
	yesDisabled: boolean;
	visible: boolean;
	addon: Addon;
};

const BillingAddonCardModal = ({
	addon,
	color,
	loading,
	children,
	onConfirm,
	onAbort,
	visible,
	abort,
	yesDisabled
}: BillingAddonCardModalProps) => {
	useEffect(() => {
		if (visible) {
			billingTracker.track(billingTracker.events.OPEN_ADDON_TERMS, { addon: addon.alias });
		}
	}, [visible]);

	const billingPeriod = (priceMonthPeriod: number) => {
		switch (priceMonthPeriod) {
			case 12:
				return 'year';
			case 6:
				return 'halfYear';
			case 3:
				return 'quarter';
			case 1:
				return 'month';
		}
	};
	const getTotalPriceTitle = (addon: Addon) => {
		if (!addon) return;
		if (addon.price === 0) {
			return (
				<Text size="xl" bold>
					{T('integration.free')}
				</Text>
			);
		} else {
			return (
				<Text size="xl" color="grey-10">
					<Text size="xl" bold>
						{addon.totalPricePerMonth}
					</Text>
					{`${addon.currency} total per month`}
				</Text>
			);
		}
	};
	const getSubtitle = (addon: Addon) => {
		if (!addon || addon.price === 0) {
			return null;
		}
		if (addon.basedOn === 'activeUsers' && !addon.tier) {
			return (
				<Text size="md" color="grey-10">
					<Text size="md" bold>
						{addon.pricePerMonth}
					</Text>
					{` ${addon.currency} total per user`}
				</Text>
			);
		}
		if (addon.basedOn === 'activeUsers' && addon.tier) {
			return <Text size="sm" color="grey-10">{`${addon.tier.start} - ${addon.tier.end} users per year`}</Text>;
		}
		if (addon.basedOn !== 'activeUsers' && addon.tier) {
			return (
				<Text size="sm" color="grey-10">{`${addon.tier.start} - ${addon.tier.end} used per ${billingPeriod(
					addon.priceMonthPeriod
				)}`}</Text>
			);
		}
	};
	return (
		<ModalWrap isMounted={visible}>
			<Modal id="billing-addon-modal">
				<ModalHeader />
				<ModalContent>{children}</ModalContent>
				<ModalControls>
					<Block className="priceblock" backgroundColor="grey-1" border="s" borderColor="grey-6">
						<Row className="price-row" direction="row">
							<Column className="price-col">
								{getTotalPriceTitle(addon)}
								{getSubtitle(addon)}
							</Column>
							<Column className="button-col">
								{onConfirm ? (
									<Column>
										<Button
											disabled={yesDisabled}
											loading={loading}
											color={color}
											onClick={() => {
												billingTracker.track(billingTracker.events.BOUGHT_ADDON, {
													addon: addon.alias
												});
												onConfirm();
											}}
											size="lg"
										>
											{T('admin.billing.addon.accept_terms_and_add')} {T(addon.name)}
										</Button>
									</Column>
								) : null}
								{onAbort ? (
									<Column>
										<Button
											disabled={loading}
											color="grey"
											type="link"
											size="lg"
											onClick={() => {
												billingTracker.track(billingTracker.events.ABORTED_ADDON_PURCHASE, {
													addon: addon.alias
												});
												onAbort();
											}}
										>
											{translate(abort)}
										</Button>
									</Column>
								) : null}
							</Column>
						</Row>
					</Block>
				</ModalControls>
			</Modal>
		</ModalWrap>
	);
};

BillingAddonCardModal.defaultProps = {
	color: 'orange',
	abort: 'default.abort',
	yesDisabled: false,
	loading: false
};

export default BillingAddonCardModal;

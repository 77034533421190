import { Button } from '@upsales/components';
import PropTypes from 'prop-types';
import React from 'react';

import openModal from 'App/services/Modal';
import logError from 'App/babel/helpers/logError';

class OptInList extends React.Component {
	constructor(props) {
		super(props);

		this.t = Tools.$translate;
		this.lang = {
			optInTemplates: this.t(props.manual !== undefined ? 'admin.manualOptIns' : 'admin.optInTemplates'),
			newOptIn: this.t('admin.newOptIn'),
			manualOptIns: this.t('admin.manualOptIns'),
			nodata: this.t('default.noData'),
			title: this.t('default.title'),
			save: this.t('default.save'),
			cancel: this.t('cancel')
		};

		this.state = {
			sort: 'title',
			descending: true,
			optIns: [],
			inlineOptInEdit: undefined
		};

		const optInAttr = Tools.OptIn.attr;
		const rb = new Tools.RequestBuilder();
		if (props.manual !== undefined) {
			// Manual optins
			rb.addFilter(optInAttr.type, rb.comparisonTypes.Equals, 'manual');
			this.config = {
				fields: [optInAttr.title, {}, optInAttr.approvedCount],
				rb: rb
			};
		} else {
			// Single and double optins
			rb.addFilter(optInAttr.type, rb.comparisonTypes.NotEquals, 'manual');
			this.config = {
				fields: [optInAttr.title, optInAttr.type, optInAttr.approvedCount],
				rb: rb
			};
		}

		this.numFormat = Tools.$filter('numberFormat');

		this.renderRow = this.renderRow.bind(this);
		this.editOptIn = this.editOptIn.bind(this);
		this.fieldFormat = this.fieldFormat.bind(this);
		this.removeOptIn = this.removeOptIn.bind(this);
		this.updateOptIn = this.updateOptIn.bind(this);
		this.closeInlineEdit = this.closeInlineEdit.bind(this);
		this.updateInlineEdit = this.updateInlineEdit.bind(this);

		this.getOptIns(this.buildFilter()).then(res => {
			this.setState({ optIns: res.data });
		});
	}

	buildFilter() {
		const rb = this.config.rb.clone();
		rb.addSort(Tools.OptIn.attr[this.state.sort], this.state.descending);
		return rb;
	}

	getOptIns(rb) {
		return Tools.OptIn.find(rb.build());
	}

	updateInlineEdit(e) {
		this.setState({
			inlineOptInEdit: Object.assign({}, this.state.inlineOptInEdit, { title: e.target.value })
		});
	}

	closeInlineEdit(optIn) {
		if (optIn) {
			this.saveOptIn(optIn).then(optIn => {
				this.updateOptIn(optIn.data);
				this.setState({ inlineOptInEdit: undefined });
			});
		} else {
			this.setState({ inlineOptInEdit: undefined });
		}
	}

	removeOptIn(optIn, e) {
		e.stopPropagation();

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('RemoveAlert', {
				body: 'confirm.removeOptIn',
				onClose: confirmed => {
					if (confirmed) {
						Tools.OptIn.delete(optIn)
							.then(() => {
								const optIns = _.reject(this.state.optIns, { id: optIn.id });
								this.setState({ optIns });
							})
							.catch(err => {
								logError(err, 'Error when deleting opt-in');
							});
					}
				}
			});
			return;
		}

		return Tools.$upModal
			.open('warningConfirm', {
				title: 'default.remove',
				body: 'confirm.removeOptIn',
				resolveTrue: 'default.remove',
				icon: 'fa-warning'
			})
			.then(() => {
				Tools.OptIn.delete(optIn).then(() => {
					const optIns = _.reject(this.state.optIns, { id: optIn.id });
					this.setState({ optIns });
				});
			});
	}

	updateOptIn(optIn) {
		var index = _.findIndex(this.state.optIns, { id: optIn.id });
		optIn.saved = true;
		const optIns = this.state.optIns;
		if (index !== -1) {
			optIns[index] = optIn;
		} else {
			optIns.push(optIn);
		}
		this.setState({ optIns });
	}

	editOptIn(optIn) {
		if (this.props.manual !== undefined) {
			this.setState({ inlineOptInEdit: optIn || { title: '', type: 'manual' } });
		} else {
			Tools.$upModal.open('optIn', { optIn: optIn }).then(this.updateOptIn);
		}
	}

	saveOptIn(optIn) {
		return Tools.OptIn.save(optIn);
	}

	fieldFormat(optIn, field) {
		switch (field.field) {
			case 'title':
				return optIn.title;
			case 'type':
				return this.t('admin.' + optIn.type);
			case 'approvedCount':
				return this.numFormat(optIn.approvedCount);
			default:
				return '';
		}
	}

	renderRow(optIn) {
		if (_.get(this.state.inlineOptInEdit, 'id') === optIn.id) {
			return (
				<InlineOptInEdit
					key={`optIn-edit-${optIn.id}`}
					optIn={this.state.inlineOptInEdit}
					close={this.closeInlineEdit}
					lang={this.lang}
					onChange={this.updateInlineEdit}
					limit={Tools.OptIn.attr.title.limit}
				/>
			);
		} else {
			return (
				<tr
					key={'optIn-' + optIn.id}
					className={'clickable'}
					onClick={this.editOptIn.bind(null, _.cloneDeep(optIn))}
				>
					{_.map(this.config.fields, f => (
						<td key={`opt-in-${optIn.id}-${f.field}`}>{this.fieldFormat(optIn, f)}</td>
					))}
					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							{!optIn.approvedCount ? (
								<button
									className="up-btn btn-link btn-grey delete-trigger"
									onClick={e => this.removeOptIn(optIn, e)}
								>
									<b className="fa fa-trash-o" />
								</button>
							) : null}
						</div>
					</td>
				</tr>
			);
		}
	}

	renderSortIndicator(attr, sortType) {
		let className = 'fa ';
		if (this.state.sort === attr) {
			className += 'fa-sort-' + sortType;
			className += !this.state.descending ? '-desc' : '-asc';
		} else {
			className += 'fa-sort';
		}
		return <b className={className} />;
	}

	updateSort(sort) {
		const descending = sort === this.state.sort && !this.state.descending;
		this.setState({ sort, descending }, () => {
			this.getOptIns(this.buildFilter()).then(res => {
				this.setState({ optIns: res.data });
			});
		});
	}

	render() {
		const { sort, descending, inlineOptInEdit } = this.state;

		const optIns = _.chain(this.state.optIns)
			.sortBy(n => {
				const compare = n[sort];
				return typeof compare === 'string' ? compare.toUpperCase() : compare;
			})
			.map(this.renderRow)
			.value();

		if (!descending) {
			optIns.reverse();
		}

		if (inlineOptInEdit !== undefined && !inlineOptInEdit.id) {
			// If new optin. Add to bottom of list
			optIns.push(
				<InlineOptInEdit
					key={'optIn-edit-' + optIns.length}
					optIn={this.state.inlineOptInEdit}
					close={this.closeInlineEdit}
					lang={this.lang}
					onChange={this.updateInlineEdit}
					limit={Tools.OptIn.attr.title.limit}
				/>
			);
		}

		return (
			<div className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">{this.lang.optInTemplates}</span>
					<div className="pull-right">
						<Button onClick={this.editOptIn.bind(null, null)} size="sm">
							{this.lang.newOptIn}
						</Button>
					</div>
				</div>

				<table>
					{optIns.length ? (
						<thead>
							<tr>
								{_.map(this.config.fields, (f, i) =>
									_.isEmpty(f) ? (
										<th key={'opt-in-field-empty' + i} />
									) : (
										<th
											key={'opt-in-field-' + f.field}
											onClick={this.updateSort.bind(this, f.field)}
											className={'sortable' + (sort === f.field ? ' active' : '')}
										>
											{this.t(f.title)}{' '}
											{this.renderSortIndicator(
												f.field,
												f.displayType === 'number' ? 'numeric' : 'alpha'
											)}
										</th>
									)
								)}
								<th style={{ width: '60px' }} />
							</tr>
						</thead>
					) : null}
					<tbody>
						{optIns.length ? (
							optIns
						) : (
							<tr className="table-no-result">
								<td colSpan={100}>{this.lang.nodata}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}

OptInList.propTypes = {
	manual: PropTypes.any
};

window.OptInList = OptInList;
export default window.OptInList;

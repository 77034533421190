import React from 'react';
import config from 'App/babel/config';
import './IntegrationIframe.scss';

type Props = {
	type: string;
	name: String;
	integrationId: number;
	objectId: number;
};

class IntegrationIframe extends React.Component<Props> {
	render() {
		const { type, name, integrationId, objectId } = this.props;

		let src = `${config.URL}${config.API}function/standardIntegrationIframe/${name}`;
		src += `?typeId=${type}&integrationId=${integrationId}&objectId=${objectId}&userId=${
			Tools.AppService.getSelf().id
		}`;

		return <iframe className="IntegrationIframe" src={src} />;
	}
}

export default IntegrationIframe;

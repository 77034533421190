import {
	AssistChip,
	Block,
	Button,
	Icon,
	Label,
	Row,
	Select,
	Table,
	TableColumn,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	Text
} from '@upsales/components';
import React, { ComponentProps, useState, useEffect, useLayoutEffect } from 'react';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'Store/index';
import { setAdvAssignData, setAssignData } from 'Store/reducers/AssignModalReducer';
import './AssignModalLeadSelectAdvanced.scss';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import type { BasicUserWithPermissions as User } from 'App/resources/Model/User';
import type Role from 'App/resources/Model/Role';
import type { FormField } from 'App/resources/Model/Form';
import Tooltip, { TooltipProps } from '@upsales/components/Tooltip/Tooltip';
import { TextProps } from '@upsales/components/Text/Text';

type RawOption = {
	option: string;
	type: 'user' | 'role';
	id: SelectItem['id'];
};

type SelectOption = {
	option: string;
	type: 'user' | 'role';
	assign: User | Role;
};

type UserSelect = {
	type: 'user' | 'role';
	assign: User | Role;
} & SelectItem;

type FieldSelect = {
	field: FormField;
} & SelectItem;

type UserTree = Awaited<ReturnType<typeof Tools.UserTreeFilterMeta>>['data'];

type OverflowTooltipProps = {
	cutoff: number;
	text: string;
	tooltipProps?: Partial<TooltipProps>;
	textProps?: Partial<TextProps>;
};

const OverflowTooltip = ({ cutoff, text, tooltipProps, textProps }: OverflowTooltipProps) => {
	return (
		<Tooltip title={text} disabled={text.length < cutoff} {...tooltipProps}>
			<Text {...textProps}>{text.length < cutoff ? text : text.slice(0, cutoff - 4) + '...'}</Text>
		</Tooltip>
	);
};

const UserOrRoleSelect = ({ value, onChange, ...selectProps }: Partial<ComponentProps<typeof Select<UserSelect>>>) => {
	const userTreeToSelect = (userTree: UserTree): UserSelect[] =>
		userTree.flatMap(leaf => {
			// Filter inactive users
			if (leaf.isRole || !leaf.ghost) {
				return leaf.isRole
					? {
							// Select component uses 'id' field to generate key, so role id's need to be distinguishable from user id's
							id: 'role' + leaf.$id,
							title: leaf.name,
							type: 'role' as const,
							assign: {
								...leaf,
								id: leaf.$id
							},
							children: userTreeToSelect(leaf.children)
					  }
					: {
							assign: leaf,
							type: 'user' as const,
							id: leaf.id,
							title: leaf.name
					  };
			}
			return [];
		});

	const [options, setOptions] = useState<UserSelect[]>([]);

	useEffect(() => {
		Tools.UserTreeFilterMeta()
			.then(res => {
				setOptions(userTreeToSelect(res.data));
			})
			.catch(() => {});
	}, []);

	return (
		<Select<UserSelect>
			options={options}
			value={value ?? null}
			renderSelectedItem={value => (
				<Text color={value ? 'black' : 'grey-10'} italic={!value} size="sm">
					{value && <Icon name={value?.type === 'user' ? 'user' : 'users'} space="mrs" />}
					{value ? value.assign?.name : T('default.userOrRole')}
				</Text>
			)}
			onChange={item => {
				// Turn role id's back into number before running the onChange
				const newItem = { ...item };
				if (newItem.type === 'role' && typeof newItem.id === 'string') {
					newItem.id = Number(newItem.id.slice(4));
				}
				return onChange?.(newItem);
			}}
			{...selectProps}
		/>
	);
};

const getAssignById = (type: 'user' | 'role', assignId: number) => {
	const getCandidates: (User | Role)[] =
		type === 'role' ? Tools.AppService.getRoles() : Tools.AppService.getActiveUsers();
	return getCandidates.find(({ id }: { id: number }) => id === assignId);
};

const rawToSelectOption = ({ option, type, id: assignId }: RawOption): SelectOption | undefined => {
	const assign = getAssignById(type, assignId as number);
	return assign
		? {
				type,
				option,
				assign
		  }
		: undefined;
};

const AssignModalLeadSelectAdvanced = () => {
	const classes = new BemClass('AssignModalLeadSelectAdvanced');

	const dispatch = useDispatch();
	const formFields: FormField[] = useSelector((state: RootState) => state.FormEditor.fields);
	const assign = useSelector((state: RootState) => state.AssignModal.assign);
	const rawAssigns: RawOption[] = useSelector(
		(state: RootState) => state.AssignModal.assign.advancedData.assignedOptions
	);

	const selQuestion: FieldSelect | null = (() => {
		const field = formFields.find(v => assign.advancedData.chosenField === v.name);
		return field
			? {
					field,
					title: field.title,
					id: field.name
			  }
			: null;
	})();

	const [selQuestionOption, setSelQuestionOption] = useState<SelectItem | null>(null);
	const [selNewUser, setSelNewUser] = useState<UserSelect | null>(null);
	const [addingField, setAddingField] = useState(false);

	const selFallbackUser: UserSelect | null = (() => {
		const { fallback } = assign.advancedData;
		if (!fallback) {
			return null;
		}
		const fbAssign = getAssignById(fallback.type, fallback.id);
		return fbAssign
			? {
					assign: fbAssign,
					type: fallback.type,
					title: fbAssign.name,
					id: fbAssign.id
			  }
			: null;
	})();

	const selQuestionFields =
		selQuestion?.field.options.split(',').flatMap(opt => (opt ? decodeURIComponent(opt) : [])) ?? [];

	// Map on question fields before rawAssigns, so the table has same sorting as the options in the form field
	const advAssigns = selQuestionFields.flatMap(opt => {
		const optionMatch = rawAssigns.find(item => item.option === opt);
		return optionMatch ? rawToSelectOption(optionMatch) ?? [] : [];
	});

	// This feels awkward, but couldn't find a better solution...
	const [anchor, setAnchor] = useState<Element | null>(null);
	useLayoutEffect(() => {
		setAnchor(document.getElementsByClassName('AssignModal__content')[0]);
	}, []);

	const applyAssignOption = (option: string | undefined, user: UserSelect | null) => {
		const valid = !!(user && option);
		const newOptions = valid
			? // Replace the option if it already exists (ie. on edit)
			  rawAssigns
					.filter(item => item.option !== option)
					.concat({
						option,
						type: user.type,
						id: user.id
					})
			: rawAssigns;
		dispatch(setAdvAssignData({ assignedOptions: newOptions }));
	};

	return (
		<div className={classes.b()}>
			<AssistChip
				type="info"
				icon="info"
				space="mts mbxl"
				title={<Text color="bright-blue">{T('assign.advanced.infoChip')}</Text>}
			/>
			{/* Select question */}
			<Block className={classes.elem('selectBlock').b()}>
				<Text size="xl">{T('assign.advanced.assignQuestion')}</Text>
				<Label required>{T('assign.advanced.selectFormField')}</Label>
				<Select<FieldSelect>
					placeholder={T('assign.advanced.formField')}
					value={selQuestion}
					options={formFields.flatMap(field =>
						// Only single select fields are valid
						['select', 'radio'].includes(field.datatype)
							? {
									field: field,
									title: field.title,
									id: field.name
							  }
							: []
					)}
					anchor={anchor}
					onChange={item => {
						dispatch(
							setAdvAssignData({
								chosenField: item.field.name,
								assignedOptions: []
							})
						);
						setSelQuestionOption(null);
						setSelNewUser(null);
						setAddingField(true);
					}}
				/>
			</Block>

			{/* Show current assigns */}
			{selQuestion && advAssigns.length ? (
				<Table>
					<TableHeader>
						<TableHeaderColumn>{T('assign.advanced.tableHeaderOptions')}</TableHeaderColumn>
						<TableHeaderColumn>{T('assign.advanced.tableHeaderAssigned')}</TableHeaderColumn>
						<TableHeaderColumn> </TableHeaderColumn>
					</TableHeader>
					{advAssigns.map((obj, i) => (
						<TableRow key={i}>
							<TableColumn>
								<OverflowTooltip cutoff={34} text={obj.option} />
							</TableColumn>
							<TableColumn>
								<UserOrRoleSelect
									anchor={anchor}
									value={{
										assign: obj.assign,
										title: obj.assign.name,
										type: obj.type,
										id: obj.assign.id
									}}
									onChange={item => applyAssignOption(obj.option, item)}
								/>
							</TableColumn>
							<TableColumn>
								<Button
									type="link"
									hoverColor="red"
									onClick={() => {
										const a = rawAssigns.filter(item => item.option !== obj.option);
										dispatch(
											setAdvAssignData({
												assignedOptions: a
											})
										);
									}}
								>
									<Icon name="trash" />
								</Button>
							</TableColumn>
						</TableRow>
					))}
				</Table>
			) : null}

			{/* Create assign */}
			{selQuestion ? (
				<Block space="mtm mbxl">
					{!addingField ? (
						<>
							{rawAssigns.length === selQuestionFields.length && (
								<Text className={classes.elem('assignedEvery').b()} color="grey-11" size="sm">
									<Icon name="info-circle" />
									{T('assign.advanced.assignedEveryOption')}
								</Text>
							)}
							<Button
								color="super-light-green"
								shadow="none"
								disabled={rawAssigns.length === selQuestionFields.length}
								onClick={() => {
									dispatch(setAssignData({}));
									setAddingField(true);
								}}
							>
								<Text color="green">
									<Icon name="plus" space="mrl" />
									{T('assign.advanced.createConditionButton')}
								</Text>
							</Button>
						</>
					) : (
						<>
							<Block className={classes.elem('selectBlock').b()}>
								<Text size="xl">{T('assign.advanced.createNewCondition')}</Text>
								<Label>{T('assign.advanced.selectFieldOption')}</Label>
								<Select<SelectItem>
									placeholder={T('assign.advanced.fieldOption')}
									anchor={anchor}
									value={selQuestionOption}
									options={selQuestionFields.map((opt, i) => {
										const alreadyChosen = advAssigns.some(item => item.option === opt);
										return {
											title: opt,
											id: i,
											disabled: alreadyChosen,
											icon: alreadyChosen ? 'lock' : undefined
										};
									})}
									onChange={item => setSelQuestionOption(item)}
								/>
								<Label>{T('default.assign')}</Label>
								<UserOrRoleSelect
									anchor={anchor}
									value={selNewUser}
									onChange={item => setSelNewUser(item)}
								/>
							</Block>
							<Row className={classes.elem('buttonRow').b()}>
								<Button
									shadow="none"
									disabled={!(selNewUser && selQuestionOption)}
									onClick={() => {
										applyAssignOption(selQuestionOption?.title, selNewUser);
										setSelQuestionOption(null);
										setSelNewUser(null);
										setAddingField(false);
									}}
								>
									<Text color="white">
										<Icon name="check" space="mrm" />
										{T('default.save')}
									</Text>
								</Button>

								<Button
									type="link"
									onClick={() => {
										// dispatching empty action which makes the containing modal update its size. Will most likely not be needed
										// if/when this is moved to a drawer
										dispatch(setAssignData({}));
										setAddingField(false);
									}}
								>
									<Text>{T('default.cancel')}</Text>
								</Button>
							</Row>
						</>
					)}
				</Block>
			) : null}

			{/* Assign other */}
			{selQuestion && !addingField ? (
				<Block className={classes.elem('selectBlock').b()}>
					<Text size="xl">{T('assign.advanced.forRemainingOptions')}</Text>
					<Label>{T('default.selectUserOrRole')}</Label>
					<Row>
						<UserOrRoleSelect
							anchor={anchor}
							value={selFallbackUser}
							onChange={item =>
								dispatch(setAdvAssignData({ fallback: { type: item.type, id: item.id } }))
							}
						/>
						<Tooltip title={T('default.clear')}>
							<Button
								type="link"
								hoverColor="red"
								onClick={() => dispatch(setAdvAssignData({ fallback: null }))}
							>
								<Icon name="trash" />
							</Button>
						</Tooltip>
					</Row>
				</Block>
			) : null}
		</div>
	);
};

export default AssignModalLeadSelectAdvanced;

import FlowStepParamList, { MAX_HEIGHT } from '../FlowStepParamList';
import { FlowStep } from 'App/resources/Model/Flow';
import T from 'Components/Helpers/translate';
import React from 'react';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
	onAddBehavior: (type: string) => void;
	onDeleteBehavior: () => void;
};

const FlowStepCreateActivity = ({ onAddBehavior, onDeleteBehavior, ...props }: Props) => {
	return (
		<FlowStepParamList icon={'check'} title={T('automationAction.CreateActivity')} entity="activity" {...props} />
	);
};

export { MAX_HEIGHT };
export default FlowStepCreateActivity;

'use strict';

angular.module('upResources').factory('SalesReport', [
	'$resource',
	'$q',
	'SalesReportAttributes',
	'URL',
	'API',
	function SalesReport($resource, $q, SalesReportAttributes, URL, API) {
		var Attribute = SalesReportAttributes().attr;

		var type = {
			ORDER: 'order',
			OPPORTUNITY: 'opportunity'
		};

		var Model = {
			customer: function (customer) {
				var instance = {};
				var SalesReport = null;
				instance.type = null;
				SalesReport = $resource(
					URL + API + customer + '/report/:reportType/sales',
					{},
					{ query: { method: 'GET', isArray: false } }
				);

				instance.find = function (filter, options) {
					if (!instance.type) {
						throw new Error('No type set for SalesReport resource');
					}
					var defered = $q.defer();
					var params = angular.extend(filter, options);
					params.reportType = instance.type;

					SalesReport.get(params, function (response) {
						if (response.error) {
							defered.reject(response.error);
						} else {
							defered.resolve(response);
						}
					});

					instance.type = null;

					return defered.promise;
				};

				instance.setType = function (type) {
					instance.type = type;

					return instance;
				};

				return instance;
			},
			attr: Attribute,
			type: type
		};

		return Model;
	}
]);

'use strict';

angular.module('upUi').factory('$upDrilldown', [
	'$upKeyboard',
	'$templateCache',
	'$http',
	'$controller',
	'$rootScope',
	'$compile',
	'$injector',
	'$q',
	function ($upKeyboard, $templateCache, $http, $controller, $rootScope, $compile, $injector, $q) {
		var opening = false;
		var drilldownHeight = 200;
		var config;
		var currentTargetRow;
		var currentDrilldown;
		var table;
		var step;
		var scroll;
		var controller;
		var resolveParams;
		var animationTime = 250;

		//  keyboard event listeners
		var KeyboardEvents = {
			escape: $upKeyboard.onKeydown(
				'drilldownEscape',
				'escape',
				function (e) {
					e.preventDefault();
					e.stopPropagation();
					closeDrilldown(currentTargetRow, currentDrilldown, function () {
						deactivateEvents();
					});
				},
				true
			),

			up: $upKeyboard.onKeydown(
				'drilldownUp',
				'up',
				function (e) {
					e.preventDefault();
					e.stopPropagation();
					openPrevDrilldown();
				},
				true
			),

			down: $upKeyboard.onKeydown(
				'drilldownDown',
				'down',
				function (e) {
					e.preventDefault();
					e.stopPropagation();
					openNextDrilldown();
				},
				true
			)
		};

		// private functions

		// validates every new drilldown that is opened
		var validate = function (config) {
			// check for table row
			if (!config.tableRow) {
				throw new Error('upDrilldown needs a tablerow to work');
			}

			config.tableRow = config.tableRow.closest('tr');

			// validate tr
			if (config.tableRow[0].tagName.toLowerCase() !== 'tr') {
				throw new Error('upDrilldown needs a valid tr element to work not: ' + config.tableRow[0].tagName);
			}
		};

		// looks for any open drilldown in the same 'drilldown-scope' and closes it
		var closeOpenRow = function (callback) {
			callback = callback || angular.noop;

			// check for open drilldown
			if (currentDrilldown) {
				closeDrilldown(currentTargetRow, currentDrilldown, callback);
			} else {
				callback();
			}
		};

		// closes open drilldown
		function closeDrilldown(target, drilldown, callback) {
			callback = callback || angular.noop;
			target.removeClass('has-open-drilldown');

			// Remove class then wait for animation to finish before removing the row from the DOM
			drilldown.removeClass('open');

			setTimeout(function () {
				// remove the drilldown after animation
				drilldown.remove();
				callback();
			}, animationTime);
		}

		// checks for and opens previous drilldown
		function openPrevDrilldown() {
			var nextTarget = currentTargetRow.prev();

			if (!opening && step && nextTarget.hasClass('drilldownable')) {
				nextTarget.find('td:first').trigger('click');
			}
		}

		// checks for and opens next drilldown
		function openNextDrilldown() {
			var nextTarget = currentTargetRow.next().next();

			if (!opening && step && nextTarget.hasClass('drilldownable')) {
				nextTarget.find('td:first').trigger('click');
			}
		}

		// creates and returns a drilldown row
		var buildRow = function () {
			// create tr and td for drilldown row
			var rowElement = angular.element('<tr/>').addClass('drilldown');
			var td = angular.element('<td/>').addClass('drilldown-td');
			var wrap = angular.element('<div/>').attr('id', 'wrap');

			// set td to span over all columns
			td.attr('colspan', 100);

			// set height to 0 for animation
			// td.css('height', 0);

			// append wrapper
			td.append(wrap);

			// append td to tr and return row
			rowElement.append(td);

			return rowElement;
		};

		// open drilldown function
		var openDrilldown = function (target, callback) {
			// scope for drilldown
			var localScope;

			callback = callback || angular.noop;
			opening = true;

			// check for open row and close it
			closeOpenRow();

			// build row
			currentDrilldown = buildRow();
			currentTargetRow = target;

			// add has open class to target
			currentTargetRow.addClass('has-open-drilldown');

			// append row to table after currentTargetRow row
			currentDrilldown.insertAfter(currentTargetRow);

			// scroll view to target
			if (scroll) {
				scrollToTarget(currentTargetRow);
			}

			var promises = {};
			localScope = $rootScope.$new();
			var injects = {
				$scope: localScope,
				$drilldownParams: {}
			};

			// check template type and get template
			if (config.template) {
				promises.$html = $q.when(config.template);
			} else {
				promises.$html = $http.get(config.templateUrl, { cache: $templateCache });
			}

			//FIXME: handle resolve errors

			// if we have resolves
			var resolveKeys = Object.keys(resolveParams);

			if (resolveKeys.length) {
				angular.forEach(resolveKeys, function (key) {
					var value = resolveParams[key];

					if (typeof value === 'function') {
						promises[key] = $injector.invoke(value, {}, { $drilldownParams: resolveParams });
					} else {
						promises[key] = value;
					}
				});
			}

			$q.all(promises)
				.then(function (result) {
					// The REAL drilldown
					var drilldownToCompile = currentDrilldown.clone();
					drilldownToCompile.find('#wrap').html(result.$html.data);

					delete result.$html;

					var params = angular.extend(resolveParams, result);

					if (controller) {
						// extend drilldown params with resolved result
						injects.$drilldownParams = params;
						injects.$scope.$drilldownParams = params;

						// create controller
						$controller(controller, injects);
					} else {
						localScope.$drilldownParams = params;
					}

					// compile wrap
					$compile(drilldownToCompile)(localScope, function (compiledElement) {
						currentDrilldown.replaceWith(compiledElement);
						currentDrilldown = compiledElement;

						opening = false;

						// wait for row to be added to the dom then show it
						setTimeout(function () {
							currentDrilldown.addClass('open');
						}, animationTime);

						callback();
					});
				})
				.catch(function () {
					closeDrilldown(target, currentDrilldown, function () {
						deactivateEvents();
					});
				});
		};

		// activate all events
		function activateEvents() {
			KeyboardEvents.escape.activate();
			KeyboardEvents.up.activate();
			KeyboardEvents.down.activate();
		}

		// deactivate all events
		function deactivateEvents() {
			KeyboardEvents.escape.deactivate();
			KeyboardEvents.up.deactivate();
			KeyboardEvents.down.deactivate();
		}

		// scroll view to current drilldown
		function scrollToTarget(target) {
			// only scroll if we are not at bottom already
			var top = target.offset().top - table.parent().offset().top + table.parent().scrollTop() - 50;

			if (target.nextAll('.drilldown').length === 1) {
				top = top - drilldownHeight;
			}

			table.parent().stop().animate({
				scrollTop: top
			});
		}

		// the drilldown service returned
		return function Drilldown(conf) {
			step = !!conf.step;
			scroll = !!conf.scroll;
			controller = conf.controller || false;
			resolveParams = conf.resolve || {};

			var init = function () {
				// set current config
				config = conf || {};

				// validate
				validate(config);

				// set target row
				var target = config.tableRow;

				// set table
				table = target.parent().parent();

				// if the target has open drilldown
				var isOpen = target.hasClass('has-open-drilldown');

				// do not proceed if target has open drilldown
				if (!isOpen) {
					// open drilldown
					openDrilldown(target, function () {
						// activate keyboard events
						activateEvents();
					});
				} else {
					// close current target drilldown
					closeDrilldown(target, currentDrilldown, function () {
						deactivateEvents();
					});
				}
			};

			init();
		};
	}
]);

import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './TimelineEntityNote.scss';
import TimelineNoteBase from '../TimelineNoteBase';
import TimelineGenericRender, { TimelineConfig, TimelineComponentProps } from '../TimelineGeneric/TimelineCardConfigs';

type Props = {
	isHistoryLog: boolean;
	isLastElem: boolean;
	hideComments?: boolean;
} & TimelineComponentProps;

// TODO: This component doesn't really have a purpose anymore, as the main logic has been moved to TimelineGeneric.tsx, so what it does still do
//   should be moved and it should be deprecated/removed
/** @deprecated Use TimelineGeneric */
const TimelineEntityNote = ({ event, isHistoryLog, isLastElem, hideComments, ...additionalProps }: Props) => {
	const component =
		event.entityType && event.entityType in TimelineConfig ? (
			<TimelineGenericRender
				type={event.entityType as TimelineConfig}
				event={event}
				showComments={!hideComments}
				enableCardClick
				{...additionalProps}
			/>
		) : null;

	const classes = new BemClass('TimelineEntityNote');
	return (
		<div className={classes.mod({ angular: !isHistoryLog }).b()}>
			<TimelineNoteBase
				event={event}
				content={component ?? <></>}
				isHistoryLog={isHistoryLog}
				isLastElem={isLastElem}
			/>
		</div>
	);
};
export default TimelineEntityNote;

import PropTypes from 'prop-types';
import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Block } from '@upsales/components';
import CriteriaRowWrapper from '../CriteriaRowWrapper';

const Formats = {
	number: /\B(?=(\d{3})+(?!\d))/g,
	postalCode: /\B(?=(\d{2})(?!\d))/g
};

export default class NumberInput extends React.Component {
	constructor(props) {
		super(props);

		const { onChange, filter } = this.props;

		this.format = Formats[props.format] || Formats.Number;

		this.state = {
			start: filter.value.start ? ('' + filter.value.start).replace(this.format, ' ') : '',
			end: filter.value.end ? ('' + filter.value.end).replace(this.format, ' ') : '',
			error: false
		};

		this.onStartChanged = this.onChange.bind(this, 'start');
		this.onEndChanged = this.onChange.bind(this, 'end');
		this.onBlur = this.onBlur.bind(this);
		this.onDebounceOnChange = _.debounce(onChange, 250);
	}

	componentDidUpdate(prevProps) {
		if (this.props.onChange !== prevProps.onChange) {
			this.onDebounceOnChange = _.debounce(this.props.onChange, 250);
		}
	}

	onChange(key, event) {
		const { filter } = this.props;
		const newState = {
			start: this.state.start,
			end: this.state.end,
			[key]: event.target.value.replace(/[^0-9.]/g, '')
		};
		const error =
			newState.start && newState.end && parseFloat(newState.start) > parseFloat(newState.end) ? true : false;

		if (this.state.error && !error) {
			newState.error = false;
		}

		this.setState(newState, () => {
			const newFilter = _.cloneDeep(filter);
			const start = this.state.start ? parseFloat(this.state.start.replace(/[^0-9.]/g, '')) : null;
			const end = this.state.end ? parseFloat(this.state.end.replace(/[^0-9.]/g, '')) : null;
			const newValue = { start: start, end: end };
			newFilter.inactive = start === null && end === null;

			this.onDebounceOnChange(newValue, newFilter);
		});
	}

	onBlur() {
		const error =
			this.state.start &&
			this.state.end &&
			parseFloat(this.state.start.replace(/[^0-9.]/g, '')) > parseFloat(this.state.end.replace(/[^0-9.]/g, ''))
				? true
				: false;
		this.setState({
			error: error,
			start: this.state.start.replace(/[^0-9.]/g, '').replace(this.format, ' '),
			end: this.state.end.replace(/[^0-9.]/g, '').replace(this.format, ' ')
		});
	}

	onFocus(event) {
		if (event.target) {
			event.target.value = event.target.value.replace(/[^0-9.]/g, '');
		}
	}

	render() {
		const classes = new bemClass('flex-input-group');

		let unit = null;

		if (this.props.unit) {
			unit = <div className={classes.elem('addon').b()}>{this.props.unit}</div>;
		}

		return (
			<CriteriaRowWrapper>
				<div
					className={classes
						.mod({
							invalid: this.state.error,
							'with-addon': !!this.props.unit,
							'with-space': this.props.space
						})
						.b()}
				>
					<Block>
						<input
							autoFocus={this.props.autoFocus}
							className="form-control"
							placeholder={Tools.$translate('default.from')}
							onChange={this.onStartChanged}
							value={this.state.start}
							onBlur={this.onBlur}
							onFocus={this.onFocus}
							disabled={this.props.disabled}
						/>
						{unit}
					</Block>
					<Block>
						<input
							className="form-control"
							placeholder={Tools.$translate('default.to')}
							onChange={this.onEndChanged}
							value={this.state.end}
							onBlur={this.onBlur}
							onFocus={this.onFocus}
							disabled={this.props.disabled}
						/>
						{unit}
					</Block>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

NumberInput.propTypes = {
	filter: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool,
	unit: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	space: PropTypes.bool,
	format: PropTypes.string
};

NumberInput.defaultProps = {
	autoFocus: true,
	disabled: false,
	space: false,
	format: 'number'
};

'use strict';
import SaveBtn from 'Components/Admin/SaveBtn';

ReactTemplates.admin = {};
const AdminRoot = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			admin: t('default.admin')
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var self = this;
		const { tools, rootData, initialImport } = this.props;
		const btnData = initialImport || rootData;

		var pageComponent = React.createElement(
			typeof self.props.pageComponent === 'function'
				? self.props.pageComponent
				: rootData.useWebPack
				? window[self.props.pageComponent]
				: ReactTemplates.admin[self.props.pageComponent],
			{
				rootData: rootData,
				staticData: this.props.staticData,
				tools: tools
			}
		);

		var saveBtn = (
			<SaveBtn
				visible={rootData.saveVisible}
				saving={btnData.saving}
				onClick={btnData.onSave}
				text={btnData.saveBtnText || null}
				disabledReasons={btnData.getDisabledReasons?.() || []}
			/>
		);

		var loader = (
			<div id="admin-loader" className={rootData.pageLoading ? 'visible' : null}>
				{React.createElement(ReactTemplates.loader, {})}
			</div>
		);

		return (
			<div className={Tools.browserService.isTouchDevice ? 'is-touch' : null}>
				<div id="component-wrapper" className={rootData.pageLoading ? null : 'visible'}>
					{pageComponent}
				</div>
				{saveBtn}
				{loader}
			</div>
		);
	}
});

export default AdminRoot;

const Checks = () => ({
	client: false,
	activity: false,
	appointment: false,
	opportunity: false,
	optin: false,
	recent: false
});

export default Checks;

'use strict';
import openModal from 'App/services/Modal';

angular.module('domain.soliditet').controller('ConfirmSoliditetUpdate', [
	'$scope',
	'$state',
	'$modalParams',
	'$upModal',
	'ActionProperties',
	'SoliditetClient',
	'Account',
	'$translate',
	'$timeout',
	'AppService',
	function (
		$scope,
		$state,
		$modalParams,
		$upModal,
		ActionProperties,
		SoliditetClient,
		Account,
		$translate,
		$timeout,
		AppService
	) {
		var ConfirmSoliditetUpdate = this;
		var accountId = $modalParams.accountId;
		var customerId = AppService.getCustomerId();

		ConfirmSoliditetUpdate.properties = [];
		ConfirmSoliditetUpdate.buying = false;

		ConfirmSoliditetUpdate.hasDuns =
			$modalParams.duns &&
			$modalParams.duns !== '0' &&
			$modalParams.duns !== '-1' &&
			$modalParams.meta.currentAccount;
		ConfirmSoliditetUpdate.credits = $modalParams.meta.metadata.soliditetCredits;
		ConfirmSoliditetUpdate.hasExisting = false;

		if (!ConfirmSoliditetUpdate.hasDuns && $modalParams.account.userRemovable) {
			_.forEach($modalParams.meta.existingAccounts.data, function (existing) {
				var match = _.find($modalParams.meta.accounts, function (account) {
					return account.dunsNo === parseInt(existing.dunsNo);
				});
				if (match) {
					ConfirmSoliditetUpdate.hasExisting = true;
					match.mergeAble = true;
					match.userEditable = existing.userEditable;
				}
			});
		}

		if (!ConfirmSoliditetUpdate.hasDuns) {
			ConfirmSoliditetUpdate.accounts = $modalParams.meta.accounts;
		}

		ConfirmSoliditetUpdate.options = {
			updateExisting: true,
			skipProjects: true,
			skipAccountManagers: true,
			skipAddresses: false,
			skipCategories: true
		};

		ConfirmSoliditetUpdate.confirm = function () {
			ConfirmSoliditetUpdate.buying = true;
			SoliditetClient.customer(customerId)
				.refresh($modalParams.accountId, ConfirmSoliditetUpdate.properties, ConfirmSoliditetUpdate.options)
				.then(function (res) {
					$scope.resolve(res.data);
				})
				.catch(updateFail);
		};

		ConfirmSoliditetUpdate.useData = function (account) {
			ConfirmSoliditetUpdate.buying = true;
			account.$buying = true;

			// Update account with dunsNo
			Account.customer(customerId)
				.save({ id: accountId, dunsNo: account.dunsNo }, { skipNotification: true })
				.then(function () {
					ConfirmSoliditetUpdate.confirm();
				})
				.catch(updateFail);
		};

		const onUpdateConfirm = (account) => {
			ConfirmSoliditetUpdate.merging = true;
			account.$merging = true;

			var mergeAccount = ($modalParams.meta?.existingAccounts?.data ?? []).find((existing) => {
				return account.dunsNo === parseInt(existing.dunsNo);
			});

			Account.customer(customerId)
				.merge(mergeAccount, $modalParams.account)
				.then(function () {
					$timeout(function () {
						$scope.saving = false;
						if (!$modalParams.dontRedirect) {
							$state.go('account.dashboard', { id: mergeAccount.id });
						} else {
							$scope.resolve();
						}
					}, 1500);
				})
				.catch(function () {
					$scope.saving = false;
				});
		};

		ConfirmSoliditetUpdate.mergeAccounts = function (account) {
			//if multiple show all
			// show modal for confirmation
			var str = $translate.instant('account.mergeInfo1');
			str += '<b> ' + account.name + '</b>';
			str += '<br/><br/>' + $translate.instant('account.mergeInfo2');

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'account.mergeClients',
					body: str,
					headerIcon: 'merge',
					confirmButtonText: 'default.merge',
					onClose: (confirmed) => {
						if (confirmed) {
							onUpdateConfirm(account);
						}
					}
				});
				return;
			}

			$upModal
				.open('defaultConfirm', {
					title: 'account.mergeClients',
					body: str,
					resolveFalse: 'default.merge',
					no: 'default.abort',
					icon: 'fa-success'
				})
				.then(function () {
					onUpdateConfirm(account);
				});
		};

		var updateFail = function () {
			ConfirmSoliditetUpdate.buying = false;
		};
	}
]);

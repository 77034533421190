import PropTypes from 'prop-types';
import React from 'react';
import { Text, Checkbox, Row, Column, Icon, Button, Tooltip } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

class SniCodeRow extends React.Component {
	constructor(props) {
		super(props);

		const AppService = getAngularModule('AppService');
		const $filter = getAngularModule('$filter');
		const t = getAngularModule('$translate');

		this.lang = {
			dollarSignTooltip: t('soliditet.dollarSignTooltip')
		};

		const currencies = AppService.getMetadata().customerCurrencies;
		const masterCurrency = currencies.find(currency => currency.masterCurrency);
		const formatCurrency = $filter('currencyFormat');
		const numberFormat = $filter('numberFormat');
		this.formatCurrency = value => formatCurrency(value, masterCurrency.iso);
		this.numberFormat = value => numberFormat(value);
	}

	render() {
		const { half, checked, isExpaned, item, onSelct, toggleExpanded } = this.props;
		const classes = new Bem('SniCodeRow');

		return (
			<Row
				className={classes.mod({ child: !item.children }).b()}
				align="center"
				onClick={() => {
					if (item.children) {
						toggleExpanded(item);
					}
				}}
			>
				<Column fixedWidth={20}>
					<Checkbox
						key={(half || checked).toString() + half.toString()}
						size="xs"
						onClick={e => {
							e.stopPropagation();
							onSelct(item, checked);
						}}
						checked={half || checked}
						half={half}
					/>
				</Column>
				<Column className="text-ellipsis">
					<Text className="Text--Inline">{item.codeAndName || item.name}</Text>
					{item.orderValue ? (
						<Tooltip title={this.lang.dollarSignTooltip}>
							<Icon name="dollar-circle" color="bright-green" space="mls" />
						</Tooltip>
					) : null}
				</Column>
				<Column fixedWidth={100} align="right">
					<Text>{this.numberFormat(item.count)}</Text>
				</Column>
				<Column align="right" fixedWidth={35}>
					{item.children ? (
						<Button size="sm" type="link" color="grey" onClick={() => toggleExpanded(item)}>
							<Icon name={isExpaned ? 'chevron-up' : 'chevron-down'} />
						</Button>
					) : null}
				</Column>
			</Row>
		);
	}
}

SniCodeRow.propTypes = {
	checked: PropTypes.bool.isRequired,
	item: PropTypes.object.isRequired,
	onSelct: PropTypes.func.isRequired,
	isExpaned: PropTypes.bool,
	half: PropTypes.bool,
	toggleExpanded: PropTypes.func
};

SniCodeRow.defaultProps = {
	half: false
};

export default SniCodeRow;

import PropTypes from 'prop-types';
import React from 'react';

class NotificationList extends React.Component {
	constructor(props) {
		super(props);

		var t = Tools.$translate;
		this.lang = {
			noNotifications: t('default.noNotifications'),
			showMore: t('default.showMore')
		};

		this.onScroll = () => {
			var sticky = this._sticky;
			if (this._list.scrollTop === 0) {
				sticky.classList.remove('minimized');
			} else {
				sticky.classList.add('minimized');
			}
		};
	}

	componentWillUnmount() {
		this._list.removeEventListener('scroll', this.onScroll);
	}

	componentDidMount() {
		this._list.addEventListener('scroll', this.onScroll);
	}

	render() {
		let placeholder = null;
		let notifications = [];
		let showMoreBtn = null;

		if (this.props.notifications.length) {
			notifications = _.chain(this.props.notifications)
				.uniq('id')
				.map((notification, i) => {
					return React.createElement(ReactTemplates.navbar.notificationRow, {
						notification,
						getStateHref: this.props.getStateHref,
						key: 'noti-' + i,
						onClick: this.props.onNotificationClick.bind(this, notification),
						exportUrl: this.props.exportUrl,
						showImport: this.props.showImport
					});
				})
				.sortBy('date')
				.value();
		} else {
			placeholder = <li className="no-notifications">{this.lang.noNotifications}</li>;
		}

		if (this.props.total > notifications.length) {
			showMoreBtn = (
				<li onClick={this.loadMore} id="noti-load-more">
					{this.props.loading ? <b className="fa fa-refresh fa-spin" /> : <a>{this.lang.showMore}</a>}
				</li>
			);
		}

		const jobs = _.chain(this.props.jobs)
			.map((job, i) => {
				return this.jobRow(job, i);
			})
			.sortBy('date')
			.value();

		return (
			<div className="dropdown-menu">
				<ul id="sticky" ref={r => (this._sticky = r)}>
					{jobs}
				</ul>

				<ul id="notification-list" ref={r => (this._list = r)}>
					{placeholder}

					{notifications}

					{showMoreBtn}
				</ul>
			</div>
		);
	}

	jobRow(job, i) {
		var t = Tools.$translate;
		var jobText = null;

		if (job.type === 'Job') {
			jobText = (
				<span className="job-text">
					{job.name ? (
						<span>
							{' '}
							{t(job.action)} {t(job.name, { total: job.message })}
						</span>
					) : (
						<span>{t('noti.' + job.action, { total: job.message })}</span>
					)}
				</span>
			);
		} else if (job.type === 'Import') {
			var total = ((job.message || 'r0').split(':')[0] || 'r0').replace('r', '');
			jobText = <span className="job-text">{t('noti.importingRows', { total: total })}</span>;
		}

		return (
			<li className="job-status" key={'job-' + i}>
				<a>
					<div className="first">
						<div className="timestamp">
							{job.status}
							{'%'}
						</div>
					</div>
					<div className="second">
						{jobText}
						<div className="progress">
							<div
								className="progress-bar blue"
								role="progressbar"
								aria-valuenow={job.status}
								aria-valuemin="0"
								aria-valuemax="100"
								style={{ width: job.status + '%' }}
							/>
						</div>
					</div>
				</a>
			</li>
		);
	}
}

NotificationList.propTypes = {
	notifications: PropTypes.array.isRequired,
	getStateHref: PropTypes.func.isRequired,
	onNotificationClick: PropTypes.func.isRequired,
	exportUrl: PropTypes.string.isRequired,
	showImport: PropTypes.func.isRequired,
	total: PropTypes.number,
	loading: PropTypes.bool,
	jobs: PropTypes.array
};

window.NotificationList = NotificationList;

export default window.NotificationList;

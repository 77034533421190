'use strict';
import { hasAccess } from 'Resources/parsers/product';

angular.module('upResources').service('ParseProduct', [
	'AppService',
	function (AppService) {
		return function (product) {
			const self = AppService.getSelf();
			const productCategories = AppService.getProductCategories();
			const hasBackendAccess = Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
			product.$hasAccess = hasBackendAccess ? product.userUsable : hasAccess(self, product, productCategories);
			return product;
		};
	}
]);

'use strict';

angular.module('domain.mail').factory('MailCampaignMeta', [
	'MailCampaign',
	'$q',
	'AppService',
	function (MailCampaign, $q, AppService) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var promises = {
					mailCampaign: MailCampaign.get($stateParams.id)
				};

				return $q.all(promises);
			});
		};
	}
]);

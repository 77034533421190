import PropTypes from 'prop-types';
import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';

function mapProps(props) {
	return {
		value: props.value,
		onClick: props.onClick,
		locked: props.locked,
		options: [
			{
				value: props.trueValue,
				text: props.trueText
			},
			{
				value: props.falseValue,
				text: props.falseText
			}
		]
	};
}

export const ComparisionToggle = inProps => {
	const props = inProps.options ? inProps : mapProps(inProps);

	let idx = props.options.findIndex(option => option.value === props.value);
	if (idx === -1) {
		idx = 0;
	}

	const currentText = props.options[idx].text;
	const nextIdx = (idx + 1) % props.options.length;
	const nextValue = props.options[nextIdx].value;

	const onClick = props.onClick.bind(null, nextValue);
	const classes = new bemClass('up-comparison-type-toggle');

	return (
		<div className={classes.mod({ locked: props.locked }).b()} onClick={props.locked ? null : onClick}>
			{currentText}
		</div>
	);
};

ComparisionToggle.propTypes = {
	value: PropTypes.any.isRequired,
	trueValue: PropTypes.any,
	trueText: PropTypes.string,
	falseValue: PropTypes.any,
	falseText: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string
		})
	),
	onClick: PropTypes.func.isRequired,
	locked: PropTypes.bool
};

export default ComparisionToggle;

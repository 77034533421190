'use strict';

angular.module('domain.campaign').controller('CampaignDashboard', [
	'$scope',
	function ($scope) {
		var CampaignCtrl = $scope.CampaignCtrl;
		if (CampaignCtrl.campaign.isCallList) {
			Tools.$state.go('campaign.activities', { id: CampaignCtrl.campaign.id });
		}
	}
]);

'use strict';

window.addEventListener(
	'dragover',
	function (e) {
		e = e || event;
		e.preventDefault();
	},
	false
);
window.addEventListener(
	'drop',
	function (e) {
		e = e || event;
		e.preventDefault();
	},
	false
);

angular
	.module('upDirectives')
	.directive('fileDropzone', function () {
		return {
			restrict: 'A',
			controller: [
				'$scope',
				'NotificationService',
				'$rootScope',
				function ($scope, NotificationService, $rootScope) {
					var FileDropzone = this;

					FileDropzone.files = [];

					var buildFile = function (rawFile) {
						return {
							id: rawFile.id,
							name: rawFile.name,
							status: rawFile.status,
							size: rawFile.size,
							progress: rawFile.upload.progress,
							mimetype: rawFile.type
						};
					};

					FileDropzone.addFile = function (rawFile) {
						FileDropzone.files.push(buildFile(rawFile));
						$scope.$apply();
					};

					FileDropzone.updateFile = function (rawFile) {
						var file = buildFile(rawFile);
						const listFile = FileDropzone.files.find(f => f.id === file.id);
						if (listFile) {
							angular.extend(listFile, file);
							$scope.$apply();
						}
					};

					FileDropzone.uploadFailed = function (rawFile, config, errMsg) {
						var errorBody = 'file.uploadFailed';

						if (
							errMsg &&
							typeof errMsg === 'string' &&
							errMsg.indexOf('upload files of this type') !== -1
						) {
							errorBody = 'file.wrongFileType';
						} else if (errMsg && typeof errMsg === 'string' && errMsg.indexOf('File is too big') !== -1) {
							errorBody = 'file.tooLarge';
						} else if (errMsg && errMsg.error && errMsg.error.key === 'InvalidFileType') {
							errorBody = 'file.uploaded.invalidType';
						}

						NotificationService.addNotification({
							style: NotificationService.style.ERROR,
							title: 'default.error',
							body: errorBody,
							icon: 'times'
						});
						var response = {};

						try {
							response = JSON.parse(rawFile.xhr.response);
						} catch (e) {
							// do nothing
						}

						$rootScope.$broadcast(config.errorEvent || 'file.uploadFailed', response);
					};

					FileDropzone.fileUploaded = function (rawFile, config) {
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							title: 'default.done',
							body: 'file.fileUploaded',
							icon: 'check'
						});

						var response = {};

						try {
							response = JSON.parse(rawFile.xhr.response).data;
						} catch (e) {
							// do nothing
						}

						$rootScope.$broadcast(config.event, response);
					};
				}
			],
			controllerAs: 'FileDropzone',
			link: function ($scope, $element, $attrs, FileDropzone) {
				var config = $scope.$eval($attrs.fileDropzone);

				var zoneElement = $element.find('[file-dropzone-zone]');
				var overlay = angular.element('<div id="click-zone" />');

				var runCallback = function (name, data) {
					if (config[name] && typeof config[name] === 'function') {
						config[name](data || null);
					}
				};

				if (!zoneElement.length) {
					zoneElement = $element;
				}

				zoneElement.append(overlay);

				var opts = {
					url: config.url,
					clickable: overlay[0],
					maxFilesize: 25,
					withCredentials: true,
					init: function () {
						this.on('sending', function (rawFile) {
							rawFile.id = Date.now();
							FileDropzone.addFile(rawFile);
							runCallback('sending');
						});

						this.on('uploadprogress', function (rawFile, progress) {
							FileDropzone.updateFile(rawFile);
							runCallback('progress', progress);
						});

						this.on('complete', function (rawFile) {
							FileDropzone.updateFile(rawFile);
						});

						this.on('success', function (rawFile) {
							FileDropzone.fileUploaded(rawFile, config);

							var response = { data: null };
							try {
								response = JSON.parse(rawFile.xhr.response);
							} catch (e) {
								// do nothing
							}

							runCallback('success', response.data);
						});

						this.on('error', function (rawFile, errMsg) {
							FileDropzone.uploadFailed(rawFile, config, errMsg);
							runCallback('error', errMsg);
						});
					}
				};

				if (config.acceptedFiles) {
					opts.acceptedFiles = config.acceptedFiles;
				} else {
					opts.acceptedFiles = Tools.AppService.getMetadata().validFileExtensions;
				}

				zoneElement.dropzone(opts);

				zoneElement.addClass('file-dropzone');
			}
		};
	})
	.directive('fileDropzoneTable', function () {
		return {
			restrict: 'A',
			require: '^fileDropzone',
			templateUrl: require('App/upsales/common/directives/templates/fileDropzoneTable.tpl.html?file'),
			link: function () {}
		};
	});

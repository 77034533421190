'use strict';

ReactTemplates.admin.standardIntegrations = {};
ReactTemplates.admin.standardIntegrations.fields = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			nothingToConfigure: t('integration.nothingToConfigure')
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getIntegrationLang: function (element, type, fallback) {
		return this.props.getIntegrationLang(element, type, fallback);
	},
	renderElement: function (field, i, sections) {
		var self = this;
		var section = null;
		var fieldElement = null;
		var pushSection = false;
		if (field.type === 'hero') {
			section = {
				hero: true,
				title: field.title ? self.getIntegrationLang(field.name, 'title', field.title) : null,
				description: field.description
					? self.getIntegrationLang(field.name, 'description', field.description)
					: null,
				color: field.color || '#666',
				backgroundColor: field.backgroundColor || '#fafafa',
				backgroundColorSlice: field.backgroundColorSlice || 'transparent'
			};
			sections.push(section);
			return;
		} else if (field.type === 'header') {
			section = {
				title: field.title ? self.getIntegrationLang(field.name, 'title', field.title) : null,
				description: field.description
					? self.getIntegrationLang(field.name, 'description', field.description)
					: null,
				fields: [],
				hero: false,
				size: field.size || null
			};
			sections.push(section);
			return;
		} else if (!sections.length || field.asHeader) {
			section = {
				title: null,
				description: null,
				fields: [],
				hero: false
			};
			pushSection = true;
		} else {
			section = sections[sections.length - 1];
		}

		var invalidMsg = null;

		if (field.$tested && !field.$testing) {
			invalidMsg = (
				<div>
					{field.$tested.valid ? (
						<span className="text-green">
							<i className="fa fa-check" /> {field.$tested.message}
						</span>
					) : (
						<span className="text-red">
							<i className="fa fa-times" /> {field.$tested.message}
						</span>
					)}
				</div>
			);
		} else if (field.$testing && field.type !== 'button') {
			invalidMsg = (
				<div>
					<i className="fa fa-circle-o-notch fa-spin" />
				</div>
			);
		}

		var input = null;
		var getting = null;
		if (field.$gettingValues) {
			getting = (
				<div className="form-group">
					<i className="fa fa-circle-o-notch fa-spin" />
				</div>
			);
		} else {
			input = React.createElement(window.StandardIntegrationField, {
				key: field.name,
				field: field,
				value: self.props.config[field.name],
				onChange: self.props.onChange,
				integration: self.props.integration,
				gettingOauth: self.props.gettingOauth,
				oauthErr: self.props.oauthErr,
				oauth: self.props.oauth,
				gettingOauthErr: self.props.gettingOauthErr,
				onRequestOauth: self.props.onRequestOauth,
				onRequestOauth2: self.props.onRequestOauth2,
				getIntegrationLang: self.props.getIntegrationLang,
				onBtnClick: self.props.onBtnClick
			});
		}

		var hasLabel = field.label && ['checkbox', 'button', 'checkboxLarge'].indexOf(field.type) === -1;
		var className = field.descriptionLong ? 'row app-field-row app-field-row--has-info' : 'row app-field-row';

		fieldElement = (
			<div className={className} key={'field-' + field.name + '-' + i}>
				<div className={'app-field-column' + (field.descriptionLong ? ' col-md-6' : ' col-md-12')}>
					{hasLabel ? (
						<label className="app-field-label">
							{self.getIntegrationLang(field.name, 'label', field.label)}{' '}
							{field.$required ? <b className="text-danger">{'*'}</b> : null}
						</label>
					) : null}
					{getting}
					{input}
					{invalidMsg}
				</div>
				{field.descriptionLong ? (
					<div className="app-field-info col-md-6">
						{self.getIntegrationLang(field.name, 'descriptionLong', field.descriptionLong)}
					</div>
				) : null}
			</div>
		);

		if (fieldElement) {
			if (!section.fields) {
				section.fields = [];
			}
			section.fields.push(fieldElement);
		}

		if (pushSection) {
			sections.push(section);
		}
	},
	renderHero: function (hero) {
		var style = {
			backgroundColor: hero.backgroundColor,
			color: hero.color
		};
		return (
			<div className="app-field-hero" style={style}>
				<div className="hero-slice" style={{ backgroundColor: hero.backgroundColorSlice }} />
				<h2 style={{ color: hero.color }}>{hero.title}</h2>
				<p>{hero.description}</p>
			</div>
		);
	},
	render: function () {
		var self = this;
		var fields = this.props.fields;
		if (!this.props.showHero) {
			fields = _.filter(self.props.fields, function (f) {
				return f.type !== 'hero';
			});
		}
		var noFields = null;
		var fieldsRows = null;
		var sections = [];

		if (!fields || !fields.length) {
			noFields = (
				<div>
					<div className="app-fields-info">{self.lang.nothingToConfigure}</div>
				</div>
			);
		} else {
			_.each(fields, function (field, i) {
				if (field.visible) {
					self.renderElement(field, i, sections);
				}
			});

			fieldsRows = _.map(sections, function (section, index) {
				if (section.hero) {
					return self.renderHero(section);
				}
				var className = 'app-field-section-title';
				if (section.size) {
					className = className + ' app-field-section-title--' + section.size;
				}

				return (
					<div key={'section' + index} className="app-field-section">
						{section.title && <div className={className}>{section.title}</div>}
						{section.description && <div className="app-field-section-info">{section.description}</div>}
						{section.fields}
					</div>
				);
			});
		}

		return (
			<div>
				{noFields}
				{fieldsRows}
			</div>
		);
	}
});

import Resource from './Resource';

class AccountExtra extends Resource {
	constructor() {
		super('accounts/extra');
	}

	get(fields, clientIds) {
		return this._getRequest('', {
			_url: `${this._url}?fields=${fields.join(',')}&clientIds=${clientIds.join(',')}`
		}).then(res => res.data);
	}
}

const resource = new AccountExtra();

export default resource;

import React from 'react';
import PropTypes from 'prop-types';
import SupersearchHighlight, { isMatch } from './SupersearchHighlight';
import { Text } from '@upsales/components';

const SupersearchResultCustomfields = ({ alwaysShow, customFields = [], values = [], searchStr }) => {
	const matches = [];
	if (customFields && customFields.length) {
		customFields.forEach((field, i) => {
			var valObj = values.find(v => v.fieldId === field.id);
			if (valObj && (isMatch(valObj.value, searchStr) || alwaysShow)) {
				matches.push(
					<Text size="sm" key={'result-custom-' + i}>
						{`${field.name}: `}
						<SupersearchHighlight searchStr={searchStr} text={valObj.value} />
					</Text>
				);
			}
		});
	}

	return matches.length ? <div className="SupersearchResultCustomfields">{matches}</div> : null;
};

SupersearchResultCustomfields.propTypes = {
	alwaysShow: PropTypes.bool,
	customFields: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		})
	),
	searchStr: PropTypes.string
};

export default SupersearchResultCustomfields;

'use strict';

angular.module('domain.admin').controller('UpdateClientAction', [
	'$modalParams',
	'$q',
	'$scope',
	'$translate',
	'$upModal',
	'ActionProperties',
	'AppService',
	'Campaign',
	'CampaignAttributes',
	'RequestBuilder',
	'TagsService',
	'FeatureHelper',
	function (
		$modalParams,
		$q,
		$scope,
		$translate,
		$upModal,
		ActionProperties,
		AppService,
		Campaign,
		CampaignAttributes,
		RequestBuilder,
		TagsService,
		FeatureHelper
	) {
		var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];
		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.customFields = [];

		$scope.isTrigger = $modalParams.isTrigger;

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.save = function () {
			$scope.saving = true;

			// map properties
			var properties = [];

			// map custom
			ActionProperties.mapCustomFields(properties, $scope.customFields, 'Client');

			if ($scope.userRole) {
				if ($scope.classicUserAction) {
					properties.push({ name: 'AccountManager', value: $scope.userRole.substr(2) });
				} else {
					properties.push({
						name: $scope.userRole.indexOf('r_') !== -1 ? 'Role' : 'User',
						value: $scope.userRole.substr(2)
					});
				}
			}
			if ($scope.properties.User) {
				switch ($scope.accountManagerAction) {
					case 'Add':
						$scope.properties.AddUser = $scope.properties.User;
						break;
					case 'Remove':
						$scope.properties.RemoveUser = $scope.properties.User;
						break;
					case 'Set':
					default:
						$scope.properties.AccountManager = $scope.properties.User;
						break;
				}
				delete $scope.properties.User;
			}

			ActionProperties.map(properties, $scope.properties, arrayProperties);

			const journeyStepProperty = _.find(properties, { name: 'JourneyStep' });
			if (journeyStepProperty) {
				properties.push({ name: 'ForceInvalidPropertyChange', value: true });
			}

			$scope.resolve(properties);
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.addField = function (field) {
			_.remove($scope.availableFields, { id: field.id });
			$scope.customFields.push(field);
			$scope.fieldToAdd = null;
		};

		$scope.removeField = function (field) {
			_.remove($scope.customFields, { id: field.id });
			$scope.availableFields.push(field);
			$scope.reloadModalPosition();
		};

		$scope.createCampaign = function () {
			$upModal
				.open('editCampaign', { customerId: AppService.getCustomerId(), noRedirect: true })
				.then(function (campaign) {
					if ($scope.properties.AddProject && _.isArray($scope.properties.AddProject)) {
						$scope.properties.AddProject.push(campaign.id);
					} else {
						$scope.properties.AddProject = [campaign.id];
					}
				})
				.catch(error => console.error(error));
		};

		$scope.isSaveDisabled = function () {
			var hasProperty = _.reduce(
				$scope.properties,
				function (res, property) {
					if (res) {
						return res;
					} else if (Array.isArray(property)) {
						return !!property.length;
					} else if (typeof property === 'number') {
						return true;
					} else {
						return !!property;
					}
				},
				false
			);

			return (
				$scope.saving ||
				($scope.myForm.$invalid && $scope.myForm.$dirty) ||
				(!hasProperty && !$scope.customFields.length && !$scope.userRole)
			);
		};

		$scope.showErrorMessage = function (form) {
			for (var key in form.$error) {
				if (key !== 'required') {
					return true;
				} else {
					var requiredArr = form.$error.required;
					for (var itr = 0; itr < requiredArr.length; ++itr) {
						var obj = requiredArr[itr];
						if (!obj.$pristine) {
							return true;
						}
					}
				}
			}
			return false;
		};

		$scope.changeCustomDate = function (field) {
			if (field.value && field.value.tag === 'RegularDate') {
				field.dates = undefined;
			}
		};

		var loadData = function () {
			var campaignFilter = new RequestBuilder();
			var campaignAttr = CampaignAttributes().attr;
			campaignFilter
				.addSort(campaignAttr.name, true)
				.addFilter(campaignAttr.active, campaignFilter.comparisonTypes.Equals, 1);

			return Campaign.customer(AppService.getCustomerId()).find(campaignFilter.build());
		};

		var init = function () {
			$scope.classicUserAction = _.find($modalParams.properties, { name: 'AccountManager' });
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;
			$scope.metadata = AppService.getMetadata();
			$scope.metadata.params.teamAccountManager = true;
			$scope.accountManagerAction = 'Set';
			$scope.showJourneyStep = $modalParams.tagEntity !== 'order' && $modalParams.tagEntity !== 'agreement';
			$scope.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			$scope.standardFields = _.filter($scope.metadata.standardFields.Client, function (field) {
				return field.group === 'standard' && field.canHide && field.active;
			});

			$scope.hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
			if ($scope.hasJourney) {
				$scope.journeySteps = Tools.AppService.getJourneySteps();
			}

			$scope.availableFields = AppService.getCustomFields('account');
			if (!$scope.isTrigger) {
				$scope.availableFields = _.filter($scope.availableFields, 'editable');
			}
			$scope.customFieldLength = $scope.availableFields.length;

			// parse properties
			$scope.properties = {};

			angular.forEach($modalParams.properties, function (prop) {
				if ($scope.isTrigger && prop.name === 'AccountManager') {
					$scope.accountManagerAction = 'Set';
					$scope.properties.User = prop.value;
				} else if ($scope.isTrigger && prop.name === 'AddUser') {
					$scope.accountManagerAction = 'Add';
					$scope.properties.User = prop.value;
				} else if ($scope.isTrigger && prop.name === 'RemoveUser') {
					$scope.accountManagerAction = 'Remove';
					$scope.properties.User = prop.value;
				} else if (prop.name === 'User' || prop.name === 'AccountManager') {
					$scope.userRole = 'u_' + prop.value;
				} else if (prop.name === 'Role') {
					$scope.userRole = 'r_' + prop.value;
				} else {
					$scope.properties[prop.name] = prop.value;
					if (_.indexOf(arrayProperties, prop.name) !== -1) {
						$scope.properties[prop.name] = $scope.properties[prop.name].split(',');
					}
				}
			});

			// Date
			var dateTags = [];
			var customDateTypes = TagsService.getTagsByEntity($modalParams.entity || $modalParams.tagEntity).reduce(
				function (result, value) {
					if (value.type !== 'Date') {
						return result;
					}

					if (!result[value.entity]) {
						result[value.entity] = {
							name: $translate.instant('default.' + value.entity),
							children: []
						};
					}

					result[value.entity].children.push(value);
					dateTags.push(value);
					return result;
				},
				{}
			);

			if (customDateTypes.general && customDateTypes.general.children) {
				var clearField = { name: $translate.instant('tag.general.clearfield'), tag: 'General.ClearField' };
				customDateTypes.general.children.push(clearField);
				customDateTypes.general.children.push({
					name: $translate.instant('tag.special.regularDate'),
					tag: 'RegularDate'
				});
				dateTags.push(clearField);
			}

			$scope.customDateTypes = {
				data: _.values(customDateTypes),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('tag')
			};

			$scope.availableFields = _.map($scope.availableFields, function (field) {
				if (customDateTypes && field.datatype === 'Date') {
					field.dates = _.values(customDateTypes);
				}
				return field;
			});

			// parse custom
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Client',
				dateTags
			);

			loadData()
				.then(function (res) {
					$scope.campaignSelect = {
						placeholder:
							$translate.instant('default.select') +
							' ' +
							$translate.instant('default.campaign').toLowerCase()
					};
					$scope.campaigns = res.data;
				})
				.catch(error => console.error(error));

			$scope.categorySelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.category').toLowerCase()
			};

			$scope.categories = AppService.getCategories('account');

			$scope.usersAndRoles = [];
			angular.forEach(AppService.getActiveUsers(), function (user) {
				user.$$type = 'User';
				user.$$id = 'u_' + user.id;
				user.$$text = user.name;
				$scope.usersAndRoles.push(user);
			});

			if (!$scope.classicUserAction) {
				angular.forEach(AppService.getRoles(), function (role) {
					role.$$type = 'Role';
					role.$$id = 'r_' + role.id;
					role.$$text = role.description;
					$scope.usersAndRoles.push(role);
				});
			}

			$scope.userRoleSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.user').toLowerCase(),
				allowClear: true
			};

			if ($scope.isTrigger) {
				// Users
				var activeUsersCopy = AppService.getActiveUsers();

				activeUsersCopy.unshift({
					id: '{{Client.UserId}}',
					name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.accountManager')
				});
				activeUsersCopy.unshift({
					id: '{{Client.AssignedUserId}}',
					name:
						$translate.instant('tag.group.Client') +
						': ' +
						$translate.instant('automationTerms.assignedUser')
				});

				switch ($modalParams.tagEntity) {
					case 'client':
						activeUsersCopy.unshift({
							id: '{{Client.RegById}}',
							name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.regBy')
						});
						break;

					case 'contact':
						activeUsersCopy.unshift({
							id: '{{Client.RegById}}',
							name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Contact.RegById}}',
							name: $translate.instant('tag.group.Contact') + ': ' + $translate.instant('default.regBy')
						});
						break;

					case 'order':
						activeUsersCopy.unshift({
							id: '{{Client.RegById}}',
							name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Contact.RegById}}',
							name: $translate.instant('tag.group.Contact') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Order.UserId}}',
							name: $translate.instant('tag.group.Order') + ': ' + $translate.instant('default.salesRep')
						});
						break;

					case 'activity':
						activeUsersCopy.unshift({
							id: '{{Client.RegById}}',
							name: $translate.instant('tag.group.Client') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Contact.RegById}}',
							name: $translate.instant('tag.group.Contact') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Activity.RegById}}',
							name: $translate.instant('tag.group.Activity') + ': ' + $translate.instant('default.regBy')
						});
						activeUsersCopy.unshift({
							id: '{{Activity.UserId}}',
							name: $translate.instant('tag.group.Activity') + ': ' + $translate.instant('default.user')
						});
						break;
				}

				// Dont add current user if automation
				if (!$modalParams.isAutomation) {
					activeUsersCopy.unshift({
						id: '{{General.CurrentUserId}}',
						name: $translate.instant('tag.general.currentuser')
					});
				}

				$scope.users = activeUsersCopy;
			}
		};

		AppService.loadedPromise.then(init).catch(error => console.error(error));
	}
]);

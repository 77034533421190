'use strict';

angular.module('upDirectives').directive('upStepInt', function () {
	return {
		restrict: 'A',
		replace: true,
		require: 'ngModel',
		templateUrl: require('App/upsales/common/directives/templates/upStepInt.tpl.html?file'),
		compile: function ($element, $attrs) {
			var input = $element.find('input');
			// Copy all attributes to the input then remove class and self
			angular.forEach($attrs.$attr, function (attr, key) {
				if (key === 'upStepInt' || key === 'class') {
					return;
				}
				input.attr(attr, $attrs[key]);
			});

			return function link($scope, $elem, $attrs, ngModel) {
				var plusBtn = $elem.find('.plus-btn');
				var minusBtn = $elem.find('.minus-btn');
				var input = $elem.find('input');
				var min;

				if ($attrs.min && !isNaN($attrs.min)) {
					min = parseInt($attrs.min);
				}

				var setValue = function (val) {
					if (min !== undefined) {
						val = Math.max(min, val);
					}
					ngModel.$setViewValue(val);
				};

				var getValue = function () {
					var val = ngModel.$viewValue;
					if (!val || val === '' || isNaN(parseInt(val))) {
						return 0;
					}

					return parseInt(val);
				};

				var addClass = function () {
					input.addClass('focus');
				};

				var removeClass = function () {
					input.removeClass('focus');
				};

				var addValue = function () {
					var val = getValue();
					setValue(val + 1);
				};

				var removeValue = function () {
					var val = getValue();
					setValue(val - 1);
				};

				var holdInterval = null;
				var holdDelayTimer = null;

				var abortHold = function () {
					// Abort hold
					if (holdDelayTimer) {
						clearTimeout(holdDelayTimer);
						holdDelayTimer = null;
					}

					// Cancel hold
					if (holdInterval) {
						clearInterval(holdInterval);
						holdInterval = null;
					}

					removeClass();
				};

				var holdWait = 50; // Run update every xx ms.
				var holdThreshold = 100; // Time to wait until hold interval starts

				minusBtn
					.on('mousedown', function () {
						// Add class
						addClass();

						// Delay hold
						holdDelayTimer = setTimeout(function () {
							holdInterval = setInterval(removeValue, holdWait);
						}, holdThreshold);
					})
					.on('mouseout', abortHold)
					.on('mouseup', function () {
						abortHold();

						// Update value
						removeValue();

						// Focus input
						input.focus();
					});

				plusBtn
					.on('mousedown', function () {
						// Add class
						addClass();

						// Delay hold
						holdDelayTimer = setTimeout(function () {
							holdInterval = setInterval(addValue, holdWait);
						}, holdThreshold);
					})
					.on('mouseout', abortHold)
					.on('mouseup', function () {
						abortHold();

						// Update value
						addValue();

						// Focus input
						input.focus();
					});
			};
		}
	};
});

import React from 'react';
import { Card } from '@upsales/components';
import './ClickableCard.scss';

const ClickableCard = ({ children, className = '', ...rest }) => (
	<Card className={`${className} ClickableCard`.trim()} {...rest}>
		{children}
	</Card>
);

export default ClickableCard;

'use strict';

angular.module('upResources').factory('LeadSource', [
	'$resource',
	'$q',
	'URL',
	'API',
	'LeadSourceAttributes',
	function LeadSource($resource, $q, URL, API, LeadSourceAttributes) {
		var LA = LeadSourceAttributes();
		var Attribute = LA.attr;
		var Keys = LA.keys;

		var baseUrl = URL + API + ':customerId/leadsources';

		var Resource = $resource(
			baseUrl,
			{},
			{
				get: { method: 'GET', isArray: false },
				getAll: { method: 'GET', isArray: false, url: baseUrl + 2 }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.get({}, params).$promise;
			},

			get: function (id) {
				return Resource.get({}, id).$promise;
			},

			getAllSources: function (filter, options) {
				var params = angular.extend(filter || {}, options);

				return Resource.getAll(params).$promise;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

const { CurrencyFormat } = require('App/babel/utils/numberFormat');
const openModal = require('App/services/Modal').default;
import openAgreement from 'App/helpers/openSubscriptionHelper';

angular.module('domain.agreement').controller('ListAgreements', [
	'$upModal',
	'$translate',
	'Agreement',
	'AgreementAttributes',
	'listViews',
	'$scope',
	'AppService',
	'FixOrder',
	'MultiRunnerActions',
	'FilterHelper',
	'Onboarding',
	'$multiSelect',
	'$filter',
	'AgreementHelper',
	'$safeApply',
	function (
		$upModal,
		$translate,
		Agreement,
		AgreementAttributes,
		listViews,
		$scope,
		AppService,
		FixOrder,
		MultiRunnerActions,
		FilterHelper,
		Onboarding,
		$multiSelect,
		$filter,
		AgreementHelper,
		$safeApply
	) {
		var Ctrl = this;
		var self;
		var customerId;
		var documentTemplates = [];

		Ctrl.attributes = AgreementAttributes();
		Ctrl.disableAddFilter = false;
		Ctrl.showAddBtn = false;
		Ctrl.listType = 'agreement';
		Ctrl.listViews = listViews;
		Ctrl.columnTemplate = '/upsales/common/components/columnTemplates/listAgreements.html';
		Ctrl.title = 'default.agreement';
		Ctrl.useMultiActions = true;
		Ctrl.total = 0;

		Ctrl.tableTemplate = 'agreementRow';

		var entries = [];
		var currentQuery = [];
		const numberFormat = $filter('numberFormat');
		const metadata = AppService.getMetadata();

		Ctrl.addButton = {
			getLabel: function () {
				return (
					$translate.instant('default.create') + ' ' + $translate.instant('default.agreement').toLowerCase()
				);
			},
			createEntry: function () {
				Ctrl.createEntry();
				Onboarding.setListIntro('agreement');
			},
			isEnabled: function () {
				const self = AppService.getSelf();
				return self.createRights.Agreement !== 'NO';
			}
		};

		Ctrl.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var clientNameFilter = props.activeFilters.ListSearch;
				return clientNameFilter && clientNameFilter.value ? clientNameFilter.value : '';
			},
			getPlaceholder: function () {
				return $translate.instant('filters.quickSearch');
			}
		};

		// Listen for agreement updates and
		// check if the agreement is in the current list
		$scope.$on('agreement.updated', function (e, updated) {
			const agreement = entries.find(agreement => agreement.id === updated.id);
			if (agreement) {
				angular.extend(agreement, updated);
				$scope.$broadcast('listView.refresh');
			}
		});

		var updateMultiselectTotal = function () {
			$multiSelect.setTotal(Ctrl.total);
		};

		$scope.$on('agreement.added', function (e, added) {
			if (FilterHelper.match(currentQuery.q, added, 'agreement')) {
				Ctrl.total++;
				updateMultiselectTotal();
				entries.push(added);
				$safeApply($scope);
			}
		});

		$scope.$on('agreement.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(entries, { id: deleted.id });
			if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				entries.splice(existingIndex, 1);
			}
		});

		Ctrl.formatTotal = function () {
			if (Ctrl.total === 1) {
				return Ctrl.total + ' ' + $translate.instant('agreement.agreement').toLowerCase();
			} else {
				return Ctrl.total + ' ' + $translate.instant('agreement.agreements').toLowerCase();
			}
		};

		/**
		 * The add button
		 */
		Ctrl.createEntry = function () {
			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				return $upModal.open('editAgreement', { customerId: customerId });
			}

			openModal('CreateSubscription', { createdFrom: 'oldAgreementList' });
		};

		/**
		 * Remove a row
		 *
		 * @param  {object} activity The row you clicked on
		 * @return {promise}         When promise is resolved {listView} will deletet that row for you
		 */
		Ctrl.removeEntry = function (agreement) {
			Agreement.customer(customerId)['delete'](agreement);
		};

		/**
		 * Clicks on a row
		 *
		 * @param  {object} activity The row you just clicked
		 * @param  {array} entries   all the visible entries
		 */
		Ctrl.clickedEntry = function (agreement) {
			openAgreement({ agreement });
		};

		// The getter function {listView} will need
		Ctrl.getEntries = function (query, rb) {
			query.q = query.q || [];

			getValueSum(rb.clone());

			// result promise
			return Agreement.customer(customerId)
				.find(query, { mapCustom: true })
				.then(function (res) {
					entries = res.data;
					Ctrl.total = res.metadata.total;

					// eslint-disable-next-line no-unused-vars
					angular.forEach(entries, function (order) {
						FixOrder.print(order);
					});

					return res;
				});
		};

		let valueSumQueryId = 0;
		let sumString = '';

		async function getValueSum(rb) {
			try {
				const queryId = ++valueSumQueryId;
				const currencyFilterValue = _.get(Ctrl.getFilters(), 'Currency.value[0]', null);
				const hasCurrencyFilter = currencyFilterValue && currencyFilterValue !== metadata.defaultCurrency.iso;
				const method = hasCurrencyFilter ? 'getMasterCurrencySum' : 'getRoleCurrencySum';

				const result = await AgreementHelper[method](rb, customerId);

				if (queryId !== valueSumQueryId) {
					return;
				}
				const currencyFormat = new CurrencyFormat(currencyFilterValue || metadata.defaultCurrency.iso);

				let sum = result.data.sum.value;
				if (hasCurrencyFilter) {
					const currency = metadata.customerCurrencies.find(currency => currency.iso === currencyFilterValue);
					sum = sum * currency.rate;
				}

				sumString = currencyFormat.short(sum);
			} catch (error) {
				console.error(error);
				sumString = '';
			}
			Ctrl.renderFormatTotal();
		}

		Ctrl.renderFormatTotal = function (total, sumString) {
			Ctrl.formatTotal(total, sumString);
			$scope.$broadcast('listView.formatTotal');
		};

		Ctrl.formatTotal = function () {
			const formattedTotal = numberFormat(Ctrl.total);
			const entityString = $translate
				.instant(Ctrl.total === 1 ? 'default.agreement' : 'default.agreements')
				.toLowerCase();
			const totalString = `${formattedTotal} ${entityString}`;
			return sumString ? `${totalString}, ${sumString}` : totalString;
		};

		Ctrl.createDocument = function (agreement) {
			$upModal.open('createDocument', { type: 'agreement', id: agreement.id, templates: documentTemplates });
		};

		Ctrl.agreementHasEnded = function (agreement) {
			var tempNextOrderDate = agreement.metadata.agreementNextOrderDate;
			if (!agreement.metadata.agreementEnddate) {
				return false;
			}
			if (agreement.metadata.agreementOrderCreationTime) {
				tempNextOrderDate = moment(tempNextOrderDate).add(
					agreement.metadata.agreementOrderCreationTime,
					'days'
				);
			}
			if (
				(!agreement.metadata.willCreateMoreOrders && agreement.id) ||
				!moment(agreement.metadata.agreementEnddate).isAfter(moment(tempNextOrderDate))
			) {
				return true;
			}
			return false;
		};

		var init = function () {
			self = AppService.getSelf();

			// Set customer it now when we know we have it
			customerId = AppService.getCustomerId();
			var agreementCf = _.map(AppService.getCustomFields('agreement'), function (field) {
				field.nameType = 'Agreement';
				return field;
			});

			var orderCf = AppService.getCustomFields('order');

			Ctrl.customFields = agreementCf.concat(orderCf); // ADD AGREEMENT
			if (self.createRights.Agreement === 'ALL' || self.createRights.Agreement === 'OWN') {
				Ctrl.showAddBtn = true;
				Ctrl.addBtnLabel =
					$translate.instant('default.create') +
					' ' +
					$translate.instant('default.recurringOrder').toLowerCase();
			}

			Ctrl.listOpts = {
				type: 'agreement',
				hasCheck: true,
				editable: true,
				placeholderKey: 'agreement',
				clickedEntry: function (agreement) {
					openAgreement({ agreement });
				},
				removeEntry: function (agreement) {
					return Agreement.customer(customerId)['delete'](agreement);
				}
			};

			Ctrl.multiActions = MultiRunnerActions.get(MultiRunnerActions.type.AGREEMENT);
		};

		//eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './NavbarButton.scss';

const NavbarButton = ({ className, ...props }) => {
	const classes = new bemClass('NavbarButton', className);
	return <div className={classes.b()} {...props} />;
};

export default NavbarButton;

'use strict';

angular.module('upAttributes').service('AccountProfileAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {};

			var filters = [];
			var standardFilters = {};

			return {
				getAll: function () {
					return attributes;
				},
				filters: filters,
				standardFilters: standardFilters,
				attr: attributes,
				keys: {}
			};
		};
	}
]);

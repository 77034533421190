import AccessType from 'App/enum/AccessType';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { FilterPreset, generateFilter } from '../filterGenerators';

export default (): { [key: string]: FilterConfig } => ({
	Name: generateFilter(FilterPreset.Text, {
		field: 'name',
		title: 'filters.title.campaignName'
	}),
	User: generateFilter(FilterPreset.User, { title: 'filters.title.campaignUser' }, AccessType.PROJECT),
	Active: generateFilter(FilterPreset.Radio, {
		listTitle: 'default.activeInactive',
		field: 'active',
		title: 'filters.title.campaignStatus',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.activeContacts', value: true, comparisonType: 'Equals' },
			{ text: 'default.inactiveContacts', value: false, comparisonType: 'Equals' }
		]
	})
});

export const CallListFilters = (): { [key: string]: FilterConfig } => ({
	Name: generateFilter(FilterPreset.Text, {
		field: 'name',
		title: 'filters.title.callListName'
	}),
	User: generateFilter(FilterPreset.User, { title: 'filters.title.campaignUser' }, AccessType.PROJECT)
});

import logError from 'App/babel/helpers/logError';

angular.module('services.latestAccountsService', []).service('LatestAccountsService', [
	'localStorageService',
	'$rootScope',
	'AppService',
	function (localStorageService, $rootScope, AppService) {
		var instance = {};

		var getKey = function (customerId, userId) {
			return 'latestAccounts-' + customerId + '-' + userId;
		};

		instance.get = function (customerId, userId, opts) {
			var key = getKey(customerId, userId);
			var accounts = localStorageService.get(key) || [];
			return opts && opts.onlyActive
				? _.filter(accounts, function (account) {
						return account.active;
				  })
				: accounts;
		};

		instance.addAccount = function (customerId, userId, account) {
			var key = getKey(customerId, userId);

			var latestAccounts = _(instance.get(customerId, userId)) // get accounts from local storage
				.unshift(account) // prepend this account
				.uniq('id') // filter accounts on uniq id
				.slice(0, 10) // limit accounts to 10
				.map(function (acc) {
					return {
						id: acc.id,
						name: acc.name,
						addresses: acc.$addresses || acc.addresses,
						active: acc.active,
						projects: acc.projects || [],
						priceListId: acc.priceListId,
						operationalAccount: acc.operationalAccount
					};
				})
				.value(); // get the results

			localStorageService.add(key, latestAccounts);
			$rootScope.$broadcast('LatestAccounts.change', { key: key, accounts: latestAccounts });
		};

		instance.removeAccount = function (customerId, userId, id) {
			var key = getKey(customerId, userId);

			var latestAccounts = _(instance.get(customerId, userId)).reject({ id: id }).value();
			localStorageService.add(key, latestAccounts);
			$rootScope.$broadcast('LatestAccounts.change', { key: key, accounts: latestAccounts });
		};

		instance.clear = function (customerId, userId) {
			var key = getKey(customerId, userId);
			localStorageService.remove(key);
			$rootScope.$broadcast('LatestAccounts.change', { key: key, accounts: [] });
		};

		instance.getKey = getKey;

		$rootScope.$on('AppService.loaded', () => {
			const self = AppService.getSelf();
			const customerId = AppService.getCustomerId();
			const accounts = instance.get(customerId, self.id);

			if (accounts && accounts.length) {
				const ids = accounts.map(account => account.id);

				const rb = new Tools.RequestBuilder();
				rb.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, ids);

				Tools.Account.customer(customerId)
					.find(rb.build())
					.then(({ data }) => {
						const idsToRemove = ids.filter(id => !_.find(data, { id }));
						for (const id of idsToRemove) {
							instance.removeAccount(customerId, self.id, id);
						}
					})
					.catch(e => {
						logError(e, 'Failed checking latest accounts');
					});
			}
		});

		// listen for deletes
		$rootScope.$on('account.deleted', function (e, account) {
			const self = AppService.getSelf();
			const customerId = AppService.getCustomerId();

			instance.removeAccount(customerId, self.id, account.id);
		});

		// on merge
		$rootScope.$on('account.merged', function (e, res) {
			const self = AppService.getSelf();
			const customerId = AppService.getCustomerId();

			instance.removeAccount(customerId, self.id, res.deleted.id);
		});

		//on update
		$rootScope.$on('account.updated', function (e, account) {
			const self = AppService.getSelf();
			const customerId = AppService.getCustomerId();
			instance.removeAccount(customerId, self.id, account.id);
			instance.addAccount(customerId, self.id, account);
		});

		return instance;
	}
]);

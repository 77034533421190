import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChooseOutcome from './ChooseOutcome';
import Actions from './Actions';
import { getMainClass, getOutcomeStatus } from './Helpers';
import './AppointmentOutcomes.scss';
import BorderBox from 'App/components/BorderBox';

const AppointmentOutcomes = props => {
	const {
		appointment,
		answerOutcome,
		followUp,
		createOpportunity,
		todos,
		fetchTodos,
		disqualifyCompany,
		saving,
		originalOutcome,
		disabledOutcomeSelect,
		save,
		saveAppointmentWithAction,
		rescheduleAppointment,
		isRescheduling
	} = props;

	const [forceChooseOutcome, setForceChooseOutcome] = useState(false);
	const { outcome } = appointment;
	const { outcomeCompleted, outcomeNotCompleted } = getOutcomeStatus(outcome);
	const shouldRenderActions = (outcomeCompleted || outcomeNotCompleted) && !forceChooseOutcome;

	const MainClass = getMainClass();

	return (
		<BorderBox className={MainClass.b()} boxShadow>
			{shouldRenderActions ? (
				<Actions
					disableOutcomeClick={disabledOutcomeSelect}
					complete={outcomeCompleted}
					onClick={() => setForceChooseOutcome(true)}
					todos={todos}
					fetchTodos={fetchTodos}
					disqualifyCompany={disqualifyCompany}
					followUp={followUp}
					createOpportunity={createOpportunity}
					appointment={appointment}
					saving={saving}
					originalOutcome={originalOutcome}
					save={save}
					saveAppointmentWithAction={saveAppointmentWithAction}
					rescheduleAppointment={rescheduleAppointment}
					isRescheduling={isRescheduling}
				/>
			) : (
				<ChooseOutcome
					outcome={outcome}
					answerOutcome={answerOutcome}
					onClick={() => setForceChooseOutcome(false)}
					saving={saving}
				/>
			)}
		</BorderBox>
	);
};

AppointmentOutcomes.propTypes = {
	appointment: PropTypes.object.isRequired,
	answerOutcome: PropTypes.func.isRequired,
	followUp: PropTypes.func.isRequired,
	createOpportunity: PropTypes.func.isRequired,
	todos: PropTypes.array.isRequired,
	fetchTodos: PropTypes.func.isRequired,
	disqualifyCompany: PropTypes.func.isRequired,
	disabledOutcomeSelect: PropTypes.bool,
	saving: PropTypes.bool,
	originalOutcome: PropTypes.string,
	save: PropTypes.func,
	saveAppointmentWithAction: PropTypes.func,
	rescheduleAppointment: PropTypes.func,
	isRescheduling: PropTypes.func
};

export default AppointmentOutcomes;

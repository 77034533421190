import React, { lazy, Suspense } from 'react';
import { matchPath, Switch } from 'react-router-dom';
import Route from './route';
const MailTemplates = lazy(() => import('App/pages/MailTemplates'));
import MailTemplatesAllowed from 'App/pages/MailTemplates/allowed';
const ListUserDefinedObjects = lazy(() => import('App/pages/ListUserDefinedObjects'));
import ListUserDefinedObjectsAllowed from 'App/pages/ListUserDefinedObjects/allowed';
const GroupMailEditor = lazy(() => import('Components/GroupMailEditor'));
const MailTemplateEditor = lazy(() => import('Components/MailTemplateEditor'));
const DocumentTemplateEditor = lazy(() => import('Components/DocumentTemplateEditor'));
const DetectedDuplicates = lazy(() => import('Components/DetectedDuplicates'));
const DetectedDuplicatesLandingPage = lazy(() => import('App/components/DetectedDuplicatesLandingPage'));
const CalendarView = lazy(() => import('Components/CalendarView/CalendarView'));
const ChangeBrands = lazy(() => import('Components/Brands/ChangeBrands'));
const MarketingBoard = lazy(() => import('App/pages/MarketingBoard'));
import MarketingBoardAllowed from 'App/pages/MarketingBoard/allowed';
const Salesboard = lazy(() => import('App/pages/Salesboard/SalesboardRouter'));
import SalesboardAllowed from 'App/pages/Salesboard/allowed';
const FormEditor = lazy(() => import('Components/FormEditor'));
const FormOverview = lazy(() => import('Components/FormOverview'));
const TodoList = lazy(() => import('App/pages/TodoList'));
import TodoListAllowed from 'App/pages/TodoList/allowed';
const TaskList = lazy(() => import('App/pages/TaskList'));
import TaskListAllowed from 'App/pages/TodoList/allowed';
const Prospecting = lazy(() => import('Components/Prospecting'));
import ProspectingAllowed from 'Components/Prospecting/allowed';
const Signals = lazy(() => import('App/pages/Prospecting/Signals'));
import SignalsAllowed from 'App/pages/Prospecting/Signals/allowed';
const EditSocialEvent = lazy(() => import('Components/SocialEvent/EditSocialEvent'));
import EditSocialEventAllowed from 'Components/SocialEvent/allowed';
const Reportcenter = lazy(() => import('App/pages/Reportcenter'));
import ReportcenterAllowed from 'App/pages/Reportcenter/allowed';
import history from './history';
import LoaderPage from './LoaderPage';
const ListCampaigns = lazy(() => import('../ListCampaigns'));
import CallListsAllowed from 'App/pages/ListCampaigns/CallListsAllowed';
const ListForms = lazy(() => import('App/pages/ListFormsAndLandingPages/ListForms'));
import ListFormsAllowed from 'App/pages/ListFormsAndLandingPages/allowed';
const ListLandingPages = lazy(() => import('App/pages/ListFormsAndLandingPages/ListLandingPages'));
import ListLandingPagesAllowed from 'App/pages/ListFormsAndLandingPages/allowed';
const ListSocialEvents = lazy(() => import('App/pages/ListSocialEvents'));
import ListSocialEventsAllowed from 'App/pages/ListSocialEvents/allowed';
import ListFlowsAllowed from '../ListFlows/allowed';
const ListFlows = lazy(() => import('../ListFlows'));
const ListMail = lazy(() => import('../ListMail'));
const ListMailCampaigns = lazy(() => import('../ListMailCampaigns'));
import ListMailCampaignsAllowed from '../ListMailCampaigns/allowed';
const AccountGrowth = lazy(() => import('App/pages/AccountGrowth'));
import AccountGrowthAllowed from 'App/pages/AccountGrowth/allowed';
const CompanyGroupCard = lazy(() => import('App/pages/CompanyGroupCard'));
import CompanyGroupCardAllowed from 'App/pages/CompanyGroupCard/allowed';
const ListSegments = lazy(() => import('../ListSegments'));
const FormSubmits = lazy(() => import('App/pages/ListFormSubmits'));
import FormSubmitsAllowed from 'App/pages/ListFormSubmits/allowed';
const ListOrders = lazy(() => import('../ListOrders'));
import ListOrdersAllowed from 'App/pages/ListOrders/allowed';
const ListVisitors = lazy(() => import('App/pages/ListVisits'));
import ListVisitorsAllowed from 'App/pages/ListVisits/allowed';
const ListContacts = lazy(() => import('App/pages/ListContacts'));
import ListContactsAllowed from 'App/pages/ListContacts/allowed';
const ListAgreements = lazy(() => import('App/pages/ListAgreements'));
import ListAgreementsAllowed from 'App/pages/ListAgreements/allowed';
const ListOpportunities = lazy(() => import('App/pages/ListOpportunities'));
import ListOpportunitiesAllowed from 'App/pages/ListOpportunities/allowed';
const ListPhoneCalls = lazy(() => import('App/pages/ListPhoneCalls'));
import ListPhoneCallsAllowed from 'App/pages/ListPhoneCalls/allowed';
const ListLeads = lazy(() => import('../ListLeads'));
import ListLeadsAllowed from '../ListLeads/allowed';
const ClientCard = lazy(() => import('../ClientCard'));
import ClientCardAllowed from '../ClientCard/allowed';
const CustomerPortfolio = lazy(() => import('../CustomerPortfolio'));
import CustomerPortfolioAllowed from '../CustomerPortfolio/allowed';
const AdvancedSearch = lazy(() => import('../AdvancedSearch'));
import AdvancedSearchAllowed from '../AdvancedSearch/allowed';
import { AccountSelf, Self } from 'App/resources/AllIWant';
const Admin = lazy(() => import('../Admin/AdminRoutes'));
import ListProjectPlanAllowed from 'App/pages/ListProjectPlans/allowed';
const ListProjectPlan = lazy(() => import('App/pages/ListProjectPlans'));
import ProjectBoardAllowed from 'App/pages/ProjectBoard/allowed';
const ProjectBoard = lazy(() => import('App/pages/ProjectBoard'));
const SupportList = lazy(() => import('../SupportList'));
import SupportListAllowed from '../SupportList/allowed';

export const isAdmin = () => {
	const self = Tools.AppService.getSelf();
	return self.administrator;
};

// Notify analytics that the location have changed
export const notifyAnalytics = () => {
	// Disabled since this means we double track everything.
	// When all routes are in react we can do tracking from here
	// and remove the tracking in app/upsales/app.js
	// import Analytics from 'App/services/Analytics';
	// Analytics.page();
};

history.listen(() => notifyAnalytics());

export type RouteConfig = {
	path: string;
	component: React.LazyExoticComponent<any>;
	sub?: string[];
	queryParams?: string;
	name?: string;
	section: 'sale' | 'market' | 'followup' | 'admin' | 'support';
	allowed?: () => boolean;
	public?: boolean;
};

export const routes: RouteConfig[] = [
	{
		path: '/group-mail-editor/:id',
		component: GroupMailEditor,
		sub: ['/design', '/recipients', '/settings'],
		section: 'market'
	},
	{
		path: '/calendar/',
		queryParams: '?date?selected',
		component: CalendarView,
		name: 'calendar',
		section: 'sale'
	},
	{
		path: '/mail-template-editor/:id',
		component: MailTemplateEditor,
		sub: ['/design', '/settings'],
		section: 'market'
	},
	{
		path: '/marketingboard',
		component: MarketingBoard,
		allowed: MarketingBoardAllowed,
		section: 'market',
		name: 'marketingboard'
	},
	{
		path: '/salesboard/:id',
		component: Salesboard,
		allowed: SalesboardAllowed,
		section: 'sale',
		name: 'salesboard'
	},
	{
		path: '/salesboard/',
		component: Salesboard,
		allowed: SalesboardAllowed,
		section: 'sale',
		name: 'salesboard'
	},
	{
		path: '/:customerId/detected-duplicates/:entity',
		component: DetectedDuplicates,
		allowed: isAdmin,
		name: 'detected-duplicates',
		section: 'sale'
	},
	{
		path: '/detected-duplicates-landing-page/:entity',
		component: DetectedDuplicatesLandingPage,
		allowed: isAdmin,
		name: 'detected-duplicates-landing-page',
		section: 'sale'
	},
	{
		path: '/changebrand/:id',
		component: ChangeBrands,
		name: 'change-brand',
		section: 'sale'
	},
	{
		path: '/form-editor/:id',
		component: FormEditor,
		section: 'market'
	},
	{
		path: '/form-overview/:id',
		component: FormOverview,
		name: 'form-overview',
		section: 'market'
	},
	{
		path: '/:customerId/events/edit/:id?',
		component: EditSocialEvent,
		name: 'editSocialEvent',
		allowed: EditSocialEventAllowed,
		section: 'market'
	},
	{
		path: '/todo/:view?',
		name: 'todo',
		component: TodoList,
		allowed: TodoListAllowed,
		section: 'sale'
	},
	{
		path: '/task',
		name: 'task',
		component: TaskList,
		allowed: TaskListAllowed,
		section: 'sale'
	},
	{
		path: '/mailtemplates',
		name: 'mailtemplates',
		component: MailTemplates,
		allowed: MailTemplatesAllowed,
		section: 'market'
	},
	{
		path: '/userdefinedobjects/:typeId',
		name: 'userdefinedobjects',
		component: ListUserDefinedObjects,
		allowed: ListUserDefinedObjectsAllowed,
		section: 'sale'
	},
	{
		path: '/rc/:dashboardId?',
		name: 'reportcenter',
		component: Reportcenter,
		allowed: ReportcenterAllowed,
		section: 'followup'
	},
	{
		path: '/signals',
		name: 'signals',
		component: Signals,
		allowed: SignalsAllowed,
		section: 'sale'
	},
	{
		path: '/campaigns',
		name: 'campaigns',
		component: ListCampaigns,
		section: 'sale'
	},
	{
		path: '/callLists',
		name: 'calllists',
		component: ListCampaigns,
		allowed: CallListsAllowed,
		section: 'sale'
	},
	{
		path: '/forms',
		name: 'forms',
		component: ListForms,
		allowed: ListFormsAllowed,
		section: 'market'
	},
	{
		path: '/landingpages',
		name: 'landingpages',
		component: ListLandingPages,
		allowed: ListLandingPagesAllowed,
		section: 'market'
	},
	{
		path: '/mail',
		name: 'mail',
		component: ListMail,
		section: 'sale'
	},
	{
		path: '/mail-campaigns',
		name: 'mail-campaigns',
		component: ListMailCampaigns,
		allowed: ListMailCampaignsAllowed,
		section: 'market'
	},
	{
		path: '/events',
		name: 'events',
		component: ListSocialEvents,
		allowed: ListSocialEventsAllowed,
		section: 'market'
	},
	{
		path: '/prospecting',
		queryParams: '?box',
		name: 'prospecting',
		component: Prospecting,
		allowed: ProspectingAllowed,
		section: 'sale'
	},
	{
		path: '/flows',
		name: 'flows',
		component: ListFlows,
		allowed: ListFlowsAllowed,
		section: 'market'
	},
	{
		path: '/accountGrowth',
		name: 'accountGrowth',
		component: AccountGrowth,
		allowed: AccountGrowthAllowed,
		section: 'sale'
	},
	{
		path: '/companyGroup/:prospectingId/:view?',
		name: 'companyGroup',
		component: CompanyGroupCard,
		allowed: CompanyGroupCardAllowed,
		section: 'sale'
	},
	{
		path: '/segments',
		name: 'segments',
		component: ListSegments,
		section: 'market'
	},
	{
		path: '/form-submits',
		name: 'formSubmits',
		queryParams: '?f',
		component: FormSubmits,
		allowed: FormSubmitsAllowed,
		section: 'market'
	},
	{
		path: '/orders',
		name: 'orders',
		component: ListOrders,
		allowed: ListOrdersAllowed,
		section: 'sale'
	},
	{
		path: '/visitors',
		name: 'visitors',
		component: ListVisitors,
		allowed: ListVisitorsAllowed,
		section: 'market'
	},
	{
		path: '/leads2',
		name: 'leads',
		component: ListLeads,
		allowed: ListLeadsAllowed,
		section: 'market'
	},
	{
		path: '/companies',
		name: 'companies',
		component: AccountGrowth,
		allowed: AccountGrowthAllowed,
		section: 'sale'
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: ListContacts,
		allowed: ListContactsAllowed,
		section: 'sale'
	},
	{
		path: '/agreements',
		name: 'agreements',
		component: ListAgreements,
		allowed: ListAgreementsAllowed,
		section: 'sale'
	},
	{
		path: '/projects',
		name: 'projects',
		component: ListProjectPlan,
		allowed: ListProjectPlanAllowed,
		section: 'sale'
	},
	{
		path: '/project-board',
		name: 'projectBoard',
		component: ProjectBoard,
		allowed: ProjectBoardAllowed,
		section: 'sale'
	},
	{
		path: '/opportunities',
		name: 'opportunities',
		component: ListOpportunities,
		allowed: ListOpportunitiesAllowed,
		section: 'sale'
	},
	{
		path: '/phoneCalls',
		name: 'phonecalls',
		component: ListPhoneCalls,
		allowed: ListPhoneCallsAllowed,
		section: 'sale'
	},
	{
		path: '/clientCard/:id/:page',
		name: 'clientCard',
		component: ClientCard,
		allowed: ClientCardAllowed,
		section: 'sale'
	},
	{
		path: '/document-template-editor/:id',
		component: DocumentTemplateEditor,
		sub: ['/details', '/design'],
		section: 'sale'
	},
	{
		path: '/customerPortfolio/:tab?',
		name: 'customerPortfolio',
		component: CustomerPortfolio,
		allowed: CustomerPortfolioAllowed,
		section: 'sale'
	},
	{
		path: '/advanced-search/:type?',
		name: 'advancedSearch',
		component: AdvancedSearch,
		allowed: AdvancedSearchAllowed,
		section: 'sale'
	},
	{
		path: '/support/:view?',
		name: 'support',
		component: SupportList,
		allowed: SupportListAllowed,
		section: 'support'
	},
	{
		path: '/admin/:page/:subpage?',
		name: 'admin',
		component: Admin,
		section: 'admin'
	}
];

export const getSectionFromPath = (path: string, accountSelf: AccountSelf, self: Self) => {
	let section = null;

	// Match path against all out route configs
	section = routes.find(r => (matchPath(path, { path: r.path }) ? r : null))?.section;

	// As long as we have our angular routes we also need to look there
	if (!section) {
		const state = Tools.$state.current;
		section = state.section;
		// This is stupid and should probably be removed when we have all routes in react
		if (state.sectionFn && typeof state.sectionFn === 'function') {
			section = state.sectionFn(accountSelf, self, Tools.$state.params, Tools.$location);
		}
	}

	return section || null;
};

const Routes = () => {
	return (
		<Suspense fallback={<LoaderPage />}>
			<Switch>
				{routes.map(r => (
					<Route key={r.path} allowed={r.allowed} path={r.path} public={r.public} component={r.component} />
				))}

				{/* fallback route when in angular */}
				<Route component={() => null} />
			</Switch>
		</Suspense>
	);
};

export default Routes;

import {
	initialState,
	RESET,
	SET_LOADING,
	SET_SAVING,
	SET_RECURRING_INTERVALS,
	SET_CATEGORIES,
	SET_PRODUCT,
	SET_INITIAL_CATEGORY,
	SET_CURRENCIES,
	SET_MULTI_CURRENCY,
	SET_CONTRIBUTION_MARGIN_ACTIVE,
	SET_MASTER_CURRENCY,
	SET_ERRORS,
	SET_PRICE_TYPE,
	SET_TIER_TYPE,
	SET_CAN_ADD_TIER_ROW,
	SET_PRICE_LIST,
	PRICE_TYPE,
	TIER_TYPE,
	SET_INITIAL_PRODUCT
} from 'Store/reducers/AdminEditProductReducer';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import {
	getProductCategoryTree,
	getUpdatedProductBundles,
	hasProductPriceBeenUpdated,
	MAX_CACHED_PRODUCTS
} from 'App/babel/helpers/product';
import Product from 'Resources/Product';
import _ from 'lodash';
import { removeItem, replaceItem } from 'Store/helpers/array';
import logError from 'App/babel/helpers/logError';
import RequestBuilder from 'Resources/RequestBuilder';
import { Equals, NotEquals } from 'Resources/ComparisonTypes';
import ProductAttributes from 'Attributes/ProductAttributes';
import { setProducts } from 'Store/actions/AppActions';
import { hasCMWithRR } from 'App/helpers/salesModelHelpers';
import { getProductCategoriesFromState, getStaticValuesFromState } from 'Store/selectors/AppSelectors';
import {
	Feature,
	getFeatureAvailableFromState,
	getSoftDeployAccessFromState
} from 'App/components/hooks/featureHelper';
import { getRecurringProductDefaultInterval } from 'App/helpers/subscriptionHelper';

export const setErrors = errors => ({ type: SET_ERRORS, errors });

const groupByPriceList = (product, customerCurrencies, priceLists, masterCurrency, showCM) => {
	const priceListCurrencies = {};
	const priceListTiers = {};
	product.tiers = _.sortBy(product.tiers, 'start');

	priceLists.forEach(priceList => {
		priceListCurrencies[priceList.id] = _.map(customerCurrencies, customerCurrency => {
			const inPriceList = _.find(product.currencies, {
				currency: customerCurrency.iso,
				priceListId: priceList.id
			});
			if (inPriceList) {
				return inPriceList;
			} else {
				const isMasterAndDefault = masterCurrency.iso === customerCurrency.iso && priceList.isDefault;
				const newCurrency = {
					currency: customerCurrency.iso,
					price: isMasterAndDefault ? product.listPrice : 0,
					priceListId: Number(priceList.id)
				};
				if (showCM) {
					newCurrency.purchaseCost = isMasterAndDefault ? product.purchaseCost : 0;
				}
				return newCurrency;
			}
		});

		const tiers = product.tiers.filter(tier => tier.priceListId === priceList.id);

		if (tiers.length) {
			if (tiers[tiers.length - 1].end === 0) {
				tiers[tiers.length - 1].end = null;
			}
		} else {
			tiers.push({
				start: 1,
				end: null,
				isTotalPrice: false,
				priceListId: priceList.id,
				currencies: []
			});
		}

		tiers.forEach(tier => {
			customerCurrencies.forEach(customerCurrency => {
				if (!tier.currencies.find(c => c.currency === customerCurrency.iso)) {
					tier.currencies.push({ value: null, currency: customerCurrency.iso });
				}
			});
		});

		priceListTiers[priceList.id] = tiers;
	});
	return { priceListCurrencies, priceListTiers };
};

const getSortId = catId => {
	const AppService = getAngularModule('AppService');
	let products = AppService.getProducts();

	if (!catId) {
		products = _.filter(products, { category: null });
	} else {
		products = _.filter(products, function (p) {
			return p.category && p.category.id === parseInt(catId);
		});
	}

	if (!products.length) {
		return 1;
	} else {
		return _.reduce(
			products,
			function (highestSortId, product) {
				if (product.sortId >= highestSortId) {
					return product.sortId + 1;
				}
				return highestSortId;
			},
			1
		);
	}
};

const isRealNum = n => !isNaN(n) && n != null && n !== '';

const tiersValid = (tiers = []) => {
	let hasErrors = false;
	const errors = {};
	// Iterate tiers and make sure they are valid
	tiers.forEach((tier, i) => {
		const prev = tiers[i - 1];

		errors[i] = {};
		// Check if tier overlaps prev tier
		if (prev && isRealNum(tier.start) && tier.start <= prev.end) {
			errors[i].overlap = true;
			hasErrors = true;
		}
		// Check if there is a gap between this and the prev tier
		if (prev && isRealNum(tier.start) && tier.start > prev.end + 1) {
			errors[i].gap = true;
			hasErrors = true;
		}
		// Check if tier has a start
		if (!isRealNum(tier.start)) {
			errors[i].missingStart = true;
			hasErrors = true;
		}

		if (isRealNum(tier.start) && isRealNum(tier.end) && tier.start > tier.end) {
			errors[i].startEndOverlap = true;
			hasErrors = true;
		}
	});

	return hasErrors ? errors : null;
};

const validateForm = (defaultPriceListId, priceLists) => (dispatch, getState) => {
	const { product } = getState().AdminEditProduct;
	const tiersError = tiersValid(product.tiers[defaultPriceListId]);
	dispatch(setErrors({ name: !product.name, tier: tiersError }));

	const priceListErrors = [];
	priceLists.forEach(priceList => {
		const error = tiersValid(product.tiers[priceList.id]);
		if (error) {
			priceListErrors.push(priceList.name);
		}
	});
	if (priceListErrors.length) {
		dispatch(setErrors({ priceLists: priceListErrors }));
	}
};

export const getEmptyTier = lastRow => (_d, getState) => {
	const { currencies, tierType, priceList } = getState().AdminEditProduct;

	return {
		start: lastRow ? lastRow.end + 1 : 1, // one more than prev row or 1 if first
		end: null,
		priceListId: priceList.id,
		isTotalPrice: tierType === TIER_TYPE.TOTAL,
		currencies: currencies.map(c => ({
			currency: c.iso,
			value: null
		}))
	};
};

const setCanAddTierRow = newPriceListId => (dispatch, getState) => {
	const { product, priceList } = getState().AdminEditProduct;
	let canAddTierRow = true;
	const priceListId = newPriceListId ?? priceList.id;
	product.tiers[priceListId].forEach(({ start, end }) => {
		if (start == null || !end) {
			canAddTierRow = false;
		}
	});
	dispatch({ type: SET_CAN_ADD_TIER_ROW, canAddTierRow });
};

export const reset = () => ({ type: RESET });
export const setLoading = loading => ({ type: SET_LOADING, loading });
export const setSaving = saving => ({ type: SET_SAVING, saving });
export const setRecurringIntervals = intervals => ({ type: SET_RECURRING_INTERVALS, intervals });
export const setCategories = () => (dispatch, getState) => {
	const { priceType } = getState().AdminEditProduct;
	// Set disabled true/false if category is calced
	const categories = getProductCategoryTree(c => ({
		...c,
		disabled: !!c.calculatingField && priceType === PRICE_TYPE.TIERED
	}));
	dispatch({ type: SET_CATEGORIES, categories });
};
export const setProduct = product => ({ type: SET_PRODUCT, product });
export const setInitialProduct = initialProduct => ({ type: SET_INITIAL_PRODUCT, initialProduct });
export const setInitialCategory = initialCategory => ({ type: SET_INITIAL_CATEGORY, initialCategory });
export const setCurrencies = currencies => ({ type: SET_CURRENCIES, currencies });
export const setMultiCurrency = multiCurrency => ({ type: SET_MULTI_CURRENCY, multiCurrency });
export const setContributionMarginActive = contributionMarginActive => ({
	type: SET_CONTRIBUTION_MARGIN_ACTIVE,
	contributionMarginActive
});
export const setMasterCurrency = masterCurrency => ({ type: SET_MASTER_CURRENCY, masterCurrency });

const updateTierTypeInAllTiers = (productTiers, tierType) => {
	return Object.entries(productTiers).reduce((newTiers, [priceListId, priceListTiers]) => {
		newTiers[priceListId] = priceListTiers.map(tier => ({
			...tier,
			isTotalPrice: tierType === TIER_TYPE.TOTAL
		}));
		return newTiers;
	}, {});
};

export const setTierType = tierType => (dispatch, getState) => {
	const { product } = getState().AdminEditProduct;
	dispatch({ type: SET_TIER_TYPE, tierType });
	dispatch(
		setProduct({
			...product,
			tiers: updateTierTypeInAllTiers(product.tiers, tierType)
		})
	);
};

export const setPriceList = selectedPriceList => (dispatch, getState) => {
	const { product, priceList: currentPriceList, priceType } = getState().AdminEditProduct;

	if (priceType === PRICE_TYPE.TIERED) {
		const tierError = tiersValid(currentPriceList ? product.tiers[currentPriceList.id] : []);
		dispatch(setErrors({ tier: tierError }));
		if (tierError) {
			return;
		}

		dispatch(setCanAddTierRow(selectedPriceList.id));
	}

	dispatch({ type: SET_PRICE_LIST, priceList: selectedPriceList });
};
export const addTierRow = () => (dispatch, getState) => {
	const { product, canAddTierRow, priceList } = getState().AdminEditProduct;
	if (!canAddTierRow) {
		return;
	}

	const currentTiers = product.tiers[priceList.id];

	dispatch(
		setProduct({
			...product,
			tiers: {
				...product.tiers,
				[priceList.id]: [...currentTiers, dispatch(getEmptyTier(currentTiers[currentTiers.length - 1]))]
			}
		})
	);
	dispatch(setCanAddTierRow());
};

export const setPriceType = priceType => (dispatch, getState) => {
	const { product, priceList } = getState().AdminEditProduct;
	dispatch({ type: SET_PRICE_TYPE, priceType });
	dispatch(
		setProduct({
			...product,
			priceType
		})
	);

	if (priceType === PRICE_TYPE.TIERED) {
		dispatch(setCanAddTierRow());
		const tierError = tiersValid(product.tiers[priceList.id]);
		dispatch(setErrors({ tier: tierError }));
	} else if (priceType === PRICE_TYPE.PER_UNIT) {
		dispatch(setErrors({ tier: null }));
	}

	// Update category tree (enable/disable calculated categories)
	dispatch(setCategories());
};

export const removeTierRow = i => (dispatch, getState) => {
	const { product, errors, priceList } = getState().AdminEditProduct;

	const currentTiers = product.tiers[priceList.id];
	const newTiers = removeItem(currentTiers, i);

	dispatch(setProduct({ ...product, tiers: { ...product.tiers, [priceList.id]: newTiers } }));
	dispatch(setCanAddTierRow());

	// If we have tier-errors we need to revalidate
	if (errors.tier) {
		const tiersError = tiersValid(newTiers);
		dispatch(setErrors({ ...errors, tier: tiersError }));
	}
};

export const updateTierRow = (i, row) => (dispatch, getState) => {
	const { product, priceList } = getState().AdminEditProduct;
	row.start = isNaN(parseInt(row.start)) ? null : parseInt(row.start);
	row.end = isNaN(parseInt(row.end)) ? null : parseInt(row.end);
	row.currencies = row.currencies.map(c => {
		c.value = isNaN(parseFloat(c.value)) ? null : parseFloat(c.value);
		return c;
	});

	const currentTiers = product.tiers[priceList.id];
	const newTiers = replaceItem(currentTiers, i, row);

	dispatch(setProduct({ ...product, tiers: { ...product.tiers, [priceList.id]: newTiers } }));
	dispatch(setCanAddTierRow());
};

export const save = () => async (dispatch, getState) => {
	const requiredCustomFieldIds = [];
	const {
		AdminEditProduct: { product: p, initialProduct, initialCategory, priceList, priceType },
		App
	} = getState();
	const { metadata, customFields } = App;
	customFields.product.forEach(field => {
		if (field.obligatoryField === 1) {
			requiredCustomFieldIds.push(field.id);
		}
	});
	const product = { ...p }; // clone it
	const productStandardFields = metadata.standardFields.Product;
	const isActiveArticleNo =
		_.get(productStandardFields, 'ArticleNo.active', false) &&
		getSoftDeployAccessFromState(App.accountSelf, 'NEW_FIELDS');
	const isRequiredArticleNo = _.get(productStandardFields, 'ArticleNo.required', false);
	const isActiveDescription =
		_.get(productStandardFields, 'Description.active', false) &&
		getFeatureAvailableFromState(App.accountSelf, Feature.PRODUCT_DESCRIPTION);
	const isRequiredDescription = _.get(productStandardFields, 'Description.required', false);

	// Check for errors
	dispatch(setErrors(initialState.errors));

	if (!product.name) {
		dispatch(setErrors({ name: !product.name }));
		return Promise.reject('FAILED_VALIDATION');
	}

	if (priceType === PRICE_TYPE.TIERED) {
		const tiersError = tiersValid(product.tiers[priceList.id]);
		if (tiersError) {
			dispatch(setErrors({ tier: tiersError }));
			return Promise.reject('FAILED_VALIDATION');
		}

		const priceLists = Tools.AppService.getPriceLists().filter(priceList => priceList.active);

		const priceListErrors = [];
		priceLists.forEach(priceList => {
			const error = tiersValid(product.tiers[priceList.id]);
			if (error) {
				priceListErrors.push(priceList.name);
			}
		});

		if (priceListErrors.length) {
			dispatch(setErrors({ priceLists: priceListErrors }));
			return Promise.reject('FAILED_VALIDATION');
		}
	}

	if (isActiveArticleNo && isRequiredArticleNo && !product.articleNo) {
		dispatch(setErrors({ articleNo: true }));
		return Promise.reject('FAILED_VALIDATION');
	}

	if (isActiveDescription && isRequiredDescription && !product.description) {
		dispatch(setErrors({ description: true }));
		return Promise.reject('FAILED_VALIDATION');
	}

	if (requiredCustomFieldIds && requiredCustomFieldIds.length) {
		const reqFields = _.filter(product.custom, function (field) {
			return requiredCustomFieldIds.includes(field.fieldId) && field.value;
		});
		if (reqFields.length !== requiredCustomFieldIds.length) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'product.error.missingCustomField'
			});
			return Promise.reject('FAILED_VALIDATION');
		}
	}

	if (isActiveArticleNo && product.articleNo) {
		const rb = new RequestBuilder();
		rb.addFilter(ProductAttributes.articleNo, Equals, product.articleNo);

		if (product.id) {
			rb.addFilter(ProductAttributes.id, NotEquals, product.id);
		}
		try {
			const products = await Product.findAll(rb.build());

			if (products.length) {
				dispatch(setErrors({ articleNo: true }));

				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'product.error.uniqArticleNo'
				});
				return Promise.reject('FAILED_VALIDATION');
			}
		} catch (err) {
			logError(err, 'Failed fetch products');
		}
	}

	product.tiers = Object.values(product.tiers).flat();
	product.currencies = Object.values(product.currencies).flat();
	dispatch(setSaving(true));

	// Set 0 from invalid currencies
	product.tiers = (product.tiers || []).map(tier => {
		tier.currencies = (tier.currencies || []).map(currency => {
			if (!isRealNum(currency.value)) {
				currency.value = 0;
			}
			return currency;
		});
		return tier;
	});

	// Map roles before save
	if (product.roles && product.roles.length) {
		product.roles = product.roles.map(({ id }) => ({ id }));
	}

	// Check if category changed, if yes we need to update sortId
	const categoryHasChanged =
		(product.category && product.category.id ? product.category.id : null) !==
		(initialCategory && initialCategory.id ? initialCategory.id : null);
	if (!product.id || categoryHasChanged) {
		product.sortId = getSortId(product.category ? product.category.id : null);
	}

	if (priceType === PRICE_TYPE.TIERED) {
		//Clear unit price info if tiered
		product.listPrice = 0;
		product.currencies = product.currencies.map(c => {
			c.price = 0;
			return c;
		});
	} else if (priceType === PRICE_TYPE.PER_UNIT) {
		product.tiers = [];
	}

	return Product.save(product, { params: { usePriceLists: 'true' } })
		.then(({ data }) => {
			// Setting $hasAccess fixes a bug which removes / hides the product from the UI after update
			const savedProduct = { ...data, $hasAccess: product.$hasAccess };
			// Update cache if less than MAX_CACHED_PRODUCTS products
			const { products, totals } = getState().App;
			if (totals.products < MAX_CACHED_PRODUCTS) {
				// Update or add product to cache
				const i = products.findIndex(p => p.id === savedProduct.id);
				if (i !== -1) {
					let updatedProducts = replaceItem(products, i, savedProduct);
					if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_BUNDLES)) {
						const hasSavedProductPriceChanged = hasProductPriceBeenUpdated(
							{ ...initialProduct, tiers: Object.values(initialProduct.tiers).flat() },
							savedProduct
						);
						// Update cached product bundles that contain the updated product
						updatedProducts = getUpdatedProductBundles(
							updatedProducts,
							savedProduct,
							hasSavedProductPriceChanged
						);
					}
					dispatch(setProducts(updatedProducts));
				} else {
					dispatch(setProducts([...products, savedProduct]));
				}
				Tools.$rootScope.$broadcast('cacheRefresher.products');
			}
		})
		.catch(e => {
			logError(e, 'Failed to save product');
			dispatch(setSaving(false));
			throw e; // To prevent close popup
		});
};

export const init = editProduct => async (dispatch, getState) => {
	const { App } = getState();
	const { params, customerCurrencies } = App.metadata;

	dispatch(reset());
	dispatch(setRecurringIntervals(getStaticValuesFromState(App, 'recurringInterval')));

	const currencies = _.sortBy(customerCurrencies, 'masterCurrency').reverse();
	const masterCurrency = _.find(currencies, 'masterCurrency');
	dispatch(setCurrencies(currencies));
	dispatch(setMasterCurrency(masterCurrency));

	const showCM =
		params.UseContributionMargin ||
		(getSoftDeployAccessFromState(App.accountSelf, 'COMBINE_RR_AND_CM') &&
			params.SalesModelOption3 === 'combinedWithCM');
	dispatch(setContributionMarginActive(showCM));

	let defaultInterval;
	const cmWithRROption = hasCMWithRR(App.metadata);
	if (App.metadata.params.SalesModel === 'rr' || cmWithRROption) {
		defaultInterval = getRecurringProductDefaultInterval(App.metadata)?.toString();
	}

	const product = editProduct || { ...Product.new(), recurringInterval: defaultInterval };

	const priceLists = Tools.AppService.getPriceLists().filter(priceList => priceList.active);
	const defaultPriceList = priceLists.find(priceList => priceList.isDefault);
	const isTiered = product.tiers.length;

	const { priceListCurrencies, priceListTiers } = groupByPriceList(
		product,
		currencies,
		priceLists,
		masterCurrency,
		showCM
	);
	product.currencies = priceListCurrencies;
	product.tiers = priceListTiers;
	// Find selected category to get more fields
	if (editProduct && editProduct.category) {
		product.category = getProductCategoriesFromState(App).find(c => c.id === product.category.id);
	}
	dispatch(setProduct(product));
	dispatch(setInitialProduct(product));
	dispatch(setPriceList(defaultPriceList));

	if (isTiered) {
		dispatch(setCanAddTierRow());
		dispatch(setPriceType(PRICE_TYPE.TIERED));
		dispatch(setTierType(product.tiers[defaultPriceList.id][0].isTotalPrice ? TIER_TYPE.TOTAL : TIER_TYPE.UNIT));
	} else {
		// Categories are set in setPriceType
		dispatch(setCategories());
	}

	dispatch(setInitialCategory(product ? product.category : null));
	dispatch(setMultiCurrency(params.MultiCurrency));

	dispatch(setCanAddTierRow());
	// Validate edits to find old errors
	if (editProduct) {
		dispatch(validateForm(defaultPriceList.id, priceLists));
	}
	dispatch(setLoading(false));
};

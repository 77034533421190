import logError from 'App/babel/helpers/logError';
import { Text } from '@upsales/components';
import React from 'react';

ReactTemplates.upFilters.components.listPageLazy = window.ReactCreateClass({
	/* REACT METHODS */
	getDefaultProps: function () {
		return {
			msBeforeSearch: 500,
			limit: 100,
			maxRows: 10000,
			defaultKey: 'id',
			rowComponent: 'itemRow'
		};
	},
	getInitialState: function () {
		return {
			pendingAJAXrequest: true,
			AJAXError: false,
			itemSearchString: '',
			data: [],
			selectedData: [],
			offset: 0,
			total: null,
			lastOffsetRequested: null,
			sortValue: false
		};
	},
	UNSAFE_componentWillMount: function () {
		this.onSearch = _.debounce(this.onSearch, this.props.msBeforeSearch);
		this.searchFn = Tools.$injector.invoke(this.props.filter.searchFn, {});
	},
	componentDidMount: function () {
		this.getInitialItems();
		ReactDOM.findDOMNode(this.refs['search-input']).focus();
	},
	compareValues: function (value1) {
		/* I am using this in UNSAFE_componentWillReceiveProps, maby it is bad to access this.props
			from there, anyhow the key prop wont change so it is safe anyways
		*/
		var filterConfig = this.props.filter;
		var key = filterConfig.key || this.props.defaultKey;

		return function (value2) {
			if (typeof value1 === 'string') {
				return value1.toLowerCase() === value2[key].toLowerCase();
			}
			return value1 === value2[key];
		};
	},
	updateSelectedData: function (props) {
		var _this = this;
		var filter = props.activeFilters[props.filterName];

		var selectedData = [];

		if (filter && filter.value && filter.value.length) {
			selectedData = _.reduce(
				filter.value,
				function (res, id) {
					var foundItem = _.find(_this.state.data, _this.compareValues(id));

					if (foundItem) {
						res.push(foundItem);
						return res;
					}

					foundItem = _.find(_this.state.selectedData, _this.compareValues(id));

					if (foundItem) {
						res.push(foundItem);
						return res;
					}

					return res;
				},
				[]
			);
		}
		this.setState({
			selectedData: selectedData
		});
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		this.updateSelectedData(newProps);
	},
	/* CUSTOM METHODS */
	getInitialItems: function () {
		var _this = this;
		var filter = this.props.filter;
		var $injector = Tools.$injector;
		var $q = $injector.get('$q');

		var searchString = this.getSearchString();

		var promises = {
			items: this.searchFn(
				searchString,
				filter.includeFields,
				this.state.offset,
				this.props.limit,
				undefined,
				this.props.activeFilters
			)
		};

		if (filter.value && filter.value.length) {
			var AppService = $injector.get('AppService');
			var customerId = AppService.getCustomerId();
			var Resource;

			if (filter.getSelected) {
				Resource = $injector.invoke(filter.getSelected, {});
				promises.selectedItems = Resource(filter.value);
			} else if (typeof filter.resource === 'function') {
				Resource = $injector.invoke(filter.resource, {});
				promises.selectedItems = Resource(customerId, filter.value);
			} else if (filter.resource) {
				var RequestBuilder = $injector.get('RequestBuilder');
				var requestBuilder = new RequestBuilder();
				var comparisonType =
					(filter.comparisonTypeToggle && filter.comparisonTypeToggle.falseValue) ||
					requestBuilder.comparisonTypes.Equals;
				var key = filter.key || _this.props.defaultKey;

				Resource = $injector.get(filter.resource);

				requestBuilder.addFilter(Resource.attr[key], comparisonType, filter.value);
				requestBuilder.fields = filter.includeFields;

				if (Resource.customer && typeof Resource.customer === 'function') {
					promises.selectedItems = Resource.customer(customerId).find(requestBuilder.build());
				} else {
					promises.selectedItems = Resource.find(requestBuilder.build());
				}
			} else {
				promises.selectedItems = Tools.$q.when({ data: filter.value });
			}
		}
		$q.all(promises)
			.then(function (res) {
				var valueKey = filter.key || _this.props.defaultKey;
				var data = res.items && res.items.data ? res.items.data : [];
				var currentSearchString = _this.getSearchString();

				data = _.unique(data, function (item) {
					var value = item[valueKey];
					return typeof value === 'string' ? value.toLowerCase() : value;
				});

				var newState = {
					selectedData: res.selectedItems && res.selectedItems.data ? res.selectedItems.data : []
				};

				if (searchString === currentSearchString) {
					newState = Object.assign(newState, {
						pendingAJAXrequest: false,
						lastOffsetRequested: 0,
						data: data,
						total: res.items.metadata ? res.items.metadata.total : data.length
					});
				}

				_this.setState(newState, function () {
					_this.updateSelectedData(_this.props);
				});
			})
			.catch(e => {
				logError(e, 'Failed to fetch initial items');
				_this.setState({
					pendingAJAXrequest: false,
					AJAXError: true
				});
			});
	},
	getSearchString: function () {
		return ReactDOM.findDOMNode(this.refs['search-input'])
			? ReactDOM.findDOMNode(this.refs['search-input']).value
			: null;
	},
	getItems: function () {
		var _this = this;
		var filter = this.props.filter;

		var searchString = this.getSearchString();

		var sort = null;

		if (filter.sortOptions) {
			if (this.state.sortValue) {
				sort = {
					attribute: this.props.filter.sortOptions.trueValue.attribute,
					ascending: this.props.filter.sortOptions.trueValue.ascending
				};
			} else {
				sort = {
					attribute: this.props.filter.sortOptions.falseValue.attribute,
					ascending: this.props.filter.sortOptions.falseValue.ascending
				};
			}
		}

		this.searchFn(
			searchString,
			filter.includeFields,
			this.state.offset,
			this.props.limit,
			sort,
			this.props.activeFilters
		)
			.then(function (res) {
				var data;
				var currentSearchString = _this.getSearchString();

				if (_this.state.offset === 0 && res.data && res.data.length) {
					data = res.data;
				} else if (_this.state.offset === 0) {
					data = [];
				} else if (res.data && res.data.length) {
					data = _this.state.data.concat(res.data);
				} else {
					data = _this.state.data;
				}

				var valueKey = filter.key || _this.props.defaultKey;

				data = _.unique(data, function (item) {
					var value = item[valueKey];
					return typeof value === 'string' ? value.toLowerCase() : value;
				});

				if (searchString === currentSearchString) {
					_this.setState({
						pendingAJAXrequest: false,
						lastOffsetRequested: _this.state.offset,
						data: data,
						total: res.metadata ? res.metadata.total : data.length
					});
				}
			})
			.catch(e => {
				logError(e, 'Failed to fetch initial items');
				_this.setState({
					pendingAJAXrequest: false,
					AJAXError: true
				});
			});
	},
	onScroll: function () {
		var scrollListBody = ReactDOM.findDOMNode(this.refs['scrollListBody']);
		var maxScrollTop = scrollListBody.scrollHeight - scrollListBody.clientHeight;

		if (scrollListBody.scrollTop > maxScrollTop * 0.9) {
			var nextOffset = this.state.offset + this.props.limit;

			if (this.state.offset === this.state.lastOffsetRequested && this.state.total > nextOffset) {
				this.setState(
					{
						offset: nextOffset,
						pendingAJAXrequest: true
					},
					function () {
						this.getItems();
					}
				);
			}
		}
	},
	onSearch: function () {
		var searchString = ReactDOM.findDOMNode(this.refs['search-input']).value;

		this.setState(
			{
				pendingAJAXrequest: true,
				offset: 0,
				total: null,
				lastOffsetRequested: null,
				itemSearchString: searchString
			},
			function () {
				this.getItems();
			}
		);
	},
	isChecked: function (item) {
		var values = [];
		if (this.props.activeFilters[this.props.filterName]) {
			values = this.props.activeFilters[this.props.filterName].value;
		}

		var key = this.props.filter.key || this.props.defaultKey;

		var value = _.find(values, function (value) {
			if (typeof value === 'string') {
				return item[key].toLowerCase() === value.toLowerCase();
			}
			return item[key] === value;
		});

		return value !== undefined ? true : false;
	},
	displayRow: function (item) {
		if (this.props.filter.search) {
			return true;
		}
		if (this.state.itemSearchString === '') {
			return true;
		}
		if (item.name && item.name.toLowerCase().indexOf(this.state.itemSearchString.toLowerCase()) !== -1) {
			return true;
		}
		return false;
	},
	getRow: function (item, options) {
		var checked = this.isChecked(item);

		var filter = this.props.filter;
		var valueKey = filter.key || this.props.defaultKey;
		var value = item[valueKey];

		if (typeof value === 'string') {
			value = value.toLowerCase();
		}

		var key = options.keyPrefix ? options.keyPrefix : '';
		key += value;
		key += item.name;

		var onChange = options.onChange ? options.onChange : this.onChange;

		/** Text **/
		var displayText = filter.displayText || 'name';

		var text = typeof displayText === 'function' ? displayText(item) : item[displayText];

		/** Subtitle **/
		var displaySubtitle = filter.displaySubtitle;

		var subtitle = null;

		if (displaySubtitle) {
			subtitle = typeof displayText === 'function' ? displaySubtitle(item) : item[displaySubtitle];
		}

		if (item.isGroup) {
			return (
				<div className="list-row list-row-separator" key={item.title + '-separator'}>
					<div className="separator">
						<span className="title">{item.title}</span>
					</div>
				</div>
			);
		}

		return React.createElement(ReactTemplates.upFilters.components[this.props.rowComponent], {
			isGroup: item.isRole ? true : false,
			checked: checked,
			disabled: false,
			onChange: onChange,
			showButton: true,
			item: item,
			text: text,
			value: value,
			key: key,
			tooltip: filter.tooltip,
			subtitle: subtitle,
			onGoBack: this.onGoBack
		});
	},
	getRows: function (array, options) {
		var _this = this;

		return _.reduce(
			array,
			function (values, item) {
				if (options.alwaysShow || _this.displayRow(item)) {
					values.push(_this.getRow(item, options));
				}
				return values;
			},
			[]
		);
	},
	onGoBack: function () {
		this.props.onPageChange(null, true);
	},
	onSelectAll: function () {
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: 'Equals',
			inactive: true,
			value: [],
			type: 'list'
		};

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onClickSelectedItem: function (clickedValue, action) {
		this.onChange(clickedValue, action);
	},
	onChange: function (newValues, action) {
		var comparisonType = this.refs.isIsNot.getValue()
			? (this.props.filter.comparisonTypeToggle && this.props.filter.comparisonTypeToggle.trueValue) ||
			  'NotEquals'
			: (this.props.filter.comparisonTypeToggle && this.props.filter.comparisonTypeToggle.falseValue) || 'Equals';

		var oldFilter = this.props.activeFilters[this.props.filterName];
		var newFilter = {
			filterName: this.props.filterName,
			comparisonType: comparisonType,
			inactive: true,
			value: [],
			type: 'list'
		};

		if (oldFilter && oldFilter.value) {
			newFilter.value = oldFilter.value;
		}

		if (action === 'add') {
			newFilter.value = [...new Set(newFilter.value.concat(newValues))];
		} else if (action === 'replace') {
			newFilter.value = newValues;
		} else {
			newFilter.value = _.filter(newFilter.value, function (value) {
				return newValues.indexOf(value) === -1;
			});
		}

		newFilter.inactive = newFilter.value.length > 0 ? false : true;

		this.props.onChange(newFilter, {
			action: 'add'
		});
	},
	onSearchKey: function (event) {
		var value = event.target.value;
		this.onSearch(value);
	},
	onToggleComperator: function (value) {
		var newFilter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var newComparisionType = value
			? (this.props.filter.comparisonTypeToggle && this.props.filter.comparisonTypeToggle.trueValue) ||
			  'NotEquals'
			: (this.props.filter.comparisonTypeToggle && this.props.filter.comparisonTypeToggle.falseValue) || 'Equals';

		if (currentFilter && currentFilter.value && currentFilter.value.length) {
			newFilter = currentFilter;
			newFilter.comparisonType = newComparisionType;

			this.props.onChange(newFilter, {
				action: 'add'
			});
		}
	},
	onToggleSortComperatorTrue: function () {
		this.onToggleSortComperator(true);
	},
	onToggleSortComperatorFalse: function () {
		this.onToggleSortComperator(false);
	},
	onToggleSortComperator: function (value) {
		this.setState(
			{
				offset: 0,
				sortValue: value
			},
			function () {
				this.getItems();
			}
		);
	},
	/* RENDER */
	render: function () {
		var filter = this.props.filter;
		var currentFilter = this.props.activeFilters[this.props.filterName];
		var rows = [];

		if (this.state.data.length) {
			rows = this.getRows(this.state.data, {});

			rows = _.filter(rows, function (row) {
				return row.props && row.props.checked && row.props.checked === true ? false : true;
			});
		}
		if (rows.length === 0 && this.state.itemSearchString !== '' && !this.state.pendingAJAXrequest) {
			var noResultsString = Tools.$translate('default.noResults');

			rows.push(
				<div key={'no-results-row'} className={'list-row list-row-item'}>
					<span className={'list-text list-text-item'}>{noResultsString}</span>
				</div>
			);
		}
		if (
			rows.length === 0 &&
			this.state.itemSearchString === '' &&
			!this.state.pendingAJAXrequest &&
			this.props.filterName.indexOf('Lookup') > -1
		) {
			var typeTranslateKey = 'default.' + this.props.tableType + 's';
			var typeTranslation = Tools.$translate(typeTranslateKey);
			var type =
				typeTranslation === typeTranslateKey
					? Tools.$translate('default.objects')
					: typeTranslation.toLowerCase();

			rows.push(
				<div key={'no-results-row'} className={'list-row list-row-item'}>
					<span className={'list-text list-text-item'}>
						{Tools.$translate('filter.noValuesInField', { type: type })}
					</span>
				</div>
			);
		}
		if (this.state.selectedData && this.state.selectedData.length) {
			var notSelectedString = Tools.$translate('default.notSelected');

			rows.unshift(
				<div key={'not-selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{notSelectedString}</span>
				</div>
			);

			var options = {
				keyPrefix: 'selected',
				onChange: this.onClickSelectedItem,
				alwaysShow: true
			};

			rows = this.getRows(this.state.selectedData, options).concat(rows);
			var selectedString = Tools.$translate('default.selected');

			rows.unshift(
				<div key={'selected-label'} className={'list-row list-row-group'}>
					<span className={'list-text list-text-group'}>{selectedString}</span>
				</div>
			);
		}

		if (this.state.pendingAJAXrequest) {
			rows.push(
				<div key={'pending-request'} className={'list-row list-row-item'}>
					<i
						style={{ position: 'relative', left: '48%' }}
						className="fa fa-refresh fa-spin fa-lg fa-fw list-page-spinner"
					/>
				</div>
			);
		}

		var nrSelected = currentFilter && currentFilter.value ? currentFilter.value.length : 0;

		var checked = false;
		if (currentFilter && currentFilter.comparisonType === 'NotEquals') {
			checked = true;
		}

		var isIsNotString = Tools.$translate('filters.excludeSelected');
		var deselectAllString = ' ' + Tools.$translate('default.deselectAll');

		var defaultValue = this.props.dataCache.get(filter.filterName + 'searchstring')
			? this.props.dataCache.get(filter.filterName + 'searchstring')
			: '';
		var placeholder = filter.search
			? Tools.$translate('default.typeToSearch')
			: Tools.$translate('filters.doFilter');

		var sortStyle = { header: {}, button: {} };
		var sortToolbar = null;

		var hasSort = filter.sortOptions ? true : false;

		if (hasSort) {
			sortStyle.header = {
				height: '112px'
			};
			sortStyle.button = {
				borderRadius: '0',
				height: '35px',
				fontSize: '12px'
			};

			var trueValueClasses = 'btn up-btn btn-sm no-shadow right';
			var falseValueClaees = 'btn up-btn btn-sm no-shadow left';

			if (this.state.sortValue) {
				trueValueClasses += ' btn-bright-blue';
				falseValueClaees += ' btn-light-grey';
			} else {
				falseValueClaees += ' btn-bright-blue';
				trueValueClasses += ' btn-light-grey';
			}

			var trueValueTitle = Tools.$translate(filter.sortOptions.trueValue.title);
			var falseValueTitle = Tools.$translate(filter.sortOptions.falseValue.title);

			sortToolbar = (
				<div className={'toolbar sortToolbar'}>
					<div className={'btn-wrap'}>
						<button onClick={this.onToggleSortComperatorFalse} className={falseValueClaees}>
							{!this.state.sortValue && (
								<i style={{ marginRight: '5px' }} className="fa fa-sort-amount-desc" />
							)}
							{falseValueTitle}
						</button>
						<button onClick={this.onToggleSortComperatorTrue} className={trueValueClasses}>
							{this.state.sortValue && (
								<i style={{ marginRight: '5px' }} className="fa fa-sort-amount-desc" />
							)}
							{trueValueTitle}
						</button>
					</div>
				</div>
			);
		}

		return (
			<div className="list-filter-page" key={this.props.filterName}>
				<div className="list-page">
					<div className="header" style={sortStyle.header}>
						<button className="back-button" onClick={this.onGoBack}>
							<b className="fa fa-chevron-left" />
						</button>
						<div className="search-input-wrap">
							<input
								ref="search-input"
								className="search-input"
								type="text"
								placeholder={placeholder}
								defaultValue={defaultValue}
								onChange={this.onSearchKey}
								maxLength="100"
							/>
							<b className="fa fa-search" />
						</div>

						{this.state.pendingAJAXrequest ? (
							<i className="fa fa-refresh fa-spin fa-fw list-page-spinner" />
						) : null}

						{this.state.AJAXError ? (
							<Text color="red" align="center">
								{Tools.$translate('loadError.filter')}
							</Text>
						) : (
							<div className={'toolbar'}>
								<span style={{ marginLeft: '10px' }}>
									<ReactTemplates.upFilters.components.toggle
										checked={checked}
										onChange={this.onToggleComperator}
										ref={'isIsNot'}
									/>
								</span>
								<span style={{ paddingLeft: '5px' }}>{isIsNotString}</span>

								<button
									style={sortStyle.button}
									disabled={nrSelected === 0}
									className={
										'btn up-btn no-shadow btn-link btn-bright-blue pull-right toolbar-deselect-all-button'
									}
									onClick={this.onSelectAll}
								>
									<i className="fa fa-times" aria-hidden="true" /> {deselectAllString}
								</button>
							</div>
						)}

						{sortToolbar}
					</div>

					<div className="list-body" ref={'scrollListBody'} onScroll={this.onScroll}>
						{rows}
					</div>
				</div>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.listLazy = window.ReactCreateClass({
	toggleExpanded: function () {
		var props = {
			filterName: this.props.filterName,
			filter: this.props.filter,
			dataCache: this.props.dataCache
		};

		if (this.props.filter.rowComponent) {
			props.rowComponent = this.props.filter.rowComponent;
		}

		this.props.onPageChange(
			{
				component: ReactTemplates.upFilters.components.listPageLazy,
				getTitle: function () {
					return Tools.$translate(this.props.filter.title);
				}.bind(this),
				props: props
			},
			false
		);
	},
	onRemoveFilter: function (event) {
		this.props.onChange(
			{
				filterName: this.props.filterName
			},
			{
				action: 'remove'
			}
		);
		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var filter = this.props.filter;
		var valueString = '';

		if (filter.value && filter.value.length) {
			valueString = filter.value.length + ' ' + Tools.$translate('default.selected').toLowerCase();
		} else {
			valueString = Tools.$translate('default.all');
		}
		return valueString;
	},
	render: function () {
		var filter = this.props.filter;
		var active = filter.value && filter.value.length > 0;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true" />
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>
			</div>
		);
	}
});

'use strict';

angular.module('domain.mail').controller('scheduleMail', [
	'$scope',
	'$modalParams',
	'NotificationService',
	function ($scope, $modalParams, NotificationService) {
		// adds 30 minutes to current date and returns the closest half hour in the future
		var b = Date.now() + 18e5,
			c = b % 18e5;

		$scope.date = $modalParams.date || new Date(9e5 >= c ? b - c : b + 18e5 - c);

		$scope.submitForm = function () {
			// if set date is not later than right now --> don not resolve..
			if (+$scope.date > +new Date()) {
				$scope.resolve($scope.date);
			} else {
				NotificationService.addNotification({
					title: 'default.error',
					body: 'mail.invalidDate',
					style: NotificationService.style.ERROR,
					icon: 'times'
				});
			}
		};
	}
]);

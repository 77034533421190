import React from 'react';
import { AnyAction } from 'redux';

export const RESET = '[ADMIN] RESET';
export const SET_SIDEBAR_SEARCH = '[ADMIN] SET_SIDEBAR_SEARCH';

export type AdminState = {
	sidebarSearch: string;
};

export type Dispatch = (action: any) => void;

export const initialState: AdminState = {
	sidebarSearch: ''
};

const ACTION_HANDLERS: { [key: string]: (s: AdminState, a: AnyAction) => AdminState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_SIDEBAR_SEARCH]: (state, { sidebarSearch }) => ({ ...state, sidebarSearch })
};

export const reducer = (state: AdminState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

const getActions = (dispatch: Dispatch) => {
	return {
		reset: () => dispatch({ type: RESET }),
		setSidebarSearch: (sidebarSearch: string) => dispatch({ type: SET_SIDEBAR_SEARCH, sidebarSearch })
	};
};

const AdminContext = React.createContext<(AdminState & ReturnType<typeof getActions>) | undefined>(undefined);

export function AdminProvider({ children }: { children: React.ReactNode }) {
	const [state, dispatch] = React.useReducer(reducer, { ...initialState });

	const actions = getActions(dispatch);
	const value = { ...state, ...actions };

	return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
}

export function useAdminContext() {
	const context = React.useContext(AdminContext);
	if (typeof context === 'undefined') {
		throw new Error('useAdminContext must be used within a AdminContext Provider');
	}
	return context;
}

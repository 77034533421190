'use strict';

angular.module('upHelpers').factory('FilterType', function () {
	return {
		Boolean: 'Boolean',
		String: 'String',
		Number: 'Number',
		Float: 'Float',
		Email: 'Email',
		Date: 'Date',
		Object: 'Object'
	};
});

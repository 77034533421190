import {
	prepDates,
	prepMultiSelect,
	getAvailableFields,
	formatDateType,
	attachScopeFunctions,
	executeSave
} from 'App/babel/helpers/multiModals';

angular.module('domain.account').controller('UpdateClientMulti', [
	'$scope',
	'$modalParams',
	'$translate',
	'$multiSelect',
	'AppService',
	function ($scope, $modalParams, $translate, $multiSelect, AppService) {
		var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isTrigger = $modalParams.isTrigger;

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.save = function () {
			var properties = [];
			// If userRole is set to noAccountManager with id -1, set value to [] to clear
			if ($scope.userRole === -1) {
				properties.push({ name: 'User', value: [] });
			} else if ($scope.userRole) {
				var action = $scope.accountManagerAction !== 'Set' ? $scope.accountManagerAction : '';
				action += $scope.userRole.indexOf('r_') !== -1 ? 'Role' : 'User';
				properties.push({ name: action, value: $scope.userRole.substr(2) });
			}

			executeSave({
				scope: $scope,
				modalParams: $modalParams,
				multiSelect: $multiSelect,
				entityType: 'Client',
				method: 'updateAccount',
				arrayProperties,
				startingProperties: properties,
				entityArg: $modalParams.entity || 'Client'
			});
		};

		attachScopeFunctions($scope, ['addField', 'removeField', 'createCampaign', 'changeCustomDate']);

		var init = function () {
			const meta = $modalParams.meta;
			$modalParams.entity = $modalParams.entity || 'Client';

			const startingFields = meta.accountCustomFields.data;
			const { customDateTypes } = prepDates($modalParams.entity);

			$scope.customFields = [];
			$scope.metadata = AppService.getMetadata();

			$scope.showJourneyStep = true;
			$scope.hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
			if ($scope.hasJourney) {
				$scope.journeySteps = Tools.AppService.getJourneySteps();
			}

			$scope.hasPriceLists =
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
				Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS);
			if ($scope.hasPriceLists) {
				$scope.priceLists = Tools.AppService.getPriceLists().filter(priceList => priceList.active);
			}

			$scope.accountManagerAction = $scope.metadata.params.teamAccountManager ? 'Add' : 'Set';

			$scope.customDateTypes = formatDateType(customDateTypes);

			$scope.availableFields = getAvailableFields($scope, startingFields, $scope.customDateTypes);
			$scope.customFieldLength = $scope.availableFields.length;

			// Properties
			$scope.properties = {};

			$scope.campaigns = meta.campaigns.data;

			$scope.usersAndRoles = [];
			// Add a non user as -> "Ingen kontoansvarig"
			$scope.usersAndRoles.push({
				$$type: null,
				$$id: -1,
				$$text: $translate.instant('default.noAccountManager')
			});

			angular.forEach(meta.activeUsers.data, function (user) {
				user.$$type = 'User';
				user.$$id = 'u_' + user.id;
				user.$$text = user.name;
				$scope.usersAndRoles.push(user);
			});

			$scope.userRoleSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.user').toLowerCase()
			};

			$multiSelect = prepMultiSelect($multiSelect, $modalParams.multiSelect);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Text, ModalHeader, ModalContent, ModalControls, Button, Title, Link, Card } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';

export default class AppList extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			title: translate('chooseMailIntegration.title'),
			subTitle: translate('chooseMailIntegration.subTitle'),
			abort: translate('default.abort'),
			learnMore: translate('default.learnMore'),
			connect: translate('default.connect')
		};
	}

	render() {
		const { integrations, loading, selectIntegration, reject } = this.props;
		const classes = new Bem('ChooseMailIntegration');

		let appList;

		if (loading) {
			appList = [1, 2, 3].map(key => {
				return <Card key={`placeholder-${key}`} className={classes.elem('integration-placeholder').b()} />;
			});
		} else {
			appList = integrations.map(integration => {
				return (
					<Card
						key={integration.id}
						className={classes.elem('integration').b()}
						onClick={() => selectIntegration(integration)}
					>
						<div className={classes.elem('integration-logo').b()}>
							{integration.imageLink ? <img src={integration.imageLink} /> : null}
						</div>
						<Title size="sm" center={true}>{`${this.lang.connect} ${integration.name}`}</Title>
					</Card>
				);
			});
		}

		return (
			<div className={classes.b()}>
				<ModalHeader className="ModalHeader--no-border-bottom" onClose={reject} />
				<ModalContent>
					<Title size="lg" bold={true} center={true}>
						{this.lang.title}
					</Title>
					<Text size="lg" style={{ margin: '20px 30px 25px 30px' }}>
						{this.lang.subTitle}
						<Link>
							<ReactTemplates.elevio
								sidebar={true}
								text={this.lang.learnMore}
								articleId={1159}
								style={{ borderBottom: 'none' }}
							/>
						</Link>
					</Text>
					<div className={classes.elem('integration-list').b()}>{appList}</div>
				</ModalContent>
				<ModalControls>
					<Button color="grey" type="link" onClick={reject}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

AppList.propTypes = {
	reject: PropTypes.func.isRequired,
	integrations: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	selectIntegration: PropTypes.func.isRequired
};

'use strict';

ReactTemplates.mailCampaign.clientsrow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			invalidEmail: t('mailCampaign.errors.invalidEmail'),
			bounce: t('mailCampaign.errors.bounce'),
			duplicates: t('mailCampaign.errors.duplicates'),
			noDataYet: t('default.noData'),
			checkLaterIfData: t('mailCampaign.errors.noData'),
			showAll: t('default.showAll')
		};
	},
	render: function () {
		var self = this;
		var standardProps = ['invalidEmail', 'bounce', 'duplicates'];

		var data = _.map(this.props.data, function (item, key) {
			if (standardProps.indexOf(key) > -1) {
				return (
					<div key={key} className="large-number">
						{item}
						<span className="sub-lang">{self.lang[key]}</span>
					</div>
				);
			}
		});

		if (data) {
			return (
				<div style={{ height: '100%' }}>
					<div className="column-rows align-middle">{data}</div>
					<a
						className="show-all"
						href={self.props.getStateHref('mailCampaign.recipients', { filter: 'showErrors' })}
					>
						{this.lang.showAll}
					</a>
				</div>
			);
		}

		return (
			<div className="no-data">
				{this.props.noDataImg ? (
					<img className="no-data-img" height="50" src={'/img/' + this.props.noDataImg} />
				) : null}
				<h4 className="no-data-msg">{this.props.noDataMsg}</h4>
			</div>
		);
	}
});

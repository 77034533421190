'use strict';

ReactTemplates.findProspect.sidebar = window.ReactCreateClass({
	/** Custom functions **/
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	lang: {},
	/** React functions **/
	getInitialState: function () {
		return {
			openFilter: 'branch',
			showStartPlaceholder: false
		};
	},
	toggleOpen: function (view) {
		this.setState({
			openFilter: view
		});
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		//this.infoChange = _.debounce(this.infoChange, 400);

		this.lang.corporateForm = t('soliditet.corporateForm');
		this.lang.clientInfo = t('soliditet.clientInfo');
		this.lang.companyBranch = t('default.companyBranch');
		this.lang.location = t('advancedSearch.location');
		this.lang.popoverCompanyInfo = t('soliditet.popoverCompanyInfo');
		this.lang.popoverSni = t('soliditet.popoverSni');
		this.lang.improveClientData = t('soliditet.improveClientData');
		this.lang.washWarningBody = t('soliditet.washWarningBody');
		this.lang.washWarningTitle = t('soliditet.washWarningTitle');
	},
	componentDidMount: function () {
		var sidebar = this.refs.sidebar;

		var self = this;
		var rootData = self.props.rootData;

		this.props.rootData.sniCodes.then(function () {
			self.setState({
				showStartPlaceholder: rootData.selectedView && rootData.selectedView.id === 1 && !rootData.viewIsDirty
			});
		});

		sidebar.addEventListener('click', this.destroyTooltip);
	},
	handleClickOutside: function () {
		// On click outside filter
		if (this.state.showStartPlaceholder) {
			this.destroyTooltip();
		}
	},
	destroyTooltip: function () {
		// On click on filter

		this.setState({
			showStartPlaceholder: false
		});

		var sidebar = this.refs.sidebar;
		sidebar.removeEventListener('click', this.destroyTooltip);
	},
	infoChange: function (filterType, values) {
		this.props.rootData.filterChange(filterType, { value: values, comparisonType: 'Equals' });
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;

		// Need to check if not should show?!
		var showStartPlaceholder = this.state.showStartPlaceholder;

		var formatCountryRow = function (item) {
			if (!item) {
				return null;
			}

			var name = tools.$translate(item.name);

			return (
				<span>
					<i style={{ marginRight: '5px' }} className={'flag-icon flag-icon-' + item.value.toLowerCase()}></i>
					{name}
				</span>
			);
		};

		var countryFilter = React.createElement(ReactTemplates.findProspect.singleSelect, {
			key: 'country',
			keyName: 'country',
			title: 'address.country',
			tools: tools,
			options: rootData.countries,
			value: rootData.filters.Country ? rootData.filters.Country.value : null,
			formatOption: formatCountryRow,
			showTooltip: false,
			toggleOpen: this.toggleOpen,
			formatHeader: formatCountryRow,
			onChange: function (countryCode) {
				if (!rootData.filters.Country || rootData.filters.Country.value !== countryCode) {
					rootData.filterChange('Country', {
						value: countryCode,
						comparisonType: 'Equals'
					});
				}
			}
		});

		var companyTypeFilter = React.createElement(ReactTemplates.findProspect.singleSelect, {
			key: 'companyType',
			keyName: 'companyType',
			title: 'soliditet.corporateForm',
			tools: tools,
			showTooltip: rootData.smallSize,
			toggleOpen: this.toggleOpen,
			options: rootData.corporateForm,
			icon: 'fa fa-home',
			value: rootData.filters.CompanyType ? rootData.filters.CompanyType.value : null,
			onChange: function (companyType) {
				if (!rootData.filters.CompanyType || rootData.filters.CompanyType.value !== companyType) {
					rootData.filterChange('CompanyType', {
						value: companyType,
						comparisonType: 'Equals'
					});
				}
			}
		});

		var branchFilter = React.createElement(ReactTemplates.findProspect.companyBranchFilter, {
			key: 'branchType',
			tools: tools,
			sniCodes: rootData.sniCodes,
			sniCodesWithSales: rootData.sniCodesWithSales,
			productTree: rootData.productTree,
			productSearch: rootData.productSearch,
			useProductSearch: rootData.useProductSearch,
			roleTree: rootData.roleTree,
			roleParentTree: rootData.roleParentTree,
			datePresets: rootData.datePresets,
			filters: rootData.filters,
			sniFilters: rootData.sniFilters,
			onSniFilterChange: rootData.sniFilterChange,
			open: this.state.openFilter === 'branch',
			toggleOpen: this.toggleOpen,
			hasBranchWithSalesRights: rootData.hasBranchWithSalesRights,
			accountManager: rootData.accountManager,
			onChange: function (values, action) {
				var newFilter;

				if (rootData.filters.SniCode && Array.isArray(rootData.filters.SniCode.value)) {
					newFilter = {
						value: _.cloneDeep(rootData.filters.SniCode.value),
						comparisonType: 'Equals'
					};
				} else {
					newFilter = {
						value: [],
						comparisonType: 'Equals'
					};
				}

				switch (action) {
					case 'add':
						newFilter.value = _.union(newFilter.value, values);

						break;
					case 'remove':
						newFilter.value = _.difference(newFilter.value, values);

						break;
					case 'replace':
						newFilter.value = values;

						break;
				}

				rootData.filterChange('SniCode', newFilter);
			},
			showStartPlaceholder: showStartPlaceholder,
			washWarning: this.props.washWarning
		});

		var infoFilter = React.createElement(ReactTemplates.findProspect.companyInfoFilter, {
			key: 'info',
			tools: tools,
			filters: rootData.filters,
			ranges: rootData.employeeAndTurnoverRanges,
			employeeRange: rootData.employeesRanges,
			turnoverRange: rootData.turnoverRanges,
			companyForms: rootData.corporateForm,
			singleSelectEmployees: rootData.singleSelectEmployees,
			countries: rootData.countries,
			onChange: self.infoChange,
			getFilterData: rootData.getFilterData,
			open: this.state.openFilter === 'companyInfo',
			toggleOpen: this.toggleOpen,
			washWarning: this.props.washWarning
		});

		var locationFilter = React.createElement(ReactTemplates.findProspect.companyLocationFilter, {
			key: 'location',
			tools: tools,
			stateAndCity: rootData.stateAndCity,
			filters: rootData.filters,
			open: this.state.openFilter === 'companyLocation',
			toggleOpen: this.toggleOpen,
			//zipCodeLookupFn: rootData.zipCodeLookupFn,
			onChange: function (newVal, action, cities, state, isZipCode) {
				// clear all from this filter
				if (action === 'clearAll') {
					return rootData.filterChange('clearZipCodeAndState');
				}

				// IF IS ZIPCODE
				if (isZipCode) {
					var hasZipCodeFilter = rootData.filters.ZipCode && Array.isArray(rootData.filters.ZipCode.value);
					var zipCodeFilter = hasZipCodeFilter
						? { value: _.cloneDeep(rootData.filters.ZipCode.value) }
						: { value: [] };
					zipCodeFilter.value =
						action === 'add'
							? _.union(zipCodeFilter.value, newVal)
							: _.difference(zipCodeFilter.value, newVal);
					return rootData.filterChange('ZipCode', zipCodeFilter);
				}

				var filterKey = !state ? 'State' : 'City';

				var stateAndCityFilter;

				if (rootData.filters.CityAndState) {
					stateAndCityFilter = rootData.filters.CityAndState
						? { value: _.cloneDeep(rootData.filters.CityAndState.value) }
						: { value: [] };
				} else {
					stateAndCityFilter = { value: [] };
				}

				switch (action) {
					case 'add':
						if (filterKey === 'City') {
							// OM alla städer är ifyllda .. ELLER OM man har state och notEquals och det bara är den som man ska ta bort

							var allCitiesInState = _.filter(stateAndCityFilter.value, function (ff) {
								return ff.state === state;
							});

							var hasNe = _.filter(stateAndCityFilter.value, function (ne) {
								return ne.state === state && ne.notEquals;
							});

							// IF CITY AND ALL cities is checked ---> add state and remove all citites from that STATE!!

							var hasAllCities =
								allCitiesInState.length + 1 === cities.length ||
								(hasNe.length && hasNe[0].city === newVal);

							if (hasAllCities) {
								stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (filter) {
									return filter.state !== state;
								});

								stateAndCityFilter.value.push({
									state: state
								});
							} else {
								var hasNotEqualsFilter = _.findIndex(stateAndCityFilter.value, function (ff2) {
									return ff2.state === state && ff2.city === newVal && ff2.notEquals;
								});

								if (hasNotEqualsFilter === -1) {
									stateAndCityFilter.value.push({
										state: state,
										city: newVal
									});
								} else {
									stateAndCityFilter.value.splice(hasNotEqualsFilter, 1);
								}
							}
						} else {
							// if any cities in that state is checked ... delete them..
							stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (c2) {
								return newVal !== c2.state;
							});

							stateAndCityFilter.value.push({
								state: newVal
							});
						}
						break;
					case 'remove':
						if (filterKey === 'City') {
							var stateIsChecked = _.findIndex(stateAndCityFilter.value, function (c3) {
								return c3.state === state && !c3.city;
							});
							// IF REMOVE A CITY AND STATE IS CHECKED --> REMOVE STATE AND CHECK ALL CITIES EXPECT THE ONE THAT
							if (stateIsChecked !== -1) {
								stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (c4) {
									return c4.state !== state;
								});

								// // Add the city that shall be removed.. with notEquals
								stateAndCityFilter.value.push({
									state: state,
									city: newVal,
									notEquals: true
								});
							} else {
								// Else just remove the city

								// if finding a ne filter on the state ---> add a new one : else jsut remove the added
								var hasNEFilter = _.filter(stateAndCityFilter.value, function (ne) {
									return ne.state === state && ne.notEquals;
								});
								if (hasNEFilter.length + 1 === cities.length) {
									stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (c5) {
										return c5.state !== state;
									});
								} else if (hasNEFilter.length) {
									stateAndCityFilter.value.push({
										state: state,
										city: newVal,
										notEquals: true
									});
								} else {
									stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (c5) {
										return c5.state !== state || c5.city !== newVal;
									});
								}
							}
						} else {
							// Remove the state and everything on that state
							stateAndCityFilter.value = _.filter(stateAndCityFilter.value, function (c6) {
								return c6.state !== newVal;
							});
						}
						break;
					case 'replace':
						// NOT USED RIGHT NOW
						if (filterKey === 'State') {
							stateAndCityFilter.value = [{ state: newVal }];
						} else {
							stateAndCityFilter.value = [{ city: newVal, state: state }];
						}
						break;
				}
				rootData.filterChange('CityAndState', stateAndCityFilter);
			},
			showStartPlaceholder: showStartPlaceholder,
			toggleGeoSort: rootData.toggleGeoSort,
			geoSort: rootData.geoSort
			//geoSortType: rootData.geoSortType
		});

		var classNames = this.props.className || '';

		var washWarning = null;
		if (this.props.washWarning) {
			washWarning = (
				<div id="find-prospects-sidebar-wash-warning">
					<div className="warning-img"></div>
					<h2>{this.lang.washWarningTitle}</h2>
					<p>{this.lang.washWarningBody}</p>
					<a href={Tools.$state.href('matcher', { customerId: Tools.AppService.getCustomerId() })}>
						{this.lang.improveClientData}
					</a>
				</div>
			);
			classNames += ' inner-scroll';
		}

		return (
			<div ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick targetRef={this.getRef} outsideClick={this.handleClickOutside}>
					<div id="find-prospects-sidebar" ref="sidebar" className={classNames}>
						{countryFilter}
						{companyTypeFilter}
						{branchFilter}
						{infoFilter}
						{locationFilter}
						{washWarning}
					</div>
				</OutsideClick>
			</div>
		);
	}
});

ReactTemplates.findProspect.selectGroupRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			onChange: function () {}
		};
	},
	onClick: function (event) {
		event.preventDefault();
		event.nativeEvent.stopImmediatePropagation();
		event.stopPropagation();

		var action = !this.props.selected ? 'add' : 'remove';

		this.props.onChange(this.props.item, action);
	},
	render: function () {
		var marginLeft = 14 * this.props.depth;

		var style = this.props.style || {};

		return (
			<div className="menu-row overflow-ellipsis" onClick={this.onClick}>
				<input
					readOnly
					checked={this.props.selected}
					style={{ marginLeft: marginLeft }}
					className="input"
					type="checkbox"
				/>
				<span style={style}>{this.props.item.name}</span>
			</div>
		);
	}
});

ReactTemplates.findProspect.select = window.ReactCreateClass({
	handleClickOutside: function () {
		this.setState({
			open: false
		});
	},
	getDefaultProps: function () {
		return {
			open: false,
			placeholder: 'placeholder',
			style: {},
			onChange: function () {}
		};
	},
	getInitialState: function () {
		return {
			open: this.props.open,
			searchString: ''
		};
	},
	lang: {},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	UNSAFE_componentWillMount: function () {
		this.remoteSearch = _.debounce(this.remoteSearch, 250);

		var t = Tools.$translate;

		this.lang.search = t('default.search');
	},
	toggleMenu: function () {
		this.setState({
			open: !this.state.open
		});
	},
	getRow: function (item, selected, onChange, depth, style) {
		return React.createElement(ReactTemplates.findProspect.selectGroupRow, {
			item: item,
			depth: depth,
			onChange: onChange,
			selected: selected,
			key: item.id + item.name,
			style: style
		});
	},
	getRows: function (depth, array, countObj) {
		var self = this;

		var _rows = [];
		var searchValue = self.state.searchString.toLowerCase();

		_.each(array, function (obj) {
			var onChange, isSelected, style;

			if (self.props.types) {
				var config = self.props.types[obj.type];
				onChange = config.onChange;
				isSelected = config.isSelected;
				style = config.style;
			} else {
				onChange = self.props.onChange;
				isSelected = self.props.isSelected;
			}

			var itemSelected = isSelected(obj);
			var lowerCaseName = obj.name.toLowerCase();

			if (itemSelected) {
				++countObj.value;
			}

			if (obj.children) {
				var childRows = self.getRows(depth + 1, obj.children, countObj);

				if (!searchValue || lowerCaseName.indexOf(searchValue) !== -1 || childRows.length) {
					_rows.push(self.getRow(obj, itemSelected, onChange, depth, style));
					_rows = _rows.concat(childRows);
				}
			} else {
				if (!searchValue || lowerCaseName.indexOf(searchValue) !== -1) {
					_rows.push(self.getRow(obj, itemSelected, onChange, depth, style));
				}
			}
		});

		return _rows;
	},
	onSearchKey: function (e) {
		if (this.props.search) {
			this.remoteSearch(e.target.value);
		} else {
			this.setState({
				searchString: e.target.value
			});
		}
	},
	remoteSearch: function (searchString) {
		this.props.search(searchString);
	},
	onClearFilter: function () {
		this.props.onClear();
	},
	render: function () {
		var menu = null;
		var counterObj = {
			value: 0
		};

		if (this.state.open) {
			var rows = null;

			if (this.props.data) {
				rows = this.getRows(0, this.props.data, counterObj);
			}

			menu = (
				<div className="menu">
					<div
						style={{ width: '94%', padding: '5px 15px', marginBottom: '5px' }}
						className="search-wrap"
						key="qsearch"
					>
						<b className="fa fa-search"></b>
						<input
							className="search-input"
							type="text"
							placeholder={this.lang.search}
							defaultValue={this.state.searchString}
							onChange={this.onSearchKey}
						/>
					</div>
					<div className="menu-trash" onClick={this.onClearFilter}>
						<b className="fa fa-trash"></b>
					</div>

					{rows}
				</div>
			);
		}

		var buttonClasses = 'select-button btn btn-link';
		var icon = <i style={{ marginLeft: '5px', position: 'relative', top: '-2px' }} className="fa fa-sort-desc"></i>;

		if (this.state.open) {
			buttonClasses += ' open';
			icon = <i style={{ marginLeft: '5px', position: 'relative', top: '2px' }} className="fa fa-sort-asc"></i>;
		}

		var buttonText = this.props.buttonText(counterObj.value);

		return (
			<div ref={this.setRef.bind(this, 'ref')} style={this.props.style} className="select">
				<OutsideClick targetRef={this.getRef} outsideClick={this.handleClickOutside}>
					<button onClick={this.toggleMenu} className={buttonClasses}>
						{buttonText}
						{icon}
					</button>
					{menu}
				</OutsideClick>
			</div>
		);
	}
});

ReactTemplates.findProspect.singleSelect = window.ReactCreateClass({
	/** Custom functions **/
	toggleMenu: function () {
		this.setState({
			open: !this.state.open
		});
	},
	onChange: function (value, e) {
		e.stopPropagation();

		this.setState({
			open: false
		});
		this.props.onChange(value);
	},
	getInitialState: function () {
		return {
			open: this.props.open
		};
	},
	/** mixins **/
	handleClickOutside: function () {
		this.setState({
			open: false
		});
	},
	/** React functions **/
	getDefaultProps: function () {
		return {
			open: false,
			title: '',
			value: null,
			options: [],
			showTooltip: false,
			onChange: function () {}
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			title: t(this.props.title)
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	render: function () {
		var self = this;

		var tools = self.props.tools;

		var options = self.props.options.map(function (item, i) {
			var content;

			if (self.props.formatOption) {
				content = self.props.formatOption(item);
			} else {
				var name = tools.$translate(item.name);

				content = <span>{name}</span>;
			}

			return (
				<div
					key={i}
					className="single-select-option overflow-ellipsis"
					onClick={self.onChange.bind(self, item.value)}
				>
					{self.props.showTooltip
						? ReactTemplates.TOOLS.withTooltip(content, name, { placement: 'bottom', delayShow: 300 })
						: content}
				</div>
			);
		});

		var menu = null;

		if (self.state.open) {
			menu = <div className="single-select-menu">{options}</div>;
		}

		var value = null;

		if (self.props.value) {
			var item = _.find(self.props.options, { value: self.props.value });
			var content;

			if (self.props.formatHeader) {
				content = self.props.formatHeader(item);
			} else {
				var name = tools.$translate(item.name);

				content = <span>{name}</span>;
			}

			value = <span className="row-header-value overflow-ellipsis">{content}</span>;
		}

		var rowHeaderClasses = self.props.open ? 'row-header open' : 'row-header';

		return (
			<div ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick targetRef={this.getRef} outsideClick={this.handleClickOutside}>
					<div className={rowHeaderClasses} onClick={self.toggleMenu}>
						{this.props.icon ? (
							<span className="row-header-icon-wrap">
								<i className={this.props.icon + ' row-header-icon'}></i>
							</span>
						) : null}
						<span className="row-header-title">{self.lang.title}</span>
						{value}
						{menu}
					</div>
				</OutsideClick>
			</div>
		);
	}
});

ReactTemplates.findProspect.salesIcon = function (_style) {
	var style = _.assign({}, { fontSize: '8px', marginBottom: '1px', marginRight: '5px' }, _style);

	return (
		<span style={style} className="fa-stack fa-lg">
			<i style={{ color: 'rgb(126, 211, 33)' }} className="fa fa-circle fa-stack-2x"></i>
			<i style={{ color: '#fff' }} className="fa fa-usd fa-stack-1x fa-inverse"></i>
		</span>
	);
};

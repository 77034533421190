import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, Block } from '@upsales/components';
import CriteriaDrilldownWrapper from '../../../CriteriaDrilldownWrapper';
import CriteriaRowWrapper from '../../../CriteriaRowWrapper';
import TextInput from '../../Text';
import Bem from '@upsales/components/Utils/bemClass';
import { cloneDeep } from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import List from '../../List';
import { getSearchFunctions, initSelection } from 'App/babel/filterConfigs/Prospecting/Location';

export default class AddressDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const t = getAngularModule('$translate');

		this.lang = {
			choose: t('default.choose'),
			town: t('address.town'),
			street: t('address.streetAddress'),
			townPlaceholder: t('default.choose') + ' ' + t('address.town').toLowerCase(),
			streetPlaceholder: t('prospecting.filters.enterAStreetName'),
			zipCode: t('address.zip'),
			closed: t('default.closed'),
			open: t('default.open')
		};

		this.onTownChange = this.onListChange.bind(this, 'town');
		this.onAddressChange = this.onListChange.bind(this, 'address');

		const searchFunctions = getSearchFunctions(props.filter.filterName);
		this.townSerchFn = searchFunctions.townSerchFn;
	}

	onListChange = (key, _, value) => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.value[key] = value;
		onChange(newFilter.filterName, newFilter);
	};

	addPostalCode = () => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		const maxKey = newFilter.value.zipcode.value.reduce((max, value) => (value.key > max ? value.key : max), 0);
		newFilter.value.zipcode.value.push({ start: null, end: null, key: maxKey + 1 });
		onChange(newFilter.filterName, newFilter);
	};

	removePostalCode = key => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		const index = newFilter.value.zipcode.value.findIndex(value => value.key === key);
		newFilter.value.zipcode.value.splice(index, 1);
		onChange(newFilter.filterName, newFilter);
	};

	onZipCodeChanged = (key, field, event) => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		const index = newFilter.value.zipcode.value.findIndex(value => value.key === key);
		newFilter.value.zipcode.value[index][field] = event.target.value;
		newFilter.value.zipcode.value[index].key = key;
		onChange(newFilter.filterName, newFilter);
	};

	render() {
		const { filter, open, selectData } = this.props;

		const townSelect = {
			config: {
				filterName: 'Town',
				type: 'list',
				searchFn: this.townSerchFn,
				resource: initSelection,
				placeholder: this.lang.townPlaceholder
			},
			filter: filter.value.town,
			onChange: this.onTownChange,
			selectData: { ...selectData[filter.filterName] }
		};

		const streetSelect = {
			config: {
				filterName: 'Street',
				type: 'list',
				displayType: 'text',
				placeholder: this.lang.streetPlaceholder
			},
			filter: filter.value.address,
			onChange: this.onAddressChange,
			selectData: {},
			autoFocus: false
		};

		const classes = new Bem('ProspectingDrilldown');
		const flexInputClasses = new Bem('flex-input-group');

		const zipCodeRows = filter.value.zipcode.value.map((value, index, array) => {
			const lastChild = index === array.length - 1;
			let button = null;

			if (lastChild) {
				button = (
					<Button type="link" onClick={this.addPostalCode}>
						<Icon name="plus" color="bright-blue" />
					</Button>
				);
			} else {
				button = (
					<Button type="link" onClick={this.removePostalCode.bind(this, value.key)}>
						<Icon name="trash" color="grey-10" />
					</Button>
				);
			}

			return (
				<tr key={value.key}>
					<td className="title-col">
						{index === 0 ? <div className="drilldown-row">{this.lang.zipCode}</div> : null}
					</td>
					<td className="table-col">
						<div className="flex-row">
							<div className="drilldown-row">
								<CriteriaRowWrapper>
									<div
										className={flexInputClasses
											.mod({ invalid: value.end && value.start && value.end < value.start })
											.b()}
									>
										<Block>
											<input
												className="form-control"
												placeholder={Tools.$translate('default.from')}
												onChange={event => this.onZipCodeChanged(value.key, 'start', event)}
												value={value.start || ''}
												maxLength="10"
											/>
										</Block>
										<Block>
											<input
												className="form-control"
												placeholder={Tools.$translate('default.to')}
												onChange={event => this.onZipCodeChanged(value.key, 'end', event)}
												value={value.end || ''}
												maxLength="10"
											/>
										</Block>
									</div>
								</CriteriaRowWrapper>
								{button}
							</div>
						</div>
					</td>
					<td className="remove-filter-col" />
				</tr>
			);
		});

		return (
			<CriteriaDrilldownWrapper
				key={filter.filterName + '-drilldown'}
				className={classes.mod('addresses').b()}
				open={open}
			>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/* Town row */}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.town}</div>
									</td>
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<List {...townSelect} />
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/* State rows */}
								{zipCodeRows}

								{/* Street row */}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.street}</div>
									</td>
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<TextInput {...streetSelect} />
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

AddressDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	selectData: PropTypes.object.isRequired
};

import SetupComponent from 'App/babel/SetupRootComponent';
import { lazy } from 'react';
import LocalStorage from 'Components/Helpers/LocalStorage';
import AssignModalLead from 'Components/AssignModal/AssignModalLead';
import BillingRoot from 'Components/Billing/BillingRoot';
import ChecklistItem from 'Components/Admin/SalesProcess/SalesCoach/Modals/ChecklistItem';
import DecisionMakers from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/DecisionMakers';
import DecisionMakersPro from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/DecisionMakersPro';
import EditEventDomain from 'Components/Admin/EditEventDomain';
import EditProduct from 'Components/Modals/EditProduct';
import NextStep from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/NextStep';
import SalesCoachModal from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal';
import Solution from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/Solution';
import VerifyBudget from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/VerifyBudget';
import VerifyTimeframe from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/VerifyTimeframe';
import PreviewPdf from 'Components/Admin/DocumentTemplate/PreviewPdf';
import TemplateEditor from 'Components/Admin/DocumentTemplate/TemplateEditor';
import ToggleFiscalYearModal from 'Components/Admin/ToggleFiscalYearModal';
import MailSignatureRoot from 'Components/MailSignature/MailSignatureRoot';

const AdminProductList = lazy(() => import('Components/Admin/AdminProductList'));
const BookedAppointmentGoals = lazy(() => import('Components/Admin/Goals/BookedAppointmentGoals'));
const BookedFirstAppointmentGoals = lazy(() => import('Components/Admin/Goals/BookedFirstAppointmentGoals'));
const CompletedAppointmentGoals = lazy(() => import('Components/Admin/Goals/CompletedAppointmentGoals'));
const CompletedFirstAppointmentGoals = lazy(() => import('Components/Admin/Goals/CompletedFirstAppointmentGoals'));
const EasyBookingSettings = lazy(() => import('Components/Admin/EasyBookingSettingsV2/Settings'));
const EasyBookingSettingsEditorV2 = lazy(() =>
	import('Components/Admin/EasyBookingSettingsV2/Editor/EasyBookingSettingsPage')
);
const JourneyStatuses = lazy(() => import('Components/Admin/JourneyStatuses/JourneyStatuses'));
const LoginStats = lazy(() => import('../../../babel/components/Admin/LoginStats'));
const PhonecallGoals = lazy(() => import('Components/Admin/Goals/PhonecallGoals'));
const PipelineGoals = lazy(() => import('Components/Admin/Goals/PipelineGoals'));
const PriceLists = lazy(() => import('Components/Admin/PriceLists'));
const ProspectingSignalsSettings = lazy(() => import('Components/Admin/ProspectingSignalsSettings'));
const Reminders = lazy(() => import('Components/Admin/Reminders'));
const Salesboard = lazy(() => import('../../../babel/components/Admin/Salesboard'));
const SalesProcessList = lazy(() => import('Components/Admin/SalesProcess/List'));
const SubscriptionIndexing = lazy(() => import('Components/Admin/SubscriptionIndexing'));
const SubscriptionIndexingList = lazy(() => import('Components/Admin/SubscriptionIndexing/SubscriptionIndexingList'));
const SharedEasyBooking = lazy(() => import('Components/Admin/EasyBookingSettingsV2/SharedEasyBooking'));
const SharedEasyBookingSettings = lazy(() => import('Components/Admin/EasyBookingSettingsV2/SharedSettings'));
const NotificationCenter = lazy(() => import('Components/Admin/NotificationCenter'));

angular.module('domain.admin', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		// BASE PATH
		$stateProvider.state('administration', {
			abstract: true,
			controller: 'AdminCtrl as AdminCtrl',
			templateUrl: require('App/upsales/domain/admin/views/main.html?file'),
			url: '/:customerId/up-admin',
			section: 'admin',
			resolve: {
				loaded: [
					'AppService',
					function (AppService) {
						return AppService.loadedPromise;
					}
				]
			}
		});

		var isAdminOrMaAdmin = function ($q, AppService) {
			var defer = $q.defer();

			// eslint-disable-next-line promise/catch-or-return
			AppService.loadedPromise.then(function () {
				if (AppService.getSelf().administrator || AppService.getSelf().userParams.mailAdmin) {
					return defer.resolve(true);
				}
				defer.reject();
			});

			return defer.promise;
		};
		isAdminOrMaAdmin.$inject = ['$q', 'AppService'];

		var isAdmin = function ($q, AppService) {
			var defer = $q.defer();

			// eslint-disable-next-line promise/catch-or-return
			AppService.loadedPromise.then(function () {
				if (AppService.getSelf().administrator) {
					return defer.resolve(true);
				}
				defer.reject();
			});

			return defer.promise;
		};
		isAdmin.$inject = ['$q', 'AppService'];

		var isTeamLeader = function ($q, AppService) {
			var defer = $q.defer();

			// eslint-disable-next-line promise/catch-or-return
			AppService.loadedPromise.then(function () {
				if (AppService.getSelf().teamLeader) {
					return defer.resolve(true);
				}
				defer.reject();
			});

			return defer.promise;
		};

		var isAdminAndCrm = function ($q, FeatureHelper, AppService) {
			return $q.all({
				isAdmin: isAdmin($q, AppService),
				hasCrm: FeatureHelper.isAvailableProductPromise(FeatureHelper.Product.CRM)
			});
		};
		isAdminAndCrm.$inject = ['$q', 'FeatureHelper', 'AppService'];

		async function easyBookingProAccess($q, FeatureHelper, AppService) {
			return $q.all({
				isAdminOrMaAdmin: isAdminOrMaAdmin($q, AppService),
				hasCrm: FeatureHelper.isAvailableProductPromise(FeatureHelper.Product.CRM)
			});
		}

		easyBookingProAccess.$inject = ['$q', 'FeatureHelper', 'AppService'];

		var isAdminOrTeamLeaderAndCrm = function ($q, FeatureHelper, AppService) {
			return $q.all({
				isAdminOrTeamLeader: $q
					.all({
						isAdmin: isAdmin($q, AppService).catch(() => false),
						isTeamLeader: isTeamLeader($q, AppService).catch(() => false)
					})
					.then(({ isAdmin, isTeamLeader }) => {
						return isAdmin || isTeamLeader ? Promise.resolve() : Promise.reject();
					}),
				hasCrm: FeatureHelper.isAvailableProductPromise(FeatureHelper.Product.CRM)
			});
		};

		var genericSubview = require('App/upsales/domain/admin/views/sub.html?file');
		var genericSubctrl = 'AdminSubCtrl as AdminSubCtrl';

		$stateProvider
			.state('administration.dashboard', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/',
				section: 'admin'
			})
			.state('administration.anonymization', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				params: {
					page: null
				},
				url: '/anonymization',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.imports', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/import',
				section: 'admin',
				params: { stepId: null },
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.import', {
				controller: 'ImportCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/import/:id',
				section: 'admin',
				params: { stepId: null, isNew: false },
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.profile', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/profile',
				section: 'admin'
			})
			.state('administration.looker', {
				controller: 'LookerAdmin as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/looker',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.user', {
				controller: 'EditUserCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/user/:id',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.activityTypes', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/activitytypes',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.orderstages', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/orderstages',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					isAvailable: function () {
						return Tools.FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.STAGES_ADMIN);
					}
				}
			})
			.state('administration.fields', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/fields/:type',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.2fa', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/security/2fa',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: function () {
						return Tools.FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.SMSAUTH);
					}
				}
			})
			.state('administration.prospectingSignals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							ProspectingSignalsSettings,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/prospecting-signals',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return (
								FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.PROSPECTING_PRO) &&
								FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.PROSPECTING_SIGNALS)
							);
						}
					]
				}
			})
			.state('administration.apiKeys', {
				controller: 'ListApiKeys as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/security/apiKeys',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.API_KEYS);
						}
					]
				}
			})
			.state('administration.scripts', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/scripts',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.script', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/script/:id',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.integrations', {
				controller: 'StandardIntegration as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/integrations',
				section: 'admin',
				params: {
					filter: { value: null }
				}
			})
			.state('administration.userdefinedobjects', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/userdefinedobjects',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.integration', {
				controller: 'ConfigureIntegration as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/integrations/configure/:id/:configure?code?setOauthToNull?tab?oAuthFieldName',
				section: 'admin'
			})
			.state('administration.editIntegration', {
				controller: 'EditIntegration as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/integrations/edit/:id',
				section: 'admin'
			})
			.state('administration.currencies', {
				controller: 'CurrencySettings as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/currencies',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.marketRejectlist', {
				controller: 'MarketRejectlist as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/marketRejectlist',
				resolve: {
					isAdminOrMaAdmin: isAdminOrMaAdmin
				}
			})
			.state('administration.mailSettings', {
				controller: 'MailAdmin as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/mailsettings',
				section: 'admin',
				resolve: {
					isAdminOrMaAdmin: isAdminOrMaAdmin,
					meta: [
						'MailAdminMeta',
						function (MailAdminMeta) {
							return MailAdminMeta();
						}
					]
				}
			})
			.state('administration.visitSettings', {
				controller: 'VisitSettings as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/visit',
				resolve: {
					isAdminOrMaAdmin: isAdminOrMaAdmin
				}
			})
			.state('administration.accountProfile', {
				controller: 'AccountProfileCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/accountProfile',
				resolve: {
					isAdminOrMaAdmin: isAdminOrMaAdmin
				}
			})
			.state('administration.dynamiclinks', {
				controller: 'DynamicLinksCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/dynamiclinks',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.DYNAMIC_LINKS);
						}
					],
					isAdmin: isAdmin
				}
			})
			.state('administration.usersAndRoles', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/usersAndRoles',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.activeInvites', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/activeInvites',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.accountSettings', {
				controller: 'AccountSettingsCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/account-settings',
				section: 'admin'
			})
			.state('administration.automations', {
				controller: 'ListAutomations as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/automations',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.AUTOMATIONS);
						}
					]
				}
			})
			.state('administration.automation', {
				controller: 'EditAutomation as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/automation/:id',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.AUTOMATIONS);
						}
					]
				}
			})
			.state('administration.triggers', {
				controller: 'ListTriggers as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/triggers',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.TRIGGERS);
						}
					]
				}
			})
			// If more than X products we show a plain list instead of the tree
			.state('administration.products', {
				templateProvider: function (FeatureHelper, AppService) {
					return AppService.loadedPromise.then(function () {
						const localStorage = new LocalStorage();
						const selectedProductsView = localStorage.getValue('admin-products-view');
						if (Boolean(selectedProductsView) && selectedProductsView === 'list') {
							return '<div></div>';
						} else {
							return genericSubview;
						}
					});
				},
				controllerProvider: function () {
					const localStorage = new LocalStorage();
					const selectedProductsView = localStorage.getValue('admin-products-view');
					if (Boolean(selectedProductsView) && selectedProductsView === 'list') {
						return function ($scope, $element, $stateParams) {
							SetupComponent($scope, AdminProductList, $element[0], $stateParams, {
								redux: true,
								modal: false,
								useSuspense: true
							});
						};
					}
					return genericSubctrl;
				},

				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/products?import',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.priceLists', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, PriceLists, $element[0], {}, { useSuspense: true });
					}
				],
				url: '/pricelists',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.trigger', {
				controller: 'EditTrigger as AdminSubCtrl',
				templateUrl: genericSubview,
				section: 'admin',
				url: '/trigger/:id',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.TRIGGERS);
						}
					]
				}
			})
			.state('administration.documentTemplates', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/documentTemplates',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.ipblock', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/ip-block',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.brand', {
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.AdminBrands, $element[0], {}, { redux: true });
					}
				],
				template: '<div></div>',
				url: '/brands',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					isAvailable: [
						'FeatureHelper',
						'$q',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.BRANDS);
						}
					]
				}
			})
			.state('administration.salesboards', {
				controller: genericSubctrl,
				templateUrl: genericSubview,
				url: '/salesboards',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.listSalesboards', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, Salesboard, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/listsalesboards',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.loginStats', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, LoginStats, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/sign-in-stats',
				section: 'admin',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.state('administration.salesboard', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$stateParams',
					function ($scope, $element, $stateParams) {
						var props = Object.assign({}, $stateParams, { $scope: $scope });
						SetupComponent($scope, window.AdminEditSalesboard, $element[0], props, { redux: true });
					}
				],
				url: '/salesboard/:id',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.salesboardCard', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.AdminSalesboardCard, $element[0], {}, { redux: true });
					}
				],
				url: '/salesboard-card',
				section: 'admin',
				params: {
					id: null
				},
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.state('administration.activityquota', {
				controller: 'ActivityQuota as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/activity-quota',
				section: 'admin',
				resolve: {
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return new Promise((resolve, reject) => {
								const hasGoals = FeatureHelper.hasSoftDeployAccess('GOALS');
								const hasToDo = FeatureHelper.hasSoftDeployAccess('TODO_LIST');

								const shouldShowOldActivityQuota = !(hasGoals && hasToDo);

								if (shouldShowOldActivityQuota) {
									resolve();
								} else {
									reject();
								}
							});
						}
					],
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm
				}
			})
			.state('administration.salesquota', {
				controller: 'SalesQuota as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/sales-quota',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm
				}
			})
			.state('administration.salesmarginquota', {
				controller: 'GenericQuota as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/sales-margin-quota',
				section: 'admin',
				resolve: {
					quotaType: async () => 'contributionMargin',
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm
				}
			})
			.state('administration.subscriptionquota', {
				controller: 'GenericQuota as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/subscription-quota',
				section: 'admin',
				resolve: {
					quotaType: async () => 'recurring',
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm
				}
			})
			.state('administration.pipelinegoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, PipelineGoals, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/pipeline-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.phonecallgoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, PhonecallGoals, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/phonecall-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.bookedappointmentgoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							BookedAppointmentGoals,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/booked-appointment-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.completedappointmentgoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							CompletedAppointmentGoals,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/completed-appointment-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.completedfirstappointmentgoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							CompletedFirstAppointmentGoals,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/completed-first-appointment-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.bookedfirstappointmentgoals', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							BookedFirstAppointmentGoals,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/booked-first-appointment-goals',
				section: 'admin',
				resolve: {
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm,
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.hasSoftDeployAccessPromise('GOALS');
						}
					]
				}
			})
			.state('administration.oneoffquota', {
				controller: 'GenericQuota as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/oneoff-quota',
				section: 'admin',
				resolve: {
					quotaType: async () => 'oneOff',
					isAdminOrTeamLeaderAndCrm: isAdminOrTeamLeaderAndCrm
				}
			})
			.state('administration.agreement', {
				controller: 'AgreementAdmin as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/agreement',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.RECURRING_ORDER);
						}
					]
				}
			})
			.state('administration.subscriptionIndexing', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							SubscriptionIndexing,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/subscriptionIndexing',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							const hasSubscriptions = FeatureHelper.isAvailablePromise(
								FeatureHelper.Feature.RECURRING_ORDER
							);
							const hasIndexing = FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_INDEXING');

							return hasSubscriptions && hasIndexing;
						}
					]
				}
			})
			.state('administration.subscriptionIndexingList', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							SubscriptionIndexingList,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/subscriptionIndexingList',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							const hasSubscriptions = FeatureHelper.isAvailablePromise(
								FeatureHelper.Feature.RECURRING_ORDER
							);
							const hasIndexing = FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_INDEX_INCREASE_V2');

							return hasSubscriptions && hasIndexing;
						}
					]
				}
			})
			.state('administration.optIn', {
				controller: 'OptInAdmin as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/optin',
				section: 'admin',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.OPT_IN);
						}
					]
				}
			});

		// MAIN
		$stateProvider
			.state('admin', {
				abstract: true,
				controller: 'Admin as Admin',
				section: 'admin',
				templateUrl: require('App/upsales/domain/admin/views/admin.html?file'),
				url: '/:customerId/admin',
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					]
				}
			})
			// SALESBOARDS
			.state('admin.salesboards', {
				controller: 'EditSalesboards as EditSalesboards',
				templateUrl: require('App/upsales/domain/admin/views/salesboard/salesboards.html?file'),
				url: '/salesboards',
				section: 'admin',
				resolve: {
					authVersion: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					],
					meta: [
						'$stateParams',
						'EditSalesboardsMeta',
						function ($stateParams, EditSalesboardsMeta) {
							return EditSalesboardsMeta($stateParams);
						}
					]
				}
			})
			.state('admin.salesboard', {
				controller: 'EditSalesboard as EditSalesboard',
				templateUrl: require('App/upsales/domain/admin/views/salesboard/salesboard.html?file'),
				url: '/salesboard/:id',
				section: 'admin',
				resolve: {
					authVersion: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					],
					meta: [
						'$stateParams',
						'EditSalesboardMeta',
						function ($stateParams, EditSalesboardMeta) {
							return EditSalesboardMeta($stateParams);
						}
					]
				}
			})
			.state('admin.engage', {
				controller: 'EditAds as EditAds',
				templateUrl: require('App/upsales/domain/admin/views/engage/engage.html?file'),
				url: '/engage',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						'$q',
						function (FeatureHelper, $q) {
							if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
								return $q.reject();
							}
						}
					],
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					],
					meta: [
						'$stateParams',
						'EditAdsMeta',
						function ($stateParams, EditAdsMeta) {
							return EditAdsMeta($stateParams);
						}
					]
				}
			})
			.state('admin.settings', {
				controller: 'Settings',
				templateUrl: require('App/upsales/domain/admin/views/settings/settings.html?file'),
				url: '/settings',
				resolve: {
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			// Temp looker admin page
			.state('lookerAdmin', {
				controller: 'LookerAdmin as AdminSubCtrl',
				template: function () {
					return '<div id="admin"><div id="admin-root" style="left:0;" up-admin-root="AdminSubCtrl.rootData"></div></div>';
				},
				url: '/looker-admin',
				section: 'admin',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.LOOKER);
						}
					],
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.deleteLog', {
				controller: 'DeleteLogCtrl as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/deletelog',
				section: 'admin',
				resolve: {
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.category', {
				controller: 'AdminCategory as AdminSubCtrl',
				templateUrl: genericSubview,
				url: '/category/:type',
				section: 'admin',
				resolve: {
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.billing', {
				template: '<div id="admin-billing-root"></div>',
				controller: [
					'$scope',
					'$element',
					'$stateParams',
					function ($scope, $element, $stateParams) {
						SetupComponent($scope, BillingRoot, $element[0], { initialTab: $stateParams.initialTab });
					}
				],
				params: {
					alias: null,
					initialTab: null
				},
				reloadOnSearch: false,
				url: '/billing/:initialTab?/?alias',
				section: 'admin',
				resolve: {
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireBillingAdmin();
						}
					]
				}
			})
			.state('administration.optOut', {
				template: '<div id="admin-opt-out-root"></div>',
				controller: [
					'$scope',
					function ($scope) {
						new window.AdminOptOutListCtrl($scope);
					}
				],
				url: '/optOut',
				section: 'admin',
				resolve: {
					authMailAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdminOrMailAdmin();
						}
					]
				}
			})
			.state('administration.accountsettings', {
				template: '<div id="admin-languagesettings-root"></div>',
				controller: [
					'$scope',
					function ($scope) {
						new window.AdminLanguageSettingsCtrl($scope);
					}
				],
				url: '/accountsettings',
				section: 'admin',
				resolve: {
					softDeploy: [
						'FeatureHelper',
						'AppService',
						'$q',
						function (FeatureHelper, AppService, $q) {
							var defer = $q.defer();

							// eslint-disable-next-line promise/catch-or-return
							AppService.loadedPromise.then(function () {
								var hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
								var hasBrokenFiscalYear = FeatureHelper.isAvailable(
									FeatureHelper.Feature.BROKEN_FISCAL_YEAR
								);

								if (hasBrokenFiscalYear || hasNewFields) {
									defer.resolve();
								} else {
									defer.reject();
								}
							});

							return defer.$promise;
						}
					],
					authMailAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdminOrMailAdmin();
						}
					]
				}
			})
			.state('administration.salesprocesses', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, SalesProcessList, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/salesprocesses',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					hasIt: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.SALES_PROCESS);
						}
					]
				}
			})
			.state('administration.appointmentAvailability', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent(
							$scope,
							EasyBookingSettings,
							$element[0],
							{},
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/appointment-availability',
				section: 'admin'
			})
			.state('administration.sharedAppointmentAvailability', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, SharedEasyBooking, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/shared-appointment-availability',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					easyBookingProAccess
				}
			})
			.state('administration.sharedAppointmentAvailabilitySettings', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$stateParams',
					function ($scope, $element, $stateParams) {
						SetupComponent(
							$scope,
							SharedEasyBookingSettings,
							$element[0],
							{ id: $stateParams.id },
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/shared-appointment-availability/:id',
				section: 'admin',
				resolve: {
					isAdminAndCrm: isAdminAndCrm,
					easyBookingProAccess
				}
			})

			.state('administration.appointmentEditor', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$stateParams',
					function ($scope, $element, $stateParams) {
						SetupComponent(
							$scope,
							EasyBookingSettingsEditorV2,
							$element[0],
							{ id: $stateParams.id },
							{ redux: true, useSuspense: true }
						);
					}
				],
				url: '/appointment-editor/:id',
				section: 'admin'
			})

			.state('administration.reminders', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, Reminders, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/reminders',
				section: 'admin'
			})
			.state('administration.mailsignature', {
				template: '<div id="admin-mailsignature-root"></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, MailSignatureRoot, $element[0]);
					}
				],
				url: '/mailSignature',
				section: 'admin',
				resolve: {
					hasIt: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.MAIL_SIGNATURE);
						}
					],
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.sharedviews', {
				template: '<div id="admin-sharedviews-root"></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.SharedViewsAdmin, $element[0], {}, { redux: true });
					}
				],
				url: '/sharedviews',
				section: 'admin',
				resolve: {
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.SHARED_VIEWS);
						}
					],
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.domains', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.AdminDomains, $element[0], {}, { redux: true });
					}
				],
				url: '/domains',
				section: 'admin',
				resolve: {
					authAdminOrMailAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdminOrMailAdmin();
						}
					]
				}
			})
			.state('administration.journeyStatuses', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, JourneyStatuses, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/journeyStatuses',
				section: 'admin',
				resolve: {
					hasFeature: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.JOURNEY_STATUS);
						}
					],
					authAdminOrMailAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdminOrMailAdmin();
						}
					]
				}
			})
			.state('administration.journeyEditor', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$stateParams',
					function ($scope, $element, $stateParams) {
						SetupComponent(
							$scope,
							window.JourneyEditor,
							$element[0],
							{ type: $stateParams.type, id: $stateParams.id },
							{ redux: true }
						);
					}
				],
				url: '/journeyEditor/:type/:id',
				section: 'admin',
				resolve: {
					// loaded: function(AppService) {
					// 	return AppService.loadedPromise;
					// },
					softDeploy: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.JOURNEY_STATUS);
						}
					],
					authAdminOrMailAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdminOrMailAdmin();
						}
					]
				}
			})
			.state('administration.singleSignOn', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.SingleSignOn, $element[0], {}, { redux: true });
					}
				],
				url: '/single-sign-on',
				section: 'admin',
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.SINGLE_SIGN_ON);
						}
					],
					authAdmin: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireAdmin();
						}
					]
				}
			})
			.state('administration.notificationCenter', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, NotificationCenter, $element[0], {}, { redux: true, useSuspense: true });
					}
				],
				url: '/notification-center',
				section: 'admin'
			})
			.state('administration.defaultMailSignature', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					function ($scope, $element) {
						SetupComponent($scope, window.DefaultMailSignature, $element[0], {}, { redux: true });
					}
				],
				url: '/default-mail-signature',
				section: 'admin',
				resolve: {
					isAvailable: function () {
						return Tools.FeatureHelper.isAvailablePromise(Tools.FeatureHelper.Feature.MAIL_SIGNATURE);
					}
				}
			});

		// Modals
		$upModalProvider
			.modal('SendEmailAction', {
				templateUrl: require('App/upsales/domain/admin/views/automations/sendEmailAction.html?file'),
				controller: 'SendEmailAction',
				style: 'form default'
			})
			.modal('EditRole', {
				template: '<div id="edit-role-modal-root"></div>',
				controller: function ($scope) {
					new window.EditRoleController($scope);
				},
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen',
				resolve: {
					isAdmin: isAdmin,
					isAvailable: function () {
						return Tools.FeatureHelper.isAvailablePromise(
							Tools.FeatureHelper.Feature.ADVANCED_ROLE_SETTINGS
						);
					}
				}
			})
			.modal('SalesCoachModal', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							SalesCoachModal,
							$element[0],
							{ id: $modalParams.id, ...$modalParams },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('ChecklistItem', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							ChecklistItem,
							$element[0],
							{ id: $modalParams.id, checklistItem: $modalParams.checklistItem || null },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('VerifyBudget', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							VerifyBudget,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('NextStep', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							NextStep,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('DecisionMakers', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							DecisionMakers,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('DecisionMakersPro', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							DecisionMakersPro,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('Solution', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							Solution,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('VerifyTimeframe', {
				template: '<div></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							VerifyTimeframe,
							$element[0],
							{ id: $modalParams.id },
							{ redux: true, modal: true }
						);
					}
				],
				rememberHeight: true,
				invisibleCurtain: true,
				style: 'new-full-screen no-padding',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('UpdateClientAction', {
				templateUrl: require('App/upsales/domain/account/views/updateClientAction.html?file'),
				controller: 'UpdateClientAction',
				style: 'form-md default'
			})
			.modal('UpdateClientContactsAction', {
				templateUrl: require('App/upsales/domain/contact/views/updateContactAction.html?file'),
				controller: 'UpdateContactAction',
				style: 'form-md default'
			})
			.modal('AssignToUserAction', {
				templateProvider: function () {
					return Tools.$q.when('<div></div>');
				},
				controller: function ($scope, $element, $modalParams) {
					SetupComponent(
						$scope,
						AssignModalLead,
						$element[0],
						{
							...$modalParams,
							title: Tools.$translate('assign.assignLead')
						},
						{ redux: true, modal: true }
					);
				},
				initialHeight: 300,
				style: function () {
					return 'processed-by-modal dynamic';
				}
			})
			.modal('AssignToUserActionLegacy', {
				templateUrl: require('App/upsales/domain/admin/views/automations/createLeadAction.html?file'),
				controller: 'CreateLeadAction',
				style: 'confirm default'
			})
			.modal('UpdateContactAction', {
				templateUrl: require('App/upsales/domain/contact/views/updateContactAction.html?file'),
				controller: 'UpdateContactAction',
				style: 'form-md default'
			})
			.modal('SendWebhookAction', {
				templateUrl: require('App/upsales/domain/admin/views/automations/sendWebhookAction.html?file'),
				controller: 'SendWebhookAction',
				style: 'form default'
			})
			.modal('CreateActivityAction', {
				templateUrl: require('App/upsales/domain/admin/views/automations/createActivityAction.html?file'),
				style: 'form-md default',
				controller: 'CreateActivityAction'
			})
			.modal('AnonymizeContactAction', {
				template: '<div></div>',
				controller: function (
					$scope,
					$element,
					$modalParams,
					AppService,
					ContactAttributes,
					ActivityAttributes,
					AppointmentAttributes,
					OrderAttributes,
					OpportunityAttributes,
					MailAttributes,
					EventAttributes,
					AgreementAttributes
				) {
					window.AnonymizeContactController(
						$scope,
						$element,
						$modalParams,
						AppService,
						ContactAttributes,
						ActivityAttributes,
						AppointmentAttributes,
						OrderAttributes,
						OpportunityAttributes,
						MailAttributes,
						EventAttributes,
						AgreementAttributes
					);
				}
			})
			.modal('AddContactToSegmentAction', {
				template: '<div id="addToSegment__root"></div>',
				controller: function (
					$scope,
					$modalParams,
					AppService,
					FeatureHelper,
					$q,
					Segment,
					$state,
					ActionProperties,
					NotificationService,
					FilterHelper,
					MultiActions,
					$multiSelect,
					$translate
				) {
					window.AddContactToSegmentController(
						$scope,
						$modalParams,
						AppService,
						FeatureHelper,
						$q,
						Segment,
						$state,
						ActionProperties,
						NotificationService,
						FilterHelper,
						MultiActions,
						$multiSelect,
						$translate
					);
				}
			})
			.modal('editCustomFields', {
				template: '<div id="edit-custom-field_modal"></div>',
				controller: function ($scope, AppService, $modalParams, CustomField, SniCodes) {
					let firstElement;
					var props = {
						type: $modalParams.type,
						title: $modalParams.title,
						field: $modalParams.field,
						customFields: $modalParams.customFields,
						roles: AppService.getRoles(),
						close: function () {
							$scope.reject();
						},
						confirm: function (field, type) {
							var customerId = Tools.AppService.getCustomerId();
							CustomField.customer(customerId)
								.setType(type)
								.save(field)
								.then(function (res) {
									if (res.error) {
										$scope.reject(res.error);
									} else {
										const customFields = $modalParams.customFields;

										if (field.id) {
											const index = customFields.findIndex(f => f.id === field.id);
											customFields[index] = res.data;
										} else {
											customFields.push(res.data);
										}

										AppService.setCustomFields(type, customFields);
										$scope.resolve(res.data);
									}
								})
								.catch(function (err) {
									$scope.reject(err);
								});
						},
						SniCodes: SniCodes,
						reloadModalPosition: $scope.reloadModalPosition
					};

					$scope.$on('modal.ready', function () {
						firstElement = document.getElementById('edit-custom-field_modal');
						if (!firstElement) {
							return;
						}

						var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
						var modal = ReactTemplates.admin.newFieldsModal;

						if (hasNewFields) {
							modal = window.EditCustomField;
						}
						ReactDOM.render(React.createElement(modal, props), firstElement);
					});

					$scope.$on('$destroy', function () {
						ReactDOM.unmountComponentAtNode(firstElement);
						firstElement = undefined;
					});
				},
				style: function () {
					if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CALCULATING_FIELDS)) {
						return 'form default';
					}
					return 'form-sm default';
				}
			})
			.modal('editDynamiclink', {
				templateUrl: require('App/upsales/domain/admin/views/dynamiclink/editDynamiclink.html?file'),
				controller: 'EditDynamiclinkCtrl as EditDynamiclinkCtrl',
				style: 'form-sm default',
				resolve: {
					meta: function ($modalParams, EditDynamiclinkMeta) {
						return EditDynamiclinkMeta($modalParams);
					}
				}
			})
			.modal('AddProductModal', {
				template: '<div></div>',
				style: 'form default',
				controller: ($scope, $modalParams) => {
					SetupComponent(
						$scope,
						EditProduct,
						null,
						{ editProduct: _.cloneDeep($modalParams.product) },
						{
							modal: true,
							redux: true
						}
					);
				},
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('AddProductCategoryModal', {
				template: '<div id="addProductCategoryModal_root"></div>',
				controller: 'AddProductCategoryModal as AddProductCategoryModal',
				style: 'form-sm default modal400 scroll-y',
				resolve: {
					isAdminAndCrm: isAdminAndCrm
				}
			})
			.modal('editDocumentTemplate', {
				templateUrl: require('App/upsales/domain/admin/views/editDocumentTemplate.html?file'),
				controller: 'EditDocumentTempalteCtrl as EditDocumentTempalteCtrl',
				style: 'form-md default',
				disableBindings: true
			})
			.modal('CreateAppointmentAction', {
				templateUrl: require('App/upsales/domain/admin/views/automations/createAppointmentAction.html?file'),
				controller: 'CreateAppointmentAction',
				style: 'form-md default'
			})
			.modal('apiKey', {
				template: '<div id="edit-apikey-modal"></div>',
				controller: function ($scope) {
					new window.EditApikeyCtrl($scope);
				},
				style: 'edit-apikey-modal default form-sm no-border',
				resolve: {
					isAdmin: isAdmin
				}
			})
			.modal('optIn', {
				template: '<div id="opt-in-modal"></div>',
				resolve: {
					template: function (StandardMailTemplate) {
						return StandardMailTemplate.find({
							name: 'systemMailTemplate.doubleOptIn',
							getSystemTemplates: true
						}).then(function (res) {
							return res.data[0];
						});
					},
					profile: function () {
						return Tools.AccountProfile.get().then(function (res) {
							return res.data;
						});
					}
				},
				controller: function ($scope) {
					$scope.$on('modal.ready', function () {
						new window.OptInModal($scope);
					});
				},
				style: 'default form'
			})
			.modal('editOptOut', {
				template: '<div id="opt-out-modal"></div>',
				resolve: {
					optOut: function ($modalParams) {
						if (!$modalParams.id) {
							return Tools.$q.when(Tools.GroupMailCategory.new());
						}
						return Tools.GroupMailCategory.get($modalParams.id).then(function (res) {
							return res.data;
						});
					}
				},
				controller: function ($scope, $modalParams) {
					$scope.$on('modal.ready', function () {
						new window.OptOutModalCtrl($scope, $modalParams.optOut);
					});
				},
				style: 'form-sm'
			})
			.modal('editStandardFields', {
				template: '<div id="edit-standard-field-modal"></div>',
				controller: function ($scope) {
					$scope.$on('modal.ready', function () {
						new window.EditStandardFieldsCtrl($scope);
					});
				},
				style: 'form modal600'
			})
			.modal('translateFields', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					SetupComponent($scope, window.TranslateFieldModal, null, $modalParams, {
						modal: true,
						redux: true
					});
				},
				style: 'form-wide default'
			})
			.modal('editEventDomain', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					SetupComponent($scope, EditEventDomain, null, $modalParams, {
						modal: true,
						redux: true
					});
				},
				style: 'form-sm default'
			})
			.modal('sharedViewsModal', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					SetupComponent($scope, window.SharedViewsModal, null, $modalParams, {
						modal: true,
						redux: true
					});
				},
				style: 'default'
			})
			.modal('PreviewTemplate', {
				template: '<div id="addProductModal_root"></div>',
				controller: [
					'$scope',
					'$element',
					'$modalParams',
					function ($scope, $element, $modalParams) {
						SetupComponent(
							$scope,
							PreviewPdf,
							$element[0],
							{
								pdfUrl: $modalParams.pdfUrl,
								upModal: $modalParams.upModal,
								pdfTemplateResource: $modalParams.pdfTemplateResource
							},
							{ redux: true, modal: true }
						);
					}
				],
				style: 'default pdf-preview PreviewPdfModal'
			})
			.modal('templateEditor', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					SetupComponent($scope, TemplateEditor, $element[0], $modalParams, {
						modal: true,
						redux: true
					});
				},
				invisibleCurtain: true,
				style: 'form-wide default new-full-screen no-padding'
			})
			.modal('toggleFiscalYear', {
				template: '<div></div>',
				controller: function ($scope, $element, $modalParams) {
					SetupComponent($scope, ToggleFiscalYearModal, $element[0], $modalParams, {
						modal: true
					});
				},
				style: 'form-sm default',
				resolve: {
					isAdmin: isAdmin
				}
			});
	}
]);

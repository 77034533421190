// Copied from angular service
export const thousandSeperator = (num, round, separator) => {
	if (!num && num !== 0) {
		return;
	}
	if (num === 0) {
		return num;
	}
	separator = separator || ' ';

	if (round) {
		if (round === 'ceil' || round === 'up') {
			num = Math.ceil(num);
		} else if (round === 'floor' || round === 'down') {
			num = Math.floor(num);
		} else {
			num = Math.round(num);
		}
	}
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

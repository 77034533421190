import React, { useRef, useEffect, useState } from 'react';

type BaseProps = {
	background: string;
	onLoad?: (target: HTMLImageElement) => void;
	preventLoad?: boolean;
};
type ImgProps = BaseProps & { asImg: true } & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'onLoad'>;
type DivProps = BaseProps & { asImg?: false } & React.HTMLAttributes<HTMLDivElement>;
type Props = ImgProps | DivProps;

function LazyLoadBackgroundImg({ background, onLoad, asImg, preventLoad, ...props }: Props) {
	const imageRef = useRef<HTMLImageElement | HTMLDivElement>(null);
	const [observed, setObserved] = useState(false);

	useEffect(() => {
		if (imageRef.current !== null) {
			if ('IntersectionObserver' in window) {
				var imageObserver = new IntersectionObserver((entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							const image = entry.target;
							observer.unobserve(image);
							setObserved(true);
						}
					});
				});

				imageObserver.observe(imageRef.current);

				return () => {
					imageObserver.disconnect();
				};
			} else {
				// Just set the bg if browser dont support IntersectionObserver
				setObserved(true);
			}
		}
	}, [imageRef.current === null]);

	const image = observed && !preventLoad ? background : '';

	return asImg ? (
		<img
			{...props}
			ref={imageRef as React.RefObject<HTMLImageElement>}
			src={image ?? undefined}
			onLoad={e => onLoad && onLoad(e.target as HTMLImageElement)}
		/>
	) : (
		<div
			{...props}
			ref={imageRef as React.RefObject<HTMLDivElement>}
			style={{ backgroundImage: image ? `url('${image}')` : '' }}
		/>
	);
}

export default LazyLoadBackgroundImg;

import './RecommendedLeads.scss';
import moment from 'moment';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { isEqual, difference, sortBy } from 'lodash';
import { Button, Card, CardHeader, Table, TableHeader, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Prospecting from 'App/babel/resources/Prospecting';
import Activity from 'App/babel/resources/Activity';
import logError from 'App/babel/helpers/logError';
import RecommendedLead from './RecommendedLead';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';
import Order from 'App/resources/Model/Order';
import Client from 'App/resources/Model/Client';
import ProspectingClient from 'App/resources/Model/ProspectingClient';

type Props = {
	order?: Order;
	recommendedLeads?: ProspectingClient[];
};

type AccountIdMap = {
	[key: string]: string;
};

const RecommendedLeads = ({ order, recommendedLeads }: Props) => {
	const companyName = order?.client?.name ?? '';
	const seller = order?.user;

	const classes = new BemClass('RecommendedLeads');
	const [pendingAddLeads, setPendingAddLeads] = useState(() => false);
	const addActivityDelayedRetryTimer = useRef<NodeJS.Timeout>();
	const [addedLeads, setAddedLeads] = useState<ProspectingClient[]>(() => []);
	const [accountIdMap, setAccountIdMap] = useState<AccountIdMap>({});

	const allLeadsAdded = useMemo(() => {
		return !!recommendedLeads?.length && isEqual(recommendedLeads.sort(), addedLeads.sort());
	}, [recommendedLeads, addedLeads]);

	const columns = [T('column.name'), T('column.revenue'), T('column.employees'), T('column.location')].map(title => ({
		title
	}));

	useEffect(() => {
		return () => {
			if (addActivityDelayedRetryTimer.current) {
				clearTimeout(addActivityDelayedRetryTimer.current);
			}
		};
	}, []);

	const showAddLeadNotification = (accountId: number, accountName: string) => {
		Tools.NotificationService.addNotification({
			icon: 'check',
			style: Tools.NotificationService.style.SUCCESS,
			title: 'order.celebration.addedCompanyLeadNotification',
			type: TYPE.GENERIC_ENTITY_ADDED,
			body: accountName,
			customBody: {
				onclickLink: {
					clicked: () => Tools.$state.go('account.dashboard', { id: accountId }),
					text: 'noti.entity.Created'
				}
			}
		});
	};

	const createActivity = async (createdAccount: Client, tries = 0) => {
		try {
			const activity = {
				description: T('activity.outcome.bookAppointment'),
				client: { id: createdAccount.id },
				contacts: [],
				users: seller ? [seller] : [],
				activityType: {
					id: Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
						? Tools.AppService.getTodoTypes().PHONE_CALL.id
						: Tools.AppService.getMetadata().params.DefaultActivityTypeId
				},
				date: Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					? null
					: moment(new Date()).format('YYYY-MM-DD'),
				notes: T('order.celebration.recommendedLeadActivityNotes', { companyName })
			};
			await Activity.save(activity, { skipNotification: true });
			showAddLeadNotification(createdAccount.id, createdAccount.name);
		} catch (err) {
			logError(err, 'Failed to save activity');
			const retryLimit = 2;
			if (tries < retryLimit) {
				addActivityDelayedRetryTimer.current = setTimeout(() => {
					createActivity(createdAccount, tries + 1);
				}, 500);
			}
		}
	};

	const createActivitiesForCreatedAccounts = async (createdAccounts: Client[]) => {
		for (const createdAccount of createdAccounts) {
			await createActivity(createdAccount);
		}
	};

	const sortLeads = (leads: ProspectingClient[]) => sortBy(leads, 'name');

	const addLead = async (recommendedLead: ProspectingClient, addActivity = true) => {
		try {
			const savedProspectResponse = await Prospecting.save({
				prospectingId: recommendedLead.prospectingId,
				customValues: []
			});

			const createdAccount = savedProspectResponse?.data;
			if (!createdAccount) {
				return;
			}

			if (addActivity) {
				setAddedLeads(sortLeads([...addedLeads, recommendedLead]));
				setAccountIdMap({
					...accountIdMap,
					[recommendedLead.prospectingId]: createdAccount.id
				});
				await createActivity(createdAccount);
			}
			return createdAccount;
		} catch (e) {
			logError(e, 'Failed to add lead');
		}
	};

	const addLeads = async () => {
		setPendingAddLeads(true);
		const createdAccounts = [];
		const createdLeads = [];
		const leadsToAdd = difference(recommendedLeads || [], addedLeads);
		const updatedAccountIdMap = { ...accountIdMap };
		for (const recommendedLead of leadsToAdd) {
			const createdAccount = await addLead(recommendedLead, false);
			if (createdAccount) {
				createdLeads.push(recommendedLead);
				createdAccounts.push(createdAccount);
				updatedAccountIdMap[recommendedLead.prospectingId] = createdAccount.id;
			}
		}
		setAddedLeads(sortLeads([...addedLeads, ...createdLeads]));
		setAccountIdMap(updatedAccountIdMap);
		await createActivitiesForCreatedAccounts(createdAccounts);
		setPendingAddLeads(false);
	};

	if (!recommendedLeads?.length) {
		return null;
	}

	return (
		<Card className={classes.b()}>
			<CardHeader size="md" title={T('prospecting.box.similarTo', { name: companyName })}>
				{!allLeadsAdded && (
					<Tooltip position="bottom" title={T('order.celebration.addLeadsTooltip')} distance={28}>
						<Button
							className={classes.elem('addLeadsButton').b()}
							size="md"
							onClick={addLeads}
							loading={pendingAddLeads}
						>
							{T('default.addAll')}
						</Button>
					</Tooltip>
				)}
			</CardHeader>

			<div
				className={classes
					.elem('table')
					.mod({ scrollable: recommendedLeads.length > 4 })
					.b()}
			>
				<Table>
					<TableHeader columns={columns} />
					{recommendedLeads.map(recommendedLead => (
						<RecommendedLead
							key={recommendedLead.prospectingId}
							recommendedLead={recommendedLead}
							addLead={addLead}
							pendingAddLeads={pendingAddLeads}
							isAdded={
								!!addedLeads.find(lead => {
									return lead.prospectingId === recommendedLead.prospectingId;
								})
							}
							accountId={accountIdMap[recommendedLead.prospectingId]}
						/>
					))}
				</Table>
			</div>
		</Card>
	);
};

export default RecommendedLeads;

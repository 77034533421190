'use strict';

angular.module('upResources').directive('upInfo', function () {
	return {
		replace: true,
		template:
			'<button type="button" class="up-info btn btn-link" bs-tooltip data-title="{{msg | translate}}" data-trigger="hover click" data-container="body"><b class="fa fa-question-circle"></b></button>',
		link: function (scope, element, attr) {
			scope.msg = attr.upInfo || '';
			element.attr('tabindex', '-1');
		}
	};
});

import React from 'react';
import PropTypes from 'prop-types';

import CriteriaWrapper from './CriteriaWrapper';
import InputType from './InputType';

export default class CriteriaInput extends React.Component {
	constructor(props) {
		super(props);
		const { $translate } = Tools;
		const { config } = props;

		this.removeCriteria = this.removeCriteria.bind(this);

		const entity = config.entity || 'contact';
		this.lang = {
			entity: $translate(`default.${entity}`),
			title: $translate(config.title),
			remove: $translate('filters.removeFilter')
		};

		const tooltip = config.tooltip;

		if (tooltip) {
			this.lang = { ...this.lang, tooltip: $translate(tooltip) };
		}
	}

	removeCriteria() {
		const { removeCriteria, filter } = this.props;
		removeCriteria(filter.filterName);
	}

	render() {
		const { onClick } = this.props;
		const showEntity = !this.props.entities || Object.keys(this.props.entities).length > 1;

		return (
			<CriteriaWrapper
				className="CriteriaInput"
				onRemove={this.removeCriteria}
				onClick={onClick}
				lang={this.lang}
				locked={this.props.filter.locked}
				type="criteria"
				showEntity={showEntity}
				icon={this.props.config.icon}
			>
				<InputType {...this.props} locked={this.props.filter.locked} />
			</CriteriaWrapper>
		);
	}
}

CriteriaInput.propTypes = {
	type: PropTypes.string.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	removeCriteria: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	entities: PropTypes.object
};

CriteriaInput.defaultProps = {
	filter: {},
	config: {}
};

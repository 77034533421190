export const cleanViewUrl = (inputUrl: string) => {
	// Match digits in the URL and replace them with placeholders
	var transformedUrl = inputUrl
		.replace(/^\/(\d+)/, '/:customerId')
		.replace(/\/(\d+)/g, '/:id')
		.replace(/(\?.*)/g, '');

	return transformedUrl;
};

export const cleanUrl = (inputUrl: string) => {
	var transformedUrl = inputUrl.replace(/\/(\d+)/g, '/:id').replace(/(\?.*)/g, '');
	return transformedUrl;
};

export const computeViewName = (path: string) => {
	// Skip pathless routes
	if (!path) {
		return '';
	}

	return path.startsWith('/') ? path : `/${path}`;
};

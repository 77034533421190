'use strict';

angular.module('upModals.meta').factory('CreateDocumentMeta', [
	'$q',
	'AppService',
	'Contact',
	'RequestBuilder',
	function ($q, AppService, Contact, RequestBuilder) {
		return function ($modalParams) {
			var customerId = AppService.getCustomerId();

			var promises = {};

			if ($modalParams.templates) {
				promises.templates = $q.when({ data: $modalParams.templates });
			} else {
				var type = $modalParams.type === 'account' ? 'client' : $modalParams.type;
				promises.templates = $q.when({ data: AppService.getDocumentTemplates(type) });
			}

			if ($modalParams.type === 'client' || $modalParams.type === 'account' || $modalParams.type === 'activity') {
				var contactFilter = new RequestBuilder();
				contactFilter.addSort(Contact.attr.name, true);
				if ($modalParams.type === 'activity') {
					contactFilter.addFilter(
						Contact.attr.account.attr.id,
						contactFilter.comparisonTypes.Equals,
						$modalParams.accountId
					);
				} else {
					contactFilter.addFilter(
						Contact.attr.account.attr.id,
						contactFilter.comparisonTypes.Equals,
						$modalParams.id
					);
				}
				contactFilter.addFilter(Contact.attr.active, contactFilter.comparisonTypes.Equals, true);
				promises.contacts = Contact.customer(customerId).find(contactFilter.build());
			} else {
				promises.contacts = $q.when({ data: [] });
			}

			return $q.all(promises);
		};
	}
]);

import ClientCardDocuments from 'App/pages/ClientCard/ContentPages/Documents/Documents';

angular.module('domain.account').controller('AccountFiles', [
	'$scope',
	'AppService',
	'File',
	'RequestBuilder',
	'$upModal',
	'$state',
	'Esign',
	'$q',
	'$translate',
	'$safeApply',
	function ($scope, AppService, File, RequestBuilder, $upModal, $state, Esign, $q, $translate, $safeApply) {
		var AccountCtrl = $scope.AccountCtrl;
		var AccountFilesCtrl = this;
		var customerId;
		AccountFilesCtrl.files = [];
		AccountFilesCtrl.esigns = [];

		AccountCtrl.useReactFiles = Tools.FeatureHelper.hasSoftDeployAccess('ACCOUNT_CARD_FILES_USE_REACT_TAB');
		AccountCtrl.ClientCardDocuments = ClientCardDocuments;

		var goToDashboardIfNoData = function () {
			// If no more activities - go to dashboard
			if (!AccountFilesCtrl.files.length && !AccountFilesCtrl.esigns.length) {
				$state.go('account.dashboard', { id: AccountCtrl.account.id });
			}
		};

		$scope.$on('file.uploaded', function (e, file) {
			if (file.client && file.client.id === AccountCtrl.account.id) {
				AccountFilesCtrl.filesTotal++;
				AccountFilesCtrl.files.push(file);
			}
		});

		$scope.$on('file.deleted', function (e, deleted) {
			var index = _.findIndex(AccountFilesCtrl.files, { id: deleted.id });

			if (index !== -1) {
				AccountFilesCtrl.files.splice(index, 1);
				AccountFilesCtrl.filesTotal--;
				goToDashboardIfNoData();
			}
		});

		$scope.uploadFile = function () {
			$upModal.open('uploadFile', {
				accountId: AccountCtrl.account.id
			});
		};

		$scope.$on('esign.added', function (e, esign) {
			if (esign.client && esign.client.id === AccountCtrl.account.id) {
				AccountFilesCtrl.esigns.push(esign);
				AccountFilesCtrl.esignsTotal++;
			}
		});

		$scope.$on('esign.deleted', function (e, deleted) {
			var index = _.findIndex(AccountFilesCtrl.esigns, { id: deleted.id });

			if (index !== -1) {
				AccountFilesCtrl.esigns.splice(index, 1);
				AccountFilesCtrl.esignsTotal--;
				goToDashboardIfNoData();
			}
		});

		$scope.$on('esign.updated', function (e, esign) {
			if (esign.client && esign.client.id === AccountCtrl.account.id) {
				var found = _.find(AccountFilesCtrl.esigns, { id: esign.id });

				if (found) {
					angular.extend(found, esign);
				}
			}
		});

		var preventEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();
		};

		AccountFilesCtrl.removeFile = function (file) {
			return File.customer(customerId)
				.delete(file)
				.then(function () {
					AccountFilesCtrl.results = _.reject(AccountFilesCtrl.results, { id: file.id });
				});
		};

		AccountFilesCtrl.removeEsign = function (esign) {
			return Esign.delete(esign).then(function () {
				AccountFilesCtrl.results = _.reject(AccountFilesCtrl.results, { id: esign.id });
			});
		};

		AccountFilesCtrl.getRejectedInitials = function (esign) {
			var rejectee = _.find(esign.involved, function (p) {
				return p.declineDate;
			});

			if (rejectee) {
				return (rejectee.fstname[0] || '') + (rejectee.sndname[0] || '');
			} else {
				return $translate.instant('default.someone').toLowerCase();
			}
		};

		AccountFilesCtrl.getEsignState = function (stateInt) {
			return Esign.state[stateInt];
		};

		AccountFilesCtrl.downloadFile = function (file, e) {
			preventEvent(e);

			File.download(file.id);
		};

		AccountFilesCtrl.openEsign = function (esign) {
			return Esign.getDownloadUrl(esign);
		};

		AccountFilesCtrl.openRelated = function (file, e) {
			preventEvent(e);

			switch (file.entity) {
				case 'Contact':
					$state.go('contact.dashboard', { id: file.entityId });
					break;

				case 'Order':
				case 'Opportunity':
					$upModal.open('editOrder', { customerId: customerId, id: file.entityId });
					break;

				case 'Activity':
					$upModal.open('editActivity', { id: file.entityId });
					break;

				case 'Appointment':
					$upModal.open('editAppointment', { id: file.entityId });
					break;
			}
		};

		var getFiles = function () {
			var filters = new RequestBuilder();
			filters.addFilter(File.attr.account.attr.id, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			filters.addSort(File.attr.uploadDate, true);

			return File.customer(customerId)
				.find(filters.build())
				.then(function (res) {
					AccountFilesCtrl.filesTotal = res.metadata.total;
					AccountFilesCtrl.files = res.data;
					return res;
				});
		};

		var getEsign = function () {
			var esignFilter = new RequestBuilder();
			esignFilter.addSort(Esign.attr.sortDate, false);
			esignFilter.addFilter(Esign.attr.account.attr.id, esignFilter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);

			return Esign.find(esignFilter.build()).then(function (res) {
				AccountFilesCtrl.esignsTotal = res.metadata.total;
				AccountFilesCtrl.esigns = res.data;
				return res;
			});
		};

		var getData = function () {
			var promises = {};
			AccountFilesCtrl.loading = true;

			if (AccountCtrl.hasDocumentFeature || AccountCtrl.hasClientDocuments) {
				promises.files = getFiles();
			}

			if (AccountCtrl.hasEsign || AccountCtrl.hasClientEsigns) {
				promises.esign = getEsign();
			}

			// eslint-disable-next-line promise/catch-or-return
			$q.all(promises).then(function (res) {
				if (res.files && res.files.metadata) {
					AccountCtrl.filesTotal = res.files.metadata.total;
				} else {
					AccountCtrl.filesTotal = 0;
				}

				if (res.esign && res.esign.metadata) {
					AccountCtrl.filesTotal += res.esign.metadata.total;
				}

				AccountFilesCtrl.loading = false;
			});
		};

		var init = function () {
			customerId = AppService.getCustomerId();

			AccountFilesCtrl.fileStorageAppProps = {
				apps: AppService.getFileStorageIntegrations(),
				selectedApp: null,
				selectedFolder: null,
				objectId: AccountCtrl.account.id,
				type: 'client',
				onFolderSelect: function (appId, folderId) {
					AccountFilesCtrl.fileStorageAppProps.selectedFolder = folderId;
					AccountFilesCtrl.fileStorageAppProps.selectedApp = appId;
					$safeApply($scope);
				}
			};

			getData();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

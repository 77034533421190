'use strict';

angular.module('services.saveInline', []).service('SaveInline', [
	'$stateParams',
	'Campaign',
	'Appointment',
	'Account',
	'Activity',
	'Order',
	'Contact',
	function ($stateParams, Campaign, Appointment, Account, Activity, Order, Contact) {
		var customerId = $stateParams.customerId;

		var Service = {
			Activity: Activity,
			Appointment: Appointment,
			Account: Account,
			Order: Order,
			Opportunity: Order,
			Contact: Contact,
			Campaign: Campaign
		};

		var checkField = function (field, val) {
			var splitted = field.split('.');
			var key = field;
			var value = val;

			if (splitted.length > 1) {
				key = splitted[0];

				if (Array.isArray(val)) {
					value = [];
					angular.forEach(val, function (val) {
						var obj = {};

						if (val != 0) {
							obj[splitted[1]] = val;
							value.push(obj);
						}
					});
				} else {
					value = {};
					value[splitted[1]] = val;
				}
			}

			return {
				key: key,
				value: value
			};
		};

		return function saveInline(value, promise, field, objectId, objectType, options) {
			var updateObject = { id: objectId };
			var parsedField = checkField(field, value);

			updateObject[parsedField.key] = parsedField.value;
			// This function was built to handle a optional passed promise and also return a promise,
			// that is why i will wrap it in a new promise to keep error handeling from the outside
			return new Promise((resolve, reject) => {
				Service[objectType]
					.customer(customerId)
					.save(updateObject, options)
					.then(function (response) {
						if (promise) {
							promise.resolve();
						}
						resolve(response.data);
					})
					.catch(function (response) {
						if (promise) {
							promise.reject(response);
						}

						reject(response);
					});
			});
		};
	}
]);

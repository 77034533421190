'use strict';

angular.module('upDirectives').directive('upEventClient', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventClient.tpl.html?file'),
		require: 'upEventClient',
		scope: {
			event: '=upEventClient'
		}
	};
});

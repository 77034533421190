import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
const getComponent = isAppointment =>
	window.ReactCreateClass({
		getInitialState: function () {
			return {
				datepickerSelectOpen: false
			};
		},
		UNSAFE_componentWillMount: function () {
			var t = window.Tools.$translate;

			this.lang = {
				title: t('default.activitiesAndAppointments'),
				titleAppointments: t('default.appointments'),
				activities: t('default.activities'),
				all: t('default.all'),
				has: t('advancedSearch.has'),
				hasNot: t('advancedSearch.hasNot'),
				open: t('default.open'),
				close: t('default.closed'),
				status: t('default.status'),
				outcome: t('default.outcome'),
				planned: t('filter.history.planned'),
				completed: t('filter.history.completed'),
				notCompleted: t('filter.history.notCompleted'),
				from: t('date.from'),
				to: t('date.to'),
				noUsersAsClosed: t('filter.history.noUsersAsClosed'),
				overdueAsClosed: t('filter.history.overdueAsClosed'),
				type: t('default.type'),
				selectActivityType: t('filter.history.selectActivityType'),
				date: t('default.date')
			};

			this.datePresets = _.map(this.props.filter.presets, function (preset) {
				return {
					id: preset,
					name: t('date.' + preset)
				};
			});
		},
		toggleDatepickerSelect: function () {
			this.setState(function (prevState) {
				return {
					datepickerSelectOpen: !prevState.datepickerSelectOpen
				};
			});
		},
		onDatePresetChanged: function (event) {
			var value = event.target.value;
			var filterValue = this.getFilterValue();

			if (value !== filterValue.Date.value.preset) {
				this.onChange([
					{ path: 'value.Date.value.preset', value: value },
					{ path: 'value.Date.value.start', value: null },
					{ path: 'value.Date.value.end', value: null }
				]);
			}
		},
		onActivityTypeChanged: function (values) {
			this.onChange([{ path: 'value.ActivityType.value', value: _.pluck(values, 'id') }]);
		},
		onInactiveFilter: function () {
			this.onChange([{ path: 'inactive', value: true }]);
		},
		onHasActivityTrue: function () {
			this.onChange([
				{ path: 'value.hasActivity', value: true },
				{ path: 'inactive', value: false }
			]);
		},
		onHasActivityFalse: function () {
			this.onChange([
				{ path: 'value.hasActivity', value: false },
				{ path: 'inactive', value: false }
			]);
		},
		onStatusNull: function () {
			this.onChange([{ path: 'value.Status.value', value: null }]);
		},
		onStatusOpen: function () {
			this.onChange([{ path: 'value.Status.value', value: 'open' }]);
		},
		onStatusClosed: function () {
			this.onChange([{ path: 'value.Status.value', value: 'closed' }]);
		},
		onOutcomePlanned: function (outcomeValues) {
			const index = outcomeValues.indexOf('planned');
			if (index > -1) {
				outcomeValues.splice(index, 1);
			} else {
				outcomeValues.push('planned');
			}
			this.onChange([{ path: 'value.Outcome.value', value: outcomeValues }]);
		},
		onOutcomeCompleted: function (outcomeValues) {
			const index = outcomeValues.indexOf('completed');
			if (index > -1) {
				outcomeValues.splice(index, 1);
			} else {
				outcomeValues.push('completed');
			}
			this.onChange([{ path: 'value.Outcome.value', value: outcomeValues }]);
		},
		onOutcomeNotCompleted: function (outcomeValues) {
			const index = outcomeValues.indexOf('notCompleted');
			if (index > -1) {
				outcomeValues.splice(index, 1);
			} else {
				outcomeValues.push('notCompleted');
			}
			this.onChange([{ path: 'value.Outcome.value', value: outcomeValues }]);
		},
		onToggleOverdueAsClosed: function (value) {
			this.onChange([{ path: 'value.overdueAsClosed', value: value }]);
		},
		onFromDateChanged: function (date) {
			this.onChange([{ path: 'value.Date.value.start', value: date }]);
		},
		onToDateChanged: function (date) {
			this.onChange([{ path: 'value.Date.value.end', value: date }]);
		},
		getFilterWithValue: function (filter) {
			var newFilter = _.cloneDeep(filter);

			if (newFilter.value) {
				return newFilter;
			} else {
				return _.assign(newFilter, newFilter.generate());
			}
		},
		getFilterValue: function () {
			var filter = this.props.filter;

			return filter.value ? _.cloneDeep(filter.value) : filter.generate().value;
		},
		onChange: function (updates) {
			var props = this.props;
			var newFilter = this.getFilterWithValue(props.filter);

			updates.forEach(function (update) {
				_.set(newFilter, update.path, update.value);
			});

			props.onChange(newFilter, {
				action: 'add'
			});
		},
		classes: function (classes) {
			return _.map(classes, function (expression, classes) {
				return expression ? classes : '';
			}).join(' ');
		},
		activityTypeIdToObject: function (values) {
			let activityTypes = window.Tools.AppService.getActivityTypes(null, true);
			if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
				const todoTypes = _.values(window.Tools.AppService.getTodoTypes());
				activityTypes = activityTypes.concat(todoTypes);
			}

			return _.reduce(
				values,
				function (res, activityTypeId) {
					var item = _.find(activityTypes, { id: activityTypeId });

					if (item) {
						res.push(item);
					}
					return res;
				},
				[]
			);
		},
		render: function () {
			var classes = this.classes;
			var filterValue = this.getFilterValue();
			var filter = this.props.filter;

			var isInactive = filter.value ? filter.isInactive(filter) : true;
			var activityValues = this.activityTypeIdToObject(filterValue.ActivityType.value);

			return (
				<div className="history-filter">
					<div className="title">{isAppointment ? this.lang.titleAppointments : this.lang.title}</div>
					<div className="history-filter-row extra-thin-row">
						<div className="flex-btn-group">
							<button
								data-test-id="listfilters-history-activity-inactive"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': isInactive,
									'btn up-btn btn-light-grey no-shadow': !isInactive
								})}
								onClick={this.onInactiveFilter}
							>
								{this.lang.all}
							</button>
							<button
								data-test-id="listfilters-history-activity-has"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': filterValue.hasActivity && !isInactive,
									'btn up-btn btn-light-grey no-shadow': !filterValue.hasActivity || isInactive
								})}
								onClick={this.onHasActivityTrue}
							>
								{this.lang.has}
							</button>
							<button
								data-test-id="listfilters-history-activity-hasNot"
								className={classes({
									'btn up-btn btn-bright-blue no-shadow': !filterValue.hasActivity && !isInactive,
									'btn up-btn btn-light-grey no-shadow': filterValue.hasActivity || isInactive
								})}
								onClick={this.onHasActivityFalse}
							>
								{this.lang.hasNot}
							</button>
						</div>
					</div>
					{!isAppointment ? (
						<div className="history-filter-row">
							<label className="secondary-title">{this.lang.status}</label>
							<div className="flex-btn-group">
								<button
									data-test-id="listfilters-history-activity-all"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === null,
										'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== null
									})}
									onClick={this.onStatusNull}
								>
									{this.lang.all}
								</button>
								<button
									data-test-id="listfilters-history-activity-open"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === 'open',
										'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== 'open'
									})}
									onClick={this.onStatusOpen}
								>
									{this.lang.open}
								</button>
								<button
									data-test-id="listfilters-history-activity-closed"
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Status.value === 'closed',
										'btn up-btn btn-light-grey no-shadow': filterValue.Status.value !== 'closed'
									})}
									onClick={this.onStatusClosed}
								>
									{this.lang.close}
								</button>
							</div>
						</div>
					) : null}

					{isAppointment ? (
						<div className="history-filter-row">
							<label className="secondary-title">{this.lang.outcome}</label>
							<div className="flex-btn-group outcome">
								<button
									//data-test-id='listfilters-history-activity-all'
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Outcome.value.includes(
											'planned'
										),
										'btn up-btn btn-light-grey no-shadow': !filterValue.Outcome.value.includes(
											'planned'
										)
									})}
									onClick={() => this.onOutcomePlanned(filterValue.Outcome.value)}
								>
									{this.lang.planned}
								</button>
								<button
									//data-test-id='listfilters-history-activity-open'
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Outcome.value.includes(
											'completed'
										),
										'btn up-btn btn-light-grey no-shadow': !filterValue.Outcome.value.includes(
											'completed'
										)
									})}
									onClick={() => this.onOutcomeCompleted(filterValue.Outcome.value)}
								>
									{this.lang.completed}
								</button>
								<button
									//data-test-id='listfilters-history-activity-closed'
									disabled={isInactive}
									className={classes({
										'btn up-btn btn-bright-blue no-shadow': filterValue.Outcome.value.includes(
											'notCompleted'
										),
										'btn up-btn btn-light-grey no-shadow': !filterValue.Outcome.value.includes(
											'notCompleted'
										)
									})}
									onClick={() => this.onOutcomeNotCompleted(filterValue.Outcome.value)}
								>
									{this.lang.notCompleted}
								</button>
							</div>
						</div>
					) : null}
					{!isAppointment ? (
						<div className="history-filter-row thin-row">
							<label className="secondary-title font-size-12">{this.lang.overdueAsClosed}</label>
							<br />
							<ReactTemplates.upFilters.components.toggle
								data-test-id="listfilters-history-activity-overdueasclosed"
								disabled={isInactive}
								className="toggle-lg toggle-bright-blue"
								checked={filterValue.overdueAsClosed}
								onChange={this.onToggleOverdueAsClosed}
							/>
						</div>
					) : null}
					<div className="history-filter-row">
						<label className="secondary-title">{this.lang.date}</label>
						<div
							className={
								'datepicker-group' +
								(filterValue.Date.value.preset === 'custom' ? ' datepicker-custom-visible' : '')
							}
						>
							<div
								className="datepicker-select-wrap"
								data-test-id="listfilters-history-activity-dateselect"
							>
								<ReactTemplates.INPUTS.upSelect
									key={'datepicker-select'}
									disabled={isInactive}
									data={this.datePresets}
									required={true}
									multiple={false}
									className="datepicker-select"
									onChange={this.onDatePresetChanged}
									defaultValue={filterValue.Date.value.preset}
									matcher={function (term, undef, item) {
										return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
									}}
								/>
							</div>
							{filterValue.Date.value.preset === 'custom' ? (
								<div className="datepicker-wrap" key="datepicker-wrap">
									<div id="datepicker-container">
										<ReactTemplates.upFilters.components.datepicker
											disabled={isInactive}
											placeholder={this.lang.from}
											name={'startDate'}
											className="form-control"
											value={filterValue.Date.value.start}
											onChange={this.onFromDateChanged}
										/>
										<ReactTemplates.upFilters.components.datepicker
											disabled={isInactive}
											placeholder={this.lang.to}
											name={'endDate'}
											className="form-control"
											value={filterValue.Date.value.end}
											onChange={this.onToDateChanged}
										/>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="history-filter-row" data-test-id="listfilters-history-activity-types">
						<label className="secondary-title">{this.lang.type}</label>
						<UpActivityTypes
							disabled={isInactive}
							multiple={true}
							entity={isAppointment ? 'appointment' : null}
							className={'form-control'}
							value={activityValues}
							onChange={this.onActivityTypeChanged}
							placeholder={this.lang.selectActivityType}
						/>
					</div>
				</div>
			);
		}
	});
ReactTemplates.upFilters.filterTypes.historyActivityPage = getComponent(false);
ReactTemplates.upFilters.filterTypes.historyAppointmentPage = getComponent(true);

'use strict';

ReactTemplates.form.buttonContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			image: t('bannereditor.image'),
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage'),
			opacity: t('bannerEditor.opacity'),
			title: t('default.title'),
			description: t('default.description'),
			style: t('landingpage.style'),
			subtitle: t('landingpage.subtitle'),
			text: t('bannerEditor.text'),
			background: t('bannerEditor.background'),
			backgroundColor: t('form.backgroundColor'),
			imgSize: t('landingpage.imgSize'),
			textColor: t('editor.color'),
			borderColor: t('form.borderColor'),
			link: t('default.link'),
			fullWidth: t('default.fullWidth'),
			alignment: t('landingpage.align'),
			targetBlank: t('admin.openLinkInNewWin'),
			verticalPadding: t('landingpage.vertical.padding'),
			horizontalPadding: t('landingpage.horizontal.padding'),
			fontSize: t('landingpage.fontSize'),
			header: t('landingpage.header'),
			button: t('bannerEditor.button'),
			buttonText: t('form.buttonText')
		};

		var alignOpts = ['left', 'center', 'right'];
		this.alignOpts = _.map(alignOpts, function (size, i) {
			return (
				<option key={'size' + i} value={size}>
					{t('landingpage.align.' + size)}
				</option>
			);
		});
	},
	valuePropChanged: function (key, e) {
		var element = this.state.element;

		if (key === 'link') {
			if (
				e.target.value !== undefined &&
				e.target.value !== null &&
				!(
					'http://'.indexOf(e.target.value.substring(0, 7)) === 0 ||
					'https://'.indexOf(e.target.value.substring(0, 8)) === 0 ||
					'mailto:'.indexOf(e.target.value.substring(0, 7)) === 0 ||
					'ftp://'.indexOf(e.target.value.substring(0, 6)) === 0
				)
			) {
				e.target.value = 'http://' + e.target.value;
			}
		}

		element.value[key] = e.target.value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	colorChange: function (key, value) {
		var element = this.state.element;

		element.value[key] = value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	toggleFullWidth: function () {
		var element = this.state.element;

		if (element.value.fullWidth) {
			element.value.fullWidth = false;
		} else {
			element.value.fullWidth = true;
		}

		this.setState({ element: element });
		this.props.onChange(element);
	},
	toggleSrcTarget: function () {
		var element = this.state.element;

		if (element.value.srcTarget === '_blank') {
			element.value.srcTarget = '';
		} else {
			element.value.srcTarget = '_blank';
		}

		this.setState({ element: element });
		this.props.onChange(element);
	},
	render: function () {
		var self = this;
		var element = self.state.element;

		var textClass = 'floating-label-textarea';
		if (element.value.text && element.value.text.length) {
			textClass += ' has-value';
		}

		var linkClass = 'floating-label-textarea';
		if (element.value.link && element.value.link.length) {
			linkClass += ' has-value';
		}

		var titleClass = 'floating-label-textarea';
		if (element.value.title && element.value.title.length) {
			titleClass += ' has-value';
		}

		return (
			<div>
				<div className="tool-main-section">
					<div className="tool-header">
						<h3>{self.lang.header}</h3>
					</div>
					<div className="tool-section">
						<div className={titleClass}>
							<label>{self.lang.header}</label>
							<input
								type="text"
								value={element.value.title}
								onChange={self.valuePropChanged.bind(self, 'title')}
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{self.lang.textColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.titleColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'titleColor')
							})}
						</div>
					</div>
				</div>
				<div className="tool-main-section">
					<div className="tool-header">
						<h3>{self.lang.button}</h3>
					</div>
					<div className="tool-section">
						<div className={textClass}>
							<label>{self.lang.buttonText}</label>
							<input
								type="text"
								value={element.value.text}
								onChange={self.valuePropChanged.bind(self, 'text')}
							/>
						</div>
						<div className={linkClass}>
							<label>{self.lang.link}</label>
							<input
								type="text"
								value={element.value.link}
								onChange={self.valuePropChanged.bind(self, 'link')}
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.textColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.buttonTextColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'buttonTextColor')
							})}
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.background}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.buttonBackgroundColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'buttonBackgroundColor')
							})}
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.fontSize}</label>
						<div className="pull-right">
							<Input.input
								type="number"
								value={element.value.fontSize}
								onChange={self.valuePropChanged.bind(self, 'fontSize')}
								className="form-control"
								style={{
									padding: '3px 6px',
									height: '25px',
									textAlign: 'right',
									width: '80px'
								}}
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.borderColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.buttonBorderColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'buttonBorderColor')
							})}
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.fullWidth}</label>
						<div className="pull-right">
							<ReactTemplates.upFilters.components.toggle
								checked={element.value.fullWidth}
								onChange={self.toggleFullWidth}
								className="toggle-bright-blue"
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.targetBlank}</label>
						<div className="pull-right">
							<ReactTemplates.upFilters.components.toggle
								checked={element.value.srcTarget === '_blank'}
								onChange={self.toggleSrcTarget}
								className="toggle-bright-blue"
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.horizontalPadding}</label>
						<div className="pull-right">
							<Input.input
								type="number"
								value={element.value.paddingH}
								onChange={self.valuePropChanged.bind(self, 'paddingH')}
								className="form-control"
								style={{
									padding: '3px 6px',
									height: '25px',
									textAlign: 'right',
									width: '80px'
								}}
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.verticalPadding}</label>
						<div className="pull-right">
							<Input.input
								type="number"
								value={element.value.paddingV}
								onChange={self.valuePropChanged.bind(self, 'paddingV')}
								className="form-control"
								style={{
									padding: '3px 6px',
									height: '25px',
									textAlign: 'right',
									width: '80px'
								}}
							/>
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.alignment}</label>
						<div className="pull-right">
							<select
								className="form-control inline-select input-sm pull-right"
								value={element.value.align}
								onChange={self.valuePropChanged.bind(self, 'align')}
							>
								{self.alignOpts}
							</select>
						</div>
					</div>
					<br clear="all" />
				</div>
			</div>
		);
	}
});

import React from 'react';

const CompanyAddress = ({ city, country }) => {
	return (
		<div id="company-address" className="company-util">
			{country ? <i className={`flag-icon flag-icon-${country.toLowerCase()}`} /> : ''}
			{city}
		</div>
	);
};

export default CompanyAddress;

'use strict';
import logError from 'App/babel/helpers/logError';

angular.module('domain.advancedSearch').directive('customfieldSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/customfieldSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&',
				fieldsEntity: '@fields'
			},
			controller: [
				'$scope',
				'AppService',
				'$attrs',
				'FilterHelper',
				function ($scope, AppService, $attrs, FilterHelper) {
					$scope.inited = false;

					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					var init = function () {
						$scope.customFields = _.filter(AppService.getCustomFields($scope.fieldsEntity), function (cf) {
							return cf.visible && cf.$hasAccess;
						});

						// remove pre-selected values from custom Select
						$scope.customFields = $scope.customFields.map(cf => {
							if (cf.datatype !== 'Select') {
								return cf;
							}
							cf.dropdownDefault = '';
							return cf;
						});

						$scope.titleStr = 'default.otherInfo';
						if ($attrs.title) {
							$scope.titleStr = $attrs.title;
						}

						$scope.hide =
							$scope.fieldsEntity === 'account' &&
							!FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						$scope.inited = true;
					};

					$scope.isActive = function (filter) {
						if (!filter) {
							return false;
						}
						const filterConfig = {
							type: 'custom',
							filterName: filter.filterName,
							$field: { datatype: filter.dataType }
						};
						return !FilterHelper.isInactiveValue(filter, $scope.fieldsEntity, filterConfig);
					};

					AppService.loadedPromise
						.then(function () {
							var unWatchInit = $scope.$watch('Filters', function (filters) {
								if (filters !== undefined) {
									unWatchInit();
									init();
								}
							});
						})
						.catch(error => logError(error, 'Failed to load AppService'));
				}
			]
		};
	}
]);

'use strict';

angular.module('upAttributes').service('OrderStageAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: false,
					selectableColumn: true,
					filterable: false
				},
				'user.id': {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.RefObject,
					filterName: 'userId',
					refParams: {
						type: 'user',
						field: 'name',
						identifyer: 'id',
						refType: 'appointment'
					},
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					groupable: true,
					sortable: false,
					selectableColumn: true
				},
				probability: {
					field: 'probability',
					type: FilterType.Number
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				keys: {
					id: 'id',
					name: 'name',
					user: 'user',
					probability: 'probability'
				}
			};
		};
	}
]);

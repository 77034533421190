import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip, Title, DropDownMenu } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';

import './DisqualifyDropDown.scss';

const DisqualifyDropDown = ({
	className,
	onDisqualify,
	buttonText,
	tooltip,
	tooltipPosition,
	loading,
	disabled,
	align
}) => {
	const classes = new bemClass('DisqualifyDropDown', className);
	return (
		<DropDownMenu
			align={align}
			className={classes.elem('dropdown').b()}
			renderTrigger={(isExpanded, setExpanded) => (
				<div className={classes.elem('disq-wrapper').mod({ expanded: isExpanded }).b()}>
					<Tooltip title={tooltip} position={tooltipPosition}>
						<Button
							className={classes.elem('disq').mod({ open: isExpanded }).b()}
							type="link"
							onClick={setExpanded}
							color="grey"
							loading={loading}
							disabled={disabled}
						>
							<Icon name="thumbs-down" />
						</Button>
					</Tooltip>
				</div>
			)}
		>
			{setExpanded => (
				<div>
					<Title className={classes.elem('dropdown-title').b()} size="sm" bold>{`${tooltip}?`}</Title>
					<Button
						className={classes.elem('disq-confirm').b()}
						block
						shadow="none"
						size="sm"
						onClick={e => {
							setExpanded(false);
							onDisqualify(e);
						}}
					>
						<Icon name="thumbs-down" /> {buttonText}
					</Button>
				</div>
			)}
		</DropDownMenu>
	);
};

DisqualifyDropDown.defaultProps = {
	className: 'DisqualifyDropDown',
	tooltipPosition: 'right',
	align: 'right'
};

DisqualifyDropDown.propTypes = {
	className: PropTypes.string,
	buttonText: PropTypes.string,
	onDisqualify: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	tooltip: PropTypes.string,
	tooltipPosition: PropTypes.oneOf(['right', 'left']).isRequired,
	align: PropTypes.oneOf(['right', 'left']).isRequired
};
window.DisqualifyDropDown = DisqualifyDropDown;
export default DisqualifyDropDown;

import React from 'react';
import t from '../Helpers/translate';
import { Icon, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './AssignModalAssigned.scss';

const AssignModalAssigned = () => {
	const classNames = new BemClass('AssignModalAssigned');
	return (
		<div className={classNames}>
			<Icon name="check" className={classNames.elem('icon')} />
			<Title size="lg" color="grey-10" className={classNames.elem('text').b()}>
				{t('processedBy.activityClosed')}
			</Title>
		</div>
	);
};

export default AssignModalAssigned;

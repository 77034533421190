'use strict';

angular.module('upDirectives').directive('upwLeadScore', function () {
	return {
		restrict: 'A',
		scope: {
			widget: '=upwLeadScore'
		},
		controller: [
			'$scope',
			'$upModal',
			'Account',
			'AppService',
			function ($scope, $upModal, Account, AppService) {
				$scope.customerId = AppService.getCustomerId();
				$scope.score = $scope.widget.score || 0;
				$scope.account = $scope.widget.account;
				$scope.date = $scope.widget.date;
				$scope.loading = true;
				$scope.$on('account.assigned', function (e, res) {
					if (res.account && res.account.id === $scope.account.id) {
						$scope.assigned = res.user;
					}
				});
				$scope.$on('account.unassigned', function (e, accountId) {
					if (accountId === $scope.account.id) {
						$scope.assigned = null;
					}
				});
				$scope.assign = function () {
					$upModal.open('processedBy', { client: $scope.account });
				};
				$scope.init = function () {
					if (!$scope.account) {
						return;
					}
					Account.customer($scope.customerId)
						.getAssigned($scope.account.id)
						.then(function (res) {
							$scope.loading = false;
							if (!res || !res.data) {
								return;
							}
							$scope.assigned = res && res.data ? res.data : null;
						})
						.catch(function (err) {
							$scope.loading = false;
							if (err.status !== 404) {
								$scope.error = err;
							}
						});
				};
				$scope.init();
			}
		],
		replace: true,
		template:
			'<div class="up-widget info" ng-if="score || assigned">' +
			'<span class="title">{{ (widget.title || \'widget.leadScore\') | translate }}</span>' +
			'<span class="value"><b style="vertical-align: top; top: 0px; margin-right: 5px;" class="score-indicator fa fa-tachometer" ng-class="{\'cold\': score >= 0 && score < 5, \'medium\': score >= 5 && score < 20, \'hot\': score >= 20}"></b>{{ $parent.score }}</span>' +
			'<span class="subtitle"" ng-if="!account && $parent.date" bs-tooltip data-title="{{ $parent.$parent.$parent.date }}" data-placement="bottom">{{ \'default.latest\' | translate }}: {{  $parent.$parent.$parent.date | upsalesDate }}</span>' +
			'</div>'
	};
});

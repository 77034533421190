require('../../upsales/patches/fileUploader.js');
require('../../upsales/initSentry.js');
require('../../upsales/app.js');
require('App/babel/angularHelpers/getAngularModule');
require('../../upsales/main.js');
require('../../upsales/sidebar.js');
require('../../upsales/common/services/tools.js');

require('../../bower_components/angular-strap/dist/angular-strap.tpl.min.js');

require('../../upsales/common/services/tools.js');

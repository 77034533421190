'use strict';
import PropTypes from 'prop-types';

ReactTemplates.TOOLS.Paginator = window.ReactCreateClass({
	propTypes: {
		max: PropTypes.number.isRequired,
		maxVisible: PropTypes.number,
		onChange: PropTypes.func.isRequired,
		total: PropTypes.number
	},
	componentDidUpdate: function (prevProps, prevState) {
		if (prevState.currentPage !== this.state.currentPage) {
			this.props.onChange(this.state.currentPage);
		}
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.currentPage !== this.state.currentPage || nextProps.total !== this.state.total) {
			this.setState({
				currentPage: nextProps.currentPage,
				total: nextProps.total
			});
		}
	},
	getDefaultProps: function () {
		return {
			maxVisible: 5
		};
	},
	getInitialState: function () {
		return {
			currentPage: this.props.currentPage || 1,
			total: 0,
			items: []
		};
	},
	goTo: function (page) {
		this.setState({ currentPage: page });
	},

	onClickNext: function () {
		var page = this.state.currentPage;

		if (page < this.props.max) {
			this.goTo(page + 1);
		}
	},
	onClickPrev: function () {
		if (this.state.currentPage > 1) {
			this.goTo(this.state.currentPage - 1);
		}
	},
	render: function () {
		var className = this.props.className || '',
			p = this.props,
			s = this.state;
		var maxVisible = p.maxVisible;

		if (maxVisible > p.max) {
			maxVisible = p.max;
		}

		var startPage = Math.max(s.currentPage - Math.floor(maxVisible) + 1, 1);
		var endPage = s.currentPage < p.max && startPage + maxVisible - 1 <= p.max ? startPage + maxVisible - 1 : p.max;
		var iterator = _.range(startPage, endPage + 1);

		var lastElement = null;
		var firstElement = null;
		var dotElAfter = null;
		var dotElBefore = null;

		var lastPageNotVisible = iterator.indexOf(p.max) === -1;
		var firstPageNotVisible = iterator.indexOf(1) === -1;
		if ((firstPageNotVisible && lastPageNotVisible) || iterator.length > maxVisible) {
			var newStartPage = startPage + Math.floor(maxVisible / 2);
			startPage = newStartPage > 1 ? newStartPage : 1;
			endPage =
				s.currentPage < p.max && startPage + maxVisible - 1 <= endPage ? startPage + maxVisible - 1 : p.max;
			iterator = _.range(startPage, endPage + 1);
			iterator = iterator.slice(0, maxVisible);
			lastPageNotVisible = iterator.indexOf(p.max) === -1;
			firstPageNotVisible = iterator.indexOf(1) === -1;
		}

		if (lastPageNotVisible) {
			var nextNr = iterator[iterator.length - 1] + 1;
			if (nextNr < p.max) {
				dotElAfter = (
					<li key={nextNr} onClick={this.goTo.bind(this, nextNr)}>
						<a>{'...'}</a>
					</li>
				);
			}

			lastElement = (
				<li key={p.max} onClick={this.goTo.bind(this, p.max)}>
					<a>{p.max}</a>
				</li>
			);
		}

		if (firstPageNotVisible) {
			var prevNr = iterator[0] - 1;
			if (prevNr > 1) {
				dotElBefore = (
					<li key={prevNr} onClick={this.goTo.bind(this, prevNr)}>
						<a>{'...'}</a>
					</li>
				);
			}

			firstElement = (
				<li key={1} onClick={this.goTo.bind(this, 1)}>
					<a>{'1'}</a>
				</li>
			);
		}

		// Do not show if only one page
		if (iterator.length <= 1) {
			iterator = [];
		}

		return (
			<div className="up-paginator-wrap">
				<ul className={'pagination up-paginator ' + className}>
					{firstElement}
					{dotElBefore}
					{iterator.map(function (page) {
						return (
							<li
								key={page}
								onClick={this.goTo.bind(this, page)}
								className={s.currentPage === page ? 'active' : ''}
							>
								<a>{page}</a>
							</li>
						);
					}, this)}
					{dotElAfter}
					{lastElement}
				</ul>
			</div>
		);
	}
});

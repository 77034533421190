import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import HistoryLog from './HistoryLog';
import { EVENT_TIMELINE_ENTITY_TYPES, EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import { getTimelineRow } from '../../babel/components/Helpers/getTimelineRow';
import T from 'Components/Helpers/translate';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import ComparisonTypes from 'Resources/ComparisonTypes';
import TimelineGenericRender, {
	TimelineConfig,
	TimelineComponentProps
} from 'Components/TimelineRow/TimelineGeneric/TimelineCardConfigs';
import Client from 'App/resources/Model/Client';
import type { HistoryLogRenderedBy } from 'App/components/HistoryLog/ModalHistoryLog';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore/TimelineLoadMore';
import RequestBuilder from 'Resources/RequestBuilder';
import { makeCancelable } from 'Helpers/promise';
import HistoryLogFilterButtons from './HistoryLogFilterButtons';
import Event from 'App/resources/Model/Event';

const listeners = [
	'activity.added',
	'activity.deleted',
	'activity.updated',
	'appointment.added',
	'appointment.deleted',
	'appointment.updated',
	'comment.added',
	'comment.deleted',
	'comment.updated'
];

const historyTypes = ['all', 'form', 'marketingCustom', 'visit', 'mail'] as const;
type HistoryType = (typeof historyTypes)[number];
type HistoryTypeCounts = { [k in HistoryType]?: number };

const fetcher: FetcherFunction<{ client: Client; historyType?: HistoryType; onlyMetadata?: boolean }, Event> = (
	filters,
	{ client, historyType, onlyMetadata = false }
) => {
	const event = Tools.Event;

	const historyTypeFilters: Record<string, () => void> = {
		form: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Form']),
		marketingCustom: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['MarketingCustom']),
		visit: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Visit']),
		mail: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Mail', 'MailCampaign'])
	};

	if (historyType && historyType in historyTypeFilters) {
		historyTypeFilters[historyType]?.();
	}

	filters
		.addFilter(event.attr.clientId, ComparisonTypes.Equals, client.id)
		.addFilter({ field: 'feedMarket' }, ComparisonTypes.Equals, null)
		.addSort(event.attr.date, false);

	// filters.addFilter({ field: 'feedMarketWithNoScore' }, ComparisonTypes.Equals, null);
	// if (onlyZero) {
	// 	filters.addFilter(event.attr.score, ComparisonTypes.Equals, 0);
	// }

	if (onlyMetadata) {
		filters.limit = 0;
	}

	return event.find(filters.build());
};

type Props = {
	client: Client;
	notesFilterActive?: boolean;
	renderedBy: HistoryLogRenderedBy;
	cardAdditionalProps?: Partial<TimelineComponentProps>;
} & PartialPick<ComponentProps<typeof HistoryLog>, 'selectButtons'>;

const MarketHistoryLog = ({ client, notesFilterActive = true, renderedBy, cardAdditionalProps, ...props }: Props) => {
	const [historyType, setHistoryType] = useState<HistoryType>('all');
	const [metadata, setMetadata] = useState<HistoryTypeCounts>({ all: 0 });

	const filterButtons = [
		{ title: T('default.all'), value: 'all' },
		{ title: T('event.types.Form'), value: 'form', icon: 'file-text' },
		{ title: T('mail.sentMails'), value: 'mail', icon: 'envelope' },
		{ title: T('event.types.Visit'), value: 'visit', icon: 'globe' },
		{ title: T('event.types.MarketingCustom'), value: 'marketingCustom', icon: 'bullseye' }
	] as const;

	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore,
		reset
	} = usePartialLoader({
		fetcher,
		broadcastTypes: listeners,
		fetcherProps: {
			client,
			historyType
		}
	});

	const containerRef = useRef<HTMLDivElement>(null);

	// Get metadata
	useEffect(() => {
		const promise = makeCancelable(
			Promise.all(
				historyTypes.map(async type => {
					return fetcher(new RequestBuilder(), { client, historyType: type, onlyMetadata: true }).then(
						res => {
							return { type, count: res.metadata.total };
						}
					);
				})
			).then(res => {
				const metadata = res.reduce((acc, cur) => {
					acc[cur.type] = cur.count;
					return acc;
				}, {} as Record<HistoryType, number>);
				setMetadata(metadata);
			})
		);

		return () => {
			promise.cancel();
		};
	}, []);

	useEffect(() => {
		reset();
	}, [historyType]);

	if (!client || !client.id) {
		return null;
	}
	const timelineElements =
		events?.map(event => {
			return event ? (
				<div key={event.id}>
					{getTimelineRow(event, 'Client', notesFilterActive, false, renderedBy, cardAdditionalProps)}
				</div>
			) : null;
		}) ?? [];

	const clientRegEvent = {
		entityType: EVENT_TIMELINE_ENTITY_TYPES.CLIENT,
		subType: EVENT_TIMELINE_SUB_TYPES.CREATED,
		entityId: client.id,
		users: client.regBy ? [client.regBy] : [],
		date: client.regDate
	};

	return (
		<div className="MarketHistoryLog" ref={containerRef}>
			<HistoryLog
				title={T('default.marketEvents')}
				loading={loading}
				{...props}
				selectButtons={
					<HistoryLogFilterButtons
						buttons={filterButtons}
						containerRef={containerRef}
						metadata={metadata}
						historyType={historyType}
						setHistoryType={setHistoryType}
					/>
				}
			>
				{timelineElements}
				{timelineElements.length < totalEvents ? (
					<TimelineLoadMore
						newEventsLoading={loading}
						numEventsLoaded={events.length}
						totalEvents={totalEvents ? totalEvents : null}
						incrementOffset={loadMore}
					/>
				) : null}
				<TimelineGenericRender
					type={TimelineConfig.AddedClient}
					event={clientRegEvent}
					includeIcon
					hideLine
					{...cardAdditionalProps}
				/>
			</HistoryLog>
		</div>
	);
};

export default MarketHistoryLog;

import Client from 'App/resources/Client';
import DetectedDuplicate from 'App/babel/resources/DetectedDuplicate';
import LocalStorage from 'Components/Helpers/LocalStorage';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import openModal from 'App/services/Modal';
import { getMatchRequestBuilder } from 'App/components/CleanMyData/Helpers/matcherHelpers';
import { makeCancelable } from 'App/babel/helpers/promise';
import { useMemo, useEffect, useState } from 'react';
import type { Self } from 'App/resources/AllIWant';

async function getLatestDuplicate(entity: string) {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'ignore' }, comparisonTypes.Equals, false);
	rb.addSort({ field: 'regDateTime' }, false);
	rb.limit = 1;
	rb.fields = ['regDateTime'];
	const filter = rb.build();
	filter.entity = entity;
	const {
		data: [duplicateItem]
	} = await DetectedDuplicate.find(filter);
	return duplicateItem ? moment(duplicateItem.regDateTime) : null;
}

async function getLatestUnwashed() {
	const rb = getMatchRequestBuilder();
	rb.addSort({ field: 'regDate' }, false);
	rb.limit = 1;
	rb.fields = ['regDate'];
	const {
		data: [washItem]
	} = await Client.find(rb.build());
	return washItem ? moment(washItem.regDate) : null;
}

const useCleanMyDataNotification = (self: Self | null): [boolean, () => void] => {
	const [shouldShowNotification, setShouldShowNotification] = useState(false);
	const lastClickedOnCleanMyData = useMemo(() => {
		const localStorage = new LocalStorage();
		const storedValue = localStorage.getValueRaw('haveClickedOnCleanMyData') as string | null;
		return storedValue && moment(storedValue).isValid() ? moment(storedValue) : null;
	}, []);

	const openCleaningModal = () => {
		if (self?.administrator) {
			const localStorage = new LocalStorage();
			localStorage.setValueRaw('haveClickedOnCleanMyData', moment().format());
			setShouldShowNotification(false);
			openModal('CleanMyData');
		}
	};

	useEffect(() => {
		if (self?.administrator) {
			const { promise, cancel } = makeCancelable(
				Promise.all([getLatestDuplicate('Client'), getLatestDuplicate('Contact'), getLatestUnwashed()])
			);

			promise
				.then(result => {
					const shouldShowNotification = result.some(dateOrNull => {
						if (!dateOrNull) {
							return false;
						} else if (!lastClickedOnCleanMyData) {
							return true;
						} else {
							return dateOrNull.isAfter(lastClickedOnCleanMyData);
						}
					});
					setShouldShowNotification(shouldShowNotification);
				})
				.catch((error: unknown) => logError(error, 'useCleanMyDataNotification'));

			return () => cancel();
		}
	}, []);

	return [shouldShowNotification, openCleaningModal];
};

export default useCleanMyDataNotification;

'use strict';

const { default: Signals } = require('Resources/Signals');

angular.module('upDirectives').directive('upEventNews', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventNews.tpl.html?file'),
		require: 'upEventNews',
		scope: {
			event: '=upEventNews'
		},
		controller: [
			'$rootScope',
			'$scope',
			function ($rootScope, $scope) {
				$scope.addToEvents = function (event) {
					Signals.pin(
						event.clientId || event.client.id,
						event.news ? event.news.entityId : event.entityId,
						event.date
					);
				};

				$scope.removeFromEvents = function (event) {
					Signals.unpin(event.news ? event.id : event.eventId);
				};

				$scope.open = function (url) {
					window.open(url, '_blank');
				};
			}
		]
	};
});

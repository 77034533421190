'use strict';
angular.module('domain.engage', ['upResources', 'security.authorization', 'ui.router']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		$stateProvider.state('listAds', {
			templateUrl: require('App/upsales/domain/engage/views/list.html?file'),
			url: '/:customerId/engage?tab',
			controller: 'ListAds as ListAdsCtrl',
			section: 'market',
			resolve: {
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				authRights: [
					'$routeGuard',
					'$stateParams',
					function ($routeGuard, $stateParams) {
						return $routeGuard.requireAdminOrMailAdmin($stateParams);
					}
				],
				meta: [
					'ListAdsMeta',
					'$stateParams',
					function (ListAdsMeta, $stateParams) {
						return ListAdsMeta($stateParams);
					}
				],
				isAvailable: [
					'FeatureHelper',
					'$q',
					function (FeatureHelper, $q) {
						if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
							return $q.reject();
						}
					}
				]
			}
		});

		$stateProvider.state('editAd', {
			templateUrl: require('App/upsales/domain/engage/views/edit.html?file'),
			url: '/:customerId/engage/edit/:id?copy?fromStorage',
			controller: 'EditAd as EditAdCtrl',
			section: 'market',
			resolve: {
				isAvailable: [
					'FeatureHelper',
					'$q',
					function (FeatureHelper, $q) {
						if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
							return $q.reject();
						}
					}
				],
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				authRights: [
					'$routeGuard',
					'$stateParams',
					function ($routeGuard, $stateParams) {
						return $routeGuard.requireAdminOrMailAdmin($stateParams);
					}
				],
				meta: [
					'EditAdMeta',
					'$stateParams',
					function (EditAdMeta, $stateParams) {
						return EditAdMeta($stateParams);
					}
				]
			}
		});

		$stateProvider.state('creativeEditor', {
			templateUrl: require('App/upsales/domain/engage/views/creativeEditor.html?file'),
			url: '/:customerId/engage/creativeEditor/:id?templateId?resolveKey',
			controller: 'CreativeEditor as CreativeEditor',
			section: 'market',
			resolve: {
				isAvailable: [
					'FeatureHelper',
					'$q',
					function (FeatureHelper, $q) {
						if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
							return $q.reject();
						}

						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ADS);
					}
				],
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				authRights: [
					'$routeGuard',
					'$stateParams',
					function ($routeGuard, $stateParams) {
						return $routeGuard.requireAdminOrMailAdmin($stateParams);
					}
				],
				meta: [
					'$stateParams',
					'CreativeEditorMeta',
					function ($stateParams, CreativeEditorMeta) {
						return CreativeEditorMeta($stateParams);
					}
				]
			}
		});

		$stateProvider.state('selectCreativeTemplate', {
			templateUrl: require('App/upsales/domain/engage/views/selectCreativeTemplate.html?file'),
			url: '/:customerId/engage/selectCreativeTemplate/?resolveKey',
			controller: 'SelectCreativeTemplate as SelectCreativeTemplate',
			section: 'market',
			resolve: {
				isAvailable: [
					'FeatureHelper',
					'$q',
					function (FeatureHelper, $q) {
						if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
							return $q.reject();
						}

						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ADS);
					}
				],
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				authRights: [
					'$routeGuard',
					'$stateParams',
					function ($routeGuard, $stateParams) {
						return $routeGuard.requireAdminOrMailAdmin($stateParams);
					}
				],
				meta: [
					'$stateParams',
					'SelectCreativeTemplateMeta',
					function ($stateParams, SelectCreativeTemplateMeta) {
						return SelectCreativeTemplateMeta($stateParams);
					}
				]
			}
		});

		$stateProvider
			.state('viewAd', {
				templateUrl: require('App/upsales/domain/engage/views/view.html?file'),
				url: '/:customerId/engage/:id',
				controller: 'ViewAd as ViewAdCtrl',
				section: 'market',
				abstract: true,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						'$q',
						function (FeatureHelper, $q) {
							if (FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING')) {
								return $q.reject();
							}
						}
					],
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					authRights: [
						'$routeGuard',
						'$stateParams',
						function ($routeGuard, $stateParams) {
							return $routeGuard.requireAdminOrMailAdmin($stateParams);
						}
					],
					meta: [
						'ViewAdMeta',
						'$stateParams',
						function (ViewAdMeta, $stateParams) {
							return ViewAdMeta($stateParams);
						}
					]
				}
			})
			.state('viewAd.dashboard', {
				templateUrl: require('App/upsales/domain/engage/views/viewDashboard.html?file'),
				url: '',
				controller: 'ViewAdDashboard as ViewAdDashboardCtrl',
				section: 'market'
			})
			.state('viewAd.accounts', {
				templateUrl: require('App/upsales/domain/engage/views/viewAccounts.html?file'),
				url: '/accounts/',
				controller: 'ViewAdAccounts as ViewAdAccountsCtrl',
				section: 'market'
			})
			.state('viewAd.creatives', {
				templateUrl: require('App/upsales/domain/engage/views/viewCreatives.html?file'),
				url: '/creatives/',
				controller: 'ViewAdCreatives as ViewAdCreativesCtrl',
				section: 'market'
			});

		$upModalProvider
			.modal('selectCreatives', {
				templateUrl: require('App/upsales/common/components/ui/modals/templates/selectCreatives.tpl.html?file'),
				controller: 'SelectCreatives as SelectCreativesCtrl',
				closeOnCurtain: true,
				style: 'default',
				constantHeight: 'calc(100vh - 150px)'
			})
			.modal('configureAdTargets', {
				templateUrl: require('App/upsales/common/components/ui/modals/templates/configureAdTargets.tpl.html?file'),
				controller: 'ConfigureAdTargets as ConfigureAdTargetsCtrl',
				closeOnCurtain: true
			});
	}
]);

'use strict';

angular.module('domain.advancedSearch', []).config([
	'$stateProvider',
	function ($stateProvider) {
		var setLoading = function ($timeout) {
			var mainContent = document.getElementById('main-content');
			mainContent.classList.add('loading-view');
			return $timeout(function () {}, 100);
		};
		setLoading.$inject = ['$timeout'];

		var sectionFn = function (accountSelf) {
			if (!accountSelf.products.crm) {
				return 'market';
			}
			return 'sale';
		};

		// States
		$stateProvider
			.state('advancedSearch', {
				abstract: true,
				templateProvider: (FeatureHelper, $http, $templateCache, $q) => {
					if (FeatureHelper.hasSoftDeployAccess('ADVANCED_SEARCH_REACT')) {
						return $q.resolve('<div></div>');
					} else {
						const url = require('App/upsales/domain/advanced-search/views/advancedSearch.html?file');
						const promise = $http.get(url).then(function (res) {
							$templateCache.put(url, res.data);
							return res.data;
						});
						return promise;
					}
				},
				url: '/:customerId/advanced-search/',
				controller: 'AdvancedSearch as AdvancedSearch',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ADVANCED_SEARCH);
						}
					],
					segment: [
						'SegmentHelper',
						function (SegmentHelper) {
							SegmentHelper.track('View advanced search', {
								module: SegmentHelper.module.advancedSearch
							});
						}
					]
				}
			})
			.state('advancedSearch.account', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchAccount.html?file'),
				url: 'accounts/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchAccount as AdvancedSearchAccount',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('advancedSearch.contact', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchContact.html?file'),
				url: 'contacts/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchContact as AdvancedSearchContact',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('advancedSearch.activity', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchActivity.html?file'),
				url: 'activities/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchActivity as AdvancedSearchActivity',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			})
			.state('advancedSearch.appointment', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchAppointment.html?file'),
				url: 'appointments/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchAppointment as AdvancedSearchAppointment',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			})
			.state('advancedSearch.opportunity', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchOpportunity.html?file'),
				url: 'opportunities/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchOpportunity as AdvancedSearchOpportunity',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PIPELINE);
						}
					]
				}
			})
			.state('advancedSearch.order', {
				templateUrl: require('App/upsales/domain/advanced-search/views/advancedSearchOrder.html?file'),
				url: 'orders/',
				section: 'sale',
				sectionFn: sectionFn,
				controller: 'AdvancedSearchOrder as AdvancedSearchOrder',
				params: {
					notificationEntityId: null
				},
				resolve: {
					setLoading: setLoading,
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ORDERS);
						}
					]
				}
			});
	}
]);

'use strict';

angular.module('upResources').factory('Links', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function Links($resource, $q, URL, API, ResourceHelper) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'dynamiclink',
			createSuccessBody: 'saved.dynamiclink',
			updateSuccessBody: 'updated.dynamiclink',
			deleteSucccessBody: 'deleted.dynamiclink',
			createErrorBody: 'saveError.dynamiclink',
			updateErrorBody: 'saveError.dynamiclink',
			deleteErrorBody: 'deleteError.dynamiclink'
		});

		var LinkAdmin = $resource(
			URL + API + 'link/:id',
			{},
			{
				update: { method: 'PUT' },
				delete: { method: 'DELETE' },
				create: { method: 'POST' },
				list: { method: 'GET' },
				get: { method: 'GET' }
			}
		);

		var Model = {
			new: function () {
				return {
					color: '002f65',
					entity: 'client',
					href: '',
					icon: 'fa-car',
					target: '_self',
					tooltip: '',
					value: ''
				};
			},
			admin: function () {
				var instance = {};

				instance.list = function () {
					return LinkAdmin.list().$promise;
				};

				instance.get = function (id) {
					return LinkAdmin.get({ id: id }).$promise;
				};

				instance.save = function (link, options) {
					var defered = $q.defer();

					if (!link) {
						return defered.reject('No link');
					}

					if (link.id) {
						LinkAdmin.update({ id: link.id }, link, defered.resolve, defered.reject);
					} else {
						LinkAdmin.create(link, defered.resolve, defered.reject);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !link.id);
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !link.id);
						});

					return defered.promise;
				};

				instance.delete = function (obj, options) {
					if (!obj.id) {
						return;
					}

					return LinkAdmin.delete({ id: obj.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, obj);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},

			customer: function (customer) {
				var instance = {};

				var Links = {
					client: $resource(
						URL + API + customer + '/links/client/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					sidebar: $resource(
						URL + API + customer + '/links/sidebar/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					user: $resource(
						URL + API + customer + '/links/user/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					contact: $resource(
						URL + API + customer + '/links/contact/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					project: $resource(
						URL + API + customer + '/links/project/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					order: $resource(
						URL + API + customer + '/links/order/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					opportunity: $resource(
						URL + API + customer + '/links/opportunity/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					activity: $resource(
						URL + API + customer + '/links/activity/:id',
						{},
						{
							get: { method: 'GET' }
						}
					),
					appointment: $resource(
						URL + API + customer + '/links/appointment/:id',
						{},
						{
							get: { method: 'GET' }
						}
					)
				};

				instance.get = function (entity, id) {
					return Links[entity].get({ id: id }).$promise;
				};

				return instance;
			}
		};

		return Model;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Text, Button } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import OpportunityRowPopUp from './OpportunityRowPopUp';
import T from 'Components/Helpers/translate';
import OrderType from 'App/resources/Model/Order';
import moment from 'moment';

import './OpportunityRow.scss';

type Props = {
	closeDate: OrderType['date'];
	opportunity: OrderType;
	overrideOpportunity: (obj: object) => void;
	updateProbability: (probability: number) => void;
	setCreateData: (data: { description: string; type: string }) => void;
	disabled?: boolean;
};

type State = { showButtons?: boolean; showPopUp?: boolean; clicked?: boolean };

class OpportunityRowExpired extends React.Component<Props, State> {
	lang: { oneDay: string; multiple: string };
	timeout?: ReturnType<typeof setTimeout>;

	static propTypes = {
		closeDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
		opportunity: PropTypes.object.isRequired,
		overrideOpportunity: PropTypes.func,
		updateProbability: PropTypes.func,
		setCreateData: PropTypes.func,
		disabled: PropTypes.bool
	};

	constructor(p: Props) {
		super(p);

		this.state = {
			showPopUp: false,
			showButtons: true
		};

		this.lang = {
			oneDay: 'opportunity.passedCloseDateOneDay',
			multiple: 'opportunity.passedCloseDate'
		};
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	calcDays(closeDate: OrderType['date']) {
		return moment().diff(moment(closeDate), 'days');
	}

	render() {
		const classes = new bemClass('OpportunityRow');

		const { closeDate, opportunity, overrideOpportunity, updateProbability, setCreateData, disabled } = this.props;
		const { showButtons, showPopUp } = this.state;

		const daysPassed = this.calcDays(closeDate);

		return (
			<Card className={classes.b()} color="super-light-red">
				<Icon className={classes.elem('iconTitle').b()} color={'red'} name={'exclamation-triangle'} />
				<div className={classes.elem('con').b()}>
					<Text className={classes.elem('description').b()}>
						{T(this.lang[daysPassed === 1 ? 'oneDay' : 'multiple'], {
							name:
								Tools.AppService.getSelf().id === opportunity.user.id
									? T('you')
									: opportunity.user.name,
							days: daysPassed
						})}
					</Text>
				</div>

				{!disabled && showButtons ? (
					<React.Fragment>
						<Button
							type="link"
							className={classes.elem('viewOptions').mod({ popUp: showPopUp }).b()}
							onClick={() => {
								this.setState({ showPopUp: true });
							}}
						>
							<Text>{T('opportunity.options')}</Text>
						</Button>
					</React.Fragment>
				) : null}
				{showPopUp ? (
					<OpportunityRowPopUp
						opportunity={opportunity}
						close={() => {
							this.setState({ showPopUp: false });
							this.timeout = setTimeout(() => {
								this.setState({ clicked: false });
							}, 300);
						}}
						startState={5}
						overrideOpportunity={overrideOpportunity}
						hideButtons={() => this.setState({ showButtons: false })}
						updateProbability={updateProbability}
						daysPassed={daysPassed}
						setCreateData={setCreateData}
					/>
				) : null}
			</Card>
		);
	}
}

export default OpportunityRowExpired;

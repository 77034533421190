import _ from 'lodash';
import OrderStage from '../Model/OrderStage';

type RawOrderStage = Optional<OrderStage, '$hasAccess' | '$sortId'>;

// Return true ore false if the user can se the stage
const hasRoleAccess = function (field: RawOrderStage, roleId: number | null, admin: boolean) {
	if (admin || !field.roles?.length || _.find(field.roles, { id: roleId })) {
		return true;
	}

	return false;
};

export default function (stage: RawOrderStage) {
	const self = Tools.AppService.getSelf();
	stage.$hasAccess = hasRoleAccess(stage, self.role?.id || null, self.administrator);

	// Set $sortId
	if (stage.probability === 0) {
		// We want 0 probability stages in the bottom
		stage.$sortId = 101;
	} else {
		stage.$sortId = stage.probability;
	}

	return stage as OrderStage;
}

import React from 'react';
import PropTypes from 'prop-types';
// import RangeSlider, {ABTestingLabel} from '@upsales/components/RangeSlider';

import './ABTestingPopupPreview.scss';
import groupPaths from './groupPaths.svg';

export default class ABTestingPopupPreview extends React.Component {
	render() {
		const { color, mainTitle, contacts, variantA, variantB } = this.props;

		return (
			<div className="preview-content abtesting-preview-content">
				<div className="preview-content-abtesting-wrap">
					<div className="preview-left">
						<div className="step-type">
							<img src="img/split-test-flow.svg" />
							<div className={`text-${color}`}>
								{Tools.$translate('flow.action')}
								{':'}
							</div>
							<div>{mainTitle}</div>
						</div>
						<div className="action-rows">
							<div className="block">
								<i className="fa fa-users" />
								<span className="spacing">{contacts}</span>
								{Tools.$translate('contacts').toLowerCase()}
							</div>
							<img src={groupPaths} />
							<div className="block">
								<div className="stats stats-a">
									{Tools.$translate('flow.abtesting.variantA')}
									<span className="percent">{variantA.value + '%'}</span>
								</div>
								<div className="stats stats-b">
									{Tools.$translate('flow.abtesting.variantB')}
									<span className="percent">{variantB.value + '%'}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ABTestingPopupPreview.defaultProps = {
	color: 'bright-blue'
};

ABTestingPopupPreview.propTypes = {
	color: PropTypes.string,
	mainTitle: PropTypes.string,
	value: PropTypes.string,
	contacts: PropTypes.number,
	variantA: PropTypes.object,
	variantB: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import AccountManagerRow from '../AccountManagerRow';
import GenericHref from '../Helpers/Hrefs/GenericHref';

class AnonListRow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			toggleDropdown: false
		};

		this.toggleDropdown = this.toggleDropdown.bind(this);
	}

	toggleDropdown() {
		this.setState({
			toggleDropdown: !this.state.toggleDropdown
		});
	}

	formatEmail(email) {
		if (email) {
			return email;
		}

		return <i className="grey">{Tools.$translate('default.noEmail')}</i>;
	}

	generateSingleRow(opts) {
		return (
			<tr>
				<td colSpan={5} style={{ textAlign: 'center', height: '80px' }}>
					<h4 style={{ margin: 0, lineHeight: '30px' }}>{opts.title}</h4>
					<p style={{ margin: 0, lineHeight: '20px' }}>{opts.subTitle}</p>
				</td>
			</tr>
		);
	}

	generateLoadingRow(opts) {
		return (
			<tr>
				<td colSpan={5} style={{ textAlign: 'center', height: '80px' }}>
					<Loader noU={true} style={{ width: '40px', height: '40px' }} />
					<p style={{ margin: 0, lineHeight: '20px' }}>{opts.title}</p>
				</td>
			</tr>
		);
	}

	beforeGenerateSingleRow(type) {
		if (type === 'compliant') {
			return this.generateSingleRow({
				title: Tools.$translate('admin.anonymization.compliant.title'),
				subTitle: Tools.$translate('admin.anonymization.compliant.subtitle')
			});
		}

		if (type === 'loading') {
			return this.generateLoadingRow({
				title: Tools.$translate('default.loadingEllipsis')
			});
		}

		return this.generateSingleRow({
			title: Tools.$translate('admin.anonymization.noRules'),
			subTitle: Tools.$translate('admin.anonymization.noRules.subTitle')
		});
	}

	fetchCorrectSortingIcon(isSortable, sorting) {
		if (isSortable && sorting === this.props.sorting && this.props.desc === true) {
			return <i className="fa sorting-indicator fa-sort-alpha-desc" />;
		} else if (isSortable && sorting === this.props.sorting && this.props.desc === false) {
			return <i className="fa sorting-indicator fa-sort-alpha-asc" />;
		}

		if (isSortable && sorting !== this.props.sorting) {
			return <i className="fa sorting-indicator fa-sort" />;
		}

		return null;
	}

	thItem(text, sortingColumn) {
		var isSortable = typeof sortingColumn !== 'undefined';
		var classNames = '';

		if (isSortable) {
			classNames += 'sortable-th';
		}

		if (this.props.sorting === sortingColumn) {
			classNames += ' sorting-active';
		}

		return (
			<th
				className={classNames}
				onClick={() => {
					this.props.setSort(sortingColumn);
				}}
			>
				{text}
				{this.fetchCorrectSortingIcon(isSortable, sortingColumn)}
			</th>
		);
	}

	generateTH() {
		var dropdownClasses = 'dropdown-checkmark dropdown';
		if (this.state.toggleDropdown) {
			dropdownClasses += ' open';
		}

		return (
			<tr>
				<th className={dropdownClasses} onClick={this.toggleDropdown}>
					<div
						className="checkbox-curtain"
						style={{ position: 'absolute', zIndex: 5, width: '16px', height: '17px' }}
					/>
					<ReactTemplates.checkbox
						checked={!!this.props.checkCheckbox}
						disabled={this.props.selectingAll}
						loading={this.props.selectingAll}
					/>
					<i style={{ marginLeft: '5px', lineHeight: '17px' }} className="fa fa-angle-down" />
					<ul className="dropdown-menu">
						<li onClick={() => this.props.check(null, 'all')}>
							<span>
								{Tools.$translate('default.selectAll')} {'(' + this.props.buttonTextNumber + ')'}
							</span>
						</li>
						<li onClick={() => this.props.check(null, true)}>
							<span>{Tools.$translate('default.selectAllOnPage')}</span>
						</li>
						<li onClick={() => this.props.check(null, false)}>
							<span>{Tools.$translate('default.deselectAll')}</span>
						</li>
					</ul>
				</th>

				{this.thItem(Tools.$translate('tag.group.Contact'), 'contact.name')}
				{this.thItem(Tools.$translate('default.email2'), 'contact.email')}
				{this.thItem(Tools.$translate('default.created'), 'contact.regDate')}
				{this.thItem(Tools.$translate('default.accountManager'))}
			</tr>
		);
	}

	leadContact(customerId, item) {
		// TO DO: Remove the fucking link if you've been adding 500+ contacts to the list, and you missclick
		// then you're gonna pull all your hair from your entire body, throw fucks at the screen
		// and then go away.
		return (
			<span className="lead-contact">
				<GenericHref
					target="_blank"
					type={'contacts'}
					customerId={customerId}
					id={item.id}
					text={item.name}
					style={{ display: 'block' }}
				/>
				<span className="lead-contact-account" style={{ marginTop: '-2px', display: 'block' }}>
					<GenericHref
						target="_blank"
						type={'accounts'}
						customerId={customerId}
						id={item.client_id ? item.client_id : '0'}
						text={item.client_name ? item.client_name : '-'}
					/>
				</span>
			</span>
		);
	}

	generateTD(opts) {
		return (
			<tr>
				<td>
					<ReactTemplates.checkbox
						disabled={opts.everyThingSelected}
						checked={opts.checked}
						onChange={() => this.props.check(opts.item)}
					/>
				</td>
				<td key={opts.key}>{this.leadContact(opts.customerId, opts.item)}</td>
				<td>{this.formatEmail(opts.item.email)}</td>
				<td>
					{opts.item.regDate ? (
						moment(opts.item.regDate).format('L')
					) : (
						<i className="grey">{Tools.$translate('default.noDate')}</i>
					)}
				</td>
				<td>
					{opts.item.user_id && opts.item.user_name ? (
						<AccountManagerRow
							user={{ id: opts.item.user_id, name: opts.item.user_name }}
							style={{ display: 'flex', alignItems: 'center' }}
							avatarStyle={{ marginTop: 0, width: 'auto', minWidth: '25px' }}
						/>
					) : (
						<i className="grey">{Tools.$translate('default.noAccountManager')}</i>
					)}
				</td>
			</tr>
		);
	}

	row(checked) {
		const { type, item, everyThingSelected } = this.props;
		const customerId = Tools.AppService.getCustomerId();

		switch (type) {
			case 'none':
			case 'compliant':
			case 'loading':
				return this.beforeGenerateSingleRow(type);

			case 'th':
				return this.generateTH();

			default: {
				const key = 'column-' + item.id + '_' + item.name;
				return this.generateTD({
					item: item,
					key: key,
					checked: checked,
					customerId: customerId,
					everyThingSelected: everyThingSelected
				});
			}
		}
	}

	render() {
		const { selectedContacts, item, type } = this.props;
		if (type === 'th' || type === 'compliant' || type === 'none' || type === 'loading') {
			return this.row();
		}

		const checked = selectedContacts.indexOf(item.id) > -1 ? true : false;
		return this.row(checked);
	}
}

AnonListRow.getDefaultProps = {
	type: 'td'
};

AnonListRow.propTypes = {
	rootData: PropTypes.shape({
		pageData: PropTypes.shape({
			currentPage: PropTypes.number
		})
	}),
	selectingAll: PropTypes.bool,
	selectedContacts: PropTypes.array,
	everyThingSelected: PropTypes.bool,
	buttonTextNumber: PropTypes.any,
	sorting: PropTypes.any,
	desc: PropTypes.bool,
	setSort: PropTypes.func,
	check: PropTypes.func,
	checkCheckbox: PropTypes.any,
	item: PropTypes.any,
	type: PropTypes.any
};
export default AnonListRow;

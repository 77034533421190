import Soliditet from './Soliditet';
import TypeAhead from './TypeAhead';
import MergeFilters from './MergeFilters';
import { show, hasPermission, requestPermission } from './BrowserNotifications';
import { hasRecurringProduct, getRecurringProducts } from './RecurringProducts';
import { MergeAccount } from './BuyFromDataSource';
import { getAllSubRoleIds } from './RoleMap';
import copyToClipboard from './copyToClipboard';
import BrowserService from './BrowserService';

window.BabelServices = {
	Soliditet,
	TypeAhead,
	MergeFilters,
	BrowserNotifications: {
		show,
		hasPermission,
		requestPermission
	},
	MergeAccount,
	getAllSubRoleIds,
	hasRecurringProduct,
	getRecurringProducts,
	copyToClipboard,
	BrowserService
};

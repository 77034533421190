import { Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import DashboardButtonGroup from 'Components/Account/AccountDashboard/DashboardButtonGroup';
import DashboardPinnedComments from 'Components/Account/AccountDashboard/DashboardPinnedComments';
import ClientHistoryLog from 'App/components/HistoryLog/ClientHistoryLog';
import ContactHistoryLog from 'App/components/HistoryLog/ContactHistoryLog';
import React, { useCallback } from 'react';
import './Overview.scss';
import OpenActivityHistoryLog from 'App/components/HistoryLog/OpenActivityHistoryLog/OpenActivityHistoryLog';
import { RootState } from 'Store/index';
import useSelector from 'App/components/hooks/useSelector/useSelector';
import { useMetadata } from 'App/components/hooks/appHooks';
import { useSoftDeployAccess } from 'App/components/hooks';
import InactivationInfo from 'Components/Account/AccountDashboard/InactivationInfo/InactivationInfo';
import { shouldOpenModal } from 'App/services/Modal';
import openModal from 'App/services/Modal/Modal';
import UiElements from 'Components/UiElements';

type Props = {
	client: Client;
} & OptionalPropGroup<{
	isContact: true;
	contact: Contact & { userEditable: true };
}>;

const ClientCardOverview = (props: Props) => {
	const { client, contact, isContact = false } = props;
	const notesFilterActive = useSelector((state: RootState) => state.NotesFilterActive);
	const metadata = useMetadata();
	const hasUiElements = useSoftDeployAccess('APP_FRAMEWORK_UI_ELEMENTS_TOP_OF_ACCOUNT_CARD');
	const uiElementsConfig = metadata?.integrations?.uiElements?.account?.accountCardTop;

	const classes = new BemClass('ClientCardOverview');

	const editAccount = useCallback(() => {
		if (shouldOpenModal('EditClient')) {
			openModal('EditClient', { id: client.id });
		} else {
			const copiedAccount = structuredClone(client);

			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal.open('editAccount', {
				customerId: Tools.AppService.getCustomerId(),
				account: copiedAccount
			});
		}
	}, [client.id]);

	const renderedBy = { type: 'company', id: client.id } as const;
	return (
		<Block className={classes.b()}>
			{hasUiElements && !!uiElementsConfig ? (
				<div className={classes.elem('widgets').b()}>
					<UiElements elements={uiElementsConfig} object={client} type="accountCardTop" />
				</div>
			) : null}
			{!client.active ? (
				<InactivationInfo
					editAccount={editAccount}
					deactivatedBy={client.deactivatedBy}
					deactivationDate={client.deactivationDate}
					deactivationReason={client.deactivationReason}
				/>
			) : null}
			{client.active ? (
				<div className={classes.elem('comment').b()}>
					<DashboardButtonGroup
						isContact={isContact}
						account={client}
						contact={isContact ? contact : undefined}
						score={client.score}
					/>
				</div>
			) : null}

			<DashboardPinnedComments clientId={client.id} />
			<div className={classes.elem('timeline').b()}>
				<OpenActivityHistoryLog client={client} cardAdditionalProps={{ hasAvatar: true }} />
				{props.isContact ? (
					<ContactHistoryLog
						contact={props.contact}
						client={client}
						notesFilterActive={notesFilterActive}
						renderedBy={renderedBy}
					/>
				) : (
					<ClientHistoryLog
						client={client}
						renderedBy={renderedBy}
						notesFilterActive={notesFilterActive}
						cardAdditionalProps={{ hasAvatar: true }}
					/>
				)}
			</div>
		</Block>
	);
};

export default ClientCardOverview;

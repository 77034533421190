'use strict';

angular.module('upsalesApp').constant('currenciesISO4217', {
	AED: { iso: 'AED', icon: null, name: 'United Arab Emirates Dirham', countries: ['AE'] },
	AFN: { iso: 'AFN', icon: null, name: 'Afghanistan Afghani', countries: ['AF'] },
	ALL: { iso: 'ALL', icon: null, name: 'Albania Lek', countries: ['AL'] },
	AMD: { iso: 'AMD', icon: null, name: 'Armenia Dram', countries: ['AM'] },
	ANG: { iso: 'ANG', icon: null, name: 'Netherlands Antilles Guilder', countries: ['SX', 'CW'] },
	AOA: { iso: 'AOA', icon: null, name: 'Angola Kwanza', countries: ['AO'] },
	ARS: { iso: 'ARS', icon: null, name: 'Argentina Peso', countries: ['AR'] },
	AUD: {
		iso: 'AUD',
		icon: null,
		name: 'Australia Dollar',
		countries: ['AU', 'CC', 'CX', 'HM', 'KI', 'NF', 'NR', 'TV']
	},
	AWG: { iso: 'AWG', icon: null, name: 'Aruba Guilder', countries: ['AW'] },
	AZN: { iso: 'AZN', icon: null, name: 'Azerbaijan Manat', countries: ['AZ'] },
	BAM: { iso: 'BAM', icon: null, name: 'Bosnia and Herzegovina Convertible Marka', countries: ['BA'] },
	BBD: { iso: 'BBD', icon: null, name: 'Barbados Dollar', countries: ['BB'] },
	BDT: { iso: 'BDT', icon: null, name: 'Bangladesh Taka', countries: ['BD'] },
	BGN: { iso: 'BGN', icon: null, name: 'Bulgaria Lev', countries: ['BG'] },
	BHD: { iso: 'BHD', icon: null, name: 'Bahrain Dinar', countries: ['BH'] },
	BIF: { iso: 'BIF', icon: null, name: 'Burundi Franc', countries: ['BI'] },
	BMD: { iso: 'BMD', icon: null, name: 'Bermuda Dollar', countries: ['BM'] },
	BND: { iso: 'BND', icon: null, name: 'Brunei Darussalam Dollar', countries: ['BN'] },
	BOB: { iso: 'BOB', icon: null, name: 'Bolivia Bolíviano', countries: ['BO'] },
	BRL: { iso: 'BRL', icon: null, name: 'Brazil Real', countries: ['BR'] },
	BSD: { iso: 'BSD', icon: null, name: 'Bahamas Dollar', countries: ['BS'] },
	BTN: { iso: 'BTN', icon: null, name: 'Bhutan Ngultrum', countries: [] },
	BWP: { iso: 'BWP', icon: null, name: 'Botswana Pula', countries: ['BW'] },
	BYN: { iso: 'BYN', icon: null, name: 'Belarus Ruble', countries: [] },
	BZD: { iso: 'BZD', icon: null, name: 'Belize Dollar', countries: ['BZ'] },
	CAD: { iso: 'CAD', icon: null, name: 'Canada Dollar', countries: ['CA'] },
	CDF: { iso: 'CDF', icon: null, name: 'Congo/Kinshasa Franc', countries: ['CD'] },
	CHF: { iso: 'CHF', icon: null, name: 'Switzerland Franc', countries: ['CH', 'LI'] },
	CLP: { iso: 'CLP', icon: null, name: 'Chile Peso', countries: ['CL'] },
	CNY: { iso: 'CNY', icon: null, name: 'China Yuan Renminbi', countries: ['CN'] },
	COP: { iso: 'COP', icon: null, name: 'Colombia Peso', countries: ['CO'] },
	CRC: { iso: 'CRC', icon: null, name: 'Costa Rica Colon', countries: ['CR'] },
	CUC: { iso: 'CUC', icon: null, name: 'Cuba Convertible Peso', countries: [] },
	CUP: { iso: 'CUP', icon: null, name: 'Cuba Peso', countries: ['CU'] },
	CVE: { iso: 'CVE', icon: null, name: 'Cape Verde Escudo', countries: ['CV'] },
	CZK: { iso: 'CZK', icon: null, name: 'Czech Republic Koruna', countries: ['CZ'] },
	DJF: { iso: 'DJF', icon: null, name: 'Djibouti Franc', countries: ['DJ'] },
	DKK: { iso: 'DKK', icon: null, name: 'Denmark Krone', countries: ['DK', 'FO', 'GL'] },
	DOP: { iso: 'DOP', icon: null, name: 'Dominican Republic Peso', countries: ['DO'] },
	DZD: { iso: 'DZD', icon: null, name: 'Algeria Dinar', countries: ['DZ'] },
	EGP: { iso: 'EGP', icon: null, name: 'Egypt Pound', countries: ['EG'] },
	ERN: { iso: 'ERN', icon: null, name: 'Eritrea Nakfa', countries: ['ER'] },
	ETB: { iso: 'ETB', icon: null, name: 'Ethiopia Birr', countries: ['ET'] },
	EUR: {
		iso: 'EUR',
		style: { height: 14, marginRight: 5 },
		icon: 'euro.png',
		name: 'Euro',
		countries: [
			'AD',
			'AT',
			'AX',
			'BE',
			'BL',
			'CY',
			'DE',
			'EE',
			'ES',
			'FI',
			'FR',
			'GF',
			'GP',
			'GR',
			'IE',
			'IT',
			'LT',
			'LU',
			'LV',
			'MC',
			'ME',
			'MF',
			'MQ',
			'MT',
			'NL',
			'PM',
			'PT',
			'RE',
			'SI',
			'SK',
			'SM',
			'TF',
			'VA',
			'WS',
			'YT'
		]
	},
	FJD: { iso: 'FJD', icon: null, name: 'Fiji Dollar', countries: ['FI'] },
	FKP: { iso: 'FKP', icon: null, name: 'Falkland Islands (Malvinas) Pound', countries: ['FK'] },
	GBP: { iso: 'GBP', icon: null, name: 'United Kingdom Pound', countries: ['GB', 'GS', 'IM', 'JE'] },
	GEL: { iso: 'GEL', icon: null, name: 'Georgia Lari', countries: ['GE'] },
	GGP: { iso: 'GGP', icon: null, name: 'Guernsey Pound', countries: ['GG'] },
	GHS: { iso: 'GHS', icon: null, name: 'Ghana Cedi', countries: ['GH'] },
	GIP: { iso: 'GIP', icon: null, name: 'Gibraltar Pound', countries: ['GI'] },
	GMD: { iso: 'GMD', icon: null, name: 'Gambia Dalasi', countries: ['GM'] },
	GNF: { iso: 'GNF', icon: null, name: 'Guinea Franc', countries: ['GN'] },
	GTQ: { iso: 'GTQ', icon: null, name: 'Guatemala Quetzal', countries: ['GT'] },
	GYD: { iso: 'GYD', icon: null, name: 'Guyana Dollar', countries: ['GY'] },
	HKD: { iso: 'HKD', icon: null, name: 'Hong Kong Dollar', countries: ['HK'] },
	HNL: { iso: 'HNL', icon: null, name: 'Honduras Lempira', countries: ['HN'] },
	HRK: { iso: 'HRK', icon: null, name: 'Croatia Kuna', countries: ['HR'] },
	HTG: { iso: 'HTG', icon: null, name: 'Haiti Gourde', countries: ['HT'] },
	HUF: { iso: 'HUF', icon: null, name: 'Hungary Forint', countries: ['HU'] },
	IDR: { iso: 'IDR', icon: null, name: 'Indonesia Rupiah', countries: ['ID'] },
	ILS: { iso: 'ILS', icon: null, name: 'Israel Shekel', countries: ['IL'] },
	IMP: { iso: 'IMP', icon: null, name: 'Isle of Man Pound', countries: [] },
	INR: { iso: 'INR', icon: null, name: 'India Rupee', countries: ['IN', 'BT'] },
	IQD: { iso: 'IQD', icon: null, name: 'Iraq Dinar', countries: ['IQ'] },
	IRR: { iso: 'IRR', icon: null, name: 'Iran Rial', countries: ['IR'] },
	ISK: { iso: 'ISK', icon: null, name: 'Iceland Krona', countries: ['IS'] },
	JEP: { iso: 'JEP', icon: null, name: 'Jersey Pound', countries: [] },
	JMD: { iso: 'JMD', icon: null, name: 'Jamaica Dollar', countries: ['JM'] },
	JOD: { iso: 'JOD', icon: null, name: 'Jordan Dinar', countries: ['JO', 'PS'] },
	JPY: { iso: 'JPY', icon: null, name: 'Japan Yen', countries: ['JP'] },
	KES: { iso: 'KES', icon: null, name: 'Kenya Shilling', countries: ['KE'] },
	KGS: { iso: 'KGS', icon: null, name: 'Kyrgyzstan Som', countries: ['KG'] },
	KHR: { iso: 'KHR', icon: null, name: 'Cambodia Riel', countries: ['KH'] },
	KMF: { iso: 'KMF', icon: null, name: 'Comorian Franc', countries: ['KM'] },
	KPW: { iso: 'KPW', icon: null, name: 'Korea (North) Won', countries: ['KP'] },
	KRW: { iso: 'KRW', icon: null, name: 'Korea (South) Won', countries: ['KR'] },
	KWD: { iso: 'KWD', icon: null, name: 'Kuwait Dinar', countries: ['KW'] },
	KYD: { iso: 'KYD', icon: null, name: 'Cayman Islands Dollar', countries: ['KY'] },
	KZT: { iso: 'KZT', icon: null, name: 'Kazakhstan Tenge', countries: ['KZ'] },
	LAK: { iso: 'LAK', icon: null, name: 'Laos Kip', countries: ['LA'] },
	LBP: { iso: 'LBP', icon: null, name: 'Lebanon Pound', countries: ['LB'] },
	LKR: { iso: 'LKR', icon: null, name: 'Sri Lanka Rupee', countries: ['LK'] },
	LRD: { iso: 'LRD', icon: null, name: 'Liberia Dollar', countries: ['LR'] },
	LSL: { iso: 'LSL', icon: null, name: 'Lesotho Loti', countries: ['LS'] },
	LYD: { iso: 'LYD', icon: null, name: 'Libya Dinar', countries: ['LY'] },
	MAD: { iso: 'MAD', icon: null, name: 'Morocco Dirham', countries: ['EH', 'MA'] },
	MDL: { iso: 'MDL', icon: null, name: 'Moldova Leu', countries: ['MD'] },
	MGA: { iso: 'MGA', icon: null, name: 'Madagascar Ariary', countries: ['MG'] },
	MKD: { iso: 'MKD', icon: null, name: 'Macedonia Denar', countries: ['MK'] },
	MMK: { iso: 'MMK', icon: null, name: 'Myanmar (Burma) Kyat', countries: ['MM'] },
	MNT: { iso: 'MNT', icon: null, name: 'Mongolia Tughrik', countries: ['MN'] },
	MOP: { iso: 'MOP', icon: null, name: 'Macau Pataca', countries: ['MO'] },
	MRO: { iso: 'MRO', icon: null, name: 'Mauritania Ouguiya', countries: ['MR'] },
	MUR: { iso: 'MUR', icon: null, name: 'Mauritius Rupee', countries: ['MU'] },
	MVR: { iso: 'MVR', icon: null, name: 'Maldives (Maldive Islands) Rufiyaa', countries: ['MV'] },
	MWK: { iso: 'MWK', icon: null, name: 'Malawi Kwacha', countries: ['MW'] },
	MXN: { iso: 'MXN', icon: null, name: 'Mexico Peso', countries: ['MX'] },
	MYR: { iso: 'MYR', icon: null, name: 'Malaysia Ringgit', countries: ['MY'] },
	MZN: { iso: 'MZN', icon: null, name: 'Mozambique Metical', countries: ['MZ'] },
	NAD: { iso: 'NAD', icon: null, name: 'Namibia Dollar', countries: ['NA'] },
	NGN: { iso: 'NGN', icon: null, name: 'Nigeria Naira', countries: ['NG'] },
	NIO: { iso: 'NIO', icon: null, name: 'Nicaragua Cordoba', countries: ['NI'] },
	NOK: { iso: 'NOK', icon: null, name: 'Norway Krone', countries: ['NO', 'BV', 'SJ'] },
	NPR: { iso: 'NPR', icon: null, name: 'Nepal Rupee', countries: ['NP'] },
	NZD: { iso: 'NZD', icon: null, name: 'New Zealand Dollar', countries: ['NZ', 'CK', 'NU', 'PN', 'TK'] },
	OMR: { iso: 'OMR', icon: null, name: 'Oman Rial', countries: ['OM'] },
	PAB: { iso: 'PAB', icon: null, name: 'Panama Balboa', countries: ['PA'] },
	PEN: { iso: 'PEN', icon: null, name: 'Peru Sol', countries: ['PE'] },
	PGK: { iso: 'PGK', icon: null, name: 'Papua New Guinea Kina', countries: ['PG'] },
	PHP: { iso: 'PHP', icon: null, name: 'Philippines Peso', countries: ['PH'] },
	PKR: { iso: 'PKR', icon: null, name: 'Pakistan Rupee', countries: ['PK'] },
	PLN: { iso: 'PLN', icon: null, name: 'Poland Zloty', countries: ['PL'] },
	PYG: { iso: 'PYG', icon: null, name: 'Paraguay Guarani', countries: ['PY'] },
	QAR: { iso: 'QAR', icon: null, name: 'Qatar Riyal', countries: ['QA'] },
	RON: { iso: 'RON', icon: null, name: 'Romania Leu', countries: ['RO'] },
	RSD: { iso: 'RSD', icon: null, name: 'Serbia Dinar', countries: ['RS'] },
	RUB: { iso: 'RUB', icon: null, name: 'Russia Ruble', countries: ['RU'] },
	RWF: { iso: 'RWF', icon: null, name: 'Rwanda Franc', countries: ['RW'] },
	SAR: { iso: 'SAR', icon: null, name: 'Saudi Arabia Riyal', countries: ['SA'] },
	SBD: { iso: 'SBD', icon: null, name: 'Solomon Islands Dollar', countries: ['SB'] },
	SCR: { iso: 'SCR', icon: null, name: 'Seychelles Rupee', countries: ['SC'] },
	SDG: { iso: 'SDG', icon: null, name: 'Sudan Pound', countries: ['SD'] },
	SEK: { iso: 'SEK', icon: null, name: 'Sweden Krona', countries: ['SE'] },
	SGD: { iso: 'SGD', icon: null, name: 'Singapore Dollar', countries: ['SG'] },
	SHP: { iso: 'SHP', icon: null, name: 'Saint Helena Pound', countries: ['SH'] },
	SLL: { iso: 'SLL', icon: null, name: 'Sierra Leone Leone', countries: ['SL'] },
	SOS: { iso: 'SOS', icon: null, name: 'Somalia Shilling', countries: ['SO'] },
	SPL: { iso: 'SPL', icon: null, name: 'Seborga Luigino', countries: [] },
	SRD: { iso: 'SRD', icon: null, name: 'Suriname Dollar', countries: ['SR'] },
	STD: { iso: 'STD', icon: null, name: 'São Tomé and Príncipe Dobra', countries: ['ST'] },
	SVC: { iso: 'SVC', icon: null, name: 'El Salvador Colon', countries: [] },
	SYP: { iso: 'SYP', icon: null, name: 'Syria Pound', countries: ['SY'] },
	SZL: { iso: 'SZL', icon: null, name: 'Swaziland Lilangeni', countries: ['SZ'] },
	THB: { iso: 'THB', icon: null, name: 'Thailand Baht', countries: ['TH'] },
	TJS: { iso: 'TJS', icon: null, name: 'Tajikistan Somoni', countries: ['TJ'] },
	TMT: { iso: 'TMT', icon: null, name: 'Turkmenistan Manat', countries: ['TM'] },
	TND: { iso: 'TND', icon: null, name: 'Tunisia Dinar', countries: ['TN'] },
	TOP: { iso: 'TOP', icon: null, name: "Tonga Pa'anga", countries: ['TO'] },
	TRY: { iso: 'TRY', icon: null, name: 'Turkey Lira', countries: ['TR'] },
	TTD: { iso: 'TTD', icon: null, name: 'Trinidad and Tobago Dollar', countries: ['TT'] },
	TVD: { iso: 'TVD', icon: null, name: 'Tuvalu Dollar', countries: [] },
	TWD: { iso: 'TWD', icon: null, name: 'Taiwan New Dollar', countries: ['TW'] },
	TZS: { iso: 'TZS', icon: null, name: 'Tanzania Shilling', countries: ['TZ'] },
	UAH: { iso: 'UAH', icon: null, name: 'Ukraine Hryvnia', countries: ['UA'] },
	UGX: { iso: 'UGX', icon: null, name: 'Uganda Shilling', countries: ['UG'] },
	USD: {
		iso: 'USD',
		icon: null,
		name: 'United States Dollar',
		countries: [
			'US',
			'AS',
			'BQ',
			'EC',
			'FM',
			'GU',
			'IO',
			'MH',
			'MP',
			'PR',
			'PW',
			'SV',
			'TC',
			'TL',
			'UM',
			'VG',
			'VI',
			'ZW'
		]
	},
	UYU: { iso: 'UYU', icon: null, name: 'Uruguay Peso', countries: ['UY'] },
	UZS: { iso: 'UZS', icon: null, name: 'Uzbekistan Som', countries: ['UZ'] },
	VEF: { iso: 'VEF', icon: null, name: 'Venezuela Bolívar', countries: ['VE'] },
	VND: { iso: 'VND', icon: null, name: 'Viet Nam Dong', countries: ['VN'] },
	VUV: { iso: 'VUV', icon: null, name: 'Vanuatu Vatu', countries: ['VU'] },
	WST: { iso: 'WST', icon: null, name: 'Samoa Tala', countries: [] },
	XAF: {
		iso: 'XAF',
		icon: null,
		name: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
		countries: ['CF', 'CG', 'CM', 'GA', 'GQ', 'TD']
	},
	XCD: {
		iso: 'XCD',
		icon: null,
		name: 'East Caribbean Dollar',
		countries: ['AG', 'AI', 'DM', 'GD', 'KN', 'LC', 'MS', 'VC']
	},
	//'XDR': {iso: 'XDR', icon: null, name: 'International Monetary Fund (IMF) Special Drawing Rights', countries: []},
	XOF: {
		iso: 'XOF',
		icon: null,
		name: 'Communauté Financière Africaine (BCEAO) Franc',
		countries: ['BF', 'BJ', 'CI', 'GW', 'ML', 'NE', 'SN', 'TG']
	},
	XPF: { iso: 'XPF', icon: null, name: 'Comptoirs Français du Pacifique (CFP) Franc', countries: ['NC', 'PF', 'WF'] },
	YER: { iso: 'YER', icon: null, name: 'Yemen Rial', countries: ['YE'] },
	ZAR: { iso: 'ZAR', icon: null, name: 'South Africa Rand', countries: ['ZA'] },
	ZMW: { iso: 'ZMW', icon: null, name: 'Zambia Kwacha', countries: ['ZM'] },
	ZWD: { iso: 'ZWD', icon: null, name: 'Zimbabwe Dollar', countries: [] }
});

import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import _ from 'lodash';
import T from 'Components/Helpers/translate';
import { getTierPriceByCurrency } from 'App/babel/helpers/order';

/**
/**
 * MAX_CACHED_PRODUCTS sets the limit for locally cacheable products.
 * Products exceeding this limit require server fetching.
 *
 * Usage:
 * - Products are loaded from cache if their count is <= this limit.
 * - Server fetch is required if the count exceeds this limit.
 * 
 * Todo: Replace all hardcoded '4000' values with this constant.
 */
export const MAX_CACHED_PRODUCTS = 4000;

const currencyFormat = (value, curr) => Tools.$filter('currencyFormat')(value, curr);

/*	
	Could sort on name perhaps but order modal does not do that now so I will skipp it
*/
export const getProductCategoryTree = categoryModifier => {
	const AppService = getAngularModule('AppService');
	// AppService actually dont return a copy here so i will clone as we will mutate the objects
	let productCategories = _.cloneDeep(AppService.getProductCategories());

	if (categoryModifier && typeof categoryModifier === 'function') {
		productCategories = productCategories.map(categoryModifier);
	}

	const productCategorMap = productCategories.reduce((res, productCategory) => {
		res[productCategory.id] = productCategory;
		productCategory.children = [];
		return res;
	}, {});

	productCategories.forEach(productCategory => {
		const parent = productCategorMap[productCategory.parentId];
		if (parent) {
			parent.children.push(productCategory);
		}
	});
	return productCategories.filter(productCategory => !productCategory.parentId);
};

/**
 * Checks if the list price of a product has been updated, including tiered price.
 * @param {Product} product - The original product object.
 * @param {Product} updatedProduct - The updated product object to compare against.
 * @returns {Boolean} - Returns true if the product price has been updated, false otherwise.
 */
export const hasProductPriceBeenUpdated = (product, updatedProduct) => {
	const hasListPriceChanged = product.listPrice !== updatedProduct.listPrice;
	if (hasListPriceChanged) {
		return true;
	}
	const isTieredProduct = !!(updatedProduct.tiers && updatedProduct.tiers.length);

	if (isTieredProduct) {
		const productTiers = product.tiers.map(tier => {
			return {
				...tier,
				end: tier.end === null ? 0 : tier.end
			};
		});
		return !_.isEqual(productTiers, updatedProduct.tiers);
	}
	return false;
};

/**
 * Calculates the list price of a bundle based on its individual bundle items
 * It is used to recalculate the product (bundle) listPrice after a product (bundle item) has been updated
 * It should match the output of the backend calculation performed here base/src/domain/repositories/elastic/product.ts
 */
export const calculateBundleListPrice = (product, currency = 'SEK', priceListId = 1) => {
	if (!product || !product.bundle) {
		return product?.listPrice || 0;
	}

	let newPrice = 0;

	product.bundle.forEach(bundleItem => {
		let itemPrice = 0;
		if (bundleItem.product.tiers && bundleItem.product.tiers.length > 0) {
			const tiers = bundleItem.product.tiers.filter(tier => tier.priceListId === priceListId);
			tiers.forEach(tier => {
				if (bundleItem.quantity >= tier.start && bundleItem.quantity <= tier.end) {
					const tierPrice = getTierPriceByCurrency(tier, currency);
					itemPrice += tierPrice * bundleItem.tierQuantity;
				}
			});
		} else {
			itemPrice += bundleItem.product.listPrice * bundleItem.quantity;
		}

		newPrice += itemPrice;
	});

	newPrice *= product.bundlePriceAdjustment || 1;

	return newPrice;
};

/**
 * Updates product bundles containing a specified updated product within a list of products.
 * Re-calculates the bundle's list price if the product price has been updated.
 *
 * @param {Array} products - The array of products to be checked and potentially updated.
 * @param {Product} updatedProduct - The product that has been updated.
 * @param {boolean} [hasProductPriceChanged=false] - Flag indicating if the product price has been updated.
 * @param {string} [currency='SEK'] - The currency used for price calculations.
 * @param {number} [priceListId=1] - The ID of the price list to be used for price calculations.
 * @returns {Array} The updated array of products.
 */
export const getUpdatedProductBundles = (
	products,
	updatedProduct,
	hasProductPriceChanged = false,
	currency = 'SEK',
	priceListId = 1
) => {
	return products.map(product => {
		if (
			product.bundle &&
			product.bundle.some(bundleItem => bundleItem.product && bundleItem.product.id === updatedProduct.id)
		) {
			const newBundle = product.bundle.map(bundleItem => {
				if (bundleItem.product && bundleItem.product.id === updatedProduct.id) {
					return { ...bundleItem, product: { ...bundleItem.product, ...updatedProduct } };
				}
				return bundleItem;
			});

			const updatedProductBundle = { ...product, bundle: newBundle };

			if (hasProductPriceChanged) {
				updatedProductBundle.listPrice = calculateBundleListPrice(updatedProductBundle, currency, priceListId);
			}

			return updatedProductBundle;
		}
		return product;
	});
};

export const getProductPriceStr = (product, currency = 'SEK', defaultPriceListId = 1) => {
	if (!product.tiers || !product.tiers.length) {
		return currencyFormat(product.listPrice || 0, currency);
	}

	const defaultTiers = product.tiers.filter(tier => tier.priceListId === defaultPriceListId);
	if (!defaultTiers.length) {
		return `${T('default.from')} ${currencyFormat(0, currency)}`;
	}

	const start = defaultTiers[0];
	const startPriceInMC = _.find(start.currencies, { currency });
	if (defaultTiers.length === 1 && startPriceInMC) {
		return `${T('default.from')} ${currencyFormat(startPriceInMC.value, currency)}`;
	}

	const end = defaultTiers[defaultTiers.length - 1];
	const endPriceInMC = _.find(end.currencies, { currency });

	if (startPriceInMC && endPriceInMC) {
		return `${currencyFormat(Math.min(startPriceInMC.value, endPriceInMC.value), currency)} - ${currencyFormat(
			Math.max(startPriceInMC.value, endPriceInMC.value),
			currency
		)}`;
	}

	return null;
};

'use strict';

angular.module('domain.advancedSearch').directive('accountLocationSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/accountLocationSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					$scope.inited = false;
					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					var init = function () {
						// Addresses
						$scope.availableAddresses = {
							Visit: 'Visit',
							Invoice: 'Invoice',
							Mail: 'Mail',
							Delivery: 'Delivery',
							Other: 'Other'
						};

						$scope.show = FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						$scope.inited = true;
					};
					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);

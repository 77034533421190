// Here we can expose components for angular/old react components
import {
	Avatar,
	ButtonGroup,
	Card,
	CardHeader,
	Checkbox,
	Column,
	Input,
	Label,
	Loader,
	OutsideClick,
	Paginator,
	Row,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Tooltip,
	Progressbar,
	Title,
	ColorSelect
} from '@upsales/components';

window.ButtonGroup = ButtonGroup;
window.CheckboxComponent = Checkbox;
window.Column = Column;
window.UiInput = Input;
window.Label = Label;
window.OutsideClick = OutsideClick;
window.Row = Row;
window.UiAvatar = Avatar;
window.UiLoader = Loader;
window.UiPaginator = Paginator;
window.UiCard = Card;
window.UiCardHeader = CardHeader;
window.UiTable = Table;
window.UiTableColumn = TableColumn;
window.UiTableHeader = TableHeader;
window.UiTableRow = TableRow;
window.UiTooltip = Tooltip;
window.UiProgressbar = Progressbar;
window.UiColorSelect = ColorSelect;
window.UiTitle = Title;

import async from 'async';

angular.module('upModals.controllers').controller('ConfigureAdTargets', [
	'$scope',
	'$rootScope',
	'$q',
	'$modalParams',
	'AppService',
	'$upModal',
	'$sce',
	'Ads',
	'$popover',
	'$translate',
	'Metadata',
	'Account',
	'Campaign',
	'AdService',
	'LeaderPage',
	'RequestBuilder',
	'LeaderAccount',
	function (
		$scope,
		$rootScope,
		$q,
		$modalParams,
		AppService,
		$upModal,
		$sce,
		Ads,
		$popover,
		$translate,
		Metadata,
		Account,
		Campaign,
		AdService,
		LeaderPage,
		RequestBuilder,
		LeaderAccount
	) {
		var ConfigureAdTargetsCtrl = this;
		var SHORT_CUT_COUNTRIES = [
			'BE',
			'CA',
			'CH',
			'DE',
			'DK',
			'ES',
			'FI',
			'FR',
			'GB',
			'IE',
			'IT',
			'NL',
			'NO',
			'SE',
			'US'
		];
		ConfigureAdTargetsCtrl.loading = true;
		ConfigureAdTargetsCtrl.customerId = $modalParams.customerId;
		ConfigureAdTargetsCtrl.type = $modalParams.type;
		ConfigureAdTargetsCtrl.adCampaign = $modalParams.adCampaign;
		ConfigureAdTargetsCtrl.targetTypes = Ads.targetTypes;
		ConfigureAdTargetsCtrl.adAccount = AppService.getAdAccount();

		ConfigureAdTargetsCtrl.save = function () {
			if (ConfigureAdTargetsCtrl.type === 'targetAbm') {
				ConfigureAdTargetsCtrl.abmTargetType = ConfigureAdTargetsCtrl.adCampaign.targetAbm = 1; // don't allow ignore option for now
				ConfigureAdTargetsCtrl.parseAbmTargetsOnSave();
			}
			if (ConfigureAdTargetsCtrl.type === 'targetRetargeting') {
				ConfigureAdTargetsCtrl.adCampaign.targetRetargeting = 1;
				ConfigureAdTargetsCtrl.parsePagesOnSave();
			}
			if (ConfigureAdTargetsCtrl.type === 'targetLocation') {
				ConfigureAdTargetsCtrl.adCampaign.targetLocation = 1;
				ConfigureAdTargetsCtrl.parseLocationsOnSave();
			}
			$scope.resolve(ConfigureAdTargetsCtrl.adCampaign);
		};

		ConfigureAdTargetsCtrl.parseAbmTargetsOnSave = function (adCampaign) {
			if (!adCampaign) {
				adCampaign = ConfigureAdTargetsCtrl.adCampaign;
			}
			var targetTypeCampaign = Ads.targetTypes.campaign;
			var targetTypeAccount = Ads.targetTypes.account;
			adCampaign.targetAbm = ConfigureAdTargetsCtrl.abmTargetType;

			// remove old targets
			_.remove(adCampaign.target, { targetType: Ads.targetTypes.campaign });
			_.remove(adCampaign.target, { targetType: Ads.targetTypes.account });
			_.remove(adCampaign.target, { targetType: Ads.targetTypes.ignoreCampaign });
			_.remove(adCampaign.target, { targetType: Ads.targetTypes.ignoreAccount });

			if (adCampaign.targetAbm === 2) {
				targetTypeCampaign = Ads.targetTypes.ignoreCampaign;
				targetTypeAccount = Ads.targetTypes.ignoreAccount;
				adCampaign.targetAbm = 2;
			}

			_.forEach(ConfigureAdTargetsCtrl.campaigns, function (campaign) {
				adCampaign.target.push({
					targetId: campaign.id,
					targetType: targetTypeCampaign
				});
			});
			_.forEach(ConfigureAdTargetsCtrl.accounts, function (account) {
				adCampaign.target.push({
					targetId: account.id,
					targetType: targetTypeAccount
				});
			});
		};

		ConfigureAdTargetsCtrl.mapAbmTargets = function (cb) {
			if (!ConfigureAdTargetsCtrl.adCampaign.target) {
				ConfigureAdTargetsCtrl.adCampaign.target = [];
			}
			ConfigureAdTargetsCtrl.campaigns = [];
			ConfigureAdTargetsCtrl.accounts = [];

			AdService.mapAccounts(
				ConfigureAdTargetsCtrl.customerId,
				ConfigureAdTargetsCtrl.adCampaign.target,
				ConfigureAdTargetsCtrl.accounts,
				function (err) {
					if (err) {
						return cb(err);
					}
					AdService.mapCampaigns(
						ConfigureAdTargetsCtrl.customerId,
						ConfigureAdTargetsCtrl.adCampaign.target,
						ConfigureAdTargetsCtrl.campaigns,
						function (err) {
							return cb(err);
						}
					);
				}
			);
		};

		ConfigureAdTargetsCtrl.mapLocationTargets = function (cb) {
			if (!ConfigureAdTargetsCtrl.adCampaign.target) {
				ConfigureAdTargetsCtrl.adCampaign.target = [];
			}
			ConfigureAdTargetsCtrl.selectedCountries = [];
			ConfigureAdTargetsCtrl.selectedRegions = [];

			AdService.mapCountries(
				ConfigureAdTargetsCtrl.customerId,
				ConfigureAdTargetsCtrl.adCampaign.target,
				ConfigureAdTargetsCtrl.selectedCountries,
				function (err) {
					if (err) {
						return cb(err);
					}
					AdService.mapRegions(
						ConfigureAdTargetsCtrl.customerId,
						ConfigureAdTargetsCtrl.adCampaign.target,
						ConfigureAdTargetsCtrl.selectedRegions,
						function (err) {
							return cb(err);
						}
					);
				}
			);
		};

		ConfigureAdTargetsCtrl.parseLocationsOnSave = function () {
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.locationRegion });
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.locationCountry });

			_.forEach(ConfigureAdTargetsCtrl.selectedRegions, function (region) {
				ConfigureAdTargetsCtrl.adCampaign.target.push({
					targetId: region.id,
					targetType: Ads.targetTypes.locationRegion
				});
			});

			_.forEach(ConfigureAdTargetsCtrl.selectedCountries, function (country) {
				ConfigureAdTargetsCtrl.adCampaign.target.push({
					targetId: country.id,
					targetType: Ads.targetTypes.locationCountry
				});
			});
		};

		ConfigureAdTargetsCtrl.mapRetargetingPages = function (cb) {
			if (!ConfigureAdTargetsCtrl.adCampaign.target) {
				ConfigureAdTargetsCtrl.adCampaign.target = [];
			}
			ConfigureAdTargetsCtrl.selectedPages = [];
			var filters = new RequestBuilder();
			filters.addFilter(LeaderPage.attr.id, filters.comparisonTypes.Equals, ConfigureAdTargetsCtrl.url);

			AdService.mapCountries(
				ConfigureAdTargetsCtrl.customerId,
				ConfigureAdTargetsCtrl.adCampaign.target,
				ConfigureAdTargetsCtrl.selectedCountries,
				function (err) {
					if (err) {
						return cb(err);
					}
					AdService.mapRegions(
						ConfigureAdTargetsCtrl.customerId,
						ConfigureAdTargetsCtrl.adCampaign.target,
						ConfigureAdTargetsCtrl.selectedRegions,
						function (err) {
							return cb(err);
						}
					);
				}
			);
		};

		ConfigureAdTargetsCtrl.parsePagesOnSave = function () {
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.webPage });
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.webPageWildCard });
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.ignoreWebPage });
			_.remove(ConfigureAdTargetsCtrl.adCampaign.target, { targetType: Ads.targetTypes.ignoreWebPageWildCard });

			_.forEach(ConfigureAdTargetsCtrl.selectedPages, function (page) {
				ConfigureAdTargetsCtrl.adCampaign.target.push({
					targetId: page.id,
					targetType: page.type,
					url: page.url
				});
			});
		};

		ConfigureAdTargetsCtrl.findRegion = function (param, cb) {
			var filter = {
				type: 'region'
			};
			//ConfigureAdTargetsCtrl.countryFilter = null;

			if (typeof param === 'number') {
				filter.id = param;
			} else {
				if (typeof param === 'object') {
					ConfigureAdTargetsCtrl.$country = param;
					filter.countryCode = param.country_code;
				} else {
					filter.countryCode = param;
				}
			}
			Ads.customer(ConfigureAdTargetsCtrl.customerId)
				.listLocations(filter)
				.then(function (res) {
					ConfigureAdTargetsCtrl.regions = res.data || [];
					_.forEach(ConfigureAdTargetsCtrl.selectedRegions, function (r) {
						var found = _.find(ConfigureAdTargetsCtrl.regions, { id: r.id });
						if (found) {
							found.selected = true;
						}
					});
					if (cb) {
						return cb();
					}
				})
				.catch(function (err) {
					if (cb) {
						return cb(err);
					}
				});
		};

		ConfigureAdTargetsCtrl.findUrl = function (cb) {
			ConfigureAdTargetsCtrl.url = ConfigureAdTargetsCtrl.url || '';
			if (ConfigureAdTargetsCtrl.url.length < 4 && ConfigureAdTargetsCtrl.topPages) {
				ConfigureAdTargetsCtrl.pages = ConfigureAdTargetsCtrl.topPages;
				return;
			}

			ConfigureAdTargetsCtrl.pages = [];
			var filters = new RequestBuilder();
			var orBuilder = filters.orBuilder();
			orBuilder.next();
			orBuilder.addFilter(LeaderPage.attr.url, filters.comparisonTypes.Search, ConfigureAdTargetsCtrl.url);
			orBuilder.next();
			orBuilder.addFilter(LeaderPage.attr.name, filters.comparisonTypes.Search, ConfigureAdTargetsCtrl.url);
			orBuilder.done();
			filters.addSort(LeaderPage.attr.pageImpression, false);
			filters.limit = 50;
			ConfigureAdTargetsCtrl.findPages(filters.build(), function (err, res) {
				if (err && cb) {
					return cb(err);
				}
				ConfigureAdTargetsCtrl.pages = res.data;
				_.forEach(ConfigureAdTargetsCtrl.pages, function (page) {
					var selected = _.find(ConfigureAdTargetsCtrl.selectedPages, { url: page.url });
					if (selected) {
						page.selected = selected.type;
					}
					return;
				});
				if (ConfigureAdTargetsCtrl.url === '') {
					ConfigureAdTargetsCtrl.topPages = ConfigureAdTargetsCtrl.pages;
				}
				if (cb) {
					return cb(null, res);
				}
			});
			//filters.addFilter(LeaderPage.attr.id, filters.comparisonTypes.Equals, 0);
		};

		ConfigureAdTargetsCtrl.addPage = function (page, type) {
			page.selected = type;
			var found = _.find(ConfigureAdTargetsCtrl.selectedPages, { url: page.url });
			if (found) {
				found.type = type;
			} else {
				ConfigureAdTargetsCtrl.selectedPages.push({
					type: type,
					url: page.url,
					id: page.id,
					name: page.name
				});
			}
		};

		ConfigureAdTargetsCtrl.removePage = function (page) {
			var foundIndex = _.findIndex(ConfigureAdTargetsCtrl.selectedPages, { url: page.url });
			if (foundIndex === -1) {
				return;
			}
			page.selected = null;
			ConfigureAdTargetsCtrl.selectedPages.splice(foundIndex, 1);
		};

		ConfigureAdTargetsCtrl.addLocation = function (location) {
			var found;
			location.selected = true;
			if (!location.flag && location.country_code) {
				location.flag = location.country_code.toLowerCase();
			}
			if (location.region_code) {
				found = _.find(ConfigureAdTargetsCtrl.selectedRegions, { id: location.id });
				if (!found) {
					ConfigureAdTargetsCtrl.selectedRegions.push(location);
				}
			} else {
				found = _.find(ConfigureAdTargetsCtrl.selectedCountries, { id: location.id });
				if (!found) {
					ConfigureAdTargetsCtrl.selectedCountries.push(location);
				}
			}
		};

		ConfigureAdTargetsCtrl.removeLocation = function (location) {
			var foundIndex;
			location.selected = false;
			if (location.region_code) {
				foundIndex = _.findIndex(ConfigureAdTargetsCtrl.selectedRegions, { id: location.id });
				if (foundIndex !== -1) {
					ConfigureAdTargetsCtrl.selectedRegions.splice(foundIndex, 1);
				}
			} else {
				foundIndex = _.findIndex(ConfigureAdTargetsCtrl.selectedCountries, { id: location.id });
				if (foundIndex !== -1) {
					ConfigureAdTargetsCtrl.selectedCountries.splice(foundIndex, 1);
				}
			}
		};

		ConfigureAdTargetsCtrl.createCampaign = function () {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editCampaign', { noRedirect: true, customerId: ConfigureAdTargetsCtrl.customerId })
				.then(function (campaign) {
					ConfigureAdTargetsCtrl.campaigns.push(campaign);
				});
		};

		ConfigureAdTargetsCtrl.findPages = function (filter, cb) {
			LeaderPage.customer(ConfigureAdTargetsCtrl.customerId)
				.find(filter)
				.then(function (res) {
					if (cb) {
						return cb(null, res);
					}
				})
				.catch(function (err) {
					if (cb) {
						return cb(err);
					}
				});
		};

		ConfigureAdTargetsCtrl.getClientIpInfo = function (cb) {
			ConfigureAdTargetsCtrl.clientIpInfoErr = false;
			ConfigureAdTargetsCtrl.loadingClientIpInfo = true;
			var obj = angular.copy(ConfigureAdTargetsCtrl.adCampaign);

			ConfigureAdTargetsCtrl.parseAbmTargetsOnSave(obj);

			Ads.customer(ConfigureAdTargetsCtrl.customerId)
				.getClientIpInfo(obj)
				.then(function (res) {
					ConfigureAdTargetsCtrl.loadingClientIpInfo = false;
					ConfigureAdTargetsCtrl.clientIpInfo = res.data;
					ConfigureAdTargetsCtrl.clientIpInfoStats = {
						hasIp: _.where(res.data, { ips: true }),
						noIp: _.where(res.data, { ips: false, hasDataSourceId: true }),
						noDuns: _.where(res.data, { hasDataSourceId: false })
					};
					if (cb) {
						return cb();
					}
				})
				.catch(function (err) {
					ConfigureAdTargetsCtrl.loadingClientIpInfo = false;
					ConfigureAdTargetsCtrl.clientIpInfoErr = true;
					if (cb) {
						return cb(err);
					}
				});
		};

		var init = function () {
			ConfigureAdTargetsCtrl.loading = true;
			ConfigureAdTargetsCtrl.isFinished = ConfigureAdTargetsCtrl.adCampaign.status >= Ads.statuses.finished;
			async.series(
				[
					function (cb) {
						if (ConfigureAdTargetsCtrl.type !== 'targetAbm') {
							return cb();
						}
						ConfigureAdTargetsCtrl.mapAbmTargets(cb);
					},
					function (cb) {
						if (ConfigureAdTargetsCtrl.type !== 'targetLocation') {
							return cb();
						}
						if (ConfigureAdTargetsCtrl.adCampaign.targetLocation) {
							ConfigureAdTargetsCtrl.showSelectedLocations = true;
						}
						ConfigureAdTargetsCtrl.mapLocationTargets(function (err) {
							if (err) {
								return cb(err);
							}
							ConfigureAdTargetsCtrl.countries = AdService.parseCountries(AdService.countries);
							_.forEach(ConfigureAdTargetsCtrl.countries, function (c) {
								var found = _.find(ConfigureAdTargetsCtrl.selectedCountries, { id: c.id });
								if (found) {
									c.selected = true;
								}
							});
							ConfigureAdTargetsCtrl.shortcutCountries = _.filter(
								ConfigureAdTargetsCtrl.countries,
								function (c) {
									return SHORT_CUT_COUNTRIES.indexOf(c.country_code) !== -1;
								}
							);
							return cb();
						});
					},
					function (cb) {
						if (ConfigureAdTargetsCtrl.type !== 'targetRetargeting') {
							return cb();
						}
						LeaderAccount.customer(ConfigureAdTargetsCtrl.customerId)
							.getScript()
							.then(function (res) {
								ConfigureAdTargetsCtrl.script = res.data;
								ConfigureAdTargetsCtrl.selectedPages = [];
								AdService.mapPages(
									ConfigureAdTargetsCtrl.customerId,
									ConfigureAdTargetsCtrl.adCampaign.target,
									ConfigureAdTargetsCtrl.selectedPages,
									function (err) {
										if (err) {
											return cb(err);
										}
										ConfigureAdTargetsCtrl.showSelectedPages =
											ConfigureAdTargetsCtrl.selectedPages.length > 0 ? true : false;
										ConfigureAdTargetsCtrl.findUrl(cb);
									}
								);
							})
							.catch(e => console.log('getScript error', e));
					}
				],
				function (err) {
					ConfigureAdTargetsCtrl.loading = false;
					ConfigureAdTargetsCtrl.loadingErr = err;
				}
			);
		};

		init();
	}
]);

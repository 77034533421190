import BemClass from '@upsales/components/Utils/bemClass';
import { FolderType, FileType } from '../AccountFiles';
import { Icon, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import Folder from '../Folder';
import File from '../File';

import './GridView.scss';

type Props = {
	folders: FolderType[];
	files: FileType[];
	openFolder: (folderId: number, name: string) => void;
	deleteFolder: (id: number) => void;
	deleteFile: (id: number) => void;
	changeFolderFolder: (id: number, folderId: number | null) => void;
	changeFileFolder: (id: number, folderId: number | null) => void;
};

const GridView = ({
	files,
	folders,
	openFolder,
	deleteFolder,
	deleteFile,
	changeFolderFolder,
	changeFileFolder
}: Props) => {
	const classes = new BemClass('FilesGridView');

	const [editFolderMode, setEditFolderMode] = useState(false);
	const [editFileMode, setEditFileMode] = useState(false);

	return (
		<div className={classes.b()}>
			<div className={classes.elem('folders-container').b()}>
				{folders.length ? (
					<Text className={classes.elem('edit-button').mod({ active: editFolderMode }).b()}>
						{T('file.folders')}
						<Icon onClick={() => setEditFolderMode(prev => !prev)} name="edit" />
					</Text>
				) : null}
				<div className={classes.elem('folders-container__folders').b()}>
					{folders.map(folder => (
						<Folder
							deleteFolder={deleteFolder}
							isInEditMode={editFolderMode}
							key={folder.id}
							openFolder={openFolder}
							id={folder.id}
							name={folder.name}
							changeFolderFolder={changeFolderFolder}
							changeFileFolder={changeFileFolder}
						/>
					))}
				</div>
			</div>
			<div className={classes.elem('files-container').b()}>
				{files.length ? (
					<Text className={classes.elem('edit-button').mod({ active: editFileMode }).b()}>
						{T('file.files')}
						<Icon onClick={() => setEditFileMode(prev => !prev)} name="edit" />
					</Text>
				) : null}
				<div className={classes.elem('files-container__files').b()}>
					{files.map(file => (
						<File
							deleteFile={deleteFile}
							isInEditMode={editFileMode}
							key={file.id}
							id={file.id}
							fileType={file.extension}
							name={file.name}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default GridView;

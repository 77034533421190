'use strict';

const { Toggle } = require('@upsales/components');

ReactTemplates.form.countdownContentType = window.ReactCreateClass({
	datepicker: null,
	ngModel: function (onChange) {
		var self = this;

		self.$setViewValue = function (newValue) {
			onChange(newValue);
		};
		self.$render = function () {};

		self.$dateValue = null;
	},
	getInitialState: function () {
		var timeValue = this.getFormattedTime(this.props.element.value.date);
		const t = Tools.$translate;
		return {
			element: this.props.element,
			time: timeValue,
			unitText: {
				days: this.props.element.unitText?.days || t('date.days'),
				hours: this.props.element.unitText?.hours || t('date.hours'),
				minutes: this.props.element.unitText?.minutes || t('date.minutes')
			}
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var timeValue = this.getFormattedTime(nextProps.element.value.date);
		this.setState({
			element: nextProps.element,
			time: timeValue
		});
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			countdownTo: t('landingpage.countdownTo'),
			date: t('default.date'),
			time: t('default.time'),
			text: t('bannerEditor.text'),
			style: t('landingpage.style'),
			color: t('mail.color'),
			units: t('landingpage.countdownUnits'),
			headerColor: t('landingpage.headerColor'),
			numberColor: t('landingpage.numberColor'),
			background: t('bannerEditor.background'),
			backgroundColor: t('form.backgroundColor'),
			eventDate: t('socialEvent.eventDate'),
			selectAnotherDate: t('socialEvent.selectAnotherDate'),
			selectEventDate: t('socialEvent.selectEventDate'),
			dateDays: t('date.days'),
			dateHours: t('date.hours'),
			dateMinutes: t('date.minutes')
		};
	},
	componentDidUpdate: function () {
		// Update datepicker to initialize it
		if (this.datepicker) {
			this.datepicker.update();
		}
	},
	componentDidMount: function () {
		var self = this;
		var ngModel = new this.ngModel(function (value) {
			self.dateChanged({ target: { value: value } });
		});
		self.datepicker = Tools.$datepicker(jQuery(self._datepicker), ngModel, {
			controller: ngModel,
			autoclose: true,
			container: 'body',
			scope: Tools.$rootScope,
			minDate: moment().startOf('day').toDate()
		});
		self.datepicker.update();

		//jQuery timepicker config
		var pickerConfig = {};
		pickerConfig.step = 15;
		pickerConfig.appendTo = 'body';
		pickerConfig.timeFormat = 'H:i';
		pickerConfig.timeType = 'date';
		pickerConfig.lang = { decimal: '.', mins: 'min', hr: 'h', hrs: 'h' };
		pickerConfig.forceRoundTime = false;
		pickerConfig.maxTime = '24:00';
		pickerConfig.scrollDefaultNow = true;

		jQuery(this._timepicker)
			.timepicker(pickerConfig)
			.on('change', function (e) {
				self.timeChanged(e);
			});
	},
	dateChanged: function (event) {
		var element = this.state.element;

		var dateMoment = moment(event.target.value);
		if (dateMoment.isValid()) {
			var timeMoment = moment(element.value.date);
			dateMoment.set('hour', timeMoment.hour() || 0).set('minute', timeMoment.minute() || 0);

			element.value.date = dateMoment.toDate();
		} else {
			element.value.date = null;
		}

		this.setState({ element: element });
		this.props.onChange(element);
	},
	timeChanged: function (event) {
		var regex = /([0-2])([0-9])?(:)?([0-5])?([0-9])?/g;
		var regexact = /[0-2][0-9]:[0-5][0-9]/g;

		var timeMoment = moment('2017-01-01 ' + event.target.value);
		if (regexact.exec(event.target.value) && timeMoment.isValid()) {
			var element = this.state.element;
			var dateMoment = moment(this.props.element.value.date);
			dateMoment.set('hour', timeMoment.hour()).set('minute', timeMoment.minute());

			element.value.date = dateMoment.toDate();
			this.setState({ element: element, time: event.target.value });
			this.props.onChange(element);
		} else if (regex.exec(event.target.value) !== null || event.target.value === '') {
			this.setState({ time: event.target.value });
		}
	},
	unitTextChanged: function (event, changedUnit) {
		const element = this.state.element;
		const unitText = this.state.unitText;
		unitText[changedUnit] = event.target.value;
		element.unitText = unitText;
		this.setState({ element, unitText });
		this.props.onChange(element);
	},
	setRef: function (key, ref) {
		this[key] = ref;
	},
	getFormattedDate: function (val) {
		if (val && moment(val).isValid()) {
			var v = moment(val).format('L');
			return v;
		}
		return val;
	},
	getFormattedTime: function (val) {
		if (val && moment(val).isValid()) {
			var v = moment(val).format('LT');
			return v;
		}
		return val;
	},
	colorChange: function (key, value) {
		var element = this.state.element;

		element.value[key] = value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	useBgChanged: function () {
		if (this.state.element.value.backgroundColor === 'transparent') {
			this.colorChange('backgroundColor', '#ffffff');
		} else {
			this.colorChange('backgroundColor', 'transparent');
		}
	},

	toggleEventDate: function () {
		var element = this.state.element;
		if (element.value.dateTag) {
			element.value.dateTag = null;
			element.value.date = new Date();
		} else {
			element.value.dateTag = '{{Event.DateIso}}';
			element.value.date = null;
		}
		this.setState({ element: element });
		this.props.onChange(element);
	},

	render: function () {
		var self = this;
		var element = this.state.element;
		var dateValue = self.getFormattedDate(element.value.date);
		var timeValue = self.state.time;
		const unitText = self.state.unitText;
		var calIconClass = 'fa fa-calendar-o';

		var useBg = true;

		if (element.value.backgroundColor === 'transparent') {
			useBg = false;
		}

		var isEvent = this.props.isSocialEvent;
		var eventDateSelected = isEvent && element.value.dateTag;

		return (
			<div>
				<div className="tool-main-section">
					<div className="tool-section">
						<div>
							<label>{self.lang.countdownTo}</label>

							{isEvent ? (
								<button
									style={{ height: '17px' }}
									className="up-btn pull-right btn-link btn-bright-blue btn-sm btn-inline"
									onClick={this.toggleEventDate}
								>
									{eventDateSelected ? this.lang.selectAnotherDate : this.lang.selectEventDate}
								</button>
							) : null}
						</div>
						{eventDateSelected ? <h2>{this.lang.eventDate}</h2> : null}
						<div className="input-group" style={eventDateSelected ? { display: 'none' } : null}>
							<span className="input-group-addon">
								<b className={calIconClass}></b>
							</span>
							<input
								className="form-control"
								style={{ width: '185px' }}
								type="text"
								value={dateValue}
								onChange={self.dateChanged}
								placeholder={self.lang.date}
								ref={self.setRef.bind(self, '_datepicker')}
							/>
							<input
								className="form-control"
								style={{ width: '80px' }}
								type="text"
								value={timeValue}
								onChange={self.timeChanged}
								placeholder={self.lang.time}
								ref={self.setRef.bind(self, '_timepicker')}
								disabled={!dateValue}
							/>
						</div>
					</div>
				</div>

				<div className="tool-header">
					<h3>{this.lang.units}</h3>
				</div>

				<div className="tool-main-section">
					<div className="tool-section" key="unit-text">
						<div className="unit-text-wrapper">
							<div className="unit-text">
								<label>{self.lang.dateDays}</label>
								<input
									type="text"
									value={unitText.days}
									onChange={e => self.unitTextChanged(e, 'days')}
								/>
							</div>
							<div className="unit-text">
								<label>{self.lang.dateHours}</label>
								<input
									type="text"
									value={unitText.hours}
									onChange={e => self.unitTextChanged(e, 'hours')}
								/>
							</div>
							<div className="unit-text">
								<label>{self.lang.dateMinutes}</label>
								<input
									type="text"
									value={unitText.minutes}
									onChange={e => self.unitTextChanged(e, 'minutes')}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="tool-header">
					<h3>{this.lang.style}</h3>
				</div>

				<div className="tool-main-section">
					<div className="tool-header">
						<h3>{this.lang.text}</h3>
					</div>
					<div className="tool-section" key="text-color">
						<label>{this.lang.headerColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.textColor,
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'textColor')
							})}
						</div>
					</div>
					<div className="tool-section" key="num-color">
						<label>{this.lang.numberColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.numberColor,
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'numberColor')
							})}
						</div>
					</div>
					<div className="tool-header">
						<h3>{this.lang.background}</h3>
						<div className="pull-right">
							<Toggle checked={useBg} onChange={self.useBgChanged} color="medium-green" />
						</div>
					</div>
					{useBg ? (
						<div className="tool-section" key="bg">
							<label>{this.lang.backgroundColor}</label>
							<div className="pull-right">
								{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
									value: element.value.backgroundColor || '#ffffff',
									isText: false,
									presets: self.props.colorVariables,
									addPreset: self.props.addPreset,
									onChange: self.colorChange.bind(self, 'backgroundColor')
								})}
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
});

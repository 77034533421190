import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { Block, Button, Headline, Icon, Input, Text } from '@upsales/components';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ResetPasswordResource from 'App/resources/ResetPassword';
import { PrimaryButton } from '@upsales/components/Buttons';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import LoginError, { LoginErrors } from '../LoginError';
import { validatePassword } from 'App/helpers/loginHelper';

type Props = {
	hash: null | string;
	onBack: () => void;
};

const LoginResetPassword = ({ hash, onBack }: Props) => {
	const classes = new BemClass('LoginResetPassword');
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [loading, setLoading] = useState(false);
	const [changing, setChanging] = useState(false);
	const [changed, setChanged] = useState(false);
	const [error, setError] = useState<null | LoginErrors | LoginErrors[]>(null);
	const getPromise = useRef<null | CancelablePromise<any>>(null);
	const requestPromise = useRef<null | CancelablePromise<any>>(null);

	useEffect(() => {
		if (!hash) {
			return;
		}
		setLoading(true);
		// Check if the reset hash is still valid
		getPromise.current = makeCancelable(ResetPasswordResource.get(hash));

		getPromise.current.promise
			.then(res => {
				if (!res.data) {
					setError(LoginErrors.RESET_PASSWORD_HASH_INVALID);
				}
			})
			.catch(err => setError(LoginErrors.RESET_PASSWORD_HASH_INVALID))
			.finally(() => setLoading(false));
	}, [hash]);

	const doResetPassword = () => {
		if (!hash) {
			return;
		}

		const errors = validatePassword(password, password2);
		setError(errors);
		if (errors) {
			return;
		}
		setChanging(true);
		requestPromise.current = makeCancelable(ResetPasswordResource.resetPassword(hash, password));
		requestPromise.current.promise
			.then(() => {
				setChanged(true);
				setChanging(false);
			})
			.catch(err => {})
			.finally(() => {
				setChanging(false);
			});
	};

	return hash ? (
		<div className={classes.b()}>
			{loading ? null : error === LoginErrors.RESET_PASSWORD_HASH_INVALID ? (
				<LoginError error={error} />
			) : (
				<form
					onSubmit={e => {
						e.preventDefault();
						doResetPassword();
					}}
				>
					<Headline size="md" color="super-light-green">
						{t('admin.changePassword')}
					</Headline>
					<Block space="mtxl">
						{changed ? (
							<Text color="super-light-green">{t('login.passwordHasBeenReset')}</Text>
						) : (
							<Fragment>
								<Block space="mbl">
									<Input
										type="password"
										size="lg"
										placeholder={t('admin.newPassword')}
										value={password}
										onChange={e => setPassword(e.target.value)}
									/>
								</Block>
								<Input
									type="password"
									size="lg"
									placeholder={t('admin.repeatPassword')}
									value={password2}
									onChange={e => setPassword2(e.target.value)}
								/>
							</Fragment>
						)}
					</Block>
					{error ? <LoginError error={error} onClear={() => setError(null)} /> : null}
					{changed ? null : (
						<Block space="mtxl">
							<PrimaryButton
								submit
								onDark
								block
								size="xl"
								loading={changing}
								disabled={!password || !password2}
							>
								{t('login.passwordReset')}
							</PrimaryButton>
						</Block>
					)}
				</form>
			)}
			<Block space="mtxl">
				<Button
					type="link"
					color="super-light-green"
					block
					size="lg"
					disabled={loading}
					onClick={() => onBack()}
				>
					<Icon name="angle-left" space="mrs" />
					{`${t('login.backTo')} ${t('default.login').toLowerCase()}`}
				</Button>
			</Block>
		</div>
	) : null;
};

export default LoginResetPassword;

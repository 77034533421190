import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import EasyBookingSettings from 'Resources/EasyBookingSettings';
import getCategoryConfigs from 'App/babel/filters/CategoryFilters';

angular.module('upAttributes').service('AppointmentAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	'$q',
	function (FilterType, DisplayType, FeatureHelper, AppService, $q) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'description',
					selectableColumn: false,
					filterable: false
				},
				location: {
					title: 'default.location',
					field: 'location',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'location',
					selectableColumn: true,
					filterable: false
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					filterName: 'dateActivity',
					inputType: 'date',
					groupable: [
						{ title: 'date.year', field: 'date', type: 'year' },
						{ title: 'date.month', field: 'date', type: 'month' }
					],
					sortable: 'date',
					selectableColumn: true,
					filterable: true
				},
				endDate: {
					title: 'default.endDate',
					field: 'endDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					filterName: 'endDateActivity',
					inputType: 'date',
					groupable: [
						{ title: 'date.year', field: 'date', type: 'year' },
						{ title: 'date.month', field: 'date', type: 'month' }
					],
					sortable: false,
					selectableColumn: false,
					filterable: true
				},
				closeDate: {
					title: 'default.closeDate',
					field: 'closeDate',
					type: FilterType.Date,
					displayType: DisplayType.String,
					inputType: 'date',
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				modDate: {
					title: 'default.modDate',
					field: 'modDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					inputType: 'date'
				},
				regDate: {
					title: 'default.regDate',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					inputType: 'date'
				},
				contactAndClient: {
					title: 'default.contactAndClient',
					field: 'contactAndClient',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				appointment: {
					title: 'default.description',
					field: 'appointment',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				users: {
					title: 'default.users',
					field: 'user.id',
					displayType: DisplayType.String,
					filterName: 'userId',
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'users.id',
							type: FilterType.Number
						},
						name: {
							field: 'users.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: false,
					selectableColumn: true,
					filterable: false
				},
				regBy: {
					title: 'report.booker',
					field: 'regBy.id',
					displayType: DisplayType.String,
					parent: 'regBy',
					selectableColumn: true,
					sortable: false,
					attr: {
						id: {
							field: 'regBy.id',
							type: FilterType.Number
						},
						name: {
							field: 'users.name',
							type: FilterType.String
						}
					}
				},
				nestedUsers: {
					title: 'default.users',
					field: 'nestedUser.id',
					displayType: DisplayType.String,
					parent: 'users',
					attr: {
						id: {
							field: 'nestedUser.id',
							type: FilterType.Number
						}
					}
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'contactId',
					link: 'contacts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: false,
					selectableColumn: true,
					filterable: true,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'clientId',
					link: 'accounts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					filterable: true,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				activityType: {
					title: 'default.activityType',
					field: 'activityType.id',
					filterName: 'activityTypeId',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: false,
					sortable: 'activityType.name',
					selectableColumn: true,
					filterable: true,
					parent: 'activityType',
					attr: {
						id: {
							field: 'activityType.id',
							type: FilterType.Number
						},
						name: {
							field: 'activityType.name',
							type: FilterType.String
						},
						first: {
							field: 'activityType.first',
							type: FilterType.String
						}
					}
				},
				notes: {
					field: 'notes',
					type: FilterType.String
				},
				isAppointment: {
					field: 'isAppointment',
					type: FilterType.Boolean
				},
				project: {
					title: 'default.project',
					field: 'project.id',
					filterName: 'projectId',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: true,
					sortable: false,
					selectableColumn: true,
					filterable: true,
					parent: 'project',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				opportunity: {
					title: 'default.opportunity',
					field: 'opportunity.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					inputType: 'select',
					selectableColumn: true,
					parent: 'opportunity',
					attr: {
						id: {
							field: 'opportunity.id',
							type: FilterType.Number
						},
						name: {
							field: 'opportunity.description',
							type: FilterType.String
						}
					}
				},
				phone: {
					title: 'default.phone',
					field: 'client.phone',
					selectableColumn: true,
					type: FilterType.String
				},
				custom: {
					field: 'notes',
					type: FilterType.String
				},
				accountManager: {
					title: 'default.accountManager',
					field: 'client.user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					groupable: false,
					selectableColumn: true
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.None,
					groupable: false,
					selectableColumn: false
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.None,
					groupable: false,
					selectableColumn: true
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.None,
					groupable: false,
					selectableColumn: true
				},
				outcome: {
					title: 'default.outcome',
					field: 'outcome',
					type: FilterType.String,
					selectableColumn: true
				}
			};

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)) {
					attributes.opportunity.selectableColumn = false;
				}

				attributes.outcome.selectableColumn = FeatureHelper.isAvailable(
					FeatureHelper.Feature.APPOINTMENT_OUTCOME
				);
				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;
			}

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			const tmpAccountCategories = getCategoryConfigs(
				'account',
				'Account',
				{
					field: 'client.category.id',
					parent: 'default.account',
					entity: undefined
				},
				'default.accountCategories'
			);

			const accountCategories = {};
			for (const key in tmpAccountCategories) {
				// Filter2:getCompareFilters() will get wrong config otherwise because FilterHelper.isCategory
				const newKey = key.replace('_', '');

				accountCategories[newKey] = tmpAccountCategories[key];
			}

			var standardFilters = {
				Id: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'text',
					type: 'text',
					hide: true
				},
				IdList: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'list',
					type: 'list',
					hide: true
				},
				Date: {
					field: 'date',
					title: 'default.date',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				User: generateUserFilter(AppService.AccessType.ACTIVITY, {
					field: 'nestedUser.id',
					title: 'default.users'
				}),
				Description: {
					field: 'description',
					title: 'default.description',
					type: 'text',
					inputType: 'text'
				},
				AppointmentOutcome: {
					title: 'default.appointmentOutcome',
					hide: true,
					field: 'outcome',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('appointmentOutcome');
					},
					searchField: 'text',
					displayText: _.property('text'),
					comparisonType: 'MatchExact'
				},
				RegBy: generateUserFilter(AppService.AccessType.ACTIVITY, {
					field: 'regBy.id',
					title: 'report.booker'
				}),
				RegDate: {
					field: 'regDate',
					title: 'default.bookingDate',
					inputType: 'date',
					type: 'dateRange',
					presets: [
						'whenever',
						'currentDay',
						'lastDay',
						'currentWeek',
						'lastWeek',
						'currentMonth',
						'lastMonth',
						'currentQuarter',
						'lastQuarter',
						'currentYear',
						'lastYear',
						'last7days',
						'last14days',
						'last30days',
						'custom'
					]
				},
				UserRole: {
					field: 'user.role.id',
					title: 'default.role',
					inputType: 'select',
					multiple: true,
					type: 'list',
					dataPromise: function ($q, AppService) {
						return $q.when({ data: _.sortBy(AppService.getRoles('appointment'), 'name') });
					},
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.user'
				},
				AccountManager: generateUserFilter(AppService.AccessType.ACCOUNT, {
					field: 'client.user.id',
					title: 'default.accountManagers'
				}),
				...accountCategories,
				Campaign: {
					field: 'project.id',
					title: 'default.campaign',
					inputType: 'select',
					multiple: true,
					search: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					resource: 'Campaign',
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name')
				},
				Contact: {
					field: 'contact.id',
					inputType: 'select',
					multiple: true,
					resource: 'Contact',
					type: 'list',
					displayType: 'listLazy',
					searchFn: function (Contact, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Contact.attr.name, true);

							if (term) {
								filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
							}

							return Contact.customer(cId).find(filter.build());
						};
					},
					search: true,
					searchField: 'name',
					multiComparitors: true,
					// displayText: function(res) {
					// 	return res.name + (res.client ? ' <i class="gray">(' + res.client.name + ')</i>' : '');
					// },
					title: 'default.contacts'
				},
				Account: {
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					displayType: 'listLazy',
					search: true,
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					title: 'default.account',
					parent: 'default.account'
				},
				AppointmentType: {
					field: 'activityType.id',
					inputType: 'select',
					multiple: true,
					dataPromise: function (customerId, $q) {
						return $q.when({ data: AppService.getActivityTypes('appointment', true) });
					},
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true,
					search: false,
					title: 'default.appointmentTypes',
					listTitle: 'default.appointmentType'
				},
				EasyBooking: {
					field: 'easyBooking.id',
					type: 'raw',
					inputType: 'select',
					displayType: 'list',
					multiple: true,
					dataPromise: function () {
						return EasyBookingSettings.find({});
					},
					generate: function () {
						return {
							comparisonType: 'Equals',
							value: []
						};
					},
					build: function (filter, rb, getField) {
						const pageId = filter.value;
						const group = rb.groupBuilder();

						group.addFilter(getField('sourceType'), rb.comparisonTypes.Equals, 'easyBooking');
						group.addFilter(getField('sourceId'), rb.comparisonTypes.Equals, pageId);
						group.done();
					},
					searchField: 'internalName',
					displayText: 'internalName',
					multiComparitors: true,
					search: false,
					title: 'default.easyBooking',
					listTitle: 'default.easyBooking'
				},
				Status: {
					title: 'default.status',
					type: 'raw',
					hide: true,
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;
						var now = moment().utc().format();

						if (val === null) {
							return;
						} else if (val === 'open') {
							rb.addFilter(getField('endDate'), rb.comparisonTypes.GreaterThanEquals, now);
						} else {
							rb.addFilter(getField('endDate'), rb.comparisonTypes.LowerThanEquals, now);
						}
					}
				},
				AdvancedDate: {
					title: 'default.date',
					type: 'raw',
					hide: true,
					fromUrl: function (f) {
						return {
							value: {
								field: f.v.field,
								start: f.v.start ? new Date(f.v.start) : null,
								end: f.v.end ? new Date(f.v.end) : null,
								preset: 'custom'
							}
						};
					},
					generate: function () {
						return {
							value: {
								field: 'date',
								start: null,
								end: null,
								preset: 'custom'
							}
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;
						var field = filter.value.field;

						if (val.start) {
							rb.addFilter(getField(field), rb.comparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter(getField(field), rb.comparisonTypes.LowerThanEquals, val.end);
						}

						return;
					}
				},
				Notes: {
					field: 'notes',
					multiple: false,
					resource: 'Appointment',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(
								getField('comment.description'),
								rb.comparisonTypes.Search,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter(getField('notes'), rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					},
					searchField: 'notes',
					displayText: 'notes',
					search: true,
					displayType: 'text',
					title: 'default.notes',
					inputType: 'text'
				},
				HasOpportunity: {
					field: 'opportunity.id',
					listTitle: 'default.opportunity',
					title: 'default.opportunity',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.with', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.without', value: null, comparisonType: 'Equals' }
					]
				},
				AddressFilters: {
					title: 'default.addresses',
					type: 'raw',
					inputType: 'address',
					displayType: 'address',
					view: 'upsales/common/components/listFilters/address.tpl.html',
					parent: 'default.account',
					availableAddresses: {
						Visit: 'Visit',
						Invoice: 'Invoice',
						Mail: 'Mail',
						Delivery: 'Delivery',
						Other: 'Other'
					},
					getText: function (val) {
						var num = 0;
						if (val.Zipcode.value.start || val.Zipcode.value.end) {
							num++;
						}
						if (val.Address.value) {
							num++;
						}
						if (val.City.value) {
							num++;
						}
						if (val.State.value) {
							num++;
						}
						if (val.Country.value) {
							num++;
						}
						return num;
					},
					generate: function () {
						return {
							value: {
								AddressType: { value: null },
								Zipcode: { value: { start: null, end: null } },
								Address: { value: '', comparisonType: 'Search' },
								City: { value: '', comparisonType: 'Search' },
								State: { value: '', comparisonType: 'Search' },
								Country: { value: '', comparisonType: 'Search' }
							}
						};
					},
					build: function (filter, rb) {
						var val = filter.value;
						var hasValue = false;

						// Set up group
						var groupBuilder = rb.groupBuilder();
						if (val.AddressType.value) {
							groupBuilder.addFilter(
								{ field: 'client.address.type' },
								rb.comparisonTypes.Equals,
								val.AddressType.value
							);
						}

						// Address
						if (val.Address.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.address' },
								rb.comparisonTypes[val.Address.comparisonType],
								val.Address.value
							);
							hasValue = true;
						}

						// City
						if (val.City.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.city' },
								rb.comparisonTypes[val.City.comparisonType],
								val.City.value
							);
							hasValue = true;
						}

						// State
						if (val.State.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.state' },
								rb.comparisonTypes[val.State.comparisonType],
								val.State.value
							);
							hasValue = true;
						}

						// Country
						if (val.Country.value.length) {
							groupBuilder.addFilter(
								{ field: 'client.address.country' },
								rb.comparisonTypes[val.Country.comparisonType],
								val.Country.value
							);
							hasValue = true;
						}

						// Zipcode start
						if (val.Zipcode.value.start) {
							groupBuilder.addFilter(
								{ field: 'client.address.zipcode' },
								rb.comparisonTypes.GreaterThanEquals,
								val.Zipcode.value.start.toString()
							);
							hasValue = true;
						}

						// Zipcode end
						if (val.Zipcode.value.end) {
							groupBuilder.addFilter(
								{ field: 'client.address.zipcode' },
								rb.comparisonTypes.LowerThanEquals,
								val.Zipcode.value.end.toString()
							);
							hasValue = true;
						}

						if (hasValue) {
							groupBuilder.done();
						}
					}
				},
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'activityType.name' },
								rb.comparisonTypes.Wildcard,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter({ field: 'description' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				JourneyStep: {
					type: 'list',
					parent: 'default.account',
					filterName: 'JourneyStep',
					field: 'client.journeyStep',
					title: 'default.journeyStep',
					hide: true,
					dataPromise: function () {
						return $q.when({
							data: Tools.AppService.getJourneySteps().map(function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					},
					showOnSegment: true
				}
			};

			if (AppService.loaded) {
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)) {
					standardFilters.HasOpportunity.hide = true;
				}

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.JourneyStep.hide = false;
				}

				standardFilters.AppointmentOutcome.hide = !FeatureHelper.isAvailable(
					FeatureHelper.Feature.APPOINTMENT_OUTCOME
				);

				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}
			}

			// Alt.namespace
			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColumn = true;

			var requiredFields = ['isAppointment', 'custom'];
			return {
				getAll: function () {
					return attributes;
				},
				requiredFields: requiredFields,
				standardFilters: standardFilters,
				attr: attributes,
				keys: {
					ID: 'id',
					DESCRIPTION: 'description',
					DATE: 'date',
					CLOSEDATE: 'closeDate',
					USER: 'user',
					CONTACT: 'contact',
					ACCOUNT: 'account',
					ACTIVITYTYPE: 'activityType',
					NOTES: 'notes',
					ISAPPOINTMENT: 'isAppointment',
					PROJECT: 'project',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

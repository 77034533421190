import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import { FilterPreset, generateFilter, generateSearchFn } from '../filterGenerators';

export default (): Readonly<{ [key: string]: FilterConfig }> => ({
	// No parent
	Form: generateFilter(FilterPreset.ListLazy, {
		field: 'form.id',
		title: 'form.forms',
		columnPath: 'forms',
		searchField: 'title',
		resource: 'Form',
		searchFn: generateSearchFn('form')
	}),
	LastAssigned: generateFilter(FilterPreset.LastAssigned, {}, 'standard', 'client.assigned.regDate'),
	Date: generateFilter(
		FilterPreset.Date,
		{
			field: 'processedDate',
			title: 'default.date'
		},
		'standard'
	),
	LandingPage: generateFilter(FilterPreset.Radio, {
		title: 'form.landingPageFilter',
		field: 'form.landingPageBody',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'form.form', value: null, comparisonType: 'Equals' },
			{ text: 'form.landingPage', value: null, comparisonType: 'NotEquals' }
		]
	}),
	LeadSource: generateFilter(FilterPreset.LeadSource, {}, 'formSubmit', 'formSubmits', 'visit.leadSource'),
	Country: generateFilter(FilterPreset.Visit, { field: 'visit.country' }),

	// Section Company
	ClientName: generateFilter(FilterPreset.Text, {
		field: 'client.name',
		grouping: 'account',
		title: 'default.accountName',
		parent: 'filters.columns.formSubmitCompany'
	}),
	HasActivity: generateFilter(FilterPreset.RawRadio, {
		title: 'default.activity',
		parent: 'filters.columns.formSubmitCompany',
		build: function (filter, rb) {
			const val = filter.value;

			if (val === null) {
				return;
			} else if (val === true) {
				const typeOr = rb.orBuilder();

				// Activity
				typeOr.next();
				typeOr.addFilter({ field: 'client.activity.id' }, comparisonTypes.NotEquals, null);

				// Appointment
				typeOr.next();
				typeOr.addFilter({ field: 'client.appointment.id' }, comparisonTypes.NotEquals, null);

				typeOr.done();
			} else {
				rb.addFilter({ field: 'client.activity.id' }, comparisonTypes.Equals, null);
				rb.addFilter({ field: 'client.appointment.id' }, comparisonTypes.Equals, null);
			}
			rb.addFilter({ field: 'contact.client.id' }, comparisonTypes.NotEquals, null);
		}
	}),
	HasOpportunity: generateFilter(
		FilterPreset.HasOrder,
		{
			title: 'default.opportunity',
			parent: 'filters.columns.formSubmitCompany'
		},
		'opportunity'
	),
	HasOrder: generateFilter(FilterPreset.HasOrder, {
		title: 'default.order',
		parent: 'filters.columns.formSubmitCompany'
	}),
	User: generateFilter(FilterPreset.User, {
		title: 'default.accountManagers',
		parent: 'filters.columns.formSubmitCompany',
		field: 'client.users.id'
	}),
	ClientJourneyStep: generateFilter(FilterPreset.JourneyStep, {
		filterName: 'ClientJourneyStep',
		field: 'client.journeyStep',
		parent: 'filters.columns.formSubmitCompany',
		title: () => `${T('default.account')}: ${T('default.journeyStep')}`,
		hide: true
	}),

	// Section Contact
	Contact: generateFilter(FilterPreset.ListLazy, {
		field: 'contact.id',
		title: 'default.contacts',
		resource: 'Contact',
		tooltip: true,
		parent: 'filters.columns.formSubmitContact',
		includeFields: undefined,
		displaySubtitle: item => (item.client && item.client.name ? item.client.name : null),
		displayText: item => (item.name ? item.name : item.email),
		searchFn: function (Contact, AppService, RequestBuilder) {
			const cId = AppService.getCustomerId();

			return function (term, fields, offset, limit) {
				const filter = new RequestBuilder();
				filter.offset = offset;
				filter.limit = limit;

				filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
				return Contact.customer(cId).find(filter.build());
			};
		}
	}),
	ContactJourneyStep: generateFilter(FilterPreset.JourneyStep, {
		filterName: 'ContactJourneyStep',
		field: 'contact.journeyStep',
		parent: 'filters.columns.formSubmitContact',
		title: () => `${T('default.contact')}: ${T('default.journeyStep')}`,
		hide: true
	}),

	// Quick search
	ListSearch: generateFilter(FilterPreset.ListSearch, {}, [
		'client.name',
		'contact.name',
		'form.name',
		'visit.leadSource.source'
	])
});

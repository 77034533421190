import StandardFieldInput from 'Components/Inputs/standardFieldInput';

angular.module('upDirectives').directive('standardFieldInput', [
	'$datepicker',
	'$filter',
	'$safeApply',
	function ($datepicker, $filter, $safeApply) {
		return {
			scope: {
				ngModel: '=ngModel',
				ngDisabled: '=',
				ngRequired: '=',
				field: '='
			},
			require: 'ngModel',
			replace: true,
			template: '<div></div>',
			restrict: 'A',
			link: function ($scope, $element, $attrs, ngModel) {
				var firstElement = $element[0];
				var tabindex = $element.attr('tabindex');
				//var name = $attrs.name;
				var required = $scope.ngRequired;
				var multiple = $attrs.multiple !== undefined;
				var placeholder = $attrs.placeholder;
				var className = $attrs.class;
				var id = $attrs.id;
				var config = $scope.field;
				const allowTags = $attrs.allowTags !== undefined;

				$element.removeAttr('tabindex');
				$element.removeAttr('class');
				$element.removeAttr('id');

				var validate = function (value) {
					if (required) {
						var hasValue = false;

						if (multiple && Array.isArray(value) && value.length) {
							hasValue = true;
						} else if (value !== undefined && value !== null && value !== '') {
							hasValue = true;
						}
						ngModel.$setValidity('required', hasValue);
					}
					ngModel.$render();
					$safeApply($scope);
				};

				var onChange = function (value) {
					ngModel.$setViewValue(value);
					validate(value);
				};

				var render = function () {
					if (!firstElement) {
						return;
					}

					var props = {
						config: config,
						disabled: $scope.ngDisabled,
						//name: name,
						required: required,
						multiple: multiple,
						placeholder: placeholder,
						tabindex: tabindex,
						className: className,
						id: id,
						onChange: onChange,
						allowTags,
						value: $scope.ngModel,
						isOld: true
					};

					ReactDOM.render(React.createElement(StandardFieldInput, props), firstElement, function () {});
					validate($scope.ngModel);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
				});

				$scope.$watch(
					'ngModel',
					function () {
						render();
					},
					true
				);

				$scope.$watch('ngDisabled', function () {
					render();
				});

				$scope.$watch('ngRequired', function () {
					render();
				});
			}
		};
	}
]);

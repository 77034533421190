import { Text } from '@upsales/components';
import Flow from 'App/resources/Model/Flow';
import React from 'react';
import FlowStepBase from '../FlowStepBase';
import T from 'Components/Helpers/translate';

type Props = {
	flow: Flow;
	locked: boolean;
};

export const MAX_HEIGHT = 76;

const FlowStepStart = ({ flow, locked }: Props) => {
	const isDraft = flow.status === 'draft';
	const hasSegment = !!flow.segment;
	let text = '';
	if (isDraft) {
		if (hasSegment) {
			text = T('flow.step.start.flowDraft');
		} else {
			text = T('flow.step.start.flowDraftNoSegment');
		}
	} else {
		text = T('flow.step.start.flowActive');
	}
	return (
		<FlowStepBase
			icon="map-pin"
			backgroundColor="super-light-blue"
			borderColor="bright-blue"
			title={T('flow.step.start.title')}
			locked={locked}
			maxHeight={MAX_HEIGHT}
		>
			<Text>{text}</Text>
		</FlowStepBase>
	);
};

export default FlowStepStart;

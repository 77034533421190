import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@upsales/components';

import './Email.scss';

const propTypes = {
	contact: PropTypes.object.isRequired,
	statics: PropTypes.object.isRequired
};

const defaultProps = {
	contact: {},
	statics: {}
};

class Email extends React.Component {
	constructor(props) {
		super(props);
		const { statics, contact } = props;
		this.customerId = Tools.AppService.getCustomerId();
		const t = Tools.$translate;
		this.lang = {
			tooltip: statics.unsubscribed + ' ' + moment(contact.unsubscribed).format('L'),
			softBounceTooltip: t('event.email_soft_bounce'),
			hardBounceTooltip: t('event.email_hard_bounce')
		};
	}

	shouldComponentUpdate(newProps) {
		const { contact } = this.props;
		const { contact: newContact } = newProps;
		if (contact.email !== newContact.email) {
			return true;
		}
		return false;
	}

	render() {
		const {
			contact: { unsubscribed, mailBounces }
		} = this.props;
		let mailBounce = this.props.contact.mailBounce;
		if (!mailBounce && mailBounces && mailBounces.length) {
			if (mailBounces.indexOf('hard_bounce') >= 0) {
				mailBounce = 'hard_bounce';
			} else if (mailBounces.indexOf('soft_bounce') >= 0) {
				mailBounce = 'soft_bounce';
			}
		}
		const customerId = this.customerId;
		return (
			<div className="AccountListContacts__Email">
				<span>
					{unsubscribed ? (
						<Tooltip title={this.lang.tooltip}>
							<i className="fa fa-minus-circle" style={{ marginRight: '5px', color: 'red' }} />
						</Tooltip>
					) : null}

					{mailBounce ? (
						<Tooltip
							title={
								mailBounce === 'hard_bounce' ? this.lang.hardBounceTooltip : this.lang.softBounceTooltip
							}
						>
							{ReactTemplates.TOOLS.mailTo(customerId, this.props.contact, {
								color: mailBounce === 'hard_bounce' ? 'red' : 'orange'
							})}
						</Tooltip>
					) : (
						ReactTemplates.TOOLS.mailTo(customerId, this.props.contact)
					)}
				</span>
			</div>
		);
	}
}

Email.propTypes = propTypes;
Email.defaultProps = defaultProps;
export default Email;

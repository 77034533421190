import { useRef, useEffect } from 'react';

function createElementRoot(id) {
	const rootContainer = document.createElement('div');
	rootContainer.setAttribute('id', id);
	rootContainer.setAttribute(
		'style',
		'position: absolute; right: 0; bottom: 0; top: 0; z-index: 8000; transition: all .5s;'
	);
	return rootContainer;
}

function addRootElement(rootElem) {
	document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
}

export default function usePortal(selector) {
	const rootref = useRef(null);

	useEffect(function setupElement() {
		const existingParent = document.querySelector(selector);
		const parentElement = existingParent || createElementRoot(selector);

		if (!existingParent) {
			addRootElement(parentElement);
		}

		parentElement.appendChild(rootref.current);

		return function removeElement() {
			rootref.current.remove();
			parentElement.remove();
		};
	}, []);

	function getRootElem() {
		if (!rootref.current) {
			rootref.current = document.createElement('div');
		}

		return rootref.current;
	}

	return getRootElem();
}

'use strict';

angular.module('upDirectives').directive('upFormDashboard', [
	'$translate',
	'$filter',
	'AppService',
	'LEAD_SOURCE',
	'avatarService',
	'$multiSelect',
	'FilterHelper',
	'$rootScope',
	'FeatureHelper',
	function (
		$translate,
		$filter,
		AppService,
		LEAD_SOURCE,
		avatarService,
		$multiSelect,
		FilterHelper,
		$rootScope,
		FeatureHelper
	) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;
				var rootData;
				var tools = {
					$translate: $translate.instant,
					$filter: $filter,
					AppService: AppService,
					avatarService: avatarService,
					$multiSelect: $multiSelect,
					FilterHelper: FilterHelper,
					$rootScope: $rootScope,
					FeatureHelper: FeatureHelper,
					opts: {
						LEAD_SOURCE: LEAD_SOURCE,
						noCheck: true,
						editable: false,
						editAndDelete: true
					}
				};

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}
						ReactDOM.render(
							React.createElement(ReactTemplates.form.formDashboard, {
								// Data and stuff
								rootData: rootData,
								tools: tools
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					$attr.upFormDashboard,
					function (data) {
						rootData = data;
						tools.sort = data.sort;
						tools.opts.editEntry = data.editEntry;
						tools.opts.clickedEntry = data.editEntry;
						tools.opts.removeEntry = data.removeEntry;
						tools.opts.qualify = data.qualify;
						tools.opts.createAccount = data.createAccount;
						render();
					},
					true
				);

				render();
			}
		};
	}
]);

class Actions {
	constructor() {
		this.result = {};
	}
	_getResult() {
		return this.result;
	}
}

class DealCardChangeActions extends Actions {
	constructor() {
		super();
		this.result.borderColor = null;
	}
	borderColor(color) {
		this.result.borderColor = color;
	}
}

class EditOrderChangeActions extends Actions {
	constructor() {
		super();
		this.result.saveDisabled = false;
	}
	disableSave(value) {
		this.result.saveDisabled = value;
	}
	// setFieldValue(fieldId, value) {
	// 	console.log('setting field value to', value, 'for field id', fieldId);
	// }
}

// class Element {}

const actions = {
	dealCardChange: DealCardChangeActions,
	editOrderChange: EditOrderChangeActions
};

class ScriptFramework {
	constructor() {
		this.listeners = {
			dealCardChange: [],
			editOrderChange: []
		};

		this.events = Object.keys(this.listeners).reduce((res, evt) => {
			res[evt] = evt;
			return res;
		}, {});

		// this.elements = {};

		// This is the exposed api in the UI scripts
		this.export = {};
		this.export.registerListener = (evt, cb) => {
			if (!cb || typeof cb !== 'function') {
				throw new Error('Provided callback is not a function');
			}
			this.isValidEvent(evt);
			this.listeners[evt].push(cb);
		};

		// this.export.addElement = () => {
		// 	throw new Error('Not yet implemented');
		// };
	}

	isValidEvent(evt) {
		if (!this.events[evt]) {
			throw new Error('No such event "' + evt + '"');
		}
	}

	run(evt, data, oldData = null) {
		return new Promise(async resolve => {
			this.isValidEvent(evt);
			let results = {};
			await Promise.all(
				this.listeners[evt].map(
					cb =>
						new Promise(innerResolve => {
							try {
								const action = new actions[evt]();
								cb(data, oldData, action, () => {
									const res = action._getResult();
									results = { ...results, ...res };
									innerResolve();
								});
							} catch (err) {
								console.warn('ScriptFramework failed:', err);
								innerResolve();
							}
						})
				)
			);

			resolve(results);
		});
	}
}

Tools.ScriptFramework = new ScriptFramework();

export default ScriptFramework;

import React, { Component } from 'react';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import { Button, Icon } from '@upsales/components';
import moment from 'moment';
import _ from 'lodash';
import t from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import Order from 'App/resources/Model/Order';
import User from 'App/resources/Model/User';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';

type Props = {
	columns: string[];
	attrs: unknown;
	item: {
		id: number;
		userRemovable: boolean;
		title: string;
		isAppointment: boolean;
		sortDate: Date;
		time: unknown;
		mdate: Date;
		client?: Client;
		file?: { filename: string };
		integrationId: number;
		opportunity: Pick<Order, 'description' | 'id' | 'orderValue' | 'valueInMasterCurrency'>;
		user: User;
		involved: any[];
		state: number;
		version: number;
	};
	tools: typeof Tools & {
		opts: {
			removeEntry: Function;
			clickedEntry: Function;
			editOrder: Function;
		};
	};
};

class ListEsign extends Component<Props> {
	future: Date;
	statics: {
		[key: string]: string;
	};

	constructor(p: Props) {
		super(p);
		this.future = moment().add(1, 'year').toDate();
		this.statics = {
			deleteTool: t('default.delete'),
			noOpportunity: t('default.noOpportunity'),
			someone: t('default.someone').toLowerCase(),
			noDescription: t('default.noDescription'),
			masterCurrencyIso:
				_.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true })?.iso || 'SEK',
			noUser: t('default.noUser')
		};
	}
	getInitials(p: { fstname: string; sndname: string }) {
		if (p) {
			return (p.fstname[0] || '') + (p.sndname[0] || '');
		}
	}
	getFileUrl() {
		return Tools.Esign.getDownloadUrl(this.props.item);
	}
	render() {
		const item = this.props.item;
		const tools = this.props.tools;
		const options = tools.opts;
		const statics = this.statics;

		const customerId = tools.AppService.getCustomerId();
		const attrs = this.props.attrs;

		const getColumn = (column: string, item: Props['item']) => {
			const currencyFormat = new CurrencyFormat(statics.masterCurrencyIso);
			const key = 'column-' + column;
			let content;
			switch (column) {
				case 'sortDate':
					return (
						<td key={key} className="column-date">
							{ReactTemplates.TOOLS.dateCalendar(item.sortDate, 'L')}
						</td>
					);
				case 'date':
					if (item.isAppointment || item.time) {
						return (
							<td key={key} className="column-date">
								{ReactTemplates.TOOLS.dateCalendar(item.mdate, 'L')} {moment(item.mdate).format('LT')}
							</td>
						);
					} else {
						return (
							<td key={key} className="column-date">
								{ReactTemplates.TOOLS.dateCalendar(item.mdate, 'L')}
							</td>
						);
					}

				case 'client':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						</td>
					);

				case 'file':
					return (
						<td key={key}>
							<div className="break-all">
								{item.file || (item.integrationId && item.title) ? (
									<a
										onClick={ReactTemplates.TOOLS.stopProp.bind(this)}
										href={this.getFileUrl()}
										target="_blank"
									>
										{item.file && item.file.filename ? item.file.filename : item.title}
									</a>
								) : (
									''
								)}
							</div>
						</td>
					);

				case 'opportunity':
					var currencyStyle = { fontSize: '11px', fontWeight: 'bold' };
					if (item.opportunity) {
						content = (
							<span>
								<a onClick={options.editOrder.bind(this, item.opportunity)}>
									{item.opportunity.description || statics.noDescription}
								</a>
								<span style={currencyStyle}>
									{' (' + currencyFormat.short(item.opportunity.valueInMasterCurrency) + ')'}
								</span>
							</span>
						);
					} else {
						content = <span className="text-grey">{statics.noOpportunity}</span>;
					}
					return <td key={key}>{content}</td>;

				case 'state':
					switch (item.state) {
						case 0:
							content = <span className="text-grey">{Tools.$translate('mail.draft')}</span>;
							break;
						case 10:
							content = <span>{t('esign.waitingForSignings')}</span>;
							break;
						case 30:
							content = <span className="text-green">{t('esign.allSigned')}</span>;
							break;
						case 40:
							content = <span className="text-red">{t('esign.canceled')}</span>;
							break;
						case 20:
							var rejectee = _.find(this.props.item.involved, p => {
								return p.declineDate;
							});
							content = (
								<span>
									{this.props.item.version < 2 ? (
										<span>
											<b className="fa fa-warning text-orange"></b>{' '}
										</span>
									) : null}
									<span className="text-red">
										{t('esign.rejectedBy')}{' '}
										{rejectee ? this.getInitials(rejectee) : statics.someone}
									</span>
								</span>
							);
					}
					return <td key={key}>{content}</td>;

				case 'involved':
					var involved = item.involved;
					if (involved.length > 8) {
						var signed = involved.filter((p: { sign: boolean | null }) => !!p.sign).length;
						var classes = 'progress-string';

						if (signed === involved.length) {
							classes += ' all-signed';
						} else if (
							_.find(involved, p => {
								return p.declineDate;
							})
						) {
							classes += ' rejected';
						} else if (signed > 0) {
							classes += ' signed';
						}

						content = (
							<span className={classes}>
								{signed} <span className="total-involvees">{'/' + involved.length}</span>
							</span>
						);
					} else {
						content = _.chain(involved)
							.sortBy(p => {
								if (!p.sign) {
									return this.future;
								}
								return p.sign;
							})
							.sortBy(person => {
								return person.userId ? 0 : 1;
							})
							.map((person, i) => {
								var initials = this.getInitials(person);
								var classes = 'ball';

								if (person.sign) {
									classes += ' signed';
								} else if (person.declineDate) {
									classes += ' rejected';
								}

								var warning = null;
								if (person.undelivered) {
									warning = <b className="fa fa-warning text-orange"></b>;
								}

								var ball = (
									<div className={classes}>
										<span>{initials}</span>
										{warning}
									</div>
								);

								return ReactTemplates.TOOLS.withTooltip(ball, person.fstname + ' ' + person.sndname, {
									placement: 'top',
									key: 'ball-' + i
								});
							})
							.value();
					}
					return (
						<td key={key}>
							<div className="up-esign-progress">{content}</div>
						</td>
					);

				case 'journeyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{statics.noUser}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>
								<ReactTemplates.TOOLS.avatar user={item.user} size={15} style={{ marginTop: 0 }} />{' '}
								{item.user.name}
							</td>
						);
					}

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var classes = '';

		switch (item.state) {
			case 30:
				classes += 'won';
				break;
			case 20:
				classes += 'lost';
				break;
		}

		var tds = this.props.columns.map(column => {
			return getColumn(column, item);
		});

		return (
			<tr className={classes} key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				{tds}
				<td className="column-tools">
					<InlineConfirm
						show={item.state === 0 && item.userRemovable === true}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.eSign'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
}

export default ListEsign;

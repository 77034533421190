'use strict';

const { default: LocalStorage } = require('Components/Helpers/LocalStorage');
const { default: RequestBuilder, comparisonTypes } = require('Resources/RequestBuilder');

angular.module('domain.account').factory('AccountMeta', [
	'$q',
	'Account',
	'AppService',
	'NotificationService',
	function ($q, Account, AppService, NotificationService) {
		return function ($stateParams) {
			var customerId = $stateParams.customerId;
			if (!$stateParams.id) {
				return $q.reject();
			}

			function getSubAccountIds() {
				const hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
				const localStorage = new LocalStorage();
				const includeSubAccount = localStorage.getValue('includeSubaccountClientId' + $stateParams.id) ?? true;

				if (hasSubAccounts && includeSubAccount) {
					const rb = new RequestBuilder();
					rb.fields = ['id'];
					rb.addFilter({ field: 'operationalAccount.id' }, comparisonTypes.Equals, $stateParams.id);
					return Account.customer(customerId)
						.find(rb.build())
						.then(res => res?.data?.map(({ id }) => id) ?? []);
				} else {
					return $q.when([]);
				}
			}

			return AppService.loadedPromise.then(function () {
				var promises = {
					account: Account.customer(customerId).get($stateParams.id),
					subAccountIds: getSubAccountIds(),
					orderStages: $q.when({ data: AppService.getStages('all', true) }),
					documentTemplates: $q.when({ data: AppService.getDocumentTemplates('client') }),
					documentTemplatesOrder: $q.when({ data: AppService.getDocumentTemplates('order') }),
					documentTemplatesAgreement: $q.when({ data: AppService.getDocumentTemplates('agreement') })
				};
				return $q
					.all(promises)
					.then(res => {
						if (res.account.data.isExternal) {
							return $q.reject();
						}

						return res;
					})
					.catch(function (err) {
						var msg = 'openError.account';

						if (err && err.status === 404) {
							if (err.data && err.data.metadata && err.data.metadata.missingRights) {
								msg = 'errorMissingRights.account';
							} else {
								msg = 'errorNotFound.account';
							}
						}

						NotificationService.addNotification({
							title: 'default.error',
							body: msg,
							style: NotificationService.style.ERROR,
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

import React, { useMemo } from 'react';
import { Card, Checkbox, Text, Table, TableRow, TableColumn, TableHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './Stages.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

type StageObj = {
	active?: boolean;
	required?: boolean;
};

type Selected = {
	[key: number]: StageObj;
};

type Props = {
	selected: Selected;
	onToggleItem: (id: number) => void;
	showOrderStages?: boolean;
};

const Stages: React.FC<Props> = ({ selected, onToggleItem, showOrderStages = false }) => {
	const classNames = new BemClass('Stages');

	const stages = useSelector((state: RootState) => state.App.stages.all) ?? [];

	const lang = {
		stageName: T('admin.newSalesProcess.stageName'),
		stageProbability: T('admin.newSalesProcess.stageProbability'),
		setAsRequired: T('admin.newSalesProcess.setAsRequired')
	};

	const filteredStages = useMemo(
		() => (showOrderStages ? stages : stages.filter(stage => stage.probability !== 100 && stage.probability !== 0)),
		[stages, showOrderStages]
	);

	return (
		<Card className={classNames.b()}>
			<Table>
				<TableHeader columns={[{ title: lang.stageName }, { title: lang.stageProbability }]} />

				{filteredStages.map(stage => {
					return (
						<TableRow key={stage.id} onClick={() => onToggleItem(stage.id)}>
							<TableColumn>
								<div className={classNames.elem('name').b()}>
									<Checkbox checked={!!selected[stage.id]?.active} />
									<Text bold>{stage.name}</Text>
								</div>
							</TableColumn>
							<TableColumn>
								<Text>{stage.probability + '%'}</Text>
							</TableColumn>
						</TableRow>
					);
				})}
			</Table>
		</Card>
	);
};

export default Stages;

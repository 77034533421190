import React from 'react';
import './Portal.scss';

export default function Overlay({ children, onClick }) {
	return (
		<div className="Overlay" onClick={onClick}>
			{children}
		</div>
	);
}

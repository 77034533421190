'use strict';

angular.module('upDirectives').directive('upBannereditorRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		link: function ($scope, $element, $attr) {
			let firstElement = $element[0];
			var rootData;
			var renderTimeout;
			var render = function () {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(function () {
					if (rootData) {
						ReactDOM.render(
							React.createElement(ReactTemplates.bannerEditor.root, {
								// Data and stuff
								rootData: rootData
							}),
							firstElement,
							function () {}
						);
					}
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$watch(
				$attr.upBannereditorRoot,
				function (data) {
					rootData = data;
					render();
				},
				true
			);
		}
	};
});

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './MailTemplatePreviewHeader.scss';

const getHeaderLogo = (logo, accountProfile) => {
	if (logo === '__darkLogo__') {
		return accountProfile.darkLogo || accountProfile.logo;
	} else if (logo === '__brightLogo__') {
		return accountProfile.logo || accountProfile.darkLogo;
	} else {
		return logo;
	}
};

const MailTemplatePreviewHeader = ({ selected, readOnly, config, accountProfile, onClick }) => {
	const classes = new bemClass('MailTemplatePreviewHeader');
	const headerImg = getHeaderLogo(config.headerLogo, accountProfile);
	let headerMargin = config.headerMargin;
	if (!headerMargin && headerMargin !== 0) {
		headerMargin = 15;
	}
	const headerStyle = {
		backgroundColor: config.headerBg,
		marginBottom: headerMargin + 'px'
	};
	return (
		<div
			className={classes
				.mod({
					selected,
					'read-only': readOnly,
					'full-width': config.fullWidthHeader,
					['align-' + config.headerLogoAlign]: true
				})
				.b()}
			onClick={onClick}
			data-testid="header"
		>
			<div className={classes.elem('inner').b()} style={headerStyle}>
				<div>
					<img src={headerImg} />
				</div>
			</div>
		</div>
	);
};

export default MailTemplatePreviewHeader;

import { globalTracker } from 'App/babel/helpers/Tracker';

angular.module('upResources').factory('Account', [
	'$resource',
	'$q',
	'AccountAttributes',
	'URL',
	'API',
	'ResourceHelper',
	'$translate',
	'ParseGeneric',
	'MapGeneric',
	'FeatureHelper',
	function Account(
		$resource,
		$q,
		AccountAttributes,
		URL,
		API,
		ResourceHelper,
		$translate,
		ParseGeneric,
		MapGeneric,
		FeatureHelper
	) {
		var AA = AccountAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'account',
			createSuccessBody: 'saved.account',
			updateSuccessBody: 'updated.account',
			deleteSucccessBody: function (obj) {
				if (!obj) {
					return 'deleted.account';
				}

				return obj.name + ' ' + $translate.instant('default.wasDeleted').toLowerCase();
			},
			createErrorBody: 'saveError.account',
			updateErrorBody: 'saveError.account',
			deleteErrorBody: 'deleteError.account',
			mergeSuccessTitle: 'default.done',
			mergeSuccessBody: '',
			mergeErrorTitle: 'default.error',
			mergeErrorBody: 'mergeError.client',
			assignSuccessTitle: 'default.saved',
			assignSuccessBody: 'saved.assignToUser',
			assignErrorTitle: 'default.error',
			assignErrorBody: 'saveError.assign',
			unassignSuccessTitle: 'saved.unassigned',
			unassignSuccessBody: 'saved.unassignedDescription',
			unassignErrorTitle: 'default.error',
			unassignErrorBody: 'saveError.unassign'
		});

		var dateFields = [
			'indexDate',
			'modDate',
			'regDate',
			'scoreUpdateDate',
			'hasActivity',
			'hadActivity',
			'hasAppointment',
			'hadAppointment',
			'hasOpportunity',
			'hadOpportunity',
			'hasOrder',
			'hadOrder',
			'ratingDateLatestChange'
		];

		var Model = {
			new: function () {
				var account = {
					users: [],
					custom: [],
					addresses: [],
					categories: [],
					journeyStep: 'lead',
					active: true
				};

				var parsed = ParseGeneric('ParseAccount', { isArray: false, custom: 'account', isNew: true })(
					{ data: account },
					null
				);

				return parsed.data;
			},
			customer: function (customer) {
				var instance = {};
				var Account = null;
				var apiUrl = URL + API + customer;
				var baseUrl = apiUrl + '/accounts/';
				var assignedUrl = apiUrl + '/function/assign/:id';

				Account = $resource(
					baseUrl + ':id',
					{},
					{
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', {
								isArray: false,
								custom: 'account',
								dateFields: dateFields
							})
						},
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', {
								isArray: true,
								custom: 'account',
								dateFields: dateFields
							})
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', {
								isArray: false,
								custom: 'account',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric('MapAccount', {
								payloadName: 'account'
							})
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric('ParseAccount', {
								isArray: false,
								custom: 'account',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric('MapAccount', {
								payloadName: 'account'
							})
						},
						merge: {
							method: 'PUT',
							isArray: false,
							url: baseUrl + ':id/merge/:mergeId',
							transformResponse: ParseGeneric('ParseAccount', {
								isArray: false,
								custom: 'account',
								dateFields: dateFields
							})
						},
						multi: { method: 'POST', isArray: false, url: baseUrl + 'multi/:type' },
						assign: { method: 'PUT', isArray: false, url: assignedUrl },
						unassign: { method: 'DELETE', isArray: false, url: assignedUrl },
						getAssigned: { method: 'GET', isArray: false, url: assignedUrl },
						getAccountMarket: { method: 'GET', isArray: false, url: apiUrl + '/report/account/marketing' }
					}
				);

				instance.find = function (filter, options) {
					if (!options || !options.includeExternal) {
						if (filter.q === undefined) {
							filter.q = [];
						} else if (!Array.isArray(filter.q)) {
							filter.q = [filter.q];
						}
						filter.q.push({ a: 'isExternal', c: 'eq', v: 0 });
					}
					if (options) {
						options.includeExternal = undefined;
					}

					var params = _.extend(filter, options || {});
					delete params.mapCustom;

					return Account.find(params).$promise;
				};

				instance.get = function (id) {
					return Account.get({ id: id }).$promise;
				};

				instance.save = function (account, options, params) {
					var defered = $q.defer();
					const usingPhoneValidation = FeatureHelper.hasSoftDeployAccess('FORMAT_PHONE_NUMBERS');

					if (!account) {
						return defered.reject('No account');
					}

					if (account.id) {
						Account.update(
							_.assign({ id: account.id }, params, {}),
							{ account: account },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Account.create(
							{ usingPhoneValidation },
							{ account: account },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !account.id);
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, !account.id, err);
						});

					return defered.promise;
				};

				instance['delete'] = function (account, options) {
					return Account.delete({ id: account.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, account);

							return res;
						})
						.catch(function (err) {
							// Notify user
							helper.onDeleteError(options, err);
						});
				};

				instance.merge = function (mergeInto, merge, options) {
					options = _.defaults(options || {}, helper.defaults);
					var resData = { merged: mergeInto, deleted: merge };

					var promise = Account.merge({ id: mergeInto.id, mergeId: merge.id }, {}).$promise;

					promise
						.then(function (res) {
							return res;
						})
						.catch(function (err) {
							if (_.get(err, 'data.error.errorCode') === 111) {
								helper.onDeleteError(options, err);
							} else {
								// Notify user
								helper.errorNotification(options.mergeErrorTitle, options.mergeErrorBody, resData);
							}

							return err;
						});

					return promise;
				};

				instance.getAssigned = function (id) {
					return Account.getAssigned({ id: id }).$promise;
				};

				instance.assign = function (account, user, params, options) {
					options = _.defaults(options || {}, helper.defaults);
					params = params || {};
					params.userId = user.id;
					var resData = { user: user, account: account, params: params };

					return Account.assign({ id: account.id }, params)
						.$promise.then(function (res) {
							// Notify user
							helper.successNotification(options.assignSuccessTitle, options.assignSuccessBody, resData);

							helper.triggerEvent(options, 'assigned', resData);
							globalTracker.track('Assigned lead');

							return res;
						})
						.catch(function () {
							// Notify user
							helper.errorNotification(options.assignErrorTitle, options.assignErrorBody, resData);
						});
				};

				instance.unassign = function (accountId, options) {
					options = _.defaults(options || {}, helper.defaults);

					return Account.unassign({ id: accountId })
						.$promise.then(function (res) {
							// Notify user
							helper.successNotification(
								options.unassignSuccessTitle,
								options.unassignSuccessBody,
								accountId
							);

							helper.triggerEvent(options, 'unassigned', accountId);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.errorNotification(options.unassignErrorTitle, options.unassignErrorBody, null);
						});
				};

				instance.getAccountMarket = async function (options) {
					var missingParameters =
						!(options.clientId || options.contactId) ||
						!options.interval ||
						!options.startDate ||
						!options.endDate;

					if (missingParameters) {
						console.log('Missing parameters! (clientId OR contactId, interval, startDate, endDate)');
						return null;
					}

					const requestOptions = {
						clientId: options.clientId,
						contactId: options.contactId,
						interval: options.interval,
						startDate: options.startDate,
						endDate: options.endDate
					};

					if (FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') && options.subAccountIds?.length) {
						requestOptions.clientId = [options.clientId, ...options.subAccountIds];
					}

					return Account.getAccountMarket(requestOptions)
						.$promise.then(function (res) {
							return res;
						})
						.catch(function (err) {
							console.log(err);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

angular.module('upResources').factory('Client', [
	'Account',
	function Client(Account) {
		return Account;
	}
]);

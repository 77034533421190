import React from 'react';
import PropTypes from 'prop-types';
import { Row, Icon, Column, Button, Text, Toggle, Tooltip } from '@upsales/components';
import Select from 'react-select';
import GoogleButton from 'Components/Buttons/Google';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import UpSelect from 'Components/Inputs/UpSelect';
import Markdown from 'Components/Markdown';
import UpSegment from 'Components/Inputs/upSegment';
import Timepicker from 'App/upsales/common/components/react/inputs/timepicker';
class Field extends React.Component {
	state = {
		oauth: null,
		value: this.props.value,
		rows: 5
	};

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value && !this.writeLock) {
			this.setState({ value: nextProps.value });
		}
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		this.lang = {
			nothingToConfigure: t('integrations.nothingToConfigure'),
			oauthConnected: t('integration.oauthConnected'),
			oauthConnecting: t('integration.oauthConnecting'),
			remove: t('default.delete'),
			add: t('default.add')
		};
	}

	componentDidMount() {
		if (this.props.field.type === 'multiselect') {
			var self = this;
			var opts = {
				multiple: self.props.field.type === 'multiselect',
				required: self.props.required || false,
				ajax: false,
				asIds: false,
				onChange: self.onSelectChange,
				idAttr: { field: 'value' },
				titleAttr: { field: 'name' },
				data: function () {
					return _.sortByOrder(self.props.field.values, 'name');
				}
			};

			var input = jQuery(self._input);

			ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
				input.select2(options);
			});
		}
	}

	componentDidUpdate() {
		if (this.props.field.type === 'select' || this.props.field.type === 'multiselect') {
			ReactTemplates.TOOLS.selectHelper.updateValue.bind(this)();
		}
	}

	setRef = (name, ref) => {
		this[name] = ref;
	};

	changeTimeout = null;

	onChange = (value, checkDependencies) => {
		if (this.changeTimeout) {
			clearTimeout(this.changeTimeout);
		}
		var self = this;
		this.changeTimeout = setTimeout(function () {
			self.props.onChange(self.props.field.name, value, checkDependencies);
		}, 200);
		this.setState({ value: value });
	};

	writeTimeout = null;
	writeLock = false;

	onTextChange = e => {
		var self = this;
		self.writeLock = true;

		if (self.writeTimeout) {
			clearTimeout(self.writeTimeout);
		}

		self.writeTimeout = setTimeout(function () {
			self.writeLock = false;
		}, 500); /* 500ms may seem like much but we have a timeout of 200ms in the onChange so it is realy only 300ms */

		// 40 is the magic number of 1 row in our textareas at the moment.
		var numRows = e.target.value.length / 40;

		if (numRows > 5) {
			self.setState({
				rows: numRows
			});
		}

		self.onChange(e.target.value, true);
	};

	onSelectChange = value => {
		this.onChange(value, true);
	};

	checkboxChange = () => {
		this.onChange(!this.props.value, true);
	};

	radioChange = value => {
		this.onChange(value, true);
	};

	dateChange = value => {
		this.onChange(value, true);
	};

	removeValue = () => {
		this.onChange(null, true);
	};

	onRequestOauth = () => {
		this.props.onRequestOauth(this.props.field);
	};

	onRequestOauth2 = () => {
		this.props.onRequestOauth2(this.props.field);
	};

	getIntegrationLang = (element, type, fallback) => {
		return this.props.getIntegrationLang(element, type, fallback);
	};

	renderTextTemplateValue = value => {
		return value
			.replace(/INTEGRATION_URL/g, this.props.integration.endpoint)
			.replace(/CUSTOMER_ID/g, window.Tools.AppService.getCustomerId());
	};

	getOAuthBtn = (field, value) => {
		const loading = !value && this.props.gettingOauth;

		switch (field.brand) {
			case 'google':
				return (
					<GoogleButton
						disabled={this.props.gettingOauth}
						onClick={this.onRequestOauth2}
						text={this.getIntegrationLang(field.name, 'buttonLabel', field.buttonLabel)}
						loading={loading}
						size={field.size || 'lg'}
					/>
				);
			default:
				return (
					<Button
						color="green"
						disabled={this.props.gettingOauth}
						onClick={this.onRequestOauth2}
						size={field.size || 'lg'}
					>
						{!value && this.props.gettingOauth ? (
							<span>
								<b className="fa fa-refresh fa-spin" /> {this.lang.oauthConnecting}
							</span>
						) : (
							this.getIntegrationLang(field.name, 'buttonLabel', field.buttonLabel)
						)}
					</Button>
				);
		}
	};

	appendCustomFieldsToOptions = () => {
		const customfields = this.props?.field?.values?.filter?.(f => f.type === 'customfields') || [];
		if (customfields.length) {
			const customFieldOptions = customfields
				.map(cf => Tools.AppService.getCustomFields(cf.entity).map(cf2 => ({ entity: cf.entity, ...cf2 })))
				.flat()
				.map(cf => ({ name: cf.name, value: `customfield_${cf.entity}_${cf.id}` }));
			const optionsToAdd = customFieldOptions.filter(
				v => !this.props.field.values.find(f => f.value === v.value)
			);
			if (optionsToAdd.length) {
				const newValues = [...this.props.field.values, ...optionsToAdd].filter(v => v.value);
				this.props.field.values = newValues;
			}
		}
	};

	render() {
		var self = this;
		var field = self.props.field;
		var fieldElement = null;
		var value = self.state.value;
		var required = field.$required;
		var multiple = false;

		switch (field.type) {
			case 'oauth':
				fieldElement = (
					<div>
						{field.description ? (
							<p>{self.getIntegrationLang(field.name, 'description', field.description)}</p>
						) : null}
						{value ? (
							<div className="oauth-connected">
								<b className="fa fa-check-circle text-green" />{' '}
								<span className="inner-text">{self.lang.oauthConnected}</span>
								<Button
									onClick={self.removeValue}
									color="light-grey"
									hoverColor="red"
									shadow="none"
									className="pull-right"
								>
									{self.lang.remove}
								</Button>
							</div>
						) : null}
						{!value ? (
							<Button
								color="green"
								disabled={self.props.gettingOauth}
								onClick={self.onRequestOauth}
								size="lg"
							>
								{!value && self.props.gettingOauth ? (
									<span>
										<b className="fa fa-refresh fa-spin" /> {self.lang.oauthConnecting}
									</span>
								) : (
									self.getIntegrationLang(field.name, 'buttonLabel', field.buttonLabel)
								)}
							</Button>
						) : null}
						{self.props.oauthErr ? <span className="text-red"> {field.errorMessage}</span> : null}
					</div>
				);
				break;
			case 'oauth2':
				fieldElement = (
					<div>
						{field.description ? (
							<p>{self.getIntegrationLang(field.name, 'description', field.description)}</p>
						) : null}
						{value ? (
							<div className="oauth-connected">
								<b className="fa fa-check-circle text-green" />{' '}
								<span className="inner-text">{self.lang.oauthConnected}</span>
								<Button
									onClick={self.removeValue}
									color="light-grey"
									hoverColor="red"
									shadow="none"
									className="pull-right"
								>
									{self.lang.remove}
								</Button>
							</div>
						) : null}
						{!value ? this.getOAuthBtn(field, value) : null}
						{self.props.oauthErr ? <span className="text-red"> {field.errorMessage}</span> : null}
					</div>
				);
				break;
			case 'text':
				fieldElement = (
					<input
						type="text"
						className="form-control"
						value={value || ''}
						placeholder={field.placeholder || ''}
						readonly={field.readonly && 'readonly'}
						required={field.required}
						onChange={self.onTextChange}
					/>
				);
				break;
			case 'textTemplate':
				fieldElement = (
					<input
						type="text"
						className="form-control"
						value={this.renderTextTemplateValue(field.value)}
						disabled={field.disabled}
						readonly={field.readonly && 'readonly'}
						required={field.required}
						onChange={self.onTextChange}
					/>
				);
				break;
			case 'password':
				fieldElement = (
					<input
						type="password"
						className="form-control"
						value={value || ''}
						placeholder={field.placeholder || ''}
						required={field.required}
						onChange={self.onTextChange}
					/>
				);
				break;
			case 'email':
				fieldElement = (
					<input
						type="email"
						className="form-control"
						value={value || ''}
						placeholder={field.placeholder || ''}
						required={field.required}
						onChange={self.onTextChange}
					/>
				);
				break;
			case 'number':
				fieldElement = (
					<input
						type="number"
						className="form-control"
						value={value || ''}
						placeholder={field.placeholder || ''}
						required={field.required}
						onChange={self.onTextChange}
					/>
				);
				break;
			case 'textarea':
				fieldElement = (
					<textarea
						className="form-control"
						value={value || ''}
						placeholder={field.placeholder || ''}
						required={field.required}
						readonly={field.readonly && 'readonly'}
						onChange={self.onTextChange}
						rows={self.state.rows}
					/>
				);
				break;
			case 'date':
				fieldElement = React.createElement(ReactTemplates.INPUTS.datepicker, {
					onChange: self.dateChange,
					className: 'form-control',
					value: value,
					name: 'date',
					required: required
				});
				break;
			case 'time':
				fieldElement = (
					<Timepicker
						onChange={self.dateChange}
						className={'form-control'}
						value={value}
						required={required}
					/>
				);
				break;
			case 'upsalesAppointmentType':
			case 'upsalesAppointmentTypeMultiple':
			case 'upsalesActivityType':
			case 'upsalesActivityTypeMultiple':
				var entity = 'appointment';
				if (field.type === 'upsalesAppointmentTypeMultiple' || field.type === 'upsalesActivityTypeMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				if (field.type === 'upsalesActivityType' || field.type === 'upsalesActivityTypeMultiple') {
					entity = 'activity';
				}
				fieldElement = React.createElement(UpActivityTypes, {
					multiple: multiple,
					entity: entity,
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesUser':
			case 'upsalesUserMultiple':
				if (field.type === 'upsalesUserMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upUsers, {
					multiple: multiple,
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesProduct':
			case 'upsalesProductMultiple':
				if (field.type === 'upsalesProductMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upProducts, {
					multiple: multiple,
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesCompany':
			case 'upsalesCompanyMultiple':
				if (field.type === 'upsalesCompanyMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upAccounts, {
					multiple: multiple,
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesRole':
			case 'upsalesRoleMultiple':
				if (field.type === 'upsalesRoleMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upRoles, {
					multiple: multiple,
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required,
					data: Tools.AppService.getRoles()
				});
				break;
			case 'upsalesOrderStage':
			case 'upsalesOrderStageMultiple':
				if (field.type === 'upsalesOrderStageMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upStages, {
					multiple: multiple,
					entity: 'order',
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesOpportunityStage':
			case 'upsalesOpportunityStageMultiple':
				if (field.type === 'upsalesOpportunityStageMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upStages, {
					multiple: multiple,
					entity: 'opportunity',
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'radio':
				fieldElement = (
					<div className="btn-group">
						{field.values?.map(function (fieldValue, i) {
							var btnClass = 'btn up-btn btn-green no-shadow';
							if (fieldValue.value !== value) {
								btnClass += ' btn-lined';
							}
							return (
								<button
									key={'radio-' + i}
									type="button"
									className={btnClass}
									onClick={self.radioChange.bind(self, fieldValue.value)}
								>
									{fieldValue.name}
								</button>
							);
						})}
					</div>
				);
				break;
			case 'checkbox':
			case 'checkboxLarge':
				var chkSize = 'md';
				if (field.asHeader || field.type === 'checkboxLarge') {
					chkSize = 'lg';
				}
				fieldElement = (
					<>
						<Toggle color="medium-green" size={chkSize} checked={value} onChange={self.checkboxChange} />
						{field.label ? (
							<label className={field.asHeader ? 'app-field-section-title' : 'app-field-label'}>
								{self.getIntegrationLang(field.name, 'label', field.label)}
							</label>
						) : null}
						{field.helpTooltip ? (
							<Tooltip title={field.helpTooltip} distance={20}>
								<Text size="sm">
									<Icon name="question-circle" space="mls mrs" color="grey-11" />
								</Text>
							</Tooltip>
						) : null}
					</>
				);
				break;
			case 'select':
				fieldElement = (
					<UpSelect
						className="form-control"
						onChange={function (e) {
							var sel = _.find(self.props.field.values, { value: e.target.value });
							self.onSelectChange(sel);
						}}
						data={_.sortByOrder(field.values, 'name')}
						defaultValue={value}
						placeholder={self.props.field.placeholder || ' '}
						required={required}
						formatSelection={function (o, container, escape) {
							return escape(o.name);
						}}
						formatResult={function (o, container, query, escape) {
							return escape(o.name);
						}}
						getId={function (o) {
							return o.value;
						}}
					/>
				);
				break;
			case 'multiselect':
				this.appendCustomFieldsToOptions();
				value = value ? value : [];
				fieldElement = ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
				break;
			case 'upsalesCampaign':
			case 'upsalesCampaignMultiple':
				if (field.type === 'upsalesCampaignMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(ReactTemplates.INPUTS.upCampaigns, {
					multiple: multiple,
					entity: 'campaign',
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'upsalesSegment':
			case 'upsalesSegmentMultiple':
				if (field.type === 'upsalesSegmentMultiple') {
					multiple = true;
					value = value ? value : [];
				}
				fieldElement = React.createElement(UpSegment, {
					multiple: multiple,
					entity: 'segment',
					className: 'form-control',
					value: value,
					onChange: self.onSelectChange,
					placeholder: self.props.field.placeholder || '',
					required: required
				});
				break;
			case 'customfield':
				fieldElement = (
					<UpSelect
						className="form-control"
						onChange={function (e) {
							const sel = _.find(
								[...Tools.AppService.getCustomFields(field.entity), ...(field.extraValues ?? [])],
								field => {
									return '' + field.id === e.target.value;
								}
							);
							const value = sel ? _.pick(sel, 'name', 'id', 'alias') : null;
							self.onSelectChange(value);
						}}
						data={_.sortByOrder(
							[...Tools.AppService.getCustomFields(field.entity), ...(field.extraValues ?? [])],
							'name'
						)}
						defaultValue={value}
						placeholder={self.props.field.placeholder || ' '}
						required={required}
						matcher={function (term, undef, item) {
							return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
						}}
						formatSelection={function (o, container, escape) {
							return escape(o.name);
						}}
						formatResult={function (o, container, query, escape) {
							return escape(o.name);
						}}
						getId={function (o) {
							return o.id;
						}}
					/>
				);
				break;
			case 'button':
				fieldElement = (
					<Button
						type="lined"
						color="green"
						loading={field.$testing}
						onClick={this.props.onBtnClick.bind(null, field.name)}
					>
						{field.label || ''}
					</Button>
				);
				break;
			case 'markdown':
				fieldElement = <Markdown markdown={field.markdown} linkTarget={field.linkTarget} />;
				break;
			case 'mapper':
				var { upsales, external, allRowsHasValue } = (value || []).reduce(
					(memo, val) => {
						const nextValue = { ...memo };
						if (val.upsales && val.upsales.value) {
							nextValue.upsales[val.upsales.value] = true;
						} else {
							nextValue.allRowsHasValue = false;
						}
						if (val.external && val.external.value) {
							nextValue.external[val.external.value] = true;
						} else {
							nextValue.allRowsHasValue = false;
						}
						return nextValue;
					},
					{ upsales: {}, external: {}, allRowsHasValue: true }
				);
				var allUpsalesFields = field.values.reduce((memo, value) => {
					switch (value.type) {
						case 'customfield':
							Tools.AppService.getCustomFields(value.entity).forEach(cf => {
								memo.push({
									value: `${value.type}_${value.entity}_${cf.id}`,
									label: cf.name,
									entity: value.entity,
									customFieldId: cf.id,
									type: 'upsales'
								});
							});
							break;
						case 'currency':
							Tools.AppService.getMetadata().customerCurrencies.forEach(currency => {
								if (value.skipMasterCurrency && currency.masterCurrency) {
									return;
								}
								memo.push({
									value: `${value.type}_${currency.iso}`,
									label: currency.iso,
									entity: 'currency',
									currencyId: currency.iso,
									type: 'upsales'
								});
							});
							break;
						case 'upsales':
							memo.push(value);
							break;
					}
					return memo;
				}, []);
				var upsalesFields = allUpsalesFields.filter(a => a.type === 'upsales' && !upsales[a.value]);
				var externalFields = field.values.filter(a => a.type === 'external' && !external[a.value]);

				fieldElement = (
					<div>
						{value
							? value.map((row, index) => (
									<Row key={index} style={{ marginBottom: '10px' }}>
										<Column style={{ marginRight: '5px' }}>
											<Select
												options={upsalesFields}
												value={row.upsales}
												onChange={rowVal => {
													this.onChange(
														value.map((val, i) => {
															if (index !== i) {
																return val;
															}

															return { ...val, upsales: rowVal };
														})
													);
												}}
											/>
										</Column>
										<Column style={{ marginRight: '5px' }}>
											<Select
												options={externalFields}
												value={row.external}
												onChange={rowVal => {
													this.onChange(
														value.map((val, i) => {
															if (index !== i) {
																return val;
															}

															return { ...val, external: rowVal };
														})
													);
												}}
											/>
										</Column>
										<Column fixedWidth={34}>
											<Button
												type="link"
												color="red"
												onClick={() => {
													this.onChange(value.filter((_val, i) => i !== index));
												}}
											>
												<Icon name="trash" />
											</Button>
										</Column>
									</Row>
							  ))
							: null}
						<Button
							disabled={!allRowsHasValue || !upsalesFields.length || !externalFields.length}
							onClick={() =>
								this.onChange(
									!value
										? [{ upsales: null, external: null }]
										: [...value, { upsales: null, external: null }]
								)
							}
						>
							{this.lang.add}
						</Button>
					</div>
				);
				break;
		}

		return <div className={'field-' + field.type}>{fieldElement}</div>;
	}
}

Field.propTypes = {
	value: PropTypes.any,
	integration: PropTypes.any,
	field: PropTypes.shape({
		type: PropTypes.string,
		values: PropTypes.array
	}),
	onRequestOauth: PropTypes.func.isRequired,
	onRequestOauth2: PropTypes.func.isRequired,
	getIntegrationLang: PropTypes.func.isRequired,
	onBtnClick: PropTypes.func.isRequired,
	gettingOauth: PropTypes.bool
};

export default Field;
window.StandardIntegrationField = Field;

'use strict';

angular.module('domain.order').factory('UpdateOrderMultiMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function ($modalParams) {
			var promises = {
				orderCustomFields: $q.when({ data: AppService.getCustomFields('order') })
			};

			return $q.all(promises).then(function (res) {
				$modalParams.stageType = $modalParams.entity === 'Order' ? 'order' : 'opportunity';
				if ($modalParams.allStages) {
					$modalParams.stageType = 'all';
				}
				var stages = AppService.getStages($modalParams.stageType);
				res.stages = { data: stages };
				return res;
			});
		};
	}
]);

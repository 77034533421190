'use strict';
import openModal from 'App/services/Modal';

ReactTemplates.findProspect.innerCompanyBranchRow = window.ReactCreateClass({
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			dollarSignTooltip: t('soliditet.dollarSignTooltip')
		};
	},
	capitalizeFirstLetter: function (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	onChange: function (checked) {
		var action = checked ? 'add' : 'remove';

		this.props.onChange([this.props.item.id], action);
	},
	isChecked: function () {
		if (
			this.props.filters &&
			this.props.filters.SniCode &&
			Array.isArray(this.props.filters.SniCode.value) &&
			this.props.filters.SniCode.value.indexOf(this.props.item.id) > -1
		) {
			return true;
		}

		return false;
	},
	hasSales: function () {
		if (!this.props.dataWithSales || !this.props.item) {
			return false;
		}

		return this.props.dataWithSales[this.props.item.id] !== undefined;
	},
	render: function () {
		var checked = this.isChecked();
		var hasSales = this.hasSales();
		var className = hasSales ? 'overflow-ellipsis row-name-text has-sales' : 'overflow-ellipsis row-name-text';

		return (
			<div className="inner-row">
				<div className="row-input">
					<ReactTemplates.checkbox
						className="toggle-sm checkbox-fix"
						checked={checked}
						onChange={this.onChange}
					/>
				</div>
				<div className="row-name">
					{ReactTemplates.TOOLS.withTooltip(
						<span className={className}>{this.capitalizeFirstLetter(this.props.item.name)}</span>,
						this.props.item.name,
						{ placement: 'bottom', delayShow: 500 }
					)}
					{hasSales &&
						ReactTemplates.TOOLS.withTooltip(
							ReactTemplates.findProspect.salesIcon({ marginRight: 0 }),
							this.lang.dollarSignTooltip,
							{ placement: 'bottom' }
						)}
				</div>
				<div className="overflow-ellipsis row-value">
					<span>{this.formatNumber(this.props.item.value)}</span>
				</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.companyBranchRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			open: false,
			checked: false,
			data: null,
			onChange: function () {}
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			dollarSignTooltip: t('soliditet.dollarSignTooltip')
		};
	},
	getInitialState: function () {
		return {
			open: this.props.open
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.open !== this.props.open) {
			this.setState({
				open: nextProps.open
			});
		}
	},
	toggleOpen: function () {
		this.setState({
			open: !this.state.open
		});
	},
	onChange: function (checked) {
		var childIds = _.map(this.props.data.children, function (sniCode) {
			return sniCode.id;
		});

		var action = checked ? 'add' : 'remove';

		this.props.onChange(childIds, action);
	},
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	hasSales: function () {
		var self = this;

		var hasSales = false;

		if (self.props.dataWithSales && self.props.data) {
			_.each(self.props.data.children, function (sniCode) {
				if (self.props.dataWithSales[sniCode.id]) {
					hasSales = true;
				}
			});
		}

		return hasSales;
	},
	isChecked: function () {
		var self = this;

		if (
			this.props.data.children &&
			this.props.data.children.length &&
			this.props.filters.SniCode &&
			Array.isArray(this.props.filters.SniCode.value)
		) {
			var checked = true;

			_.each(this.props.data.children, function (sniCode) {
				if (_.indexOf(self.props.filters.SniCode.value, sniCode.id) === -1) {
					checked = false;
				}
			});

			return checked;
		}

		return false;
	},
	hasCheckedChild: function () {
		var self = this;

		if (
			this.props.data.children &&
			this.props.data.children.length &&
			this.props.filters.SniCode &&
			Array.isArray(this.props.filters.SniCode.value)
		) {
			var checked = false;

			_.each(this.props.data.children, function (sniCode) {
				if (_.indexOf(self.props.filters.SniCode.value, sniCode.id) > -1) {
					checked = true;
				}
			});

			return checked;
		}

		return false;
	},
	itemIsVisible: function (item) {
		var isChecked = false;

		if (this.props.filters.SniCode && Array.isArray(this.props.filters.SniCode.value)) {
			isChecked = this.props.filters.SniCode.value.indexOf(item.sni_code) > -1;
		}
		return isChecked || item.name.toLowerCase().indexOf(this.props.searchString.toLowerCase()) > -1;
	},
	render: function () {
		var self = this;
		var rows = null;
		var tools = this.props.tools;

		var name = tools.$translate(this.props.data.name);

		if (this.state.open && this.props.data && Array.isArray(this.props.data.children)) {
			rows = this.props.data.children
				.filter(function (item) {
					return self.itemIsVisible(item);
				})
				.map(function (item) {
					return React.createElement(ReactTemplates.findProspect.innerCompanyBranchRow, {
						filters: self.props.filters,
						searchString: self.props.searchString,
						dataWithSales: self.props.dataWithSales,
						item: item,
						onChange: self.props.onChange,
						key: item.id,
						tools: self.props.tools
					});
				});
		}

		var checked = this.isChecked();
		var hasSales = this.hasSales();
		var intermediate = !checked && this.hasCheckedChild();
		var disabled = this.props.data && this.props.data.children && this.props.data.children.length ? false : true;

		var value = this.formatNumber(this.props.data.value);

		return (
			<div className="all-branches-list-row">
				<div className="outer-row" onClick={this.toggleOpen}>
					<div className="row-input" onClick={ReactTemplates.TOOLS.stopProp}>
						<ReactTemplates.checkbox
							disabled={disabled}
							intermediate={intermediate}
							readOnly={true}
							className="toggle-sm checkbox-fix"
							checked={checked}
							onChange={this.onChange}
						/>
					</div>
					<div className="row-name">
						{hasSales &&
							self.props.hasBranchWithSalesRights &&
							ReactTemplates.TOOLS.withTooltip(
								ReactTemplates.findProspect.salesIcon(),
								this.lang.dollarSignTooltip,
								{ placement: 'bottom', key: 'icon' }
							)}
						{ReactTemplates.TOOLS.withTooltip(
							<span className="overflow-ellipsis row-name-text">{name}</span>,
							name,
							{ placement: 'bottom', delayShow: 500, key: 'name' }
						)}
						{!disabled && (
							<i
								style={{ color: '#d4d4d4' }}
								className={this.state.open ? 'fa fa-minus-square' : 'fa fa-plus-square'}
							/>
						)}
					</div>
					<div className="overflow-ellipsis row-value">{value}</div>
				</div>
				{rows}
			</div>
		);
	}
});

ReactTemplates.findProspect.companyWithSalesBranchRow = window.ReactCreateClass({
	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	onCompanyListClick: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		var $upModal = this.props.tools.$upModal;

		if (Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_LIST_MODAL_REACT')) {
			openModal('ClientListModal', { clients: this.props.data.names });
		} else {
			$upModal.open('clientListModal', {
				clients: this.props.data.names
			});
		}
	},
	toolTipContainer: null,
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			more: t('default.more2'),
			client: t('default.client'),
			clients: t('default.clients'),
			toAValueOf: t('soliditet.toAValueOf')
		};

		this.toolTipContainer = $('#find-prospects')[0];
	},
	onChange: function () {
		var action = this.isChecked() ? 'remove' : 'add';

		this.props.onChange([this.props.data.sni_code], action);
	},
	getClientsWithSameSniCode: function () {
		var totalClientsWithSameSniCode = 0;

		var sniCodes = this.props.sniCodes;

		for (var itr = 0; itr < sniCodes.length; ++itr) {
			var found = _.find(sniCodes[itr].children, { id: this.props.data.sni_code });

			if (found) {
				totalClientsWithSameSniCode = found.value;
				break;
			}
		}

		return totalClientsWithSameSniCode;
	},
	isChecked: function () {
		if (this.props.filters.SniCode && Array.isArray(this.props.filters.SniCode.value)) {
			return this.props.filters.SniCode.value.indexOf(this.props.data.sni_code) > -1;
		}

		return false;
	},
	render: function () {
		var AppService = this.props.tools.AppService;
		var masterCurrency = _.find(AppService.getMetadata().customerCurrencies, { masterCurrency: true });
		masterCurrency = masterCurrency ? masterCurrency.iso : 'SEK';

		var checked = this.isChecked();

		var totalClientsWithSameSniCode = this.formatNumber(this.getClientsWithSameSniCode());

		var currencyFormat = this.props.tools.$filter('currencyFormat');
		var sales = currencyFormat(this.props.data.orderValue, masterCurrency);

		var names = _.pluck(this.props.data.names, 'name');
		var total = this.props.data.names.length;
		var clientText = (total === 1 ? this.lang.client : this.lang.clients).toLowerCase();
		var subtext = total + ' ' + clientText + ' ' + this.lang.toAValueOf + ' ' + sales;

		return (
			<div key={this.props.data.sni_code} className="sales-branches-list-row">
				<div style={{ marginBottom: '2px' }} onClick={this.onChange}>
					<span className="sales-branches-input">
						<ReactTemplates.checkbox className="toggle-sm checkbox-fix" checked={checked} />
					</span>

					{ReactTemplates.TOOLS.withTooltip(
						<span className="sales-branches-sni-text overflow-ellipsis">{this.props.data.sni_text}</span>,
						this.props.data.sni_text,
						{ placement: 'bottom', delayShow: 500 }
					)}

					<span className="sales-branches-count overflow-ellipsis">{totalClientsWithSameSniCode}</span>
				</div>

				<div onClick={this.onCompanyListClick}>
					{ReactTemplates.TOOLS.withTooltip(
						<span className="sales-branches-client-names">{subtext}</span>,
						names.join('\n'),
						{ placement: 'bottom', container: this.toolTipContainer }
					)}
				</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.companyBranchFilter = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			open: false,
			onChange: function () {},
			searchString: '',
			pageSize: 25
		};
	},
	getInitialState: function () {
		return {
			data: null,
			dataWithSales: null,
			open: this.props.open,
			mode: 'all',
			searchString: '',
			loading: true,
			withSalesLoading: 0,
			page: 1
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		var accountManagerName = null;

		if (this.props.accountManager) {
			accountManagerName = this.props.accountManager.name;
		}

		this.lang = {
			title: t('default.companyBranch'),
			allTypes: t('soliditet.allTypes'),
			allTypesWithSales: t('soliditet.allTypesWithSales'),
			searchType: t('soliditet.searchCompanyType'),
			selectAll: t('default.selectAll'),
			popoverSni: t('soliditet.popoverSni'),
			noResult: t('default.noResults'),
			showMore: t('default.showMore'),
			moreInfo: t('feature.moreInfo', { name: accountManagerName }),
			upgradeToMax: t('feature.upgradeToMax'),
			branchPlaceholderInfo: t('soliditet.branchPlaceholderInfo'),
			branchPlaceholderTitle: t('soliditet.branchPlaceholderTitle')
		};

		this.search = _.debounce(this.search, 300);
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var self = this;

		if (nextProps.sniCodes !== this.props.sniCodes) {
			nextProps.sniCodes.then(function (data) {
				self.setState({
					data: data
				});
			});
		}

		if (nextProps.sniCodesWithSales !== this.props.sniCodesWithSales) {
			nextProps.sniCodesWithSales.then(function (data) {
				self.setState({
					dataWithSales: data
				});
			});
		}
	},
	resizeTimeout: null,
	resizeThrottler: function () {
		var self = this;

		if (!self.resizeTimeout) {
			self.resizeTimeout = setTimeout(function () {
				self.resizeTimeout = null;
				self.calculateHeight();
				self.setState({});
			}, 66);
		}
	},
	height: null,
	calculateHeight: function () {
		var sidebar = document.getElementById('find-prospects-sidebar');
		if (sidebar) {
			this.height = sidebar.offsetHeight - 330;
		}
	},
	componentDidMount: function () {
		var self = this;

		self.calculateHeight();
		window.addEventListener('resize', self.resizeThrottler);

		self.props.sniCodes.then(function (sniCodes) {
			self.setState({
				data: sniCodes,
				loading: false,
				withSalesLoading: ++self.state.withSalesLoading
			});
		});

		self.props.sniCodesWithSales.then(function (data) {
			self.setState({
				dataWithSales: data,
				withSalesLoading: ++self.state.withSalesLoading
			});
		});
	},
	componentWillUnmount: function () {
		window.removeEventListener('resize', this.resizeThrottler);
	},
	selectAll: function () {
		var ids = _.reduce(
			this.state.data,
			function (res, category) {
				return res.concat(_.pluck(category.children, 'id'));
			},
			[]
		);

		this.props.onChange(ids, 'replace');
	},
	deselectAll: function () {
		this.props.onChange([], 'replace');
	},
	selectAllSalesChecked: function () {
		var self = this;

		if (self.props.filters.SniCode && Array.isArray(self.props.filters.SniCode.value)) {
			var ids = _.filter(self.state.dataWithSales, this.itemWithSalesIsVisible).map(function (item) {
				return item.sni_code;
			});

			return _.intersection(ids, self.props.filters.SniCode.value).length === ids.length;
		}

		return false;
	},
	toggleSelectAllSales: function () {
		var ids = _.filter(this.state.dataWithSales, this.itemWithSalesIsVisible).map(function (item) {
			return item.sni_code;
		});

		var action = this.refs.selectAllSales.getValue() ? 'remove' : 'add';

		this.props.onChange(ids, action);
	},
	toggleMode: function () {
		$('#company-type-filter .search-input').val('');
		$('.row-table').scrollTop(0);

		this.setState({
			mode: this.state.mode === 'all' ? 'withSales' : 'all',
			page: 1,
			searchString: ''
		});
	},
	toggleMenu: function () {
		var view = !this.props.open ? 'branch' : undefined;
		this.props.toggleOpen(view);
	},
	getParents: function (id, _in, _out, fromProduct) {
		var self = this;
		var found = false;

		_in.forEach(function (item) {
			if (item.type === 'product') {
				if (fromProduct && item.id === id) {
					found = true;
				}
			} else {
				if (!fromProduct && item.id === id) {
					_out.push(item.id);
					found = true;
				} else if (self.getParents(id, item.children, _out, fromProduct)) {
					_out.push(item.id);
					found = true;
				}
			}
		});

		return found;
	},
	getProductChildIds: function (array) {
		var self = this;
		var _ids = [];

		array.forEach(function (item) {
			if (item.children) {
				_ids = _ids.concat(self.getProductChildIds(item.children));
			} else {
				_ids.push(item.id);
			}
		});

		return _ids;
	},
	getProductCategoryChildIds: function (array) {
		var self = this;
		var _ids = [];

		array.forEach(function (item) {
			if (item.children) {
				_ids.push(item.id);
				_ids = _ids.concat(self.getProductCategoryChildIds(item.children));
			}
		});

		return _ids;
	},
	findRole: function (id) {
		var self = this;

		if (!self.props.roleTree) {
			return undefined;
		}

		var _findRole = function (array, targetId) {
			var item = _.find(array, { id: targetId });

			if (item !== undefined) {
				return item;
			}

			for (var itr = 0; itr < array.length; ++itr) {
				item = _findRole(array[itr].children, targetId);

				if (item !== undefined) {
					return item;
				}
			}

			return item;
		};

		return _findRole(self.props.roleTree, id);
	},
	getRoleParentIds: function (item) {
		var _ids = [];
		var current = item;

		while (current.parent) {
			current = this.findRole(current.parent);

			if (current) {
				_ids.push(current.id);
			} else {
				break;
			}
		}

		return _ids;
	},
	getRoleChildIds: function (array) {
		var self = this;
		var _ids = [];

		array.forEach(function (item) {
			_ids.push(item.id);
			_ids = _ids.concat(self.getRoleChildIds(item.children));
		});

		return _ids;
	},
	onSearchKey: function (event) {
		var value = event.target.value;
		this.search(value);
	},
	search: function (value) {
		this.setState({
			searchString: value
		});
	},
	groupIsVisible: function (item) {
		var self = this;

		var isChecked = false;

		if (
			this.props.filters.SniCode &&
			Array.isArray(this.props.filters.SniCode.value) &&
			item.children &&
			item.children.length
		) {
			isChecked = true;

			_.each(item.children, function (sniCode) {
				if (_.indexOf(self.props.filters.SniCode.value, sniCode.id) === -1) {
					isChecked = false;
				}
			});
		}

		var matchesSearchString = item.name.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1;

		var hasVisibleChild =
			_.filter(item.children, function (element) {
				return self.itemIsVisible(element);
			}).length > 0;

		return hasVisibleChild || isChecked || matchesSearchString;
	},
	itemIsVisible: function (item) {
		var isChecked = false;

		if (this.props.filters.SniCode && Array.isArray(this.props.filters.SniCode.value)) {
			isChecked = this.props.filters.SniCode.value.indexOf(item.sni_code) > -1;
		}

		return isChecked || item.name.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1;
	},
	itemWithSalesIsVisible: function (item) {
		var isChecked = false;

		if (this.props.filters.SniCode && Array.isArray(this.props.filters.SniCode.value)) {
			isChecked = this.props.filters.SniCode.value.indexOf(item.sni_code) > -1;
		}

		return isChecked || item.sni_text.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1;
	},
	incrementPage: function () {
		this.setState({
			page: this.state.page + 1
		});
	},
	render: function () {
		var self = this;

		var classes = {
			active: 'btn btn-sm no-shadow btn-green up-btn type-toggle-buttons',
			disabled: 'btn btn-sm btn-light-grey no-shadow up-btn type-toggle-buttons'
		};

		var productSelect = React.createElement(ReactTemplates.findProspect.select, {
			key: 'productSelect',
			data: this.props.productTree,
			buttonText: function () {
				var sniFilters = self.props.sniFilters;
				var translate = Tools.$translate;

				var nrSelected = sniFilters.Product && sniFilters.Product.value && sniFilters.Product.value.length;

				if (nrSelected) {
					var word = nrSelected === 1 ? 'default.product' : 'default.products';

					return nrSelected + ' ' + translate(word);
				} else {
					return translate('default.all') + ' ' + translate('default.products');
				}
			},
			onClear: function () {
				self.props.onSniFilterChange('Product', {
					value: [],
					comparisonType: 'Equals'
				});
				self.props.onSniFilterChange('ProductCategory', {
					value: [],
					comparisonType: 'Equals'
				});
			},
			search: self.props.useProductSearch ? self.props.productSearch : null,
			types: {
				product: {
					onChange: function (item, action) {
						var sniFilters = self.props.sniFilters;
						var categoryIds, categoryValue;
						var productIds = [item.id];
						var productValue =
							sniFilters.Product && Array.isArray(sniFilters.Product.value)
								? sniFilters.Product.value
								: [];

						switch (action) {
							case 'add':
								self.props.onSniFilterChange('Product', {
									value: _.union(productValue, productIds),
									comparisonType: 'Equals'
								});

								break;
							case 'remove':
								categoryValue =
									sniFilters.ProductCategory && Array.isArray(sniFilters.ProductCategory.value)
										? sniFilters.ProductCategory.value
										: [];
								categoryIds = [];
								self.getParents(item.id, self.props.productTree, categoryIds, true);

								self.props.onSniFilterChange('ProductCategory', {
									value: _.difference(categoryValue, categoryIds),
									comparisonType: 'Equals'
								});

								self.props.onSniFilterChange('Product', {
									value: _.difference(productValue, productIds),
									comparisonType: 'Equals'
								});

								break;
						}
					},
					isSelected: function (item) {
						var sniFilters = self.props.sniFilters;

						if (sniFilters.Product && Array.isArray(sniFilters.Product.value)) {
							return sniFilters.Product.value.indexOf(item.id) > -1;
						} else {
							return false;
						}
					}
				},
				productCategory: {
					onChange: function (item, action) {
						var sniFilters = self.props.sniFilters;
						var productIds, categoryIds, categoryValue, productValue;

						switch (action) {
							case 'add':
								productIds = self.getProductChildIds(item.children);
								categoryIds = self.getProductCategoryChildIds(item.children);
								categoryIds.push(item.id);

								categoryValue =
									sniFilters.ProductCategory && Array.isArray(sniFilters.ProductCategory.value)
										? sniFilters.ProductCategory.value
										: [];

								self.props.onSniFilterChange('ProductCategory', {
									value: _.union(categoryValue, categoryIds),
									comparisonType: 'Equals'
								});

								if (productIds.length) {
									productValue =
										sniFilters.Product && Array.isArray(sniFilters.Product.value)
											? sniFilters.Product.value
											: [];

									self.props.onSniFilterChange('Product', {
										value: _.union(productValue, productIds),
										comparisonType: 'Equals'
									});
								}

								break;
							case 'remove':
								categoryIds = [];
								self.getParents(item.id, self.props.productTree, categoryIds, false);

								self.props.onSniFilterChange('ProductCategory', {
									value: _.difference(sniFilters.ProductCategory.value, categoryIds),
									comparisonType: 'Equals'
								});

								break;
						}
					},
					isSelected: function (item) {
						var sniFilters = self.props.sniFilters;

						if (sniFilters.ProductCategory && Array.isArray(sniFilters.ProductCategory.value)) {
							return sniFilters.ProductCategory.value.indexOf(item.id) > -1;
						} else {
							return false;
						}
					},
					style: {
						fontWeight: 'bold'
					}
				}
			},
			tools: self.props.tools
		});

		var dateSelect = React.createElement(ReactTemplates.findProspect.select, {
			key: 'dateSelect',
			data: this.props.datePresets,
			buttonText: function () {
				var sniFilters = self.props.sniFilters;
				var translate = Tools.$translate;

				if (sniFilters.Date && sniFilters.Date.value && sniFilters.Date.value.preset) {
					var key = 'date.' + sniFilters.Date.value.preset;

					if (key === 'date.prev12Month') {
						key = 'date.prev12MonthShort';
					}

					return translate(key);
				} else {
					return translate('date.whenever');
				}
			},
			onClear: function () {
				self.props.onSniFilterChange('Date', {
					value: null,
					comparisonType: 'Equals'
				});
			},
			onChange: function (item) {
				var sniFilters = self.props.sniFilters;
				var value = {
					preset: item.id
				};

				if (sniFilters.Date && sniFilters.Date.value && sniFilters.Date.value.preset === item.id) {
					value = null;
				}

				self.props.onSniFilterChange('Date', {
					value: value
				});
			},
			isSelected: function (item) {
				var sniFilters = self.props.sniFilters;

				if (sniFilters.Date && sniFilters.Date.value) {
					return sniFilters.Date.value.preset === item.id;
				} else {
					return false;
				}
			},
			tools: self.props.tools
		});

		var roleSelect = React.createElement(ReactTemplates.findProspect.select, {
			key: 'roleSelect',
			data: this.props.roleTree,
			buttonText: function () {
				var sniFilters = self.props.sniFilters;
				var translate = Tools.$translate;

				if (sniFilters.Role && Array.isArray(sniFilters.Role.value) && sniFilters.Role.value.length) {
					var word = sniFilters.Role.value.length === 1 ? 'default.role' : 'default.roles';

					return sniFilters.Role.value.length + ' ' + translate(word);
				} else {
					return translate('default.all') + ' ' + translate('default.roles');
				}
			},
			onClear: function () {
				self.props.onSniFilterChange('Role', {
					value: [],
					comparisonType: 'Equals'
				});
			},
			onChange: function (item, action) {
				var sniFilters = self.props.sniFilters;
				var ids;

				if (sniFilters.Role && Array.isArray(sniFilters.Role.value)) {
					var value = sniFilters.Role.value.slice();

					ids = self.getRoleChildIds([item]);
					ids.push(item.id);

					switch (action) {
						case 'add':
							value = _.union(value, ids);
							break;
						case 'remove':
							value = _.difference(value, ids);
							break;
						case 'replace':
							value = ids;
							break;
					}

					self.props.onSniFilterChange('Role', {
						value: value,
						comparisonType: 'Equals'
					});
				} else {
					ids = self.getRoleChildIds([item]);
					ids.push(item.id);

					self.props.onSniFilterChange('Role', {
						value: ids,
						comparisonType: 'Equals'
					});
				}
			},
			isSelected: function (item) {
				var sniFilters = self.props.sniFilters;

				if (sniFilters.Role && Array.isArray(sniFilters.Role.value)) {
					return sniFilters.Role.value.indexOf(item.id) > -1;
				} else {
					return false;
				}
			},
			tools: self.props.tools
		});

		var rows = null;

		if (this.state.mode === 'all') {
			if (Array.isArray(self.state.data)) {
				rows = self.state.data
					.filter(function (sniGroup) {
						return self.groupIsVisible(sniGroup);
					})
					.map(function (sniGroup) {
						return React.createElement(ReactTemplates.findProspect.companyBranchRow, {
							data: sniGroup,
							dataWithSales: self.state.dataWithSales,
							open: self.state.searchString ? true : false,
							filters: self.props.filters,
							onChange: self.props.onChange,
							tools: self.props.tools,
							key: sniGroup.name,
							searchString: self.state.searchString,
							hasBranchWithSalesRights: self.props.hasBranchWithSalesRights
						});
					});
			}
		} else {
			if (self.state.dataWithSales && self.state.data && self.props.hasBranchWithSalesRights) {
				rows = _.filter(self.state.dataWithSales, function (sniGroup) {
					return self.itemWithSalesIsVisible(sniGroup);
				})
					.sort(function (a, b) {
						return a.orderValue > b.orderValue ? -1 : 1;
					})
					.filter(function (_, index) {
						return index < self.state.page * self.props.pageSize;
					})
					.map(function (sniGroup) {
						return React.createElement(ReactTemplates.findProspect.companyWithSalesBranchRow, {
							data: sniGroup,
							sniCodes: self.state.data,
							filters: self.props.filters,
							onChange: self.props.onChange,
							tools: self.props.tools,
							key: sniGroup.sni_code,
							searchString: self.state.searchString
						});
					});

				if (_.size(self.state.dataWithSales) > self.state.page * self.props.pageSize) {
					rows.push(
						<div className="show-more" onClick={self.incrementPage}>
							{self.lang.showMore}
							<br />
							<i className="fa fa-sort-desc" />
						</div>
					);
				}
			}
		}

		var list = null;

		if (this.props.open) {
			list = [];

			list.push(
				<div className="type-toggle-buttons-container filter-row" key="buttons">
					<div style={{ width: '100%' }} className="btn-group">
						<button
							onClick={this.toggleMode}
							className={this.state.mode === 'all' ? classes.active : classes.disabled}
						>
							{self.lang.allTypes}
						</button>
						<button
							onClick={this.toggleMode}
							className={this.state.mode === 'withSales' ? classes.active : classes.disabled}
						>
							{ReactTemplates.findProspect.salesIcon()}
							{self.lang.allTypesWithSales}
						</button>
					</div>
				</div>
			);

			if (this.state.mode === 'withSales' && rows) {
				list.push(
					<div className="order-filters filter-row" key="salesFilters">
						{productSelect}
						{roleSelect}
						{dateSelect}
					</div>
				);

				if (rows.length && this.state.withSalesLoading === 2) {
					list.push(
						<div className="select-all filter-row" key="selectAll">
							<button onClick={this.selectAll} style={{ padding: '0' }} className="btn btn-link">
								{this.lang.selectAll}
							</button>
							<button
								className="up-btn btn-grey btn-link btn-hover-red pull-right"
								style={{ height: ' 18px', padding: '0', lineHeight: '18px' }}
								onClick={this.deselectAll}
							>
								<i className="fa fa-trash" />
							</button>
						</div>
					);
				}
			} else if (this.state.mode === 'withSales' && !self.props.hasBranchWithSalesRights) {
				var branchPlaceholder = React.createElement(ReactTemplates.findProspect.branchPlaceholder, {
					branchPlaceholderTitle: self.lang.branchPlaceholderTitle,
					branchPlaceholderInfo: self.lang.branchPlaceholderInfo,
					moreInfo: self.lang.moreInfo,
					upgradeToMax: self.lang.upgradeToMax,
					user: self.props.accountManager,
					avatarService: self.props.tools.avatarService
				});

				list.push(branchPlaceholder);
			} else {
				list.push(
					<div className="search-wrap filter-row" key="qsearch">
						<b className="fa fa-search" />
						<input
							className="search-input"
							type="text"
							placeholder={this.lang.searchType}
							defaultValue={this.state.searchString}
							onChange={this.onSearchKey}
						/>
					</div>
				);

				if (rows && rows.length && !this.state.loading) {
					list.push(
						<div className="select-all filter-row" key="selectAll">
							<button onClick={this.selectAll} style={{ padding: '0' }} className="btn btn-link">
								{this.lang.selectAll}
							</button>
							<button
								className="up-btn btn-grey btn-link btn-hover-red pull-right"
								style={{ height: ' 18px', padding: '0', lineHeight: '18px' }}
								onClick={this.deselectAll}
							>
								<i className="fa fa-trash" />
							</button>
						</div>
					);
				}
			}

			if (this.state.mode === 'withSales' && this.state.withSalesLoading !== 2) {
				list.push(
					<div className="loading-row filter-row" key="loading">
						<i className="fa fa-refresh fa-spin fa-fw" />
					</div>
				);
			} else if (this.state.mode === 'all' && this.state.loading) {
				list.push(
					<div className="loading-row filter-row" key="loading">
						<i className="fa fa-refresh fa-spin fa-fw" />
					</div>
				);
			} else if (rows && rows.length) {
				var tabelClasses =
					this.state.mode === 'withSales' ? 'row-table row-table-sales filter-row' : 'row-table filter-row';
				var style = null;
				if (!this.props.washWarning) {
					style = { maxHeight: this.height };
				}
				list.push(
					<div className={tabelClasses} style={style} key="row">
						{rows}
					</div>
				);
			} else if (this.state.mode !== 'withSales' || self.props.hasBranchWithSalesRights) {
				list.push(
					<div className="no-result filter-row" key="noResult">
						{this.lang.noResult}
					</div>
				);
			}
		}

		var value = null;

		if (
			this.props.filters.SniCode &&
			Array.isArray(this.props.filters.SniCode.value) &&
			this.props.filters.SniCode.value.length
		) {
			value = this.props.filters.SniCode.value.length;
		}

		var rowHeaderClasses = self.props.open ? 'row-header open' : 'row-header';

		return (
			<div id="company-type-filter">
				<div className={rowHeaderClasses} onClick={self.toggleMenu}>
					<span className="row-header-icon-wrap">
						<i className={'fa fa-sitemap row-header-icon'} />
					</span>

					<span className="row-header-title">{self.lang.title}</span>
					<span className="row-header-value">
						<span>{value}</span>
					</span>
				</div>
				<div>{list}</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.branchPlaceholder = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	render: function () {
		var user = this.props.user;

		return (
			<div id="feature-sa-placeholder">
				<div className="content">
					<div className="logo">
						<div className="logo-text">{this.props.upgradeToMax}</div>
					</div>
					<div className="title">{this.props.branchPlaceholderTitle}</div>
					<div className="info">{this.props.branchPlaceholderInfo}</div>
					<div className="contact-info">
						{this.props.moreInfo}
						{':'}
					</div>
				</div>
				<div className="bottom-info">
					<div className="avatar">
						<ReactTemplates.TOOLS.avatar user={user} size={40} avatarService={this.props.avatarService} />
					</div>
					<div className="contact-info">
						<div>{user.name}</div>
						<div className="small">{user.email}</div>
						<div className="small">{user.phone} </div>
					</div>
				</div>
			</div>
		);
	}
});

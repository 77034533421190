import Bem from '@upsales/components/Utils/bemClass';

const DragHandle = () => {
	const AdminSalesboardColumnBem = new Bem('AdminSalesboardColumn');

	return (
		<div className={AdminSalesboardColumnBem.elem('drag-handler').b()}>
			<div>{'.......'}</div>
			<div>{'.......'}</div>
		</div>
	);
};

export default DragHandle;

import async from 'async';

angular.module('upModals.controllers').controller('ViewSubmit', [
	'$scope',
	'$q',
	'$modalParams',
	'AppService',
	'$upModal',
	'Form',
	'FormSubmit',
	'Contact',
	'Account',
	function ($scope, $q, $modalParams, AppService, $upModal, Form, FormSubmit, Contact, Account) {
		var customerId = AppService.getCustomerId();
		var submitId = $modalParams.id;
		$scope.loading = true;
		$scope.formTitle = '';
		$scope.form = null;
		$scope.submit = null;
		$scope.client = null;
		$scope.contact = null;
		$scope.formSubmit = null;

		async.series(
			[
				function (cb) {
					var promises = {};
					var accountPromise = $q.defer();
					var contactPromise = $q.defer();
					var formPromise = $q.defer();

					promises.submit = FormSubmit.customer(customerId).get(submitId);
					promises.client = accountPromise.promise;
					promises.contact = contactPromise.promise;
					promises.form = formPromise.promise;

					promises.submit
						.then(function (res) {
							res = res.data;
							if (res.client && res.client.id) {
								Account.customer(customerId)
									.get(res.client.id)
									.then(accountPromise.resolve)
									.catch(e => console.log('get account error', e));
							} else {
								accountPromise.resolve({});
							}

							if (res.contact && res.contact.id) {
								Contact.customer(customerId)
									.get(res.contact.id)
									.then(contactPromise.resolve)
									.catch(e => console.log('get contact error', e));
							} else {
								contactPromise.resolve({});
							}

							if (res.form && res.form.id) {
								Form.get(res.form.id)
									.then(formPromise.resolve)
									.catch(e => console.log('get form error', e));
							} else {
								formPromise.resolve({});
							}
						})
						.catch(e => console.log('get submit error', e));

					$q.all(promises)
						.then(function (res) {
							$scope.client = res.client.data;
							$scope.submit = res.submit.data;
							$scope.contact = res.contact.data;
							$scope.form = res.form.data;
							$scope.date = $scope.submit.regDate;
							$scope.clientAddress = _.find($scope.client.addresses, { type: 'Mail' });
							cb();
						})
						.catch(e => console.log('view submit error', e));
				}
			],
			function () {
				$scope.loading = false;
			}
		);
	}
]);

'use strict';

import RoleSelect from 'Components/RoleSelect';

angular.module('domain.admin').controller('EditDocumentTempalteCtrl', [
	'$scope',
	'URL',
	'API',
	'AppService',
	'NotificationService',
	'$modalParams',
	'$translate',
	'FileUploader',
	'DocumentTemplate',
	'ResourceHelper',
	function (
		$scope,
		URL,
		API,
		AppService,
		NotificationService,
		$modalParams,
		$translate,
		FileUploader,
		DocumentTemplate,
		ResourceHelper
	) {
		var Ctrl = this,
			customerId = AppService.getCustomerId();

		Ctrl.types = null;
		Ctrl.file = null;
		Ctrl.fakeFile = null;
		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'documentTemplate',
			createSuccessBody: 'saved.documentTemplate',
			updateSuccessBody: 'updated.documentTemplate',
			createErrorBody: 'saveError.documentTemplate',
			updateErrorBody: 'saveError.documentTemplate',
			deleteErrorBody: 'deleteError.documentTemplate'
		});

		var types = [
			{
				name: $translate.instant('default.order'),
				id: 'order'
			},
			{
				name: $translate.instant('default.agreement'),
				id: 'agreement'
			},
			{
				name: $translate.instant('feature.companiesAndContacts'),
				id: 'client'
			},
			{
				name: $translate.instant(
					Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
						? 'default.appointment'
						: 'default.activityAndAppointment'
				),
				id: 'activity'
			}
		];

		var udoTypes = _.map(AppService.getMetadata().params.UserDefinedObject, function (udo) {
			return {
				id: 'object' + udo.id,
				name: udo.name
			};
		});

		var dragenter = function (e) {
			e.stopPropagation();
			e.preventDefault();

			$('#document-template-drag-icon').addClass('bounce');
		};

		var dragover = function (e) {
			e.stopPropagation();
			e.preventDefault();
		};

		var dragleave = function (e) {
			e.stopPropagation();
			e.preventDefault();

			var fileWrapper = document.getElementById('file-wrapper');

			if (!fileWrapper.contains(e.fromElement)) {
				$('#document-template-drag-icon').removeClass('bounce');
			}
		};

		function drop(e) {
			e.stopPropagation();
			e.preventDefault();

			$('#document-template-drag-icon').removeClass('bounce');

			var dt = e.dataTransfer;
			var files = dt.files;

			if (files && files[0]) {
				if (files[0].type !== 'application/msword') {
					NotificationService.addNotification({
						title: 'default.error',
						body: 'admin.documentTemplateModal.whrongFileType',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				} else if (files[0].size > 8388608) {
					NotificationService.addNotification({
						title: 'default.error',
						body: 'admin.documentTemplateModal.toLargeFile',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				} else {
					Ctrl.uploader.addToQueue(files);
					Ctrl.item = Ctrl.uploader.queue[0];
				}
			}
		}

		setTimeout(function () {
			var dropzone = document.getElementById('file-wrapper');
			dropzone.addEventListener('dragenter', dragenter, false);
			dropzone.addEventListener('dragover', dragover, false);
			dropzone.addEventListener('dragleave', dragleave, false);
			dropzone.addEventListener('drop', drop, false);
		}, 0);

		Ctrl.types = types.concat(udoTypes);

		Ctrl.fileUploadConfig = {
			url: URL + API + customerId + '/documentTemplates/upload',
			withCredentials: true,
			acceptedFiles: 'application/msword',
			autoUpload: false,
			onAfterAddingFile: function (item) {
				if (item.file.size > 8388608) {
					item.cancel();
					item.remove();

					NotificationService.addNotification({
						title: 'default.error',
						body: 'admin.documentTemplateModal.toLargeFile',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				} else {
					Ctrl.file = item;
				}
			},
			onErrorItem: function (item) {
				item.cancel();
				item.remove();
				Ctrl.file = null;

				helper.onSaveError({}, !Ctrl.documentTemplate.id);
			},
			onSuccessItem: function (item, res) {
				if (res.error || !res.data) {
					item.cancel();
					item.remove();
					Ctrl.file = null;

					helper.onSaveError({}, !Ctrl.documentTemplate.id);
				} else {
					helper.onSave({}, res.data, !Ctrl.documentTemplate.id);
					$scope.resolve(res.data);
				}
			}
		};

		Ctrl.uploader = new FileUploader(Ctrl.fileUploadConfig);

		var defaultDocumentTemplate = {
			name: null,
			type: null,
			roles: []
		};

		Ctrl.documentTemplate = $modalParams.documentTemplate
			? _.cloneDeep($modalParams.documentTemplate)
			: defaultDocumentTemplate;

		if (Ctrl.documentTemplate.id) {
			Ctrl.fakeFile = Ctrl.documentTemplate.name + '.doc';
		}

		Ctrl.removeFakeFile = function () {
			Ctrl.fakeFile = null;
		};

		Ctrl.saveIsDisabled = function () {
			if (Ctrl.saving) {
				return true;
			}

			if (!Ctrl.documentTemplate.id) {
				return !Ctrl.documentTemplate.name || !Ctrl.documentTemplate.type || !Ctrl.file;
			} else {
				var sameName = Ctrl.documentTemplate.name === $modalParams.documentTemplate.name;
				var sameType = Ctrl.documentTemplate.type === $modalParams.documentTemplate.type;
				var sameFile = !Ctrl.file;
				var hasFile = Ctrl.fakeFile || Ctrl.file;
				var sameRoles = _.isEqual(
					_.map(Ctrl.documentTemplate.roles, 'id'),
					_.map($modalParams.documentTemplate.roles, 'id')
				);

				if (!hasFile) {
					return true;
				} else {
					return sameName && sameType && sameRoles && sameFile;
				}
			}
		};

		Ctrl.save = function () {
			if (Ctrl.file) {
				Ctrl.file.formData.push({ name: Ctrl.documentTemplate.name });
				Ctrl.file.formData.push({ type: Ctrl.documentTemplate.type });
				Ctrl.file.formData.push({ roles: JSON.stringify(Ctrl.documentTemplate.roles ?? []) });

				if (Ctrl.documentTemplate.id) {
					Ctrl.file.formData.push({ id: Ctrl.documentTemplate.id });
				}

				Ctrl.file.upload();
			} else {
				Ctrl.saving = true;
				var documentTemplate = _.pick(Ctrl.documentTemplate, ['id', 'name', 'roles', 'type']);

				DocumentTemplate.customer(customerId)
					.save(documentTemplate)
					.then(function (res) {
						$scope.resolve(res.data);
						Ctrl.saving = false;
					})
					.catch(e => console.error(e));
			}
		};

		Ctrl.removeAttachment = function () {
			Ctrl.file.cancel();
			Ctrl.file.remove();
			Ctrl.file = null;
		};

		Ctrl.roleTreeChanged = function (roles) {
			Ctrl.documentTemplate.roles = roles;
		};

		Ctrl.hasRoleFeature = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED);
		Ctrl.hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');
		Ctrl.documentRoles = AppService.getRoleMap().document;

		Ctrl.RoleSelect = RoleSelect;

		$scope.$on('$destroy', function () {
			var dropzone = document.getElementById('file-wrapper');

			if (dropzone) {
				dropzone.removeEventListener('dragenter', dragenter);
				dropzone.removeEventListener('dragover', dragover);
				dropzone.removeEventListener('dragleave', dragleave);
				dropzone.removeEventListener('drop', drop);
			}
		});
	}
]);

import * as Sentry from '@sentry/browser';
import { getCircularReplacer } from 'App/babel/utils';
import logError from 'App/babel/helpers/logError';
import { modalTracker } from 'App/babel/helpers/Tracker';
import { closeElevioWidgets } from 'App/helpers/appHelper';

// TODO:
// 	* Fix experimental hash-stuff

angular.module('upUi').provider('$upModal', function () {
	// object to hold modal-configs
	var modals = {};

	// Array of all the open modals
	var openModals = [];

	// Add curtain to body
	var modalCurtain = angular.element('<div class=up-modal-curtain>').appendTo('body');

	// function for register modals during config state of app
	this.modal = function (name, options) {
		if (!options) {
			throw new Error('No options object for modal: ' + name);
		}
		options.name = name;
		modals[name] = options;
		modals[name].$compiled = null;

		return this;
	};

	// The modal service/factory
	this.$get = [
		'$q',
		'$http',
		'$templateCache',
		'$rootScope',
		'$compile',
		'$controller',
		'$injector',
		'$animate',
		'$state',
		'$parse',
		'VoiceService',
		function (
			$q,
			$http,
			$templateCache,
			$rootScope,
			$compile,
			$controller,
			$injector,
			$animate,
			$state,
			$parse,
			VoiceService
		) {
			let openedTimeStamp;
			let isEdit;

			var closeTopModal = function (state) {
				if (!openModals.length) {
					return false;
				}
				var prevented = openModals[openModals.length - 1].close(true, 'State changed', state);
				if (prevented) {
					return true;
				} else {
					return closeTopModal();
				}
			};

			var parseStateRef = function (ref) {
				var parsed = ref.replace(/\n/g, ' ').match(/^([^(]+?)\s*(\((.*)\))?$/);
				if (!parsed || parsed.length !== 4) {
					return null;
				}
				return { state: parsed[1], paramExpr: parsed[3] || null };
			};

			var getResumeState = function (stateObj) {
				return function (mergeParams) {
					var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

					$state.go(stateObj.state.name, stateParams);
				};
			};

			// bind close to location change if any modal is open
			var closeTimeout;

			function handleStateChange(e, toState, toStateParams) {
				if (openModals.length) {
					if (openModals[openModals.length - 1].noCloseOnStateChange) {
						return;
					}
					e.preventDefault();

					if (closeTimeout) {
						clearTimeout(closeTimeout);
					}

					closeTimeout = setTimeout(function () {
						var stateObj = { state: toState, stateParams: toStateParams };
						var prevented = closeTopModal(stateObj);

						if (!prevented) {
							getResumeState(stateObj)();
						}
					});
				}
			}

			$rootScope.$on('$stateChangeStart', handleStateChange);

			// open modal from register
			function OpenModal(name, params, event) {
				if (!modals[name]) {
					throw new Error('No modal with the name: ' + name);
				}

				var config = modals[name];

				params = params || {};

				params.$modalName = name;

				$rootScope.$broadcast('$upModal.open', name, params);
				return new Modal(config, params, event);
			}

			// Create custom modal on the fly
			function CreateModal(config, params) {
				return new Modal(config, params);
			}

			// INIT NEW MODAL
			function Modal(config, modalParams) {
				modalParams = modalParams || {};

				// check for template
				if (!config.template && !config.templateUrl && !config.templateProvider) {
					throw new Error('upModal factory must have template, templateProvider or templateUrl');
				}

				var container = angular.element(config.container || document.body);
				var closeOnEscape = config.closeOnEscape === undefined ? true : !!config.closeOnEscape;
				var controller = config.controller || angular.noop;
				var controllerProvider = config.controllerProvider;
				var closeCallback = config.onClose || angular.noop;
				var notify = config.notify || angular.noop;
				var $window = angular.element(window);
				var $document = angular.element(document);
				var resolveObj = config.resolve || {};
				var modalDefered = $q.defer();
				var closeOnCurtain = config.closeOnCurtain;
				var margin = 20;
				var topMargin = 88;
				var modalObject = {};
				var disableBindings = config.disableBindings !== undefined ? config.disableBindings : true;

				openedTimeStamp = new Date();
				isEdit = !!modalParams.id;

				var style = null;
				var inlineStyle = null;
				if (config.style && typeof config.style === 'function') {
					style = $injector.invoke(config.style, {}, { $modalParams: modalParams }) || null;
				} else {
					style = config.style || null;
				}

				if (config.inlineStyle && typeof config.inlineStyle === 'function') {
					inlineStyle = $injector.invoke(config.inlineStyle, {}, { $modalParams: modalParams }) || null;
				} else {
					inlineStyle = config.inlineStyle || null;
				}

				if (config.inlineStyle && typeof config.inlineStyle === 'function') {
					inlineStyle = $injector.invoke(config.inlineStyle, {}, { $modalParams: modalParams }) || null;
				} else {
					inlineStyle = config.inlineStyle || null;
				}
				var getPrevModal = function () {
					return openModals[openModals.length - 1];
				};

				var disableScroll = function () {
					var body = angular.element(document.body);
					var oldWidth = body.innerWidth();
					body.css('overflow', 'hidden');
					body.width(oldWidth);
					body.addClass('modal-open');
				};

				var enableScroll = function () {
					var body = angular.element(document.body);
					body.css('overflow', 'auto');
					body.width('auto');
					body.removeClass('modal-open');
				};

				var placeModalAboveDrawer = (modalElement, modalCurtain) => {
					// Check for any open react modal to make sure modal is on top
					const openReactModals = document.querySelectorAll('.Modals__modal');
					if (openReactModals.length) {
						modalElement.addClass('above-react-modal');
						modalCurtain?.addClass('above-react-modal');
					} else {
						modalCurtain?.removeClass('above-react-modal');
					}

					return document.querySelectorAll('.Modals__modal--ascended');
				};

				// open function
				var openFunction = function (modalElement, callback) {
					// blur current focused btn/link
					angular.element(':focus').blur();

					// Update hash (we cannot allow sub modals to hash right now)
					if (config.hash) {
						// EXPERIMENTAL
						// Fix modal params reference before this
						// $location.search('modal', config.name);
						// $location.search('modalP', JSON.stringify(modalParams));
					}

					// show modal
					modalElement.show();
					if (callback && typeof callback === 'function') {
						callback();
					}

					// disable scroll
					disableScroll();

					closeElevioWidgets();
				};

				var getPosition = function () {
					var modalElement = modalObject.element;
					var hasControls;

					// Clone the modal to calculate the height
					var clone = modalElement
						.clone()
						.removeClass('full-height compiled')
						.addClass('measuring')
						.appendTo('body');

					var controls = clone.find('.up-modal-controls');
					if (!controls.length) {
						controls = clone.find('.ModalControls');
					}
					if (controls.length && controls.is(':visible')) {
						hasControls = true;
					} else {
						hasControls = false;
					}

					// The total height we have to play with
					var windowHeight = $window.height();

					// Make room for phone (minimized at least)
					var ongoingCall = VoiceService.callInProgress();
					if (ongoingCall) {
						windowHeight -= 50;
					}

					// Check if we are too high
					var tooHigh = clone.outerHeight() > windowHeight - margin - topMargin;

					var size = {
						height: (tooHigh ? windowHeight - margin * 2 : clone.outerHeight()) + 'px',
						tooHigh: tooHigh,
						hasControls: hasControls
					};
					size.numHeight = parseInt(size.height);

					size.compareStr = size.height + '.' + size.tooHigh + '' + size.hasControls;
					modalObject.sizeCompareStr = size.compareStr;

					// Just to ensure IE not setting height to 0..
					if (size.height === '0px') {
						size.height = '500px';
					}

					// Remove the clone when we are done with it yeah!
					clone.remove();

					return size;
				};

				// set max height and center modal
				var setMaxHeight = function (pos, resize) {
					if (!modalObject || modalObject.constantHeight) {
						return;
					}

					var modalElement = modalObject.element;
					var scope = modalObject.scope;
					var changed = false;

					// Emit event
					scope.$broadcast('modal.positionChangeStart', { modalElement: modalElement });

					if (modalElement.hasClass('initially-hidden')) {
						modalElement.removeClass('initially-hidden');
					}

					if (style && style.indexOf('fullscreen') !== -1) {
						if (!modalElement.hasClass('fullscreen') || resize) {
							modalElement.addClass('fullscreen');
							changed = true;
						}
					} else if (style && style.indexOf('new-full-screen') !== -1) {
						if (!modalElement.hasClass('new-full-screen') || resize) {
							modalElement.addClass('new-full-screen');
							changed = true;
						}
					} else {
						// Save local compareStr
						var compareStr = modalObject.sizeCompareStr;

						// modal size
						pos = pos || getPosition();

						// See if we should trigger event
						if (!pos.compareStr || compareStr !== pos.compareStr) {
							changed = true;
						}

						// set modal content margin bottom
						if (pos.hasControls) {
							modalElement.addClass('has-controls');
						} else {
							modalElement.removeClass('has-controls');
						}

						var position = {
							bottom: pos.bottom,
							height: pos.height,
							width: pos.width,
							left: pos.left,
							marginLeft: pos.marginLeft
						};

						if (
							!modalElement.hasClass('form') &&
							!modalElement.hasClass('form-sm') &&
							!modalElement.hasClass('form-wide')
						) {
							position.top = pos.top;
						}

						// position the modal if we have custom position
						if (config.position) {
							modalElement.position(config.position);
							modalElement.css('height', position.height);
						} else {
							modalElement.addClass('full-height');
							modalElement.css(position);
							if (config.rememberHeight) {
								config.rememberedHeight = pos.numHeight;
							}
						}

						if (pos.tooHigh) {
							modalElement.addClass('full-height');
						} else {
							modalElement.removeClass('full-height');
						}
					}

					// Emit event
					if (changed) {
						scope.$broadcast('modal.positionChanged', { modalElement: modalElement });
					}
				};

				let resizeTimeout;
				var resizeFunc = function () {
					clearTimeout(resizeTimeout);
					resizeTimeout = setTimeout(() => {
						setMaxHeight(null, true);
						modalObject.element.position(config.position);
					}, 300);
				};

				var setPromiseOnScope = function (scope) {
					// new promise
					var defered = $q.defer();

					// listeners
					// eslint-disable-next-line promise/catch-or-return
					defered.promise.then(
						function (result) {
							modalObject.close(false, result);
						},
						function (error) {
							modalObject.close(true, error);
						}
					);

					// extend modal promise on scope
					angular.extend(scope, defered);

					// .. and the good old close function
					scope.close = defered.reject;
				};

				// Removes all events
				var removeEvents = function () {
					// Unsubscribe events if this was the last open modal
					$window.off('resize', resizeFunc);
					$document.off('keyup', keyboardEvent);
				};

				// close function
				modalObject.close = function (reject, result, stateObj) {
					try {
						const diffInSec = Math.round((new Date().getTime() - openedTimeStamp.getTime()) / 1000);
						const { OLD_MODAL_SERVICE } = modalTracker.events;
						modalTracker.track(OLD_MODAL_SERVICE, {
							openTime: diffInSec,
							modal: modalParams.$modalName,
							reject,
							isEdit
						});
					} catch (error) {
						logError(error, 'Failed to track old modal service');
					}

					var localScope = modalObject.scope;
					var prevented = false;

					if (localScope) {
						// emit event on local scope so we have the chance to prevent close for some reason
						var e = localScope.$emit(reject ? 'modal.rejected' : 'modal.resolved', result);

						// Setup state stuff on event
						if (stateObj) {
							e.state = stateObj.state;
							e.resumeState = getResumeState(stateObj);
						} else {
							e.state = null;
							e.resumeState = angular.noop;
						}

						if (e.defaultPrevented) {
							// set new promise on local scope
							setPromiseOnScope(localScope);
							prevented = true;
							return prevented;
						}
					}

					// Remove modal hash if we had one
					// if(config.hash) {
					// EXPERIMENTAL
					// $location.search('modal', null);
					// $location.search('modalP', null);
					// }

					// destroy scope
					if (localScope) {
						localScope.$destroy();
						localScope = null;
					}

					// remove modalElement
					if (modalObject.element) {
						modalObject.element.remove();
					}

					// unset curtain event
					if (closeOnCurtain) {
						modalCurtain.off('click', curtainClick);
					}

					// Enable scroll and unsubscribe events if this is the last open modal
					if (openModals.length !== 1) {
						// Show prev modal
						if (modalObject.prevModal && modalObject.prevModal.element) {
							modalObject.prevModal.element.removeClass('hidden');

							if (modalObject.prevModal.element[0].classList.contains('new-full-screen')) {
								modalCurtain.addClass('full-screen-curtain');
							} else {
								if (modalCurtain != null) {
									modalCurtain.removeClass('full-screen-curtain');
								}
							}
							// Move curtain behind the most-top open modal
							if (modalCurtain != null) {
								modalCurtain.insertBefore(modalObject.prevModal.element);
							}
						}
					} else {
						// Enable scroll again
						enableScroll();

						// Remove events
						removeEvents();

						// remove rootscope reference
						$rootScope.$upModalOpen = false;

						// hide curtain
						modalCurtain.hide();

						modalCurtain.removeClass('full-screen-curtain');

						if (disableBindings) {
							$rootScope.$broadcast('$dynamicBindingsOn');
						}
					}

					// Put back aschended class on react modals
					[].forEach.call(modalObject.reascendElements || [], el => {
						el.classList.add('Modals__modal--ascended');
					});

					// Curtain shouldnt be above modal when closed
					if (modalCurtain && !modalObject.prevModal?.element?.[0].classList.contains('above-react-modal')) {
						modalCurtain.removeClass('above-react-modal');
					}

					// Pull modalObject from openModals array
					_.remove(openModals, function (modal) {
						return modal.id === modalObject.id;
					});

					// run callback
					//FIXME: remove laterz
					closeCallback(result);

					// resolve/reject promise
					if (reject) {
						modalDefered.reject(result);
					} else {
						modalDefered.resolve(result);
					}

					Sentry.addBreadcrumb({
						category: 'modal',
						message: 'Closed modal ' + modalParams.$modalName,
						level: Sentry.Severity.Info
					});

					closeElevioWidgets();

					return prevented;
				};

				var onError = function () {
					modalObject.close(true);
				};

				// keyboard event function
				function keyboardEvent(event) {
					var tar = angular.element(event.target);
					// prevent escape in selects
					if (
						event.keyCode === 27 &&
						!config.escapeInInput &&
						(tar.hasClass('select2-focused') ||
							tar.hasClass('select2-focusser') ||
							tar.hasClass('prevent-escape'))
					) {
						return tar.blur();
					}

					if (event.keyCode === 27 && closeOnEscape && modalObject.scope) {
						if (openModals[openModals.length - 1]) {
							openModals[openModals.length - 1].close(true, 'escaped');
						}
					}
				}

				function curtainClick() {
					if (openModals.indexOf(modalObject) === openModals.length - 1) {
						modalObject.scope.reject();
					}
				}

				// create modal
				var create = function () {
					// The modalObject
					modalObject.element = null;
					modalObject.scope = null;
					modalObject.prevModal = getPrevModal();
					modalObject.id = Math.floor(Math.random() * 10000 + 1);
					modalObject.isFullScreen = style && style.indexOf('new-full-screen') !== -1;
					modalObject.noCloseOnStateChange = config.noCloseOnStateChange;

					// Push to openModals array
					openModals.push(modalObject);

					// If there already is an open modal
					// Hide prev modal only if its not fullscreen
					if (modalObject.prevModal && modalObject.prevModal.element && !modalObject.prevModal.isFullScreen) {
						modalObject.prevModal.element.addClass('hidden');
					} else {
						if (disableBindings) {
							$rootScope.$broadcast('$dynamicBindingsOff');
						}

						// Set reference on rootscope
						$rootScope.$upModalOpen = true;

						if (config.invisibleCurtain) {
							modalCurtain.addClass('invisible-curtain');
						} else {
							modalCurtain.removeClass('invisible-curtain');
						}

						// Show curtain
						modalCurtain.appendTo('body').show();
					}

					var promises = {};
					var localScope = $rootScope.$new();
					localScope.reloadModalPosition = function () {
						setMaxHeight();
					};
					localScope.notify = notify;
					localScope.$modalOpen = false;
					localScope.addModalClass = function (c) {
						modalObject.element.addClass(c);
					};
					localScope.removeModalClass = function (c) {
						modalObject.element.removeClass(c);
					};

					var $htmlPromise;
					var injects = {
						$scope: localScope,
						$modalParams: {}
					};

					// check template type and get template
					if (config.template) {
						$htmlPromise = $q.when({ data: config.template });
					} else if (config.templateProvider && typeof config.templateProvider === 'function') {
						$htmlPromise = $q
							.when($injector.invoke(config.templateProvider, {}, { $modalParams: modalParams }))
							.then(function (res) {
								return { data: res };
							});
					} else {
						$htmlPromise = $http.get(config.templateUrl, { cache: $templateCache });
					}
					var initialHeight = null;
					$htmlPromise
						.then(function (result) {
							// html
							var html = result.data;

							modalObject.element = angular.element('<div>');
							modalObject.element.html(html);
							modalObject.scope = localScope;
							$animate.enabled(false, modalObject.element);

							if (modalParams.initialHeight || config.initialHeight) {
								initialHeight = modalParams.initialHeight || config.initialHeight;
							} else if (config.rememberHeight && config.rememberedHeight) {
								initialHeight = config.rememberedHeight;
							} else {
								var initialHeightElem = modalObject.element.find('[data-initial-height]');
								if (initialHeightElem) {
									initialHeight = initialHeightElem.attr('data-initial-height');
								}
							}

							modalObject.constantHeight = false;
							if (modalParams.constantHeight) {
								modalObject.constantHeight = modalParams.constantHeight;
							} else if (config.constantHeight) {
								modalObject.constantHeight = config.constantHeight;
							} else {
								var constantHeightElem = modalObject.element.find('[data-constant-height]');
								if (constantHeightElem.length) {
									modalObject.constantHeight = constantHeightElem.attr('data-constant-height');
								}
							}

							if (modalObject.constantHeight) {
								initialHeight = modalObject.constantHeight;
							}

							// Set height
							if (initialHeight) {
								if (typeof initialHeight === 'number') {
									modalObject.element.css('height', initialHeight + 'px');
								} else {
									modalObject.element.css('height', initialHeight);
								}
							}

							setPromiseOnScope(modalObject.scope);

							// create modal
							modalObject.element.addClass('up-modal');
							// Disabling browser translations for modals because there is a delay between creating the dom element with the template and passing it
							// into angular for compilation. This causes a race condition where the browser might translate the template before it's parsed
							modalObject.element.addClass('notranslate');
							container.append(modalObject.element);

							if (config.position) {
								modalObject.element.addClass('no-animate');
								modalObject.element.position(config.position);
							}

							// add style
							if (style) {
								modalObject.element.addClass(style);
							}

							if (inlineStyle) {
								var styleKeys = Object.keys(inlineStyle);
								for (var i = 0; i < styleKeys.length; i++) {
									modalObject.element.css(styleKeys[i], inlineStyle[styleKeys[i]]);
								}
							}

							// bind close to curtain click
							if (closeOnCurtain) {
								modalCurtain.on('click', _.bind(curtainClick, {}, true, 'close'));
							}

							// bind keyboard events if this is the only open modal
							if (openModals.length === 1) {
								$document.on('keyup', keyboardEvent);

								// bind resize-event to window
								$window.on('resize', resizeFunc);
							}

							return html;
						})
						.then(function () {
							openFunction(modalObject.element, function () {
								// if we have resolves
								var resolveKeys = Object.keys(resolveObj);

								if (resolveKeys.length) {
									angular.forEach(resolveKeys, function (key) {
										var value = resolveObj[key];

										promises[key] = $injector.invoke(
											value,
											{},
											{ $modalParams: modalParams, $preCompileElement: modalObject.element }
										);
									});
								}
								$q.all(promises)
									.then(function (result) {
										// The REAL modal
										var modalToCompile = modalObject.element.clone();

										// extend modal params with resolved result
										injects.$modalParams = angular.extend(modalParams, result);
										injects.$scope.$modalParams = angular.extend(modalParams, result);
										injects.$element = modalToCompile;

										if (controllerProvider) {
											var providerResult = $injector.invoke(controllerProvider, {});

											$controller(providerResult, injects);
										} else if (controller) {
											// create controller
											const realController = $controller(controller, injects);
											if (!config.onClose && realController?.onCloseCourtain) {
												closeCallback = realController.onCloseCourtain;
											}
										}

										if (style && style.indexOf('new-full-screen') !== -1) {
											modalCurtain.addClass('full-screen-curtain');
										} else {
											modalCurtain.removeClass('full-screen-curtain');
										}

										// compile
										if (!config.$compiled || config.noCompile) {
											config.$compiled = $compile(modalToCompile);
										}
										config.$compiled(modalObject.scope, function (compiledModalElement) {
											if (initialHeight) {
												if (typeof initialHeight === 'number') {
													modalObject.element.css('height', initialHeight + 'px');
												} else {
													modalObject.element.css('height', initialHeight);
												}
											}

											placeModalAboveDrawer(compiledModalElement);

											// Replace modalElement with new compiled element
											modalObject.element.replaceWith(compiledModalElement);
											modalObject.element = compiledModalElement;

											// Check for any open react modal to make sure modal is on top
											modalObject.reascendElements = placeModalAboveDrawer(
												compiledModalElement,
												modalCurtain
											);

											// Listen for all clicks on ui-sref attributed elements
											modalObject.element.on('click', '[ui-sref]', function (e) {
												var stateObj = parseStateRef(
													angular.element(e.currentTarget).attr('ui-sref')
												);
												if (!stateObj) {
													return;
												}

												var stateParams = $parse(stateObj.paramExpr)(modalObject.scope);

												// Check if the clicked state is the same as the current state
												// This fix might need to be removed when we fix the modal-has-own-state thing
												if ($state.href(stateObj.state, stateParams) === window.location.hash) {
													// close the modal
													closeTopModal();
												}
												// Else the original stateChange event is triggered
											});

											// Emit ready event on local scope
											modalObject.scope.$emit('modal.ready', modalObject);
											modalObject.scope.$modalOpen = true;

											let message = '';

											if (modalParams.$modalName) {
												message = 'Opened modal ' + modalParams.$modalName;
											} else {
												try {
													message =
														'Opened generated modal with ' +
														JSON.stringify(modalParams, getCircularReplacer());
												} catch (error) {
													console.error('Modal.js', error);
													message = 'An modal was opened';
												}
											}

											Sentry.addBreadcrumb({
												category: 'modal',
												message: message,
												level: Sentry.Severity.Info
											});

											// Watch height change
											if (!modalObject.constantHeight) {
												var time = null;
												modalObject.scope.$watch(function () {
													if (time) {
														clearTimeout(time);
													}
													time = setTimeout(function () {
														setMaxHeight();
													}, 50);
												}, true);
											}

											modalObject.element.addClass('compiled');
										});
									})
									.catch(onError);
							});
						})
						.catch(error => {
							onError();
							logError(error, 'Failed loading Modal html');
						});
				};

				create();

				// return promise
				return modalDefered.promise;
			}

			var hasOpenModal = function () {
				return openModals.length > 0;
			};
			function unhideCurtain() {
				modalCurtain.removeClass('invisible-curtain');
			}

			return {
				open: OpenModal,
				create: CreateModal,
				hasOpenModal: hasOpenModal,
				list: modals,
				unhideCurtain
				// transitionTo: doCoolStuff here
			};
		}
	];
});

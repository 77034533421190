'use strict';

angular.module('upDirectives').directive('upAdminSidebarRoot', [
	'$translate',
	'$upModal',
	'EditorCk',
	'$datepicker',
	'$timepicker',
	'$rootScope',
	'AppService',
	'FeatureHelper',
	'avatarService',
	function (
		$translate,
		$upModal,
		EditorCk,
		$datepicker,
		$timepicker,
		$rootScope,
		AppService,
		FeatureHelper,
		avatarService
	) {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				var firstElement = $element[0];
				var renderTimeout;
				var rootData;
				var tools = {
					$translate: $translate.instant,
					$upModal: $upModal,
					EditorCk: EditorCk,
					$datepicker: $datepicker,
					$timepicker: $timepicker,
					$rootScope: $rootScope,
					AppService: AppService,
					FeatureHelper: FeatureHelper,
					avatarService: avatarService
				};

				var render = function () {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(function () {
						if (!firstElement) {
							return;
						}

						ReactDOM.render(
							React.createElement(ReactTemplates.admin.sidebar, {
								// Data and stuff
								rootData: Object.assign({}, rootData),
								tools: tools
							}),
							firstElement,
							function () {}
						);
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch(
					$attr.upAdminSidebarRoot,
					function (data) {
						rootData = data;
						render();
					},
					true
				);

				render();
			}
		};
	}
]);

import Resource from 'Resources/Resource';

class MarketStats extends Resource {
	constructor() {
		super('marketstats');
	}

	getStats(preset: string, filters = {}) {
		const params = typeof filters !== 'object' ? {} : filters;
		return this._getRequest(preset, { methodName: 'find', params }).then(response => response.data);
	}
}

const resource = new MarketStats();

export default resource;

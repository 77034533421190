import React from 'react';
import { Card, Row, Column } from '@upsales/components';

import t from 'Components/Helpers/translate';
import StepDrawer from '../StepDrawer';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerActionList from '../Components/DrawerActionList';
import StepContactTable from '../Components/StepContactTable';
import Flow, { FlowStep, FlowStepStats } from 'App/resources/Model/Flow';
import { RelationDataType } from 'Components/Flow/formatActionProperty';

const COLOR = 'rose-4';
const title = 'flow.phonecallsCreated';

type Props = {
	popup: { step: FlowStep & { stats: FlowStepStats; id: number }; type: string };
	properties: Array<object>;
	closePopup: () => void;
	relationData: RelationDataType;
	flow: Flow;
};
export default function PhoneCall(props: Props) {
	const childIsPhoneOutcome = props.popup.step.childYes?.type === 'phoneOutcome';
	const stats = childIsPhoneOutcome ? props.popup.step.childYes?.stats : props.popup.step.stats;
	const tabTypes = ['currentlyInStep', 'fallenOutOfStep'];

	if (Tools.FeatureHelper.hasSoftDeployAccess('FLOW_PHONE_OUTCOME') && childIsPhoneOutcome) {
		tabTypes.push('positivePhoneOutcome', 'negativePhoneOutcome');
	}
	return (
		<StepDrawer {...props} title={t(title)} subtitle={t('flow.phonecallsCreated')} color={COLOR} icon={'phone'}>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<Row>
					<Column>
						<DrawerInfoTitle total={props.popup.step.stats.hasCompletedStepUnique} title={t(title)} />
					</Column>
				</Row>
				<DrawerActionList
					type={props.popup.type}
					properties={props.properties}
					relationData={props.relationData}
				/>
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={
					childIsPhoneOutcome && props.popup.step.childYes?.id
						? props.popup.step.childYes?.id
						: props.popup.step.id
				}
				segmentId={props.flow.segmentId || null}
				stats={stats}
				types={tabTypes}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

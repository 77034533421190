export default {
	formName: {
		title: 'form.form',
		field: 'form.name',
		locked: true
	},
	assigned: {
		title: 'scoreboard.assignedUser',
		field: 'client.assigned.user.name'
	},
	score: {
		title: 'default.leadScore',
		field: 'score'
	},
	id: {
		title: 'form.id',
		field: 'form.id'
	},
	date: {
		title: 'default.date',
		field: 'regDate'
	},
	leadSource: {
		title: 'default.leadsourceType',
		field: 'visit.leadSource.type'
	}
};

import async from 'async';

angular.module('upModals.controllers').controller('ViewVisit', [
	'$scope',
	'$q',
	'$modalParams',
	'AppService',
	'$upModal',
	'Visitor',
	'Account',
	'Contact',
	function ($scope, $q, $modalParams, AppService, $upModal, Visitor, Account, Contact) {
		var customerId = AppService.getCustomerId();
		var visitId = $modalParams.id;
		$scope.loading = true;
		$scope.visit = null;
		$scope.client = null;
		$scope.contact = null;

		async.series(
			[
				function (cb) {
					var promises = {};
					var accountPromise = $q.defer();
					var contactPromise = $q.defer();

					promises.visit = Visitor.customer(customerId).get(visitId);
					promises.client = accountPromise.promise;
					promises.contact = contactPromise.promise;

					promises.visit
						.then(function (res) {
							res = res.data;

							if (res.client && res.client.id) {
								Account.customer(customerId)
									.get(res.client.id)
									.then(accountPromise.resolve)
									.catch(e => console.log('get account error', e));
							} else {
								accountPromise.resolve({});
							}

							if (res.contact && res.contact.id) {
								Contact.customer(customerId)
									.get(res.contact.id)
									.then(contactPromise.resolve)
									.catch(e => console.log('get contact error', e));
							} else {
								contactPromise.resolve({});
							}
						})
						.catch(e => console.log('get visit error', e));

					$q.all(promises)
						.then(function (res) {
							$scope.client = res.client.data;
							$scope.visit = res.visit.data;
							$scope.contact = res.contact.data;
							$scope.clientAddress = _.find($scope.client.addresses, { type: 'Mail' });
							cb();
						})
						.catch(e => console.log('view visit error', e));
				}
			],
			function () {
				$scope.loading = false;
			}
		);
	}
]);

'use strict';

angular.module('domain.contact').factory('ConfirmEsignMeta', [
	'$q',
	'AppService',
	'Esign',
	function ($q, AppService, Esign) {
		// Requires account to open
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var esign = $q.defer();
				var settingsPromise = $q.defer();

				var promises = {
					esign: esign.promise,
					settings: settingsPromise.promise
				};

				// if we have some preset data
				if ($modalParams.id) {
					// Get esign
					promises.esign = Esign.get($modalParams.id);
				} else if ($modalParams.esign) {
					// if this is a confirm
					promises.esign = $q.when({ data: $modalParams.esign });
				} else {
					promises.esign = $q.reject('Missing confirm(esign) or id');
				}

				/* App settings */
				var getSettings = function (id) {
					Esign.runAction({
						type: 'settings',
						integrationId: id
					})
						.then(function (res) {
							settingsPromise.resolve(res.data);
						})
						.catch(function (err) {
							return settingsPromise.reject(err);
						});
				};

				promises.esign.then(function (res) {
					if (res.data.id && res.data.integrationId) {
						getSettings(res.data.integrationId);
					} else {
						settingsPromise.resolve({});
					}
				});

				return $q.all(promises).then(function (response) {
					return response;
				});
			});
		};
	}
]);

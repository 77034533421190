import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute, RouteProps } from 'react-router-dom';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { useSelector } from 'react-redux';
import type { RootState } from 'Store/index';
import { goToStartPage } from 'App/upsales/common/helpers/startpageHelper';

type Props = RouteProps & { public?: boolean; allowed?: () => boolean };

const AllowedWrapper: React.FC<RouteProps & { public?: boolean; allowed: () => boolean }> = ({ allowed, ...props }) => {
	const isAllowed = allowed();
	// If allowed fn returns falsy we need to abort and go to default route
	if (!isAllowed) {
		goToStartPage();
		return null;
	}
	return <ReactRoute {...props} />;
};

const Route: React.FC<Props> = ({ public: isPublic = false, allowed = () => true, ...props }) => {
	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		const AppService = getAngularModule('AppService');

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(() => {
			setLoaded(true);
		});
	}, []);

	const brandId = useSelector((state: RootState) => state.App?.self?.userParams?.selectedBrand ?? 1);

	// if route is not public we need to wait for AppService.loaded here before rendering the route
	if (!loaded && !isPublic) {
		return null;
	}

	// Render the route
	return <AllowedWrapper key={brandId} allowed={allowed} {...props} />;
};

Route.propTypes = {
	public: PropTypes.bool,
	allowed: PropTypes.func
};

export default Route;

'use strict';

(function () {
	var UNSAFE_componentWillMount = function () {
		var t = Tools.$translate;
		this.lang = {
			noName: t('default.noName')
		};
	};

	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var initSelect2 = function (self) {
		var Opportunity = Tools.Opportunity;
		var masterCurrency = _.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true });
		var f = Tools.$filter;

		var opts = {
			formatResult: function (opportunity, el, x, encode) {
				return (
					'<b>' +
					f('upsalesDate')(opportunity.date) +
					' ' +
					encode(opportunity.description) +
					'</b><br/>' +
					f('currencyFormat')(opportunity.value, masterCurrency.iso) +
					'<span class="pull-right">' +
					opportunity.user.name +
					'</span>'
				);
			},

			resource: Opportunity,
			required: self.props.required || false,
			multiple: self.props.multiple || false,
			placeholder: function () {
				return self.props.placeholder;
			},
			linked: self.props.linked || false,
			ajax: true,
			asIds: false,
			idAttr: Opportunity.attr.id,
			titleAttr: Opportunity.attr.description,
			sorting: { field: Opportunity.attr.description, ascending: true },
			emptyMsg: self.lang.noName,
			addEvent: 'opportunity.added',
			fields: ['user', 'value'],
			filterType: 'opportunity',
			onChange: self.props.onChange,
			goTo: function () {
				return Tools.$upModal.open('editOrder', { id: self.props.value.id });
			},
			filters: function (rb) {
				if (self.props.accountId) {
					rb.addFilter(Opportunity.attr.client.attr.id, rb.comparisonTypes.Equals, self.props.accountId);
				}
			}
		};

		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			self.isAjax = options.ajax !== undefined;
			self.currentDataLength = options.data ? options.data.length : 0;
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upOpportunities = window.ReactCreateClass({
		accountId: null,
		isAjax: false,
		currentDataLength: 0,
		getStateFromProps: function (props) {
			return {
				accountId: props.accountId
			};
		},
		getInitialState: function () {
			return this.getStateFromProps(this.props);
		},
		UNSAFE_componentWillReceiveProps: function (nextProps) {
			var state = this.getStateFromProps(nextProps);

			this.setState(state);
		},
		UNSAFE_componentWillMount: UNSAFE_componentWillMount,
		componentDidUpdate: function () {
			ReactTemplates.TOOLS.selectHelper.updateValue.bind(this)();
			if (this.accountId !== this.state.accountId) {
				initSelect2(this, false);
			}
			this.accountId = this.state.accountId;
		},
		componentDidMount: function () {
			initSelect2(this, false);
			this.accountId = this.state.accountId;
		},
		render: render
	});
})();

import PropTypes from 'prop-types';
import React from 'react';
import State from './State';
import AnonPageSwitcher from './PageSwitcher';
import Config from './defaultStates/Config';
import Checks from './defaultStates/Checks';
import { numberFormat } from '../Filters/Currencies';
import async from 'async';
import AdminHeader from 'Components/Admin/AdminHeader';
class Anonymization extends React.Component {
	constructor(props) {
		super(props);

		const { rootData } = this.props;
		this.state = Object.assign({}, State(rootData.pageData));

		this.switchTab = this.switchTab.bind(this);
		this.selectField = this.selectField.bind(this);
		this.updateConfig = this.updateConfig.bind(this);
		this.checkboxChange = this.checkboxChange.bind(this);
		this.contactSelected = this.contactSelected.bind(this);
		this.selectAllFields = this.selectAllFields.bind(this);
		this.deselectEverything = this.deselectEverything.bind(this);
		this.triggerFilterContacts = this.triggerFilterContacts.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const pageData = nextProps.rootData.pageData;

		this.setState({
			contacts: pageData.contacts,
			loading: pageData.loading,
			buttonTextNumber: numberFormat(pageData.numContacts),
			query: pageData.query
		});
	}

	checkboxChange(type) {
		const state = this.triggerFilterContacts(null, type);
		this.setState(state);
	}

	triggerFilterContacts(inputState, type) {
		let state;

		if (inputState) {
			state = inputState;
		} else {
			state = Object.assign({}, this.state);
		}

		if (type) {
			state.checks[type] = !state.checks[type];
		}
		const PageData = this.props.rootData.pageData;
		PageData.filterContacts(state.checks, state.config);

		return state;
	}

	contactSelected(contacts, all) {
		const selectAll = typeof all === 'boolean' && all === true ? true : false;
		const deselectEverything = typeof all === 'boolean' && all === false ? true : false;
		const selectEverything = selectAll === false && all === 'all' ? true : false;
		const page = this.props.rootData.pageData.currentPage;
		let selectedContacts = [].concat(this.state.selectedContacts);

		if (selectEverything) {
			const self = this;
			let result = [];
			let hasAllItems = false;
			this.setState({
				selectingAll: true
			});

			async.whilst(
				() => !hasAllItems,
				cb => {
					const offsetFilter = Object.assign(
						{},
						{ offset: result.length, limit: 1000, f: 'id' },
						self.state.query
					);

					Tools.Contact.anonymize(offsetFilter)
						.then(response => {
							result = result.concat(response.data);
							hasAllItems = response.metadata.total <= result.length;
							cb(null);
						})
						.catch(e => console.log('anonymize error', e));
				},
				() => {
					const resultIds = _.map(result, function (item) {
						return item.id;
					});

					self.setState({
						selectedContacts: resultIds,
						everyThingSelected: true,
						selectingAll: false
					});
				}
			);

			return;
		}

		if (deselectEverything) {
			selectedContacts = [];
			return this.setState({
				selectedContacts: selectedContacts,
				everyThingSelected: false
			});
		}

		if (selectAll && this.state.everyThingSelected === false) {
			const firstIndex = (page - 1) * 50;
			const lastIndex = (page - 1) * 50 + 50 - 1;

			if (selectedContacts[firstIndex] && selectedContacts[lastIndex]) {
				const filteredContacts = [];
				selectedContacts.forEach((item, index) => {
					if (index < firstIndex || index > lastIndex) {
						filteredContacts.push(item);
					}
				});

				if (filteredContacts.length > 0) {
					selectedContacts = [].concat(filteredContacts);
				}

				return this.setState({
					selectedContacts: selectedContacts
				});
			}
		}

		if (this.state.everyThingSelected) {
			selectedContacts = [];
		}

		contacts.forEach(contact => {
			if (contact && selectedContacts.indexOf(contact.id) === -1) {
				selectedContacts.push(contact.id);
			} else if (!selectAll) {
				_.pull(selectedContacts, contact.id);
			}
		});

		this.setState({
			selectedContacts: selectedContacts,
			everyThingSelected: false
		});
	}

	updateConfig(type, value) {
		const state = Object.assign({}, this.state);
		const numberValue = parseInt(value, 10);

		if (typeof value === 'boolean') {
			state.config[type].active = value;
		} else if (!isNaN(numberValue)) {
			state.config[type].selected = numberValue;
		} else if (type === 'recent' && typeof value === 'string') {
			state.config[type].selected = '';
		} else if (type === 'client' && typeof value === 'string') {
			state.config[type].selected = '';
		} else {
			console.log('Your value is not of the correct type.');
		}

		this.triggerFilterContacts(state, null);
		this.setState(state);
	}

	switchTab(target) {
		this.setState({ tabActive: target });
	}

	isSelected(selectedFields, compare, reversed) {
		if (reversed === true) {
			return selectedFields.filter(f => {
				return !(f.field === compare.field && f.parent === compare.parent);
			});
		}

		return selectedFields.filter(f => {
			// if ()
			return f.field === compare.field && f.parent === compare.parent;
		});
	}

	selectAllFields(fields) {
		const stateField = Object.assign({}, this.state.fields);
		if (typeof fields === 'string' && fields === 'remove') {
			stateField.selectedFields = [];
			return this.setState({ fields: stateField });
		}

		_.forEach(fields, fieldGroup => {
			_.forEach(fieldGroup, field => {
				const exists = this.isSelected(stateField.selectedFields, field);
				if (exists.length === 0) {
					stateField.selectedFields.push(field);
				}
			});
		});

		this.setState({ fields: stateField });
	}

	deselectEverything() {
		const state = Object.assign({}, this.state);

		state.fields.selectedFields = [];
		state.selectedContacts = [];
		state.checks = Checks();
		state.config = Config();

		this.setState(state);
	}

	selectField(obj, remove) {
		const stateField = Object.assign({}, this.state.fields);
		const shouldIRender = this.isSelected(stateField.selectedFields, obj);

		if (shouldIRender.length === 0) {
			stateField.selectedFields.push(obj);
			this.setState({ fields: stateField });
		} else if (remove === true) {
			stateField.selectedFields = this.isSelected(stateField.selectedFields, obj, true);
			this.setState({ fields: stateField });
		}
	}

	render() {
		const { buttonTextNumber, tabActive } = this.state;
		const { rootData } = this.props;
		return (
			<div id="admin-page-anonymization">
				<AdminHeader
					title={Tools.$translate('admin.anonymization.name')}
					description={Tools.$translate('admin.anonymization.description')}
					image="anonymization.svg"
					articleId={568}
					tabs={[
						{ id: 'rules', title: Tools.$translate('admin.anonymization.rules') },
						{
							id: 'contacts',
							loading: rootData.pageData.loading,
							title: `${Tools.$translate('admin.anonymization.contactsToAnonymize')} (${
								Object.keys(rootData.pageData.query).length ? buttonTextNumber : 0
							})`
						}
					]}
					onTabChange={id => this.switchTab(id)}
					selectedTab={tabActive}
				/>

				<AnonPageSwitcher
					activeTab={tabActive}
					switchTab={this.switchTab}
					CheckboxChange={this.checkboxChange}
					rootData={rootData}
					contactSelected={this.contactSelected}
					updateConfig={this.updateConfig}
					onRecentChange={this.updateConfig}
					selectField={this.selectField}
					selectAllFields={this.selectAllFields}
					deselectEverything={this.deselectEverything}
					fields={this.state.fields}
					{...this.state}
				/>
			</div>
		);
	}
}

Anonymization.propTypes = {
	rootData: PropTypes.object.isRequired
};

window.Anonymization = Anonymization;
export default window.Anonymization;

import React, { useState, createRef } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Button } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import ModalWrap from 'Components/ModalWrap';
import './MailTemplatePreviewAddButton.scss';
import MailTemplatePreviewAddMenu from './MailTemplatePreviewAddMenu';

type MailTemplatePreviewAddButtonProps = {
	onSelect: (c: string | number) => void;
	vertical?: boolean;
	last?: boolean;
	first?: boolean;
};

const MailTemplatePreviewAddButton = ({
	onSelect,
	vertical = false,
	last = false,
	first = false
}: MailTemplatePreviewAddButtonProps) => {
	const classes = new bemClass('MailTemplatePreviewAddButton').mod({ vertical, horizontal: !vertical, last, first });
	const wrapper = createRef<HTMLDivElement>();

	const [open, setOpen] = useState(false);

	return (
		<div
			ref={wrapper}
			className={classes.mod({ open }).b()}
			onClick={e => {
				if (wrapper?.current) {
					wrapper.current.style.display = 'none';
					(document.elementFromPoint(e.clientX, e.clientY) as HTMLElement)?.click();
					wrapper.current.style.display = 'inherit';
				}
			}}
		>
			<Button
				data-testid={'MailTemplatePreviewAddButton-' + vertical + '-' + last + '-' + first}
				color="green"
				className={classes.elem('button').b()}
				onClick={e => {
					e.stopPropagation();
					if (vertical) {
						onSelect('');
					} else {
						setOpen(!open);
					}
				}}
			>
				<Icon name="plus" />
			</Button>

			{!vertical ? (
				<ModalWrap isMounted={open} onClose={() => setOpen(false)}>
					<MailTemplatePreviewAddMenu
						className={classes.elem('menu').b()}
						header={translate('mail.selectRowLayout')}
						onSelect={onSelect}
						onClose={() => setOpen(false)}
					/>
				</ModalWrap>
			) : null}
		</div>
	);
};

export default MailTemplatePreviewAddButton;

import PropTypes from 'prop-types';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, ButtonSelect } from '@upsales/components';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import T from 'Components/Helpers/translate';

import './BooleanInput.scss';

export default class BooleanInput extends React.Component {
	findSelectedOption() {
		const { config, filter } = this.props;

		const selectedOption = _.find(
			config.options,
			option =>
				option.value === filter.value &&
				(!option.comparisonType || option.comparisonType === (filter.comparisonType || 'Equals'))
		);
		if (selectedOption === undefined) {
			return config.options[0];
		}
		return selectedOption;
	}

	onChange(option) {
		const { onChange, filter } = this.props;
		const newFilter = _.cloneDeep(filter);
		newFilter.comparisonType = option.comparisonType || 'Equals';

		onChange(option.value, newFilter);
	}

	render() {
		const useNew = Tools.FeatureHelper.hasSoftDeployAccess('NEW_SEGMENT_FILTERS');
		const { config, filter } = this.props;
		const selectedOption = this.findSelectedOption();
		const classes = new BemClass(this.constructor.name);

		const options = _.filter(config.options, option => !option.inactive);

		return (
			<CriteriaRowWrapper rowFlex>
				<div className="criteria-row">
					{useNew ? (
						<ButtonSelect
							value={filter.value}
							options={options.map(o => ({ title: T(o.text), value: o.value }))}
							onChange={v => this.onChange(options.find(o => o.value === v))}
						/>
					) : (
						<div className={classes.elem('btn-group')}>
							{_.map(options, (option, index) => {
								const onClick = this.onChange.bind(this, option);
								const color = option === selectedOption ? 'green' : 'light-grey';

								return (
									<Button color={color} shadow="none" onClick={onClick} key={index}>
										{T(option.text)}
									</Button>
								);
							})}
						</div>
					)}
				</div>
			</CriteriaRowWrapper>
		);
	}
}

BooleanInput.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.shape({ options: PropTypes.array.isRequired }).isRequired,
	onChange: PropTypes.func.isRequired
};

import React, { useState } from 'react';
import MarketingChart from './MarketingChart';
import Client from 'App/resources/Model/Client';
import BemClass from '@upsales/components/Utils/bemClass';
import MarketHistoryLog from 'App/components/HistoryLog/MarketHistoryLog';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import './Marketing.scss';

export type MarketingSummary = {
	formCount: number;
	formScore: number;
	visitCount: number;
	visitScore: number;
	mailCount: number;
	mailScore: number;
	marketingcustomScore: number;
	marketingcustomCount: number;
	manualCount: number | null;
	manualScore: number | null;
	impressionCount: number;
};

export type MarketingMeta = {
	totalCount: number;
	totalMailCount: number;
	totalFormCount: number;
	totalLeadScore: number;

	totalVisit: number;
	totalMail: number;
	totalForm: number;
	totalMarketingCustom: number;
	impressionCount: number;
};

export type MarketingGraphData = { [k: string]: MarketingSummary };

const ClientCardMarketing = ({ client, subAccountIds }: { client: Client; subAccountIds?: number[] }) => {
	const classes = new BemClass('ClientCardContent__Marketing');
	const notesFilterActive = useSelector((state: RootState) => state.NotesFilterActive);

	const [nrOfMonths, setNrOfMonths] = useState(3);
	const [marketGrouping, setMarketGrouping] = useState('event');

	return (
		<div className={classes.b()}>
			<MarketingChart
				client={client}
				subAccountIds={subAccountIds}
				nrOfMonths={nrOfMonths}
				marketGrouping={marketGrouping}
				setNrOfMonths={setNrOfMonths}
				setMarketGrouping={setMarketGrouping}
			/>
			<MarketHistoryLog
				client={client}
				renderedBy={{ type: 'company', id: client.id }}
				notesFilterActive={notesFilterActive}
				cardAdditionalProps={{ hasAvatar: true }}
			/>
		</div>
	);
};

export default ClientCardMarketing;

'use strict';
angular.module('domain.admin').factory('EditAdsMeta', [
	'$q',
	'Ads',
	function ($q, Ads) {
		return function ($stateParams) {
			var customerId = $stateParams.customerId;

			var promises = {
				account: Ads.customer(customerId).getAccount(),
				credit: Ads.customer(customerId).getCredits(),
				creditTransactions: Ads.customer(customerId).getCreditTransactions()
			};

			return $q.all(promises);
		};
	}
]);

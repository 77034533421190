'use strict';

import SendBeam from 'Resources/SendBeam';
import globalTracker from 'App/babel/helpers/Tracker/Global';

angular.module('upDirectives').directive('upVoice', [
	'AppService',
	'VoiceService',
	'NotificationService',
	'$translate',
	function (AppService, VoiceService, NotificationService) {
		return {
			restrict: 'A',
			require: '?bindonce',
			link: function (scope, element, attributes, bindonce) {
				AppService.loadedPromise.then(function () {
					var phoneIntegration = AppService.getPhoneIntegration();
					var callType = attributes.callType;
					var hasCallFromWeb = AppService.getSelf().userParams.callFromWeb;

					if (phoneIntegration || hasCallFromWeb) {
						element.css('cursor', 'pointer');

						element.on('click', async function (e) {
							e.stopPropagation();
							var number;
							const prop = scope.$eval(attributes.upVoice) || {}; // contact or client

							switch (callType) {
								case 'contact':
								case 'client':
									number = prop[attributes.upVoiceNumber].replace(/ /g, '');
									break;
								case 'contactNumber':
								case 'clientNumber':
									number = scope.$eval(attributes.upVoiceNumber).replace(/ /g, '');
									break;
							}

							if (phoneIntegration) {
								if (!_.get(phoneIntegration, 'capabilities.useCallToLinks')) {
									e.preventDefault();
								}

								var related;
								if (attributes.activityId) {
									related = {
										type: 'Activity',
										id: scope.$eval(attributes.activityId),
										closeDate: scope.$eval(attributes.closeDate)
									};
								}

								switch (callType) {
									case 'contact':
									case 'contactNumber':
										VoiceService.callContact(prop, number, related);
										break;
									case 'client':
									case 'clientNumber':
										VoiceService.callClient(prop, number, related);
										break;
								}
							} else if (hasCallFromWeb) {
								e.preventDefault();

								try {
									await SendBeam.makePhoneCall(number, prop.name);

									NotificationService.addNotification({
										style: NotificationService.style.SUCCESS,
										icon: 'mobile',
										title: 'click_to_call.callSent',
										body: 'click_to_call.tapNotification'
									});
								} catch (err) {
									NotificationService.addNotification({
										style: NotificationService.style.ERROR,
										icon: 'times',
										title: 'default.error',
										body: 'default.somethingWrong'
									});
								}

								globalTracker.track('Send call to app: Clicked link');
							}
						});
					}

					var metadata = AppService.getMetadata();
					var telType;
					if (_.get(phoneIntegration, 'capabilities.useCallToLinks')) {
						telType = _.get(phoneIntegration, 'capabilities.useCallToLinks');
					} else {
						telType = metadata.params.callToType;
					}
					var telTarget = metadata.params.callToTarget;
					var telPrefix = metadata.params.telPrefix;

					var update = function (obj) {
						if (!obj) {
							return;
						}
						var value;

						if (callType === 'contactNumber' || callType === 'clientNumber') {
							value = scope.$eval(attributes.upVoiceNumber) || '';
						} else {
							value = obj[attributes.upVoiceNumber] || '';
						}
						var number = value.replace(/ /g, '');
						var prefixedNumber = '';

						for (var i = 0; i < number.length; i++) {
							if (i === 0 && number[i] === '0' && telPrefix && telPrefix.length) {
								prefixedNumber += telPrefix;
							} else if (i === 0 && number[i] === '+') {
								prefixedNumber += number[i];
							} else if (/[0-9]/.test(number[i])) {
								prefixedNumber += number[i];
							}
						}

						var href = telType + prefixedNumber;
						attributes.$set('href', href);
						attributes.$set('target', telTarget);
					};

					if (bindonce) {
						update(scope.$eval(attributes.upVoice)[attributes.upVoiceNumber]);
					} else {
						scope.$watch(attributes.upVoice, update);
					}
				});
			}
		};
	}
]);

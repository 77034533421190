export default {
	account: {
		title: 'default.account',
		field: 'client.name',
		locked: true
	},
	contact: {
		title: 'default.contact',
		field: 'contact.name',
		locked: true
	},
	id: {
		title: 'order.id',
		field: 'id',
		locked: true
	},
	description: {
		title: 'default.description',
		field: 'description',
		locked: true
	},
	date: {
		title: 'default.date',
		field: 'date'
	},
	value: {
		title: 'default.value',
		field: 'value'
	},
	contributionMarginMasterCurrency: {
		title: 'default.contributionMarginInMasterCurrency',
		field: 'contributionMargin'
	},
	contributionMargin: {
		title: 'default.contributionMargin',
		field: 'contributionMarginLocalCurrency'
	},
	annualValue: {
		title: 'default.annualValue',
		field: 'annualValue'
	},
	monthlyValue: {
		title: 'default.monthlyValue',
		field: 'monthlyValue'
	},
	user: {
		title: 'default.user',
		field: 'user.name'
	},
	probability: {
		title: 'default.probability',
		field: 'probability'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},
	project: {
		title: 'default.campaigns',
		field: 'project.name'
	},
	product: {
		title: 'default.product',
		field: 'orderRow.product.name'
	},
	productCategory: {
		title: 'default.productCategory',
		field: 'orderRow.product.category.name'
	},
	listPrice: {
		title: 'default.listPrice',
		field: 'orderRow.listPrice'
	},
	price: {
		title: 'default.price',
		field: 'orderRow.price'
	},
	listPriceMasterCurrency: {
		title: 'default.listPriceMasterCurrency',
		field: 'orderRow.listPriceMasterCurrency'
	},
	priceMasterCurrency: {
		title: 'default.priceMasterCurrency',
		field: 'orderRow.priceMasterCurrency'
	},
	quantity: {
		title: 'default.quantity',
		field: 'orderRow.quantity'
	},
	stage: {
		title: 'default.stage',
		field: 'stage.name'
	},
	currency: {
		title: 'default.currency',
		field: 'currency'
	},
	currencyRate: {
		title: 'default.currencyRate',
		field: 'currencyRate'
	},
	clientConnection: {
		title: 'default.clientConnection',
		field: 'clientConnection.name'
	},
	regDate: {
		title: 'default.regDate',
		field: 'regDate'
	},
	periodization: {
		title: 'default.periodization',
		field: 'periodization.value'
	},
	periodizationMasterCurrency: {
		title: 'default.periodizationMasterCurrency',
		field: 'periodization.valueMasterCurrency'
	},
	periodizationStartDate: {
		title: 'order.periodization.startDate',
		field: 'periodization.startDate'
	},
	periodizationEnddate: {
		title: 'order.periodization.endDate',
		field: 'periodization.endDate'
	},
	lostReason: {
		title: 'order.lostReason',
		field: 'lostReason.value'
	},
	competitor: {
		title: 'order.competitorId',
		field: 'competitor.name'
	}
};

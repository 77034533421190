import './reactSelectSettings.scss';
import React from 'react';
import { components } from 'react-select';
import { Styles } from 'react-select/lib/styles';
import { Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { SelectComponentsConfig } from 'react-select/lib/components';

export const reactSelectStyles = ({
	menu = {},
	menuList = {},
	container = {},
	option = {},
	control = {},
	indicatorsContainer = {},
	overrides = {}
}: { [key: string]: Partial<Styles> } = {}) => ({
	menu: (provided: Partial<Styles>) => ({
		...provided,
		marginTop: '1px',
		...menu
	}),
	menuList: (provided: Partial<Styles>) => ({
		...provided,
		paddingTop: '0px',
		paddingBottom: '0px',
		...menuList
	}),
	container: (provided: Partial<Styles>) => ({
		...provided,
		...container
	}),
	option: (provided: Partial<Styles>) => ({
		...provided,
		cursor: 'pointer',
		...option
	}),
	control: (provided: Partial<Styles>) => ({
		...provided,
		minHeight: '34px',
		gap: '2px',
		...control
	}),
	IndicatorsContainer: (provided: Partial<Styles>) => ({
		...provided,
		...indicatorsContainer
	}),
	...overrides
});

const DropdownIndicator: SelectComponentsConfig<any>['DropdownIndicator'] = ({ innerProps }) => (
	<Icon name="chevron-down" className="ReactSelectDropdownIcon" {...innerProps} />
);

const ClearIndicator: SelectComponentsConfig<any>['ClearIndicator'] = ({ innerProps }) => (
	<Icon name="times" className="ReactSelectClearIcon" space="mrm" {...innerProps} />
);

export const reactSelectComponents = (
	componentOverrides: SelectComponentsConfig<any> = {}
): SelectComponentsConfig<any> => ({
	...components,
	DropdownIndicator,
	ClearIndicator,
	IndicatorSeparator: () => null,
	...componentOverrides
});

export const noOptionsMessage = () => {
	return T('noResult.generic');
};

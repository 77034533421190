import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';
import { Block, Column, Icon, Row, Title, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { PrimaryButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import Agreement from 'App/resources/Model/Agreement';
import openModal from 'App/services/Modal';
import { RootState } from 'Store/index';
import React from 'react';

import './SubscriptionPromoBanner.scss';

type SubscriptionPromoBannerType = {
	accountSelf: RootState['App']['accountSelf'];
	agreement: Agreement;
	closeModal: () => void;
};

const SubscriptionPromoBanner = (props: SubscriptionPromoBannerType) => {
	const { accountSelf, agreement, closeModal } = props;

	const { t } = useTranslation();
	const classes = new BemClass('SubscriptionPromoBanner');

	const hasPromoFlag = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_MODAL');

	if (!hasPromoFlag || hasSubscriptionModal) {
		return <div></div>;
	}

	const lang = {
		title: t('subscription.promo.modal.title'),
		subtitle: t('subscription.promo.modal.subtitle'),
		button: t('subscription.promo.modal.buttonText')
	};

	const openSubscription = () => {
		closeModal();

		// Gotta set timeout because of a race condition with the old style and new style modal
		// overflow on the body element is set by both
		setTimeout(() => {
			if (agreement.id) {
				openModal('EditSubscription', { agreementId: agreement.id, agreement });
			} else {
				openModal('CreateSubscription');
			}
		}, 100);
	};

	return (
		<Block space="plxl ptxl prxl pbxl" className={classes.b()} backgroundColor="danger-1">
			<Row align="space-between">
				<Row>
					<Block space="mtl mbl">
						<Icon name="info-circle" space="mrxl" color="danger-6" />
					</Block>
					<Column>
						<Title bold color="danger-6">
							{lang.title}
						</Title>
						<Text color="danger-6">{lang.subtitle}</Text>
					</Column>
				</Row>
				<Row align="center">
					<PrimaryButton onClick={openSubscription}>{lang.button}</PrimaryButton>
				</Row>
			</Row>
		</Block>
	);
};

export default SubscriptionPromoBanner;

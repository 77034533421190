'use strict';
ReactTemplates.ROWSTATICS.phoneCallRow = function (tools) {
	var t = tools.$translate;
	var statics = {
		noContact: t('default.noContact'),
		noRelation: t('default.noRelation'),
		noUser: t('default.noUser')
	};

	return statics;
};

ReactTemplates.ROW.phoneCallRow = window.ReactCreateClass({
	displayName: 'phoneCallRow',
	generateClock: function (time) {
		var secNum = parseInt(time, 10); // don't forget the second param
		var hours = Math.floor(secNum / 3600);
		var minutes = Math.floor((secNum - hours * 3600) / 60);
		var seconds = secNum - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = '0' + hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}

		if (hours > 0) {
			return hours + ':' + minutes + ':' + seconds;
		}

		return minutes + ':' + seconds;
	},
	getRelationIcon: function (relation) {
		var cls = '';

		var style = {
			width: '15px',
			textAlign: 'center',
			color: '#4A90E2',
			fontSize: '1.2em'
		};

		switch (relation.type) {
			case 'Activity':
				cls = 'up-icon-activity';
				break;
			case 'Appointment':
				cls = 'fa fa-calendar';
				break;
			case 'Order':
				cls = 'fa fa-dollar ';
				break;
			case 'Opportunity':
				cls = 'up-icon-pipeline';
				break;
		}

		return <b style={style} className={cls}></b>;
	},
	openRelated: function (item, e) {
		e.stopPropagation();

		this.props.tools.opts.openRelated(item);
	},
	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;

		var getColumn = function (column, item) {
			var key = 'column' + column;
			const date = item.closeDate || item.date;

			switch (column) {
				case 'date':
					return (
						<td key={key} className="column-date">
							{ReactTemplates.TOOLS.dateCalendar(date, 'L')} {moment(date).format('LT')}
						</td>
					);

				case 'durationInS':
					return (
						<td key={key} className="column-call-length">
							{self.generateClock(item.durationInS)}
						</td>
					);

				case 'phoneNumber':
					var obj = null;
					var val = item.phoneNumber;
					var type = 'contact';

					if (item.contact) {
						obj = item.contact;
						obj.client = item.client;
					}
					if (!obj) {
						type = 'client';
						obj = item.client;
					}
					var tel;
					if (val && obj) {
						tel = ReactTemplates.TOOLS.upVoice(obj, type, val);
					}
					return (
						<td key={key}>
							{tel ? (
								<a target={tel.target} href={tel.href} onClick={tel.onClick}>
									{val}
								</a>
							) : null}
							{tel ? null : <span>{val}</span>}
						</td>
					);

				case 'client':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client ? item.client.name : null}
								tools={tools}
							/>
						</td>
					);

				case 'contact':
					if (!item.contact) {
						return (
							<td key={key}>
								<i className="grey">{ReactTemplates.ROWSTATICS.phoneCallRow.noContact}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>{ReactTemplates.TOOLS.contactsText(customerId, [item.contact], tools)}</td>
						);
					}

				case 'contactAndClient':
					return (
						<td key={key} className="account-contact-col">
							{item.contact ? (
								<ReactTemplates.TOOLS.contactHref
									customerId={customerId}
									id={item.contact.id}
									text={item.contact.name}
									tools={tools}
								/>
							) : (
								<i className="text-placeholder">{tools.$translate('default.noContact')}</i>
							)}
							{item.client ? (
								<span className="account-subtitle">
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.client.id}
										text={item.client.name}
										tools={tools}
									/>
								</span>
							) : null}
						</td>
					);
				case 'related':
					var relatedElement = null;
					var iconStyle = {
						verticalAlign: 'middle',
						maxHeight: '13px',
						margin: '0 2px'
					};
					if (item.related && item.related.length) {
						if (item.related.length === 1) {
							relatedElement = (
								<span style={iconStyle} onClick={self.openRelated.bind(self, item.related[0])}>
									{self.getRelationIcon(item.related[0])}
								</span>
							);

							relatedElement = ReactTemplates.TOOLS.withTooltip(relatedElement, item.related[0].name, {
								placement: 'top'
							});
						} else {
							relatedElement = _.chain(item.related)
								.sortBy('type')
								.map(function (rel, i) {
									var t = (
										<span style={iconStyle} onClick={self.openRelated.bind(self, rel)}>
											{self.getRelationIcon(rel)}
										</span>
									);
									return ReactTemplates.TOOLS.withTooltip(t, rel.name, {
										placement: 'top',
										key: 'related-' + i
									});
								})
								.value();
						}
					}
					return (
						<td key={key} className="column-date">
							{relatedElement}
						</td>
					);

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{statics.noUser}</i>
							</td>
						);
					}

					return (
						<td key={key}>
							<ReactTemplates.TOOLS.avatar
								user={item.user}
								size={15}
								avatarService={tools.avatarService}
								style={{ marginTop: '0px' }}
							/>{' '}
							{item.user.name}
						</td>
					);

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		return (
			<tr key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				{tds}
				<td className="column-tools"></td>
			</tr>
		);
	}
});

import { globalTracker } from 'App/babel/helpers/Tracker';

angular.module('upResources').factory('MailCampaign', [
	'$resource',
	'$q',
	'URL',
	'API',
	'mailCampaignAttributes',
	'GenericMapper',
	'$stateParams',
	'ResourceHelper',
	'$translate',
	'AppService',
	'ParseGeneric',
	'MapGeneric',
	function (
		$resource,
		$q,
		URL,
		API,
		mailCampaignAttributes,
		GenericMapper,
		$stateParams,
		ResourceHelper,
		$translate,
		AppService,
		ParseGeneric,
		MapGeneric
	) {
		// This resource is effed up, please fix!

		var MC = mailCampaignAttributes();
		var Attributes = MC.attr;
		var Keys = MC.keys;
		var Resource = $resource(
			URL + API + 'mailCampaigns/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailCampaign', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					})
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailCampaign', {
						isArray: true,
						custom: false,
						skipDateConvert: true
					})
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					}),
					transformRequest: MapGeneric(null, {
						payloadName: 'mailCampaign'
					})
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					}),
					transformRequest: MapGeneric(null, {
						payloadName: 'mailCampaign'
					})
				}
			}
		);

		var status = {
			DRAFT: 'DRAFT',
			SCHEDULED: 'SCHEDULED',
			SENT: 'SENT',
			NO_CONTACTS: 'NO_CONTACTS',
			ERROR: 'ERROR',
			DEL_PROJECT: 'DEL_PROJECT'
		};

		var helper = new ResourceHelper();

		var isScheduled = function (date) {
			if (!date || moment().isAfter(date)) {
				return false;
			}
			return true;
		};

		var isDraft = function (mail) {
			return mail.status === 'DRAFT';
		};

		var saveTitle = function (mail) {
			if (isDraft(mail)) {
				return 'default.saved';
			}
			var scheduled = isScheduled(mail.sendDate);
			return scheduled ? 'mail.mailCampaignScheduled' : 'mail.mailCampaignStarted';
		};

		var saveBody = function (mail) {
			if (isDraft(mail)) {
				return 'mail.mailWasSavedDraft';
			}
			if (isScheduled(mail.sendDate)) {
				var d = moment(mail.sendDate).format('L LT');
				return $translate.instant('mail.mailCampaignWillBeSent') + ' ' + d;
			}

			return 'mail.mailCampaignWasSent';
		};

		var sendToSegment = function (mail) {
			if (isDraft(mail)) {
				return;
			}
			if (isScheduled(mail.sendDate)) {
				return globalTracker.track('Scheduled mailCampaign');
			}

			return globalTracker.track('Sent mailCampaign');
		};

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'mailCampaign',
			createSuccessTitle: saveTitle,
			createSuccessBody: saveBody,
			updateSuccessTitle: saveTitle,
			updateSuccessBody: saveBody,
			deleteSucccessBody: 'mail.mailCampaignDeleted',
			createErrorBody: 'saveError.mailCampaign',
			updateErrorBody: 'saveError.mailCampaign',
			deleteErrorBody: 'mail.mailCampaignDeleteError'
		});

		var Model = {
			new: function () {
				var self = AppService.getSelf();

				var mailCampaign = {
					name: '',
					template: null,
					user: self,
					body: '',
					bodyJson: null,
					sendDate: null,
					mailClicked: 0,
					mailRead: 0,
					mailPending: 0,
					mailSent: 0,
					from: self.email,
					fromName: self.name,
					subject: '',
					status: status.DRAFT,
					project: null,
					jobId: null,
					attachments: [],
					isArchived: false,
					filterConfig: null,
					filter: null
				};

				return mailCampaign;
			},
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				filter.q = filter.q || [];
				filter.q.push({
					a: 'status',
					c: 'ne',
					v: ['ACTIVE', 'MISSING_CREDITS', 'MISSING_PAYMENT', 'ARCHIVED']
				});
				var params = angular.extend(filter, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (mailCampaign, options) {
				if (!mailCampaign) {
					return $q.reject('No mailCampaign');
				}

				var defered = $q.defer();

				if (mailCampaign.id) {
					Resource.update(
						{ id: mailCampaign.id },
						{ mailCampaign: mailCampaign },
						defered.resolve,
						defered.reject
					);
				} else {
					Resource.create({}, { mailCampaign: mailCampaign }, defered.resolve, defered.reject);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !mailCampaign.id);
						sendToSegment(res.data);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !mailCampaign);
					});

				return defered.promise;
			},

			archive: function (id, isArchived) {
				return Model.save(
					{ id: id, isArchived: isArchived },
					{
						updateSuccessTitle: 'default.archived',
						updateSuccessBody: 'mail.mailCampaignArchived',
						updateErrorBody: 'mail.mailCampaignArchivedError'
					}
				);
			},

			delete: function (mailCampaign, options) {
				return Resource.delete({ id: mailCampaign.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, mailCampaign);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			mailBodyPreview: function (type, id) {
				if (!id) {
					id = type;
					type = 'campaign';
				}
				var baseUrl = URL + API + 'file/mailBodyPreview/' + type + '/' + id;
				return baseUrl;
			},
			getRecipientStatus: function (/*campaignId*/) {
				// Fake it for now
				return $q.when({
					data: {
						total: 1200,
						unsub: 190,
						bounce: 90,
						duplicates: 50,
						missingEmail: 50,
						inactiveContacts: 10,
						invalidEmail: 10
					}
				});
			},
			attr: Attributes,
			keys: Keys,
			status: status
		};

		return Model;
	}
]);

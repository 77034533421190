import { cloneDeep, reduce, set, get } from 'lodash';

class DataStore {
	constructor(render, actions, initialStore, callback, noCloneFields) {
		this.store = Object.assign({}, initialStore || {});
		this.store.actions = actions || {};
		this.render = render;
		this.callback = callback;
		this.noCloneFields = noCloneFields ?? {};

		this.setStore = (data, callback) => {
			this.store = { ...this.store, ...data };
			render(this.store, callback);
		};

		// This one can be slow, use pluck or get instaid
		this.getStore = () => {
			console.warn('This is very slow on large objects.');
			return cloneDeep(this.store);
		};

		// Use this if you want to get multiple keys but not the entire store
		this.pluck = (...args) =>
			reduce(
				args,
				(result, path) => {
					result[path] = this.get(path);
					return result;
				},
				{}
			);

		this.set = (path, value, callback) => {
			this.store = Object.assign({}, this.store);
			set(this.store, path, value);
			this.render(this.store, callback);
		};

		// Use this if you only want 1 item, its better than cloning the entire store
		this.get = path => this.noCloneFields.hasOwnProperty(path) ? get(this.store, path) : cloneDeep(get(this.store, path));

		this.push = (path, data, callback) => {
			var array = this.get(path);
			if (!Array.isArray(array)) {
				throw new Error('Item in store at path: "' + path + '" is not an array.');
			}
			array.push(data);
			this.set(path, array, callback);
		};

		this.splice = (path, index, count, add, callback) => {
			var array = this.get(path);
			if (!Array.isArray(array)) {
				throw new Error('Item in store at path: "' + path + '" is not an array.');
			}

			// I assume noone wants to add undefined.
			if (add !== undefined) {
				array.splice(index, count, add);
			} else {
				array.splice(index, count);
			}

			this.set(path, array, callback);
		};

		// Use this to trigger initial render
		this.init = () => {
			throw new Error('Function init on dataStore is deprecated');
		};

		render(this.store, callback);
	}
}

window.DataStore = DataStore;
export default DataStore;

'use strict';

angular.module('domain.contact').controller('MergeContacts', [
	'$scope',
	'$modalParams',
	'Contact',
	'AppService',
	'$timeout',
	'$translate',
	'RequestBuilder',
	function ($scope, $modalParams, Contact, AppService, $timeout, $translate, RequestBuilder) {
		var MergeContacts = this;
		var customerId;
		MergeContacts.saving = false;
		MergeContacts.selectedContact = null;

		var init = function () {
			customerId = AppService.getCustomerId();
			var meta = $modalParams.meta;

			MergeContacts.mergeContact = meta.contact.data;

			var placeholder = $translate.instant('default.selectContact');
			var missingRights = $translate.instant('noDeleteRights.contact');

			MergeContacts.contactSelect = {
				placeholder: placeholder,
				allowClear: 1,
				formatResult: function (obj, el, x, encode) {
					var nameStr = encode(obj.name);
					if (!obj.userRemovable) {
						nameStr += ' (' + missingRights + ')';
					}
					if (obj.title) {
						nameStr += '<br/><span class="subtitle">' + encode(obj.title) + '</span>';
					}
					return nameStr;
				},
				formatSelection: function (obj, x, encode) {
					var nameStr = obj.name;
					if (!obj.userRemovable) {
						nameStr += ' (' + missingRights + ')';
					}
					return encode(nameStr);
				},
				matcher: function (term, udnef, obj) {
					return obj.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
				}
			};

			if (meta.contacts.isAjax) {
				var data = meta.contacts.data;
				MergeContacts.contactSelect.ajax = {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						if (query.data !== undefined) {
							// If we have initial data to show when no searchString is set
							if (query.data === '' && data && data.length) {
								return query.success({ data: data });
							}

							var filter = new RequestBuilder();

							filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, query.data);
							filter.addFilter(
								Contact.attr.account,
								filter.comparisonTypes.Equals,
								$modalParams.accountId
							);
							filter.addFilter(Contact.attr.id, filter.comparisonTypes.NotEquals, $modalParams.id);

							filter.fields = ['id', 'name'];

							filter.limit = 10;

							return Contact.find(filter.build()).then(query.success);
						}
						return query.success({
							data: []
						});
					},
					results: function (res) {
						for (const contact of res.data) {
							if (!contact.userRemovable) {
								contact.disabled = true;
							}
						}

						return { results: res.data };
					}
				};
			} else {
				for (const contact of meta.contacts.data) {
					if (!contact.userRemovable) {
						contact.disabled = true;
					}
				}

				MergeContacts.contactSelect.data = meta.contacts.data;
			}
		};

		MergeContacts.merge = function () {
			MergeContacts.saving = true;
			if (!MergeContacts.selectedContact) {
				return;
			}
			if (parseInt(MergeContacts.selectedContact.id) === parseInt(MergeContacts.mergeContact.id)) {
				MergeContacts.saving = false;
				return;
			}
			Contact.customer(customerId)
				.merge(MergeContacts.mergeContact, MergeContacts.selectedContact)
				.then(function (res) {
					$timeout(function () {
						$scope.resolve(res.data);
						MergeContacts.saving = false;
					}, 1500);
				})
				.catch(function () {
					MergeContacts.saving = false;
				});
		};

		MergeContacts.select = function (contact) {
			MergeContacts.selectedContact = contact;
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column } from '@upsales/components';

import t from 'Components/Helpers/translate';
import StepDrawer from '../StepDrawer';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerActionList from '../Components/DrawerActionList';

const typeMap = {
	todo: {
		icon: 'activity',
		title: 'flow.todosCreated',
		header: 'automationAction.CreateTodo',
		unique: false
	},
	activity: {
		icon: 'check',
		title: 'flow.activitiesCreated',
		header: 'automationAction.CreateActivity',
		unique: false
	},
	assign: {
		icon: 'user-plus',
		title: 'default.numAssigned',
		header: 'automationAction.AssignToUser',
		unique: true
	},
	account: {
		icon: 'home',
		title: 'flow.clientsUpdated',
		header: 'automationAction.UpdateClient',
		uniqueClients: true
	},
	contact: {
		icon: 'user',
		title: 'flow.contactsUpdated',
		header: 'automationAction.UpdateContact',
		unique: true
	}
};

export default function StepPreview({ stats, type, properties, popup, relationData, children, ...props }) {
	const isTodo = properties.some(
		prop => prop.name === 'ActivityType' && prop.value === Tools.AppService.getTodoTypes()?.TODO?.id
	);
	const filteredProperties = properties.filter(prop => !(isTodo && prop.name === 'ActivityType'));
	const { icon, title, header, unique, uniqueClients } = typeMap[isTodo ? 'todo' : type];

	return (
		<StepDrawer {...props} title={t('flow.action')} subtitle={t(header)} color="rose-4" icon={icon}>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<Row>
					<Column>
						<DrawerInfoTitle
							total={
								uniqueClients
									? stats.hasCompletedStepClients
									: unique
									? stats.hasCompletedStepUnique
									: stats.hasCompletedStep
							}
							title={title}
						/>
					</Column>
				</Row>
				<DrawerActionList properties={filteredProperties} type={popup.type} relationData={relationData} />
			</Card>
			{children}
		</StepDrawer>
	);
}

StepPreview.propTypes = {
	children: PropTypes.node.isRequired,
	properties: PropTypes.array.isRequired,
	relationData: PropTypes.object.isRequired,
	popup: PropTypes.object.isRequired,
	stats: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired
};

import Resource from './Resource';

class ResetPassword extends Resource {
	constructor() {
		super('resetPassword');
	}

	sendResetLink(email) {
		return this._postRequest('', { email });
	}

	resetPassword({ hash, password }) {
		return this._postRequest(hash, { password });
	}

	changePassword({ email, password, newPassword }) {
		return this._postRequest('', { email, password, newPassword }, { _url: 'changePassword' });
	}
}

const resource = new ResetPassword();

export default resource;

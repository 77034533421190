'use strict';
// The only place using this directive is in the old advanced search. Remove this whnen ADVANCED_SEARCH_REACT is released.
var formatResult = function (obj, container, query, escape) {
	return (
		'<div>' +
		escape(obj.internalName || obj.name) +
		(obj.internalName ? '<div class="subtitle">' + escape(obj.name) + '</div>' : '') +
		'</div>'
	);
};

var matcher = function (term, _, object) {
	return (
		object.name?.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
		object.internalName?.toLowerCase().indexOf(term.toLowerCase()) !== -1
	);
};

var termsFilter = function (rb, value) {
	var orBuilder = rb.orBuilder();

	orBuilder.next();
	orBuilder.addFilter({ field: 'name' }, rb.comparisonTypes.Wildcard, value);
	orBuilder.next();
	orBuilder.addFilter({ field: 'internalName' }, rb.comparisonTypes.Wildcard, value);

	orBuilder.done();
};

var formSelectDir = 'upFormSelect';
angular.module('upDirectives').directive(formSelectDir + 'Ids', function (Form, selectHelper) {
	return selectHelper.getDirectiveObjectIds(formSelectDir + 'Ids', {
		resource: Form,
		ajax: true,
		asIds: true,
		idAttr: Form.attr.id,
		titleAttr: Form.attr.name,
		sorting: { field: Form.attr.name, ascending: true },
		formatResult: formatResult,
		termsFilter: termsFilter,
		matcher: matcher,
		fields: ['internalName']
	});
});

const { default: openModal } = require('App/services/Modal/Modal');
import { getCurrentLanguage } from 'Components/Helpers/translate';

window.OptInModal = function ($scope) {
	var fillEmptyValues = function (optIn) {
		var predefinedText = store.get('predefinedText');
		optIn.emailTitle = optIn.emailTitle || predefinedText.email.headline;
		optIn.emailDescription = optIn.emailDescription || predefinedText.email.description;
		optIn.emailLinkText = optIn.emailLinkText || predefinedText.email.acceptLink;
		// Replace brackets with curls because using curls in translation file tries to parse tags. And we want to show them
		optIn.emailExplanation = optIn.emailExplanation || predefinedText.email.explanation;
		optIn.landingpageTitle = optIn.landingpageTitle || predefinedText.landingpage.headline;
		optIn.landingpageDescription = optIn.landingpageDescription || predefinedText.landingpage.description;
	};

	var setPredefinedText = function (lang) {
		store.set('predefinedText', window.OptInInfoText.translate(lang));
	};

	var actions = {
		showForm: function () {
			store.setStore({ state: 'form' });
		},
		changeTab: function (i) {
			store.setStore({ activeTab: i });
		},
		closeModal: function () {
			$scope.reject();
		},
		setType: function (type) {
			var optIn = store.get('optIn');
			if (store.get('loading') || optIn.id) {
				return;
			}
			store.set('optIn.type', type);
		},
		onChange: function (key, value) {
			store.set('optIn.' + key, value);
		},
		storeTerms: function (terms) {
			store.setStore({ terms: terms });
		},
		save: function () {
			var optIn = store.get('optIn');
			fillEmptyValues(optIn);
			store.setStore({ loading: true });
			Tools.OptIn.save(optIn)
				.then(function (res) {
					$scope.resolve(res.data);
				})
				.finally(function () {
					store.setStore({ loading: false });
				});
		},
		previewEmail: function () {
			// eslint-disable-next-line promise/catch-or-return
			Tools.InkyParser.parse($scope.$modalParams.template.bodyJson, {
				profile: $scope.$modalParams.profile
			}).then(function (res) {
				// Replace tags
				var html = res.data;
				var optIn = store.get('optIn');
				var predefinedText = store.get('predefinedText');

				var regExLogo = new RegExp('{{LOGO}}', 'g');
				var regExTitle = new RegExp('{{TITLE}}', 'g');
				var regExDescription = new RegExp('{{DESCRIPTION}}', 'g');
				var regExLinkText = new RegExp('{{LINK.TEXT}}', 'g');
				var regExLink = new RegExp('{{LINK}}', 'g');
				var regExExplanation = new RegExp('{{EXPLANATION}}', 'g');
				html = html.replace(
					regExLogo,
					$scope.$modalParams.profile.darkLogo || $scope.$modalParams.profile.logo
				);
				html = html.replace(regExTitle, optIn.emailTitle || predefinedText.email.headline);
				html = html.replace(regExDescription, optIn.emailDescription || predefinedText.email.description);
				html = html.replace(regExLinkText, optIn.emailLinkText || predefinedText.email.acceptLink);
				html = html.replace(regExLink, '#" onClick="return false;');
				html = html.replace(regExExplanation, optIn.emailExplanation || predefinedText.email.explanation);
				html = html.replace(/\'/g, '&#39'); // eslint-disable-line no-useless-escape

				// CSS hack fix
				html = html.replace('table.container{background:#fefefe;', 'table.container{');
				html = html.replace('html{min-height:100%;background:#f3f3f3}', 'html{min-height:100%}');

				openModal('MailPreview', {
					name: '',
					html: `<iframe style="border: none; width: 100%; height: 560px;" srcdoc='${html}'></iframe>`,
					isPlainText: false
				});
			});
		},
		previewLandingPage: function () {
			var optIn = store.get('optIn');
			var predefinedText = store.get('predefinedText');
			const frameSrc =
				Tools.URL +
				'external/optInPreview?title=' +
				(optIn.landingpageTitle || predefinedText.landingpage.headline) +
				'&description=' +
				(optIn.landingpageDescription || predefinedText.landingpage.description);
			openModal('MailPreview', {
				name: '',
				html: `<iframe style="border: none; width: 100%; height: 700px;" src='${frameSrc}'></iframe>`,
				isPlainText: false
			});
		},
		changeInfoLanguage: function (lang) {
			setPredefinedText(lang);
			store.setStore({ infoLanguage: lang });
		}
	};

	var rootNode = document.getElementById('opt-in-modal');

	const currentLanguage = getCurrentLanguage();
	var store = new DataStore(
		function (store) {
			if (!rootNode) {
				return;
			}

			ReactDOM.render(React.createElement(ReactTemplates.admin.optInModal, store), rootNode);
		},
		actions,
		{
			state: 'info',
			activeTab: 0,
			loading: false,
			terms: '',
			infoLanguage: currentLanguage,
			predefinedText: window.OptInInfoText.translate(currentLanguage),
			optInInfoLanguages: window.OptInInfoText.getLangs(),
			reloadModalPosition: $scope.reloadModalPosition
		}
	);

	$scope.$on('$destroy', function () {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});
	if ($scope.$modalParams.optIn) {
		store.setStore({ state: 'form', optIn: $scope.$modalParams.optIn });
	} else {
		store.setStore({ optIn: Tools.OptIn.new() });
	}
};

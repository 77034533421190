import { Button, Loader, Text, Input, Toggle, Column, Row, Block } from '@upsales/components';
import RoleSelect from 'Components/RoleSelect';

ReactTemplates.MODALS.AddProductCategory = window.ReactCreateClass({
	getInitialState: function () {
		return {
			roles: this.props.roles,
			orderRowFields: this.props.orderRowFields,
			categories: this.props.categories,
			calculatingFields: this.props.calculatingFields,
			newProduct: this.props.editProductCategory
				? this.props.editProductCategory
				: {
						id: null,
						name: null,
						roles: [],
						orderRowFields: [],
						inheritRoles: Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS'),
						parentId: 0,
						sortId: 99999999
				  }
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			create: t('default.create'),
			new: t('default.new'),
			product: t('default.product'),
			notUsed: t('default.notUsed'),
			nameOfProductCategory: t('admin.productCategoryName'),
			useCalculatingFieldAsRowValue: t('admin.productCategory.useCalculatingFieldAsRowValue'),
			save: t('default.save'),
			roles: t('default.roles'),
			showFields: t('admin.productCategory.orderRowFields.onlyShow'),
			selectFields: t('admin.productCategory.orderRowFields.selectField'),
			cancel: t('default.abort'),
			placeInsideCategory: t('admin.products.setUnderCategory'),
			chooseCategory: t('admin.chooseCategory'),
			chooseRoles: t('admin.documentTemplateModal.rolesPlaceholder'),
			createProductCategory: t('admin.createProductCategory'),
			editProductCategory: t('admin.editProductCategory'),
			nameNotUnique: t('saveError.productCategory.nameNotUnique'),
			inheritFromParent: t('admin.productCategory.inheritFromParent'),
			hasTieredProducts: t('product.hasTieredProducts')
		};
	},

	changeParent: function (e) {
		var oldNewProduct = this.state.newProduct;
		oldNewProduct.parentId = Number(e.target.value);

		this.setState({
			newProduct: oldNewProduct
		});
	},

	changeRoles: function (roles) {
		var oldNewProduct = this.state.newProduct;
		oldNewProduct.roles = roles;

		this.setState({
			newProduct: oldNewProduct
		});

		this.props.reloadModalPos();
	},

	changeInheritRoles: function (value) {
		this.setState({
			newProduct: { ...this.state.newProduct, inheritRoles: value }
		});

		this.props.reloadModalPos();
	},

	changeCalculatingField: function (e) {
		var oldNewProduct = this.state.newProduct;

		oldNewProduct.calculatingField = { id: Number(e.target.value) };

		this.setState({
			newProduct: oldNewProduct
		});
	},

	changeOrderRowFields: function (event) {
		var oldNewProduct = this.state.newProduct;
		var added = event.target.added;
		var removed = event.target.removed;

		if (!oldNewProduct.orderRowFields) {
			oldNewProduct.orderRowFields = [];
		}
		if (added) {
			oldNewProduct.orderRowFields = oldNewProduct.orderRowFields.concat(added);
		}
		if (removed) {
			oldNewProduct.orderRowFields = _.without(oldNewProduct.orderRowFields, removed);
		}

		this.setState({
			newProduct: oldNewProduct
		});

		this.props.reloadModalPos();
	},

	matcher: function (term, undef, item) {
		return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
	},

	changeName: function (e) {
		var oldNewProduct = this.state.newProduct;
		oldNewProduct.name = e.target.value;

		this.setState({
			newProduct: oldNewProduct
		});
	},

	render: function () {
		const { hasTieredProducts, loading, editProductCategory, notUniqueNameError, saving } = this.props;

		if (loading) {
			return <Loader noU={true} size="sm" />;
		}

		var self = this;
		var hasUserPermissionsAdvanced = Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED
		);
		var hasRowCustomVisibility = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ROW_CUSTOM_VISIBILITY);
		var hasCalculatingRowValue = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CALCULATING_ROW_VALUE);
		const hasBackendProductAccess = Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		let categoryId = null;
		if (editProductCategory && editProductCategory.parentId) {
			categoryId = editProductCategory.parentId;
		} else {
			const selectedParent = _.find(this.state.categories, { id: this.state.newProduct.parentId });
			if (selectedParent && selectedParent.id) {
				categoryId = selectedParent.id;
			}
		}

		return (
			<div className="add-product-modal">
				<div className="header">
					<h2 className="title">
						{self.state.newProduct.id ? self.lang.editProductCategory : self.lang.createProductCategory}
					</h2>
					<span className="close" onClick={this.props.closeModal}>
						<b className="fa fa-times" />
					</span>
				</div>
				<div className="form-container">
					<div className="form-group">
						<label>
							{this.lang.nameOfProductCategory}
							<span className="text-danger">{'*'}</span>
						</label>
						<Input
							type="text"
							state={notUniqueNameError ? 'error' : null}
							onChange={this.changeName}
							value={this.state.newProduct.name}
							autofocus
						/>
						{notUniqueNameError ? (
							<Text color="red" italic={true} size="sm">
								{this.lang.nameNotUnique}
							</Text>
						) : null}
					</div>
					<div className="form-group">
						<label>{this.lang.placeInsideCategory}</label>
						<ReactTemplates.INPUTS.upSelect
							tools={this.props.tools}
							options={{ allowClear: true, placeholder: self.lang.chooseCategory }}
							data={this.state.categories}
							multiple={false}
							required={false}
							className="form-control"
							onChange={this.changeParent}
							matcher={this.matcher}
							defaultValue={categoryId}
						/>
					</div>
					{hasUserPermissionsAdvanced ? (
						<div className="form-group">
							{/* No this is not how we do stuff, but my next ticket will refactor the shit out of this file */}
							<label style={{ width: '100%' }}>
								{this.lang.roles}
								{hasBackendProductAccess && !hasTreeSelect
									? React.createElement(ReactTemplates.elevio, {
											sidebar: true,
											articleId: 1500,
											style: { float: 'right', lineHeight: '1.8em' }
									  })
									: null}
							</label>
							{hasTreeSelect ? (
								<RoleSelect
									positionRelative
									roles={this.state.roles}
									selectedRoles={this.state.newProduct.roles}
									disabled={saving}
									onChange={this.changeRoles}
								/>
							) : (
								<ReactTemplates.INPUTS.upRoles
									tools={Tools}
									data={this.state.roles}
									multiple={true}
									placeholder={self.lang.chooseRoles}
									required={false}
									onChange={this.changeRoles}
									value={this.state.newProduct.roles}
								/>
							)}
							{hasBackendProductAccess && !hasTreeSelect ? (
								<Block space="mtm">
									<Row>
										<Column fixedWidth={30}>
											<Toggle
												size="sm"
												color="medium-green"
												checked={this.state.newProduct.inheritRoles}
												onChange={v => this.changeInheritRoles(v)}
											/>
										</Column>
										<Column>
											<Text size="sm">{this.lang.inheritFromParent}</Text>
										</Column>
									</Row>
								</Block>
							) : null}
						</div>
					) : null}
					{hasRowCustomVisibility ? (
						<div className="form-group">
							<label>{this.lang.showFields}</label>
							<ReactTemplates.INPUTS.upSelect
								tools={Tools}
								data={this.state.orderRowFields}
								multiple={true}
								placeholder={self.lang.selectFields}
								className={'form-control'}
								required={false}
								onChange={this.changeOrderRowFields}
								defaultValue={this.state.newProduct.orderRowFields}
							/>
						</div>
					) : null}
					{hasCalculatingRowValue ? (
						<div className="form-group">
							<label style={{ width: '100%' }}>
								{this.lang.useCalculatingFieldAsRowValue}
								{React.createElement(ReactTemplates.elevio, {
									sidebar: true,
									articleId: 997,
									style: { float: 'right', lineHeight: '1.8em' }
								})}
							</label>
							<ReactTemplates.INPUTS.upSelect
								tools={Tools}
								data={this.state.calculatingFields}
								placeholder={self.lang.notUsed}
								className={'form-control'}
								required={false}
								onChange={this.changeCalculatingField}
								defaultValue={
									this.state.newProduct.calculatingField && this.state.newProduct.calculatingField.id
								}
								disabled={hasTieredProducts}
							/>
							{hasTieredProducts ? (
								<Text color="grey-10" italic={true} size="sm">
									{this.lang.hasTieredProducts}
								</Text>
							) : null}
						</div>
					) : null}
					<div className="form-group">
						<Button
							onClick={function () {
								self.props.onSave(self.state.newProduct);
							}}
							color={self.state.newProduct.name ? 'green' : 'light-grey'}
							block={true}
							size="lg"
							shadow="none"
							disabled={!self.state.newProduct.name}
							loading={saving}
						>
							{this.lang.save}
						</Button>
						<Button onClick={this.props.closeModal} type="link" color="grey" block>
							{this.lang.cancel}
						</Button>
					</div>
				</div>
			</div>
		);
	}
});

import Checks from './defaultStates/Checks';
import Config from './defaultStates/Config';
import Fields from './defaultStates/Fields';
import { numberFormat } from '../Filters/Currencies';

const State = pageData => {
	return Object.assign(
		{},
		{
			tabActive: 'rules',
			selectedContacts: new Array(),
			contacts: [].concat(pageData.contacts),
			buttonTextNumber: numberFormat(pageData.numContacts),
			query: Object.assign({}, pageData.query),
			everyThingSelected: false,
			buttonText: Tools.$translate('admin.anonymization.listTitle').toLowerCase(),
			checks: Checks(),
			config: Config(),
			fields: Fields()
		}
	);
};

export default State;

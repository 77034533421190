'use strict';
import PropTypes from 'prop-types';

ReactTemplates.alerts = {};
ReactTemplates.alerts.confirm = window.ReactCreateClass({
	propTypes: {
		id: PropTypes.string.isRequired,
		reject: PropTypes.func.isRequired,
		resolve: PropTypes.func.isRequired,
		confirmationMode: PropTypes.string
	},

	getInitialState: function () {
		var state = {};

		if (this.props.confirmationMode && this.props.confirmationMode === 'text') {
			state.input = '';

			if (this.props.confirmText && this.props.confirmText.length) {
				state = _.assign({}, state, {
					confirmText: this.props.confirmText,
					confirmationMode: this.props.confirmationMode,
					valid: false
				});
			} else {
				state = _.assign({}, state, {
					confirmText: 'UPSALES',
					confirmationMode: this.props.confirmationMode,
					valid: false
				});
			}
		}

		return state;
	},
	UNSAFE_componentWillUpdate: function (nextProps, nextState) {
		nextState.valid = nextState.input === this.state.confirmText;
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			confirm: t('default.confirm'),
			abort: t('default.abort'),
			ok: t('default.ok'),
			deleteField: t('soliditet.matcher.actionDelete') + ' ' + t('default.field').toLowerCase()
		};
	},
	compare: function (e) {
		var text = e.target.value;
		var state = _.cloneDeep(this.state);

		state.input = text;

		this.setState({
			input: state.input
		});
	},
	buttonControls: function () {
		var rejectButton = !this.props.hideAbort && (
			<button className="btn up-btn btn-link btn-bright-blue" onClick={this.props.reject}>
				{this.props.no ? this.props.no : this.lang.abort}
			</button>
		);

		if (this.props.resolveTrue) {
			return (
				<div className="up-modal-controls" disabled={this.state.valid}>
					{rejectButton}
				</div>
			);
		}

		if (this.props.resolveFalse) {
			return (
				<div className="up-modal-controls" disabled={this.state.valid}>
					{rejectButton}
				</div>
			);
		}

		if (this.props.resolve) {
			return (
				<div className="up-modal-controls">
					<button
						disabled={!this.state.valid}
						onClick={this.props.resolve}
						className="btn up-btn btn-bright-blue"
					>
						{this.lang.confirm + ' ' + this.lang.deleteField.toLowerCase()}
					</button>
					{rejectButton}
				</div>
			);
		}
	},
	confirmation: function () {
		var txt = Tools.$translate('default.typeToConfirm', { value: this.state.confirmText }) + '.';

		return (
			<div className="confirmation">
				<span className="validation-icon">
					{this.state.input && this.state.input.length && (
						<i
							className={
								this.state.valid ? 'fa fa-check-circle valid fa-2x' : 'fa fa-times-circle invalid fa-2x'
							}
						></i>
					)}
				</span>
				<label>{txt}</label>
				<input
					type="text"
					className={
						this.state.input && this.state.input.length > 1
							? this.state.valid
								? 'form-control valid'
								: 'form-control invalid'
							: 'form-control'
					}
					value={this.state.input}
					onChange={this.compare}
				/>
			</div>
		);
	},
	render: function () {
		var buttons = this.buttonControls();

		return (
			<div id={this.props.id}>
				<div className="up-modal-header">
					<h2>
						{this.props.iconClasses && <i className={this.props.iconClasses + 'template-icon'}></i>}
						{this.props.title ? this.props.title : this.lang.confirm}
					</h2>
					<a className="btn btn-link modal-close" onClick={this.props.reject}>
						<i className="fa fa-times"></i>
					</a>
				</div>
				<div className="up-modal-content">
					<div className="up-panel">
						<div className="up-panel-content">
							<p>
								{this.props.body && this.props.body.split('?')[0]}
								<br />
								{this.props.body.split('?')[1]}
							</p>
							{this.props.confirmationMode && this.confirmation()}
						</div>
					</div>
				</div>
				{buttons}
			</div>
		);
	}
});

'use strict';

angular.module('domain.soliditet').factory('UpdateClientDunsMeta', [
	'$q',
	'AppService',
	'RequestBuilder',
	'Account',
	function ($q, AppService, RequestBuilder, Account) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				// Get suggestions based on name
				var nameFilter = new RequestBuilder();
				nameFilter.addFilter(Account.attr.name, nameFilter.comparisonTypes.Search, $modalParams.name);
				nameFilter.addSort('_score', false);
				nameFilter.limit = 5;

				var dunsFilter = new RequestBuilder();
				dunsFilter.addFilter(Account.attr.dunsNo, dunsFilter.comparisonTypes.Equals, $modalParams.dunsNo);
				dunsFilter.limit = 1;

				var customerId = AppService.getCustomerId();
				var promises = {
					metadata: $q.when(AppService.getMetadata()),
					accounts: Account.customer(customerId)
						.find(nameFilter.build())
						.then(function (res) {
							return res.data;
						}),
					dunsMatch: Account.customer(customerId)
						.find(dunsFilter.build())
						.then(function (res) {
							return res.data;
						})
				};

				return $q.all(promises);
			});
		};
	}
]);

import Attribute, { DisplayType, Type } from './Attribute';

const DocumentAttributes: { [key: string]: ReturnType<typeof Attribute> } = {
	mimetype: Attribute({
		title: '',
		field: 'mimetype',
		displayKey: 'mimetype',
		type: Type.String,
		displayType: DisplayType.FileType
	}),
	filename: Attribute({
		title: 'file.fileName',
		field: 'filename',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'filename'
	}),
	size: Attribute({
		title: 'file.fileSize',
		field: 'size',
		type: Type.Number,
		displayType: DisplayType.FileSize,
		sortable: 'size'
	}),
	uploadDate: Attribute({
		title: 'file.uploadedDate',
		field: 'uploadDate',
		type: Type.Date,
		displayType: DisplayType.DateTime,
		sortable: 'uploadDate'
	}),
	user: Attribute({
		title: 'file.uploader',
		field: 'user',
		type: Type.Object,
		displayType: DisplayType.User,
		sortable: 'user.name'
	}),
	fileRelation: Attribute({
		title: 'file.relatedTo',
		field: 'entityId',
		displayKey: ['entity', 'entityId', 'contact', 'activity', 'appointment', 'order'],
		type: Type.Number,
		displayType: DisplayType.FileRelation
	}),
	delete: Attribute({
		// This is to allow a delete column. SimpleListView replaces the column with 'delete' as key with a trash icon and a delete function
		title: '',
		field: '',
		type: Type.String
	})
};

export default DocumentAttributes;

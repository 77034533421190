import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon, Loader, Row, Column } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { onOpen, views } from 'Store/reducers/AssignModalReducer';
import AssignModalSidebar from './AssignModalSidebar';
import AssignModalActivities from './AssignModalActivities';
import AssignModalAssigned from './AssignModalAssigned';
import AssignModalCloseActivity from './AssignModalCloseActivity';
import AssignModalAssign from './AssignModalAssign';
import './AssignModal.scss';

const hasInTimeAppointivities = activitiesAndAppointments => {
	return _.some(activitiesAndAppointments, item => {
		if (item.isAppointment) {
			return true;
		}
		var date = moment(item.date);

		if (!item.time) {
			date.hours(23).minutes(59);
		}
		if (date.isAfter(moment())) {
			return true;
		}
		return false;
	});
};

export const mapStateToProps = state => ({
	client: state.AssignModal.client,
	loading: state.AssignModal.loading,
	view: state.AssignModal.view
});

const mapDispatchToProps = {
	onOpen
};

class AssignModal extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			accountManagers: t('default.accountManagers'),
			accountManager: t('default.accountManager'),
			noAccountManager: t('default.noAccountManager'),
			inactiveUser: t('default.inactiveUser'),
			planedActivitiesAndAppointments: t('processedBy.planedActivitiesAndAppointments'),
			delayedActivities: t('processedBy.delayedActivities'),
			delayedActivity: t('processedBy.delayedActivity'),
			assignUser: t('processedBy.AssignUser')
		};

		props.onOpen(props.modalParams).catch(() => {
			// Close modal if we fail
			props.reject();
		});

		this.renderContent = this.renderContent.bind(this);
	}

	componentDidUpdate() {
		this.props.reloadModalPosition();
	}

	renderContent(hasInTime) {
		switch (this.props.view) {
			case views.ACTIVITIES:
				return (
					<AssignModalActivities hasInTime={hasInTime} reloadModalPosition={this.props.reloadModalPosition} />
				);
			case views.ASSIGN:
				return (
					<AssignModalAssign
						close={this.props.reject}
						onAssign={this.props.resolve}
						reloadModalPosition={this.props.reloadModalPosition}
					/>
				);
			case views.ASSIGNED:
				return <AssignModalAssigned />;
			case views.CLOSEACTIVITY:
				return <AssignModalCloseActivity reloadModalPosition={this.props.reloadModalPosition} />;
			default:
				return null;
		}
	}

	render() {
		const { client, loading, reject } = this.props;
		const modalClass = new BemClass('AssignModal');
		if (loading) {
			return (
				<div className={modalClass.elem('loader').b()}>
					<Loader />
				</div>
			);
		}
		const hasInTime = hasInTimeAppointivities(this.props.activitiesAndAppointments);

		if (!hasInTime) {
			modalClass.mod('only-overdue');
		}

		return (
			<div className={modalClass}>
				<Button color="grey" type="link" className={modalClass.elem('close')} onClick={reject}>
					<Icon name="times" />
				</Button>
				<Row>
					<Column fixedWidth={250}>{client ? <AssignModalSidebar client={client} /> : null}</Column>
					<Column>
						<div className={modalClass.elem('content')}>{this.renderContent(hasInTime)}</div>
					</Column>
				</Row>
			</div>
		);
	}
}

AssignModal.propTypes = {
	activitiesAndAppointments: PropTypes.array.isRequired,
	onOpen: PropTypes.func.isRequired,
	editAppoinivity: PropTypes.func,
	reject: PropTypes.func,
	resolve: PropTypes.func,
	changeView: PropTypes.func,
	client: PropTypes.object,
	modalParams: PropTypes.shape({
		client: PropTypes.object,
		contactId: PropTypes.number,
		from: PropTypes.string,
		formFields: PropTypes.array,
		formId: PropTypes.number
	}),
	loading: PropTypes.bool,
	activitiesLoading: PropTypes.bool,
	view: PropTypes.oneOf(Object.keys(views)),
	reloadModalPosition: PropTypes.func
};

AssignModal.defaultProps = {
	activitiesAndAppointments: [],
	modalParams: {}
};

export const detached = AssignModal;
export default connect(mapStateToProps, mapDispatchToProps)(AssignModal);

import PropTypes from 'prop-types';
import React from 'react';
import { Equals, NotEquals, EqualsAll } from 'Resources/ComparisonTypes';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import ToggleList from './ToggleList';
import StackedDateInput from 'Components/Inputs/StackedDateInput/StackedDateInput';

export default class VisitInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			isNot: Tools.$translate('comparison.NotEquals'),
			placeholder: Tools.$translate('segment.anyPage'),
			all: Tools.$translate('default.all'),
			any: Tools.$translate('default.any')
		};

		this.onPageChanged = this.onChange.bind(this, 'page');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'page':
				newFilter.value.Page.value = value;
				break;
			case 'preset':
				newFilter.value.Date = value;
				break;
			case 'comperator':
				if (value === NotEquals) {
					newFilter.value.Page.comparisonType = Equals;
					newFilter.value.hasVisits = false;
				} else {
					newFilter.value.Page.comparisonType = value;
					newFilter.value.hasVisits = true;
				}
				break;
		}

		newFilter.inactive = false;

		onChange(newFilter.value, newFilter);
	}

	render() {
		const { filter, selectData, LIST_AJAX_LIMIT, fakeConfigs } = this.props;

		const listProps = {
			placeholder: this.lang.placeholder,
			config: fakeConfigs.Page,
			defaultValue: filter.value.Page.value,
			onChange: this.onPageChanged,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT,
			select2: {
				formatResult: function (page, container, query, escape) {
					const text = page.name ? page.name : `<< ${Tools.$translate('default.noTitle')} >>`;

					let urlText = page.url;

					if (urlText.length > 40) {
						urlText = urlText.substr(0, 19) + '...' + urlText.substr(urlText.length - 19, urlText.length);
					}

					const impressionsText = Tools.$filter('numberFormat')(page.pageImpression);

					return `<div class="up-page-select"> 
							<div class="visit-row"> 
								<div class="text-container"> 
									<div class="list-text-wrap">${escape(text)}</div>
									<div class="list-text-wrap url-row">${escape(urlText)}</div>
									<div class="list-text-wrap"> 
										<i style="margin-right: 5px" class="fa fa-eye" aria-hidden="true"></i>
										${escape(impressionsText)}
									</div>
								</div>
							</div>
						</div>`;
				},
				initSelection: (element, callback) => {
					const { LeaderPage, RequestBuilder } = Tools;
					const urls = element
						.val()
						// eslint-disable-next-line no-useless-escape
						.replace(/[\]\[]/g, '')
						.split(',');

					if (urls.length) {
						const rb = new RequestBuilder();
						rb.fields = [
							LeaderPage.attr.url.field,
							LeaderPage.attr.name.field,
							LeaderPage.attr.pageImpression.field
						];
						rb.addFilter(LeaderPage.attr.url, rb.comparisonTypes.MatchExact, urls);

						LeaderPage.find(rb.build())
							.then(res => callback(res.data))
							.catch(() => callback([]));
					}
				}
			}
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.all
				},
				{
					value: NotEquals,
					text: this.lang.isNot
				}
			],
			onClick: this.onComperatorChange,
			value: filter.value.hasVisits ? filter.value.Page.comparisonType || Equals : NotEquals
		};

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<ToggleList className="col-sm-8" list={listProps} toggle={toggleProps} isListActive={() => true} />
					<div className="col-sm-4">
						<StackedDateInput config={{}} filter={filter.value.Date} onChange={this.onPresetChange} />
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

VisitInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	fakeConfigs: PropTypes.object.isRequired
};

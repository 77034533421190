'use strict';

var roleSelectDir = 'upRoleSelect';
angular
	.module('upDirectives')
	.directive(roleSelectDir, function (selectHelper, AppService, $q) {
		return selectHelper.getDirectiveObject(roleSelectDir, {
			getter: function () {
				return $q.when({ data: AppService.getRoles() });
			},
			asIds: false
		});
	})
	.directive(roleSelectDir + 'Ids', function (selectHelper, AppService, $q) {
		return selectHelper.getDirectiveObjectIds(roleSelectDir + 'Ids', {
			getter: function (customerId, filter, opts, scope, $attrs) {
				var data = AppService.getRoleMap();
				data = data.all;
				if ($attrs.hasOwnProperty('addTopLevel')) {
					var index = _.findIndex(data, { id: -1 });
					if (index === -1) {
						data.unshift({ id: -1, name: 'Top level users' });
					}
				}
				if ($attrs.hasOwnProperty('removeRole') && $attrs.removeRole !== '-1') {
					var removeIndex = _.findIndex(data, { id: parseInt($attrs.removeRole) });
					if (removeIndex !== -1) {
						data.splice(removeIndex, 1);
					}
				}

				return $q.when({ data: data });
			},
			asIds: true
		});
	});

import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { EllipsisTooltip, Row, TableColumn, TableRow, Text } from '@upsales/components';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { BasicUser, BasicUserWithPermissions } from 'App/resources/Model/User';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import Client, { ClientIdName } from 'App/resources/Model/Client';
import SimpleListView from 'App/pages/ClientCard/SimpleListView';
import { useSupportUsers } from 'App/components/hooks/appHooks';
import GenericHref from 'Components/Helpers/Hrefs/GenericHref';
import BemClass from '@upsales/components/Utils/bemClass';
import { HashRouter as Router } from 'react-router-dom';
import { dateCalendar } from 'App/helpers/DateHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import type Ticket from 'App/resources/Model/Ticket';
import { Equals } from 'Resources/ComparisonTypes';
import TicketResource from 'App/resources/Ticket';
import Contact from 'App/resources/Model/Contact';
import TicketAttributes from 'Attributes/Ticket';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import Avatar from 'Components/Avatar';
import React from 'react';

import './ClientContactCardTicketList.scss';

const COLUMNS_OPEN_CONTACT = ['title', 'regDate', 'user'];
const COLUMNS_CLOSED_CONTACT = ['title', 'resolveDate', 'user'];
const className = new BemClass('ClientCardContent__Support');

type SupportProps = {
	client: Client | Contact;
	isClient: boolean;
	subaccountIds?: number[];
};

type GetDataProps = SupportProps & {
	rb: RequestBuilder;
	getOpenTickets?: boolean;
	getArchivedTickets?: boolean;
	subaccountIds?: number[];
};

const getData = ({
	client,
	isClient,
	rb,
	getOpenTickets,
	getArchivedTickets,
	subaccountIds
}: GetDataProps): Promise<{ data: Ticket[]; metadata: { total: number } }> => {
	if (getOpenTickets !== undefined) {
		rb.addSort({ field: getOpenTickets ? 'regDate' : 'resolveDate' }, false);
		rb.addFilter({ field: 'status.closed' }, Equals, getOpenTickets ? false : true);
	} else {
		rb.addSort({ field: 'regDate' }, false);
	}

	if (getArchivedTickets) {
		rb.addFilter({ field: 'isArchived' }, Equals, true);
	} else {
		rb.addFilter({ field: 'isArchived' }, Equals, false);
	}

	if (isClient) {
		rb.addFilter({ field: 'client.id' }, Equals, [client.id, ...(subaccountIds || [])]);
	} else {
		rb.addFilter({ field: 'contact.id' }, Equals, client.id);
	}

	return TicketResource.find(rb.build())
		.then(data => {
			return data;
		})
		.catch(error => {
			logError(error);
			return { data: [], metadata: { total: 0 } };
		});
};

export const renderTableRow = (
	ticket: Ticket,
	{ columns, attributes, categories }: ListViewTableProvided<Ticket>,
	users: BasicUserWithPermissions[],
	subaccountIds: number[],
	isOperationalAccount: boolean
) => {
	const foundUser = users.filter(u => !u.ghost).find(u => u.id === ticket.user?.id);
	const userIsInactive: boolean = !foundUser && !!ticket.user;
	const columnElements = columns.map(column => {
		let content = null;

		switch (column) {
			case 'title':
				return (
					<TableColumn className={className.elem('subject').b()} key={column + ticket.id}>
						<EllipsisTooltip title={ticket.title}>
							<Text size="sm">{ticket.title}</Text>
						</EllipsisTooltip>
					</TableColumn>
				);
			case 'subaccount':
				return isOperationalAccount ? (
					<TableColumn>
						{ticket.client && subaccountIds.includes(ticket.client?.id) ? (
							<SubaccountsColumn client={ticket.client as ClientIdName} />
						) : null}
					</TableColumn>
				) : null;
			case 'contact':
				content = ticket.contact ? (
					<GenericHref type="contact" text={ticket.contact?.name} id={ticket.contact?.id} />
				) : (
					<Text size="sm" italic color="grey-10">
						{T('default.noContact')}
					</Text>
				);
				break;
			case 'user':
				content = (
					<EllipsisTooltip
						title={
							ticket.user
								? userIsInactive
									? `${ticket.user.name} (${T('default.inactive')})`
									: ticket.user.name
								: T('support.unassigned')
						}
						className={className.elem('user-name').b()}
					>
						{ticket.user ? (
							<>
								<Avatar user={ticket.user as BasicUser} size={20} />
								<Text size="sm">
									{userIsInactive
										? `${ticket.user.name} (${T('default.inactive')})`
										: ticket.user.name}
								</Text>
							</>
						) : (
							<Text size="sm">{T('support.unassigned')}</Text>
						)}
					</EllipsisTooltip>
				);
				break;
			case 'regDate':
				content = <Text size="sm">{dateCalendar(new Date(ticket.regDate), true) as string}</Text>;
				break;
			case 'resolveDate':
				content = (
					<Text size="sm">
						{
							dateCalendar(
								new Date(ticket.resolveDate ? ticket.resolveDate : ticket.modDate),
								true
							) as string
						}
					</Text>
				);
				break;
			default:
				return (
					<ListViewDefaultColumn<Ticket>
						key={column}
						item={ticket}
						attributes={attributes}
						column={column}
						categories={categories}
					/>
				);
		}

		return <TableColumn key={column + ticket.id}>{content}</TableColumn>;
	});

	return (
		<TableRow key={ticket.id} onClick={() => openModal('EditTicket', { ticketId: ticket.id })}>
			{columnElements}
		</TableRow>
	);
};

const renderOpenTableRow =
	(users: BasicUserWithPermissions[], subaccountIds: number[], isOperationalAccount: boolean) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount
		);
	};

const renderClosedTableRow =
	(users: BasicUserWithPermissions[], subaccountIds: number[], isOperationalAccount: boolean) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount
		);
	};

const renderArchivedTableRow =
	(users: BasicUserWithPermissions[], subaccountIds: number[], isOperationalAccount: boolean) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount
		);
	};

const TicketList = (props: SupportProps) => {
	const users = useSupportUsers('active');
	const columnsOpenClient = ['title', 'contact', 'regDate', 'user'];
	const columnsClosedClient = ['title', 'contact', 'resolveDate', 'user'];
	const subaccountIds = props.subaccountIds ?? [];

	const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
	const isOperationalAccount = hasSubaccounts && subaccountIds.length > 0;

	if (isOperationalAccount) {
		columnsOpenClient.splice(1, 0, 'subaccount');
		columnsClosedClient.splice(1, 0, 'subaccount');
	}

	return (
		<Router>
			<SimpleListView<Ticket>
				classes={className}
				renderTableRow={renderOpenTableRow(users, subaccountIds, isOperationalAccount)}
				getData={(rb: RequestBuilder) =>
					getData({
						client: props.client,
						isClient: props.isClient,
						rb,
						getOpenTickets: true,
						subaccountIds: subaccountIds
					})
				}
				attributes={TicketAttributes}
				columns={props.isClient ? columnsOpenClient : COLUMNS_OPEN_CONTACT}
				broadcastType={'ticket'}
				renderNoData={() => <DefaultNoData formatNoData={() => T('support.noOpenTickets')} subtitle="" />}
				renderHeader={() => (
					<Row className={className.elem('header').b()}>
						<Text size="xl">{T('support.openTickets')}</Text>
					</Row>
				)}
			/>
			<SimpleListView<Ticket>
				classes={className}
				renderTableRow={renderClosedTableRow(users, subaccountIds, isOperationalAccount)}
				getData={(rb: RequestBuilder) =>
					getData({
						client: props.client,
						isClient: props.isClient,
						rb,
						getOpenTickets: false,
						subaccountIds: subaccountIds
					})
				}
				attributes={TicketAttributes}
				columns={props.isClient ? columnsClosedClient : COLUMNS_CLOSED_CONTACT}
				broadcastType={'ticket'}
				renderNoData={() => <DefaultNoData formatNoData={() => T('support.noClosedTickets')} subtitle="" />}
				renderHeader={() => (
					<Row className={className.elem('header').b()}>
						<Text size="xl">{T('support.closedTickets')}</Text>
					</Row>
				)}
			/>
			<SimpleListView<Ticket>
				classes={className}
				renderTableRow={renderArchivedTableRow(users, subaccountIds, isOperationalAccount)}
				getData={(rb: RequestBuilder) =>
					getData({
						client: props.client,
						isClient: props.isClient,
						rb,
						getArchivedTickets: true,
						subaccountIds: subaccountIds
					})
				}
				attributes={TicketAttributes}
				columns={props.isClient ? columnsOpenClient : COLUMNS_OPEN_CONTACT}
				broadcastType={'ticket'}
				renderNoData={() => <DefaultNoData formatNoData={() => T('support.noArchivedTickets')} subtitle="" />}
				renderHeader={() => (
					<Row className={className.elem('header').b()}>
						<Text size="xl">{T('support.archivedTickets')}</Text>
					</Row>
				)}
			/>
		</Router>
	);
};

export default TicketList;

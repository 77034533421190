import React from 'react';
import PropTypes from 'prop-types';
import { Text, Block, Row, Column } from '@upsales/components';
import { TIER_TYPE } from 'Store/reducers/AdminEditProductReducer';
import { productTier as productTierShape } from 'App/babel/propTypes/ProductShape';
import './EditProductTierEndRow.scss';

const EditProductTierEndRow = ({ lastTier, currency }) => {
	const currencyObjI = lastTier.currencies.findIndex(obj => obj.currency === currency);
	const currencyObj = lastTier.currencies[currencyObjI];
	const start = parseInt(lastTier.end) + 1;

	return (
		<Row className="EditProductTierEndRow">
			<Column size={3}>
				<Block space="pll pbl">
					<Text color="grey-10">{start}</Text>
				</Block>
			</Column>
			<Column size={3}>
				<Block space="pll pbl">
					<Text color="grey-10">{'∞'}</Text>
				</Block>
			</Column>
			<Column size={6}>
				<Block space="pll pbl">
					<Text color="grey-10">{currencyObj.value}</Text>
				</Block>
			</Column>
		</Row>
	);
};

EditProductTierEndRow.defaultProps = {
	tier: {
		start: null,
		end: null,
		type: TIER_TYPE.UNIT,
		currencies: []
	}
};

EditProductTierEndRow.propTypes = {
	tier: productTierShape.isRequired,
	currency: PropTypes.string.isRequired
};

export default EditProductTierEndRow;

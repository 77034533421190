import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';

angular.module('upAttributes').service('MailAttributes', [
	'FilterType',
	'DisplayType',
	'$translate',
	'AppService',
	'FeatureHelper',
	function (FilterType, DisplayType, $translate, AppService, FeatureHelper) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false
				},
				date: {
					title: 'default.date',
					field: 'date',
					sortable: 'date',
					selectableColumn: true,
					displayType: DisplayType.Date
				},
				type: {
					title: 'default.status',
					field: 'type',
					selectableColumn: true,
					displayType: DisplayType.String,
					elevioId: 278
				},
				from: {
					title: 'mail.from',
					field: 'from',
					selectableColumn: true,
					sortable: 'from',
					displayType: DisplayType.String
				},
				cc: {
					title: 'mail.cc',
					field: 'cc',
					selectableColumn: false,
					displayType: DisplayType.String
				},
				bcc: {
					title: 'mail.bcc',
					field: 'bcc',
					selectableColumn: false,
					displayType: DisplayType.String
				},
				fromName: {
					title: 'mail.fromName',
					field: 'fromName',
					selectableColumn: true,
					sortable: 'fromName',
					displayType: DisplayType.String
				},
				body: {
					title: 'mail.body',
					field: 'body',
					displayType: DisplayType.String
				},
				contact: {
					title: 'default.contact',
					field: 'contact',
					type: FilterType.String,
					displayType: DisplayType.String,
					skippMapping: true,
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					},
					selectableColumn: false
				},
				client: {
					title: 'default.account',
					field: 'client',
					type: FilterType.String,
					displayType: DisplayType.String,
					skippMapping: true,
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					},
					selectableColumn: false
				},
				userId: {
					title: 'default.user',
					field: 'userId',
					displayType: DisplayType.Number
				},
				socialEventId: {
					title: 'default.socialEvent',
					field: 'socialEventId',
					displayType: DisplayType.Number
				},
				template: {
					title: 'mail.template',
					field: 'template.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					sortable: 'template.name',
					parent: 'template',
					attr: {
						id: {
							field: 'template.id',
							type: FilterType.Number
						},
						name: {
							field: 'template.name',
							type: FilterType.String
						}
					},
					selectableColumn: true
				},
				events: {
					selectableColumn: true,
					title: 'mail.readclick',
					field: 'events.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'type',
					parent: 'events',
					attr: {
						id: {
							field: 'events.id',
							type: FilterType.Number
						},
						type: {
							field: 'events.type',
							type: FilterType.String
						},
						value: {
							field: 'events.value',
							type: FilterType.String
						}
					}
				},
				to: {
					title: 'default.email',
					field: 'to',
					selectableColumn: true,
					sortable: 'to',
					displayType: DisplayType.String
				},
				subject: {
					title: 'mail.subject',
					field: 'subject',
					sortable: 'subject',
					displayType: DisplayType.String
				},
				lastClickDate: {
					title: 'mail.readclick',
					field: 'lastClickDate',
					sortable: 'lastClickDate',
					displayType: DisplayType.String
				},
				lastEventDate: {
					title: 'mail.readclickDate',
					field: 'lastEventDate',
					sortable: 'lastEventDate',
					selectableColumn: true,
					displayType: DisplayType.Date
				},
				lastReadDate: {
					title: 'mail.readDate',
					field: 'lastReadDate',
					displayType: DisplayType.Date
				},
				groupMailId: {
					title: 'mail.mailing',
					field: 'groupMailId',
					displayType: DisplayType.Number
				},
				jobId: {
					title: 'mail.jobId',
					field: 'jobId',
					displayType: DisplayType.String
				},
				isMap: {
					title: 'mail.isMap',
					field: 'isMap',
					displayType: DisplayType.String
				},
				errorCause: {
					title: 'mail.errorCause',
					field: 'errorCause',
					displayType: DisplayType.String
				},
				attachments: {
					title: 'mail.attachments',
					field: 'attachments',
					displayType: DisplayType.String
				},
				mailBodySnapshotId: {
					title: 'admin.anonymization.mail_body_snapshot_id',
					field: 'mailBodySnapshotId',
					displayType: DisplayType.Number
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				recipients: {
					title: '',
					field: 'recipients',
					displayType: DisplayType.None
				}
			};

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var standardFilters = {
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'subject' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				User: generateUserFilter(null, {
					title: 'user',
					field: 'user.id'
				}),
				Date: {
					title: 'mail.sentDateFilter',
					field: 'date',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				Type: {
					title: 'default.status',
					field: 'type',
					inputType: 'select',
					type: 'list',
					multiComparitors: true,
					multiple: true,
					searchField: 'title',
					displayText: 'name',
					dataPromise: function ($q) {
						return $q.when({
							data: [
								{ id: 'out', name: $translate.instant('mail.sent') },
								{ id: 'in', name: $translate.instant('mail.received') },
								{ id: 'sch', name: $translate.instant('mail.scheduled') },
								{ id: 'cre', name: $translate.instant('mail.status.MISSING_CREDITS') },
								{ id: 'pay', name: $translate.instant('mail.status.MISSING_PAYMENT') },
								{ id: 'pro', name: $translate.instant('mail.status.PROCESSING') },
								{ id: 'err', name: $translate.instant('mail.status.ERROR') }
							]
						});
					}
				},
				GroupMail: {
					title: 'filters.fromGroupMail',
					field: 'groupMailId',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: 0, comparisonType: 'NotEquals' },
						{ text: 'default.no', value: 0, comparisonType: 'Equals' }
					]
				},
				IsRead: {
					title: 'mail.read',
					field: 'lastReadDate',
					type: 'notNull',
					displayType: 'radio',
					parent: 'default.mailEvents',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: true },
						{ text: 'default.no', value: false }
					]
				},
				IsClicked: {
					title: 'mail.clicked',
					field: 'lastClickDate',
					type: 'notNull',
					displayType: 'radio',
					parent: 'default.mailEvents',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: true },
						{ text: 'default.no', value: false }
					]
				},
				LastReadDate: {
					title: 'mail.readDate',
					field: 'lastReadDate',
					inputType: 'date',
					type: 'dateRange',
					parent: 'default.mailEvents',
					presets: dateFilterPresets
				},
				LastClickDate: {
					title: 'mail.clickDate',
					field: 'lastClickDate',
					inputType: 'date',
					type: 'dateRange',
					parent: 'default.mailEvents',
					presets: dateFilterPresets
				}
			};

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;
				if (
					metaData.params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}
			}

			var keys = {};
			angular.forEach(attributes, function (value, key) {
				// Vem har gjort detta? kommer inte funka om man vill använda det
				keys[key.toUpperCase()] = key.toLowerCase();
			});
			var requiredFields = ['client'];
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				keys: keys,
				requiredFields: requiredFields
			};
		};
	}
]);

'use strict';

angular.module('services.search', ['upResources', 'security']).factory('SearchService', [
	'$q',
	'Quicksearch',
	function ($q, Quicksearch) {
		var client = null;

		var sortOrder = {
			client: 1,
			contact: 2,
			subAccount: 3,
			activity: 4,
			appointment: 5,
			opportunity: 6,
			order: 7
		};

		var instance = {
			connect: function () {
				if (client) {
					client.disconnect();
					client = null;
				}
			},

			parse: {
				phone: function (phone) {
					var tmp = phone.replace(/[ ()-]/g, '');
					tmp = tmp.split('');
					phone = '';
					for (var i = 0; i < tmp.length; i++) {
						phone += '*' + tmp[i];
					}
					return phone;
				},
				name: function (name) {
					return name.trim();
				}
			},

			validate: {
				phone: function (str) {
					var re = /[0-9 -+()]{5,}/;
					return re.test(str);
				}
			},

			search: function (searchString, customerId, callback, ts, options) {
				if (!searchString || !searchString.length || searchString.length < 2) {
					return $q.when({});
				}
				return Quicksearch.search(searchString, ts, options).then(function (data) {
					var result = {};
					if (ts) {
						result.ts = data.ts;
					}

					if (data.data) {
						_.each(Object.keys(data.data), function (key) {
							result[key] = {
								data: data.data[key].data,
								metadata: data.data[key].metadata,
								type: key,
								sortOrder: sortOrder[key]
							};
						});
					}

					return result;
				});
			},
			listener: function (callback) {
				if (callback) {
					client?.on('find', callback);
				}
			}
		};

		return instance;
	}
]);

import openModal from 'App/services/Modal';
import { openDrawer } from 'Services/Drawer';
import SocialEvent from 'Resources/SocialEvents.js';
import entityName from 'Components/Helpers/EntityName';
import { comparisonTypes } from 'Resources/RequestBuilder';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import SocialEventAttributes from 'App/babel/attributes/SocialEventAttributes';
import FormAttributes from 'App/babel/attributes/FormAttributes';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

angular.module('domain.campaign').controller('Campaign', [
	'$scope',
	'$translate',
	'$upModal',
	'$state',
	'$stateParams',
	'meta',
	'Account',
	'RequestBuilder',
	'Contact',
	'Activity',
	'SaveInline',
	'MailCampaign',
	'Order',
	'AppService',
	'FeatureHelper',
	'UserDefinedObject',
	'UdoLink',
	'$q',
	'Appointment',
	'Campaign',
	'Form',
	'$safeApply',
	'MultiRunnerActions',
	function (
		$scope,
		$translate,
		$upModal,
		$state,
		$stateParams,
		meta,
		Account,
		RequestBuilder,
		Contact,
		Activity,
		SaveInline,
		MailCampaign,
		Order,
		AppService,
		FeatureHelper,
		UserDefinedObject,
		UdoLink,
		$q,
		Appointment,
		Campaign,
		Form,
		$safeApply,
		MultiRunnerActions
	) {
		var CampaignCtrl = this;
		var customerId = $stateParams.customerId;
		var metadata;

		CampaignCtrl.editCampaign = function (e) {
			if (CampaignCtrl.campaign?.isCallList) {
				const { id, users, name } = CampaignCtrl.campaign;
				openDrawer('EditCallList', { callList: { id, users, name } });
			} else {
				$upModal.open('editCampaign', { customerId: customerId, id: CampaignCtrl.campaign.id }, e);
			}
		};

		CampaignCtrl.editAccount = function (e, account) {
			e.preventDefault();
			e.stopPropagation();
			$upModal.open('editAccount', { customerId: customerId, id: account.id }, e);
		};

		CampaignCtrl.deleteCampaign = function (e) {
			e.preventDefault();

			var confirmButtonText = $translate.instant('admin.modal.deleteEntity', {
				entity: $translate
					.instant(entityName(CampaignCtrl.campaign.isCallList ? 'calllist' : 'campaign', 1))
					.toLowerCase()
			});
			var alertConfirmOptions = {
				type: 'confirm',
				reactive: true,
				fullscreen: true,
				hideAbort: false,
				dialog: AlertConfirm,
				id: 'confirm-delete-fields',
				body: React.createElement(AlertBody, {
					customerId: customerId,
					numSelected: 1,
					entity: CampaignCtrl.campaign.isCallList ? 'callList' : 'campaign'
				}),
				confirmButtonText: confirmButtonText,
				confirmClass: 'btn-red btn-lg',
				confirmationMode: 'text',
				confirmText: AppService.getSelf().email,
				confirmFn: function () {
					// Delete
					if (!isNaN(CampaignCtrl.campaign.id)) {
						return Campaign.customer(customerId)
							['delete'](CampaignCtrl.campaign)
							.then(function () {
								$state.go('react-root-campaigns', { customerId: customerId });
							});
					} else {
						return Promise.resolve();
					}
				}
			};

			if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				return openModal('Alert', {
					...alertConfirmOptions,
					title: getConfirmationTitle(CampaignCtrl.campaign.isCallList ? 'CallList' : 'Campaign', 1),
					onClose: confirmed => {
						if (confirmed) {
							alertConfirmOptions.confirmFn();
						}
					}
				});
			}

			return $upModal.open('alert', alertConfirmOptions);
		};

		CampaignCtrl.editActivity = function (e, activity) {
			e.preventDefault();
			e.stopPropagation();

			var params = {};

			if (activity) {
				params.id = activity.id;
			}

			if (activity && activity.isAppointment) {
				params.appointment = { project: { id: CampaignCtrl.campaign.id } };
				$upModal.open('editAppointment', params);
			} else {
				params.activity = { project: { id: CampaignCtrl.campaign.id } };
				$upModal.open('editActivity', params);
			}
		};

		CampaignCtrl.createActivity = function (e) {
			e.preventDefault();
			e.stopPropagation();

			if (CampaignCtrl.removeActivities) {
				openDrawer('CreateCall', { callListId: CampaignCtrl.campaign.id });
			} else {
				var options = {
					activity: {
						project: { id: CampaignCtrl.campaign.id, name: CampaignCtrl.campaign.name }
					}
				};
				$upModal.open('editActivity', options);
			}
		};

		CampaignCtrl.createAppointment = function () {
			const options = {
				appointment: {
					project: { id: CampaignCtrl.campaign.id, name: CampaignCtrl.campaign.name }
				}
			};

			$upModal.open('editAppointment', options);
		};

		CampaignCtrl.addOrder = function () {
			$upModal.open('editOrder', {
				campaign: CampaignCtrl.campaign,
				customerId: customerId
			});
		};

		CampaignCtrl.addOpportunity = function () {
			$upModal.open('editOrder', {
				type: 'opportunity',
				campaign: CampaignCtrl.campaign,
				customerId: customerId
			});
		};

		CampaignCtrl.openOpportunity = function (id) {
			$upModal.open('editOrder', {
				type: 'opportunity',
				campaign: CampaignCtrl.campaign.id,
				customerId: customerId,
				id: id
			});
		};

		CampaignCtrl.sendMail = function () {
			Tools.$upModal.open('createGroupMail', { project: CampaignCtrl.campaign });
		};

		CampaignCtrl.matchClients = function () {
			var options = {
				campaignId: CampaignCtrl.campaign.id
			};
			$state.go('matcher', options);
		};

		// return true if current state is same as provided
		CampaignCtrl.isState = function (state) {
			return $state.is('campaign.' + state);
		};

		CampaignCtrl.checkRights = function () {
			return true;
		};

		CampaignCtrl.addUdo = function (id) {
			var udoId = id ? id : $state.params.udoId;
			$upModal.open('editUserDefinedObject', {
				typeId: udoId,
				object: {
					project: { id: CampaignCtrl.campaign.id, name: CampaignCtrl.campaign.name }
				}
			});
		};

		CampaignCtrl.addSubObject = function (e) {
			var state = $state.$current.toString().split('.')[1];

			switch (state) {
				case 'order':
					CampaignCtrl.addOrder();
					break;
				case 'activities':
					CampaignCtrl.createActivity(e);
					break;
				case 'appointments':
					CampaignCtrl.createAppointment();
					break;
				case 'opportunities':
					CampaignCtrl.addOpportunity();
					break;
				case 'accounts':
					CampaignCtrl.addAccount(e);
					break;
				case 'contacts':
					CampaignCtrl.addContact(e);
					break;
				case 'mailinglists':
					CampaignCtrl.sendMail();
					break;
				case 'socialEvents':
					CampaignCtrl.addSocialEvent(e);
					break;
				case 'udo':
					CampaignCtrl.addUdo();
					break;
				case 'forms':
					CampaignCtrl.addForm(e);
					break;
			}
		};

		CampaignCtrl.getActivityLabel = (plural = false) => {
			if (CampaignCtrl.removeActivities) {
				return plural ? 'todo.phonecalls' : 'default.phonecall';
			} else {
				if (CampaignCtrl.campaign.isCallList) {
					return plural ? 'voice.phoneCalls' : 'default.phonecall';
				}
				return plural ? 'default.activities' : 'default.activity';
			}
		};

		CampaignCtrl.addLabel = function () {
			var state = $state.$current.toString().split('.')[1];

			switch (state) {
				case 'order':
					return 'default.order';
				case 'activities':
					return CampaignCtrl.getActivityLabel();
				case 'appointments':
					return 'default.appointment';
				case 'opportunities':
					return 'default.opportunity';
				case 'accounts':
					return 'default.account';
				case 'contacts':
					return 'default.contact';
				case 'mailinglists':
					if (CampaignCtrl.isAvailable.groupMail) {
						return 'mail.mailings';
					}
					return false;
				case 'socialEvents':
					return 'default.socialEvent';
				case 'udo':
					return ' ';
				case 'forms':
					return 'form.form';
				default:
					return false;
			}
		};

		CampaignCtrl.getExportAction = function () {
			var state = $state.$current.toString().split('.')[1];

			switch (state) {
				case 'order':
					return MultiRunnerActions.get(MultiRunnerActions.type.ORDER).export;
				case 'activities':
					return MultiRunnerActions.get(MultiRunnerActions.type.ACTIVITY).export;
				case 'appointments':
					return MultiRunnerActions.get(MultiRunnerActions.type.APPOINTMENT).export;
				case 'opportunities':
					return MultiRunnerActions.get(MultiRunnerActions.type.OPPORTUNITY).export;
				case 'accounts':
					return MultiRunnerActions.get(MultiRunnerActions.type.ACCOUNT).export;
				case 'contacts':
					return MultiRunnerActions.get(MultiRunnerActions.type.CONTACT).export;
				case 'mailinglists':
					return MultiRunnerActions.get(MultiRunnerActions.type.MAIL).export;
				case 'udo':
					return MultiRunnerActions.get(MultiRunnerActions.type.USERDEFOBJ_CONTACT, {
						udo: { id: CampaignCtrl.udo.id }
					}).export;
			}
		};

		CampaignCtrl.exportSubObject = function (e) {
			e.preventDefault();
			e.stopPropagation();
			var state = $state.$current.toString().split('.')[1];
			var exportAciton = CampaignCtrl.getExportAction();

			var rb, columns;

			switch (state) {
				case 'order':
					rb = getOrderRequestBuilder();
					columns = [
						'date',
						'description',
						'user.name',
						'contact.name',
						'client.name',
						'value',
						'stage.name'
					];
					break;
				case 'activities':
					rb = getRequestBuilder(CampaignCtrl.campaign.isCallList ? 'callList.id' : undefined);
					columns = ['date', 'description', 'activityType.name', 'contacts', 'users'];
					break;
				case 'appointments':
					rb = getRequestBuilder();
					columns = ['date', 'description', 'activityType.name', 'contacts', 'users'];
					break;
				case 'opportunities':
					rb = getOpportunityRequestBuilder();
					columns = [
						'date',
						'description',
						'user.name',
						'contact.name',
						'client.name',
						'value',
						'stage.name'
					];
					break;
				case 'accounts':
					rb = getRequestBuilder();
					columns = ['name', 'phone', 'users'];
					break;
				case 'contacts':
					rb = getRequestBuilder();
					columns = ['name', 'title', 'phone', 'cellPhone', 'client.name', 'contact.email'];
					break;
				case 'mailinglists':
					rb = getMailCampaignRequestBuilder();
					columns = ['sendDate', 'status', 'subject'];
					break;
				case 'udo':
					rb = getUdoRequestBuilder();
					columns = ['description'];
					break;
			}

			rb.limit = undefined;
			var filters = rb.build();
			return exportAciton.run(null, filters, rb, columns, {}, true);
		};

		CampaignCtrl.addSocialEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();

			openModal('FindSocialEvent', {
				modifyFilters: rb => {
					const orBuilder = rb.orBuilder();
					orBuilder.next();
					orBuilder.addFilter(
						SocialEventAttributes.projects,
						comparisonTypes.NotEquals,
						CampaignCtrl.campaign.id
					);
					orBuilder.next();
					orBuilder.addFilter(SocialEventAttributes.projects, comparisonTypes.Equals, null);
					orBuilder.done();
				},
				onClose: socialEvent => {
					if (socialEvent && !socialEvent.projects.find(project => project.id === CampaignCtrl.campaign.id)) {
						const projects = socialEvent.projects || [];
						projects.push({ id: CampaignCtrl.campaign.id });
						SocialEvent.save({ id: socialEvent.id, projects: projects });
					}
				}
			});
		};

		CampaignCtrl.addAccount = function (e) {
			e.preventDefault();
			e.stopPropagation();

			const excludeIds = _.pluck(CampaignCtrl.accounts, 'id');

			openModal('FindClient', {
				excludeIds,
				modifyFilters: rb => {
					rb.addFilter(Account.attr.campaigns, comparisonTypes.NotEquals, CampaignCtrl.campaign.id);
				},
				onClose: client => {
					if (client && !client.projects?.find(project => project.id === CampaignCtrl.campaign.id)) {
						const campaigns = client.projects || [];
						campaigns.push({ id: CampaignCtrl.campaign.id });
						Account.customer(customerId).save({ id: client.id, campaigns: campaigns });
					}
				}
			});
		};

		// save account inline function
		CampaignCtrl.saveInline = function (value, promise, field) {
			SaveInline(value, promise, field, CampaignCtrl.campaign.id, 'Campaign');
		};

		CampaignCtrl.addContact = function (e) {
			e.preventDefault();
			e.stopPropagation();

			openModal('FindContact', {
				modifyFilters: rb => {
					rb.addFilter(Contact.attr.campaigns, comparisonTypes.NotEquals, CampaignCtrl.campaign.id);
				},
				onClose: contact => {
					if (contact) {
						var campaigns = contact.projects || [];
						campaigns.push({ id: CampaignCtrl.campaign.id });
						Contact.customer(customerId).save({ id: contact.id, campaigns: campaigns });
					}
				}
			});
		};

		CampaignCtrl.addForm = function (e) {
			e.preventDefault();
			e.stopPropagation();

			const excludeIds = _.pluck(CampaignCtrl.forms, 'id');

			openModal('FindForm', {
				excludeIds,
				modifyFilters: rb => {
					rb.addFilter(FormAttributes.projects.attr.id, comparisonTypes.NotEquals, CampaignCtrl.campaign.id);
				},
				onClose: form => {
					if (form && !form.projects?.find(project => project.id === CampaignCtrl.campaign.id)) {
						const projects = form.projects || [];
						projects.push({ id: CampaignCtrl.campaign.id });
						Form.save({ id: form.id, projects: projects });
					}
				}
			});
		};

		var orderAdded = function (e, added) {
			if (added.project && added.project.id === CampaignCtrl.campaign.id) {
				if (added.probability === 100) {
					CampaignCtrl.ordersTotal++;
				} else {
					CampaignCtrl.opportunitiesTotal++;
				}
			}
		};

		var socialEventAdded = function () {
			setTimeout(function () {
				getSocialEvents();
			}, 1500);
		};

		var orderUpdated = function () {
			setTimeout(function () {
				getOrders();
				getOpportunities();
			}, 1500);
		};

		$scope.$on('socialEvent.added', socialEventAdded);
		$scope.$on('socialEvent.updated', socialEventAdded);

		$scope.$on('mailCampaign.added', function () {
			CampaignCtrl.mailCampaignsTotal++;
		});

		$scope.$on('campaign.updated', function (e, updated) {
			if (updated.id === CampaignCtrl.campaign.id) {
				var oldCustom = CampaignCtrl.campaign.custom;

				angular.forEach(oldCustom, function (field) {
					var found = _.find(updated.custom, { fieldId: field.id });
					if (found) {
						field.value = found.value;
					} else {
						field.value = null;
					}
				});

				CampaignCtrl.campaign = angular.extend(CampaignCtrl.campaign, updated);
				CampaignCtrl.campaign.custom = oldCustom;
			}
			$safeApply($scope);
		});

		$scope.$on('activity.deleted', function (e, deleted) {
			if (deleted.project && deleted.project.id === CampaignCtrl.campaign.id) {
				CampaignCtrl.activitiesTotal--;
			}
		});

		$scope.$on('activity.added', function (e, added) {
			if ([added.project?.id, added.callList?.id].includes(CampaignCtrl.campaign.id)) {
				CampaignCtrl.activitiesTotal++;
			}
		});

		$scope.$on('activity.updated', function () {
			setTimeout(function () {
				getActivities();
			}, 1500);
		});

		$scope.$on('appointment.deleted', function (e, deleted) {
			if (deleted.project && deleted.project.id === CampaignCtrl.campaign.id) {
				CampaignCtrl.appointmentsTotal--;
			}
		});

		$scope.$on('appointment.added', function (e, added) {
			if (added.project && added.project.id === CampaignCtrl.campaign.id) {
				CampaignCtrl.appointmentsTotal++;
			}
		});

		$scope.$on('appointment.updated', function () {
			setTimeout(function () {
				getAppointments();
			}, 1500);
		});

		$scope.$on('contact.deleted', function (e, deleted) {
			if (deleted.projects && _.findIndex(deleted.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				CampaignCtrl.contactsTotal--;
			}
		});

		$scope.$on('contact.added', function (e, added) {
			if (added.projects && _.findIndex(added.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				CampaignCtrl.contactsTotal++;
			}
		});

		$scope.$on('contact.updated', function () {
			setTimeout(function () {
				getContacts();
			}, 1500);
		});

		$scope.$on('account.deleted', function (e, deleted) {
			if (deleted.projects && _.findIndex(deleted.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				CampaignCtrl.accountsTotal--;
			}
		});

		$scope.$on('account.added', function (e, added) {
			if (added.projects && _.findIndex(added.projects, { id: CampaignCtrl.campaign.id }) !== -1) {
				CampaignCtrl.accountsTotal++;
			}
		});

		$scope.$on('account.updated', function () {
			setTimeout(function () {
				getAccounts();
			}, 1500);
		});

		var formAdded = function () {
			setTimeout(function () {
				getForms();
			}, 1500);
		};

		$scope.$on('form.added', formAdded);
		$scope.$on('form.updated', formAdded);

		$scope.$on('order.added', orderAdded);
		$scope.$on('opportunity.added', orderAdded);

		$scope.$on('order.updated', orderUpdated);

		function getRequestBuilder(field) {
			var filter = new RequestBuilder();
			filter.addFilter({ field: field || 'project.id' }, filter.comparisonTypes.Equals, $stateParams.id);
			filter.limit = 0;
			return filter;
		}

		var getFilters = function (field) {
			var filter = getRequestBuilder(field).build();
			return filter;
		};

		function getAccounts() {
			Account.customer(customerId)
				.find(getFilters())
				.then(function (res) {
					CampaignCtrl.accountsTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getAccounts error', err));
		}

		function getContacts() {
			Contact.customer(customerId)
				.find(getFilters())
				.then(function (res) {
					CampaignCtrl.contactsTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getContacts error', err));
		}

		function getOpportunityRequestBuilder() {
			var orderFilter = new RequestBuilder();
			orderFilter.addFilter(Order.attr.campaign, orderFilter.comparisonTypes.Equals, $stateParams.id);
			orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 100);
			orderFilter.limit = 0;

			return orderFilter;
		}

		function getOpportunities() {
			var orderFilter = getOpportunityRequestBuilder();

			Order.customer(customerId)
				.find(orderFilter.build())
				.then(function (res) {
					CampaignCtrl.opportunitiesTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getOpportunities error', err));
		}

		function getOrderRequestBuilder() {
			var orderFilter = new RequestBuilder();
			orderFilter.addFilter(Order.attr.campaign, orderFilter.comparisonTypes.Equals, $stateParams.id);
			orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.Equals, 100);
			orderFilter.limit = 0;

			return orderFilter;
		}

		function getOrders() {
			var orderFilter = getOrderRequestBuilder();
			Order.customer(customerId)
				.find(orderFilter.build())
				.then(function (res) {
					CampaignCtrl.ordersTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getOrders error', err));
		}

		function getActivities() {
			Activity.customer(customerId)
				.find(getFilters(CampaignCtrl.campaign.isCallList ? 'callList.id' : undefined))
				.then(function (res) {
					CampaignCtrl.activitiesTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getActivities error', err));
		}

		function getSocialEvents() {
			SocialEvent.find(getFilters('projects.id'))
				.then(function (res) {
					CampaignCtrl.socialEventsTotal = res.metadata.total;
					$safeApply($scope);
				})
				.catch(err => console.log('CampaignCtrl getSocialEvents error', err));
		}

		function getAppointments() {
			Appointment.customer(customerId)
				.find(getFilters())
				.then(function (res) {
					CampaignCtrl.appointmentsTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getAppointments error', err));
		}

		function getForms() {
			Form.find(getFilters())
				.then(function (res) {
					CampaignCtrl.formsTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getForms error', err));
		}

		function getMailCampaignRequestBuilder() {
			var filter = new RequestBuilder();
			filter.addFilter(MailCampaign.attr.campaign, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addFilter(MailCampaign.attr.isArchived, filter.comparisonTypes.Equals, false);
			filter.addFilter(MailCampaign.attr.status, filter.comparisonTypes.Equals, ['DRAFT', 'SENT', 'SCHEDULED']);
			filter.limit = 0;

			return filter;
		}

		var getMailCampaigns = function () {
			var filter = getMailCampaignRequestBuilder();
			MailCampaign.customer(customerId)
				.find(filter.build())
				.then(function (res) {
					CampaignCtrl.mailCampaignsTotal = res.metadata.total;
				})
				.catch(err => console.log('CampaignCtrl getMailCampaigns error', err));
		};

		function getUdoRequestBuilder() {
			var filters = new RequestBuilder();
			filters.limit = 0;
			filters.addFilter(
				UserDefinedObject.attr.campaign.attr.id,
				filters.comparisonTypes.Equals,
				CampaignCtrl.campaign.id
			);

			return filters;
		}

		var getNoUDO = function () {
			if (!CampaignCtrl.hasUdo) {
				return false;
			}

			var promises = {};
			CampaignCtrl.udoTotal = {};

			// Account filter
			var filters = getUdoRequestBuilder();

			angular.forEach(CampaignCtrl.udo, function (object) {
				// Get total items for each object (for this account)
				CampaignCtrl.udoTotal[object.id] = 0;

				promises[object.id] = UserDefinedObject.setId(object.id)
					.find(filters.build())
					.then(function (res) {
						CampaignCtrl.udoTotal[object.id] = res.metadata.total;
					});
			});

			return $q.all(promises);
		};

		// Only run once!!! (it initializes event listeners)
		var checkforUDO = function () {
			CampaignCtrl.udo = _.filter(metadata.params.UserDefinedObject, { link: UdoLink.CAMPAIGN });

			// Set var so we know there is some accountUdos
			if (CampaignCtrl.udo.length && FeatureHelper.isAvailable(FeatureHelper.Feature.UDO)) {
				CampaignCtrl.hasUdo = true;

				angular.forEach(CampaignCtrl.udo, function (udo) {
					$scope.$on('userDefinedObject' + udo.id + '.added', function (e, updated) {
						if (updated.project && updated.project.id === CampaignCtrl.campaign.id) {
							CampaignCtrl.udoTotal[udo.id]++;
						}
					});
					$scope.$on('userDefinedObject' + udo.id + '.deleted', function (e, deleted) {
						if (deleted.project && deleted.project.id === CampaignCtrl.campaign.id) {
							CampaignCtrl.udoTotal[udo.id]--;
						}
					});
				});
			} else {
				CampaignCtrl.hasUdo = false;
			}
		};

		var init = function () {
			metadata = AppService.getMetadata();
			var self = AppService.getSelf();

			// campaign
			CampaignCtrl.campaign = meta.campaign.data;

			CampaignCtrl.customerSelf = AppService.getSelf();
			CampaignCtrl.mailActivated = metadata.map.mail;

			CampaignCtrl.moveFromCampaign = CampaignCtrl.campaign.isCallList
				? $translate.instant('default.removeFromCallList')
				: $translate.instant('default.removeFromCampaign');

			CampaignCtrl.canMail =
				CampaignCtrl.mailActivated &&
				(CampaignCtrl.customerSelf.administrator || CampaignCtrl.customerSelf.userParams.mailAdmin);

			const hasMatcherFromSoliditet = CampaignCtrl.customerSelf.userParams.soliditetIsActive;
			const hasMatcherFromProspecting = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);

			const hasMatcher =
				(hasMatcherFromSoliditet || hasMatcherFromProspecting) &&
				(self.userParams.multiSoliditetAccess || CampaignCtrl.customerSelf.administrator) &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOLIDITET_MATCHER);

			CampaignCtrl.isAvailable = {
				activity: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				appointment: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				opportunity: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE),
				order: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS),
				account: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
				contact: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
				socialEvent: FeatureHelper.isAvailable(FeatureHelper.Feature.SOCIAL_EVENTS),
				udo: FeatureHelper.isAvailable(FeatureHelper.Feature.UDO),
				form: FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS),
				soliditet: hasMatcher
			};
			CampaignCtrl.removeActivities =
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') ||
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');

			CampaignCtrl.createRights = {};
			angular.forEach(self.createRights, function (value, type) {
				CampaignCtrl.createRights[type] = value === 'ALL' || value === 'OWN';
			});
			// get NO. accounts
			getAccounts();

			// get NO. contacts
			getContacts();

			// get NO. opportunities
			getOpportunities();

			// get NO. orders
			getOrders();

			// get NO. activities
			getActivities();

			// get NO. appointments
			getAppointments();

			getMailCampaigns();

			// Get NO. forms
			getForms();

			// Check for UDO on client then find some for this account
			checkforUDO();
			getNoUDO();

			// Get NO. socialEvents
			getSocialEvents();
		};

		init();
	}
]);

import Resource from './Resource';
import config from 'App/babel/config';
// import Attributes from 'Attributes/TipAttributes';

class Tip extends Resource {
	constructor() {
		super('external/randomTip' /*Attributes*/);

		this.REQUEST_OPTIONS.withCredentials = false;
	}

	_getUrl(url) {
		return config.URL + this._url + url;
	}

	getRandomTip() {
		return this._getRequest('', { methodName: 'getRandomTip' }).then(d => d.data);
	}
}

const resource = new Tip();

export default resource;

import { appointmentOutcomeTracker } from 'App/babel/helpers/Tracker';
import {
	prepDates,
	getUsersByTag,
	formatDateType,
	getAvailableFields,
	executeSave,
	parseDate,
	attachScopeFunctions
} from 'App/babel/helpers/multiModals';

const trackOutcome = outcome => {
	appointmentOutcomeTracker.track(appointmentOutcomeTracker.events.SET_OUTCOME, {
		location: 'modal',
		outcome
	});
};

angular.module('domain.appointment').controller('UpdateAppointmentMulti', [
	'$scope',
	'ActionProperties',
	'$multiSelect',
	'$modalParams',
	'AppService',
	function ($scope, ActionProperties, $multiSelect, $modalParams, AppService) {
		var arrayProperties = [];
		var currentAppointmentLength;

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isTrigger = $modalParams.isTrigger;

		$scope.$$postDigest(() => {
			if ($scope.properties.AppointmentType) {
				const existingAppointmentType = $scope.appointmentTypes.find(at => {
					return `${$scope.properties.AppointmentType}` === `${at.id}`;
				});
				if (!existingAppointmentType) {
					$scope.UpdateAppointment?.field_AppointmentType?.$setValidity?.('existingAppointmentType', false);
					$scope.UpdateAppointment?.field_AppointmentType?.$setDirty?.();
				}
			}
		});

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.save = () =>
			executeSave({
				scope: $scope,
				modalParams: $modalParams,
				multiSelect: $multiSelect,
				entityType: 'Appointment',
				method: 'updateAppointment',
				arrayProperties,
				onSuccess: properties => {
					const outcomeChanged = properties.find(prop => prop.name === 'Outcome');
					if (outcomeChanged) {
						trackOutcome(outcomeChanged.value);
					}
				}
			});

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.onTimeChange = function (timeIn) {
			var timeInMoment = moment($scope.properties[timeIn]);
			if ($scope.properties.StartTime > $scope.properties.EndTime && timeIn === 'StartTime') {
				$scope.properties.EndTime = timeInMoment.clone().add(currentAppointmentLength, 'minutes').toDate();
			}

			if ($scope.properties.StartTime < $scope.properties.EndTime && timeIn === 'StartTime') {
				$scope.properties.StartTime = timeInMoment.clone().toDate();
			}

			if ($scope.properties.StartTime > $scope.properties.EndTime && timeIn === 'EndTime') {
				$scope.properties.StartTime = timeInMoment
					.clone()
					.subtract(currentAppointmentLength, 'minutes')
					.toDate();
			}

			if ($scope.properties.StartTime < $scope.properties.EndTime && timeIn === 'EndTime') {
				$scope.properties.EndTime = timeInMoment.clone().toDate();
			}
		};

		$scope.onAppointmentTypeChange = () => {
			$scope.UpdateAppointment?.field_AppointmentType?.$setValidity?.('existingAppointmentType', true);
			$scope.UpdateAppointment?.field_AppointmentType?.$setPristine?.();
		};

		$scope.setDate = function (isEndDate) {
			if (!$scope.date.type || !$scope.date.type.tag) {
				return;
			}

			if (isEndDate) {
				$scope.properties.EndDate =
					'{{' + $scope.date.end.type.tag + ':' + ($scope.date.end.offset || 0) + '}}';
			} else {
				$scope.properties.StartDate = '{{' + $scope.date.type.tag + ':' + ($scope.date.offset || 0) + '}}';
			}
		};

		attachScopeFunctions($scope, ['createCampaign', 'addField', 'removeField', 'changeCustomDate']);

		var init = function () {
			const meta = $modalParams.meta;
			const startingFields = meta.appointmentCustomFields.data;
			const { dateTags, dateTypes, customDateTypes } = prepDates($modalParams.entity || $modalParams.tagEntity);

			currentAppointmentLength = 60;
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.properties = {};
			$scope.availableFields = getAvailableFields($scope, startingFields, customDateTypes);
			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customDateTypes = formatDateType(customDateTypes);

			if ($scope.isTrigger) {
				$scope.dateTypes = formatDateType(dateTypes);
				$scope.date = parseDate($modalParams.properties, $scope.dateTypes.data, 'Date');
				$scope.setDate();
			}

			// Users
			$scope.users = angular.copy(AppService.getActiveUsers());
			$scope.users.unshift(...getUsersByTag($modalParams.tagEntity));

			// parse properties
			ActionProperties.parse($scope.properties, $modalParams.properties, arrayProperties);

			// parse custom
			$scope.customFields = [];
			ActionProperties.parseCustomFields(
				$scope.availableFields,
				$scope.customFields,
				$scope.properties,
				'Appointment',
				dateTags
			);

			$scope.properties.User = AppService.getSelf().id;

			$scope.appointmentTypes = meta.appointmentTypes.data;
			$scope.appointmentOutcomes = [
				{ id: 'planned', name: Tools.$translate('appointment.outcome.planned') },
				{ id: 'completed', name: Tools.$translate('appointment.outcome.completed') },
				{ id: 'notCompleted', name: Tools.$translate('appointment.outcome.notCompleted') }
			];
		};

		init();
	}
]);

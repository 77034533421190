import { Text } from '@upsales/components';
import { t } from 'i18next';
import React from 'react';

const ModalHeader = ({ className, icon, title, close, appointmentId }) => {
	return (
		<div className={`up-modal-header ${className} ${appointmentId ? 'has-subtitle' : null}`}>
			<i className={`${icon} template-icon`} />
			<h2 className={appointmentId ? 'has-subtitle' : null}>
				{title}
				{appointmentId ? (
					<div className="subtitle subtitle-no-uppercase">
						<Text size="sm" color="grey-10">
							{appointmentId ? `${t('todo.drawerHeader.activityID')}: ${appointmentId}` : null}
						</Text>
					</div>
				) : null}
			</h2>
			<a className="btn btn-link modal-close" onClick={close}>
				<i className="fa fa-times" />
			</a>
		</div>
	);
};

export default ModalHeader;

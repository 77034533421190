import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, Text, Row, Column, Icon, Button, Link } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './AccountListBoardOfDirectorsCompanyCard.scss';
import Prospecting from 'App/babel/resources/Prospecting';
import { getDefaultIndustryFieldFromCountry } from 'App/services/prospectingService';

class AccountListBoardOfDirectorsCompanyCard extends React.Component {
	constructor(props) {
		super(props);
		this.roles = [];
		if (Array.isArray(this.props.company.directors)) {
			this.director = this.props.company.directors.find(
				director => director.prospectingId === this.props.prospectingId
			);
			this.roles = this.props.company.directors
				.filter(director => director.prospectingId === this.props.prospectingId)
				.map(director => director.roleName);
		} else {
			this.director = this.props.company.directors;
		}
		const t = Tools.$translate;
		this.lang = {
			add: t('add_to_upsales'),
			revenue: t('account.revenue'),
			employees: t('default.employees'),
			industry: t('account.industry'),
			alreadyInUpsales: t('account.alreadyInUpsales'),
			orgNumber: t('default.orgNumberShort')
		};
	}

	addCompany(prospectingId) {
		return Prospecting.save({ prospectingId }).then(createdAccount => {
			Tools.$state.go('account.dashboard', { id: createdAccount.data.id });
		});
	}

	goToExistingCompany = () => {
		Tools.$state.go('account.dashboard', { id: this.props.matchInUpsales.id });
	};

	render() {
		const classNames = new bemClass('AccountListBoardOfDirectorsCompanyCard');
		const t = Tools.$translate;

		const name = this.props.name;
		const lastChar = name.substr(name.length - 1);
		const roleLangTag = ['s', 'x', 'z'].includes(lastChar) ? 'account.assignmentNonGenitive' : 'account.assignment';
		const country = Prospecting.getCountryFromProspectingId(this.props.company.prospectingId);
		const industryField = getDefaultIndustryFieldFromCountry(country);
		const industryCode = this.props.company[industryField];
		let industryText = t('default.sniCode.00009');

		if (industryCode && industryCode !== '9' && industryCode !== '00009' && industryCode !== '99999') {
			const staticValue = Tools.AppService.getStaticValue(industryField, `${industryCode}`);
			if (staticValue && staticValue.name) {
				industryText = staticValue.name;
			}
		}

		return (
			<div className={classNames.b()}>
				<Card
					color={this.props.matchInUpsales ? 'grey-1' : 'white'}
					className={classNames.elem('companyCard').b()}
				>
					<div className={classNames.elem('innerCon').b()}>
						{this.props.matchInUpsales ? (
							<Link onClick={this.goToExistingCompany}>
								<Title bold>{this.props.company.name}</Title>
							</Link>
						) : (
							<Title bold>{this.props.company.name}</Title>
						)}
						<b className="flag-icon flag-icon-se"></b>
						<Text color="grey-11" className={classNames.elem('postTown').b()}>
							{this.props.company.postTown}
						</Text>
						{this.props.matchInUpsales ? (
							<div className={classNames.elem('topRight').b()}>
								<Icon name="check" />
								<Text color="grey-11">{this.lang.alreadyInUpsales}</Text>
							</div>
						) : (
							<Button
								className={classNames.elem('topRightButton').b()}
								onClick={() => this.addCompany(this.props.company.prospectingId)}
							>
								<Icon name="plus"></Icon>
								{this.lang.add}
							</Button>
						)}

						<Row className={classNames.elem('informationRow').b()}>
							<Column fixedWidth={190}>
								<Text className={classNames.elem('informationRow').elem('roles').b()}>
									{this.roles.join(', ')}
								</Text>
								<Text size="sm" color="grey-12">
									{t(roleLangTag, { name })}
								</Text>
							</Column>
							<Column>
								<Text>
									{this.props.company.noEmployees
										? Tools.$filter('numberFormat')(this.props.company.noEmployees)
										: 0}
								</Text>
								<Text size="sm" color="grey-12">
									{this.lang.employees}
								</Text>
							</Column>
							<Column>
								<Text>{Tools.$filter('currencyFormat')(this.props.company.revenue, 'SEK')}</Text>
								<Text size="sm" color="grey-12">
									{this.lang.revenue}
								</Text>
							</Column>
							<Column>
								<Text>{this.props.company.orgNumber}</Text>
								<Text size="sm" color="grey-12">
									{this.lang.orgNumber}
								</Text>
							</Column>
						</Row>
						<Row className={classNames.elem('industry').b()}>
							<Column>
								<Text>{industryText}</Text>
								<Text size="sm" color="grey-12">
									{this.lang.industry}
								</Text>
							</Column>
						</Row>
					</div>
				</Card>
			</div>
		);
	}
}

AccountListBoardOfDirectorsCompanyCard.propTypes = {
	company: PropTypes.object.isRequired,
	prospectingId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	matchInUpsales: PropTypes.object
};

export default AccountListBoardOfDirectorsCompanyCard;

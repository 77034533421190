import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Row, Column, Headline, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';

import getAngularModule from '../../angularHelpers/getAngularModule';
import { lostOpportunityCompanies, neverContactedCompanies } from './filters';
import './FindHiddenProspects.scss';
import { ListViewFilter } from 'App/resources/AllIWant';

type Props = {
	reject: () => object;
};

class FindHiddenProspects extends React.PureComponent<Props> {
	lang: { [key: string]: string };
	static propTypes = {
		reject: PropTypes.func.isRequired
	};

	static defaultProps = {
		reject: () => {}
	};

	constructor(props: Props) {
		super(props);

		const t = getAngularModule('$translate');

		this.lang = {
			headline: t('companies.findHidden'),
			description: t('companies.thereIsOftenALotOfPotential'),
			showMeAList: t('companies.showMeAList'),
			hadAnOpportunity: t('companies.hadAnOpportunity'),
			addedToUpsales: t('companies.addedToUpsales'),
			companiesWithOpportunities: t('companies.companiesWithOpportunities'),
			abort: t('default.abort')
		};
	}

	handleTypeClick = (filters: { [name: string]: ListViewFilter }) => {
		const $location = getAngularModule('$location');
		const FilterHelper = getAngularModule('FilterHelper');

		$location.search('q', FilterHelper.convertForURL(filters, 'account'));
		this.props.reject();
	};

	render() {
		const { reject } = this.props;
		const classes = new bemClass('FindHiddenProspects');

		return (
			<div className={classes.b()}>
				<Button className={classes.elem('close', 'exit-button').b()} onClick={reject} shadow="none">
					<Icon name="times" />
				</Button>
				<div className={classes.elem('inner', 'text-center').b()}>
					<Headline size="sm">{this.lang.headline}</Headline>

					<Text className={classes.elem('Description').b()} color="grey-10" size="xl">
						{this.lang.description}
					</Text>
					<Text size="lg" bold>
						{this.lang.showMeAList}
					</Text>

					<Row className={classes.elem('Types').b()}>
						<Column
							fixedWidth={200}
							className={classes.elem('Type').b()}
							onClick={this.handleTypeClick.bind(this, lostOpportunityCompanies)}
						>
							<Text size="lg" dangerouslySetInnerHTML={{ __html: this.lang.hadAnOpportunity }}></Text>
							<Text className="FindHiddenProspects__Type--visible-on-hover" color="grey-10" size="md">
								{this.lang.companiesWithOpportunities}
							</Text>
						</Column>
						<Column fixedWidth={20} />
						<Column
							fixedWidth={200}
							className={classes.elem('Type').b()}
							onClick={this.handleTypeClick.bind(
								this,
								neverContactedCompanies as { [name: string]: ListViewFilter }
							)}
						>
							<Text size="lg">{this.lang.addedToUpsales}</Text>
						</Column>
					</Row>

					<Button
						className={classes.elem('Cancel').b()}
						color="grey"
						type="link"
						block
						size="lg"
						onClick={reject}
					>
						{this.lang.abort}
					</Button>
				</div>
			</div>
		);
	}
}

export default FindHiddenProspects;

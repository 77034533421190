'use strict';
import PhoneInput from '../../../../babel/components/Inputs/PhoneInput';
//eslint-disable-next-line
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
angular.module('upDirectives').directive('upCellPhoneRoot', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr, controller) {
			let firstElement = $element[0];
			let elem = null;
			const normalizedFunc = (modelValue, viewValue) => {
				var value = modelValue || viewValue;
				if (!value || !window.phoneNumberHelper) {
					return true;
				}
				if ($scope.$eval($attr.isNew) || (value.length && value[0] === '+')) {
					return window.phoneNumberHelper.isPossibleNumber(elem ? elem.formatNumber(value) : value);
				} else {
					return phoneRegex.test(elem ? elem.formatNumber(value) : value);
				}
			};

			var renderTimeout;
			var render = function (cb) {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(function () {
					elem = ReactDOM.render(
						React.createElement(PhoneInput, {
							phone: $scope.$eval($attr.upCellPhoneRoot),
							countryCode: $scope.$eval($attr.countryCode),
							onChange: $scope.$eval($attr.onChange),
							name: $attr.name,
							iconClass: $attr.iconClass,
							required: $scope.$eval($attr.ngRequired),
							isNew: $scope.$eval($attr.isNew)
						}),
						firstElement,
						function () {
							if (cb) {
								cb();
							}
						}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			render(() => {
				controller.$validators.customValidationFunction = normalizedFunc;
			});
		}
	};
});

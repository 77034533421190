'use strict';

import { getLoggedInUserFilter } from 'App/upsales/common/attributes/filter/userFilter';

var userSelectDir = 'upUserSelect';
angular
	.module('upDirectives')
	.directive(userSelectDir, function (User, selectHelper, AppService) {
		return selectHelper.getDirectiveObject(userSelectDir, {
			resource: User,
			ajax: false,
			data: function ($attrs, $scope, options) {
				if (options.data) {
					return options.data;
				}
				var users = [];
				if ($attrs.userType !== undefined) {
					users = AppService.getUsers($attrs.userType);
				} else {
					users = AppService.getActiveUsers();
				}
				return users;
			},
			asIds: false,
			idAttr: function ($attrs, $scope, options) {
				return options.idAttr || User.attr.id;
			},
			titleAttr: User.attr.name,
			sorting: { field: User.attr.name, ascending: true },
			fields: [User.attr.email.field, User.attr.userCellPhone.field]
		});
	})
	.directive(userSelectDir + 'Ids', function (User, selectHelper, AppService) {
		return selectHelper.getDirectiveObjectIds(userSelectDir + 'Ids', {
			resource: User,
			ajax: false,
			data: function ($attrs, $scope, options) {
				if (options.data) {
					return options.data;
				}
				var users = [];
				if ($attrs.userType !== undefined) {
					users = AppService.getUsers($attrs.userType);
				} else {
					users = AppService.getActiveUsers();
				}
				return users;
			},
			asIds: true,
			idAttr: User.attr.id,
			titleAttr: User.attr.name,
			sorting: { field: User.attr.name, ascending: true }
		});
	})
	.directive('upUserRoleSelectIds', [
		'User',
		'selectHelper',
		'UserTreeFilterMeta',
		'AppService',
		'$translate',
		function (User, selectHelper, UserTreeFilterMeta, AppService) {
			var setIds = function (list) {
				list.forEach(function (obj) {
					if (obj.$id) {
						obj.id = 'role-' + obj.$id;

						if (obj.children) {
							setIds(obj.children);
						}
					}
				});
			};

			return selectHelper.getDirectiveObjectIds('upUserRoleSelectIds', {
				idAttr: {
					field: 'id'
				},
				formatSelection: function (obj, el, escape) {
					if (typeof obj.id === 'string' && obj.id !== 'self') {
						return '<b>' + escape(obj.name) + '</b>';
					}

					return escape(obj.name);
				},
				titleAttr: User.attr.name,
				asIds: true,
				ajax: false,
				sorting: { field: User.attr.name, ascending: true },
				getter: function (a1, a2, a3, scope, $attrs) {
					return UserTreeFilterMeta($attrs.userType).then(function (res) {
						var fixedData = {
							data: _.cloneDeep(res.data)
						};

						fixedData.data.unshift(getLoggedInUserFilter());

						setIds(fixedData.data);
						return fixedData;
					});
				},
				findInData: function (findObj, $attrs) {
					var users = AppService.getUsers($attrs.userType);
					var roles = _.cloneDeep(AppService.getRoles($attrs.userType));
					roles.forEach(function (role) {
						role.id = 'role-' + role.id;
					});

					if (findObj.id === 'self') {
						return getLoggedInUserFilter();
					}

					return typeof findObj.id === 'string'
						? _.find(roles, { id: findObj.id })
						: _.find(users, { id: findObj.id });
				}
			});
		}
	]);

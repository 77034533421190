ReactTemplates.admin.quotaInput = window.ReactCreateClass({
	maxValue: 1699605031, // eeeh... yes :)
	getDefaultProps: function () {
		return {
			allowNegativeValues: false
		};
	},
	getInitialState: function () {
		let value = this.props.value[this.props.field] * (this.props.currencyRate || 1);
		if (!this.props.noRound) {
			value = Math.round(value);
		}
		return {
			hasFocus: false,
			value: this.formatValue(value, this.props.quotaCurrency),
			realValue: value
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (
			nextProps.value[nextProps.field] !== this.state.realValue * (this.props.currencyRate || 1) ||
			this.props.quotaCurrency !== nextProps.quotaCurrency
		) {
			var realValue = nextProps.value[nextProps.field] * (nextProps.currencyRate || 1);
			if (!this.props.noRound) {
				realValue = Math.round(realValue);
			}

			this.setState({
				value: this.state.hasFocus ? realValue : this.formatValue(realValue, nextProps.quotaCurrency),
				realValue: realValue
			});
		}
	},
	formatValue: function (value, quotaCurrency) {
		if (quotaCurrency) {
			return Tools.$filter('currencyFormat')(value || 0, quotaCurrency);
		}
		return Tools.$filter('numberFormat')(value || 0);
	},
	onWheel: function (e) {
		e.preventDefault();
	},
	handleFocus: function (e) {
		e.target.addEventListener('wheel', this.onWheel, { passive: false });
		this.setState({
			value: this.state.realValue,
			hasFocus: true
		});
	},
	handleBlur: function (e) {
		e.target.removeEventListener('wheel', this.onWheel, { passive: false });
		if (!this.state.hasFocus) {
			return;
		}
		var value = this.state.value || 0;
		if (!this.props.allowNegativeValues && value < 0) {
			value = 0;
		}
		var formattedValue = this.formatValue(value, this.props.quotaCurrency);

		this.setState(
			{
				hasFocus: false,
				realValue: value,
				value: formattedValue
			},
			() => {
				var quota = _.cloneDeep(this.props.value);
				quota[this.props.field] = value / (this.props.currencyRate || 1);
				if (quota[this.props.field] !== this.props.value[this.props.field] / (this.props.currencyRate || 1)) {
					this.props.onChange(quota);
				}
			}
		);
	},
	onKeyDown: function (e) {
		if (e.key === 'Enter' && this.refs.input) {
			this.refs.input.blur();
		} else if (e.key === 'Escape') {
			this.setState(
				{
					value: this.props.value[this.props.field]
				},
				function () {
					if (this.refs.input) {
						this.refs.input.blur();
					}
				}
			);
		}
	},
	onChange: function (e) {
		var value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null;

		// limit to max value
		var maxValue = this.props.max || this.maxValue;
		if (value > maxValue) {
			return;
		}

		this.setState({
			value: value
		});
	},
	buttonAction: function () {
		var value = this.state.value || 0;
		if (!this.props.allowNegativeValues && value < 0) {
			value = 0;
		}
		var formattedValue = this.formatValue(value, this.props.quotaCurrency);
		var convertedValue = value / (this.props.currencyRate || 1);

		this.setState(
			{
				hasFocus: false,
				realValue: convertedValue,
				value: formattedValue
			},
			function () {
				this.props.buttonAction(this.props.buttonValue, convertedValue);
			}
		);
	},
	render: function () {
		var className = 'inline-edit';
		if (this.props.showSubtitle) {
			className += ' two-lines';
		}
		if (this.props.disabled || this.props.isSaving) {
			className += ' disabled';
		}
		var tooltip = Tools.$translate('admin.applyForRemainingMonths', {
			VALUE: this.formatValue(this.state.value, this.props.quotaCurrency)
		});
		var value = this.state.value !== undefined ? this.state.value : '';

		return (
			<div className={className}>
				<input
					type={this.state.hasFocus ? 'number' : 'text'}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					onKeyDown={this.onKeyDown}
					onChange={this.onChange}
					value={value}
					ref="input"
					max={this.maxValue}
					min={this.props.allowNegativeValues ? undefined : 0}
					disabled={this.props.disabled || this.props.isSaving}
				/>
				{this.state.hasFocus &&
					ReactTemplates.TOOLS.withTooltip(
						<button
							tabIndex={-1}
							onMouseDown={this.buttonAction}
							className="btn btn-sm btn-link btn-bright-blue apply-next"
						>
							<b className="fa fa-arrow-circle-right" />
						</button>,
						tooltip,
						{ placement: 'bottom' }
					)}
				{!this.state.hasFocus && this.props.subtitle !== '' && (
					<span className="subtitle">{this.props.subtitle}</span>
				)}
			</div>
		);
	}
});

import React, { useEffect, useState } from 'react';
import './AppLoader.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import Lottie from 'react-lottie';
import { useAnimationData } from '../hooks';

type Props = {
	visible: boolean;
};

export default ({ visible }: Props) => {
	const { loading, animationData } = useAnimationData(() => import('./AppLoaderAnimation.json'));
	// Wait a moment before hiding to let the fadeout play
	const [delayedVisible, setDelayedVisible] = useState(true);
	useEffect(() => {
		if (!visible) {
			setTimeout(() => setDelayedVisible(false), 300);
		} else {
			setDelayedVisible(true);
		}
	}, [visible]);

	const classes = new BemClass('AppLoader').mod({ visible });
	return (
		<div className={classes.b()}>
			{!loading && delayedVisible ? (
				<Lottie
					options={{
						loop: true,
						animationData
					}}
					width={130}
					isClickToPauseDisabled
				/>
			) : null}
		</div>
	);
};

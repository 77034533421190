import React from 'react';
import { Column, Text, Row } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';

type Props = {
	item: {
		name: string;
		command: string;
		altCommands: string[];
	};
};

const SupersearchResultRowCommand = ({ item, ...props }: Props) => {
	return (
		<SupersearchResultRow {...props} isCommand={true}>
			<Column>
				<Row>
					<Text>{item.name}</Text>
				</Row>
				<Row>
					<Text color="grey-10" size="sm" italic>
						{item.command}
					</Text>
				</Row>
			</Column>
			{item.altCommands.length ? (
				<Column>
					<Row>
						<Text>{Tools.$translate('navbar.quickSearch.quickNav.alternativeCommands')}</Text>
					</Row>
					<Row>
						<Text size="sm" italic>
							{item.altCommands.toString()}
						</Text>
					</Row>
				</Column>
			) : null}
		</SupersearchResultRow>
	);
};

export default SupersearchResultRowCommand;

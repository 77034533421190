import { binaryGroup } from 'App/helpers/accountsHelper';
import async from 'async';

const formats = (type, clientId, utils, person, jqObj, escape) => {
	return escape(utils.select2[type](person, clientId, jqObj));
};

const getContactByClient = (clientIds, utils) => {
	return new Promise((resolve, reject) => {
		if (!clientIds) {
			return resolve([]);
		}

		let result = [];
		let hasAllItems = false;

		async.whilst(
			() => !hasAllItems,
			cb => {
				var fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title', 'optins', 'journeyStep'];
				utils.req
					.clientContactsAndRelations(clientIds, fields, result.length)
					.then(function (res) {
						result = result.concat(res.data);
						hasAllItems = res.metadata.total <= result.length;

						return cb(null);
					})
					.catch(e => console.log('clientContactsAndRelations error', e));
			},
			err => {
				if (err) return reject(err);

				return resolve(result);
			}
		);
	});
};

export default (clientId, users, utils, operationalAccountId) => {
	return new Promise(resolve => {
		const OBJECT = {
			data: [],
			id: p => p.$id,
			formatSelection: (person, jqObj, escape) => {
				return formats('formatParticipantSelection', clientId, utils, person, jqObj, escape);
			},
			formatResult: (person, jqObj, query, escape) => {
				return formats('formatParticipantResult', clientId, utils, person, jqObj, escape);
			},
			matcher: (term, text, item) => {
				return item.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
			}
		};

		const clientIds = [];
		if (clientId) {
			clientIds.push(clientId);
		}
		if (operationalAccountId) {
			clientIds.push(operationalAccountId);
		}

		getContactByClient(clientIds, utils)
			.then(data => {
				const predicate = contact =>
					operationalAccountId ? contact.client.id === operationalAccountId : false;
				const [mainContacts, contacts] = binaryGroup(data, predicate);

				OBJECT.data = [
					{ name: Tools.$translate('default.contacts'), children: contacts },
					{ name: Tools.$translate('default.users'), children: users },
					{
						name: Tools.$translate('appointmentModal.participantSelect.otherContacts'),
						children: []
					}
				];

				if (operationalAccountId && mainContacts.length) {
					const label = `${Tools.$translate('default.fromMainAccount')} (${mainContacts[0].client.name})`;
					OBJECT.data.splice(1, 0, { name: label, children: mainContacts });
				}

				return resolve({ contacts: data, object: OBJECT });
			})
			.catch(e => console.log('getContactByClient error', e));
	});
};

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Card,
	Row,
	Column,
	Icon,
	Text,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn,
	Tooltip
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import {
	getJourneyStep,
	statusDisqualifiable,
	statusQualifiable,
	SQL,
	DISQUALIFIED
} from 'Components/Helpers/journeyStep';
import t from 'Components/Helpers/translate';

import './JourneyHistoryDropDown.scss';

const baseClass = 'JourneyHistoryDropDown';

const formatHistoryDate = (date, isCurrent) =>
	isCurrent ? (
		<div>
			{date ? moment(date).format('L') : null}
			{' • '}
			<span>{t('default.current')}</span>
		</div>
	) : date ? (
		moment(date).format('L')
	) : null;

class JourneyHistoryDropDown extends PureComponent {
	renderActionButton(setExpanded, { text, color, icon, journeyStep }, i) {
		const { disableActions } = this.props;

		const classes = new bemClass(baseClass);
		return (
			<Row key={journeyStep + i} className={classes.elem('action-row').b()}>
				<Column fixedWidth={36} className={classes.elem('action-col-ball').b()}>
					<div className={classes.elem('ball').mod({ white: true }).b()} />
				</Column>
				<Column className={classes.elem('action-col-button').b()}>
					<Tooltip title={this.props.tooltipArray[i]} position="right">
						<Button
							color={color}
							disabled={disableActions}
							block
							onClick={() => this.props.onSetJourneyStep(journeyStep).then(setExpanded)}
						>
							<Icon name={icon} />
							<Text color="white" className={classes.elem('action-text').b()}>
								{t(text)}
							</Text>
						</Button>
					</Tooltip>
				</Column>
			</Row>
		);
	}

	renderActions(setExpanded) {
		const { journeyStep } = this.props;

		const disqualifiable = statusDisqualifiable(journeyStep);
		const qualifiable = statusQualifiable(journeyStep);
		if (!disqualifiable && !qualifiable) {
			return null;
		}

		const buttonArr = [];
		if (qualifiable) {
			const sql = getJourneyStep(SQL);
			buttonArr.push({
				text: 'default.salesQualify',
				icon: 'thumbs-up',
				journeyStep: sql.value
			});
		}
		if (disqualifiable) {
			const disqualified = getJourneyStep(DISQUALIFIED);
			buttonArr.push({
				text: 'default.disqualify',
				icon: 'thumbs-down',
				journeyStep: disqualified.value
			});
		}
		const classes = new bemClass(baseClass);
		return (
			<Fragment>
				{buttonArr.map(this.renderActionButton.bind(this, setExpanded))}
				<div className={classes.elem('action-line').b()} />
			</Fragment>
		);
	}

	renderHistory() {
		const { historyArr } = this.props;
		const classes = new bemClass(baseClass);
		return (
			<div className={classes.elem('table-wrapper').b()}>
				<Table>
					{historyArr.map(({ value, date }, i, { length }) => {
						const status = getJourneyStep(value);
						return (
							<TableRow key={'history-' + value + i} className={classes.elem('history-row').b()}>
								<TableColumn size="lg" className={classes.elem('history-col-ball').b()}>
									<div className={classes.elem('ball').b()} />
									<div
										className={classes
											.elem('line')
											.mod({ solid: true, hidden: length - 1 === i })
											.b()}
									/>
								</TableColumn>
								<TableColumn
									size="lg"
									title={t(status.name)}
									subtitle={formatHistoryDate(date, i === 0)}
								/>
							</TableRow>
						);
					})}
				</Table>
			</div>
		);
	}

	render() {
		const { children, historyArr } = this.props;
		const classes = new bemClass(baseClass);
		return (
			<DropDownMenu
				align="left"
				verticalAlign="bottom"
				className={classes.elem('dropdown').b()}
				renderTrigger={children}
			>
				{setExpanded => (
					<Card>
						{this.renderActions(setExpanded)}
						{historyArr.length ? (
							<Fragment>
								<Text className={classes.elem('history-header')} bold>
									{t('default.history')}
								</Text>
								{this.renderHistory()}
							</Fragment>
						) : null}
					</Card>
				)}
			</DropDownMenu>
		);
	}
}

JourneyHistoryDropDown.defaultProps = {
	historyArr: []
};

JourneyHistoryDropDown.propTypes = {
	journeyStep: PropTypes.string,
	historyArr: PropTypes.array,
	onSetJourneyStep: PropTypes.func,
	disableActions: PropTypes.bool,
	tooltipArray: PropTypes.array
};

export default JourneyHistoryDropDown;

import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { debounce } from 'lodash';
import SideBar from './SideBar';
import Content from './Content';
import Footer from './Footer';
import { SIDEBAR_WIDTH } from './shared';
import PropTypes from 'prop-types';

import './PeriodizationList.scss';

const classes = new BEM('PeriodizationList');

const getStartAndEndDates = opportunities => {
	const result = {
		startDate: null,
		endDate: null
	};

	for (const opportunity of opportunities) {
		if (opportunity.periodization) {
			const orderStart = moment(opportunity.periodization.startDate);
			const orderEnd = moment(opportunity.periodization.endDate);

			if (!result.startDate || orderStart.isBefore(result.startDate)) {
				result.startDate = orderStart;
			}
			if (!result.endDate || orderEnd.isAfter(result.endDate)) {
				result.endDate = orderEnd;
			}
		}
	}

	if (!result.startDate && !result.endDate) {
		result.startDate = moment().startOf('year');
		result.endDate = moment().endOf('year');
	} else if (!result.startDate) {
		result.startDate = moment(result.endDate).subtract(1, 'year');
	} else if (!result.endDate) {
		result.endDate = moment(result.startDate).add(1, 'year');
	}

	result.startDate.startOf('month');
	result.endDate.startOf('month');

	return result;
};

class PeriodizationList extends React.Component {
	static propTypes = {
		listView: PropTypes.shape({
			changePage: PropTypes.func.isRequired,
			entries: PropTypes.array.isRequired,
			limit: PropTypes.number.isRequired,
			total: PropTypes.number,
			page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
		}),
		listViewHelper: PropTypes.shape({
			monthStats: PropTypes.object
		})
	};

	state = {};

	constructor(props) {
		super(props);
		this.updateActiveYear = debounce(this.updateActiveYear, 200);
	}

	static getDerivedStateFromProps(props, state) {
		const {
			listView: { entries: opportunities = [] }
		} = props;

		const newState = getStartAndEndDates(opportunities);

		if (state.opportunityArrayReference !== opportunities) {
			newState.opportunityArrayReference = opportunities;
			newState.activeYear = newState.startDate.year();
		} else if (!(newState.startDate.year() <= state.activeYear && newState.endDate.year() >= state.activeYear)) {
			newState.activeYear = newState.startDate.year();
		}

		return newState;
	}

	updateActiveYear = periodizationList => {
		const spyElements = document.querySelectorAll('.PeriodizationList__scrollspy');
		const scrollLeft = periodizationList.scrollLeft;
		const threshold = SIDEBAR_WIDTH + scrollLeft;

		for (const element of spyElements.values()) {
			const left = element.offsetLeft;
			const right = element.offsetLeft + element.offsetWidth;
			if (left <= threshold && right > threshold) {
				const matches = /PeriodizationList__scrollspy-(\d{4})/.exec(element.id);

				if (matches && matches.length === 2) {
					const year = parseInt(matches[1]);
					if (!isNaN(year)) {
						this.setState({ activeYear: year });
					}
				}

				break;
			}
		}
	};

	onScroll = event => {
		if (event.target) {
			this.updateActiveYear(event.target);
		}
	};

	render() {
		const {
			listView: { changePage, entries: opportunities = [], limit = 50, total = 0, page = 1 },
			listViewHelper: { monthStats = {}, periodizationField }
		} = this.props;
		const { startDate, endDate, activeYear } = this.state;
		const offset = (parseInt(page) - 1) * limit;

		return (
			<div className={classes.b()} onScroll={this.onScroll}>
				<SideBar
					activeYear={activeYear}
					opportunities={opportunities}
					startDate={startDate}
					endDate={endDate}
					periodizationField={periodizationField}
				/>
				<Content
					activeYear={activeYear}
					opportunities={opportunities}
					startDate={startDate}
					endDate={endDate}
					periodizationField={periodizationField}
				/>
				<Footer
					startDate={startDate}
					endDate={endDate}
					limit={limit}
					offset={offset}
					total={total}
					changePage={changePage}
					monthStats={monthStats}
					periodizationField={periodizationField}
				/>
			</div>
		);
	}
}

export default PeriodizationList;

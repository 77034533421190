import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column } from '@upsales/components';

import t from 'Components/Helpers/translate';
import StepDrawer from '../StepDrawer';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerInfoSnooze from '../Components/DrawerInfoSnooze';
import StepContactTable from '../Components/StepContactTable';
import { getSnoozeText } from '../helpers';

export default function Snooze(props) {
	const stats = props.popup.step.stats;
	return (
		<StepDrawer
			{...props}
			title={t('flow.snooze')}
			subtitle={getSnoozeText('flow.waitFor', props.popup.step)}
			color="black"
			icon="snooze"
		>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<Row>
					<Column>
						<DrawerInfoTitle total={stats.currentlyInStep} title={'flow.contactsHereNow'} />
					</Column>
					<Column>
						<DrawerInfoTitle total={stats.hasCompletedStepUnique} title={'flow.contactsPassedThisStep'} />
					</Column>
				</Row>
				<DrawerInfoSnooze step={props.popup.step} />
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={stats}
				types={['currentlyInStep', 'fallenOutOfStep']}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

Snooze.propTypes = {
	flow: PropTypes.object.isRequired,
	popup: PropTypes.object.isRequired
};

import AdminHeader from 'Components/Admin/AdminHeader';
import { NumberInput, Button, Loader, Block, Flex, Label } from '@upsales/components';
import CountrySelect from 'App/components/Inputs/Selects/CountrySelect';
import { countries } from 'App/helpers/regionHelpers';
import T from 'Components/Helpers/translate';

ReactTemplates.admin.mailSettings = window.ReactCreateClass({
	getStateFromProps: function (props) {
		return {
			settings: props.rootData.pageData.settings,
			domains: props.rootData.pageData.domains,
			addingDomain: props.rootData.pageData.addingDomain,
			editDomain: null,
			the_domain: null
		};
	},

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState(this.getStateFromProps(nextProps));
	},

	onNewField: function () {
		this.setState({
			addingDomain: true
		});
	},

	onSettingsChange: function (field, e) {
		var settings = this.state.settings;

		if (field === 'minEventDelayTime') {
			settings[field] = Math.min(Math.max(e, 0), 60);
		} else if (e) {
			if (field === 'country') {
				settings[field] = e.title;
			} else if (e.target) {
				settings[field] = e.target.value;
			} else if (e.code && e.id) {
				settings[field] = e.translated;
			} else if (field === 'limitEmailCount' || field === 'limitWeeks' || field === 'emailWarningCount') {
				settings[field] = e;
			}
		} else {
			settings[field] = null;
		}

		this.props.rootData.onChange(settings);

		this.setState({
			settings: settings
		});
	},

	componentDidMount: function () {
		this.props.rootData.getDomains();
	},

	UNSAFE_componentWillMount: function () {
		var t = T;

		this.hasHalon = Tools.FeatureHelper.hasSoftDeployAccess('MAIL_PROVIDER_HALON');
		this.showMinEventDelayTime = Tools.FeatureHelper.hasSoftDeployAccess('MAIL_ACCOUNT_MIN_EVENT_DELAY_TIME');

		this.lang = {
			fromName: t('mail.fromName'),
			mailsettings: {
				title: t('default.mailsettings'),
				desc: t('mail.adminIntro')
			},
			limitTitle: t('mailSettings.limit.title'),
			limitInputMax: t('mailSettings.limit.input.max'),
			limitInputEmails: t('mailSettings.limit.input.emails'),
			limitInputWeeks: t('mailSettings.limit.input.weeks'),
			limitInfo: t('mailSettings.limit.info'),
			minutes: t('minutes'),
			warningCountTitle: t('mailSettings.warningCount.title'),
			warningCountContactsEmail: t('mailSettings.warningCount.contactsEmail'),
			warningCountInfo: t('mailSettings.warningCount.info'),
			minEventDelayTimeTitle: T('mailSettings.minEventDelayTime.title'),
			minDelay: t('mailSettings.limit.input.minDelay'),
			sender: {
				title: t('mail.canSpam'),
				desc: t('mail.canSpamInfo'),
				input: {
					emailLabel: t('mail.presetEmail'),
					emailPlaceholder: 'exempel@foretaget.se',
					userLabel: t('mail.presetSender'),
					userPlaceholder: 'Test Testsson'
				},
				disclaimer: t('mail.disclaimer'),
				disclaimerInfo: t('mail.disclaimerInfo')
			},
			points: {
				title: t('mail.pointsTitle'),
				desc: t('mail.pointsInfo')
			},
			standard: {
				title: t('mail.defaultSender'),
				desc: t('mail.defaultSenderInfo')
			},
			name: t('default.name'),
			phone: t('default.phone'),
			address: t('address.address'),
			zipcode: t('form.Client.zipcode'),
			city: t('form.Client.city'),
			country: t('form.Client.country'),
			openedMail: t('mail.opened') + ' ' + t('mail.mail').toLowerCase(),
			clickedMail: t('advancedSearch.click') + ' ' + t('mail.mail').toLowerCase(),
			unsubscribed: t('admin.mail.unSubscribed'),
			acceptTerms: {
				termsInfo1: t('mail.termsInfo1'),
				termsInfo2: t('mail.termsInfo2'),
				termsInfo3: t('mail.termsInfo3'),
				termsInfo4: t('mail.termsInfo4'),
				termsInfo5: t('mail.termsInfo5'),
				termsInfo6: t('mail.termsInfo6'),
				termsInfo6Halon: t('mail.termsInfo6Halon'),
				termsInfo7: t('mail.termsInfo7'),
				termsInfo8: t('mail.termsInfo8'),
				termsInfo9: t('mail.termsInfo9'),
				termsInfo10: t('mail.termsInfo10'),
				termsInfo11: t('mail.termsInfo11'),
				termsInfo12: t('mail.termsInfo12'),
				termsAccept: t('mail.termsAccept')
			},
			activate: t('default.activate')
		};
	},

	verify: function (domain) {
		this.setState({ editDomain: domain.domain });
	},

	closeVerify: function () {
		this.setState({ editDomain: null });
	},

	updateNewDomain: function (e) {
		if (!e) return;
		this.setState({ the_domain: e.target.value });
	},

	addNewDomain: function () {
		this.props.rootData.addDomain({ domain: this.state.the_domain });

		var state = _.cloneDeep(this.state);
		state.domains.push({ domain: this.state.the_domain, valid: false });

		this.setState(state);
	},

	cancelNewDomain: function () {
		this.setState({ addingDomain: false, the_domain: null });
	},
	getSetupView: function () {
		return (
			<div id="admin-page-email-settings">
				<div id="accept-term-body">
					<div className="content">
						<h3>{this.lang.acceptTerms.termsInfo1}</h3>
						<p>{this.lang.acceptTerms.termsInfo2}</p>
						<p>{this.lang.acceptTerms.termsInfo3}</p>
						<br />
						<div>
							<h4>{this.lang.acceptTerms.termsInfo4}</h4>
							<p>
								<b>{this.lang.acceptTerms.termsInfo5}</b>
							</p>
							<p>
								{this.hasHalon
									? this.lang.acceptTerms.termsInfo6Halon
									: this.lang.acceptTerms.termsInfo6}
							</p>
							{this.hasHalon ? null : <p>{this.lang.acceptTerms.termsInfo7}</p>}
							<p>
								<b>{this.lang.acceptTerms.termsInfo8}</b>
							</p>
							<p>{this.lang.acceptTerms.termsInfo9}</p>
							<p>{this.lang.acceptTerms.termsInfo10}</p>
							<p>{this.lang.acceptTerms.termsInfo11}</p>
							<p>{this.lang.acceptTerms.termsInfo12}</p>
						</div>
						<button
							className="btn btn-block btn-bright-blue no-shadow up-btn"
							onClick={this.props.rootData.acceptTerms}
						>
							{this.lang.acceptTerms.termsAccept}
						</button>
					</div>
				</div>
			</div>
		);
	},
	fieldHasErrors: function (field) {
		return this.props.rootData.pageData.errorFields.indexOf(field) !== -1;
	},
	render: function () {
		var self = this;
		var rootData = self.props.rootData;
		if (!self.state.settings.acceptedTermsDate) {
			return self.getSetupView();
		}
		var domainSection = null;
		var activateBtn = null;

		var isAdmin = Tools.AppService.getSelf().administrator;
		var isMaAdmin = isAdmin || Tools.AppService.getSelf().userParams.mailAdmin;

		if (self.state.settings.id && !isMaAdmin) {
			domainSection = React.createElement(ReactTemplates.admin.domainTable, {
				domains: self.state.domains,
				addDomain: rootData.addDomain,
				translate: T
			});
		}

		if (!self.state.settings.id && isMaAdmin) {
			activateBtn = (
				<Button
					block={true}
					loading={rootData.saving}
					color="bright-blue"
					shadow="none"
					className="btn activate-btn"
					onClick={rootData.onSave}
				>
					{self.lang.activate}
				</Button>
			);
		}

		var selectedCountry = countries.find(function (c) {
			c.translated = T(c.lang);
			return c.translated === self.state.settings.country;
		});

		var errorMsg = null;

		if (rootData.pageData.errorFields.length) {
			errorMsg = (
				<div className="error-msg">
					<p>{'You have some fields that are required to fill in. Please fill them and save again.'}</p>
				</div>
			);
		}

		return (
			<div id="admin-page-email-settings">
				<AdminHeader
					title={self.lang.mailsettings.title}
					description={this.lang.mailsettings.desc}
					image="mail-settings.svg"
					articleId={471}
				/>

				<div id="admin-content">
					{errorMsg}
					{domainSection}

					<div className="card">
						<h1>{self.lang.fromName}</h1>
						<div className="admin-section">
							<h2>{self.lang.standard.title}</h2>

							<div className="section-content">
								<div className="row">
									<div className="col-md-8">
										<div className="row">
											<div
												className={
													self.fieldHasErrors('defaultEmail')
														? 'col-md-6 has-error'
														: 'col-md-6'
												}
											>
												<label>
													{self.lang.sender.input.emailLabel}
													<span className="required-field">{'*'}</span>
												</label>
												<input
													onChange={self.onSettingsChange.bind(self, 'defaultEmail')}
													defaultValue={this.state.settings.defaultEmail}
													type="text"
													className="form-control"
													placeholder={self.lang.sender.input.emailPlaceholder}
												/>
											</div>
											<div
												className={
													self.fieldHasErrors('defaultFrom')
														? 'col-md-6 has-error'
														: 'col-md-6'
												}
											>
												<label>
													{self.lang.sender.input.userLabel}
													<span className="required-field">{'*'}</span>
												</label>
												<input
													onChange={self.onSettingsChange.bind(self, 'defaultFrom')}
													defaultValue={this.state.settings.defaultFrom}
													type="text"
													className="form-control"
													placeholder={self.lang.sender.input.userPlaceholder}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="right-block-quote">
											<p className="text-upsales-grey">{self.lang.standard.desc}</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="admin-section">
							<div className="row">
								<h2 className="col-md-12">{self.lang.sender.title}</h2>
								<div className="col-md-8">
									<div className="section-content">
										<div className="row">
											<div
												className={
													self.fieldHasErrors('name') ? 'col-md-12 has-error' : 'col-md-12'
												}
											>
												<label>
													{self.lang.name}
													<span className="required-field">{'*'}</span>
												</label>
												<input
													value={this.state.settings.name}
													onChange={self.onSettingsChange.bind(self, 'name')}
													className="form-control"
												/>
											</div>
											<div className="col-md-6">
												<label>{self.lang.address}</label>
												<input
													value={this.state.settings.address}
													onChange={self.onSettingsChange.bind(self, 'address')}
													className="form-control"
												/>
											</div>
											<div className="col-md-2" style={{ width: '21%' }}>
												<label>{self.lang.zipcode}</label>
												<input
													value={this.state.settings.zipCode}
													onChange={self.onSettingsChange.bind(self, 'zipCode')}
													className="form-control"
												/>
											</div>
											<div className="col-md-4" style={{ width: '29%' }}>
												<label>{self.lang.city}</label>
												<input
													value={this.state.settings.city}
													onChange={self.onSettingsChange.bind(self, 'city')}
													className="form-control"
												/>
											</div>
											<div className="col-md-6">
												<label>{self.lang.country}</label>
												<CountrySelect
													value={selectedCountry?.id || null}
													anchor="#admin-content"
													onChange={self.onSettingsChange.bind(self, 'country')}
												/>
											</div>
											<div className="col-md-6">
												<label>{self.lang.phone}</label>
												<input
													value={this.state.settings.phone}
													onChange={self.onSettingsChange.bind(self, 'phone')}
													className="form-control"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="right-block-quote">
										<p className="text-upsales-grey">{self.lang.sender.desc}</p>
									</div>
								</div>
							</div>
							<div className="row" style={{ marginTop: '30px' }}>
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-12">
											<label style={{ margin: '0' }}>{self.lang.sender.disclaimer}</label>
											<textarea
												onChange={self.onSettingsChange.bind(self, 'disclaimer')}
												className="form-control"
												rows="5"
												defaultValue={this.state.settings.disclaimer}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="right-block-quote">
										<p className="text-upsales-grey">{self.lang.sender.disclaimerInfo}</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="admin-section">
							<div className="row">
								<h1 className="col-md-12">{self.lang.limitTitle}</h1>
								<div className="col-md-8">
									<div className="section-content">
										<div className="row emails-limit">
											<h2>{self.lang.limitInputMax}</h2>
											<NumberInput
												onChange={self.onSettingsChange.bind(self, 'limitEmailCount')}
												min={1}
												max={1000}
												align="center"
												value={this.props.rootData.pageData.settings.limitEmailCount}
											/>
											<h2>{self.lang.limitInputEmails}</h2>
											<NumberInput
												onChange={self.onSettingsChange.bind(self, 'limitWeeks')}
												min={1}
												max={12}
												align="center"
												value={this.props.rootData.pageData.settings.limitWeeks}
											/>
											<h2>{self.lang.limitInputWeeks}</h2>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="right-block-quote">
										<p className="text-upsales-grey">{self.lang.limitInfo}</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="admin-section">
							<div className="row">
								<h1 className="col-md-12">{self.lang.points.title}</h1>
								<div className="col-md-8">
									<div className="section-content">
										<div className="row">
											<div className="col-md-4">
												<label>{self.lang.openedMail}</label>
												<input
													className="form-control"
													onChange={self.onSettingsChange.bind(self, 'mailOpenScore')}
													type="number"
													defaultValue={self.state.settings.mailOpenScore}
												/>
											</div>
											<div className="col-md-4">
												<label>{self.lang.clickedMail}</label>
												<input
													className="form-control"
													onChange={self.onSettingsChange.bind(self, 'mailClickScore')}
													type="number"
													defaultValue={self.state.settings.mailClickScore}
												/>
											</div>
											<div className="col-md-4">
												<label>{self.lang.unsubscribed}</label>
												<input
													className="form-control"
													onChange={self.onSettingsChange.bind(self, 'mailUnsubscribeScore')}
													type="number"
													defaultValue={self.state.settings.mailUnsubscribeScore}
												/>
											</div>
										</div>
									</div>
									{this.showMinEventDelayTime ? (
										<Block space="mtxl" className="section-content">
											<Block space="mbl">
												<Label size="md" bold={true}>
													{self.lang.minEventDelayTimeTitle}
												</Label>
											</Block>
											<Flex align="center">
												<div className="row emails-limit">
													<h2>{self.lang.minDelay}</h2>
													<NumberInput
														onChange={self.onSettingsChange.bind(self, 'minEventDelayTime')}
														min={0}
														max={60}
														align="center"
														value={
															this.props.rootData.pageData.settings.minEventDelayTime ?? 0
														}
													/>
													<h2>{self.lang.minutes}</h2>
												</div>
											</Flex>
										</Block>
									) : null}
								</div>
								<div className="col-md-4">
									<div className="right-block-quote">
										<p className="text-upsales-grey">{self.lang.points.desc}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="admin-section">
							<div className="row">
								<h1 className="col-md-12">{self.lang.warningCountTitle}</h1>
								<div className="col-md-8">
									<div className="section-content">
										<div className="row emails-limit">
											<h2>{self.lang.limitInputMax}</h2>
											<div className="section-row">
												<NumberInput
													className="input-form-control"
													onChange={self.onSettingsChange.bind(self, 'emailWarningCount')}
													min={1}
													max={1000000}
													align="center"
													value={this.props.rootData.pageData.settings.emailWarningCount}
												/>
											</div>
											<h2>{self.lang.warningCountContactsEmail}</h2>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="right-block-quote">
										<p className="text-upsales-grey">{self.lang.warningCountInfo}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					{activateBtn}
				</div>
			</div>
		);
	}
});

ReactTemplates.admin.domainTable = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			domains: []
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = this.props.translate;

		this.lang = {
			domain: t('mail.domain'),
			save: t('default.save'),
			cancel: t('default.abort'),
			domains: {
				title: t('mail.domainValidation'),
				desc: t('mail.domainValidationInfo')
			},
			domainTableTitle: t('mail.domains'),
			addField: t('mail.verify.new.mail.domain'),
			verify: t('mail.validateDomain')
		};
	},

	getStateFromProps: function (props) {
		return {
			domains: props.domains,
			editDomain: '',
			theDomain: '',
			addingDomain: false
		};
	},

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState(this.getStateFromProps(nextProps));
	},

	updateNewDomain: function (e) {
		this.setState({ theDomain: e.target.value });
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	componentDidUpdate: function (prevProps, prevState) {
		if (this.state.addingDomain && !prevState.addingDomain) {
			var input = this.newDomainInput;
			input.focus();
		}
	},

	addNewDomain: function () {
		this.props.addDomain({ domain: this.state.theDomain });

		var state = _.cloneDeep(this.state);

		state.theDomain = '';
		state.editDomain = '';
		state.addingDomain = false;

		this.setState(state);
	},
	cancelNewDomain: function () {
		this.setState({ addingDomain: false, theDomain: '' });
	},

	verify: function (domain) {
		this.setState({ editDomain: domain.domain });
	},
	closeVerify: function () {
		this.setState({ editDomain: '' });
	},
	onNewField: function () {
		this.setState({
			addingDomain: true
		});
	},

	render: function () {
		var self = this;
		var addElement = null;
		if (this.state.addingDomain) {
			addElement = (
				<tr style={{ height: '52px' }}>
					<td width="5px"></td>
					<td>
						<div
							className={
								this.state.theDomain && this.state.theDomain.length > 0
									? 'floating-label-input has-value'
									: 'floating-label-input'
							}
						>
							<label>{this.lang.domain}</label>
							<input
								type="text"
								ref={this.setRef.bind(this, 'newDomainInput')}
								onChange={this.updateNewDomain}
							/>
						</div>
					</td>
					<td className="edit_buttons" style={{ paddingRight: '20px' }}>
						<button
							onClick={this.addNewDomain}
							disabled={this.state.theDomain.length < 1 ? true : false}
							className="up-btn btn-bright-blue no-shadow"
						>
							{this.lang.save}
						</button>
						<button onClick={self.cancelNewDomain} className="btn up-btn btn-grey btn-link no-shadow">
							{this.lang.cancel}
						</button>
					</td>
				</tr>
			);
		}
		var domainHeader = null;
		if (self.state.domains && self.state.domains.length) {
			domainHeader = (
				<thead>
					<tr>
						<th style={{ width: '5px' }}></th>
						<th>{this.lang.domain}</th>
						<th style={{ width: '180px' }}>
							{this.lang.verify} {this.lang.domain.toLowerCase()}
						</th>
					</tr>
				</thead>
			);
		} else {
			domainHeader = (
				<thead>
					<tr>
						<th colSpan="3" style={{ textAlign: 'center' }}>
							<Loader
								noU={true}
								style={{
									height: '50px',
									width: '50px',
									position: 'absolute',
									top: '30px',
									transform: 'translateX(-50%)'
								}}
							/>
						</th>
					</tr>
				</thead>
			);
		}
		var domainList = _.map(self.state.domains, function (domain) {
			return React.createElement(ReactTemplates.admin.domainRow, {
				domain: domain,
				translate: self.props.translate,
				verify: self.verify,
				closeVerify: self.closeVerify,
				editDomain: domain.domain === self.state.editDomain
			});
		});

		return (
			<div className="admin-section">
				<h2>{this.lang.domains.title}</h2>
				<p className="text-upsales-grey">{this.lang.domains.desc}</p>

				<div className="section-content admin-table">
					<div className="admin-table-top">
						<span className="admin-table-title">{this.lang.domainTableTitle}</span>
						<div className="pull-right">
							<button onClick={this.onNewField} className="btn up-btn btn-bright-blue no-shadow">
								{this.lang.addField}
							</button>
						</div>
					</div>
					<table>
						{domainHeader}
						<tbody>
							{addElement}
							{domainList}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
});

ReactTemplates.admin.domainRow = window.ReactCreateClass({
	getDefaultProps: function () {
		return {};
	},

	getInitialState: function () {
		return {};
	},

	verify: function (domain) {
		this.props.verify(domain);
	},

	closeVerify: function () {
		this.props.closeVerify();
	},

	UNSAFE_componentWillMount: function () {
		var t = this.props.translate;

		this.lang = {
			notVerified: t('mail.notValidatedDomain'),
			verify: t('mail.validateDomain'),
			verifyTitle: t('mail.verifyInfoSplit1'),
			verifyDesc: t('mail.verifyInfoSplit2'),
			verified: t('mail.validatedDomain'),
			dns: {
				type: t('mail.dnsType'),
				host: t('mail.dnsHost'),
				data: t('mail.dnsData')
			}
		};
	},

	render: function () {
		var domain = this.props.domain;
		if (this.props.editDomain) {
			var authElement = (
				<div>
					<tr className="row">
						<td className="cname col-md-2">{domain.dns ? domain.dns.mail_cname.type : ''}</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.mail_cname.host : ''}
								type="text"
								className="form-control"
							/>
						</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.mail_cname.data : ''}
								type="text"
								className="form-control"
							/>
						</td>
					</tr>
					<tr className="row">
						<td className="cname col-md-2">{domain.dns ? domain.dns.dkim1.type : ''}</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.dkim1.host : ''}
								type="text"
								className="form-control"
							/>
						</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.dkim1.data : ''}
								type="text"
								className="form-control"
							/>
						</td>
					</tr>
					<tr className="row">
						<td className="cname col-md-2">{domain.dns ? domain.dns.dkim2.type : ''}</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.dkim2.host : ''}
								type="text"
								className="form-control"
							/>
						</td>
						<td className="col-md-5">
							<input
								defaultValue={domain.dns ? domain.dns.dkim2.data : ''}
								type="text"
								className="form-control"
							/>
						</td>
					</tr>
				</div>
			);

			return (
				<tr className="editing">
					<td colSpan={3}>
						<tr>
							<td width="10px" className={domain.valid ? 'valid' : 'not-valid'}></td>
							<td className="two-lines">
								{domain.domain}
								{domain.valid ? (
									<span className="verified-text">{this.lang.verified}</span>
								) : (
									<span className="verified-text">{this.lang.notVerified}</span>
								)}
							</td>
							<td style={{ width: '180px', paddingRight: '20px' }}>
								{!domain.valid ? (
									<button
										onClick={this.verify.bind(this, domain)}
										className="up-btn btn-block btn-bright-blue no-shadow"
									>
										{this.lang.verify}
									</button>
								) : (
									''
								)}
							</td>
						</tr>
						<tr>
							<td colSpan={3}>
								<div className="inside-holder">
									<i onClick={this.closeVerify} className="close-button fa fa-times fa-lg" />

									<h2>{this.lang.verifyTitle}</h2>
									<p>{this.lang.verifyDesc}</p>

									<table className="dns">
										<tr className="row">
											<td className="col-md-2">{this.lang.dns.type}</td>
											<td className="col-md-5">{this.lang.dns.host}</td>
											<td className="col-md-5">{this.lang.dns.data}</td>
										</tr>
										{authElement}
									</table>
								</div>
							</td>
						</tr>
					</td>
				</tr>
			);
		}

		return (
			<tr style={{ height: '52px' }}>
				<td width="10px" className={domain.valid ? 'valid' : 'not-valid'}></td>
				<td className="two-lines">
					{domain.domain}
					{domain.valid ? (
						<span className="verified-text">{this.lang.verified}</span>
					) : (
						<span className="verified-text">{this.lang.notVerified}</span>
					)}
				</td>
				<td style={{ width: '180px', paddingRight: '20px' }}>
					{!domain.valid ? (
						<Button
							loading={domain.adding}
							onClick={this.verify.bind(this, domain)}
							block={true}
							color="bright-blue"
							shadow="none"
						>
							{this.lang.verify}
						</Button>
					) : (
						''
					)}
				</td>
			</tr>
		);
	}
});

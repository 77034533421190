import React from 'react';
import { Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './AssignButton.scss';

type AssignButtonProps = React.ComponentProps<typeof Button> & {
	className?: string | BemClass;
};

const AssignButton = ({ className, ...props }: AssignButtonProps) => {
	const classNames = new BemClass('AssignButton', className);
	return <Button {...props} className={classNames.b()} />;
};

export default AssignButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboardNotify } from 'Services/copyToClipboard';
import BemClass from '@upsales/components/Utils/bemClass';
import './EventActivity.scss';
import { Link, Input, Icon, Tooltip } from '@upsales/components';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

export default class EventContacts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value ?? '',
			contactId: this.props.contact?.id,
			openEdit: false
		};

		const t = Tools.$translate;
		this.lang = {
			noPhone: t('default.noPhone'),
			noEmail: t('default.noEmail').toLowerCase(),
			edit: t('default.edit'),
			copy: t('default.copy')
		};
	}

	componentDidUpdate() {
		if (this.state.contactId !== this.props.contact?.id) {
			this.setState({ contactId: this.props.contact?.id, value: this.props.value ?? '' });
		}
	}

	renderIcons() {
		let icon;
		if (this.props.type === 'cellphone') {
			icon = <b className={this.props.value ? 'icon fa fa-mobile iconBlue' : 'icon fa fa-mobile'}></b>;
		} else if (this.props.type === 'phone') {
			icon = <b className={this.props.value ? 'icon fa fa-phone iconBlue' : 'icon fa fa-phone'}></b>;
		} else if (this.props.type === 'email') {
			icon = (
				<span>
					{' '}
					<b className={this.props.value ? 'icon fa fa-envelope-o iconBlue' : 'icon fa fa-envelope-o'}></b>
				</span>
			);
		}
		return icon;
	}

	renderEmail(str, user) {
		const MAIL_ACTIVATED = Tools.AppService.getMetadata().map.mailActivated;
		if (!MAIL_ACTIVATED) {
			return <a href={`mailto:${str}`}>{str}</a>;
		} else {
			return (
				<Link
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
							openNewMailWithContact(user);
						} else {
							Tools.$upModal.open('sendEmail', {
								customerId: Tools.AppService.getCustomerId(),
								contactId: user.id,
								contact: user
							});
						}
					}}
				>
					{str}
				</Link>
			);
		}
	}

	copy = (e, value) => {
		e.stopPropagation();
		copyToClipboardNotify(value);
	};

	openEdit = e => {
		e.stopPropagation();
		this.setState({ openEdit: !this.state.openEdit });
	};

	changeContact = value => {
		this.setState({ value: value });
	};

	saveContact = () => {
		if (this.props.value !== this.state.value) {
			this.props.saveContacts(this.props.type, this.state.value);
		}
		this.setState({ openEdit: false });
	};

	renderPhone(number, contact) {
		const phoneProps = ReactTemplates.TOOLS.upVoice(contact, 'contact', number);
		if (phoneProps) {
			return (
				<a className="Link" href={phoneProps.href} target={phoneProps.target} onClick={phoneProps.onClick}>
					{number}
				</a>
			);
		}
	}

	render() {
		const classes = new BemClass('ActivityWrapper');
		return (
			<React.Fragment>
				{this.state.openEdit ? (
					<div className={classes.elem('contactInput')}>
						{this.renderIcons()}
						<Input
							value={this.state.value}
							size="sm"
							className="inputValue"
							onChange={e => this.changeContact(e.target.value)}
							onBlur={this.saveContact}
							onKeyPress={event => {
								if (event.key === 'Enter') {
									this.saveContact();
								}
							}}
							autoFocus
						/>
					</div>
				) : (
					<div test-id="contactItem" className={classes.elem('contactItem')} onClick={e => this.openEdit(e)}>
						{this.renderIcons()}
						{this.props.type === 'email' ? (
							<React.Fragment>
								{this.props.value ? (
									this.renderEmail(this.props.value, this.props.contact)
								) : (
									<span className={classes.elem('subText')}>
										{' '}
										<i> {this.lang.noEmail} </i>{' '}
									</span>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								{this.props.value ? (
									this.renderPhone(this.props.value, this.props.contact)
								) : (
									<span className={classes.elem('subText')}>
										{' '}
										<i> {this.lang.noPhone} </i>
									</span>
								)}
							</React.Fragment>
						)}

						<div className={classes.elem('tools')}>
							<div className="contactTools">
								<Tooltip title={this.lang.edit} distance={20}>
									<Icon name="edit" className="fa fa-edit" onClick={e => this.openEdit(e)} />
								</Tooltip>
							</div>

							{this.props.value && (
								<div className="contactTools">
									<Tooltip title={this.lang.copy} distance={20}>
										<Icon name="copy" onClick={e => this.copy(e, this.props.value)} />
									</Tooltip>
								</div>
							)}
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

EventContacts.propTypes = {
	type: PropTypes.string,
	value: PropTypes.string,
	contact: PropTypes.object,
	saveContacts: PropTypes.func
};

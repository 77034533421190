'use strict';

angular.module('upAttributes').service('NotificationAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date
				},
				userIds: {
					field: 'userIds.userId',
					type: FilterType.Number,
					displayType: DisplayType.String,
					parent: 'users',
					attr: {
						userId: {
							field: 'userIds.userId',
							type: FilterType.Number
						},
						read: {
							field: 'userIds.read',
							type: FilterType.Date
						}
					}
				}
			};

			return {
				attr: attributes,
				getAll: function () {
					return attributes;
				}
			};
		};
	}
]);

import ReduxRootComponent from 'Components/ReduxComponentRoot';

angular.module('upDirectives').directive('upLandingpageRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			let firstElement = $element[0];
			var renderTimeout;
			var rootData;

			var render = function () {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(function () {
					if (!firstElement) {
						return;
					}

					ReactDOM.render(
						<ReduxRootComponent rootData={rootData} component={ReactTemplates.form.editLandingpageRoot} />,
						firstElement,
						function () {
							if (rootData?.scrollToForm) {
								rootData.scrollToForm = false;
							}
						}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$watch(
				$attr.upLandingpageRoot,
				function (data) {
					rootData = data;
					render();
				},
				true
			);

			render();
		}
	};
});

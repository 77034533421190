import React, { useState } from 'react';
import { Headline, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './RelateTo.scss';
import RelationSelect, { OnChangeValue } from 'App/components/RelationSelect';
import { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';
import { Speed } from '../animations/Animate/Animate';
import T from 'Components/Helpers/translate';

export interface RelateToProps {
	className: string;
	entityLabel?: string;
	client: { id: number; name: string };
	user: { id: number };
	availableEntities?: AvailableRelationConfig;
	visible?: boolean;
	onChange: (value?: { [key: string]: number | null | undefined }) => void;
	headline?: string;
	title?: string;
}

const RelateTo: React.FC<RelateToProps> = ({
	client,
	entityLabel = '',
	user,
	availableEntities,
	visible = true,
	onChange = () => {},
	className,
	headline,
	title
}) => {
	const classes = new BemClass('RelateTo', className);
	const [appointmentId, setAppointmentId] = useState<number | null | undefined>(undefined);
	const [opportunityId, setOpportunityId] = useState<number | null | undefined>(undefined);
	const [activityId, setActivityId] = useState<number | null | undefined>(undefined);

	const lang = {
		title: title ? title : T('relateTo.title'),
		headline: headline ? headline : T('relateTo.headline', { entity: T(entityLabel).toLowerCase() })
	};

	const onRelationSelected = (v: OnChangeValue) => {
		setAppointmentId(v.appointmentId);
		setOpportunityId(v.opportunityId);
		setActivityId(v.activityId);
		onChange(v);
	};

	return client ? (
		<div className={classes.b()}>
			<Title size="md" color="grey-11">
				{lang.title}
			</Title>
			<Headline>{lang.headline}</Headline>
			{visible ? (
				<RelationSelect
					userId={user?.id}
					onChange={onRelationSelected}
					onClose={() => onChange()}
					client={client}
					appointmentId={appointmentId}
					opportunityId={opportunityId}
					activityId={activityId}
					availableEntities={availableEntities}
					multiple={false}
					autofocus={visible}
					autofocusDelay={Speed.slow}
					disableOutsideClick={true}
				></RelationSelect>
			) : null}
		</div>
	) : null;
};

export default RelateTo;

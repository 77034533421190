'use strict';

angular.module('upResources').factory('LookerLook', [
	'$resource',
	'$q',
	'URL',
	'API',
	function Looker($resource, $q, URL, API) {
		var baseUrl = URL + API + 'looker/looks/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				list: {
					method: 'GET',
					isArray: false
				}
			}
		);

		var Model = {
			list: function () {
				return Resource.get({}).$promise;
			}
		};

		return Model;
	}
]);

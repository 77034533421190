'use strict';

angular.module('upResources').factory('ActivityList', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ActivityAttributes',
	'ParseGeneric',
	function ActivityList($resource, $q, URL, API, ActivityAttributes, ParseGeneric) {
		var AA = ActivityAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var dateFields = ['date', 'indexDate', 'modDate', 'regDate'];

		var parser = function (res) {
			try {
				res = JSON.parse(res);
			} catch (e) {
				return res;
			}

			if (res.data) {
				res.data = _.map(res.data, function (item) {
					if (item.activityType) {
						if (item.isAppointment) {
							return ParseGeneric(null, {
								isArray: false,
								custom: 'appointemtn'
							})({ data: item }, null).data;
						} else {
							return ParseGeneric(null, {
								isArray: false,
								custom: 'activity',
								dateFields: dateFields
							})({ data: item }, null).data;
						}
					}

					return item;
				});
			}

			return res;
		};

		var Resource = $resource(
			URL + API + 'search/activitylist/:includes',
			{},
			{
				find: {
					method: 'GET',
					skipDateConvert: true,
					transformResponse: parser
				}
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				if (options && options.mapCustom) {
					delete options.mapCustom;
				}
				var params = _.extend(filter, options || {});

				return Resource.find(params).$promise;
			},

			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

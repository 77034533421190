import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Row, Column, Text, Avatar } from '@upsales/components';
import T from 'Components/Helpers/translate';
import SupersearchResultCustomfields from './SupersearchResultCustomfields';
import './SupersearchResultRow.scss';

type Props = {
	className?: string;
	children?: React.ReactNode;
	users?: {
		id: number;
		name: string;
		email: string;
	}[];
	customValues?: any[];
	customFields?: any[];
	inactive?: boolean;
	hideUsers?: boolean;
	alwaysShowCustomfields?: boolean;
	searchStr?: string;
	selected?: boolean;
	isCommand?: boolean;
};
const SupersearchResultRow = ({
	className,
	children,
	users = [],
	customValues = [],
	customFields = [],
	inactive = false,
	hideUsers = false,
	alwaysShowCustomfields = false,
	searchStr,
	selected,
	isCommand = false,
	...props
}: Props) => {
	const classes = new bemClass('SupersearchResultRow', className).mod({ inactive, selected });

	return (
		<div className={classes.b()} {...props}>
			<Row>
				{children}
				{!hideUsers ? (
					<Column className={classes.elem('user-col').b()}>
						{users.length ? (
							<Text size="sm">
								<div className={classes.elem('avatar-wrap').b()}>
									<Avatar size="sm" initials={users[0].name} email={users[0].email} />
								</div>
								<div className={classes.elem('user-names-wrap').b()}>{users[0].name}</div>
								<div className={classes.elem('more-users-wrap').b()}>
									{users.length > 1 ? ` +${users.length - 1}` : null}
								</div>
							</Text>
						) : !isCommand ? (
							<Text size="sm" color="grey-10" italic={true}>
								{T('default.noUser')}
							</Text>
						) : null}
					</Column>
				) : null}
			</Row>
			{!isCommand ? (
				<SupersearchResultCustomfields
					alwaysShow={alwaysShowCustomfields}
					customFields={customFields}
					searchStr={searchStr}
					values={customValues}
				/>
			) : null}
		</div>
	);
};

export default SupersearchResultRow;

import logError from 'App/babel/helpers/logError';

angular.module('domain.soliditet').controller('ConfirmSoliditetBuyParent', [
	'$scope',
	'$state',
	'$modalParams',
	'ActionProperties',
	'SoliditetClient',
	'Account',
	'AppService',
	function ($scope, $state, $modalParams, ActionProperties, SoliditetClient, Account, AppService) {
		var ConfirmSoliditetBuyParent = this;

		var customerId;

		ConfirmSoliditetBuyParent.properties = [];
		ConfirmSoliditetBuyParent.buying = false;
		ConfirmSoliditetBuyParent.saving = false;

		ConfirmSoliditetBuyParent.matches = $modalParams.meta.matches.data;

		ConfirmSoliditetBuyParent.useExisting = function () {
			ConfirmSoliditetBuyParent.saving = true;
			// Set duns on existing account
			var data = { id: ConfirmSoliditetBuyParent.matches[0].id, dunsNo: $modalParams.duns };
			Account.customer(customerId)
				.save(data)
				.then(function () {
					// Resolve with the matched account
					$scope.resolve(ConfirmSoliditetBuyParent.matches[0]);
				})
				.catch(e => {
					logError(e, 'Failed saving account');
				});
		};

		ConfirmSoliditetBuyParent.confirm = function () {
			ConfirmSoliditetBuyParent.buying = true;

			ConfirmSoliditetBuyParent.options = {
				updateExisting: false,
				skipProjects: false,
				skipAccountManagers: false,
				skipAddresses: false,
				skipCategories: false
			};

			ActionProperties.mapCustomFields(
				ConfirmSoliditetBuyParent.properties,
				ConfirmSoliditetBuyParent.customFields,
				'Client'
			);

			SoliditetClient.customer(customerId)
				.buy($modalParams.duns, ConfirmSoliditetBuyParent.properties, ConfirmSoliditetBuyParent.options)
				.then(function (res) {
					// Go to account
					setTimeout(function () {
						$scope.resolve(res.data);
					}, 2000);
				})
				.catch(function () {
					ConfirmSoliditetBuyParent.buying = false;
				});
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			ConfirmSoliditetBuyParent.metadata = AppService.getMetadata();

			var customFields = AppService.getCustomFields('account');
			ConfirmSoliditetBuyParent.customFields = _.filter(customFields, function (value) {
				return value.obligatoryField && value.editable && value.alias !== 'ORG_NO';
			});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

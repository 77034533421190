import { Loader } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';
import Page from '../Page';

import './LoaderPage.scss';

export default function LoaderPage() {
	const classes = new bemClass('LoaderPage');
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowLoader(true);
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<Page transparent={true} className={classes.b()}>
			{showLoader ? <Loader className={classes.elem('loader').b()} /> : null}
		</Page>
	);
}

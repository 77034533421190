'use strict';

angular.module('domain.admin').controller('EditSalesboards', [
	'$scope',
	'meta',
	'$stateParams',
	'ListViewService',
	'$upModal',
	'$state',
	function ($scope, meta, $stateParams, ListViewService, $upModal, $state) {
		var EditSalesboards = this;
		var customerId = $stateParams.customerId;

		$scope.listviews = meta.listViews;
		$scope.currentView = meta.listViews[0];

		$scope.board = EditSalesboards.board;

		$scope.salesboards = _.where(meta.listViews, { private: false, editable: true });

		$scope.editSalesboard = function (salesboard) {
			var id = salesboard ? salesboard.id : 'new';
			$state.go('admin.salesboard', { customerId: customerId, id: id });
		};

		$scope.deleteSalesboard = function (salesboard) {
			// create confirmation dialog
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.create({
					templateUrl: require('App/upsales/common/components/ui/modals/templates/removeViewConfirm.tpl.html?file'),
					style: 'warning'
				})
				.then(function () {
					// remove view from list
					_.pull($scope.salesboards, salesboard);

					// remove from server
					ListViewService['delete']('salesboard', salesboard, customerId);
				});
		};

		EditSalesboards.customerId = $stateParams.customerId;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Title } from '@upsales/components';
import './CriteriaPlaceholder.scss';

const CriteriaPlaceholder = ({ text }) => (
	<div className="CriteriaPlaceholder">
		<Icon name="arrow-down" color="grey-10" space="mrl" />
		<Title italic color="grey-10">
			{text}
		</Title>
	</div>
);

CriteriaPlaceholder.propTypes = {
	text: PropTypes.string.isRequired
};

export default CriteriaPlaceholder;

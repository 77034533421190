import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalHeader, ModalContent, ModalControls, Button } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import { init, saveIntegrationConfig } from 'App/babel/store/reducers/ChooseMailIntegrationReducer';
import IntegrationHelper from 'App/helpers/IntegrationHelper';
import { configChanged, saveConfig, isConfigValid } from 'App/babel/store/reducers/ConfigIntegration';

const mapStateToProps = state => ({
	config: state.ConfigIntegration.config,
	savingConfig: state.ConfigIntegration.savingConfig,
	fields: state.ConfigIntegration.fields
});

const mapDispatchToProps = {
	init,
	saveIntegrationConfig,
	configChanged,
	saveConfig,
	isConfigValid
};

export class ConfigureIntegration extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			abort: translate('default.abort'),
			save: translate('default.save')
		};
	}

	render() {
		const {
			reject,
			resolve,
			config,
			fields,
			savingConfig,
			configChanged,
			saveIntegrationConfig,
			isConfigValid
		} = this.props;
		const classes = new Bem('ChooseMailIntegration');
		const saveDisabled = !isConfigValid();

		return (
			<div className={classes.mod('configure-integration').b()}>
				<ModalHeader className="ModalHeader--no-border-bottom" onClose={this.props.reject} />
				<ModalContent className="IntegrationConfig">
					<ReactTemplates.admin.standardIntegrations.fields
						getIntegrationLang={IntegrationHelper.getIntegrationLang.bind(null, '')}
						config={config}
						onChange={configChanged}
						fields={fields}
						showHero={true}
						onBtnClick={function () {}}
					/>
				</ModalContent>
				<ModalControls>
					<Button
						color="bright-blue"
						onClick={() => saveIntegrationConfig(resolve)}
						disabled={saveDisabled}
						loading={savingConfig}
					>
						{this.lang.save}
					</Button>
					<Button color="grey" type="link" onClick={() => reject()}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

ConfigureIntegration.propTypes = {
	config: PropTypes.object.isRequired,
	fields: PropTypes.arrayOf(PropTypes.object).isRequired,
	savingConfig: PropTypes.bool.isRequired,
	configChanged: PropTypes.func.isRequired,
	saveConfig: PropTypes.func.isRequired,
	isConfigValid: PropTypes.func.isRequired,
	reject: PropTypes.func.isRequired,
	saveIntegrationConfig: PropTypes.func.isRequired,
	resolve: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureIntegration);

'use strict';

var staticValuesSelectDir = 'upStaticValues';
angular
	.module('upDirectives')
	.directive(staticValuesSelectDir + 'Ids', function (AppService, StaticValues, selectHelper, $q) {
		return selectHelper.getDirectiveObjectIds(staticValuesSelectDir + 'Ids', {
			ajax: true,
			asIds: true,
			idAttr: StaticValues.attr.id,
			titleAttr: StaticValues.attr.name,
			select2: { minimumInputLength: 0 },
			getter: function (customerId, filter, term, scope, $attrs) {
				var type = $attrs.staticType;
				var idFilter = filter && _.find(filter.q, { a: 'id' });
				var data = AppService.getStaticValues(type);
				var filteredData = data.filter(function (option) {
					if (idFilter && idFilter.v.indexOf(option.id) === -1) {
						return false;
					}

					return !term || option.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
				});
				return $q.when({ data: filteredData, metadata: { total: Infinity } });
			}
		});
	});

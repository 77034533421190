import './multipleOptionComponent.scss';
import { Flex } from '@upsales/components';

import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@upsales/components';
class multipleOptionComponent extends React.Component {
	render() {
		const { option, className, onSelect } = this.props;

		if (option.loading) {
			return (
				<div
					style={{
						height: '30px',
						lineHeight: '30px',
						fontSize: '14px',
						padding: '0 10px 5px',
						textAlign: 'center'
					}}
				>
					<Loader noU={true} style={{ width: '25px', height: '25px' }} />
				</div>
			);
		}

		if (option.isButtonGroup) {
			return option.render;
		}

		if (option.value) {
			return (
				<div
					key={`selectable-option-${option.value}`}
					className={className || 'multiple-option-selectable-option'}
					onMouseDown={e => {
						onSelect(option);
						e.stopPropagation();
					}}
				>
					<Flex direction="column">
						<div>{option.label}</div>
						{option.subtitle ? <div className="multiple-option-subtitle">{option.subtitle}</div> : null}
					</Flex>
				</div>
			);
		}

		return (
			<div
				onMouseDown={e => e.stopPropagation()}
				className={className}
				key={option.label}
				style={{
					backgroundColor: '#EDF1F5',
					padding: '0 10px',
					height: '25px',
					color: '#4B5562',
					fontSize: '11px',
					lineHeight: '25px',
					textTransform: 'uppercase'
				}}
			>
				<Flex direction="column">
					<div>{option.label}</div>
					{option.subtitle ? <div className="multiple-option-subtitle">{option.subtitle}</div> : null}
				</Flex>
			</div>
		);
	}
}

multipleOptionComponent.propTypes = {
	option: PropTypes.object,
	className: PropTypes.string,
	optionIndex: PropTypes.number,
	onSelect: PropTypes.func
};
export default multipleOptionComponent;

angular.module('domain.form').controller('FormSubmit', [
	'$scope',
	'$state',
	'RequestBuilder',
	'FormSubmit',
	function ($scope, $state, RequestBuilder, FormSubmit) {
		var FormCtrl = $scope.FormCtrl;
		var customerId = FormCtrl.customerId;

		var getSubmits = function () {
			var filters = RequestBuilder();
			filters.addFilter({ field: 'formId' }, filters.comparisonTypes.Equals, FormCtrl.form.id);
			FormSubmit.customer(customerId)
				.find(filters.build())
				.then(function (response) {
					$scope.submits = response.data;
				});
		};

		var init = function () {
			getSubmits();
		};

		init();
	}
]);

const Fields = () => {
	const res = {
		selectedFields: [],
		obligatoryFields: [
			{ name: 'column.name', field: 'name', parent: 'contact' },
			{ name: 'default.title', field: 'title', parent: 'contact' },
			{ name: 'default.phone', field: 'phone', parent: 'contact' },
			{ name: 'default.cellPhone', field: 'cellPhone', parent: 'contact' },
			{ name: 'default.email', field: 'email', parent: 'contact' },
			{ name: 'default.notes', field: 'notes', parent: 'contact' },
			{ name: 'admin.anonymization.customFields', field: 'custom', parent: 'contact' }
		]
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS')) {
		res.obligatoryFields.push({ name: 'default.titlecategory', field: 'titleCategory', parent: 'contact' });
	}

	return res;
};

export default Fields;

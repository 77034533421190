import { Text, Tooltip } from '@upsales/components';
import React from 'react';
import './RatingLabel.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import _ from 'lodash';
import T from 'Components/Helpers/translate';

const getRatingType = (ratingField: string) => {
	const rating = ratingField.split(' - ')[0];

	if (['AAA', 'AA', 'A'].includes(rating)) {
		return 'success';
	} else if (rating === 'B') {
		return 'warning';
	} else if (rating === 'C') {
		return 'danger';
	} else {
		return 'standard';
	}
};

const getRatingTranslation = (ratingField: string) => {
	let rating = ratingField.split(' - ')[0];
	if (rating === 'Nystartat företag') {
		rating = 'NewCompany';
	} else if (rating === 'Ej fastställd' || rating === 'Ej fastställd rating') {
		rating = 'Unknown';
	}
	let fullText = T('admin.customfieldCreditRating' + rating);
	if (fullText === 'admin.customfieldCreditRating' + rating) {
		fullText = ratingField;
	}
	return fullText;
};

const getRatingLabel = function (account: Client) {
	let ratingField;

	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && account.creditRating) {
		const values = Tools.AppService.getStaticValues('creditRating');
		ratingField = (_.find(values, { id: account.creditRating }) ?? _.find(values, { id: 'unknown' }))?.name;
	} else if (account.soliditet?.financialData?.rating) {
		ratingField = getRatingTranslation(account.soliditet.financialData.rating);
	} else {
		ratingField = _.find(account.custom, { alias: 'RATING' })?.value;
	}

	return ratingField
		? { full: ratingField, short: ratingField.split(' - ')[0], type: getRatingType(ratingField) }
		: null;
};

const RatingLabel = ({ client }: { client: Client }) => {
	const ratingLabel = getRatingLabel(client);
	if (!ratingLabel) {
		return null;
	}

	const classes = new BemClass('RatingLabel');
	return (
		<Tooltip
			distance={20}
			position="right"
			title={ratingLabel.full}
			className={classes
				.mod({
					success: ratingLabel.type === 'success',
					danger: ratingLabel.type === 'danger',
					warning: ratingLabel.type === 'warning'
				})
				.b()}
		>
			<Text color="white" bold size="sm">
				{ratingLabel.short}
			</Text>
		</Tooltip>
	);
};

export { RatingLabel, getRatingTranslation };

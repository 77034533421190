import { Text } from '@upsales/components';
import './EditActivityFooter.scss';

ReactTemplates.editActivity.footer = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			createDocument: t('default.createDocument'),
			save: t('default.save'),
			removeActivity: t('default.removeActivity'),
			closeActivity: t('default.closeActivity'),
			followupActivity: t('activity.followupActivity'),
			missingEditAccess: t('noEditRights.activity'),
			followupAppointment: t('activity.followupAppointment')
		};
	},
	setDropdowns: function () {
		var docDrop = this._documentDropdownToggle;
		if (docDrop) {
			jQuery(docDrop).dropdown();
		}
	},
	componentDidMount: function () {
		this.setDropdowns();
	},
	componentDidUpdate: function () {
		this.setDropdowns();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	remove: function () {},
	close: function () {},
	save: function (closeActivity) {
		this.props.onSave(closeActivity);
	},
	createDocument: function (doc) {
		this.props.onCreateDocument(doc);
	},
	createFollowup: function (isAppointment) {
		this.props.onCreateFollowup(isAppointment);
	},
	render: function () {
		var self = this;
		var activity = self.props.activity;
		var disabled = self.props.disabled;
		var isFlash = self.props.isFlash;
		var documentTemplates = self.props.documentTemplates;
		var closing = self.props.closing;
		var removeBtn = null;
		var documentsDropup = null;
		var saveBtn = null;
		var followupBtns = null;
		var closeBtn = null;
		var formInvalid = self.props.formInvalid;

		if (activity.id && activity.userRemovable && activity.userEditable && !isFlash) {
			removeBtn = ReactTemplates.TOOLS.withTooltip(
				<div className="btn-group pull-left">
					<button
						onClick={self.remove}
						style={{ marginRight: '10px' }}
						type="button"
						className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left"
						disabled={disabled}
					>
						<i className="fa fa-trash"></i>
					</button>
				</div>,
				self.lang.removeActivity,
				{ placement: 'top' }
			);
		}

		if (activity.id && documentTemplates && documentTemplates.length && self.props.isAvailable.document) {
			var templates = _.map(documentTemplates, function (doc, i) {
				return (
					<li key={'doc-template-' + i}>
						<div className="dropdown-option" onClick={self.createDocument.bind(self, doc)}>{doc.name}</div>
					</li>
				);
			});
			documentsDropup = (
				<div className="dropdown dropup btn-group pull-left">
					<button
						type="button"
						className="btn up-btn btn-bright-blue no-shadow btn-lined dropdown-toggle"
						disabled={disabled || formInvalid}
						ref={self.setRef.bind(self, '_documentDropdownToggle')}
						data-toggle="dropdown"
					>
						<b className="fa fa-file-o"></b> {self.lang.createDocument} <b className="fa fa-caret-up"></b>
					</button>

					<ul className="dropdown-menu up-dropdown-white limited">{templates}</ul>
				</div>
			);
		}

		if (!activity.closeDate && !isFlash) {
			saveBtn = (
				<button type="submit" className="btn up-btn btn-bright-blue no-shadow" disabled={disabled}>
					{self.lang.save}
				</button>
			);
		}

		if (!isFlash) {
			closeBtn = (
				<button type="button" className="btn up-btn btn-grey btn-link" onClick={self.close}>
					{self.lang.abort}
				</button>
			);
		}

		if (activity.userEditable) {
			followupBtns = (
				<div className="btn-group">
					{!activity.closeDate ? (
						<button
							type="button"
							className="btn up-btn btn-bright-blue no-shadow btn-lined"
							onClick={self.save.bind(self, true)}
							disabled={disabled || formInvalid}
						>
							{closing ? <b className="fa fa-spin fa-refresh"></b> : <b className="fa fa-check"></b>}
							<span> {self.lang.closeActivity}</span>
						</button>
					) : null}

					<button
						type="button"
						className="btn up-btn btn-bright-blue no-shadow btn-lined"
						onClick={self.createFollowup.bind(self, false)}
						disabled={disabled || formInvalid}
					>
						<b className="fa fa-plus"></b> {self.lang.followupActivity}
					</button>

					<button
						type="button"
						className="btn up-btn btn-bright-blue no-shadow btn-lined"
						onClick={self.createFollowup.bind(self, true)}
						disabled={disabled || formInvalid}
					>
						<b className="fa fa-calendar"></b> {self.lang.followupAppointment}
					</button>
				</div>
			);
		}

		return (
			<div className="up-modal-controls EditActivityFooter" ref={self.props.ref}>
				{removeBtn}

				{documentsDropup}

				{saveBtn}

				{this.props.isOutcomeEnabled ? null : followupBtns}

				{!activity.userEditable ? (
					<Text className="EditActivityFooter__missing-access" color="grey-10">
						{this.lang.missingEditAccess}
					</Text>
				) : null}

				{closeBtn}
			</div>
		);
	}
});

angular.module('upAttributes', []);

require('../../../upsales/common/attributes/activity.js');
require('../../../upsales/common/attributes/agreement.js');
require('../../../upsales/common/attributes/project.js');
require('../../../upsales/common/attributes/contact.js');
require('../../../upsales/common/attributes/mail.js');
require('../../../upsales/common/attributes/mailCampaign.js');
require('../../../upsales/common/attributes/mailCampaignInfo.js');
require('../../../upsales/common/attributes/order.js');
require('../../../upsales/common/attributes/orderRow.js');
require('../../../upsales/common/attributes/leadsources.js');
require('../../../upsales/common/attributes/leadchannels.js');
require('../../../upsales/common/attributes/account.js');
require('../../../upsales/common/attributes/activityType.js');
require('../../../upsales/common/attributes/appointmentType.js');
require('../../../upsales/common/attributes/appointment.js');
require('../../../upsales/common/attributes/category.js');
require('../../../upsales/common/attributes/customField.js');
require('../../../upsales/common/attributes/orderStage.js');
require('../../../upsales/common/attributes/product.js');
require('../../../upsales/common/attributes/productCategory.js');
require('../../../upsales/common/attributes/salesReport.js');
require('../../../upsales/common/attributes/user.js');
require('../../../upsales/common/attributes/lead.js');
require('../../../upsales/common/attributes/lead2.js');
require('../../../upsales/common/attributes/campaign.js');
require('../../../upsales/common/attributes/visitor.js');
require('../../../upsales/common/attributes/accountCategory.js');
require('../../../upsales/common/attributes/accountCategoryType.js');
require('../../../upsales/common/attributes/leaderAccount.js');
require('../../../upsales/common/attributes/leaderPage.js');
require('../../../upsales/common/attributes/mailTemplate.js');
require('../../../upsales/common/attributes/mailAccount.js');
require('../../../upsales/common/attributes/score.js');
require('../../../upsales/common/attributes/event.js');
require('../../../upsales/common/attributes/soliditetClient.js');
require('../../../upsales/common/attributes/formSubmit.js');
require('../../../upsales/common/attributes/form.js');
require('../../../upsales/common/attributes/opportunity.js');
require('../../../upsales/common/attributes/genericQuota.js');
require('../../../upsales/common/attributes/notification.js');
require('../../../upsales/common/attributes/activityQuota.js');
require('../../../upsales/common/attributes/file.js');
require('../../../upsales/common/attributes/apiKey.js');
require('../../../upsales/common/attributes/staticValues.js');
require('../../../upsales/common/attributes/accountProfile.js');
require('../../../upsales/common/attributes/userDefinedObject.js');
require('../../../upsales/common/attributes/userDefinedCategoryType.js');
require('../../../upsales/common/attributes/userDefinedCategory.js');
require('../../../upsales/common/attributes/esign.js');
require('../../../upsales/common/attributes/bannerGroup.js');
require('../../../upsales/common/attributes/phoneCall.js');
require('../../../upsales/common/attributes/segment.js');
require('../../../upsales/common/attributes/deleteLog.js');
require('../../../upsales/common/attributes/flow.js');
require('../../../upsales/common/attributes/optIn.js');
require('../../../upsales/common/attributes/groupMailCategory.js');
require('../../../upsales/common/attributes/banner.js');
require('../../../upsales/common/attributes/adCampaign.js');

require('../../../upsales/common/attributes/filter/staticValueLookUpFilter.js');

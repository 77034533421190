import Resource from './Resource';

class FileFolder extends Resource {
	constructor() {
		super('fileFolders');
	}

	getFilesAndFolders = async (clientId: number) =>
		this._getRequest(`all/${clientId}`, {
			methodName: 'getFilesAndFolders'
		}).then(response => response.data);

	changeFileFolder = async (id: number, folderId: number | null) =>
		this._putRequest(`changeFileFolder/${id}/${folderId}`, {
			methodName: 'changeFileFolder'
		}).then(response => response.data);
}

export default new FileFolder();

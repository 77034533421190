import React from 'react';
import t from 'Components/Helpers/translate';
import { ButtonSelect } from '@upsales/components';
import { FilterConfig, FilterConfigRaw } from 'App/babel/filterConfigs/FilterConfig';
import _ from 'lodash';

type SupportTicketPageProps = {
	filter: FilterConfig;
	onChange(filterConfig: FilterConfig, action: { action: string }): void;
};

const SupportTicketPage = ({ filter, onChange }: SupportTicketPageProps) => {
	const has = filter.value ? filter.value.has : 'all';
	const status = filter.value ? filter.value.Status.value : '';

	const onValueChange = (updates: { path: string; value: boolean | string }[]) => {
		let newFilter = _.cloneDeep(filter);

		if (!filter.value) {
			newFilter = Object.assign(newFilter, (newFilter as FilterConfigRaw).generate?.());
		}

		updates.forEach(update => {
			_.set(newFilter, update.path, update.value);
		});

		onChange(newFilter, {
			action: 'add'
		});
	};

	const hasOnChange = (value: string | boolean) => {
		if (value === 'all') {
			onValueChange([
				{ path: 'inactive', value: true },
				{ path: 'value.has', value: value },
				{ path: 'value.Status.value', value: '' }
			]);
		} else {
			if (has === 'all') {
				onValueChange([
					{ path: 'inactive', value: false },
					{ path: 'value.has', value: value },
					{ path: 'value.Status.value', value: 'all' }
				]);
			} else {
				onValueChange([
					{ path: 'inactive', value: false },
					{ path: 'value.has', value: value }
				]);
			}
		}
	};

	const statusOnChange = (value: string) => {
		onValueChange([{ path: 'value.Status.value', value: value }]);
	};

	return (
		<div className="history-filter">
			<div className="title">{t('ticket.tickets')}</div>
			<div className="history-filter-row extra-thin-row">
				<ButtonSelect
					className="flex-btn-group"
					color="bright-blue"
					onChange={hasOnChange}
					options={[
						{
							title: t('default.all'),
							value: 'all'
						},
						{
							title: t('advancedSearch.has'),
							value: true
						},
						{
							title: t('advancedSearch.hasNot'),
							value: false
						}
					]}
					value={has}
				/>
			</div>
			<div className="history-filter-row">
				<label className="secondary-title">{t('default.status')}</label>
				<ButtonSelect
					className="flex-btn-group no-shadow"
					color="bright-blue"
					onChange={statusOnChange}
					options={[
						{
							title: t('default.all'),
							value: 'all'
						},
						{
							title: t('default.open'),
							value: 'open'
						},
						{
							title: t('default.closed'),
							value: 'closed'
						}
					]}
					value={status}
					disabled={has === 'all'}
				/>
			</div>
		</div>
	);
};

export default SupportTicketPage;

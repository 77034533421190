import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Button, Label, Icon } from '@upsales/components';
import _ from 'lodash';
import translate from 'Components/Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import ValidationService from 'Services/ValidationService';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

import './SystemMail.scss';
// eslint-disable-next-line no-useless-escape
export const sendMailSelect = {
	tags: [],
	simple_tags: true,
	formatResult: obj => translate('default.add') + ': ' + _.escape(obj.text),
	createSearchChoice: term => {
		if (ValidationService.validEmail(term)) {
			return { id: term, text: term };
		} else {
			return null;
		}
	},
	formatNoMatches: () => translate('default.enterEmail'),
	formatSelection: (obj, container, escape) => escape(obj.text || obj)
};

class SystemMail extends PureComponent {
	lang = {
		addRecipients: translate('admin.addRecipients'),
		addMe: translate('admin.addMe'),
		sendMailPlaceholder: translate('admin.sendMailPlaceholder'),
		sendEmail: translate('admin.sendEmail'),
		emailSent: translate('admin.emailSent')
	};

	state = {
		sendingEmail: false,
		alreadySent: false,
		sendToEmails: []
	};

	componentWillUnmount() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
	}

	recipientsChanged = ({ target: { added, removed } }) => {
		let sendToEmails = [...this.state.sendToEmails];
		if (added) {
			sendToEmails.push(added);
		} else if (removed) {
			sendToEmails = sendToEmails.filter(e => e.id !== removed.id);
		}
		this.setState({ sendToEmails });
	};

	sendMail = async () => {
		this.setState({ sendingEmail: true });

		try {
			await this.props.sendMail(this.state.sendToEmails.map(obj => obj.id));
			this.setState({ sendingEmail: false, alreadySent: true });
			this.timer = setTimeout(() => {
				this.setState({ alreadySent: false, sendToEmails: [] });
			}, 2000);
		} catch (err) {
			this.setState({ sendingEmail: false });
		}
	};

	addMe = () => {
		const AppService = getAngularModule('AppService');
		const selfEmail = AppService.getSelf().email;
		const sendToEmails = [...this.state.sendToEmails, { id: selfEmail, text: selfEmail }];
		this.setState({ sendToEmails });
	};

	render() {
		const classes = new bemClass('SystemMail');
		const { sendingEmail, sendToEmails, alreadySent } = this.state;

		return (
			<div className={classes.b()}>
				<Label>
					<span>{this.lang.addRecipients}</span>
					<Button onClick={this.addMe} type="link">
						{this.lang.addMe}
					</Button>
				</Label>
				<UpSelect
					defaultValue={sendToEmails}
					placeholder={this.lang.sendMailPlaceholder}
					options={sendMailSelect}
					onChange={this.recipientsChanged}
					disabled={sendingEmail}
					multiple
				/>
				<Button
					className={classes.elem('Button').mod({ left: alreadySent }).b()}
					block={true}
					color={alreadySent ? 'bright-green' : 'green'}
					onClick={this.sendMail}
					loading={sendingEmail}
					disabled={sendToEmails.length === 0}
				>
					{alreadySent ? <Icon name="check" /> : null}
					{alreadySent ? this.lang.emailSent : this.lang.sendEmail}
				</Button>
			</div>
		);
	}
}

SystemMail.propTypes = {
	sendMail: PropTypes.func.isRequired
};

export default SystemMail;

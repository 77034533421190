'use strict';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, self._selectHelperOptions, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upSocialEvents = window.ReactCreateClass({
		UNSAFE_componentWillMount: function () {
			var self = this;
			var SocialEvents = Tools.SocialEvents;
			this._selectHelperOptions = {
				multiple: this.props.multiple || false,
				required: this.props.required || false,
				onChange: this.props.onChange,
				resource: SocialEvents,
				placeholder: function () {
					return self.props.placeholder || '';
				},
				ajax: true,
				asIds: true,
				idAttr: SocialEvents._attributes.id,
				titleAttr: SocialEvents._attributes.name,
				addEvent: 'SocialEvent.added',
				sorting: { field: SocialEvents._attributes.name, ascending: true },
				filterType: 'SocialEvent',
				select2: this.props.select2 || {}
			};
		},
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		UNSAFE_componentWillReceiveProps: ReactTemplates.TOOLS.selectHelper.UNSAFE_componentWillReceiveProps,
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

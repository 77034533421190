angular.module('upDirectives').directive('togglePriorityFlag', function () {
	//TODO: Rename it
	return {
		scope: {
			className: '=',
			onClick: '=',
			activity: '=',
			show: '=',
			icon: '=',
			tooltip: '='
		},
		replace: true,
		template: '<div></div>',
		restrict: 'A',
		link: function ($scope, $element, $attr) {
			let renderTimeout;
			let firstElement = $element[0];

			const render = () => {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}
				renderTimeout = setTimeout(() => {
					if (!firstElement) {
						return;
					}
					ReactDOM.render(
						React.createElement(ReactTemplates.TOOLS.rowTool, {
							className: $scope.className,
							loading: $scope.$eval($attr.loading),
							item: $scope.activity,
							show: $scope.show,
							icon: $attr.icon,
							tooltip: $scope.tooltip,
							onClick: $scope.onClick
						}),
						firstElement,
						() => {}
					);
				});
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$watch(
				'className',
				() => {
					render();
				},
				true
			);

			$scope.$watch(
				'loading',
				() => {
					render();
				},
				true
			);

			render();
		}
	};
});

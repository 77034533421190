'use strict';

angular.module('upDirectives').directive('upFlashSidebarButton', [
	'AppService',
	'$rootScope',
	function (AppService, $rootScope) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/common/directives/templates/upFlashSidebarButton.tpl.html?file'),
			link: function (scope, element) {
				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var currentUserId = AppService.getSelf().id;
					var customerId = AppService.getCustomerId();

					var getLocalStorage = function () {
						var obj = window.localStorage.getItem('flash');

						if (obj !== null) {
							try {
								return JSON.parse(obj);
							} catch (e) {
								console.log(e);
								return {};
							}
						} else {
							return {};
						}
					};

					var storedData = getLocalStorage();

					if (
						storedData[customerId] &&
						storedData[customerId][currentUserId] &&
						storedData[customerId][currentUserId].minimized === true
					) {
						element.addClass('visible');
					}

					var timout;

					scope.$on('flashHidden', function () {
						element.addClass('visible');

						clearTimeout(timout);

						element.find('#flash-sidbar-button-tooltip').addClass('visible');
						timout = setTimeout(function () {
							element.find('#flash-sidbar-button-tooltip').removeClass('visible');
						}, 3000);
					});

					scope.onClick = function () {
						var data = null,
							storedData = getLocalStorage();

						if (
							storedData[customerId] &&
							storedData[customerId][currentUserId] &&
							storedData[customerId][currentUserId].data
						) {
							data = storedData[customerId][currentUserId].data;
							data.fromMinimized = true;
						}

						if (data) {
							$rootScope.$broadcast('openFlash', data);
						} else {
							$rootScope.$broadcast('openFlash', { fromMinimized: true });
						}
					};

					scope.toggleTooltip = function (show) {
						if (show) {
							element.find('#flash-sidbar-button-tooltip').addClass('visible');
						} else {
							element.find('#flash-sidbar-button-tooltip').removeClass('visible');
						}
					};
				});
			}
		};
	}
]);

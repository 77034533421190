'use strict';

angular.module('domain.advancedSearch').directive('contactContactinfoSearchSection', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/domain/advanced-search/directives/contactContactinfoSearchSection.tpl.html?file'),
		scope: {
			Filters: '=filters',
			onChange: '&',
			onKeyup: '&'
		},
		controller: [
			'$scope',
			'AppService',
			function ($scope, AppService) {
				$scope.inited = false;
				$scope.ngChange = function () {
					if ($scope.onChange) {
						$scope.onChange();
					}
				};

				$scope.ngKeyup = function (e) {
					if ($scope.onKeyup) {
						$scope.onKeyup({ $event: e });
					}
				};

				$scope.toggleMissingAt = function () {
					if (!$scope.Filters.EmailMissingAt.value.MissingAt) {
						$scope.Filters.EmailMissingAt.value.MissingAt = true;
						$scope.Filters.EmailMissingAt.value.Email.value = '';
					} else {
						$scope.Filters.EmailMissingAt.value.MissingAt = false;
					}
					$scope.ngChange();
				};

				var init = function () {
					$scope.Filters.Notes.comparisonType = 'Match';
					$scope.inited = true;
				};
				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var unWatchInit = $scope.$watch('Filters', function (filters) {
						if (filters !== undefined) {
							unWatchInit();
							init();
						}
					});
				});
			}
		]
	};
});

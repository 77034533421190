import React from 'react';
import CompanyHistory from './CompanyHistory/CompanyHistory';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import CompanyPhone from './CompanyPhone/CompanyPhone';
import CompanyWebsite from './CompanyWebsite/CompanyWebsite';
import ClientRating from 'Components/ClientRating';

import JourneyStepDisplay from '../../../JourneyStepDisplay';
import { Tooltip } from '@upsales/components';

const CompanyBlock = ({
	client,
	openContact,
	showCompanyHistory = true,
	showMarketHistory = false,
	showAddress = true,
	showPhone = true,
	showWebsite = true,
	showRating = true,
	actions
}) => {
	if (!client || !client.id) {
		return (
			<div id="company-block">
				<span className="placeholder-text">{Tools.$translate('company.block.placeholder')}</span>
			</div>
		);
	}

	const mapHistory = function (client) {
		return {
			sales: {
				has: {
					activity: client.hasActivity,
					appointment: client.hasAppointment,
					opportunity: client.hasOpportunity,
					order: client.hasOrder
				},
				had: {
					activity: client.hadActivity,
					appointment: client.hadAppointment,
					opportunity: client.hadOpportunity,
					order: client.hadOrder
				}
			},
			market: {
				mail: client.hasMail,
				form: client.hasForm,
				visit: client.hasVisit
			}
		};
	};

	let clientAdress = null;
	if (
		client.$addresses &&
		client.$addresses.Visit &&
		client.$addresses.Visit.city &&
		client.$addresses.Visit.city.length &&
		client.$addresses.Visit.country &&
		client.$addresses.Visit.country.length
	) {
		clientAdress = <CompanyAddress city={client.$addresses.Visit.city} country={client.$addresses.Visit.country} />;
	}

	return (
		<div id="company-block">
			<h3 className="company-name">
				<Tooltip title={Tools.$translate('default.goToAccount')} position="right">
					<a
						onClick={event =>
							openContact(
								event,
								Tools.$state.href('account.dashboard', {
									customerId: Tools.AppService.getCustomerId(),
									id: client.id
								})
							)
						}
					>
						{client.name}
					</a>
				</Tooltip>
			</h3>
			<JourneyStepDisplay
				id={client.id}
				type="client"
				disableActions={!client.userEditable}
				journeyStep={client.journeyStep}
				onSetJourneyStep={step => actions.setJourneyStep('client', client, step)}
			/>
			{showCompanyHistory ? (
				<CompanyHistory
					theKey={`${client.name}-history`}
					history={mapHistory(client)}
					showMarketHistory={showMarketHistory}
				/>
			) : null}
			{showRating ? (
				<div id="company-rating" className="company-util">
					<ClientRating client={client} />
				</div>
			) : null}
			{showAddress && clientAdress}
			{showPhone ? <CompanyPhone client={client} /> : null}
			{showWebsite ? <CompanyWebsite website={client.webpage} /> : null}
		</div>
	);
};

export default CompanyBlock;

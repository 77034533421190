import Resource from 'Resources/Resource';

class AIResource extends Resource {
	constructor() {
		super('ai/completions', null);
	}
}

const resource = new AIResource();

export default resource;

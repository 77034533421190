import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, Help, Toggle, Tabs, Tab, Text, Icon, Block, Loader } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './AdminHeader.scss';

type Props = {
	title: string;
	description: string | JSX.Element;
	image?: string;
	articleId?: number;
	toggle?: boolean;
	toggleChecked?: boolean;
	onToggleChange?: React.ComponentProps<typeof Toggle>['onChange'];
	toggleLabel?: string;
	toggleDisabled?: boolean;
	toggleArticleId?: number;
	toggleErrorText?: string;
	tabs?: (React.ComponentProps<typeof Tab> & { loading?: boolean })[];
	selectedTab?: string;
	onTabChange?: React.ComponentProps<typeof Tabs>['onChange'];
};

const AdminHeader = ({
	title,
	description,
	image,
	articleId = 0,
	toggle,
	toggleChecked,
	onToggleChange,
	toggleLabel,
	toggleDisabled,
	toggleArticleId,
	toggleErrorText,
	tabs,
	selectedTab,
	onTabChange
}: Props) => {
	const classes = new bemClass('AdminHeader', 'admin-page-header');
	return (
		<Card className={classes.b()}>
			<div className={classes.elem('text-wrap').b()}>
				<Title className={classes.elem('title').b()}>{title}</Title>
				<span className={classes.elem('description').b()}>{description}</span>
				{articleId ? (
					<span className="text-bright-blue text-no-wrap">
						<Help articleId={articleId} sidebar={true}>
							{T('default.readMore')}
						</Help>
					</span>
				) : null}
				{toggle ? (
					<>
						<div className={classes.elem('toggle').b()}>
							<Toggle
								disabled={toggleDisabled}
								size="xl"
								color="medium-green"
								checked={toggleChecked}
								onChange={onToggleChange}
							/>
							<label>
								{toggleLabel}
								{toggleArticleId ? <Help articleId={toggleArticleId} sidebar /> : null}
							</label>
						</div>
						{toggleErrorText ? (
							<Block>
								<Text color="red">
									<Icon name="ban" space="mrl" />
									{toggleErrorText}
								</Text>
							</Block>
						) : null}
					</>
				) : null}
			</div>
			{image ? (
				<div className={classes.elem('image').b()}>
					<div style={{ backgroundImage: `url(img/${image})` }} />
				</div>
			) : null}
			{tabs && tabs.length ? (
				<Tabs
					color="white"
					className={classes.elem('tabs').b()}
					noFlex={true}
					selected={selectedTab}
					onChange={onTabChange}
				>
					{tabs.map(({ id, title, disabled, loading }) => (
						<Tab key={id} id={id} disabled={disabled || loading}>
							{loading ? <Loader size="xs" /> : title}
						</Tab>
					))}
				</Tabs>
			) : null}
		</Card>
	);
};

AdminHeader.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	image: PropTypes.string,
	articleId: PropTypes.number,
	toggle: PropTypes.bool,
	toggleChecked: PropTypes.bool,
	onToggleChange: PropTypes.func,
	toggleLabel: PropTypes.string,
	toggleDisabled: PropTypes.bool,
	toggleArticleId: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired
		})
	),
	selectedTab: PropTypes.string,
	onTabChange: PropTypes.func
};

export default AdminHeader;

'use strict';

angular.module('upResources').factory('OptIn', [
	'$resource',
	'$q',
	'URL',
	'API',
	'OptInAttributes',
	'ResourceHelper',
	'$translate',
	function OptIn($resource, $q, URL, API, OptInAttributes, ResourceHelper, $translate) {
		var Attributes = OptInAttributes().attr;
		var keys = OptInAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'optIn',
			createSuccessBody: 'saved.optIn',
			updateSuccessBody: 'updated.optIn',
			deleteSucccessBody: 'deleted.optIn',
			createErrorBody: 'saveError.optIn',
			updateErrorBody: 'saveError.optIn',
			deleteErrorBody: 'deleteError.optIn'
		});

		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'optIn/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: { method: 'GET', isArray: false },
				find: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			new: function () {
				var optIn = {
					type: 'single',
					title: '',
					// Replace brackets to curlies after translate, curlies are used to mark link
					text: $translate.instant('admin.optInTermsText').replace('[[', '{{').replace(']]', '}}'),
					terms: null
				};

				return optIn;
			},

			find: function (filter) {
				return Resource.find(filter).$promise;
			},

			save: function (optIn, options) {
				var defered = $q.defer();

				if (!optIn) {
					return defered.reject('No optIn');
				}

				if (optIn.id) {
					Resource.update(
						{ id: optIn.id },
						{ optIn: optIn },
						function (response) {
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Resource.create(
						{ optIn: optIn },
						function (response) {
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !optIn.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !optIn.id);
					});

				return defered.promise;
			},

			delete: function (optIn, options) {
				return Resource.delete({ id: optIn.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, optIn);

						return res;
					})
					.catch(function (err) {
						// Notify user
						var onDeleteErrorOptions = options;

						if (_.get(err, 'data.error.errorCode') === 104) {
							onDeleteErrorOptions = _.assign(
								{ deleteErrorBody: 'admin.optIn.saveErrorUsedOnForm' },
								onDeleteErrorOptions
							);
						}

						helper.onDeleteError(onDeleteErrorOptions);
					});
			},
			attr: Attributes,
			keys: keys,
			requiredFields: OptInAttributes().requiredFields
		};

		return Model;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardContent, Headline, Text, Row, Column, Logo, Link, Avatar } from '@upsales/components';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import './ManageAccount.scss';

const upsalesContactInfo = {
	name: 'Upsales Nordic AB',
	adress: 'Vasagatan 16',
	city: '111 20 Stockholm',
	phone: '+46 8-505 806 00',
	email: 'info@upsales.com'
};

class ManageAccount extends React.Component {
	constructor(props) {
		super(props);

		this.signUp = this.signUp.bind(this);
	}

	signUp(link) {
		const { accountInformation } = this.props;
		const { you, customerDetails } = accountInformation;
		const space = you.name && you.name.indexOf(' ');
		const firstName = space > 0 ? you.name.substring(0, space) : you.name;
		const lastName = space > 0 ? you.name.substring(space + 1) : '';

		link += `?first_name=${firstName}&last_name=${lastName}&email=${you.email}&reference=${customerDetails.id}&organization=${customerDetails.name}`;

		window.location.assign(link);
	}

	openTab(link, logout) {
		window.open(link);
		if (logout) {
			Tools.$state.go('login');
		}
	}

	renderAdmins(admins) {
		if (!admins || !admins.length) {
			return;
		}

		return admins.map(admin => (
			<Row key={admin.email} className="ManageAccount__administrators">
				<Avatar key={'avatar-' + admin.email} initials={admin.name} email={admin.email} size={'sm'} />
				<Text>{admin.name}</Text>
			</Row>
		));
	}

	renderAvatar() {
		const { accountInformation } = this.props;
		const accountManager = accountInformation.accountManager;

		return (
			<div className="ManageAccount__description__avatar">
				<Card>
					<CardContent>
						<Avatar
							key={'avatar'}
							initials={accountManager.name}
							email={accountManager.email}
							size={'lg'}
						/>
						<Text bold={true}>{accountManager.name}</Text>
						<Text className="ManageAccount__content__account-manager">{accountManager.title}</Text>
						<Link href={'mailto:' + accountManager.email} color={'green'}>
							{accountManager.email}
						</Link>
						<br />
						<Link href="#" color={'green'}>
							{accountManager.phone || upsalesContactInfo.phone}
						</Link>
					</CardContent>
				</Card>
			</div>
		);
	}

	renderTrial() {
		const { accountInformation } = this.props;
		const isExpired = !accountInformation.customerDetails.stillActive;

		const headline = isExpired ? 'Your trial period has ended' : 'Ready to pick a plan?';
		const subtitle = isExpired
			? "We have paused your account, but don't worry. All your hard work and data is saved another couple of days."
			: 'We have four tailor made plans to enable you to increase your revenue.';

		const avatar = this.renderAvatar();

		return (
			<div className="ManageAccount__content">
				<Logo color="green" />
				<Row>
					<Column key="1" className="ManageAccount__description">
						<Headline>{headline}</Headline>
						<Text size="lg">{subtitle}</Text>
					</Column>
					<Column key="2">{avatar}</Column>
				</Row>
			</div>
		);
	}

	renderKillDatePassed() {
		const { accountInformation } = this.props;
		const { you, admins } = accountInformation;
		const administrator = you && you.administrator;

		const avatar = this.renderAvatar();
		const adminRows = this.renderAdmins(admins);

		return (
			<div>
				<div className="ManageAccount__content">
					<Logo color="green" />
					<Row>
						<Column key="1" className="ManageAccount__description">
							<Headline>{'Your account is paused'}</Headline>
							<Text size="lg">
								{
									"We have paused your account due to missing payments, but don't worry. All your hard work and data is saved."
								}
							</Text>

							<Text size="lg">
								{
									'Once payment is made your account will be available again. Do not hesitate to contact support or your account manager if you think a mistake has been made.'
								}
							</Text>
						</Column>
						<Column key="2">{avatar}</Column>
					</Row>
					<div className="ManageAccount__admins">
						{!administrator && (
							<Text bold={true}>
								{admins && admins.length === 1 ? 'Your administrator is:' : 'Your administrators are:'}
							</Text>
						)}
						{!administrator && adminRows}
					</div>
				</div>
				<div className="ManageAccount__footer">
					<Row>
						<Column key="1" align="left">
							<Text bold={true}>{upsalesContactInfo.name}</Text>
							<Text>{upsalesContactInfo.adress}</Text>
							<Text>{upsalesContactInfo.city}</Text>
						</Column>
						<Column key="2" align="right">
							<Text bold={true}>{upsalesContactInfo.phone}</Text>
							<Link href={'mailto:' + upsalesContactInfo.email} color={'bright-blue'}>
								{upsalesContactInfo.email}
							</Link>
						</Column>
					</Row>
				</div>
			</div>
		);
	}

	render() {
		const { accountInformation } = this.props;
		const isTrial = accountInformation.customerDetails.provisioningTrial;

		if (!isTrial) {
			const killDate = accountInformation.customerDetails.killDate;
			const paymentExtensions = Tools.AppService.getPaymentExtensions();
			const hasActivePaymentExtension =
				paymentExtensions.length > 0 &&
				moment().startOf('day').isSameOrBefore(paymentExtensions.pop()?.postponeDate);

			// If the user isn't authed, the accountInformation.customerDetails object will be missing the killDate key, so accountInformation.customerDetails.killDate will be UNDEFINED
			// If the killDate is null it has been purposefully set to null, so we should redirect away from the page. If the killDate is in the future we should also redirect away
			// Also if the user has gotten a payment extension from master we should redirect away

			// In order to check for the above, we check if killDate === null instead of only checking if it is falsy
			if (killDate === null || moment(killDate).isAfter(moment()) || hasActivePaymentExtension) {
				return <Redirect to="/" />;
			}
		}

		const content = isTrial ? this.renderTrial() : this.renderKillDatePassed();

		return (
			<div className="ManageAccount">
				<div className="ManageAccount__Backfill" />
				<div className="ManageAccount__wrapper">{content}</div>
			</div>
		);
	}
}

ManageAccount.propTypes = {
	credentials: PropTypes.object,
	accountInformation: PropTypes.object,
	ManageAccountAddress: PropTypes.object,
	lang: PropTypes.object,
	credits: PropTypes.object
};

export const detached = ManageAccount;

export default connect(null, null)(ManageAccount);

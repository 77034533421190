import { Progressbar, Icon } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';

ReactTemplates.mailCampaign.sidebar = window.ReactCreateClass({
	getInitialState: function () {
		return {
			showErrors: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			user: t('default.user'),
			sentBy: t('mail.sentBy'),
			sender: t('mail.sender'),
			sendDate: t('mail.sendDate'),
			recievers: t('mail.recievers'),
			contacts: t('default.contacts'),
			whyDidAllNotGetIt: t('mail.whyDidAllNotGetIt'),
			unSubscribed: t('groupMail.unSubscribed'),
			prevUnsubscribed: t('default.prevUnsubscribed'),
			missing: t('default.missing'),
			previousBounce: t('default.previousBounce'),
			hardBounce: t('event.contacthard_bounce'),
			softBounce: t('event.contactsoft_bounce'),
			withSameEmail: t('default.withSameEmail'),
			email: t('default.email'),
			inactiveContacts: t('default.inactiveContacts'),
			withInvalidEmail: t('mail.withInvalidEmail'),
			preview: t('mail.preview'),
			reject: t('event.contactreject'),
			otherError: t('mail.notSentUnknownError'),
			attachments: t('groupMail.attachments'),
			edit: t('default.edit'),
			bounces: t('event.bounces'),
			createSegment: t('segment.createSegment'),
			sending: t('mail.sending'),
			contactsFromSelection: t('groupMail.contactsFromSelection'),
			tagError: t('event.tag_error'),
			unverifiedSender: t('mail.unverifiedSender'),
			share: t('default.share')
		};

		this.tags = {
			'Client.UserName': t('default.accountManager'),
			'Client.UserEmail': t('default.accountManagerEmail'),
			'General.CompanySender': t('mail.default'),
			'General.CompanyEmail': t('mail.presetEmail'),
			'General.CurrentUserName': t('default.signedInUser'),
			'General.CurrentUserEmail': t('default.signedInUserEmail')
		};
	},

	imgLoaded: function () {
		this._preview.classList.add('loaded');
	},

	imgError: function () {
		this._preview.classList.add('failed');
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	getHrefFromState: function (stateName, params) {
		params.customerId = Tools.AppService.getCustomerId();
		return this.props.tools.$state.href(stateName, params || {});
	},

	number: function (val) {
		return this.props.tools.$filter('number')(val);
	},

	showErrors: function () {
		this.setState({ showErrors: true });
	},

	parseTags: function (str) {
		if (str.indexOf('{{') === 0 && str.indexOf('}}') === str.length - 2) {
			return this.tags[str.replace('{{', '').replace('}}', '')] || str;
		}

		return str;
	},

	openAttachment: function (file) {
		var tools = this.props.tools;
		var url =
			tools.URL +
			tools.API +
			this.props.rootData.customerId +
			'/resources/download/internal/' +
			file.value +
			'?inline=true';
		window.open(url, '_blank');
	},

	onFilterChange: function (e, filters) {
		if (this.props.tools.$state.current.name === 'mailCampaign.recipients') {
			e.preventDefault();

			this.props.rootData.pageData.onFiltersReset();

			filters = filters ? filters.split(',') : [];

			this.props.rootData.pageData.onFilterChange('deliveredGroup', filters.indexOf('all') > -1);

			for (var i in filters) {
				this.props.rootData.pageData.onFilterChange(filters[i], true);
			}
		}
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;
		var rootData = this.props.rootData;
		var mailCampaign = rootData.mailCampaign;

		var totalBounced = 0;
		if (rootData.stats) {
			totalBounced = rootData.stats.previous_bounce + rootData.stats.hardBounce + rootData.stats.softBounce;
		}

		var progressBar = null;
		if (rootData.stats) {
			var sentPercent = (rootData.stats.send / rootData.stats.total) * 100;
			var errorPercent = (rootData.stats.error / rootData.stats.total) * 100;
			var hasErrors = errorPercent !== 0 || rootData.stats.missingEmail !== 0;
			const fromSelection = mailCampaign.filter && !mailCampaign.segments?.length && !mailCampaign.project;
			// Missing email does not count as an error here, so we need to check that too
			progressBar = (
				<div className="sidebar-section">
					<label>
						{self.lang.recievers}
						{fromSelection ? (
							<button
								type="button"
								className="btn up-btn pull-right btn-link btn-bright-blue btn-inline"
								onClick={rootData.createSegment}
								style={{
									height: '20px',
									lineHeight: '20px'
								}}
							>
								{self.lang.createSegment}
							</button>
						) : null}
					</label>
					{mailCampaign.project ? (
						<a
							className="ellipsis-line"
							href={self.getHrefFromState('campaign.dashboard', { id: mailCampaign.project.id })}
						>
							{mailCampaign.project.name}
						</a>
					) : null}
					{mailCampaign.segments?.length
						? mailCampaign.segments.map(segment => {
								return (
									<a
										key={segment.id}
										className="ellipsis-line"
										href={self.getHrefFromState('segment.contacts', {
											id: segment.id,
											customerId: this.props.rootData.customerId
										})}
									>
										{segment.name}
									</a>
								);
						  })
						: null}

					{fromSelection ? self.lang.contactsFromSelection : null}

					<span className="subtitle">
						{rootData.stats.total} {self.lang.contacts}
					</span>

					<div className="progress">
						<Progressbar value={sentPercent} color="green" />
						<Progressbar value={errorPercent > 0 ? sentPercent + errorPercent : 0} color="orange" />
					</div>

					{hasErrors ? (
						<div>
							{!self.state.showErrors ? (
								<button
									type="button"
									className="up-btn btn-orange btn-link btn-inline btn-sm"
									onClick={self.showErrors}
								>
									<b className="fa fa-fw fa-warning"></b> {self.lang.whyDidAllNotGetIt}
									{'?'}
								</button>
							) : null}

							{self.state.showErrors ? (
								<div id="error-reasons">
									{rootData.stats.send ? (
										<div>
											<i className="fa fa-fw fa-user text-bright-blue"></i>
											<a
												href={self.getHrefFromState('mailCampaign.recipients', {})}
												onClick={function (e) {
													self.onFilterChange(e, 'all');
												}}
											>
												<span className="text-bright-blue">
													{self.number(rootData.stats.send)}{' '}
													{self.lang.recievers.toLowerCase()}
												</span>
											</a>
										</div>
									) : null}
									{rootData.stats.unsubscribed ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.unsubscribed)}{' '}
												{self.lang.unSubscribed.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.previous_unsubscribed ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<a
												href={self.getHrefFromState('mailCampaign.recipients', {
													filter: 'showErrors',
													drill: 'prevunsub'
												})}
												onClick={function (e) {
													self.onFilterChange(e, 'prevunsub');
												}}
											>
												<span className="text-orange">
													{self.number(rootData.stats.previous_unsubscribed)}{' '}
													{self.lang.prevUnsubscribed.toLowerCase()}
												</span>
											</a>
										</div>
									) : null}

									{totalBounced ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<a
												href={self.getHrefFromState('mailCampaign.recipients', {
													filter: 'showErrors',
													drill: 'bounce,prevbounce'
												})}
												onClick={function (e) {
													self.onFilterChange(e, 'bounce,prevbounce');
												}}
											>
												<span className="text-orange">
													{self.number(totalBounced)} {self.lang.bounces.toLowerCase()}
												</span>
											</a>
										</div>
									) : null}

									{rootData.stats.notActive ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.notActive)}{' '}
												{self.lang.inactiveContacts.toLowerCase()}{' '}
												{self.lang.contacts.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.duplicateEmail ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<a
												href={self.getHrefFromState('mailCampaign.recipients', {
													filter: 'showErrors',
													drill: 'duplicates'
												})}
												onClick={function (e) {
													self.onFilterChange(e, 'duplicates');
												}}
											>
												<span className="text-orange">
													{self.number(rootData.stats.duplicateEmail)}{' '}
													{self.lang.withSameEmail.toLowerCase()}
												</span>
											</a>
										</div>
									) : null}
									{rootData.stats.invalidEmail ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<a
												href={self.getHrefFromState('mailCampaign.recipients', {
													filter: 'showErrors',
													drill: 'invalidemail'
												})}
												onClick={function (e) {
													self.onFilterChange(e, 'invalidemail');
												}}
											>
												<span className="text-orange">
													{self.number(rootData.stats.invalidEmail)}{' '}
													{self.lang.withInvalidEmail.toLowerCase()}
												</span>
											</a>
										</div>
									) : null}
									{rootData.stats.reject ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.reject)} {self.lang.reject.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.otherError ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.otherError)}{' '}
												{self.lang.otherError.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.missingEmail ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-grey"></i>
											<span className="text-grey">
												{self.number(rootData.stats.missingEmail)}{' '}
												{self.lang.missing.toLowerCase()} {self.lang.email.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.tagError ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.tagError)}{' '}
												{self.lang.tagError.toLowerCase()}
											</span>
										</div>
									) : null}
									{rootData.stats.unverifiedSender ? (
										<div>
											<i className="fa fa-fw fa-exclamation-triangle text-orange"></i>
											<span className="text-orange">
												{self.number(rootData.stats.unverifiedSender)}{' '}
												{self.lang.unverifiedSender.toLowerCase()}
											</span>
										</div>
									) : null}
								</div>
							) : null}
						</div>
					) : null}
				</div>
			);
		}
		var sendDate = moment(mailCampaign.sendDate);
		return (
			<div id="card-sidebar">
				<h2 id="title">{mailCampaign.subject}</h2>

				<div className="sidebar-section">
					<label>{self.lang.sendDate}</label>
					{sendDate.isValid() ? sendDate.format('DD MMMM YYYY HH:mm') : self.lang.sending}
				</div>

				<div className="sidebar-section">
					<label>{self.lang.user}</label>
					<ReactTemplates.TOOLS.avatar
						user={mailCampaign.user}
						size={20}
						avatarService={tools.avatarService}
						style={{ marginTop: '0px' }}
					/>
					<span className="ellipsis-line">{mailCampaign.user.name}</span>
				</div>

				<div className="sidebar-section">
					<label>{self.lang.sender}</label>
					<span className="ellipsis-line">{self.parseTags(mailCampaign.fromName)}</span>
					<span className="subtitle ellipsis-line">{self.parseTags(mailCampaign.from)}</span>
				</div>

				{progressBar}

				{mailCampaign.attachments && mailCampaign.attachments.length ? (
					<div className="sidebar-section">
						<label>{self.lang.attachments}</label>
						{_.map(mailCampaign.attachments, function (file) {
							return (
								<a
									onClick={self.openAttachment.bind(self, file)}
									style={{ display: 'block', whiteSpace: 'normal' }}
								>
									{' '}
									{file.filename}{' '}
								</a>
							);
						})}
					</div>
				) : null}

				<div className="sidebar-section">
					<div id="preview-img" ref={self.setRef.bind(self, '_preview')}>
						<img
							onLoad={self.imgLoaded}
							onError={self.imgError}
							onClick={rootData.openPreview}
							src={mailCampaign.$$thumbnailUrl}
						/>
						<div className="preview-img-gradient"></div>
						<span className="preview-img-button-wrap">
							<DefaultButton onClick={rootData.openPreview} block>
								<Icon space="mrs" name="eye"></Icon>
								{self.lang.preview}
							</DefaultButton>
							<DefaultButton onClick={rootData.copyShareableLink} block>
								<Icon space="mrs" name="share-alt"></Icon>
								{self.lang.share}
							</DefaultButton>
						</span>
					</div>
				</div>
			</div>
		);
	}
});

import DatePreset from 'App/upsales/common/services/datePresetService';

angular.module('upDirectives').directive('upDatepicker', function () {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			changeCallback: '&ngChange',
			model: '=ngModel',
			disabled: '=ngDisabled'
		},
		require: 'ngModel',
		templateUrl: require('App/upsales/common/directives/templates/upDatepicker.tpl.html?file'),
		link: function (scope, element, attrs) {
			if (attrs.toggle !== undefined) {
				element.hide();
				scope.isToggle = true;
				setTimeout(function () {
					element.find('.up-datepicker-curtain').on('click', function () {
						element.hide();
					});
				}, 0);
			} else {
				scope.isToggle = false;
			}

			// If you do not set any presets to the directive these are the onse you get
			var standardPresets = [
				'whenever',
				'untilToday',
				'fromToday',
				'currentWeek',
				'currentMonth',
				'lastMonth',
				'lastWeek',
				'custom'
			];

			// presets
			var presetArray = standardPresets;
			if (attrs.presets !== undefined) {
				var splt = attrs.presets.split(',');
				if (splt.length && splt[0] !== '') {
					presetArray = splt;
				}
			}

			var getPreset = function (key) {
				if (key === 'custom') {
					return {
						get: function () {
							return {
								start: scope.model.start,
								end: scope.model.end
							};
						}
					};
				}

				return DatePreset.get(key);
			};

			scope.$watch(
				'model',
				function (value) {
					if (!value) {
						scope.model = {
							preset: 'whenever',
							start: null,
							end: null
						};
					} else {
						scope.model = {
							...value,
							start: value.start ? new Date(value.start) : null,
							end: value.end ? new Date(value.end) : null
						};
					}
				},
				true
			);

			var datePicker = element.find('#datepicker');
			var pickerStartChangeTimer = null;
			var pickerEndChangeTimer = null;
			var defaults = {
				preset: 'whenever',
				startValue: null,
				endValue: null,
				onChange: function () {}
			};

			// set default onChange function
			if (scope.changeCallback === undefined) {
				scope.changeCallback = defaults.onChange;
			}

			// set class
			element.addClass('up-datepicker');

			// set dropdown width
			var width = element.width();
			element.find('.dropdown-menu').css('width', width);

			// set presets
			scope.presets = {};
			angular.forEach(presetArray, function (key) {
				key = key.trim();
				var pre = DatePreset.get(key);
				if (pre) {
					scope.presets[key] = pre;
				}
			});
			delete scope.presets.custom;

			scope.presets = _.sortBy(scope.presets, 'sortId');
			scope.onlyCustom = attrs.presets === 'custom' ? true : false;

			// change function
			var change = function () {
				if (!scope.model) {
					return;
				}
				scope.changeCallback({
					$preset: scope.model.preset,
					$value: scope.model,
					$text: getText()
				});
			};

			// generate valueText function
			function getText() {
				if (!scope.model.preset) {
					return null;
				}
				if (scope.model.preset !== 'custom' && scope.model.preset !== 'whenever') {
					// return preset language tag if preset is a dynamic date
					return getPreset(scope.model.preset).name;
				} else if (scope.model.preset === 'whenever') {
					// return no text if preset is whenever
					return null;
				} else {
					// return selected dates if preset is custom
					var start = scope.model.start || '',
						end = scope.model.end || '',
						separator = start && end ? ' - ' : '';

					if (start) {
						start = moment(start).format('L');
					}
					if (end) {
						end = moment(end).format('L');
					}

					return start + separator + end;
				}
			}

			// datepicker change function
			scope.pickerStartChange = function () {
				if (pickerStartChangeTimer) {
					clearTimeout(pickerStartChangeTimer);
				}
				pickerStartChangeTimer = setTimeout(function () {
					if (moment(scope.model.start).isAfter(scope.model.end)) {
						scope.model.end = moment(scope.model.start).endOf('day').toDate();
					}
					if (scope.model.start !== '') {
						scope.model.start = moment(scope.model.start).startOf('day').toDate();
						change();
					}
				}, 0);
			};
			scope.pickerEndChange = function () {
				if (pickerEndChangeTimer) {
					clearTimeout(pickerEndChangeTimer);
				}
				pickerEndChangeTimer = setTimeout(function () {
					if (moment(scope.model.end).isBefore(scope.model.start)) {
						scope.model.start = moment(scope.model.end).startOf('day').toDate();
					}
					if (scope.model.end !== '') {
						scope.model.end = moment(scope.model.end).endOf('day').toDate();
						change();
					}
				}, 0);
			};

			// preset set function
			scope.setPreset = function (preset) {
				scope.model.preset = preset;

				var val = getPreset(preset).get();

				scope.model.start = val.start ? moment(val.start).toDate() : val.start;
				scope.model.end = val.end ? moment(val.end).toDate() : val.end;

				if (preset === 'custom') {
					scope.model.start = moment().startOf('day').toDate();
					scope.model.end = moment().endOf('day').add(1, 'week').toDate();

					// focus datepicker
					setTimeout(function () {
						datePicker.focus();
					}, 0);
				}

				change();

				if (scope.isToggle) {
					element.find('.up-datepicker-curtain').trigger('click');
				}
			};

			scope.openSelect2 = function () {
				element.find('.up-datepicker-preset-select').select2('open');
			};

			change();
		}
	};
});

'use strict';

angular.module('upResources').service('ParseEsign', [
	'URL',
	'API',
	'AppService',
	function (URL, API, AppService) {
		return function (esign) {
			esign.status = esign.upsalesStatus;

			if (esign.file) {
				var customerId = AppService.getCustomerId();
				esign.file.$$previewUrl =
					URL + API + customerId + '/resources/download/internal/' + esign.file.id + '?inline=true';
			}

			return esign;
		};
	}
]);

'use strict';

ReactTemplates.upFilters.filterTypes.radio = window.ReactCreateClass({
	getInitialState: function () {
		return {
			expanded: this.props.expanded || false
		};
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	onInputChange: function (event) {
		var oldOption = this.findSelectedOption();
		var newOption = this.props.filter.options[event.target.value];

		if (oldOption !== newOption) {
			this.props.onChange(
				{
					filterName: this.props.filterName,
					comparisonType: newOption.comparisonType,
					inactive: newOption.inactive ? true : false,
					value: newOption.value,
					type: 'radio'
				},
				{
					action: 'add'
				}
			);
		}
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.props.onChange(
			{
				filterName: this.props.filterName,
				inactive: true
			},
			{
				action: 'remove'
			}
		);

		ReactTemplates.TOOLS.stopProp(event);
	},
	findSelectedOption: function () {
		var filter = this.props.filter;

		var selectedOption = _.find(filter.options, function (option) {
			return (
				option.value === filter.value &&
				!!option.inactive === !!filter.inactive &&
				(option.comparisonType || 'Equals') === (filter.comparisonType || 'Equals')
			);
		});

		if (selectedOption === undefined) {
			return filter.options[0];
		}
		return selectedOption;
	},
	formatValueString: function () {
		var valueString = '';

		var selectedOption = this.findSelectedOption();

		if (selectedOption) {
			valueString = Tools.$translate(selectedOption.text);
		}

		return valueString;
	},
	render: function () {
		var filter = this.props.filter;

		var title = Tools.$translate(filter.title);
		var valueString = this.formatValueString();

		var selectedOption = this.findSelectedOption();
		var active = selectedOption.inactive === true ? false : true;
		var btnWidth = 100 / filter.options.length + '%';

		var options = _.map(
			filter.options,
			function (option, index) {
				var text = Tools.$translate(option.text);
				var classes = 'btn up-btn btn-sm no-shadow btn-light-grey';

				if (option === selectedOption) {
					classes = 'btn up-btn btn-sm no-shadow btn-bright-blue';
				}

				return (
					<button
						key={'option-' + index}
						value={index}
						className={classes}
						onClick={this.onInputChange}
						style={{ width: btnWidth, padding: '4px' }}
					>
						{text}
					</button>
				);
			}.bind(this)
		);

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{valueString}
					</span>
				</div>

				{this.state.expanded && (
					<div className={'btn-group btn-block drilldown radio-drilldown'}>{options}</div>
				)}
			</div>
		);
	}
});

import Attribute, { DisplayType, Type } from './Attribute';

const UserDefinedObjectAttribute = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'id'
	}),
	user: Attribute({
		type: Type.Object,
		displayType: DisplayType.User,
		field: 'user',
		title: 'default.user',
		sortable: 'user.name',
		placeholder: 'default.noUser',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		}
	}),
	client: Attribute({
		type: Type.Object,
		displayType: DisplayType.ClientLink,
		field: 'client',
		title: 'default.client',
		sortable: 'client.name',
		placeholder: 'default.noAccount',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			}),
			users: Attribute({
				type: Type.Array,
				displayType: DisplayType.Users,
				field: 'client.users',
				title: 'default.accountManager',
				placeholder: 'default.noAccountManager',
				displayKey: ['client.users', 'name'],
				attr: {
					id: Attribute({
						field: 'client.users.id',
						type: Type.Number
					}),
					name: Attribute({
						field: 'client.users.name',
						type: Type.String
					}),
					email: Attribute({
						field: 'client.users.email',
						type: Type.Email
					}),
					role: Attribute({
						type: Type.Object,
						field: 'client.users.role',
						attr: {
							id: Attribute({
								field: 'client.users.role.id',
								type: Type.Number
							}),
							name: Attribute({
								field: 'client.users.role.name',
								type: Type.String
							})
						}
					})
				}
			})
		}
	}),
	clientUsers: Attribute({
		type: Type.Array,
		displayType: DisplayType.Users,
		field: 'client.users',
		title: 'default.accountManager',
		placeholder: 'default.noAccountManager',
		displayKey: 'client.users',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'client.users.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.users.name',
				type: Type.String
			}),
			email: Attribute({
				field: 'client.users.email',
				type: Type.Email
			}),
			role: Attribute({
				type: Type.Object,
				field: 'client.users.role',
				attr: {
					id: Attribute({
						field: 'client.users.role.id',
						type: Type.Number
					}),
					name: Attribute({
						field: 'client.users.role.name',
						type: Type.String
					})
				}
			})
		}
	}),
	contact: Attribute({
		type: Type.Object,
		displayType: DisplayType.ContactLink,
		field: 'contact',
		title: 'default.contact',
		sortable: 'contact.name',
		placeholder: 'default.noContact',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'contact.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contact.name',
				type: Type.String
			})
		}
	}),
	notes: Attribute({
		title: 'default.notes',
		field: 'notes',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes',
		selectableColumn: false
	}),
	notes1: Attribute({
		title: 'default.notes',
		field: 'notes1',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes1',
		selectableColumn: false
	}),
	notes2: Attribute({
		title: 'default.notes',
		field: 'notes2',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes2',
		selectableColumn: false
	}),
	notes3: Attribute({
		title: 'default.notes',
		field: 'notes3',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes3',
		selectableColumn: false
	}),
	notes4: Attribute({
		title: 'default.notes',
		field: 'notes4',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes4',
		selectableColumn: false
	}),
	regDate: Attribute({
		title: 'default.regDate',
		field: 'regDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'regDate'
	}),
	modDate: Attribute({
		title: 'default.modDate',
		field: 'modDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'modDate'
	}),
	/* Unfortunately both project and campaign is needed. 
	   project to make the resource map and parse to work,
	   and campaign to not break old list views */
	project: Attribute({
		type: Type.Object,
		displayType: DisplayType.ProjectLink,
		field: 'project',
		title: 'default.project',
		sortable: 'project.name',
		placeholder: 'default.noCampaign',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'project.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'project.name',
				type: Type.String
			})
		}
	}),
	campaign: Attribute({
		type: Type.Object,
		displayType: DisplayType.ProjectLink,
		field: 'project',
		displayKey: 'project',
		title: 'default.project',
		sortable: 'project.name',
		placeholder: 'default.noCampaign',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'project.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'project.name',
				type: Type.String
			})
		}
	}),
	categories: Attribute({
		type: Type.Array,
		displayType: DisplayType.Array,
		field: 'categories',
		title: 'default.categories',
		displayKey: ['categories', 'name'],
		selectableColumn: true,
		sortable: 'category.name',
		placeholder: 'default.noCategories',
		attr: {
			id: Attribute({
				field: 'category.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'category.name',
				type: Type.String
			})
		}
	}),
	custom: Attribute({
		type: Type.Array,
		field: 'custom'
	}),
	userEditable: Attribute({
		type: Type.Boolean,
		field: 'userEditable'
	}),
	userRemovable: Attribute({
		type: Type.Boolean,
		field: 'userRemovable'
	})
};

export default UserDefinedObjectAttribute;

'use strict';

angular.module('domain.contact').factory('UpdateContactMultiMeta', [
	'$q',
	'Campaign',
	'AppService',
	function ($q, Campaign, AppService) {
		return function () {
			var customerId = AppService.getCustomerId();

			var promises = {
				campaigns: Campaign.customer(customerId).find({}),
				contactCustomFields: $q.when({ data: AppService.getCustomFields('contact') })
			};

			return $q.all(promises);
		};
	}
]);

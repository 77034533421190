import React from 'react';
import BEMClass from '@upsales/components/Utils/bemClass';
import './LinkButtonGroup.scss';
import { Icon, IconName, Tooltip } from '@upsales/components';
import URLFormatter from '../../Helpers/URLFormatter';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

type LinkButtonProps = {
	href?: string;
	icon: IconName;
	tooltip: string;
	active: boolean;
};

const LinkButton: React.FC<LinkButtonProps> = ({ href, icon, tooltip, active }) => {
	const classes = new BEMClass('LinkButton');

	return (
		<Tooltip title={tooltip} position="bottom" distance={18}>
			<a
				className={classes.mod(active ? 'active' : 'inactive').b()}
				href={href ? URLFormatter.format(href) : undefined}
				target="_blank"
			>
				<Icon className={classes.elem('icon').b()} name={icon} />
			</a>
		</Tooltip>
	);
};

type LinkButtonGroupProps = {
	description?: string;
	buttons: { service: string; href: string; icon: IconName; search?: boolean }[];
};

const LinkButtonGroup: React.FC<LinkButtonGroupProps> = ({ description, buttons }) => {
	const classes = new BEMClass('LinkButtonGroup');

	const t = getAngularModule('$translate');

	const lang = {
		descriptionMissing: t('prospecting.descriptionMissing')
	};

	const hasDescription = description !== undefined && description !== '';

	return (
		<div className={classes.b()} onClick={event => event.stopPropagation()}>
			<LinkButton
				key="description"
				tooltip={description || lang.descriptionMissing}
				icon="info"
				active={hasDescription}
			/>
			{buttons.map(button => {
				const isInvalid = button.href === null || button.href === '' || button.href === undefined;
				const visitTextTag = button.search
					? 'prospecting.manually.clickToSearch'
					: 'prospecting.manually.clickToVisit';

				const tooltipText = !isInvalid
					? t(visitTextTag, { service: button.service })
					: t('prospecting.manually.notAvailable', { service: button.service });
				return (
					<div key={button.service}>
						<LinkButton href={button.href} tooltip={tooltipText} icon={button.icon} active={!isInvalid} />
					</div>
				);
			})}
		</div>
	);
};

export default LinkButtonGroup;

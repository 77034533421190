'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('AccountCategory', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AccountCategoryAttributes',
	'RequestBuilder',
	'CacheService',
	'ParseGeneric',
	'AppService',
	'ResourceHelper',
	function AccountCategory(
		$resource,
		$q,
		URL,
		API,
		AccountCategoryAttributes,
		RequestBuilder,
		CacheService,
		ParseGeneric,
		AppService,
		ResourceHelper
	) {
		var AA = AccountCategoryAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'category',
			createSuccessBody: 'saved.category',
			updateSuccessBody: 'updated.category',
			deleteSucccessBody: 'deleted.category',
			createErrorBody: 'saveError.category',
			updateErrorBody: 'saveError.category',
			deleteErrorBody: 'deleteError.category'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Category = null;
				var Cache = CacheService.getCache(CacheService.cacheTypes.ACCOUNTCATEGORY);
				Category = $resource(
					URL + API + customer + '/clientcategories/:id',
					{},
					{
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCategory', { isArray: true })
						},
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCategory', { isArray: false })
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric('ParseCategory', { isArray: false })
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric('ParseCategory', { isArray: false })
						},
						merge: {
							method: 'POST',
							isArray: false,
							url: URL + API + customer + '/clientcategories/merge',
							transformResponse: ParseGeneric('ParseCategory', { isArray: false })
						}
					}
				);

				instance.find = function (filter, options) {
					var defered = $q.defer();
					var params = angular.extend(filter, options);

					Category.find(params, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				instance.get = function (id) {
					if (!id) return $q.reject('no category id');

					return Category.get({ id: id }).$promise;
				};

				instance.save = function (category, options) {
					var defered = $q.defer();

					if (!category) {
						return defered.reject('No category');
					}

					if (category.id) {
						Category.update(
							{ id: category.id },
							category,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Category.create(
							{},
							category,
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							var categories = AppService.getCategories('account');
							var index = _.findIndex(categories, { id: res.data.id });

							if (index > -1) {
								categories[index] = _.cloneDeep(res.data);
							} else {
								categories.unshift(_.cloneDeep(res.data));
							}

							AppService.setCategories('account', categories);
							AllIWantDataCache.clearData();

							helper.onSave(options, res.data, !category.id);
						})
						.catch(function (err) {
							if (_.get(err, 'data.error.errorCode') === 25) {
								helper.errorNotification(
									'default.error',
									'default.nameAlreadyInUse',
									null,
									!category.id
								);
								options = options || {};
								options.skipErrorNotification = true;
							}
							helper.onSaveError(options, !category.id);

							return $q.reject(err);
						});

					return defered.promise;
				};

				instance.getCached = function () {
					var key = 'AccountCategories';
					var cached = Cache.get(key);
					var filter = new RequestBuilder();
					filter.addSort('name', true);
					if (cached) {
						return $q.when(cached);
					} else {
						return instance.find(filter.build()).then(function (res) {
							Cache.put(key, res);
							return res;
						});
					}
				};

				instance.merge = function (mergeObj) {
					return Category.merge(mergeObj)
						.$promise.then(function (res) {
							var categories = AppService.getCategories('account');

							var toIndex = _.findIndex(categories, { id: mergeObj.to });

							if (toIndex > -1) {
								categories[toIndex] = res.data;
							}

							categories = _.filter(categories, function (category) {
								return category.id !== mergeObj.from;
							});

							AppService.setCategories('account', categories);
							AllIWantDataCache.clearData();

							return res;
						})
						.catch(function (err) {
							helper.onSaveError();
							return $q.reject(err);
						});
				};

				instance['delete'] = function (category, options) {
					return Category.delete({ id: category.id })
						.$promise.then(function (res) {
							var categories = AppService.getCategories('account');
							categories = _.filter(categories, function (_category) {
								return _category.id !== category.id;
							});
							AppService.setCategories('account', categories);
							AllIWantDataCache.clearData();

							helper.onDelete(options, category);
							return res;
						})
						.catch(function (err) {
							helper.onDeleteError(options);
							return $q.reject(err);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upResources').factory('UserParam', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function UserParam($resource, $q, URL, API, ResourceHelper) {
		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'master/userParam/';
		var Resource = $resource(
			baseUrl + ':paramId',
			{},
			{
				update: { method: 'PUT', isArray: false },
				create: { method: 'POST', isArray: false }
			}
		);

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'userParam',
			updateSuccessBody: 'updated.userParam',
			updateErrorBody: 'saveError.userParam'
		});

		var justSave = function (method, paramId, value, options) {
			options = options || {};
			var defered = $q.defer();

			if (value === undefined || !paramId) {
				return $q.reject('No params');
			}

			if (method === 'create') {
				Resource.create({ paramId: paramId, value: value }, defered.resolve, defered.reject);
			} else {
				Resource.update({ paramId: paramId }, { value: value }, defered.resolve, defered.reject);
			}

			defered.promise
				.then(function (res) {
					// Notify user
					helper.onSave(options, res.data, false);
				})
				.catch(function () {
					// Notify user
					helper.onSaveError(options, false);
				});

			return defered.promise;
		};

		var Model = {
			save: function (paramId, value, options) {
				return justSave('save', paramId, value, options);
			},
			create: function (paramId, value, options) {
				return justSave('create', paramId, value, options);
			},
			delete: function (paramId, options) {
				return Resource.delete({ paramId: paramId })
					.$promise.then(function (res) {
						//helper.onDelete(options);
						return res;
					})
					.catch(function (err) {
						helper.onDeleteError(options);
						return $q.reject(err);
					});
			},
			createMulti: function (values) {
				var defered = $q.defer();

				if (!Array.isArray(values) && values.length) {
					return defered.reject('No params');
				}

				Resource.create({ values: values }, defered.resolve, defered.reject);

				defered.promise
					.then(function (res) {
						helper.onSave({}, res.data, false);
					})
					.catch(function (err) {
						helper.onSaveError({}, false);
						return $q.reject(err);
					});

				return defered.promise;
			}
		};

		return Model;
	}
]);

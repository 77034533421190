import AppointmentParticipantResponse from 'App/enum/AppointmentParticipantResponse';
import { Avatar, Flex } from '@upsales/components';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	user: PropTypes.object.isRequired,
	avatar: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
	avatarSize: PropTypes.number,
	avatarStyle: PropTypes.object,
	isHost: PropTypes.bool
};

class AccountManagerRow extends React.Component {
	render() {
		const user = this.props.user;

		if (!user || !user.hasOwnProperty('name')) {
			return <i className="grey">{Tools.$translate('default.noAccountManager')}</i>;
		}

		return (
			<Flex
				gap={4}
				alignItems="center"
				className={`account-manager ${this.props.className}`}
				style={this.props.style}
			>
				{this.props.avatar ? (
					<Avatar
						initials={user.name}
						style={{ height: '30px', width: '30px', fontSize: '30px', ...(this.props.avatarStyle ?? {}) }}
						status={AppointmentParticipantResponse[user.responseStatus]}
					/>
				) : null}
				<span className="account-manager-name">
					{user.name}
					{this.props.isHost ? <span className="host">{Tools.$translate('appointment.host')}</span> : null}
				</span>
			</Flex>
		);
	}
}

AccountManagerRow.propTypes = propTypes;
AccountManagerRow.defaultProps = {
	className: '',
	style: null,
	avatarStyle: null,
	avatarSize: 25,
	avatar: true,
	isHost: false
};

export default AccountManagerRow;

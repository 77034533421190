'use strict';

angular.module('domain.engage').factory('ViewAdMeta', [
	'$q',
	'RequestBuilder',
	'Ads',
	'AppService',
	function ($q, RequestBuilder, Ads, AppService) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var adId = $stateParams.id;
				var promises = {
					ad: Ads.customer(customerId).get(adId),
					credits: Ads.customer(customerId).getCredits()
				};

				return $q.all(promises);
			});
		};
	}
]);

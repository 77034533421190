import React from 'react';
import withDrilldown from './withDrilldown';
import AppointmentDrilldown from './AppointmentDrilldown.js';

const InputWithDrillDown = withDrilldown(AppointmentDrilldown, {
	field: 'hasAppointment',
	text: 'default.appointments'
});

export default function SegmentAppointmentInput(props) {
	return <InputWithDrillDown {...props} />;
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../hooks';
import { RootState } from 'Store/index';
import { Icon, Button, Tooltip, Block, IconName } from '@upsales/components';
import { restoreSavedModals } from 'Store/actions/ModalActions';
import { StateModal } from 'Store/reducers/ModalReducer';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './SidebarSavedModalButton.scss';
import { getAccountSelf, getSelf } from 'Store/selectors/AppSelectors';
import { useForceRender } from 'App/components/hooks';

type StoredFlashActivityInLocalStorage = {
	minimized: boolean;
	closedActivitiesCurrentBlixt: number;
	bookedAppointmentsCurrentBlixt: number;
	flashTime: number;
	flashTimeDuration: number;
	appointmentListWidgetRoles: unknown;
	appointmentListWidgetTypes: unknown;
	activityOutcomesListWidgetRoles: unknown;
	activityOutcomesListWidgetTypes: unknown;
	activityListWidgetRoles: unknown;
	activityListWidgetTypes: unknown;
	data: {
		currentId: number;
		filter: unknown;
	}
}

type StoredFlashActivities = {
	[customerId: string]: {
		[customerUserId: string]: StoredFlashActivityInLocalStorage
	}
}

type ButtonConfig = {
	icon: IconName;
	tooltip?: (props: StateModal['props']) => string;
};

type SavedModal = Omit<StateModal, 'name'> & {
	name: StateModal['name'] | 'FlashActivity';
};

const buttonVariants: readonly { condition: (m: SavedModal) => boolean; opts: ButtonConfig }[] = [
	{
		condition: m => m.name === 'EditPhonecall' && m.props?.focus,
		opts: { icon: 'flash', tooltip: () => T('savedModal.returnTo.focus') }
	},
	{
		condition: m => m.name === 'EditPhonecall',
		opts: {
			icon: 'phone',
			tooltip: p => {
				const contact = p?.activity?.contacts?.[0]?.name;
				const client = p?.activity?.client?.name;
				const withString = client
					? contact
						? T('savedModal.withContactAtCompany', { contact, client })
						: T('savedModal.withCompany', { client })
					: '';
				return T('savedModal.returnTo.phonecall') + ' ' + withString;
			}
		}
	},
	{
		condition: m => m.name === 'EditTodo',
		opts: {
			icon: 'todo',
			tooltip: p => T('savedModal.returnTo.todo') + (p?.todo?.description ? `: ${p?.todo?.description}` : '')
		}
	},
	{
		condition: m => m.name === 'FlashActivity',
		opts: {
			icon: 'flash',
			tooltip: () => T('flash.sidbarButtonTooltip'),
		}
	}
] as const;

const defaultButton: Required<ButtonConfig> = {
	icon: 'dock-to-right',
	tooltip: () => T('savedModal.returnTo.drawer')
} as const;

type SidebarMinimizedModalButtonProps = {
	minimizedModals: SavedModal[];
	onMinimizedButtonClick: () => void;
}

export const SidebarMinimizedModalButton = ({
	minimizedModals,
	onMinimizedButtonClick
}: SidebarMinimizedModalButtonProps) => {

	const classes = new BemClass('SidebarSavedModalButton');
	const buttonRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		buttonRef.current?.animate?.(
			[{ transform: 'scale(1)' }, { transform: 'scale(1.15)' }, { transform: 'scale(1)' }],
			{
				duration: 750,
				iterations: 2
			}
		);
	}, [buttonRef.current, minimizedModals[0]?.id]);

	if (!minimizedModals.length) return null;

	const targetModal = minimizedModals[minimizedModals.length - 1];

	const opts = buttonVariants.find(c => c.condition(targetModal))?.opts ?? defaultButton;
	const tooltipTitle = (opts.tooltip ?? defaultButton.tooltip)(targetModal.props);


	return (
		<Block space="ptm pbm" className={classes.b()}>
			<div ref={buttonRef}>
				<Tooltip title={tooltipTitle} position="right" distance={20}>
					<Button onClick={onMinimizedButtonClick}>
						<Icon name={opts.icon} />
					</Button>
				</Tooltip>
			</div>
		</Block>
	);
};

export const SidebarSavedModalButton = () => {
	const dispatch = useAppDispatch();
	const savedModals = useSelector(({ Modal }: RootState) => Modal.savedModals);
	const onMinimizedButtonClick = () => dispatch(restoreSavedModals());

	return (
		<SidebarMinimizedModalButton
			minimizedModals={savedModals}
			onMinimizedButtonClick={onMinimizedButtonClick}
		/>
	);
};


export const SidebarSavedLocalStorageModalButton = () => {
    const currentUserId = getSelf()?.id;
	const customerId = getAccountSelf()?.client?.id;
	const { triggerRender } = useForceRender();

	const getMinimizedFlashActivities = () => {
		if (!customerId || !currentUserId) {
			return null;
		}
		try {
			const storedData = localStorage.getItem('flash');
			if (storedData !== null) {
				const parsedData = (JSON.parse(storedData) as StoredFlashActivities)[customerId][currentUserId];;
				return parsedData.minimized ? parsedData : null;
			}
		} catch (err) {
			return null;
		}
	};

	useEffect(() => {
		const offFlashHidden = Tools.$rootScope.$on('flashHidden', triggerRender);
		const offFlashClosed = Tools.$rootScope.$on('flashClosed', triggerRender);

		return () => {
			offFlashHidden();
			offFlashClosed();
		};
	}, []);

	const minimizedFlashActivities = getMinimizedFlashActivities();
	if (!minimizedFlashActivities) {
		return null;
	}

	const parseToStateModal = (flashActivity: StoredFlashActivityInLocalStorage): SavedModal => ({
		id: flashActivity.data.currentId,
		opts: {},
		openedTimeStamp: new Date(flashActivity.flashTime),
		name: 'FlashActivity',
		props: flashActivity.data || {},
	});

	const flashActivityAsStateModal = parseToStateModal(minimizedFlashActivities);

	const onMinimizedButtonClick = () => Tools.$rootScope.$broadcast('openFlash', {
		...flashActivityAsStateModal.props,
		fromMinimized: true
	});

	return (
		<SidebarMinimizedModalButton
			minimizedModals={[flashActivityAsStateModal]}
			onMinimizedButtonClick={onMinimizedButtonClick}
		/>
	);
};

export default SidebarSavedLocalStorageModalButton;

'use strict';

ReactTemplates.findProspect.startInfo = window.ReactCreateClass({
	getInitialState: function () {
		return {};
	},
	render: function () {
		var self = this;
		return (
			<div id="find-prospects-body">
				<div id="start-info-main">
					<div id="start-img">
						<img src="img/find-new-prospects.png" />
					</div>
					<h2 className="start-info-title">{self.props.infoTitle}</h2>
					<div id="start-info-text">{self.props.infoText}</div>
				</div>
			</div>
		);
	}
});

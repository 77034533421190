'use strict';
import openAgreement from 'App/helpers/openSubscriptionHelper';

angular.module('upDirectives').directive('upEventAgreement', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventAgreement.tpl.html?file'),
		require: 'upEventAgreement',
		scope: {
			event: '=upEventAgreement',
			defaultCurrency: '=defaultCurrency'
		},
		controller: [
			'$scope',
			'$stateParams',
			'$upModal',
			'Agreement',
			function ($scope, $stateParams, $upModal, Agreement) {
				$scope.customerId = Tools.AppService.getCustomerId();
				$scope.openAgreement = function () {
					openAgreement({ agreementId: $scope.event.entityId });
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}
					$scope.event.$$loading = true;

					//eslint-disable-next-line promise/catch-or-return
					Agreement.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}
							$scope.event.$$eventDetails = res.data;
							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
						});
				};
			}
		]
	};
});

'use strict';

angular.module('upResources').service('MapGeneric', [
	'$injector',
	'MapHelpers',
	function ($injector, MapHelpers) {
		return function (mapperName, opts) {
			if (!opts) {
				opts = {};
			}

			var mapper = mapperName && $injector.get(mapperName);
			return function (payload, headers) {
				payload = angular.copy(payload);
				var obj = payload;
				// If data is not root level object
				if (opts.payloadName) {
					obj = payload[opts.payloadName];
				}
				if (obj.custom) {
					obj.custom = MapHelpers.mapCustom(obj.custom);
				}

				if (obj.$mappedCustom) {
					obj.$mappedCustom = undefined;
				}

				var mapped = mapper ? mapper(payload, headers) : payload;

				mapped = window.Tools.mapDates(mapped);

				return angular.toJson(mapped);
			};
		};
	}
]);

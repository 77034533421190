'use strict';

ReactTemplates.form.landingPage = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		var self = this;
		self.lang = {
			externalSite: t('form.externalSite')
		};
	},
	ensureHttpPrefix: function (value) {
		var notValid =
			value !== undefined &&
			value !== null &&
			!(
				'http://'.indexOf(value.substring(0, 7)) === 0 ||
				'https://'.indexOf(value.substring(0, 8)) === 0 ||
				'ftp://'.indexOf(value.substring(0, 6)) === 0
			);

		if (notValid) {
			return 'http://' + value;
		} else {
			return value;
		}
	},
	onChange: function (e) {
		var element = this.state.element;

		element.value = { href: this.ensureHttpPrefix(e.target.value) };

		this.setState({ element: element });
		this.props.onChange(element);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	render: function () {
		var self = this;

		return (
			<div className="tool-main-section no-padding">
				<div className="tool-section">
					<input
						className="form-control"
						value={self.state.element.value.href}
						onChange={self.onChange}
						placeholder={self.lang.externalSite}
					/>
				</div>
			</div>
		);
	}
});

import parseListView, { parseReportView, parseUdoListView } from 'App/resources/parsers/listView';

angular.module('upResources').factory('AllIWant', [
	'$resource',
	'URL',
	'API',
	'ParseCustom',
	'ParseOrderStage',
	'ParseCategory',
	function AllIWant($resource, URL, API, ParseCustom, ParseOrderStage, ParseCategory) {
		var getUdoId = function (str) {
			var res = parseInt(str[str.length - 1]);
			return isNaN(res) ? 1 : res;
		};

		var Resource = $resource(
			URL + API + 'alliwant?onlyUserId=true',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: function (res) {
						try {
							res = JSON.parse(res);
						} catch (e) {
							return res;
						}

						var data = res.data;

						if (data) {
							if (data.self) {
								Tools.AppService.setAccountSelf(data.self);
							}
							if (data.customerSelf) {
								Tools.AppService.setSelf(data.customerSelf);
							}

							if (data.userMap) {
								const userCache = {};
								data.userMap.active.forEach(user => {
									userCache[user.id] = user;
								});
								data.userMap.inactive.forEach(user => {
									userCache[user.id] = user;
								});
								data.userMap.deleted.forEach(user => {
									userCache[user.id] = user;
								});

								const skip = new Set(['deleted', 'active', 'inactive']);
								for (const key in data.userMap) {
									if (skip.has(key)) continue;

									data.userMap[key] = data.userMap[key].map(userId => userCache[userId]);
								}
							}

							if (data.customFields) {
								var parsedFields = {};
								_.each(data.customFields, function (fields, type) {
									parsedFields[type] = _.map(fields, function (field) {
										return ParseCustom(field);
									});
								});
								data.customFields = parsedFields;
							}

							if (data.listViews) {
								var parsedListViews = {};
								_.each(data.listViews, function (views, type) {
									if (type.indexOf('userDefinedObject') !== -1) {
										var udoId = getUdoId(views[0].type);
										var cacheKey = 'userDefined' + udoId;
										var c2Fields = data.userDefinedCategoryTypes[udoId];
										var udoObj = _.find(data.metadata.params.UserDefinedObject, { id: udoId });
										// TODO: MOVE THIS TO NEW FILE
										parsedListViews[type] = _.map(data.listViews[type], function (view) {
											return parseUdoListView(
												view,
												data.customFields[cacheKey],
												c2Fields,
												udoObj
											);
										});
									} else {
										parsedListViews[type] = _.map(data.listViews[type], function (view) {
											return parseListView(view);
										});
									}
								});
								data.listViews = parsedListViews;
							}

							if (data.reportViews) {
								var parsedreportViews = {};

								if (data.hasSharedViews) {
									_.each(data.reportViews, function (views, type) {
										parsedreportViews[type] = _.map(views, parseReportView);
									});
								} else {
									_.each(data.reportViews, function (views, type) {
										if (!Array.isArray(views)) {
											parsedreportViews[type] = {
												own: _.map(views.own, parseReportView),
												public: _.map(views.public, parseReportView)
											};
											return;
										}

										parsedreportViews[type] = {
											own: _.map(_.filter(views, 'private'), parseReportView),
											shared: _.map(_.filter(views, 'shared'), parseReportView),
											public: _.map(
												_.filter(views, function (view) {
													return !view.private && !view.shared;
												}),
												parseReportView
											)
										};
									});
								}

								data.reportViews = parsedreportViews;
							}

							if (data.orderStages) {
								data.orderStages = _.map(data.orderStages, function (stage) {
									return ParseOrderStage(stage);
								});
							}

							if (data.productCategories) {
								const hasBackendAccess =
									Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
								data.productCategories = _.map(data.productCategories, function (category) {
									return ParseCategory(category, hasBackendAccess);
								});
							}

							if (data.clientCategories) {
								data.clientCategories = _.map(data.clientCategories, function (category) {
									return ParseCategory(category);
								});
							}

							if (data.contactCategories) {
								data.contactCategories = _.map(data.contactCategories, function (category) {
									return ParseCategory(category);
								});
							}
						}

						res.data = data;

						return res;
					}
				}
			}
		);

		var Model = {
			get: function () {
				return Resource.get().$promise;
			}
		};

		return Model;
	}
]);

'use strict';

(function () {
	var render = function () {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	};

	var componentDidMount = function (self) {
		var OptIn = Tools.OptIn; // <- change to optins

		var opts = {
			multiple: self.props.multiple || false,
			required: self.props.required || false,
			onChange: self.props.onChange,
			resource: OptIn,
			placeholder: function () {
				return self.props.placeholder || '';
			},
			ajax: true,
			asIds: false,
			idAttr: OptIn.attr.id,
			titleAttr: OptIn.attr.title,
			addEvent: 'OptIn.added',
			sorting: { field: OptIn.attr.title, ascending: true },
			filterType: 'OptIn',
			filters: function (rb) {
				if (self.props.optinType === 'manual') {
					rb.addFilter(OptIn.attr.type, rb.comparisonTypes.Equals, self.props.optinType);
				}
			},
			select2: self.props.select2 || {}
		};

		var input = jQuery(self._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(self, opts, input, function (options) {
			input.select2(options);
		});
	};

	ReactTemplates.INPUTS.upOptins = window.ReactCreateClass({
		componentDidMount: function () {
			componentDidMount(this, false);
		},
		componentDidUpdate: ReactTemplates.TOOLS.selectHelper.updateValue,
		render: render
	});
})();

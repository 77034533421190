import React from 'react';
import PropTypes from 'prop-types';
import { openDrawer } from 'Services/Drawer';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import { phoneNumberHelper } from '@upsales/common';

const propTypes = {
	contact: PropTypes.object,
	cellphone: PropTypes.bool
};

const defaultProps = {
	contact: {}
};

class Phone extends React.Component {
	constructor(props) {
		super(props);
		var t = Tools.$translate;
		this.lang = {
			noSet: t('default.noSet'),
			phoneType: props.cellphone ? t('default.cellPhone').toLowerCase() : t('default.phone').toLowerCase()
		};
	}

	shouldComponentUpdate(newProps) {
		const { contact } = this.props;
		const { contact: newContact } = newProps;
		if (newContact.cellphone && contact.cellphone !== newContact.cellphone) {
			return true;
		} else if (contact.phone !== newContact.phone) {
			return true;
		}
		return false;
	}

	render() {
		const { contact, cellphone } = this.props;
		const number = cellphone ? contact.cellPhone : contact.phone;
		var cellTel = ReactTemplates.TOOLS.upVoice(contact, 'contact', number);
		var noCellPhone = this.lang.noSet + ' ' + this.lang.phoneType;
		return (
			<div className="AccountListContacts__Phone">
				{cellTel ? (
					<a
						target={cellTel.target}
						href={cellTel.href}
						onClick={e => {
							cellTel.onClick(e);
							if (
								Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
								Tools.AppService.getSelf().userParams.phoneClickDrawer
							) {
								openDrawer('CreateCall', {
									client: contact.client,
									contact,
									date: new Date(),
									time: moment().format('LT'),
									initialDescription: T('default.phonecall')
								});
							}
						}}
					>
						{phoneNumberHelper.format(
							number,
							phoneNumberHelper.getCountryCodeForNumber(number),
							'INTERNATIONAL'
						)}
					</a>
				) : (
					<i className="grey">{noCellPhone}</i>
				)}
			</div>
		);
	}
}

Phone.propTypes = propTypes;
Phone.defaultProps = defaultProps;
export default Phone;

import { Flex, Avatar, AvatarStack, Text, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { useSoftDeployAccess } from 'App/components/hooks';
import { useSelf } from 'App/components/hooks/appHooks';
import { useTranslation } from 'Components/Helpers/translate';
import colorMappings from '@upsales/components/Utils/colorMappings';
import React from 'react';

export const CalendarItem = ({
	appointment,
	userMap,
	onOpenAppointmentModal,
	invisible,
	continuousDate,
	startsBefore,
	fullDay,
	backgroundColor
}) => {
	const { t } = useTranslation();
	const hasCalendarColor = useSoftDeployAccess('CALENDAR_VIEW_COLORS');
	const self = useSelf();

	const isMyAppointment = appointment.users?.some(user => user.id === self.id);
	const userIds = appointment.users?.map(user => user.id) || [appointment.userId];
	const users = userIds.map(id => userMap?.[id]).filter(Boolean);
	const appointmentLength = (appointment.endDate.getTime() - appointment.date.getTime()) / (60 * 1000);

	const classes = new bemClass('Appointment');
	const textClassName = startsBefore && !fullDay ? 'transparent-text' : '';
	const wrapperStyle = invisible
		? { backgroundColor: 'transparent', margin: 0, padding: 0 }
		: continuousDate && startsBefore && !fullDay
		? { marginRight: -6, marginLeft: -6, paddingLeft: 10, borderRadius: 0 }
		: continuousDate
		? { marginRight: -6, borderTopRightRadius: 0, borderBottomRightRadius: 0 }
		: startsBefore && !fullDay
		? { marginLeft: -6, paddingLeft: 10, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
		: {};

	const textStyle = appointment.isEvent
		? { color: colorMappings.get('grey-10') }
		: appointment.color
		? { color: window.getTextColor(appointment.color) }
		: {};
	const clientName = appointment.client && appointment.client.name;
	const showClientName = appointmentLength > 25;
	const tooltip = appointment.isEditing
		? ''
		: appointment.description
		? `${appointment.description}${clientName ? '\n' + clientName : ''}`
		: t('calendar.event.private.tooltip');

	return (
		<div
			style={{ ...wrapperStyle, ...(backgroundColor && { backgroundColor }) }}
			className={classes.mod({ clickable: !appointment.isEvent, editing: appointment.isEditing }).b()}
			onClick={e => onOpenAppointmentModal && onOpenAppointmentModal(e, appointment)}
		>
			{!invisible && <div className={classes.elem('overlay').b()} />}
			<Tooltip title={tooltip} disabled={!tooltip}>
				<div className={classes.elem('header').b()}>
					<Text bold style={textStyle} className={`xs time ${textClassName}`}>
						{startsBefore ? moment(startsBefore).format('HH:mm') : moment(appointment.date).format('HH:mm')}
					</Text>
					<Text
						bold
						style={textStyle}
						className={`noWrap ${textClassName} ${showClientName ? '' : 'xs'}`}
						size="sm"
					>
						{appointment.isEditing
							? appointment.description
							: appointment.description || t('default.occupied')}
					</Text>
				</div>
				{clientName && showClientName ? (
					<Text style={textStyle} className={`xs noWrap no-margin ${textClassName}`}>
						{clientName}
					</Text>
				) : null}
				{hasCalendarColor && !isMyAppointment ? (
					<Flex alignItems="center">
						<AvatarStack size="sm">
							{users.map(user => (
								<Avatar key={user.id} email={user.email} initials={user.name} />
							))}
						</AvatarStack>
						{users.length === 1 ? (
							<Text space="mls" className="noWrap" size="sm">
								{users[0].name}
							</Text>
						) : null}
					</Flex>
				) : null}
			</Tooltip>
		</div>
	);
};

import React from 'react';
import { FlowStep } from 'App/resources/Model/Flow';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import FlowStepBase from '../FlowStepBase';
import { Text } from '@upsales/components';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
	isInvalid: boolean;
};

export const MAX_HEIGHT = 100;

const FlowStepCreateTodo = ({ step, ...baseProps }: Props) => {
	const classes = new BemClass('FlowStepCreateTodo');
	const description = step.params.find(param => param.name === 'Description')?.value;
	return (
		<FlowStepBase
			icon="activity"
			title={T('flow.step.createTodo')}
			maxHeight={MAX_HEIGHT}
			backgroundColor="white"
			borderColor="rose-4"
			className={classes.b()}
			{...baseProps}
		>
			<Text ellipsis>{description}</Text>
		</FlowStepBase>
	);
};

export default FlowStepCreateTodo;

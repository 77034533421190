'use strict';

angular.module('domain.admin').controller('EditDynamiclinkCtrl', [
	'$scope',
	'Links',
	'$modalParams',
	'TagsService',
	function ($scope, Links, $modalParams, TagsService) {
		var EditDynamiclinkCtrl = this;

		EditDynamiclinkCtrl.tagsVisible = false;
		EditDynamiclinkCtrl.tagSearch = '';
		EditDynamiclinkCtrl.tags = [];
		EditDynamiclinkCtrl.lastCopiedTag = null;
		EditDynamiclinkCtrl.saving = false;

		EditDynamiclinkCtrl.entities = ['client', 'contact', 'appointment', 'activity', 'order', 'sidebar'];

		EditDynamiclinkCtrl.setEntity = function (entity, init) {
			if (!init && EditDynamiclinkCtrl.link.entity === entity) {
				return;
			}

			EditDynamiclinkCtrl.link.entity = entity;

			EditDynamiclinkCtrl.tags = TagsService.getTagsByEntity(entity);

			if (entity === 'client') {
				EditDynamiclinkCtrl.tags = _.filter(EditDynamiclinkCtrl.tags, function (tagObj) {
					return tagObj.tag !== 'Client.Www';
				});
			}
		};

		EditDynamiclinkCtrl.toggleNewWin = function () {
			if (EditDynamiclinkCtrl.openInNewWin) {
				EditDynamiclinkCtrl.link.target = '_blank';
			} else {
				EditDynamiclinkCtrl.link.target = '_self';
			}
		};

		EditDynamiclinkCtrl.copyToClipBoard = function (tag) {
			document.getElementById('tag-copy-element').value = '{{' + tag + '}}';
			document.getElementById('tag-copy-element').select();

			try {
				document.execCommand('copy');
				EditDynamiclinkCtrl.lastCopiedTag = tag;
			} catch (err) {
				console.log(err);
			}
		};

		// $scope.edit = d !== 'new';
		// $scope.link = (meta.link && meta.link.data )? meta.link.data : { entity: 'client', value: '', href: '', icon: '', color: '000000', target: '_blank'};
		$scope.entities = ['client', 'contact', 'appointment', 'activity', 'order', 'sidebar'];

		EditDynamiclinkCtrl.save = function () {
			if (EditDynamiclinkCtrl.link.color.length && EditDynamiclinkCtrl.link.color.indexOf('#') === 0) {
				EditDynamiclinkCtrl.link.color = EditDynamiclinkCtrl.link.color.replace('#', '');
			}

			if (EditDynamiclinkCtrl.link.icon) {
				EditDynamiclinkCtrl.link.icon = EditDynamiclinkCtrl.link.icon.id;
			}

			$scope.saving = true;
			Links.admin()
				.save(EditDynamiclinkCtrl.link)
				.then(function (res) {
					$scope.resolve(res.data);
				})
				.catch(function () {})
				.finally(function () {
					$scope.saving = false;
				});
		};

		EditDynamiclinkCtrl.toggleAvailableTags = function () {
			EditDynamiclinkCtrl.tagsVisible = !EditDynamiclinkCtrl.tagsVisible;

			if (EditDynamiclinkCtrl.tagsVisible) {
				$scope.addModalClass('form');
			} else {
				$scope.removeModalClass('form');
			}
		};

		var getIcons = function () {
			return [
				{ id: 'fa-glass', text: 'fa-glass' },
				{ id: 'fa-music', text: 'fa-music' },
				{ id: 'fa-search', text: 'fa-search' },
				{ id: 'fa-envelope-o', text: 'fa-envelope-o' },
				{ id: 'fa-heart', text: 'fa-heart' },
				{ id: 'fa-star', text: 'fa-star' },
				{ id: 'fa-star-o', text: 'fa-star-o' },
				{ id: 'fa-user', text: 'fa-user' },
				{ id: 'fa-film', text: 'fa-film' },
				{ id: 'fa-th-large', text: 'fa-th-large' },
				{ id: 'fa-th', text: 'fa-th' },
				{ id: 'fa-th-list', text: 'fa-th-list' },
				{ id: 'fa-check', text: 'fa-check' },
				{ id: 'fa-remove', text: 'fa-remove' },
				{ id: 'fa-search-plus', text: 'fa-search-plus' },
				{ id: 'fa-search-minus', text: 'fa-search-minus' },
				{ id: 'fa-power-off', text: 'fa-power-off' },
				{ id: 'fa-signal', text: 'fa-signal' },
				{ id: 'fa-gear', text: 'fa-gear' },
				{ id: 'fa-trash-o', text: 'fa-trash-o' },
				{ id: 'fa-home', text: 'fa-home' },
				{ id: 'fa-file-o', text: 'fa-file-o' },
				{ id: 'fa-clock-o', text: 'fa-clock-o' },
				{ id: 'fa-road', text: 'fa-road' },
				{ id: 'fa-download', text: 'fa-download' },
				{ id: 'fa-arrow-circle-o-down', text: 'fa-arrow-circle-o-down' },
				{ id: 'fa-arrow-circle-o-up', text: 'fa-arrow-circle-o-up' },
				{ id: 'fa-inbox', text: 'fa-inbox' },
				{ id: 'fa-play-circle-o', text: 'fa-play-circle-o' },
				{ id: 'fa-repeat', text: 'fa-repeat' },
				{ id: 'fa-refresh', text: 'fa-refresh' },
				{ id: 'fa-list-alt', text: 'fa-list-alt' },
				{ id: 'fa-lock', text: 'fa-lock' },
				{ id: 'fa-flag', text: 'fa-flag' },
				{ id: 'fa-headphones', text: 'fa-headphones' },
				{ id: 'fa-volume-off', text: 'fa-volume-off' },
				{ id: 'fa-volume-down', text: 'fa-volume-down' },
				{ id: 'fa-volume-up', text: 'fa-volume-up' },
				{ id: 'fa-qrcode', text: 'fa-qrcode' },
				{ id: 'fa-barcode', text: 'fa-barcode' },
				{ id: 'fa-tag', text: 'fa-tag' },
				{ id: 'fa-tags', text: 'fa-tags' },
				{ id: 'fa-book', text: 'fa-book' },
				{ id: 'fa-bookmark', text: 'fa-bookmark' },
				{ id: 'fa-print', text: 'fa-print' },
				{ id: 'fa-camera', text: 'fa-camera' },
				{ id: 'fa-font', text: 'fa-font' },
				{ id: 'fa-bold', text: 'fa-bold' },
				{ id: 'fa-italic', text: 'fa-italic' },
				{ id: 'fa-text-height', text: 'fa-text-height' },
				{ id: 'fa-text-width', text: 'fa-text-width' },
				{ id: 'fa-align-left', text: 'fa-align-left' },
				{ id: 'fa-align-center', text: 'fa-align-center' },
				{ id: 'fa-align-right', text: 'fa-align-right' },
				{ id: 'fa-align-justify', text: 'fa-align-justify' },
				{ id: 'fa-list', text: 'fa-list' },
				{ id: 'fa-outdent', text: 'fa-outdent' },
				{ id: 'fa-indent', text: 'fa-indent' },
				{ id: 'fa-video-camera', text: 'fa-video-camera' },
				{ id: 'fa-photo', text: 'fa-photo' },
				{ id: 'fa-pencil', text: 'fa-pencil' },
				{ id: 'fa-map-marker', text: 'fa-map-marker' },
				{ id: 'fa-adjust', text: 'fa-adjust' },
				{ id: 'fa-tint', text: 'fa-tint' },
				{ id: 'fa-edit', text: 'fa-edit' },
				{ id: 'fa-share-square-o', text: 'fa-share-square-o' },
				{ id: 'fa-check-square-o', text: 'fa-check-square-o' },
				{ id: 'fa-arrows', text: 'fa-arrows' },
				{ id: 'fa-step-backward', text: 'fa-step-backward' },
				{ id: 'fa-fast-backward', text: 'fa-fast-backward' },
				{ id: 'fa-backward', text: 'fa-backward' },
				{ id: 'fa-play', text: 'fa-play' },
				{ id: 'fa-pause', text: 'fa-pause' },
				{ id: 'fa-stop', text: 'fa-stop' },
				{ id: 'fa-forward', text: 'fa-forward' },
				{ id: 'fa-fast-forward', text: 'fa-fast-forward' },
				{ id: 'fa-step-forward', text: 'fa-step-forward' },
				{ id: 'fa-eject', text: 'fa-eject' },
				{ id: 'fa-chevron-left', text: 'fa-chevron-left' },
				{ id: 'fa-chevron-right', text: 'fa-chevron-right' },
				{ id: 'fa-plus-circle', text: 'fa-plus-circle' },
				{ id: 'fa-minus-circle', text: 'fa-minus-circle' },
				{ id: 'fa-times-circle', text: 'fa-times-circle' },
				{ id: 'fa-check-circle', text: 'fa-check-circle' },
				{ id: 'fa-question-circle', text: 'fa-question-circle' },
				{ id: 'fa-info-circle', text: 'fa-info-circle' },
				{ id: 'fa-crosshairs', text: 'fa-crosshairs' },
				{ id: 'fa-times-circle-o', text: 'fa-times-circle-o' },
				{ id: 'fa-check-circle-o', text: 'fa-check-circle-o' },
				{ id: 'fa-ban', text: 'fa-ban' },
				{ id: 'fa-arrow-left', text: 'fa-arrow-left' },
				{ id: 'fa-arrow-right', text: 'fa-arrow-right' },
				{ id: 'fa-arrow-up', text: 'fa-arrow-up' },
				{ id: 'fa-arrow-down', text: 'fa-arrow-down' },
				{ id: 'fa-share', text: 'fa-share' },
				{ id: 'fa-expand', text: 'fa-expand' },
				{ id: 'fa-compress', text: 'fa-compress' },
				{ id: 'fa-plus', text: 'fa-plus' },
				{ id: 'fa-minus', text: 'fa-minus' },
				{ id: 'fa-asterisk', text: 'fa-asterisk' },
				{ id: 'fa-exclamation-circle', text: 'fa-exclamation-circle' },
				{ id: 'fa-gift', text: 'fa-gift' },
				{ id: 'fa-leaf', text: 'fa-leaf' },
				{ id: 'fa-fire', text: 'fa-fire' },
				{ id: 'fa-eye', text: 'fa-eye' },
				{ id: 'fa-eye-slash', text: 'fa-eye-slash' },
				{ id: 'fa-warning', text: 'fa-warning' },
				{ id: 'fa-plane', text: 'fa-plane' },
				{ id: 'fa-calendar', text: 'fa-calendar' },
				{ id: 'fa-random', text: 'fa-random' },
				{ id: 'fa-comment', text: 'fa-comment' },
				{ id: 'fa-magnet', text: 'fa-magnet' },
				{ id: 'fa-chevron-up', text: 'fa-chevron-up' },
				{ id: 'fa-chevron-down', text: 'fa-chevron-down' },
				{ id: 'fa-retweet', text: 'fa-retweet' },
				{ id: 'fa-shopping-cart', text: 'fa-shopping-cart' },
				{ id: 'fa-folder', text: 'fa-folder' },
				{ id: 'fa-folder-open', text: 'fa-folder-open' },
				{ id: 'fa-arrows-v', text: 'fa-arrows-v' },
				{ id: 'fa-arrows-h', text: 'fa-arrows-h' },
				{ id: 'fa-bar-chart', text: 'fa-bar-chart' },
				{ id: 'fa-twitter-square', text: 'fa-twitter-square' },
				{ id: 'fa-facebook-square', text: 'fa-facebook-square' },
				{ id: 'fa-camera-retro', text: 'fa-camera-retro' },
				{ id: 'fa-key', text: 'fa-key' },
				{ id: 'fa-gears', text: 'fa-gears' },
				{ id: 'fa-comments', text: 'fa-comments' },
				{ id: 'fa-thumbs-o-up', text: 'fa-thumbs-o-up' },
				{ id: 'fa-thumbs-o-down', text: 'fa-thumbs-o-down' },
				{ id: 'fa-star-half', text: 'fa-star-half' },
				{ id: 'fa-heart-o', text: 'fa-heart-o' },
				{ id: 'fa-sign-out', text: 'fa-sign-out' },
				{ id: 'fa-linkedin-square', text: 'fa-linkedin-square' },
				{ id: 'fa-thumb-tack', text: 'fa-thumb-tack' },
				{ id: 'fa-external-link', text: 'fa-external-link' },
				{ id: 'fa-sign-in', text: 'fa-sign-in' },
				{ id: 'fa-trophy', text: 'fa-trophy' },
				{ id: 'fa-github-square', text: 'fa-github-square' },
				{ id: 'fa-upload', text: 'fa-upload' },
				{ id: 'fa-lemon-o', text: 'fa-lemon-o' },
				{ id: 'fa-phone', text: 'fa-phone' },
				{ id: 'fa-square-o', text: 'fa-square-o' },
				{ id: 'fa-bookmark-o', text: 'fa-bookmark-o' },
				{ id: 'fa-phone-square', text: 'fa-phone-square' },
				{ id: 'fa-twitter', text: 'fa-twitter' },
				{ id: 'fa-facebook', text: 'fa-facebook' },
				{ id: 'fa-github', text: 'fa-github' },
				{ id: 'fa-unlock', text: 'fa-unlock' },
				{ id: 'fa-credit-card', text: 'fa-credit-card' },
				{ id: 'fa-feed', text: 'fa-feed' },
				{ id: 'fa-hdd-o', text: 'fa-hdd-o' },
				{ id: 'fa-bullhorn', text: 'fa-bullhorn' },
				{ id: 'fa-bell', text: 'fa-bell' },
				{ id: 'fa-certificate', text: 'fa-certificate' },
				{ id: 'fa-hand-o-right', text: 'fa-hand-o-right' },
				{ id: 'fa-hand-o-left', text: 'fa-hand-o-left' },
				{ id: 'fa-hand-o-up', text: 'fa-hand-o-up' },
				{ id: 'fa-hand-o-down', text: 'fa-hand-o-down' },
				{ id: 'fa-arrow-circle-left', text: 'fa-arrow-circle-left' },
				{ id: 'fa-arrow-circle-right', text: 'fa-arrow-circle-right' },
				{ id: 'fa-arrow-circle-up', text: 'fa-arrow-circle-up' },
				{ id: 'fa-arrow-circle-down', text: 'fa-arrow-circle-down' },
				{ id: 'fa-globe', text: 'fa-globe' },
				{ id: 'fa-wrench', text: 'fa-wrench' },
				{ id: 'fa-tasks', text: 'fa-tasks' },
				{ id: 'fa-filter', text: 'fa-filter' },
				{ id: 'fa-briefcase', text: 'fa-briefcase' },
				{ id: 'fa-arrows-alt', text: 'fa-arrows-alt' },
				{ id: 'fa-users', text: 'fa-users' },
				{ id: 'fa-link', text: 'fa-link' },
				{ id: 'fa-cloud', text: 'fa-cloud' },
				{ id: 'fa-flask', text: 'fa-flask' },
				{ id: 'fa-cut', text: 'fa-cut' },
				{ id: 'fa-copy', text: 'fa-copy' },
				{ id: 'fa-paperclip', text: 'fa-paperclip' },
				{ id: 'fa-save', text: 'fa-save' },
				{ id: 'fa-square', text: 'fa-square' },
				{ id: 'fa-bars', text: 'fa-bars' },
				{ id: 'fa-list-ul', text: 'fa-list-ul' },
				{ id: 'fa-list-ol', text: 'fa-list-ol' },
				{ id: 'fa-strikethrough', text: 'fa-strikethrough' },
				{ id: 'fa-underline', text: 'fa-underline' },
				{ id: 'fa-table', text: 'fa-table' },
				{ id: 'fa-magic', text: 'fa-magic' },
				{ id: 'fa-truck', text: 'fa-truck' },
				{ id: 'fa-pinterest', text: 'fa-pinterest' },
				{ id: 'fa-pinterest-square', text: 'fa-pinterest-square' },
				{ id: 'fa-google-plus-square', text: 'fa-google-plus-square' },
				{ id: 'fa-google-plus', text: 'fa-google-plus' },
				{ id: 'fa-money', text: 'fa-money' },
				{ id: 'fa-caret-down', text: 'fa-caret-down' },
				{ id: 'fa-caret-up', text: 'fa-caret-up' },
				{ id: 'fa-caret-left', text: 'fa-caret-left' },
				{ id: 'fa-caret-right', text: 'fa-caret-right' },
				{ id: 'fa-columns', text: 'fa-columns' },
				{ id: 'fa-sort', text: 'fa-sort' },
				{ id: 'fa-sort-down', text: 'fa-sort-down' },
				{ id: 'fa-sort-up', text: 'fa-sort-up' },
				{ id: 'fa-envelope', text: 'fa-envelope' },
				{ id: 'fa-linkedin', text: 'fa-linkedin' },
				{ id: 'fa-undo', text: 'fa-undo' },
				{ id: 'fa-gavel', text: 'fa-gavel' },
				{ id: 'fa-dashboard', text: 'fa-dashboard' },
				{ id: 'fa-comment-o', text: 'fa-comment-o' },
				{ id: 'fa-comments-o', text: 'fa-comments-o' },
				{ id: 'fa-flash', text: 'fa-flash' },
				{ id: 'fa-sitemap', text: 'fa-sitemap' },
				{ id: 'fa-umbrella', text: 'fa-umbrella' },
				{ id: 'fa-paste', text: 'fa-paste' },
				{ id: 'fa-lightbulb-o', text: 'fa-lightbulb-o' },
				{ id: 'fa-exchange', text: 'fa-exchange' },
				{ id: 'fa-cloud-download', text: 'fa-cloud-download' },
				{ id: 'fa-cloud-upload', text: 'fa-cloud-upload' },
				{ id: 'fa-user-md', text: 'fa-user-md' },
				{ id: 'fa-stethoscope', text: 'fa-stethoscope' },
				{ id: 'fa-suitcase', text: 'fa-suitcase' },
				{ id: 'fa-bell-o', text: 'fa-bell-o' },
				{ id: 'fa-coffee', text: 'fa-coffee' },
				{ id: 'fa-cutlery', text: 'fa-cutlery' },
				{ id: 'fa-file-text-o', text: 'fa-file-text-o' },
				{ id: 'fa-building-o', text: 'fa-building-o' },
				{ id: 'fa-hospital-o', text: 'fa-hospital-o' },
				{ id: 'fa-ambulance', text: 'fa-ambulance' },
				{ id: 'fa-medkit', text: 'fa-medkit' },
				{ id: 'fa-fighter-jet', text: 'fa-fighter-jet' },
				{ id: 'fa-beer', text: 'fa-beer' },
				{ id: 'fa-h-square', text: 'fa-h-square' },
				{ id: 'fa-plus-square', text: 'fa-plus-square' },
				{ id: 'fa-angle-double-left', text: 'fa-angle-double-left' },
				{ id: 'fa-angle-double-right', text: 'fa-angle-double-right' },
				{ id: 'fa-angle-double-up', text: 'fa-angle-double-up' },
				{ id: 'fa-angle-double-down', text: 'fa-angle-double-down' },
				{ id: 'fa-angle-left', text: 'fa-angle-left' },
				{ id: 'fa-angle-right', text: 'fa-angle-right' },
				{ id: 'fa-angle-up', text: 'fa-angle-up' },
				{ id: 'fa-angle-down', text: 'fa-angle-down' },
				{ id: 'fa-desktop', text: 'fa-desktop' },
				{ id: 'fa-laptop', text: 'fa-laptop' },
				{ id: 'fa-tablet', text: 'fa-tablet' },
				{ id: 'fa-mobile', text: 'fa-mobile' },
				{ id: 'fa-circle-o', text: 'fa-circle-o' },
				{ id: 'fa-quote-left', text: 'fa-quote-left' },
				{ id: 'fa-quote-right', text: 'fa-quote-right' },
				{ id: 'fa-spinner', text: 'fa-spinner' },
				{ id: 'fa-circle', text: 'fa-circle' },
				{ id: 'fa-reply', text: 'fa-reply' },
				{ id: 'fa-github-alt', text: 'fa-github-alt' },
				{ id: 'fa-folder-o', text: 'fa-folder-o' },
				{ id: 'fa-folder-open-o', text: 'fa-folder-open-o' },
				{ id: 'fa-smile-o', text: 'fa-smile-o' },
				{ id: 'fa-frown-o', text: 'fa-frown-o' },
				{ id: 'fa-meh-o', text: 'fa-meh-o' },
				{ id: 'fa-gamepad', text: 'fa-gamepad' },
				{ id: 'fa-keyboard-o', text: 'fa-keyboard-o' },
				{ id: 'fa-flag-o', text: 'fa-flag-o' },
				{ id: 'fa-flag-checkered', text: 'fa-flag-checkered' },
				{ id: 'fa-terminal', text: 'fa-terminal' },
				{ id: 'fa-code', text: 'fa-code' },
				{ id: 'fa-reply-all', text: 'fa-reply-all' },
				{ id: 'fa-star-half-empty', text: 'fa-star-half-empty' },
				{ id: 'fa-location-arrow', text: 'fa-location-arrow' },
				{ id: 'fa-crop', text: 'fa-crop' },
				{ id: 'fa-code-fork', text: 'fa-code-fork' },
				{ id: 'fa-unlink', text: 'fa-unlink' },
				{ id: 'fa-question', text: 'fa-question' },
				{ id: 'fa-info', text: 'fa-info' },
				{ id: 'fa-exclamation', text: 'fa-exclamation' },
				{ id: 'fa-superscript', text: 'fa-superscript' },
				{ id: 'fa-subscript', text: 'fa-subscript' },
				{ id: 'fa-eraser', text: 'fa-eraser' },
				{ id: 'fa-puzzle-piece', text: 'fa-puzzle-piece' },
				{ id: 'fa-microphone', text: 'fa-microphone' },
				{ id: 'fa-microphone-slash', text: 'fa-microphone-slash' },
				{ id: 'fa-shield', text: 'fa-shield' },
				{ id: 'fa-calendar-o', text: 'fa-calendar-o' },
				{ id: 'fa-fire-extinguisher', text: 'fa-fire-extinguisher' },
				{ id: 'fa-rocket', text: 'fa-rocket' },
				{ id: 'fa-maxcdn', text: 'fa-maxcdn' },
				{ id: 'fa-chevron-circle-left', text: 'fa-chevron-circle-left' },
				{ id: 'fa-chevron-circle-right', text: 'fa-chevron-circle-right' },
				{ id: 'fa-chevron-circle-up', text: 'fa-chevron-circle-up' },
				{ id: 'fa-chevron-circle-down', text: 'fa-chevron-circle-down' },
				{ id: 'fa-html5', text: 'fa-html5' },
				{ id: 'fa-css3', text: 'fa-css3' },
				{ id: 'fa-anchor', text: 'fa-anchor' },
				{ id: 'fa-unlock-alt', text: 'fa-unlock-alt' },
				{ id: 'fa-bullseye', text: 'fa-bullseye' },
				{ id: 'fa-ellipsis-h', text: 'fa-ellipsis-h' },
				{ id: 'fa-ellipsis-v', text: 'fa-ellipsis-v' },
				{ id: 'fa-rss-square', text: 'fa-rss-square' },
				{ id: 'fa-play-circle', text: 'fa-play-circle' },
				{ id: 'fa-ticket', text: 'fa-ticket' },
				{ id: 'fa-minus-square', text: 'fa-minus-square' },
				{ id: 'fa-minus-square-o', text: 'fa-minus-square-o' },
				{ id: 'fa-level-up', text: 'fa-level-up' },
				{ id: 'fa-level-down', text: 'fa-level-down' },
				{ id: 'fa-check-square', text: 'fa-check-square' },
				{ id: 'fa-pencil-square', text: 'fa-pencil-square' },
				{ id: 'fa-external-link-square', text: 'fa-external-link-square' },
				{ id: 'fa-share-square', text: 'fa-share-square' },
				{ id: 'fa-compass', text: 'fa-compass' },
				{ id: 'fa-toggle-down', text: 'fa-toggle-down' },
				{ id: 'fa-toggle-up', text: 'fa-toggle-up' },
				{ id: 'fa-toggle-right', text: 'fa-toggle-right' },
				{ id: 'fa-euro', text: 'fa-euro' },
				{ id: 'fa-gbp', text: 'fa-gbp' },
				{ id: 'fa-usd', text: 'fa-usd' },
				{ id: 'fa-inr', text: 'fa-inr' },
				{ id: 'fa-yen', text: 'fa-yen' },
				{ id: 'fa-rub', text: 'fa-rub' },
				{ id: 'fa-won', text: 'fa-won' },
				{ id: 'fa-btc', text: 'fa-btc' },
				{ id: 'fa-file', text: 'fa-file' },
				{ id: 'fa-file-text', text: 'fa-file-text' },
				{ id: 'fa-sort-alpha-asc', text: 'fa-sort-alpha-asc' },
				{ id: 'fa-sort-alpha-desc', text: 'fa-sort-alpha-desc' },
				{ id: 'fa-sort-amount-asc', text: 'fa-sort-amount-asc' },
				{ id: 'fa-sort-amount-desc', text: 'fa-sort-amount-desc' },
				{ id: 'fa-sort-numeric-asc', text: 'fa-sort-numeric-asc' },
				{ id: 'fa-sort-numeric-desc', text: 'fa-sort-numeric-desc' },
				{ id: 'fa-thumbs-up', text: 'fa-thumbs-up' },
				{ id: 'fa-thumbs-down', text: 'fa-thumbs-down' },
				{ id: 'fa-youtube-square', text: 'fa-youtube-square' },
				{ id: 'fa-youtube', text: 'fa-youtube' },
				{ id: 'fa-xing', text: 'fa-xing' },
				{ id: 'fa-xing-square', text: 'fa-xing-square' },
				{ id: 'fa-youtube-play', text: 'fa-youtube-play' },
				{ id: 'fa-dropbox', text: 'fa-dropbox' },
				{ id: 'fa-stack-overflow', text: 'fa-stack-overflow' },
				{ id: 'fa-instagram', text: 'fa-instagram' },
				{ id: 'fa-flickr', text: 'fa-flickr' },
				{ id: 'fa-adn', text: 'fa-adn' },
				{ id: 'fa-bitbucket', text: 'fa-bitbucket' },
				{ id: 'fa-bitbucket-square', text: 'fa-bitbucket-square' },
				{ id: 'fa-tumblr', text: 'fa-tumblr' },
				{ id: 'fa-tumblr-square', text: 'fa-tumblr-square' },
				{ id: 'fa-long-arrow-down', text: 'fa-long-arrow-down' },
				{ id: 'fa-long-arrow-up', text: 'fa-long-arrow-up' },
				{ id: 'fa-long-arrow-left', text: 'fa-long-arrow-left' },
				{ id: 'fa-long-arrow-right', text: 'fa-long-arrow-right' },
				{ id: 'fa-apple', text: 'fa-apple' },
				{ id: 'fa-windows', text: 'fa-windows' },
				{ id: 'fa-android', text: 'fa-android' },
				{ id: 'fa-linux', text: 'fa-linux' },
				{ id: 'fa-dribbble', text: 'fa-dribbble' },
				{ id: 'fa-skype', text: 'fa-skype' },
				{ id: 'fa-foursquare', text: 'fa-foursquare' },
				{ id: 'fa-trello', text: 'fa-trello' },
				{ id: 'fa-female', text: 'fa-female' },
				{ id: 'fa-male', text: 'fa-male' },
				{ id: 'fa-gittip', text: 'fa-gittip' },
				{ id: 'fa-sun-o', text: 'fa-sun-o' },
				{ id: 'fa-moon-o', text: 'fa-moon-o' },
				{ id: 'fa-archive', text: 'fa-archive' },
				{ id: 'fa-bug', text: 'fa-bug' },
				{ id: 'fa-vk', text: 'fa-vk' },
				{ id: 'fa-weibo', text: 'fa-weibo' },
				{ id: 'fa-renren', text: 'fa-renren' },
				{ id: 'fa-pagelines', text: 'fa-pagelines' },
				{ id: 'fa-stack-exchange', text: 'fa-stack-exchange' },
				{ id: 'fa-arrow-circle-o-right', text: 'fa-arrow-circle-o-right' },
				{ id: 'fa-arrow-circle-o-left', text: 'fa-arrow-circle-o-left' },
				{ id: 'fa-toggle-left', text: 'fa-toggle-left' },
				{ id: 'fa-dot-circle-o', text: 'fa-dot-circle-o' },
				{ id: 'fa-wheelchair', text: 'fa-wheelchair' },
				{ id: 'fa-vimeo-square', text: 'fa-vimeo-square' },
				{ id: 'fa-turkish-lira', text: 'fa-turkish-lira' },
				{ id: 'fa-try', text: 'fa-try' },
				{ id: 'fa-plus-square-o', text: 'fa-plus-square-o' },
				{ id: 'fa-space-shuttle', text: 'fa-space-shuttle' },
				{ id: 'fa-slack', text: 'fa-slack' },
				{ id: 'fa-envelope-square', text: 'fa-envelope-square' },
				{ id: 'fa-wordpress', text: 'fa-wordpress' },
				{ id: 'fa-openid', text: 'fa-openid' },
				{ id: 'fa-bank', text: 'fa-bank' },
				{ id: 'fa-mortar-board', text: 'fa-mortar-board' },
				{ id: 'fa-yahoo', text: 'fa-yahoo' },
				{ id: 'fa-google', text: 'fa-google' },
				{ id: 'fa-reddit', text: 'fa-reddit' },
				{ id: 'fa-reddit-square', text: 'fa-reddit-square' },
				{ id: 'fa-stumbleupon-circle', text: 'fa-stumbleupon-circle' },
				{ id: 'fa-stumbleupon', text: 'fa-stumbleupon' },
				{ id: 'fa-delicious', text: 'fa-delicious' },
				{ id: 'fa-digg', text: 'fa-digg' },
				{ id: 'fa-pied-piper-pp', text: 'fa-pied-piper-pp' },
				{ id: 'fa-pied-piper-alt', text: 'fa-pied-piper-alt' },
				{ id: 'fa-drupal', text: 'fa-drupal' },
				{ id: 'fa-joomla', text: 'fa-joomla' },
				{ id: 'fa-language', text: 'fa-language' },
				{ id: 'fa-fax', text: 'fa-fax' },
				{ id: 'fa-building', text: 'fa-building' },
				{ id: 'fa-child', text: 'fa-child' },
				{ id: 'fa-paw', text: 'fa-paw' },
				{ id: 'fa-spoon', text: 'fa-spoon' },
				{ id: 'fa-cube', text: 'fa-cube' },
				{ id: 'fa-cubes', text: 'fa-cubes' },
				{ id: 'fa-behance', text: 'fa-behance' },
				{ id: 'fa-behance-square', text: 'fa-behance-square' },
				{ id: 'fa-steam', text: 'fa-steam' },
				{ id: 'fa-steam-square', text: 'fa-steam-square' },
				{ id: 'fa-recycle', text: 'fa-recycle' },
				{ id: 'fa-car', text: 'fa-car' },
				{ id: 'fa-cab', text: 'fa-cab' },
				{ id: 'fa-tree', text: 'fa-tree' },
				{ id: 'fa-spotify', text: 'fa-spotify' },
				{ id: 'fa-deviantart', text: 'fa-deviantart' },
				{ id: 'fa-soundcloud', text: 'fa-soundcloud' },
				{ id: 'fa-database', text: 'fa-database' },
				{ id: 'fa-file-pdf-o', text: 'fa-file-pdf-o' },
				{ id: 'fa-file-word-o', text: 'fa-file-word-o' },
				{ id: 'fa-file-excel-o', text: 'fa-file-excel-o' },
				{ id: 'fa-file-powerpoint-o', text: 'fa-file-powerpoint-o' },
				{ id: 'fa-file-picture-o', text: 'fa-file-picture-o' },
				{ id: 'fa-file-zip-o', text: 'fa-file-zip-o' },
				{ id: 'fa-file-sound-o', text: 'fa-file-sound-o' },
				{ id: 'fa-file-movie-o', text: 'fa-file-movie-o' },
				{ id: 'fa-file-code-o', text: 'fa-file-code-o' },
				{ id: 'fa-vine', text: 'fa-vine' },
				{ id: 'fa-codepen', text: 'fa-codepen' },
				{ id: 'fa-jsfiddle', text: 'fa-jsfiddle' },
				{ id: 'fa-life-ring', text: 'fa-life-ring' },
				{ id: 'fa-circle-o-notch', text: 'fa-circle-o-notch' },
				{ id: 'fa-rebel', text: 'fa-rebel' },
				{ id: 'fa-empire', text: 'fa-empire' },
				{ id: 'fa-git-square', text: 'fa-git-square' },
				{ id: 'fa-git', text: 'fa-git' },
				{ id: 'fa-hacker-news', text: 'fa-hacker-news' },
				{ id: 'fa-tencent-weibo', text: 'fa-tencent-weibo' },
				{ id: 'fa-qq', text: 'fa-qq' },
				{ id: 'fa-wechat', text: 'fa-wechat' },
				{ id: 'fa-send', text: 'fa-send' },
				{ id: 'fa-send-o', text: 'fa-send-o' },
				{ id: 'fa-history', text: 'fa-history' },
				{ id: 'fa-circle-thin', text: 'fa-circle-thin' },
				{ id: 'fa-header', text: 'fa-header' },
				{ id: 'fa-paragraph', text: 'fa-paragraph' },
				{ id: 'fa-sliders', text: 'fa-sliders' },
				{ id: 'fa-share-alt', text: 'fa-share-alt' },
				{ id: 'fa-share-alt-square', text: 'fa-share-alt-square' },
				{ id: 'fa-bomb', text: 'fa-bomb' },
				{ id: 'fa-soccer-ball-o', text: 'fa-soccer-ball-o' },
				{ id: 'fa-tty', text: 'fa-tty' },
				{ id: 'fa-binoculars', text: 'fa-binoculars' },
				{ id: 'fa-plug', text: 'fa-plug' },
				{ id: 'fa-slideshare', text: 'fa-slideshare' },
				{ id: 'fa-twitch', text: 'fa-twitch' },
				{ id: 'fa-yelp', text: 'fa-yelp' },
				{ id: 'fa-newspaper-o', text: 'fa-newspaper-o' },
				{ id: 'fa-wifi', text: 'fa-wifi' },
				{ id: 'fa-calculator', text: 'fa-calculator' },
				{ id: 'fa-paypal', text: 'fa-paypal' },
				{ id: 'fa-google-wallet', text: 'fa-google-wallet' },
				{ id: 'fa-cc-visa', text: 'fa-cc-visa' },
				{ id: 'fa-cc-mastercard', text: 'fa-cc-mastercard' },
				{ id: 'fa-cc-discover', text: 'fa-cc-discover' },
				{ id: 'fa-cc-amex', text: 'fa-cc-amex' },
				{ id: 'fa-cc-paypal', text: 'fa-cc-paypal' },
				{ id: 'fa-cc-stripe', text: 'fa-cc-stripe' },
				{ id: 'fa-bell-slash', text: 'fa-bell-slash' },
				{ id: 'fa-bell-slash-o', text: 'fa-bell-slash-o' },
				{ id: 'fa-trash', text: 'fa-trash' },
				{ id: 'fa-copyright', text: 'fa-copyright' },
				{ id: 'fa-at', text: 'fa-at' },
				{ id: 'fa-eyedropper', text: 'fa-eyedropper' },
				{ id: 'fa-paint-brush', text: 'fa-paint-brush' },
				{ id: 'fa-birthday-cake', text: 'fa-birthday-cake' },
				{ id: 'fa-area-chart', text: 'fa-area-chart' },
				{ id: 'fa-pie-chart', text: 'fa-pie-chart' },
				{ id: 'fa-line-chart', text: 'fa-line-chart' },
				{ id: 'fa-lastfm', text: 'fa-lastfm' },
				{ id: 'fa-lastfm-square', text: 'fa-lastfm-square' },
				{ id: 'fa-toggle-off', text: 'fa-toggle-off' },
				{ id: 'fa-toggle-on', text: 'fa-toggle-on' },
				{ id: 'fa-bicycle', text: 'fa-bicycle' },
				{ id: 'fa-bus', text: 'fa-bus' },
				{ id: 'fa-ioxhost', text: 'fa-ioxhost' },
				{ id: 'fa-angellist', text: 'fa-angellist' },
				{ id: 'fa-cc', text: 'fa-cc' },
				{ id: 'fa-shekel', text: 'fa-shekel' },
				{ id: 'fa-meanpath', text: 'fa-meanpath' },
				{ id: 'fa-buysellads', text: 'fa-buysellads' },
				{ id: 'fa-connectdevelop', text: 'fa-connectdevelop' },
				{ id: 'fa-dashcube', text: 'fa-dashcube' },
				{ id: 'fa-forumbee', text: 'fa-forumbee' },
				{ id: 'fa-leanpub', text: 'fa-leanpub' },
				{ id: 'fa-sellsy', text: 'fa-sellsy' },
				{ id: 'fa-shirtsinbulk', text: 'fa-shirtsinbulk' },
				{ id: 'fa-simplybuilt', text: 'fa-simplybuilt' },
				{ id: 'fa-skyatlas', text: 'fa-skyatlas' },
				{ id: 'fa-cart-plus', text: 'fa-cart-plus' },
				{ id: 'fa-cart-arrow-down', text: 'fa-cart-arrow-down' },
				{ id: 'fa-diamond', text: 'fa-diamond' },
				{ id: 'fa-ship', text: 'fa-ship' },
				{ id: 'fa-user-secret', text: 'fa-user-secret' },
				{ id: 'fa-motorcycle', text: 'fa-motorcycle' },
				{ id: 'fa-street-view', text: 'fa-street-view' },
				{ id: 'fa-heartbeat', text: 'fa-heartbeat' },
				{ id: 'fa-venus', text: 'fa-venus' },
				{ id: 'fa-mars', text: 'fa-mars' },
				{ id: 'fa-mercury', text: 'fa-mercury' },
				{ id: 'fa-transgender', text: 'fa-transgender' },
				{ id: 'fa-transgender-alt', text: 'fa-transgender-alt' },
				{ id: 'fa-venus-double', text: 'fa-venus-double' },
				{ id: 'fa-mars-double', text: 'fa-mars-double' },
				{ id: 'fa-venus-mars', text: 'fa-venus-mars' },
				{ id: 'fa-mars-stroke', text: 'fa-mars-stroke' },
				{ id: 'fa-mars-stroke-v', text: 'fa-mars-stroke-v' },
				{ id: 'fa-mars-stroke-h', text: 'fa-mars-stroke-h' },
				{ id: 'fa-neuter', text: 'fa-neuter' },
				{ id: 'fa-genderless', text: 'fa-genderless' },
				{ id: 'fa-facebook-official', text: 'fa-facebook-official' },
				{ id: 'fa-pinterest-p', text: 'fa-pinterest-p' },
				{ id: 'fa-whatsapp', text: 'fa-whatsapp' },
				{ id: 'fa-server', text: 'fa-server' },
				{ id: 'fa-user-plus', text: 'fa-user-plus' },
				{ id: 'fa-user-times', text: 'fa-user-times' },
				{ id: 'fa-bed', text: 'fa-bed' },
				{ id: 'fa-viacoin', text: 'fa-viacoin' },
				{ id: 'fa-train', text: 'fa-train' },
				{ id: 'fa-subway', text: 'fa-subway' },
				{ id: 'fa-medium', text: 'fa-medium' },
				{ id: 'fa-yc', text: 'fa-yc' },
				{ id: 'fa-optin-monster', text: 'fa-optin-monster' },
				{ id: 'fa-opencart', text: 'fa-opencart' },
				{ id: 'fa-expeditedssl', text: 'fa-expeditedssl' },
				{ id: 'fa-battery', text: 'fa-battery' },
				{ id: 'fa-battery-three-quarters', text: 'fa-battery-three-quarters' },
				{ id: 'fa-battery-half', text: 'fa-battery-half' },
				{ id: 'fa-battery-quarter', text: 'fa-battery-quarter' },
				{ id: 'fa-battery-empty', text: 'fa-battery-empty' },
				{ id: 'fa-mouse-pointer', text: 'fa-mouse-pointer' },
				{ id: 'fa-i-cursor', text: 'fa-i-cursor' },
				{ id: 'fa-object-group', text: 'fa-object-group' },
				{ id: 'fa-object-ungroup', text: 'fa-object-ungroup' },
				{ id: 'fa-sticky-note', text: 'fa-sticky-note' },
				{ id: 'fa-sticky-note-o', text: 'fa-sticky-note-o' },
				{ id: 'fa-cc-jcb', text: 'fa-cc-jcb' },
				{ id: 'fa-cc-diners-club', text: 'fa-cc-diners-club' },
				{ id: 'fa-clone', text: 'fa-clone' },
				{ id: 'fa-balance-scale', text: 'fa-balance-scale' },
				{ id: 'fa-hourglass-o', text: 'fa-hourglass-o' },
				{ id: 'fa-hourglass-start', text: 'fa-hourglass-start' },
				{ id: 'fa-hourglass-half', text: 'fa-hourglass-half' },
				{ id: 'fa-hourglass-end', text: 'fa-hourglass-end' },
				{ id: 'fa-hourglass', text: 'fa-hourglass' },
				{ id: 'fa-hand-rock-o', text: 'fa-hand-rock-o' },
				{ id: 'fa-hand-paper-o', text: 'fa-hand-paper-o' },
				{ id: 'fa-hand-scissors-o', text: 'fa-hand-scissors-o' },
				{ id: 'fa-hand-lizard-o', text: 'fa-hand-lizard-o' },
				{ id: 'fa-hand-spock-o', text: 'fa-hand-spock-o' },
				{ id: 'fa-hand-pointer-o', text: 'fa-hand-pointer-o' },
				{ id: 'fa-hand-peace-o', text: 'fa-hand-peace-o' },
				{ id: 'fa-trademark', text: 'fa-trademark' },
				{ id: 'fa-registered', text: 'fa-registered' },
				{ id: 'fa-creative-commons', text: 'fa-creative-commons' },
				{ id: 'fa-gg', text: 'fa-gg' },
				{ id: 'fa-gg-circle', text: 'fa-gg-circle' },
				{ id: 'fa-tripadvisor', text: 'fa-tripadvisor' },
				{ id: 'fa-odnoklassniki', text: 'fa-odnoklassniki' },
				{ id: 'fa-odnoklassniki-square', text: 'fa-odnoklassniki-square' },
				{ id: 'fa-get-pocket', text: 'fa-get-pocket' },
				{ id: 'fa-wikipedia-w', text: 'fa-wikipedia-w' },
				{ id: 'fa-safari', text: 'fa-safari' },
				{ id: 'fa-chrome', text: 'fa-chrome' },
				{ id: 'fa-firefox', text: 'fa-firefox' },
				{ id: 'fa-opera', text: 'fa-opera' },
				{ id: 'fa-internet-explorer', text: 'fa-internet-explorer' },
				{ id: 'fa-tv', text: 'fa-tv' },
				{ id: 'fa-contao', text: 'fa-contao' },
				{ id: 'fa-500px', text: 'fa-500px' },
				{ id: 'fa-amazon', text: 'fa-amazon' },
				{ id: 'fa-calendar-plus-o', text: 'fa-calendar-plus-o' },
				{ id: 'fa-calendar-minus-o', text: 'fa-calendar-minus-o' },
				{ id: 'fa-calendar-times-o', text: 'fa-calendar-times-o' },
				{ id: 'fa-calendar-check-o', text: 'fa-calendar-check-o' },
				{ id: 'fa-industry', text: 'fa-industry' },
				{ id: 'fa-map-pin', text: 'fa-map-pin' },
				{ id: 'fa-map-signs', text: 'fa-map-signs' },
				{ id: 'fa-map-o', text: 'fa-map-o' },
				{ id: 'fa-map', text: 'fa-map' },
				{ id: 'fa-commenting', text: 'fa-commenting' },
				{ id: 'fa-commenting-o', text: 'fa-commenting-o' },
				{ id: 'fa-houzz', text: 'fa-houzz' },
				{ id: 'fa-vimeo', text: 'fa-vimeo' },
				{ id: 'fa-black-tie', text: 'fa-black-tie' },
				{ id: 'fa-fonticons', text: 'fa-fonticons' },
				{ id: 'fa-reddit-alien', text: 'fa-reddit-alien' },
				{ id: 'fa-edge', text: 'fa-edge' },
				{ id: 'fa-credit-card-alt', text: 'fa-credit-card-alt' },
				{ id: 'fa-codiepie', text: 'fa-codiepie' },
				{ id: 'fa-modx', text: 'fa-modx' },
				{ id: 'fa-fort-awesome', text: 'fa-fort-awesome' },
				{ id: 'fa-usb', text: 'fa-usb' },
				{ id: 'fa-product-hunt', text: 'fa-product-hunt' },
				{ id: 'fa-mixcloud', text: 'fa-mixcloud' },
				{ id: 'fa-scribd', text: 'fa-scribd' },
				{ id: 'fa-pause-circle', text: 'fa-pause-circle' },
				{ id: 'fa-pause-circle-o', text: 'fa-pause-circle-o' },
				{ id: 'fa-stop-circle', text: 'fa-stop-circle' },
				{ id: 'fa-stop-circle-o', text: 'fa-stop-circle-o' },
				{ id: 'fa-shopping-bag', text: 'fa-shopping-bag' },
				{ id: 'fa-shopping-basket', text: 'fa-shopping-basket' },
				{ id: 'fa-hashtag', text: 'fa-hashtag' },
				{ id: 'fa-bluetooth', text: 'fa-bluetooth' },
				{ id: 'fa-bluetooth-b', text: 'fa-bluetooth-b' },
				{ id: 'fa-percent', text: 'fa-percent' },
				{ id: 'fa-gitlab', text: 'fa-gitlab' },
				{ id: 'fa-wpbeginner', text: 'fa-wpbeginner' },
				{ id: 'fa-wpforms', text: 'fa-wpforms' },
				{ id: 'fa-envira', text: 'fa-envira' },
				{ id: 'fa-universal-access', text: 'fa-universal-access' },
				{ id: 'fa-wheelchair-alt', text: 'fa-wheelchair-alt' },
				{ id: 'fa-question-circle-o', text: 'fa-question-circle-o' },
				{ id: 'fa-blind', text: 'fa-blind' },
				{ id: 'fa-audio-description', text: 'fa-audio-description' },
				{ id: 'fa-volume-control-phone', text: 'fa-volume-control-phone' },
				{ id: 'fa-braille', text: 'fa-braille' },
				{ id: 'fa-assistive-listening-systems', text: 'fa-assistive-listening-systems' },
				{ id: 'fa-asl-interpreting', text: 'fa-asl-interpreting' },
				{ id: 'fa-deaf', text: 'fa-deaf' },
				{ id: 'fa-glide', text: 'fa-glide' },
				{ id: 'fa-glide-g', text: 'fa-glide-g' },
				{ id: 'fa-signing', text: 'fa-signing' },
				{ id: 'fa-low-vision', text: 'fa-low-vision' },
				{ id: 'fa-viadeo', text: 'fa-viadeo' },
				{ id: 'fa-viadeo-square', text: 'fa-viadeo-square' },
				{ id: 'fa-snapchat', text: 'fa-snapchat' },
				{ id: 'fa-snapchat-ghost', text: 'fa-snapchat-ghost' },
				{ id: 'fa-snapchat-square', text: 'fa-snapchat-square' },
				{ id: 'fa-pied-piper', text: 'fa-pied-piper' },
				{ id: 'fa-first-order', text: 'fa-first-order' },
				{ id: 'fa-yoast', text: 'fa-yoast' },
				{ id: 'fa-themeisle', text: 'fa-themeisle' },
				{ id: 'fa-google-plus-official', text: 'fa-google-plus-official' },
				{ id: 'fa-fa', text: 'fa-fa' },
				{ id: 'fa-handshake-o', text: 'fa-handshake-o' },
				{ id: 'fa-envelope-open', text: 'fa-envelope-open' },
				{ id: 'fa-envelope-open-o', text: 'fa-envelope-open-o' },
				{ id: 'fa-linode', text: 'fa-linode' },
				{ id: 'fa-address-book', text: 'fa-address-book' },
				{ id: 'fa-address-book-o', text: 'fa-address-book-o' },
				{ id: 'fa-address-card', text: 'fa-address-card' },
				{ id: 'fa-address-card-o', text: 'fa-address-card-o' },
				{ id: 'fa-user-circle', text: 'fa-user-circle' },
				{ id: 'fa-user-circle-o', text: 'fa-user-circle-o' },
				{ id: 'fa-user-o', text: 'fa-user-o' },
				{ id: 'fa-id-badge', text: 'fa-id-badge' },
				{ id: 'fa-id-card', text: 'fa-id-card' },
				{ id: 'fa-id-card-o', text: 'fa-id-card-o' },
				{ id: 'fa-quora', text: 'fa-quora' },
				{ id: 'fa-free-code-camp', text: 'fa-free-code-camp' },
				{ id: 'fa-telegram', text: 'fa-telegram' },
				{ id: 'fa-thermometer-full', text: 'fa-thermometer-full' },
				{ id: 'fa-thermometer-three-quarters', text: 'fa-thermometer-three-quarters' },
				{ id: 'fa-thermometer-half', text: 'fa-thermometer-half' },
				{ id: 'fa-thermometer-quarter', text: 'fa-thermometer-quarter' },
				{ id: 'fa-thermometer-empty', text: 'fa-thermometer-empty' },
				{ id: 'fa-shower', text: 'fa-shower' },
				{ id: 'fa-bathtub', text: 'fa-bathtub' },
				{ id: 'fa-podcast', text: 'fa-podcast' },
				{ id: 'fa-window-maximize', text: 'fa-window-maximize' },
				{ id: 'fa-window-minimize', text: 'fa-window-minimize' },
				{ id: 'fa-window-restore', text: 'fa-window-restore' },
				{ id: 'fa-window-close', text: 'fa-window-close' },
				{ id: 'fa-window-close-o', text: 'fa-window-close-o' },
				{ id: 'fa-bandcamp', text: 'fa-bandcamp' },
				{ id: 'fa-grav', text: 'fa-grav' },
				{ id: 'fa-etsy', text: 'fa-etsy' },
				{ id: 'fa-imdb', text: 'fa-imdb' },
				{ id: 'fa-ravelry', text: 'fa-ravelry' },
				{ id: 'fa-eercast', text: 'fa-eercast' },
				{ id: 'fa-microchip', text: 'fa-microchip' },
				{ id: 'fa-snowflake-o', text: 'fa-snowflake-o' },
				{ id: 'fa-superpowers', text: 'fa-superpowers' },
				{ id: 'fa-wpexplorer', text: 'fa-wpexplorer' },
				{ id: 'fa-meetup', text: 'fa-meetup' }
			];
		};

		var init = function () {
			EditDynamiclinkCtrl.link = $modalParams.meta.link.data;

			EditDynamiclinkCtrl.openInNewWin = EditDynamiclinkCtrl.link.target === '_blank';

			if (EditDynamiclinkCtrl.link.color.length && EditDynamiclinkCtrl.link.color.indexOf('#') === -1) {
				EditDynamiclinkCtrl.link.color = '#' + EditDynamiclinkCtrl.link.color;
			}

			if (EditDynamiclinkCtrl.link.icon) {
				EditDynamiclinkCtrl.link.icon = {
					id: EditDynamiclinkCtrl.link.icon,
					text: EditDynamiclinkCtrl.link.icon
				};
			}

			EditDynamiclinkCtrl.setEntity(EditDynamiclinkCtrl.link.entity, true);

			EditDynamiclinkCtrl.iconSelectConfig = {
				allowClear: true,
				data: getIcons(),
				dropdownCssClass: 'icon-select',
				id: function (icon) {
					return icon.id;
				},
				formatResult: function (icon, container, query, escape) {
					return '<b class="fa ' + escape(icon.text) + '"></b>';
				},
				formatSelection: function (icon, container, escape) {
					return '<b class="fa ' + escape(icon.text) + '"></b> ' + escape(icon.text);
				},
				matcher: function (trm, icon) {
					return icon.substr(3).indexOf(trm) !== -1;
				}
			};
		};

		init();
	}
]);

'use strict';

angular.module('upDirectives').directive('upLeadStatus', function () {
	return {
		restrict: 'A',
		scope: {
			activity: '=',
			activityOld: '=',
			opportunity: '=',
			opportunityOld: '=',
			order: '=',
			orderOld: '=',
			visit: '=',
			mail: '=',
			formSubmit: '='
		},
		replace: true,
		templateUrl: require('App/upsales/common/directives/templates/upLeadStatus.tpl.html?file'),
		link: function (scope, elem, $attrs) {
			scope.firstClass = {};
			scope.firstIcon = {};
			scope.firstTooltip = '';
			scope.firstDate = '';

			scope.secondClass = {};
			scope.secondIcon = {};
			scope.secondTooltip = '';
			scope.secondDate = '';

			scope.thirdClass = {};
			scope.thirdIcon = {};
			scope.thirdTooltip = '';
			scope.thirdDate = '';

			// Prevent click
			elem.on('click', function (e) {
				e.stopPropagation();
			});

			var getDateStr = function (date) {
				var momentDate = moment(date);
				if (momentDate.isValid()) {
					// If date is in future we display date calendar
					var today = new Date();
					if (momentDate.isSame(today, 'day') || momentDate.isAfter(today)) {
						var d = momentDate.calendar();

						// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
						if (d !== '0') {
							return ', ' + d;
						}
						return ', ' + momentDate.format('L');
					} else {
						return ', ' + momentDate.fromNow(); // else we display ex. "4 years ago"
					}
				} else {
					return date;
				}
			};

			if ($attrs.market === undefined) {
				var accountOrContact = $attrs.contact ? 'contact' : 'account';

				scope.firstIcon = { 'fa fa-check': true };
				scope.secondIcon = { 'up-icon-pipeline': true };
				scope.thirdIcon = { 'fa fa-usd': true };
				scope.firstTooltip = 'search.' + accountOrContact + 'HasNoActivity';
				scope.secondTooltip = 'search.' + accountOrContact + 'HasNoOpportunity';
				scope.thirdTooltip = 'search.' + accountOrContact + 'HasNoOrder';

				if (scope.activity) {
					scope.firstClass = { 'indicator-active': true };
					scope.firstTooltip = 'search.' + accountOrContact + 'HasActivity';
					scope.firstDate = getDateStr(scope.activity);
				} else if (scope.activityOld) {
					scope.firstClass = { 'indicator-inverted': true };
					scope.firstTooltip = 'search.' + accountOrContact + 'HadActivity';
					scope.firstDate = getDateStr(scope.activityOld);
				}

				if (scope.appointment) {
					scope.firstClass = { 'indicator-active': true };
					scope.firstTooltip = 'search.' + accountOrContact + 'HasAppointment';
					scope.firstDate = getDateStr(scope.appointment);
				} else if (scope.appointmentOld && !scope.activity) {
					scope.firstClass = { 'indicator-inverted': true };
					scope.firstTooltip = 'search.' + accountOrContact + 'HadAppointment';
					scope.firstDate = getDateStr(scope.appointmentOld);
				}

				if (scope.opportunity) {
					scope.secondClass = { 'indicator-active': true };
					scope.secondTooltip = 'search.' + accountOrContact + 'HasOpportunity';
					scope.secondDate = getDateStr(scope.opportunity);
				} else if (scope.opportunityOld) {
					scope.secondClass = { 'indicator-inverted': true };
					scope.secondTooltip = 'search.' + accountOrContact + 'HadOpportunity';
					scope.secondDate = getDateStr(scope.opportunityOld);
				}

				if (scope.order) {
					scope.thirdClass = { 'indicator-active': true };
					scope.thirdTooltip = 'search.' + accountOrContact + 'HasOrder';
					scope.thirdDate = getDateStr(scope.order);
				} else if (scope.orderOld) {
					scope.thirdClass = { 'indicator-inverted': true };
					scope.thirdTooltip = 'search.' + accountOrContact + 'HadOrder';
					scope.thirdDate = getDateStr(scope.orderOld);
				}
			} else if ($attrs.market !== undefined) {
				scope.firstIcon = { 'fa fa-globe': true };
				scope.secondIcon = { 'fa fa-envelope': true };
				scope.thirdIcon = { 'fa fa-file': true };

				scope.firstTooltip = 'event.types.Visit';
				scope.secondTooltip = 'event.types.Mail';
				scope.thirdTooltip = 'event.types.Form';

				if (scope.visit) {
					scope.firstClass = { 'indicator-inverted': true };
				}

				if (scope.mail) {
					scope.secondClass = { 'indicator-inverted': true };
				}

				if (scope.formSubmit) {
					scope.thirdClass = { 'indicator-inverted': true };
				}
			}
		}
	};
});

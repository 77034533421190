import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import { Textarea, Input, Label, Text, Row, Column, Toggle, Block } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { setAssignData } from 'Store/reducers/AssignModalReducer';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import './AssignModalActivityInfo.scss';
import { RootState } from 'Store/index';
import { CampaignIdSelect } from 'Components/PlanPhonecallsDrawer/CampaignSelect';
import { PopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';

export const mapStateToProps = (state: RootState) => ({
	description: state.AssignModal.assign.description,
	notes: state.AssignModal.assign.notes,
	priority: state.AssignModal.assign.priority,
	callListId: state.AssignModal.assign.callListId,
	projectId: state.AssignModal.assign.projectId
});

const mapDispatchToProps = {
	setAssignData
};

const connected = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connected> & { reloadModalPosition?: () => void };

class AssignModalActivityInfo extends React.Component<Props> {
	static contextType = PopupPortalAnchorsContext;
	lang: { [key: string]: string };
	_desc: HTMLInputElement | null = null;
	_main: HTMLDivElement | null = null;
	constructor(props: Props) {
		super(props);

		this.lang = {
			activityDescription: T('processedBy.activityDescription'),
			notes: T('default.notes'),
			highActivity: T('activity.high'),
			priority: T('activity.priority'),
			phoneCallDescription: T('processedBy.phoneCallDescription')
		};

		if (!props.description) {
			const self = Tools.AppService.getSelf();
			props.setAssignData({ description: `${T('assign.assignedLeadFrom')} ${self.name}` });
		}
	}

	componentDidMount() {
		if (this._desc) {
			this._desc.focus();
			this._desc.selectionStart = this._desc.selectionEnd = this._desc.value.length;
		}
	}

	render() {
		const isPriorityEnable = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
		const isTodoListEnable = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		const hasCallLists = Tools.FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
		
		const {
			description,
			notes,
			setAssignData,
			priority,
			callListId,
			projectId,
			reloadModalPosition
		} = this.props;
		const { activityDescription, notes: notesLabel, phoneCallDescription } = this.lang;
		const standardFields = Tools.AppService.getMetadata().standardFields.Activity;

		const classNames = new BemClass('AssignModalActivityInfo');

		return (
			<div className={classNames.b()} ref={r => (this._main = r)}>
				<Row direction="row" className="column-justify">
					<Column>
						<Label required>{isTodoListEnable ? phoneCallDescription : activityDescription}</Label>
						<Input
							value={description}
							onChange={e => setAssignData({ description: e.target.value })}
							inputRef={r => (this._desc = r)}
						/>
					</Column>
					{isPriorityEnable ? (
						<Column className={classNames.elem('Prio').b()} fixedWidth={100}>
							<Label>{this.lang.priority}</Label>
							<Row>
								<Column fixedWidth={50}>
									<Toggle
										color="red"
										icon="flag"
										size="lg"
										onChange={checked =>
											setAssignData({
												priority: checked ? ACTIVITY_PRIORITY.HIGH : ACTIVITY_PRIORITY.NONE
											})
										}
										checked={!!priority}
									/>
								</Column>
								<Column>
									<Text>{this.lang.highActivity}</Text>
								</Column>
							</Row>
						</Column>
					) : null}
				</Row>

				{hasCallLists ? (
					<Block space="mtl">
						<CampaignIdSelect
							anchor={document.querySelector('.AssignModal__content')}
							value={callListId}
							onChange={callListId => setAssignData({ callListId })}
							onToggle={() => reloadModalPosition?.()}
							withToggle={true}
							isCallList
						/>
					</Block>
				) : null}

				{standardFields.Project.active ? (
					<Block space="mtl">
						<CampaignIdSelect
							anchor={document.querySelector('.AssignModal__content')}
							value={projectId}
							onChange={projectId => setAssignData({ projectId })}
							onToggle={() => reloadModalPosition?.()}
							withToggle={true}
						/>
					</Block>
				) : null}

				<Label>{notesLabel}</Label>
				<Textarea value={notes} onChange={e => setAssignData({ notes: e.target.value })} />
			</div>
		);
	}
}

export const detached = AssignModalActivityInfo;
const Component = connected(AssignModalActivityInfo);
export default Component;

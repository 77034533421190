import PropTypes from 'prop-types';
import { TIER_TYPE } from 'Store/reducers/AdminEditProductReducer';

export const productTier = PropTypes.shape({
	start: PropTypes.number,
	end: PropTypes.number,
	type: PropTypes.oneOf([TIER_TYPE.UNIT, TIER_TYPE.TOTAL]),
	currencies: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number,
			currency: PropTypes.string
		})
	)
});

export default PropTypes.shape({
	tiers: PropTypes.arrayOf(productTier)
});

'use strict';

angular.module('upResources').factory('UserDefinedCategoryType', [
	'$resource',
	'$q',
	'URL',
	'API',
	'UserDefinedCategoryTypeAttributes',
	function UserDefinedCategoryType($resource, $q, URL, API, UserDefinedCategoryTypeAttributes) {
		var AA = UserDefinedCategoryTypeAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var Category = $resource(
			URL + API + 'userDefinedObjectCategoryTypes/:udoFieldId/:id',
			{},
			{
				query: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			setId: function (udoFieldId) {
				Model.udoFieldId = udoFieldId;

				return Model;
			},
			find: function (filter, options) {
				var defered = $q.defer();
				var params = angular.extend(filter, options);
				params.udoFieldId = Model.udoFieldId;

				Category.get(
					params,
					function (response) {
						defered.resolve(response);
					},
					function (response) {
						defered.reject(response.status);
					}
				);

				return defered.promise;
			},

			get: function (id) {
				if (!id) {
					return $q.reject('no category type id');
				}

				return Category.get({ id: id, udoFieldId: Model.udoFieldId }).$promise;
			},

			getFn: function () {
				return Model.find({}).then(function (res) {
					return res;
				});
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboardNotify } from 'Services/copyToClipboard';
import BemClass from '@upsales/components/Utils/bemClass';
import './EventActivity.scss';
import { Icon, Tooltip, OutsideClick, Textarea } from '@upsales/components';

export default class EventNotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notes: this.props.notes,
			openEdit: false
		};
	}

	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		this.lang = {
			edit: t('default.edit'),
			copy: t('default.copy'),
			notes: t('default.notes'),
			insertSign: t('default.insertSign'),
			clickAddAnote: t('default.clickAddAnote')
		};
	}

	copy = (e, value) => {
		e.stopPropagation();
		copyToClipboardNotify(value);
	};

	openEdit = () => {
		this.setState({ openEdit: true });
	};

	notesChanged = value => {
		this.setState({ notes: value });
	};

	setRef = (name, ref) => {
		this[name] = ref;
	};
	insertSign = e => {
		this.setState({ openEdit: true });
		e.preventDefault();
		e.stopPropagation();

		var tools = Tools.AppService.getSelf();
		var dateTimeStr = moment().format('L LT');
		var signature = tools.name + ' ' + dateTimeStr + ':\n';
		var pos = signature.length;
		var notes = this.state.notes;
		var textarea = this._notes;
		var newNotes = signature + '\n\n' + (notes || '');
		this.notesChanged(newNotes);
		if (textarea) {
			textarea.focus();
			setTimeout(function () {
				textarea.setSelectionRange(pos, pos);
			}, 50);
		}
	};

	render() {
		const classes = new BemClass('ActivityWrapper');
		return (
			<OutsideClick
				targetClass={'ActivityWrapper__notesWrapper'}
				outsideClick={() => {
					if (this.props.notes !== this.state.notes) {
						this.props.updateNotes(this.state.notes);
					}
					this.setState({ openEdit: false });
				}}
			>
				<div test-id="notes" className={classes.elem('notesWrapper')}>
					<div className={classes.elem('notesTitle')}>
						<div className="title">
							<b> {this.lang.notes} </b>
						</div>

						<div className="addSign">
							<button
								type="button"
								className="up-btn btn btn-link btn-bright-blue insert-sign-btn"
								onClick={this.insertSign}
								disabled={this.props.disabled}
							>
								<b className="fa fa-pencil" /> {this.lang.insertSign}
								{'...'}
							</button>
						</div>
					</div>
					<div test-id="notesEditor" className={classes.elem('notesEditor')}>
						<Textarea
							className={this.state.notes ? 'flat-input setMinHeight' : 'flat-input'}
							name="notes-area"
							textareaRef={this.setRef.bind(this, '_notes')}
							value={this.state.notes || ''}
							onChange={e => this.notesChanged(e.target.value)}
							onClick={this.openEdit}
							placeholder={!this.state.openEdit ? this.lang.clickAddAnote : ''}
							disabled={this.props.disabled}
						/>

						{this.state.notes && (
							<div className="notesTools">
								<Tooltip title={this.lang.copy} distance={20}>
									<Icon name="copy" onClick={e => this.copy(e, this.state.notes)} />
								</Tooltip>
							</div>
						)}
					</div>
				</div>
			</OutsideClick>
		);
	}
}

EventNotes.propTypes = {
	notes: PropTypes.string,
	updateNotes: PropTypes.func,
	disabled: PropTypes.bool
};

import Resource from './Resource';
import Attributes from 'Attributes/StandardIntegrationAttributes';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

class StandardIntegrationSettings extends Resource {
	constructor() {
		super('standardIntegrationSettings', Attributes);
	}
	async save(data, opts = {}) {
		const result = await super.save(data, opts);
		AllIWantDataCache.clearData();
		return result;
	}
}

const resource = new StandardIntegrationSettings();
window.Tools.StandardIntegrationSettings = resource;
export default resource;

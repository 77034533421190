'use strict';

angular.module('upResources').service('ParseForm', function () {
	return function (form) {
		if (typeof form.socialMediaTags === 'string') {
			form.socialMediaTags = JSON.parse(form.socialMediaTags);
		}

		// Form html gets escaped in api/src/controller/customer/form.js to prevent XSS attacks, so we need to unescape it here
		const parseReplace = fieldType => {
			return fieldType
				?.replaceAll('&amp;', '&')
				.replaceAll('&lt;', '<')
				.replaceAll('&gt;', '>')
				.replaceAll('&quot;', '"')
				.replaceAll('&#039;', "'");
		};

		form.title = parseReplace(form.title);
		form.description = parseReplace(form.description);

		return form;
	};
});

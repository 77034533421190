import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './OpportunityRow.scss';

type Props = {
	text: string;
	isWarning: boolean;
};

class OpportunityRowOverAvg extends React.Component<Props> {
	static propTypes = {
		isWarning: PropTypes.bool.isRequired,
		text: PropTypes.string.isRequired
	};

	render() {
		const classes = new bemClass('OpportunityRow');
		const { text, isWarning } = this.props;
		return (
			<Card className={classes.b()}>
				<Icon
					className={classes.elem('iconTitle').b()}
					color={isWarning ? 'red' : 'green'}
					name={isWarning ? 'hourglass-end' : 'hourglass-half'}
				/>

				<div className={classes.elem('con').b()}>
					<Text className={classes.elem('description').b()}>{text}</Text>
				</div>
			</Card>
		);
	}
}

export default OpportunityRowOverAvg;

import React from 'react';
import PropTypes from 'prop-types';

import CriteriaWrapper from './CriteriaWrapper';

import ActivityInfo from './Info/Activity';
import ChangesInfo from './Info/Changes';
import AssignInfo from './Info/Assign';
import SegmentInfo from './Info/Segment';
import EmailInfo from './Info/Email';
import WebhookInfo from './Info/Webhook';
import LockedJourney from './Info/LockedJourney';
import { availableActions } from 'Store/reducers/JourneyEditorReducer';
import logError from 'App/babel/helpers/logError';

const actionMap = {
	CreateActivity: ActivityInfo,
	UpdateContactClient: ChangesInfo,
	UpdateContact: ChangesInfo,
	AnonymizeContact: ChangesInfo,
	SendEmail: EmailInfo,
	SendWebhook: WebhookInfo,
	SendWebRequest: WebhookInfo,
	AddContactToSegment: SegmentInfo,
	AssignToUser: AssignInfo,
	SetCountactJourneyStatus: ChangesInfo
};

export default class CriteriaInfo extends React.Component {
	constructor(props) {
		super(props);
		const { $translate } = Tools;
		const { config, action } = props;

		this.removeCriteria = this.removeCriteria.bind(this);

		const entity = config.entity || 'contact';

		const { title } = availableActions.find(a => a.action === action) || {};
		this.lang = {
			entity: $translate(`default.${entity}`),
			title: $translate(title),
			remove: $translate('admin.actions.remove')
		};

		const showMultipleEmailInfo = Tools.AppService.getMetadata().params.SyncJourneyStatus && action === 'SendEmail';

		if (showMultipleEmailInfo) {
			this.lang.tooltip = $translate('admin.journeyMultipleEmailInfo');
		}
	}

	removeCriteria() {
		const { removeCriteria, filter } = this.props;
		removeCriteria(filter.filterName);
	}

	render() {
		const { action, onClick } = this.props;
		let ActionRow = actionMap[action];
		if (this.props.config.locked) {
			ActionRow = LockedJourney;
		}

		if (!ActionRow) {
			const msg = `No action type found for: ${action}`;
			logError(new Error(msg), msg);
			return null;
		}
		return (
			<CriteriaWrapper
				className="CriteriaInfo"
				onRemove={this.removeCriteria}
				onClick={onClick}
				lang={this.lang}
				locked={this.props.config.locked}
				type="action"
			>
				<ActionRow {...this.props} />
			</CriteriaWrapper>
		);
	}
}

CriteriaInfo.propTypes = {
	action: PropTypes.oneOf(Object.keys(actionMap)).isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	removeCriteria: PropTypes.func.isRequired,
	onClick: PropTypes.func
};

CriteriaInfo.defaultProps = {
	filter: {},
	config: {}
};

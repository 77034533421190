'use strict';
// The only place using this directive is in the old advanced search. Remove this whnen ADVANCED_SEARCH_REACT is released.
var pageSelectDir = 'upPageSelect';
angular
	.module('upDirectives')
	.directive(pageSelectDir + 'Ids', function (LeaderPage, selectHelper, $translate, $filter) {
		return selectHelper.getDirectiveObjectIds(pageSelectDir + 'Ids', {
			resource: LeaderPage,
			ajax: true,
			asIds: true,
			idAttr: LeaderPage.attr.url,
			titleAttr: LeaderPage.attr.name,
			sorting: { field: LeaderPage.attr.pageImpression, ascending: false },
			comparisonType: 'mp',
			fields: [LeaderPage.attr.pageImpression.field],
			infiniteScroll: true,
			formatResult: function (page, container, query, escape) {
				var text = escape(page.name);

				if (!text) {
					text = '<< ' + $translate.instant('default.noTitle') + ' >>';
				}

				var urlText = page.url;

				var maxLength = 40;

				if (urlText.length > maxLength) {
					urlText = urlText.substr(0, 19) + '...' + urlText.substr(urlText.length - 19, urlText.length);
				}

				var impressionsText = $filter('numberFormat')(page.pageImpression);

				return (
					'<div class="up-page-select">' +
					'<div class="visit-row">' +
					'<div class="text-container">' +
					'<div class="list-text-wrap">' +
					text +
					'</div>' +
					'<div class="list-text-wrap url-row">' +
					escape(urlText) +
					'</div>' +
					'<div class="list-text-wrap">' +
					'<i style="margin-right: 5px" class="fa fa-eye" aria-hidden="true"></i>' +
					escape(impressionsText) +
					'</div>' +
					'</div>' +
					'</div>' +
					'</div>'
				);
			}
		});
	});

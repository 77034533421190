'use strict';

angular.module('domain.advancedSearch').directive('contactInfoSearchSection', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/domain/advanced-search/directives/contactInfoSearchSection.tpl.html?file'),
		scope: {
			Filters: '=filters',
			onChange: '&',
			onKeyup: '&'
		},
		controller: [
			'$scope',
			'AppService',
			function ($scope, AppService) {
				$scope.inited = false;
				$scope.ngChange = function () {
					if ($scope.onChange) {
						$scope.onChange();
					}
				};

				$scope.ngKeyup = function (e) {
					if ($scope.onKeyup) {
						$scope.onKeyup({ $event: e });
					}
				};

				var init = function () {
					if ($scope.Filters.RegDate.value.preset !== 'custom') {
						$scope.Filters.RegDate.value = {
							field: 'date',
							start: null,
							end: null,
							preset: 'custom'
						};
					}

					$scope.Filters.Phone.comparisonType = 'Search';
					$scope.Filters.CellPhone.comparisonType = 'Search';
					$scope.Filters.Name.comparisonType = 'Search';
					$scope.Filters.JourneyStep.comparisonType = 'Equals';
					$scope.inited = true;

					$scope.categoryTypes = AppService.getCategoryTypes('contact');

					if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS)) {
						$scope.journeySteps = AppService.getJourneySteps();
					}
				};

				var standardFields = Tools.AppService.getMetadata().standardFields.Contact;
				var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
				$scope.showSalutation = hasNewFields && standardFields.Salutation && standardFields.Salutation.active;
				$scope.showTitleCategory =
					hasNewFields && standardFields.TitleCategory && standardFields.TitleCategory.active;

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var unWatchInit = $scope.$watch('Filters', function (filters) {
						if (filters !== undefined) {
							unWatchInit();
							init();
						}
					});
				});
			}
		]
	};
});

import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Label, Input, Row, Column, Textarea, Text, Link } from '@upsales/components';
import { connect } from 'react-redux';
import {
	loadSingleSignOn,
	setSingleSignOnSettings,
	toggleSingleSignOn,
	saveSingleSignOnSettings
} from 'Store/reducers/SingleSignOnReducer';
import './SingleSignOn.scss';
import PropTypes from 'prop-types';
import AdminHeader from './AdminHeader';
import SaveBtn from 'Components/Admin/SaveBtn';

const propTypes = {
	certificate: PropTypes.string,
	loginUrl: PropTypes.string,
	logoutUrl: PropTypes.string,
	enabled: PropTypes.bool,
	loadSingleSignOn: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	setSingleSignOnSettings: PropTypes.func.isRequired,
	toggleSingleSignOn: PropTypes.func.isRequired,
	dirty: PropTypes.bool,
	saving: PropTypes.bool,
	saveSingleSignOnSettings: PropTypes.func.isRequired,
	metadataUrl: PropTypes.string,
	bypassUrl: PropTypes.string
};

const mapStateToProps = state => {
	return {
		...state.SingleSignOn
	};
};

const mapDispatchToProps = {
	loadSingleSignOn,
	setSingleSignOnSettings,
	toggleSingleSignOn,
	saveSingleSignOnSettings
};

export class SingleSignOn extends Component {
	componentDidMount() {
		this.props.loadSingleSignOn();
	}

	updateVal(e) {
		const settings = {
			certificate: this.props.certificate,
			loginUrl: this.props.loginUrl,
			logoutUrl: this.props.logoutUrl
		};

		settings[e.target.name] = e.target.value;

		this.props.setSingleSignOnSettings(settings);
	}

	render() {
		const t = Tools.$translate;
		const {
			certificate,
			loginUrl,
			logoutUrl,
			enabled,
			dirty,
			saving,
			saveSingleSignOnSettings,
			loading,
			metadataUrl,
			bypassUrl
		} = this.props;

		return (
			<div id="admin-root">
				<div id="admin-page-single-sign-on" className={loading ? null : 'visible'}>
					<AdminHeader
						title={t('admin.singleSignOn')}
						description={t('admin.singleSignOn.info')}
						image="single-sign-on.svg"
						toggle={true}
						toggleChecked={enabled}
						onToggleChange={this.props.toggleSingleSignOn}
						toggleLabel={`${t('default.use')} ${t('admin.singleSignOn')}`}
					/>

					<div id="admin-content">
						<Card>
							<CardHeader title={t('admin.singleSignOn.settings')} />
							<CardContent>
								<Row>
									<Column>
										<Label required={true}>
											{t('default.add') + ' ' + t('admin.singleSignOn.certificate').toLowerCase()}
										</Label>
										<Textarea
											name="certificate"
											required={true}
											placeholder={
												t('default.add') +
												' ' +
												t('admin.singleSignOn.certificate').toLowerCase()
											}
											inputClassName="textarea-large"
											value={certificate}
											onChange={e => this.updateVal(e)}
										/>
									</Column>
								</Row>
								<Row className="input-row">
									<Column>
										<Label required={true}>{t('admin.singleSignOn.loginUrl')}</Label>
										<Input
											name="loginUrl"
											required={true}
											onChange={e => this.updateVal(e)}
											placeholder={t('admin.singleSignOn.addURL')}
											value={loginUrl}
										/>
									</Column>
									<Column>
										<Label>{t('admin.singleSignOn.logoutUrl')}</Label>
										<Input
											name="logoutUrl"
											onChange={e => this.updateVal(e)}
											placeholder={t('admin.singleSignOn.addURL')}
											value={logoutUrl}
										/>
									</Column>
								</Row>
								<Row className="input-row">
									<Column>
										<Text>{t('admin.singleSignOn.MetadataInfo')}</Text>
										<Link target="_blank" href={metadataUrl}>
											{metadataUrl}
										</Link>
									</Column>
								</Row>
								<Row>
									<Column>
										<Text>{t('admin.singleSignOn.forgotSamlInfo')}</Text>
										<Link target="_blank" href={bypassUrl}>
											{bypassUrl}
										</Link>
									</Column>
								</Row>
							</CardContent>
						</Card>
					</div>
				</div>
				<SaveBtn visible={dirty} saving={saving} onClick={saveSingleSignOnSettings} text={t('default.save')} />
				<div id="admin-loader" className={loading ? 'visible' : null}>
					<ReactTemplates.loader />
				</div>
			</div>
		);
	}
}

SingleSignOn.propTypes = propTypes;

const ConnectedSingleSignOn = connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
export default ConnectedSingleSignOn;
window.SingleSignOn = ConnectedSingleSignOn;

import React, { Component } from 'react';
import AdminHeader from 'Components/Admin/AdminHeader';
import t from 'Components/Helpers/translate';
import Role from 'App/resources/Model/Role';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import RoleSelect from 'Components/RoleSelect';

import './Looker.scss';

type Props = {
	rootData: {
		editExploreAccess: (value: BasicUserWithPermissions) => void;
		toogleHideReportCenter: (value: boolean) => void;
		editDashboardAccess: Function;
		pageData: {
			hideReportCenter: boolean;
			editDashboardAccess: () => void;
			exploreAccess: Function;
			dashboards: {
				id: string;
				isFavorite: boolean;
				roles: Optional<Role, 'userEditable' | 'userRemovable'>[];
				useNext: boolean;
				space: string;
				title: string;
			}[];
			roles: Role[];
		};
	};
};

class Looker extends Component<Props> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			looker: t('admin.looker'),
			lookerInfo: t('admin.lookerInfo'),
			name: t('default.name'),
			type: t('admin.looker.type'),
			dashboards: t('admin.looker.dashboards'),
			explore: t('admin.looker.explore'),
			dashboardAccess: t('admin.looker.dashboardAccess'),
			hideReportCenter: t('admin.looker.hideReportCenter'),
			chooseRoles: t('admin.looker.chooseRoles'),
			chooseUsers: t('admin.looker.chooseUsers')
		};
	}

	render() {
		const rootData = this.props.rootData;
		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		const dashboards = rootData.pageData.dashboards.map((dashboard, i) => {
			const badTypeRoles = dashboard.roles as unknown as Optional<Role, 'userEditable' | 'userRemovable'>;
			return (
				<tr key={'dashboard-' + i}>
					<td>{dashboard.title}</td>
					<td>{t(dashboard.space)}</td>
					<td>
						{hasTreeSelect ? (
							<RoleSelect
								roles={rootData.pageData.roles}
								selectedRoles={dashboard.roles}
								onChange={rootData.editDashboardAccess.bind(this, dashboard.id)}
							/>
						) : (
							<ReactTemplates.INPUTS.upRoles
								tools={Tools}
								data={rootData.pageData.roles}
								value={badTypeRoles}
								multiple={true}
								asTree="true"
								placeholder={this.lang.chooseRoles}
								onChange={rootData.editDashboardAccess.bind(this, dashboard.id)}
							/>
						)}
					</td>
				</tr>
			);
		});

		return (
			<div id="admin-page-looker" className="AdminLooker">
				<AdminHeader
					title={this.lang.looker}
					description={this.lang.lookerInfo}
					image="looker.svg"
					toggle={true}
					toggleChecked={rootData.pageData.hideReportCenter}
					onToggleChange={rootData.toogleHideReportCenter}
					toggleLabel={this.lang.hideReportCenter}
				/>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.dashboards}</span>
						</div>

						<table>
							<thead>
								<tr>
									<th>{this.lang.name}</th>
									<th>{this.lang.type}</th>
									<th id="access-header">{this.lang.dashboardAccess}</th>
								</tr>
							</thead>
							<tbody>{dashboards}</tbody>
						</table>
					</div>

					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">{this.lang.explore}</span>
							<div>
								<div className="selct2-holder">
									<ReactTemplates.INPUTS.upUsers
										multiple={true}
										className="form-control"
										value={rootData.pageData.exploreAccess}
										onChange={rootData.editExploreAccess}
										tools={Tools}
										placeholder={this.lang.chooseUsers}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Looker;

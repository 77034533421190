'use strict';

angular.module('upResources').factory('StaticValues', [
	'$resource',
	'$q',
	'URL',
	'API',
	'StaticValuesAttributes',
	'ParseGeneric',
	function ($resource, $q, URL, API, StaticValuesAttributes, ParseGeneric) {
		var Attribute = StaticValuesAttributes().getAll();

		var Resource = $resource(
			URL + API + 'staticValues/:type',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseStaticValues', { isArray: false })
				}
			}
		);

		var Model = {
			get: function (type) {
				return Resource.get({ type: type }).$promise;
			},
			attr: Attribute
		};
		return Model;
	}
]);

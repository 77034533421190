import {
	prepDates,
	prepMultiSelect,
	getAvailableFields,
	formatDateType,
	attachScopeFunctions,
	executeSave
} from 'App/babel/helpers/multiModals';

angular.module('domain.contact').controller('UpdateContactMulti', [
	'$scope',
	'$modalParams',
	'$translate',
	'$multiSelect',
	'AppService',
	function ($scope, $modalParams, $translate, $multiSelect, AppService) {
		var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;
		$scope.isTrigger = $modalParams.isTrigger;

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.save = function () {
			var method = 'updateContact';
			var defaultEntity = 'Contact';

			if ($scope.clientContacts) {
				method = 'updateClientContacts';
				defaultEntity = 'Client';
			}

			executeSave({
				scope: $scope,
				modalParams: $modalParams,
				multiSelect: $multiSelect,
				entityType: 'Contact',
				method,
				arrayProperties,
				entityArg: $modalParams.entity || defaultEntity
			});
		};

		attachScopeFunctions($scope, ['addField', 'removeField', 'createCampaign', 'changeCustomDate']);

		var init = function () {
			const meta = $modalParams.meta;

			const startingFields = meta.contactCustomFields.data;
			const { customDateTypes } = prepDates($modalParams.entity || $modalParams.tagEntity);

			$scope.showTitleCategory =
				Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') &&
				AppService.getMetadata().standardFields.Contact.TitleCategory?.active;

			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			$scope.market = Tools.FeatureHelper.isAvailableProduct(Tools.FeatureHelper.Product.MA);
			$scope.customFields = [];
			$scope.clientContacts = $modalParams.clientContacts;

			$scope.showJourneyStep = true;
			$scope.hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
			if ($scope.hasJourney) {
				$scope.journeySteps = Tools.AppService.getJourneySteps();
			}

			$scope.availableFields = getAvailableFields($scope, startingFields, customDateTypes);
			$scope.customFieldLength = $scope.availableFields.length;
			$scope.customDateTypes = formatDateType(customDateTypes);

			// Properties
			$scope.properties = {};

			$scope.campaignSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.campaign').toLowerCase()
			};
			$scope.campaigns = meta.campaigns.data;

			$multiSelect = prepMultiSelect($multiSelect, $modalParams.multiSelect);
		};

		init();
	}
]);

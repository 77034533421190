import FlowStepParamList, { MAX_HEIGHT } from '../FlowStepParamList';
import { FlowStep } from 'App/resources/Model/Flow';
import T from 'Components/Helpers/translate';
import React from 'react';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
};

const FlowStepUpdateContact = (props: Props) => {
	return <FlowStepParamList icon="user-edit" title={T('flow.step.updateContact')} entity="contact" {...props} />;
};

export { MAX_HEIGHT };
export default FlowStepUpdateContact;

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Table, TableRow, TableColumn, Text, Icon } from '@upsales/components';
import t from 'Components/Helpers/translate';
import './MailContentEditorRows.scss';

const getColLang = type => {
	switch (type) {
		case 'TEXT':
			return t('mail.text');
		case 'IMAGE':
			return t('default.image');
		case 'BUTTON':
			return t('bannerEditor.button');
		case 'HR':
			return t('mail.line');
		case 'VIDEO':
			return t('landingpage.video');
		case 'HTML':
			return 'HTML';
		case 'PRODUCTTABLE':
			return t('admin.documentTemplateEditor.productTable');
		default:
			return '';
	}
};

const MailContentEditorRows = ({ rows, onSelect, setHovered, ...props }) => {
	const classes = new bemClass('MailContentEditorRows');
	return (
		<div {...props} className={classes.b()}>
			<Table>
				{rows.map((row, i) => (
					<React.Fragment key={`row-${i}`}>
						<TableRow
							className={classes.elem('row').b()}
							onClick={() => onSelect(null, row)}
							onMouseOver={() => setHovered(row.columns[0].id, row.id)}
							onMouseOut={() => setHovered(null, null)}
						>
							<TableColumn>
								<Text bold={true}>{`${t('mail.row')} ${i + 1}`}</Text>
							</TableColumn>
						</TableRow>
						{row.columns.map((col, ci) => (
							<TableRow
								key={`row-${i}-col-${ci}`}
								className={classes.elem('col').b()}
								onClick={() => onSelect(col, row)}
								onMouseOver={() => setHovered(col.id, row.id)}
								onMouseOut={() => setHovered(null, null)}
							>
								<TableColumn>
									<Icon name="columns" />
									{getColLang(col.type)}
								</TableColumn>
							</TableRow>
						))}
					</React.Fragment>
				))}
			</Table>
		</div>
	);
};

MailContentEditorRows.propTypes = {
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			columns: PropTypes.arrayOf(PropTypes.shape({}))
		})
	),
	onSelect: PropTypes.func,
	setHovered: PropTypes.func
};

export default MailContentEditorRows;

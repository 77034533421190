'use strict';

import projectFilters from 'App/babel/filterConfigs/Project';
const ProjectFilters = projectFilters();

angular.module('upAttributes').service('CampaignAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				startDate: {
					title: 'default.startDate',
					field: 'startDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					sortable: 'startDate',
					selectableColumn: true,
					filterable: false
				},
				endDate: {
					title: 'default.endDate',
					field: 'endDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					sortable: 'endDate',
					selectableColumn: true,
					filterable: false
				},
				active: {
					title: 'default.active',
					field: 'active',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true,
					filterable: false
				},
				users: {
					title: 'default.users',
					field: 'user.id',
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'users.id',
							type: FilterType.Number
						},
						name: {
							field: 'users.name',
							type: FilterType.String
						}
					},
					groupable: false,
					// NOT POSSIBLE!!
					// sortable: 'users.name',
					selectableColumn: true,
					filterable: false
				},
				notes: {
					title: 'default.notes',
					field: 'notes',
					type: FilterType.String
				},
				quota: {
					title: 'campaign.quota',
					field: 'quota',
					sortable: 'quota',
					selectableColumn: true,
					type: FilterType.String
				},
				statsActivity: {
					title: 'default.activities',
					field: 'stats.activity',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				statsAppointment: {
					title: 'default.appointments',
					field: 'stats.appointment',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				statsClient: {
					title: 'default.accounts',
					field: 'stats.client',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				statsContact: {
					title: 'default.contacts',
					field: 'stats.contact',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				statsOrder: {
					title: 'default.orders',
					field: 'stats.order',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				statsOpportunity: {
					title: 'default.opportunities',
					field: 'stats.opportunity',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false
				},
				isCallList: {
					field: 'isCallList',
					type: FilterType.boolean
				},
				custom: {}
			};

			const standardFilters = ProjectFilters;
			const requiredFields = ['custom'];
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					STARTDATE: 'startDate',
					ENDDATE: 'endDate',
					ACTIVE: 'active',
					NAME: 'name',
					USERS: 'users',
					NOTES: 'notes',
					QUOTA: 'quota',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

import AccountSettings from '../components/Admin/AccountSettings';
import React from 'react';
import ReactDOM from 'react-dom';
import logError from '../helpers/logError';
import LocalStorage from 'App/babel/components/Helpers/LocalStorage';
import openModal from 'App/services/Modal';

const AdminLanguageSettingsCtrl = (window.AdminLanguageSettingsCtrl = scope => {
	const CustomerLanguage = window.Tools.CustomerLanguage;
	const localStorage = new LocalStorage();
	let rootNode;
	let dataStore;

	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	const render = props => {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(React.createElement(AccountSettings, props), rootNode);
	};

	const actions = {
		startAddLanguage: () => {
			dataStore.set('isAdding', true);
		},
		getLanguages: () => {
			CustomerLanguage.find()
				.then(result => {
					const selectedLanguages = result.data || [];
					selectedLanguages.reverse();
					const masterLanguage = _.find(selectedLanguages, language => language.isMaster) || null;
					dataStore.setStore({
						loading: false,
						selectedLanguages: result.data,
						masterLanguage: masterLanguage
					});
				})
				.catch(error => logError(error, 'AdminLanguageSettingsCtrl - getLanguages'));
		},
		cancelAddLanguage: () => {
			dataStore.setStore({ isAdding: false, tmpLanguage: null });
		},
		languageChange: event => {
			const languages = dataStore.get('languages');
			const tmpLanguage = _.find(languages, { id: event.target.value });

			if (tmpLanguage) {
				dataStore.set('tmpLanguage', tmpLanguage);
			}
		},
		saveLanguage: () => {
			const { tmpLanguage, saving } = dataStore.pluck('tmpLanguage', 'saving');

			if (tmpLanguage && !saving) {
				dataStore.set('saving', true);

				Tools.CustomerLanguage.save({ language: tmpLanguage.id, isMaster: false })
					.then(result => {
						if (result.error || !result.data) {
							dataStore.set('saving', false);
						} else {
							const savedLanguage = result.data;
							const selectedLanguages = dataStore.get('selectedLanguages');
							const index = _.findIndex(selectedLanguages, { id: savedLanguage.language });

							if (index !== -1) {
								selectedLanguages[index] = savedLanguage;
							} else {
								selectedLanguages.unshift(savedLanguage);
							}

							const newState = { tmpLanguage: null, saving: false, selectedLanguages, isAdding: false };
							dataStore.setStore(newState);
						}
					})
					.catch(() => dataStore.set('saving', false));
			}
		},
		removeLanguage: language => {
			dataStore.set('currentlyDeleting', language.id);

			Tools.CustomerLanguage.delete(language.id)
				.then(() => {
					const selectedLanguages = dataStore.get('selectedLanguages');
					const filteredSelectedLanguage = _.filter(selectedLanguages, selectedLanguage => {
						return selectedLanguage.id !== language.id;
					});
					dataStore.set('selectedLanguages', filteredSelectedLanguage);
				})
				.catch(() => {
					dataStore.set('currentlyDeleting', null);
				});
		},
		changeMasterLanguage: event => {
			dataStore.setStore({ tmpMasterLanguage: event.target.value });
		},
		saveMasterLanguage: () => {
			const { selectedLanguages, tmpMasterLanguage, savingMasterLanguage, masterLanguage, languages } =
				dataStore.pluck(
					'selectedLanguages',
					'tmpMasterLanguage',
					'savingMasterLanguage',
					'masterLanguage',
					'languages'
				);

			if (tmpMasterLanguage && !savingMasterLanguage) {
				let promise;

				const selectedLanguage = _.find(selectedLanguages, { language: tmpMasterLanguage });
				const missingTranslationCount = selectedLanguage ? selectedLanguage.missing.length : Infinity;

				// TODO: Removal candidate:
				// Since it's not possible to save a master language with missing translations, I don't think that this is needed
				if (masterLanguage && missingTranslationCount) {
					const staticValueLanguage = _.find(languages, { id: tmpMasterLanguage });
					const languageString = staticValueLanguage ? staticValueLanguage.name : tmpMasterLanguage;

					const body =
						missingTranslationCount === Infinity
							? Tools.$translate('admin.languageSettings.confirmBody.NoTranslations', {
									language: languageString
							  })
							: Tools.$translate('admin.languageSettings.confirmBody', {
									language: languageString,
									count: missingTranslationCount
							  });

					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						promise = new Promise((resolve, reject) => {
							openModal('Alert', {
								title: 'admin.languageSettings.translationsMissing',
								body: body,
								headerIcon: 'exclamation-triangle',
								confirmButtonText: 'admin.languageSettings.changeAnyways',
								onClose: confirmed => {
									if (confirmed) {
										promise = resolve(true);
									} else {
										promise = reject(false);
									}
								}
							});
						});
					} else {
						promise = Tools.$upModal.open('warningConfirm', {
							title: 'admin.languageSettings.translationsMissing',
							body: body,
							icon: 'fa-exclamation-triangle',
							resolveTrue: 'admin.languageSettings.changeAnyways'
						});
					}
				} else {
					promise = Promise.resolve(true);
				}

				promise
					.then(() => {
						dataStore.set('savingMasterLanguage', true);

						const data = selectedLanguage
							? Object.assign({}, selectedLanguage, { isMaster: true })
							: { language: tmpMasterLanguage, isMaster: true };

						Tools.CustomerLanguage.save(data)
							.then(result => {
								if (result.error || !result.data) {
									dataStore.set('savingMasterLanguage', false);
								} else {
									const savedLanguage = result.data;
									const selectedLanguages = dataStore.get('selectedLanguages');
									const index = _.findIndex(selectedLanguages, { language: savedLanguage.language });

									if (savedLanguage.isMaster) {
										for (const language of selectedLanguages) {
											language.isMaster = false;
										}
									}

									if (index !== -1) {
										selectedLanguages[index] = savedLanguage;
									} else {
										selectedLanguages.push(savedLanguage);
									}

									const newState = {
										tmpMasterLanguage: null,
										savingMasterLanguage: false,
										selectedLanguages,
										masterLanguage: savedLanguage
									};
									dataStore.setStore(newState);
								}
							})
							.catch(() => dataStore.set('savingMasterLanguage', false));
					})
					.catch(error => logError(error, 'AdminLanguageSettingsCtrl - saveMasterLanguage'));
			}
		},
		toggleBrokenFiscalYear: value => {
			dataStore.set('savingBrokenFiscalYear', true);

			Tools.ClientParam.save(228, value)
				.then(() => {
					dataStore.setStore({ savingBrokenFiscalYear: false, brokenFiscalYearEnabled: value });
				})
				.catch(() => {
					dataStore.set('savingBrokenFiscalYear', false);
				});
		},
		toggleAutoAddProspectingData: value => {
			dataStore.set('savingAutoAddProspectingData', true);

			Tools.ClientParam.save(246, value)
				.then(() => {
					dataStore.setStore({ savingAutoAddProspectingData: false, autoAddProspectingDataEnabled: value });
				})
				.catch(() => {
					dataStore.set('savingAutoAddProspectingData', false);
				});
		},
		selectBrokenFiscalYearOffset: value => {
			dataStore.set('savingBrokenFiscalYear', true);

			Tools.ClientParam.save(229, value)
				.then(() => {
					dataStore.setStore({
						savingBrokenFiscalYear: false,
						brokenFiscalYearOffset: value,
						showFiscalYearSelect: false
					});
				})
				.catch(() => {
					dataStore.set('savingBrokenFiscalYear', false);
				});
		},
		showFiscalyearSelect: () => {
			dataStore.set('showFiscalYearSelect', true);
		},
		salesModelActions: {
			selectSalesModel: value => {
				dataStore.set('tmpSalesModel', value);
				if (dataStore.get('salesModel') === value) {
					dataStore.set('tmpSalesModelOption', dataStore.get('salesModelOption'));
				} else {
					switch (value) {
						case 'sales':
							dataStore.set('tmpSalesModelOption', null);
							break;
						case 'cm':
							dataStore.set('tmpSalesModelOption', 'cm');
							break;
						case 'rr':
							dataStore.set('tmpSalesModelOption', 'arr');
							break;
						default:
							dataStore.set('tmpSalesModelOption', dataStore.get('salesModelOption'));
							break;
					}
				}
			},
			selectSalesModelOption: value => {
				dataStore.set('tmpSalesModelOption', value);
			},
			selectSalesModelOption2: value => {
				dataStore.set('tmpSalesModelOption2', value);
			},
			selectSalesModelOption3: value => {
				dataStore.set('tmpSalesModelOption3', value);
			},
			setGroupARRChangesByUser: value => {
				dataStore.set('tmpGroupARRChangesByUser', value);
			},
			saveSalesModelSettings: () => {
				const tmpSalesModel = dataStore.get('tmpSalesModel');
				const tmpSalesModelOption = dataStore.get('tmpSalesModelOption');
				const tmpSalesModelOption2 = dataStore.get('tmpSalesModelOption2');
				const tmpSalesModelOption3 = dataStore.get('tmpSalesModelOption3');
				const salesModel = dataStore.get('salesModel');
				const salesModelOption = dataStore.get('salesModelOption');
				const salesModelOption2 = dataStore.get('salesModelOption2');
				const salesModelOption3 = dataStore.get('salesModelOption3');
				const tempGroupARRChangesByUser = dataStore.get('tmpGroupARRChangesByUser');
				const groupARRChangesByUser = dataStore.get('groupARRChangesByUser');
				const updateMetadataParam = (param, value) => {
					const metadata = Tools.AppService.getMetadata();
					Tools.AppService.setMetadata({
						...metadata,
						params: {
							...metadata.params,
							[param]: value
						}
					});
				};
				const setContributionMargin = value => {
					Tools.ClientParam.save(219, value, { skipNotification: true })
						.then(() => {
							updateMetadataParam('UseContributionMargin', value);
						})
						.catch(e => {
							logError(e);
						});
				};

				const setSalesboardSumContributionMargin = value => {
					Tools.ClientParam.save(220, value, { skipNotification: true })
						.then(() => {
							localStorage.setValueRaw(
								`salesboard.displayValueType-cm`,
								value ? 'contributionMargin' : 'value'
							);
							updateMetadataParam('SalesboardSumContributionMargin', value);
						})
						.catch(e => {
							logError(e);
						});
				};
				const enableAgreements = () => {
					const metadata = Tools.AppService.getMetadata();

					if (!metadata.params.AgreementEnabled) {
						Tools.ClientParam.save(189, true, { skipNotification: true })
							.then(() => {
								updateMetadataParam('AgreementEnabled', true);
							})
							.catch(e => {
								logError(e);
							});
					}
				};

				const setListViews = listViewTypes => {
					const customerId = Tools.AppService.getCustomerId();
					listViewTypes.forEach(type => {
						Tools.ListView.customer(customerId)
							.setVisibilityType('public')
							.setEntityType(type)
							.find({})
							.then(function (res) {
								Tools.AppService.setListViews(type, res.data);
							})
							.catch(e => {
								logError(e, 'Failed to get list view');
							});
					});
				};
				let skipThisNotification = false;
				let skipNextNotification = false;
				if (tmpSalesModel !== salesModel) {
					skipNextNotification = true;
					Tools.ClientParam.save(233, tmpSalesModel, { skipNotification: skipThisNotification })
						.then(() => {
							dataStore.setStore({ salesModel: tmpSalesModel });
							updateMetadataParam('SalesModel', tmpSalesModel);
							setListViews(['order', 'opportunity', 'agreement', 'accountGrowth']);
							Tools.$rootScope.$broadcast('salesmodel.changed');
						})
						.catch(e => {
							logError(e);
						});
					switch (tmpSalesModel) {
						case 'sales':
							setContributionMargin(false);
							setSalesboardSumContributionMargin(false);
							break;
						case 'cm':
							setContributionMargin(true);
							break;
						case 'rr':
							setContributionMargin(false);
							setSalesboardSumContributionMargin(false);
							enableAgreements();
							break;
						default:
							break;
					}
				}
				if (tmpSalesModelOption !== salesModelOption) {
					skipThisNotification = skipNextNotification;
					skipNextNotification = true;
					Tools.ClientParam.save(234, tmpSalesModelOption, { skipNotification: skipThisNotification })
						.then(() => {
							dataStore.setStore({ salesModelOption: tmpSalesModelOption });
							updateMetadataParam('SalesModelOption', tmpSalesModelOption);
							setListViews(['accountGrowth']);
						})
						.catch(e => {
							logError(e);
						});
					switch (tmpSalesModelOption) {
						case 'sales':
							setSalesboardSumContributionMargin(false);
							break;
						case 'cm':
							setSalesboardSumContributionMargin(true);
							break;
						case 'arr':
						case 'mrr':
							localStorage.setValueRaw(
								`salesboard.displayValueType-rr`,
								tmpSalesModelOption === 'mrr' ? 'monthlyValue' : 'annualValue'
							);
							break;
						default:
							break;
					}
				}
				if (tmpSalesModelOption2 !== salesModelOption2) {
					skipThisNotification = skipNextNotification;
					skipNextNotification = true;
					Tools.ClientParam.save(235, tmpSalesModelOption2, { skipNotification: skipThisNotification })
						.then(() => {
							dataStore.setStore({ salesModelOption2: tmpSalesModelOption2 });
							updateMetadataParam('SalesModelOption2', tmpSalesModelOption2);
						})
						.catch(e => {
							logError(e);
						});
				}
				if (tmpSalesModelOption3 !== salesModelOption3) {
					skipThisNotification = skipNextNotification;
					skipNextNotification = true;
					Tools.ClientParam.save(245, tmpSalesModelOption3, { skipNotification: skipThisNotification })
						.then(() => {
							dataStore.setStore({ salesModelOption3: tmpSalesModelOption3 });
							updateMetadataParam('SalesModelOption3', tmpSalesModelOption3);
							switch (tmpSalesModelOption3) {
								case 'onlyCM':
									localStorage.setValueRaw(
										`salesboard.displayValueType-cm`,
										tmpSalesModelOption === 'cm' ? 'contributionMargin' : 'value'
									);
									break;
								default:
									break;
							}
						})
						.catch(e => {
							logError(e);
						});
				}
				if (tempGroupARRChangesByUser !== groupARRChangesByUser) {
					skipThisNotification = skipNextNotification;
					skipNextNotification = true;
					Tools.ClientParam.save(241, tempGroupARRChangesByUser, { skipNotification: skipThisNotification })
						.then(() => {
							dataStore.setStore({ groupARRChangesByUser: tempGroupARRChangesByUser });
							updateMetadataParam('GroupARRChangesByUser', tempGroupARRChangesByUser);
						})
						.catch(e => {
							logError(e);
						});
				}
			}
		}
	};

	Promise.all([Tools.AppService.getStaticValuesPromise('languages'), Tools.AppService.loadedPromise])
		.then(res => {
			const metadata = Tools.AppService.getMetadata();

			const initialStore = {
				languages: res[0] || [],
				selectedLanguages: [],
				loading: true,
				saving: false,
				savingMasterLanguage: false,
				tmpMasterLanguage: null,
				tmpLanguage: null,
				isAdding: false,
				masterLanguage: null,
				currentlyDeleting: null,
				brokenFiscalYearEnabled: metadata.params.brokenFiscalYearEnabled,
				brokenFiscalYearOffset: metadata.params.brokenFiscalYearOffset,
				savingBrokenFiscalYear: false,
				showFiscalYearSelect:
					metadata.params.brokenFiscalYearEnabled && !metadata.params.brokenFiscalYearOffset,
				salesModel: metadata.params.SalesModel,
				salesModelOption: metadata.params.SalesModelOption,
				salesModelOption2: metadata.params.SalesModelOption2,
				salesModelOption3: metadata.params.SalesModelOption3,
				tmpSalesModel: metadata.params.SalesModel,
				tmpSalesModelOption: metadata.params.SalesModelOption,
				tmpSalesModelOption2: metadata.params.SalesModelOption2,
				tmpSalesModelOption3: metadata.params.SalesModelOption3,
				tmpGroupARRChangesByUser: metadata.params.GroupARRChangesByUser,
				groupARRChangesByUser: metadata.params.GroupARRChangesByUser,
				savingAutoAddProspectingData: false,
				autoAddProspectingDataEnabled: metadata.params.AutoAddProspectingData
			};

			rootNode = document.getElementById('admin-languagesettings-root');
			dataStore = new DataStore(render, actions, initialStore);
			CustomerLanguage.find()
				.then(result => {
					const selectedLanguages = result.data;
					selectedLanguages.reverse();
					const masterLanguage = _.find(selectedLanguages, language => language.isMaster) || null;
					dataStore.setStore({
						loading: false,
						selectedLanguages: result.data,
						masterLanguage: masterLanguage
					});
				})
				.catch(error => logError(error, 'AdminLanguageSettingsCtrl - CustomerLanguage.find'));
		})
		.catch(error => logError(error, 'AdminLanguageSettingsCtrl - [getStaticValuesPromise, loadedPromise]'));
});

export default AdminLanguageSettingsCtrl;

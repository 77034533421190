import React, { useEffect, useRef, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useSelf, useAccountSelf } from 'App/components/hooks/appHooks';
import { useSoftDeployAccess, useSelector } from 'App/components/hooks';
import { matchPath, useLocation } from 'react-router-dom';
import { routes } from 'App/pages/routes';

import { computeViewName, cleanViewUrl, cleanUrl } from './measureUIHelpers';
import { type StateModal } from 'Store/reducers/ModalReducer';

const MeasureUI = ({ children }: { children: React.ReactNode }) => {
	const self = useSelf();
	const accountSelf = useAccountSelf();
	const hasMeasureUI = useSoftDeployAccess('UI_SPEED_MEASURE');
	const [inited, setInited] = useState(false);
	const location: any = useLocation();
	const openModals = useSelector(state => state.Modal.openModals);

	const openModal = openModals?.find(modal => !modal.unmounting);
	const currentlyTrackedView = useRef<string>();
	const isTracking = hasMeasureUI && inited;

	const startView = (viewName: string) => {
		if (viewName !== currentlyTrackedView.current) {
			currentlyTrackedView.current = viewName;
			datadogRum.startView({ name: viewName });
		}
	};

	const registerRouteChange = () => {
		const routeMatch = routes.find(route => matchPath(location.pathname, route));
		// is angular path
		const viewName = routeMatch ? computeViewName(routeMatch.path) : cleanViewUrl(location.pathname);

		startView(viewName);
	};

	const registerModalRoute = (modal: Pick<StateModal, 'name' | 'id'>) => {
		let viewName = `/modal/${modal.name}`;
		if (modal.id) {
			viewName += `/:id`;
		}
		startView(viewName);
	};

	const init = () => {
		datadogRum.init({
			applicationId: 'f4f73da0-d146-4ff6-b573-98210c42c256',
			clientToken: 'pubac124a86122270bc4a3c633ae765a2ec',
			site: 'datadoghq.eu',
			service: 'ui-measures',
			env: process.env.NODE_ENV,
			// Specify a version number to identify the deployed version of your application in Datadog
			version: '1.0.0',
			sessionSampleRate: 100,
			sessionReplaySampleRate: 0,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'mask',
			trackViewsManually: true,
			beforeSend: (event, context) => {
				if (event.type === 'resource') {
					const ommitedUrls = [
						'fast.appcues.com',
						'authPusher/ ',
						'ipa.elev.io',
						'api-iam.intercom.io',
						'api-js.mixpanel.com',
						'googleapis.com',
						'api.segment.io',
						'sockjs',
						'stats.pusher.com'
					];
					const shouldOmitUrl = ommitedUrls.some(url => event.resource.url.includes(url));
					// If fonts are cached, there is no need to track them
					const isCachedFont =
						event.resource.url.includes('fonts.gstatic.com') && event.resource.duration === 0;
					if (shouldOmitUrl || isCachedFont) {
						return false;
					}
					event.resource.url = cleanUrl(event.resource.url);
				}

				if (event.type === 'action' && event.action.target) {
					event.action.target.name = event._dd?.action?.target?.selector ?? '<Failed to get selector>';
				}

				if (event.view?.url) {
					event.view.url = cleanViewUrl(event.view.url);
				}

				if (event.view?.referrer) {
					event.view.referrer = cleanViewUrl(event.view.referrer);
				}

				return true;
			}
		});
	};

	useEffect(() => {
		if (isTracking) {
			if (openModal) {
				registerModalRoute(openModal);
			} else {
				registerRouteChange();
			}
		}
	}, [isTracking, location?.pathname, openModal]);

	useEffect(() => {
		if (hasMeasureUI && !inited) {
			init();
			setInited(true);
		}
	}, [hasMeasureUI, inited]);

	useEffect(() => {
		const unregisterListener = Tools.$rootScope?.$on('$upModal.open', (e: any, modal: any, params: any) => {
			if (isTracking) {
				registerModalRoute({
					name: modal,
					id: params?.id
				});
			}
		});

		return () => unregisterListener?.();
	}, [isTracking]);

	useEffect(() => {
		if (hasMeasureUI && self && accountSelf?.client?.id) {
			datadogRum.setUser({
				id: `${self.id}`,
				customerId: `${accountSelf.client.id}`
			});
		} else {
			datadogRum.clearUser();
		}
	}, [self, accountSelf, hasMeasureUI]);

	return <>{children}</>;
};

export default MeasureUI;

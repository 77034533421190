'use strict';
import { getRatingTranslation } from 'Components/Misc/RatingLabel/RatingLabel';

angular.module('upDirectives').directive('upRatingLabel', [
	'FeatureHelper',
	'AppService',
	function (FeatureHelper, AppService) {
		return {
			restrict: 'A',
			replace: true,
			/* eslint-disable quotes */
			template:
				'<div ' +
				'class="label up-rating-label" ' +
				'ng-class="{' +
				"	'label-success': ratingLabel.split == 'AAA' || ratingLabel.split == 'AA' || ratingLabel.split == 'A'," +
				"	'label-warning': ratingLabel.split == 'B'," +
				"	'label-danger': ratingLabel.split == 'C'," +
				"	'label-default': ratingLabel.split !== 'AAA' && ratingLabel.split !== 'AA' && ratingLabel.split !== 'A' && ratingLabel.split !== 'C' && ratingLabel.split !== 'B'," +
				'}" ' +
				'ng-if="ratingLabel" ' +
				'ng-bind="ratingLabel.split" ' +
				'bs-tooltip ' +
				'data-title="{{ratingLabel.full}}" ' +
				'data-placement="right" ' +
				'data-container="body" ' +
				'></div>',
			/* eslint-enable quotes */
			link: function ($scope, elem, $attrs) {
				// Update rating label
				var getRatingLabel = function (account) {
					if (!account) {
						return;
					}

					if (FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && account.creditRating) {
						var values = AppService.getStaticValues('creditRating');
						var creditRating = _.find(values, { id: account.creditRating });

						if (!creditRating) {
							creditRating = _.find(values, { id: 'unknown' });
						}

						if (creditRating) {
							$scope.ratingLabel = {
								split: creditRating.name.split(' - ')[0],
								full: creditRating.name
							};
						}

						return;
					} else if (
						account.soliditet &&
						account.soliditet.financialData &&
						account.soliditet.financialData.rating
					) {
						$scope.ratingLabel = {
							split: account.soliditet.financialData.rating.split(' - ')[0],
							full: getRatingTranslation(account.soliditet.financialData.rating)
						};
						return;
					} else {
						var ratingCustomField = _.find(account.custom, { alias: 'RATING' });

						if (ratingCustomField && ratingCustomField.value) {
							$scope.ratingLabel = {
								split: ratingCustomField.value.split(' - ')[0],
								full: ratingCustomField.value
							};
							return;
						}
					}
					$scope.ratingLabel = null;
				};

				$scope.$watch($attrs.account, getRatingLabel, true);
			}
		};
	}
]);

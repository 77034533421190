import { Icon, Tooltip, Link, ButtonSelect } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import logError from 'App/babel/helpers/logError';
import { previewTemplate } from 'App/helpers/mailTemplateHelpers';
import { makeCancelable } from 'App/babel/helpers/promise';
import { validateSender } from 'Store/actions/helpers/sharedMailActions';

ReactTemplates.MODALS.MailTemplateBrowser = window.ReactCreateClass({
	getInitialState: function () {
		return {
			page: 1,
			sort: 'modDate',
			sortField: this.props.tools.MailTemplate.attr.modDate.sortable,
			sortAscending: false,
			totalPages: 0,
			templates: [],
			total: 0,
			loading: false,
			search: '',
			galleryStyle: 'thumbnails',
			selectedUser: Tools.AppService.getSelf(),
			accountProfile: null,
			domains: {}
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		var attributes = this.props.tools.MailTemplate.attr;

		this.lang = {
			chooseTemplate: t('form.chooseTemplate'),
			templates: t('mail.templates'),
			template: t('mail.template'),
			choose: t('default.choose'),
			search: t('default.search'),
			name: t(attributes.name.title),
			modDate: t(attributes.modDate.title),
			user: t(attributes.user.title),
			filterOnUser: t('filters.filterOn') + ' ' + t('default.user').toLowerCase(),
			mailTemplate: t('noMatches.mailTemplate'),
			preview: t('mail.preview'),
			warnings: {
				MissingUnsub: t('mailCampaign.templateMissingUnsub'),
				DomainNotVerified: 'mailTemplate.senderDomainNotVerified'
			}
		};

		this.sortTimeout = null;
		this.searchTimeout = null;
	},

	componentDidMount: function () {
		this.getTemplates(1);
		if (this._searchInput) {
			this._searchInput.focus();
		}

		const accountProfilePromise = Tools.AccountProfile.get().catch(err =>
			logError(err, 'mailTemplateBrowser.jsx: Could not get account profile')
		);
		const mailAccountPromise = Tools.MailAccount.get().catch(err =>
			logError(err, 'mailTemplateBrowser.jsx: Could not get mail account')
		);
		const domainsPromise = Tools.MailAccount.domains().catch(err =>
			logError(err, 'mailTemplateBrowser.jsx: Could not get domains')
		);

		this.cancelablePromise = makeCancelable(
			Promise.all([accountProfilePromise, mailAccountPromise, domainsPromise])
		);

		// eslint-disable-next-line promise/catch-or-return
		this.cancelablePromise.promise.then(res => {
			const [
				{ data: accountProfile },
				{ data: mailAccount } = { data: {} },
				{ data: domains } = { data: [] }
			] = res;

			this.setState({
				accountProfile,
				mailAccount,
				domains: domains.reduce((domainMap, { domain, valid }) => ((domainMap[domain] = valid), domainMap), {})
			});
		});
	},

	componentWillUnmount: function () {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	},

	getTemplates: function (page) {
		const LIMIT = 12;
		var self = this;

		self.setState({
			loading: true
		});

		var MailTemplate = self.props.tools.MailTemplate;

		var filter = new self.props.tools.RequestBuilder();
		filter.offset = (page - 1) * LIMIT;
		filter.limit = LIMIT;

		filter.addSort(self.state.sortField, self.state.sortAscending);

		if (self.state.search) {
			filter.addFilter(MailTemplate.attr.name, filter.comparisonTypes.Search, self.state.search);
		}

		if (this.state.selectedUser && this.state.selectedUser.id) {
			filter.addFilter(MailTemplate.attr.user.attr.id, filter.comparisonTypes.Equals, this.state.selectedUser.id);
		}

		MailTemplate.find(filter.build())
			.then(function (res) {
				self.setState({
					totalPages: Math.ceil(res.metadata.total / LIMIT),
					templates: res.data,
					total: res.metadata.total,
					loading: false
				});
			})
			.catch(error => logError(error, 'mailTemplateBrowser.jsx - Failed to get templates'));
	},

	updateSearch: function (e) {
		var self = this;

		self.setState({
			search: e.target.value
		});

		if (self.searchTimeout) {
			clearTimeout(self.searchTimeout);
			self.searchTimeout = null;
		}

		self.searchTimeout = setTimeout(function () {
			self.getTemplates(1);
		}, 500);
	},

	close: function () {
		this.props.closeModal();
	},

	select: function (template) {
		this.props.onSelect(template);
	},

	updateSort: function (e) {
		var self = this;
		var value = e.target.value;

		var attributes = this.props.tools.MailTemplate.attr;
		var sort = attributes[value];
		var asc = true;

		if (value === 'modDate') {
			asc = false;
		}

		self.setState({
			sort: value,
			sortField: sort.sortable,
			sortAscending: asc,
			page: 1
		});

		if (self.sortTimeout) {
			clearTimeout(self.sortTimeout);
			self.sortTimeout = null;
		}

		self.sortTimeout = setTimeout(function () {
			self.getTemplates(1);
		}, 500);
	},

	getSortSelect: function () {
		var self = this;
		var sortOptions = ['name', 'modDate', 'user'];

		var options = sortOptions.map(function (sort) {
			return (
				<option key={sort} value={sort}>
					{self.lang[sort]}
				</option>
			);
		});

		return (
			<select className="sort-select" value={this.state.sort} onChange={self.updateSort}>
				{options}
			</select>
		);
	},

	setGalleryStyle: function (style) {
		if (this.state.galleryStyle !== style) {
			this.setState({
				galleryStyle: style
			});
		}
	},

	setSortOrder: function (isAscending) {
		var self = this;

		if (self.state.sortAscending !== isAscending) {
			self.setState({
				sortAscending: isAscending,
				page: 1
			});

			if (self.sortTimeout) {
				clearTimeout(self.sortTimeout);
				self.sortTimeout = null;
			}

			self.sortTimeout = setTimeout(function () {
				self.getTemplates(1);
			}, 500);
		}
	},

	userFilterChanged: function (user) {
		this.setState({ selectedUser: user });
		this.getTemplates(1);
	},

	getHeader: function () {
		var self = this;

		return (
			<div className="up-modal-header">
				<div className="left-tools">
					<h2>{self.lang.chooseTemplate}</h2>
					<div className="template-total">
						{self.state.total
							? self.state.total +
							  ' ' +
							  (self.state.total === 1
									? self.lang.template.toLowerCase()
									: self.lang.templates.toLowerCase())
							: ''}
					</div>
					<div className="btn-group">
						<ButtonSelect
							disabled={self.state.loading || !self.state.templates}
							options={[
								{ icon: 'list', value: 'list' },
								{ icon: 'th-large', value: 'thumbnails' }
							]}
							onChange={self.setGalleryStyle}
							value={self.state.galleryStyle}
						/>
					</div>
					<div className="search-container">
						<b className="fa fa-search" />
						<input
							type="text"
							className="search-bar"
							onChange={self.updateSearch}
							value={self.state.search}
							placeholder={self.lang.search}
							ref={function (r) {
								self._searchInput = r;
							}}
						/>
					</div>
				</div>
				<div className="right-tools">
					<ReactTemplates.INPUTS.upUsers
						placeholder={this.lang.filterOnUser}
						className="header-select"
						value={this.state.selectedUser}
						onChange={this.userFilterChanged}
					/>
					{self.getSortSelect()}
					<div className="btn-group">
						<ButtonSelect
							disabled={self.state.loading || !self.state.templates}
							options={[
								{ icon: 'sort-alpha-down', value: true },
								{ icon: 'sort-alpha-desc', value: false }
							]}
							onChange={self.setSortOrder}
							value={self.state.sortAscending}
						/>
					</div>
					<b className="fa fa-times" onClick={self.close} />
				</div>
			</div>
		);
	},

	getWarning: function (template) {
		// When OPTIONAL_UNSUB is released we can retire this prop entirely
		const canSendWithoutUnsub = Tools.FeatureHelper.hasSoftDeployAccess('OPTIONAL_UNSUB');
		const requireUnsub = !canSendWithoutUnsub && !!this.props.$modalParams.requireUnsub;

		if (requireUnsub && template.body.indexOf('{{UNSUB') === -1) {
			return this.lang.warnings.MissingUnsub;
		}
		if (template.from) {
			const { valid, domain } = validateSender(this.state.mailAccount, this.state.domains, template.from);
			if (!valid) {
				return Tools.$translate(this.lang.warnings.DomainNotVerified, {
					domain: domain ? `(${domain}) ` : ''
				});
			}
		}
		return null;
	},

	getPreviewGallery: function (template) {
		const templatePreviewStyle = {
			background: 'url("' + template.$$thumbnailUrl + '") no-repeat top center'
		};
		const avatarSize = 25;
		const warning = this.getWarning(template);
		const selected = template.id === this.props.$modalParams.selectedTemplateId;

		return (
			<div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
				<div className="package-box package-box-block cover-img no-responsive no-animate">
					{selected ? <Icon name="check-circle" color="green" /> : null}
					<div className={'overlay' + (warning ? ' overlay-white' : '')}>
						{warning ? (
							<div>
								<b className="fa fa-warning"></b>
								{warning}
							</div>
						) : (
							<>
								<DefaultButton
									className="btn up-btn btn-block btn-bright-blue no-shadow"
									onClick={() => {
										this.select(template);
									}}
								>
									{this.lang.choose}
								</DefaultButton>
								{!selected ? (
									<DefaultButton
										className="btn up-btn btn-block btn-bright-blue no-shadow"
										onClick={e => previewTemplate(e, template, this.state.accountProfile)}
									>
										<Icon name="eye" /> {this.lang.preview}
									</DefaultButton>
								) : null}
							</>
						)}
					</div>
					<div className="preview-image" style={templatePreviewStyle} />
					<div className="package-box-footer lined">
						<div className="package-box-title">
							<h2 style={{ fontSize: '12px !important' }}>{template.name}</h2>
						</div>
						{ReactTemplates.TOOLS.withTooltip(
							<div className="avatar" style={{ height: avatarSize + 'px' }}>
								{React.createElement(ReactTemplates.TOOLS.avatar, {
									user: template.user,
									avatarService: this.props.tools.avatarService,
									size: avatarSize
								})}
							</div>,
							template.user && template.user.name ? template.user.name : '',
							{ placement: 'top', key: template.id }
						)}
					</div>
				</div>
			</div>
		);
	},

	getPreviewListItem: function (template) {
		const avatarSize = 25;
		const warning = this.getWarning(template);

		const trProps = {};
		if (warning) {
			trProps.className = 'unselectable';
		} else {
			trProps.className = 'selectable';
			trProps.onClick = this.select.bind(this, template);
		}

		return (
			<tr key={template.id} {...trProps}>
				<td>{template.name}</td>
				<td>{moment(template.modDate).format('L LT')}</td>
				<td>
					<div>
						{ReactTemplates.TOOLS.withTooltip(
							<div className="avatar" style={{ height: avatarSize + 'px' }}>
								{React.createElement(ReactTemplates.TOOLS.avatar, {
									user: template.user,
									avatarService: this.props.tools.avatarService,
									size: avatarSize
								})}
							</div>,
							template.user && template.user.name ? template.user.name : '',
							{ placement: 'left' }
						)}
						{warning ? (
							<Tooltip title={warning} position="left" distance="25">
								<Icon name="warning" color={'grey-10'} size="lg" />
							</Tooltip>
						) : null}
						{template.id === this.props.$modalParams.selectedTemplateId ? (
							<Icon name="check" color="grey-10" />
						) : null}
						<Link onClick={e => previewTemplate(e, template, this.state.accountProfile)}>
							<Icon name="eye" color="grey-10" />
						</Link>
					</div>
				</td>
			</tr>
		);
	},

	mapUpTemplates: function (state) {
		var methods = {
			thumbnails: this.getPreviewGallery,
			list: this.getPreviewListItem
		};

		return _.map(this.state.templates, methods[state]);
	},

	getBody: function () {
		var templates = this.mapUpTemplates(this.state.galleryStyle);
		if (templates.length === 0) {
			return this.returnTemplateBody(
				<div className="no-templates">{this.lang.mailTemplate}</div>,
				'up-modal-content'
			);
		}

		if (this.state.galleryStyle === 'thumbnails') {
			return this.returnTemplateBody(templates, 'up-modal-content');
		}

		if (this.state.galleryStyle === 'list') {
			var listTemplates = (
				<table className="main-table">
					<thead>
						<tr>
							<th>{this.lang.name}</th>
							<th>{this.lang.modDate}</th>
							<th>{this.lang.user}</th>
						</tr>
					</thead>
					<tbody>{templates}</tbody>
				</table>
			);

			return this.returnTemplateBody(listTemplates, 'up-modal-content no-padding');
		}
	},

	returnTemplateBody: function (body, classes) {
		return <div className={classes}>{body}</div>;
	},

	changePage: function (page) {
		this.setState({
			page: page
		});

		this.getTemplates(page);
	},

	getFooter: function () {
		var self = this;

		return (
			<div className="up-modal-controls">
				{this.state.templates.length
					? React.createElement(ReactTemplates.TOOLS.Paginator, {
							max: self.state.totalPages,
							onChange: self.changePage,
							currentPage: self.state.page
					  })
					: null}
			</div>
		);
	},

	render: function () {
		var header = this.getHeader();
		var body = this.getBody();
		var footer = this.getFooter();

		return (
			<div id="mail-template-chooser">
				{header}
				{body}
				{footer}
			</div>
		);
	}
});

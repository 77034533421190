import {
	APPOINTMENTS_FETCH_SUCCESS,
	APPOINTMENTS_FETCH_ERROR,
	OTHER_EVENTS_FETCH_SUCCESS,
	EVENTS_FETCH_SUCCESS,
	EVENTS_FETCH_ERROR,
	APPOINTMENT_ADDED,
	APPOINTMENT_UPDATED,
	APPOINTMENT_DELETED
} from 'Store/reducers/AppointmentsReducer';

export const otherEventsFetchSuccess = value => ({ type: OTHER_EVENTS_FETCH_SUCCESS, value });
export const eventsFetchSuccess = value => ({ type: EVENTS_FETCH_SUCCESS, value });
export const eventsFetchError = value => ({ type: EVENTS_FETCH_ERROR, value });
export const appointmentFetchSuccess = value => ({ type: APPOINTMENTS_FETCH_SUCCESS, value });
export const appointmentFetchError = value => ({ type: APPOINTMENTS_FETCH_ERROR, value });
export const appointmentAdded = value => ({ type: APPOINTMENT_ADDED, value });
export const appointmentUpdated = value => ({ type: APPOINTMENT_UPDATED, value });
export const appointmentDeleted = value => ({ type: APPOINTMENT_DELETED, value });

export const getAppointments = (date, activeFilters = {}) => {
	return dispatch => {
		/*
			Fetch appointment for current month, the previous and next.
			Then we should have appointments that for example have a startDate prev month but ends in the current
		*/
		const customerId = Tools.AppService.getCustomerId();
		const filters = Tools.FilterHelper.parseFilters(activeFilters, 'appointment');

		filters.addFilter(
			Tools.Appointment.attr.date,
			filters.comparisonTypes.GreaterThanEquals,
			moment(date).add(-1, 'month').startOf('month').startOf('week')
		);
		filters.addFilter(
			Tools.Appointment.attr.date,
			filters.comparisonTypes.LessThanEquals,
			moment(date).add(1, 'month').endOf('month').endOf('week')
		);

		return Tools.Appointment.customer(customerId)
			.find(filters.build())
			.then(res => {
				dispatch(appointmentFetchSuccess(res.data));
			})
			.catch(e => {
				dispatch(appointmentFetchError(e.message));
			});
	};
};

export const getEvents = (date, userIds) => {
	return dispatch => {
		const calendarIntegrations = Tools.AppService.getCalendarIntegrations();
		if (calendarIntegrations.length === 0) {
			return;
		}

		const [integration] = calendarIntegrations;
		const currentUserId = Tools.AppService.getSelf().id;

		return Tools.StandardIntegration.data(currentUserId)
			.run({
				data: {
					startDateTime: moment.utc(date).add(-1, 'month').startOf('month').startOf('week').format(),
					endDateTime: moment.utc(date).add(1, 'month').endOf('month').endOf('week').format()
				},
				userIds,
				type: 'events',
				integrationId: integration.id
			})
			.then(res => {
				const eventMapper = event => ({
					...event,
					date: new Date(event.startDateTime),
					endDate: new Date(event.endDateTime),
					isEvent: true
				});

				const events =
					!userIds?.length || userIds?.indexOf(currentUserId) >= 0
						? (res.data.events ?? []).map(eventMapper)
						: [];

				let otherUsers = [];
				if (res.data.otherUsers) {
					for (const events of Object.values(res.data.otherUsers)) {
						otherUsers = otherUsers.concat(events.map(eventMapper));
					}
				}

				dispatch(eventsFetchSuccess(events));
				dispatch(otherEventsFetchSuccess(otherUsers));
			})
			.catch(e => {
				dispatch(eventsFetchError(e.message));
			});
	};
};

export const addAppointment = appointment => {
	return dispatch => {
		dispatch(appointmentAdded(appointment));
	};
};

export const updateAppointment = appointment => {
	return dispatch => {
		dispatch(appointmentUpdated(appointment));
	};
};

export const deleteAppointment = appointment => {
	return dispatch => {
		dispatch(appointmentDeleted(appointment));
	};
};

'use strict';

angular.module('upDirectives').directive('upScrollTo', [
	'$window',
	function ($window) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var scrollTo = function (elementId) {
					var offset = attrs.offset || 0;
					if (!elementId) {
						$window.scrollTo(0, 0);
					}
					var el = angular.element(document.getElementById(elementId));
					if (el) {
						$window.scrollTo(0, el.position().top - offset);
					}
				};

				if (attrs.scrollOnFocus !== undefined) {
					element.on('focus', function (e) {
						e.preventDefault();
						element[0].scrollTop = 0;
					});
				} else {
					element.on('click', function (e) {
						e.preventDefault();
						scrollTo(attrs.upScrollTo);
					});
				}
			}
		};
	}
]);

'use strict';

angular.module('domain.lead').factory('EditLeadMeta', [
	'$q',
	'Lead',
	'LeadSource',
	'Leadchannels',
	'Campaign',
	'CustomfieldMapper',
	'NotificationService',
	'AppService',
	function ($q, Lead, LeadSource, Leadchannels, Campaign, CustomfieldMapper, NotificationService, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();

				var promises = {
					campaigns: Campaign.customer(customerId).find({}),
					leadsources: LeadSource.get({ customerId: $modalParams.customerId }).$promise,
					leadchannels: Leadchannels.get({ customerId: $modalParams.customerId }).$promise,
					users: $q.when({ data: AppService.getActiveUsers() }),
					customFields: $q.when({ data: AppService.getCustomFields('lead') }),
					self: $q.when(AppService.getSelf())
				};

				if ($modalParams.id) {
					promises.lead = Lead.customer(customerId).get($modalParams.id);
				} else if ($modalParams.lead) {
					promises.lead = $q.when({ data: $modalParams.lead });
				} else {
					promises.lead = $q.when({ data: {} });
				}

				return $q
					.all(promises)
					.then(function (response) {
						response.lead.data.custom = CustomfieldMapper.parse(
							response.customFields.data,
							response.lead.data.custom
						);

						// Fix for when a user are selected and no longer exist
						var user = response.lead.data.user;
						if (user && !_.some(response.users.data, { id: user.id })) {
							response.users.data.push(user);
						}

						return response;
					})
					.catch(function (err) {
						NotificationService.addNotification({
							title: 'default.error',
							body: 'openError.lead',
							style: 'error',
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

'use strict';

angular.module('domain.engage').factory('EditAdMeta', [
	'$q',
	'RequestBuilder',
	'Ads',
	'$translate',
	'AppService',
	'localStorageService',
	function ($q, RequestBuilder, Ads, $translate, AppService, localStorageService) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var adId = $stateParams.id;
				var promises = {
					credits: Ads.customer(customerId).getCredits()
				};

				if ($stateParams.id) {
					promises.ad = Ads.customer(customerId)
						.get(adId)
						.then(function (res) {
							if ($stateParams.copy) {
								res.data.name =
									res.data.name + ' (' + $translate.instant('default.copied').toLowerCase() + ')';
								delete res.data.id;
							}
							return res;
						});
				} else if ($stateParams.fromStorage) {
					var campaign = angular.copy(localStorageService.get('activeAdCampaign'));
					// localStorageService.remove('activeAdCampaign');
					promises.ad = $q.when({ data: campaign });
				}

				return $q.all(promises);
			});
		};
	}
]);

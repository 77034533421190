import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImportColumnShape from '../../propTypes/ImportColumnShape';
import { ButtonGroup, Button, Tooltip, Icon } from '@upsales/components';
import Select from 'react-select';
import _ from 'lodash';
import './ImportColumn.scss';

import { reactSelectStyles, reactSelectComponents, noOptionsMessage } from 'App/helpers/reactSelectSettings';

export class SingleOptionComponent extends Component {
	static propTypes = {
		option: PropTypes.object,
		className: PropTypes.string,
		onSelect: PropTypes.func
	};

	render() {
		const { option, className, onSelect } = this.props;

		if (option.isButtonGroup) {
			return option.render;
		}

		if (option.header) {
			return (
				<div
					data-testid="header"
					onMouseDown={e => e.stopPropagation()}
					className={`import-select-header ${className}`}
					key={option.label}
				>
					{option.label}
				</div>
			);
		}

		let classes = 'import-select-option';
		if (option.isCustom) classes += ' import-select-custom-option';
		if (className) classes += ' ' + className;

		return (
			<div
				data-testid="option"
				key={`selectable-option-${option.value}`}
				className={classes}
				onMouseDown={e => {
					onSelect(option);
					e.stopPropagation();
				}}
			>
				{option.label}
				{option.importDesc && (
					<Tooltip position="bottom" distance="20" title={option.importDesc} className="pull-right">
						<Icon name={'question-circle'} />
					</Tooltip>
				)}
			</div>
		);
	}
}

export const selectOption = (data, value, label, render, disabled = false) => {
	return { value, label, render, disabled, ...data };
};

export const formatTags = availableTags => {
	const allTags = _.flatten(
		_.map(availableTags, list => {
			const fields = [];
			const customFields = [];

			_.map(list.children, field => {
				if (field.tag.includes('custom')) {
					field.isCustom = true;
					customFields.push(field);
				} else {
					fields.push(field);
				}
			});

			if (customFields.length) {
				return [
					{ tag: list.name, header: true },
					...fields,
					{ tag: list.name + ': ' + Tools.$translate('default.customField'), header: true },
					...customFields
				];
			}

			return [{ tag: list.name, header: true }, ...fields];
		})
	);

	return _.map(allTags, tag => {
		if (tag.header) return selectOption(tag, tag.tag, tag.tag, tag.tag, true);
		return selectOption(tag, tag.tag, tag.name, tag.tag);
	});
};

export default class extends Component {
	constructor(props) {
		super(props);
		const availableTags = props.getAvailableTags();
		const tags = formatTags(availableTags);
		const hideBtnGroup = props.importType !== Tools.Import.type.CLIENT_AND_CONTACT;

		this.state = {
			selectedField: null,
			contactsTabActive: true,
			availableTags,
			tags,
			hideBtnGroup,
			insertIndex: tags.length
		};
	}

	static propTypes = {
		changeMatch: PropTypes.func,
		column: ImportColumnShape,
		height: PropTypes.number,
		clientIdPresent: PropTypes.bool,
		numRows: PropTypes.number,
		getAvailableTags: PropTypes.func,
		setField: PropTypes.func,
		refTags: PropTypes.object,
		importType: PropTypes.string
	};

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		var t = Tools.$translate;
		this.lang = {
			selectField: t('import.selectField'),
			contact: t('default.contact'),
			client: t('default.client'),
			noImport: t('import.noImport'),
			change: t('default.change'),
			useForMatching: t('import.useForMatching'),
			dontImport: t('import.dontImport'),
			pickField: t('import.pickFieldInUpsales'),
			exampleDataFromFile: t('admin.exampleDataFromFile'),
			willImport: t('import.willImport'),
			columnInFile: t('import.columnInFile'),
			needConnectionToUpsalesField: t('admin.needConnectionToUpsalesField'),
			importToUpsalesField: t('admin.importToUpsalesField'),
			helpTooltip: t('import.column.helpTooltip.connectingFields')
		};
	}

	componentDidMount() {
		const {
			column: { field },
			refTags: { client, contact, product },
			importType
		} = this.props;
		const { selectedField } = this.state;

		switch (importType) {
			case Tools.Import.type.PRODUCT:
				if (field && !selectedField && product?.length) {
					const tag = _.find(product, { tag: field });
					if (tag) {
						this.setState({ selectedField: selectOption(tag, tag.tag, tag.name, tag.tag) });
					}
				}
				break;
			default:
				if (field && !selectedField && client?.length && contact?.length) {
					const tag = _.find(client, { tag: field }) || _.find(contact, { tag: field });
					if (tag) {
						this.setState({ selectedField: selectOption(tag, tag.tag, tag.name, tag.tag) });
					}
				}
		}
	}

	componentDidUpdate() {
		const prevAvailableTags = this.state.availableTags;
		const newAvailableTags = this.props.getAvailableTags();

		if (!_.isEqual(prevAvailableTags, newAvailableTags)) {
			const tags = formatTags(newAvailableTags);
			this.setState({ availableTags: newAvailableTags });
			this.setState({ tags });
		}
	}

	getIndices(array, contact, client) {
		const findIndexOnContacts = _.findIndex(array, { value: contact });
		const findIndexOnClients = _.findIndex(array, { value: client });
		return [findIndexOnContacts, findIndexOnClients];
	}

	renderBtnGroup(array) {
		const { tags } = this.state;
		const { contact, client } = this.lang;

		const [contactIndex, clientIndex] = this.getIndices(array || tags, contact, client);

		return {
			isButtonGroup: true,
			render: (
				<ButtonGroup block={true} className="import-select-btn-group">
					<Button
						shadow="none"
						size="sm"
						onClick={() => this.changeTab(true, contactIndex)}
						type={!this.state.contactsTabActive ? 'lined' : null}
					>
						<Icon name={'user'} className="pull-left" />
						{contact}
					</Button>
					<Button
						shadow="none"
						size="sm"
						onClick={() => this.changeTab(false, clientIndex)}
						type={this.state.contactsTabActive ? 'lined' : null}
					>
						<Icon name={'home'} className="pull-left" />
						{client}
					</Button>
				</ButtonGroup>
			),
			disabled: true
		};
	}

	changeVal = data => {
		const array = formatTags(this.props.getAvailableTags());

		let insertIndex = _.findIndex(array, { value: data?.value });

		if (insertIndex === -1) {
			insertIndex = this.state.insertIndex;
		} else if (insertIndex >= this.state.insertIndex) {
			insertIndex++;
		}

		if (data) {
			this.setState({
				selectedField: selectOption(data, data.value, data.label, data.render),
				insertIndex
			});
			this.props.setField(data, this.props.column.id);
		} else this.setState({ selectedField: '' });
	};

	changeTab = (contactsTabActive, index) => {
		this.scrollToElement(index * 30 - 5);
		this.setState({ contactsTabActive });
	};

	scrollToElement = howFar => {
		const COMPONENT = document.querySelector('.select__menu-list');
		if (COMPONENT) {
			COMPONENT.scrollTo({
				top: howFar,
				left: 0,
				behavior: 'smooth'
			});
		}
	};

	filterOptions = (option, inputValue) => {
		const filter = this.state.tags.filter(opt => {
			if (opt.header) return true;
			return opt.label.toLowerCase().includes(inputValue.toLowerCase());
		});
		const { selectedField, insertIndex } = this.state;
		if (selectedField) {
			filter.splice(insertIndex, 0, selectedField);
		}
		if (this.state.hideBtnGroup) {
			return [...filter];
		} else {
			return [this.renderBtnGroup(filter), ...filter];
		}
	};

	render() {
		const { selectedField, tags, hideBtnGroup } = this.state;

		var self = this;
		var column = this.props.column;
		var statusClass = 'red';
		var columnClass = 'column';
		var statusText = (
			<span>
				{this.lang.needConnectionToUpsalesField}
				<Tooltip title={this.lang.helpTooltip}>
					<ReactTemplates.elevio sidebar={true} articleId={598} />
				</Tooltip>
			</span>
		);

		if (column.skip) {
			statusClass = 'grey';
			statusText = this.lang.noImport;
			columnClass += ' skip';
		} else if (column.field) {
			statusClass = 'green';
			statusText = this.lang.willImport;
			columnClass += ' valid';
		}

		var rows = _.map(column.sampleValues, function (row, i) {
			if (i < 5) {
				return <li key={'r-' + row + i}>{row}</li>;
			}
		});

		return (
			<div className={columnClass}>
				<div className={'column-status ' + statusClass}>
					{statusClass === 'green' ? (
						<Icon name="check" />
					) : statusClass === 'red' ? (
						<Icon name="times" />
					) : null}
					{statusText}
				</div>
				<div className="column-head">
					<span className={'column-label' + (column.skip || column.field ? ' column-label-hidden' : '')}>
						{this.lang.columnInFile}
					</span>
					<Tooltip title={column.name}>
						<div className="field-name">
							<i className="fa fa-file-excel-o" />
							{column.name}
						</div>
					</Tooltip>

					<div
						className={'connection-link' + (column.field ? ' connection-link-visible' : '')}
						style={{ zIndex: 0 }}
					>
						<b className="fa fa-link" />
					</div>

					<span className={'column-label' + (column.skip || column.field ? ' column-label-hidden' : '')}>
						{this.lang.importToUpsalesField}
					</span>
					<div
						className={`ImportSelectTag ${
							!column.skip ? (selectedField ? 'has-success' : 'has-error') : ''
						} ${hideBtnGroup ? 'hide-btn-group' : ''}`}
					>
						<Select
							classNamePrefix={'select'}
							instanceId="importSelect"
							placeholder={this.lang.selectField}
							value={selectedField}
							onChange={this.changeVal}
							isDisabled={!!column.skip}
							isClearable={false}
							isSearchable={true}
							onBlur={() => this.setState({ contactsTabActive: true })}
							filterOptions={this.filterOptions}
							onClose={() => this.setState({ contactsTabActive: true })}
							options={hideBtnGroup ? [...tags] : [this.renderBtnGroup(), ...tags]}
							menuContainerStyle={{ width: '380px' }}
							noOptionsMessage={noOptionsMessage}
							styles={reactSelectStyles({
								menuList: { width: '380px' },
								overrides: {
									control: (provided, state) => ({
										...provided,
										minHeight: '34px',
										zIndex: state.isFocused ? '10' : '1',
										width: state.isFocused ? '380px' : 'auto'
									})
								},
								menu: { width: '380px' }
							})}
							components={reactSelectComponents({
								Option: ({ innerProps, data }) => {
									return <SingleOptionComponent option={data} onSelect={innerProps.onClick} />;
								}
							})}
						/>
					</div>

					<Button
						color={column.skip ? 'bright-blue' : 'orange'}
						type={column.skip ? 'lined' : null}
						block={true}
						className="skip-btn"
						onClick={function () {
							self.changeVal();
							self.props.importToggle(column.id, column.skip);
						}}
					>
						{column.skip ? this.lang.change : this.lang.dontImport}
					</Button>

					{column.field === 'Client.Id' ? (
						<div className="column-info">
							<ReactTemplates.elevio sidebar={true} articleId={598} />
						</div>
					) : null}
				</div>
				<ul className="ImportColumn-sample-values" style={{ height: this.props.height + 'px' }}>
					<li key="heading" className="">
						{this.lang.exampleDataFromFile}
					</li>
					{rows}
				</ul>
				{column.emptyRows !== null ? (
					<div className="column-empty-rows">
						<Icon name="info" />
						<span>
							{Tools.$translate('import.emptyRows', {
								rows: this.props.numRows
									? Math.floor(((this.props.numRows - column.emptyRows) / this.props.numRows) * 100)
									: 0
							})}
						</span>
					</div>
				) : null}
			</div>
		);
	}
}

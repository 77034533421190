'use strict';

angular.module('upResources').factory('LookerDashboard', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function Looker($resource, $q, URL, API, ResourceHelper) {
		var baseUrl = URL + API + 'looker/dashboards/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				update: {
					method: 'PUT',
					isArray: false
				},
				list: {
					method: 'GET',
					isArray: false
				}
			}
		);

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'lookerDashboard',
			updateSuccessBody: 'updated.lookerDashboard',
			updateErrorBody: 'saveError.lookerDashboard'
		});

		var notifyUser = _.debounce(function (data) {
			helper.onSave({}, data, false);
		}, 500);

		var Model = {
			update: function (id, roleIds) {
				var defered = $q.defer();
				if (!id || roleIds === undefined) {
					return defered.reject('No params');
				}

				Resource.update({ id: id || 0 }, { roleIds: roleIds }, defered.resolve, defered.reject);

				defered.promise
					.then(function (res) {
						// Notify user
						notifyUser(res.data);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError({}, false);
					});

				return defered.promise;
			},
			save: function (dashboard) {
				var defered = $q.defer();
				if (!dashboard.id) {
					return defered.reject('No params');
				}

				Resource.update({ id: dashboard.id }, dashboard, defered.resolve, defered.reject);

				defered.promise
					.then(function (res) {
						// Notify user
						notifyUser(res.data);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError({}, false);
					});

				return defered.promise;
			},
			list: function () {
				return Resource.get({}).$promise;
			}
		};

		return Model;
	}
]);

import PropTypes from 'prop-types';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import ToggleList from './ToggleList';

import './Segment.scss';

export default class Segment extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			placeholder: Tools.$translate('segment.selectCriteriaPlaceholder'),
			isNot: Tools.$translate('comparison.NotEquals'),
			onlySegmentWithoutSegment: Tools.$translate('segment.onlySegmentWithoutSegment'),
			all: Tools.$translate('default.all'),
			any: Tools.$translate('default.any')
		};

		this.onValueChange = this.onChange.bind(this, 'value');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');

		this.recentlyChanged = false;
		this.recentlyChangedTimeout = null;
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'value':
				newFilter.value = value;
				break;
			case 'comperator':
				newFilter.comparisonType = value;
				break;
		}

		newFilter.inactive = !(Array.isArray(newFilter.value) && newFilter.value.length);
		onChange(newFilter.value, newFilter);

		this.recentlyChanged = true;
		clearTimeout(this.recentlyChangedTimeout);
		this.recentlyChangedTimeout = setTimeout(() => {
			this.recentlyChanged = false;
		}, 3000);
	}

	render() {
		const { filter, config, selectData, LIST_AJAX_LIMIT, autoFocus, listStateError } = this.props;
		const infiniteRecursionError =
			listStateError && listStateError.errorCode === 107 && this.recentlyChanged ? true : false;
		const classes = new BemClass('SegmentInputWrapper');

		const listProps = {
			config: config,
			defaultValue: filter.value,
			onChange: this.onValueChange,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT,
			placeholder: this.lang.placeholder,
			autoOpen: autoFocus && true
		};

		const toggleProps = {
			options: [
				{
					value: 'Equals',
					text: this.lang.any
				},
				{
					value: 'EqualsAll',
					text: this.lang.all
				},
				{
					value: 'NotEquals',
					text: this.lang.isNot
				}
			],
			onClick: this.onComperatorChange,
			value: filter.comparisonType
		};

		return (
			<CriteriaRowWrapper className={classes.mod({ 'segment-error': infiniteRecursionError }).b()}>
				<div className="criteria-row">
					<ToggleList
						list={listProps}
						toggle={toggleProps}
						className={infiniteRecursionError ? 'multi-comparitors-wrap--error' : ''}
					/>
					{infiniteRecursionError ? <ReactTemplates.elevio articleId={825} sidebar={true} /> : null}
				</div>
			</CriteriaRowWrapper>
		);
	}
}

Segment.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool.isRequired,
	listStateError: PropTypes.object
};

window.SegmentFilterInput = Segment;

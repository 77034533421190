'use strict';

const { Toggle } = require('@upsales/components');

ReactTemplates.form.textContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element,
			bgColor: this.getInitialBgColor(),
			ckPosition: Tools.browserService.isFirefox() ? '' : 'position: absolute; '
		};
	},
	getInitialBgColor: function (next) {
		var self = this;
		var hexCodes = next
			? next.element.value.text.match(/#([a-fA-F0-9]{3}){1,2}\b/gi)
			: this.props.element.value.text.match(/#([a-fA-F0-9]{3}){1,2}\b/gi);
		var suitableBgColor = _.map(hexCodes, function (hex) {
			return window.getTextColor(hex);
		});

		var result = self.getMaxOccurences(suitableBgColor);

		return result[0];
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	componentWillUnmount: function () {
		this.editor.destroy();
	},
	mountEditor: function (initialData) {
		if (this.editor) {
			this.editor.destroy();
		}
		var textarea = this._textarea;
		this.editor = CKEDITOR.replace(textarea, this.editorOptions);

		if (initialData) {
			this.editor.setData(initialData);
		}

		this.editor.on('change', this.onChange);
		this.editor.on('keyup', this.onChange);
		this.editor.on('blur', this.onChange);
		var self = this;

		this.editor.on('instanceReady', function (editor) {
			if (self.editorOptions.init) {
				self.editorOptions.init(editor);
			}
			self.setEditorBackground.bind(self, editor);
		});
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		var self = this;
		self.lang = {
			videoAddress: t('landingpage.videoAddress')
		};

		// CKeditor options
		self.editorOptions = Tools.EditorCk.getOptions({
			height: window.innerHeight > 700 ? '600px' : '300px',
			hideImage: true,
			hideTags: !self.props.strictTagList,
			strictTagList: self.props.strictTagList,
			extraPlugins: 'lineheight'
		});
		self.editorOptions.toolbar = [
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', 'Align', 'TextColor', 'RemoveFormat', 'Format']
			},
			{ name: 'styles', items: ['Font', 'FontSize', 'lineheight'] },
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock',
					'Link',
					'Unlink'
				]
			}
		];

		// Colors
		var colorsArr = _.map(_.values(self.props.accountProfile.colors), function (color) {
			return color.substring(1);
		});
		var extraColors = _.map(_.values(self.props.accountProfile.extraColors), function (color) {
			return color.value.substring(1);
		});
		self.editorOptions.colorButton_colors = colorsArr.concat(extraColors).join(',');
		self.editorOptions.colorButton_enableAutomatic = false;
		self.editorOptions.forcePasteAsPlainText = true;

		// Setup format tags
		// For inline style definition.
		self.editorOptions.format_tags =
			'maintitle;maintitlelarge;secondarytitle;secondarytitlelarge;ingress;paragraph';
		self.editorOptions.format_paragraph = {
			name: t('admin.paragraph'),
			element: 'p',
			attributes: { class: 'paragraph' }
		};
		self.editorOptions.format_ingress = {
			name: t('admin.preamble'),
			element: 'p',
			attributes: { class: 'ingress' }
		};
		self.editorOptions.format_maintitle = { name: t('admin.mainTitle'), element: 'h2' };
		self.editorOptions.format_maintitlelarge = {
			name: `${t('admin.mainTitle')} large`,
			element: 'h2',
			attributes: { class: 'large' }
		};
		self.editorOptions.format_secondarytitle = { name: t('admin.secondaryTitle'), element: 'h3' };
		self.editorOptions.format_secondarytitlelarge = {
			name: `${t('admin.secondaryTitle')} large`,
			element: 'h3',
			attributes: { class: 'large' }
		};

		// Remove ck-footer
		self.editorOptions.removePlugins = 'elementspath,contextmenu,scayt,tabletools,liststyle,tableselection';

		// Set enter-key behaviour
		self.editorOptions.enterMode = CKEDITOR.ENTER_DIV;
		self.editorOptions.shiftEnterMode = CKEDITOR.ENTER_BR;

		self.editorOptions.font_names = CKEDITOR.config.font_names;

		if (self.props.accountProfile.fonts) {
			var googleFonts = [];

			self.props.accountProfile.fonts.forEach(function (font) {
				googleFonts.push(font.family);
				self.editorOptions.font_names = font.family + '/' + font.family + ';' + self.editorOptions.font_names;
			});

			self.editorOptions.contentsCss = googleFonts.map(function (font) {
				return 'https://fonts.googleapis.com/css?display=swap&family=' + encodeURI(font);
			});

			if (googleFonts.length) {
				window.WebFont.load({
					google: {
						families: googleFonts
					}
				});
			}
		}

		self.editorOptions.fontSize_sizes =
			'8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;26/26px;27/27px;28/28px;29/29px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;42/42px;44/44px;46/46px;48/48px;50/50px;52/52px;54/54px;56/56px;58/58px;60/60px;';
	},
	UNSAFE_componentWillUpdate: function (nextProps, nextState) {
		if (nextState.element.id !== this.state.element.id) {
			var nextText = nextState.element.value.text;
			var color = this.getInitialBgColor(nextState);

			if (color !== this.state.bgColor) {
				this.setState({ bgColor: color });
			}

			nextText =
				'<div id="tmp" style="background: ' +
				color +
				';width: 100%; height: fit-content; min-height: 100%;' +
				this.state.ckPosition +
				'padding: 20px;top: 0; bottom: 0; left: 0; right: 0;">' +
				nextState.element.value.text +
				'</div>';
			this.mountEditor(nextText);
		}
	},
	componentDidMount: function () {
		this.mountEditor();
	},
	onChange: function (e) {
		var element = this.state.element;
		element.value = { text: this.editor.getData() };

		var tmpDiv = document.createElement('div');
		tmpDiv.innerHTML = element.value.text;
		var backGroundDiv = tmpDiv.querySelector('#tmp');

		element.value.text = backGroundDiv ? backGroundDiv.innerHTML : tmpDiv.innerHTML;

		this.setState({ element: element });
		this.props.onChange(element);
		if (Tools.browserService.isFirefox) {
			this.setEditorBackground(e);
		}
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getMaxOccurences: function (suitableBgColor) {
		var distribution = {};
		var max = 0;
		var result = [];

		suitableBgColor.forEach(function (a) {
			distribution[a] = (distribution[a] || 0) + 1;
			if (distribution[a] > max) {
				max = distribution[a];
				result = [a];
				return;
			}
			if (distribution[a] === max) {
				result.push(a);
			}
		});

		return result;
	},
	setEditorBackground: function (e) {
		var editorElements = document.getElementsByClassName(e.editor.id);
		if (editorElements.length > 0) {
			var iframe = editorElements[0].getElementsByTagName('iframe')[0].contentWindow;
			iframe.document.getElementsByTagName('body')[0].style.background = this.state.bgColor || '#FFFFFF';
		}
	},
	changeBackground: function () {
		var self = this;
		var nextColor = '';
		var nextText = self.state.element.value.text;

		if (self.state.bgColor === '#222222') {
			nextColor = '#FFFFFF';
		} else {
			nextColor = '#222222';
		}
		nextText =
			'<div id="tmp" style="background: ' +
			nextColor +
			';width: 100%; height: fit-content; min-height: 100%;' +
			this.state.ckPosition +
			'padding: 20px;top: 0; bottom: 0; left: 0; right: 0;">' +
			self.state.element.value.text +
			'</div>';
		this.editor.editable().setHtml(nextText);
		this.setState({
			bgColor: nextColor
		});
	},
	render: function () {
		var self = this;
		var textValue = null;
		textValue =
			'<div id="tmp" style="background: ' +
			self.state.bgColor +
			';width: 100%; height: fit-content; min-height: 100%;' +
			this.state.ckPosition +
			'padding: 20px;top: 0; bottom: 0; left: 0; right: 0;">' +
			self.state.element.value.text +
			'</div>';
		return (
			<div className="tool-main-section no-padding">
				<textarea value={textValue} ref={self.setRef.bind(self, '_textarea')} />
				<div
					className="tool-section"
					id="change-background"
					style={{ backgroundColor: '#fafafa', padding: '10px 15px' }}
				>
					<Toggle
						color="medium-green"
						checked={self.state.bgColor === '#222222'}
						onChange={self.changeBackground}
					/>
					<label className="pull-right">{window.Tools.$translate('form.editor.darkMode')}</label>
				</div>
			</div>
		);
	}
});

import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';

angular.module('domain.mail').controller('MailCampaignRecipients', [
	'$scope',
	'AppService',
	'Mail',
	'RequestBuilder',
	'MultiRunnerActions',
	'$multiSelect',
	'$upModal',
	'Contact',
	'MailCampaignInfo',
	'$stateParams',
	'$safeApply',
	function (
		$scope,
		AppService,
		Mail,
		RequestBuilder,
		MultiRunnerActions,
		$multiSelect,
		$upModal,
		Contact,
		MailCampaignInfo,
		$stateParams,
		$safeApply
	) {
		var MailCampaignCtrl = $scope.MailCampaignCtrl;
		var getTimeout;
		var limit = 50;
		var searchStr = '';

		MailCampaignCtrl.rootData.pageComponent = 'recipients';
		MailCampaignCtrl.rootData.hasMultiSelect = true;

		var sendGroupMailAction = function (type) {
			var action = Tools.MultiRunnerActions.get(Tools.MultiRunnerActions.type.CONTACT).sendGroupMail;
			var listView = { filters: {} };
			var filter = Tools.FilterHelper.filter('MailCampaignFilters', 'contact');
			filter.inactive = false;
			filter.value = {
				inactive: false,
				MailCampaign: { value: [MailCampaignCtrl.rootData.mailCampaign.id] },
				EventType: { value: type, not: true },
				hasMailCampaign: true,
				Date: { value: { preset: 'whenever' } }
			};

			listView.filters.MailCampaignFilters = filter;

			return action.run(listView);
		};

		var addFilters = function (rb) {
			if (MailCampaignCtrl.rootData.pageData.filters.deliveredGroup) {
				if (MailCampaignCtrl.rootData.pageData.filters.open) {
					rb.addFilter(Mail.attr.lastReadDate, rb.comparisonTypes.NotEquals, null);
				}
				if (MailCampaignCtrl.rootData.pageData.filters.click) {
					rb.addFilter(Mail.attr.lastClickDate, rb.comparisonTypes.NotEquals, null);
				}
				if (MailCampaignCtrl.rootData.pageData.filters.submit) {
					rb.addFilter(
						{ field: 'contact.formSubmit.leadSource.numvalue' },
						rb.comparisonTypes.Equals,
						MailCampaignCtrl.rootData.mailCampaign.id
					);
				}
				if (MailCampaignCtrl.rootData.pageData.filters.unsub) {
					rb.addFilter({ field: 'events.type' }, rb.comparisonTypes.Equals, 'unsub');
				}

				rb.addFilter(Mail.attr.errorCause, rb.comparisonTypes.Equals, null);
			} else {
				var errors = [];
				if (MailCampaignCtrl.rootData.pageData.filters.bounce) {
					errors.push('hard_bounce');
					errors.push('soft_bounce');
				}
				if (MailCampaignCtrl.rootData.pageData.filters.prevbounce) {
					errors.push('previous_bounce');
				}

				if (MailCampaignCtrl.rootData.pageData.filters.prevunsub) {
					errors.push('previous_unsubscribed');
				}
				if (MailCampaignCtrl.rootData.pageData.filters.duplicates) {
					errors.push('duplicate_email');
				}
				if (MailCampaignCtrl.rootData.pageData.filters.invalidemail) {
					errors.push('invalid_email');
				}
				if (MailCampaignCtrl.rootData.pageData.filters.unverifiedSender) {
					errors.push('unverified_sender');
				}

				if (errors.length) {
					rb.addFilter(Mail.attr.errorCause, rb.comparisonTypes.MatchExact, errors);
				} else {
					rb.addFilter(Mail.attr.errorCause, rb.comparisonTypes.NotEquals, null);
				}
			}
		};

		var getMail = function () {
			MailCampaignCtrl.rootData.pageData.loading = true;

			if (getTimeout) {
				clearTimeout(getTimeout);
			}

			getTimeout = setTimeout(function () {
				var rb = new RequestBuilder();
				rb.limit = limit;
				rb.offset = limit * (MailCampaignCtrl.rootData.pageData.activePage - 1);

				if (searchStr && searchStr.length) {
					var orBuilder = rb.orBuilder();

					orBuilder.next();
					orBuilder.addFilter(Mail.attr.client.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(Mail.attr.contact.attr.name, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.next();
					orBuilder.addFilter(Mail.attr.to, rb.comparisonTypes.Wildcard, searchStr);

					orBuilder.done();
				}

				if (MailCampaignCtrl.rootData.pageData.selectedLink != null) {
					const groupBuilder = rb.groupBuilder();
					groupBuilder.addFilter({ field: 'events.type' }, rb.comparisonTypes.Equals, 'click');
					groupBuilder.addFilter(
						{ field: 'events.value' },
						rb.comparisonTypes.Equals,
						MailCampaignCtrl.rootData.pageData.selectedLink
					);
					groupBuilder.done();
				}

				rb.addSort(
					MailCampaignCtrl.rootData.pageData.currentSorting.attr,
					MailCampaignCtrl.rootData.pageData.currentSorting.asc
				);

				rb.addFilter(
					Mail.attr.groupMailId,
					rb.comparisonTypes.Equals,
					MailCampaignCtrl.rootData.mailCampaign.id
				);

				addFilters(rb);

				MailCampaignCtrl.rootData.currentRb = rb;
				Mail.find(rb.build(), { mailCampaignFields: true })
					.then(function (res) {
						MailCampaignCtrl.rootData.pageData.mail = res.data;
						MailCampaignCtrl.rootData.pageData.total = res.metadata.total;
						MailCampaignCtrl.rootData.pageData.loading = false;
						MailCampaignCtrl.rootData.pageData.dataTimestamp = Date.now();

						MailCampaignCtrl.rootData.pageData.totalPages = MailCampaignCtrl.calculateTotalPages(
							limit,
							MailCampaignCtrl.rootData.pageData.total
						);
					})
					.catch(error => logError(error, 'mailCampaignRecipients - getMail'));
			}, 20);
		};

		MailCampaignCtrl.rootData.pageData = {
			mail: [],
			loading: true,
			total: 0,
			totalPages: 1,
			activePage: 1,
			currentSorting: { attr: 'contact.name', asc: true },
			drilldownData: null,
			drilldownLoading: false,
			dataTimestamp: Date.now(),
			selectedLink: null,
			currentlyUnBouncing: [],
			onSearch: function (str) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				searchStr = str;
				getMail();
			},
			onChangePage: function (page) {
				MailCampaignCtrl.rootData.pageData.activePage = page;
				getMail();
			},
			onSort: function (attr) {
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				if (MailCampaignCtrl.rootData.pageData.currentSorting.attr === attr) {
					// Flip it
					MailCampaignCtrl.rootData.pageData.currentSorting.asc = !MailCampaignCtrl.rootData.pageData
						.currentSorting.asc;
				} else {
					MailCampaignCtrl.rootData.pageData.currentSorting = { attr: attr, asc: true };
				}
				getMail();
			},
			onChangeUrl: function (link) {
				MailCampaignCtrl.rootData.pageData.selectedLink = link;
				getMail();
			},

			filters: {
				deliveredGroup: true,
				open: false,
				click: false,
				submit: false,
				unsub: false,
				bounce: false,
				duplicates: false,
				prevunsub: false,
				prevbounce: false,
				invalidemail: false,
				unverifiedSender: false
			},
			onFiltersReset: function () {
				var filters = MailCampaignCtrl.rootData.pageData.filters;
				for (var k in filters) filters[k] = false;
			},
			onFilterChange: function (filter, value) {
				MailCampaignCtrl.rootData.pageData.selectedLink = null;
				MailCampaignCtrl.rootData.pageData.filters[filter] = value;
				if (filter === 'deliveredGroup') {
					if (value) {
						MailCampaignCtrl.rootData.pageData.filters.bounce = false;
						MailCampaignCtrl.rootData.pageData.filters.duplicates = false;
						MailCampaignCtrl.rootData.pageData.filters.prevunsub = false;
						MailCampaignCtrl.rootData.pageData.filters.prevbounce = false;
						MailCampaignCtrl.rootData.pageData.filters.invalidemail = false;
						MailCampaignCtrl.rootData.pageData.filters.unverifiedSender = false;
					} else {
						MailCampaignCtrl.rootData.pageData.filters.open = false;
						MailCampaignCtrl.rootData.pageData.filters.click = false;
						MailCampaignCtrl.rootData.pageData.filters.submit = false;
						MailCampaignCtrl.rootData.pageData.filters.unsub = false;
					}
				} else if (filter === 'all') {
					_.each(MailCampaignCtrl.rootData.pageData.filters, function (filter, key) {
						if (key !== 'deliveredGroup') {
							MailCampaignCtrl.rootData.pageData.filters[key] = false;
						}
					});
				}
				$multiSelect.selectNone();
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				// set offset == 0 if filter change?
				getMail();
			},
			onInactivateContact: function (mail) {
				if (!mail.contact) {
					return;
				}

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'integration.inactivate',
						body: 'confirm.inactivateContact',
						confirmButtonText: 'integration.inactivate',
						onClose: confirmed => {
							if (confirmed) {
								const contact = { id: mail.contact.id, active: false };
								Contact.save(contact);
								const found = MailCampaignCtrl.rootData.pageData.mail?.find?.(existingMail => existingMail.id === mail.id);
								if (found) {
									found.contact.active = false;
								}
							}
						}
					});
					return;
				}

				// Show confirm modal
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'integration.inactivate',
						body: 'confirm.inactivateContact',
						resolveTrue: 'integration.inactivate'
					})
					.then(function () {
						var contact = { id: mail.contact.id, active: false };
						Contact.save(contact);
						var found = _.find(MailCampaignCtrl.rootData.pageData.mail, { id: mail.id });
						found.contact.active = false;
					});
			},
			onUnBounceContact: function (mail) {
				const fixedEmail = mail.to.trim().toLowerCase();
				MailCampaignCtrl.rootData.pageData.currentlyUnBouncing.push(fixedEmail);
				$safeApply($scope);

				Contact.unbounce({ email: mail.to })
					.then(() => {
						for (const mail of MailCampaignCtrl.rootData.pageData.mail) {
							const fixedEmailOther = mail.to.trim().toLowerCase();
							if (fixedEmailOther === fixedEmail && Array.isArray(mail.contact.mailBounces)) {
								mail.contact.mailBounces = mail.contact.mailBounces.filter(
									bounceType => bounceType !== 'hard_bounce'
								);
							}
						}
						MailCampaignCtrl.rootData.pageData.currentlyUnBouncing = MailCampaignCtrl.rootData.pageData.currentlyUnBouncing.filter(
							email => email !== fixedEmail
						);
					})
					.catch(error => {
						if (!error?.status || error.status >= 500) {
							logError(error, 'mailCampaignRecipients.js - onUnBounceContact');
						}
						MailCampaignCtrl.rootData.pageData.currentlyUnBouncing = MailCampaignCtrl.rootData.pageData.currentlyUnBouncing.filter(
							email => email !== fixedEmail
						);
					});
			},
			onEditContact: function (mail) {
				if (!mail.contact) {
					return;
				}
				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editContact', { id: mail.contact.id }).then(function (contact) {
					var found = _.find(MailCampaignCtrl.rootData.pageData.mail, { id: mail.id });
					found.contact.name = contact.name;
					found.contact.active = contact.active;
				});
			},
			onDrilldown: function (mail) {
				if (!mail.contact) {
					MailCampaignCtrl.rootData.pageData.drilldownData = [];
					return;
				}
				MailCampaignCtrl.rootData.pageData.drilldownLoading = true;
				var filters = new RequestBuilder();
				filters.addFilter(
					{ field: 'mail.groupMailId' },
					filters.comparisonTypes.Equals,
					MailCampaignCtrl.rootData.mailCampaign.id
				);
				filters.addFilter({ field: 'contact.id' }, filters.comparisonTypes.Equals, mail.contact.id);
				filters.addSort('date', false);
				MailCampaignInfo.find(filters.build())
					.then(function (res) {
						MailCampaignCtrl.rootData.pageData.drilldownData = res.data;
						MailCampaignCtrl.rootData.pageData.drilldownLoading = false;
					})
					.catch(error => logError(error, 'mailCampaignRecipients - onDrilldown'));
			},
			sendToNonClickers: function () {
				sendGroupMailAction('click');
			},
			sendToNonOpeners: function () {
				sendGroupMailAction('open');
			}
		};

		var init = function () {
			MailCampaignCtrl.rootData.listActionData.actions = MultiRunnerActions.get(MultiRunnerActions.type.MAIL);

			$multiSelect.selectNone();
			if ($stateParams.filter === 'showErrors') {
				MailCampaignCtrl.rootData.pageData.onFilterChange('deliveredGroup', false);

				var drill = $stateParams.drill ? $stateParams.drill.split(',') : [];
				for (var i in drill) {
					MailCampaignCtrl.rootData.pageData.onFilterChange(drill[i], true);
				}
			} else {
				getMail();
			}
		};

		//eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

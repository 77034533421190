import React, { useMemo } from 'react';
import { Block, Column, Icon, IconName, Row, Text, Tooltip } from '@upsales/components';
import './FlowStepBase.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import colorMappings from '@upsales/components/Utils/colorMappings';
import T from 'Components/Helpers/translate';

type Props = React.ComponentProps<typeof Block> & {
	title: string;
	icon: IconName;
	borderColor: string;
	locked?: boolean;
	maxHeight: number;
	onDelete?: () => void;
	editable?: boolean;
	isSplit?: boolean;
	isSplitYesIcon?: IconName;
	isSplitNoIcon?: IconName;
	isInvalid?: boolean;
	onClickBehaviour?: () => void;
};

export const getSnoozeUnitText = (time: number, unit: string) =>
	({
		day: T(time > 1 ? 'date.days' : 'date.day'),
		week: T(time > 1 ? 'date.weeks' : 'date.week'),
		month: T(time > 1 ? 'date.months' : 'date.month')
	}[unit]);

export default ({
	title,
	children,
	className,
	icon,
	borderColor: bc,
	locked,
	maxHeight,
	onDelete,
	editable = false,
	isSplit = false,
	isSplitYesIcon = 'check',
	isSplitNoIcon = 'times',
	isInvalid = false,
	onClickBehaviour,
	...blockProps
}: Props) => {
	const classes = useMemo(() => new BemClass('FlowStepBase', className), [className]);
	const style = {
		transform: `translateY(-50%)`
	};
	const borderColor = isInvalid ? 'grey-5' : bc;
	return (
		<Block
			{...blockProps}
			className={classes.mod({ clickable: !!blockProps.onClick, isInvalid }).b()}
			border="s"
			borderRadius
			borderColor={borderColor}
			style={style}
		>
			{isInvalid ? (
				<Block
					backgroundColor="yellow-1"
					border="s"
					borderColor="yellow-3"
					className={classes.elem('warning').b()}
				>
					<Icon name="warning" />
				</Block>
			) : null}
			<div className={classes.elem('icon').b()} style={{ backgroundColor: colorMappings.get(borderColor) }}>
				<Icon name={icon} color="white" />
			</div>
			<Row className={classes.elem('titleRow').b()}>
				<Column>
					<Text size="sm" className={classes.elem('title').b()}>
						{title}
					</Text>
				</Column>
				<Column fixedWidth={24}>
					{editable && onDelete ? (
						<Tooltip title={T('flow.removeStep')} distance={20}>
							<Icon
								name="trash"
								onClick={onDelete}
								className={classes.elem('trash').b()}
								color="grey-9"
							/>
						</Tooltip>
					) : null}
				</Column>
			</Row>
			{locked ? <Icon name="lock" className={classes.elem('lock').b()} color="grey-9" /> : null}
			{children}
			{isSplit ? (
				<Block
					space="mts"
					onClick={
						onClickBehaviour
							? e => {
									e.stopPropagation();
									onClickBehaviour();
							  }
							: undefined
					}
				>
					<Row>
						<Column>
							<Text align="center" size="sm">
								<Icon name={isSplitNoIcon} color="red" space="mrs" />
								{T('default.no')}
							</Text>
						</Column>
						<Column>
							<Text align="center" size="sm">
								<Icon name={isSplitYesIcon} color="success-5" space="mrs" />
								{T('default.yes')}
							</Text>
						</Column>
					</Row>
				</Block>
			) : null}
		</Block>
	);
};

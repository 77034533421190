import React, { ReactElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, Column, Icon, Row, SelectAsync, Text, Title } from '@upsales/components';
import T, { useTranslation } from 'Components/Helpers/translate';

import './CriteriaTableFooter.scss';
import { DefaultButton } from '@upsales/components/Buttons';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { OptionalQueryElement } from '@upsales/components/PopupPortal/PopupPortal';

type Types = 'contact' | 'client' | 'ticket';

const sortIds: Record<string, number> = {
	'segment.contactCriterias': 1,
	'segment.clientCriterias': 1,
	'default.categories': 2,
	'advancedSearch.accountHistory': 3,
	'default.market': 5,
	'filters.columns.contactinformation': 4,
	'default.otherInfo': 6,
	'default.other': 6
};

const selectHeaderIcons = {
	client: 'home',
	contact: 'user',
	ticket: 'customer-support'
} as const;

const entityName = {
	contact: 'default.contacts',
	client: 'default.clients',
	ticket: 'ticket.tickets'
};

const selectHeaders = {
	contact: 'segment.contactCriterias',
	client: 'segment.clientCriterias',
	ticket: 'segment.ticketCriterias'
};

const filterEntities = {
	contact: 'contact',
	client: 'account',
	ticket: 'ticket'
};

type Props = {
	entities?: {
		[key in Types]?: boolean;
	};
	filters: {
		isExclude: boolean;
		config: Record<string, any>;
	};
	filterConfigs: {
		[name: string]: FilterConfig & {
			showOnSegment?: boolean;
			allowMultipleSelections?: boolean;
			$field?: { visible?: boolean };
		};
	};
	addFilter: (isExclude: boolean, id: string) => void;
	anchor?: OptionalQueryElement;
};

type CriteriaSelectItem = {
	id: string;
	title: string;
	sortId: number;
	children?: Omit<CriteriaSelectItem, 'children' | 'sortId'>[];
	disabled?: boolean;
};

const getSelectData = async (
	filterConfigs: Props['filterConfigs'],
	searchTerm: string,
	activeFilters: { [key: string]: any },
	entity: Types
) => {
	const { FeatureHelper, AppService } = Tools;
	const hasRemovedActivities = FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
	const oldActivitiesCount = AppService.getTotals('oldActivities');
	const filterEntity = filterEntities[entity];

	const contactSelectData = TypedObject.keys(filterConfigs).reduce<CriteriaSelectItem[]>((res, name, i) => {
		const filter = filterConfigs[name];
		const title = T(typeof filter.title === 'function' ? filter.title() : filter.title || '');
		const show = title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
		const shouldSeeActivityFilter = !hasRemovedActivities || oldActivitiesCount > 0;

		if (
			filter.showOnSegment &&
			filter.entity === filterEntity &&
			(!activeFilters.hasOwnProperty(name) || filter.allowMultipleSelections) &&
			show &&
			name !== 'ClientActivityFilters' && // Never let them select the old activities and appointment filter
			// Never let them select the activities on contact if they have REMOVED_ACTIVITIES and no old activities
			(name !== 'ContactOnlyActivityFilters' || shouldSeeActivityFilter)
		) {
			// Remove invisible fields
			if (name.indexOf('Custom_') !== -1 && filter.$field && !filter.$field.visible) {
				return res;
			}

			if (filter.parent) {
				let parent = res.find(item => item.title === filter.parent);
				if (!parent) {
					let parentTitle;

					if (filterEntity === 'contact' && name.indexOf('Custom_') > -1) {
						parentTitle = T('default.otherContactinfo');
					} else if (filterEntity === 'account' && name.indexOf('Custom_') > -1) {
						parentTitle = T('default.otherAccountinfo');
					} else {
						parentTitle = T(filter.parent);
					}
					parent = {
						id: 'header-' + i,
						title: parentTitle,
						children: [],
						sortId: sortIds[filter.parent] || 7
					};
					res.push(parent);
				}
				parent.children?.push({ id: name, title });
			} else {
				res.push({ id: name, title, sortId: 1 });
			}
		}

		return res;
	}, []);

	return _.sortBy(contactSelectData, 'sortId');
};

const CriteriaTableFooter = ({
	entities = {
		contact: true,
		client: true,
		ticket: false
	},
	filters,
	filterConfigs,
	addFilter,
	anchor
}: Props) => {
	const { t } = useTranslation();
	const [selectedEntity, setSelectedEntity] = useState<null | Types>(null);
	const isExclude = filters.isExclude;
	const classes = new BemClass('CriteriaTableFooter');
	const buttons: ReactElement<typeof DefaultButton>[] = [];

	const wrapperRef = useRef<null | HTMLInputElement>(null);
	const selectRef = useRef<null | HTMLInputElement>(null);

	useEffect(() => {
		if (selectedEntity) {
			selectRef.current?.focus();
		}
	});

	TypedObject.keys(entities).forEach(entity => {
		if (entities[entity]) {
			buttons.push(
				<DefaultButton key={entity} size="sm" onClick={() => setSelectedEntity(entity)}>
					{t(entityName[entity])}
				</DefaultButton>
			);
		}
	});

	return (
		<Row className={classes.b()} ref={wrapperRef}>
			<Column>
				<Text>
					<Icon name="plus-circle" color="grey-10" space="mrm" />
					{t('segment.addCriteriasFor')}
				</Text>
			</Column>
			<Column className={classes.elem('select-wrap').b()}>
				{buttons}
				<SelectAsync<CriteriaSelectItem>
					key={selectedEntity + '-select'}
					anchor={anchor || wrapperRef.current?.querySelector('.CriteriaTableFooter__select-wrap')}
					fetcher={async str =>
						selectedEntity ? getSelectData(filterConfigs, str, filters.config, selectedEntity) : []
					}
					value={null}
					fetchOnOpen
					openOnFocus
					inputRef={selectRef}
					onBlur={() => setSelectedEntity(null)}
					className={classes.elem('select').mod({ visible: !!selectedEntity }).b()}
					onChange={({ id }) => {
						addFilter(isExclude, id);
						setSelectedEntity(null);
					}}
					optionHeaderType="disabled"
					placeholder={t('segment.selectCriteriaPlaceholder')}
					renderCustomHeader={() => (
						<Block space="ptl pbl pll prl">
							{selectedEntity ? (
								<Title>
									<Icon name={selectHeaderIcons[selectedEntity]} space="mrl" />
									{t(selectHeaders[selectedEntity])}
								</Title>
							) : null}
						</Block>
					)}
				/>
			</Column>
		</Row>
	);
};

CriteriaTableFooter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	filterConfigs: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	entities: PropTypes.object
};

export default CriteriaTableFooter;

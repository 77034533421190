ReactTemplates.segment.eventRow = window.ReactCreateClass({
	getInitialState: function () {
		var isSameMonth = moment().isSame(this.props.eventRow.date, 'month');
		return { expanded: isSameMonth };
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			mailCampaigns: t('default.mailings').toLowerCase(),
			mailCampaign: t('default.mailing'),
			flowMailCampaigns: t('segment.flowMailCampaigns').toLowerCase(),
			dynamicSelection: t('segment.dynamicSelection'),
			manualRemove: t('segment.manualRemove'),
			manualAdd: t('segment.manualAdd'),
			flow: t('default.flow'),
			contacts: t('default.contacts2').toLowerCase(),
			wasRemovedFromSegment: t('segment.wasRemovedFromSegment').toLowerCase(),
			wasAddedFromSegment: t('segment.wasAddedFromSegment').toLowerCase(),
			recievedGroupMail: t('groupMail.recievedGroupMail').toLowerCase(),
			noName: t('default.noName'),
			noEvents: t('segment.noEvents')
		};
	},
	toggleExpanded: function () {
		this.setState({ expanded: !this.state.expanded });
	},
	formatDate: function (date) {
		var theMoment = moment(date);
		var cal = theMoment.calendar();
		if (cal !== '0') {
			return cal;
		}
		return theMoment.format('ddd D');
	},
	render: function () {
		var self = this;
		var eventRow = this.props.eventRow;
		var rowMoment = moment(eventRow.date);
		var tableWidth = this.props.tableWidth;

		var subEvents = _.chain(this.props.events)
			.map(function (event) {
				var iconColor;
				var iconClass;
				var subtitle;
				var description;
				switch (event.type) {
					case 'removeContact':
						iconColor = 'red';
						iconClass = 'fa fa-minus';
						subtitle = self.lang.dynamicSelection;
						description = self.lang.wasRemovedFromSegment;
						if (event.sub_type === 'manual') {
							subtitle = self.lang.manualRemove;
						}
						break;
					case 'addContact':
						iconColor = 'bright-blue';
						iconClass = 'fa fa-plus';
						subtitle = self.lang.dynamicSelection;
						description = self.lang.wasAddedFromSegment;
						if (event.sub_type === 'manual') {
							subtitle = self.lang.manualAdd;
						}
						break;
					case 'sendMail':
						iconColor = 'orange';
						iconClass = 'fa fa-paper-plane';
						subtitle = self.lang.mailCampaign;
						description = (
							<span>
								{self.lang.recievedGroupMail}{' '}
								<a href={self.props.getHref('mailCampaign.dashboard', { id: event.mail_campaign_id })}>
									{event.mailSubject || self.lang.noName}
								</a>
							</span>
						);
						if (event.sub_type === 'flow') {
							subtitle = self.lang.flow;
						}
						break;
					default:
						return null;
				}
				return (
					<tr key={event.year + '-' + event.month + '-' + event.date + '-' + event.type}>
						<td className="expand-row">
							<div className="expand-row-contents">
								<div className={'event-icon ' + iconColor}>
									<b className={iconClass}></b>
								</div>
								<span className="date">{self.formatDate(event.date)}</span>
							</div>
						</td>
						<td colSpan={2} className="expand-row">
							<div className="expand-row-contents">
								<span>
									{self.props.tools.formatNumber(event.contacts)} {self.lang.contacts}
								</span>{' '}
								{description}
								<span className="subtitle">{subtitle}</span>
							</div>
						</td>
					</tr>
				);
			})
			.compact()
			.value();

		return (
			<tr
				key={'event-' + eventRow.year + '-' + eventRow.month}
				className={this.state.expanded ? 'expanded' : null}
			>
				<td colSpan={3}>
					<div className="table-group">
						<table className="inner-table">
							<tbody>
								<tr className="group-header" onClick={this.toggleExpanded}>
									<td style={{ width: tableWidth[0] + 'px' }} className="group-date" key="date">
										<h2>{rowMoment.format('MMMM')}</h2>
										{rowMoment.format('YYYY')}
									</td>
									<td className="group-mailcampaigns" key="mailCampaignStats">
										{!!eventRow.manualMailCampaigns && (
											<div className="stats-row" key="mailCampaigns">
												<div className="icon">
													<b className="fa fa-paper-plane"></b>
												</div>
												<a
													href={self.props.getHref('segment.mailCampaign', {
														id: self.props.segment.id
													})}
												>
													{self.props.tools.formatNumber(eventRow.manualMailCampaigns)}{' '}
													{self.lang.mailCampaigns}
												</a>
											</div>
										)}
										{!!eventRow.flowMail && (
											<div className="stats-row" key="flowMailCampaigns">
												<div className="icon">
													<b className="fa fa-paper-plane"></b>
												</div>
												<a
													href={self.props.getHref('segment.flow', {
														id: self.props.segment.id
													})}
												>
													{self.props.tools.formatNumber(eventRow.flowMail)}{' '}
													{self.lang.flowMailCampaigns}
												</a>
											</div>
										)}
									</td>
									<td className="group-sum" style={{ width: tableWidth[1] + 'px' }} key="contactsSum">
										<div className="total">
											<b className="fa fa-user"></b>
											{self.props.tools.formatNumber(eventRow.totalContacts)}
										</div>
										<div style={{ margin: '0 -10px' }}>
											<div className="added" style={{ padding: '0 5px' }}>
												<b className="fa fa-plus"></b>
												{self.props.tools.formatNumber(eventRow.addedContacts)}
											</div>
											<div className="removed" style={{ padding: '0 5px' }}>
												<b className="fa fa-minus"></b>
												{self.props.tools.formatNumber(eventRow.removedContacts)}
											</div>
										</div>
									</td>
								</tr>
								{subEvents.length ? (
									subEvents
								) : (
									<tr className="no-data expand-row">
										<td colSpan={3}>
											<div className="expand-row-contents">{self.lang.noEvents}</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</td>
			</tr>
		);
	}
});

'use strict';

angular.module('domain.admin').controller('CreateLeadAction', [
	'$scope',
	'$modalParams',
	'$translate',
	'AppService',
	function ($scope, $modalParams, $translate, AppService) {
		var arrayProperties = ['Role'];

		$scope.change = function () {
			if ($scope.properties.Role && $scope.properties.Role.length) {
				$scope.roleError = false;
			}
		};

		$scope.save = function () {
			if ($scope.typeToggle === 'roles' && !$scope.properties.Role) {
				$scope.roleError = true;
				return;
			}

			$scope.saving = true;
			// map properties
			var properties = [];
			angular.forEach($scope.properties, function (val, key) {
				var obj = {
					name: key,
					value: val
				};

				if (_.indexOf(arrayProperties, key) !== -1) {
					if (Array.isArray(obj.value)) {
						obj.value = obj.value.join(',');
					}
				}

				properties.push(obj);
			});

			// Remove the one we don't want
			if ($scope.typeToggle === 'users') {
				_.remove(properties, { name: 'Role' });
			} else {
				_.remove(properties, { name: 'User' });
			}

			$scope.resolve(properties);
		};

		var init = function () {
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			// parse properties
			$scope.properties = {
				sendEmail: false,
				AssignToManager: false,
				Role: null,
				User: null
			};

			angular.forEach($modalParams.properties, function (prop) {
				$scope.properties[prop.name] = prop.value;
				if (_.indexOf(arrayProperties, prop.name) !== -1) {
					$scope.properties[prop.name] = $scope.properties[prop.name]
						? ($scope.properties[prop.name] + '').split(',')
						: null;
				}
			});

			if ($scope.properties.Role) {
				$scope.typeToggle = 'roles';
			} else {
				$scope.typeToggle = 'users';
			}

			$scope.roleSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.role').toLowerCase()
			};
			$scope.roles = AppService.getRoles();

			$scope.userSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.users').toLowerCase()
			};
			$scope.users = AppService.getActiveUsers();
		};

		AppService.loadedPromise.then(init);
	}
]);

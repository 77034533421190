'use strict';

angular.module('upResources').factory('Flow', [
	'$resource',
	'$q',
	'URL',
	'API',
	'FlowAttributes',
	'ResourceHelper',
	function Flow($resource, $q, URL, API, FlowAttributes, ResourceHelper) {
		var Attributes = FlowAttributes().attr;
		var keys = FlowAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'flow',
			createSuccessBody: 'saved.flow',
			updateSuccessBody: 'updated.flow',
			deleteSucccessBody: 'deleted.flow',
			createErrorBody: 'saveError.flow',
			updateErrorBody: 'saveError.flow',
			deleteErrorBody: 'deleteError.flow'
		});

		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'flows/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: { method: 'GET', isArray: false },
				find: { method: 'GET', isArray: false },
				delete: { method: 'DELETE', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			newStep: function (type, props) {
				props = props || {};
				var step = {
					type: type,
					params: {},
					childYes: null,
					childNo: null,
					waitTime: null,
					waitUnit: null,
					formId: null,
					templateId: null,
					mailCampaignId: null,
					link: null,
					parentType: null
				};
				switch (type) {
					case 'snooze':
					case 'clickedMail':
					case 'readMail':
						step.waitTime = props.waitTime || 1;
						step.waitUnit = 'day';
						break;
					case 'formSubmit':
						step.waitTime = props.waitTime || 1;
						step.waitUnit = 'day';
						step.formId = null;
						break;
					case 'sendMail':
						step.templateId = null;
						break;
					case 'createActivity':
					case 'updateClient':
					case 'updateContact':
					case 'assign':
						step.params = props;
						break;
				}
				return step;
			},
			new: function () {
				var flow = {
					id: null,
					segment: null,
					path: null,
					status: 'draft',
					name: '',
					timezone: null,
					startTime: null,
					endTime: null,
					skipWeekends: false,
					saving: false
				};

				return flow;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options || {});
				delete params.mapCustom;

				return Resource.find(filter).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (flow, options) {
				var defered = $q.defer();

				if (!flow) {
					return defered.reject('No flow');
				}

				if (flow.id) {
					Resource.update(
						{ id: flow.id },
						{ flow: flow },
						function (response) {
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Resource.create(
						{ flow: flow },
						function (response) {
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !flow.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !flow.id);
					});

				return defered.promise;
			},

			delete: function (id, options) {
				return Resource.delete({ id: id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, { id: id });

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},
			attr: Attributes,
			keys: keys,
			requiredFields: FlowAttributes().requiredFields
		};

		return Model;
	}
]);

'use strict';

ReactTemplates.mailCampaign.clientschart = window.ReactCreateClass({
	chart: null,
	config: {
		chart: { type: 'column', backgroundColor: 'transparent', height: 145, spacing: [0, 20, 0, 0] },
		credits: { enabled: false },
		title: { text: null },
		subtitle: { text: null },
		xAxis: { visible: false },
		yAxis: { visible: false },
		legend: { enabled: false },
		tooltip: { enabled: false },
		plotOptions: {
			series: {
				groupPadding: 0.05,
				pointPadding: 0,
				borderWidth: 0,
				borderRadius: 3,
				dataLabels: {
					enabled: true,
					className: 'small-dataLabel',
					allowOverlap: true,
					formatter: function () {
						if (this.y > 999) {
							return Math.round((this.y / 1000) * 10) / 10 + 'k';
						} else {
							return this.y;
						}
					}
				}
			}
		},
		series: [{ colorByPoint: true, data: null }]
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			invalidEmail: t('mailCampaign.errors.invalidEmail'),
			bounce: t('mailCampaign.errors.bounce'),
			duplicates: t('mailCampaign.errors.duplicates'),
			noDataYet: t('default.noData'),
			checkLaterIfData: t('mailCampaign.errors.noData'),
			showAll: t('default.showAll')
		};
	},
	shouldComponentUpdate: function (nextProps) {
		return !_.isEqual(nextProps.data, this.props.data);
	},
	renderChart: function () {
		var self = this;

		if (self.refs.container) {
			var config = this.config;
			var container = self.refs.container;

			var _data = _.map(this.props.data, function (obj, i) {
				obj.color = self.props.colors[i];
				obj.y = obj.total || obj.count;
				obj.borderRadius = 3;
				return obj;
			});

			config.series[0].data = _data;

			if (config.chart) {
				config.chart.animation = false;
			}
			new Highcharts.Chart(container, config, function () {
				self.chart = this;
				self.reflow();
			});
		}
	},
	renderNoData: function () {
		return (
			<div className="no-data">
				{this.props.noDataImg ? (
					<img className="no-data-img" height="50" src={'/img/' + this.props.noDataImg} />
				) : null}
				<h4 className="no-data-msg">{this.props.noDataMsg}</h4>
			</div>
		);
	},
	reflow: function () {
		if (this.chart) {
			var config = this.config;
			var container = this.refs.container;
			var reflow = false;

			if (
				config.chart &&
				config.chart.type === 'column' &&
				config.series.length &&
				config.series[0].data.length
			) {
				var newWidth = config.series[0].data.length * 150;

				this.chart.series[0].update({
					pointWidth: 20
				});

				reflow = true;

				if (newWidth > container.parentNode.clientWidth) {
					reflow = true;
				} else {
					container.style.width = null;
					reflow = true;
				}
			} else {
				container.style.width = null;
				reflow = true;
			}

			if (reflow) {
				this.chart.reflow(); // reflow chart
			}
		}
	},
	hasData: function () {
		var data = this.props.data;
		return (Array.isArray(data) && data.length) || (!Array.isArray(data) && data);
	},
	componentDidMount: function () {
		var hasData = this.hasData();

		window.addEventListener('resize', this.renderChart);

		if (hasData) {
			this.renderChart();
		}
	},

	componentWillUnmount: function () {
		window.removeEventListener('resize', this.renderChart);
	},
	componentDidUpdate: function () {
		this.renderChart();
	},
	render: function () {
		if (this.hasData()) {
			return <div ref="container"></div>;
		}

		return this.renderNoData();
	}
});

import SessionResource from 'App/resources/Session';
import SelfResource from 'App/resources/Self';
import history from 'App/pages/routes/history';
import { LoginErrors } from 'App/pages/Login/LoginError';
import config from '../../config';
import { passwordHelper } from '@upsales/common';
import { removeAllNotifications } from 'Store/reducers/SystemNotificationReducer';
import InAppChat from 'Services/InAppChat';
import store, { RESET_ALL_REDUCERS, RootState } from 'Store/index';
import { initSession, setLoading } from 'Store/actions/AppActions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';
import AppService from 'App/legacy/AppService';

export const getEnvString = () => {
	let envString = '';
	if (config.ENV === 'DEV' || config.ENV === 'ALPHA' || config.ENV === 'BETA') {
		envString = config.ENV;

		if (config.ENV === 'BETA' && config.URL.indexOf('sandbox') !== -1) {
			envString = 'SANDBOX';
		}
	}
	return envString;
};

export const checkBrowser = () => {
	const supportedBrowsers = ['Chrome', 'Firefox'];
	const browser = navigator.userAgent;

	const mobile = ['Android', 'iPhone'];
	let unsupported = true,
		isMobileBrowser = false,
		mobileType = null;

	for (let i = 0; i < supportedBrowsers.length; i++) {
		if (browser.includes(supportedBrowsers[i])) {
			unsupported = false;
			break;
		}
	}

	for (let i = 0; i < mobile.length; i++) {
		if (browser.includes(mobile[i])) {
			isMobileBrowser = true;
			mobileType = mobile[i].toLowerCase();
			break;
		}
	}

	return { unsupported, mobileType, isMobileBrowser };
};

const parseKeyValueArray = (array: string[]) =>
	array.reduce<{ [k: string]: any }>((res, item) => {
		const [key, val] = item.trim().split('=').map(decodeURIComponent);
		try {
			return { ...res, [key]: JSON.parse(val) };
		} catch (e) {
			return { ...res, [key]: val };
		}
	}, {});

const parseCookies = () => parseKeyValueArray(document.cookie.split(';'));

export const getSavedUser = () => {
	const savedUserEmailKey = 'ls.Upsales.crm.savedEmail';
	const savedUserNameKey = 'ls.Upsales.crm.savedName';
	const cookies = parseCookies();

	if (cookies[savedUserEmailKey] && cookies[savedUserNameKey]) {
		return { email: cookies[savedUserEmailKey], name: cookies[savedUserNameKey] };
	}

	return null;
};

const deleteCookie = (key: string) => {
	document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const setSavedUser = (email: string | null, name: string | null) => {
	if (email && name) {
		document.cookie = `ls.Upsales.crm.savedEmail=${email}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
		document.cookie = `ls.Upsales.crm.savedName=${name}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
	} else {
		deleteCookie('ls.Upsales.crm.savedEmail');
		deleteCookie('ls.Upsales.crm.savedName');
	}
};

const getUrlParams = () => parseKeyValueArray(history.location.search.replace('?', '').split('&'));

export const redirectToStartpage = async (customerId: number) => {
	const hash = getUrlParams();

	const cookies = parseCookies();
	if (hash.externalSSO) {
		return Tools.$state.go('externalSSO', { site: hash.externalSSO });
	} else if (hash.returnTo) {
		let params = { customerId };

		if (hash.params) {
			params = hash.params;
		}
		return Tools.$state.go(hash.returnTo, params).catch(() => Tools.$state.go('tmpStart', { customerId }));
	} else if (cookies.loginReturnUrl && cookies.loginReturnUrl !== 'undefined') {
		// eslint-disable-next-line promise/catch-or-return
		return Tools.$state.go('tmpStart', { customerId }).then(function () {
			window.location.href = cookies.loginReturnUrl;
			deleteCookie('loginReturnUrl');
		});
	} else {
		return Tools.$state.go('tmpStart', { customerId });
	}
};

const onSuccessfullLogin = async (rememberUser: boolean) => {
	let self;
	try {
		self = (await SelfResource.get()).data;
	} catch (error: any) {
		const res = error.response?.data;
		const missingIndex = res?.error?.key === 'MissingIndex';
		const isTrial = res?.client?.provisioningTrial;
		if (missingIndex && isTrial) {
			throw LoginErrors.MISSING_INDEX_TRIAL;
		} else if (missingIndex) {
			throw LoginErrors.MISSING_INDEX;
		}
		throw LoginErrors.UNKNOWN;
	}

	if (rememberUser) {
		setSavedUser(self.email, self.name);
	} else {
		setSavedUser(null, null);
	}

	// Start init the app
	const dispatch = store.dispatch as ThunkDispatch<RootState, any, AnyAction>;
	await dispatch(initSession(true));

	// Redirect to startpage
	await redirectToStartpage(self.clients[0].clientId);

	// We hide the loader when the startpage has been loaded. Give it some extra time
	setTimeout(() => dispatch(setLoading(false)));
};

export const login = async (email: string, password: string, rememberUser: boolean) => {
	let token;
	try {
		const hash = getUrlParams();
		const { data } = await SessionResource.login({ email, password, samlBypass: hash.samlBypass || null });
		token = data.token;
		if (data.isTwoFactorAuth) {
			return { token, isTwoFactorAuth: true };
		}
	} catch (error: any) {
		const res = error.response?.data;
		const status = error.response?.status;
		if (status === 403 && res.errorCode === 113) {
			throw LoginErrors.NO_VALID_BRAND;
		} else if (status === 403 && res.errorCode === 140) {
			throw LoginErrors.ACCOUNT_LOCKED;
		} else if (status === 403) {
			throw LoginErrors.IP_BLOCKED;
		}
		// if error is 402 there is no active agreement
		else if (status === 402) {
			return Tools.$state.go('manageAccount', { credentials: { email, password } });
		} else if (status === 401 && res.error?.errorCode === 94) {
			throw LoginErrors.NO_PHONE_2FA;
		} else if (res.errorCode === 116) {
			throw LoginErrors.FORCE_RESET_PASSWORD;
		} else {
			// else just show error invalid pass/username
			throw LoginErrors.CREDENTIALS;
		}
	}

	await onSuccessfullLogin(rememberUser);

	return { isTwoFactorAuth: false, token };
};

export const getResetHash = () => {
	const params = getUrlParams();

	return (params.resetPassword as string) || null;
};

export const getActivateFortnox = () => {
	const params = getUrlParams();

	return !!params.activateFortnox;
};

const passwordValidationErrorMap: { [key: string]: LoginErrors } = {
	'resetPassword.rule.atleastEightCharactersError': LoginErrors.PASSWORD_TOO_SHORT,
	'resetPassword.rule.containCapitalAndNonCapitalError': LoginErrors.PASSWORD_CAPITAL,
	'resetPassword.rule.containNumberError': LoginErrors.PASSWORD_NUMBER
};

export const validatePassword = (password: string, password2: string): null | LoginErrors | LoginErrors[] => {
	// eslint-disable-next-line security/detect-possible-timing-attacks
	if (password !== password2) {
		return LoginErrors.PASSWORDS_NOT_MATCHING;
	}

	const validation = passwordHelper.validate(password);

	if (!validation.valid) {
		return validation.errors.map(error => {
			return passwordValidationErrorMap[error] || LoginErrors.UNKNOWN;
		});
	}

	return null;
};

export const ensureUserIsLoggedOut = async () => {
	return new Promise<void>(resolve => {
		SessionResource.logout()
			.finally(function () {
				// this is still needed to tell some angular components to clear internal variables
				Tools.$rootScope.$broadcast('upsales.logout');

				// Destroy push notification socket
				Tools.PushNotifications.destroy();

				// logout zendesk user
				if (window.zE) {
					window.zE('messenger', 'logoutUser');
				}

				// Clear all notifications
				removeAllNotifications();

				// Shutdown in app chat
				InAppChat.shutdown();

				// Clear cache
				Tools.CacheService.clear();
				AllIWantDataCache.clearData();

				// Run AppService.reset to clear the session timeout
				AppService.reset();

				// Reset redux state
				store.dispatch({ type: RESET_ALL_REDUCERS });

				resolve();
			})
			.catch(() => {
				// We do not care if logout fails
			});
	});
};

export const loginTwoFA = async (token: string, code: string, rememberUser: boolean) => {
	try {
		await SessionResource.loginTwoFA(token, code);
	} catch (error: any) {
		const res = error.response?.data;

		if (res?.key === 'TwoFactorCodeWrong') {
			throw LoginErrors.INVALID_2FA;
		}
		if (res?.key === 'TwoFactorCodeDisabled') {
			throw LoginErrors.OUTDATED_2FA;
		}
		throw LoginErrors.UNKNOWN;
	}

	await onSuccessfullLogin(rememberUser);
};

export const getLoginMethod = async (email: string, noRedirect = false) => {
	const hash = getUrlParams();

	return SessionResource.getLoginMethod(email, hash.samlBypass || null, noRedirect);
};

'use strict';

angular.module('upResources').factory('ContactRelation', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	function ContactCategory($resource, $q, URL, API, ResourceHelper) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'contactRelation',
			createSuccessBody: 'saved.relation',
			updateSuccessBody: 'updated.relation',
			deleteSucccessTitle: 'default.saved',
			deleteSucccessBody: 'deleted.relation',
			createErrorBody: 'saveError.relation',
			updateErrorBody: 'saveError.relation',
			deleteErrorBody: 'deleteError.relation'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};
				var ContactRelation = null;

				ContactRelation = $resource(
					URL + API + customer + '/contactrelation/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						get: { method: 'GET' },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.save = function (relation, options) {
					var method = 'create';

					if (relation.id) {
						method = 'update';
					}

					return ContactRelation[method]({ id: relation.id }, relation)
						.$promise.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !relation.id);

							return res;
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, !relation.id, err);
							return $q.reject(err);
						});
				};

				instance.get = function (id) {
					return ContactRelation.get({ id: id }).$promise;
				};

				instance['delete'] = function (relation, options) {
					return ContactRelation.delete({ id: relation.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, relation);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			}
		};

		return Model;
	}
]);

import { Icon, TableColumn, Text, Tooltip, TableRow } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { FolderType } from '../../AccountFiles';
import T from 'Components/Helpers/translate';
import { useDrag } from 'react-dnd';
import React from 'react';
import './FolderRow.scss';

type Props = {
	folder: FolderType;
	openFolder: (folderId: number, name: string) => void;
	deleteFolder: (id: number) => void;
};

const FolderRow = ({ folder, openFolder, deleteFolder }: Props) => {
	const classes = new BemClass('FolderRow');

	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'ALL',
		item: { id: 2 },
		collect: monitor => ({ isDragging: !!monitor.isDragging() })
	}));

	return (
		<TableRow
			ref={drag}
			className={classes.mod({ isDragging }).b()}
			key={folder.id}
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				openFolder(folder.id, folder.name);
			}}
		>
			<TableColumn>
				<Text>
					<Icon className={classes.elem('folder-icon').b()} name={'folder'} />
					{folder.name}
				</Text>
			</TableColumn>
			<TableColumn className={classes.elem('last-column').b()} align="right">
				<Tooltip className={classes.elem('delete-button').b()} title={T('default.delete')} position="left">
					<Icon
						onClick={e => {
							e.stopPropagation();
							deleteFolder(folder.id);
						}}
						color="grey-6"
						name="trash-o"
					/>
				</Tooltip>
			</TableColumn>
		</TableRow>
	);
};

export default FolderRow;

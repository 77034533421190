'use strict';

angular.module('upAttributes').service('LeadAttributes', [
	'FilterType',
	'DisplayType',
	'AppService',
	function (FilterType, DisplayType, AppService) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				regDate: {
					title: 'default.date',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: true,
					sortable: 'regDate'
				},
				address: {
					title: 'default.address',
					field: 'address',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				zipcode: {
					title: 'default.zipcode',
					field: 'zipcode',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				state: {
					title: 'default.city',
					field: 'city',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				contact: {
					title: 'default.contact',
					field: 'contact',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				webpage: {
					title: 'default.webpage',
					field: 'webpage',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				title: {
					title: 'default.title',
					field: 'title',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				phone: {
					title: 'default.phone',
					field: 'phone',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				email: {
					title: 'default.email',
					field: 'email',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				country: {
					title: 'default.country',
					field: 'country',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				notes: {
					title: 'default.notes',
					field: 'notes',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				project: {
					title: 'default.campaign',
					field: 'project',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					parent: 'project',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				source: {
					title: 'column.source',
					field: 'source.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					filterType: 'userId',
					comparisonTypes: ['Equals', 'NotEquals'],
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'source',
					attr: {
						id: {
							field: 'source.id',
							type: FilterType.Number
						},
						name: {
							field: 'source.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: 'source.name',
					selectableColumn: true
				},
				channel: {
					title: 'default.leadchannel',
					field: 'channel.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					parent: 'channel',
					attr: {
						id: {
							field: 'channel.id',
							type: FilterType.Number
						},
						name: {
							field: 'channel.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: 'channel.name',
					selectableColumn: true
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					filterType: 'userId',
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					displayAttr: 'name',
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					sortable: 'user.id',
					selectableColumn: true
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filter: {
						multiple: true,
						resource: 'Account',
						type: 'list',
						search: true,
						searchField: 'name',
						displayText: _.property('name'),
						title: 'default.account'
					},
					link: 'accounts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					sortable: 'name'
				},
				assigned: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'assigned',
					title: 'scoreboard.assignedUser',
					sortable: 'assigned',
					selectableColumn: true
				},
				custom: {}
			};

			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColumn = true;

			var filters = [
				{
					field: 'source.id',
					inputType: 'select',
					multiple: true,
					resource: 'LeadSource',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					title: 'default.leadsources',
					multiComparitors: true,
					listTitle: 'default.leadsource'
				},
				{
					title: 'default.user',
					field: 'user.id',
					resource: 'User',
					resourceType: 'activity',
					filters: function () {
						return [{ a: 'active', c: 'eq', v: 1 }];
					},
					type: 'list',
					multiple: true,
					inputType: 'select',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true
				},

				// {
				// 	title: 'default.country',
				// 	field: 'country',
				// 	resource: 'Lead',
				// 	search: true,
				// 	type: 'list',
				// 	inputType: 'select',
				// 	searchField: 'country',
				// 	displayText: 'country',
				// },
				//
				{
					field: 'channel.id',
					inputType: 'select',
					multiple: true,
					resource: 'Leadchannels',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					title: 'default.leadchannels',
					multiComparitors: true,
					listTitle: 'default.leadchannel'
				},
				{
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					search: true,
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					title: 'default.accountName',
					parent: 'default.account'
				}
			];

			var standardFilters = {
				Source: {
					field: 'source.id',
					inputType: 'select',
					multiple: true,
					resource: 'LeadSource',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					title: 'default.leadsources',
					multiComparitors: true,
					listTitle: 'default.leadsource'
				},
				User: {
					field: 'user.id',
					title: 'default.user',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta(AppService.AccessType.ACCOUNT);
					},
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true
				},
				// 'Country': {
				// 	field: 'country',
				// 	title: 'default.country',
				// 	resource: 'Lead',
				// 	search: true,
				// 	type: 'list',
				// 	inputType: 'select',
				// 	searchField: 'country',
				// 	displayText: 'country',
				// },
				//
				Channel: {
					field: 'channel.id',
					inputType: 'select',
					multiple: true,
					resource: 'Leadchannels',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					title: 'default.leadchannels',
					multiComparitors: true,
					listTitle: 'default.leadchannel'
				},
				Account: {
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					search: true,
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					title: 'default.accountName',
					parent: 'default.account'
				},
				Date: {
					field: 'regDate',
					title: 'default.date',
					inputType: 'date',
					type: 'dateRange'
				},
				Active: {
					listTitle: 'default.activeInactive',
					field: 'active',
					title: 'default.active',
					type: 'boolean',
					inputType: 'boolean'
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: filters,
				standardFilters: standardFilters,
				keys: {
					ID: 'id',
					NAME: 'name',
					// DATE: 'date',
					USER: 'user',
					SOURCE: 'source',
					ACCOUNT: 'account',
					CLIENT: 'client'
				}
			};
		};
	}
]);

'use strict';

angular.module('upResources').factory('Metadata', [
	'$q',
	'$resource',
	'URL',
	'API',
	function Metadata($q, $resource, URL, API) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var Metadata = $resource(
					URL + API + customer + '/metadata/',
					{},
					{
						query: { method: 'GET', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.get = function () {
					return Metadata.get().$promise.then(_.property('data'), function (res) {
						return $q.reject(res.status);
					});
				};

				// window.Metadata = $resource(URL + API + ':customerId/metadata/', {customerId: function(){return $stateParams.customerId}},{
				// 	'get': {responseType:'json', transformResponse: function(res){return res && res.data}}
				// });

				return instance;
			}
		};

		return Model;
	}
]);

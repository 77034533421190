import Activity from 'App/resources/Model/Activity';

export const ActivityDateSorter = (a: Pick<Activity, 'date'>, b: Pick<Activity, 'date'>) => {
	if (!a.date) {
		return 1;
	}
	if (!b.date) {
		return -1;
	}
	const aDate = new Date(a.date);
	const bDate = new Date(b.date);
	if (aDate < bDate) {
		return 1;
	}
	if (bDate < aDate) {
		return -1;
	}
	return 0;
};

'use strict';

angular.module('upModals.controllers').controller('FileBrowser', [
	'$rootScope',
	'$scope',
	'$modalParams',
	'File',
	'AppService',
	'RequestBuilder',
	'$upModal',
	'NotificationService',
	'$q',
	'$translate',
	'$filter',
	function (
		$rootScope,
		$scope,
		$modalParams,
		File,
		AppService,
		RequestBuilder,
		$upModal,
		NotificationService,
		$q,
		$translate,
		$filter
	) {
		var FileBrowser = this;
		var customerId;
		var types = [];
		var filters = [];

		FileBrowser.files = [];
		FileBrowser.searchStr = '';
		FileBrowser.selectedFiles = [];
		FileBrowser.galleryStyle = 'thumbnails';
		FileBrowser.loading = false;
		FileBrowser.totalFiles = 0;
		FileBrowser.limit = 8;
		FileBrowser.offset = 0;
		FileBrowser.imageOnly = false;

		FileBrowser.recentlyAdded = [];
		FileBrowser.selectedFileTypes = [];

		FileBrowser.closeModal = $scope.reject;
		FileBrowser.types = types;

		$scope.$on('file.uploaded', function (e, file) {
			var index = _.findIndex(FileBrowser.recentlyAdded, function (raddedFile) {
				return raddedFile instanceof window.File && raddedFile.size === file.size;
			});

			if (index !== -1) {
				FileBrowser.recentlyAdded.splice(index, 1);
			}

			FileBrowser.recentlyAdded.push(parseFile(file));
		});

		$scope.$on('file.deleted', function (e, deleted) {
			var index = _.findIndex(FileBrowser.files, { id: deleted.id });
			var indexSelected = _.findIndex(FileBrowser.selectedFiles, { id: deleted.id });
			var indexRecently = _.findIndex(FileBrowser.recentlyAdded, { id: deleted.id });

			if (index !== -1) {
				FileBrowser.files.splice(index, 1);
			}
			if (indexSelected !== -1) {
				FileBrowser.selectedFiles.splice(indexSelected, 1);
			}
			if (indexRecently !== -1) {
				FileBrowser.recentlyAdded.splice(indexRecently, 1);
			}
		});

		$scope.$on('file.invalid', function () {
			var title = $translate.instant('mail.invalidFileType');
			var body = $translate.instant('error.selectAnImage');

			NotificationService.addNotification({
				title: title,
				body: body,
				style: NotificationService.style.ERROR,
				icon: 'times'
			});
		});

		var parseFile = function (file) {
			file.$$previewUrl = File.getInlineThumbnailUrl(file.id);
			file.$$publicUrl = File.getPublicUrl(file.filename);
			if (file.mimetype.indexOf('image') !== -1 && file.filename.indexOf('.psd') === -1) {
				file.$$hasPreview = true;
			}

			if (FileBrowser.selectedFiles.length && _.find(FileBrowser.selectedFiles, { id: file.id })) {
				file.$$selected = true;
			}

			return file;
		};

		var selectFile = function (file) {
			if (file.$$selected) {
				file.$$selected = false;
				_.remove(FileBrowser.selectedFiles, { id: file.id });
			} else {
				file.$$selected = true;
				FileBrowser.selectedFiles.push(file);

				if (!FileBrowser.multiple) {
					$scope.resolve(FileBrowser.selectedFiles);
				}
			}
		};

		FileBrowser.delete = function (file) {
			File.customer(customerId)
				.delete(file)
				.then(function () {
					$rootScope.$broadcast('file.deleted', file);
				});
		};

		FileBrowser.upload2 = function (FileList) {
			var self = this;
			var opts = {
				library: !$modalParams.fileEntity,
				fileEntity: $modalParams.fileEntity
			};

			if ($modalParams.selectOnUpload) {
				opts.closeOnUpload = true;
			}

			var deferred = $q.defer();

			_.each(FileList, function (file) {
				if ($modalParams.sizeLimit && file.size > $modalParams.sizeLimit) {
					NotificationService.addNotification({
						title: 'default.error',
						body: $translate.instant('bannerEditor.fileTooLargeMaxSizeIs', {
							size: $filter('fileSize')($modalParams.sizeLimit, true)
						}),
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
					return deferred.resolve();
				}

				if (!file.type || (self.isImageOnly() && file.type.indexOf('image/') !== 0)) {
					$rootScope.$broadcast('file.invalid', file);
					return deferred.resolve();
				}
				file.pendingUpload = true;
				FileBrowser.recentlyAdded.push(file);

				var promise = File.customer(customerId).upload(file, opts);
				promise
					.then(function (file) {
						$rootScope.$broadcast('file.uploaded', file.data.data);
						deferred.resolve(file);
					})
					.catch(function () {
						var index = _.findIndex(FileBrowser.recentlyAdded, function (raddedFile) {
							return raddedFile instanceof window.File && raddedFile.size === file.size;
						});

						if (index !== -1) {
							FileBrowser.recentlyAdded.splice(index, 1);
						}
					});
			});

			return deferred.promise;
		};

		FileBrowser.search = _.debounce(function (str) {
			FileBrowser.offset = 0;
			FileBrowser.searchStr = str;
			var rb = new RequestBuilder();
			rb.addFilter(File.attr.filename, rb.comparisonTypes.Search, str || '');
			FileBrowser.getFiles(rb);
		}, 200);

		FileBrowser.selectFile = function (file) {
			if (file.$$loading) {
				return false;
			}

			// Make file public if flag is set and file is not.
			if ($modalParams.public && !file.public) {
				file.$$loading = true;

				File.customer(customerId)
					.makePublic(file.id)
					.then(function () {
						setTimeout(function () {
							file.$$loading = false;
							file.public = true;
							selectFile(file);
						}, 200);
					});
			} else {
				// If file is public or flag is not set for modal we just select it
				selectFile(file);
			}
		};

		FileBrowser.selectStringFile = function (file) {
			if (file) {
				$scope.resolve(file);
			}
		};

		FileBrowser.setGalleryStyle = function (style) {
			FileBrowser.galleryStyle = style;
		};

		FileBrowser.getFiles = function (rb) {
			FileBrowser.loading = true;
			FileBrowser.offset = rb.offset;

			rb.addFilter(File.attr.entity, rb.comparisonTypes.NotEquals, 'Esign');

			if (types.length) {
				rb.addFilter(File.attr.mimetype, rb.comparisonTypes.WildcardEnd, types);
			}

			rb.limit = FileBrowser.limit;

			File.customer(customerId)
				.find(rb.build())
				.then(function (res) {
					FileBrowser.files = _.map(res.data, parseFile);
					FileBrowser.totalFiles = res.metadata.total;
				})
				.finally(function () {
					FileBrowser.loading = false;
				});
		};

		FileBrowser.setImageOnly = function () {
			FileBrowser.imageOnly = true;
		};

		FileBrowser.isImageOnly = function () {
			return FileBrowser.imageOnly;
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			FileBrowser.multiple = $modalParams.multiple;
			FileBrowser.extraFieldUploadedRow = $modalParams.extraFieldUploadedRow;

			if ($modalParams.types && $modalParams.types.length) {
				angular.forEach($modalParams.types, function (type) {
					types.push(type);
				});
				if (types.length === 1 && types[0] === 'image') {
					FileBrowser.setImageOnly();
				}
			}

			if ($modalParams.filters && $modalParams.filters.length) {
				angular.forEach($modalParams.filters, function (filter) {
					filters.push(filter);
				});
			}
		};

		init();
	}
]);

export const lostOpportunityCompanies = {
	HasOpportunity: {
		inactive: false,
		value: {
			hasOpportunity: true,
			OrderRowProduct: {
				value: []
			},
			OrderRowProductCategory: {
				value: []
			},
			Value: {
				value: {
					start: '1',
					end: null
				}
			},
			Date: {
				value: {
					start: null,
					end: null,
					preset: 'whenever'
				}
			},
			Campaign: {
				value: []
			},
			User: {
				value: []
			},
			Stage: {
				value: []
			},
			Won: {
				value: null
			},
			Currency: {
				value: null
			}
		},
		filterName: 'HasOpportunity'
	}
} as const;

export const neverContactedCompanies = {
	HasActivity: {
		inactive: false,
		value: {
			hasActivity: false,
			overdueAsClosed: false,
			ActivityType: {
				value: []
			},
			Date: {
				value: {
					start: null,
					end: null,
					preset: 'whenever'
				}
			},
			Campaign: {
				value: []
			},
			User: {
				value: []
			},
			Status: {
				value: null
			}
		},
		filterName: 'HasActivity'
	},
	HasOpportunity: {
		inactive: false,
		value: {
			hasOpportunity: false,
			OrderRowProduct: {
				value: []
			},
			OrderRowProductCategory: {
				value: []
			},
			Value: {
				value: {
					start: null,
					end: null
				}
			},
			Date: {
				value: {
					start: null,
					end: null,
					preset: 'whenever'
				}
			},
			Campaign: {
				value: []
			},
			User: {
				value: []
			},
			Stage: {
				value: []
			},
			Won: {
				value: null
			},
			Currency: {
				value: null
			}
		},
		filterName: 'HasOpportunity'
	},
	HasOrder: {
		inactive: false,
		value: {
			hasOrder: false,
			OrderRowProduct: {
				value: []
			},
			OrderRowProductCategory: {
				value: []
			},
			Value: {
				value: {
					start: null,
					end: null
				}
			},
			Date: {
				value: {
					start: null,
					end: null,
					preset: 'whenever'
				}
			},
			Campaign: {
				value: []
			},
			User: {
				value: []
			},
			Stage: {
				value: []
			},
			Currency: {
				value: null
			}
		},
		filterName: 'HasOrder'
	},
	HasMail: {
		value: false,
		filterName: 'HasMail',
		dataType: 'raw'
	},
	HasFormSubmit: {
		value: null,
		filterName: 'HasFormSubmit',
		dataType: 'raw'
	},
	Campaign: {
		value: [],
		comparisonType: 'Equals',
		filterName: 'Campaign',
		columnPath: 'campaigns',
		dataType: 'list',
		field: 'project.id',
		inactive: true
	}
} as const;

'use strict';

angular.module('upResources').service('MapEsign', function () {
	return function (payload) {
		var esign = payload.esign;

		esign.tsupdated = undefined;

		angular.forEach(esign.involved, function (p) {
			p.tsupdated = undefined;
		});

		payload.esign = esign;
		return payload;
	};
});

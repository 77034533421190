import React from 'react';
import PropTypes from 'prop-types';
import { Title, Row, Column, CardContent, Text, Icon } from '@upsales/components';
import ClickableCard from './ClickableCard';
import { connect } from 'react-redux';
import './CreateReportModal.scss';
import sortAlphabetically from '../../utils/sortAlphabetically';
import ExploreShape from './ExploreShape';

class CreateReportModal extends React.Component {
	static propTypes = {
		resolve: PropTypes.func.isRequired,
		custom: PropTypes.arrayOf(ExploreShape),
		standard: PropTypes.arrayOf(ExploreShape)
	};

	goToExplore(id) {
		Tools.$state.go('looker', { type: 'explore', id }, { reload: false });
		this.props.resolve();
	}

	renderExplores(explores, title, className) {
		return (
			<div>
				<Title className="InsightsExploreTitle">{title}</Title>
				{/* 
				This map-reduce wraps every 4th column in a nice new Row element
			*/}
				{explores
					.map(explore => {
						return (
							<Column key={explore.name} className={'InsightsExploreColumn ' + className}>
								<ClickableCard
									className="InsightsExploreCard"
									onClick={() => this.goToExplore(explore.name)}
								>
									<CardContent className="InsightsExploreColumn--Card__center-content">
										{explore.icon && (
											<div className="ClickableCardImg">
												<img src={explore.icon} />
											</div>
										)}
										<Title className="InsightsExploreCardTitle">{explore.label}</Title>
										{explore.description && (
											<Text className="InsightsExploreCardDescription" color="grey-10" size="sm">
												{explore.description}
											</Text>
										)}
									</CardContent>
								</ClickableCard>
							</Column>
						);
					})
					.reduce((result, element, index) => {
						if (index % 4 === 0) {
							result.push([]);
						}

						result[result.length - 1].push(element);
						return result;
					}, [])
					.map((columns, index) => {
						return <Row key={index}>{columns}</Row>;
					})}
			</div>
		);
	}

	render() {
		const t = Tools.$translate;
		return (
			<div id="create-report-modal" className="FullscreenModal--scrollable">
				<button className="exit-button" onClick={() => this.props.resolve()}>
					<Icon name="times" />
				</button>

				<div className="full-screen-modal-content CreateReportModalContent">
					<Title center size="xl">
						{t('looker.selectDataModel')}
					</Title>
					<Title center size="sm" color="grey-10">
						{t('looker.selectDataModelDescription')}
					</Title>

					{this.renderExplores(
						this.props.standard,
						t('looker.defaultModel', { NAME: Tools.AppService.getAccountSelf().client.name }),
						''
					)}
					{this.renderExplores(
						this.props.custom,
						t('looker.customModel', { NAME: Tools.AppService.getAccountSelf().client.name }),
						'InsightsExploreColumn--Smaller'
					)}
				</div>
			</div>
		);
	}
}

// Mapping icons to the standard reports
const standardReports = {
	crm_sales_and_pipeline: {
		icon: 'img/pipeline-1.svg'
	},
	crm_marketing: {
		icon: 'img/marketing/bullhorn.svg'
	},
	crm_sales_and_quotas: {
		icon: 'img/pipeline-1.svg'
	},
	crm_sales_and_pipeline_beginner: {
		icon: 'img/pipeline-1.svg'
	},
	crm_activities_and_appointments: {
		icon: 'img/activity-type.svg'
	},
	crm_activities_and_appointments_beginner: {
		icon: 'img/activity-type.svg'
	},
	crm_agreement: {
		icon: 'img/agreement.svg'
	},
	crm_after_lead_assign: {
		icon: 'img/anonymization.svg'
	},
	crm_revenue_forecast: {
		icon: 'img/document.svg'
	},
	crm_activities_and_quotas: {
		icon: 'img/activity-type.svg'
	},
	crm_base_client: {
		icon: 'img/book.svg'
	}
};

export const mapStateToProps = state => {
	const t = Tools.$translate;
	const { custom, standard } = state.Insights.explores.reduce(
		(result, explore) => {
			const exploreName = explore.name.replace(/_[0-9]*$/, '');
			if (standardReports[exploreName]) {
				result.standard.push({
					...explore,
					...{
						description: t(`looker.explore.${exploreName}.description`) // Adds description language tag to each explore
					},
					...standardReports[exploreName]
				});
			} else {
				result.custom.push({ ...explore });
			}

			return result;
		},
		{ custom: [], standard: [] }
	);

	return {
		custom: custom.sort(sortAlphabetically('label')),
		standard: standard.sort(sortAlphabetically('label'))
	};
};

window.CreateReportModal = connect(mapStateToProps)(CreateReportModal);
export const NotConnected = CreateReportModal;
export default window.CreateReportModal;

'use strict';

angular.module('domain.order').controller('StageList', [
	'$modalParams',
	'$scope',
	'$rootScope',
	'Order',
	'$filter',
	'CustomfieldMapper',
	'$stateParams',
	'RequestBuilder',
	'Contact',
	'NotificationService',
	'$state',
	function (
		$modalParams,
		$scope,
		$rootScope,
		Order,
		$filter,
		CustomfieldMapper,
		$stateParams,
		RequestBuilder,
		Contact,
		NotificationService,
		$state
	) {
		var StageList = this;

		var init = function () {
			var stages = $modalParams.data;
			StageList.lost = [];
			StageList.won = [];
			StageList.open = [];

			_.map(stages, function (stage) {
				if (stage.probability === 0) {
					StageList.lost.push(stage);
				}
				if (stage.probability === 100) {
					StageList.won.push(stage);
				}
				if (stage.probability > 0 && stage.probability < 100) {
					StageList.open.push(stage);
				}
			});
		};

		init();
	}
]);

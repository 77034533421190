import React, { useState, useRef, useEffect } from 'react';
import { Label } from '@upsales/components';
import TextInput from '../../../Inputs/TextInput';
import T from 'Components/Helpers/translate';

const DescriptionField = ({
	defaultDescription,
	required,
	hasError,
	disabled,
	handleChange
}: {
	defaultDescription?: string;
	required?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	handleChange: (type: string, value: string) => void;
}) => {
	const [description, setDescription] = useState(defaultDescription);
	const [touched, setAsTouched] = useState(false);
	const inputRef = useRef<number>();

	useEffect(() => {
		if (!touched && defaultDescription?.length) {
			setDescription(defaultDescription);
			// need to delay the execution in the event queue to avoid trying to read the store object before it is enabled
			setTimeout(() => {
				handleChange('description', defaultDescription);
			});
		}
	}, [defaultDescription]);

	return (
		<div className="flex-row">
			<div className="col">
				<Label required={required}>{T('default.description')}</Label>
				<TextInput
					type="text"
					icon="pencil"
					defaultValue={description}
					placeholder={T('appointment.describeAppointment')}
					onChange={(value: string) => {
						setAsTouched(value?.length > 0);
						setDescription(value);
						clearTimeout(inputRef.current);
						inputRef.current = window.setTimeout(() => {
							handleChange('description', value);
						}, 300);
					}}
					hasError={hasError}
					extraOpts={{
						classes: ['description-field height-fix']
					}}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default DescriptionField;

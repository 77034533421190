import React from 'react';
import { Drawer, DrawerHeader } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';

import './StepDrawer.scss';

type StepDrawerProps = Omit<React.ComponentProps<typeof DrawerHeader>, 'subtitle'> & {
	color?: any;
	subtitle?: string;
	// TODO: change to this when DrawerHeader has correct colors
	// color?: React.ComponentProps<typeof DrawerHeader>['iconColor'],
	icon?: React.ComponentProps<typeof DrawerHeader>['icon'];
	closePopup?: React.ComponentProps<typeof DrawerHeader>['onHide'];
	children?: React.ReactNode;
};

export default function StepDrawer({ title, subtitle, color, icon, closePopup, children }: StepDrawerProps) {
	const classes = new bem('StepDrawer');
	const titleProps = title && subtitle ? { title: subtitle, subtitle: title + ':' } : { title };

	return (
		<Drawer className={classes.b()} show={true}>
			<DrawerHeader
				{...titleProps}
				supertitle={true}
				subtitleColor={color}
				icon={icon}
				iconColor={color}
				onHide={closePopup}
			/>
			{children}
		</Drawer>
	);
}

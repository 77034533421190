'use strict';

angular.module('upResources').factory('Scripts', [
	'$resource',
	'ResourceHelper',
	'$q',
	'URL',
	'API',
	'AppService',
	function ($resource, ResourceHelper, $q, URL, API, AppService) {
		var helper = new ResourceHelper();

		var eventPrefix = 'script';

		helper.setDefaults({
			eventPrefix: eventPrefix,
			createSuccessBody: 'saved.script',
			deleteSucccessBody: 'deleted.script',
			createErrorBody: 'saveError.script',
			deleteErrorBody: 'deleteError.script'
		});

		var types = {
			order: {
				init: {
					edit: 'orderStartEdit'
				},
				save: {
					edit: 'orderStartSaving'
				}
			},
			client: {
				init: {
					edit: 'accountStartEdit',
					view: 'accountView'
				},
				save: {
					edit: 'accountSaving'
				}
			},
			contact: {
				init: {
					edit: 'accountStartEdit',
					view: 'accountView'
				},
				save: {
					edit: 'accountSaving'
				}
			}
		};

		var Resource = $resource(
			URL + API + 'uiScript/:id',
			{},
			{
				update: { method: 'PUT' },
				delete: { method: 'DELETE' },
				create: { method: 'POST' },
				list: { method: 'GET' },
				get: { method: 'GET' }
			}
		);

		var Model = {
			new: function () {
				var script = {
					description: '',
					active: true,
					type: 'order_edit',
					code:
						'// use the relevant object to manipulate data,\n' +
						'// which object that exists depends on when the scripts run (order, account or contact)\n' +
						"// e.g., order.description = 'Hello' to set description  \n" +
						' \n' +
						"// on 'save' scripts, a callback cb() function has to be called, if you don't \n" +
						'// want the object to be saved send an error message as the parameter \n' +
						"// e.g., return cb('the order didn't validate'); \n" +
						' \n' +
						'//jQuery/$, async, moment and some other js libraries are available\n' +
						' \n' +
						'// upsales.me is an object containing info of current user \n' +
						'// upsales.get[type](id, cb) gets an object, \n' +
						'//e.g., upsales.get.account(123, function(err, account) {...}) \n' +
						'// upsales.open[type](id, cb) navigates within the app, \n' +
						'//e.g., upsales.open.account(123) \n' +
						'// upsales.notify(msg) shows a notification \n' +
						'// upsales.warning(msg) shows an alert \n'
				};
				return script;
			},
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			list: function () {
				return Resource.list().$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (obj, options) {
				if (!obj.id) {
					return Resource.create(obj)
						.$promise.then(function (res) {
							helper.onSave(options, res.data, !obj.id);
							Model.list().then(function (listRes) {
								AppService.setScripts(listRes.data);
							});
							return res;
						})
						.catch(function () {
							helper.onSaveError(options, !obj.id);
						});
				}
				return Resource.update({ id: obj.id }, obj)
					.$promise.then(function (res) {
						helper.onSave(options, res.data, !obj.id);
						Model.list().then(function (listRes) {
							AppService.setScripts(listRes.data);
						});
						return res;
					})
					.catch(function () {
						helper.onSaveError(options, !obj.id);
					});
			},

			delete: function (obj, options) {
				if (!obj.id) {
					return;
				}
				return Resource['delete']({ id: obj.id })
					.$promise.then(function (res) {
						helper.onDelete(options, obj);
						return res;
					})
					.catch(function () {
						helper.onDeleteError(options);
					});
			},

			events: {
				added: eventPrefix + '.added',
				updated: eventPrefix + '.updated',
				deleted: eventPrefix + '.deleted'
			},

			getType: function (script) {
				if (
					!types[script.type] ||
					!types[script.type][script.when] ||
					!types[script.type][script.when][script.action]
				) {
					return null;
				}
				return types[script.type][script.when][script.action];
			}
		};

		return Model;
	}
]);

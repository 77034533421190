'use strict';

angular.module('domain.campaign').controller('CampaignAppointments', [
	'$scope',
	'FilterHelper',
	'AppService',
	'$upModal',
	'RequestBuilder',
	'Appointment',
	function ($scope, FilterHelper, AppService, $upModal, RequestBuilder, Appointment) {
		var customerId;
		var CampaignCtrl = $scope.CampaignCtrl;
		var currentFilter;

		var CampaignAppointmentsCtrl = this;

		CampaignCtrl.CampaignAppointmentsCtrl = CampaignAppointmentsCtrl;

		CampaignAppointmentsCtrl.appointmentsError = false;
		CampaignAppointmentsCtrl.limit = 50;
		CampaignAppointmentsCtrl.appointmentsLoading = false;
		CampaignAppointmentsCtrl.appointmentsCurrent = 1;
		CampaignAppointmentsCtrl.appointmentPages = 0;

		$scope.$on('appointment.added', function (e, added) {
			if (added.project && added.project.id === CampaignCtrl.campaign.id) {
				CampaignAppointmentsCtrl.appointments.push(added);
			}
		});

		$scope.$on('appointment.updated', function (e, updated) {
			var index = _.findIndex(CampaignAppointmentsCtrl.appointments, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'appointment')) {
				if (index !== -1) {
					CampaignAppointmentsCtrl.appointments[index] = updated;
				} else {
					CampaignAppointmentsCtrl.appointments.push(updated);
				}
			} else if (index !== -1) {
				CampaignAppointmentsCtrl.appointments.splice(index, 1);
			}
		});

		$scope.$on('appointment.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignAppointmentsCtrl.appointments, { id: deleted.id });

			if (index !== -1) {
				CampaignAppointmentsCtrl.appointments.splice(index, 1);
			}
		});

		CampaignAppointmentsCtrl.getAppointments = function ($page) {
			CampaignAppointmentsCtrl.appointmentsError = false;
			CampaignAppointmentsCtrl.appointmentsLoading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Appointment.attr.project, filter.comparisonTypes.Equals, CampaignCtrl.campaign.id);
			filter.addSort('date', false);
			filter.limit = CampaignAppointmentsCtrl.limit;
			filter.offset = CampaignAppointmentsCtrl.limit * ($page - 1);
			currentFilter = filter.build();

			Appointment.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					CampaignAppointmentsCtrl.appointmentsLoading = false;
					CampaignAppointmentsCtrl.appointments = res.data;
					CampaignCtrl.appointmentsTotal = res.metadata.total;

					if (CampaignCtrl.appointmentsTotal !== 0) {
						CampaignAppointmentsCtrl.appointmentPages = Math.ceil(
							CampaignCtrl.appointmentsTotal / CampaignAppointmentsCtrl.limit
						);
					}
				})
				.catch(function () {
					CampaignAppointmentsCtrl.appointmentsLoading = false;
					CampaignAppointmentsCtrl.appointmentsError = true;
				});
		};

		CampaignAppointmentsCtrl.moveAppointment = function (appointment) {
			appointment.project = null;
			Appointment.customer(customerId).save(appointment);
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			CampaignAppointmentsCtrl.getAppointments(1);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React, { Component } from 'react';
import AdminHeader from 'Components/Admin/AdminHeader';
import _ from 'lodash';
import t from 'Components/Helpers/translate';

type Props = {
	rootData: {
		pageData: {
			enabled: boolean;
			pageLoading: boolean;
			myIp: string;
			ipList: string[];
			toggleIpBlock: (state: boolean) => void;
			originalIps: {
				id: number;
				ipAddress: string;
				rule: 'allow' | 'deny';
				sortIdx: number;
				userId: number;
			}[];
			onChange: (value: string[]) => void;
		};
		saving: boolean;
	};
};

class IpBlock extends Component<Props> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			title: t('admin.ipblock.title'),
			description: t('admin.ipblock.description'),
			use: t('default.use'),
			limitedLogin: t('admin.ipblock.use'),
			addMyIp: t('admin.ipblock.addMyIp'),
			ipSelectDescription: t('admin.ipblock.selectDescription'),
			ipSelectPlaceholder: t('admin.ipblock.selectPlaceholder'),
			readMore: t('default.readMore')
		};
	}

	showAddOwnIp() {
		const ipList = this.props.rootData.pageData.ipList;
		const myIp = this.props.rootData.pageData.myIp;

		return _.find(ipList, { ipAddress: myIp }) === undefined ? true : false;
	}

	addOwnIp = () => {
		const pageData = this.props.rootData.pageData;
		const myIp = pageData.myIp;
		pageData.onChange([myIp].concat(pageData.ipList));
	};

	onChange = (values: string[]) => {
		this.props.rootData.pageData.onChange(values);
	};

	render() {
		const onChangeDebounce = _.debounce(this.onChange, 100);
		const pageData = this.props.rootData.pageData;
		return (
			<div id="admin-page-ipblock">
				<AdminHeader
					title={this.lang.title}
					description={this.lang.description}
					image="ip-block.svg"
					toggle={true}
					toggleChecked={pageData.enabled}
					toggleDisabled={pageData.pageLoading}
					onToggleChange={pageData.toggleIpBlock}
					toggleLabel={this.lang.limitedLogin}
					articleId={595}
				/>
				<div id="admin-content">
					<div className="admin-section">
						<div className="row">
							<div className="col-md-8">
								<div className="ip-block-textarea admin-table" style={{ height: '295px' }}>
									<div
										className="admin-table-top"
										style={{
											padding: '20px',
											lineHeight: '1.5em'
										}}
									>
										<span>{this.lang.ipSelectDescription}</span>
									</div>

									<div className="selct2-holder">
										<ReactTemplates.INPUTS.upTags
											className="form-control"
											tools={Tools}
											data={pageData.ipList}
											placeholder={this.lang.ipSelectPlaceholder}
											onChange={onChangeDebounce}
											disabled={this.props.rootData.saving}
										/>
									</div>

									{this.showAddOwnIp() ? (
										<button onClick={this.addOwnIp} className="up-btn btn-link btn-bright-blue">
											{this.lang.addMyIp + ' (' + pageData.myIp + ')'}
										</button>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default IpBlock;

import AdminHeader from 'Components/Admin/AdminHeader';
import logError from 'App/babel/helpers/logError';
import { Toggle, Help, Icon, Button } from '@upsales/components';
import t from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import RoleSelect from 'Components/RoleSelect';

ReactTemplates.admin.orderstages = window.ReactCreateClass({
	getInitialState: function () {
		return {
			editRolesOpen: false,
			edit: null,
			targetKey: null,
			touched: {
				name: false,
				probability: false
			},
			isNew: false,
			editToEdit: false,
			sorting: '',
			sortDirection: 'asc'
		};
	},
	UNSAFE_componentWillMount: function () {
		this.version = Tools.AppService.getAccountSelf().version;

		this.lang = {
			description: t('admin.stages.description'),
			title: t('default.stages'),
			stage: t('default.stage'),
			permission: t('default.permissionToRole'),
			probability: t('default.probability'),
			new: t('default.new'),
			save: t('default.save'),
			cancel: t('default.abort'),
			all: t('default.all'),
			name: t('tag.account.name'),
			percent: t('admin.customfieldType.Percent'),
			role: t('default.role'),
			noScripts: t('admin.noScripts'),
			none: t('default.none'),
			more: t('default.more2'),
			warning: t('default.warning'),
			warning1: t('admin.stages.confirmRemoval.body'),
			ok: t('default.confirm'),
			orders: t('default.orders'),
			order: t('default.order'),
			deleteStage: t('soliditet.matcher.actionDelete') + ' ' + t('default.stage').toLowerCase(),
			confirm: t('default.confirm'),
			exclude: t('default.exclude'),
			excluded: t('default.excluded')
		};

		this.editInlineFieldName = this.editInlineField.bind(this, 'name');
		this.editInlineFieldProbability = this.editInlineField.bind(this, 'probability');

		this.edit_ClickOnName = this.inlineEdit;
		this.edit_ClickOnProbability = this.inlineEdit;
		this.edit_ClickOnRole = this.inlineEdit;
	},
	onNewField: function () {
		if (!(this.state.edit && !this.state.edit.id)) {
			this.setState({
				edit: { name: '', probability: '', roles: [] },
				touched: { name: false, probability: false },
				isNew: true
			});
		}
	},
	onDelete: function (item, event) {
		var self = this;
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();

		var promise = self.props.rootData.pageData.checkStage(item);

		promise
			.then(function ({ orderCount, subscriptionCount }) {
				const canBeRemoved = orderCount + subscriptionCount === 0;

				if (canBeRemoved) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							body: self.lang.warning1,
							title: self.lang.deleteStage,
							confirmButtonText: self.lang.ok,
							onClose: confirmed => {
								if (confirmed) {
									self.props.rootData.pageData.onDeleteStage(item);
								}
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					self.props.tools.$upModal
						.open('warningConfirm', {
							body: self.lang.warning1,
							title: self.lang.deleteStage,
							resolveTrue: self.lang.ok
						})
						.then(function () {
							self.props.rootData.pageData.onDeleteStage(item);
						});
				} else {
					const bodyOrderMsg = t('admin.stages.confirmRemoval.bodyOrder', {
						ordersCount: orderCount
					});
					const bodySubsMsg = t('admin.stages.confirmRemoval.bodySubscription', { count: subscriptionCount });

					let alertBodyMsg = '';

					if (orderCount > 0) {
						alertBodyMsg += bodyOrderMsg + ' ';
					}
					if (subscriptionCount > 0) {
						alertBodyMsg += bodySubsMsg;
					}

					self.props.tools.$upModal.open('errorAlert', {
						body: alertBodyMsg,
						title: self.lang.deleteStage,
						resolveTrue: self.lang.ok
					});
				}
			})
			.catch(e => logError(e, 'Failed to check stage'));
	},
	inlineEdit: function (item, property) {
		var stateObject = {
			edit: _.cloneDeep(item),
			targetKey: property
		};

		if (this.state.edit) {
			stateObject.editToEdit = true;
		}

		if (property !== 'role') {
			stateObject.editRolesOpen = false;
		}

		this.setState(stateObject);
	},
	save: function (item) {
		if (this.validate('name', item) && this.validate('probability', item)) {
			var onSave = this.props.rootData.onSave;
			onSave(item);
		}
	},
	validate: function (property, item) {
		var stages = this.props.rootData.pageData.stages;

		if (property === 'name') {
			var index = _.findIndex(stages, function (_item) {
				return (item.name || '').toLowerCase() === (_item.name || '').toLowerCase() && item.id !== _item.id;
			});

			return index === -1 && !!item.name;
		} else if (property === 'probability') {
			var probability = parseInt(item.probability);
			return !isNaN(probability) && probability <= 100 && probability >= 0;
		} else {
			return false;
		}
	},
	editInlineField: function (property, event) {
		var edit = this.state.edit;
		var value = (event.target.value || '').substring(0, 30);

		if (property === 'probability') {
			value = value.replace(/[^0-9]/g, '');
		}

		var touched = _.cloneDeep(this.state.touched);
		touched[property] = true;

		edit[property] = value;

		this.setState({
			edit: this.state.edit,
			touched: touched
		});
	},
	editExclude: function (value) {
		var edit = this.state.edit;

		var touched = _.cloneDeep(this.state.touched);
		touched.exclude = true;

		edit.exclude = value;

		this.setState({
			edit: this.state.edit,
			touched: touched
		});
	},
	changeRole: function (value) {
		this.state.edit.roles = value;
		this.setState({
			edit: this.state.edit
		});
	},
	stopEdit: function () {
		this.setState({
			edit: null,
			isNew: false,
			targetKey: null,
			editToEdit: false
		});
	},
	saveEdit: function () {
		if (this.validate('name', this.state.edit) && this.validate('probability', this.state.edit)) {
			var onSave = this.props.rootData.onSave;
			onSave(this.state.edit);

			this.setState({ edit: null, isNew: false, targetKey: null, editToEdit: false });
		}
	},
	openEditRoles: function () {
		this.setState({ editRolesOpen: true });
	},
	closeEditRoles: function () {
		this.setState({ editRolesOpen: false });
	},
	row: function (item) {
		var self = this;
		var hasRoleAccess = self.props.rootData.pageData.hasRoleFeature;
		var onDelete = this.onDelete.bind(this, item);

		var roleString = <ReactTemplates.admin.tableTokens items={item.roles} />;

		return (
			<window.UiTableRow key={'stage-' + item.id}>
				<window.UiTableColumn
					className="AdminOrderStages__table-name"
					title={item.name}
					subtitle={item.exclude ? self.lang.excluded : null}
					onClick={this.edit_ClickOnName.bind(this, item, 'name')}
				/>
				<window.UiTableColumn
					className="AdminOrderStages__table-exclude"
					onClick={this.edit_ClickOnName.bind(this, item, 'name')}
				/>

				<window.UiTableColumn
					className="AdminOrderStages__table-probability"
					onClick={this.edit_ClickOnProbability.bind(this, item, 'probability')}
					title={item.probability + '%'}
					subtitle={
						<window.UiProgressbar
							className="AdminOrderStages__Progress"
							size="sm"
							color={item.probability === 100 ? 'bright-green' : 'bright-blue'}
							value={item.probability}
						/>
					}
				/>
				{hasRoleAccess && (
					<window.UiTableColumn
						className="AdminOrderStages__table-roles"
						onClick={this.edit_ClickOnRole.bind(this, item, 'role')}
					>
						{roleString}
					</window.UiTableColumn>
				)}
				<window.UiTableColumn
					align="right"
					className="AdminOrderStages__table-tools"
					onClick={this.edit_ClickOnRole.bind(this, item, 'name')}
				>
					{this.props.rootData.pageData?.stages?.length > 1 ? (
						<div className="AdminOrderStages__table-tools-inner">
							<Button
								data-test-id="delete-stage-button"
								color="grey"
								type="link"
								className="delete-stage"
								onClick={onDelete}
							>
								<i className="fa fa-trash" />
							</Button>
						</div>
					) : null}
				</window.UiTableColumn>
			</window.UiTableRow>
		);
	},
	saveOnEnter: function (item, event) {
		if (event.keyCode === 13) {
			event.preventDefault();
			event.stopPropagation();
			this.saveEdit();
		}
	},
	editRow: function (item, index) {
		var self = this;
		var tools = this.props.tools;
		var hasRoleAccess = self.props.rootData.pageData.hasRoleFeature;

		var nameClasses = 'floating-label-input';

		if (item.name && item.name.length > 0) {
			nameClasses = nameClasses + ' has-value';
		}
		if (self.state.touched.name && !self.validate('name', item)) {
			nameClasses = nameClasses + ' has-error';
		}

		var probabilityClasses = 'floating-label-input';

		if (!isNaN(parseInt(item.probability))) {
			probabilityClasses = probabilityClasses + ' has-value';
		}
		if (self.state.touched.probability && !self.validate('probability', item)) {
			probabilityClasses = probabilityClasses + ' has-error';
		}

		var timeoutVariable;

		var eventListeners = {
			'select2-blur': function () {
				timeoutVariable = setTimeout(function () {
					self.closeEditRoles();
				}, 200);
			},
			'select2-selecting': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-opening': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-focus': function () {
				clearTimeout(timeoutVariable);
			}
		};

		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');

		return (
			<window.UiTableRow key={'stage-edit-' + item.id} style={{ height: '62px', backgroundColor: '#f5f5f5' }}>
				<window.UiTableColumn className="AdminOrderStages__table-name">
					<div data-test-id="editing-stage-name" className={nameClasses}>
						<label>{self.lang.name}</label>
						<input
							id="admin-stages-name-input"
							key={index + 'nameInput'}
							type="text"
							name="name"
							onChange={this.editInlineFieldName}
							onKeyDown={this.saveOnEnter.bind(self, item)}
							value={item.name}
						/>
					</div>
				</window.UiTableColumn>
				<window.UiTableColumn className="AdminOrderStages__table-exclude">
					{item.probability === 100 ? (
						<div>
							<label className="AdminOrderStages__exclude-label">{this.lang.exclude}</label>
							<Toggle
								checked={!!item.exclude}
								color="medium-green"
								size="sm"
								onChange={this.editExclude}
							/>
							<Help articleId={954} sidebar />
						</div>
					) : null}
				</window.UiTableColumn>
				<window.UiTableColumn className="AdminOrderStages__table-probability">
					<div className={probabilityClasses}>
						<label>{self.lang.probability}</label>
						<input
							id="admin-stages-probability-input"
							key={index + 'probabilityInput'}
							name="probability"
							onChange={this.editInlineFieldProbability}
							onKeyDown={this.saveOnEnter.bind(self, item)}
							value={item.probability}
						/>
					</div>
				</window.UiTableColumn>
				{hasRoleAccess ? (
					<window.UiTableColumn className="AdminOrderStages__table-roles">
						<div className="role-select-wrap">
							{hasTreeSelect ? (
								<RoleSelect
									roles={self.props.rootData.pageData.roles}
									selectedRoles={item.roles}
									onChange={self.changeRole}
								/>
							) : (
								<div style={{ display: self.state.editRolesOpen ? 'block' : 'none' }}>
									<ReactTemplates.INPUTS.upRoles
										id="admin-stages-role-input"
										key={'role-select'}
										tools={tools}
										data={self.props.rootData.pageData.roles}
										value={item.roles}
										multiple={true}
										onChange={self.changeRole}
										eventListeners={eventListeners}
									/>
								</div>
							)}
						</div>
					</window.UiTableColumn>
				) : null}
				<window.UiTableColumn className="AdminOrderStages__table-tools" align="right">
					<Button
						data-test-id="admin-stages-save-edit"
						onClick={self.saveEdit.bind(self, item)}
						shadow="none"
						size="sm"
						disabled={self.validate('name', item) && self.validate('probability', item) ? false : true}
					>
						{self.lang.save}
					</Button>
					<Button onClick={self.stopEdit} shadow="none" size="sm" color="grey" type="link">
						{self.lang.cancel}
					</Button>
				</window.UiTableColumn>
			</window.UiTableRow>
		);
	},
	componentDidUpdate: function (prevProps, prevState) {
		if (this.state.edit && !this.state.editRolesOpen) {
			this.setState({
				editRolesOpen: true
			});
		}

		if (this.state.edit && this.state.edit.id) {
			if (!prevState.edit || this.state.editToEdit) {
				if (this.state.targetKey) {
					switch (this.state.targetKey) {
						case 'role':
							setTimeout(function () {
								$('#admin-stages-role-input').select2('open');
							}, 20);
							break;

						case 'probability':
							var prob = $('#admin-stages-probability-input');
							prob.focus();
							prob[0].setSelectionRange(prob.val().length, prob.val().length);
							break;

						default:
							var elm = $('#admin-stages-name-input');
							elm.focus();
							elm[0].setSelectionRange(elm.val().length, elm.val().length);
							break;
					}
				} else {
					var _elm = $('#admin-stages-name-input');
					_elm.focus();
					_elm[0].setSelectionRange(_elm.val().length, _elm.val().length);
				}
			}
		}

		if (this.state.edit && this.state.isNew && !prevState.isNew) {
			$('#admin-stages-name-input').focus().select();
		}
	},

	doSort: function (sort) {
		var type = sort.field;
		if (this.state.sortDirection && this.state.sortDirection === 'asc') {
			this.setState({
				sorting: type,
				sortDirection: 'desc'
			});
		} else {
			this.setState({
				sorting: type,
				sortDirection: 'asc'
			});
		}
	},

	sortIcon: function (name) {
		var sort = this.state.sorting;
		var direction = this.state.sortDirection;

		if (sort !== name || sort.length === 0) {
			return <i className="fa fa-sort" style={{ paddingLeft: '5px' }} />;
		}

		if (name === 'probability' && sort === name) {
			return <i className={'fa fa-sort-numeric-' + direction} style={{ paddingLeft: '5px', color: 'inherit' }} />;
		}

		return <i className={'fa fa-sort-alpha-' + direction} style={{ paddingLeft: '5px', color: 'inherit' }} />;
	},

	sortFunc: function (stages) {
		var sort = this.state.sorting;
		var direction = this.state.sortDirection;

		if (Array.isArray(stages)) {
			stages.sort(function (a, b) {
				var _a = sort === 'probability' ? a[sort] : a[sort].toUpperCase();
				var _b = sort === 'probability' ? b[sort] : b[sort].toUpperCase();

				if (direction === 'asc') {
					if (_a < _b) {
						return -1;
					}

					if (_a > _b) {
						return 1;
					}
				}

				if (_a > _b) {
					return -1;
				}

				if (_a < _b) {
					return 1;
				}

				return 0;
			});
		}

		return stages;
	},

	render: function () {
		var self = this;
		var zeroStages = _.filter(this.props.rootData.pageData.stages, function (stage) {
			return stage.probability === 0;
		});

		var stages = _.filter(this.props.rootData.pageData.stages, function (stage) {
			return stage.probability > 0;
		});

		stages = stages.concat(zeroStages);

		if (this.state.sorting) {
			stages = self.sortFunc(stages);
		}

		var rows = _.map(stages, function (item, index) {
			if (self.state.edit && self.state.edit.id === item.id) {
				return self.editRow(self.state.edit, index);
			} else {
				return self.row(item, index);
			}
		});

		if (self.state.edit && !self.state.edit.id) {
			rows.unshift(self.editRow(self.state.edit, -1));
		}

		var hasRoleAccess = self.props.rootData.pageData.hasRoleFeature;

		var columns = [{ title: self.lang.stage, sort: 'name' }];
		columns.push({ title: '' });
		columns.push({ title: self.lang.probability, sort: 'probability' });
		if (hasRoleAccess) {
			columns.push({ title: self.lang.permission });
		}
		columns.push({ title: '' });

		return (
			<div id="admin-page-stages" className="AdminOrderStages">
				<AdminHeader
					title={this.lang.title}
					description={this.lang.description}
					image="pipeline.svg"
					articleId={463}
				/>
				<div id="admin-content">
					<window.UiCard>
						<window.UiCardHeader title={self.lang.all + ' ' + self.lang.title.toLowerCase()}>
							<Button
								disabled={self.state.edit ? true : false}
								size="sm"
								shadow="none"
								onClick={self.onNewField.bind(self, 'stage')}
								color="green"
							>
								<Icon name="plus" /> {self.lang.new + ' ' + self.lang.stage.toLowerCase()}
							</Button>
						</window.UiCardHeader>
						<window.UiTable className="AdminOrderStages__table">
							<window.UiTableHeader
								onSortChange={self.doSort}
								sorting={this.state.sorting}
								asc={this.state.sortDirection === 'asc'}
								columns={columns}
							/>
							{rows}
						</window.UiTable>
					</window.UiCard>
				</div>
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import ModalWrap from 'Components/ModalWrap';
import { Modal, ModalHeader, ModalContent, Card, Block, Text, Row, Column, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import BillingAddonShape from 'App/babel/propTypes/BillingAddonShape';
import T from 'Components/Helpers/translate';
import { getTierString } from 'App/babel/helpers/addons';
import './BillingAddonTiersModal.scss';

const currencyFormat = (num, c) => Tools.$filter('currencyFormat')(num, c, true);

const BillingAddonTiersModal = ({ addon, onClose }) => {
	const classes = new bemClass('BillingAddonTiersModal');

	return (
		<ModalWrap isMounted={!!addon} className={classes.b()} onClose={onClose}>
			{addon ? (
				<Modal size="sm">
					<ModalHeader
						color="blue"
						title={`${T('admin.billing.addon.tiersFor')} ${T(addon.name)}`}
						onClose={onClose}
						icon="tiers"
						className="ModalHeader--no-border-bottom"
					/>
					<ModalContent>
						{addon.tiers.map(tier => {
							const current = addon.tier && addon.tier.start === tier.start;
							return (
								<Card
									shadow={current ? null : 'none'}
									color={current ? 'white' : 'transparent'}
									key={`${tier.start}-${tier.end}`}
									className={classes.elem('tier').b()}
								>
									<Row>
										<Column fixedWidth={35} className={classes.elem('current-col').b()}>
											{current ? (
												<Block
													backgroundColor="bright-green"
													className={classes.elem('current').b()}
												>
													<Icon name="check" color="white" space="mrs" />
												</Block>
											) : null}
										</Column>
										<Column>
											<Text color="black">
												{getTierString({
													...addon,
													tier
												})}
											</Text>
											<Text color="grey-10" size="sm">
												{tier.price
													? currencyFormat(
															Math.ceil(
																tier.price /
																	(addon.productInterval ?? addon.priceMonthPeriod)
															),
															addon.currency
													  )
													: T('integration.free')}
											</Text>
										</Column>
									</Row>
								</Card>
							);
						})}
					</ModalContent>
				</Modal>
			) : null}
		</ModalWrap>
	);
};

BillingAddonTiersModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	addon: BillingAddonShape
};

export default BillingAddonTiersModal;

'use strict';

angular.module('upDirectives').directive('upNoResultRow', [
	'$translate',
	function ($translate) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if (!attrs.upNoResultRow) {
					throw new Error('No listType sent to upNoResultRow directive');
				}

				var type = scope.$eval(attrs.upNoResultRow) || attrs.upNoResultRow;
				var text = '';

				if (
					/^opportunity|contact|order|account|activity|automation|action|visitor|submit|banner|adCampaign|phonecall$/.test(
						type
					)
				) {
					text = $translate.instant('noResult.' + type);

					if (attrs.add) {
						angular
							.element('<a>')
							.appendTo(element.find('td'))
							.text($translate('default.add'))
							.on('click', function (e) {
								scope.$eval(attrs.add, { $event: e });
							});
					}
				} else {
					text = $translate.instant('noResult.default');
				}

				var colspan = attrs.colspan || 100;

				// create row
				var column = angular.element('<td colspan=' + colspan + '>' + text + '.</td>');

				element.addClass('no-result-row').append(column);
			}
		};
	}
]);

'use strict';

ReactTemplates.flash.clockWidget = window.ReactCreateClass({
	getInitialState: function () {
		var time;

		if (this.after17()) {
			var date = moment();
			time = date.format('HH:mm');
			var subtitle = date.format('zz ZZ');

			return { mode: 3, time: time, subText: subtitle };
		} else {
			time = moment.utc(moment().hour(17).minute(1).diff(moment(this.props.time))).format('H[h] mm[m]');

			return { mode: 1, time: time, subText: '' };
		}
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			close: t('flash.close'),
			minimize: t('flash.minimize'),
			timeInFlashMode: t('flash.timeInFlashMode'),
			untilClocktIs17: t('flash.untilClocktIs17')
		};
	},
	componentWillUnmount: function () {
		// Unsubscribe events here
		clearInterval(this.state.intervalId);
	},
	componentDidUpdate: function () {
		// Start interval if flash is open and interval has not already been started
		if (this.props.open && !this.state.intervalId) {
			var intervalId = setInterval(this.timer, 1000);
			var time, subText, mode;

			if (this.after17()) {
				var date = moment();

				mode = 3;
				time = date.format('HH:mm');
				subText = date.format('zz ZZ');
			} else {
				var m1 = moment().hour(17).minute(1).second(0);
				var m2 = moment(this.props.time);

				mode = 1;
				time = moment.utc(m1.diff(m2)).format('H[h] mm[m]');
				subText = this.lang.untilClocktIs17;
			}

			this.setState({ mode: mode, time: time, intervalId: intervalId, subText: subText });
		}
	},
	after17: function () {
		var m1 = moment().hour(17).minute(1).second(0);
		var m2 = moment();
		m1.isBefore(m2);

		return m1.isBefore(m2);
	},
	timer: function (m) {
		var time, subtitle;
		var self = this;
		var mode = m ? m : this.state.mode;

		if (this.after17() && mode === 1) {
			mode = 3;
		}

		// which type of time to render?
		switch (mode) {
			case 1: // clock until 17:00
				// Only update every 5 second... fix a rounding problem
				var m1 = moment().hour(17).minute(1).second(0);
				var m2 = moment();

				time = moment.utc(m1.diff(m2)).format('H[h] mm[m]');
				subtitle = self.lang.untilClocktIs17;
				break;
			case 2: // duration in flash mode
				time = moment.utc(+moment() - self.props.time).format('H[h] m[m] s[s]');
				subtitle = self.lang.timeInFlashMode;
				break;
			case 3: // actual time right now
				var date = moment();
				time = date.format('HH:mm');
				subtitle = date.format('zz ZZ');
				break;
		}

		if (!this.props.open) {
			clearInterval(this.state.intervalId);
			self.setState({
				mode: mode,
				time: time,
				intervalId: null,
				subText: subtitle
			});
		} else {
			self.setState({
				mode: mode,
				time: time,
				intervalId: self.state.intervalId,
				subText: subtitle
			});
		}
	},
	toggleMode: function () {
		var state = this.state.mode + 1;

		var mode;

		if (this.after17()) {
			mode = ((state + 2) % 2) + 2; // [2-3]
		} else {
			mode = ((state + 2) % 3) + 1; // [1-3]
		}

		this.timer(mode);
	},
	render: function () {
		var self = this;

		var clock = (
			<div>
				<div className="title">{self.state.time}</div>
				<div className="subtitle">{self.state.subText}</div>
			</div>
		);

		return (
			<div id="footer-clock-widget" onClick={self.toggleMode}>
				{clock}
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import AdminHeader from '../Admin/AdminHeader';
import SingleRow from './MailSignatureRow';
import { connect } from 'react-redux';
import EditMailSignatureRoot from 'Components/MailSignature/EditMailSignatureRoot';

import { Card, CardHeader, Button, Table, TableHeader } from '@upsales/components';

import {
	setSort,
	stopEdit,
	startEdit,
	saveSignature,
	getSignatures,
	deleteSignature,
	createSignature
} from 'Store/reducers/MailSignatureReducer';

import './MailSignature.scss';

const mapStateToProps = state => ({
	signatures: state.MailSignature.signatures,
	sort: state.MailSignature.sort,
	asc: state.MailSignature.asc,
	edit: state.MailSignature.edit,
	lang: state.MailSignature.lang
});

const mapDispatchToProps = {
	setSort,
	stopEdit,
	startEdit,
	getSignatures,
	saveSignature,
	deleteSignature,
	createSignature
};

class MailSignature extends React.Component {
	constructor(props) {
		super(props);

		this.renderTable = this.renderTable.bind(this);
	}

	renderNoFieldsSelected() {
		const { createSignature } = this.props;
		return (
			<tr className="briefcase-holder">
				<td colSpan="4">
					<div className="briefcase" style={{ backgroundImage: 'url(img/empty-briefcase.svg)' }} />
					<h5 className="no-fields-title">{Tools.$translate('mailSignature.noResult.title')}</h5>
					<p className="no-fields-copy" onClick={createSignature}>
						{Tools.$translate('mailSignature.noResult.copy')}
					</p>
				</td>
			</tr>
		);
	}

	renderTable() {
		const { setSort, createSignature, saveSignature, sort, edit, lang } = this.props;
		const { asc, signatures } = this.props;

		if (edit !== null) {
			if (!Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				Tools.$upModal.open('alert', {
					dialog: EditMailSignatureRoot,
					className: 'form',
					edit: edit,
					onSave: saveSignature,
					onDelete: deleteSignature
				});
			}
		}

		return (
			<Card className="admin-mailsignature-table" key={'mailsignature-table'}>
				<CardHeader title={lang.headerTitle}>
					<Button onClick={createSignature} shadow="none" size="sm">
						{lang.newSignature}
					</Button>
				</CardHeader>
				<Table className="mail-signature-table">
					<TableHeader
						sorting={sort}
						asc={asc}
						columns={[
							{ title: Tools.$translate('default.name'), sort: 'name' },
							{ title: Tools.$translate('default.status'), sort: 'active', sortType: 'numeric' },
							{ title: Tools.$translate('default.default') },
							{ title: '', props: { style: { width: '60px' } } }
						]}
						onChangeSort={sort => setSort({ sort: sort.field, asc: sort.asc })}
					/>
					{signatures.length
						? signatures.map(s => <SingleRow key={s.name + s.id} data={s} />)
						: this.renderNoFieldsSelected()}
				</Table>
			</Card>
		);
	}

	render() {
		return (
			<div className="admin-root-wrapper">
				<AdminHeader
					title={this.props.lang.headerTitle}
					description={this.props.lang.headerDescription}
					image="admin-mailsignature.svg"
				/>
				<div id="admin-content">{this.renderTable()}</div>
			</div>
		);
	}
}

MailSignature.propTypes = {
	setSort: PropTypes.func.isRequired,
	createSignature: PropTypes.func.isRequired,
	deleteSignature: PropTypes.func.isRequired,
	saveSignature: PropTypes.func.isRequired,
	sort: PropTypes.string.isRequired,
	asc: PropTypes.bool.isRequired,
	signatures: PropTypes.array,
	edit: PropTypes.object,
	lang: PropTypes.object.isRequired
};

export const detached = MailSignature;
export default connect(mapStateToProps, mapDispatchToProps)(MailSignature);

export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	name: {
		title: 'default.name',
		field: 'name',
		locked: true
	},
	active: {
		title: 'default.active',
		field: 'active'
	},
	phone: {
		title: 'default.phone',
		field: 'phone'
	},
	fax: {
		title: 'default.fax',
		field: 'fax'
	},
	webpage: {
		title: 'tag.account.www',
		field: 'webpage'
	},
	assigned: {
		title: 'scoreboard.assignedUser',
		field: 'assigned.user.name'
	},
	score: {
		field: 'score',
		title: 'default.score'
	},
	scoreUpdateDate: {
		title: 'default.scoreUpdate',
		field: 'scoreUpdateDate'
	},
	users: {
		title: 'default.accountManager',
		field: 'user.name'
	},
	lead: {
		title: 'default.lead',
		field: 'lead.id'
	},
	campaigns: {
		title: 'default.campaigns',
		field: 'project.name'
	},
	categories: {
		title: 'default.categories',
		field: 'category.name'
	},
	parent: {
		title: 'default.parentCompany',
		field: 'parent.name'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},

	adClicks: {
		field: 'ads.clicks',
		title: 'default.adClicks'
	},
	adImpressions: {
		field: 'ads.impressions',
		title: 'default.adImpressions'
	},
	adLastImpression: {
		title: 'default.adLastImpression',
		field: 'ads.lastImpression'
	},

	addressMail: {
		title: 'address.address.mail',
		field: 'address.address_Mail'
	},
	cityMail: {
		title: 'address.city.mail',
		field: 'address.city_Mail'
	},
	countryMail: {
		title: 'address.country.mail',
		field: 'address.country_Mail'
	},
	stateMail: {
		title: 'address.state.mail',
		field: 'address.state_Mail'
	},
	zipcodeMail: {
		title: 'address.zipcode.mail',
		field: 'address.zipcode_Mail'
	},

	addressVisit: {
		title: 'address.address.visit',
		field: 'address.address_Visit'
	},
	cityVisit: {
		title: 'address.city.visit',
		field: 'address.city_Visit'
	},
	countryVisit: {
		title: 'address.country.visit',
		field: 'address.country_Visit'
	},
	stateVisit: {
		title: 'address.state.visit',
		field: 'address.state_Visit'
	},
	zipcodeVisit: {
		title: 'address.zipcode.visit',
		field: 'address.zipcode_Visit'
	},

	addressDelivery: {
		title: 'address.address.delivery',
		field: 'address.address_Delivery'
	},
	cityDelivery: {
		title: 'address.city.delivery',
		field: 'address.city_Delivery'
	},
	countryDelivery: {
		title: 'address.country.delivery',
		field: 'address.country_Delivery'
	},
	stateDelivery: {
		title: 'address.state.delivery',
		field: 'address.state_Delivery'
	},
	zipcodeDelivery: {
		title: 'address.zipcode.delivery',
		field: 'address.zipcode_Delivery'
	},

	addressInvoice: {
		title: 'address.address.invoice',
		field: 'address.address_Invoice'
	},
	cityInvoice: {
		title: 'address.city.invoice',
		field: 'address.city_Invoice'
	},
	countryInvoice: {
		title: 'address.country.invoice',
		field: 'address.country_Invoice'
	},
	stateInvoice: {
		title: 'address.state.invoice',
		field: 'address.state_Invoice'
	},
	zipcodeInvoice: {
		title: 'address.zipcode.invoice',
		field: 'address.zipcode_Invoice'
	},

	addressOther: {
		title: 'address.address.other',
		field: 'address.address_Other'
	},
	cityOther: {
		title: 'address.city.other',
		field: 'address.city_Other'
	},
	countryOther: {
		title: 'address.country.other',
		field: 'address.country_Other'
	},
	stateOther: {
		title: 'address.state.other',
		field: 'address.state_Other'
	},
	zipcodeOther: {
		title: 'address.zipcode.other',
		field: 'address.zipcode_Other'
	},

	type: {
		title: 'address.type',
		field: 'address.type'
	},

	dunsNo: {
		title: 'default.duns',
		field: 'dunsNo'
	},
	orgNo: {
		title: 'default.orgNumber',
		field: 'orgNo',
		unreleasedFeature: 'NEW_FIELDS'
	},
	registrationDate: {
		title: 'default.founded',
		field: 'registrationDate',
		unreleasedFeature: 'NEW_FIELDS'
	},
	sniCode: {
		title: 'default.companyBranch.sni',
		field: 'sniCode',
		unreleasedFeature: 'NEW_FIELDS'
	},
	sicCode: {
		title: 'default.companyBranch.sic',
		field: 'sicCode',
		unreleasedFeature: 'NEW_FIELDS'
	},
	naicsCode: {
		title: 'default.companyBranch.naics',
		field: 'naicsCode',
		unreleasedFeature: 'NEW_FIELDS'
	},
	ukSicCode: {
		title: 'default.companyBranch.uksic',
		field: 'ukSicCode',
		unreleasedFeature: 'NEW_FIELDS'
	},
	naceCode: {
		title: 'default.companyBranch.nace',
		field: 'naceCode',
		unreleasedFeature: 'NEW_FIELDS'
	},
	companyForm: {
		title: 'soliditet.corporateForm',
		field: 'companyForm',
		unreleasedFeature: 'NEW_FIELDS'
	},
	creditRating: {
		title: 'default.creditRating.bisnode',
		field: 'creditRating',
		unreleasedFeature: 'NEW_FIELDS'
	},
	turnover: {
		title: 'default.turnover',
		field: 'turnover',
		unreleasedFeature: 'NEW_FIELDS'
	},
	profit: {
		title: 'default.profit',
		field: 'profit',
		unreleasedFeature: 'NEW_FIELDS'
	},
	clientStatus: {
		title: 'default.status',
		field: 'status',
		unreleasedFeature: 'NEW_FIELDS'
	},
	noEmployees: {
		title: 'default.numOfEmployees',
		field: 'noEmployees',
		unreleasedFeature: 'NEW_FIELDS'
	},
	facebook: {
		title: 'leadSource.facebook',
		field: 'facebook',
		unreleasedFeature: 'NEW_FIELDS'
	},
	twitter: {
		title: 'leadSource.twitter',
		field: 'twitter',
		unreleasedFeature: 'NEW_FIELDS'
	},
	linkedIn: {
		title: 'leadSource.linkedin',
		field: 'linkedin',
		unreleasedFeature: 'NEW_FIELDS'
	},
	journey: {
		title: 'default.journeyStep',
		field: 'journeyStep'
	},
	regDate: {
		title: 'default.created',
		field: 'regDate'
	},
	clientPlan: {
		title: 'accountPlan',
		field: 'clientPlan.type',
		unreleasedFeature: 'ACCOUNT_PLAN'
	}
};

const getAccount = appointment => {
	return new Promise(resolve => {
		Tools.Account.customer(Tools.AppService.getCustomerId())
			.get(appointment.client.id)
			.then(response => {
				return resolve(response.data);
			});
	});
};

export default getAccount;

import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import moment from 'moment';

export const makeIterator = (startDate, endDate, unit = 'month', stepSize = 1) => {
	const currentPosition = moment(startDate);

	return {
		*[Symbol.iterator]() {
			do {
				yield moment(currentPosition);
				currentPosition.add(stepSize, unit);
			} while (currentPosition.isSameOrBefore(endDate));
		}
	};
};

export const editOpportunity = opportunity => {
	const $upModal = getAngularModule('$upModal');
	const AppService = getAngularModule('AppService');
	const customerId = AppService.getCustomerId();
	$upModal.open('editOrder', { type: 'opportunity', customerId, id: opportunity.id, showPeriodizing: true });
};

export const SIDEBAR_WIDTH = 218;

'use strict';

angular.module('domain.advancedSearch').directive('opportunityInfoSearchSection', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/domain/advanced-search/directives/opportunityInfoSearchSection.tpl.html?file'),
		scope: {
			Filters: '=filters',
			onChange: '&',
			onKeyup: '&'
		},
		controller: [
			'$scope',
			'AppService',
			'$translate',
			function ($scope, AppService, $translate) {
				$scope.inited = false;
				$scope.ngChange = function () {
					if ($scope.onChange) {
						$scope.onChange();
					}
				};

				$scope.ngKeyup = function (e) {
					if ($scope.onKeyup) {
						$scope.onKeyup({ $event: e });
					}
				};

				$scope.AdvancedDateFilterChange = () => {
					const selectedDateField = $scope.Filters.AdvancedDate.value.field;
					switch (selectedDateField) {
						case 'regDate':
							$scope.Filters.Status.value = 'all';
							break;
						case 'date':
							$scope.Filters.Status.value = 'active';
							break;
						case 'closeDate':
							$scope.Filters.Status.value = 'lost';
							break;
						default:
							break;
					}
					$scope.ngChange();
				};

				$scope.advancedDateSelectConfig = {
					formatResult: function (object) {
						const descriptionTranslationKey =
							object.id === 'regDate'
								? 'createdDescription'
								: object.id === 'date'
								? 'expectedCloseDateDescription'
								: 'lostDescription';
						const description = $translate.instant(
							`advancedSearch.opportunity.date.${descriptionTranslationKey}`
						);
						return `<div>${object.text}</div><div class="advanced-search-select2-dropdown-description">${description}</div>`;
					}
				};

				var init = function () {
					$scope.inited = true;
				};

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					$scope.Filters.Description.comparisonType = 'Search';

					$scope.currencyIso = Tools.AppService.getMetadata().defaultCurrency.iso;

					var unWatchInit = $scope.$watch('Filters', function (filters) {
						if (filters !== undefined) {
							unWatchInit();
							init();
						}
					});
				});
			}
		]
	};
});

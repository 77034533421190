angular.module('domain.lead', ['ngRoute', 'upResources', 'security.authorization', 'services.customer']).config([
	'$upModalProvider',
	function ($upModalProvider) {
		$upModalProvider
			.modal('editLead', {
				templateUrl: require('App/upsales/domain/lead/views/editLeads.html?file'),
				controller: 'editLead',
				style: 'form',
				heavy: true,
				openAnimation: true,
				closeAnimation: true,
				resolve: {
					authUser: function ($routeGuard) {
						return $routeGuard.requireUser();
					},
					meta: function ($modalParams, EditLeadMeta) {
						return EditLeadMeta($modalParams);
					}
				}
			})
			.modal('LeadResetScore', {
				templateUrl: require('App/upsales/domain/lead/views/resetLeadMulti.html?file'),
				style: 'confirm',
				controller: 'ResetLeadMulti'
			});
	}
]);

import { getCurrentLanguage } from 'Components/Helpers/translate';

ReactTemplates.admin.optInModal = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			infoTitle: t('admin.optInInfoTitle'),
			singleOptIn: t('admin.singleOptIn'),
			doubleOptIn: t('admin.doubleOptIn'),
			next: t('default.next'),
			abort: t('default.abort'),
			editOptInTemplate: t('admin.editOptInTemplate'),
			save: t('default.save'),
			numberOfOptIns: t('admin.numberOfOptIns'),

			// Tabs
			settings: t('admin.settings'),
			terms: t('admin.terms'),
			confirmation: t('admin.optInConfirmation'),
			landingPage: t('admin.optInLandingPage'),

			// Settings
			requiredOptInType: t('admin.requiredOptInType'),
			optInTitle: t('admin.optInTitle'),
			formOptInText: t('admin.formOptInText'),
			formOptInTextPreview: t('admin.formOptInTextPreview'),

			//Terms
			attachTitleTerms: t('admin.attachTitleTerms'),
			attachTermsBody: t('admin.attachTermsBody'),
			readMore: t('default.readMore'),
			readMoreOptIn: t('admin.optIn.readMore'),
			optInRequiresTerms: t('admin.optInRequiresTerms'),
			optInTerms: t('admin.optInTerms'),

			// Confirmation email / Landing page
			languageSelectHeader: t('admin.languageSelectHeader'),
			headline: t('admin.headline'),
			description: t('default.description'),
			linkToAccept: t('admin.linkToAccept'),
			explanation: t('admin.explanation'),
			previewEmail: t('admin.previewEmail'),
			previewLandingPage: t('admin.previewLandingPage'),

			// Features
			featureSingle1: t('admin.optInFeatureSingle1'),
			featureSingle2: t('admin.optInFeatureSingle2'),
			featureSingle3: t('admin.optInFeatureSingle3'),
			featureSingle4: t('admin.optInFeatureSingle4'),
			featureDouble1: t('admin.optInFeatureDouble1'),
			featureDouble2: t('admin.optInFeatureDouble2'),
			featureDouble3: t('admin.optInFeatureDouble3'),
			featureDouble4: t('admin.optInFeatureDouble4')
		};

		this.numFormat = Tools.$filter('numberFormat');

		this.tabs = [this.lang.settings, this.lang.terms, this.lang.confirmation, this.lang.landingPage];
		this.tabContent = [
			this.renderSettingsTab,
			this.renderTermsTab,
			this.renderConfirmationEmailTab,
			this.renderLandingPageTab
		];

		this.reloadPositionTimeout = null;

	},

	componentDidUpdate(prevProps) {
		if (prevProps.activeTab !== this.props.activeTab) {
			clearTimeout(this.reloadModalPosition);
			this.reloadModalPosition = setTimeout(() => this.props.reloadModalPosition(), 50);
		}
	},

	componentWillUnmount() {
		clearTimeout(this.reloadModalPosition);
	},

	textChanged: function (key, e) {
		this.props.actions.onChange(key, e.target.value);
	},

	toggleChanged: function (value) {
		var self = this;
		// Wait for slider animation
		setTimeout(function () {
			self.props.actions.onChange('terms', value ? '' : null);
		}, 300);

		if (!value) {
			// Store terms until modal is closed
			self.props.actions.storeTerms(this.props.optIn.terms);
		}
	},

	setOptInTextLink: function () {
		// Add ** around link in backgroud
		var textInput = document.getElementById('opt-in-form-text');
		if (textInput.selectionEnd !== textInput.selectionStart) {
			var linkText = textInput.value.substr(
				textInput.selectionStart,
				textInput.selectionEnd - textInput.selectionStart
			);
			this.props.actions.onChange(
				'text',
				textInput.value.slice(0, textInput.selectionStart) +
					'**' +
					linkText +
					'**' +
					textInput.value.slice(textInput.selectionEnd)
			);
		}
	},

	renderInfoLangSelectors: function (forceDisabled) {
		var self = this;
		var langSelect = _.map(this.props.optInInfoLanguages, function (flag, land) {
			return (
				<Button
					land={land}
					key={'optin-info-lang-select-' + flag}
					color="bright-blue"
					onClick={self.props.actions.changeInfoLanguage.bind(null, land)}
					className={land === self.props.infoLanguage || forceDisabled ? 'disabled' : ''}
				>
					<b className={'flag-icon flag-icon-' + flag}></b>
				</Button>
			);
		});
		const uses = getCurrentLanguage();
		// var uses = Tools.$translateService.use();
		var index = _.findIndex(langSelect, function (l) {
			return l.props.land === uses;
		});
		if (index > 0) {
			var usedLang = langSelect.splice(index, 1);
			langSelect.splice(0, 0, usedLang[0]);
			return langSelect;
		} else {
			return langSelect;
		}
	},

	renderInfoItem: function (text, isBad) {
		return (
			<div className="opt-in-info-item" key={'opt-in-item-' + text}>
				<b className={'fa fa-fw ' + (isBad ? 'fa-times-circle' : 'fa-check')}></b>
				{text}
			</div>
		);
	},

	renderInfo: function () {
		return (
			<div className="opt-in-info">
				<div className="opt-in-info-left">
					<h2>{this.lang.infoTitle}</h2>
					<h3>{this.lang.singleOptIn}</h3>
					{this.renderInfoItem(this.lang.featureSingle1)}
					{this.renderInfoItem(this.lang.featureSingle2)}
					{this.renderInfoItem(this.lang.featureSingle3, true)}

					<h3>{this.lang.doubleOptIn}</h3>
					{this.renderInfoItem(this.lang.featureDouble1)}
					{this.renderInfoItem(this.lang.featureDouble2)}
					{this.renderInfoItem(this.lang.featureDouble3, true)}

					<div className="opt-in-info-read-more">
						<ReactTemplates.elevio articleId={522} text={this.lang.readMoreOptIn} />
					</div>

					<div className="opt-in-info-btns">
						<Button size="lg" block={true} color="bright-blue" onClick={this.props.actions.showForm}>
							{this.lang.next}
						</Button>
						<Button block={true} color="grey" type="link" onClick={this.props.actions.closeModal}>
							{this.lang.abort}
						</Button>
					</div>
				</div>

				<div className="opt-in-info-right">
					<img src="img/opt-in-explanation.svg" />
				</div>
			</div>
		);
	},

	renderFormTabs: function () {
		var self = this;
		var activeTab = this.props.activeTab;
		return this.tabs.map(function (name, i) {
			if (self.props.optIn.type === 'single' && i > 1) {
				// Only show email and landingpage tabs for double opt ins
				return null;
			} else {
				return (
					<div
						key={'opt-in-tab-' + name}
						className={'form-tab' + (activeTab === i ? ' form-tab--active' : '')}
						onClick={function () {
							self.props.actions.changeTab(i);
						}}
					>
						{name}
					</div>
				);
			}
		});
	},

	renderTextInput: function (value, name, required, placeholder, maxLength, textArea) {
		var inputType = textArea ? 'textarea' : 'input';

		var props = {
			id: 'opt-in-form-' + name,
			key: 'opt-in-form-' + name,
			disabled: this.props.loading,
			className: 'form-control ' + (textArea ? 'terms-textarea' : ''),
			value: value,
			type: 'text',
			placeholder: placeholder,
			onChange: this.textChanged.bind(null, name),
			maxLength: maxLength,
			required: required
		};

		return React.createElement(inputType, props);
	},

	renderSettingsTab: function () {
		return (
			<div className="content-max-w">
				<label>
					{this.lang.requiredOptInType} <ReactTemplates.elevio articleId={523} />
				</label>
				<div className={'opt-in-type-wrap' + (this.props.optIn.id ? '' : ' opt-in-type-clickable')}>
					<div
						className={'opt-in-type' + (this.props.optIn.type === 'single' ? ' opt-in-type--selected' : '')}
						onClick={this.props.actions.setType.bind(null, 'single')}
					>
						<div className="opt-in-type-icon">
							<b className="fa fa-wpforms"></b>
							<b className="fa fa-chevron-right"></b>
							<b className="fa fa-envelope-o"></b>
						</div>
						<h4>{this.lang.singleOptIn}</h4>
					</div>
					<div
						className={'opt-in-type' + (this.props.optIn.type === 'double' ? ' opt-in-type--selected' : '')}
						onClick={this.props.actions.setType.bind(null, 'double')}
					>
						<div className="opt-in-type-icon">
							<b className="fa fa-wpforms"></b>
							<b className="fa fa-chevron-right"></b>
							<b className="fa fa-envelope-o"></b>
							<b className="fa fa-chevron-right"></b>
							<b className="fa fa-laptop"></b>
						</div>
						<h4>{this.lang.doubleOptIn}</h4>
					</div>
				</div>
				<div className="optin-info-wrap">
					<label>{this.lang.optInTitle}</label>
					<b
						style={{ color: 'red', fontSize: '16px' }}
						dangerouslySetInnerHTML={{ __html: '&nbsp;&bull;' }}
					></b>
					{this.renderTextInput(this.props.optIn.title, 'title', true, '', 45)}
					<label>
						{this.lang.formOptInText} <ReactTemplates.elevio articleId={524} />
					</label>
					<b
						style={{ color: 'red', fontSize: '16px' }}
						dangerouslySetInnerHTML={{ __html: '&nbsp;&bull;' }}
					></b>
					{this.renderTextInput(this.props.optIn.text, 'text', false, '', 512)}
				</div>
			</div>
		);
	},

	renderTermsTab: function () {
		var termsToggle = (
			<div className="require-terms-switch-holder">
				<label>{this.lang.optInRequiresTerms}</label>
				<Toggle
					value={this.props.optIn.terms !== null}
					onChange={this.toggleChanged}
					className="toggle-bright-blue toggle-lg"
				/>
			</div>
		);

		var content;
		if (this.props.optIn.approvedCount > 0) {
			content = (
				<div dangerouslySetInnerHTML={{ __html: this.props.optIn.terms }} style={{ minHeight: '274px' }}></div>
			);
		} else if (this.props.optIn.terms !== null) {
			content = (
				<div className="opt-in-terms">
					{termsToggle}
					<div>
						<label>
							{this.lang.optInTerms} <ReactTemplates.elevio articleId={525} />
						</label>
					</div>
					<window.CKEditorWrapper
						onChange={this.textChanged.bind(null, 'terms')}
						value={this.props.optIn.terms || this.props.terms}
						toolbar={[{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Link', 'Unlink'] }]}
					/>
				</div>
			);
		} else {
			content = (
				<div>
					<div className="opt-in-require-terms">
						<img src="img/opt-in-terms.svg" />
						<h2>{this.lang.attachTitleTerms}</h2>
						<div className="opt-in-terms-body">
							<h4>{this.lang.attachTermsBody}</h4>
							<ReactTemplates.elevio articleId={525} text={this.lang.readMore} />
						</div>
						{termsToggle}
					</div>
					<div className="terms-slice-container">
						<div className="terms-slice"></div>
					</div>
				</div>
			);
		}

		return <div className="opt-in-terms-container">{content}</div>;
	},

	renderConfirmationEmailTab: function () {
		var optIn = this.props.optIn;
		return (
			<div className="content-max-w" key="preivew-email">
				<label>{this.lang.languageSelectHeader}</label>
				<div className="optin-respons-language-picker">
					{this.renderInfoLangSelectors(
						!_.some(
							[optIn.emailTitle, optIn.emailDescription, optIn.emailLinkText, optIn.emailExplanation],
							function (a) {
								return _.size(a) === 0;
							}
						)
					)}
				</div>
				<label>{this.lang.headline}</label>
				{this.renderTextInput(
					optIn.emailTitle,
					'emailTitle',
					false,
					this.props.predefinedText.email.headline,
					45
				)}
				<label>{this.lang.description}</label>
				{this.renderTextInput(
					optIn.emailDescription,
					'emailDescription',
					false,
					this.props.predefinedText.email.description,
					256,
					true
				)}
				<label>{this.lang.linkToAccept}</label>
				{this.renderTextInput(
					optIn.emailLinkText,
					'emailLinkText',
					false,
					this.props.predefinedText.email.acceptLink,
					45
				)}
				<label>{this.lang.explanation}</label>
				{this.renderTextInput(
					optIn.emailExplanation,
					'emailExplanation',
					false,
					this.props.predefinedText.email.explanation,
					256,
					true
				)}
				<Button type={'lined'} color="bright-blue" onClick={this.props.actions.previewEmail}>
					{this.lang.previewEmail}
				</Button>
			</div>
		);
	},

	renderLandingPageTab: function () {
		var optIn = this.props.optIn;
		return (
			<div className="content-max-w" key="preview-landingpage">
				<label>{this.lang.languageSelectHeader}</label>
				<div className="optin-respons-language-picker">
					{this.renderInfoLangSelectors(
						!_.some([optIn.landingpageTitle, optIn.landingpageDescription], function (a) {
							return _.size(a) === 0;
						})
					)}
				</div>
				<label>{this.lang.headline}</label>
				{this.renderTextInput(
					optIn.landingpageTitle,
					'landingpageTitle',
					false,
					this.props.predefinedText.landingpage.headline,
					45
				)}
				<label>{this.lang.description}</label>
				{this.renderTextInput(
					optIn.landingpageDescription,
					'landingpageDescription',
					false,
					this.props.predefinedText.landingpage.description,
					256,
					true
				)}
				<Button type={'lined'} color="bright-blue" onClick={this.props.actions.previewLandingPage}>
					{this.lang.previewLandingPage}
				</Button>
			</div>
		);
	},

	renderForm: function () {
		return (
			<div className="opt-in-form">
				<div className="up-modal-header white-header">
					<h2>
						<b className="fa fa-pencil-square-o"></b>
						{this.lang.editOptInTemplate}
					</h2>
					<div className="opt-in-form-tabs">{this.renderFormTabs()}</div>
					<b className="fa fa-times" onClick={this.props.actions.closeModal}></b>
				</div>
				<div className="up-modal-content">{this.tabContent[this.props.activeTab]()}</div>
				<div className="up-modal-controls white">
					{this.props.optIn.approvedCount !== undefined && (
						<div className="opt-in-stats">
							<h4 className="opt-in-stats-heading">{this.lang.numberOfOptIns}</h4>
							<h4 className="opt-in-stats-approved-by">
								{this.numFormat(this.props.optIn.approvedCount)}
							</h4>
						</div>
					)}
					<div className="opt-in-info-btns">
						<Button
							disabled={
								this.props.loading ||
								this.props.optIn.title.length === 0 ||
								this.props.optIn.text.length === 0
							}
							color="bright-blue"
							onClick={this.props.actions.save}
							className="main-action"
							shadow={'none'}
						>
							{this.lang.save}
						</Button>
						<Button
							disabled={this.props.loading}
							color="grey"
							type="link"
							onClick={this.props.actions.closeModal}
						>
							{this.lang.abort}
						</Button>
					</div>
				</div>
			</div>
		);
	},

	render: function () {
		if (this.props.state === 'info') {
			return this.renderInfo();
		} else {
			return this.renderForm();
		}
	}
});

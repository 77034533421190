'use strict';

angular.module('domain.contact').factory('EditContactMeta', [
	'$q',
	'Contact',
	'Account',
	'NotificationService',
	'AppService',
	function ($q, Contact, Account, NotificationService, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var promises = {};

				if ($modalParams.id) {
					promises.contact = Contact.customer(customerId).get($modalParams.id);
				} else if ($modalParams.contact) {
					promises.contact = $q.when({ data: $modalParams.contact });
				} else {
					promises.contact = $q.when({ data: Contact.new() });

					if ($modalParams.clientId) {
						promises.client = Account.customer(customerId).get($modalParams.clientId);
					}
				}

				return $q
					.all(promises)
					.then(function (response) {
						return response;
					})
					.catch(function (err) {
						NotificationService.addNotification({
							title: 'default.error',
							body: 'openError.contact',
							style: 'error',
							icon: 'times'
						});
						return $q.reject(err);
					});
			});
		};
	}
]);

'use strict';

angular.module('upResources').factory('SuggestEmail', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ApiKey($resource, $q, URL, API) {
		var Resource = $resource(
			URL + API + 'function/email-suggest/:clientId?firstname=:firstname&lastname=:lastname',
			{ clientId: '@id' },
			{
				get: { method: 'GET', isArray: false }
			}
		);

		return {
			get: function (clientId, firstname, lastname) {
				return Resource.get({ clientId: clientId, firstname: firstname, lastname: lastname }).$promise;
			}
		};
	}
]);

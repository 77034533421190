'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('ActivityType', [
	'$resource',
	'$q',
	'ActivityTypeAttributes',
	'URL',
	'API',
	'ResourceHelper',
	function ActivityType($resource, $q, ActivityTypeAttributes, URL, API, ResourceHelper) {
		var Attribute = ActivityTypeAttributes().getAll();

		var methods = {
			query: {
				method: 'GET',
				isArray: false
			},
			put: {
				method: 'PUT',
				isArray: false
			},
			post: {
				method: 'POST',
				isArray: false
			},
			merge: {
				method: 'PUT',
				isArray: false,
				url: URL + API + 'activitytypes/activity/merge/:keep/with/:merge'
			}
		};

		var Resource = $resource(URL + API + 'activityTypes/activity/:id', {}, methods);

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'activityType'
		});
		var Model = {
			find: function (request) {
				return Resource.get(request).$promise.then(
					function (response) {
						return response;
					},
					function (response) {
						return response.status;
					}
				);
			},

			// Model == ActivityType

			save: function (activity, options) {
				var promise;
				if (activity.id) {
					var params = { id: activity.id };
					promise = Resource.put(params, activity).$promise;
				} else {
					promise = Resource.post(activity).$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !activity.id);
						AllIWantDataCache.clearData();
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !activity.id);
					});

				return promise;
			},

			merge: function (keepId, mergeId, options) {
				var params = {
					keep: keepId,
					merge: mergeId
				};
				var promise = Resource.merge(params, {}).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res, false);
						helper.onDelete(options, { id: mergeId }, false);
						AllIWantDataCache.clearData();
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, false, err);
					});

				return promise;
			},

			attr: Attribute
		};

		return Model;
	}
]);

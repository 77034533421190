'use strict';

angular.module('domain.activity').factory('CreateActivityMultiMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function () {
			var promises = {
				activeUsers: $q.when(AppService.getActiveUsers()),
				activeUserId: $q.when(AppService.getSelf().id),
				activityTypes: $q.when(AppService.getActivityTypes('activity', true)),
				activityCustomFields: $q.when(AppService.getCustomFields('activity')),
				roles: $q.when(AppService.getRoles())
			};

			return $q.all(promises);
		};
	}
]);

'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.contact').controller('ContactOpportunities', [
	'$scope',
	'RequestBuilder',
	'FilterHelper',
	'$upModal',
	'$translate',
	'$stateParams',
	'$location',
	'FixOrder',
	'SaveInline',
	'Opportunity',
	'Order',
	'AppService',
	'$filter',
	'$state',
	function (
		$scope,
		RequestBuilder,
		FilterHelper,
		$upModal,
		$translate,
		$stateParams,
		$location,
		FixOrder,
		SaveInline,
		Opportunity,
		Order,
		AppService,
		$filter,
		$state
	) {
		var opportunities;
		var ContactCtrl = $scope.ContactCtrl;
		var $date = $filter('date');

		var updated = function (e, updated) {
			const opportunity = _.find(opportunities, { id: updated.id });
			if (updated.contact?.id === ContactCtrl.contact.id) {
				if (opportunity) {
					if ($scope.filter && FilterHelper.match($scope.filter.q, updated, 'opportunity')) {
						angular.extend(opportunity, updated);
					} else {
						_.remove(opportunities, opportunity);
					}
					$scope.groupChange();
				}
			} else if (opportunity) {
				_.remove(opportunities, opportunity);
				$scope.groupChange();
			}
		};

		var added = function (e, added) {
			if (added.contact?.id === ContactCtrl.contact.id) {
				opportunities.push(added);
				$scope.groupChange();
			}
		};

		var deleted = function (e, deleted) {
			var index = _.findIndex(opportunities, { id: deleted.id });

			if (index !== -1) {
				opportunities.splice(index, 1);

				// If no more activities - go to dashboard
				if (!opportunities.length) {
					$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
				} else {
					$scope.groupChange();
				}
			}
		};

		$scope.$on('opportunity.deleted', deleted);
		$scope.$on('order.deleted', deleted);

		$scope.$on('opportunity.added', added);
		$scope.$on('order.added', added);

		$scope.$on('opportunity.updated', updated);
		$scope.$on('order.updated', updated);

		$scope.groupable2 = {
			data: [
				{
					id: 1,
					text: $translate.instant('date.month'),
					groupOn: function (obj) {
						return $date(obj.date, 'MMMM yyyy');
					}
				},
				{
					id: 2,
					text: $translate.instant('date.year'),
					groupOn: function (obj) {
						return $date(obj.date, 'yyyy');
					}
				},
				{ id: 3, text: $translate.instant('default.user'), groupOn: 'user.id', textOn: 'user.name' },
				{ id: 4, text: $translate.instant('default.stage'), groupOn: 'stage.id', textOn: 'stage.name' },
				{
					id: 5,
					hash: 'activeLost',
					text: $translate.instant('stage.activeLost'),
					groupOn: function (obj) {
						var found = _.find(ContactCtrl.orderStages, { id: obj.stage.id });
						return found && found.probability > 0
							? $translate.instant('stage.active')
							: $translate.instant('stage.lost');
					}
				}
			],
			allowClear: true,
			placeholder: $translate.instant('default.groupBy')
		};

		// opportunity inline save
		$scope.saveInlineOpportunity = function (value, promise, field, opportunityId) {
			SaveInline(value, promise, field, opportunityId, 'Opportunity');
		};

		// open opportunity
		$scope.openOpportunity = function (opportunity) {
			$location.path($scope.customerId + '/opportunities/' + opportunity.id);
		};

		$scope.groupChange = function () {
			$scope.opportunities = $filter('groupBy')(opportunities, $scope.grouping);

			if (
				$scope.grouping?.hash === 'activeLost' &&
				$scope.opportunities &&
				$scope.opportunities[0] &&
				$scope.opportunities[0].items.length
			) {
				var stage = _.find(ContactCtrl.orderStages, { id: $scope.opportunities[0].items[0].stage.id });
				if (stage && stage.probability === 0) {
					$scope.opportunities.reverse();
				}
			}
		};

		$scope.createOpportunity = function () {
			var options = {
				customerId: $stateParams.customerId,
				clientId: ContactCtrl.contact.client.id,
				contactId: ContactCtrl.contact.id,
				type: 'opportunity'
			};
			$upModal.open('editOrder', options);
		};

		$scope.removeOpportunity = function (opportunity, e) {
			if (e) {
				e.stopPropagation();
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'default.opportunity',
					body: 'confirm.removeOpportunity',
					onClose: confirmed => {
						if (confirmed) {
							Order.customer($scope.customerId)['delete'](opportunity);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') +
						' ' +
						$translate.instant('default.opportunity').toLowerCase(),
					body: 'confirm.removeOpportunity',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Order.customer($scope.customerId)['delete'](opportunity);
				});
		};

		$scope.getOpportunities = function () {
			$scope.tableLoading = true;
			Opportunity.customer($scope.customerId)
				.find($scope.filter)
				.then(
					function (response) {
						$scope.nrOfOpportunities = response.metadata.total;
						opportunities = response.data;

						// sum order rows
						angular.forEach(opportunities, function (opportunity) {
							opportunity = FixOrder.print(opportunity);
						});
						$scope.groupChange();
						$scope.tableLoading = false;
					},
					function () {
						$scope.tableLoading = false;
						//ContactCtrl.opportunitiesTotal = 0;
					}
				)
				.then(updateList);
		};

		var updateList = function () {
			if ($stateParams.opportunity && $stateParams.opportunity.length > 0) {
				var opportunity = JSON.parse($stateParams.opportunity);
				var value = 0;
				angular.forEach(opportunity.orderRow, function (orderRow) {
					value += parseInt(orderRow.price) * parseInt(orderRow.quantity);
				});
				opportunity.value = value;

				var existingOpportunity = _.find(opportunities, { id: opportunity.id });
				if (existingOpportunity) {
					angular.extend(existingOpportunity, opportunity);
					$scope.groupChange();
				} else {
					opportunities.push(opportunity);
					//ContactCtrl.opportunitiesTotal++;
					$scope.groupChange();
				}
			}
		};

		var getGroupingFromHash = function () {
			var hash = $location.search();
			var groupBy = hash.groupBy ? hash.groupBy : 'activeLost';
			return _.find($scope.groupable2.data, { hash: groupBy });
		};

		var init = function () {
			$scope.grouping = getGroupingFromHash();

			var rb = new RequestBuilder();
			rb.addFilter(Opportunity.attr.probability, rb.comparisonTypes.NotEquals, 100);
			rb.addFilter(Opportunity.attr.contact.attr.id, rb.comparisonTypes.Equals, ContactCtrl.contact.id);
			rb.addSort(Opportunity.attr.date, false);
			rb.limit = 50;
			rb.offset = 0;
			$scope.filter = rb.build();

			$scope.getOpportunities();
		};

		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';

const MailTemplatePreviewPadding = ({ column }) => {
	const style = {
		borderTopWidth: column.vPadding + 'px',
		borderRightWidth: column.hPadding + 'px',
		borderBottomWidth: column.vPadding + 'px',
		borderLeftWidth: column.hPadding + 'px'
	};
	return <div className="MailTemplatePreviewPadding" style={style} />;
};
export default MailTemplatePreviewPadding;

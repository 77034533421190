'use strict';

angular.module('upDirectives').directive('upEventAppointment', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventAppointment.tpl.html?file'),
		require: 'upEventAppointment',
		scope: {
			event: '=upEventAppointment',
			noIcon: '@'
		},
		controller: [
			'$scope',
			'$stateParams',
			'$upModal',
			'Appointment',
			'$safeDigest',
			'$timeout',
			function ($scope, $stateParams, $upModal, Appointment, $safeDigest, $timeout) {
				$scope.customerId = Tools.AppService.getCustomerId();

				$scope.openAppointment = function () {
					var options = {
						id: $scope.event.entityId
					};

					$upModal.open('editAppointment', options);
				};

				$scope.editOpportunity = function () {
					var options = {
						customerId: $stateParams.customerId,
						type: 'opportunity',
						id: $scope.event.$$eventDetails.opportunity.id
					};

					$upModal.open('editOrder', options);
				};

				$scope.expand = function () {
					if ($scope.event.$$expand) {
						$scope.event.$$expand = false;
						return $timeout(function () {
							$safeDigest($scope);
						}, 400);
					}
					$scope.event.$$loading = true;

					Appointment.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}

							$scope.event.$$eventDetails = res.data;
							$scope.event.$$expand = true;
							$scope.event.$$loading = false;
						})
						.catch(err => console.error(err));
				};
			}
		]
	};
});

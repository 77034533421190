import isIntegrationUrl from 'Resources/helpers/isIntegrationUrl';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';
import logError from 'App/babel/helpers/logError';
import InAppChat from 'App/babel/services/InAppChat';

var generateShowLogin = function ($location, $cookies, $safeApply, $rootScope) {
	return function (triedToAccess) {
		return new Promise((resolve, reject) => {
			if (
				triedToAccess &&
				triedToAccess.indexOf('/login') === -1 &&
				triedToAccess.indexOf('/activate-app') === -1 &&
				triedToAccess.toLowerCase().indexOf('/manageaccount') === -1
			) {
				$cookies.loginReturnUrl = triedToAccess;
			}
			// Do NOT add $location.search({}) here, you think you make stuff nicer but you will actually
			// make the reset password page break
			const off = $rootScope.$on('$stateChangeSuccess', (event, toState) => {
				off();
				if (toState.name === 'login' || toState.name === 'react-root-login') {
					return resolve();
				} else {
					return reject();
				}
			});

			$location.path('/login');
			$safeApply($rootScope);
		});
	};
};

function clearMixpanelCookies() {
	const accountSelf = Tools.AppService.getAccountSelf();
	if (!accountSelf) {
		return;
	}
	const userId = accountSelf.id;
	const cookies = document.cookie.split(';');
	for (const cookie of cookies) {
		const c = cookie.trim().split('=');
		const key = c[0];
		const value = c[1];
		if (key.includes('mixpanel') && value.includes('user_id')) {
			try {
				const obj = JSON.parse(decodeURIComponent(value));
				if (parseInt(obj.$user_id) !== userId) {
					document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
				} else if (obj.features) {
					const updatedCookie = encodeURIComponent(JSON.stringify({ ...obj, features: undefined }));
					document.cookie = `${key}=${updatedCookie}`;
				}
			} catch (e) {
				logError(e, 'Failed to parse mixpanel cookie');
			}
		}
	}
}

angular
	.module('security.service', ['upResources', 'ui.router', 'ngCookies'])
	.config([
		'$httpProvider',
		'$stateProvider',
		function ($httpProvider, $stateProvider) {
			// NEW_LOGIN Show angular login for all envs but dev/alpha
			if (window.useReactInit) {
				$stateProvider.state('react-root-login', { url: '/login', template: '<div></div>' });
			} else {
				$stateProvider.state('login', {
					url: '/login',
					templateUrl: require('App/upsales/common/security/views/login.html?file'),
					controller: 'LoginCtrl'
				});
			}

			var currentTime = new Date().getTime();

			$httpProvider.interceptors.push([
				'$q',
				'$location',
				'$cacheFactory',
				'$cookies',
				'API',
				'URL',
				'AppService',
				'$safeApply',
				'$rootScope',
				function ($q, $location, $cacheFactory, $cookies, API, URL, AppService, $safeApply, $rootScope) {
					var showLogin = generateShowLogin($location, $cookies, $safeApply, $rootScope);
					return {
						response: function (res) {
							// If this is an api-call and no logout
							var url = res.config.url;
							var refreshTimer =
								url.indexOf(URL + API) !== -1 &&
								url.indexOf(API + 'session') === -1 &&
								url.indexOf(API + 'lang/') === -1;

							if (refreshTimer) {
								// Reset timer for session in AppService
								AppService.updateSession();
							}
							return res;
						},
						responseError: function (response) {
							/* Check if user is authenticated and request is not to session */
							const got401 = response.status === 401;
							const isSessionURL =
								typeof response.config?.url === 'string' &&
								response.config.url.indexOf(API + 'session') === 1;
							const isMethodDelete = response.config?.method === 'DELETE';

							var requiresAuth =
								got401 &&
								!isIntegrationUrl(response.config?.url) &&
								(!isSessionURL || (isSessionURL && isMethodDelete));

							/* An admin has changed to that there no longer is any valid brands for the user */
							if (_.get(response, 'data.error.key') === 'NoValidBrand') {
								requiresAuth = true;
							}

							if (
								response.status === 401 &&
								typeof response.config?.url === 'string' &&
								response.config.url.indexOf('/professional/servlet/ReportServlet') !== -1
							) {
								requiresAuth = false;
							}

							if (requiresAuth) {
								$cacheFactory.get('$http').removeAll();
								showLogin(window.location.href);
							}

							var noIndex =
								response.status === 403 &&
								response.data &&
								response.data.error &&
								response.data.error.key === 'MissingIndex';

							// If customer is missing its index
							if (noIndex) {
								$cacheFactory.get('$http').removeAll();
								$cookies.failed = 'missingIndex';
								showLogin();
							}

							return $q.reject(response);
						},
						request: function (config) {
							var isHtml =
								config &&
								_.endsWith(config.url, '.html') &&
								config.url.indexOf('SALESBOARD_OPP_TEMPLATE') === -1;
							if (isHtml) {
								config.url = config.url + '?ver=' + currentTime;
							}

							return config;
						}
					};
				}
			]);
		}
	])
	.factory('security', [
		'$rootScope',
		'Self',
		'$location',
		'Session',
		'CacheService',
		'AppService',
		'PushNotifications',
		'$cookies',
		'$safeApply',
		function (
			$rootScope,
			Self,
			$location,
			Session,
			CacheService,
			AppService,
			PushNotifications,
			$cookies,
			$safeApply
		) {
			var customerUser = null;

			var service = {
				getUser: Self.get,
				getCustomerUser: Self.getCustomerSelf,
				isAdmin: function () {
					return customerUser !== null && customerUser.administrator !== null;
				},
				showLogin: function () {
					return generateShowLogin($location, $cookies, $safeApply, $rootScope)();
				},
				loggedIn: function () {
					$rootScope.$broadcast('upsales.login');
				},
				logout: async function () {
					clearMixpanelCookies();

					if (window.location.href.indexOf('/login') === -1) {
						// return to login
						try {
							await service.showLogin();
						} catch {
							// It rejects if we show a modal asking the user to save before leaving
							return;
						}
					}
					// eslint-disable-next-line promise/catch-or-return
					Session.logout().finally(function () {
						$rootScope.$broadcast('upsales.logout');

						if (window.zE) {
							window.zE('messenger', 'logoutUser');
						}

						PushNotifications.destroy();
						window.store.SystemNotification.removeAllNotifications();

						InAppChat.shutdown();
						// Clear cache
						CacheService.clear();
						AllIWantDataCache.clearData();

						AppService.reset();
					});
				}
			};

			return service;
		}
	]);

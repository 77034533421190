import React from 'react';
import PropTypes from 'prop-types';
import Button from '@upsales/components/Button';

class NotesBlock extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			focus: false,
			textLength: 0
		};
	}

	componentDidUpdate() {
		if (this.state.focus) {
			this.textarea.selectionStart = this.textarea.selectionEnd = this.state.textLength - 2;
			this.textarea.focus();
			this.setState({ focus: false });
		}

		this.textarea.style.height = '1px';
		this.textarea.style.height = this.textarea.scrollHeight + 'px';
	}

	addSignature = () => {
		const inputName = `${Tools.AppService.getSelf().name} ${moment().format('L LT')}\n\n\n`;
		const inputText = this.props.notesText || '';

		this.props.onChange(inputName + inputText);
		this.setState({ textLength: inputName.length, focus: true });
	};

	render() {
		const editable = this.props.userEditable;

		return (
			<div id="NotesBlock" className={this.props.hasError ? 'NotesBlock--error' : null}>
				<Button type="link" color="bright-blue" disabled={!editable} onClick={this.addSignature}>
					<i className="fa fa-pencil" />
					{Tools.$translate('default.insertSignature')}
				</Button>
				<textarea
					ref={inp => (this.textarea = inp)}
					onChange={event => this.props.onChange(event.target.value)}
					className="flat-input"
					id="notes-area"
					title={this.props.tooltipText}
					value={this.props.notesText || ''}
					required={this.props.required}
					disabled={!editable}
				/>
				{this.props.required ? <b className="required-field-notes text-danger">{'*'}</b> : null}
			</div>
		);
	}
}

NotesBlock.propTypes = {
	notesText: PropTypes.string,
	tooltipText: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	userEditable: PropTypes.bool
};

export default NotesBlock;

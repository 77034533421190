'use strict';

angular.module('domain.admin').controller('EditAds', [
	'$scope',
	'$upModal',
	'meta',
	'$q',
	'$state',
	'$stateParams',
	'$translate',
	'Ads',
	function ($scope, $upModal, meta, $q, $state, $stateParams, $translate, Ads) {
		var EditAds = this;
		var customerId = $stateParams.customerId;

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.accountCreated, function (e, created) {
			EditAds.account = created;
		});

		EditAds.account = meta.account.data;
		EditAds.credit = meta.credit.data || 0;
		EditAds.transactions = meta.creditTransactions.data || [];
		EditAds.createAccount = function () {
			EditAds.activating = true;
			return Ads.customer(customerId)
				.saveAccount({})
				.then(function (res) {
					EditAds.activating = false;
				})
				.catch(function (err) {
					EditAds.activating = false;
					EditAds.activatingErr = err;
				});
		};
	}
]);

import T from 'Components/Helpers/translate';

export const getCountUnitPlural = unit =>
	({
		activeUsers: T('admin.billing.counter.activeUsers'),
		uniqueContacts: T('admin.billing.counter.uniqueContacts'),
		activeTriggersAndAutomations: T('admin.billing.counter.activeTriggersAndAutomations'),
		activeSubscriptions: T('admin.billing.counter.activeSubscriptions'),
		launchedEventsYear: T('admin.billing.counter.launchedEventsPerYear'),
		launchedEventsMonth: T('admin.billing.counter.launchedEventsPerMonth'),
		ordersAndOpportunitiesYear: T('admin.billing.counter.ordersAndOpportunitiesPerYear'),
		ordersAndOpportunitiesMonth: T('admin.billing.counter.ordersAndOpportunitiesPerMonth'),
		uniqueContactsGotMarketEventYear: T('admin.billing.counter.uniqueContactsGotMarketEventPerYear'),
		uniqueContactsGotMarketEventMonth: T('admin.billing.counter.uniqueContactsGotMarketEventPerMonth'),
		uniqueContactsGotMailYear: T('admin.billing.counter.uniqueContactsGotMailPerYear'),
		uniqueContactsGotMailMonth: T('admin.billing.counter.uniqueContactsGotMailPerMonth'),
		uniqueContactsThatGotMarketEventForBrandMonth: T(
			'admin.billing.counter.uniqueContactsThatGotMarketEventForBrandMonth'
		),
		customDomains: T('admin.billing.counter.customDomains'),
		apiRequestsLastDay: T('admin.billing.counter.apiRequestsLastDay'),
		uniqueUsersInSharedCalendars: T('admin.billing.counter.uniqueUsersInSharedCalendars')
	}[unit] || '');

const getCountUnit = unit =>
	({
		activeUsers: T('default.user'),
		uniqueContacts: T('default.contact'),
		activeTriggersAndAutomations: T('admin.billing.counter.activeTriggersAndAutomations'),
		activeSubscriptions: T('default.recurringOrder'),
		launchedEventsYear: T('default.socialEvent'),
		launchedEventsMonth: T('default.socialEvent'),
		ordersAndOpportunitiesYear: T('default.opportunity'),
		ordersAndOpportunitiesMonth: T('default.opportunity'),
		uniqueContactsGotMarketEventYear: T('default.contact'),
		uniqueContactsGotMarketEventMonth: T('default.contact'),
		uniqueContactsGotMailYear: T('default.contact'),
		uniqueContactsGotMailMonth: T('default.contact'),
		uniqueContactsThatGotMarketEventForBrandMonth: T('default.contact'),
		customDomains: T('mail.domain'),
		apiRequestsLastDay: T('admin.billing.counter.apiRequests'),
		uniqueUsersInSharedCalendars: T('default.user')
	}[unit] || '');

const numberFormat = num => Tools.$filter('numberFormat')(num);

export const getTierString = addon => {
	if (!addon.tier) {
		return '';
	}
	const unit = getCountUnitPlural(addon.basedOn).toLowerCase();
	if (addon.tier.end) {
		return `${T('admin.billing.addon.upTo')} ${numberFormat(addon.tier.end)} ${unit} `;
	}
	return `${T('admin.billing.addon.moreThan')} ${numberFormat(addon.tier.start)} ${unit} `;
};

export const getAddonPeriod = () => {
	return T('date.month').toLowerCase();
};

export const getPerXText = (addon = {}) => {
	let period;
	let perUnit = '';
	period = T('date.month').toLowerCase();
	if (addon.basedOn && (!addon.tier || !addon.tier.isTotalPrice)) {
		perUnit = `${getCountUnit(addon.basedOn).toLowerCase()} / `;
	}
	return `${perUnit}${period}`;
};

'use strict';

angular.module('upDirectives').directive('upwLatestAccounts', function () {
	return {
		restrict: 'A',
		controllerAs: 'Latest',
		controller: [
			'$scope',
			'$rootScope',
			'$state',
			'localStorageService',
			'AppService',
			'LatestAccountsService',
			function ($scope, $rootScope, $state, localStorageService, AppService, LatestAccountsService) {
				var Latest = this;

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var self = AppService.getSelf();
					Latest.customerId = AppService.getCustomerId();
					Latest.accounts = LatestAccountsService.get(Latest.customerId, self.id);

					$scope.$on('LatestAccounts.change', function (e, data) {
						if (data.key === LatestAccountsService.getKey(Latest.customerId, self.id)) {
							Latest.accounts = LatestAccountsService.get(Latest.customerId, self.id);
						}
					});
				});
			}
		]
	};
});

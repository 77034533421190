import Attribute, { Type } from './Attribute';

const ClientPlanAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'clientId',
		type: Type.Number
	}),
	description: Attribute({
		title: 'default.description',
		type: Type.String,
		displayKey: ['id', 'description'],
		field: 'description'
	}),
	type: Attribute({
		title: 'default.type',
		type: Type.String,
		field: 'type'
	}),
	value: Attribute({
		title: 'default.value',
		type: Type.Number,
		field: 'type'
	}),
	date: Attribute({
		title: 'default.date',
		type: Type.Date,
		field: 'date'
	}),
	clientNeeds: Attribute({
		type: Type.String,
		field: 'clientNeeds'
	}),
	meetingNeeds: Attribute({
		type: Type.String,
		field: 'meetingNeeds'
	}),
	buyNowReason: Attribute({
		type: Type.String,
		field: 'buyNowReason'
	}),
	regDate: Attribute({
		type: Type.Date,
		field: 'regDate'
	}),
	modDate: Attribute({
		type: Type.Date,
		field: 'modDate'
	}),
	modBy: Attribute({
		type: Type.Date,
		field: 'modDate'
	}),
	status: Attribute({
		title: 'default.status',
		type: Type.String,
		field: 'status'
	})
};

export default ClientPlanAttributes;

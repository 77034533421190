import AddOption from 'App/components/SubscriptionAdvanceSettings/AddOption';
import BemClass from '@upsales/components/Utils/bemClass';
import { renderToString } from 'react-dom/server';
import T from 'Components/Helpers/translate';
import React from 'react';

import {
	Card,
	Help,
	Icon,
	Block,
	Table,
	Button,
	Toggle,
	Tooltip,
	TableRow,
	RadioItem,
	CardHeader,
	CardContent,
	TableColumn,
	TableHeader
} from '@upsales/components';

import './SubscriptionAdvanceSettings.scss';

type Props = {
	onDefaultChange: (option: number) => void;
	onEnableChange?: (enabled: boolean) => void;
	onChange: (options: number[]) => void;
	showEnabledToggle?: boolean;
	defaultOption: number;
	articleId?: number;
	maxPreset?: number;
	max?: number;
	options: number[];
	enabled: boolean;
	param: string;
	langMap: {
		title: string;
		subtitle: string;
		optionZero: string;
		optionOne: (interval: number) => string;
		optionPlural: (interval: number) => string;
		addOption: {
			placeholder: string;
			textAfterInput: string;
			textBeforeInput: string;
			textAfterInputPlural: string;
		};
	};
};

const SubscriptionAdvanceSettings = ({
	showEnabledToggle,
	onDefaultChange,
	enabled = true,
	onEnableChange,
	defaultOption,
	articleId,
	maxPreset,
	onChange,
	langMap,
	options,
	max
}: Props) => {
	const classes = new BemClass('SubscriptionAdvanceSettings');

	const removeOption = async (optionToRemove: number) => {
		const updatedOptions = options.filter(option => {
			return option !== optionToRemove;
		});

		onChange(updatedOptions);
	};

	const renderSubtitle = () => (
		<div className={classes.elem('createOrdersInAdvanceDescriptionContainer').b()}>
			<span>{langMap.subtitle}</span>
			{articleId ? (
				<Block space="mls">
					<Help
						sidebar={true}
						articleId={articleId}
						className={classes.elem('createOrdersInAdvanceHelp').b()}
					/>
				</Block>
			) : null}
		</div>
	);

	const renderCardHeader = () => {
		const space = enabled ? 'mbm' : 'mbm pbl';
		return (
			<Block space={space}>
				<CardHeader
					title={langMap.title}
					subtitle={renderSubtitle()}
					className={classes.elem('createOrdersInAdvance').b()}
				>
					{showEnabledToggle ? (
						<Block space="mrxl">
							<Toggle
								color="medium-green"
								checked={enabled}
								onChange={onEnableChange}
								className={classes.elem('createOrdersInAdvanceToggle').b()}
							/>
						</Block>
					) : null}
				</CardHeader>
			</Block>
		);
	};

	const renderRemoveOptionTooltipText = () => {
		const element = (
			<div className={classes.elem('removeOptionTooltip').b()}>
				<div>{T('agreement.removeCreateOrdersInAdvanceOptionTooltipFirstLine')}</div>
				<div>{T('agreement.removeCreateOrdersInAdvanceOptionTooltipSecondLine')}</div>
			</div>
		);
		return renderToString(element);
	};

	const renderOption = (option: number) => {
		const optionText =
			option === 0 ? langMap.optionZero : option === 1 ? langMap.optionOne(option) : langMap.optionPlural(option);

		const isDefault = option === defaultOption;
		const showRemoveButton = option !== 0 && !isDefault;
		const hideSetPreset = maxPreset && option > maxPreset;
		return (
			<TableRow className={classes.elem('option').b()} key={`createOrderInAdvanceOption-${option}`}>
				<TableColumn className={classes.elem('optionText').b()}>{optionText}</TableColumn>
				<TableColumn>
					{hideSetPreset ? null : (
						//@ts-ignore I will fix this later
						<RadioItem size="sm" checked={isDefault} onChange={() => onDefaultChange(option)} />
					)}
				</TableColumn>
				<TableColumn>
					{showRemoveButton ? (
						<Tooltip position="top" html={true} title={renderRemoveOptionTooltipText()}>
							<Button
								type="link"
								color="grey"
								className={classes.elem('removeOptionButton').b()}
								onClick={() => removeOption(option)}
							>
								<Icon name="trash" />
							</Button>
						</Tooltip>
					) : null}
				</TableColumn>
			</TableRow>
		);
	};

	const renderTableHeader = () => {
		const firstColumnHeader = { title: T('agreement.createOrdersInAdvanceFirstColumn') };
		const secondColumnHeader = { title: T('agreement.createOrdersInAdvanceSecondColumn') };
		const thirdColumnHeader = { title: '' };

		return (
			<TableHeader
				className={classes.elem('tableHeader').b()}
				columns={[firstColumnHeader, secondColumnHeader, thirdColumnHeader]}
			/>
		);
	};

	const renderContent = () => (
		<>
			<Table>
				{renderTableHeader()}
				{options.sort((a, b) => a - b).map(renderOption)}
			</Table>
			<AddOption options={options} langMap={langMap.addOption} onChange={onChange} max={max} />
		</>
	);

	const renderOptionsTable = () =>
		enabled ? <CardContent className={classes.elem('content').b()}>{renderContent()}</CardContent> : null;

	return (
		<Card className={classes.b()} space="mtxl mbxl ptm">
			{renderCardHeader()}
			{renderOptionsTable()}
		</Card>
	);
};

export default SubscriptionAdvanceSettings;

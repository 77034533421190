import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Switch, Redirect, useParams } from 'react-router-dom';
import bemClass from '@upsales/components/Utils/bemClass';
import { Loader, Tabs as HeaderTabs, Tab, ColorSwitcher, Text, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import T from 'Components/Helpers/translate';
import { circle } from 'Components/Helpers/styleHelper';
import EditorHeader from 'Components/EditorHeader';
import Confirm from 'Components/Modals/Confirm';
import Route from 'App/pages/routes/route';
import './DocumentTemplateEditor.scss';
import { useSelector } from 'react-redux';
import type { RootState } from 'Store/index';
import DocumentTemplateEditorDetails from './DocumentTemplateEditorDetails';
import PopupPortalAnchors from 'App/helpers/PopupPortalAnchors';
import { DocumentTemplateEditorDesign, onEditorChange } from './DocumentTemplateEditorDesign';
import logError from 'Helpers/logError';
import AccountProfile, { ColorVariable } from 'App/resources/Model/AccountProfile';
import NotificationService from 'App/babel/NotificationService';
import { getTemplate, saveTemplate, clearEditor } from '../../store/actions/TemplateEditorActions.js';
import { makeCancelable } from 'App/babel/helpers/promise';
import { generateHTMLFromConfig } from 'Services/DocumentTemplate';
import { useAppDispatch } from 'App/components/hooks';
import type Role from 'App/resources/Model/Role.d.ts';
import LZString from 'lz-string';
import history from 'App/pages/routes/history';
import _ from 'lodash';

export enum LoadErrorTypes {
	MISSING_PROFILE = 'MISSING_PROFILE',
	NOT_FOUND = 'NOT_FOUND'
}

export type ProductTableColumn = {
	name: string;
	isActive: boolean;
	description?: string;
	tag: string;
	type: string;
	id: string;
	title: string;
	align: string;
};

export type ProductTableTotalPrice = {
	oneOff: string;
	recurring: string;
};

const columnTypeNames = ['EMPTY', 'TEXT', 'IMAGE', 'PRODUCTTABLE'] as const;
export type ColumnTypeName = 'EMPTY' | 'TEXT' | 'IMAGE' | 'PRODUCTTABLE';

export const verifyColumnTypeName = (value: string) => {
	if (columnTypeNames.includes(value as ColumnTypeName)) {
		return value as ColumnTypeName;
	}
};

export type ColumnType = {
	content: string;
	hPadding: number;
	id: number;
	rowId: number;
	size: number;
	style: {
		self: {
			[styleName: string]: string;
		};
	};
	type: ColumnTypeName;
	vPadding: number;
	width: number;
	src?: string;
	enableCellBackground?: boolean;
	backgroundColor?: string;
	// Image type
	imgWidth?: number;
	userImgWidth?: number;
	realImgWidth?: number;
	href?: string;
	showCaption?: boolean;
	caption?: string;
	captionAlign?: string;
	initialFixedWidth?: number;
	initialImgWidth?: number;
	// Text type
	align?: string;
	// Product table type
	ProductTableColumns?: ProductTableColumn[];
	ProductTableTotalPrice?: ProductTableTotalPrice;
	// Additional settings
	showPriceSummation?: boolean;
	showProductDescription?: boolean;
	showDiscounts?: boolean;
	imgAlign: string;
};

export type RowType = {
	backgroundColor: string;
	columns: ColumnType[];
	fullWidth: boolean;
	id: number;
	style: Object;
	size?: string | number;
	rowId?: number;
};

export type Config = {
	headerLogo: string;
	headerLogoAlign: string;
	headerLink: string;
	darkHeader: boolean;
	fullWidthHeader: boolean;
	rows: RowType[];
	bodyBg: string;
	footerTextColor: string;
	footerLinkTextColor: string;
	headerBg: string;
	logoType: string;
	hasHeader: boolean;
	headerMargin?: number;
	hasFooter: boolean;
	footerMargin?: number;
	hasPageMargin?: boolean;
	pageMargin?: number;
};

export type DocumentTemplateState = {
	name: string;
	roles: Role[];
	availableAt: string;
	activated: boolean;
	config: Config;
	loadError?: LoadErrorTypes | null;
	hash: string;
	accountProfile: AccountProfile | null;
	selectedColumn?: ColumnType;
	selectedRow?: RowType;
	hoveredColumn?: ColumnType;
	hoveredRow?: RowType;
	sidebarVisible: boolean;
	selectedToolsTab?: string;
	changes: string[];
	currentChange: number;
	selectedSettingsTab?: string;
	html: string;
	shouldScrollToRow: boolean;
};

export type DocumentTemplate = {
	active: boolean;
	css: string;
	html: string;
	isCustom: boolean;
	isSkeleton: boolean;
	json: string;
	language: string;
	templateId: string;
	templateName: string;
	userRoles: Role[];
	uuid: string;
	isEditorFormat: boolean;
	configHash: string;
};

function isEditingTemplate(): boolean {
	const url = history.location.pathname;
	const urlParts = url.split('/');
	if (urlParts[1] === 'document-template-editor' && urlParts[2] !== 'new') {
		return true;
	} else {
		return false;
	}
}

function isEqual(prev: DocumentTemplateState, next: DocumentTemplateState): boolean {
	const nameEqual = prev.name === next.name;
	const activatedEqual = prev.activated === next.activated;
	const rolesEqual =
		prev.roles.length === next.roles.length &&
		prev.roles.every(prevRole => next.roles.some(nextRole => prevRole.id === nextRole.id));
	const decompressedInitialState = LZString.decompressFromBase64(next.changes[0]);
	const decompressedCurrentState = LZString.decompressFromBase64(next.changes[next.currentChange]);
	let configEqual = false;
	if (decompressedInitialState && decompressedCurrentState) {
		configEqual = _.isEqual(
			JSON.parse(decompressedInitialState).config,
			JSON.parse(decompressedCurrentState).config
		);
	}

	return nameEqual && activatedEqual && rolesEqual && configEqual;
}

export const isInvalidTemplateName = (templateName: string | null) => {
	const specialCharRegex = /[^A-Za-z0-9\såäöÅÄÖ]+/;
	if (!templateName) {
		return T('admin.documentTemplate.missingName');
	} else if (specialCharRegex.test(templateName)) {
		return T('admin.documentTemplate.standardTemplatesModal.errors.invalidTemplateName');
	}
	return false;
};

const tabTypes = ['details', 'design'] as const;
type TabType = (typeof tabTypes)[number];
const verifyTabType = (value: string) => {
	if (tabTypes.includes(value as TabType)) {
		return value as TabType;
	}
};

const steps: Array<TabType> = ['details', 'design'];
const getNextStep = (selectedTab: TabType): TabType | null => {
	const nextStepI: number = steps.indexOf(selectedTab) + 1;
	const nextTab: TabType | null = nextStepI !== -1 ? steps[nextStepI] : null;
	return nextTab;
};

/* NOTE: The following code includes manual pixel adjustments to ensure that the position of the header and content
are consistent across all pages. This solution was necessary to fix an issue where the first page had larger white
spaces than the rest. It might not be the most elegant solution, but it serves the purpose. */
export const generateCSSFromState = (state: DocumentTemplateState): string => {
	const backgroundColor = state.config.bodyBg;
	const typography = state?.accountProfile?.typography;
	const colors = state?.accountProfile?.colors;
	const headerMargin = state.config.hasHeader && state.config.headerMargin ? state.config.headerMargin : 0;
	const footerMargin = state.config.hasFooter && state.config.footerMargin ? state.config.footerMargin : 0;
	const pageMargin = state.config.hasPageMargin && state.config.pageMargin ? state.config.pageMargin : 0;

	return `body { width: 210mm; background: {{bgconverter "${backgroundColor}"}}; }
	@media print {
		.root-table { margin: -18px !important; border-spacing: 0; }
		.print-container { border-spacing: 0; }
		thead { display: table-header-group; padding: 0;}
		tbody { display: table-row-group; }
		tfoot { display: table-footer-group; }
		footer, .page-footer-space { font-size: 12px; height: ${
			92 + footerMargin
		}px; background: {{bgconverter "${backgroundColor}"}}; }
		footer { position: fixed; bottom: 0px; margin: 0 0 ${pageMargin}px 0;}
		.header-content { display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			padding-right: 10px;
			padding-left: 10px;
			margin: 0 0 ${headerMargin}px 0;
		}
		.root-print-header-content, .root-print-footer-content { 
			display: flex;
			justify-content: center;
			align-items: center;
			padding; 0 10px 0 10px;
			margin: 0 0 ${pageMargin}px 0;
		}
		header, .header-container, root-print-header { z-index: 1; }
		body { margin: 0; padding 0; }
		body::before { /*Fix for making all pages fully covered by the backgroundColor*/
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 210mm;
			height: 297mm;
			z-index: -1;
			background: {{bgconverter "${backgroundColor}"}};
		}
	}
	.root-print-body {
		padding: 0;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.tbody-container  {
		padding: 0;
	}
	.tbody-container > .text-row {
		page-break-inside: auto !important;
	}
	.header-container, .root-print-header {
		display: flex;
		justify-content: center;
		padding: 0;
	}
	h2 { font-family: ${typography?.h2.fontFamily}; font-size: ${typography?.h2.size}px; font-weight: ${
		typography?.h2.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.h2.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.h2.underline ? 'underline' : 'none'
	}; color: ${colors?.mainText}}
	h3 { font-family: ${typography?.h3.fontFamily}; font-size: ${typography?.h3.size}px; font-weight: ${
		typography?.h3.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.h3.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.h3.underline ? 'underline' : 'none'
	}; color: ${colors?.secondaryText}}
	p, li { font-family: ${typography?.p.fontFamily}; font-size: ${typography?.p.size}px; font-weight: ${
		typography?.p.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.p.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.p.underline ? 'underline' : 'none'
	}; color: ${colors?.contentText}}
	.ingress { font-family: ${typography?.ingress.fontFamily}; font-size: ${typography?.ingress.size}px; font-weight: ${
		typography?.ingress.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.ingress.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.ingress.underline ? 'underline' : 'none'
	}; color: ${colors?.contentTextIngress}}
	link { font-family: ${typography?.link.fontFamily}; font-size: ${typography?.link.size}px; font-weight: ${
		typography?.link.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.link.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.link.underline ? 'underline' : 'none'
	}; color: ${colors?.linkText}}
	a { font-family: ${typography?.link.fontFamily}; font-weight: ${
		typography?.link.bold ? 'bold' : 'normal'
	}; font-style: ${typography?.link.italic ? 'italic' : 'normal'}; text-decoration: ${
		typography?.link.underline ? 'underline' : 'none'
	}; color: ${colors?.linkText}}
	footer { font-family: ${
		typography?.p.fontFamily
	}; font-size: 12px; font-weight: 'normal'; font-style:'normal'; text-decoration: 'none';
		 color: ${colors?.footerText}}
	.product-table p {font-weight: initial; font-style: initial; text-decoration: initial; color: initial; font-size: 12px !important;}
	.product-table {font-family: ${typography?.p.fontFamily}}
	footer a { font-family: ${
		typography?.p.fontFamily
	}; font-size: 12px; font-weight: normal; font-style:normal; text-decoration: none;
	color: ${colors?.footerText}}
	`;
};

const initialState: DocumentTemplateState = {
	name: '',
	roles: [],
	availableAt: '',
	activated: true,
	config: {
		headerLogo: '',
		headerLogoAlign: 'center',
		headerLink: '',
		darkHeader: false,
		fullWidthHeader: false,
		rows: [],
		bodyBg: '#ffffff',
		hasFooter: false,
		footerTextColor: '#1E252E', // Footer colors is updated in getAccountProfile() according to the profile settings.
		footerLinkTextColor: '#1F5CAD',
		headerBg: '#ffffff',
		hasHeader: false,
		logoType: 'url',
		headerMargin: 10,
		footerMargin: 0
	},
	loadError: null,
	hash: '',
	accountProfile: null,
	sidebarVisible: false,
	changes: [],
	currentChange: -1,
	selectedSettingsTab: 'settings',
	selectedToolsTab: 'column',
	html: '',
	shouldScrollToRow: false
};

const DocumentTemplateEditor = () => {
	const { t } = useTranslation();
	const lang = {
		details: t('createCustomTemplate.details'),
		design: t('groupMail.design'),
		createCustomTemplateHeadline: t('createCustomTemplate.createCustomTemplate'),
		editCustomTemplateHeadline: t('createCustomTemplate.editCustomTemplate'),
		nextStep: t('groupMail.nextStep'),
		cancel: t('default.abort'),
		savingChanges: t('groupMail.savingChanges'),
		template: t('mail.template'),
		couldNotOpenDocument: t('openError.documentTemplate'),
		allChangesSaved: t('groupMail.allChangesSaved'),
		noName: t('default.noName'),
		saveAsNew: t('mail.saveAsNew'),
		saveTemplate: t('document.saveTemplate'),
		updateTemplate: t('document.updateTemplate'),
		save: t('default.save'),
		saveAndKeepEditing: t('mail.saveAndKeepEditing'),
		edit: t('default.edit'),
		canNotSave: t('groupMail.canNotSave'),
		clickToEdit: t('default.clickToEdit'),
		edited: t('mailTemplate.edited'),
		missingAccountProfile: t('admin.missingAccountProfile'),
		missingName: t('admin.documentTemplate.missingName'),
		saveChanges: t('default.saveChanges'),
		changesWillBeLost: t('confirm.changesWillBeLost'),
		dontSave: t('default.dontSave'),
		error: {
			MISSING_BODY: t('mail.mailMissingContent'),
			MISSING_NAME: t('groupMail.missingName'),
			MISSING_SUBJECT: t('groupMail.missingSubject'),
			MISSING_GROUP_CATEGORY: t('groupMail.missingCategory'),
			ACCOUNTPROFILE_FAILED: t('admin.documentTemplateEditor.accountprofile.failed')
		},
		invalidTemplateName: t('admin.documentTemplate.standardTemplatesModal.errors.invalidTemplateName')
	};

	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const reduxDispatch = useAppDispatch();
	const documentTemplate = useSelector((state: RootState) => state?.TemplateEditor?.editor || '');
	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState<TabType>('details');
	const [dataHasChanged, setDataHasChanged] = useState(false);
	const [saveClicked, setSaveClicked] = useState(false);
	const [editing] = useState(isEditingTemplate());
	const { customerId } = useSelector((state: RootState) => state.App);
	const { id } = useParams<{ id?: string }>();
	const [state, dispatch] = useReducer((prev: DocumentTemplateState, next: Partial<DocumentTemplateState>) => {
		const updatedState = { ...prev, ...next };
		if (!isEqual(prev, updatedState) && !loading) {
			setDataHasChanged(true);
		}
		return updatedState;
	}, initialState);

	const uuid: string | undefined = id === 'new' ? undefined : id;
	const classes = new bemClass('DocumentTemplateEditor', 'FullScreenPage').mod({
		open: true
	});
	let onConfirmTitle;
	let onConfirmSupertitle;
	let onConfirmIcon;
	let disableSave: boolean;
	let onAbortConfirm;
	const validName = isInvalidTemplateName(state.name);
	const onConfirmTooltip = validName ? validName : undefined;
	const nextStep = getNextStep(selectedTab);
	if (editing) {
		onConfirmTitle = lang.updateTemplate;
		onConfirmSupertitle = null;
		onConfirmIcon = 'save';
		disableSave = !dataHasChanged || !!validName;
		onAbortConfirm = dataHasChanged;
	} else {
		onConfirmTitle = nextStep && !editing ? lang.design : lang.saveTemplate;
		onConfirmSupertitle = nextStep && !editing ? lang.nextStep : null;
		onConfirmIcon = !nextStep ? 'save' : undefined;
		disableSave = nextStep ? !!validName : false;
		onAbortConfirm = !!state.name || state.roles.length !== 0 || !state.activated;
	}

	const getAccountProfile = async () => {
		Tools.AccountProfile.get()
			.then(({ data }) => {
				if (!data) {
					dispatch({ loadError: LoadErrorTypes.MISSING_PROFILE, accountProfile: null });
				} else {
					const colorVariables: ColorVariable[] = [
						{ title: t('admin.colors'), colors: {} },
						{ title: t('admin.extraColors'), colors: {} }
					];
					Object.keys(data.colors).forEach(key => {
						colorVariables[0].colors['$|' + key + '|$'] = data.colors[key];
					});
					data?.extraColors?.forEach((color: { value: string }) => {
						colorVariables[1].colors[color.value] = color.value;
					});
					data.colorVariables = colorVariables;
					initialState.config.footerTextColor = data.colors.footerText;
					initialState.config.footerLinkTextColor = data.colors.footerLinkText;
					dispatch({ accountProfile: data });
				}
			})
			.catch(error => {
				logError(error, lang.error.ACCOUNTPROFILE_FAILED);
				NotificationService.add({
					icon: 'times',
					style: NotificationService.style.ERROR,
					title: 'default.error',
					body: 'admin.documentTemplateEditor.accountprofile.failed'
				});
			});
	};

	const cancel = () => {
		history.push('/' + customerId + '/up-admin/documentTemplates');
		reduxDispatch(clearEditor());
	};

	const goToAccountProfile = () => {
		Tools.$state.go('administration.accountProfile');
	};

	const goToTab = (tabName: TabType) => {
		if (tabName === 'design') {
			if (validName && !editing) {
				tabName = 'details';
			}
		}
		const currentPath = history.location.pathname.split('/');
		let firstTime: boolean = false;

		if (tabName === 'details' && currentPath[currentPath.length - 1] === 'design' && !editing) {
			history.goBack();
			return;
		}

		if (currentPath.length === 4) {
			// Target to change .../details to .../design and vice versa
			currentPath[currentPath.length - 1] = tabName;
		} else {
			currentPath.push(tabName);
			firstTime = true;
		}

		let newPath = currentPath.join('/');
		const queryString = history.location.search;
		newPath += queryString;
		setSelectedTab(tabName);

		if (newPath === history.location.pathname) return;

		if (firstTime) {
			history.replace(newPath);
		} else {
			history.push(newPath);
		}
	};

	const createDocumentTemplateFromState = (template: DocumentTemplate | null): DocumentTemplate => {
		return {
			active: state.activated,
			css: generateCSSFromState(state),
			html: state.accountProfile ? generateHTMLFromConfig(state.config, state.accountProfile) : '<div></div>',
			isCustom: template ? template.isCustom : false,
			isSkeleton: template ? template.isSkeleton : false,
			json: '',
			language: '',
			templateId: template ? template.templateId : '',
			templateName: state.name,
			userRoles: state.roles,
			uuid: uuid ? uuid : documentTemplate?.uuid ? documentTemplate?.uuid : '',
			isEditorFormat: true,
			configHash: LZString.compressToBase64(JSON.stringify(state.config))
		};
	};

	const onNextStep = () => {
		if (validName) {
			return;
		}
		if (editing) {
			setSaveClicked(true);
		} else {
			const nextStep = getNextStep(selectedTab);
			if (nextStep) {
				goToTab(nextStep);
			} else {
				setSaveClicked(true);
			}
		}
	};

	const disableDesignTab = (tab: string): boolean => {
		if (loading) return true;
		if (tab === 'design') {
			return !!validName;
		}
		return false;
	};

	let content;
	if (state.loadError) {
		if (state.loadError === 'MISSING_PROFILE') {
			content = (
				<Confirm
					size="md"
					visible={true}
					onConfirm={goToAccountProfile}
					onAbort={cancel}
					title="groupMail.missingAccountProfile"
					yes="admin.setupAccountProfile"
					color="green"
				>
					{lang.missingAccountProfile}
				</Confirm>
			);
		} else {
			content = (
				<Confirm
					visible={true}
					onConfirm={cancel}
					title="default.error"
					yes="default.close"
					icon="exclamation-triangle"
					color="orange"
				>
					{lang.couldNotOpenDocument}
				</Confirm>
			);
		}
	} else if (loading) {
		content = <Loader className={classes.elem('loader').b()} />;
	} else {
		content = (
			<Switch>
				{/* Routes for new templates */}
				<Route path="/document-template-editor/new/details">
					{<DocumentTemplateEditorDetails state={state} dispatch={dispatch} />}
				</Route>
				<Route path="/document-template-editor/new/design">
					{!validName ? <DocumentTemplateEditorDesign state={state} dispatch={dispatch} /> : null}
				</Route>
				<Redirect
					from="/document-template-editor/new"
					to={{
						pathname: '/document-template-editor/new/details',
						search: location.search
					}}
				/>

				{/* Routes for editing templates */}
				<Route
					path="/document-template-editor/:id/details"
					render={() => {
						return <DocumentTemplateEditorDetails state={state} dispatch={dispatch} />;
					}}
				/>
				<Route
					path="/document-template-editor/:id/design"
					render={() => {
						return <DocumentTemplateEditorDesign state={state} dispatch={dispatch} />;
					}}
				/>
				<Route
					path="/document-template-editor/:id"
					render={({ match }) => {
						const newPath = `/document-template-editor/${match.params.id}/details`;
						return <Redirect to={newPath} />;
					}}
				/>
			</Switch>
		);
	}
	const onCallbackError = () => {
		setSaveClicked(false);
	};

	useEffect(() => {
		if (saveClicked) {
			reduxDispatch(
				saveTemplate(createDocumentTemplateFromState(documentTemplate), true, cancel, onCallbackError)
			);
		}
	}, [saveClicked]);

	useEffect(() => {
		// When a new row is added, the default row height is 70px, and we have to compensate for that
		// when caluculating whether to scroll or not.
		const EMPTY_ROW_HEIGHT = 70;
		const SCROLL_THRESHOLD = 20;
		if (state.shouldScrollToRow && wrapperRef.current) {
			const distanceFromBottomOfContainer =
				wrapperRef.current.scrollHeight -
				(wrapperRef.current.scrollTop + wrapperRef.current.clientHeight) -
				EMPTY_ROW_HEIGHT;
			const isScrollAtBottom =
				wrapperRef.current.scrollHeight -
					wrapperRef.current.clientHeight -
					wrapperRef.current.scrollTop -
					EMPTY_ROW_HEIGHT <=
				SCROLL_THRESHOLD;
			if (isScrollAtBottom) {
				wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
			} else {
				const adjustedScrollTop = Math.min(
					wrapperRef.current.scrollTop + distanceFromBottomOfContainer,
					wrapperRef.current.scrollTop + EMPTY_ROW_HEIGHT
				);
				wrapperRef.current.scrollTop = adjustedScrollTop;
			}

			dispatch({ shouldScrollToRow: false });
		}
	}, [state.shouldScrollToRow]);

	useEffect(() => {
		if (history.location.pathname.endsWith('/details')) {
			goToTab('details');
		} else if (history.location.pathname.endsWith('/design')) {
			goToTab('design');
		}
	}, [history.location.pathname]);

	useEffect(() => {
		// Set tab based on URL
		if (editing) {
			goToTab('design');
		} else {
			goToTab(selectedTab); // If we refresh on the design tab we want to go back to the detials tab
		}

		// Get account profile
		const getAccountProfilePromise = makeCancelable(getAccountProfile());
		getAccountProfilePromise.promise
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				if (!editing) {
					setLoading(false);
				}
			});

		return () => {
			getAccountProfilePromise.cancel();
		};
	}, []);

	useEffect(() => {
		if (uuid) {
			reduxDispatch(getTemplate(uuid)).catch(() => {
				// Since we are not handling the load error in the old action and reducers but still handle
				// getting and saving the template there, we need to dispatch the load error if the dispatch
				// of getting the template fails in TemplateEditorActions.js here
				dispatch({ loadError: LoadErrorTypes.NOT_FOUND });
			});
		}
	}, [uuid]);

	useEffect(() => {
		if (documentTemplate?.uuid && state.accountProfile) {
			let configFromHTML = initialState.config;
			try {
				const decompressedData =
					LZString.decompressFromBase64(documentTemplate.configHash) || JSON.stringify(initialState.config);
				configFromHTML = JSON.parse(decompressedData);
			} catch (error) {
				logError(error, 'Error parsing the config data');
			}

			dispatch({
				name: documentTemplate.templateName,
				activated: documentTemplate.active,
				roles: documentTemplate.userRoles,
				html: documentTemplate.html,
				config: configFromHTML
			});
			onEditorChange(state, dispatch, { config: configFromHTML }); // Makes sure that we can undo any changes we do if we edit the template.
		}
	}, [documentTemplate?.uuid, state.accountProfile]);

	// If we are editing a template we want to wait for the state to be loaded
	useEffect(() => {
		if (state.name && loading) {
			setLoading(false);
		}
	}, [state.name]);

	useEffect(() => {
		/* If a user tries to reload the browser this event handler gives the user a popup from the browser warning that a reload may cause information to be lost.
		The user has to confirm to reload the browser. */
		const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				return (e.returnValue = '');
			}
			return '';
		};
		window.addEventListener('beforeunload', beforeUnloadHandler);
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadHandler);
		};
	}, []);

	return (
		<div className={classes.b()} data-testid="DocumentTemplateEditor" ref={wrapperRef}>
			<PopupPortalAnchors anchor={wrapperRef.current}>
				<EditorHeader
					onConfirmTitle={onConfirmTitle}
					onConfirmSupertitle={onConfirmSupertitle}
					disableSave={disableSave}
					onConfirmIcon={onConfirmIcon}
					onConfirmHasNext={!!nextStep}
					onConfirmTooltip={onConfirmTooltip}
					onAbortConfirm={onAbortConfirm}
					onConfirm={onNextStep}
					className={classes.elem('header').b()}
					subtitle={null}
					onAbort={cancel}
					loading={loading}
					title={editing ? lang.editCustomTemplateHeadline : lang.createCustomTemplateHeadline}
				>
					<HeaderTabs
						noFlex
						color="white"
						onChange={tab => {
							const verifiedTab = verifyTabType(tab);
							if (verifiedTab) goToTab(verifiedTab);
						}}
						selected={selectedTab}
					>
						{steps.map((tab, i) => (
							<Tab key={tab} id={tab} data-testid={tab} disabled={disableDesignTab(tab)}>
								{!editing && <ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>}
								<Tooltip
									disabled={!disableDesignTab(tab)}
									title={onConfirmTooltip || ''}
									data-testid={tab + '-tooltip'}
								>
									<Text
										data-testid={tab + '-text'}
										color="inherit"
										bold={selectedTab === tab}
										loading={loading}
									>
										{loading ? '' : lang[tab]}
									</Text>
								</Tooltip>
							</Tab>
						))}
					</HeaderTabs>
				</EditorHeader>
				{content}
			</PopupPortalAnchors>
		</div>
	);
};

export default DocumentTemplateEditor;

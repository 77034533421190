import React from 'react';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline, Text } from '@upsales/components';
import { selectRole, save } from 'Store/reducers/DesiredOutcomeReducer';
import GridItem from './GridItem';
import T from '../Helpers/translate';
import getAngularModule from '../../angularHelpers/getAngularModule';
import './RoleSelect.scss';

const mapStateToProps = state => ({
	desiredOutcomes: state.DesiredOutcome.desiredOutcomes,
	selectedRole: state.DesiredOutcome.selectedRole,
	saving: state.DesiredOutcome.saving
});

const mapDispatchToProps = {
	selectRole,
	save
};

class RoleSelect extends React.PureComponent {
	constructor(p) {
		super(p);

		const AppService = getAngularModule('AppService');

		const userName = AppService.getSelf().name;
		const companyName = AppService.getAccountSelf().client.name;

		this.lang = {
			roleDescription: T('desiredoutcome.role.description'),
			roleTitle: T('desiredoutcome.role.title', { userName, companyName }),
			roleWhyAnswerThis: T('desiredoutcome.role.whyAnswerThis'),
			of: T('default.of').toLowerCase()
		};
	}

	selectNoMatch = () => {
		this.props.selectRole({ id: -1 });
	};

	render() {
		const { desiredOutcomes, selectRole, selectedRole } = this.props;
		const classes = new bemClass('DesiredOutcomeRoleSelect');

		const gridItems = desiredOutcomes.map(item => (
			<GridItem
				key={item.id}
				select={selectRole}
				item={item}
				selected={selectedRole && selectedRole.id === item.id}
			/>
		));

		return (
			<div>
				<div className={classes.b()}>
					<Headline>{this.lang.roleTitle}</Headline>
				</div>
				<div className={classes.elem('grid').b()}>{gridItems}</div>
				<div className={classes.b()}>
					<Text color="grey-10">{this.lang.roleDescription}</Text>
					<ReactTemplates.elevio articleId={987} sidebar={true} text={this.lang.roleWhyAnswerThis} />
				</div>
			</div>
		);
	}
}

RoleSelect.defaultProps = {};

export const detached = RoleSelect;

const Component = connect(mapStateToProps, mapDispatchToProps)(RoleSelect);

export default Component;

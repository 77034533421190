export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	startDate: {
		title: 'default.startDate',
		field: 'startDate'
	},
	endDate: {
		title: 'default.endDate',
		field: 'endDate'
	},
	active: {
		title: 'default.active',
		field: 'active'
	},
	name: {
		title: 'default.name',
		field: 'name',
		locked: true
	},
	users: {
		title: 'default.users',
		field: 'user.name'
	},
	notes: {
		title: 'default.notes',
		field: 'notes'
	},
	quota: {
		title: 'campaign.quota',
		field: 'quota'
	}
};

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

import './Controls.scss';

const propTypes = {
	contact: PropTypes.object.isRequired,
	statics: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	removeEntity: PropTypes.func.isRequired,
	editEntity: PropTypes.func.isRequired
};

const defaultProps = {
	contact: {},
	statics: {},
	removeEntity: () => {},
	editEntity: () => {}
};

class Controls extends React.Component {
	render() {
		const { contact, statics, entity, removeEntity, editEntity } = this.props;
		return (
			<div className="AccountListContacts__Controls">
				<ReactTemplates.TOOLS.rowTool
					key="edit"
					item={contact}
					show={contact.userEditable}
					icon="edit"
					tooltip={statics.editTool}
					onClick={editEntity}
				/>
				<InlineConfirm
					show={contact.userRemovable}
					tooltip={statics.deleteTool}
					onConfirm={removeEntity}
					entity={entity}
				>
					<Button type="link" color="grey" className="row-tool">
						<Icon name="trash" />
					</Button>
				</InlineConfirm>
			</div>
		);
	}
}

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;
export default Controls;

/**
 * Provide phone config attributes for call from web
 * @returns {object} - phone config attributes
 */
export default (name, type, phone, href) => {
	const phoneIntegration = Tools.AppService.getPhoneIntegration();
	const hasCallFromWeb = Tools.AppService.getSelf().userParams.callFromWeb;
	const defaultHref = `callto:${phone}`;

	return phone && !phoneIntegration && hasCallFromWeb
		? ReactTemplates.TOOLS.upVoice({ name }, type, phone)
		: { href: href || defaultHref };
};

import T from 'Components/Helpers/translate';
import './ListView.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	EllipsisTooltip,
	Icon,
	Link,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Tooltip
} from '@upsales/components';
import { getIconAndColor } from 'App/babel/helpers/file';
import { FolderType, FileType } from '../AccountFiles';
import moment from 'moment';
import FolderRow from './FolderRow';

type Props = {
	folders: FolderType[];
	files: FileType[];
	openFolder: (folderId: number, name: string) => void;
	deleteFolder: (id: number) => void;
	deleteFile: (id: number) => void;
};

const ListView = ({ folders, files, openFolder, deleteFolder, deleteFile }: Props) => {
	const classes = new BemClass('FilesListView');

	const openRelated = (e: React.FormEvent, entity: string, entityId: number) => {
		e.stopPropagation();

		switch (entity) {
			case 'Contact':
				Tools.$state.go('contact.dashboard', { id: entityId });
				break;

			case 'Order':
			case 'Opportunity':
				Tools.$upModal.open('editOrder', { customerId: Tools.AppService.getCustomerId(), id: entityId });
				break;

			case 'Activity':
				Tools.$upModal.open('editActivity', { id: entityId });
				break;

			case 'Appointment':
				Tools.$upModal.open('editAppointment', { id: entityId });
				break;
		}
	};

	const getRow = (file: FileType) => {
		const [iconName, color] = getIconAndColor(file?.extension);
		return (
			<TableRow
				className={classes.elem('file').b()}
				key={file.id}
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.stopPropagation();

					Tools.File.download(file.id);
				}}
			>
				<TableColumn>
					<EllipsisTooltip title={file.name.match(/.{1,20}/g)?.join('\n')}>
						<Text className={classes.elem('file').elem('name').b()}>
							<Icon className={classes.elem('folder-icon').b()} color={color} name={iconName} />
							{file.name}
						</Text>
					</EllipsisTooltip>
				</TableColumn>
				<TableColumn>
					<Text>{`${Math.round(file.size / 10) / 100} Kb`}</Text>
				</TableColumn>
				<TableColumn>
					<Text>{moment(file.date).format('L')}</Text>
				</TableColumn>
				<TableColumn>
					<Text>{Tools.AppService.getUsers().find(u => u.id === file.userId)?.name ?? ''}</Text>
				</TableColumn>
				<TableColumn className={classes.elem('last-column').b()}>
					{file.related && file.related !== 'Client' ? (
						<Link
							onClick={e => {
								if (file.related && file.relatedId) {
									openRelated(e, file.related, file.relatedId);
								}
							}}
						>
							{file.related}
						</Link>
					) : (
						<div></div>
					)}
					<Tooltip className={classes.elem('delete-button').b()} title="Delete" position="left">
						<Icon
							onClick={e => {
								e.stopPropagation();
								deleteFile(file.id);
							}}
							color="grey-6"
							name="trash-o"
						/>
					</Tooltip>
				</TableColumn>
			</TableRow>
		);
	};

	const fileColumns = [
		{ title: T('default.name') },
		{ title: T('file.fileSize') },
		{ title: T('file.uploadedDate') },
		{ title: T('file.uploader') },
		{ title: T('file.relatedTo') }
	];
	const folderColumns = [{ title: T('default.name') }, { title: '' }];
	return (
		<div className={classes.b()}>
			{folders.length ? (
				<Table className={classes.elem('folderList').b()}>
					<TableHeader color="grey" columns={folderColumns}></TableHeader>
					{folders.map(folder => (
						<FolderRow
							key={folder.id}
							folder={folder}
							openFolder={openFolder}
							deleteFolder={deleteFolder}
						/>
					))}
				</Table>
			) : null}
			{files.length ? (
				<Table>
					<TableHeader color="grey" columns={fileColumns}></TableHeader>
					{files.map(file => getRow(file))}
				</Table>
			) : null}
		</div>
	);
};

export default ListView;

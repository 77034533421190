import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import { Icon, Text, DropDownMenu, Card } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { FolderTreeType } from '../../AccountFiles';
import FolderModal from '../../FolderModal';
import { useDrop } from 'react-dnd';

import './FolderTreeBranch.scss';

type Props = {
	folder: FolderTreeType | null;
	changeFolder: (folderId?: number | null) => void;
	lastInTree: boolean;
	addFolder: (name: string, parentId?: number | null) => void;
	changeNameOfFolder: (id: number, name: string) => void;
	addFile: (folderId?: number | null) => void;
	setSearchString?: (text: null) => void;
	searchString?: string | null;
	changeFolderFolder: (id: number, folderId: number | null) => void;
	changeFileFolder: (id: number, folderId: number | null) => void;
};

const FolderTreeBranch = ({
	folder,
	changeFolder,
	lastInTree,
	addFile,
	addFolder,
	changeNameOfFolder,
	setSearchString,
	searchString,
	changeFolderFolder,
	changeFileFolder
}: Props) => {
	const classes = new BemClass('FolderTreeBranch');
	const [showChangeName, setShowChangeName] = useState(false);
	const [showAddFolder, setShowAddFolder] = useState(false);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: 'ALL',
		drop: (item: { id: number; isFile: boolean }) => {
			if (item.isFile) {
				changeFileFolder(item.id, folder?.id ?? null);
			} else {
				changeFolderFolder(item.id, folder?.id ?? null);
			}
		},
		collect: monitor => ({ isOver: !!monitor.isOver() })
	}));

	const resetValues = () => {
		setShowAddFolder(false);
		setShowChangeName(false);
	};

	return (
		<div ref={drop} className={classes.mod({ isOver }).b()}>
			<DropDownMenu
				arrowed={false}
				useAnimation
				noMinWidth
				renderTrigger={(expanded, setExpanded) => (
					<Text
						bold={lastInTree}
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							if (typeof searchString === 'string') {
								setSearchString?.(null);
								return;
							}

							if (lastInTree) {
								resetValues();
								setExpanded(e);
							} else {
								changeFolder(folder?.id);
							}
						}}
					>
						{folder?.name || T('file.documents')}
						{lastInTree ? <Icon className={classes.elem('chevron-icon').b()} name="chevron-down" /> : null}
					</Text>
				)}
			>
				{setClosed => (
					<>
						{showAddFolder || showChangeName ? null : (
							<Card className={classes.elem('dropdown').b()}>
								<div onClick={() => addFile(folder?.id)}>
									<Icon name="file" />
									{T('file.addFile')}
								</div>
								<div onClick={() => setShowAddFolder(true)}>
									<Icon name="folder" />
									{T('file.addFolder')}
								</div>
								{folder ? (
									<div onClick={() => setShowChangeName(true)}>
										<Icon name="edit" />
										{T('file.rename')}
									</div>
								) : null}
							</Card>
						)}
						{showAddFolder ? (
							<FolderModal
								onClick={name => {
									addFolder(name);
									setClosed();
								}}
							/>
						) : null}
						{showChangeName ? (
							<FolderModal
								startName={folder?.name}
								buttonText={T('file.rename')}
								onClick={name => {
									if (folder?.id) {
										changeNameOfFolder(folder.id, name);
										setClosed();
									}
								}}
							/>
						) : null}
					</>
				)}
			</DropDownMenu>
		</div>
	);
};

export default FolderTreeBranch;

import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../TableHeader';
import AdminHeader from './AdminHeader';
import AdminOptOutRow from './AdminOptOutRow';
import { Button } from '@upsales/components';

class AdminOptOutList extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			optOutCategories: t('default.optOutCategories'),
			optOutCategoriesInfo: t('default.optOutCategoriesInfo'),
			newOptOutCategory: t('default.newOptOutCategory'),
			nodata: t('default.noData'),
			title: t('default.title'),
			inactiveOptOutCategories: t('default.inactiveOptOutCategories')
		};

		this.renderRows = this.renderRows.bind(this);
	}

	renderRows(categories, sort, asc) {
		const rows = _.chain(categories)
			.sortBy(n => {
				var compare = n[sort];
				return typeof compare === 'string' ? compare.toUpperCase() : compare;
			})
			.map(cat => <AdminOptOutRow key={cat.id} onClick={this.props.actions.onEdit} {...cat} />)
			.value();

		if (asc) {
			rows.reverse();
		}
		return rows;
	}

	renderTable(categories, title, isInactive) {
		const sort = {
			setSort: this.props.actions[isInactive ? 'setInactiveSort' : 'setSort'],
			sort: isInactive ? this.props.inactiveSort : this.props.sort,
			asc: isInactive ? this.props.inactiveAsc : this.props.asc
		};
		return (
			<div className="admin-table" key={'opt-out-table' + (isInactive ? '-inactive' : '')}>
				<div className="admin-table-top">
					<span className="admin-table-title">{title}</span>
					{!isInactive ? (
						<div className="pull-right">
							<Button onClick={this.props.actions.addOptOut} size="sm">
								{this.lang.newOptOutCategory}
							</Button>
						</div>
					) : null}
				</div>
				{categories.length ? (
					<table>
						<thead>
							<tr>
								<TableHeader
									title={this.lang.title}
									sort="title"
									currentSort={sort.sort}
									asc={sort.asc}
									onClick={sort.setSort}
								/>
								<th style={{ width: '60px' }} />
							</tr>
						</thead>
						<tbody>{categories}</tbody>
					</table>
				) : null}
			</div>
		);
	}

	render() {
		const activeRows = this.renderRows(this.props.categories, this.props.sort, this.props.asc);
		const inactiveRows = this.renderRows(
			this.props.inactiveCategories,
			this.props.inactiveSort,
			this.props.inactiveAsc
		);

		return (
			<div className="admin-root-wrapper">
				<AdminHeader
					title={this.lang.optOutCategories}
					description={this.lang.optOutCategoriesInfo}
					image="admin-opt-out.svg"
				/>

				<div id="admin-content">
					{this.renderTable(activeRows, this.lang.optOutCategories)}
					{inactiveRows.length
						? this.renderTable(inactiveRows, this.lang.inactiveOptOutCategories, true)
						: null}
				</div>
			</div>
		);
	}
}

AdminOptOutList.propTypes = {
	actions: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
	inactiveCategories: PropTypes.array.isRequired,
	sort: PropTypes.string.isRequired,
	asc: PropTypes.bool.isRequired,
	inactiveSort: PropTypes.string.isRequired,
	inactiveAsc: PropTypes.bool.isRequired
};

export default AdminOptOutList;

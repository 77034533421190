ReactTemplates.admin.roleAccessInput = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			acceptTerms: t('integration.acceptTerms')
		};
	},

	getTypeText: function (access, type, entity, accessType) {
		var tEntity = entity ? entity.toLowerCase() : '';
		var res = {};
		switch (access) {
			case 'no':
				res.title = Tools.$translate('default.none');
				res.color = 'red';
				res.subtitle = Tools.$translate('admin.roleAccess.' + type + '.none', { ENTITY: tEntity });
				break;
			case 'own':
				res.title = Tools.$translate('default.onlyOwn');
				if (accessType === 'Report') {
					res.subtitle = Tools.$translate('admin.roleAccess.access.own.report');
				} else {
					res.subtitle = Tools.$translate('admin.roleAccess.' + type + '.own', { ENTITY: tEntity });
				}
				res.color = 'orange';
				break;
			case 'ownAndNoUser':
				res.title = Tools.$translate('admin.roleAccess.ownAndNoUser');
				res.subtitle = Tools.$translate('admin.roleAccess.' + type + '.ownAndNoUser', { ENTITY: tEntity });
				res.color = 'orange';
				break;
			case 'roleAndNoUser':
				res.title = Tools.$translate('admin.roleAccess.roleAndNoUser');
				res.subtitle = Tools.$translate('admin.roleAccess.' + type + '.roleAndNoUser', { ENTITY: tEntity });
				res.color = 'grey';
				break;
			case 'all':
				res.title = Tools.$translate('default.role');
				if (accessType === 'Report') {
					res.subtitle = Tools.$translate('admin.roleAccess.access.role.report');
				} else {
					res.subtitle = Tools.$translate('admin.roleAccess.' + type + '.role', { ENTITY: tEntity });
				}
				res.color = 'grey';
				break;
			case 'top':
				res.title = Tools.$translate('admin.highestAccess');
				if (accessType === 'Report') {
					res.subtitle = Tools.$translate('admin.roleAccess.access.full.report');
				} else {
					res.subtitle = Tools.$translate(
						!this.props.topLevel ? 'admin.roleAccess.fullTopLevel' : 'admin.roleAccess.' + type + '.full'
					);
				}
				res.color = 'green';
				break;
			default:
				return null;
		}
		return res;
	},

	componentDidMount: function () {
		var self = this;
		var options = {
			minimumResultsForSearch: -1,
			formatSelection: function (value, container, escape) {
				if (typeof value !== 'object') {
					return;
				}
				var obj = self.getTypeText(value.id);
				if (!obj) {
					return '<i calss="text-grey">' + 'unavailable value' + '</i>';
				}
				return (
					'<span class="role-access-select"><span class="color-ball ' +
					escape(obj.color) +
					'"></span> ' +
					escape(obj.title) +
					'</span>'
				);
			},
			formatResult: function (value, container, query, escape) {
				if (typeof value !== 'object') {
					return;
				}
				var obj = self.getTypeText(
					value.id,
					value.element[0].dataset.type,
					value.element[0].dataset.entity,
					self.props.accessType
				);
				if (!obj) {
					value.element[0].style.display = 'none';
					return '';
				}
				return (
					'<span class="role-access-select has-sub"><span class="color-ball ' +
					escape(obj.color) +
					'"></span> ' +
					escape(obj.title) +
					'<span class="access-subtitle">' +
					escape(obj.subtitle) +
					'</span></span>'
				);
			}
		};

		jQuery(this.select)
			.select2('val', this.props.value)
			.select2(options)
			.on('change', function (e) {
				self.props.onChange(e.added.id);
			});
	},

	componentDidUpdate: function () {
		jQuery(this.select).select2('val', this.props.value);
	},

	setRef: function (r) {
		this.select = r;
	},

	render: function () {
		var self = this;

		var options = _.map(this.props.options, function (opt) {
			return (
				<option key={opt} data-type={self.props.type} data-entity={self.props.name} value={opt}>
					{opt}
				</option>
			);
		});

		return (
			<select
				value={this.props.value}
				className={'access-selector' + (this.props.last ? ' last' : '')}
				ref={this.setRef}
			>
				{options}
			</select>
		);
	}
});

const ScrollHelper = function (host) {
	let scrollTop = 0;
	// let throttled = false;
	// let delay = 100;
	// let forLastExec;

	const update = () => {
		host.setState({});
	};

	/* I am not using throttle as that made it look laggy and it flows kinda good with 100k products anyway */
	this.onScroll = function (event) {
		scrollTop = event.target.scrollTop;
		update();

		// if (!throttled) {
		// 	update();

		// 	throttled = true;

		// 	setTimeout(function() {
		// 		throttled = false;
		// 	}, delay);
		// }
		// else {
		// 	clearTimeout(forLastExec);
		// 	forLastExec = setTimeout(update, delay);
		// }
	};
	this.filter = function (elements, options) {
		const { top = 0, containerHeight = 0, elementHeights = 0, limit = 50 } = options;
		const effectiveScrollTop = Math.max(scrollTop - top, 0);

		let getHeight;

		if (typeof elementHeights === 'number') {
			getHeight = () => elementHeights;
		} else if (typeof elementHeights === 'function') {
			getHeight = elementHeights;
		} else if (Array.isArray(elementHeights)) {
			getHeight = (element, index) => elementHeights[index];
		} else {
			throw Error(
				'elementHeights needs to be a scalar, array of heights or a function with signature (element, index) => height_of_element'
			);
		}

		let startHeight = 0;
		let start = 0;

		for (const [index, element] of elements.entries()) {
			const elementHeight = getHeight(element, index);

			if (startHeight + elementHeight < effectiveScrollTop) {
				startHeight = startHeight + elementHeight;
				start = index + 1;
			} else {
				break;
			}
		}

		let visibleHeight = 0;
		let end = 0;
		if (start >= elements.length) {
			start = elements.length - 1;
		}

		for (let itr = start; itr < elements.length; ++itr) {
			const element = elements[itr];
			const elementHeight = getHeight(element, itr);

			if (visibleHeight < containerHeight) {
				visibleHeight = visibleHeight + elementHeight;
				end = itr + 1;
			} else {
				break;
			}
		}

		for (let itr = 0; itr < limit && end < elements.length; ++itr) {
			end = end + 1;
		}

		for (let itr = 0; itr < limit && start > 0; ++itr) {
			const element = elements[start];
			const elementHeight = getHeight(element, start);
			startHeight = startHeight - elementHeight;
			start = start - 1;
		}

		let endHeight = 0;

		for (let itr = end; itr < elements.length; ++itr) {
			const element = elements[itr];
			const elementHeight = getHeight(element, itr);

			endHeight = endHeight + elementHeight;
		}

		const topDummyElement = <div key="dummy-top" style={{ height: startHeight }} />;
		const bottomDummyElement = <div key="dummy-bottom" style={{ height: endHeight }} />;

		return [topDummyElement, ...elements.slice(start, end + 1), bottomDummyElement];
	};
};

window.ScrollHelper = ScrollHelper;
export default ScrollHelper;

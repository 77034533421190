import React from 'react';
import { Button, Icon } from '@upsales/components';
import Merge from '../Modals/CreateNewAccount/Merge';

const GroupSizeMerge = props => {
	return (
		<div className="merge-container">
			<Button className="exit-button" onClick={() => props.reject()}>
				<Icon name="times" />
			</Button>
			<Merge {...props} />
		</div>
	);
};

export default GroupSizeMerge;

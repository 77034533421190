'use strict';

const numberAndStringType = ['[object String]', '[object Number]'];
const arrayAndObjectsType = ['[object Array]', '[object Object]'];

export const getLen = (type, value) => {
	if (type === '[object Array]') {
		return value.length;
	}

	return Object.keys(value).length;
};

export const stringAndNumberCheck = (val1, val2) => {
	if (val1 === val2) {
		return true;
	}

	return false;
};

export const arrayAndObjectCheck = (val1, val2) => {
	if (!isEqual(val1, val2)) {
		return false;
	}

	return true;
};

export const functionCheck = (val1, val2) => {
	if (val1.toString() !== val2.toString()) {
		return false;
	}

	return true;
};

export const arrayCheck = (type, opts) => {
	opts.value = opts.value.slice().sort();
	opts.other = opts.other.slice().sort();

	for (var i = 0; i < opts.valueLen; i++) {
		if (compare(opts.value[i], opts.other[i]) === false) {
			return false;
		}
	}

	return true;
};

export const objectCheck = (type, opts) => {
	for (const key in opts.value) {
		if (opts.value.hasOwnProperty(key) && compare(opts.value[key], opts.other[key]) === false) {
			return false;
		}
	}

	return true;
};

const types = {
	'[object Array]': arrayCheck,
	'[object Object]': objectCheck
};

export const checkDecider = (type, opts) => {
	return types[type](type, opts);
};

export const isEqual = (value, other) => {
	const type = Object.prototype.toString.call(value);
	const otherType = Object.prototype.toString.call(other);
	if (type !== otherType) {
		return false;
	}

	if (numberAndStringType.indexOf(type) > -1) {
		return stringAndNumberCheck(value, other);
	}

	const valueLen = getLen(type, value);
	const otherLen = getLen(type, other);

	if (valueLen !== otherLen) {
		return false;
	}

	return checkDecider(type, {
		value: value,
		other: other,
		valueLen: valueLen,
		otherLen: otherLen
	});
};

export const compare = (item1, item2) => {
	const itemType = Object.prototype.toString.call(item1);
	if (arrayAndObjectsType.indexOf(itemType) >= 0) {
		return arrayAndObjectCheck(item1, item2);
	}

	if (itemType !== Object.prototype.toString.call(item2)) {
		return false;
	}

	if (itemType === '[object Function]') {
		return functionCheck(item1, item2);
	}

	return stringAndNumberCheck(item1, item2);
};

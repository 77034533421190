'use strict';

angular.module('upHelpers').factory('OrderHelper', [
	'Order',
	function (Order) {
		function getMasterCurrencySum(rb, customerId) {
			var totalSumAgg = rb.aggregationBuilder();
			totalSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInMasterCurrency);
			totalSumAgg.aggregationName('sum');

			var totalWeightedSumAgg = rb.aggregationBuilder();
			totalWeightedSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.weightedValueInMasterCurrency);
			totalWeightedSumAgg.aggregationName('weightedSum');

			totalSumAgg.done();
			totalWeightedSumAgg.done();

			return Tools.Report.customer(customerId).setType(Tools.Report.type.ORDER).find(rb.build());
		}

		function getRoleCurrencySum(rb, customerId) {
			var currencyAgg = rb.aggregationBuilder();
			currencyAgg.addAggregation(rb.aggregationTypes.Terms, Order.attr.currency);
			currencyAgg.aggregationName('currency');

			var totalSumAgg = currencyAgg.aggregationBuilder();
			totalSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.value);
			totalSumAgg.aggregationName('sum');

			var totalWeightedSumAgg = currencyAgg.aggregationBuilder();
			totalWeightedSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.weightedValue);
			totalWeightedSumAgg.aggregationName('weightedSum');

			var totalSumInMasterCurrencyAgg = currencyAgg.aggregationBuilder();
			totalSumInMasterCurrencyAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInMasterCurrency);
			totalSumInMasterCurrencyAgg.aggregationName('sumInMasterCurrency');

			var totalWeightedSumInMasterCurrencyAgg = currencyAgg.aggregationBuilder();
			totalWeightedSumInMasterCurrencyAgg.addAggregation(
				rb.aggregationTypes.Sum,
				Order.attr.weightedValueInMasterCurrency
			);
			totalWeightedSumInMasterCurrencyAgg.aggregationName('weightedSumInMasterCurrency');

			totalWeightedSumInMasterCurrencyAgg.done();
			totalSumInMasterCurrencyAgg.done();
			totalWeightedSumAgg.done();
			totalSumAgg.done();
			currencyAgg.done();

			return Tools.Report.customer(customerId).setType(Tools.Report.type.ORDER).find(rb.build());
		}

		function getRoleCurrencySumNew(rb, customerId) {
			var totalSumInRoleCurrency = rb.aggregationBuilder();
			totalSumInRoleCurrency.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInRoleCurrency);
			totalSumInRoleCurrency.aggregationName('sum');

			var totalWeightedSumInRoleCurrency = rb.aggregationBuilder();
			totalWeightedSumInRoleCurrency.addAggregation(
				rb.aggregationTypes.Sum,
				Order.attr.weightedValueInRoleCurrency
			);
			totalWeightedSumInRoleCurrency.aggregationName('weightedSum');

			totalSumInRoleCurrency.done();
			totalWeightedSumInRoleCurrency.done();

			return Tools.Report.customer(customerId).setType(Tools.Report.type.ORDER).find(rb.build());
		}

		return {
			getRoleCurrencySum: getRoleCurrencySum,
			getMasterCurrencySum: getMasterCurrencySum,
			getRoleCurrencySumNew: getRoleCurrencySumNew
		};
	}
]);

'use strict';

angular.module('domain.advancedSearch').directive('orderInfoSearchSection', [
	'FeatureHelper',
	function (FeatureHelper) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/orderInfoSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				'$translate',
				function ($scope, AppService, $translate) {
					$scope.inited = false;
					$scope.AgreementEnabled = false;

					$scope.ngChange = function () {
						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					$scope.advancedDateSelectConfig = {
						formatResult: function (object) {
							const descriptionTranslationKey =
								object.id === 'regDate'
									? 'createdDescription'
									: object.id === 'date'
									? 'closeDateDescription'
									: 'actualCloseDateDescription';
							const description = $translate.instant(
								`advancedSearch.order.date.${descriptionTranslationKey}`
							);
							return `<div>${object.text}</div><div class="advanced-search-select2-dropdown-description">${description}</div>`;
						}
					};

					var init = function () {
						$scope.inited = true;
					};

					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						$scope.AgreementEnabled =
							AppService.getMetadata().params.AgreementEnabled &&
							FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER);
						$scope.showOpportunityFilter = FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE);

						$scope.Filters.Description.comparisonType = 'Search';

						$scope.currencyIso = Tools.AppService.getMetadata().defaultCurrency.iso;

						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);

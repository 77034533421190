'use strict';

angular.module('upUi').factory('$upKeyboard', function () {
	// some presets
	var keyKeys = {
		escape: 27,
		enter: 13,
		up: 38,
		down: 40
	};

	var keyupEvents = {};
	var keydownEvents = {};

	var getEvents = function (event) {
		return event === 'keyup' ? keyupEvents : keydownEvents;
	};

	var register = function (event, eventId, key, callback, deactivate) {
		var callback = callback || angular.noop;
		var code = key;
		var events = getEvents(event);
		var active = !deactivate;

		if (typeof key === 'string' && keyKeys[key]) {
			code = keyKeys[key];
		} else if (typeof key === 'string' && keyKeys[key] === undefined) {
			throw new Error(key + ' is not a valid keyPreset');
		}

		events[eventId] = {
			type: event,
			keyCode: code,
			callback: callback,
			active: active
		};

		return {
			eventId: eventId,
			keyCode: code,
			activate: function () {
				activateEvent(events, eventId, code);
			},
			deactivate: function () {
				deactivateEvent(events, eventId, code);
			}
		};
	};

	var activateEvent = function (events, eventId, code) {
		events[eventId].active = true;
	};

	var deactivateEvent = function (events, eventId, code) {
		events[eventId].active = false;
	};

	var on = function (e) {
		var code = e.keyCode;
		var events = getEvents(e.type);

		// run callbacks
		_.forOwn(events, function (event, eventId) {
			if (event.keyCode === code && event.active) {
				e.eventId = eventId;
				event.callback(e);
			}
		});
	};

	var off = function (event, eventId) {
		var events = getEvents(event);

		delete events[eventId];
	};

	// listen for keyboard events
	angular.element(document).on('keyup keydown', on);

	// return instance
	var KeyboardInstance = {
		// register keyup listener
		onKeyup: function (eventId, key, callback, deactivate) {
			return register('keyup', eventId, key, callback, deactivate);
		},

		// register keydown listener
		onKeydown: function (eventId, key, callback, deactivate) {
			return register('keydown', eventId, key, callback, deactivate);
		},

		// destroy keyup listener
		offKeyup: function (eventId) {
			off('keyup', eventId);
		},

		// destroy keydown listener
		offKeydown: function (eventId) {
			off('keydown', eventId);
		}
	};

	return KeyboardInstance;
});

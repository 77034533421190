import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Text } from '@upsales/components';
import { useDrag, useDrop } from 'react-dnd';

import './Folder.scss';

type Props = {
	id: number;
	name: string;
	openFolder: (folderId: number, name: string) => void;
	isInEditMode: boolean;
	deleteFolder: (id: number) => void;
	changeFolderFolder: (id: number, folderId: number | null) => void;
	changeFileFolder: (id: number, folderId: number | null) => void;
};

const Folder = ({ name, openFolder, id, isInEditMode, deleteFolder, changeFolderFolder, changeFileFolder }: Props) => {
	const classes = new BemClass('Folder');

	const [{ isOver }, drop] = useDrop(() => ({
		accept: 'ALL',
		drop: (item: { id: number; isFile: boolean }) => {
			if (item.isFile) {
				changeFileFolder(item.id, id);
			} else if (item.id !== id) {
				changeFolderFolder(item.id, id);
			}
		},
		collect: monitor => ({ isOver: !!monitor.isOver() })
	}));

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: 'ALL',
			canDrag: !isInEditMode,
			item: { id, isFile: false },
			collect: monitor => ({ isDragging: !!monitor.isDragging() })
		}),
		[isInEditMode]
	);

	return (
		<div ref={drop}>
			<div
				onClick={!isInEditMode ? () => openFolder(id, name) : undefined}
				className={classes.mod({ isInEditMode, isDragging, isOver }).b()}
				ref={drag}
			>
				<Text size="sm" bold>
					<Icon name="folder" />
					{name}
				</Text>
				{isInEditMode ? (
					<Icon onClick={() => deleteFolder(id)} className={classes.elem('trash').b()} name="trash" />
				) : null}
			</div>
		</div>
	);
};

export default Folder;

import './OrderCloseDatePicker.scss';
import React, { useState } from 'react';
import moment from 'moment';
import { Card, Text, Block, Button, Calendar, CalendarHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

interface Props {
	date?: Date | null;
	visible: boolean;
	onChange?: (date: Date | null) => void;
}

const OrderCloseDatePicker = ({ date, visible, onChange }: Props) => {
	const classes = new BemClass('OrderCloseDatePicker');
	const [isMonth, setIsMonth] = useState(true);
	const [orderDate, setOrderDate] = useState<Date | undefined | null>(date);
	const isPastDate = moment(date).isBefore();
	const isFutureDate = moment(date).isAfter();

	const changeOrderDate = (date: Date) => {
		if (!isMonth && onChange) {
			const nextDate = moment(orderDate).add(1, 'month').format('YYYY-MM-DD');
			const prevDate = moment(orderDate).add(-1, 'month').format('YYYY-MM-DD');
			const selectedDate = moment(date).format('YYYY-MM-DD');
			const changedThroughArrowFunction =
				moment(selectedDate).isSame(nextDate) || moment(selectedDate).isSame(prevDate);

			if (!changedThroughArrowFunction) {
				onChange(date);
			}
		}
		setOrderDate(date);
	};

	const onLeaveIt = () => {
		if (onChange) {
			onChange(null);
		}
	};

	const onToday = () => {
		if (onChange) {
			onChange(new Date());
		}
	};

	const renderPastDateHeaderText = () => (
		<Block space="mbm ptl prl pll" className={classes.elem('pastDateHeaderText').b()}>
			<Text size="md" bold>
				{T('orderCloseDatePicker.pastDateHeaderText', { closeDate: moment(date).format('L') })}
			</Text>
		</Block>
	);

	const renderFutureDateHeaderText = () => (
		<Block space="mbm ptl prl pll" className={classes.elem('futureDateHeaderText').b()}>
			<Text size="md" bold>
				{T('orderCloseDatePicker.futureDateHeaderText', { closeDate: moment(date).format('L') })}
			</Text>
		</Block>
	);

	const renderCalendarHeader = () => (
		<div className={classes.elem('calendarHeader').b()}>
			<CalendarHeader
				showMonths={true}
				isMonthView={isMonth}
				setIsMonth={setIsMonth}
				date={orderDate || new Date()}
				onDateChange={changeOrderDate}
			/>
		</div>
	);

	const renderCalendar = () => (
		<Calendar
			min={new Date()}
			showMonths={true}
			selected={orderDate || new Date()}
			isMonthView={isMonth}
			setIsMonth={setIsMonth}
			onSelect={changeOrderDate}
			onDateChange={changeOrderDate}
			displayDate={orderDate || new Date()}
			className={classes.elem('calendar').b()}
			weekIndicator={T('calendar.weekPrefix')}
		/>
	);

	const renderLeaveItButton = () => (
		<Button type="link" onClick={onLeaveIt} className={classes.elem('leaveItButton').b()}>
			{T('orderCloseDatePicker.leaveItButton')}
		</Button>
	);

	const renderTodayButton = () => (
		<Button type="link" onClick={onToday} className={classes.elem('todayButton').b()}>
			{T('orderCloseDatePicker.todayButton')}
		</Button>
	);

	return (
		<Card className={classes.mod({ visible }).b()}>
			{isPastDate ? renderPastDateHeaderText() : null}
			{isFutureDate ? renderFutureDateHeaderText() : null}
			{renderCalendarHeader()}
			{renderCalendar()}
			<Block space="pts pbs" className={classes.elem('buttonContainer').b()}>
				{renderLeaveItButton()}
				{renderTodayButton()}
			</Block>
		</Card>
	);
};

export default OrderCloseDatePicker;

import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text, Link } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import T from 'Components/Helpers/translate';

const getDate = momentDate => {
	let str = '';
	if (momentDate.isValid()) {
		// If date is in future we display date calendar
		const today = new Date();
		if (momentDate.isSame(today, 'day') || momentDate.isAfter(today)) {
			const d = momentDate.calendar();

			// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
			str = d !== '0' ? d : momentDate.format('L');
		} else {
			// check for IE since moment obj becomes weird in IE..
			str = Tools.browserService.isIE()
				? moment(momentDate.format('YYYY-MM-DD')).fromNow()
				: momentDate.fromNow(); // else we display ex. "4 years ago"
		}
	}

	return str;
};

const getClientLink = (closeSearch, client, color) =>
	client ? (
		<Link
			onClick={e => {
				e.stopPropagation();
				closeSearch();
			}}
			href={Tools.$state.href('account.dashboard', {
				id: client.id,
				customerId: Tools.AppService.getCustomerId()
			})}
			color={color}
		>
			{client.name}
		</Link>
	) : null;
const getContactLink = (closeSearch, contacts) =>
	contacts && contacts.length ? (
		<Link
			onClick={e => {
				e.stopPropagation();
				closeSearch();
			}}
			href={Tools.$state.href('contact.dashboard', {
				id: contacts[0].id,
				customerId: Tools.AppService.getCustomerId()
			})}
		>
			{contacts[0].name}
		</Link>
	) : null;

const getClientAndContact = (appointment, closeSearch) => {
	let title;
	let subtitle;

	if (appointment.contacts && appointment.contacts.length) {
		title = getContactLink(closeSearch, appointment.contacts);
		if (appointment.contacts.length > 1) {
			title = (
				<Fragment>
					{title}
					{` + ${appointment.contacts.length - 1} ${T('default.more2').toLowerCase()}`}
				</Fragment>
			);
		}
		subtitle = appointment.client ? getClientLink(closeSearch, appointment.client, 'grey-12') : null;
	} else if (appointment.client) {
		title = appointment.client ? getClientLink(closeSearch, appointment.client) : null;
	}

	return {
		title,
		subtitle
	};
};

const SupersearchResultRowAppointment = ({ className, item: appointment, searchStr, closeSearch, ...props }) => {
	const classes = new bemClass('SupersearchResultRowAppointment', className);
	const appointmentDate = moment(appointment.date);
	const inactive = moment().isAfter(appointmentDate);
	const dateStr = getDate(appointmentDate);
	const { title, subtitle } = getClientAndContact(appointment, closeSearch);

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={appointment.users ? appointment.users : []}
			inactive={inactive}
			searchStr={searchStr}
			customValues={appointment.custom}
			{...props}
		>
			<Column size={4}>
				<Text bold={true} color={inactive ? 'grey-10' : 'black'} size="md" italic={inactive}>
					<SupersearchHighlight searchStr={searchStr} text={appointment.description} type="string" />
				</Text>

				<Text color="grey-12" size="sm" italic={inactive}>
					{dateStr}
					{appointment.location ? ` - ${appointment.location}` : null}
				</Text>
			</Column>
			<Column>
				{title ? (
					<Text size="sm" italic={inactive}>
						{title}
					</Text>
				) : null}
				{subtitle ? (
					<Text size="sm" italic={inactive}>
						{subtitle}
					</Text>
				) : null}
			</Column>
		</SupersearchResultRow>
	);
};

SupersearchResultRowAppointment.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	closeSearch: PropTypes.func
};

export default SupersearchResultRowAppointment;

import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../../../CriteriaRowWrapper';
import Drilldown from './Drilldown';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { ButtonSelect } from '@upsales/components';
import { cloneDeep } from 'lodash';
import './Industry.scss';

class IndustryInput extends React.Component {
	constructor(props) {
		super(props);

		const t = getAngularModule('$translate');

		this.lang = {
			allIndustries: t('soliditet.allTypes'),
			industriesWithSales: t('prospecting.topIndustriesWithSales'),
			lessOptions: t('default.lessOptions'),
			moreOptions: t('default.moreOptions')
		};

		this.toggleDrilldown = this.toggleDrilldown.bind(this);

		const { filter, registerDrilldown } = this.props;
		registerDrilldown(filter.filterName, Drilldown);
	}

	toggleDrilldown() {
		const { filter, toggleDrilldown } = this.props;
		toggleDrilldown(filter.filterName);
	}

	onButtonSelectChange = id => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.industries = id;
		onChange(newFilter.value, newFilter);
	};

	onButtonSelectClick = () => {
		const { filter, toggleDrilldown, hasOpenDrilldown } = this.props;
		if (hasOpenDrilldown === false) {
			toggleDrilldown(filter.filterName);
		}
	};

	render() {
		const { hasOpenDrilldown, filter } = this.props;

		return (
			<CriteriaRowWrapper rowFlex>
				<div className="criteria-row criteria-row--prospecting-industry">
					<ButtonSelect
						options={[
							{ value: 'allIndustries', title: this.lang.allIndustries },
							{ value: 'industriesWithSales', title: this.lang.industriesWithSales, icon: 'dollar' }
						]}
						value={filter.industries}
						onChange={this.onButtonSelectChange}
						onClick={this.onButtonSelectClick}
					/>
					<div
						className="btn btn-link up-btn btn-bright-blue drilldown-btn btn-lg"
						onClick={this.toggleDrilldown}
					>
						{hasOpenDrilldown ? this.lang.lessOptions : this.lang.moreOptions}
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

IndustryInput.propTypes = {
	registerDrilldown: PropTypes.func.isRequired,
	toggleDrilldown: PropTypes.func.isRequired,
	hasOpenDrilldown: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	locked: PropTypes.bool
};

export default IndustryInput;

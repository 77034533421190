import React, { useEffect, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import LZString from 'lz-string';
import bemClass from '@upsales/components/Utils/bemClass';
import './DocumentTemplateEditorDesign.scss';
import { EditorWrap } from 'Components/EditorUtils';
import { Headline, Label, Loader, Tab, Tabs, Button, Column, Row, Toggle } from '@upsales/components';
import { ColumnTypeName, DocumentTemplateState } from './DocumentTemplateEditor';
import { removeItem } from 'Store/helpers/array';
import lzString from 'lz-string';
import DocumentEditorToolbar from './DocumentEditorToolbar';
import MailContentEditorRows from 'Components/MailContentEditor/MailContentEditorRows';
import MailContentEditorText from 'Components/MailContentEditor/MailContentEditorText';
import MailContentEditorImage from 'Components/MailContentEditor/MailContentEditorImage';
import MailContentEditorPadding from 'Components/MailContentEditor/MailContentEditorPadding';
import MailContentEditorCellSettings from 'Components/MailContentEditor/MailContentEditorCellSettings';
import { ColumnType, RowType, Config } from './DocumentTemplateEditor';
import BemClass from '@upsales/components/Utils/bemClass';
import { SIDEBAR_MAX_FROM_LEFT, SIDEBAR_MIN_WIDTH } from 'Components/MailContentEditor/MailContentEditorHoc';
import DocumentContentEditorEmpty from './DocumentContentEditorEmpty';
import MailContentEditorHeader from 'Components/MailContentEditor/MailContentEditorHeader';
import DocumentContentEditorRowSettings from './DocumentContentEditorRowSettings';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AccountProfile from 'App/resources/Model/AccountProfile';
import logError from 'Helpers/logError';
import NotificationService from 'App/babel/NotificationService';
import DocumentTemplateAvailableTags from './DocumentTemplateAvailableTags';
import { DocumentContentEditorProductTable } from './DocumentContentEditorProductTable';
import { DocumentContentEditorProductTableAdditional } from './DocumentContentEditorProductTableAdditional';
import DocumentTemplatePreview from './DocumentTemplatePreview';
import { generateProductTableColumns } from './DocumentTemplateEditorHelpers';
import StepIntInput from 'Components/Inputs/StepIntInput';

type Props = {
	state: DocumentTemplateState;
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>;
};

type User = {
	email: string;
	id: number;
	name: string;
	role: { name: string; id: number } | null;
};

type SelectedImage = {
	$$previewUrl: string;
	$$publicUrl: string;
	entity: string;
	filename: string;
	id: number;
	labels: Array<string>;
	mimetype: string;
	preventLoad: boolean;
	public: boolean;
	size: number;
	user: User;
	upload: moment.Moment;
	userEditable: boolean;
	userRemovable: boolean;
};

export const findRowIndex = (config: Config, rowId: number) => config.rows.findIndex(r => r.id === rowId);
export const findColIndex = (config: Config, rowIndex: number, colId: number) =>
	config.rows[rowIndex].columns.findIndex(c => c.id === colId);
export const getValueHash = (config: Config) => {
	return lzString.compressToBase64(JSON.stringify(config));
};

export const onEditorChange = (
	state: DocumentTemplateState,
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>,
	newState: {
		config: Config;
		selectedRow?: RowType | undefined;
		selectedColumn?: ColumnType | undefined;
		sidebarVisible?: boolean;
	}
) => {
	const config = newState.config;
	const selectedRow = newState.selectedRow || state.selectedRow;
	const selectedColumn = newState.selectedColumn || state.selectedColumn;
	const sidebarVisible = newState.sidebarVisible !== undefined ? newState.sidebarVisible : state.sidebarVisible;
	const selectedSettingsTab = state.selectedSettingsTab;
	const selectedToolsTab = state.selectedToolsTab;

	const compressed = LZString.compressToBase64(
		JSON.stringify({ config, selectedRow, selectedColumn, sidebarVisible, selectedSettingsTab, selectedToolsTab })
	);
	// Don't push same change again
	if (state.changes[state.currentChange] === compressed) {
		return;
	}
	const prevChanges = state.changes.length ? state.changes.slice(0, state.currentChange + 1) : [];
	let nextChanges;
	if (prevChanges.length < 20) {
		nextChanges = [...prevChanges, compressed];
	} else {
		nextChanges = [...prevChanges.slice(1, prevChanges.length), compressed];
	}
	dispatch({ changes: nextChanges, currentChange: Math.max(0, nextChanges.length - 1) });
};

const getColWidthFromSize = (size: number, manualSize = false) => {
	// This condition is because size 3 and 4 columns both will return width as 173, 70/30 will break line unless we reduce 7o size
	const CONTAINER_WIDTH = 640;
	if (size === 8 && manualSize) {
		return Math.floor((CONTAINER_WIDTH / 12) * size);
	}
	return Math.round((CONTAINER_WIDTH / 12) * size);
};

const getColumnInnerMaxWidth = (column: ColumnType) => {
	return (column.initialFixedWidth || column.width) - column.hPadding * 2;
};

export const DocumentTemplateEditorDesign = ({ state, dispatch }: Props) => {
	const [loadingPreview, setLoadingPreview] = useState<boolean>(true);
	const { t } = useTranslation();
	const lang = {
		availableTags: t('tag.available'),
		general: t('mail.generalSettings'),
		backgroundColor: t('admin.documentTemplateEditor.backgroundColor'),
		useOwnImage: t('mail.useOwnImage'),
		footer: t('admin.documentTemplateEditor.footer'),
		pageMargin: t('admin.documentTemplateEditor.pageMargin'),
		unsubscribeLink: t('mail.unsubscribeLink'),
		column: t('mail.column'),
		row: t('mail.row'),
		image: t('default.image'),
		text: t('mail.text'),
		productTable: t('admin.documentTemplateEditor.productTable'),
		clearColumn: t('mail.clearColumn'),
		allRowsAndCols: t('mailTemplate.allRowsAndCols'),
		settings: t('form.settings'),
		footerLinkTextColor: t('admin.footerLinkTextColor'),
		footerTextColor: t('admin.footerTextColor'),
		fullWidth: t('admin.documentTemplateEditor.headerFullWidth'),
		margin: t('mail.margin'),
		totalRecurringPriceDefaultValue: t(
			'admin.documentTemplateEditor.productTable.totalPriceRecurring.defaultValue'
		),
		totalOneOffPriceDefaultValue: t('admin.documentTemplateEditor.productTable.totalPriceOneOff.defaultValue')
	};
	const classes = new bemClass('DocumentTemplateEditorDesign').mod('open');
	const showAddFirstRow = !state.config.hasFooter && !state.config.hasHeader && !state.config.rows.length;

	const selectColumn = (selectedColumn: ColumnType | undefined, selectedRow?: RowType) => {
		dispatch({
			selectedRow: selectedRow,
			selectedColumn: selectedColumn,
			sidebarVisible: true,
			selectedToolsTab: 'column'
		});
	};

	const getEmptyRow = () => ({
		id: Date.now(),
		style: { self: {} },
		columns: [],
		fullWidth: false,
		backgroundColor: '#ffffff'
	});

	const getImageWidth = (column: ColumnType) => {
		const maxWidth = getColumnInnerMaxWidth(column);
		if (column.imgWidth && column.imgWidth > maxWidth) {
			return maxWidth;
		} else if (column.userImgWidth && column.imgWidth && column.userImgWidth > column.imgWidth) {
			return Math.min(column.userImgWidth, maxWidth);
		}
		return column.imgWidth;
	};

	// Get a column with default values based on type
	const getEmptyCol = (
		row: RowType,
		type: ColumnTypeName = 'EMPTY',
		manualSize = false,
		columnProps = {},
		column?: ColumnType
	) => {
		let colSize;
		if (manualSize && column) {
			colSize = +column.size;
		} else {
			colSize = 12 / row.columns.length;
		}

		let col: ColumnType = {
			id: Date.now(),
			size: colSize,
			type,
			content: '',
			style: {
				self: {
					padding: '10px 10px',
					'padding-left': '10px',
					'padding-right': '10px',
					'padding-top': '10px',
					'padding-bottom': '10px'
				}
			},
			rowId: row.rowId && manualSize ? row.rowId : row.id,
			vPadding: 10,
			hPadding: 10,
			width: getColWidthFromSize(colSize, manualSize),
			imgAlign: '',
			ProductTableTotalPrice: {
				recurring: lang.totalRecurringPriceDefaultValue,
				oneOff: lang.totalOneOffPriceDefaultValue
			}
		};
		switch (type) {
			case 'IMAGE':
				{
					const imgWidth = col.width - col.hPadding - col.vPadding;
					col.imgWidth = imgWidth;
					col.imgWidth = getImageWidth(col);
					col.realImgWidth = imgWidth;
					col.src =
						type === 'IMAGE'
							? `http://placehold.it/${imgWidth}x${imgWidth}`
							: 'https://upsales.wistia.com/medias/8s6lbmg88o';
					col.href = '';
					col.showCaption = false;
					col.caption = '';
					col.captionAlign = 'left';
					col.initialFixedWidth = col.width;
					col.initialImgWidth = getImageWidth(col);
				}
				break;
			case 'TEXT':
				col.src = '';
				col.align = 'left';
				break;
			case 'PRODUCTTABLE':
				col = { ...col, ...generateProductTableColumns() };
				break;
		}

		if (Object.keys(columnProps).length > 0) {
			col = { ...col, ...columnProps };
		}

		return col;
	};

	const onAddRow = (size: string | number, atIndex: number) => {
		const config = { ...state.config };
		const row: RowType = getEmptyRow();

		if (size === '70/30' || size === '30/70') {
			const col70 = getEmptyCol(row);
			const col30 = getEmptyCol(row);
			col30.id = col30.id + 1;
			col70.size = 8;
			col70.width = getColWidthFromSize(col70.size);
			col30.size = 4;
			col30.width = getColWidthFromSize(col30.size);

			if (size === '30/70') {
				row.columns = [col30, col70];
			} else {
				row.columns = [col70, col30];
			}
		} else if (typeof size === 'number') {
			row.columns = Array.from(Array(12 / size)).map((_col, i) => {
				const col = getEmptyCol(row);
				col.id = col.id + i;
				col.size = size;

				return col;
			});
		}

		config.rows = [...config.rows.slice(0, atIndex), row, ...config.rows.slice(atIndex)];

		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedRow: row,
			selectedColumn: row.columns[0],
			sidebarVisible: true,
			shouldScrollToRow: true
		});

		onEditorChange(state, dispatch, {
			config,
			selectedRow: row,
			selectedColumn: row.columns[0],
			sidebarVisible: true
		});
	};

	const reflowColumns = (columns: ColumnType[]) => {
		return columns.map(col => {
			col.size = 12 / columns.length;
			col.width = getColWidthFromSize(col.size);

			if ('IMAGE' === col.type) {
				col.initialFixedWidth = col.width;
				if (!col.userImgWidth) {
					col.userImgWidth = col.imgWidth;
				}
				col.imgWidth = getImageWidth(col);
			}
			return col;
		});
	};

	const onRowDelete = (rowId: number | Object) => {
		let selectedColumn;
		if (typeof rowId !== 'object') {
			selectedColumn = rowId;
		} else if (state.selectedColumn) {
			selectedColumn = state.selectedColumn.rowId;
		}
		const config = { ...state.config };
		const rowIndex = selectedColumn ? findRowIndex(config, selectedColumn) : -1;

		if (rowIndex === -1) {
			return;
		}

		config.rows = [...config.rows.slice(0, rowIndex), ...config.rows.slice(rowIndex + 1)];
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedRow: undefined,
			selectedColumn: undefined,
			sidebarVisible: false
		});
		onEditorChange(state, dispatch, {
			config,
			selectedRow: undefined,
			selectedColumn: undefined,
			sidebarVisible: false
		});
	};

	const openSidebar = () => {
		dispatch({
			sidebarVisible: true,
			selectedRow: undefined,
			selectedColumn: undefined
		});
	};

	const closeSidebar = () => {
		dispatch({
			sidebarVisible: false,
			selectedRow: undefined,
			selectedColumn: undefined
		});
	};

	const onRemoveCol = (rowIndex: number, atIndex: number) => {
		const config: Config = { ...state.config };
		const row: RowType = config.rows[rowIndex];

		// if removing last column in row
		if (row.columns.length === 1) {
			return onRowDelete(row.id);
		}
		row.columns = reflowColumns(removeItem(row.columns, atIndex));
		dispatch({ config: config, hash: getValueHash(config) });
		closeSidebar();
		onEditorChange(state, dispatch, {
			config,
			selectedRow: undefined,
			selectedColumn: undefined,
			sidebarVisible: false
		});
	};

	const onRowCopy = (rowInput: RowType, atIndex: number) => {
		const config = { ...state.config };
		const rowIndex = findRowIndex(config, rowInput.id);
		if (rowIndex === -1) {
			return;
		}

		if (rowInput.columns.some(column => column.type === 'PRODUCTTABLE')) {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: 'admin.documentTemplateEditor.ErrorOnCopy'
			});
			return;
		}

		const row = JSON.parse(JSON.stringify(rowInput));
		row.id = Date.now();
		row.columns.forEach(function setId(column: ColumnType, index: number) {
			column.id = Date.now() + index;
			column.rowId = row.id;
		});

		config.rows = [...config.rows.slice(0, atIndex), row, ...config.rows.slice(atIndex)];
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedRow: row,
			selectedColumn: undefined,
			sidebarVisible: false
		});

		onEditorChange(state, dispatch, { config, selectedRow: row, selectedColumn: undefined, sidebarVisible: false });
	};

	const onAddColumn = (rowIndex: number, atIndex: number) => {
		if (state.config.rows[rowIndex].columns.some(column => column.type === 'PRODUCTTABLE')) {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: 'admin.documentTemplateEditor.ErrorOnAddColumn'
			});
			return;
		}

		try {
			const config = { ...state.config };
			const row = config.rows[rowIndex];
			const col = getEmptyCol(row);
			col.id = Date.now();
			row.columns.splice(atIndex, 0, col);

			row.columns = reflowColumns(row.columns);

			dispatch({
				config: config,
				hash: getValueHash(config),
				selectedRow: row,
				selectedColumn: row.columns[atIndex],
				sidebarVisible: true
			});

			onEditorChange(state, dispatch, {
				config,
				selectedRow: row,
				selectedColumn: row.columns[atIndex],
				sidebarVisible: true
			});
		} catch (e) {
			logError(e, 'Failed to add column');
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: 'mailTemplate.failedToAddColumn'
			});
		}
	};

	const onRowChange = (row: RowType) => {
		// Update column in config
		const config = { ...state.config };
		const rowIndex = findRowIndex(config, row.id);

		if (rowIndex === -1) {
			return;
		}

		config.rows[rowIndex] = { ...config.rows[rowIndex], ...row };

		let newSelectedRow = state.selectedRow;
		if (state.selectedRow && state.selectedRow.id === row.id) {
			newSelectedRow = { ...state.selectedRow, ...row };
		}
		dispatch({ config: config, hash: getValueHash(config), selectedRow: newSelectedRow });

		onEditorChange(state, dispatch, { config, selectedRow: newSelectedRow });
	};

	const onRowClear = () => {
		const config = { ...state.config };
		if (!state.selectedColumn) return;
		const rowIndex = findRowIndex(config, state.selectedColumn.rowId);
		if (rowIndex === -1) {
			return;
		}

		config.rows[rowIndex].backgroundColor = '#ffffff';
		config.rows[rowIndex].fullWidth = false;

		config.rows[rowIndex].columns = config.rows[rowIndex].columns.map(c => {
			const col = getEmptyCol(config.rows[rowIndex]);
			col.id = c.id;
			col.size = c.size;
			return col;
		});

		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: undefined,
			selectedRow: undefined,
			sidebarVisible: false
		});
		onEditorChange(state, dispatch, {
			config,
			selectedRow: undefined,
			selectedColumn: undefined,
			sidebarVisible: false
		});
	};

	const changeColumnType = (type: ColumnTypeName, columnProps = {}) => {
		if (!state.selectedColumn) return;
		if (!state.selectedRow) return;
		// Update column in config
		const config = { ...state.config };
		const rowIndex = findRowIndex(config, state.selectedColumn.rowId);
		if (rowIndex === -1) {
			return;
		}

		const colIndex = findColIndex(config, rowIndex, state.selectedColumn.id);
		if (colIndex === -1) {
			return;
		}
		let column = getEmptyCol(state.selectedRow, type, false, columnProps);
		column.size = state.selectedColumn.size;
		column.width = state.selectedColumn.width;

		if (state.selectedColumn.size === 8 || state.selectedColumn.size === 4) {
			column = getEmptyCol(state.selectedRow, type, true, columnProps, state.selectedColumn);
		}

		config.rows[rowIndex].columns[colIndex] = column;
		dispatch({ config: config, hash: getValueHash(config), selectedColumn: column });
		onEditorChange(state, dispatch, { config, selectedColumn: column });
	};

	let columnChangeTimer: ReturnType<typeof setTimeout> | null = null;
	const onColumnChange = (column: ColumnType) => {
		// Update column in config
		const config = { ...state.config };
		const rowIndex = findRowIndex(config, column.rowId);

		if (rowIndex === -1) {
			return;
		}

		const colIndex = findColIndex(config, rowIndex, column.id);

		if (colIndex === -1) {
			return;
		}

		if (
			column.href &&
			!column.href.match(/{{.*}}/g) &&
			!(
				'http://'.indexOf(column.href.substring(0, 7)) === 0 ||
				'https://'.indexOf(column.href.substring(0, 8)) === 0 ||
				'mailto:'.indexOf(column.href.substring(0, 7)) === 0 ||
				'tel:'.indexOf(column.href.substring(0, 4)) === 0
			)
		) {
			column.href = 'http://' + column.href;
		}

		config.rows[rowIndex].columns[colIndex] = { ...column };
		const newState = { config, hash: getValueHash(config), selectedColumn: state.selectedColumn };

		if (state.selectedColumn && state.selectedColumn.id === column.id) {
			newState.selectedColumn = { ...column };
		}

		dispatch({ config: newState.config, hash: newState.hash, selectedColumn: newState.selectedColumn });

		if (columnChangeTimer) {
			clearTimeout(columnChangeTimer);
		}

		const TYPING_CHARS_PER_MIN = 150; // Average is between 150-200

		columnChangeTimer = setTimeout(() => {
			columnChangeTimer = null;
			onEditorChange(state, dispatch, { config, selectedColumn: newState.selectedColumn });
		}, 1000 / (TYPING_CHARS_PER_MIN / 60)); // Average typing speed is between 150-200 CPS
	};

	const clearColumn = () => {
		if (!state.selectedColumn) return;
		if (!state.selectedRow) return;
		// Update column in config
		const config = { ...state.config };
		const rowIndex = findRowIndex(config, state.selectedColumn.rowId);

		if (rowIndex === -1) {
			return;
		}

		const colIndex = findColIndex(config, rowIndex, state.selectedColumn.id);

		if (colIndex === -1) {
			return;
		}

		const column = getEmptyCol(state.selectedRow, 'EMPTY');
		config.rows[rowIndex].columns[colIndex] = column;
		dispatch({ config: config, hash: getValueHash(config), selectedColumn: column });
		onEditorChange(state, dispatch, { config, selectedColumn: column });
	};

	const columnSelected = !state.selectedColumn;
	const onColumnSettingsChanged = (column: ColumnType) => {
		column.vPadding = parseInt(`${column.vPadding}px`);
		column.hPadding = parseInt(`${column.hPadding}px`);
		column.style.self.padding = `${column.vPadding}px ${column.hPadding}px`;
		column.style.self['padding-left'] = `${column.hPadding}px`;
		column.style.self['padding-right'] = `${column.hPadding}px`;
		column.style.self['padding-top'] = `${column.vPadding}px`;
		column.style.self['padding-bottom'] = `${column.vPadding}px`;

		if (['IMAGE'].indexOf(column.type) !== -1) {
			if (!column.userImgWidth) {
				column.userImgWidth = column.imgWidth;
			}
			column.imgWidth = getImageWidth(column);
		}

		if (column.enableCellBackground === true && !column.backgroundColor) {
			column.backgroundColor = '#ffffff';
		}

		return onColumnChange(column);
	};

	const renderColumnRowSettings = (classes: BemClass) => {
		const selectedColumn = state.selectedColumn;
		if (!selectedColumn) return null;
		const selectedRow = state.selectedRow;
		if (!selectedRow) return;
		let content = null;

		if (state.selectedToolsTab === 'row') {
			content = (
				<DocumentContentEditorRowSettings
					state={state}
					row={selectedRow}
					onChange={(row: RowType) => onRowChange(row)}
					onDelete={(rowId: number | Object) => onRowDelete(rowId)}
					onClear={() => onRowClear()}
				/>
			);
		} else if (state.selectedToolsTab === 'tags') {
			content = (
				<DocumentTemplateAvailableTags isProductTable={selectedColumn.type === 'PRODUCTTABLE' ? true : false} />
			); // Some tags are only works for product table
		} else if (selectedColumn.type === 'EMPTY') {
			content = (
				<DocumentContentEditorEmpty state={state} onSelect={(type: ColumnTypeName) => changeColumnType(type)} />
			);
		} else {
			const baseProps = {
				column: selectedColumn,
				onChange: (column: ColumnType) => onColumnChange(column),
				accountProfile: state.accountProfile
			};
			let tools = null;
			let title = null;
			switch (selectedColumn.type) {
				case 'IMAGE':
					title = lang.image;
					tools = (
						<MailContentEditorImage
							{...baseProps}
							maxWidth={getColumnInnerMaxWidth(selectedColumn)}
							isVideo={false}
						/>
					);
					break;
				case 'TEXT':
					title = lang.text;
					tools = <MailContentEditorText {...baseProps} />;
					break;
				case 'PRODUCTTABLE':
					title = lang.productTable;
					tools = (
						<DocumentContentEditorProductTable
							state={state}
							dispatch={dispatch}
							data-testid="product-table-button"
						/>
					);
					break;
			}
			content = (
				<div>
					<div className={classes.elem('sidebar-section').b()}>
						<Row className={classes.elem('sidebar-header').b()}>
							<Column>
								<Headline size="xs">{title}</Headline>
							</Column>
							<Column align="right">
								<Button type="link" color="grey" onClick={() => clearColumn()}>
									{lang.clearColumn}
								</Button>
							</Column>
						</Row>
						{tools}
					</div>
					{selectedColumn.type === 'PRODUCTTABLE' ? (
						<div className={classes.elem('sidebar-section').b()}>
							<DocumentContentEditorProductTableAdditional state={state} dispatch={dispatch} />
						</div>
					) : null}
					<MailContentEditorPadding
						column={selectedColumn}
						onChange={column => onColumnSettingsChanged(column)}
					/>
					<MailContentEditorCellSettings
						column={selectedColumn}
						onChange={column => onColumnSettingsChanged(column)}
						accountProfile={state.accountProfile}
					/>
				</div>
			);
		}

		return (
			<div className={classes.elem('sidebar-outer').b()}>
				<Tabs selected={state.selectedToolsTab} onChange={selectedToolsTab => dispatch({ selectedToolsTab })}>
					<Tab id="column">{lang.column}</Tab>
					<Tab id="row">{lang.row}</Tab>
					<Tab id="tags">{lang.availableTags}</Tab>
				</Tabs>
				<div className={classes.elem('sidebar-inner').b()}>{content}</div>
			</div>
		);
	};

	const selectFromRowsPanel = (selectedColumn: ColumnType, selectedRow: RowType) => {
		let selectedToolsTab;
		if (selectedColumn) {
			selectedToolsTab = 'column';
		} else {
			selectedColumn = selectedRow.columns[0];
			selectedToolsTab = 'row';
		}
		dispatch({
			selectedSettingsTab: 'settings',
			selectedRow: selectedRow,
			selectedToolsTab: selectedToolsTab,
			selectedColumn: selectedColumn
		});
	};

	const setHovered = (hoveredColumn: ColumnType, hoveredRow: RowType) => {
		dispatch({ hoveredColumn: hoveredColumn, hoveredRow: hoveredRow });
	};

	const onRowMove = (sourceIndex: number, destinationIndex: number) => {
		const config = { ...state.config };
		const [removed] = config.rows.splice(sourceIndex, 1);
		config.rows.splice(destinationIndex, 0, removed);
		dispatch({ config: config, hash: getValueHash(config) });
		onEditorChange(state, dispatch, { config });
	};

	const onColMove = (
		rowDroppableSourceId: string,
		rowDroppableDestinationId: string,
		sourceIndex: number,
		destinationIndex: number
	) => {
		const config = { ...state.config };

		// Find source row index
		const sourceRowIndex = config.rows.findIndex(
			r => r.id.toString() === rowDroppableSourceId.replace('col-droppable-', '')
		);

		// Find destination row index
		const destinationRowIndex = config.rows.findIndex(
			r => r.id.toString() === rowDroppableDestinationId.replace('col-droppable-', '')
		);
		// If dropped in the same spot as it was taken from'
		if (sourceRowIndex === destinationRowIndex && sourceIndex === destinationIndex) {
			return;
		}

		// Blocking moving cells with a product table since they need the width 100% to work and therefore can't be moved if the whole row is not moved
		if (config.rows[destinationRowIndex].columns.find(obj => obj.type === 'PRODUCTTABLE')) {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: 'admin.documentTemplateEditor.ErrorOnMoveTo'
			});

			return;
		}

		// Blocking moving cells with a product table since they need the width 100% to work and therefore can't be moved if the whole row is not moved
		if (config.rows[sourceRowIndex].columns.find(obj => obj.type === 'PRODUCTTABLE')) {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'default.error',
				body: 'admin.documentTemplateEditor.ErrorOnMoveFrom'
			});
			return;
		}

		// Remove column from current place
		const [columnConfig] = config.rows[sourceRowIndex].columns.splice(sourceIndex, 1);

		// If dropped in another row we need to set reference to the new row
		if (sourceRowIndex !== destinationRowIndex) {
			columnConfig.rowId = config.rows[destinationRowIndex].id;

			// Also if this column is selected we need to update selected column and row
			selectColumn(columnConfig, config.rows[destinationRowIndex]);
		}
		// Place it in the new spot
		config.rows[destinationRowIndex].columns.splice(destinationIndex, 0, columnConfig);

		// Reflow source row if not empty
		if (config.rows[sourceRowIndex].columns.length) {
			config.rows[sourceRowIndex].columns = reflowColumns(config.rows[sourceRowIndex].columns);
		}

		// Reflow destination row if not same as source
		if (sourceRowIndex !== destinationRowIndex) {
			config.rows[destinationRowIndex].columns = reflowColumns(config.rows[destinationRowIndex].columns);
		}

		// if source row was left empty we remove it, this has to be done after all reflows to keep indexes
		if (!config.rows[sourceRowIndex].columns.length) {
			config.rows.splice(sourceRowIndex, 1);
		}

		dispatch({ config: config, hash: getValueHash(config) });
		onEditorChange(state, dispatch, { config });
	};

	const configChanged = async (setting: string, value: string | boolean) => {
		const config = { ...state.config, [setting]: value };
		dispatch({ config: config, hash: getValueHash(config) });
		onEditorChange(state, dispatch, { config });
	};

	const changeLogoType = (isLogo: boolean) => {
		let logoType = 'url';
		let headerLogo = '';
		if (isLogo) {
			logoType = 'logo';
			headerLogo = 'logo';
		}
		const config = { ...state.config, logoType, headerLogo };
		dispatch({ config: config, hash: getValueHash(config) });
		onEditorChange(state, dispatch, { config });
	};

	const saveProfileLogo = (logo: string) => {
		// Is used to save a profile logo if there is no logo on the accountProfile
		const $upModal = getAngularModule('$upModal');
		const filePromise = $upModal
			.open<SelectedImage[]>('fileBrowser', {
				types: ['image'],
				public: true,
				selectOnUpload: true
			})
			.then(selectedImages => {
				const accountProfile: AccountProfile | null = state.accountProfile;

				if (accountProfile) {
					if (logo === '__brightLogo__') {
						accountProfile.logo = selectedImages[0].$$publicUrl;
					} else if (logo === '__darkLogo__') {
						accountProfile.darkLogo = selectedImages[0].$$publicUrl;
					}
				}
				if (accountProfile) {
					return Tools.AccountProfile.save(accountProfile);
				} else {
					NotificationService.add({
						icon: 'times',
						style: NotificationService.style.ERROR,
						title: 'default.error',
						body: 'documentTemplate.failedToSaveProfileLogo'
					});
				}
			})
			.catch(e => {
				console.error('saveProfileLogo on open', e);
			});
		filePromise
			.then(() => {
				const config = { ...state.config, logoType: 'logo', headerLogo: logo };
				dispatch({ config: config, hash: getValueHash(config) });
				onEditorChange(state, dispatch, { config });
			})
			.catch(e => console.error('saveProfileLogo filePromise', e));
	};

	useEffect(() => {
		setLoadingPreview(false);
	}, []);

	useEffect(() => {
		if (showAddFirstRow) {
			closeSidebar();
			onEditorChange(state, dispatch, {
				config: state.config,
				selectedRow: undefined,
				selectedColumn: undefined,
				sidebarVisible: false
			});
		}
	}, [state.currentChange]);
	return (
		<div className={classes.b()} data-testid="DocumentTemplateEditorDesign">
			<DocumentEditorToolbar state={state} dispatch={dispatch} />
			<EditorWrap
				sidebarVisible={state.sidebarVisible}
				sidebarDisabled={false}
				sidebarMinWidth={SIDEBAR_MIN_WIDTH}
				sidebarMaxWidth={SIDEBAR_MAX_FROM_LEFT}
				onOpen={() => openSidebar()}
				onClose={() => closeSidebar()}
				renderMain={() => (
					<div className={classes.elem('preview-container').b()}>
						{loadingPreview ? (
							<Loader className={classes.elem('preview-loader').b()} size="sm" />
						) : (
							<div className={classes.elem('preview-editor').b()}>
								<DocumentTemplatePreview
									data-testid="MailTemplatePreview"
									className={classes
										.elem('preview')
										.mod(showAddFirstRow ? 'hidden' : '')
										.b()}
									config={state.config}
									accountProfile={state.accountProfile}
									onSelectColumn={(selectedColumn, selectedRow = undefined) =>
										selectColumn(selectedColumn, selectedRow)
									}
									selectedColumn={state.selectedColumn}
									selectedRow={state.selectedRow}
									sidebarVisible={state.sidebarVisible}
									hoveredColumn={state.hoveredColumn}
									hoveredRow={state.hoveredRow}
									onAddRow={(size: string | number, index: number) => onAddRow(size, index)}
									onAddColumn={(rowIndex: number, atIndex: number) => onAddColumn(rowIndex, atIndex)}
									onRemoveCol={onRemoveCol}
									onRowMove={(sourceIndex: number, destinationIndex: number) =>
										onRowMove(sourceIndex, destinationIndex)
									}
									onColMove={(
										rowDroppableSourceId: string,
										rowDroppableDestinationId: string,
										sourceIndex: number,
										destinationIndex: number
									) =>
										onColMove(
											rowDroppableSourceId,
											rowDroppableDestinationId,
											sourceIndex,
											destinationIndex
										)
									}
									onColumnChange={(column: ColumnType) => onColumnChange(column)}
									onDelete={(rowId: number | Object) => onRowDelete(rowId)}
									onCopy={(rowInput: RowType, atIndex: number) => onRowCopy(rowInput, atIndex)}
								/>
							</div>
						)}
					</div>
				)}
				renderSidebar={() => (
					<div className={classes.elem('sidebar').b()}>
						{columnSelected ? (
							<div className={classes.elem('sidebar-outer').b()}>
								<Tabs
									selected={state.selectedSettingsTab}
									onChange={selectedSettingsTab =>
										dispatch({ selectedSettingsTab: selectedSettingsTab })
									}
								>
									<Tab id="settings">{lang.settings}</Tab>
									<Tab id="rows" data-testid="rowTab">
										{lang.allRowsAndCols}
									</Tab>
									<Tab id="tags">{lang.availableTags}</Tab>
								</Tabs>
								{state.selectedSettingsTab === 'settings' ? (
									<div>
										<div className={classes.elem('sidebar-section').b()}>
											<Headline size="xs">{lang.general}</Headline>
											<Row className={classes.elem('sidebar-section-input-row').b()}>
												<Column>
													<Label>{lang.backgroundColor}</Label>
												</Column>
												<Column align="right" data-testid="colorpicker">
													<ReactTemplates.bannerEditor.colorPicker
														value={state.config.bodyBg}
														presets={state.accountProfile?.colorVariables || []}
														onChange={color => configChanged('bodyBg', color)}
													/>
												</Column>
											</Row>
										</div>
										<MailContentEditorHeader
											config={state.config}
											configChanged={configChanged}
											changeLogoType={changeLogoType}
											saveProfileLogo={saveProfileLogo}
											accountProfile={state.accountProfile}
											langFullWidth={lang.fullWidth}
										/>
										<div className={classes.elem('sidebar-section').b()}>
											<Row className={classes.elem('sidebar-section-input-row').b()}>
												<Column>
													<Headline size="xs">{lang.footer}</Headline>
												</Column>
												<Column align="right" data-testid="document-footer-toggle">
													<Toggle
														color="medium-green"
														checked={state.config.hasFooter}
														onChange={val => configChanged('hasFooter', val)}
													/>
												</Column>
											</Row>
											{state.config.hasFooter ? (
												<div>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{lang.footerTextColor}</Label>
														</Column>
														<Column align="right">
															<ReactTemplates.bannerEditor.colorPicker
																value={state.config.footerTextColor}
																presets={
																	(state.accountProfile &&
																		state.accountProfile.colorVariables) ||
																	[]
																}
																onChange={color =>
																	configChanged('footerTextColor', color)
																}
															/>
														</Column>
													</Row>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{lang.footerLinkTextColor}</Label>
														</Column>
														<Column align="right">
															<ReactTemplates.bannerEditor.colorPicker
																value={state.config.footerLinkTextColor}
																presets={
																	(state.accountProfile &&
																		state.accountProfile.colorVariables) ||
																	[]
																}
																onChange={color =>
																	configChanged('footerLinkTextColor', color)
																}
															/>
														</Column>
													</Row>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{lang.margin}</Label>
														</Column>
														<Column align="right">
															<StepIntInput
																value={state.config.footerMargin}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
																	configChanged('footerMargin', e.target.value)
																}
																size="sm"
															/>
														</Column>
													</Row>
												</div>
											) : null}
										</div>
										<div className={classes.elem('sidebar-section').b()}>
											<Row className={classes.elem('sidebar-section-input-row').b()}>
												<Column>
													<Headline size="xs">{lang.pageMargin}</Headline>
												</Column>
												<Column align="right" data-testid="document-page-margin-toggle">
													<Toggle
														color="medium-green"
														checked={state.config.hasPageMargin}
														onChange={val => configChanged('hasPageMargin', val)}
													/>
												</Column>
											</Row>
											{state.config.hasPageMargin ? (
												<div>
													<Row className={classes.elem('sidebar-section-input-row').b()}>
														<Column>
															<Label>{lang.margin}</Label>
														</Column>
														<Column align="right">
															<StepIntInput
																value={state.config.pageMargin}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
																	configChanged('pageMargin', e.target.value)
																}
																size="sm"
																data-testid="page-margin-step-input"
															/>
														</Column>
													</Row>
												</div>
											) : null}
										</div>
									</div>
								) : state.selectedSettingsTab === 'rows' ? (
									<MailContentEditorRows
										rows={state.config.rows}
										onSelect={(selectedColumn: ColumnType, selectedRow: RowType) =>
											selectFromRowsPanel(selectedColumn, selectedRow)
										}
										setHovered={(hoveredColumn: ColumnType, hoveredRow: RowType) =>
											setHovered(hoveredColumn, hoveredRow)
										}
									/>
								) : (
									<div className={classes.elem('sidebar-inner').b()}>
										<DocumentTemplateAvailableTags />
									</div>
								)}
							</div>
						) : (
							renderColumnRowSettings(classes)
						)}
					</div>
				)}
			></EditorWrap>
		</div>
	);
};

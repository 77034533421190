import React from 'react';
import T from 'Components/Helpers/translate';
import { Text } from '@upsales/components';

import './SalesProcessAddonHint.scss';
import BemClass from '@upsales/components/Utils/bemClass';

interface SalesProcessAddonHintProps {}

const SalesProcessAddonHint: React.FC<SalesProcessAddonHintProps> = () => {
	const classNames = new BemClass('SalesProcessAddonHint');
	const isAvailable = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SALES_PROCESS_PRO);

	const url = 'https://support.upsales.com/hc/en-us/articles/360013333437';
	const lang = {
		prefix: T('admin.salesProcess.hint.prefix'),
		addon: T('admin.salesProcess.hint.name'),
		suffix: T('admin.salesProcess.hint.suffix')
	};

	return !isAvailable ? (
		<div className={classNames.b()}>
			<Text size="md" color="grey-11">
				{lang.prefix}{' '}
				<a href={url} target="_blank">
					{lang.addon}
				</a>{' '}
				{lang.suffix}
			</Text>
		</div>
	) : null;
};

export default SalesProcessAddonHint;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, ButtonGroup, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import './AdvancedSearchFooter.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import entityName from 'Components/Helpers/EntityName';

const getTypeLang = (type, count) => {
	const fixedType =
		type === 'activity' &&
		Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
		Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			? 'task'
			: type;

	return T(entityName(fixedType, count)).toLowerCase();
};

const numberFilter = num => Tools.$filter('number')(num);

const AdvancedSearchFooter = ({ loading, setResultType, viewLoading, searchView, numResults, onPlanPhoneCalls }) => {
	const hasTodo =
		Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && ['ACCOUNT', 'CONTACT'].indexOf(searchView.type) !== -1;
	const classes = new bemClass('AdvancedSearchFooter');
	const btns = viewLoading
		? []
		: searchView.resultTypes.map(type => {
				return (
					<Button
						shadow="none"
						loading={loading}
						key={type}
						type={type !== searchView.resultsAs ? 'lined' : null}
						disabled={loading}
						onClick={() => setResultType(type)}
					>
						{`${T('default.show')} ${numberFilter(numResults[type])} ${getTypeLang(
							type.toLowerCase(),
							numResults[type]
						)}`}
					</Button>
				);
		  });

	return (
		<Card className={classes.b()}>
			<ButtonGroup>{btns}</ButtonGroup>
			{hasTodo && !viewLoading && numResults[searchView.resultsAs] ? (
				<Button onClick={onPlanPhoneCalls} type="link">
					<Icon name="phone" space="mrs" />
					{T('todo.planPhonecalls')}
				</Button>
			) : null}
		</Card>
	);
};

AdvancedSearchFooter.propTypes = {
	loading: PropTypes.bool,
	numResults: PropTypes.object,
	onPlanPhoneCalls: PropTypes.func,
	setResultType: PropTypes.func.isRequired,
	viewLoading: PropTypes.bool,
	searchView: PropTypes.shape({
		resultTypes: PropTypes.arrayOf(PropTypes.string),
		resultsAs: PropTypes.string
	})
};

export default AdvancedSearchFooter;

import React from 'react';
import { Card, TableColumn, TableRow, Table, CardHeader, TableHeader, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import t from 'Components/Helpers/translate';

import './FinancialCard.scss';

type Props = {
	showPlaceholder: boolean;
	years: number[];
	currency: string;
	type: 'incomeStatement' | 'balanceSheet' | 'keyFigures';
	accounts: { [key: string]: number }[];
	reference: React.RefObject<HTMLDivElement>;
};

type MappedKeys = { label?: string; key?: string; title?: string };

class FinancialCard extends React.Component<Props> {
	years: number[];
	columns: { title: string }[];
	mappedKeys: {
		keyFigures: MappedKeys[];
		incomeStatement: MappedKeys[];
		balanceSheet: MappedKeys[];
	};
	lang: { [key: string]: string };
	constructor(props: Props) {
		super(props);
		const currentYear = moment().year();
		this.lang = {
			keyFigures: t('financials.keyFigures'),
			incomeStatement: t('financials.incomeStatement'),
			balanceSheet: t('financials.balanceSheet'),
			proRequired: t('financials.proRequired'),
			purchaseDescription: t('financials.purchaseDescription'),
			unit: t(`financials.unit${this.props.currency}`)
		};
		this.years = this.props.showPlaceholder
			? [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4]
			: this.props.years;
		this.columns = this.years.map(year => ({ title: year.toString() }));
		while (this.columns.length < 5) {
			this.columns.push({
				title: moment(this.columns[this.columns.length - 1].title + '-01-01')
					.subtract(1, 'year')
					.year()
					.toString()
			});
		}
		this.mappedKeys = {
			keyFigures: [
				{
					label: t('financials.card.krReturnOnCapitalPercent', { unit: this.lang.unit }),
					key: 'krReturnOnCapitalPercent'
				},
				{
					label: t('financials.card.krReturnOnCapEmpPercent', { unit: this.lang.unit }),
					key: 'krReturnOnCapEmpPercent'
				},
				{
					label: t('financials.card.krAvgDebtEqRatioPercent', { unit: this.lang.unit }),
					key: 'krAvgDebtEqRatioPercent'
				},
				{
					label: t('financials.card.krRiskBuffer', { unit: this.lang.unit }),
					key: 'krRiskBuffer'
				},
				{
					label: t('financials.card.krEbitda', { unit: this.lang.unit }),
					key: 'krEbitda'
				},
				{
					label: t('financials.card.krInterestCoverageEbitda', { unit: this.lang.unit }),
					key: 'krInterestCoverageEbitda'
				},
				{
					label: t('financials.card.krGrossProfitMarginPercent', { unit: this.lang.unit }),
					key: 'krGrossProfitMarginPercent'
				},
				{
					label: t('financials.card.krSolidityPercent', { unit: this.lang.unit }),
					key: 'krSolidityPercent'
				},
				{
					label: t('financials.card.krCapitalTurnoverTimes', { unit: this.lang.unit }),
					key: 'krCapitalTurnoverTimes'
				},
				{
					label: t('financials.card.krWorkingCapital', { unit: this.lang.unit }),
					key: 'krWorkingCapital'
				},
				{
					label: t('financials.card.krQuickRatioPercent', { unit: this.lang.unit }),
					key: 'krQuickRatioPercent'
				},
				{
					label: t('financials.card.krChangeInTurnoverPercent', { unit: this.lang.unit }),
					key: 'krChangeInTurnoverPercent'
				},
				{
					label: t('financials.card.krDegreeOfDebt', { unit: this.lang.unit }),
					key: 'krDegreeOfDebt'
				},
				{
					label: t('financials.card.krRateOfReturnTimes', { unit: this.lang.unit }),
					key: 'krRateOfReturnTimes'
				},
				{
					label: t('financials.card.krReturnOnOpCapital', { unit: this.lang.unit }),
					key: 'krReturnOnOpCapital'
				},
				{
					label: t('financials.card.krRiskBufferOnOpCapPerc', { unit: this.lang.unit }),
					key: 'krRiskBufferOnOpCapPerc'
				},
				{
					label: t('financials.card.krInventoryTurnoverTimes', { unit: this.lang.unit }),
					key: 'krInventoryTurnoverTimes'
				},
				{
					label: t('financials.card.krDuPontModelPercent', { unit: this.lang.unit }),
					key: 'krDuPontModelPercent'
				}
			],
			incomeStatement: [
				{
					label: t('financials.card.plNetSales', { unit: this.lang.unit }),
					key: 'plNetSales'
				},
				{
					label: t('financials.card.plRawMatAndCons', { unit: this.lang.unit }),
					key: 'plRawMatAndCons'
				},
				{
					label: t('financials.card.plGoodsForResale', { unit: this.lang.unit }),
					key: 'plGoodsForResale'
				},
				{
					label: t('financials.card.plPersonalCosts', { unit: this.lang.unit }),
					key: 'plPersonalCosts'
				},
				{
					label: t('financials.card.plOtherExtCosts', { unit: this.lang.unit }),
					key: 'plOtherExtCosts'
				},
				{
					label: t('financials.card.plOperatingResult', { unit: this.lang.unit }),
					key: 'plOperatingResult'
				},
				{
					label: t('financials.card.plResultParticipationGroup', { unit: this.lang.unit }),
					key: 'plResultParticipationGroup'
				},
				{
					label: t('financials.card.plExtInterestInc', { unit: this.lang.unit }),
					key: 'plExtInterestInc'
				},
				{
					label: t('financials.card.plInterestExpExt', { unit: this.lang.unit }),
					key: 'plInterestExpExt'
				},
				{
					label: t('financials.card.plOtherFinInc', { unit: this.lang.unit }),
					key: 'plOtherFinInc'
				},
				{
					label: t('financials.card.plProfLossAfterFinItems', { unit: this.lang.unit }),
					key: 'plProfLossAfterFinItems'
				},
				{
					label: t('financials.card.plGroupContrib', { unit: this.lang.unit }),
					key: 'plGroupContrib'
				},
				{
					label: t('financials.card.plShareholderContrib', { unit: this.lang.unit }),
					key: 'plShareholderContrib'
				},
				{
					label: t('financials.card.plOtherAppropriations', { unit: this.lang.unit }),
					key: 'plOtherAppropriations'
				},
				{
					label: t('financials.card.plTax', { unit: this.lang.unit }),
					key: 'plTax'
				},
				{
					label: t('financials.card.plNetProfitLoss', { unit: this.lang.unit }),
					key: 'plNetProfitLoss'
				}
			],
			balanceSheet: [
				{
					title: t('financials.card.fixedAssets')
				},
				{
					label: t('financials.card.bsPatents', { unit: this.lang.unit }),
					key: 'bsPatents'
				},
				{
					label: t('financials.card.bsLandBuild', { unit: this.lang.unit }),
					key: 'bsLandBuild'
				},
				{
					label: t('financials.card.bsPlantMachine', { unit: this.lang.unit }),
					key: 'bsPlantMachine'
				},
				{
					label: t('financials.card.bsEquipToolsFixAndFit', { unit: this.lang.unit }),
					key: 'bsEquipToolsFixAndFit'
				},
				{
					label: t('financials.card.bsMachinesInventory', { unit: this.lang.unit }),
					key: 'bsMachinesInventory'
				},
				{
					label: t('financials.card.bsOtherTangAssNonDep', { unit: this.lang.unit }),
					key: 'bsOtherTangAssNonDep'
				},
				{
					label: t('financials.card.bsOtherMatDepreciation', { unit: this.lang.unit }),
					key: 'bsOtherMatDepreciation'
				},
				{
					label: t('financials.card.bsTotalTangibleAssets', { unit: this.lang.unit }),
					key: 'bsTotalTangibleAssets'
				},
				{
					label: t('financials.card.bsGroupShare', { unit: this.lang.unit }),
					key: 'bsGroupShare'
				},
				{
					label: t('financials.card.bsAccRecieveCorpGroup', { unit: this.lang.unit }),
					key: 'bsAccRecieveCorpGroup'
				},
				{
					label: t('financials.card.bsOtherFinAssets', { unit: this.lang.unit }),
					key: 'bsOtherFinAssets'
				},
				{
					label: t('financials.card.bsTotalFinancialAssets', { unit: this.lang.unit }),
					key: 'bsTotalFinancialAssets'
				},
				{
					label: t('financials.card.bsTotFixAssets', { unit: this.lang.unit }),
					key: 'bsTotFixAssets'
				},
				{
					title: t('financials.card.inventories')
				},
				{
					label: t('financials.card.bsAccRecieveGroup', { unit: this.lang.unit }),
					key: 'bsAccRecieveGroup'
				},
				{
					label: t('financials.card.bsOtherAccRecieve', { unit: this.lang.unit }),
					key: 'bsOtherAccRecieve'
				},
				{
					label: t('financials.card.bsTotalAccountsReceivable', { unit: this.lang.unit }),
					key: 'bsTotalAccountsReceivable'
				},
				{
					label: t('financials.card.bsShortInvestments', { unit: this.lang.unit }),
					key: 'bsShortInvestments'
				},
				{
					label: t('financials.card.bsCashAndBankBalances', { unit: this.lang.unit }),
					key: 'bsCashAndBankBalances'
				},
				{
					label: t('financials.card.bsTotalTurnoverAssets', { unit: this.lang.unit }),
					key: 'bsTotalTurnoverAssets'
				},
				{
					title: t('financials.card.equity')
				},
				{
					label: t('financials.card.bsIssuedShareCapital', { unit: this.lang.unit }),
					key: 'bsIssuedShareCapital'
				},
				{
					label: t('financials.card.bsOtherStockholderEquity', { unit: this.lang.unit }),
					key: 'bsOtherStockholderEquity'
				},
				{
					label: t('financials.card.bsProfitLossBroughtFwd', { unit: this.lang.unit }),
					key: 'bsProfitLossBroughtFwd'
				},
				{
					label: t('financials.card.bsShareholderContribution', { unit: this.lang.unit }),
					key: 'bsShareholderContribution'
				},
				{
					label: t('financials.card.bsNetProfitLoss', { unit: this.lang.unit }),
					key: 'bsNetProfitLoss'
				},
				{
					label: t('financials.card.bsTotalDividensCapacity', { unit: this.lang.unit }),
					key: 'bsTotalDividensCapacity'
				},
				{
					label: t('financials.card.bsTotEquity', { unit: this.lang.unit }),
					key: 'bsTotEquity'
				},
				{
					title: t('financials.card.longTermLiabilities')
				},
				{
					label: t('financials.card.bsLtLiabToCreditInstit', { unit: this.lang.unit }),
					key: 'bsLtLiabToCreditInstit'
				},
				{
					label: t('financials.card.bsLtLiabGroupAssComp', { unit: this.lang.unit }),
					key: 'bsLtLiabGroupAssComp'
				},
				{
					label: t('financials.card.bsOtherLongTermLiab', { unit: this.lang.unit }),
					key: 'bsOtherLongTermLiab'
				},
				{
					label: t('financials.card.bsTotLongTermDebts', { unit: this.lang.unit }),
					key: 'bsTotLongTermDebts'
				},
				{
					title: t('financials.card.currentLiabilites')
				},
				{
					label: t('financials.card.bsLiabCreditInst', { unit: this.lang.unit }),
					key: 'bsLiabCreditInst'
				},
				{
					label: t('financials.card.bsAccPayTrade', { unit: this.lang.unit }),
					key: 'bsAccPayTrade'
				},
				{
					label: t('financials.card.bsStLiabGroupAssComp', { unit: this.lang.unit }),
					key: 'bsStLiabGroupAssComp'
				},
				{
					label: t('financials.card.bsOtherShortTermLiab', { unit: this.lang.unit }),
					key: 'bsOtherShortTermLiab'
				},
				{
					label: t('financials.card.bsTotCurrentLiabilities', { unit: this.lang.unit }),
					key: 'bsTotCurrentLiabilities'
				},
				{
					label: t('financials.card.bsTotEquityAndLiab', { unit: this.lang.unit }),
					key: 'bsTotEquityAndLiab'
				}
			]
		};
	}
	generateRows() {
		const rows = [];
		let zebraStripes = false;
		let beforeIsTitle = false;
		for (const entery of this.mappedKeys[this.props.type]) {
			if (entery.title) {
				if (beforeIsTitle) {
					rows.pop();
				}
				beforeIsTitle = true;
				rows.push(
					<TableRow
						key={this.props.type + rows.length}
						className={zebraStripes ? 'FinancialCard__zebraStripes' : ''}
					>
						<TableColumn key={this.props.type + rows.length} size="lg" title={entery.title}></TableColumn>
						{this.columns.map(column => (
							<TableColumn key={column.title + entery.title} />
						))}
					</TableRow>
				);
				zebraStripes = !zebraStripes;
				continue;
			}
			const column = [];
			column.push(
				<TableColumn key={(entery?.key || '') + column.length} className={'FinancialCard__tableColumnTitle'}>
					{entery.label}
				</TableColumn>
			);
			let onlyZeros = true;
			for (const account of this.props.accounts) {
				if (!entery.key || account[entery.key] === null || account[entery.key] === undefined) {
					continue;
				}
				if (account[entery.key] !== 0) {
					onlyZeros = false;
				}
				const formattedNumber =
					this.props.currency === 'GBP' &&
					!['krProfitMarginPercent', 'krSolidityPercent', 'krQuickRatioPercent'].includes(entery.key)
						? Tools.$filter('numberFormat')(account[entery.key], false, 0)
						: Tools.$filter('numberFormat')(account[entery.key]);

				column.push(
					<TableColumn key={(entery?.key || '') + column.length}>
						<Text size="sm">{formattedNumber}</Text>
					</TableColumn>
				);
			}
			if (onlyZeros) {
				continue;
			}
			while (column.length < 6) {
				column.push(<TableColumn key={(entery?.key || '') + column.length} />);
			}
			beforeIsTitle = false;
			rows.push(
				<TableRow
					key={this.props.type + rows.length}
					className={zebraStripes ? 'FinancialCard__zebraStripes' : ''}
				>
					{column}
				</TableRow>
			);
			zebraStripes = !zebraStripes;
		}
		if (beforeIsTitle) {
			rows.pop();
		}
		return rows;
	}

	generateFakeRows() {
		const rows = [];
		for (const entery of this.mappedKeys[this.props.type].slice(0, 3)) {
			if (entery.title) {
				rows.push(
					<TableRow key={this.props.type + rows.length}>
						<TableColumn key={this.props.type + rows.length} size="lg" title={entery.title}></TableColumn>
					</TableRow>
				);
				continue;
			}
			const column = [];
			column.push(
				<TableColumn key={entery?.key || '' + column.length} className={'FinancialCard__tableColumnTitle'}>
					{entery.label}
				</TableColumn>
			);
			for (const year of this.years) {
				column.push(
					<TableColumn key={entery?.key || '' + column.length + year}>
						<Text size="sm">{Math.floor(Math.random() * 100)}</Text>
					</TableColumn>
				);
			}
			rows.push(<TableRow key={this.props.type + rows.length}>{column}</TableRow>);
		}
		rows.push(
			<TableRow key={this.props.type + rows.length}>
				<TableColumn key={this.props.type + 'last'}> </TableColumn>
			</TableRow>
		);
		return rows;
	}

	render() {
		const classNames = new bemClass('FinancialCard');
		return (
			<div className={classNames.b()} ref={this.props.reference}>
				{this.props.showPlaceholder ? (
					<div>
						<div className={classNames.elem('fadeCard').b()}>
							<Card className={classNames.elem('keyFiguresContainerLowZ').b()}>
								<CardHeader
									key={'header' + this.props.type}
									size="md"
									title={
										this.props.type === 'incomeStatement' || this.props.type === 'balanceSheet'
											? this.lang[this.props.type] + ' ' + this.lang.unit
											: this.lang[this.props.type]
									}
								/>
								<CardHeader
									key={'header' + this.props.type}
									size="md"
									title={
										this.props.type === 'incomeStatement' || this.props.type === 'balanceSheet'
											? this.lang[this.props.type] + ' ' + this.lang.unit
											: this.lang[this.props.type]
									}
								/>
								<Table>
									<TableHeader
										key={'title' + this.props.type}
										columns={[{ title: '' }, ...this.columns]}
									/>
									{this.generateFakeRows()}
								</Table>
							</Card>
						</div>
					</div>
				) : (
					<Card className={classNames.elem('keyFiguresContainer').b()}>
						<CardHeader
							key={'header' + this.props.type}
							size="md"
							title={
								this.props.type === 'incomeStatement' || this.props.type === 'balanceSheet'
									? this.lang[this.props.type] + ' ' + this.lang.unit
									: this.lang[this.props.type]
							}
						/>
						<Table>
							<TableHeader key={'title' + this.props.type} columns={[{ title: '' }, ...this.columns]} />
							{this.generateRows()}
						</Table>
					</Card>
				)}
			</div>
		);
	}
}

export default FinancialCard;

'use strict';
import Looker from 'Components/Admin/Looker';

angular.module('domain.admin').controller('LookerAdmin', [
	'AppService',
	'$upModal',
	'$scope',
	'$q',
	'LookerDashboard',
	'ClientParam',
	'$state',
	function (AppService, $upModal, $scope, $q, LookerDashboard, ClientParam, $state) {
		var AdminSubCtrl = this;

		// AdminSubCtrl.rootData = AdminCtrl.getRootObject(); // USE THIS LATER
		AdminSubCtrl.rootData = {
			pageLoading: true,
			saving: false,
			saveVisible: false,
			pageComponent: Looker,
			pageData: null,
			getUrlFromState: function (stateName, params) {
				return $state.href(stateName, params || {});
			},
			onChange: function () {},
			onSave: function () {}
		};

		AdminSubCtrl.rootData.pageComponent = Looker;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			dashboards: [],
			exploreAccess: [],
			users: [],
			roles: [],
			hideReportCenter: AppService.getMetadata().params.HideReportCenter
		};

		AdminSubCtrl.rootData.editDashboardAccess = function (dashboardId, roles) {
			var roleIds = _.pluck(roles, 'id');
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.dashboards, function (db) {
				return db.id === dashboardId;
			});
			var oldRoles = _.cloneDeep(AdminSubCtrl.rootData.pageData.dashboards[index].roles);

			// FIXME Roles are not changed???
			AdminSubCtrl.rootData.pageData.dashboards[index].roles = roles;

			LookerDashboard.update(dashboardId, roleIds).catch(function () {
				AdminSubCtrl.rootData.pageData.error = true;
				AdminSubCtrl.rootData.pageData.dashboards[index].roles = oldRoles;
			});
		};

		AdminSubCtrl.rootData.editExploreAccess = function (users) {
			var userIdArray = _.pluck(users, 'id');
			var metadata = AppService.getMetadata();

			var method = 'save';
			if (metadata.params.LookerExploreAccess === null) {
				method = 'create';
			}

			ClientParam[method](210, userIdArray.join(','))
				.then(function () {
					metadata.params.LookerExploreAccess = userIdArray;
					AppService.setMetadata(metadata);
				})
				.catch(function () {
					AdminSubCtrl.rootData.pageData.error = true;
				});
		};

		AdminSubCtrl.rootData.toogleHideReportCenter = function (value) {
			var metadata = AppService.getMetadata();
			var method = 'save';
			if (metadata.params.HideReportCenter === null) {
				method = 'create';
			}
			ClientParam[method](222, value)
				.then(function () {
					metadata.params.HideReportCenter = value;
					AppService.setMetadata(metadata);
					AdminSubCtrl.rootData.pageData.hideReportCenter = value;
				})
				.catch(function () {
					AdminSubCtrl.rootData.pageData.error = true;
				});
		};

		var mapRoleName = function (dashboards) {
			_.forEach(dashboards, function (dashboard) {
				dashboard.roles = _.map(dashboard.roleIds, function (roleId) {
					var role = _.find(AppService.getRoles(), { id: roleId });
					return role;
				});
				dashboard.roles = _.compact(dashboard.roles);
			});
			return dashboards;
		};

		var mapUserName = function (userIds) {
			var users = _.map(userIds, function (userId) {
				return _.find(AppService.getActiveUsers(), { id: userId });
			});
			users = _.compact(users);
			return users;
		};

		var init = function () {
			LookerDashboard.list({})
				.then(function (res) {
					var dashboards = mapRoleName(res.data);
					var metadata = AppService.getMetadata();

					dashboards = _.reject(dashboards, dashboard => {
						if (dashboard.private) {
							return true;
						}
						if (dashboard.id.indexOf('basic_reporting::') === 0) {
							return true;
						}

						return false;
					});
					AdminSubCtrl.rootData.pageData.dashboards = dashboards;
					AdminSubCtrl.rootData.pageData.users = AppService.getActiveUsers();
					AdminSubCtrl.rootData.pageData.roles = AppService.getRoles();
					AdminSubCtrl.rootData.pageData.exploreAccess = mapUserName(metadata.params.LookerExploreAccess);

					AdminSubCtrl.rootData.pageLoading = false;
				})
				.catch(function () {
					AdminSubCtrl.rootData.pageData.error = true;
				});
		};

		// eslint-disable-next-line
		AppService.loadedPromise.then(init);
	}
]);

import React, { useState, useEffect, useRef } from 'react';
import { Icon, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import TextEditor from 'Components/TextEditor';
import translate from 'Components/Helpers/translate';
import './MailTemplatePreviewImage.scss';

const MailTemplatePreviewImage = ({
	column,
	readOnly,
	onChange,
	selected,
	maxWidth,
	accountProfile,
	isDoc = false
}) => {
	const classes = new bemClass('MailTemplatePreviewImage');
	const [broken, setBroken] = useState(false);
	const editorRef = useRef();

	useEffect(() => {
		if (!readOnly && editorRef.current) {
			if (selected) {
				editorRef.current.element.focus();
			} else {
				editorRef.current.element.blur();
			}
		}
	}, [readOnly, selected]);

	const toolbar = [
		{
			name: 'basicstyles',
			items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']
		},
		{ name: 'colors', items: ['TextColor'] },
		{ name: 'styles', items: ['Format', 'FontSize'] },
		{ name: 'fonts', items: ['Font'] },
		{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
		{ name: 'links', items: ['Link', 'Unlink'] }
	];
	if (!isDoc) toolbar.push({ name: 'signatures', items: ['signatures'] });

	return (
		<div className={classes.mod({ broken }).b()} data-testid="MailTemplatePreviewImage">
			<div className={classes.elem('align-img-wrap').b()} style={{ textAlign: column.imgAlign }}>
				<img
					src={column.src}
					style={{ width: column.imgWidth, maxWidth }}
					onError={() => setBroken(true)}
					onLoad={() => setBroken(false)}
				/>
			</div>
			{broken ? (
				<div className={classes.elem('broken-info')}>
					<Icon name="times" />
					<Text color="red">{translate('mailTemplate.imageNotFound')}</Text>
				</div>
			) : null}
			{column.showCaption ? (
				<div className={classes.elem('align-wrap').b()} style={{ textAlign: column.captionAlign }}>
					{readOnly ? (
						<div dangerouslySetInnerHTML={{ __html: column.caption }} />
					) : (
						<TextEditor
							type="inline"
							instanceRef={editorRef}
							value={column.caption}
							onChange={e => onChange({ ...column, caption: e.value })}
							config={{
								forcePasteAsPlainText: true,
								toolbar: toolbar,
								extraPlugins: 'tags,signatures',
								format_maintitle: { name: 'Main title', element: 'h2' },
								format_secondarytitle: { name: 'Title', element: 'h3' },
								format_tags: 'paragraph;ingress;maintitle;secondarytitle',
								format_paragraph: {
									name: 'Paragraph',
									element: 'p',
									attributes: { class: 'paragraph' }
								},
								format_ingress: {
									name: 'Ingress',
									element: 'p',
									attributes: { class: 'ingress' }
								}
							}}
							accountProfile={accountProfile}
						/>
					)}
				</div>
			) : null}
		</div>
	);
};
export default MailTemplatePreviewImage;

import React from 'react';
import { Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './ShownInStages.scss';

export default function ShowInStages(props: { stages: string[]; maxCharCount?: number }) {
	const { stages, maxCharCount = 0 } = props;
	const classNames = new BemClass('ShownInStages');

	const stagesToShow = [];
	const hiddenStages = [];
	let maxChars = maxCharCount;
	for (const stage of stages) {
		if (stagesToShow.length === 0 || stage.length <= maxChars) {
			stagesToShow.push(stage);
			maxChars -= stage.length;
		} else {
			hiddenStages.push(stage);
		}
	}
	const hidden = stages.length - stagesToShow.length;
	return (
		<div>
			{stagesToShow.map(stage => (
				<Text color="green" size="sm" className={classNames.elem('selectedStages').b()} key={stage}>
					{stage}
				</Text>
			))}
			{hidden > 0 ? (
				<Tooltip title={hiddenStages.join(', ')}>
					<Text size="sm" className={classNames.elem('hiddenStages').b()}>
						{'+' + hidden}
					</Text>
				</Tooltip>
			) : null}
		</div>
	);
}

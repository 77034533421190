import { Block, Link, Text, Title } from '@upsales/components';
import logError from 'Helpers/logError';
import { makeCancelable } from 'Helpers/promise';
import React, { useEffect, useState } from 'react';
import TipResource from 'Resources/Tip';
import T from 'Components/Helpers/translate';
import './LoginTip.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { PrimaryButton } from '@upsales/components/Buttons';

type Tip = {
	type: string;
	title: string;
	description: string;
	link: string;
};

export default function LoginTip() {
	const classes = new BemClass('LoginTip');
	const [tip, setTip] = useState<null | Tip>(null);
	useEffect(() => {
		const getter = makeCancelable<{ data: Tip }>(TipResource.getRandomTip());
		getter.promise
			.then(({ data }) => {
				setTip(
					data
						? data
						: {
								type: '',
								title: 'Find out more about the Upsales revenue engine',
								description:
									'The fastest growing B2B companies use CRM, Marketing Automation and Analytics from Upsales to reach new customers and win more deals.',
								link: 'https://www.upsales.com'
						  }
				);
			})
			.catch(e => logError(e, 'Failed to fetch random tip'));

		return () => {
			getter.cancel();
		};
	}, []);

	return tip ? (
		<Block space="prxl plxl" className={classes.b()}>
			<Block space="mbl">
				<Text color="super-light-green" size="lg" className={classes.elem('header').b()}>
					{tip.type}
				</Text>
			</Block>
			<Title size="lg" color="super-light-green" bold>
				{tip.title}
			</Title>
			<Block space="mtl mbxl">
				<Text color="super-light-green">{tip.description}</Text>
			</Block>

			<Link nostyle href={tip.link} target='_blank'>
				<PrimaryButton size='lg' onDark>{T('default.readMore')}</PrimaryButton>
			</Link>
		</Block>
	) : null;
}

import mailCampaignFilters from 'App/babel/filterConfigs/MailCampaign';
const MailCampaignFilters = mailCampaignFilters();

angular.module('upAttributes').service('mailCampaignAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				status: {
					title: 'default.status',
					field: 'status',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					filterable: false,
					sortable: 'status'
				},
				flowId: {
					title: 'flowId',
					field: 'flowId',
					type: FilterType.Number
				},
				mailSent: {
					title: 'mail.sent',
					field: 'mailSent',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'mailSent'
				},

				mailRead: {
					title: 'mail.opened',
					field: 'mailRead',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'mailRead'
				},
				mailClicked: {
					title: 'mail.clicked',
					field: 'mailClicked',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'mailClicked'
				},
				isArchived: {
					title: 'default.archived',
					field: 'isArchived',
					type: FilterType.Boolean,
					displayType: DisplayType.Boolean,
					groupable: false,
					selectableColumn: false,
					filterable: true
				},
				doSend: {
					field: 'doSend',
					type: FilterType.Boolean,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				sendDate: {
					title: 'column.date',
					field: 'sendDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: false,
					filterable: false,
					sortable: 'sendDate'
				},
				modDate: {
					title: 'listColumnTitle.lastModified',
					field: 'modDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'modDate'
				},
				opened: {
					title: 'default.opend',
					field: 'opend',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				project: {
					title: 'column.listSource',
					field: 'project.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					parent: 'project',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				segment: {
					title: 'default.segment',
					field: 'segment.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false,
					filterable: false,
					parent: 'segment',
					attr: {
						id: {
							field: 'segment.id',
							type: FilterType.Number
						},
						name: {
							field: 'segment.name',
							type: FilterType.String
						}
					}
				},
				type: {
					title: 'default.status',
					field: 'type',
					selectableColumn: true,
					sortable: 'type',
					displayType: DisplayType.String
				},
				from: {
					title: 'mail.from',
					field: 'from',
					selectableColumn: true,
					displayType: DisplayType.String,
					sortable: 'from'
				},
				fromName: {
					title: 'mail.fromName',
					field: 'fromName',
					selectableColumn: true,
					sortable: 'fromName',
					displayType: DisplayType.String
				},
				body: {
					title: 'mail.body',
					field: 'body',
					displayType: DisplayType.String
				},
				contact: {
					title: 'default.contact',
					field: 'contact',
					displayType: DisplayType.String
				},
				client: {
					title: 'default.account',
					field: 'client',
					displayType: DisplayType.String
				},
				template: {
					title: 'mail.template',
					field: 'template.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					parent: 'template',
					attr: {
						id: {
							field: 'template.id',
							type: FilterType.Number
						},
						name: {
							field: 'template.name',
							type: FilterType.String
						}
					}
				},
				to: {
					title: 'mail.reciever',
					field: 'to',
					selectableColumn: true,
					displayType: DisplayType.String
				},
				subject: {
					title: 'mail.subject',
					field: 'subject',
					selectableColumn: true,
					sortable: 'subject',
					displayType: DisplayType.String
				},
				name: {
					title: 'default.internalName',
					field: 'name',
					selectableColumn: true,
					sortable: 'name',
					displayType: DisplayType.String
				},
				lastClickDate: {
					title: 'mail.readclick',
					field: 'lastClickDate',
					displayType: DisplayType.String
				},
				lastEventDate: {
					title: 'mail.readclickDate',
					field: 'lastEventDate',
					displayType: DisplayType.Date
				},
				lastReadDate: {
					title: 'mail.readDate',
					field: 'lastReadDate',
					displayType: DisplayType.Date
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					}
				},
				isDraft: {
					title: 'mail.draft',
					field: 'isDraft',
					displayType: DisplayType.Date
				},
				custom: {},
				attachments: {
					title: 'mail.attachments',
					field: 'attachments',
					displayType: DisplayType.String
				},
				socialEventId: {
					field: 'socialEventId',
					displayType: DisplayType.Number
				},
				socialEventSendToStatus: {
					field: 'socialEventSendToStatus',
					displayType: DisplayType.String
				},
				bodyJson: {
					title: 'mail.bodyJson',
					field: 'bodyJson',
					displayType: DisplayType.String,
					selectableColumn: false,
					groupable: false,
					filterable: false
				},
				labels: {
					title: 'file.label',
					field: 'labels',
					displayType: DisplayType.String,
					selectableColumn: false
				}
			};

			attributes.campaign = angular.copy(attributes.project);
			attributes.campaign.selectableColumn = false;

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: MailCampaignFilters,
				keys: {
					ID: 'id'
				}
			};
		};
	}
]);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import Text from '@upsales/components/Text';
import Icon from '@upsales/components/Icon';
import Row from '@upsales/components/Row';
import Column from '@upsales/components/Column';
import Button from '@upsales/components/Button';
import JourneyHistoryDropDown from './JourneyHistoryDropDown';
import { statuses, getJourneyStep, statusDisqualifiable, LEAD } from 'Components/Helpers/journeyStep';
import JourneyStepHistory from 'Resources/JourneyStepHistory';
import logError from 'App/babel/helpers/logError';

import './JourneyStepDisplay.scss';

const getIconName = (isExpanded, saving) => {
	if (saving) {
		return 'spinner';
	} else {
		return isExpanded ? 'chevron-up' : 'chevron-down';
	}
};

class JourneyStepDisplay extends PureComponent {
	state = { saving: false, historyArr: [] };
	lang = {
		updatingStatus: Tools.$translate('default.updatingStatus'),
		disqualify: Tools.$translate('default.disqualify'),
		disqualifyCompany: Tools.$translate('default.disqualifyCompany'),
		disqualifyContact: Tools.$translate('default.disqualifyContact'),
		salesQualifyCompany: Tools.$translate('default.salesQualifyCompany'),
		salesQualifyContact: Tools.$translate('default.salesQualifyContact')
	};

	fetchHistory = force => {
		const { id, type, journeyStep } = this.props;
		if (id && (!this.historyFetched || force)) {
			this.historyFetched = true;
			JourneyStepHistory.find(id, type)
				.then(historyArr => {
					if (!historyArr || !historyArr.length) {
						historyArr = [{ value: journeyStep || LEAD, date: null }];
					}
					this.setState({ historyArr });
				})
				.catch(e => {
					logError(e, 'Failed fetching journey history', { id, type });
				});
		}
	};

	onSetJourneyStep = journeyStep => {
		this.setState({ saving: true });
		return this.props.onSetJourneyStep(journeyStep).finally(() => {
			this.setState({ saving: false });
			this.fetchHistory(true);
		});
	};

	componentDidUpdate(prevProps) {
		if (this.props.journeyStep !== prevProps.journeyStep) {
			this.fetchHistory(true);
		}
	}

	render() {
		if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS)) {
			return null;
		}

		const { journeyStep, className, type, disableActions, isSubaccount, isInactive } = this.props;
		const { saving } = this.state;
		const classes = new bemClass('JourneyStepDisplay', className);
		const step = getJourneyStep(journeyStep || LEAD);
		const tooltipArray = [
			type === 'client' ? this.lang.salesQualifyCompany : this.lang.salesQualifyContact,
			type === 'client' ? this.lang.disqualifyCompany : this.lang.disqualifyContact
		];

		return (
			<div className={classes.mod({ isSubaccount, isInactive }).b()}>
				<JourneyHistoryDropDown
					disableActions={disableActions}
					onSetJourneyStep={this.onSetJourneyStep}
					tooltipArray={tooltipArray}
					journeyStep={journeyStep || LEAD}
					historyArr={this.state.historyArr} // If current and journeyStep missmatch
				>
					{(isExpanded, setExpanded) => (
						<Row
							className={classes.elem('button-wrapper').mod({ expanded: isExpanded }).b()}
							onClick={setExpanded}
							onMouseOver={() => this.fetchHistory(false)}
						>
							<Column>
								<Text size="sm">{Tools.$translate(step.name)}</Text>
							</Column>
							<Column fixedWidth={30} align="center">
								<Button
									className={classes.elem('button').mod({ open: isExpanded }).b()}
									type={isExpanded ? null : 'link'}
									color={isExpanded ? 'white' : 'grey'}
								>
									<Icon spin={saving} name={getIconName(isExpanded, saving)} />
								</Button>
							</Column>
						</Row>
					)}
				</JourneyHistoryDropDown>
			</div>
		);
	}
}

JourneyStepDisplay.statusDisqualifiable = statusDisqualifiable;

JourneyStepDisplay.defaultProps = {
	type: 'client',
	journeyStep: LEAD,
	isSubaccount: false,
	isInactive: false
};

JourneyStepDisplay.propTypes = {
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(['client', 'contact']).isRequired,
	journeyStep: PropTypes.oneOf(statuses),
	disableActions: PropTypes.bool,
	className: PropTypes.string,
	onSetJourneyStep: PropTypes.func,
	isSubaccount: PropTypes.bool,
	isInactive: PropTypes.bool
};

export default JourneyStepDisplay;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Text, Input, Block, Row, Column, Button, Title } from '@upsales/components';
import CriteriaDrilldownWrapper from '../../../CriteriaDrilldownWrapper';
import Bem from '@upsales/components/Utils/bemClass';
import { uniq } from 'lodash';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import SniCodeRow from './SniCodeRow';

const SearchInput = ({ placeholder, onChange }) => {
	const [searchValue, setSearchValue] = useState('');
	useEffect(() => {
		onChange(searchValue);
	}, [searchValue]);

	return (
		<Input
			icon="search"
			placeholder={placeholder}
			value={searchValue}
			onChange={event => setSearchValue(event.target.value)}
			onClear={() => setSearchValue('')}
		/>
	);
};

export default class IndustryDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const t = getAngularModule('$translate');

		this.lang = {
			industry: t('default.companyBranch'),
			searchIndustry: t('default.search') + ' ' + t('default.companyBranch').toLowerCase(),
			industryName: t('default.companyBranchName').toUpperCase(),
			noOfCompanies: t('default.noOfCompanies').toUpperCase(),
			deselectAll: t('default.deselectAll'),
			closed: t('default.closed'),
			open: t('default.open'),
			noSalesTitle: t('prospecting.manually.industry.noSalesTitle'),
			noSalesDescription: t('prospecting.manually.industry.noSalesDescription'),
			noSalesElevio: t('prospecting.manually.industry.noSalesElevio')
		};

		this.state = {
			data: [],
			searchValue: '',
			expanded: {},
			hasSales: false
		};

		this.onSearchChanged = _.debounce(this.onSearchChanged, 250);
	}

	componentDidMount() {
		const {
			selectData,
			filter: { filterName }
		} = this.props;

		this.dataPromise = makeCancelable(selectData[filterName]);
		this.dataPromise.promise
			.then(({ data }) => this.setState({ data, hasSales: !!data.some(item => item.orderValue) }))
			.catch(e => {
				if (!e.isCanceled) {
					logError(e, 'IndustryDrilldown failed to get sni codes');
				}
			});
	}

	componentWillUnmount() {
		if (this.dataPromise) {
			this.dataPromise.cancel();
		}
	}

	onChange(value) {
		const { filter, onChange } = this.props;
		const newFilter = { ...filter, value };
		onChange(newFilter.filterName, newFilter);
	}

	onSearchChanged = searchValue => {
		this.setState({ searchValue });
	};

	onParentClick = (parent, checked) => {
		const {
			filter: { industries, value: currentValue }
		} = this.props;

		if (checked) {
			const childMap = parent.children.reduce((res, child) => {
				res[child.id] = true;
				return res;
			}, {});

			const value = currentValue.filter(value => !childMap[value]);
			this.onChange(value);
		} else {
			const children =
				industries === 'industriesWithSales'
					? parent.children.filter(child => child.orderValue > 0)
					: parent.children;
			const childIds = children.map(child => child.id);
			const value = uniq(currentValue.concat(childIds));
			this.onChange(value);
		}
	};

	parentCheckStatus = parent => {
		const {
			filter: { industries, value: currentValue }
		} = this.props;
		const valueMap = currentValue.reduce((res, id) => {
			res[id] = true;
			return res;
		}, {});
		const children =
			industries === 'industriesWithSales'
				? parent.children.filter(child => child.orderValue > 0)
				: parent.children;
		const count = children.reduce((count, child) => (valueMap[child.id] ? count + 1 : count), 0);
		return {
			checked: count > 0 && count === children.length,
			half: count > 0 && count < children.length
		};
	};

	onChildClick = (child, checked) => {
		const {
			filter: { value: currentValue }
		} = this.props;
		if (checked) {
			const value = currentValue.filter(value => value !== child.id);
			this.onChange(value);
		} else {
			const value = [...currentValue, child.id];
			this.onChange(value);
		}
	};

	childCheckStatus = child => {
		const {
			filter: { value: currentValue }
		} = this.props;
		return currentValue.indexOf(child.id) > -1;
	};

	toggleExpanded = parent => {
		const expanded = this.state.expanded;
		const value = !expanded[parent.id];
		this.setState({ expanded: { ...expanded, [parent.id]: value } });
	};

	itemVisible(item) {
		const {
			filter: { industries }
		} = this.props;
		const { searchValue } = this.state;
		const lowerCasedSearchValue = searchValue.toLocaleLowerCase();
		const visible = item =>
			(item.name.toLowerCase().indexOf(lowerCasedSearchValue) > -1 ||
				item?.codeAndName?.toLowerCase()?.indexOf(lowerCasedSearchValue) > -1) &&
			(industries === 'industriesWithSales' ? item.orderValue > 0 : true);
		return item.children ? visible(item) || item.children.some(child => visible(child)) : visible(item);
	}

	deselectAll = () => {
		this.onChange([]);
	};

	render() {
		const {
			open,
			filter: { value, industries }
		} = this.props;
		const lang = this.lang;
		const { data, searchValue, expanded, hasSales } = this.state;
		const classes = new Bem('ProspectingDrilldown');

		if (!hasSales && industries === 'industriesWithSales') {
			return (
				<CriteriaDrilldownWrapper className={classes.mod({ 'no-sales': true, industry: true }).b()} open={open}>
					<td colSpan={3}>
						<div className="drilldown-animation-wrapper">
							<table cellPadding={5}>
								<tbody>
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.industry}</div>
										</td>
										<td className="table-col"></td>
									</tr>
									<tr>
										<td className="title-col"></td>
										<td className="table-col">
											<div className="flex-row">
												<div className="drilldown-row">
													<Row>
														<Column>
															<Block space="mrxl">
																<Block space="mbxl">
																	<Title>{lang.noSalesTitle}</Title>
																</Block>
																<Block space="mbxl">
																	<Text color="grey-10">
																		{lang.noSalesDescription}
																	</Text>
																</Block>
																<Block space="mbxl">
																	<ReactTemplates.elevio
																		articleId={767}
																		sidebar={true}
																	>
																		{lang.noSalesElevio}
																	</ReactTemplates.elevio>
																</Block>
															</Block>
														</Column>
														<Column fixedWidth={340}>
															<Block space="mlxl mrxl">
																<img src="img/where_to_update_company.png" />
															</Block>
														</Column>
													</Row>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</td>
				</CriteriaDrilldownWrapper>
			);
		}

		const rows = data.reduce((rows, parent) => {
			const { checked, half } = this.parentCheckStatus(parent);
			const isExpaned = expanded[parent.id] || !!searchValue;
			const visible = this.itemVisible(parent);

			if (visible) {
				rows.push(
					<SniCodeRow
						key={parent.id}
						half={half}
						checked={half || checked}
						isExpaned={isExpaned}
						item={parent}
						onSelct={this.onParentClick}
						toggleExpanded={this.toggleExpanded}
					/>
				);
			}

			if (isExpaned) {
				for (const child of parent.children) {
					const checked = this.childCheckStatus(child);
					const visible = this.itemVisible(child);

					if (visible) {
						rows.push(
							<SniCodeRow key={child.id} checked={checked} item={child} onSelct={this.onChildClick} />
						);
					}
				}
			}

			return rows;
		}, []);

		return (
			<CriteriaDrilldownWrapper className={classes.mod('industry').b()} open={open}>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.industry}</div>
									</td>
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<Row>
													<Column fixedWidth={400}>
														<SearchInput
															placeholder={lang.searchIndustry}
															onChange={this.onSearchChanged}
														/>
													</Column>
													<Column>
														{value.length ? (
															<Button type="link" onClick={this.deselectAll}>
																<Text color="grey-11" italic={true}>
																	{lang.deselectAll}
																</Text>
															</Button>
														) : null}
													</Column>
												</Row>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td className="title-col" />
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<Block className={classes.elem('table').b()}>
													<Row>
														<Column fixedWidth={30} />
														<Column>
															<Text size="sm">{this.lang.industryName}</Text>
														</Column>
														<Column align="right">
															<Text size="sm">{this.lang.noOfCompanies}</Text>
														</Column>
														<Column fixedWidth={35} />
													</Row>
													{rows}
												</Block>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

IndustryDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	selectData: PropTypes.object.isRequired
};

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Checkbox, Headline, Input, Select, Text, Label } from '@upsales/components';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import './DocumentTemplateEditorDetails.scss';
import { usePopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';
import { DocumentTemplateState, isInvalidTemplateName } from './DocumentTemplateEditor';
import { useTranslation } from 'Components/Helpers/translate';

type Props = {
	state: DocumentTemplateState;
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>;
};

export type SelectValue = React.ComponentProps<typeof Select<SelectItem, true>>['value'];

const DocumentTemplateEditorDetails = ({ state, dispatch }: Props) => {
	const { t } = useTranslation();
	const lang = {
		details: t('createCustomTemplate.details'),
		templateName: t('createCustomTemplate.templateName'),
		templateNamePlaceholder: t('createCustomTemplate.templateNamePlaceholder'),
		templateAvailableAt: t('createCustomTemplate.templateAvailableAt'),
		templateWhoCanUse: t('createCustomTemplate.templateWhoCanUse'),
		activateTemplate: t('createCustomTemplate.activateTemplate'),
		allRoles: t('createCustomTemplate.allRoles'),
		order: t('createCustomTemplate.Order'),
		templateReadyWhenSaved: t('createCustomTemplate.templateReadyWhenSaved'),
		whoCanUsePlaceholder: t('admin.documentTemplates.details.whoCanUsePlaceholder'),
		characterLimitReached: t('default.characterLimitReached'),
		invalidTemplateName: t('admin.documentTemplate.standardTemplatesModal.errors.invalidTemplateName')
	};
	const validInput = isInvalidTemplateName(state.name) === lang.invalidTemplateName ? 'error' : undefined;
	const classes = new bemClass('DocumentTemplateEditorDetails').mod('open');
	const maxInputLength = 50; // Decided by designer David Leche, it is possible to send up to 2 kb of metadata but this is not necessary

	const getRoles = () => {
		const allRoles = Tools.AppService.getRoles();
		const roles = [...allRoles.map(obj => ({ id: obj.id, title: obj.name }))];
		return roles;
	};

	const userRoles: SelectValue = state.roles.map(obj => ({ id: obj.id, title: obj.name }));

	const { anchor } = usePopupPortalAnchorsContext();

	const onAddRole = (newRole: SelectItem) => {
		const role = Tools.AppService.getRoles().find(role => newRole.id === role.id);
		if (role) dispatch({ roles: [...state.roles, role] });
	};

	const onRemoveRole = (roleId: number | string) => {
		const updatedRoles = state.roles.filter(role => role.id !== roleId);
		dispatch({ roles: updatedRoles });
	};
	return (
		<div className={classes.b()} data-testid="DocumentTemplateEditorDetails">
			<div className={classes.elem('container').b()}>
				<Headline>{lang.details}</Headline>
				<div className={classes.elem('inputs').b()}>
					<div className={classes.elem('input').b()}>
						<Label
							required={true}
							maxLength={maxInputLength}
							value={state.name}
							maxLengthReachedText={lang.characterLimitReached}
						>
							{lang.templateName}
						</Label>
						<Input
							state={validInput}
							data-testid="Input_templateName"
							value={state.name}
							placeholder={lang.templateNamePlaceholder}
							maxLength={maxInputLength}
							onChange={e => dispatch({ name: e.target.value })}
						></Input>
					</div>
					<div className={classes.elem('input').b()}>
						<Text bold={true}>{lang.templateAvailableAt}</Text>
						{/* To be updated to use availableAt state when we support more than orders */}
						<Input defaultValue={lang.order} disabled={true}></Input>
					</div>
					<div className={classes.elem('input').b()}>
						<Text bold={true}>{lang.templateWhoCanUse}</Text>
						<Select
							data-testid="Input_roles"
							multi
							anchor={anchor}
							options={getRoles().filter(o => !userRoles.some(i => i.id === o.id))}
							onClear={() => dispatch({ roles: [] })}
							value={userRoles}
							onChange={onAddRole}
							onRemove={onRemoveRole}
							placeholder={lang.whoCanUsePlaceholder}
						/>
					</div>
					<div className={classes.elem('input').b()}>
						<div className={classes.elem('checkboxInput').b()}>
							<Checkbox
								data-testid="checkBox"
								checked={state.activated}
								onClick={() => dispatch({ activated: !state.activated })}
							/>
							<div>
								<Text bold={true}>{lang.activateTemplate}</Text>
								<Text>{lang.templateReadyWhenSaved}</Text>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DocumentTemplateEditorDetails;

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './NavbarCustomLinks.scss';
import { Icon, Text, Row, Column } from '@upsales/components';
import T from 'Components/Helpers/translate';
import NavbarDropdown from '../NavbarDropdown';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';

class NavbarCustomLinks extends React.Component {
	state = {
		loading: true,
		links: []
	};
	componentDidMount() {
		const self = Tools.AppService.getSelf();
		this.fetchPromise = makeCancelable(
			Tools.Links.customer(Tools.AppService.getCustomerId()).get('sidebar', self.id)
		);
		this.fetchPromise.promise
			.then(({ data }) => {
				this.setState({ links: data, loading: false });
			})
			.catch(e => logError(e, 'Failed to load navbar links'));
	}
	componentWillUnmount() {
		if (this.fetchPromise) {
			this.fetchPromise.cancel();
		}
	}
	render() {
		const { loading, links } = this.state;
		const classes = new bemClass('NavbarCustomLinks');

		if (loading || !links.length) {
			return null;
		}

		return (
			<NavbarDropdown
				align="right"
				className={classes.b()}
				renderTrigger={({ open, setOpen }) => (
					<li className="menu-item" onMouseDown={() => setOpen(!open)}>
						<a className="dropdown-toggle">
							<Icon name="link" />
						</a>
					</li>
				)}
			>
				{({ setOpen }) =>
					links.map(link => {
						return (
							<a
								key={link.id}
								target={link.target || '_blank'}
								href={link.href}
								onClick={() => setOpen(false)}
							>
								<Row className={classes.elem('item').b()}>
									<Column fixedWidth={50} align="center">
										{link.icon ? (
											<Icon
												name={link.icon.replace('fa-', '')}
												style={link.color ? { color: `#${link.color}` } : {}}
											/>
										) : null}
									</Column>
									<Column>
										<Text>{T(link.value)}</Text>
									</Column>
								</Row>
							</a>
						);
					})
				}
			</NavbarDropdown>
		);
	}
}

export default NavbarCustomLinks;

import { Icon, Text } from '@upsales/components';
import React from 'react';
import FlowStepBase from '../FlowStepBase';
import T from 'Components/Helpers/translate';
import Flow from 'App/resources/Model/Flow';
import './FlowStepEnd.scss';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	locked: boolean;
	onClick: () => void;
	flow: Flow;
	editable: boolean;
};

export const MAX_HEIGHT = 76;

const FlowStepEnd = ({ locked, onClick, flow, editable }: Props) => {
	const classes = new BemClass('FlowStepEnd');
	return (
		<FlowStepBase
			className={classes.b()}
			icon={flow.loop ? 'repeat' : 'flag-checkered'}
			backgroundColor="success-1"
			borderColor="success-5"
			title={flow.loop ? T('flow.loopFlow') : T('flow.step.end.title')}
			locked={locked}
			maxHeight={MAX_HEIGHT}
			onClick={onClick}
		>
			{flow.loop && editable ? (
				<Icon name="edit" className={classes.elem('edit-icon').b()} color="grey-9" />
			) : null}
			<Text>
				{flow.loop
					? `${T('flow.loopedFlowInfoShort', { waitTime: flow.loopTime })} ${T(
							'calendar.singularplural.' + flow.loopUnit,
							{ value: flow.loopTime }
					  ).toLowerCase()}`
					: T('flow.step.end.contactsCompletedFlow')}
			</Text>
		</FlowStepBase>
	);
};

export default FlowStepEnd;

import Attribute, { DisplayType, Type } from './Attribute';

const QuotaAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Text
	}),
	user: Attribute({
		title: 'default.user',
		field: 'user.id',
		type: Type.Object,
		displayType: DisplayType.Text,
		displayAttr: 'name',
		sortable: 'user.name',
		parent: 'user',
		attr: {
			id: {
				field: 'user.id',
				type: Type.Number
			},
			name: {
				field: 'user.name',
				type: Type.String
			},
			role: {
				field: 'user.role',
				type: Type.Object,
				attr: {
					id: {
						field: 'user.role.id',
						type: Type.Number
					}
				}
			}
		}
	}),
	valueInMasterCurrency: Attribute({
		title: 'default.valueInMasterCurrency',
		field: 'valueInMasterCurrency',
		type: Type.Number,
		displayType: DisplayType.Text
	}),
	value: Attribute({
		title: 'default.value',
		field: 'value',
		type: Type.Number,
		displayType: DisplayType.Text
	}),
	month: Attribute({
		title: 'default.month',
		field: 'month',
		type: Type.Number,
		displayType: DisplayType.Text
	}),
	currencyRate: Attribute({
		title: 'default.currencyRate',
		field: 'currencyRate',
		type: Type.Float,
		displayType: DisplayType.Text
	}),
	currency: Attribute({
		title: 'default.currency',
		field: 'currency',
		type: Type.String,
		displayType: DisplayType.Currency
	}),
	year: Attribute({
		title: 'default.year',
		field: 'year',
		type: Type.Number,
		displayType: DisplayType.Text
	}),
	date: Attribute({
		title: 'default.date',
		field: 'date',
		type: Type.Date,
		displayType: DisplayType.Date
	})
};

export default QuotaAttributes;

import React from 'react';
import translate from 'Components/Helpers/translate';
import { Input, Label } from '@upsales/components';
import UpSelect from 'Components/Inputs/UpSelect';
import './FileBrowserFilters.scss';
import { BasicUserWithPermissions } from 'App/resources/Model/User';

interface FileBrowserFiltersProps {
	searchString: string;
	onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	selectedUser?: BasicUserWithPermissions;
	changeUserFilter: (user: BasicUserWithPermissions) => void;
	onSortChange: (sort: { field: string; asc: boolean }) => void;
	currentSort: string | null;
	sortTypes: { name: string; id: string }[];
}

const FileBrowserFilters = ({
	searchString,
	onSearch,
	selectedUser,
	changeUserFilter,
	onSortChange,
	currentSort,
	sortTypes
}: FileBrowserFiltersProps) => {
	return (
		<div className="FileBrowserFilters">
			<Input
				icon="search"
				maxLength={120}
				onChange={onSearch}
				value={searchString}
				placeholder={translate('default.search')}
				noborder
				clear
				color="grey-2"
				autofocus
			/>
			<Label>{translate('file.uploader')}</Label>
			<ReactTemplates.INPUTS.upUsers
				placeholder={translate('filters.allUsers')}
				onChange={changeUserFilter}
				value={selectedUser}
			/>
			<Label>{translate('groupMail.sortBy')}</Label>
			<UpSelect onChange={onSortChange} defaultValue={currentSort} data={sortTypes} />
		</div>
	);
};

export default FileBrowserFilters;

import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';

angular.module('upDirectives').directive('upInlineAction', function () {
	return {
		scope: {
			onDiscard: '=',
			onSave: '=',
			showTop: '=',
			closeDialog: '=',
			saveDisabled: '=',
			saveDisabledTooltip: '='
		},
		replace: true,
		template: '<div></div>',
		restrict: 'A',
		link: function ($scope, $element) {
			let firstElement = $element[0];
			const render = () => {
				if (!firstElement) {
					return;
				}

				ReactDOM.render(
					React.createElement(InlineAction, {
						onReject: function () {
							$scope.onDiscard();
						},
						toggleInlineAction: function () {
							$scope.closeDialog();
						},
						onConfirm: function () {
							$scope.onSave();
						},
						showTop: $scope.showTop,
						saveDisabled: $scope.saveDisabled,
						saveDisabledTooltip: $scope.saveDisabledTooltip
					}),
					firstElement,
					function () {}
				);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			render();
		}
	};
});

import logError from 'App/babel/helpers/logError';
import { preview } from 'App/helpers/creatives';
import ClientCardMarketing from 'App/pages/ClientCard/ContentPages/Marketing/Marketing';
import TimelineEntityNote from 'Components/TimelineRow/TimelineEntityNote';

angular.module('domain.account').controller('AccountMarket', [
	'$scope',
	'$q',
	'$stateParams',
	'RequestBuilder',
	'Event',
	'EventService',
	'AppService',
	'$filter',
	'Account',
	'Lead2',
	'Ads',
	'$translate',
	'Contact',
	'$upModal',
	function (
		$scope,
		$q,
		$stateParams,
		RequestBuilder,
		Event,
		EventService,
		AppService,
		$filter,
		Account,
		Lead2,
		Ads,
		$translate,
		Contact,
		$upModal
	) {
		var AccountCtrl = $scope.AccountCtrl;

		var customerId = $stateParams.customerId;
		var eventOffset = 0;
		var eventLimit = 50;
		var weekPrefix;
		var metadata;
		var chart, marketGrouping, nrOfMonths;

		$scope.eventsLoading = false;
		$scope.graphLoading = false;
		$scope.events = [];
		$scope.totalEvents = 0;
		$scope.eventsLength = 0;
		$scope.creatives = [];
		$scope.totalEventMail = 0;
		$scope.totalEventForm = 0;
		$scope.totalEventVisit = 0;
		$scope.totalAll = 0;

		AccountCtrl.TimelineEntityNote = TimelineEntityNote;

		AccountCtrl.useReactMarket = Tools.FeatureHelper.hasSoftDeployAccess('ACCOUNT_CARD_MARKETING_USE_REACT_TAB');
		AccountCtrl.hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		AccountCtrl.ClientCardMarketing = ClientCardMarketing;

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				// Reload stuff
				$scope.getEvents();
			}
		});

		$scope.$on('account.assigned', function (e, res, clientId) {
			if (AccountCtrl.account.id === clientId) {
				// Reload stuff
				$scope.getEvents();
			}
		});

		$scope.$on('account.resetScore', function (e, clientId) {
			if (AccountCtrl.account.id === clientId) {
				// Reload stuff
				$scope.getEvents(true);
				getData();
			}
		});

		$scope.$on('account.unassigned', function (e, clientId) {
			if (AccountCtrl.account.id === clientId) {
				// Reload stuff
				$scope.getEvents(true);
				getData();
			}
		});

		$scope.$on('mail.added', function (e, added) {
			if (added.client && added.client.id === AccountCtrl.account.id && added.type !== 'sch') {
				var event = EventService.create.Mail(added);
				$scope.events.unshift(event);
				$scope.getEvents();
			}
		});

		$scope.$on('market.added', function (e, res) {
			if (res && res.client && res.client.id === AccountCtrl.account.id) {
				var event = EventService.create.Manual(res);
				$scope.events.unshift(event);
				getData();
			}
		});

		var makeLegendItem = function (name, kpi, noP, color, icon) {
			if (kpi) {
				icon = '<i class="fa fa-' + icon + '" style="color:' + color + ';"></i>';
			} else {
				icon = '<i class="fa fa-' + icon + '"></i>';
			}
			return (
				'<span style="font-size:18px;">' +
				kpi +
				($scope.marketGrouping === 'score' && !noP ? 'p' : '') +
				'</span><span><br />' +
				icon +
				' ' +
				$translate.instant(name) +
				'</span>'
			);
		};
		var buildChart = function (serieData) {
			var series = [
				{
					name: makeLegendItem('leads.fromForms', $scope.totalForm, false, '#4E0065', 'file-text'),
					type: 'column',
					color: '#4E0065',
					data: serieData.serieForm,
					visible: !$scope.totalLeadScore || !!$scope.totalForm // if score is 0, make the lines visible for the graph to show as a placeholder (looks better...) // else make this column hidden by default
				},
				{
					name: makeLegendItem('mail.clickedMails', $scope.totalMail, false, '#721A94', 'envelope'),
					type: 'column',
					color: '#721A94',
					data: serieData.serieMail,
					visible: !$scope.totalLeadScore || !!$scope.totalMail
				},
				{
					name: makeLegendItem('default.siteVisits', $scope.totalVisit, false, '#B254E0', 'globe'),
					type: 'column',
					borderRadiusTopLeft: 5,
					borderRadiusTopRight: 5,
					color: '#B254E0',
					data: serieData.serieVisit,
					visible: !$scope.totalLeadScore || !!$scope.totalVisit
				},
				{
					name: makeLegendItem(
						'leads.fromMarketingCustom',
						$scope.totalMarketingCustom,
						false,
						'#9933C0',
						'bullseye'
					),
					type: 'column',
					borderRadiusTopLeft: 5,
					borderRadiusTopRight: 5,
					color: '#9933C0',
					data: serieData.serieMarketingCustom,
					visible: !$scope.totalLeadScore || !!$scope.totalMarketingCustom
				}
			];

			if (serieData.serieImpressionCount && serieData.serieImpressionCount.length) {
				series.push({
					name: makeLegendItem('default.adImpressions', $scope.impressionCount, true, '#9933C0', 'bullhorn'),
					type: 'line',
					color: '#9933C0',
					yAxis: 1,
					data: serieData.serieImpressionCount,
					lineWidth: 2,
					visible: !$scope.totalLeadScore || !!$scope.impressionCount
				});
			}

			// attribute is serieData and categories
			$scope.chartConfig = {
				chart: {
					type: 'column',
					marginTop: 60,
					height: 200,
					style: {
						fontFamily: '"Roboto"'
					},
					borderRadius: 5,
					backgroundColor: 'transparent',
					events: {
						init: function () {
							chart = this;
						}
					}
				},
				credits: { enabled: false },
				title: {
					text: ''
				},
				xAxis: {
					categories: serieData.categories,
					lineColor: 'transparent',
					tickLength: 0,
					tickWidth: 0,
					labels: {
						formatter: function () {
							if ($scope.nrOfMonths === 3) {
								var date = moment().week(this.value.substring(5, 7));
								if (this.chart.chartWidth < 1250) {
									return weekPrefix + date.isoWeek();
								}
								return date.startOf('w').format('DD MMM') + ' - ' + date.endOf('w').format('DD MMM');
							} else {
								if (this.chart.chartWidth < 750) {
									return moment(this.value).format("MMM 'YY");
								}
								return moment(this.value).format('MMM YYYY');
							}
						},
						overflow: false,
						rotation: false
					}
				},
				yAxis: [
					{
						min: 0,
						maxPadding: 0.01,
						title: {
							text: ''
						},
						labels: {
							formatter: function () {
								if (this.value > 0) {
									return $filter('numberFormat')(this.value);
								}
							},
							style: {
								color: ' #6B7C93'
							}
						}
					},
					{
						title: {
							text: '',
							style: {
								color: Highcharts.getOptions().colors[2]
							}
						},
						opposite: true,
						labels: {
							formatter: function () {
								if (this.value > 0) {
									return $filter('numberFormat')(this.value);
								}
							},
							style: {
								color: ' #6B7C93'
							}
						}
					}
				],
				legend: {
					align: 'center',
					x: 0,
					verticalAlign: 'top',
					y: -10,
					//layout: 'vertical',
					floating: false,
					backgroundColor: 'transparent',
					borderColor: '#A4B3C7',
					borderWidth: 0,
					symbolWidth: 0,
					symbolHeight: 0,
					symbolRadius: 0,
					shadow: false,
					useHTML: true,
					itemStyle: {
						fontWeight: 'normal',
						fontSize: '11px',
						fontFamily: 'Roboto'
					}
				},
				tooltip: {
					enabled: false
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						borderWidth: 0,
						marker: {
							radius: 2
						}
					},
					series: {
						marker: {
							radius: 2
						},
						point: {
							events: {
								mouseOver: function () {
									if (chart && chart.yAxis[1]) {
										if (this.series.userOptions.yAxis === 1) {
											chart.yAxis[1].update({
												labels: {
													style: {
														color: '#6B7C93',
														fontWeight: 'bold'
													}
												}
											});
										}
									}
								},
								mouseOut: function () {
									if (chart && chart.yAxis[1]) {
										chart.yAxis[1].update({
											labels: {
												style: {
													color: ' #6B7C93',
													fontWeight: 'normal'
												}
											}
										});
									}
								}
							}
						}
					}
				},
				series: series
			};
			$scope.graphLoading = false;
		};

		var mapGraphData = function (data) {
			var keys = Object.keys(data);
			var categories;
			var serieForm = _.range(0, 12, 0);
			var serieMail = _.range(0, 12, 0);
			var serieVisit = _.range(0, 12, 0);
			var serieMarketingCustom = _.range(0, 12, 0);
			var serieImpressionCount = _.range(0, 12, 0);

			if ($scope.nrOfMonths === 12) {
				var month = new Date().getMonth();
				var year = 1900 + new Date().getYear();
				categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (m) {
					var mon = month - m;
					if (mon < 0) {
						return (
							year -
							1 +
							'-' +
							moment()
								.month(mon + 12)
								.format('MM')
						);
					}
					return year + '-' + moment().month(mon).format('MM');
				});
			} else {
				// first day three months ago..
				categories = _.map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (m) {
					var dateStart = moment().subtract(m, 'w');
					return dateStart.format('YYYY') + '-' + dateStart.format('WW');
				});
			}

			var countOrScore = $scope.marketGrouping === 'event' ? 'Count' : 'Score';

			_.each(keys, function (key) {
				var index;
				index = categories.indexOf(key);
				if (index !== -1) {
					serieForm[index] = data[key]['form' + countOrScore];
					serieMail[index] = data[key]['mail' + countOrScore];
					serieVisit[index] = data[key]['visit' + countOrScore];
					serieMarketingCustom[index] = data[key]['marketingcustom' + countOrScore];
					if ($scope.impressionCount > 0) {
						serieImpressionCount[index] = data[key].impressionCount;
					}
				}
			});
			buildChart({
				categories: categories,
				serieForm: serieForm,
				serieMail: serieMail,
				serieVisit: serieVisit,
				serieMarketingCustom: serieMarketingCustom,
				serieImpressionCount: serieImpressionCount
			});
		};

		var getEventMetaData = function () {
			$q.all([
				getEvents('mail', true),
				getEvents('form', true),
				getEvents('visit', true),
				getEvents('marketingCustom', true),
				getEvents(null, true)
			])
				.then(function (res) {
					$scope.totalEventMail = res[0].metadata.total;
					$scope.totalEventForm = res[1].metadata.total;
					$scope.totalEventVisit = res[2].metadata.total;
					$scope.totalEventMarketingCustom = res[3].metadata.total;
					$scope.totalAll = res[4].metadata.total;
				})
				.catch(error => logError(error, `controllers/accountMarket - getEventMetaData`));
		};

		var getStuffWithNoScore = function () {
			$q.all([
				getEvents('form', true, true),
				getEvents('visit', true, true),
				getEvents('marketingCustom', true, true)
			])
				.then(function (res) {
					$scope.nrMarketingNoScore = res[0].metadata.total + res[1].metadata.total + res[2].metadata.total;
					$scope.nrFormsNoScore = res[0].metadata.total;
					$scope.nrVisitsNoScore = res[1].metadata.total;
					$scope.nrMarketingCustomNoScore = res[2].metadata.total;
				})
				.catch(error => logError(error, `controllers/accountMarket - getStuffWithNoScore`));
		};

		function getData() {
			$scope.graphLoading = true;
			// get data with zero events

			var options = {
				clientId: AccountCtrl.account.id,
				interval: $scope.nrOfMonths === 3 ? 'week' : 'month',
				startDate: moment().subtract($scope.nrOfMonths, 'month').format('YYYY-MM-DD'),
				endDate: moment().add(1, 'day').format('YYYY-MM-DD')
			};

			if (AccountCtrl.hasSubAccounts && AccountCtrl.subAccountIds?.length) {
				options.subAccountIds = AccountCtrl.subAccountIds;
			}

			Account.customer(customerId)
				.getAccountMarket(options)
				.then(function (results) {
					if (results && results.data) {
						$scope.totalVisit =
							$scope.marketGrouping === 'event'
								? results.metadata.visitCount
								: results.metadata.visitScore;
						$scope.totalMail =
							$scope.marketGrouping === 'event' ? results.metadata.mailCount : results.metadata.mailScore;
						$scope.totalForm =
							$scope.marketGrouping === 'event' ? results.metadata.formCount : results.metadata.formScore;
						$scope.totalMarketingCustom =
							$scope.marketGrouping === 'event'
								? results.metadata.marketingcustomCount
								: results.metadata.marketingcustomScore;
						$scope.totalLeadScore = results.metadata.totalScore;
						$scope.totalCount =
							(results.metadata.visitCount || 0) +
							(results.metadata.mailCount || 0) +
							(results.metadata.formCount || 0) +
							(results.metadata.marketingcustomCount || 0) +
							(results.metadata.manualCount || 0);
						$scope.impressionCount = results.metadata.impressionCount;
						$scope.getLeads();
						getAdsData(results);

						if ($scope.allowZeroEvents) {
							$scope.getEvents(true);
						}
					} else {
						$scope.totalCount = 0;
						$scope.totalMailCount = 0;
						$scope.totalFormCount = 0;
						$scope.totalLeadScore = 0;

						$scope.totalVisit = 0;
						$scope.totalMail = 0;
						$scope.totalForm = 0;
						$scope.impressionCount = 0;

						mapGraphData([]);
					}
				})
				.catch(error => logError(error, `controllers/accountMarket - getData`));
		}

		$scope.getLeads = function () {
			$scope.leadsLoading = true;
			var sortKey = $scope.marketGrouping === 'score' ? 'scoreTotal' : 'countTotal';
			var filter = new RequestBuilder();
			filter.addFilter(Lead2.attr.id, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...(AccountCtrl.subAccountIds || [])
			]);
			filter.addFilter({ field: 'scoreTotal', type: 'Number' }, filter.comparisonTypes.GreaterThan, 0);
			filter.addFilter(
				{ field: 'date', type: 'Date' },
				filter.comparisonTypes.GreaterThan,
				moment().subtract($scope.nrOfMonths, 'month').format('YYYY-MM-DD')
			);
			filter.addSort({ field: sortKey, type: 'Number' }, false);
			filter.limit = 5;

			var rb = filter.build();
			rb.showContact = true;
			Lead2.customer(customerId)
				.find(rb)
				.then(function (res) {
					$scope.contacts = res.data || [];
					if ($scope.contacts.length) {
						var contactFilter = new RequestBuilder();
						contactFilter.addFilter(
							Contact.attr.id,
							contactFilter.comparisonTypes.Equals,
							_.pluck($scope.contacts, 'contactId')
						);

						Contact.customer(customerId)
							.find(contactFilter.build())
							.then(function (contacts) {
								_.each(contacts.data, function (c) {
									if (c.title && c.title.length) {
										var index = _.findIndex($scope.contacts, { contactId: c.id });

										if (index !== -1) {
											$scope.contacts[index].title = c.title;
										}
									}
								});
								$scope.leadsLoading = false;
							})
							.catch(error => logError(error, `controllers/accountMarket - getLeads`));
					} else {
						$scope.leadsLoading = false;
					}
				})
				.catch(error => logError(error, `controllers/accountMarket - getLeads`));
		};

		$scope.changeHistoryType = function (type) {
			if (type === $scope.historyType) {
				return;
			}
			$scope.historyType = type;
			$scope.getEvents(true);
			getEventMetaData();
		};

		function getAdsData(data) {
			var campaignIds = data.metadata.adCampaignIds;

			if (campaignIds && campaignIds.length) {
				Ads.customer(customerId)
					.find({ id: campaignIds })
					.then(function (campaigns) {
						$scope.adCampaignNames = '';
						campaigns.data.forEach(function (c) {
							$scope.adCampaignNames += '\n' + c.name;
						});
						mapGraphData(data.data);
					})
					.catch(error => logError(error, `controllers/accountMarket - getAdsData`));
			} else {
				mapGraphData(data.data);
			}
		}

		$scope.previewPopup = function (creativeId) {
			preview({ id: creativeId, isGroup: false });
		};

		$scope.timelineHeader = function (date) {
			return moment(date).format('MMMM YYYY');
		};

		$scope.showHeader = function (obj1, obj2) {
			if (!obj2 || !obj1) {
				return true;
			}
			return moment(obj1.date).format('YYYY-MM') !== moment(obj2.date).format('YYYY-MM');
		};

		$scope.resetScoreForContact = function (contact) {
			var params = {
				userId: metadata.user.id,
				customerId: customerId,
				contact: { id: contact.contactId, name: contact.contactName }
			};

			$upModal
				.open('resetScore', params)
				.then(function () {
					// Remove row from board
					var index = _.findIndex($scope.contacts, { contactId: contact.contactId });
					if (index !== -1) {
						$scope.contacts.splice(index, 1);
					}
				})
				.catch(error => logError(error, `controllers/accountMarket - resetScoreForContact`));
		};

		$scope.getEvents = function (resetOffset) {
			$scope.eventsLoading = true;
			if (resetOffset) {
				$scope.eventOffset = 0;
				eventOffset = 0;
			}
			getEvents($scope.historyType);
		};

		function getEvents(historyType, isMetaData, onlyZero) {
			// $scope.eventsLoading = true;
			var eventFilter = new RequestBuilder();
			eventFilter.addFilter(Event.attr.clientId, eventFilter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...(AccountCtrl.subAccountIds || [])
			]);
			if (!$scope.allowZeroEvents && !onlyZero) {
				eventFilter.addFilter({ field: 'feedMarket' }, eventFilter.comparisonTypes.Equals, null);
			} else {
				eventFilter.addFilter({ field: 'feedMarketWithNoScore' }, eventFilter.comparisonTypes.Equals, null);
			}

			if (onlyZero) {
				eventFilter.addFilter(Event.attr.score, eventFilter.comparisonTypes.Equals, 0);
			}

			eventFilter.addSort(Event.attr.date, false);
			eventFilter.limit = isMetaData ? 0 : eventLimit;
			eventFilter.offset = eventOffset;

			switch (historyType) {
				case 'form':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Form']);
					break;
				case 'marketingCustom':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'MarketingCustom'
					]);
					break;
				case 'visit':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Visit']);
					break;

				case 'mail':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'Mail',
						'MailCampaign'
					]);
					break;
			}

			return Event.find(eventFilter.build()).then(function (res) {
				if (res.data.length) {
					// Group Events
					var events = EventService.groupEvents(res.data);
					if (eventOffset === 0) {
						$scope.events = events;
					} else {
						EventService.groupEvents(res.data, $scope.events);
					}
					$scope.totalEvents = res.metadata.total;
					eventOffset += eventLimit;
					$scope.eventsLength += res.data.length;
				} else {
					if (eventOffset === 0) {
						$scope.events = [];
						$scope.totalEvents = 0;
						$scope.eventsLength = 0;
						AccountCtrl.marketActivities = 0;
					}
				}
				switch (historyType) {
					case 'form':
						$scope.totalForm = res.metadata.total;
						break;
					case 'visit':
						$scope.totalVisit = res.metadata.total;
						break;

					case 'mail':
						$scope.totalMail = res.metadata.total;
						break;
					case 'marketingCustom':
						$scope.totalMarketingCustom = res.metadata.total;
						break;
					default:
						$scope.totalAll = res.metadata.total;
						break;
				}
				$scope.eventsLoading = false;

				return res;
			});
		}

		$scope.setGrouping = function (updateEvents) {
			if ($scope.marketGrouping !== marketGrouping || $scope.nrOfMonths !== nrOfMonths) {
				marketGrouping = $scope.marketGrouping;
				nrOfMonths = $scope.nrOfMonths;
				getData();
			}

			if (updateEvents || $scope.allowZeroEvents) {
				$scope.getEvents(true);
				getEventMetaData();
			}
		};

		var init = function () {
			metadata = AppService.getMetadata();
			$scope.allowZeroEvents = false;
			$scope.nrOfMonths = 3;
			nrOfMonths = 3;
			$scope.marketGrouping = 'event';
			marketGrouping = 'event';
			$scope.getEvents();
			$scope.getLeads();

			getStuffWithNoScore();
			weekPrefix = $translate.instant('calendar.weekPrefix') + '.';
			getData();
			getEventMetaData();
			$scope.clientEvent = EventService.create.Client(AccountCtrl.account);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

const createContact = (customerId, account) => {
	const OPTIONS = {
		customerId,
		account
	};

	return new Promise(resolve => {
		Tools.$upModal.open('editContact', OPTIONS).then(contact => {
			return resolve(contact);
		});
	});
};

export default createContact;

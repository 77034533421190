import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

export default function ListMailCampaignsAllowed() {
	const { self } = useSelector(({ App }: RootState) => App);

	if (self?.userParams.mailAdmin || self?.administrator) {
		return true;
	}
	return false;
}

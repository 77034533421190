import BemClass from '@upsales/components/Utils/bemClass';
import DocumentAttributes from 'App/babel/attributes/DocumentAttributes';
import EsignAttributes from 'App/babel/attributes/EsignAttributes';
import Client from 'App/resources/Model/Client';
import React from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import { Esign } from '../../../../resources/Model/Esign';
import SimpleListView from '../../SimpleListView';
import { useTranslation } from 'Components/Helpers/translate';
import './Documents.scss';
import Document from 'App/resources/Model/Document';
import ListViewHeaderTitle from 'App/components/ListView/ListViewHeader/ListViewHeaderTitle';

const COLUMNS_ESIGN = ['mdate', 'filename', 'opportunity', 'status', 'signingParties', 'delete'];
const COLUMNS_DOCUMENTS = ['mimetype', 'filename', 'size', 'uploadDate', 'user', 'fileRelation', 'delete'];

const getEsign = async (rb: RequestBuilder, id: number) => {
	rb.addFilter({ field: 'client.id' }, 'eq', id);
	return Tools.Esign.find(rb.build());
};

const getDocuments = async (rb: RequestBuilder, id: number) => {
	rb.addFilter({ field: 'client.id' }, 'eq', id);
	return Tools.File.find(rb.build());
};

const onDocumentClick =
	({ id }: Document) =>
	() => {
		Tools.File.download(id);
	};

const isDraft = (item: Esign) => item.state === 0;

const onEsignClick = (esign: Esign) => () => {
	const options = {
		esign: {
			client: {
				id: esign.client.id
			}
		},
		id: esign?.id
	};

	// Only open edit if saved as draft
	if (!esign || isDraft(esign)) {
		Tools.$upModal.open('editEsign', options);
	} else {
		Tools.$upModal.open('confirmEsign', options);
	}
};

const onEsignDelete = (item: Esign) => {
	return Tools.Esign.delete(item);
};

const onDocumentDelete = (item: Document) => {
	return Tools.File.delete({ id: item.id });
};

type Props = {
	client: Client;
};

const ClientCardDocuments = ({ client }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('ClientCardDocuments');

	const hasEsign =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ESIGN) &&
		Tools.AppService.getEsignIntegrations().length;

	const hasDocuments =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS) ||
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CLIENT_DOCUMENTS);
	const canAddDocuments = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS);

	return (
		<div className={classes.b()}>
			{hasEsign ? (
				<SimpleListView<Esign>
					classes={classes.elem('esign')}
					getData={(rb: RequestBuilder) => getEsign(rb, client.id)}
					columns={COLUMNS_ESIGN}
					onRowClick={onEsignClick}
					attributes={EsignAttributes}
					useHeader
					renderHeaderFirstExtra={({ total }) => (
						<ListViewHeaderTitle title={t('default.eSign')} total={total} />
					)}
					broadcastType="document"
					initialSorting={[{ attribute: 'mdate', ascending: false }]}
					tableLimitOptions={[25]}
					hideNoResults={true}
					deleteFn={onEsignDelete}
					isDeletable={item => item.userRemovable && isDraft(item)}
					entityName={'default.eSign'}
					noStickyHeader
				/>
			) : null}
			{hasDocuments ? (
				<SimpleListView<Document>
					classes={classes.elem('files')}
					getData={(rb: RequestBuilder) => getDocuments(rb, client.id)}
					columns={COLUMNS_DOCUMENTS}
					onRowClick={onDocumentClick}
					addBtn={canAddDocuments}
					attributes={DocumentAttributes}
					renderHeaderFirstExtra={({ total }) => (
						<ListViewHeaderTitle title={t('file.files')} total={total} />
					)}
					useHeader
					addBtnText={t('default.uploadFile')}
					onAddBtnClick={({ reloadTable }) => {
						return Tools.$upModal
							.open('uploadFile', {
								accountId: client.id
							})
							.finally(() => {
								reloadTable({ silent: true });
							});
					}}
					broadcastType="document"
					initialSorting={[{ attribute: 'uploadDate', ascending: false }]}
					tableLimitOptions={[25]}
					hideNoResults={true}
					deleteFn={onDocumentDelete}
					isDeletable={item => item.userRemovable}
					entityName={'file.file'}
					noStickyHeader
				/>
			) : null}
		</div>
	);
};

export default ClientCardDocuments;

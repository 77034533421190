'use strict';

angular.module('upResources').factory('Role', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	'NotificationService',
	'$upModal',
	function Role($resource, $q, URL, API, ResourceHelper, NotificationService, $upModal) {
		var Role = $resource(
			URL + API + 'roles/:id',
			{},
			{
				//'query':{method:'GET',isArray:false},
				create: {
					method: 'POST',
					isArray: false
				},
				update: {
					method: 'PUT',
					isArray: false
				},
				getSettings: {
					method: 'GET',
					isArray: false,
					url: URL + API + 'roleSettings/:id'
				}
			}
		);

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'role',
			createSuccessBody: 'saved.role',
			updateSuccessBody: 'updated.role',
			deleteSucccessBody: 'deleted.role',
			createErrorBody: 'saveError.role',
			updateErrorBody: 'saveError.role',
			deleteErrorBody: 'deleteError.role'
		});

		var Model = {
			getMap: function () {
				return Role.get({ asMap: true }).$promise;
			},

			new: function () {
				var role = {
					name: '',
					description: '',
					parentId: 1, // DEFAULT?
					defaultCurrency: '',
					defaultSalesboardId: null,
					templateData: {
						access: {
							Client: 1,
							Activity: 1,
							Project: 1,
							Report: 1,
							Opportunity: 1,
							Contact: 1,
							Order: 1,
							Document: 1,
							UserDefObj1: 1,
							UserDefObj2: 1,
							UserDefObj3: 1,
							UserDefObj4: 1,
							Exchange: 1,
							Agreement: 1
						},
						modify: {
							Client: 1,
							Activity: 1,
							Project: 1,
							Report: 1,
							Opportunity: 1,
							Contact: 1,
							Order: 1,
							Document: 1,
							UserDefObj1: 1,
							UserDefObj2: 1,
							UserDefObj3: 1,
							UserDefObj4: 1,
							Exchange: 1,
							Agreement: 1
						},
						delete: {
							Client: 1,
							Activity: 1,
							Project: 1,
							Report: 1,
							Opportunity: 1,
							Contact: 1,
							Order: 1,
							Document: 1,
							UserDefObj1: 1,
							UserDefObj2: 1,
							UserDefObj3: 1,
							UserDefObj4: 1,
							Exchange: 1,
							Agreement: 1
						}
					}
				};

				return role;
			},

			getSettings: function (id) {
				return Role.getSettings({ id: id }).$promise;
			},

			save: function (role, options) {
				var defered = $q.defer();

				if (!role) {
					return defered.reject('No role');
				}

				if (role.id) {
					Role.update(
						{ id: role.id },
						{ role: role },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Role.create(
						{ role: role },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !role.id);
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, !role.id, err);
					});

				return defered.promise;
			},

			delete: function (role, options) {
				return Role.delete({ id: role.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, role);
						return res;
					})
					.catch(function (err) {
						// Notify user
						if (err.data.error && err.data.error.errorCode === 97) {
							$upModal.open('warningAlert', {
								icon: 'fa-warning',
								title: 'default.warning',
								body: 'deleteError.userInRole',
								no: 'default.ok'
							});
						} else {
							helper.onDeleteError(options, err);
						}
					});
			}
		};

		return Model;
	}
]);

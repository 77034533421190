import async from 'async';

import InAppChat from 'App/babel/services/InAppChat';
import openModal from 'App/services/Modal';

angular.module('domain.engage').controller('EditAd', [
	'$rootScope',
	'$state',
	'$translate',
	'$stateParams',
	'meta',
	'$upModal',
	'Ads',
	'Visitor',
	'AppService',
	'$anchorScroll',
	'$location',
	'AdService',
	'localStorageService',
	'$scope',
	function (
		$rootScope,
		$state,
		$translate,
		$stateParams,
		meta,
		$upModal,
		Ads,
		Visitor,
		AppService,
		$anchorScroll,
		$location,
		AdService,
		localStorageService,
		$scope
	) {
		var EditAdCtrl = this;
		var now = moment();

		EditAdCtrl.customerId = $stateParams.customerId;

		EditAdCtrl.minDates = {
			start: now.subtract(1, 'days').format('YYYY-MM-DD'),
			end: now.subtract(1, 'days').format('YYYY-MM-DD')
		};
		EditAdCtrl.calculatedImpressions = 0;
		EditAdCtrl.statuses = Ads.statuses;
		EditAdCtrl.disableEditing = AdService.disableEditing;
		EditAdCtrl.validateLaunch = AdService.validateLaunch;
		EditAdCtrl.allowedActions = AdService.allowedActions;

		EditAdCtrl.saveable = true;
		EditAdCtrl.allowStateChange = true;

		EditAdCtrl.errorMessages = {
			CantUpdateAdCampaignWhenStatusIsActivating: 'readOnly',
			CantUpdateAdCampaignWhenStatusIsError: 'readOnly',
			InvalidStatusChange: 'status',
			NotEnoughAdCredits: 'budget',
			CantDecreaseActiveAdCampaignBudget: 'budget',
			TooLowBudget: 'budget',
			TooHighBudget: 'budget',
			CantSetSAdCampaignStartDateToAPassedDate: 'startDate',
			CantChangeActiveStartDateOnceItHasPassed: 'startDate',
			CantSetEndDateToEarlierOnceAdCampaignIsActive: 'endDate',
			CantLaunchAdCampaignWithoutCreative: 'creative',
			ActiveAdCampaignIsMissingCreatives: 'creative',
			CantLaunchAdCampaignWithoutSiteTemplates: 'siteTemplate',
			ActiveAdCampaignIsMissingSiteTemplate: 'siteTemplate'
		};

		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromStateParams, options) {
			if (EditAdCtrl.isDirty && !EditAdCtrl.allowStateChange) {
				EditAdCtrl.allowStateChange = true;
				event.preventDefault();
				options.location = true;
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								await EditAdCtrl.save();
							}
							$state.go(toState, toParams, options);
						}
					});
					return;
				}

				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'confirm.abortEdit',
						body: 'confirm.changesWillBeLost',
						resolveTrue: 'default.abortEdit',
						no: 'default.continueEdit'
					})
					.then(
						function () {
							$state.go(toState, toParams, options);
						},
						function () {
							EditAdCtrl.allowStateChange = false;
						}
					);
			}
		});

		EditAdCtrl.goToBannerEditor = function () {
			EditAdCtrl.allowStateChange = true;
			// Save campaign if it is drafted already
			// if(EditAdCtrl.ad.id) {
			// 	EditAdCtrl.saving = true;
			// 	var obj = angular.copy(EditAdCtrl.ad);
			// 	Ads.customer(EditAdCtrl.customerId).save(obj).then(function() {
			// 		$state.go('selectCreativeTemplate', {resolveId: EditAdCtrl.ad.id});
			// 	}).catch(function () {
			// 		EditAdCtrl.saving = false;
			// 	});
			// } else {
			// Clear localstorage for snapshots olter than 3h
			var keys = localStorageService.keys();
			_.each(keys, function (key) {
				if (key.indexOf('adCampaign-snapshot-') === 0) {
					var ms = key.replace('adCampaign-snapshot-', '');
					ms = parseInt(ms);
					ms = Date.now() - ms;
					var diff = moment.duration(ms).hours();

					if (diff > 3) {
						localStorageService.remove(key);
					}
				}
			});
			var key = Date.now();
			var obj = angular.copy(EditAdCtrl.ad);
			obj.creative = EditAdCtrl.activeCreatives;
			localStorageService.add('adCampaign-snapshot-' + key, obj);
			$state.go('selectCreativeTemplate', { resolveKey: key });
			// }
		};

		EditAdCtrl.save = function () {
			EditAdCtrl.saving = true;
			EditAdCtrl.allowStateChange = true;
			var obj = angular.copy(EditAdCtrl.ad);
			obj.creative = EditAdCtrl.activeCreatives;
			if (!obj.name) {
				obj.name = $translate.instant('ads.noNameCampaign');
			}
			return Ads.customer(EditAdCtrl.customerId)
				.save(obj)
				.then(function (res) {
					EditAdCtrl.saving = false;
					$state.go('viewAd.dashboard', res.data);
				})
				.catch(function () {
					EditAdCtrl.saving = false;
					EditAdCtrl.allowStateChange = false;
				});
		};

		EditAdCtrl.isChatEnabled = () => {
			return InAppChat.isEnabledAndLoaded();
		};

		EditAdCtrl.openChat = e => {
			e.preventDefault();
			InAppChat.open();
		};

		EditAdCtrl.adTarget = function (type) {
			if (type === 'targetOfficeHours') {
				EditAdCtrl.ad.targetOfficeHours = 1;
				return;
			}
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('configureAdTargets', {
					customerId: EditAdCtrl.customerId,
					type: type,
					adCampaign: EditAdCtrl.ad
				})
				.then(function (res) {
					EditAdCtrl.ad = res;
					$rootScope.$broadcast('adCampaign.targetChange', {
						type: type,
						target: EditAdCtrl.ad.target,
						subtype: EditAdCtrl.ad.targetAbm === 2 ? 2 : null
					});
					EditAdCtrl.validateForm();
				});
		};

		EditAdCtrl.removeTarget = function (type) {
			if (type === 'targetAbm') {
				EditAdCtrl.ad.targetAbm = 0;
			}
			if (type === 'targetRetargeting') {
				EditAdCtrl.ad.targetRetargeting = 0;
			}
			if (type === 'targetLocation') {
				EditAdCtrl.ad.targetLocation = 0;
			}
			if (type === 'targetOfficeHours') {
				EditAdCtrl.ad.targetOfficeHours = 0;
			}
		};

		EditAdCtrl.allin = function () {
			EditAdCtrl.ad.budget = EditAdCtrl.credits;
			EditAdCtrl.validateForm();
			$('.allin').show();
		};

		EditAdCtrl.changeDate = function (type) {
			var start = moment(EditAdCtrl.ad.startDate);
			var end = moment(EditAdCtrl.ad.endDate);
			if (type === 'start') {
				if (end.isBefore(start)) {
					EditAdCtrl.ad.endDate = EditAdCtrl.ad.startDate;
				}
			} else if (type === 'end') {
				if (end.isBefore(start)) {
					EditAdCtrl.ad.startDate = EditAdCtrl.ad.endDate;
				}
			}
			EditAdCtrl.validateForm();
		};

		EditAdCtrl.scrolltoHref = function (id, offset) {
			// set the location.hash to the id of
			// the element you wish to scroll to.
			$location.hash(id);
			// call $anchorScroll()
			$anchorScroll(id);

			if (offset) {
				setTimeout(function () {
					scrollBy(0, -155);
				}, 0);
			}
		};

		EditAdCtrl.mapTemplates = function (template) {
			_.each(EditAdCtrl.siteTemplates, function (tmpl) {
				if (tmpl.id === template.id) {
					tmpl.active = !tmpl.active;
				} else {
					tmpl.active = false;
				}
			});

			EditAdCtrl.ad.siteTemplate = [];
			if (template.active) {
				EditAdCtrl.ad.siteTemplate.push({ sitetemplateId: template.externalId, active: true });
			}

			EditAdCtrl.validateForm();
		};

		EditAdCtrl.templateFilter = function (item) {
			return item.active;
		};

		EditAdCtrl.addCreative = function () {
			var key = Date.now();
			var obj = angular.copy(EditAdCtrl.ad);
			obj.creative = EditAdCtrl.activeCreatives;
			localStorageService.add('adCampaign-snapshot-' + key, obj);

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('selectCreatives', {
					creatives: EditAdCtrl.ad.creative,
					customerId: EditAdCtrl.customerId,
					resolveKey: key
				})
				.then(function (res) {
					EditAdCtrl.ad.creative = [];
					_.forEach(res, function (n) {
						EditAdCtrl.ad.creative.push({
							creativeId: n.id,
							active: true
						});
					});
					EditAdCtrl.activeCreatives = _.where(EditAdCtrl.ad.creative, { active: true });
					EditAdCtrl.validateForm();
				});
		};

		EditAdCtrl.validatePage = function (url, obj) {
			obj.validPage = 'loading';
			Visitor.customer(EditAdCtrl.customerId)
				.validatePage(url)
				.then(function (res) {
					obj.validPage = res.data;
				})
				.catch(function () {
					obj.validPage = 'err';
				});
		};

		EditAdCtrl.deleteAd = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'ads.confirmDeleteAdTitle',
					body: 'ads.confirmDeleteAdBody',
					confirmButtonText: 'ads.confirmDeleteAdTitle',
					headerIcon: 'trash',
					onClose: confirmed => {
						if (confirmed) {
							Ads.customer(EditAdCtrl.customerId)
								.delete(EditAdCtrl.ad)
								.then(function () {
									$state.go('listAds', EditAdCtrl.ad);
								})
								.catch(e => console.log('delete ad error', e));
						}
					}
				});
				return;
			}
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('errorConfirm', {
					title: 'ads.confirmDeleteAdTitle',
					body: 'ads.confirmDeleteAdBody',
					resolveTrue: 'ads.confirmDeleteAdTitle',
					icon: 'fa-trash'
				})
				.then(function () {
					Ads.customer(EditAdCtrl.customerId)
						.delete(EditAdCtrl.ad)
						.then(function () {
							$state.go('listAds', EditAdCtrl.ad);
						})
						.catch(e => console.log('delete ad error', e));
				});
		};

		EditAdCtrl.abortEdit = function () {
			$state.go('listAds');
		};

		EditAdCtrl.loadTemplatesOnInit = function () {
			if (!EditAdCtrl.ad || !EditAdCtrl.ad.siteTemplate) {
				EditAdCtrl.ad.siteTemplate = [];
			}
			_.forEach(EditAdCtrl.siteTemplates, function (tmpl) {
				var found = _.find(EditAdCtrl.ad.siteTemplate, { sitetemplateId: tmpl.externalId, active: true });
				tmpl.active = !!found;
				tmpl.activeCpm = found ? found.cpm : null;
			});
		};

		EditAdCtrl.validateForm = function (isInit) {
			if (!isInit) {
				EditAdCtrl.isDirty = true;
			}
			var oldAd = EditAdCtrl.oldAd ? angular.copy(EditAdCtrl.oldAd) : angular.copy(EditAdCtrl.ad);
			var newAd = angular.copy(EditAdCtrl.ad);
			EditAdCtrl.minImpressions = 0;
			EditAdCtrl.maxImpressions = 0;

			EditAdCtrl.saveError = {
				budget: false,
				startDate: false,
				endDate: false,
				siteTemplate: false,
				creative: false
			};

			EditAdCtrl.launchError = {
				budget: false,
				startDate: false,
				endDate: false,
				siteTemplate: false,
				creative: false
			};
			EditAdCtrl.saveable = true;

			EditAdCtrl.saveErrors = AdService.invalidUpdates(
				oldAd,
				newAd,
				EditAdCtrl.credits,
				EditAdCtrl.adAccount ? EditAdCtrl.adAccount.values : null,
				true
			);
			if (EditAdCtrl.ad.status === EditAdCtrl.statuses.draft) {
				EditAdCtrl.launchErrors = AdService.invalidUpdates(
					newAd,
					{ status: EditAdCtrl.statuses.launch },
					EditAdCtrl.credits,
					EditAdCtrl.adAccount ? EditAdCtrl.adAccount.values : null,
					true
				);
			} else {
				EditAdCtrl.launchErrors = EditAdCtrl.saveErrors;
			}
			_.forEach(EditAdCtrl.saveErrors, function (errorMsg) {
				EditAdCtrl.saveError[EditAdCtrl.errorMessages[errorMsg]] = errorMsg;
				EditAdCtrl.saveable = false;
			});
			_.forEach(EditAdCtrl.launchErrors, function (errorMsg) {
				EditAdCtrl.launchError[EditAdCtrl.errorMessages[errorMsg]] = errorMsg;
			});

			_.forEach(EditAdCtrl.siteTemplates, function (tmpl) {
				var imp;
				if (!tmpl.active) {
					return;
				}
				imp = EditAdCtrl.ad.budget / (tmpl.cpm / 1000);
				if (!EditAdCtrl.minImpressions) {
					EditAdCtrl.minImpressions = imp;
				}
				if (imp < EditAdCtrl.minImpressions) {
					EditAdCtrl.minImpressions = imp;
				}
				if (imp > EditAdCtrl.maxImpressions) {
					EditAdCtrl.maxImpressions = imp;
				}
			});
		};

		function init() {
			EditAdCtrl.loading = true;
			EditAdCtrl.adAccount = AppService.getAdAccount();

			if (meta && meta.ad && meta.ad.data) {
				EditAdCtrl.ad = meta.ad.data;
				if (!EditAdCtrl.ad.id) {
					// copy from old ad campaign
					var dateSpan = moment(EditAdCtrl.ad.endDate).diff(moment(EditAdCtrl.ad.startDate), 'days');
					EditAdCtrl.isNew = true;
					EditAdCtrl.ad.status = Ads.statuses.draft;
					EditAdCtrl.ad.startDate = new Date();
					EditAdCtrl.ad.endDate = moment().add(dateSpan, 'days').toDate();
					EditAdCtrl.ad.siteTemplate = _.where(EditAdCtrl.ad.siteTemplate, { active: true });
					EditAdCtrl.ad.creative = _.where(EditAdCtrl.ad.creative, { active: true });
				} else {
					EditAdCtrl.oldAd = angular.copy(EditAdCtrl.ad);
				}
			} else {
				// new ad campaign
				EditAdCtrl.isNew = true;
				EditAdCtrl.ad = {
					budget: 0,
					startDate: new Date(),
					endDate: moment().add(1, 'month').toDate(),
					status: Ads.statuses.draft,
					useUrlFromCreative: true,
					creative: [],
					siteTemplate: [],
					target: [],
					targetOfficeHours: 0,
					targetLocation: 0,
					targetRetargeting: 0
				};
			}

			EditAdCtrl.isRunning = AdService.isRunning(EditAdCtrl.ad);
			EditAdCtrl.credits = meta.credits.data;

			if (EditAdCtrl.isRunning) {
				EditAdCtrl.startDateHasPassed = moment(EditAdCtrl.ad.startDate).isBefore(moment());
			}

			EditAdCtrl.isFinished = EditAdCtrl.ad.status >= EditAdCtrl.statuses.finished;

			EditAdCtrl.validPage = {};
			EditAdCtrl.activeCreatives = _.where(EditAdCtrl.ad.creative, { active: true });

			if (!EditAdCtrl.ad.target) {
				EditAdCtrl.ad.target = [];
			}

			async.series(
				[
					function (cb) {
						Ads.customer(EditAdCtrl.customerId)
							.getSiteTemplates()
							.then(function (res) {
								EditAdCtrl.siteTemplates = res.data;
								EditAdCtrl.loadTemplatesOnInit();
								return cb();
							})
							.catch(function (e) {
								EditAdCtrl.siteTemplatesErr = e;
								return cb();
							});
					}
				],
				function (err) {
					EditAdCtrl.loading = false;
					EditAdCtrl.loadingErr = err;
					if (!err) {
						EditAdCtrl.validateForm(true);
					}
					EditAdCtrl.allowStateChange = false;
				}
			);
		}

		AppService.loadedPromise.then(init).catch(e => console.log('load engage edit error', e));
	}
]);

'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.engage').controller('CreativeEditor', [
	'$scope',
	'$rootScope',
	'$stateParams',
	'AppService',
	'meta',
	'BannerGroup',
	'$state',
	'$upModal',
	'AccountProfile',
	function ($scope, $rootScope, $stateParams, AppService, meta, BannerGroup, $state, $upModal, AccountProfile) {
		var CreativeEditor = this;
		var isDirty = false;
		var allowStateChange = true;
		var stateHash;

		$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromStateParams, options) {
			if (isDirty && !allowStateChange) {
				allowStateChange = true;
				event.preventDefault();

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					// The reason I am not using the UnsavedChangesAlert here is that there is no easy way to save the
					// changes from here, since it seems it is stored in a component state in a different component (ReactTemplates.bannerEditor.root)
					openModal('Alert', {
						title: 'confirm.abortEdit',
						body: 'confirm.changesWillBeLost',
						confirmButtonText: 'default.abortEdit',
						cancelButtonText: 'default.continueEdit',
						onClose: confirmed => {
							if (confirmed) {
								$state.go(toState, toParams, options);
							} else {
								allowStateChange = false;
							}
						}
					});
					return;
				}

				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'confirm.abortEdit',
						body: 'confirm.changesWillBeLost',
						resolveTrue: 'default.abortEdit',
						no: 'default.continueEdit'
					})
					.then(
						function () {
							$state.go(toState, toParams, options);
						},
						function () {
							allowStateChange = false;
						}
					);
			}
		});

		var onSave = function (config, isDraft) {
			var group = angular.copy(config);

			if (isDraft) {
				group.draft = 1;
			} else {
				group.draft = 0;
			}

			return BannerGroup.save(group, { skipNotification: true });
		};

		var onChange = function (newStateHash, saved) {
			isDirty = true;

			if (newStateHash !== stateHash && !saved) {
				allowStateChange = false;
			} else {
				allowStateChange = true;
			}
		};

		var addPreset = function (color) {
			CreativeEditor.rootData.accountProfile.extraColors.push({ value: color });
			return AccountProfile.save(CreativeEditor.rootData.accountProfile);
		};

		CreativeEditor.rootData = {
			loaded: false,
			onSave: onSave,
			onChange: onChange,
			addPreset: addPreset
		};

		var init = function () {
			CreativeEditor.rootData.accountProfile = meta.accountProfile.data;
			CreativeEditor.rootData.loaded = true;
			CreativeEditor.rootData.creative = meta.config;
			CreativeEditor.rootData.markdown = meta.config.body;

			stateHash = Tools.LZString.compressToBase64(JSON.stringify(CreativeEditor.rootData.creative));
		};

		AppService.loadedPromise.then(init);
	}
]);

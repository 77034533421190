'use strict';

angular.module('domain.appointment').factory('UpdateAppointmentMultiMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function () {
			var promises = {
				activeUsers: $q.when({ data: AppService.getActiveUsers() }),
				appointmentTypes: $q.when({ data: AppService.getActivityTypes('appointment', true) }),
				appointmentCustomFields: $q.when({ data: AppService.getCustomFields('appointment') })
			};

			return $q.all(promises);
		};
	}
]);

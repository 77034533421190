import './AddOption.scss';

import React, { useState, useRef, useEffect, useMemo } from 'react';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Text, Block, Input, Button } from '@upsales/components';

const AddOption = ({ options, langMap, onChange, max }) => {
	const dayMinLimit = 0;
	const classes = new BemClass('AddOption');

	const inputRef = useRef(null);
	const errorResetTimer = useRef(null);
	const [days, setDays] = useState('');
	const [expanded, setExpanded] = useState(false);
	const [showInputError, setShowInputError] = useState(false);
	const [inputErrorMessage, setInputErrorMessage] = useState('');

	const errorMessageLang = {
		showDayMaxLimitError: T('agreement.inputError.exceedsDayMaxLimit'),
		alreadyExistingOption: T('agreement.inputError.alreadyExistingOption')
	};

	const saveDisabled = useMemo(() => {
		return days === '' || showInputError;
	}, [days, showInputError]);

	const toggleExpanded = () => {
		const newIsExpanded = !expanded;
		setExpanded(newIsExpanded);
		if (newIsExpanded && inputRef.current) {
			inputRef.current.focus();
		}
	};

	const setInputRef = r => {
		inputRef.current = r;
	};

	const clearErrorTimer = () => {
		clearTimeout(errorResetTimer.current);
	};

	const resetErrorMessage = () => {
		clearErrorTimer();
		const animationDelay = 200;
		errorResetTimer.current = setTimeout(() => {
			setInputErrorMessage('');
		}, animationDelay);
	};

	useEffect(() => {
		return () => clearErrorTimer();
	}, []);

	const alreadyExistingOption = value => {
		const foundOption = options.find(option => option === value);
		return foundOption !== undefined;
	};

	const handleInputChange = event => {
		if (inputErrorMessage) {
			resetErrorMessage();
			setShowInputError(false);
		}
		if (!event?.target?.value) {
			setDays('');
			return;
		}

		const value = parseInt(event.target.value, 10);
		if (value >= dayMinLimit) {
			setDays(value);
		}
		if (alreadyExistingOption(value)) {
			clearErrorTimer();
			setInputErrorMessage(errorMessageLang.alreadyExistingOption);
			setShowInputError(true);
		} else if (max && value > max) {
			clearErrorTimer();
			setInputErrorMessage(errorMessageLang.showDayMaxLimitError);
			setShowInputError(true);
		}
	};

	const saveOption = async () => {
		if (!days || showInputError) {
			return;
		}
		const updatedOptions = [...options];
		updatedOptions.push(parseInt(days, 10));
		onChange(updatedOptions);
		setDays('');
		setExpanded(false);
	};

	const onKeyUp = event => {
		if (showInputError) {
			return;
		}
		if (event.key === 'Enter') {
			saveOption();
		}
	};

	const renderAddOptionButton = () => (
		<Button
			type="link"
			color="bright-blue"
			onClick={toggleExpanded}
			className={classes.elem('addOptionButton').mod({ visible: !expanded, hidden: expanded }).b()}
		>
			<Block space="mrl">
				<Icon name="plus" />
			</Block>
			{T('agreement.addCreateOrdersInAdvanceOption')}
		</Button>
	);

	const renderInput = () => (
		<div className={classes.elem('inputContainer').b()}>
			<Block space="mrm">
				<Text>{langMap.textBeforeInput}</Text>
			</Block>
			<Input
				min={0}
				value={days}
				type="number"
				onKeyUp={onKeyUp}
				autofocus={expanded}
				state={showInputError ? 'error' : 'focus'}
				inputRef={setInputRef}
				onChange={handleInputChange}
				className={classes.elem('optionInput').b()}
				placeholder={langMap.placeholder}
			/>
			<Block space="mlm">
				<Text className={classes.elem('textAfterInput').b()}>
					{days === 1 ? langMap.textAfterInput : langMap.textAfterInputPlural}
				</Text>
			</Block>
		</div>
	);

	const renderActionButtons = () => (
		<div className={classes.elem('buttonContainer').b()}>
			<Block space="mrm">
				<Button type="link" color="grey" className={classes.elem('cancelEdit').b()} onClick={toggleExpanded}>
					{T('agreement.cancelEditOfCreateOrdersInAdvanceOption')}
				</Button>
			</Block>
			<Button
				onClick={saveOption}
				disabled={saveDisabled}
				color={saveDisabled ? 'grey' : undefined}
				className={classes.elem('saveOption').b()}
			>
				<Block space="mrl">
					<Icon name="plus" />
				</Block>
				{T('agreement.addCreateOrdersInAdvanceOption')}
			</Button>
		</div>
	);

	const renderEditView = () => (
		<div
			className={classes
				.elem('editContainer')
				.mod({
					visible: expanded,
					hidden: !expanded
				})
				.b()}
		>
			{renderInput()}
			{renderActionButtons()}
		</div>
	);

	const renderInputError = () => (
		<Block className={classes.elem('inputErrorContainer').mod({ visible: showInputError }).b()}>
			<Text italic color="red" className={classes.elem('inputError')}>
				{inputErrorMessage}
			</Text>
		</Block>
	);

	return (
		<Block space="ptl" className={classes.b()}>
			{renderAddOptionButton()}
			{renderEditView()}
			{renderInputError()}
		</Block>
	);
};

export default AddOption;

import { insertAfter } from 'App/babel/store/helpers/array';

angular.module('upAttributes').service('PhoneCallAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	function (FilterType, DisplayType, FeatureHelper, AppService) {
		return function () {
			var attributes = {
				id: {
					title: 'voice.id',
					field: 'id',
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'id'
				},
				date: {
					title: 'default.date',
					field: 'date',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'date',
					selectableColumn: true
				},
				phoneNumber: {
					title: 'voice.phoneNumber',
					field: 'phoneNumber',
					type: FilterType.String,
					displayType: DisplayType.String,
					sortable: 'phoneNumber',
					selectableColumn: true
				},
				durationInS: {
					title: 'voice.callLength',
					field: 'durationInS',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'durationInS',
					selectableColumn: true
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'contacts',
					groupable: false,
					sortable: 'contact.name',
					selectableColumn: true,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'user.name',
					selectableColumn: true,
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					}
				},
				contactAndClient: {
					title: 'default.contactAndClient',
					field: 'contactAndClient',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					sortable: 'client.name'
				},
				related: {
					title: 'voice.relatedObject',
					field: 'related',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					sortable: false
				},
				client: {
					title: 'default.account',
					field: 'client.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: true,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				custom: {}
			};

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'custom'
			];

			var standardFilters = {
				Date: {
					field: 'date',
					title: 'order.date',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				Contact: {
					field: 'contact.id',
					inputType: 'select',
					multiple: true,
					resource: 'Contact',
					type: 'list',
					displayType: 'listLazy',
					includeFields: ['name'],
					searchFn: function (Contact, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Contact.attr.name, true);

							if (term) {
								filter.addFilter(Contact.attr.name, filter.comparisonTypes.Search, term);
							}

							return Contact.customer(cId).find(filter.build());
						};
					},
					search: true,
					searchField: 'name',
					multiComparitors: true,
					displayText: function (res) {
						return res.name;
					},
					title: 'default.contacts'
				},
				Account: {
					field: 'client.id',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					displayType: 'listLazy',
					search: true,
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					title: 'default.account',
					parent: 'default.account'
				},
				CallLength: {
					title: 'voice.callLengthInMinutes',
					field: 'durationInS',
					type: 'range',
					inputType: 'range',
					multiplier: 60
				},
				User: {
					field: 'user.id',
					title: 'default.user',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta(AppService.AccessType.ACTIVITY);
					},
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true
				}
			};

			var requiredFields = ['isAppointment', 'custom'];

			if (AppService.loaded) {
				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					DESCRIPTION: 'description',
					DATE: 'date',
					CLOSEDATE: 'closeDate',
					USERS: 'users',
					CONTACT: 'contact',
					ACCOUNT: 'account',
					ACTIVITYTYPE: 'activityType',
					NOTES: 'notes',
					ISAPPOINTMENT: 'isAppointment',
					PROJECT: 'project',
					CUSTOM: 'custom',
					CONTACTS: 'contacts'
				}
			};
		};
	}
]);

import Attribute from './Attribute';

const OnboardingStepAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	sort_id: new Attribute({
		type: Attribute.types.Number,
		field: 'sortId'
	}),
	title: new Attribute({
		type: Attribute.types.String,
		field: 'title'
	}),
	shadow_title: new Attribute({
		type: Attribute.types.String,
		field: 'shadowTitle'
	}),
	substep_title: new Attribute({
		type: Attribute.types.String,
		field: 'substepTitle'
	}),
	description: new Attribute({
		type: Attribute.types.String,
		field: 'description'
	}),
	substep_description: new Attribute({
		type: Attribute.types.String,
		field: 'substepDescription'
	}),
	video_link: new Attribute({
		type: Attribute.types.String,
		field: 'videoLink'
	}),
	article_title: new Attribute({
		type: Attribute.types.String,
		field: 'articleTitle'
	}),
	article_link: new Attribute({
		type: Attribute.types.String,
		field: 'articleLink'
	}),
	step_type: new Attribute({
		type: Attribute.types.String,
		field: 'stepType'
	}),
	has_sub_steps: new Attribute({
		type: Attribute.types.Boolean,
		field: 'hasSubSteps'
	})
};

window.OnboardingStepAttributes = OnboardingStepAttributes;

export default OnboardingStepAttributes;

'use strict';

angular
	.module('upsalesApp')
	.constant('DEFAULT_INVALID_DOMAINS', [
		{ name: 'gmail' },
		{ name: 'yahoo' },
		{ name: 'hotmail' },
		{ name: 'live' },
		{ name: 'aol' },
		{ name: 'outlook' }
	]);

import { CalendarHeader, Help } from '@upsales/components';
import QuotaTable from 'Components/QuotaTable';
import AdminHeader from 'Components/Admin/AdminHeader';

ReactTemplates.admin.salesQuota = window.ReactCreateClass({
	getInitialState: function () {
		return {
			fullscreen: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			salesQuota: t('campaign.quota'),
			salesQuotaDescription: t('admin.salesQuotaDescription'),
			user: t('default.user'),
			showPreviousYear: t('admin.showPreviousYearOrder'),
			year: t('date.year'),
			showTotalIn: t('admin.showTotalIn'),
			allUsers: t('filters.allUsers'),
			role: t('default.role'),
			fullscreen: t('default.fullscreen'),
			showInactiveUsers: t('admin.includeInactiveAndDeletedUsers')
		};
	},
	showPreviousYear: function () {
		this.props.rootData.filterChange('showPreviousYear', !this.props.rootData.pageData.showPreviousYear);
	},
	toggleShowInactiveUsers: function () {
		this.props.rootData.filterChange('showInactiveUsers', !this.props.rootData.pageData.showInactiveUsers);
	},
	userChange: function (user) {
		this.props.rootData.filterChange('user', user);
	},
	yearChange: function (value) {
		if (value.getFullYear() < 1970) {
			return;
		}
		this.props.rootData.filterChange('year', value.getFullYear());
	},
	currencyChange: function (e) {
		var defaultCurrency = _.find(this.props.rootData.pageData.currencies, { iso: e.target.value });
		this.props.rootData.filterChange('defaultCurrency', defaultCurrency);
	},
	toggleFullScreen: function () {
		this.setState({ fullscreen: !this.state.fullscreen });
	},
	render: function () {
		var loader = <ReactTemplates.loader />;
		var defaultCurrency = this.props.rootData.pageData.defaultCurrency;

		return (
			<div id="admin-page-sales-quota" className={this.state.fullscreen ? 'fullscreen visible' : 'visible'}>
				<AdminHeader
					title={this.lang.salesQuota}
					description={this.lang.salesQuotaDescription}
					image="sales-goals.svg"
					articleId={581}
				/>

				<div id="admin-content">
					<div className="admin-section">
						<div className="admin-table">
							{!this.props.rootData.pageLoading && (
								<div className="table-tools">
									<div className="tool-filter" key="user">
										<label>
											{this.lang.user}
											{'/'}
											{this.lang.role}
										</label>
										<ReactTemplates.INPUTS.upUsers
											value={this.props.rootData.pageData.user}
											data={this.props.rootData.pageData.roleTree}
											tools={this.props.tools}
											placeholder={this.lang.allUsers}
											onChange={this.userChange}
										/>
									</div>

									<div className="tool-filter year" key="year">
										<label>{this.lang.year}</label>
										<CalendarHeader
											date={moment({
												year: this.props.rootData.pageData.year,
												month: 1,
												day: 1
											}).toDate()}
											isMonthView
											autoAlign
											onDateChange={this.yearChange}
										/>
									</div>
									<div className="tool-filter currency" key="currency">
										<Help articleId={1058} />
										<label>{this.lang.showTotalIn}</label>
										<ReactTemplates.INPUTS.upSelect
											className="form-control"
											data={_.map(this.props.rootData.pageData.currencies, function (y) {
												return { id: y.iso, name: y.iso };
											})}
											defaultValue={defaultCurrency.iso}
											required={true}
											onChange={this.currencyChange}
										/>
									</div>
									<div className="toggle-wrap">
										<ReactTemplates.upFilters.components.toggle
											className="toggle-bright-blue toggle-sm"
											value={this.props.rootData.pageData.showInactiveUsers}
											onChange={this.toggleShowInactiveUsers}
										/>
										<label>{this.lang.showInactiveUsers}</label>
									</div>
									<div className="toggle-wrap">
										<ReactTemplates.upFilters.components.toggle
											className="toggle-bright-blue toggle-sm"
											value={this.props.rootData.pageData.showPreviousYear}
											onChange={this.showPreviousYear}
										/>
										<label>{this.lang.showPreviousYear}</label>
									</div>
									{ReactTemplates.TOOLS.withTooltip(
										<button
											id="toggle-fullscreen"
											type="button"
											className="up-btn btn-grey btn-link"
											onClick={this.toggleFullScreen}
										>
											{!this.state.fullscreen ? (
												<b className="fa fa-expand" />
											) : (
												<b className="fa fa-compress" />
											)}
										</button>,
										this.lang.fullscreen,
										{
											placement: 'left',
											style: { top: '10px', right: '10px', position: 'absolute' }
										}
									)}
								</div>
							)}
							{!this.props.rootData.pageData.tableLoading ? <QuotaTable {...this.props} /> : null}
							<div className={!this.props.rootData.pageData.tableLoading ? 'hide' : ''}>{loader}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

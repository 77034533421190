import React, { useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, Icon, Tooltip, Block } from '@upsales/components';
import './MailTemplatePreviewColumnTools.scss';
import T from 'Components/Helpers/translate';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

type MailTemplatePreviewColumnToolsProps = {
	onRemove: () => void;
	dragHandleProps: DraggableProvidedDragHandleProps | undefined;
};

export default ({ onRemove, dragHandleProps }: MailTemplatePreviewColumnToolsProps) => {
	const classes = useMemo(() => new BemClass('MailTemplatePreviewColumnTools'), []);
	return (
		<div className={classes.b()}>
			<Block className={classes.elem('drag-handle').b()} {...dragHandleProps}>
				<Icon name="arrows" color="grey-10" />
			</Block>
			<Tooltip title={T('mailTemplate.removeColumn')} className={classes.elem('remove').b()}>
				<Button
					onClick={e => {
						e.stopPropagation();
						onRemove();
					}}
					type="link"
					color="grey-10"
					size="sm"
				>
					<Icon name="trash" />
				</Button>
			</Tooltip>
		</div>
	);
};

import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Text, Icon, Link } from '@upsales/components';
import SupersearchResultRow from './SupersearchResultRow';
import SupersearchHighlight from './SupersearchHighlight';
import T from 'Components/Helpers/translate';

const getDate = (momentDate, closed) => {
	let str = '';
	if (momentDate.isValid()) {
		// If date is in future we display date calendar
		const today = new Date();
		if (momentDate.isSame(today, 'day') || momentDate.isAfter(today)) {
			const d = momentDate.calendar();

			// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
			str = d !== '0' ? d : momentDate.format('L');
		} else {
			// check for IE since moment obj becomes weird in IE..
			str = Tools.browserService.isIE()
				? moment(momentDate.format('YYYY-MM-DD')).fromNow()
				: momentDate.fromNow(); // else we display ex. "4 years ago"
		}
	}

	return str ? `${closed ? T('default.wasClosed') : ''} ${str}` : null;
};

const getClientLink = (closeSearch, client, color) =>
	client ? (
		<Link
			onClick={e => {
				e.stopPropagation();
				closeSearch();
			}}
			href={Tools.$state.href('account.dashboard', {
				id: client.id,
				customerId: Tools.AppService.getCustomerId()
			})}
			color={color}
		>
			{client.name}
		</Link>
	) : null;
const getContactLink = (closeSearch, contacts) =>
	contacts && contacts.length ? (
		<Link
			onClick={e => {
				e.stopPropagation();
				closeSearch();
			}}
			href={Tools.$state.href('contact.dashboard', {
				id: contacts[0].id,
				customerId: Tools.AppService.getCustomerId()
			})}
		>
			{contacts[0].name}
		</Link>
	) : null;

const getClientAndContact = (activity, closeSearch) => {
	let title;
	let subtitle;

	if (activity.contacts && activity.contacts.length) {
		title = getContactLink(closeSearch, activity.contacts);
		subtitle = activity.client ? getClientLink(closeSearch, activity.client, 'grey-12') : null;
	} else if (activity.client) {
		title = activity.client ? getClientLink(closeSearch, activity.client) : null;
	}

	return {
		title,
		subtitle
	};
};

const SupersearchResultRowActivity = ({ className, item: activity, searchStr, closeSearch, ...props }) => {
	const classes = new bemClass('SupersearchResultRowActivity', className);
	const closed = !!activity.closeDate;
	const dateStr = getDate(moment(closed ? activity.closeDate : activity.date), closed);
	const { title, subtitle } = getClientAndContact(activity, closeSearch);

	return (
		<SupersearchResultRow
			className={classes.b()}
			users={activity.users ? activity.users : []}
			inactive={closed}
			searchStr={searchStr}
			customValues={activity.custom}
			{...props}
		>
			<Column size={4}>
				<Text bold={true} color={closed ? 'grey-10' : 'black'} size="md" italic={closed}>
					<SupersearchHighlight searchStr={searchStr} text={activity.description} type="string" />
				</Text>

				<Text color="grey-12" size="sm" italic={closed}>
					{dateStr ? (
						<Fragment>
							<Icon name="calendar-o" /> {dateStr}
						</Fragment>
					) : null}
				</Text>
			</Column>
			<Column>
				{title ? (
					<Text size="sm" italic={closed}>
						{title}
					</Text>
				) : null}
				{subtitle ? (
					<Text size="sm" italic={closed}>
						{subtitle}
					</Text>
				) : null}
			</Column>
		</SupersearchResultRow>
	);
};

SupersearchResultRowActivity.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	closeSearch: PropTypes.func
};

export default SupersearchResultRowActivity;

'use strict';

angular.module('upResources').factory('FormSubmit', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'FormSubmitAttributes',
	'ResourceHelper',
	'ParseGeneric',
	function FormSubmit($resource, $q, URL, API, GenericMapper, FormSubmitAttributes, ResourceHelper, ParseGeneric) {
		var AA = FormSubmitAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'formSubmit',
			createSuccessBody: 'saved.formSubmit',
			updateSuccessBody: 'updated.formSubmit',
			deleteSucccessBody: 'deleted.formSubmit',
			createErrorBody: 'saveError.formSubmit',
			updateErrorBody: 'saveError.formSubmit',
			deleteErrorBody: 'deleteError.formSubmit'
		});

		var dateFields = ['regDate', 'processedDate'];

		var Resource = $resource(
			URL + API + 'formSubmits/:id',
			{},
			{
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: true, custom: 'formSubmit', dateFields }),
					skipDateConvert: true
				},
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (submit, options) {
				var promise;

				if (submit.id) {
					promise = Resource.update({ id: submit.id }, submit).$promise;
				} else {
					promise = Resource.create(submit).$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !submit.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !submit.id);
					});

				return promise;
			},

			delete: function (submit, options) {
				return Resource['delete']({ id: submit })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, submit);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

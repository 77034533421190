'use strict';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

angular.module('upResources').factory('File', [
	'$resource',
	'URL',
	'API',
	'FileAttributes',
	'ResourceHelper',
	'AppService',
	'PUBLICPATH',
	'$upModal',
	'Esign',
	'ParseGeneric',
	'$http',
	'$q',
	'$translate',
	'NotificationService',
	function File(
		$resource,
		URL,
		API,
		FileAttributes,
		ResourceHelper,
		AppService,
		PUBLICPATH,
		$upModal,
		Esign,
		ParseGeneric,
		$http,
		$q,
		$translate,
		NotificationService
	) {
		var AA = FileAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var entityTypes = {
			CONTACT: 'Contact',
			APPOINTMENT: 'Appointment'
		};

		var helper = new ResourceHelper();
		var baseUrl = URL + API + 'files/';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				update: { method: 'PUT', isArray: false },
				delete: { method: 'DELETE', isArray: false },
				makePublic: { method: 'PUT', isArray: false, url: baseUrl + ':id/public' },
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric()
				},
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric()
				}
			}
		);

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'file',
			deleteSucccessBody: 'deleted.file',
			deleteErrorBody: 'deleteError.file'
		});

		var deleteFile = function (file, options) {
			return Resource.delete({ id: file.id })
				.$promise.then(function (res) {
					// Notify user
					helper.onDelete(options, file);

					return res;
				})
				.catch(function () {
					// Notify user
					helper.onDeleteError(options);
				});
		};

		var Model = {
			customer: function () {
				// We do not care anymore
				return Model;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);

				return Resource.get(params).$promise;
			},

			upload: function (file, options) {
				if (file && file.size > 25000000) {
					NotificationService.addNotification({
						style: NotificationService.style.ERROR,
						title: 'default.error',
						body: 'file.tooLarge',
						icon: 'times'
					});
					return $q.reject('file.tooLarge');
				}

				var deferred = $q.defer();
				var customerId = AppService.getCustomerId();
				var url = URL + API + customerId;

				if (options.accountId) {
					url += '/resources/upload/internal/client/' + options.accountId + '/';
				} else if (options.contactId) {
					url += '/resources/upload/internal/contact/' + options.contactId + '/';
				} else if (options.orderId) {
					url += '/resources/upload/internal/order/' + options.orderId + '/';
				} else if (options.isCreative) {
					url += '/resources/upload/external/ads/creative/';
				} else if (options.library) {
					url += '/resources/upload/internal/general/0';
				} else if (options.fileEntity) {
					url += '/resources/upload/internal/' + options.fileEntity + '/' + (options.fileId || 0);
				}

				$http({
					method: 'POST',
					url: url,
					data: file,
					transformRequest: function (data, headersGetter) {
						var headers = headersGetter();
						headers['Content-Type'] = undefined;

						/* eslint-disable eqeqeq */
						if (data == undefined) {
							/* eslint-enable eqeqeq */
							return data;
						}

						var fd = new FormData();
						fd.append('file', file);

						return fd;
					}
				}).then(
					function (res) {
						deferred.resolve(res);
					},
					function (err) {
						if (err && err.data && err.data.error && err.data.error === 'Error: File too large') {
							NotificationService.addNotification({
								style: NotificationService.style.ERROR,
								title: 'default.error',
								body: 'file.tooLarge',
								icon: 'times'
							});
						}
						deferred.reject(err);
					}
				);

				return deferred.promise;
			},

			delete: function (file, options) {
				options = options || {};
				// Checkif file is used in esign
				if (file.entity !== 'Esign' && !options.noConfirm) {
					return Esign.find({ 'file.id': file.id, limit: 0 }).then(function (res) {
						if (res.metadata.total) {
							var confirmButtonText = $translate.instant('admin.modal.deleteEntity', {
								entity: $translate.instant('file.file').toLowerCase()
							});
							var alertConfirmOptions = {
								type: 'confirm',
								fullscreen: true,
								hideAbort: false,
								dialog: AlertConfirm,
								id: 'confirm-delete-fields',
								body: React.createElement(AlertBody, {
									numSelected: 1,
									entity: 'File'
								}),
								confirmButtonText: confirmButtonText,
								confirmClass: 'btn-red',
								confirmationMode: 'text',
								confirmText: AppService.getSelf().email,
								confirmFn: function () {
									return deleteFile(file, options);
								}
							};

							if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
								openModal('Alert', {
									...alertConfirmOptions,
									title: getConfirmationTitle('File', 1),
									onClose: (confirmed) => {
										if (confirmed) {
											alertConfirmOptions.confirmFn();
										}
									}
								});
							} else {
								$upModal.open('alert', alertConfirmOptions);
							}
						} else {
							return deleteFile(file, options);
						}
					});
				} else {
					return deleteFile(file, options);
				}
			},

			makePublic: function (fileId) {
				return Resource.makePublic({ id: fileId }, {}).$promise;
			},
			attr: Attribute,
			keys: Keys,
			entityTypes: entityTypes,
			requiredFields: AA.requiredFields,
			getDownloadUrl: function (id) {
				var customerId = AppService.getCustomerId();
				return URL + API + customerId + '/resources/download/internal/' + id;
			},
			getInlineUrl: function (id) {
				var customerId = AppService.getCustomerId();
				return URL + API + customerId + '/resources/download/internal/' + id + '?inline=true';
			},
			getInlineThumbnailUrl: function (id) {
				var customerId = AppService.getCustomerId();
				return URL + API + customerId + '/resources/download/internal/' + id + '?inline=true&thumbnail=true';
			},
			getPublicUrl: function (name) {
				var meta = AppService.getMetadata();
				const encodedName = encodeURIComponent(name);
				return PUBLICPATH + meta.publicUrlHash + '/images/' + encodedName;
			},
			download: function (id) {
				var url = Model.getDownloadUrl(id);

				window.location.href = url;
			},
			open: function (id) {
				var url = Model.getInlineUrl(id);
				window.open(url, '_blank');
			}
		};

		return Model;
	}
]);

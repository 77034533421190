import React, { Fragment, useEffect, useRef, useState } from 'react';
import './DocumentTemplatePreview.scss';
import { DragDropContext, Droppable, Draggable, DragUpdate, DragStart, DropResult } from 'react-beautiful-dnd';
import { parseVariable, getColWidthFromSize } from 'Services/MailTemplate';
import bemClass from '@upsales/components/Utils/bemClass';
import { Row, Column, Text, Icon, Button, ButtonGroup, Tooltip } from '@upsales/components';
import MailTemplatePreviewText from 'Components/MailTemplatePreview/MailTemplatePreviewText';
import MailTemplatePreviewImage from 'Components/MailTemplatePreview/MailTemplatePreviewImage';
import { useTranslation } from 'Components/Helpers/translate';
import MailTemplatePreviewAddButton from 'Components/MailTemplatePreview/MailTemplatePreviewAddButton';
import MailTemplatePreviewAddMenu from 'Components/MailTemplatePreview/MailTemplatePreviewAddMenu';
import MailTemplatePreviewHeader from 'Components/MailTemplatePreview/MailTemplatePreviewHeader';
import MailTemplatePreviewFooter from 'Components/MailTemplatePreview/MailTemplatePreviewFooter';
import InlineConfirm from '../Dialogs/InlineConfirm';
import MailTemplatePreviewPadding from 'Components/MailTemplatePreview/MailTemplatePreviewPadding';
import MailTemplatePreviewColumnTools from 'Components/MailTemplatePreview/MailTemplatePreviewColumnTools';
import DocumentTemplatePreviewProductTable from 'Components/DocumentTemplateEditor/DocumentTemplatePreviewProductTable';
import AccountProfile from 'App/resources/Model/AccountProfile';
import { ColumnType, Config, RowType } from 'Components/DocumentTemplateEditor/DocumentTemplateEditor';
import BemClass from '@upsales/components/Utils/bemClass';

const MAX_COLS = 6;

// Apply the same css to multiple selectors
const buildSelectors = function (base: string, selectors = ['']) {
	if (!Array.isArray(selectors)) {
		selectors = [selectors];
	}
	let css = '';
	const selector: Array<string> = [];
	selectors.forEach(function (sel) {
		selector.push(base + ' ' + sel);
	});
	css = selector.join(',');

	return css;
};

const getFontWeight = (bool: boolean) => {
	return 'font-weight: ' + (bool ? 'bold' : 'normal') + ';';
};

const getUnderline = (bool: boolean) => {
	return 'text-decoration: ' + (bool ? 'underline' : 'none') + ';';
};

const getItalic = (bool: boolean) => {
	return 'font-style: ' + (bool ? 'italic' : 'normal') + ';';
};

const styleBlock = (accountProfile: AccountProfile) => {
	// color variables
	const contentColor = accountProfile.colors.contentText;
	const contentIngressColor = accountProfile.colors.contentTextIngress;
	const mainColor = accountProfile.colors.mainText;
	const secondaryColor = accountProfile.colors.secondaryText;
	const linkColor = accountProfile.colors.linkText;
	const btnBgColor = accountProfile.colors.buttonBg;

	// font variables
	// Family
	const h2Family = accountProfile.typography.h2.fontFamily;
	const h3Family = accountProfile.typography.h3.fontFamily;
	const ingressFamily = accountProfile.typography.ingress.fontFamily;
	const pFamily = accountProfile.typography.p.fontFamily;
	const linkFamily = accountProfile.typography.link.fontFamily;
	// size
	const h2Size = accountProfile.typography.h2.size;
	const h3Size = accountProfile.typography.h3.size;
	const ingressSize = accountProfile.typography.ingress.size;
	const pSize = accountProfile.typography.p.size;
	// bold
	const h2Weight = getFontWeight(accountProfile.typography.h2.bold);
	const h3Weight = getFontWeight(accountProfile.typography.h3.bold);
	const ingressWeight = getFontWeight(accountProfile.typography.ingress.bold);
	const pWeight = getFontWeight(accountProfile.typography.p.bold);
	const linkWeight = getFontWeight(accountProfile.typography.link.bold);
	// // underline
	const h2Underline = getUnderline(accountProfile.typography.h2.underline);
	const h3Underline = getUnderline(accountProfile.typography.h3.underline);
	const ingressUnderline = getUnderline(accountProfile.typography.ingress.underline);
	const pUnderline = getUnderline(accountProfile.typography.p.underline);
	const linkUnderline = getUnderline(accountProfile.typography.link.underline);
	// // italic
	const h2Italic = getItalic(accountProfile.typography.h2.italic);
	const h3Italic = getItalic(accountProfile.typography.h3.italic);
	const ingressItalic = getItalic(accountProfile.typography.ingress.italic);
	const pItalic = getItalic(accountProfile.typography.p.italic);
	const linkItalic = getItalic(accountProfile.typography.link.italic);

	const btnBorderRadius = accountProfile.btnBorderRadius;

	return (
		<style>
			{`
			${buildSelectors('.DocumentTemplatePreview', ['p', 'p > *:not(a)', 'ul li', 'ol li'])} {
				color: ${contentColor};
				font-size: ${pSize}px;
				${pWeight};
				${pUnderline}
				${pItalic}
				white-space: initial;
				font-family: ${pFamily};
			}

			${buildSelectors('.DocumentTemplatePreview', [
				'.DocumentTemplatePreviewProductTable__table-row *',
				'.DocumentTemplatePreviewProductTable__table-summary *'
			])} {
				font-family: ${pFamily};
			}
			
			${buildSelectors('DocumentTemplatePreview a:not(.MailTemplatePreviewFooter a):not(.MailTemplatePreviewHeader a)')} {
				color: ${linkColor};										
				${linkWeight}								
				${linkUnderline}										
				${linkItalic}										
				font-family: ${linkFamily};										
			}

			${buildSelectors('.DocumentTemplatePreview', ['p strong', 'p strong *', 'p.ingress strong', 'p.ingress strong *'])} {
				font-weight: bold !important;
			}

			${buildSelectors('.DocumentTemplatePreview', ['p em', 'p em *', 'p.ingress em', 'p.ingress em *'])} {
				font-style: italic;
			}

			${buildSelectors('.DocumentTemplatePreview', ['p u', 'p u *', 'p.ingress u', 'p.ingress u *'])} {
				text-decoration: underline;
			}

			${buildSelectors('.DocumentTemplatePreview', ['.ingress'])} {
				color: ${contentIngressColor};
				font-size: ${ingressSize}px;
				${ingressWeight}
				${ingressUnderline}
				${ingressItalic}
				font-family: ${ingressFamily};
			}

			${buildSelectors('.DocumentTemplatePreview', ['h2'])} {
				color: ${mainColor};
				font-size: ${h2Size}px;
				${h2Weight}
				${h2Underline}
				${h2Italic}
				width: 100%;
				font-family: ${h2Family};
			}

			${buildSelectors('.DocumentTemplatePreview', ['h3'])} {
				color: ${secondaryColor};
				font-size: ${h3Size}px;
				${h3Weight}
				${h3Underline}
				${h3Italic}
				font-family: ${h3Family};
			}

			${buildSelectors('.MailTemplatePreviewButton', ['.MailTemplatePreviewButton__btn'])} {
				background-color: ${btnBgColor};
				border-radius: ${btnBorderRadius}px;
				font-family: ${linkFamily};
			}
		`}
		</style>
	);
};

const stringifyStyleContent = (style: { [key: string]: string }, accountProfile: AccountProfile) => {
	return Object.keys(style)
		.map(key => {
			return `${key}: ${parseVariable(style[key], accountProfile)};`;
		})
		.join('');
};

const getInlineStyleBlock = (
	prefix: string,
	id: number,
	style: { [key: string]: string },
	accountProfile: AccountProfile | null
) => {
	if (!style) {
		return null;
	}

	if (!accountProfile) {
		return null;
	}

	return (
		<style>
			{`
			${buildSelectors(`#${prefix}-${id}`)} {
				${stringifyStyleContent(style, accountProfile)}
			}
			`}
		</style>
	);
};

const handleClick = (e: MouseEvent) => {
	if (e.target && (e.target as HTMLElement).tagName.toLowerCase() === 'a') {
		e.preventDefault();
	}
};

const isDraggingColumn = (draggableId: string) => draggableId.startsWith('col-');

type Props = {
	config: Config;
	accountProfile: AccountProfile | null;
	onSelectColumn: (selectedColumn: ColumnType | undefined, selectedRow?: RowType) => void;
	onColumnChange: (column: ColumnType) => void;
	selectedColumn: ColumnType | undefined;
	selectedRow: RowType | undefined;
	hoveredColumn: ColumnType | undefined;
	hoveredRow: RowType | undefined;
	onAddRow: (size: string | number, atIndex: number) => void;
	onAddColumn: (rowIndex: number, atIndex: number) => void;
	onColMove: (
		rowDroppableSourceId: string,
		rowDroppableDestinationId: string,
		sourceIndex: number,
		destinationIndex: number
	) => void;
	onRowMove: (sourceIndex: number, destinationIndex: number) => void;
	onCopy: (rowInput: RowType, atIndex: number) => void;
	onDelete: (rowId: number | Object) => void;
	onRemoveCol: (rowIndex: number, atIndex: number) => void;
	readOnly?: boolean;
	sidebarVisible?: boolean;
	className?: string;
};

const DocumentTemplatePreview = ({
	config,
	accountProfile,
	onSelectColumn,
	onColumnChange,
	selectedColumn,
	selectedRow,
	hoveredColumn,
	hoveredRow,
	onAddRow,
	onAddColumn,
	onColMove,
	onRowMove,
	onCopy,
	onDelete,
	onRemoveCol,
	readOnly,
	sidebarVisible,
	className
}: Props) => {
	const [dragging, setDragging] = useState(false);
	const [dragType, setDragType] = useState('row');
	const [hoveredRowColSize, setHoveredRowColSize] = useState(12);
	const [draggableIdState, setDraggableIdState] = useState<string | null>(null);
	const [colSourceDroppableId, setColSourceDroppableId] = useState<string | null>(null);
	const wrapperRef = useRef(null);

	const classes = new bemClass('DocumentTemplatePreview', className);
	const rootStyle = {
		backgroundColor: config.bodyBg,
		paddingTop: config.hasPageMargin ? config.pageMargin : undefined,
		paddingBottom: config.hasPageMargin ? config.pageMargin : undefined
	};
	let headerMargin = config.headerMargin;
	if (!headerMargin && headerMargin !== 0) {
		headerMargin = 15;
	}
	const showAddFirstRow = !config.hasFooter && !config.hasHeader && !readOnly && !config.rows.length;
	const { t } = useTranslation();
	const lang = {
		empty: t('default.empty'),
		deleteRow: t('mailTemplate.deleteRow'),
		duplicateRow: t('mailTemplate.duplicateRow'),
		deleteConfirm: t('mail.deleteConfirm'),
		row: t('mail.deleteBtnRow'),
		addFirstCol: t('mailTemplate.addFirstCol'),
		// Product table
		product: t('default.product'),
		priceOneOff: t('admin.documentTemplateEditor.productTable.priceOneOff'),
		priceOneOffInfo: t('admin.documentTemplateEditor.productTable.priceOneOffInfo'),
		priceRecurring: t('admin.documentTemplateEditor.productTable.priceRecurring'),
		priceRecurringInfo: t('admin.documentTemplateEditor.productTable.priceRecurringInfo'),
		quantity: t('default.quantity'),
		summary: t('admin.documentTemplateEditor.productTable.summary')
	};

	const onBeforeCapture = ({ draggableId }: { draggableId: string }) => {
		const isColDrag = isDraggingColumn(draggableId);
		if (isColDrag) {
			const draggableElem = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement; // TODO: Remove as HTMLElement
			if (draggableElem) {
				draggableElem.style.maxHeight = '100px';
			}
		}
	};

	const onDragUpdate = (update: DragUpdate) => {
		if (update?.destination?.droppableId && dragType === 'column') {
			const rowId = update.destination.droppableId.replace('col-droppable-', '');
			const row = config.rows.find(row => row.id + '' === rowId);
			if (row) {
				if (update.destination.droppableId === update.source.droppableId) {
					setHoveredRowColSize(12 / row.columns.length);
				} else {
					setHoveredRowColSize(12 / (row.columns.length + 1));
				}
			}
		}
	};

	const onDragStart = ({ draggableId, source }: DragStart) => {
		const isColDrag = isDraggingColumn(draggableId);
		setDragging(true);
		setDragType(isColDrag ? 'column' : 'row');
		setDraggableIdState(draggableId);
		setColSourceDroppableId(isColDrag ? source.droppableId : null);
	};

	const onRowDragEnd = (result: DropResult) => {
		setDragging(false);
		setDraggableIdState(null);
		setColSourceDroppableId(null);

		const { destination, source, draggableId } = result;

		const isColDrag = isDraggingColumn(draggableId);
		if (isColDrag) {
			const draggableElem = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement; // TODO: Remove as HTMLElement
			if (draggableElem) {
				draggableElem.style.maxHeight = '';
			}
		}

		// dropped outside the list
		if (!destination) {
			return;
		}

		// Dropped in same location
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		if (isColDrag) {
			onColMove(source.droppableId, destination.droppableId, source.index, destination.index);
		} else {
			onRowMove(source.index, destination.index);
		}
	};

	const renderRows = (classes: BemClass) => {
		return config.rows.map((row, i) => {
			const rowId = `row-${row.id}`;
			const rowIsFull = !(row.columns.length < MAX_COLS);
			const droppableId = `col-droppable-${row.id}`;
			const textEditorHeight = '100%';
			return (
				<div key={row.id}>
					<div id={'row-' + i} data-testid={'row-' + i}>
						<Draggable draggableId={'row-' + row.id} index={i} isDragDisabled={readOnly}>
							{(rowDragProvided, rowDragSnapshot) => (
								<div ref={rowDragProvided.innerRef} {...rowDragProvided.draggableProps}>
									<Droppable
										droppableId={droppableId}
										direction="horizontal"
										ignoreContainerClipping
										isDropDisabled={
											dragType === 'row' || (rowIsFull && colSourceDroppableId !== droppableId)
										}
									>
										{(rowDropProvided, rowDropSnapshot) => (
											<Fragment>
												<style>
													{`
								#row-${row.id}:after {
									background-color: ${parseVariable(row.backgroundColor, accountProfile)};
								}
								`}
												</style>
												<div
													id={rowId}
													className={classes
														.elem('row')
														.mod({
															'full-width': row.fullWidth,
															selected: selectedRow ? selectedRow.id === row.id : false,
															hovered: hoveredRow ? hoveredRow.id === row.id : false,
															dragging: rowDragSnapshot.isDragging,
															'row-is-full':
																dragging &&
																dragType === 'column' &&
																colSourceDroppableId !== droppableId &&
																rowIsFull,
															'read-only': readOnly
														})
														.b()}
													style={dragging ? { pointerEvents: 'none' } : {}}
												>
													<div className={classes.elem('edit-options-row').b()}>
														<ButtonGroup>
															<Tooltip title={lang.duplicateRow}>
																<Button
																	data-testid="copy-row"
																	color="super-light-green"
																	className={classes
																		.elem('edit-options-row-btn')
																		.mod('first')
																		.b()}
																	onClick={() => Promise.resolve(onCopy(row, i + 1))}
																>
																	<Icon name="copy" />
																</Button>
															</Tooltip>
															<InlineConfirm
																show={true}
																tooltip={null}
																onConfirm={() => Promise.resolve(onDelete(row.id))}
																entity={'mail.row'}
																btnText={lang.deleteConfirm}
																showBody={false}
															>
																<Tooltip title={lang.deleteRow}>
																	<Button
																		data-testid="delete-row"
																		color="super-light-green"
																		className={classes
																			.elem('edit-options-row-btn')
																			.b()}
																	>
																		<Icon name="trash" />
																	</Button>
																</Tooltip>
															</InlineConfirm>
														</ButtonGroup>
														{!readOnly ? (
															<div
																className={classes.elem('row-drag-handle').b()}
																{...rowDragProvided.dragHandleProps}
															>
																<Icon name="arrows" />
															</div>
														) : null}
													</div>
													<div id={rowId + '-ck'} className="ck-inline-toolbar"></div>
													{i === 0 && !readOnly ? (
														<MailTemplatePreviewAddButton
															onSelect={(c: string | number) => onAddRow(c, 0)}
															first={!config.hasHeader}
														/>
													) : null}
													<Row
														className={classes.elem('row-inner').b()}
														ref={rowDropProvided.innerRef}
														{...rowDropProvided.droppableProps}
													>
														{row.columns.map((col, ci) => {
															let content: JSX.Element | null;
															const thisColIsDragged =
																draggableIdState === `col-${row.id}-${col.id}`;
															const selected = selectedColumn
																? selectedColumn.id === col.id
																: false;
															// set column size if this row is being dragged over and was not the source droppable
															const size =
																rowDropSnapshot.isDraggingOver &&
																!rowDropSnapshot.draggingFromThisWith
																	? hoveredRowColSize
																	: col.size;

															const colProps = {
																id: 'column-' + col.id,
																onClick: readOnly
																	? null
																	: () => onSelectColumn(col, row),
																style: {
																	backgroundColor:
																		(col.enableCellBackground &&
																			col.backgroundColor) ||
																		(thisColIsDragged ? 'white' : 'transparent')
																}
															};
															switch (col.type) {
																case 'TEXT':
																	content = (
																		<MailTemplatePreviewText
																			column={col}
																			readOnly={readOnly}
																			onChange={onColumnChange}
																			selected={selected}
																			accountProfile={accountProfile}
																			rowId={rowId}
																			isFirstRow={!config.hasHeader && i === 0}
																			isDoc={true}
																			textEditorHeight={textEditorHeight}
																		/>
																	);
																	break;
																case 'IMAGE':
																	content = (
																		<MailTemplatePreviewImage
																			column={col}
																			readOnly={readOnly}
																			onChange={onColumnChange}
																			selected={selected}
																			accountProfile={accountProfile}
																			maxWidth={
																				rowDropSnapshot.isDraggingOver ||
																				thisColIsDragged
																					? getColWidthFromSize(
																							hoveredRowColSize
																					  )
																					: undefined
																			}
																			isDoc={true}
																		/>
																	);
																	break;
																case 'EMPTY':
																	content = readOnly ? null : (
																		<div
																			className={classes
																				.elem('empty-content')
																				.b()}
																		>
																			<Text center={true} color="green">
																				{lang.empty}
																			</Text>
																		</div>
																	);
																	break;
																case 'PRODUCTTABLE':
																	content = (
																		<DocumentTemplatePreviewProductTable
																			productTableConfig={col}
																		/>
																	);
																	break;
																default:
																	content = col.type;
															}

															const canAddCols = !readOnly && !rowIsFull;
															return (
																<Draggable
																	key={'col-' + col.id}
																	draggableId={`col-${row.id}-${col.id}`}
																	index={ci}
																	isDragDisabled={readOnly}
																>
																	{(colDrag, colDragSnapshot) => (
																		<Fragment>
																			{getInlineStyleBlock(
																				'column',
																				col.id,
																				col.style.self,
																				accountProfile
																			)}
																			<Column
																				{...colProps}
																				size={
																					colDragSnapshot.isDragging
																						? hoveredRowColSize
																						: size
																				}
																				className={classes
																					.elem('column')
																					.mod({
																						selected,
																						hovered: hoveredColumn
																							? hoveredColumn.id ===
																							  col.id
																							: false,
																						empty: col.type === 'EMPTY',
																						[col.type?.toLowerCase()]:
																							!!col.type,
																						dragging:
																							colDragSnapshot.isDragging,
																						'read-only': readOnly
																					})
																					.b()}
																				ref={colDrag.innerRef}
																				{...colDrag.draggableProps}
																				style={{
																					...colProps.style,
																					...colDrag.draggableProps.style,
																					width: colDragSnapshot.isDragging
																						? getColWidthFromSize(
																								hoveredRowColSize
																						  )
																						: undefined,
																					overflow:
																						rowDropSnapshot.isDraggingOver ||
																						colDragSnapshot.isDragging
																							? 'hidden'
																							: undefined,
																					flex: colDragSnapshot.isDragging
																						? `0 0 ${getColWidthFromSize(
																								hoveredRowColSize
																						  )}px`
																						: undefined,
																					transform:
																						!colDragSnapshot.isDragging &&
																						colDrag.draggableProps.style &&
																						colDrag.draggableProps.style
																							.transform
																							? `translate(${getColWidthFromSize(
																									hoveredRowColSize
																							  )}px, 0px)`
																							: colDrag.draggableProps
																									.style
																							? colDrag.draggableProps
																									.style.transform
																							: undefined,
																					minHeight:
																						dragging &&
																						dragType === 'column'
																							? '50px'
																							: undefined
																				}}
																			>
																				<MailTemplatePreviewColumnTools
																					onRemove={() => {
																						onRemoveCol(i, ci);
																					}}
																					dragHandleProps={
																						colDrag.dragHandleProps
																					}
																				/>

																				{canAddCols ? (
																					<MailTemplatePreviewAddButton
																						vertical
																						onSelect={() =>
																							onAddColumn(i, ci)
																						}
																					/>
																				) : null}
																				<MailTemplatePreviewPadding
																					column={col}
																				/>
																				{content}
																				{canAddCols &&
																				ci === row.columns.length - 1 ? (
																					<MailTemplatePreviewAddButton
																						vertical
																						last
																						onSelect={() =>
																							onAddColumn(i, ci + 1)
																						}
																					/>
																				) : null}
																			</Column>
																		</Fragment>
																	)}
																</Draggable>
															);
														})}
														{rowDropProvided.placeholder}
													</Row>
													{!readOnly ? (
														<MailTemplatePreviewAddButton
															onSelect={(c: string | number) => onAddRow(c, i + 1)}
														/>
													) : null}
												</div>
											</Fragment>
										)}
									</Droppable>
								</div>
							)}
						</Draggable>
					</div>
				</div>
			);
		});
	};

	useEffect(() => {
		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, []);

	return (
		<div
			className={classes.b()}
			style={rootStyle}
			ref={wrapperRef}
			id="TemplatePreview"
			data-testid="TemplatePreview"
		>
			{accountProfile ? styleBlock(accountProfile) : null}

			{config.hasHeader ? (
				<div id="TemplatePreviewHeader">
					<MailTemplatePreviewHeader
						selected={selectedColumn === undefined && sidebarVisible === true}
						readOnly={readOnly}
						config={config}
						accountProfile={accountProfile}
						onClick={readOnly ? null : () => onSelectColumn(undefined)}
					/>
				</div>
			) : null}

			{(config.hasHeader || config.hasFooter) && config.rows.length === 0 ? (
				<MailTemplatePreviewAddButton
					onSelect={(c: string | number) => onAddRow(c, 0)}
					first={config.hasFooter && !config.hasHeader}
				/>
			) : null}

			{(config.hasHeader || config.hasFooter) && config.rows.length === 0 ? (
				<MailTemplatePreviewAddButton
					onSelect={(c: string | number) => onAddRow(c, 0)}
					first={config.hasFooter && !config.hasHeader}
				/>
			) : null}

			{showAddFirstRow ? (
				<MailTemplatePreviewAddMenu
					className={classes.elem('add-first-row').b()}
					header={lang.addFirstCol}
					onSelect={(c: string | number) => onAddRow(c, 0)}
				/>
			) : null}

			<DragDropContext
				onBeforeCapture={onBeforeCapture}
				onDragStart={onDragStart}
				onDragEnd={onRowDragEnd}
				onDragUpdate={onDragUpdate}
			>
				<Droppable
					droppableId="row-droppable"
					direction="vertical"
					isDropDisabled={!dragging || dragType !== 'row'}
				>
					{provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{renderRows(classes)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{config.hasFooter ? (
				<div id="TemplatePreviewFooter">
					<MailTemplatePreviewFooter
						selected={selectedColumn === undefined && sidebarVisible === true}
						readOnly={readOnly}
						accountProfile={accountProfile}
						config={config}
						onClick={readOnly ? null : () => onSelectColumn(undefined)}
					/>
				</div>
			) : null}
		</div>
	);
};

export default DocumentTemplatePreview;

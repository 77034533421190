'use strict';

angular.module('domain.contact').factory('EditEsignMeta', [
	'$q',
	'NotificationService',
	'AppService',
	'Esign',
	'Account',
	'utils',
	'User',
	'Order',
	'RequestBuilder',
	'$upModal',
	'File',
	function ($q, NotificationService, AppService, Esign, Account, utils, User, Order, RequestBuilder, $upModal, File) {
		// Requires account to open
		return function ($modalParams, $preCompileElement) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var account = $q.defer();
				var accountFiles = $q.defer();
				var contacts = $q.defer();
				var opportunities = $q.defer();
				var settingsPromise = $q.defer();

				var promises = {
					account: account.promise,
					accountFiles: accountFiles.promise,
					contacts: contacts.promise,
					opportunities: opportunities.promise,
					users: $q.when({ data: AppService.getActiveUsers() }),
					settings: settingsPromise.promise
				};

				var getContacts = function (accountId) {
					var fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title'];
					utils.req
						.clientContactsAndRelations(accountId, fields)
						.then(contacts.resolve)
						.catch(contacts.reject);
				};

				var getOpportunities = function (accountId) {
					var orderFilter = new RequestBuilder();
					orderFilter.addFilter(Order.attr.account, orderFilter.comparisonTypes.Equals, accountId);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 100);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 0);
					Order.customer(customerId)
						.find(orderFilter.build())
						.then(opportunities.resolve)
						.catch(opportunities.reject);
				};

				var getFiles = function (accountId) {
					// Get number of files(pdf) on this account
					var fileFilter = new RequestBuilder();
					fileFilter.addFilter(File.attr.account.attr.id, fileFilter.comparisonTypes.Equals, accountId);
					fileFilter.addFilter(File.attr.mimetype, fileFilter.comparisonTypes.WildcardEnd, ['pdf']);
					fileFilter.limit = 0;
					File.find(fileFilter.build()).then(accountFiles.resolve).catch(accountFiles.reject);
				};

				var getAccount = function (accountId) {
					Account.customer(customerId).get(accountId).then(account.resolve).catch(account.reject);
				};

				var getSettings = function (id) {
					Esign.runAction({
						type: 'settings',
						integrationId: id
					})
						.then(function (res) {
							if (res.data.documentType === 'html') {
								$preCompileElement.addClass('esign-narrow');
							}
							$preCompileElement.show();
							settingsPromise.resolve(res.data);
						})
						.catch(function (err) {
							return settingsPromise.reject(err);
						});
				};

				// if this is an edit
				if ($modalParams.id) {
					// Get esign
					promises.esign = Esign.get($modalParams.id);
				} else if ($modalParams.esign) {
					// if we have some preset data
					var esign = Esign.new();
					esign = _.merge(esign, $modalParams.esign);
					promises.esign = $q.when({ data: esign });
				} else {
					// This will not work!! we need account to open
					promises.esign = $q.reject('Missing account');
				}

				// eslint-disable-next-line promise/catch-or-return
				promises.esign.then(function (res) {
					getContacts(res.data.client.id);
					getOpportunities(res.data.client.id);
					getFiles(res.data.client.id);
					getAccount(res.data.client.id);

					if (res.data.id && res.data.integrationId) {
						getSettings(res.data.integrationId);
					} else if ($modalParams.id) {
						$preCompileElement.show();
						settingsPromise.resolve(null);
					}
				});

				// get the esign account if we got some presets
				if (!$modalParams.id) {
					// If we have one and only one esign-app we can prefetch the settings
					var esignIntegrations = AppService.getEsignIntegrations();
					if (esignIntegrations.length === 1) {
						getSettings($modalParams.integrationId || esignIntegrations[0].id);
					} else {
						$preCompileElement.show();
						settingsPromise.resolve(null);
					}
				}

				return $q
					.all(promises)
					.then(function (response) {
						if (response.account && response.account.data) {
							response.esign.data.client = response.account.data;
						}

						if (response.settings && !response.esign.data.id) {
							response.esign.data.integrationId = $modalParams.integrationId || esignIntegrations[0].id;
						}

						// If this e-sign is sent we open the status modal
						if (response.esign.data.state !== Esign.stateEnum.DRAFT) {
							$upModal.open('confirmEsign', {
								esign: response.esign.data
							});
							return $q.reject('sent');
						}

						return response;
					})
					.catch(function (err) {
						if (err === 'sent') {
							return $q.reject();
						} else {
							let title = 'default.error';
							let body = 'openError.esign';

							if (err.data.error?.standardIntegrationError) {
								title = 'openError.esign';
								body = err.data.error.standardIntegrationError;
							}
							NotificationService.addNotification({
								title: title,
								body: body,
								style: 'error',
								icon: 'times'
							});
							return $q.reject(err);
						}
					});
			});
		};
	}
]);

import React, { Fragment, useEffect } from 'react';
import AppLoader from './components/AppLoader';
import { useAppDispatch, useSelector } from './components/hooks';
import { initSession } from 'Store/actions/AppActions';
import history from './pages/routes/history';

import { waitForAngular } from './AngularApp';

type Props = {
	children: React.ReactNode;
	removeAngular: boolean;
};

// Do not redirect to login if currently on any of these routes
const noRedirectRoutes = ['/login', '/activate-app'];

export const onlyRenderWhenAuthenticated = (Component: React.ComponentType) => () => {
	const { authenticated } = useSelector(({ App }) => ({
		authenticated: App.authenticated
	}));

	return authenticated ? <Component /> : null;
};

const Session = ({ children, removeAngular }: Props) => {
	const dispatch = useAppDispatch();
	const { loading } = useSelector(({ App }) => ({
		loading: App.loading
	}));

	const runInitSession = async () => {
		if (process.env.NODE_ENV === 'test' && waitForAngular) {
			// Need to wait for angular to be loaded before we can init the session
			await waitForAngular();
		}

		dispatch(initSession()).catch(() => {
			// If no session, redirect to login (if not already there)
			if (!noRedirectRoutes.some(route => history.location.pathname.startsWith(route))) {
				history.replace('/login');
			}
		});
	};

	useEffect(() => {
		// This is where the app initialization will happen, for now we let the react app handle it in dev environments
		if (removeAngular) {
			runInitSession();
		}
	}, []);

	return (
		<Fragment>
			<AppLoader visible={loading} />
			{children}
		</Fragment>
	);
};

export default Session;

'use strict';
import ActivityOutcomes from 'Components/Activity/ActivityOutcomes';
import setupComponent from 'App/babel/SetupRootComponent';

angular.module('upDirectives').directive('upActivityOutcomeRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			const firstElement = $element[0];

			if (firstElement) {
				setupComponent(
					$scope,
					ActivityOutcomes,
					firstElement,
					{
						activity: $scope.$eval($attr.upActivityOutcomeRoot),
						contact: $scope.$eval($attr.contact),
						company: $scope.$eval($attr.company),
						onChange: $scope.$eval($attr.onChange),
						disabledTooltip: $scope.$eval($attr.disabledTooltip),
						onPresetDateChange: $scope.$eval($attr.onPresetDateChange),
						onDateChange: $scope.$eval($attr.onDateChange),
						onTimeChange: $scope.$eval($attr.onTimeChange),
						confirm: $scope.$eval($attr.save),
						disabled: $scope.$eval($attr.disabled),
						bookAppointment: $scope.$eval($attr.bookAppointment),
						createActivityFollowUp: $scope.$eval($attr.createFollowup),
						setAppointment: $scope.$eval($attr.setAppointment),
						createOpportunity: $scope.$eval($attr.createOpportunity),
						createOrder: $scope.$eval($attr.createOrder),
						changeContact: $scope.$eval($attr.selectContact),
						setContact: $scope.$eval($attr.setContact),
						dateAnchor: $scope.$eval($attr.dateAnchor),
						dateScrollContainer: $scope.$eval($attr.dateScrollContainer)
					},
					{
						redux: true,
						watch: [
							{
								attr: $attr.upActivityOutcomeRoot,
								prop: 'activity'
							},
							{
								attr: $attr.disabledTooltip,
								prop: 'disabledTooltip'
							},
							{
								attr: $attr.contact,
								prop: 'contact'
							},
							{
								attr: $attr.company,
								prop: 'company'
							},
							{
								attr: $attr.disabled,
								prop: 'disabled'
							}
						]
					}
				);
			}
		}
	};
});

import React, { useEffect, useRef } from 'react';
import { ProductTableColumn } from './DocumentTemplateEditor';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const ItemTypes = {
	COLUMNSETTINGS: 'columnsettings'
};

type Props = {
	column: ProductTableColumn;
	index: number;
	moveColumn: (dragIndex: number, hoverIndex: number) => void;
	setDragId: (dragId: string | null) => void;
	setDraggedItemWidth: (width: number) => void;
	renderColumnSettings: (
		column: ProductTableColumn,
		index: number,
		drag?: React.LegacyRef<HTMLDivElement> | undefined
	) => React.ReactNode;
};

export const DraggableColumnSettings = ({
	column,
	index,
	moveColumn,
	setDragId,
	setDraggedItemWidth,
	renderColumnSettings
}: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.COLUMNSETTINGS,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveColumn(dragIndex, hoverIndex);
			item.index = hoverIndex;
		}
	});

	const [{ isDragging }, drag, preview] = useDrag({
		type: ItemTypes.COLUMNSETTINGS,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		}),
		item: () => {
			setDragId(column.id);

			if (ref.current) {
				setDraggedItemWidth(ref.current.offsetWidth);
			}
			return { id: column.id, index };
		},
		end: () => {
			setDragId(null);
		}
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, [preview]);

	const opacity = isDragging ? 0 : 1;
	drop(ref);

	return (
		<div key={column.id} ref={ref} data-handler-id={handlerId} style={{ opacity }}>
			{renderColumnSettings(column, index, drag)}
		</div>
	);
};

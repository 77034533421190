// This adds ability to add “Logged in user” when you apply a user filter in the lists.
// This makes it possible to for example share a view that has a user filter.

import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import AccessType from 'App/enum/AccessType';
import {
	FilterPreset,
	generateFilter,
	getLoggedInUserFilter as getLogged,
} from 'App/babel/filterConfigs/filterGenerators';

/** @deprecated: Use generateFilter format from filterGenerators.ts instead **/
// This is added to all user-type filters, and it's on top of all the users in the filter.
export const getLoggedInUserFilter = getLogged;
/** @deprecated: Use generateFilter format from filterGenerators.ts instead **/
export const generateUserFilter = function (accessType: AccessType | null, options = {}): FilterConfig {
	return generateFilter(FilterPreset.User, options, accessType);
};

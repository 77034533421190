import React from 'react';
import { Button, Checkbox, Icon, IconName, Link, TableColumn, Text, Tooltip } from '@upsales/components';
import Todo from 'App/resources/Model/Todo';
import BemClass from '@upsales/components/Utils/bemClass';
import { ActivityClient, ActivityContact } from 'App/resources/Model/Activity';
import T from 'Components/Helpers/translate';
import { useAppDispatch } from 'App/components/hooks';
import { toggleTodoDone, toggleTodoPriority } from 'Store/actions/TodoActions';
import TodoListTableRowDescriptionCol from './TodoListTableRowDescriptionCol';
import { DangerButton, DefaultButton } from '@upsales/components/Buttons';
import logError from 'Helpers/logError';
import { appointmentOutcomeTracker } from 'Helpers/Tracker';
import { openDrawer } from 'Services/Drawer';
import TodoListTableRowQuickActions from './TodoListTableRowQuickActions';
import { RootState } from 'Store/index';
import { useSelector } from 'react-redux';
import TodoListTableRowActionButton from './TodoListTableRowActionButton';
import TodoListSmallActionButton from './TodoListSmallActionButton';

export enum TodoListDisplayType {
	/** @deprecated */
	ActionButton = 'actionButton',
	ActionButtonSmall = 'actionButtonSmall',
	Description = 'description',
	Icon = 'icon',
	OutcomeButtons = 'outcomeButtons',
	Tools = 'tools',
	WhatToDo = 'whatTodo',
	WithWhom = 'withWhom'
}

export type TodoTypeProps = {
	isTodoNoRelation: boolean;
	descriptionTitle: string;
	timeSubTitle: string;
	icon: IconName | null;
	isTodo: boolean;
};

const getTooltipNotes = (notes: string) => {
	return (notes || '').substr(0, 400) + (notes.length > 400 ? '...' : '');
};

const setOutcomeAndOpenAppointment = async (todo: Todo, outcome: string) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS')) {
		const app = await Tools.Appointment.customer(Tools.AppService.getCustomerId()).get(todo.id);
		Tools.$upModal.open('editAppointment', { appointment: app.data, preSelectedOutcome: outcome });
	} else {
		const customerId = Tools.AppService.getCustomerId();
		Tools.Appointment.customer(customerId)
			.save({ id: todo.id, outcome: outcome })
			.then(() => {
				appointmentOutcomeTracker.track(appointmentOutcomeTracker.events.SET_OUTCOME, {
					location: 'todolist',
					outcome
				});
				Tools.$upModal.open('editAppointment', { id: todo.id });
			})
			.catch(err => {
				logError(err, 'Error saving appointment outcome');
			});
	}
};

const getClientLink = (client: ActivityClient, small?: boolean) =>
	client ? (
		<Text size={small ? 'sm' : 'md'}>
			<Link
				onClick={e => e?.stopPropagation()}
				href={Tools.$state.href('account.dashboard', {
					id: client.id,
					customerId: Tools.AppService.getCustomerId()
				})}
				color={small ? 'grey-10' : undefined}
			>
				{client.name}
			</Link>
		</Text>
	) : null;

export const getContactLink = (contacts: ActivityContact[]) =>
	contacts?.length ? (
		<Text size="md">
			<Link
				onClick={e => e?.stopPropagation()}
				href={Tools.$state.href('contact.dashboard', {
					id: contacts[0].id,
					customerId: Tools.AppService.getCustomerId()
				})}
			>
				{contacts[0].name}
			</Link>
			<Tooltip
				position="top"
				title={
					contacts
						.slice(1)
						.map(c => c.name)
						.join(', ') ?? ''
				}
				disabled={contacts.length < 2}
			>
				{contacts.length > 1 ? `\xa0\xa0+${contacts.length - 1}` : null}
			</Tooltip>
		</Text>
	) : null;

const getTitles = function (todo: Todo) {
	let title;
	let subtitle;

	if (todo.contacts?.length) {
		title = getContactLink(todo.contacts);
		subtitle = todo.client ? getClientLink(todo.client, true) : '';
	} else if (todo.client) {
		title = getClientLink(todo.client);
	}

	return {
		title,
		subtitle
	};
};

type TodoListColumnProps = {
	classes: BemClass;
	todo: Todo;
	typeProps: TodoTypeProps;
	hideFocusMode?: boolean;
	hidePriority?: boolean;
};

const configs: Record<TodoListDisplayType, React.FC<TodoListColumnProps>> = {
	[TodoListDisplayType.ActionButton]: TodoListTableRowActionButton,
	[TodoListDisplayType.ActionButtonSmall]: TodoListSmallActionButton,
	[TodoListDisplayType.Description]: TodoListTableRowDescriptionCol,
	[TodoListDisplayType.Icon]: ({ classes, todo, typeProps }) => {
		const { multiselect } = useSelector((state: RootState) => state.Todo);
		const dispatch = useAppDispatch();
		return (
			<TableColumn className={classes.elem('iconCol').b()} color="grey-7" align="right">
				{todo.hot ? (
					<Tooltip title={T('todo.hotTooltip')} className={classes.elem('hotIcon').b()}>
						<Icon name="fire" />
					</Tooltip>
				) : null}
				{typeProps.isTodo ? (
					<Checkbox
						size="xs"
						checked={!!todo.closeDate}
						disabled={multiselect.enabled}
						onClick={
							multiselect.enabled
								? undefined
								: e => {
										e.stopPropagation();
										dispatch(toggleTodoDone(todo));
								  }
						}
					/>
				) : (
					<Icon
						color={multiselect.enabled ? 'grey-4' : 'grey-10'}
						name={typeProps.icon || ('' as IconName)}
					/>
				)}
			</TableColumn>
		);
	},
	[TodoListDisplayType.OutcomeButtons]: ({ todo, classes }) => (
		<TableColumn className={classes.elem('outcomeActions').b()}>
			<Tooltip title={T('todo.markAsNotCompleted')} className="outcomeButtonNo">
				<DangerButton
					size="xs"
					onClick={e => {
						e?.stopPropagation();
						setOutcomeAndOpenAppointment(todo, 'notCompleted');
					}}
				>
					<Icon name="calendar-times-o" />
				</DangerButton>
			</Tooltip>
			<Tooltip title={T('todo.markAsCompleted')}>
				<DefaultButton
					size="xs"
					onClick={e => {
						e?.stopPropagation();
						setOutcomeAndOpenAppointment(todo, 'completed');
					}}
				>
					<Icon name="calendar-check-o" />
				</DefaultButton>
			</Tooltip>
		</TableColumn>
	),
	[TodoListDisplayType.Tools]: ({ classes, todo, typeProps, hideFocusMode = false, hidePriority = false }) => {
		const dispatch = useAppDispatch();
		return (
			<TableColumn className={classes.elem('flagCol').b()} align="right">
				<div className={classes.elem('quickactions').b()}>
					{todo.type === 'phonecall' && !hideFocusMode ? (
						<Tooltip title={todo.closeDate ? T('todo.focusModeDisabled') : T('todo.focusModeFromHere')}>
							<Button
								type="link"
								onClick={e => {
									e?.stopPropagation();
									openDrawer('EditPhonecall', {
										activity: todo,
										focus: true,
										shouldIterateList: true
									});
								}}
								disabled={!!todo.closeDate}
								color="grey"
							>
								<Icon name="flash" />
							</Button>
						</Tooltip>
					) : null}
					{todo.type !== 'appointment' && !hidePriority ? (
						<Tooltip
							title={T(
								todo.priority === 3
									? typeProps.isTodo
										? 'todo.prioTodo'
										: 'todo.prioCall'
									: 'todo.clickToPrioCall'
							)}
						>
							<Button
								type="link"
								color={todo.priority === 3 ? 'red' : 'grey'}
								onClick={e => {
									e?.stopPropagation();
									dispatch(toggleTodoPriority(todo));
								}}
							>
								<Icon name="flag" />
							</Button>
						</Tooltip>
					) : null}
					<TodoListTableRowQuickActions todo={todo} />
				</div>
			</TableColumn>
		);
	},
	[TodoListDisplayType.WithWhom]: ({ classes, todo, typeProps }) =>
		!typeProps.isTodoNoRelation ? (
			<TableColumn className={classes.elem('withWhomCol').b()} {...getTitles(todo)} />
		) : null,
	[TodoListDisplayType.WhatToDo]: ({ classes, todo, typeProps }) => (
		<TableColumn colSpan={typeProps.isTodoNoRelation ? 4 : undefined} className={classes.elem('titleCol').b()}>
			<div className={classes.elem('titleCol-wrap').b()}>
				<Text
					size="md"
					className={classes
						.elem('titleCol-title')
						.mod({ strike: typeProps.isTodo && !!todo.closeDate })
						.b()}
				>
					<Tooltip
						position="top"
						disabled={!todo.notes}
						title={todo.notes ? getTooltipNotes(todo.notes) : ''}
					>
						{typeProps.descriptionTitle}
					</Tooltip>
				</Text>
				<Text size="sm" color="grey-11" className={classes.elem('titleCol-subtitle').b()}>
					{typeProps.timeSubTitle}{' '}
					{todo.type === 'appointment' && todo.location ? (
						<Tooltip title={todo.location} distance={18}>
							<Text color={'grey-10'} size="sm">
								<Icon name="map-marker" />
								{todo.location.split(',')[0] ?? ''}
							</Text>
						</Tooltip>
					) : null}
				</Text>
			</div>
		</TableColumn>
	)
};

const TodoListTableColumn = ({ type, ...props }: { type: TodoListDisplayType } & TodoListColumnProps) => {
	const Component = configs[type];
	return <Component {...props} />;
};

export default TodoListTableColumn;

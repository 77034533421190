import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { OutsideClick } from '@upsales/components';

import Overlay from './Overlay';
import usePortal from './usePortal';

export default function Portal({ children, hasBackdrop = true, isOpen, onClose }) {
	const target = usePortal('portal-container');

	let component = (
		<OutsideClick targetId="portal-container" outsideClick={onClose}>
			{children || <Fragment />}
		</OutsideClick>
	);

	if (hasBackdrop) {
		component = <Overlay>{component}</Overlay>;
	}

	if (isOpen) {
		return createPortal(component, target);
	}

	return null;
}

window.Portal = Portal;

'use strict';

import { Icon, Text, Tooltip, Button } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';

ReactTemplates.ROWSTATICS.orderRow = function (tools) {
	var statics = {};
	statics.deleteTool = tools.$translate('default.delete');
	statics.noCampaign = tools.$translate('default.noCampaign');
	return statics;
};

ReactTemplates.ROW.orderRow = window.ReactCreateClass({
	displayName: 'orderRow',
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var t = tools.$translate;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var activeApps = tools.AppService.getMetadata().integrations.active;

		var getColumn = function (column, item) {
			var key = 'column-' + column;
			if (column.indexOf('AppSyncStatus_') === 0) {
				const appId = parseInt(column.split('_')[1]);
				const app = activeApps.find(({ id }) => id === appId)?.name || '';
				const lastSyncStatus =
					(item.lastIntegrationStatus ?? []).find(status => status.integrationId === appId) ?? {};
				const entity = Tools.$location.$$path.includes('orders')
					? t('default.order')
					: t('default.opportunity');

				statics.syncStatusPending = t('integration.tooltip.inProgress', { entity, app });
				statics.syncStatusSuccess = t('integration.tooltip.success', { entity, app });
				statics.syncStatusFailed = t('integration.tooltip.failed', { entity, app });

				let content;
				if (lastSyncStatus.callbackStatus === 0) {
					content = (
						<Text color="yellow">
							<Tooltip position="left" title={statics.syncStatusPending} distance={20}>
								<Icon name="circle" />
							</Tooltip>
						</Text>
					);
				} else if (lastSyncStatus.callbackStatus === 1) {
					content = (
						<Text color="green">
							<Tooltip position="left" title={statics.syncStatusSuccess} distance={20}>
								<Icon name="circle" />
							</Tooltip>
						</Text>
					);
				} else if (lastSyncStatus.callbackStatus == null || lastSyncStatus.callbackStatus === -1) {
					content = null;
				} else {
					content = (
						<Text color="red">
							<Tooltip position="left" title={statics.syncStatusFailed} distance={20}>
								<Icon name="circle" />
							</Tooltip>
						</Text>
					);
				}
				return <td>{content}</td>;
			}
			switch (column) {
				case 'date':
				case 'closeDate':
					return (
						<td key={key} className="column-date">
							{ReactTemplates.TOOLS.dateCalendar(item[column], 'L')}
						</td>
					);

				case 'client':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						</td>
					);

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText([item.user], tools)}</td>;
					}
				case 'project':
				case 'campaign':
					if (!item.project) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaign}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText([item.project], tools)}</td>;
					}

				case 'contact':
					if (!item.contact) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noContact')}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>{ReactTemplates.TOOLS.contactsText(customerId, [item.contact], tools)}</td>
						);
					}

				case 'description':
					return <td key={key}>{item.description ? item.description : ''}</td>;

				case 'id':
					return <td key={key}>{item.id ? item.id : ''}</td>;

				case 'contributionMargin': {
					const key =
						options.isweightedvalue === 'on'
							? 'weightedContributionMarginLocalCurrency'
							: 'contributionMarginLocalCurrency';
					let value = '';
					if (item[key] !== null && item[key] !== undefined) {
						value =
							options.isweightedvalue === 'on'
								? Math.round(item[key])
								: Math.round(item[key] * 100) / 100;
					}

					const currencyFormat = tools.$filter('currencyFormat');
					const currency = item.currency
						? item.currency
						: _.find(tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true }).iso;
					const displayString = currencyFormat(value, currency);

					return <td key={key}>{displayString}</td>;
				}
				case 'value': {
					const key = options.isweightedvalue === 'on' ? 'weightedValue' : 'value';
					let value = '';
					if (item[key] !== null && item[key] !== undefined) {
						value =
							options.isweightedvalue === 'on'
								? Math.round(item[key])
								: Math.round(item[key] * 100) / 100;
					}

					const currencyFormat = tools.$filter('currencyFormat');
					const currency = item.currency
						? item.currency
						: _.find(tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true }).iso;
					const displayString = currencyFormat(value, currency);

					return <td key={key}>{displayString}</td>;
				}
				case 'stage':
					return <td key={key}>{item.stage && item.stage.name ? item.stage.name : ''}</td>;

				case 'product':
					if (!item.orderRow || !item.orderRow.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noProducts}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.productsText(item.orderRow, tools)}</td>;
					}

				case 'closedCol':
					return (
						<td key={key}>
							{ReactTemplates.TOOLS.dateCalendar(item.date, 'L')}
							<span className="column-subtitle">
								{item.user ? (
									ReactTemplates.TOOLS.usersText([item.user], tools)
								) : (
									<i className="grey">{tools.$translate('default.noUser')}</i>
								)}
							</span>
						</td>
					);

				case 'descStageCol':
					return (
						<td key={key}>
							{item.description ? item.description : ''}
							<span className="column-subtitle">
								{item.stage && item.stage.name ? item.stage.name : ''}
							</span>
						</td>
					);

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep = item.contact ? item.contact.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);
				case 'users':
					if (!item.client.users || !item.client.users.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noAccountManager}</i>
							</td>
						);
					} else {
						if (item.client.users.length === 1) {
							return (
								<td key={key}>
									<ReactTemplates.TOOLS.avatar
										user={item.client.users[0]}
										size={15}
										avatarService={tools.avatarService}
										style={{ marginTop: '0px' }}
									/>{' '}
									{item.client.users[0].name}
								</td>
							);
						} else {
							return (
								<td key={key}>
									{_.map(item.client.users, function (user, i) {
										return (
											<ReactTemplates.TOOLS.avatar
												key={'u-' + i}
												user={user}
												size={15}
												avatarService={tools.avatarService}
												style={{ marginTop: '0px', marginRight: '3px' }}
											/>
										);
									})}
								</td>
							);
						}
					}

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});
		return (
			<tr key={'item-' + item.id} onClick={options.clickedEntry.bind(this, item)} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className="column-tools">
					<InlineConfirm
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.' + options.type}
						show={item.userRemovable}
						tooltip={statics.deleteTool}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

import { Text, Row, Block, IconName } from '@upsales/components';
import { FlowStep } from 'App/resources/Model/Flow';
import React, { useMemo, useEffect, useState } from 'react';
import FlowStepBase from '../FlowStepBase';
import T from 'Components/Helpers/translate';
import { sortBy } from 'lodash';
import formatActionProperty, { sort, RelationDataType } from 'Components/Flow/formatActionProperty';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'App/babel/helpers/promise';
import fetchRelationData from './fetchRelationData';
import logError from 'App/babel/helpers/logError';

import './FlowStepParamList.scss';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
	title: string;
	icon: IconName;
	entity: 'account' | 'contact' | 'activity';
};

export const MAX_HEIGHT = 72;

type FormattedProperty = ReturnType<typeof formatActionProperty>;
type Params = Props['step']['params'];

function formatParams(params: Params, entity: Props['entity'], relationData: RelationDataType) {
	if (!Array.isArray(params)) {
		return [];
	}

	const sortedProperties = sortBy(params, property => sort(entity, property));
	return sortedProperties.reduce<FormattedProperty[]>((res, property, index, params) => {
		if (property.name === 'NewMarketScore') {
			const marketDescription = params.find(property => property.name === 'NewMarketDescription');
			const marketDescriptionText = marketDescription ? `${marketDescription.value} ` : '';

			res.push({ title: T('default.newMarketEvent'), text: `${marketDescriptionText}${property.value}p` });
		} else if (property.name === 'NewMarketDescription') {
			return res;
		} else if (
			property.name === 'ActivityType' &&
			property.value === Tools.AppService.getTodoTypes()?.PHONE_CALL?.id
		) {
			return res;
		} else {
			res.push(formatActionProperty(entity, property, relationData));
		}

		return res;
	}, []);
}

const FlowStepParamList = ({ icon, entity, title, step, ...baseProps }: Props) => {
	const classes = new BemClass('FlowStepParamList');
	const [relationData, setRelationData] = useState<RelationDataType>({ segment: [], campaign: [] });
	const formattedParams = useMemo(() => formatParams(step.params, entity, relationData), [step.params, relationData]);

	useEffect(() => {
		const cancelablePromise = makeCancelable(fetchRelationData(step));
		cancelablePromise.promise
			.then(([campaign, segment]) => setRelationData({ campaign, segment }))
			.catch(error => logError(error, 'FlowStepParamList - fetchRelationData'));
		return () => cancelablePromise.cancel();
	}, [step.params]);

	const [firstProperty, ...allButFirstProperty] = formattedParams;
	const isInvalid = formattedParams.length === 0;

	return (
		<FlowStepBase
			icon={icon}
			backgroundColor="white"
			borderColor="rose-4"
			title={title}
			maxHeight={MAX_HEIGHT}
			className={classes.b()}
			isInvalid={isInvalid}
			{...baseProps}
		>
			{firstProperty ? (
				<Row>
					<Text>{`${firstProperty.title}:`}</Text>
					<Text>{firstProperty.text}</Text>
				</Row>
			) : null}
			{allButFirstProperty.length ? (
				<Block>
					<Text>{T('flow.step.paramList.andMore', { number: allButFirstProperty.length })}</Text>
				</Block>
			) : null}
		</FlowStepBase>
	);
};

export default FlowStepParamList;

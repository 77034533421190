import React, { useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';

import './SignalsToggle.scss';
import { SlideFade } from 'App/components/animations';

type SignalsToggleProps = {
	enabled: boolean;
	onToggle: () => void;
};

const SignalsToggle: React.FC<SignalsToggleProps> = ({ enabled, onToggle }) => {
	const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
	const classes = new bemClass('SignalsToggle');

	const lang = {
		title: T('account.signals.toggle.confirmTitle'),
		text: T('account.signals.toggle.confirmText')
	};

	const onClick = () => {
		if (!enabled) {
			setShowInfoBox(true);
			setTimeout(() => {
				setShowInfoBox(false);
			}, 4000);
		} else if (enabled) {
			setShowInfoBox(false);
		}
		onToggle();
	};

	return (
		<div className={classes.mod({ enabled: enabled }).b()} onClick={onClick}>
			{enabled ? (
				<div className={classes.elem('icon-background').b()}>
					<Icon name="rss" color="green"></Icon>
				</div>
			) : null}
			{!enabled ? <Icon name="rss" color="grey-10"></Icon> : null}
			<SlideFade visible={showInfoBox} bounce direction="top" speed="slow">
				<div id="info-box">
					<Icon name="check"></Icon>
					<span>
						<Text align="center" size="lg" color="bright-blue">
							{lang.title}
						</Text>
						<Text align="center" size="md" color="bright-blue">
							{lang.text}
						</Text>
					</span>
				</div>
			</SlideFade>
		</div>
	);
};

export default SignalsToggle;

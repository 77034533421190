'use strict';

angular.module('domain.userdefobj', ['ngRoute', 'upResources', 'security.authorization', 'services.customer']).config([
	'$upModalProvider',
	function ($upModalProvider) {
		$upModalProvider.modal('editUserDefinedObject', {
			templateUrl: require('App/upsales/domain/userdefinedobject/views/editUserDefinedObject.tpl.html?file'),
			controller: 'EditUserDefinedObject as EditUserDefinedObject',
			style: 'form',
			heavy: true,
			closeAnimation: true,
			openAnimation: true,
			resolve: {
				authUser: function ($routeGuard) {
					return $routeGuard.requireUser();
				},
				meta: function ($modalParams, EditUserDefinedObjectMeta) {
					return EditUserDefinedObjectMeta($modalParams);
				}
			}
		});
	}
]);

'use strict';

angular.module('upResources').factory('Lookup', [
	'$resource',
	'URL',
	'API',
	function Lookup($resource, URL, API) {
		var Model = {
			customer: function (customer) {
				var instance = {};
				var Lookup = null;
				var baseUrl = URL + API + customer + '/lookup/:type/:field/';

				Lookup = $resource(
					baseUrl + ':templateId',
					{},
					{
						find: { method: 'GET' }
					}
				);

				instance.findEnd = function (field, term, limit, sort, excludeValues) {
					if (!instance.type) {
						throw new Error('No type set for lookup resource');
					}

					if (typeof field === 'object' && field.hasOwnProperty('field')) {
						field = field.field;
					}

					var params = {};
					params.limit = limit || 10;
					params.type = instance.type;
					params.field = field;
					params.q = term + '*';
					params.caseSensitive = instance.isCaseSensitive;
					instance.type = null;

					if (sort) {
						params.sort = sort;
					}

					if (excludeValues) {
						params.excludeValues = excludeValues;
					}

					return Lookup.find(params).$promise;
				};

				instance.findCustomValues = function (fieldId, term, limit, overrideFieldBase) {
					if (!instance.type) {
						throw new Error('No type set for lookup resource');
					}

					var params = {};
					var fieldBase = 'custom';
					if (overrideFieldBase) {
						fieldBase = overrideFieldBase;
						params[overrideFieldBase + '.fieldId'] = fieldId;
					} else {
						params.fieldId = fieldId;
					}
					params.limit = limit || 10;
					params.type = instance.type;
					params.field = fieldBase + '.value';
					if (term) {
						params.q = term + '*';
					}
					instance.type = null;

					return Lookup.find(params).$promise;
				};

				instance.setType = function (type) {
					instance.type = type;
					return instance;
				};

				instance.setCaseSensitive = function () {
					instance.isCaseSensitive = true;
					return instance;
				};

				return instance;
			},
			allTypes: {
				ACCOUNT: 'client',
				CONTACT: 'contact',
				KEYWORD: 'keyword',
				APPOINTMENT: 'appointment',
				SOLIDITETCLIENT: 'soliditetClient'
			}
		};

		return Model;
	}
]);

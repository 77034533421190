import React from 'react';
import PropTypes from 'prop-types';
import { default as formatActionProperty, sort } from '../formatActionProperty';
import './ActionPopup.scss';

class ActionPopup extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			edit: t('default.edit')
		};
	}

	render() {
		const { type, relationData, properties, children, onEdit } = this.props;
		const rows = _.sortBy(properties, sort.bind(null, type)).reduce((res, property, index, array) => {
			let config;

			if (property.name === 'NewMarketScore') {
				const marketDescription = _.find(array, { name: 'NewMarketDescription' });
				const marketDescriptionText = marketDescription ? marketDescription.value : '';

				config = {
					title: Tools.$translate('default.newMarketEvent'),
					text: `${marketDescriptionText} ${property.value}p`
				};
			} else if (property.name === 'NewMarketDescription') {
				return res;
			} else if (
				property.name === 'ActivityType' &&
				property.value === Tools.AppService.getTodoTypes()?.PHONE_CALL?.id
			) {
				return res;
			} else {
				config = formatActionProperty(type, property, relationData);
			}
			if (config) {
				res.push(
					<div key={property.name} className="popup-content-ta__row">
						<label className="popup-content-ta__row-label">{config.title}</label>
						<div className="popup-content-ta__row-text">{config.text}</div>
					</div>
				);
			}

			return res;
		}, []);

		return (
			<div className="action-popup">
				{children}
				<div className="popup-content-ta">
					{rows}
					<div className="popup-content-ta__edit-btn-wrap">
						<button
							className="save-button btn up-btn btn-bright-blue no-shadow btn-lg btn-block edit-btn"
							onClick={onEdit}
							data-test-id="flow-step-popup-save"
						>
							{this.lang.edit}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

ActionPopup.propTypes = {
	children: PropTypes.any,
	onEdit: PropTypes.func,
	properties: PropTypes.array,
	relationData: PropTypes.any,
	type: PropTypes.string
};

export default ActionPopup;

'use strict';
angular.module('upHelpers').service('TriggerHelper', [
	'$filter',
	'AppService',
	function ($filter, AppService) {
		var attributes;
		var operators = {
			GreaterThanEquals: 'GreaterThanEquals',
			GreaterThan: 'GreaterThan',
			LessThanEquals: 'LessThanEquals',
			LessThan: 'LessThan',
			Equals: 'Equals',
			NotEquals: 'NotEquals'
		};

		var entities = {
			client: {
				Entity: 'Client',
				entity: 'client',
				customFields: true,
				relations: ['client', 'general'],
				selectable: true
			},
			contact: {
				Entity: 'Contact',
				entity: 'contact',
				customFields: true,
				relations: ['contact', 'client', 'general'],
				selectable: true
			},
			order: {
				Entity: 'Order',
				entity: 'order',
				customFields: true,
				relations: ['order', 'orderedproduct', 'client', 'general'],
				selectable: true
			},
			activity: {
				Entity: 'Activity',
				entity: 'activity',
				customFields: true,
				relations: ['activity', 'client', 'general'],
				selectable: () =>
					!(
						Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					)
			},
			todo: {
				Entity: 'Todo',
				entity: 'todo',
				customFields: true,
				relations: ['activity', 'client', 'general'],
				hiddenAttributes: ['Activity.Outcome'],
				selectable: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			},
			phonecall: {
				Entity: 'Phonecall',
				entity: 'phonecall',
				customFields: true,
				relations: ['activity', 'client', 'general'],
				selectable: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			},
			appointment: {
				Entity: 'Appointment',
				entity: 'appointment',
				customFields: true,
				relations: ['appointment', 'client', 'general'],
				selectable: true
			},
			project: {
				Entity: 'Project',
				entity: 'project',
				customFields: true,
				relations: ['project', 'general'],
				selectable: true
			},
			agreement: {
				Entity: 'Agreement',
				entity: 'agreement',
				customFields: true,
				relations: ['agreement', 'agreementproduct', 'client', 'general'],
				selectable: true
			},
			lead: {
				Entity: 'Lead',
				entity: 'lead',
				customFields: true,
				relations: ['lead', 'client', 'general'],
				selectable: false
			},
			clientrelation: {
				Entity: 'ClientRelation',
				entity: 'clientrelation',
				customFields: true,
				relations: ['client', 'general'],
				selectable: false
			},
			orderedproduct: {
				Entity: 'OrderedProduct',
				entity: 'orderedproduct',
				customFields: true,
				relations: [],
				selectable: false
			},
			agreementproduct: {
				Entity: 'AgreementProduct',
				entity: 'agreementproduct',
				customFields: true,
				relations: [],
				selectable: false
			},
			userdefinedobject: {
				Entity: 'UserDefinedObject',
				entity: 'userdefinedobject',
				customFields: true,
				relations: ['userdefinedobject', 'general'],
				isUdo: true,
				udoNo: 1
			},
			userdefinedobject2: {
				Entity: 'UserDefinedObject2',
				entity: 'userdefinedobject2',
				customFields: true,
				relations: ['userdefinedobject2', 'general'],
				isUdo: true,
				udoNo: 2
			},
			userdefinedobject3: {
				Entity: 'UserDefinedObject3',
				entity: 'userdefinedobject3',
				customFields: true,
				relations: ['userdefinedobject3', 'general'],
				isUdo: true,
				udoNo: 3
			},
			userdefinedobject4: {
				Entity: 'UserDefinedObject4',
				entity: 'userdefinedobject4',
				customFields: true,
				relations: ['userdefinedobject4', 'general'],
				isUdo: true,
				udoNo: 4
			},
			general: { Entity: 'General', entity: 'general', customFields: false, relations: [], selectable: false }
		};

		var Actions = {
			SendMail: {
				alias: 'SendMail',
				action: 'SendMail',
				title: 'automationAction.SendEmail',
				multiple: true,
				featureAvailable: true,
				modalName: 'SendEmailAction'
			},
			AssignToUser: {
				alias: 'AssignToUser',
				action: 'AssignToUser',
				title: 'automationAction.AssignToUser',
				multiple: false,
				featureAvailable: true
			},
			UpdateClient: {
				alias: 'UpdateClient',
				action: 'UpdateClient',
				title: 'automationAction.UpdateClient',
				multiple: false,
				featureAvailable: true
			},
			UpdateClientContacts: {
				alias: 'UpdateClientContacts',
				action: 'UpdateClientContacts',
				title: 'automationAction.UpdateClientContacts',
				multiple: false,
				featureAvailable: true
			},
			SendWebhook: {
				alias: 'SendWebhook',
				action: 'SendWebRequest',
				title: 'automationAction.SendWebhook',
				multiple: true,
				featureAvailable: true,
				modalName: 'SendWebhookAction'
			},
			CreateActivity: {
				alias: 'CreateActivity',
				action: 'CreateActivity',
				title: 'automationAction.CreateActivity',
				multiple: false,
				featureAvailable: true,
				available: () =>
					!(
						Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					)
			},
			UpdateContact: {
				alias: 'UpdateContact',
				action: 'UpdateContact',
				title: 'automationAction.UpdateContact',
				multiple: false,
				featureAvailable: true
			},
			CreateAppointment: {
				alias: 'CreateAppointment',
				action: 'CreateAppointment',
				title: 'automationAction.CreateAppointment',
				multiple: false,
				featureAvailable: true
			},
			UpdateAppointment: {
				alias: 'UpdateAppointment',
				action: 'UpdateAppointment',
				title: 'automationAction.UpdateAppointment',
				multiple: false,
				featureAvailable: true,
				modalName: 'updateAppointmentMulti'
			},
			UpdateOrder: {
				alias: 'UpdateOrder',
				action: 'UpdateOrder',
				title: 'automationAction.UpdateOrder',
				multiple: false,
				featureAvailable: true,
				modalName: 'updateOrderMulti'
			},
			UpdateActivity: {
				alias: 'UpdateActivity',
				action: 'UpdateActivity',
				title: 'automationAction.UpdateActivity',
				multiple: false,
				featureAvailable: true,
				modalName: 'updateActivityMulti',
				available: () =>
					!(
						Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					)
			},
			CreateClient: {
				alias: 'CreateClient',
				action: 'CreateClient',
				title: 'automationAction.CreateClient',
				multiple: false,
				featureAvailable: true
			},
			UpdateLead: {
				alias: 'UpdateLead',
				action: 'UpdateLead',
				title: 'automationAction.UpdateLead',
				multiple: false,
				featureAvailable: true
			},
			UpdatePhonecall: {
				alias: 'UpdatePhonecall',
				drawerName: 'PlanPhonecallsTriggerAction',
				action: 'UpdatePhonecall',
				title: 'automationAction.UpdatePhonecall',
				multiple: false,
				featureAvailable: true,
				available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			},
			UpdateTodo: {
				alias: 'UpdateTodo',
				drawerName: 'CreateTodoTriggerAction',
				action: 'UpdateTodo',
				title: 'automationAction.UpdateTodo',
				multiple: false,
				featureAvailable: true,
				available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			},
			CreatePhonecall: {
				alias: 'CreatePhonecall',
				drawerName: 'PlanPhonecallsTriggerAction',
				action: 'CreatePhonecall',
				title: 'automationAction.CreatePhonecall',
				multiple: false,
				featureAvailable: true,
				available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			},
			CreateTodo: {
				alias: 'CreateTodo',
				drawerName: 'CreateTodoTriggerAction',
				action: 'CreateTodo',
				title: 'automationAction.CreateTodo',
				multiple: false,
				featureAvailable: true,
				available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			}
		};

		// Make this more advance
		var actions = {
			client: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			contact: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			order: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			activity: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateActivity,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateActivity,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			appointment: {
				create: [
					//Actions.CreateAppointment, // not in classic
					Actions.UpdateAppointment,
					Actions.UpdateClient,
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					//Actions.CreateAppointment, // not in classic
					Actions.UpdateAppointment,
					Actions.UpdateClient,
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.UpdateClient, Actions.SendMail, Actions.SendWebhook]
			},
			project: {
				create: [Actions.CreateAppointment, Actions.SendMail, Actions.SendWebhook],
				update: [Actions.CreateAppointment, Actions.SendMail, Actions.SendWebhook],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			agreement: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.SendMail,
					Actions.SendWebhook
				]
			},
			lead: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
					// createClient
					// updateLead
				],
				update: [
					Actions.CreateActivity,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
					// createClient
					// updateLead
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			udo: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			clientrelation: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreateAppointment,
					Actions.UpdateClient,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			phonecall: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdatePhonecall,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdatePhonecall,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			},
			todo: {
				create: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateTodo,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				update: [
					Actions.CreateActivity,
					Actions.CreatePhonecall,
					Actions.CreateTodo,
					Actions.UpdateTodo,
					Actions.UpdateClient,
					Actions.UpdateContact,
					Actions.UpdateOrder,
					Actions.SendMail,
					Actions.SendWebhook
				],
				delete: [Actions.SendMail, Actions.SendWebhook]
			}
		};

		var criterias = {};
		var criteriaTree = {};
		var allCriterias = [];

		var getOperatorsFromType = function (type) {
			switch (type) {
				case 'date':
				case 'integer':
					return [
						operators.Equals,
						operators.NotEquals,
						operators.LessThan,
						operators.LessThanEquals,
						operators.GreaterThan,
						operators.GreaterThanEquals
					];
				case 'phonecallOutcome':
					return [operators.Equals];
				default:
					return [operators.Equals, operators.NotEquals];
			}
		};

		var instance = {};

		instance.buildCriterias = function () {
			// Keep local a copy of criterias in her so we do not get circular relations
			var relationAttrs = {};
			criteriaTree = {};
			criterias = {};
			allCriterias = {};
			var metadata = AppService.getMetadata();
			var FeatureHelper = window.Tools.FeatureHelper;
			var standardFields = metadata.standardFields;
			var udoParams = metadata.params.UserDefinedObject;
			_.each(Object.keys(attributes), function (entity) {
				var e = entity.toLowerCase();
				criteriaTree[e] = [];
				criterias[e] = [];
				relationAttrs[e] = [];
				allCriterias[e] = [];

				const hiddenAttributes = entities[e].hiddenAttributes || [];

				_.each(attributes[entity], function (attr) {
					if (hiddenAttributes.includes(attr.id)) return;
					if (standardFields[entity]) {
						var isNewField = attr.feature === FeatureHelper.Feature.NEW_FIELDS;
						var standardField = _.find(standardFields[entity], { name: attr.name });
						if (isNewField && (!standardField || !standardField.active)) {
							return;
						}
					}
					var criteria = {
						id: attr.id,
						type: attr.type,
						min: attr.min,
						max: attr.max,
						operators: getOperatorsFromType(attr.type),
						title: attr.lang
					};
					if (attr.selectText) {
						criteria.selectText = attr.selectText;
					}
					if (attr.idAttribute) {
						criteria.idAttribute = attr.idAttribute;
					}
					if (attr.asCriteria) {
						criterias[e].push(criteria);
						relationAttrs[e].push(criteria);
					}
					allCriterias[e].push(criteria);
				});

				// Custom

				if (entities[e].customFields) {
					var cf = {
						id: entity,
						title: 'default.field.other',
						customFields: e + 'CustomFields'
					};
					relationAttrs[e].push(cf);
				}

				if (entities[e].isUdo) {
					var found = _.find(udoParams, { id: entities[e].udoNo });
					if (found) {
						if (found.linkType === 'contact') {
							// Add client criterias as well, yo.
							entities[e].relations = ['client'].concat(entities[e].relations);
						}
						entities[e].relations = [found.linkType].concat(entities[e].relations);
					}
				}
				if (entities[e].relations.length) {
					_.each(entities[e].relations, function (rel) {
						criteriaTree[e].push({
							id: 'Relation.' + rel,
							title: 'trigger.field.' + rel,
							relation: rel,
							hiddenAttributes
						});
					});
				}
			});
		};

		instance.setAttributes = function (attr) {
			attributes = attr;
			if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && attributes.Activity) {
				attributes.Activity.splice(
					attributes.Activity.findIndex(e => e.id === 'Activity.ActivityType'),
					1
				);
			}
			instance.buildCriterias();
		};

		instance.getEntityCriteriasUnfiltered = function (entity) {
			return allCriterias[entity] ? angular.copy(allCriterias[entity]) : [];
		};

		instance.getEntityCriterias = function (entity) {
			return criterias[entity] ? angular.copy(criterias[entity]) : [];
		};

		instance.getEntityRelations = function (entity) {
			return entity[entity].relations;
		};

		instance.getEntityCriteriaTree = function (entity) {
			return criteriaTree[entity] ? angular.copy(criteriaTree[entity]) : [];
		};

		instance.getEntityActions = function (entity, trigger) {
			if (entity.indexOf('userdefinedobject') === 0) {
				const metadata = AppService.getMetadata();
				const udoId = parseInt(entity.replace('userdefinedobject', ''));
				const found = metadata.params.UserDefinedObject.find(({ id }) => id === udoId);

				if (found?.linkType === 'project') {
					// Phone calls and activities require a client, so we remove them if the UDO is linked to a project
					return actions.udo[trigger[0]].filter(a => {
						return ![Actions.CreateActivity.alias, Actions.CreatePhonecall.alias].includes(a.alias);
					});
				}
				return actions.udo[trigger[0]];
			}
			return actions[entity] && actions[entity][trigger[0]] ? angular.copy(actions[entity][trigger[0]]) : [];
		};

		instance.getEntityObject = function (entity) {
			return angular.copy(entities[entity]);
		};

		instance.getUdoEntityObject = function (udo) {
			var obj = {
				Entity: 'UserDefinedObject_' + udo.id,
				entity: 'userdefinedobject_' + udo.id,
				customFields: true,
				relations: ['general']
			};

			return obj;
		};

		instance.getTriggerEntities = function () {
			return _.pluck(
				_.values(
					_.filter(entities, en => {
						return typeof en.selectable === 'function' ? en.selectable() : en.selectable;
					})
				),
				'entity'
			);
		};

		instance.formatCustomFieldCriteria = function (id, field) {
			var criteria = {};
			criteria.id = id + '.custom_' + field.id;
			criteria.text = field.name;

			// Datatype should never be falsy, but in alpha we have some broken customfields for some reason.
			criteria.type = (field.datatype || '').toLowerCase();
			criteria.alias = field.alias;
			criteria.customfield = true;
			criteria.disabled = false;

			// set operators (and other)
			switch (criteria.type) {
				case 'select':
					criteria.operators = [operators.Equals, operators.NotEquals];
					criteria.selectOptions = [];
					criteria.selectText = 'text';

					angular.forEach(field.default, function (cfOption) {
						criteria.selectOptions.push({ text: cfOption, id: cfOption });
					});
					break;

				case 'integer':
				case 'percent':
				case 'currency':
				case 'discount':
				case 'double':
					criteria.operators = [
						operators.Equals,
						operators.NotEquals,
						operators.GreaterThanEquals,
						operators.LessThanEquals
					];
					break;
				case 'user':
				case 'users':
					criteria.operators = [operators.Equals, operators.NotEquals];
					criteria.selectOptions = [];
					criteria.selectText = 'text';
					if (criteria.type === 'users') {
						criteria.id = criteria.id + '.valueArray';
					}
					AppService.getActiveUsers().forEach(function (cfOption) {
						criteria.selectOptions.push({ text: cfOption.name, id: cfOption.id });
					});
					break;

				default:
					criteria.operators = [operators.Equals, operators.NotEquals];
					break;
			}

			if (field.alias === 'TURNOVER') {
				criteria.type = 'select';
				criteria.operators = [operators.GreaterThanEquals, operators.LessThanEquals];
				criteria.selectOptions = [];
				criteria.selectText = 'text';

				angular.forEach([10000, 30000, 50000, 100000, 500000, 1000000], function (cfOption) {
					criteria.selectOptions.push({ text: $filter('numberFormat')(cfOption), id: cfOption });
				});
			}

			return criteria;
		};

		instance.mapEventsToTrigger = function (events) {
			var eventToTrigger = {
				after_client_insert: 'create',
				after_client_update: 'update',
				after_client_delete: 'delete',
				after_contact_insert: 'create',
				after_contact_update: 'update',
				after_contact_delete: 'delete',
				after_order_insert: 'create',
				after_order_update: 'update',
				after_order_edit: 'edit',
				after_order_delete: 'delete',
				after_activity_insert: 'create',
				after_activity_update: 'update',
				after_activity_delete: 'delete',
				after_appointment_insert: 'create',
				after_appointment_update: 'update',
				after_appointment_delete: 'delete',
				after_project_insert: 'create',
				after_project_update: 'update',
				after_project_delete: 'delete',
				after_clientrelation_insert: 'create',
				after_clientrelation_update: 'update',
				after_clientrelation_delete: 'delete',
				after_agreement_insert: 'create',
				after_agreement_update: 'update',
				after_agreement_edit: 'edit',
				after_agreement_delete: 'delete',
				after_lead_insert: 'create',
				after_lead_update: 'update',
				after_lead_delete: 'delete',
				after_userdefobj_insert: 'create',
				after_userdefobj_update: 'update',
				after_userdefobj_delete: 'delete',
				after_userdefobj_insert2: 'create',
				after_userdefobj_update2: 'update',
				after_userdefobj_delete2: 'delete',
				after_userdefobj_insert3: 'create',
				after_userdefobj_update3: 'update',
				after_userdefobj_delete3: 'delete',
				after_userdefobj_insert4: 'create',
				after_userdefobj_update4: 'update',
				after_userdefobj_delete4: 'delete',
				after_todo_insert: 'create',
				after_todo_update: 'update',
				after_todo_delete: 'delete',
				after_phonecall_insert: 'create',
				after_phonecall_update: 'update',
				after_phonecall_delete: 'delete'
			};

			return events.map(function (event) {
				return eventToTrigger[event];
			});
		};

		instance.mapEntityAndTriggerToEvents = function (entity, triggers) {
			var entityTriggerToEvent = {
				client: {
					create: 'after_client_insert',
					update: 'after_client_update',
					delete: 'after_client_delete'
				},
				contact: {
					create: 'after_contact_insert',
					update: 'after_contact_update',
					delete: 'after_contact_delete'
				},
				order: {
					create: 'after_order_insert',
					update: 'after_order_update',
					edit: 'after_order_edit',
					delete: 'after_order_delete'
				},
				activity: {
					create: 'after_activity_insert',
					update: 'after_activity_update',
					delete: 'after_activity_delete'
				},
				appointment: {
					create: 'after_appointment_insert',
					update: 'after_appointment_update',
					delete: 'after_appointment_delete'
				},
				project: {
					create: 'after_project_insert',
					update: 'after_project_update',
					delete: 'after_project_delete'
				},
				clientrelation: {
					create: 'after_clientrelation_insert',
					update: 'after_clientrelation_update',
					delete: 'after_clientrelation_delete'
				},
				agreement: {
					create: 'after_agreement_insert',
					update: 'after_agreement_update',
					edit: 'after_agreement_edit',
					delete: 'after_agreement_delete'
				},
				lead: {
					create: 'after_lead_insert',
					update: 'after_lead_update',
					delete: 'after_lead_delete'
				},
				userdefinedobject: {
					create: 'after_userdefobj_insert',
					update: 'after_userdefobj_update',
					delete: 'after_userdefobj_delete'
				},
				userdefinedobject2: {
					create: 'after_userdefobj_insert2',
					update: 'after_userdefobj_update2',
					delete: 'after_userdefobj_delete2'
				},
				userdefinedobject3: {
					create: 'after_userdefobj_insert3',
					update: 'after_userdefobj_update3',
					delete: 'after_userdefobj_delete3'
				},
				userdefinedobject4: {
					create: 'after_userdefobj_insert4',
					update: 'after_userdefobj_update4',
					delete: 'after_userdefobj_delete4'
				},
				todo: {
					create: 'after_todo_insert',
					update: 'after_todo_update',
					delete: 'after_todo_delete'
				},
				phonecall: {
					create: 'after_phonecall_insert',
					update: 'after_phonecall_update',
					delete: 'after_phonecall_delete'
				}
			};

			return triggers.map(function (trigger) {
				return entityTriggerToEvent[entity][trigger];
			});
		};

		instance.mapEventToEntity = {
			after_client_insert: 'client',
			after_client_update: 'client',
			after_client_delete: 'client',
			after_contact_insert: 'contact',
			after_contact_update: 'contact',
			after_contact_delete: 'contact',
			after_order_insert: 'order',
			after_order_update: 'order',
			after_order_delete: 'order',
			after_activity_insert: 'activity',
			after_activity_update: 'activity',
			after_activity_delete: 'activity',
			after_appointment_insert: 'appointment',
			after_appointment_update: 'appointment',
			after_appointment_delete: 'appointment',
			after_project_insert: 'project',
			after_project_update: 'project',
			after_project_delete: 'project',
			after_clientrelation_insert: 'clientrelation',
			after_clientrelation_update: 'clientrelation',
			after_clientrelation_delete: 'clientrelation',
			after_agreement_insert: 'agreement',
			after_agreement_update: 'agreement',
			after_agreement_delete: 'agreement',
			after_lead_create: 'lead',
			after_lead_update: 'lead',
			after_lead_delete: 'lead',
			after_userdefobj_insert: 'userdefinedobject',
			after_userdefobj_update: 'userdefinedobject',
			after_userdefobj_delete: 'userdefinedobject',
			after_userdefobj_insert2: 'userdefinedobject2',
			after_userdefobj_update2: 'userdefinedobject2',
			after_userdefobj_delete2: 'userdefinedobject2',
			after_userdefobj_insert3: 'userdefinedobject3',
			after_userdefobj_update3: 'userdefinedobject3',
			after_userdefobj_delete3: 'userdefinedobject3',
			after_userdefobj_insert4: 'userdefinedobject4',
			after_userdefobj_update4: 'userdefinedobject4',
			after_userdefobj_delete4: 'userdefinedobject4',
			after_phonecall_insert: 'phonecall',
			after_phonecall_update: 'phonecall',
			after_phonecall_delete: 'phonecall',
			after_todo_insert: 'todo',
			after_todo_update: 'todo',
			after_todo_delete: 'todo'
		};

		instance.operators = operators;

		return instance;
	}
]);

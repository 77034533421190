import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import {
	Button,
	Column,
	Row,
	Tooltip,
	Icon,
	DropDownMenu,
	Text,
	Input,
	RadioList,
	RadioItem,
	Loader,
	ScrollEndDetector
} from '@upsales/components';
import BS from 'Services/BrowserService';
import './DocumentEditorToolbar.scss';
import LZString from 'lz-string';
import { ColumnType, DocumentTemplateState, RowType } from './DocumentTemplateEditor';
import { generateHTMLFromConfig } from 'Services/DocumentTemplate';
import pdfTemplateResource from '../../../babel/resources/pdfTemplates';
import { generateCSSFromState } from './DocumentTemplateEditor';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import OrderResource from 'App/resources/Order';
import RequestBuilder from 'Resources/RequestBuilder';
import Order from 'App/resources/Model/Order';

const classes = new bemClass('DocumentEditorToolbar');

type OrderRadioListProps = {
	orderList: Order[];
	searchString: string;
	selectedOrder: number | null;
	setSelectedOrder: (value: number | null) => void;
	listContainerRef: React.RefObject<HTMLDivElement>;
};

export const OrderRadioList = ({
	orderList,
	searchString,
	selectedOrder,
	setSelectedOrder,
	listContainerRef
}: OrderRadioListProps) => {
	const { t } = useTranslation();
	const lang = {
		cancel: t('default.cancel'),
		chooseOrder: t('admin.documentTemplateEditor.chooseOrder'),
		noResultsFor: t('default.noResultsFor'),
		noResultsOrOrders: t('admin.documentTemplateEditor.noResultsOrOrders'),
		saveAsTemplate: t('mail.saveAsTemplate'),
		searchOrder: t('admin.documentTemplateEditor.searchOrder'),
		searchOrderSpecifics: t('admin.documentTemplateEditor.searchOrderSpecifics'),
		changeTemplate: t('mail.changeTemplateConfirm'),
		preview: t('admin.documentTemplateEditor.preview'),
		previewInfo: t('admin.documentTemplateEditor.previewInfo'),
		previewTooltip: t('admin.documentTemplateEditor.previewTooltip'),
		sendTest: t('groupMail.sendTest'),
		testRecipients: t('mail.testRecipients'),
		undoChanges: t('mailTemplate.undoChanges'),
		redoChanges: t('mailTemplate.redoChanges'),
		removeDefaultTemplate: t('mail.removeAsDefaultTemplate'),
		saveAsDefaultTemplate: t('mail.saveAsDefaultTemplate')
	};

	const filterOrders = (query: string) => {
		if (query === '') {
			return orderList;
		} else {
			return orderList.filter(
				item =>
					item.description.toLowerCase().includes(query.toLowerCase()) ||
					item.client.name.toLowerCase().includes(query.toLowerCase())
			);
		}
	};

	const filteredOrders = filterOrders(searchString);
	const renderLoadingSize = 20;
	const selectedOrderIndex = filteredOrders.findIndex(item => item.id === selectedOrder);
	const [renderedCount, setRenderedCount] = useState(
		selectedOrderIndex !== -1 && selectedOrderIndex + 3 > renderLoadingSize
			? selectedOrderIndex + 3
			: renderLoadingSize
	);
	const checkedOrderRef = useRef<HTMLDivElement | null>(null);

	const loadMoreOrders = () => {
		setTimeout(() => {
			setRenderedCount(prevCount => prevCount + renderLoadingSize);
		}, 0);
	};

	useEffect(() => {
		if (checkedOrderRef.current && listContainerRef.current) {
			const topPos = checkedOrderRef.current.offsetTop;
			const bottomPos = topPos + checkedOrderRef.current.offsetHeight;
			if (bottomPos > listContainerRef.current.offsetHeight) {
				listContainerRef.current.scrollTop = topPos;
			}
		}
	}, []);
	return (
		<>
			{filteredOrders?.length > 0 ? (
				<div style={{ display: filteredOrders.length ? 'block' : 'none' }}>
					<RadioList onChange={value => setSelectedOrder(parseInt(value))}>
						{filteredOrders.slice(0, renderedCount).map(order => (
							<RadioItem
								key={order.id}
								checked={order.id === selectedOrder}
								value={order.id}
								size="sm"
								label={order.id.toString()}
								className={classes.elem('order').b()}
							>
								<Column ref={order.id === selectedOrder ? checkedOrderRef : null}>
									<Row className={classes.elem('order-title').b()}>{order.description}</Row>
									<Row className={classes.elem('order-text').b()}>{order.client.name}</Row>
									<Row className={classes.elem('order-text').b()}>
										{order.value} {order.currency} • {order.date.toString()}
									</Row>
								</Column>
							</RadioItem>
						))}
					</RadioList>
					<div style={{ height: filteredOrders.length <= renderedCount ? '0px' : '1px' }}>
						<ScrollEndDetector
							onScrollEnd={loadMoreOrders}
							disabled={filteredOrders.length <= renderedCount}
						/>
					</div>
				</div>
			) : (
				<div
					style={{ display: filteredOrders.length ? 'none' : 'block' }}
					className={classes.elem('search-info').b()}
				>
					{lang.noResultsOrOrders}
				</div>
			)}
		</>
	);
};

type Props = {
	state: DocumentTemplateState;
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>;
};

const DocumentEditorToolbar = ({ state, dispatch }: Props) => {
	const { t } = useTranslation();
	const lang = {
		cancel: t('default.cancel'),
		chooseOrder: t('admin.documentTemplateEditor.chooseOrder'),
		noResultsFor: t('default.noResultsFor'),
		saveAsTemplate: t('mail.saveAsTemplate'),
		searchOrder: t('admin.documentTemplateEditor.searchOrder'),
		searchOrderSpecifics: t('admin.documentTemplateEditor.searchOrderSpecifics'),
		changeTemplate: t('mail.changeTemplateConfirm'),
		preview: t('admin.documentTemplateEditor.preview'),
		previewInfo: t('admin.documentTemplateEditor.previewInfo'),
		previewTooltip: t('admin.documentTemplateEditor.previewTooltip'),
		sendTest: t('groupMail.sendTest'),
		testRecipients: t('mail.testRecipients'),
		undoChanges: t('mailTemplate.undoChanges'),
		redoChanges: t('mailTemplate.redoChanges'),
		removeDefaultTemplate: t('mail.removeAsDefaultTemplate'),
		saveAsDefaultTemplate: t('mail.saveAsDefaultTemplate')
	};
	const [orderSearchString, setOrderSearchString] = useState<string>('');
	const [forceRender, setForceRender] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
	const [orders, setOrders] = useState<Order[]>([]);
	const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
	const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
	const { editor } = useSelector((state: RootState) => state.TemplateEditor);
	let modalRef: { current: Element }[] = [
		document.getElementsByClassName('up-modal-curtain')[0],
		document.getElementsByClassName('up-modal')[0]
	]
		.filter(Boolean)
		.map(r => ({ current: r }));
	const listContainerRef = useRef<HTMLDivElement | null>(null);

	const doChange = (next: number, changes: string[]) => {
		const change = changes[next];
		if (change) {
			try {
				const decompressConfig: string | null = LZString.decompressFromBase64(change);
				if (decompressConfig) {
					dispatch({
						config: { ...JSON.parse(decompressConfig).config },
						currentChange: next,
						selectedColumn: JSON.parse(decompressConfig).selectedColumn,
						selectedRow: JSON.parse(decompressConfig).selectedRow,
						sidebarVisible: JSON.parse(decompressConfig).sidebarVisible,
						selectedSettingsTab: JSON.parse(decompressConfig).selectedSettingsTab,
						selectedToolsTab: JSON.parse(decompressConfig).selectedToolsTab
					});
				}
			} catch (e) {
				console.log('Failed to parse config', e);
			}
		}
	};

	const undoChange = () => {
		doChange(state.currentChange - 1, state.changes);
	};

	const redoChange = () => {
		doChange(state.currentChange + 1, state.changes);
	};

	const getTemplateFromState = (config = state.config) => {
		if (state.accountProfile) {
			const templateBody = generateHTMLFromConfig(config, state.accountProfile);
			const template = {
				active: state.activated,
				css: generateCSSFromState(state),
				html: templateBody,
				isCustom: true,
				isSkeleton: false,
				json: '',
				language: '',
				templateId: editor.templateId,
				templateName: state.name,
				userRoles: state.roles,
				uuid: 0,
				isEditorFormat: true,
				configHash: LZString.compressToBase64(JSON.stringify(state.config))
			};
			return template;
		}
	};

	const productTableRows = () => {
		return state.config.rows.flatMap((row: RowType) =>
			row.columns.filter((col: ColumnType) => col.type === 'PRODUCTTABLE')
		);
	};

	const previewDocumentTemplate = async () => {
		if (state.accountProfile) {
			const template = getTemplateFromState();
			if (!template) {
				setLoadingPreview(false);
				return;
			}
			try {
				Tools.$upModal.open('PreviewTemplate', {
					pdfTemplateResource: {
						resource: pdfTemplateResource,
						uuid: 0,
						type: selectedOrder ? 'order' : 'default',
						pdfName: template.templateName,
						sampleDataJson: '',
						templateType: 'custom',
						orderId: selectedOrder,
						hideMailIcon: true,
						previewMeta: {
							useDiscount: productTableRows().map(
								(foundProductTableColumn: ColumnType) => foundProductTableColumn.showDiscounts
							)[0],
							showProductDescription: productTableRows().map(
								(foundProductTableColumn: ColumnType) => foundProductTableColumn.showProductDescription
							)[0]
						},
						template
					}
				});
			} catch {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'error',
					title: 'default.error',
					body: 'admin.documentTemplate.standardTemplatesModal.errors.failedToPreviewPdfTemplate'
				});
			} finally {
				setLoadingPreview(false);
				setTimeout(() => {
					modalRef = [
						document.getElementsByClassName('up-modal-curtain')[0],
						document.getElementsByClassName('up-modal')[0]
					]
						.filter(Boolean)
						.map(r => ({ current: r }));
					setForceRender(!forceRender);
				});
			}
		}
	};

	const fetchOrders = async () => {
		const rb = new RequestBuilder();
		rb.addSort('date');
		const fetchedOrders = await OrderResource.find(rb.build());
		setOrders(fetchedOrders.data);
		setSelectedOrder(fetchedOrders.data.length > 0 ? fetchedOrders.data[0].id : null);
		setLoadingOrders(false);
	};

	const previewDropDown = (close: () => void) => {
		return (
			<Column className={classes.elem('preview-drop-down').b()}>
				<Row className={classes.elem('preview-drop-down-info').b()}>{lang.previewInfo}</Row>
				<Row className={classes.elem('preview-drop-down-orders').b()}>
					<Column>
						<Text>{lang.chooseOrder}</Text>
						<Row className={classes.elem('preview-drop-down-search').b()}>
							<Column className={classes.elem('preview-drop-down-search-inner').b()}>
								<Row className={classes.elem('preview-drop-down-search-top').b()}>
									<Input
										icon="search"
										value={orderSearchString}
										placeholder={lang.searchOrder}
										onChange={e => {
											setOrderSearchString(e.target.value);
										}}
									/>
								</Row>
								<Row
									ref={listContainerRef}
									className={classes.elem('preview-drop-down-search-content').b()}
								>
									<Column>
										{loadingOrders ? (
											<Row className={classes.elem('loading-orders').b()}>
												<Loader size="sm" data-testid="loader"></Loader>
											</Row>
										) : (
											<OrderRadioList
												orderList={orders ?? []}
												searchString={orderSearchString}
												selectedOrder={selectedOrder}
												setSelectedOrder={setSelectedOrder}
												listContainerRef={listContainerRef}
											/>
										)}
									</Column>
								</Row>
							</Column>
						</Row>
					</Column>
				</Row>
				<Row>
					<Column>
						<Row>
							<Tooltip title={lang.previewTooltip} position="top" disabled={selectedOrder !== null}>
								<PrimaryButton
									onClick={() => {
										setLoadingPreview(true);
										previewDocumentTemplate();
										close();
									}}
									disabled={!selectedOrder}
									className={classes.elem('preview-drop-down-button').b()}
									loading={loadingPreview}
									data-testid="preview-order-button"
								>
									{lang.preview}
								</PrimaryButton>
							</Tooltip>
						</Row>
						<Row>
							<ThirdButton
								onClick={() => {
									setLoadingPreview(false);
									close();
								}}
								className={classes.elem('preview-drop-down-button').b()}
							>
								{lang.cancel}
							</ThirdButton>
						</Row>
					</Column>
				</Row>
			</Column>
		);
	};

	const keyDown = (e: KeyboardEvent) => {
		const undo = BS.isOptionCombinedKey(e, 90, false);
		const redo = BS.isOptionCombinedKey(e, 90, true);

		if (undo && !redo) {
			undoChange();
		} else if (redo) {
			redoChange();
		}
	};

	useEffect(() => {
		fetchOrders();
	}, []);

	useEffect(() => {
		if (state.changes) {
			document.addEventListener('keydown', keyDown);
		}

		return () => {
			if (state.changes) {
				document.removeEventListener('keydown', keyDown);
			}
		};
	}, [state.changes, undoChange, redoChange]);
	return (
		<div className={classes.b()} data-testid="DocumentEditorToolbar">
			<Row>
				<Column align="center">
					<div></div>
				</Column>
				<Column align="center">
					<div className={classes.elem('history-buttons').b()}>
						<Tooltip
							title={lang.undoChanges}
							position="top"
							disabled={!state.changes.length || state.currentChange <= 0}
						>
							<Button
								data-testid="undo"
								hoverColor="green"
								shadow="none"
								onClick={undoChange}
								disabled={!state.changes.length || state.currentChange <= 0}
							>
								<Icon name="undo" />
							</Button>
						</Tooltip>
						<Tooltip
							title={lang.redoChanges}
							position="top"
							disabled={!state.changes.length || !state.changes[state.currentChange + 1]}
						>
							<Button
								data-testid="redo"
								hoverColor="green"
								shadow="none"
								onClick={redoChange}
								disabled={!state.changes.length || !state.changes[state.currentChange + 1]}
							>
								<Icon name="redo" />
							</Button>
						</Tooltip>
					</div>
				</Column>
				<Column align="right" data-testid="DropDownMenuColumn">
					<DropDownMenu
						targetRefs={modalRef}
						align="right"
						verticalAlign="bottom"
						renderTrigger={(expanded, setExpanded) => (
							<div className={classes.elem('preview').b()}>
								<Button type="link" onClick={setExpanded}>
									<Icon name="eye" />
									{lang.preview}
								</Button>
							</div>
						)}
					>
						{close => previewDropDown(close)}
					</DropDownMenu>
				</Column>
			</Row>
		</div>
	);
};

export default DocumentEditorToolbar;

'use strict';

angular.module('upResources').factory('Visitor', [
	'$resource',
	'$q',
	'VisitorAttributes',
	'URL',
	'API',
	'AppService',
	'ParseGeneric',
	function Visitor($resource, $q, VisitorAttributes, URL, API, AppService, ParseGeneric) {
		var VA = VisitorAttributes();
		var Attribute = VA.attr;
		var Keys = VA.keys;

		var dateFields = [
			'client.hasActivity',
			'client.hadActivity',
			'client.hasAppointment',
			'client.hadAppointment',
			'client.hasOpportunity',
			'client.hadOpportunity',
			'client.hasOrder',
			'client.hadOrder'
		];
		var Resource = $resource(
			URL + API + 'visits/:id',
			{},
			{
				find: {
					method: 'GET',
					isArray: false,
					skipDateConvert: true,
					transformResponse: ParseGeneric(null, { isArray: true, custom: false, dateFields: dateFields })
				},
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var ValidatePage = $resource(
			URL + API + 'function/validatePage/',
			{},
			{
				create: { method: 'POST', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);

				// Set q array
				params.q = params.q || [];

				return Resource.find(params).$promise;
			},

			get: function (id) {
				if (!id) {
					return $q.reject('Missing id');
				}
				return Resource.get({ id: id }).$promise;
			},

			save: function (/*visit*/) {
				var defered = $q.defer();

				return defered.promise;
			},

			delete: function (/*id*/) {
				var defered = $q.defer();

				return defered.promise;
			},

			validatePage: function (page) {
				return ValidatePage.create({ url: page }).$promise;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

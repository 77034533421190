import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';

export default (): { [key: string]: FilterConfig } => ({
	User: {
		field: 'user.id',
		title: 'default.users',
		type: 'list'
	},
	Currency: {
		field: 'currency',
		title: 'default.currency',
		type: 'list'
	}
});

'use strict';

angular.module('upHelpers').factory('AgreementHelper', [
	'Agreement',
	function (Agreement) {
		function getMasterCurrencySum(rb, customerId) {
			rb.addExtraParam('useEntityAccess', true);
			var totalSumAgg = rb.aggregationBuilder();
			totalSumAgg.addAggregation(rb.aggregationTypes.Sum, Agreement.attr.yearlyValueInMasterCurrency);
			totalSumAgg.aggregationName('sum');
			totalSumAgg.done();

			return Tools.Report.customer(customerId).setType(Tools.Report.type.AGREEMENT).find(rb.build());
		}

		function getRoleCurrencySum(rb, customerId) {
			rb.addExtraParam('useEntityAccess', true);
			var totalSumInRoleCurrency = rb.aggregationBuilder();
			totalSumInRoleCurrency.addAggregation(rb.aggregationTypes.Sum, Agreement.attr.yearlyValueInRoleCurrency);
			totalSumInRoleCurrency.aggregationName('sum');

			totalSumInRoleCurrency.done();

			return Tools.Report.customer(customerId).setType(Tools.Report.type.AGREEMENT).find(rb.build());
		}

		return {
			getRoleCurrencySum,
			getMasterCurrencySum
		};
	}
]);

import {
	ACTIVITIES_FETCH_SUCCESS,
	ACTIVITIES_FETCH_ERROR,
	ACTIVITY_ADDED,
	ACTIVITY_UPDATED,
	ACTIVITY_DELETED,
	ACTIVITIES_FETCHING
} from 'Store/reducers/ActivitiesReducer';

export const activityFetching = value => ({ type: ACTIVITIES_FETCHING, value });
export const activityFetchSuccess = value => ({ type: ACTIVITIES_FETCH_SUCCESS, value });
export const activityFetchError = value => ({ type: ACTIVITIES_FETCH_ERROR, value });
export const activityAdded = value => ({ type: ACTIVITY_ADDED, value });
export const activityUpdated = value => ({ type: ACTIVITY_UPDATED, value });
export const activityDeleted = value => ({ type: ACTIVITY_DELETED, value });

export const getActivities = (date, activeFilters, { limit, offset, sort, asc }, range = 'day', loadMore = false) => {
	return dispatch => {
		const customerId = Tools.AppService.getCustomerId();
		const filters = Tools.FilterHelper.parseFilters(activeFilters, 'activity');

		if (range === 'workWeek') {
			range = 'week';
		}

		if (range !== 'month') {
			filters.addFilter(
				Tools.Activity.attr.date,
				filters.comparisonTypes.GreaterThanEquals,
				moment(date).startOf(range)
			);
			filters.addFilter(
				Tools.Activity.attr.date,
				filters.comparisonTypes.LessThanEquals,
				moment(date).endOf(range)
			);
		} else {
			filters.addFilter(
				Tools.Activity.attr.date,
				filters.comparisonTypes.GreaterThanEquals,
				moment(date).startOf('month').startOf('week')
			);
			filters.addFilter(
				Tools.Activity.attr.date,
				filters.comparisonTypes.LessThanEquals,
				moment(date).endOf('month').endOf('week')
			);
		}

		filters.addFilter(Tools.Activity.attr.isAppointment, filters.comparisonTypes.Equals, false);

		if (limit) {
			filters.limit = limit;
			filters.offset = offset;
		}

		const built = filters.build();

		if (sort) {
			built.sort = !built.sort ? [] : built.sort;
			built.sort.push({ a: sort, s: asc ? 'A' : 'Z' });
		}

		dispatch(activityFetching(loadMore));
		return Tools.ActivityList.customer(customerId)
			.find(built)
			.then(res => {
				dispatch(activityFetchSuccess({ activities: res.data, count: res.metadata.total, loadMore }));
			})
			.catch(e => {
				dispatch(activityFetchError(e.message));
			});
	};
};

export const addActivity = activity => {
	return dispatch => {
		dispatch(activityAdded(activity));
	};
};

export const updateActivity = activity => {
	return dispatch => {
		dispatch(activityUpdated(activity));
	};
};

export const deleteActivity = activity => {
	return dispatch => {
		dispatch(activityDeleted(activity));
	};
};

'use strict';

angular.module('upFilters').filter('secondsToTime', function () {
	function padTime(t) {
		return t < 10 ? '0' + t : t;
	}

	return function (_seconds) {
		var returnValue = '';
		if (_seconds <= 0) returnValue = '00:00';

		if (typeof _seconds === 'string') _seconds = parseInt(_seconds);

		if (_seconds > 0) {
			var hours = Math.floor(_seconds / 3600),
				minutes = Math.floor((_seconds % 3600) / 60),
				seconds = Math.floor(_seconds % 60);

			if (hours > 0) {
				returnValue = padTime(hours) + ':' + padTime(minutes) + ':' + padTime(seconds);
			} else {
				returnValue = padTime(minutes) + ':' + padTime(seconds);
			}
		}

		return returnValue;
	};
});

import React from 'react';
import { Provider } from 'react-redux';
import store from 'Store';

import { getSignatures, setLanguage } from 'Store/reducers/MailSignatureReducer';

import MailSignature from './MailSignature';

class MailSignatureRoot extends React.Component {
	constructor(props) {
		super(props);

		const langObj = {
			headerTitle: 'admin.teamMailSignature.title',
			headerDescription: 'default.mailSignatureInfo',
			newSignature: 'create_new'
		};

		store.dispatch(setLanguage(langObj));
		store.dispatch(getSignatures());
	}

	render() {
		return (
			<Provider store={store}>
				<MailSignature />
			</Provider>
		);
	}
}

export default MailSignatureRoot;

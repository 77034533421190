import React from 'react';
import PropTypes from 'prop-types';
import urlSlug from 'slugify';
import { connect } from 'react-redux';
import { DOMAIN_TYPES, getSocialEventDomains } from 'Store/reducers/DomainReducer';
import './LandingpageSettings.scss';
import {
	ModalHeader,
	ModalContent,
	ModalControls,
	Row,
	Column,
	Label,
	Input,
	Text,
	Textarea,
	Toggle,
	Button
} from '@upsales/components';
import { removeHtml } from '@upsales/common';
import bemClass from '@upsales/components/Utils/bemClass';
import PageDomainInput from '../PageDomainInput';

export const mapStateToProps = state => ({
	verifiedDomains: state.Domains[DOMAIN_TYPES.VERIFIED_SOCIAL_EVENTS]
});

const mapDispatchToProps = {
	getSocialEventDomains
};

const findScriptIndex = form => form.fields.findIndex(f => f.name === 'Prefs.js');

class LandingpageSettings extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			settings: t('admin.settings'),
			abort: t('default.abort'),
			done: t('default.done'),
			name: t('landingpage.pageName'),
			internalName: t('landingpage.pageNameInternal'),
			customScripts: t('landingpage.customScripts'),
			backgroundColor: t('landingpage.pageBackgroundColor'),
			pasteScriptHere: t('landingpage.pasteScriptHere'),
			urlFriendlyName: t('socialEvent.urlFriendlyName'),
			domainChangesFromEventDetails: t('socialEvent.domainChangesFromEventDetails'),
			viewOptions: t('landingpage.viewOptions')
		};

		this.state = {
			name: props.form.name || '',
			internalName: props.form.internalName || localStorage.getItem('forms.internalName') || '',
			scripts: '',
			backgroundColor: props.form.landingPageBody.backgroundColor || '#ffffff',
			specialElements: _.reduce(
				props.form.landingPageBody.rows,
				(res, row) => {
					if (row.special) {
						res[row.id] = row;
					}
					return res;
				},
				{}
			),
			domain: props.form.domain || null,
			urlName: props.form.urlName || '',
			domainValid: true,
			nameTouched: !!props.form.name
		};

		const i = findScriptIndex(props.form);

		if (i !== -1) {
			this.state.scripts = props.form.fields[i].options;
		}

		this.resolve = () => {
			const form = {
				...this.props.form,
				name: this.state.name,
				internalName: this.state.internalName,
				domain: this.state.domain,
				urlName: this.state.urlName
			};

			form.landingPageBody.backgroundColor = this.state.backgroundColor;

			form.landingPageBody.rows = form.landingPageBody.rows.map(row => {
				if (this.state.specialElements[row.id]) {
					row.visible = this.state.specialElements[row.id].visible;
				}
				return row;
			});

			const i = findScriptIndex(form);
			if (i !== -1) {
				form.fields[i].options = this.state.scripts;
			} else {
				form.fields.push({
					title: 'form.Prefs.js',
					name: 'Prefs.js',
					type: 'string',
					options: this.state.scripts
				});
			}

			this.props.resolve(form);
		};

		this.changeColor = value => this.setState({ backgroundColor: value });

		this.setUrlName = name => {
			let urlName = this.state.urlName;
			if (!this.state.nameTouched && name && [' ', '-'].every(s => s !== name[name.length - 1])) {
				urlName = urlSlug(name.toLowerCase());
			}
			name = removeHtml(name);

			this.setState({ name, urlName });
		};

		this.setDomain = ({ domain, urlName, valid, nameTouched }) => {
			this.setState({ domain, urlName, domainValid: valid, nameTouched });
		};

		props.getSocialEventDomains();
	}

	renderToggleRow(key, classes) {
		const row = this.state.specialElements[key];
		const onChange = visible =>
			this.setState({
				specialElements: {
					...this.state.specialElements,
					[row.id]: { ...this.state.specialElements[row.id], visible }
				}
			});
		return (
			<div key={key} className={classes.elem('toggle-row').b()}>
				<Toggle color="medium-green" checked={row.visible} onChange={onChange} />
				<Label onClick={() => onChange(!row.visible)}>{Tools.$translate(row.lang)}</Label>
			</div>
		);
	}

	render() {
		const connectedToEvent = !!this.props.form.socialEventId;
		const classes = new bemClass('LandingpageSettings');
		const hasCustomDomain = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LANDINGPAGE_CUSTOM_DOMAIN);
		const hasEventFeature = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS);
		return (
			<div className={classes.b()}>
				<ModalHeader icon="cog" title={this.lang.settings} onClose={this.props.reject} />
				<ModalContent>
					<Row className={classes.elem('row').b()}>
						<Column className={classes.elem('col').b()}>
							<Label>{this.lang.name}</Label>
							<Input
								className={classes.elem('name').b()}
								value={this.state.name}
								onChange={e => this.setUrlName(e.target.value)}
								maxLength={255}
							/>
						</Column>
						<Column className={classes.elem('col').b()}>
							<Label>{this.lang.internalName}</Label>
							<Input
								className={classes.elem('internalName').b()}
								value={this.state.internalName}
								onChange={e => this.setState({ internalName: e.target.value })}
								maxLength={40}
							/>
						</Column>
					</Row>
					{hasEventFeature || hasCustomDomain ? (
						<Row className={classes.elem('row').b()}>
							<Column className={classes.elem('col').b()}>
								<Label>
									{this.lang.urlFriendlyName} <ReactTemplates.elevio articleId={842} sidebar />
								</Label>
								<PageDomainInput
									disabled={connectedToEvent}
									landingpageId={this.props.form.id}
									domains={this.props.verifiedDomains}
									value={{ domain: this.state.domain, urlName: this.state.urlName }}
									onChange={this.setDomain}
									validateDomain={connectedToEvent ? async () => true : undefined}
								/>
								{connectedToEvent ? (
									<Text color="grey-10" size="sm">
										{this.lang.domainChangesFromEventDetails}
									</Text>
								) : null}
							</Column>
						</Row>
					) : null}
					<Row className={classes.elem('row').b()}>
						<Column className={classes.elem('col').b()}>
							<Label>{this.lang.backgroundColor}</Label>
							<ReactTemplates.bannerEditor.colorPicker
								value={this.state.backgroundColor}
								isText={false}
								onChange={this.changeColor}
							/>
						</Column>
						<Column className={classes.elem('col').b()}>
							<Label>{this.lang.viewOptions}</Label>
							{Object.keys(this.state.specialElements).map(key => this.renderToggleRow(key, classes))}
						</Column>
					</Row>
					<Row className={classes.elem('row').b()}>
						<Column className={classes.elem('col').b()}>
							<Label>{this.lang.customScripts}</Label>
							<Textarea
								className={classes.elem('scripts').b()}
								value={this.state.scripts}
								placeholder={this.lang.pasteScriptHere}
								onChange={e => this.setState({ scripts: e.target.value })}
							/>
						</Column>
					</Row>
				</ModalContent>
				<ModalControls>
					<Button shadow="none" onClick={this.resolve} disabled={!this.state.domainValid}>
						{this.lang.done}
					</Button>
					<Button type="link" color="grey" onClick={this.props.reject}>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

LandingpageSettings.defaultProps = {
	form: {},
	reject: () => {},
	resolve: () => {},
	verifiedDomains: []
};

LandingpageSettings.propTypes = {
	reject: PropTypes.func.isRequired,
	resolve: PropTypes.func.isRequired,
	getSocialEventDomains: PropTypes.func.isRequired,
	verifiedDomains: PropTypes.array.isRequired,
	form: PropTypes.object.isRequired
};

export const detached = LandingpageSettings;

const Component = connect(mapStateToProps, mapDispatchToProps)(LandingpageSettings);
export default Component;
window.LandingpageSettings = Component;

'use strict';

angular.module('upAttributes').service('GenericQuotaAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				userId: {
					title: 'tag.activity.userid',
					field: 'userId',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				user: {
					title: 'tag.activity.userid',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						},
						role: {
							attr: {
								id: {
									type: FilterType.Number,
									field: 'user.role.id'
								}
							}
						}
					}
				},
				period: {
					title: 'date.datePeriod',
					field: 'period',
					type: FilterType.Date,
					displayType: DisplayType.String
				},
				quota: {
					title: 'tag.project.quota',
					field: 'quota',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				currency: {
					title: 'default.currency',
					field: 'currency',
					type: FilterType.String,
					displayType: DisplayType.Number
				},
				currencyRate: {
					title: 'default.currencyRate',
					field: 'currencyRate',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				type: {
					title: 'default.type',
					field: 'type',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					sortable: 'user.name',
					parent: 'user',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						},
						role: {
							field: 'user.role',
							type: FilterType.Object,
							attr: {
								id: {
									field: 'user.role.id',
									type: FilterType.Number
								}
							}
						}
					}
				}
			};

			return {
				attr: attributes,
				keys: {
					USERID: 'userId',
					PERIOD: 'year',
					QUOTA: 'quota',
					CURRENCY: 'currency',
					CURRENCYRATE: 'currencyRate',
					ID: 'id',
					TYPE: 'type'
				}
			};
		};
	}
]);

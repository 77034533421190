'use strict';
import { getFileIcon } from 'App/babel/helpers/file';

angular.module('upDirectives').directive('upFileTypeIcon', function () {
	return {
		restrict: 'A',
		replace: true,
		template: '<b class="fa"></b>',
		link: function ($scope, $element, $attrs) {
			var mimeType = $scope.$eval($attrs.upFileTypeIcon);
			var className = getFileIcon(mimeType);

			$element.addClass(className);
		}
	};
});

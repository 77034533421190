import PropTypes from 'prop-types';

export default PropTypes.shape({
	field: PropTypes.string,
	id: PropTypes.number,
	index: PropTypes.number,
	isMatch: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	name: PropTypes.string,
	sampleValues: PropTypes.arrayOf(PropTypes.string),
	skip: PropTypes.number,
	emptyRows: PropTypes.number
});

'use strict';

angular.module('domain.engage').factory('ListAdsMeta', [
	'$q',
	'RequestBuilder',
	'Ads',
	'AppService',
	function ($q, RequestBuilder, Ads, AppService) {
		return function () {
			return AppService.loadedPromise.then(function () {
				const customerId = AppService.getCustomerId();
				return $q.all({
					hasAdCampaigns: Ads.customer(customerId)
						.findWithMetadata({ limit: 0 })
						.then(res => (res.metadata.total ? true : false)),
					hasBanners: Ads.customer(customerId)
						.findGroupedCreative({ limit: 0 })
						.then(res => (res.metadata.total ? true : false))
				});
			});
		};
	}
]);

'use strict';

angular.module('upResources').service('ParseUserDefinedObject', [
	'AppService',
	'ParseHelpers',
	function (AppService, ParseHelpers) {
		var categoryTypes = null;
		var categories = null;

		return function (object) {
			if (!categoryTypes) {
				categoryTypes = {
					1: AppService.getCategoryTypes('userDefined1'),
					2: AppService.getCategoryTypes('userDefined2'),
					3: AppService.getCategoryTypes('userDefined3'),
					4: AppService.getCategoryTypes('userDefined4')
				};
			}
			if (!categories) {
				categories = {
					1: AppService.getCategories('userDefined1', true),
					2: AppService.getCategories('userDefined2', true),
					3: AppService.getCategories('userDefined3', true),
					4: AppService.getCategories('userDefined4', true)
				};
			}
			ParseHelpers.parseCategories(
				object,
				categories[object.userDefinedObjectId],
				categoryTypes[object.userDefinedObjectId]
			);

			return object;
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setCompanyProfileData } from 'Store/reducers/OnboardingReducer';
import Bem from '@upsales/components/Utils/bemClass';
import { Title, Text, Label, Icon } from '@upsales/components';

const mapDispatchToProps = {
	setCompanyProfileData
};

const mapStateToProps = state => ({
	companyProfile: state.Onboarding.companyProfile,
	currentStepId: state.Onboarding.currentStepId,
	currentSubstepId: state.Onboarding.currentSubstepId
});

class CompanyProfileLogotypes extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.companyProfile.logotypes.maintitle'),
			description: T('onboarding.step.companyProfile.logotypes.description'),
			background: T('admin.background').toLowerCase(),
			dark: T('default.dark'),
			light: T('default.light'),
			edit: {
				upload: T('ads.upload')
			}
		};

		this.selectLogotype = this.selectLogotype.bind(this);
	}

	async selectLogotype(type) {
		const { setCompanyProfileData } = this.props;

		try {
			const response = await Tools.$upModal.open('fileBrowser', {
				public: true,
				multiple: false,
				types: ['image']
			});
			const selectedLogo = response[0].$$publicUrl;

			const key = type === 'dark' ? 'darkLogo' : 'logo';
			setCompanyProfileData(key, selectedLogo);
		} catch (error) {
			/* uoModal rejects if you close it with the x button */
		}
	}

	showOverlay(type, logo, logoClass) {
		if (logo) {
			return (
				<div onClick={() => this.selectLogotype(type)} className={logoClass.elem('Overlay').b()}>
					<div
						className={logoClass
							.elem('Image')
							.mod({ dark: type === 'dark', light: type === 'light' })
							.b()}
					>
						<div
							className={logoClass.elem('BackGroundImage').b()}
							style={{ backgroundImage: `url("${logo}")` }}
						/>
					</div>
				</div>
			);
		}

		return (
			<div onClick={() => this.selectLogotype(type)} className={logoClass.elem('Overlay').b()}>
				<div className={logoClass.elem('IconHolder').b()}>
					<Icon name="plus" />
				</div>
				<Text size="sm">{this.lang.edit.upload}</Text>
			</div>
		);
	}

	logoTypeRender(type, logo) {
		const LogoClass = new Bem('Logotype');
		return (
			<div className={LogoClass.elem(type)}>
				{this.showOverlay(type, logo, LogoClass)}
				<Label required={true}>{`${this.lang[type]} ${this.lang.background}`}</Label>
			</div>
		);
	}

	render() {
		const { companyProfile } = this.props;
		const { darkLogo, logo: lightLogo } = companyProfile;

		const MainClass = new Bem('CompanyProfileLogo');

		return (
			<div className={MainClass}>
				<Title>{this.lang.title}</Title>
				<Text>{this.lang.description}</Text>

				<div className={MainClass.elem('LogoTypes').b()}>
					{this.logoTypeRender('dark', darkLogo)}
					{this.logoTypeRender('light', lightLogo)}
				</div>
			</div>
		);
	}
}

CompanyProfileLogotypes.propTypes = {
	setCompanyProfileData: PropTypes.func,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number,
	companyProfile: PropTypes.object
};

CompanyProfileLogotypes.setAsCompleteOnNext = true;
CompanyProfileLogotypes.buttonDisabledCriterias = ['logo', 'darkLogo'];

export const detached = CompanyProfileLogotypes;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileLogotypes);

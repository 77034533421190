import AdminHeader from 'Components/Admin/AdminHeader';
import { Button, Icon } from '@upsales/components';

ReactTemplates.admin.udos = window.ReactCreateClass({
	getInitialState: function () {
		return {
			editUdoOpen: false,
			edit: null,
			selectOpen: false,
			touched: {
				name: false
			},
			sort: 'name',
			descending: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.linkTypes = {
			4: t('default.account'),
			6: t('default.contact'),
			1: t('default.campaign')
		};

		this.lang = {
			field: t('default.field'),
			description: t('admin.udoDescription'),
			name: t('default.name'),
			all: t('default.all'),
			create: t('admin.newUdo'),
			udo: t('feature.userDefinedObjects'),
			account: t('default.account'),
			campaign: t('default.campaign'),
			contact: t('default.contact'),
			save: t('default.save'),
			cancel: t('default.abort'),
			notes: t('default.notes'),
			connectTo: t('admin.udo.connectTo'),
			ok: t('default.ok'),
			warning1: t('admin.udo.deleteWarningPart1'),
			warning2: t('admin.udo.deleteWarningPart2'),
			newField: t('admin.fieldsNew'),
			remove: t('default.delete'),
			warningTitle: t('admin.udo.warning.title')
		};

		this.editInlineFieldName = this.editInlineField.bind(this, 'name');
	},
	componentDidUpdate: function (prevProps, prevState) {
		if (
			(this.state.edit && !prevState.edit) ||
			(this.state.edit && prevState.edit && this.state.edit.id && prevState.edit.id)
		) {
			var domNode = this.nameInput;
			if (domNode) {
				var inputLength = domNode.value.length;
				domNode.focus();
				domNode.setSelectionRange(inputLength, inputLength);
			}
		}
	},
	onNewField: function () {
		var id = this.nextId();

		if (!(this.state.edit && !this.state.edit.id) && id) {
			this.setState({
				edit: { id: id, name: '', notes: '', link: 0 },
				touched: { name: false }
			});
		}
	},
	inlineEdit: function (item) {
		this.setState({ edit: _.cloneDeep(item) });
	},
	editInlineField: function (property, event) {
		var value = (event.target.value || '').substring(0, 30);

		var touched = _.cloneDeep(this.state.touched);
		touched[property] = true;

		this.state.edit[property] = value;
		this.setState({
			edit: this.state.edit,
			touched: touched
		});
	},
	stopEdit: function () {
		this.setState({ edit: null });
	},
	editFields: function (udo, event) {
		ReactTemplates.TOOLS.stopProp(event);

		var $state = this.props.tools.$state;
		$state.go('administration.fields', { type: 'userDefined' + udo.id });
	},
	saveEdit: function () {
		if (this.validate('name', this.state.edit) && this.validate('link', this.state.edit)) {
			var onSave = this.props.rootData.onSave;
			onSave(this.state.edit);

			this.setState({ edit: null });
		}
	},

	nextId: function () {
		var rootData = this.props.rootData;
		var udos = rootData.pageData.udos;
		var checker = [true, true, true, true];

		_.each(udos, function (udo) {
			checker[udo.id - 1] = false;
		});

		for (var itr = 0; itr < 4; ++itr) {
			if (checker[itr] === true) {
				return itr + 1;
			}
		}
		return undefined;
	},
	keyPressSave: function (e) {
		if (e.charCode === 13) {
			this.saveEdit();
		}
	},
	validate: function (propery, item) {
		if (propery === 'name') {
			return (item.name || '').trim().length;
		} else if (propery === 'link') {
			return item.link === 1 || item.link === 4 || item.link === 6;
		} else {
			return false;
		}
	},
	typeChanged: function (e) {
		this.state.edit.link = e.target.added.id;
		this.setState({
			edit: this.state.edit
		});
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	editRow: function (item, index) {
		var t = Tools.$translate;
		var link = this.linkTypes[item.link] || '';
		var udos = this.props.rootData.pageData.udos;
		var nameClasses = 'floating-label-input';

		if (item.name && item.name.length > 0) {
			nameClasses = nameClasses + ' has-value';
		}
		if (this.state.touched.name && !this.validate('name', item)) {
			nameClasses = nameClasses + ' has-error';
		}

		var data = _.map(this.linkTypes, function (name, id) {
			return {
				id: parseInt(id),
				name: name
			};
		});

		var select2Options = {
			placeholder: this.lang.connectTo,
			dropdownCssClass: 'udo-type-select-dropdown',
			minimumResultsForSearch: -1,
			allowClear: false
		};

		var isNew =
			_.find(udos, function (udo) {
				return udo.id === item.id;
			}) === undefined;

		var count = this.props.rootData.pageData.fieldCount[item.id];
		var editFields = this.editFields.bind(this, item);

		return (
			<tr
				key={'udo-' + index}
				style={{ height: '62px', backgroundColor: '#f5f5f5' }}
				className="admin-table-row udo-table-inline-tr"
			>
				<td className="admin-table-cell udo-table-inline-td udo-table-inline-td-1">
					<div className={nameClasses}>
						<label>{this.lang.name}</label>
						<input
							key={index}
							type="text"
							name="name"
							onChange={this.editInlineFieldName}
							onKeyPress={this.keyPressSave.bind(null)}
							value={item.name}
							ref={this.setRef.bind(this, 'nameInput')}
						/>
					</div>
				</td>
				{isNew ? (
					<td
						colSpan={2}
						className="admin-table-cell udo-table-inline-td udo-table-inline-td-2"
						style={{ position: 'relative', paddingRight: 15 }}
					>
						<label
							className={
								this.state.edit.link !== 0 ? 'fake-floating-label has-value' : 'fake-floating-label'
							}
						>
							{this.lang.connectTo}
						</label>
						<ReactTemplates.INPUTS.upSelect
							className="udo-type-select"
							onChange={this.typeChanged}
							defaultValue={this.state.edit.link}
							options={select2Options}
							data={data}
						/>
					</td>
				) : (
					[
						<td key="link" className="udo-table-td udo-table-td-2 admin-table-cell">
							{link}
						</td>,
						<td key="fields" className="udo-table-td udo-table-td-3 admin-table-cell">
							<span onClick={editFields} className="btn-link">
								{count ? t('admin.fieldsEdit', { count: count }) : this.lang.newField}
							</span>
						</td>
					]
				)}
				<td className="admin-table-cell udo-table-inline-td udo-table-inline-td-3">
					<div className="btn-wrap">
						<button
							onClick={this.saveEdit}
							className="btn up-btn btn-bright-blue no-shadow btn-sm"
							disabled={this.validate('name', item) && this.validate('link', item) ? false : true}
						>
							{this.lang.save}
						</button>
						<button onClick={this.stopEdit} className="btn up-btn no-shadow btn-sm btn-grey btn-link">
							{this.lang.cancel}
						</button>
					</div>
				</td>
			</tr>
		);
	},

	row: function (udo, index) {
		var rootData = this.props.rootData;
		var t = Tools.$translate;
		var link = this.linkTypes[udo.link] || '';
		var count = rootData.pageData.fieldCount[udo.id];
		var editFields = this.editFields.bind(this, udo);
		var editUdo = this.inlineEdit.bind(this, udo);

		return (
			<tr className={'udo-table-tr clickable'} key={'udo-' + index}>
				<td onClick={editUdo} className="udo-table-td udo-table-td-1 admin-table-cell">
					{udo.name}
				</td>
				<td onClick={editUdo} className="udo-table-td udo-table-td-2 admin-table-cell">
					{link}
				</td>
				<td className="udo-table-td udo-table-td-3 admin-table-cell">
					<span onClick={editFields} className="btn-link">
						{count ? t('admin.fieldsEdit', { count: count }) : this.lang.newField}
					</span>
				</td>
				<td className="admin-row-tools-wrap udo-tr-custom-delete">
					<div className="admin-row-tools">
						<InlineConfirm
							show
							onConfirm={() => Promise.reject(this.props.rootData.pageData.delete(udo))}
							entity={'default.userdefinedobject'}
						>
							<Button type="link" color="grey" className="row-tool">
								<Icon name="trash" />
							</Button>
						</InlineConfirm>
					</div>
				</td>
			</tr>
		);
	},
	getSortClass: function (name, type) {
		var sort = this.state.sort;
		var descending = this.state.descending;

		if (sort !== name) {
			return 'fa fa-sort';
		} else if (type === 'number') {
			return descending ? 'fa fa-sort-numeric-desc' : 'fa fa-sort-numeric-asc';
		} else {
			return descending ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc';
		}
	},
	updateSort: function (sort) {
		var descending = sort === this.state.sort && !this.state.descending;
		this.setState({
			sort: sort,
			descending: descending
		});
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;

		var udos = _.sortBy(rootData.pageData.udos, function (item) {
			if (self.state.sort === 'name') {
				return (item.name || '').toLowerCase();
			} else {
				return self.linkTypes[item.link] || '';
			}
		});

		var rows = _.map(udos, function (item, index) {
			if (self.state.edit && self.state.edit.id === item.id) {
				return self.editRow(self.state.edit, index);
			} else {
				return self.row(item, index);
			}
		});

		if (this.state.descending) {
			rows = rows.reverse();
		}

		if (self.state.edit && _.find(udos, { id: self.state.edit.id }) === undefined) {
			rows.unshift(self.editRow(self.state.edit, -1));
		}

		var addButton = (
			<div className="pull-right">
				<Button disabled={self.state.edit ? true : false} onClick={self.onNewField} size="sm">
					{self.lang.create}
				</Button>
			</div>
		);

		return (
			<div id="admin-page-udo">
				<AdminHeader
					title={this.lang.udo}
					description={this.lang.description}
					image="user-defined-objects.svg"
					articleId={456}
				/>

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top">
							<span className="admin-table-title">
								{self.lang.all + ' ' + self.lang.udo.toLowerCase()}
							</span>
							{rootData.pageData.udos.length < 4 ? addButton : null}
						</div>

						<table style={{ tableLayout: 'fixed' }}>
							<thead>
								<tr>
									<th
										onClick={this.updateSort.bind(this, 'name')}
										className={
											'udo-table-th udo-table-th-1 sortable' +
											(this.state.sort === 'name' ? ' active' : '')
										}
									>
										{self.lang.name} <i className={this.getSortClass('name')} />
									</th>
									<th
										onClick={this.updateSort.bind(this, 'connectedTo')}
										className={
											'udo-table-th udo-table-th-2 sortable' +
											(this.state.sort === 'connectedTo' ? ' active' : '')
										}
									>
										{self.lang.connectTo} <ReactTemplates.elevio articleId="456" />{' '}
										<i className={this.getSortClass('connectedTo')} />
									</th>
									<th className="udo-table-th udo-table-th-3">{self.lang.field}</th>
									<th style={{ width: '120px' }} className="udo-table-th udo-table-th-4"></th>
								</tr>
							</thead>
							<tbody>{rows}</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
});

import AdvancedSearchSidebar from '../../../common/components/react/advancedSearch/Sidebar';

angular.module('domain.advancedSearch').directive('advancedSearchSidebar', [
	'$safeApply',
	function ($safeApply) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/sidebar.tpl.html?file'),
			scope: {
				Filters: '=filters',
				view: '=view'
			},
			controller: [
				'$scope',
				'AppService',
				'FilterHelper',
				'$translate',
				'FeatureHelper',
				function ($scope, AppService, FilterHelper, $translate, FeatureHelper) {
					$scope.inited = false;
					$scope.searchString = '';

					var generateCustomFiltersConfig = function (type) {
						var out = [];
						var lowercaseType = type.toLowerCase();
						var customFields = AppService.getCustomFields(lowercaseType);

						var keys = Object.keys($scope.Filters[type]).filter(function (key) {
							return key.substring(0, 7) === 'Custom_';
						});

						keys = _.sortBy(keys, function (key) {
							return _.find(customFields, { id: parseInt($scope.Filters[type][key].field) }).sortId;
						});

						keys.forEach(function (key) {
							out.push({
								getTitle: function () {
									return _.find(customFields, { id: parseInt($scope.Filters[type][key].field) }).name;
								},
								focus: function () {
									var config = _.find(customFields, {
										id: parseInt($scope.Filters[type][key].field)
									});
									var id = '#Custom_' + $scope.Filters[type][key].field;

									if (
										config.dataType === 'Time' ||
										config.dataType === 'Integer' ||
										config.datatype === 'Currency' ||
										config.datatype === 'Percent' ||
										config.datatype === 'Discount'
									) {
										inputFocus(id, 'input:first');
									} else if (
										config.datatype === 'Select' ||
										config.datatype === 'Date' ||
										config.datatype === 'Text' ||
										config.datatype === 'String' ||
										config.datatype === 'Link' ||
										config.datatype === 'Email' ||
										config.datatype === 'Users'
									) {
										inputFocus(id);
									} else {
										buttonFocus(id);
									}
								},
								isActive: function () {
									const filter = $scope.Filters[type][key];
									const filterConfig = {
										type: 'custom',
										filterName: filter.filterName,
										$field: { datatype: filter.dataType }
									};
									return !FilterHelper.isInactiveValue(filter, lowercaseType, filterConfig);
								},
								reset: function () {
									if ($scope.Filters[type][key].dataType === 'Boolean') {
										$scope.Filters[type][key].inactive = true;
									} else if (
										$scope.Filters[type][key].dataType === 'Select' ||
										$scope.Filters[type][key].dataType === 'Users'
									) {
										$scope.Filters[type][key].value = [];
									} else {
										$scope.Filters[type][key] = FilterHelper.filter(key, lowercaseType);
									}
								}
							});
						});

						return out;
					};

					var highlightLabel = function (element) {
						var allredyHighlighted = false;

						var classList = element.prop('classList');

						for (var itr = 0; itr < classList.length; ++itr) {
							if (classList[itr] === 'label-highlight') {
								allredyHighlighted = true;
								break;
							}
						}

						if (allredyHighlighted === false) {
							element.addClass('label-highlight');
							setTimeout(function () {
								element.removeClass('label-highlight');
							}, 1400);
						}
					};

					function buttonFocus(selector) {
						var element = $(selector);
						var offset = element[0].offsetTop - 50;

						highlightLabel(element);

						$('#search-content').animate({ scrollTop: offset }, 200, function () {
							// Do button Focus
						});
					}

					function inputFocus(selector, focusSelector) {
						var element = $(selector);
						var offset = element[0].offsetTop - 50;

						highlightLabel(element);

						$('#search-content').animate({ scrollTop: offset }, 200, function () {
							focusSelector = focusSelector || 'input';
							element.find(focusSelector).focus();
						});
					}

					var accountConfigs = [
						{
							id: '#accountSearchInfo',
							name: 'advancedSearch.accountInfo',
							icon: 'fa fa-home',
							filters: [
								{
									getTitle: function () {
										return 'default.accountName';
									},
									focus: function () {
										inputFocus('#account-search-section-name');
									},
									isActive: function () {
										return !!$scope.Filters.ACCOUNT.Name.value;
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Name.value = '';
									}
								},
								{
									getTitle: function () {
										return 'default.accountManager';
									},
									focus: function () {
										inputFocus('#account-search-section-user');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AccountManagerFilters &&
											($scope.Filters.ACCOUNT.AccountManagerFilters.value.User.value.length ||
												$scope.Filters.ACCOUNT.AccountManagerFilters.value
													.missingAccountManager)
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AccountManagerFilters.value.User.value = [];
										$scope.Filters.ACCOUNT.AccountManagerFilters.value.missingAccountManager = false;
									}
								},
								{
									getTitle: function () {
										return 'default.parentCompany';
									},
									focus: function () {
										inputFocus('#account-search-section-parent');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.ParentId &&
											$scope.Filters.ACCOUNT.ParentId.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.ParentId.value = [];
									}
								},
								{
									getTitle: function () {
										return 'default.id';
									},
									focus: function () {
										inputFocus('#account-search-section-id');
									},
									isActive: function () {
										return !!$scope.Filters.ACCOUNT.Id.value;
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Id.value = '';
									}
								},
								{
									getTitle: function () {
										return 'default.notes';
									},
									focus: function () {
										inputFocus('#account-search-section-notes');
									},
									isActive: function () {
										return !!$scope.Filters.ACCOUNT.Notes.value;
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Notes.value = '';
									}
								},
								{
									getTitle: function () {
										return 'default.campaign';
									},
									focus: function () {
										inputFocus('#account-search-section-campaign');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.Campaign &&
											$scope.Filters.ACCOUNT.Campaign.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Campaign.value = [];
									}
								},
								{
									getTitle: function () {
										return 'default.category';
									},
									focus: function () {
										inputFocus('#account-search-section-categories');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.Category &&
											$scope.Filters.ACCOUNT.Category.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Category.value = [];
									}
								},
								{
									getTitle: function () {
										return 'default.created';
									},
									focus: function () {
										inputFocus('#account-search-section-regdate');
									},
									isActive: function () {
										return (
											$scope.Filters.ACCOUNT.RegDate &&
											($scope.Filters.ACCOUNT.RegDate.value.start ||
												$scope.Filters.ACCOUNT.RegDate.value.end)
										);
									},
									reset: function () {
										if ($scope.Filters.ACCOUNT.RegDate) {
											$scope.Filters.ACCOUNT.RegDate.value.start = null;
											$scope.Filters.ACCOUNT.RegDate.value.end = null;
										}
									}
								}
							]
						},
						{
							id: '#accountGeography',
							name: 'advancedSearch.location',
							icon: 'fa fa-map-marker',
							filters: [
								{
									getTitle: function () {
										return 'address.address';
									},
									focus: function () {
										inputFocus('#account-location-search-address');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AddressFilters &&
											$scope.Filters.ACCOUNT.AddressFilters.value.Address.value
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AddressFilters.value.AddressType.value = null;
										$scope.Filters.ACCOUNT.AddressFilters.value.Address.value = '';
									}
								},
								{
									getTitle: function () {
										return 'address.zip';
									},
									focus: function () {
										inputFocus('#account-location-search-zipcode', 'input:first');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AddressFilters.value.Zipcode &&
											($scope.Filters.ACCOUNT.AddressFilters.value.Zipcode.value.start ||
												$scope.Filters.ACCOUNT.AddressFilters.value.Zipcode.value.end)
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AddressFilters.value.Zipcode.value.start = '';
										$scope.Filters.ACCOUNT.AddressFilters.value.Zipcode.value.end = '';
									}
								},
								{
									getTitle: function () {
										return 'address.city';
									},
									focus: function () {
										inputFocus('#account-location-search-city');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AddressFilters &&
											$scope.Filters.ACCOUNT.AddressFilters.value.City.value &&
											$scope.Filters.ACCOUNT.AddressFilters.value.City.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AddressFilters.value.City.value = [];
									}
								},
								{
									getTitle: function () {
										return 'address.state';
									},
									focus: function () {
										inputFocus('#account-location-search-state');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AddressFilters &&
											$scope.Filters.ACCOUNT.AddressFilters.value.State.value &&
											$scope.Filters.ACCOUNT.AddressFilters.value.State.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AddressFilters.value.State.value = [];
									}
								},
								{
									getTitle: function () {
										return 'address.country';
									},
									focus: function () {
										inputFocus('#account-location-search-country');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.AddressFilters &&
											$scope.Filters.ACCOUNT.AddressFilters.value.Country.value &&
											$scope.Filters.ACCOUNT.AddressFilters.value.Country.value.length
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.AddressFilters.value.Country.value = [];
									}
								}
							]
						},
						{
							id: '#accountContactInfo',
							name: 'advancedSearch.accountContactInfo',
							icon: 'fa fa-comment',
							filters: [
								{
									getTitle: function () {
										return 'form.Client.webpage';
									},
									focus: function () {
										inputFocus('#account-contact-info-website');
									},
									isActive: function () {
										return !!$scope.Filters.ACCOUNT.Webpage.value;
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Webpage.value = '';
									}
								},
								{
									getTitle: function () {
										return 'default.phone';
									},
									focus: function () {
										inputFocus('#account-contact-info-phone');
									},
									isActive: function () {
										return !!($scope.Filters.ACCOUNT.Phone && $scope.Filters.ACCOUNT.Phone.value);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Phone.value = '';
									}
								}
							]
						},
						{
							id: '#accountHistory',
							name: 'advancedSearch.accountHistory',
							icon: 'fa fa-history',
							filters: []
						},
						{
							id: '#marketEvents',
							name: 'scoreboard.eventTypes',
							icon: 'fa fa-bullhorn',
							filters: [
								{
									getTitle: function () {
										return 'default.leadScore';
									},
									focus: function () {
										inputFocus('#market-event-score');
									},
									isActive: function () {
										return !!(
											$scope.Filters.ACCOUNT.Score.value.start ||
											$scope.Filters.ACCOUNT.Score.value.end
										);
									},
									reset: function () {
										$scope.Filters.ACCOUNT.Score.value.start = null;
										$scope.Filters.ACCOUNT.Score.value.end = null;
									}
								}
							]
						},
						{
							id: '#accountCustom',
							name: 'default.otherInfo',
							icon: '',
							filters: generateCustomFiltersConfig('ACCOUNT')
						}
					];

					if (
						!Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS) ||
						Tools.AppService.getMetadata().standardFields.Client.Fax.active
					) {
						accountConfigs[2].filters.push({
							getTitle: function () {
								return 'default.fax';
							},
							focus: function () {
								inputFocus('#account-contact-info-fax');
							},
							isActive: function () {
								return !!$scope.Filters.ACCOUNT.Fax.value;
							},
							reset: function () {
								$scope.Filters.ACCOUNT.Fax.value = '';
							}
						});
					}

					if (FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES)) {
						accountConfigs[0].filters.splice(6, 0, {
							getTitle: function () {
								return 'default.status';
							},
							focus: function () {
								buttonFocus('#account-search-info-status');
							},
							isActive: function () {
								return !$scope.Filters.ACCOUNT.Active.inactive;
							},
							reset: function () {
								$scope.Filters.ACCOUNT.Active.inactive = true;
							}
						});
					}

					$scope.viewConfigs = {
						CONTACT: {
							sections: [
								{
									id: '#searchInfo',
									name: 'default.contacts',
									icon: 'fa fa-user',
									filters: [
										{
											getTitle: function () {
												return 'default.name';
											},
											focus: function () {
												inputFocus('#contact-search-info-name');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Name.value;
											},
											reset: function () {
												$scope.Filters.CONTACT.Name.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.title';
											},
											focus: function () {
												inputFocus('#contact-search-info-title');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Title.value.length;
											},
											reset: function () {
												$scope.Filters.CONTACT.Title.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.campaign';
											},
											focus: function () {
												inputFocus('#contact-search-info-campaign');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Campaign.value.length;
											},
											reset: function () {
												$scope.Filters.CONTACT.Campaign.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.segment';
											},
											focus: function () {
												inputFocus('#contact-search-info-segment');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Segment.value.length;
											},
											reset: function () {
												$scope.Filters.CONTACT.Segment.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.contactCategory';
											},
											focus: function () {
												inputFocus('#contact-search-info-category');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Category.value.length;
											},
											reset: function () {
												$scope.Filters.CONTACT.Category.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.notes';
											},
											focus: function () {
												inputFocus('#contact-search-info-notes');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Notes.value;
											},
											reset: function () {
												$scope.Filters.CONTACT.Notes.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.status';
											},
											focus: function () {
												buttonFocus('#contact-search-info-status');
											},
											isActive: function () {
												return !$scope.Filters.CONTACT.Active.inactive;
											},
											reset: function () {
												$scope.Filters.CONTACT.Active.inactive = true;
											}
										},
										{
											getTitle: function () {
												return 'default.id2';
											},
											focus: function () {
												inputFocus('#contact-search-info-id');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Id.value;
											},
											reset: function () {
												$scope.Filters.CONTACT.Id.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.created';
											},
											focus: function () {
												inputFocus('#contact-search-info-regdate');
											},
											isActive: function () {
												return (
													$scope.Filters.CONTACT.RegDate &&
													($scope.Filters.CONTACT.RegDate.value.start ||
														$scope.Filters.CONTACT.RegDate.value.end)
												);
											},
											reset: function () {
												$scope.Filters.CONTACT.RegDate.value.start = null;
												$scope.Filters.CONTACT.RegDate.value.end = null;
											}
										}
									]
								},
								{
									id: '#contactInfo',
									name: 'account.contactInfo',
									icon: 'fa fa-comment',
									filters: [
										{
											getTitle: function () {
												return 'default.phone';
											},
											focus: function () {
												inputFocus('#contact-contact-info-phone');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.Phone.value;
											},
											reset: function () {
												$scope.Filters.CONTACT.Phone.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.cellPhone';
											},
											focus: function () {
												inputFocus('#contact-contact-info-cellphone');
											},
											isActive: function () {
												return !!$scope.Filters.CONTACT.CellPhone.value;
											},
											reset: function () {
												$scope.Filters.CONTACT.CellPhone.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.email';
											},
											focus: function () {
												inputFocus('#contact-contact-info-email');
											},
											isActive: function () {
												return !!(
													$scope.Filters.CONTACT.EmailMissingAt &&
													$scope.Filters.CONTACT.EmailMissingAt.value.Email.value.length
												);
											},
											reset: function () {
												if ($scope.Filters.CONTACT.EmailMissingAt) {
													$scope.Filters.CONTACT.EmailMissingAt.value.Email.value = '';
												}
											}
										}
									]
								},
								{
									id: '#marketEvents',
									name: 'scoreboard.eventTypes',
									icon: 'fa fa-bullhorn',
									filters: [
										{
											getTitle: function () {
												return 'default.leadScore';
											},
											focus: function () {
												inputFocus('#market-event-score');
											},
											isActive: function () {
												return !!(
													$scope.Filters.CONTACT.Score.value.start ||
													$scope.Filters.CONTACT.Score.value.end
												);
											},
											reset: function () {
												$scope.Filters.CONTACT.Score.value.start = null;
												$scope.Filters.CONTACT.Score.value.end = null;
											}
										}
									]
								},
								{
									id: '#contactCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('CONTACT')
								}
							]
						},
						ACCOUNT: {
							sections: [
								accountConfigs[0],
								accountConfigs[1],
								accountConfigs[2],
								accountConfigs[3],
								accountConfigs[4],
								{
									id: '#accountCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('ACCOUNT')
								}
							]
						},
						ACTIVITY: {
							sections: [
								{
									id: '#activitySearchInfo',
									name: Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
										? 'default.taskInfo'
										: 'default.activityInfo',
									icon: 'up-icon-activity',
									filters: [
										{
											getTitle: function () {
												return 'default.created';
											},
											focus: function () {
												inputFocus('#activity-info-regDate');
											},
											isActive: function () {
												return !!(
													$scope.Filters.ACTIVITY.RegDate &&
													$scope.Filters.ACTIVITY.RegDate.value.preset !== 'whenever'
												);
											},
											reset: function () {
												$scope.Filters.ACTIVITY.RegDate.value = null;
											}
										},
										{
											getTitle: function () {
												return 'default.wasClosed';
											},
											focus: function () {
												inputFocus('#activity-info-wasClosed');
											},
											isActive: function () {
												return !!(
													$scope.Filters.ACTIVITY.CloseDate &&
													$scope.Filters.ACTIVITY.CloseDate.value.preset !== 'whenever'
												);
											},
											reset: function () {
												$scope.Filters.ACTIVITY.CloseDate.value = null;
											}
										},
										{
											getTitle: function () {
												return 'default.date';
											},
											focus: function () {
												inputFocus('#activity-info-date');
											},
											isActive: function () {
												return !!(
													$scope.Filters.ACTIVITY.Date &&
													$scope.Filters.ACTIVITY.Date.value.preset !== 'whenever'
												);
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Date.value = null;
											}
										},
										{
											getTitle: function () {
												return Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
													? 'default.taskType'
													: 'default.activityType';
											},
											focus: function () {
												inputFocus('#activity-info-activityType');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.ActivityType.value.length;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.ActivityType.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.user';
											},
											focus: function () {
												inputFocus('#activity-info-user');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.User.value.length;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.User.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.campaign';
											},
											focus: function () {
												inputFocus('#activity-info-campaign');
											},
											isActive: function () {
												return (
													$scope.Filters.ACTIVITY.Campaign &&
													!!$scope.Filters.ACTIVITY.Campaign.value.length
												);
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Campaign.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.callList';
											},
											focus: function () {
												inputFocus('#activity-info-call-list');
											},
											isActive: function () {
												return (
													$scope.Filters.ACTIVITY.CallList &&
													!!$scope.Filters.ACTIVITY.CallList.value
												);
											},
											reset: function () {
												$scope.Filters.ACTIVITY.CallList.value = undefined;
											}
										},
										{
											getTitle: function () {
												return 'default.status';
											},
											focus: function () {
												buttonFocus('#activity-info-status');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.Status.value;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Status.value = null;
											}
										},
										{
											getTitle: function () {
												return 'default.description';
											},
											focus: function () {
												inputFocus('#activity-info-description');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.Description.value;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Description.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.notes';
											},
											focus: function () {
												inputFocus('#activity-info-notes');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.Notes.value;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Notes.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.id2';
											},
											focus: function () {
												inputFocus('#activity-info-id');
											},
											isActive: function () {
												return !!$scope.Filters.ACTIVITY.Id.value;
											},
											reset: function () {
												$scope.Filters.ACTIVITY.Id.value = '';
											}
										}
									]
								},
								{
									id: '#activityCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('ACTIVITY')
								}
							]
						},
						APPOINTMENT: {
							sections: [
								{
									id: '#appointmentSearchInfo',
									name: 'default.appointmentInfo',
									icon: 'fa fa-calendar',
									filters: [
										{
											getTitle: function () {
												return 'default.date';
											},
											focus: function () {
												buttonFocus('#appointment-info-date');
											},
											isActive: function () {
												return !!(
													$scope.Filters.APPOINTMENT.AdvancedDate &&
													($scope.Filters.APPOINTMENT.AdvancedDate.value.start ||
														$scope.Filters.APPOINTMENT.AdvancedDate.value.end)
												);
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.AdvancedDate.value.start = null;
												$scope.Filters.APPOINTMENT.AdvancedDate.value.end = null;
											}
										},
										{
											getTitle: function () {
												return 'default.appointmentType';
											},
											focus: function () {
												inputFocus('#appointment-info-appointmentType');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.AppointmentType.value.length;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.AppointmentType.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.appointmentUser';
											},
											focus: function () {
												inputFocus('#appointment-info-user');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.User.value.length;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.User.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.campaign';
											},
											focus: function () {
												inputFocus('#appointment-info-campaign');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.Campaign.value.length;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.Campaign.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.appointmentStatus';
											},
											focus: function () {
												inputFocus('#appointment-info-status');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.Status.value;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.Status.value = null;
											}
										},
										{
											getTitle: function () {
												return 'default.description';
											},
											focus: function () {
												inputFocus('#appointment-info-description');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.Description.value;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.Description.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.notes';
											},
											focus: function () {
												inputFocus('#appointment-info-notes');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.Notes.value;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.Notes.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.id2';
											},
											focus: function () {
												inputFocus('#appointment-info-id');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.Id.value;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.Id.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.appointmentOutcome';
											},
											focus: function () {
												inputFocus('#appointment-outcome-id');
											},
											isActive: function () {
												return !!$scope.Filters.APPOINTMENT.AppointmentOutcome.value.length;
											},
											reset: function () {
												$scope.Filters.APPOINTMENT.AppointmentOutcome.value = [];
											}
										}
									]
								},
								{
									id: '#appointmentCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('APPOINTMENT')
								}
							]
						},
						ORDER: {
							sections: [
								{
									id: '#orderSearchInfo',
									name: 'default.orders',
									icon: 'fa fa-usd',
									filters: [
										{
											getTitle: function () {
												return 'default.date';
											},
											focus: function () {
												buttonFocus('#order-info-date');
											},
											isActive: function () {
												return !!(
													$scope.Filters.ORDER.AdvancedDate &&
													($scope.Filters.ORDER.AdvancedDate.value.start ||
														$scope.Filters.ORDER.AdvancedDate.value.end)
												);
											},
											reset: function () {
												$scope.Filters.ORDER.AdvancedDate.value.start = null;
												$scope.Filters.ORDER.AdvancedDate.value.end = null;
											}
										},
										{
											getTitle: function () {
												return 'default.stage';
											},
											focus: function () {
												inputFocus('#order-info-stage');
											},
											isActive: function () {
												return !!$scope.Filters.ORDER.Stage.value.length;
											},
											reset: function () {
												$scope.Filters.ORDER.Stage.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.user';
											},
											focus: function () {
												inputFocus('#order-info-user');
											},
											isActive: function () {
												return !!$scope.Filters.ORDER.User.value.length;
											},
											reset: function () {
												$scope.Filters.ORDER.User.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.product';
											},
											focus: function () {
												inputFocus('#order-info-product');
											},
											isActive: function () {
												return (
													$scope.Filters.ORDER.OrderRowProduct.value &&
													$scope.Filters.ORDER.OrderRowProduct.value.length
												);
											},
											reset: function () {
												$scope.Filters.ORDER.OrderRowProduct.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.sum';
											},
											focus: function () {
												inputFocus('#order-info-sum', 'input:first');
											},
											isActive: function () {
												return !!(
													$scope.Filters.ORDER.ValueRange &&
													($scope.Filters.ORDER.ValueRange.value.start ||
														$scope.Filters.ORDER.ValueRange.value.end)
												);
											},
											reset: function () {
												$scope.Filters.ORDER.ValueRange.value.start = null;
												$scope.Filters.ORDER.ValueRange.value.end = null;
											}
										},
										{
											getTitle: function () {
												return 'default.campaign';
											},
											focus: function () {
												inputFocus('#order-info-campaign');
											},
											isActive: function () {
												return !!$scope.Filters.ORDER.Campaign.value.length;
											},
											reset: function () {
												$scope.Filters.ORDER.Campaign.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.id2';
											},
											focus: function () {
												inputFocus('#order-info-id');
											},
											isActive: function () {
												return !!$scope.Filters.ORDER.Id.value;
											},
											reset: function () {
												$scope.Filters.ORDER.Id.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.description';
											},
											focus: function () {
												inputFocus('#order-info-description');
											},
											isActive: function () {
												return !!$scope.Filters.ORDER.Description.value;
											},
											reset: function () {
												$scope.Filters.ORDER.Description.value = '';
											}
										}
									]
								},
								{
									id: '#orderCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('ORDER')
								},
								{
									id: '#orderrowCustom',
									name: 'default.orderrowFields',
									icon: '',
									filters: generateCustomFiltersConfig('ORDERROW')
								}
							]
						},
						OPPORTUNITY: {
							sections: [
								{
									id: '#opportunitySearchInfo',
									name: 'default.opportunities',
									icon: 'up-icon-pipeline',
									filters: [
										{
											getTitle: function () {
												return 'default.date';
											},
											focus: function () {
												buttonFocus('#opportunity-info-date');
											},
											isActive: function () {
												return !!(
													$scope.Filters.OPPORTUNITY.AdvancedDate &&
													($scope.Filters.OPPORTUNITY.AdvancedDate.value.start ||
														$scope.Filters.OPPORTUNITY.AdvancedDate.value.end)
												);
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.AdvancedDate.value.start = null;
												$scope.Filters.OPPORTUNITY.AdvancedDate.value.end = null;
											}
										},
										{
											getTitle: function () {
												return 'default.status';
											},
											focus: function () {
												buttonFocus('#opportunity-info-status');
											},
											isActive: function () {
												return $scope.Filters.OPPORTUNITY.Status.value !== 'all';
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Status.value = 'all';
											}
										},
										{
											getTitle: function () {
												return 'default.user';
											},
											focus: function () {
												inputFocus('#opportunity-info-user');
											},
											isActive: function () {
												return !!$scope.Filters.OPPORTUNITY.User.value.length;
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.User.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.age';
											},
											focus: function () {
												inputFocus('#opportunity-info-age');
											},
											isActive: function () {
												return (
													$scope.Filters.OPPORTUNITY.Age.value.value ||
													$scope.Filters.OPPORTUNITY.Age.value.value === 0
												);
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Age.value.value = null;
											}
										},
										{
											getTitle: function () {
												return 'default.stage';
											},
											focus: function () {
												inputFocus('#opportunity-info-stage');
											},
											isActive: function () {
												return !!$scope.Filters.OPPORTUNITY.Stage.value.length;
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Stage.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.product';
											},
											focus: function () {
												inputFocus('#opportunity-info-product');
											},
											isActive: function () {
												return (
													$scope.Filters.OPPORTUNITY.OrderRowProduct.value &&
													$scope.Filters.OPPORTUNITY.OrderRowProduct.value.length
												);
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.OrderRowProduct.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.sum';
											},
											focus: function () {
												inputFocus('#opportunity-info-sum', 'input:first');
											},
											isActive: function () {
												return !!(
													$scope.Filters.OPPORTUNITY.ValueRange &&
													($scope.Filters.OPPORTUNITY.ValueRange.value.start ||
														$scope.Filters.OPPORTUNITY.ValueRange.value.end)
												);
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.ValueRange.value.start = null;
												$scope.Filters.OPPORTUNITY.ValueRange.value.end = null;
											}
										},
										{
											getTitle: function () {
												return 'default.id2';
											},
											focus: function () {
												inputFocus('#opportunity-info-id');
											},
											isActive: function () {
												return !!$scope.Filters.OPPORTUNITY.Id.value;
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Id.value = '';
											}
										},
										{
											getTitle: function () {
												return 'default.campaign';
											},
											focus: function () {
												inputFocus('#opportunity-info-campaign');
											},
											isActive: function () {
												return !!$scope.Filters.OPPORTUNITY.Campaign.value.length;
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Campaign.value = [];
											}
										},
										{
											getTitle: function () {
												return 'default.description';
											},
											focus: function () {
												inputFocus('#opportunity-info-description');
											},
											isActive: function () {
												return !!$scope.Filters.OPPORTUNITY.Description.value;
											},
											reset: function () {
												$scope.Filters.OPPORTUNITY.Description.value = '';
											}
										}
									]
								},
								{
									id: '#opportunityCustom',
									name: 'default.otherInfo',
									icon: '',
									filters: generateCustomFiltersConfig('OPPORTUNITY')
								},
								{
									id: '#orderrowCustom',
									name: 'default.orderrowFields',
									icon: '',
									filters: generateCustomFiltersConfig('ORDERROW')
								}
							]
						}
					};

					var showAccountFilters = FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);

					if (showAccountFilters) {
						$scope.viewConfigs.CONTACT.sections.push(accountConfigs[0]);
						$scope.viewConfigs.CONTACT.sections.push(accountConfigs[1]);
						$scope.viewConfigs.CONTACT.sections.push(accountConfigs[2]);
						$scope.viewConfigs.CONTACT.sections.push(accountConfigs[5]);

						$scope.viewConfigs.ACTIVITY.sections.push(accountConfigs[0]);
						$scope.viewConfigs.ACTIVITY.sections.push(accountConfigs[1]);
						$scope.viewConfigs.ACTIVITY.sections.push(accountConfigs[2]);
						$scope.viewConfigs.ACTIVITY.sections.push({
							id: '#accountHistory',
							name: 'advancedSearch.accountHistory',
							icon: 'fa fa-history',
							filters: [
								{
									getTitle: function () {
										return 'default.opportunity';
									},
									focus: function () {
										buttonFocus('#account-history-search-opportunity');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.OpportunityFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.OpportunityFilters.inactive) {
											$('#account-history-search-opportunity > [up-ios-toggle]').click();
										}
									}
								},
								{
									getTitle: function () {
										return 'default.sales';
									},
									focus: function () {
										buttonFocus('#account-history-search-sales');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.OrderFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.OrderFilters.inactive) {
											$('#account-history-search-sales > [up-ios-toggle]').click();
										}
									}
								}
							]
						});
						$scope.viewConfigs.ACTIVITY.sections.push(accountConfigs[5]);

						$scope.viewConfigs.APPOINTMENT.sections.push(accountConfigs[0]);
						$scope.viewConfigs.APPOINTMENT.sections.push(accountConfigs[1]);
						$scope.viewConfigs.APPOINTMENT.sections.push(accountConfigs[2]);
						$scope.viewConfigs.APPOINTMENT.sections.push({
							id: '#accountHistory',
							name: 'advancedSearch.accountHistory',
							icon: 'fa fa-history',
							filters: [
								{
									getTitle: function () {
										return 'default.opportunity';
									},
									focus: function () {
										buttonFocus('#account-history-search-opportunity');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.OpportunityFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.OpportunityFilters.inactive) {
											$('#account-history-search-opportunity > [up-ios-toggle]').click();
										}
									}
								},
								{
									getTitle: function () {
										return 'default.sales';
									},
									focus: function () {
										buttonFocus('#account-history-search-sales');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.OrderFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.OrderFilters.inactive) {
											$('#account-history-search-sales > [up-ios-toggle]').click();
										}
									}
								}
							]
						});
						$scope.viewConfigs.APPOINTMENT.sections.push(accountConfigs[5]);

						$scope.viewConfigs.OPPORTUNITY.sections.push(accountConfigs[0]);
						$scope.viewConfigs.OPPORTUNITY.sections.push(accountConfigs[1]);
						$scope.viewConfigs.OPPORTUNITY.sections.push(accountConfigs[2]);
						$scope.viewConfigs.OPPORTUNITY.sections.push({
							id: '#accountHistory',
							name: 'advancedSearch.accountHistory',
							icon: 'fa fa-history',
							filters: [
								{
									getTitle: function () {
										return 'default.activitiesAndAppointments';
									},
									focus: function () {
										buttonFocus('#account-history-search-activity');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.ActivityFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.ActivityFilters.inactive) {
											$('#account-history-search-activity > [up-ios-toggle]').click();
										}
									}
								}
							]
						});
						$scope.viewConfigs.OPPORTUNITY.sections.push(accountConfigs[5]);

						$scope.viewConfigs.ORDER.sections.push(accountConfigs[0]);
						$scope.viewConfigs.ORDER.sections.push(accountConfigs[1]);
						$scope.viewConfigs.ORDER.sections.push(accountConfigs[2]);
						$scope.viewConfigs.ORDER.sections.push({
							id: '#accountHistory',
							name: 'advancedSearch.accountHistory',
							icon: 'fa fa-history',
							filters: [
								{
									getTitle: function () {
										return 'default.activitiesAndAppointments';
									},
									focus: function () {
										buttonFocus('#account-history-search-activity');
									},
									isActive: function () {
										return !$scope.Filters.ACCOUNT.ActivityFilters.inactive;
									},
									reset: function () {
										if (!$scope.Filters.ACCOUNT.ActivityFilters.inactive) {
											$('#account-history-search-activity > [up-ios-toggle]').click();
										}
									}
								}
							]
						});
						$scope.viewConfigs.ORDER.sections.push(accountConfigs[5]);
					}

					var showAgreementFilter =
						AppService.getMetadata().params.AgreementEnabled &&
						FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER);
					var showOpportunityFilter = FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE);

					if (showOpportunityFilter) {
						$scope.viewConfigs.ORDER.sections[0].filters.push({
							getTitle: function () {
								return 'order.createdFromOpportunity';
							},
							focus: function () {
								buttonFocus('#order-info-opportunity');
							},
							isActive: function () {
								return $scope.Filters.ORDER.FromOpportunity.value !== 'all';
							},
							reset: function () {
								$scope.Filters.ORDER.FromOpportunity.value = 'all';
							}
						});
					}
					if (showAgreementFilter) {
						$scope.viewConfigs.ORDER.sections[0].filters.push({
							getTitle: function () {
								return 'order.createdFromAgreement';
							},
							focus: function () {
								buttonFocus('#order-info-agreement');
							},
							isActive: function () {
								return $scope.Filters.ORDER.AgreementId.value !== 'all';
							},
							reset: function () {
								$scope.Filters.ORDER.AgreementId.value = 'all';
							}
						});
					}

					var showAccountHistory = FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM);
					var showAccountHistoryActivity = FeatureHelper.isAvailable(
						FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS
					);
					var showAccountHistoryOpportunity = FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE);
					var showAccountHistoryOrder = FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS);

					var showMarketEventsMail = FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL);
					var showMarketEventsGroupMail = FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL);
					var showMarketEventsForms = FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS);
					var showMarketEventsVisits = FeatureHelper.isAvailable(FeatureHelper.Feature.VISITS);
					var showMarketEvents =
						showMarketEventsMail ||
						showMarketEventsGroupMail ||
						showMarketEventsForms ||
						showMarketEventsVisits;

					if (showAccountHistoryActivity) {
						$scope.viewConfigs.ACCOUNT.sections[3].filters.push({
							getTitle: function () {
								return 'default.activitiesAndAppointments';
							},
							focus: function () {
								buttonFocus('#account-history-search-activity');
							},
							isActive: function () {
								return !$scope.Filters.ACCOUNT.ActivityFilters.inactive;
							},
							reset: function () {
								if (!$scope.Filters.ACCOUNT.ActivityFilters.inactive) {
									$('#account-history-search-activity > [up-ios-toggle]').click();
								}
							}
						});
					}
					if (showAccountHistoryOpportunity) {
						$scope.viewConfigs.ACCOUNT.sections[3].filters.push({
							getTitle: function () {
								return 'default.opportunity';
							},
							focus: function () {
								buttonFocus('#account-history-search-opportunity');
							},
							isActive: function () {
								return !$scope.Filters.ACCOUNT.OpportunityFilters.inactive;
							},
							reset: function () {
								if (!$scope.Filters.ACCOUNT.OpportunityFilters.inactive) {
									$('#account-history-search-opportunity > [up-ios-toggle]').click();
								}
							}
						});
					}
					if (showAccountHistoryOrder) {
						$scope.viewConfigs.ACCOUNT.sections[3].filters.push({
							getTitle: function () {
								return 'default.sales';
							},
							focus: function () {
								buttonFocus('#account-history-search-sales');
							},
							isActive: function () {
								return !$scope.Filters.ACCOUNT.OrderFilters.inactive;
							},
							reset: function () {
								if (!$scope.Filters.ACCOUNT.OrderFilters.inactive) {
									$('#account-history-search-sales > [up-ios-toggle]').click();
								}
							}
						});
					}

					if (showMarketEventsMail) {
						$scope.viewConfigs.CONTACT.sections[2].filters.push({
							getTitle: function () {
								return 'advancedSearch.unsubribedGroupMail';
							},
							focus: function () {
								buttonFocus('#market-event-unsubscribed');
							},
							isActive: function () {
								return !$scope.Filters.CONTACT.Unsubscribed.inactive;
							},
							reset: function () {
								$scope.Filters.CONTACT.Unsubscribed.inactive = true;
							}
						});
					}
					if (showMarketEventsMail) {
						$scope.viewConfigs.CONTACT.sections[2].filters.push({
							getTitle: function () {
								return 'event.bounce';
							},
							focus: function () {
								buttonFocus('#market-event-bounced');
							},
							isActive: function () {
								return !$scope.Filters.CONTACT.MailBounced.inactive;
							},
							reset: function () {
								$scope.Filters.CONTACT.MailBounced.inactive = true;
							}
						});
					}
					if (showMarketEventsGroupMail) {
						$scope.viewConfigs.CONTACT.sections[2].filters.push({
							getTitle: function () {
								return 'default.mailings';
							},
							focus: function () {
								buttonFocus('#market-event-mailings');
							},
							isActive: function () {
								return (
									$scope.Filters.CONTACT.MailCampaignFilters &&
									!$scope.Filters.CONTACT.MailCampaignFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.CONTACT.MailCampaignFilters &&
									!$scope.Filters.CONTACT.MailCampaignFilters.inactive
								) {
									$('#market-event-mailings > [up-ios-toggle]').click();
								}
							}
						});

						$scope.viewConfigs.ACCOUNT.sections[4].filters.push({
							getTitle: function () {
								return 'default.mailings';
							},
							focus: function () {
								buttonFocus('#market-event-mailings');
							},
							isActive: function () {
								return (
									$scope.Filters.ACCOUNT.MailCampaignFilters &&
									!$scope.Filters.ACCOUNT.MailCampaignFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.ACCOUNT.MailCampaignFilters &&
									!$scope.Filters.ACCOUNT.MailCampaignFilters.inactive
								) {
									$('#market-event-mailings > [up-ios-toggle]').click();
								}
							}
						});
					}
					if (showMarketEventsVisits) {
						$scope.viewConfigs.CONTACT.sections[2].filters.push({
							getTitle: function () {
								return 'event.types.Visit';
							},
							focus: function () {
								buttonFocus('#market-event-visits');
							},
							isActive: function () {
								return (
									$scope.Filters.CONTACT.VisitorFilters &&
									!$scope.Filters.CONTACT.VisitorFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.CONTACT.VisitorFilters &&
									!$scope.Filters.CONTACT.VisitorFilters.inactive
								) {
									$('#market-event-visits > [up-ios-toggle]').click();
								}
							}
						});

						$scope.viewConfigs.ACCOUNT.sections[4].filters.push({
							getTitle: function () {
								return 'event.types.Visit';
							},
							focus: function () {
								buttonFocus('#market-event-visits');
							},
							isActive: function () {
								return (
									$scope.Filters.ACCOUNT.VisitorFilters &&
									!$scope.Filters.ACCOUNT.VisitorFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.ACCOUNT.VisitorFilters &&
									!$scope.Filters.ACCOUNT.VisitorFilters.inactive
								) {
									$('#market-event-visits > [up-ios-toggle]').click();
								}
							}
						});
					}
					if (showMarketEventsForms) {
						$scope.viewConfigs.CONTACT.sections[2].filters.push({
							getTitle: function () {
								return 'event.types.Form';
							},
							focus: function () {
								buttonFocus('#market-event-forms');
							},
							isActive: function () {
								return (
									$scope.Filters.CONTACT.FormSubmitFilters &&
									!$scope.Filters.CONTACT.FormSubmitFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.CONTACT.FormSubmitFilters &&
									!$scope.Filters.CONTACT.FormSubmitFilters.inactive
								) {
									$('#market-event-forms > [up-ios-toggle]').click();
								}
							}
						});

						$scope.viewConfigs.ACCOUNT.sections[4].filters.push({
							getTitle: function () {
								return 'event.types.Form';
							},
							focus: function () {
								buttonFocus('#market-event-forms');
							},
							isActive: function () {
								return (
									$scope.Filters.ACCOUNT.FormSubmitFilters &&
									!$scope.Filters.ACCOUNT.FormSubmitFilters.inactive
								);
							},
							reset: function () {
								if (
									$scope.Filters.ACCOUNT.FormSubmitFilters &&
									!$scope.Filters.ACCOUNT.FormSubmitFilters.inactive
								) {
									$('#market-event-forms > [up-ios-toggle]').click();
								}
							}
						});
					}

					Object.keys($scope.viewConfigs).forEach(function (entity) {
						if (!showAccountHistory) {
							$scope.viewConfigs[entity].sections = filterSection(
								$scope.viewConfigs[entity].sections,
								'#accountHistory'
							);
						}
						if (!showMarketEvents) {
							$scope.viewConfigs[entity].sections = filterSection(
								$scope.viewConfigs[entity].sections,
								'#marketEvents'
							);
						}
					});

					function filterSection(sections, id) {
						return _.filter(sections, function (section) {
							return section.id !== id;
						});
					}

					var getStandardFilterName = function (config) {
						if (['OrgNo'].indexOf(config.name) > -1) {
							return config.name + 'Lookup';
						} else {
							return config.name;
						}
					};

					if (Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)) {
						var contactStandardFields = Tools.AppService.getMetadata().standardFields.Contact;

						if (contactStandardFields.Salutation && contactStandardFields.Salutation.active) {
							var config = contactStandardFields.Salutation;

							$scope.viewConfigs.CONTACT.sections[0].filters.splice(2, 0, {
								getTitle: function () {
									return config.nameTag;
								},
								focus: function () {
									inputFocus('#contact-search-info-salutation');
								},
								isActive: function () {
									return !!$scope.Filters.CONTACT[config.name].value.length;
								},
								reset: function () {
									return ($scope.Filters.CONTACT[config.name].value = []);
								}
							});
						}

						_.each(Tools.AppService.getMetadata().standardFields.Client, function (config) {
							if (
								config.canHide === true &&
								config.active === true &&
								['Facebook', 'Twitter', 'LinkedIn', 'Fax', 'About'].indexOf(config.name) === -1 &&
								!!$scope.Filters.ACCOUNT[getStandardFilterName(config)]
							) {
								$scope.viewConfigs.ACCOUNT.sections[0].filters.push({
									getTitle: function () {
										if (config.name === 'CreditRating') {
											return 'default.creditRating';
										} else if (config.name === 'OrgNo') {
											return 'default.orgNumberShort';
										} else if (config.name === 'NoEmployees') {
											return 'default.numOfEmployees.short';
										}

										return config.nameTag;
									},
									focus: function () {
										var id = '#account-field-' + getStandardFilterName(config);
										inputFocus(id);
									},
									isActive: function () {
										if (config.type === 'Date' || config.type === 'Number') {
											var value = $scope.Filters.ACCOUNT[getStandardFilterName(config)].value;
											return !!value && !!(value.start || value.end);
										} else if (config.name === 'SniCode') {
											return (
												!!$scope.Filters.ACCOUNT.SniCode.value?.length ||
												!!$scope.Filters.ACCOUNT.SniCodeRange.value?.start ||
												!!$scope.Filters.ACCOUNT.SniCodeRange.value?.end
											);
										} else {
											return !!$scope.Filters.ACCOUNT[getStandardFilterName(config)].value.length;
										}
									},
									reset: function () {
										if (config.type === 'Date' || config.type === 'Number') {
											var value = $scope.Filters.ACCOUNT[getStandardFilterName(config)].value;

											if (value) {
												value.start = null;
												value.end = null;
											}
										} else if (config.name === 'SniCode') {
											// Reset both single value and range
											const value = $scope.Filters.ACCOUNT.SniCodeRange.value;
											if (value) {
												value.start = null;
												value.end = null;
											}
											return ($scope.Filters.ACCOUNT.SniCode.value = []);
										} else {
											return ($scope.Filters.ACCOUNT[getStandardFilterName(config)].value = []);
										}
									}
								});
							}
						});
					}

					var addCategoryFilters = function (type) {
						var lowercaseType = type.toLowerCase();
						var categories = AppService.getCategoryTypes(lowercaseType);

						categories.forEach(function (category) {
							$scope.viewConfigs[type].sections[0].filters.push({
								getTitle: function () {
									return category.name;
								},
								focus: function () {
									var id = '#' + lowercaseType + '-category-' + category.id;
									inputFocus(id);
								},
								isActive: function () {
									return !!$scope.Filters[type]['Category_' + category.id].value.length;
								},
								reset: function () {
									$scope.Filters[type]['Category_' + category.id].value = [];
								}
							});
						});
					};

					addCategoryFilters('ACCOUNT');
					addCategoryFilters('CONTACT');

					for (const type of Object.values($scope.viewConfigs)) {
						for (const section of type.sections) {
							section.name = $translate.instant(section.name);
							for (const filter of section.filters) {
								filter.title = $translate.instant(filter.getTitle());
							}
						}
					}

					var container;

					var render = function () {
						container = $('#advanced-search-side-bar')[0];
						if (!container) {
							return;
						}

						ReactDOM.render(
							React.createElement(AdvancedSearchSidebar, {
								config: $scope.viewConfigs,
								view: $scope.view,
								safeApply: function () {
									$safeApply($scope);
								}
							}),
							container
						);
					};

					$scope.$on('$destroy', function () {
						if (container) {
							ReactDOM.unmountComponentAtNode(container);
							container = undefined;
						}
					});

					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						$scope.inited = true;
						render();

						$scope.$watch(
							'Filters',
							function () {
								render();
							},
							true
						);

						$scope.$watch('view', function () {
							render();
						});
					});
				}
			]
		};
	}
]);

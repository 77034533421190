import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import DatePreset from 'App/upsales/common/services/datePresetService';

angular.module('upAttributes').service('AgreementAttributes', [
	'FilterType',
	'DisplayType',
	'OrderRowAttributes',
	'AppService',
	'FeatureHelper',
	function (FilterType, DisplayType, OrderRowAttributes, AppService, FeatureHelper) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'id',
					selectableColumn: true
				},
				description: {
					title: 'default.description',
					field: 'description',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					selectableColumn: true
				},
				intervalPeriod: {
					title: 'agreement.intervalPeriod',
					field: 'metadata.agreementIntervalPeriod',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'metadata.agreementIntervalPeriod',
					selectableColumn: true
				},
				periodLength: {
					title: 'agreement.periodLength',
					field: 'metadata.periodLength',
					type: FilterType.String,
					displayType: DisplayType.String,
					inputType: 'text',
					groupable: false,
					sortable: 'metadata.periodLength',
					selectableColumn: true
				},
				value: {
					title: 'default.value',
					field: 'value',
					type: FilterType.String,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'value'
				},
				contributionMargin: {
					title: 'default.contributionMargin',
					field: 'contributionMargin',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'contributionMargin'
				},
				contributionMarginInAgreementCurrency: {
					title: 'default.contributionMarginInAgreementCurrency',
					field: 'contributionMarginInAgreementCurrency',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'contributionMarginInAgreementCurrency'
				},
				yearlyValue: {
					title: 'default.yearlyValue',
					field: 'yearlyValue',
					type: FilterType.String,
					displayText: () => {
						const hasRecurringOrder = Tools.FeatureHelper.isAvailable(
							Tools.FeatureHelper.Feature.RECURRING_ORDER
						);
						const hasRecurringSalesModel = Tools.AppService.getMetadata().params.SalesModel === 'rr';
						const isMRR = Tools.AppService.getMetadata().params.SalesModelOption === 'mrr';
						const changeYearlyToMonthly = hasRecurringOrder && hasRecurringSalesModel && isMRR;

						return changeYearlyToMonthly ? 'agreement.list.column.monthlyValue' : 'default.yearlyValue';
					},
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'yearlyValue'
				},
				yearlyValueInMasterCurrency: {
					title: 'default.yearlyValueInMasterCurrency',
					field: 'yearlyValueInMasterCurrency',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false
				},
				yearlyValueInRoleCurrency: {
					title: 'default.yearlyValueInRoleCurrency',
					field: 'yearlyValueInRoleCurrency',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					selectableColumn: false
				},
				yearlyContributionMargin: {
					title: 'default.yearlyContributionMargin',
					field: 'yearlyContributionMargin',
					type: FilterType.String,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'yearlyContributionMargin'
				},
				yearlyContributionMarginInAgreementCurrency: {
					title: 'default.yearlyContributionMarginInAgreementCurrency',
					field: 'yearlyContributionMarginInAgreementCurrency',
					type: FilterType.String,
					displayType: DisplayType.Number,
					selectableColumn: true,
					sortable: 'yearlyContributionMarginInAgreementCurrency'
				},
				project: {
					title: 'default.campaigns',
					field: 'project.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'project',
					sortable: 'project.name',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				startDate: {
					title: 'default.startDate',
					field: 'metadata.agreementStartdate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'metadata.agreementStartdate',
					selectableColumn: true
				},
				endDate: {
					title: 'default.endDate',
					field: 'metadata.agreementEnddate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'metadata.agreementEnddate',
					selectableColumn: true
				},
				invoiceStartDate: {
					title: 'agreement.startDateCurrentPeriod',
					field: 'metadata.agreementInvoiceStartdate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'metadata.agreementInvoiceStartdate',
					selectableColumn: true
				},
				nextOrderDate: {
					title: 'agreement.nextOrderDate',
					field: 'metadata.agreementNextOrderDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'metadata.agreementNextOrderDate',
					selectableColumn: true
				},
				renewalDate: {
					title: 'agreement.renewalDate',
					field: 'metadata.agreementRenewalDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					// inputType: 'date',
					sortable: 'metadata.agreementRenewalDate',
					selectableColumn: true
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'user',
					sortable: 'user.name',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					selectableColumn: true
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'contacts',
					groupable: false,
					sortable: 'contact.name',
					selectableColumn: true,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				probability: {
					title: 'default.probability',
					field: 'probability',
					inputType: 'text',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					filterName: 'probability',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'probability',
					selectableColumn: true
				},
				notes: {
					field: 'notes',
					type: FilterType.String
				},
				product: {
					title: 'default.product',
					field: 'orderRow.product.id',
					inputType: 'select',
					type: FilterType.String,
					displayType: DisplayType.String,
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					filter: {
						multiple: true,
						resource: 'Product',
						type: 'list',
						searchField: 'name',
						displayText: _.property('name'),
						title: 'default.account'
					},
					selectableColumn: false
				},
				orderRow: {
					parent: 'orderRow',
					attr: OrderRowAttributes.attr
				},
				stage: {
					title: 'default.stage',
					field: 'stage.id',
					inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'orderStage',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: true,
					sortable: 'stage.name',
					selectableColumn: true,
					parent: 'stage',
					attr: {
						id: {
							field: 'stage.id',
							type: FilterType.Number
						},
						name: {
							field: 'stage.name',
							type: FilterType.String
						}
					}
				},
				currency: {
					title: 'default.currency',
					field: 'currency',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				currencyRate: {
					title: 'default.currencyRate',
					field: 'currencyRate',
					type: FilterType.Float,
					displayType: DisplayType.Number
				},
				clientConnection: {
					title: 'default.clientConnection',
					field: 'clientConnection.id',
					displayType: DisplayType.String,
					parent: 'clientConnection',
					attr: {
						id: {
							field: 'clientConnection.id',
							type: FilterType.Number
						},
						name: {
							field: 'clientConnection.name',
							type: FilterType.String
						}
					}
				},
				metadata: {
					title: '',
					field: 'metadata',
					parent: 'metadata',
					attr: {
						agreementStartdate: {
							field: 'metadata.agreementStartdate',
							type: FilterType.Date
						},
						agreementEnddate: {
							field: 'metadata.agreementEnddate',
							type: FilterType.Date
						},
						agreementInvoiceStartdate: {
							field: 'metadata.agreementInvoiceStartdate',
							type: FilterType.Date
						},
						agreementNextOrderDate: {
							field: 'metadata.agreementNextOrderDate',
							type: FilterType.Date
						},
						agreementRenewalDate: {
							field: 'metadata.agreementRenewalDate',
							type: FilterType.Date
						},
						agreementIntervalPeriod: {
							field: 'metadata.agreementIntervalPeriod',
							type: FilterType.Number
						},
						agreementNotes: {
							field: 'metadata.agreementNotes',
							type: FilterType.String
						},
						agreementOrderCreationTime: {
							field: 'metadata.agreementOrderCreationTime',
							type: FilterType.Date
						},
						periodLength: {
							field: 'metadata.periodLength',
							type: FilterType.Number
						}
					}
				},
				custom: {},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				movedFrom: {
					field: 'movedFrom'
				},
				noticePeriod: {
					title: 'agreement.noticePeriod',
					field: 'metadata.noticePeriod',
					sortable: 'metadata.noticePeriod',
					displayType: DisplayType.number,
					groupable: false,
					selectableColumn: true
				},
				noticeDate: {
					title: 'agreement.noticeDate',
					field: 'metadata.noticeDate',
					sortable: 'metadata.noticeDate',
					displayType: DisplayType.Date,
					groupable: false,
					selectableColumn: true
				}
			};

			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColum = true;
			attributes.campaign = angular.copy(attributes.project);

			var dateFilterPresets = [
				'whenever',
				'nextDay',
				'currentDay',
				'lastDay',
				'nextWeek',
				'currentWeek',
				'lastWeek',
				'nextMonth',
				'currentMonth',
				'lastMonth',
				'nextQuarter',
				'currentQuarter',
				'lastQuarter',
				'nextYear',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var standardFilters = {
				Stage: {
					field: 'stage.id',
					title: 'default.stages',
					inputType: 'select',
					dataPromise: function (customerId, AppService, $q) {
						return $q.when({ data: AppService.getStages('won') });
					},
					multiple: true,
					multiComparitors: true,
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					listType: 'order'
				},
				OrderRowProduct: {
					field: 'orderRow.product.id',
					title: 'default.products',
					type: 'raw',
					multiple: true,
					groupParent: 'category.id',
					groupParentTitle: 'category.name',
					showInactiveToggle: true,
					displayText: _.property('name'),
					toUrl: function (filter) {
						return { v: filter.value, c: filter.comparisonType };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v,
							comparisonType: rawFilter.c
						};
					},
					isInactive: function (filter) {
						return !(filter && filter.value && filter.value.length);
					},
					generate: function () {
						return {
							value: null,
							comparisonType: 'Equals'
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value && filter.value.length) {
							if (filter.comparisonType === 'NotEquals') {
								var groupFilter = rb.groupBuilder();
								groupFilter.isNotFilter();
								groupFilter.addFilter(
									getField('orderRow.product.id'),
									rb.comparisonTypes.Equals,
									filter.value
								);
								groupFilter.done();
							} else {
								rb.addFilter(getField('orderRow.product.id'), rb.comparisonTypes.Equals, filter.value);
							}
						}
					}
				},
				Contact: {
					field: 'contact.id',
					inputType: 'select',
					multiple: true,
					resource: 'Contact',
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: function (res) {
						return res.name + (res.client ? ' (' + res.client.name + ')' : '');
					},
					title: 'default.contacts'
				},
				Campaign: {
					field: 'project.id',
					title: 'default.campaign',
					inputType: 'select',
					multiple: true,
					search: true,
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();
						return function (term) {
							var filter = new RequestBuilder();
							filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							return Campaign.customer(cId).find(filter.build());
						};
					},
					resource: 'Campaign',
					type: 'list',
					searchField: 'name',
					multiComparitors: true,
					displayText: _.property('name')
				},
				Active: {
					title: 'default.active',
					field: 'metadata.agreementEnddate',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.active', value: true },
						{ text: 'default.inactive', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var orGroup;
						if (filter.value) {
							orGroup = rb.orBuilder();
							orGroup.next();
							orGroup.addFilter(
								attributes.metadata.attr.agreementEnddate,
								rb.comparisonTypes.Equals,
								null
							);
							orGroup.next();
							orGroup.addFilter(
								attributes.metadata.attr.agreementEnddate,
								rb.comparisonTypes.GreaterThan,
								new Date()
							);
							orGroup.done();
							rb.addFilter(
								attributes.metadata.attr.agreementStartdate,
								rb.comparisonTypes.LessThanEquals,
								new Date()
							);
						} else {
							if (filter.value === false) {
								orGroup = rb.orBuilder();
								orGroup.next();
								orGroup.addFilter(
									attributes.metadata.attr.agreementEnddate,
									rb.comparisonTypes.LessThan,
									new Date()
								);
								orGroup.next();
								orGroup.addFilter(
									attributes.metadata.attr.agreementStartdate,
									rb.comparisonTypes.GreaterThan,
									new Date()
								);
								orGroup.done();
								rb.addSort(attributes.metadata.attr.agreementStartdate, false);
							}
						}
					}
				},
				YearlyValue: {
					title: 'default.yearlyValue',
					field: 'yearlyValue',
					type: 'range',
					inputType: 'range'
				},
				Description: {
					field: 'description',
					title: 'default.description',
					type: 'text',
					inputType: 'text'
				},
				Id: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'text',
					type: 'text'
				},
				startDate: {
					field: 'metadata.agreementStartdate',
					title: 'default.startDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				},
				endDate: {
					field: 'metadata.agreementEnddate',
					title: 'default.endDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				},
				invoiceStartDate: {
					field: 'metadata.agreementInvoiceStartdate',
					title: 'agreement.startDateCurrentPeriod',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				},
				nextOrderDate: {
					field: 'metadata.agreementNextOrderDate',
					title: 'agreement.nextOrderDate',
					inputType: 'date',
					displayType: 'dateRange',
					type: 'raw',
					parent: 'filter.agreementSettings',
					generate: function () {
						return {
							value: {
								preset: 'whenever'
							}
						};
					},
					isInactive: function (filter) {
						if (!filter.value) {
							return true;
						}
						if (filter.value.preset === 'custom' && (filter.value.start || filter.value.end)) {
							return false;
						}
						if (filter.value.preset !== 'whenever') {
							return false;
						}
						return true;
					},
					toUrl: function (filter) {
						var f = {};

						if (filter.value) {
							f.v = {};

							if (filter.value.start) {
								f.v.s = filter.value.start;
							}
							if (filter.value.end) {
								f.v.e = filter.value.end;
							}
							if (filter.value.preset) {
								f.v.p = filter.value.preset;
							}
						}
						return f;
					},
					fromUrl: function (rawFilter) {
						if (!rawFilter || !rawFilter.v) {
							return this.generate();
						} else {
							var filter = { value: {} };

							if (rawFilter.v.s) {
								filter.value.start = rawFilter.v.s;
							}
							if (rawFilter.v.e) {
								filter.value.end = rawFilter.v.e;
							}
							if (rawFilter.v.p) {
								filter.value.preset = rawFilter.v.p;
							}
							if (filter.value.preset === 'custom') {
								if (filter.value.start) {
									filter.value.start = new Date(filter.value.start);
								}
								if (filter.value.end) {
									filter.value.end = new Date(filter.value.end);
								}
							}

							return filter;
						}
					},
					build: function (filter, rb) {
						var start = filter.value ? filter.value.start : null;
						var end = filter.value ? filter.value.end : null;
						if (filter.value && filter.value.preset && filter.value.preset !== 'custom') {
							var values = DatePreset.getValues(filter.value.preset);
							start = values.start;
							end = values.end;
						}
						if (start) {
							rb.addFilter(
								{ field: 'metadata.agreementNextOrderDate' },
								rb.comparisonTypes.GreaterThanEquals,
								start
							);
						}

						if (end) {
							rb.addFilter(
								{ field: 'metadata.agreementNextOrderDate' },
								rb.comparisonTypes.LessThanEquals,
								end
							);
						}

						rb.addFilter({ field: 'metadata.willCreateMoreOrders' }, rb.comparisonTypes.Equals, true);
					},
					presets: dateFilterPresets
				},
				renewalDate: {
					field: 'metadata.agreementRenewalDate',
					title: 'agreement.renewalDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				},
				intervalPeriod: {
					field: 'metadata.agreementIntervalPeriod',
					title: 'agreement.intervalPeriod',
					inputType: 'select',
					dataPromise: function ($q) {
						return $q.when({
							data: AppService.getStaticValues('recurringInterval')
						});
					},
					multiple: true,
					multiComparitors: true,
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					parent: 'filter.agreementSettings'
				},
				periodLength: {
					field: 'metadata.periodLength',
					title: 'agreement.periodLength',
					inputType: 'select',
					dataPromise: function ($q, $translate) {
						return $q.when({
							data: [
								{
									id: '12',
									name: $translate.instant('agreement.periodLength.oneYear')
								},
								{
									id: '24',
									name: $translate.instant('agreement.periodLength.twoYears')
								},
								{
									id: '36',
									name: $translate.instant('agreement.periodLength.threeYears')
								},
								{
									id: '48',
									name: $translate.instant('agreement.periodLength.fourYears')
								},
								{
									id: '60',
									name: $translate.instant('agreement.periodLength.fiveYears')
								},
								{
									id: '0',
									name: $translate.instant('agreement.periodLength.untilFurtherNotice')
								}
							]
						});
					},
					allowZero: true,
					multiple: true,
					multiComparitors: true,
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					parent: 'filter.agreementSettings'
				},
				User: generateUserFilter(AppService.AccessType.ORDER, {
					field: 'user.id',
					title: 'default.users',
					parent: 'default.user'
				}),
				UserRole: {
					field: 'user.role.id',
					title: 'default.role',
					inputType: 'select',
					multiple: true,
					dataPromise: function ($q, AppService) {
						return $q.when({ data: _.sortBy(AppService.getRoles('order'), 'name') });
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.user'
				},
				Account: {
					title: 'default.accountName',
					field: 'client.name',
					parent: 'default.account',
					type: 'text',
					inputType: 'text'
				},
				AccountManager: generateUserFilter(AppService.AccessType.ACCOUNT, {
					field: 'client.user.id',
					title: 'default.accountManagers',
					parent: 'default.account'
				}),
				AccountCategory: {
					field: 'client.category.id',
					title: 'default.accountCategories',
					inputType: 'select',
					multiple: true,
					dataPromise: function (customerId, $q, filterName, AppService) {
						var defered = $q.defer();
						var categories = AppService.getCategories('account');

						var categoryMap = _.groupBy(categories, 'categoryType');
						if (filterName.indexOf('Category_') === 0) {
							var categoryId = filterName.split('_')[1];
							defered.resolve({ data: categoryMap[categoryId] });
						} else {
							defered.resolve({ data: categoryMap[0] });
						}

						return defered.promise;
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.account'
				},
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter(
								{ field: 'orderRow.product.name' },
								rb.comparisonTypes.Match,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter({ field: 'user.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'description' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				ProbabilityRange: {
					field: 'probability',
					title: 'default.probability',
					type: 'range',
					inputType: 'range',
					listType: 'opportunity',
					hide: true
				},
				noticePeriod: {
					field: 'metadata.noticePeriod',
					title: 'agreement.noticePeriod',
					type: 'dateRange',
					inputType: 'range',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				},
				noticeDate: {
					field: 'metadata.noticeDate',
					title: 'agreement.noticeDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filter.agreementSettings'
				}
			};

			var requiredFields = ['metadata', 'custom'];

			if (AppService.loaded && Tools.AppService.getTotals('products') > 4000) {
				var searchConfig = {
					displayType: 'listLazy',
					search: true,
					searchField: 'name',
					resource: 'Product',
					inputType: 'select',
					searchFn: function () {
						var cId = Tools.AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new Tools.RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Tools.Product.attr.name, true);

							if (term) {
								filter.addFilter(Tools.Product.attr.name, filter.comparisonTypes.Search, term);
							}

							return Tools.Product.customer(cId).find(filter.build());
						};
					}
				};

				standardFilters.OrderRowProduct = _.assign(standardFilters.OrderRowProduct, searchConfig);
			} else {
				var dataPromiseConfig = {
					displayType: 'list',
					inputType: 'selectGroup',
					dataPromise: function (ProductTreeFilterMeta) {
						return ProductTreeFilterMeta(true, '');
					}
				};

				standardFilters.OrderRowProduct = _.assign(standardFilters.OrderRowProduct, dataPromiseConfig);
			}

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				if (
					metaData.params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
					insertAfter(dateFilterPresets, 'nextQuarter', 'nextFiscalQuarter');
					insertAfter(dateFilterPresets, 'nextYear', 'nextFiscalYear');
				}
				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: [],
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				keys: {
					ID: 'id',
					DESCRIPTION: 'description',
					DATE: 'date',
					USER: 'user',
					CONTACT: 'contact',
					ACCOUNT: 'account',
					PROBABILITY: 'probability',
					STAGE: 'stage',
					PRODUCT: 'product',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';

import ListInput from './ListInput';
import { ComparisionToggle } from 'Components/Inputs/ComparisonTypeToggle';

class ToggleList extends React.Component {
	render() {
		const { list, toggle, className = '' } = this.props;
		const classNames = this.props.isListActive(list.defaultValue, toggle.value)
			? className + ' multi-comparitors-wrap active'
			: className + ' multi-comparitors-wrap';

		return (
			<div className={classNames}>
				<ComparisionToggle {...toggle} />
				<ListInput {...list} />
			</div>
		);
	}
}

ToggleList.propTypes = {
	list: PropTypes.object.isRequired,
	toggle: PropTypes.object.isRequired,
	className: PropTypes.string,
	isListActive: PropTypes.func.isRequired
};

ToggleList.defaultProps = {
	isListActive: value => Array.isArray(value) && value.length
};

export default ToggleList;

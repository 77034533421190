angular.module('upDirectives').directive('upTable', [
	'AppService',
	'FilterHelper',
	'ListViewService',
	'$translate',
	'$multiSelect',
	'$upModal',
	'avatarService',
	'$rootScope',
	'$parse',
	'$filter',
	'browserService',
	'API',
	'URL',
	'FeatureHelper',
	function (
		AppService,
		FilterHelper,
		ListViewService,
		$translate,
		$multiSelect,
		$upModal,
		avatarService,
		$rootScope,
		$parse,
		$filter,
		browserService,
		API,
		URL,
		FeatureHelper
	) {
		return {
			restrict: 'A',
			replace: true,
			scope: {
				tableData: '=',
				tableColumns: '=',
				tableLoading: '=',
				tableOptions: '=',
				tableTemplate: '=',
				tableType: '@',
				tableClick: '&',
				tableLimit: '=',
				pages: '=',
				offset: '=',
				useCheck: '=',
				sorting: '=',
				customFields: '=customfields',
				onSort: '&',
				tableInactive: '=',
				editColumns: '&',
				selectables: '=',
				actions: '='
			},
			templateUrl: require('App/upsales/common/directives/templates/upTable.tpl.html?file'),
			controller: [
				'$scope',
				function ($scope) {
					this.selectables = [];

					this.setTableSticky = function (sticky) {
						$scope.tableSticky = !!(sticky !== undefined);
					};

					this.setTableEditable = function (editable) {
						$scope.tableEditable = !!(editable !== undefined);
					};

					this.sort = function ($sorting) {
						$scope.tableLoading = true;
						$scope.tableData = [];
						$scope.sorting = [$sorting];

						if ($scope.onSort && typeof $scope.onSort === 'function') {
							$scope.onSort();
						}

						$scope.$apply();
					};

					this.onError = function () {
						$scope.tableError = true;
						$scope.tableLoading = false;
					};
				}
			],
			link: function ($scope, $element, attrs, controller) {
				var tableContainer;
				var columnTemplates = ListViewService.getAttrs(attrs.tableType);

				var reRender = function () {
					setTimeout(function () {
						renderTable($scope.tableData, $scope.tableColumns);
					}, 0);
				};

				$scope.$on('$multiSelect.toggle', reRender);
				$scope.$on('$multiSelect.selectAll', reRender);
				$scope.$on('$multiSelect.selectAllFilter', reRender);
				$scope.$on('$multiSelect.selectNone', reRender);

				var columns = ListViewService.getAttrs(attrs.tableType);
				var categoryTypes = AppService.getCategoryTypes(attrs.tableType);

				if (attrs.tableType.indexOf('userDefinedObject') === 0) {
					categoryTypes = AppService.getCategoryTypes(
						'userDefined' + attrs.tableType[attrs.tableType.length - 1]
					);
				} else {
					categoryTypes = AppService.getCategoryTypes(attrs.tableType);
				}

				angular.forEach(columns, function (attr, key) {
					if (attr.selectableColumn) {
						attr.key = key;
						controller.selectables.push(attr);
					}
				});

				// Add customCategory types to selection
				if (categoryTypes.length) {
					angular.forEach(categoryTypes, function (cat) {
						controller.selectables.push({
							field: 'Category_' + cat.id,
							key: 'Category_' + cat.id,
							title: cat.name
						});
					});
				}

				// Add extra selectables
				if ($scope.selectables) {
					angular.forEach($scope.selectables, function (sel) {
						controller.selectables.push({
							field: sel.key,
							key: sel.key,
							title: sel.title
						});
					});
				}

				controller.selectables = _.uniq(controller.selectables, 'field');

				var editColumns = function () {
					if (attrs.editColumns && typeof $scope.editColumns === 'function') {
						$scope.editColumns({ $tableType: attrs.tableType });
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('EditColumns', {
								columns: $scope.tableColumns,
								selectables: controller.selectables,
								customfields: $scope.customFields,
								tableType: attrs.tableType
							})
							.then(function (columns) {
								$scope.tableColumns = columns;
							});
					}
				};

				function renderTable(rows, cols) {
					if (!tableContainer) {
						tableContainer = $element.find('#table-container')[0];
					}
					if (tableContainer && rows && Array.isArray(rows) && !$scope.tableInactive) {
						ReactDOM.render(
							React.createElement(ReactTemplates.table, {
								// Table data and columns
								data: rows,
								columns: cols,
								columnTemplates: columnTemplates,
								currentSorting: $scope.sorting ? $scope.sorting[0] : null,
								template: $scope.tableTemplate,
								categoryTypes: categoryTypes,
								attrs: columns,
								sticky: $scope.tableSticky,
								actions: $scope.actions,
								// Injects
								tools: {
									sort: controller.sort,
									limit: $scope.tableLimit,
									AppService: AppService,
									FilterHelper: FilterHelper,
									ListViewService: ListViewService,
									$translate: $translate.instant,
									customFields: $scope.customFields,
									$multiSelect: $multiSelect,
									metadata: AppService.getMetadata(),
									$upModal: $upModal,
									avatarService: avatarService,
									$rootScope: $rootScope,
									editColumns: editColumns,
									$filter: $filter,
									isIE: browserService.isIE(),
									URL: URL,
									API: API,
									FeatureHelper: FeatureHelper,
									$parse: $parse,

									// Opts, the ctrl can pass stuff here
									opts: $scope.tableOptions,

									// This is triggered when the array is changed in react
									onChange: function (newVal) {
										angular.copy(newVal, $scope.tableData);
									}
								}
							}),
							tableContainer,
							function () {
								// console.timeEnd('render');
							}
						);
					}
				}

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(tableContainer);
					tableContainer = undefined;
				});

				// set table editable
				controller.setTableEditable(attrs.tableEditable);

				// Set sticky
				controller.setTableSticky(attrs.tableSticky);

				$scope.$watch('tableColumns', function (newValue) {
					renderTable($scope.tableData, newValue);
				});

				// Force a render of the react table
				$scope.$on('renderTable', function () {
					renderTable($scope.tableData, $scope.tableColumns);
				});

				$scope.$watch('tableInactive', function (inactive) {
					if (inactive) {
						$(tableContainer).hide();
					} else {
						$(tableContainer).show();
					}
				});

				$scope.$watchCollection('tableData', function (newValue) {
					renderTable(newValue, $scope.tableColumns);
				});
			}
		};
	}
]);

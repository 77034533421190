import Trigger, { TriggerAction } from 'App/resources/Model/Trigger';

export enum TRIGGER_ERRORS {
	MISSING_APPOINTMENT_TYPE = 'admin.trigger.dontExecAppointmentTrigger.missingAppointmentType'
}

type TriggerIsValid = {
	isValid: boolean;
	errorLangTag: string;
};

const validationResult = (isValid: boolean, errorLangTag = ''): TriggerIsValid => ({ isValid, errorLangTag });

const validateAppointmentTriggerAction = (trigger: TriggerAction): TriggerIsValid => {
	const appointmentTypeProperty = trigger.properties.find(p => p.name === 'AppointmentType');
	if (appointmentTypeProperty) {
		const validAppointmentType = Tools.AppService.getActivityTypes().find(at => {
			return at.id === parseInt(appointmentTypeProperty.value);
		});
		if (!validAppointmentType) {
			return validationResult(false, TRIGGER_ERRORS.MISSING_APPOINTMENT_TYPE);
		}
		return validationResult(true);
	}
	return validationResult(true);
};

export const validateTriggerAction = (action: TriggerAction): TriggerIsValid => {
	switch (action.alias) {
		case 'UpdateAppointment':
			return validateAppointmentTriggerAction(action);
		default:
			return validationResult(true);
	}
};

const TriggerValidator = (trigger: Trigger): TriggerIsValid => {
	let validation = validationResult(true);
	for (const action of trigger.actions) {
		validation = validateTriggerAction(action);
		if (!validation.isValid) {
			return validation;
		}
	}
	return validation;
};

export default TriggerValidator;

import React, { ComponentProps, useMemo, useEffect } from 'react';
import { Block } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import AcceptContract from 'App/components/AcceptContract';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import { AITracker } from 'App/babel/helpers/Tracker';

type BlockProps = ComponentProps<typeof Block>;
type Props = BlockProps & { title: string };

const CLIENT_PARAM = {
	id: 244,
	name: 'AISuggestionsEnabled'
};

const AcceptAISuggestionContract = ({ title, ...props }: Props) => {
	const AppService = getAngularModule('AppService');

	const self = AppService.getSelf();
	const lang = useMemo(
		() => ({
			title: T(title),
			canNotAcceptText: T('acceptAISuggestionContract.canNotAcceptText')
		}),
		[]
	);

	let clientParamPromise: null | CancelablePromise = null;

	function onAccept() {
		AITracker.track(AITracker.events.ACCEPTED_CONTRACT, {});

		const ClientParam = getAngularModule('ClientParam');
		clientParamPromise = makeCancelable(ClientParam.save(CLIENT_PARAM.id, true, { skipNotification: true }));
		clientParamPromise.promise
			.then(() => {
				const metadata = AppService.getMetadata();
				AppService.setMetadata({ ...metadata, params: { ...metadata.params, [CLIENT_PARAM.name]: true } });
			})
			.catch(error => {
				console.error(error);
				logError(error);
			});
	}

	useEffect(() => clientParamPromise?.cancel(), []);

	return (
		<AcceptContract
			name={'UpsalesAISuggestions'}
			title={lang.title}
			showBetaChip={true}
			canAccept={self?.billingAdmin ?? false}
			canNotAcceptText={lang.canNotAcceptText}
			onAccept={onAccept}
			{...props}
		/>
	);
};

export default AcceptAISuggestionContract;

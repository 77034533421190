import Resource from 'Resources/Resource';

class ScoreSumResource extends Resource {
	constructor() {
		super('report/score/sum');
	}

	async scoreSumRequest(params: object) {
		const res = await this._getRequest('', { methodName: 'scoreSumRequest', params });
		return res.data;
	}
}


const resource = new ScoreSumResource();

export default resource;

import { Text, Link } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';

ReactTemplates.form.mapContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element,
			searchStr: ''
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		var self = this;
		self.lang = {
			address: t('address.address'),
			enterAddressForMap: t('landingpage.enterAddressForMap'),
			showMapAddress: t('landingpage.showMapAddress'),
			showMapTools: t('landingpage.showMapTools'),
			allowScrollZoom: t('landingpage.allowScrollZoom'),
			openMapsOnClick: t('landingpage.openMapsOnClick'),
			settings: t('admin.settings').toLowerCase(),
			gmapKeyWarning: t('form.gmapKey.warning')
		};
	},
	initAutoComplete: function () {
		var self = this;
		if (!this.autocomplete && this.state.element.value.address === '') {
			var input = self._addressInput;
			var setAddress = function (element, place) {
				element.value.address = place.formatted_address;
				element.value.lat = place.geometry.location.lat();
				element.value.lng = place.geometry.location.lng();
				self.autocomplete = null;
				self.setState({ element: element, searchStr: place.formatted_address });
				self.props.onChange(element);
			};

			loader
				.load()
				.then(() => {
					self.autocomplete = new google.maps.places.Autocomplete(input, {});

					self.autocomplete.addListener('place_changed', function () {
						var place = self.autocomplete.getPlace();
						var element = self.state.element;
						if (place.geometry) {
							setAddress(element, place);
						} else {
							if (self.props.skipSearch.includes(input.value)) {
								// If input should be skipped
								element.value.address = input.value;
								self.props.onChange(element);
								self.autocomplete = null;
								return;
							}
							// Set first suggestion
							var autocompleteService = new google.maps.places.AutocompleteService();
							autocompleteService.getPlacePredictions(
								{ input: input.value },
								function listentoresult(list) {
									if (list || list.length > 0) {
										var placesService = new google.maps.places.PlacesService(input);
										placesService.getDetails(
											{ reference: list[0].reference },
											setAddress.bind(self, element)
										);
									}
								}
							);
							//self.setState({element: element, searchStr: place.formatted_address});
						}
					});
				})
				.catch(e => {
					logError(e, 'Failed to load Google');
				});
		}
	},
	componentDidMount: function () {
		this.initAutoComplete();
	},
	componentDidUpdate: function () {
		this.initAutoComplete();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	clearAddressSearchStr: function () {
		this.setState({ searchStr: '' });
	},
	setSearchStr: function (e) {
		this.setState({ searchStr: e.target.value });
	},
	removeAddress: function () {
		var element = this.state.element;
		element.value.address = '';
		element.value.lat = 0;
		element.value.lng = 0;
		this.setState({ element: element, searchStr: '' });
		this.props.onChange(element);
	},
	valueBoolChanged: function (key) {
		var element = this.state.element;
		element.value[key] = !element.value[key];
		this.setState({ element: element });
		this.props.onChange(element);
	},
	render: function () {
		var self = this;
		var element = self.state.element;

		var addressStyle = { lineHeight: '30px', width: '270px' };
		const form = this.props.form;
		const hasGMAPSoftDeployFeature =
			Tools.FeatureHelper.hasSoftDeployAccess('ALWAYS_USE_GMAP_KEY') ||
			Tools.FeatureHelper.hasSoftDeployAccess('USE_GMAP_KEY_IF_EXIST');
		const selectedDomain = form.domain ? _.find(this.props.verifiedDomains, { name: form.domain }) : null;
		const showGMAPKeyWarning = hasGMAPSoftDeployFeature && selectedDomain && !selectedDomain.gmapKey;

		return (
			<div>
				<div className="tool-main-section">
					{element.value.address ? (
						<>
							<div className="tool-section">
								<label className="tool-label block-label">{this.lang.address}</label>
								<span className="ellipsis" style={addressStyle}>
									{element.value.address}
								</span>
								<button
									type="button"
									className="up-btn btn-sm btn-grey btn-hover-red btn-link pull-right"
									onClick={self.removeAddress}
								>
									<b className="fa fa-trash-o"></b>
								</button>
								<div className="clearfix"></div>
							</div>

							<div className="tool-section">
								<label>{self.lang.showMapAddress}</label>
								<div className="pull-right">
									<ReactTemplates.upFilters.components.toggle
										checked={self.state.element.value.showAddress}
										onChange={self.valueBoolChanged.bind(self, 'showAddress')}
										className="toggle-bright-blue"
									/>
								</div>
							</div>

							<div className="tool-section">
								<label>{self.lang.showMapTools}</label>
								<div className="pull-right">
									<ReactTemplates.upFilters.components.toggle
										checked={self.state.element.value.showTools}
										onChange={self.valueBoolChanged.bind(self, 'showTools')}
										className="toggle-bright-blue"
									/>
								</div>
							</div>

							<div className="tool-section">
								<label>{self.lang.allowScrollZoom}</label>
								<div className="pull-right">
									<ReactTemplates.upFilters.components.toggle
										checked={self.state.element.value.allowScrollZoom}
										onChange={self.valueBoolChanged.bind(self, 'allowScrollZoom')}
										className="toggle-bright-blue"
									/>
								</div>
							</div>
						</>
					) : (
						<div className="tool-section">
							<label className="tool-label block-label">{this.lang.address}</label>
							<input
								autoFocus
								type="text"
								className="form-control"
								value={this.state.searchStr}
								onChange={this.setSearchStr}
								onKeyPress={this.handleKeyPress}
								onBlur={self.clearAddressSearchStr}
								placeholder={self.lang.enterAddressForMap}
								ref={this.setRef.bind(self, '_addressInput')}
							/>
						</div>
					)}
					{showGMAPKeyWarning ? (
						<div className="tool-section">
							<Text size="sm" color="orange">
								{this.lang.gmapKeyWarning}
								<Link
									href={Tools.$state.href('administration.domains', {
										customerId: Tools.AppService.getCustomerId()
									})}
								>
									{this.lang.settings}
								</Link>
							</Text>
						</div>
					) : null}
				</div>
			</div>
		);
	}
});

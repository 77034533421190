'use strict';

angular.module('upDirectives').service('selectHelper', [
	'RequestBuilder',
	'$compile',
	'AppService',
	'FilterType',
	'$parse',
	'$q',
	'FilterHelper',
	'$state',
	function (RequestBuilder, $compile, AppService, FilterType, $parse, $q, FilterHelper, $state) {
		var Helper = {};
		var baseDirectiveObject = function () {
			return {
				restrict: 'A',
				require: 'ngModel',
				priority: 1000,
				replace: true,
				template: function ($element, $attrs) {
					if ($attrs.idModel !== undefined) {
						return '<input ng-model="model" type="hidden"/>';
					}
					return '<input type="hidden"/>';
				}
			};
		};

		var baseSelect2Obj = function (idKey, titleKey, emptyMsg) {
			return {
				minimumInputLength: 0,
				quietMillis: 100,
				allowClear: 1,
				placeholder: ' ',
				initSelection: _.noop,
				formatResult: function (obj, el, x, encode) {
					return encode(emptyCheck(obj[titleKey], emptyMsg));
				},
				formatSelection: function (obj, x, encode) {
					return encode(emptyCheck(obj[titleKey], emptyMsg));
				},
				matcher: function (term, undefined, object) {
					if (!object[titleKey]) {
						return false;
					}
					return object[titleKey].toLowerCase().indexOf(term.toLowerCase()) !== -1;
				},
				id: function (obj) {
					return obj ? obj[idKey] : null;
				}
			};
		};

		var getAjaxRb = function (customFilters, $attrs, idAttr, titleAttr, sorting, fields) {
			var rb = new RequestBuilder();
			if (customFilters) {
				customFilters(rb, $attrs);
			}

			if (sorting) {
				rb.addSort(sorting.field, sorting.ascending);
			}

			rb.fields = [idAttr.field, titleAttr.field];

			if (fields) {
				rb.fields = _.unique(rb.fields.concat(fields));
			}

			return rb;
		};

		var getOption = function (option, $attrs, defaultValue, $scope, isFunction) {
			// Set option to provided (from directive)
			var opt = option;

			// If the option was set and it was a function (and not a isFunction optionpt)
			if (option && typeof option === 'function' && !isFunction) {
				var options = {};
				if ($attrs.options && $scope) {
					options = $parse($attrs.options)($scope);
				}
				opt = option($attrs, $scope, options) || defaultValue;
			}

			// if no option was set we use default value
			if (opt === undefined) {
				opt = defaultValue;
			}

			// return option value now
			return opt;
		};

		// Returns the title or a provided message if the title is empty
		function emptyCheck(title, msg) {
			title = _.trim(title);
			if (!title.length || title === ' ') {
				return msg;
			}
			return title;
		}

		var compiler = function (directiveName, defaultOptions) {
			/*
			Some available options
			- ajax: the options is requested from the api as the user types
			- limit: If combined with ajax the select can be a normal select(no ajax) if there is less than the provided limit of the entity
			- asIds: The model is containing the ids of the selected objects instaid of the object itself
		*/
			defaultOptions = defaultOptions || {};

			return function (element, $attr) {
				var directiveNameDashed = $attr.$attr[directiveName];
				var select2ConfKey = directiveName + 'Select2config';
				var isMultiple = $attr.multiple !== undefined;
				var required = $attr.required !== undefined;
				$attr.$set('ui-select2', select2ConfKey);
				$attr.$set(directiveNameDashed);
				$attr.$set('type', 'hidden');

				if ($attr.ngChange) {
					$attr.$set('ng-change', '$parent.' + $attr.ngChange);
				}

				return {
					post: function (scope, $element, $attrs, ngModel) {
						var options = {};
						var extraOpts = {};

						// If we have som override options from the view we extend the directive options
						if ($attr.options) {
							extraOpts = $parse($attrs.options)(scope) || {};
						}

						angular.extend(options, extraOpts, defaultOptions);

						var isTooMany = false;
						// Is true if the ajax results was more than the limit

						// The number of allowed options before the select becomes an ajax-select (only when ajax option is set). Defaults to 500
						var MAX_LIMIT = getOption(options.limit, $attrs, 500);

						var titleAttr = getOption(options.titleAttr, $attrs, { field: 'name' });
						// Is used to print the title for each option and also the field you search when ajax

						var termsFilter = getOption(options.termsFilter, $attrs, null, scope, true);
						// used to set custom terms filter

						var idAttr;
						// Is used to get objects based on ids

						var emptyMsg = getOption(options.emptyMsg, $attrs, '-'); // defaults to '-';
						// Is printed out if the title value is empty

						var Resource = getOption(options.resource, $attrs);
						// The resource to use whe ncollecting options for the select

						var customFilters;
						// customfilters used when getting the options from the api

						var data;
						// Holds the options for static selects

						var fields = getOption(options.fields, $attrs, null);
						// Fields to get from resource other than {idAttr} and {titleAttr} fields

						var sorting = getOption(options.sorting, $attrs);
						// request sort order

						var resourceType = getOption(options.resourceType, $attrs);
						// if the resource require a type (like user) we set it here

						var getter;
						// The get function for the resource

						var formatResult;
						var matcher;
						var formatSelection;
						// The format functions for select2

						var isAjax;

						var cachedTotal = getOption(options.cachedTotal, $attrs, null);

						var infiniteScroll = getOption(options.infiniteScroll, $attrs, false);

						var formatData = options.formatData || (data => data);

						var referenceData;
						// Holds reference data for selects with children

						var initialDataPromise;
						// ngModel is the select2 model, for asIds selects this is not the same model as the ids are stored in
						// Wait for app to load
						AppService.loadedPromise.then(function () {
							// Is used to get objects based on ids
							idAttr = getOption(options.idAttr, $attr, { field: 'id' }, scope); // defaults to id;

							// If this is a static select or nah
							isAjax = getOption(options.ajax, $attr, false, scope);

							// Set the static data array
							data = getOption(options.data, $attrs, [], scope);

							var customerId = AppService.getCustomerId();

							if (isAjax) {
								if (options.getter) {
									getter = options.getter;
								} else {
									getter = function (customerId, filter) {
										var internalGetter;
										if (resourceType) {
											if (Resource.customer) {
												internalGetter = Resource.customer(customerId).setType(resourceType);
											} else {
												internalGetter = Resource.setType(resourceType);
											}
										} else {
											if (Resource.customer) {
												internalGetter = Resource.customer(customerId);
											} else {
												internalGetter = Resource;
											}
										}

										return internalGetter.find(filter);
									};

									if (options.filters && typeof options.filters === 'function') {
										customFilters = options.filters;
									}
								}
							} else {
								if (options.getter) {
									getter = options.getter;
								} else {
									getter = function () {
										if (typeof data === 'object' && data.referenceData) {
											referenceData = data.referenceData;
											return $q.when({ data: data.data });
										}
										return $q.when({ data: data });
									};
								}
							}

							// If the model will be ids
							if (options.asIds) {
								// Setup the default state of the model, multiselect or not

								var findInData;
								if (options.findInData) {
									findInData = options.findInData;
								} else {
									findInData = function (findObj) {
										if (referenceData) {
											return _.find(referenceData, findObj);
										}
										return _.find(data, findObj);
									};
								}

								// Sets the select2 model based on id(s)
								var setModelByIds = function (value) {
									if (value) {
										// Parse the id(s)
										if (idAttr.type === FilterType.Number) {
											if (isMultiple && Array.isArray(value)) {
												value = _.map(value, function (val) {
													return parseInt(val);
												});
											} else {
												value = parseInt(value);
											}
										}
										if (isAjax) {
											// Setup filter and get the objects
											if ((isMultiple && Array.isArray(value) && value.length) || !isMultiple) {
												var filter = new RequestBuilder();
												var comparisonType = options.comparisonType
													? options.comparisonType
													: filter.comparisonTypes.Equals;

												filter.addFilter(idAttr, comparisonType, value);

												if (customFilters) {
													customFilters(filter, $attrs);
												}

												getter(customerId, filter.build(), null, scope, $attrs).then(function (
													res
												) {
													// Apply to the select2 model
													if (isMultiple) {
														ngModel.$setViewValue(res.data);
													} else {
														ngModel.$setViewValue(res.data[0]);
													}
												});
											} else {
												if (isMultiple) {
													ngModel.$setViewValue([]);
												} else {
													ngModel.$setViewValue(null);
												}
											}
										} else {
											initialDataPromise.then(function () {
												// Filter out all results based on the id
												var findObj = {};
												if (isMultiple) {
													var objects = [];
													_.each(value, function (id) {
														findObj[idAttr.field] = id;
														var found = findInData(findObj, $attrs);
														if (found) {
															objects.push(found);
														}
													});
													ngModel.$setViewValue(objects);
												} else {
													findObj[idAttr.field] = value;
													var found = findInData(findObj, $attrs);
													ngModel.$setViewValue(found);
												}
											});
										}
									} else {
										// Empty value
										if (isMultiple) {
											ngModel.$setViewValue([]);
										} else {
											ngModel.$setViewValue(null);
										}
									}
								};

								// Watch the select2 model and set the idModel when changed
								scope.$watch(
									'model',
									function (value, oldValue) {
										if (value !== undefined) {
											var changed = false;
											// Multi select
											if (isMultiple) {
												var newValue = _.pluck(value || [], idAttr.field);
												changed = JSON.stringify(newValue) !== JSON.stringify(scope.idModel);
												scope.idModel = newValue;
											} else if (value && typeof value === 'object' && value[idAttr.field]) {
												// Single select
												changed = scope.idModel !== value[idAttr.field];
												scope.idModel = value[idAttr.field];
											} else {
												changed = !!scope.idModel;
												scope.idModel = null; // Unset state for single select
											}
											if (
												scope.onChange &&
												typeof scope.onChange === 'function' &&
												changed &&
												oldValue !== undefined
											) {
												scope.onChange(value);
											}
										}
									},
									true
								);

								// Watch the id model for changes and set the select2 model (sets the initial value too)
								scope.$watch(
									'idModel',
									function (value) {
										if ((isMultiple && value) || (!isMultiple && value && value !== scope.model)) {
											setModelByIds(value);
										}
									},
									true
								);
							}

							// The select2 config object
							var baseObj = baseSelect2Obj(idAttr.field, titleAttr.field, emptyMsg);
							scope[select2ConfKey] = _.merge(baseObj, options.select2 || {});

							// Set allow clear if field is not required
							scope[select2ConfKey].allowClear = !required ? 1 : 0;

							// Function that return the function
							if (options.formatResultFn) {
								formatResult = getOption(options.formatResultFn, $attr, null, scope, false);
							} else {
								formatResult = getOption(options.formatResult, $attr, null, scope, true);
							}
							if (options.matcherFn) {
								matcher = getOption(options.matcherFn, $attr, null, scope, false);
							} else {
								matcher = getOption(options.matcher, $attr, null, scope, true);
							}
							if (options.formatSelectionFn) {
								formatSelection = getOption(options.formatSelectionFn, $attr, null, scope, false);
							} else {
								formatSelection = getOption(options.formatSelection, $attr, null, scope, true);
							}

							if (formatResult) {
								scope[select2ConfKey].formatResult = formatResult;
							}
							if (matcher) {
								scope[select2ConfKey].matcher = matcher;
							}
							if (formatSelection) {
								scope[select2ConfKey].formatSelection = formatSelection;
							}

							// Link it
							if (options.linked && options.goTo) {
								var oldFormatSelection = scope[select2ConfKey].formatSelection;
								scope[select2ConfKey].formatSelection = Helper.wrapFormatSelectionLink(
									oldFormatSelection,
									options.goTo
								);
							}

							// Custom search choice
							if (options.createSearchChoice) {
								scope[select2ConfKey].createSearchChoice = options.createSearchChoice;
								scope[select2ConfKey].createSearchChoicePosition = function (list, item) {
									list.push(item);
									data.push(item);
									if (referenceData) {
										referenceData.push(item);
									}
								};
							}

							// Setup add event listener if we have one
							if (options.addEvent) {
								scope.$on(options.addEvent, function (e, added) {
									// If the list is static we might wanna push the new item
									// If not the item will show up when user types to search
									if (!isAjax || !isTooMany) {
										var rb = getAjaxRb(customFilters, $attrs, idAttr, titleAttr, sorting, fields);
										if (FilterHelper.match(rb.build().q, added, options.filterType)) {
											scope[select2ConfKey].data.push(added);
										}
									}
								});
							}

							var setSelect2Ajax = function (total) {
								if (total > MAX_LIMIT) {
									if (
										(!data || !data.length) &&
										(!options.select2 || options.select2.minimumInputLength === undefined)
									) {
										scope[select2ConfKey].minimumInputLength = 1;
									}
									scope[select2ConfKey].matcher = undefined;
									scope[select2ConfKey].ajax = {
										data: function (term, page) {
											return {
												term: term,
												page: page
											};
										},
										transport: function (query) {
											if (query.data.term !== undefined) {
												// If we have initial data to show when no searchString is set
												if (query.data.term === '' && data && data.length) {
													return query.success({ data: data });
												}

												var filter = getAjaxRb(
													customFilters,
													$attrs,
													idAttr,
													titleAttr,
													sorting,
													fields
												);
												if (termsFilter) {
													termsFilter(filter, query.data.term);
												} else {
													filter.addFilter(
														titleAttr,
														filter.comparisonTypes.Search,
														query.data.term
													);
												}
												filter.limit = 50; // LINE-1489

												if (infiniteScroll) {
													filter.offset = (query.data.page - 1) * filter.limit;
												}

												//return getter(customerId).find(filter.build()).then(query.success);
												return getter(
													customerId,
													filter.build(),
													query.data.term,
													scope,
													$attrs
												).then(({ data, ...rest }) =>
													query.success({ data: formatData(data), ...rest })
												);
											}
											return query.success({
												data: []
											});
										},
										results: function (res) {
											var more =
												infiniteScroll &&
												res.metadata.total > res.metadata.offset + res.metadata.limit;

											return { results: res.data, more: more };
										}
									};

									isTooMany = true;

									// Compile the element
									$compile($element)(scope);
								} else {
									var rb = getAjaxRb(customFilters, $attrs, idAttr, titleAttr, sorting, fields);
									rb.limit = MAX_LIMIT;
									return getter(customerId, rb.build(), null, scope, $attrs).then(function (res) {
										scope[select2ConfKey].data = formatData(res.data);

										// Compile the element
										$compile($element)(scope);
									});
								}
							};

							// If this is an ajax
							if (isAjax) {
								if (cachedTotal !== null) {
									setSelect2Ajax(cachedTotal);
									return;
								}

								// Make filters and get total data so we know if there is more objects than the limit
								var rb = getAjaxRb(customFilters, $attrs, idAttr, titleAttr, sorting, fields);
								rb.limit = 0;

								initialDataPromise = getter(customerId, rb.build(), null, scope, $attrs).then(function (
									res
								) {
									// If there is more than the limit we make this select an ajax
									setSelect2Ajax(res.metadata.total);
								});
							} else {
								// This select is static so we use the provided options
								initialDataPromise = getter(customerId, {}, null, scope, $attrs).then(function (res) {
									data = res.data;
									scope[select2ConfKey].data = data;

									// Compile the element
									$compile($element)(scope);
								});
							}
						}); // End of AppService.loadedPromise
					}
				};
			};
		};

		Helper.getDirectiveObject = function (directiveName, options) {
			var obj = baseDirectiveObject();
			obj.compile = compiler(directiveName, options);
			obj.scope = true;
			return obj;
		};

		Helper.getDirectiveObjectIds = function (directiveName, options) {
			var obj = baseDirectiveObject();
			obj.compile = compiler(directiveName, options);
			obj.scope = {
				idModel: '=',
				options: '=',
				onChange: '='
			};
			return obj;
		};

		Helper.wrapFormatSelectionLink = function (formatSelection, goToFn) {
			// Link it
			if (formatSelection && angular.isFunction(formatSelection) && goToFn && angular.isFunction(goToFn)) {
				return function (obj, elem, encode) {
					var a = angular.element('<a href=""/>');
					a.addClass('select2-inner-link');
					a.attr('href', 'javascript:void(0)');
					a.html(formatSelection(obj, elem, encode));

					elem.off('mousedown', '.select2-inner-link');
					elem.off('click', '.select2-inner-link');

					elem.on('mousedown', '.select2-inner-link', function (e) {
						e.stopPropagation();
						goToFn(obj, $state);
					}).on('click', '.select2-inner-link', function (e) {
						e.stopPropagation();
						e.preventDefault();
					});

					return a;
				};
			} else {
				// Return original selection
				return formatSelection;
			}
		};

		return Helper;
	}
]);

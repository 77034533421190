import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import { Button, Icon } from '@upsales/components';

ReactTemplates.ROWSTATICS.mailRow = function (tools) {
	var statics = {};
	statics.editTool = tools.$translate('default.edit');
	statics.deleteTool = tools.$translate('default.delete');
	statics.noAccountManager = tools.$translate('default.noAccountManager');
	statics.noCampaigns = tools.$translate('default.noCampaigns');
	statics.noCategories = tools.$translate('default.noCategories');
	statics.noName = tools.$translate('default.noName');
	statics.addExternal = tools.$translate('default.addExternal');

	return statics;
};

ReactTemplates.ROW.mailRow = window.ReactCreateClass({
	displayName: 'mailRow',
	click: function () {
		this.props.tools.opts.clickedEntry(this.props.item);
	},
	edit: function () {
		this.props.tools.opts.editEntry(this.props.item);
	},
	saveExternal: function (item) {
		this.props.tools.opts.saveExternal(item || this.props.item);
	},
	refreshSoliditet: function (e) {
		if (e) {
			ReactTemplates.TOOLS.stopProp(e);
		}
		this.props.tools.opts.refreshSoliditet(this.props.item);
	},
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var columnTools = this.props.tools.opts.columnTools || {};

		var getColumn = function (column, item) {
			var key = 'column-' + column;
			switch (column) {
				case 'contact':
					return (
						<td key={key}>
							<span className="lead-contact">
								<ReactTemplates.TOOLS.contactHref
									customerId={customerId}
									id={item.contact ? item.contact.id : '0'}
									text={item.contact ? item.contact.name : item.to}
									tools={tools}
									style={{ display: 'block' }}
								/>
								<span
									className="lead-contact-account"
									style={{
										marginTop: '-2px',
										display: 'block'
									}}
								>
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.client ? item.client.id : '0'}
										text={item.client ? item.client.name : '-'}
										tools={tools}
									/>
								</span>
							</span>
						</td>
					);

				case 'type':
					var ret;
					switch (item.type) {
						case 'out':
							ret = (
								<td key={key}>
									<i
										className="Icon Icon-paper-plane"
										style={{ color: '#4A90E2', paddingRight: '15px' }}
									></i>
									<span>{tools.$translate('mail.status.SENT')}</span>
								</td>
							);
							break;

						case 'in':
							ret = (
								<td key={key}>
									<i
										className="Icon Icon-envelope-sync"
										style={{ color: '#4A90E2', paddingRight: '15px' }}
									></i>
									<span>{tools.$translate('mail.status.RECEIVED')}</span>
								</td>
							);
							break;
						case 'sch':
							ret = (
								<td key={key}>
									<i
										className="fa fa-calendar"
										style={{ color: '#4A90E2', paddingRight: '15px' }}
									></i>
									<span>{tools.$translate('mail.status.SCHEDULED')}</span>
								</td>
							);
							break;

						case 'pro':
							ret = (
								<td key={key} style={{ color: '#999' }}>
									<i className="fa fa-refresh" style={{ paddingRight: '15px' }}></i>
									<span>{tools.$translate('mail.status.PROCESSING')}</span>
								</td>
							);
							break;

						case 'cre':
						case 'pay':
						case 'err':
							var errorMessage = tools.$translate('mail.status.ERROR_UNKNOWN');

							if (item.type === 'cre') {
								errorMessage = tools.$translate('mail.status.MISSING_CREDITS');
							}
							if (item.type === 'pay') {
								errorMessage = tools.$translate('mail.status.MISSING_PAYMENT');
							}
							if (item.errorCause) {
								errorMessage = tools.$translate('event.' + item.errorCause);
							}

							errorMessage = errorMessage.toUpperCase();
							var tooltip = false;

							if (item.errorCause === 'soft_bounce') {
								tooltip = tools.$translate('mail.softBounce.description');
							}
							if (item.errorCause === 'hard_bounce') {
								tooltip = tools.$translate('mail.hardBounce.description');
							}

							var errorDescription = (
								<span style={{ display: 'block', color: '#DB2023', fontSize: '11px' }}>
									{errorMessage}
								</span>
							);
							if (tooltip) {
								errorDescription = ReactTemplates.TOOLS.withTooltip(
									<span style={{ display: 'block', color: '#DB2023', fontSize: '11px' }}>
										{errorMessage}
									</span>,
									tooltip,
									{ placement: 'bottom', delayShow: 1000 }
								);
							}

							ret = (
								<td key={key} style={{ width: '170px' }}>
									<i
										className="fa fa-exclamation-triangle"
										style={{ color: '#DB2023', padding: '11px 15px 0 0', float: 'left' }}
									></i>
									<span>
										{tools.$translate('mail.status.ERROR')}
										{errorDescription}
									</span>
								</td>
							);
							break;
					}

					return ret;

				case 'events':
					var maStatus = React.createElement(ReactTemplates.TOOLS.mailStats, {
						read: item.lastReadDate || item.lastClickDate,
						clicked: item.lastClickDate,
						hideSubmit: true,
						tools: tools
					});

					return <td key={key}>{item.type === 'out' ? maStatus : ''}</td>;

				case 'date':
					return (
						<td key={key} className="column-startDate">
							{item.date && ['in', 'out', 'err', 'sch'].includes(item.type)
								? ReactTemplates.TOOLS.dateCalendar(item.date, 'L')
								: ''}
						</td>
					);

				case 'subject':
					return (
						<td key={key} className="column-subject overflow-controller">
							{item.subject}
						</td>
					);

				case 'lastEventDate':
					if (item.lastEventDate === null) {
						return (
							<td key={key} className="column-lastEventDate">
								{'-'}
							</td>
						);
					}

					return (
						<td key={key} className="column-lastEventDate">
							{moment(item.lastEventDate).format('L')}
						</td>
					);

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep = item.contact ? item.contact.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		if (!columnTools.extraColumn1) {
			columnTools.extraColumn1 = {
				show: function () {
					return false;
				}
			};
		}

		// TODO Only show if userRemovable, show tooltip
		return (
			<tr key={'item-' + item.id} onClick={this.click} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className="column-tools">
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={
							!item.isExternal &&
							item.userEditable &&
							columnTools.edit !== false &&
							['out', 'pro', 'in'].indexOf(item.type) === -1
						}
						icon="edit"
						tooltip={statics.editTool}
						onClick={this.edit}
					/>
					<InlineConfirm
						show={!item.isExternal && item.userRemovable && columnTools.remove !== false}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.mail'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

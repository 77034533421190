'use strict';

var segmentSelectDir = 'upSegments';
angular
	.module('upDirectives')
	.directive(segmentSelectDir, function (Segment, selectHelper) {
		return selectHelper.getDirectiveObject(segmentSelectDir, {
			resource: Segment,
			ajax: true,
			asIds: false,
			idAttr: Segment.attr.id,
			titleAttr: Segment.attr.name,
			addEvent: 'segment.added',
			fields: ['id', 'name', 'filter'],
			sorting: { field: Segment.attr.name, ascending: true },
			goTo: function (obj, $state) {
				return $state.go('segment.events', { id: obj.id }, { reload: true });
			},
			filters: function (rb, $attrs) {
				if ($attrs.showInactive === undefined) {
					rb.addFilter(Segment.attr.active, rb.comparisonTypes.Equals, 1);
				}
			}
		});
	})
	.directive(segmentSelectDir + 'Ids', function (Segment, selectHelper) {
		return selectHelper.getDirectiveObjectIds(segmentSelectDir + 'Ids', {
			resource: Segment,
			ajax: true,
			asIds: true,
			idAttr: Segment.attr.id,
			titleAttr: Segment.attr.name,
			addEvent: 'segment.added',
			fields: ['id', 'name', 'filter'],
			sorting: { field: Segment.attr.name, ascending: true },
			goTo: function (obj, $state) {
				return $state.go('segment.events', { id: obj.id });
			},
			filters: function (rb, $attrs) {
				if ($attrs.showInactive === undefined) {
					rb.addFilter(Segment.attr.active, rb.comparisonTypes.Equals, 1);
				}
			}
		});
	});

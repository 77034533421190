import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

export default function () {
	const { accountSelf } = useSelector(({ App }: RootState) => App);
	if (accountSelf?.features.pipeline) {
		return true;
	}
	return false;
}

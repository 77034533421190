import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, ButtonSelect, DropDownMenu } from '@upsales/components';
import FolderTreeBranch from './FolderTreeBranch';
import { FolderTreeType } from '../AccountFiles';
import AddFolderModal from '../FolderModal';
import SearchInput from '../SearchInput';
import FolderDropdownOption from './FolderDropdownOption/FolderDropdownOption';

import './Header.scss';

type Props = {
	folders: FolderTreeType[];
	changeFolder: (folderId?: number | null) => void;
	addFolder: (name: string) => void;
	changeNameOfFolder: (id: number, name: string) => void;
	setListMode: (listMode: boolean) => void;
	listMode: boolean;
	addFile: () => void;
	setSearchString: (text: string | null) => void;
	searchString: string | null;
	changeFolderFolder: (id: number, folderId: number | null) => void;
	changeFileFolder: (id: number, folderId: number | null) => void;
};

const Header = ({
	folders,
	changeFolder,
	addFolder,
	changeNameOfFolder,
	setListMode,
	listMode,
	addFile,
	setSearchString,
	searchString,
	changeFolderFolder,
	changeFileFolder
}: Props) => {
	const classes = new BemClass('Header');

	let foldersToShow = folders;
	let foldersDropDown: FolderTreeType[] = [];
	if (folders.length >= 4) {
		foldersToShow = folders.slice(-2);
		foldersDropDown = folders.slice(0, folders.length - 2);
	}

	return (
		<div className={classes.b()}>
			<div className={classes.elem('folder-tree').b()}>
				<FolderTreeBranch
					changeNameOfFolder={changeNameOfFolder}
					addFolder={addFolder}
					folder={null}
					changeFolder={changeFolder}
					lastInTree={!folders.length}
					addFile={addFile}
					setSearchString={setSearchString}
					searchString={searchString}
					changeFolderFolder={changeFolderFolder}
					changeFileFolder={changeFileFolder}
				/>
				{foldersDropDown.length ? (
					<DropDownMenu
						align="left"
						useAnimation
						noMinWidth
						renderTrigger={(expanded, setExpanded) => (
							<>
								<Icon name="arrow-right" />
								<Icon
									name="ellipsis-h"
									onClick={setExpanded}
									onDragEnter={setExpanded}
									className={classes.elem('folders-dropdown-button').b()}
								/>
							</>
						)}
					>
						<div className={classes.elem('dropdown').b()}>
							{foldersDropDown.map(folder => (
								<FolderDropdownOption
									key={folder.id}
									folder={folder}
									changeFolder={changeFolder}
									changeFolderFolder={changeFolderFolder}
									changeFileFolder={changeFileFolder}
								/>
							))}
						</div>
					</DropDownMenu>
				) : null}
				{foldersToShow.map(folder => (
					<React.Fragment key={folder.id}>
						<Icon name="arrow-right" />
						<FolderTreeBranch
							changeNameOfFolder={changeNameOfFolder}
							addFolder={addFolder}
							folder={folder}
							changeFolder={changeFolder}
							lastInTree={folder.id === folders[folders.length - 1].id}
							addFile={addFile}
							changeFolderFolder={changeFolderFolder}
							changeFileFolder={changeFileFolder}
						/>
					</React.Fragment>
				))}
			</div>
			<div className={classes.elem('right-container').b()}>
				<SearchInput
					changeFolder={changeFolder}
					setSearchString={setSearchString}
					searchString={searchString}
				/>
				<Icon onClick={addFile} className={classes.elem('add-icon').b()} name="file" />

				<AddFolderModal
					onClick={name => {
						addFolder(name);
					}}
					noDropDown={false}
				/>

				<ButtonSelect
					onChange={value => {
						setListMode(value === 2);
						localStorage.setItem('boxViewDocuments', value.toString());
					}}
					options={[
						{
							icon: 'th-large',
							value: 1
						},
						{
							icon: 'th-list',
							value: 2
						}
					]}
					value={listMode ? 2 : 1}
				/>
			</div>
		</div>
	);
};

export default Header;

'use strict';

window.ValidDomainController = function (scope, translate, BusinessEmail, DEFAULT_INVALID_DOMAINS) {
	var rootNode;

	var saveFn = function () {
		props.saving = true;
		render();
		BusinessEmail.save({ value: props.domains }).then(function () {
			scope.resolve();
		});
	};

	var onChange = function (domains) {
		props.domains = domains;
		render();
	};

	var props = {
		loading: false,
		saving: false,
		hasError: false,
		error: '',
		closeModal: function () {
			scope.resolve();
		},
		save: saveFn,
		onChange: onChange,
		domains: [],
		tools: {
			$translate: translate
		},
		addDomain: function (data) {
			var dom = _.find(props.domains, function (dd) {
				if (dd.topLevel === null) {
					return dd.name.toLowerCase() === data.name.toLowerCase() && dd.topLevel === data.topLevel;
				} else if (data.topLevel === null) {
					return dd.name.toLowerCase() === data.name.toLowerCase() && dd.topLevel === data.topLevel;
				} else {
					return (
						dd.name.toLowerCase() === data.name.toLowerCase() &&
						dd.topLevel.toLowerCase() === data.topLevel.toLowerCase()
					);
				}
			});

			if (!dom) {
				props.domains.unshift(data);

				props.saving = true;
				render();

				BusinessEmail.save({ value: props.domains }).then(function () {
					props.saving = false;
					render();
				});

				scope.reloadModalPosition();
			} else {
				props.hasError = true;
				props.error = window.Tools.$translate('form.validDomain.couldntAdd');
				render();

				setTimeout(function () {
					props.hasError = false;
					props.error = '';
					render();
				}, 6000);
			}
		},
		deleteRow: function (domain) {
			var domIndex = _.findIndex(props.domains, function (dd) {
				return dd.name === domain.name && dd.topLevel === domain.topLevel;
			});

			if (domIndex !== -1) {
				props.domains.splice(domIndex, 1);

				props.saving = true;
				render();

				BusinessEmail.save({ value: props.domains }).then(function () {
					props.saving = false;
					render();
				});
			}
		}
	};

	var init = function (_e, data) {
		rootNode = data.element[0];
		props.loading = true;
		render();
		BusinessEmail.get().then(function (res) {
			var mappedDomains = [];

			mappedDomains = _.map(res.data.value, function (domain) {
				if (!domain.hasOwnProperty('topLevel')) {
					domain.topLevel = null;
				}

				return domain;
			});

			props.domains = mappedDomains.length ? mappedDomains : DEFAULT_INVALID_DOMAINS;
			props.loading = false;
			render();
		});
	};

	var render = function () {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(
			React.createElement(ReactTemplates.form.validDomainModal, Object.assign({}, props)),
			rootNode,
			function () {}
		);
	};

	const unsubscribe = scope.$root.$on('modal.ready', init);

	scope.$on('$destroy', function () {
		unsubscribe();
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});
};

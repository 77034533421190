'use strict';

angular.module('upResources').factory('BannerGroup', [
	'$resource',
	'$q',
	'URL',
	'API',
	'BannerGroupAttributes',
	'ParseGeneric',
	'ResourceHelper',
	'MapGeneric',
	function BannerGroup(
		$resource,
		$q,
		URL,
		API,
		BannerGroupAttributes,
		ParseGeneric,
		ResourceHelper,
		MapGeneric
	) {
		var Attributes = BannerGroupAttributes().attr;
		var Keys = BannerGroupAttributes().keys;

		var helper = new ResourceHelper();
		var validateSaveErr = function (err) {
			if (err && err.data && err.data.error) {
				if (err.data.error === 'landingPage_unreachable') {
					return 'saveError.bannerGroupLandingPage';
				}
			}

			return 'saveError.bannerGroup';
		};

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'bannerGroup',
			createSuccessBody: 'saved.bannerGroup',
			updateSuccessBody: 'updated.bannerGroup',
			deleteSucccessBody: 'deleted.bannerGroup',
			createErrorBody: validateSaveErr,
			updateErrorBody: validateSaveErr,
			deleteErrorBody: 'deleteError.bannerGroup'
		});

		var Resource = $resource(
			URL + API + 'bannerGroups/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseBannerGroup', { isArray: false, custom: false })
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseBannerGroup', { isArray: true, custom: false })
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseBannerGroup', { isArray: false, custom: false }),
					transformRequest: MapGeneric(null, {
						payloadName: 'bannerGroup'
					})
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseBannerGroup', { isArray: false, custom: false }),
					transformRequest: MapGeneric(null, {
						payloadName: 'bannerGroup'
					})
				}
			}
		);

		var Model = {
			new: function () {
				var data = {
					title: '',
					landingPage: '',
					pages: [],
					formats: [],
					availableFormats: []
				};

				var parsed = ParseGeneric('ParseBannerGroup', { isArray: false, custom: false, isNew: true })(
					{ data: data },
					null
				);

				return parsed.data;
			},
			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (bannerGroup, options) {
				var defered = $q.defer();

				if (!bannerGroup) {
					return defered.reject('No bannerGroup');
				}

				if (bannerGroup.id) {
					Resource.update(
						{ id: bannerGroup.id },
						{ bannerGroup: bannerGroup },
						defered.resolve,
						defered.reject
					);
				} else {
					Resource.create({ bannerGroup: bannerGroup }, defered.resolve, defered.reject);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !bannerGroup.id);
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, !bannerGroup.id, err);
					});

				return defered.promise;
			},

			delete: function (bannerGroup, options) {
				return Resource.delete({ id: bannerGroup.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, bannerGroup);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			attr: Attributes,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upDirectives').directive('upChangeValid', [
	'$safeApply',
	function ($safeApply) {
		return {
			restrict: 'A',
			require: 'ngModel',
			scope: {
				upChangeValid: '&',
				required: '='
			},
			link: function (scope, element, attrs, ngModel) {
				var runChange = function () {
					scope.upChangeValid();
				};

				if (!attrs.regexp) {
					return;
				}

				var regexp = new RegExp(attrs.regexp);

				// watch change of view value
				scope.$watch(
					function () {
						return ngModel.$viewValue;
					},
					function (newValue) {
						if (!newValue && !scope.required) {
							runChange();
						} else if (regexp.test(newValue)) {
							runChange();
						}
					}
				);

				// when click outside...
				element.on('blur', function () {
					runChange();
					$safeApply(scope);
				});
			}
		};
	}
]);

import './AgreementCustomPeriodLength.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { NumberInput, Text, Block, Button, Icon } from '@upsales/components';

import T from 'Components/Helpers/translate';

type Props = {
	periodLength?: number;
	onChange?: (periodLength?: number) => void;
	onClose?: () => void;
	disabled?: boolean;
};

const AgreementCustomPeriodLength = ({ periodLength, onChange, onClose, disabled }: Props) => {
	const classes = new BemClass('AgreementCustomPeriodLength');

	return (
		<div className={classes.b()}>
			<Block className={classes.elem('periodLengthInput').b()} space="mrm">
				<NumberInput
					min={1}
					max={99}
					align="right"
					space="mrm"
					disabled={disabled}
					onChange={onChange}
					value={periodLength}
				/>
				<Text>{T('date.months')}</Text>
			</Block>
			{!disabled ? (
				<Block>
					<Button type="link" className={classes.elem('closeButton').b()} onClick={onClose}>
						<Icon name="times" color="grey-11" />
					</Button>
				</Block>
			) : null}
		</div>
	);
};

export default AgreementCustomPeriodLength;

'use strict';

angular.module('upHelpers').factory('DisplayType', function () {
	return {
		String: 'string',
		Number: 'number',
		RefObject: 'refObj',
		Date: 'date',
		Currency: 'currency',
		None: 'none'
	};
});

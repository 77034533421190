import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class CKEditor extends React.Component {
	constructor(props) {
		super(props);

		//Bindings
		this.onLoad = this.onLoad.bind(this);

		//State initialization
		this.state = {
			isScriptLoaded: props.isScriptLoaded
		};
	}

	componentDidMount() {
		this.onLoad();
	}

	UNSAFE_componentWillReceiveProps(props) {
		const editor = this.editorInstance;
		if (editor && editor.getData() !== props.content) {
			editor.setData(props.content);
		}
	}

	componentWillUnmount() {
		this.unmounting = true;
		this.editorInstance.destroy();
	}

	onLoad() {
		if (this.unmounting) return;

		this.setState({
			isScriptLoaded: true
		});

		if (!window.CKEDITOR) {
			console.error('CKEditor not found');
			return;
		}

		this.editorInstance = window.CKEDITOR.appendTo(
			ReactDOM.findDOMNode(this),
			this.props.config,
			this.props.content
		);

		//Register listener for custom events if any
		for (var event in this.props.events) {
			var eventHandler = this.props.events[event];

			this.editorInstance.on(event, eventHandler);
		}
	}

	render() {
		return <div className={this.props.activeClass} />;
	}
}

CKEditor.defaultProps = {
	content: '',
	config: {},
	isScriptLoaded: false,
	activeClass: '',
	events: {}
};

CKEditor.propTypes = {
	content: PropTypes.any,
	config: PropTypes.object,
	isScriptLoaded: PropTypes.bool,
	scriptUrl: PropTypes.string,
	activeClass: PropTypes.string,
	events: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column, Link } from '@upsales/components';

import t from 'Components/Helpers/translate';
import DrawerInfoTitle from '../Components/DrawerInfoTitle';
import DrawerInfoProgress from '../Components/DrawerInfoProgress';
import DrawerInfoSnooze from '../Components/DrawerInfoSnooze';
import StepContactTable from '../Components/StepContactTable';
import StepEmailSubject from '../Components/StepEmailSubject';
import StepDrawer from '../StepDrawer';

const COLOR = 'medium-turcose';

export default function ReadMail(props) {
	const stats = props.popup.step.stats;
	const getStatsPercentage = num =>
		stats.hasCompletedStepUnique ? Math.min(Math.floor((num / stats.hasCompletedStepUnique) * 100), 100) : 0;
	const statsArray = [
		{
			color: COLOR,
			num: stats.readMailInTime,
			format: num => `${getStatsPercentage(num)}% ${t('flow.opened')}`
		},
		{
			color: null,
			num: stats.notReadMailInTime,
			format: num => `${getStatsPercentage(num)}% ${t('flow.openedNot')}`
		}
	];

	return (
		<StepDrawer
			{...props}
			title={t('flow.behavior')}
			subtitle={t('mailCampaign.openedMail')}
			color={COLOR}
			icon="eye"
		>
			<Card space="ptl prl pbxl pll" className="StepDrawer__content">
				<StepEmailSubject title={props.template ? props.template.name : t('mail.mailings')} />
				<Row>
					<Column fixedWidth={90}>
						<DrawerInfoProgress total={stats.hasCompletedStepUnique} statsArray={statsArray} />
						{props.popup.step.mailCampaignId ? (
							<Link
								href={props.getHref('mailCampaign.dashboard', { id: props.popup.step.mailCampaignId })}
							>
								{t('flow.showMailCampaign')}
							</Link>
						) : null}
					</Column>
					<Column>
						<DrawerInfoTitle
							total={stats.hasCompletedStepUnique}
							title={'flow.contactsPassedThisStep'}
							statsArray={statsArray}
						/>
					</Column>
				</Row>
				<DrawerInfoSnooze step={props.popup.step} />
			</Card>
			<StepContactTable
				flowId={props.flow.id}
				stepId={props.popup.step.id}
				segmentId={props.flow.segmentId}
				stats={stats}
				types={['currentlyInStep', 'fallenOutOfStep', 'readMailInTime', 'notReadMailInTime']}
				flow={props.flow}
			/>
		</StepDrawer>
	);
}

ReadMail.propTypes = {
	getHref: PropTypes.func.isRequired,
	popup: PropTypes.object.isRequired,
	flow: PropTypes.object.isRequired,
	template: PropTypes.object
};

import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import { Text, Paginator } from '@upsales/components';
import PropTypes from 'prop-types';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { makeIterator } from '../shared';
import _ from 'lodash';
import translate from 'Components/Helpers/translate';

import './Footer.scss';

export const getUserCurrency = () => {
	const AppService = getAngularModule('AppService');
	const userRole = AppService.getSelf().role;
	const currencies = AppService.getMetadata().customerCurrencies;

	if (userRole) {
		const roles = AppService.getRoles();
		const fullUserRole = _.find(roles, { id: userRole.id });
		if (fullUserRole && fullUserRole.defaultCurrency) {
			const roleCurrency = _.find(currencies, { iso: fullUserRole.defaultCurrency });
			if (roleCurrency) {
				return roleCurrency;
			}
		}
	}
	return _.find(currencies, { masterCurrency: true }) || { iso: 'SEK', rate: 1 };
};

export default class Footer extends React.Component {
	static propTypes = {
		startDate: PropTypes.object.isRequired,
		endDate: PropTypes.object.isRequired,
		total: PropTypes.number.isRequired,
		limit: PropTypes.number.isRequired,
		offset: PropTypes.number.isRequired,
		changePage: PropTypes.func.isRequired,
		monthStats: PropTypes.object.isRequired,
		periodizationField: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.language = {
			totalPerMonth: translate('periodization.totalPerMonth')
		};
		this.currency = getUserCurrency();
	}

	render() {
		const { startDate, endDate, monthStats, total, limit, offset, changePage, periodizationField } = this.props;
		const monthIterator = makeIterator(startDate, endDate);

		const columns = Array.from(monthIterator).map((momentObj, index, array) => {
			const columnClasses = new BEM('PeriodizationListContentColumn');
			const key = momentObj.format('YYYY-MM');
			const value = monthStats[periodizationField][key] || 0;
			const textProps = value ? {} : { italic: true, color: 'grey-6' };
			const showBorderLeft = index > 0 && array[index - 1].year() !== momentObj.year() ? true : false;
			const valueInRoleCurrency = value * this.currency.rate;

			return (
				<div key={key} className={columnClasses.mod({ 'border-left': showBorderLeft }).b()}>
					<Text {...textProps}>{numberFormat(valueInRoleCurrency)}</Text>
				</div>
			);
		});

		const footerClasses = new BEM('PeriodizationListFooter');
		const showPaginator = total > limit;
		const classes = {
			lc: footerClasses.elem('column').mod('left').b(),
			rc: footerClasses.elem('column').mod('right').b(),
			row: footerClasses.elem('row').b(),
			srow: footerClasses.elem('row').mod('summary').b()
		};

		const leftColumn = [];
		const rightColumn = [];

		leftColumn.push(
			<div key="left-column-summary" className={classes.row}>
				<Text>{`${this.language.totalPerMonth} (${this.currency.iso})`}</Text>
			</div>
		);
		rightColumn.push(
			<div key="right-column-summary" className={classes.srow}>
				{columns}
			</div>
		);

		if (showPaginator) {
			leftColumn.push(<div key="left-column-paginator" className={classes.row}></div>);
			rightColumn.push(
				<div key="right-column-paginator" className={classes.row}>
					<Paginator
						limit={limit}
						offset={offset}
						total={total}
						paddButtons={4}
						align="center"
						onChange={(offset, zeroIndexedPage) => changePage(zeroIndexedPage + 1)}
					/>
				</div>
			);
		}

		return (
			<div className={footerClasses.b()}>
				<div className={classes.lc}>{leftColumn}</div>
				<div className={classes.rc}>{rightColumn}</div>
			</div>
		);
	}
}

import DetectedDuplicate from 'App/babel/resources/DetectedDuplicate';

angular.module('domain.contact').controller('ListContacts', [
	'$q',
	'$translate',
	'$upModal',
	'$state',
	'ContactAttributes',
	'Contact',
	'$scope',
	'AppService',
	'FilterHelper',
	'MultiRunnerActions',
	'Onboarding',
	'$location',
	'$stateParams',
	'localStorageService',
	'$rootScope',
	'Segment',
	'$multiSelect',
	'$safeApply',
	function (
		$q,
		$translate,
		$upModal,
		$state,
		ContactAttributes,
		Contact,
		$scope,
		AppService,
		FilterHelper,
		MultiRunnerActions,
		Onboarding,
		$location,
		$stateParams,
		localStorageService,
		$rootScope,
		Segment,
		$multiSelect,
		$safeApply
	) {
		var Ctrl = this;
		var self;
		var customerId;
		var contacts = [];
		var currentQuery = [];
		var segment = null;
		var segmentFilter = null;
		Ctrl.fromGroupMailEdit = !!($stateParams.fromGroupMailEdit || $location.search().fromGroupMailEdit);
		Ctrl.fromSegment = !!($stateParams.fromSegment || $location.search().fromSegment);
		Ctrl.fromGroupMailRecipient = !!$stateParams.fromGroupMailRecipient;
		Ctrl.selectMode = false;

		if (Ctrl.fromSegment || Ctrl.fromGroupMailEdit) {
			Ctrl.selectMode = true;
		}

		Ctrl.attributes = ContactAttributes();
		Ctrl.disableAddFilter = false;
		Ctrl.showEdit = true;
		Ctrl.listType = 'contact';
		Ctrl.tableTemplate = 'contactRow';
		Ctrl.title = 'default.contacts';
		Ctrl.customFields = [];
		Ctrl.total = 0;
		Ctrl.infoRow = null;
		Ctrl.filterOpts = {};
		Ctrl.noSavedViews = false;

		// Do stuff when in select mode
		if (Ctrl.selectMode) {
			Ctrl.useMultiActions = false;
			Ctrl.openOnInit = true;
			Ctrl.noSavedViews = true;
			Ctrl.excludeCount = 0;
			Ctrl.excludeCountCurrentFilter = 0;
			Ctrl.saving = false;

			Ctrl.listViews = [
				{
					columns: ['contactText', 'email', 'clientText'],
					default: true,
					editable: false,
					filters: [],
					id: 99999,
					limit: 100,
					private: false,
					sorting: [{ attribute: 'name', ascending: true }],
					title: $translate.instant('defaultView.allContacts'),
					type: 'contact'
				}
			];

			Ctrl.filterMap = function (filter, filterName) {
				if (filterName === 'ExcludeId' || filterName === 'MissingEmail') {
					filter.hide = false;
				}
			};

			Ctrl.disableAddButton = function (filters) {
				// Allow empty filters for segments
				if (Ctrl.fromSegment && Object.keys(filters).length) {
					return false;
				}

				var filterKeys = Object.keys(filters).filter(function (filterKey) {
					return filterKey !== 'ExcludeId';
				});

				return filterKeys.length > 0 ? false : true;
			};

			Ctrl.selectModeBack = function (filters, shallSave) {
				if (Ctrl.saving) {
					return;
				}

				if (shallSave) {
					Ctrl.saving = true;
				}

				var params = { customerId: customerId };
				if (Ctrl.fromGroupMailEdit) {
					var fromStorage = null;
					try {
						fromStorage = JSON.parse(localStorageService.get('unsavedMailCampaign'));
					} catch (e) {
						console.log('error', e);
					}

					if (filters && filters.ExcludeId) {
						filters.ExcludeId.inactive = false;
					}
					// Set id on stateParams
					if (fromStorage && fromStorage.id) {
						if (Ctrl.fromGroupMail) {
							params.groupMailId = fromStorage.id;
						} else {
							params.groupMailEditId = fromStorage.id;
						}
					}
					if (shallSave) {
						params.filters = FilterHelper.convertForURL(filters, 'contact', { skipInactive: true });
					}

					Tools.routerHistory.push({
						pathname: `/group-mail-editor/${params.groupMailEditId}/recipients`,
						search: '?filters=' + params.filters
					});
				} else if (Ctrl.fromSegment) {
					if (segment) {
						params.id = segment.id;
						params.segment = segment;
						params.expanded = 'expanded';
						if (!shallSave) {
							return $state.go('segment.contacts', params);
						}

						if (Object.keys(filters).length > 0) {
							var hasChanged = $stateParams.q !== FilterHelper.convertForURL(filters, 'contact');
							if (shallSave && hasChanged) {
								var filter = segmentFilter;

								if (filters && filters.ExcludeId) {
									filters.ExcludeId.inactive = false;
								}

								var parseFilters = Ctrl.isSpecialExcludeFilter ? _.pick(filters, 'IncludeId') : filters;
								var q = FilterHelper.parseFilters(parseFilters, 'contact').build().q;

								filter.body = { q: q };
								filter.config = filters;
								params.filter = filter;
							}

							$state.go('segment.contacts', params);
						}
					}
				}
			};
		}

		// Group mail specific stuff
		if (Ctrl.fromGroupMailEdit) {
			$location.search({
				fromGroupMailEdit: true,
				q: $stateParams.q,
				columns: JSON.stringify(Ctrl.listViews[0].columns)
			});
		}
		// Segment specific stuff
		else if (Ctrl.fromSegment) {
			var getEntriesSegment = function (query, rb) {
				currentQuery = rb.build();
				Ctrl.total = 0;

				var countContactRb = _.cloneDeep(rb);

				var filters = Ctrl.getFilters();
				var getExcludes = $q.when({ metadata: { total: 0 } });

				if (filters.ExcludeId && Array.isArray(filters.ExcludeId.value) && filters.ExcludeId.value.length) {
					var excludeQuery = _.pick(filters, 'ExcludeId');
					excludeQuery = _.cloneDeep(excludeQuery);
					excludeQuery.ExcludeId.comparisonType = 'Equals';
					excludeQuery.ExcludeId.inactive = false;

					var excludeRb = FilterHelper.parseFilters(excludeQuery, 'contact');
					excludeRb.limit = 0;

					getExcludes = Contact.find(excludeRb.build());

					var idListToExclude = excludeQuery.ExcludeId.value;
					countContactRb.addFilter(
						Contact.attr.id,
						countContactRb.comparisonTypes.NotEquals,
						idListToExclude
					);

					query.q = _.filter(query.q, function (x) {
						return !(x.a === 'id' && x.c === 'ne');
					});
				}

				var countContactQuery = countContactRb.build();
				countContactQuery.limit = 0;

				return $q
					.all({
						getContacts: Contact.find(query, { mapCustom: true }),
						getContactCount: Contact.find(countContactQuery, { mapCustom: true }),
						getExcludes: getExcludes
					})
					.then(function (res) {
						contacts = res.getContacts.data;
						Ctrl.total = res.getContactCount.metadata
							? res.getContactCount.metadata.total
							: res.getContacts.length;
						Ctrl.excludeCount = res.getExcludes.metadata.total;
						Ctrl.excludeCountCurrentFilter = 0;

						return res.getContacts;
					});
			};

			Ctrl.filterOpts.idListIsInactive = true;

			var segmentSpecificColumns = ['score', 'history', 'marketHistory'];
			Ctrl.listViews[0].columns = Ctrl.listViews[0].columns.concat(segmentSpecificColumns);

			if ($stateParams.fromSegment) {
				segment = $stateParams.fromSegment.segment;
				segmentFilter = $stateParams.fromSegment.filter;

				Ctrl.isSpecialExcludeFilter =
					Object.keys(segmentFilter.config).length === 1 && segmentFilter.config.hasOwnProperty('IncludeId');

				$location.search({
					fromSegment: JSON.stringify({
						f: segmentFilter.id || null,
						e: segmentFilter.isExclude,
						s: segment.id,
						i: Ctrl.isSpecialExcludeFilter
					}),
					q: $stateParams.q,
					columns: JSON.stringify(Ctrl.listViews[0].columns)
				});
			} else {
				var urlParams = JSON.parse($location.search().fromSegment);

				Ctrl.isSpecialExcludeFilter = urlParams.i;

				segmentFilter = {
					id: urlParams.f,
					body: {},
					config: {},
					isExclude: urlParams.e
				};

				Segment.get(urlParams.s)
					.then(function (res) {
						if (res && res.data) {
							segment = res.data;
						}
					})
					.catch(e => console.log('get segment error', e));
			}

			Ctrl.getNumberExcludedContacts = function () {
				var filters = Ctrl.getFilters();

				if (!filters || !filters.IncludeId || !Array.isArray(filters.IncludeId.value)) {
					return 0;
				} else {
					return filters.IncludeId.value.length;
				}
			};
		} else if (Ctrl.fromGroupMailRecipient) {
			$location.search({
				q: $stateParams.q
			});
		} else {
			Ctrl.useMultiActions = true;

			Ctrl.headerListLinks = [
				{
					type: 'account',
					state: 'accounts',
					text: 'default.clients',
					icon: 'fa-home'
				},
				{
					type: 'contact',
					state: 'contacts',
					text: 'default.contacts',
					icon: 'fa-user'
				}
			];

			Ctrl.addButton = {
				getLabel: function () {
					return $translate.instant('default.createContact');
				},
				createEntry: function () {
					Ctrl.createEntry();
					Onboarding.setListIntro('contact');
				},
				isEnabled: function () {
					const self = AppService.getSelf();
					return self.createRights.Contact !== 'NO';
				}
			};

			Ctrl.quickSearch = {
				getFilter: function (value) {
					return {
						filterName: 'ListSearch',
						inactive: !value,
						value: value
					};
				},
				getValue: function (props) {
					var filter = props.activeFilters.ListSearch;
					return filter && filter.value ? filter.value : '';
				},
				getPlaceholder: function () {
					return $translate.instant('filters.quickSearch');
				}
			};
		}

		var updateMultiselectTotal = function () {
			$multiSelect.setTotal(Ctrl.total);
		};

		// Ctrl.secondList = 'accounts';
		// Ctrl.secondListText = 'default.clientRegister';
		Ctrl.headerExtensionPath = require('App/upsales/domain/contact/views/listContactsHeaderExtension.html?file');

		$scope.$on('filterCleared', function (e, filter) {
			if (filter && filter.filterName === 'ExcludeId') {
				Ctrl.excludeCountCurrentFilter = 0;
			}
		});

		$scope.$on('contact.added', function (e, added) {
			if (FilterHelper.match(currentQuery.q, added, 'contact')) {
				Ctrl.total++;
				updateMultiselectTotal();
				contacts.push(added);
			}
		});

		$scope.$on('contact.updated', function (e, updated) {
			var existingIndex = _.findIndex(contacts, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, updated, 'contact')) {
				contacts[existingIndex] = updated;
			} else if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				contacts.splice(existingIndex, 1);
			}
		});

		$scope.$on('contact.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(contacts, { id: deleted.id });
			if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				contacts.splice(existingIndex, 1);
			}
		});

		Ctrl.formatTotal = function (total) {
			const totalContacts = total || Ctrl.total;
			if (totalContacts) {
				if (totalContacts === 1) {
					return totalContacts + ' ' + $translate.instant('default.contact').toLowerCase();
				}

				return totalContacts + ' ' + $translate.instant('default.contacts').toLowerCase();
			}

			return totalContacts + ' ' + $translate.instant('default.contacts').toLowerCase();
		};

		/**
		 * The add button
		 */
		Ctrl.createEntry = function () {
			var options = { customerId: customerId };

			$upModal.open('editContact', options);
		};

		// The getter function {listView} will need
		Ctrl.getEntries = function (query, rb) {
			currentQuery = rb.build();
			Ctrl.total = 0;

			return Contact.customer(customerId)
				.find(query, { mapCustom: true })
				.then(function (res) {
					contacts = res.data;
					Ctrl.total = res.metadata ? res.metadata.total : res.length;

					return res;
				});
		};

		Ctrl.loadDetectedDuplicates = function () {
			if (self.administrator) {
				DetectedDuplicate.find({ limit: 0, ignore: 0, entity: 'Contact' })
					.then(function (data) {
						if (data.metadata.total > 0) {
							Ctrl.linkButton = {
								getLabel: function () {
									return data.metadata.total;
								},
								action: function () {
									$state.go('react-root-detected-duplicates', {
										entity: 'contacts',
										customerId: customerId
									});
								}
							};
						} else {
							Ctrl.linkButton = null;
						}
						$safeApply($scope);
					})
					.catch(e => console.log('detect duplicates error', e));
			}
		};

		Ctrl.init = function () {
			self = AppService.getSelf();
			customerId = AppService.getCustomerId();

			Ctrl.customFields = AppService.getCustomFields('contact');
			Ctrl.addBtnLabel =
				$translate.instant('default.add') + ' ' + $translate.instant('default.contact').toLowerCase();

			if (Ctrl.fromSegment) {
				var key = customerId + ':' + self.id + ':hideSegmentContactListInfoRow';
				var showInfoRow = localStorage.getItem(key);

				Ctrl.getEntries = getEntriesSegment;

				if (showInfoRow !== 'true') {
					Ctrl.infoRow = 'Segment';
				}
			}

			if (
				$stateParams.fromSegment &&
				$stateParams.fromSegment.filter.isExclude &&
				$stateParams.fromSegment.filter.manualExcluded
			) {
				Ctrl.filterOpts.forceClosedFilter = true;
			}

			Ctrl.listOpts = {
				type: 'contact',
				hasCheck: Ctrl.fromGroupMailEdit || Ctrl.fromSegment ? false : true,
				editable: !Ctrl.fromSegment && !Ctrl.fromGroupMailEdit,
				placeholderKey: 'contact',
				selectMode: Ctrl.selectMode,
				isSpecialExcludeFilter: Ctrl.isSpecialExcludeFilter,
				isExcludeMode: $stateParams.fromSegment ? $stateParams.fromSegment.filter.isExclude : false,
				fromSegment: $stateParams.fromSegment ? $stateParams.fromSegment : null,
				infoRow: Ctrl.infoRow,
				getExcludeIds: function () {
					var filters = Ctrl.getFilters();
					return (filters.ExcludeId && filters.ExcludeId.value) || [];
				},
				closeInfoRow: function () {
					Ctrl.listOpts.infoRow = Ctrl.infoRow = null;
					var key = customerId + ':' + self.id + ':hideSegmentContactListInfoRow';

					localStorage.setItem(key, 'true');

					$rootScope.$broadcast('renderTable', {});
				},
				openInfoRow: function () {
					Ctrl.listOpts.infoRow = Ctrl.infoRow = 'Segment';
					var key = customerId + ':' + self.id + ':hideSegmentContactListInfoRow';

					localStorage.setItem(key, 'false');

					$rootScope.$broadcast('renderTable', {});
				},
				clickedEntry: function (contact) {
					var options = {
						customerId: customerId,
						id: contact.id
					};

					$state.go('contact.dashboard', options);
				},
				editEntry: function (contact) {
					var options = {
						customerId: customerId,
						id: contact.id
					};

					$upModal.open('editContact', options);
				},
				removeEntry: function (contact) {
					return Contact.customer(customerId)['delete'](contact);
				},
				excludeContact: function (contact) {
					var filters = Ctrl.getFilters();

					if (Ctrl.isSpecialExcludeFilter) {
						if (filters.IncludeId && Array.isArray(filters.IncludeId.value)) {
							filters.IncludeId.value = _.filter(filters.IncludeId.value, function (id) {
								return id !== contact.id;
							});
						}
					} else {
						if (!filters.ExcludeId) {
							filters.ExcludeId = FilterHelper.getConfig('ExcludeId', 'contact');
							filters.ExcludeId.inactive = true;
						}
						if (!Array.isArray(filters.ExcludeId.value)) {
							filters.ExcludeId.value = [];
						}
						var index = filters.ExcludeId.value.indexOf(contact.id);
						if (index === -1) {
							filters.ExcludeId.value.push(contact.id);
							Ctrl.excludeCountCurrentFilter++;
							Ctrl.excludeCount++;
						} else {
							filters.ExcludeId.value.splice(index, 1);
							Ctrl.excludeCount--;
							Ctrl.excludeCountCurrentFilter--;
						}
					}
					$scope.$apply();
				},
				includeContact: function (contact) {
					var segment = $stateParams.fromSegment.segment;
					var filters = segment.filter;
					var currentViewFilters = Ctrl.getFilters();

					segment.filter = _.map(filters, function (filter) {
						if (!filter.isExclude && filter.config.ExcludeId) {
							filter.body.q = _.map(filter.body.q, function (q) {
								if (q.a === 'id' && q.c === 'ne') {
									q.v = _.filter(q.v, function (id) {
										return id !== contact.id;
									});
								}

								return q;
							});

							filter.config.ExcludeId.value = _.filter(filter.config.ExcludeId.value, function (id) {
								return id !== contact.id;
							});
						}

						return filter;
					});

					Segment.save(segment)
						.then(function () {
							currentViewFilters.Id.value = _.filter(currentViewFilters.Id.value, function (val) {
								return val !== contact.id;
							});
						})
						.catch(e => console.log('save segment error', e));
				}
			};

			Ctrl.multiActions = MultiRunnerActions.get(MultiRunnerActions.type.CONTACT);
			Ctrl.loadDetectedDuplicates();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(Ctrl.init);
	}
]);

'use strict';

ReactTemplates.admin.notFound = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			admin: t('default.admin')
		};
	},
	render: function () {
		return <div>Not found</div>;
	}
});

'use strict';

angular.module('upResources').service('ParseAccountProfile', function () {
	return function (data) {
		// This is for the new domain layer that returns an array in find
		if (Array.isArray(data)) {
			return data.length ? data[0] : null;
		}

		// Make sure we did not get a clientParam object
		if (data && !data.alias && !data.value) {
			return data;
		}

		// Set profile to null if parse failed
		var json = null;

		// try to parse the json
		try {
			json = JSON.parse(data.value);
		} catch (e) {
			// if parsing fails, the json will be null
		}

		// Temporary fix for accounts that had the early accountProfile format - remove this laters
		if (json) {
			if (!json.extraColors) {
				json.extraColors = [];
			}

			if (json.typography) {
				_.each(json.typography, function (value, key) {
					if (!value.fontFamily) {
						json.typography[key].fontFamily = 'Arial, Helvetica, sans-serif';
					}
				});
			}

			if (!json.colors.footerLinkText) {
				json.colors.footerLinkText = json.colors.linkText;
			}
			if (!json.colors.footerText) {
				json.colors.footerText = json.colors.mainText;
			}
		}

		return json;
	};
});

import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './AppOnboarding.scss';
import {
	Headline,
	Block,
	Text,
	Button,
	Icon,
	Table,
	Card,
	TableHeader,
	TableRow,
	TableColumn,
	Loader,
	Row,
	Column
} from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import { connect } from 'react-redux';
import UpsalesAI from 'Resources/UpsalesAI';
import { createGoogleSearchURL, determineCompanyDescription } from 'Components/Prospecting/utils';
import { RootState } from 'Store/index';
import LinkButtonGroup from 'Components/Buttons/LinkButtonGroup';
import T from 'Components/Helpers/translate';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import RequestBuilder from 'Resources/RequestBuilder';
import Prospecting from 'Resources/Prospecting';

const FETCH_LIMIT = 15;

type AIClient = {
	prospectingId: string;
	name: string;
	website: string;
	linkedin: string;
	turnover: number;
	noEmployees: number;
};

type AppOnboardingSimilarClientsProps = {
	orgNo?: string;
	count?: number;
	name: string;
	language: string;
	onBack: () => void;
	onConfirm: (prospectingIds: string[]) => void;
};

const mapStateToProps = ({ App }: RootState) => {
	return {
		language: App.self?.language ?? ''
	};
};

const findInAI = async (orgNo: string): Promise<AIClient[]> => {
	const country = Tools.AppService.getMetadata().params.CustomerRegion;
	const { data: aiData } = await UpsalesAI.find({
		orgNumbers: [orgNo.replace(country === 'GB' ? /[^A-Za-z0-9]/g : /\D/g, '')],
		country
	});
	return aiData;
};

const findInProspectingKeyword = async (word: string): Promise<[ProspectingClient[], number]> => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'description' }, 'mand', word);
	rb.addSort({ field: 'relevance' }, false);
	rb.limit = FETCH_LIMIT;
	rb.extraParams.push({ key: 'country', value: Tools.AppService.getMetadata().params.CustomerRegion });

	const { data, metadata } = await Prospecting.find(rb.build());

	return [data, metadata.total];
};

const AppOnboardingSimilarClients = ({
	orgNo,
	name,
	language,
	onBack,
	onConfirm
}: AppOnboardingSimilarClientsProps) => {
	const [clients, setClients] = useState<AIClient[]>([]);
	const [clientsTotal, setClientsTotal] = useState(0);
	const [loadingClients, setLoadingClients] = useState(true);
	const [selected, setSelected] = useState<string[]>([]);

	const toggleCompany = (prospectingId: AIClient['prospectingId']) => {
		if (selected.includes(prospectingId)) {
			setSelected([...selected.filter(id => id !== prospectingId)]);
		} else {
			setSelected([...selected, prospectingId]);
		}
	};

	useEffect(() => {
		if (!orgNo && !name) {
			setClients([]);
			setLoadingClients(false);
		} else if (orgNo) {
			setLoadingClients(true);
			findInAI(orgNo)
				.then(data => {
					setClientsTotal(data.length ? data.length + 1 : 0); // ai wont give total so just set length + 1 and assume there is more
					setClients(data.splice(0, FETCH_LIMIT));
					setLoadingClients(false);
				})
				.catch(e => {
					logError(e, 'Failed to fetch prospecting data');
					// Allow entering upsales
				});
		} else if (name) {
			setLoadingClients(true);
			findInProspectingKeyword(name)
				.then(([data, total]) => {
					setClientsTotal(total);
					setClients(
						data.map(a => ({
							prospectingId: a.prospectingId,
							linkedin: a.linkedin ?? '',
							name: a.name,
							turnover: a.revenue,
							noEmployees: a.noEmployees || 0,
							website: a.website ?? '',
							description: a.description ?? '',
							adding: false,
							matchInUpsales: a.matchInUpsales
						}))
					);
					setLoadingClients(false);
				})
				.catch(e => {
					logError(e, 'Failed to fetch prospecting data');
				});
		}
	}, [orgNo]);

	const classes = new BemClass('AppOnboardingSimilarClients');
	return (
		<div className={classes.b()}>
			<Block space="mbxl">
				<Button color="white" onClick={() => onBack()}>
					<Icon name="chevron-left" space="mrs" />
					{T('onboarding.erp.back')}
				</Button>
			</Block>
			<Block space="mbxl">
				<Headline>{T('onboarding.erp.companiesSimilarTo', { NAME: name })}</Headline>
			</Block>
			{loadingClients ? (
				<Loader size="sm" />
			) : (
				<Card space="mbxl">
					<Table>
						<TableHeader
							columns={[
								{ title: T('default.name') },
								{ title: '' },
								{ title: T('default.turnover') },
								{ title: T('default.noEmployees') },
								{ title: '' }
							]}
						/>
						{clients.map((client, i) => {
							const description = determineCompanyDescription(client);
							return (
								<TableRow key={`${client.name}${i}`}>
									<TableColumn>
										<Text bold>{client.name}</Text>
									</TableColumn>
									<TableColumn>
										<LinkButtonGroup
											description={description}
											buttons={[
												client.website
													? {
															service: 'Website',
															href: client.website,
															icon: 'globe'
													  }
													: {
															service: 'Google',
															href: createGoogleSearchURL(client),
															icon: 'globe',
															search: true
													  },
												{ service: 'LinkedIn', href: client.linkedin, icon: 'linkedin' }
											]}
										/>
									</TableColumn>
									<TableColumn>
										<Text>{numberFormat(client.turnover)}</Text>
									</TableColumn>
									<TableColumn>
										<Text>{numberFormat(client.noEmployees)}</Text>
									</TableColumn>
									<TableColumn align="right">
										<Button
											size="sm"
											color={
												selected.includes(client.prospectingId) ? 'green' : 'super-light-green'
											}
											onClick={() => toggleCompany(client.prospectingId)}
											type={selected.includes(client.prospectingId) ? 'lined' : undefined}
										>
											<Icon
												name={selected.includes(client.prospectingId) ? 'check' : 'plus'}
												space="mrs"
											/>
											{T(
												selected.includes(client.prospectingId)
													? 'default.selected'
													: 'onboarding.erp.addToUpsales'
											)}
										</Button>
									</TableColumn>
								</TableRow>
							);
						})}
						{clientsTotal > FETCH_LIMIT ? (
							<TableRow>
								<TableColumn align="center" colSpan={100}>
									<Text color="grey-11">
										{T('onboarding.erp.youWillFindTheEntireResultInUpsales')}
									</Text>
								</TableColumn>
							</TableRow>
						) : null}
					</Table>
				</Card>
			)}
			<Block space="mtxl">
				<Row>
					<Column align="right">
						<Button
							size="xl"
							disabled={!selected.length}
							color={selected.length ? 'green' : 'light-grey'}
							onClick={() => onConfirm(selected)}
						>
							{T(
								selected.length
									? 'onboarding.erp.addSelectedCompaniesAndEnterUpsales'
									: 'onboarding.erp.selectCompaniesToAdd'
							)}
							<Icon name="chevron-right" space="mlm" />
						</Button>
					</Column>
				</Row>
			</Block>
		</div>
	);
};

export const detached = AppOnboardingSimilarClients;

export default connect(mapStateToProps)(AppOnboardingSimilarClients);

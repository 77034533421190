'use strict';

angular.module('upDirectives').directive('upEventLead', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventLead.tpl.html?file'),
		require: 'upEventLead',
		scope: {
			event: '=upEventLead'
		},
		controller: [
			'$scope',
			'$stateParams',
			'$upModal',
			'Lead',
			function ($scope, $stateParams, $upModal, Lead) {
				$scope.openLead = function () {
					var options = {
						customerId: $stateParams.customerId,
						id: $scope.event.entityId
					};

					$upModal.open('editLead', options);
				};

				$scope.expand = function () {
					if ($scope.event.$$expand === 1000) {
						$scope.event.$$expand = 1;
						return;
					}
					$scope.event.$$loading = true;

					// eslint-disable-next-line promise/catch-or-return
					Lead.customer($stateParams.customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}
							$scope.event.$$eventDetails = res.data;
							$scope.event.$$expand = 1000;
							$scope.event.$$loading = false;
						});
				};
			}
		]
	};
});

import { useFeatureAvailable } from 'App/components/hooks';
import { useSelf } from 'App/components/hooks/appHooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

export default () => {
	const hasCustomerSupport = useFeatureAvailable(Feature.CUSTOMER_SUPPORT);
	const self = useSelf();
	const hasUserAccess = !!(self?.support || self?.administrator);

	return hasCustomerSupport && hasUserAccess;
};

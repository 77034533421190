'use strict';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

angular.module('upResources').factory('UserDefinedObject', [
	'$resource',
	'$q',
	'URL',
	'API',
	'UserDefinedObjectAttributes',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	'AppService',
	'$translate',
	function UserDefinedObject(
		$resource,
		$q,
		URL,
		API,
		UserDefinedObjectAttributes,
		ResourceHelper,
		ParseGeneric,
		MapGeneric,
		AppService,
		$translate
	) {
		var AA = UserDefinedObjectAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: function () {
				return 'userDefinedObject' + Model.udoFieldId;
			},
			createSuccessBody: function () {
				return Model.selectedConfig.name + ' ' + $translate.instant('default.wasCreated').toLowerCase();
			},
			updateSuccessBody: function () {
				return Model.selectedConfig.name + ' ' + $translate.instant('default.wasUpdated').toLowerCase();
			},
			deleteSuccessBody: function () {
				return Model.selectedConfig.name + ' ' + $translate.instant('default.wasDeleted').toLowerCase();
			},
			createErrorBody: function () {
				return (
					$translate.instant('saveError.userDefinedObject') + ' ' + Model.selectedConfig.name.toLowerCase()
				);
			},
			updateErrorBody: function () {
				return (
					$translate.instant('saveError.userDefinedObject') + ' ' + Model.selectedConfig.name.toLowerCase()
				);
			},
			deleteErrorBody: function () {
				return (
					$translate.instant('deleteError.userDefinedObject') + ' ' + Model.selectedConfig.name.toLowerCase()
				);
			}
		});

		var baseUrl = URL + API + 'userDefinedObjects/:udoFieldId/';

		var customFieldFn = function () {
			return 'userDefined' + Model.udoFieldId;
		};

		const dateFields = ['modDate', 'regDate'];

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseUserDefinedObject', {
						isArray: false,
						custom: customFieldFn,
						dateFields
					})
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseUserDefinedObject', {
						isArray: true,
						custom: customFieldFn,
						dateFields
					})
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseUserDefinedObject', {
						isArray: false,
						custom: customFieldFn
					}),
					transformRequest: MapGeneric('MapUserDefinedObject', {
						payloadName: 'userDefinedObject'
					})
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseUserDefinedObject', {
						isArray: false,
						custom: customFieldFn
					}),
					transformRequest: MapGeneric('MapUserDefinedObject', {
						payloadName: 'userDefinedObject'
					})
				}
			}
		);

		var Model = {
			udoFieldId: null,

			selectedConfig: null,

			new: function () {
				var self = AppService.getSelf();

				var object = {
					notes: '',
					notes1: '',
					notes2: '',
					notes3: '',
					notes4: '',
					client: null,
					contact: null,
					project: null,
					user: self,
					custom: [],
					categories: [],
					userRemovable: true,
					userEditable: true,
					userDefinedObjectId: Model.udoFieldId
				};

				var parsed = ParseGeneric('ParseUserDefinedObject', {
					isArray: false,
					custom: customFieldFn,
					isNew: true
				})(
					{
						data: object
					},
					null
				);

				return parsed.data;
			},

			setId: function (udoFieldId) {
				Model.udoFieldId = udoFieldId;

				// Find and sett config for udo
				Model.selectedConfig = _.find(AppService.getMetadata().params.UserDefinedObject, {
					id: parseInt(udoFieldId)
				});

				return Model;
			},

			find: function (filter, options) {
				if (!Model.udoFieldId) {
					throw new Error('No fieldId set for UDO resource');
				}

				var params = angular.extend(filter, options);
				params.udoFieldId = Model.udoFieldId;

				return Resource.find(params).$promise;
			},

			get: function (id) {
				if (!Model.udoFieldId) {
					throw new Error('No fieldId set for UDO resource');
				}

				if (!id) {
					return $q.reject('no userDefinedObject id');
				}

				var params = {
					id: id,
					udoFieldId: Model.udoFieldId
				};

				return Resource.get(params).$promise;
			},

			save: function (userDefinedObject, options) {
				var defered = $q.defer();

				if (!Model.udoFieldId) {
					return defered.reject('No fieldId set for UDO resource');
				}

				if (!userDefinedObject) {
					return defered.reject('No userDefinedObject');
				}

				if (userDefinedObject.id) {
					Resource.update(
						{ id: userDefinedObject.id, udoFieldId: Model.udoFieldId },
						{ userDefinedObject: userDefinedObject },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Resource.create(
						{ udoFieldId: Model.udoFieldId },
						{ userDefinedObject: userDefinedObject },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !userDefinedObject.id);
						AllIWantDataCache.clearData();
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !userDefinedObject.id);
					});

				return defered.promise;
			},

			delete: function (userDefinedObject, options) {
				return Resource.delete({ id: userDefinedObject.id, udoFieldId: Model.udoFieldId })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, userDefinedObject);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

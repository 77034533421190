import PropTypes from 'prop-types';
import React from 'react';

const InlineOptInEdit = ({ optIn, lang, onChange, close, limit }) => {
	const valid = optIn.title.length <= limit;
	return (
		<tr className="opt-in-inline-edit-row">
			<td colSpan="4">
				<div className="optin-input-container">
					<h4>{lang.title}</h4>
					<p>{optIn.title}</p>
					<input
						autoFocus
						value={optIn.title}
						onChange={onChange}
						className={valid ? '' : 'error'}
						onKeyPress={e => {
							if (e.key === 'Enter') {
								close(optIn);
							}
						}}
					/>
				</div>
				<div className="optin-control-container">
					<button
						className="btn up-btn btn-lg btn-link btn-grey no-shadow btn-sm"
						onClick={close.bind(null, null)}
					>
						{lang.cancel}
					</button>
					<button
						disabled={!valid}
						className="btn up-btn btn-lg btn-bright-blue no-shadow btn-sm"
						onClick={close.bind(null, optIn)}
					>
						{lang.save}
					</button>
				</div>
			</td>
		</tr>
	);
};

InlineOptInEdit.propTypes = {
	optIn: PropTypes.object,
	lang: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
};

window.InlineOptInEdit = InlineOptInEdit;
export default window.InlineOptInEdit;

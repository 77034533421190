class URLFormatter {
	static format(url: string): string | undefined {
		try {
			if (!url.includes('https://') && !url.includes('http://')) {
				url = 'https://' + url;
			}
			// Tries creating a URL object (catches some corner cases)
			const tmp = new URL(url);
			return tmp.toString();
		} catch (err) {
			return undefined;
		}
	}
}

export default URLFormatter;

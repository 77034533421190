import { Button, ClickableItem, Flex } from '@upsales/components';
import AdminHeader from 'Components/Admin/AdminHeader';

ReactTemplates.admin.accountProfile = window.ReactCreateClass({
	getInitialState: function () {
		return {
			addingColor: false,
			colorToAdd: '#ffffff',
			sidebarExpanded:
				Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1659 ? true : false,
			fixed: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			accountProfile: t('admin.accountProfile'),
			accountProfileInfo: t('admin.accountProfileInfo'),
			clientInfo: t('admin.accountProfile.location'),
			locationInfo: t('admin.accountProfile.locationInfo'),
			accountName: t('default.accountName'),
			address: t('address.address'),
			zip: t('address.zip'),
			city: t('address.city'),
			country: t('address.country'),
			phone: t('default.phone'),
			email: t('default.email'),
			logo: t('admin.logo'),
			change: t('default.change'),
			enterUrl: t('admin.enterUrl'),
			colors: t('admin.colors'),
			mainColor: t('admin.mainColor'),
			button: t('admin.button'),
			contentBg: t('admin.contentBg'),
			background: t('admin.background'),
			extraColors: t('admin.extraColors'),
			add: t('default.add'),
			abort: t('default.abort'),
			typography: t('admin.typography'),
			typographyInfo: t('admin.typographyInfo'),
			mainTitle: t('admin.mainTitle'),
			secondaryTitle: t('admin.secondaryTitle'),
			preamble: t('admin.preamble'),
			paragraph: t('admin.paragraph'),
			link: t('admin.link'),
			profileExample: t('admin.profileExample'),
			preambleExampleText: t('admin.preambleExampleText'),
			paragraphExampleText: t('admin.paragraphExampleText'),
			contactUs: t('admin.contactUs'),
			footerLinkText: t('admin.footerLinkText'),
			logoBrightBackground: t('admin.logoBrightBackground'),
			logoDarkBackground: t('admin.logoDarkBackground'),
			uploadLogo: t('admin.uploadLogo'),
			logoInfo: t('admin.accountProfile.logoInfo'),
			colorInfo: t('admin.accountProfile.colorInfo'),
			extracolorInfo: t('admin.accountProfile.extracolorInfo'),
			mailExample: t('admin.accountProfile.mailExample'),
			bannerExample: t('admin.accountProfile.bannerExample'),
			supportEmailExample: t('admin.accountProfile.supportEmailExample'),
			supportEmailExampleTicketUpdated: t('admin.accountProfile.supportEmailExample.ticketUpdated'),
			supportEmailExampleDate: t('admin.accountProfile.supportEmailExample.date'),
			supportEmailMessage: t('admin.accountProfile.supportEmailExample.message'),
			landingPageExample: t('admin.accountProfile.landingPageExample'),
			preview: t('admin.accountProfile.preview'),
			hidePreview: t('admin.accountProfile.hidePreview'),
			footerText: t('admin.footerText'),
			logoSizeRecommendation: t('admin.accountProfile.logoSizeRecommendation'),
			select: t('default.select')
		};

		this.colors = [
			{ title: this.lang.mainColor, hoverKey: 'main', modelKey: 'mainBg' },
			{ title: this.lang.button, hoverKey: 'button', modelKey: 'buttonBg' },
			{ title: this.lang.contentBg, hoverKey: 'content', modelKey: 'contentBg' },
			{ title: this.lang.background, hoverKey: 'body', modelKey: 'bodyBg' }
		];

		this.scrollbarWidth = window.Tools.browserService.scrollbarWidth;

		this.colorChange = _.debounce(this.colorChange, 250);
	},
	componentDidMount: function () {
		var element = document.getElementById('admin-root');
		if (element) {
			element.addEventListener('scroll', this.onScroll);
		}

		this.renderBanner();
	},
	componentDidUpdate: function () {
		this.renderBanner();
	},
	renderBanner: function () {
		var self = this;
		var pageData = self.props.rootData.pageData;

		_.each(pageData.bannerTemplate, function (banner) {
			var config = window.BannerParser.generateConfig(banner.body, pageData.profile);

			window.BannerParser.parse(config, banner.body, pageData.profile, function (err, html) {
				if (err) {
					console.log('ERROR PARSING TEMPLATE');
					return;
				}
				html = 'data:text/html;charset=utf-8,' + html;

				var id;

				if (banner.name === 'Template 1 - Hörnflagga') {
					id = 1;
				} else if (banner.name === 'Template 2 - Growth day') {
					id = 2;
				} else if (banner.name === 'Template 7 - 10 tips') {
					id = 7;
				}

				if (id === undefined) {
					return;
				}

				var iframe = self['_banner' + id];

				if (!iframe) {
					return;
				}

				iframe.contentWindow?.document?.open();
				iframe.contentWindow?.document?.write(html);
				iframe.contentWindow?.document?.close();
			});
		});
	},
	onScroll: function () {
		if (!this._header) {
			return;
		}

		var container = document.getElementById('admin-root');
		var scroll = container.scrollTop + container.clientHeight;
		var bottomDiff = container.scrollHeight - scroll;
		var key = this._header.clientHeight + 2;

		if (
			(key < 0 && bottomDiff >= key * -1) || // For bottom diff
			(!(key < 0) && container.scrollTop >= key) // For top diff
		) {
			if (!this.state.fixed) {
				this.setState({ fixed: true });
			}
		} else {
			if (this.state.fixed) {
				this.setState({ fixed: false });
			}
		}
	},
	profilePropChanged: function (key, e) {
		this.props.rootData.onProfilePropChanged(key, e.target.value);
	},
	highlightPreview: function (className) {
		var elements = this._preview.querySelectorAll('.hover-element-' + className);
		_.each(elements, function (e) {
			e.classList.add('hovered-element');
		});
	},
	highlightEnd: function () {
		var elements = this._preview.querySelectorAll('.hovered-element');
		_.each(elements, function (e) {
			e.classList.remove('hovered-element');
		});
	},
	colorChange: function (modelKey, key, value) {
		this.props.rootData.onColorChanged(modelKey || 'colors', key, value);
	},
	showEditColor: function () {
		this.setState({ addingColor: true, colorToAdd: '#ffffff' });
	},
	abortColorEdit: function () {
		this.setState({ addingColor: false });
	},
	colorToAddChange: function (value) {
		this.setState({ colorToAdd: value });
	},
	saveColor: function () {
		this.props.rootData.saveColor(this.state.colorToAdd);
		this.abortColorEdit();
	},
	onTypoSelectChange: function (profileModelKey, type, key, value) {
		this.props.rootData.onTypographyChanged(profileModelKey, type, key, value);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	toggleSidebar: function () {
		this.setState({
			sidebarExpanded: true
		});
	},
	closeSidebar: function () {
		this.setState({
			sidebarExpanded: false
		});
	},
	renderSupportEmailPreview: function (profile, self) {
		return (
			<div className="fake-support-email">
				<div className="fake-support-email__header">
					{profile.darkLogo?.length ? (
						<img src={profile.darkLogo} height={40} />
					) : (
						<span>{profile.name}</span>
					)}
				</div>
				<div className="fake-support-email__item">{this.lang.supportEmailExampleTicketUpdated}</div>
				<div className="fake-support-email__item">
					<div className="fake-support-email__user-name">
						<b>{self.name}</b> {`(${profile.name})`}
					</div>
					<div className="fake-support-email__date">{this.lang.supportEmailExampleDate}</div>
					<div className="fake-support-email__message">{this.lang.supportEmailMessage}</div>
				</div>
			</div>
		);
	},
	renderTypographyPreview: function (colors, typography, isForLandingPages) {
		var self = this;
		var rootData = this.props.rootData;
		var profile = rootData.pageData.profile;
		var h2Style = {
			color: colors.mainText,
			fontSize: typography.h2.size + 'px',
			fontWeight: typography.h2.bold ? 'bold' : 'normal',
			textDecoration: typography.h2.underline ? 'underline' : 'none',
			fontStyle: typography.h2.italic ? 'italic' : 'normal',
			fontFamily: typography.h2.fontFamily
		};

		var h3Style = {
			color: colors.secondaryText,
			fontSize: typography.h3.size + 'px',
			fontWeight: typography.h3.bold ? 'bold' : 'normal',
			textDecoration: typography.h3.underline ? 'underline' : 'none',
			fontStyle: typography.h3.italic ? 'italic' : 'normal',
			fontFamily: typography.h3.fontFamily
		};

		var ingressStyle = {
			color: colors.contentTextIngress,
			fontSize: typography.ingress.size + 'px',
			fontWeight: typography.ingress.bold ? 'bold' : 'normal',
			textDecoration: typography.ingress.underline ? 'underline' : 'none',
			fontStyle: typography.ingress.italic ? 'italic' : 'normal',
			fontFamily: typography.ingress.fontFamily
		};

		var pStyle = {
			color: colors.contentText,
			fontSize: typography.p.size + 'px',
			fontWeight: typography.p.bold ? 'bold' : 'normal',
			textDecoration: typography.p.underline ? 'underline' : 'none',
			fontStyle: typography.p.italic ? 'italic' : 'normal',
			fontFamily: typography.p.fontFamily
		};

		var btnStyle = {
			color: colors.buttonText,
			backgroundColor: colors.buttonBg,
			borderRadius: profile.btnBorderRadius
		};

		var linkStyle = {
			color: colors.linkText,
			fontWeight: typography.link.bold ? 'bold' : 'normal',
			textDecoration: typography.link.underline ? 'underline' : 'none',
			fontStyle: typography.link.italic ? 'italic' : 'normal',
			fontFamily: typography.link.fontFamily
		};

		var hoverClass = 'hover-element-' + (isForLandingPages ? 'landing-' : 'email-');
		return (
			<div id="fake-content-wrapper" className="hover-element-body" style={{ backgroundColor: colors.bodyBg }}>
				<div className="profile-header hover-element-main" style={{ backgroundColor: colors.mainBg }}>
					<img src={profile.logo} height="30" data-test-id="account-profile-preview-logo" />
					<div
						id="contact-us"
						className={'hover-element-button ' + (hoverClass + 'button')}
						style={{
							backgroundColor: colors.buttonBg,
							color: colors.buttonText
						}}
					>
						{self.lang.contactUs}
					</div>
				</div>

				<div className="profile-content hover-element-content" style={{ backgroundColor: colors.contentBg }}>
					<h2 style={h2Style} className={'hover-element-h2 ' + (hoverClass + 'h2')}>
						{self.lang.mainTitle}
					</h2>
					<h3 style={h3Style} className={'hover-element-h3 ' + (hoverClass + 'h3')}>
						{self.lang.secondaryTitle}
					</h3>

					<p style={ingressStyle} className={'ingress hover-element-ingress ' + (hoverClass + 'ingress')}>
						{self.lang.preambleExampleText}
					</p>

					<p style={pStyle} className={'hover-element-p ' + (hoverClass + 'p')}>
						{self.lang.paragraphExampleText}{' '}
						<a style={linkStyle} className={'hover-element-a ' + (hoverClass + 'a')}>
							{'Lorem ipsum'}
						</a>
					</p>

					<button
						type="button"
						className={'profile-button hover-element-button ' + (hoverClass + 'button')}
						style={btnStyle}
					>
						{self.lang.button}
					</button>
				</div>

				<div className="profile-footer" style={{ color: colors.footerText }}>
					<div className="pull-left">
						<b
							className={'hover-element-footerText ' + (hoverClass + 'footerText')}
							data-test-id="account-profile-preview-name"
						>
							{profile.name}
						</b>
						<br />
						{profile.email ? (
							<a
								style={{ color: colors.footerLinkText }}
								className={'hover-element-footerLink ' + (hoverClass + 'footerLink')}
							>
								<b className="fa fa-envelope" />{' '}
								<span data-test-id="account-profile-preview-email">{profile.email}</span>
							</a>
						) : null}
						<br />
						{profile.phone ? (
							<a
								style={{ color: colors.footerLinkText }}
								className={'hover-element-footerLink ' + (hoverClass + 'footerLink')}
							>
								<b className="fa fa-phone" />{' '}
								<span data-test-id="account-profile-preview-phone">{profile.phone}</span>
							</a>
						) : null}
					</div>
					<div className={'pull-right hover-element-footerText ' + (hoverClass + 'footerText')}>
						<span data-test-id="account-profile-preview-address">{profile.address}</span>
						<br />
						<span data-test-id="account-profile-preview-zipcode">{profile.zipcode}</span>{' '}
						<span data-test-id="account-profile-preview-city">{profile.city}</span>
						<br />
						<span data-test-id="account-profile-preview-country">{profile.country}</span>
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var profile = rootData.pageData.profile;
		var CustomFonts = window.AdminAccountProfileCustomFonts;
		var NavTabs = window.AdminAccountProfileNavTabs;
		var Typography = window.AdminAccountProfileTypography;

		if (!profile) {
			return null;
		}

		var colorVariables = [
			{ title: this.lang.colors, colors: {} },
			{ title: this.lang.extraColors, colors: {} }
		];

		_.forEach(profile.colors, value => {
			colorVariables[0].colors[value] = value;
		});
		_.forEach(profile.extraColors, color => {
			colorVariables[1].colors[color.value] = color.value;
		});

		var colors = _.map(self.colors, function (color, i) {
			return (
				<tr
					key={'color-' + i}
					onMouseOver={self.highlightPreview.bind(self, color.hoverKey)}
					onMouseOut={self.highlightEnd}
				>
					<td>{color.title}</td>
					<td style={{ width: '120px', verticalAlign: 'top' }}>
						<div className="pull-right account-profile-colors">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: profile.colors[color.modelKey],
								isText: false,
								presets: colorVariables,
								onChange: self.colorChange.bind(self, 'colors', color.modelKey)
							})}
						</div>
					</td>
				</tr>
			);
		});

		var extraColors = _.map(profile.extraColors, function (color, i) {
			// Tooltip this color.value
			return ReactTemplates.TOOLS.withTooltip(
				<div className="color-ball" style={{ backgroundColor: color.value }}>
					<button type="button" className="remove-color" onClick={rootData.removeColor.bind(self, i)}>
						<b className="fa fa-times" />
					</button>
				</div>,
				color.value,
				{ placement: 'top', key: 'color' + i }
			);
		});

		var contentClassName = '';

		if (this.state.fixed) {
			contentClassName += ' fixed';
		}
		if (this.state.sidebarExpanded) {
			contentClassName += ' open';
		}

		var sidebarStyle = {};
		var tabbarStyle = {};
		var sidebarClassName = 'profile-preview-sidebar';

		if (this.state.fixed && this.state.sidebarExpanded) {
			sidebarClassName += ' fixed';
			sidebarStyle.marginRight = this.scrollbarWidth;
		}
		if (this.state.fixed) {
			tabbarStyle.right = this.scrollbarWidth;
		}
		if (this.state.sidebarExpanded) {
			sidebarClassName += ' open';
		}

		return (
			<div id="admin-page-account-profile">
				<div ref={r => (this._header = r)}>
					<AdminHeader
						title={self.lang.accountProfile}
						description={self.lang.accountProfileInfo}
						image="company-profile.svg"
						articleId={470}
					/>
				</div>
				<div
					style={tabbarStyle}
					className={this.state.fixed ? 'page-header-tabbar fixed' : 'page-header-tabbar'}
					ref={function (r) {
						self._tabbar = r;
					}}
				>
					<NavTabs />
					<Button className="account-profile-preview-button" onClick={this.toggleSidebar}>
						{this.lang.preview}
					</Button>
				</div>

				<div id="admin-content" className={contentClassName}>
					<div className="row">
						<div className="col-sm-12 settings-column">
							<div className="admin-section admin-card" id="address-tab-content">
								<h2>
									{self.lang.clientInfo} <ReactTemplates.elevio articleId={434} sidebar={true} />
								</h2>
								<div className="admin-info-row">
									<div className="info-row-info">{self.lang.locationInfo}</div>
									<div className="info-row-content">
										<div className="row form-group">
											<div className="col-md-12 ">
												<label>{self.lang.accountName}</label>
												<input
													type="text"
													defaultValue={profile.name}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'name')}
													data-test-id="account-profile-name"
												/>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-12 ">
												<label>{self.lang.address}</label>
												<input
													type="text"
													defaultValue={profile.address}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'address')}
													data-test-id="account-profile-address"
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-md-5 col-lg-3 form-group">
												<label>{self.lang.zip}</label>
												<input
													type="text"
													defaultValue={profile.zipcode}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'zipcode')}
													data-test-id="account-profile-zipcode"
												/>
											</div>
											<div style={{ paddingLeft: 0 }} className="col-md-7 col-lg-5 form-group">
												<label>{self.lang.city}</label>
												<input
													type="text"
													defaultValue={profile.city}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'city')}
													data-test-id="account-profile-city"
												/>
											</div>
											<div className="col-md-12 col-lg-4 form-group country-input">
												<label>{self.lang.country}</label>
												<input
													type="text"
													defaultValue={profile.country}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'country')}
													data-test-id="account-profile-country"
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-md-12 col-lg-6 form-group">
												<label>{self.lang.phone}</label>
												<input
													type="text"
													defaultValue={profile.phone}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'phone')}
													data-test-id="account-profile-phone"
												/>
											</div>
											<div className="col-md-12 col-lg-6 form-group email-input">
												<label>{self.lang.email}</label>
												<input
													type="text"
													defaultValue={profile.email}
													className="form-control"
													onChange={self.profilePropChanged.bind(self, 'email')}
													data-test-id="account-profile-email"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="admin-section admin-card" id="logo-tab-content">
								<h2>
									{self.lang.logo} <ReactTemplates.elevio articleId={435} sidebar={true} />
								</h2>

								<div className="admin-info-row">
									<div className="info-row-info">
										{self.lang.logoInfo}
										<br />
										<br />
										{self.lang.logoSizeRecommendation}
									</div>
									<div className="info-row-content">
										<div
											className="package-box cover-img no-responsive no-animate"
											style={{
												backgroundColor: '#666666',
												maxWidth: '350px'
											}}
											data-test-id="account-profile-logo-box"
										>
											<div
												className="preview-image"
												style={{
													backgroundSize: 'contain',
													height: '90px',
													margin: '23px 20px 60px 20px',
													backgroundImage: profile.logo ? 'url("' + profile.logo + '")' : ''
												}}
											>
												{!profile.logo && (
													<h2
														style={{ color: '#fff' }}
														className={'no-logo-text no-logo-text--bright'}
													>
														{this.lang.uploadLogo}
													</h2>
												)}
											</div>

											<div className="overlay">
												<Flex justifyContent="end">
													<ClickableItem
														icon="trash-o"
														iconColor="white"
														size="sm"
														borderRadius
														onClick={rootData.deleteImage.bind(null, 'logo')}
														block={false}
														disabled={!profile.logo}
													/>
												</Flex>
												<button
													type="button"
													className="btn up-btn btn-block btn-bright-blue no-shadow"
													style={{ marginTop: '8px' }}
													onClick={rootData.selectImage.bind(null, 'logo')}
												>
													<b className="fa fa-pencil" />{' '}
													<span>{profile.logo ? self.lang.change : self.lang.select}</span>
												</button>

												<button
													type="button"
													className="btn up-btn btn-block btn-bright-blue no-shadow"
													style={{ marginTop: '8px' }}
													onClick={rootData.setLogoUrl.bind(null, 'logo')}
													data-test-id="account-profile-set-logo-url-btn"
												>
													<b className="fa fa-url" /> <span>{self.lang.enterUrl}</span>
												</button>
											</div>
											<div className="package-box-footer lined">
												<div className="package-box-title">
													<h2>{self.lang.logoDarkBackground}</h2>
													<span className="text-grey" style={{ display: 'block' }}>
														{profile.logo ? profile.logo.split('/').pop() : ''}
													</span>
												</div>
											</div>
										</div>
										<div
											className="package-box cover-img no-responsive no-animate"
											style={{
												backgroundColor: '#fafafa',
												maxWidth: '350px'
											}}
										>
											<div
												className="preview-image"
												style={{
													backgroundSize: 'contain',
													height: '90px',
													margin: '23px 20px 60px 20px',
													backgroundImage: profile.darkLogo
														? 'url("' + profile.darkLogo + '")'
														: ''
												}}
											>
												{!profile.darkLogo && (
													<h2 className="no-logo-text no-logo-text--dark">
														{this.lang.uploadLogo}
													</h2>
												)}
											</div>

											<div className="overlay">
												<Flex justifyContent="end">
													<ClickableItem
														icon="trash-o"
														iconColor="white"
														size="sm"
														borderRadius
														onClick={rootData.deleteImage.bind(null, 'darkLogo')}
														block={false}
														disabled={!profile.darkLogo}
													/>
												</Flex>
												<button
													type="button"
													className="btn up-btn btn-block btn-bright-blue no-shadow"
													style={{ marginTop: '8px' }}
													onClick={rootData.selectImage.bind(null, 'darkLogo')}
												>
													<b className="fa fa-pencil" />{' '}
													<span>
														{profile.darkLogo ? self.lang.change : self.lang.select}
													</span>
												</button>

												<button
													type="button"
													className="btn up-btn btn-block btn-bright-blue no-shadow"
													style={{ marginTop: '8px' }}
													onClick={rootData.setLogoUrl.bind(null, 'darkLogo')}
												>
													<b className="fa fa-url" /> <span>{self.lang.enterUrl}</span>
												</button>
											</div>
											<div className="package-box-footer lined">
												<div className="package-box-title">
													<h2>{self.lang.logoBrightBackground}</h2>
													<span className="text-grey" style={{ display: 'block' }}>
														{profile.darkLogo ? profile.darkLogo.split('/').pop() : ''}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="admin-section admin-card" id="colors-tab-content">
								<h2>
									{self.lang.colors} <ReactTemplates.elevio articleId={436} sidebar={true} />
								</h2>

								<div className="admin-info-row">
									<div className="info-row-info">{self.lang.colorInfo}</div>
									<div className="info-row-content">
										<div className="admin-table card-table">
											<table>{colors}</table>
										</div>
									</div>
								</div>

								<h2>
									{self.lang.extraColors} <ReactTemplates.elevio articleId={437} sidebar={true} />
								</h2>

								<div className="admin-info-row">
									<div className="info-row-info">{self.lang.extracolorInfo}</div>
									<div className="info-row-content">
										<div>
											{extraColors}

											{ReactTemplates.TOOLS.withTooltip(
												<button
													type="button"
													className="color-ball add"
													onClick={self.showEditColor}
												>
													<b className="fa fa-plus" />
												</button>,
												self.lang.add,
												{ placement: 'bottom' }
											)}
										</div>

										{self.state.addingColor ? (
											<div style={{ marginTop: '15px' }}>
												<div className="pull-left">
													{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
														value: self.state.colorToAdd,
														isText: false,
														presets: colorVariables,
														onChange: _.debounce(self.colorToAddChange, 200)
													})}
												</div>
												<div className="pull-right">
													<button
														type="button"
														className="up-btn btn-xs btn-green no-shadow"
														onClick={self.saveColor}
													>
														{self.lang.add}
													</button>
													<button
														type="button"
														className="up-btn btn-xs btn-link btn-bright-blue no-shadow"
														onClick={self.abortColorEdit}
													>
														{self.lang.abort}
													</button>
												</div>
												<div className="clearfix" />
											</div>
										) : null}
									</div>
								</div>
							</div>

							<CustomFonts
								fonts={profile.fonts || []}
								onChange={this.props.rootData.onFontsChanged}
								onRemove={this.props.rootData.onFontRemoved}
							/>

							<Typography
								fonts={profile.fonts || []}
								profile={profile}
								onMouseOver={self.highlightPreview}
								onMouseOut={self.highlightEnd}
								onTypoSelectChange={self.onTypoSelectChange}
								onColorChange={self.colorChange}
								onToggleTypography={rootData.toggleTypography}
								colorVariables={colorVariables}
							/>
						</div>
					</div>
					<div className={sidebarClassName} style={sidebarStyle} ref={self.setRef.bind(self, '_preview')}>
						<div className="header">
							<button onClick={this.closeSidebar}>
								<i className="fa fa-chevron-right header-icon" />
								<span className="header-text">{this.lang.hidePreview}</span>
							</button>
						</div>
						<div className="body">
							<div className="preview">
								<div>
									<label>{self.lang.landingPageExample}</label>
									{this.renderTypographyPreview(
										profile.colorsLandingPages,
										profile.typographyLandingPages,
										true
									)}
								</div>

								<label>{self.lang.mailExample}</label>
								{this.renderTypographyPreview(profile.colors, profile.typography)}

								{Tools.FeatureHelper.isAvailable('CUSTOMER_SUPPORT') ? (
									<>
										<label>{self.lang.supportEmailExample}</label>
										{this.renderSupportEmailPreview(
											this.props.rootData.pageData.profile,
											Tools.AppService.getSelf()
										)}
									</>
								) : null}

								<label>{self.lang.bannerExample}</label>

								<div className="fake-banner-wrap">
									<div className="fake-banner" style={{ height: 250, width: 300 }}>
										<iframe
											src=""
											border="0"
											scrolling="no"
											allowTransparency="true"
											width="100%"
											height="100%"
											ref={self.setRef.bind(self, '_banner1')}
										/>
									</div>
								</div>
								<div className="fake-banner-wrap">
									<div className="fake-banner" style={{ height: 600, width: 160 }}>
										<iframe
											src=""
											border="0"
											scrolling="no"
											allowTransparency="true"
											width="100%"
											height="100%"
											ref={self.setRef.bind(self, '_banner2')}
										/>
									</div>
									<div className="fake-banner" style={{ height: 600, width: 160 }}>
										<iframe
											src=""
											border="0"
											scrolling="no"
											allowTransparency="true"
											width="100%"
											height="100%"
											ref={self.setRef.bind(self, '_banner7')}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

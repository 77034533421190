import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import { ComparisionToggle } from 'Components/Inputs/ComparisonTypeToggle';
import { SelectTokens } from '@upsales/components';
import T from 'Components/Helpers/translate';
import _ from 'lodash';

export default class TextInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			trueText: T(this.props.config.inputTrueText || 'comparison.Equals'),
			falseText: T(this.props.config.inputFalseText || 'comparison.NotEquals')
		};

		this.onValuesChange = this.onChange.bind(this, 'values');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');
		this.useNew = Tools.FeatureHelper.hasSoftDeployAccess('NEW_SEGMENT_FILTERS');
	}

	setRef(name, r) {
		this[name] = r;
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'values':
				newFilter.value = value;
				break;
			case 'comperator':
				newFilter.comparisonType = value;
				break;
		}

		newFilter.inactive = !this.isFilterActive(newFilter);

		onChange(newFilter.value, newFilter);
	}

	getPlaceholder() {
		const { config } = this.props;

		if (config.filterName.indexOf('Custom') > -1) {
			return T('default.freeText');
		}

		switch (config.filterName) {
			case 'NameList':
			case 'EmailList':
			case 'NotesList':
			case 'NameClientList':
			case 'Address':
				return T('segment.placeholder.' + config.filterName);
			case 'Name':
				return T('segment.placeholder.NameList');
			default:
				return T('segment.selectCriteriaPlaceholder');
		}
	}

	componentWillUnmount() {
		if (this.useNew) {
			return;
		}
		const element = jQuery(this._input);
		element.select2('destroy');
		// Calling .off() with no arguments removes all handlers attached to the elements.
		element.off();
	}

	componentDidMount() {
		if (this.useNew) {
			return;
		}
		var options = {
			placeholder: this.props.placeholder || this.getPlaceholder(),
			allowClear: true,
			tags: [],
			separator: '|',
			dropdownCssClass: 'select2-dropdown-hidden',
			formatNoMatches: function () {
				return '';
			},
			createSearchChoice: function (term) {
				const trimmedTerm = (term || '').trim();

				if (trimmedTerm) {
					return { id: trimmedTerm, text: trimmedTerm };
				}
			},
			selectOnBlur: true
		};

		var self = this;

		jQuery(this._input)
			.select2(options)
			.on('change', e => self.onValuesChange(e.val));

		if (this.props.autoFocus) {
			jQuery(this._input).select2('focus');
		}
	}

	isFilterActive(filter) {
		return Array.isArray(filter.value) && filter.value.length ? true : false;
	}

	render() {
		const { filter } = this.props;
		// The select2-no-dropdown class can be removed when NEW_SEGMENT_FILTERS is released
		const classNames = this.isFilterActive(filter)
			? 'multi-comparitors-wrap select2-no-dropdown active'
			: 'multi-comparitors-wrap select2-no-dropdown';

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<div className={classNames}>
						<ComparisionToggle
							trueValue={this.props.trueValue}
							falseValue={this.props.falseValue}
							trueText={this.lang.trueText}
							falseText={this.lang.falseText}
							onClick={this.onComperatorChange}
							value={filter.comparisonType}
						/>
						{this.useNew ? (
							<SelectTokens
								value={filter.value?.map(id => ({ id })) || []}
								onChange={val => this.onValuesChange(val.map(v => v.id))}
								placeholder={this.props.placeholder || this.getPlaceholder()}
							/>
						) : (
							<input
								type="hidden"
								ref={this.setRef.bind(this, '_input')}
								name={this.props.name}
								disabled={this.props.disabled || null}
								required={this.props.required || null}
								className={this.props.className || 'form-control'}
								id={this.props.id || null}
								tabIndex={this.props.tabIndex || null}
								defaultValue={(filter.value || []).join('|')}
							/>
						)}
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

TextInput.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	tabIndex: PropTypes.number,
	autoFocus: PropTypes.bool,
	trueValue: PropTypes.string,
	falseValue: PropTypes.string
};

TextInput.defaultProps = {
	autoFocus: true,
	trueValue: 'Search',
	falseValue: 'NotSearch'
};

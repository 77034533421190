import React, { Component } from 'react';
import { Button } from '@upsales/components';
import t from 'Components/Helpers/translate';

type Props = {
	apiKey: {
		name: string;
		key: string;
	};
	actions: {
		save: () => void;
		close: () => void;
		onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
		copy: () => void;
	};
	edit: boolean;
	copied: boolean;
};

class EditApikey extends Component<Props> {
	lang: { [key: string]: string };
	constructor(p: Props) {
		super(p);

		this.lang = {
			title: t('admin.apiKey.modal.create'),
			description: t('admin.apiKey.modal.description'),
			close: t('default.close'),
			generate: t('admin.apiKey.generate'),
			name: t('default.name'),
			namePlaceholder: t('admin.apiKey.namePlaceholder'),
			key: t('default.key'),
			clickToCopy: t('default.clickToCopy'),
			copied: t('default.copiedToClipboard')
		};
	}

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			this.props.actions.save();
		}
	};

	render() {
		let rightSideContent, wrapClasses;

		if (this.props.edit) {
			wrapClasses = 'column-wrap';
			rightSideContent = (
				<div className="generate-button-wrap">
					<Button onClick={this.props.actions.save} disabled={!this.props.apiKey.name} block color="white">
						{this.lang.generate}
					</Button>
				</div>
			);
		} else {
			wrapClasses = 'column-wrap edit';
			rightSideContent = (
				<div className="display-key-wrap">
					<label>{this.lang.key}</label>
					<div className="key-container">{this.props.apiKey.key}</div>
					<button className="btn up-btn no-shadow copy-button" onClick={this.props.actions.copy}>
						{this.props.copied ? this.lang.copied : this.lang.clickToCopy}
					</button>
				</div>
			);
		}

		return (
			<div className={wrapClasses}>
				<a className="btn btn-link btn-link--gray modal-close" onClick={this.props.actions.close}>
					<i className="fa fa-times"></i>
				</a>
				<div className="left-column">
					<div className="header">
						<i className="fa fa-key" aria-hidden="true"></i>
						<span className="title">{this.lang.title}</span>
					</div>
					<div className="body">{this.lang.description}</div>
					<div className="footer">
						<Button color="white" type="link" onClick={this.props.actions.close}>
							{this.lang.close}
						</Button>
					</div>
				</div>
				<div className="right-column">
					<div className="name-input-wrap">
						<label>{this.lang.name}</label>
						<input
							className="form-control name-input"
							placeholder={this.lang.namePlaceholder}
							onChange={this.props.actions.onNameChange}
							value={this.props.apiKey.name}
							onKeyPress={this.onKeyPress}
							disabled={!this.props.edit}
							maxLength={64}
						/>
					</div>
					{rightSideContent}
				</div>
			</div>
		);
	}
}

export default EditApikey;

var optInInfo = {
	'en-US': {
		email: {
			description: 'To complete the subscription process, please click the link below.',
			explanation:
				"This email was sent to {{Contact.email}}. If you didn't request this email you may just ignore it - you're only opted in, once the button has been clicked.",
			headline: 'Please confirm your subscription',
			acceptLink: 'I accept the terms'
		},
		landingpage: {
			description: "You are now subscribed to {{Company.name}}'s email list",
			headline: 'Thanks for subscribing!'
		},
		flag: 'gb'
	},
	'sv-SE': {
		email: {
			description: 'För att färdigställa prenumerationen, klicka på länken nedan.',
			explanation:
				'Det här emailet sändes till {{Contact.email}}. Om du inte önskade detta email kan du ignorera detta - du är endast in optad efter det att du klickat på knappen',
			headline: 'Var god acceptera prenumerationen',
			acceptLink: 'Jag accepterar vilkoren'
		},
		landingpage: {
			description: 'Du prenumererar nu på {{Company.name}}s emaillista.',
			headline: 'Tack för godkännandet!'
		},
		flag: 'se'
	}
};

var OptInInfoText = {
	translate: function (lang) {
		return optInInfo[lang] || optInInfo['en-US'];
	},
	getLangs: function () {
		return _.zipObject(
			Object.keys(optInInfo),
			_.map(optInInfo, function (land) {
				return land.flag;
			})
		);
	}
};

if (window && window.angular) {
	window.OptInInfoText = OptInInfoText;
}

'use strict';

ReactTemplates.bannerEditor.editorBackgroundElement = window.ReactCreateClass({
	savedBg: null,
	getInitialState: function () {
		return {
			background: this.props.background
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			setImage: t('bannerEditor.setImage'),
			background: t('bannerEditor.background'),
			changeImage: t('bannerEditor.changeImage'),
			opacity: t('bannerEditor.opacity'),
			useImage: t('bannerEditor.useImage'),
			blur: t('bannerEditor.blur')
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ background: nextProps.background });
	},
	bgImgToggle: function () {
		var background = this.state.background;
		var enabled = background.img === null;

		if (enabled) {
			background.img = this.savedBg ? this.savedBg : '';
		} else {
			this.savedBg = background.img;
			background.img = null;
		}
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	opacityChanged: function (value) {
		var background = this.state.background;
		background.imgOpacity = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	blurChanged: function (value) {
		var background = this.state.background;
		background.imgBlur = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	colorChange: function (value) {
		var background = this.state.background;
		background.color = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	browseImages: function () {
		var self = this;
		Tools.$upModal
			.open('fileBrowser', {
				types: ['image'],
				public: true,
				selectOnUpload: true,
				sizeLimit: 100000 // limit bg to 100k in size
			})
			.then(function (files) {
				var background = self.state.background;
				background.img = files[0].$$publicUrl;
				background.fileSize = files[0].size;
				self.setState({ background: background });
				self.props.backgroundChange(background);
			});
	},
	render: function () {
		var self = this;
		var previewStyle = { backgroundColor: self.state.background.color };
		var imgElem = null;

		if (self.state.background.img) {
			var style = {
				opacity: self.state.background.imgOpacity / 100,
				backgroundImage: "url('" + self.state.background.img + "')"
			};
			imgElem = <div id="img-element" style={style}></div>;
		}

		return (
			<div className="sidebar-section">
				<div className="sidebar-header">
					<h2>{self.lang.background}</h2>
					<div className="element-tools">
						{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
							value: self.state.background.color,
							isText: false,
							presets: self.props.colorVariables,
							onChange: self.colorChange
						})}
					</div>
				</div>
				<div className="section-row">
					<ReactTemplates.checkbox
						checked={self.state.background.img !== null}
						onChange={self.bgImgToggle.bind(self, 0)}
						className="toggle-bright-blue"
					/>{' '}
					{self.lang.useImage}
				</div>
				{self.state.background.img !== null
					? [
							<div className="section-row" key="img-row">
								<div id="img-preview" style={previewStyle}>
									{imgElem}

									<button className="up-btn btn-green no-shadow" onClick={self.browseImages}>
										{imgElem ? self.lang.changeImage : self.lang.setImage}
									</button>
								</div>
							</div>,
							<div className="section-row" key="opacity-row">
								<label>{self.lang.opacity}</label>
								<ReactTemplates.upStepInt
									min={0}
									max={100}
									value={self.state.background.imgOpacity}
									className="input-sm pull-right"
									onChange={self.opacityChanged}
								/>
							</div>
							// <div className='section-row' key='blur-row'>
							// 	<label>{self.lang.blur}</label>
							// 	<ReactTemplates.upStepInt min={0} max={100} value={self.state.background.imgBlur} className='input-sm pull-right' onChange={self.blurChanged} />
							// </div>
					  ]
					: null}
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import logError from 'App/babel/helpers/logError';
import { loader } from 'App/helpers/googleMapsLoader';

export default (Input, params = {}) => {
	class Autocomplete extends React.Component {
		componentDidMount() {
			const input = this.inputRef;
			loader
				.load()
				.then(() => {
					const self = Tools.AppService.getSelf();
					const hasSwedishTimezone = self?.userParams?.timeZone === 'Europe/Stockholm';
					const componentRestrictions = { country: ['se', 'no', 'dk', 'fi'] };

					// It was the best I could do.
					// There's supposed to be a locationBias param which should be able to figure out location based on IP.
					// Tried for hours without getting it to work...
					// https://developers.google.com/maps/documentation/places/web-service/autocomplete
					if (hasSwedishTimezone) {
						params.componentRestrictions = componentRestrictions;
					}

					const autocomplete = new google.maps.places.Autocomplete(input, params);
					autocomplete.addListener('place_changed', () => {
						const place = autocomplete.getPlace();
						if (place.geometry) {
							this.props.onChange(place.formatted_address, place);
						} else {
							// Set first suggestion
							const autocompleteService = new google.maps.places.AutocompleteService();
							autocompleteService.getPlacePredictions({ input: input.value, ...params }, list => {
								if (list || list.length > 0) {
									const placesService = new google.maps.places.PlacesService(input);
									placesService.getDetails({ reference: list[0].reference }, place => {
										this.props.onChange(place.formatted_address, place);
									});
								}
							});
						}
					});
				})
				.catch(e => logError(e, 'Failed to load Google'));
		}
		render() {
			const { onChange, ...props } = this.props;
			return (
				<Input
					inputRef={r => (this.inputRef = r)}
					onChange={e => {
						if (e.target) {
							onChange(e.target.value);
						}
					}}
					{...props}
				/>
			);
		}
	}
	Autocomplete.propTypes = {
		onChange: PropTypes.func.isRequired
	};
	Autocomplete.defaultProps = {
		onChange: () => {}
	};
	return Autocomplete;
};

import { Text, Button, Tooltip } from '@upsales/components';
import { makeCancelable } from 'App/babel/helpers/promise';
import LimitSelect from 'Components/LimitSelect';
import Confirm from 'Components/Modals/Confirm';
import openModal from 'App/services/Modal';
import {
	getCountryFromProspectingId,
	getDefaultCurrencyFromProspectingId,
	getDefaultIndustryFieldFromCountry
} from 'App/services/prospectingService';

ReactTemplates.soliditetMatcher = {};

ReactTemplates.soliditetMatcher.row = window.ReactCreateClass({
	getInitialState: function () {
		return {
			action: null,
			matchAction: null,
			client: this.props.client,
			filter: this.props.filter,
			saving: false
		};
	},

	getType: function (client) {
		if (client.id) {
			return 'upsales';
		}
		if (client.prospectingId) {
			return 'prospecting';
		}
		if (client.dunsNo) {
			return 'soliditet';
		}
		return null;
	},

	getId: function (client) {
		const type = this.getType(client);
		switch (type) {
			case 'upsales':
				return client.id;
			case 'soliditet':
				return client.dunsNo;
			case 'prospecting':
				return client.prospectingId;
			default:
				return undefined;
		}
	},

	UNSAFE_componentWillMount: function () {
		this.calculateStuff();
		this.setState({ action: null });
		var client = this.state.client;
		if (client.soliditetMatch || client.upsalesMatch || client.prospectingMatch) {
			this.match();
		}
		var t = Tools.$translate;
		this.lang = {
			turnover: t('default.turnover'),
			employees: t('default.employees'),
			action: {
				delete: t('soliditet.matcher.delete'),
				hide: t('soliditet.matcher.hide'),
				merge: t('soliditet.matcher.merge')
			},
			multipleMatches: t('soliditet.matcher.multipleMatches'),
			noMatches: t('soliditet.matcher.noMatches'),
			select2Soliditet: t('soliditet.matcher.select2Soliditet'),
			select2Prospecting: t('soliditet.matcher.select2Prospecting'),
			select2Upsales: t('soliditet.matcher.select2Upsales'),
			missingDeleteAccess: t('soliditet.matcher.missingDeleteAccess')
		};
	},

	formatTurnover: function (client) {
		const formatCurrency = Tools.$filter('currencyFormat');
		const type = this.getType(client);
		switch (type) {
			case 'soliditet':
				return formatCurrency(client.turnover, 'SEK');
			case 'prospecting': {
				const currency = getDefaultCurrencyFromProspectingId(client.prospectingId);
				return formatCurrency(client.revenue, currency);
			}
			case 'upsales': {
				const currency = getDefaultCurrencyFromProspectingId(client.prospectingId);
				return formatCurrency(client.turnover, currency);
			}
		}
	},

	formatOrgNumber: function (orgNo) {
		if (!orgNo) {
			return '';
		}
		if (orgNo) {
			orgNo = orgNo + '';
		}
		if (orgNo && orgNo.length === 10) {
			orgNo = orgNo.substring(0, 6) + '-' + orgNo.substring(6, 10);
		}
		return orgNo;
	},

	formatSoliditetRow: function (client) {
		const turnover = this.formatTurnover(client);
		const orgNo = this.formatOrgNumber(client.orgNo);
		const hideTurnover = !client.turnover ? ' hide' : '';
		const hideEmployees = !client.noOfEmployeesExact ? ' hide' : '';
		const flag = client.country
			? '<i style="padding-top:1px" class="icon flag-icon flag-icon-' + client.country.toLowerCase() + '"></i>'
			: '';
		const html =
			'' +
			'<div class="soliditet-matcher-dropdown-format">' +
			'<div>' +
			client.name +
			(client.isHeadqueraters ? ' <i class="fa fa-home"></i>' : '') +
			'<span class="pull-right text-grey org-no">' +
			orgNo +
			'</span></div>' +
			'<div class="sni-text">' +
			client.sniText +
			'</div>' +
			'<div class="other-info">' +
			'<span>' +
			flag +
			' ' +
			client.city +
			'</span>' +
			'<span class="turnover' +
			hideTurnover +
			'">' +
			turnover +
			'</span>' +
			'<div class="employees' +
			hideEmployees +
			'"><i class="fa fa-user"></i>' +
			client.noOfEmployeesExact +
			'</div>' +
			'</div>' +
			'</div>';

		return html;
	},

	formatProspectingRow: function (client) {
		const turnover = this.formatTurnover(client);
		const orgNo = this.formatOrgNumber(client.orgNumber);
		const hideTurnover = !client.revenue ? ' hide' : '';
		const hideEmployees = !client.noEmployees ? ' hide' : '';
		const country = getCountryFromProspectingId(client.prospectingId);
		const industryField = getDefaultIndustryFieldFromCountry(country);
		const staticValue = Tools.AppService.getStaticValue(industryField, client[industryField]);
		const town = client.visitingTown || client.postTown || client.registeredTown || '';
		const html =
			'' +
			'<div class="soliditet-matcher-dropdown-format">' +
			'<div>' +
			client.name +
			(client.headquarters ? ' <i class="fa fa-home"></i>' : '') +
			'<span class="pull-right text-grey org-no">' +
			orgNo +
			'</span></div>' +
			'<div class="sni-text">' +
			(staticValue ? staticValue.name : '') +
			'</div>' +
			'<div class="other-info">' +
			'<span>' +
			'<i style="padding-top:1px" class="icon flag-icon flag-icon-' +
			country.toLowerCase() +
			'"></i>' +
			' ' +
			town +
			'</span>' +
			'<span class="turnover' +
			hideTurnover +
			'">' +
			turnover +
			'</span>' +
			'<div class="employees' +
			hideEmployees +
			'"><i class="fa fa-user"></i>' +
			client.noEmployees +
			'</div>' +
			'</div>' +
			'</div>';

		return html;
	},

	formatUpsalesRow: function (client) {
		var orgNo = this.formatOrgNumber(client.orgNo);
		var reactCRM = (
			<ReactTemplates.TOOLS.leadStatus
				theKey={client.id}
				tools={this.props.tools}
				activity={client.hasActivity}
				activityOld={client.hadActivity}
				appointment={client.hasAppointment}
				appointmentOld={client.hadAppointment}
				opportunity={client.hasOpportunity}
				opportunityOld={client.hadOpportunity}
				order={client.hasOrder}
				orderOld={client.hadOrder}
			/>
		);
		var reactMarketing = <ReactTemplates.TOOLS.eventTypes tools={this.props.tools} account={client} />;
		var crmIcons = window.renderToString(reactCRM);
		var marketingIcons = window.renderToString(reactMarketing);

		var flag = client.countryCode
			? '<i style="padding-top:1px" class="icon flag-icon flag-icon-' +
			  client.countryCode.toLowerCase() +
			  '"></i>'
			: '';

		var adressWithCity = _.find(client.addresses, 'city');
		var city = adressWithCity && adressWithCity.city;

		var html =
			'' +
			'<div class="soliditet-matcher-dropdown-format">' +
			'<div>' +
			client.name +
			'<span class="pull-right text-grey org-no">' +
			orgNo +
			'</span></div>' +
			'<div class="sni-text">' +
			(client.sniText ? client.sniText : ' ') +
			'</div>' +
			'<div class="other-info">' +
			'<span>' +
			flag +
			' ' +
			(city ? city : ' ') +
			'</span>' +
			'<div class="pull-right">' +
			crmIcons +
			'<span> </span>' +
			marketingIcons +
			'</div>' +
			'</div>' +
			'</div>';

		return html;
	},

	componentDidMount: function () {
		if (this._clientInput) {
			var self = this;
			var client = this.state.client;
			var soliditetData =
				client.soliditetMatches && client.soliditetMatches.length ? client.soliditetMatches : [];
			var upsalesData = client.upsalesMatches && client.upsalesMatches.length ? client.upsalesMatches : [];
			var prospectingData =
				client.prospectingMatches && client.prospectingMatches.length ? client.prospectingMatches : [];
			var queryString = '';

			var options = {
				data: [],
				id: function (client) {
					return self.getId(client);
				},
				ajax: {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						queryString = query.data ? query.data.toLowerCase() : '';
						if (queryString === '') {
							return query.success({ data: [] });
						}

						return self.props.rootData
							.clientSearch(client.id, queryString, client.countryCode)
							.then(function (res) {
								query.success(res);
							});
					},
					results: function (res) {
						const upsalesResult = queryString.length ? res.upsales : upsalesData;
						const prospectingResult = queryString.length ? res.prospecting : prospectingData;
						const soliditetResult = queryString.length ? res.soliditet : soliditetData;
						const results = [];

						if (upsalesResult.length) {
							results.push({ name: self.lang.select2Upsales, children: upsalesResult });
						}
						if (prospectingResult.length) {
							results.push({ name: self.lang.select2Prospecting, children: prospectingResult });
						}
						if (soliditetResult.length) {
							results.push({ name: self.lang.select2Soliditet, children: soliditetResult });
						}

						return { results };
					}
				},
				formatResult: function (client) {
					const type = self.getType(client);

					switch (type) {
						case 'upsales':
							return self.formatUpsalesRow(client);
						case 'prospecting':
							return self.formatProspectingRow(client);
						case 'soliditet':
							return self.formatSoliditetRow(client);
						default:
							return client.name;
					}
				}
			};

			var jqField = jQuery(this._clientInput);
			jqField.select2(options).on('change', function (e) {
				jqField.select2('close');
				jqField.select2('val', '');

				client.upsalesMatch = undefined;
				client.soliditetMatch = undefined;
				client.prospectingMatch = undefined;

				const type = self.getType(e.added);

				switch (type) {
					case 'upsales':
						client.upsalesMatch = e.added;
						break;
					case 'prospecting':
						client.prospectingMatch = e.added;
						break;
					case 'soliditet':
						client.soliditetMatch = e.added;
						break;
				}

				self.setState({ client });
				self.match();
			});
		}
	},

	UNSAFE_componentWillUpdate: function () {
		this.calculateStuff();
	},

	componentWillUnmount() {
		if (this._updatePromise) {
			this._updatePromise.cancel();
		}
	},

	UNSAFE_componentWillReceiveProps: function (newProps) {
		const client = newProps.client;
		client.countryCode =
			getCountryFromProspectingId(client.prospectingId) ?? _.find(client.addresses, 'country')?.country ?? null;
		this.setState({ client });
	},

	calculateStuff: function () {
		var client = this.state.client;
		var numberOfMatches =
			(client.soliditetMatches && client.soliditetMatches.length) +
			(client.upsalesMatches && client.upsalesMatches.length) +
			(client.prospectingMatches && client.prospectingMatches.length);
		this.hasMultipleMatches = numberOfMatches > 1;
		this.hasNoMatches = !client.soliditetMatch && !client.upsalesMatch && !client.prospectingMatch;
	},

	match: function () {
		if (this.hasNoMatches) {
			this.showDropdown();
			return;
		}

		const client = this.state.client;
		let value;
		let currentAction;
		if (client.prospectingMatch) {
			currentAction = 'updateProspecting';
			value = { action: currentAction, id: client.id, prospectingId: client.prospectingMatch.prospectingId };
		} else if (client.soliditetMatch) {
			currentAction = 'buy';
			value = { action: currentAction, id: client.id, dunsNo: client.soliditetMatch.dunsNo };
		} else {
			currentAction = 'merge';
			value = { action: currentAction, keep: client.upsalesMatch.id, merge: client.id };
		}

		this.props.onChange(this.state.client.id, value);
		this.setState({ action: 'match' });
		this.setState({ matchAction: currentAction });
	},

	onMatchButtonClick: function () {
		const client = this.state.client;

		if (this.state.action === 'match') {
			this.setState({ action: null });
			this.props.onChange(this.state.client.id, undefined);
			client.upsalesMatch = undefined;
			client.soliditetMatch = undefined;
			client.prospectingMatch = undefined;
		} else {
			this.match();
		}
	},

	hide: function () {
		this.state.client.upsalesMatch = undefined;
		this.state.client.soliditetMatch = undefined;
		this.state.client.prospectingMatch = undefined;
		if (this.state.action === 'hide') {
			this.setState({ action: null });
			this.props.onChange(this.state.client.id, undefined);
			return;
		}
		this.props.onChange(this.state.client.id, { action: 'hide', id: this.state.client.id });
		this.setState({ action: 'hide' });
	},

	delete: function () {
		this.state.client.upsalesMatch = undefined;
		this.state.client.soliditetMatch = undefined;
		this.state.client.prospectingMatch = undefined;
		if (this.state.action === 'delete') {
			this.setState({ action: null });
			this.props.onChange(this.state.client.id, undefined);
			return;
		}
		this.props.onChange(this.state.client.id, {
			action: 'delete',
			id: this.state.client.id,
			name: this.state.client.name
		});
		this.setState({ action: 'delete' });
	},

	setRef: function (key, ref) {
		this[key] = ref;
	},

	showDropdown: function () {
		if (this._clientInput) {
			jQuery(this._clientInput).select2('open');
		}
	},

	getMatchColumnContent: function () {
		var client = this.state.client;
		var numberOfMatches =
			client.upsalesMatches.length + client.soliditetMatches.length + client.prospectingMatches.length;

		if (client.prospectingMatch) {
			const prospectingClient = client.prospectingMatch;
			const turnover = this.formatTurnover(prospectingClient);
			const town =
				prospectingClient.visitingTown ||
				prospectingClient.postTown ||
				prospectingClient.registeredTown ||
				null;
			const country = getCountryFromProspectingId(prospectingClient.prospectingId) ?? 'SE';

			return (
				<div className="soliditet-match">
					<div>
						<span className="bold">{prospectingClient.name}</span>
					</div>
					<div>
						<div className="city">
							<i className={'icon flag-icon flag-icon-' + country.toLowerCase()} /> {town}
						</div>
						<div className="turnover">
							<span className="bold">{this.lang.turnover + ':'}</span>{' '}
							{prospectingClient.revenue ? turnover : '-'}
						</div>
						<div className="employees">
							<span className="bold">{this.lang.employees + ':'}</span>{' '}
							{prospectingClient.noEmployees || '-'}
						</div>
					</div>
				</div>
			);
		} else if (client.soliditetMatch) {
			const turnover = this.formatTurnover(client.soliditetMatch);
			const flagClasses = client.country ? 'icon flag-icon flag-icon-' + client.country.toLowerCase() : '';

			return (
				<div className="soliditet-match">
					<div>
						<span className="bold">{client.soliditetMatch.name}</span>
					</div>
					<div>
						<div className="city">
							<i className={flagClasses} /> {client.soliditetMatch.city}
						</div>
						<div className="turnover">
							<span className="bold">{this.lang.turnover + ':'}</span> {turnover}
						</div>
						<div className="employees">
							<span className="bold">{this.lang.employees + ':'}</span>{' '}
							{client.soliditetMatch.noOfEmployeesExact}
						</div>
					</div>
				</div>
			);
		} else if (client.upsalesMatch) {
			return (
				<div className="upsales-match">
					<i className="fa fa-exchange" /> {this.lang.action.merge} {this.getAccountHref(client.upsalesMatch)}
				</div>
			);
		} else if (this.state.action === 'delete') {
			return (
				<div className="text-red">
					<i className="fa fa-trash" /> {this.lang.action.delete}
				</div>
			);
		} else if (this.state.action === 'hide' || client.dunsNo === '-1') {
			return (
				<div className="text-dark-grey">
					<i className="fa fa-eye-slash" /> {this.lang.action.hide}
				</div>
			);
		} else if (this.hasMultipleMatches) {
			return (
				<div>
					<i className="fa fa-home" /> {numberOfMatches} {this.lang.multipleMatches}
				</div>
			);
		} else {
			return (
				<div className="no-matches">
					<i className="fa fa-question-circle" /> {this.lang.noMatches}
				</div>
			);
		}
	},

	getAccountHref: function (client) {
		var accountHref = '#/' + this.props.tools.customerId + '/accounts/' + client.id + '/';
		return <a href={accountHref}>{client.name}</a>;
	},

	getClientColumn: function () {
		var client = this.state.client;
		var hasUsers = client.users && client.users.length;
		var userClasses = 'fa fa-user' + (hasUsers ? ' text-bright-blue' : '');

		var accountManagers = '';
		var accountManagersTooltip = '';
		if (hasUsers) {
			var userName = client.users[0].name;
			var userNameSplit = userName.split(' ');
			accountManagers = userName.substring(0, 1);
			if (userNameSplit.length > 1) {
				accountManagers += userNameSplit[1].substring(0, 1);
			}
			if (client.users.length > 1) {
				accountManagers += ' + ' + (client.users.length - 1);
			}
			accountManagersTooltip = _.pluck(client.users, 'name').join(', ');
		}

		var accountManagerComponent = (
			<div className="icon-wrapper">
				<i className={userClasses} />
				<span className="text-bright-blue"> {accountManagers}</span>
			</div>
		);
		if (hasUsers) {
			accountManagerComponent = ReactTemplates.TOOLS.withTooltip(
				accountManagerComponent,
				accountManagersTooltip,
				{ placement: 'top' }
			);
		}

		return (
			<div className="position">
				<span>{this.getAccountHref(client)}</span>
				<div className="icons">
					<ReactTemplates.TOOLS.leadStatus
						theKey={client.id}
						tools={this.props.tools}
						activity={client.hasActivity}
						activityOld={client.hadActivity}
						appointment={client.hasAppointment}
						appointmentOld={client.hadAppointment}
						opportunity={client.hasOpportunity}
						opportunityOld={client.hadOpportunity}
						order={client.hasOrder}
						orderOld={client.hadOrder}
					/>{' '}
					<ReactTemplates.TOOLS.eventTypes tools={this.props.tools} account={client} />
					{accountManagerComponent}
				</div>
			</div>
		);
	},

	render: function () {
		var self = this;
		var client = this.state.client;

		var address = _.find(client.addresses, 'city');
		var city = address && address.city ? address.city : ' ';

		var whiteBackground =
			this.state.action === 'match' || (this.state.action === null && client.dunsNo !== '-1')
				? ' white-background'
				: '';
		var greyBackground = client.dunsNo === '-1' || this.state.action !== null ? ' grey-background' : '';
		var matchBackground = client.dunsNo !== '-1' && this.state.action === null ? ' blue-background' : '';
		matchBackground = this.state.action === 'match' ? ' white-background' : matchBackground;

		var nameClasses = 'column name-col ' + whiteBackground;
		var cityClasses = 'column city-col ' + whiteBackground;
		var matchClasses = 'column match-col ' + matchBackground;

		var actionMatchClasses = 'column action-col match-button ' + matchBackground;
		var actionHideClasses = 'column action-col hide-button ' + matchBackground;
		var actionRemoveClasses = 'column action-col remove-button ' + matchBackground;
		var innerMatchClasses = 'inner ' + (this.state.action === 'match' ? ' selected' : '') + greyBackground;
		var innerHideClasses =
			'inner ' +
			((this.state.action === null && client.dunsNo === '-1') || this.state.action === 'hide'
				? ' selected'
				: '') +
			greyBackground;
		var innerDeleteClasses = 'inner ' + (this.state.action === 'delete' ? ' selected' : '') + greyBackground;

		var flagClasses = client.countryCode ? 'icon flag-icon flag-icon-' + client.countryCode.toLowerCase() : '';
		var actualCountry = client.countryCode ? this.props.tools.CountryCodes.get(client.countryCode) : '';
		actualCountry = actualCountry && actualCountry.name;
		var flag = ReactTemplates.TOOLS.withTooltip(<i className={flagClasses} />, actualCountry, { placement: 'top' });

		var input = <input type="hidden" ref={this.setRef.bind(this, '_clientInput')} />;

		return (
			<tr className="table-row">
				<td className={nameClasses}>{self.getClientColumn()}</td>
				<td className={cityClasses}>{this.formatOrgNumber(client.orgNo)}</td>
				<td className={cityClasses}>
					{flag} {city}
				</td>
				<td className={matchClasses} onClick={this.showDropdown}>
					<div className="select-div">
						{input}
						{self.getMatchColumnContent()}
					</div>
				</td>
				<td className={actionMatchClasses}>
					<div className={innerMatchClasses} onClick={self.onMatchButtonClick}>
						<i className="fa fa-check" />
					</div>
				</td>
				<td className={actionHideClasses}>
					<div className={innerHideClasses} onClick={self.hide}>
						<i className="fa fa-eye-slash" />
					</div>
				</td>
				<td className={actionRemoveClasses}>
					<Tooltip disabled={client.userRemovable} title={self.lang.missingDeleteAccess}>
						<div className={innerDeleteClasses} onClick={client.userRemovable ? self.delete : () => {}}>
							<i className="fa fa-trash" />
						</div>
					</Tooltip>
				</td>
			</tr>
		);
	}
});

ReactTemplates.soliditetMatcher.root = window.ReactCreateClass({
	model: {},
	ignoreIds: [],

	getInitialState: function () {
		return {
			showInfo: true,
			showFilter: false,
			actionMade: false,
			filter: {
				showMineOnly: false,
				showHidden: false
			},
			updateAllConfirmVisible: false
		};
	},

	doSubmit: function () {
		this.setState({ saving: true });
		this._updatePromise = makeCancelable(this.props.rootData.submit(this.model));

		this._updatePromise.promise
			.then(() => {
				if (this.ignoreIds.length > 300) {
					this.ignoreIds = [];
				}

				this.ignoreIds.push(_.keys(this.model));
				var filter = {
					showMineOnly: this.state.filter.showMineOnly,
					showHidden: this.state.filter.showHidden,
					ignoreIds: this.ignoreIds
				};
				this.props.rootData.getMatches(filter);
				this.model = {};
			})
			.catch(e => console.log('failed to match companies', e))
			.finally(() => {
				this.setState({ saving: false });
			});
	},

	submit: function () {
		var self = this;
		var clientsToDelete = _.filter(self.model, { action: 'delete' });

		if (clientsToDelete && clientsToDelete.length) {
			var body = self.lang.removeClientsConfirm + ':<br><br>';
			_.forEach(clientsToDelete, function (client) {
				body += '- ' + client.name + '<br>';
			});
			body += '<br>';

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.confirm',
					body: (
						<p>
							{self.lang.removeClientsConfirm}
							{':'}
							<br />
							<br />
							{clientsToDelete.map(client => (
								<p key={client.id}>
									{'- '}
									{client.name}
									<br />
								</p>
							))}
						</p>
					),
					headerIcon: 'warning',
					confirmButtonText: 'OK',
					onClose: async confirmed => {
						if (confirmed) {
							self.doSubmit();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			self.props.tools.$upModal
				.open('infoConfirm', {
					title: 'default.confirm',
					body: body,
					resolveTrue: 'Ok',
					icon: 'fa-warning'
				})
				.then(function () {
					self.doSubmit();
				});
		} else {
			self.doSubmit();
		}
	},

	onChange: function (id, value) {
		if (value) {
			this.model[id] = value;
		} else {
			delete this.model[id];
		}

		var modelChanged = _.keys(this.model).length > 0;
		if (modelChanged !== this.state.actionMade) {
			this.setState({ actionMade: modelChanged });
		}
	},

	toggleInfo: function () {
		this.setState({ showInfo: !this.state.showInfo });
	},

	toggleFilter: function () {
		this.setState({ showFilter: !this.state.showFilter });
	},

	closeFilter: function () {
		this.setState({ showFilter: false });
	},

	setLimit: function (limit) {
		this.props.rootData.setLimit(limit);
		this.model = {};
		this.props.rootData.getMatches(this.state.filter);
	},

	applyAccountFilter: function () {
		var filter = {
			showMineOnly: !this.state.filter.showMineOnly,
			showHidden: this.state.filter.showHidden
		};
		this.setState({ filter: filter });
		this.model = {};

		this.props.rootData.getMatches(filter);
	},

	applyHiddenFilter: function () {
		var filter = {
			showMineOnly: this.state.filter.showMineOnly,
			showHidden: !this.state.filter.showHidden
		};
		this.setState({ filter: filter });
		this.model = {};

		this.props.rootData.getMatches(filter);
	},

	UNSAFE_componentWillMount: function () {
		this.model = {};
		this.ignoreIds = [];

		var t = Tools.$translate;
		this.lang = {
			noName: t('default.noName'),
			soliditetMatcher: t('soliditet.matcher.soliditetMatcher'),
			hiddenMatches: t('soliditet.matcher.hiddenMatches'),
			removeClientsConfirm: t('confirm.removeClientsConfirm'),
			campaign: t('soliditet.matcher.campaign'),
			filterButton: t('soliditet.matcher.filterButton'),
			infoTitle: t('soliditet.matcher.infoTitle'),
			infoIngress: t('soliditet.matcher.infoIngress'),
			infoReadMore: t('soliditet.matcher.infoReadmore'),
			submit: t('soliditet.matcher.submit'),
			column: {
				name: t('soliditet.matcher.name'),
				orgNo: t('default.orgNumber'),
				city: t('soliditet.matcher.city'),
				match: t('soliditet.matcher.match'),
				actionMatch: t('soliditet.matcher.actionMatch'),
				actionHide: t('soliditet.matcher.actionHide'),
				actionDelete: t('soliditet.matcher.actionDelete')
			},
			allDone: t('soliditet.matcher.allDone'),
			allDone2: t('soliditet.matcher.allDone2'),
			allDoneButton: t('soliditet.matcher.allDoneButton'),
			filter: {
				accountManager: t('soliditet.matcher.accountManager'),
				hidden: t('soliditet.matcher.hidden')
			},
			updateAll: t('soliditet.matcher.updateAll'),
			updateAllConfirmation: t('soliditet.matcher.updateAllConfirmation'),
			updateAllButtonDisabled: t('soliditet.matcher.updateAllButtonDisabled')
		};

		// Load data
		this.props.rootData.getMatches(this.state.filter);
	},

	goToFindProspects: function () {
		var self = this;
		self.props.tools.$state.go('findProspects');
	},

	getListView: function (rows) {
		var self = this;
		var buttonActive = this.state.actionMade;

		return (
			<div>
				{this.props.rootData.tableLoading && (
					<div className="spinner-wrapper">
						<b className="fa fa-spin fa-refresh spinner " />
					</div>
				)}

				{this.state.showInfo && !this.props.rootData.tableLoading && (
					<div className="card-content container">
						<div className="header-box row">
							<div className="text">
								<h2> {self.lang.infoTitle}</h2>
								<div className="info">
									{' '}
									{self.lang.infoIngress}{' '}
									<ReactTemplates.elevio
										articleId="253"
										text={self.lang.infoReadMore}
										sidebar="true"
									/>
								</div>
							</div>
							<div className="icon">
								<div className="img" />
							</div>
						</div>
						<i className="fa fa-times cross" onClick={self.toggleInfo} />
					</div>
				)}

				{!this.props.rootData.tableLoading && (
					<table className="main-table">
						<thead className="table-header">
							<tr>
								<th className="name-col">{self.lang.column.name}</th>
								<th className="orgNo-col">{self.lang.column.orgNo}</th>
								<th className="city-col">{self.lang.column.city}</th>
								<th className="match-col">{self.lang.column.match}</th>
								<th className="action-col">{self.lang.column.actionMatch}</th>
								<th className="action-col">{self.lang.column.actionHide}</th>
								<th className="action-col last">{self.lang.column.actionDelete}</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				)}
				<div className="footer-space" />
				{!this.props.rootData.tableLoading && (
					<div className="footer footer--removeFrame">
						<Button
							size="sm"
							color={buttonActive ? 'green' : 'grey'}
							disabled={!buttonActive}
							onClick={this.submit}
							loading={this.state.saving}
						>
							{this.lang.submit}
						</Button>
						<LimitSelect
							setLimit={self.setLimit}
							limit={this.props.rootData.limit}
							limits={[25, 50, 100]}
						/>
					</div>
				)}
			</div>
		);
	},

	getAchievementView: function () {
		var self = this;

		return (
			<div id="card-main-view">
				<div className="card-content">
					<div className="placeholder">
						<div className="inner-wrap">
							<img src="img/up-finish-line.png" />
							<h2>{self.lang.allDone}</h2>
							<span>{self.lang.allDone2}</span>
							<div className="btn-inner-wrap">
								<button
									type="button"
									className="up-btn btn-bright-blue"
									onClick={self.goToFindProspects}
								>
									{self.lang.allDoneButton}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	},

	getFilterDom: function () {
		var filterClass = 'filter' + (this.state.showFilter ? ' show' : '');
		var hiddenToggleClass = this.state.showFilter ? 'hidden-toggle show' : '';

		return (
			<div>
				<div className={hiddenToggleClass} onClick={this.closeFilter} />
				<div className={filterClass}>
					<span className="toggle" onClick={this.toggleFilter}>
						{this.lang.filterButton}
					</span>
					{this.state.showFilter && (
						<div className="dropdown">
							<div className="hide-shit" />
							<div className="option">
								<span className="option-text">{this.lang.filter.accountManager}</span>
								<div className="option-toggle">
									<ReactTemplates.upFilters.components.toggle
										checked={this.state.filter.showMineOnly}
										onChange={this.applyAccountFilter.bind(this)}
										className="toggle-bright-blue"
									/>
								</div>
							</div>
							<div className="option">
								<span className="option-text">{this.lang.filter.hidden}</span>
								<div className="option-toggle">
									<ReactTemplates.upFilters.components.toggle
										checked={this.state.filter.showHidden}
										onChange={this.applyHiddenFilter.bind(this)}
										className="toggle-bright-blue"
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	},

	formatNumber: function (number) {
		if (typeof number !== 'number') {
			return '';
		}

		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},

	render: function () {
		var self = this;
		var clients = this.props.rootData.data;
		var totalMatches = this.props.rootData.totalMatches;
		var totalHidden = this.props.rootData.totalHidden;
		var isLoading = this.props.rootData.tableLoading;
		var title = this.props.rootData.campaign
			? this.lang.campaign + ' ' + this.props.rootData.campaign.name
			: this.lang.soliditetMatcher;

		var rows = _.map(clients, function (client) {
			client.name = client.name ? client.name : self.lang.noName;
			return React.createElement(ReactTemplates.soliditetMatcher.row, {
				client: client,
				onChange: self.onChange,
				tools: self.props.tools,
				rootData: self.props.rootData,
				filter: self.state
			});
		});

		return (
			<div id="soliditet-matcher">
				<div className="up-header up-header-sm border-top fixed">
					<div id="title">
						<h2>
							{title + ':'} {self.formatNumber(totalMatches)}
						</h2>{' '}
						<Text color="grey-10" loading={isLoading}>
							{'(' + totalHidden} {this.lang.hiddenMatches + ')'}
						</Text>{' '}
						{!this.state.showInfo && (
							<i className="fa fa-info-circle info-circle" onClick={self.toggleInfo} />
						)}
					</div>
					{!this.props.rootData.tableLoading ? (
						<>
							<div id="bulk-wash-button">
								<Tooltip
									disabled={!this.props.rootData.ongoingMatchAllJob}
									title={this.lang.updateAllButtonDisabled}
								>
									<Button
										size="sm"
										color="green"
										disabled={this.props.rootData.ongoingMatchAllJob}
										onClick={() => this.setState({ updateAllConfirmVisible: true })}
									>
										{this.lang.updateAll}
									</Button>
								</Tooltip>
							</div>
							{this.state.updateAllConfirmVisible ? (
								<Confirm
									title={'soliditet.matcher.updateAllButtonTitle'}
									color={'green'}
									visible={true}
									onConfirm={() => {
										this.setState({ updateAllConfirmVisible: false });
										this.props.rootData.matchAll();
									}}
									onAbort={() => this.setState({ updateAllConfirmVisible: false })}
									yes={'soliditet.matcher.updateAllButton'}
									abort={'default.abort'}
								>
									{this.lang.updateAllConfirmation}
								</Confirm>
							) : null}
						</>
					) : null}
					{self.getFilterDom()}
				</div>

				{totalMatches === 0 && self.getAchievementView()}
				{(isLoading || totalMatches > 0) && self.getListView(rows)}
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import './CalendarWrapper.scss';
import { CalendarItem } from './CalendarItem';
import CalendarItemMonthView from './CalendarItemMonthView';
import { Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { sortBy } from 'lodash';

class CalendarDayWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAllAppointments: false
		};
	}

	handleShowAppointments = showAllAppointments => () => {
		this.setState({ showAllAppointments });
	};

	handleShowFullDay = e => {
		e.stopPropagation();

		this.props.handleShowFullDay(moment(this.props.date).toJSON());
	};

	render() {
		const { onOpenAppointmentModal, date } = this.props;
		const { showAllAppointments } = this.state;

		const classes = new bemClass('CalendarDay');

		const isFirstDayOfWeek =
			moment(date).isSame(moment(date).startOf('week'), 'day') && moment(date).startOf('week');
		const isLastDayOfWeek = moment(date).isSame(moment(date).endOf('week'), 'day') && moment(date).endOf('week');

		let appointments = sortBy(this.props.appointments, a => new Date(a.date));

		appointments.forEach(a => {
			let startsBefore;
			if (!moment(date).isSame(moment(a.date), 'day')) {
				startsBefore = true;
			}

			if (!startsBefore) {
				let currentOrder = 0;
				a.order = -1;
				while (a.order < 0) {
					if (!appointments.some(appointment => appointment.order === currentOrder)) {
						a.order = currentOrder;
					}
					currentOrder++;
				}
			}
		});

		appointments = sortBy(appointments, a => a.order);

		const extraAppointmentsCount = appointments.filter(a => {
			return a.order > 2;
		}).length;

		return (
			<div
				className={classes.elem('wrapper').b()}
				onClick={e => onOpenAppointmentModal(e, date)}
				onMouseLeave={this.handleShowAppointments(false)}
				onMouseEnter={this.handleShowAppointments(
					appointments.some(a => {
						return a.order > 2;
					})
				)}
			>
				{!!appointments.length &&
					appointments.slice(0, 3).map(a => {
						let continuousDate;
						let startsBefore;
						if (!moment(date).isSame(moment(a.date), 'day')) {
							startsBefore = date;
						}
						if (!moment(date).isSame(moment(a.endDate), 'day')) {
							continuousDate = true;
						}

						return (
							<CalendarItemMonthView
								key={a.id}
								backgroundColor={a.color}
								onOpenAppointmentModal={a.isEvent ? undefined : onOpenAppointmentModal}
								continuousDate={continuousDate}
								startsBefore={startsBefore}
								isFirstDayOfWeek={isFirstDayOfWeek}
								dayDate={date}
								appointment={a}
							/>
						);
					})}
				{showAllAppointments && (
					<div
						style={isFirstDayOfWeek ? { left: 0 } : isLastDayOfWeek ? { right: 0 } : { left: -10 }}
						className={classes.elem('AllAppointments').b()}
					>
						<div onClick={this.handleShowFullDay} className={classes.elem('AllAppointments--header').b()}>
							<Text size="sm">{moment(date).format('DD')}</Text>
							<Text size="sm" color="bright-blue">
								{Tools.$translate('calendar.showFullDay')}
							</Text>
						</div>
						{appointments.map(a => {
							let startsBefore;
							if (!moment(date).isSame(moment(a.date), 'day')) {
								startsBefore = date;
							}
							return (
								<CalendarItem
									key={a.id}
									backgroundColor={a.color}
									onOpenAppointmentModal={onOpenAppointmentModal}
									fullDay
									startsBefore={startsBefore}
									appointment={a}
								/>
							);
						})}
					</div>
				)}
				{extraAppointmentsCount ? (
					<Text color="grey-7" className="extraAppointmentsCount">
						{extraAppointmentsCount} {Tools.$translate('default.more')}
					</Text>
				) : null}
			</div>
		);
	}
}

CalendarDayWrapper.propTypes = {
	appointments: PropTypes.array,
	previousDayAppointments: PropTypes.array,
	onOpenAppointmentModal: PropTypes.func,
	date: PropTypes.instanceOf(Date),
	handleShowFullDay: PropTypes.func
};

export default CalendarDayWrapper;

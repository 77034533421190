import { Block, Icon, Link, Text } from '@upsales/components';
import { checkBrowser } from 'App/helpers/loginHelper';
import React, { useEffect, useState } from 'react';
import T from 'Components/Helpers/translate';
import BrowserService from 'Services/BrowserService';

export default function LoginUnsupportedBrowser() {
	const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [mobile, setMobile] = useState<null | string>(null);
	const [isIE, setIsIE] = useState(false);
	useEffect(() => {
		setIsIE(BrowserService.isIE());
		const { unsupported, mobileType, isMobileBrowser } = checkBrowser();
		setUnsupportedBrowser(unsupported);
		setIsMobile(isMobileBrowser);
		setMobile(mobileType);
	}, []);

	const color = 'bright-orange';
	const space = 'pll prl ptm pbm mll mrl';

	if (isIE) {
		return (
			<Block backgroundColor={color} borderRadius space={space}>
				<Text color="white" bold>
					<Icon name="info-circle" space="mrs" />
					{T('login.upsalesWorksBestIn')}{' '}
					<Link color="inherit" href="https://www.google.com/chrome/browser/desktop/">
						Chrome
					</Link>{' '}
					{T('login.or')}{' '}
					<Link color="inherit" href="https://www.mozilla.org/en-US/firefox/new/">
						Firefox
					</Link>
				</Text>
			</Block>
		);
	}

	if (isMobile && mobile === 'iphone') {
		return (
			<Block backgroundColor={color} borderRadius space={space}>
				<Text color="white" bold>
					<Icon name="info-circle" space="mrs" />
					{T('browser.iphoneAppInfo')}{' '}
					<Link
						color="inherit"
						title="App Store"
						href="https://itunes.apple.com/se/app/upsales-crm/id397053677"
						target="_blank"
					>
						{T('browser.iphoneAppLinkText')}
					</Link>
				</Text>
			</Block>
		);
	}

	if (isMobile && mobile === 'android') {
		return (
			<Block backgroundColor={color} borderRadius space={space}>
				<Text color="white" bold>
					<Icon name="info-circle" space="mrs" />
					{T('browser.androidAppInfo')}{' '}
					<Link
						color="inherit"
						title="Google Play"
						href="http://play.google.com/store/apps/details?id=com.upsales"
						target="_blank"
					>
						{T('browser.androidAppLinkText')}
					</Link>
				</Text>
			</Block>
		);
	}

	if (unsupportedBrowser) {
		return (
			<Block backgroundColor={color} borderRadius space={space}>
				<Text color="white" bold>
					<Icon name="info-circle" space="mrs" />
					{T('browser.outdated')}
				</Text>
			</Block>
		);
	}

	return null;
}

'use strict';
import logError from 'App/babel/helpers/logError';

angular.module('domain.admin').controller('MailAdmin', [
	'$scope',
	'$stateParams',
	'MailAccount',
	'AppService',
	'$safeApply',
	function ($scope, $stateParams, MailAccount, AppService, $safeApply) {
		var customerId;
		var initialHash;
		var AdminCtrl = $scope.AdminCtrl;
		var AdminSubCtrl = this;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'mailSettings';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			domains: [],
			settings: {
				acceptedTermsDate: null,
				activeDays: '',
				name: '',
				phone: '',
				address: '',
				city: '',
				zipCode: '',
				country: '',
				customerId: null,
				defaultEmail: '',
				defaultFrom: '',
				disclaimer: '',
				endTime: 15,
				startTime: 7,
				id: null,
				mailClickScore: 1,
				mailOpenScore: 0,
				mailUnsubscribeScore: -5,
				limitEmailCount: 2,
				limitWeeks: 1
			},
			startTime: null,
			endTime: null,
			addingDomain: false,
			requiredFields: ['defaultEmail', 'defaultFrom', 'name'],
			errorFields: []
		};

		let initialLimitEmailCount;
		let initialLimitWeeks;

		var showSaveIfChanged = function (settings) {
			// eslint-disable-next-line no-use-before-define
			var hash = getCurrentHash(settings);
			AdminSubCtrl.rootData.saveVisible = AdminSubCtrl.rootData.activated && hash !== initialHash;
			$safeApply($scope);
		};

		var getCurrentHash = function (settings) {
			return Tools.LZString.compressToBase64(
				JSON.stringify(settings ? settings : AdminSubCtrl.rootData.pageData.settings)
			);
		};

		AdminSubCtrl.rootData.getDomains = function (cb) {
			MailAccount.domains()
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.domains = res.data;
					if (typeof cb === 'function') {
						return cb(true);
					}
				})
				.catch(e => logError(e));
		};

		AdminSubCtrl.rootData.addDomain = function (domainObject) {
			AdminSubCtrl.rootData.pageData.addingDomain = true;

			domainObject.valid = false;
			domainObject.adding = true;
			AdminSubCtrl.rootData.pageData.domains.push(domainObject);
			AdminSubCtrl.rootData.pageData.domains.sort(function (a, b) {
				if (a.domain < b.domain) {
					return -1;
				}

				if (a.domain > b.domain) {
					return 1;
				}

				return 0;
			});

			MailAccount.addDomain(domainObject)
				.then(function () {
					AdminSubCtrl.rootData.getDomains(function (cb) {
						if (cb) {
							AdminSubCtrl.rootData.pageData.addingDomain = false;
						}
					});
				})
				.catch(function (err) {
					console.log('Add domain error', err);
					AdminSubCtrl.rootData.pageData.addingDomain = false;
				});
		};

		var checkRequiredFields = function (settings) {
			return _.filter(AdminSubCtrl.rootData.pageData.requiredFields, function (field) {
				if (field === 'defaultEmail') {
					var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
					var emailValid = re.test(settings[field]);
					return !(settings[field] && settings[field].length) || !emailValid;
				}
				return !settings[field] || !settings[field].length;
			});
		};

		AdminSubCtrl.rootData.onChange = function (settingsObject) {
			if (isNaN(settingsObject.limitEmailCount)) settingsObject.limitEmailCount = initialLimitEmailCount;
			if (isNaN(settingsObject.limitWeeks)) settingsObject.limitWeeks = initialLimitWeeks;
			if (settingsObject.limitEmailCount < 1) settingsObject.limitEmailCount = 1;
			if (settingsObject.limitEmailCount > 1000) settingsObject.limitEmailCount = 1000;
			if (settingsObject.limitWeeks < 1) settingsObject.limitWeeks = 1;
			if (settingsObject.limitWeeks > 12) settingsObject.limitWeeks = 12;
			AdminSubCtrl.rootData.pageData.settings = settingsObject;
			if (AdminSubCtrl.rootData.pageData.errorFields.length) {
				AdminSubCtrl.rootData.pageData.errorFields = checkRequiredFields(settingsObject);
			}
			showSaveIfChanged(settingsObject);
		};

		AdminSubCtrl.rootData.onSave = function () {
			AdminSubCtrl.rootData.saving = true;

			// Check all required fields..
			AdminSubCtrl.rootData.pageData.errorFields = checkRequiredFields(AdminSubCtrl.rootData.pageData.settings);

			if (AdminSubCtrl.rootData.pageData.errorFields.length) {
				AdminSubCtrl.rootData.saving = false;
				AdminSubCtrl.rootData.saveVisible = false;
				return;
			}

			MailAccount[AdminSubCtrl.rootData.activated ? 'update' : 'save'](AdminSubCtrl.rootData.pageData.settings)
				.then(function (res) {
					if (!AdminSubCtrl.rootData.activated) {
						var metadata = Tools.AppService.getMetadata();
						metadata.map.mailActivated = true;
						metadata.map.mail = true;
						Tools.AppService.setMetadata(metadata);
					}
					AdminSubCtrl.rootData.saving = false;
					AdminSubCtrl.rootData.saveVisible = false;
					AdminSubCtrl.rootData.activated = true;
					AdminSubCtrl.rootData.pageData.settings = res.data;
					AdminSubCtrl.rootData.getDomains();
					initialHash = getCurrentHash();
				})
				.catch(function () {
					AdminSubCtrl.rootData.saving = false;
					AdminSubCtrl.rootData.saveVisible = false;
				});
		};

		AdminSubCtrl.rootData.acceptTerms = function () {
			const metadata = AppService.getMetadata();
			if (AdminSubCtrl.rootData.pageData.settings.id && metadata.map.mailActivated) {
				AdminSubCtrl.rootData.acceptNewTerms();
			} else {
				AdminSubCtrl.rootData.pageData.settings.acceptedTermsDate = true;
				$safeApply($scope);
			}
		};

		AdminSubCtrl.rootData.acceptNewTerms = function () {
			AdminSubCtrl.rootData.pageData.settings.acceptedTermsDate = true;
			AdminSubCtrl.rootData.acceptingTerms = true;
			MailAccount.customer(customerId)
				.update(AdminSubCtrl.rootData.pageData.settings)
				.then(function (res) {
					AdminSubCtrl.rootData.activated = true;
					AdminSubCtrl.rootData.pageData.settings = res.data;
					AdminSubCtrl.rootData.acceptingTerms = false;
					AdminSubCtrl.rootData.getDomains();
				})
				.catch(e => logError(e))
				.finally(function () {
					$scope.saving = false;
					$safeApply($scope);
				});
		};

		var init = function () {
			customerId = $stateParams.customerId;
			var metadata = AppService.getMetadata();
			var activated = metadata.map.mailActivated;

			AdminSubCtrl.rootData.activated = activated;
			var now = new Date();

			if (metadata.map.mailActivated) {
				MailAccount.get()
					.then(function (account) {
						AdminSubCtrl.rootData.pageData.settings = account.data;
						initialLimitEmailCount = account.data.limitEmailCount;
						initialLimitWeeks = account.data.limitWeeks;
						initialHash = getCurrentHash();

						now.setUTCHours(AdminSubCtrl.rootData.pageData.settings.startTime, 0, 0, 0);
						AdminSubCtrl.rootData.pageData.startTime = new Date(now);

						now.setUTCHours(AdminSubCtrl.rootData.pageData.settings.endTime, 0, 0, 0);
						AdminSubCtrl.rootData.pageData.endTime = new Date(now);
						AdminSubCtrl.rootData.pageLoading = false;
					})
					.catch(e => logError(e));
			} else {
				now.setUTCHours(7, 0, 0, 0);
				AdminSubCtrl.rootData.pageData.startTime = new Date(now);

				now.setUTCHours(15, 0, 0, 0);
				AdminSubCtrl.rootData.pageData.endTime = new Date(now);

				AdminSubCtrl.rootData.pageData.settings.mailOpenScore = 0;
				AdminSubCtrl.rootData.pageData.settings.mailClickScore = 1;
				AdminSubCtrl.rootData.pageData.settings.mailUnsubscribeScore = -5;

				initialHash = getCurrentHash();
				AdminSubCtrl.rootData.pageLoading = false;
			}
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

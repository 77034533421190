'use strict';

angular.module('upAttributes').service('LeaderAccountAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				}
			};
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				keys: {
					ID: 'id'
				}
			};
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';

import './Email.scss';

export default class EmailInfo extends React.Component {
	getProperty(property) {
		const { config } = this.props;
		for (const p of config.properties) {
			if (p.name === property) {
				return p;
			}
		}
		return {};
	}

	render() {
		const classes = new BemClass('EmailInfo');
		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>{this.getProperty('Subject').value}</div>
					<div className={classes.elem('sub-title').b()}>{this.getProperty('ToAddresses').value}</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

EmailInfo.propTypes = {
	config: PropTypes.object.isRequired
};

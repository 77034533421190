import parseMailCampaign from 'App/resources/parsers/mailCampaign';

// Parser de-angularized and testable in /app/babel/resources/parsers/mailCampaign.js
angular.module('upResources').service('ParseMailCampaign', function () {
	return function (mailCampaign) {
		// This needs to be here since new genericParser don't run on old resources
		if (mailCampaign.filterConfig) {
			try {
				mailCampaign.filterConfig = JSON.parse(mailCampaign.filterConfig);
			} catch (e) {
				mailCampaign.filterConfig = null;
				mailCampaign.filter = null;
			}
		}
		return parseMailCampaign(mailCampaign);
	};
});

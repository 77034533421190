import { preview } from 'App/helpers/creatives';

angular.module('domain.engage').controller('ListAds', [
	'$scope',
	'$state',
	'$translate',
	'AccountProfile',
	'$stateParams',
	'File',
	'$upModal',
	'AppService',
	'NotificationService',
	'RequestBuilder',
	'Ads',
	'Event',
	'AdService',
	'$q',
	'BannerGroup',
	'FeatureHelper',
	'URL',
	'API',
	'ListAttributes',
	'FilterHelper',
	'meta',
	function (
		$scope,
		$state,
		$translate,
		AccountProfile,
		$stateParams,
		File,
		$upModal,
		AppService,
		NotificationService,
		RequestBuilder,
		Ads,
		Event,
		AdService,
		$q,
		BannerGroup,
		FeatureHelper,
		URL,
		API,
		ListAttributes,
		FilterHelper,
		meta
	) {
		const ListAdsCtrl = this;
		const customerId = AppService.getCustomerId();
		ListAdsCtrl.AccountProfile = null;
		ListAdsCtrl.graph = {};
		ListAdsCtrl.clicks = 0;
		ListAdsCtrl.impressions = 0;
		ListAdsCtrl.customDates = AdService.customReportDates();
		ListAdsCtrl.maxDate = moment().endOf('day');
		ListAdsCtrl.adAccount = AppService.getAdAccount();
		ListAdsCtrl.limit = 25;
		ListAdsCtrl.offset = 0;
		ListAdsCtrl.total = 0;
		ListAdsCtrl.page = 1;
		ListAdsCtrl.data = [];
		ListAdsCtrl.loading = true;
		ListAdsCtrl.activeTab = null;
		ListAdsCtrl.filters = {};
		ListAdsCtrl.archiving = {};
		ListAdsCtrl.statuses = Ads.statuses;
		ListAdsCtrl.hasAdCampaigns = meta.hasAdCampaigns;
		ListAdsCtrl.hasBanners = meta.hasBanners;
		ListAdsCtrl.isAdministrator = AppService.getSelf().administrator;

		const tabs = {
			campaigns: 'campaigns',
			banners: 'banners'
		};

		const activeTab = $stateParams.tab && tabs[$stateParams.tab] ? $stateParams.tab : tabs.campaigns;
		const adCampaignAttributes = ListAttributes.get('adCampaign').attr;
		const bannerAttributes = ListAttributes.get('banner').attr;

		ListAdsCtrl.config = {
			[tabs.campaigns]: {
				columns: [
					adCampaignAttributes.name,
					adCampaignAttributes.status,
					adCampaignAttributes.target,
					adCampaignAttributes.budget,
					adCampaignAttributes.spent,
					adCampaignAttributes.startDate,
					adCampaignAttributes.endDate,
					adCampaignAttributes.impressions,
					adCampaignAttributes.clicks
				],
				type: 'adCampaign',
				filters: ['Name', 'Archived']
			},
			[tabs.banners]: {
				columns: [
					bannerAttributes.name,
					bannerAttributes.status,
					bannerAttributes.type,
					bannerAttributes.size,
					bannerAttributes.landingPage,
					bannerAttributes.regBy
				],
				type: 'banner',
				filters: ['Name']
			}
		};

		ListAdsCtrl.getSortClass = function (column) {
			if (column.sortable && column.sortable === ListAdsCtrl.sort) {
				const sortKey = `${column.displayType}-${ListAdsCtrl.ascending}`;
				const sortMap = {
					'string-true': 'fa-sort-alpha-asc',
					'string-false': 'fa-sort-alpha-desc',
					'number-true': 'fa-sort-numeric-asc',
					'number-false': 'fa-sort-numeric-desc',
					'date-true': 'fa-sort-numeric-asc',
					'date-false': 'fa-sort-numeric-desc'
				};
				return sortMap[sortKey] || '';
			}
			return '';
		};

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			var found = _.findIndex(ListAdsCtrl.data, { id: updated.id });
			if (found !== -1) {
				ListAdsCtrl.data[found] = updated;
			}
		});

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.deleted, function (e, deleted) {
			var found = _.findIndex(ListAdsCtrl.data, { id: deleted.id });
			if (found !== -1) {
				ListAdsCtrl.data.splice(found, 1);
			}
		});

		$scope.$on('bannerGroup.deleted', function (e, deleted) {
			var found = _.findIndex(ListAdsCtrl.data, { id: deleted.id, $isGroup: true });
			if (found !== -1) {
				ListAdsCtrl.data.splice(found, 1);
			}
		});

		ListAdsCtrl.createAccount = function () {
			ListAdsCtrl.activating = true;
			return Ads.customer(customerId)
				.saveAccount({})
				.then(function () {
					ListAdsCtrl.adAccount = AppService.getAdAccount();
					ListAdsCtrl.activating = false;
					ListAdsCtrl.activated = ListAdsCtrl.adAccount && ListAdsCtrl.adAccount.active;
				})
				.catch(function (err) {
					ListAdsCtrl.activating = false;
					ListAdsCtrl.activatingErr = err;
				});
		};

		function handleErrorGeneric(error) {
			console.error(error);
		}

		ListAdsCtrl.uploadBanner = function () {
			$upModal.open('uploadCreative', {});
		};
		ListAdsCtrl.createBanner = function () {
			$state.go('selectCreativeTemplate');
		};

		ListAdsCtrl.deleteAd = function (ad) {
			return Ads.customer(customerId).delete(ad);
		};

		ListAdsCtrl.archiveAdCampaign = function (adCampaign) {
			const archiveId = adCampaign.id;
			ListAdsCtrl.archiving[archiveId] = true;

			Ads.customer(customerId)
				.archive(archiveId)
				.then(function () {
					ListAdsCtrl.archiving[archiveId] = false;
					if (ListAdsCtrl.filters.Archived.value === false) {
						ListAdsCtrl.data = _.filter(ListAdsCtrl.data, adCampaign => archiveId !== adCampaign.id);
						ListAdsCtrl.total--;
					}
				})
				.catch(function () {
					ListAdsCtrl.archiving[archiveId] = false;
				});
		};

		ListAdsCtrl.editAd = function (ad) {
			$state.go('editAd', ad);
		};

		ListAdsCtrl.viewAd = function (ad) {
			$state.go('viewAd.dashboard', ad);
		};

		ListAdsCtrl.changeSort = function (column) {
			if (column.sortable) {
				if (ListAdsCtrl.sort === column.sortable) {
					ListAdsCtrl.ascending = !ListAdsCtrl.ascending;
				} else {
					ListAdsCtrl.sort = column.sortable;
					ListAdsCtrl.ascending = true;
				}
				ListAdsCtrl.page = 1;
				ListAdsCtrl.fetchData();
			}
		};

		ListAdsCtrl.changeDate = function (type) {
			var start, end, query;
			ListAdsCtrl.loadingChart = true;
			_.remove(ListAdsCtrl.chartConfig.xAxis[0].categories, function () {
				return true;
			});
			_.remove(ListAdsCtrl.chartConfig.series[0].data, function () {
				return true;
			});
			_.remove(ListAdsCtrl.chartConfig.series[1].data, function () {
				return true;
			});
			if (type === 'start' || type === 'end') {
				ListAdsCtrl.selectedDate = 'customDate';
				start = moment(ListAdsCtrl.graph.startDate).startOf('day');
				ListAdsCtrl.graph.startDate = start.toDate();
				end = moment(ListAdsCtrl.graph.endDate).endOf('day');
				ListAdsCtrl.graph.endDate = end.toDate();
				if (type === 'start') {
					if (end.isBefore(start)) {
						ListAdsCtrl.graph.endDate = ListAdsCtrl.graph.startDate;
					}
				} else if (type === 'end') {
					if (end.isBefore(start)) {
						ListAdsCtrl.graph.startDate = ListAdsCtrl.graph.endDate;
					}
				}
			} else {
				ListAdsCtrl.graph.startDate = type.startDate;
				ListAdsCtrl.graph.endDate = type.endDate;
				ListAdsCtrl.selectedDate = type.name;
				ListAdsCtrl.graph.startDate = ListAdsCtrl.graph.startDate.startOf('day').toDate();
				ListAdsCtrl.graph.endDate = ListAdsCtrl.graph.endDate.endOf('day').toDate();
			}

			query = new RequestBuilder();
			query.addFilter(Event.attr.date, 'gte', ListAdsCtrl.graph.startDate);
			query.addFilter(Event.attr.date, 'lte', ListAdsCtrl.graph.endDate);
			Ads.customer(customerId)
				.stats('day', query.build())
				.then(function (res) {
					ListAdsCtrl.statsPerDay = res.data;
					ListAdsCtrl.createGraph();
				})
				.catch(function () {
					ListAdsCtrl.loadingChart = false;
				});
		};

		ListAdsCtrl.createGraph = function () {
			ListAdsCtrl.impressions = 0;
			ListAdsCtrl.clicks = 0;

			if (!ListAdsCtrl.statsPerDay || !ListAdsCtrl.statsPerDay.length) {
				ListAdsCtrl.loadingChart = false;
				return;
			}
			var sd = moment(ListAdsCtrl.graph.startDate);
			var plotStartDate = Date.UTC(sd.format('YYYY'), sd.format('M') - 1, sd.format('D'));
			var a = angular.copy(sd);
			var b = ListAdsCtrl.graph.endDate;
			ListAdsCtrl.chartConfig.series[0].pointStart = plotStartDate;
			ListAdsCtrl.chartConfig.series[1].pointStart = plotStartDate;
			for (var m = a; m.isBefore(b); m.add('days', 1)) {
				var impressions = 0;
				var clicks = 0;
				for (var j = 0; j < ListAdsCtrl.statsPerDay.length; j++) {
					var tmp = moment(ListAdsCtrl.statsPerDay[j].date).format('YYYYMMDD');
					if (tmp === m.format('YYYYMMDD')) {
						impressions = ListAdsCtrl.statsPerDay[j].impressions || 0;
						clicks = ListAdsCtrl.statsPerDay[j].clicks || 0;
					}
				}
				ListAdsCtrl.chartConfig.series[0].data.push(impressions);
				ListAdsCtrl.chartConfig.series[1].data.push(clicks);

				ListAdsCtrl.impressions += impressions;
				ListAdsCtrl.clicks += clicks;
			}
			ListAdsCtrl.loadingChart = false;
		};

		ListAdsCtrl.filtersChanged = function () {
			ListAdsCtrl.page = 1;
			ListAdsCtrl.fetchData();
		};

		ListAdsCtrl.setActiveTab = function (tab) {
			if (tab !== ListAdsCtrl.activeTab) {
				ListAdsCtrl.activeTab = tab;
				ListAdsCtrl.sort = 'name';
				ListAdsCtrl.ascending = true;
				ListAdsCtrl.page = 1;
				const { type, filters } = ListAdsCtrl.config[tab];

				ListAdsCtrl.filters = filters.reduce((filters, filterName) => {
					filters[filterName] = FilterHelper.filter(filterName, type);
					return filters;
				}, {});

				ListAdsCtrl.fetchData();
			}
		};

		ListAdsCtrl.editBanner = function (bannerGroup) {
			$state.go('creativeEditor', { id: bannerGroup.id });
		};

		ListAdsCtrl.deleteBanner = function (bannerGroup) {
			return BannerGroup.delete(bannerGroup).then(function () {
				_.remove(ListAdsCtrl.data, { isGroup: 1, id: bannerGroup.id });
			});
		};

		ListAdsCtrl.inactivateCreative = function (creative) {
			creative.state = 'archived';
			return Ads.customer(customerId)
				.deleteCreative(creative)
				.then(function () {
					_.remove(ListAdsCtrl.data, { isGroup: 0, id: creative.id });
				});
		};

		ListAdsCtrl.previewCreative = function (creative) {
			if (creative.isGroup && creative.draft) {
				ListAdsCtrl.editBanner({ id: creative.id });
				return;
			}

			preview(creative);
		};

		ListAdsCtrl.cleanupUrl = function (url) {
			url = url || '';
			url = url.replace('www.', '').replace('http://', '').replace('https://', '');
			return url;
		};

		function getAccountProfile() {
			AccountProfile.get()
				.then(function (res) {
					ListAdsCtrl.AccountProfile = res.data ? res.data.value : null;
				})
				.catch(handleErrorGeneric);
		}

		ListAdsCtrl.fetchData = function () {
			ListAdsCtrl.loading = true;
			const filterType = ListAdsCtrl.config[ListAdsCtrl.activeTab].type;
			const rb = FilterHelper.parseFilters(ListAdsCtrl.filters, filterType);
			rb.offset = (ListAdsCtrl.page - 1) * ListAdsCtrl.limit;
			rb.limit = ListAdsCtrl.limit;
			rb.addSort(ListAdsCtrl.sort, ListAdsCtrl.ascending);

			const method = ListAdsCtrl.activeTab === tabs.banners ? 'findGroupedCreative' : 'findWithMetadata';
			Ads.customer(customerId)
				[method](rb.build())
				.then(function ({ data, metadata }) {
					ListAdsCtrl.data = data;
					ListAdsCtrl.total = metadata.total;
					ListAdsCtrl.loading = false;

					if (ListAdsCtrl.activeTab === tabs.banners) {
						const users = AppService.getUsers();
						for (const banner of data) {
							const found = _.find(users, { id: banner.regBy });
							banner.regBy = found || null;
						}
					}
				})
				.catch(function (error) {
					ListAdsCtrl.loading = false;
					console.error('ui/app/upsales/domain/engage/controllers/list.js - fetchData', error);
				});
		};

		ListAdsCtrl.chartConfig = {
			chart: {
				zoomType: 'xy'
			},
			options: {
				chart: {
					backgroundColor: '#F6F9FB',
					plotBackgroundColor: '#F6F9FB',
					style: {
						fontFamily: '"Roboto"'
					}
				}
			},
			credits: {
				enabled: false
			},
			title: {
				text: ''
			},
			size: {
				height: 250
			},
			xAxis: [
				{
					crosshair: true,
					type: 'datetime',
					dateTimeLabelFormats: {
						day: '%e %b'
					}
				}
			],
			yAxis: [
				{
					// Primary yAxis
					floor: 0,
					title: {
						text: $translate.instant('ads.clicks'),
						style: {
							color: '#B2BECF'
						}
					}
				},
				{
					// Secondary yAxis
					floor: 0,
					title: {
						text: $translate.instant('ads.impressions'),
						style: {
							color: '#A4B3C7'
						}
					},
					opposite: true
				}
			],
			tooltip: {
				shared: true
			},
			legend: {
				layout: 'vertical',
				align: 'left',
				x: 120,
				verticalAlign: 'top',
				y: 100,
				floating: true
			},
			series: [
				{
					name: $translate.instant('ads.impressions'),
					type: 'column',
					yAxis: 1,
					data: [],
					endOnTick: true,
					color: '#E1C2F1',
					pointStart: Date.UTC(2010, 0, 1),
					pointInterval: 24 * 3600 * 1000, // one day
					tooltip: {
						valueSuffix: ''
					}
				},
				{
					name: $translate.instant('ads.clicks'),
					type: 'spline',
					data: [],
					endOnTick: true,
					color: '#721A94',
					pointStart: Date.UTC(2010, 0, 1),
					pointInterval: 24 * 3600 * 1000, // one day
					tooltip: {
						valueSuffix: ''
					}
				}
			]
		};

		ListAdsCtrl.changeDate(ListAdsCtrl.customDates[0]);

		ListAdsCtrl.toggleLoading = function () {
			this.chartConfig.loading = !this.chartConfig.loading;
		};

		ListAdsCtrl.setActiveTab(activeTab);
		getAccountProfile();
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Input } from '@upsales/components';
import './CreateMailTemplate.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from '../../angularHelpers/getAngularModule';
import MailTemplate from 'App/babel/resources/MailTemplate';
import history from 'App/pages/routes/history';
import GroupMailEditorRobot from '../GroupMailEditor/GroupMailEditorRobot';
import FeatureIntroModal from 'Components/FeatureIntroModal';
import MailTemplateAttr from 'Attributes/MailTemplateAttributes';

const NAME_LENGTH = MailTemplateAttr.name.size;

class CreateMailTemplate extends React.PureComponent {
	constructor(p) {
		super(p);

		this.onSave = this.onSave.bind(this);
		this.onNameChange = this.onNameChange.bind(this);

		const t = getAngularModule('$translate');

		this.lang = {
			headline: t('mailTemplate.createTemplate'),
			description: t('mailTemplate.createTemplateInfo'),
			internalName: t('mailTemplate.internalTemplateName'),
			nextStep: t('groupMail.nextTemplateDesign'),
			abort: t('default.abort')
		};

		this.state = {
			internalName: '',
			saving: false
		};
	}

	componentDidMount() {
		this._internalName.focus();
	}

	onNameChange({ target: { value } }) {
		this.setState({ internalName: value });
	}

	onSave(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ saving: true });

		const saveData = {
			...MailTemplate.new(),
			subject: this.state.internalName,
			name: this.state.internalName
		};

		MailTemplate.save(saveData)
			.then(res => {
				history.push('/mail-template-editor/' + res.data.id);
				this.props.reject();
			})
			.catch(() => this.setState({ saving: false }));
	}

	render() {
		const classes = new bemClass('CreateMailTemplate');

		return (
			<FeatureIntroModal
				className={classes.b()}
				title={this.lang.headline}
				description={this.lang.description}
				onClose={this.props.reject}
				renderIllustration={() => <GroupMailEditorRobot state="booting" />}
			>
				<form onSubmit={this.onSave} autoComplete="off">
					<Label maxLength={NAME_LENGTH} value={this.state.internalName} required>
						{this.lang.internalName}
					</Label>
					<Input
						disabled={this.state.saving}
						value={this.state.internalName}
						onChange={this.onNameChange}
						inputRef={r => (this._internalName = r)}
						state={this.state.internalName?.length > NAME_LENGTH ? 'error' : undefined}
					/>

					<Button
						color={!this.state.internalName ? 'grey' : 'green'}
						shadow={!this.state.internalName ? 'none' : 'high'}
						size="xl"
						block
						className={classes.elem('next-step').b()}
						disabled={!this.state.internalName || this.state.internalName?.length > NAME_LENGTH}
						loading={this.state.saving}
						submit
					>
						{this.lang.nextStep}
					</Button>

					<Button
						className={classes.elem('cancel').b()}
						color="grey"
						type="link"
						block
						size="lg"
						onClick={() => this.props.reject()}
						disabled={this.state.saving}
					>
						{this.lang.abort}
					</Button>
				</form>
			</FeatureIntroModal>
		);
	}
}

CreateMailTemplate.propTypes = {
	reject: PropTypes.func.isRequired
};

CreateMailTemplate.defaultProps = {
	reject: () => {}
};

export default CreateMailTemplate;

import React, { CSSProperties, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import './DocumentContentEditorProductTable.scss';
import {
	Column,
	Row,
	Input,
	Text,
	Icon,
	Toggle,
	Tooltip,
	Label,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	OutsideClick,
	ButtonGroup,
	Button,
	IconName,
	Title
} from '@upsales/components';
import { ColumnType, DocumentTemplateState, ProductTableColumn } from './DocumentTemplateEditor';
import { findRowIndex, findColIndex, getValueHash, onEditorChange } from './DocumentTemplateEditorDesign';
import { useDragLayer } from 'react-dnd';
import { DefaultButton, PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import ModalWrap from 'Components/ModalWrap';
import { DraggableColumnSettings } from './DraggableColumnSettings';
import DocumentTemplateAvailableTags, { getTagName } from './DocumentTemplateAvailableTags';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import { v4 as uuidv4 } from 'uuid';

export const getIndicesFromColId = (state: DocumentTemplateState): [number | undefined, number | undefined] => {
	const config = state.config;
	const rowIndex = state.selectedColumn ? findRowIndex(config, state.selectedColumn.rowId) : undefined;
	const colIndex =
		rowIndex === undefined || state.selectedColumn === undefined
			? undefined
			: findColIndex(config, rowIndex, state.selectedColumn.id);
	return [rowIndex, colIndex];
};

type Props = {
	state: DocumentTemplateState;
	dispatch: React.Dispatch<Partial<DocumentTemplateState>>;
};

export const DocumentContentEditorProductTable = ({ state, dispatch }: Props) => {
	const { t } = useTranslation();
	const lang = {
		addColumn: t('admin.documentTemplateEditor.productTable.addColumn'),
		addColumnTooltip: t('admin.documentTemplateEditor.productTable.addColumnTooltip'),
		behaviour: t('admin.documentTemplateEditor.productTable.behaviour'),
		behaviourInfo: t('admin.documentTemplateEditor.productTable.behaviourInfo'),
		cancel: t('default.cancel'),
		tagError: t('admin.documentTemplateEditor.productTable.error.badTag'),
		info: t('admin.documentTemplateEditor.productTable.info'),
		nameOfColumn: t('admin.documentTemplateEditor.productTable.nameOfColumn'),
		newColumn: t('admin.documentTemplateEditor.productTable.newColumn'),
		product: t('default.product'),
		priceOneOff: t('admin.documentTemplateEditor.productTable.priceOneOff'),
		priceOneOffInfo: t('admin.documentTemplateEditor.productTable.priceOneOffInfo'),
		priceRecurring: t('admin.documentTemplateEditor.productTable.priceRecurring'),
		priceRecurringInfo: t('admin.documentTemplateEditor.productTable.priceRecurringInfo'),
		quantity: t('default.quantity'),
		requiredFields: t('admin.documentTemplateEditor.productTable.requiredFields'),
		save: t('default.save'),
		summary: t('default.summary'),
		toggleTooltip: t('admin.documentTemplateEditor.productTable.toggleTooltip'),
		Custom: t('default.custom')
	};

	const classes = new bemClass('DocumentContentEditorProductTable');

	const [dragId, setDragId] = useState<string | null>(null);
	const [draggedItemWidth, setDraggedItemWidth] = useState<number>(0);
	const [showAddModal, setShowAddModal] = useState<boolean>(false);
	const [newColumnName, setNewColumnName] = useState<string>(lang.newColumn);
	const [newColumnTag, setNewColumnTag] = useState<string>('');
	const [showInlineAction, setShowInlineAction] = useState<string | null>(null);

	const numberOfActiveColumns =
		state.selectedColumn && state.selectedColumn.ProductTableColumns
			? state.selectedColumn.ProductTableColumns.filter((column: ProductTableColumn) => column.isActive).length
			: 0;
	const maxNumberOfActiveColumns = 5;
	const isProductTable = true;
	const alignments = ['left', 'right'] as const;

	const onToggleColumn = (index: number) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) {
			return;
		}
		const config = { ...state.config };
		const column = { ...state.selectedColumn } as ColumnType;
		if (!column) return;
		if (column.ProductTableColumns) {
			column.ProductTableColumns[index] = {
				...column.ProductTableColumns[index],
				isActive: !column.ProductTableColumns[index].isActive
			};
		}
		config.rows[rowIndex].columns[colIndex] = { ...column };
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config: config, selectedColumn: column });
	};

	const onDeleteColumn = (index: number) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) {
			return;
		}
		const config = { ...state.config };
		const column = { ...state.selectedColumn } as ColumnType;
		if (!column || !column.ProductTableColumns) return;
		const newProductTableColumns = [...column.ProductTableColumns];

		newProductTableColumns.splice(index, 1);

		column.ProductTableColumns = newProductTableColumns;
		config.rows[rowIndex].columns[colIndex] = { ...column };

		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config, selectedColumn: column });
	};

	const onChangeAlignment = (index: number) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) {
			return;
		}
		const config = { ...state.config };
		const column = { ...state.selectedColumn } as ColumnType;
		if (!column) return;
		if (column.ProductTableColumns) {
			column.ProductTableColumns[index] = {
				...column.ProductTableColumns[index],
				align: column.ProductTableColumns[index].align === 'left' ? 'right' : 'left'
			};
		}
		config.rows[rowIndex].columns[colIndex] = { ...column };
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config: config, selectedColumn: column });
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const [rowIndex, colIndex] = getIndicesFromColId(state);
		if (rowIndex === undefined || colIndex === undefined) return;
		if (rowIndex === -1 || colIndex === -1) return;
		const config = { ...state.config };
		const column = { ...state.selectedColumn } as ColumnType;
		if (!column || !column.ProductTableColumns) return;
		column.ProductTableColumns[index].name = e.target.value;
		config.rows[rowIndex].columns[colIndex] = { ...column };
		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});
		onEditorChange(state, dispatch, { config, selectedColumn: column });
	};

	const moveColumn = (dragIndex: number, hoverIndex: number) => {
		if (dragIndex === hoverIndex) return;
		if (!state.selectedColumn || !state.selectedColumn.ProductTableColumns) return;
		const newColumns = [...state.selectedColumn.ProductTableColumns];
		const movedItem = newColumns[dragIndex];
		newColumns.splice(dragIndex, 1);
		newColumns.splice(hoverIndex, 0, movedItem);

		const config = { ...state.config };
		const rowIndex = findRowIndex(config, state.selectedColumn.rowId);
		const colIndex = findColIndex(config, rowIndex, state.selectedColumn.id);
		const column = { ...state.selectedColumn };
		column.ProductTableColumns = newColumns;
		config.rows[rowIndex].columns[colIndex] = column;

		dispatch({
			config: config,
			hash: getValueHash(config),
			selectedColumn: column
		});

		onEditorChange(state, dispatch, { config, selectedColumn: column });
	};

	const closeModal = () => {
		setShowAddModal(false);
		setNewColumnName(lang.newColumn);
		setNewColumnTag('');
		setShowInlineAction(null);
	};

	const onAddColumn = () => {
		const config = { ...state.config };
		if (!state.selectedColumn) return;
		const rowIndex = findRowIndex(config, state.selectedColumn.rowId);

		if (rowIndex === -1) return;

		const colIndex = findColIndex(config, rowIndex, state.selectedColumn.id);

		if (colIndex === -1) return;

		const column = { ...state.selectedColumn };
		if (!column || !column.ProductTableColumns) return;

		const tagName = getTagName(newColumnTag.substring(2, newColumnTag.length - 2));

		if (tagName.length === 1) {
			const newProductTableColumn = {
				name: newColumnName,
				isActive: true,
				tag: '<p>' + newColumnTag + '</p>',
				type: lang.Custom,
				id: uuidv4(),
				title: tagName[0].name,
				align: 'right'
			};
			column.ProductTableColumns = [...column.ProductTableColumns, newProductTableColumn];
			config.rows[rowIndex].columns[colIndex] = { ...column };
			dispatch({
				config: config,
				hash: getValueHash(config),
				selectedColumn: column
			});
			onEditorChange(state, dispatch, { config, selectedColumn: column });
			closeModal();
		} else {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'error',
				title: 'default.error',
				body: lang.tagError
			});
		}
	};

	const renderColumnSettings = (
		column: ProductTableColumn,
		index: number,
		drag?: React.LegacyRef<HTMLDivElement>
	) => {
		return (
			<Column
				data-testid={'column-settings-' + index}
				className={classes
					.elem('column-type')
					.mod({ 'dragging-active-hover': dragId !== null && dragId !== column.id })
					.mod({ 'dragging-active': !drag && dragId === column.id })
					.b()}
			>
				<Title className={classes.elem('column-title').b()} size="sm" bold={true}>
					{column.title}
				</Title>
				<Row>
					<Column>
						<Input
							data-testid={'column-settings-input-' + index}
							disabled={!column.isActive}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, index)}
							defaultValue={column.name}
						></Input>
					</Column>
					<Column className={classes.elem('column-settings').b()}>
						<ButtonGroup block={true} className={classes.elem('column-alignment').b()}>
							{alignments.map(align => (
								<Button
									key={align}
									shadow="none"
									size="xs"
									onClick={() => onChangeAlignment(index)}
									color={column.align === align ? 'green' : 'light-grey'}
								>
									<Icon name={('align-' + align) as IconName} />
								</Button>
							))}
						</ButtonGroup>
						{column.type === lang.Custom ? (
							<Icon
								className={classes.elem('column-delete').b()}
								name="trash"
								onClick={() => onDeleteColumn(index)}
								data-testid="column-settings-delete"
							></Icon>
						) : (
							<Tooltip
								title={lang.toggleTooltip}
								disabled={column.isActive || numberOfActiveColumns < maxNumberOfActiveColumns}
							>
								<Toggle
									data-testid={'column-settings-toggle-' + index}
									disabled={!column.isActive && numberOfActiveColumns >= maxNumberOfActiveColumns}
									onChange={() => onToggleColumn(index)}
									checked={column.isActive}
								></Toggle>
							</Tooltip>
						)}
						<div ref={drag}>
							<Column>
								<Icon className={classes.elem('column-move').b()} name="arrows-alt"></Icon>
							</Column>
						</div>
					</Column>
				</Row>
				{column.description ? (
					<Row className={classes.elem('column-info').b()}>
						<Text size="sm" color="grey-11">
							{column.description}
						</Text>
					</Row>
				) : null}
			</Column>
		);
	};

	const CustomDragLayer = () => {
		const { item, isDragging, initialOffset, currentOffset } = useDragLayer(monitor => ({
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging()
		}));

		if (!isDragging || dragId === null) {
			return null;
		}

		const layerStyles: CSSProperties = {
			position: 'fixed',
			pointerEvents: 'none',
			zIndex: 100000,
			left: 0,
			top: 0
		};

		const getItemStyles = (
			initialOffset: { x: number; y: number } | null,
			currentOffset: { x: number; y: number } | null
		): CSSProperties => {
			if (!initialOffset || !currentOffset) {
				return { display: 'none' };
			}

			const transform = `translate(${initialOffset.x - draggedItemWidth + 11}px, ${currentOffset.y - 17}px)`;
			return {
				transform,
				WebkitTransform: transform,
				minWidth: `${draggedItemWidth}px`,
				maxWidth: `${draggedItemWidth}px`
			};
		};
		if (!state.selectedColumn || !state.selectedColumn.ProductTableColumns) return null;
		const column = state.selectedColumn.ProductTableColumns.find(
			(column: ProductTableColumn) => column.id === item.id
		);

		return (
			<div style={layerStyles}>
				<div style={getItemStyles(initialOffset, currentOffset)}>
					{column ? renderColumnSettings(column, item.index) : null}
				</div>
			</div>
		);
	};

	const handleClick = (tagValue: string, tagName: string) => {
		setNewColumnTag(tagValue);
		if (newColumnName === lang.newColumn) setNewColumnName(tagName);
	};

	return (
		<>
			<div className={classes.b()}>
				<div className={classes.elem('info').b()}>{lang.info}</div>
				<CustomDragLayer />
				{state.selectedColumn && state.selectedColumn.ProductTableColumns
					? state.selectedColumn.ProductTableColumns.map((column: ProductTableColumn, index: number) => (
							<DraggableColumnSettings
								key={'selected-column' + column.title}
								column={column}
								index={index}
								moveColumn={moveColumn}
								setDragId={setDragId}
								setDraggedItemWidth={setDraggedItemWidth}
								renderColumnSettings={renderColumnSettings}
							/>
					  ))
					: null}
				<Tooltip title={lang.addColumnTooltip} disabled={numberOfActiveColumns < maxNumberOfActiveColumns}>
					<DefaultButton
						disabled={numberOfActiveColumns >= maxNumberOfActiveColumns}
						size="md"
						className={classes.elem('add-column-btn').b()}
						onClick={() => setShowAddModal(true)}
					>
						<Icon name="plus" />
						{lang.addColumn}
					</DefaultButton>
				</Tooltip>
			</div>
			{showAddModal ? (
				<OutsideClick
					targetClass={classes.elem('modal-custom-size').b()}
					outsideClick={() => (newColumnTag && newColumnName ? setShowInlineAction('top') : closeModal())}
				>
					<ModalWrap>
						<Modal size="md" className={classes.elem('modal-custom-size').b()}>
							<ModalHeader
								title={lang.newColumn}
								onClose={() =>
									newColumnTag && newColumnName ? setShowInlineAction('top') : closeModal()
								}
							/>
							{showInlineAction === 'top' ? (
								<InlineAction
									toggleInlineAction={() => setShowInlineAction(null)}
									onReject={closeModal}
									onConfirm={onAddColumn}
									showTop
								/>
							) : null}
							<ModalContent className={classes.elem('modal-content').b()}>
								<Row className={classes.elem('add-modal').b()}>
									<Column className={classes.elem('add-modal-left').b()}>
										<Row className={classes.elem('add-modal-input').b()}>
											<Label required value={newColumnName}>
												{lang.nameOfColumn}
											</Label>
											<Input
												value={newColumnName}
												onChange={e => setNewColumnName(e.target.value)}
												data-testid="new-column-name"
											></Input>
										</Row>
										<Row className={classes.elem('add-modal-input').b()}>
											<Label required>{lang.behaviour}</Label>
											<Input
												data-testid="new-column-behaviour"
												value={newColumnTag}
												onChange={e => setNewColumnTag(e.target.value)}
											></Input>
											<Text size="sm">{lang.behaviourInfo}</Text>
										</Row>
									</Column>
									<Column className={classes.elem('add-modal-right').b()}>
										<DocumentTemplateAvailableTags
											handleClick={handleClick}
											isProductTable={isProductTable}
										/>
									</Column>
								</Row>
							</ModalContent>
							<ModalControls>
								<Tooltip title={lang.requiredFields} disabled={!!newColumnName && !!newColumnTag}>
									<PrimaryButton
										disabled={!newColumnTag || !newColumnName}
										onClick={() => {
											onAddColumn();
										}}
										data-testid="add-modal-save"
									>
										{lang.save}
									</PrimaryButton>
								</Tooltip>
								<ThirdButton
									onClick={() =>
										newColumnTag && newColumnName ? setShowInlineAction('bottom') : closeModal()
									}
								>
									{lang.cancel}
								</ThirdButton>
								{showInlineAction === 'bottom' ? (
									<InlineAction
										toggleInlineAction={() => setShowInlineAction(null)}
										onReject={closeModal}
										onConfirm={onAddColumn}
										showBottom
									/>
								) : null}
							</ModalControls>
						</Modal>
					</ModalWrap>
				</OutsideClick>
			) : null}
		</>
	);
};

'use strict';

angular.module('services.locale', []).factory('localeService', [
	'$locale',
	function ($locale) {
		var instance = {};
		var esignLang = 'sv';
		var esignLanguages = {
			sv: 'sv',
			en: 'en'
		};

		instance.setLocale = function (id) {
			if (!configs[id]) {
				return;
			}

			angular.forEach(configs[id], function (val, key) {
				$locale[key] = val;
			});

			esignLang = esignLanguages[id] || esignLanguages.sv;
		};

		instance.getEsignLang = function () {
			return esignLang;
		};

		var configs = {
			sv: {
				DATETIME_FORMATS: {
					AMPMS: ['fm', 'em'],
					DAY: ['s\u00f6ndag', 'm\u00e5ndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'l\u00f6rdag'],
					MONTH: [
						'januari',
						'februari',
						'mars',
						'april',
						'maj',
						'juni',
						'juli',
						'augusti',
						'september',
						'oktober',
						'november',
						'december'
					],
					SHORTDAY: ['s\u00f6n', 'm\u00e5n', 'tis', 'ons', 'tors', 'fre', 'l\u00f6r'],
					SHORTMONTH: [
						'jan.',
						'feb.',
						'mars',
						'apr.',
						'maj',
						'juni',
						'juli',
						'aug.',
						'sep.',
						'okt.',
						'nov.',
						'dec.'
					],
					fullDate: 'EEEE d MMMM y',
					longDate: 'd MMMM y',
					medium: 'd MMM y HH:mm:ss',
					mediumDate: 'd MMM y',
					mediumTime: 'HH:mm:ss',
					short: 'y-MM-dd HH:mm',
					shortDate: 'y-MM-dd',
					shortTime: 'HH:mm'
				},
				NUMBER_FORMATS: {
					CURRENCY_SYM: 'kr',
					DECIMAL_SEP: ',',
					GROUP_SEP: ' ',
					PATTERNS: [
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 3,
							minFrac: 0,
							minInt: 1,
							negPre: '-',
							negSuf: '',
							posPre: '',
							posSuf: ''
						},
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 2,
							minFrac: 2,
							minInt: 1,
							negPre: '-',
							negSuf: '\u00a0\u00a4',
							posPre: '',
							posSuf: '\u00a0\u00a4'
						}
					]
				}
			}
		};

		return instance;
	}
]);

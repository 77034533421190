'use strict';

import AdvancedSearchView from '../../../../babel/components/AdvancedSearchView/index.js';

angular.module('upDirectives').directive('advancedSearchViewPicker', [
	'$translate',
	'AppService',
	'$timeout',
	'$rootScope',
	'FilterHelper',
	'$multiSelect',
	'$filter',
	'$upModal',
	'$state',
	'$parse',
	'avatarService',
	function () {
		return {
			restrict: 'A',
			template: '<div></div>',
			replace: true,
			scope: {
				filters: '=',
				searchView: '=',
				nextSearchView: '=',
				selectedView: '=',
				onViewChange: '&'
			},
			link: function ($scope, $element) {
				let firstElement = $element[0];
				let renderTimeout;

				const render = () => {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					renderTimeout = setTimeout(() => {
						if (!firstElement) {
							return;
						}

						const filters = {};

						$scope.searchView.resultTypes.forEach(resultType => {
							filters[resultType] = $scope.filters[resultType];
						});
						ReactDOM.render(
							React.createElement(AdvancedSearchView, {
								filters,
								nextSearchView: $scope.nextSearchView,
								searchView: $scope.searchView,
								selectedView: $scope.selectedView,
								onViewChange: view => $scope.onViewChange()(view)
							}),
							firstElement,
							() => {}
						);
					}, 20);
				};

				$scope.$on('$destroy', () => {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});
				$scope.$watch('searchView', render, true);
				$scope.$watch('selectedView', render, true);
				$scope.$watch('nextSearchView', render, true);
				$scope.$watch('filters', render, true);
			}
		};
	}
]);

'use strict';

angular.module('upResources').factory('LeaderAccount', [
	'$resource',
	'$q',
	'LeaderAccountAttributes',
	'URL',
	'API',
	'ResourceHelper',
	'AppService',
	'$rootScope',
	function LeaderAccount($resource, $q, LeaderAccountAttributes, URL, API, ResourceHelper, AppService, $rootScope) {
		var helper = new ResourceHelper();

		var AA = LeaderAccountAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var LeaderScript = $resource(URL + API + 'master/script', {}, {});

		var Resource = $resource(
			URL + API + 'master/leadenhancerAccount/:id',
			{},
			{
				query: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);

				return Resource.get(params).$promise;
			},

			get: function (filter, options) {
				var params = angular.extend(filter, options);

				return Resource.get(params).$promise;
			},

			save: function () {
				var defered = $q.defer();

				Resource.create(
					{},
					function (response) {
						if (response.error) {
							defered.reject(response);
						}
						var metadata = AppService.getMetadata();
						metadata.map.tracking = true;

						AppService.setMetadata(metadata);

						$rootScope.$broadcast('metadata.updated', metadata);

						defered.resolve(response);
					},
					function (err) {
						helper.onSaveError({}, true, err);
						defered.reject(err);
					}
				);

				return defered.promise;
			},

			getScript: function () {
				return LeaderScript.get({}).$promise;
			},

			activationStatus: function () {
				var defered = $q.defer();
				Resource.get()
					.$promise.then(function () {
						defered.resolve({ success: true, statusCode: 200 });
					})
					.catch(function (res) {
						defered.resolve({ success: false, statusCode: res.status });
					});

				return defered.promise;
			},

			delete: function () {
				var defered = $q.defer();

				return defered.promise;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

import Contact from 'App/resources/Model/Contact';

export enum NOT_ABLE_TO_DEBOUNCE_REASONS {
	NOT_BOUNCED,
	MAX_REMOVALS_REACHED
}

const ALLOWED_BOUNCE_REMOVAL_PERCENTAGE = 2;

export const checkUnbounceContact = (contact: Contact) => {
	let valid = true,
		reason = null;

	const totalNumberOfContacts = Tools.AppService.getTotals('contacts');
	const maxAllowedBounceRemovals = Math.floor((totalNumberOfContacts * ALLOWED_BOUNCE_REMOVAL_PERCENTAGE) / 100);
	const currentNumberOfBounceRemovals = Tools.AppService.getMetadata().params.RemovedBouncesCurrentMonth ?? 0;
	const newNumberOfBounceRemovals = currentNumberOfBounceRemovals + 1;

	if (newNumberOfBounceRemovals > maxAllowedBounceRemovals) {
		valid = false;
		reason = NOT_ABLE_TO_DEBOUNCE_REASONS.MAX_REMOVALS_REACHED;
	} else if (!contact?.mailBounces?.includes('hard_bounce')) {
		valid = false;
		reason = NOT_ABLE_TO_DEBOUNCE_REASONS.NOT_BOUNCED;
	}

	return { valid, reason };
};

import { AllIWantData } from 'App/resources/AllIWant';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

export default function ListSocialEventsAllowed() {
	const { self, accessRights } = useSelector(({ App }: RootState) => App);
	const hasEventAccess = (accessRights as AllIWantData['accessRights']).SocialEvent;
	if (self?.userParams.mailAdmin || self?.administrator || hasEventAccess) {
		return true;
	}
	return false;
}

import React from 'react';
import withDrilldown from './withDrilldown';
import ActivityDrilldown from './ActivityDrilldown.js';

const InputWithDrillDown = withDrilldown(ActivityDrilldown, { field: 'hasActivity', text: 'default.activities' });

export default function SegmentActivityInput(props) {
	return <InputWithDrillDown {...props} />;
}

window.SegmentActivityInput = SegmentActivityInput;

import { currencyFormat, numberFormat } from '../Filters/Currencies';
import {
	setData,
	remove,
	save,
	cancel,
	updateData,
	distribute,
	hasDateRange,
	setDate,
	toggleRowToAccrue,
	PERIODIZATION_FIELD,
	setField,
	getOrderRowValue,
	cleanFloat
} from 'Store/reducers/PeriodizationReducer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import './Periodization.scss';
import { PrimaryButton } from '@upsales/components/Buttons';
import Bem from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { hasRRWithCM } from 'App/helpers/salesModelHelpers';

import {
	TableHeader,
	TableRow,
	TableColumn,
	Table,
	Label,
	Button,
	ButtonGroup,
	Icon,
	DateInput,
	ModalHeader,
	ModalControls,
	Text,
	Tooltip,
	ButtonSelect
} from '@upsales/components';

const mapStateToProps = state => ({
	order: state.Periodization.order,
	pending: state.Periodization.pending,
	startDate: state.Periodization.startDate,
	endDate: state.Periodization.endDate,
	startDateInput: state.Periodization.startDateInput,
	endDateInput: state.Periodization.endDateInput,
	isInvalid: state.Periodization.isInvalid,
	invalidIssue: state.Periodization.invalidIssue,
	data: state.Periodization.data,
	field: state.Periodization.field,
	rowsToAccure: Object.keys(state.Periodization.rowsToAccure).reduce((memo, key) => {
		if (state.Periodization.rowsToAccure[key]) {
			memo[key] = state.Periodization.rowsToAccure[key];
		}

		return memo;
	}, {})
});

const mapDispatchToProps = {
	save,
	cancel,
	setData,
	remove,
	updateData,
	distribute,
	hasDateRange,
	setDate,
	toggleRowToAccrue,
	setField
};

class Periodization extends React.PureComponent {
	constructor(props) {
		super(props);

		this.isOrder = !!this.props.order.closeDate;
		this.isEdit = !!this.props.order.id;

		this.lang = {
			products: T('default.products'),
			total: T('default.total'),
			totalCostPerMonth: T('order.periodization.totalCostPerMonth'),
			months: moment.monthsShort(),
			edit: T('default.edit'),
			create: T('default.create'),
			order: T('default.order'),
			opportunity: T('default.opportunity'),
			savePeriodization: T('order.savePeriodization'),
			removePeriodization: T('order.removePeriodization'),
			cancel: T('cancel'),
			startDate: T('default.startDate'),
			endDate: T('default.endDate'),
			select: T('default.select'),
			distribute: T('order.periodization.distribute'),
			toDistribute: T('order.periodization.toDistribute'),
			masterCurrency: T('admin.masterCurrency'),
			editRowAccural: T('order.periodization.editRowAccural'),
			accureTip: T('order.periodization.accureTip'),
			value: T('periodization.value').toLowerCase(),
			purchaseCost: T('default.purchasedAtCost').toLowerCase()
		};
		this.lang.valueAndPurchaseCost =
			this.lang.value.toLowerCase() + ' ' + T('and') + ' ' + this.lang.purchaseCost.toLowerCase();

		const params = Tools.AppService.getMetadata().params;

		this.hasContributionMargin =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CONTRIBUTION_MARGIN) &&
			(params.UseContributionMargin || hasRRWithCM());

		this.state = {
			startDateInvalid: false,
			endDateInvalid: false
		};
	}

	renderModalHeader() {
		const title =
			(this.isEdit ? this.lang.edit : this.lang.create) +
			' ' +
			(this.isOrder ? this.lang.order : this.lang.opportunity);

		const { cancel, toggle } = this.props;
		return (
			<ModalHeader
				icon="pencil-square-o"
				title={title}
				onClose={() => {
					cancel();
					toggle();
				}}
			/>
		);
	}

	distribute = () => {
		const { distribute, startDate, endDate } = this.props;

		if (startDate && endDate) {
			distribute();
		} else {
			this.setState({ startDateInvalid: !startDate, endDateInvalid: !endDate });
		}
	};

	setStartDate = event => {
		this.setDate('start', event.target.value);
	};

	setEndDate = event => {
		this.setDate('end', event.target.value);
	};

	setDate = (key, value) => {
		this.setState({ [`${key}DateInvalid`]: false });
		this.props.setDate(key, value);
	};

	renderDateRange() {
		const { startDateInput, endDateInput, setField, field } = this.props;
		const { startDateInvalid, endDateInvalid } = this.state;

		return (
			<div className="periodization-date-range-wrapper">
				<ButtonGroup>
					<div className="period-start">
						<Label>{this.lang.startDate}</Label>
						<DateInput
							value={startDateInput}
							icon="calendar"
							closeOnSelect={true}
							placeholder={this.lang.select + ' ' + this.lang.startDate}
							onChange={this.setStartDate}
							max={endDateInput}
							state={startDateInvalid ? 'error' : undefined}
							showWeekNumbers={true}
							calendarSize={6}
						/>
					</div>
					<div className="period-end">
						<Label>{this.lang.endDate}</Label>
						<DateInput
							value={endDateInput}
							icon="calendar"
							closeOnSelect={true}
							placeholder={this.lang.select + ' ' + this.lang.endDate}
							onChange={this.setEndDate}
							min={startDateInput}
							state={endDateInvalid ? 'error' : undefined}
							showWeekNumbers={true}
							calendarSize={6}
						/>
					</div>
					<Button type="lined" className="no-shadow" onClick={this.distribute}>
						{this.lang.distribute}
					</Button>
				</ButtonGroup>
				{this.hasContributionMargin ? (
					<ButtonSelect
						value={field}
						size="md"
						options={[
							{ title: this.lang.value, value: PERIODIZATION_FIELD.VALUE },
							{ title: this.lang.purchaseCost, value: PERIODIZATION_FIELD.PURCHASE_COST }
						]}
						onChange={setField}
					/>
				) : null}
			</div>
		);
	}

	renderModalControls() {
		const { isInvalid, cancel, save, remove, toggle, pending, invalidIssue } = this.props;

		const langKey = invalidIssue === PERIODIZATION_FIELD.VALUE_AND_PURCHASE_COST ? 'tooltipBoth' : 'tooltip';
		const saveButtonTooltip = T('periodization.saveButton.' + langKey, { invalidIssue: this.lang[invalidIssue] });
		return (
			<ModalControls>
				{Object.keys(this.props.data).length ? (
					<Button
						color="grey"
						type="link"
						className="pull-left"
						shadow="none"
						onClick={() => {
							remove()
								.then(() => toggle())
								.catch(e => console.error('Failed to remove', e));
						}}
					>
						<Icon name="trash-o" />
						&nbsp;&nbsp;
						{this.lang.removePeriodization}
					</Button>
				) : null}
				<Tooltip disabled={!isInvalid} title={saveButtonTooltip}>
					<PrimaryButton
						shadow="none"
						loading={pending}
						disabled={pending || isInvalid}
						onClick={() => {
							save()
								.then(() => toggle())
								.catch(e => console.error('Failed to save', e));
						}}
					>
						{this.lang.savePeriodization}
					</PrimaryButton>
				</Tooltip>
				<Button
					color="grey"
					type="link"
					onClick={() => {
						cancel();
						toggle();
					}}
				>
					{this.lang.cancel}
				</Button>
			</ModalControls>
		);
	}

	renderProductHeader() {
		return (
			<TableHeader className="admin-table-header">
				<TableColumn className="admin-table-cell">{this.lang.products}</TableColumn>
			</TableHeader>
		);
	}

	renderProductRow(orderRow, currency) {
		const { field, rowsToAccure } = this.props;
		const hasRowsToAccure = Object.keys(rowsToAccure).length > 0;
		const classes = new Bem('Periodization');
		const isEnabled = !hasRowsToAccure || rowsToAccure[orderRow.id];
		const value = field === PERIODIZATION_FIELD.VALUE ? orderRow.price : orderRow.purchaseCost;
		const isTotalTier = orderRow.product?.tiers?.[0]?.isTotalPrice;
		const quantity = orderRow.quantity || 0;
		const formattedQuantity = numberFormat(quantity);
		const needsTooltip = quantity.toString().length !== formattedQuantity.toString().length;
		let renderedQuantity;
		if (needsTooltip) {
			renderedQuantity = <Tooltip title={quantity}>{formattedQuantity}</Tooltip>;
		} else {
			renderedQuantity = formattedQuantity;
		}

		return (
			<TableRow
				key={orderRow.$$hashKey}
				className={
					rowsToAccure[orderRow.id]
						? classes.elem('TableRow').mod('selected').b()
						: hasRowsToAccure
						? classes.elem('TableRow').mod('disabled').b()
						: classes.elem('TableRow').b()
				}
				onClick={() => this.props.toggleRowToAccrue(orderRow.id)}
			>
				<TableColumn size="lg" className="TableColumn--has-subtitle" color={isEnabled ? 'black' : 'grey-5'}>
					<Tooltip title={this.lang.editRowAccural} position="right">
						<div className="product-container">
							<div className="text-ellipsis">{orderRow.product.name}</div>
							<div
								className={
									'TableColumn__subtitle ' +
									(isEnabled ? 'TableColumn__subtitle--dark-grey' : 'TableColumn__subtitle--grey-5')
								}
							>
								<span>
									{renderedQuantity}{' '}
									{(isTotalTier ? ' @ ' : ' x ') + currencyFormat(value, currency, false, 2)}
								</span>
							</div>
						</div>
					</Tooltip>
				</TableColumn>
			</TableRow>
		);
	}

	renderProductFooter() {
		return (
			<TableRow className="table-footer product-table-footer">
				<TableColumn>{this.lang.totalCostPerMonth}</TableColumn>
			</TableRow>
		);
	}

	renderInlineEdit(rowId, index) {
		const { updateData, data, field } = this.props;

		const hasRowsToAccure = Object.keys(this.props.rowsToAccure).length > 0;
		const isEnabled = !hasRowsToAccure || this.props.rowsToAccure[rowId];

		return (
			<ReactTemplates.admin.quotaInput
				field={'value'}
				value={{ value: data[rowId][index][field] }}
				onChange={obj => {
					updateData(rowId, index, obj.value);
				}}
				buttonAction={(ignore, value) => {
					updateData(rowId, index, value, true);
				}}
				buttonValue={''}
				tools={Tools}
				showSubtitle={false}
				subtitle={''}
				currencyRate={1}
				max={1699605031}
				disabled={!isEnabled}
				noRound={true}
				allowNegativeValues={true}
			/>
		);
	}

	renderPeriodizationHeader() {
		const { data, order } = this.props;
		const firstRowId = order.orderRow[0].id;

		return (
			<TableHeader className="admin-table-header">
				{_.map(data[firstRowId], (period, index) => {
					return (
						<TableColumn className="admin-table-cell" key={index}>
							<Text size="sm">{this.lang.months[period.month - 1]}</Text>
							<Text size="sm" color="grey-10">
								{period.year}
							</Text>
						</TableColumn>
					);
				})}
			</TableHeader>
		);
	}

	renderPeriodizationColumn(rowId, index) {
		return <TableColumn key={index}>{this.renderInlineEdit(rowId, index)}</TableColumn>;
	}

	renderPeriodizationFooter(footerTotal) {
		var periodizationFooterCols = [];

		_.forEach(footerTotal, (value, index) => {
			periodizationFooterCols.push(<TableColumn key={index}>{numberFormat(value)}</TableColumn>);
		});

		return (
			<Table className="table-footer">
				<TableRow className="periodization-table-footer">{periodizationFooterCols}</TableRow>
			</Table>
		);
	}

	renderTotalHeader() {
		return (
			<TableHeader className="admin-table-header">
				<TableColumn className="admin-table-cell">{this.lang.total}</TableColumn>
			</TableHeader>
		);
	}

	renderTotalRow(orderRow, rowSum) {
		const { field } = this.props;
		const orderTotal = getOrderRowValue(orderRow, field);
		const toDistribute = cleanFloat(orderTotal - cleanFloat(rowSum));

		return (
			<TableRow key={orderRow.$$hashKey}>
				<TableColumn className={toDistribute < 0 ? 'warning' : ''}>
					<div className="total-footer-total">
						{currencyFormat(rowSum, this.props.order.currency, false, 2)}
					</div>
					<div className="total-footer-distribute">
						{this.lang.toDistribute +
							': ' +
							currencyFormat(toDistribute, this.props.order.currency, false, 2)}
					</div>
				</TableColumn>
			</TableRow>
		);
	}

	renderTotalFooter(sum) {
		const { order, field } = this.props;
		let totalValue = 0;
		_.forEach(order.orderRow, orderRow => {
			totalValue += getOrderRowValue(orderRow, field);
		});
		const toDistribute = cleanFloat(totalValue - cleanFloat(sum));

		return (
			<TableRow className="table-footer total-table-footer">
				<TableColumn className={toDistribute < 0 ? 'warning' : ''}>
					<div className="total-footer-total">
						{this.lang.total + ': ' + currencyFormat(sum, this.props.order.currency, false, 2)}
					</div>
					<div className="total-footer-distribute">
						{this.lang.toDistribute +
							': ' +
							currencyFormat(toDistribute, this.props.order.currency, false, 2)}
					</div>
				</TableColumn>
			</TableRow>
		);
	}

	render() {
		const productTableHeader = this.renderProductHeader(); //
		const productTableRows = [];
		const productTableFooter = this.renderProductFooter(); //

		const periodizationTableHeader = this.renderPeriodizationHeader(); //
		const periodizationTableRows = [];

		const totalTableHeader = this.renderTotalHeader(); //
		const totalTableRows = [];

		const { order, data, hasDateRange, field } = this.props;
		const footerTotal = [];

		_.forEach(order.orderRow, orderRow => {
			productTableRows.push(this.renderProductRow(orderRow, order.currency)); //

			const rowArray = data && data[orderRow.id];

			let rowSum = 0;
			if (rowArray) {
				const periodizationCols = [];
				rowSum = 0;
				_.forEach(rowArray, (entry, index) => {
					if (footerTotal[index] === undefined) {
						footerTotal.push(0);
					}

					rowSum += entry[field];
					footerTotal[index] += entry[field];

					periodizationCols.push(this.renderPeriodizationColumn(orderRow.id, index));
				});

				periodizationTableRows.push(<TableRow key={orderRow.$$hashKey}>{periodizationCols}</TableRow>);
			}

			totalTableRows.push(this.renderTotalRow(orderRow, rowSum)); //
		});

		const periodizationTableFooter = this.renderPeriodizationFooter(footerTotal);
		const totalTableFooter = this.renderTotalFooter(_.sum(footerTotal));

		const productTable = (
			<div className="periodization-product-table-wrap">
				<Table className={'periodization-product-table'}>
					{productTableHeader}
					{productTableRows}
					{productTableFooter}
				</Table>
			</div>
		);

		const periodizationTable = (
			<div
				className="periodization-data-table-wrap"
				ref={r => {
					this._dataTable = r;
				}}
			>
				<Table className={'periodization-data-table'}>
					{periodizationTableHeader}
					{periodizationTableRows}
				</Table>
				{periodizationTableFooter}
			</div>
		);

		const totalTable = (
			<div className="periodization-total-table-wrap">
				<Table className={'periodization-total-table'}>
					{totalTableHeader}
					{totalTableRows}
					{totalTableFooter}
				</Table>
			</div>
		);

		const periodizationPlaceholder = (
			<div className="periodization-placeholder">
				<Text size="lg" color="grey-11">
					<Icon name="reply" color="grey-11" />
					{this.lang.accureTip}
				</Text>
			</div>
		);

		return (
			<div className="Periodization table-wrapper">
				{this.renderModalHeader()}
				{this.renderDateRange()}
				<div className="Periodization_inner-table-container">
					<div className="Periodization_inner-table-wrapper">
						{productTable}
						{hasDateRange() ? periodizationTable : periodizationPlaceholder}
						{totalTable}
					</div>
				</div>
				{this.renderModalControls()}
			</div>
		);
	}
}

Periodization.propTypes = {
	order: PropTypes.object,
	save: PropTypes.func,
	cancel: PropTypes.func,
	remove: PropTypes.func,
	toggle: PropTypes.func,
	isInvalid: PropTypes.bool,
	invalidIssue: PropTypes.string,
	pending: PropTypes.bool,
	data: PropTypes.object,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	startDateInput: PropTypes.instanceOf(Date),
	endDateInput: PropTypes.instanceOf(Date),
	setDate: PropTypes.func,
	updateData: PropTypes.func,
	distribute: PropTypes.func,
	hasDateRange: PropTypes.func,
	setData: PropTypes.func,
	toggleRowToAccrue: PropTypes.func,
	rowsToAccure: PropTypes.object
};

export const detached = Periodization;
const Component = connect(mapStateToProps, mapDispatchToProps)(Periodization);

window.Periodization = Component;

export default Component;

import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@upsales/components';
import CriteriaDrilldownWrapper from '../../../CriteriaDrilldownWrapper';
import NumberInput from '../../Number';
import Bem from '@upsales/components/Utils/bemClass';
import { cloneDeep } from 'lodash';

export default class EmployeesDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const { $translate: t, FeatureHelper } = Tools;

		this.lang = {
			noEmployees: t('default.noEmployees'),
			noEmployeesChange: t('prospecting.filters.noEmployeesChange'),
			proFilter: t('prospecting.filters.proFilter'),
			upgradeToProToUse: t('prospecting.filters.upgradeToProToUse'),
			closed: t('default.closed'),
			open: t('default.open')
		};

		this.onNoEmployeesChanged = this.onChange.bind(this, 'noEmployees');
		this.onNoEmployeesChangeChanged = this.onChange.bind(this, 'noEmployeesChange');

		this.hasPro = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);

		this.offsets = [
			{ name: t('prospecting.filters.last1Year'), id: 1 },
			{ name: t('prospecting.filters.last2Year'), id: 2 },
			{ name: t('prospecting.filters.last3Year'), id: 3 },
			{ name: t('prospecting.filters.last4Year'), id: 4 }
		];
	}

	onOffsetChange = event => {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.value.noEmployeesChange.value.offset = event.target.added.id;
		onChange(newFilter.filterName, newFilter);
	};

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = cloneDeep(filter);
		newFilter.value[key].value = value;
		onChange(newFilter.filterName, newFilter);
	}

	render() {
		const { filter, open } = this.props;
		const offset = filter.value.noEmployeesChange.value.offset;
		const offsetObject = this.offsets.find(option => option.id === offset);

		const classes = new Bem('ProspectingDrilldown');

		return (
			<CriteriaDrilldownWrapper
				key={filter.filterName + '-drilldown'}
				className={classes.mod('employees').b()}
				open={open}
			>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/* NoEmployees row */}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.noEmployees}</div>
									</td>
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<NumberInput
													space={true}
													filter={filter.value.noEmployees}
													onChange={this.onNoEmployeesChanged}
													autoFocus={true}
												/>
											</div>
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/* NoEmployees changed row */}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.noEmployeesChange}</div>
										{this.hasPro ? null : (
											<Text color="bright-blue" size="sm">
												{this.lang.proFilter}
											</Text>
										)}
									</td>
									<td className="table-col">
										<div className="flex-row">
											<div className="drilldown-row">
												<NumberInput
													unit={'%'}
													space={true}
													filter={filter.value.noEmployeesChange}
													onChange={this.onNoEmployeesChangeChanged}
													autoFocus={false}
													disabled={!this.hasPro}
												/>
												<ReactTemplates.INPUTS.upSelect
													className="form-control"
													disabled={!this.hasPro}
													data={this.offsets}
													required={true}
													multiple={false}
													onChange={this.onOffsetChange}
													defaultValue={offsetObject}
													options={{ minimumResultsForSearch: -1 }}
												/>
											</div>
											{this.hasPro ? null : (
												<Text
													className={classes.elem('pro-filter').b()}
													color="bright-blue"
													size="sm"
													italic={true}
												>
													{this.lang.upgradeToProToUse}
												</Text>
											)}
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

EmployeesDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

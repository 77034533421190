import React from 'react';
import PropTypes from 'prop-types';
import './SupersearchHighlight.scss';

const escapeRegex = text => {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const isMatch = (text, searchStr) => {
	if (text && searchStr) {
		const escapedText = escapeRegex(searchStr.toString());
		const reg = new RegExp(escapedText, 'gi');
		const matches = text.toString().match(reg);
		return !!(matches && matches.length);
	} else {
		return false;
	}
};

const diacritic = text => {
	return text
		.split('')
		.map(function (char) {
			if ('aâàáåä'.indexOf(char) > -1) {
				return '[aâàáåä]';
			}
			if ('eêëèé'.indexOf(char) > -1) {
				return '[eêëèé]';
			}
			if ('[iîïìí]'.indexOf(char) > -1) {
				return '[iîïìí]';
			}
			if ('[oôòóö]'.indexOf(char) > -1) {
				return '[oôòóö]';
			}
			if ('uûüùú'.indexOf(char) > -1) {
				return '[uûüùú]';
			}
			if ('[wŵẅẁẃ]'.indexOf(char) > -1) {
				return '[wŵẅẁẃ]';
			}
			if ('[yŷÿỳý]'.indexOf(char) > -1) {
				return '[yŷÿỳý]';
			}
			return char;
		})
		.join('');
};

const getRegExp = (value, type) => {
	let reg;
	let escapedText = escapeRegex(value.toString());
	if (type === 'email' && value[0] !== '@') {
		escapedText = diacritic(escapedText);
		reg = new RegExp('(^|\\.|\\-|@)' + escapedText, 'gi');
	} else if (type === 'phone' && /[0-9 -+()]{5,}/.test(escapedText)) {
		const phoneRegexp =
			escapedText
				.split('')
				.filter(function (char) {
					return char >= '0' && char <= '9';
				})
				.map(function (char) {
					return '.*' + char;
				})
				.join('') + '.*';

		reg = new RegExp(phoneRegexp, 'gi');
	} else if (type === 'string') {
		escapedText = diacritic(escapedText);
		reg = new RegExp('\\b' + escapedText, 'gi');
	} else {
		reg = new RegExp(escapedText, 'gi');
	}
	return reg;
};

const SupersearchHighlight = ({ searchStr, text, type }) => {
	if (!searchStr) {
		return text;
	}

	if (!text) {
		return null;
	}
	const reg = getRegExp(searchStr.toString().trim(), type);
	const segments = text.split(reg);
	const matches = text.match(reg);

	const res = segments.reduce((res, segment, i) => {
		if (segment !== '@') {
			res.push(<span key={'nomatch-' + i}>{segment}</span>);
		}
		if (segments.length === i + 1) {
			return res;
		}
		if (matches[i] && matches[i].length) {
			res.push(
				<span key={'match-' + i} className="SupersearchHighlight__match">
					<span>{matches[i]}</span>
				</span>
			);
		}
		return res;
	}, []);

	return <span className="SupersearchHighlight">{res}</span>;
};

SupersearchHighlight.propTypes = {
	searchStr: PropTypes.string,
	text: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['email', 'string', 'phone'])
};

export default SupersearchHighlight;

'use strict';

angular.module('domain.segment').controller('newSegmentModal', [
	'$scope',
	'$translate',
	'AppService',
	'FeatureHelper',
	'$modalParams',
	'$q',
	'Segment',
	'$state',
	function ($scope, $translate, AppService, FeatureHelper, $modalParams, $q, Segment, $state) {
		var renderTimeout;
		var root;

		var props = {
			saving: false,
			segment: $modalParams.segment ? $modalParams.segment : { name: '', description: '', active: true },
			returnData: $modalParams.fromFlow || $modalParams.noRedirect || false,
			tools: {
				AppService: AppService,
				$translate: $translate,
				$q: $q
			},
			closeModal: function () {
				$scope.reject();
			},
			onSave: function (obj, isEdit, returnData) {
				props.saving = true;

				if (isEdit) {
					obj.id = $modalParams.segment.id;
				} else {
					obj.filter = [
						{
							orGroup: false,
							isExclude: false,
							config: {
								Active: {
									comparisonType: 'Equals',
									filterName: 'Active',
									dataType: 'radio',
									field: 'active',
									value: true,
									inactive: false
								}
							},
							body: {
								q: [
									{
										a: 'active',
										c: 'eq',
										v: true
									}
								]
							}
						}
					];
				}

				Segment.save(obj)
					.then(function (res) {
						var options = {
							id: res.data.id
						};

						if (isEdit || returnData) {
							$scope.resolve(res.data);
						} else {
							$state.go('segment.contacts', options);
						}
					})
					.catch(function () {
						props.saving = false;
						render();
					});

				render();
			}
		};

		function render() {
			if (!root) {
				root = document.getElementById('newSegment__root');
			}

			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			var react = ReactTemplates.segment.newSegmentModal;
			renderTimeout = setTimeout(function () {
				if (!root) {
					return;
				}

				ReactDOM.render(React.createElement(react, props), root);
			}, 20);
		}

		var init = function () {
			render();
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(root);
			root = undefined;
		});

		$scope.$on('modal.ready', function () {
			init();
		});
	}
]);

import logError from 'App/babel/helpers/logError';
import MailIntegration from 'App/babel/resources/MailIntegration';

angular.module('domain.mail').controller('PreviewInfo', [
	'$scope',
	'$modalParams',
	'$translate',
	'Mail',
	'AppService',
	'$safeApply',
	async function ($scope, $modalParams, $translate, Mail, AppService, $safeApply) {
		const mail = angular.copy($modalParams.mail);
		mail.isTest = true;
		const contact = $modalParams.contact;
		const mailIntegration = $modalParams.mailIntegration;
		let ownEmail = '';

		if (mailIntegration) {
			try {
				const integrationProfile = await MailIntegration.getProfile();
				ownEmail = integrationProfile.email;
			} catch (error) {
				logError(error, 'Failed to load mail integration account');
				const self = AppService.getSelf();
				ownEmail = self.email;
			}
		} else {
			const self = AppService.getSelf();
			ownEmail = self.email;
		}

		$scope.addresses = [ownEmail];
		$safeApply($scope);

		$scope.previewWindow = function () {
			const preview = window.open(
				'',
				'',
				'height=700,width=800,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
			);

			const html =
				'<head>' +
				'<style>' +
				'body {' +
				'margin: 0;' +
				'border-top: 5px solid #002f65;' +
				'color: #333;' +
				'font-family: Helvetica;' +
				'font-size: 14px;' +
				'}' +
				'#info h2 {' +
				'color: #002f65;' +
				'font-family: Helvetica;' +
				'font-size: 22px;' +
				'margin-bottom: 6px;' +
				'}' +
				'#info p{' +
				'margin-top: 2px;' +
				'margin-bottom: 2px;' +
				'}' +
				'#emailHeaders {' +
				'font-size:12px;' +
				'}' +
				'#emailHeaders tr {' +
				'border-bottom: 1px solid #f0f0f0;' +
				'}' +
				'</style>' +
				'</head>' +
				'<body>' +
				'<table border=0 width="100%" cellpadding="0" cellspacing="0">' +
				'<tr width="100%">' +
				'<td width="100%" id="info" bgcolor="#fff">' +
				'<font>' +
				'<h2>' +
				$translate.instant('mail.preview') +
				' ' +
				$translate.instant('mail.mail').toLowerCase() +
				'</h2>' +
				'<p>' +
				$translate.instant('mail.previewDisclaimer') +
				'.</p>' +
				'</font>' +
				'</td>' +
				'</tr>' +
				'<tr width="100%">' +
				'<td width="100%" id="emailHeaders">' +
				'<br>' +
				'<table border=0 width="100%">' +
				'<tr width="100%"><td><b>' +
				$translate.instant('mail.from') +
				':</b></td><td>' +
				(mail.fromName || mail.from) +
				' &lt;' +
				mail.from +
				'&gt;</td></tr>' +
				'<tr><td><b>' +
				$translate.instant('mail.to') +
				':</b></td><td>' +
				(mail.to ? mail.to : '-') +
				' </td></tr>' +
				'<tr><td><b>' +
				$translate.instant('mail.subject') +
				':</b></td><td>' +
				_.escape(mail.subject) +
				'</td></tr>' +
				'</table>' +
				'<br>' +
				'</td>' +
				'</tr>' +
				'<tr>' +
				'<td id="previewBody">' +
				(mail.body || '') +
				'</td>' +
				'</tr>' +
				'</table>' +
				'</body>';
			preview.document.write(html);
		};

		$scope.sendTest = function () {
			mail.to = $scope.addresses;
			if (contact) {
				mail.contact = contact.data.id;
				mail.client = contact.data.client.id;
			}

			mail.userId = $modalParams.userId;
			let promise;

			if (mailIntegration) {
				promise = MailIntegration.test(mail, {
					params: { integrationId: mailIntegration.id },
					skipEvent: true
				});
			} else {
				promise = Mail.customer($modalParams.customerId).sendTest(mail);
			}

			promise
				.then(() => {
					$scope.resolve();
				})
				.catch(error => {
					logError(error, 'Failed to send test mail');
				});
		};

		$scope.addOwnEmail = function () {
			$scope.addresses.push(ownEmail);
		};
	}
]);

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { Row, Icon, Tooltip } from '@upsales/components';
import QuotaWidget from './QuotaWidget';
import './QuotaCharts.scss';
import { openDrawer } from 'Services/Drawer';
import { connect } from 'react-redux';
import { init, getPinnedWidgetData, getWidgetData } from 'Store/actions/GoalsActions';
import ReduxListeners from 'Store/ReduxListeners';
import PropTypes from 'prop-types';
import T from 'Components/Helpers/translate';
import NavbarButton from 'App/navbar/NavbarButton';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { getSpeedTracker } from 'App/helpers/speedTracker';
import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';

const mapStateToProps = state => {
	return {
		pinnedWidgets: state.Goals.pinnedWidgets,
		userWidgets: state.Goals.userWidgets,
		showTeam: state.Goals.showTeam,
		accountSelf: state.App.accountSelf
	};
};

const mapDispatchToProps = {
	init
};

const entityEvents = [
	'order.added',
	'order.updated',
	'order.deleted',
	'appointment.added',
	'appointment.updated',
	'appointment.deleted',
	'opportunity.added',
	'opportunity.updated',
	'opportunity.deleted',
	'quota.added',
	'genericQuotas.added',
	'activityQuotas.added',
	'agreement.added',
	'agreement.updated',
	'agreement.deleted',
	'activity.updated'
];

class QuotaCharts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			title: ''
		};

		const hasSpeedQuotaChartsUpdate = getSoftDeployAccessFromState(props.accountSelf, 'SPEED_QUOTA_CHARTS_UPDATE');

		this.listeners = [
			...entityEvents.map(eventType =>
				ReduxListeners.add(eventType, data =>
					this.measureWidgetDataSpeed(hasSpeedQuotaChartsUpdate, data, eventType)()
				)
			),
			ReduxListeners.add('salesmodel.changed', init),
			ReduxListeners.add('role.updated', init)
		];
	}

	componentWillUnmount() {
		this.listeners.map(off => off());
	}

	componentDidMount() {
		this.props
			.init()
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(err => console.log(err));
	}

	measureWidgetDataSpeed = (hasSpeedQuotaChartsUpdate, eventData, eventType) => () => async dispatch => {
		const speedTracker = getSpeedTracker('QuotaChartsUpdate', true);
		speedTracker?.startTracking();
		if (hasSpeedQuotaChartsUpdate) {
			await getPinnedWidgetData({ type: eventType, data: eventData })(dispatch);
		} else {
			await getWidgetData({ type: eventType, data: eventData })(dispatch);
		}

		speedTracker?.publishInteractable();
		speedTracker?.sendResults(hasSpeedQuotaChartsUpdate);
	};

	renderWidgets() {
		const widgets = this.props.pinnedWidgets;
		return widgets.map(widget => (
			<QuotaWidget
				key={`${widget.group}.${widget.data.type.name}`}
				type={widget.data.type.name}
				progress={widget.data.total.progress}
				goal={widget.data.total.goal}
				currency={widget.data.currency}
			/>
		));
	}

	render() {
		const MainClass = new Bem('QuotaCharts');
		const { loading } = this.state;
		const numberOfWidgets = this.props.pinnedWidgets.length;
		const wrapperSize =
			numberOfWidgets === 3 ? 'lg' : numberOfWidgets === 2 ? 'md' : numberOfWidgets === 1 ? 'sm' : 'no-widgets';
		if (loading) {
			return null;
		} else {
			return (
				<NavbarButton
					className={MainClass.mod(wrapperSize).b()}
					onClick={() => {
						globalTracker.track('Clicked on quota charts');
						openDrawer('GoalSummaryDrawer');
					}}
				>
					{wrapperSize !== 'no-widgets' ? <Row>{this.renderWidgets()}</Row> : null}
					<div id="goals-flag">
						<Tooltip delay={200} title={T('goals.noTargetsTooltip')}>
							<Icon color="super-light-green" name="flag-checkered" />
						</Tooltip>
					</div>
				</NavbarButton>
			);
		}
	}
}

QuotaCharts.propTypes = {
	init: PropTypes.func.isRequired,
	pinnedWidgets: PropTypes.array,
	userWidgets: PropTypes.array,
	showTeam: PropTypes.bool,
	accountSelf: PropTypes.object
};

export const detached = QuotaCharts;

export default connect(mapStateToProps, mapDispatchToProps)(QuotaCharts);

import store from 'Store/index';
import * as FeatureHelperActions from 'Store/actions/FeatureHelperActions';

angular.module('upHelpers').factory('FeatureHelper', [
	'$q',
	'AppService',
	function ($q, AppService) {
		function isAvailableAddon(addon) {
			return store.dispatch(FeatureHelperActions.isAvailableAddon(addon));
		}

		function isAvailable(feature, searchAccount) {
			return store.dispatch(FeatureHelperActions.isAvailable(feature, searchAccount));
		}

		// This is only for angular and should never be used in react, i won't move it from this file
		function isAvailablePromise(feature) {
			var p = $q.defer();
			AppService.loadedPromise
				.then(function () {
					if (isAvailable(feature)) {
						p.resolve(true);
					} else {
						p.reject(false);
					}
				})
				.catch(function () {
					p.reject('error_loading_appservice');
				});
			return p.promise;
		}

		function canBuyAddon(feature) {
			return store.dispatch(FeatureHelperActions.canBuyAddon(feature));
		}

		function isAvailableProduct(product) {
			return store.dispatch(FeatureHelperActions.isAvailableProduct(product));
		}
		function getOngoingTrialAddons() {
			return store.dispatch(FeatureHelperActions.getOngoingTrialAddons());
		}

		// This is only for angular and should never be used in react, i won't move it from this file
		function isAvailableProductPromise(product) {
			var p = $q.defer();
			AppService.loadedPromise
				.then(function () {
					if (isAvailableProduct(product)) {
						p.resolve(true);
					} else {
						p.reject(false);
					}
				})
				.catch(function () {
					p.reject('error_loading_appservice');
				});
			return p.promise;
		}

		function hasSoftDeployAccess(feature) {
			return store.dispatch(FeatureHelperActions.hasSoftDeployAccess(feature));
		}

		function hasSoftDeployAccessUser(feature) {
			return store.dispatch(FeatureHelperActions.hasSoftDeployAccessUser(feature));
		}

		// This is only for angular and should never be used in react, i won't move it from this file
		function hasSoftDeployAccessPromise(feature) {
			return AppService.loadedPromise.then(function () {
				var defer = $q.defer();
				var promise = defer.promise;

				if (hasSoftDeployAccess(feature)) {
					defer.resolve();
				} else {
					defer.reject();
				}
				return promise;
			});
		}

		// This is only for angular and should never be used in react, i won't move it from this file
		function placeholderPromise(feature) {
			var p = $q.defer();
			AppService.loadedPromise
				.then(function () {
					if (!isAvailable(feature)) {
						p.reject('featureNotAvailable');
					} else {
						p.resolve(true);
					}
				})
				.catch(function () {
					p.reject('error_loading_appservice');
				});
			return p.promise;
		}

		return {
			Product: FeatureHelperActions.Product,
			Feature: FeatureHelperActions.Feature,
			Addon: FeatureHelperActions.Addon,
			isAvailable: isAvailable,
			isAvailableProduct: isAvailableProduct,
			isAvailablePromise: isAvailablePromise,
			isAvailableProductPromise: isAvailableProductPromise,
			placeholder: placeholderPromise,
			softDeployPromise: function (feature) {
				var p = $q.defer();
				AppService.loadedPromise
					.then(function () {
						if (hasSoftDeployAccess(feature)) {
							p.resolve(true);
						} else {
							p.reject(false);
						}
					})
					.catch(function () {
						p.reject('error_loading_appservice');
					});
				return p.promise;
			},
			hasSoftDeployAccessUser: hasSoftDeployAccessUser,
			hasSoftDeployAccess: hasSoftDeployAccess,
			hasSoftDeployAccessPromise: hasSoftDeployAccessPromise,
			isAvailableAddon: isAvailableAddon,
			canBuyAddon: canBuyAddon,
			getOngoingTrialAddons: getOngoingTrialAddons
		};
	}
]);

'use strict';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('Contact', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ContactAttributes',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	'FeatureHelper',
	function Contact(
		$resource,
		$q,
		URL,
		API,
		ContactAttributes,
		ResourceHelper,
		ParseGeneric,
		MapGeneric,
		FeatureHelper
	) {
		var Attributes = ContactAttributes().attr;
		var keys = ContactAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'contact',
			createSuccessBody: 'saved.contact',
			updateSuccessBody: 'updated.contact',
			deleteSucccessBody: 'deleted.contact',
			createErrorBody: 'saveError.contact',
			updateErrorBody: 'saveError.contact',
			deleteErrorBody: 'deleteError.contact',
			unsubSuccessBody: 'saved.unsub',
			resubSuccessBody: 'saved.resub',
			moveSuccessBody: 'saved.contactMoved',
			mergeSuccessTitle: 'default.done',
			mergeSuccessBody: '',
			mergeErrorTitle: 'default.error',
			mergeErrorBody: 'mergeError.contact',
			onclickLink: savedObject => {
				Tools.$state.go('contact.dashboard', { id: savedObject.id });
			},
			notificationType: TYPE.GENERIC_ENTITY_ADDED,
			extraSeconds: 1000
		});

		var dateFields = [
			'modDate',
			'regDate',
			'scoreUpdateDate',
			'hasActivity',
			'hadActivity',
			'hasAppointment',
			'hadAppointment',
			'hasOpportunity',
			'hadOpportunity',
			'hasOrder',
			'hadOrder'
		];
		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'contacts/';
		var unsubUrl = apiUrl + 'function/unsub/:id';
		const unbounceUrl = apiUrl + 'bounce/:mailAsBase64';
		var relevanceUrl = apiUrl + 'relevance/contacts/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseContact', {
						isArray: false,
						custom: 'contact',
						dateFields: dateFields
					}),
					skipDateConvert: true
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseContact', {
						isArray: true,
						custom: 'contact',
						dateFields: dateFields
					}),
					skipDateConvert: true
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseContact', {
						isArray: false,
						custom: 'contact',
						dateFields: dateFields
					}),
					transformRequest: MapGeneric('MapContact', { payloadName: 'contact' }),
					skipDateConvert: true
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseContact', {
						isArray: false,
						custom: 'contact',
						dateFields: dateFields
					}),
					transformRequest: MapGeneric('MapContact', { payloadName: 'contact' }),
					skipDateConvert: true
				},
				anonymize: { method: 'GET', isArray: false, url: baseUrl + 'anonymization' },
				multi: { method: 'POST', isArray: false, url: baseUrl + 'multi/:type' },
				unsub: { method: 'POST', isArray: false, url: unsubUrl },
				resub: { method: 'DELETE', isArray: false, url: unsubUrl },
				unbounce: { method: 'DELETE', isArray: false, url: unbounceUrl },
				move: { method: 'PUT', isArray: false, url: baseUrl + ':id/move/:clientId' },
				merge: { method: 'PUT', isArray: false, url: baseUrl + ':id/merge/:mergeId' },
				findByRelevance: {
					method: 'GET',
					isArray: false,
					url: relevanceUrl,
					transformResponse: ParseGeneric('ParseContact', {
						isArray: false,
						custom: 'contact',
						dateFields: dateFields
					}),
					skipDateConvert: true
				}
			}
		);

		var Model = {
			new: function () {
				var contact = {
					custom: [],
					categories: [],
					journeyStep: 'lead'
				};

				var parsed = ParseGeneric('ParseContact', { isArray: false, custom: 'contact', isNew: true })(
					{ data: contact },
					null
				);

				return parsed.data;
			},
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options || {});
				delete params.mapCustom;

				return Resource.find(filter).$promise;
			},

			findByRelevance: function (options) {
				return Resource.findByRelevance(options).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			anonymize: function (query) {
				if (Array.isArray(query.opts)) {
					query.opts = JSON.stringify(query.opts);
				}

				return Resource.anonymize(query).$promise;
			},

			save: function (contact, options) {
				var defered = $q.defer();

				if (!contact) {
					return defered.reject('No contact');
				}

				const usingFirstnameLastname = FeatureHelper.hasSoftDeployAccess(
					Tools.FeatureHelper.Feature.NEW_FIELDS
				);

				const usingPhoneValidation = FeatureHelper.hasSoftDeployAccess('FORMAT_PHONE_NUMBERS');

				if (contact.id) {
					// on edit shouldnt require phone validation
					Resource.update(
						{ id: contact.id, usingFirstnameLastname },
						{ contact: contact },
						defered.resolve,
						defered.reject
					);
				} else {
					Resource.create(
						{ usingFirstnameLastname: usingFirstnameLastname, usingPhoneValidation },
						{ contact: contact },
						defered.resolve,
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !contact.id);
					})
					.catch(function (error) {
						// Notify user
						helper.onSaveError(options, !contact.id, error);
					});

				return defered.promise;
			},

			delete: function (contact, options) {
				return Resource.delete({ id: contact.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, contact);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			unsubscribe: function (contact, options) {
				options = _.defaults(options || {}, helper.defaults);
				var promise = Resource.unsub({ id: contact.id }).$promise;

				promise
					.then(function () {
						// Notify user
						helper.successNotification(options.updateSuccessTitle, options.unsubSuccessBody, contact.id);

						helper.triggerEvent(options, 'unsubscribed', contact.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !contact.id);
					});

				return promise;
			},

			resubscribe: function (contact, options) {
				options = _.defaults(options || {}, helper.defaults);
				var promise = Resource.resub({ id: contact.id }).$promise;

				promise
					.then(function () {
						// Notify user
						helper.successNotification(options.updateSuccessTitle, options.resubSuccessBody, contact.id);

						helper.triggerEvent(options, 'resubscribed', contact.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !contact.id);
					});

				return promise;
			},

			unbounce: function (contact, requestOptions = {}) {
				const options = _.defaults(requestOptions, helper.defaults);
				const mailAsBase64 = btoa(contact.email);
				const promise = Resource.unbounce({ mailAsBase64 }).$promise;

				function updateClientParam(removedBouncesCurrentMonth) {
					if (typeof removedBouncesCurrentMonth === 'number') {
						const metadata = Tools.AppService.getMetadata();
						metadata.params.RemovedBouncesCurrentMonth = removedBouncesCurrentMonth;
						Tools.AppService.setMetadata(metadata);
					}
				}

				promise
					.then(data => {
						updateClientParam(data.removedBouncesCurrentMonth);
						helper.successNotification('default.removed', 'email.unbounce.saved');
						helper.triggerEvent(options, 'unbounce', contact.email);
					})
					.catch(res => {
						let body = '';
						switch (res.status) {
							case 404:
								body = 'email.unbounce.alreadyRemoved';
								break;
							case 403:
								body = 'email.unbounce.maxNumberRemovalsReached';
								break;
							default:
								body = 'email.unbounce.error';
						}

						updateClientParam(res.data.error.removedBouncesCurrentMonth);
						helper.errorNotification('default.error', body);
					});

				return promise;
			},

			move: function (contact, options) {
				options = _.defaults(options || {}, helper.defaults);
				var promise = Resource.move({ id: contact.id, clientId: contact.client.id }, {}).$promise;

				promise
					.then(function (res) {
						// Notify user
						helper.successNotification(options.updateSuccessTitle, options.moveSuccessBody, res.data);

						helper.triggerEvent(options, 'updated', res.data);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !contact.id);
					});

				return promise;
			},

			merge: function (mergeInto, merge, options) {
				options = _.defaults(options || {}, helper.defaults);
				var resData = { merged: mergeInto, deleted: merge };

				var promise = Resource.merge({ id: mergeInto.id, mergeId: merge.id }, {}).$promise;

				promise
					.then(function (res) {
						return res;
					})
					.catch(function (err) {
						// Notify user
						helper.errorNotification(options.mergeErrorTitle, options.mergeErrorBody, resData);
						return err;
					});

				return promise;
			},
			attr: Attributes,
			keys: keys,
			requiredFields: ContactAttributes().requiredFields
		};

		return Model;
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress, Text } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';

import './DrawerInfoProgress.scss';

const TRAILCOLOR = 'grey-4';

const getAccumulatedPercentage = (total, array) => {
	const num = array.reduce((acc, { num }) => acc + num, 0);
	return (num / total || 0) * 100;
};

export default function DrawerInfoProgress({ total, percentageTotal = total, statsArray, icon = 'user' }) {
	const classes = new bem('DrawerInfoProgress');
	return (
		<div className={classes.b()}>
			{[...statsArray].reverse().map(({ color }, i, arr) => (
				<Progress
					key={'progress-' + i}
					strokeWidth={15}
					color={color}
					trailColor={i === 0 ? TRAILCOLOR : 'none'}
					percentage={getAccumulatedPercentage(percentageTotal, arr.slice(i))}
					hideText
				/>
			))}
			<div className={classes.elem('progress-info').b()}>
				<Icon name={icon} space="mla mra" />
				<Text bold center>
					{total}
				</Text>
			</div>
		</div>
	);
}

DrawerInfoProgress.propTypes = {
	total: PropTypes.number.isRequired,
	percentageTotal: PropTypes.number,
	icon: PropTypes.string,
	statsArray: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			num: PropTypes.number
		})
	).isRequired
};

'use strict';

angular.module('upResources').factory('Quicksearch', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ParseGeneric',
	function ($resource, $q, URL, API, ParseGeneric) {
		var Resource = $resource(
			URL + API + 'quicksearch/',
			{},
			{
				find: {
					method: 'GET',
					transformResponse: ParseGeneric(null, { skipDateConvert: true })
				}
			}
		);

		var Model = {
			search: function (str, ts, options) {
				var params = _.assign({ q: { a: 'searchValue', c: 'eq', v: str } }, options);
				var req = Resource.find(params).$promise;

				if (ts) {
					req.then(function (res) {
						res.ts = ts;
						return res;
					});
				}

				return req;
			}
		};

		return Model;
	}
]);

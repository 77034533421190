import React, { useMemo } from 'react';
import { ButtonSelect } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

const PrecreateOptionsButtonSelect = ({ options, selected, onChange }) => {
	const classes = new BemClass('PrecreateOptionsButtonSelect');

	const selectOptions = useMemo(() => {
		return (
			options?.map(option => ({
				title:
					option === 0
						? T('agreement.precreateOptionNo')
						: option === 1
						? T('agreement.precreateOptionWithDays', { days: option })
						: T('agreement.precreateOptionWithDaysPlural', { days: option }),
				value: option
			})) ?? []
		);
	}, [options]);

	return (
		<ButtonSelect
			value={selected}
			color="bright-blue"
			onChange={onChange}
			className={classes.b()}
			options={selectOptions}
		/>
	);
};

export default PrecreateOptionsButtonSelect;

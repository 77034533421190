import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Row, Column, Loader } from '@upsales/components';
import UpsalesSupportCard from '../UpsalesSupportCard/UpsalesSupportCard';
import UpsalesKnowledgebaseCard from '../UpsalesKnowledgebaseCard/UpsalesKnowledgebaseCard';
import LookerWrapper from './LookerWrapper';
import DashboardTable from './DashboardTable';
import DashboardShape from './DashboardShape';
import './InsightsContent.scss';

const propTypes = {
	closeDropdown: PropTypes.func.isRequired,
	signedUrl: PropTypes.string,
	dashboardsPending: PropTypes.bool,
	setFavorite: PropTypes.func.isRequired,
	goToDashboard: PropTypes.func.isRequired,
	favorites: PropTypes.arrayOf(DashboardShape),
	mine: PropTypes.arrayOf(DashboardShape),
	shared: PropTypes.arrayOf(DashboardShape),
	standard: PropTypes.arrayOf(DashboardShape)
};

const InsightsContent = props => {
	const lang = {
		favorites: Tools.$translate('looker.favorites'),
		createdByMe: Tools.$translate('looker.createdByMe'),
		sharedWithMe: Tools.$translate('looker.sharedWithMe'),
		standard: Tools.$translate('looker.standard'),
		learnMore: Tools.$translate('looker.learnMore'),
		visitHelp: Tools.$translate('default.visitHelpcenter')
	};

	const supportLinks = [
		{
			title: 'Introduction to Insights',
			url: 'https://support.upsales.com/hc/en-us/articles/360013436878-Insights'
		},
		{
			title: 'Building a dashboard in Insights',
			url: 'https://support.upsales.com/hc/en-us/articles/360001695848-Building-a-dashboard-in-Insights-'
		},
		{
			title: 'Dashboard management in Insights',
			url: 'https://support.upsales.com/hc/en-us/articles/360001713607-Dashboard-management-in-Insights'
		},
		{
			title: 'Filtering data in Insights',
			url: 'https://support.upsales.com/hc/en-us/articles/360001713767-Filtering-data-in-Insights'
		},
		{
			title: 'Date filters in Insights',
			url: 'https://support.upsales.com/hc/en-us/categories/360000140047-Video-guides?wchannelid=juel2sd6nb&wmediaid=m68asn8oei'
		}
	];

	return (
		<div className="InsightsContent">
			{props.signedUrl ? (
				<LookerWrapper signedUrl={props.signedUrl} closeDropdown={props.closeDropdown} />
			) : (
				<CardContent className="InsightsContent--CardContent">
					<Row className="InsightsContent--Row">
						<Column
							size={9}
							className={
								props.dashboardsPending
									? 'InsightsContent--Column InsightsContent--Column_center-everything'
									: ''
							}
						>
							{props.dashboardsPending ? (
								<Loader />
							) : (
								[
									<DashboardTable
										key="favorites"
										goToDashboard={props.goToDashboard}
										setFavorite={props.setFavorite}
										icon="heart"
										title={lang.favorites}
										dashboards={props.favorites}
									/>,
									<DashboardTable
										key="user"
										goToDashboard={props.goToDashboard}
										setFavorite={props.setFavorite}
										icon="user"
										title={lang.createdByMe}
										dashboards={props.mine}
									/>,
									<DashboardTable
										key="shared"
										goToDashboard={props.goToDashboard}
										setFavorite={props.setFavorite}
										icon="users"
										title={lang.sharedWithMe}
										dashboards={props.shared}
									/>,
									<DashboardTable
										key="standard"
										goToDashboard={props.goToDashboard}
										setFavorite={props.setFavorite}
										icon="upsales-u"
										title={lang.standard}
										dashboards={props.standard}
									/>
								]
							)}
						</Column>
						<Column className="InsightsContent--Column">
							<UpsalesKnowledgebaseCard
								articles={supportLinks}
								title={lang.learnMore}
								link="https://support.upsales.com/hc/en-us/categories/360000142528-Analytics"
							/>
							<UpsalesSupportCard className="InsightsContent--UpsalesSupportCard" />
						</Column>
					</Row>
				</CardContent>
			)}
		</div>
	);
};

InsightsContent.propTypes = propTypes;

export default InsightsContent;

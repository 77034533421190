import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Button, Row, Column, Headline, Text, Label, Input } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { removeHtml } from '@upsales/common';

import history from 'App/pages/routes/history';
import getAngularModule from '../../angularHelpers/getAngularModule';
import './CreateForm.scss';

const FORM_TYPE = {
	FORM: 'form',
	LANDING: 'landing'
};

class CreateForm extends React.PureComponent {
	static propTypes = {
		reject: PropTypes.func.isRequired
	};

	static defaultProps = {
		reject: () => {}
	};

	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			formType: props.formType,
			internalName: ''
		};

		const t = getAngularModule('$translate');

		this.lang = {
			headline: t('form.selectType'),
			landingPage: t('form.landingPage'),
			internalName: t('default.internalName'),
			next: t('default.next'),
			formInfo: t('form.formInfo'),
			landingPageInfo: t('form.landingPageInfo2'),
			form: t('form.form'),
			abort: t('default.abort'),
			letsGetInTouch: t('form.letsGetInTouch'),
			fillOutForm: t('form.fillOutForm')
		};
	}

	componentDidMount() {
		const nameInput = document.getElementById('form-name');

		if (nameInput) {
			nameInput.focus();
		}
	}

	handleSubmit = async event => {
		const { formType, internalName } = this.state;
		event.preventDefault();

		this.setState({ saving: true });

		const Form = getAngularModule('Form');

		if (formType === FORM_TYPE.FORM) {
			const FormEditorMeta = getAngularModule('FormEditorMeta');
			const meta = await FormEditorMeta({ customerId: Tools.AppService.getCustomerId() });

			return Form.save({
				...meta.form.data,
				title: this.lang.letsGetInTouch,
				description: this.lang.fillOutForm,
				name: internalName,
				isDraft: true
			})
				.then(res => {
					history.push(`/form-editor/${res.data.id}`);
					setTimeout(() => this.props.reject(), 3000);
				})
				.catch(() => this.setState({ saving: false }));
		} else {
			localStorage.setItem('forms.internalName', internalName);
			history.push(`/${Tools.AppService.getCustomerId()}/forms/landingpageEditor/`);
			return this.props.reject();
		}
	};

	handleTypeChange = formType => {
		this.setState({ formType });
	};

	handleNameChange = event => {
		const name = removeHtml(event.currentTarget.value);
		this.setState({ internalName: name });
	};

	render() {
		const { reject } = this.props;
		const { saving, internalName, formType } = this.state;
		const classes = new bemClass('CreateForm');

		return (
			<form onSubmit={this.handleSubmit} className={classes.b()} autoComplete="off">
				<Button className={classes.elem('close', 'exit-button').b()} onClick={reject} shadow="none">
					<Icon name="times" />
				</Button>
				<div className={classes.elem('inner', 'text-center').b()}>
					<Headline size="sm">{this.lang.headline}</Headline>
					<Row className={classes.elem('type').b()}>
						<Column
							className={classNames({ 'Column--selected': formType === FORM_TYPE.FORM })}
							onClick={() => this.handleTypeChange(FORM_TYPE.FORM)}
						>
							<Icon name="check-circle" color="green" />
							<div>
								<img src="/img/form-sign-up-purple.svg" height="65" />
							</div>
							<Text size="lg">{this.lang.form}</Text>
							<Text color="grey-11">{this.lang.formInfo}</Text>
						</Column>
						<Column
							className={classNames({ 'Column--selected': formType === FORM_TYPE.LANDING })}
							onClick={() => this.handleTypeChange(FORM_TYPE.LANDING)}
						>
							<Icon name="check-circle" color="green" />
							<div>
								<img src="/img/form-sign-up-purple2.svg" height="65" />
							</div>
							<Text size="lg">{this.lang.landingPage}</Text>
							<Text color="grey-11">{this.lang.landingPageInfo}</Text>
						</Column>
					</Row>

					<strong className="text-left">
						<Label required>{this.lang.internalName}</Label>
					</strong>
					<Input
						autoComplete={Date.now()}
						name={Date.now()}
						id="form-name"
						placeholder={this.lang.internalName}
						onChange={this.handleNameChange}
						value={this.state.internalName}
					/>

					<Button
						size="xl"
						block
						className={classes.elem('NextStep').b()}
						disabled={!internalName || !formType}
						loading={saving}
						submit
					>
						{this.lang.next}
					</Button>

					<Button
						className={classes.elem('cancel').b()}
						color="grey"
						type="link"
						block
						size="lg"
						onClick={reject}
						disabled={saving}
					>
						{this.lang.abort}
					</Button>
				</div>
			</form>
		);
	}
}

window.CreateForm = CreateForm;

export default CreateForm;

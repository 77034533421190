import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import delayedMount from '@upsales/components/Utils/delayedMount';
import { Card, Title } from '@upsales/components';
import GroupTree from 'Components/Modals/CreateNewAccount/GroupTree';
import EditorHeader from 'Components/EditorHeader';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import t from 'Components/Helpers/translate';
import { removeItem } from 'Store/helpers/array';
import FindProspectsBranchSubtitle from 'App/babel/domain/crm/client/findProspects/FindProspectsBranchSubtitle';
import './FindProspectsGroupTree.scss';

const goToAccount = id => {
	const $state = getAngularModule('$state');
	$state.go('account.dashboard', { id });
};

class FindProspectsGroupTree extends React.PureComponent {
	static propTypes = {
		rootDuns: PropTypes.number,
		client: PropTypes.object,
		clients: PropTypes.arrayOf(PropTypes.object).isRequired,
		onClose: PropTypes.func.isRequired,
		visible: PropTypes.bool
	};
	static defaultProps = {
		clients: [],
		onClose: () => {}
	};
	state = {
		selected: []
	};
	toggleAccount = (_source, client) => {
		let selected;
		const i = this.state.selected.indexOf(parseInt(client.dunsNo));
		if (i !== -1) {
			selected = removeItem(this.state.selected, i);
		} else {
			selected = [...this.state.selected, parseInt(client.dunsNo)];
		}
		this.setState({ selected });
	};
	scrollToSelector = selector => {
		const node = document.querySelector('.FindProspectsGroupTree ' + selector);
		node.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};
	render() {
		const { visible, onClose, rootDuns, client, clients, onSelect } = this.props;
		const classes = new bemClass('FindProspectsGroupTree').mod({ visible });
		const numSel = this.state.selected.length;
		return (
			<Card className={classes.b()}>
				<EditorHeader>
					<Title>{`${t('account.companyGroupFor')} ${client ? client.name : null}`}</Title>
					<FindProspectsBranchSubtitle
						size="sm"
						data={clients}
						rootDuns={rootDuns}
						onClick={container => {
							if (container === 'found') {
								this.scrollToSelector('.found-tree');
							} else {
								this.scrollToSelector('.unknown-tree');
							}
						}}
					/>
					<div className={classes.elem('controls').b()}>
						<EditorHeaderButton title={t('account.backToSearch')} onClick={onClose} />
						<EditorHeaderButton
							title={
								numSel
									? `${t('default.manage')} ${numSel} ${t(
											numSel === 1 ? 'default.client' : 'default.clients'
									  )}`
									: t('default.selectAll')
							}
							next
							onClick={() => onSelect(this.state.selected)}
						/>
					</div>
				</EditorHeader>
				<GroupTree
					className={classes.elem('content').b()}
					rootDuns={rootDuns}
					dataSourceId="soliditet"
					idField="dunsNo"
					groupAccount={client}
					addingAccount={this.props.addingAccount}
					accounts={clients}
					multiselect={true}
					selected={this.state.selected}
					actions={{
						beginAddAccount: this.toggleAccount,
						goToAccount,
						beginMerge: this.props.onMerge
					}}
				/>
			</Card>
		);
	}
}

export default delayedMount(FindProspectsGroupTree);

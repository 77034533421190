'use strict';
import PeriodizationChart from 'Components/Periodization/PeriodizationChart';

angular.module('upDirectives').directive('upPeriodizationChartRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		link: function ($scope, $element, $attr) {
			var root = $element[0];
			var hasPeriodization = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PERIODIZATION);

			if (root && hasPeriodization) {
				window.SetupComponent(
					$scope,
					PeriodizationChart,
					root,
					{
						edit: $scope.$eval($attr.edit),
						order: $scope.$eval($attr.order)
					},
					{ redux: true, ignoreAppService: true }
				);
			}
		}
	};
});

import logError from 'App/babel/helpers/logError';

angular.module('domain.admin').controller('StandardIntegration', [
	'AppService',
	'$scope',
	'$state',
	'$stateParams',
	'StandardIntegration',
	'$q',
	'$safeApply',
	'RequestBuilder',
	function (AppService, $scope, $state, $stateParams, StandardIntegration, $q, $safeApply, RequestBuilder) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'integrations';
		AdminSubCtrl.rootData.pageLoading = true;

		var customerId;

		AdminSubCtrl.rootData.pageData = {
			integrations: [],
			yourIntegrations: [],
			integrationPublisher: false,
			searchString: null,
			administrator: false,
			configs: []
		};

		AdminSubCtrl.rootData.onViewIntegration = function (integration) {
			if (integration.userOnly) {
				Tools.$state.go('administration.integration', { id: integration.id, configure: 'user' });
			} else {
				$state.go('administration.integration', { id: integration.id });
			}
		};

		AdminSubCtrl.rootData.onEditIntegration = function (integration) {
			$state.go('administration.editIntegration', { id: integration.id });
		};

		AdminSubCtrl.rootData.pageData.onSearch = function (searchString) {
			AdminSubCtrl.rootData.pageData.searchString = searchString;

			$safeApply($scope);
		};

		AdminSubCtrl.rootData.pageData.updateSearch = function (category, features) {
			const rb = new RequestBuilder();
			const orBuilder = rb.orBuilder();
			orBuilder.next();

			if (!AdminSubCtrl.rootData.pageData.administrator) {
				rb.addFilter({ field: 'userOnly' }, rb.comparisonTypes.Equals, true);
			}

			if (category?.length && category !== 'all') {
				rb.addFilter({ field: 'category' }, 'wc', category);
			}

			let itAddedOr = false;
			features.forEach(function (feat) {
				if (feat.active) {
					if (feat.id === 'free') {
						itAddedOr = true;
						orBuilder.addFilter(feat.attribute, feat.comparison, feat.value);
						orBuilder.next();
						orBuilder.addFilter(feat.attribute, 'eq', null);
						orBuilder.next();
						return;
					}
					if (feat.filterType === 'OR') {
						itAddedOr = true;
						orBuilder.addFilter(feat.attribute, feat.comparison, feat.value);
						orBuilder.next();
						return;
					}
					rb.addFilter(feat.attribute, feat.comparison, feat.value);
				}
			});
			if (itAddedOr) {
				orBuilder.done();
			}

			let integrations;
			Tools.StandardIntegrations.find(rb.build())
				.then(function (response) {
					integrations = response.data;
					return AdminSubCtrl.rootData.pageData.configs;
				})
				.then(configs => {
					AdminSubCtrl.rootData.pageData.integrations = integrations.sort(sortAZ);
					_.each(AdminSubCtrl.rootData.pageData.integrations, function (integration) {
						var config = _.find(configs.data, { integrationId: integration.id });
						integration.$config = config;
					});
					AdminSubCtrl.rootData.pageLoading = false;
					$safeApply($scope);
				})
				.catch(error => {
					logError(
						error,
						'ui/app/upsales/domain/admin/controllers/standardIntegration/integrations.js updateSearch'
					);
				});
		};

		function sortAZ(a, b) {
			if (a.name < b.name) {
				return -1;
			} else if (a.name > b.name) {
				return 1;
			}

			return 0;
		}

		var init = function () {
			customerId = AppService.getCustomerId();
			AdminSubCtrl.rootData.pageData.administrator = AppService.getSelf().administrator;
			AdminSubCtrl.rootData.pageData.integrationPublisher =
				Tools.FeatureHelper.isAvailable('INTEGRATION_PUBLISHER') &&
				AdminSubCtrl.rootData.pageData.administrator;

			var promises = {};

			var filter = $stateParams.filter || {};
			if (!AdminSubCtrl.rootData.pageData.administrator) {
				filter.userOnly = true;
			}
			filter.sort = { a: 'name', s: 'A' };

			promises.integrations = StandardIntegration.find(filter).then(function (res) {
				AdminSubCtrl.rootData.pageData.yourIntegrations = _.filter(res.data, function (o) {
					return o.publisherClientId === customerId;
				});

				return res;
			});

			AdminSubCtrl.rootData.pageData.configs = StandardIntegration.setting(customerId).find({});

			$q.all(promises).catch(error => {
				logError(error, 'ui/app/upsales/domain/admin/controllers/standardIntegration/integrations.js init');
			});
		};

		// eslint-disable-next-line
		AppService.loadedPromise.then(init);
	}
]);

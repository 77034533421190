import React from 'react';
import T from 'Components/Helpers/translate';
import { connect } from 'react-redux';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { Text, Headline, ButtonBox, Card, Title, Tooltip, Block, Flex } from '@upsales/components';
import Stages from '../Stages';
import BemClass from '@upsales/components/Utils/bemClass';
import { setSolution } from 'Store/reducers/SalesCoachReducer';
import { cloneDeep } from 'lodash';
import { Fade } from '@upsales/components/animations';
import { salesCoachTracker } from 'App/babel/helpers/Tracker';
import { getStages } from 'Store/actions/AppGetterActions';
import OrderStage from 'App/resources/Model/OrderStage';

import './Solution.scss';
import SalesProcessAddonHint from 'Components/Admin/SalesProcess/SalesCoach/SalesProcessAddonHint';

type StageObj = {
	active: boolean;
	required?: boolean;
};

type Selected = {
	[key: number]: StageObj;
};

type SolutionType = {
	active: boolean;
	entireSalesProcess: boolean;
	stages: Selected;
};

type Props = {
	reject: () => void;
	solution: SolutionType;
	setSolution: (solution: Object) => void;
	getStages: (
		type: 'all' | 'order' | 'opportunity' | 'lost' | 'won' | 'excludedIds',
		skipAuth: boolean
	) => OrderStage[] | number[];
};

type State = {
	solution: SolutionType;
};

const mapStateToProps = ({ SalesCoachReducer }: { SalesCoachReducer: { solution: SolutionType } }) => ({
	solution: SalesCoachReducer.solution
});

const mapDispatchToProps = { setSolution, getStages };

class Solution extends React.Component<Props, State> {
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			cancel: T('default.cancel'),
			save: T('default.save'),
			verifyingSolution: T('admin.newSalesProcess.verifyingSolution'),
			descriptionSolution: T('admin.newSalesProcess.descriptionSolution'),
			atWhatPoint: T('admin.newSalesProcess.atWhatPoint'),
			throughEntireSalesProcess: T('admin.newSalesProcess.throughEntireSalesProcess'),
			specificStage: T('admin.newSalesProcess.specificStage'),
			backToChecklist: T('admin.newSalesProcess.backToChecklist'),
			chooseStagesToSave: T('admin.newSalesProcess.chooseStagesToSave')
		};
		this.state = { solution: cloneDeep(p.solution) };
	}

	track = (id: number, active: boolean) => {
		const { getStages } = this.props;
		const probability = (getStages('opportunity', false) as OrderStage[]).find(
			stage => stage.id === id
		)?.probability;
		if (!probability) {
			return;
		}

		const { ACTIVATED_SOLUTION, DEACTIVATED_SOLUTION } = salesCoachTracker.events;
		const event = active ? ACTIVATED_SOLUTION : DEACTIVATED_SOLUTION;
		salesCoachTracker.track(event, { probability });
	};

	flipSelected = (id: number) => {
		const { solution } = this.state;
		if (!solution.stages[id]) {
			solution.stages[id] = { active: false };
		}
		solution.stages[id].active = !solution.stages[id]?.active;
		if (!solution.stages[id].active && solution.stages[id]?.required) {
			solution.stages[id].required = false;
		}

		this.track(id, solution.stages[id].active);
		this.setState({ solution });
	};

	flipRequired = (id: number) => {
		const { solution } = this.state;
		if (!solution.stages[id]) {
			solution.stages[id] = { active: false };
		}
		solution.stages[id].required = !solution.stages[id]?.required;
		if (solution.stages[id].required && !solution.stages[id]?.active) {
			solution.stages[id].active = true;
		}
		this.setState({ solution });
	};

	toggleSpecificStage = () => {
		const { solution } = this.state;
		if (!solution.entireSalesProcess) {
			return;
		}

		this.setState({ solution: { ...solution, entireSalesProcess: false, stages: {} } });
	};

	toggleEntireSalesProcess = () => {
		const { solution } = this.state;
		if (solution.entireSalesProcess) {
			return;
		}

		this.setState({ solution: { ...solution, entireSalesProcess: true, stages: {} } });
	};

	haveNoStagesSelected() {
		if (!this.state.solution.entireSalesProcess) {
			const activeStage = Object.values(this.state.solution.stages).find(stage => {
				return stage.active;
			});
			return !activeStage;
		}
		return false;
	}

	render() {
		const { reject, setSolution } = this.props;
		const { solution } = this.state;
		const { entireSalesProcess } = solution;

		const classNames = new BemClass('Solution');

		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.verifyingSolution}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={() => {
										reject();
									}}
									supertitle={undefined}
									className={undefined}
									noIcon
									next={false}
								/>
								<Tooltip title={this.lang.chooseStagesToSave} disabled={!this.haveNoStagesSelected()}>
									<EditorHeaderButton
										title={this.lang.save}
										supertitle={this.lang.backToChecklist}
										disabled={this.haveNoStagesSelected()}
										onClick={() => {
											setSolution({ ...solution, active: true });
											reject();
										}}
										next
										className="NextStep__EditorHeaderButton"
										noIcon={false}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Card>
					<div className={classNames.elem('content').b()}>
						<Headline>{this.lang.verifyingSolution}</Headline>
						<Text color="grey-11">{this.lang.descriptionSolution}</Text>
						<Block space="mtxl">
							<Title size="lg">{this.lang.atWhatPoint}</Title>
						</Block>
						<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
							<ButtonBox
								onClick={this.toggleEntireSalesProcess}
								selected={entireSalesProcess}
								title={this.lang.throughEntireSalesProcess}
							/>
							<ButtonBox
								onClick={this.toggleSpecificStage}
								selected={!entireSalesProcess}
								title={this.lang.specificStage}
							/>
						</Flex>
						<Fade visible={!entireSalesProcess}>
							<Block space="mtl">
								<Stages selected={solution.stages} onToggleItem={this.flipSelected} />
								<SalesProcessAddonHint />
							</Block>
						</Fade>
					</div>
				</Flex>
			</Fade>
		);
	}
}

export const detached = Solution;

const Component = connect(mapStateToProps, mapDispatchToProps)(Solution);

export default Component;

import Resource from './Resource';

class FlowContactsActive extends Resource {
	constructor() {
		super('flows');
	}
	async getActiveFlows(
		type: 'client' | 'contact',
		id: number,
		limit: number,
		offset: number,
		includeSubAccounts: boolean = false
	) {
		return super
			._getRequest(`active/${type}/${id}?limit=:limit&offset=:offset&includeSubAccounts=:includeSubAccounts`, {
				urlParams: { limit, offset, includeSubAccounts }
			})
			.then(d => d.data.data);
	}
}

const resource = new FlowContactsActive();

export default resource;

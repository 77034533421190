import DatePreset from 'App/upsales/common/services/datePresetService';

angular.module('services.filterParser', []).factory('FilterParser', [
	'RequestBuilder',
	function (RequestBuilder) {
		// parse one filter
		function parseFilter(filterObj, parsedRequest) {
			// switch filter type
			switch (filterObj.filterType) {
				// normal
				case 'list':
				case 'normal':
					parsedRequest.addFilter(
						getField(filterObj.field),
						parsedRequest.comparisonTypes[filterObj.comparisonType],
						filterObj.value
					);
					break;

				// not equals null (only for bool filterType)
				case 'notNull':
					if (filterObj.value) {
						parsedRequest.addFilter(
							getField(filterObj.field),
							parsedRequest.comparisonTypes.NotEquals,
							null
						);
					} else {
						parsedRequest.addFilter(getField(filterObj.field), parsedRequest.comparisonTypes.Equals, null);
					}
					break;
				// not equals 0 (only for bool filterType)
				case 'notZero':
					if (filterObj.value) {
						parsedRequest.addFilter(getField(filterObj.field), parsedRequest.comparisonTypes.NotEquals, 0);
					} else {
						parsedRequest.addFilter(getField(filterObj.field), parsedRequest.comparisonTypes.Equals, 0);
					}
					break;

				// not equals empty array (only for bool filterType)
				case 'notEmptyArray':
					if (filterObj.value) {
						parsedRequest.addFilter(getField(filterObj.field), parsedRequest.comparisonTypes.NotEquals, []);
					} else {
						parsedRequest.addFilter(getField(filterObj.field), parsedRequest.comparisonTypes.Equals, []);
					}
					break;

				// range
				case 'dateRange':
				case 'range':
					if (filterObj.filterType === 'dateRange' && filterObj.preset && filterObj.preset !== 'custom') {
						var values = DatePreset.getValues(filterObj.preset);
						filterObj.value.start = values.start;
						filterObj.value.end = values.end;
					}
					// start
					if (filterObj.value.start) {
						const value = filterObj.value.start;
						parsedRequest.addFilter(
							getField(filterObj.field),
							parsedRequest.comparisonTypes.GreaterThanEquals,
							value
						);
					}

					// end
					if (filterObj.value.end) {
						let value = filterObj.value.end;

						if (filterObj.preset === 'custom') {
							value = moment(value).add('days', 1).utc().format();
						}
						parsedRequest.addFilter(
							getField(filterObj.field),
							parsedRequest.comparisonTypes.LowerThan,
							value
						);
					}

					if (filterObj.status) {
						switch (filterObj.status) {
							case 'open':
								parsedRequest.addFilter(
									{ field: 'closeDate', type: 'date' },
									parsedRequest.comparisonTypes.Equals,
									null
								);
								parsedRequest.addFilter(
									{ field: 'isAppointment', type: 'boolean' },
									parsedRequest.comparisonTypes.Equals,
									false
								);

								break;
							case 'closed':
								//  ADD FILTER GROUP HERE
								// parsedRequest.addFilterGroup([

								// ]);
								parsedRequest.addFilter(
									{ field: 'closeDate', type: 'date' },
									parsedRequest.comparisonTypes.NotEquals,
									null
								);
								parsedRequest.addFilter(
									{ field: 'isAppointment', type: 'boolean' },
									parsedRequest.comparisonTypes.Equals,
									false
								);
								break;
						}
					}
					break;

				// custom field filter
				case 'custom':
					parsedRequest.addFilter(
						getField(filterObj.field),
						parsedRequest.comparisonTypes[filterObj.comparisonType],
						filterObj.value
					);
					break;
			}
		}

		function getField(field) {
			// var fieldObj = ListViewService.getAttrByField(viewType, field);
			// var type = fieldObj.attr ? fieldObj.attr.id.type.toLowerCase() : fieldObj.type.toLowerCase();

			return { field: field };
		}

		function parse(view) {
			var parsedRequest = RequestBuilder();

			// parse filters
			angular.forEach(view.filters, function (filter) {
				if (!filter.inactive) {
					parseFilter(filter, parsedRequest);
				}
			});

			// parse fields
			angular.forEach(view.columns, function (column) {
				parsedRequest.fields.push(column);
			});

			// set limit
			parsedRequest.limit = view.limit;

			//set offset
			parsedRequest.offset = view.offset;

			// set sorting
			angular.forEach(view.sorting, function (sort) {
				parsedRequest.addSort(sort.attribute, sort.ascending);
			});

			return parsedRequest;
		}

		return parse;
	}
]);

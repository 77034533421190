export const getAllSubRoleIds = roleId => {
	const allRoles = Tools.AppService.getRoles();
	const roleMap = _.reduce(
		allRoles,
		(roleMap, role) => {
			if (!roleMap[role.id]) {
				roleMap[role.id] = [];
			}

			if (role.parent) {
				if (!roleMap[role.parent.id]) {
					roleMap[role.parent.id] = [role.id];
				} else {
					roleMap[role.parent.id].push(role.id);
				}
			}
			return roleMap;
		},
		{}
	);

	let allRoleIds = [roleId];
	let queue = [roleId];

	while (queue.length) {
		const currentId = queue.shift();
		const children = roleMap[currentId];
		allRoleIds = allRoleIds.concat(children);
		queue = queue.concat(children);
	}

	return allRoleIds;
};

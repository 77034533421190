import _ from 'lodash';
import { AllIWantData } from '../AllIWant';
import CustomField from '../Model/CustomField';

type RawCustomField = Optional<CustomField, '$hasAccess'>;

const hasRoleAccess = function (
	field: RawCustomField,
	roleId: number | null,
	admin: boolean,
	self: AllIWantData['self']
) {
	if (!self.features.userPermissionsAdvanced) {
		return true;
	}

	if (admin || !field.roles?.length || _.find(field.roles, { id: roleId })) {
		return true;
	}

	return false;
};

export default function (field: RawCustomField) {
	const customerSelf = Tools.AppService.getSelf();
	const self = Tools.AppService.getAccountSelf();

	// if customerSelf is still missing and DEV
	if (!customerSelf && Tools.ENV === 'DEV') {
		console.warn('Customfields got parsed before app was initialized! use AppService.getCustomFields.');
		console.trace();
	}
	field.$hasAccess = hasRoleAccess(field, customerSelf.role?.id || null, customerSelf.administrator, self);
	// Fix select default
	if (field.datatype === 'Select' && field.default) {
		try {
			const options: string[] = [];
			_.each(field.default, function (opt) {
				const trm = _.trim(opt);
				if (trm.length) {
					options.push(trm);
				}
			});
			field.default = options;
		} catch (e) {
			console.warn('Failed to parse options for customfield: ' + field.id);
			console.trace();
		}
	}

	return field as CustomField;
}

ReactTemplates.segment.events = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			events: t('default.events'),
			date: t('default.date'),
			contacts: t('default.contacts2'),
			noEvents: t('segment.noEvents'),
			filters: {
				all: t('default.all'),
				added: t('segment.added'),
				removed: t('segment.removed'),
				mail: t('default.mail')
			}
		};

		this.filters = ['all', 'added', 'removed', 'mail'];
	},
	render: function () {
		var self = this;
		var eventComponent = this.props.eventComponent;
		var tableWidth = [120, 140];

		var events = _.chain(this.props.segment.stats.byMonth)
			.map(function (eventRow) {
				if (self.props.eventComponent.selectedFilter !== 'all') {
					var events = [];
					if (self.props.eventComponent.selectedFilter === 'removed') {
						events = _.filter(eventRow.events, { type: 'removeContact' });
					}
					if (self.props.eventComponent.selectedFilter === 'added') {
						events = _.filter(eventRow.events, { type: 'addContact' });
					}
					if (self.props.eventComponent.selectedFilter === 'mail') {
						events = _.filter(eventRow.events, { type: 'sendMail' });
					}
				} else {
					events = eventRow.events;
				}
				if (!events.length) {
					return null;
				}

				// dont know if this really is necessary, but we had a condition where the year was 0 in alpha.
				// maybe its a  non-error after some bug fixes in api when created? i dunno, i let this be like this in the mean time..
				if (eventRow.year > 1970) {
					eventRow.date = moment(eventRow.year + '-' + eventRow.month + '-01').toDate();
					return React.createElement(
						ReactTemplates.segment.eventRow,
						_.merge({}, self.props, {
							eventRow: eventRow,
							tableWidth: tableWidth,
							events: events
						})
					);
				}
			})
			.compact()
			.value();

		var table;
		if (eventComponent.eventsLoading) {
			table = <ReactTemplates.loader />;
		} else if (!events || !events.length) {
			table = <h2 className="no-events-msg">{this.lang.noEvents}</h2>;
		} else {
			table = (
				<div id="event-table-wrap">
					<table id="event-table">
						<thead>
							<th key="date" style={{ width: tableWidth[0] + 'px' }}>
								{self.lang.date}
							</th>
							<th key="events" style={{ width: 'auto' }}>
								{self.lang.events}
							</th>
							<th key="contacts" style={{ width: tableWidth[1] + 'px' }}>
								{self.lang.contacts}
							</th>
						</thead>
						<tbody>{events}</tbody>
					</table>
				</div>
			);
		}

		return (
			<div id="segment-events">
				<div id="header">
					<h2>{this.lang.events}</h2>
					<div id="filters" className="btn-group">
						{_.map(this.filters, function (name) {
							var classNames = 'btn up-btn btn-light-grey no-border btn-sm no-shadow';
							if (eventComponent.selectedFilter === name) {
								classNames = 'btn up-btn btn-white-blue no-border btn-sm';
							}
							return (
								<button
									type="button"
									key={name}
									className={classNames}
									onClick={function () {
										eventComponent.setFilter(name);
									}}
									disabled={eventComponent.eventsLoading}
								>
									{self.lang.filters[name]}
								</button>
							);
						})}
					</div>
				</div>

				{table}
			</div>
		);
	}
});

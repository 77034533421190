import { globalTracker } from 'App/babel/helpers/Tracker';
import { Icon } from '@upsales/components';
import config from '../../../../../../config';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import i18next from 'i18next';
import { getInitials } from 'App/helpers/getInitials';

ReactTemplates.navbar.profileDropdown = window.ReactCreateClass({
	getInitialState: function () {
		return {
			avatar: { initials: '', url: '', gravatar: false }
		};
	},
	componentDidMount: function () {
		this.setDropdown();

		this.onLogout = function () {
			const security = getAngularModule('security');
			security.logout();
		};

		i18next.on('languageChanged', () => {
			this.forceUpdate();
		});

		this.profilePickListener = Tools.$rootScope.$on('profileImage.updated', (e, data) => {
			const avatar = {
				gravatar: true,
				profileImg: true,
				initials: getInitials(Tools.AppService.getSelf())
			};
			if (data.imageId) {
				avatar.url =
					Tools.URL +
					Tools.API +
					Tools.AppService.getCustomerId() +
					'/resources/download/internal/' +
					data.imageId;
			}
			this.setState({ avatar });
		});
	},
	componentWillUnmount: function () {
		this.profilePickListener();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	setDropdown: function () {
		var settingsDrop = this._dropdownToggle;
		if (settingsDrop) {
			jQuery(settingsDrop).dropdown();
		}
	},
	getItem: function (state, text, icon, stateParams, key) {
		return (
			<li key={key || 'admin-drop-' + state}>
				<a href={this.props.getStateHref(state, stateParams)}>
					{icon ? (
						<span>
							<b className={'fa ' + icon} />{' '}
						</span>
					) : null}{' '}
					{text}
				</a>
			</li>
		);
	},
	getSsoUrl: function (site) {
		return config.URL + config.API + 'function/externalSSO/' + site;
	},
	getItemSsoWindow: function (state, text, icon, stateParams, key) {
		var url = this.getSsoUrl(stateParams.site);
		return (
			<li key={key || 'admin-drop-' + state}>
				<a href={url} target="_blank">
					{icon ? (
						<span>
							<b className={'fa ' + icon} />{' '}
						</span>
					) : null}{' '}
					{text}
				</a>
			</li>
		);
	},
	mixpanelEvent: function (event) {
		globalTracker.track(event);
	},
	render: function () {
		var self = this;
		var t = Tools.$translate;
		self.lang = {
			userSettings: t('admin.userSettings'),
			settings: t('admin.settings'),
			administration: t('navbar.administration'),
			accountSettings: t('admin.accountSettings'),
			classicAdmin: t('admin.classicAdmin'),
			logout: t('navbar.logout'),
			siteVisitors: t('default.siteVisitors'),
			automations: t('admin.automations'),
			mailsettings: t('default.mailsettings'),
			templates: t('mail.templates'),
			esign: t('default.eSign'),
			accountProfile: t('admin.accountProfile'),
			apiKeys: t('admin.apiKeys'),
			apps: t('admin.apps'),
			myProfile: t('default.myProfile'),
			configureUpsales: t('default.configureUpsales'),
			educations: t('default.educations'),
			releaseNotes: t('default.releaseNotes'),
			looker: t('default.looker')
		};
		var isAdmin = this.props.self.administrator;
		var marketAdmin = this.props.self.userParams.mailAdmin;
		var features = this.props.accountSelf.features;
		var dropdownMenu;
		const showApps = features.integrations;

		var optionLength = 3;
		if (showApps) {
			optionLength++;
		}

		var classNames = 'dropdown-menu upsales-tools';
		var optionStyle = {
			width: 100 / optionLength + '%'
		};
		if (optionLength === 4) {
			classNames += ' two-rows';
			optionStyle.width = '50%';
		}

		const settingsUrl =
			isAdmin || marketAdmin
				? this.props.getStateHref('administration.dashboard')
				: this.props.getStateHref('administration.profile');

		dropdownMenu = (
			<ul className={classNames}>
				<div id="content">
					<div id="dropdown-header-fire">{self.lang.configureUpsales}</div>
					{showApps ? (
						<a
							className="profile-dropdown-btn"
							style={optionStyle}
							href={this.props.getStateHref('administration.integrations')}
						>
							<b className="fa fa-puzzle-piece" />
							{self.lang.apps}
						</a>
					) : null}
					<a className="profile-dropdown-btn" style={optionStyle} href={settingsUrl}>
						<b className="fa fa-cogs" />
						{self.lang.settings}
					</a>
					{optionLength === 4 ? <br /> : null}
					<a
						className="profile-dropdown-btn"
						style={optionStyle}
						href={'https://support.upsales.com/hc/en-us'}
						target="_blank"
						onClick={this.mixpanelEvent.bind(this, 'Admin dropdown docebo')}
					>
						<b className="fa fa-graduation-cap" />
						{self.lang.educations}
					</a>
					<a
						className="profile-dropdown-btn"
						style={optionStyle}
						href={'https://upsales.releasenotes.io/'}
						target="_blank"
					>
						<b className="fa fa-gift" />
						{self.lang.releaseNotes}
					</a>
					<div id="logout-btn" onClick={this.onLogout}>
						<b className="fa fa-power-off" /> {self.lang.logout}
					</div>
				</div>
			</ul>
		);

		return (
			<li id="profile-dropdown" className="menu-item dropdown">
				<a className="dropdown-toggle" ref={self.setRef.bind(self, '_dropdownToggle')} data-toggle="dropdown">
					<span className="navbar-settings">
						{this.props.self && this.props.self.name ? (
							<ReactTemplates.TOOLS.avatar
								id="profile-img"
								user={this.props.self || ''}
								size={31}
								style={{ marginTop: '8px' }}
								avatar={self.state.avatar}
							/>
						) : (
							<Icon name="cogs" />
						)}
					</span>
				</a>
				{dropdownMenu}
			</li>
		);
	}
});

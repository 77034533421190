'use strict';

angular.module('upResources').factory('ActivityQuota', [
	'$resource',
	'$q',
	'ActivityQuotaAttributes',
	'URL',
	'API',
	'ResourceHelper',
	function ActivityQuota($resource, $q, ActivityQuotaAttributes, URL, API, ResourceHelper) {
		var Attribute = ActivityQuotaAttributes().attr;

		var Resource = null;
		Resource = $resource(
			URL + API + 'activityQuota/',
			{},
			{ query: { method: 'GET', isArray: false } },
			{ save: { method: 'POST', isArray: false } }
		);

		var helper = new ResourceHelper();
		var eventPrefix = 'activityQuotas';

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: eventPrefix,
			createSuccessBody: 'saved.' + eventPrefix,
			createErrorBody: 'saveError.' + eventPrefix
		});

		var Model = {
			build: function () {
				return {
					user: null,
					performed: 0,
					created: 0,
					activityType: null,
					month: null,
					year: null
				};
			},
			find: function (filter, options) {
				var defered = $q.defer();
				var params = _.extend(filter, options || {});

				Resource.get(
					params,
					function (response) {
						if (response.error) {
							defered.reject(response.error);
						} else {
							defered.resolve(response);
						}
					},
					function (response) {
						defered.reject(response.status);
					}
				);

				return defered.promise;
			},
			save: function (activityQuota, options) {
				var defered = $q.defer();

				Resource.save(
					activityQuota,
					function (result) {
						helper.onSave(options, result.data, true);
						defered.resolve(result);
					},
					function (err) {
						defered.reject(err);
					}
				);

				return defered.promise;
			},
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},
			attr: Attribute
		};

		return Model;
	}
]);

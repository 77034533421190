import './AgreementInitialPeriodToggle.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, Toggle, Text, Tooltip } from '@upsales/components';

import T from 'Components/Helpers/translate';

type Props = {
	checked?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
};

const AgreementInitialPeriodToggle = ({ checked, disabled, onChange }: Props) => {
	const classes = new BemClass('AgreementInitialPeriodToggle');

	const onToggleClick = (checked: boolean) => {
		onChange?.(checked);
	};

	return (
		<Tooltip title={T('agreement.initialPeriodToggleTooltip')} disabled={!!disabled}>
			<Block className={classes.b()} space="mll mrl" onClick={() => onToggleClick(!checked)}>
				<Toggle checked={!!checked} disabled={!!disabled} space="mrm" />
				<Text>{T('agreement.initialPeriodToggle')}</Text>
			</Block>
		</Tooltip>
	);
};

export default AgreementInitialPeriodToggle;

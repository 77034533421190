import _ from 'lodash';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('Order', [
	'$resource',
	'URL',
	'API',
	'OrderAttributes',
	'$stateParams',
	'CustomFieldMapper',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	'AppService',
	'ParseHelpers',
	function Order(
		$resource,
		URL,
		API,
		OrderAttributes,
		$stateParams,
		CustomFieldMapper,
		ResourceHelper,
		ParseGeneric,
		MapGeneric,
		AppService,
		ParseHelpers
	) {
		var OA = OrderAttributes();
		var Attribute = OA.attr;
		var Keys = OA.keys;
		var resourceUrl = URL + API + ':customerId/orders/';
		var defaults = {
			customerId: function () {
				return $stateParams.customerId;
			},
			id: '@id'
		};

		var isOpportunity = function (order) {
			return order.probability !== 0 && order.probability !== 100;
		};

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: function (obj) {
				return isOpportunity(obj) ? 'opportunity' : 'order';
			},
			createSuccessBody: function (obj) {
				return isOpportunity(obj) ? 'saved.opportunity' : 'saved.order';
			},
			updateSuccessBody: function (obj) {
				return isOpportunity(obj) ? 'updated.opportunity' : 'updated.order';
			},
			deleteSucccessBody: function (obj) {
				return isOpportunity(obj) ? 'deleted.opportunity' : 'deleted.order';
			},

			createErrorBody: 'saveError.order',
			updateErrorBody: 'saveError.order',
			deleteErrorBody: 'deleteError.order',
			onclickLink: savedObject => {
				Tools.$upModal.open('editOrder', { id: savedObject.id });
			},
			notificationType: TYPE.GENERIC_ENTITY_ADDED,
			extraSeconds: 1000
		});

		var dateFields = [
			'date',
			'closeDate',
			'modDate',
			'regDate',
			'periodization.startDate',
			'periodization.endDate'
		];

		var methods = {
			get: {
				method: 'GET',
				transformResponse: ParseGeneric('ParseOrder', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				})
			},
			query: {
				method: 'GET',
				isArray: false,
				transformResponse: ParseGeneric('ParseOrder', {
					isArray: true,
					custom: 'order',
					dateFields: dateFields
				})
			},
			put: {
				method: 'PUT',
				transformResponse: ParseGeneric('ParseOrder', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				}),
				params: {
					skipTriggers: '@skipTriggers'
				},
				transformRequest: MapGeneric('MapOrder')
			},
			post: {
				method: 'POST',
				transformResponse: ParseGeneric('ParseOrder', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				}),
				transformRequest: MapGeneric('MapOrder')
			},
			multi: { method: 'POST', isArray: false, url: resourceUrl + 'multi/:type' }
		};

		var Resource = $resource(resourceUrl + ':id', defaults, methods);

		var Model = {
			newRow: function () {
				var metadata = AppService.getMetadata();
				var defaultCurrency = _.find(metadata.customerCurrencies, {
					masterCurrency: true
				});
				var row = {
					listPrice: 0,
					price: 0,
					quantity: 0,
					tierQuantity: 0,
					custom: [],
					purchaseCost: 0
				};

				var fields = AppService.getCustomFields('orderrow');
				ParseHelpers.parseCustom(row, fields);

				row.$listPrice = row.listPrice * defaultCurrency.rate;

				var discount = parseFloat(
					((row.listPrice - row.price) * row.quantity * defaultCurrency.rate).toFixed(2)
				);
				row.$discount = discount > 0 ? discount : 0;
				row.$price = row.price;
				if (row.$discount !== undefined && !isNaN(row.$discount)) {
					row.$discountPercent = undefined;
					row.$discountRaw = parseFloat(row.$discount);
					row.$percentPlaceholder = ((row.$discountRaw / (row.$listPrice * row.quantity)) * 100).toFixed(2);
					if (isNaN(row.$percentPlaceholder)) {
						row.$percentPlaceholder = 0;
					}
				}

				return row;
			},
			new: function (data) {
				var metadata = AppService.getMetadata();
				var defaultCurrency = _.find(metadata.customerCurrencies, {
					masterCurrency: true
				});

				var order = {
					orderRow: [Model.newRow()],
					date: new Date(),
					currencyRate: defaultCurrency.rate,
					currency: defaultCurrency.iso,
					user: null,
					stage: null,
					project: null,
					contact: null,
					custom: [],
					...(data ?? {})
				};

				var parsed = ParseGeneric('ParseOrder', {
					isArray: false,
					custom: 'order',
					skipDateConvert: !data,
					isNew: true
				})(
					{
						data: order
					},
					null
				);

				return parsed.data;
			},
			customer: function (customer) {
				var instance = {};

				instance.find = function (filter, options) {
					var params = _.extend(filter, options || {});
					var mapCustom = params.mapCustom || false;
					delete params.mapCustom;

					params.customerId = customer;

					if (mapCustom) {
						return Resource.query(params).$promise.then(CustomFieldMapper.map);
					}

					return Resource.query(params).$promise;
				};

				instance.get = function (id) {
					return Resource.get({ customerId: customer, id: id }).$promise;
				};

				instance.save = function (order, options) {
					var promise = Resource[order.id ? 'put' : 'post']({ customerId: customer }, order).$promise;

					promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !order.id);
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, !order.id, err);
						});

					return promise;
				};

				instance['delete'] = function (order, options) {
					return Resource.delete({ customerId: customer }, order)
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, order);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: OrderAttributes().requiredFields
		};

		return angular.extend(Resource, Model);
	}
]);

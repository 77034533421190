'use strict';

angular.module('upResources').factory('MailTemplate', [
	'$resource',
	'$q',
	'URL',
	'API',
	'MailTemplateAttributes',
	'ResourceHelper',
	'AppService',
	'ParseGeneric',
	'MapGeneric',
	function MailTemplate(
		$resource,
		$q,
		URL,
		API,
		MailTemplateAttributes,
		ResourceHelper,
		AppService,
		ParseGeneric,
		MapGeneric
	) {
		var Attributes = MailTemplateAttributes().attr;
		var Keys = MailTemplateAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'mailTemplate',
			createSuccessBody: 'saved.mailTemplate',
			updateSuccessBody: 'updated.mailTemplate',
			deleteSucccessBody: 'deleted.mailTemplate',
			createErrorBody: 'saveError.mailTemplate',
			updateErrorBody: 'saveError.mailTemplate',
			deleteErrorBody: 'deleteError.template'
		});

		var MailTemplate = $resource(
			URL + API + 'mail/templates/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					})
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: true,
						custom: false,
						skipDateConvert: true
					})
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					}),
					transformRequest: MapGeneric(null, {
						payloadName: 'mailTemplate'
					})
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseMailTemplate', {
						isArray: false,
						custom: false,
						skipDateConvert: true
					}),
					transformRequest: MapGeneric(null, {
						payloadName: 'mailTemplate'
					})
				}
			}
		);

		var Model = {
			new: function () {
				var self = AppService.getSelf();

				var mailCampaign = {
					name: '',
					user: self,
					subject: '',
					body: '',
					bodyJson: null,
					from: self.email,
					fromName: self.name,
					usedCounter: 0,
					active: 0,
					private: false,
					roles: [],
					attachments: []
				};

				return mailCampaign;
			},

			find: function (filter, options) {
				var params = angular.extend(filter, options);
				return MailTemplate.find(params).$promise;
			},

			get: function (id) {
				return MailTemplate.get({ id: id }).$promise;
			},

			save: function (mailTemplate, options) {
				var defered = $q.defer();

				if (!mailTemplate) {
					return defered.reject('No mailTemplate');
				}

				if (mailTemplate.id) {
					MailTemplate.update(
						{ id: mailTemplate.id },
						{ mailTemplate: mailTemplate },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					MailTemplate.create(
						{ mailTemplate: mailTemplate },
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !mailTemplate.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !mailTemplate.id);
					});

				return defered.promise;
			},

			delete: function (mailTemplate, options) {
				return MailTemplate.delete({ id: mailTemplate.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, mailTemplate);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			mailBodyPreview: function (type, id) {
				if (!id) {
					id = type;
					type = 'template';
				}
				var baseUrl = URL + API + '/file/mailBodyPreview/' + type + '/' + id;
				return baseUrl;
			},
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},
			attr: Attributes,
			keys: Keys
		};

		return Model;
	}
]);

import Resource from './Resource';
import Attributes from 'Attributes/ARRChangesAttributes';

class ARRChanges extends Resource {
	constructor() {
		super('annualvaluechanges', Attributes);
	}
}

const resource = new ARRChanges();

window.Tools.ARRChanges = resource;

export default resource;

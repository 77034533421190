'use strict';

angular.module('services.fixOrder', []).factory('FixOrder', [
	'$q',
	'AppService',
	function ($q, AppService) {
		var instance = {};

		instance.print = function (order) {
			order.value = 0;
			order.currencyRate = order.currencyRate || 1;

			if (!order.orderRow || !order.orderRow.length) {
				return order;
			}
			angular.forEach(order.orderRow, function (row) {
				if (!row || !row.price || !row.quantity) {
					return;
				}
				order.value += row.price * order.currencyRate * row.quantity;
				if (order.currency === null) {
					var defaultCurrency = _.find(AppService.getMetadata().customerCurrencies, { masterCurrency: true });
					if (defaultCurrency) {
						order.currency = defaultCurrency.iso;
					}
				}
			});

			return order;
		};

		return instance;
	}
]);

'use strict';
import logError from 'Helpers/logError';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';

angular.module('upModals.controllers').controller('EditColumns', [
	'$scope',
	'$modalParams',
	'$translate',
	'ListViewService',
	'FilterHelper',
	'CustomfieldMapper',
	'AppService',
	'FeatureHelper',
	function (
		$scope,
		$modalParams,
		$translate,
		ListViewService,
		FilterHelper,
		CustomfieldMapper,
		AppService,
		FeatureHelper
	) {
		var EditColumns = this;
		var selectables = [];
		var customfields = [];

		EditColumns.columns = [];
		EditColumns.customFields = [];
		EditColumns.addressColumns = [];
		EditColumns.nestedColumns = [];
		EditColumns.maxColumns = $modalParams.maxColumns || 8;
		EditColumns.maxColumnsSelected; // eslint-disable-line  no-unused-expressions

		EditColumns.sortableOptions = {
			containment: 'parent',
			cursor: 'move',
			disabled: false,
			scroll: false,
			placeholder: 'drag-placeholder',
			tolerance: 'pointer',
			items: '.draggable',
			cancel: '.remove'
		};

		const getField = $modalParams.attributes
			? (_tableType, key) => $modalParams.attributes[key] ?? null
			: ListViewService.getField;

		EditColumns.done = function () {
			if (EditColumns.columns.length) {
				$scope.resolve(_.pluck(EditColumns.columns, 'key'));
			} else {
				//Shouldn't reach this code since button should be disabled.
				logError(new Error("Can't save without selected columns"));
			}
		};

		EditColumns.add = function (col) {
			if (col.$selected || EditColumns.columns.length >= EditColumns.maxColumns) {
				return;
			}

			col.$selected = true;

			EditColumns.columns.push(col);

			EditColumns.maxColumnsSelected = EditColumns.columns.length === EditColumns.maxColumns;
		};

		EditColumns.remove = function (col) {
			_.pull(EditColumns.columns, col);

			var selectable;
			if (FilterHelper.isCustom(col.key)) {
				selectable = _.find(EditColumns.customFields, { key: col.key });
			} else if (FilterHelper.isAddress(col.key)) {
				selectable = _.find(EditColumns.addressColumns, { key: col.key });
			} else if (FilterHelper.parseNested(col.key)) {
				selectable = _.find(EditColumns.nestedColumns, { key: col.key });
			} else {
				selectable = _.find(EditColumns.selectableColumns, { key: col.key });
			}
			if (selectable) {
				selectable.$selected = false;
			}

			EditColumns.maxColumnsSelected = EditColumns.columns.length === EditColumns.maxColumns;
		};

		var fixAndOrderSelectables = function () {
			var cols = [];
			angular.forEach(selectables, function (col) {
				var index = $modalParams.columns.indexOf(col.key);
				col.$translated = $translate.instant(col.title);
				col.$selected = index !== -1;

				if (FilterHelper.isAddress(col.key)) {
					EditColumns.addressColumns.push(col);
				} else if (FilterHelper.parseNested(col.key)) {
					col.title = FilterHelper.parseNested(col.key);
					col.$translated = $translate.instant('nested.' + FilterHelper.parseNested(col.key).child);
					EditColumns.nestedColumns.push(col);
				} else {
					cols.push(col);
				}
			});

			if (customfields) {
				angular.forEach(customfields, function (field) {
					if (!CustomfieldMapper.hasAccess(field) || !field.visible) {
						return false;
					}

					var key = 'Custom_' + field.id;
					if (field.nameType) {
						key = 'Custom' + field.nameType + '_' + field.id;
					}

					var index = $modalParams.columns.indexOf(key);
					EditColumns.customFields.push({
						key: key,
						title: field.name,
						$translated: $translate.instant(field.name),
						$selected: index !== -1
					});
				});
			}

			var hasNewFields = FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS);
			var standardFields = AppService.getMetadata().standardFields;

			EditColumns.selectableColumns = cols.filter(column => {
				var returnValue = true;

				if (column.unreleasedFeature === 'NEW_FIELDS') {
					if (hasNewFields) {
						var entity = $modalParams.tableType === 'account' ? 'client' : $modalParams.tableType;
						if (entity) {
							entity = entity[0].toUpperCase() + entity.substring(1);
						}
						var found = _.find(standardFields[entity], { field: column.field });

						if (!found || !found.active) {
							return false;
						}
					} else {
						return false;
					}
				}

				if (column.locked) {
					returnValue = false;
				}

				if (column.hasOwnProperty('hide') && column.hide === true) {
					returnValue = false;
				}

				const cmColumns = ['cmLast12Months', 'cmTrend12Months', 'cmPercentOfTurnover'];
				const salesModel = Tools.AppService.getMetadata().params.SalesModel;

				const dontHaveCM = salesModel !== 'cm' && !hasRRWithCM();

				if (cmColumns.includes(column.field) && dontHaveCM) {
					returnValue = false;
				}

				const rrColumns = [
					'arr',
					'arrLast12Months',
					'arrPercentOfTurnover',
					'arrOneYearAgo',
					'mrr',
					'mrrLast12Months',
					'mrrPercentOfTurnover',
					'mrrOneYearAgo'
				];
				const enabledSubscriptions =
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
					Tools.AppService.getMetadata().params.AgreementEnabled;

				if (rrColumns.includes(column.field) && !enabledSubscriptions && !hasCMWithRR()) {
					returnValue = false;
				}

				return returnValue;
			});
		};

		var init = function () {
			selectables = $modalParams.selectables;
			customfields = $modalParams.customfields;

			EditColumns.columnTitle = 'filters.columns.' + $modalParams.tableType;

			if ($modalParams.tableType.indexOf('userDefinedObject') === 0) {
				var metadata = AppService.getMetadata();
				var udos = metadata.params.UserDefinedObject;
				var type = $modalParams.tableType;
				var udo = _.find(udos, { id: parseInt(type[type.length - 1]) });
				if (udo) {
					EditColumns.columnTitle = udo.name;
				}
			}

			// Convert columns to a Set in order to remove duplicates
			const columns = new Set($modalParams.columns);
			angular.forEach(columns, function (key) {
				if (
					$modalParams.tableType !== 'mailCampaign' ||
					key !== 'labels' ||
					Tools.FeatureHelper.hasSoftDeployAccess('MAIL_CAMPAIGN_LABELS')
				) {
					var field;
					if (FilterHelper.isCustom(key)) {
						field = FilterHelper.getCustomConfig(key, customfields);
						EditColumns.columns.push({
							key: key,
							title: field.title,
							$translated: $translate.instant(field.title)
						});

						return;
					} else if (FilterHelper.isCategory(key) || FilterHelper.isAddress(key)) {
						field = _.find(selectables, { key: key });
						if (field) {
							EditColumns.columns.push({
								key: key,
								title: field.title,
								$translated: $translate.instant(field.title)
							});
						}
						return;
					} else if (FilterHelper.parseNested(key)) {
						EditColumns.columns.push({
							key: key,
							title: FilterHelper.parseNested(key),
							$translated: $translate.instant('nested.' + FilterHelper.parseNested(key).child)
						});

						return;
					}

					var c = getField($modalParams.tableType, key);
					if (c) {
						c.key = key;
						c.$translated = $translate.instant(c.title);
						EditColumns.columns.push(c);
					}
				}
			});

			fixAndOrderSelectables();

			EditColumns.maxColumnsSelected = EditColumns.columns.length === EditColumns.maxColumns;
		};

		init();
	}
]);

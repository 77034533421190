'use strict';

ReactTemplates.form.videoContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			videoAddress: t('landingpage.videoAddress'),
			allowFullScreen: t('landingpage.allowFullScreen'),
			enterVideoHrefHere: t('landingpage.enterVideoHrefHere'),
			shadowBelowVideo: t('landingpage.shadowBelowVideo'),
			style: t('landingpage.style'),
			pasteVideoInfo: t('landingpage.pasteVideoInfo'),
			onlyYoutubeOrVimeoError: t('landingpage.onlyYoutubeOrVimeoError')
		};
	},
	fullScreenChange: function () {
		var element = this.state.element;

		if (element.value.allowFullScreen) {
			element.value.allowFullScreen = false;
		} else {
			element.value.allowFullScreen = true;
		}

		this.setState({ element: element });
		this.props.onChange(element);
	},
	shadowChange: function () {
		var element = this.state.element;

		if (element.value.shadow) {
			element.value.shadow = false;
		} else {
			element.value.shadow = true;
		}

		this.setState({ element: element });
		this.props.onChange(element);
	},
	isValid: function () {
		var src = this.state.element.value.src;
		if (src.indexOf('youtube') !== -1 || src.indexOf('youtu.be') !== -1) {
			return true;
		}

		if (src.indexOf('vimeo') !== -1) {
			return true;
		}

		if (src.indexOf('wistia') !== -1) {
			return true;
		}

		return false;
	},
	getIcon: function () {
		var src = this.state.element.value.src;
		if (src.indexOf('youtube') !== -1 || src.indexOf('youtu.be') !== -1) {
			return <b className="fa fa-youtube"></b>;
		} else if (src.indexOf('vimeo') !== -1) {
			return <b className="fa fa-vimeo"></b>;
		}
		return null;
	},
	srcChange: function (e) {
		var element = this.state.element;

		element.value.src = e.target.value;

		this.setState({ element: element });
		this.props.onChange(element);
	},
	render: function () {
		var self = this;

		var icon = this.getIcon();
		var isValid = this.isValid();
		var hasVideo = self.state.element.value.src && self.state.element.value.src.length;
		var src = this.state.element.value.src;
		var isYoutube = src.indexOf('youtube') !== -1 || src.indexOf('youtu.be') !== -1;
		var inputClass = 'form-control video-input';

		if (!isValid) {
			inputClass += ' input-warning';
		}

		return (
			<div>
				<div className="tool-main-section">
					<div className="tool-section" key="addr-row">
						<label className="tool-label block-label">{self.lang.videoAddress}</label>
						<p className="text-dark-grey">
							<i>{self.lang.pasteVideoInfo}</i>
						</p>
						<div className={icon ? 'input-group' : ''}>
							<input
								type="text"
								className={inputClass}
								value={self.state.element.value.src}
								onChange={self.srcChange}
								placeholder={self.lang.enterVideoHrefHere}
							/>
							{icon ? <div className="input-group-addon">{icon}</div> : null}
						</div>
						{hasVideo && !isValid ? (
							<div className="video-warning">{self.lang.onlyYoutubeOrVimeoError}</div>
						) : null}
					</div>

					{isYoutube ? (
						<div className="tool-section" key="fs-row">
							<label>{self.lang.allowFullScreen}</label>
							<div className="pull-right">
								<ReactTemplates.upFilters.components.toggle
									checked={self.state.element.value.allowFullScreen}
									onChange={self.fullScreenChange}
									className="toggle-bright-blue"
								/>
							</div>
						</div>
					) : null}

					{isYoutube ? (
						<div className="tool-section" key="shadow-row">
							<label>{self.lang.shadowBelowVideo}</label>
							<div className="pull-right">
								<ReactTemplates.upFilters.components.toggle
									checked={self.state.element.value.shadow}
									onChange={self.shadowChange}
									className="toggle-bright-blue"
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
		// {hasVideo & false ? <div>
		// 	<div className='tool-header'>
		// 		<h3>{self.lang.style}</h3>
		// 	</div>
		// 	<div className='tool-main-section'>
		// 	</div>
		// </div> : null}
	}
});

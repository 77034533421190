'use strict';
import history from 'App/pages/routes/history';

angular.module('domain.campaign').controller('CampaignForms', [
	'$scope',
	'$state',
	'$stateParams',
	'RequestBuilder',
	'Form',
	'AppService',
	'FilterHelper',
	function ($scope, $state, $stateParams, RequestBuilder, Form, AppService, FilterHelper) {
		var currentFilter;
		var CampaignCtrl = $scope.CampaignCtrl;

		var CampaignFormsCtrl = this;

		CampaignFormsCtrl.error = false;
		CampaignFormsCtrl.limit = 50;
		CampaignFormsCtrl.loading = false;
		CampaignFormsCtrl.currentPage = 1;
		$scope.formsPages = 0;

		$scope.$on('form.updated', async function (e, updated) {
			var index = _.findIndex(CampaignFormsCtrl.forms, { id: updated.id });
			const form = await Form.get(updated.id).then(res => res.data);
			if (FilterHelper.match(currentFilter.q, updated, 'form')) {
				if (index !== -1) {
					CampaignFormsCtrl.forms[index] = form;
				} else {
					CampaignFormsCtrl.forms.push(form);
				}
			} else if (index !== -1) {
				CampaignFormsCtrl.forms.splice(index, 1);
			}
			CampaignCtrl.forms = CampaignFormsCtrl.forms;
		});

		CampaignFormsCtrl.getForms = function ($page) {
			CampaignFormsCtrl.error = false;
			CampaignFormsCtrl.loading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Form.attr.projects, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addSort('name', true);
			filter.limit = CampaignFormsCtrl.limit;
			filter.offset = CampaignFormsCtrl.limit * ($page - 1);
			currentFilter = filter.build();

			Form.find(currentFilter)
				.then(function (res) {
					CampaignFormsCtrl.loading = false;
					CampaignFormsCtrl.forms = res.data;
					CampaignCtrl.formsTotal = res.metadata.total;
					CampaignCtrl.forms = CampaignFormsCtrl.forms;

					if (CampaignCtrl.formsTotal !== 0) {
						$scope.formsPages = Math.ceil(CampaignCtrl.formsTotal / CampaignFormsCtrl.limit);
					}
				})
				.catch(function () {
					CampaignFormsCtrl.loading = false;
					CampaignFormsCtrl.error = true;
				});
		};

		CampaignFormsCtrl.removeFromProject = function (form) {
			_.remove(form.projects, { id: CampaignCtrl.campaign.id });
			Form.save({ id: form.id, projects: form.projects });
		};

		CampaignFormsCtrl.goToForm = function (form) {
			history.push('/form-overview/' + form.id);
		};

		var init = function () {
			CampaignFormsCtrl.getForms(1);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import './CssEditor.scss';

class CssEditor extends PureComponent {
	static propTypes = {
		form: PropTypes.object.isRequired,
		apply: PropTypes.func.isRequired,
		hideHeader: PropTypes.bool,
		isVisible: PropTypes.bool
	};

	state = {
		isUpdating: false
	};

	editor;
	editorRef = createRef();

	componentDidMount() {
		this.editor = ace.edit(this.editorRef.current);
		this.editor.setTheme('ace/theme/monokai');
		this.editor.session.setMode('ace/mode/css');

		var value = this.props.form.fields.find(field => {
			return field.name.indexOf('Prefs.css') === 0;
		});

		if (value && value.options) {
			this.editor.session.setValue(value.options);
		}

		let i, j;
		this.editor.session.on('change', () => {
			clearTimeout(i);
			clearTimeout(j);

			i = setTimeout(() => {
				this.setState({ isUpdating: true });

				j = setTimeout(() => {
					this.update();
					this.setState({ isUpdating: false });
				}, 300);
			}, 200);
		});
	}

	componentWillUnmount() {
		if (this.editor) {
			this.editor.destroy();
		}
	}

	update = () => {
		const form = { ...this.props.form };

		_.remove(form.fields, ({ name }) => name.indexOf('Prefs.css') === 0);

		form.fields.push({
			title: 'form.Prefs.css',
			name: 'Prefs.css',
			type: 'string',
			options: this.editor.session.getValue()
		});

		this.props.apply(form);
	};

	render() {
		return (
			<div className="CssEditor" style={{ display: this.props.isVisible ? 'block' : 'none' }}>
				{!this.props.hideHeader && (
					<div className="tool-header">
						<h3>
							{'Custom CSS'} <ReactTemplates.elevio articleId="967" />
						</h3>
					</div>
				)}

				<div id="editor-wrap" ref={this.editorRef} />
				{this.state.isUpdating && <window.Loader noU dark className="pull-right" />}
			</div>
		);
	}
}

window.CssEditor = CssEditor;
export default CssEditor;

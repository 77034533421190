import React, { useEffect, useRef, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './GroupMailEditorRobot.scss';
import Lottie from 'react-lottie';
import { useAnimationData } from 'App/components/hooks';

type STATES = 'scheduled' | 'waiting' | 'send' | 'booting';

type Props = {
	state: STATES;
	className?: string;
};

type Animation = {
	playSegments: (keyframes: number[], loop: boolean) => void;
	pause: () => void;
	addEventListener: (event: string, callback: () => void) => void;
	removeEventListener: (event: string) => void;
};

const GroupMailEditorRobot: React.FC<Props> = ({ state, className }) => {
	const [loopback, setLoopback] = useState<number[] | null>(null);
	const { animationData } = useAnimationData(() => import('./emailBot.json'));
	const _lottie = useRef<Lottie & { anim: Animation }>(null);

	const classes = new bemClass('GroupMailEditorRobot', className);

	const onLoopComplete = (loopbackSegments: number[]) => {
		_lottie.current?.anim.pause();
		_lottie.current?.anim.playSegments(loopbackSegments, true);
	};

	const runAnimations = () => {
		let keyframes;
		let loopback = null;

		switch (state) {
			case 'scheduled':
				keyframes = [38, 275];
				break;
			case 'waiting':
				keyframes = [275, 324];
				loopback = [275, 324];
				break;
			case 'send':
				keyframes = [324, 450];
				break;
			case 'booting':
				keyframes = [0, 275];
				loopback = [38, 275];
				break;
		}

		setLoopback(loopback);

		_lottie.current?.anim.playSegments(keyframes, true);
	};

	useEffect(() => {
		if (_lottie.current) {
			if (loopback) {
				_lottie.current?.anim.addEventListener('loopComplete', () => {
					onLoopComplete(loopback);
				});
			}
			return () => {
				_lottie.current?.anim.removeEventListener('loopComplete');
			};
		}
	}, [_lottie.current]);

	useEffect(() => {
		runAnimations();
	}, [state]);

	const defaultOptions = {
		loop: state !== 'send',
		autoplay: false,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div className={classes.b()}>
			<Lottie options={defaultOptions} height={600} width={600} isClickToPauseDisabled={true} ref={_lottie} />
		</div>
	);
};

export default GroupMailEditorRobot;

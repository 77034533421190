import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import { Icon, Text, Tooltip } from '@upsales/components';
import moment from 'moment';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import translate from 'Components/Helpers/translate';
import { makeIterator, editOpportunity } from '../shared';
import { flatten } from 'lodash';

import './Content.scss';

const classes = new BEM('PeriodizationListContent');
const tableClasses = new BEM('PeriodizationListTable');
const columnClass = 'PeriodizationListContentColumn';

const Warning = ({ title }) => (
	<Tooltip title={title}>
		<Icon color="orange" name="warning" />
	</Tooltip>
);

const getHeaderOptions = (startDate, endDate) => {
	let currentPosition = moment(startDate);
	const result = [];

	do {
		const startOfNextYear = moment(currentPosition).add(1, 'year').startOf('year');
		const endMonth = endDate.isBefore(startOfNextYear) ? endDate.month() + 1 : 12;
		const span = endMonth - currentPosition.month();

		result.push({ year: currentPosition.year(), span });

		currentPosition = startOfNextYear;
	} while (currentPosition.isSameOrBefore(endDate));

	return result;
};

export default class Content extends React.Component {
	static propTypes = {
		startDate: PropTypes.object.isRequired,
		endDate: PropTypes.object.isRequired,
		opportunities: PropTypes.array.isRequired,
		periodizationField: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.language = {
			opportunity: translate('default.opportunity')
		};
	}

	render() {
		const { startDate, endDate, opportunities, periodizationField } = this.props;

		const yearSelectOptions = getHeaderOptions(startDate, endDate).map(({ year, span }, index) => {
			const columnClasses = new BEM(columnClass);

			return (
				<div
					key={year}
					style={{ gridColumn: `span ${span}` }}
					id={`PeriodizationList__scrollspy-${year}`}
					className={`PeriodizationList__scrollspy ${columnClasses.mod({ 'border-left': index > 0 }).b()}`}
				>
					<Text size="xl">{year}</Text>
				</div>
			);
		});

		const startOfDay = moment().startOf('day');
		const tableHeaderColumns = Array.from(makeIterator(startDate, endDate)).map((momentObj, index, array) => {
			const columnClasses = new BEM(columnClass);
			const showBorderLeft = index > 0 && array[index - 1].year() !== momentObj.year() ? true : false;
			const key = momentObj.format('YYYY-MM');
			const month = momentObj.format('MMMM');

			return (
				<div key={key} className={columnClasses.mod({ 'border-left': showBorderLeft }).b()}>
					<Text size="sm">{month}</Text>
				</div>
			);
		});

		const tableRows = opportunities.map(opportunity => {
			const monthIterator = makeIterator(startDate, endDate);

			const flatArray = opportunity.periodization ? flatten(Object.values(opportunity.periodization.data)) : [];
			const periodizationMap = flatArray.reduce((dateMap, periodization) => {
				const key = `${periodization.year}-${periodization.month}`;
				const currentValue = dateMap[key];
				dateMap[key] = currentValue
					? currentValue + (periodization[periodizationField] || 0)
					: periodization[periodizationField] || 0;
				return dateMap;
			}, {});

			const columns = Array.from(monthIterator).map((momentObj, index, array) => {
				const columnClasses = new BEM(columnClass);
				const key = momentObj.format('YYYY-M');
				const showBorderLeft = index > 0 && array[index - 1].year() !== momentObj.year() ? true : false;
				const value = periodizationMap[key] || 0;
				const textProps = value ? {} : { italic: true, color: 'grey-6' };
				let warning = null;

				if (value && momentObj.isBefore(startOfDay)) {
					const title = translate('periodization.passedCloseDate', { date: momentObj.format('YYYY-MM-DD') });
					warning = <Warning title={title} />;
				}

				return (
					<div
						key={key}
						className={columnClasses.mod({ 'border-left': showBorderLeft }).b()}
						onClick={() => editOpportunity(opportunity)}
					>
						{warning}
						<Text {...textProps}>{numberFormat(value)}</Text>
					</div>
				);
			});

			return <div key={opportunity.id}>{columns}</div>;
		});

		return (
			<div className={classes.b()}>
				<div className={classes.elem('header').b()}>
					<div className={classes.elem('year-select').b()}>{yearSelectOptions}</div>
				</div>
				<div className={tableClasses.b()}>
					<div>{tableHeaderColumns}</div>
					{tableRows}
				</div>
			</div>
		);
	}
}

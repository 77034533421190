import React, { useState } from 'react';
import T from '../../Helpers/translate';
import SupportTicketPage from 'App/upsales/common/components/react/filters/history/SupportTicketPage';

function HistoryFilter({ filter, onChange, filterName, ...restProps }) {
	const [currentComponent, setCurrentComponent] = useState({});
	const active = isActive(filter);
	const value = formatValue(filter, active);
	const [headerClass, titleClass, bodyClass] = generateClasses(active);

	if (currentComponent.visible) {
		return (
			<div className="list-filters-row">
				<div className={headerClass} onClick={toggleExpanded}>
					<span className={titleClass}>{T(filter.title)}</span>
					<span className={bodyClass}>{value}</span>
				</div>
				<div className="drilldown">
					{React.createElement(currentComponent.component, {
						filter,
						onChange,
						filterName,
						...restProps
					})}
				</div>
			</div>
		);
	}

	return (
		<div className="list-filters-row">
			<div className={headerClass}>
				<span onClick={toggleExpanded} className={titleClass}>
					{T(filter.title)}
				</span>
				{active ? (
					<span className="delete-button pull-right" onClick={event => onRemoveFilter(event, filterName)}>
						<i className="fa fa-times-circle" aria-hidden="true" />
					</span>
				) : null}
				<span onClick={toggleExpanded} className={bodyClass}>
					{value}
				</span>
			</div>
		</div>
	);

	function generateClasses(active) {
		if (active) {
			return ['header active', 'title active', 'selected-values pull-right'];
		}

		return ['header', 'title', 'selected-values pull-right default'];
	}

	function isActive(filter) {
		if (filter.hasOwnProperty('inactive')) {
			return !filter.inactive;
		}

		return false;
	}

	function formatValue(filter, isActive) {
		if (isActive) {
			const numberFilters = filter.getText(filter.value, filter.inactive);

			if (numberFilters === 1) {
				return `${numberFilters} ${T('filter').toLowerCase()}`;
			}

			return `${numberFilters} ${T('filters').toLowerCase()}`;
		}

		return T('default.all');
	}

	function toggleExpanded() {
		let props = {
			component: null,
			filterName: filterName,
			visible: true
		};

		switch (filterName) {
			case 'OpportunityFilters':
			case 'HasOpportunity':
				props.component = ReactTemplates.upFilters.filterTypes.historyOpportunityPage;
				break;
			case 'ClientActivityFilters':
			case 'HasActivity':
				props.component = ReactTemplates.upFilters.filterTypes.historyActivityPage;
				break;
			case 'HasAppointment':
				props.component = ReactTemplates.upFilters.filterTypes.historyAppointmentPage;
				break;
			case 'ClientTodoFilters':
			case 'HasTodo':
				props.component = ReactTemplates.upFilters.filterTypes.historyTodoPage;
				break;
			case 'ClientPhonecallFilters':
			case 'HasPhonecall':
				props.component = ReactTemplates.upFilters.filterTypes.historyPhonecallPage;
				break;
			case 'ClientOrderFilters':
			case 'HasOrder':
				props.component = ReactTemplates.upFilters.filterTypes.historyOrderPage;
				break;
			case 'ClientSupportTicketFilters':
			case 'HasSupportTickets':
				props.component = SupportTicketPage;
				break;
		}

		if (currentComponent.filterName === filterName) {
			props = {
				component: null,
				filterName: null,
				visible: false
			};
		}

		setCurrentComponent(props);
	}

	function onRemoveFilter(event, filterName) {
		onChange({ filterName: filterName }, { action: 'remove' });
	}
}

window.HistoryFilter = HistoryFilter;
export default HistoryFilter;

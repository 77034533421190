import logError from 'App/babel/helpers/logError';
import EditUserRoot from 'Components/Admin/EditUserRoot';
import moment from 'moment';
import Quota from 'Resources/Quota';
import openModal from 'App/services/Modal';
import { FieldModel } from 'App/components/FormObserver';

angular.module('domain.admin').controller('EditUserCtrl', [
	'$scope',
	'AppService',
	'$safeApply',
	'$stateParams',
	'User',
	'$state',
	'NotificationService',
	'$upModal',
	'$translate',
	'GenericQuota',
	'ActivityQuota',
	function (
		$scope,
		AppService,
		$safeApply,
		$stateParams,
		User,
		$state,
		NotificationService,
		$upModal,
		$translate,
		GenericQuota,
		ActivityQuota
	) {
		var EditUserCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;
		var currentHash;
		var currentEmail;
		var currentName;

		var paramMap = {
			mailAdmin: 55,
			multiSoliditetAccess: 56,
			dnbCountryCodes: 57,
			signalsModifyAccess: 71
		};

		const hasCustomerSupport = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT);

		var parseBool = function (val) {
			return parseInt(val) ? true : false;
		};

		var parseUserParams = function (params) {
			const mapped = {
				dnbCountryCodes: 'SE',
				multiSoliditetAccess: 0,
				mailAdmin: 0,
				signalsModifyAccess: 0
			};

			params.forEach(param => {
				if (param.param_id === paramMap.dnbCountryCodes) {
					mapped.dnbCountryCodes = param.value;
				}
				if (param.param_id === paramMap.multiSoliditetAccess) {
					mapped.multiSoliditetAccess = parseBool(param.value);
				}
				if (param.param_id === paramMap.mailAdmin) {
					mapped.mailAdmin = parseBool(param.value);
				}
				if (param.param_id === paramMap.signalsModifyAccess) {
					mapped.signalsModifyAccess = parseBool(param.value);
				}
			});

			return mapped;
		};

		var newUser = function () {
			return {
				email: '',
				role: null,
				name: '',
				userTitle: '',
				language: 'sv-SE',
				administrator: 0,
				billingAdmin: 0,
				teamLeader: 0,
				export: 0,
				userParams: parseUserParams([]),
				custom: [],
				active: 1,
				ghost: 0,
				crm: 1,
				support: 0,
				userCellPhone: ''
			};
		};

		var showSave = function () {
			if (
				!EditUserCtrl.rootData.pageData.addSeatsDrawerVisible &&
				currentHash !== Tools.LZString.compressToBase64(JSON.stringify(EditUserCtrl.rootData.pageData.user)) &&
				!EditUserCtrl.rootData.pageData.errors.email &&
				!EditUserCtrl.rootData.pageData.errors.licenseType
			) {
				EditUserCtrl.rootData.saveVisible = true;
			} else {
				EditUserCtrl.rootData.saveVisible = false;
			}
			$safeApply($scope);
		};

		var mapInvite = function (email, user) {
			var mappedUser = {
				email: email,
				roleId: user.role ? user.role.id : null,
				administrator: user.administrator,
				billingAdmin: user.billingAdmin,
				teamLeader: user.teamLeader,
				export: user.export,
				language: user.language,
				userParams: user.userParams,
				custom: user.custom,
				active: user.active,
				ghost: user.ghost,
				crm: user.crm,
				support: user.support
			};

			if (!EditUserCtrl.rootData.pageData.multi) {
				mappedUser.name = user.name;
				mappedUser.userTitle = user.userTitle;
				mappedUser.userCellPhone = user.userCellPhone;
			}

			return mappedUser;
		};

		var mapUserParams = function (params) {
			var mapped = [];
			Object.keys(params).forEach(function (key) {
				mapped.push({ param_id: paramMap[key], value: params[key] });
			});

			return mapped;
		};

		var validate = function () {
			var hasError = false;
			var errors = {
				email: false,
				name: false,
				userCellPhone: false,
				licenseType: false,
				custom: {}
			};

			if (
				(!EditUserCtrl.rootData.pageData.multi &&
					!EditUserCtrl.rootData.pageData.emailRegex.test(EditUserCtrl.rootData.pageData.user.email)) ||
				(EditUserCtrl.rootData.pageData.multi && !EditUserCtrl.rootData.pageData.emails.length)
			) {
				errors.email = true;
				hasError = true;
			}
			if (!EditUserCtrl.rootData.pageData.multi && !EditUserCtrl.rootData.pageData.user.name.length) {
				errors.name = true;
				hasError = true;
			}

			var fields = Tools.AppService.getCustomFields('user');
			for (const field of fields) {
				const customValue = EditUserCtrl.rootData.pageData.user.custom.find(c => c.fieldId === field.id);
				if (field.obligatoryField && !customValue?.value) {
					errors.custom[field.id] = true;
					hasError = true;
				}
			}

			if (
				hasCustomerSupport &&
				!EditUserCtrl.rootData.pageData.user.crm &&
				!EditUserCtrl.rootData.pageData.user.support
			) {
				errors.licenseType = true;
				hasError = true;
			}

			EditUserCtrl.rootData.pageData.hasError = hasError;
			EditUserCtrl.rootData.pageData.errors = errors;

			$safeApply($scope);
		};

		function getStatus() {
			const user = EditUserCtrl.rootData.pageData.user;
			if (user.active && !user.ghost) {
				return 'active';
			}
			if (user.active && user.ghost) {
				return 'inactive';
			}
			return 'removed';
		}

		function getAddSeatDrawerVisible() {
			return EditUserCtrl.rootData.pageData.addSeatsDrawerVisible;
		}

		EditUserCtrl.rootData = AdminCtrl.getRootObject();
		EditUserCtrl.rootData.pageComponent = EditUserRoot;
		EditUserCtrl.rootData.pageLoading = true;
		EditUserCtrl.rootData.pageData = {
			submitted: false,
			fieldTouched: {
				email: false,
				name: false,
				title: false,
				userCellPhone: false
			},
			edit: false,
			emailRegex:
				// eslint-disable-next-line no-useless-escape
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			// eslint-disable-next-line no-useless-escape
			phoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,12}$/im,
			multi: false,
			hasError: false,
			errors: {
				email: false,
				name: false,
				userCellPhone: false
			},
			user: null, // yes, important
			emails: [],
			roles: [],
			noLicences: false,
			isLastActiveUser: false,
			isLastAdmin: false,
			getStatus: getStatus,
			getAddSeatDrawerVisible: getAddSeatDrawerVisible,
			toggleMulti: function () {
				EditUserCtrl.rootData.pageData.multi = !EditUserCtrl.rootData.pageData.multi;
				if (
					EditUserCtrl.rootData.pageData.multi &&
					EditUserCtrl.rootData.pageData.user.email.length &&
					!EditUserCtrl.rootData.pageData.emails.length
				) {
					EditUserCtrl.rootData.pageData.emails = [EditUserCtrl.rootData.pageData.user.email];
				}
				$safeApply($scope);
			},
			userFieldChange: function (key, val) {
				EditUserCtrl.rootData.pageData.user[key] = val;
				// uncheck billing admin if admin was untoggled
				if (key === 'administrator' && !val) {
					EditUserCtrl.rootData.pageData.user.billingAdmin = 0;
				}
				validate();
				showSave();

				$safeApply($scope);
			},
			userParamChange: function (key, val) {
				EditUserCtrl.rootData.pageData.user.userParams[key] = val;
				showSave();
				validate();
				$safeApply($scope);
			},
			setEmails: function (value) {
				EditUserCtrl.rootData.pageData.emails = value;

				const usersCount = Tools.AppService.getRealActiveUsersCount();
				const accountSelf = Tools.AppService.getAccountSelf();
				if (accountSelf.client.numberOfLicenses < usersCount + EditUserCtrl.rootData.pageData.emails.length) {
					EditUserCtrl.rootData.pageData.noLicences = true;
					EditUserCtrl.rootData.pageData.setStatus('inactive');
				} else {
					EditUserCtrl.rootData.pageData.noLicences = false;
					EditUserCtrl.rootData.pageData.setStatus('active');
				}
				validate();
				showSave();
				$safeApply($scope);
			},
			setFieldTouched: function (key) {
				EditUserCtrl.rootData.pageData.fieldTouched[key] = true;
				$safeApply($scope);
			},
			customFieldChange: function (id, value) {
				var i = _.findIndex(EditUserCtrl.rootData.pageData.user.custom, function (obj) {
					return parseInt(obj.fieldId) === parseInt(id);
				});
				if (i !== -1) {
					EditUserCtrl.rootData.pageData.user.custom[i].value = value;
				} else {
					EditUserCtrl.rootData.pageData.user.custom.push({ fieldId: id, value: value });
				}
				showSave();
				validate();
				$safeApply($scope);
			},
			setStatus: function (status) {
				if (status === 'active') {
					EditUserCtrl.rootData.pageData.user.active = 1;
					EditUserCtrl.rootData.pageData.user.ghost = 0;
					EditUserCtrl.rootData.saveBtnText = !$stateParams.id ? 'admin.inviteUsers' : null;
				} else if (status === 'inactive') {
					EditUserCtrl.rootData.pageData.user.active = 1;
					EditUserCtrl.rootData.pageData.user.ghost = 1;
					EditUserCtrl.rootData.saveBtnText = !$stateParams.id ? 'admin.createInactiveUser' : null;
				} else if (status === 'removed') {
					EditUserCtrl.rootData.pageData.user.active = 0;
					EditUserCtrl.rootData.pageData.user.ghost = 0;
					EditUserCtrl.rootData.saveBtnText = !$stateParams.id ? 'admin.createInactiveUser' : null;
				}
				showSave();
			},
			setNoLicenses: function (status) {
				if (status) {
					EditUserCtrl.rootData.pageData.noLicences = true;
				}
				showSave();
				validate();
				$safeApply($scope);
			},
			setAddSeatDrawerVisible: function (open) {
				EditUserCtrl.rootData.pageData.addSeatsDrawerVisible = open;
				showSave();
				$safeApply($scope);
			}
		};

		var invite = function () {
			var user = Object.assign({}, EditUserCtrl.rootData.pageData.user);

			if (!user.crm && hasCustomerSupport) {
				user.administrator = false;
				user.billingAdmin = false;
				user.teamLeader = false;
				user.export = false;
				user.userParams.mailAdmin = false;
				user.userParams.signalsModifyAccess = false;
				user.userParams.multiSoliditetAccess = false;
			}

			user.userParams = mapUserParams(EditUserCtrl.rootData.pageData.user.userParams);
			var users = [];

			if (EditUserCtrl.rootData.pageData.multi) {
				_.each(EditUserCtrl.rootData.pageData.emails, function (email) {
					users.push(mapInvite(email, user));
				});
			} else {
				users.push(mapInvite(user.email, user));
			}

			return User.inviteUsers(users)
				.then(function (res) {
					const isActive = _.get(res, 'data[0].active');
					const isGhost = _.get(res, 'data[0].ghost');
					if (!isActive || isGhost) {
						NotificationService.addNotification({
							title: 'admin.inactiveUserCreated',
							body: 'admin.userAddedToUserList',
							style: NotificationService.style.SUCCESS,
							icon: 'check'
						});
						$state.go('administration.usersAndRoles');
					} else {
						NotificationService.addNotification({
							title: 'admin.invitationSent',
							body: 'admin.invitationSentToUsers',
							style: NotificationService.style.SUCCESS,
							icon: 'check'
						});
						$state.go('administration.activeInvites');
					}
				})
				.catch(e => {
					if (e && e.data && e.data.error && e.data.error.key === 'NotEnoughLicenses') {
						Tools.NotificationService.addNotification({
							style: Tools.NotificationService.style.ERROR,
							title: 'default.error',
							body: 'user.notEnoughLicenses',
							icon: 'times'
						});
						return;
					} else if (e && e.data && e.data.error && e.data.error.key === 'NotEnoughSupportLicenses') {
						Tools.NotificationService.addNotification({
							style: Tools.NotificationService.style.ERROR,
							title: 'default.error',
							body: 'user.notEnoughSupportLicenses',
							icon: 'times'
						});
					} else if (e?.data?.error?.key === 'AdminRequiresCrm') {
						Tools.NotificationService.addNotification({
							style: Tools.NotificationService.style.ERROR,
							title: 'default.error',
							body: 'user.adminRequiresCrm',
							icon: 'times'
						});
					} else {
						return Tools.$q.reject(e);
					}
				});
		};

		var overwriteUser = function () {
			// Save as new user
			var user = Object.assign({}, EditUserCtrl.rootData.pageData.user);
			user.userParams = mapUserParams(EditUserCtrl.rootData.pageData.user.userParams);

			EditUserCtrl.rootData.saving = true;
			// Invite new user
			return invite()
				.then(function () {
					// Update old user as removed
					var oldUser = { id: user.id, clientId: user.clientId, active: 1, ghost: 1 };
					return User.update(oldUser);
				})
				.catch(function (err) {
					var body = $translate.instant('default.error');
					if (_.get(err, 'data.error.key') === 'DuplicateUser') {
						body = $translate.instant('saveError.userInviteDuplicateUser');
					}
					NotificationService.addNotification({
						style: NotificationService.style.ERROR,
						icon: 'times',
						title: 'default.error',
						body: body
					});
				})
				.then(function () {
					EditUserCtrl.rootData.saving = false;
				});
		};

		var save = function () {
			var user = Object.assign({}, EditUserCtrl.rootData.pageData.user);

			if (!user.crm && hasCustomerSupport) {
				user.administrator = false;
				user.billingAdmin = false;
				user.teamLeader = false;
				user.export = false;
				user.userParams.mailAdmin = false;
				user.userParams.signalsModifyAccess = false;
				user.userParams.multiSoliditetAccess = false;
			}

			user.userParams = mapUserParams(EditUserCtrl.rootData.pageData.user.userParams);

			EditUserCtrl.rootData.saving = true;
			return User.update(user)
				.then(async function () {
					// we need to update metadata with new role due to it not happening until user refresh
					const metadata = Tools.AppService.getMetadata();

					Tools.AppService.setMetadata({
						...metadata,
						role: user.role
					});

					const res = await User.getMap(user);
					await Tools.AppService.setUserMap(res.data);

					// Overwrite
					// Back to list, wait for appService to reload
					setTimeout(function () {
						$state.go('administration.usersAndRoles');
					}, 1000);
				})
				.catch(error => {
					// Set back billing admin and admin back to true (to make non billing admins not end up in a bad state where they cant save)
					if (error && error.data && error.data.error && error.data.error.key === 'NoBillingAdminLeft') {
						EditUserCtrl.rootData.pageData.user.administrator = 1;
						EditUserCtrl.rootData.pageData.user.billingAdmin = 1;
					}
					EditUserCtrl.rootData.saving = false;
					$safeApply($scope);
				});
		};

		const fetchFutureQuotas = async () => {
			const salesQuotaPromise = Quota.find({
				q: [
					{ a: Quota.attr.user.field, c: 'eq', v: [EditUserCtrl.rootData.pageData.user.id] },
					{ a: Quota.attr.date.field, c: 'gte', v: moment().format() },
					{ a: Quota.attr.value.field, c: 'gt', v: 0 }
				]
			});
			const genericQuotaPromise = GenericQuota.find(
				{
					q: [
						{
							a: GenericQuota.attr.user.field,
							c: 'eq',
							v: [EditUserCtrl.rootData.pageData.user.id]
						},
						{ a: GenericQuota.attr.period.field, c: 'gte', v: moment().format() },
						{ a: GenericQuota.attr.quota.field, c: 'gt', v: 0 }
					]
				},
				{
					// Everything but sales
					quotaType: [
						'contributionMargin',
						'recurring',
						'oneOff',
						'pipeline',
						'phonecall',
						'booked_appointment',
						'completed_appointment',
						'booked_first_appointment',
						'completed_first_appointment'
					]
				}
			);
			const activityQuotaPromise = ActivityQuota.find({
				q: [
					{ a: ActivityQuota.attr.user.field, c: 'eq', v: [EditUserCtrl.rootData.pageData.user.id] },
					{ a: ActivityQuota.attr.date.field, c: 'gte', v: moment().format() },
					{
						or: {
							q: [
								[{ a: ActivityQuota.attr.created.field, c: 'gt', v: 0 }],
								[{ a: ActivityQuota.attr.performed.field, c: 'gt', v: 0 }]
							]
						}
					}
				]
			});
			return Promise.all([salesQuotaPromise, genericQuotaPromise, activityQuotaPromise]);
		};

		const clearFutureQuotas = async (sales, generic, activity) => {
			if (sales.data.length) {
				const updatedSalesQuotas = sales.data.map(quota => {
					quota.value = 0;
					quota.valueInMasterCurrency = 0;
					return quota;
				});
				Quota.save(updatedSalesQuotas, { skipNotification: true });
			}
			if (generic.data.length) {
				const updatedGenericQuotas = generic.data.map(quota => {
					quota.quota = 0;
					quota.period = moment(quota.period).format('YYYY-MM-DD');
					return quota;
				});
				// genericQuotas can only be saved individually
				updatedGenericQuotas.forEach(quota => GenericQuota.save(quota, { skipNotification: true }));
			}
			if (activity.data.length) {
				const updatedActivityQuotas = activity.data.map(quota => {
					quota.created = 0;
					quota.performed = 0;
					return quota;
				});
				ActivityQuota.save(updatedActivityQuotas, { skipNotification: true });
			}
		};

		// Check if there are any future quotas for the user, and then ask if they want to remove them before finally saving the user
		const runFutureQuotaCheckAndSave = saveFn => {
			fetchFutureQuotas()
				.then(([sales, generic, activity]) => {
					if (sales.data.length || generic.data.length || activity.data.length) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('Alert', {
								title: 'admin.clearFutureTargets',
								body: 'admin.clearFutureTargetsDescription',
								headerIcon: 'question-circle',
								confirmButtonText: 'admin.clearTargets',
								cancelButtonText: 'admin.keepTargets',
								onClose: async confirmed => {
									if (confirmed === undefined) {
										return;
									}
									if (confirmed) {
										clearFutureQuotas(sales, generic, activity);
									}
									saveFn();
								}
							});
							return;
						}

						return $upModal
							.open('infoConfirm', {
								title: 'admin.clearFutureTargets',
								body: 'admin.clearFutureTargetsDescription',
								icon: 'fa-question-triangle',
								resolveFalse: 'admin.clearTargets',
								resolveFalseBtnClass: 'btn-green',
								resolveTrue: 'admin.keepTargets',
								resolveTrueBtnClass: 'btn-super-light-green'
							})
							.then(res => {
								if (res) {
									saveFn();
								} else {
									clearFutureQuotas(sales, generic, activity);
									saveFn();
								}
							});
					} else {
						saveFn();
					}
				})
				.catch(e => logError(e));
		};

		const askForEmailChange = async () => {
			openModal('AskForInput', {
				title: 'admin.createNewUser.emailChangeRequired.title',
				body: 'admin.createNewUser.emailChangeRequired.body',
				formModel: {
					email: FieldModel.email('default.email')
						.required()
						.customValidator(value => {
							return value === currentEmail
								? 'admin.createNewUser.emailChangeRequired.emailInputError.notUnique'
								: false;
						})
				},
				initialValues: {
					email: ''
				},
				onClose: fields => {
					if (fields?.email) {
						EditUserCtrl.rootData.pageData.user.email = fields.email;
						runFutureQuotaCheckAndSave(overwriteUser);
					}
				}
			});
		};

		EditUserCtrl.rootData.onSave = function () {
			EditUserCtrl.rootData.pageData.submitted = true;
			validate();
			if (EditUserCtrl.rootData.pageData.hasError) {
				var errorElement = document.querySelector('#admin-root .has-error');
				if (errorElement) {
					errorElement.scrollIntoView({ behavior: 'smooth' });
				}
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'validation.missingRequiredFieldsTitle'
				});
				return;
			}

			if (EditUserCtrl.rootData.pageData.edit) {
				const userNotActive =
					(EditUserCtrl.rootData.pageData.user.ghost && EditUserCtrl.rootData.pageData.user.active) ||
					(!EditUserCtrl.rootData.pageData.user.ghost && !EditUserCtrl.rootData.pageData.user.active);
				if (
					currentEmail !== EditUserCtrl.rootData.pageData.user.email ||
					currentName !== EditUserCtrl.rootData.pageData.user.name
				) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							title: 'admin.createNewUser',
							body: 'admin.createAsNewUserConfirm',
							headerIcon: 'question-circle',
							confirmButtonText: 'admin.keepAndCreateNewUser',
							cancelButtonText: 'default.update',
							onClose: async confirmed => {
								if (confirmed === undefined) {
									return;
								}
								if (confirmed) {
									const emailUnchanged = currentEmail === EditUserCtrl.rootData.pageData.user.email;
									if (emailUnchanged) {
										askForEmailChange();
									} else {
										runFutureQuotaCheckAndSave(overwriteUser);
									}
								} else {
									if (userNotActive) {
										runFutureQuotaCheckAndSave(save);
									} else {
										save();
									}
								}
							}
						});
						return;
					}

					return $upModal
						.open('infoConfirm', {
							title: 'admin.createNewUser',
							body: 'admin.createAsNewUserConfirm',
							icon: 'fa-question-triangle',
							resolveFalse: 'default.update',
							resolveFalseBtnClass: 'btn-green',
							resolveTrue: 'admin.keepAndCreateNewUser',
							resolveTrueBtnClass: 'btn-super-light-green'
						})
						.then(function (res) {
							if (res) {
								const emailUnchanged = currentEmail === EditUserCtrl.rootData.pageData.user.email;
								if (emailUnchanged) {
									askForEmailChange();
								} else {
									runFutureQuotaCheckAndSave(overwriteUser);
								}
							} else {
								// Continue save user
								if (userNotActive) {
									runFutureQuotaCheckAndSave(save);
								} else {
									save();
								}
							}
						});
				} else if (userNotActive) {
					runFutureQuotaCheckAndSave(save);
				} else {
					save();
				}
			} else {
				invite().catch(function (res) {
					var body = 'saveError.userInvite';
					if (
						res.data &&
						res.data.error &&
						res.data.error &&
						res.data.error.errorCode &&
						res.data.error.errorCode === 94
					) {
						body = EditUserCtrl.rootData.pageData.multi
							? 'saveError.userInviteEmailTakenMultiple'
							: 'saveError.userInviteEmailTaken';
					} else if (
						res.data &&
						res.data.error &&
						res.data.error &&
						res.data.error.errorCode &&
						res.data.error.errorCode === 97
					) {
						body = EditUserCtrl.rootData.pageData.multi
							? 'saveError.userInviteDuplicateUserMultiple'
							: 'saveError.userInviteDuplicateUser';
					}

					NotificationService.addNotification({
						title: 'default.error',
						body: body,
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				});
			}
		};

		var init = function () {
			EditUserCtrl.rootData.pageData.roles = AppService.getRoles();
			EditUserCtrl.rootData.pageData.has2fa = Tools.AppService.getMetadata().params.SmsAuthentication;
			var accountSelf = Tools.AppService.getAccountSelf();
			var self = Tools.AppService.getSelf();
			var usersCount = Tools.AppService.getRealActiveUsersCount();
			EditUserCtrl.rootData.pageData.isMe = false;
			EditUserCtrl.rootData.pageData.loggedInUserIsBillingAdmin = !!self.billingAdmin;
			EditUserCtrl.rootData.pageData.addSeatsDrawerVisible = false;

			if (accountSelf.client.numberOfLicenses <= usersCount) {
				EditUserCtrl.rootData.pageData.noLicences = true;
			}

			if ($stateParams.id) {
				User.getMasteruser($stateParams.id)
					.then(function (res) {
						EditUserCtrl.rootData.pageData.isMe = res.data.id === self.id;
						EditUserCtrl.rootData.pageData.user = res.data;
						EditUserCtrl.rootData.pageData.user.userParams = parseUserParams(res.data.userParams);
						EditUserCtrl.rootData.pageData.edit = true;
						EditUserCtrl.rootData.pageLoading = false;
						currentHash = Tools.LZString.compressToBase64(
							JSON.stringify(EditUserCtrl.rootData.pageData.user)
						);
						currentEmail = EditUserCtrl.rootData.pageData.user.email;
						currentName = EditUserCtrl.rootData.pageData.user.name;
						EditUserCtrl.rootData.pageData.isLastActiveUser =
							usersCount === 1 && res.data.active && !res.data.ghost;
						EditUserCtrl.rootData.pageData.isLastAdmin = usersCount === 1 && res.data.administrator;
						EditUserCtrl.rootData.pageData.roleWasActive = res.data.active && !res.data.ghost;
						$safeApply($scope);
					})
					.catch(e => logError(e, 'EditUser: Failed to fetch masterUser'));
			} else {
				EditUserCtrl.rootData.pageLoading = false;
				EditUserCtrl.rootData.pageData.user = newUser();
				EditUserCtrl.rootData.saveBtnText = 'admin.inviteUsers';

				if (EditUserCtrl.rootData.pageData.noLicences) {
					EditUserCtrl.rootData.pageData.user.active = 0;
					EditUserCtrl.rootData.pageData.user.ghost = 0;
					EditUserCtrl.rootData.saveBtnText = 'admin.createInactiveUser';
				}
				validate();
			}
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

'use strict';
angular.module('domain.admin').factory('EditSalesboardsMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function () {
			return AppService.loadedPromise.then(function () {
				var promises = {
					roles: $q.when({ data: AppService.getRoles() }),
					listViews: AppService.getListViews('salesboard'),
					metadata: $q.when(AppService.getMetadata())
				};

				return $q.all(promises);
			});
		};
	}
]);

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Card, Text } from '@upsales/components';
import T from '../Helpers/translate';
import './GridItem.scss';

class GridItem extends React.PureComponent {
	select = () => {
		this.props.select(this.props.item);
	};

	render() {
		const { title, description, icon } = this.props.item;
		const classes = new bemClass('DesiredOutcomeGridItem');

		return (
			<Card
				className={classes.mod({ 'expand-on-hover': true, selected: this.props.selected }).b()}
				onClick={this.select}
			>
				<div className={classes.elem('title').b()}>
					<img src={`img/${icon}`} />
					<Text center={true} className={classes.elem('text').b()}>
						{T(title)}
					</Text>
				</div>
				<div className={classes.elem('description').b()}>
					<Text center={true} color="grey-10">
						{T(description)}
					</Text>
				</div>
			</Card>
		);
	}
}

export default GridItem;

'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';

angular.module('domain.campaign').controller('CampaignActivities', [
	'$scope',
	'FilterHelper',
	'AppService',
	'$upModal',
	'RequestBuilder',
	'Activity',
	'FeatureHelper',
	'$translate',
	function ($scope, FilterHelper, AppService, $upModal, RequestBuilder, Activity, FeatureHelper, $translate) {
		var customerId;
		var CampaignCtrl = $scope.CampaignCtrl;
		var currentFilter;

		var CampaignActivitiesCtrl = this;

		CampaignCtrl.CampaignActivitiesCtrl = CampaignActivitiesCtrl;

		$scope.activitiesError = false;
		$scope.limit = 50;
		$scope.activitiesLoading = false;
		$scope.activitiesCurrent = 1;
		$scope.activitiesPages = 0;
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');

		$scope.$on('activity.added', function (e, added) {
			if (added.project?.id === CampaignCtrl.campaign.id || added.callList?.id === CampaignCtrl.campaign.id) {
				CampaignActivitiesCtrl.activities.unshift(added);
			}
		});

		$scope.$on('activity.updated', function (e, updated) {
			var index = _.findIndex(CampaignActivitiesCtrl.activities, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'activity')) {
				if (index !== -1) {
					CampaignActivitiesCtrl.activities[index] = updated;
				} else {
					CampaignActivitiesCtrl.activities.unshift(updated);
				}
			} else if (index !== -1) {
				CampaignActivitiesCtrl.activities.splice(index, 1);
			}
		});

		$scope.$on('activity.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignActivitiesCtrl.activities, { id: deleted.id });

			if (index !== -1) {
				CampaignActivitiesCtrl.activities.splice(index, 1);
			}
		});

		$scope.getActivities = function ($page) {
			$scope.activitiesError = false;
			$scope.activitiesLoading = true;

			var filter = new RequestBuilder();
			if (CampaignCtrl.campaign.isCallList) {
				filter.addFilter({ field: 'callList.id' }, filter.comparisonTypes.Equals, CampaignCtrl.campaign.id);
			} else {
				filter.addFilter(Activity.attr.campaign, filter.comparisonTypes.Equals, CampaignCtrl.campaign.id);
			}

			if (CampaignCtrl.campaign.isCallList) {
				filter.addSort('date', true);
			} else {
				filter.addSort('date', false);
			}
			filter.limit = $scope.limit;
			filter.offset = $scope.limit * ($page - 1);
			currentFilter = filter.build();

			Activity.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					$scope.activitiesLoading = false;
					CampaignActivitiesCtrl.activities = res.data;
					CampaignCtrl.activitiesTotal = res.metadata.total;

					if (CampaignCtrl.activitiesTotal !== 0) {
						$scope.activitiesPages = Math.ceil(CampaignCtrl.activitiesTotal / $scope.limit);
					}
				})
				.catch(function () {
					$scope.activitiesLoading = false;
					$scope.activitiesError = true;
				});
		};

		$scope.moveActivity = function (activity) {
			if (CampaignCtrl.campaign.isCallList) {
				activity.callList = null;
			} else {
				activity.project = null;
			}
			Activity.customer(customerId).save(activity);
		};

		$scope.setPriority = function (item) {
			const { priority } = item;

			return Activity.customer(customerId).save({
				...item,
				priority: priority ? ACTIVITY_PRIORITY.NONE : ACTIVITY_PRIORITY.HIGH
			});
		};

		$scope.getPriorityTooltip = function (activity) {
			if (activity.priority === ACTIVITY_PRIORITY.HIGH) {
				return $translate.instant('activity.removeAsImportant');
			}
			return $translate.instant('activity.markAsImportant');
		};

		$scope.isHighPriority = function (activity) {
			return activity.priority === ACTIVITY_PRIORITY.HIGH;
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			$scope.getActivities(1);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import { Boxes } from 'Components/Prospecting/enumBoxes';
import history from 'App/pages/routes/history';

// These actions will show up after the addon is purchased or a trial is started
export const NEXT_STEPS: { [alias: string]: { title: string; action: () => void }[] } = {
	PROSPECTING_PRO_SIGNALS: [
		{
			title: 'prospecting.ceoBoardMembers.promo.findLeadsInsideExistingCustomerGroups',
			action: () => {
				history.push('/prospecting?box=' + Boxes.InsideCompanies);
			}
		},
		{
			title: 'prospecting.ceoBoardMembers.promo.findLeadsFromExistingCustomersTitle',
			action: () => {
				history.push('/prospecting?box=' + Boxes.CEOOnBoard);
			}
		}
	]
};

export const NEXT_STEP_TITLES: { [alias: string]: string } = {
	PROSPECTING_PRO_SIGNALS: 'prospecting.proNextStepTitle'
};

import { getAvailableTimezones, Timezone } from 'App/helpers/DateHelpers';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { StaticSelect, type StaticSelectConsumerProps } from './StaticSelect';

export const TimezoneSelect = ({
	value,
	...props
}: Omit<StaticSelectConsumerProps<Timezone>, 'value'> & { value: string | null }) => {
	const zones = useMemo(
		() =>
			_.sortByAll(
				getAvailableTimezones().map(z => ({ ...z, title: z.description })),
				['utcOffset', 'description']
			),
		[]
	);
	const selected = useMemo(() => (value ? zones.find(z => z.id === value) ?? null : null), [value, zones]);
	return <StaticSelect value={selected} options={zones} {...props} />;
};

export default TimezoneSelect;

'use strict';
import React from 'react';

/* TODO(Add more props options) */
ReactTemplates.upFilters.components.toggle = window.Toggle = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			value: false,
			onChange: function () {}
		};
	},
	getInitialState: function () {
		var val = this.props.value;
		if (this.props.hasOwnProperty('checked')) {
			val = this.props.checked;
		}

		return {
			value: !!val
		};
	},
	onClick: function () {
		if (this.props.disabled) {
			return;
		}
		var newValue = !this.state.value;

		this.setState({
			value: newValue
		});

		this.props.onChange(newValue);
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (this.props.hasOwnProperty('checked')) {
			if (nextProps.checked !== this.props.checked) {
				this.setState({
					value: nextProps.checked
				});
			}
		} else {
			if (nextProps.value !== this.props.value) {
				this.setState({
					value: nextProps.value
				});
			}
		}
	},
	getValue: function () {
		return this.state.value;
	},
	render: function () {
		var classes = 'up-ios-toggle';
		if (this.props.className) {
			classes += ' ' + this.props.className;
		}
		return (
			<div
				data-test-id={this.props['data-test-id']}
				style={{ display: 'inline' }}
				onClick={!this.props.disabled ? this.onClick : null}
			>
				<input
					className="up-ios-toggle-hidden-checkbox"
					type="checkbox"
					readOnly
					checked={this.state.value}
					disabled={this.props.disabled}
				/>
				<div className={classes}>
					<div className="toggle-handle" />
				</div>
			</div>
		);
	}
});

/* TODO(Add more props options) */
ReactTemplates.checkbox = window.Checkbox = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			checked: false,
			disabled: false,
			onChange: function () {}
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.checked !== this.props.checked) {
			this.setState({
				value: nextProps.checked
			});
		}
	},
	getInitialState: function () {
		return {
			value: this.props.checked
		};
	},
	onClick: function (event) {
		if (!this.props.disabled) {
			var newValue = !this.state.value;

			if (!this.props.readOnly) {
				this.setState({
					value: newValue
				});
			}

			this.props.onChange(newValue, event);
		}
	},
	getValue: function () {
		return this.state.value;
	},
	render: function () {
		var classes = 'up-toggle';
		if (this.props.className) {
			classes += ' ' + this.props.className;
		}
		if (this.props.disabled) {
			classes += ' disabled';
		}
		var checkClass = 'fa toggle-check';

		if (this.props.intermediate) {
			checkClass += ' fa-minus';
		} else if (this.state.value) {
			checkClass += ' fa-check checked';
		} else if (this.props.loading) {
			checkClass += ' fa-refresh fa-spin';
		}

		return (
			<div style={{ display: 'inline-block' }} onClick={this.onClick}>
				<input
					readOnly
					type="checkbox"
					className="up-toggle-hidden-checkbox"
					checked={this.props.loading || this.state.value}
				/>
				<div tabIndex="0" className={classes}>
					<div className={checkClass} />
				</div>
			</div>
		);
	}
});

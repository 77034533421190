'use strict';

angular.module('upsalesApp').constant('LEAD_SOURCE', {
	facebook: {
		url: 'http://www.facebook.com',
		icon: 'facebook',
		favicon: 'https://www.facebook.com/favicon.ico'
	},
	google: {
		url: 'https://www.google.com',
		icon: 'google',
		favicon: 'https://www.google.com/favicon.ico',
		types: {
			paidsearch: {
				url: 'https://ads.google.com',
				icon: '',
				img: 'img/leadsources/google_ads_logo_simple.svg',
				iconStyle: {
					height: 16
				},
				favicon: 'https://www.google.com/favicon.ico',
				title: 'Google Ads'
			}
		}
	},
	linkedin: {
		url: 'http://www.linkedin.com',
		icon: 'linkedin',
		favicon: 'https://www.linkedin.com/favicon.ico'
	},
	adwords: {
		url: 'http://www.adwords.com',
		img: 'img/leadsources/adwords.png',
		favicon: 'https://www.google.com/s2/favicons?domain=https://ads.google.com/'
	},
	upsalesads: { url: null, img: 'img/leadsources/upsales.png', favicon: '' },
	upsalesmail: { url: null, img: 'img/leadsources/upsales.png', favicon: '' },
	instagram: { url: 'http://www.instagram.com', icon: 'instagram', favicon: 'https://www.instagram.com/favicon.ico' },
	twitter: { url: 'http://www.twitter.com', icon: 'twitter-square', favicon: 'https://www.twitter.com/favicon.ico' },
	xing: { url: 'http://www.xing.com', icon: 'xing-square', favicon: 'https://www.xing.com/favicon.ico' },
	bing: { url: 'https://www.bing.com', icon: '', faivcon: 'https://www.bing.com/favicon.ico' },
	yahoo: { url: 'https://www.yahoo.com', icon: '', faivcon: 'https://www.yahoo.com/favicon.ico' },
	youtube: { url: 'https://www.youtube.com', icon: '', faivcon: 'https://www.youtube.com/favicon.ico' },
	tumblr: { url: 'https://www.tumblr.com', icon: '', faivcon: 'https://www.tumblr.com/favicon.ico' },
	pinterest: { url: 'https://www.pinterest.com', icon: '', faivcon: 'https://www.pinterest.com/favicon.ico' },
	slideshare: { url: 'https://www.slideshare.net', icon: '', faivcon: 'https://www.slideshare.net/favicon.ico' },
	digg: { url: 'https://www.digg.com', icon: '', faivcon: 'https://www.digg.com/static/images/digg-favicon.png' },
	reddit: { url: 'https://www.reddit.com', icon: '', faivcon: 'https://www.reddit.com/favicon.ico' },
	sphinn: { url: 'https://www.sphinn.com', icon: '', faivcon: 'https://www.sphinn.com/favicon.ico' },
	myspace: { url: 'https://www.myspace.com', icon: '', faivcon: 'https://www.myspace.com/favicon.ico' },
	mixx: { url: 'https://www.mixxx.org/', icon: '', faivcon: 'https://www.mixxx.org/static/images/favicon.ico' },
	fark: { url: 'https://www.fark.com', icon: '', faivcon: 'https://www.fark.com/favicon.ico' },
	triiibes: { url: 'http://www.triiibes.com', icon: '', faivcon: 'http://www.triiibes.com/favicon.ico' },
	livejournal: { url: 'https://www.livejournal.com', icon: '', faivcon: 'https://www.livejournal.com/favicon.ico' },
	technorati: {
		url: 'https://technorati.com/',
		icon: '',
		faivcon: 'https://static.garnet.synacor.com/assets/en_US/gen4/shared/images/icons/favicon.ico'
	},
	slashdot: { url: 'https://slashdot.org', icon: '', faivcon: 'https://slashdot.org/favicon.ico' },
	ning: { url: 'https://www.ning.com', icon: '', faivcon: 'https://www.ning.com/favicon.ico' },
	metacafe: { url: 'https://www.metacafe.com', icon: '', faivcon: 'https://www.metacafe.com/favicon.ico' },
	wikipedia: { url: 'https://www.wikipedia.com', icon: '', faivcon: 'https://www.wikipedia.com/favicon.ico' },
	flickr: { url: 'https://www.flickr.com', icon: '', faivcon: 'https://www.flickr.com/favicon.ico' },
	plurk: { url: 'https://www.plurk.com', icon: '', faivcon: 'https://www.plurk.com/favicon.ico' },
	knol: { url: 'https://www.knol.com', icon: '', faivcon: 'https://www.knol.com/favicon.ico' },
	hootsuite: { url: 'https://www.hootsuite.com', icon: '', faivcon: 'https://www.hootsuite.com/favicon.ico' },
	quora: { url: 'https://www.quora.com', icon: '', faivcon: 'https://www.quora.com/favicon.ico' },
	vimeo: { url: 'https://www.vimeo.com', icon: '', faivcon: 'https://www.vimeo.com/favicon.ico' },
	getConfig: function (leadSource, defaults = { iconType: 'icon', iconProps: { name: 'globe' } }) {
		if (!leadSource) {
			return defaults;
		}

		const T = Tools.$translate;
		const result = {};

		if (!leadSource.source || leadSource.type === 'direct') {
			const translateKey = 'visitor.type.' + leadSource.type;
			const translateValue = T(translateKey);
			result.title = translateKey !== translateValue ? translateValue : leadSource.type;
		} else {
			const translateKey = 'leadSource.' + leadSource.source;
			const translateValue = T(translateKey);
			result.title = translateKey !== translateValue ? translateValue : leadSource.source;
		}

		if (!leadSource.source) {
			return Object.assign(result, defaults);
		}

		const sourceOptions = this[leadSource.source.toLowerCase()];

		if (!sourceOptions) {
			return Object.assign(result, defaults);
		}

		const options =
			sourceOptions.types && leadSource.type && sourceOptions.types[leadSource.type.toLowerCase()]
				? sourceOptions.types[leadSource.type.toLowerCase()]
				: sourceOptions;

		if (options.img) {
			result.iconType = 'img';
			result.iconProps = { src: options.img };
		} else if (options.icon) {
			result.iconType = 'icon';
			result.iconProps = { name: options.icon };
		} else {
			result.iconType = 'none';
			result.iconProps = {};
		}

		if (options.iconStyle) {
			result.iconProps.style = options.iconStyle;
		}
		if (options.url) {
			result.url = options.url;
		}
		if (options.title) {
			result.title = options.title;
		}
		return result;
	}
});

'use strict';

angular.module('upDirectives').directive('upAccountChartSales', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		link: function ($scope, $element, $attr) {
			let firstElement = $element[0];
			var chartConfig;
			var renderTimeout;
			var renderChart = function () {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}

				renderTimeout = setTimeout(function () {
					if (chartConfig && firstElement) {
						var graphComponent = $attr.isMarket
							? ReactTemplates.reportAccountMarket
							: ReactTemplates.reportAccountSales;
						ReactDOM.render(
							React.createElement(graphComponent, {
								// Chart data and stuff
								config: chartConfig,

								// Injects
								tools: {}
							}),
							firstElement,
							function () {}
						);
					}
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			$scope.$watch(
				$attr.chart,
				function (config) {
					chartConfig = config;
					renderChart();
				},
				true
			);
		}
	};
});

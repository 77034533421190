'use strict';

angular.module('upResources').factory('InviteCode', [
	'$resource',
	'$q',
	'URL',
	'API',
	function InviteCode($resource, $q, URL, API) {
		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'userinvite/';
		var Resource = $resource(
			baseUrl + ':customer/:code',
			{},
			{
				get: { method: 'GET', isArray: false },
				save: { method: 'POST', isArray: false }
			}
		);

		var Model = {
			get: function (customer, code) {
				return Resource.get({ customer: customer, code: code }).$promise;
			},
			save: function (data) {
				return Resource.save(data).$promise;
			}
		};

		return Model;
	}
]);

'use strict';
import { analyticsProps } from 'Resources/Activity';
import { TYPE } from 'Store/reducers/SystemNotificationReducer';

angular.module('upResources').factory('Activity', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ActivityAttributes',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	'AppService',
	function Activity(
		$resource,
		$q,
		URL,
		API,
		ActivityAttributes,
		ResourceHelper,
		ParseGeneric,
		MapGeneric,
		AppService
	) {
		var AA = ActivityAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'activity',
			createSuccessBody: 'saved.activity',
			updateSuccessBody: 'updated.activity',
			deleteSucccessBody: 'deleted.activity',
			createErrorBody: 'saveError.activity',
			updateErrorBody: 'saveError.activity',
			deleteErrorBody: 'deleteError.activity',
			attr: Attribute,
			onclickLink: savedObject => {
				Tools.$upModal.open('editActivity', { id: savedObject.id });
			},
			notificationType: TYPE.GENERIC_ENTITY_ADDED,
			extraSeconds: 1000,
			analyticsProps
		});

		var dateFields = ['date', 'indexDate', 'modDate', 'regDate'];

		var Model = {
			new: function () {
				var meta = AppService.getMetadata();
				var self = AppService.getSelf();
				var activityTypes = AppService.getActivityTypes('activity', true);

				var activity = {
					client: null,
					users: [self],
					project: null,
					date: new Date(),
					endDate: new Date(),
					description: '',
					notes: '',
					custom: [],
					isAppointment: false,
					activityType: _.find(activityTypes, { id: meta.params.DefaultActivityTypeId }) || activityTypes[0],
					phoneCalls: []
				};

				var parsed = ParseGeneric(null, {
					isArray: false,
					isNew: true,
					custom: 'activity'
				})(
					{
						data: activity
					},
					null
				);

				return parsed;
			},
			customer: function (customer) {
				var instance = {};
				var Activity = null;
				var baseUrl = URL + API + customer + '/activities/';
				const query = Tools.FeatureHelper.hasSoftDeployAccess('FASTER_ACTIVITIES') ? '?speedy=true' : '';
				Activity = $resource(
					baseUrl + ':id',
					{},
					{
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'activity',
								dateFields: dateFields
							})
						},
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: true,
								custom: 'activity',
								dateFields: dateFields
							})
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'activity',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric('MapActivity', {
								payloadName: 'activity'
							}),
							url: baseUrl + query
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric(null, {
								isArray: false,
								custom: 'activity',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric('MapActivity', {
								payloadName: 'activity'
							}),
							url: baseUrl + ':id' + query
						},
						multi: { method: 'POST', isArray: false, url: baseUrl + 'multi/:type' }
					}
				);

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					delete params.mapCustom;

					return Activity.find(params).$promise;
				};

				instance.get = function (id) {
					if (!id) {
						return $q.reject('no activity id');
					}

					return Activity.get({ id: id }).$promise;
				};

				instance.save = function (activity, options) {
					var defered = $q.defer();

					if (!activity) {
						return defered.reject('No activity');
					}

					if (activity.id) {
						Activity.update(
							{ id: activity.id },
							{ activity: activity },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Activity.create(
							{ activity: activity },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !activity.id);
						})
						.catch(function (err) {
							// Notify user
							helper.onSaveError(options, !activity.id, err);
						});

					return defered.promise;
				};

				instance['delete'] = function (activity, options) {
					return Activity.delete({ id: activity.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, activity);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

import async from 'async';
import ProvisioningResource from 'App/babel/resources/Provisioning';
import Integration from 'Components/Admin/Integration';
import IntegrationHelper from 'App/helpers/IntegrationHelper';
import FullTermsModal from 'App/babel/components/Modals/FullTermsModal';
import FullscreenModal from 'App/babel/components/Dialogs/FullscreenModal';
import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('ConfigureIntegration', [
	'AppService',
	'$scope',
	'$stateParams',
	'StandardIntegration',
	'Contract',
	'$safeApply',
	'$q',
	'$state',
	'NotificationService',
	'CacheRefresher',
	'$upModal',
	function (
		AppService,
		$scope,
		$stateParams,
		StandardIntegration,
		Contract,
		$safeApply,
		$q,
		$state,
		NotificationService,
		CacheRefresher,
		$upModal
	) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = Integration;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.saveVisible = false;
		AdminSubCtrl.loaded = false;

		var customerId;
		var metadata;
		var prevent = true;

		var configMethod = StandardIntegration.setting;

		var getResumeState = function (stateObj) {
			return function (mergeParams) {
				var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

				$state.go(stateObj.state.name, stateParams, stateObj.options);
			};
		};

		AdminSubCtrl.getLog = function () {
			var sort = { a: 'startDate', s: 'Z' };
			StandardIntegration.log(customerId)
				.find({ integrationId: AdminSubCtrl.rootData.pageData.masterIntegration.id, sort: sort, limit: 100 })
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.log = res.data;
				})
				.catch(function (err) {
					AdminSubCtrl.rootData.pageData.logErr = err;
				});
		};

		AdminSubCtrl.getStatus = function () {
			AdminSubCtrl.rootData.pageData.showSupport = true;
			AdminSubCtrl.rootData.pageData.status = null;
			var data = {
				type: 'status',
				data: null,
				typeId: 0,
				integrationId: AdminSubCtrl.rootData.pageData.masterIntegration.id
			};
			StandardIntegration.data(customerId)
				.run(data)
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.status = {};
					if (res.data.body && res.data.body.message) {
						AdminSubCtrl.rootData.pageData.status.message = res.data.body.message;
					}

					if (res.data.statusCode === 200) {
						AdminSubCtrl.rootData.pageData.status.status = 1;
					}
					if (res.data.statusCode === 404) {
						AdminSubCtrl.rootData.pageData.status.status = 0;
					} else if (res.data.statusCode >= 300) {
						AdminSubCtrl.rootData.pageData.status.status = -1;
					}
				})
				.catch(function () {
					AdminSubCtrl.rootData.pageData.status = {
						status: -2
					};
				});
		};

		var onAcceptTerms = function () {
			AdminSubCtrl.acceptingTerms = true;
			var obj = { contractId: AdminSubCtrl.rootData.pageData.masterIntegration.contract.id };

			if (AdminSubCtrl.rootData.pageData.configureForUser) {
				obj.contractId = AdminSubCtrl.rootData.pageData.masterIntegration.userContract.id;
			}
			return Contract.accepted(customerId)
				.save(obj, { skipNotification: true })
				.catch(function (err) {
					AdminSubCtrl.termsErr = err;
				});
		};

		function getBaseOauthEndpoint(endpoint) {
			return endpoint.indexOf('?') > -1 ? `${endpoint}&` : `${endpoint}/?`;
		}

		var onRequestOauth = function () {
			var integration = AdminSubCtrl.rootData.pageData.masterIntegration;
			var oauthField = _.find(AdminSubCtrl.rootData.pageData.fields, { type: 'oauth' });
			window.location =
				getBaseOauthEndpoint(oauthField.endpoint) +
				'state=' +
				integration.$oauthState +
				'&callbackPath=' +
				integration.$oauthCallbackPath;
		};

		var onRequestOauth2 = function (oauthField) {
			var integration = AdminSubCtrl.rootData.pageData.masterIntegration;

			window.location =
				getBaseOauthEndpoint(oauthField.endpoint) +
				'state=' +
				integration.$oauthState +
				'-' +
				integration.id +
				'-' +
				oauthField.name +
				'&client_id=' +
				oauthField.clientId +
				'&response_type=code&redirect_uri=' +
				oauthField.callbackUri;
		};

		var onGetOauthToken = function (field) {
			AdminSubCtrl.rootData.pageData.gettingOauth = true;

			// fake it
			// var def = $q.defer();
			// setTimeout(function() {
			// 	AdminSubCtrl.rootData.pageData.gettingOauth = false;
			// 	AdminSubCtrl.rootData.pageData.config[field.name] = 'oauthstringwithsolonglength';
			// 	def.resolve();
			// 	checkFields();
			// 	$safeApply($scope);
			// }, 1000);-
			// return def.promise;

			var opts = {
				callbackPath: AdminSubCtrl.rootData.pageData.masterIntegration.$oauthCallbackPath.split('?')[0],
				integrationId: AdminSubCtrl.rootData.pageData.masterIntegration.id,
				code: AdminSubCtrl.rootData.pageData.oauth.code,
				state: AdminSubCtrl.rootData.pageData.oauth.state
			};

			return StandardIntegration.data(customerId)
				.oauth(opts)
				.then(function (values) {
					AdminSubCtrl.rootData.pageData.gettingOauth = false;
					if (values.data && values.data.error) {
						AdminSubCtrl.rootData.pageData.oauthErr = values.data.error;
						AdminSubCtrl.rootData.pageData.oauth = null;
					} else {
						AdminSubCtrl.rootData.pageData.config[field.name] = values.data;
					}
					var params = $stateParams;
					params.code = undefined;
					$state.go('.', params, { notify: false, reload: true });

					checkFields();
				})
				.catch(function (err) {
					AdminSubCtrl.rootData.pageData.gettingOauth = false;
					AdminSubCtrl.rootData.pageData.gettingOauthErr = err;
				});
		};

		var onChange = function (key, value) {
			if (key !== 'specialKey') {
				AdminSubCtrl.rootData.pageData[key] = value;
			}
		};

		var testConfig = function (config) {
			if (!customerId) {
				customerId = AppService.getCustomerId();
			}
			var settings = AdminSubCtrl.rootData.pageData.configureForUser
				? StandardIntegration.userSetting
				: StandardIntegration.setting;
			if (AdminSubCtrl.rootData.pageData.testingEnabled && AdminSubCtrl.rootData.pageData.fields.length) {
				return settings(customerId)
					.testConfig(config)
					.catch(function (error) {
						return $q.reject(error);
					});
			} else {
				return $q.when();
			}
		};

		var getExternalConfig = function () {
			var settings = AdminSubCtrl.rootData.pageData.configureForUser
				? StandardIntegration.userSetting
				: StandardIntegration.setting;
			return settings(AppService.getCustomerId()).getExternalConfig($stateParams.id);
		};

		var showInvalidNotification = function () {
			// Show notification
			NotificationService.addNotification({
				style: NotificationService.style.WARN,
				icon: 'warning',
				title: 'default.error',
				body: 'default.youHaveFormErrors'
			});
		};

		var onSave = function (noRedirect, skipTestConfig) {
			var invalid = false;
			// Validate
			_.each(AdminSubCtrl.rootData.pageData.fields, function (field) {
				if (
					(field.externalValidation && field.visible && (!field.$tested || !field.$tested.valid)) ||
					(field.required && field.visible && !AdminSubCtrl.rootData.pageData.config[field.name])
				) {
					invalid = true;
				}
			});

			if (invalid && AdminSubCtrl.rootData.pageData.active) {
				return showInvalidNotification();
			}

			AdminSubCtrl.rootData.saving = true;
			var config = {
				id: AdminSubCtrl.rootData.pageData.masterIntegration.id,
				integrationId: AdminSubCtrl.rootData.pageData.masterIntegration.id,
				configJson: JSON.stringify(AdminSubCtrl.rootData.pageData.config),
				active: AdminSubCtrl.rootData.pageData.active
			};

			var options = {
				masterIntegration: AdminSubCtrl.rootData.pageData.masterIntegration
			};

			var testPromise = skipTestConfig ? $q.when() : testConfig(config);

			return testPromise
				.then(function (res) {
					if (res?.overwriteConfigJson) {
						config.configJson = res.configJson;
					}
					return configMethod(customerId).save(config, options);
				})
				.then(function (res) {
					AdminSubCtrl.rootData.saving = false;
					AdminSubCtrl.rootData.pageData.isNew = false;
					AdminSubCtrl.rootData.pageData.active = res.data.active;
					AdminSubCtrl.rootData.saveVisible = false;
					prevent = false;

					// Update metadata
					CacheRefresher.refresh([CacheRefresher.types.METADATA]);
					if (!noRedirect) {
						$state.go('administration.integrations', { customerId: customerId });
					}
				})
				.catch(function () {
					AdminSubCtrl.rootData.saving = false;
				});
		};

		$scope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams, options) {
			if (AdminSubCtrl.loaded && prevent && AdminSubCtrl.rootData.saveVisible) {
				e.preventDefault();
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							prevent = false;
							if (confirmed) {
								await onSave(undefined);
							}
							getResumeState({ state: toState, stateParams: toStateParams, options: options })();
						}
					});
					return;
				}

				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('warningConfirm', {
						title: 'default.saveChanges',
						body: 'confirm.changesWillBeLost',
						resolveTrue: 'default.dontSave',
						no: 'default.abort',
						icon: 'fa-exclamation-triangle'
					})
					.then(function () {
						var stateObj = { state: toState, stateParams: toStateParams, options: options };
						prevent = false;

						getResumeState(stateObj)();
					});
			}
		});

		var checkField = function (field) {
			var fieldData = IntegrationHelper.checkField(
				field,
				AdminSubCtrl.rootData.pageData.fields,
				AdminSubCtrl.rootData.pageData.config
			);
			field = Object.assign(field, fieldData);
			if (field.externalValues && field.visible) {
				getValues(field);
			}
		};

		function checkFields() {
			_.each(AdminSubCtrl.rootData.pageData.fields, checkField);
			$safeApply($scope);
		}

		function getValues(field) {
			field.$gettingValues = true;
			return IntegrationHelper.getFieldValues(
				field,
				AdminSubCtrl.rootData.pageData.fields,
				AdminSubCtrl.rootData.pageData.masterIntegration.id,
				AdminSubCtrl.rootData.pageData.config
			).then(function (data) {
				field = Object.assign(field, data);
				field.$gettingValues = false;
				$safeApply($scope);
				$scope.$broadcast('forceRender');
			});
		}

		var activate = function () {
			AdminSubCtrl.rootData.pageData.activating = true;
			var config = {
				id: AdminSubCtrl.rootData.pageData.masterIntegration.id,
				integrationId: AdminSubCtrl.rootData.pageData.masterIntegration.id,
				configJson: JSON.stringify(AdminSubCtrl.rootData.pageData.config),
				active: true
			};

			var options = {
				masterIntegration: AdminSubCtrl.rootData.pageData.masterIntegration
			};

			var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
			if (!productAlias || AdminSubCtrl.rootData.pageData.notProvisioned) {
				AdminSubCtrl.rootData.pageData.tab = 'settings';
				return configMethod(customerId)
					.save(config, options)
					.then(() => {
						AdminSubCtrl.rootData.pageData.active = true;
						AdminSubCtrl.rootData.pageData.activating = false;
					})
					.catch(() => {
						AdminSubCtrl.rootData.pageData.activating = false;
					});
			}

			var failFunc = function () {
				NotificationService.addNotification({
					style: NotificationService.style.WARN,
					icon: 'warning',
					title: 'default.purchaseFailed',
					body: 'default.contactUpsales'
				});
			};

			return ProvisioningResource.purchase(productAlias)
				.then(function (res) {
					if (res.error) {
						failFunc();
					} else {
						AdminSubCtrl.rootData.pageData.active = true;
						AdminSubCtrl.rootData.pageData.tab = 'settings';
						AdminSubCtrl.rootData.pageData.provisioning = {}; //Mock provisioning, can't be bothered to wait for it.

						configMethod(customerId).save(config, options);
					}
				})
				.catch(failFunc)
				.finally(function () {
					AdminSubCtrl.rootData.pageData.activating = false;
					$scope.$broadcast('forceRender');
				});
		};

		var onActivate = async () => {
			AdminSubCtrl.rootData.pageData.activating = true;
			try {
				await showTerms(true);
			} catch (e) {
				if (!e) {
					// e is null if user aborts
					return;
				}
				throw e;
			}
			await onAcceptTerms();
			await activate();
			CacheRefresher.refresh([CacheRefresher.types.METADATA]);
			AdminSubCtrl.rootData.pageData.activating = false;
		};

		var onCancel = function () {
			AdminSubCtrl.rootData.pageData.cancelling = true;
			$scope.$broadcast('forceRender');
			var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
			if (productAlias) {
				ProvisioningResource.cancel(productAlias)
					.then(function () {
						AdminSubCtrl.rootData.pageData.provisioning.cancelled = true;
						AdminSubCtrl.rootData.pageData.cancelling = false;
						$scope.$broadcast('forceRender');
					})
					.catch(e => console.log('cancel error', e));
			}
		};

		var onPurchase = function () {
			AdminSubCtrl.rootData.pageData.purchasing = true;
			$scope.$broadcast('forceRender');
			var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
			if (productAlias) {
				showTerms(true)
					.then(function () {
						ProvisioningResource.purchase(productAlias)
							.then(function () {
								if (!AdminSubCtrl.rootData.pageData.provisioning) {
									AdminSubCtrl.rootData.pageData.provisioning = {};
								}

								AdminSubCtrl.rootData.pageData.purchasing = false;
								AdminSubCtrl.rootData.pageData.provisioning.endDate = undefined;
								AdminSubCtrl.rootData.pageData.provisioning.cancelled = false;

								CacheRefresher.refresh([CacheRefresher.types.METADATA]);
								$scope.$broadcast('forceRender');
							})
							.catch(e => console.log('purchase error', e));
					})
					.catch(e => console.log('show terms error', e));
			}
		};

		var onDeactivate = function () {
			AdminSubCtrl.rootData.pageData.active = false;
			onSave(undefined, true)
				.then(function () {
					var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
					if (productAlias) {
						ProvisioningResource.cancel(productAlias);
					}
				})
				.catch(e => console.log('save error', e));
		};

		var onAbort = function () {
			$state.go('administration.integrations', { customerId: customerId });
		};

		var onShowLog = function () {
			AdminSubCtrl.rootData.pageData.showLog = !AdminSubCtrl.rootData.pageData.showLog;

			$safeApply($scope);
		};

		var validateField = function (field) {
			field.$testing = true;
			return IntegrationHelper.validateField(
				field.name,
				AdminSubCtrl.rootData.pageData.config,
				AdminSubCtrl.rootData.pageData.masterIntegration.id
			).then(function (fieldData) {
				field.$tested = fieldData;
				field.$testing = false;
			});
		};

		var onFieldChange = function (fieldName, value, checkDependencies) {
			AdminSubCtrl.rootData.pageData.config[fieldName] = value;

			if (checkDependencies) {
				var field = _.find(AdminSubCtrl.rootData.pageData.fields, { name: fieldName });
				if (field.externalValidation) {
					validateField(field)
						.then(checkFields)
						.then(function () {
							$scope.$broadcast('forceRender');
						})
						.catch(e => console.log('validate field error', e));
				} else {
					checkFields();
				}
			}

			AdminSubCtrl.rootData.saveVisible = true;

			$safeApply($scope);
		};

		AdminSubCtrl.rootData.onSave = function () {
			if (!AdminSubCtrl.rootData.pageData.active) {
				onActivate();
			} else {
				onSave();
			}
		};

		AdminSubCtrl.rootData.onButtonClick = function (fieldName) {
			var field = _.find(AdminSubCtrl.rootData.pageData.fields, { name: fieldName });
			field.$testing = true;

			$scope.$broadcast('forceRender');

			IntegrationHelper.configButtonClick(
				!!AdminSubCtrl.rootData.pageData.configureForUser,
				fieldName,
				AdminSubCtrl.rootData.pageData.config,
				AdminSubCtrl.rootData.pageData.masterIntegration.id
			)
				.then(function (data) {
					field.$tested = data;
					field.$testing = false;
					$scope.$broadcast('forceRender');
				})
				.catch(e => console.log('configure error', e));
		};

		function showTerms(accept) {
			const hasPermissions =
				AdminSubCtrl.rootData.pageData.configureForUser || AdminSubCtrl.rootData.pageData.isAdmin;

			var terms;
			if (AdminSubCtrl.rootData.pageData.configureForUser) {
				terms = AdminSubCtrl.rootData.pageData.masterIntegration.userContract;
			} else {
				terms = AdminSubCtrl.rootData.pageData.masterIntegration.contract;
			}

			const configureForUser = AdminSubCtrl.rootData.pageData.configureForUser;
			const userConfigurable = AdminSubCtrl.rootData.pageData.masterIntegration.userConfigurable;
			// If user has accepted terms we need to show the accepted terms, not the latest
			if (!accept && hasPermissions) {
				if (
					// If admin of app has accepted terms and is in client app
					!configureForUser &&
					userConfigurable
				) {
					terms.body = AdminSubCtrl.rootData.pageData.masterIntegration.termsAccepted ?? terms.body;
				} else {
					terms.body = AdminSubCtrl.rootData.pageData.masterIntegration.termsAcceptedUser ?? terms.body;
				}
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				return new Promise((resolve, reject) => {
					return openModal('FullTerms', {
						terms: terms.body,
						version: terms.version,
						accept: accept,
						price: AdminSubCtrl.rootData.pageData.price,
						onClose: confirmed => {
							if (confirmed) {
								resolve();
							} else {
								reject();
							}
						}
					});
				});
			}

			return Tools.$upModal.open('alert', {
				fullscreen: true,
				dialog: FullscreenModal,
				children: React.createElement(FullTermsModal, {
					terms: terms.body,
					version: terms.version,
					accept: accept,
					price: AdminSubCtrl.rootData.pageData.price
				})
			});
		}

		var setTab = function (tab) {
			if (tab === 'log') {
				AdminSubCtrl.getStatus();
			}
			AdminSubCtrl.rootData.pageData.tab = tab;

			$safeApply($scope);
		};

		var setPage = function (state, params) {
			$state.go('administration.' + state, params);
		};

		AdminSubCtrl.rootData.pageData = {
			tab: 'description',
			error: false,
			masterIntegration: null,
			isNew: true,
			active: false,
			configure: false,
			configureForUser: $stateParams.configure === 'user',
			config: {},
			log: [],
			status: {},
			logErr: null,
			onChange: onChange,
			onDeactivate: onDeactivate,
			onActivate: onActivate,
			onCancel: onCancel,
			onPurchase: onPurchase,
			onAcceptTerms: onAcceptTerms,
			onFieldChange: onFieldChange,
			onGetOauthToken: onGetOauthToken,
			onRequestOauth: onRequestOauth,
			onRequestOauth2: onRequestOauth2,
			onAbort: onAbort,
			missingFeatures: [],
			fields: [],
			gettingOauth: false,
			oauthErr: null,
			oauth: null,
			gettingOauthErr: null,
			showSupport: false,
			testingEnabled: false,
			showTerms: showTerms,
			setTab: setTab,
			setPage: setPage,
			onShowLog: onShowLog,
			showLog: false,
			isMainApp: false,
			activate
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			metadata = AppService.getMetadata();
			AdminSubCtrl.metadata = metadata;
			AdminSubCtrl.features = AppService.getAccountSelf().features;
			AdminSubCtrl.rootData.pageData.isAdmin = metadata.user.administrator;

			var qs = $stateParams;

			AdminSubCtrl.rootData.pageData.isMainApp = !!_.find(metadata.mainApps, { id: parseInt($stateParams.id) });

			AdminSubCtrl.loaded = true;

			if (qs && qs.code) {
				// oath, show config
				AdminSubCtrl.rootData.saveVisible = true;
				AdminSubCtrl.rootData.pageData.oauth = {
					code: qs.code,
					state: qs.state,
					field: qs.oAuthFieldName
				};
			}

			if (AdminSubCtrl.rootData.pageData.configureForUser) {
				configMethod = StandardIntegration.userSetting;
			}

			async.waterfall(
				[
					function masterConfig(cb) {
						StandardIntegration.get($stateParams.id)
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.masterIntegration = res.data;
								AdminSubCtrl.rootData.pageData.masterIntegration.$oauthCallbackPath = encodeURI(
									window.location.hash.replace('#', '')
								);
								AdminSubCtrl.rootData.pageData.masterIntegration.$oauthState = Math.floor(
									Math.random() * 1000000000
								);
								AdminSubCtrl.rootData.pageData.masterIntegration.configParsed = JSON.parse(
									AdminSubCtrl.rootData.pageData.masterIntegration.configJson
								);

								if (!AdminSubCtrl.rootData.pageData.masterIntegration.color) {
									AdminSubCtrl.rootData.pageData.masterIntegration.color = '#ffffff';
								}
								cb(null, AdminSubCtrl.rootData.pageData.masterIntegration);
							})
							.catch(function (err) {
								cb(err);
							});
					},
					function externalConfig(data, cb) {
						if (!data.externalConfig) {
							return cb();
						}

						// Get external config
						return getExternalConfig()
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.masterIntegration.configParsed = res;
								cb(null);
							})
							.catch(cb);
					},
					function parseConfig(cb) {
						if (AdminSubCtrl.rootData.pageData.configureForUser) {
							AdminSubCtrl.rootData.pageData.fields =
								AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.fields.user;
						} else {
							AdminSubCtrl.rootData.pageData.fields =
								AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.fields.account;
						}
						AdminSubCtrl.rootData.pageData.testingEnabled = AdminSubCtrl.rootData.pageData.configureForUser
							? AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.userTest === true
							: AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.test === true;
						cb();
					},
					function config(cb) {
						if (
							!AdminSubCtrl.rootData.pageData.configureForUser &&
							!AdminSubCtrl.rootData.pageData.isAdmin
						) {
							return cb();
						}
						configMethod(customerId)
							.find({ integration_id: $stateParams.id })
							.then(function (res) {
								var config;
								if (!res.data.length) {
									config = {};
								} else {
									config = res.data[0];
								}
								if (!config.configJson) {
									return cb();
								}
								AdminSubCtrl.rootData.pageData.config = JSON.parse(config.configJson);
								AdminSubCtrl.rootData.pageData.active = config.active;
								AdminSubCtrl.rootData.pageData.isNew = false;

								if ($stateParams.setOauthToNull) {
									AdminSubCtrl.rootData.pageData.config.oauth = null;
								}

								cb();
							})
							.catch(function (err) {
								cb(err);
							});
					},
					function getFields(cb) {
						if (
							!AdminSubCtrl.rootData.pageData.configureForUser &&
							!AdminSubCtrl.rootData.pageData.isAdmin
						) {
							// don't have access to the settings
							return cb();
						}
						async.each(
							AdminSubCtrl.rootData.pageData.fields,
							function (field, innerCb) {
								async.auto(
									[
										function (aCb) {
											AdminSubCtrl.rootData.pageData.config[field.name] =
												IntegrationHelper.getFieldInitValue(
													field,
													AdminSubCtrl.rootData.pageData.config[field.name]
												);
											if (field.externalValidation) {
												validateField(field)
													.then(function () {
														aCb();
													})
													.catch(aCb);
											} else {
												aCb();
											}
										},
										function (aCb) {
											getValues(field)
												.then(function () {
													aCb(null);
												})
												.catch(aCb);
										}
									],
									innerCb
								);
							},
							cb
						);
					},
					function checkFeatures(cb) {
						AdminSubCtrl.rootData.pageData.missingFeatures = [];
						if (
							!AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.requirements ||
							!AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.requirements.length
						) {
							return cb();
						}
						AdminSubCtrl.rootData.pageData.masterIntegration.configParsed.requirements.forEach(function (
							requirement
						) {
							if (!AdminSubCtrl.features[requirement]) {
								AdminSubCtrl.rootData.pageData.missingFeatures.push(requirement);
							}
						});
						return cb();
					},
					function getOauth(cb) {
						if (AdminSubCtrl.rootData.pageData.oauth) {
							var oauthField = _.find(AdminSubCtrl.rootData.pageData.fields, { type: 'oauth' });
							if (oauthField) {
								return onGetOauthToken(oauthField)
									.then(function () {
										cb();
									})
									.catch(cb);
							}

							const oauth2Fields = AdminSubCtrl.rootData.pageData.fields.filter(
								field => field.type === 'oauth2'
							);
							const oauth2Field =
								oauth2Fields.find(field => field.name === AdminSubCtrl.rootData.pageData.oauth.field) ??
								oauth2Fields[0];

							if (oauth2Field) {
								return onGetOauthToken(oauth2Field)
									.then(function () {
										cb();
									})
									.catch(cb);
							}
						} else {
							checkFields();
							cb();
						}
					},
					function getPrice(cb) {
						var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
						if (!productAlias) {
							return cb();
						}

						ProvisioningResource.getProduct(productAlias)
							.then(function (res) {
								AdminSubCtrl.rootData.pageData.price = res.data.price;
								cb();
							})
							.catch(function (err) {
								if (err.status === 404) {
									AdminSubCtrl.rootData.pageData.notProvisioned = true;
									return cb();
								}
								AdminSubCtrl.rootData.pageData.notAvailable = true;
								cb();
							});
					},
					function getProvisioning(cb) {
						var productAlias = AdminSubCtrl.rootData.pageData.masterIntegration.alias;
						if (!productAlias) {
							return cb();
						}

						ProvisioningResource.hasApp(productAlias)
							.then(function (res) {
								if (res && res.data && res.data.date) {
									AdminSubCtrl.rootData.pageData.provisioning = res.data;
								}
								cb();
							})
							.catch(e => console.log('app check error', e));
					}
				],
				function (err) {
					AdminSubCtrl.rootData.pageData.error = err;
					AdminSubCtrl.rootData.pageLoading = false;
					AdminSubCtrl.rootData.pageData.configure = true;
					if (err) {
						$safeApply($scope);
						return;
					}
					if (AdminSubCtrl.rootData.pageData.isAdmin) {
						AdminSubCtrl.getLog();
					}

					var tab;

					if ($stateParams.tab === 'support') {
						tab = 'log';
						if (
							AdminSubCtrl.rootData.pageData.masterIntegration.userConfigurable &&
							!AdminSubCtrl.rootData.pageData.configureForUser
						) {
							// navigate to user status
							setPage('integration', {
								tab: 'support',
								id: AdminSubCtrl.rootData.pageData.masterIntegration.id.toString(),
								configure: 'user'
							});
						} else {
							AdminSubCtrl.getStatus();
						}
					} else if (AdminSubCtrl.rootData.pageData.active) {
						tab = 'settings';
						if (
							!AdminSubCtrl.rootData.pageData.masterIntegration.userConfigurable ||
							AdminSubCtrl.rootData.pageData.configureForUser
						) {
							AdminSubCtrl.getStatus(); // only get status if active app
						}
					} else {
						tab = 'description';
					}
					$stateParams.tab = undefined;
					AdminSubCtrl.rootData.pageData.tab = tab;

					$safeApply($scope);
				}
			);
		};
		AppService.loadedPromise.then(init).catch(e => console.log('configureIntegration load error', e));
	}
]);

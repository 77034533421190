'use strict';

angular.module('services.browser', []).provider('browserService', function () {
	function isTouchDevice() {
		var el = document.createElement('div');
		el.setAttribute('ongesturestart', 'return;'); // or try "ontouchstart"
		var isTouch = typeof el.ongesturestart === 'function';
		el.remove();
		return isTouch;
	}

	var userAgent = window.navigator.userAgent;
	var browser = {
		isTouchDevice: isTouchDevice()
	};

	// Browsers
	browser.ISFF = userAgent.indexOf('Firefox') !== -1;
	browser.ISOPERA = userAgent.indexOf('Opera') !== -1;
	browser.ISCHROME = userAgent.indexOf('Chrome') !== -1;
	browser.ISSAFARI = userAgent.indexOf('Safari') !== -1 && !browser.ISCHROME;
	browser.ISWEBKIT = userAgent.indexOf('WebKit') !== -1;
	browser.ISIE = userAgent.indexOf('Trident') > 0 || userAgent.indexOf('MSIE') > 0;

	// IE versions
	browser.ISIE6 = userAgent.indexOf('MSIE 6') > 0;
	browser.ISIE7 = userAgent.indexOf('MSIE 7') > 0;
	browser.ISIE8 = userAgent.indexOf('MSIE 8') > 0;
	browser.ISIE9 = userAgent.indexOf('MSIE 9') > 0;
	browser.ISIE10 = userAgent.indexOf('MSIE 10') > 0;

	// Recent IE
	browser.ISIE11UP = userAgent.indexOf('MSIE') === -1 && userAgent.indexOf('Trident') > 0;
	browser.ISIE10UP = browser.ISIE10 || browser.ISIE11UP;
	browser.ISIE9UP = browser.ISIE9 || browser.ISIE10UP;

	// Unsupported browsers
	browser.ISUNSUPPORTED = browser.ISIE6 || browser.ISIE7 || browser.ISIE8;

	if (userAgent.indexOf('Win') !== -1) {
		browser.os = 'windows';
	}
	if (userAgent.indexOf('Mac') !== -1) {
		browser.os = 'osx';
	}
	if (userAgent.indexOf('X11') !== -1) {
		browser.os = 'unix';
	}
	if (userAgent.indexOf('Linux') !== -1) {
		browser.os = 'linux';
	}

	var div = document.createElement('div');
	var innerDiv = document.createElement('div');
	div.appendChild(innerDiv);
	div.style.overflowY = 'scroll';
	div.style.width = '100px';
	div.style.height = '10px';
	innerDiv.style.height = '10px';
	document.body.appendChild(div);

	var scrollbarWidth = div.offsetWidth - innerDiv.clientWidth;

	div.remove();

	// The service/factory
	this.$get = function () {
		var Instance = {};
		// Fyll på med getters ni behöver

		Instance.isIE = function () {
			return browser.ISIE;
		};

		Instance.isIE9 = function () {
			return browser.ISIE9;
		};

		Instance.isFirefox = function () {
			return browser.ISFF;
		};

		Instance.isUnsupported = function () {
			return browser.ISUNSUPPORTED;
		};

		Instance.locationOrigin = function () {
			if (browser.ISIE) {
				return (
					window.location.protocol +
					'//' +
					window.location.hostname +
					(window.location.port ? ':' + window.location.port : '')
				);
			}
			return window.location.origin;
		};

		Instance.isOptionCombinedKey = function (e, key, shift) {
			var keyMatch = key === e.keyCode;
			if (!keyMatch || (shift && !e.shiftKey)) {
				return false;
			}

			// CMD key on mac
			if (browser.os === 'osx' && e.metaKey) {
				e.preventDefault();
				return true;
			}

			// CTRL on all besides mac
			if (browser.os !== 'osx' && e.ctrlKey) {
				e.preventDefault();
				return true;
			}

			return false;
		};

		Instance.isOSX = function () {
			return browser.os === 'osx';
		};

		Instance.isTouchDevice = browser.isTouchDevice;
		Instance.scrollbarWidth = scrollbarWidth;

		return Instance;
	};
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import './EventActivity.scss';
import { Input, Tooltip } from '@upsales/components';

export default class EventTitles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: this.props.eventDetails.contact ? this.props.eventDetails.contact.title : null,
			titleCategories: null,
			selectedCategory: null,
			titleEror: null,
			titleCategoryEror: false
		};
		var t = Tools.$translate;
		this.lang = {
			required: t('default.required')
		};
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		if (this.props !== nextProps && nextProps.eventDetails.contact) {
			this.setState({ title: nextProps.eventDetails.contact.title });
		}
	};
	setTitleCategories = titleCategories => {
		if (titleCategories.target.added.name !== this.state.selectedCategory) {
			if (titleCategories.target.added.type === 'remove') {
				if (this.props.eventDetails.requiredFieldsContact.TitleCategory) {
					this.setState({ titleCategoryEror: true });
				} else {
					this.setState({ selectedCategory: titleCategories.target.added.name });
					this.props.updateContact(titleCategories.target.added.name, 'titleCategory');
					this.setState({ titleCategoryEror: false });
				}
			} else {
				this.setState({ selectedCategory: titleCategories.target.added.name });
				this.props.updateContact(titleCategories.target.added.name, 'titleCategory');
				this.setState({ titleCategoryEror: false });
			}
		}
	};
	changeTitle = value => {
		this.setState({
			title: value,
			titleEror: null
		});
	};
	saveTitle = () => {
		this.setState({ titleEror: null });
		if (this.props.eventDetails.contact.title !== this.state.title) {
			if (this.state.title === '' && this.props.eventDetails.requiredFieldsContact.Title) {
				this.setState({
					title: this.props.eventDetails.contact.title,
					titleEror: 'error'
				});
			} else {
				this.props.updateContact(this.state.title, 'title');
				this.setState({ titleEror: null });
			}
		}
	};

	render() {
		const { eventDetails } = this.props || null;
		const classes = new BemClass('ActivityWrapper');
		const select2Options = {
			dropdownCssClass: 'up-select2--inline__dropdown',
			minimumResultsForSearch: -1,
			allowClear: false
		};
		return (
			<React.Fragment>
				{eventDetails && (
					<div className="titleWrapper">
						<div className={classes.elem('contactTitles')}>
							{eventDetails.contact &&
								eventDetails.contact.titleCategory &&
								eventDetails.titleCategories && (
									<Tooltip
										title={this.state.titleCategoryEror ? this.lang.required : ''}
										distance={20}
									>
										<ReactTemplates.INPUTS.upSelect
											defaultValue={{
												id: 0,
												name:
													eventDetails.contact.titleCategory.value ||
													this.state.selectedCategory
											}}
											className={
												this.state.titleCategoryEror
													? 'titleCategoryError titleCategory '
													: 'titleCategory'
											}
											onChange={this.setTitleCategories}
											options={select2Options}
											data={eventDetails.titleCategories}
											state={this.state.titleCategoryEror}
										/>
									</Tooltip>
								)}

							{eventDetails.contact && eventDetails.contact.title && (
								<React.Fragment>
									{eventDetails.contact.titleCategory && (
										<span className="barBoarder"> {' | '} </span>
									)}
									<Tooltip title={this.state.titleEror ? this.lang.required : ''} distance={20}>
										<Input
											value={this.state.title}
											size="sm"
											className="titleName"
											onChange={e => this.changeTitle(e.target.value)}
											onBlur={this.saveTitle}
											onFocus={this.saveTitle}
											onKeyPress={event => {
												if (event.key === 'Enter') {
													this.saveTitle();
												}
											}}
											state={this.state.titleEror}
										/>
									</Tooltip>
								</React.Fragment>
							)}
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}
EventTitles.propTypes = {
	saveContact: PropTypes.func,
	updateContact: PropTypes.func,
	eventDetails: PropTypes.object
};

import Resource from './Resource';

class AccountCard extends Resource {
	constructor() {
		super('accountCard' /*Attributes*/);
	}
}

const resource = new AccountCard();

export default resource;

'use strict';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import CallListSelect from 'Components/Inputs/CallListSelect';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';

angular.module('domain.advancedSearch').directive('activityInfoSearchSection', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/domain/advanced-search/directives/activityInfoSearchSection.tpl.html?file'),
		scope: {
			Filters: '=filters',
			onChange: '&',
			onKeyup: '&'
		},
		controller: [
			'$scope',
			'AppService',
			'$safeApply',
			'$translate',
			'FeatureHelper',
			function ($scope, AppService, $safeApply, $translate, FeatureHelper) {
				$scope.isPriorityEnable = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');
				$scope.hasActivities = !Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
				$scope.inited = false;
				$scope.ngChange = function () {
					if ($scope.onChange) {
						$scope.onChange();
					}
				};
				$scope.onActivityTypeChange = values => {
					$scope.Filters.ActivityType.value = values.map(({ id }) => id);
					$safeApply($scope);
				};

				$scope.onCallListChange = value => {
					$scope.Filters.CallList.value = value;
					$safeApply($scope);
				};

				$scope.activityTypeLabel = $scope.hasActivities
					? $translate.instant('default.activityType')
					: $translate.instant('default.taskType');
				$scope.activityTypeLabelLowerCase = $scope.hasActivities
					? $translate.instant('default.activityType').toLowerCase()
					: $translate.instant('default.taskType').toLowerCase();
				$scope.callListLabelLowerCase = $translate.instant('default.callList').toLowerCase();
				$scope.UpActivityTypes = UpActivityTypes;
				$scope.CallListSelect = CallListSelect;
				$scope.ngKeyup = function (e) {
					if ($scope.onKeyup) {
						$scope.onKeyup({ $event: e });
					}
				};

				$scope.togglePriority = () => {
					const { Priority } = $scope.Filters;

					Priority.value = Priority.value[0] ? [ACTIVITY_PRIORITY.NONE] : [ACTIVITY_PRIORITY.HIGH];
					$scope.ngChange();
				};

				$scope.hasTodoList = FeatureHelper.hasSoftDeployAccess('TODO_LIST');

				var init = function () {
					$scope.Filters.Description.comparisonType = 'Wildcard';
					$scope.Filters.Notes.comparisonType = 'Wildcard';
					$scope.Filters.CallList.value = null;
					$scope.inited = true;
					if ($scope.Filters.Priority) {
						$scope.priority = !!$scope.Filters.Priority.value[0];
					}
				};

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var unWatchInit = $scope.$watch('Filters', function (filters) {
						if (filters !== undefined) {
							unWatchInit();
							init();
						}
					});
				});
			}
		]
	};
});

import BrowserService from 'Services/BrowserService';
import Pusher from 'pusher-js';
import logError from 'App/babel/helpers/logError';
import history from 'App/pages/routes/history';
import openModal from 'App/services/Modal';

Pusher.Runtime.createXHR = function () {
	const xhr = new XMLHttpRequest();
	xhr.withCredentials = true;
	return xhr;
};

angular.module('upResources').factory('PushNotifications', [
	'$rootScope',
	'$translate',
	'$q',
	'URL',
	'API',
	'PUSHER',
	'$resource',
	'NotificationAttributes',
	'Ads',
	'AppService',
	'$upModal',
	'ParseGeneric',
	'Esign',
	'CacheRefresher',
	'Metadata',
	'Self',
	'Appointment',
	'Activity',
	function PushNotifications(
		$rootScope,
		$translate,
		$q,
		URL,
		API,
		PUSHER,
		$resource,
		NotificationAttributes,
		Ads,
		AppService,
		$upModal,
		ParseGeneric,
		Esign,
		CacheRefresher,
		Metadata,
		Self,
		Appointment,
		Activity
	) {
		var pusher = null;

		var Types = {
			AGREEMENT: 'Agreement',
			APP: 'AppCallback',
			ASSIGNED: 'Assigned',
			ESIGN: 'Esign',
			EXPORT: 'Export',
			IMPORT: 'Import',
			JOB: 'Job',
			LEAD: 'Lead',
			LISTVIEW: 'ListView',
			MAIL: 'Mail',
			MARKETING: 'MarketingCustom',
			ORDER: 'Order',
			REPORTVIEW: 'ReportView',
			SUBMIT: 'Submit',
			SYSTEM: 'System',
			VISIT: 'Visit',
			PROVISIONING: 'Provisioning',
			TICKET: 'Ticket',
			DEFAULT: 'Default'
		};

		var currentJobs = {};
		let queuedJobIds = [];

		var dateFields = ['date', 'read'];

		var Notification = $resource(
			URL + API + ':customer/notifications/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseNotification', {
						isArray: false,
						custom: 'notification',
						dateFields: dateFields
					}),
					skipDateConvert: true
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseNotification', {
						isArray: true,
						custom: 'notification',
						dateFields: dateFields
					}),
					skipDateConvert: true
				},
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false },
				markAsRead: { method: 'PUT', isArray: false, url: URL + API + ':customer/notifications/read' }
			}
		);

		var Model = {
			destroy: function () {
				if (pusher) {
					pusher.disconnect();
					pusher = null;
				}
			},

			customer: function (customer) {
				var instance = {};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					params.customer = customer;

					return Notification.find(params).$promise;
				};

				instance.markAsRead = function (notificationIds) {
					return Notification.markAsRead({ customer: customer }, { notificationIds: notificationIds })
						.$promise;
				};

				instance.init = function (userId, metadata) {
					if (metadata) {
						var socketId = customer + '.' + userId;

						if (pusher) {
							pusher.disconnect();
						}
						pusher = new Pusher(PUSHER.key, {
							authTransport: 'ajax',
							authEndpoint: URL + API + customer + '/authPusher/',
							encrypted: true,
							socket_id: socketId,
							cluster: PUSHER.cluster
						});

						var channel = pusher.subscribe(metadata.notificationChannel);
						var userChannel = pusher.subscribe(metadata.notificationUserChannel);
						var metaChannel = pusher.subscribe(metadata.metaChannel);
						var publicChannel = pusher.subscribe(metadata.publicChannel);

						userChannel.bind_global(function (eventName, data) {
							if (eventName.indexOf('pusher:') === -1) {
								$rootScope.$broadcast('userPrivate.' + eventName, data);
							}
						});

						metaChannel.bind('refreshCache', function (data) {
							if (!data || !data.types || !data.types.length) {
								return;
							}

							CacheRefresher.refresh(data.types);
						});

						publicChannel.bind('hardRefreshPush', function () {
							$rootScope.$broadcast('hardRefreshPush');
						});

						const downloadExport = id => {
							// Abort if this tab is not the active one
							if (BrowserService.pageIsHidden()) {
								return;
							}
							id.split(',').forEach(function (actualId, index) {
								setTimeout(function () {
									var link = document.createElement('a');
									link.href = URL + API + customer + '/resources/download/internal/' + actualId;
									document.body.appendChild(link);
									link.click();
									document.body.removeChild(link);
								}, 1000 * (index + 1));
							});
						};

						channel.bind('notificationObject', function (data) {
							// Check for user in array of users
							var accessible = data.accessableBy === 'all' || data.accessableBy.indexOf(userId) !== -1;
							// If we have access to this notification we get it
							if (accessible) {
								var oldNotification = currentJobs[data.notificationId];
								if (currentJobs[data.notificationId]) {
									oldNotification.message = data.message;
									oldNotification.status = data.status;
									$rootScope.$broadcast('pushNotification.data', oldNotification);

									// quick fix to update notifications
									if (
										oldNotification.type === 'Job' &&
										oldNotification.action === 'launch-ad-campaign'
									) {
										if (oldNotification.status === 100 || oldNotification.status === -1) {
											Ads.customer(customer)
												.get(oldNotification.entityId)
												.then(function (res) {
													$rootScope.$broadcast(
														Ads.eventPrefix + '.' + Ads.events.updated,
														res.data
													);
												})
												.catch(e => {
													logError(e, `Failed to get Ad ${oldNotification.entityId}`);
												});
										}
									} else if (
										oldNotification.type === 'Job' &&
										oldNotification.action === 'soliditet-buy-multiple-clients'
									) {
										$rootScope.$broadcast(
											'soliditetBuyMultipleClientsNotification',
											oldNotification
										);
									} else if (
										oldNotification.type === Types.JOB &&
										['merge-client', 'merge-contact'].includes(oldNotification.action) &&
										data.status === 100
									) {
										const entity =
											'merge-client' === oldNotification.action ? 'account' : 'contact';
										$rootScope.$broadcast(entity + '.merged', {
											merged: {
												id: parseInt(data.message.split(':')[0])
											},
											deleted: {
												id: parseInt(data.message.split(':')[1])
											}
										});
									}
									if (oldNotification.type === Types.EXPORT) {
										if (oldNotification.status === 100) {
											downloadExport(oldNotification.message);
										}
									}

									if (data.status === 100) {
										delete currentJobs[data.notificationId];
									}
								} else {
									const isSharedNotification = data.accessableBy?.length > 1;
									if (queuedJobIds.includes(data.notificationId)) {
										// if we're here, it means we're already waiting on this notification data
										// ...so we don't want to queue it again
										return;
									}
									if (isSharedNotification) {
										queuedJobIds.push(data.notificationId);
									}

									// Add random delay between 0 and 30 seconds
									const waitTime = isSharedNotification ? Math.random() * 30_000 : 0;
									setTimeout(() => {
										Notification.get({ customer: customer, id: data.notificationId })
											.$promise.then(function (res) {
												// we've fetched the extra notification data, so remove it from the queue
												queuedJobIds = queuedJobIds.filter(id => id !== data.notificationId);

												var notificationData = res.data;

												const isJobOrImportExport =
													notificationData.type === Types.JOB ||
													notificationData.type === Types.EXPORT ||
													notificationData.type === Types.IMPORT;

												$rootScope.$broadcast('pushNotification.data', notificationData);

												if (isJobOrImportExport) {
													currentJobs[notificationData.id] = notificationData;
												}

												if (notificationData.type === Types.EXPORT) {
													if (notificationData.status === 100) {
														downloadExport(notificationData.message);
													}
												}

												// quick fix to update notifications
												if (
													notificationData.type === 'Job' &&
													notificationData.action === 'launch-ad-campaign'
												) {
													if (data.status === 100 || data.status === -1) {
														return Ads.customer(customer)
															.get(notificationData.entityId)
															.then(function (res) {
																$rootScope.$broadcast(
																	Ads.eventPrefix + '.' + Ads.events.updated,
																	res.data
																);
															});
													}
												} else if (
													currentJobs[data.notificationId] &&
													currentJobs[data.notificationId].action ===
														'soliditet-buy-multiple-clients'
												) {
													$rootScope.$broadcast(
														'soliditetBuyMultipleClientsNotification',
														currentJobs[data.notificationId]
													);
												}

												// Notify the app about stuff here
												switch (notificationData.type) {
													case Types.ESIGN:
														return Esign.get(notificationData.esign.id).then(function (
															res
														) {
															var data = res.data;
															data.id = notificationData.esign.id;
															$rootScope.$broadcast('esign.updated', data);
														});
													case Types.LISTVIEW: {
														let promise;
														if (notificationData.action === 'salesboard') {
															promise = window.Tools.Salesboard.get(
																notificationData.entityId
															);
														} else {
															promise = Tools.ListView.customer(customer).get(
																notificationData.action,
																notificationData.entityId
															);
														}
														return promise.then(function (res) {
															$rootScope.$broadcast('listView.added', res.data);
														});
													}
													case Types.REPORTVIEW:
														return Tools.ReportView.get(notificationData.entityId, {
															entity: notificationData.action
														}).then(function (res) {
															AppService.setReportViews(
																notificationData.action,
																res.data
															);
															$rootScope.$broadcast('reportView.added', res.data);
														});
												}
											})
											.catch(e => {
												if (e && e.status === 401) {
													// we have become loged out due to inactivity or logging in on another device.
													Model.destroy();
												} else if (e && e.status === 404) {
													// This can happen when you swap between brands
												} else {
													logError(e, 'Failed to handle notification');
												}
											});
									}, waitTime);
								}
							}
						});

						channel.bind('genericObject', function (data) {
							switch (data.type) {
								case 'Activity':
									$rootScope.$broadcast(`activity.${data.event}`, data.obj);
									break;
								case 'ListView':
								case 'ReportView':
									var promise;

									if (data.view === 'salesboard') {
										promise = window.Tools.Salesboard.get(data.id);
									} else {
										promise = Tools.ListView.customer(customer).get(data.view, data.id);
									}

									promise
										.then(function (res) {
											$rootScope.$broadcast('listView.updated', res.data);
										})
										.catch(e => {
											logError(e, 'Failed to get list view');
										});
									break;
								case 'ARRChangesUpdated': {
									$rootScope.$broadcast('ARRChanges.updated', data);
									break;
								}
								case 'CustomerSupportEmailRedirectDone': {
									$rootScope.$broadcast('admin.customerSupportEmailRedirectDone', data.obj);
									break;
								}
								case 'refreshProvisioning':
									if (AppService.isLoggedIn()) {
										var promises = {};
										promises.metadata = Metadata.customer(customer).get();
										promises.self = Self.get();

										$q.all(promises)
											.then(function (res) {
												AppService.setMetadata(res.metadata);
												AppService.setAccountSelf(res.self.data);
												window.store.Billing.retrieveData();
											})
											.catch(e => {
												logError(e, 'Failed to refresh provisioning');
											});
									} else {
										Tools.$state.go('login');
									}
									break;
								case 'Ticket':
									$rootScope.$broadcast(`ticket.${data.event}`, data.obj);
									break;
								case 'Comment':
									if (typeof data.obj === 'object') {
										const user = AppService.getSelf();
										data.obj.userEditable = data.obj.userRemovable = [
											data.obj.regBy,
											data.obj.modBy
										].includes(user.id);
									}

									$rootScope.$broadcast(`comment.${data.event}`, data.obj);
									break;
								default:
									console.error(
										'pushNotifications genericObject. Rogue switch case value',
										data.type
									);
							}
						});

						var doesAppointivityExist = function (isMulti, isActivity, data) {
							if (isMulti) {
								return $q.when(true);
							}

							var resource = isActivity ? Activity : Appointment;
							return resource
								.customer(Tools.AppService.getCustomerId())
								.get(data.object.id)
								.then(function (res) {
									return res.data;
								})
								.catch(function (err) {
									// Return null if cant find the appointivity
									if (err && err.status === 404) {
										return;
									}

									throw err;
								});
						};

						var pushReminder = function (data) {
							if (!data) {
								return;
							}

							const parsedDate = window.Tools.parseDate(data.date || data.object.date);
							var date = moment(parsedDate);
							var timeToActivity = moment.duration(date.diff(moment()));
							var reminderTime = Math.ceil(timeToActivity.asMinutes());
							var reminderUnit = 'minutes';
							var isActivity = data.entity === 'activity';

							var isAppointmentOutcome = data.entity === 'appointmentOutcome';
							var isMulti = data.idArray;
							var isAppointmentOutComeMulti = isAppointmentOutcome && isMulti;
							var isAppointmentOutComeBroken =
								isAppointmentOutcome && !isMulti && (!data.object || !data.object.client);
							var isBroken = !isMulti && !data.object;
							if (isAppointmentOutComeMulti || isAppointmentOutComeBroken || isBroken) {
								return;
							}

							doesAppointivityExist(isMulti, isActivity, data)
								.then(function (appointivity) {
									if (!appointivity) {
										return;
									}
									if (appointivity.isAppointment && appointivity.outcome !== 'planned') {
										return;
									}

									if (reminderTime > 60) {
										reminderTime = Math.ceil(timeToActivity.asHours());
										reminderUnit = 'hours';
									}

									var title =
										$translate.instant(data.entity) +
										' ' +
										$translate.instant('notificationcenter.planned.in').toLowerCase() +
										' ' +
										reminderTime +
										' ' +
										$translate.instant(reminderUnit);
									if (reminderTime <= 0) {
										title =
											$translate.instant(data.entity) +
											' ' +
											$translate.instant('date.now').toLowerCase();
									}
									var clientName = data.object && data.object.client && data.object.client.name;
									if (clientName) {
										title +=
											' ' + $translate.instant('default.with').toLowerCase() + ' ' + clientName;
									}
									if (isAppointmentOutcome) {
										// Restrict user from taking action if apointivity is not user editable
										if (!appointivity.userEditable) {
											return;
										}
										if (clientName) {
											title =
												$translate.instant('appointment.outcome.whatResultPrefix') +
												' ' +
												clientName +
												' ' +
												$translate.instant('appointment.outcome.whatResultPostfix');
										} else {
											title = $translate.instant('appointment.outcome.whatResult');
										}
									}

									var body =
										date.calendar().toLowerCase() +
										' ' +
										$translate.instant('default.at2').toLowerCase() +
										' ' +
										date.format('HH:mm');
									var browserNotificationBody = body;
									var snoozeTimeMinutes = 10;
									var snoozeTimeMillis = snoozeTimeMinutes * 60 * 1000;

									var notification = {
										type: 'reminder',
										entity: data.entity,
										title: title,
										body: body,
										autoHide: false,
										snoozeTime: snoozeTimeMinutes,
										onSnooze: function () {
											setTimeout(function () {
												pushReminder(data);
											}, snoozeTimeMillis);
										}
									};

									if (!isAppointmentOutcome) {
										notification.icon = 'bell';
									}

									var onClick;
									if (isMulti) {
										onClick = function () {
											if (
												Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
												Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
											) {
												history.push(`/todo/allToday`);
											} else {
												var state = isActivity ? 'activities' : 'appointments';
												var path =
													location.origin +
													'/#/' +
													Tools.AppService.getCustomerId() +
													'/' +
													state +
													'/?q=' +
													Tools.FilterHelper.convertForURL(
														{ IdList: { value: data.idArray } },
														data.entity,
														{
															toString: true
														}
													);
												location.replace(path);
											}
										};

										var langTag = isActivity ? 'default.activities' : 'default.appointments';
										browserNotificationBody =
											data.idArray.length + ' ' + $translate.instant(langTag) + ' ' + body;
										notification.body =
											data.idArray.length +
											' ' +
											$translate.instant(langTag) +
											' ' +
											notification.body;

										notification.buttonText = $translate.instant('notificationcenter.seeAll');
									} else {
										onClick = function () {
											var editEntity = isActivity ? 'editActivity' : 'editAppointment';
											Tools.$upModal.open(editEntity, { id: data.object.id });
										};
										browserNotificationBody = data.object.activityType.name + ' ' + body;
										notification.body = data.object.activityType.name + ' ' + notification.body;

										if (isAppointmentOutcome) {
											notification.updateOutcome = function (
												notificationId,
												didComplete,
												callback
											) {
												window.NotificationService.update({
													id: notificationId,
													isSaving: true
												});
												Appointment.customer(Tools.AppService.getCustomerId())
													.get(data.object.id)
													.then(function (res) {
														var appointment = res.data;
														appointment.outcome = didComplete
															? 'completed'
															: 'notCompleted';
														return Appointment.customer(Tools.AppService.getCustomerId())
															.save(appointment)
															.then(function () {
																$upModal.open('editAppointment', {
																	id: appointment.id
																});
																callback();
															});
													})
													.catch(e => {
														window.NotificationService.update({
															id: notificationId,
															isSaving: false
														});
														logError(e, 'Something went wrong while updating apointivity');
													});
											};
										} else {
											notification.description = data.object.description;
										}
										notification.buttonText = isActivity
											? $translate.instant('notificationcenter.goto.activity')
											: $translate.instant('notificationcenter.goto.appointment');
									}

									if (window.document.hidden && !isAppointmentOutcome) {
										var browserNotificationOpts = {
											onClick: function () {
												onClick();
											},
											hasIcon: true,
											autoHide: false
										};

										window.BabelServices.BrowserNotifications.show(
											{ title: title, body: browserNotificationBody },
											browserNotificationOpts
										);
									} else {
										notification.onClick = onClick;
										window.NotificationService.add(notification);
									}
								})
								.catch(e => {
									logError(e, 'Does appointivity exists error');
								});
						};

						userChannel.bind('reminder', function (data) {
							pushReminder(data);
						});

						channel.bind('support_' + userId, function (data) {
							openModal('RequestSupport', {
								email: data.object.email,
								message: data.object.message,
								name: data.object.name
							});
						});

						channel.bind('setFeature_' + userId, function (data) {
							const featureFlag = data.object.message.featureFlag.name;
							const enable = data.object.message.setToTrue;

							const promises = {};
							promises.self = Self.get();

							$q.all(promises)
								.then(function (res) {
									res.self.data.unreleasedFeatures[featureFlag] = enable;
									AppService.setAccountSelf(res.self.data);
								})
								.catch(e => {
									logError(e, 'Failed to update userparams when setting feature flag');
								});
						});

						channel.bind('migrationStatus', function () {
							$rootScope.$broadcast('migrationStatus');
						});

						// Setup developer fakeChannels
						channel.bind('fakeNotification', function (data) {
							$rootScope.$broadcast('pushNotification.data', data.object);
						});
						channel.bind('fakeMigrated', function () {
							$rootScope.$broadcast('migrationStatus');
						});

						channel.bind('onboardingProgressChanged', function (data) {
							$rootScope.$broadcast('onboardingStepCompleted', data);
						});

						channel.bind('standardIntegrationImportChanged', function (data) {
							$rootScope.$broadcast('standardIntegrationImportChanged', data);
						});
					}
				};

				return instance;
			},

			Types: Types,
			attr: NotificationAttributes().attr
		};

		return Model;
	}
]);

import React, { useState, useEffect, useRef } from 'react';
import Widget from 'Components/Widget';
import T from 'Components/Helpers/translate';
import { RCWidgetData } from 'Resources/ReportWidget';

export type SalesWidget = {
	data: RCWidgetData;
	group: string;
	period: string;
};
interface Props {
	widget: SalesWidget;
	visible?: boolean;
	salesPrognosis?: number;
	progressBarAnimationDelay: number;
}

const SalesTargetWidget = ({ widget, visible, salesPrognosis, progressBarAnimationDelay }: Props) => {
	const prognosisTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
	// if we have a sales prognosis, we want to prepare Widget to render the correct bar colors by setting widgetPrognosis to 1
	const prognosis = widget.data.type.name === 'RECURRING' ? salesPrognosis : null;
	const [widgetPrognosis, setWidgetPrognosis] = useState(prognosis && salesPrognosis ? 1 : 0);

	useEffect(() => {
		if (visible && prognosis) {
			prognosisTimer.current = setTimeout(() => {
				setWidgetPrognosis(prognosis);
			}, 2000);
		}
	}, [visible, prognosis]);

	const remainingLabel =
		widget.data.total.progress < widget.data.total.goal
			? T(widget.data.language.remaining)
			: T('report.surpassedQouta');

	return (
		<Widget
			key={widget.data.type.name}
			type={widget.data.type.name}
			period={widget.period}
			title={T(widget.data.language.title)}
			subtitle={T(widget.data.language.subTitle)}
			size={'md'}
			currency={widget.data.currency}
			goal={widget.data.total.goal}
			goalLabel={T(widget.data.language.goal)}
			surpassedGoalLabel={T(widget.data.language.overGoal)}
			progress={widget.data.total.progress}
			progressLabel={T(widget.data.language.progress)}
			remaining={widget.data.total.remaining}
			remainingLabel={remainingLabel}
			group={widget.group}
			noMargin
			hidePinned
			showShadow
			prognosis={widgetPrognosis}
			prognosisLabel={T('order.celebration.recurringSalesPrognosis')}
		/>
	);
};

export default SalesTargetWidget;

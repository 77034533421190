'use strict';

import Automations from 'Components/Admin/Automations';
import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('ListAutomations', [
	'AppService',
	'$scope',
	'$state',
	'$upModal',
	'$translate',
	'$safeApply',
	function (AppService, $scope, $state, $upModal, $translate, $safeApply) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = Automations;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			automations: []
		};

		$scope.$on('automation.deleted', function (e, automation) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.automations, { id: automation.id });

			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.automations.splice(index, 1);
				$safeApply($scope);
			}
		});

		AdminSubCtrl.rootData.editAutomation = function (automation) {
			var id = automation ? automation.id : null;
			$state.go('administration.automation', { id: id });
		};

		AdminSubCtrl.rootData.deleteAutomation = function (automation) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'admin.automation',
					body: (
						<>
							{$translate.instant('confirm.removeAutomation2')} {' '}
							<b>{(automation.name || $translate.instant('default.noName'))}</b>{'?'}
						</>
					),
					onClose: confirmed => {
						if (confirmed) {
							Tools.Automations.delete(automation.id);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') +
						' ' +
						$translate.instant('admin.automation').toLowerCase(),
					body:
						$translate.instant('confirm.removeAutomation2') +
						' <b>' +
						(automation.name || $translate.instant('default.noName')) +
						'</b>?',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					window.Tools.Automations.delete(automation.id);
				});
		};

		var getAutomations = function () {
			var filters = new Tools.RequestBuilder();
			filters.addFilter({ field: 'journeyType' }, filters.comparisonTypes.Equals, null);
			return window.Tools.Automations.find(filters.build()).then(function (res) {
				AdminSubCtrl.rootData.pageData.automations = res.data;
				$safeApply($scope);
			});
		};

		var init = function () {
			// eslint-disable-next-line promise/catch-or-return
			getAutomations().then(function () {
				AdminSubCtrl.rootData.pageLoading = false;
				$safeApply($scope);
			});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

'use strict';

ReactTemplates.findProspect.companyInfoFilter = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			data: null,
			open: false,
			includeHeadquarters: false,
			onChange: function () {}
		};
	},
	getInitialState: function () {
		return {
			data: null
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			companyInfo: t('soliditet.clientInfo'),
			numOfEmployees: t('default.numOfEmployees'),
			to: t('default.to').toLowerCase(),
			companyTurnover: t('soliditet.companyTurnover'),
			noFilterForThisType: t('soliditet.noFilterForThisType'),
			includeHeadquarter: t('soliditet.includeHeadquarter')
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var self = this;

		if (nextProps.ranges !== self.props.ranges) {
			nextProps.ranges.then(function (data) {
				self.setState({
					data: data,
					open: nextProps.open
				});
			});
		}
	},
	scrollTop: 0,
	UNSAFE_componentWillUpdate: function () {
		var node = this.refs['list'];

		if (node) {
			this.scrollTop = node.scrollTop;
		}
	},
	componentDidUpdate: function () {
		var node = this.refs['list'];

		if (node) {
			node.scrollTop = this.scrollTop;
		}

		var inputEmployee = ReactDOM.findDOMNode(this.refs['_employee_input']);
		if (inputEmployee) {
			var self = this;

			var formatSelection = function (obj, container, escape) {
				return escape(obj.title);
			};
			var formatResult = function (obj, container, query, escape) {
				return escape(obj.title);
			};
			var matcher = function (term, undefined, object) {
				return object.title.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			};

			var initSelection = function (element, callback) {
				var initValue = null;
				var filter = self.props.filters.EmployeesRange;

				if (filter) {
					initValue = _.find(self.props.singleSelectEmployees, function (val) {
						return filter.value.start === val.start && filter.value.end === val.end;
					});
				}

				callback(initValue);
			};

			$(inputEmployee)
				.select2({
					multiple: false,
					data: this.props.singleSelectEmployees,
					formatSelection: formatSelection,
					formatResult: formatResult,
					matcher: matcher,
					initSelection: initSelection
				})
				.on('change', function (event) {
					if (event.val && event.added) {
						self.props.onChange('EmployeesRange', { start: event.added.start, end: event.added.end });
					}
				});
		}
	},
	resizeTimeout: null,
	resizeThrottler: function () {
		var self = this;

		if (!self.resizeTimeout) {
			self.resizeTimeout = setTimeout(function () {
				self.resizeTimeout = null;
				self.calculateHeight();
				self.setState({});
			}, 66);
		}
	},
	height: null,
	calculateHeight: function () {
		var sidebar = document.getElementById('find-prospects-sidebar');
		if (sidebar) {
			this.height = sidebar.offsetHeight - 225;
		}
	},
	componentDidMount: function () {
		var self = this;

		self.calculateHeight();
		window.addEventListener('resize', self.resizeThrottler);

		self.props.ranges.then(function (ranges) {
			self.setState({
				data: ranges
			});
		});
	},
	componentWillUnmount: function () {
		window.removeEventListener('resize', this.resizeThrottler);
	},
	toggleOpen: function () {
		var view = !this.props.open ? 'companyInfo' : undefined;
		this.props.toggleOpen(view);
	},
	onChangeFilter: function (filterName, values) {
		if (filterName === 'Employees' || filterName === 'Turnover') {
			var obj = {};
			obj[filterName] = { start: values.start, end: values.end };
			obj.fromRange = true;
			this.setState(obj);
			this.props.onChange(filterName, values);
		} else {
			this.props.onChange(filterName, values);
		}
	},
	onChangeInputFn: function (type, isStart, obj) {
		if (obj[type].start && obj[type].end && obj[type].start > obj[type].end) {
			if (isStart) {
				obj[type].end = obj[type].start;
			} else {
				obj[type].start = obj[type].end;
			}
		}
		if (type === 'Turnover') {
			obj[type].start = obj[type].start ? obj[type].start * 1000 : null;
			obj[type].end = obj[type].end ? obj[type].end * 1000 : null;
		}
		this.props.onChange(type, obj[type]);
	},
	render: function () {
		var self = this;
		var rowHeaderClasses = self.props.open ? 'row-header open' : 'row-header';
		var currency = self.props.filters.Country
			? _.find(self.props.countries, { value: self.props.filters.Country.value })
			: null;
		currency = currency ? currency.currency : 'SEK'; // fallback

		var list = null;

		if (this.props.open) {
			list = [];
			var graphEmployee = null;
			var graphTurnover = null;

			var eValue = this.props.getFilterData('Employees')
				? this.props.getFilterData('Employees').value
				: { start: null, end: null };
			var tValue = this.props.getFilterData('Turnover')
				? this.props.getFilterData('Turnover').value
				: { start: null, end: null };

			var employeeInput = eValue; //self.state.Employees || {start: null, end: null};
			var turnoverInput = tValue; //self.state.Turnover || {start: null, end: null};

			var companyForm = null;
			if (self.props.filters.CompanyType) {
				companyForm = _.find(self.props.companyForms, function (cForm) {
					var values = _.difference(cForm.value, self.props.filters.CompanyType.value);
					return values.length === 0;
				});
			}

			var emplFilter = null,
				turnoverFilter = null;

			// IF company type == AB
			if (self.state.data && companyForm && companyForm.key === 'AB') {
				graphEmployee = React.createElement(ReactTemplates.findProspect.rangeGraphFilter, {
					config: self.state.data.employees,
					name: 'Employees',
					key: 'Employees',
					onChange: self.onChangeFilter,
					stepSize: 1000,
					values: eValue, //this.state.Employees,
					fromRange: this.state.fromRange,
					range: this.props.employeeRange
				});

				graphTurnover = React.createElement(ReactTemplates.findProspect.rangeGraphFilter, {
					config: self.state.data.turnover,
					name: 'Turnover',
					key: 'Turnover',
					onChange: self.onChangeFilter,
					stepSize: 1000,
					values: tValue, //this.state.Turnover,
					fromRange: this.state.fromRange,
					range: this.props.turnoverRange
				});

				var rangeInputEl = React.createElement(ReactTemplates.findProspect.rangeInput, {
					name: 'Employees',
					onChange: self.onChangeInputFn,
					startValue: employeeInput.start,
					endValue: employeeInput.end,
					toPlaceholder: self.lang.to,
					max: 1000,
					min: 0
				});

				emplFilter = (
					<div style={{ position: 'relative' }}>
						{graphEmployee}
						{rangeInputEl}
					</div>
				);

				var turnoverStart = turnoverInput.start ? Math.round(turnoverInput.start / 1000) : null;
				var turnoverEnd =
					turnoverInput.end || turnoverInput.end === 0 ? Math.round(turnoverInput.end / 1000) : null;

				var rangeInputTu = React.createElement(ReactTemplates.findProspect.rangeInput, {
					name: 'Turnover',
					onChange: self.onChangeInputFn,
					startValue: turnoverStart,
					endValue: turnoverEnd,
					toPlaceholder: self.lang.to,
					unit: 'M' + currency,
					max: 1000,
					min: 0
				});

				turnoverFilter = (
					<div style={{ position: 'relative' }}>
						{graphTurnover}
						{rangeInputTu}
					</div>
				);
			} else if (self.state.data && companyForm && companyForm.key === 'State') {
				// Company type === Stat, landsting, kommuner, församlinga
				emplFilter = (
					<div>
						<input className={'form-control'} ref={'_employee_input'} />
					</div>
				);
			}

			var includeHeadquarters = this.props.filters.Headquarters ? this.props.filters.Headquarters.value : false;

			if (!self.state.data && companyForm && (companyForm.key === 'State' || companyForm.key === 'AB')) {
				list.push(
					<div key="employee-filter" className="company-info-section" style={{ paddingTop: '5px' }}>
						<div style={{ width: '100%', padding: '5px 15px', textAlign: 'center' }} key="loading">
							<i className="fa fa-refresh fa-spin fa-fw" />
						</div>
					</div>
				);
			} else if (companyForm && companyForm.key) {
				// If === AB or State

				list.push(
					<div key="employee-filter" className="company-info-section">
						<div>{self.lang.numOfEmployees}</div>
						{emplFilter}
					</div>
				);

				if (turnoverFilter) {
					list.push(
						<div key="turnover-filter" className="company-info-section" style={{ paddingTop: '15px' }}>
							<div>
								{self.lang.companyTurnover} {'(M'}
								{currency}
								{')'}
							</div>
							{turnoverFilter}
						</div>
					);

					list.push(
						<div key="headquarter-filter" style={{ width: '100%', padding: '15px' }}>
							<ReactTemplates.upFilters.components.toggle
								value={includeHeadquarters}
								onChange={self.onChangeFilter.bind(self, 'Headquarters')}
								className="toggle-bright-blue"
							/>
							<span className="filial-label">{self.lang.includeHeadquarter}</span>
						</div>
					);
				}
			} else {
				list.push(
					<div key="no-filter" style={{ width: '100%', padding: '5px 15px' }}>
						<div id="no-filter-label">{self.lang.noFilterForThisType}</div>
					</div>
				);
			}
		}

		var value = [];
		var hasEmployeeFilter = self.props.filters.Employees || self.props.filters.EmployeesRange;

		if (hasEmployeeFilter) {
			var filterValue = self.props.filters.Employees
				? self.props.filters.Employees.value
				: self.props.filters.EmployeesRange.value;

			if (filterValue.start || filterValue.start === 0 || filterValue.end) {
				value.push(
					<div key="employee-filter-value" className="metadata">
						<i className="fa fa-users" />
						<span>
							{filterValue.start || filterValue.start === 0 ? filterValue.start : 0}
							{'-'}
							{filterValue.end || filterValue.end === 0 ? filterValue.end : '1000+'}
						</span>
					</div>
				);
			}
		}

		if (
			self.props.filters.Turnover &&
			(self.props.filters.Turnover.value.start || self.props.filters.Turnover.value.end)
		) {
			var startVal = self.props.filters.Turnover.value.start
				? Math.round(self.props.filters.Turnover.value.start / 1000)
				: 0;
			var endVal =
				self.props.filters.Turnover.value.end || self.props.filters.Turnover.value.end === 0
					? Math.round(self.props.filters.Turnover.value.end / 1000) + ' M' + currency
					: '1000+ M' + currency;

			value.push(
				<div key="turnover-filter-value" className="metadata">
					<span>
						{startVal}
						{'-'}
						{endVal}
					</span>
				</div>
			);
		}

		var style = null;

		if (!this.props.washWarning) {
			style = { maxHeight: this.height, overflowY: 'scroll' };
		}

		return (
			<div id="company-type-filter" className="company-info-filter">
				<div className={rowHeaderClasses} onClick={self.toggleOpen}>
					<span className="row-header-icon-wrap">
						<i className={'fa fa-info-circle row-header-icon'} />
					</span>

					<span className="row-header-title">{self.lang.companyInfo}</span>
					<span className="row-header-value">{value}</span>
				</div>
				<div ref="list" style={style}>
					{list}
				</div>
			</div>
		);
	}
});

ReactTemplates.findProspect.rangeInput = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		this.onChange = _.debounce(this.onChange, 300);
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.startValue !== this.state.start || nextProps.endValue !== this.state.end) {
			this.setState({
				start: nextProps.startValue,
				end: nextProps.endValue
			});
		}
	},
	onChangeInput: function (type, isStart, e) {
		var val = e.target.value;
		this.onChangeInputFn(type, isStart, val);
	},
	onChangeInputFn: function (type, isStart, val) {
		var obj = this.state;
		if (isStart) {
			var startValue = parseInt(val);

			obj.start = isNaN(startValue) || (this.props.min && startValue < this.props.min) ? 0 : startValue;
		} else {
			var endValue = parseInt(val);
			var invalidNumber =
				(isNaN(endValue) && typeof endValue !== 'undefined') ||
				(this.props.max && endValue > this.props.max) ||
				(this.props.min && endValue < this.props.min);

			obj.end = invalidNumber ? 1000 : endValue;
		}
		obj.fromRange = false;

		this.setState(obj);
		this.onChange(type, isStart, _.cloneDeep(obj));
	},
	onChange: function (type, isStart, obj) {
		var res = {};
		res[type] = obj;
		this.props.onChange(type, isStart, res);
	},
	getInitialState: function () {
		return {
			start: this.props.startValue,
			end: this.props.endValue
		};
	},
	render: function () {
		var self = this;

		var addon = null;
		var addonClass = null;
		if (self.props.unit) {
			addon = <span className="input-group-addon">{self.props.unit}</span>;
			addonClass = 'input-group';
		}

		return (
			<div className="row" style={{ position: 'relative' }}>
				<div className="col-xs-6">
					<div className="form-group">
						<div className={addonClass}>
							<input
								name="startEmployee"
								type="number"
								placeholder="0"
								className="form-control up-input"
								min="0"
								max="1000"
								onChange={self.onChangeInput.bind(self, self.props.name, true)}
								value={self.state.start}
							/>
							{addon}
						</div>
					</div>
				</div>
				<div className="to-binder">{self.props.toPlaceholder}</div>
				<div className="col-xs-6">
					<div className="form-group">
						<div className={addonClass}>
							<input
								name="endEmployee"
								type="number"
								placeholder="1000+"
								className="form-control up-input"
								min="0"
								max="1000"
								onChange={self.onChangeInput.bind(self, self.props.name, false)}
								value={self.state.end}
							/>
							{addon}
						</div>
					</div>
				</div>
			</div>
		);
	}
});

import React from 'react';
import { connect } from 'react-redux';
import { ColorSwitcher, Tab, Tabs, Text, Title, Card } from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import Checklist from './Checklist';
import UsersAndRoles from './UsersAndRoles';
import BemClass from '@upsales/components/Utils/bemClass';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { MappedSalesCoach } from 'App/resources/Model/SalesCoach';
import T from 'Components/Helpers/translate';
import {
	save,
	setName,
	setActiveIfNew,
	setSalesCoach,
	getAllSalesCoaches,
	resetSalesCoach
} from 'Store/reducers/SalesCoachReducer';
import openModal from 'App/services/Modal';

import './SalesCoachModal.scss';

type SalesCoachModalProps = {
	reject: () => void;
	resetSalesCoach: () => void;
	save: (coach?: MappedSalesCoach) => void;
	setActiveIfNew: () => void;
	getAllSalesCoaches: () => void;
	setSalesCoach: (salesCoach: MappedSalesCoach) => void;
	setName: (name: string, salesCoaches?: MappedSalesCoach[] | undefined) => void;
	salesCoach: MappedSalesCoach | null;
	salesCoaches: MappedSalesCoach[] | undefined;
	name: string;
	isNameUnique: boolean;
};

type SalesCoachModalState = { selectedTab: string };

type HeaderTab = {
	id: string;
	lang: string;
};

const mapStateToProps = ({ SalesCoachReducer }: { SalesCoachReducer: { name: string; isNameUnique: boolean } }) => ({
	name: SalesCoachReducer.name,
	isNameUnique: SalesCoachReducer.isNameUnique
});

const mapDispatchToProps = { resetSalesCoach, save, setName, setActiveIfNew, setSalesCoach, getAllSalesCoaches };

class SalesCoachModal extends React.Component<SalesCoachModalProps, SalesCoachModalState> {
	tabs: Array<HeaderTab>;
	lang: { [key: string]: string };

	constructor(p: SalesCoachModalProps) {
		super(p);
		this.lang = {
			cancel: T('default.cancel'),
			createASalesProcess: T('admin.newSalesProcess.createASalesProcess'),
			nameUsers: T('admin.newSalesProcess.nameUsers'),
			checklist: T('admin.newSalesProcess.checklist'),
			nextStep: T('admin.newSalesProcess.nextStep'),
			save: T('default.save')
		};
		this.tabs = [
			{ id: 'usersAndRoles', lang: this.lang.nameUsers },
			{ id: 'checklist', lang: this.lang.checklist }
		];
		this.state = { selectedTab: this.tabs[0].id };
	}

	componentDidMount() {
		const { setSalesCoach, salesCoach } = this.props;
		if (salesCoach) {
			setSalesCoach(salesCoach);
		}
	}

	componentWillUnmount() {
		this.props.resetSalesCoach();
	}

	setSelectedTab = (selectedTab: string) => {
		const { name, isNameUnique } = this.props;
		if (selectedTab === 'checklist' && (name?.length === 0 || !isNameUnique)) {
			return;
		}

		this.setState({ selectedTab });
	};

	getSelectedTab(selectedTab: string) {
		switch (selectedTab) {
			case 'checklist':
				return <Checklist />;
			case 'usersAndRoles':
				return <UsersAndRoles salesCoaches={this.props.salesCoaches ?? undefined} />;

			default:
				return null;
		}
	}

	render() {
		const {
			salesCoach,
			name,
			save,
			setName,
			salesCoaches,
			reject,
			getAllSalesCoaches,
			setActiveIfNew,
			isNameUnique
		} = this.props;
		const { selectedTab } = this.state;

		const classNames = new BemClass('SalesCoachModal');

		return (
			<div className={classNames.b()}>
				<Card className={classNames.elem('header').b()}>
					<Title>{salesCoach ? salesCoach.name : this.lang.createASalesProcess}</Title>
					<Tabs noFlex color="white" onChange={this.setSelectedTab} selected={selectedTab}>
						{this.tabs.map((tab, i) => (
							<Tab key={i} id={tab.id}>
								<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
								<Text color="inherit" bold={selectedTab === tab.id}>
									{tab.lang}
								</Text>
							</Tab>
						))}
					</Tabs>
					<div className={classNames.elem('controls').b()}>
						{/* I will fix this later :-) */}
						<EditorHeaderButton
							title={this.lang.cancel}
							onClick={async () => {
								if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
									openModal('UnsavedChangesAlert', {
										disableConfirm: name?.length === 0 || !isNameUnique,
										disableConfirmTooltip: 'admin.newSalesProcess.nameRequiredSaveTooltip',
										onClose: async confirmed => {
											if (confirmed === undefined) {
												return;
											}
											if (confirmed) {
												await save();
												await getAllSalesCoaches();
											}
											reject();
										}
									});
									return;
								}

								await Tools.$upModal.open('infoConfirm', {
									title: 'default.unsavedChanges',
									body: 'confirm.changesWillBeLost',
									icon: 'fa-exclamation-triangle',
									no: 'default.abort',
									resolveTrue: 'default.discardChanges',
									resolveTrueBtnClass: 'btn-bright-blue btn-lined'
								});
								await getAllSalesCoaches();
								reject();
							}}
							supertitle={undefined}
							className={classNames.elem('cancel').b()}
							noIcon
							next={false}
						/>
						<EditorHeaderButton
							title={selectedTab === 'checklist' ? this.lang.save : this.lang.checklist}
							supertitle={selectedTab === 'checklist' ? null : this.lang.nextStep}
							disabled={name?.length === 0 || !isNameUnique}
							onClick={async () => {
								if (selectedTab === 'checklist') {
									await setActiveIfNew();
									await save();
									await getAllSalesCoaches();
									reject();
								} else {
									const trimmedName = name.trim();
									if (name.length !== trimmedName.length) {
										setName(trimmedName, salesCoaches);
									} else {
										this.setState({ selectedTab: 'checklist' });
									}
								}
							}}
							next
							className={undefined}
							noIcon={false}
						/>
					</div>
				</Card>
				<div className={classNames.elem('content').b()}>{this.getSelectedTab(selectedTab)}</div>
			</div>
		);
	}
}

export const detached = SalesCoachModal;

const Component = connect(mapStateToProps, mapDispatchToProps)(SalesCoachModal);

export default Component;

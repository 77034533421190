import { PureComponent } from 'react';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import lzString from 'lz-string';
import {
	getEmptyRow,
	getEmptyCol,
	types,
	getColWidthFromSize,
	getImageWidth,
	reflowColumns
} from 'Services/MailTemplate';
import logError from 'App/babel/helpers/logError';
import { removeItem } from 'Store/helpers/array';
import NotificationService from 'App/babel/NotificationService';

export const findRowIndex = (config, rowId) => config.rows.findIndex(r => r.id === rowId);
export const findColIndex = (config, rowIndex, colId) => config.rows[rowIndex]?.columns.findIndex(c => c.id === colId);

export const SIDEBAR_MIN_WIDTH = 300;
export const SIDEBAR_MAX_FROM_LEFT = 600;

export const getValueHash = config => {
	return lzString.compressToBase64(JSON.stringify(config));
};

const LOGOS = {
	__darkLogo__: 'darkLogo',
	__brightLogo__: 'logo'
};

class MailContentEditorHoc extends PureComponent {
	constructor(p) {
		super(p);

		this.state = {
			hash: null,
			html: '',
			config: p.config || {
				headerLogo: '',
				headerLink: '',
				rows: [],
				selectedRowId: null,
				selectedColumnId: null
			},
			loadingPreview: false, // maybe remove
			sidebarVisible: false,
			selectedColumn: null,
			selectedRow: null,
			hoveredColumn: null,
			hoveredRow: null,
			selectedToolsTab: 'column',
			selectedSettingsTab: 'settings',
			updateSidebarTrigger: 0
		};

		this.changeLogoType = isLogo => {
			let logoType = 'url';
			let headerLogo = '';
			if (isLogo) {
				logoType = 'logo';
				headerLogo = LOGOS.__brightLogo__;
			}
			const config = { ...this.state.config, logoType, headerLogo };
			this.setState({ config, hash: getValueHash(config) });
			this.props.onChange(config);
		};

		this.saveProfileLogo = logo => {
			const $upModal = getAngularModule('$upModal');
			const filePromise = $upModal
				.open('fileBrowser', {
					types: ['image'],
					public: true,
					selectOnUpload: true
				})
				.then(selectedImages => {
					const AccountProfile = getAngularModule('AccountProfile');
					this.props.accountProfile[LOGOS[logo]] = selectedImages[0].$$publicUrl;
					return AccountProfile.save(this.props.accountProfile);
				});

			filePromise
				.then(() => {
					const config = { ...this.state.config, logoType: 'logo', headerLogo: logo };
					this.setState({ config, hash: getValueHash(config) });
					this.props.onChange(config);
				})
				.catch(e => console.error('saveProfileLogo', e));
		};

		this.configChanged = async (setting, value) => {
			const config = { ...this.state.config, [setting]: value };
			this.setState({ config, hash: getValueHash(config) });

			this.props.onChange(config);
		};

		this.columnChangeTimer = null;
		this.onColumnChange = column => {
			// Update column in config
			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, column.rowId);

			if (rowIndex === -1) {
				return;
			}

			const colIndex = findColIndex(config, rowIndex, column.id);

			if (colIndex === -1) {
				return;
			}

			if (
				column.href &&
				!column.href.match(/{{.*}}/g) &&
				!(
					'http://'.indexOf(column.href.substring(0, 7)) === 0 ||
					'https://'.indexOf(column.href.substring(0, 8)) === 0 ||
					'mailto:'.indexOf(column.href.substring(0, 7)) === 0 ||
					'tel:'.indexOf(column.href.substring(0, 4)) === 0
				)
			) {
				column.href = 'http://' + column.href;
			}

			config.rows[rowIndex].columns[colIndex] = { ...column };
			let newState = {};

			if (this.state.selectedColumn && this.state.selectedColumn.id === column.id) {
				newState.selectedColumn = { ...column };
				config.selectedColumnId = column.id;
				config.selectedRowId = config.rows[rowIndex].id;
			}

			newState = { ...newState, config, hash: getValueHash(config) };

			this.setState(newState);
			if (this.columnChangeTimer) {
				clearTimeout(this.columnChangeTimer);
			}

			const TYPING_CHARS_PER_MIN = 150; // Average is between 150-200

			this.columnChangeTimer = setTimeout(() => {
				this.columnChangeTimer = null;
				this.props.onChange(config);
			}, 1000 / (TYPING_CHARS_PER_MIN / 60)); // Average typing speed is between 150-200 CPS
		};

		this.onRowChange = row => {
			// Update column in config
			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, row.id);

			if (rowIndex === -1) {
				return;
			}

			config.rows[rowIndex] = { ...config.rows[rowIndex], ...row };

			let newState = {};

			if (this.state.selectedRow && this.state.selectedRow.id === row.id) {
				newState.selectedRow = { ...this.state.selectedRow, ...row };
				config.selectedRowId = newState.selectedRow.id;
			}

			newState = { ...newState, config, hash: getValueHash(config) };

			this.setState(newState);

			this.props.onChange(config);
		};

		this.clearColumn = () => {
			// Update column in config
			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, this.state.selectedColumn.rowId);

			if (rowIndex === -1) {
				return;
			}

			const colIndex = findColIndex(config, rowIndex, this.state.selectedColumn.id);

			if (colIndex === -1) {
				return;
			}

			const column = getEmptyCol(this.state.selectedRow, this.props.accountProfile, types.EMPTY);

			config.rows[rowIndex].columns[colIndex] = column;
			config.selectedColumnId = column.id;
			config.selectedRowId = config.rows[rowIndex].id;

			const newState = { config, hash: getValueHash(config), selectedColumn: column };

			this.setState(newState);

			this.props.onChange(config);
		};

		this.changeColumnType = (type, columnProps = {}) => {
			// Update column in config
			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, this.state.selectedColumn.rowId);

			if (rowIndex === -1) {
				return;
			}

			const colIndex = findColIndex(config, rowIndex, this.state.selectedColumn.id);

			if (colIndex === -1) {
				return;
			}
			let column = getEmptyCol(this.state.selectedRow, this.props.accountProfile, type, false, columnProps);
			column.size = this.state.selectedColumn.size;
			column.width = this.state.selectedColumn.width;

			if (this.state.selectedColumn.size === 8 || this.state.selectedColumn.size === 4) {
				column = getEmptyCol(this.state.selectedColumn, this.props.accountProfile, type, true, columnProps);
			}

			config.rows[rowIndex].columns[colIndex] = column;
			config.selectedColumnId = column.id;
			config.selectedRowId = config.rows[rowIndex].id;

			const newState = { config, hash: getValueHash(config), selectedColumn: column };

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onAddRow = (size, atIndex) => {
			const config = { ...this.state.config };
			const row = getEmptyRow();

			if (size === '70/30' || size === '30/70') {
				const col70 = getEmptyCol(row, this.props.accountProfile);
				const col30 = getEmptyCol(row, this.props.accountProfile);
				col30.id = col30.id + 1;
				col70.size = 8;
				col70.width = getColWidthFromSize(col70.size);
				col30.size = 4;
				col30.width = getColWidthFromSize(col30.size);

				if (size === '30/70') {
					row.columns = [col30, col70];
				} else {
					row.columns = [col70, col30];
				}
			} else {
				// Create the array of empty elements before creating the columns or else the width will be wrong
				row.columns = Array.from(Array(12 / size));
				row.columns = row.columns.map((_col, i) => {
					const col = getEmptyCol(row, this.props.accountProfile);
					col.id = col.id + i;
					col.size = size;

					return col;
				});
			}

			config.rows = [...config.rows.slice(0, atIndex), row, ...config.rows.slice(atIndex)];
			config.selectedRowId = row.id;
			config.selectedColumnId = row.columns[0].id;

			const newState = {
				config,
				hash: getValueHash(config),
				selectedRow: row,
				selectedColumn: row.columns[0],
				sidebarVisible: true
			};

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onAddColumn = (rowIndex, atIndex) => {
			try {
				const config = { ...this.state.config };
				const row = config.rows[rowIndex];
				const col = getEmptyCol(row, this.props.accountProfile);
				col.id = Date.now();
				row.columns.splice(atIndex, 0, col);

				row.columns = reflowColumns(row.columns);
				config.selectedRowId = row.id;
				config.selectedColumnId = row.columns[atIndex].id;

				const newState = {
					config,
					hash: getValueHash(config),
					selectedRow: row,
					selectedColumn: row.columns[atIndex],
					sidebarVisible: true
				};

				this.setState(newState);

				this.props.onChange(config);
			} catch (e) {
				logError(e, 'Failed to add column');
				NotificationService.add({
					icon: 'times',
					style: NotificationService.style.ERROR,
					title: 'default.error',
					body: 'mailTemplate.failedToAddColumn'
				});
			}
		};

		this.onRemoveCol = (rowIndex, atIndex) => {
			const config = { ...this.state.config };
			const row = config.rows[rowIndex];

			// if removing last column in row
			if (row.columns.length === 1) {
				return this.onRowDelete(row.id);
			}

			row.columns = reflowColumns(removeItem(row.columns, atIndex));
			config.selectedColumnId = config.selectedRowId = null;

			const newState = {
				config,
				hash: getValueHash(config)
			};

			this.setState(newState, () => {
				this.closeSidebar();
			});

			this.props.onChange(config);
		};

		this.onRowCopy = (rowInput = null, atIndex) => {
			const config = { ...this.state.config };
			const selectedColumn = typeof rowInput.id !== 'object' ? rowInput.id : this.state.selectedColumn.rowId;

			const rowIndex = findRowIndex(config, selectedColumn);
			if (rowIndex === -1) {
				return;
			}

			const row = JSON.parse(JSON.stringify(rowInput));
			row.id = Date.now();

			row.columns.forEach(function setId(column, index) {
				column.id = Date.now() + index;
				column.rowId = row.id;
			});

			config.rows = [...config.rows.slice(0, atIndex), row, ...config.rows.slice(atIndex)];
			config.selectedColumnId = config.selectedRowId = null;

			const newState = {
				config,
				hash: getValueHash(config),
				selectedRow: null,
				selectedColumn: null,
				sidebarVisible: false
			};

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onRowDelete = (rowId = null) => {
			const selectedColumn = typeof rowId !== 'object' ? rowId : this.state.selectedColumn.rowId;

			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, selectedColumn);

			if (rowIndex === -1) {
				return;
			}

			config.rows = [...config.rows.slice(0, rowIndex), ...config.rows.slice(rowIndex + 1)];
			config.selectedColumnId = config.selectedRowId = null;

			const newState = {
				config,
				hash: getValueHash(config),
				selectedColumn: null,
				selectedRow: null,
				sidebarVisible: false
			};

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onRowClear = () => {
			const config = { ...this.state.config };
			const rowIndex = findRowIndex(config, this.state.selectedColumn.rowId);

			if (rowIndex === -1) {
				return;
			}

			config.rows[rowIndex].columns = config.rows[rowIndex].columns.map(c => {
				const col = getEmptyCol(config.rows[rowIndex], this.props.accountProfile);
				col.id = c.id;
				col.size = c.size;

				return col;
			});
			config.selectedColumnId = config.selectedRowId = null;

			const newState = {
				config,
				hash: getValueHash(config),
				selectedColumn: null,
				selectedRow: null,
				sidebarVisible: false
			};

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onRowMove = (sourceIndex, destinationIndex) => {
			const config = { ...this.state.config };
			const [removed] = config.rows.splice(sourceIndex, 1);
			config.rows.splice(destinationIndex, 0, removed);

			const newState = {
				config,
				hash: getValueHash(config)
			};

			this.setState(newState);

			this.props.onChange(config);
		};

		this.onColMove = (rowDroppableSourceId, rowDroppableDestinationId, sourceIndex, destinationIndex) => {
			const config = { ...this.state.config };
			// Find source row index
			const sourceRowIndex = config.rows.findIndex(
				r => r.id.toString() === rowDroppableSourceId.replace('col-droppable-', '')
			);

			// Find destination row index
			const destinationRowIndex = config.rows.findIndex(
				r => r.id.toString() === rowDroppableDestinationId.replace('col-droppable-', '')
			);
			// If dropped in the same spot as it was taken from
			if (sourceRowIndex === destinationRowIndex && sourceIndex === destinationIndex) {
				return;
			}

			// Remove column from current place
			const [columnConfig] = config.rows[sourceRowIndex].columns.splice(sourceIndex, 1);

			// If dropped in another row we need to set reference to the new row
			if (sourceRowIndex !== destinationRowIndex) {
				columnConfig.rowId = config.rows[destinationRowIndex].id;

				// Also if this column is selected we need to update selected column and row
				this.selectColumn(columnConfig, config.rows[destinationRowIndex]);
			}

			// Place it in the new spot
			config.rows[destinationRowIndex].columns.splice(destinationIndex, 0, columnConfig);

			// Reflow source row if not empty
			if (config.rows[sourceRowIndex].columns.length) {
				config.rows[sourceRowIndex].columns = reflowColumns(config.rows[sourceRowIndex].columns);
			}

			// Reflow destination row if not same as source
			if (sourceRowIndex !== destinationRowIndex) {
				config.rows[destinationRowIndex].columns = reflowColumns(config.rows[destinationRowIndex].columns);
			}

			// if source row was left empty we remove it, this has to be done after all reflows to keep indexes
			if (!config.rows[sourceRowIndex].columns.length) {
				config.rows.splice(sourceRowIndex, 1);
			}

			// Set new state
			this.setState({
				config,
				hash: getValueHash(config)
			});

			// Trigger change
			this.props.onChange(config);
		};

		this.selectColumn = (selectedColumn, selectedRow = null) => {
			this.setState({
				selectedRow,
				selectedColumn,
				sidebarVisible: true,
				selectedToolsTab: 'column'
			});
		};

		this.onColumnSettingsChanged = column => {
			column.style.self.padding = `${column.vPadding}px ${column.hPadding}px`;
			column.style.self['padding-left'] = `${column.hPadding}px`;
			column.style.self['padding-right'] = `${column.hPadding}px`;
			column.style.self['padding-top'] = `${column.vPadding}px`;
			column.style.self['padding-bottom'] = `${column.vPadding}px`;

			if ([types.IMAGE, types.VIDEO].indexOf(column.type) !== -1) {
				if (!column.userImgWidth) {
					column.userImgWidth = column.imgWidth;
				}
				column.imgWidth = getImageWidth(column);
			}

			if (column.enableCellBackground === true && !column.backgroundColor) {
				column.backgroundColor = '#ffffff';
			}

			return this.onColumnChange(column);
		};

		this.openSidebar = () => {
			this.setState({
				sidebarVisible: true,
				selectedRow: null,
				selectedColumn: { id: 'header' }
			});
		};
		this.closeSidebar = () => {
			this.setState({
				sidebarVisible: false,
				selectedRow: null,
				selectedColumn: null
			});
		};
	}
}

export default MailContentEditorHoc;

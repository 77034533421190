'use strict';

ReactTemplates.upStepInt = window.ReactCreateClass({
	chart: null,
	holding: false,
	holdTimeout: null,
	getInitialState: function () {
		return {
			value: this.props.value
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ value: nextProps.value });
	},
	getSafeValue: function (value) {
		if (this.props.max !== undefined) {
			value = Math.min(this.props.max, value);
		}
		if (this.props.min !== undefined) {
			value = Math.max(this.props.min, value);
		}

		return value;
	},
	mouseDown: function (upDown) {
		var self = this;
		self.holding = true;

		self.holdTimeout = setTimeout(function () {
			if (upDown === '-') {
				self.setValueHoldDown(self.state.value - 1);
			} else {
				self.setValueHoldUp(self.state.value + 1);
			}
		}, 50);
	},
	mouseUp: function () {
		this.holding = false;
		if (this.holdTimeout) {
			clearTimeout(this.holdTimeout);
		}
		this.valueChange({ target: { value: this.state.value } });
	},
	setValue: function (value) {
		this.valueChange({ target: { value: value } });
	},
	setValueHoldDown: function (value) {
		var self = this;
		self.valueChange({ target: { value: value } });

		self.holdTimeout = setTimeout(function () {
			self.setValueHoldDown(value - 1);
		}, 30);
	},
	setValueHoldUp: function (value) {
		var self = this;
		self.valueChange({ target: { value: value } });

		self.holdTimeout = setTimeout(function () {
			self.setValueHoldUp(value + 1);
		}, 30);
	},
	valueChange: function (e) {
		var val = this.getSafeValue(e.target.value);
		if (val === this.state.value) {
			return;
		}
		this.setState({ value: val });

		if (!this.holding) {
			this.props.onChange(val);
		}
	},
	render: function () {
		var classes = 'input-sm input-group up-step-int';
		if (this.props.className) {
			classes += ' ' + this.props.className;
		}
		return (
			<div className={classes}>
				<span className="input-group-btn">
					<button
						className="btn up-btn btn-light-grey no-shadow minus-btn"
						type="button"
						tabIndex="-1"
						onClick={this.setValue.bind(this, parseInt(this.state.value) - 1)}
						onMouseDown={this.mouseDown.bind(this, '-')}
						onMouseUp={this.mouseUp}
						onMouseOut={this.mouseUp}
					>
						-
					</button>
				</span>

				<input type="number" className="form-control" value={this.state.value} onChange={this.valueChange} />

				<span className="input-group-btn">
					<button
						className="btn up-btn btn-light-grey no-shadow plus-btn"
						type="button"
						tabIndex="-1"
						onClick={this.setValue.bind(this, parseInt(this.state.value) + 1)}
						onMouseDown={this.mouseDown.bind(this, '+')}
						onMouseUp={this.mouseUp}
						onMouseOut={this.mouseUp}
					>
						+
					</button>
				</span>
			</div>
		);
	}
});

'use strict';

angular.module('domain.activity').controller('MoveOrCloseActivity', [
	'$scope',
	'$modalParams',
	'$translate',
	'NotificationService',
	function ($scope, $modalParams, $translate) {
		$scope.properties = {};

		$scope.save = function () {
			$scope.myForm.$submitted = true;

			const time = $scope.properties.time;
			const date = $scope.properties.Date;
			if (time && date) {
				$scope.properties.Date.setHours(time.getHours(), time.getMinutes(), 0, 0);
			} else if (date) {
				$scope.properties.Date.setHours(0, 0, 0, 0);
			}

			// Fix notes
			if ($scope.properties.notes && $modalParams.notes) {
				$scope.properties.notes = $scope.properties.notes + '\n\n' + $modalParams.notes;
			} else {
				$scope.properties.notes = $modalParams.notes || $scope.properties.notes;
			}
			$scope.resolve($scope.properties);
		};

		$scope.addTime = function (time) {
			$scope.properties.time = time;
		};

		var init = function () {
			$scope.description = $modalParams.description;

			$scope.andCreateFollowUp = $modalParams.isClose
				? $translate.instant('default.and').toLowerCase() +
				  ' ' +
				  $translate.instant('activity.createFolowup').toLowerCase()
				: '';
			$scope.title = $modalParams.isMove
				? $translate.instant('activity.move')
				: $translate.instant('default.closeActivity');
			$scope.submitBtn = $modalParams.isMove
				? $translate.instant('default.move')
				: $translate.instant('default.close');
		};

		init();
	}
]);

'use strict';
angular.module('upAttributes').service('SoliditetClientAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				dunsNo: {
					title: 'tag.account.dunsNo',
					field: 'dunsNo',
					type: FilterType.Number,
					displayType: DisplayType.String,
					sortable: 'dunsNo'
				},
				name: {
					title: 'default.accountName',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					sortable: 'name'
				},
				turnover: {
					title: 'default.turnover',
					field: 'turnover',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'turnover'
				},
				noOfEmployeesExact: {
					title: 'default.employees',
					field: 'noOfEmployeesExact',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'noOfEmployeesExact'
				},
				employeesExact: {
					title: 'default.employees',
					field: 'noOfEmployeesExact',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'noOfEmployeesExact'
				},
				employeesStart: {
					title: 'default.employees',
					field: 'employeesStart',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'employeesStart'
				},
				employeesEnd: {
					title: 'default.employees',
					field: 'employeesEnd',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					sortable: 'employeesEnd'
				},
				headquarters: {
					title: 'default.headquarters',
					field: 'headquarters',
					type: FilterType.Boolean,
					displayType: DisplayType.String
				},
				sniCode: {
					title: 'default.sniCode',
					field: 'sniCode',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				state: {
					title: 'default.state',
					field: 'state',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				status: {
					title: 'default.status',
					field: 'status',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				city: {
					title: 'address.city',
					field: 'city',
					type: FilterType.String,
					displayType: DisplayType.String,
					sortable: 'city'
				},
				zipcode: {
					title: 'default.zipCode',
					field: 'zipcode',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				orgNo: {
					title: 'default.orgNo',
					field: 'orgNo',
					type: FilterType.Number,
					displayType: DisplayType.Number
				},
				country: {
					title: 'address.country',
					field: 'country',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				sniText: {
					title: 'default.companyBranch',
					field: 'sniText',
					type: FilterType.String,
					displayType: FilterType.String,
					sortable: 'sniText'
				},
				rootParentDuns: {
					title: 'default.rootParentDuns',
					field: 'rootParentDuns',
					type: FilterType.Number,
					displayType: DisplayType.String
				}
			};

			// All available filters
			var filters = [];

			var standardFilters = {
				Turnover: {
					type: 'range',
					field: 'turnover'
				},
				Employees: {
					type: 'range',
					field: 'noOfEmployeesExact'
				},
				Country: {
					type: 'text',
					field: 'country'
				},
				Client: {
					type: 'text',
					field: 'name'
				},
				SniCode: {
					type: 'list',
					field: 'sniCode'
				},
				Product: {
					type: 'list',
					field: 'order.product'
				},
				ProductCategory: {
					type: 'list',
					field: 'order.productCategory'
				},
				Role: {
					type: 'list',
					field: 'order.role'
				},
				Date: {
					type: 'dateRange',
					field: 'order.date'
				},
				CompanyType: {
					type: 'list',
					field: 'companyType.id'
				},
				Headquarters: {
					type: 'raw',
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();
							orBuilder.next();
							orBuilder.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, 0);
							orBuilder.next();
							orBuilder.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, 1);
							orBuilder.done();
						} else {
							rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, 1);
						}
					}
				},
				EmployeesRange: {
					type: 'raw',
					toUrl: function (filter) {
						var f = {};

						f.vs = filter.value.start;
						f.ve = filter.value.end;

						return f;
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value.start = rawFilter.vs;
						filter.value.end = rawFilter.ve;
						return filter;
					},
					generate: function () {
						return {
							value: {
								start: null,
								end: null
							}
						};
					},
					build: function (filter, rb) {
						if (filter.value) {
							rb.addFilter(
								{ field: 'noOfEmployeesStart' },
								rb.comparisonTypes.GreaterThanEquals,
								filter.value.start
							);
							rb.addFilter(
								{ field: 'noOfEmployeesEnd' },
								rb.comparisonTypes.LessThanEquals,
								filter.value.end
							);
						}
					}
				},
				ZipCode: {
					type: 'raw',
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v,
							comparisonType: rawFilter.c
						};
					},
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orFilter = rb.orBuilder();
							_.each(filter.value, function (val) {
								orFilter.next();
								orFilter.addFilter({ field: 'zipcode' }, rb.comparisonTypes.WildcardEnd, val);
							});
							orFilter.done();
						}
					}
				},
				CityAndState: {
					type: 'raw',
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orFilter = rb.orBuilder();
							var groupedFilter = _.groupBy(filter.value, 'state');
							_.each(groupedFilter, function (filter) {
								orFilter.next();
								orFilter.addFilter({ field: 'state' }, rb.comparisonTypes.MatchExact, filter[0].state);

								if (filter.length > 1 || filter[0].city) {
									var isNe = filter[0].notEquals;
									var ct = isNe ? rb.comparisonTypes.NotMatchExact : rb.comparisonTypes.MatchExact;
									orFilter.addFilter({ field: 'city' }, ct, _.pluck(filter, 'city'));
								}
							});
							orFilter.done();
						}
					}
				}
			};

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: filters,
				standardFilters: standardFilters,
				keys: {
					ID: 'id',
					NAME: 'name',
					TURNOVER: 'turnover',
					EMPLOYEESEXACT: 'employeesExact',
					EMPLOYEESSTART: 'employeesStart',
					EMPLOYEESEND: 'employeesEnd',
					HEADQUARTERS: 'headquarters',
					SNICODE: 'sniCode',
					STATE: 'state',
					CITY: 'city'
				}
			};
		};
	}
]);

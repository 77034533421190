import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import React from 'react';

const TotalTableRow = ({
	totalSum,
	previousSum,
	showCurrency = false,
	defaultCurrency,
	showPreviousYear = false,
	className = ''
}) => {
	const numberFormat = new NumberFormat();
	const currencyFormat = defaultCurrency ? new CurrencyFormat(defaultCurrency.iso) : numberFormat;
	var formattedTotal = numberFormat.short(Math.round(totalSum));
	var formattedPreviousYear = numberFormat.short(Math.round(previousSum));

	if (showCurrency) {
		formattedTotal = currencyFormat.short(Math.round(totalSum));
		formattedPreviousYear = currencyFormat.short(Math.round(previousSum));
	}

	var cn = '';
	if (showPreviousYear) {
		cn = 'two-lines';
	}

	return (
		<tr className={`${cn} ${className}`.trim()}>
			<td>
				{formattedTotal}
				{showPreviousYear && <span className="subtitle">{formattedPreviousYear}</span>}
			</td>
		</tr>
	);
};

export default TotalTableRow;

import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import LeadSource from '../../Columns/LeadSource';

import { connect } from 'react-redux';
import {
	Title,
	Text,
	Button,
	ButtonGroup,
	Icon,
	Card,
	CardHeader,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Loader
} from '@upsales/components';
import CodeEditor from '../../CodeEditor';
import { navigateNext } from 'Store/reducers/OnboardingReducer';

const ScriptClass = new Bem('ScriptStep');

const mapStateToProps = state => ({
	visits: state.Onboarding.visits,
	visitsLoading: state.Onboarding.visitsLoading,
	onboarding: state.Onboarding.onboarding,
	currentStepId: state.Onboarding.currentStepId,
	visitScript: state.Onboarding.visitScript
});

const mapDispatchToProps = {
	navigateNext
};

class Script extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			title: T('onboarding.step.script.title'),
			description: T('onboarding.step.script.description'),
			howToInstall: T('onboarding.step.script.howToInstallTitle'),
			copy: T('default.copy'),
			copied: T('default.copiedToClipboard'),
			installedDescription: T('onboarding.step.script.installedDescription'),
			installedTitle: T('onboarding.step.script.installedTitle'),
			external: [
				{
					title: 'Wordpress',
					link:
						'https://support.upsales.com/hc/en-us/articles/360018784334-How-to-install-the-website-tracking-script-to-your-WordPress-website'
				},
				{
					title: T('onboarding.step.script.howToInstall'),
					link:
						'https://support.upsales.com/hc/en-us/articles/360001666047-Setting-up-the-website-visits-tracking-script'
				}
			],
			date: T('default.date'),
			comany: T('default.client'),
			leadScore: T('default.leadScore'),
			leadSource: T('default.leadsource'),
			visitors: T('default.visits'),
			goToList: T('onboarding.step.script.goToList')
		};

		this.state = {
			copied: false
		};

		this.copyCode = this.copyCode.bind(this);
	}

	copyCode() {
		this._hiddenScript.select();

		try {
			document.execCommand('copy');
			this.setState({
				copied: true
			});

			setTimeout(() => this.setState({ copied: false }), 3500);
		} catch (err) {
			console.err('Copy Error', err);
		}
	}

	isCopyButton(stateCopied) {
		if (stateCopied) {
			return (
				<Button>
					<Icon name="check" />
					{this.lang.copied}
				</Button>
			);
		}

		return <Button onClick={this.copyCode}>{this.lang.copy}</Button>;
	}

	goToVisitList() {
		Tools.$state.go('visitors', {});
	}

	isFinishedContent() {
		const { visits, visitsLoading } = this.props;
		const lang = this.lang;

		let rows;

		if (visitsLoading) {
			rows = (
				<TableRow key="loading-row">
					<TableColumn colSpan={4}>
						<Loader noU={true} size="xs" />
					</TableColumn>
				</TableRow>
			);
		} else {
			rows = visits.map(visit => {
				return (
					<TableRow key={visit.id}>
						<TableColumn title={moment(visit.startDate).format('YYYY-MM-DD')} />
						<TableColumn>
							{visit.client ? (
								<ReactTemplates.TOOLS.accountHref id={visit.client.id} text={visit.client.name} />
							) : null}
						</TableColumn>
						<TableColumn title={visit.score} />
						<TableColumn className="TableColumn--lead-source">
							<LeadSource visit={visit} />
						</TableColumn>
					</TableRow>
				);
			});
		}

		return (
			<Card>
				<CardHeader title={lang.visitors}>
					<Button onClick={this.goToVisitList}>{lang.goToList}</Button>
				</CardHeader>
				<Table>
					<TableHeader
						columns={[
							{ title: lang.date },
							{ title: lang.comany },
							{ title: lang.leadScore },
							{ title: lang.leadSource }
						]}
					/>
					{rows}
				</Table>
			</Card>
		);
	}

	isNotFinishedContent() {
		return (
			<div>
				<div className={ScriptClass.elem('CodeEditor').b()}>
					<input
						readOnly
						type="text"
						ref={inp => (this._hiddenScript = inp)}
						className="hidden-input"
						value={this.props.visitScript}
					/>
					<CodeEditor readOnly={true} value={this.props.visitScript} />
					{this.isCopyButton(this.state.copied)}
				</div>

				<Title className={ScriptClass.elem('Subtitle').b()}>{this.lang.howToInstall}</Title>
				<ButtonGroup>
					{this.lang.external.map((external, index) => {
						return (
							<Button
								key={index}
								onClick={() => window.open(external.link, '_blank')}
								size="lg"
								shadow="none"
								type="link"
							>
								<Icon name="external-link" />
								{external.title}
							</Button>
						);
					})}
				</ButtonGroup>
			</div>
		);
	}

	render() {
		const { onboarding, currentStepId } = this.props;

		const currentStep = _.find(onboarding.steps, { id: currentStepId });
		const isFinished = (currentStep && currentStep.completed) || false;
		const title = isFinished ? this.lang.installedTitle : this.lang.title;
		const description = isFinished ? this.lang.installedDescription : this.lang.description;
		const content = isFinished ? this.isFinishedContent() : this.isNotFinishedContent();

		return (
			<div className={ScriptClass.b()}>
				<Title>{title}</Title>
				<Text>{description}</Text>
				{content}
			</div>
		);
	}
}

Script.propTypes = {
	visits: PropTypes.array,
	visitsLoading: PropTypes.bool,
	onboarding: PropTypes.object,
	currentStepId: PropTypes.number,
	visitScript: PropTypes.string,
	navigateNext: PropTypes.func
};

export const detached = Script;
export default {
	default: connect(mapStateToProps, mapDispatchToProps)(Script)
};

'use strict';

angular.module('upResources').factory('StandardMailTemplate', [
	'$resource',
	'$q',
	'URL',
	'API',
	'MailTemplateAttributes',
	'AppService',
	'ParseGeneric',
	function StandardMailTemplate($resource, $q, URL, API, MailTemplateAttributes, AppService, ParseGeneric) {
		var Attributes = MailTemplateAttributes().attr;
		var Keys = MailTemplateAttributes().keys;

		var Resource = $resource(
			URL + API + 'standardMailTemplate/:id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseStandardMailTemplate', { isArray: false, custom: false })
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseStandardMailTemplate', { isArray: true, custom: false })
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				var params = angular.extend(filter || {}, options);
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			attr: Attributes,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

angular.module('upResources').factory('Form', [
	'$resource',
	'URL',
	'API',
	'FormAttributes',
	'ResourceHelper',
	'$translate',
	'ParseGeneric',
	function Form($resource, URL, API, FormAttributes, ResourceHelper, $translate, ParseGeneric) {
		var FSA = FormAttributes();
		var Attribute = FSA.attr;
		var Keys = FSA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: function (obj) {
				return !obj.landingPageBody ? 'form' : 'landingpage';
			},
			createSuccessBody: function (obj) {
				return !obj.landingPageBody ? 'saved.form' : 'saved.landingpage';
			},
			updateSuccessBody: function (obj) {
				return !obj.landingPageBody ? 'updated.form' : 'updated.landingpage';
			},
			deleteSucccessBody: 'deleted.form',
			createErrorBody: 'saveError.form',
			updateErrorBody: 'saveError.form',
			deleteErrorBody: 'deleteError.form'
		});

		var dateFields = ['regDate', 'lastSubmitDate'];

		var Resource = $resource(
			URL + API + 'forms/:id',
			{},
			{
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseForm', { isArray: true, dateFields: dateFields }),
					skipDateConvert: true
				},
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric('ParseForm', { isArray: false, dateFields: dateFields }),
					skipDateConvert: true
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric('ParseForm', { isArray: false, dateFields: dateFields }),
					skipDateConvert: true
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric('ParseForm', { isArray: false, dateFields: dateFields }),
					skipDateConvert: true
				}
			}
		);

		var externalLeadSourceScript = $resource(URL + API + 'forms/external-lead-source?href=:href', {}, {});

		var getAPColor = function (accountProfile, key, defaultVal) {
			if (!accountProfile) {
				return defaultVal;
			}
			return accountProfile.colors[key];
		};

		var Model = {
			new: function (accountProfile) {
				var form = {
					title: '',
					name: '',
					description: '',
					landingPage: '',
					buttonText: $translate.instant('mail.send'),
					thankYouTitle: $translate.instant('form.thanksPage.title'),
					thankYouTitleOnDemand: $translate.instant('form.thanksPage.title'),
					thankYouText: $translate.instant('form.thanksPage.description'),
					thankYouTextOnDemand: $translate.instant('form.thanksPage.description'),
					thankYouElement: null,
					thankYouElementOnDemand: null,
					buttonBgColor: getAPColor(accountProfile, 'buttonBg', '#080808'),
					buttonTextColor: getAPColor(accountProfile, 'buttonText', '#ffffff'),
					backgroundColor: getAPColor(accountProfile, 'contentBg', '#ffffff'),
					textColor: getAPColor(accountProfile, 'contentText', '#000000'),
					linkColor: getAPColor(accountProfile, 'linkText', '#4A90E2'),
					user: null,
					score: 40,
					views: 0,
					redirect: 0,
					showTitle: 1,
					fields: [
						{
							name: 'Contact.email',
							title: $translate.instant('form.Contact.email'),
							required: 1,
							datatype: null,
							options: null,
							sort: 3,
							properties: [],
							formId: 4
						},
						{
							name: 'Client.name',
							title: $translate.instant('form.Client.name'),
							required: 1,
							datatype: null,
							options: null,
							sort: 4,
							properties: [],
							formId: 4
						},
						{
							title: $translate.instant('form.Prefs.reCaptchaFake'),
							name: 'Prefs.reCaptcha',
							type: 'checkbox',
							sort: 5
						}
					],
					actions: [],
					submits: 47,
					landingPageBody: null,
					html: null,
					userRemovable: true,
					userEditable: true,
					socialMediaTags: null,
					domain: null,
					urlName: null
				};

				var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

				if (hasNewFields) {
					form.fields.unshift({
						name: 'Contact.lastname',
						title: $translate.instant('form.Contact.lastname'),
						required: 1,
						datatype: null,
						options: null,
						sort: 2,
						properties: [],
						formId: 4
					});
					form.fields.unshift({
						name: 'Contact.firstname',
						title: $translate.instant('form.Contact.firstname'),
						required: 1,
						datatype: null,
						options: null,
						sort: 1,
						properties: [],
						formId: 4
					});
				} else {
					form.fields.unshift({
						name: 'Contact.name',
						title: $translate.instant('form.Contact.name'),
						required: 1,
						datatype: null,
						options: null,
						sort: 1,
						properties: [],
						formId: 4
					});
				}

				return form;
			},
			find: function (filter, options) {
				var params = angular.extend(filter, options);
				params.includePrefs = true;
				return Resource.find(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id, includePrefs: true }).$promise;
			},

			save: function (form, options) {
				var promise;

				if (form.id) {
					promise = Resource.update({ id: form.id }, form).$promise;
				} else {
					promise = Resource.create(form).$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !form.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !form.id);
					});

				return promise;
			},

			getExternalLeadSourceScript: function (href) {
				return externalLeadSourceScript.get({ href: href }).$promise.then(function (data) {
					return data;
				});
			},

			delete: function (form, options) {
				return Resource['delete']({ id: form.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, form);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			attr: Attribute,
			keys: Keys
		};

		Model.archive = function (id, archive) {
			var body = 'form.formArchived';
			if (!archive) {
				body = 'form.formUnarchived';
			}
			return Model.save(
				{ id: id, isArchived: archive },
				{
					updateSuccessBody: body
				}
			);
		};

		return Model;
	}
]);

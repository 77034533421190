'use strict';

angular.module('upResources').service('MapHelpers', function () {
	var Instance = {};

	var mapper = {
		number: function (value, attr) {
			var num = value === null || value === '' ? 0 : parseInt(value);
			if (isNaN(num)) {
				throw new Error("'" + value + "' is not a number for " + attr.field);
			}
			return num;
		},
		float: function (value, attr) {
			var num = value === null ? 0 : parseFloat(value);
			if (isNaN(num)) {
				throw new Error("'" + value + "' is not a float for " + attr.field);
			}
			return num;
		},
		string: function (value) {
			value = value === null || value === undefined ? '' : value;
			return value.toString();
		},
		boolean: function (value, attr) {
			value = Boolean(value);
			if (typeof value !== 'boolean') {
				throw new Error("'" + value + "' is not a valid boolean for field" + attr.field);
			}
			return value;
		},
		reference: function (obj, attr) {
			var id = obj !== null && obj.hasOwnProperty('id') ? obj.id : obj;
			/* eslint-disable eqeqeq */
			if (obj.id == undefined) {
				/* eslint-enable eqeqeq */
				return null;
			} else if (obj.id && (isNaN(id) || id === null)) {
				throw new Error('Missing id for', attr.field, 'in mapper.', null);
			} else {
				var mappedObj = {};

				if (typeof id === 'number') {
					var num = parseInt(id);
					mappedObj.id = num;
				}

				angular.forEach(Object.keys(obj), function (key) {
					var attribute = attr.attr[key];

					if (!attribute) {
						return;
					}

					var value = obj[key];

					var type = attribute ? attribute.type.toLowerCase() : null;

					if (attribute && attribute.skipMap) {
						return;
					}

					if (Array.isArray(value)) {
						var mappedArray = [];
						angular.forEach(value, function (val) {
							mappedArray.push(mapper[type](val, attribute));
						});
						mappedObj[key] = mappedArray;
					} else if (attribute !== undefined && typeof mapper[attribute.type.toLowerCase()] === 'function') {
						mappedObj[key] = mapper[type](value, attribute);
					}
				});

				return mappedObj;
			}
		}
	};

	Instance.mapCategories = function (obj) {
		if (obj.hasOwnProperty('$standardCategories')) {
			obj.categories = obj.$standardCategories;
			obj.$extraCategories = obj.$extraCategories || [];
			_.forEach(obj.$extraCategories, function (categories) {
				// Mash all category types back together
				obj.categories = _.union(obj.categories, categories);
			});
		}
	};

	Instance.mapCustom = function (fields) {
		var mappedFields = [];
		angular.forEach(fields, function (field) {
			// Only Selects can be saved as null
			if (_.isNull(field.value) && field.datatype === 'Select') {
				mappedFields.push({ fieldId: field.id || field.fieldId, value: field.value });
				return;
			}

			// To clear integers, set value to null
			if (_.includes(['Integer', 'Currency', 'Discount', 'User'], field.datatype) && _.isNull(field.value)) {
				mappedFields.push({ fieldId: field.id || field.fieldId, value: null });
				return;
			}

			if (field.datatype === 'Users' && _.isEmpty(field.value)) {
				mappedFields.push({ fieldId: field.id || field.fieldId, value: null });
				return;
			}

			if (field.datatype === 'Integer' && isNaN(field.value)) {
				return;
			}

			if (!_.isUndefined(field.value) && !_.isNull(field.value)) {
				mappedFields.push({ fieldId: field.id || field.fieldId, value: field.value });
			}
		});
		return mappedFields;
	};

	Instance.map = function (obj, attributes) {
		var mapped = {};

		angular.forEach(Object.keys(obj), function (key) {
			// Do not send attr-less keys
			if (attributes[key] === undefined) {
				return;
			}

			var attribute = attributes[key];

			// CustomFields are mapped separately
			if (key === 'custom' && attribute !== undefined) {
				mapped[key] = obj[key];
				return;
			}

			// Check for reference object
			if (attribute.attr !== undefined && obj[key]) {
				// if multiple
				if (Array.isArray(obj[key])) {
					mapped[attribute.parent] = [];
					angular.forEach(obj[key], function (item) {
						mapped[attribute.parent].push(mapper.reference(item, attribute));
					});
				} else {
					mapped[attribute.parent] = mapper.reference(obj[key], attribute);
				}
			} else if (attribute.type && typeof mapper[attribute.type.toLowerCase()] === 'function') {
				var type = attribute.type.toLowerCase();
				mapped[key] = mapper[type](obj[key], attribute);
			} else {
				mapped[key] = obj[key];
			}
		});

		return mapped;
	};

	return Instance;
});

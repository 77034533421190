import PropTypes from 'prop-types';

const tier = PropTypes.shape({
	start: PropTypes.number,
	end: PropTypes.number,
	price: PropTypes.number,
	isTotalPrice: PropTypes.bool
});

export default PropTypes.shape({
	alias: PropTypes.string.isRequired,
	basedOn: PropTypes.string,
	currency: PropTypes.string.isRequired,
	description: PropTypes.string,
	name: PropTypes.string,
	price: PropTypes.number,
	quantity: PropTypes.number,
	readMoreHref: PropTypes.string,
	tier,
	tiers: PropTypes.arrayOf(tier),
	totalCost: PropTypes.number,
	type: PropTypes.oneOf(['tiered', 'quantity_based']),
	priceMonthPeriod: PropTypes.number,
	productInterval: PropTypes.number
});

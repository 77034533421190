import colorMappings from '@upsales/components/Utils/colorMappings';
import MailBounceLabel from 'App/babel/components/Misc/MailBounceLabel';

ReactTemplates.general = {};
ReactTemplates.general.FollowUp = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			text: t('mailCampaign.followUp')
		};
	},
	render: function () {
		return (
			<div className="follow-up">
				<h3>{this.lang.text}</h3>
			</div>
		);
	}
});

ReactTemplates.TIMELINE = {};
ReactTemplates.TIMELINE.root = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			timelineHeader_date: t('tag.activity.date'),
			timelineHeader_contactPerson: t('tag.contact'),
			timelineHeader_client: t('default.account'),
			noResult: t('noResult.generic'),
			timelineHeader_marketActivity: t('default.marketActivity')
		};
	},
	render: function () {
		var loadingRow = null;
		var tools = this.props.tools;

		var events = _.map(this.props.events, function (event, key) {
			if (event.type !== 'send') {
				return <ReactTemplates.TIMELINE.event key={key} type={event.type} data={event} tools={tools} />;
			}
		});

		var hasEvents = events.length > 0;

		if (!hasEvents) {
			events = [
				<tr key="no-events" className="no-result-row">
					<td className="cursor-default" colSpan={5} style={{ paddingTop: '20px' }}>
						<img style={{ width: '70px' }} src="img/empty-briefcase.png" />
						<br />
						<h2 style={{ marginTop: '20px' }}>{this.lang.noResult}</h2>
					</td>
				</tr>
			];
		}

		if (this.props.loading) {
			loadingRow = (
				<tr id="load-row">
					<td colSpan={5}>{React.createElement(ReactTemplates.loader, {})}</td>
				</tr>
			);
		}

		var paginator = null;
		var totalPages = this.props.totalPages;
		if (totalPages > 1) {
			paginator = React.createElement(ReactTemplates.TOOLS.Paginator, {
				currentPage: this.props.activePage,
				max: totalPages,
				onChange: this.props.onChangePage
			});
		}

		return (
			<div>
				<table style={{ tableLayout: 'fixed', width: '100%' }} className="timeline">
					<ReactTemplates.TIMELINE.thead
						key="theaddder"
						columns={[
							{
								thclass: 'col1'
							},
							{
								v: hasEvents ? this.lang.timelineHeader_date : '',
								class: 'time',
								thclass: 'col2'
							},
							{
								v: hasEvents ? this.lang.timelineHeader_contactPerson : '',
								class: 'uppercase-fontwight400',
								thclass: 'col3'
							},
							{
								thclass: 'col4',
								v: hasEvents ? this.lang.timelineHeader_client : '',
								class: 'uppercase-fontwight400'
							},
							{
								thclass: 'col5',
								v: hasEvents ? this.lang.timelineHeader_marketActivity : '',
								class: 'uppercase-fontwight400'
							}
						]}
					/>
					<tbody>
						{loadingRow}
						{!this.props.loading ? events : null}
					</tbody>
					{hasEvents ? (
						<ReactTemplates.TIMELINE.sendToData data={this.props.maildata} tools={this.props.tools} />
					) : null}
				</table>
				{paginator}
			</div>
		);
	}
});

ReactTemplates.TIMELINE.thead = window.ReactCreateClass({
	getColumnsFromProps: function (columns) {
		return _.map(columns, function (col, i) {
			if (typeof col === 'string') {
				return (
					<th className={col.thclass} key={i}>
						{col}
					</th>
				);
			}

			if (!col.v) {
				return <th className={col.thclass} key={i} />;
			}

			if (col.class) {
				if (col.child) {
					if (col.child.class) {
						return (
							<th className={col.thclass} key={i}>
								<div className={col.class}>{col.v}</div>
								<div className={col.child.class}>{col.child.v}</div>
							</th>
						);
					} else {
						return (
							<th className={col.thclass} key={i}>
								<div className={col.class}>{col.v}</div>
								{col.child.v}
							</th>
						);
					}
				} else {
					return (
						<th className={col.thclass} key={i}>
							<div className={col.class}>{col.v}</div>
						</th>
					);
				}
			} else {
				if (col.child) {
					if (col.child.class) {
						return (
							<th className={col.thclass} key={i}>
								{col.v}
								<div className={col.child.class}>{col.child.v}</div>
							</th>
						);
					} else {
						return (
							<th className={col.thclass} key={i}>
								{col.v}
								{col.child.v}
							</th>
						);
					}
				} else {
					return (
						<th className={col.thclass} key={i}>
							{col.v}
						</th>
					);
				}
			}
		});
	},

	render: function () {
		var columns = this.getColumnsFromProps(this.props.columns);

		return (
			<thead key="thead">
				<tr key="thead-tr">{columns}</tr>
			</thead>
		);
	}
});

ReactTemplates.TIMELINE.sendToData = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			sentTo: t('default.sentTo').toLowerCase(),
			contacts: t('default.contacts2').toLowerCase()
		};

		this.tags = {
			'Client.UserName': t('account.accountManagerForTheCompany')
		};
	},
	parseTag: function (str) {
		if (str.indexOf('{{') === 0 && str.indexOf('}}') === str.length - 2) {
			return this.tags[str.replace('{{', '').replace('}}', '')] || str;
		}

		return str;
	},
	render: function () {
		if (!this.props.data) {
			return (
				<tfoot>
					<tr>
						<td />
					</tr>
				</tfoot>
			);
		}

		var date = moment(this.props.data.sendDate).format('L LT');
		var from = this.parseTag(this.props.data.from);

		return (
			<tfoot>
				<tr>
					<ReactTemplates.TIMELINE.iconTd type={'sender'} />
					<td colSpan={4}>
						{from + ' ' + this.lang.sentTo + ' ' + this.props.data.total + ' ' + this.lang.contacts}
						<br />
						{date}
					</td>
				</tr>
			</tfoot>
		);
	}
});

ReactTemplates.TIMELINE.iconTd = window.ReactCreateClass({
	render: function () {
		var colors = {
			formsubmit: colorMappings.get('purple'),
			click: colorMappings.get('purple'),
			unsub: colorMappings.get('red'),
			sender: '#999',
			reject: '#999',
			soft_bounce: '#999',
			hard_bounce: '#999',
			visit: colorMappings.get('purple')
		};
		var divClasses = 'icon';
		var iClasses = 'fa fa-fw';

		switch (this.props.type) {
			case 'open':
				divClasses += '';
				iClasses += ' fa-eye';
				break;

			case 'click':
				divClasses += ' click';
				iClasses += ' fa-mouse-pointer';
				break;

			case 'formsubmit':
				divClasses += ' formsubmit';
				iClasses += ' fa-wpforms';
				break;

			case 'visit':
				iClasses += ' fa-globe';
				break;

			case 'unsub':
			case 'soft_bounce':
			case 'hard_bounce':
				divClasses += '';
				iClasses += ' fa-exclamation-triangle';
				break;

			case 'sender':
				divClasses += '';
				iClasses += ' fa-paper-plane';
				break;

			case 'reject':
				divClasses += '';
				iClasses += ' fa-times';
		}

		return (
			<td key={iClasses + '-' + divClasses} className="icon-td cursor-default">
				<div
					className={divClasses}
					style={{
						backgroundColor: colors[this.props.type] ? colors[this.props.type] : '#fff',
						color: colors[this.props.type] ? '#fff' : colorMappings.get('purple')
					}}
				>
					<i
						className={iClasses}
						style={{
							color: colors[this.props.type] ? '#fff' : colorMappings.get('purple')
						}}
					/>
				</div>
				<div className="line" />
			</td>
		);
	}
});
ReactTemplates.TIMELINE.event = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			mailActions: {
				click: t('mail.clickedInMail').toLowerCase(),
				formsubmit: t('event.form.submited2').toLowerCase(),
				open: t('mailCampaign.openedTheMail').toLowerCase(),
				visit: t('visitor.didVisited').toLowerCase(),
				unsub: t('default.unsubscribedFrom').toLowerCase(),
				unsubAll: t('mail.mailUnsubscribed').toLowerCase()
			},
			at: t('default.at').toLowerCase(),
			notDelivered: t('mail.notDelivered'),
			missingEmail: t('mail.missingEmail'),
			invalidEmail: t('mail.invalidEmail'),
			prevBounce: t('event.prevBounce'),
			duplicate: t('default.thisIsADuplicate'),
			hard_bounce: t('event.contacthard_bounce').toLowerCase(),
			soft_bounce: t('event.contactsoft_bounce').toLowerCase(),
			to: t('mail.to').toLowerCase(),
			theMail: t('mail.theMail'),
			pages: t('visitor.pages').toLowerCase(),
			page: t('visitor.page').toLowerCase(),
			bounced: t('mail.bounced').toLowerCase()
		};
		this.hasBounceReason = Tools.FeatureHelper.hasSoftDeployAccess('BOUNCE_REASONS');
	},

	visitMsg: function (visit, type) {
		if (visit.pageCount > 1) {
			return (
				this.lang.mailActions[type] +
				' ' +
				visit.pageCount +
				' ' +
				this.lang.pages +
				' ' +
				this.lang.at +
				' ' +
				visit.domain
			);
		}
		return (
			this.lang.mailActions[type] +
			' ' +
			visit.pageCount +
			' ' +
			this.lang.page +
			' ' +
			this.lang.at +
			' ' +
			visit.domain
		);
	},

	mailMsg: function (mail, type) {
		if (type === 'unsub') {
			if (mail.groupMailCategoryTitle) {
				return this.lang.mailActions.unsub + ' ' + mail.groupMailCategoryTitle;
			}
			return this.lang.mailActions.unsubAll;
		}

		return this.lang.mailActions[type];
	},

	render: function () {
		var colors = {
			formsubmit: colorMappings.get('purple'),
			click: colorMappings.get('purple'),
			visit: colorMappings.get('purple'),
			soft_bounce: '#999',
			hard_bounce: '#999'
		};
		var data = this.props.data;
		var type = this.props.type;
		var isBounce;

		if (!data) {
			return (
				<tr key="unknown" className="unknown">
					<td />
				</tr>
			);
		}

		if (type === 'hard_bounce' || type === 'soft_bounce') {
			const bounceReason = data?.mail?.bounceReason;
			if (bounceReason && this.hasBounceReason) {
				isBounce = (
					<>
						<span>{`${this.lang.theMail} ${this.lang.bounced}   `}</span>
						<MailBounceLabel type={type} reason={bounceReason} />
						<span>{'   ' + this.lang.to + ' '}</span>
					</>
				);
			} else {
				isBounce = <span>{this.lang.theMail + ' ' + this.lang[type] + ' ' + this.lang.to + ' '}</span>;
			}
		}

		return (
			<tr key={type + '-' + data.client.id}>
				<ReactTemplates.TIMELINE.iconTd type={type} />
				<td className="cursor-default">
					<div
						className="time"
						style={{ borderLeft: colors[type] ? '3px solid ' + colors[type] : '1px solid #eee' }}
					>
						<div className="valign-middle">
							{ReactTemplates.TOOLS.dateCalendar(data.date, 'L LT', true)}
						</div>
					</div>
				</td>
				<td className="cursor-default">
					<div className="timeline-column">
						<div className="valign-middle text-ellipsis">
							{isBounce}
							<ReactTemplates.TOOLS.contactHref
								customerId={this.props.tools.AppService.getCustomerId()}
								id={data.contact ? data.contact.id : '0'}
								text={data.contact ? data.contact.name : data.to}
								tools={this.props.tools}
								style={{ display: 'block' }}
							/>{' '}
							{type === 'visit' ? this.visitMsg(data.visit, type) : this.mailMsg(data.mail, type)}
						</div>
					</div>
				</td>
				<td key="client" className="cursor-default">
					<div
						style={{ lineHeight: '18px', paddingTop: '6px', paddingRight: '20px' }}
						className="timeline-column text-ellipsis"
					>
						<ReactTemplates.TOOLS.accountHref
							customerId={this.props.tools.AppService.getCustomerId()}
							id={data.client ? data.client.id : '0'}
							text={data.client ? data.client.name : ''}
							tools={this.props.tools}
						/>
						<br />
						<ReactTemplates.TOOLS.leadStatus
							tools={this.props.tools}
							activity={data.client ? data.client.hasActivity : null}
							activityOld={data.client ? data.client.hadActivity : null}
							appointment={data.client ? data.client.hasAppointment : null}
							appointmentOld={data.client ? data.client.hadAppointment : null}
							order={data.client ? data.client.hasOrder : null}
							orderOld={data.client ? data.client.hadOrder : null}
							opportunity={data.client ? data.client.hasOpportunity : null}
							opportunityOld={data.client ? data.client.hadOpportunity : null}
						/>
					</div>
				</td>
				<td key="marketActivity" className="cursor-default">
					<div className="timeline-column last-column">
						{data.contact ? (
							<ReactTemplates.TOOLS.eventTypes
								account={data.contact}
								theKey="marketActivity"
								tools={this.props.tools}
							/>
						) : null}
					</div>
				</td>
			</tr>
		);
	}
});

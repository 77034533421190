import React from 'react';
import { connect } from 'react-redux';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { SalesCoachChecklistItem } from 'App/resources/Model/SalesCoach';
import { SalesCoachChecklistItem as ChecklistOption } from 'Resources/SalesCoachChecklistItems';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Fade } from 'App/components/animations/index';
import Stages from '../SalesCoachModal/Stages';
import { Text, Block, ButtonBox, Title, Card, Tooltip, Flex } from '@upsales/components';
import { removeItem } from 'Store/helpers/array';
import { setChecklistItem } from 'Store/reducers/SalesCoachReducer';
import ChecklistItemSelect from './ChecklistItemSelect';
import OrderStage from 'App/resources/Model/OrderStage';

import './ChecklistItem.scss';

type ChecklistItemProps = {
	reject: () => void;
	setChecklistItem: (checklistItem: SalesCoachChecklistItem) => void;
	checklistItem: SalesCoachChecklistItem;
	checklistItems: ChecklistOption[];
};

type ChecklistItemState = {
	checklistItem: SalesCoachChecklistItem;
	entireSalesProcess: boolean;
};

const mapDispatchToProps = {
	setChecklistItem
};

const mapStateToProps = (state: any) => ({
	checklistItems: state.SalesCoachReducer.checklistItems
});

class ChecklistItem extends React.Component<ChecklistItemProps, ChecklistItemState> {
	lang: { [key: string]: string };
	stages: OrderStage[];

	constructor(p: ChecklistItemProps) {
		super(p);
		this.lang = {
			cancel: T('default.cancel'),
			addAChecklistItem: T('admin.newSalesProcess.addAChecklistItem'),
			save: T('default.save'),
			backToChecklist: T('admin.newSalesProcess.backToChecklist'),
			next: T('default.next'),
			itemTitle: T('admin.newSalesProcess.itemTitle'),
			examplePlaceholder: T('admin.newSalesProcess.examplePlaceholder'),
			itemTitleIsWhatTheUserWillSee: T('admin.newSalesProcess.itemTitleIsWhatTheUserWillSee'),
			specificStage: T('admin.newSalesProcess.specificStage'),
			entireSalesProcess: T('admin.newSalesProcess.entireSalesProcess'),
			atWhatPoint: T('admin.newSalesProcess.atWhatPoint'),
			entireSalesProcessStages: T('admin.newSalesProcess.entireSalesProcessStages'),
			chooseStagesToSave: T('admin.newSalesProcess.chooseStagesToSave'),
			pickATitleToSave: T('admin.newSalesProcess.pickATitleToSave')
		};
		const checklistItem = { ...this.props.checklistItem };

		this.state = {
			checklistItem,
			entireSalesProcess: checklistItem.stages.length ? false : true
		};
		this.stages = Tools.AppService.getStages();
	}

	changeTitle = (title: string) => {
		this.setState({ checklistItem: { ...this.state.checklistItem, title } });
	};

	toggleEntireSalesProcess = () => {
		const checklistItem = { ...this.state.checklistItem, stages: [] };
		this.setState({ entireSalesProcess: !this.state.entireSalesProcess, checklistItem });
	};

	flipSelectedStages = (stageId: number) => {
		const stages = this.state.checklistItem.stages;
		const idx = stages.findIndex(stage => stage.stageId === stageId);

		if (idx !== -1) {
			const checklistItem = { ...this.state.checklistItem, stages: removeItem(stages, idx) };
			this.setState({ checklistItem });
		} else {
			const stage = this.stages.find(stage => stage.id === stageId);
			const name = stage?.name || '';

			const checklistItem = {
				...this.state.checklistItem,
				stages: [...stages, { stageId, name, required: false }]
			};
			this.setState({ checklistItem });
		}
	};

	save = () => {
		const allStages = this.stages;

		const selectedStages = this.state.checklistItem.stages;

		const idsToInclude = new Set(selectedStages.map(a => a.stageId));
		const filteredStages = allStages.filter(a => idsToInclude.has(a.id));
		const sortedStages = filteredStages.map(a => ({ stageId: a.id, name: a.name, required: false }));

		const checklistItem = {
			...this.state.checklistItem,
			stages: sortedStages
		};

		this.props.setChecklistItem(checklistItem);
		this.props.reject();
	};

	hasNotChosenAnyStages = () => {
		return !this.state.entireSalesProcess && this.state.checklistItem.stages.length === 0;
	};

	render() {
		const classNames = new BemClass('ChecklistItem');

		const { checklistItem, entireSalesProcess } = this.state;

		const { title, stages } = checklistItem;

		const { checklistItems } = this.props;

		const hasTitle = title.length > 0;
		const selected = stages.reduce((res, { stageId, required }) => {
			res[stageId] = { active: true, required: required };
			return res;
		}, {} as any);

		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.addAChecklistItem}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={this.props.reject}
									supertitle={undefined}
									className={undefined}
									noIcon={true}
									next={false}
								/>
								<Tooltip
									title={hasTitle ? this.lang.chooseStagesToSave : this.lang.pickATitleToSave}
									disabled={!this.hasNotChosenAnyStages() && hasTitle}
								>
									<EditorHeaderButton
										title={this.lang.save}
										supertitle={this.lang.backToChecklist}
										className={undefined}
										disabled={!hasTitle || this.hasNotChosenAnyStages()}
										onClick={this.save}
										next={true}
										noIcon={false}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Card>
					<Block className={classNames.elem('content').b()}>
						<Text bold>{this.lang.itemTitle}</Text>
						<ChecklistItemSelect
							className={classNames.elem('input').b()}
							onChange={event => this.changeTitle(event.target.value)}
							onSelect={title => this.changeTitle(title)}
							options={checklistItems}
							value={title}
						/>
						<Fade visible={!hasTitle}>
							<Block space="mts">
								<Text>{this.lang.itemTitleIsWhatTheUserWillSee}</Text>
							</Block>
						</Fade>
						<Fade visible={hasTitle}>
							<Block space="mtxl">
								<Title size="lg">{this.lang.atWhatPoint}</Title>
								<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
									<ButtonBox
										onClick={this.toggleEntireSalesProcess}
										selected={entireSalesProcess}
										title={this.lang.entireSalesProcess}
									/>
									<ButtonBox
										onClick={this.toggleEntireSalesProcess}
										selected={!entireSalesProcess}
										title={this.lang.specificStage}
									/>
								</Flex>
								{entireSalesProcess ? (
									<Block space="mts">
										<Text size="lg" bold={true}>
											{this.lang.entireSalesProcessStages}
										</Text>
									</Block>
								) : null}
								<Fade visible={!entireSalesProcess}>
									<Block space="mtl">
										<Stages
											selected={selected}
											onToggleItem={this.flipSelectedStages}
											showOrderStages
										/>
									</Block>
								</Fade>
							</Block>
						</Fade>
					</Block>
				</Flex>
			</Fade>
		);
	}
}

export const detached = ChecklistItem;

const Component = connect(mapStateToProps, mapDispatchToProps)(ChecklistItem);

export default Component;

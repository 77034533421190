'use strict';

angular.module('upDirectives').directive('notifyError', [
	'$parse',
	'NotificationService',
	'$safeDigest',
	function ($parse, NotificationService, $safeDigest) {
		return {
			restrict: 'A',
			priority: 50, // we need this directive to happen after ng-submit
			require: 'form',
			compile: function compile(element, attrs) {
				// Disable browser native validation
				attrs.$set('novalidate', '');

				return {
					post: function ($scope, $formElement, $attrs, Form) {
						// Add custom attrs to form ctrl
						Form.$submitted = false;
						var scrollElement = null;
						var scrollOffset = attrs.scrollOffset || 0;

						if ($attrs.scrollIn) {
							if ($attrs.scrollIn === 'body') {
								scrollElement = angular.element('body');
							} else {
								scrollElement = $formElement.find($attrs.scrollIn);
							}
						}

						// Unbind ng-submit
						$formElement.off('submit');

						var validate = function (e, justValidate) {
							if ($attrs.beforeSubmit) {
								$parse($attrs.beforeSubmit)($scope);
							}
							// Set submitted flag
							Form.$submitted = true;
							// Check for errors, prevent submit and set form to dirty so the view can display them
							if (Form.$invalid) {
								Form.$dirty = true;
								$safeDigest($scope);

								setTimeout(() => {
									var errors = $formElement.find('.has-error');

									// Scroll to first faulty field
									if (scrollElement) {
										if (errors.length) {
											var posTop =
												errors.first().offset().top -
												scrollElement.offset().top +
												scrollElement.scrollTop();
											scrollElement.animate(
												{
													scrollTop: posTop - scrollOffset
												},
												150
											);
										}
									}

									// Focus field
									errors.first().find('input').focus();
								}, 20);

								var title = $attrs.errorTitle ? $attrs.errorTitle : 'default.error';
								var body = $attrs.errorBody ? $attrs.errorBody : 'default.youHaveFormErrors';

								// Show notification
								NotificationService.addNotification({
									style: NotificationService.style.WARN,
									icon: 'warning',
									title: title,
									body: body
								});

								return false;
							} else {
								// Run original ngSubmit
								Form.$setPristine();
								if (justValidate) {
									return true;
								}
								$parse($attrs.ngSubmit)($scope);
							}
						};

						// Bind new submit event
						$formElement.bind('submit', validate);

						$scope.notifyErrorSubmit = validate;
					}
				};
			}
		};
	}
]);

export default function allowed() {
	const self = Tools.AppService.getSelf();
	if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS)) {
		return false;
	}

	const hasEventAccess = Tools.AppService.getAccessRights().SocialEvent;
	if (!self.userParams.mailAdmin && !self.administrator && !hasEventAccess) {
		return false;
	}

	return true;
}

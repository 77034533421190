import React, { useState, useRef } from 'react';
import { Input, Text, OutsideClick } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import { SalesCoachChecklistItem } from 'Resources/SalesCoachChecklistItems';

import './ChecklistItemSelect.scss';

type Props = {
	options: SalesCoachChecklistItem[];
	onSelect: (title: string) => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	className: string;
};

const ChecklistItemSelect: React.FC<Props> = ({ options, onSelect, className, value, ...props }) => {
	const classes = new Bem('ChecklistItemSelect', className);

	const [expanded, setExpanded] = useState(false);
	const [highlighted, setHighlighted] = useState(-1);
	const dropdown = useRef<HTMLDivElement>(null);

	const title = value;
	const lowerCaseTitle = title.toLowerCase();
	const filteredOptions = options.filter(option => {
		const optionLowerCasedTitle = option.title.toLowerCase();
		return optionLowerCasedTitle.toLowerCase().indexOf(lowerCaseTitle) === 0 && title !== option.title;
	});

	function expand() {
		setHighlighted(-1);
		setExpanded(true);
	}

	function onMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>, option: SalesCoachChecklistItem) {
		event.stopPropagation();
		onSelect(option.title);
	}

	function clamp(value: number) {
		return Math.max(Math.min(value, filteredOptions.length - 1), -1);
	}

	function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (['Tab', 'Enter', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
			const indexOfHighlighted = filteredOptions.findIndex(option => option.id === highlighted);

			switch (event.key) {
				case 'Enter':
				case 'Tab': {
					if (indexOfHighlighted !== -1) {
						event.preventDefault();
						onSelect(filteredOptions[indexOfHighlighted].title);
					}
					break;
				}
				case 'ArrowUp': {
					event.preventDefault();
					const idx = clamp(indexOfHighlighted === -1 ? -1 : indexOfHighlighted - 1);
					setHighlighted(filteredOptions[idx]?.id || -1);
					break;
				}
				case 'ArrowDown': {
					event.preventDefault();
					const idx = clamp(indexOfHighlighted === -1 ? 0 : indexOfHighlighted + 1);
					setHighlighted(filteredOptions[idx]?.id || -1);
					break;
				}
			}
		}
	}

	const rows = filteredOptions.map((option, index) => (
		<div
			key={option.id}
			onMouseDown={event => onMouseDown(event, option)}
			className={classes
				.elem('dropdown-item')
				.mod({ highlighted: option.id === highlighted })
				.b()}
		>
			<Text color={option.id === highlighted ? 'white' : 'black'}>{option.title}</Text>
			<Text size="sm" color={option.id === highlighted ? 'white' : 'grey-11'}>
				{option.salesCoach.name}
			</Text>
		</div>
	));

	return (
		<OutsideClick
			targetRef={() => dropdown.current}
			outsideClick={() => setExpanded(false)}
			className={classes.b()}
		>
			<Input
				{...props}
				value={title}
				onBlur={() => setExpanded(false)}
				onFocus={() => expand()}
				onKeyDown={event => onKeyDown(event)}
			/>
			{expanded && filteredOptions.length ? (
				<div className={classes.elem('dropdown').b()} ref={dropdown}>
					{rows}
				</div>
			) : null}
		</OutsideClick>
	);
};

export default ChecklistItemSelect;

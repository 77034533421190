import ResourceTyped from './ResourceTyped';

export type Contract = {
	id: number;
	name: string;
	version: number;
	body: string;
	type: 'feature';
	admin: boolean;
};

class FeatureContract extends ResourceTyped<Contract> {
	constructor() {
		super('featurecontract');
	}

	accept(contract: Contract, opts: object = {}) {
		return this._postRequest(`${contract.id}/accept`, {}, { ...opts, methodName: 'accept' }).then(res => res.data);
	}
}

const resource = new FeatureContract();

export default resource;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Text, Button } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { SlideFade } from 'App/components/animations';

import './AllRecommendationsApproved.scss';

type Props = {
	flipRecomendations: () => void;
	showRecomendations: boolean;
	checked: JSX.Element[];
	inOrderStage?: boolean;
};

class AllRecommendationsApproved extends React.Component<Props> {
	lang: { [key: string]: string };

	static propTypes = {
		flipRecomendations: PropTypes.func.isRequired,
		showRecomendations: PropTypes.bool.isRequired,
		checked: PropTypes.arrayOf(PropTypes.element).isRequired
	};

	constructor(p: Props) {
		super(p);
		this.lang = {
			onTheFinishLine: T('opportunity.onTheFinishLine'),
			checklistCompleted: T('opportunity.checklistCompleted'),
			tipsForWinningDeals: T('opportunity.tipsForWinningDeals'),
			viewAllRecommendations: T('opportunity.viewAllRecommendations'),
			hideRecommendations: T('opportunity.hideRecommendations')
		};
	}

	render() {
		const { flipRecomendations, showRecomendations, checked } = this.props;
		const classes = new bemClass('AllRecommendationsApproved');
		return (
			<div className={classes.b()}>
				<Card className={classes.elem('header').mod({ open: showRecomendations }).b()}>
					{this.props.inOrderStage ? (
						<div className={classes.elem('header').elem('text').b()}>
							<Text>{this.lang.checklistCompleted}</Text>
						</div>
					) : (
						<>
							<div className={classes.elem('header').elem('greenShape').b()}>
								<img src="img/up-finish-line.png" />
							</div>
							<div className={classes.elem('header').elem('text').mod('left-margin').b()}>
								<Text>{this.lang.onTheFinishLine}</Text>
							</div>
						</>
					)}

					{checked?.length ? (
						<Button type="link" onClick={flipRecomendations}>
							<Text color="grey-10">
								{showRecomendations ? this.lang.hideRecommendations : this.lang.viewAllRecommendations}
							</Text>
							<Icon
								space="mls"
								className={classes
									.elem('header')
									.elem('rotatingIcon')
									.mod({ rotate: showRecomendations })
									.b()}
								name={'chevron-down'}
								color="grey-10"
							></Icon>
						</Button>
					) : null}
				</Card>
				<SlideFade direction="top" visible={showRecomendations} height maxHeight={checked.length * 50}>
					<div>{checked}</div>
				</SlideFade>
			</div>
		);
	}
}

export default AllRecommendationsApproved;

import angular from 'angular';
import parseCategory from 'App/resources/parsers/category';

angular.module('upResources').service('ParseProductCategory', [
	'FeatureHelper',
	function (FeatureHelper) {
		const hasBackendAccess = FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
		return category => parseCategory(category, hasBackendAccess);
	}
]);

import TableHeaderDropdown from 'Components/MultiSelect/TableHeaderDropdown';

ReactTemplates.mailCampaign.table = window.ReactCreateClass({
	getInitialState: function () {
		return {
			drilldownId: null
		};
	},

	UNSAFE_componentWillMount: function () {
		this.lang = {};
		Tools.$multiSelect.setTotal(this.props.total ?? 0);
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	onSort: function (attr) {
		this.props.onSort(attr);
	},

	renderTh: function (col, i) {
		var style = {};
		var arrow = null;
		var onClick = null;
		if (col.width) {
			style.width = col.width + 'px';
		}
		if (col.sortable) {
			style.cursor = 'pointer';
			onClick = this.onSort.bind(this, col.sortable);
			var sortClass = 'sorting-indicator fa';
			if (col.sortable === this.props.currentSorting.attr) {
				sortClass += ' active-sort';
				if (this.props.currentSorting.asc) {
					sortClass += ' fa-sort-alpha-asc';
				} else {
					sortClass += ' fa-sort-alpha-desc';
				}
			} else {
				sortClass += ' fa-sort';
			}
			arrow = <b className={sortClass} />;
		}
		return (
			<th key={'th-' + i} style={style} onClick={onClick}>
				<span>
					{col.title}
					{arrow}
					{col.elevioId ? React.createElement(ReactTemplates.elevio, { articleId: col.elevioId }) : null}
				</span>
			</th>
		);
	},

	closeMultiSelect: function () {
		$(ReactDOM.findDOMNode(this.refs['dropdown'])).removeClass('open').click();
		$('body').trigger('click');
	},
	selectAll: function () {
		this.closeMultiSelect();
		const ids = _.pluck(this.props.rows, 'id');
		Tools.$multiSelect.selectArray(ids);
	},
	selectAllFilter: function () {
		this.closeMultiSelect();
		Tools.$multiSelect.selectAllFilter();
	},
	selectNone: function () {
		this.closeMultiSelect();
		Tools.$multiSelect.selectNone();
	},
	selectToggle: function () {
		if (Tools.$multiSelect.allSelected) {
			this.selectNone();
		} else {
			this.selectAll();
		}
	},

	getMultiselectClass: function () {
		var toggleClass = '';
		if (!Tools.$multiSelect.allSelected && Tools.$multiSelect.selected.length) {
			toggleClass = 'Icon-minus-square-o';
		} else if (Tools.$multiSelect.allSelected || Tools.$multiSelect.allSelectedFilter) {
			toggleClass = 'Icon-check-square';
		} else {
			toggleClass = 'Icon-square-o';
		}
		return toggleClass;
	},

	openDrilldown: function (row) {
		this.setState({ drilldownId: row.id });
	},

	onDrilldown: function (row) {
		if (this.state.drilldownId === row.id) {
			this.setState({ drilldownId: null });
			return;
		}
		this.props.onDrilldown(row, this.openDrilldown.bind(this, row));
	},

	UNSAFE_componentWillReceiveProps: function (props) {
		// close drilldown if data changed
		if (props.dataTimestamp !== this.props.dataTimestamp) {
			this.setState({ drilldownId: null });
		}
		if(props.total !== this.props.total) {
			Tools.$multiSelect.setTotal(props.total ?? 0);
		}
	},

	render: function () {
		var self = this;
		var tools = this.props.tools;

		var rows = [];
		var colLength = this.props.columns.length;
		var checkAllElem = null;
		var total = this.props.total;

		if (self.props.multiActions) {
			colLength++;
			var dropdownTitle;
			if (self.props.rows.length === total) {
				dropdownTitle = <b className={'Icon ' + self.getMultiselectClass()} ref="indicator" />;
				checkAllElem = (
					<div
						id="check-btn"
						className="btn btn-sm"
						disabled={!total ? true : false}
						onClick={self.selectToggle}
					>
						{dropdownTitle}
					</div>
				);
			} else {
				checkAllElem = (
					<TableHeaderDropdown
						items={[
							{
								onClick: self.selectAllFilter,
								text: tools.$translate('default.selectAllResult')
							},
							{
								onClick: self.selectAll,
								text: tools.$translate('default.selectAllOnPage')
							},
							{
								onClick: self.selectNone,
								text: tools.$translate('default.deselectAll')
							}
						]}
						selected={tools.$multiSelect.allSelected || tools.$multiSelect.allSelectedFilter}
					/>
				);
			}
		}

		var loaderRow = null;
		if (self.props.loading) {
			loaderRow = (
				<tr id="load-row">
					<td colSpan={colLength}>{React.createElement(ReactTemplates.loader, {})}</td>
				</tr>
			);
		} else {
			rows = [];
			if (self.props.renderRow) {
				_.each(self.props.rows, function (row, i) {
					rows.push(
						self.props.renderRow(
							row,
							i,
							self.onDrilldown.bind(self, row),
							self.state.drilldownId === row.id
						)
					);

					if (self.state.drilldownId === row.id) {
						rows.push(self.props.renderDrilldown(row, i));
					}
				});
			} else {
				_.each(self.props.rows, function (row, i) {
					rows.push(
						React.createElement(self.props.rowComponent, {
							item: row,
							index: i,
							key: i,
							isDrilled: self.state.drilldownId === row.id,
							openDrilldown: self.onDrilldown.bind(self, row),
							rowTools: self.props.rowTools,
							tools: tools,
							currentlyUnBouncing: self.props.currentlyUnBouncing
						})
					);

					if (self.state.drilldownId === row.id) {
						rows.push(self.props.renderDrilldown(row, i));
					}
				});
			}
		}

		var ths = _.map(self.props.columns, self.renderTh);

		var paginator = null;
		var totalPages = self.props.totalPages;
		if (totalPages > 1) {
			paginator = React.createElement(ReactTemplates.TOOLS.Paginator, {
				currentPage: self.props.activePage,
				max: totalPages,
				onChange: self.props.onChangePage
			});
		}

		var noResult = null;
		if (!rows.length && !self.props.loading) {
			noResult = <h2 className="no-result">{self.props.noResult}</h2>;
		}

		return (
			<div>
				<table ref={self.setRef.bind(self, '_table')}>
					<thead>
						<tr>
							{self.props.multiActions ? <th style={{ width: '50px' }}>{checkAllElem}</th> : null}
							{ths}
						</tr>
					</thead>
					<tbody>
						{loaderRow}
						{rows}
					</tbody>
				</table>

				{noResult}

				{paginator}
			</div>
		);
	}
});

'use strict';

angular.module('upResources').service('ParseAgreement', [
	'AppService',
	'ParseHelpers',
	function (AppService, ParseHelpers) {
		return function (agreement) {
			agreement.metadata.agreementInvoiceStartdate = window.Tools.parseDate(
				agreement.metadata.agreementInvoiceStartdate &&
					agreement.metadata.agreementInvoiceStartdate.substring(0, 10)
			);
			agreement.metadata.agreementNextOrderDate = window.Tools.parseDate(
				agreement.metadata.agreementNextOrderDate && agreement.metadata.agreementNextOrderDate.substring(0, 10)
			);
			agreement.metadata.agreementRenewalDate = window.Tools.parseDate(
				agreement.metadata.agreementRenewalDate && agreement.metadata.agreementRenewalDate.substring(0, 10)
			);
			agreement.metadata.agreementStartdate = window.Tools.parseDate(
				agreement.metadata.agreementStartdate && agreement.metadata.agreementStartdate.substring(0, 10)
			);
			agreement.metadata.agreementEnddate = window.Tools.parseDate(
				agreement.metadata.agreementEnddate && agreement.metadata.agreementEnddate.substring(0, 10)
			);

			var agreementCustomFields = AppService.getCustomFields('agreement');

			agreement.metadata = ParseHelpers.parseCustom(agreement.metadata, agreementCustomFields);

			return agreement;
		};
	}
]);

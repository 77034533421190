import ResourceTyped from './ResourceTyped';
import Agreement from 'App/resources/Model/Agreement';
import AgreementHistoryVersion from 'App/resources/Model/AgreementHistoryVersion';
class AgreementHistory extends ResourceTyped<AgreementHistoryVersion> {
	constructor() {
		super('agreementHistory');
		this.dateFields = ['regDateTime', 'endDate'];
	}

	get(id: number) {
		return super.get(id);
	}

	toggleHidden(id: number): Promise<Agreement> {
		return this._putRequest(`toggleHidden/${id}`, { id }, { methodName: 'toggleHidden' }).then(res => {
			return res.data;
		});
	}
}

const resource = new AgreementHistory();

export default resource;

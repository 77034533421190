import React, { Component } from 'react';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import EditUser from './EditUser';

class EditUserRoot extends Component {
	render() {
		const props = _.cloneDeep(this.props);
		return <ReduxRootComponent component={EditUser} {...props} />;
	}
}
export default EditUserRoot;

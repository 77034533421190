import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import InlineConfirm from '../Dialogs/InlineConfirm';
import AccessView from '../Helpers/AccessView';

import { startEdit, deleteSignature } from 'Store/reducers/MailSignatureReducer';

import { Button, TableColumn, TableRow, Icon } from '@upsales/components';

import './MailSignature.scss';

const mapDispatchToProps = {
	startEdit,
	deleteSignature
};

function MailSignatureRow(props) {
	const { data, startEdit, deleteSignature, key } = props;
	const mergedUsersRoles = data.users.concat(data.roles);

	const nameTableColumnProps = {
		title: data.name,
		onClick: () => startEdit(data)
	};

	nameTableColumnProps.subtitle = data.startDate ? makeColumnProps(data.startDate, data.endDate) : null;

	return (
		<TableRow key={key} className="mail-signature-row">
			<TableColumn {...nameTableColumnProps} />
			<TableColumn
				onClick={() => startEdit(data)}
				title={checkIfActive(data.active, data.startDate, data.endDate)}
			/>
			<TableColumn className="access">
				<AccessView list={mergedUsersRoles} />
			</TableColumn>
			<td className="TableColumn TableColumn--align-left admin-row-tools-wrap">
				<div className="admin-row-tools">
					<InlineConfirm
						show={true}
						tooltip={Tools.$translate('default.delete')}
						onConfirm={() => Promise.resolve(deleteSignature(data))}
						entity={'default.mailSignature'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</div>
			</td>
		</TableRow>
	);

	function makeColumnProps(start, end) {
		if (start && !end) {
			return [
				<span key={data.id + '-start-date'}>
					{Tools.$translate('default.starting') + ' ' + moment(data.startDate).format('L')}
				</span>
			];
		}

		return [
			<span key={`${data.id}-start-date`}>{moment(data.startDate).format('L')}</span>,
			<Icon key={`${data.id}-icon-angle-right`} name="angle-right" />,
			<span key={`${data.id}-end-date`}>{moment(data.endDate).format('L')}</span>
		];
	}

	function checkIfActive(active, start, end) {
		let isActive = false;
		const t = [Tools.$translate('default.active'), Tools.$translate('default.inactive')];

		if (active) {
			if (start && end) {
				if (moment().isAfter(start) && moment().isBefore(end)) {
					isActive = true;
				}
			} else if (start) {
				if (moment().isAfter(start)) {
					isActive = true;
				}
			} else {
				isActive = true;
			}
		}

		if (isActive) {
			return <span className="is__active">{t[0]}</span>;
		}

		return <span className="is__inactive">{t[1]}</span>;
	}
}

MailSignatureRow.propTypes = {
	data: PropTypes.object.isRequired,
	startEdit: PropTypes.func.isRequired,
	changeStandard: PropTypes.func,
	deleteSignature: PropTypes.func,
	key: PropTypes.string
};

export const detached = MailSignatureRow;
export default connect(null, mapDispatchToProps)(MailSignatureRow);

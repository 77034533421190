class DataCache {
	constructor() {
		this.data = {};
	}

	get(key) {
		return this.data[key] ? this.data[key].value : undefined;
	}

	set(key, newData) {
		const oldDataObj = this.data[key];

		if (oldDataObj) {
			oldDataObj.value = newData;
		} else {
			this.data[key] = {
				value: newData,
				observers: []
			};
		}
	}
}

export default DataCache;

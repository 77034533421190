import logError from 'App/babel/helpers/logError';

angular.module('domain.segment').controller('addToSegmentModal', [
	'$scope',
	'$translate',
	'AppService',
	'$modalParams',
	'$q',
	'Segment',
	'NotificationService',
	'$multiSelect',
	function ($scope, $translate, AppService, $modalParams, $q, Segment, NotificationService, $multiSelect) {
		var renderTimeout;
		var root;

		var props = {
			limit: 40,
			offset: 0,
			saving: false,
			segments: [],
			selectedSegments: [],
			selectedIds: [],
			all_loaded: false,
			sort: null,
			tools: {
				AppService: AppService,
				$translate: $translate,
				$q: $q
			},
			closeModal: function () {
				$scope.reject();
			},
			refetch: function () {
				if (!props.all_loaded) {
					props.offset += 40;
					getData();
				}
			},
			onSave: function () {
				props.saving = true;
				Segment.add($modalParams.selected, { name: 'AddSegments', value: props.selectedSegments })
					.then(function () {
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.saved',
							body: 'saved.contacts'
						});
						closeAfterSave();
					})
					.catch(function () {
						$scope.saving = false;
						NotificationService.addNotification({
							style: NotificationService.style.ERROR,
							icon: 'times',
							title: 'default.error',
							body: 'saveError.contacts'
						});
					});

				render();
			},
			checkbox: function (id) {
				if (props.selectedSegments.indexOf(id) > -1) {
					props.selectedSegments.splice(props.selectedSegments.indexOf(id), 1);
				} else {
					props.selectedSegments.push(id);
				}

				render();
			}
		};

		function getData() {
			Segment.find({ limit: props.limit, offset: props.offset })
				.then(function (res) {
					if (res.data.length === 0) {
						props.all_loaded = true;
					}

					props.segments = props.segments.concat(res.data);

					render();
				})
				.catch(error => logError(error, 'addToSegmentModal.js - getData failed'));
		}

		function closeAfterSave() {
			$scope.saving = false;

			// Deselect all
			$multiSelect.selectNone();

			// Close modal
			$scope.resolve();
		}

		function render() {
			if (!root) {
				root = document.getElementById('addToSegment__root');
			}

			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			var react = ReactTemplates.segment.addToSegmentModal;
			renderTimeout = setTimeout(function () {
				if (!root) {
					return;
				}

				ReactDOM.render(React.createElement(react, props), root);
			}, 20);
		}

		var init = function () {
			props.selectedIds = $modalParams.selected;

			getData();
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(root);
			root = undefined;
		});

		$scope.$on('modal.ready', function () {
			init();
		});
	}
]);

'use strict';

angular.module('upAttributes').service('LeaderPageAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				score: {
					title: 'default.pageValue',
					field: 'score',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				pageImpression: {
					title: 'default.visits',
					field: 'pageImpression',
					type: FilterType.Number,
					displayType: DisplayType.String
				},
				url: {
					title: 'default.url',
					field: 'url',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				keywords: {
					title: 'default.keywords',
					field: 'keywords',
					type: FilterType.String,
					displayType: DisplayType.String
				},
				hide: {
					title: 'default.hide',
					field: 'hide',
					type: FilterType.Boolean,
					displayType: DisplayType.None
				}
			};
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				keys: {
					ID: 'id',
					NAME: 'name',
					SCORE: 'score',
					PAGEIMPRESSION: 'pageImpression',
					URL: 'url',
					KEYWORDS: 'keywords',
					HIDE: 'hide'
				}
			};
		};
	}
]);

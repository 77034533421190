import React, { PureComponent, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Title, Row, Column, Avatar, LeadStatus } from '@upsales/components';
import { leadStatus as leadStatusTooltips } from '../../Helpers/TooltipHelper';
import './AccountSelect.scss';

class AccountSelect extends PureComponent {
	static propTypes = {
		customerId: PropTypes.number.isRequired,
		accountId: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
		autoFocus: PropTypes.bool,
		canSelectRemovable: PropTypes.bool,
		excludeAccounts: PropTypes.array
	};

	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			chooseAnAccount: t('default.chooseAnAccount'),
			noAddress: t('address.noAddress'),
			missingRights: t('noDeleteRights.client'),
			id: t('default.id')
		};

		this._accountSelect = createRef();
	}

	componentDidMount() {
		this.setAccountSelect();
	}

	componentWillUnmount() {
		window.jQuery(this._accountSelect.current).select2('destroy');
	}

	setAccountSelect() {
		window
			.jQuery(this._accountSelect.current)
			.select2({
				placeholder: this.lang.chooseAnAccount,
				minimumInputLength: 2,
				quietMillis: 100,
				allowClear: 1,
				ajax: {
					data: term => term,
					transport: query => this.getAccounts(query),
					results: res => this.getFilteredAccounts(res)
				},
				formatResult: obj => window.renderToStaticMarkup(this.getSelectOption(obj)),
				formatSelection: (obj, x, encode) => encode(this.getSelection(obj))
			})
			.on('change', e => this.onChange(e.added));

		if (this.props.autoFocus) {
			setTimeout(() => {
				window.jQuery(this._accountSelect.current).select2('open');
			}, 500);
		}
	}

	getAccounts(query) {
		const { RequestBuilder, Account, $q } = Tools;

		if (query.data) {
			const filter = RequestBuilder();
			filter.addFilter(Account.attr.name, filter.comparisonTypes.WildcardEnd, query.data);
			filter.limit = 100;

			return Account.customer(this.props.customerId)
				.find(filter.build())
				.then(res => this.addAvatarToUsers(res))
				.then(query.success);
		}

		return $q.when({ data: [] });
	}

	async addAvatarToUsers(res) {
		for (const i in res.data) {
			if (res.data[i].users && res.data[i].users.length) {
				const avatar = await Tools.avatarService.getAvatar(res.data[i].users[0]);
				res.data[i].users[0].avatar = avatar;
			}
		}

		return res;
	}

	getFilteredAccounts(res) {
		const excludeAccounts = this.props.excludeAccounts || [];

		for (const account of res.data) {
			if (!this.props.canSelectRemovable && !account.userRemovable) {
				account.disabled = true;
			}
		}

		return {
			results: res.data.filter(account => {
				return account.id !== this.props.accountId && !excludeAccounts.includes(account.id);
			})
		};
	}

	getSelectOption(account) {
		const { country, city } = this.getAccountAddress(account.addresses);
		const includeMissingRightsText = !this.props.canSelectRemovable && account.userRemovable === false;

		return (
			<div className="AccountSelect">
				<Title size="sm">
					{account.name}
					{includeMissingRightsText && ` (${this.lang.missingRights})`}
				</Title>
				<Row>
					<Column>
						{country && <b className={`icon flag-icon flag-icon-${country.toLowerCase()}`} />}
						{city}
					</Column>
					<Column>
						<LeadStatus account={account} tooltips={this.getTooltips(account)} size="small" />
					</Column>
					<Column>
						{account.users && account.users.length ? (
							<Fragment>
								<Avatar
									src={account.users[0].avatar.url}
									initials={account.users[0].avatar.initials}
									size="sm"
								/>
								{account.users[0].name}
							</Fragment>
						) : null}
					</Column>
					<Column>{`${this.lang.id}: ${account.id}`}</Column>
				</Row>
			</div>
		);
	}

	getAccountAddress(addresses) {
		if (addresses && addresses.length) {
			const address = addresses.find(a => (a.type === 'Visit' && a.city) || a.city);
			if (address) return address;
		}

		return { city: this.lang.noAddress };
	}

	getTooltips(account) {
		return ['activity', 'opportunity', 'order'].map(type =>
			Tools.$translate(leadStatusTooltips(type, {}, account))
		);
	}

	getSelection(account) {
		let name = account.name;

		if (account.userRemovable === false) {
			name += ` (${this.lang.missingRights})`;
		}

		return name;
	}

	onChange(account) {
		let selectedAccount;

		if (account) {
			if (account.users && account.users.length) {
				delete account.users[0].avatar;
			}

			selectedAccount = account;
		}

		this.props.onChange(selectedAccount);
	}

	render() {
		return <input className="form-control" ref={this._accountSelect} />;
	}
}

window.AccountSelect = AccountSelect;
export default AccountSelect;

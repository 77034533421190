import component from 'App/babel/components/PeriodizationList';
import { ButtonSelect } from '@upsales/components';
import { PERIODIZATION_FIELD } from 'Store/reducers/PeriodizationReducer';

angular.module('domain.opportunity').controller('ListPeriodizations', [
	'$upModal',
	'$translate',
	'Order',
	'OrderAttributes',
	'FixOrder',
	'$scope',
	'AppService',
	'FilterHelper',
	'$sce',
	'Report',
	'$filter',
	'$q',
	'RequestBuilder',
	'$safeDigest',
	function (
		$upModal,
		$translate,
		Order,
		OrderAttributes,
		FixOrder,
		$scope,
		AppService,
		FilterHelper,
		$sce,
		Report,
		$filter,
		$q,
		RequestBuilder,
		$safeDigest
	) {
		const Ctrl = this;
		const customerId = AppService.getCustomerId();
		const metadata = AppService.getMetadata();
		const masterCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });
		Ctrl.customFields = AppService.getCustomFields('order');
		Ctrl.filterOpts = { filterType: 'opportunity' };
		Ctrl.listOpts = {
			type: 'opportunity',
			hasCheck: true,
			editable: true,
			placeholderKey: 'opportunity',
			removeEntry: order => Order.customer(customerId)['delete'](order),
			clickedEntry: (order, entries, e) => $upModal.open('editOrder', { id: order.id, customerId: customerId }, e)
		};

		const lang = {
			opportunity: $translate.instant('opportunity'),
			opportunities: $translate.instant('opportunities').toLowerCase(),
			add: $translate.instant('default.add'),
			quickSearch: $translate.instant('filters.quickSearch')
		};

		let opportunities = [];
		let currentRb = new RequestBuilder();

		const currencyFormat = $filter('currencyFormat');
		const numberFormat = $filter('numberFormat');
		const attr = OrderAttributes().attr;

		Ctrl.showAddBtn = true;
		Ctrl.listType = 'periodization';
		Ctrl.component = component;
		Ctrl.monthStats = { [PERIODIZATION_FIELD.VALUE]: {}, [PERIODIZATION_FIELD.CONTRIBUTION_MARGIN]: {} };
		Ctrl.headerExtensionPath = require('App/upsales/domain/opportunity/views/periodizationHeaderExtensionsListView.html?file');
		Ctrl.headerListLinks = [
			{
				type: 'salesboard',
				state: 'react-root-salesboard',
				text: 'default.salesboard',
				icon: 'Icon Icon-salesboard'
			},
			{
				type: 'opportunity',
				state: 'react-root-opportunities',
				text: 'default.opportunities',
				icon: 'Icon Icon-list'
			},
			{
				type: 'periodization',
				state: 'periodizations',
				text: 'default.periodizations',
				icon: 'Icon Icon-calendar-dollar'
			}
		];

		Ctrl.periodizationField = PERIODIZATION_FIELD.VALUE;
		Ctrl.ContributionMarginButtonSelect = () => (
			<ButtonSelect
				value={Ctrl.periodizationField}
				size="sm"
				options={[
					{ title: $translate.instant('periodization.value'), value: PERIODIZATION_FIELD.VALUE },
					{
						title: $translate.instant('default.contributionMargin'),
						value: PERIODIZATION_FIELD.CONTRIBUTION_MARGIN
					}
				]}
				onChange={value => {
					Ctrl.periodizationField = value;
					$safeDigest($scope);
				}}
			/>
		);

		Ctrl.hasContributionMargin =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CONTRIBUTION_MARGIN) &&
			Tools.AppService.getMetadata().params.UseContributionMargin;

		Ctrl.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var filter = props.activeFilters.ListSearch;
				return filter && filter.value ? filter.value : '';
			},
			getPlaceholder: function () {
				return lang.quickSearch;
			}
		};

		Ctrl.addButton = {
			getLabel: () => `${lang.add} ${lang.opportunity}`,
			createEntry: () => $upModal.open('editOrder', { type: 'opportunity', customerId: customerId })
		};

		function addedFn(e, added) {
			if (FilterHelper.match(currentRb.build(), added, 'order')) {
				Ctrl.total++;
				opportunities.push(added);
				// This needs to be changed if someone at any point changes so that you can sort on other stuff
				opportunities.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0));
				getValueSum();
				getMonthAggregations();
			}
		}

		function updatedFn(e, updated) {
			const existingIndex = _.findIndex(opportunities, { id: updated.id });
			if (existingIndex !== -1) {
				if (FilterHelper.match(currentRb.build(), updated, 'order')) {
					opportunities[existingIndex] = angular.extend({}, opportunities[existingIndex], updated);
				} else {
					Ctrl.total--;
					opportunities.splice(existingIndex, 1);
				}
				getValueSum();
				getMonthAggregations();
			}
		}

		function deletedFn(e, deleted) {
			const existingIndex = _.findIndex(opportunities, { id: deleted.id });
			if (existingIndex !== -1) {
				Ctrl.total--;
				opportunities.splice(existingIndex, 1);
				getValueSum();
				getMonthAggregations();
			}
		}

		// Listen for order events
		$scope.$on('order.added', addedFn);
		$scope.$on('opportunity.added', addedFn);

		$scope.$on('order.updated', updatedFn);
		$scope.$on('opportunity.updated', updatedFn);

		$scope.$on('order.deleted', deletedFn);
		$scope.$on('opportunity.deleted', deletedFn);

		function getMasterCurrencySum(rb) {
			var totalSumAgg = rb.aggregationBuilder();
			totalSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInMasterCurrency);
			totalSumAgg.aggregationName('sum');
			totalSumAgg.done();

			return Report.customer(customerId).setType(Report.type.ORDER).find(rb.build());
		}

		function getRoleCurrencySum(rb) {
			var currencyAgg = rb.aggregationBuilder();
			currencyAgg.addAggregation(rb.aggregationTypes.Terms, Order.attr.currency);
			currencyAgg.aggregationName('currency');

			var totalSumAgg = currencyAgg.aggregationBuilder();
			totalSumAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.value);
			totalSumAgg.aggregationName('sum');

			var totalSumInMasterCurrencyAgg = currencyAgg.aggregationBuilder();
			totalSumInMasterCurrencyAgg.addAggregation(rb.aggregationTypes.Sum, Order.attr.valueInMasterCurrency);
			totalSumInMasterCurrencyAgg.aggregationName('sumInMasterCurrency');

			totalSumInMasterCurrencyAgg.done();
			totalSumAgg.done();
			currencyAgg.done();

			return Report.customer(customerId).setType(Report.type.ORDER).find(rb.build());
		}

		let requestId = 0;
		Ctrl.getEntries = (query, rb) => {
			requestId = requestId + 1;

			if (!rb.findFilter('stage.id')) {
				rb.addFilter(attr.probability, rb.comparisonTypes.NotEquals, 100);
			}

			currentRb = rb.clone();

			getValueSum();
			getMonthAggregations();

			return Order.customer(customerId)
				.find(rb.build(), { mapCustom: true })
				.then(res => {
					opportunities = res.data;
					Ctrl.total = res.metadata.total;
					opportunities.forEach(opportunity => FixOrder.print(opportunity));
					return res;
				});
		};

		function getMonthAggregations() {
			const valueRb = currentRb.clone();

			const valueDateAgg = valueRb.aggregationBuilder();
			valueDateAgg.addAggregation(valueRb.aggregationTypes.Terms, 'periodization.date');
			valueDateAgg.aggregationInterval('month');
			valueDateAgg.aggregationName('dateAgg');
			const valueAgg = valueDateAgg.aggregationBuilder();
			valueAgg.addAggregation(valueRb.aggregationTypes.Sum, 'periodization.value');
			valueAgg.aggregationName('valueAgg');
			valueAgg.done();
			valueDateAgg.done();

			const contributionMarginRb = currentRb.clone();

			const contributionMarginDateAgg = contributionMarginRb.aggregationBuilder();
			contributionMarginDateAgg.addAggregation(contributionMarginRb.aggregationTypes.Terms, 'periodization.date');
			contributionMarginDateAgg.aggregationInterval('month');
			contributionMarginDateAgg.aggregationName('dateAgg');
			const contributionMarginAgg = contributionMarginDateAgg.aggregationBuilder();
			contributionMarginAgg.addAggregation(
				contributionMarginRb.aggregationTypes.Sum,
				'periodization.contributionMargin'
			);
			contributionMarginAgg.aggregationName('valueAgg');
			contributionMarginAgg.done();
			contributionMarginDateAgg.done();

			return Promise.all([
				Report.customer(customerId).setType(Report.type.ORDER).find(valueRb.build()),
				Report.customer(customerId).setType(Report.type.ORDER).find(contributionMarginRb.build())
			]).then(([valueRes, contributionMarginRes]) => {
				const map = res =>
					res.data.dateAgg.buckets.reduce((res, row) => {
						res[row.key_as_string] = row.valueAgg ? row.valueAgg.value : 0;
						return res;
					}, {});

				Ctrl.monthStats[PERIODIZATION_FIELD.VALUE] = map(valueRes);
				Ctrl.monthStats[PERIODIZATION_FIELD.CONTRIBUTION_MARGIN] = map(contributionMarginRes);
			});
		}

		Ctrl.formatTotalHtml = '';
		Ctrl.formatTotal = () => Ctrl.formatTotalHtml;

		function getValueSum() {
			const currentRequestId = requestId;

			return $q
				.all({
					masterCurrency: getMasterCurrencySum(currentRb.clone()),
					roleCurrency: getRoleCurrencySum(currentRb.clone())
				})
				.then(res => {
					const masterCurrencyData = res.masterCurrency.data;
					const roleData = res.roleCurrency.data;

					const metadata = AppService.getMetadata();
					const currencies = metadata.customerCurrencies;

					const hasMultiCurrencies = currencies && currencies.length > 1 && metadata.params.MultiCurrency;
					const hasAggregationData =
						roleData && roleData.currency && roleData.currency.buckets && roleData.currency.buckets.length;
					const roleCurrency = metadata.role
						? _.find(currencies, function (curr) {
								return curr.iso === metadata.role.defaultCurrency;
						  })
						: null;

					if (hasMultiCurrencies && hasAggregationData && roleCurrency) {
						const currecyMap = {};
						let totalSumInRoleCurrency = 0;

						_.each(roleData.currency.buckets, function (currency) {
							currecyMap[currency.key] = {
								sum: currency.sum.value,
								sumInMasterCurrency: currency.sumInMasterCurrency.value
							};

							if (currency.key === roleCurrency.iso.toLowerCase()) {
								totalSumInRoleCurrency += currency.sum.value;
							} else {
								totalSumInRoleCurrency += currency.sumInMasterCurrency.value * roleCurrency.rate;
							}
						});

						Ctrl.total = roleData.currency.doc_count || 0;
						return currencyFormat(totalSumInRoleCurrency, roleCurrency.iso) || 0;
					} else if (masterCurrencyData && masterCurrencyData.sum) {
						Ctrl.total = masterCurrencyData.sum.doc_count || 0;
						return currencyFormat(masterCurrencyData.sum.value, masterCurrency.iso) || 0;
					}
				})
				.then(sum => {
					if (currentRequestId === requestId) {
						const formattedNum = numberFormat(Ctrl.total || 0);
						const text = `${formattedNum} ${Ctrl.total === 1 ? lang.opportunity : lang.opportunities} ${
							sum ? `, ${sum}` : ''
						}`;
						Ctrl.formatTotalHtml = $sce.trustAsHtml(text);
						$scope.$broadcast('listView.formatTotal', { formatTotal: Ctrl.formatTotalHtml.toString() });
					}
				});
		}
	}
]);

import Creative from 'App/resources/Model/Creative';
import logError from 'App/babel/helpers/logError';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

function getIframeContent(creative: Creative) {
	if (creative.isGroup) {
		const content = creative.children.reduce((html, banner) => {
			return (
				html +
				`
				<div style="width:${banner.width}px;margin: 20px 0 5px 0; color: #6B7C93;border:1px solid #002f65;">
					${banner.code}
				</div>
				<br>
			`
			);
		}, '');
		return `
			<html>
				<body style="margin:0;padding:10px;">
					${content}
				</body>
			</html>
		`;
	} else {
		let content = '';
		if (creative.code) {
			content = creative.code;
		} else {
			content = `<img src="${creative.sampleImageUrl}"/>`;
		}
		return `
			<html>
				<body style="margin:0;padding:0px;">
					${content}
				</body>
			</html>
		`;
	}
}

export const preview = async (creative: Partial<Creative>) => {
	const options = creative.isGroup
		? `height=${700},width=${900},scrollbars=no,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no`
		: `height=${creative.width || 700},width=${
				creative.width || 900
		  },scrollbars=no,resizable=no,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no`;

	const previewWindow = window.open('', '', options);

	if (previewWindow) {
		previewWindow.document.write(`
			<html>
				<body style="margin:0">
					<iframe
						style="border:none;width:100%;height:100%;"
						sandbox="allow-scripts"
					></iframe>
				</body>
			</html>
		`);

		try {
			const { AppService, Ads } = Tools;
			const rb = new RequestBuilder();
			const uniqueId = creative.uniqueId ? creative.uniqueId : `${creative.id}-${creative.isGroup ? '1' : '0'}`;
			rb.addFilter({ field: 'uniqueId' }, comparisonTypes.Equals, uniqueId);
			rb.addExtraParam('includeAll', 1);
			const customerId = AppService.getCustomerId();
			const {
				data: [apiCreative]
			} = await Ads.customer(customerId).findGroupedCreative(rb.build());
			const iframe = previewWindow.document.querySelector('iframe');

			if (apiCreative && iframe) {
				iframe.setAttribute('srcDoc', getIframeContent(apiCreative));
			}
		} catch (error) {
			logError(error, 'ui/app/helpers/creatives.ts');
		}
	}
};

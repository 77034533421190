import AppointmentParticipantResponse from 'App/enum/AppointmentParticipantResponse';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Link, Input, Button, Tooltip, Expandable, Flex, Text, Avatar, Block } from '@upsales/components';
import JourneyStepDisplay from '../../../JourneyStepDisplay';
import PhoneInput from '../../../Inputs/PhoneInput';
import ValidationService from 'Services/ValidationService';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

import './ContactBlock.scss';
import logError from 'Helpers/logError';

class ContactBlock extends React.Component {
	constructor(props) {
		super(props);

		this.renderOpen = this.renderOpen.bind(this);
		this.saveContact = this.saveContact.bind(this);
		this.editButtons = this.editButtons.bind(this);
		this.stopEditing = this.stopEditing.bind(this);
		this.renderClosed = this.renderClosed.bind(this);
		this.startEditing = this.startEditing.bind(this);
		this.isEmailOrPhone = this.isEmailOrPhone.bind(this);
		this.updateUserProp = this.updateUserProp.bind(this);

		this.accountName = Tools.AppService.getAccountSelf().client.name;

		this.state = {
			edit: false,
			editId: null,
			user: props.user,
			open: props.open,
			isFirstRender: true,
			expandedMap: {},
			'phone-isValid': true,
			'cellPhone-isValid': true
		};
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		const newState = {
			user: nextProps.user,
			open: nextProps.open
		};

		if (!nextProps.open) {
			newState.edit = false;
			newState.editId = null;
			newState.focusField = null;
		}

		this.setState(newState);
	}

	componentDidUpdate() {
		if (this.state.focusField) {
			const field = document.getElementById(`edit_${this.state.focusField}`);
			if (field) {
				field.focus();
			}
		} else if (this.state.edit && this.state.isFirstRender) {
			const nameInput = document.getElementById('edit_name');

			if (nameInput) {
				nameInput.focus();
				nameInput.setSelectionRange(0, 9999);
			}
		}
	}

	contactFormInvalid() {
		const { phone, cellPhone } = this.state.user;
		const isPhoneValid = !phone || !phone.trim() || this.state['phone-isValid'];
		const isCellPhoneValid = !cellPhone || !cellPhone.trim() || this.state['cellPhone-isValid'];
		return !isPhoneValid || !isCellPhoneValid;
	}

	startEditing(fieldClicked) {
		const user = this.state.user;
		if (!user.userEditable) {
			return;
		}

		const STATE = {
			edit: true,
			editId: user.id,
			focusField: null
		};

		if (fieldClicked && typeof fieldClicked === 'string') {
			STATE.focusField = fieldClicked;
		}

		this.setState(STATE);

		if (!this.state.open) {
			this.props.onClick(user);
		}
	}

	stopEditing(fromSave) {
		if (fromSave) {
			this.setState({
				edit: false,
				editId: null,
				focusField: null
			});
		} else {
			this.setState({
				edit: false,
				editId: null,
				focusField: null,
				user: this.props.originalUser
			});
		}
	}

	saveContact(contact) {
		this.props.saveContact(contact);
		this.stopEditing(true);
	}

	updateUserProp(value, field) {
		const USER = Object.assign({}, this.state.user);
		USER[field] = value;

		this.setState({
			user: USER,
			isFirstRender: false,
			focusField: null
		});
	}

	renderTop(user) {
		const AppService = Tools.AppService;
		const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

		const OPEN_ICON = (
			<Tooltip title={Tools.$translate('default.editContact')}>
				<Icon name="pencil" onClick={this.startEditing} />
			</Tooltip>
		);

		if (this.state.edit && this.state.editId === user.id) {
			if (hasNewFields) {
				return (
					<div className="editing-top">
						<Input
							size="sm"
							inline={true}
							key="first-name-key"
							id="edit_name"
							value={user.firstName}
							placeholder={Tools.$translate('default.firstName')}
							onChange={ev => this.updateUserProp(ev.target.value, 'firstName')}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									this.saveContact(this.state.user);
								}
							}}
						/>
						<Input
							size="sm"
							inline={true}
							key="last-name-key"
							id="edit_last_name"
							value={user.lastName}
							placeholder={Tools.$translate('default.lastName')}
							onChange={ev => this.updateUserProp(ev.target.value, 'lastName')}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									this.saveContact(this.state.user);
								}
							}}
						/>
						<Input
							size="sm"
							inline={true}
							key="title-key"
							id="edit_title"
							value={user.title}
							placeholder={Tools.$translate('default.missing.title')}
							onChange={ev => this.updateUserProp(ev.target.value, 'title')}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									this.saveContact(this.state.user);
								}
							}}
						/>
					</div>
				);
			} else {
				return (
					<div className="editing-top">
						<Input
							size="sm"
							inline={true}
							key="name-key"
							id="edit_name"
							value={user.name}
							placeholder={Tools.$translate('default.noName')}
							onChange={ev => this.updateUserProp(ev.target.value, 'name')}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									this.saveContact(this.state.user);
								}
							}}
						/>
						<Input
							size="sm"
							inline={true}
							key="title-key"
							id="edit_title"
							value={user.title}
							placeholder={Tools.$translate('default.missing.title')}
							onChange={ev => this.updateUserProp(ev.target.value, 'title')}
							onKeyDown={ev => {
								if (ev.keyCode === 13) {
									this.saveContact(this.state.user);
								}
							}}
						/>
					</div>
				);
			}
		}

		return (
			<div>
				<Flex alignItems="center">
					<div className="parti-holder">
						<div className="participant-name text-ellipsis">
							<div>
								<Avatar
									icon="user"
									status={AppointmentParticipantResponse[user.responseStatus]}
									className="contact-avatar"
								/>
							</div>
							<Block>
								<Tooltip title={Tools.$translate('voice.goToContact')}>
									<Link
										className={`contact-participant-link${
											AppointmentParticipantResponse[user.responseStatus] ===
											AppointmentParticipantResponse.needsAction
												? ' waiting'
												: ''
										}`}
										onClick={event =>
											this.props.openContact(
												event,
												Tools.$state.href('contact', {
													id: user.id,
													customerId: AppService.getCustomerId()
												})
											)
										}
									>
										{user.name}
									</Link>
								</Tooltip>
								<div
									onClick={() => (!user.title ? this.startEditing('title') : null)}
									className={
										user.title
											? 'participant-title text-ellipsis'
											: 'participant-title grey text-ellipsis'
									}
								>
									{user.titleCategory && hasNewFields ? (
										<span style={{ marginRight: '5px' }}>{user.titleCategory.value}</span>
									) : null}
									{user.title ? user.title : Tools.$translate('default.noTitle')}
								</div>
								{user.client?.name ? (
									<div className="participant-title grey text-ellipsis">{user.client.name}</div>
								) : null}
							</Block>
						</div>
					</div>
					{this.state.open && this.state.user.userEditable ? (
						<div className="parti-arrow">{OPEN_ICON}</div>
					) : null}
					<div className="parti-arrow" onClick={() => this.props.onClick(user)}>
						<Tooltip title={Tools.$translate(this.state.open ? 'default.close' : 'default.moreInfo')}>
							<Icon name={this.state.open ? 'chevron-up' : 'chevron-down'} />
						</Tooltip>
					</div>
				</Flex>
				{this.state.open ? (
					<span>
						<JourneyStepDisplay
							id={user.id}
							type="contact"
							disableActions={!user.userEditable}
							journeyStep={user.journeyStep}
							onSetJourneyStep={step => this.props.setJourneyStep('contact', user, step)}
						/>
					</span>
				) : null}
			</div>
		);
	}

	renderClosed(user) {
		return (
			<div className="participants" key={`appointment-participants-${user.id}`}>
				{this.renderTop(user)}
				<ul className="contact-more-info" />
			</div>
		);
	}

	renderPhone(number, contact) {
		const phoneProps = ReactTemplates.TOOLS.upVoice(contact, 'contact', number);
		return (
			<a href={phoneProps.href} target={phoneProps.target} onClick={phoneProps.onClick}>
				{number}
			</a>
		);
	}

	renderEmail(str, contact) {
		const MAIL_ACTIVATED = Tools.AppService.getMetadata().map.mailActivated;
		if (!MAIL_ACTIVATED) {
			return <a href={`mailto:${str}`}>{str}</a>;
		} else {
			return (
				<a
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
							openNewMailWithContact({ ...contact, email: str });
						} else {
							Tools.$upModal.open('sendEmail', {
								customerId: Tools.AppService.getCustomerId(),
								contactId: contact.id,
								contact: contact
							});
						}
					}}
				>
					{str}
				</a>
			);
		}
	}

	isEmailOrPhone(str, user) {
		if (ValidationService.validatePhone(str)) {
			return this.renderPhone(str, user);
		} else if (ValidationService.validEmail(str)) {
			return this.renderEmail(str, user);
		}

		return str;
	}

	renderEditMode(user, LIST_OPTS) {
		const RETURN_ARRAY = [];

		for (let i = 0; i < LIST_OPTS.length; i++) {
			const isPhoneField = LIST_OPTS[i].attr === 'phone' || LIST_OPTS[i].attr === 'cellPhone';
			const number = user[LIST_OPTS[i].attr];

			if (isPhoneField) {
				RETURN_ARRAY.push(
					<li key={`index-edit-list-${i}`}>
						<PhoneInput
							onChange={(phone, countryCode) => {
								const USER = Object.assign({}, this.state.user);
								USER[LIST_OPTS[i].attr] = phone;
								USER[LIST_OPTS[i].attr + 'CountryCode'] = countryCode;
								this.setState({
									user: USER,
									isFirstRender: false,
									focusField: null
								});
							}}
							onValidChange={isValid => this.setState({ [`${LIST_OPTS[i].attr}-isValid`]: isValid })}
							name={LIST_OPTS[i].attr}
							iconClass={LIST_OPTS[i].icon}
							phone={number}
							countryCode={user[`${LIST_OPTS[i].attr}CountryCode`]}
						/>
					</li>
				);
			} else {
				RETURN_ARRAY.push(
					<li key={`index-edit-list-${i}`}>
						<i className={LIST_OPTS[i].iconClass} />
						<span className="inline-edit" style={LIST_OPTS[i].extraStyle}>
							<Input
								inline
								size="sm"
								value={user[LIST_OPTS[i].attr]}
								id={`edit_${LIST_OPTS[i].attr}`}
								placeholder={Tools.$translate(LIST_OPTS[i].fallbackTranslate)}
								onChange={ev => this.updateUserProp(ev.target.value, LIST_OPTS[i].attr)}
								onKeyDown={ev => {
									if (ev.keyCode === 13) {
										this.saveContact(this.state.user);
									}
								}}
							/>
						</span>
					</li>
				);
			}
		}

		return RETURN_ARRAY;
	}

	renderLiItems(user) {
		const RETURN_ARRAY = [];
		const LIST_OPTS = [
			{
				attr: 'phone',
				icon: 'phone',
				fallbackTranslate: 'default.missing.phone'
			},
			{
				attr: 'cellPhone',
				icon: 'mobile',
				fallbackTranslate: 'default.missing.mobile'
			},
			{
				attr: 'email',
				icon: 'envelope',
				fallbackTranslate: 'default.missing.email'
			}
		];

		if (this.state.edit && this.state.editId === user.id) {
			return this.renderEditMode(user, LIST_OPTS);
		}

		for (let i = 0; i < LIST_OPTS.length; i++) {
			RETURN_ARRAY.push(
				<li
					key={`index-list-${i}`}
					onClick={!user[LIST_OPTS[i].attr] ? () => this.startEditing(LIST_OPTS[i].attr) : null}
				>
					<Icon
						name={LIST_OPTS[i].icon}
						color={user[LIST_OPTS[i].attr] ? 'bright-blue' : 'grey-10'}
						space="mrm"
					/>
					<span style={LIST_OPTS[i].extraStyle}>
						{user[LIST_OPTS[i].attr] ? (
							this.isEmailOrPhone(user[LIST_OPTS[i].attr], user)
						) : (
							<span className="grey italic">{Tools.$translate(LIST_OPTS[i].fallbackTranslate)}</span>
						)}
					</span>
				</li>
			);
		}

		return RETURN_ARRAY;
	}

	editButtons() {
		const isInValid = this.contactFormInvalid();

		return (
			<div className="inline-edit-controls">
				<Button
					disabled={isInValid}
					onClick={() => this.saveContact(this.state.user)}
					size="xs"
					color="bright-blue"
					shadow="none"
				>
					{Tools.$translate('default.save')}
				</Button>
				<Button
					onClick={() => this.stopEditing(false)}
					size="xs"
					type="link"
					style={{ height: '22px', fontSize: '11px', lineHeight: '11px', padding: '0 12px' }}
				>
					{Tools.$translate('cancel')}
				</Button>
			</div>
		);
	}

	componentDidMount() {
		if (this.props.type === 'user') {
			Tools.avatarService
				.getAvatar(this.props.user)
				.then(userAvatar => this.setState({ userAvatar }))
				.catch(e => {
					logError(e, 'Failed to get user avatar');
				});
		}
	}

	renderOpen(user) {
		const liItems = this.renderLiItems(user);

		return (
			<div
				className={
					this.state.edit
						? 'participants participants-open participants-edit'
						: 'participants participants-open'
				}
				key={`appointment-participants-${user.id}`}
			>
				{this.renderTop(user)}
				<ul className="contact-more-info">{liItems}</ul>
				{this.state.edit ? this.editButtons() : null}
			</div>
		);
	}

	renderParticipant(p, type) {
		const avatar = (
			<Avatar
				icon={type !== 'user' ? 'user' : undefined}
				initials={type !== 'emailAttendee' ? p.name : p.email}
				status={AppointmentParticipantResponse[p.responseStatus]}
				src={this.state.userAvatar?.url}
			/>
		);
		const title =
			type === 'contact' || type === 'user' ? p.name : p.email ?? Tools.$translate('default.missing.email');
		const isExpanded = this.state.expandedMap[p.id];

		const subtitle =
			type === 'contact' ? (
				<>
					<Text size="sm" color="grey-10" italic={!p.title}>
						{p.title || Tools.$translate('default.missing.title')}
					</Text>
					<Text size="sm" color="grey-10">
						{p.client?.name || this.props.appointment.client?.name}
					</Text>
				</>
			) : type === 'user' ? (
				<Text size="sm" color="grey-10">
					{this.accountName}
				</Text>
			) : (
				''
			);

		const expanded = isExpanded ? (
			<ul className="select-participant-list-expanded">{this.renderLiItems(p)}</ul>
		) : null;

		return (
			<Expandable
				className={`Expandable--${type} ${isExpanded ? 'Expandable--expanded' : ''}`}
				key={p.id}
				setExpanded={v => {
					if (type !== 'contact') {
						return;
					}
					const expandedMap = { ...this.state.expandedMap, [p.id]: v };
					this.setState({
						expandedMap
					});
				}}
				expanded={isExpanded}
				fade
				title={
					<div>
						<Flex alignItems="center" gap={12}>
							{avatar}
							<div>
								<Text>{title}</Text>
								{subtitle}
							</div>
						</Flex>
						<Flex>{expanded}</Flex>
					</div>
				}
			></Expandable>
		);
	}

	render() {
		const { open, user } = this.state;
		const { type } = this.props;
		if (this.props.useNewList) {
			return this.renderParticipant(user, type);
		}
		if (open) {
			return this.renderOpen(user);
		}

		return this.renderClosed(user);
	}
}

ContactBlock.propTypes = {
	user: PropTypes.object.isRequired, // User is really a contact...
	open: PropTypes.bool,
	onClick: PropTypes.func,
	saveContact: PropTypes.func,
	originalUser: PropTypes.object,
	setJourneyStep: PropTypes.func,
	openContact: PropTypes.func,
	appointment: PropTypes.object,
	useNewList: PropTypes.bool,
	type: PropTypes.string
};

ContactBlock.defaultProps = {
	open: false
};

export default ContactBlock;

'use strict';

angular.module('upResources').factory('CustomFieldMapper', function () {
	return {
		map: function (res) {
			angular.forEach(res.data, function (obj) {
				obj.$mappedCustom = {};
				if (obj.custom && obj.custom.length) {
					angular.forEach(obj.custom, function (field) {
						obj.$mappedCustom[field.fieldId] = field;
					});
				}
				if (obj.metadata && obj.metadata.custom && obj.metadata.custom.length) {
					obj.metadata.$mappedCustom = {};
					angular.forEach(obj.metadata.custom, function (field) {
						obj.metadata.$mappedCustom[field.fieldId] = field;
					});
				}
			});

			return res;
		}
	};
});

import AdminHeader from 'Components/Admin/AdminHeader';
import React, { Component } from 'react';
import t from 'Components/Helpers/translate';
import AutomationTable from './AutomationTable';
import _ from 'lodash';
import Automation from 'App/resources/Model/Automation';

type Props = {
	rootData: {
		pageData: {
			automations: Automation[];
		};
		editAutomation: (automation?: Automation) => void;
		deleteAutomation: (automation: Automation) => void;
	};
};

class Automations extends Component<Props> {
	lang: { [key: string]: string };
	constructor(p: Props) {
		super(p);
		this.lang = {
			automations: t('admin.automations'),
			automationsInfo: t('admin.automationsInfo')
		};
	}

	deleteAutomation(automation: Automation, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		e.stopPropagation();
		this.props.rootData.deleteAutomation(automation);
	}

	render() {
		const rootData = this.props.rootData;

		const separatedAutomations = _.reduce(
			rootData.pageData.automations,
			(res, automation) => {
				if (automation.active) {
					res.active.push(automation);
				} else {
					res.inactive.push(automation);
				}
				return res;
			},
			{ active: [] as Automation[], inactive: [] as Automation[] }
		);

		const activeTable = (
			<AutomationTable
				editAutomation={this.props.rootData.editAutomation}
				deleteAutomation={this.deleteAutomation.bind(this)}
				automations={separatedAutomations.active}
			/>
		);
		let inactiveTable = null;

		if (separatedAutomations.inactive.length) {
			inactiveTable = (
				<AutomationTable
					hideNewButton={true}
					editAutomation={this.props.rootData.editAutomation}
					deleteAutomation={this.deleteAutomation.bind(this)}
					automations={separatedAutomations.inactive}
				/>
			);
		}

		return (
			<div id="admin-page-automations">
				<AdminHeader
					title={this.lang.automations}
					description={this.lang.automationsInfo}
					image="automations.svg"
					articleId={453}
				/>

				<div id="admin-content">
					{activeTable}
					{inactiveTable}
				</div>
			</div>
		);
	}
}

export default Automations;

import PropTypes from 'prop-types';
import React from 'react';
import CriteriaTable from '../Criteria/CriteriaTable';
import Button from '../Inputs/Button';
import { Card } from '@upsales/components';

class FilterContainer extends React.Component {
	constructor(props) {
		super(props);

		const translate = Tools.$translate;

		this.lang = {
			editCriterias: translate('segment.editCriterias'),
			hideCriterias: translate('segment.hideCriterias'),
			numberOfCriterias: 'segment.numberOfCriterias',
			criteria: translate('admin.criteria').toLowerCase(),
			criterias: translate('admin.criterias').toLowerCase(),
			clickToEdit: translate('default.clickToEdit'),
			continue: translate('onboarding.continue'),
			title: translate('segment.filter.placeholder.title'),
			subtitle: translate('segment.filter.placeholder.subtitle')
		};
	}

	placeholder() {
		const { filterSelection } = this.props;

		return (
			<div key="placeholder" className="placeholder-wrapper">
				<div className="left-side">
					<div className="title">{this.lang.title}</div>
					<div className="sub-title">{this.lang.subtitle}</div>
					<Button
						className="main-action"
						color="bright-blue"
						shadow="none"
						size="xl"
						onClick={filterSelection.toggleExpand}
					>
						{this.lang.continue}
					</Button>
				</div>
				<div className="right-side">
					<img src="img/upsales-criteria-illustration.gif" />
				</div>
			</div>
		);
	}

	expanded() {
		const {
			filterSelection,
			segment,
			getExcludeFilter,
			getIncludeFilter,
			listState: { error },
			selectData
		} = this.props;

		const excludeFilter = getExcludeFilter(segment);
		const includeFilter = getIncludeFilter(segment);

		return (
			<div key="expanded" className="expanded-wrapper">
				<div className="header">
					<span className="title">
						{this.lang.editCriterias}
						<ReactTemplates.elevio articleId={547} />
					</span>
					<div className="toggle-button-wrap" onClick={filterSelection.toggleExpand}>
						<button className="btn-round">
							<i className="fa fa-chevron-up" />
						</button>
						<button className="btn up-btn btn-link btn-grey">{this.lang.hideCriterias}</button>
					</div>
				</div>
				<Card space="mbxl">
					<CriteriaTable
						filters={includeFilter}
						{...filterSelection}
						listStateError={error}
						selectData={selectData}
						criteriaSelectAnchor={document.getElementById('segment-filter-v2')}
					/>
				</Card>
				<Card space="mbxl">
					<CriteriaTable
						filters={excludeFilter}
						{...filterSelection}
						listStateError={error}
						selectData={selectData}
						criteriaSelectAnchor={document.getElementById('segment-filter-v2')}
					/>
				</Card>
				<div className="footer" onClick={filterSelection.toggleExpand}>
					<div className="collapse-segment-filter">
						<button className="btn-round">
							<i className="fa fa-chevron-up" />
						</button>
						<span>{this.lang.hideCriterias}</span>
					</div>
				</div>
			</div>
		);
	}

	collapsed(count) {
		const { filterSelection } = this.props;

		const translate = Tools.$translate;
		const numberCriteriasDescription = translate(this.lang.numberOfCriterias, {
			count: count,
			criterias: count === 1 ? this.lang.criteria : this.lang.criterias
		});

		return (
			<div key="collapsed" className="collapsed-wrapper" onClick={filterSelection.toggleExpand}>
				<div className="left-side">
					<div className="text">{numberCriteriasDescription}</div>
					<div className="expand-button-wrap">
						<button className="btn-round">
							<i className="fa fa-chevron-down" />
						</button>
						<span>{this.lang.clickToEdit}</span>
					</div>
				</div>
				<div className="right-side">
					<img src="img/contact-funnel.svg" />
				</div>
			</div>
		);
	}

	render() {
		const { segment, filterSelection, getExcludeFilter, getIncludeFilter } = this.props;
		const state = filterSelection.state;

		const excludeFilter = getExcludeFilter(segment);
		const includeFilter = getIncludeFilter(segment);
		const count = Object.keys(excludeFilter.config).length + Object.keys(includeFilter.config).length;

		let className;

		if (state.expanded) {
			className = 'expanded';
		} else if (count === 0 && !state.placeholderHidden) {
			className = 'placeholder';
		} else {
			className = 'collapsed';
		}

		return (
			<div id="segment-filter-v2" className={className}>
				{this.placeholder()}
				{this.collapsed(count)}
				{this.expanded()}
			</div>
		);
	}
}

FilterContainer.propTypes = {
	filterSelection: PropTypes.object.isRequired,
	segment: PropTypes.object.isRequired,
	getExcludeFilter: PropTypes.func.isRequired,
	getIncludeFilter: PropTypes.func.isRequired,
	listState: PropTypes.object,
	selectData: PropTypes.object
};

window.SegmentFilterContainer = FilterContainer;
export default FilterContainer;

'use strict';

angular.module('upDirectives').directive('upErrorRow', [
	'$filter',
	'$compile',
	function ($filter, $compile) {
		return {
			restrict: 'A',
			scope: {
				reload: '&'
			},
			link: function (scope, element, attrs) {
				if (!attrs.upErrorRow) {
					throw new Error('No listType sent to upErrorRow directive');
				}

				element.addClass('error-row');

				// create row
				var html;
				var column = angular.element('<td colspan="100"/>');

				var type = scope.$eval(attrs.upErrorRow);

				var icon = angular.element('<b/>');
				icon.addClass('fa fa-warning icon');

				if (type && (type == 'opportunity' || type == 'contact' || type == 'order' || type == 'activity')) {
					html = $filter('translate')('listError.' + type);
				} else {
					html = $filter('translate')('listError.default');
				}
				html += '. ';

				column.prepend(icon, html);

				// reload function
				if (attrs.reload) {
					// create add link
					var link = angular.element('<a href="#">');
					link.text($filter('translate')('default.tryAgain'));

					link.on('click', function (e) {
						e.preventDefault();
						scope.reload();
					});

					column.append(link);
				}

				element.append(column);
			}
		};
	}
]);

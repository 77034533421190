'use strict';

angular.module('domain.voice', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	'$upModalProvider',
	function ($stateProvider, $upModalProvider) {
		$stateProvider.state('phoneCalls', {
			templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
			url: '/:customerId/phoneCalls/',
			controller: 'ListPhoneCalls as listViewHelper',
			section: 'sale',
			resolve: {
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				isAvailable: [
					'AppService',
					'$q',
					function (AppService, $q) {
						var p = $q.defer();
						// eslint-disable-next-line promise/catch-or-return
						AppService.loadedPromise.then(function () {
							if (AppService.getPhoneIntegration()) {
								p.resolve(true);
							} else {
								p.reject(false);
							}
						});
						return p.promise;
					}
				],
				listViews: [
					'AppService',
					function (AppService) {
						return AppService.loadedPromise.then(function () {
							return AppService.getListViews('phoneCall');
						});
					}
				]
			}
		});

		$upModalProvider.modal('openCall', {
			templateUrl: require('App/upsales/domain/voice/views/openCall.html?file'),
			style: 'form-sm info phonecall-modal',
			controller: 'openCall as openCall',
			resolve: {
				meta: function ($modalParams, Account, AppService) {
					if (!$modalParams.client?.id) {
						return null;
					}
					return Account.customer(AppService.getCustomerId()).get($modalParams.client.id);
				}
			}
		});
	}
]);

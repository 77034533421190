import React, { SyntheticEvent } from 'react';
import {
	DropDownButton,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn,
	Icon,
	Button,
	Tooltip,
	Block,
	Help,
	TableHeader,
	Text,
	Title
} from '@upsales/components';
import moment from 'moment';
import BemClass from '@upsales/components/Utils/bemClass';
import { copyToClipboardNotify } from 'Services/copyToClipboard';
import './EasyBookingLinkDropdown.scss';
import { easyBookingTracker } from 'App/babel/helpers/Tracker';

function EasyBookingLinkDropdown(props: { hasTooltip?: boolean }) {
	const classNames = new BemClass('EasyBookingLinkDropdown');
	const ezbData = Tools.AppService.getMetadata().activatedFeatures.easyBooking;
	const ezbEnabled = ezbData.active;
	const user = Tools.AppService.getSelf();

	type BookingPages = typeof ezbData.bookingPages;
	type SplitBookingPages = { personalPages: BookingPages; sharedPages: BookingPages };

	const activePages = ezbData.bookingPages.filter(({ active }) => active);

	const displayedPagesLimit = 10;

	const { personalPages, sharedPages } = activePages.slice(0, displayedPagesLimit).reduce<SplitBookingPages>(
		(accumulator, bookingPage) => {
			const list = bookingPage.isShared ? accumulator.sharedPages : accumulator.personalPages;
			list.push(bookingPage);
			return accumulator;
		},
		{ personalPages: [], sharedPages: [] }
	);

	const t = Tools.$translate;
	const lang = {
		title: t('appointment.easyBookingLinkDropDown.title'),
		titleOld: t('appointment.easyBookingLinkDropDown.title.old'),
		copyLink: t('appointment.easyBookingLinkDropDown.copyLink'),
		editAvailability: t('appointment.easyBookingLinkDropDown.editAvailability'),
		about: t('appointment.easyBookingLinkDropDown.about'),
		personal: t('appointment.easyBookingLinkDropDown.personal'),
		shared: t('appointment.easyBookingLinkDropDown.shared'),
		settings: t('appointment.easyBookingLinkDropDown.settings'),
		viewAll: t('appointment.easyBookingLinkDropDown.viewAll')
	};

	const title = lang.title;

	const goToBookingPage = (url: string) => () => {
		window.open(url, '_blank');
	};

	const goToEditPage = (id: number) => (e: SyntheticEvent) => {
		e.stopPropagation();
		Tools.$state.go('administration.appointmentEditor', { id });
	};

	const copyBookingPage = (url: string) => (e: SyntheticEvent) => {
		e.stopPropagation();
		copyToClipboardNotify(url);
		easyBookingTracker.track(easyBookingTracker.events.COPY_LINK, {
			date: moment().format('YYYY-MM-DD')
		});
	};

	if (!ezbEnabled) return null;

	return (
		<div className={classNames.b()}>
			<DropDownMenu
				align="right"
				arrowed={false}
				renderTrigger={(expanded, setExpanded) => (
					<Tooltip title={title} disabled={!props.hasTooltip}>
						<div
							data-testid="dropDownButton"
							className={classNames.elem('label').b()}
							onClick={(e: Parameters<typeof setExpanded>[0]) => {
								e.stopPropagation();
								setExpanded(e);
							}}
						>
							<Icon name="calendar" color="black" />
							<DropDownButton title={title} size="sm" expanded={expanded} hasChildren={false} />
						</div>
					</Tooltip>
				)}
			>
				<>
					<div className={classNames.elem('header').b()}>
						<div className={classNames.elem('header').elem('titleRow').b()}>
							<Title color="dark-grey" size="md" bold>
								{title}
							</Title>
							<Tooltip title={lang.about} position="left">
								<Help articleId={1163}>
									<Icon name="question-circle" />
								</Help>
							</Tooltip>
						</div>
						<Text color="grey-10" size="md" className={classNames.elem('header').elem('subtitle').b()}>
							{user.name}
						</Text>
					</div>

					{personalPages.length > 0 ? (
						<Table>
							<TableHeader columns={[{ title: lang.personal }]} />
							{personalPages.map(({ id, title, bookingUrl }) => (
								<TableRow key={id} onClick={goToBookingPage(bookingUrl)} data-testid="link-test">
									<TableColumn title={title} />
									<TableColumn align="right">
										<Tooltip position="left" html={true} title={lang.editAvailability}>
											<Button
												type="link"
												color="grey"
												onClick={goToEditPage(id)}
												data-testid="edit-test"
											>
												<Icon name="edit" />
											</Button>
										</Tooltip>
										<Tooltip position="left" html={true} title={lang.copyLink}>
											<Button
												type="link"
												color="grey"
												onClick={copyBookingPage(bookingUrl)}
												data-testid="copy-test"
											>
												<Icon name="copy" />
											</Button>
										</Tooltip>
									</TableColumn>
								</TableRow>
							))}
						</Table>
					) : null}
					{sharedPages.length > 0 ? (
						<Table>
							<TableHeader columns={[{ title: lang.shared }]} />
							{sharedPages.map(({ id, title, bookingUrl }) => (
								<TableRow key={id} onClick={goToBookingPage(bookingUrl)}>
									<TableColumn title={title} />
									<TableColumn align="right">
										<Tooltip position="left" html={true} title={lang.copyLink}>
											<Button type="link" color="grey" onClick={copyBookingPage(bookingUrl)}>
												<Icon name="copy" />
											</Button>
										</Tooltip>
									</TableColumn>
								</TableRow>
							))}
						</Table>
					) : null}

					<Block border="ts" borderColor="grey-4">
						<Button
							type="link"
							block
							size="md"
							onClick={() => {
								Tools.$state.go('administration.appointmentAvailability');
							}}
							data-testid="bottom-button"
						>
							{activePages.length > 10 ? lang.viewAll : lang.settings}
						</Button>
					</Block>
				</>
			</DropDownMenu>
		</div>
	);
}
export default EasyBookingLinkDropdown;

import React from 'react';
import _ from 'lodash';
import UiElements from 'Components/UiElements';
import BaseInfo from 'Components/EditActivity/BaseInfo';

ReactTemplates.editActivity = {};
ReactTemplates.editActivity.root = window.ReactCreateClass({
	resizeTimer: null,
	activityId: null,
	fullHeight: true,
	getStateFromProps: function (props) {
		var activity = props.activity ? JSON.parse(JSON.stringify(props.activity)) : {};

		if (!activity.time) {
			activity.time = undefined;
		} else if (activity.date && activity.time) {
			activity.time = activity.date;
		}

		return {
			activity: activity,
			saving: props.saving,
			topMargin: props.topMargin || 20,
			bottomMargin: props.bottomMargin || 20,
			isOutcomeEnabled: this.getOutcomeEnableState(activity)
		};
	},
	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var newState = this.getStateFromProps(nextProps);
		this.setState(newState);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			insertSign: t('default.insertSign'),
			links: t('default.links')
		};
	},
	getOutcomeEnableState: function (activity) {
		const activityTypes = Tools.AppService.getActivityTypes('activity', true);
		const activityTypeId = _.get(activity, 'activityType.id');
		const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);

		return hasActivityOutcomeAccess && !!_.get(_.find(activityTypes, { id: activityTypeId }), 'hasOutcome', false);
	},
	updateSize: function () {
		var self = this;

		if (self.resizeTimer) {
			clearTimeout(self.resizeTimer);
			self.resizeTimer = null;
		}

		// delay if transitioning
		if (self.props.transitioning) {
			self.resizeTimer = setTimeout(self.updateSize, 200);
			return;
		}

		self.resizeTimer = setTimeout(function () {
			self.resizeTimer = null;
			var modal = self._modal;
			if (!modal) {
				return;
			}

			var sidebar = self._sidebar;
			var header = ReactDOM.findDOMNode(self._header);
			var center = ReactDOM.findDOMNode(self._center);
			var footer = ReactDOM.findDOMNode(self._footer);
			modal.classList.remove('full-height');
			modal.style.height = 'initial';

			var contentHeight = Math.max(sidebar.clientHeight, center.clientHeight);
			var containerHeight = window.innerHeight - self.state.topMargin - self.state.bottomMargin;
			var modalHeight = contentHeight + header.clientHeight;

			if (footer) {
				modalHeight += footer.clientHeight;
			}

			if (modalHeight < containerHeight) {
				modal.style.height = modalHeight + 'px';
				self.fullHeight = false;
			} else {
				modal.classList.add('full-height');
				modal.style.height = 'initial';
				self.fullHeight = true;
			}
		}, 20);
	},
	componentWillUnmount: function () {
		if (this.resizeTimer) {
			clearTimeout(this.resizeTimer);
			this.resizeTimer = null;
		}
		window.removeEventListener('resize', this.updateSize);
	},
	componentDidMount: function () {
		this.updateSize();

		window.addEventListener('resize', this.updateSize);
		this.activityId = this.state.activity ? this.state.activity.id : null;
	},
	componentDidUpdate: function () {
		// Do stuf after update (modify widths/heights etc)
		this.updateSize();

		// Scroll first error-input into view and set focus to it
		if (this.props.submitted && this.activityId === this.state.activity.id) {
			var inputs = this._modal.querySelectorAll('.has-error');
			if (inputs.length) {
				inputs[0].scrollIntoView();
				inputs[0].querySelector('input').focus();
			}
		}
		this.activityId = this.state.activity.id;
	},
	onSave: function (closeActivity) {
		this.props.onSave(this.state.activity, closeActivity);
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	userChange: function (user) {
		var activity = this.state.activity;
		activity.users = [user];
		this.props.onChange(this.state.activity);
	},
	close: function () {
		// this.props.close();
	},
	notesChanged: function (e) {
		var activity = this.state.activity;
		activity.notes = e.target.value;
		this.props.onChange(this.state.activity);
	},
	accountChange: function (account) {
		var activity = this.state.activity;
		activity.client = account;
		this.props.onChange(this.state.activity);
	},
	contactChange: function (contact) {
		var activity = this.state.activity;
		activity.contacts = contact ? [contact] : null;
		this.setState({ activity: activity });
		this.props.onChange(this.state.activity);
	},
	baseInfoChange: function (key, value) {
		var activity = this.state.activity;
		activity[key] = value;
		this.props.onChange(this.state.activity);
	},
	onCustomChange: function (id, value) {
		var activity = this.state.activity;
		var index = _.findIndex(activity.custom, { id: id });
		if (index !== -1) {
			activity.$mappedCustom[id].value = value;
			activity.custom[index].value = value;
		}
		this.props.onChange(this.state.activity);
	},
	insertSign: function (e) {
		e.preventDefault();
		e.stopPropagation();

		var dateTimeStr = moment().format('L LT');
		var signature = this.props.tools.self.name + ' ' + dateTimeStr + ':\n';
		var pos = signature.length;
		var activity = this.state.activity;
		var textarea = ReactDOM.findDOMNode(this._notes);
		activity.notes = signature + '\n\n' + (activity.notes || '');
		this.notesChanged({ target: { value: activity.notes } });

		textarea.focus();
		setTimeout(function () {
			textarea.setSelectionRange(pos, pos);
		}, 50);
	},
	scrollNotesToTop: function (e) {
		e.preventDefault();
		e.target.scrollTop = 0;
	},
	render: function () {
		var self = this;
		var tools = this.props.tools;
		var isFlash = this.props.flash || false;
		var activity = this.state.activity;
		var requiredFields = tools.metadata.requiredFields.Activity;
		var requiredFieldsContact = tools.metadata.requiredFields.Contact;
		var saving = this.state.saving;
		var lockedActivity = !!this.state.activity.closeDate || !activity.userEditable;
		var rootClass = 'edit-activity-modal';
		var fieldsDisabled = saving || lockedActivity;
		var formInvalid = false;
		var formDirty = self.props.dirty;
		var formSubmitted = false;
		const documentTemplates = self.props.documentTemplates;
		const isFooterVisible =
			!isFlash ||
			(activity.id && documentTemplates && documentTemplates.length && self.props.isAvailable.document) ||
			!activity.userEditable ||
			!this.state.isOutcomeEnabled;
		var centerClass = 'up-modal-content has-sidebar-x2';
		const uiSidebarElements = _.get(self.props.tools.metadata, 'integrations.uiElements.editActivity.sidebar', []);

		if (isFlash) {
			rootClass += ' is-flash';
		}

		if (activity.client) {
			centerClass += ' account-selected';
		}

		if (!isFooterVisible) {
			centerClass += ' hidden-footer';
		}

		var header = React.createElement(ReactTemplates.editActivity.header, {
			isFlash: isFlash,
			ref: self.setRef.bind(self, '_header'),
			disabled: fieldsDisabled,
			users: self.props.users,
			activity: activity,
			userChange: self.userChange,
			tools: tools,
			formInvalid: self.props.formInvalid
		});

		var footer = React.createElement(ReactTemplates.editActivity.footer, {
			disabled: fieldsDisabled,
			activity: activity,
			onSave: self.onSave,
			formInvalid: formInvalid,
			documentTemplates: self.props.documentTemplates,
			onCreateDocument: self.props.onCreateDocument,
			onCreateFollowup: self.props.onCreateFollowup,
			isFlash: isFlash,
			closing: self.props.closing,
			ref: self.setRef.bind(self, '_footer'),
			isAvailable: this.props.isAvailable,
			isOutcomeEnabled: this.state.isOutcomeEnabled,
			tools: tools
		});

		var sidebarAccount = React.createElement(ReactTemplates.editActivity.sidebarAccount, {
			disabled: fieldsDisabled,
			activity: activity,
			onChange: self.accountChange,
			formDirty: formDirty,
			formSubmitted: formSubmitted,
			onEditAccount: self.props.onEditAccount,
			onSave: self.props.onSave,
			onSetJourneyStep: self.props.onSetJourneyStepClient,
			goToUrl: self.props.goToUrl
		});

		var sidebarContact = React.createElement(ReactTemplates.editActivity.sidebarContact, {
			disabled: fieldsDisabled,
			activity: activity,
			onChange: self.contactChange,
			formDirty: formDirty,
			contactRemoved: self.props.contactRemoved,
			onCreateContact: self.props.onCreateContact,
			onContactSave: self.props.onContactSave,
			onEmailContact: self.props.onEmailContact,
			contactSaving: self.props.contactSaving,
			formSubmitted: formSubmitted,
			requiredFields: requiredFields,
			requiredFieldsContact: requiredFieldsContact,
			updateSize: self.updateSize,
			onSetJourneyStep: self.props.onSetJourneyStepContact,
			beforeGoTo: self.props.beforeGoTo
		});

		var baseInfo = (
			<BaseInfo
				ref={self.setRef.bind(self, '_center')}
				formDirty={formDirty}
				disabled={fieldsDisabled}
				onChange={self.baseInfoChange}
				next={self.props.next}
				onSave={self.onSave}
				onCreateFollowup={self.props.onCreateFollowup}
				bookAppointment={self.props.bookAppointment}
				createOpportunityAndSave={this.props.createOpportunityAndSave}
				createOrderAndSave={this.props.createOrderAndSave}
				onSetJourneyStep={self.props.onSetJourneyStepContact}
				setOutcome={self.props.setOutcome}
				activity={activity}
				requiredFields={requiredFields}
				onCreateCampaign={self.props.onCreateCampaign}
				onCreateOpportunity={self.props.onCreateOpportunity}
				onCustomChange={self.onCustomChange}
				formInvalid={self.props.formInvalid}
				isAvailable={this.props.isAvailable}
				isOutcomeEnabled={this.state.isOutcomeEnabled}
				setContact={self.props.setContact}
				tools={tools}
			/>
		);

		var modalClasses = 'up-modal form default dynamic compiled has-controls';

		if (self.props.transitioning) {
			modalClasses += self.props.transitioning === 'right' ? ' transition-r' : ' transition-l';
		}

		if (self.fullHeight) {
			modalClasses += ' full-height';
		}

		var linksSection = null;

		var links = self.props.links;
		if (links && links.length) {
			var linkRows = _.map(links, function (link) {
				var icon = null;
				var style = null;
				if (link.color) {
					style = {
						color: '#' + link.color
					};
				}
				if (link.icon && link.icon.indexOf('fa') === 0) {
					var iconClass = 'icon fa custom-icon ' + link.icon;
					icon = <b className={iconClass} style={style} />;
				} else if (link.icon && link.icon.indexOf('fa') !== 0) {
					icon = <img src={link.icon} />;
				}

				return (
					<li>
						<a target={link.target || '_blank'} href={link.href}>
							{icon} {link.value}
						</a>
					</li>
				);
			});
			linksSection = (
				<div id="links-section" className="sidebar-section">
					<h3>
						<b className="fa fa-link" /> {self.lang.links}
					</h3>

					<ul>{linkRows}</ul>
				</div>
			);
		}

		return (
			<div className={modalClasses} ref={self.setRef.bind(self, '_modal')}>
				<div className={rootClass}>
					{header}

					<div className={centerClass}>
						<div className="modal-sidebar" ref={self.setRef.bind(self, '_sidebar')}>
							{sidebarAccount}

							{sidebarContact}

							{linksSection}

							{uiSidebarElements.length ? (
								<div className="sidebar-section edge-to-edge">
									<UiElements
										elements={uiSidebarElements}
										object={activity}
										type="editActivity"
										onObjectChange={() => {
											/* update of object is not supported for activity */
										}}
									/>
								</div>
							) : null}

							<div dangerouslySetInnerHTML={{ __html: '<div id="sidebar-script-container"></div>' }} />
						</div>

						{baseInfo}
					</div>

					{isFooterVisible ? footer : null}

					<div className="sidebar-bg" />
					<div className="sidebar-bg right" />

					{activity.client ? (
						<div className="modal-sidebar sidebar-right">
							<button
								type="button"
								className="up-btn btn btn-link btn-bright-blue insert-sign-btn"
								onClick={self.insertSign}
								disabled={fieldsDisabled}
							>
								<b className="fa fa-pencil" /> {self.lang.insertSign}
								{'...'}
							</button>

							<window.Input.textarea
								id="notes-area"
								className="flat-input"
								name="notes-area"
								ref={self.setRef.bind(self, '_notes')}
								value={activity.notes || ''}
								onFocus={self.scrollNotesToTop}
								onChange={self.notesChanged}
								disabled={fieldsDisabled}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
});

'use strict';

angular.module('domain.engage').controller('SelectCreativeTemplate', [
	'$stateParams',
	'AppService',
	'StandardCreative',
	'meta',
	'$upModal',
	function ($stateParams, AppService, StandardCreative, meta, $upModal) {
		var SelectCreativeTemplate = this;

		SelectCreativeTemplate.rootData = {
			loaded: false
		};

		var getTemplates = function () {
			StandardCreative.find().then(function (res) {
				SelectCreativeTemplate.rootData.templates = res.data;
			});
		};

		var init = function () {
			SelectCreativeTemplate.rootData.accountProfile = meta.accountProfile.data;
			SelectCreativeTemplate.rootData.loaded = true;
			getTemplates();
		};

		AppService.loadedPromise.then(init);
	}
]);

import './AgreementFollowUpChip.scss';
import React, { useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { ellipsisClass } from '@upsales/components/Utils/ellipsis';
import { Text, AssistChip } from '@upsales/components';
import T from 'Components/Helpers/translate';

type Props = {
	agreementDescription?: string;
};

const AgreementFollowUpChip = ({ agreementDescription }: Props) => {
	const classes = new BemClass('AgreementFollowUpChip');
	const titleRef = useRef<HTMLDivElement>(null);

	const title = (
		<div className={classes.elem('title').b()} ref={titleRef}>
			{T('agreement.followUpSubscriptionChipText')}
			&nbsp;
			<Text className={ellipsisClass} size="sm" bold>
				{agreementDescription}
			</Text>
		</div>
	);

	return (
		<div className={classes.b()}>
			<AssistChip icon="info-circle" type="info" title={title} />
		</div>
	);
};

export default AgreementFollowUpChip;

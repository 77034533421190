'use strict';
angular.module('domain.opportunity', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$stateProvider',
	function ($stateProvider) {
		$stateProvider.state('periodizations', {
			templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
			url: '/:customerId/periodizations/',
			controller: 'ListPeriodizations as listViewHelper',
			section: 'sale',
			resolve: {
				authUser: [
					'$routeGuard',
					function ($routeGuard) {
						return $routeGuard.requireUser();
					}
				],
				hasFeature: [
					'FeatureHelper',
					'$q',
					function (FeatureHelper) {
						return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PERIODIZATION);
					}
				],
				listViews: [
					'AppService',
					function (AppService) {
						return AppService.loadedPromise.then(function () {
							return AppService.getListViews('periodization');
						});
					}
				]
			}
		});
	}
]);

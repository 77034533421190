'use strict';

angular.module('upModals.controllers').controller('UploadCreativeCtrl', [
	'$scope',
	'Ads',
	'$upModal',
	'$modalParams',
	'URL',
	'API',
	'AppService',
	function ($scope, Ads, $upModal, $modalParams, URL, API, AppService) {
		var UploadCreativeCtrl = this;
		var customerId = AppService.getCustomerId();

		var FORMATS = {
			// Adgear
			1: { name: 'Leaderboard', width: 728, height: 90 },
			2: { name: 'MediumRectangle', width: 300, height: 250 },
			3: { name: 'WideSkyscraper', width: 160, height: 600 },
			4: { name: 'LargeRectangle', width: 336, height: 280 },
			5: { name: 'Full-SizeBanner', width: 468, height: 60 },
			6: { name: 'Skyscraper', width: 120, height: 600 },
			7: { name: 'Half Page', width: 300, height: 600 },
			9: { name: 'Square', width: 250, height: 250 },
			10: { name: 'SmallSquare', width: 200, height: 200 },
			11: { name: 'Billboard', width: 970, height: 250 },
			12: { name: 'StandardBanner', width: 320, height: 50 },
			13: { name: 'Rectangle', width: 180, height: 150 },
			14: { name: 'Banner', width: 300, height: 50 },
			15: { name: '234x60', width: 234, height: 60 },
			16: { name: '720x360', width: 720, height: 300 },
			17: { name: 'InterstitialPortrait', width: 320, height: 480 },
			18: { name: 'InterstitialLandscape', width: 480, height: 320 },
			21: { name: '725x360', width: 725, height: 360 },
			22: { name: '741x360', width: 741, height: 360 },
			23: { name: '640x960', width: 640, height: 960 },
			28: { name: '240x400', width: 240, height: 400 },
			29: { name: 'Smartphone Fixed HD', width: 600, height: 100 },
			30: { name: '640x100', width: 640, height: 100 },
			31: { name: 'Panorama (small)', width: 980, height: 120 },
			32: { name: 'Panorama (large)', width: 980, height: 240 },
			33: { name: '125x125', width: 125, height: 125 },
			34: { name: '480x75', width: 480, height: 75 },
			35: { name: '480x60', width: 480, height: 60 },
			36: { name: '300x480', width: 300, height: 480 },
			37: { name: '480x280', width: 480, height: 280 },
			38: { name: '480x400', width: 480, height: 400 },
			39: { name: '250x360', width: 250, height: 360 },
			40: { name: '320x380', width: 320, height: 380 },
			41: { name: '320x320', width: 320, height: 320 },
			48: { name: '250x600', width: 250, height: 600 },
			51: { name: '600x500', width: 600, height: 500 }
		};
		FORMATS = {
			// Bidtheatre
			1: { name: '728x90', width: 728, height: 90 },
			2: { name: '250x360', width: 250, height: 360 },
			3: { name: 'Text', width: 500, height: 160 },
			4: { name: '300x250', width: 300, height: 250 },
			5: { name: '250x240', width: 250, height: 240 },
			6: { name: '140x350', width: 140, height: 350 },
			7: { name: '468x60', width: 468, height: 60 },
			8: { name: '234x60', width: 234, height: 60 },
			9: { name: '160x600', width: 160, height: 600 },
			10: { name: '120x600', width: 120, height: 600 },
			11: { name: '300x600', width: 300, height: 600 },
			12: { name: '125x125', width: 125, height: 125 },
			13: { name: '980x120', width: 980, height: 120 },
			14: { name: '250x250', width: 250, height: 250 },
			15: { name: '980x400', width: 980, height: 400 },
			16: { name: '768x90', width: 768, height: 90 },
			17: { name: '468x400', width: 468, height: 400 },
			18: { name: '1000x30', width: 1000, height: 30 },
			19: { name: '200x300', width: 200, height: 300 },
			20: { name: '924x110', width: 924, height: 110 },
			21: { name: '660x201', width: 660, height: 201 },
			22: { name: '300x300', width: 300, height: 300 },
			23: { name: '468x468', width: 468, height: 468 },
			25: { name: '550x400', width: 550, height: 400 },
			26: { name: '117x117', width: 117, height: 117 },
			27: { name: '150x250', width: 150, height: 250 },
			28: { name: '200x200', width: 200, height: 200 },
			29: { name: '250x200', width: 250, height: 200 },
			30: { name: '550x450', width: 550, height: 450 },
			31: { name: '450x450', width: 450, height: 450 },
			32: { name: '600x60', width: 600, height: 60 },
			33: { name: '600x300', width: 600, height: 300 },
			34: { name: '555x450', width: 555, height: 450 },
			35: { name: '720x480', width: 720, height: 480 },
			36: { name: '320x240', width: 320, height: 240 },
			37: { name: '640x360', width: 640, height: 360 },
			38: { name: '520x15', width: 520, height: 15 },
			39: { name: '140x1180', width: 140, height: 1180 },
			40: { name: '140x597', width: 140, height: 597 },
			41: { name: '400x400', width: 400, height: 400 },
			42: { name: '500x700', width: 500, height: 700 },
			43: { name: '480x280', width: 480, height: 280 },
			44: { name: '220x265', width: 220, height: 265 },
			45: { name: '468x120', width: 468, height: 120 },
			46: { name: '576x324', width: 576, height: 324 },
			48: { name: '720x576', width: 720, height: 576 },
			49: { name: '847x179 - Panorama', width: 847, height: 179 },
			50: { name: '960x250', width: 960, height: 250 },
			51: { name: '468x240', width: 468, height: 240 },
			52: { name: '990x300', width: 990, height: 300 },
			53: { name: '848x179 - ap3 Panorama', width: 848, height: 179 },
			54: { name: '960x200', width: 960, height: 200 },
			55: { name: '200x215', width: 200, height: 215 },
			56: { name: '980x200', width: 980, height: 200 },
			57: { name: '240x400', width: 240, height: 400 },
			58: { name: '833x200', width: 833, height: 200 },
			59: { name: '512x288', width: 512, height: 288 },
			60: { name: '200x600', width: 200, height: 600 },
			61: { name: '995x100', width: 995, height: 100 },
			62: { name: '728x408', width: 728, height: 408 },
			63: { name: '336x280', width: 336, height: 280 },
			64: { name: '640x156', width: 640, height: 156 },
			65: { name: '200x900', width: 200, height: 900 },
			66: { name: '150x120', width: 150, height: 120 },
			67: { name: '235x90', width: 235, height: 90 },
			68: { name: '185x79', width: 185, height: 79 },
			69: { name: '260200', width: 260, height: 200 },
			70: { name: '140x175 ap3.ee', width: 140, height: 175 },
			71: { name: '210x360', width: 210, height: 360 },
			72: { name: '250x800', width: 250, height: 800 },
			73: { name: '440x146', width: 440, height: 146 },
			74: { name: '180x500', width: 180, height: 500 },
			75: { name: '980x150', width: 980, height: 150 },
			76: { name: '768x150', width: 768, height: 150 },
			77: { name: '250x340', width: 250, height: 340 },
			78: { name: '202x150', width: 202, height: 150 },
			79: { name: '250x90', width: 250, height: 90 },
			80: { name: '380x150', width: 380, height: 150 },
			81: { name: '1000x100', width: 1000, height: 100 },
			82: { name: '728x500', width: 728, height: 500 },
			83: { name: '800x500', width: 800, height: 500 },
			84: { name: '180x180', width: 180, height: 180 },
			85: { name: '1001x450', width: 1001, height: 450 },
			86: { name: '995x180', width: 995, height: 180 },
			87: { name: '1000x200', width: 1000, height: 200 },
			88: { name: '788x150', width: 788, height: 150 },
			89: { name: '750x200', width: 750, height: 200 },
			90: { name: '180x750', width: 180, height: 750 },
			91: { name: '300x60', width: 300, height: 60 },
			92: { name: '350x350', width: 350, height: 350 },
			93: { name: '145x145', width: 145, height: 145 },
			94: { name: '600x200', width: 600, height: 200 },
			95: { name: '165x200', width: 165, height: 200 },
			96: { name: '640x200', width: 640, height: 200 },
			97: { name: '995x200', width: 995, height: 200 },
			98: { name: '200x400', width: 200, height: 400 },
			99: { name: '798x150', width: 798, height: 150 },
			100: { name: '110x80', width: 110, height: 80 },
			101: { name: '980x510', width: 980, height: 510 },
			102: { name: '974x110', width: 974, height: 110 },
			103: { name: '405x300', width: 405, height: 300 },
			104: { name: '560x200', width: 560, height: 200 },
			105: { name: '1x1 Pixel', width: 1, height: 1 },
			106: { name: '500x330', width: 500, height: 330 },
			107: { name: '689x100', width: 689, height: 100 },
			108: { name: '1001x400', width: 1001, height: 400 },
			109: { name: '800x35', width: 800, height: 35 },
			111: { name: '900x90', width: 900, height: 90 },
			112: { name: '990x90', width: 990, height: 90 },
			113: { name: '140x600', width: 140, height: 600 },
			114: { name: 'Apollo.lv 420x260', width: 420, height: 260 },
			116: { name: '468x300 ap3.ee', width: 468, height: 300 },
			118: { name: '995x150 Reisileidja.delfi', width: 995, height: 150 },
			119: { name: '560x240', width: 560, height: 240 },
			120: { name: '1000x120', width: 1000, height: 120 },
			121: { name: '480x240', width: 480, height: 240 },
			122: { name: '800x600', width: 800, height: 600 },
			123: { name: '440x300', width: 440, height: 300 },
			124: { name: '750x100', width: 750, height: 100 },
			125: { name: '180x150', width: 180, height: 150 },
			126: { name: '660x200', width: 660, height: 200 },
			127: { name: '1024x768', width: 1024, height: 768 },
			128: { name: '600x800', width: 600, height: 800 },
			129: { name: '924x220', width: 924, height: 220 },
			130: { name: '1240x400', width: 1240, height: 400 },
			131: { name: '180x90', width: 180, height: 90 },
			132: { name: '250x500', width: 250, height: 500 },
			133: { name: '980x90', width: 980, height: 90 },
			134: { name: '580x300', width: 580, height: 300 },
			135: { name: '995x95', width: 995, height: 95 },
			136: { name: '980x100', width: 980, height: 100 },
			137: { name: '544x310', width: 544, height: 310 },
			138: { name: '775x70', width: 775, height: 70 },
			139: { name: '792x150', width: 792, height: 150 },
			140: { name: 'TD', width: 600, height: 100 },
			141: { name: 'AdReach 750x200 + splash 750x300 11-11', width: 750, height: 300 },
			142: { name: '300x75_mobile_testing', width: 300, height: 75 },
			143: { name: '468x180', width: 468, height: 180 },
			144: { name: '220x220', width: 220, height: 220 },
			145: { name: '170x90', width: 170, height: 90 },
			146: { name: '200x100', width: 200, height: 100 },
			147: { name: '135x120', width: 135, height: 120 },
			148: { name: '200x80', width: 200, height: 80 },
			149: { name: '150x60', width: 150, height: 60 },
			150: { name: '1098*250', width: 1098, height: 250 },
			151: { name: '240x160', width: 240, height: 160 },
			155: { name: '400x270', width: 400, height: 270 },
			156: { name: '300x50', width: 300, height: 50 },
			157: { name: '980x316', width: 980, height: 316 },
			159: { name: '468x600', width: 468, height: 600 },
			160: { name: '980x250', width: 980, height: 250 },
			161: { name: '514x400', width: 514, height: 400 },
			162: { name: '980x240', width: 980, height: 240 },
			164: { name: '250x600', width: 250, height: 600 },
			165: { name: '1200x70', width: 1200, height: 70 },
			166: { name: '768x520', width: 768, height: 520 },
			167: { name: '970x31', width: 970, height: 31 },
			168: { name: '950x90', width: 950, height: 90 },
			169: { name: '970x90', width: 970, height: 90 },
			170: { name: '575x100', width: 575, height: 100 },
			171: { name: '480x80', width: 480, height: 80 },
			172: { name: '650x350', width: 650, height: 350 },
			174: { name: '160x200', width: 160, height: 200 },
			175: { name: '440x220', width: 440, height: 220 },
			176: { name: '250x1080', width: 250, height: 1080 },
			177: { name: '710x160', width: 710, height: 160 },
			178: { name: '320x50 (mobile)', width: 320, height: 50 },
			179: { name: '468x160', width: 468, height: 160 },
			180: { name: '680x80', width: 680, height: 80 },
			181: { name: '560x340', width: 560, height: 340 },
			182: { name: '1250x360', width: 1250, height: 360 },
			183: { name: '480x320', width: 480, height: 320 },
			184: { name: '468x220', width: 468, height: 220 },
			185: { name: '600x160', width: 600, height: 160 },
			186: { name: '360x250', width: 360, height: 250 },
			187: { name: '99x72 (Facebook sidebar ad)', width: 99, height: 72 },
			188: { name: '640x480', width: 640, height: 480 },
			189: { name: '980x300', width: 980, height: 300 },
			191: { name: '1920x700', width: 1920, height: 700 },
			192: { name: '980x124', width: 980, height: 124 },
			193: { name: '300x500', width: 300, height: 500 },
			194: { name: '930x180', width: 930, height: 180 },
			195: { name: '320x80 (mobile)', width: 320, height: 80 },
			196: { name: '320x90 (mobile)', width: 320, height: 90 },
			197: { name: '1000x800', width: 1000, height: 800 },
			198: { name: '1000x800', width: 800, height: 1000 },
			199: { name: '320x160', width: 320, height: 160 },
			200: { name: '320x320', width: 320, height: 320 },
			201: { name: '1040x120', width: 1040, height: 120 },
			202: { name: '2000x120', width: 2000, height: 120 },
			203: { name: '300x200', width: 300, height: 200 },
			204: { name: '285x540', width: 285, height: 540 },
			205: { name: '500x400', width: 500, height: 400 },
			206: { name: '580x400', width: 580, height: 400 },
			207: { name: '1250x240', width: 1250, height: 240 },
			208: { name: '300x120', width: 300, height: 120 },
			209: { name: '250x750', width: 250, height: 750 },
			210: { name: '600x315', width: 600, height: 315 },
			211: { name: '250x310', width: 250, height: 310 },
			212: { name: '251x251', width: 251, height: 251 },
			213: { name: '500x240', width: 500, height: 240 },
			214: { name: '240x250', width: 240, height: 250 },
			215: { name: '1080x120', width: 1080, height: 120 },
			216: { name: '1280x720', width: 1280, height: 720 },
			217: { name: '320x480', width: 320, height: 480 },
			218: { name: '720x408', width: 720, height: 408 },
			219: { name: '300x480', width: 300, height: 480 },
			220: { name: '752x270', width: 752, height: 270 },
			221: { name: '254x133', width: 254, height: 133 },
			222: { name: '1920x1080', width: 1920, height: 1080 },
			223: { name: '560x292', width: 560, height: 292 },
			224: { name: '470x246', width: 470, height: 246 },
			225: { name: '250x480', width: 250, height: 480 },
			226: { name: '970x418', width: 970, height: 418 },
			227: { name: '970x250', width: 970, height: 250 },
			228: { name: '1024x576', width: 1024, height: 576 },
			229: { name: '468*200', width: 468, height: 200 },
			230: { name: '250x300', width: 250, height: 300 },
			231: { name: 'Video', width: 0, height: 0 },
			232: { name: '550x360', width: 550, height: 360 },
			233: { name: '300x280', width: 300, height: 280 },
			234: { name: '1030x250', width: 1030, height: 250 },
			235: { name: '1030x120', width: 1030, height: 120 },
			236: { name: '300x360', width: 300, height: 360 },
			237: { name: '995x600', width: 995, height: 600 },
			238: { name: '780x600', width: 780, height: 600 },
			239: { name: '844x178', width: 844, height: 178 },
			240: { name: '390x280', width: 390, height: 280 },
			241: { name: '854x480', width: 854, height: 480 },
			242: { name: '852x480', width: 852, height: 480 },
			243: { name: '980x600', width: 980, height: 600 },
			244: { name: '480x400', width: 480, height: 400 },
			245: { name: '640x450', width: 640, height: 450 },
			246: { name: '640x640', width: 640, height: 640 },
			247: { name: '300x150', width: 300, height: 150 },
			248: { name: '928x90', width: 928, height: 90 },
			249: { name: '640x79', width: 640, height: 79 },
			250: { name: '640x80', width: 640, height: 80 },
			251: { name: '640x157', width: 640, height: 157 },
			252: { name: '700x200', width: 700, height: 200 },
			253: { name: '312x545', width: 312, height: 545 },
			254: { name: '530x400', width: 530, height: 400 },
			255: { name: '750x600', width: 750, height: 600 },
			256: { name: '320x120', width: 320, height: 120 },
			257: { name: '950x160', width: 950, height: 160 },
			259: { name: '852x210', width: 852, height: 210 },
			260: { name: '1100x210', width: 1100, height: 210 },
			261: { name: '320x100', width: 320, height: 100 },
			262: { name: '1250x480', width: 1250, height: 480 },
			263: { name: '180x600', width: 180, height: 600 },
			264: { name: '720x300', width: 720, height: 300 },
			265: { name: '180x900', width: 180, height: 900 },
			266: { name: '180x650', width: 180, height: 650 },
			267: { name: '580x500', width: 580, height: 500 },
			268: { name: '980x320', width: 980, height: 320 },
			269: { name: '1250x320', width: 1250, height: 320 },
			270: { name: '1200x628', width: 1200, height: 628 },
			271: { name: '660x557', width: 660, height: 557 },
			272: { name: '320x270', width: 320, height: 270 },
			273: { name: '960x180', width: 960, height: 180 },
			274: { name: '768x1004', width: 768, height: 1004 },
			275: { name: '1024x748', width: 1024, height: 748 },
			276: { name: '1600x40', width: 1600, height: 40 },
			277: { name: '1280x800', width: 1280, height: 800 },
			278: { name: '760x400', width: 760, height: 400 },
			279: { name: '600x600', width: 600, height: 600 },
			280: { name: '310x280', width: 310, height: 280 },
			281: { name: '768x432', width: 768, height: 432 },
			282: { name: '250x400', width: 250, height: 400 },
			283: { name: '520x360', width: 520, height: 360 },
			284: { name: '659x365', width: 659, height: 365 },
			285: { name: '690x160', width: 690, height: 160 },
			286: { name: '480x220', width: 480, height: 220 },
			287: { name: '360x360', width: 360, height: 360 },
			288: { name: '250x160', width: 250, height: 160 },
			289: { name: '1250x160', width: 1250, height: 160 },
			290: { name: '900x200', width: 900, height: 200 },
			291: { name: '300x160', width: 300, height: 160 },
			292: { name: '370x180', width: 370, height: 180 },
			293: { name: '480x270', width: 480, height: 270 },
			294: { name: '576x576', width: 576, height: 576 },
			295: { name: '320x250', width: 320, height: 250 },
			296: { name: '500x460', width: 500, height: 460 },
			297: { name: '540x360', width: 540, height: 360 },
			298: { name: '150x150', width: 150, height: 150 },
			299: { name: '500x200', width: 500, height: 200 },
			300: { name: '500x500', width: 500, height: 500 },
			301: { name: '725x90', width: 725, height: 90 },
			302: { name: '120x60', width: 120, height: 60 },
			303: { name: '600x400', width: 600, height: 400 },
			304: { name: '1272x250', width: 1272, height: 250 },
			305: { name: '1050x240', width: 1050, height: 240 },
			306: { name: '600x500', width: 600, height: 500 },
			307: { name: '1270x800', width: 1270, height: 800 },
			308: { name: '430x300', width: 430, height: 300 },
			309: { name: '980x480', width: 980, height: 480 },
			310: { name: '480x360', width: 480, height: 360 },
			311: { name: '468x360', width: 468, height: 360 },
			312: { name: '980x360', width: 980, height: 360 },
			313: { name: '1140x300', width: 1140, height: 300 },
			314: { name: '980x220', width: 980, height: 220 },
			315: { name: '1050x576', width: 1050, height: 576 },
			316: { name: '690x390', width: 690, height: 390 },
			317: { name: '300x545', width: 300, height: 545 },
			318: { name: '1920x150', width: 1920, height: 150 },
			319: { name: '250x720', width: 250, height: 720 },
			320: { name: '1245x206', width: 1245, height: 206 },
			321: { name: '300x400', width: 300, height: 400 },
			322: { name: '800x800', width: 800, height: 800 },
			323: { name: '1280x563', width: 1280, height: 563 },
			324: { name: '650x650', width: 650, height: 650 },
			325: { name: '1600x900', width: 1600, height: 900 },
			326: { name: '928x120', width: 928, height: 120 },
			327: { name: '930x600', width: 930, height: 600 },
			328: { name: '960x540', width: 960, height: 540 },
			329: { name: '240x240', width: 240, height: 240 },
			330: { name: '380x400', width: 380, height: 400 },
			331: { name: '982x40', width: 982, height: 40 },
			332: { name: '1140x250', width: 1140, height: 250 },
			333: { name: '240x600', width: 240, height: 600 }
		};

		UploadCreativeCtrl.formats = [];
		_.forEach(_.keys(FORMATS), function (key) {
			UploadCreativeCtrl.formats.push({
				id: key,
				width: FORMATS[key].width,
				height: FORMATS[key].height,
				name: FORMATS[key].name
			});
		});
		UploadCreativeCtrl.creative = {};

		UploadCreativeCtrl.checkProtocol = function () {
			if (!UploadCreativeCtrl.creative.url || !UploadCreativeCtrl.creative.url.length) {
				return;
			}
			if (UploadCreativeCtrl.creative.url.indexOf('http://') !== -1) {
				return;
			}
			if (UploadCreativeCtrl.creative.url.indexOf('https://') !== -1) {
				return;
			}
			UploadCreativeCtrl.creative.url = 'http://' + UploadCreativeCtrl.creative.url;
		};

		UploadCreativeCtrl.setDimensions = function (format) {
			UploadCreativeCtrl.creative.width = format.width;
			UploadCreativeCtrl.creative.height = format.height;
		};

		UploadCreativeCtrl.filterFormat = function (format) {
			var width, height;
			if (!UploadCreativeCtrl.filter) {
				return true;
			}
			var hasX = UploadCreativeCtrl.filter.toLowerCase().split('x');

			hasX[0] = hasX[0].trim();
			if (hasX[1]) {
				hasX[1] = hasX[1].trim();
				height = hasX[1];
			} else {
				height = hasX[0];
			}
			width = hasX[0];

			if (hasX[0] && hasX[1]) {
				if (format.width.toString().indexOf(width) === 0 && format.height.toString().indexOf(height) === 0) {
					return true;
				} else {
					return false;
				}
			}

			if (format.width.toString().indexOf(width) === 0) {
				return true;
			}
			if (format.height.toString().indexOf(height) === 0) {
				return true;
			}
			return false;
		};
		var processUploaded = function (file) {
			UploadCreativeCtrl.file = file;
			if (file.dimension && FORMATS[file.dimension]) {
				file.width = FORMATS[file.dimension].width;
				file.height = FORMATS[file.dimension].height;
			}
			UploadCreativeCtrl.creative = {
				//externalFileId: file..id,
				fileId: file.id,
				name: file.filename
					.replace('.png', '')
					.replace('.jpg', '')
					.replace('.gif', '')
					.replace('.jpeg', '')
					.replace('.zip', ''),
				mimetype: file.mimetype,
				size: file.size,
				width: file.width,
				height: file.height,
				code: file.code,
				formatId: file.dimension,
				url: '',
				language: 'any',
				altText: '',
				type: file.type
			};
			UploadCreativeCtrl.valid = true;
		};

		UploadCreativeCtrl.save = function () {
			UploadCreativeCtrl.checkProtocol();
			UploadCreativeCtrl.saving = true;
			Ads.customer(customerId)
				.createCreative(UploadCreativeCtrl.creative)
				.then(function (res) {
					UploadCreativeCtrl.saving = false;
					$scope.resolve(res.data);
				})
				.catch(function () {
					var fileType = UploadCreativeCtrl.file ? UploadCreativeCtrl.file.type : null;
					UploadCreativeCtrl.saving = false;
					UploadCreativeCtrl.file = null;
					UploadCreativeCtrl.creative = null;
					if (fileType === 'zip') {
						// this error is prob because index.html is not in root path of archive
						UploadCreativeCtrl.err = 'ads.errWhenSavingZipCreative';
						UploadCreativeCtrl.errTitle = 'ads.errWhenSavingZipCreativeTitle';
					} else {
						UploadCreativeCtrl.err = 'ads.unknownUploadErr';
						UploadCreativeCtrl.errTitle = 'ads.unknownUploadErrTitle';
					}
				});
		};

		UploadCreativeCtrl.uploadErrorUnderstood = function () {
			UploadCreativeCtrl.err = null;
			UploadCreativeCtrl.uploadFile();
		};

		UploadCreativeCtrl.uploadFile = function () {
			$upModal
				.open('uploadFile', {
					isCreative: true,
					closeOnUpload: true
				})
				.then(
					function (file) {
						UploadCreativeCtrl.type = 'upload';
						processUploaded(file);
					},
					function (err) {
						var creativeErrors = [70, 71, 72, 73]; // only display the error if these error codes
						if (!err) {
							return;
						}
						UploadCreativeCtrl.file = null;
						UploadCreativeCtrl.creative = null;
						UploadCreativeCtrl.showErrElevo = false;
						if (!err.error || !err.error.errorCode || creativeErrors.indexOf(err.error.errorCode) === -1) {
							UploadCreativeCtrl.err = 'ads.unknownUploadErr';
							UploadCreativeCtrl.err = 'ads.unknownUploadErrTitle';
						} else {
							UploadCreativeCtrl.err = 'error.' + err.error.errorCode;
							UploadCreativeCtrl.errTitle = 'error.' + err.error.errorCode + 'title';
							if (err.error.errorCode === 73) {
								UploadCreativeCtrl.elevoDesciption = `error.${err.error.errorCode}dimesions`;
								UploadCreativeCtrl.showErrElevo = true;
							}
						}
					}
				);
		};
	}
]);

import AdminHeader from 'Components/Admin/AdminHeader';
import logError from 'App/babel/helpers/logError';
import { Button, Toggle, Checkbox } from '@upsales/components';

ReactTemplates.admin.currencies = window.ReactCreateClass({
	getInitialState: function () {
		return {
			editCurrency: null
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			currencies: t('admin.currencies'),
			currenciesDescription: t('admin.currenciesDescription'),
			masterCurrency: t('admin.masterCurrency'),
			name: t('default.name'),
			active: t('default.active'),
			inactive: t('default.inactive'),
			addCurrency: t('admin.addCurrency'),
			currencyRate: t('default.currencyRate'),
			save: t('default.save'),
			cancel: t('default.abort'),
			currentRate: t('admin.currentRate'),
			copyRate: t('admin.copyRate'),
			autoUpdate: t('admin.autoUpdateCurrency'),
			info: t('admin.currencies.info'),
			readMore: t('default.readMore'),
			activeCurrencies: t('admin.currencies.active'),
			activateMultiCurrency: t('admin.currencies.activateMultiCurrency')
		};
	},
	addRow: function () {
		var currencies = this.props.rootData.pageData.currencies;

		var currency = _.find(Tools.currenciesISO4217, function (currency) {
			return _.find(currencies, { iso: currency.iso }) === undefined && currency;
		});

		var newCurrency = _.cloneDeep(currency);
		newCurrency.rate = '1';
		newCurrency.originalRate = 1;
		newCurrency.rateChanged = false;

		this.setState({
			isUpdate: false,
			editCurrency: newCurrency
		});
	},
	editCurrency: function (currency) {
		if (currency.masterCurrency) {
			return;
		}

		var editCurrency = _.cloneDeep(currency);
		editCurrency.rate = 1.0 / currency.rate;
		editCurrency.rate = editCurrency.rate.toFixed(2).replace('.', ',');
		editCurrency.originalRate = currency.rate;
		editCurrency.rateChanged = false;

		this.setState({
			isUpdate: true,
			editCurrency: editCurrency
		});
	},
	stopEdit: function () {
		this.setState({
			editCurrency: null,
			isUpdate: false
		});
	},
	getFlag: function (iso) {
		var config = Tools.currenciesISO4217[iso];

		if (config) {
			if (config.icon) {
				return config.icon;
			} else if (config.countries && config.countries.length) {
				return 'flag-icon flag-icon-' + config.countries[0].toLowerCase();
			}
		}
		return null;
	},
	onSaveCurrency: function () {
		var self = this;
		this.props.rootData.pageData
			.onSaveCurrency(this.state.editCurrency, this.state.isUpdate)
			.then(function () {
				self.stopEdit();
			})
			.catch(e => logError(e, 'Failed to save currency'));
	},
	onCurrencyActiveChange: function (iso, value, event) {
		event.stopPropagation();
		this.props.rootData.pageData.onCurrencyActiveChange(iso, value);
	},
	onISOChanged: function (currency) {
		var editCurrency = this.state.editCurrency;
		editCurrency.iso = currency.iso;
		this.setState({ editCurrency: editCurrency });
	},
	onRateChanged: function (event) {
		var editCurrency = this.state.editCurrency;
		var newValue = event.target.value;

		const hasMoreThanOneDelimiter = () => {
			const allowedDelimiters = [',', '.'];
			const currencyCharacters = newValue.split('');
			const delimiterScore = currencyCharacters.filter(r => allowedDelimiters.includes(r)).length;
			return delimiterScore > 1;
		};

		// Only allowed to have one comma or one dot
		if (hasMoreThanOneDelimiter()) {
			return;
		}

		newValue = newValue.replace(/[^0-9,.]/g, '');
		var newValueNumber = newValue.replace(',', '.');
		newValueNumber = Number(newValueNumber);

		if (newValueNumber > 1000000) {
			return;
		}

		editCurrency.rate = newValue;
		editCurrency.rateChanged = true;
		this.setState({ editCurrency: editCurrency });
	},
	renderRow: function (currency, i) {
		var rateColumn;
		var activeCheck = null;
		var rootData = this.props.rootData;
		var $translate = Tools.$translate;

		if (currency.masterCurrency) {
			rateColumn = <span className="master-currency">{this.lang.masterCurrency.toUpperCase()}</span>;
		} else {
			var reversedRate = Tools.$filter('numberFormat')(1.0 / currency.rate, false, 2);
			rateColumn = <span>{reversedRate}</span>;
			activeCheck = (
				<Checkbox
					size="sm"
					checked={currency.active}
					disabled={rootData.saving}
					onChange={this.onCurrencyActiveChange.bind(this, currency.iso)}
				/>
			);
		}

		if (this.state.isUpdate && this.state.editCurrency.iso === currency.iso) {
			return this.renderAddRow();
		} else {
			var flag = this.getFlag(currency.iso);
			var key = 'isoCurrency.' + currency.iso;
			var name = $translate(key);
			var nameClass = name === key ? 'no-name' : 'name';
			var iconClass = flag ? flag + ' icon' : 'no-icon';

			return (
				<tr
					className={currency.masterCurrency ? 'inactive-row' : 'clickable'}
					key={'currency-' + i}
					onClick={this.editCurrency.bind(this, currency)}
				>
					<td>
						<span className={iconClass}></span>
						<span className={nameClass}>{name}</span>
						<span className="iso">{currency.iso}</span>
					</td>
					<td>{rateColumn}</td>
					<td>{activeCheck}</td>
				</tr>
			);
		}
	},
	renderAddRow: function () {
		var rootData = this.props.rootData;
		var editCurrency = this.state.editCurrency;
		var $translate = Tools.$translate;

		var isoColumn = null;

		if (this.state.isUpdate) {
			var flag = this.getFlag(editCurrency.iso);
			var key = 'isoCurrency.' + editCurrency.iso;
			var name = $translate(key);
			var nameClass = name === key ? 'no-name' : 'name';
			var iconClass = flag ? flag + ' icon' : 'no-icon';

			isoColumn = [
				<span key="isoColumn0" className={iconClass}></span>,
				<span key="isoColumn1" className={nameClass}>
					{name}
				</span>,
				<span key="isoColumn2" className="iso">
					{editCurrency.iso}
				</span>
			];
		} else {
			isoColumn = (
				<ReactTemplates.INPUTS.upCurrency
					required={true}
					disabled={rootData.loading}
					exclude={_.pluck(this.props.rootData.pageData.currencies, 'iso')}
					value={this.state.editCurrency}
					onChange={this.onISOChanged}
				/>
			);
		}

		return (
			<tr key="addCurrency" style={{ height: '70px', backgroundColor: '#f5f5f5' }}>
				<td>
					<div style={{ marginBottom: '5px', maxWidth: 300 }}>{isoColumn}</div>
				</td>
				<td>
					<div
						className={editCurrency.rate ? 'floating-label-input has-value' : 'floating-label-input'}
						style={{ marginBottom: '5px' }}
					>
						<label>{this.lang.currencyRate}</label>
						<input
							type="text"
							disabled={rootData.loading}
							onChange={this.onRateChanged}
							value={editCurrency.rate}
						/>
					</div>
				</td>
				<td className="edit-controls" style={{ textAlign: 'right' }}>
					<Button onClick={this.onSaveCurrency} disabled={rootData.loading || !editCurrency.rate} size="sm">
						{this.lang.save}
					</Button>
					<Button onClick={this.stopEdit} disabled={rootData.loading} type="link" size="sm">
						{this.lang.cancel}
					</Button>
				</td>
			</tr>
		);
	},
	render: function () {
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;
		var table = null;

		if (rootData.pageData.hasMultiCurrencies) {
			var rows = _.chain(rootData.pageData.currencies)
				.sortBy('iso')
				.sortBy(function (c) {
					return !c.masterCurrency;
				})
				.map(this.renderRow)
				.value();

			if (this.state.editCurrency && !this.state.isUpdate) {
				rows.splice(1, 0, this.renderAddRow());
			}

			table = (
				<div className="admin-table">
					<div className="admin-table-top">
						<span className="admin-table-title">{this.lang.activeCurrencies}</span>
						<div className="pull-right">
							<Button onClick={this.addRow} size="sm" disabled={rootData.loading}>
								{this.lang.addCurrency}
							</Button>
						</div>
					</div>

					<table>
						<thead>
							<tr>
								<th>{this.lang.name}</th>
								<th style={{ width: 200 }}>
									{this.lang.currencyRate} <ReactTemplates.elevio articleId={638} sidebar={true} />
								</th>
								<th style={{ width: 200 }}>{this.lang.active}</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</table>
				</div>
			);
		}

		var masterCurrency = _.find(rootData.pageData.currencies, { masterCurrency: true }) || null;

		if (masterCurrency) {
			masterCurrency = Tools.currenciesISO4217[masterCurrency.iso];
		}

		return (
			<div id="admin-page-currencies">
				<AdminHeader
					title={this.lang.currencies}
					description={this.lang.currenciesDescription}
					image="currency.svg"
				/>

				<div id="admin-content">
					<div className="admin-table master-currency-card">
						<div className="admin-info-row">
							<div className="info-row-info">
								{this.lang.info}
								<br />
								<span className="text-bright-blue">
									<ReactTemplates.elevio articleId={638} sidebar={true} text={this.lang.readMore} />
								</span>
							</div>
							<div className="info-row-content">
								<div className="title">{this.lang.masterCurrency}</div>
								<div className="master-currency-wrap">
									<ReactTemplates.INPUTS.upCurrency
										disabled={!pageData.canChangeMasterCurrency}
										required={true}
										value={masterCurrency}
										allowCustomCurrency={true}
										onChange={pageData.onMasterCurrencyChange}
									/>
								</div>
								<div className="toggle-multicurrency">
									<label>{this.lang.activateMultiCurrency}</label>
									<Toggle
										color="medium-green"
										checked={rootData.pageData.hasMultiCurrencies}
										onChange={rootData.pageData.toggleMultiCurrency}
									/>
								</div>
							</div>
						</div>
					</div>
					{table}
				</div>
			</div>
		);
	}
});

import React, { useState, useEffect, useRef } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './FileBrowserLabel.scss';
import { Icon, Title, Tooltip } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import T from 'Components/Helpers/translate';

interface Props {
	editLabel?: () => void;
	onSelect: () => void;
	onDelete?: () => void;
	selected: boolean;
	title: string;
	numEntities?: number;
}

const FileBrowserLabel = ({ editLabel, onSelect, onDelete, selected, title, numEntities }: Props) => {
	const [toolsVisible, setToolsVisible] = useState<boolean>(false);
	const classes = new bemClass('FileBrowserLabel');
	let numVisible = false;
	if (onDelete || editLabel) {
		numVisible = true;
	}
	const titleRef = useRef<HTMLDivElement>(null);
	const [isOverflowed, setIsOverflowed] = useState(false);
	useEffect(() => {
		if (titleRef.current) {
			setIsOverflowed(titleRef.current.scrollWidth > titleRef.current.offsetWidth);
		}
	}, [titleRef.current]);
	return (
		<div className={classes.elem('labelWrapper').mod({ selected, toolsVisible }).b()} onClick={() => onSelect()}>
			<Tooltip position="top" disabled={!isOverflowed} title={title}>
				<div
					ref={titleRef}
					className={classes.elem('title').mod({ numVisible: !numVisible }).b()}
					color="dark-grey"
				>
					{title}
				</div>
			</Tooltip>

			<div tabIndex={0} className={classes.elem('labelButtonsDiv').b()}>
				{editLabel ? (
					<Icon
						className={classes.elem('icon').b()}
						onClick={e => {
							e.stopPropagation();
							editLabel();
						}}
						name="edit"
					></Icon>
				) : null}
				{onDelete ? (
					<InlineConfirm
						className={classes.elem('deleteModal').b()}
						show={true}
						onConfirm={() => Promise.resolve(onDelete())}
						entity={T('file.label')}
						onVisibleChange={(vis: boolean) => setToolsVisible(vis)}
					>
						<Icon className={classes.elem('icon').b()} name="trash"></Icon>
					</InlineConfirm>
				) : null}
			</div>
			<Title className={classes.elem('num').mod({ numVisible }).b()} size="sm" color="dark-grey">
				{numEntities}
			</Title>
		</div>
	);
};
export default FileBrowserLabel;

import React from 'react';
import { connect } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { Headline, Text, Label, Input, Icon, Button } from '@upsales/components';
import { selectRole, toggleDesiredOutcome, saveNoMatch } from 'Store/reducers/DesiredOutcomeReducer';
import './NoMatch.scss';
import T from '../Helpers/translate';
import getAngularModule from '../../angularHelpers/getAngularModule';

const mapDispatchToProps = {
	selectRole,
	saveNoMatch,
	toggleDesiredOutcome
};

class NoMatch extends React.PureComponent {
	constructor(p) {
		super(p);
		const AppService = getAngularModule('AppService');
		const companyName = AppService.getAccountSelf().client.name;

		this.lang = {
			whatDoYouDo: T('desiredoutcome.noMatch.whatDoYouDo', { companyName }),
			dailyTask: T('desiredoutcome.noMatch.dailyTask', { companyName }),
			title: T('default.title'),
			previous: T('default.previous'),
			startUsingUpsales: T('desiredoutcome.outcome.startUsingUpsales'),
			addTitle: `${T('default.add')} ${T('default.title').toLowerCase()}`
		};

		this.state = {
			text: ''
		};
	}

	deSelectRole = () => {
		this.props.selectRole(null);
	};

	inputUpdated = event => {
		this.setState({
			text: event.target.value
		});
	};

	save = () => {
		this.props.saveNoMatch(this.state.text);
	};

	render() {
		const classes = new bemClass('DesiredOutcomeNoMatch');
		const saveDisabled = this.state.text.length < 2 || this.props.saving;
		const saveBtnColor = saveDisabled ? 'light-grey' : 'bright-blue';

		return (
			<div className={classes.b()}>
				<Headline style={{ textAlign: 'center' }} size="sm">
					{this.lang.whatDoYouDo}
				</Headline>
				<Text center={true} size="xl" color="grey-10">
					{this.lang.dailyTask}
				</Text>
				<div className={classes.elem('width-container').b()}>
					<Label value={this.state.text} maxLength={32} required={true}>
						{this.lang.title}
					</Label>
					<Input value={this.state.text} onChange={this.inputUpdated} placeholder={this.lang.addTitle} />
					<Button disabled={saveDisabled} color={saveBtnColor} size="lg" block={true} onClick={this.save}>
						{this.lang.startUsingUpsales}
					</Button>
					<Button type="link" block={true} onClick={this.deSelectRole}>
						<Icon style={{ marginRight: 10 }} name="chevron-left" /> {this.lang.previous}
					</Button>
				</div>
			</div>
		);
	}
}

NoMatch.defaultProps = {};

export const detached = NoMatch;

const Component = connect(null, mapDispatchToProps)(NoMatch);

export default Component;

'use strict';

angular.module('upResources').factory('Agreement', [
	'$resource',
	'URL',
	'API',
	'AgreementAttributes',
	'GenericMapper',
	'$stateParams',
	'CustomFieldMapper',
	'ResourceHelper',
	'ParseGeneric',
	function Agreement(
		$resource,
		URL,
		API,
		AgreementAttributes,
		GenericMapper,
		$stateParams,
		CustomFieldMapper,
		ResourceHelper,
		ParseGeneric
	) {
		var OA = AgreementAttributes();
		var Attribute = OA.attr;
		var Keys = OA.keys;
		var resourceUrl = URL + API + ':customerId/agreements/';
		var defaults = {
			customerId: function () {
				return $stateParams.customerId;
			},
			id: '@id'
		};

		function transformRequest(agreement) {
			var mapped;
			var meta = agreement.metadata;
			meta.$mappedCustom = undefined;
			GenericMapper.map(agreement, Attribute, function (err, mappedOrder) {
				mappedOrder.orderRow = agreement.orderRow;
				mapped = mappedOrder;
			});
			mapped.metadata = meta;
			mapped.createDiffOrder = agreement.createDiffOrder;
			mapped.createdFromOrderId = agreement.createdFromOrderId;

			return angular.toJson({ agreement: mapped });
		}

		var dateFields = ['regDate', 'modDate'];

		var methods = {
			get: {
				method: 'GET',
				responseType: 'json',
				transformResponse: ParseGeneric('ParseAgreement', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				})
			},
			query: {
				method: 'GET',
				isArray: false,
				transformResponse: ParseGeneric('ParseAgreement', {
					isArray: true,
					custom: 'order',
					dateFields: dateFields
				})
			},
			put: {
				method: 'PUT',
				transformRequest: transformRequest,
				transformResponse: ParseGeneric('ParseAgreement', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				})
			},
			post: {
				method: 'POST',
				transformRequest: transformRequest,
				transformResponse: ParseGeneric('ParseAgreement', {
					isArray: false,
					custom: 'order',
					dateFields: dateFields
				})
			},
			preview: {
				method: 'POST',
				url: resourceUrl + 'preview'
			},
			previewGroup: {
				method: 'POST',
				url: resourceUrl + 'previewGroup'
			},
			diffOrders: {
				method: 'POST',
				url: resourceUrl + 'diffOrders'
			},
			moveAgreement: {
				method: 'POST',
				url: resourceUrl + 'moveAgreement'
			},
			multi: { method: 'POST', isArray: false, url: resourceUrl + 'multi/:type' }
		};

		var Resource = $resource(resourceUrl + ':id', defaults, methods);

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'agreement',
			createSuccessBody: 'saved.agreement',
			updateSuccessBody: 'updated.agreement',
			deleteSucccessBody: 'deleted.agreement',
			createErrorBody: 'saveError.agreement',
			updateErrorBody: 'saveError.agreement',
			deleteErrorBody: 'deleteError.agreement'
		});

		var Model = {
			customer: function (customer) {
				var instance = {};

				instance.find = function (filter, options) {
					var params = _.extend(filter, options || {});
					var mapCustom = params.mapCustom || false;
					delete params.mapCustom;

					params.customerId = customer;

					if (mapCustom) {
						return Resource.query(params).$promise.then(CustomFieldMapper.map);
					}

					return Resource.query(params).$promise;
				};

				instance.get = function (id) {
					return Resource.get({ customerId: customer, id: id }).$promise;
				};

				instance.preview = function (agreement) {
					return Resource.preview({ agreement }).$promise;
				};
				// Todo: Move these to agreementGroup resource instead
				instance.previewGroup = function (
					agreements,
					agreementsToShowDiffOrder = [],
					isEdit,
					extraDiffOrderValue
				) {
					return Resource.previewGroup({
						agreements,
						agreementsToShowDiffOrder,
						isEdit,
						extraDiffOrderValue
					}).$promise;
				};

				instance.diffOrders = function (agreements, oneOffs) {
					return Resource.diffOrders({ agreements, oneOffs }).$promise;
				};

				instance.moveAgreement = function (agreement, clientId, contactId) {
					return Resource.moveAgreement({ agreement, clientId, contactId }).$promise;
				};

				instance.save = function (agreement, options) {
					var promise = Resource[agreement.id ? 'put' : 'post']({ customerId: customer }, agreement).$promise;

					promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !agreement.id);

							return res;
						})
						.catch(function (err) {
							// Notify user
							if (err.data.error?.key === 'InactiveClient') {
								helper.setDefaults({
									createErrorBody: 'error.inactive.client',
									updateErrorBody: 'error.inactive.client'
								});
							} else {
								helper.setDefaults({
									createErrorBody: 'saveError.agreement',
									updateErrorBody: 'saveError.agreement'
								});
							}
							helper.onSaveError(options, !agreement.id, err);
						});

					return promise;
				};

				instance['delete'] = function (agreement, options) {
					return Resource.delete({ customerId: customer }, agreement)
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, agreement);

							return res;
						})
						.catch(function () {
							// Notify user
							helper.onDeleteError(options);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: OA.requiredFields
		};

		return angular.extend(Resource, Model);
	}
]);

'use strict';

angular.module('upAttributes').service('MailAccountAttributes', [
	'FilterType',
	function (FilterType) {
		return function () {
			var attributes = {
				id: {
					field: 'id',
					type: FilterType.Number
				},
				defaultEmail: {
					field: 'defaultEmail',
					type: FilterType.String
				},
				defaultFrom: {
					field: 'defaultFrom',
					type: FilterType.String
				},
				disclaimer: {
					field: 'disclaimer',
					type: FilterType.String
				},
				mailOpenScore: {
					field: 'score.mailOpened',
					type: FilterType.Number
				},
				mailClickScore: {
					field: 'score.mailClicked',
					type: FilterType.Number
				},
				mailUnsubscribeScore: {
					field: 'score.undsubscribed',
					type: FilterType.Number
				},
				name: {
					field: 'address.name',
					type: FilterType.String
				},
				address: {
					field: 'address.address',
					type: FilterType.String
				},
				zipCode: {
					field: 'address.zipCode',
					type: FilterType.String
				},
				city: {
					field: 'address.city',
					type: FilterType.String
				},
				country: {
					field: 'address.country',
					type: FilterType.String
				},
				phone: {
					field: 'address.phone',
					type: FilterType.String
				},
				acceptedTermsDate: {
					field: 'acceptedTermsDate',
					type: FilterType.Date
				},
				limitEmailCount: {
					field: 'limitEmailCount',
					type: FilterType.Number
				},
				limitWeeks: {
					field: 'limitWeeks',
					type: FilterType.Number
				},
				emailWarningCount: {
					field: 'emailWarningCount',
					type: FilterType.Number
				},
				minEventDelayTime: {
					field: 'minEventDelayTime',
					type: FilterType.Number
				}
			};
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				keys: {
					ID: 'id'
				}
			};
		};
	}
]);

import UiElements from 'Components/UiElements';

angular.module('upDirectives').directive('uiElementsReact', [
	function () {
		return {
			restrict: 'A',
			link: function ($scope, $element, $attrs) {
				const getObject = function () {
					return $scope.$eval($attrs.object);
				};

				let renderTimeout;

				const render = elements => {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}
					renderTimeout = setTimeout(() => {
						ReactDOM.render(
							React.createElement(UiElements, {
								elements,
								object: getObject()
							}),
							$element[0],
							() => {}
						);
					}, 20);
				};

				render([]);

				const unsubWatch = $scope.$watch($attrs.uiElementsReact, function (elements) {
					if (elements) {
						render(elements);
						unsubWatch();
					}
				});

				$scope.$on('$destroy', () => ReactDOM.unmountComponentAtNode($element[0]));
			}
		};
	}
]);

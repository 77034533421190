'use strict';

angular.module('upResources').factory('Campaign', [
	'$resource',
	'$q',
	'CampaignAttributes',
	'URL',
	'API',
	'CacheService',
	'RequestBuilder',
	'ResourceHelper',
	'ParseGeneric',
	'MapGeneric',
	function Campaign(
		$resource,
		$q,
		CampaignAttributes,
		URL,
		API,
		CacheService,
		RequestBuilder,
		ResourceHelper,
		ParseGeneric,
		MapGeneric
	) {
		var AA = CampaignAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'campaign',
			createSuccessBody: 'saved.campaign',
			updateSuccessBody: 'updated.campaign',
			deleteSucccessBody: 'deleted.campaign',
			createErrorBody: 'saveError.campaign',
			updateErrorBody: 'saveError.campaign',
			deleteErrorBody: 'deleteError.campaign'
		});

		var dateFields = ['regDate', 'modDate'];

		var Model = {
			customer: function (customer) {
				var instance = {};
				var Campaign = null;
				var baseUrl = URL + API + customer + '/projects/';
				var Cache = CacheService.getCache(CacheService.cacheTypes.CAMPAIGN);
				var activeCacheKey = 'Campaigns.active';

				Campaign = $resource(
					baseUrl + ':id',
					{},
					{
						get: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCampaign', {
								isArray: false,
								custom: 'project',
								dateFields: dateFields
							}),
							skipDateConvert: true
						},
						find: {
							method: 'GET',
							isArray: false,
							transformResponse: ParseGeneric('ParseCampaign', {
								isArray: true,
								custom: 'project',
								dateFields: dateFields
							}),
							skipDateConvert: true
						},
						create: {
							method: 'POST',
							isArray: false,
							transformResponse: ParseGeneric('ParseCampaign', {
								isArray: false,
								custom: 'project',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric(null, {
								payloadName: 'project'
							}),
							skipDateConvert: true
						},
						update: {
							method: 'PUT',
							isArray: false,
							transformResponse: ParseGeneric('ParseCampaign', {
								isArray: false,
								custom: 'project',
								dateFields: dateFields
							}),
							transformRequest: MapGeneric(null, {
								payloadName: 'project'
							}),
							skipDateConvert: true
						},
						multi: { method: 'POST', isArray: false, url: baseUrl + 'multi/:type' }
					}
				);

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);

					return Campaign.find(params).$promise;
				};

				instance.get = function (id) {
					if (!id) {
						return $q.reject('no campaign id');
					}

					return Campaign.get({ id: id }).$promise;
				};

				instance.save = function (campaign, options) {
					var defered = $q.defer();

					if (!campaign) {
						return defered.reject('No campaign');
					}

					if (campaign.id) {
						Campaign.update(
							{ id: campaign.id },
							{ project: campaign },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					} else {
						Campaign.create(
							{ project: campaign },
							function (response) {
								if (response.error) {
									defered.reject(response);
								}
								defered.resolve(response);
							},
							defered.reject
						);
					}

					defered.promise
						.then(function (res) {
							// Notify user
							helper.onSave(options, res.data, !campaign.id);
						})
						.catch(function () {
							// Notify user
							helper.onSaveError(options, !campaign.id);
						});

					return defered.promise;
				};

				instance.getCachedActive = function () {
					var cached = Cache.get(activeCacheKey);

					if (cached) {
						return $q.when(cached);
					} else {
						var filter = RequestBuilder();
						filter
							.addSort(Attribute.name, true)
							.addFilter(Attribute.active, filter.comparisonTypes.Equals, 1);
						filter.fields = ['id', 'name'];
						return instance.find(filter.build()).then(function (res) {
							Cache.put(activeCacheKey, res);
							return res;
						});
					}
				};

				instance['delete'] = function (campaign, options) {
					// Clear cache
					Cache.remove(activeCacheKey);

					return Campaign.delete({ id: campaign.id })
						.$promise.then(function (res) {
							// Notify user
							helper.onDelete(options, campaign);

							return res;
						})
						.catch(function (err) {
							// Notify user
							helper.onDeleteError(options, err);
						});
				};

				return instance;
			},
			attr: Attribute,
			keys: Keys,
			requiredFields: AA.requiredFields
		};

		return Model;
	}
]);

'use strict';

angular.module('upDirectives').directive('upInlineEdit', [
	'$q',
	'$filter',
	'$compile',
	'NotificationService',
	'DateFormat',
	'$document',
	'$datepicker',
	'$parse',
	function ($q, $filter, $compile, NotificationService, DateFormat, $document, $datepicker, $parse) {
		return {
			restrict: 'A',
			scope: {
				callback: '&',
				value: '=',
				opt: '=options',
				optionsFnc: '&',
				ngDisabled: '=ngDisabled'
			},
			replace: true,
			template: '<span class="up-inline-edit"></span>',
			compile: function (element, $attrs) {
				var orgTag = element.context.tagName.toLowerCase();

				return function (scope, element, attr) {
					var ctrl;
					var mainWrapperElem = element;
					var wrapperElem = angular.element('<span class="inline-wrapper" />');
					var placeholderElem;
					switch (orgTag) {
						case 'textarea':
							placeholderElem = angular.element(
								'<textarea rows=' +
									(attr.rows ? attr.rows : 3) +
									' class="inline-placeholder" disabled/>'
							);
							break;
						case 'h2':
							placeholderElem = angular.element('<h2 class="inline-placeholder"/>');
							break;
						default:
							placeholderElem = angular.element('<span class="inline-placeholder" />');
							break;
					}
					var activatorElem = angular.element('<button class="inline-activator btn btn-tool" />');

					var required = 'required' in attr;
					var type = attr.type || 'text';
					var valueElem;
					var applyButton;
					var input;
					var multiple = attr.multiple !== undefined;
					var map = attr.map !== undefined;
					var label = attr.label;
					var key = attr.key;
					var $translate = $filter('translate');
					var shiftPressed = false;
					var dropDownOpen = false;
					var curtain = angular.element('#up-inline-curtain');
					scope.loading = false;

					scope.selectOptions = {
						allowClear: required ? 0 : 1,
						width: '200px',
						formatSelection: function (item, container, escape) {
							return escape(item.name);
						},
						formatResult: function (item, container, query, escape) {
							return escape(item.name);
						}
					};

					var clickyFn = function (e) {
						abort();
					};

					var disable = function () {
						if (type === 'textarea' || type !== 'select' || multiple) {
							var icon = applyButton.find('.fa');
							icon.removeClass('fa-check');
							icon.addClass('fa-refresh fa-spin');
							applyButton.addClass('disabled');
							applyButton.blur();
						} else if (type === 'select') {
							var container = element.find('.select2-container-active').first();
							var spinner = angular.element('<b class="fa fa-refresh fa-spin inline-loader"/>');
							container.append(spinner);
							element.find('.select2-arrow').remove();
						}
						var i = element.find('.inline-input');
						i.removeClass('inline-input');
						i.addClass('inline-disabled');
					};

					var save = function () {
						disable();

						if (ctrl.$invalid) {
							onError(ctrl.$error);
						} else {
							// create promise
							var savePromise = $q.defer();

							// disable input while saving and show loader
							input.prop('disabled', true);
							scope.loading = true;

							// run callback and inject new value and promise
							scope.callback({
								$value: ctrl.$modelValue,
								$promise: savePromise
							});

							// on promise resolve/reject
							savePromise.promise.then(onSaved).catch(onError);
						}
					};

					var onSaved = function () {
						// hide spinner and set new label
						scope.loading = false;
						scope.label = getLabel();

						if (map) {
							var res = [];
							angular.forEach(ctrl.$viewValue, function (val) {
								var obj = {};
								obj[key] = parseInt(val);
								obj[label] = _.find(scope.options, obj)[label];
								res.push(obj);
							});
							scope.value = res;
						} else {
							scope.value = ctrl.$viewValue !== '' ? ctrl.$viewValue : scope.model;
						}

						// deactivate editmode
						deactivate();
					};

					var onError = function (response) {
						// hide spinner and abort edit
						scope.loading = false;
						abort();

						var error = response.error || response;

						// show notification error
						NotificationService.addNotification({
							title: 'default.error',
							body: getErrorMsg(error),
							style: 'Error',
							icon: 'times'
						});

						// flash value red
						var i = 300;
						var elementToBlink = placeholderElem.is(':visible') ? placeholderElem : valueElem;
						var initialColor = elementToBlink.css('color');
						var blink = setInterval(function () {
							elementToBlink.css('color', 'red');
							setTimeout(function () {
								elementToBlink.css('color', initialColor);
							}, i / 2);
						}, i);

						setTimeout(function () {
							clearInterval(blink);
						}, i * 3 + i);
					};

					// returns error message
					var getErrorMsg = function (error) {
						var err = typeof error === 'object' ? Object.keys(error)[0] : error;
						return 'inlineError.' + (/url|required/.test(err) ? err : 'invalidValue');
					};

					var findInOptions = function (id) {
						var search = {};
						search[key] = !isNaN(id) ? parseInt(id) : id;
						var searchArray = [];
						angular.forEach(scope.options, function (object) {
							if (object.children) {
								searchArray.push(object.children);
							} else {
								searchArray.push(object);
							}
						});

						var found = _.find(_.uniq(_.flatten(searchArray), key), search);

						if (found) {
							return found[label];
						} else if (attr.selectedNotFound) {
							return $parse(attr.selectedNotFound)(scope.$parent);
						}
						return '';
					};

					// returns the label based on input type
					var getLabel = function () {
						switch (type) {
							case 'select':
								if (!multiple) {
									return $translate.instant(findInOptions(scope.model));
								}

								if (scope.model && scope.model.length) {
									if ($attrs.showAll !== undefined) {
										return _.map(scope.model, findInOptions).join(', ');
									} else {
										return (
											findInOptions(scope.model[0]) +
											(scope.model.length > 1
												? ' ' +
												  $translate.instant('default.and').toLowerCase() +
												  ' ' +
												  (scope.model.length - 1) +
												  ' ' +
												  $translate.instant('filters.more').toLowerCase()
												: '')
										);
									}
								} else {
									return '';
								}
								break;
							default:
								return scope.model;
						}
					};

					// get the right input element depending on input type
					var getInput = function () {
						var input;
						switch (type) {
							case 'text':
							case 'email':
							case 'url':
							default:
								input = angular.element('<input type=' + type + '>');

								// set input height to value line-height
								input.css({
									lineHeight: valueElem.css('lineHeight'),
									fontSize: valueElem.css('fontSize')
								});
								break;
							case 'textarea':
								input = angular.element('<textarea rows=' + (attr.rows ? attr.rows : 3) + '>');
								input.css({
									lineHeight: valueElem.css('lineHeight'),
									fontSize: valueElem.css('fontSize')
								});
								break;

							case 'select':
								input = angular.element('<select />');

								angular.forEach(scope.options, function (item) {
									if (item.children) {
										var optGroup = angular.element('<optGroup/>');
										optGroup.attr('label', $translate.instant(item.name));
										angular.forEach(item.children, function (child_item) {
											optGroup.append(
												angular
													.element('<option/>')
													.val(child_item[key])
													.html($translate.instant(child_item[label]))
											);
										});
										input.append(optGroup);
									} else {
										input.append(
											angular
												.element('<option/>')
												.val(item[key])
												.html($translate.instant(item[label]))
										);
									}
								});

								// setWidth
								scope.selectOptions.width = element.parent().width();
								scope.selectOptions.minimumResultsForSearch = 10;

								input.one('select2-loaded', function () {
									angular.element('.select2-input').on('keydown', function (e) {
										switch (e.keyCode) {
											case 27:
												abort();
												break;
											case 13:
												if (!dropDownOpen) {
													save();
												}
												break;
										}
									});
									input.css('opacity', 1);
									input.select2('container').css('opacity', 1);
									angular.element('#select2-drop').css('z-index', 20001);
								});

								if (type === 'select') {
									if (multiple) {
										input.attr('multiple', true);
										scope.selectOptions.width = parseInt(scope.selectOptions.width) - 30 + 'px';
									} else if (!required) {
										// Add "select-none" option
										input.prepend(angular.element('<option/>').val(0).html('-'));
									}
									input.on({
										'select2-open': function (e) {
											setTimeout(function () {
												dropDownOpen = true;
											}, 0);
										},
										'select2-close': function (e) {
											setTimeout(function () {
												dropDownOpen = false;
											}, 0);
										}
									});
								}

								// options
								if (scope.options && key && attr.value) {
									input.attr({
										type: 'hidden',
										'ui-select2': '{{selectOptions}}'
									});
								}

								// bind onchange to save
								input.on('select2-selecting', function () {
									setTimeout(function () {
										if (!multiple) {
											save();
										}
									}, 0);
								});

								break;

							case 'date':
								input = angular.element('<input type="date" data-start-week="1">');
								break;
						}

						// set input model
						input.attr({
							'ng-model': 'model',
							required: required,
							'ng-pattern': attr.ngPattern
						});

						mainWrapperElem.append(input);

						// compile it
						$compile(input)(scope);

						// input get compiled and replaced with magic
						// so we need find the new input
						if (type == 'date') {
							input = element.find('input');
						}

						ctrl = angular.element(input).controller('ngModel');

						if (type === 'date') {
							ctrl.$viewChangeListeners.push(() => {
								scope.value = new Date(scope.value);
								save();
							});
						}

						return input;
					};

					var activate = function () {
						var options = scope.optionsFnc({});
						if (options) {
							scope.options = options;
						}

						// create input
						input = getInput();

						if (type === 'select') {
							input.css('opacity', 0);
						}

						input.addClass('inline-input');

						// add it to mainWrapperElem
						// mainWrapperElem.append(input);

						if (type === 'select' && Array.isArray(scope.value) && typeof scope.value[0] === 'object') {
							scope.model = _.pluck(scope.value, key);
						}

						// create save/apply button for multiple selects
						if (multiple || type !== 'select') {
							applyButton = angular.element('<button/>').addClass('inline-apply btn btn-tool');
							applyButton.append('<b class="fa fa-check"/>');
							applyButton.on('click', function (e) {
								e.preventDefault();
								e.stopPropagation();
								save();
							});
							mainWrapperElem.append(applyButton);
						}

						// set blur and keyup event listener
						input.on({
							keydown: function (e) {
								if (e.keyCode === 9) {
									e.preventDefault();
								}
							},
							keyup: function (e) {
								if (scope.loading) return;
								// on enter or escape
								if (e.keyCode === 13 && type !== 'select' && !e.shiftKey) {
									e.preventDefault();
									save();
								} else if (e.keyCode === 27) {
									input.blur(); // need to blur on datepicern
									abort();
								} else if (e.keyCode === 16) {
									shiftPressed = false;
								} else if (e.keyCode === 9) {
									save();
									var nextElem;
									if (e.shiftKey) {
										nextElem = mainWrapperElem
											.prevAll('.up-inline-edit')
											.first()
											.find('.inline-activator');
									} else {
										nextElem = mainWrapperElem
											.nextAll('.up-inline-edit')
											.first()
											.find('.inline-activator');
									}
									if (nextElem.length) {
										nextElem.trigger('click');
									}
								}
							},
							click: function (event) {
								event.stopPropagation();
							}
						});

						wrapperElem.hide();

						scope.$apply();

						if (type === 'select') {
							// focus select
							setTimeout(function () {
								input.select2('open');
							}, 0);
						} else {
							// focus input
							input.focus();
						}
						curtain.show();
						curtain.on('click', abort);
					};

					var abort = function () {
						scope.model = scope.value;
						deactivate();
					};

					var deactivate = function () {
						input.select2('close');
						setTimeout(function () {
							curtain.hide();
							curtain.off('click', abort);
							input.remove();
							if (multiple || type !== 'select') {
								applyButton.remove();
								angular.element('#select2-drop-mask').off('mousedown', clickyFn);
							}
							wrapperElem.show();
						}, 0);
					};

					var init = function () {
						var ensureHttpPrefix = function (value) {
							if (
								value &&
								typeof value === 'string' &&
								value.indexOf('http://') !== 0 &&
								value.indexOf('https://') !== 0 &&
								value.indexOf('ftp://') !== 0
							) {
								value = 'http://' + value;
							}
							return value;
						};
						// if element is a link we need value element to be that alsooo
						if (/email|url/.test(type) || orgTag === 'a') {
							valueElem = angular.element('<a>');
							attr.$observe('href', function () {
								var href = attr.href;
								if (attr.type === 'url') {
									valueElem.attr({
										href: ensureHttpPrefix(href),
										target: '_blank'
									});
								} else {
									valueElem.attr({
										href: href
									});
								}
							});
						} else if (orgTag === 'textarea') {
							valueElem = angular.element(
								'<' + orgTag + ' rows=' + (attr.rows ? attr.rows : 3) + ' disabled>'
							);
						} else {
							valueElem = angular.element('<' + orgTag + '>');
						}

						mainWrapperElem.append(wrapperElem);

						var valueConditional = "value !== null && value !== undefined && value !== ''";

						if (multiple) {
							valueConditional += ' && value.length';
						}

						valueElem
							.addClass('inline-value')
							.attr({
								'ng-show': valueConditional
							})
							.html(type == 'date' ? '{{ label | upsalesDate:false}}' : '{{ label }}');

						// placeholder
						placeholderElem.attr({
							'ng-hide': valueConditional,
							'ng-bind-html': 'placeholder'
						});

						// activator
						activatorElem.html('<b class="fa fa-pencil"></b>');

						wrapperElem.append(valueElem, placeholderElem, activatorElem);

						// set value
						if (!attr.value) {
							scope.value = '';
							scope.label = '';
							scope.model = '';
						} else {
							scope.$watch(
								'value',
								function (val) {
									if (val !== undefined) {
										scope.value = val;

										scope.model = val;

										if (type === 'date') {
											scope.model = new Date(scope.model);
										} else if (type === 'number') {
											scope.model = Number(scope.model);
										}

										if (type == 'select' && key && label) {
											if (multiple && val === null) {
												scope.value = [];
											}
											if (Array.isArray(val) && typeof val[0] === 'object') {
												scope.model = _.pluck(val, key);
											}

											if (attr.optionsFnc && typeof scope.optionsFnc === 'function') {
												scope.options = scope.optionsFnc({});
												scope.label = getLabel();
											} else {
												scope.$watch('opt', function (options) {
													if (options !== undefined) {
														scope.options = options;
														scope.label = getLabel();
													}
												});
											}
										} else {
											scope.label = getLabel();
										}
									}
								},
								true
							);
						}

						// set placeholder
						scope.placeholder = attr.placeholder || '';

						// set onSave function
						if (!attr.callback) {
							scope.callback = function () {
								arguments[0]['$promise'].resolve();
							};
						}
						scope.$watch('ngDisabled', function (newValue) {
							if (newValue !== undefined) {
								if (newValue) {
									mainWrapperElem.addClass('ng-disabled');
								} else {
									mainWrapperElem.removeClass('ng-disabled');
								}
							}
						});

						// compile
						$compile(wrapperElem)(scope);

						/*
						 *	EVENT LISTENERS
						 */
						var clickable = 'clickable' in attr;
						// set click function
						activatorElem
							.add(clickable && placeholderElem)
							.add(clickable && valueElem)
							.on({
								click: function (e) {
									if (!scope.ngDisabled) {
										e.preventDefault();
										e.stopPropagation();
										activate();
									}
								}
							});
					};

					init();
				};
			}
		};
	}
]);

import { Loader } from '@upsales/components';

ReactTemplates.segment.modalTable = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			th: {
				name: t('default.name'),
				flowStatus: t('flow.flowStatus')
			}
		};
	},
	render: function () {
		var self = this;

		var rows = _.map(self.props.rows, function (row) {
			return (
				<ReactTemplates.segment.modalTableRow
					key={row.id}
					item={row}
					tools={self.props.tools}
					checkbox={self.props.checkbox}
					selectedSegments={self.props.selectedSegments}
				/>
			);
		});

		return (
			<table>
				<tbody>
					<tr>
						<th className={self.props.sort !== null ? 'activeSort' : ''} colSpan={2}>
							{self.lang.th.name}
							{self.props.sort ? (
								<i className="fa fa-caret-up"></i>
							) : (
								<i className="fa fa-caret-down"></i>
							)}
						</th>
						<th>{self.lang.th.flowStatus}</th>
					</tr>
					{rows}
					{self.props.fetching && !self.props.all_loaded ? (
						<tr className="loading-row">
							<td colSpan={3}>
								<Loader noU={true} />
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		);
	}
});

ReactTemplates.segment.modalTableRow = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			active: t('default.active'),
			draft: t('mail.draft')
		};
	},
	render: function () {
		var self = this;
		var item = self.props.item;

		return (
			<tr key={item.name}>
				<td>
					<input
						type="checkbox"
						checked={self.props.selectedSegments.indexOf(item.id) > -1}
						onChange={function () {
							self.props.checkbox(item.id);
						}}
					/>
				</td>
				<td>
					<span className="segment-title">{item.name}</span>
					<span className="segment-description">{item.description}</span>
				</td>
				<td>
					{item.active ? (
						<span className="status" style={{ color: '#5cb85c' }}>
							<i className="fa fa-code-fork" />
							{self.lang.active}
						</span>
					) : (
						<span className="status">
							<i className="fa fa-code-fork" />
							{self.lang.draft}
						</span>
					)}
				</td>
			</tr>
		);
	}
});

ReactTemplates.segment.addToSegmentModal = window.ReactCreateClass({
	getDefaultState: function (props) {
		return {
			lastKnownOffset: 0,
			all_loaded: props.all_loaded,
			fetching: false,
			lastRender: false,
			searchString: '',
			selectedSegments: props.selectedSegments
		};
	},
	getInitialState: function () {
		return this.getDefaultState(this.props);
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (!this.state.lastRender) {
			this.setState({
				fetching: false,
				all_loaded: nextProps.all_loaded,
				lastKnownOffset: nextProps.offset,
				selectedSegments: nextProps.selectedSegments
			});
		} else if (!nextProps.all_loaded) {
			this.setState({
				all_loaded: nextProps.all_loaded,
				lastRender: true,
				fetching: false,
				selectedSegments: nextProps.selectedSegments
			});
		} else {
			this.setState({
				fetching: false,
				selectedSegments: nextProps.selectedSegments
			});
		}
	},
	onScroll: function () {
		var rowContainer = ReactDOM.findDOMNode(this.refs['rowContainer']);
		var maxScrollTop = rowContainer.scrollHeight - rowContainer.clientHeight;
		if (rowContainer.scrollTop > maxScrollTop * 0.9 && this.props.offset === this.state.lastKnownOffset) {
			this.setState(
				{
					lastKnownOffset: this.state.lastKnownOffset + 40,
					fetching: true
				},
				this.props.refetch()
			);
		}
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			title: t('segment.multiAction.modal.title'),
			saving: t('default.saving'),
			continue: t('onboarding.continue'),
			abort: t('default.abort'),
			active: t('default.active'),
			searchPlaceholder: t('default.search')
		};
	},

	onInputChange: function (event) {
		this.props.refetch();
		this.setState({
			searchString: event.target.value
		});
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},

	render: function () {
		var self = this;

		var segments = self.props.segments;

		if (self.state.searchString && self.state.searchString.length) {
			segments = _.filter(segments, function (item) {
				return item.name.toLowerCase().indexOf(self.state.searchString.toLowerCase()) > -1;
			});
		}

		var t = Tools.$translate;
		var numSegments = this.props.selectedSegments.length;
		this.lang.addIntoSegment =
			t('segment.addIntoSegment') +
			' ' +
			numSegments +
			' ' +
			(numSegments > 1 ? t('default.segments').toLowerCase() : t('default.segment').toLowerCase());

		return (
			<div id="segment-multi-action-wrapper">
				<div className="up-modal-header">
					<h2>{self.lang.title}</h2>

					<div className="search-holder">
						<i className="fa fa-search"></i>
						<input
							type="text"
							id="upload-files-search"
							maxLength="120"
							onChange={self.onInputChange}
							value={self.state.searchString}
							placeholder={self.lang.searchPlaceholder}
							ref={self.setRef.bind(self, '_search')}
						/>
					</div>

					<a
						className="btn btn-link modal-close"
						onClick={function () {
							self.props.closeModal();
						}}
					>
						<i className="fa fa-times"></i>
					</a>
				</div>
				<div className="up-modal-content" ref={'rowContainer'} onScroll={this.onScroll}>
					<ReactTemplates.segment.modalTable
						rows={segments}
						sort={self.props.sort}
						tools={self.props.tools}
						ref="scrollable"
						onScroll={this.onScroll}
						fetching={this.state.fetching}
						all_loaded={this.state.all_loaded}
						checkbox={this.props.checkbox}
						selectedSegments={self.state.selectedSegments}
					/>
				</div>
				<div className="up-modal-controls">
					<button
						type="submit"
						disabled={self.props.saving ? 'disabled' : false}
						className="btn up-btn btn-green no-shadow"
						onClick={function () {
							self.props.onSave();
						}}
					>
						{self.props.saving ? self.lang.saving : self.lang.addIntoSegment}
					</button>
					<button
						type="button"
						disabled={self.props.saving ? 'disabled' : false}
						className="btn up-btn btn-grey btn-link"
						onClick={function () {
							self.props.closeModal();
						}}
					>
						{this.lang.abort}
					</button>
				</div>
			</div>
		);
	}
});

import UpSelect from 'Components/Inputs/UpSelect';
import { getFileIcon } from 'App/babel/helpers/file';
import { Button, ButtonGroup, Headline, Icon, Text } from '@upsales/components';

ReactTemplates.fileUploader = {};
ReactTemplates.fileUploader.root = window.ReactCreateClass({
	getInitialState: function () {
		var state = {
			searchString: null,
			dragging: false,
			filteredFileType: null,
			listType: 'thumbnail',
			selectedUser: Tools.AppService.getSelf(),
			currentFilter: null,
			currentSort: 'uploadDate',
			currentDirection: false
		};

		return state;
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			pickFile: t('file.selectFile'),
			pickImage: t('mail.selectImage'),
			multipleFiles: t('file.files'),
			searchPlaceholder: t('default.search'),
			all: t('default.all'),
			allUsers: t('filters.allUsers'),
			pick: t('default.choose'),
			documents: t('default.document'),
			images: t('default.images'),
			fileTypeDropdownPlaceholder: t('file.allFileTypes'),
			sortDropdownPlaceholder: t('default.latest') + ' ' + t('file.uploadedDate').toLowerCase(),
			recentUploaded: t('default.recently') + ' ' + t('file.uploadedPlural').toLowerCase(),
			isUploading: t('esign.uploading'),
			sort: {
				filename: t('file.fileName'),
				mimetype: t('file.fileType'),
				size: t('file.fileSize'),
				username: t('default.user'),
				uploaded: t('file.uploadedDate')
			},
			directionDropdownPlaceholder: t('file.sort.change'),
			direction: {
				filename: { asc: t('file.sort.az'), desc: t('file.sort.za') },
				mimetype: { asc: t('file.sort.az'), desc: t('file.sort.za') },
				size: { desc: t('file.sort.large'), asc: t('file.sort.small') },
				username: { asc: t('file.sort.az'), desc: t('file.sort.za') }
			}
		};

		this.acceptedFileTypes = {
			documents: [
				'pdf',
				'doc',
				'docx',
				'txt',
				'zip',
				'xml',
				'xls',
				'xlsx',
				'ppt',
				'pptx',
				'ods',
				'odt',
				'odp',
				'rtf'
			],
			images: ['jpe', 'jpg', 'jpeg', 'png', 'gif']
		};

		this.sortTypes = [
			{ name: this.lang.sort.uploaded, id: 'uploadDate' },
			{ name: this.lang.sort.filename, id: 'filename' },
			{ name: this.lang.sort.mimetype, id: 'mimetype' },
			{ name: this.lang.sort.size, id: 'size' },
			{ name: this.lang.sort.username, id: 'user.attr.name' }
		];

		this.setState({ currentSort: this.sortTypes[0].id });
	},

	componentDidMount: function () {
		this.getFiles();
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	drag: function () {
		this.setState({
			dragging: !this.state.dragging
		});
	},

	getDataTransferFiles: function (event) {
		var dataTransferItemsList = [];
		if (event.dataTransfer) {
			var dt = event.dataTransfer;
			if (dt.files && dt.files.length) {
				dataTransferItemsList = dt.files;
			} else if (dt.items && dt.items.length) {
				dataTransferItemsList = dt.items;
			}
		} else if (event.target && event.target.files) {
			dataTransferItemsList = event.target.files;
		}

		return Array.prototype.slice.call(dataTransferItemsList);
	},
	drop: function (ev) {
		var self = this;
		ev.preventDefault();

		self.setState({
			dragging: false
		});

		var list = self.getDataTransferFiles(ev);
		self.props.rootData.upload2(list);
	},

	uploadFile: function (ev) {
		var self = this;
		ev.preventDefault();

		var list = self.getDataTransferFiles(ev);
		self.props.rootData.upload2(list);
	},

	selectImage: function (file, stringFile) {
		if (stringFile) {
			return this.props.rootData.selectStringFile(file);
		}
		this.props.rootData.selectFile(file);
	},

	deleteFile: function (file) {
		this.props.rootData.delete(file);
	},

	changeUserFilter: function (user) {
		this.setState({ selectedUser: user, offset: 0 }, this.getFiles);
	},

	changeSort: function (sort) {
		if (sort.target) {
			sort = {
				field: sort.target.value || this.state.currentSort,
				asc: sort.target.value ? true : !this.state.currentDirection
			};
		}
		this.setState(
			{
				offset: 0,
				currentSort: sort.field,
				currentDirection: sort.asc
			},
			this.getFiles
		);
	},
	changeSearch: function (event) {
		var value = event.target.value;

		this.setState(
			{
				offset: 0,
				searchString: value
			},
			_.debounce(this.getFiles, 200)
		);
	},
	changeOffset: function (offset) {
		this.setState({ offset: offset }, this.getFiles);
	},

	getFiles: function () {
		var rb = new Tools.RequestBuilder();
		rb.addSort(this.state.currentSort, this.state.currentDirection);
		if (this.state.selectedUser) {
			rb.addFilter(Tools.File.attr.user, rb.comparisonTypes.eq, this.state.selectedUser.id);
		}
		if (this.state.searchString) {
			rb.addFilter(Tools.File.attr.filename, rb.comparisonTypes.Search, this.state.searchString);
		}
		rb.offset = this.state.offset || 0;
		this.props.rootData.getFiles(rb);
	},

	extraRowItemComponent: function (file) {
		var self = this;
		var fileImageStyle = {
			background: 'url("' + file + '") no-repeat center center',
			backgroundSize: 'auto 100%'
		};

		return (
			<div className="file" key={'uploaded-file-' + file}>
				<div className="data-on-hover">
					<button className="up-btn btn-bright-blue btn-sm" onClick={self.selectImage.bind(self, file, true)}>
						<i className="fa fa-check" />
						{self.lang.pick}
					</button>
				</div>
				<ReactTemplates.TOOLS.lazyLoad height={215}>
					<div className="preview" style={fileImageStyle} />
				</ReactTemplates.TOOLS.lazyLoad>
				<div className="file-bottom-info">
					<span>{file}</span>
				</div>
			</div>
		);
	},
	extraRowComponent: function (row) {
		var self = this;
		if (!Array.isArray(row.images)) {
			throw new Error('Images must be an array of strings');
		}

		var items = row.images;
		var html = [];
		_.each(items, function (item) {
			html.push(self.extraRowItemComponent(item));
		});

		return html;
	},
	createExtraRows: function () {
		var self = this;
		var returnValue = [];
		var rows = self.props.rootData.extraFieldUploadedRow;
		_.each(rows, function (extraRow) {
			var imgArray = [];
			var rowOption = null;
			if (extraRow.title) {
				rowOption = <div className="files-options">{extraRow.title}</div>;
			}

			imgArray.push(self.extraRowComponent(extraRow));

			returnValue.push(
				<div className="extra-rows files">
					{rowOption}
					{imgArray}
				</div>
			);
		});

		return returnValue;
	},
	getFileIcon: function (mimeType) {
		return getFileIcon(mimeType);
	},
	renderTable: function (files) {
		var self = this;
		return (
			<window.UiTable>
				<window.UiTableHeader
					sorting={self.state.currentSort}
					asc={self.state.currentDirection}
					columns={[
						{ title: this.lang.sort.filename, sort: 'filename' },
						{ title: this.lang.sort.uploaded, sort: 'uploadDate', sortType: 'numeric' },
						{ title: this.lang.sort.mimetype, sort: 'mimetype' }
					]}
					onSortChange={self.changeSort}
				/>
				{files.map(function (file, index) {
					var fileIcon = 'fa ' + self.getFileIcon(file.mimetype);
					return (
						<window.UiTableRow
							onClick={self.selectImage.bind(self, file, false)}
							key={'file-table-file-' + file.id || index}
						>
							<window.UiTableColumn className="filename-column">
								<span className="ellipsis">{file.filename}</span>
							</window.UiTableColumn>
							<window.UiTableColumn className="align-middle">
								<window.UiTooltip title={file.user.name} distance={20}>
									<ReactTemplates.TOOLS.avatar
										user={file.user}
										size={25}
										avatarService={self.props.tools.avatarService}
									/>
								</window.UiTooltip>
								{moment(file.uploadDate).format('YYYY-MM-DD')}
							</window.UiTableColumn>
							<window.UiTableColumn>
								<b className={fileIcon} /> {file.mimetype.split('/')[1].toUpperCase()}{' '}
								{'(' + (file.size / 1000).toFixed(0) + 'kb' + ')'}
							</window.UiTableColumn>
						</window.UiTableRow>
					);
				})}
			</window.UiTable>
		);
	},
	renderThumbnailList: function (files) {
		return <div className="filebrowser-padding-wrapper">{files.map(this.renderThumbnail)}</div>;
	},
	renderThumbnail: function (file, index) {
		var self = this;
		if (file.pendingUpload) {
			return (
				<div className="file loading" key={'uploading-' + file.id || index}>
					<div className="upsales-loader-holder">
						<window.UiLoader />
						<h3>{self.lang.isUploading}</h3>
					</div>
				</div>
			);
		}

		var fileIcon = 'fa ' + self.getFileIcon(file.mimetype);
		var previewStyle;
		var isImage = file.mimetype && file.mimetype.split('/')[0] === 'image';
		if (isImage) {
			previewStyle = {
				background: 'url("' + file.$$previewUrl + '") no-repeat center center',
				backgroundSize: '100% auto'
			};
		} else {
			previewStyle = {
				background: '#fafafa'
			};
		}

		return (
			<div className="file" key={'recent-added-file-' + file.id}>
				<div className="data-on-hover">
					<i onClick={self.deleteFile.bind(self, file)} className="fa fa-trash" />
					<button
						className="up-btn btn-bright-blue btn-sm"
						onClick={self.selectImage.bind(self, file, false)}
					>
						<i className="fa fa-check" />
						{self.lang.pick}
					</button>
				</div>
				<div className="preview" style={previewStyle}>
					{!isImage && <b className={fileIcon} />}
				</div>
				<div className="file-bottom-info">
					<span>{file.filename}</span>
					<ReactTemplates.TOOLS.avatar
						user={file.user}
						size={25}
						avatarService={self.props.tools.avatarService}
					/>
					<div className="extra-info">
						<span>{moment(file.uploadDate).format('L')}</span>
						<span>{(file.size / 1000).toFixed(0) + 'kb'}</span>
					</div>
				</div>
			</div>
		);
	},

	render: function () {
		var self = this;
		var rootData = self.props.rootData;
		var isImageOnly = self.props.rootData.isImageOnly ? self.props.rootData.isImageOnly() : false;
		var type = isImageOnly ? self.lang.images : self.lang.multipleFiles;

		var extraRows =
			self.props.rootData.extraFieldUploadedRow &&
			self.props.rootData.extraFieldUploadedRow.length &&
			self.createExtraRows();

		var sortClassName = 'fa fa-sort-alpha-' + (self.state.currentDirection ? 'asc' : 'desc');

		return (
			<div className="filebrowser-modal" onDragEnter={self.drag} onDragLeave={self.drag}>
				<div className="up-modal-header">
					<span className="modal-title">{isImageOnly ? self.lang.pickImage : self.lang.pickFile}</span>

					<div className="search-holder">
						<i className="fa fa-search" />
						<input
							type="text"
							id="upload-files-search"
							maxLength="120"
							onChange={self.changeSearch}
							value={self.state.searchString}
							placeholder={self.lang.searchPlaceholder}
							ref={self.setRef.bind(self, '_search')}
						/>
					</div>

					<a className="btn btn-link modal-close" onClick={rootData.closeModal}>
						<i className="fa fa-times" />
					</a>
				</div>
				<div className="up-modal-content pior">
					<div className="up-panel">
						<div className="up-panel-content">
							<div className="filebrowser-padding-wrapper">
								<ReactTemplates.fileUploader.dropzone
									isImageOnly={self.props.rootData.isImageOnly}
									tools={self.props.tools}
									dragging={self.state.dragging}
									drop={self.drop}
									uploadFile={self.uploadFile}
								/>
								<div className="added-files files">
									{rootData.recentlyAdded.length ? (
										<Headline size="sm">{self.lang.recentUploaded}</Headline>
									) : null}
									{rootData.recentlyAdded.map(this.renderThumbnail)}
								</div>
								{extraRows}
							</div>
							<div className="files files-wrapper">
								<div className="files-options filebrowser-padding-wrapper">
									<div className="headline">
										<Headline size="sm">{type}</Headline>
										<Text color="grey-10">
											{rootData.totalFiles + ' ' + type.toLowerCase()}
										</Text>
									</div>

									<div className="sort-options">
										<ReactTemplates.INPUTS.upUsers
											placeholder={self.lang.allUsers}
											onChange={self.changeUserFilter}
											value={self.state.selectedUser}
										/>
										<UpSelect
											onChange={self.changeSort}
											defaultValue={self.state.currentSort}
											data={self.sortTypes}
											getId={function (o) {
												return o.id || 0;
											}}
										/>
										{self.state.currentSort ? (
											<div onClick={self.changeSort} className={sortClassName} />
										) : null}
										<ButtonGroup>
											<Button
												onClick={function () {
													self.setState({ listType: 'thumbnail' });
												}}
												type={this.state.listType !== 'thumbnail' ? 'lined' : undefined}
											>
												<Icon name="th-large" />
											</Button>
											<Button
												onClick={function () {
													self.setState({ listType: 'table' });
												}}
												type={this.state.listType !== 'table' ? 'lined' : undefined}
											>
												<Icon name="bars" />
											</Button>
										</ButtonGroup>
									</div>
								</div>
								{this.state.listType === 'thumbnail'
									? this.renderThumbnailList(rootData.files)
									: this.renderTable(rootData.files)}
							</div>
						</div>
					</div>
				</div>
				<div className="up-modal-controls">
					{rootData.totalFiles > rootData.limit ? (
						<window.UiPaginator
							color="light-grey|bright-blue"
							limit={rootData.limit}
							offset={rootData.offset}
							total={rootData.totalFiles}
							onChange={self.changeOffset}
						/>
					) : null}
				</div>
			</div>
		);
	}
});

ReactTemplates.fileUploader.dropzone = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			uploadFile: t('default.uploadFile'),
			uploadImage: t('default.uploadImage'),
			selectFileText: t('file.uploadHelpText_split1'),
			selectImageText: t('image.uploadHelpText_split1'),
			dropfileHere: t('file.uploadHelpText_split2'),
			dropYourFileHere: t('file.dropYourFileHere'),
			dropYourImageHere: t('file.dropYourImageHere')
		};
	},
	render: function () {
		var dragging = this.props.dragging;
		var isImageOnly = this.props.isImageOnly ? this.props.isImageOnly() : false;

		return (
			<div>
				<div className={dragging ? 'dragging drag' : 'drag'} onDrop={this.props.drop}>
					<div className="valign-middle">
						<svg
							width="120"
							height="151"
							viewBox="0 0 120 151"
							className="file-drop-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
						>
							<g className="card" fill="none" fillRule="evenodd">
								<path
									className="paper"
									fill="#E4EDF7"
									d="M13,3.00972645 C13,1.34750043 14.3424077,0 16.0020839,0 L85,0 L107,22 L107,113.999935 C107,115.656825 105.653127,117 104.005268,117 L15.9947322,117 C14.3407873,117 13,115.665878 13,113.990274 L13,3.00972645 Z"
								/>
								<path
									className="arrow"
									fill="#4A90E2"
									d="M73.0412946,61.2142857 C73.0412946,61.8058065 72.8348235,62.3080336 72.421875,62.7209821 L61.5234375,73.6361607 C61.0881675,74.0491092 60.58036,74.2555804 60,74.2555804 C59.4084792,74.2555804 58.9062521,74.0491092 58.4933036,73.6361607 L47.5948661,62.7209821 C47.1707568,62.3191944 46.9587054,61.8169673 46.9587054,61.2142857 C46.9587054,60.6227649 47.1707568,60.1149575 47.5948661,59.6908482 L48.8337054,58.4352679 C49.2689754,58.0223194 49.7767828,57.8158482 50.3571429,57.8158482 C50.9486637,57.8158482 51.4508908,58.0223194 51.8638393,58.4352679 L56.7857143,63.3571429 L56.7857143,51.5714286 C56.7857143,50.9910685 56.9977657,50.4888414 57.421875,50.0647321 C57.8459843,49.6406229 58.3482114,49.4285714 58.9285714,49.4285714 L61.0714286,49.4285714 C61.6517886,49.4285714 62.1540157,49.6406229 62.578125,50.0647321 C63.0022343,50.4888414 63.2142857,50.9910685 63.2142857,51.5714286 L63.2142857,63.3571429 L68.1361607,58.4352679 C68.5491092,58.0223194 69.0513363,57.8158482 69.6428571,57.8158482 C70.2232172,57.8158482 70.7310246,58.0223194 71.1662946,58.4352679 L72.421875,59.6908482 C72.8348235,60.1261182 73.0412946,60.6339257 73.0412946,61.2142857 Z"
								/>
								<path
									className="fold"
									fill="#4A90E2"
									opacity=".2"
									d="M84,0.0459127487 C84,-0.511600348 84.3143399,-0.649214134 84.7031002,-0.260453851 L106.789944,21.8263904 C107.178256,22.2147019 107.044718,22.5294906 106.483578,22.5294906 L85.0094668,22.5294906 C84.4519537,22.5294906 84,22.0811639 84,21.5200238 L84,0.0459127487 Z"
								/>
							</g>
							<ellipse
								className="shadow"
								id="shadow"
								cx="60"
								cy="147"
								fill="#000000"
								opacity=".1"
								rx="60"
								ry="4"
							/>
						</svg>
						<h3>{isImageOnly ? this.lang.dropYourImageHere : this.lang.dropYourFileHere}</h3>
					</div>
				</div>
				<div id="dropzone">
					<div className="valign-middle">
						<h3>{isImageOnly ? this.lang.uploadImage : this.lang.uploadFile}</h3>
						<p>
							<span className="upload-linktext">
								{isImageOnly && (
									<input
										type="file"
										accept="image/*"
										multiple
										name="fileUpload"
										id="fileUpload"
										onChange={this.props.uploadFile}
									/>
								)}
								{!isImageOnly && (
									<input
										type="file"
										multiple
										name="fileUpload"
										id="fileUpload"
										onChange={this.props.uploadFile}
									/>
								)}
								<label htmlFor="fileUpload" />
								{isImageOnly ? this.lang.selectImageText : this.lang.selectFileText}
							</span>
							<span>{', ' + this.lang.dropfileHere}</span>
						</p>
					</div>
				</div>
			</div>
		);
	}
});

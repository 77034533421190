'use strict';

angular.module('domain.soliditet').controller('UpdateClientDuns', [
	'$scope',
	'$state',
	'$modalParams',
	'$upModal',
	'ActionProperties',
	'SoliditetClient',
	'Account',
	function ($scope, $state, $modalParams, $upModal, ActionProperties, SoliditetClient, Account) {
		var ConfirmSoliditetUpdate = this;

		ConfirmSoliditetUpdate.properties = [];
		ConfirmSoliditetUpdate.buying = false;
		ConfirmSoliditetUpdate.accounts = $modalParams.meta.accounts;
		ConfirmSoliditetUpdate.sameDunsNoClient = null;
		ConfirmSoliditetUpdate.credits = $modalParams.meta.metadata.soliditetCredits;
		ConfirmSoliditetUpdate.selectedClient = null;
		ConfirmSoliditetUpdate.select2Client = null;

		if ($modalParams.meta.dunsMatch.length) {
			ConfirmSoliditetUpdate.sameDunsNoClient = $modalParams.meta.dunsMatch[0];

			if (ConfirmSoliditetUpdate.accounts.length) {
				ConfirmSoliditetUpdate.accounts = _.filter(ConfirmSoliditetUpdate.accounts, function (account) {
					return account.dunsNo !== ConfirmSoliditetUpdate.sameDunsNoClient.dunsNo;
				});
			}
		}

		ConfirmSoliditetUpdate.options = {
			updateExisting: true,
			skipProjects: true,
			skipAccountManagers: true,
			skipAddresses: false,
			skipCategories: true
		};

		ConfirmSoliditetUpdate.selectClient = function () {
			ConfirmSoliditetUpdate.selectedClient = ConfirmSoliditetUpdate.select2Client;
		};

		ConfirmSoliditetUpdate.listSelectClient = function (account) {
			ConfirmSoliditetUpdate.select2Client = undefined;
			ConfirmSoliditetUpdate.selectedClient = account;
		};

		var updateFail = function () {
			ConfirmSoliditetUpdate.buying = false;
		};

		ConfirmSoliditetUpdate.confirm = function () {
			ConfirmSoliditetUpdate.buying = true;
			ConfirmSoliditetUpdate.selectedClient.$buying = true;

			// Update account with dunsNo
			Account.customer($modalParams.customerId)
				.save(
					{ id: ConfirmSoliditetUpdate.selectedClient.id, dunsNo: $modalParams.dunsNo },
					{ skipNotification: true }
				)
				.then(function () {
					SoliditetClient.customer($modalParams.customerId)
						.refresh(
							ConfirmSoliditetUpdate.selectedClient.id,
							ConfirmSoliditetUpdate.properties,
							ConfirmSoliditetUpdate.options
						)
						.then(function (res) {
							$scope.resolve(res.data);
						})
						.catch(updateFail);
				})
				.catch(updateFail);
		};
	}
]);

'use strict';

import React from 'react';
import _ from 'lodash';

var Input = (window.Input = {});
Input.textarea = window.ReactCreateClass({
	getInitialState: function () {
		return {
			value: this.props.value || ''
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		if (newProps.value !== this.state.value) {
			this.state.value = newProps.value;
			this.setState(this.state);
		}
	},
	onChange: function (e) {
		this.state.value = e.target.value;
		this.setState(this.state);

		this.props.onChange(e);
	},
	floatingKeyup: function (e) {
		var elem = e.target;
		elem.style.height = '5px';
		elem.style.height = elem.scrollHeight + 'px';
	},
	render: function () {
		var props = _.clone(this.props);

		props.onChange = this.onChange;
		props.value = this.state.value;

		if (props.floating) {
			props.onKeyUp = this.floatingKeyup;
			props.rows = 1;
		}

		return React.createElement('textarea', props);
	}
});

Input.input = window.ReactCreateClass({
	getInitialState: function () {
		return {
			value: this.props.value || ''
		};
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		if (newProps.value !== this.state.value) {
			this.state.value = newProps.value;
			this.setState(this.state);
		}
	},
	onChange: function (e) {
		this.state.value = e.target.value;
		this.setState(this.state);

		this.props.onChange(e);
	},
	render: function () {
		var props = _.clone(this.props);

		props.onChange = this.onChange;
		props.value = this.state.value;

		return React.createElement('input', props);
	}
});

'use strict';

import AllIWantDataCache from 'App/helpers/allIWantDataCache';
import logError from 'App/babel/helpers/logError';

angular.module('services.cacheRefresher', []).service('CacheRefresher', [
	'AppService',
	'RequestBuilder',
	'ActivityType',
	'AppointmentType',
	'CustomField',
	'UserDefinedCategoryType',
	'UserDefinedCategory',
	'AccountCategory',
	'AccountCategoryType',
	'ContactCategory',
	'ContactCategoryType',
	'OrderStage',
	'ProductCategory',
	'Product',
	'Role',
	'User',
	'$rootScope',
	'$q',
	'FeatureHelper',
	'Metadata',
	'Self',
	function CacheRefresher(
		AppService,
		RequestBuilder,
		ActivityType,
		AppointmentType,
		CustomField,
		UserDefinedCategoryType,
		UserDefinedCategory,
		AccountCategory,
		AccountCategoryType,
		ContactCategory,
		ContactCategoryType,
		OrderStage,
		ProductCategory,
		Product,
		Role,
		User,
		$rootScope,
		$q,
		FeatureHelper,
		Metadata,
		Self
	) {
		var cacheTypes = {
			ACTIVITYTYPE: 'ActivityType',
			APPOINTMENTTYPE: 'AppointmentType',
			CLIENTCATEGORY: 'ClientCategory',
			CLIENTCATEGORYTYPE: 'ClientCategoryType',
			CONTACTCATEGORY: 'ContactCategory',
			CONTACTCATEGORYTYPE: 'ContactCategoryType',
			ORDERSTAGE: 'OrderStage',
			PRODUCT: 'Product',
			PRODUCTCATEGORY: 'ProductCategory',
			ROLE: 'Role',
			USER: 'User',
			ACTIVITYCUSTOMFIELD: 'ActivityCustomField',
			TODOCUSTOMFIELD: 'TodoCustomField',
			AGREEMENTCUSTOMFIELD: 'AgreementCustomField',
			APPOINTMENTCUSTOMFIELD: 'AppointmentCustomField',
			CLIENTCUSTOMFIELD: 'ClientCustomField',
			CONTACTCUSTOMFIELD: 'ContactCustomField',
			LEADCUSTOMFIELD: 'LeadCustomField',
			ORDERCUSTOMFIELD: 'OrderCustomField',
			ORDEREDPRODUCTCUSTOMFIELD: 'OrderedProductCustomField',
			PRODUCTCUSTOMFIELD: 'ProductCustomField',
			PROJECTCUSTOMFIELD: 'ProjectCustomField',
			PROJECTPLANCUSTOMFIELD: 'ProjectPlanCustomField',
			TICKETCUSTOMFIELD: 'TicketCustomField',
			USERCUSTOMFIELD: 'UserCustomField',
			USERDEFINEDOBJECTCUSTOMFIELD: 'UserDefinedObjectCustomField',
			USERDEFINEDOBJECT2CUSTOMFIELD: 'UserDefinedObject2CustomField',
			USERDEFINEDOBJECT3CUSTOMFIELD: 'UserDefinedObject3CustomField',
			USERDEFINEDOBJECT4CUSTOMFIELD: 'UserDefinedObject4CustomField',
			USERDEFINEDOBJECTCATEGORY: 'UserDefinedObjectCategory',
			USERDEFINEDOBJECT2CATEGORY: 'UserDefinedObject2Category',
			USERDEFINEDOBJECT3CATEGORY: 'UserDefinedObject3Category',
			USERDEFINEDOBJECT4CATEGORY: 'UserDefinedObject4Category',
			USERDEFINEDOBJECTCATEGORYTYPE: 'UserDefinedObjectCategoryType',
			USERDEFINEDOBJECT2CATEGORYTYPE: 'UserDefinedObject2CategoryType',
			USERDEFINEDOBJECT3CATEGORYTYPE: 'UserDefinedObject3CategoryType',
			USERDEFINEDOBJECT4CATEGORYTYPE: 'UserDefinedObject4CategoryType',
			METADATA: 'Metadata',
			SELF: 'Self'
		};

		var refreshCustomFields = function (customerId, type) {
			return CustomField.customer(customerId)
				.setType(type)
				.find({})
				.then(function (res) {
					AppService.setCustomFields(type, res.data);
					$rootScope.$broadcast('cacheRefresher.customField', type);
				});
		};

		var refreshUserDefinedCustomFields = function (customerId, type, number) {
			return CustomField.customer(customerId)
				.setId(number)
				.findUdo({})
				.then(function (res) {
					AppService.setCustomFields(type, res.data);
					$rootScope.$broadcast('cacheRefresher.userDefinedObjectCustomField', type);
				});
		};

		var refreshUserDefinedCategoryType = function (customerId, type, number) {
			return UserDefinedCategoryType.setId(number)
				.find({})
				.then(function (res) {
					AppService.setCategoryTypes(type, res.data);
				});
		};

		var refreshUserDefinedCategory = function (customerId, type, number) {
			return UserDefinedCategory.setId(number)
				.find({})
				.then(function (res) {
					AppService.setCategories(type, res.data);
				});
		};

		function refresh(types) {
			AllIWantDataCache.clearData();
			var customerId = AppService.getCustomerId();
			var promises = _.map(types, function (type) {
				switch (type) {
					case cacheTypes.ACTIVITYTYPE:
						return ActivityType.find({}).then(function (res) {
							AppService.setActivityTypes('activity', res.data);
						});
					case cacheTypes.APPOINTMENTTYPE:
						return AppointmentType.customer(customerId)
							.find({})
							.then(function (res) {
								AppService.setActivityTypes('appointment', res.data);
							});
					case cacheTypes.CLIENTCATEGORY:
						return AccountCategory.customer(customerId)
							.find({})
							.then(function (res) {
								AppService.setCategories('account', res.data);
							});
					case cacheTypes.CLIENTCATEGORYTYPE:
						return AccountCategoryType.customer(customerId)
							.find({})
							.then(function (res) {
								AppService.setCategoryTypes('account', res.data);
							});
					case cacheTypes.CONTACTCATEGORY:
						return ContactCategory.customer(customerId)
							.find({})
							.then(function (res) {
								AppService.setCategories('contact', res.data);
							});
					case cacheTypes.CONTACTCATEGORYTYPE:
						return AccountCategoryType.customer(customerId)
							.find({})
							.then(function (res) {
								AppService.setCategoryTypes('contact', res.data);
							});
					case cacheTypes.ORDERSTAGE:
						var stageFilter = new RequestBuilder();
						stageFilter.addSort(OrderStage.attr.probability, true);
						stageFilter.addSort(OrderStage.attr.name, true);
						return OrderStage.customer(customerId)
							.find(stageFilter.build())
							.then(function (res) {
								AppService.setStages(res.data);
							});
					case cacheTypes.PRODUCT:
						if (AppService.getTotals('products') > 4000) {
							return $q.when([]);
						}
						return Product.customer(customerId)
							.getAll()
							.then(function (res) {
								AppService.setProducts(res);
								$rootScope.$broadcast('cacheRefresher.products');
							});
					case cacheTypes.PRODUCTCATEGORY:
						var productCategoryFilter = new RequestBuilder();
						productCategoryFilter.addSort(ProductCategory.attr.sortId, true);
						productCategoryFilter.addSort(ProductCategory.attr.description, true);
						return ProductCategory.customer(customerId)
							.find(productCategoryFilter.build())
							.then(function (res) {
								AppService.setProductCategories(res.data);
								$rootScope.$broadcast('cacheRefresher.productCategories');
							});
					case cacheTypes.ROLE:
						return $q
							.all({
								role: Role.getMap(),
								user: User.getMap()
							})
							.then(function (res) {
								AppService.setRoleMap(res.role.data);
								AppService.setUserMap(res.user.data);
							});
					case cacheTypes.USER:
						return User.getMap().then(function (res) {
							AppService.setUserMap(res.data);

							// Self might have been updated (only name), trigger event if that is the case
							var self = AppService.getSelf();
							var updatedSelf = _.find(res.data.active, { id: self.id });
							if (updatedSelf && updatedSelf.name !== self.name) {
								self.name = updatedSelf.name;
								$rootScope.$broadcast('self.updated', self);
							}
						});
					case cacheTypes.ACTIVITYCUSTOMFIELD:
						return refreshCustomFields(customerId, 'activity');
					case cacheTypes.TODOCUSTOMFIELD:
						return refreshCustomFields(customerId, 'todo');
					case cacheTypes.AGREEMENTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'agreement');
					case cacheTypes.APPOINTMENTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'appointment');
					case cacheTypes.CLIENTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'account');
					case cacheTypes.CONTACTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'contact');
					case cacheTypes.LEADCUSTOMFIELD:
						return refreshCustomFields(customerId, 'lead');
					case cacheTypes.ORDERCUSTOMFIELD:
						return refreshCustomFields(customerId, 'order');
					case cacheTypes.ORDEREDPRODUCTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'orderrow');
					case cacheTypes.PRODUCTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'product');
					case cacheTypes.PROJECTCUSTOMFIELD:
						return refreshCustomFields(customerId, 'project');
					case cacheTypes.PROJECTPLANCUSTOMFIELD:
						return refreshCustomFields(customerId, 'projectPlan');
					case cacheTypes.TICKETCUSTOMFIELD:
						return refreshCustomFields(customerId, 'ticket');
					case cacheTypes.USERCUSTOMFIELD:
						return refreshCustomFields(customerId, 'user');
					case cacheTypes.USERDEFINEDOBJECTCUSTOMFIELD:
						return refreshUserDefinedCustomFields(customerId, 'userDefined1', 1);
					case cacheTypes.USERDEFINEDOBJECT2CUSTOMFIELD:
						return refreshUserDefinedCustomFields(customerId, 'userDefined2', 2);
					case cacheTypes.USERDEFINEDOBJECT3CUSTOMFIELD:
						return refreshUserDefinedCustomFields(customerId, 'userDefined3', 3);
					case cacheTypes.USERDEFINEDOBJECT4CUSTOMFIELD:
						return refreshUserDefinedCustomFields(customerId, 'userDefined4', 4);
					case cacheTypes.USERDEFINEDOBJECTCATEGORY:
						return refreshUserDefinedCategory(customerId, 'userDefined1', 1);
					case cacheTypes.USERDEFINEDOBJECT2CATEGORY:
						return refreshUserDefinedCategory(customerId, 'userDefined2', 2);
					case cacheTypes.USERDEFINEDOBJECT3CATEGORY:
						return refreshUserDefinedCategory(customerId, 'userDefined3', 3);
					case cacheTypes.USERDEFINEDOBJECT4CATEGORY:
						return refreshUserDefinedCategory(customerId, 'userDefined4', 4);
					case cacheTypes.USERDEFINEDOBJECTCATEGORYTYPE:
						return refreshUserDefinedCategoryType(customerId, 'userDefined1', 1);
					case cacheTypes.USERDEFINEDOBJECT2CATEGORYTYPE:
						return refreshUserDefinedCategoryType(customerId, 'userDefined2', 2);
					case cacheTypes.USERDEFINEDOBJECT3CATEGORYTYPE:
						return refreshUserDefinedCategoryType(customerId, 'userDefined3', 3);
					case cacheTypes.USERDEFINEDOBJECT4CATEGORYTYPE:
						return refreshUserDefinedCategoryType(customerId, 'userDefined4', 4);
					case cacheTypes.METADATA:
						return Metadata.customer(customerId)
							.get()
							.then(function (res) {
								AppService.setMetadata(res);
							});
					case cacheTypes.SELF:
						return Self.getCustomerSelf(customerId).then(function (res) {
							AppService.setSelf(res);
						});
				}
			});

			return $q.all(promises);
		}

		/**
		 * Broadcast channel to communicate between tabs
		 * @see https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
		 *
		 * Need two channels because the tab that broadcasts won't get the message otherwise
		 */

		const channelName = 'cache_refresh_channel';
		const broadcastChannel = new BroadcastChannel(channelName);
		const listenChannel = new BroadcastChannel(channelName);

		const typeMap = {
			[cacheTypes.ACTIVITYCUSTOMFIELD]: 'activity',
			[cacheTypes.TODOCUSTOMFIELD]: 'todo',
			[cacheTypes.AGREEMENTCUSTOMFIELD]: 'agreement',
			[cacheTypes.APPOINTMENTCUSTOMFIELD]: 'appointment',
			[cacheTypes.CLIENTCUSTOMFIELD]: 'account',
			[cacheTypes.CONTACTCUSTOMFIELD]: 'contact',
			[cacheTypes.LEADCUSTOMFIELD]: 'lead',
			[cacheTypes.ORDERCUSTOMFIELD]: 'order',
			[cacheTypes.ORDEREDPRODUCTCUSTOMFIELD]: 'orderrow',
			[cacheTypes.PRODUCTCUSTOMFIELD]: 'product',
			[cacheTypes.PROJECTCUSTOMFIELD]: 'project',
			[cacheTypes.PROJECTPLANCUSTOMFIELD]: 'projectPlan',
			[cacheTypes.TICKETCUSTOMFIELD]: 'ticket',
			[cacheTypes.USERCUSTOMFIELD]: 'user',
			[cacheTypes.USERDEFINEDOBJECTCATEGORY]: { type: 'userDefined1', number: 1 },
			[cacheTypes.USERDEFINEDOBJECT2CATEGORY]: { type: 'userDefined2', number: 2 },
			[cacheTypes.USERDEFINEDOBJECT3CATEGORY]: { type: 'userDefined3', number: 3 },
			[cacheTypes.USERDEFINEDOBJECT4CATEGORY]: { type: 'userDefined4', number: 4 },
			[cacheTypes.USERDEFINEDOBJECTCATEGORYTYPE]: { type: 'userDefined1', number: 1 },
			[cacheTypes.USERDEFINEDOBJECT2CATEGORYTYPE]: { type: 'userDefined2', number: 2 },
			[cacheTypes.USERDEFINEDOBJECT3CATEGORYTYPE]: { type: 'userDefined3', number: 3 },
			[cacheTypes.USERDEFINEDOBJECT4CATEGORYTYPE]: { type: 'userDefined4', number: 4 },
			[cacheTypes.USERDEFINEDOBJECTCUSTOMFIELD]: { type: 'userDefined1', number: 1 },
			[cacheTypes.USERDEFINEDOBJECT2CUSTOMFIELD]: { type: 'userDefined2', number: 2 },
			[cacheTypes.USERDEFINEDOBJECT3CUSTOMFIELD]: { type: 'userDefined3', number: 3 },
			[cacheTypes.USERDEFINEDOBJECT4CUSTOMFIELD]: { type: 'userDefined4', number: 4 }
		};

		const refreshCustomFieldsNew = (cacheType, customerId) => {
			const type = typeMap[cacheType];

			return CustomField.customer(customerId)
				.setType(type)
				.find({})
				.then(({ data }) => {
					$rootScope.$broadcast('cacheRefresher.customField', type);

					return { cacheType, type, data };
				});
		};

		const refreshUserDefinedCustomFieldsNew = (cacheType, customerId) => {
			const { type, number } = typeMap[cacheType];

			return CustomField.customer(customerId)
				.setId(number)
				.findUdo({})
				.then(({ data }) => {
					$rootScope.$broadcast('cacheRefresher.userDefinedObjectCustomField', type);

					return { cacheType, type, data };
				});
		};

		const refreshUserDefinedCategoryTypeNew = cacheType => {
			const { type, number } = typeMap[cacheType];

			return UserDefinedCategoryType.setId(number)
				.find({})
				.then(({ data }) => ({ cacheType, type, data }));
		};

		const refreshUserDefinedCategoryNew = cacheType => {
			const { type, number } = typeMap[cacheType];

			return UserDefinedCategory.setId(number)
				.find({})
				.then(({ data }) => ({ cacheType, type, data }));
		};

		const refreshDefault = (cacheType, resource, type) =>
			resource.find({}).then(({ data }) => ({ cacheType, type, data }));

		const refreshNew = types => {
			const customerId = AppService.getCustomerId();

			// Broadcast on listen channel so other tabs can cancel the debounce
			listenChannel.postMessage({ customerId, msg: 'cancelDebounce' });

			const promises = types.map(cacheType => {
				switch (cacheType) {
					case cacheTypes.ACTIVITYTYPE:
						return refreshDefault(cacheType, ActivityType, 'activity');
					case cacheTypes.APPOINTMENTTYPE:
						return refreshDefault(cacheType, AppointmentType.customer(customerId), 'appointment');
					case cacheTypes.CLIENTCATEGORY:
						return refreshDefault(cacheType, AccountCategory.customer(customerId), 'account');
					case cacheTypes.CLIENTCATEGORYTYPE:
						return refreshDefault(cacheType, AccountCategoryType.customer(customerId), 'account');
					case cacheTypes.CONTACTCATEGORY:
						return refreshDefault(cacheType, ContactCategory.customer(customerId), 'contact');
					case cacheTypes.CONTACTCATEGORYTYPE:
						return refreshDefault(cacheType, ContactCategoryType.customer(customerId), 'contact');
					case cacheTypes.ORDERSTAGE: {
						const stageFilter = new RequestBuilder();
						stageFilter.addSort(OrderStage.attr.probability, true);
						stageFilter.addSort(OrderStage.attr.name, true);
						return OrderStage.customer(customerId)
							.find(stageFilter.build())
							.then(({ data }) => ({
								cacheType,
								data
							}));
					}
					case cacheTypes.PRODUCT:
						if (AppService.getTotals('products') > 4000) {
							return $q.when([]);
						}
						return Product.customer(customerId)
							.getAll()
							.then(data => {
								$rootScope.$broadcast('cacheRefresher.products');

								return { cacheType, data };
							});
					case cacheTypes.PRODUCTCATEGORY: {
						const productCategoryFilter = new RequestBuilder();
						productCategoryFilter.addSort(ProductCategory.attr.sortId, true);
						productCategoryFilter.addSort(ProductCategory.attr.description, true);
						return ProductCategory.customer(customerId)
							.find(productCategoryFilter.build())
							.then(({ data }) => {
								$rootScope.$broadcast('cacheRefresher.productCategories');

								return { cacheType, data };
							});
					}
					case cacheTypes.ROLE:
						return $q
							.all({
								role: Role.getMap(),
								user: User.getMap()
							})
							.then(res => ({
								cacheType,
								data: {
									role: res.role.data,
									user: res.user.data
								}
							}));
					case cacheTypes.USER:
						return User.getMap().then(({ data }) => {
							// Self might have been updated (only name), trigger event if that is the case
							const self = AppService.getSelf();
							const updatedSelf = data.active.find(user => user.id === self.id);
							if (updatedSelf && updatedSelf.name !== self.name) {
								self.name = updatedSelf.name;
								$rootScope.$broadcast('self.updated', self);
							}

							return { cacheType, data };
						});
					case cacheTypes.ACTIVITYCUSTOMFIELD:
					case cacheTypes.TODOCUSTOMFIELD:
					case cacheTypes.AGREEMENTCUSTOMFIELD:
					case cacheTypes.APPOINTMENTCUSTOMFIELD:
					case cacheTypes.CLIENTCUSTOMFIELD:
					case cacheTypes.CONTACTCUSTOMFIELD:
					case cacheTypes.LEADCUSTOMFIELD:
					case cacheTypes.ORDERCUSTOMFIELD:
					case cacheTypes.ORDEREDPRODUCTCUSTOMFIELD:
					case cacheTypes.PRODUCTCUSTOMFIELD:
					case cacheTypes.PROJECTCUSTOMFIELD:
					case cacheTypes.PROJECTPLANCUSTOMFIELD:
					case cacheTypes.TICKETCUSTOMFIELD:
					case cacheTypes.USERCUSTOMFIELD:
						return refreshCustomFieldsNew(cacheType, customerId);
					case cacheTypes.USERDEFINEDOBJECTCUSTOMFIELD:
					case cacheTypes.USERDEFINEDOBJECT2CUSTOMFIELD:
					case cacheTypes.USERDEFINEDOBJECT3CUSTOMFIELD:
					case cacheTypes.USERDEFINEDOBJECT4CUSTOMFIELD:
						return refreshUserDefinedCustomFieldsNew(cacheType, customerId);
					case cacheTypes.USERDEFINEDOBJECTCATEGORY:
					case cacheTypes.USERDEFINEDOBJECT2CATEGORY:
					case cacheTypes.USERDEFINEDOBJECT3CATEGORY:
					case cacheTypes.USERDEFINEDOBJECT4CATEGORY:
						return refreshUserDefinedCategoryNew(cacheType);
					case cacheTypes.USERDEFINEDOBJECTCATEGORYTYPE:
					case cacheTypes.USERDEFINEDOBJECT2CATEGORYTYPE:
					case cacheTypes.USERDEFINEDOBJECT3CATEGORYTYPE:
					case cacheTypes.USERDEFINEDOBJECT4CATEGORYTYPE:
						return refreshUserDefinedCategoryTypeNew(cacheType);
					case cacheTypes.METADATA:
						return Metadata.customer(customerId)
							.get()
							.then(data => ({ cacheType, data }));
					case cacheTypes.SELF:
						return Self.getCustomerSelf(customerId).then(data => ({ cacheType, data }));
					default:
						logError(cacheType, 'CacheRefresher.refresh - Unknown cache type');
						return undefined;
				}
			});

			return $q.all(
				promises.forEach(p =>
					p.then(res => {
						if (!res) {
							return;
						}

						const { cacheType, type, data } = res;
						broadcastChannel.postMessage({ cacheType, type, data, customerId });
					})
				)
			);
		};

		// Add random delay between 10 and 30 seconds
		const debounceTime = 10_000 + Math.random() * 20_000;

		let debouncedRefresh = _.debounce(refresh, debounceTime);

		const refreshProxy = types => {
			if (FeatureHelper.hasSoftDeployAccess('FIX_MULTI_TAB_REFRESH')) {
				debouncedRefresh = _.debounce(refreshNew, debounceTime);
			}

			debouncedRefresh(types);
		};

		listenChannel.onmessage = event => {
			if (!event.data) {
				return;
			}

			const { msg, cacheType, data, type, customerId } = event.data;

			if (customerId !== AppService.getCustomerId()) {
				return;
			}

			if (msg === 'cancelDebounce') {
				debouncedRefresh?.cancel();
				return;
			}

			if (!cacheType || !data) {
				return;
			}

			switch (cacheType) {
				case cacheTypes.ACTIVITYTYPE:
				case cacheTypes.APPOINTMENTTYPE:
					return AppService.setActivityTypes(type, data);
				case cacheTypes.CLIENTCATEGORY:
				case cacheTypes.CONTACTCATEGORY:
					return AppService.setCategories(type, data);
				case cacheTypes.CLIENTCATEGORYTYPE:
				case cacheTypes.CONTACTCATEGORYTYPE:
					return AppService.setCategoryTypes(type, data);
				case cacheTypes.ORDERSTAGE:
					return AppService.setStages(data);
				case cacheTypes.PRODUCT:
					return AppService.setProducts(data);
				case cacheTypes.PRODUCTCATEGORY:
					return AppService.setProductCategories(data);
				case cacheTypes.ROLE:
					AppService.setRoleMap(data.role);
					AppService.setUserMap(data.user);
					return;
				case cacheTypes.USER:
					return AppService.setUserMap(data);
				case cacheTypes.ACTIVITYCUSTOMFIELD:
				case cacheTypes.TODOCUSTOMFIELD:
				case cacheTypes.AGREEMENTCUSTOMFIELD:
				case cacheTypes.APPOINTMENTCUSTOMFIELD:
				case cacheTypes.CLIENTCUSTOMFIELD:
				case cacheTypes.CONTACTCUSTOMFIELD:
				case cacheTypes.LEADCUSTOMFIELD:
				case cacheTypes.ORDERCUSTOMFIELD:
				case cacheTypes.ORDEREDPRODUCTCUSTOMFIELD:
				case cacheTypes.PRODUCTCUSTOMFIELD:
				case cacheTypes.PROJECTCUSTOMFIELD:
				case cacheTypes.USERCUSTOMFIELD:
				case cacheTypes.USERDEFINEDOBJECTCUSTOMFIELD:
				case cacheTypes.USERDEFINEDOBJECT2CUSTOMFIELD:
				case cacheTypes.USERDEFINEDOBJECT3CUSTOMFIELD:
				case cacheTypes.USERDEFINEDOBJECT4CUSTOMFIELD:
				case cacheTypes.PROJECTPLANCUSTOMFIELD:
				case cacheTypes.TICKETCUSTOMFIELD:
					return AppService.setCustomFields(type, data);
				case cacheTypes.USERDEFINEDOBJECTCATEGORY:
				case cacheTypes.USERDEFINEDOBJECT2CATEGORY:
				case cacheTypes.USERDEFINEDOBJECT3CATEGORY:
				case cacheTypes.USERDEFINEDOBJECT4CATEGORY:
					return AppService.setCategories(type, data);
				case cacheTypes.USERDEFINEDOBJECTCATEGORYTYPE:
				case cacheTypes.USERDEFINEDOBJECT2CATEGORYTYPE:
				case cacheTypes.USERDEFINEDOBJECT3CATEGORYTYPE:
				case cacheTypes.USERDEFINEDOBJECT4CATEGORYTYPE:
					return AppService.setCategoryTypes(type, data);
				case cacheTypes.METADATA:
					return AppService.setMetadata(data);
				case cacheTypes.SELF:
					return AppService.setSelf(data);
			}
		};

		return {
			refresh: refreshProxy,
			types: cacheTypes
		};
	}
]);

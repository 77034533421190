import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Checkbox, IconName, TableColumn, TableRow } from '@upsales/components';
import './TodoListTableRow.scss';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'Store/index';
import { SlideFade } from 'App/components/animations';
import { toggleMultiselectedId } from 'Store/actions/TodoActions';
import Todo from 'App/resources/Model/Todo';
import TodoListTableRowDescriptionCol from './TodoListTableRowDescriptionCol';
import { ListViewColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { DisplayType } from 'App/babel/attributes/Attribute';
import { getDateString, getDateTimeString } from 'App/helpers/DateHelpers';
import TodoListTableColumn, { TodoListDisplayType, TodoTypeProps } from './TodoListColumns';
import { useSoftDeployAccess } from 'App/components/hooks';

const mapStateToProps = ({ Todo }: RootState) => ({
	multiselect: Todo.multiselect,
	selectedId: Todo.selectedId,
	daysToHighlight: Todo.daysToHighlight,
	selectedView: Todo.selectedView
});

const mapDispatchToProps = {
	toggleMultiselectedId
};

export const onClick = (todo: Todo) => {
	switch (todo.type) {
		case 'appointment':
			Tools.$upModal.open('editAppointment', { id: todo.id });
			break;
		case 'phonecall': {
			// Pass some data to have preloaded
			const { id, priority, description, date, time, client, contacts, custom } = todo;
			const activity = { id, priority, description, date, time, client, contacts, custom };
			openDrawer('EditPhonecall', { activity, shouldIterateList: true });
			break;
		}
		case 'todo':
			openDrawer('EditTodo', { todo });
			break;
		default:
			Tools.$upModal.open('editActivity', { id: todo.id });
	}
};

export const setTypeProps = (todo: Todo): TodoTypeProps => {
	let descriptionTitle = '';
	let timeSubTitle = '';
	let icon: IconName | null = null;
	let isTodo = false;
	let isTodoNoRelation = false;

	const useDescription = Tools.FeatureHelper.hasSoftDeployAccess('TASKS_COLUMN_IMPROVEMENTS');

	switch (todo.type) {
		case 'todo':
			descriptionTitle = todo.description.replace(/\n/gm, ' ');
			isTodo = true;
			isTodoNoRelation =
				!todo.client && (!todo.contacts || !todo.contacts[0]) && !todo.opportunity && !todo.projectPlan;
			break;
		case 'appointment':
			descriptionTitle = useDescription ? todo.description : T('default.appointment');
			icon = 'calendar';
			break;
		case 'phonecall':
			descriptionTitle = useDescription ? todo.description : T('todoListTableRow.phonecall');
			icon = 'phone';
			break;
		default:
			descriptionTitle = '';
			icon = 'cross' as IconName;
			break;
	}

	if (todo.date) {
		// If appointment or if other but with time
		if (todo.type === 'appointment' || todo.time) {
			timeSubTitle = getDateTimeString(todo.date);
		} else {
			timeSubTitle = getDateString(todo.date);
		}
	}

	return {
		isTodoNoRelation,
		descriptionTitle,
		timeSubTitle,
		icon,
		isTodo
	};
};

const highlightRow = (daysToHighlight: string, todo: Todo) => {
	return (
		daysToHighlight === 'yesterday' &&
		moment(todo.date).isBetween(
			moment().subtract(1, 'days').startOf('day'),
			moment().subtract(1, 'days').endOf('day'),
			undefined,
			'[]'
		)
	);
};

const isOverdue = (todo: Todo) => {
	if (todo.type === 'appointment' || todo.time) {
		return moment(todo.date).isBefore(moment());
	}
	return moment(todo.date).isBefore(moment().subtract(1, 'days').endOf('day'));
};

const shouldShowOutcomeButtons = (todo: Todo) => {
	return (
		todo.type === 'appointment' && todo.client && moment(todo.date).isBefore(moment()) && todo.outcome === 'planned'
	);
};

export const ActionColumn = ({
	todo,
	classes,
	typeProps = setTypeProps(todo),
	alwaysRenderColoumn = false
}: {
	todo: Todo;
	classes: BemClass;
	typeProps?: TodoTypeProps;
	alwaysRenderColoumn?: boolean;
}) => {
	const colProps = {
		classes,
		todo,
		typeProps
	};
	const usesNewColumns = useSoftDeployAccess('TASKS_COLUMN_IMPROVEMENTS');

	return shouldShowOutcomeButtons(todo) ? (
		<TodoListTableColumn type={TodoListDisplayType.OutcomeButtons} {...colProps} />
	) : !typeProps.isTodoNoRelation ? (
		<TableColumn className={classes.elem('buttonCol').mod({ small: usesNewColumns }).b()}>
			<div className={classes.elem('buttonCol-wrap').b()}>
				{todo.type !== 'todo' ? (
					usesNewColumns ? (
						<TodoListTableColumn type={TodoListDisplayType.ActionButtonSmall} {...colProps} />
					) : (
						<TodoListTableColumn type={TodoListDisplayType.ActionButton} {...colProps} />
					)
				) : null}
			</div>
		</TableColumn>
	) : alwaysRenderColoumn ? (
		<TableColumn />
	) : null;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
	todo: Todo;
	isMultiselected: boolean;
	isMultiselectDisabled: boolean;
};

const TodoListTableRow = ({
	todo,
	isMultiselected,
	toggleMultiselectedId,
	isMultiselectDisabled,
	multiselect,
	selectedId,
	daysToHighlight,
	selectedView
}: Props) => {
	const classes = new BemClass('TodoListTableRow');
	const typeProps = setTypeProps(todo);

	const isShared =
		Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS') && selectedView?.startsWith('custom-all-');

	const colProps = {
		classes,
		todo,
		typeProps
	};

	return (
		<TableRow
			className={classes
				.mod({
					[todo.type]: true,
					hot: todo.hot,
					selected: todo.id === selectedId,
					selectedHighlight: todo.id === selectedId && highlightRow(daysToHighlight, todo),
					highlight: highlightRow(daysToHighlight, todo),
					redDate: isOverdue(todo)
				})
				.b()}
			onClick={onClick.bind(null, todo)}
			key={todo.id}
		>
			<TableColumn
				onClick={
					multiselect.enabled
						? e => {
								e.stopPropagation();
								toggleMultiselectedId(todo.id);
						  }
						: undefined
				}
			>
				<SlideFade bounce direction="left" visible={multiselect.enabled} delayInMs={300} delayOutInMs={0}>
					<div>
						<Checkbox size="xs" checked={isMultiselected} disabled={isMultiselectDisabled} />
					</div>
				</SlideFade>
			</TableColumn>

			<TodoListTableColumn type={TodoListDisplayType.Icon} {...colProps} />
			<TodoListTableColumn type={TodoListDisplayType.WhatToDo} {...colProps} />
			<TodoListTableColumn type={TodoListDisplayType.WithWhom} {...colProps} />

			<ActionColumn todo={todo} typeProps={typeProps} classes={classes} />

			{!typeProps.isTodoNoRelation ? <TodoListTableRowDescriptionCol todo={todo} /> : null}

			{isShared ? <ListViewColumn type={DisplayType.User} data={todo.users[0]} colSize="md" /> : null}

			<TodoListTableColumn type={TodoListDisplayType.Tools} {...colProps} />
		</TableRow>
	);
};

export const detached = TodoListTableRow;

export default connector(TodoListTableRow);

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon } from '@upsales/components';
import './EditorWrap.scss';
import EditorSidebarToggle from './EditorSidebarToggle';
import BrowserService from 'Services/BrowserService';

class EditorWrap extends React.PureComponent {
	onResizeMouseDown = e => {
		e.preventDefault();

		const stopResize = () => {
			this._wrapper.classList.remove('EditorWrap--sidebar-resizing');
			window.removeEventListener('mousemove', this.onWinResize);
			window.removeEventListener('mouseup', stopResize);
		};

		window.addEventListener('mousemove', this.onWinResize);
		window.addEventListener('mouseup', stopResize);
	};

	onWinResize = e => {
		this._wrapper.classList.add('EditorWrap--sidebar-resizing');
		const newWidth = this._sidebar.clientWidth - (e.pageX - this._sidebar.getBoundingClientRect().left);
		if (newWidth >= this.props.sidebarMinWidth && newWidth <= document.body.clientWidth - this.props.mainMinWidth) {
			this._sidebar.style.width = newWidth + 'px';
			this._wrapper.style.marginRight = newWidth + 'px';
		}
	};

	componentDidMount() {
		this._handle.addEventListener('mousedown', this.onResizeMouseDown);
		window.addEventListener('resize', this.onWinResize);
	}

	componentWillUnmount() {
		this._handle.removeEventListener('mousedown', this.onResizeMouseDown);
		window.removeEventListener('resize', this.onWinResize);
	}

	render() {
		const { sidebarDisabled, renderMain, renderSidebar, className, sidebarVisible } = this.props;
		const classes = new bemClass('EditorWrap', className);

		const fnOpts = {};
		return (
			<div
				className={classes.mod({ 'sidebar-visible': sidebarVisible && !sidebarDisabled }).b()}
				ref={r => (this._wrapper = r)}
			>
				{renderMain(fnOpts)}
				<div
					className={classes.elem('sidebar').b()}
					ref={r => (this._sidebar = r)}
					style={sidebarVisible ? { right: `${BrowserService.scrollbarWidth}px` } : null}
				>
					{sidebarDisabled ? null : (
						<React.Fragment>
							{sidebarVisible ? (
								<EditorSidebarToggle
									icon="chevron-right"
									sidebarVisible={sidebarVisible}
									onClick={this.props.onClose}
									className={classes.elem('sidebar-toggle').b()}
								/>
							) : null}

							<EditorSidebarToggle
								icon="cog"
								sidebarVisible={sidebarVisible}
								onClick={this.props.onOpen}
								className={classes.elem('sidebar-toggle').b()}
							/>
						</React.Fragment>
					)}

					<div className={classes.elem('sidebar-handle').b()} ref={r => (this._handle = r)}>
						<Icon name="ellipsis-v" />
					</div>

					{renderSidebar(fnOpts)}
				</div>
			</div>
		);
	}
}

EditorWrap.propTypes = {
	sidebarVisible: PropTypes.bool.isRequired,
	className: PropTypes.string,
	sidebarMinWidth: PropTypes.number.isRequired,
	mainMinWidth: PropTypes.number.isRequired,
	renderMain: PropTypes.func.isRequired,
	renderSidebar: PropTypes.func.isRequired,
	onOpen: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	sidebarDisabled: PropTypes.bool.isRequired
};

EditorWrap.defaultProps = {
	sidebarVisible: false,
	sidebarMinWidth: 300,
	mainMinWidth: 600,
	renderMain: () => {},
	renderSidebar: () => {},
	onOpen: () => {},
	onClose: () => {},
	sidebarDisabled: false
};

export default EditorWrap;

import Breadcrumbs, { BreadcrumbItem } from 'App/upsales/common/components/react/Breadcrumbs';
import { Tooltip, Icon, Button, Toggle, Input, ButtonGroup } from '@upsales/components';
ReactTemplates.admin.editAutomation = window.ReactCreateClass({
	getInitialState: function () {
		return {
			nameTouched: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			name: t('default.name'),
			active: t('default.active'),
			all: t('default.all').toLowerCase(),
			triggerWhen: t('admin.triggerWhen'),
			whenCreating: t('admin.triggerWhen.create'),
			whenEditing: t('admin.triggerWhen.editing'),
			whenDeleting: t('admin.triggerWhen.deleting'),
			thatMatches: t('admin.triggerMatches'),
			followingCriterias: t('admin.followingCriterias'),
			ofFollowingCriterias: t('admin.ofFollowingCriterias'),
			atLeastOne: t('admin.atLeastOne'),
			actions: t('admin.actions'),
			actionsInfo: t('admin.actionsInfo'),
			noActions: t('admin.noActions'),
			add: t('default.add'),
			automation: t('admin.automation'),
			automationTitle: t('admin.automationTitle'),
			automationInfo: t('admin.automationInfo'),
			'automation-clients': t('admin.automationTypeClient'),
			'automation-contacts': t('admin.automationTypeContact'),
			newAutomation: t('admin.newAutomation'),
			viewAutomation: t('admin.viewAutomation'),
			editAutomation: t('admin.editAutomation')
		};

		this.hasSetFocus = false;
	},
	propChange: function (key, e) {
		this.props.rootData.pageData.propChange(key, e.target.value);
	},
	changeJobType: function (job) {
		this.propChange('job', { target: { value: job } });
	},
	activeChange: function (value) {
		this.props.rootData.pageData.propChange('active', value);
	},
	renderJobType: function (type) {
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;
		var automation = pageData.automation;
		var criterias = pageData.criterias;
		var hasCriterias = criterias.and.length + criterias.or.length > 0;
		return (
			<Button
				shadow="none"
				disabled={hasCriterias}
				type={automation.job === type ? null : 'lined'}
				onClick={this.changeJobType.bind(null, type)}
			>
				{this.lang[type]}
			</Button>
		);
	},
	renderAddCriteriaBtn: function (criteriaType) {
		var automation = this.props.rootData.pageData.automation;
		var hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
		var isSelectedBrand = Tools.AppService.getSelectedBrand() === automation.brandId;
		var isDisabled = hasBrands && !isSelectedBrand && automation.id;
		var addCriteria = this.props.rootData.pageData.addCriteria;
		var saving = this.props.rootData.saving;

		return (
			<button
				type="button"
				onClick={addCriteria.bind(self, criteriaType)}
				className="table-btn add-criteria"
				disabled={isDisabled || saving}
			>
				<b className="fa fa-plus-circle" /> {this.lang.add}
			</button>
		);
	},
	renderCriteriaHeader: function (text) {
		return (
			<div className="admin-table-top">
				<span className="admin-table-title">{text}</span>
			</div>
		);
	},
	renderCriteria: function (type, criteria, i) {
		var props = this.props;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;
		var automation = this.props.rootData.pageData.automation;

		var hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
		var isSelectedBrand = Tools.AppService.getSelectedBrand() === automation.brandId;
		var isDisabled = hasBrands && !isSelectedBrand && automation.id;

		return React.createElement(ReactTemplates.admin.triggerCriteria, {
			key: criteria.uuid,
			criteria: criteria,
			criterias: rootData.pageData.availableCriterias,
			disabled: isDisabled || rootData.saving,
			onDelete: pageData.removeCriteria.bind(this, type, i),
			onChange: pageData.onCriteriaChange.bind(this, type, i),
			selectOptions: props.staticData.selectOptions,
			getSelectedCriteriaObject: pageData.getSelectedCriteriaObject,
			getCriteriaUnfiltered: pageData.getCriteriaUnfiltered,
			tools: props.tools
		});
	},
	removeAction: function (i, e) {
		e.stopPropagation();
		this.props.rootData.pageData.removeAction(i);
	},
	renderAction: function (action, i) {
		var props = this.props;
		var t = window.Tools.$translate;
		var editAction = props.rootData.pageData.editAction;
		var saving = props.rootData.saving;
		var automation = this.props.rootData.pageData.automation;

		var hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
		var isSelectedBrand = Tools.AppService.getSelectedBrand() === automation.brandId;
		var isDisabled = hasBrands && !isSelectedBrand && automation.id;
		const dontExecActivity =
			(action.action === 'CreateActivity' || action.action === 'UpdateActivity') &&
			Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
			Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');

		return (
			<div
				key={'action-' + i}
				className={dontExecActivity ? 'action-row dontexec' : 'action-row'}
				onClick={editAction.bind(this, i, action)}
			>
				{dontExecActivity ? (
					<Tooltip title={t('admin.automation.dontExecActivityAutomation')}>
						<Icon color="orange" name="exclamation-triangle" />
					</Tooltip>
				) : null}
				{t('automationAction.' + action.action)}

				{!isDisabled && (
					<button
						type="button"
						className="up-btn btn-link delete-trigger btn-grey"
						onClick={this.removeAction.bind(this, i)}
						disabled={saving}
					>
						<b className="fa fa-trash-o" />
					</button>
				)}
			</div>
		);
	},
	nameBlur: function () {
		this.setState({ nameTouched: true });
	},
	render: function () {
		var self = this;
		var rootData = this.props.rootData;
		var pageData = rootData.pageData;
		var saving = rootData.saving;
		var addAction = pageData.addAction;
		var automation = pageData.automation;
		var isEdit = !automation.id;

		var hasBrands = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BRANDS);
		var isSelectedBrand = Tools.AppService.getSelectedBrand() === automation.brandId;
		var isDisabled = hasBrands && !isSelectedBrand && automation.id && automation.id;

		var nameClass = 'floating-label-input';
		if (automation && automation.name && automation.name.length) {
			nameClass += ' has-value';
		}
		if (this.state.nameTouched && !(automation && automation.name && automation.name.length)) {
			nameClass += ' has-error';
		}

		var showEntityToggle = isEdit && Tools.FeatureHelper.hasSoftDeployAccess('ADMIN_CONTACT_AUTOMATION');

		var actionInfoStyle = {
			marginTop: 0,
			paddingRight: showEntityToggle ? '300px' : null
		};

		return (
			<div id="admin-page-edit-trigger">
				<div className="admin-page-header one-row">
					<Breadcrumbs key="bread">
						<BreadcrumbItem
							key="automations"
							state="administration.automations"
							text={this.lang.automation}
						/>
						<BreadcrumbItem
							key="edit"
							text={
								automation.id
									? isDisabled
										? this.lang.viewAutomation
										: this.lang.editAutomation
									: this.lang.newAutomation
							}
						/>
					</Breadcrumbs>

					<div className={nameClass}>
						<Input
							inline
							placeholder={self.lang.name}
							onBlur={this.nameBlur}
							autofocus
							maxLength="128"
							type="text"
							value={automation.name}
							onChange={self.propChange.bind(self, 'name')}
							disabled={isDisabled || saving}
						/>
					</div>

					<div className="trigger-active">
						<label>{self.lang.active}</label>
						<Toggle
							size="lg"
							color="medium-green"
							checked={automation.active}
							onChange={self.activeChange}
							disabled={isDisabled || saving}
						/>
					</div>
				</div>

				<div id="admin-content">
					<div id="action-info" style={actionInfoStyle} className="admin-section">
						<h2>{isEdit ? self.lang.automationTitle : self.lang[automation.job]}</h2>
						<p className="text-upsales-grey">{self.lang.automationInfo}</p>
						{showEntityToggle ? (
							<div className="automation-job-type-wrapper">
								<ButtonGroup>
									{self.renderJobType('automation-clients')}
									{self.renderJobType('automation-contacts')}
								</ButtonGroup>
							</div>
						) : null}
					</div>

					<div className="admin-table">
						<div style={{ top: 32 }} className="vert-line" />
						<div className="line" />
						{self.renderCriteriaHeader(
							<span>
								{self.lang.thatMatches} <b>{self.lang.all}</b> {self.lang.followingCriterias}
								{':'}
							</span>
						)}

						{_.map(pageData.criterias.and, self.renderCriteria.bind(self, 'and'))}

						{self.renderAddCriteriaBtn('and')}
					</div>

					<div className="admin-table">
						<div className="vert-line" />
						<div className="line" />
						{self.renderCriteriaHeader(
							<span>
								{self.lang.thatMatches} <b>{self.lang.atLeastOne}</b> {self.lang.ofFollowingCriterias}
								{':'}
							</span>
						)}

						{_.map(pageData.criterias.or, self.renderCriteria.bind(self, 'or'))}

						{self.renderAddCriteriaBtn('or')}
					</div>

					<div id="action-info" className="admin-section">
						<h2>{self.lang.actions}</h2>
						<p className="text-upsales-grey">{self.lang.actionsInfo}</p>
					</div>

					<div id="action-table" className="admin-table">
						<div className="vert-line" />
						<div className="line-point" />

						{_.map(automation.actions, self.renderAction)}

						{
							<button
								type="button"
								onClick={addAction}
								className="table-btn add-criteria"
								disabled={isDisabled || saving}
							>
								<b className="fa fa-plus-circle" /> {this.lang.add}
							</button>
						}
					</div>
				</div>
			</div>
		);
	}
});

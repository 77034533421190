import './RecommendedLead.scss';
import React, { useState } from 'react';

import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import { Block, Button, Icon, TableRow, TableColumn, Tooltip } from '@upsales/components';

import ProspectingClient from 'App/resources/Model/ProspectingClient';

type Props = {
	recommendedLead?: ProspectingClient | null;
	addLead?: (lead: ProspectingClient) => void;
	pendingAddLeads?: boolean;
	isAdded?: boolean;
	accountId?: string;
	onClose?: () => void;
};

const RecommendedLead = ({ recommendedLead, addLead, pendingAddLeads, isAdded, accountId, onClose }: Props) => {
	const classes = new BemClass('RecommendedLead');
	const numberFormat = Tools.$filter('numberFormat');
	const [pendingAddLeadAction, setPendingAddLeadAction] = useState(() => false);

	if (!recommendedLead) {
		return <TableRow />;
	}

	const onAddLead = async () => {
		setPendingAddLeadAction(true);
		await addLead!(recommendedLead);
		setPendingAddLeadAction(false);
	};

	const goToAccount = () => {
		if (onClose) {
			onClose();
		}
		Tools.$state.go('account.dashboard', { id: accountId });
	};

	return (
		<TableRow
			key={recommendedLead.prospectingId}
			className={classes.b()}
			onClick={accountId ? goToAccount : undefined}
		>
			<TableColumn className={classes.elem('name').b()} size="lg" title={recommendedLead.name} />

			<TableColumn
				className={classes.elem('revenue').b()}
				size="lg"
				title={numberFormat(recommendedLead.revenue)}
			/>

			<TableColumn
				className={classes.elem('employeesCount').b()}
				size="lg"
				title={`${recommendedLead.noEmployees}`}
			/>

			<TableColumn className={classes.elem('location').b()} size="lg">
				<Block space="mrm">
					<i className="flag-icon flag-icon-se" />
				</Block>
				<span>{recommendedLead.visitingTown}</span>
			</TableColumn>

			<TableColumn align="right">
				{isAdded ? (
					<Icon className={classes.elem('addedLeadIcon').b()} color="bright-green" name="check" />
				) : (
					<Tooltip position="bottom" title={T('order.celebration.addLeadTooltip')} distance={28}>
						<Button
							className={classes.elem('addLead').b()}
							onClick={addLead ? onAddLead : undefined}
							size="md"
							loading={pendingAddLeads || pendingAddLeadAction}
						>
							{T('default.add')}
						</Button>
					</Tooltip>
				)}
			</TableColumn>
		</TableRow>
	);
};

export default RecommendedLead;

'use strict';

angular
	.module('upUi')
	.run(function () {
		// Run state of app
	})
	.provider('$upAdvancedSearch', function () {
		// $upAdvancedSearchProvider
		var AdvancedSearch = this;

		// SearchTypes enum
		var SearchTypes = {
			ACCOUNT: 'account',
			CONTACT: 'contact',
			ACTIVITY: 'activity',
			ORDER: 'order',
			OPPORTUNITY: 'opportunity',
			APPOINTMENT: 'appointment'
		};

		// We are not open
		AdvancedSearch.isOpen = false;

		// Holds the prefilled filters
		AdvancedSearch.filters = null;

		// Deafult searchType is accounts
		AdvancedSearch.searchType = SearchTypes.ACCOUNT;

		// MainCtrl's scope
		AdvancedSearch.mainScope = null;

		// The service/factory
		AdvancedSearch.$get = function ($rootScope, $location, $dynamicBindings) {
			AdvancedSearch.wrapperElement = angular.element('#advanced-search-wrap');
			AdvancedSearch.curtainElement = angular.element('#advanced-search-curtain');

			// Document keyup function
			var keyUp = function (e) {
				if (e.keyCode === 27) {
					closeSearchWindow();
				}
			};

			var disableScroll = function () {
				var body = angular.element(document.body);
				var oldWidth = body.innerWidth();
				body.css('overflow', 'hidden');
				body.width(oldWidth);
			};

			var enableScroll = function () {
				var body = angular.element(document.body);
				body.css('overflow', 'auto');
				body.width('auto');
			};

			// Open function
			function openSearchWindow() {
				$dynamicBindings.on(AdvancedSearch.mainScope);
				disableScroll();
				AdvancedSearch.isOpen = true;

				// Emit open event
				var eventData = { type: AdvancedSearch.searchType };
				if (AdvancedSearch.filters) {
					eventData.filters = AdvancedSearch.filters;
					AdvancedSearch.filters = null;
				}
				$rootScope.$emit('$upAdvancedSearch.open', eventData);

				// Listen for escape and close
				angular.element(document).on('keyup', keyUp);

				// Return instance for chaining
				return Instance;
			}

			// Close function
			function closeSearchWindow(e, callback) {
				if (!AdvancedSearch.isOpen) {
					return;
				}
				enableScroll();
				AdvancedSearch.isOpen = false;

				// Emit close event
				$rootScope.$emit('$upAdvancedSearch.close', {});

				// Unsubscribe for keyup event
				angular.element(document).off('keyup', keyUp);

				// Run callback
				if (typeof callback === 'function') {
					callback();
				}

				// Set searchType to default
				AdvancedSearch.searchType = SearchTypes.ACCOUNT;

				setTimeout(function () {
					$dynamicBindings.off(AdvancedSearch.mainScope, true);
				});

				// Return instance for chaining
				return Instance;
			}

			function SetType(type) {
				// Do not change if the type does not exist or if the type is already set
				if (!SearchTypes[type.toUpperCase()] || SearchTypes[type.toUpperCase()] === AdvancedSearch.searchType) {
					return;
				}

				// Set type
				AdvancedSearch.searchType = type;

				// Event emitting in 3..2..1..NOW!
				$rootScope.$emit('$upAdvancedSearch.typeChange', { type: type });

				// Return instance for chaining
				return Instance;
			}

			// Set som filters to be prefilled when searrch is opened
			function SetFilters(filters) {
				AdvancedSearch.filters = filters;

				return Instance;
			}

			function init() {
				// Click on curtain should close wrapper
				AdvancedSearch.curtainElement.on('click', closeSearchWindow);

				// Close on state change
				$rootScope.$on('$stateChangeSuccess', closeSearchWindow);

				// On page change we close it
				$rootScope.$watch(function () {
					return $location.search();
				}, closeSearchWindow);
			}

			// Run init
			init();

			// Service exposed methods
			var Instance = {
				open: openSearchWindow,
				close: function (callback) {
					return closeSearchWindow(null, callback);
				},
				isOpen: function () {
					return AdvancedSearch.isOpen;
				},
				getSearchType: function () {
					return AdvancedSearch.searchType;
				},
				setSearchType: SetType,
				toggle: function () {
					return AdvancedSearch.isOpen ? closeSearchWindow() : openSearchWindow();
				},
				searchTypes: SearchTypes,
				setFilters: SetFilters,
				getFilters: function () {
					return AdvancedSearch.filters;
				},
				setScope: function (scope) {
					AdvancedSearch.mainScope = scope;
				}
			};

			return Instance;
		};
	});

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { Button } from '@upsales/components';

import Avatar from '../Avatar/Avatar';
import Contact from 'App/resources/Model/Contact';

const propTypes = {
	t: PropTypes.func.isRequired,
	contact: PropTypes.object.isRequired,
	generalData: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	fetching: PropTypes.bool.isRequired,
	fetchingPrintable: PropTypes.bool.isRequired,
	actions: PropTypes.object.isRequired
};

type DataRow = {
	title: string;
	value: string | number;
};

const dataRowFnc = ({ title, value }: DataRow) => {
	return (
		<div key={'data-row-' + title} className="export-info-row" style={{ display: 'flex' }}>
			<span className="export-info-title" style={{ minWidth: '60px', flex: 2 }}>
				{title}
			</span>
			<span className="export-info-data" style={{ flex: 3, color: '#999999', fontStyle: 'italic' }}>
				{_.isNumber(value) ? value : value || ''}
			</span>
		</div>
	);
};

type Actions = {
	openPrintable: () => void;
	closeModal: () => void;
};

type ExportContactDataProps = {
	t: (key: string) => string;
	contact: Contact;
	data: DataRow[];
	generalData: DataRow[];
	fetching: boolean;
	fetchingPrintable: boolean;
	actions: Actions;
};

const ExportContactData = ({
	t,
	contact,
	data,
	generalData,
	fetching,
	fetchingPrintable,
	actions
}: ExportContactDataProps) => {
	const avatarService = Tools.avatarService;
	return (
		<div className="export-contact-data">
			<div className="up-modal-header white-header">
				<h2>{t('contact.exportContactData')}</h2>
				<b className="fa fa-times" onClick={actions.closeModal} />
			</div>
			<div className="up-modal-content">
				<div>
					<Avatar avatarService={avatarService} user={contact} />
					<div className="contact-wrapper">
						<h4>{contact.name}</h4>
					</div>
				</div>
				<div className="export-info-wrapper">
					<h3>{t('default.generalInfo')}</h3>
					{_.map(generalData, dataRowFnc)}
					<h3>{t('default.data')}</h3>
					{fetching ? (
						<div className="spinner-wrapper">
							<b className="fa fa-spinner fa-spin" />
						</div>
					) : (
						_.map(data, dataRowFnc)
					)}
				</div>
			</div>
			<div className="up-modal-controls white">
				<div className="modal-info-btns">
					<Button
						color="bright-blue"
						disabled={fetchingPrintable}
						onClick={actions.openPrintable}
						className="main-action"
						shadow={'none'}
					>
						{fetchingPrintable ? (
							<span className="spinner-wrapper">
								<b className="fa fa-spinner fa-spin" />
							</span>
						) : (
							''
						)}
						{t('default.print')}
					</Button>
					<Button color="grey" type="link" onClick={actions.closeModal}>
						{t('default.abort')}
					</Button>
				</div>
			</div>
			<div id="export-data-printarea" />
		</div>
	);
};

ExportContactData.propTypes = propTypes;

export default ExportContactData;

const printPropTypes = {
	t: PropTypes.func.isRequired,
	generalData: PropTypes.array,
	entityData: PropTypes.array
};

type ExportDataPrintFormatProps = {
	t: (key: string) => string;
	generalData: DataRow[];
	entityData: { title: string; num: number; rows: (row: any) => JSX.Element }[];
};

export const ExportDataPrintFormat = ({ t, generalData, entityData }: ExportDataPrintFormatProps) => (
	<div className="export-info-wrapper">
		<h3>{t('default.generalInfo')}</h3>
		{_.map(generalData, dataRowFnc)}
		<h3>{t('default.data')}</h3>
		{_.map(entityData, entity => (
			<div>
				<h4>{`${entity.title} (${entity.num})`}</h4>
				{entity.rows}
			</div>
		))}
	</div>
);

ExportDataPrintFormat.propTypes = printPropTypes;

'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.admin').controller('ListTriggers', [
	'AppService',
	'$scope',
	'$rootScope',
	'$stateParams',
	'$state',
	'Trigger',
	'$upModal',
	'$translate',
	function (AppService, $scope, $rootScope, $stateParams, $state, Trigger, $upModal, $translate) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = 'triggers';
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			triggers: []
		};

		$scope.$on('trigger.deleted', function (e, trigger) {
			var index = _.findIndex(AdminSubCtrl.rootData.pageData.triggers, { id: trigger.id });

			if (index !== -1) {
				AdminSubCtrl.rootData.pageData.triggers.splice(index, 1);
			}
		});

		AdminSubCtrl.rootData.editTrigger = function (trigger) {
			var opts = {};
			if (trigger) {
				opts.id = trigger.id;
			}
			$state.go('administration.trigger', opts);
		};

		AdminSubCtrl.rootData.deleteTrigger = function (trigger) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'admin.trigger',
					body: (
						<>
							{$translate.instant('confirm.removeTrigger')}{" "}
							<b>{(trigger.name || $translate.instant('default.noName'))}</b>{"?"}
						</>
					),
					onClose: confirmed => {
						if (confirmed) {
							Trigger.delete(trigger);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('admin.trigger').toLowerCase(),
					body:
						$translate.instant('confirm.removeTrigger') +
						' <b>' +
						(trigger.name || $translate.instant('default.noName')) +
						'</b>?',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					Trigger.delete(trigger);
				});
		};

		var getTriggers = function () {
			return Trigger.find().then(function (res) {
				AdminSubCtrl.rootData.pageData.triggers = res.data;
			});
		};

		var init = function () {
			getTriggers().then(function () {
				AdminSubCtrl.rootData.pageLoading = false;
			});
		};

		AppService.loadedPromise.then(init);
	}
]);

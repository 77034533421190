import React from 'react';
import PropTypes from 'prop-types';
import { Title, Card, Loader } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './FileStorageApps.scss';
import FileStorageAppView from 'Components/FileStorageAppView';

import { connect } from 'react-redux';
import { selectFolder, checkFolder, removeFolder, resetFileStorageApps } from 'Store/actions/FileStorageAppsActions';

class FileStorageApps extends React.Component {
	constructor(props) {
		super(props);

		this.openSelectFolder = this.openSelectFolder.bind(this);
	}
	state = {
		selectedFolder: null,
		selectedApp: null
	};

	componentDidMount() {
		const { apps, checkFolder, objectId } = this.props;
		if (apps && apps.length) {
			apps.forEach(app => {
				checkFolder(app.id, { clientId: objectId });
			});
		}
		window.addEventListener('message', this.handleRedirectToSelectedFile, false);
	}

	componentWillUnmount() {
		this.props.resetFileStorageApps();
		window.removeEventListener('message', this.handleRedirectToSelectedFile, false);
	}

	handleRedirectToSelectedFile = e => {
		if (e && e.data && e.data.type === 'REDIRECT') {
			window.location = e.data.url;
		}
	};

	openSelectFolder(app) {
		const opts = {
			name: 'fileStorageSelectFolder',
			type: this.props.type,
			integrationId: app.id,
			objectId: this.props.objectId,
			frameless: true,
			fullscreen: true
		};
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('integrationModal', opts).then(res => {
			if (res) {
				const { selectFolder } = this.props;
				selectFolder(app.id, { folderId: res.id, clientId: opts.objectId });
			}
		});
	}

	handleDisconnect = integrationId => () => {
		this.props.removeFolder(integrationId, { clientId: this.props.objectId });
	};

	render() {
		const { apps, selectedApps, selectedAppsFetching, objectId } = this.props;
		const classNames = new bemClass('FileStorageApps');
		const appsWithFolderSelected = [];
		const appsWithoutFolderSelected = [];

		if (apps.length) {
			apps.forEach(app => {
				const otherData = selectedApps.find(selectedApp => selectedApp.integrationId === app.id);
				if (otherData) {
					app = {
						...app,
						...(otherData && { permissionDenied: !!otherData.permissionDenied, files: otherData.files })
					};
					appsWithFolderSelected.push(app);
				} else {
					appsWithoutFolderSelected.push(app);
				}
			});
		}

		if (selectedAppsFetching) {
			return (
				<div className={classNames.elem('loaderWrapper').b()}>
					<Loader />
				</div>
			);
		}

		return (
			<div className={classNames.b()}>
				{appsWithFolderSelected.length
					? appsWithFolderSelected.map(selectedApp => {
							return (
								<FileStorageAppView
									key={selectedApp.id}
									onDisconnect={this.handleDisconnect}
									objectId={objectId}
									app={selectedApp}
								/>
							);
					  })
					: null}

				{appsWithoutFolderSelected.length ? (
					<React.Fragment>
						<Title className={classNames.elem('header').b()}>
							{Tools.$translate('account.files.header')}
						</Title>
						<Title className={classNames.elem('subHeader').b()} color="grey-10">
							{Tools.$translate('account.files.subHeader')}
						</Title>
						<div className={classNames.elem('appWrapper').b()}>
							{appsWithoutFolderSelected.map(app => {
								const cardStyle = {
									backgroundImage: `url(${app.imageLink})`,
									color: app.color
								};
								return (
									<div
										key={app.id}
										onClick={() => this.openSelectFolder(app)}
										className={classNames.elem('app').b()}
									>
										<Card style={cardStyle} />
										<Title className={classNames.elem('appTitle').b()} color="grey-10" size="sm">
											{app.name}
										</Title>
									</div>
								);
							})}
						</div>
					</React.Fragment>
				) : null}
			</div>
		);
	}
}

FileStorageApps.propTypes = {
	selectFolder: PropTypes.func.isRequired,
	checkFolder: PropTypes.func.isRequired,
	removeFolder: PropTypes.func.isRequired,
	apps: PropTypes.array.isRequired,
	selectedApps: PropTypes.array,
	selectedApp: PropTypes.number,
	selectedFolder: PropTypes.string,
	onFolderSelect: PropTypes.func,
	resetFileStorageApps: PropTypes.func,
	objectId: PropTypes.number,
	selectedAppsFetching: PropTypes.bool,
	type: PropTypes.oneOf(['client', 'contact']).isRequired
};

const mapStateToProps = state => ({
	selectedApps: state.FileStorageApps.selectedApps,
	selectedAppsFetching: state.FileStorageApps.selectedAppsFetching
});

const mapDispatchToProps = {
	selectFolder,
	checkFolder,
	removeFolder,
	resetFileStorageApps
};

export const detached = FileStorageApps;
const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(FileStorageApps);
export default ConnectedComponent;

window.FileStorageApps = ConnectedComponent;

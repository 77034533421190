import { Card, Input, ButtonSelect, Text, Loader, Icon, OutsideClick } from '@upsales/components';
import withAutoComplete from 'Components/Helpers/GoogleMapsAutoComplete';
import AccountProfile from 'App/resources/Model/AccountProfile';
import { makeCancelable } from 'App/babel/helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import logError from 'App/babel/helpers/logError';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import React, { Component } from 'react';

import './LocationPicker.scss';

const AutoCompleteInput = withAutoComplete(Input);

const ButtonSelectValues = {
	client: 'client',
	upsales: 'upsales'
};

type Props = {
	client?: Pick<Client, 'addresses' | 'name'>;
	value?: string;
	onChange: (location: string | null) => void;
	disabled?: boolean;
};
type State = {
	input: string;
	buttonSelect: (typeof ButtonSelectValues)[keyof typeof ButtonSelectValues];
	focus: boolean;
	companyProfileAddress?: Pick<AccountProfile, 'address' | 'city' | 'zipcode'>;
	loading: boolean;
	focusFromArrow: boolean;
};

class LocationPicker extends Component<Props, State> {
	lang: { [key: string]: string };
	accountProfilePromise: { promise: Promise<any>; cancel: () => void } | undefined;
	accountName: AccountProfile['name'];
	dropdownRef: React.RefObject<HTMLInputElement>;

	constructor(p: Props) {
		super(p);
		this.state = {
			input: '',
			buttonSelect: ButtonSelectValues.upsales,
			focus: false,
			loading: true,
			focusFromArrow: false
		};
		this.lang = {
			searchAddress: T('default.searchAddress')
		};
		this.accountName = Tools.AppService.getAccountSelf().client.name;
		this.dropdownRef = React.createRef();
	}

	componentDidMount() {
		this.accountProfilePromise = makeCancelable(Tools.AccountProfile.get());
		this.accountProfilePromise.promise
			.then(({ data }) =>
				this.setState({
					companyProfileAddress: { address: data?.address, city: data?.city, zipcode: data?.zipcode },
					loading: false
				})
			)
			.catch(e => {
				if (!e.isCanceled) {
					logError(e, 'Failed to fetch account profile');
				}
			});
	}

	componentWillUnmount() {
		if (this.accountProfilePromise) {
			this.accountProfilePromise.cancel();
		}
	}

	changeLocation(location: string) {
		const { onChange } = this.props;

		this.setState({ input: location });
		onChange(location.length === 0 ? null : location);
	}

	mapType(type: string) {
		switch (type) {
			case 'Visit':
				return T('address.visit');
			case 'Invoice':
				return T('address.invoice');
			case 'Delivery':
				return T('address.delivery');
			case 'Other':
				return T('address.other');
			case 'Mail':
				return T('address.mail');
		}
	}

	addressFromSettings() {
		const { userAddress, userState, userZipCode } = Tools.AppService.getSelf();
		const className = new BemClass('LocationPicker');

		const locations = [userAddress, userZipCode, userState].filter(l => !!l);

		if (locations.length === 0) {
			return null;
		}
		const location = locations.join(', ');
		return (
			<div
				tabIndex={0}
				className={className.elem('location').mod('withoutType').b()}
				onClick={() => {
					this.changeLocation(location);
					this.setState({ focus: false, focusFromArrow: false });
				}}
			>
				<Text>{location}</Text>
				<Text size="sm" color="grey-11">
					{T('appointment.fromYourProfileSettings')}
				</Text>
			</div>
		);
	}

	addressFromCompanyProfile() {
		if (!this.state.companyProfileAddress) {
			return;
		}
		const { address, city, zipcode } = this.state.companyProfileAddress;
		const className = new BemClass('LocationPicker');

		const locations = [address, zipcode, city].filter(l => !!l);
		if (locations.length === 0) {
			return null;
		}
		const location = locations.join(', ');
		return (
			<div
				tabIndex={0}
				className={className.elem('location').mod('withoutType').b()}
				onClick={() => {
					this.setState({ focus: false, focusFromArrow: false });
					this.changeLocation(location);
				}}
			>
				<Text>{location}</Text>
				<Text size="sm" color="grey-11">
					{T('appointment.fromCompanyProfile')}
				</Text>
			</div>
		);
	}

	addressesFromClient() {
		const { client } = this.props;
		const className = new BemClass('LocationPicker');

		const filteredAddresses = client?.addresses?.filter?.(l => !!l.address);

		if (filteredAddresses?.length === 0) {
			return (
				<Text className={className.elem('noAddresses').b()} color="grey-10">
					{T('appointment.locationPicker.companyDoesntHaveAnyAddresses')}
				</Text>
			);
		}

		return (
			<React.Fragment>
				{Array.isArray(client?.addresses) &&
					filteredAddresses?.map((location, index) => (
						<div
							key={index}
							tabIndex={0}
							className={className.elem('location').mod('withType').b()}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								this.changeLocation(location.address ?? '');
								this.setState({ focus: false, focusFromArrow: false });
							}}
						>
							<Text>{location.address}</Text>
							<Text size="sm" color="grey-11">
								{this.mapType(location.type)}
							</Text>
						</div>
					))}
			</React.Fragment>
		);
	}

	hasClient(accountHasAddress: boolean) {
		const { client } = this.props;
		const { buttonSelect } = this.state;

		let options = [{ title: this.accountName, value: ButtonSelectValues.upsales }];

		if (client) {
			options = [{ title: client.name, value: ButtonSelectValues.client }, ...options];
		}

		if (!accountHasAddress) {
			return this.addressesFromClient();
		}

		return (
			<React.Fragment>
				<ButtonSelect
					value={buttonSelect}
					onChange={buttonSelect => this.setState({ buttonSelect })}
					options={options}
				/>
				{buttonSelect === ButtonSelectValues.upsales ? (
					<React.Fragment>
						{this.addressFromSettings()}
						{this.addressFromCompanyProfile()}
					</React.Fragment>
				) : (
					this.addressesFromClient()
				)}
			</React.Fragment>
		);
	}

	render() {
		const { client, value, disabled } = this.props;
		const { input, focus, loading, companyProfileAddress, focusFromArrow } = this.state;

		const className = new BemClass('LocationPicker');

		const accountHasAddress = !!(companyProfileAddress?.address || Tools.AppService.getSelf().userAddress);
		const clientHasAddress = !!(client?.addresses?.length ?? 0 > 0);
		const hasAddresses = clientHasAddress || accountHasAddress;

		return (
			<div
				className={className.mod({ disabled }).b()}
				onFocus={() => this.setState({ focus: true })}
				onBlur={() => this.setState({ focus: false })}
			>
				<AutoCompleteInput
					value={value?.length ?? 0 > 0 ? value : input}
					onChange={(input: string) => this.changeLocation(input)}
					placeholder={this.lang.searchAddress}
					className={className.elem('input').mod({ noAddresses: !hasAddresses }).b()}
					disabled={disabled}
				/>
				{(value?.length ?? 0 > 0) || (input?.length ?? 0 > 0) ? (
					<Icon
						className={className.elem('times').mod({ noAddresses: !hasAddresses }).b()}
						name="times"
						onClick={() => this.changeLocation('')}
					/>
				) : null}

				{hasAddresses ? (
					<React.Fragment>
						<OutsideClick
							targetRef={() => this.dropdownRef.current || null}
							outsideClick={() => this.setState({ focus: false, focusFromArrow: false })}
						>
							<div ref={this.dropdownRef}>
								<Icon
									name="chevron-down"
									onClick={
										!disabled ? () => this.setState({ focusFromArrow: !focusFromArrow }) : undefined
									}
								></Icon>
								<SlideFade speed="fast" visible={focusFromArrow || (focus && input.length === 0)}>
									<Card>
										{loading ? (
											<div className={className.elem('loader').b()}>
												<Loader size="sm" />
											</div>
										) : (
											<React.Fragment>
												{client ? (
													this.hasClient(accountHasAddress)
												) : (
													<React.Fragment>
														<Text
															className={className.elem('companyName').b()}
															color="black"
															bold
														>
															{this.accountName}
														</Text>
														{this.addressFromSettings()}
														{this.addressFromCompanyProfile()}
													</React.Fragment>
												)}
											</React.Fragment>
										)}
									</Card>
								</SlideFade>
							</div>
						</OutsideClick>
					</React.Fragment>
				) : null}
			</div>
		);
	}
}

export default LocationPicker;

import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

angular.module('upDirectives').directive('deleteContentModalBody', function () {
	return {
		scope: {
			field: '=upCustomFieldReact',
			entity: '=',
			numSelected: '='
		},
		replace: true,
		template: '<div></div>',
		restrict: 'A',
		link: function ($scope, $element) {
			let firstElement = $element[0];
			if (!firstElement) {
				return;
			}
			ReactDOM.render(
				React.createElement(AlertBody, {
					fullModalBody: true,
					entity: $scope.entity,
					numSelected: $scope.numSelected
				}),
				firstElement,
				function () {}
			);

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});
		}
	};
});

'use strict';

angular.module('upResources').factory('MasterClient', [
	'$resource',
	'$q',
	'URL',
	'API',
	'ResourceHelper',
	'AppService',
	'Self',
	function MasterClient($resource, $q, URL, API, ResourceHelper, AppService, Self) {
		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'masterClient'
		});

		var baseUrl = URL + API + 'master/client/:id';

		var Resource = $resource(
			baseUrl,
			{},
			{
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			update: function (clientData) {
				var defer = $q.defer();
				// Make sure we only try to update ourselves
				var id = AppService.getCustomerId();
				var promise = Resource.update({ id: id }, clientData).$promise;

				promise
					.then(function () {
						// Get fresh self
						return Self.get().then(function (res) {
							AppService.setAccountSelf(res.data);
							// Notify user
							helper.onSave({}, {}, false);
							defer.resolve(res.data.client);
						});
					})
					.catch(function () {
						// Notify user
						helper.onSaveError({}, false);
						defer.reject();
					});

				return defer.promise;
			}
		};

		return Model;
	}
]);

import React from 'react';
import T from 'Components/Helpers/translate';
import { Icon, Tooltip } from '@upsales/components';

// Move this higher later
type FormActionProperty = {
	name: string;
	value: string;
};
type FormAction = {
	action: string;
	id: number;
	properties: FormActionProperty[];
};
type Form = {
	id: number;
	name: string;
	internalName: string;
	user: { id: number; name: string };
	regDate: Date;
	submits: number;
	views: number;
	landingPageBody: string;
	lastFormSubmit: Date;
	userEditable: boolean;
	isArchived: boolean;
	actions: FormAction[];
};

type Props = {
	item: Form;
	tools: {
		opts: {
			archiveEntry: (form: Form) => void;
			editEntry: (form: Form) => void;
			clickedEntry: (form: Form) => void;
		};
	};
	attrs: {};
	columns: string[];
};

class FormRow extends React.Component<Props> {
	displayName = 'formRow';
	archive = () => {
		this.props.tools.opts.archiveEntry(this.props.item);
	};

	edit = () => {
		this.props.tools.opts.editEntry(this.props.item);
	};

	render() {
		const item = this.props.item;
		const tools = this.props.tools;
		const options = tools.opts;
		const attrs = this.props.attrs;
		const numberFormat = Tools.$filter('numberFormat');

		const getColumn = function (column: string, item: Form) {
			const key = 'column-' + column;

			switch (column) {
				case 'name':
					return (
						<td key={key}>
							{item.internalName || item.name || ''}
							{item.internalName ? <span className="column-subtitle">{item.name}</span> : null}
						</td>
					);

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{T('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{window.ReactTemplates.TOOLS.usersText([item.user])}</td>;
					}

				case 'regDate':
					return (
						<td key={key} className="column-regDate">
							{window.ReactTemplates.TOOLS.dateCalendar(item.regDate, 'L')}
						</td>
					);

				case 'submits':
					var submits = numberFormat(item.submits || 0);

					return (
						<td key={key} className="no-overflow">
							{submits}
						</td>
					);

				case 'views':
					var views = numberFormat(item.views || 0);

					return (
						<td key={key} className="no-overflow">
							{views}
						</td>
					);

				case 'landingPageBody':
					return (
						<td key={key} className="no-overflow">
							{T(item.landingPageBody ? 'form.landingPage' : 'form.submits')}
						</td>
					);

				case 'lastFormSubmit':
					return (
						<td key={key} className="column-regDate">
							{window.ReactTemplates.TOOLS.dateCalendar(item.lastFormSubmit, 'L')}
						</td>
					);

				default:
					return window.ReactTemplates.TOOLS.genericTD({}, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		let isCreatingPrioCalls = false;
		if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
			const createCallAction = item.actions?.find(a => a.action === 'PlanPhonecalls');
			isCreatingPrioCalls = !!createCallAction?.properties.find(p => p.name === 'Priority' && p.value === '3');
		}
		return (
			<tr
				key={'item-' + item.id}
				onClick={() => options.clickedEntry(item)}
				ref="row"
				className={item.isArchived ? 'inactive' : undefined}
			>
				{tds}
				<td className="column-tools">
					{isCreatingPrioCalls ? (
						<Tooltip title={T('form.actionCreatesPrioCalls')}>
							<Icon name="flag" color="red" space="mrl mll" />
						</Tooltip>
					) : null}
					<window.ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userEditable}
						icon="edit"
						tooltip={T('default.edit')}
						onClick={this.edit}
					/>
					<window.ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.userEditable}
						icon="archive"
						tooltip={T(item.isArchived ? 'default.unArchive' : 'default.archive')}
						onClick={this.archive}
					/>
					<window.ReactTemplates.TOOLS.rowTool item={item} icon="archive" onClick={this.archive} />
				</td>
			</tr>
		);
	}
}

export default FormRow;

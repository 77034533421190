'use strict';

angular.module('upResources').factory('GenericQuota', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericQuotaAttributes',
	'ResourceHelper',
	function Quota($resource, $q, URL, API, GenericQuotaAttributes, ResourceHelper) {
		var SMQ = GenericQuotaAttributes();
		var Attribute = SMQ.attr;
		var Keys = SMQ.keys;

		var Resource = $resource(
			URL + API + 'genericQuotas/:id',
			{ id: '@id' },
			{
				query: { method: 'GET', isArray: false },
				save: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var helper = new ResourceHelper();
		var eventPrefix = 'genericQuotas';

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: eventPrefix,
			createSuccessBody: 'saved.' + eventPrefix,
			createErrorBody: 'saveError.' + eventPrefix
		});

		var Model = {
			customer: function () {
				return Model;
			},

			new: function () {
				var masterCurrency = _.find(Tools.AppService.getMetadata().customerCurrencies, 'masterCurrency');

				return {
					user: null,
					period: new Date(),
					/*year: new Date().getUTCFullYear(),
					month: new Date().getUTCMonth(),*/
					quota: 0,
					currency: masterCurrency.iso,
					currencyRate: 1,
					type: null
				};
			},
			save: function (salesQuota, options) {
				var defered = $q.defer();

				if (salesQuota.id) {
					Resource.update({ id: salesQuota.id }, salesQuota, defered.resolve, defered.reject);

					defered.promise
						.then(function (result) {
							helper.onSave(options, result.data, true);
						})
						.catch(function () {
							helper.onSaveError(options, true);
						});
				} else {
					Resource.save(
						salesQuota,
						function (result) {
							helper.onSave(options, result.data, true);
							defered.resolve(result);
						},
						function (err) {
							helper.onSaveError(options, true);
							defered.reject(err);
						}
					);
				}

				return defered.promise;
			},
			find: function (filter, options) {
				if (filter.q === undefined) {
					filter.q = [];
				} else if (!Array.isArray(filter.q)) {
					filter.q = [filter.q];
				}
				const type = (options && options.quotaType) || 'contributionMargin';
				filter.q.push({ a: 'type', c: 'eq', v: type });
				delete options?.quotaType;
				var params = _.extend(filter, options);
				return Resource.get(params).$promise;
			},

			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';

var upFormTemplates = 'upFormTemplates';
angular
	.module('upDirectives')
	.directive(upFormTemplates + 'Ids', function (AppService, MailTemplate, selectHelper) {
		return selectHelper.getDirectiveObjectIds(upFormTemplates + 'Ids', {
			resource: MailTemplate,
			ajax: true,
			asIds: true,
			idAttr: MailTemplate.attr.id,
			titleAttr: MailTemplate.attr.name,
			addEvent: 'mailTemplate.added',
			sorting: { field: MailTemplate.attr.name, ascending: true },
			fields: function ($attrs) {
				return $attrs.hasOwnProperty('noBody') ? [] : ['body'];
			},
			filters: function (rb, $attrs) {
				var self = AppService.getSelf();

				if ($attrs.showInactive === undefined) {
					var orFilter = rb.orBuilder();

					orFilter.next();
					orFilter.addFilter(MailTemplate.attr.private, rb.comparisonTypes.Equals, false);
					orFilter.next();
					orFilter.addFilter(MailTemplate.attr.private, rb.comparisonTypes.Equals, true);
					orFilter.addFilter(MailTemplate.attr.user.attr.id, rb.comparisonTypes.Equals, self.id);
				}
			}
		});
	});

import Attribute from './Attribute';

const SocialEventPageAttributes = {
	page_id: new Attribute({
		type: Attribute.types.Number,
		field: 'pageId'
	}),

	event_id: new Attribute({
		type: Attribute.types.Number,
		field: 'eventId'
	}),
	type: new Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'type'
	})
};

window.SocialEventPageAttributes = SocialEventPageAttributes;

export default SocialEventPageAttributes;

'use strict';

angular.module('domain.mail').controller('ListMailTemplates', [
	'$translate',
	'$upModal',
	'$state',
	'$q',
	'MailTemplate',
	'MailTemplateAttributes',
	'listViews',
	'AppService',
	'FilterHelper',
	'$scope',
	'Form',
	'RequestBuilder',
	'Onboarding',
	function (
		$translate,
		$upModal,
		$state,
		$q,
		MailTemplate,
		MailTemplateAttributes,
		listViews,
		AppService,
		FilterHelper,
		$scope,
		Form,
		RequestBuilder,
		Onboarding
	) {
		var self = this;

		self.attributes = MailTemplateAttributes();
		self.disableAddFilter = false;
		self.showRemove = true;
		self.title = 'mail.templates';
		self.showAddBtn = true;
		self.listType = 'mailTemplate';
		self.listViews = listViews;
		self.columnTemplate = '/upsales/common/components/columnTemplates/listMailTemplates.html';
		self.total = 0;

		self.tableTemplate = 'mailTemplateRow';

		self.headerExtensionPath = require('App/upsales/common/components/ui/templates/headerExtensionsListView.html?file');
		self.headerListLinks = [
			// {
			// 	type: 'mail',
			// 	state: 'listMail',
			// 	text: 'tag.contact.email'
			// },
			// {
			// 	type: 'mailTemplate',
			// 	state: 'listMailTemplates',
			// 	text: 'mail.templates'
			// }
		];

		// self.secondList = 'listMail';
		// self.secondListText = 'mail.sentMails';

		self.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var clientNameFilter = props.activeFilters.ListSearch;
				return clientNameFilter && clientNameFilter.value ? clientNameFilter.value : '';
			},
			getPlaceholder: function () {
				return $translate.instant('filters.quickSearch');
			}
		};

		var currentQuery = [];
		var templates = [];
		var customerId;

		$scope.$on('mailTemplate.added', function (e, added) {
			if (FilterHelper.match(currentQuery.q, added, 'mailTemplate')) {
				self.total++;
				templates.push(added);
			}
		});

		$scope.$on('mailTemplate.updated', function (e, updated) {
			var existingIndex = _.findIndex(templates, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, updated, 'mailTemplate')) {
				templates[existingIndex] = updated;
			} else if (existingIndex !== -1) {
				self.total--;
				templates.splice(existingIndex, 1);
			}
		});

		$scope.$on('mailTemplate.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(templates, { id: deleted.id });
			if (existingIndex !== -1) {
				self.total--;
				templates.splice(existingIndex, 1);
			}
		});

		/**
		 * The add button
		 */
		self.createEntry = function () {
			return Tools.$upModal.open('createMailTemplate');
		};

		/**
		 * Remove a row
		 *
		 * @param  {object} activity The row you clicked on
		 * @return {promise}         When promise is resolved {listView} will deletet that row for you
		 */
		self.removeEntry = function (template) {
			// Find any form(active) using this template
			var formFilter = new RequestBuilder();
			var propGroup = formFilter.groupBuilder();
			propGroup.addFilter(
				Form.attr.actions.attr.properties.attr.name,
				formFilter.comparisonTypes.Equals,
				'MailTemplate'
			);
			propGroup.addFilter(
				Form.attr.actions.attr.properties.attr.value,
				formFilter.comparisonTypes.Equals,
				template.id
			);
			propGroup.done();
			formFilter.limit = 0;

			return $q
				.all({
					form: Form.find(formFilter.build())
				})
				.then(function (res) {
					var usedInForm = res.form.metadata && res.form.metadata.total;

					if (usedInForm) {
						return $upModal
							.open('warningAlert', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('default.template').toLowerCase(),
								body: 'confirm.cantDeleteTemplateForm',
								icon: 'fa-warning'
							})
							.then($q.reject);
					} else {
						return $upModal
							.open('warningConfirm', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('default.template').toLowerCase(),
								body: 'confirm.removeTemplate',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								// Action -> notify
								return MailTemplate.customer(customerId)['delete'](template);
							});
					}
				});
		};

		/**
		 * Clicks on a row
		 *
		 * @param  {object} template The row you just clicked
		 * @param  {array} entries   all the visible entries
		 */
		self.clickedEntry = function (template) {
			if (!template.userEditable) {
				return;
			}
			Tools.routerHistory.push('/mail-template-editor/' + template.id);
		};

		// The getter function {listView} will need
		self.getEntries = function (query) {
			currentQuery = query;
			if (!query.q) {
				query.q = [];
			}
			query.q.push({ a: MailTemplate.attr.socialEvent.field, c: 'eq', v: null });
			return MailTemplate.customer(customerId)
				.find(query)
				.then(function (res) {
					templates = res.data;
					self.total = res.metadata ? res.metadata.total : res.length;
					return res;
				});
		};

		var init = function () {
			var metadata = AppService.getMetadata();
			customerId = AppService.getCustomerId();

			self.addBtnLabel =
				$translate.instant('default.create') + ' ' + $translate.instant('mail.template').toLowerCase();

			if (metadata.map.mailActivated) {
				self.addButton = {
					getLabel: function () {
						return $translate.instant('default.create') + ' ' + $translate.instant('default.template');
					},
					createEntry: function () {
						self.createEntry();
						Onboarding.setListIntro('mailTemplate');
					}
				};
			}

			self.listOpts = {
				type: 'mailTemplate',
				hasCheck: false,
				editable: true,
				placeholderKey: 'mailTemplate',
				featureInactive: !metadata.map.mailActivated,
				clickedEntry: function (template) {
					if (!template.userEditable) {
						return;
					}
					Tools.routerHistory.push('/mail-template-editor/' + template.id);
				},
				removeEntry: function (template) {
					// Find any form(active) using this template
					var formFilter = new RequestBuilder();
					var propGroup = formFilter.groupBuilder();
					propGroup.addFilter(
						Form.attr.actions.attr.properties.attr.name,
						formFilter.comparisonTypes.Equals,
						'MailTemplate'
					);
					propGroup.addFilter(
						Form.attr.actions.attr.properties.attr.value,
						formFilter.comparisonTypes.Equals,
						template.id
					);
					propGroup.done();
					formFilter.limit = 5;
					formFilter.fields = [Form.attr.id.field, Form.attr.name.field];

					return $q
						.all({
							form: Form.find(formFilter.build())
						})
						.then(function (res) {
							var usedInForm = res.form.metadata && res.form.metadata.total;

							var body = '';
							var and = $translate.instant('default.and').toLowerCase();
							var more = $translate.instant('default.more2').toLowerCase();
							var totalForms = res.form.data.length;

							if (totalForms) {
								body += '<ul>';
							}

							_.forEach(res.form.data, function (form) {
								var href = `#/form-overview/${form.id}`;
								body += '<li><a href="' + href + '">' + form.name + '</a></li>';
							});

							if (totalForms) {
								body += '</ul>';
							}

							if (totalForms > 10) {
								body += '<span>' + and + totalForms - 10 + more + '...</span>';
							}

							if (usedInForm) {
								body = $translate.instant('confirm.cantDeleteTemplateForm') + '<br/>' + body;

								return $upModal
									.open('warningAlert', {
										title:
											$translate.instant('default.remove') +
											' ' +
											$translate.instant('default.template').toLowerCase(),
										body: body,
										icon: 'fa-warning'
									})
									.then($q.reject);
							} else {
								return MailTemplate.customer(customerId)['delete'](template);
							}
						});
				}
			};
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

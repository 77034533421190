'use strict';

angular.module('upResources').factory('LeaderPage', [
	'$resource',
	'$q',
	'LeaderPageAttributes',
	'URL',
	'API',
	'ResourceHelper',
	function LeaderPage($resource, $q, LeaderPageAttributes, URL, API, ResourceHelper) {
		var AA = LeaderPageAttributes();
		var Attribute = AA.attr;
		var Keys = AA.keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'leaderPage',
			createSuccessBody: 'saved.page',
			updateSuccessBody: 'updated.page',
			deleteSuccessBody: 'deleted.page',
			createErrorBody: 'saveError.page',
			updateErrorBody: 'saveError.page',
			deleteErrorBody: 'deleteError.page'
		});

		var Resource = $resource(
			URL + API + 'pages/:id',
			{},
			{
				query: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options);

				return Resource.get(params).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (page, options) {
				return Resource.update({ id: page.id }, { page: page })
					.$promise.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !page.id);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !page.id);
					});
			},

			delete: function (page, options) {
				return Resource.delete({ id: page.id })
					.$promise.then(function (res) {
						// Notify user
						helper.onDelete(options, page);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

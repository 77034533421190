import React from 'react';
import { CardHeader, Table, TableHeader, TableRow, Card, TableColumn, Button } from '@upsales/components';
import Icon from '@upsales/components/Icon';
import T from '../Helpers/translate';
import './DashboardTable.scss';

const DashboardTable = ({ icon, title, dashboards, setFavorite, goToDashboard }) => {
	if (dashboards.length === 0) {
		// Pure React components cannot return null, noscript is the second best as it's not rendered in browsers that support React
		return <noscript />;
	}

	return (
		<Card className="DashboardTable">
			<CardHeader icon={icon} title={title} />
			<Table>
				<TableHeader
					columns={[
						{ title: Tools.$translate('default.name') },
						{ title: Tools.$translate('column.description') },
						{ title: '' }
					]}
				/>
				{dashboards.map(({ id, title, isFavorite, description, useNext }) => (
					<TableRow key={id} clickable onClick={() => goToDashboard(id, useNext)}>
						<TableColumn style={{ width: '40%' }}>{title || ''}</TableColumn>
						<TableColumn>{T(description) || ''}</TableColumn>
						<TableColumn align="right">
							{isFavorite ? (
								<Button
									onClick={e => {
										e.stopPropagation();
										setFavorite(id, !isFavorite);
									}}
									type="link"
									color="red"
								>
									<Icon name="heart" />
								</Button>
							) : (
								<Button
									onClick={e => {
										e.stopPropagation();
										setFavorite(id, !isFavorite);
									}}
									type="link"
									color="light-grey"
									hoverColor="red"
								>
									<Icon name="heart" />
								</Button>
							)}
						</TableColumn>
					</TableRow>
				))}
			</Table>
		</Card>
	);
};

export default DashboardTable;

'use strict';

import { Button, Checkbox, Icon, Tooltip } from '@upsales/components';
import _, { isEqual, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import SendBeam from 'Resources/SendBeam';
import globalTracker from 'App/babel/helpers/Tracker/Global';
import FormRow from 'App/upsales/common/components/react/listForms';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import TableHead from 'App/babel/components/TableHead';

window.ReactTemplates = {
	TOOLS: {},
	ROW: {
		formRow: FormRow
	},
	ROWSTATICS: {},
	OTHER: {},
	INPUTS: {},
	MODALS: {},
	bannerEditor: {},
	navbar: {
		searchRows: {}
	}
};
var ReactTemplates = window.ReactTemplates;

window.loadTooltip(); // Loads tooltip from es6 library

ReactTemplates.TOOLS.stopProp = function (e) {
	if (e.stopPropagation) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	}
};

ReactTemplates.TOOLS.upTel = function (value) {
	var metadata = Tools.AppService.getMetadata();
	var telType = metadata.params.callToType;
	var telTarget = metadata.params.callToTarget;

	if (!value) {
		return {};
	}

	var number = value.replace(/ /g, '');
	var href = telType + number;

	return {
		href: href,
		target: telTarget
	};
};

ReactTemplates.TOOLS.upVoice = function (object, callType, value, related) {
	var phoneIntegration = Tools.AppService.getPhoneIntegration();
	var config = { href: null, target: null, onClick: null };
	const hasCallFromWeb = Tools.AppService.getSelf().userParams.callFromWeb;
	const { name } = object || {};

	if (!value) {
		return null;
	}

	var number = value.replace(/ /g, '');

	if (phoneIntegration) {
		config.onClick = function (e) {
			ReactTemplates.TOOLS.stopProp(e);

			if (!_.get(phoneIntegration, 'capabilities.useCallToLinks')) {
				e.preventDefault();
			}

			switch (callType) {
				case 'contact':
					Tools.VoiceService.callContact(object, number, related);
					break;
				case 'client':
					Tools.VoiceService.callClient(object, number, related);
					break;
			}
		};
	} else if (hasCallFromWeb) {
		config.onClick = async function (e) {
			e.stopPropagation();
			e.preventDefault();

			try {
				await SendBeam.makePhoneCall(number, name);

				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'mobile',
					title: 'click_to_call.callSent',
					body: 'click_to_call.tapNotification'
				});
			} catch (err) {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'default.somethingWrong'
				});
			}

			globalTracker.track('Send call to app: Clicked link');
		};
	} else {
		config.onClick = function (e) {
			e.stopPropagation();
		};
	}

	var metadata = Tools.AppService.getMetadata();
	var telType;
	if (_.get(phoneIntegration, 'capabilities.useCallToLinks')) {
		telType = _.get(phoneIntegration, 'capabilities.useCallToLinks');
	} else {
		telType = metadata.params.callToType;
	}

	var telTarget = metadata.params.callToTarget;
	var telPrefix = metadata.params.telPrefix;

	var prefixedNumber = '';

	for (var i = 0; i < number.length; i++) {
		if (i === 0 && number[i] === '0' && telPrefix && telPrefix.length) {
			prefixedNumber += telPrefix;
		} else if (i === 0 && number[i] === '+') {
			prefixedNumber += number[i];
		} else if (/[0-9]/.test(number[i])) {
			prefixedNumber += number[i];
		}
	}

	var href = telType + prefixedNumber;

	config.href = href;
	config.target = telTarget;

	return config;
};

ReactTemplates.TOOLS.emptyTD = function (text, key) {
	return <td key={key}>{text || ''}</td>;
};

ReactTemplates.TOOLS.dateCalendar = function (date, format, calendarTimeToday) {
	return dateCalendar(date, calendarTimeToday, format);
};

ReactTemplates.TOOLS.accountHref = window.ReactCreateClass({
	displayName: 'accountHref',
	getInitialState: function () {
		var customerId = Tools.AppService.getCustomerId();

		return {
			customerId: customerId,
			activityId: this.props.activityId ? '?activityId=' + this.props.activityId : '',
			id: this.props.id,
			text: this.props.text,
			isExternal: this.props.isExternal,
			sub: this.props.sub ? this.props.sub + '/' : ''
		};
	},
	shouldComponentUpdate: function (newProps) {
		return this.state.text !== newProps.text;
	},
	UNSAFE_componentWillUpdate: function (newProps) {
		var self = this;
		// Set the state text if it was changed
		if (self.state.text !== newProps.text) {
			self.setState({ text: newProps.text });
		}
	},
	render: function () {
		var text = _.trim(this.state.text);
		if ((!text.length || text === ' ') && !this.props.sub) {
			text = Tools.$translate('default.noName');
		}

		if (this.state.isExternal) {
			return <span>{text}</span>;
		}
		return (
			<a
				href={
					'#/' +
					this.state.customerId +
					'/accounts/' +
					this.state.id +
					'/' +
					this.state.activityId +
					this.state.sub
				}
				onClick={ReactTemplates.TOOLS.stopProp}
			>
				{text}
			</a>
		);
	}
});

const contactHref = props => {
	let text = _.trim(props.text);
	if ((!text.length || text === ' ') && !props.sub) {
		text = <i>{Tools.$translate('default.noName')}</i>;
	}
	const sub = props.sub ? props.sub + '/' : '';

	return (
		<a
			href={`#/${props.customerId}/contacts/${props.id}/${sub}`}
			onClick={e => {
				ReactTemplates.TOOLS.stopProp(e);
				if (props.onClick && typeof props.onClick === 'function') {
					props.onClick(e);
				}
			}}
		>
			{text}
		</a>
	);
};

ReactTemplates.TOOLS.contactHref = contactHref;

ReactTemplates.TOOLS.campaignHref = window.ReactCreateClass({
	displayName: 'campaignHref',
	getInitialState: function () {
		return {
			customerId: this.props.customerId,
			id: this.props.id,
			text: this.props.text,
			sub: this.props.sub ? this.props.sub + '/' : ''
		};
	},
	shouldComponentUpdate: function (newProps) {
		return this.state.text !== newProps.text;
	},
	UNSAFE_componentWillUpdate: function (newProps) {
		var self = this;
		// Set the state text if it was changed
		if (self.state.text !== newProps.text) {
			self.setState({ text: newProps.text });
		}
	},
	render: function () {
		var text = _.trim(this.state.text);
		if ((!text.length || text === ' ') && !this.props.sub) {
			text = Tools.$translate('default.noName');
		}

		if (this.state.isExternal) {
			return React.createElement('span', null, text);
		}
		return React.createElement(
			'a',
			{
				href: '#/' + this.state.customerId + '/campaigns/' + this.state.id + '/' + this.state.sub,
				onClick: ReactTemplates.TOOLS.stopProp
			},
			text
		);
	}
});

ReactTemplates.TOOLS.templateHref = window.ReactCreateClass({
	displayName: 'templateHref',
	getInitialState: function () {
		return {
			customerId: this.props.customerId,
			id: this.props.id,
			text: this.props.text,
			sub: this.props.sub ? this.props.sub : 'design'
		};
	},
	shouldComponentUpdate: function (newProps) {
		return this.state.text !== newProps.text;
	},
	UNSAFE_componentWillUpdate: function (newProps) {
		var self = this;
		// Set the state text if it was changed
		if (self.state.text !== newProps.text) {
			self.setState({ text: newProps.text });
		}
	},
	render: function () {
		var text = _.trim(this.state.text);
		if ((!text.length || text === ' ') && !this.props.sub) {
			text = Tools.$translate('default.noName');
		}

		if (this.state.isExternal) {
			return React.createElement('span', null, text);
		}
		return React.createElement(
			'a',
			{
				href: '#/mail-template-editor/' + this.state.id + '/' + this.state.sub,
				onClick: ReactTemplates.TOOLS.stopProp
			},
			text
		);
	}
});

ReactTemplates.TOOLS.segmentHref = window.ReactCreateClass({
	displayName: 'campaignHref',
	getInitialState: function () {
		return {
			customerId: this.props.customerId,
			id: this.props.id,
			text: this.props.text,
			sub: this.props.sub ? this.props.sub + '/' : ''
		};
	},
	shouldComponentUpdate: function (newProps) {
		return this.state.text !== newProps.text;
	},
	UNSAFE_componentWillUpdate: function (newProps) {
		var self = this;
		// Set the state text if it was changed
		if (self.state.text !== newProps.text) {
			self.setState({ text: newProps.text });
		}
	},
	render: function () {
		var text = _.trim(this.state.text);
		if ((!text.length || text === ' ') && !this.props.sub) {
			text = Tools.$translate('default.noName');
		}
		return React.createElement(
			'a',
			{
				href: '#/' + this.state.customerId + '/segments/' + this.state.id + '/' + this.state.sub,
				onClick: ReactTemplates.TOOLS.stopProp
			},
			text
		);
	}
});

ReactTemplates.TOOLS.mailTo = function (customerId, contact, style) {
	// if user can use mail-function
	var click = function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var metadataMap = Tools.AppService.getMetadata().map;
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FLASH) && metadataMap && metadataMap.mail) {
			e.preventDefault();

			if (contact.email) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openNewMailWithContact(contact);
				} else {
					Tools.$upModal.open('sendEmail', {
						customerId: customerId,
						email: contact.email,
						contactId: contact.id
					});
				}
			}
		}
	};

	return (
		<a style={style} href={'mailto:' + contact.email} onClick={click.bind(this)} target="_top">
			{contact.email}
		</a>
	);
};

ReactTemplates.TOOLS.rowTool = window.ReactCreateClass({
	displayName: 'rowTool',
	getDefaultProps: function () {
		return {
			onClick: function () {},
			className: '',
			icon: ''
		};
	},
	shouldComponentUpdate: function (nextProps) {
		return !isEqual(this.props, nextProps);
	},
	click: function (e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.onClick(this.props.item, e);
	},
	render: function () {
		const btnClass = this.props.className;
		var btn = (
			<Button type="link" className={'row-tool ' + btnClass} onClick={this.click}>
				{this.props.icon ? <Icon name={this.props.icon} /> : null}
				{this.props.children ? this.props.children : null}
			</Button>
		);

		if (this.props.loading) {
			return (
				<span className="row-tool-loading">
					<b className="fa fa-spinner fa-spin" />
				</span>
			);
		}

		return this.props.show
			? this.props.tooltip
				? ReactTemplates.TOOLS.withTooltip(btn, this.props.tooltip, { placement: 'left' })
				: btn
			: null;
	}
});

ReactTemplates.TOOLS.leadStatus = window.ReactCreateClass({
	displayName: 'leadStatus',
	getTimeStr: function (date) {
		var momentDate = moment(new Date(date));
		if (momentDate.isValid()) {
			// If date is in future we display date calendar
			var today = new Date();
			if (momentDate.isSame(today, 'day') || momentDate.isAfter(today)) {
				var d = momentDate.calendar();

				// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
				if (d !== '0') {
					return d;
				}
				return momentDate.format('L');
			} else {
				// var isIE = window.Tools.browserService.isIE();
				// // check for IE since moment obj becomes weird in IE..
				// return isIE ? moment(momentDate.format('L')).fromNow() : momentDate.fromNow(); // else we display ex. "4 years ago"
			}
		} else {
			return date;
		}
	},
	render: function () {
		var $translate = window.Tools.$translate;
		const isContact = this.props.isContact;
		var activityClass = '';
		const entity =
			window.Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
			window.Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
				? 'Task'
				: 'Activity';
		var activityTooltip = isContact ? `search.contactHasNo${entity}` : `search.accountHasNo${entity}`;
		var activityDate = null;
		var opportunityClass = '';
		var opportunityTooltip = isContact ? 'search.contactHasNoOpportunity' : 'search.accountHasNoOpportunity';
		var opportunityDate = null;
		var orderClass = '';
		var orderTooltip = isContact ? 'search.contactHasNoOrder' : 'search.accountHasNoOrder';
		var orderDate = null;
		var classStyle = 'indicator-wrapper indicator-' + (this.props.size || 'sm');

		if (this.props.activity) {
			activityClass = 'indicator-active';
			activityTooltip = isContact ? `search.contactHas${entity}` : `search.accountHas${entity}`;
			activityDate = this.getTimeStr(this.props.activity);
		} else if (this.props.activityOld) {
			activityClass = 'indicator-inverted';
			activityTooltip = isContact ? `search.contactHad${entity}` : `search.accountHad${entity}`;
			activityDate = this.getTimeStr(this.props.activityOld);
		}

		if (this.props.appointment) {
			activityClass = 'indicator-active';
			activityTooltip = isContact ? 'search.contactHasAppointment' : 'search.accountHasAppointment';
			activityDate = this.getTimeStr(this.props.appointment);
		} else if (this.props.appointmentOld && !this.props.activity) {
			activityClass = 'indicator-inverted';
			activityTooltip = isContact ? 'search.contactHadAppointment' : 'search.accountHadAppointment';
			activityDate = this.getTimeStr(this.props.appointmentOld);
		}

		if (this.props.opportunity) {
			opportunityClass = 'indicator-active';
			opportunityTooltip = isContact ? 'search.contactHasOpportunity' : 'search.accountHasOpportunity';
			opportunityDate = this.getTimeStr(this.props.opportunity);
		} else if (this.props.opportunityOld) {
			opportunityClass = 'indicator-inverted';
			opportunityTooltip = isContact ? 'search.contactHadOpportunity' : 'search.accountHadOpportunity';
			opportunityDate = this.getTimeStr(this.props.opportunityOld);
		}

		if (this.props.order) {
			orderClass = 'indicator-active';
			orderTooltip = isContact ? 'search.contactHasOrder' : 'search.accountHasOrder';
			orderDate = this.getTimeStr(this.props.order);
		} else if (this.props.orderOld) {
			orderClass = 'indicator-inverted';
			orderTooltip = isContact ? 'search.contactHadOrder' : 'search.accountHadOrder';
			orderDate = this.getTimeStr(this.props.orderOld);
		}

		return (
			<div key={'leadStatus-' + this.props.theKey} className={classStyle} onClick={ReactTemplates.TOOLS.stopProp}>
				{ReactTemplates.TOOLS.withTooltip(
					<div className={'first ' + activityClass}>
						<i className="fa fa-check" />
					</div>,
					$translate(activityTooltip) + (activityDate ? ', ' + activityDate : ''),
					{
						placement: this.props.tooltipPlacement || 'top',
						key: 'activity_' + this.props.theKey,
						style: { display: 'inline-block' }
					}
				)}

				{ReactTemplates.TOOLS.withTooltip(
					<div className={'second ' + opportunityClass}>
						<i className="up-icon-pipeline" />
					</div>,
					$translate(opportunityTooltip) + (opportunityDate ? ', ' + opportunityDate : ''),
					{
						placement: this.props.tooltipPlacement || 'top',
						key: 'opportunity_' + this.props.theKey,
						style: { display: 'inline-block' }
					}
				)}

				{ReactTemplates.TOOLS.withTooltip(
					<div className={'third ' + orderClass}>
						<i className="fa fa-usd" />
					</div>,
					$translate(orderTooltip) + (orderDate ? ', ' + orderDate : ''),
					{
						placement: this.props.tooltipPlacement || 'top',
						key: 'order_' + this.props.theKey,
						style: { display: 'inline-block' }
					}
				)}
			</div>
		);
	}
});

ReactTemplates.TOOLS.checkboxTD = window.ReactCreateClass({
	displayName: 'checkbox-td',
	getDefaultProps: function () {
		return {
			idField: 'id'
		};
	},
	click: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		if (!this.isDisabled()) {
			this.props.tools.$multiSelect.toggle(_.get(this.props.item, this.props.idField));
		}
	},
	isChecked: function () {
		return this.props.tools.$multiSelect.isChecked(_.get(this.props.item, this.props.idField));
	},
	isDisabled: function () {
		return this.props.tools.$multiSelect.allSelectedFilter;
	},
	render: function () {
		return (
			<td key="checkbox-td" className="column-checkbox" onClick={this.click}>
				<Checkbox
					disabled={this.isDisabled()}
					checked={this.isChecked() || this.isDisabled()}
					onClick={this.click}
					size="xs"
				/>
			</td>
		);
	}
});

ReactTemplates.TOOLS.genericTD = function (props, attrs, col, item, key) {
	key = key || 'blank';
	var m;
	if (Tools.FilterHelper.isCustom(col)) {
		var cf = Tools.FilterHelper.getCustomConfig(col, props.customFields);
		if (
			!item.$mappedCustom ||
			item.$mappedCustom[cf.field] === null ||
			item.$mappedCustom[cf.field] === undefined ||
			item.$mappedCustom[cf.field] === ''
		) {
			return ReactTemplates.TOOLS.emptyTD('', key);
		}

		if (cf.$field.datatype === 'Boolean') {
			return (
				<td key={key}>
					{item.$mappedCustom[cf.field].value
						? Tools.$translate('default.yes')
						: Tools.$translate('default.no')}
				</td>
			);
		} else if (cf.$field.datatype === 'Date') {
			if (!item.$mappedCustom[cf.field].value) {
				return ReactTemplates.TOOLS.emptyTD('', key);
			}
			m = moment(item.$mappedCustom[cf.field].value);
			if (!m.isValid() || !item.$mappedCustom[cf.field].value) {
				return ReactTemplates.TOOLS.emptyTD('', key);
			}
			return <td key={key}>{m.format('L')}</td>;
		} else if (cf.$field.datatype === 'Link') {
			return (
				<td key={key}>
					<a
						target="_blank"
						href={item.$mappedCustom[cf.field].value}
						onClick={ReactTemplates.TOOLS.stopProp}
					>
						{item.$mappedCustom[cf.field].value}
					</a>
				</td>
			);
		} else if (_.includes(['User', 'Users'], cf.$field.datatype)) {
			var users = item.$mappedCustom[cf.field].value;
			if (!users) {
				return <td key={key}>{item.$mappedCustom[cf.field].value}</td>;
			}
			var customFieldValuesArray = Tools.ActionProperties.parseCustomFieldUserValues(users);

			return <td key={key}>{customFieldValuesArray}</td>;
		} else {
			return <td key={key}>{item.$mappedCustom[cf.field].value}</td>;
		}
	} else {
		if (!attrs[col]) {
			return null;
		}
		if (attrs[col].standardField) {
			var selectOptions = Tools.AppService.getStaticValues(attrs[col].standardField);
			var selectedOption = _.find(selectOptions, { id: item[attrs[col].field] });
			if (selectedOption) {
				return <td key={key}>{selectedOption.name}</td>;
			}
		}

		if (attrs[col].type === 'Date') {
			if (!item[col]) {
				return ReactTemplates.TOOLS.emptyTD('', key);
			}
			m = moment(item[col]);
			if (!m.isValid()) {
				return ReactTemplates.TOOLS.emptyTD('', key);
			}

			return <td key={key}>{moment(item[col]).format('L')}</td>;
		}

		var value = item[col];
		if (value && attrs[col].displayAttr) {
			value = value[attrs[col].displayAttr];
		} else if (!value && attrs[col].field) {
			value = item[attrs[col].field];
		}

		if (attrs[col].displayType === Tools.DisplayType.Currency && (value || value === 0)) {
			value = Tools.$filter('currencyFormat')(value, item.currency || 'SEK');
			return <td key={key}>{value}</td>;
		}

		if (isObject(value)) {
			return ReactTemplates.TOOLS.emptyTD('', key);
		}

		return <td key={key}>{value}</td>;
	}
};

ReactTemplates.TOOLS.usersText = function (users) {
	if (users.length > 1) {
		var str = _.pluck(users.slice(1), 'name').join('\n');

		return (
			<span>
				{users[0].name} {Tools.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{users.length - 1} {Tools.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else {
		return users[0] ? users[0].name : Tools.$translate('default.noUser');
	}
};

ReactTemplates.TOOLS.productsText = function (orderRows, props) {
	var products = _.unique(_.pluck(_.pluck(orderRows, 'product'), 'name'));

	if (products.length > 1) {
		var str = products.slice(1).join('\n');

		return (
			<span>
				{products[0]} {props.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{products.length - 1} {props.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else {
		return products[0];
	}
};

ReactTemplates.TOOLS.campaignsText = function (campaigns, props, customerId) {
	if (campaigns.length > 1) {
		var str = _.pluck(campaigns.slice(1), 'name').join('\n');

		return (
			<span>
				{campaigns[0].name} {props.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{campaigns.length - 1} {props.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else if (customerId) {
		// If customerId is defined and there are only one campaign --> make it a link
		return (
			<a href={'#/' + customerId + '/campaigns/' + campaigns[0].id + '/'} onClick={ReactTemplates.TOOLS.stopProp}>
				{campaigns[0].name}
			</a>
		);
	} else {
		return campaigns[0].name;
	}
};

ReactTemplates.TOOLS.categoriesText = function (categories, props) {
	if (categories.length > 1) {
		var str = _.pluck(categories.slice(1), 'name').join('\n');

		return (
			<span>
				{categories[0].name} {props.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{categories.length - 1} {props.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else {
		return categories[0].name;
	}
};

ReactTemplates.TOOLS.segmentText = function (segments, props) {
	if (segments.length > 1) {
		var str = _.pluck(segments.slice(1), 'name').join('\n');

		return (
			<span>
				{segments[0].name} {props.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{segments.length - 1} {props.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else {
		return segments[0].name;
	}
};

ReactTemplates.TOOLS.contactsText = function (customerId, contacts, props, sub) {
	if (!props) {
		props = Tools;
	}

	if (!contacts.length) {
		return ReactTemplates.TOOLS.emptyTD();
	}

	var standardFields = Tools.AppService.getMetadata().standardFields.Contact;
	var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
	var showSalutations = hasNewFields && standardFields.Salutation && standardFields.Salutation.active;

	sub = sub ? sub + '/' : '';
	var firstContact = contacts[0];

	var name =
		showSalutations && firstContact.salutation
			? firstContact.salutation.value + ' ' + firstContact.name
			: firstContact.name;
	var link = (
		<a
			href={'#/' + customerId + '/contacts/' + firstContact.id + '/' + sub}
			onClick={ReactTemplates.TOOLS.stopProp}
		>
			{name}
		</a>
	);

	if (contacts.length > 1) {
		var str = _.pluck(contacts.slice(1), 'name').join('\n');

		return (
			<span>
				{link}
				{' ' + props.$translate('default.and').toLowerCase() + ' '}
				<Tooltip placement="left" title={str}>
					<b onClick={ReactTemplates.TOOLS.stopProp}>
						{contacts.length - 1} {props.$translate('filters.more').toLowerCase()}
					</b>
				</Tooltip>
			</span>
		);
	} else {
		return link;
	}
};

ReactTemplates.TOOLS.tbody = function (rows) {
	return <tbody key="main-tbody">{rows}</tbody>;
};

ReactTemplates.TOOLS.thead = TableHead;

var sortIconMap = {
	number: {
		asc: 'Icon-sort-numeric-asc',
		desc: 'Icon-sort-numeric-desc'
	},
	date: {
		asc: 'Icon-sort-numeric-asc',
		desc: 'Icon-sort-numeric-desc'
	},
	string: {
		asc: 'Icon-sort-alpha-asc',
		desc: 'Icon-sort-alpha-desc'
	},
	none: {
		asc: 'Icon-sort-amount-asc',
		desc: 'Icon-sort-amount-desc'
	}
};

ReactTemplates.TOOLS.tableTH = window.ReactCreateClass({
	displayName: 'TableTH',
	classes: {
		none: 'Icon-sort'
	},
	generateState: function () {
		var isSorted = this.props.currentSorting && this.props.currentSorting.attribute === this.props.column.sortable;
		var datatype = (this.props.column.displayType || this.props.column.type || '').toLowerCase();
		var className = this.classes.none;
		if (isSorted) {
			var sortType = sortIconMap[datatype] || sortIconMap.none;
			className = sortType[this.props.currentSorting.ascending ? 'asc' : 'desc'];
		}
		return {
			isSorted: isSorted,
			asc: this.props.currentSorting ? this.props.currentSorting.ascending : false,
			class: className
		};
	},
	getInitialState: function () {
		return this.generateState();
	},
	resizeTimeout: null,
	resizeThrottler: function () {
		var self = this;

		if (!self.resizeTimeout) {
			self.resizeTimeout = setTimeout(function () {
				self.resizeTimeout = null;
				self.setWidth();
			}, 66);
		}
	},
	componentDidMount: function () {
		this.setClasses();

		var tableElement = this.refs['th'].parentNode.parentNode.parentNode;

		if (tableElement.classList.contains('fixed')) {
			window.addEventListener('resize', this.resizeThrottler);
			this.setWidth();
		}
		this.setWidth();
	},
	componentWillUnmount: function () {
		var tableElement = this.refs['th'].parentNode.parentNode.parentNode;
		if (tableElement.classList.contains('fixed')) {
			window.removeEventListener('resize', this.resizeThrottler);
		}
	},
	componentDidUpdate: function () {
		this.state = this.generateState();

		this.setClasses();

		var tableElement = this.refs['th'].parentNode.parentNode.parentNode;
		if (tableElement.classList.contains('fixed')) {
			this.setWidth();
		}
		this.setWidth();
	},
	click: function () {
		var column = this.props.column;
		if (column.sortable) {
			var sortOrder;
			if (this.state.isSorted) {
				sortOrder = !this.state.asc;
			} else {
				// If ASC is true or false
				sortOrder = column.type === 'String' ? true : false;
			}
			this.props.sort(this.props.column.sortable, sortOrder);
		}
	},
	setWidth: function () {
		var tools = this.props.tools;
		var column = this.props.column;
		var icon = tools.FilterHelper.useIcon(column.title);

		if (this.props.tools.opts.type === 'order') {
			var self = this;
			setTimeout(function () {
				var th = $(ReactDOM.findDOMNode(self.refs['th']));
				var colElem = $(ReactDOM.findDOMNode(self.refs['column']));

				if (icon) {
					th.css('min-width', Math.max(colElem.outerWidth() + 15, 50));
				} else {
					th.css('min-width', Math.max(colElem.outerWidth() + 15, column.minWidth || 100));
				}
			}, 0);
		} else {
			var th = $(ReactDOM.findDOMNode(this.refs['th']));
			var colElem = $(ReactDOM.findDOMNode(this.refs['column']));

			if (icon) {
				th.css('min-width', Math.max(colElem.outerWidth() + 15, 50));
			} else {
				th.css('min-width', Math.max(colElem.outerWidth() + 15, column.minWidth || 100));
			}
		}
	},
	setClasses: function () {
		var colElem = $(ReactDOM.findDOMNode(this.refs['column']));
		var indicatorElem = $(ReactDOM.findDOMNode(this.refs['indicator']));

		if (this.state.isSorted) {
			colElem.addClass('sorted');
		} else {
			colElem.removeClass('sorted');
		}

		indicatorElem.removeClass(
			Object.values(sortIconMap)
				.reduce(function (out, v) {
					return out.concat(Object.values(v));
				}, [])
				.join(' ')
		);
		indicatorElem.addClass(this.state.class);
	},
	render: function () {
		var tools = this.props.tools;
		var column = this.props.column;
		var indicator = '';
		var classNames = 'th-inner';

		let title = typeof column.displayText === 'function' ? column.displayText() : column.title;
		title = tools.$translate(title);
		var icon = tools.FilterHelper.useIcon(column.title);

		if (title && title.length > 20) {
			title = title.substr(0, 20) + '...';
		}

		var thStyle = {};

		if (icon) {
			title = ReactTemplates.TOOLS.withTooltip(<span className={icon.icon} />, tools.$translate(icon.tooltip), {
				placement: 'top'
			});

			thStyle.minWidth = '50px';
		}

		if (column.sortable) {
			var style = { paddingLeft: '5px' };
			classNames += ' sortable-header';
			indicator = <span ref="indicator" className="Icon sorting-indicator" style={style} />;
		}

		var tdClass = 'table-column sortable column-' + column.field;

		var elevioId = null;
		if (column.elevioId) {
			elevioId = (
				<span>
					{' '}
					<ReactTemplates.elevio articleId={column.elevioId} />
				</span>
			);
		} else {
			elevioId = '';
		}

		return (
			<th ref="th" key={'column-' + column.title} className={tdClass} style={thStyle}>
				<div ref="column" className={classNames} onClick={this.click}>
					{title}
					{elevioId}
					{indicator}
				</div>
			</th>
		);
	}
});

ReactTemplates.table = window.ReactCreateClass({
	displayName: 'table',
	getInitialState: function () {
		return {};
	},
	getSort: function (val, type) {
		if (val === null || val === undefined) {
			return null;
		}

		if (type === 'Date' && val.getTime) {
			return val.getTime();
		}

		if (type === 'String' && val.toLowerCase) {
			return val.toLowerCase();
		}

		if ((type === 'Currency' || type === 'Percent' || type === 'Discount') && !isNaN(parseFloat(val))) {
			return parseFloat(val);
		}

		if ((type === 'Number' || type === 'Integer') && !isNaN(parseInt(val))) {
			return parseInt(val);
		}

		return val;
	},
	sortFn: function (item, col) {
		var FilterHelper = this.props.tools.FilterHelper;
		var $parse = this.props.tools.$parse;
		var customFields = this.props.tools.customFields;

		if (FilterHelper.isCustom(col)) {
			var cf = FilterHelper.getCustomConfig(col, customFields);
			if (
				!item.$mappedCustom ||
				item.$mappedCustom[cf.field] === null ||
				item.$mappedCustom[cf.field] === undefined
			) {
				return null;
			}

			return this.getSort(item.$mappedCustom[cf.field].value, cf.$field.datatype);
		} else {
			var a = $parse(col)(item);

			return this.getSort(a, this.props.attrs[col].type);
		}
	},
	// eslint-disable-next-line no-unused-vars
	doSort: function (rows) {
		// eslint-disable-line no-unused-vars
		var self = this;

		if (!self.props.currentSorting) {
			return;
		}

		var col = self.props.currentSorting.attribute;

		if (self.props.attrs[col] || self.props.tools.FilterHelper.isCustom(col)) {
			var ascending = self.props.currentSorting.ascending;
			// eslint-disable-next-line no-unused-vars
			rows = rows.sort(function (A, B) {
				var itemA = A.props.item;
				var itemB = B.props.item;

				var aValue = self.sortFn(itemA, col),
					bValue = self.sortFn(itemB, col);

				if (aValue === null) {
					return 1;
				}
				if (bValue === null) {
					return -1;
				}

				if (aValue === bValue) {
					bValue = itemA.id;
					aValue = itemB.id;
				}
				if (ascending) {
					if (typeof aValue === 'string') {
						return aValue.localeCompare(bValue, 'sv');
					}
					return aValue > bValue ? 1 : -1;
				}
				if (typeof bValue === 'string') {
					return bValue.localeCompare(aValue, 'sv');
				}
				return aValue > bValue ? -1 : 1;
			});
		}
	},
	render: function () {
		var statics = ReactTemplates.ROWSTATICS[this.props.template]
			? ReactTemplates.ROWSTATICS[this.props.template](this.props.tools)
			: {};

		var rows;

		if (this.props.useReactSupportRows) {
			if (this.props.loading) {
				rows = (
					<tr className="load-row">
						<td colSpan={'100'}>
							<b className="fa fa-refresh fa-spin" />
						</td>
					</tr>
				);
			} else if (this.props.data && this.props.data.length) {
				rows = this.props.data.map(
					function (item) {
						return React.createElement(ReactTemplates.ROW[this.props.template], {
							item: item,
							columns: this.props.columns,
							tools: this.props.tools,
							statics: statics,
							attrs: this.props.attrs
						});
					}.bind(this)
				);

				if (!this.props.skipSort) {
					this.doSort(rows);
				}
			} else {
				var $translate = Tools.$translate;
				var noResultString = $translate('noResult.default');
				rows = (
					<tr className="no-result-row">
						<td colSpan={'100'}>{noResultString}</td>
					</tr>
				);
			}
		} else {
			rows = this.props.data.map(
				function (item) {
					return React.createElement(ReactTemplates.ROW[this.props.template], {
						item: item,
						columns: this.props.columns,
						tools: this.props.tools,
						statics: statics,
						attrs: this.props.attrs
					});
				}.bind(this)
			);

			if (!this.props.skipSort) {
				this.doSort(rows);
			}
		}

		var classes = 'main-table';

		if (this.props.sticky) {
			classes += ' fixed-header';
		}

		return (
			<table className={classes} key="main-table" ref={this.props.ref}>
				<ReactTemplates.TOOLS.thead
					data={this.props.data}
					columns={this.props.columns}
					tools={this.props.tools}
					columnTemplates={this.props.columnTemplates}
					currentSorting={this.props.currentSorting}
					length={this.props.data.length}
					categoryTypes={this.props.categoryTypes}
					actions={this.props.actions}
				/>

				<tbody key="main-tbody">{rows}</tbody>
			</table>
		);
	}
});

ReactTemplates.TOOLS.eventTypes = window.ReactCreateClass({
	displayName: 'eventTypes',
	render: function () {
		var $translate = window.Tools.$translate;
		var visitTooltip = 'event.types.Visit';
		var mailTooltip = 'event.types.Mail';
		var formTooltip = 'event.types.Form';

		var visitClass = 'first';
		var mailClass = 'second';
		var formClass = 'third';

		if (this.props.account.hasVisit) {
			visitClass = 'first indicator-inverted';
		}

		if (this.props.account.hasMail) {
			mailClass = 'second indicator-inverted';
		}

		if (this.props.account.hasForm) {
			formClass = 'third indicator-inverted';
		}

		var size = this.props.size ? 'indicator-' + this.props.size : 'indicator-sm';

		return (
			<div key={'event-type-' + this.props.theKey} className={'indicator-wrapper indicator-orange ' + size}>
				{ReactTemplates.TOOLS.withTooltip(
					<div className={visitClass}>
						<i className="fa fa-globe" />
					</div>,
					$translate(visitTooltip),
					{ placement: 'top', key: 'visit-' + this.props.theKey, style: { display: 'inline-block' } }
				)}

				{ReactTemplates.TOOLS.withTooltip(
					<div className={mailClass}>
						<i className="fa fa-envelope" />
					</div>,
					$translate(mailTooltip),
					{ placement: 'top', key: 'mail-' + this.props.theKey, style: { display: 'inline-block' } }
				)}

				{ReactTemplates.TOOLS.withTooltip(
					<div className={formClass}>
						<i className="fa fa-file" />
					</div>,
					$translate(formTooltip),
					{ placement: 'top', key: 'form-' + this.props.theKey, style: { display: 'inline-block' } }
				)}
			</div>
		);
	}
});

ReactTemplates.TOOLS.mailStats = window.ReactCreateClass({
	displayName: 'mailStats',
	render: function () {
		var tools = this.props.tools;
		var openedTooltip = 'event.contactopen';
		var clickedTooltip = 'mail.mailEvent.click';
		var submittedTooltip = 'event.form.submited';

		var readClass = 'first';
		var clickedClass = 'second';
		var submittedClass = 'third';

		if (this.props.read) {
			readClass += ' indicator-active';
		}

		if (this.props.clicked) {
			clickedClass += ' indicator-active';
		}

		if (this.props.submitted) {
			submittedClass += ' indicator-active';
		}

		return (
			<div key={'event-type-' + this.props.theKey} className="indicator-wrapper indicator-sm indicator-orange">
				<div key="read" className={readClass}>
					{this.props.read ? (
						ReactTemplates.TOOLS.withTooltip(
							<i className="fa fa-eye" />,
							tools.$translate(openedTooltip) + '\n' + moment(this.props.read).format('L LT'),
							{ placement: 'top', key: 'visit-tt' }
						)
					) : (
						<i className="fa fa-eye" />
					)}
				</div>

				<div key="clicked" className={clickedClass}>
					{this.props.clicked ? (
						ReactTemplates.TOOLS.withTooltip(
							<i className="fa fa-mouse-pointer" />,
							tools.$translate(clickedTooltip) + '\n' + moment(this.props.clicked).format('L LT'),
							{ placement: 'top', key: 'mail-tt' }
						)
					) : (
						<i className="fa fa-mouse-pointer" />
					)}
				</div>

				{this.props.hideSubmit ? (
					''
				) : (
					<div key="submitted" className={submittedClass}>
						{this.props.submitted ? (
							ReactTemplates.TOOLS.withTooltip(
								<i className="fa fa-wpforms" />,
								tools.$translate(submittedTooltip) + '\n' + moment(this.props.submitted).format('L LT'),
								{ placement: 'top', key: 'form-tt' }
							)
						) : (
							<i className="fa fa-wpforms" />
						)}
					</div>
				)}
			</div>
		);
	}
});

ReactTemplates.TOOLS.avatar = window.ReactCreateClass({
	getInitialState: function () {
		var self = this;
		var size = self.props.size ? self.props.size : 30;
		var avatar = self.props.avatar;

		return {
			user: self.props.user,
			size: size,
			avatar: avatar
		};
	},

	getDefaultProps: function () {
		return {
			avatar: { initials: '', url: '', gravatar: false }
		};
	},
	downloadAvatar: function () {
		var self = this;
		var avatarService = window.Tools.avatarService;

		avatarService
			.getAvatar(self.props.user, { skipGravatarCall: self.props.skipGravatarCall })
			.then(function (avatar) {
				self.setState({ avatar: avatar });
			})
			.catch(err => console.error(err));
	},
	componentDidUpdate: function (prevProps) {
		// change to gravatar or initials
		if (prevProps.avatar.url && !this.props.avatar.url) {
			this.downloadAvatar();
		} 
		if ((prevProps.user || {}).id !== (this.props.user || {}).id) {
			this.setState({ avatar: this.props.avatar });
			this.downloadAvatar();
		}
	},
	componentDidMount: function () {
		this.downloadAvatar();
	},
	onError: function () {
		if (this.state.avatar.url.indexOf('gravatar') === -1) {
			var avatarService = window.Tools.avatarService;
			this.setState({
				avatar: avatarService.getGravatar(this.props.user, { notFoundError: false }),
				hasError: true
			});
		}
	},
	UNSAFE_componentWillUpdate: function (newProps, newState) {
		var self = this;
		var size = this.props.size ? this.props.size : 30;
		// Set the state text if it was changed --> Only check if not assign before.. shallComponentUpdate check if visit has an assign
		if (newProps.user && newProps.user.id) {
			var avatar = this.props.avatar.url ? this.props.avatar : newState.avatar;
			if (newProps.avatar && newProps.avatar.url) {
				avatar.url = newProps.avatar.url;
			}

			if (this.state.avatar.url !== avatar.url) {
				self.setState({ user: this.props.user, size: size, avatar: avatar });
			}
		}
	},
	render: function () {
		var size = this.state.size;

		if (this.props.user && this.props.user.name) {
			var avatar = this.state.avatar;
			var tag = avatar.gravatar ? <img className="img" src={avatar.url} onError={this.onError} /> : null;
			var innerDivStyle = _.merge(
				{
					display: avatar.initials.length ? 'block' : 'none',
					lineHeight: size + 'px'
				},
				this.props.innerStyle || {}
			);

			var outerDivStyle = _.merge(
				{
					width: size,
					height: size,
					fontSize: parseInt(size) / 2 + 'px',
					marginTop: '3px'
				},
				this.props.style || {}
			);

			var spanStyle = {
				lineHeight: size + 'px'
			};

			var classNames = 'generic-avatar round';

			if (this.props.className) {
				classNames += ' ' + this.props.className;
			}

			return (
				<div className={classNames} style={outerDivStyle} id={this.props.id || null}>
					<div style={innerDivStyle} className="color">
						<span className="name" style={spanStyle}>
							{avatar.initials}
						</span>
					</div>
					{tag}
				</div>
			);
		} else {
			return <div />;
		}
	}
});

ReactTemplates.TOOLS.pagination = window.ReactCreateClass({
	click: function (pageNumber) {
		if (this.props.searchStr) {
			this.props.onChange(this.props.searchStr, pageNumber);
		} else {
			this.props.onChange(null, pageNumber);
		}
	},
	makePage: function (number, text, isActive) {
		return {
			number: number,
			text: text,
			active: isActive
		};
	},
	getPages: function (maxSize, currentPage, totalPages) {
		var rotate = true;
		var pages = [];
		// Default page limits
		var startPage = 1,
			endPage = totalPages;
		var isMaxSized = maxSize && maxSize < totalPages;

		// recompute if maxSize
		if (isMaxSized) {
			if (rotate) {
				// Current page is displayed in the middle of the visible ones
				startPage = Math.max(currentPage - Math.floor(maxSize / 2), 1);
				endPage = startPage + maxSize - 1;

				// Adjust if limit is exceeded
				if (endPage > totalPages) {
					endPage = totalPages;
					startPage = endPage - maxSize + 1;
				}
			} else {
				// Visible pages are paginated with maxSize
				startPage = (Math.ceil(currentPage / maxSize) - 1) * maxSize + 1;

				// Adjust last page if limit is exceeded
				endPage = Math.min(startPage + maxSize - 1, totalPages);
			}
		}

		if (startPage === 2) {
			pages.push(this.makePage(1, 1, false));
		}
		if (startPage > 2) {
			pages.push(this.makePage(1, 1, false));
			pages.push(this.makePage(startPage - 1, '...', false));
		}

		// Add page number links
		for (var number = startPage; number <= endPage; number++) {
			pages.push(this.makePage(number, number, number === currentPage));
		}

		if (endPage < totalPages - 1) {
			pages.push(this.makePage(endPage + 1, '...', false));
			pages.push(this.makePage(totalPages, totalPages, false));
		}
		if (endPage === totalPages - 1) {
			pages.push(this.makePage(totalPages, totalPages, false));
		}

		// Add links to move between page sets
		if (isMaxSized && !rotate) {
			if (startPage > 1) {
				// prevPageSet
				pages.unshift(this.makePage(startPage - 1, '...', false));
			}

			if (endPage < totalPages) {
				// nextPageSet
				pages.push(this.makePage(endPage + 1, '...', false));
			}
		}

		return pages;
	},
	render: function () {
		var self = this;
		var maxSize = self.props.maxSize;
		var totalPages = Math.ceil(self.props.totalItems / self.props.itemsPerPage);
		var currentPage = self.props.currentPage;

		var _pages = this.getPages(maxSize, currentPage, totalPages);
		var pages = _.map(_pages, function (page) {
			return (
				<li key={'pagination-' + page.number} className="number">
					<a
						className={page.active ? 'current number' : 'number'}
						onClick={self.click.bind(self, page.number)}
					>
						{page.number}
					</a>
				</li>
			);
		});

		return (
			<div className="up-paginator-wrap">
				<ul className="up-paginator">{pages}</ul>
			</div>
		);
	}
});

ReactTemplates.TOOLS.lazyLoad = window.ReactCreateClass({
	getInitialState: function () {
		return { visible: false };
	},
	propTypes: {
		children: PropTypes.node.isRequired,
		height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		threshold: PropTypes.number
	},
	getDefaultProps: function () {
		return {
			elementType: 'div',
			threshold: 0
		};
	},
	componentDidMount: function () {
		var eventNode = this.getEventNode();

		eventNode.addEventListener('scroll', this.onWindowScroll);
		window.addEventListener('resize', this.onWindowScroll);
		this.onWindowScroll();
	},
	style: function (element, prop) {
		return typeof getComputedStyle !== 'undefined'
			? getComputedStyle(element, null).getPropertyValue(prop)
			: element.style[prop];
	},
	overflow: function (element) {
		return this.style(element, 'overflow') + this.style(element, 'overflow-y') + this.style(element, 'overflow-x');
	},
	parentScroll: function (element) {
		if (!(element instanceof HTMLElement)) {
			return window;
		}

		var parent = element;

		while (parent) {
			if (parent === document.body || parent === document.documentElement) {
				break;
			}

			if (!parent.parentNode) {
				break;
			}

			if (/(scroll|auto)/.test(this.overflow(parent))) {
				return parent;
			}

			parent = parent.parentNode;
		}

		return window;
	},
	getEventNode: function () {
		return this.parentScroll(ReactDOM.findDOMNode(this));
	},
	componentDidUpdate: function () {
		if (!this.state.visible) this.onWindowScroll();
	},
	componentWillUnmount: function () {
		this.onVisible();
	},
	onVisible: function () {
		var eventNode = this.getEventNode();
		eventNode.removeEventListener('scroll', this.onWindowScroll);
		window.removeEventListener('resize', this.onWindowScroll);
	},
	hasMounted: function (component) {
		try {
			ReactDOM.findDOMNode(component);
			return true;
		} catch (e) {
			return false;
		}
	},
	timeout: null,
	onWindowScroll: function () {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		var self = this;
		this.timeout = setTimeout(function () {
			if (self.hasMounted(self)) {
				var threshold = self.props.threshold;
				var bounds = ReactDOM.findDOMNode(self).getBoundingClientRect();
				var scrollTop = window.pageYOffset;
				var top = bounds.top + scrollTop;
				var height = bounds.bottom - bounds.top;

				if (
					top === 0 ||
					(top <= scrollTop + window.innerHeight + threshold && top + height > scrollTop - threshold)
				) {
					self.setState({ visible: true });
					self.onVisible();
				}
			}
		}, 100);
	},
	render: function () {
		var elStyles = { height: this.props.height };
		var elClasses = this.state.visible ? 'lazy-load lazy-load-visible' : 'lazy-load';

		return (
			<div style={elStyles} className={elClasses}>
				{!this.state.visible && <ReactTemplates.loader />}
				{this.state.visible && this.props.children}
			</div>
		);
	}
});

'use strict';

angular.module('upFilters').filter('parseUrl', [
	'$filter',
	function ($filter) {
		return function (url, noOfChars) {
			noOfChars = noOfChars || 41;
			if (!url || url.length < noOfChars) {
				return url;
			}
			url = url.substring(0, noOfChars - 10) + ' ... ' + url.substring(url.length - 5, url.length);
			return url;
			/*
        var parser = document.createElement('a');
        parser.href = url;

        var indexOfLastSlash = parser.pathname.lastIndexOf('/');
        var indexOfSecondToLastSlash = parser.pathname.lastIndexOf('/', indexOfLastSlash - 1);

        var parseUrl = parser.host;

        console.log(parseUrl);

        if(parser.pathname == '/'){
            return parseUrl;
        }
        else if(indexOfSecondToLastSlash == -1 || indexOfSecondToLastSlash == 0){
            parseUrl += parser.pathname;
        }
        else{
            parseUrl += "/..." + parser.pathname.substring(indexOfSecondToLastSlash);
        }
        return parseUrl;
        */
		};
	}
]);

import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import { Icon, Text, Help } from '@upsales/components';
import T from 'Components/Helpers/translate';
import parseBounceReason from 'App/babel/helpers/parseBounceReason';

import './MailBounceLabel.scss';

type Props = {
	reason: string;
	type: 'hard_bounce' | 'soft_bounce';
};

const MailBounceLabel = ({ type, reason }: Props) => {
	const classes = new BEM('MailBounceLabel');
	const result = parseBounceReason(reason);

	return (
		<div className={classes.mod({ [type]: true }).b()}>
			<Help articleId={1265} sidebar={true}>
				<Text color={type === 'hard_bounce' ? 'dark-red' : 'black'} size="sm">
					<Icon name="warning" /> {T(`bounceReason.${result}`)}
				</Text>
			</Help>
		</div>
	);
};

export default MailBounceLabel;

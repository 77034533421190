import logError from 'App/babel/helpers/logError';
import { getProductCategoryTree } from 'App/babel/helpers/product';

angular.module('domain.admin').controller('AddProductCategoryModal', [
	'$scope',
	'$translate',
	'AppService',
	'FeatureHelper',
	'RequestBuilder',
	'$modalParams',
	'$q',
	'ProductCategory',
	'Product',
	function (
		$scope,
		$translate,
		AppService,
		FeatureHelper,
		RequestBuilder,
		$modalParams,
		$q,
		ProductCategory,
		Product
	) {
		var renderTimeout;
		var root;

		var props = {
			hasAccess: true,
			onSave: function (product) {
				props.notUniqueNameError = false;
				props.saving = true;
				render();
				ProductCategory.customer(props.customerId)
					.save(product)
					.then(function () {
						$scope.reject();
						render();
					})
					.catch(function (e) {
						props.saving = false;
						if (_.get(e, 'data.error.key') === 'NameNotUnique') {
							props.notUniqueNameError = true;
						} else {
							logError(e, 'Failed to save productCategory');
						}
						render();
					});
			},
			closeModal: function () {
				$scope.reject();
			},
			reloadModalPos: function () {
				$scope.reloadModalPosition();
			},
			customerId: null,
			type: null,
			roles: null,
			inheritRoles: false,
			categories: null,
			tools: {
				AppService: AppService,
				$translate: $translate,
				$q: $q
			},
			editProductCategory: null,
			notUniqueNameError: false,
			saving: false,
			loading: true,
			hasTieredProducts: false
		};

		function render() {
			if (!root) {
				root = document.getElementById('addProductCategoryModal_root');
			}

			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			var react = ReactTemplates.MODALS[$modalParams.type];
			renderTimeout = setTimeout(function () {
				if (!root) {
					return;
				}
				ReactDOM.render(React.createElement(react, props), root);
			}, 20);
		}

		var init = function () {
			props.type = 'AddProductCategory';
			props.hasAccess = true;
			props.roles = AppService.getRoles();
			props.orderRowFields = _.filter(AppService.getCustomFields('orderrow'), function (field) {
				return field.editable || field.visible;
			});

			const customFields = AppService.getCustomFields('orderrow').reduce(
				(cf, field) => {
					if (field.datatype === 'Calculation') {
						if (!window.calculateField.isValidAsValue(field.formula)) {
							cf.invalid.push({ ...field, disabled: true });
						} else {
							cf.valid.push(field);
						}
					}
					return cf;
				},
				{ valid: [], invalid: [] }
			);
			props.calculatingFields = [
				{ name: Tools.$translate('product.selectable'), children: customFields.valid },
				{ name: Tools.$translate('product.nonSelectable'), children: customFields.invalid }
			];

			var recursiveFilter = function (categories, targetId) {
				var filteredCategories = _.filter(categories, function (category) {
					var isNotTarget = category.id !== targetId;
					// If not target continue search in children
					if (isNotTarget) {
						category.children = recursiveFilter(category.children, targetId);
					}
					// Ok if not target, otherwise prune tree
					return isNotTarget;
				});

				return filteredCategories;
			};

			props.categories = getProductCategoryTree();
			if ($modalParams.productCategory && $modalParams.productCategory.id) {
				props.categories = recursiveFilter(props.categories, $modalParams.productCategory.id);
			}
			props.customerId = AppService.getCustomerId();
			props.editProductCategory = $modalParams.productCategory;

			// Fetch tiered-products in this category (if feature available, calc fields and is edit)
			if (
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_TIERS) &&
				props.calculatingFields.length &&
				$modalParams.productCategory &&
				$modalParams.productCategory.id
			) {
				const rb = new RequestBuilder();
				rb.limit = 0;
				rb.addFilter(Product.attr.categoryId, rb.comparisonTypes.Equals, $modalParams.productCategory.id);
				rb.addFilter(Product.attr.tiers.attr.start, rb.comparisonTypes.NotEquals, null);
				Product.customer(props.customerId)
					.find(rb.build())
					.then(({ metadata }) => {
						props.hasTieredProducts = !!metadata.total;
						props.loading = false;
						render();
					})
					.catch(e => {
						// Show error and close modal
						logError(e, 'Failed to fetch tiered products in product category');
						Tools.NotificationService.addNotification({
							icon: 'times',
							style: Tools.NotificationService.style.ERROR,
							title: 'default.error',
							body: 'openError.productCategory'
						});
						$scope.reject();
					});
			} else {
				props.loading = false;
			}

			render();
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(root);
			root = undefined;
		});

		$scope.$on('modal.ready', function () {
			init();
		});
	}
]);

'use strict';

// FIXME: later we might want to split this by action!

angular.module('domain.admin').controller('EditAction', [
	'$scope',
	'$modalParams',
	'$translate',
	'$q',
	'AppService',
	'RequestBuilder',
	'LeadSource',
	'Campaign',
	'CampaignAttributes',
	function (
		$scope,
		$modalParams,
		$translate,
		$q,
		AppService,
		RequestBuilder,
		LeadSource,
		Campaign,
		CampaignAttributes
	) {
		var arrayProperties = ['categories', 'campaigns'];

		$scope.save = function () {
			$scope.saving = true;

			// map properties
			var properties = [];
			angular.forEach($scope.properties, function (val, key) {
				var obj = {
					name: key,
					value: val
				};

				if (_.indexOf(arrayProperties, key) !== -1) {
					obj.value = obj.value.join(',');
				}

				properties.push(obj);
			});
			$scope.resolve(properties);
		};

		$scope.typeToggle = 'users';

		$scope.userRoleChange = function () {
			if ($scope.userRole) {
				if ($scope.userRole.description) {
					// ROLE
					if ($scope.properties.User) delete $scope.properties.User;
					$scope.properties.Role = $scope.userRole.id;
				} else {
					// USER
					if ($scope.properties.Role) delete $scope.properties.Role;
					$scope.properties.User = $scope.userRole.id;
				}
			}
		};

		$scope.userRoleChangeCreateAccount = function () {
			if ($scope.userRole) {
				if ($scope.userRole.description) {
					// ROLE
					if ($scope.properties.AccountManager) delete $scope.properties.AccountManager;
					$scope.properties.Role = $scope.userRole.id;
				} else {
					// USER
					if ($scope.properties.Role) delete $scope.properties.Role;
					$scope.properties.AccountManager = $scope.userRole.id;
				}
			}
		};

		var getTag = function (tag, title) {
			return {
				text: title,
				value: '<span contentEditable="false" class="inserted-tag" data-tag="' + tag + '">' + title + '</span>'
			};
		};

		var init = function (res) {
			if ($modalParams.edit) {
				// TODO ? fix CF's here
			}

			// parse properties
			$scope.properties = {};
			angular.forEach($modalParams.properties, function (prop) {
				$scope.properties[prop.name] = prop.value;
				if (_.indexOf(arrayProperties, prop.name) !== -1) {
					$scope.properties[prop.name] = $scope.properties[prop.name].split(',');
				} else if (prop.name === 'User' || prop.name === 'Role' || prop.name === 'AccountManager') {
					$scope.userRole = prop.value;
				}
			});

			$scope.accountCategories = res.accountCategories.data;
			$scope.contactCategories = res.contactCategories.data;
			$scope.campaigns = res.campaigns.data;
			$scope.leadSources = res.leadSources.data;

			$scope.customFields = _.filter(res.accountCustomFields.data, 'editable');
			$scope.customFieldLength = $scope.customFields.length;

			$scope.contactCustomFields = _.filter(res.contactCustomFields, 'editable');
			$scope.contactCustomFieldLength = $scope.contactCustomFields.length;

			$scope.accountCategorySelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.category').toLowerCase()
			};

			$scope.contactCategorySelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.category').toLowerCase()
			};

			$scope.campaignSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.campaign').toLowerCase()
			};

			$scope.leadSourceSelect = {
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.leadsource').toLowerCase()
			};

			$scope.userRoleSelect = {
				matcher: function (term, text, obj) {
					return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				placeholder:
					$translate.instant('default.select') + ' ' + $translate.instant('default.user').toLowerCase(),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				id: 'id',
				data: [
					{ name: $translate.instant('default.roles'), children: res.roles.data },
					{ name: $translate.instant('default.users'), children: res.activeUsers.data }
				]
			};

			$scope.editorOptions = {
				change: $scope.mailContentChange,
				keyup: $scope.mailContentChange,
				stylesheets: ['styles/wysiwyg.css'],
				tools: [
					'bold',
					'italic',
					'underline',
					'justifyLeft',
					'justifyCenter',
					'justifyRight',
					'justifyFull',
					'insertUnorderedList',
					'insertOrderedList',
					'indent',
					'outdent',
					'createLink',
					'unlink',
					'insertImage',
					'createTable',
					'addRowAbove',
					'addRowBelow',
					'addColumnLeft',
					'addColumnRight',
					'deleteRow',
					'deleteColumn',
					'viewHtml',
					{
						name: 'formatting',
						items: [
							{ text: $translate.instant('default.default'), value: 'p' },
							{ text: $translate.instant('default.title') + ' 1', value: 'h1' },
							{ text: $translate.instant('default.title') + ' 2', value: 'h2' },
							{ text: $translate.instant('default.title') + ' 3', value: 'h3' }
						]
					},
					'fontName',
					'fontSize',
					'foreColor'
					//'insertHtml'
				],
				insertHtml: [
					getTag('%%fname%%', $translate.instant('mailTags.fname')),
					getTag('%%lname%%', $translate.instant('mailTags.lname')),
					getTag('%%account_manager%%', $translate.instant('mailTags.account_manager')),
					getTag('%%account_manager_email%%', $translate.instant('mailTags.account_manager_email')),
					getTag('%%account_manager_title%%', $translate.instant('mailTags.account_manager_title')),
					getTag('%%account_manager_phone%%', $translate.instant('mailTags.account_manager_phone')),
					getTag('%%account_manager_cellphone%%', $translate.instant('mailTags.account_manager_cellphone'))
				],
				messages: {
					bold: $translate.instant('editor.bold'),
					italic: $translate.instant('editor.italic'),
					underline: $translate.instant('editor.underline'),
					justifyCenter: $translate.instant('editor.justifyCenter'),
					justifyLeft: $translate.instant('editor.justifyLeft'),
					justifyRight: $translate.instant('editor.justifyRight'),
					justifyFull: $translate.instant('editor.justifyFull'),
					insertUnorderedList: $translate.instant('editor.insertUnorderedList'),
					insertOrderedList: $translate.instant('editor.insertOrderedList'),
					indent: $translate.instant('editor.indent'),
					outdent: $translate.instant('editor.outdent'),
					createLink: $translate.instant('editor.createLink'),
					unlink: $translate.instant('editor.unlink'),
					fontNameInherit: $translate.instant('editor.fontNameInherit'),
					fontSizeInherit: $translate.instant('editor.fontSizeInherit'),
					viewHtml: $translate.instant('editor.viewHtml'),
					imageWebAddress: $translate.instant('editor.imageWebAddress'),
					imageAltText: $translate.instant('editor.imageAltText'),
					linkWebAddress: $translate.instant('editor.imageWebAddress'),
					linkText: $translate.instant('editor.linkText'),
					linkToolTip: $translate.instant('editor.linkToolTip'),
					linkOpenInNewWindow: $translate.instant('editor.linkOpenInNewWindow'),
					dialogInsert: $translate.instant('editor.dialogInsert'),
					dialogUpdate: $translate.instant('editor.dialogUpdate'),
					dialogButtonSeparator: $translate.instant('editor.dialogButtonSeparator'),
					dialogCancel: $translate.instant('default.abort'),
					insertImage: $translate.instant('editor.insertImage'),
					insertHtml: $translate.instant('editor.insertTag'),
					imageWidth: $translate.instant('editor.imageWidth'),
					imageHeight: $translate.instant('editor.imageHeight'),
					foreColor: $translate.instant('editor.color'),
					createTable: $translate.instant('editor.createTable')
				}
			};
		};

		var loadData = function () {
			var customerId = AppService.getCustomerId();

			var campaignFilter = new RequestBuilder();
			var campaignAttr = CampaignAttributes().attr;
			campaignFilter
				.addSort(campaignAttr.name, true)
				.addFilter(campaignAttr.active, campaignFilter.comparisonTypes.Equals, 1);

			var promises = {
				roles: $q.when({ data: AppService.getRoles() }),
				leadSources: LeadSource.find({}),
				accountCategories: $q.when({ data: AppService.getCategories('account') }),
				contactCategories: $q.when({ data: AppService.getCategories('contact') }),
				activeUsers: $q.when({ data: AppService.getActiveUsers() }),
				accountCustomFields: $q.when({ data: AppService.getCustomFields('account') }),
				contactCustomFields: $q.when({ data: AppService.getCustomFields('contact') }),
				campaigns: Campaign.customer(customerId).find(campaignFilter.build())
			};

			return $q.all(promises);
		};

		AppService.loadedPromise.then(loadData).then(init);
	}
]);

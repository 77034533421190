// Based on:
// http://www.jqueryrain.com/?zETM2MpT

'use strict';

angular.module('upDirectives').directive('upColorpicker', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		template: '<input type="text" class="up-color-picker" />',
		replace: true,
		compile: function (element) {
			var copyClasses = element[0].className;
			element.removeAttr('up-colorpicker');
			var wrap = angular.element(
				'<div class="up-color-picker-wrap">' +
					element[0].outerHTML +
					'<div class="up-color-picker-taglabel"></div></div>'
			);
			wrap.addClass(copyClasses);
			element.replaceWith(wrap);
			var presets;
			var saveBtn;

			return function link($scope, wrap, $attrs, ngModel) {
				var input = wrap.find('input');
				var tagLabel = wrap.find('.up-color-picker-taglabel');
				var hasTagVal = false;

				if ($attrs.inputClass) {
					input.addClass($attrs.inputClass);
				}

				var slidersHeight = 130;
				var pickerOpts = {
					format: 'hex',
					sliders: {
						saturation: {
							maxLeft: slidersHeight,
							maxTop: slidersHeight,
							callLeft: 'setSaturation',
							callTop: 'setBrightness'
						},
						hue: {
							maxTop: slidersHeight,
							maxLeft: 0,
							callLeft: false,
							callTop: 'setHue'
						}
					}
				};

				if ($attrs.colorpickerAlign) {
					pickerOpts.align = $attrs.colorpickerAlign;
				}

				var addColorPresets = function () {
					if ($attrs.presets) {
						presets = $scope.$eval($attrs.presets);
						pickerOpts.colorSelectors = presets[0].colors;
					}

					input.colorpicker(pickerOpts);

					var Colorpicker = input.data('colorpicker');

					if (presets && presets.length) {
						var picker = Colorpicker.picker;

						picker.find('.colorpicker-selectors').html('');

						angular.forEach(presets, function (group) {
							// Do not show group if empty
							if (!group.colors || !Object.keys(group.colors).length) {
								return;
							}

							if (group.title) {
								picker
									.find('.colorpicker-selectors')
									.append('<div class="colors-title">' + group.title + '</div>');
							}

							$.each(group.colors, function (name, color) {
								var $btn = $('<i />').css('background-color', color).data('class', name);
								$btn.click(function () {
									ngModel.$setViewValue($(this).data('class'));
									input.colorpicker('hide');
									$scope.$apply();
								});
								picker.find('.colorpicker-selectors').append($btn);
							});
						});

						picker.find('.colorpicker-selectors').show();
					}

					if ($scope.$eval($attrs.addPreset)) {
						saveBtn = $('<i />')
							.addClass('fa fa-plus')
							.css({
								'padding-top': '2px',
								'text-align': 'center',
								display:
									!presets || !presets[1].colors || !presets[1].colors[$scope.$eval($attrs.ngModel)]
										? 'block'
										: 'none'
							});
						saveBtn.click(function () {
							var addPreset = $scope.$eval($attrs.addPreset);
							addPreset($scope.$eval($attrs.ngModel)).then(function () {
								addColorPresets();
							});
						});
						Colorpicker.picker.find('.colorpicker-selectors').append(saveBtn);
					}
				};
				addColorPresets();

				var re = /#[a-zA-Z0-9]{6}$/;
				// var re2 = /#[a-zA-Z0-9]{3}$/;

				var valid = function (val) {
					return re.test(val); // || re2.test(val);
				};

				var render = function (value) {
					if (valid(value)) {
						// ngModel.$setValidity('hex', true);

						input.css({
							'background-color': value,
							'background-image': 'none',
							color: window.getTextColor(value)
						});

						tagLabel.hide();

						input.colorpicker('setValue', value);
						hasTagVal = false;
						if (saveBtn) {
							saveBtn.css({
								display: !presets || !presets[1].colors || !presets[1].colors[value] ? 'block' : 'none',
								'background-color': value,
								color: window.getTextColor(value)
							});
						}
					} else if (presets && presets[0].colors && presets[0].colors[value]) {
						var val = presets[0].colors[value];

						// ngModel.$setValidity('hex', true);

						input.css({
							'background-color': val,
							'background-image': 'none',
							color: window.getTextColor(val)
						});
						input.colorpicker('setValue', val);
						tagLabel.css({
							'background-color': val,
							color: window.getTextColor(val)
						});
						tagLabel.html(val).show();
						hasTagVal = true;
						if (saveBtn) {
							saveBtn.css({
								display: 'none'
							});
						}
					} else {
						input.css({
							'background-color': '',
							'background-image': '',
							color: ''
						});
						tagLabel.hide();
						hasTagVal = false;
						if (saveBtn) {
							saveBtn.css({
								display: 'none'
							});
						}
						// ngModel.$setValidity('hex', false);
					}
				};

				input.on('changeColor', function () {
					var val = input.colorpicker('getValue');
					var preset = null;
					if (!valid(val) && presets && presets.length && presets[0].colors) {
						preset = val;
						val = presets[0].colors[preset];
					}

					ngModel.$setViewValue(preset || val);
				});

				input.on('change', function () {
					// If field is changed(blured) and not a valid hex we set it anyway to get a value from colorpicker
					if (!valid(input.val())) {
						input.colorpicker('setValue', input.val());
					}
				});

				input.on('keydown', function (e) {
					// Prevent enter from submitting form
					if (e.keyCode === 13) {
						e.preventDefault();
						return false;
					}
				});

				tagLabel.on('click', function () {
					input.focus();
				});

				input.on('focus', function () {
					if (hasTagVal) {
						var val = presets[0].colors[input.val()];
						input.val(val);
						tagLabel.hide();
					}
				});

				input.on('blur', function () {
					ngModel.$setViewValue(input.val());
					$scope.$apply();

					if (hasTagVal) {
						tagLabel.show();
					}
				});

				// initial watch
				$scope.$watch($attrs.ngModel, render);
			};
		}
	};
});

import { Loader, Button, Icon, Title, Text, Block } from '@upsales/components';
import UpFlow from 'Components/Inputs/UpFlow';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import openModal from 'App/services/Modal';
import BouncingTooltip from 'Components/Tooltip/BouncingTooltip';
import { flowTracker } from 'Helpers/Tracker';

ReactTemplates.segment.flowPreview = window.ReactCreateClass({
	preventBlur: false,
	getInitialState: function () {
		return {
			flowHasError: false,
			statusDropOpen: false,
			activated: false,
			currentlyOpenSelect: null,
			flowSelectReady: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			containsErrorsAndCanNotBeActivated: t('flow.containsErrorsAndCanNotBeActivated'),
			flow: t('flow.flow'),
			flowExample: t('flow.flowExample'),
			createFlow: t('flow.createFlow'),
			eventInvitationExample: t('flowExample.eventInvitationExample'),
			onboardingExample: t('flowExample.onboardingExample'),
			coldProspectNurturingExample: t('flowExample.coldProspectNurturingExample'),
			flowInfoTitle: t('flow.flowInfoTitle'),
			flowInfoBody: t('flow.flowInfoBody'),
			active: t('default.active'),
			draft: t('mail.draft'),
			status: t('default.status'),
			paused: t('mail.paused'),
			pause: t('mail.pause'),
			activate: t('default.activate'),
			editFlow: t('flow.editFlow'),
			clickToActivateFlow: t('flow.clickToActivateFlow'),
			activateFlow: t('flow.activateFlow'),
			activateFlowInfo: t('flow.activateFlowInfo'),
			pauseFlow: t('flow.pauseFlow'),
			pauseFlowInfo: t('flow.pauseFlowInfo'),
			activatingFlow: t('flow.activatingFlow'),
			flowActivated: t('flow.flowActivated')
		};
	},
	componentDidMount: function () {
		this.trackClicks();
		document.addEventListener('click', this.outsideClick);
	},
	UNSAFE_componentWillUpdate: function (nextProps) {
		// close dropdown if flowActivatedInfo is about to go away
		if (this.props.flowComponent.flowActivatedInfo && !nextProps.flowComponent.flowActivatedInfo) {
			this.closeDropdown();
		}
	},
	trackClicks: function () {
		var self = this;
		document.onmousedown = function (e) {
			// If dropdown is open and click was not in/on trigger/dropdown
			if (
				self.state.statusDropOpen &&
				self._dropdown &&
				!self._dropdown.contains(e.target) &&
				self._dropdownTrigger !== e.target &&
				!self._dropdownTrigger.contains(e.target)
			) {
				self.closeDropdown();
			}
		};
	},
	componentWillUnmount: function () {
		document.onmousedown = null;
		document.removeEventListener('click', this.outsideClick);
	},
	usePreset: function (e, id) {
		e.preventDefault();

		this.props.flowComponent.usePreset(id);
	},
	openDropdown: function () {
		this.props.flowComponent.hideTooltip();
		this.setState({ statusDropOpen: true });
	},
	closeDropdown: function () {
		this.setState({ statusDropOpen: false });
	},
	toggleDropdown: function () {
		this.setState({ statusDropOpen: !this.state.statusDropOpen });
	},
	outsideClick: function (e) {
		var self = this;
		var sidebarTrigger = document.getElementById('change-segment-status-sidebar');
		// If dropdown is open and click was not in/on trigger/dropdown
		if (
			self.props.cogOpen &&
			self._cogOpen &&
			!self._cogOpen.contains(e.target) &&
			self._cogTrigger !== e.target &&
			!self._cogTrigger.contains(e.target) &&
			(!sidebarTrigger || (sidebarTrigger && sidebarTrigger !== e.target && !sidebarTrigger.contains(e.target)))
		) {
			self.props.editDropdown();
		}
	},
	settingsDropdown: function (flow) {
		var self = this;

		var itemArray = [];

		itemArray.push({
			key: 'duplicate-flow',
			class: 'item',
			icon: 'fa fa-copy',
			title: Tools.$translate('flow.settings.duplicate.title'),
			desc: Tools.$translate('flow.settings.duplicate.desc'),
			onClick: function () {
				self.props.editDropdown();
				self.props.actions.copyFlow(flow);
			}
		});

		itemArray.push({
			key: 'edit-flow',
			class: 'item',
			icon: 'fa fa-edit',
			title: Tools.$translate('flow.settings.edit.title'),
			desc: Tools.$translate('flow.settings.edit.desc'),
			onClick: function () {
				self.props.editDropdown();
				self.props.actions.editFlow(flow);
			}
		});

		itemArray.push({
			key: 'remove-flow',
			class: 'item',
			icon: 'fa fa-trash-o',
			title: Tools.$translate('flow.settings.remove.title'),
			desc: Tools.$translate('flow.settings.remove.desc'),
			onClick: function () {
				self.props.editDropdown();
				self.props.actions.deleteFlow(flow);
			}
		});

		return (
			<ul>
				{_.map(itemArray, function (item) {
					return (
						<li data-test-id={'flow-edit-' + item.key} key={item.key} onClick={item.onClick}>
							<div className={item.class}>
								<div className="icon">
									<i className={item.icon} />
								</div>
								<div className="text">
									<span>{item.title}</span>
									<span>{item.desc}</span>
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		);
	},
	openSelectFlow: function () {
		this.setState({ currentlyOpenSelect: 'flow' }, function () {
			$('.flow-select input').select2('open');
		});
	},
	closeSelectFlow: function () {
		setTimeout(function () {
			$('.flow-select input').select2('data', null);
		}, 0);

		this.setState({ currentlyOpenSelect: null });
	},
	render: function () {
		var self = this;
		var flowComponent = this.props.flowComponent;
		var actions = this.props.actions;
		var header;
		var content;

		var dropDown = self.settingsDropdown(flowComponent.flow);

		var loader = (
			<div id="flow-loader" className={flowComponent.loading ? 'visible' : null}>
				{React.createElement(ReactTemplates.loader, {})}
			</div>
		);
		if (!flowComponent.loading) {
			if (flowComponent.flow) {
				var statusClasses = '';
				var isActive = flowComponent.flow.status === 'active';
				var isPaused = flowComponent.flow.status === 'paused';
				var hasSegment = !!flowComponent.flow.segment;

				if (!hasSegment) {
					statusClasses += ' disabled';
				} else {
					if (self.state.statusDropOpen) {
						statusClasses += ' open';
					}
					if (isActive) {
						statusClasses += ' active';
					}
				}

				const showActivateTooltip =
					flowComponent.showTooltip &&
					!isActive &&
					!flowComponent.showSelectionTooltip &&
					this.props.activeTab === 'flow';

				header = (
					<div id="flow-header" style={{ padding: 0 }}>
						<BouncingTooltip
							title={self.lang.clickToActivateFlow}
							direction="top"
							iterationCount={'infinite'}
							distance={20}
							dissmisable={true}
							visible={showActivateTooltip}
						>
							{makeTooltipDisappear => (
								<div
									tabIndex="-1"
									id="flow-status-dropdown"
									className={statusClasses}
									ref={function (ref) {
										self._statusDropdown = ref;
									}}
								>
									<button
										type="button"
										onClick={() => {
											if (hasSegment) {
												makeTooltipDisappear();
												this.toggleDropdown();
											}
										}}
										ref={function (r) {
											self._dropdownTrigger = r;
										}}
									>
										<span className="header">{self.lang.status}</span>
										<span className="status">
											{isActive ? (
												<span>
													<b className="fa fa-play" />
													{self.lang.active}
												</span>
											) : isPaused ? (
												<span>{self.lang.paused}</span>
											) : (
												<span>{self.lang.draft}</span>
											)}
										</span>
										<b className="fa fa-caret-down" />
									</button>

									<div
										className="flow-status-dropdown-popup"
										ref={function (r) {
											self._dropdown = r;
										}}
									>
										<div
											className={
												'option-large' +
												(!isActive &&
												!flowComponent.activatingFlow &&
												!flowComponent.flowActivatedInfo
													? ' option-visible'
													: '')
											}
										>
											<Title color="grey-11">{this.lang.activateFlow}</Title>
											<Block space="mtl mbxl">
												<Text color="grey-10">
													{this.state.flowHasError
														? this.lang.containsErrorsAndCanNotBeActivated
														: this.lang.activateFlowInfo}
												</Text>
											</Block>
											<PrimaryButton
												onClick={() => {
													document.onmousedown = null;
													if (flowComponent.flow.sourceTemplate) {
														flowTracker.track(
															flowTracker.events.ACTIVATE_FLOW_FROM_TEMPLATE,
															{
																template: flowComponent.flow.sourceTemplate
															}
														);
													}
													// eslint-disable-next-line promise/catch-or-return
													actions.setFlowStatus('active').finally(this.trackClicks);
												}}
												disabled={this.state.flowHasError}
											>
												<Icon name="play" space="mrs" />
												{this.lang.activate} {this.lang.flow.toLowerCase()}
											</PrimaryButton>
										</div>

										<div
											className={
												'option-large' +
												(isActive &&
												!flowComponent.activatingFlow &&
												!flowComponent.flowActivatedInfo
													? ' option-visible'
													: '')
											}
										>
											<Title color="grey-11">{this.lang.pauseFlow}</Title>
											<Block space="mtl mbxl">
												<Text color="grey-10">{this.lang.pauseFlowInfo}</Text>
											</Block>
											<PrimaryButton
												onClick={
													isActive
														? () => {
																this.closeDropdown();
																actions.setFlowStatus('paused');
														  }
														: undefined
												}
											>
												<Icon name="pause" space="mrs" />
												{this.lang.pause} {this.lang.flow.toLowerCase()}
											</PrimaryButton>
										</div>

										<div
											className={
												'activation-loading' +
												(flowComponent.activatingFlow ? ' option-visible' : '')
											}
										>
											<Block space="mbl">
												<Loader />
											</Block>
											<Title>{self.lang.activatingFlow}</Title>
										</div>

										<div
											className={
												'activation-activated' +
												(flowComponent.flowActivatedInfo ? ' option-visible' : '')
											}
										>
											<Icon name="check" space="mbl" color="success-5" />
											<Title>{self.lang.flowActivated}</Title>
										</div>
									</div>
								</div>
							)}
						</BouncingTooltip>
						{flowComponent.isStandalone ? (
							<div className="nav-tools">
								<a
									ref={function (r) {
										self._cogTrigger = r;
									}}
									data-test-id="segment-nav-edit"
									className="nav-a"
									onClick={self.props.editDropdown}
								>
									<b className="fa fa-cog" />
								</a>
								<div
									ref={function (r) {
										self._cogOpen = r;
									}}
									className={self.props.cogOpen ? 'settings-dropdown open' : 'settings-dropdown'}
								>
									{dropDown}
								</div>
							</div>
						) : (
							''
						)}
						<Button id="edit-flow-right-btn" size="xl" onClick={flowComponent.editFlow}>
							<b className="fa fa-edit" /> {self.lang.editFlow}
						</Button>
					</div>
				);

				content = React.createElement(ReactTemplates.segment.flow, {
					flow: flowComponent.flow,
					contacts: this.props.totalContacts,
					editable: false,
					onErrorChange: flowHasError => {
						if (flowHasError !== this.state.flowHasError) {
							this.setState({ flowHasError });
						}
					},
					tools: this.props.tools
				});
			} else {
				var selectWrapClass = 'select-wrap';

				switch (this.state.currentlyOpenSelect) {
					case 'flow':
						selectWrapClass += ' flow';
						break;
					default:
						break;
				}

				header = (
					<div id="flow-header">
						<div className="header-left">
							<h2>{Tools.$translate('flow.noFlow.title')}</h2>
							<p>{Tools.$translate('flow.noFlow.desc')}</p>
							<PrimaryButton
								data-test-id="create-flow-btn"
								onClick={function () {
									openModal('CreateFlow', {
										flow: {
											segment: {
												id: self.props.segment.id,
												name: self.props.segment.name
											}
										},
										onClose: savedFlow => {
											if (savedFlow?.segment) {
												Tools.$state.go('editFlow', {
													segmentId: savedFlow.segment.id
												});
											}
										}
									});
								}}
							>
								<Icon name="plus" />
								{self.lang.createFlow}
							</PrimaryButton>

							<div className={selectWrapClass}>
								<span className="flow">
									{self.state.flowSelectReady ? (
										<DefaultButton onClick={this.openSelectFlow}>
											<Icon name="copy" />
											{Tools.$translate('flow.noFlow.copyFrom')}
										</DefaultButton>
									) : (
										<Loader size="sm" />
									)}
								</span>
								<span className="flow-select">
									<UpFlow
										uncontrolled={true}
										onChange={function (value) {
											// eslint-disable-next-line promise/catch-or-return
											self.props.actions.copyFlowPromise(value).then(function (copiedFlow) {
												openModal('CreateFlow', {
													flow: {
														...copiedFlow,
														segment: {
															id: self.props.segment.id,
															name: self.props.segment.name
														}
													},
													onClose: savedFlow => {
														if (savedFlow) {
															Tools.$state.go('editFlow', {
																segmentId: savedFlow.segment.id
															});
														}
													}
												});
											});
										}}
										eventListeners={{
											'select2-close': this.closeSelectFlow,
											'select2-open': this.openSelectFlow
										}}
										onReady={() => this.setState({ flowSelectReady: true })}
									/>
								</span>
							</div>
						</div>
						<div className="header-right">
							<img src="img/flow2.svg" />
						</div>
					</div>
				);
			}
		}

		return (
			<div id="segment-flow" className={flowComponent.isStandalone ? 'standalone' : ''}>
				{header}

				{content}

				{loader}
			</div>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';

import Bem from '@upsales/components/Utils/bemClass';
import { Text, Button, Row, Icon, Tooltip } from '@upsales/components';
import { columnConfigs, deleteColumn } from 'Store/reducers/AdminEditSalesboardReducer';
import store from 'Store';
import './PreviewSalesboardColumn.scss';

const getFilterItems = (filters, field, data) => {
	const filter = _.find(filters, { field });

	if (!filter) {
		return null;
	}

	const valueArray = Array.isArray(filter.value) ? filter.value : [filter.value];
	const isDateFilter = field === 'date';

	return valueArray.map(value => {
		const idValue = value && value.preset ? value.preset : value;
		const found = _.find(data, { id: idValue });
		return found
			? found
			: { id: idValue, name: Tools.$translate(isDateFilter ? `date.${idValue}` : 'default.noName') };
	});
};

class PreviewSalesboardColumn extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			editColumn: t('default.editColumn'),
			deleteColumn: t('admin.salesboard.deleteColumn'),
			type: t('default.type'),
			sorting: t('default.sorting'),
			nothingSelected: t('default.nothingSelected'),
			anyTime: t('date.anyTime'),
			lockedColumn: t('admin.salesboard.lockedColumn'),
			tooltip: t('admin.salesboard.lockedColumnTooltip')
		};

		const { column } = this.props;
		this.config = columnConfigs[column.type];
		this.filterData = this.config.filter.data();
		this.dateFilterData = this.config.dateFilter.data();
		this.sortData = this.config.getSortable();
	}

	startEdit = () => {
		const { column, startEdit } = this.props;
		startEdit(column.$id);
	};

	getDateFilterText() {
		const { column } = this.props;
		const config = this.config;
		const lang = this.lang;

		const dateFilter = _.find(column.filters, { field: config.dateFilter.field });

		if (_.get(dateFilter, 'value.preset') === 'custom') {
			return `${moment(dateFilter.value.start).format('YYYY-MM-DD')} - ${moment(dateFilter.value.end).format(
				'YYYY-MM-DD'
			)}`;
		} else {
			const selectedDateFilterValues = getFilterItems(
				column.filters,
				config.dateFilter.field,
				this.dateFilterData
			);
			return selectedDateFilterValues
				? selectedDateFilterValues.map(object => object.name).join(', ')
				: lang.anyTime;
		}
	}

	render() {
		const { column } = this.props;
		const lang = this.lang;
		const t = Tools.$translate;

		const config = this.config;

		const hasNonSupportedFilters = _.some(
			column.filters,
			filter => [config.filter.field, config.dateFilter.field].indexOf(filter.field) === -1
		);

		const selectedFilterValues = getFilterItems(column.filters, config.filter.field, this.filterData);
		const filterText = selectedFilterValues
			? selectedFilterValues.map(object => object.name).join(', ')
			: lang.nothingSelected;
		const dateFilterText = this.getDateFilterText();

		const selectedSorting =
			(column.sorting &&
				column.sorting.length &&
				_.find(this.sortData, sort => sort.id === column.sorting[0].attribute)) ||
			null;
		const selectedSortingText = selectedSorting ? selectedSorting.name : lang.nothingSelected;
		const selectedSortingIcon = selectedSorting ? (
			<Icon name={column.sorting[0].ascending ? 'sort-numeric-asc' : 'sort-numeric-desc'} />
		) : null;

		const PreviewSalesboardColumnBem = new Bem('PreviewSalesboardColumn');
		const style = column.image ? { backgroundImage: `url(${column.image}?width=250)` } : {};

		return (
			<div className={PreviewSalesboardColumnBem.b()}>
				<div style={style} className={PreviewSalesboardColumnBem.elem('image').b()}>
					<div className={PreviewSalesboardColumnBem.elem('overlay').b()} />
					<Text color="white">{column.title}</Text>
				</div>
				<div className={PreviewSalesboardColumnBem.elem('config-container').b()}>
					<Row>
						<Text bold={true}>{lang.type}</Text>
					</Row>
					<Row>
						<Text color="grey-11">{t(config.title)}</Text>
					</Row>
					<Row>
						<Text bold={true}>{t(config.filter.title)}</Text>
					</Row>
					<Row>
						<Text color="grey-11">{filterText}</Text>
					</Row>
					<Row>
						<Text bold={true}>{t(config.dateFilter.title)}</Text>
					</Row>
					<Row>
						<Text color="grey-11">{dateFilterText}</Text>
					</Row>
					<Row>
						<Text bold={true}>{lang.sorting}</Text>
					</Row>
					<Row>
						<Text style={{ marginRight: 5 }} color="grey-11">
							{selectedSortingText}
						</Text>
						<Text color="grey-11">{selectedSortingIcon}</Text>
					</Row>
				</div>
				{hasNonSupportedFilters ? (
					<Row className={PreviewSalesboardColumnBem.elem('locked-container').b()}>
						<Tooltip title={lang.tooltip}>
							<Text bold={true} italic={true} color="grey-10">
								<Icon name="lock" style={{ marginRight: 5 }} /> {lang.lockedColumn}
							</Text>
						</Tooltip>
						<Tooltip title={lang.deleteColumn}>
							<div onClick={() => store.dispatch(deleteColumn(column))}>
								<Text bold={true} color="grey-10">
									<Icon name="trash" />
								</Text>
							</div>
						</Tooltip>
					</Row>
				) : (
					<Button
						className={PreviewSalesboardColumnBem.elem('start-edit-button').b()}
						onClick={this.startEdit}
						type="link"
					>
						<Icon name="pencil-square-o" /> {lang.editColumn}
					</Button>
				)}
			</div>
		);
	}
}

PreviewSalesboardColumn.propTypes = {
	column: PropTypes.object,
	startEdit: PropTypes.func
};

export default PreviewSalesboardColumn;

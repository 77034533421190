import { Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import { useDrop } from 'react-dnd';

import './FolderDropdownOption.scss';

type Props = {
	folder: { id: number; name: string };
	changeFolder: (id: number) => void;
	changeFolderFolder: (id: number, folderId: number | null) => void;
	changeFileFolder: (id: number, folderId: number | null) => void;
};

const FolderDropdownOption = ({ folder, changeFolder, changeFolderFolder, changeFileFolder }: Props) => {
	const classes = new BemClass('FolderDropdownOption');

	const [{ isOver }, drop] = useDrop(() => ({
		accept: 'ALL',
		drop: (item: { id: number; isFile: boolean }) => {
			if (item.isFile) {
				changeFileFolder(item.id, folder.id);
			} else {
				changeFolderFolder(item.id, folder.id);
			}
		},
		collect: monitor => ({ isOver: !!monitor.isOver() })
	}));

	return (
		<div className={classes.mod({ isOver }).b()} ref={drop} onClick={() => changeFolder(folder.id)}>
			<Icon name="folder" />
			{folder.name}
		</div>
	);
};

export default FolderDropdownOption;

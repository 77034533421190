'use strict';

angular.module('upAttributes').service('OrderRowAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				listPrice: {
					title: 'default.id',
					field: 'listPrice',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				price: {
					title: 'default.id',
					field: 'price',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				quantity: {
					title: 'default.description',
					field: 'quantity',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					inputType: 'text',
					groupable: false,
					sortable: true,
					selectableColumn: true,
					filterable: false
				},
				product: {
					title: 'default.description',
					field: 'product',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					inputType: 'text',
					groupable: false,
					sortable: true,
					selectableColumn: true,
					filterable: false
				}
			};
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				keys: {
					ID: 'id',
					LISTPRICE: 'listPrice',
					PRICE: 'price',
					QUANTITY: 'quantity',
					PRODUCT: 'product'
				}
			};
		};
	}
]);

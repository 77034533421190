'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.campaign').controller('CampaignOrder', [
	'$scope',
	'$stateParams',
	'$upModal',
	'FilterHelper',
	'RequestBuilder',
	'Order',
	'AppService',
	function ($scope, $stateParams, $upModal, FilterHelper, RequestBuilder, Order, AppService) {
		var customerId;
		var CampaignOrderCtrl = this;
		var CampaignCtrl = $scope.CampaignCtrl;
		var currentFilter;

		CampaignOrderCtrl.error = false;
		CampaignOrderCtrl.limit = 50;
		CampaignOrderCtrl.loading = false;
		CampaignOrderCtrl.current = 1;
		CampaignOrderCtrl.pages = 0;

		$scope.$on('order.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignOrderCtrl.orders, { id: deleted.id });

			if (index !== -1) {
				CampaignOrderCtrl.orders.splice(index, 1);
			}
		});

		$scope.$on('order.updated', function (e, updated) {
			var index = _.findIndex(CampaignOrderCtrl.orders, { id: updated.id });
			if (FilterHelper.match(currentFilter.q, updated, 'order')) {
				if (index !== -1) {
					CampaignOrderCtrl.orders[index] = updated;
				} else {
					CampaignOrderCtrl.orders.push(updated);
				}
			} else if (index !== -1) {
				CampaignOrderCtrl.orders.splice(index, 1);
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (FilterHelper.match(currentFilter.q, added, 'order')) {
				CampaignOrderCtrl.orders.push(added);
			}
		});

		CampaignOrderCtrl.getOrders = function ($page) {
			CampaignOrderCtrl.error = false;
			CampaignOrderCtrl.loading = true;

			var filter = new RequestBuilder();
			filter.addFilter(Order.attr.campaign, filter.comparisonTypes.Equals, $stateParams.id);
			filter.addFilter(Order.attr.probability, filter.comparisonTypes.Equals, 100);
			filter.addSort('date', true);
			filter.limit = CampaignOrderCtrl.limit;
			filter.offset = CampaignOrderCtrl.limit * ($page - 1);
			currentFilter = filter.build();

			Order.customer(customerId)
				.find(currentFilter)
				.then(function (res) {
					CampaignOrderCtrl.loading = false;
					CampaignOrderCtrl.orders = res.data;
					CampaignCtrl.ordersTotal = res.metadata.total;
					if (CampaignOrderCtrl.ordersTotal !== 0) {
						CampaignOrderCtrl.pages = Math.ceil(CampaignCtrl.ordersTotal / CampaignOrderCtrl.limit);
					}
				})
				.catch(function () {
					CampaignOrderCtrl.loading = false;
					CampaignOrderCtrl.error = true;
				});
		};

		CampaignOrderCtrl.moveOrder = function (order) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: CampaignCtrl.moveFromCampaign,
					body: 'confirm.moveOrderFromCampaign',
					confirmButtonText: 'default.remove',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							order.project = null;
							Order.customer(customerId).save(order);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: CampaignCtrl.moveFromCampaign,
					body: 'confirm.moveOrderFromCampaign',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					order.project = null;
					Order.customer(customerId).save(order);
				});
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			CampaignOrderCtrl.getOrders(1);
		};

		AppService.loadedPromise.then(init);
	}
]);

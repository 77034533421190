'use strict';

angular.module('upResources').service('ParseNotification', function () {
	return function (notification) {
		if (notification.action.indexOf('UserDefinedObject') !== -1) {
			var action = notification.action || '';
			var s = action.indexOf('_');

			if (s === -1 && action.indexOf('multi') !== -1) {
				var metadata = Tools.AppService.getMetadata();
				var udoWithIdOne = action.slice(-1) === 't';
				var id = udoWithIdOne ? 1 : parseInt(action.slice(-1));
				var udo = _.find(metadata.params.UserDefinedObject, { id: id });

				notification.udo = udo ? udo : { name: Tools.$translate('default.userdefinedobjects').toLowerCase() };
				notification.action = !udoWithIdOne ? action.slice(0, -1) : action;
			} else {
				var name = [action.slice(0, s), action.slice(s + 1)];
				// set action for jobs..
				if (name[0].indexOf('Delete') !== -1) {
					notification.action = 'default.deleting';
				} else if (name[0].indexOf('CreateActivity') !== -1) {
					notification.action = 'automationAction.CreatingActivititiesFor';
				} else {
					notification.action = name[0];
				}
				notification.name = name[1];
			}
		}

		return notification;
	};
});

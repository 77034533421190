import React, { Component } from 'react';
import { Title, Text, Tooltip, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import './EmailSyncCard.scss';
import _ from 'lodash';
import emailRegex from '../../helpers/emailRegex';
class EmailSyncCard extends Component {
	constructor(p) {
		super(p);

		const { $translate: t } = Tools;

		this.lang = {
			title: t('integration.accountSettings.title'),
			label: t('integration.accountSettings.emailLogsLabel'),
			description: t('integration.accountSettings.emailLogsDesc'),
			tooltip: t('integration.accountSettings.emailLogsTooltip'),
			noMatch: t('default.enterEmail'),
			add: t('default.add')
		};
	}

	formatResult = (item, container, query, escape) => {
		if (!item.name) {
			return this.lang.add + ': ' + escape(item.email);
		}
		return escape(item.name);
	};

	formatSelection = (item, container, escape) => {
		return (
			escape(item.email) +
			(item.name && escape(item.name) ? '<span style="font-size:10px;"> (' + escape(item.name) + ')</span>' : '')
		);
	};

	createSearchChoice = term => {
		if (emailRegex.test(term)) {
			return { id: term, email: term };
		} else {
			return null;
		}
	};

	formatNoMatches = () => this.lang.noMatch;

	matcher = (trm, param, obj) => {
		var nameMatch = obj.name && obj.name.toLowerCase().indexOf(trm.toLowerCase()) !== -1;
		var emailMatch = obj.email && obj.email.toLowerCase().indexOf(trm.toLowerCase()) !== -1;
		return nameMatch || emailMatch;
	};

	findUsers = val => {
		var users = Tools.AppService.getActiveUsers();
		return _.reduce(
			val,
			(result, value) => {
				if (isNaN(value)) {
					result.push(value);
				} else {
					var user = _.find(users, { id: parseInt(value) });
					if (user) {
						result.push(user.email);
					}
				}
				return result;
			},
			[]
		);
	};

	onChange = e => {
		const ClientParam = getAngularModule('ClientParam');
		const values = this.findUsers(e.target.value);
		ClientParam.save(236, values.join(','));
	};

	initSelection = (element, cb) => {
		var users = Tools.AppService.getActiveUsers();
		var value = _.map(Tools.AppService.getMetadata().params.SyncLogEmails, m => {
			var user = _.find(users, { email: m });
			if (user) {
				return { id: user.email, email: user.email, name: user.name };
			}
			return { id: m, email: m };
		});
		cb(value);
	};

	render() {
		const {
			lang,
			formatResult,
			formatSelection,
			createSearchChoice,
			formatNoMatches,
			matcher,
			onChange,
			initSelection
		} = this;

		const classes = new BemClass('EmailSyncCard');

		const selectProps = {
			multiple: true,
			className: `form-control ${classes.elem('email-input').b()}`,
			data: Tools.AppService.getActiveUsers(),
			defaultValue: Tools.AppService.getMetadata().params.SyncLogEmails || [],
			formatResult,
			formatSelection,
			createSearchChoice,
			formatNoMatches,
			matcher,
			onChange,
			initSelection,
			tools: Tools
		};

		return (
			<div className={`admin-table ${classes.b()}`}>
				<div className="admin-info-row">
					<div className="info-row-info">{lang.description}</div>
					<div className="info-row-content">
						<Title>{lang.title}</Title>
						<div className={`${classes.elem('fieldset').b()}`}>
							<div className={`${classes.elem('fieldset-header').b()}`}>
								<Text bold>{lang.label}</Text>
								<Tooltip className="pull-right" position="left" distance={20} title={lang.tooltip}>
									<Icon name="question-circle" />
								</Tooltip>
							</div>
							<div className="selct2-holder">
								{React.createElement(ReactTemplates.INPUTS.upSelect, selectProps)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmailSyncCard;

import {
	ColumnType,
	RowType,
	Config,
	ProductTableColumn
} from 'Components/DocumentTemplateEditor/DocumentTemplateEditor';
import AccountProfile from 'App/resources/Model/AccountProfile';
import T from 'Components/Helpers/translate';

export const CONTAINER_WIDTH = 640;

const getJustifyContentValue = (align: string) => {
	switch (align) {
		case 'left':
			return 'flex-start';
		case 'center':
			return 'center';
		case 'right':
			return 'flex-end';
		default:
			return 'flex-start';
	}
};

export const getColumnInnerMaxWidth = (column: ColumnType) => {
	return (column.initialFixedWidth || column.width) - column.hPadding * 2;
};

export const getImageWidth = (column: ColumnType) => {
	const maxWidth = getColumnInnerMaxWidth(column);
	if (column.imgWidth && column.imgWidth > maxWidth) {
		return maxWidth;
	} else if (column.imgWidth && column.userImgWidth && column.userImgWidth > column.imgWidth) {
		return Math.min(column.userImgWidth, maxWidth);
	}

	return column.imgWidth;
};

export const getColWidthFromSize = (size: number, manualSize = false) => {
	// This condition is because size 3 and 4 columns both will return width as 173, 70/30 will break line unless we reduce 7o size
	if (size === 8 && manualSize) {
		return Math.floor((CONTAINER_WIDTH / 12) * size);
	}
	return Math.round((CONTAINER_WIDTH / 12) * size);
};

const dataStyle = (HTMLElement: any, attr: string, value?: string, selector?: any) => {
	let currentStyle: Record<string, any> = {};
	const objStyle = HTMLElement.style;

	selector = selector || 'self';
	if (objStyle) {
		try {
			currentStyle = JSON.parse(objStyle);
		} catch (e) {
			currentStyle = {};
		}
	}

	if (value === null && currentStyle[selector]) {
		delete currentStyle[selector][attr];
	} else if (value === undefined && currentStyle[selector]) {
		return currentStyle[selector][attr] || null;
	} else {
		if (!currentStyle[selector]) {
			currentStyle[selector] = {};
		}
		currentStyle[selector][attr] = value;
	}

	HTMLElement.style[attr] = value || '';
};

const generateTotalPriceRowInProductTable = (
	columnConfig: ColumnType,
	summationCell: HTMLElement,
	priceTitle: string,
	price: string,
	strikedPrice: string
) => {
	const totalPrice = document.createElement('div');
	const title = document.createElement('p');
	title.classList.add('.total');
	const strongTitle = document.createElement('strong');

	if (priceTitle === 'recurring') {
		strongTitle.textContent =
			columnConfig.ProductTableTotalPrice?.recurring ||
			T('admin.documentTemplateEditor.productTable.totalPriceRecurring.defaultValue');
	} else {
		strongTitle.textContent =
			columnConfig.ProductTableTotalPrice?.oneOff ||
			T('admin.documentTemplateEditor.productTable.totalPriceOneOff.defaultValue');
	}
	dataStyle(strongTitle, 'text-align', 'left');
	dataStyle(title, 'margin', '0');

	title.appendChild(strongTitle);
	totalPrice.appendChild(title);

	const totalPriceParagraph = document.createElement('p');
	totalPriceParagraph.classList.add('.total');
	const totalPriceStrong = document.createElement('strong');
	totalPriceStrong.textContent = price;
	dataStyle(totalPriceStrong, 'text-align', 'right');
	dataStyle(totalPriceParagraph, 'margin', '0');

	if (columnConfig.showDiscounts) {
		const striked = document.createElement('s');
		striked.textContent = strikedPrice;

		dataStyle(striked, 'text-align', 'right');
		dataStyle(striked, 'padding-right', '10px');

		totalPriceParagraph.appendChild(striked);
	}

	dataStyle(totalPrice, 'display', 'flex');
	dataStyle(totalPrice, 'justify-content', 'space-between');
	dataStyle(totalPrice, 'padding', '5px 12px 0px 0px');
	dataStyle(totalPrice, 'min-width', `${CONTAINER_WIDTH / 6}px`);
	dataStyle(totalPriceParagraph, 'padding-left', '20px');

	totalPriceParagraph.appendChild(totalPriceStrong);
	totalPrice.appendChild(totalPriceParagraph);
	summationCell.appendChild(totalPrice);
};

const generateColumnHTMLFromConfig = (columnConfig: ColumnType) => {
	const colHTML = document.createElement('div');
	const colContainer = document.createElement('div');
	let productTableBodyContent = '';

	colHTML.setAttribute('large', columnConfig.size.toString());
	dataStyle(colContainer, 'padding', `${columnConfig.vPadding}px ${columnConfig.hPadding}px`);

	if (columnConfig.enableCellBackground && columnConfig.backgroundColor) {
		dataStyle(colContainer, 'backgroundColor', columnConfig.backgroundColor);
	}

	const content = document.createElement('div');

	switch (columnConfig.type) {
		case 'IMAGE': {
			dataStyle(colContainer, 'lineHeight', '0');
			dataStyle(colContainer, 'height', '100%');
			dataStyle(colContainer, 'min-height', '48px');
			dataStyle(colContainer, 'box-sizing', 'border-box');
			dataStyle(colHTML, 'height', '100%');
			dataStyle(content, 'height', '100%');
			if (columnConfig.imgAlign) {
				content.style.textAlign = columnConfig.imgAlign;
			} else {
				content.style.textAlign = 'center';
			}

			content.style.lineHeight = '1';

			const contentLink = document.createElement('a');
			if (columnConfig.href) {
				contentLink.href = columnConfig.href;
			}
			contentLink.target = '_blank';
			contentLink.style.display = 'block';
			contentLink.style.lineHeight = '0';

			const contentImage = document.createElement('img');
			contentImage.className = 'thumbnail';

			contentLink.appendChild(contentImage);
			content.appendChild(contentLink);

			if (columnConfig.src) {
				contentImage.src = columnConfig.src;
			}
			columnConfig.imgWidth = getImageWidth(columnConfig);

			const img = content.getElementsByTagName('img')[0];
			if (columnConfig.imgWidth) {
				if (columnConfig.imgWidth > CONTAINER_WIDTH) {
					img.setAttribute('width', CONTAINER_WIDTH.toString());
				} else {
					img.setAttribute('width', columnConfig.imgWidth.toString());
				}
			}
			if (columnConfig.src) {
				img.setAttribute('src', columnConfig.src);
			}
			img.setAttribute('data-retina-ready', 'true');
			break;
		}

		case 'TEXT': {
			content.innerHTML = columnConfig.content;
			const paragraphElements = content.querySelectorAll('p, h2, h3, ol, ul');

			if (paragraphElements) {
				paragraphElements.forEach(element => {
					dataStyle(element, 'margin', '0');
					dataStyle(element, 'margin-bottom', '10px');
				});
			}

			dataStyle(content, 'overflow-wrap', 'anywhere'); // Line break if text overflows the row, identical to editor
			dataStyle(colContainer, 'height', '100%');
			dataStyle(colContainer, 'min-height', '48px');
			dataStyle(colContainer, 'box-sizing', 'border-box');
			dataStyle(colHTML, 'height', '100%');
			dataStyle(content, 'height', '100%');

			if (content.innerHTML && columnConfig.align) {
				content.style.textAlign = columnConfig.align;
			}
			break;
		}

		case 'PRODUCTTABLE': {
			const productTable = document.createElement('table');
			productTable.classList.add('product-table');
			dataStyle(productTable, 'border-collapse', 'separate');
			dataStyle(productTable, 'border-spacing', '0px 5px');
			dataStyle(productTable, 'table-layout', 'auto');
			dataStyle(productTable, 'width', `calc(${CONTAINER_WIDTH}px - ${columnConfig.hPadding * 2}px`);

			const headerRow = document.createElement('tr');
			const tableHeader = document.createElement('thead');

			if (columnConfig.ProductTableColumns) {
				// Headers for product table
				columnConfig.ProductTableColumns.forEach((productTableCol: ProductTableColumn) => {
					const headerCell = document.createElement('th');
					if (productTableCol.isActive) {
						const headerParagraph = document.createElement('p');
						const headerStrong = document.createElement('strong');
						dataStyle(headerParagraph, 'color', 'rgb(75,85,98)'); // gray-11

						headerStrong.textContent = productTableCol.name;
						headerParagraph.innerHTML = headerStrong.outerHTML;
						if (productTableCol.type === T('default.custom')) {
							dataStyle(headerCell, 'white-space', 'normal');
						} else {
							dataStyle(headerCell, 'white-space', 'nowrap');
						}
						dataStyle(headerCell, 'text-align', productTableCol.align);
						headerCell.appendChild(headerParagraph);
						dataStyle(headerCell, 'padding', '0px 12px 0px 12px');
					} else {
						headerCell.style.display = 'none';
					}

					headerRow.appendChild(headerCell);
				});

				tableHeader.appendChild(headerRow);
				productTable.appendChild(tableHeader);

				const tableBody = document.createElement('tbody');
				// Data cells for product table
				productTableBodyContent = '{{#each order.orderRow}}<tr style="min-height: 100px;">';
				columnConfig.ProductTableColumns.forEach(productTableCol => {
					const dataCell = document.createElement('td');
					if (productTableCol.isActive) {
						dataStyle(dataCell, 'vertical-align', 'center');
						dataStyle(dataCell, 'text-align', productTableCol.align);

						const dataParagraph = document.createElement('div');
						dataStyle(dataParagraph, 'display', 'inline-block');
						dataParagraph.innerHTML = productTableCol.tag;
						dataStyle(dataCell, 'padding', '10px 12px 10px 12px');

						dataStyle(dataCell, 'border-top', '1px solid lightGray');
						if (productTableCol.type === T('default.custom')) {
							dataStyle(dataCell, 'overflow-wrap', 'anywhere');
						} else {
							dataStyle(dataCell, 'overflow-wrap', 'break-word');
						}

						dataCell.appendChild(dataParagraph);
					} else {
						dataCell.style.display = 'none';
					}
					productTableBodyContent += dataCell.outerHTML;
				});

				productTableBodyContent += '</tr>{{/each}}';

				const productTableBodyPlaceHolder = '<tr class="producttablebody"></tr>';
				tableBody.innerHTML = productTableBodyPlaceHolder;

				productTable.appendChild(tableBody);
			}

			// Price summation of product table
			const summationRow = document.createElement('div');
			const summationCell = document.createElement('div');
			summationRow.classList.add('product-table');
			dataStyle(summationRow, 'border-top', '1px solid black');
			dataStyle(summationRow, 'display', 'flex');
			dataStyle(summationRow, 'justify-content', 'flex-end');
			dataStyle(summationCell, 'text-align', 'right');
			dataStyle(summationCell, 'white-space', 'nowrap');
			dataStyle(summationCell, 'padding-top', '10px');

			if (columnConfig.showPriceSummation) {
				const foundOneOffPrice = columnConfig.ProductTableColumns?.find(
					(prodCol: ProductTableColumn) =>
						prodCol.title === T('admin.documentTemplateEditor.productTable.priceOneOff')
				);
				const foundRecurring = columnConfig.ProductTableColumns?.find(
					(prodCol: ProductTableColumn) =>
						prodCol.title === T('admin.documentTemplateEditor.productTable.priceRecurring')
				);
				if (foundRecurring && foundRecurring.isActive) {
					// Generate recurring
					generateTotalPriceRowInProductTable(
						columnConfig,
						summationCell,
						'recurring',
						"{{currencyFormatter (math (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'include') '*' order.currencyRate) order.currency}}",
						"{{#if (isDiscount (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'include') (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'include'))}}{{currencyFormatter (math (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'include') '*' order.currencyRate) order.currency}}{{/if}}"
					);
				}
				if (foundOneOffPrice && foundOneOffPrice.isActive) {
					// Generate one-off
					generateTotalPriceRowInProductTable(
						columnConfig,
						summationCell,
						'one-off',
						"{{currencyFormatter (math (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'exclude') '*' order.currencyRate) order.currency}}",
						"{{#if (isDiscount (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'exclude') (sumOrderPrice order.orderRow 'price' 'product.isRecurring' 'exclude'))}}{{currencyFormatter (math (sumOrderPrice order.orderRow 'listPrice' 'product.isRecurring' 'exclude') '*' order.currencyRate) order.currency}}{{/if}}"
					);
				}
			}
			summationRow.appendChild(summationCell);
			content.innerHTML = productTable.outerHTML + summationRow.outerHTML;
			break;
		}

		case 'EMPTY': {
			dataStyle(colContainer, 'padding-y', `${columnConfig.vPadding}px`);
			dataStyle(colContainer, 'height', '100%');
			dataStyle(colContainer, 'min-height', '60px');
			break;
		}
	}

	// create caption element
	if (['IMAGE'].indexOf(columnConfig.type) !== -1) {
		let captionElem = content.querySelectorAll('.caption')[0];
		if (columnConfig.showCaption) {
			captionElem = document.createElement('div');
			if (columnConfig.caption) {
				captionElem.innerHTML = columnConfig.caption;
				if (columnConfig.captionAlign) {
					dataStyle(captionElem, 'textAlign', columnConfig.captionAlign);
				}
			}
			content.append(captionElem);
		}
	}

	if (content) {
		colContainer.append(content);
	}

	colHTML.append(colContainer);
	return { colHTML, productTableBodyContent };
};

const generateRowHTMLFromConfig = (rowConfig: RowType) => {
	const rowHTML = document.createElement('div');

	dataStyle(rowHTML, 'background-color', rowConfig.backgroundColor);
	dataStyle(rowHTML, 'display', 'flex');
	dataStyle(rowHTML, 'justify-content', 'center');
	dataStyle(rowHTML, 'page-break-inside', 'auto');
	dataStyle(rowHTML, 'margin', '0 auto');

	if (rowConfig.fullWidth) {
		dataStyle(rowHTML, 'width', `100%`);
	} else {
		dataStyle(rowHTML, 'width', `${CONTAINER_WIDTH}px`);
		dataStyle(rowHTML, 'background-color', rowConfig.backgroundColor);
	}
	Object.assign(rowHTML.style, rowConfig.style);

	let productTableColumnBodyContent = '';
	if (rowConfig.columns && rowConfig.columns.length > 0) {
		const hasEmptyColumns = rowConfig.columns.some((column: ColumnType) => column.type === 'EMPTY');
		rowConfig.columns.forEach((column: ColumnType) => {
			const colContainer = document.createElement('div');
			const columnWidth = getColWidthFromSize(column.size).toString() + 'px';
			dataStyle(colContainer, 'width', columnWidth);

			if (hasEmptyColumns) {
				dataStyle(rowHTML, 'min-height', '60px');
				dataStyle(colContainer, 'min-height', '60px');
			} else {
				dataStyle(rowHTML, 'min-height', '40px');
				dataStyle(colContainer, 'min-height', '40px');
			}
			const { colHTML, productTableBodyContent } = generateColumnHTMLFromConfig(column);
			if (column.type === 'TEXT') {
				rowHTML.classList.add('text-row');
			}
			if (productTableBodyContent) {
				productTableColumnBodyContent = productTableBodyContent;
			}
			colContainer.append(colHTML);
			rowHTML.append(colContainer);
		});
	}
	return { rowHTML, productTableColumnBodyContent };
};

const getHTMLRootElem = (config: Config) => {
	const root = document.createElement('div');
	dataStyle(root, 'display', 'flex');
	dataStyle(root, 'flex-direction', 'column');
	dataStyle(root, 'align-items', 'center');
	dataStyle(root, 'width', `100%`);
	dataStyle(root, 'background-color', config.bodyBg);
	return root;
};

const createPrintContainer = (rootTable: HTMLElement) => {
	const rootPrintContainer = document.createElement('table');
	rootPrintContainer.classList.add('print-container');
	dataStyle(rootPrintContainer, 'width', '100%');
	dataStyle(rootPrintContainer, 'margin', '0 auto');

	//Header part of the print container
	const rootPrintContainerHeader = document.createElement('thead');
	const rootPrintContainerHeaderRow = document.createElement('tr');
	const rootPrintContainerHeaderCell = document.createElement('th');
	rootPrintContainerHeaderCell.classList.add('root-print-header');

	const rootPrintContainerHeaderCellHTML = document.createElement('header');
	rootPrintContainerHeaderCellHTML.classList.add('root-print-header-content');

	rootPrintContainerHeaderCell.appendChild(rootPrintContainerHeaderCellHTML);
	rootPrintContainerHeaderRow.appendChild(rootPrintContainerHeaderCell);
	rootPrintContainerHeader.appendChild(rootPrintContainerHeaderRow);
	rootPrintContainer.appendChild(rootPrintContainerHeader);

	//Body part of the print container
	const rootPrintContainerBody = document.createElement('tbody');
	const rootPrintContainerBodyRow = document.createElement('tr');
	const rootPrintContainerBodyCell = document.createElement('td');
	rootPrintContainerBodyCell.classList.add('root-print-body');

	rootPrintContainerBodyCell.appendChild(rootTable);
	rootPrintContainerBodyRow.appendChild(rootPrintContainerBodyCell);
	rootPrintContainerBody.appendChild(rootPrintContainerBodyRow);
	rootPrintContainer.appendChild(rootPrintContainerBody);

	// Footer part of the print container
	const rootPrintContainerFooter = document.createElement('tfoot');
	const rootPrintContainerFooterRow = document.createElement('tr');
	const rootPrintContainerFooterCell = document.createElement('td');
	rootPrintContainerFooterCell.classList.add('root-print-footer');

	const rootPrintContainerFooterContent = document.createElement('div');
	rootPrintContainerFooterContent.classList.add('root-print-footer-content');

	rootPrintContainerFooterCell.appendChild(rootPrintContainerFooterContent);
	rootPrintContainerFooterRow.appendChild(rootPrintContainerFooterCell);
	rootPrintContainerFooter.appendChild(rootPrintContainerFooterRow);
	rootPrintContainer.appendChild(rootPrintContainerFooter);

	return rootPrintContainer;
};

const createHeader = (config: Config, accountProfile: AccountProfile) => {
	const headerHTML = document.createElement('header');
	headerHTML.classList.add('header-content');
	const headerContent = document.createElement('div');

	dataStyle(headerHTML, 'display', 'flex');
	dataStyle(headerHTML, 'justify-content', getJustifyContentValue(config.headerLogoAlign));
	dataStyle(headerContent, 'width', `${CONTAINER_WIDTH}px`);
	dataStyle(headerContent, 'margin', `0 auto`);

	if (config.headerMargin && config.headerMargin > 0) {
		dataStyle(headerHTML, 'margin-bottom', config.headerMargin.toString());
	}

	if (config.fullWidthHeader) {
		dataStyle(headerHTML, 'background-color', config.headerBg);
		headerHTML.style.width = '100%';
	} else {
		dataStyle(headerHTML, 'background-color', config.headerBg);
		dataStyle(headerHTML, 'width', `${CONTAINER_WIDTH - 20}px`);
		dataStyle(headerHTML, 'padding-right', `${10}px`);
		dataStyle(headerHTML, 'padding-left', `${10}px`);
	}

	dataStyle(headerHTML, 'padding-y', '10px');
	dataStyle(headerHTML, 'min-height', `40px`);

	const { headerLogo, logoType } = config;

	let headerImageLink = '';

	if (headerLogo) {
		if (logoType === 'url') {
			headerImageLink = headerLogo;
		} else if (logoType === 'logo') {
			if (headerLogo === '__brightLogo__') {
				headerImageLink = accountProfile.logo;
			} else if (headerLogo === '__darkLogo__') {
				headerImageLink = accountProfile.darkLogo;
			}
		}
	}

	const headerLink = document.createElement('a');
	headerLink.href = config.headerLink;
	headerLink.target = '_blank';
	headerLink.style.display = 'block';
	headerLink.style.lineHeight = '0';
	headerLink.style.textAlign = config.headerLogoAlign;

	const headerImage = document.createElement('img');
	headerImage.className = 'thumbnail';

	headerLink.appendChild(headerImage);
	headerContent.appendChild(headerLink);

	headerImage.className = 'thumbnail';
	headerImage.src = headerImageLink;

	const img = headerContent.getElementsByTagName('img')[0];

	if (config.headerLink) {
		img.setAttribute('src', headerImageLink);
	}
	img.style.maxHeight = '40px';
	img.setAttribute('data-retina-ready', 'true');
	headerHTML.appendChild(headerContent);
	return headerHTML;
};

const createFooter = (config: Config, accountProfile: AccountProfile) => {
	const footerHTML = document.createElement('footer');
	const footerContent = document.createElement('div');
	dataStyle(footerContent, 'display', 'flex');
	dataStyle(footerContent, 'flex-direction', 'row');
	dataStyle(footerContent, 'justify-content', 'space-between');
	dataStyle(footerContent, 'width', `${CONTAINER_WIDTH}px`);
	dataStyle(footerContent, 'padding', `${10}px ${10}px`);
	if (config.footerMargin) {
		dataStyle(footerContent, 'padding-top', `${config.footerMargin + 10}px`);
	}

	const leftColumn = document.createElement('div');
	const rightColumn = document.createElement('div');

	if (accountProfile.name) {
		const nameElement = document.createElement('strong');
		nameElement.innerHTML = `${accountProfile.name}<br />`;
		leftColumn.appendChild(nameElement);
	}
	if (accountProfile.address) {
		const addressElement = document.createElement('span');
		addressElement.innerHTML = `${accountProfile.address}<br />`;
		leftColumn.appendChild(addressElement);
	}
	if (accountProfile.zipcode || accountProfile.city) {
		const cityElement = document.createElement('span');
		cityElement.innerHTML = `${accountProfile.zipcode || ''} ${accountProfile.city || ''}<br />`;
		leftColumn.appendChild(cityElement);
	}
	const countryElement = document.createElement('span');
	countryElement.innerHTML = `${accountProfile.country || ''}`;

	const emailLink = document.createElement('a');
	emailLink.innerHTML = accountProfile.email || '';
	emailLink.href = 'mailto:' + accountProfile.email;
	emailLink.style.color = config.footerLinkTextColor;

	const phoneLink = document.createElement('a');
	phoneLink.innerHTML = accountProfile.phone || '';
	phoneLink.href = 'tel:' + accountProfile.phone;
	phoneLink.style.color = config.footerLinkTextColor;

	leftColumn.appendChild(countryElement);
	leftColumn.style.color = config.footerTextColor;
	rightColumn.appendChild(emailLink);
	rightColumn.appendChild(document.createElement('br'));
	rightColumn.appendChild(phoneLink);

	footerContent.appendChild(leftColumn);
	footerContent.appendChild(rightColumn);
	footerHTML.appendChild(footerContent);
	return footerHTML;
};

export const generateHTMLFromConfig = (config: Config, accountProfile: AccountProfile) => {
	const root = getHTMLRootElem(config);

	const rootTable = document.createElement('table');
	dataStyle(rootTable, 'width', '100%');
	dataStyle(rootTable, 'margin', '0 auto');

	rootTable.classList.add('root-table');

	if (config.hasPageMargin) {
		const rootPrintContainer = createPrintContainer(rootTable);
		root.appendChild(rootPrintContainer);
	} else {
		root.appendChild(rootTable);
	}

	// Add header
	if (config.hasHeader) {
		const headerHTML = createHeader(config, accountProfile);
		// Create table header placeholder
		const tableHead = document.createElement('thead');
		const tableHeadRow = document.createElement('tr');
		const tableHeaderCell = document.createElement('th');
		tableHeaderCell.classList.add('header-container');
		rootTable.appendChild(tableHead);
		tableHead.appendChild(tableHeadRow);
		tableHeadRow.appendChild(tableHeaderCell);
		tableHeaderCell.appendChild(headerHTML);
	}

	// Create table body
	const tableBody = document.createElement('tbody');
	const tableBodyRow = document.createElement('tr');
	const tableBodyCell = document.createElement('td');
	tableBodyCell.classList.add('tbody-container');
	let productTableRowBodyContent = '';

	config.rows.forEach((row: RowType) => {
		const { rowHTML, productTableColumnBodyContent } = generateRowHTMLFromConfig(row);
		if (productTableColumnBodyContent) {
			productTableRowBodyContent = productTableColumnBodyContent;
		}
		tableBodyCell.appendChild(rowHTML);
	});

	rootTable.appendChild(tableBody);
	tableBody.appendChild(tableBodyRow);
	tableBodyRow.appendChild(tableBodyCell);

	// Add footer
	if (config.hasFooter) {
		const footerHTML = createFooter(config, accountProfile);
		root.append(footerHTML);
		// Create table footer placeholder
		const tableFooter = document.createElement('tfoot');
		const tableFooterRow = document.createElement('tr');
		const tableFooterCell = document.createElement('td');
		const tableFooterPageHeaderSpace = document.createElement('div');

		tableFooterPageHeaderSpace.classList.add('page-footer-space');

		rootTable.appendChild(tableFooter);
		tableFooter.appendChild(tableFooterRow);
		tableFooterRow.appendChild(tableFooterCell);
		tableFooterCell.appendChild(tableFooterPageHeaderSpace);
	}

	let templateHTML = root.outerHTML;
	templateHTML = templateHTML.replace(/<tr class="producttablebody"><\/tr>/g, productTableRowBodyContent);
	return templateHTML;
};

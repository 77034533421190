'use strict';

angular.module('upDirectives').directive('upToggle', function () {
	var toggleCheck = function (scope, elem, model, trueValue, falseValue) {
		if (elem[0].disabled) {
			return;
		}

		if (model) {
			var setValue = !elem[0].checked ? trueValue : falseValue;
			model.$setViewValue(!elem[0].checked);
			model.$modelValue = setValue;
			elem[0].checked = setValue === trueValue;
			scope.$apply();
		} else {
			elem[0].checked = !elem[0].checked;
		}
	};

	return {
		restrict: 'A',
		require: '?ngModel',
		template: '<div class="up-toggle"></div>',
		link: function ($scope, $checkbox, $attr, ngModel) {
			var classes = {
				hiddenCheckbox: 'up-toggle-hidden-checkbox',
				checked: 'checked',
				wrapper: 'up-toggle',
				check: 'toggle-check'
			};

			var copyClasses = $checkbox[0].className;
			var trueValue = true;
			var falseValue = false;

			// Hide the original checkbox
			$checkbox.addClass(classes.hiddenCheckbox);

			var wrapper = angular.element('<div tabindex="0"/>');
			wrapper.addClass(copyClasses);
			wrapper.addClass(classes.wrapper);
			var check = angular.element('<div class="fa fa-check" />');
			check.addClass(classes.check);

			// add id
			var id = $checkbox.attr('id');
			if (id) {
				wrapper.attr('id', 't_' + id);
			}

			wrapper.append(check);
			$checkbox.after(wrapper);

			wrapper.on('click', function () {
				if ($checkbox.parent().is('label')) {
					return;
				}
				toggleCheck($scope, $checkbox, ngModel, trueValue, falseValue);
			});

			wrapper.on('keydown', function (e) {
				// Toggle on space
				if (e.keyCode === 32) {
					e.preventDefault();
					toggleCheck($scope, $checkbox, ngModel, trueValue, falseValue);
				}
			});

			if (ngModel) {
				if ($attr.ngTrueValue) {
					trueValue = $attr.ngTrueValue;
				}

				if ($attr.ngFalseValue) {
					falseValue = $attr.ngFalseValue;
				}
				$scope.$watch($attr.ngModel, function (value) {
					if (
						value !== undefined &&
						typeof value !== 'boolean' &&
						!($attr.ngTrueValue || $attr.ngFalseValue)
					) {
						ngModel.$setViewValue(Boolean(parseInt(value)));
						$checkbox[0].checked = ngModel.$viewValue;
					}
				});
			}
		}
	};
});

angular.module('upDirectives').directive('upIosToggle', function () {
	var toggleCheck = function (scope, elem, model, trueValue, falseValue) {
		if (elem[0].disabled) {
			return;
		}

		if (model) {
			var setValue = !elem[0].checked ? trueValue : falseValue;
			model.$setViewValue(!elem[0].checked);
			model.$modelValue = setValue;
			elem[0].checked = setValue === trueValue;
			scope.$apply();
		} else {
			elem[0].checked = !elem[0].checked;
		}
	};

	return {
		restrict: 'A',
		require: 'ngModel',
		template: '<div class="up-ios-toggle"><div class="toggle-handle"></div></div>',
		link: function ($scope, $checkbox, $attr, ngModel) {
			var classes = {
				hiddenCheckbox: 'up-ios-toggle-hidden-checkbox',
				checked: 'checked',
				wrapper: 'up-ios-toggle',
				handle: 'toggle-handle'
			};

			var copyClasses = $checkbox[0].className;
			var trueValue = true;
			var falseValue = false;

			// Hide the original checkbox
			$checkbox.addClass(classes.hiddenCheckbox);

			var wrapper = angular.element('<div tabindex="0"/>');
			wrapper.addClass(copyClasses);
			wrapper.addClass(classes.wrapper);
			var handle = angular.element('<div/>');
			handle.addClass(classes.handle);

			// add id
			var id = $checkbox.attr('id');
			if (id) {
				wrapper.attr('id', 't_' + id);
			}

			wrapper.append(handle);
			$checkbox.after(wrapper);

			wrapper.on('click', function () {
				if ($checkbox.parent().is('label')) {
					return;
				}
				toggleCheck($scope, $checkbox, ngModel, trueValue, falseValue);
			});

			wrapper.on('keydown', function (e) {
				// Toggle on space
				if (e.keyCode === 32) {
					e.preventDefault();
					toggleCheck($scope, $checkbox, ngModel, trueValue, falseValue);
				}
			});

			if (ngModel) {
				if ($attr.ngTrueValue) {
					trueValue = $attr.ngTrueValue;
				}

				if ($attr.ngFalseValue) {
					falseValue = $attr.ngFalseValue;
				}
				$scope.$watch($attr.ngModel, function (value) {
					if (
						value !== undefined &&
						typeof value !== 'boolean' &&
						!($attr.ngTrueValue || $attr.ngFalseValue)
					) {
						ngModel.$setViewValue(Boolean(parseInt(value)));
						$checkbox[0].checked = ngModel.$viewValue;
					}
				});
			}
		}
	};
});

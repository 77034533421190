import InAppChat from 'App/babel/services/InAppChat';

angular.module('domain.onboarding').controller('ProductPlaceholderCtrl', [
	'AppService',
	'$translate',
	'product',
	'$scope',
	function (AppService, $translate, product, $scope) {
		var renderTimeout = null;
		var element = document.getElementById('product-placeholder-root');
		var accountManager;
		var iframeUrl = 'https://www.upsales.com/';
		var hasChat = InAppChat.isEnabledAndLoaded();

		var tools = {
			$translate: $translate
		};

		var render = function () {
			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			renderTimeout = setTimeout(function () {
				if (!element) {
					return;
				}

				ReactDOM.render(
					React.createElement(ReactTemplates.onboarding.productPlaceholder, {
						accountManager: accountManager,
						iframeUrl: iframeUrl,
						hasChat: hasChat,
						tools: tools
					}),
					element
				);
			}, 20);
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(element);
			element = undefined;
		});

		var init = function () {
			var accountSelf = AppService.getAccountSelf();
			var self = AppService.getSelf();
			if (self.language !== 'sv-SE') {
				iframeUrl += 'en/';
			}

			switch (product) {
				case 'market':
					iframeUrl += 'marketing-automation/iframe';
					break;
				case 'crm':
					iframeUrl += 'platform/sales/crm/iframe';
					break;
				default:
					iframeUrl = null;
			}
			accountManager = accountSelf.accountManager;
			render();
		};

		AppService.loadedPromise.then(init);
	}
]);

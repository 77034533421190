import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeView, setSelectedItem, updateUser, views } from 'Store/reducers/AssignModalReducer';
import { TableRow, TableColumn, Tooltip, Icon, Button, Avatar, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './AssignModalActivityRow.scss';

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
	setSelectedItem,
	changeView,
	updateUser
};

const openAppointment = item => {
	Tools.$upModal.open('editAppointment', { id: item.id });
};

const openActivity = item => {
	Tools.$upModal.open('editActivity', { id: item.id });
};

const getDateString = item => {
	let dateString;
	if (item.isAppointment) {
		dateString =
			moment(item.date).tz(window.userTimezone).format('YYYY-MM-DD HH:mm') +
			' - ' +
			moment(item.endDate).tz(window.userTimezone).format('HH:mm');
	} else if (item.time) {
		dateString = moment(item.date).tz(window.userTimezone).format('YYYY-MM-DD HH:mm');
	} else {
		dateString = moment(item.date).tz(window.userTimezone).format('YYYY-MM-DD');
	}
	return dateString !== 'Invalid date' ? dateString : '';
};

const isClosedOrPassed = item => {
	if (item.isAppointment) {
		return moment().isAfter(item.endDate);
	} else {
		return !!item.closeDate;
	}
};

class AssignModalActivityRow extends React.Component {
	constructor(props) {
		super(props);
		const t = window.Tools.$translate;

		this.lang = {
			chooseUser: t('assign.chooseSeller'),
			inactive: t('default.inactive'),
			closeActivity: t('default.closeActivity')
		};

		this.state = {
			editUserOpen: false
		};

		this.onActivityClick = () =>
			props.item.isAppointment ? openAppointment(props.item) : openActivity(props.item);

		this.activeUsers = window.Tools.AppService.getActiveUsers();

		this.closeActivity = this.closeActivity.bind(this);
		this.updateUser = this.updateUser.bind(this);
	}
	closeActivity(e) {
		e.stopPropagation();
		this.props.setSelectedItem(this.props.item);
		this.props.changeView(views.CLOSEACTIVITY);
	}
	toggleEditUserOpen() {
		this.setState(function (state) {
			return {
				editUserOpen: !state.editUserOpen
			};
		});
	}
	componentDidUpdate(prevProps, prevState) {
		var item = this.props.item;

		if (this.state.editUserOpen && !prevState.editUserOpen) {
			$('#pb-role-input-' + item.id).select2('open');
		}
	}
	updateUser(users) {
		this.props.updateUser(this.props.item, users);
	}
	getSingleUserSelect(item) {
		var self = this;
		var props = this.props;
		var state = this.state;

		var fromSelect = false;

		/*
			Why not only have onClose? onClose was run before onChange and issued a new
			render before onChange could update the props. And in that render componentDidUpdate was
			called in the user select and overwrote the newly selected value with the old props.
		*/

		var eventListeners = {
			'select2-selecting': function () {
				fromSelect = true;
				self.toggleEditUserOpen();
			},
			'select2-close': function () {
				if (fromSelect) {
					fromSelect = false;
				} else {
					self.toggleEditUserOpen();
				}
			}
		};

		return (
			<div key={item.id + '-user-select'} style={{ display: state.editUserOpen ? 'block' : 'none' }}>
				<ReactTemplates.INPUTS.upUsers
					id={'pb-role-input-' + item.id}
					key={item.id + '-user-select'}
					data={props.assignableUsers}
					value={item.users[0]}
					required={true}
					placeholder={this.lang.chooseUser}
					onChange={this.updateUser}
					eventListeners={eventListeners}
				/>
			</div>
		);
	}
	getSingleUserRow(item) {
		const state = this.state;
		const lang = this.lang;

		const user = item.users[0];
		const active = _.find(this.activeUsers, { id: user.id }) !== undefined;
		const wrapClasses = new BemClass('AssignModalActivityRow__user').mod(!active ? 'inactive' : null);

		return (
			<div
				className={wrapClasses}
				onClick={e => {
					e.stopPropagation();
					this.toggleEditUserOpen();
				}}
				key={item.id + '-user-column'}
				style={{ display: !state.editUserOpen ? 'block' : 'none' }}
			>
				<Avatar key={user.id} email={user.email} initials={user.name} size="sm" />
				<div className={wrapClasses.elem('name')}>
					{!active ? (
						<Tooltip title={lang.inactive} position="left">
							{user.name}
						</Tooltip>
					) : (
						user.name
					)}
				</div>
				<Icon name="caret-down" />
			</div>
		);
	}
	getMultiUserSelect(item) {
		var self = this;
		var props = this.props;
		var state = this.state;

		var timeoutVariable;

		var eventListeners = {
			'select2-blur': function () {
				timeoutVariable = setTimeout(function () {
					self.toggleEditUserOpen();
				}, 200);
			},
			'select2-selecting': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-opening': function () {
				clearTimeout(timeoutVariable);
			},
			'select2-focus': function () {
				clearTimeout(timeoutVariable);
			}
		};

		return (
			<div
				className="pb-multiple-user-column-selectwrap"
				key={item.id + '-user-select'}
				style={{ display: state.editUserOpen ? 'block' : 'none' }}
			>
				<ReactTemplates.INPUTS.upUsers
					id={'pb-role-input-' + item.id}
					key={item.id + '-user-select'}
					data={props.assignableUsers}
					value={item.users}
					required={true}
					multiple={true}
					onChange={this.updateUser}
					onBlur={this.toggleEditUserOpen}
					eventListeners={eventListeners}
				/>
			</div>
		);
	}
	getMultiUserRow(item) {
		const state = this.state;
		const lang = this.lang;

		const wrapClasses = new BemClass('AssignModalActivityRow__user');

		return (
			<div
				className={wrapClasses}
				onClick={e => {
					e.stopPropagation();
					this.toggleEditUserOpen();
				}}
				key={item.id + '-user-column'}
				style={{ display: !state.editUserOpen ? 'block' : 'none' }}
			>
				{_.map(item.users, user => {
					var active = _.find(this.activeUsers, { id: user.id }) !== undefined;

					return (
						<Avatar
							email={user.email}
							initials={user.name}
							size="sm"
							key={user.id}
							className={wrapClasses.elem('multi-avatar').mod(!active ? 'inactive-user' : null)}
						/>
					);
				})}
				{item.users.length === 0 ? (
					<Text color="grey" size="sm" italic>
						{lang.chooseUser}
					</Text>
				) : null}
				<Icon name="caret-down" />
			</div>
		);
	}
	getUserColumn(item) {
		var select = item.isAppointment ? this.getMultiUserSelect(item) : this.getSingleUserSelect(item);
		var row = item.users.length === 1 ? this.getSingleUserRow(item) : this.getMultiUserRow(item);

		var content = [select, row];

		return (
			<TableColumn data-test-id="processedby-modal-user-column" style={{ cursor: 'pointer' }}>
				{content}
			</TableColumn>
		);
	}
	render() {
		const { item } = this.props;
		const canClose = !item.isAppointment && !isClosedOrPassed(item);

		const classNames = new BemClass('AssignModalActivityRow');

		if (isClosedOrPassed(item)) {
			classNames.mod('closed');
		}

		return (
			<TableRow
				data-test-id="processedby-modal-activity-row"
				key={item.id}
				className={classNames.b()}
				onClick={this.onActivityClick}
			>
				<TableColumn
					data-test-id="processedby-modal-desciption-column"
					key={'description-column-' + item.id}
					icon={item.isAppointment ? 'calendar' : 'activity'}
					title={item.description}
					subtitle={getDateString(item)}
				/>
				{this.getUserColumn(item)}
				{canClose ? (
					<TableColumn
						className={classNames.elem('close-col').b()}
						data-test-id="processedby-modal-close-column"
						key="close-column"
					>
						<Tooltip title={this.lang.closeActivity} position="right">
							<Button color="grey" type="link" onClick={this.closeActivity}>
								<Icon name="check-square-o" />
							</Button>
						</Tooltip>
					</TableColumn>
				) : (
					<TableColumn />
				)}
			</TableRow>
		);
	}
}

AssignModalActivityRow.propTypes = {
	item: PropTypes.object,
	setSelectedItem: PropTypes.func,
	changeView: PropTypes.func,
	updateUser: PropTypes.func
};

export const detached = AssignModalActivityRow;
const Component = connect(mapStateToProps, mapDispatchToProps)(AssignModalActivityRow);
export default Component;

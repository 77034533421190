'use strict';

angular.module('domain.mail').factory('SentMailMeta', [
	'$q',
	'MailAccount',
	'User',
	'RequestBuilder',
	'MailTemplate',
	'MailCampaign',
	'Mail',
	'AppService',
	function ($q, MailAccount, User, RequestBuilder, MailTemplate, MailCampaign, Mail, AppService) {
		return function ($modalParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = AppService.getCustomerId();
				var mail;

				if ($modalParams.mail && $modalParams.mail.id) {
					// get fresh mail data
					$modalParams.id = $modalParams.mail.id;
				}

				if ($modalParams.id) {
					mail = Mail.customer(customerId).get($modalParams.id);
				} else if ($modalParams.mailCampaignId) {
					mail = MailCampaign.get({ customerId: customerId, id: $modalParams.mailCampaignId }).$promise;
				} else {
					mail = $q.when({ data: $modalParams.mail });
				}

				return mail.then(function (mail) {
					var mailAccountPromise = $q.defer();

					MailAccount.customer(customerId)
						.get()
						.then(function (data) {
							mailAccountPromise.resolve(data);
						})
						.catch(function (err) {
							if (err.status === 404) {
								mailAccountPromise.resolve({ data: null });
							} else {
								mailAccountPromise.reject(err);
							}
						});

					var promises = {
						mailAccount: mailAccountPromise
					};

					promises.user = $q.when({ data: _.filter(AppService.getActiveUsers(), { email: mail.data.from }) });

					if (mail.data.template) {
						promises.template = MailTemplate.customer(customerId)
							.get(mail.data.template.id)
							.catch(function () {
								// return false so we can open the email even if the template is removed
								return $q.when({ data: 'removed' });
							});
					} else {
						promises.template = { data: false };
					}

					return $q.all(promises).then(function (results) {
						results.mail = mail.data;
						return results;
					});
				});
			});
		};
	}
]);

'use strict';

angular.module('upResources').factory('Session', [
	'$resource',
	'$http',
	'$q',
	'URL',
	'API',
	'AppService',
	function SessionService($resource, $http, $q, URL, API, AppService) {
		var Session = $resource(URL + API + 'session', {});
		var TwoFASession = $resource(URL + API + 'session/twoFactorKey', {});
		var LoginMethod = $resource(URL + API + 'loginMethod', {});
		LoginMethod.delete = function (body, onSuccess, onError) {
			$http({
				method: 'DELETE',
				url: URL + API + 'loginMethod',
				data: body,
				headers: { 'Content-Type': 'application/json;charset=utf-8' }
			})
				.then(onSuccess, onError)
				.catch(onError);
		};

		return {
			login: function (credentials) {
				return Session.save(credentials).$promise;
			},
			loginTwoFA: function (credentials) {
				return TwoFASession.save(credentials).$promise;
			},
			logout: function () {
				var deferred = $q.defer();

				var self = AppService.getSelf();
				if (!self || !self.email) {
					Session.delete(
						{},
						function () {
							deferred.resolve(true);
						},
						function (response) {
							var reason = response.status;
							deferred.reject(reason);
						}
					);
				} else {
					LoginMethod.delete(
						{ email: self.email },
						function ({ data: response }) {
							Session.delete(
								{},
								function () {
									deferred.resolve(true);
								},
								function (response) {
									var reason = response.status;
									deferred.reject(reason);
								}
							);
							if (response.method === 'saml') {
								window.location = response.url;
							}
						},
						function (response) {
							var reason = response.status;
							deferred.reject(reason);
						}
					);
				}

				return deferred.promise;
			},
			getLoginMethod: function (email, samlBypass, noRedirect = false) {
				var deferred = $q.defer();

				LoginMethod.save(
					{ email: email, samlBypass: samlBypass },
					function (response) {
						if (response.method === 'saml' && !noRedirect) {
							window.location = response.url;
						}

						deferred.resolve(response);
					},
					function (response) {
						var reason = response.status;
						deferred.reject(reason);
					}
				);

				return deferred.promise;
			}
		};
	}
]);

'use strict';

angular.module('domain.account').factory('FormMeta', [
	'$q',
	'Form',
	'AppService',
	'AccountProfile',
	'OptIn',
	function ($q, Form, AppService, AccountProfile, OptIn) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				if (!$stateParams.id) {
					return $q.reject();
				}

				var promises = {
					form: Form.get($stateParams.id),
					users: $q.when({ data: AppService.getActiveUsers() }),
					accountProfile: AccountProfile.get(),
					optIns: OptIn.find(),
					domains: Tools.Domains.find()
				};

				return $q.all(promises);
			});
		};
	}
]);

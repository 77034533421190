import React, { ComponentProps, useState, useMemo, useEffect } from 'react';
import { Block, Icon, Text, Expandable, AssistChip, Button } from '@upsales/components';
import T from 'Components/Helpers/translate';
import FeatureContract, { Contract } from 'App/babel/resources/FeatureContract';
import logError from 'App/babel/helpers/logError';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import { Fade } from 'App/components/animations/index';

import './AcceptContract.scss';

type BlockProps = ComponentProps<typeof Block>;

type Props = BlockProps & {
	name: string;
	title: string;
	showBetaChip?: boolean;
	canAccept: boolean;
	canNotAcceptText?: string;
	onAccept?: () => void | Promise<void>;
	onDecline?: () => void | Promise<void>;
};

const AcceptContract = (props: Props) => {
	const {
		name,
		title,
		showBetaChip = false,
		canAccept,
		canNotAcceptText = '',
		onAccept,
		onDecline,
		...blockProps
	} = props;
	const classes = new BemClass('AcceptContract');

	const lang = useMemo(
		() => ({
			beta: T('acceptContract.beta'),
			accept: T('acceptContract.accept'),
			decline: T('acceptContract.decline')
		}),
		[]
	);

	const [expanded, setExpanded] = useState(false);
	const [saving, setSaving] = useState(false);
	const [contract, setContract] = useState<Contract | null>(null);
	const promises: CancelablePromise[] = [];

	async function loadContract() {
		const cancelablePromises = makeCancelable(FeatureContract.find({ name }));
		promises.push(cancelablePromises);
		cancelablePromises.promise
			.then(response => {
				const {
					data: [contract]
				} = response;
				setContract(contract);
			})
			.catch(error => {
				console.error(error);
				logError(error);
			});
	}

	function decline() {
		setExpanded(false);
		onDecline?.();
	}

	async function accept() {
		if (contract !== null) {
			setSaving(true);
			const cancelablePromises = makeCancelable(FeatureContract.accept(contract));
			promises.push(cancelablePromises);
			cancelablePromises.promise
				.then(() => onAccept?.())
				.then(() => {
					setSaving(false);
					setExpanded(false);
				})
				.catch(error => {
					console.error(error);
					logError(error);
				});
		}
	}

	useEffect(() => {
		loadContract();
		return () => promises.forEach(promise => promise.cancel());
	}, []);

	const titleComponent = (
		<Block className={classes.elem('header').b()}>
			{showBetaChip ? <AssistChip title={lang.beta} type="purple" space="mrl" /> : null}
			<Fade visible={!expanded}>
				<Text bold={true}>{title}</Text>
			</Fade>
		</Block>
	);

	return (
		<Block {...blockProps}>
			<Expandable
				className={classes.b()}
				setExpanded={setExpanded}
				expanded={expanded}
				expandedHeight={300}
				fade={true}
				title={titleComponent}
			>
				{canAccept ? (
					<Block className={classes.elem('content').b()}>
						<Block>
							{contract ? (
								<p>{contract.body}</p>
							) : (
								<>
									<Text loading={true} />
									<br />
									<Text loading={true} />
									<br />
									<Text loading={true} />
									<br />
								</>
							)}
						</Block>
						<Block className={classes.elem('footer').b()}>
							<Button
								color="super-light-green"
								size="lg"
								onClick={() => accept()}
								disabled={!contract || saving}
								loading={saving}
							>
								<Icon name="check" space="mrl" />
								{lang.accept}
							</Button>
							<Button
								color="green"
								type="link"
								size="lg"
								onClick={() => decline()}
								disabled={!contract || saving}
							>
								<Icon name="times" space="mrl" />
								{lang.decline}
							</Button>
						</Block>
					</Block>
				) : (
					<Block className={classes.elem('content').b()}>
						<p>{canNotAcceptText}</p>
					</Block>
				)}
			</Expandable>
		</Block>
	);
};

export default AcceptContract;

'use strict';

angular.module('services.cacheService', []).service('CacheService', [
	'$cacheFactory',
	function CacheService($cacheFactory) {
		// TODO: Kunna sätta olika exp-dates för varje objectType

		var Types = {
			CUSTOMFIELD: 'CustomField',
			USER: 'User',
			ROLE: 'Role',
			ACTIVITYTYPE: 'ActivityType',
			APPOINTMENTTYPE: 'AppointmentType',
			ACCOUNTCATEGORY: 'AccountCategory',
			ACCOUNTCATEGORYTYPE: 'AccountCategoryType',
			CONTACTCATEGORY: 'ContactCategory',
			CONTACTCATEGORYTYPE: 'ContactCategoryType',
			LEADCHANNEL: 'Leadchannel',
			CAMPAIGN: 'campaign',
			PRODUCTCATEGORY: 'ProductCategory',
			PRODUCT: 'Product',
			DOCUMENTTEMPLATE: 'DocumentTemplate',
			ORDERSTAGE: 'OrderStage'
		};

		var Caches = {};
		function clear() {
			angular.forEach(Caches, function (c) {
				if (c) {
					c.removeAll();
				}
			});
		}

		var Instance = function (type) {
			if (!Caches[type]) {
				Caches[type] = $cacheFactory(type, {});
			}
			var Cache = Caches[type];

			return {
				get: function (key) {
					// Check if cache exists
					if (!Cache) {
						return undefined;
					}
					var cached = Cache.get(key);
					if (!cached) {
						return undefined;
					}

					// Check if cached object is older than 1 hour
					if (Date.now() - cached.age > 3600000) {
						return undefined;
					}

					// Return value
					return angular.copy(cached.data);
				},

				put: function (key, value) {
					if (!Cache) {
						return undefined;
					}
					return Cache.put(key, {
						data: angular.copy(value),
						age: Date.now()
					});
				},

				remove: function () {
					return Cache.remove();
				},

				removeAll: function () {
					return Cache.removeAll();
				}
			};
		};

		return {
			cacheTypes: Types,
			getCache: Instance,
			clear: clear,
			restore: clear
		};
	}
]);

'use strict';
angular.module('domain.admin').factory('EditDynamiclinkMeta', [
	'$q',
	'Links',
	function ($q, Links) {
		return function (params) {
			var promises = {};
			if (params.id) {
				promises.link = Links.admin().get(params.id);
			} else {
				promises.link = $q.when({ data: Links.new() });
			}

			return $q.all(promises);
		};
	}
]);

import logError from 'App/babel/helpers/logError';

angular.module('domain.mail').controller('MailCampaignDashboard', [
	'$q',
	'$scope',
	'AppService',
	'campaignMeta',
	'MailCampaignInfo',
	'MailCampaign',
	'$stateParams',
	'RequestBuilder',
	function ($q, $scope, AppService, campaignMeta, MailCampaignInfo, MailCampaign, $stateParams, RequestBuilder) {
		var getTimeout;
		var MailCampaignCtrl = $scope.MailCampaignCtrl;

		MailCampaignCtrl.rootData.pageComponent = 'dashboard';
		MailCampaignCtrl.rootData.pageData = {
			loading: true,
			total: 0,
			totalPages: 1,
			activePage: 1,
			currentSearch: '',
			currentSorting: { attr: 'date', asc: false },
			onSearch: function (str) {
				MailCampaignCtrl.rootData.pageData.activePage = 1;
				MailCampaignCtrl.rootData.pageData.currentSearch = str;
				getMailEvents(str, 25);
			},
			onChangePage: function (page) {
				MailCampaignCtrl.rootData.pageData.activePage = page;
				if (MailCampaignCtrl.rootData.pageData.currentSearch) {
					getMailEvents(MailCampaignCtrl.rootData.pageData.currentSearch, 25);
				} else {
					getMailEvents(null, 25);
				}
			},
			types: [
				{ type: 'all', name: 'all', isActive: true },
				{ type: 'open', name: 'mailOpened', isActive: false },
				{ type: 'click', name: 'adsClick', isActive: false },
				{ type: 'formsubmit', name: 'formLatestSubmits', isActive: false },
				{ type: 'unsub', name: 'mailUnsubscribers', isActive: false },
				{ type: 'bounce', name: 'eventBounces', isActive: false }
			],
			selectedTypes: function (types) {
				return types.map(function (t) {
					if (t.isActive) {
						if (t.type === 'bounce') {
							return ['hard_bounce', 'soft_bounce'];
						}
						return t.type;
					} else {
						return false;
					}
				});
			},
			toggleType: function (_type) {
				MailCampaignCtrl.rootData.pageData.types = MailCampaignCtrl.rootData.pageData.types.map(function (
					type
				) {
					if (_type === 'all') {
						type.isActive = false;
					} else if (type.type === _type) {
						if (type.isActive) {
							type.isActive = false;
							return type;
						} else {
							type.isActive = true;
							return type;
						}
					}
					return type;
				});

				const isNotChecked = MailCampaignCtrl.rootData.pageData.types.filter(function (filter) {
					return filter.isActive && filter.type !== 'all';
				});

				var allElement = MailCampaignCtrl.rootData.pageData.types.find(function (element) {
					return element.type === 'all';
				});
				if (allElement) {
					allElement.isActive = isNotChecked.length ? false : true;
				}

				MailCampaignCtrl.rootData.pageData.activePage = 1;
				if (MailCampaignCtrl.rootData.pageData.currentSearch) {
					getMailEvents(MailCampaignCtrl.rootData.pageData.currentSearch, 25);
				} else {
					getMailEvents(null, 25);
				}
			}
		};

		var selectAll = function () {
			var array = _.cloneDeep(MailCampaignCtrl.rootData.pageData.types);
			var result = array.map(function (type) {
				type.isActive = true;
				return type;
			});

			result = MailCampaignCtrl.rootData.pageData.selectedTypes(result);
			return _.compact(_.flatten(result));
		};

		$scope.$on('$destroy', () => {
			if (getTimeout) {
				clearTimeout(getTimeout);
			}
		});

		function getMailEvents(q, limit) {
			MailCampaignCtrl.rootData.pageData.loading = true;

			if (getTimeout) {
				clearTimeout(getTimeout);
			}

			getTimeout = setTimeout(function () {
				var rb = new RequestBuilder();
				rb.limit = limit;
				rb.offset = limit * (MailCampaignCtrl.rootData.pageData.activePage - 1);

				rb.addFilter(MailCampaignInfo.attr.groupMailId, rb.comparisonTypes.Equals, $stateParams.id);

				var subType = _.compact(
					_.flatten(
						MailCampaignCtrl.rootData.pageData.selectedTypes(MailCampaignCtrl.rootData.pageData.types)
					)
				);

				if (!subType.length) {
					selectAll();
				}

				var subTypes = _.compact(
					_.flatten(
						MailCampaignCtrl.rootData.pageData.selectedTypes(MailCampaignCtrl.rootData.pageData.types)
					)
				);
				var indexOfAll = subTypes.indexOf('all');
				if (indexOfAll !== -1) {
					subTypes.splice(indexOfAll, 1);
				}

				if (!subTypes.length) {
					subTypes = selectAll();
				}

				rb.addFilter(MailCampaignInfo.attr.type, rb.comparisonTypes.Equals, subTypes);
				if (q && q.length) {
					var orBuilder = rb.orBuilder();

					orBuilder.next();
					orBuilder.addFilter(MailCampaignInfo.attr.clientName, rb.comparisonTypes.Search, q);

					orBuilder.next();
					orBuilder.addFilter(MailCampaignInfo.attr.clientAddress, rb.comparisonTypes.Search, q);

					orBuilder.next();
					orBuilder.addFilter(MailCampaignInfo.attr.contactName, rb.comparisonTypes.Search, q);

					orBuilder.done();
				}

				rb.addSort(
					MailCampaignCtrl.rootData.pageData.currentSorting.attr,
					MailCampaignCtrl.rootData.pageData.currentSorting.asc
				);

				$q.all({
					list: MailCampaignInfo.find(rb.build()),
					meta: MailCampaign.get($stateParams.id)
				})
					.then(function (data) {
						MailCampaignCtrl.rootData.pageData.mailEvents = _.sortBy(data.list.data, 'date').reverse();
						MailCampaignCtrl.rootData.pageData.total = data.list.metadata.total;
						MailCampaignCtrl.rootData.pageData.mailStats = campaignMeta.data;
						MailCampaignCtrl.rootData.pageData.mailMeta = {
							from: data.meta.data.fromName,
							fromEmail: data.meta.data.from,
							sendDate: data.meta.data.sendDate,
							total: campaignMeta.data.events.send
						};

						MailCampaignCtrl.rootData.pageData.loading = false;
						MailCampaignCtrl.rootData.pageData.totalPages = MailCampaignCtrl.calculateTotalPages(
							limit,
							MailCampaignCtrl.rootData.pageData.total
						);
					})
					.catch(e => logError(e, 'MailCampaignDashboard: getMailEvents failed'));
			}, 300);
		}

		var init = function () {
			getMailEvents(null, 25);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

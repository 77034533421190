import React, { useRef, useState, useLayoutEffect } from 'react';
import PopupPortalAnchors from 'App/helpers/PopupPortalAnchors';
import Order from 'App/resources/Model/Order';
import Client from 'App/resources/Model/Client';
import { OptionalQueryElement } from 'App/helpers/PopupPortalAnchors';
import ModalHistoryLog from 'App/components/HistoryLog/ModalHistoryLog';

// This view is needed in order to be able to set up PopupPortalAnchors with the notes filter history in angular.
// Once the editOrderDashboard.html has been ported to react, this component is no longer needed and the rendered
// children can be moved and used directly in the ported view instead.

type Props = Collect<{
	order: Order & {
		client: Client;
	}
} & PickProps<typeof ModalHistoryLog, 'buttonOrder'>>;

const OrderHistory = ({ order, buttonOrder }: Props) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [anchor, setAnchor] = useState<OptionalQueryElement>();

	useLayoutEffect(() => {
		setAnchor(wrapperRef.current?.closest('.up-modal'));
	}, []);

	return (
		<div ref={wrapperRef}>
			<PopupPortalAnchors anchor={anchor} scrollContainerSelector=".up-modal-content">
				<ModalHistoryLog
					opportunity={order}
					client={order?.client}
					buttonOrder={buttonOrder}
					renderedBy={{ type: 'opportunity', id: order?.id }}
				/>
			</PopupPortalAnchors>
		</div>
	);
};

export default OrderHistory;

'use strict';

angular.module('upModals.controllers').controller('CreateDocument', [
	'$scope',
	'$modalParams',
	'$upModal',
	function ($scope, $modalParams, $upModal) {
		var CreateDocument = this;

		// set available templates on scope
		CreateDocument.templates = $modalParams.meta.templates.data;

		// Contacts
		CreateDocument.contacts = $modalParams.meta.contacts.data;

		// If we have preset contactId
		CreateDocument.contactId = $modalParams.contactId;

		// when we select a template
		CreateDocument.select = function (template) {
			var params = {
				entityId: $modalParams.id,
				templateId: template.id,
				type: $modalParams.type,
				name: template.name
			};

			if ($modalParams.accountId) {
				params.accountId = $modalParams.accountId;
			}

			if (CreateDocument.contactId) {
				params.contactId = CreateDocument.contactId;
			}
			$scope.resolve();
			$upModal.open('pdfPreview', params);
		};
	}
]);

import Resource from './Resource';

class Function extends Resource {
	constructor() {
		super('function');
	}

	translateTags(object) {
		if (!object.entity) {
			throw new Error('You need to set entity');
		}

		if (!object.entityId) {
			throw new Error('You need to set entityId');
		}

		const mappedData = Object.assign(
			{},
			{
				entity: object.entity,
				entityId: object.entityId,
				data: object.data
			}
		);

		return this._postRequest('translate', mappedData, { methodName: 'translateTags' }).then(res => res.data);
	}
}

const resource = new Function();

window.Tools.Function = resource;
export default resource;

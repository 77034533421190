'use strict';

angular.module('upResources').factory('GroupMailCategory', [
	'$resource',
	'URL',
	'API',
	'GroupMailCategoryAttributes',
	'ResourceHelper',
	function GroupMailCategory($resource, URL, API, GroupMailCategoryAttributes, ResourceHelper) {
		var Attributes = GroupMailCategoryAttributes().attr;
		var keys = GroupMailCategoryAttributes().keys;

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'groupMailCategory',
			createSuccessBody: 'saved.groupMailCategory',
			updateSuccessBody: 'updated.groupMailCategory',
			createErrorBody: 'saveError.groupMailCategory',
			updateErrorBody: 'saveError.groupMailCategory'
		});

		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'groupMailCategories/';
		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				find: { method: 'GET', isArray: false },
				get: { method: 'GET', isArray: false },
				create: { method: 'POST', isArray: false },
				update: { method: 'PUT', isArray: false }
			}
		);

		var Model = {
			new: function () {
				var optOut = {
					title: '',
					description: '',
					languages: [],
					active: true
				};

				return optOut;
			},

			find: function (filter) {
				return Resource.find(filter).$promise;
			},

			get: function (id) {
				return Resource.get({ id: id }).$promise;
			},

			save: function (optOut, options) {
				var promise;

				if (optOut.id) {
					promise = Resource.update({ id: optOut.id }, optOut).$promise;
				} else {
					promise = Resource.create(optOut).$promise;
				}

				promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !optOut.id);
					})
					.catch(function () {
						// Notify user
						helper.onSaveError(options, !optOut.id);
					});

				return promise;
			},

			attr: Attributes,
			keys: keys,
			requiredFields: GroupMailCategoryAttributes().requiredFields
		};

		return Model;
	}
]);

import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';

const NotificationBadge = (props: { color: string; maxAmount: number; amount: number }) => {
	if (props.amount <= 0) return null;

	let notification: string = props.amount.toString();
	if (props.amount > props.maxAmount) {
		notification = props.maxAmount.toString() + '+';
	}
	const classes = new bemClass('notification-badge-child');
	const modObj: { [color: string]: boolean } = {};
	modObj[props.color] = true;
	return <div className={classes.mod(modObj).b()}>{notification}</div>;
};

export default NotificationBadge;

import React from 'react';
import Dropzone from 'react-dropzone';
import bemClass from '@upsales/components/Utils/bemClass';
import FileUploadDropzone from './FileUploadDropzone';
import FileUploadDropzoneActive from './FileUploadDropzoneActive';
import './FileUpload.scss';

const defaultAccept = ['image/*', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv'];

interface childProps {
	open: () => void;
	isDragActive: boolean;
	isDragActiveBrowser: boolean;
}

interface FileUploadProps {
	children(props: childProps): JSX.Element | JSX.Element[];
	className?: string;
	onDrop: <File>(files: File[]) => void;
	accept: string[];
}

class FileUpload extends React.PureComponent<FileUploadProps> {
	render() {
		const { children, className, onDrop, accept = defaultAccept } = this.props;
		const classes = new bemClass('FileUpload', className);

		const isDragActiveBrowser = false;
		return (
			<Dropzone onDropAccepted={onDrop} noClick={true} noKeyboard={true} accept={accept.join(', ')}>
				{({ getRootProps, getInputProps, isDragActive, open }) => (
					<div
						{...getRootProps({
							className: classes
								.mod({
									active: isDragActive
								})
								.b()
						})}
					>
						<input {...getInputProps()} />
						{children({ open, isDragActive, isDragActiveBrowser })}
					</div>
				)}
			</Dropzone>
		);
	}
}

export { FileUploadDropzone, FileUploadDropzoneActive };

export default FileUpload;

/*
We could probably base this on the new ListViewTable component, but it should not be done in this PR
 */
import { Button, Flex, Icon, TableColumn, TableRow, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { showCoverup } from 'App/AngularApp';
import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import {
	ListViewDefaultColumn,
	renderDefaultTable,
	RenderTableProvided,
	RenderTableRowProvided
} from 'App/components/ListView/ListViewRenderHelpers';
import React, { Fragment, useEffect, useState } from 'react';
import './SimpleListView.scss';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import Attribute, { Type } from 'App/babel/attributes/Attribute';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import T from 'Components/Helpers/translate';

type TableRowProvided<ItemType extends {}> = ListViewTableProvided<ItemType, RenderTableRowProvided<ItemType>>;

type Props<ItemType extends {}> = {
	columns: string[];
	getData: ListViewPropsExternal<ItemType>['getData'];
	subHeaderFn?: (item: ItemType, sorting: string) => string;
	rowStyles?: { [modName: string]: (item: ItemType) => boolean };
	onRowClick?: (item: ItemType, provided: TableRowProvided<ItemType>) => () => void;
	classes?: BemClass;
	hideNoResults?: boolean;
	/** Supply custom render functions for columns. By default columns will be rendered by `ListViewDefaultColumn`, based on `attributes`,
	 * but this can be used to override that behavior with your own components */
	customColumnRenders?: {
		[column: string]: (item: ItemType, provided: TableRowProvided<ItemType>) => React.ReactNode;
	};
	/** @deprecated Prefer using `customColumnRenders`, `rowStyles` and `onRowClick` props instead of defining your own row renderer */
	renderTableRow?: ListViewPropsExternal<ItemType>['renderTableRow'];
} & OptionalPropGroup<{
	deleteFn: (item: ItemType, provided: TableRowProvided<ItemType>) => Promise<void>;
	isDeletable: (item: ItemType) => boolean;
	entityName: string;
	useHeader?: boolean;
	noStickyHeader?: boolean;
}> &
	PartialOmit<ListViewPropsExternal<ItemType>, 'renderTableRow'>;

const modifyRenderTableFn =
	<ItemType extends {}>(
		renderTable: Required<Props<ItemType>>['renderTable'],
		getSubHeader: Required<Props<ItemType>>['subHeaderFn']
	) =>
	(tableProvided: RenderTableProvided<ItemType>) => {
		const lastHeader = { current: '', index: 0 };

		const modifiedRenderRow = (
			item: ItemType,
			rowProvided: ListViewTableProvided<ItemType, RenderTableRowProvided<ItemType>>
		) => {
			// Attach a sub-header row above tableRows if the text to be displayed has changed.
			// Relies on being synced up with sorting - aka it only checks if the item before is different
			// Consider: May want to disconnect the rendering itself from the logic deciding if it should render. Current
			// approach does not allow for any more advanced rendering than just straight text
			const headerText = getSubHeader(item, tableProvided.sorting[0]?.attribute ?? '');
			const shouldDisplayHeader = headerText && headerText !== lastHeader.current;
			lastHeader.current = headerText;
			return shouldDisplayHeader ? (
				<Fragment key={`${item[rowProvided.itemIdentifier]}`}>
					<TableRow
						key={`subHeader${lastHeader.index++}`}
						className={rowProvided.classes.elem('subHeader').b()}
					>
						<TableColumn colSpan={100}>
							<Text bold size="lg">
								{headerText}
							</Text>
						</TableColumn>
					</TableRow>
					{tableProvided.renderTableRow(item, rowProvided)}
				</Fragment>
			) : (
				tableProvided.renderTableRow(item, rowProvided)
			);
		};

		return renderTable({ ...tableProvided, renderTableRow: modifiedRenderRow });
	};

type TableRowProps<ItemType extends {}> = {
	item: ItemType;
	provided: TableRowProvided<ItemType>;
} & Pick<Props<ItemType>, 'customColumnRenders' | 'rowStyles' | 'onRowClick'>;

const SimpleListViewTableRow = <ItemType extends {}>({
	item,
	provided,
	customColumnRenders = {},
	rowStyles = {},
	onRowClick
}: TableRowProps<ItemType>) => {
	const { attributes, columns, itemIdentifier, customFields, categories } = provided;

	const classes = new BemClass('TableRow');

	Object.keys(rowStyles).forEach(modName => {
		if (rowStyles[modName](item)) {
			classes.mod(modName);
		}
	});

	const columnElements = columns.map(column =>
		customColumnRenders[column] ? (
			<TableColumn key={column} className={classes.elem(column).b()}>
				{customColumnRenders[column](item, provided)}
			</TableColumn>
		) : (
			<ListViewDefaultColumn<ItemType>
				key={column}
				column={column}
				attributes={attributes}
				item={item}
				customFields={customFields}
				categories={categories}
			/>
		)
	);

	return (
		<TableRow key={item[itemIdentifier] as any} className={classes.b()} onClick={onRowClick?.(item, provided)}>
			{columnElements}
		</TableRow>
	);
};

export const InlineDeleteButton = <ItemType extends {}>({
	isDeletable,
	deleteFn,
	entityName,
	item,
	provided
}: Required<Pick<Props<ItemType>, 'isDeletable' | 'deleteFn' | 'entityName'>> & {
	item: ItemType;
	provided: TableRowProvided<ItemType>;
}) => (
	<Flex flex={[0, 0, '40px']}>
		<InlineConfirm
			show={isDeletable(item)}
			tooltip={T('default.delete')}
			onConfirm={() => {
				return deleteFn(item, provided).finally(() => {
					setTimeout(() => provided.reloadTable({ silent: true }), 1000);
				});
			}}
			entity={entityName}
		>
			<Button type="link" color="grey">
				<Icon name="trash" />
			</Button>
		</InlineConfirm>
	</Flex>
);

const SimpleListView = <ItemType extends {}>({
	classes,
	columns,
	getData,
	subHeaderFn,
	rowStyles,
	onRowClick,
	renderTable,
	renderTableRow,
	hideNoResults,
	deleteFn,
	isDeletable,
	entityName,
	customColumnRenders = {},
	useHeader,
	...passthrough
}: Props<ItemType>) => {
	if (classes) {
		classes.add('SimpleListView');
	} else {
		classes = new BemClass('SimpleListView');
	}
	const [hasNoData, setHasNoData] = useState(false);
	useEffect(() => {
		// Listview disables the coverup on mount - we don't want that here
		showCoverup();
	}, []);

	if (subHeaderFn) {
		renderTable = renderTable ?? renderDefaultTable;
		renderTable = modifyRenderTableFn(renderTable, subHeaderFn);
	}

	if (isDeletable && deleteFn) {
		customColumnRenders.delete = (item, provided) => {
			return (
				<Flex justifyContent="end">
					<InlineDeleteButton
						isDeletable={isDeletable}
						deleteFn={deleteFn}
						entityName={entityName!}
						item={item}
						provided={provided}
					/>
				</Flex>
			);
		};
	}

	renderTableRow =
		renderTableRow ??
		((item, provided) => (
			<SimpleListViewTableRow
				item={item}
				provided={provided}
				rowStyles={rowStyles}
				onRowClick={onRowClick}
				customColumnRenders={customColumnRenders}
			/>
		));

	const attributes = {
		delete: Attribute({
			title: '',
			field: '',
			type: Type.String
		}),
		...(passthrough.attributes ?? {})
	};

	return !(hideNoResults && hasNoData) ? (
		<ListView<ItemType>
			noStickyTableHeader
			className={classes.b()}
			getData={(rb, provided) =>
				getData(rb, provided).then(a => {
					if (!a.data.length) {
						setHasNoData(true);
					}
					return a;
				})
			}
			onChange={prov => prov.reloadTable({ silent: true })}
			isFullscreen={false}
			columns={columns}
			skipSortById
			renderHeader={useHeader ? undefined : () => <></>}
			tableLimitOptions={[10]}
			renderTable={renderTable}
			renderTableRow={renderTableRow}
			{...passthrough}
			attributes={attributes}
		/>
	) : null;
};

export default SimpleListView;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Icon, IconName } from '@upsales/components';

const alignments = ['left', 'center', 'right'] as const;
type Align = (typeof alignments)[number];

type Props = {
	value: Align;
	onChange: (align: Align) => void;
};

const MailContentEditorAlign = ({ value, onChange, ...props }: Props) => {
	return (
		<ButtonGroup block={true} {...props}>
			{alignments.map(align => (
				<Button
					key={align}
					shadow="none"
					size="sm"
					onClick={() => onChange(align)}
					color={value === align ? 'green' : 'light-grey'}
				>
					<Icon name={('align-' + align) as IconName} />
				</Button>
			))}
		</ButtonGroup>
	);
};

MailContentEditorAlign.propTypes = {
	value: PropTypes.oneOf(alignments).isRequired,
	onChange: PropTypes.func.isRequired
};

MailContentEditorAlign.defaultProps = {
	value: alignments[0],
	onChange: () => {}
};

export default MailContentEditorAlign;

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Loader } from '@upsales/components';
import { makeCancelable } from 'App/babel/helpers/promise';

type App = {
	name?: string;
	baseurl?: string;
	integrationId?: number;
	imageLink: string;
	color: string;
};
type AccountAppProps = {
	apps: App[];
	account: {};
	initialAppId: number;
};
type AccountAppsState = {
	selected?: string;
	selectedItem?: App | null;
	url: string | undefined;
	initialAppId: number;
};
class AccountApps extends React.Component<AccountAppProps, AccountAppsState> {
	fetchUrlPromise: { promise: Promise<any>; cancel: () => void } | undefined;
	static propTypes = {
		apps: PropTypes.array,
		account: PropTypes.object,
		initialAppId: PropTypes.number
	};
	static defaultProps = {
		apps: []
	};
	constructor(props: AccountAppProps) {
		super(props);

		const selected = this.getInitSelected(props.initialAppId);
		this.state = {
			selected: selected?.name,
			selectedItem: selected,
			url: selected?.baseurl,
			initialAppId: props.initialAppId
		};
	}

	getInitSelected(initAppId: number) {
		if (this.props.apps.length) {
			const first = this.props.apps[0];
			if (initAppId) {
				const selected = _.find(this.props.apps, { integrationId: initAppId });
				return selected || first;
			}
			return first;
		}
		return null;
	}

	getFrameUrl(mainObject: {}) {
		if (this.fetchUrlPromise) {
			this.fetchUrlPromise.cancel();
		}
		this.fetchUrlPromise = makeCancelable(
			Tools.StandardIntegration.data(Tools.AppService.getCustomerId()).run(mainObject)
		);
		this.fetchUrlPromise.promise
			.then(response => {
				const URL = response.data;
				this.setState({ url: URL });
			})
			.catch(e => {
				if (!e.isCanceled) {
					console.log('StandardIntegration failed to get iframe url', e);
				}
			});
	}

	componentWillUnmount() {
		if (this.fetchUrlPromise) {
			this.fetchUrlPromise.cancel();
		}
	}

	componentDidMount() {
		const { selectedItem } = this.state;
		const { account } = this.props;

		const mainObject = {
			typeId: '',
			data: account,
			type: 'iframe',
			integrationId: selectedItem?.integrationId
		};

		this.getFrameUrl(mainObject);
	}

	componentDidUpdate(prevProps: AccountAppProps, prevState: AccountAppsState) {
		if (this.props.apps.length && this.state.selected === null) {
			const selected = this.getInitSelected(this.state.initialAppId);
			this.setState({
				selected: selected?.name,
				selectedItem: selected
			});
		} else {
			const { selectedItem, url } = this.state;
			const { account } = this.props;

			if (url !== prevState.url || selectedItem?.integrationId !== prevState.selectedItem?.integrationId) {
				const mainObject = {
					typeId: '',
					data: account,
					type: 'iframe',
					integrationId: selectedItem?.integrationId
				};

				this.getFrameUrl(mainObject);
			}
		}
	}

	icon(image: string) {
		if (image.indexOf('#') !== -1) {
			return <span className="color-style" style={{ backgroundColor: image }} />;
		}

		return <img className="image-style" src={image} />;
	}
	mapApps(apps: App[]) {
		const { selected } = this.state;

		const mappedApps = apps.map(a => {
			const props = {
				id: a.name,
				noFlex: true
			};

			return (
				<Tab key={a.name} {...props}>
					{this.icon(a.imageLink || a.color)}
					{a.name}
				</Tab>
			);
		});

		return (
			<Tabs
				selected={selected}
				onChange={(value: string) => {
					const obj = this.props.apps.find(a => {
						return a.name === value;
					});

					this.setState({
						selected: value,
						selectedItem: obj
					});
				}}
			>
				{mappedApps}
			</Tabs>
		);
	}

	makeIframe(url: string) {
		return <iframe className="AccountApps__iframe" src={url} width="100%" height="100%" frameBorder="none" />;
	}

	makeLoader() {
		return <Loader className="app-loader" noU={true} />;
	}

	render() {
		const { url } = this.state;
		const { apps } = this.props;

		return (
			<div id="account-apps">
				{apps.length ? this.mapApps(apps) : null}
				<div className="spacer">{url ? this.makeIframe(url) : this.makeLoader()}</div>
			</div>
		);
	}
}

export default AccountApps;

import Resource from './Resource';

class UnreleasedFeatureUser extends Resource {
	constructor() {
		super('master/unreleasedFeatureUser');
	}

	toggle(data) {
		return this._postRequest('', data, { methodName: 'toggle' }).then(res => {
			return res.data;
		});
	}
}

const resource = new UnreleasedFeatureUser();

window.Tools.UnreleasedFeatureUser = resource;

export default resource;

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon } from '@upsales/components';
import BrowserService from 'Services/BrowserService';
import './EditorSidebarToggle.scss';

const EditorSidebarToggle = ({ className, icon, sidebarVisible, ...props }) => {
	const classes = new bemClass('EditorSidebarToggle', className);
	const style = {};
	if (sidebarVisible) {
		if (icon === 'cog') {
			style.top = '80px';
		}
	} else {
		style.marginLeft = `-${BrowserService.scrollbarWidth}px`;
	}
	return (
		<div style={style} {...props} className={classes.b()} data-testid={icon}>
			<Icon name={icon} />
		</div>
	);
};

EditorSidebarToggle.defaultProps = {
	icon: 'cog'
};

EditorSidebarToggle.propTypes = {
	icon: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default EditorSidebarToggle;

'use strict';

angular.module('upDirectives').directive('upComparisonTypeToggle', function () {
	return {
		restrict: 'A',
		replace: true,
		scope: {
			ngModel: '=ngModel',
			ngChange: '&ngChange'
		},
		link: function (scope) {
			scope.$watch('ngModel', function (model) {
				if (model !== undefined && scope.ngChange) {
					scope.ngChange();
				}
			});
		},
		template: function (el, attrs) {
			return (
				'<div class="up-comparison-type-toggle"' +
				'ng-click="(ngModel = ngModel == \'' +
				(attrs.trueValue || 'Equals') +
				"' ? 'NotEquals' : '" +
				(attrs.trueValue || 'Equals') +
				'\')"' +
				'>' +
				'<input ' +
				'type="checkbox"' +
				'ng-model="ngModel"' +
				'autocomplete="none"' +
				'ng-true-value="\'' +
				(attrs.trueValue || 'Equals') +
				'\'"' +
				'ng-false-value="\'NotEquals\'"' +
				'style="display: none;"' +
				'/> ' +
				"<span>{{ (ngModel == '" +
				(attrs.trueValue || 'Equals') +
				"' ? 'filters.Equals' : 'filters.NotEquals') | translate | lowercase}}</span>" +
				'</div>'
			);
		}
	};
});

import { Text, Tooltip, Button, Icon } from '@upsales/components';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

ReactTemplates.ROWSTATICS.mailingsRow = function (tools) {
	var statics = {};
	statics.editTool = tools.$translate('default.edit');
	statics.deleteTool = tools.$translate('default.delete');
	statics.noAccountManager = tools.$translate('default.noAccountManager');
	statics.noCampaign = tools.$translate('default.noCampaign');
	statics.noCategories = tools.$translate('default.noCategories');
	statics.noName = tools.$translate('default.noName');
	statics.addExternal = tools.$translate('default.addExternal');

	statics.archive = tools.$translate('default.archive');
	statics.unArchive = tools.$translate('default.unArchive');
	statics.filterSelection = tools.$translate('groupMail.filterSelection');
	statics.segment = tools.$translate('default.segment');
	statics.campaign = tools.$translate('default.campaign');
	statics.notSentMissingUnsub = tools.$translate('mail.notSentMissingUnsub');

	return statics;
};

ReactTemplates.ROW.mailingsRow = window.ReactCreateClass({
	displayName: 'mailingsRow',
	click: function () {
		this.props.tools.opts.clickedEntry(this.props.item);
	},
	edit: function () {
		this.props.tools.opts.editEntry(this.props.item);
	},
	saveExternal: function (item) {
		this.props.tools.opts.saveExternal(item || this.props.item);
	},
	refreshSoliditet: function (e) {
		if (e) {
			ReactTemplates.TOOLS.stopProp(e);
		}
		this.props.tools.opts.refreshSoliditet(this.props.item);
	},
	archive: function () {
		this.props.tools.opts.archiveMailCampaign(this.props.item);
	},
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var columnTools = this.props.tools.opts.columnTools || {};

		var getColumn = function (column, item) {
			var key = 'column-' + column;
			switch (column) {
				case 'contact':
					return (
						<td key={key}>
							<span className="lead-contact">
								<ReactTemplates.TOOLS.contactHref
									customerId={customerId}
									id={item.contact ? item.contact.id : '0'}
									text={item.contact ? item.contact.name : item.to}
									tools={tools}
									style={{ display: 'block' }}
								/>
								<span
									className="lead-contact-account"
									style={{
										marginTop: '-2px',
										display: 'block'
									}}
								>
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.client ? item.client.id : '0'}
										text={item.client ? item.client.name : '-'}
										tools={tools}
									/>
								</span>
							</span>
						</td>
					);

				case 'sendDate':
					if (item.status === 'DRAFT') {
						return ReactTemplates.TOOLS.emptyTD();
					} else {
						return (
							<td key={key} className="column-startDate">
								{ReactTemplates.TOOLS.dateCalendar(item.sendDate, 'L')}
							</td>
						);
					}

				case 'modDate':
					if (!item.modDate) {
						return ReactTemplates.TOOLS.emptyTD();
					} else {
						return (
							<td key={key} className="column-modDate">
								{ReactTemplates.TOOLS.dateCalendar(item.modDate, 'L')}
							</td>
						);
					}

				case 'from':
					switch (item.from) {
						case '{{General.CompanyEmail}}':
							return <td key={key}>{'Standardavsändare'}</td>;

						case '{{General.CurrentUserEmail}}':
							return <td key={key}>{'Inloggad användare'}</td>;

						case '{{Client.UserEmail}}':
							return <td key={key}>{'Kontoansvarig'}</td>;

						default:
							return <td key={key}>{item.from}</td>;
					}

				case 'user':
					if (!item.user) {
						return (
							<td key={key}>
								<i className="grey">{tools.$translate('default.noUser')}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.usersText([item.user], tools)}</td>;
					}

				case 'date':
					return (
						<td key={key} className="column-startDate">
							{ReactTemplates.TOOLS.dateCalendar(item.date, 'L')}
						</td>
					);

				case 'subject':
					return (
						<td key={key} className="column-subject overflow-controller">
							{item.subject}
						</td>
					);

				case 'status':
					if (item.status === 'NO_UNSUB') {
						return (
							<td key={key}>
								<Text color="red" size="sm">
									{statics.notSentMissingUnsub}
								</Text>
							</td>
						);
					}
					var status = item.status;
					if (status === 'ERROR') {
						// We should not show error statuses for the users
						status = 'SENT';
					}
					return <td key={key}>{tools.$translate('mail.status.' + status)}</td>;

				case 'mailSent':
					return (
						<td key={key}>
							<a href={tools.$state.href('mailCampaign', { id: item.id })}>{item.mailSent}</a>
						</td>
					);

				case 'mailRead':
					var readPercentage = ((item.mailRead / item.mailSent) * 100).toFixed(0);
					if (!isNaN(readPercentage)) {
						readPercentage = ' (' + readPercentage + '%)';
					} else {
						readPercentage = '';
					}

					return (
						<td key={key}>
							<a href={tools.$state.href('mailCampaign', { id: item.id })}>{item.mailRead}</a>
							{readPercentage}
						</td>
					);

				case 'mailClicked': {
					const clickedPercentage = ((item.mailClicked / item.mailSent) * 100).toFixed(0);
					const subtitle = !isNaN(clickedPercentage) ? ' (' + clickedPercentage + '%)' : '';

					return (
						<td key={key}>
							<a href={tools.$state.href('mailCampaign', { id: item.id })}>{item.mailClicked}</a>
							{subtitle}
						</td>
					);
				}
				case 'campaign':
					if (item.segments?.length) {
						if (item.segments.length === 1) {
							return (
								<td key={key}>
									<ReactTemplates.TOOLS.segmentHref
										customerId={customerId}
										id={item.segments[0].id}
										text={item.segments[0].name}
										tools={tools}
										style={{ display: 'block' }}
									/>
								</td>
							);
						}
						const tooltip = item.segments
							.slice(1)
							.map(segment => segment.name)
							.join('\n');

						return (
							<td key={key}>
								<ReactTemplates.TOOLS.segmentHref
									customerId={customerId}
									id={item.segments[0].id}
									text={item.segments[0].name}
									tools={tools}
									style={{ display: 'block' }}
								/>
								<Tooltip title={tooltip}>
									<span>{` +${item.segments.length - 1}`}</span>
								</Tooltip>
							</td>
						);
					} else if (item.project) {
						return (
							<td key={key}>
								<ReactTemplates.TOOLS.campaignHref
									customerId={customerId}
									id={item.project ? item.project.id : '0'}
									text={item.project ? item.project.name : '-'}
									tools={tools}
									style={{ display: 'block' }}
								/>
							</td>
						);
					} else {
						return <td key={key}>{tools.$translate('default.manually')}</td>;
					}

				case 'to':
					if (item.project) {
						return (
							<td key={key}>
								<ReactTemplates.TOOLS.campaignHref
									customerId={customerId}
									id={item.project ? item.project.id : '0'}
									text={item.project ? item.project.name : '-'}
									tools={tools}
									style={{ display: 'block' }}
								/>
								<span className="column-subtitle">{statics.campaign}</span>
							</td>
						);
					}

					if (item.segment) {
						return (
							<td key={key}>
								<a
									onClick={function (e) {
										e.stopPropagation();
									}}
									href={Tools.$state.href('segment.contacts', {
										id: item.segment.id,
										customerId: Tools.AppService.getCustomerId()
									})}
								>
									{item.segment.name}
								</a>
								<span className="column-subtitle">{statics.segment}</span>
							</td>
						);
					}

					if (item.filter) {
						return <td key={key}>{statics.filterSelection}</td>;
					}

					return <td key={key}></td>;

				case 'lastClickDate':
					//return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);

					if (item.type === 'sch') {
						return (
							<td key={key} className="column-lastClickDate">
								{'-'}
							</td>
						);
					}

					var readClassnames = 'indicator',
						clickClassnames = 'indicator';
					if (item.lastReadDate || item.lastClickDate) {
						readClassnames += ' valid';
					}

					if (item.lastClickDate) {
						clickClassnames += ' valid';
					}

					return (
						<td key={key} className="column-lastClickDate">
							<div
								className={readClassnames}
								bs-tooltip
								data-title={tools.$translate('mail.read')}
								data-placement="top"
							>
								{tools.$translate('mail.read')[0]}
							</div>
							<div
								className={clickClassnames}
								bs-tooltip
								data-title={tools.$translate('mail.clicked')}
								data-placement="top"
							>
								{tools.$translate('mail.clicked')[0]}
							</div>
						</td>
					);

				case 'template':
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.templateHref
								customerId={customerId}
								id={item.template ? item.template.id : '0'}
								text={item.template ? item.template.name : '-'}
								tools={tools}
							/>
						</td>
					);

				case 'lastEventDate':
					if (item.lastEventDate === null) {
						return (
							<td key={key} className="column-lastEventDate">
								{'-'}
							</td>
						);
					}

					return (
						<td key={key} className="column-lastEventDate">
							{moment(item.lastEventDate).format('L')}
						</td>
					);

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		if (!columnTools.extraColumn1) {
			columnTools.extraColumn1 = {
				show: function () {
					return false;
				}
			};
		}
		// TODO Only show if userRemovable, show tooltip
		return (
			<tr key={'item-' + item.id} onClick={this.click} ref="row">
				{tds}
				<td className="column-tools">
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.status === 'SENT'}
						icon={item.isArchived ? 'box-open' : 'archive'}
						tooltip={item.isArchived ? statics.unArchive : statics.archive}
						onClick={this.archive}
					/>
					<InlineConfirm
						show={
							item.status === 'DRAFT' ||
							item.status === 'SCHEDULED' ||
							item.status === 'ERROR' ||
							item.status === 'NO_CONTACTS'
						}
						tooltip={statics.deleteTool}
						onConfirm={this.props.tools.opts.removeEntry.bind(null, item, false)}
						entity={'default.mailings'}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</td>
			</tr>
		);
	}
});

import { Icon } from '@upsales/components';

ReactTemplates.OTHER.filterTable = window.ReactCreateClass({
	displayName: 'filterTable',
	shouldComponentUpdate: function () {
		return true;
	},
	toggleOne: function (item, e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.tools.select(item);
	},
	selectOneOnly: function (item, e) {
		ReactTemplates.TOOLS.stopProp(e);
		this.props.tools.selectOne(item);
	},
	render: function () {
		var self = this;
		var selected = self.props.selected;
		var filterConfig = self.props.filterConfig;
		var getTextFunction = typeof filterConfig.displayText === 'function';
		var getSubtitleFunction = typeof filterConfig.subtitle === 'function';
		var allSelected = !selected.length;
		var selectAllText = Tools.$translate('default.select') + ' ' + Tools.$translate('filters.all').toLowerCase();
		if (filterConfig.selectAllText && typeof filterConfig.selectAllText === 'function') {
			selectAllText = filterConfig.selectAllText();
		}
		var onlyText = Tools.$translate('default.only').toLowerCase();
		var multiple = filterConfig.multiple;
		var isSearch = self.props.isSearch;
		var url = null;
		var rowbreakStyle = {
			display: 'block'
		};
		var renderRow = function (item, type) {
			var text = '';
			if (getTextFunction) {
				text = filterConfig.displayText(item);
			} else {
				text = item[filterConfig.displayText];
			}

			if (getSubtitleFunction) {
				url = filterConfig.displaySubtitle(item); // <i className="grey">{item.url}</i>
			} else {
				url = item[filterConfig.displaySubtitle];
			}

			return (
				<tr ref={item.id} key={type + '-row-' + item.id} onClick={self.toggleOne.bind(self, item)}>
					<td className="check">
						{multiple ? (
							<div>
								<input
									type="checkbox"
									className="up-toggle-hidden-checkbox"
									checked={item.$$selected}
								/>
								<div
									className="up-toggle"
									onClick={self.toggleOne.bind(self, item)}
									key={type + '-chk' + item.id}
								>
									<div className="fa fa-check toggle-check"></div>
								</div>
							</div>
						) : item.$$selected ? (
							<Icon name="check" />
						) : null}
					</td>
					<td>
						{text}{' '}
						{url ? (
							<i className="grey" style={rowbreakStyle}>
								{' '}
								{url}{' '}
							</i>
						) : null}
						{multiple ? (
							<button
								onClick={self.selectOneOnly.bind(self, item)}
								className="select-only up-btn btn btn-sm btn-link no-shadow btn-bright-blue"
							>
								{onlyText}
							</button>
						) : null}
					</td>
				</tr>
			);
		};

		var rows = self.props.data.map(
			function (item) {
				return renderRow(item, 'filter');
			}.bind(self)
		);

		var selectedSection = null;

		if (isSearch && selected.length) {
			var selectedRows = self.props.selected.map(
				function (item) {
					return renderRow(item, 'selected');
				}.bind(self)
			);

			selectedSection = (
				<tbody>
					<tr>
						<td colSpan="100">
							<b>{Tools.$translate('default.selected')}</b>
						</td>
					</tr>
					{selectedRows}
				</tbody>
			);
		}

		return (
			<table className="filter-table" style={{ marginTop: 0 }}>
				<tbody>
					<tr className={allSelected} onClick={self.props.tools.selectAll}>
						<td className="check">{allSelected ? <Icon name="check" /> : null}</td>
						<td>{selectAllText}</td>
					</tr>
					{rows}
				</tbody>
				{selectedSection}
			</table>
		);
	}
});

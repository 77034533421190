import Attribute, { DisplayType, Type } from './Attribute';

const EsignAttributes: { [key: string]: ReturnType<typeof Attribute> } = {
	mdate: Attribute({
		title: 'default.date',
		field: 'mdate',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'mdate'
	}),
	filename: Attribute({
		title: 'file.fileName',
		field: 'title', //'file.filename'
		displayKey: 'title',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'title'
	}),
	opportunity: Attribute({
		title: 'field.order.value',
		field: 'opportunity',
		type: Type.Object,
		displayType: DisplayType.EsignOpportunity,
		sortable: 'opportunity'
	}),
	status: Attribute({
		title: 'default.status',
		field: 'state',
		displayKey: ['state', 'involved'],
		type: Type.Array,
		displayType: DisplayType.EsignStatus,
		sortable: 'state'
	}),
	signingParties: Attribute({
		title: 'esign.signingParties',
		field: 'involved',
		displayKey: 'involved',
		type: Type.Array,
		displayType: DisplayType.EsignInvolved,
		sortable: 'involved'
	}),
	delete: Attribute({
		// This is to allow a delete column. SimpleListView replaces the column with 'delete' as key with a trash icon and a delete function
		title: '',
		field: 'involved',
		type: Type.String
	})
};

export default EsignAttributes;

'use strict';

var activityTypeSelectDir = 'upActivityTypes';
angular
	.module('upDirectives')
	.directive(activityTypeSelectDir + 'Ids', function (AppService, ActivityType, selectHelper) {
		return selectHelper.getDirectiveObjectIds(activityTypeSelectDir + 'Ids', {
			ajax: false,
			asIds: true,
			idAttr: ActivityType.attr.id,
			titleAttr: ActivityType.attr.name,
			sorting: { field: ActivityType.attr.name, ascending: true },
			data: function ($attrs) {
				var entity = $attrs.entity;
				var rights = $attrs.rights === 'true' ? true : false;
				const hasTodo = AppService.getAccountSelf().unreleasedFeatures['TODO_LIST'];
				const shouldRemoveActivities =
					hasTodo && AppService.getAccountSelf().unreleasedFeatures['REMOVE_ACTIVITIES'];

				let data = [];
				let entities = shouldRemoveActivities
					? AppService.getActivityTypes('appointment', rights)
					: AppService.getActivityTypes(entity, rights);

				if (entity !== 'appointment' && hasTodo) {
					entities = entities.concat(Object.values(AppService.getTodoTypes()));
				}
				data = _.sortByOrder(entities, 'name');
				return data;
			}
		});
	});

import React, { useState } from 'react';
import Select from 'react-select';
import T from '../Helpers/translate';

import './MailBounce.scss';

export default function MailBounce({ filter, filterName, onChange }) {
	const [visible, setVisible] = useState(false);
	const [activeValue, setActiveValue] = useState(null);
	const value = formatValue(filter);
	const [headerClass, titleClass, bodyClass] = generateClasses(filter);
	const codeInside = [
		<span className={titleClass} key="title-span" onClick={onClick}>
			{T(filter.title)}
		</span>
	];

	if (isActive(filter)) {
		codeInside.push(
			<span
				key="remove-span"
				className="delete-button pull-right"
				onClick={() => onRemoveFilter(onChange, filterName)}
			>
				<i className="fa fa-times-circle" aria-hidden="true" />
			</span>
		);
	}

	codeInside.push(
		<span className={bodyClass} key="body-span" onClick={onClick}>
			{value}
		</span>
	);

	if (visible) {
		return (
			<div className="list-filters-row FilterType__MailBounce FilterType__MailBounce--Visible">
				<div className={headerClass}>{codeInside}</div>
				<div className="drilldown">
					<Select
						className="MailBounce__Select"
						options={mapSelectOptions(filter.options)}
						value={activeValue || filter.value}
						clearable={false}
						onChange={selected => {
							setActiveValue(selected.value);

							const filterObj = Object.assign({}, filter);
							filterObj.value = selected.value;

							onChange(filterObj, { action: 'add' });
						}}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="list-filters-row FilterType__MailBounce">
			<div className={headerClass}>{codeInside}</div>
		</div>
	);

	function mapSelectOptions(options) {
		return options.map(v => {
			return {
				value: v.value,
				label: T(v.text)
			};
		});
	}

	function onRemoveFilter(fn, filterName) {
		const action = { action: 'remove' };

		fn({ filterName }, action);
	}

	function generateClasses() {
		if (isActive(filter)) {
			return ['header active', 'title active', 'selected-values pull-right'];
		}

		return ['header', 'title', 'selected-values pull-right default'];
	}

	function isActive(filter) {
		return !filter.inactive;
	}

	function onClick() {
		setVisible(!visible);
	}

	function formatValue(filter) {
		if (!filter.inactive) {
			const found = filter.options.find(o => o.value === filter.value);
			if (found) {
				return T(found.text);
			}
		}

		return T('default.all');
	}
}

window.MailBounce = MailBounce;

import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import CriteriaRowWrapper from 'Components/Criteria/CriteriaRow/CriteriaRowWrapper';

import './Activity.scss';

export default class ActivityInfo extends React.Component {
	constructor(props) {
		super(props);

		this.activitiesTypes = Tools.AppService.getActivityTypes();
	}

	getProperty(property) {
		const { config } = this.props;
		for (const p of config.properties) {
			if (p.name === property) {
				return p;
			}
		}
		return {};
	}

	getDescription() {
		const { value } = this.getProperty('Description');
		return value;
	}

	getActivityType() {
		const { value } = this.getProperty('ActivityType');
		return (this.activitiesTypes.find(a => a.id === parseInt(value) || a.id === value) || {})?.name || '';
	}

	render() {
		const classes = new BemClass('ActivityInfo');
		return (
			<CriteriaRowWrapper className={classes.b()}>
				<div className="criteria-row">
					<div className={classes.elem('title').b()}>{this.getDescription()}</div>
					<div className={classes.elem('sub-title').b()}>{this.getActivityType()}</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

ActivityInfo.propTypes = {
	config: PropTypes.object.isRequired
};

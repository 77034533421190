import React from 'react';
import T from 'Components/Helpers/translate';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Headline, ButtonBox, Card, Title, Flex, Block } from '@upsales/components';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { setNextStep } from 'Store/reducers/SalesCoachReducer';
import { cloneDeep } from 'lodash';
import { Fade } from '@upsales/components/animations';

import './NextStep.scss';

type nextStep = {
	onlyAppointments: boolean;
	active: boolean;
};

type State = {
	nextStep: nextStep;
};

type Props = {
	reject: () => void;
	nextStep: nextStep;
	setNextStep: (nextStep: nextStep) => void;
};

const mapStateToProps = ({ SalesCoachReducer }: { SalesCoachReducer: { nextStep: nextStep } }) => ({
	nextStep: SalesCoachReducer.nextStep
});

const mapDispatchToProps = { setNextStep };

class NextStep extends React.Component<Props, State> {
	hasTodos: boolean;
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.hasTodos = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		this.lang = {
			cancel: T('default.cancel'),
			save: T('default.save'),
			nextStepCoaching: T('admin.newSalesProcess.nextStepCoaching'),
			whatCountsAsHavingANextStep: T('admin.newSalesProcess.whatCountsAsHavingANextStep'),
			onlyAppointments: T('admin.newSalesProcess.onlyAppointments'),
			bothCallsAndAppointments: T('admin.newSalesProcess.bothCallsAndAppointments'),
			bothActivitiesAndAppointments: T('admin.newSalesProcess.bothActivitiesAndAppointments'),
			backToChecklist: T('admin.newSalesProcess.backToChecklist'),
			descriptionNextStep: T('admin.newSalesProcess.descriptionNextStep')
		};
		this.state = { nextStep: cloneDeep(p.nextStep) };
	}

	setOnlyAppointments = (value: boolean) => {
		const { nextStep } = this.state;
		this.setState({ nextStep: { ...nextStep, onlyAppointments: value } });
	};

	render() {
		const { reject, setNextStep } = this.props;
		const { onlyAppointments } = this.state.nextStep;
		const classNames = new BemClass('NextStep');
		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.nextStepCoaching}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={() => {
										reject();
									}}
									supertitle={undefined}
									className={undefined}
									noIcon
									next={false}
								/>
								<EditorHeaderButton
									title={this.lang.save}
									supertitle={this.lang.backToChecklist}
									disabled={false}
									onClick={() => {
										const { nextStep } = this.state;
										reject();
										setNextStep({ ...nextStep, active: true });
									}}
									next
									className="NextStep__EditorHeaderButton"
									noIcon={false}
								/>
							</div>
						</Flex>
					</Card>
					<div className={classNames.elem('content').b()}>
						<Headline>{this.lang.nextStepCoaching}</Headline>
						<Text color="grey-11">{this.lang.descriptionNextStep}</Text>
						<Block space="mtxl">
							<Title size="lg">{this.lang.whatCountsAsHavingANextStep}</Title>
						</Block>
						<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
							<ButtonBox
								selected={onlyAppointments}
								onClick={() => this.setOnlyAppointments(true)}
								icon="calendar"
								title={this.lang.onlyAppointments}
							/>
							<ButtonBox
								className={classNames.elem('bothCallsAndAppointments').b()}
								selected={!onlyAppointments}
								onClick={() => this.setOnlyAppointments(false)}
								icon={this.hasTodos ? 'call-list' : 'activity'}
								title={
									this.hasTodos
										? this.lang.bothCallsAndAppointments
										: this.lang.bothActivitiesAndAppointments
								}
							/>
						</Flex>
					</div>
				</Flex>
			</Fade>
		);
	}
}

export const detached = NextStep;

const Component = connect(mapStateToProps, mapDispatchToProps)(NextStep);

export default Component;

import { insertAfter } from 'App/babel/store/helpers/array';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import generateVisitFilter from 'App/babel/filters/Visit';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';

angular.module('upAttributes').service('VisitorAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	'$q',
	function (FilterType, DisplayType, FeatureHelper, AppService, $q) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false
				},
				startDate: {
					title: 'default.date',
					field: 'startDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					sortable: 'startDate',
					selectableColumn: true
				},
				client: {
					title: 'default.account',
					field: 'client.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					link: 'accounts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String
						}
					}
				},
				contact: {
					title: 'default.contact',
					field: 'contact.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'contact.name',
					selectableColumn: false,
					parent: 'contact',
					attr: {
						id: {
							field: 'contact.id',
							type: FilterType.Number
						},
						name: {
							field: 'contact.name',
							type: FilterType.String
						}
					}
				},
				sales: {
					title: 'default.turnover',
					field: 'sales',
					displayType: DisplayType.String,
					selectableColumn: true
				},
				groupStructure: {
					title: 'account.relations.groupSize.groupSize',
					field: 'groupStructure',
					displayType: DisplayType.None,
					selectableColumn: true,
					groupable: false
				},
				location: {
					title: 'address.city',
					field: 'location',
					displayType: DisplayType.String,
					sortable: 'client.mailAddress.city',
					selectableColumn: true
				},
				status: {
					title: 'default.history',
					field: 'status',
					displayType: DisplayType.String,
					selectableColumn: true
				},
				pages: {
					title: 'visitor.pages',
					field: 'pages',
					sortable: 'pages',
					displayType: DisplayType.String,
					selectableColumn: true
				},
				score: {
					title: 'default.score',
					field: 'score',
					sortable: 'score',
					type: FilterType.Number,
					displayType: DisplayType.String,
					selectableColumn: true
				},
				time: {
					title: 'default.time',
					field: 'time',
					displayType: DisplayType.String,
					selectableColumn: true
				},
				assigned: {
					type: FilterType.Number,
					DisplayType: DisplayType.String,
					field: 'assigned',
					title: 'assign.assignedTo',
					elevioId: 242,
					selectableColumn: true
				},
				utmCampaign: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'leadSource.utm.campaign',
					title: 'column.utmCampaign',
					selectableColumn: true,
					sortable: 'leadSource.utm.campaign'
				},
				utmContent: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'leadSource.utm.content',
					title: 'column.utmContent',
					selectableColumn: true,
					sortable: 'leadSource.utm.content'
				},
				utmMedium: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'leadSource.utm.medium',
					title: 'column.utmMedium',
					selectableColumn: true,
					sortable: 'leadSource.utm.medium'
				},
				utmSource: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'leadSource.utm.source',
					title: 'column.utmSource',
					selectableColumn: true,
					sortable: 'leadSource.utm.source'
				},
				utmTerm: {
					type: FilterType.String,
					DisplayType: DisplayType.String,
					field: 'leadSource.utm.term',
					title: 'column.utmTerm',
					selectableColumn: true,
					sortable: 'leadSource.utm.term'
				},
				leadSource: {
					title: 'default.leadsource',
					field: 'leadSource',
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'leadSource.type',
					attr: {
						type: {
							field: 'leadSource.type',
							type: FilterType.Number
						},
						source: {
							field: 'leadSource.source',
							type: FilterType.String
						},
						value: {
							field: 'leadSource.value',
							type: FilterType.String
						}
					}
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true
				},
				clientJourneyStep: {
					title: 'default.journeyStepClient',
					field: 'client.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				contactJourneyStep: {
					title: 'default.journeyStepContact',
					field: 'contact.journeyStep',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false
				},
				country: {
					title: 'visit.country',
					field: 'country',
					displayType: DisplayType.String,
					selectableColumn: true
				}
			};

			// Alt.namespace
			attributes.account = angular.copy(attributes.client);
			attributes.account.selectableColumn = false;

			var filters = [
				{
					field: 'startDate',
					title: 'default.date',
					inputType: 'date',
					type: 'dateRange'
				},
				{
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					search: true,
					title: 'default.name',
					field: 'client.id',
					multiComparitors: true,
					parent: 'default.account'
				},
				{
					listTitle: 'default.existingClient',
					title: 'default.existingClient',
					field: 'client.clientIds',
					type: 'notEmptyArray',
					inputType: 'boolean'
				}
			];

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			var standardFilters = {
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'contact.name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				StartDate: {
					field: 'startDate',
					title: 'default.date',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets
				},
				Score: {
					title: 'default.score',
					field: 'score',
					type: 'range',
					inputType: 'range'
				},
				PageUrl: {
					field: 'pages.url',
					resource: 'LeaderPage',
					key: 'url',
					keyType: 'string',
					sortOptions: {
						falseValue: {
							attribute: 'pageImpression',
							ascending: false,
							title: 'visitor.pageSortViews'
						},
						trueValue: {
							attribute: 'lastUpdateDate',
							ascending: false,
							title: 'visitor.pageSortDate'
						}
					},
					comparisonTypeToggle: {
						trueValue: 'nmex',
						falseValue: 'mex'
					},
					type: 'raw',
					displayType: 'listLazy',
					rowComponent: 'visitRow',
					searchField: 'name',
					displayText: 'name',
					displaySubtitle: 'url',
					multiComparitors: true,
					search: true,
					title: 'visitor.pages',
					listTitle: 'visitor.page',
					searchFn: function (LeaderPage, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit, sort) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							if (sort) {
								filter.addSort(sort.attribute, sort.ascending);
							} else {
								filter.addSort('pageImpression', false);
							}

							if (term) {
								var Or = filter.orBuilder();
								Or.next();
								Or.addFilter({ field: 'url' }, filter.comparisonTypes.Search, term);
								Or.next();
								Or.addFilter({ field: 'name' }, filter.comparisonTypes.Search, term);
								Or.done();
							}

							return LeaderPage.customer(cId)
								.find(filter.build())
								.then(function (result) {
									var fixedData = result.data.reduce(function (urlMap, item) {
										var lowerCasedUrl = item.url.toLowerCase();
										var mapItem = urlMap[lowerCasedUrl];

										if (mapItem) {
											mapItem.pageImpression += item.pageImpression;

											if (moment(mapItem.lastUpdateDate).isBefore(item.lastUpdateDate)) {
												mapItem.name = item.name;
												mapItem.lastUpdateDate = item.lastUpdateDate;
											}
										} else {
											urlMap[lowerCasedUrl] = {
												url: lowerCasedUrl,
												name: item.name,
												pageImpression: item.pageImpression,
												lastUpdateDate: item.lastUpdateDate
											};
										}

										return urlMap;
									}, {});

									result.data = Object.values(fixedData);

									return result;
								});
						};
					},
					toUrl: function (filter) {
						return { v: filter.value, c: filter.comparisonType };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v,
							comparisonType: rawFilter.c
						};
					},
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var comparisonType = filter.comparisonType || rb.comparisonTypes.MatchExact;

						if (filter.value && filter.value.length) {
							rb.addFilter({ field: 'pages.url' }, comparisonType, filter.value);
						}
					}
				},
				LeadSource: {
					filterName: 'LeadSource',
					field: 'leadSource',
					title: 'default.leadsource',
					columnPath: 'leadSource',
					resource: 'Visit',
					displayType: 'listLeadSource',
					includeFields: ['leadSource'],
					multiComparitors: true,
					type: 'raw',
					dataPromise: function (RequestBuilder, Report, FilterHelper, LeadSource) {
						var cId = AppService.getCustomerId();

						return function (activeFilters) {
							var rb = new RequestBuilder();

							var filters = _.omit(_.cloneDeep(activeFilters), 'LeadSource');

							return LeadSource.customer(cId).getAllSources(
								FilterHelper.parseFilters(filters, 'visitor', rb).build(),
								{ type: 'visits' }
							);
						};
					},
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value, c: filter.comparisonType };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v,
							comparisonType: rawFilter.c
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var selectedTypes = {};
							var selectedSource = {};

							if (filter.comparisonType !== 'NotEquals') {
								var Or = rb.orBuilder();

								_.each(filter.value, function (item) {
									if (!item.source) {
										Or.next();
										Or.addFilter(
											{ field: 'leadSource.type' },
											rb.comparisonTypes.Equals,
											item.type
										);

										selectedTypes[item.type] = true;
									}
								});

								_.each(filter.value, function (item) {
									if (!item.value && !selectedTypes[item.type]) {
										Or.next();
										if (item.type !== 'utm_campaign') {
											Or.addFilter(
												{ field: 'leadSource.source' },
												rb.comparisonTypes.Equals,
												item.source
											);
											Or.addFilter(
												{ field: 'leadSource.type' },
												rb.comparisonTypes.Equals,
												item.type
											);
										} else {
											Or.addFilter(
												{ field: 'leadSource.value' },
												rb.comparisonTypes.Equals,
												item.source
											);
										}

										selectedSource[item.source] = true;
									}
								});

								_.each(filter.value, function (item) {
									if (!selectedSource[item.source] && !selectedTypes[item.type]) {
										Or.next();
										Or.addFilter(
											{ field: 'leadSource.source' },
											rb.comparisonTypes.Equals,
											item.source
										);
										Or.addFilter(
											{ field: 'leadSource.type' },
											rb.comparisonTypes.Equals,
											item.type
										);
										Or.addFilter(
											{ field: 'leadSource.value' },
											rb.comparisonTypes.Equals,
											item.value
										);
									}
								});

								Or.done();
							} else {
								_.each(filter.value, function (item) {
									if (!item.source) {
										var Group = rb.groupBuilder();

										Group.addFilter(
											{ field: 'leadSource.type' },
											rb.comparisonTypes.Equals,
											item.type
										);

										Group.isNotFilter();
										Group.done();

										selectedTypes[item.type] = true;
									}
								});

								_.each(filter.value, function (item) {
									if (!item.value && !selectedTypes[item.type]) {
										var Group = rb.groupBuilder();

										if (item.type !== 'utm_campaign') {
											Group.addFilter(
												{ field: 'leadSource.source' },
												rb.comparisonTypes.Equals,
												item.source
											);
											Group.addFilter(
												{ field: 'leadSource.type' },
												rb.comparisonTypes.Equals,
												item.type
											);
										} else {
											Group.addFilter(
												{ field: 'leadSource.value' },
												rb.comparisonTypes.Equals,
												item.source
											);
										}

										Group.isNotFilter();
										Group.done();

										selectedSource[item.source] = true;
									}
								});

								_.each(filter.value, function (item) {
									if (!selectedSource[item.source] && !selectedTypes[item.type]) {
										var Group = rb.groupBuilder();

										Group.addFilter(
											{ field: 'leadSource.source' },
											rb.comparisonTypes.Equals,
											item.source
										);
										Group.addFilter(
											{ field: 'leadSource.type' },
											rb.comparisonTypes.Equals,
											item.type
										);
										Group.addFilter(
											{ field: 'leadSource.value' },
											rb.comparisonTypes.Equals,
											item.value
										);

										Group.isNotFilter();
										Group.done();
									}
								});
							}
						}
					}
				},
				Account: {
					field: 'client.name',
					grouping: 'account',
					inputType: 'text',
					type: 'text',
					title: 'default.accountName',
					parent: 'default.account'
				},
				HideUnidentified: {
					field: 'client',
					listTitle: 'default.identified',
					title: 'default.identified',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.no', value: null, comparisonType: 'Equals' }
					]
				},
				AccountManager: generateUserFilter(null, {
					field: 'client.user.id',
					title: 'default.accountManagers',
					multiComparitors: false,
					includeFields: ['name'],
					parent: 'default.account'
				}),
				Campaign: {
					filterName: 'Campaign',
					field: 'client.projects.id',
					title: 'default.campaign',
					grouping: 'account',
					columnPath: 'campaigns',
					resource: 'Campaign',
					includeFields: ['name'],
					type: 'list',
					displayType: 'listLazy',
					search: true,
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					}
				},
				AccountCategory: {
					field: 'client.categories.id',
					title: 'default.accountCategories',
					inputType: 'select',
					multiple: true,
					dataPromise: function (customerId, $q, filterName, AppService) {
						var defered = $q.defer();
						var categories = AppService.getCategories('account');

						var categoryMap = _.groupBy(categories, 'categoryType');
						if (filterName.indexOf('Category_') === 0) {
							var categoryId = filterName.split('_')[1];
							defered.resolve({ data: categoryMap[categoryId] });
						} else {
							defered.resolve({ data: categoryMap[0] });
						}

						return defered.promise;
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					multiComparitors: true,
					parent: 'default.account'
				},
				JourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					parent: 'default.account',
					field: 'client.journeyStep',
					title: 'default.journeyStep',
					hide: true,
					dataPromise: function () {
						return $q.when({
							data: _.map(Tools.AppService.getJourneySteps(), function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					}
				},
				IsAssigned: {
					title: 'scoreboard.assigned',
					grouping: 'account',
					hide: true,
					field: 'assigned',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.assigned', value: null, comparisonType: 'NotEquals' },
						{ text: 'scoreboard.unassigned', value: null, comparisonType: 'Equals' }
					]
				},
				Country: generateVisitFilter('country')
			};

			if (AppService.loaded) {
				const metaData = AppService.getMetadata();
				const syncJourneyStatus = metaData.params ? metaData.params.SyncJourneyStatus : false;
				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.JourneyStep.hide = false;
				}
				attributes.journeyStep.selectableColumn = syncJourneyStatus;
				attributes.clientJourneyStep.selectableColumn = !syncJourneyStatus;
				attributes.contactJourneyStep.selectableColumn = !syncJourneyStatus;

				if (
					metaData.params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO)) {
					attributes.groupStructure.hide = true;
				}
			}

			return {
				getAll: function () {
					return attributes;
				},
				filters: filters,
				standardFilters: standardFilters,
				attr: attributes,
				keys: {
					ID: 'id',
					DATE: 'date',
					ACCOUNT: 'account',
					TURNOVER: 'turnover'
				}
			};
		};
	}
]);

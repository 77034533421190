'use strict';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';

angular.module('upDirectives').directive('upActivityRow', [
	'$compile',
	function ($compile) {
		return {
			restrict: 'A',
			priority: 999,
			scope: { activity: '=upActivityRow' },
			link: function (scope, element, iAttrs) {
				// FULASTE FIXEN EVeR
				if (element.is('td')) {
					element = element.parent();
				}

				scope.$watch(
					'activity',
					function (activity) {
						if (activity && !activity.hasOwnProperty('subject')) {
							// visa som inactive om det är ett möte och datum är passerat eller om aktivitet och closed == true
							if (
								(!activity.isAppointment && activity.closeDate) ||
								(activity.isAppointment && moment(activity.endDate).isBefore())
							) {
								element.addClass('inactive');
								element.removeClass('passed');
							} else if (
								!activity.isAppointment &&
								!activity.closeDate &&
								moment(activity.date).endOf('day').isBefore()
							) {
								element.addClass('passed');
								element.removeClass('inactive');
							} else {
								element.removeClass('passed');
								element.removeClass('inactive');
							}
							if (!activity.isAppointment && activity.priority === ACTIVITY_PRIORITY.HIGH) {
								element.addClass('high-priority');
							}
						}
					},
					true
				);
			}
		};
	}
]);

import React from 'react';
import PropTypes from 'prop-types';

import './AccountListWrapper.scss';

const propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.element),
	subtitleElement: PropTypes.element
};

class AccountListWrapper extends React.Component {
	render() {
		return (
			<div className="AccountListWrapper">
				<div className="wrapper-header">
					<h2>{this.props.title}</h2>
					<span>{this.props.subtitle}</span>
					{this.props.subtitleElement}
				</div>
				{this.props.children}
			</div>
		);
	}
}

AccountListWrapper.propTypes = propTypes;
export default AccountListWrapper;

import React from 'react';
import { Block, Button, Text, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';

import './SaveBtn.scss';
import { renderToString } from 'react-dom/server';

type Props = {
	disabled?: boolean;
	saving: boolean;
	visible: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	text?: string;
	disabledReasons?: string[];
};

const SaveBtn = ({ disabled, saving, visible, onClick, text, disabledReasons }: Props) => {
	const classes = new BemClass('AdminSaveBtn');
	classes.mod({ visible });

	const isDisabled = disabled || !!disabledReasons?.length;

	const renderTooltip = () => {
		return renderToString(
			<Block>
				{disabledReasons?.map(reason => (
					<Text key={reason} color="white" size="sm">
						{reason}
					</Text>
				))}
			</Block>
		);
	};

	const saveButton = (
		<Button
			className={classes.b()}
			disabled={isDisabled}
			color="green"
			size="lg"
			loading={saving}
			shadow="high"
			onClick={onClick}
		>
			{text ? T(text) : T('default.save')}
		</Button>
	);
	return disabledReasons?.length ? (
		<Tooltip
			className={classes.elem('disabledReasonsTooltip').b()}
			disabled={!disabledReasons?.length}
			title={renderTooltip()}
		>
			{saveButton}
		</Tooltip>
	) : (
		saveButton
	);
};

export default SaveBtn;

'use strict';
import { Row, Column, Tooltip, Button, Icon, Text, Block } from '@upsales/components';

ReactTemplates.voice.inCall = window.ReactCreateClass({
	getStateFromProps: function (props) {
		return {
			callStart: props.callStart,
			currentCount: this.state ? this.state.currentCount : 0
		};
	},

	getInitialState: function () {
		return this.getStateFromProps(this.props);
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState(this.getStateFromProps(nextProps));
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			activity: t('default.activity'),
			appointment: t('default.appointment'),
			opportunity: t('default.opportunity'),
			order: t('default.order'),
			email: t('mail.sendEmail'),
			hungUp: t('voice.hungUp'),
			showMore: t('voice.showMaximized')
		};
	},

	componentDidMount: function () {
		var self = this;
		this.intervalId = setInterval(function () {
			if (self.state.callStart) {
				self.timer();
			}
		}, 1000);
	},

	componentWillUnmount: function () {
		clearInterval(this.intervalId);
	},

	timer: function () {
		this.setState({ currentCount: Math.round((Date.now() - this.state.callStart) / 1000) });
	},

	generateClock: function (time) {
		var secNum = parseInt(time, 10); // don't forget the second param
		var hours = Math.floor(secNum / 3600);
		var minutes = Math.floor((secNum - hours * 3600) / 60);
		var seconds = secNum - hours * 3600 - minutes * 60;

		if (hours < 10) {
			hours = '0' + hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}

		if (hours > 0) {
			return hours + ':' + minutes + ':' + seconds;
		}

		return minutes + ':' + seconds;
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	render: function () {
		var self = this;
		var time = this.generateClock(this.state.currentCount);
		const caller = this.props.caller;

		if (!this.props.number) {
			return null;
		}

		var hasTime =
			Tools.AppService.getPhoneIntegration() &&
			Tools.AppService.getPhoneIntegration().capabilities &&
			Tools.AppService.getPhoneIntegration().capabilities.pickupHook;

		if (caller && caller.client) {
			return (
				<div>
					{this.props.minimized ? (
						<Row className="dial-info">
							<Column className="caller-name clickable" size={6} onClick={self.props.toggleWindow.bind()}>
								<Tooltip title={this.lang.showMore} position="top">
									<Block space="ptl plxl">
										{caller ? (
											<Text bold size="md" color="white">
												{caller.contact ? caller.contact.name : caller.client.name}
											</Text>
										) : null}
										{hasTime ? (
											<Text size="sm" color="white">
												{time}
											</Text>
										) : null}
									</Block>
								</Tooltip>
							</Column>
							<Column className="caller-options" size={6} align="center">
								<Block space="ptl">
									<Button color="red" onClick={self.props.hangUp.bind(this, true, false)}>
										<Text bold color="white">
											<Icon name="phone-fail" className="hangup-icon" /> {this.lang.hungUp}
										</Text>
									</Button>
								</Block>
							</Column>
						</Row>
					) : (
						<React.Fragment>
							<div className="meta">
								<h2 className="clickable" onClick={self.props.doAction.bind(self, 'goTo')}>
									{caller.contact ? caller.contact.name : caller.client.name}
								</h2>
								{caller.contact ? (
									<div
										className="subtitle clickable"
										onClick={self.props.doAction.bind(self, 'goToAccount')}
									>
										{caller.client.name}
									</div>
								) : null}
								{hasTime ? <span className="number-row">{time}</span> : null}
							</div>
							<button
								type="button"
								id="hang-up-btn"
								className="phone-btn"
								onClick={self.props.hangUp.bind(this, true, false)}
							>
								<b className="fa fa-phone"></b>
							</button>
							<div className="activity-list">
								<ul>
									<li onClick={self.props.doAction.bind(self, 'activity')}>
										<i className="up-icon-activity"></i>
										{this.lang.activity}
									</li>
									<li onClick={self.props.doAction.bind(self, 'appointment')}>
										<i className="fa fa-fw fa-calendar"></i>
										{this.lang.appointment}
									</li>
									<li onClick={self.props.doAction.bind(self, 'opportunity')}>
										<i className="up-icon-pipeline"></i>
										{this.lang.opportunity}
									</li>
									<li onClick={self.props.doAction.bind(self, 'order')}>
										<i className="fa fa-fw fa-dollar"></i>
										{this.lang.order}
									</li>
									<li onClick={self.props.doAction.bind(self, 'mail')}>
										<i className="fa fa-fw fa-envelope"></i>
										{this.lang.email}
									</li>
								</ul>
							</div>
						</React.Fragment>
					)}
				</div>
			);
		} else {
			return (
				<React.Fragment>
					{this.props.minimized ? (
						<Row className="dial-info">
							<Column className="caller-name clickable" size={6} onClick={self.props.toggleWindow.bind()}>
								<Tooltip title={this.lang.showMore} position="top">
									<Block space="ptl plxl">
										<Text bold size="md" color="white">
											{self.props.number}
										</Text>
										{hasTime ? (
											<Text size="sm" color="white">
												{hasTime}
											</Text>
										) : null}
									</Block>
								</Tooltip>
							</Column>
							<Column className="caller-options" size={6} align="center">
								<Block space="ptl">
									<Button color="red" onClick={self.props.hangUp.bind(this, true, false)}>
										<Text bold color="white">
											<Icon name="phone-fail" className="hangup-icon" /> {this.lang.hungUp}
										</Text>
									</Button>
								</Block>
							</Column>
						</Row>
					) : (
						<div>
							<div className="meta">
								<span className="number-row">
									<b className="fa fa-volume-control-phone"></b> {self.props.number}
								</span>
								{hasTime ? <span className="number-row">{time}</span> : null}
							</div>
							<button
								type="button"
								id="hang-up-btn"
								className="phone-btn"
								onClick={self.props.hangUp.bind(this, true, false)}
							>
								<b className="fa fa-phone"></b>
							</button>
						</div>
					)}
				</React.Fragment>
			);
		}
	}
});

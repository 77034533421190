'use strict';

angular.module('domain.admin').controller('AdminCategory', [
	'AppService',
	'$scope',
	'$stateParams',
	'$translate',
	'AccountCategoryType',
	'ContactCategoryType',
	'AccountCategory',
	'ContactCategory',
	function (
		AppService,
		$scope,
		$stateParams,
		$translate,
		AccountCategoryType,
		ContactCategoryType,
		AccountCategory,
		ContactCategory
	) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		var rootData = (AdminSubCtrl.rootData = AdminCtrl.getRootObject());
		var pageData = (rootData.pageData = {
			merge: null,
			isMerging: false
		});

		rootData.pageComponent = 'category';
		rootData.pageLoading = true;

		pageData.actions = {
			removeCategoryType: function (categoryType) {
				if (!categoryType.hasOwnProperty('id')) {
					pageData.categoryTypes = _.filter(pageData.categoryTypes, function (categoryType) {
						return categoryType.id !== undefined;
					});
					$scope.$apply();
				} else {
					(pageData.type === 'account' ? AccountCategoryType : ContactCategoryType)
						.customer(AdminSubCtrl.customerId)
						.delete(categoryType)
						.then(function (res) {
							if (res && !res.error) {
								pageData.categoryTypes = _.filter(pageData.categoryTypes, function (_categoryType) {
									return _categoryType.id !== categoryType.id;
								});
							}
						})
						.finally(function () {});
				}
			},
			removeCategory: function (category) {
				(pageData.type === 'account' ? AccountCategory : ContactCategory)
					.customer(AdminSubCtrl.customerId)
					.delete(category)
					.then(function (res) {
						if (res && !res.error) {
							var categoryType = _.find(pageData.categoryTypes, { id: category.categoryType });

							if (categoryType) {
								categoryType.children = categoryType.children.filter(function (_category) {
									return _category.id !== category.id;
								});
							}
						}
					})
					.finally(function () {});
			},
			saveCategory: function (category) {
				(pageData.type === 'account' ? AccountCategory : ContactCategory)
					.customer(AdminSubCtrl.customerId)
					.save(category)
					.then(function (res) {
						if (res && !res.error && res.data) {
							var category = res.data;
							var categoryType = _.find(pageData.categoryTypes, { id: category.categoryType });

							if (categoryType) {
								var index = _.findIndex(categoryType.children, { id: category.id });

								if (index > -1) {
									categoryType.children[index] = category;
								} else {
									categoryType.children.unshift(category);
								}
							}
						}
					});
			},
			saveCategoryType: function (categoryType) {
				var children = categoryType.children;
				delete categoryType.children;

				(pageData.type === 'account' ? AccountCategoryType : ContactCategoryType)
					.customer(AdminSubCtrl.customerId)
					.save(categoryType)
					.then(function (res) {
						if (res && !res.error && res.data) {
							var index;

							if (!categoryType.hasOwnProperty('id')) {
								index = _.findIndex(pageData.categoryTypes, function (categoryType) {
									return categoryType.id === undefined;
								});
							} else {
								index = _.findIndex(pageData.categoryTypes, { id: categoryType.id });
							}

							var _categoryType = res.data;

							_categoryType.children = children ? children : [];

							if (index > -1) {
								pageData.categoryTypes[index] = _categoryType;
							} else {
								pageData.categoryTypes.unshift(_categoryType);
							}
						}
					})
					.finally(function () {});
			},
			mergeCategories: function (mergeObj) {
				rootData.pageData.isMerging = true;

				(pageData.type === 'account' ? AccountCategory : ContactCategory)
					.customer(AdminSubCtrl.customerId)
					.merge(mergeObj)
					.then(function (res) {
						if (res && res.data && !res.error) {
							var category = res.data;

							var categoryType = _.find(pageData.categoryTypes, function (categoryType) {
								return categoryType.id === category.categoryType;
							});

							if (categoryType) {
								var categoryIndex = _.findIndex(categoryType.children, function (_category) {
									return _category.id === category.id;
								});

								if (categoryIndex > -1) {
									categoryType.children[categoryIndex] = category;
								}

								categoryType.children = _.filter(categoryType.children, function (_category) {
									return _category.id !== mergeObj.from;
								});
							}
						}
					})
					.finally(function () {
						pageData.actions.toggleMerge(pageData.merge);
						rootData.pageData.isMerging = false;
					});
			},
			toggleMerge: function (merge) {
				if (pageData.merge === merge) {
					pageData.merge = null;
				} else {
					pageData.merge = merge;
				}
				$scope.$apply();
			},
			onNewActivityType: function () {
				pageData.categoryTypes.unshift({
					name: '',
					children: []
				});
				$scope.$apply();
			}
		};

		var init = function () {
			var type = (pageData.type = $stateParams.type || 'account');

			var categories = AppService.getCategories(type);
			var categoryTypes = AppService.getCategoryTypes(type);

			categoryTypes.push({
				id: 0,
				name:
					$translate.instant('default.default') +
					'  ' +
					$translate.instant('default.' + type + 'Categories').toLowerCase()
			});

			var categoryTypeMap = _.reduce(
				categories,
				function (res, category) {
					var array = res[category.categoryType];
					if (array) {
						array.push(category);
					} else {
						res[category.categoryType] = [category];
					}
					return res;
				},
				{}
			);

			/* AppService clones categoryTypes so it is fine to add keys here */
			_.each(categoryTypes, function (categoryType) {
				categoryType.children = categoryTypeMap[categoryType.id] || [];
			});

			pageData.categoryTypes = categoryTypes;
			AdminSubCtrl.customerId = AppService.getCustomerId();
			rootData.pageLoading = false;
		};

		AppService.loadedPromise.then(init);
	}
]);

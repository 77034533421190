'use strict';

ReactTemplates.mailCampaign.tableSearch = window.ReactCreateClass({
	getInitialState: function () {
		return {
			value: ''
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			search: t('default.search')
		};
	},

	UNSAFE_componentWillReceiveProps: function (nextProps) {
		if (nextProps.value !== this.props.value) {
			this.setState({ value: nextProps.value });
		}
	},

	onChange: function (e) {
		this.setState({ value: e.target.value });

		if (this.props.onChange) {
			this.props.onChange(e.target.value);
		}
	},

	clear: function () {
		this.setState({ value: '' });

		if (this.props.onChange) {
			this.props.onChange('');
		}
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	render: function () {
		var classNames = 'table-search';

		if (this.props.className) {
			classNames += ' ' + this.props.className;
		}

		if (this.state.value.length) {
			classNames += ' has-value';
		}

		return (
			<div className={classNames}>
				<input
					style={{ width: this.props.width ? this.props.width + 'px' : 'auto' }}
					type="text"
					placeholder={this.props.placeholder || this.lang.search}
					value={this.state.value}
					onChange={this.onChange}
				/>
				<b className="fa fa-search"></b>
				<button type="button" onClick={this.clear} tabIndex="-1">
					<b className="fa fa-times-circle"></b>
				</button>
			</div>
		);
	}
});

'use strict';

angular.module('upResources').service('MapUserDefinedObject', [
	'MapHelpers',
	function (MapHelpers) {
		return function (payload) {
			var userDefinedObject = payload.userDefinedObject;

			MapHelpers.mapCategories(userDefinedObject);

			payload.userDefinedObject = userDefinedObject;

			return payload;
		};
	}
]);

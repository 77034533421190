'use strict';

angular.module('upAttributes').service('ProjectAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: false,
					selectableColumn: false,
					filterable: false
				},
				userId: {
					title: 'default.user',
					field: 'userId',
					type: FilterType.Number,
					displayType: DisplayType.RefObject,
					filterName: 'userId',
					refParams: {
						type: 'user',
						field: 'name',
						identifyer: 'id',
						refType: 'activity'
					},
					comparisonTypes: ['Equals', 'NotEquals'],
					inputType: 'select',
					groupable: true,
					sortable: false,
					selectableColumn: true,
					filterable: true
				}
			};
			return {
				getAll: function () {
					return attributes;
				}
			};
		};
	}
]);

import logError from 'App/babel/helpers/logError';

angular.module('domain.contact').controller('CampaignUdo', [
	'$scope',
	'AppService',
	'$stateParams',
	'UserDefinedObject',
	'RequestBuilder',
	'$upModal',
	function ($scope, AppService, $stateParams, UserDefinedObject, RequestBuilder, $upModal) {
		var CampaignCtrl = $scope.CampaignCtrl;
		var CampaignUdoCtrl = this;

		CampaignUdoCtrl.items = [];

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.added', function (e, added) {
			if (added.project && added.project.id === CampaignCtrl.campaign.id) {
				CampaignUdoCtrl.items.push(added);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.deleted', function (e, deleted) {
			var index = _.findIndex(CampaignUdoCtrl.items, { id: deleted.id });

			if (index !== -1) {
				CampaignUdoCtrl.items.splice(index, 1);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.updated', function (e, updated) {
			if (updated.project && updated.project.id === CampaignCtrl.campaign.id) {
				var index = _.findIndex(CampaignUdoCtrl.items, { id: updated.id });

				if (index !== -1) {
					CampaignUdoCtrl.items[index] = updated;
				}
			}
		});

		var preventEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();
		};

		CampaignUdoCtrl.removeItem = function (item, e) {
			preventEvent(e);
			UserDefinedObject.setId(CampaignUdoCtrl.udo.id).delete(item);
		};

		CampaignUdoCtrl.editItem = function (item, e) {
			preventEvent(e);

			$upModal.open('editUserDefinedObject', {
				typeId: CampaignUdoCtrl.udo.id,
				id: item.id
			});
		};

		CampaignUdoCtrl.selectLink = function (url, e) {
			e.stopPropagation();
			window.open(url, '_blank');
		};

		var getItems = function () {
			CampaignUdoCtrl.loading = true;
			var filters = new RequestBuilder();
			filters.addFilter(
				UserDefinedObject.attr.campaign.attr.id,
				filters.comparisonTypes.Equals,
				CampaignCtrl.campaign.id
			);
			filters.addSort(UserDefinedObject.attr.regDate, true);

			return UserDefinedObject.setId(CampaignUdoCtrl.udo.id)
				.find(filters.build())
				.then(function (res) {
					CampaignUdoCtrl.items = res.data;
					CampaignCtrl.udoTotal[CampaignUdoCtrl.udo.id] = res.metadata.total;
					CampaignUdoCtrl.loading = false;
				});
		};

		var init = function () {
			var cacheKey = 'userDefined' + $stateParams.udoId;
			var fields = AppService.getCustomFields(cacheKey);
			var categoryTypes = AppService.getCategoryTypes(cacheKey);

			CampaignUdoCtrl.udo = CampaignCtrl.udo.find(function (obj) {
				return obj.id === parseInt($stateParams.udoId);
			});

			// Set up columns
			CampaignUdoCtrl.columns = [];

			angular.forEach(fields, function (field) {
				if (field.visible && field.datatype !== 'Text') {
					CampaignUdoCtrl.columns.push(field);
				}
			});

			CampaignUdoCtrl.columns = _.sortBy(CampaignUdoCtrl.columns, 'sortId');

			if (categoryTypes.length) {
				angular.forEach(categoryTypes, function (type) {
					CampaignUdoCtrl.columns.push(type);
				});
			}
			CampaignUdoCtrl.columns = CampaignUdoCtrl.columns.slice(0, 6);

			getItems();
		};

		AppService.loadedPromise.then(init).catch(error => {
			logError(error, 'Cannot get campaign UDO items');
		});
	}
]);

'use strict';

angular.module('upResources').factory('InkyParser', [
	'$resource',
	'URL',
	'API',
	'ResourceHelper',
	function InkyParser($resource, URL, API, ResourceHelper) {
		var Model = {};
		var apiUrl = URL + API;
		var baseUrl = apiUrl + 'inkyParser/';
		var helper = new ResourceHelper();
		var Resource = $resource(
			baseUrl,
			{},
			{
				parse: { method: 'POST', isArray: false, url: baseUrl + 'parse/' },
				getCss: { method: 'GET', isArray: false, url: baseUrl + 'getCss/' }
			}
		);

		Model.parse = function (markdown, options) {
			var params = _.extend({ markdown: markdown }, options || {});

			var promise = Resource.parse(params).$promise;

			if (!options.skipNotification) {
				promise.catch(function () {
					// Show error notification
					helper.errorNotification('default.error', 'error.generateTemplate');
				});
			}

			return promise;
		};

		Model.parsePreview = function (markdown, options) {
			var params = _.extend({ markdown: markdown, preview: true }, options || {});

			return Resource.parse(params).$promise;
		};

		Model.getCss = function (preview, options) {
			var params = _.extend({}, options || {});

			if (preview) {
				params.preview = true;
			}

			return Resource.getCss(params).$promise;
		};

		return Model;
	}
]);

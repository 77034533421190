import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import StackedDateInput from '../../../Inputs/StackedDateInput/StackedDateInput';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { insertAfter } from 'App/babel/store/helpers/array';

export default class DateInput extends React.Component {
	constructor(props) {
		super(props);
		const AppService = getAngularModule('AppService');
		const FeatureHelper = getAngularModule('FeatureHelper');

		this.DEFAULT_PRESETS = [
			'currentMonth',
			'currentQuarter',
			'currentWeek',
			'currentYear',
			'lastMonth',
			'lastWeek',
			'lastYear',
			'lastXdays',
			'custom'
		];

		const brokenFiscalYearEnabled =
			AppService.getMetadata().params.brokenFiscalYearEnabled &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR);

		if (brokenFiscalYearEnabled) {
			insertAfter(this.DEFAULT_PRESETS, 'currentQuarter', 'currentFiscalQuarter');
			insertAfter(this.DEFAULT_PRESETS, 'currentYear', 'currentFiscalYear');
			insertAfter(this.DEFAULT_PRESETS, 'lastYear', 'lastFiscalYear');
		}
	}

	updateDateFilter = newFilter => {
		this.props.onChange(newFilter.value, newFilter);
	};

	render() {
		const { filter, autoFocus, config } = this.props;
		const presets = Array.isArray(config.presets) && config.presets.length ? config.presets : this.DEFAULT_PRESETS;

		return (
			<CriteriaRowWrapper>
				<StackedDateInput
					config={{ presets }}
					filter={filter}
					onChange={this.updateDateFilter}
					autoFocus={autoFocus}
				/>
			</CriteriaRowWrapper>
		);
	}
}

DateInput.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool.isRequired
};

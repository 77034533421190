'use strict';

var campaignSelectDir = 'upCampaigns';
angular
	.module('upDirectives')
	.directive(campaignSelectDir, function (Campaign, selectHelper, AppService) {
		return selectHelper.getDirectiveObject(campaignSelectDir, {
			resource: Campaign,
			cachedTotal: function ($attrs) {
				if ($attrs.showInactive === undefined) {
					return AppService.getTotals('activeCampaigns');
				} else {
					return AppService.getTotals('campaigns');
				}
			},
			ajax: true,
			asIds: false,
			idAttr: Campaign.attr.id,
			titleAttr: Campaign.attr.name,
			addEvent: 'campaign.added',
			sorting: { field: Campaign.attr.name, ascending: true },
			filterType: 'campaign',
			goTo: function (obj, $state) {
				return $state.go('campaign.dashboard', { id: obj.id }, { reload: true });
			},
			filters: function (rb, $attrs) {
				if ($attrs.showInactive === undefined) {
					rb.addFilter(Campaign.attr.active, rb.comparisonTypes.Equals, true);
				}
			}
		});
	})
	.directive(campaignSelectDir + 'Ids', function (Campaign, selectHelper, AppService) {
		return selectHelper.getDirectiveObjectIds(campaignSelectDir + 'Ids', {
			resource: Campaign,
			cachedTotal: function ($attrs) {
				if ($attrs.showInactive === undefined) {
					return AppService.getTotals('activeCampaigns');
				} else {
					return AppService.getTotals('campaigns');
				}
			},
			ajax: true,
			asIds: true,
			idAttr: Campaign.attr.id,
			titleAttr: Campaign.attr.name,
			addEvent: 'campaign.added',
			sorting: { field: Campaign.attr.name, ascending: true },
			filterType: 'campaign',
			goTo: function (obj, $state) {
				return $state.go('campaign.dashboard', { id: obj.id }, { reload: true });
			},
			filters: function (rb, $attrs) {
				if ($attrs.showInactive === undefined) {
					rb.addFilter(Campaign.attr.active, rb.comparisonTypes.Equals, true);
				}
			}
		});
	});

import logError from 'App/babel/helpers/logError';
import history from 'App/pages/routes/history';

angular.module('domain.admin').controller('AdminCtrl', [
	'$scope',
	'AppService',
	'$state',
	'StandardIntegration',
	'$safeApply',
	function ($scope, AppService, $state, StandardIntegration, $safeApply) {
		var AdminCtrl = this;
		var customerId;

		$scope.$on('standardIntegrationUser.updated', function (e, updated) {
			const { userSettings, masterIntegration: integration } = updated;

			if (updated.masterIntegration.userOnly) {
				const userIndex = _.findIndex(AdminCtrl.sidebarData.integrations.userConfigurable, {
					id: integration.id
				});

				if (userSettings.active && userIndex === -1) {
					AdminCtrl.sidebarData.integrations.userConfigurable.push({
						id: integration.id,
						name: integration.name,
						color: integration.color,
						imageLink: integration.imageLink
					});
					AdminCtrl.sidebarData.integrations.userConfigurable = _.sortBy(
						AdminCtrl.sidebarData.integrations.userConfigurable,
						'name'
					);
				} else if (!userSettings.active && userIndex > -1) {
					AdminCtrl.sidebarData.integrations.userConfigurable.splice(userIndex, 1);
				}
			}
		});

		$scope.$on('standardIntegration.updated', function (e, updated) {
			var isActive = updated.active;

			StandardIntegration.get(updated.integrationId)
				.then(function (res) {
					var index = _.findIndex(AdminCtrl.sidebarData.integrations.global, { id: res.data.id });

					if (res.data.userConfigurable) {
						var userIndex = _.findIndex(AdminCtrl.sidebarData.integrations.userConfigurable, {
							id: res.data.id
						});
					}
					if (isActive) {
						var toPush = {
							id: res.data.id,
							name: res.data.name,
							color: res.data.color,
							imageLink: res.data.imageLink
						};
						if (index === -1) {
							AdminCtrl.sidebarData.integrations.global.push(toPush);
							AdminCtrl.sidebarData.integrations.global = _.sortBy(
								AdminCtrl.sidebarData.integrations.global,
								'name'
							);
						}

						if (userIndex === -1) {
							AdminCtrl.sidebarData.integrations.userConfigurable.push(toPush);
							AdminCtrl.sidebarData.integrations.userConfigurable = _.sortBy(
								AdminCtrl.sidebarData.integrations.userConfigurable,
								'name'
							);
						}
					} else {
						if (index !== -1) {
							AdminCtrl.sidebarData.integrations.global.splice(index, 1);
						}

						if (userIndex !== -1) {
							AdminCtrl.sidebarData.integrations.userConfigurable.splice(userIndex, 1);
						}
					}
				})
				.catch(error => {
					logError(
						error,
						'ui/app/upsales/domain/admin/controllers/standardIntegration/integrations.js updateSearch'
					);
				});
		});

		AdminCtrl.getRootObject = function () {
			return {
				pageLoading: false,
				saving: false,
				saveVisible: false,
				pageComponent: 'dashboard',
				pageData: null,
				getUrlFromState: getUrlFromState,
				onChange: function () {},
				onSave: function () {},
				sidebarSearch: function (str) {
					AdminCtrl.sidebarData.outsideSearch = str;
					$safeApply($scope);
				}
			};
		};

		var setPage = function (state, params) {
			if (state === 'ticketRules') {
				history.push('/admin/customer-support/rules');
				return;
			}
			if (state === 'customerSupport') {
				history.push('/admin/customer-support/verify');
				return;
			} else if (state === 'projectplan') {
				history.push('/admin/projectplan');
				return;
			} else if (state === 'ticketFields') {
				history.push('/admin/customer-support/fields');
				return;
			} else if (state === 'ticketResponseTemplates') {
				history.push('/admin/customer-support/responsetemplates');
				return;
			}
			AdminCtrl.sidebarData.state = state;
			$state.go('administration.' + state, params);
		};

		var isSelectedState = function (stateName, params, opts) {
			if (opts && opts.ignoreParams) {
				return $state.is(stateName);
			}
			params = _.merge({}, params || {}, { customerId: customerId.toString() });
			return $state.is(stateName, params);
		};

		function getUrlFromState(stateName, params) {
			return $state.href(stateName, params || {});
		}

		AdminCtrl.sidebarData = {
			state: (Tools.$state.current?.name ?? '').replace('administration.', ''),
			setPage: setPage,
			isSelectedState: isSelectedState,
			getUrlFromState: getUrlFromState,
			self: null,
			integrations: {
				userConfigurable: [],
				global: [],
				mainApps: []
			},
			outsideSearch: ''
		};

		var init = function () {
			var metadata = AppService.getMetadata();
			customerId = AppService.getCustomerId();
			AdminCtrl.sidebarData.self = AppService.getSelf();
			AdminCtrl.sidebarData.integrations.userConfigurable = _.sortBy(
				metadata.integrations.userConfigurable,
				'name'
			);

			AdminCtrl.sidebarData.integrations.global = _.sortBy(metadata.integrations.active, 'name').filter(
				integration => !integration.userOnly
			);

			AdminCtrl.sidebarData.integrations.mainApps = metadata.mainApps;

			$scope.$on('$stateChangeSuccess', function (e, state, stateParams) {
				AdminCtrl.sidebarData.currentUrl = getUrlFromState(state.name, stateParams);
			});
		};

		// eslint-disable-next-line
		AppService.loadedPromise.then(init);
	}
]);

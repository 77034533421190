'use strict';

ReactTemplates.mailCampaign.clientslist = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			noDataYet: t('default.noData'),
			checkLaterIfData: t('mailCampaign.errors.noData')
		};
	},
	generateLink: function (linkTo, value, label, trailingSlash) {
		var tools = this.props.tools;
		if (linkTo === 'external') {
			return (
				<a target="_blank" href={value}>
					{label}
				</a>
			);
		}
		if (linkTo === 'forms') {
			return <a href={'#/form-overview/' + value}>{label}</a>;
		}

		return (
			<a href={'#/' + tools.AppService.getCustomerId() + '/' + linkTo + '/' + value + (trailingSlash ? '/' : '')}>
				{label}
			</a>
		);
	},
	render: function () {
		var self = this;
		var result = null;
		var data = this.props.data ? this.props.data[this.props.dataProperty] : null;
		if (this.props.data && !this.props.data[this.props.dataProperty]) {
			data = this.props.data;
		}

		var title = this.props.title;
		var value = this.props.value;
		var count = this.props.count;
		var label = this.props.label;
		var linkTo = this.props.linkTo;
		var name = this.props.name;
		var trailingSlash = this.props.trailingSlash;

		if (data && data.length && title) {
			result = _.map(data, function (item) {
				var link = self.generateLink(linkTo, item[value], item[label], trailingSlash);

				return (
					<tr style={{ height: 'auto', lineHeight: '1.3em', border: 'none' }}>
						<td style={{ width: '30px' }}>{item[count]}</td>
						<td
							style={{
								textOverflow: 'ellipsis',
								whiteSpace: 'pre',
								overflow: 'hidden',
								lineHeight: '16px'
							}}
						>
							{link}
						</td>
					</tr>
				);
			});

			return (
				<div
					style={{
						position: 'absolute',
						top: '14px',
						left: '14px',
						right: '14px',
						bottom: '14px'
					}}
				>
					<h3>{name}</h3>
					<p style={{ marginTop: '20px', fontSize: '12px' }}>{title}</p>
					<table>
						<tbody>{result}</tbody>
					</table>
				</div>
			);
		}

		return (
			<div className="no-data">
				<h3>{name}</h3>
				{this.props.noDataImg ? (
					<img
						className="no-data-img"
						style={{ marginTop: '25px' }}
						height="50"
						src={'/img/' + this.props.noDataImg}
					/>
				) : null}
				<h4 className="no-data-msg">{this.props.noDataMsg}</h4>
			</div>
		);
	}
});

export default {
	id: {
		title: 'default.id',
		field: 'id',
		locked: true
	},
	modDate: {
		title: 'default.modDate',
		field: 'modDate'
	},
	regDate: {
		title: 'default.regDate',
		field: 'regDate'
	},
	user: {
		title: 'default.user',
		field: 'user.name'
	}
};

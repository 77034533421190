import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { Title, Card } from '@upsales/components';

const ImportApp = ({ app, onClick = () => {}, disabled = false, size = 104, hideName = false }) => {
	const ImportAppClass = new Bem('ImportApp');

	return (
		<div style={{ height: size, width: size }} onClick={onClick} className={ImportAppClass.mod({ disabled }).b()}>
			<Card
				style={{
					backgroundColor: app.color ? app.color : '#fff',
					backgroundImage: `url(${app.imageLink})`
				}}
			/>
			{hideName ? null : <Title size="sm">{app.name}</Title>}
		</div>
	);
};

export default ImportApp;

import ClientExports from './client';

export default {
	...ClientExports,
	cmLast12Months: {
		field: 'growth.cmLast12Months',
		title: 'accountGrowth.column.cmLast12months'
	},
	cmTrend12Months: {
		field: 'growth.cmTrend12Months',
		title: 'accountGrowth.column.cmTrend12months'
	},
	salesLast12Months: {
		field: 'growth.salesLast12Months',
		title: 'accountGrowth.column.salesLast12months'
	},
	salesTrend12Months: {
		field: 'growth.salesTrend12Months',
		title: 'accountGrowth.column.salesTrend12Months'
	},
	salesPercentOfTurnover: {
		field: 'growth.salesPercentOfTurnover',
		title: 'accountGrowth.column.salesPercentOfTurnover'
	},
	arrPercentOfTurnover: {
		field: 'growth.arrPercentOfTurnover',
		title: 'accountGrowth.column.arrPercentOfTurnover'
	},
	mrrPercentOfTurnover: {
		field: 'growth.mrrPercentOfTurnover',
		title: 'accountGrowth.column.mrrPercentOfTurnover'
	},
	cmPercentOfTurnover: {
		field: 'growth.cmPercentOfTurnover',
		title: 'accountGrowth.column.cmPercentOfTurnover'
	},
	arrLast12Months: {
		field: 'growth.arrLast12Months',
		title: 'accountGrowth.column.arrLast12Months'
	},
	mrrLast12Months: {
		field: 'growth.mrrLast12Months',
		title: 'accountGrowth.column.mrrLast12Months'
	},
	arr: {
		field: 'growth.arr',
		title: 'accountGrowth.column.arr'
	},
	mrr: {
		field: 'growth.mrr',
		title: 'accountGrowth.column.mrr'
	},
	arrOneYearAgo: {
		field: 'growth.arrOneYearAgo',
		title: 'accountGrowth.column.arrOneYearAgo'
	},
	mrrOneYearAgo: {
		field: 'growth.mrrOneYearAgo',
		title: 'accountGrowth.column.mrrOneYearAgo'
	}
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, Icon, Card, Button, Loader, Row, Column, Text, RadioItem } from '@upsales/components';
import { MergeAccount as BuyFromDataSource } from '../../../services/BuyFromDataSource';
import NordicButton from './NordicButton';
import { loadDataSource } from 'Store/reducers/BuyFromDataSourceReducer';

import './BuyFromDataSourceInline.scss';

const mapStateToProps = state => ({
	app: state.BuyFromDataSourceInline.dataSource,
	loading: state.BuyFromDataSourceInline.dataSourceLoading
});

const mapDispatchToProps = {
	loadDataSource
};

function isNordic(account) {
	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC) && account.prospectingId) {
		return true;
	}
	const address = account.mailAddress;

	if (!address) {
		return false;
	}
	if (Tools.AppService.getSelf().userParams.soliditetIsActive === false) {
		return false;
	}
	const { country: countryCode, city, state } = address;
	return ((city || state) && !countryCode) || ['SE', 'DK', 'FI', 'NO'].indexOf(countryCode) > -1;
}

class BuyFromDataSourceInline extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			purchaseType: 'no-data',
			adding: false,
			accountsWithSimilarDuns: []
		};

		const t = window.Tools.$translate;
		this.lang = {
			add: t('default.add'),
			doCreate: t('scoreboard.CreateAccount'),
			doClose: t('ads.closePreview'),
			learnMore: t('default.readMore'),
			noDataSource: {
				title: t('bisnode.notInstalled.title'),
				text: t('bisnode.notInstalled.text'),
				install: t('bisnode.installApp'),
				addWithoutData: t('bisnode.addWithoutDataSource')
			},
			addCompany: t('default.addAccount'),
			credits: t('mail.credits'),
			dunsMatchesExisting: t('BuyFromDataSourceInline.dunsMatchesExisting')
		};

		this.doExpand = this.doExpand.bind(this);
		this.cardContents = this.cardContents.bind(this);
		this.radioChange = this.radioChange.bind(this);
		this.doBuy = this.doBuy.bind(this);
	}

	componentDidMount() {
		const { loadDataSource, dataSourceId } = this.props;
		if (dataSourceId) {
			loadDataSource(dataSourceId);
		}
	}

	doExpand(e) {
		e?.stopPropagation();
		this.setState({ expanded: !this.state.expanded });
	}

	doBuy() {
		const { purchaseType } = this.state;
		const { app, account, onConfirm } = this.props;

		return BuyFromDataSource({
			newAccount: { dunsNo: account.dunsNo },
			dataSource: app,
			account,
			purchaseType,
			type: 'refresh'
		})
			.then(() => {
				onConfirm(account.id);
			})
			.catch(error => {
				console.error('BuyFromDataSourceInline doBuy', error);
			});
	}

	radioChange(purchaseType) {
		if (!purchaseType) {
			return this.setState({ purchaseType: 'no-data' });
		}

		this.setState({ purchaseType });
	}

	getTranslation(text, language) {
		if (!language) {
			language = Tools.AppService.getSelf().language || 'en';
		}

		if (!text) {
			return '';
		}

		language = language.split('-')[0];
		return text[language] || text.en || text;
	}

	cardContents(hasDataSource) {
		const { purchaseType } = this.state;
		const { app, loading, account, onConfirm } = this.props;
		const { noDataSource, learnMore, addCompany } = this.lang;

		if (loading) {
			return <Loader size="sm" />;
		}

		if (hasDataSource && app) {
			const appConfig = JSON.parse(app.configJson);
			const { capabilities } = appConfig;

			const hasMultipleOptions = capabilities.pricing && Object.keys(capabilities.pricing).length > 1;
			const pricingOptions = _.map(capabilities.pricing, pricingOption => {
				return (
					<Row key={pricingOption.endpoint}>
						<Column style={{ visibility: hasMultipleOptions ? 'visible' : 'hidden' }} fixedWidth={30}>
							<RadioItem
								size="sm"
								onChange={value => this.radioChange(value)}
								value={pricingOption.endpoint}
								checked={pricingOption.endpoint === purchaseType}
							/>
						</Column>
						<Column>
							<Text bold={true} size={'sm'}>
								{' '}
								{this.getTranslation(pricingOption.text)}{' '}
								{pricingOption.elevio && (
									<ReactTemplates.elevio articleId={pricingOption.elevio} sidebar={true} />
								)}
							</Text>
							<Text size={'sm'}>{pricingOption.cost + ' ' + Tools.$translate('soliditet.credits')}</Text>
						</Column>
					</Row>
				);
			});

			// no data row
			pricingOptions.unshift(
				<Row key={'no-data'}>
					<Column fixedWidth={30}>
						<RadioItem
							size="sm"
							onChange={value => this.radioChange(value)}
							value={'no-data'}
							checked={'no-data' === purchaseType}
						/>
					</Column>
					<Column>
						<Text bold={true} size={'sm'}>
							{' '}
							{Tools.$translate('default.noData')}
						</Text>
						<Text size={'sm'}>{Tools.$translate('integration.free')}</Text>
					</Column>
				</Row>
			);

			return (
				<div className="inside-card">
					<Text bold={true} size="lg">
						{addCompany}
					</Text>
					{pricingOptions.reverse()}
					<div className="button-darker-background">
						<Button block onClick={this.doBuy} color="bright-green">
							{addCompany}
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="inside-card">
				<h3>
					<Icon name="warning" /> {noDataSource.title}
				</h3>
				<p>
					{noDataSource.text}
					<br />
					<ReactTemplates.elevio text={learnMore} articleId={838} sidebar={true} />
				</p>
				{Tools.AppService.getSelf().administrator && (
					<Button onClick={() => Tools.$state.go('administration.integration', { id: 219 })} block={true}>
						{noDataSource.install}
					</Button>
				)}
				<Button
					onClick={async () => {
						await BuyFromDataSource({
							dataSource: {},
							account: account,
							purchaseType: 'no-data'
						});

						return onConfirm(account.id);
					}}
					block={true}
					shadow="none"
					type="link"
				>
					{noDataSource.addWithoutData}
				</Button>
			</div>
		);
	}

	renderFunction() {
		const { expanded } = this.state;
		const { add, doCreate, doClose } = this.lang;
		const { hasDataSource } = this.props;

		if (expanded) {
			return (
				<div className={hasDataSource ? 'flyout-holder small-flyout' : 'flyout-holder'}>
					<Tooltip title={doClose}>
						<Button size="sm" type="link" className="expandedButton" onClick={this.doExpand}>
							<Icon name="plus" />
							{add}
						</Button>
					</Tooltip>
					<Card>{this.cardContents(hasDataSource)}</Card>
				</div>
			);
		}

		return (
			<Tooltip title={doCreate}>
				<Button size="sm" type="link" onClick={this.doExpand}>
					<Icon name="plus" />
					{add}
				</Button>
			</Tooltip>
		);
	}

	render() {
		if (isNordic(this.props.account)) {
			return <NordicButton account={this.props.account} onConfirm={this.props.onConfirm} />;
		} else {
			return <div className="BuyFromDataSourceInline">{this.renderFunction()}</div>;
		}
	}
}

BuyFromDataSourceInline.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	loadDataSource: PropTypes.func,
	hasDataSource: PropTypes.bool,
	dataSourceId: PropTypes.number,
	account: PropTypes.object,
	app: PropTypes.object,
	loading: PropTypes.bool
};

export const detached = BuyFromDataSourceInline;
export default connect(mapStateToProps, mapDispatchToProps)(BuyFromDataSourceInline);

import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
	Card,
	CardHeader,
	CardContent,
	Loader,
	Headline,
	Title,
	Text,
	Button,
	Link,
	Help,
	Flex
} from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import GroupTree from '../../Modals/CreateNewAccount/GroupTree';
import T from '../../Helpers/translate';
import RequiredFields from '../../Modals/CreateNewAccount/RequiredFields';
import GroupSizeMerge from 'Components/GroupSizeMerge';
import { init, addAccount, reducerMerge, addAllAccounts } from 'Store/reducers/AccountReducer';
import { globalTracker } from 'Helpers/Tracker';
import BillingAddonBanner from 'App/components/BillingAddonBanner';
import { NEXT_STEPS } from 'Components/Billing/BillingAddonCard';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import { useSoftDeployAccess } from 'App/components/hooks';
import history from 'App/pages/routes/history';
import BemClass from '@upsales/components/Utils/bemClass';

const mapStateToProps = state => ({
	soliditetData: state.Account.soliditetData,
	account: state.Account.account,
	companyGroupData: state.Account.companyGroupData,
	unbought: state.Account.unbought,
	tree: state.Account.tree,
	unknowns: state.Account.unknowns,
	config: state.Account.config,
	showCommercial: state.Account.showCommercial,
	size: state.Account.size
});

const mapDispatchToProps = {
	init,
	addAccount,
	reducerMerge,
	addAllAccounts
};

function GroupSize(props) {
	const {
		account,
		showCommercial,
		soliditetData,
		companyGroupData,
		addAccount,
		addAllAccounts,
		reducerMerge,
		tree,
		unknowns,
		config,
		unbought,
		size
	} = props;

	const classes = new BemClass('GroupSize');
	const prospectingPLG = useSoftDeployAccess('PROSPECTING_CEO_ON_BOARD');

	const actions = {
		goToAccount: accountId => Tools.$state.go('account.dashboard', { id: accountId }),
		beginAddAccount: (dataSourceId, account, purchaseType) => {
			const requiredFields = _.filter(Tools.AppService.getCustomFields('account'), function (value) {
				return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
			});

			if (requiredFields.length) {
				Tools.$upModal.open('generic', {
					Component: props => (
						<div id="create-new-account-modal">
							<RequiredFields {...props} />
						</div>
					),
					requiredFields: requiredFields,
					addingAccount: { account, dataSourceId },
					accounts: { [dataSourceId]: config },
					fullscreen: true,
					actions: {
						addAccount: (addingAccount, properties, purchaseType, resolve) => {
							globalTracker.track('Cross sales - add relation from account card');
							addAccount({
								externalId: account[config.idField],
								dataSourceId: dataSourceId,
								pricingKey: purchaseType,
								customValues: properties,
								resolve
							});
						}
					}
				});
			} else {
				globalTracker.track('Cross sales - add relation from account card');
				addAccount({
					externalId: account[config.idField],
					dataSourceId: dataSourceId,
					pricingKey: purchaseType
				});
			}
		},
		merge: reducerMerge,
		beginMerge: (dataSourceId, account) => {
			Tools.$upModal.open('generic', {
				addingAccount: { account, dataSourceId },
				accounts: { [dataSourceId]: config },
				className: 'group-size-merge',
				fullscreen: true,
				Component: props => (
					<div id="create-new-account-modal">
						<GroupSizeMerge {...props} />
					</div>
				),
				actions: {
					merge: reducerMerge
				}
			});
		}
	};

	let content = <Loader />;
	if (account) {
		if (showCommercial) {
			const alias = 'PROSPECTING_PRO_SIGNALS';
			content = prospectingPLG ? (
				<div className="AccountRelations__addon-banner">
					<BillingAddonBanner
						alias={alias}
						loading={false}
						chipText={T('admin.billing.addon.PROSPECTING_PRO.name')}
						title={
							size.total > 1
								? T('prospecting.groupStructure.companyIsPartOfGroupWithXother', {
										companyName: account.name,
										size: numberFormat(size.total - 1)
								  })
								: T('prospecting.groupStructure.findLeadsWithinTheGroupOfCustomer')
						}
						text={T('prospecting.groupStructure.withProYouCanSeeTheFullGroup')}
						location="clientRelations"
						nextSteps={[
							{
								title: T('prospecting.showGroupTreeFor', {
									companyName: account.name
								}),
								action: () => props.init(account)
							},
							...(NEXT_STEPS[alias] || [])
						]}
					/>
				</div>
			) : (
				<div className="GroupTree--NotEligible">
					<div className="GroupTree--Shader" />
					<img src="img/group-size-placeholder.jpg" />
					<div className="Account">
						<Card>
							<CardContent>
								<div className="tag">
									{Tools.$translate('account.relations.groupSize.onlyInGazelle')}
								</div>
								<Headline size="sm">
									{Tools.$translate('account.relations.groupSize.see')} {account.name}{' '}
									{Tools.$translate('account.relations.groupSize.groupSize').toLowerCase()}
								</Headline>
								<Title>
									<span>{Tools.$translate('account.relations.groupSize.deeperDescription1')}</span>
									<b>{account.name}</b>
									<span>{Tools.$translate('account.relations.groupSize.deeperDescription2')}</span>
								</Title>
							</CardContent>
							<img className="upgrade-image" src="img/group-size-image-upgrade.png" />
						</Card>
					</div>
				</div>
			);
		} else if (companyGroupData.length < 2 && !tree) {
			content = (
				<div key="No-Duns" className="NoDuns">
					<Card slice="grey-10" className="Card--noDuns">
						<CardContent>
							<div className="CardContent--Inner">
								<Title size="lg">
									{T('account.relations.noduns.title', { accountName: account.name })}
								</Title>
								<Help articleId={1024} sidebar>
									<Text size="lg">
										{T('account.relations.noduns.description', { accountName: account.name })}
										<Link>{T('default.readMore')}</Link>
									</Text>
								</Help>
							</div>
							<img src="img/no-results-hourglass.svg" />
						</CardContent>
					</Card>
				</div>
			);
		} else {
			content = (
				<GroupTree
					className="AccountRelations__GroupTree"
					rootDuns={soliditetData.rootParentDuns || account.dunsNo}
					dataSourceId={config.id}
					idField={config.idField}
					groupAccount={account}
					accounts={companyGroupData}
					actions={actions}
					tree={tree}
					unknowns={unknowns}
					config={config.companyGroup}
				/>
			);
		}
	}

	const showAddAllButton =
		(config?.id === 'soliditet' && companyGroupData.length > 1) ||
		(config?.id === 'prospecting' && unbought.length <= 100);
	const title = T('account.relations.groupSize.title');
	const description = showCommercial
		? T('account.relations.groupSize.commercialDescription', { accountName: account.name })
		: T('account.relations.groupSize.description', { numCompanies: size.total, numBranch: size.branches });

	const addAllButtonText =
		config?.id === 'prospecting'
			? T('account.relations.groupSize.addEntireGroupSize')
			: T('account.relations.groupSize.buyEntireGroupSize');

	const companyGroupWidgetAccess = Tools.FeatureHelper.hasSoftDeployAccess('COMPANY_GROUP_CARD');

	const goToCompanyGroup = () => {
		history.push(`/companyGroup/${tree.prospectingId}/`);
	};

	return (
		<Card className={classes.b()} color="transparent">
			{showCommercial && prospectingPLG ? null : (
				<CardHeader title={title} subtitle={description}>
					<Flex space="mtm" gap="u3">
						{companyGroupWidgetAccess ? (
							<DefaultButton onClick={goToCompanyGroup}>
								{T('companyGroup.showCompanyGroup')}
							</DefaultButton>
						) : null}
						{showAddAllButton ? (
							<Button disabled={!unbought.length} onClick={addAllAccounts}>
								{addAllButtonText}
							</Button>
						) : null}
					</Flex>
				</CardHeader>
			)}
			{content}
		</Card>
	);
}

GroupSize.propTypes = {
	account: PropType.object,
	soliditetData: PropType.object,
	companyGroupData: PropType.array,
	addAccount: PropType.func,
	reducerMerge: PropType.func,
	addAllAccounts: PropType.func,
	tree: PropType.object,
	unknowns: PropType.array,
	config: PropType.object,
	unbought: PropType.array,
	size: PropType.object,
	showCommercial: PropType.bool
};

export const detached = GroupSize;
export default connect(mapStateToProps, mapDispatchToProps)(GroupSize);

'use strict';

angular.module('upResources').factory('CurrencyRate', [
	'$resource',
	'$q',
	'URL',
	'API',
	function ($resource, $q, URL, API) {
		var Resource = $resource(
			URL + API + 'master/currencyRate/',
			{},
			{
				find: { method: 'GET', isArray: false }
			}
		);

		var Model = {
			find: function () {
				return Resource.find({}).$promise;
			}
		};
		return Model;
	}
]);

import { Component } from 'react';
import PropTypes from 'prop-types';

class TableHeader extends Component {
	constructor(props) {
		super(props);

		this.renderSortIndicator = this.renderSortIndicator.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	renderSortIndicator(active) {
		var className = 'fa ';
		if (active) {
			className += 'fa-sort-' + this.props.type;
			className += this.props.asc ? '-desc' : '-asc';
		} else {
			className += 'fa-sort';
		}
		return <b className={className} />;
	}

	onClick() {
		this.props.onClick(this.props.sort, this.props.currentSort === this.props.sort ? !this.props.asc : false);
	}

	render() {
		const active = this.props.sort === this.props.currentSort;
		return (
			<th onClick={this.onClick} className={'sortable' + (active ? ' active' : '')}>
				{this.props.title} {this.renderSortIndicator(active)}
			</th>
		);
	}
}

TableHeader.defaultProps = {
	type: 'alpha',
	onClick: () => {}
};

TableHeader.propTypes = {
	title: PropTypes.string.isRequired,
	sort: PropTypes.string.isRequired,
	asc: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(['alpha', 'numeric']),
	currentSort: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default TableHeader;

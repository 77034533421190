import React, { Fragment } from 'react';
import { Tabs, Tab } from '@upsales/components';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';

import './Typography.scss';
import fontWeights from './fontWeights';

const TABS = {
	LANDING_PAGE: 'landing-page',
	EMAIL: 'email'
};

class Typography extends React.Component {
	fontFamilies = [
		'Arial, Helvetica, sans-serif',
		'"Arial Black", Gadget, sans-serif',
		'"Comic Sans MS", cursive, sans-serif',
		'Impact, Charcoal, sans-serif',
		'"Lucida Sans Unicode", "Lucida Grande", sans-serif',
		'Tahoma, Geneva, sans-serif',
		'"Trebuchet MS", Helvetica, sans-serif',
		'Verdana, Geneva, sans-serif',
		'Georgia, serif',
		'"Palatino Linotype", "Book Antiqua", Palatino, serif',
		'"Times New Roman", Times, serif',
		'"Courier New", Courier, monospace',
		'"Lucida Console", Monaco, monospace'
	];

	constructor(props) {
		super(props);

		this.state = {
			selectedTab: TABS.LANDING_PAGE
		};
	}

	UNSAFE_componentWillMount() {
		const t = Tools.$translate;
		this.lang = {
			button: t('admin.button'),
			typography: t('admin.typography'),
			typographyInfo: t('admin.typographyInfo'),
			mainTitle: t('admin.mainTitle'),
			secondaryTitle: t('admin.secondaryTitle'),
			preamble: t('admin.preamble'),
			paragraph: t('admin.paragraph'),
			link: t('admin.link'),
			footerLinkText: t('admin.footerLinkText'),
			footerText: t('admin.footerText')
		};

		this.typography = [
			{ title: this.lang.mainTitle, hoverKey: 'h2', modelKey: 'h2', colorModel: 'mainText' },
			{ title: this.lang.secondaryTitle, hoverKey: 'h3', modelKey: 'h3', colorModel: 'secondaryText' },
			{ title: this.lang.preamble, hoverKey: 'ingress', modelKey: 'ingress', colorModel: 'contentTextIngress' },
			{ title: this.lang.paragraph, hoverKey: 'p', modelKey: 'p', colorModel: 'contentText' },
			{ title: this.lang.link, hoverKey: 'a', modelKey: 'link', colorModel: 'linkText', skipSize: true },
			{ title: this.lang.button, hoverKey: 'button', modelKey: null, colorModel: 'buttonText' },
			{ title: this.lang.footerLinkText, hoverKey: 'footerLink', modelKey: null, colorModel: 'footerLinkText' },
			{ title: this.lang.footerText, hoverKey: 'footerText', modelKey: null, colorModel: 'footerText' }
		];
	}

	componentDidUpdate() {
		const { fonts } = this.props;

		if (fonts.length) {
			WebFont.load({
				google: {
					families: fonts.map(font => font.family)
				}
			});
		}
	}

	handleTabChange = tab => {
		this.setState({
			selectedTab: tab
		});
	};

	handleFontChange = (profileModelKey, typo, event) => {
		const { onTypoSelectChange } = this.props;

		onTypoSelectChange(profileModelKey, typo.modelKey, 'fontFamily', event.target.value);
		onTypoSelectChange(profileModelKey, typo.modelKey, 'weight', 'regular');
	};

	renderTypography() {
		const btnClass = 'btn btn-xs up-btn btn-bright-blue no-shadow btn-lined';
		const btnClassSelected = 'btn btn-xs up-btn btn-bright-blue no-shadow';
		const {
			profile,
			fonts,
			onMouseOver,
			onMouseOut,
			onTypoSelectChange,
			onToggleTypography,
			onColorChange
		} = this.props;
		const { selectedTab } = this.state;
		const isForLandingPages = selectedTab === TABS.LANDING_PAGE;
		const profileModelKey = isForLandingPages ? 'typographyLandingPages' : 'typography';
		const ColorPicker = ReactTemplates.bannerEditor.colorPicker;
		const googleFontsMap = new Map();

		fonts.forEach(font => googleFontsMap.set(font.family, font));

		return this.typography.map((typo, i) => {
			const model = profile[profileModelKey][typo.modelKey];

			return (
				<tr
					key={'typo-' + i}
					onMouseOver={() => onMouseOver((isForLandingPages ? 'landing-' : 'email-') + typo.hoverKey)}
					onMouseOut={() => onMouseOut(profileModelKey)}
				>
					<td className="typography-tools">
						<div className="row">
							<div className="col-xs-12">{typo.title}</div>
							<div className="typography-tools col-xs-12">
								<div className="typography-row">
									{typo.modelKey ? (
										<select
											className="font-family-select"
											value={model.fontFamily}
											onChange={this.handleFontChange.bind(this, profileModelKey, typo)}
										>
											{isForLandingPages &&
												fonts.map(font => (
													<option key={font.family} value={font.family}>
														{font.family}
													</option>
												))}
											{this.fontFamilies.map((fam, i) => (
												<option key={'fam-' + i} value={fam}>
													{fam}
												</option>
											))}
										</select>
									) : null}
									{typo.modelKey && !typo.skipSize ? (
										<Fragment>
											{isForLandingPages && googleFontsMap.has(model.fontFamily) ? (
												<select
													className="Typography__WeightSelect"
													value={model.weight}
													onChange={e =>
														onTypoSelectChange(
															profileModelKey,
															typo.modelKey,
															'weight',
															e.target.value
														)
													}
												>
													{googleFontsMap.get(model.fontFamily).variants.map(weight => (
														<option key={weight} value={weight}>
															{fontWeights[weight]}
														</option>
													))}
												</select>
											) : null}
											<select
												className="font-size-select"
												value={model.size}
												onChange={e =>
													onTypoSelectChange(
														profileModelKey,
														typo.modelKey,
														'size',
														e.target.value
													)
												}
											>
												{new Array(34).fill(null).map((size, i) => (
													<option key={'size-' + i + 1} value={i + 1}>
														{i + 1}
													</option>
												))}
											</select>
										</Fragment>
									) : null}
								</div>
								<div className="typography-row">
									<ColorPicker
										value={
											profile[isForLandingPages ? 'colorsLandingPages' : 'colors'][
												typo.colorModel
											]
										}
										presets={this.props.colorVariables}
										isText={false}
										onChange={color =>
											onColorChange(
												isForLandingPages ? 'colorsLandingPages' : 'colors',
												typo.colorModel,
												color
											)
										}
									/>
									{typo.modelKey ? (
										<div className="btn-group" style={{ display: 'inline-block' }}>
											{!googleFontsMap.has(model.fontFamily) ? (
												<button
													type="button"
													className={model.bold ? btnClassSelected : btnClass}
													onClick={() =>
														onToggleTypography(profileModelKey, typo.modelKey, 'bold')
													}
												>
													<b className="fa fa-bold" />
												</button>
											) : null}

											<button
												type="button"
												className={model.underline ? btnClassSelected : btnClass}
												onClick={() =>
													onToggleTypography(profileModelKey, typo.modelKey, 'underline')
												}
											>
												<b className="fa fa-underline" />
											</button>
											<button
												type="button"
												className={model.italic ? btnClassSelected : btnClass}
												onClick={() =>
													onToggleTypography(profileModelKey, typo.modelKey, 'italic')
												}
											>
												<b className="fa fa-italic" />
											</button>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</td>
				</tr>
			);
		});
	}

	render() {
		const t = Tools.$translate;

		return (
			<div className="Typography">
				<div className="admin-section admin-card" id="typography-tab-content">
					<h2>
						{this.lang.typography} <ReactTemplates.elevio articleId={438} sidebar={true} />
					</h2>

					<Tabs selected={this.state.selectedTab} onChange={this.handleTabChange}>
						<Tab id={TABS.LANDING_PAGE} title={t('navbar.formsAndLandingpages')} />
						<Tab id={TABS.EMAIL} title={t('admin.customfieldType.EmailAndDocument')} />
					</Tabs>

					<div className="admin-info-row">
						<div className="info-row-content">
							<div className="admin-table card-table typography-table">
								<table>{this.renderTypography()}</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Typography.propTypes = {
	profile: PropTypes.object.isRequired,
	fonts: PropTypes.array.isRequired,
	onMouseOver: PropTypes.func.isRequired,
	onMouseOut: PropTypes.func.isRequired,
	onTypoSelectChange: PropTypes.func.isRequired,
	onColorChange: PropTypes.func.isRequired,
	onToggleTypography: PropTypes.func.isRequired,
	colorVariables: PropTypes.array
};

window.AdminAccountProfileTypography = Typography;
export default Typography;

import React from 'react';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { Button, ButtonSelect, Icon, Tooltip } from '@upsales/components';
import _ from 'lodash';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FilterCopy from 'Components/SharedViews/FilterCopy';
import NotificationBadge from 'App/components/NotificationBadge';
import T from 'Components/Helpers/translate';

ReactTemplates.upFilters = {};
ReactTemplates.upFilters.components = {};
ReactTemplates.upFilters.filterTypes = {};

ReactTemplates.upFilters.filterMenu = window.ReactCreateClass({
	getActiveFilters: function () {
		return _.reduce(
			this.props.activeFilters,
			function (sum, filter) {
				return filter.inactive || filter.value === undefined || filter.value === '' ? sum : sum + 1;
			},
			0
		);
	},
	getInitialState: function () {
		var defaultSettings = {
			open: false
		};

		this.dataCache = new Tools.DataCache();

		var cachedSettings = JSON.parse(window.localStorage.getItem('upListFilters'));
		var settings = _.assign({}, defaultSettings, cachedSettings);

		var state = {
			qsearchString: null,
			pages: [
				{
					component: ReactTemplates.upFilters.components.filterList,
					props: { filterName: 'menu', dataCache: this.dataCache, tableType: this.props.tableType },
					getTitle: function () {
						const numberActiveFilters = this.getActiveFilters();
						var activeFiltersString = Tools.$translate(
							numberActiveFilters === 1 ? 'filters.activeFilter' : 'filters.activeFilters'
						).toLowerCase();
						var noActiveFiltersString = Tools.$translate('filters.noActiveFilters');

						return numberActiveFilters
							? numberActiveFilters + ' ' + activeFiltersString
							: noActiveFiltersString;
					}.bind(this)
				}
			],
			open:
				this.props.opts && this.props.opts.forceClosedFilter === true
					? false
					: this.props.opts && this.props.opts.alwaysOpen
					? true
					: settings.open
		};

		if (this.props.quickSearch) {
			state.qsearchString = this.props.quickSearch.getValue(this.props);
		}

		if (this.props.open && angular.element(window).width() > 1400) {
			state.open = true;
		}

		if (this.props.opts && this.props.opts.forceClosedFilter === true) {
			state.open = false;
		}

		if (this.props.hideFilters) {
			state.open = false;
		}

		return state;
	},
	UNSAFE_componentWillReceiveProps: function (newProps) {
		if (this.props.quickSearch && !this.quickSearchTimer) {
			this.setState({
				qsearchString: this.props.quickSearch.getValue(newProps)
			});
		}
	},
	componentWillUnmount: function () {
		window.removeEventListener('toggleFilters', this.toggleOpen);
	},
	componentDidMount: function () {
		if (this.state.open && !this.props?.opts?.alwaysOpen) {
			$('#main-content.has-up-list-filters').addClass('filters-open');
		}
		window.addEventListener('toggleFilters', this.toggleOpen);
		this.props.onVisibleChange?.(this.state.open);
	},
	toggleOpen: function () {
		var open = this.state.open;
		if (open) {
			$('#main-content.has-up-list-filters').removeClass('filters-open');
		} else {
			$('#main-content.has-up-list-filters').addClass('filters-open');
		}
		window.localStorage.setItem('upListFilters', JSON.stringify({ open: !open }));
		this.setState({ open: !open });
		// Create event
		var event = new CustomEvent('filtersToggledInstant', {
			bubbles: true,
			cancelable: false,
			detail: { open: !open }
		});

		this.props.onVisibleChange?.(!open);

		// Trigger event
		window.dispatchEvent(event);
		setTimeout(function () {
			// Create event
			var event = document.createEvent('Event');
			event.initEvent('filtersToggled', true, true);

			// Trigger event
			window.dispatchEvent(event);
		}, 200);
	},
	search: function (filter, options) {
		this.props.onChange(filter, options);
	},
	UNSAFE_componentWillMount: function () {
		this.search = _.debounce(this.search, 300);
	},
	onInputChange: function (event) {
		var value = event.target.value;

		this.setState({
			qsearchString: value
		});

		// We use this in UNSAFE_componentWillReceiveProps to avoid overriding the current search string while the user is typing
		if (this.quickSearchTimer) {
			clearTimeout(this.quickSearchTimer);
		}
		this.quickSearchTimer = setTimeout(() => {
			this.quickSearchTimer = null;
		}, 500);

		this.search(this.props.quickSearch.getFilter(value), { action: 'add' });
	},
	onPageChange: function (page, goBack) {
		const pages = [...this.state.pages];
		if (goBack) {
			// Do not remove the root page
			if (pages.length !== 1) {
				pages.pop();
			}
		} else {
			var shouldPageOpen = true;
			var newPageFilterName = page.props.filterName;

			pages.forEach(function (page) {
				var oldPageFilterName = page.props.filterName;

				if (newPageFilterName === oldPageFilterName) {
					shouldPageOpen = false;
				}
			});

			if (shouldPageOpen) {
				pages.push(page);
			}
		}

		this.setState({ pages });
	},
	focusSearch: function () {
		this._search.focus();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	addBtnClick: function (e) {
		globalTracker.track('add', { type: this.props.tableType, location: 'list' });
		this.props.addButton.createEntry(e);
	},
	getFilter: function (name) {
		const { tableType } = this.props;
		return Tools.FilterHelper.filter(name, tableType);
	},
	handleQuickFilterChange: function (name, value) {
		const { activeFilters, tableType } = this.props;
		const filter = activeFilters[name] || this.getFilter(name, tableType);

		if (!filter) {
			return;
		}

		filter.value = value;
		this.props.onChange(filter, { action: 'add' });
	},
	renderQuickPriorityFilter: function () {
		const { quickPriorityFilterName, quickPriorityFilterOptions, activeFilters } = this.props;
		if (!quickPriorityFilterName) return null;

		const filter = activeFilters[quickPriorityFilterName];
		const value = _.get(filter, 'value', 0);

		return (
			<ButtonSelect
				className="quick-prio-filters"
				value={value}
				size="sm"
				options={quickPriorityFilterOptions}
				onChange={value => this.handleQuickFilterChange(quickPriorityFilterName, value)}
			/>
		);
	},
	renderQuickDateFilter: function () {
		const { quickDateFilterName, quickDateFilterOptions, activeFilters } = this.props;
		if (!quickDateFilterName) return null;

		const filter = activeFilters[quickDateFilterName];
		let value = null;

		if (filter && filter.value && filter.value.preset && filter.value.preset !== 'custom') {
			value = filter.value.preset;
		}

		return (
			<ButtonSelect
				className="quick-date-filters"
				value={value}
				size="sm"
				options={quickDateFilterOptions}
				onChange={preset => this.handleQuickFilterChange(quickDateFilterName, { preset })}
			/>
		);
	},
	render: function () {
		var activePage = this.state.pages[this.state.pages.length - 1];
		var self = this;

		var content = this.state.pages.map(function (page, index) {
			var props = _.assign(
				{ key: page.props.filterName },
				self.props,
				{
					onPageChange: self.onPageChange,
					toggleOpen: self.toggleOpen,
					open: self.state.open,
					dataCache: self.dataCache
				},
				page.props
			);
			const Component = page.component;
			return (
				<CSSTransition key={index} classNames="active-page" timeout={{ enter: 100, exit: 100 }}>
					<Component {...props} />
				</CSSTransition>
			);
		});

		var linkButton = null;
		if (this.props.linkButton) {
			const duplicatesAmount = parseInt(this.props.linkButton.getLabel());
			linkButton = (
				<Tooltip title={T('default.duplicatesTooltip', { duplicatesAmount })} style={{ marginRight: '10px' }}>
					<Button
						id="list-view-add-btn"
						onClick={this.props.linkButton.action}
						color="yellow-2"
						shadow="none"
						size="sm"
						className="notification-badge-parent"
					>
						<Icon name="warning" color="black" />
						<NotificationBadge color="yellow-5" amount={duplicatesAmount} maxAmount={99} />
					</Button>
				</Tooltip>
			);
		}

		var addButton = null;

		// Don't remove access if isEnabled is missing
		const addButtonEnabled =
			this.props.addButton && this.props.addButton.hasOwnProperty('isEnabled')
				? this.props.addButton.isEnabled()
				: true;

		if (this.props.addButton && addButtonEnabled) {
			var listTypeClass = 'list-add-btn-' + this.props.tableType;
			var addBtnMarginTop = this.props.hideFilters ? '8px' : '0px';
			if (this.props.secondButton || linkButton) {
				addButton = (
					<div style={{ display: 'inline-block' }}>
						{linkButton}
						{this.props.secondButton ? (
							<Button
								color="super-light-green"
								shadow="none"
								size="sm"
								onClick={this.props.secondButton.action}
								style={{ marginTop: addBtnMarginTop, marginRight: '10px' }}
							>
								<span dangerouslySetInnerHTML={{ __html: this.props.secondButton.getLabel() }}></span>
							</Button>
						) : null}
						<Button
							style={{ marginTop: '10px', marginRight: '10px', marginBottom: '10px' }}
							id="list-view-add-btn"
							onClick={this.addBtnClick}
							color="green"
							size="sm"
							className={listTypeClass + ' list-add btn btn-add-action'}
						>
							<span className="responsive-show-s">
								<b className="fa fa-plus" />
							</span>
							<span className="responsive-hide-s">
								<b className="fa fa-plus" />
								<span style={{ marginLeft: '5px' }}>{this.props.addButton.getLabel()}</span>
							</span>
						</Button>
					</div>
				);
			} else {
				addButton = (
					<button
						style={{ marginTop: '10px', marginBottom: '10px' }}
						id="list-view-add-btn"
						onClick={this.addBtnClick}
						className={listTypeClass + ' list-add btn up-btn btn-add-action btn-sm'}
					>
						<span className="responsive-show-s">
							<b className="fa fa-plus" />
						</span>
						<span className="responsive-hide-s">
							<b className="fa fa-plus" />
							<span style={{ marginLeft: '5px' }}>{this.props.addButton.getLabel()}</span>
						</span>
					</button>
				);
			}
		}

		var quickSearch = null;
		if (this.props.quickSearch) {
			quickSearch = (
				<div id="list-view-qsearch-wrap" className={this.state.qsearchString.length ? 'has-value' : null}>
					<input
						type="text"
						id="list-view-qsearch"
						maxLength="120"
						onChange={this.onInputChange}
						value={this.state.qsearchString}
						placeholder={this.props.quickSearch.getPlaceholder()}
						ref={this.setRef.bind(this, '_search')}
					/>
					<b className="fa fa-search" onClick={this.focusSearch} />
				</div>
			);
		}

		var abort = null;
		if (this.props.selectModeBack) {
			var goBack = function () {
				self.props.selectModeBack(self.props.activeFilters);
			};
			abort = (
				<button className="btn btn-sm up-btn no-shadow btn-link btn-light-grey back-button" onClick={goBack}>
					{Tools.$translate('default.abort')}
				</button>
			);
		}

		const quickPriorityFilter = this.renderQuickPriorityFilter();
		const quickDateFilter = this.renderQuickDateFilter();
		const actionsClassName = classNames('actions', { 'list-filters-open': this.state.open });

		let filterTooltip = T('default.filterNoneSelectedTooltip');
		if (this.getActiveFilters() > 0) {
			filterTooltip = T('default.filterTooltip', { activeFilters: this.getActiveFilters() });
		}

		return (
			<div className={actionsClassName} ref="root">
				{quickPriorityFilter}
				{quickDateFilter}
				{addButton}
				{quickSearch}
				{abort}

				{this.props.hideFilters || this.state.open ? null : (
					<Tooltip
						title={filterTooltip}
						style={{ marginTop: '10px', marginBottom: '10px', marginRight: '20px' }}
					>
						<Button
							id="list-view-add-btn"
							onClick={this.toggleOpen}
							className="notification-badge-parent"
							color="super-light-blue"
							size="sm"
							shadow="none"
						>
							<Icon name="filter" />
							<NotificationBadge color="blue" amount={this.getActiveFilters()} maxAmount={9} />
						</Button>
					</Tooltip>
				)}
				{this.props.hideFilters ? null : (
					<div
						id="list-filters-wrap"
						className={
							(Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME') ? 'remove-frame ' : '') +
							(this.state.open ? 'visible' : '')
						}
					>
						<div className="list-filter-header" onClick={this.toggleOpen}>
							<b className="fa fa-chevron-right" /> {activePage.getTitle()}
						</div>

						<TransitionGroup>{content}</TransitionGroup>
					</div>
				)}
			</div>
		);
	}
});

ReactTemplates.upFilters.components.filterList = window.ReactCreateClass({
	getInitialState: function () {
		return {
			filterSearchString: '',
			showAllFilters: 'true',
			currentTab: this.props.tableType
		};
	},
	filters: {},
	initFilters: function () {
		var self = this;
		var filterService = self.props.filterService;
		var tableType = self.props.tableType;

		var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);
		const hasClientContactTicketFilters = Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_CONTACT_SUPPORT_FILTERS');
		var standardFields = Tools.AppService.getMetadata().standardFields;

		var staticFilters = _.pick(filterService.filterConfigs, function (config) {
			if (config.hide === true) {
				return false;
			} else if (config.unreleasedFeature === 'NEW_FIELDS') {
				if (!hasNewFields) {
					return false;
				}
				var entity = tableType === 'account' ? 'client' : tableType;
				if (entity) {
					entity = entity[0].toUpperCase() + entity.substring(1);
				}
				var field = config.field ? config.field.split('.')[0] : config.field;
				var found = _.find(standardFields[entity], { field });
				if (!found || !found.active) {
					return false;
				}
			} else if (config.unreleasedFeature === 'CLIENT_CONTACT_SUPPORT_FILTERS') {
				if (!hasNewFields || !hasClientContactTicketFilters) {
					return false;
				}
			}

			return true;
		});

		_.each(staticFilters, function (config, filterName) {
			if (config.combinationFilter) {
				var filters = _.reduce(
					config.combinationFilter,
					function (res, config, filterName) {
						res[filterName] = filterService.filterConfigs[filterName];
						return res;
					},
					{}
				);

				staticFilters[filterName] = _.assign({}, config, { filters: filters });
			}
		});

		var dynamicFilters = {};

		var customFields = _.sortBy(filterService.customFields, 'sortId').filter(function (cf) {
			return cf.visible !== 0;
		});

		var customFieldConfigs = Tools.FilterHelper.getConfigsFromCustomFields(tableType, customFields);
		dynamicFilters = _.assign(dynamicFilters, customFieldConfigs);

		if (filterService.relatedEntities && filterService.relatedEntities.length) {
			_.each(filterService.relatedEntities, function (entity) {
				var relatedCustomFields = _.sortBy(Tools.AppService.getCustomFields(entity), 'sortId').filter(function (
					cf
				) {
					return cf.visible !== 0;
				});
				customFieldConfigs = Tools.FilterHelper.getConfigsFromCustomFields(entity, relatedCustomFields);
				dynamicFilters = _.assign(dynamicFilters, customFieldConfigs);
			});
		}

		self.filters = _.assign({}, staticFilters, dynamicFilters);
	},
	UNSAFE_componentWillMount: function () {
		this.initFilters();
	},
	onFilterSearch: function (event) {
		this.setState({
			filterSearchString: event.target.value
		});
	},
	clearFilterSearch: function () {
		this.setState({ filterSearchString: '' });
	},
	toggleShowAllFilters: function (event) {
		var value = event.target.value;
		this.setState({
			showAllFilters: value
		});
	},
	toggleTab: function (type) {
		this.setState({
			currentTab: type
		});
	},
	clearFilters: function () {
		var self = this;
		var filterConfigs = this.props.filterService.filterConfigs;
		if (this.props.onClear) {
			this.props.onClear();
		} else {
			Object.keys(this.props.activeFilters).forEach(function (filterName) {
				var filterConfig = filterConfigs[filterName];

				if (filterConfig && filterConfig.noclear) {
					return;
				}

				self.props.onChange({ filterName: filterName }, { action: 'remove' });
			});
		}
		this.setState({
			showAllFilters: 'true'
		});
	},
	getTypeIcon: function (type) {
		switch (type) {
			case 'account':
				return 'fa fa-home';
			case 'contact':
				return 'fa fa-user';
			case 'activity':
				return 'fa fa-check';
			case 'appointment':
				return 'fa fa-calendar';
			default:
				return '';
		}
	},

	componentDidMount: function () {
		if (this._tabs) {
			this._page.addEventListener('scroll', this.stickTabs);
		}
	},

	componentWillUnmount: function () {
		if (this._tabs) {
			this._page.removeEventListener('scroll', this.stickTabs);
		}
	},

	stickTabs: function (e) {
		if (this._tabs) {
			if (e.target.scrollTop >= 80) {
				this._tabs.classList.add('sticky');
			} else {
				this._tabs.classList.remove('sticky');
			}
		}
	},

	componentDidUpdate: function (prevProps) {
		if (this._tabs) {
			this.stickTabs({ target: this._page });
		}

		if (!_.isEqual(prevProps.filterService, this.props.filterService)) {
			this.initFilters();
			this.forceUpdate();
		}
	},

	render: function () {
		var _this = this;
		var currentTab = this.state.currentTab;
		var hasQueryString = _this.state.filterSearchString && _this.state.filterSearchString.length;
		var showOnlyActiveFilters = _this.state.showAllFilters === 'false';
		var hasMultipleEntities = false;
		var currentEntity;

		var filters = _.chain(_this.filters)
			.pick(function (config, name) {
				var filteDisplayName = '';
				if (typeof config.title === 'function') {
					filteDisplayName = config.title().toLowerCase();
				} else if (config.title) {
					filteDisplayName = Tools.$translate(config.title).toLowerCase();
				}
				var searchTerm = _this.state.filterSearchString ? _this.state.filterSearchString.toLowerCase() : '';
				var quickSearchShow = filteDisplayName.indexOf(searchTerm) !== -1;

				var showActiveFilters = true;

				if (showOnlyActiveFilters) {
					var activeFilter = _this.props.activeFilters[name];

					if (activeFilter === undefined || activeFilter.inactive) {
						showActiveFilters = false;
					}
				}
				if (!currentEntity) {
					currentEntity = config.entity;
				} else if (currentEntity !== config.entity) {
					hasMultipleEntities = true;
				}

				return quickSearchShow && showActiveFilters;
			})
			.map(function (config, name) {
				if (!config.entity) {
					config.entity = _this.props.tableType;
				}
				var result;
				if (config.combinationFilter) {
					var filters = _.reduce(
						config.filters,
						function (res, config, filterName) {
							res[filterName] = _.assign({}, config, _.cloneDeep(_this.props.activeFilters[filterName]), {
								filterName: filterName
							});
							return res;
						},
						{}
					);

					result = _.assign({}, config, { filters: filters, filterName: name });
				} else if (config instanceof window.Filter) {
					result = Object.assign(
						Object.create(Object.getPrototypeOf(config)),
						config,
						_.cloneDeep(_this.props.activeFilters[name]),
						{ filterName: name }
					);
				} else {
					result = _.assign({}, config, _.cloneDeep(_this.props.activeFilters[name]), {
						filterName: name,
						title: config.title
					});
				}

				var title = config.parent ? Tools.$translate(config.parent) : '';
				var isUDO = config.entity && config.entity.indexOf('userDefinedObject') !== -1;
				if (title && config.type === 'custom' && !isUDO) {
					isUDO = _this.props.tableType && _this.props.tableType.indexOf('userDefinedObject') !== -1;

					if (isUDO) {
						title = Tools.$translate('filters.columns.other');
					} else {
						title =
							title +
							' ' +
							Tools.$translate(
								'filters.columns.' + (config.entity ? config.entity : _this.props.tableType)
							);
					}
				}

				if (hasMultipleEntities && (hasQueryString || showOnlyActiveFilters)) {
					var tempParent = Tools.$translate(
						'default.' + (config.entity ? config.entity : _this.props.tableType)
					);
					title = tempParent + (title.length ? ': ' + title : '');
				}
				result = _.assign(result, { parent: title });
				return result;
			})
			.value();

		var filtersGroupedByType = _this.props.opts?.noTabs ? { [currentTab]: filters } : _.groupBy(filters, 'entity');

		var filtersGroupedByParent = _.groupBy(filters, 'parent');
		var filtersGroupedByTypeAndParent = _.groupBy(filtersGroupedByType[currentTab], 'parent');

		var activeTabClasses = 'btn up-btn no-shadow tab';
		var inactiveTabClasses = 'btn up-btn no-shadow tab inactive';

		var tabHolder = null;

		if (Object.keys(filtersGroupedByType).length > 1 && !hasQueryString && !showOnlyActiveFilters) {
			var tabs = _.map(Object.keys(filtersGroupedByType), function (type, index) {
				return (
					<button
						data-test-id={'listfilters-tab-' + type}
						key={index}
						className={currentTab === type ? activeTabClasses : inactiveTabClasses}
						onClick={_this.toggleTab.bind(null, type)}
					>
						<b className={_this.getTypeIcon(type)} />
						{Tools.$translate('filters.columns.' + type)}
					</button>
				);
			});

			tabHolder = (
				<div
					className="list-filters-tabs"
					ref={function (r) {
						_this._tabs = r;
					}}
				>
					<div className="btn-group">{tabs}</div>
				</div>
			);
		}

		var visibleFilters =
			hasQueryString || showOnlyActiveFilters ? filtersGroupedByParent : filtersGroupedByTypeAndParent;
		var rows = _.reduce(
			visibleFilters,
			function (res, filterArray, parentTitle) {
				if (parentTitle.length) {
					res.push(
						<div className="list-filters-row" key={parentTitle + '-separator'}>
							<div className="separator text-ellipsis">
								<span className="title">{parentTitle}</span>
							</div>
						</div>
					);
				}

				filterArray.forEach(function (filter) {
					var props = {
						opts: _this.props.opts,
						filterName: filter.filterName,
						filter: filter,
						customFields: _this.props.filterService.customFields,
						customerId: _this.props.filterService.customerId,
						dataCache: _this.props.dataCache,
						key: filter.filterName + '-filterRow',
						onChange: _this.props.onChange,
						onPageChange: _this.props.onPageChange,
						filterService: _this.props.filterService,
						'data-test-id': 'listfilters-filter-' + filter.filterName,
						activeFilters: _this.props.activeFilters,
						tableType: _this.props.tableType
					};

					var type = filter.displayType ? filter.displayType : filter.type;

					if (filter.component) {
						var c = filter.component;
						if (typeof c === 'function') {
							c = filter.component({});
						}
						var Component = window[c];
						res.push(<Component {...props} />);
					} else if (ReactTemplates.upFilters.filterTypes[type]) {
						const Component = ReactTemplates.upFilters.filterTypes[type];

						// Should not happen but just in case
						if (type === 'text' && typeof props.filter.value === 'object') {
							props.filter.value = '';
						}
						res.push(<Component {...props} />);
					}
				});

				return res;
			},
			[]
		);

		var activeBtnClasses = 'btn up-btn no-shadow show-filter';
		var inactiveBtnClasses = 'btn up-btn no-shadow show-filter inactive';
		var showAllFilters = this.state.showAllFilters === 'true';
		var placeholder = Tools.$translate('default.search') + ' ' + Tools.$translate('filters.filters').toLowerCase();

		var listFilterPageClass = 'list-filter-page active-page';
		if (this.props.hasChanged) {
			listFilterPageClass += ' has-changes';
		}

		return (
			<div
				className={listFilterPageClass}
				key="root-page"
				ref={function (r) {
					_this._page = r;
				}}
			>
				<div className="list-filters-showall">
					<div className="btn-group">
						<button
							className={showAllFilters ? activeBtnClasses : inactiveBtnClasses}
							onClick={this.toggleShowAllFilters}
							value={true}
						>
							{Tools.$translate('filters.showAllFilters')}
						</button>
						<button
							className={showAllFilters ? inactiveBtnClasses : activeBtnClasses}
							onClick={this.toggleShowAllFilters}
							value={false}
						>
							{Tools.$translate('filters.onlyActive')}
						</button>
						{ReactTemplates.TOOLS.withTooltip(
							<button
								id="clear-filters"
								className="up-btn btn-link no-shadow btn-primary"
								onClick={this.clearFilters}
							>
								<b className="fa fa-trash" />
							</button>,
							Tools.$translate('filters.clearFilter'),
							{ placement: 'left' }
						)}
					</div>
				</div>

				<div id="available-filters-search-wrap" className="search-input-wrap">
					<input
						type="text"
						className="search-input"
						placeholder={placeholder}
						value={this.state.filterSearchString}
						onChange={this.onFilterSearch}
					/>
					<b className="fa fa-search" />
					{this.state.filterSearchString ? (
						<b className="fa fa-times" onClick={this.clearFilterSearch} />
					) : null}
				</div>
				{tabHolder}
				<div className="list-filters-table">{rows}</div>
				<FilterCopy
					isVisible={this.props.hasChanged}
					isDisabled={this.props.isDisabled}
					onViewChange={this.props.onViewChange}
				/>
			</div>
		);
	}
});

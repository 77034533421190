import React from 'react';
import classNames from 'classnames';
import HeaderUserSelect from 'Components/Inputs/HeaderUserSelect';

ReactTemplates.editActivity.header = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			user: t('default.user'),
			userWarning: t('flash.userWarning'),
			selectUser: t('default.selectUser'),
			activityWith: t('flash.activityWith'),
			edit: t('default.edit'),
			closed: t('activity.closed'),
			new: t('default.new'),
			at: t('default.at'),
			activity: t('default.activity'),
			noName: t('default.noName')
		};
		this.hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);
	},
	render: function () {
		var self = this;
		var tools = self.props.tools;
		var activity = self.props.activity;
		var disabled = self.props.disabled;
		var isFlash = self.props.isFlash;
		var iconClass = 'fa template-icon';
		var selectedUser = activity.users.length ? activity.users[0] : null;
		var userWarning = null;

		if (isFlash) {
			iconClass += ' fa-flash';

			if (selectedUser && selectedUser.id !== tools.self.id) {
				userWarning = self.lang.userWarning;
			}
		} else {
			iconClass += ' fa-check';
		}

		const activityDate = activity.date ? moment(activity.date).format('L') : null;
		const activityTime = activity.time ? moment(activity.time).format('LT') : null;
		const isOutDated = !activity.closeDate && moment(activity.date).endOf('day').isBefore();

		return (
			<div className="up-modal-header white-header" ref={self.props.ref}>
				{this.hasActivityOutcomeAccess ? (
					<div className="up-modal-header-title">
						<i className={iconClass} />
						<div className="up-modal-header-title-holder">
							<h2>
								{isFlash ? (
									<span>
										<span>{self.lang.activityWith} </span>
										{activity.client ? (
											<span>
												<a
													href={
														'#/' +
														tools.customerId +
														'/accounts/' +
														activity.client.id +
														'/'
													}
												>
													{activity.client.name.length
														? activity.client.name
														: self.lang.noName}
												</a>
											</span>
										) : null}
									</span>
								) : (
									<span>
										{activity.id ? (
											<span>{activity.closeDate ? self.lang.closed : self.lang.edit}</span>
										) : (
											<span>{self.lang.new}</span>
										)}
										<span> {self.lang.activity.toLowerCase()}</span>
										{activity.client ? (
											<span>
												{' '}
												{self.lang.at.toLowerCase()}
												<a
													href={
														'#/' +
														tools.customerId +
														'/accounts/' +
														activity.client.id +
														'/'
													}
												>
													{activity.client.name.length
														? activity.client.name
														: self.lang.noName}
												</a>
											</span>
										) : null}
									</span>
								)}
							</h2>
							<div className={classNames('up-modal-header-date', { 'text-red': isOutDated })}>
								<span>{activityDate}</span> <span>{activityTime}</span>
							</div>
						</div>
					</div>
				) : (
					<h2>
						<i className={iconClass} />
						{isFlash ? (
							<span>
								<span>{self.lang.activityWith} </span>
								{activity.client ? (
									<span>
										<a href={'#/' + tools.customerId + '/accounts/' + activity.client.id + '/'}>
											{activity.client.name.length ? activity.client.name : self.lang.noName}
										</a>
									</span>
								) : null}
							</span>
						) : (
							<span>
								{activity.id ? (
									<span>{activity.closeDate ? self.lang.closed : self.lang.edit}</span>
								) : (
									<span>{self.lang.new}</span>
								)}
								<span> {self.lang.activity.toLowerCase()}</span>

								{activity.client ? (
									<span>
										{' '}
										{self.lang.at.toLowerCase()}{' '}
										<a href={'#/' + tools.customerId + '/accounts/' + activity.client.id + '/'}>
											{activity.client.name.length ? activity.client.name : self.lang.noName}
										</a>
									</span>
								) : null}
							</span>
						)}
					</h2>
				)}

				<HeaderUserSelect
					title={this.lang.user}
					user={selectedUser}
					users={self.props.users}
					placeholder={self.lang.selectUser}
					disabled={disabled}
					warning={userWarning ? self.lang.userWarning : null}
					onChange={self.props.userChange}
					hasError={self.props.formInvalid && !selectedUser}
					border="ls rs"
					borderColor="grey-4"
				/>

				{!isFlash ? (
					<a className="btn btn-link modal-close" onClick={self.close}>
						<i className="fa fa-times"></i>
					</a>
				) : null}
			</div>
		);
	}
});

import ReactDOM from 'react-dom';
import React from 'react';

const AddContactToSegmentController = (window.AddContactToSegmentController = async (
	$scope,
	$modalParams,
	AppService,
	FeatureHelper,
	$q,
	Segment,
	$translate
) => {
	if (FeatureHelper.hasSoftDeployAccess('ADMIN_CONTACT_AUTOMATION')) {
		let renderTimeout;
		let root;

		const props = {
			limit: 40,
			offset: 0,
			saving: false,
			segments: [],
			selectedSegments: $modalParams.properties || [],
			all_loaded: false,
			sort: null,
			tools: {
				AppService: AppService,
				$translate: $translate,
				$q: $q
			},
			closeModal: () => {
				$scope.reject();
			},
			refetch: () => {
				if (!props.all_loaded) {
					props.offset += 40;
					getData();
				}
			},
			onSave: () => {
				$scope.resolve(props.selectedSegments);
			},
			checkbox: id => {
				if (props.selectedSegments.indexOf(id) > -1) {
					props.selectedSegments.splice(props.selectedSegments.indexOf(id), 1);
				} else {
					props.selectedSegments.push(id);
				}

				render();
			}
		};

		const getData = () => {
			Segment.find({ limit: props.limit, offset: props.offset }).then(res => {
				if (res.data.length === 0) {
					props.all_loaded = true;
				}

				props.segments = props.segments.concat(res.data);

				render();
			});
		};

		const render = () => {
			if (!root) {
				root = document.getElementById('addToSegment__root');
			}

			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			const react = ReactTemplates.segment.addToSegmentModal;
			renderTimeout = setTimeout(() => {
				if (!root) {
					return;
				}
				ReactDOM.render(React.createElement(react, props), root);
			}, 20);
		};

		const init = () => {
			props.selectedIds = $modalParams.selected;
			getData();
		};

		$scope.$on('$destroy', () => {
			ReactDOM.unmountComponentAtNode(root);
			root = undefined;
		});

		$scope.$on('modal.ready', () => {
			init();
		});
	}
});

export default AddContactToSegmentController;

'use strict';

angular.module('upUi').factory('ProductTreeFilterMeta', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function (showInactive, searchString) {
			var defered = $q.defer();
			var promises = {
				categories: $q.when({ data: AppService.getProductCategories(!showInactive) }),
				products: $q.when({ data: AppService.getProducts(!showInactive) })
			};

			var countChildren = function (array) {
				var helper = function (group) {
					var counter = 0;
					angular.forEach(group.children, function (child) {
						if (!child.isRole) {
							counter++;
						} else {
							counter += helper(child);
						}
					});
					group.$$length = counter;
					return counter;
				};

				angular.forEach(array, function (child) {
					if (child.children) {
						helper(child);
					}
				});
			};

			var setDepth = function (array, depth) {
				angular.forEach(array, function (ruser) {
					ruser.$depth = depth;
					if (ruser.children) {
						setDepth(ruser.children, depth + 1);
					}
				});
			};

			// eslint-disable-next-line promise/catch-or-return
			$q.all(promises).then(function (res) {
				var categories = JSON.parse(JSON.stringify(res.categories.data));
				var products = JSON.parse(JSON.stringify(res.products.data));
				var root = [];

				if (products) {
					products = _.filter(products, function (obj) {
						if ((obj.name || '').toLowerCase().indexOf(searchString.toLowerCase()) > -1) {
							return true;
						} else {
							return false;
						}
					});
				}

				_.each(products, function (product) {
					// If root
					if (!product.category || product.category.id === 0) {
						product.$depth = 0;
						root.push(product);
					} else {
						var parent = _.find(categories, { id: product.category.id });

						if (parent) {
							if (!parent.children) {
								parent.children = [];
							}
							parent.children.push(product);
							product.$$parent = parent;
						}
					}
				});

				// Filter out roles without users or child roles.
				categories = _.filter(categories, function (category) {
					if (category.children) {
						return true;
					}
					return _.some(categories, function (innerCategory) {
						return innerCategory.parentId === category.id;
					});
				});

				_.each(categories, function (category) {
					// If root
					if (!category.parentId || category.parentId === 0) {
						category.$depth = 0;
						root.push(category);
					} else {
						var parent = _.find(categories, function (cat) {
							return category.parentId === cat.id || category.parentId === cat.$id;
						});

						if (parent) {
							if (!parent.children) {
								parent.children = [];
							}
							parent.children.push(category);
							category.$$parent = parent;
						}
					}
					category.isRole = true;
					category.$id = category.id;
					if (!category.children) {
						category.children = [];
					}
					delete category.id; // don't want select2 to be able to select a optgroup
				});

				setDepth(root, 0);
				countChildren(root);
				defered.resolve({ data: root });
			});

			return defered.promise;
		};
	}
]);

angular.module('upUi').factory('ProductTree', [
	'$q',
	'AppService',
	function ($q, AppService) {
		return function (onlyActive, skipAuth, selectableCategories, usePriceLists, excludeBundles) {
			var countChildren = function (array) {
				var helper = function (group) {
					var counter = 0;
					angular.forEach(group.children, function (child) {
						if (!child.isRole) {
							counter++;
						} else {
							counter += helper(child);
						}
					});
					group.$$length = counter;
					return counter;
				};

				angular.forEach(array, function (child) {
					if (child.children) {
						helper(child);
					}
				});
			};

			var setDepth = function (array, depth) {
				angular.forEach(array, function (ruser) {
					ruser.$depth = depth;
					if (ruser.children) {
						setDepth(ruser.children, depth + 1);
					}
				});
			};

			var categories = angular.copy(AppService.getProductCategories(skipAuth));
			var products = angular.copy(AppService.getProducts(onlyActive, skipAuth, usePriceLists, excludeBundles));
			var root = [];

			_.each(products, function (product) {
				// If root
				if (!product.category || product.category.id === 0) {
					product.$depth = 0;
					root.push(product);
				} else {
					var parent = _.find(categories, { id: product.category.id });

					if (parent) {
						if (!parent.children) {
							parent.children = [];
						}
						parent.children.push(product);
						product.$$parent = parent;
					}
				}
			});

			// Filter out roles without users or child roles.
			categories = _.filter(categories, function (category) {
				if (category.children) {
					return true;
				}
				return _.some(categories, function (innerCategory) {
					return innerCategory.parent && innerCategory.parent.id === category.id;
				});
			});

			_.each(categories, function (category) {
				// If root
				if (!category.parent || category.parent.id === 0) {
					category.$depth = 0;
					root.push(category);
				} else {
					var parent = _.find(categories, function (cat) {
						return category.parent.id === cat.id || category.parent.id === cat.$id;
					});

					if (parent) {
						if (!parent.children) {
							parent.children = [];
						}
						parent.children.push(category);
						category.$$parent = parent;
					}
				}
				category.isRole = true;
				category.$id = category.id;
				if (!category.children) {
					category.children = [];
				}
				if (selectableCategories) {
					category.id = 'category-' + category.id;
				} else {
					// don't want select2 to be able to select a optgroup
					delete category.id;
				}
			});

			setDepth(root, 0);
			countChildren(root);

			return { data: root };
		};
	}
]);

import angular from 'angular';
import parseCategory from 'App/resources/parsers/category';

angular.module('upResources').service('ParseCategory', [
	function () {
		return function (category) {
			return parseCategory(category);
		};
	}
]);

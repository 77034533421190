'use strict';

angular.module('upResources').factory('Statuspage', [
	'$resource',
	'STATUSPAGE',
	function Product($resource, STATUSPAGE) {
		var baseUrl = 'https://api.statuspage.io/v1/pages/' + STATUSPAGE.pageId;
		var headers = { Authorization: 'OAuth ' + STATUSPAGE.key };

		var Statuspage = $resource(
			baseUrl + '/:method/:id.json',
			{},
			{
				getPage: {
					method: 'GET',
					isArray: false,
					headers: headers,
					withCredentials: false
				},
				getIncidents: {
					method: 'GET',
					isArray: true,
					headers: headers,
					withCredentials: false
				}
			}
		);

		var Model = {
			getPage: function () {
				return Statuspage.getPage({ method: 'components', id: STATUSPAGE.componentId }).$promise;
			},

			getIncidents: function () {
				return Statuspage.getIncidents({ method: 'incidents' }).$promise;
			},

			getUnresolvedIncidents: function () {
				return Statuspage.getIncidents({ method: 'incidents', id: 'unresolved' }).$promise;
			},

			getScheduledIncidents: function () {
				return Statuspage.getIncidents({ method: 'incidents', id: 'scheduled' }).$promise;
			}
		};

		return Model;
	}
]);

import { Row, Column, Tooltip, Button, Icon, Checkbox } from '@upsales/components';

ReactTemplates.ROWSTATICS.soliditetRow = function () {
	var statics = {};
	statics.update = Tools.$translate('default.update');
	statics.add = Tools.$translate('default.add');
	statics.noName = Tools.$translate('default.noName');
	statics.subsidiary = Tools.$translate('client.subsidiary');
	statics.to = Tools.$translate('default.to').toLowerCase();
	statics.updated = Tools.$translate('default.updated');
	statics.viewGroup = Tools.$translate('account.showGroup');
	statics.commercialDescription = Tools.$translate('account.relations.groupSize.commercialDescription2');

	return statics;
};

ReactTemplates.ROW.soliditetRow = window.ReactCreateClass({
	displayName: 'soliditetRow',
	formatNumber: function (number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},
	componentDidMount: function () {
		$(ReactDOM.findDOMNode(this.refs['check'])).data('id', this.props.item.dunsNo);
	},
	buy: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var tools = this.props.tools;
		tools.opts.buyClient(this.props.item);
	},
	updateClient: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var tools = this.props.tools;
		tools.opts.updateClient(this.props.item);
	},
	updateClientWithDunsNo: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var tools = this.props.tools;
		tools.opts.updateClientWithDunsNo(this.props.item);
	},
	showGroupTree: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var tools = this.props.tools;
		tools.opts.showGroupTree(this.props.item);
	},
	goToClient: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var customerId = Tools.AppService.getCustomerId();
		var $state = this.props.tools.$state;

		$state.go('account.dashboard', { id: this.props.item.upsalesClient.id, customerId: customerId });
	},
	goToParent: function (e) {
		ReactTemplates.TOOLS.stopProp(e);

		var customerId = Tools.AppService.getCustomerId();
		var $state = this.props.tools.$state;

		$state.go('account.dashboard', { id: this.props.item.parent.id, customerId: customerId });
	},
	check: function () {
		this.props.tools.$multiSelect.toggle(this.props.item.dunsNo);
	},
	isChecked: function () {
		return this.props.tools.$multiSelect.isChecked(this.props.item.dunsNo);
	},
	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = ReactTemplates.ROWSTATICS.soliditetRow(tools);
		var attrs = this.props.attrs;
		var currencyFormat = Tools.$filter('currencyFormat');
		var numberFormat = Tools.$filter('numberFormat');

		var getColumn = function (column, item) {
			var key = 'column-' + column;

			switch (column) {
				case 'dunsNo':
					return <td key={key}>{item.dunsNo}</td>;
				case 'name':
					var clientName = item.name || statics.noName;
					var clientElement = null;

					if (item.upsalesClient) {
						clientElement = <a onClick={self.goToClient}>{clientName}</a>;
					} else {
						clientElement = <b>{clientName}</b>;
					}

					var parentElement = null;

					if (item.parent) {
						if (item.parent.id) {
							parentElement = (
								<span className="">
									<span>{statics.subsidiary + ' ' + statics.to + ' '}</span>
									<a onClick={self.goToParent}>{item.parent.name}</a>
								</span>
							);
						} else {
							parentElement = (
								<span className="parent-row">
									<span>{statics.subsidiary + ' ' + statics.to + ' '}</span>
									<b>{item.parent.name}</b>
								</span>
							);
						}
					}

					return (
						<td className="overflow-ellipsis name-column" style={{ maxWidth: '200px' }} key={key}>
							{clientElement}
							<br />
							{parentElement}
						</td>
					);
				case 'turnover':
					var turnoverString = '-';

					if (item.turnover || item.turnover === 0) {
						turnoverString = currencyFormat(item.turnover * 1000, options.currency || 'SEK');
					}

					return <td key={key}>{turnoverString}</td>;
				case 'noOfEmployeesExact':
					var employeString = '';

					if (item.noOfEmployeesExact) {
						employeString = numberFormat(item.noOfEmployeesExact);
					} else if (item.employeesStart && item.employeesEnd) {
						employeString = numberFormat(item.employeesStart) + ' - ' + numberFormat(item.employeesEnd);
					} else {
						employeString = '-';
					}

					return (
						<td className="overflow-ellipsis" key={key} style={{ maxWidth: '100px' }}>
							{employeString}
						</td>
					);
				case 'sniText':
					return (
						<td className="overflow-ellipsis snitext-column" style={{ maxWidth: '200px' }} key={key}>
							{item.sniText || '-'}
						</td>
					);
				case 'city':
					return (
						<td className="overflow-ellipsis city-column" style={{ maxWidth: '200px' }} key={key}>
							{item.city || '-'}
						</td>
					);
				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		var existInUpsales = item.upsalesClient ? true : false;
		var allredyBought = _.contains(this.props.tools.opts.boughtDunsNos, item.dunsNo);

		var button;

		if (allredyBought) {
			button = (
				<div style={{ textAlign: 'center', color: '#aaa', fontSize: '12px' }}>
					<i style={{ marginRight: '5px' }} className="fa fa-check" /> {statics.updated}
				</div>
			);
		} else if (existInUpsales) {
			button = (
				<button
					style={{ height: '28px', paddingRight: 0, paddingLeft: 0 }}
					className="btn-sm up-btn btn-green no-shadow btn-link"
					onClick={this.updateClient}
				>
					<i style={{ marginRight: '10px' }} className="fa fa-refresh" />
					{statics.update}
				</button>
			);
		} else {
			button = (
				<button
					style={{ height: '28px', display: 'inline-block' }}
					className="btn-sm up-btn no-shadow btn-green"
					onClick={this.buy}
				>
					<i style={{ marginRight: '10px' }} className="fa fa-plus" />
					{statics.add}
				</button>
			);
		}

		var checked = this.isChecked();

		const hasGroupTree = this.props.tools.hasGroupTree;

		let groupSizeButton = (
			<Button
				disabled={item.groupSize < 2 || !hasGroupTree}
				type="link"
				color="grey"
				hoverColor="green"
				className="show-group-tree"
				onClick={this.showGroupTree}
				loading={this.props.tools.openingGroup === item.dunsNo}
			>
				<Icon name="sitemap" space="mrs" />
				<span>{item.groupSize}</span>
			</Button>
		);
		if (hasGroupTree && item.groupSize > 1) {
			groupSizeButton = (
				<Tooltip title={statics.viewGroup} position="left">
					{groupSizeButton}
				</Tooltip>
			);
		}

		return (
			<tr key={'item-' + item.dunsNo} ref="row" onClick={this.check}>
				<td key="checkbox-td" className="column-checkbox">
					<Checkbox checked={checked} size="xs" />
				</td>

				{tds}

				<td className="tools" key="addButton">
					<Row>
						<Column align="left">
							{hasGroupTree
								? groupSizeButton
								: ReactTemplates.TOOLS.withTooltip(groupSizeButton, statics.commercialDescription, {
										placement: 'left'
								  })}
						</Column>
						<Column align="right">{button}</Column>
					</Row>
				</td>
				{!existInUpsales && !allredyBought ? (
					<td
						style={{ paddingLeft: '0', paddingRight: '5px', width: '20px' }}
						onClick={this.updateClientWithDunsNo}
						key="connectToUpsalesClient"
					>
						{ReactTemplates.TOOLS.withTooltip(
							<i style={{ marginRight: '10px', marginLeft: '10px' }} className="fa fa-exchange" />,
							Tools.$translate('soliditet.connectWithUpsalesClient'),
							{ placement: 'left' }
						)}
					</td>
				) : (
					<td style={{ paddingLeft: '0', paddingRight: '5px', width: '20px' }} key="connectToUpsalesClient" />
				)}
			</tr>
		);
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';
import './DrawerInfoSnooze.scss';
import { getSnoozeText } from '../helpers';

export default function DrawerInfoSnooze({ step }) {
	const classes = new bem('DrawerInfoSnooze');
	return (
		<span className={classes.b()}>
			<div className={classes.elem('ball').b()}>
				<Icon color="white" name="snooze" />
			</div>
			<Text bold>{getSnoozeText('flow.waitingFor', step)}</Text>
		</span>
	);
}

DrawerInfoSnooze.propTypes = {
	step: PropTypes.object.isRequired
};

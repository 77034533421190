import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import Resource from 'Resources/Resource';

class ClientIp extends Resource {
	constructor() {
		super('clientIps');

		this.notifications = {
			save: {
				// @ts-expect-error
				title: 'default.saved',
				body: 'saved.ipBlock',
				style: STYLE.SUCCESS,
				icon: 'save',
				type: TYPE.BODY,
			},
			saveError: {
				// @ts-expect-error
				title: 'default.error',
				body: 'saveError.ipBlock',
				style: STYLE.ERROR,
				icon: 'save',
				type: TYPE.BODY,
			},
			delete: {
				// @ts-expect-error
				title: 'default.removed',
				body: 'deleted.ipBlock',
				style: STYLE.SUCCESS,
				icon: 'trash',
				type: TYPE.BODY,
			},
			deleteError: {
				// @ts-expect-error
				title: 'default.error',
				body: 'deleteError.ipBlock',
				style: STYLE.ERROR,
				icon: 'trash',
				type: TYPE.BODY,
			},
		};
	}
}

const resource = new ClientIp();

export default resource;

'use strict';
import UpAudio from 'Components/Inputs/UpAudio';

angular.module('upDirectives').directive('upAudioRoot', [
	function () {
		return {
			restrict: 'AE',
			template: '<div></div>',
			replace: true,
			link: function ($scope, $element, $attr) {
				let firstElement = $element[0];
				var renderTimeout;

				var render = function (src) {
					if (renderTimeout) {
						clearTimeout(renderTimeout);
					}

					renderTimeout = setTimeout(function () {
						if (firstElement) {
							ReactDOM.render(
								React.createElement(UpAudio, {
									// Data and stuff
									src: src
								}),
								firstElement,
								function () {}
							);
						}
					}, 20);
				};

				$scope.$on('$destroy', function () {
					ReactDOM.unmountComponentAtNode(firstElement);
					firstElement = undefined;
				});

				$scope.$watch($attr.src, function (src) {
					if (src !== undefined) {
						render(src);
					}
				});

				render();
			}
		};
	}
]);

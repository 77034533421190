import PropTypes from 'prop-types';
import React from 'react';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import ToggleList from './ToggleList';
import { Equals, NotEquals, EqualsAll } from 'Resources/ComparisonTypes';
import { STATUS } from 'Resources/SocialEventContacts';
import StackedDateInput from 'Components/Inputs/StackedDateInput/StackedDateInput';

export default class SocialEventInput extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			isNot: Tools.$translate('comparison.NotEquals'),
			placeholder: `${Tools.$translate('default.choose')} ${Tools.$translate(
				'default.socialEvents'
			).toLowerCase()}`,
			all: Tools.$translate('default.all'),
			any: Tools.$translate('default.any')
		};

		this.onSocialEventChanged = this.onChange.bind(this, 'socialEvent');
		this.onStatusChanged = this.onChange.bind(this, 'status');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');

		this.statusOptons = _.map(STATUS, status => {
			return {
				id: status.status,
				name: Tools.$translate(`socialEvent.status.${status.status}`)
			};
		});

		this.statusOptons.unshift({
			id: null,
			name: Tools.$translate('default.all')
		});
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'socialEvent':
				newFilter.value.SocialEvent.value = Array.isArray(value) && value.length ? value : null;
				break;
			case 'status': {
				newFilter.value.ContactStatus.value = value.target.value || null;
				break;
			}
			case 'preset':
				newFilter.value.Date = value;
				break;
			case 'comperator':
				newFilter.value.SocialEvent.comparisonType = value;
				break;
		}

		const hasEventValue =
			Array.isArray(newFilter.value.SocialEvent.value) && newFilter.value.SocialEvent.value.length ? true : false;
		const hasStatusValue = newFilter.value.ContactStatus.value ? true : false;
		const hasDateValue =
			newFilter.value?.Date?.value &&
			(newFilter.value.Date.value.preset !== 'whenever' ||
				newFilter.value.Date.value.start ||
				newFilter.value.Date.value.end)
				? true
				: false;
		newFilter.inactive = !(hasEventValue || hasStatusValue || hasDateValue);

		onChange(newFilter.value, newFilter);
	}

	isListActive() {
		const filter = this.props.filter;
		return Array.isArray(filter.value.SocialEvent.value) && filter.value.SocialEvent.value.length;
	}

	render() {
		const { filter, selectData, LIST_AJAX_LIMIT, fakeConfigs } = this.props;
		const statusValue = _.find(this.statusOptons, { id: filter.value.ContactStatus.value });

		const listProps = {
			placeholder: this.lang.placeholder,
			config: fakeConfigs.SocialEvent,
			defaultValue: filter.value.SocialEvent.value,
			onChange: this.onSocialEventChanged,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.all
				},
				{
					value: NotEquals,
					text: this.lang.isNot
				}
			],
			onClick: this.onComperatorChange,
			value: filter.value.SocialEvent.comparisonType
		};

		return (
			<CriteriaRowWrapper max800w>
				<div className="criteria-row">
					<ToggleList
						className="col-sm-5"
						list={listProps}
						toggle={toggleProps}
						isListActive={() => this.isListActive()}
					/>
					<div className="col-sm-3">
						<ReactTemplates.INPUTS.upSelect
							className="form-control"
							required={false}
							data={this.statusOptons}
							defaultValue={statusValue}
							onChange={this.onStatusChanged}
							options={{ minimumResultsForSearch: -1 }}
						/>
					</div>
					<div className="col-sm-4">
						<StackedDateInput
							config={{}}
							filter={filter.value.Date || { value: { start: null, end: null, preset: 'whenever' } }}
							onChange={this.onPresetChange}
							required={false}
						/>
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

SocialEventInput.propTypes = {
	filter: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	fakeConfigs: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

'use strict';
import EditApikey from 'Components/Admin/EditApikey';

window.EditApikeyCtrl = function ($scope) {
	var rootNode;

	$scope.$on('$destroy', function () {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	$scope.$on('modal.ready', function () {
		/* Dependencies */
		var DataStore = window.DataStore;
		var ApiKey = window.Tools.ApiKey;

		/* Logic */
		rootNode = document.getElementById('edit-apikey-modal');

		var render = function (props, callback) {
			if (!rootNode) {
				return;
			}

			ReactDOM.render(
				React.createElement(
					EditApikey,
					Object.assign({}, props, { renderCallback: callback || function () {} })
				),
				rootNode,
				function () {}
			);
		};

		var actions = {
			onNameChange: function (event) {
				var value = event.target.value;

				// eslint-disable-next-line no-use-before-define
				var key = dataStore.get('apiKey');
				key.name = value;

				// eslint-disable-next-line no-use-before-define
				dataStore.setStore({
					apiKey: key
				});
			},
			save: function () {
				// eslint-disable-next-line no-use-before-define
				var state = dataStore.getStore();

				// eslint-disable-next-line no-use-before-define
				dataStore.setStore({
					saving: true
				});

				ApiKey.save(state.apiKey)
					.then(function (res) {
						// eslint-disable-next-line no-use-before-define
						dataStore.setStore({
							saving: false,
							edit: false,
							apiKey: res.data
						});
					})
					.catch(function () {
						// eslint-disable-next-line no-use-before-define
						dataStore.setStore({
							saving: false
						});
					});
			},
			copy: function () {
				var text = document.querySelector('#edit-apikey-modal .key-container').innerText;

				var textArea = document.createElement('textarea');
				textArea.style.position = 'fixed';
				textArea.style.top = 0;
				textArea.style.left = 0;
				textArea.style.width = '2em';
				textArea.style.height = '2em';
				textArea.style.padding = 0;
				textArea.style.border = 'none';
				textArea.style.outline = 'none';
				textArea.style.boxShadow = 'none';
				textArea.style.background = 'transparent';
				textArea.value = text;

				document.body.appendChild(textArea);

				textArea.select();

				try {
					document.execCommand('copy');

					// eslint-disable-next-line no-use-before-define
					dataStore.setStore({
						copied: true
					});
				} catch (err) {
					console.log(err);
				}

				document.body.removeChild(textArea);
			},
			close: $scope.close
		};

		var initialState = {
			saving: false,
			edit: true,
			copied: false,
			apiKey: {
				name: '',
				key: '',
				active: true
			}
		};

		var dataStore = new DataStore(render, actions, initialState);
	});
};

import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useRef, useState } from 'react';
import './Login.scss';
import { Button, Icon, Link, Logo, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { ensureUserIsLoggedOut, getActivateFortnox, getEnvString, getResetHash } from 'App/helpers/loginHelper';
import LoginUnsupportedBrowser from './LoginUnsupportedBrowser';
import LoginTip from './LoginTip';
import { CancelablePromise } from 'Helpers/promise';
import { SlideFade } from '@upsales/components/animations';
import LoginForm from './LoginForm/LoginForm';
import LoginRequestPasswordReset from './LoginRequestPasswordReset/LoginRequestPasswordReset';
import LoginAppStatus from './LoginAppStatus/LoginAppStatus';
import LoginResetPassword from './LoginResetPassword/LoginResetPassword';
import history from '../routes/history';
import Login2fa from './Login2fa/Login2fa';
import { setLoading } from 'Store/actions/AppActions';
import { useAppDispatch } from 'App/components/hooks';
import LoginForceResetPassword from './LoginResetPassword/LoginForceResetPassword';
import LoginCreateAccount from './LoginCreateAccount';

export default function Login() {
	const classes = new BemClass('Login');
	const { t } = useTranslation();
	const envString = getEnvString();
	const loginReq = useRef<null | CancelablePromise<any>>(null);
	const [view, setView] = useState<
		'login' | 'requestPasswordReset' | 'forcePasswordReset' | 'resetPassword' | '2faInput' | 'createAccount'
	>('login');
	// Old password and email is set by LoginForm when user is forced to reset password
	const [oldPassword, setOldPassword] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [resetPasswordHash, setResetPasswordHash] = useState<null | string>(null);
	const [twoFaToken, setTwoFaToken] = useState<null | string>(null);
	const [rememberMe, setRememberMe] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		// This prevents users to go to /login and sign in with another account and get wierd data
		ensureUserIsLoggedOut();

		// Check if we have a reset password token in the url
		const resetHash = getResetHash();
		const activateFortnox = getActivateFortnox();
		if (resetHash || activateFortnox) {
			// Clean up url
			history.replace('/login');
		}
		if (resetHash) {
			setView('resetPassword');
			setResetPasswordHash(resetHash);
		} else if (activateFortnox) {
			setView('createAccount');
		}

		// Always stop loading when login is visited
		dispatch(setLoading(false));

		return () => {
			// Cancel any pending requests
			loginReq.current?.cancel();
		};
	}, []);

	return (
		<div className={classes.b()}>
			<div className={classes.elem('logo').b()}>
				<Logo color="super-light-green" />
				<Text color="bright-orange" className={classes.elem('env-msg').b()} italic size="lg">
					{envString}
				</Text>
			</div>
			<div className={classes.elem('left').b()}>
				<LoginTip />
			</div>
			<div className={classes.elem('right').b()}>
				<div className={classes.elem('links').b()}>
					<Link nostyle href="https://upsales.releasenotes.io/" target="_blank">
						<Button type="link" color="super-light-green">
							{t('login.releaseNotes')}
						</Button>
					</Link>
					<Text color="super-light-green">|</Text>
					<Link nostyle href="https://support.upsales.com" target="_blank">
						<Button type="link" color="super-light-green">
							<Icon name="life-ring" space="mrs" />
							{t('default.visitHelpCenter')}
						</Button>
					</Link>
				</div>
				<div className={classes.elem('main').b()}>
					<LoginUnsupportedBrowser />

					<div className={classes.elem('main-inner').b()}>
						<LoginAppStatus />

						<SlideFade
							visible={view === 'login'}
							direction={view === 'login' ? 'right' : 'left'}
							delayInMs={50}
							delayOutInMs={0}
						>
							<div className={classes.elem('form').b()}>
								<LoginForm
									rememberMe={rememberMe}
									setRememberMe={setRememberMe}
									onResetPassword={() => setView('requestPasswordReset')}
									onForceResetPassword={({ oldPassword, email }) => {
										setView('forcePasswordReset');
										setOldPassword(oldPassword);
										setEmail(email);
									}}
									on2fa={token => {
										setTwoFaToken(token);
										setView('2faInput');
									}}
								/>
							</div>
						</SlideFade>
						<SlideFade
							visible={view === 'requestPasswordReset'}
							direction="right"
							delayInMs={50}
							delayOutInMs={0}
						>
							<div className={classes.elem('form').b()}>
								<LoginRequestPasswordReset onBack={() => setView('login')} />
							</div>
						</SlideFade>
						<SlideFade visible={view === 'resetPassword'} direction="right" delayInMs={50} delayOutInMs={0}>
							<div className={classes.elem('form').b()}>
								<LoginResetPassword hash={resetPasswordHash} onBack={() => setView('login')} />
							</div>
						</SlideFade>
						<SlideFade visible={view === '2faInput'} direction="right" delayInMs={50} delayOutInMs={0}>
							<div className={classes.elem('form').b()}>
								<Login2fa token={twoFaToken} rememberMe={rememberMe} onBack={() => setView('login')} />
							</div>
						</SlideFade>
						<SlideFade
							visible={view === 'forcePasswordReset'}
							direction="right"
							delayInMs={50}
							delayOutInMs={0}
						>
							<div className={classes.elem('form').b()}>
								<LoginForceResetPassword
									email={email}
									oldPassword={oldPassword}
									showLogin={() => setView('login')}
									on2fa={token => {
										setTwoFaToken(token);
										setView('2faInput');
									}}
									rememberMe={rememberMe}
								/>
							</div>
						</SlideFade>
						<SlideFade visible={view === 'createAccount'} direction="right" delayInMs={50} delayOutInMs={0}>
							<div className={classes.elem('form').b()}>
								<LoginCreateAccount showLogin={() => setView('login')} />
							</div>
						</SlideFade>
					</div>
				</div>
			</div>
		</div>
	);
}

'use strict';

angular.module('domain.lead').controller('editLead', [
	'$scope',
	'$rootScope',
	'$translate',
	'$modalParams',
	'$stateParams',
	'Lead',
	'CustomfieldMapper',
	'CountryCodes',
	function ($scope, $rootScope, $translate, $modalParams, $stateParams, Lead, CustomfieldMapper, CountryCodes) {
		var customerId = $stateParams.customerId;

		$scope.editable = true;

		$scope.save = function () {
			$scope.saving = true;
			$scope.lead.custom = CustomfieldMapper.map($scope.myForm);

			Lead.customer(customerId)
				.save($scope.lead)
				.then(function (response) {
					$scope.resolve(response.data);
				})
				.catch(function () {
					$scope.saving = false;
				});
		};

		$scope.remove = function () {
			Lead.delete(params, lead).$promise.then(function (res) {
				$scope.resolve(res);
			}, $scope.reject);
		};

		var init = function () {
			var meta = $modalParams.meta;
			var params = { customerId: 1 };
			var lead = meta.lead.data;

			if (lead.id) {
				$scope.editable = lead.userEditable;
			}

			$scope.lead = lead;
			$scope.campaigns = meta.campaigns.data;
			$scope.customFields = meta.customFields.data;
			$scope.customFieldsLength = _.filter(meta.customFields.data, 'editable').length;
			$scope.users = meta.users.data;
			$scope.edit = meta.lead.data.id ? true : false;
			$scope.lockedAccount = false;
			$scope.countries = CountryCodes.countries();
			if (!$scope.lead.user) {
				$scope.lead.user = meta.self;
			}

			if (!$scope.edit && $modalParams.account) {
				$scope.lead.client = $modalParams.account;
				$scope.lockedAccount = true;
			}

			$scope.select2leadsource = {
				data: meta.leadsources.data,
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('id')
			};

			$scope.select2leadchannel = {
				data: meta.leadchannels.data,
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('id')
			};

			$scope.select2project = {
				data: meta.campaigns.data,
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('id')
			};

			$scope.userSelect = {
				data: $scope.users,
				formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
				formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				id: _.property('id')
			};
		};

		init();
	}
]);

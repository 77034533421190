'use strict';

angular.module('domain.campaign').factory('CampaignMeta', [
	'$q',
	'Campaign',
	'CustomfieldMapper',
	'RequestBuilder',
	'Activity',
	'AppService',
	function ($q, Campaign, CustomfieldMapper, RequestBuilder, Activity, AppService) {
		return function ($stateParams) {
			return AppService.loadedPromise.then(function () {
				var customerId = $stateParams.customerId || AppService.getCustomerId();

				var promises = {
					campaign: Campaign.customer(customerId).get($stateParams.id),
					customFields: $q.when({ data: AppService.getCustomFields('project') })
				};

				return $q.all(promises).then(function (response) {
					response.campaign.data.custom = CustomfieldMapper.parseVisible(
						response.customFields.data,
						response.campaign.data.custom
					);
					return response;
				});
			});
		};
	}
]);

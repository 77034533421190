import Products from 'Resources/Product';

export const getRecurringProducts = productIds => {
	if (
		!(
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
			Tools.AppService.getMetadata().params.AgreementEnabled
		)
	) {
		return Promise.resolve([]);
	}
	if (Tools.AppService.getTotals('products') < 4000) {
		return Promise.resolve(
			_.filter(Tools.AppService.getProducts(), product =>
				productIds ? product.isRecurring && productIds.includes(product.id) : product.isRecurring
			)
		);
	} else {
		const filter = {
			isRecurring: true,
			id: productIds ? productIds : [],
			sort: 'id'
		};
		return Products.findAll(filter).catch(function (err) {
			console.log('Error getting products: ', err);
			return [];
		});
	}
};

export const hasRecurringProduct = order => {
	if (
		!(
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
			Tools.AppService.getMetadata().params.AgreementEnabled
		)
	) {
		return Promise.resolve(false);
	}

	return getRecurringProducts().then(function (recurringProducts) {
		return _.some(order.orderRow, function (row) {
			return _.some(recurringProducts, { id: row.product.id });
		});
	});
};

import ResourceTyped from 'Resources/ResourceTyped';
import ColumnsTemplate from './Model/ColumnsTemplate';

class ColumnsTemplateResource extends ResourceTyped<ColumnsTemplate> {
	constructor() {
		super('columnsTemplates');
	}
}

const resource = new ColumnsTemplateResource();

export default resource;

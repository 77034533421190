export const insertAfter = (arrayToInsertInto, afterWhatToInsert, whatToInsert) => {
	const index = arrayToInsertInto.indexOf(afterWhatToInsert);
	arrayToInsertInto.splice(index + 1, 0, whatToInsert);
};

export const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};

window.utils = {
	insertAfter,
	getCircularReplacer
};

'use strict';

angular.module('domain.admin').controller('SendWebhookAction', [
	'$scope',
	'$modalParams',
	'$translate',
	function ($scope, $modalParams, $translate) {
		var arrayProperties = [];

		$scope.tagsVisible = false;
		$scope.modalParams = $modalParams;

		$scope.toggleAvailableTags = function () {
			$scope.tagsVisible = !$scope.tagsVisible;
		};

		$scope.modalTagListProps = {
			modalParams: $modalParams,
			onClose: function () {
				$scope.tagsVisible = false;
				$scope.$apply();
			}
		};

		$scope.methods = ['POST', 'PUT', 'GET', 'DELETE'];
		$scope.contentTypes = [
			{ id: 'application/atom+xml' },
			{ id: 'application/json' },
			{ id: 'application/x-www-form-urlencoded' },
			{ id: 'application/xml' },
			{ id: 'multipart/form-data' },
			{ id: 'text/html' },
			{ id: 'text/plain' }
		];

		$scope.headers = [];

		$scope.save = function () {
			$scope.saving = true;

			// Map headers
			if ($scope.headers.length) {
				var headers = [];
				angular.forEach($scope.headers, function (header) {
					if (header.key.length) {
						headers.push(header.key + ':' + header.value);
					}
				});
				$scope.properties.Headers = headers.join('\n');
			} else {
				delete $scope.properties.Headers;
			}

			// map properties
			var properties = [];
			angular.forEach($scope.properties, function (val, key) {
				var obj = {
					name: key,
					value: val
				};

				if (_.indexOf(arrayProperties, key) !== -1) {
					obj.value = obj.value.join(',');
				}

				properties.push(obj);
			});
			$scope.resolve(properties);
		};

		$scope.setMethod = function (method) {
			// Set new method
			$scope.properties.Method = method;
		};

		$scope.addHeader = function () {
			$scope.headers.push({ key: '', value: '' });
		};

		$scope.removeHeader = function (index) {
			$scope.headers.splice(index, 1);
			$scope.reloadModalPosition();
		};

		var init = function () {
			// parse properties
			$scope.properties = {};
			$scope.isEdit = !!$modalParams.properties;
			$scope.removeAction = $modalParams.removeAction;
			$scope.actionIndex = $modalParams.actionIndex;

			if ($modalParams.prefill) {
				$scope.properties.Method = $modalParams.prefill.method || null;
				$scope.properties.Body = $modalParams.prefill.body || '';
				$scope.properties.ContentType = $modalParams.prefill.contentType || '';
				$scope.properties.Encoding = $modalParams.prefill.encoding || '';
			}

			angular.forEach($modalParams.properties, function (prop) {
				$scope.properties[prop.name] = prop.value;

				// Parse headers
				if (prop.name === 'Headers') {
					$scope.headers = [];
					angular.forEach($scope.properties[prop.name].split('\n'), function (string) {
						var split = string.split(':');
						$scope.headers.push({ key: split[0], value: split[1] });
					});
				}
			});

			// Set default method
			if (!$scope.properties.Method) {
				$scope.properties.Method = 'POST';
			}

			// Set default content-type
			if (!$scope.properties.ContentType) {
				$scope.properties.ContentType = $scope.contentTypes[0].id;
			}

			$scope.contentTypeSelect = {
				placeholder: $translate.instant('default.select') + ' Content-Type'
			};
		};

		init();
	}
]);

import async from 'async';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import RefreshAccount from 'Components/Modals/RefreshAccount/RefreshAccount';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

angular.module('domain.engage').controller('ViewAdAccounts', [
	'$scope',
	'$location',
	'$safeDigest',
	'MultiRunnerActions',
	'FilterHelper',
	'ListAttributes',
	'$state',
	'$upModal',
	'$stateParams',
	'RequestBuilder',
	'Account',
	'Ads',
	'AppService',
	'$translate',
	'FeatureHelper',
	'$multiSelect',
	function (
		$scope,
		$location,
		$safeDigest,
		MultiRunnerActions,
		FilterHelper,
		ListAttributes,
		$state,
		$upModal,
		$stateParams,
		RequestBuilder,
		Account,
		Ads,
		AppService,
		$translate,
		FeatureHelper,
		$multiSelect
	) {
		var ViewAdAccountsCtrl = this;
		var ViewAdCtrl = $scope.ViewAdCtrl;
		var customerId = ViewAdCtrl.customerId;
		var currentQuery;

		var resetPaginator = function () {
			ViewAdAccountsCtrl.currentPage = 1;
			ViewAdAccountsCtrl.offset = 0;
			$location.search('p', null);
		};

		ViewAdAccountsCtrl.error = false;
		ViewAdAccountsCtrl.loading = true;
		ViewAdAccountsCtrl.currentPage = 1;
		ViewAdAccountsCtrl.client = [];
		ViewAdAccountsCtrl.total = 0;
		ViewAdAccountsCtrl.offset = 0;

		ViewAdAccountsCtrl.currentTargetIds = [];
		ViewAdAccountsCtrl.filterOnActive = true;
		ViewAdAccountsCtrl.filterOnIp = false;
		ViewAdAccountsCtrl.limits = [25, 50, 100, 200, 500];
		ViewAdAccountsCtrl.limit = ViewAdAccountsCtrl.limits[0];
		ViewAdAccountsCtrl.selectables = [
			{
				key: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'impressions'),
				title: 'nested.impressions'
			},
			{ key: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'clicks'), title: 'nested.clicks' }
		];
		if (ViewAdCtrl.ad.targetAbm) {
			ViewAdAccountsCtrl.selectables.push({
				key: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'hasIp'),
				title: 'nested.hasIp'
			});
			//ViewAdAccountsCtrl.selectables.push({ key: FilterHelper.createNested('adCampaign', 'id', ViewAdCtrl.ad.id, 'grade'), title: 'nested.grade' });
		}
		ViewAdAccountsCtrl.columns = ['name', 'eventTypes'];
		ViewAdAccountsCtrl.columns = ViewAdAccountsCtrl.columns.concat(_.map(ViewAdAccountsCtrl.selectables, 'key'));
		ViewAdAccountsCtrl.sorting = [{ attribute: 'name', ascending: true }];

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			if (updated.id !== ViewAdCtrl.ad.id) {
				return;
			}
			_.forEach(ViewAdAccountsCtrl.currentTargetIds.clientIds, function (clientId) {
				var existingIndex = _.findIndex(ViewAdAccountsCtrl.client, { id: clientId });
				if (existingIndex === -1) {
					return;
				}
				var isActiveInAdCampaign = ViewAdCtrl.isAccountActive(ViewAdAccountsCtrl.client[existingIndex]);

				if (isActiveInAdCampaign) {
					return;
				}

				ViewAdAccountsCtrl.total--;
				ViewAdAccountsCtrl.client.splice(existingIndex, 1);
			});

			ViewAdAccountsCtrl.currentTargetIds = ViewAdCtrl.targetIds;
		});

		$scope.$on(Ads.eventPrefix + '.' + Ads.events.updated, function (e, updated) {
			if (ViewAdCtrl.ad.id === updated.id) {
				ViewAdAccountsCtrl.getAccounts();
			}
		});

		$scope.$on('account.added', function (e, added) {
			var existingIndex = _.findIndex(ViewAdAccountsCtrl.client, { id: added.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, added, 'account')) {
				ViewAdAccountsCtrl.client[existingIndex] = added;
				ViewAdAccountsCtrl.getAccountStats();
				return;
			}
			if (FilterHelper.match(currentQuery.q, added, 'account')) {
				ViewAdAccountsCtrl.total++;
				ViewAdAccountsCtrl.client.push(added);
				ViewAdAccountsCtrl.getAccountStats();
			}
		});

		$scope.$on('account.updated', function (e, updated) {
			var existingIndex = _.findIndex(ViewAdAccountsCtrl.client, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(currentQuery.q, updated, 'account')) {
				ViewAdAccountsCtrl.client[existingIndex] = updated;
				ViewAdAccountsCtrl.getAccountStats();
			} else if (existingIndex !== -1) {
				ViewAdAccountsCtrl.total--;
				ViewAdAccountsCtrl.client.splice(existingIndex, 1);
				ViewAdAccountsCtrl.getAccountStats();
			}
		});

		$scope.$on('account.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(ViewAdAccountsCtrl.client, { id: deleted.id });
			if (existingIndex !== -1) {
				ViewAdAccountsCtrl.total--;
				ViewAdAccountsCtrl.client.splice(existingIndex, 1);
				ViewAdAccountsCtrl.getAccountStats();
			}
		});

		$scope.$on('account.merged', function () {
			ViewAdAccountsCtrl.getAccounts();
		});

		var safeDigest = function () {
			var phase = $scope.$root.$$phase;
			if (phase === '$apply' || phase === '$digest') {
				return;
			}
			$scope.$digest();
		};

		$scope.$on('$multiSelect.toggle', function (e, data) {
			ViewAdAccountsCtrl.selected = data.selected.length;
			safeDigest();
		});

		$scope.$on('$multiSelect.selectAll', function () {
			ViewAdAccountsCtrl.selected = ViewAdAccountsCtrl.client.length;
			safeDigest();
		});

		$scope.$on('$multiSelect.selectAllVisible', function () {
			ViewAdAccountsCtrl.selected = ViewAdAccountsCtrl.client.length;
			safeDigest();
		});

		$scope.$on('$multiSelect.selectAllFilter', function () {
			ViewAdAccountsCtrl.selected = ViewAdAccountsCtrl.total;
			safeDigest();
		});

		$scope.$on('$multiSelect.selectNone', function () {
			ViewAdAccountsCtrl.selected = 0;
			safeDigest();
		});

		ViewAdAccountsCtrl.listOpsts = {
			type: 'account',
			hasCheck: true,
			editable: true,
			columnTools: {
				remove: false,
				extraColumn1: {
					icon: 'times-circle',
					tooltip: 'ads.removeFromAdCampaignTooltip',
					show: ViewAdCtrl.isAccountActive,
					onClick: ViewAdCtrl.removeClientFromAds
				}
			},
			clickedEntry: function (account) {
				if (!account.isExternal) {
					return $state.go('account.dashboard', { id: account.id, customerId: customerId });
				}
			},
			saveExternal: ViewAdCtrl.saveExternal,
			editEntry: function (account) {
				if (shouldOpenModal('EditClient')) {
					openModal('EditClient', {
						id: account.id
					});
				} else {
					var options = {
						customerId: customerId,
						id: account.id
					};

					$upModal.open('editAccount', options);
				}
			},
			removeEntry: function (account) {
				// Comfirm
				var confirmButtonText =
					$translate.instant('admin.modal.deleteEntity', {
						entity: $translate.instant('account').toLowerCase()
					}) + $translate.instant('admin.modal.delete.andRelatedObjects');
				var alertConfirmOptions = {
					type: 'confirm',
					reactive: true,
					fullscreen: true,
					hideAbort: false,
					dialog: AlertConfirm,
					id: 'confirm-delete-fields',
					body: React.createElement(AlertBody, {
						numSelected: 1,
						entity: 'Project'
					}),
					confirmButtonText: confirmButtonText,
					confirmClass: 'btn-red',
					confirmationMode: 'text',
					confirmText: account.name,
					confirmFn: Account.customer(customerId)['delete'].bind(null, account)
				};

				if (FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					return openModal('Alert', {
						...alertConfirmOptions,
						title: getConfirmationTitle('Project', 1),
						onClose: confirmed => {
							if (confirmed) {
								alertConfirmOptions.confirmFn();
							}
						}
					});
				}

				return $upModal.open('alert', alertConfirmOptions);
			},
			refreshSoliditet: function (account) {
				const hasProspecting = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);
				const hasSoliditet = Tools.FeatureHelper.isAvailable(
					Tools.FeatureHelper.Feature.SOLIDITET_MULTI_MARKET
				);

				if (hasProspecting || hasSoliditet) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('RefreshAccount', {
							className: 'form-md allow-overflow',
							account: account,
							name: account.name,
							customerId: customerId,
							accountId: account.id,
							duns: account.dunsNo,
							integrationFilter: ['soliditet', 'prospecting'],
							onClose: account => {
								if (account) {
									Tools.$state.reload();
								}
							}
						});
					} else {
						const component = props => <ReduxRootComponent component={RefreshAccount} {...props} />;
						// eslint-disable-next-line promise/catch-or-return
						Tools.$upModal
							.open('alert', {
								className: 'form-md allow-overflow',
								dialog: component,
								account: account,
								name: account.name,
								customerId: customerId,
								accountId: account.id,
								duns: account.dunsNo,
								integrationFilter: ['soliditet', 'prospecting']
							})
							.then(() => {
								Tools.$state.reload();
							});
					}
				}
			}
		};

		ViewAdAccountsCtrl.onSort = function () {
			setTimeout(function () {
				ViewAdAccountsCtrl.getAccounts();
			}, 200);
		};

		ViewAdAccountsCtrl.setLimit = function (limit) {
			ViewAdAccountsCtrl.limit = limit;
			ViewAdAccountsCtrl.getAccounts();
		};

		ViewAdAccountsCtrl.getAccounts = function (pageChange) {
			var filters;
			var opts = {
				ipStatus: ViewAdAccountsCtrl.filterOnIp,
				includeInactive: !ViewAdAccountsCtrl.filterOnActive
			};

			ViewAdAccountsCtrl.error = false;
			ViewAdAccountsCtrl.loading = true;
			ViewAdAccountsCtrl.client = [];

			if (!pageChange) {
				resetPaginator();
			}

			if (ViewAdAccountsCtrl.quickFindValue) {
				opts.nameSearch = ViewAdAccountsCtrl.quickFindValue;
			}

			if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
				// always include inactive when finished
				opts.includeInactive = true;
			}

			filters = ViewAdCtrl.getFilter(opts);
			filters.limit = ViewAdAccountsCtrl.limit;
			filters.offset = ((ViewAdAccountsCtrl.currentPage || 1) - 1) * filters.limit;
			filters.sorting = ViewAdAccountsCtrl.sorting;

			ViewAdAccountsCtrl.filters = _.clone(filters);
			currentQuery = filters.build();

			Account.customer(ViewAdCtrl.customerId)
				.find(currentQuery, { mapCustom: true, includeExternal: true })
				.then(function (res) {
					ViewAdAccountsCtrl.client = res.data;
					ViewAdAccountsCtrl.total = res.metadata ? res.metadata.total : res.length;
					ViewAdAccountsCtrl.loading = false;
					$multiSelect.setTotal(ViewAdAccountsCtrl.total);
				})
				.catch(function (err) {
					ViewAdAccountsCtrl.loading = false;
					ViewAdAccountsCtrl.error = err;
				});
		};

		ViewAdAccountsCtrl.getAccountStats = function () {
			ViewAdAccountsCtrl.stats = {};
			async.series([
				function (cb) {
					var opts = { ipStatus: 'hasIp' };
					if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
						// always include inactive when finished
						opts.includeInactive = true;
					}
					var filters = ViewAdCtrl.getFilter(opts);
					filters.limit = 0;
					Account.customer(ViewAdCtrl.customerId)
						.find(filters.build(), { includeExternal: true })
						.then(function (res) {
							ViewAdAccountsCtrl.stats.hasIp = res.metadata.total;
							cb();
						})
						.catch(function (err) {
							cb(err);
						});
				},
				function (cb) {
					var opts = { ipStatus: 'hasNoIp' };
					if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
						// always include inactive when finished
						opts.includeInactive = true;
					}
					var filters = ViewAdCtrl.getFilter(opts);
					filters.limit = 0;
					Account.customer(ViewAdCtrl.customerId)
						.find(filters.build(), { includeExternal: true })
						.then(function (res) {
							ViewAdAccountsCtrl.stats.hasNoIp = res.metadata.total;
							cb();
						})
						.catch(function (err) {
							cb(err);
						});
				},
				function (cb) {
					var opts = { ipStatus: 'hasNoDuns' };
					if (ViewAdCtrl.ad.status > ViewAdCtrl.statuses.paused) {
						// always include inactive when finished
						opts.includeInactive = true;
					}
					var filters = ViewAdCtrl.getFilter(opts);
					filters.limit = 0;
					Account.customer(ViewAdCtrl.customerId)
						.find(filters.build(), { includeExternal: true })
						.then(function (res) {
							ViewAdAccountsCtrl.stats.hasNoDuns = res.metadata.total;
							cb();
						})
						.catch(function (err) {
							cb(err);
						});
				},
				function (cb) {
					var filters = new RequestBuilder();
					filters.addFilter(
						Account.attr.adCampaign.attr.id,
						filters.comparisonTypes.Equals,
						ViewAdCtrl.ad.id
					);
					if (ViewAdCtrl.targetIds.projectIds.length) {
						filters.addFilter(
							Account.attr.campaigns,
							filters.comparisonTypes.NotEquals,
							ViewAdCtrl.targetIds.projectIds
						);
					}
					filters.addFilter(
						Account.attr.id,
						filters.comparisonTypes.NotEquals,
						ViewAdCtrl.targetIds.clientIds
					);

					filters.limit = 0;
					Account.customer(ViewAdCtrl.customerId)
						.find(filters.build(), { includeExternal: true })
						.then(function (res) {
							ViewAdAccountsCtrl.stats.inactive = res.metadata.total;
							cb();
						})
						.catch(function (err) {
							cb(err);
						});
				}
			]);
		};

		ViewAdAccountsCtrl.editAccount = function (account, e) {
			if (e) {
				e.stopPropagation();
			}
			$upModal.open('editAccount', { customerId: ViewAdCtrl.customerId, account: account });
		};

		ViewAdAccountsCtrl.runMultiAction = function (action) {
			var rb = _.clone(ViewAdAccountsCtrl.filters);
			action.run(ViewAdAccountsCtrl, rb.build(), rb, ViewAdAccountsCtrl.columns);
		};

		ViewAdAccountsCtrl.quickFindAccounts = function () {
			if (!ViewAdAccountsCtrl.quickFindValue) {
				return ViewAdAccountsCtrl.getAccounts();
			}
			if (ViewAdAccountsCtrl.quickFindValue.length < 2) {
				return;
			}
			ViewAdAccountsCtrl.getAccounts();
		};

		var init = function () {
			ViewAdAccountsCtrl.currentTargetIds = ViewAdCtrl.targetIds;
			ViewAdAccountsCtrl.multiActions = MultiRunnerActions.get('ACCOUNT', { ignore: ['remove'] });
			ViewAdAccountsCtrl.customFields = AppService.getCustomFields('account');
			ViewAdAccountsCtrl.getAccounts();
			ViewAdAccountsCtrl.getAccountStats();
		};

		AppService.loadedPromise.then(init).catch(e => console.log('load engage viewAccounts error', e));
	}
]);

'use strict';
import PropTypes from 'prop-types';
import _ from 'lodash';

ReactTemplates.INPUTS.multiRangeInput = window.ReactCreateClass({
	propTypes: {
		max: PropTypes.number,
		min: PropTypes.number,
		startValue: PropTypes.number,
		endValue: PropTypes.number,
		step: PropTypes.number,
		rangeSet: PropTypes.array,
		name: PropTypes.string.isRequired,
		onChangeFn: PropTypes.func
	},
	getDefaultProps: function () {
		return {
			min: 0,
			max: 10,
			startValue: 0,
			endValue: 10,
			step: 1,
			rangeSet: null,
			onChangeFn: _.noop
		};
	},
	getInitialState: function () {
		return { startValue: null, endValue: null };
	},
	componentDidMount: function () {
		var self = this;
		var input = this.refs['original_' + this.props.name];
		var ghost = this.refs['ghost_' + this.props.name];

		var min = self.props.min;
		var max = self.props.max;
		input.value = self.props.startValue || self.props.startValue === 0 ? self.props.startValue : min;
		ghost.value = self.props.endValue || self.props.endValue === 0 ? self.props.endValue : max;
		// input.addEventListener("input", self.update);
		// ghost.addEventListener("input", self.update);

		self.update();
	},
	update: function () {
		var input = this.refs['original_' + this.props.name];
		var ghost = this.refs['ghost_' + this.props.name];
		var startValue, endValue;
		startValue = Math.min(input.value, ghost.value);
		endValue = Math.max(input.value, ghost.value);

		if (startValue !== this.state.startValue || endValue !== this.state.endValue) {
			ghost.style.setProperty(
				'--low',
				100 * ((startValue - this.props.min) / (this.props.max - this.props.min)) + 1 + '%'
			);
			ghost.style.setProperty(
				'--high',
				100 * ((endValue - this.props.min) / (this.props.max - this.props.min)) - 1 + '%'
			);
			input.value = startValue;
			ghost.value = endValue;

			if (this.props.rangeSet && this.props.rangeSet.length) {
				this.props.onChangeFn(this.props.rangeSet[startValue], this.props.rangeSet[endValue]);
			} else {
				this.props.onChangeFn(startValue, endValue);
			}
		}
	},

	render: function () {
		var step = this.props.set;
		var min = this.props.min;
		var max = this.props.max;

		if (this.props.rangeSet && this.props.rangeSet.length) {
			step = 1;
			min = 0;
			max = this.props.rangeSet.length - 1;
		}
		return (
			<div key={this.props.name}>
				<input
					type="range"
					onChange={this.update}
					ref={'original_' + this.props.name}
					className="multirange original"
					value={this.props.startValue}
					min={min}
					max={max}
					step={step}
				/>
				<input
					type="range"
					onChange={this.update}
					ref={'ghost_' + this.props.name}
					className="multirange ghost"
					value={this.props.endValue}
					min={min}
					max={max}
					step={step}
				/>
			</div>
		);
	}
});

'use strict';

angular.module('upResources').factory('Segment', [
	'$resource',
	'$q',
	'SegmentAttributes',
	'URL',
	'API',
	'ResourceHelper',
	'ParseGeneric',
	function Client($resource, $q, SegmentAttributes, URL, API, ResourceHelper, ParseGeneric) {
		var SA = SegmentAttributes();
		var Attribute = SA.attr;
		var Keys = SA.keys;

		var helper = new ResourceHelper();

		helper.setDefaults({
			eventPrefix: 'segment',
			createSuccessBody: 'saved.segment',
			updateSuccessBody: 'updated.segment',
			deleteSucccessBody: 'deleted.segment',
			createErrorBody: 'saveError.segment',
			updateErrorBody: 'saveError.segment',
			deleteErrorBody: 'deleteError.segment'
		});

		var dateFields = ['createDate', 'modDate'];

		var resourceUrl = URL + API + 'segments/';
		var Segment = $resource(
			resourceUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, {
						isArray: false,
						dateFields: dateFields
					})
				},
				find: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, {
						isArray: true,
						dateFields: dateFields
					})
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric(null, {
						isArray: false,
						dateFields: dateFields
					})
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric(null, {
						isArray: false,
						dateFields: dateFields
					})
				},
				delete: {
					method: 'DELETE',
					isArray: false
				},
				add: {
					method: 'POST',
					isArray: false,
					url: resourceUrl + 'add'
				}
			}
		);

		var Model = {
			find: function (filter, options) {
				var params = _.extend({}, filter, options);
				return Segment.find(params).$promise;
			},
			get: function (id) {
				return Segment.get({ id: id }).$promise;
			},
			delete: function (id, options) {
				return Segment.delete({ id: id })
					.$promise.then(function (res) {
						helper.onDelete(options, { id: id });
						return res;
					})
					.catch(function (err) {
						helper.onDeleteError(options, err);
					});
			},
			save: function (segment, options) {
				var defered = $q.defer();
				if (!segment) {
					return defered.reject('No segment');
				}

				if (segment.id) {
					Segment.update(
						{ id: segment.id },
						segment,
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				} else {
					Segment.create(
						segment,
						function (response) {
							if (response.error) {
								defered.reject(response);
							}
							defered.resolve(response);
						},
						defered.reject
					);
				}

				defered.promise
					.then(function (res) {
						helper.onSave(options, res.data, !segment.id);
					})
					.catch(function () {
						helper.onSaveError(options, !segment.id);
					});

				return defered.promise;
			},
			add: function (selectedIds, properties) {
				if (!Array.isArray(properties)) {
					properties = [properties];
				}
				return Segment.add({ selectedIds, properties, filters: {} }).$promise;
			},
			attr: Attribute,
			keys: Keys
		};

		return Model;
	}
]);

'use strict';
import logError from 'App/babel/helpers/logError';

angular.module('upResources').factory('Self', [
	'$resource',
	'$q',
	'URL',
	'API',
	'AppService',
	'ResourceHelper',
	function SelfService($resource, $q, URL, API, AppService, ResourceHelper) {
		/*
		 *	YAYY, We have 3 functions that does the same(ish) stuff....
		 */

		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'self',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			updateErrorTitle: 'default.error',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings',
			passwordChangeBody: 'saved.password',
			passwordChangeErrorBody: 'saveError.password'
		});

		var parseSelf = function (res) {
			try {
				res = JSON.parse(res);
				res.data.lastOnline = new Date(res.data.lastOnline);
				res.data.lastOnlinePower = new Date(res.data.lastOnlinePower);
			} catch (e) {
				return res;
			}

			return res;
		};

		var baseUrl = URL + API;
		var SelfResource = $resource(
			baseUrl + ':customerId/self',
			{},
			{
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: parseSelf,
					skipDateConvert: true
				},
				get: {
					method: 'GET',
					transformResponse: parseSelf,
					skipDateConvert: true
				},
				changePassword: {
					method: 'PUT',
					url: baseUrl + 'self/changepassword',
					transformResponse: parseSelf,
					skipDateConvert: true
				}
			}
		);

		var Self = {};

		Self.getSelf = function () {
			return SelfResource.get().$promise.then(_.property('data'));
		};

		let counter = 0;
		let hasLoggedError = false;
		Self.get = function () {
			if (!hasLoggedError) {
				if (counter > 10_000) {
					//Sometimes we get stuck in a death spiral, this is to help us debug it
					logError(new Error('Death spiral'));
					hasLoggedError = true;
				} else {
					counter++;
				}
			}

			return SelfResource.get().$promise;
		};

		Self.getCustomerSelf = function (id) {
			return SelfResource.get({ customerId: id }).$promise.then(_.property('data'), function (res) {
				return $q.reject(res.status);
			});
		};

		Self.changePassword = function (masterId, newPassword, oldPassword, options) {
			options = _.defaults(options || {}, helper.defaults);

			var promise = SelfResource.changePassword(
				{},
				{ id: masterId, password: newPassword, oldPassword: oldPassword }
			).$promise;

			promise
				.then(function (res) {
					// Notify user
					helper.successNotification(options.updateSuccessTitle, options.passwordChangeBody, res.data);

					helper.triggerEvent(options, 'passwordChanged', res.data);

					return res;
				})
				.catch(function (res) {
					// Notify user
					helper.errorNotification(options.updateErrorTitle, options.passwordChangeErrorBody, res.data);
				});

			return promise;
		};

		Self.save = function (data, options) {
			var promise = SelfResource.update({ customerId: AppService.getCustomerId() }, data).$promise;

			promise
				.then(function (res) {
					// Notify user
					helper.onSave(options, res.data, false);

					var eventUser = angular.copy(res.data);
					delete eventUser.userParams;

					// Send user updated event too
					helper.triggerEvent({ eventPrefix: 'user' }, 'updated', eventUser);
				})
				.catch(function () {
					// Notify user
					helper.onSaveError(options, false);
				});

			return promise;
		};

		return Self;
	}
]);

'use strict';

ReactTemplates.appPreview = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			comingSoon: t('integrations.comingSoon')
		};
	},
	render: function () {
		var imgStyle = {
			backgroundImage: 'url(' + this.props.integration.imageLink + ')'
		};

		var boxStyle = { backgroundColor: this.props.integration.color || '#ffffff' };

		var classes = 'package-box cover-img';
		var comingSoon = null;
		var disabled = false;
		var footerStyle = null;
		if (this.props.integration.version === '0' || this.props.integration.version === 0) {
			footerStyle = { paddingTop: '3px' };
			disabled = true;
			classes += ' disabled';
			comingSoon = <div className="text-grey">{this.lang.comingSoon}</div>;
		}

		if (!this.props.integration.description || !this.props.onClick) {
			classes += ' no-animate';
		}

		if (!this.props.onClick) {
			classes += ' no-click';
		}

		var activeIndicator = null;
		if (this.props.integration.$config && this.props.integration.$config.active) {
			activeIndicator = (
				<div className="integration-active-indicator">
					<b className="fa fa-check"></b>
				</div>
			);
		}

		return (
			<div className="integration-box">
				<div
					onClick={!disabled && this.props.onClick ? this.props.onClick : null}
					className={classes}
					style={boxStyle}
				>
					<div className="integration-img" style={imgStyle}></div>
					{this.props.onClick ? <div className="overlay"></div> : null}
					<div className="package-box-footer lined tall" style={footerStyle}>
						<h2 className="package-box-title">{this.props.integration.name}</h2>
						{comingSoon}
						{this.props.integration.description ? (
							<div className="show-on-hover-child">{this.props.integration.description}</div>
						) : null}
						{activeIndicator}
					</div>
				</div>
			</div>
		);
	}
});

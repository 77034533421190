'use strict';

var categorySelectDir = 'upCategories';
angular
	.module('upDirectives')
	.directive(categorySelectDir, function (AppService, Category, selectHelper, $translate, $q) {
		return selectHelper.getDirectiveObject(categorySelectDir, {
			ajax: true,
			asIds: false,
			idAttr: Category.attr.id,
			titleAttr: Category.attr.name,
			sorting: { field: Category.attr.name, ascending: true },
			cachedTotal: function ($attrs) {
				var categories = AppService.getCategories($attrs.entity);
				if ($attrs.categoryType === undefined) {
					return categories.length;
				} else {
					var id = parseInt($attrs.categoryType);
					return _.filter(categories, function (type) {
						return type.categoryType === id || type.categoryTypeId === id;
					}).length;
				}
			},
			getter: function (customerId, filter, searchStr, scope, $attrs) {
				var entity = $attrs.entity;

				var skipAuth = entity.indexOf('userDefined') !== -1;
				var categories = AppService.getCategories(entity, skipAuth);
				var types = AppService.getCategoryTypes(entity);
				var data = [];

				if (searchStr && searchStr.length) {
					categories = _.filter(categories, function (cat) {
						return cat.name.toLowerCase().indexOf(searchStr.toLowerCase()) === 0;
					});
				}

				if (filter.q?.length) {
					data = _.filter(categories, function (cat) {
						var searchStr = filter.q[0]?.v || '';
						return cat.name && cat.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1;
					});
					return $q.when({ data: data });
				}
				// No type? then show all
				if ($attrs.categoryType === undefined) {
					// group by type if more than one
					if (types.length) {
						var root = types;
						var standardType = { id: 0, name: $translate.instant('default.categories'), children: [] };
						// Add standard category to array
						root.push(standardType);
						angular.forEach(categories, function (cat) {
							// Find parentType and push category to it
							var parent = _.find(root, { id: cat.categoryType });

							if (parent) {
								if (!parent.children) {
									parent.children = [];
								}
								parent.children.push(cat);
							} else {
								standardType.children.push(cat);
							}
						});

						// Remove empty types
						root = _.filter(root, function (type) {
							type.$id = type.id;
							type.id = undefined;
							return type.children && type.children.length;
						});

						return $q.when({
							data: _.sortBy(root, 'name'),
							referenceData: categories,
							metadata: { total: data.length }
						});
					} else {
						data = categories;
					}
				} else {
					// Show all by categoryTypeId set to 0 to get standard
					var id = parseInt($attrs.categoryType);
					data = _.filter(categories, function (cat) {
						return cat.categoryType === id || cat.categoryTypeId === id;
					});
				}
				return $q.when({ data: _.sortBy(data, 'name'), metadata: { total: data.length } });
			}
		});
	})
	.directive(categorySelectDir + 'Ids', function (AppService, Category, selectHelper, $translate, $q) {
		return selectHelper.getDirectiveObjectIds(categorySelectDir + 'Ids', {
			ajax: true,
			asIds: true,
			idAttr: Category.attr.id,
			titleAttr: Category.attr.name,
			sorting: { field: Category.attr.name, ascending: true },
			cachedTotal: function ($attrs) {
				var categories = AppService.getCategories($attrs.entity);
				if ($attrs.categoryType === undefined) {
					return categories.length;
				} else {
					var id = parseInt($attrs.categoryType);
					return _.filter(categories, function (type) {
						return type.categoryType === id || type.categoryTypeId === id;
					}).length;
				}
			},
			getter: function (customerId, filter, searchStr, scope, $attrs) {
				var entity = $attrs.entity;

				var skipAuth = entity.indexOf('userDefined') !== -1;
				var categories = AppService.getCategories(entity, skipAuth);
				var types = AppService.getCategoryTypes(entity);
				var data = [];

				if (searchStr && searchStr.length) {
					categories = _.filter(categories, function (cat) {
						return cat.name.toLowerCase().indexOf(searchStr.toLowerCase()) === 0;
					});
				}

				if (filter.q?.length) {
					if (filter.q.length === 1 && filter.q[0].a === 'id' && Array.isArray(filter.q[0].v)) {
						data = _.filter(categories, function (c) {
							return filter.q[0].v.indexOf(c.id) !== -1;
						});
						return $q.when({ data: data });
					}
					data = _.filter(categories, function (cat) {
						var searchStr = filter.q[0]?.v || '';
						return cat.name && cat.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1;
					});
					return $q.when({ data: data });
				}
				// No type? then show all
				if ($attrs.categoryType === undefined) {
					// group by type if more than one
					if (types.length) {
						var root = types;
						var standardType = { id: 0, name: $translate.instant('default.categories'), children: [] };
						// Add standard category to array
						root.push(standardType);
						angular.forEach(categories, function (cat) {
							// Find parentType and push category to it
							var parent = _.find(root, { id: cat.categoryType });

							if (parent) {
								if (!parent.children) {
									parent.children = [];
								}
								parent.children.push(cat);
							} else {
								standardType.children.push(cat);
							}
						});

						// Remove empty types
						root = _.filter(root, function (type) {
							type.$id = type.id;
							type.id = undefined;
							return type.children && type.children.length;
						});

						return $q.when({
							data: _.sortBy(root, 'name'),
							referenceData: categories,
							metadata: { total: data.length }
						});
					} else {
						data = categories;
					}
				} else {
					// Show all by categoryTypeId set to 0 to get standard
					var id = parseInt($attrs.categoryType);
					data = _.filter(categories, function (cat) {
						return cat.categoryType === id || cat.categoryTypeId === id;
					});
				}
				return $q.when({ data: _.sortBy(data, 'name'), metadata: { total: data.length } });
			}
		});
	});

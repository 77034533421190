import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from 'Store';
import { Router } from 'react-router-dom';
import history from 'App/pages/routes/history';

class ReduxRootComponent extends React.Component {
	render() {
		const { component: Component, ...props } = this.props;
		return (
			<Router history={history} location={history.location}>
				<Provider store={store}>
					<Component {...props} />
				</Provider>
			</Router>
		);
	}
}

ReduxRootComponent.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default ReduxRootComponent;

'use strict';

angular.module('upResources').factory('LookerSSO', [
	'$resource',
	'$q',
	'URL',
	'API',
	function Looker($resource, $q, URL, API) {
		var baseUrl = URL + API + 'function/externalSSO/looker/';
		var Resource = $resource(
			baseUrl + ':type/:id',
			{},
			{
				get: { method: 'GET', isArray: false }
			}
		);

		var Model = {
			get: function (type, id, useNext) {
				type = type || 'dashboard';
				id = id || 0;
				return Resource.get({ type: type, id: id, useNext }).$promise;
			}
		};

		return Model;
	}
]);

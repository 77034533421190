import InAppChat from 'App/babel/services/InAppChat';

angular.module('domain.onboarding').controller('FeaturePlaceholderCtrl', [
	'AppService',
	'$translate',
	'$stateParams',
	'$filter',
	'$scope',
	function (AppService, $translate, $stateParams, $filter, $scope) {
		var renderTimeout = null;
		var element = document.getElementById('feature-placeholder-root');
		var title = '';
		var columnsKey = null;
		var salesboard = false;
		var iframeUrl = null;
		var urlBase = 'https://www.upsales.com/';
		var accountManager = null;
		var hasChat = InAppChat.isEnabledAndLoaded();

		var tools = {
			$translate: $translate,
			$filter: $filter
		};

		var render = function () {
			if (renderTimeout) {
				clearTimeout(renderTimeout);
			}

			renderTimeout = setTimeout(function () {
				if (!element) {
					return;
				}

				ReactDOM.render(
					React.createElement(ReactTemplates.onboarding.featurePlaceholder, {
						title: title,
						columnsKey: columnsKey,
						salesboard: salesboard,
						iframeUrl: iframeUrl,
						accountManager: accountManager,
						hasChat: hasChat,
						tools: tools
					}),
					element
				);
			}, 20);
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(element);
			element = undefined;
		});

		var init = function () {
			var accountSelf = AppService.getAccountSelf();
			var self = AppService.getSelf();
			if (self.language !== 'sv-SE') {
				urlBase += 'en/platform/';
			} else {
				urlBase += 'plattform/';
			}

			switch ($stateParams.feature) {
				case 'leads':
					title = 'defaultView.myLeads';
					columnsKey = 'lead';
					iframeUrl =
						urlBase + (self.language !== 'sv-SE' ? 'marketing/lead-scoring/' : 'marknadsforing/leads/');
					break;
				case 'visits':
					title = 'defaultView.identifiedVisitsWithScore';
					columnsKey = 'visit';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'marketing/' : 'marknadsforing/');
					iframeUrl += 'webbsidebesok/';
					break;
				case 'forms':
					title = 'defaultView.formSubmit';
					columnsKey = 'form';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'marketing/' : 'marknadsforing/');
					iframeUrl += 'formular-och-landningssidor/';
					break;
				case 'groupMail':
					title = 'defaultView.mymailCampaigns';
					columnsKey = 'groupMail';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'marketing/' : 'marknadsforing/');
					iframeUrl += 'e-postutskick/';
					break;
				case 'activitiesAndAppointments':
					title = 'defaultView.myOpenActivitiesTillToday';
					columnsKey = 'activity';
					iframeUrl =
						urlBase +
						(self.language !== 'sv-SE' ? 'sales/activity--meetings/' : 'forsaljning/aktiviteter-moten/');
					break;
				case 'orders':
					title = 'defaultView.mySalesThisMonth';
					columnsKey = 'order';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'sales/' : 'forsaljning/');
					iframeUrl += 'saljtavlan/';
					break;
				case 'pipeline':
					columnsKey = 'pipeline';
					title = 'defaultView.salesboardStandard';
					salesboard = true;
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'sales/' : 'forsaljning/');
					iframeUrl += 'saljtavlan/';
					break;
				case 'esign':
					title = 'default.eSigns';
					columnsKey = 'esign';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'sales/' : 'forsaljning/');
					iframeUrl += 'e-sign/';
					break;
				case 'recurringOrder':
					title = 'defaultView.activeAgreements';
					columnsKey = 'recurringOrder';
					iframeUrl =
						urlBase +
						(self.language !== 'sv-SE' ? 'sales/recurring-order/' : 'forsaljning/aterkommande-order/');
					break;
				case 'socialEvents':
					title = 'default.socialEvents';
					columnsKey = 'socialEvents';
					iframeUrl = urlBase + (self.language !== 'sv-SE' ? 'marketing/events/' : 'marknadsforing/events/');
					break;
			}

			iframeUrl += 'iframe/';

			accountManager = accountSelf.accountManager;
			render();
		};

		AppService.loadedPromise.then(init);
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Column, Row, Label, Toggle, Headline, Button, Input } from '@upsales/components';
import StepIntInput from 'Components/Inputs/StepIntInput';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import MailContentEditorAlign from './MailContentEditorAlign';
import MailContentEditorSelectImage from './MailContentEditorSelectImage';
import './MailContentEditorHeader.scss';

class MailContentEditorHeader extends React.PureComponent {
	constructor(p) {
		super(p);

		const t = getAngularModule('$translate');

		this.lang = {
			backgroundColor: t('form.backgroundColor'),
			header: t('mail.header'),
			fullWidth: t('mailTemplate.pageHeaderFullWidth'),
			logo: t('admin.logo'),
			uploadLogo: t('admin.uploadLogo'),
			useLogo: t('mail.useLogo'),
			useOwnImage: t('mail.useOwnImage'),
			logoLink: t('mail.logoLink'),
			margin: t('mail.margin')
		};
	}

	renderUpload = (classes, logo) => {
		return (
			<Button
				type="link"
				onClick={e => {
					e.stopPropagation();
					this.props.saveProfileLogo(logo);
				}}
				className={classes.elem('upload').b()}
			>
				{this.lang.uploadLogo}
			</Button>
		);
	};

	render() {
		const parentClasses = new bemClass('MailContentEditor');
		const classes = new bemClass('MailContentEditorHeader');
		const isLogo = this.props.config.logoType === 'logo';
		if (
			isLogo &&
			this.props.config.headerLogo !== '__brightLogo__' &&
			this.props.config.headerLogo !== '__darkLogo__'
		) {
			this.props.configChanged('headerLogo', '__brightLogo__');
		}
		return (
			<div>
				<div className={parentClasses.elem('sidebar-section').b()}>
					<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
						<Column>
							<Headline size="xs">{this.lang.header}</Headline>
						</Column>
						<Column align="right" data-testid="document-header-toggle">
							<Toggle
								color="medium-green"
								checked={this.props.config.hasHeader}
								onChange={val => this.props.configChanged('hasHeader', val)}
							/>
						</Column>
					</Row>
					{this.props.config.hasHeader ? (
						<div data-testid="document-header">
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<Label>{this.lang.backgroundColor}</Label>
								</Column>
								<Column align="right">
									<ReactTemplates.bannerEditor.colorPicker
										value={this.props.config.headerBg}
										presets={this.props.accountProfile.colorVariables}
										onChange={color => this.props.configChanged('headerBg', color)}
									/>
								</Column>
							</Row>
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<Label>{this.lang.margin}</Label>
								</Column>
								<Column align="right">
									<StepIntInput
										value={this.props.config.headerMargin}
										onChange={e => this.props.configChanged('headerMargin', e.target.value)}
										size="sm"
									/>
								</Column>
							</Row>
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<Label>{this.props.langFullWidth || this.lang.fullWidth}</Label>
								</Column>
								<Column align="right" size={4}>
									<Toggle
										color="medium-green"
										checked={this.props.config.fullWidthHeader}
										onChange={val => this.props.configChanged('fullWidthHeader', val)}
									/>
								</Column>
							</Row>
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<Headline size="xs">{this.lang.logo}</Headline>
								</Column>
								<Column align="right">
									<Button type="link" onClick={() => this.props.changeLogoType(!isLogo)}>
										{!isLogo ? this.lang.useLogo : this.lang.useOwnImage}
									</Button>
								</Column>
							</Row>
							{isLogo ? (
								<Row>
									<Column>
										<div
											className={classes
												.elem('logo-preview')
												.mod({
													bright: true,
													selected: this.props.config.headerLogo === '__brightLogo__'
												})
												.b()}
											onClick={() => this.props.configChanged('headerLogo', '__brightLogo__')}
										>
											<img src={this.props.accountProfile.logo} />
											{!this.props.accountProfile.logo
												? this.renderUpload(classes, '__brightLogo__')
												: null}
										</div>
									</Column>
									<Column>
										<div
											className={classes
												.elem('logo-preview')
												.mod({
													dark: true,
													selected: this.props.config.headerLogo === '__darkLogo__'
												})
												.b()}
											onClick={() => this.props.configChanged('headerLogo', '__darkLogo__')}
										>
											<img src={this.props.accountProfile.darkLogo} />
											{!this.props.accountProfile.darkLogo
												? this.renderUpload(classes, '__darkLogo__')
												: null}
										</div>
									</Column>
								</Row>
							) : (
								<MailContentEditorSelectImage
									column={{
										initialFixedWidth: 560,
										hPadding: 0
									}}
									value={this.props.config.headerLogo}
									onChange={({ src }) => this.props.configChanged('headerLogo', src)}
								/>
							)}
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<MailContentEditorAlign
										value={this.props.config.headerLogoAlign}
										onChange={align => this.props.configChanged('headerLogoAlign', align)}
									/>
								</Column>
							</Row>
							<Row className={parentClasses.elem('sidebar-section-input-row').b()}>
								<Column>
									<Label>{this.lang.logoLink}</Label>
									<Input
										value={this.props.config.headerLink}
										onChange={e => this.props.configChanged('headerLink', e.target.value)}
										placeholder="https://upsales.com"
									/>
								</Column>
							</Row>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

MailContentEditorHeader.propTypes = {
	configChanged: PropTypes.func.isRequired,
	changeLogoType: PropTypes.func.isRequired,
	saveProfileLogo: PropTypes.func.isRequired,
	config: PropTypes.object,
	accountProfile: PropTypes.object,
	langFullWith: PropTypes.any // Added this so that we can change the text but still reuse the component
};

MailContentEditorHeader.defaultProps = {
	configChanged: () => {},
	saveProfileLogo: () => {},
	changeLogoType: () => {}
};

export default MailContentEditorHeader;

'use strict';

import React from 'react';
import { TimeInput } from '@upsales/components';
export default class Timepicker extends React.Component {
	regexp = new RegExp(/^([01]\d|2[0-3]):([0-5]\d)$/);

	constructor(props) {
		super(props);
		this.state = {
			value: props.value
		};
	}

	setRef = (name, ref) => {
		this[name] = ref;
	};

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(newProps) {
		this.setState({
			value: newProps.value
		});
	}

	format = val => {
		var timeMoment = moment(val);
		if (val && timeMoment.isValid()) {
			if (this.props.asString) {
				return val;
			}

			var value = timeMoment.format('HH:mm');
			if (this.regexp.test(value)) {
				return value;
			}
		} else if (val === undefined) {
			return '';
		}
		return val;
	};

	inputChange = e => {
		var val = e.target.value;
		if (this.regexp.test(val)) {
			let value;
			if (this.props.useNewTime) {
				const [hour, minutes] = val.split(':');
				value = moment().hour(hour).minute(minutes).second(0).millisecond(0).toDate();
			} else {
				value = moment().hour(val[0]).minute(val[1]).second(0).millisecond(0).toDate();
			}
			this.props.onChange(value);
		} else {
			val = null;
			if (this.props.hasOwnProperty('invalidTimeValue')) {
				this.props.onChange(this.props.invalidTimeValue);
			}
		}

		this.setState({ value: val });
	};

	timeChange = () => {
		var input = jQuery(this._input);
		var value = input.timepicker('getSecondsFromMidnight');

		if (value === null) {
			return undefined;
		}

		var hours = Math.floor(value / 3600);
		var minutes = Math.floor(value / 60) - hours * 60;

		value = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);

		if (this.props.asString !== undefined) {
			return value;
		}

		value = value.split(':');
		value = moment().hour(value[0]).minute(value[1]).second(0).millisecond(0).toDate();
		this.props.onChange(value);
	};

	componentDidMount() {
		var self = this;
		var input = jQuery(self._input);

		//jQuery timepicker config
		var pickerConfig = {
			step: self.props.step || 15,
			appendTo: self.props.container || 'body',
			timeFormat: self.props.timeFormat || 'H:i',
			lang: { decimal: '.', mins: 'min', hr: 'h', hrs: 'h' },
			forceRoundTime: false,
			maxTime: '24:00',
			scrollDefaultNow: true
		};

		input.timepicker(pickerConfig);

		input.on('timeFormatError', function (e) {
			e.stopPropagation();
		});

		input.on('selectTime', function () {
			self.timeChange();
		});

		input.on('changeTime', function () {
			self.timeChange();
		});
	}

	hideOnBlur = () => {
		var element = this._input;
		if (element) {
			setTimeout(function () {
				$(element).timepicker('hide');
			}, 200);
		}
	};

	render() {
		var self = this;
		var value = self.format(self.state.value);

		var onBlur = function () {};
		if (this.props.onBlur) {
			onBlur = this.props.onBlur;
		} else if (this.props.hideOnBlur) {
			onBlur = this.hideOnBlur;
		}

		if (this.props.useNewTime) {
			const usesTwelveHourFormat = moment('13:00', 'LT').format('LT').length > 5;
			return (
				<TimeInput
					step={15}
					value={typeof value !== 'number' ? value || '' : value}
					onChange={self.inputChange}
					placeholder={self.props.placeholder || ''}
					tabIndex={self.props.tabindex || null}
					disabled={self.props.disabled || false}
					timeFormat={usesTwelveHourFormat ? 'military' : undefined}
					onBlur={onBlur}
				/>
			);
		}

		return (
			<input
				autoFocus={this.props.autoFocus || false}
				ref={self.setRef.bind(self, '_input')}
				type="text"
				maxLength="8"
				placeholder={self.props.placeholder || ''}
				value={value}
				tabIndex={self.props.tabIndex || null}
				disabled={self.props.disabled || false}
				onChange={self.inputChange}
				name={self.props.name || ''}
				className={self.props.className || 'form-control'}
				onBlur={onBlur}
			/>
		);
	}
}

import { Block, Paginator } from '@upsales/components';
import AdminHeader from 'Components/Admin/AdminHeader';

ReactTemplates.admin.imports = window.ReactCreateClass({
	getInitialState: function () {
		return {
			importing: false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			importTitle: t('import.pagetitle'),
			importDesc: t('import.description'),
			started: t('import.started'),
			filename: t('file.fileName'),
			rows: t('default.rows'),
			user: t('default.user'),
			ongoingImports: t('imports.ongoing'),
			doneImports: t('imports.done'),
			nodata: t('default.noData'),
			uploadFile: t('default.uploadFile'),
			selectFileText: t('file.uploadHelpText_split1'),
			dropfileHere: t('file.uploadHelpText_split2'),
			dropToUpload: t('file.dropToUpload'),
			ongoing: t('default.ongoing'),
			draft: t('mail.draft'),
			waiting: t('admin.waiting')
		};
	},

	go: function (id) {
		Tools.$state.go('administration.import', { id: id, stepId: Tools.$state.params.stepId });
	},

	deleteImport: function (e, imprt) {
		e.stopPropagation();
		this.props.rootData.deleteImport(imprt);
	},

	renderTable: function (key, total, limit, offset, onOffsetChange, title, icon, rows) {
		if (!rows.length) {
			return null;
		}
		var widths = ['155px', '355px', '110px', '155px', '60px'];
		var tableHeader = (
			<tr>
				<th style={{ width: widths[0] }}>{this.lang.started}</th>
				<th style={{ width: widths[1] }}>{this.lang.filename}</th>
				<th>{this.lang.rows}</th>
				<th>{this.lang.user}</th>
				<th style={{ width: widths[4] }}></th>
			</tr>
		);

		return (
			<div className="admin-table" key={key}>
				<div className="admin-table-top">
					<span className="admin-table-title">
						<b className={'fa fa-' + icon}></b> {title}
					</span>
				</div>
				<table>
					{rows.length ? <thead>{tableHeader}</thead> : null}
					<tbody>{rows}</tbody>
				</table>
				{total > limit ? (
					<Block space="ptl pbl">
						<Paginator total={total} limit={limit} offset={offset} onChange={onOffsetChange} />
					</Block>
				) : null}
			</div>
		);
	},

	render: function () {
		var self = this;

		var rootData = this.props.rootData;

		var ongoingImportsRows = rootData.pageData.ongoing.map(function (row) {
			const startedRow = rootData.pageData.started?.find(o => o.id === row.entityId);
			var progress = { width: ((parseInt(row.status) || 0) / 100) * 100 + '%' };
			return (
				<tr key={row.id}>
					<td>{row.date ? moment(row.date).format('L LT') : null}</td>
					<td className="status-col">
						{startedRow?.file && startedRow?.file.filename}
						{row.status ? (
							<div className="progress" style={{ width: '150px' }}>
								<div className="progress-bar bright-blue" style={progress}></div>
							</div>
						) : (
							<div>
								<span className="text-grey waiting-text">{self.lang.waiting.toUpperCase()}</span>
							</div>
						)}
					</td>
					<td>{startedRow?.numRows || 0}</td>
					<td>{row.registeredBy && row.registeredBy.name}</td>
					<td></td>
				</tr>
			);
		});

		var draftImportsRows = rootData.pageData.draft.map(function (row) {
			return (
				<tr
					key={row.id}
					className="clickable"
					onClick={function () {
						self.go(row.id);
					}}
				>
					<td>{row.regDate && moment(row.regDate).format('L LT')}</td>
					<td>{row.file && row.file.filename}</td>
					<td>{row.numRows || 0}</td>
					<td>{row.user && row.user.name}</td>
					<td className="admin-row-tools-wrap">
						<div className="admin-row-tools">
							<button
								type="button"
								className="up-btn btn-link btn-grey delete-trigger"
								onClick={function (e) {
									self.deleteImport(e, row);
								}}
							>
								<b className="fa fa-trash-o"></b>
							</button>
						</div>
					</td>
				</tr>
			);
		});

		var doneImportsRows = rootData.pageData.done.map(function (row) {
			return (
				<tr key={row.id}>
					<td>{row.regDate && moment(row.regDate).format('L LT')}</td>
					<td>{row.file && row.file.filename}</td>
					<td>{row.numRows || 0}</td>
					<td>{row.user && row.user.name}</td>
					<td></td>
				</tr>
			);
		});

		return (
			<div id="admin-page-import">
				<AdminHeader
					title={this.lang.importTitle}
					description={this.lang.importDesc}
					image="import.svg"
					articleId={621}
				/>

				<div id="admin-content">
					<ReactTemplates.admin.importUploader
						importType={this.props.rootData.importType}
						onUpload={this.props.rootData.onCreate}
						loading={rootData.saving}
					/>

					{this.renderTable(
						1,
						rootData.pageData.ongoingTotal,
						rootData.pageData.limit,
						rootData.pageData.ongoingOffset,
						() => {},
						self.lang.ongoing,
						'refresh',
						ongoingImportsRows
					)}

					{this.renderTable(
						2,
						rootData.pageData.draftTotal,
						rootData.pageData.limit,
						rootData.pageData.draftOffset,
						offset => rootData.pageData.getDraft(offset),
						self.lang.draft,
						'edit',
						draftImportsRows
					)}

					{this.renderTable(
						3,
						rootData.pageData.doneTotal,
						rootData.pageData.limit,
						rootData.pageData.doneOffset,
						offset => rootData.pageData.getDone(offset),
						self.lang.doneImports,
						'check',
						doneImportsRows
					)}
				</div>
			</div>
		);
	}
});

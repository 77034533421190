import { Block, Icon, Link, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { FlowStep } from 'App/resources/Model/Flow';
import FlowStepUserRow from '../FlowStepUserRow';
import T from 'Components/Helpers/translate';
import React from 'react';

import './FlowStepCreatePhoneCall.scss';

type Props = {
	step: FlowStep;
	onClick: () => void;
	onDelete: () => void;
	editable: boolean;
	isInvalid: boolean;
	onAddBehavior: (type: string, skipPopup: boolean) => void;
	onDeleteBehavior: () => void;
};

export const MAX_HEIGHT = 100;

const FlowStepCreatePhoneCall = ({ step, onAddBehavior, onDeleteBehavior, ...baseProps }: Props) => {
	const classes = new BemClass('FlowStepCreatePhoneCall');
	const hasBehaviorFeature = Tools.FeatureHelper.hasSoftDeployAccess('FLOW_PHONE_OUTCOME');
	const hasBehaviour = step.childYes?.type === 'phoneOutcome';

	return (
		<FlowStepUserRow
			icon={'phone'}
			title={T('flow.step.createPhoneCall')}
			maxHeight={MAX_HEIGHT}
			isSplit={hasBehaviour}
			isSplitYesIcon="phone-success"
			isSplitNoIcon="phone-fail"
			step={step}
			placeholder={baseProps.editable ? T('flow.step.selectCaller') : T('flow.noCaller')}
			className={classes.b()}
			{...baseProps}
			onClickBehaviour={undefined}
		>
			{hasBehaviorFeature ? (
				<Block space="mts">
					{baseProps.editable && !hasBehaviour ? (
						<Block>
							<Text size="sm">
								<Link
									onClick={e => {
										e.stopPropagation();
										onAddBehavior('phoneOutcome', true);
									}}
								>
									<Icon name="plus" space="mrs" />
									{`${T('flow.addCallOutcome')}`}
								</Link>
							</Text>
						</Block>
					) : hasBehaviour ? (
						<Block space="mts" className={classes.elem('behaviorBlock').b()}>
							<Text size="sm">{`${T('flow.callOutcome')}`}</Text>
							{baseProps.editable ? (
								<Tooltip className={classes.elem('deleteTooltip').b()} title={T('flow.removeStep')}>
									<Icon
										name="trash"
										onClick={e => {
											e.stopPropagation();
											onDeleteBehavior();
										}}
										className={classes.elem('trash').b()}
										color="grey-9"
									/>
								</Tooltip>
							) : null}
						</Block>
					) : null}
				</Block>
			) : null}
		</FlowStepUserRow>
	);
};

export default FlowStepCreatePhoneCall;

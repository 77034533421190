import React from 'react';
import PropTypes from 'prop-types';
import AssignModalActivityRow from './AssignModalActivityRow';
import BemClass from '@upsales/components/Utils/bemClass';
import { Table, TableHeader, Title } from '@upsales/components';
import './AssignModalActivityList.scss';

class AssignModalActivityList extends React.Component {
	constructor(props) {
		super(props);
		var t = window.Tools.$translate;

		this.lang = {
			type: t('default.type'),
			user: t('default.user')
		};
	}
	renderRows(items) {
		return items.map(item => (
			<AssignModalActivityRow item={item} key={`is-appointment${item.isAppointment}-${item.id}`} />
		));
	}
	render() {
		const { type, user } = this.lang;
		const { title, className, items } = this.props;
		const columns = [{ title: type }, { title: user }, { title: '' }];

		const classNames = new BemClass('AssignModalActivityList', className);

		var rows = this.renderRows(items);
		var tableClassNames = classNames.elem('table');

		return (
			<div className={classNames}>
				{title ? <Title className={classNames.elem('list-heading').b()}>{title}</Title> : null}
				<Table className={tableClassNames.b()}>
					<TableHeader
						className={classNames.elem(className).elem('list-heading-table-header').b()}
						columns={columns}
					/>
					{rows}
				</Table>
			</div>
		);
	}
}

AssignModalActivityList.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	items: PropTypes.array
};

export default AssignModalActivityList;

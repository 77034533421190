import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import { Label } from '@upsales/components';
import './Select2FloatingLabel.scss';

/*
	The css only works for multi select2 right now, if one wants it to work 
	for single selects look that the scss used in pages/udo.jsx
*/
const Select2FloatingLabel = (WrappedComponent, path) => {
	class FloatingComponent extends React.Component {
		constructor(props) {
			super(props);
			this.state = { shouldFloat: false };
		}

		render() {
			const { multiple, value, label, className = '', data, onChange } = this.props;
			const Select2FloatingLabelClass = new Bem('Select2FloatingLabel');
			const hasValue = (multiple ? value && value.length : value) || this.state.shouldFloat;
			const classNames = className.split(' ');
			classNames.push(Select2FloatingLabelClass.elem('input').b());

			const Component = WrappedComponent ? WrappedComponent : _.get(ReactTemplates, path);

			return (
				<div className={Select2FloatingLabelClass.mod({ 'has-value': hasValue }).b()}>
					<Label>{label}</Label>
					<Component
						className={classNames.join(' ').trim()}
						multiple={multiple}
						value={value}
						data={data}
						onChange={onChange}
						eventListeners={{
							'select2-opening': () => this.setState({ shouldFloat: true }),
							'select2-close': () => setTimeout(() => this.setState({ shouldFloat: false }), 100)
						}}
					/>
				</div>
			);
		}
	}

	FloatingComponent.propTypes = {
		multiple: PropTypes.bool,
		value: PropTypes.any,
		label: PropTypes.string,
		className: PropTypes.string,
		data: PropTypes.array,
		onChange: PropTypes.func
	};

	return FloatingComponent;
};

export default Select2FloatingLabel;

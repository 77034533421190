'use strict';

ReactTemplates.flash.shield = window.ReactCreateClass({
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			title: t(this.props.title).toUpperCase(),
			placeholder: t(this.props.placeholder)
		};
	},
	render: function () {
		var count = this.props.settings.data || 0;

		return (
			<div id={this.props.id} className="shield-widget">
				<div className="shield-widget-shield-wrap">
					<div className="shield-widget-shield">
						<div className="shield-widget-icon">
							<b className="up-icon-shield"></b>
						</div>
						<div className="shield-widget-count">{count}</div>
					</div>
				</div>
				<div className="shield-widget-title">{this.lang.title}</div>
			</div>
		);
	}
});

'use strict';

import openModal from 'App/services/Modal';

angular.module('domain.contact').controller('ContactUdo', [
	'$scope',
	'AppService',
	'$stateParams',
	'UserDefinedObject',
	'RequestBuilder',
	'$upModal',
	function ($scope, AppService, $stateParams, UserDefinedObject, RequestBuilder, $upModal) {
		var ContactCtrl = $scope.ContactCtrl;
		var ContactUdoCtrl = this;

		ContactUdoCtrl.items = [];

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.added', function (e, added) {
			if (added.client && added.client.id === ContactCtrl.account.id) {
				ContactUdoCtrl.items.push(added);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.deleted', function (e, deleted) {
			var index = _.findIndex(ContactUdoCtrl.items, { id: deleted.id });

			if (index !== -1) {
				ContactUdoCtrl.items.splice(index, 1);
			}
		});

		$scope.$on('userDefinedObject' + $stateParams.udoId + '.updated', function (e, updated) {
			if (updated.client && updated.client.id === ContactCtrl.account.id) {
				var index = _.findIndex(ContactUdoCtrl.items, { id: updated.id });

				if (index !== -1) {
					ContactUdoCtrl.items[index] = updated;
				}
			}
		});

		var preventEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();
		};

		ContactUdoCtrl.removeItem = function (item, e) {
			preventEvent(e);

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					body: 'confirm.userDefinedObject',
					onClose: confirmed => {
						if (confirmed) {
							UserDefinedObject.setId(ContactUdoCtrl.udo.id).delete(item);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'default.remove',
					body: 'confirm.userDefinedObject',
					resolveTrue: 'default.remove',
					icon: 'fa-warning',
					focus: 1
				})
				.then(function () {
					// Delete item here
					UserDefinedObject.setId(ContactUdoCtrl.udo.id).delete(item);
				});
		};

		ContactUdoCtrl.editItem = function (item, e) {
			preventEvent(e);

			$upModal.open('editUserDefinedObject', {
				typeId: ContactUdoCtrl.udo.id,
				id: item.id
			});
		};

		ContactUdoCtrl.createItem = function () {
			$upModal.open('editUserDefinedObject', {
				typeId: ContactUdoCtrl.udo.id,
				object: {
					client: { id: ContactCtrl.contact.client.id },
					contact: { id: ContactCtrl.contact.id }
				}
			});
		};

		ContactUdoCtrl.selectLink = function (url, e) {
			e.stopPropagation();
			window.open(url, '_blank');
		};

		var getItems = function () {
			ContactUdoCtrl.loading = true;
			var filters = new RequestBuilder();
			filters.addFilter(
				UserDefinedObject.attr.contact.attr.id,
				filters.comparisonTypes.Equals,
				ContactCtrl.contact.id
			);
			filters.addSort(UserDefinedObject.attr.regDate, true);

			return UserDefinedObject.setId(ContactUdoCtrl.udo.id)
				.find(filters.build())
				.then(function (res) {
					ContactUdoCtrl.items = res.data;
					ContactCtrl.udoTotal[ContactUdoCtrl.udo.id] = res.metadata.total;
					ContactUdoCtrl.loading = false;
				});
		};

		var init = function () {
			var cacheKey = 'userDefined' + $stateParams.udoId;
			var fields = AppService.getCustomFields(cacheKey);
			var categoryTypes = AppService.getCategoryTypes(cacheKey);

			ContactUdoCtrl.udo = _.find(ContactCtrl.udo, function (obj) {
				return obj.id === parseInt($stateParams.udoId);
			});

			// Set up columns
			ContactUdoCtrl.columns = [];

			angular.forEach(fields, function (field) {
				if (field.visible && field.datatype !== 'Text') {
					ContactUdoCtrl.columns.push(field);
				}
			});

			ContactUdoCtrl.columns = _.sortBy(ContactUdoCtrl.columns, 'sortId');

			if (categoryTypes.length) {
				angular.forEach(categoryTypes, function (type) {
					ContactUdoCtrl.columns.push(type);
				});
			}
			ContactUdoCtrl.columns = ContactUdoCtrl.columns.slice(0, 6);

			getItems();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);

import PropTypes from 'prop-types';
import React from 'react';
import { Input, Toggle, Label, ButtonSelect } from '@upsales/components';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import CriteriaDrilldownWrapper from '../CriteriaDrilldownWrapper';
import ListInput from './ListInput';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import { OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';

const mapIdsToObject = (sourceObjects, ids) => {
	var objects = _.map(ids, function (objectId) {
		return _.find(sourceObjects, { id: objectId });
	});

	return _.compact(objects);
};

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export default class ActivityDrilldown extends React.Component {
	constructor(props) {
		super(props);

		const { $translate, AppService } = Tools;

		this.lang = {
			users: Tools.$translate('default.users') + ':',
			date: $translate('default.date') + ':',
			type: $translate('default.type') + ':',
			status: $translate('default.status') + ':',
			orderValue: $translate('field.order.value') + ':',
			from: $translate('default.from'),
			to: $translate('default.to'),
			all: $translate('default.all'),
			closed: $translate('default.closed'),
			open: $translate('default.open'),
			notReached: $translate('activity.outcome.phonecall.notReached'),
			reached: $translate('activity.outcome.phonecall.reached'),
			numOfDays: $translate('default.numOfDays'),
			priority: $translate('activity.priority'),
			high: $translate('activity.high'),
			latestOutcome: $translate('activity.latestOutcome') + ':',
			latestOutcomeDate: $translate('activity.latestOutcomeDate') + ':',
			callist: $translate('default.callList') + ':',
			selectCallist: $translate('default.select') + ' ' + $translate('default.callList').toLowerCase(),
			selectActivityType: $translate('default.select') + ' ' + $translate('default.activityType').toLowerCase(),
			selectUsers: $translate('default.select') + ' ' + $translate('default.users').toLowerCase()
		};

		this.onUserChange = this.onChange.bind(this, 'user');
		this.onStatusChanged = this.onChange.bind(this, 'status');
		this.onOutcomeChanged = this.onChange.bind(this, 'outcome');
		this.onTypeChange = this.onChange.bind(this, 'type');
		this.onCallistChange = this.onChange.bind(this, 'callist');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onPriorityChange = this.onChange.bind(this, 'priority');
		this.onDateStartChange = this.onChange.bind(this, 'dateStart');
		this.onDateEndChange = this.onChange.bind(this, 'dateEnd');
		this.onOutcomeDatePresetChange = this.onChange.bind(this, 'outcomeDatePreset');
		this.onOutcomeDateStartChange = this.onChange.bind(this, 'outcomeDateStart');
		this.onOutcomeDateEndChange = this.onChange.bind(this, 'outcomeDateEnd');

		const { config } = this.props;
		const activityTypes = AppService.getActivityTypes().concat(_.values(AppService.getTodoTypes()));
		this.mapActivityTypesObjects = mapIdsToObject.bind(null, activityTypes);

		this.datePresets = _.map(config.presets, function (preset) {
			return {
				id: preset,
				name: capitalizeFirstLetter($translate('date.' + preset))
			};
		});

		this.statusOptions = [
			{ title: this.lang.all, value: null },
			{ title: this.lang.open, value: 'open' },
			{ title: this.lang.closed, value: 'closed' }
		];

		this.outcomeOptions = [
			{ title: this.lang.all, value: null },
			{ title: this.lang.reached, value: OUTCOME_TYPES.ANSWER },
			{ title: this.lang.notReached, value: OUTCOME_TYPES.NO_ANSWER }
		];
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		if (!newFilter.value.OutcomeDate) {
			newFilter.value.OutcomeDate = { value: { start: null, end: null, preset: 'whenever' } };
		}

		switch (key) {
			case 'user':
				newFilter.value.User.value = value;
				break;
			case 'status':
				newFilter.value.Status.value = value;
				break;
			case 'outcome':
				newFilter.value.Outcome.value = value;
				break;
			case 'type':
				newFilter.value.ActivityType.value = _.pluck(value, 'id');
				break;
			case 'callist':
				newFilter.value.Campaign.value = value;
				break;
			case 'priority':
				newFilter.value.Priority.value = [value ? ACTIVITY_PRIORITY.HIGH : ACTIVITY_PRIORITY.NONE];
				break;
			case 'preset': {
				const preset = value.target.value;
				newFilter.value.Date.value.preset = preset;
				newFilter.value.Date.value.start = null;
				newFilter.value.Date.value.end = null;
				break;
			}
			case 'dateStart':
				newFilter.value.Date.value.start = value;
				break;
			case 'dateEnd':
				newFilter.value.Date.value.end = value;
				break;
			case 'outcomeDatePreset': {
				const preset = value.target.value;
				newFilter.value.OutcomeDate.value.preset = preset;
				newFilter.value.OutcomeDate.value.start = null;
				newFilter.value.OutcomeDate.value.end = null;
				break;
			}
			case 'outcomeDateStart':
				newFilter.value.OutcomeDate.value.start = value;
				break;
			case 'outcomeDateEnd':
				newFilter.value.OutcomeDate.value.end = value;
				break;
			default:
				throw new Error('Should never get here');
		}

		onChange(newFilter.filterName, newFilter);
	}

	render() {
		const { filter, open, fakeConfigs, selectData } = this.props;
		const preset = filter.value.Date.value.preset;
		const presetValue = _.find(this.datePresets, { id: preset });
		const showDatepickers = preset === 'custom';
		const showDayInput = preset === 'lastXdays';

		const OutcomeDate = filter.value.OutcomeDate ?? { value: { start: null, end: null, preset: 'whenever' } };
		const outcomeDatePreset = OutcomeDate.value.preset;
		const outcomeDatePresetValue = _.find(this.datePresets, { id: outcomeDatePreset });
		const showOutcomeDateDatepickers = outcomeDatePreset === 'custom';
		const showOutcomeDateDayInput = outcomeDatePreset === 'lastXdays';

		const importantPrio =
			filter.value.Priority && filter.value.Priority.value[0] === ACTIVITY_PRIORITY.HIGH
				? filter.value.Priority.value[0]
				: false;

		const showActivityTypeSection = !['ClientToDo', 'ContactToDo', 'ContactPhoneCall', 'ClientPhoneCall'].includes(
			filter.filterName
		);
		const showStatusSection = !['ContactPhoneCall', 'ClientPhoneCall'].includes(filter.filterName);
		const showPrioritySection = !['ClientToDo', 'ContactToDo'].includes(filter.filterName);
		const showCallistSection = false; // Should be added later
		const showOutcomeSection = ['ContactPhoneCall', 'ClientPhoneCall'].includes(filter.filterName);

		const callistSelect = {
			placeholder: this.lang.selectCallist,
			config: fakeConfigs.Callist,
			defaultValue: filter.value.Campaign.value,
			onChange: this.onCallistChange,
			selectData: selectData
		};

		const userSelect = {
			placeholder: this.lang.selectUsers,
			config: fakeConfigs.User,
			defaultValue: filter.value.User.value,
			onChange: this.onUserChange,
			selectData: selectData,
			select2: {
				formatSelection: function (obj, el, escape) {
					const escapedName = escape(obj.name);
					return obj.isRole ? `<b>${escapedName}</b>` : escapedName;
				}
			}
		};

		return (
			<CriteriaDrilldownWrapper key={filter.filterName + '-drilldown'} className="activity-drilldown" open={open}>
				<td colSpan={3}>
					<div className="drilldown-animation-wrapper">
						<table cellPadding={5}>
							<tbody>
								{/*Date row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.date}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div className={showDatepickers ? 'col-sm-6 col-lg-6' : 'col-lg-6'}>
												<ReactTemplates.INPUTS.upSelect
													className="form-control"
													key={'datepicker-select'}
													data={this.datePresets}
													required={true}
													multiple={false}
													onChange={this.onPresetChange}
													defaultValue={presetValue}
													options={{ minimumResultsForSearch: -1 }}
												/>
											</div>
											{showDatepickers ? (
												<div className="col-sm-3">
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.from}
														name={'startDate'}
														className="form-control"
														value={filter.value.Date.value.start}
														onChange={this.onDateStartChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showDatepickers ? (
												<div className={'col-sm-3'}>
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.to}
														name={'endDate'}
														className="form-control"
														value={filter.value.Date.value.end}
														onChange={this.onDateEndChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showDayInput ? (
												<div className="col-sm-3">
													<Input
														type="number"
														placeholder={this.lang.numOfDays}
														value={filter.value.Date.value.start}
														onChange={e => this.onDateStartChange(e.target.value)}
													/>
												</div>
											) : null}
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/*Users row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.users}</div>
									</td>
									<td>
										<div className="drilldown-row">
											<div className="col-lg-6">
												<ListInput {...userSelect} />
											</div>
										</div>
									</td>
									<td className="CriteriaRow__remove" />
								</tr>

								{/*Outcome row*/}
								{showOutcomeSection ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.latestOutcome}</div>
										</td>
										<td className="table-col">
											<div className="drilldown-row">
												<div className="col-lg-6">
													<ButtonSelect
														options={this.outcomeOptions}
														value={filter.value.Outcome.value}
														onChange={this.onOutcomeChanged}
													/>
												</div>
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/*Outcome date row*/}
								<tr>
									<td className="title-col">
										<div className="drilldown-row">{this.lang.latestOutcomeDate}</div>
									</td>
									<td className="table-col">
										<div className="drilldown-row">
											<div
												className={
													showOutcomeDateDatepickers ? 'col-sm-6 col-lg-6' : 'col-lg-6'
												}
											>
												<ReactTemplates.INPUTS.upSelect
													className="form-control"
													key={'datepicker-select'}
													data={this.datePresets}
													required={true}
													multiple={false}
													onChange={this.onOutcomeDatePresetChange}
													defaultValue={outcomeDatePresetValue}
													options={{ minimumResultsForSearch: -1 }}
												/>
											</div>
											{showOutcomeDateDatepickers ? (
												<div className="col-sm-3">
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.from}
														name={'startDate'}
														className="form-control"
														value={OutcomeDate.value.start}
														onChange={this.onOutcomeDateStartChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showOutcomeDateDatepickers ? (
												<div className={'col-sm-3'}>
													<ReactTemplates.upFilters.components.datepicker
														placeholder={this.lang.to}
														name={'endDate'}
														className="form-control"
														value={OutcomeDate.value.end}
														onChange={this.onOutcomeDateEndChange}
														container="body"
														placement="auto bottom-auto"
													/>
												</div>
											) : null}
											{showOutcomeDateDayInput ? (
												<div className="col-sm-3">
													<Input
														type="number"
														placeholder={this.lang.numOfDays}
														value={OutcomeDate.value.start}
														onChange={e => this.onDateStartChange(e.target.value)}
													/>
												</div>
											) : null}
										</div>
									</td>
									<td className="remove-filter-col" />
								</tr>

								{/*Status row*/}
								{showStatusSection ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.status}</div>
										</td>
										<td className="table-col">
											<div className="drilldown-row">
												<div className="col-lg-6">
													<ButtonSelect
														options={this.statusOptions}
														value={filter.value.Status.value}
														onChange={this.onStatusChanged}
													/>
												</div>
											</div>
										</td>
										<td className="remove-filter-col" />
									</tr>
								) : null}

								{/*Activitytype row*/}
								{showActivityTypeSection ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.type}</div>
										</td>
										<td>
											<div className="drilldown-row">
												<div className="col-lg-6">
													<UpActivityTypes
														onChange={this.onTypeChange}
														placeholder={this.lang.selectActivityType}
														entity={
															['ContactOnlyActivityFilters', 'ClientActivity'].includes(
																filter.filterName
															)
																? 'activity'
																: null
														}
														multiple={true}
														value={this.mapActivityTypesObjects(
															filter.value.ActivityType.value
														)}
													/>
												</div>
											</div>
										</td>
										<td className="CriteriaRow__remove" />
									</tr>
								) : null}

								{/*Priority row*/}
								{showPrioritySection ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.priority}</div>
										</td>
										<td>
											<div className="drilldown-row">
												<div className="col-lg-6 col-prio">
													<Toggle
														className="pull-left"
														onChange={this.onPriorityChange}
														size="md"
														checked={!!importantPrio}
														color="red"
													/>
													<Label className="pull-left">{this.lang.high}</Label>
												</div>
											</div>
										</td>
										<td className="CriteriaRow__remove" />
									</tr>
								) : null}

								{/*Callist row*/}
								{showCallistSection ? (
									<tr>
										<td className="title-col">
											<div className="drilldown-row">{this.lang.callist}</div>
										</td>
										<td>
											<div className="drilldown-row">
												<div className="col-lg-6">
													<ListInput {...callistSelect} />
												</div>
											</div>
										</td>
										<td className="CriteriaRow__remove" />
									</tr>
								) : null}
							</tbody>
						</table>
					</div>
				</td>
			</CriteriaDrilldownWrapper>
		);
	}
}

ActivityDrilldown.propTypes = {
	open: PropTypes.bool.isRequired,
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	selectData: PropTypes.object.isRequired,
	fakeConfigs: PropTypes.object.isRequired
};

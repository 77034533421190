import React from 'react';
import { Card, CardContent, Link, Title, Row, Button } from '@upsales/components';
import './UpsalesKnowledgebaseCard.scss';

const UpsalesKnowledgebaseCard = ({ className = '', title, link, articles = [] }) => {
	const openLink = () => {
		window.open(link, '_blank');
	};

	return (
		<Card className={`${className} UpsalesKnowledgebaseCard`}>
			<div className="CardHeader">
				<img src="img/graduation-hat.svg" />
				<div className="CardHeader__title-wrap">
					<Title>{title}</Title>
				</div>
			</div>
			<CardContent>
				{articles.map((article, index) => (
					<Row key={index}>
						<Link title={article.title} href={article.url} target="_blank">
							{article.title}
						</Link>
					</Row>
				))}
			</CardContent>

			<Button block={true} shadow="none" color="white" onClick={() => openLink()}>
				{Tools.$translate('default.visitHelpCenter')}
			</Button>
		</Card>
	);
};

window.UpsalesKnowledgebaseCard = UpsalesKnowledgebaseCard;
export default UpsalesKnowledgebaseCard;

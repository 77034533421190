'use strict';

angular.module('domain.contact').factory('MergeContactsMeta', [
	'$q',
	'Contact',
	'RequestBuilder',
	'$upModal',
	function ($q, Contact, RequestBuilder, $upModal) {
		return function ($modalParams) {
			var customerId = $modalParams.customerId;
			var rb = new RequestBuilder();
			rb.addFilter(Contact.attr.client.attr.id, rb.comparisonTypes.Equals, $modalParams.accountId);
			rb.addFilter(Contact.attr.id, rb.comparisonTypes.NotEquals, $modalParams.id);
			rb.addFilter(Contact.attr.active, rb.comparisonTypes.Equals, true);
			rb.addSort(Contact.attr.name, true);
			rb.fields = ['id', 'name'];
			var promises = {
				contact: Contact.customer(customerId).get($modalParams.id),
				contacts: Contact.customer(customerId).find(rb.build())
			};

			return $q.all(promises).then(
				function (results) {
					if (results.contacts.metadata.total > results.contacts.data.length) {
						results.contacts.isAjax = true;
					}
					if (!results.contacts.data.length) {
						$upModal.open('warningAlert', {
							title: 'default.noContacts',
							body: 'contact.noContactsToMerge'
						});
						return $q.reject();
					}
					return results;
				},
				function (err) {
					return $q.reject(err);
				}
			);
		};
	}
]);

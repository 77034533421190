import { globalTracker } from 'App/babel/helpers/Tracker';
import InactivationInfo from 'Components/Account/AccountDashboard/InactivationInfo/InactivationInfo';
import moment from 'moment';
import News from 'Resources/News';
import logError from 'App/babel/helpers/logError';
import DashboardButtonGroup from 'Components/Account/AccountDashboard/DashboardButtonGroup';
import TimelineEntityNote from 'Components/TimelineRow/TimelineEntityNote';
import DashboardPinnedComments from 'Components/Account/AccountDashboard/DashboardPinnedComments';
import NotesFilterButton from 'App/components/NotesFilter/NotesFilterButton';
import store from 'Store';
import { ActivityDateSorter } from 'Components/Helpers/Sorters';
import TimelineMail from 'Components/TimelineRow/TimelineMail';
import FlowContactsActive from 'Resources/FlowContactsActive';
import Comment from 'Resources/Comment';
import ClientCardOverview from 'App/pages/ClientCard/ContentPages/Overview/Overview';

angular.module('domain.account').controller('AccountDashboard', [
	'$scope',
	'$q',
	'$stateParams',
	'$safeApply',
	'RequestBuilder',
	'Event',
	'ActivityList',
	'EventService',
	'AppService',
	'FilterHelper',
	'ScriptService',
	'FeatureHelper',
	function (
		$scope,
		$q,
		$stateParams,
		$safeApply,
		RequestBuilder,
		Event,
		ActivityList,
		EventService,
		AppService,
		FilterHelper,
		ScriptService,
		FeatureHelper
	) {
		var AccountCtrl = $scope.AccountCtrl;
		AccountCtrl.InactivationInfo = InactivationInfo;

		AccountCtrl.DashboardButtonGroup = DashboardButtonGroup;
		AccountCtrl.DashboardPinnedComments = DashboardPinnedComments;
		AccountCtrl.TimelineEntityNote = TimelineEntityNote;
		AccountCtrl.NotesFilterButton = NotesFilterButton;
		AccountCtrl.TimelineMail = TimelineMail;
		AccountCtrl.hasMailRelations =
			FeatureHelper.hasSoftDeployAccess('MAIL_RELATION') && FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		AccountCtrl.removeActivities =
			FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') && FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		AccountCtrl.hasPinnedComments = FeatureHelper.hasSoftDeployAccess('PINNED_COMMENTS');

		AccountCtrl.useReactDashboard = FeatureHelper.hasSoftDeployAccess('ACCOUNT_CARD_OVERVIEW_USE_REACT_TAB');
		AccountCtrl.ClientCardOverview = ClientCardOverview;

		var customerId = $stateParams.customerId;
		var self;
		var openActivitiesQuery;
		var currentTimelineQuery;

		var activitiesLimit = 25;
		$scope.openActivities = [];
		$scope.openActivitiesOffset = 0;
		$scope.totalOpenActivities = 0;
		$scope.openActivitiesLoading = false;

		var eventLimit = 25;
		$scope.events = [];
		$scope.eventOffset = 0;
		$scope.totalEvents = 0;
		$scope.eventsLength = 0;
		$scope.eventsLoading = false;

		var activeFlowLimit = 20;
		$scope.activeFlows = [];
		$scope.activeFlowOffset = 0;
		$scope.totalActiveFlows = 0;

		$scope.activityLoading = false;
		$scope.historySelection = null;
		$scope.historyType = null;
		$scope.notesFilterActive = store.getState().NotesFilterActive;

		$scope.storeUnsubscribe = store.subscribe(() => {
			const notesFilterActive = store.getState().NotesFilterActive;
			if (!notesFilterActive === $scope.notesFilterActive) {
				$scope.notesFilterActive = notesFilterActive;
				$safeApply($scope);
			}
		});

		$scope.$on('$destroy', () => {
			$scope.storeUnsubscribe?.();
		});

		$scope.showCommentOrNote = event => {
			const hasComments = event.data?.comment;
			let hasNotes;

			if (event.entityType === 'Appointment') {
				hasNotes = event.appointment.notes || event.appointment.description;
			} else if (event.entityType === 'Opportunity' || event.entityType === 'Order') {
				hasNotes = event.opportunity.notes || event.opportunity.description;
			} else if (event.entityType === 'Activity') {
				hasNotes = true;
			}
			const hasCommentsOrNotes = hasComments || hasNotes;
			return $scope.notesFilterActive && hasCommentsOrNotes;
		};

		$scope.showEvent = event => {
			return !$scope.showCommentOrNote(event);
		};

		// UPDATED EVENTS
		var activityAppointmentUpdated = function (e, activity) {
			if (AccountCtrl.isThisAccountOrSubaccounts(activity.client)) {
				const newEvent = EventService.create.Activity(activity);
				let isOpenActivity = false;
				let isAppointment = false;

				/* Find the activity, save the type and remove it */

				// Open activities
				let foundEvent = _.find($scope.openActivities, { entityId: activity.id });

				if (foundEvent) {
					isAppointment = foundEvent.appointment ? true : false;
					isOpenActivity = true;
					_.pull($scope.openActivities, foundEvent);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				} else {
					// History activity
					foundEvent = _.find($scope.events, { entityType: 'Activity', entityId: activity.id });

					// History appointment
					if (!foundEvent) {
						isAppointment = true;
						foundEvent = _.find($scope.events, { entityType: 'Appointment', entityId: activity.id });
					}

					if (foundEvent) {
						_.pull($scope.events, foundEvent);
					}
				}

				if (foundEvent) {
					newEvent.$$expand = foundEvent.$$expand;
					Object.keys(foundEvent).forEach(prop => {
						if (typeof foundEvent[prop] === 'function') {
							newEvent[prop] = foundEvent[prop];
						}
					});
				}

				/* Add it if it matches any of the lists filters and update the amount number */

				// Open activities
				if (
					FilterHelper.match(openActivitiesQuery.q, activity, 'activity') &&
					(activity.isAppointment ? moment().isBefore(activity.date) : true) // FilterHelper.match() only compares the day, we want to compare exact time
				) {
					newEvent.date = activity.date;
					$scope.openActivities.push(newEvent);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					if (!isOpenActivity) {
						$scope.totalOpenActivities++;
						$scope.totalAll--;
						if (isAppointment) {
							$scope.totalAppointments--;
						} else {
							$scope.totalActivities--;
						}
					}
				} else if (FilterHelper.match(currentTimelineQuery.q, newEvent, 'event')) {
					$scope.events.push(newEvent);
					if (isOpenActivity) {
						$scope.totalOpenActivities--;
						$scope.totalAll++;
						if (isAppointment) {
							$scope.totalAppointments++;
						} else {
							$scope.totalActivities++;
						}
					}
				}
			}
		};

		$scope.$on('activity.updated', activityAppointmentUpdated);
		$scope.$on('appointment.updated', activityAppointmentUpdated);

		$scope.$on('contact.updated', function (e, contact) {
			if (AccountCtrl.isThisAccountOrSubaccounts(contact.client)) {
				// Update events
				angular.forEach($scope.events, function (event) {
					angular.forEach(event.contacts, function (evtContact) {
						if (evtContact?.id === contact.id) {
							angular.extend(evtContact, contact);
						}
					});
				});

				const event = EventService.create.Contact(contact);
				const match = FilterHelper.match(currentTimelineQuery.q, event, 'event');
				let foundEvent = _.find($scope.events, { entityType: 'Contact', entityId: contact.id });
				// If we find it
				if (foundEvent) {
					// Check if it match the filters
					if (match) {
						foundEvent = EventService.extend.Contact(foundEvent, contact);
					} else {
						// Remove it
						_.pull($scope.events, foundEvent);
					}
				} else if (match) {
					// If we did not find it but it now match we add it
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('order.updated', async function (e, order) {
			if (AccountCtrl.isThisAccountOrSubaccounts(order.client)) {
				const orderEvent = _.find($scope.events, { entityType: 'Order', entityId: order.id });
				if (orderEvent) {
					_.pull($scope.events, orderEvent);
					const updatedOrder = EventService.extend.Order(orderEvent, order);
					setTimeout(() => {
						// Timeout is (for some reason) needed for the order to update
						updatedOrder.$$expand = 1; // Will not update if expanded
						$scope.events.push(updatedOrder);
					});
					return;
				}

				const opportunityEvent = _.find($scope.events, { entityType: 'Opportunity', entityId: order.id });
				if (opportunityEvent) {
					_.pull($scope.events, opportunityEvent);
					$scope.totalOpportunity--;
					$scope.totalOrder++;
					const updatedOpportunity = EventService.extend.Order(opportunityEvent, order);
					if (FilterHelper.match(currentTimelineQuery.q, updatedOpportunity, 'event')) {
						$scope.events.push(updatedOpportunity);
					}
				}
			}
		});

		$scope.$on('agreement.updated', function (e, updated) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(updated.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(updated.clientConnection)
			) {
				const agreementEvent = _.find($scope.events, { entityType: 'Agreement', entityId: updated.id });
				if (!agreementEvent) {
					return;
				}
				EventService.extend.Agreement(agreementEvent, updated);
			}
		});

		$scope.$on('opportunity.updated', async function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				const opportunityEvent = _.find($scope.events, { entityType: 'Opportunity', entityId: updated.id });
				if (opportunityEvent) {
					_.pull($scope.events, opportunityEvent);
					const updatedOpportunity = EventService.extend.Order(opportunityEvent, updated);
					setTimeout(() => {
						// Timeout is (for some reason) needed for the opportunity to update
						updatedOpportunity.$$expand = 1; // Will not update if expanded
						$scope.events.push(updatedOpportunity);
					});
				}
			}
		});

		// ADDED EVENTS
		$scope.$on('activity.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				const event = EventService.create.Activity(added);

				// Check for filter matches in both lists
				if (FilterHelper.match(openActivitiesQuery.q, added, 'activity')) {
					event.date = added.date;
					$scope.openActivities.push(event);
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					$scope.totalOpenActivities++;
					return;
				}

				$scope.totalActivities++;
				$scope.totalAll++;
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					if (added.activityType?.name === 'Phonecall' && added.closeDate) {
						event.date = new Date(added.closeDate);
					}
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('appointment.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				const event = EventService.create.Activity(added);

				// Check for filter matches in both lists
				if (FilterHelper.match(openActivitiesQuery.q, added, 'appointment')) {
					$scope.openActivities.push(event);
					$scope.totalOpenActivities++;
					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
					return;
				}

				$scope.totalAppointments++;
				$scope.totalAll++;
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('comment.added', async function (e, added) {
			if (added.activity || added.appointment || added.opportunity || added.ticket) {
				return;
			}

			if (added.client?.id === AccountCtrl.account.id) {
				if (added.tooBigForPusher) {
					try {
						const { data: comment } = await Comment.get(added.id);
						added = comment;
					} catch (err) {
						logError(err, 'Could not get comment');
						return;
					}
				}

				const event = EventService.create.Comment(added);
				if ($scope.historyType === null || $scope.historyType === 'comments') {
					$scope.events.push(event);
				}
				$scope.totalAll++;
				$safeApply($scope);
			}
		});
		$scope.$on('comment.updated', function (e, updated) {
			if (updated.client?.id === AccountCtrl.account.id) {
				if ($scope.historyType === null || $scope.historyType === 'comments') {
					const foundEvent = _.find($scope.events, { entityId: updated.id, entityType: 'Comment' });
					if (foundEvent) {
						_.pull($scope.events, foundEvent);
						const updatedComment = EventService.extend.Comment(foundEvent, updated);
						$scope.events.push(updatedComment);
						$safeApply($scope);
					}
				}
			}
		});

		$scope.$on('clientPlan.added', function (e, added) {
			if (!AccountCtrl.isThisAccountOrSubaccounts({ id: added.clientId })) {
				return;
			}

			const event = EventService.create.ClientPlan(added, 'Created');
			$scope.events.push(event);
			$scope.totalAll++;
			$safeApply($scope);
		});

		$scope.$on('clientPlan.updated', function (e, updated) {
			if (!AccountCtrl.isThisAccountOrSubaccounts({ id: updated.clientId })) {
				return;
			}

			$scope.events.forEach(event => {
				if (event.entityId === updated.id && event.entityType === 'ClientPlan') {
					event.plan = updated;
				}
			});
			$safeApply($scope);
		});

		$scope.$on('clientPlan.statusChanged', function (e, updated) {
			if (!AccountCtrl.isThisAccountOrSubaccounts({ id: updated.clientId })) {
				return;
			}

			const changedStatus = EventService.create.ClientPlan(updated, 'StatusChanged');
			$scope.events.push(changedStatus);
			$safeApply($scope);
		});

		$scope.$on('ticket.created', function (e, created) {
			if (!AccountCtrl.isThisAccountOrSubaccounts(created.client)) {
				return;
			}

			const createdTicket = EventService.create.Ticket(created, 'Created');
			$scope.events.push(createdTicket);
			$safeApply($scope);
		});

		$scope.$on('ticket.statusChanged', function (e, updated) {
			if (!AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				return;
			}

			const changedStatus = EventService.create.Ticket(updated, 'StatusChanged');
			$scope.events.push(changedStatus);
			$safeApply($scope);
		});

		$scope.$on('mail.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client) && added.type !== 'sch') {
				$scope.totalMarket++;
				$scope.totalAll++;
				const event = EventService.create.Mail(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});
		$scope.$on('mail.updated', function (e, mail) {
			if (AccountCtrl.isThisAccountOrSubaccounts(mail.client)) {
				// Update events

				const event = EventService.create.Mail(mail);
				const match = FilterHelper.match(currentTimelineQuery.q, event, 'event');
				let foundEvent = _.find($scope.events, { entityType: 'Mail', entityId: mail.id });
				// If we find it
				if (foundEvent) {
					// Check if it match the filters
					if (match) {
						foundEvent = EventService.extend.Mail(foundEvent, mail);
					} else {
						// Remove it
						_.pull($scope.events, foundEvent);
					}
				} else if (match) {
					// If we did not find it but it now match we add it
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('agreement.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				$scope.totalAll++;
				const event = EventService.create.Agreement(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('contact.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				$scope.totalAll++;
				const event = EventService.create.Contact(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				$scope.totalOrder++;
				$scope.totalAll++;
				const event = EventService.create.Order(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('opportunity.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				$scope.totalOpportunity++;
				$scope.totalAll++;
				const event = EventService.create.Order(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('esign.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client) && added.state !== 0) {
				$scope.totalAll++;
				const event = EventService.create.Esign(added);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('market.added', function (e, res) {
			if (AccountCtrl.isThisAccountOrSubaccounts(res?.client)) {
				AccountCtrl.account.score += res.score;
				AccountCtrl.account.scoreUpdateDate = res.date;
				AccountCtrl.marketScoreAllTime += res.score;

				$scope.totalAll++;
				$scope.totalMarket++;
				const event = EventService.create.Manual(res);
				if ($scope.historyType === null || $scope.historyType === 'market') {
					$scope.events.push(event);
				}
			}
		});

		// DELETED EVENTS
		$scope.$on('appointment.deleted', function (e, appointment) {
			let foundEvent = _.find($scope.openActivities, { entityId: appointment.id, entityType: 'Appointment' });
			if (foundEvent) {
				$scope.totalOpenActivities--;
				_.pull($scope.openActivities, foundEvent);
				$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				return;
			}

			foundEvent = _.find($scope.events, { entityId: appointment.id, entityType: 'Appointment' });
			if (foundEvent) {
				$scope.totalAppointments--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('opportunity.deleted', function (e, opportunity) {
			const foundEvent = _.find($scope.events, { entityId: opportunity.id, entityType: 'Opportunity' });
			if (foundEvent) {
				$scope.totalOpportunity--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('order.deleted', function (e, order) {
			const foundEvent = _.find($scope.events, { entityId: order.id, entityType: 'Order' });
			if (foundEvent) {
				$scope.totalOrder--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('activity.deleted', function (e, activity) {
			let foundEvent = _.find($scope.openActivities, { entityId: activity.id, entityType: 'Activity' });
			if (foundEvent) {
				$scope.totalOpenActivities--;
				_.pull($scope.openActivities, foundEvent);
				$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				$safeApply($scope);
				return;
			}

			foundEvent = _.find($scope.events, { entityId: activity.id, entityType: 'Activity' });
			if (foundEvent) {
				$scope.totalActivities--;
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
				$safeApply($scope);
			}
		});
		$scope.$on('comment.deleted', function (e, comment) {
			const foundEvent = _.find($scope.events, { entityId: comment.id, entityType: 'Comment' });
			if (foundEvent) {
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
				$safeApply($scope);
			}
		});

		$scope.$on('contact.deleted', function (e, contact) {
			const foundEvent = _.find($scope.events, { entityId: contact.id, entityType: 'Contact' });
			if (foundEvent) {
				$scope.totalAll--;
				_.pull($scope.events, foundEvent);
			}
		});

		$scope.$on('account.assigned', function (e, res) {
			if (res.account?.id === AccountCtrl.account.id) {
				res.account.assigned = !res.account.assigned ? {} : res.account.assigned;
				res.account.assigned.user = res.user;
				const event = EventService.create.Lead(res.account);
				if (FilterHelper.match(currentTimelineQuery.q, event, 'event')) {
					$scope.events.push(event);
				}
			}
		});

		$scope.$on('account.resetScore', function (e, clientId) {
			if (clientId === AccountCtrl.account.id) {
				AccountCtrl.account.score = 0;
				// marketScoreAllTime will be updated from getNOMarketEvents (triggered from account.resetScore in ui/app/upsales/domain/account/controllers/account.js)
				$scope.getEvents(true);
			}
		});

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				// Reload stuff
				$scope.getEvents(true);
				$scope.getActivities(true);
			}
		});

		$scope.$on('account.unassigned', function (e, accountId) {
			if (accountId === AccountCtrl.account.id) {
				getMetaData();
			}
		});

		$scope.$on('news.pin', function (e, event) {
			if (event.client.id === AccountCtrl.account.id) {
				var newsEvent = _.find($scope.events, {
					entityType: 'News',
					entityId: event.entityId
				});
				newsEvent.eventId = event.id;
				newsEvent.pinned = true;
				$scope.totalSignals += 1;
				$scope.totalAll += 1;
				$safeApply($scope);
			}
		});

		$scope.$on('news.unpin', function (e, id) {
			let found = false;
			$scope.events = $scope.events.reduce((events, event) => {
				if (event.entityType === 'News') {
					if (event.news && event.id === id) {
						// Event from event table, should be removed
						found = true;
					} else if (!event.news && event.eventId === id) {
						// Event from creditsafe, should be unpinned
						found = true;
						events.push({ ...event, pinned: false });
					} else {
						events.push(event);
					}
				} else {
					events.push(event);
				}
				return events;
			}, []);
			if (found) {
				if ($scope.historyType === null) {
					// If on 'all' tab, update the number of events in view
					$scope.totalEvents--;
				}
				$scope.totalSignals -= 1;
				$scope.totalAll -= 1;
			}
			$safeApply($scope);
		});

		$scope.sortedOpenActivities = [];

		$scope.timelineHeader = function (date) {
			return date ? moment(date).format('MMMM YYYY') : Tools.$translate('todo.noDate');
		};

		$scope.showHeader = function (obj1, obj2) {
			if (!obj2 || !obj1) {
				return true;
			}
			return moment(obj1.date).format('YYYY-MM') !== moment(obj2.date).format('YYYY-MM');
		};

		$scope.changeHistorySelection = function (selection) {
			if (selection === $scope.historySelection) {
				return;
			}
			$scope.historySelection = selection;
			$scope.getEvents(true);
		};

		$scope.changeHistoryType = function (type) {
			if (type === $scope.historyType) {
				return;
			}
			$scope.historyType = type;
			$scope.getEvents(true);
			$safeApply($scope);
		};

		$scope.toggleNoteFilter = async function () {
			$scope.notesFilterActive = !$scope.notesFilterActive;
			$safeApply($scope);
		};

		const getNews = async () => {
			try {
				if (AccountCtrl.hasSubaccounts && AccountCtrl.subAccountIds?.length) {
					return await News.getClientNews(AccountCtrl.account.id, {
						subAccountIds: AccountCtrl.subAccountIds
					});
				} else if (AccountCtrl.account.prospectingId) {
					let result = await News.getClientNews(AccountCtrl.account.id);
					result = {
						...result,
						data: result.data.map(entry => ({
							...entry,
							entityType: 'News',
							entityId: entry.id,
							clientId: AccountCtrl.account.id,
							date: new Date(entry.date)
						}))
					};
					return result;
				} else {
					return { data: [], metadata: { total: 0 } };
				}
			} catch (err) {
				logError(err, 'Could not get client news');
				return { data: [], metadata: { total: 0 } };
			}
		};

		var diff = 0;

		var getData = async function (historyType, onlyMeta) {
			$scope.eventsLoading = true;

			if (AccountCtrl.hasSignalsFeature && historyType === 'news') {
				const news = await getNews();
				$scope.totalNews = news.data.length;

				if (!onlyMeta) {
					$scope.events = news.data;
					$scope.eventsLength = news.data.length;
					$scope.totalEvents = news.data.length;
					$scope.eventsLoading = false;
				}
				$scope.eventsLoading = false;
				$safeApply($scope);
				return news;
			}

			var eventFilter = new RequestBuilder();
			eventFilter.addFilter(Event.attr.clientId, eventFilter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			eventFilter.addFilter(Event.attr.date, eventFilter.comparisonTypes.LessThanEquals, moment().format());

			// Filter out news if feature not enabled
			if (!AccountCtrl.hasSignalsFeature) {
				eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.NotEquals, 'News');
			}

			if (historyType !== 'market') {
				eventFilter.addFilter(Event.attr.feed, eventFilter.comparisonTypes.Equals, null);
				eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.NotEquals, ['Client']);
			}

			eventFilter.addSort(Event.attr.date, false);
			eventFilter.addSort(Event.attr.entityType, false);
			eventFilter.limit = eventLimit;
			eventFilter.offset = $scope.eventOffset;

			if (onlyMeta) {
				eventFilter.limit = 0;
			}
			if ($scope.historySelection) {
				eventFilter.addFilter(Event.attr.users.attr.id, eventFilter.comparisonTypes.Equals, [self.id]);
			}

			switch (historyType) {
				case 'activities':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Activity']);
					break;

				case 'order':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'Order',
						'Agreement'
					]);
					break;

				case 'appointments':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Appointment']);
					break;

				case 'market':
					eventFilter.addFilter({ field: 'feedMarket' }, eventFilter.comparisonTypes.Equals, null);
					break;

				case 'opportunities':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, ['Opportunity']);
					break;

				case 'signals':
					eventFilter.addFilter(Event.attr.entityType, eventFilter.comparisonTypes.Equals, [
						'News',
						'Signals'
					]);
					break;

				default:
					break;
			}

			currentTimelineQuery = eventFilter.build();
			return Event.customer(customerId)
				.find(currentTimelineQuery)
				.then(function (res) {
					if (res.data.length) {
						// Group Events
						var events = EventService.groupEvents(res.data);
						// Subtract mergedEvents..
						diff = res.data.length - events.length;

						if ($scope.eventOffset === 0) {
							$scope.events = events;
						} else {
							EventService.groupEvents(res.data, $scope.events);
						}
						$scope.totalEvents = res.metadata.total;
						$scope.eventOffset += eventLimit;
						$scope.eventsLength = $scope.events.length;
					} else {
						if ($scope.eventOffset === 0) {
							$scope.events = [];
							$scope.totalEvents = 0;
							$scope.eventsLength = 0;
						}
					}
					switch (historyType) {
						case 'activities':
							$scope.totalActivities = res.metadata.total;
							break;

						case 'order':
							$scope.totalOrder = res.metadata.total;
							break;

						case 'appointments':
							$scope.totalAppointments = res.metadata.total;
							break;

						case 'market':
							$scope.totalMarket = res.metadata.total;
							break;

						case 'opportunities':
							$scope.totalOpportunity = res.metadata.total;
							break;
						case 'signals':
							$scope.totalSignals = res.metadata.total;
							break;
						default:
							$scope.totalAll =
								diff > 0 && res.metadata.total > diff ? res.metadata.total - diff : res.metadata.total;

							break;
					}
					$scope.eventsLoading = false;
					$safeApply($scope);
					return res;
				});
		};

		function getMetaData() {
			$q.all([
				getData('activities', true),
				getData('order', true),
				getData('appointments', true),
				getData('market', true),
				getData('opportunities', true),
				getData('signals', true),
				getData(null, true),
				getData('news', true)
			])
				.then(function (res) {
					$scope.totalActivities = res[0].metadata.total;
					$scope.totalOrder = res[1].metadata.total;
					$scope.totalAppointments = res[2].metadata.total;
					$scope.totalMarket = res[3].metadata.total;
					$scope.totalOpportunity = res[4].metadata.total;
					$scope.totalSignals = res[5].metadata.total;
					$scope.totalAll =
						diff > 0 && res[6].metadata.total > diff ? res[6].metadata.total - diff : res[6].metadata.total;
				})
				.catch(err => console.error(err));
		}

		$scope.getEvents = function (resetOffset) {
			if (resetOffset) {
				$scope.eventOffset = 0;
			}
			return getData($scope.historyType);
		};

		$scope.$on('signals.toggle', function (e, active) {
			if (active) {
				// Signals enabled
				return Promise.all([
					getData('signals', $scope.historyType !== 'signals'),
					getData('news', $scope.historyType !== 'news')
				]);
			} else {
				// Signals disabled
				$scope.totalNews = 0;
				$scope.totalSignals = 0;
				$safeApply($scope);
				return;
			}
		});

		$scope.track = function (type) {
			globalTracker.track('add', { type: type, location: 'account_card_btn_group' });
		};

		$scope.getActivities = function (resetOffset = false) {
			$scope.openActivitiesLoading = true;
			if (resetOffset) {
				$scope.openActivitiesOffset = 0;
			}

			const filter = new RequestBuilder();
			filter.limit = activitiesLimit;
			filter.offset = $scope.openActivitiesOffset;
			filter.addSort(ActivityList.attr.date, false);
			filter.addFilter(ActivityList.attr.client.attr.id, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			filter.addFilter(ActivityList.attr.projectPlan.attr.id, filter.comparisonTypes.Equals, null);

			const orBuilder = filter.orBuilder();
			orBuilder.next();
			orBuilder.addFilter(ActivityList.attr.closeDate, filter.comparisonTypes.Equals, null);
			orBuilder.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
			orBuilder.next();
			orBuilder.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, true);
			orBuilder.addFilter(ActivityList.attr.date, filter.comparisonTypes.GreaterThanEquals, new Date());
			orBuilder.done();

			openActivitiesQuery = filter.build();

			ActivityList.find(openActivitiesQuery)
				.then(function (res) {
					$scope.openActivitiesLoading = false;
					$scope.totalOpenActivities = res.metadata.total;

					const activityEvents = res.data.map(activity => {
						const event = EventService.create.Activity(activity);
						event.date = activity.date;
						event.company = $scope.clientEvent.client;
						return event;
					});

					if ($scope.openActivitiesOffset === 0) {
						$scope.openActivities = activityEvents;
					} else {
						$scope.openActivities = $scope.openActivities.concat(activityEvents);
					}

					$scope.openActivitiesOffset += activitiesLimit;

					$scope.sortedOpenActivities = $scope.openActivities.sort(ActivityDateSorter);
				})
				.catch(err => console.error(err));
		};

		$scope.getActiveFlows = function () {
			const hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
			const includeSubAccounts = hasSubAccounts && AccountCtrl.includingSubaccountData;

			$scope.activeFlowsLoading = true;
			// eslint-disable-next-line promise/catch-or-return
			FlowContactsActive.getActiveFlows(
				'client',
				AccountCtrl.account.id,
				activeFlowLimit,
				$scope.activeFlowOffset,
				includeSubAccounts
			)
				.then(res => {
					res.data.forEach(({ flowId, flowName, date, contacts, segmentId, regByName }) =>
						$scope.activeFlows.push({
							flowName,
							date,
							contacts: contacts.split('_##_').map(contactStr => {
								const contact = contactStr.split(',-,');
								return {
									name: contact[0],
									id: Number(contact[1])
								};
							}),
							segmentId,
							entityType: 'ActiveFlow',
							entityId: flowId,
							regByName
						})
					);
					$scope.activeFlowOffset += activeFlowLimit;
					$scope.activeFlowsLoading = false;
					$scope.totalActiveFlows = res.metadata.total;
					$safeApply($scope);
				})
				.catch(err => {
					console.error(err);
					$scope.activeFlowsLoading = false;
					$safeApply($scope);
				});
		};

		var init = function () {
			self = AppService.getSelf();
			$scope.getEvents();
			$scope.getActivities(true);
			if (!Tools.FeatureHelper.hasSoftDeployAccess('SKIP_MAIL_CAMPAIGN_INFO')) {
				$scope.getActiveFlows();
			}

			getMetaData();

			$scope.clientEvent = EventService.create.Client(AccountCtrl.account);

			ScriptService.account.openDashboard(AccountCtrl.account);
		};

		AppService.loadedPromise.then(init).catch(err => console.error(err));
	}
]);

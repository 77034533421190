import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Row, Column, Text } from '@upsales/components';
import CriteriaTableFooterNew from './CriteriaTableFooterNew';
import T from 'Components/Helpers/translate';

import './CriteriaTableFooter.scss';
import { DefaultButton } from '@upsales/components/Buttons';

const selectConfig = {
	'segment.contactCriterias': { icon: 'fa fa-user', className: 'primary-criterias', sortId: 1 },
	'segment.clientCriterias': { icon: 'fa fa-home', className: 'primary-criterias', sortId: 1 },
	'default.categories': { icon: '', className: '', sortId: 2 },
	'advancedSearch.accountHistory': { icon: '', className: '', sortId: 3 },
	'default.market': { icon: '', className: '', sortId: 5 },
	'filters.columns.contactinformation': { icon: '', className: '', sortId: 4 },
	'default.otherInfo': { icon: '', className: '', sortId: 6 },
	'default.other': { icon: '', className: '', sortId: 6 }
};

const getSelectData = (filterConfigs, searchTerm, activeFilters, defaultParent, entity) => {
	const { FeatureHelper, AppService } = Tools;
	const hasRemovedActivities = FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES');
	const oldActivitiesCount = AppService.getTotals('oldActivities');

	const contactSelectData = _.reduce(
		filterConfigs,
		(res, filter, name) => {
			const title = T(filter.title);
			const show = title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
			const shouldSeeActivityFilter = !hasRemovedActivities || oldActivitiesCount > 0;

			if (
				filter.showOnSegment &&
				filter.entity === entity &&
				(!activeFilters.hasOwnProperty(name) || filter.allowMultipleSelections) &&
				show &&
				name !== 'ClientActivityFilters' && // Never let them select the old activities and appointment filter
				// Never let them select the activities on contact if they have REMOVED_ACTIVITIES and no old activities
				(name !== 'ContactOnlyActivityFilters' || shouldSeeActivityFilter)
			) {
				// Remove invisible fields
				if (name.indexOf('Custom_') !== -1 && filter.$field && !filter.$field.visible) {
					return res;
				}
				const parentKey = filter.parent || defaultParent;
				let key;

				if (entity === 'contact' && name.indexOf('Custom_') > -1) {
					key = T('default.otherContactinfo');
				} else if (entity === 'account' && name.indexOf('Custom_') > -1) {
					key = T('default.otherAccountinfo');
				} else {
					key = T(parentKey);
				}

				const icon = (selectConfig[parentKey] && selectConfig[parentKey].icon) || null;
				const className = (selectConfig[parentKey] && selectConfig[parentKey].className) || '';
				const sortId = (selectConfig[parentKey] && selectConfig[parentKey].sortId) || 7;
				const parent =
					res[key] ||
					(res[key] = { name: key, children: [], icon: icon, className: className, sortId: sortId });
				parent.children.push({ id: name, name: title });
			}

			return res;
		},
		{}
	);

	return _.sortBy(
		_.map(contactSelectData, filter => filter),
		'sortId'
	);
};

class CriteriaTableFooter extends React.Component {
	constructor(props) {
		super(props);
		// Do nothing below this if we should use new component
		this.useNewComponent = Tools.FeatureHelper.hasSoftDeployAccess('TICKET_RULES');

		this.lang = {
			client: T('default.clients'),
			contact: T('default.contacts'),
			addTypeFor: T('segment.addCriteriasFor'),
			selectPlaceholder: T('segment.selectCriteriaPlaceholder')
		};

		this.isExclude = props.filters.isExclude;

		this.openSelectContact = this.toggleSelect.bind(this, 'contact');
		this.openSelectClient = this.toggleSelect.bind(this, 'client');
		this.closeSelect = this.closeSelect.bind(this, null);
		this.openSelect = this.openSelect.bind(this, null);
		this.onCriteriaSelect = this.onCriteriaSelect.bind(this, this.isExclude);

		this.state = { currentlyOpenSelect: null };

		const sharedSelectProps = {
			defaultValue: null,
			formatResult: this.formatResult,
			placeholder: this.lang.selectPlaceholder,
			onChange: this.onCriteriaSelect,
			matcher: function (term, undef, item) {
				return (item.name || '').toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			options: {
				formatSearching: '',
				closeOnSelect: false,
				dropdownCssClass: 'select2-criteria-select',
				formatResultCssClass: this.formatResultCssClass,
				allowClear: false
			},
			eventListeners: { 'select2-close': this.closeSelect, 'select2-open': this.openSelect }
		};

		this.clientSelectProps = _.cloneDeep(sharedSelectProps);
		this.clientSelectProps.options.ajax = {
			data: term => {
				return term;
			},
			transport: query => {
				const { filterConfigs, filters } = this.props;
				const data = getSelectData(
					filterConfigs,
					query.data,
					filters.config,
					'segment.clientCriterias',
					'account'
				);
				return query.success(data);
			},
			results: data => {
				return { results: data };
			}
		};

		this.contactSelectProps = _.cloneDeep(sharedSelectProps);
		this.contactSelectProps.options.ajax = {
			data: term => {
				return term;
			},
			transport: query => {
				const { filterConfigs, filters } = this.props;
				const data = getSelectData(
					filterConfigs,
					query.data,
					filters.config,
					'segment.contactCriterias',
					'contact'
				);
				return query.success(data);
			},
			results: data => {
				return { results: data };
			}
		};
	}

	toggleSelect(value) {
		this.setState({ currentlyOpenSelect: value }, () => {
			$(this._elem).find(`.CriteriaTableFooter__${value}-select`).select2('open');
		});
	}

	formatResult(object) {
		var icon = object.icon ? <i className={object.icon} /> : null;
		var className = 'criteria-select-result ' + object.className;

		return window.renderToStaticMarkup(
			<span className={className}>
				{icon}
				{object.name}
			</span>
		);
	}

	formatResultCssClass(object) {
		return object.className || '';
	}

	onCriteriaSelect(isExclude, event) {
		var criteria = event.target.value;
		this.props.addFilter(isExclude, criteria);
	}

	closeSelect() {
		$('body').css('overflow', '');
		const currentlyOpenSelect = this.state.currentlyOpenSelect;

		setTimeout(() => {
			$(`input.${currentlyOpenSelect}.criteria-select.${this.isExclude}`).select2('data', null);
			this.setState({ currentlyOpenSelect: null });
		}, 0);
	}

	componentWillUnmount() {
		$('body').css('overflow', '');
	}

	openSelect() {
		$('body').css('overflow', 'hidden');

		const element = $('#select2-drop.select2-criteria-select')[0];
		const innerElement = $('#select2-drop.select2-criteria-select .select2-results');

		if (element && innerElement) {
			const bottom = element.offsetTop + 630;
			const windowHeight = window.innerHeight;
			const diff = bottom - windowHeight;

			if (bottom > windowHeight) {
				if (diff < 175) {
					innerElement.css('max-height', Math.max(600 - diff, 300));
				} else {
					innerElement.css('max-height', '');
				}
			} else {
				innerElement.css('max-height', 600);
			}
		}
	}

	render() {
		if (this.useNewComponent) {
			return <CriteriaTableFooterNew {...this.props} />;
		}
		const { entities } = this.props;
		const classes = new BemClass('CriteriaTableFooter');

		let contacBtn = null,
			contactSelect = null;
		if (entities.contact) {
			contacBtn = (
				<DefaultButton size="sm" onClick={this.openSelectContact}>
					{this.lang.contact}
				</DefaultButton>
			);

			contactSelect = (
				<span
					key="contact-select"
					className={classes
						.elem('select')
						.mod({ visible: this.state.currentlyOpenSelect === 'contact' })
						.b()}
				>
					<ReactTemplates.INPUTS.upSelect
						{...this.contactSelectProps}
						className={classes.elem('contact-select').b()}
					/>
				</span>
			);
		}
		let clientBtn = null,
			clientSelect = null;
		if (entities.client) {
			clientBtn = (
				<DefaultButton size="sm" onClick={this.openSelectClient}>
					{this.lang.client}
				</DefaultButton>
			);

			clientSelect = (
				<span
					key="client-select"
					className={classes
						.elem('select')
						.mod({ visible: this.state.currentlyOpenSelect === 'client' })
						.b()}
				>
					<ReactTemplates.INPUTS.upSelect
						{...this.clientSelectProps}
						className={classes.elem('client-select').b()}
					/>
				</span>
			);
		}

		return (
			<Row className={classes.b()} ref={r => (this._elem = r)}>
				<Column>
					<Text>
						<Icon name="plus-circle" color="grey-10" space="mrm" />
						{T('segment.addCriteriasFor')}
					</Text>
				</Column>
				<Column className={classes.elem('select-wrap').b()}>
					{contacBtn}
					{clientBtn}
					{contactSelect}
					{clientSelect}
				</Column>
			</Row>
		);
	}
}

CriteriaTableFooter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	filterConfigs: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	entities: PropTypes.object
};

CriteriaTableFooter.defaultProps = {
	entities: {
		contact: true,
		client: true
	}
};

export default CriteriaTableFooter;

import React, { useLayoutEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	ButtonGroup,
	Button,
	Icon,
	DropDownMenu,
	Title,
	Checkbox,
	Progress,
	Textarea,
	IconName
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import FakeTooltip from 'Components/Tooltip/FakeTooltip';
import './DashboardButtonGroup.scss';
import { SlideFade } from 'App/components/animations';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { openDrawer } from 'Services/Drawer';
import Comment from 'App/babel/resources/Comment';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import logError from 'App/babel/helpers/logError';
import Avatar from 'Components/Avatar';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import MentionsInput from 'Components/Mentions/MentionsInput';
import Editor from '@draft-js-plugins/editor';
import openModal from 'App/services/Modal';
import { useForceRender, useSoftDeployAccess } from 'App/components/hooks';
import SubAccountStateFrame from '../SubAccountStateFrame';

const MIN_ACTIONS_TO_RENDER = 5;
const CommentConfirm = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	return (
		<div {...props}>
			<Checkbox checked />
			<Title>{T('comment.addedBelow')}</Title>
		</div>
	);
};
type Props = {
	contactsTotal?: number;
	isContact: boolean;
	account: Pick<
		Client,
		'id' | 'createRights' | 'userEditable' | 'name' | 'score' | 'priceListId' | 'operationalAccount'
	>;
	contact?: Contact & { userEditable: true };
	score?: number;
};

type Action = {
	title: string;
	icon: string;
	buttonColor?: string;
	shouldRender: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const isDraftEditor = (ref: HTMLTextAreaElement | Editor): ref is Editor => {
	return (ref as Editor).editor !== undefined;
};
const DashboardButtonGroup = ({ account, contactsTotal, contact, isContact = false, score }: Props) => {
	let textareaRef = useRef<HTMLTextAreaElement | Editor | null>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [comment, setComment] = useState('');
	const [saving, setSaving] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const UdoLink = getAngularModule('UdoLink');
	const useSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const isSubAccount = !!account.operationalAccount;
	const hasSubAccounts = useSubAccounts && isSubAccount;

	const { forced, triggerRender } = useForceRender();

	useLayoutEffect(() => {
		if (textareaRef && textareaRef.current && wrapperRef && wrapperRef.current) {
			const element = isDraftEditor(textareaRef.current)
				? textareaRef.current.editor?.editorContainer
				: textareaRef.current;
			const wrapperSpace = hasSubAccounts && comment ? 190 : hasSubAccounts ? 145 : comment ? 110 : 65;
			if (element) {
				element.style.height = 'inherit';
				if (element.scrollHeight < 60) {
					element.style.maxHeight = element.style.height = '40px';
				} else {
					element.style.maxHeight = element.style.height = `${element.scrollHeight}px`;
				}

				wrapperRef.current.style.height = 'inherit';
				wrapperRef.current.style.maxHeight = wrapperRef.current.style.height = `${
					parseInt(element.style.height) + wrapperSpace
				}px`;
			}
		}
	}, [comment, forced]);

	const saveComment = async () => {
		setSaving(true);
		const { id, name } = Tools.AppService.getSelf();
		await Comment.save({
			user: { id, name },
			description: comment.replace(/\n\n/g, '\n'),
			client: { id: account.id, name: account.name },
			contact: contact ? { id: contact.id, name: contact.name, email: contact.email } : null
		});
		setSaving(false);
		setShowConfirm(true);
		setTimeout(() => {
			setShowConfirm(false);
			setComment('');
			triggerRender();
		}, 2000);
	};
	const meta = Tools.AppService.getMetadata();

	function openSendEmail(mailContact: Contact & { userEditable: boolean }) {
		if (meta.map.mail) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openNewMailWithContact(mailContact);
			} else {
				const options = {
					customerId: Tools.AppService.getCustomerId(),
					contactId: mailContact.id,
					contact: mailContact ?? null
				};
				Tools.$upModal.open('sendEmail', options);
			}
		} else {
			window.open('mailto:' + mailContact.email, '_top');
		}
	}

	function addMailToContact(sendEmail: (email: string) => void) {
		// eslint-disable-next-line promise/catch-or-return
		if (!contact) {
			return;
		}
		return Tools.$upModal
			.open('infoPrompt', {
				title: 'default.enterEmail',
				placeholder: 'default.email',
				required: true,
				type: 'email'
			})
			.then(function (email) {
				if (email) {
					Tools.Contact.customer(Tools.AppService.getCustomerId())
						.save({ id: contact.id, email: email })
						.then(function () {
							sendEmail(email);
						})
						.catch(e => {
							logError(e, 'Failed saving contact');
						});
				}
			});
	}

	const actions: Action[] = [
		{
			title: T('default.comment'),
			icon: 'comment',
			buttonColor: 'white',
			shouldRender: true
		},
		{
			title: T('default.activity'),
			icon: 'activity',
			shouldRender:
				account.createRights.Activity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
				!Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
				!Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES'),
			onClick: () => {
				const activity = {
					client: { id: account.id },
					contacts: contact ? { id: contact.id } : undefined
				};
				Tools.$upModal.open('editActivity', { activity });
			}
		},
		{
			title: T('todo.todo'),
			icon: 'activity',
			shouldRender:
				account.createRights.Activity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST'),
			onClick: () => openDrawer('CreateTodo', { client: account, contact })
		},
		{
			title: T('todo.planACall'),
			icon: 'phone',
			shouldRender:
				account.createRights.Activity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST'),
			onClick: () => openDrawer('CreateCall', { client: account, contact })
		},
		{
			title: T('default.appointment'),
			icon: 'calendar',
			shouldRender:
				account.createRights.Appointment &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
			onClick: () =>
				Tools.$upModal.open('editAppointment', {
					appointment: { client: { id: account.id }, contacts: contact ? [contact] : [] }
				})
		},
		{
			title: T('default.email'),
			icon: 'envelope',
			shouldRender: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL) && isContact,
			onClick: () => {
				if (!contact) {
					return;
				}
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					if (contact.email) {
						openNewMailWithContact(contact);
					} else {
						addMailToContact(email => {
							contact.email = email;
							openNewMailWithContact(contact);
						});
					}
				} else {
					if (contact.email) {
						openSendEmail(contact);
					} else {
						addMailToContact(email => {
							contact.email = email;
							openSendEmail(contact);
						});
					}
				}
			}
		},
		{
			title: T('default.contact'),
			icon: 'user',
			shouldRender:
				!isContact &&
				account.createRights.Activity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANIES_AND_CONTACTS) &&
				!isContact,
			onClick: () =>
				Tools.$upModal.open('editContact', { customerId: Tools.AppService.getCustomerId(), account: account })
		},
		{
			title: T('default.opportunity'),
			icon: 'opportunity',
			shouldRender:
				account.createRights.Opportunity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PIPELINE),
			onClick: () =>
				Tools.$upModal.open(
					'editOrder',
					contact
						? {
								customerId: Tools.AppService.getCustomerId(),
								clientId: contact.client.id,
								contactId: contact.id,
								type: 'opportunity'
						  }
						: {
								customerId: Tools.AppService.getCustomerId(),
								clientId: account.id,
								type: 'opportunity'
						  }
				)
		},
		{
			title: T('default.order'),
			icon: 'usd',
			shouldRender:
				account.createRights.Order && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ORDERS),
			onClick: () =>
				Tools.$upModal.open('editOrder', {
					customerId: Tools.AppService.getCustomerId(),
					clientId: contact?.client.id || account.id,
					contactId: contact?.id
				})
		},
		{
			title: T('default.recurringOrder'),
			icon: 'dollar',
			shouldRender:
				account.createRights.Agreement &&
				meta.params.AgreementEnabled &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER) &&
				Tools.AppService.getAccessRights().Agreement,
			onClick: () => {
				if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
					return Tools.$upModal.open('editAgreement', {
						customerId: Tools.AppService.getCustomerId(),
						accountId: contact?.client.id || account.id,
						contactId: contact?.id
					});
				}

				openModal('CreateSubscription', {
					client: contact?.client || account,
					contact: contact,
					createdFrom: isContact ? 'contactButtonGroup' : 'accountButtonGroup'
				});
			}
		},
		...(
			meta.params.UserDefinedObject?.filter(
				udo => udo.link === (isContact ? UdoLink.CONTACT : UdoLink.ACCOUNT)
			) || []
		).map(u => ({
			title: u.name,
			icon: 'puzzle-piece',
			shouldRender:
				account.createRights[('UserDefObj' + u.id) as keyof typeof account.createRights] &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.UDO),
			onClick: () => {
				Tools.$upModal.open('editUserDefinedObject', {
					typeId: u.id,
					object: {
						contact,
						client: { id: account.id, name: account.name }
					}
				});
			}
		})),
		{
			title: T('default.document'),
			icon: 'file',
			shouldRender:
				account.userEditable &&
				!!Tools.AppService.getDocumentTemplates('client').length &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS),
			onClick: () =>
				Tools.$upModal.open('createDocument', {
					type: 'account',
					id: account.id,
					templates: Tools.AppService.getDocumentTemplates('client'),
					accountId: account.id
				})
		},
		{
			title: T('account.addRelation'),
			icon: 'sitemap',
			shouldRender:
				!isContact &&
				account.userEditable &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_RELATIONS),
			onClick: () =>
				Tools.$upModal.open('editClientRelation', {
					account: account,
					customerId: Tools.AppService.getCustomerId()
				})
		},
		{
			title: T('account.mergeClients'),
			icon: 'exchange',
			shouldRender: !isContact && account.userEditable,
			onClick: () =>
				Tools.$upModal.open('mergeClients', { id: account.id, customerId: Tools.AppService.getCustomerId() })
		},
		{
			title: T('default.marketEvent'),
			icon: 'tachometer',
			shouldRender: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MARKETING_EVENTS),
			onClick: () => Tools.$upModal.open('newMarketEvent', { account, contact })
		},
		{
			title: T('default.unsubFromMassMail'),
			icon: 'minus-circle',
			shouldRender:
				isContact &&
				meta.map.mail &&
				!contact?.unsubscribed &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_MAIL),
			onClick: () => {
				if (!contact) {
					return;
				}

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'default.unsubFromMassMail',
						body: 'confirm.unsubBody',
						confirmButtonText: 'default.unsubFromMassMail',
						headerIcon: 'warning',
						onClose: confirmed => {
							if (confirmed) {
								Tools.Contact.customer(Tools.AppService.getCustomerId()).unsubscribe(contact);
							}
						}
					});
					return;
				}

				return Tools.$upModal
					.open('warningConfirm', {
						title: T('default.unsubFromMassMail'),
						body: T('confirm.unsubBody'),
						resolveTrue: T('default.unsubFromMassMail'),
						icon: 'fa-warning'
					})
					.then(function () {
						Tools.Contact.customer(Tools.AppService.getCustomerId()).unsubscribe(contact);
					});
			}
		},
		{
			title: T('default.moveToAccount'),
			icon: 'truck',
			shouldRender: isContact && !!contact?.userEditable,
			onClick: () =>
				Tools.$upModal.open('moveContact', {
					contact
				})
		},
		{
			title: T('default.relation'),
			icon: 'sitemap',
			shouldRender: isContact && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_RELATIONS),

			onClick: () =>
				Tools.$upModal.open('editContactRelation', {
					customerId: Tools.AppService.getCustomerId(),
					contact
				})
		},
		{
			title: T('contact.mergeWithOtherContact'),
			icon: 'exchange',
			shouldRender: isContact && !!contact?.userEditable,
			onClick: () => {
				if (!contact) {
					return;
				}
				return Tools.$upModal.open('mergeContacts', {
					id: contact.id,
					accountId: contact.client.id,
					customerId: Tools.AppService.getCustomerId()
				});
			}
		},
		{
			title: T('default.resubForMassMail'),
			icon: 'minus-circle',
			shouldRender:
				isContact &&
				meta.map.mail &&
				(Tools.AppService.getSelf().administrator || !!Tools.AppService.getSelf().userParams?.mailAdmin) &&
				!!contact?.unsubscribed &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_MAIL),
			onClick: () => {
				if (!contact) {
					return;
				}

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'confirm.resub',
						body: 'confirm.resubBody',
						confirmButtonText: 'confirm.resub',
						headerIcon: 'warning',
						onClose: confirmed => {
							if (confirmed) {
								Tools.Contact.customer(Tools.AppService.getCustomerId()).resubscribe(contact);
							}
						}
					});
					return;
				}

				return Tools.$upModal
					.open('warningConfirm', {
						title: T('confirm.resub'),
						body: T('confirm.resubBody'),
						resolveTrue: T('confirm.resub'),
						icon: 'fa-warning'
					})
					.then(function () {
						Tools.Contact.customer(Tools.AppService.getCustomerId()).resubscribe(contact);
					});
			}
		},
		{
			title: T('default.assignToSeller'),
			icon: 'star',
			shouldRender:
				!isContact &&
				account.userEditable &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.LEADS),
			onClick: () => Tools.$upModal.open('processedBy', { client: account })
		},
		{
			title: T('default.resetScore'),
			icon: 'thumbs-down',
			shouldRender:
				!isContact &&
				account.userEditable &&
				!!score &&
				Tools.FeatureHelper.isAvailableProduct(Tools.FeatureHelper.Product.MA),
			onClick: () =>
				Tools.$upModal.open('resetScore', {
					account: account,
					userId: Tools.AppService.getSelf().id,
					customerId: Tools.AppService.getCustomerId(),
					synch: !(contactsTotal || 0 > 1000)
				})
		},
		{
			title: T('default.uploadFile'),
			icon: 'cloud-upload',
			shouldRender: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS),
			onClick: () =>
				Tools.$upModal.open('uploadFile', isContact ? { contactId: contact?.id } : { accountId: account.id })
		},
		{
			title: T('default.eSign'),
			icon: 'edit',
			shouldRender:
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ESIGN) &&
				!!Tools.AppService.getEsignIntegrations().length,
			onClick: () =>
				Tools.$upModal.open(
					'editEsign',
					isContact
						? {
								esign: { client: { id: account.id }, involved: [Tools.Esign.newInvolved({ contact })] },
								openInvolved: true
						  }
						: { esign: { client: { id: account.id } } }
				)
		},
		{
			title: T('default.activity'),
			icon: 'activity',
			shouldRender:
				account.createRights.Activity &&
				Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
				!Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES'),
			onClick: () => {
				const activity = {
					client: { id: account.id },
					contacts: contact ? { id: contact.id } : undefined
				};
				Tools.$upModal.open('editActivity', { activity });
			}
		}
	];
	const actionsToRender = actions.filter(a => a.shouldRender);
	const classes = new BemClass('DashboardButtonGroup');
	const maxLengthOfComment = 65535;
	const placeholder = T('default.startTyping') + '...';
	return (
		<div className={classes.mod({ gotContent: comment }).b()} ref={wrapperRef}>
			<SubAccountStateFrame space="mbl" operationalAccount={account.operationalAccount} />
			<ButtonGroup>
				{actionsToRender.slice(0, MIN_ACTIONS_TO_RENDER).map((a, i) => (
					<Button key={i} onClick={a.onClick} color={a.buttonColor || 'light-grey'}>
						{a.icon ? <Icon name={a.icon as IconName} space="mrm" /> : null}
						{a.title}
					</Button>
				))}
				{actionsToRender.length > 5 ? (
					<DropDownMenu
						align="right"
						renderTrigger={(expanded, setExpanded) => (
							<Button color={'light-grey'} onClick={setExpanded}>
								{T('account.more') + '...'}
							</Button>
						)}
					>
						<div className={classes.elem('dropdown-actions').b()}>
							{actionsToRender.slice(MIN_ACTIONS_TO_RENDER, actionsToRender.length).map((a, i) => (
								<Button key={i} onClick={a.onClick} color="white">
									<Icon name={a.icon as IconName} space="mrl" />
									{a.title}
								</Button>
							))}
						</div>
					</DropDownMenu>
				) : null}
			</ButtonGroup>
			<div className={classes.elem('content').b()}>
				<SlideFade direction="top" visible={showConfirm}>
					<CommentConfirm className={classes.elem('confirm').b()} />
				</SlideFade>
				<SlideFade direction="top" visible={!showConfirm}>
					<div>
						{Tools.FeatureHelper.hasSoftDeployAccess('MENTION') ? (
							<MentionsInput
								value={comment}
								onChange={val => {
									triggerRender();
									setComment(val);
								}}
								editorRef={r => {
									textareaRef.current = r;
								}}
								editorProps={{ placeholder }}
								onEnter={() => {
									if (!showConfirm && !saving && comment) {
										saveComment();
									}
								}}
							/>
						) : (
							<Textarea
								textareaRef={r => (textareaRef = { current: r })}
								value={comment}
								disabled={saving}
								onChange={v => setComment(v.target.value)}
								onKeyDown={e => {
									//listen for enter, shift enter should be new line
									if (e.key === 'Enter' && !e.shiftKey) {
										e.preventDefault?.();
										if (!showConfirm && !saving && comment) {
											saveComment();
										}
									}
								}}
								placeholder={placeholder}
								size="sm"
								noborder
								maxLength={maxLengthOfComment}
							/>
						)}
						{comment?.length > 0 ? <FakeTooltip title={T('comment.tooltip')} /> : null}
					</div>
				</SlideFade>
			</div>
			<SlideFade speed="fast" direction="top" visible={!!comment && !showConfirm && !saving}>
				<div className={classes.elem('footer').b()}>
					<div className={classes.elem('buttons').b()}>
						<Progress percentage={(comment.length / maxLengthOfComment) * 100} hideText />
						<Button onClick={saveComment}>{T('default.addComment')}</Button>
						<Button onClick={() => setComment('')} type="link" color="grey">
							{T('default.cancel')}
						</Button>
					</div>
				</div>
			</SlideFade>
			<Avatar size={28} user={Tools.AppService.getSelf()} />
		</div>
	);
};
export default DashboardButtonGroup;

'use strict';

window.Loader = window.ReactCreateClass({
	render: function () {
		var className = 'upsales-loader';

		if (this.props.dark) {
			className += ' dark';
		} else {
			className += ' light';
		}

		if (this.props.noU) {
			className += ' small';
		}

		if (this.props.className) {
			className += ' ' + this.props.className;
		}

		var svg =
			'<svg ' +
			'viewBox="0 0 100 100" ' +
			'preserveAspectRatio="xMidYMid">' +
			'<animate attributeName="opacity" values="0;1" dur="0.3s" repeatCount="0"/>' +
			'<g>' +
			'<rect x="0" y="0" width="100" height="100" fill="none"></rect>' +
			'<circle class="bg-circle" cx="50" cy="50" r="40" opacity="0.05" stroke="#000000" fill="none" stroke-width="3" stroke-linecap="round"></circle>' +
			'<circle class="progress-circle"cx="50" cy="50" r="40" stroke="#4a90e2" fill="none" stroke-width="3" stroke-linecap="round">' +
			'<animate attributeName="stroke-dashoffset" dur="2.3s" repeatCount="indefinite" from="0" to="502"></animate>' +
			'<animate attributeName="stroke-dasharray" dur="2.3s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"></animate>' +
			'</circle>' +
			(!this.props.noU
				? '<g x="" y="0" transform="translate(-135,-135) rotate(0)">' +
				  '<path class="upsales-u" stroke="none" fill="#FFFFFF" d="M199.184025,174.178389 C198.647987,174.178389 197.972076,174.31217 197.597687,174.672286 C197.177232,175.029878 196.925127,176.419018 196.900838,176.911233 L196.176349,190.798424 C195.745843,199.040709 192.36629,202.311203 184.114653,202.311203 C181.39761,202.311203 178.652928,201.908176 176.512963,200.204355 C173.73394,197.919974 173.738128,194.383599 173.916528,190.97764 L174.646043,177.001262 C174.744875,175.119907 174.574013,174.135478 172.431535,174.178389 L172.8143,171.937759 L182.089434,171.937759 L181.797126,174.135478 C179.969571,174.135478 179.341402,175.029878 179.250108,176.777452 L178.466152,191.784536 C178.191432,197.068484 179.434371,198.906086 184.828254,198.906086 C190.313432,198.906086 191.31599,195.950273 191.568096,191.112263 L192.330275,176.507364 C192.416544,174.851503 191.862079,174.31217 190.265691,174.178389 L190.696196,171.937759 L199.568465,171.937759 L199.184025,174.178389"></path>' +
				  '</g>'
				: '') +
			'</g>' +
			'</svg>';

		return (
			<div
				id={this.props.id}
				className={className}
				style={this.props.style}
				dangerouslySetInnerHTML={{ __html: svg }}
			></div>
		);
	}
});

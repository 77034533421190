import React from 'react';

const Rating = ({ text, color, title }) => {
	if (color) {
		if (typeof title === 'object' && title.value) {
			return (
				<div title={title.value} className="rating">
					{text}
				</div>
			);
		}

		return (
			<div title={title} className={`rating ${color}`}>
				{text}
			</div>
		);
	}

	if (typeof title === 'object' && title.value) {
		return (
			<div title={title.value} className="rating">
				{text}
			</div>
		);
	}

	return (
		<div title={title} className="rating">
			{text}
		</div>
	);
};

export default Rating;

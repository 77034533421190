import mailTemplateFilters from 'App/babel/filterConfigs/MailTemplate';
const MailTemplateFilters = mailTemplateFilters();

angular.module('upAttributes').service('MailTemplateAttributes', [
	'FilterType',
	'DisplayType',
	function (FilterType, DisplayType) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: false,
					filterable: false
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true
				},
				from: {
					title: 'default.from',
					field: 'from',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'from',
					selectableColumn: true
				},
				fromName: {
					title: 'default.fromName',
					field: 'fromName',
					type: FilterType.String,
					sortable: 'fromName',
					displayType: DisplayType.String
				},
				subject: {
					title: 'mail.subject',
					field: 'subject',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'subject',
					selectableColumn: true
				},
				body: {
					title: 'mail.body',
					field: 'body',
					displayType: DisplayType.String
				},
				private: {
					field: 'private',
					displayType: DisplayType.Boolean
				},
				usedCounter: {
					title: 'mail.timesUsed',
					field: 'usedCounter',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'usedCounter',
					selectableColumn: false
				},
				lastUsedDate: {
					title: 'mail.latestUsed',
					field: 'lastUsedDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					sortable: 'latestUsedDate',
					selectableColumn: false
				},
				regDate: {
					title: 'default.created',
					field: 'regDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					sortable: 'regDate',
					selectableColumn: true
				},
				user: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'user',
					sortable: 'user.name',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				custom: {},
				attachments: {
					title: 'mail.attachments',
					field: 'attachments',
					displayType: DisplayType.String
				},
				bodyJson: {
					title: 'mail.bodyJson',
					field: 'bodyJson',
					displayType: DisplayType.String,
					selectableColumn: false,
					groupable: false,
					filterable: false
				},
				modDate: {
					title: 'listColumnTitle.lastModified',
					field: 'modDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					groupable: false,
					sortable: 'modDate',
					selectableColumn: true
				},
				socialEvent: {
					title: 'default.socialEvent',
					field: 'socialEvent',
					displayType: DisplayType.Number,
					groupable: false,
					filterable: false
				},
				links: {
					title: 'default.links',
					field: 'links',
					displayType: DisplayType.String,
					groupable: false,
					filterable: false
				},
				labels: {
					title: 'file.label',
					field: 'labels',
					displayType: DisplayType.String,
					selectableColumn: false
				}
			};

			// All available filters
			var filters = [
				{
					title: 'default.name',
					field: 'name',
					inputType: 'text',
					type: 'normal'
				},
				{
					inputType: 'select',
					multiple: true,
					resource: 'User',
					resourceType: 'activity',
					filters: function () {
						return [{ a: 'active', c: 'eq', v: 1 }];
					},
					type: 'list',
					searchField: 'name',
					displayText: 'name',
					multiComparitors: true,
					listTitle: 'default.user',
					title: 'default.users',
					field: 'user.id'
				},
				{
					title: 'mail.privateTemplate',
					field: 'private',
					type: 'normal',
					inputType: 'boolean'
				}
			];

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				filters: filters,
				standardFilters: MailTemplateFilters,
				keys: {
					ID: 'id',
					NAME: 'name'
				}
			};
		};
	}
]);

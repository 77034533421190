import React, { useMemo } from 'react';
import StaticSelect, { StaticSelectConsumerProps, useSelectLanguage } from 'App/components/Inputs/Selects/StaticSelect';
import { Flag } from '@upsales/components';
import _ from 'lodash';
import { languages } from 'App/helpers/regionHelpers';
import T, { useTranslation } from 'Components/Helpers/translate';

const languageToOption = (language: (typeof languages)[0]) => ({
	id: language.id,
	title: T(language.name),
	country: language.country,
	iso: language.iso
});

type MappedLanguage = ReturnType<typeof languageToOption>;

type Props = Pick<StaticSelectConsumerProps<MappedLanguage>, 'language' | 'anchor' | 'required' | 'onChange'> & {
	value: string | null;
	availableLanguagesByCode?: string[];
};

const defaultLanguages = languages.map(l => l.id.toUpperCase());

const useSharedProps = ({ availableLanguagesByCode = defaultLanguages, language }: Partial<Props>) => {
	const { t } = useTranslation();
	const updatedLanguage = useSelectLanguage(language);
	let options = availableLanguagesByCode.reduce<MappedLanguage[]>((res, l) => {
		const language = languages.find(x => x.id.toUpperCase() === l.toUpperCase());
		if (language) {
			res.push(languageToOption(language));
		}
		return res;
	}, []);

	options = _.sortBy(options, 'title');

	return {
		placeholder: t('default.selectLanguage'),
		options,
		language: updatedLanguage,
		renderItemLeft: (item: MappedLanguage) => <Flag code={item.country.toLowerCase()} />
	};
};

export const LanguageSelect = ({ value, ...props }: Props) => {
	const sharedProps = useSharedProps(props);
	const selected = useMemo(
		() => (value ? sharedProps.options.find(l => l.id.toLowerCase() === value.toLowerCase()) ?? null : null),
		[value, sharedProps.options]
	);

	return <StaticSelect<MappedLanguage> value={selected} showSearch={false} {...sharedProps} {...props} />;
};

export default LanguageSelect;

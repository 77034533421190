import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Drawer, DrawerHeader, Card, Table, TableRow, TableColumn, Icon, Input } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import './TagsDrawer.scss';
import copyToClipboard from 'Services/copyToClipboard';
import { getLiveTags } from 'App/babel/store/actions/SocialEventActions';

export const getEventTags = (showEventTags, showWebinarTag) => {
	if (!showEventTags) {
		return [];
	}
	const eventTags = getLiveTags();
	eventTags['Mail.CanNotAttendLink'] = {
		name: translate('socialEvent.canNotAttendLink')
	};
	if (showWebinarTag) {
		eventTags['Mail.WebinarInviteLink'] = {
			name: translate('socialEvent.webinar.link')
		};
	}
	return Object.keys(eventTags).reduce((tags, key) => {
		const tag = eventTags[key];
		if (!tag.hidden) {
			tags.push({ name: tag.name, tag: key });
		}
		return tags;
	}, []);
};

const TagsDrawer = ({ visible, onHide, entity, showEventTags, showWebinarTag }) => {
	const TagsService = getAngularModule('TagsService');
	const [lastCopied, setLastCopied] = useState(null);
	const [searchStr, setSearchStr] = useState('');
	const classes = new bemClass('TagsDrawer');
	let tags = TagsService.getTagsByEntity(entity);
	tags = _.uniq(tags, 'tag');
	tags = tags.filter(tag => {
		if (
			!searchStr.length ||
			tag.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1 ||
			tag.tag.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
		) {
			return true;
		}

		return false;
	});
	tags = tags.concat(getEventTags(showEventTags, showWebinarTag));

	return (
		<div className={classes.b()}>
			<Drawer show={visible} position="right">
				<DrawerHeader title={translate('tag.available')} onHide={() => onHide()} />
				<Card className={classes.elem('content').b()}>
					<div className={classes.elem('search').b()}>
						<Input
							noborder
							placeholder={translate('default.search')}
							color="grey-2"
							icon="search"
							clear={true}
							value={searchStr}
							onChange={e => setSearchStr(e.target.value)}
						/>
					</div>
					<Table>
						{tags.map(tag => (
							<TableRow
								success={lastCopied === tag.tag}
								key={tag.tag}
								onClick={() => {
									setLastCopied(tag.tag);
									copyToClipboard(`{{${tag.tag}}}`);
									setTimeout(() => {
										setLastCopied(null);
									}, 800);
								}}
							>
								<TableColumn title={tag.name} subtitle={`{{${tag.tag}}}`} />
								<TableColumn>
									{lastCopied === tag.tag ? (
										<span className={classes.elem('copied').b()}>
											<Icon name="check" /> {translate('default.copiedToClipboard')}
										</span>
									) : (
										<span className={classes.elem('copy').b()}>{translate('default.copy')}</span>
									)}
								</TableColumn>
							</TableRow>
						))}
					</Table>
				</Card>
			</Drawer>
		</div>
	);
};

TagsDrawer.propTypes = {
	visible: PropTypes.bool,
	onHide: PropTypes.func.isRequired
};

export default TagsDrawer;

import PropTypes from 'prop-types';
import React from 'react';
import ToggleList from './ToggleList';
import CriteriaRowWrapper from '../CriteriaRowWrapper';
import StackedDateInput from 'Components/Inputs/StackedDateInput/StackedDateInput';
import { Equals, NotEquals, EqualsAll } from 'Resources/ComparisonTypes';

export default class FormSubmitInput extends React.Component {
	constructor(props) {
		super(props);

		const { $translate } = Tools;

		this.lang = {
			is: $translate('comparison.Equals'),
			isNot: $translate('comparison.NotEquals'),
			placeholder: $translate('segment.anyForm'),
			all: Tools.$translate('default.all'),
			any: Tools.$translate('default.any')
		};

		this.onFormChanged = this.onChange.bind(this, 'form');
		this.onPresetChange = this.onChange.bind(this, 'preset');
		this.onComperatorChange = this.onChange.bind(this, 'comperator');
	}

	onChange(key, value) {
		const { filter, onChange } = this.props;
		const newFilter = _.cloneDeep(filter);

		switch (key) {
			case 'form':
				newFilter.value.Form.value = value;
				break;
			case 'preset':
				newFilter.value.Date = value;
				break;
			case 'comperator':
				if (value === NotEquals) {
					newFilter.value.Form.comparisonType = Equals;
					newFilter.value.hasFormSubmits = false;
				} else {
					newFilter.value.Form.comparisonType = value;
					newFilter.value.hasFormSubmits = true;
				}
				break;
		}

		newFilter.inactive = false;

		onChange(newFilter.value, newFilter);
	}

	render() {
		const { filter, selectData, LIST_AJAX_LIMIT, fakeConfigs } = this.props;

		const listProps = {
			placeholder: this.lang.placeholder,
			config: fakeConfigs.Form,
			defaultValue: filter.value.Form.value,
			onChange: this.onFormChanged,
			selectData: selectData,
			LIST_AJAX_LIMIT: LIST_AJAX_LIMIT
		};

		const toggleProps = {
			options: [
				{
					value: Equals,
					text: this.lang.any
				},
				{
					value: EqualsAll,
					text: this.lang.all
				},
				{
					value: NotEquals,
					text: this.lang.isNot
				}
			],
			onClick: this.onComperatorChange,
			value: filter.value.hasFormSubmits ? filter.value.Form.comparisonType || Equals : NotEquals
		};

		return (
			<CriteriaRowWrapper>
				<div className="criteria-row">
					<ToggleList className="col-sm-8" list={listProps} toggle={toggleProps} isListActive={() => true} />
					<div className="col-sm-4">
						<StackedDateInput config={{}} filter={filter.value.Date} onChange={this.onPresetChange} />
					</div>
				</div>
			</CriteriaRowWrapper>
		);
	}
}

FormSubmitInput.propTypes = {
	filter: PropTypes.shape({
		value: PropTypes.shape({
			Form: PropTypes.object.isRequired,
			Date: PropTypes.object.isRequired
		}).isRequired
	}).isRequired,
	fakeConfigs: PropTypes.object.isRequired,
	selectData: PropTypes.object.isRequired,
	LIST_AJAX_LIMIT: PropTypes.number.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};

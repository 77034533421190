'use strict';

angular.module('upDirectives').directive('upScrollClass', function () {
	return {
		restrict: 'A',
		link: function (scope, element, attr) {
			var classObj = scope.$eval(attr.upScrollClass);
			var containerIsWindow = attr.container === undefined;
			var container = containerIsWindow ? window : attr.container;
			container = angular.element(container);

			var scrollHandler = function () {
				var containerHeight = containerIsWindow ? container.height() - 165 : container.height();
				// if we have the up-scroll-height-limit setting and the targeted element is higher than the container,
				// we do not do anything
				if (attr.upScrollHeightLimit !== undefined && element[0].clientHeight > containerHeight) {
					_.forEach(classObj, function (key, theClass) {
						element.removeClass(theClass);
					});
					return;
				}

				var scroll = container.scrollTop() + container.height();

				if (attr.scrollModal !== undefined) {
					scroll = container.offset().top + container.scrollTop() - 72;
				}

				var bottomDiff = container[0].scrollHeight - scroll;

				_.forEach(classObj, function (key, theClass) {
					if (
						(key < 0 && bottomDiff >= parseInt(key * -1)) || // For bottom diff
						(!(key < 0) && container.scrollTop() >= parseInt(key)) // For top diff
					) {
						element.addClass(theClass);
					} else {
						element.removeClass(theClass);
					}
				});
			};

			container.on('scroll', scrollHandler);

			// Unsub event if it is on window
			if (containerIsWindow) {
				scope.$on('$destroy', function () {
					container.off('scroll', scrollHandler);
				});
			}
		}
	};
});

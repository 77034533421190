'use strict';

angular.module('domain.scoreboard', ['upResources', 'security.authorization']).config([
	'$routeGuardProvider',
	'$stateProvider',
	'$upModalProvider',
	function ($routeGuardProvider, $stateProvider, $upModalProvider) {
		$upModalProvider
			.modal('resetScore', {
				templateUrl: require('App/upsales/domain/scoreboard/views/resetScore.html?file'),
				style: 'confirm',
				controller: 'ResetScore',
				resolve: {}
			})
			.modal('SelectUser', {
				templateUrl: require('App/upsales/domain/scoreboard/views/listUserModal.html?file'),
				controller: 'SelectUser',
				style: 'confirm'
			});
	}
]);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Title, Text } from '@upsales/components';
import bem from '@upsales/components/Utils/bemClass';

import t from 'Components/Helpers/translate';

import './DrawerInfoTitle.scss';

type Props = {
	total: number;
	title: string;
	subtitle?: string;
	statsArray: {
		format: (num: number) => string;
		color?: string;
		num: number;
	}[];
};

export default function DrawerInfoTitle({ total, title, statsArray, subtitle }: Props) {
	const classes = new bem('DrawerInfoTitle');
	return (
		<Fragment>
			<Title bold size="xl">
				{total}
			</Title>
			<Text>{t(title)}</Text>
			{subtitle ? (
				<Text size="sm" color="grey-11">
					{t(subtitle)}
				</Text>
			) : null}

			<div className={classes.elem('stats').b()}>
				{statsArray.map(({ color, num, format }, i) => (
					<Text key={'drawer-header-' + i}>
						<Icon name="circle" space="mrl" color={color || 'grey-4'} />
						{format(num)}
					</Text>
				))}
			</div>
		</Fragment>
	);
}

DrawerInfoTitle.propTypes = {
	total: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	statsArray: PropTypes.arrayOf(
		PropTypes.shape({
			format: PropTypes.func,
			color: PropTypes.string,
			num: PropTypes.number
		})
	).isRequired
};

DrawerInfoTitle.defaultProps = {
	statsArray: []
};

'use strict';

angular.module('upResources').factory('Contract', [
	'$resource',
	'$q',
	'URL',
	'API',
	'GenericMapper',
	'ResourceHelper',
	function ($resource, $q, URL, API, GenericMapper, ResourceHelper) {
		var helper = new ResourceHelper();

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'contract',
			createSuccessBody: 'saved.settings',
			updateSuccessBody: 'updated.settings',
			createErrorBody: 'saveError.settings',
			updateErrorBody: 'saveError.settings'
		});

		var Model = {
			contract: function () {
				var instance = {};

				var Contract = $resource(
					URL + API + 'contract/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false }
					}
				);

				instance.get = function (id) {
					return Contract.get({ id: id }).$promise;
				};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return Contract.get(params).$promise;
				};

				instance.update = function (id, data) {
					var defered = $q.defer();

					Contract.update({ id: id }, data, function (response) {
						defered.resolve(response);
					});

					return defered.promise;
				};

				return instance;
			},
			accepted: function () {
				var instance = {};

				var Contract = $resource(
					URL + API + 'contractAccepted/:id',
					{},
					{
						query: { method: 'GET', isArray: false },
						create: { method: 'POST', isArray: false },
						update: { method: 'PUT', isArray: false },
						remove: { method: 'DELETE', isArray: false }
					}
				);

				instance.get = function (id) {
					return Contract.get({ id: id }).$promise;
				};

				instance.find = function (filter, options) {
					var params = angular.extend(filter, options);
					return Contract.get(params).$promise;
				};

				instance.save = function (obj, options) {
					var promise = Contract.create(obj).$promise;
					return promise
						.then(function (res) {
							helper.onSave(options, res.data, false);
							return res;
						})
						.catch(function (err) {
							helper.onSaveError(options, false);
							return err;
						});
				};

				instance.remove = function (id) {
					return Contract.remove({ id: id }).$promise;
				};

				return instance;
			}
		};
		return Model;
	}
]);

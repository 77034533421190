import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UpSelect from '../../Inputs/UpSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import { find } from 'lodash';
import ShownInStages from 'Components/Admin/ShownInStages';

import {
	Button,
	Card,
	CardContent,
	Icon,
	Input,
	CardHeader,
	DropDownMenu,
	Text,
	TableHeader,
	Table,
	TableRow,
	TableColumn,
	Checkbox,
	OutsideClick,
	Row
} from '@upsales/components';

import {
	initComponent,
	saveDefaultLanguage,
	getFieldValues,
	deleteTag,
	edit,
	saveTranslation,
	deleteTranslation,
	cancelEdit,
	TYPE
} from 'Store/reducers/TranslateFieldReducer';

import './TranslateField.scss';

const mapStateToProps = state => ({
	loading: state.TranslateField.loading,
	editObj: state.TranslateField.editObj,
	defaultLanguage: state.TranslateField.defaultLanguage,
	availableLanguages: state.TranslateField.availableLanguages,
	fieldValues: state.TranslateField.fieldValues,
	newValue: state.TranslateField.newValue,
	staticLanguages: state.TranslateField.staticLanguages
});

const mapDispatchToProps = {
	initComponent,
	saveDefaultLanguage,
	getFieldValues,
	deleteTag,
	edit,
	saveTranslation,
	deleteTranslation,
	cancelEdit
};

const formatWithFlag = function (lang) {
	const staticLanguages = this.props.staticLanguages || [];
	const language = _.find(staticLanguages, { language: lang.language });

	if (!language) {
		return '';
	}
	const icon = <i style={{ marginRight: 5 }} className={'flag-icon flag-icon-' + language.country} />;
	const text = Tools.$translate(language.name);

	return window.renderToStaticMarkup(
		<span>
			{icon}
			{text}
		</span>
	);
};

const matcher = (term, undef, item) => {
	const name = item.name ? Tools.$translate(item.name).toLowerCase() : '';
	return name.indexOf(term.toLowerCase()) !== -1;
};

const nrOfVisibleFlags = 5;

class TranslateField extends React.Component {
	constructor(props) {
		super(props);
		props.initComponent(
			props.fieldName,
			props.salesProcessId,
			props.defaultLang,
			props.editMode,
			props.salesCoachId
		);

		const t = Tools.$translate;

		this.classes = new BemClass('TranslateField');

		this.lang = {
			fieldName: t('default.' + props.fieldName),
			fieldDescription: t('admin.translateField.description.' + props.fieldName),
			noDefaultLanguage: t('admin.translateField.noDefaultLanguage'),
			noDefaultLanguagePart1: t('admin.translateField.noDefaultLanguagePart1'),
			noDefaultLanguagePart2: t('admin.translateField.noDefaultLanguagePart2'),
			addValue: t('admin.translateField.addValue'),
			addTranslation: t('admin.translateField.addTranslation'),
			chooseLanguage: t('admin.translateField.chooseLanguage'),
			newValue: t('admin.translateField.newValue'),
			defaultValue: t('admin.translateField.defaultValue'),
			defaultLanguage: t('admin.translateField.defaultLanguage'),
			translation: t('admin.translateField.translation'),
			addNewValue: t('admin.translateField.addNewValue'),
			confirmDelete: 'admin.translateField.confirmDelete',
			more: t('default.more2').toLowerCase(),
			delete: t('default.delete'),
			save: t('default.save'),
			cancel: t('cancel'),
			stagesTitle: t('default.stages'),
			allStages: t('admin.newSalesProcess.allStages'),
			shownInStages: t('admin.newSalesProcess.shownInStages'),
			active: t('default.active')
		};

		this.state = { showDropDown: {} };

		this.lang.noValuesYet =
			t('admin.translateField.noValues') + ' `' + this.lang.fieldName + '` ' + t('admin.translateField.yet');
		this.formatWithFlag = formatWithFlag.bind(this);
	}

	componentDidUpdate() {
		if (this.props.reloadModalPosition) {
			this.props.reloadModalPosition();
		}
	}

	hideDeleteButtons = row => row.default?.tag?.subType;

	/** STATELESS FUNCTIONS? **/

	renderNoDefaultLanguage() {
		const { saveDefaultLanguage, staticLanguages } = this.props;

		return (
			<div className="no-default-language-wrapper">
				<div className="header">{this.lang.noDefaultLanguage + '.'}</div>
				<div>
					{this.lang.noDefaultLanguagePart1} <i>{this.lang.fieldName}</i>
				</div>
				<div>{this.lang.noDefaultLanguagePart2 + '.'}</div>

				<div className="select-wrapper">
					<UpSelect
						className="pretty-select"
						required
						placeholder={this.lang.chooseLanguage}
						formatSelection={this.formatWithFlag}
						formatResult={(obj, container, query, escape) => this.formatWithFlag(obj, container, escape)}
						matcher={matcher}
						data={() => {
							return { results: staticLanguages };
						}}
						onChange={e => saveDefaultLanguage(e.target.value)}
					/>
				</div>
			</div>
		);
	}

	renderNoFieldValues() {
		const { edit } = this.props;

		return (
			<div className="briefcase-holder">
				<div className="briefcase" />
				<h5 className="title">{this.lang.noValuesYet}</h5>
				<p className="copy">
					<Button type="link" onClick={() => edit(TYPE.NEW)}>
						{this.lang.addNewValue}
					</Button>
				</p>
			</div>
		);
	}

	getFlag(lang) {
		const staticLanguages = this.props.staticLanguages || [];
		const language = _.find(staticLanguages, { id: lang });

		if (!language) {
			return '';
		}
		return (
			<span key={language.country}>
				<i className={'flag-icon flag-icon-' + language.country} />
			</span>
		);
	}

	renderFlags(row) {
		const { edit } = this.props;
		const flagClassName = this.classes.elem('flag');
		flagClassName.add('full-cell-width');

		let flags = row.translations.map(translation => {
			return (
				<Button
					className={flagClassName.b()}
					key={translation.tagId + translation.language}
					size="lg"
					type="link"
					onClick={() => edit(TYPE.EDIT, _.cloneDeep(translation))}
				>
					<Row>
						{this.getFlag(translation.language)}
						<Text>{translation.value}</Text>
					</Row>
				</Button>
			);
		});
		if (flags.length > nrOfVisibleFlags) {
			flags = flags.slice(0, nrOfVisibleFlags);
		}
		return flags;
	}

	renderMore(row) {
		const { edit } = this.props;
		if (row.translations.length <= nrOfVisibleFlags) {
			return '';
		}

		const moreCount = row.translations.length - nrOfVisibleFlags;

		return (
			<Button
				key={row.tagId + 'more'}
				size="lg"
				type="link"
				onClick={() => edit(TYPE.EDIT, _.cloneDeep(row.translations[0]))}
				className={this.classes.elem('showMore').b()}
			>
				{'+ ' + moreCount + ' ' + this.lang.more}
			</Button>
		);
	}

	renderDefaultValue(row) {
		const { edit } = this.props;

		const obj = {
			id: row.default.id,
			tagId: row.default.tagId,
			defaultValue: row.default.value
		};

		const className = this.classes.elem('defaultValueButton');
		className.add('full-cell-width');

		return (
			<Button size="lg" type="link" color="black" onClick={() => edit(TYPE.VALUE, obj)} className={className.b()}>
				{row.default ? row.default.value : ''}
			</Button>
		);
	}

	renderHoverActions(row) {
		const { edit, deleteTag, availableLanguages, removeFromFields } = this.props;
		const showAddTranslations = availableLanguages.length && row.translations.length < availableLanguages.length;
		const hideDeleteButtons = this.hideDeleteButtons(row);

		const obj = {
			tagId: row.tagId
		};

		return (
			<DropDownMenu
				renderTrigger={(isExpanded, setExpanded) => {
					return (
						<div className={this.classes.elem('flexContainer').b()}>
							{showAddTranslations ? (
								<Button
									className="visibleOnHover"
									size="sm"
									type="link"
									onClick={() => edit(TYPE.ADD, obj)}
								>
									<div>
										<Icon name="plus" />
										{' ' + this.lang.addTranslation}
									</div>
								</Button>
							) : null}
							{!hideDeleteButtons && (
								<Button
									color="grey"
									shadow="none"
									type="link"
									className={
										isExpanded
											? 'is_expanded field__row--delete-btn'
											: 'visibleOnHover field__row--delete-btn'
									}
									onClick={setExpanded}
								>
									<Icon name="trash" />
								</Button>
							)}
						</div>
					);
				}}
			>
				<Card>
					<CardContent>
						{!!row.usedCount && (
							<Text center={true}>
								{Tools.$translate(this.lang.confirmDelete, { count: row.usedCount || 0 })}
							</Text>
						)}
						<div className="field__row--delete-confirm">
							<Button
								className="confirmDelete"
								color="red"
								shadow="none"
								onClick={() => {
									deleteTag(obj.tagId);
									if (removeFromFields) {
										removeFromFields(obj.tagId);
									}
								}}
							>
								{this.lang.delete + ' "' + row.default.value + '"'}
							</Button>
						</div>
					</CardContent>
				</Card>
			</DropDownMenu>
		);
	}

	/** STATEFULL FUNCTIONS **/

	renderDefaultValueEdit() {
		const { editObj, edit, saveTranslation } = this.props;
		const obj = editObj.obj;

		const changeValue = value => {
			obj.defaultValue = value;
			edit(editObj.type, obj);
		};
		const handleKeyPress = event => {
			if (event.key === 'Enter') {
				saveTranslation();
			}
		};

		return (
			<Input
				autoFocus
				inline={true}
				tabIndex={'1'}
				defaultValue={obj.defaultValue}
				placeholder={this.lang.addValue}
				onKeyPress={e => handleKeyPress(e)}
				onFocus={e => e.target.select()}
				onChange={e => changeValue(e.target.value)}
				className={this.classes.elem('defaultValueEdit').b()}
			/>
		);
	}

	flipStage = (stageId, rowId) => {
		const { fields, setFields } = this.props;

		const idx = fields.findIndex(field => field.rowId === rowId);
		if (idx === -1) {
			fields.push({ active: true, activeStages: [{ stageId }], rowId });
			setFields(fields);
			return;
		}

		const indexOfStage = fields[idx].activeStages.map(stage => stage.stageId).indexOf(stageId);
		if (indexOfStage === -1) {
			fields[idx].activeStages.push({ stageId });
			fields[idx].active = true;
		} else {
			fields[idx].activeStages.splice(indexOfStage, 1);
		}
		setFields(fields);
	};

	renderShownInStages = id => {
		const { showDropDown } = this.state;
		if (!showDropDown[id]) {
			return null;
		}
		const { fields } = this.props;
		const stages = Tools.AppService.getStages().filter(stage => stage.probability !== 100);
		return (
			<OutsideClick
				targetClass="TranslateField__shownInStage"
				outsideClick={() => this.setState({ showDropDown: { ...this.state.showDropDown, [id]: false } })}
			>
				<Card className="TranslateField__shownInStage">
					<Text bold>{this.lang.stagesTitle}</Text>
					{stages.map(stage => (
						<div className="TranslateField__shownInStage__wrapper" key={stage.id}>
							<div
								className="TranslateField__shownInStage__row"
								key={stage.id}
								onClick={() => this.flipStage(stage.id, id)}
							>
								<Checkbox
									checked={
										!!fields
											.find(field => field.rowId === id)
											?.activeStages.find(activeStage => activeStage.stageId === stage.id)
									}
								/>
								<Text>{stage.name}</Text>
							</div>
						</div>
					))}
				</Card>
			</OutsideClick>
		);
	};

	renderLanguageSelect(row, autoOpen, isAdd) {
		const { editObj, edit, availableLanguages } = this.props;
		const obj = editObj.obj;
		let untranslatedLanguages = availableLanguages;
		if (isAdd) {
			untranslatedLanguages = _.reject(availableLanguages, function (lang) {
				return _.find(row.translations, { language: lang.language });
			});
		}

		const changeLanguage = language => {
			obj.language = language;

			const found = row.translations && _.find(row.translations, { language: language });
			if (found) {
				obj.id = found.id;
				obj.value = found.value;
			} else {
				obj.id = undefined;
				obj.value = '';
			}

			edit(editObj.type, obj);
		};

		return (
			<UpSelect
				className="pretty-select"
				autoOpen={autoOpen}
				required={!!row.tagId}
				placeholder={this.lang.chooseLanguage}
				formatSelection={this.formatWithFlag}
				formatResult={(obj, container, query, escape) => this.formatWithFlag(obj, container, escape)}
				matcher={matcher}
				data={() => {
					return { results: untranslatedLanguages };
				}}
				defaultValue={obj.language}
				onChange={e => changeLanguage(e.target.value)}
				getId={language => language.language}
			/>
		);
	}

	renderTranslation(row) {
		const { saveTranslation, deleteTranslation, editObj, edit } = this.props;
		const obj = editObj.obj;

		const changeValue = value => {
			obj.value = value;
			edit(editObj.type, obj);
		};
		const handleKeyPress = event => {
			if (event.key === 'Enter') {
				saveTranslation();
			}
		};

		const hideDeleteButton = this.hideDeleteButtons(row);

		return (
			<div className={this.classes.elem('flexContainer').b()}>
				<Input
					autoFocus
					tabIndex={'3'}
					inline={true}
					className={this.classes
						.elem('editTranslationInput')
						.mod({
							hideMe: !obj.tagId && !obj.language
						})
						.b()}
					onKeyPress={e => handleKeyPress(e)}
					placeholder={this.lang.translation}
					onFocus={e => e.target.select()}
					value={obj.value}
					onChange={e => changeValue(e.target.value)}
				/>
				{!hideDeleteButton && (
					<Button
						className={this.classes.elem('deleteTranslationButton').mod({ hideMe: !obj.id })}
						color="grey"
						size="sm"
						type="link"
						onClick={() => deleteTranslation(obj.id)}
					>
						<Icon name="trash" />
					</Button>
				)}
			</div>
		);
	}

	renderSaveCancel() {
		const { editObj, cancelEdit, saveTranslation } = this.props;
		const disabled = !editObj.obj.defaultValue && (!editObj.obj.language || !editObj.obj.value);

		return (
			<div>
				<Button
					size="sm"
					disabled={disabled}
					onClick={() => saveTranslation()}
					className={this.classes.elem('saveTranslationButton').b()}
				>
					{this.lang.save}
				</Button>
				<Button
					size="sm"
					type="link"
					onClick={() => cancelEdit()}
					className={this.classes.elem('cancelEditButton').b()}
				>
					{this.lang.cancel}
				</Button>
			</div>
		);
	}

	getSelectedStageNames(id) {
		const { fields } = this.props;
		let stages = fields.find(field => field.rowId === id)?.activeStages;
		if (!stages || stages.length === 0) {
			return <Text color="grey-8">{this.lang.allStages}</Text>;
		}
		const allStages = Tools.AppService.getStages();
		stages = stages.map(stage => find(allStages, { id: stage.stageId }).name);

		return <ShownInStages stages={stages} maxCharCount={9} />;
	}

	renderRow(row) {
		const { editObj, availableLanguages, shownInStages, activate, fields } = this.props;
		let showInStageColumn = <TableColumn />;
		let checkboxColumn = <TableColumn />;

		let firstColumn = '',
			secondColumn = '',
			thirdColumn = '',
			fourthColumn = '';
		const editingRow = editObj?.obj?.tagId === row.tagId;

		const isEditDefaultValue = editingRow && editObj.type === TYPE.VALUE;
		const isEditTranslation = editingRow && editObj.type === TYPE.EDIT;
		const isAddTranslation = editingRow && editObj.type === TYPE.ADD;
		const isAddingNewValue = !row.tagId && editObj.type === TYPE.NEW;

		if (isEditDefaultValue) {
			firstColumn = this.renderDefaultValueEdit();
			secondColumn = this.renderFlags(row);
			fourthColumn = this.renderSaveCancel();
		} else if (isEditTranslation || isAddTranslation) {
			firstColumn = this.renderDefaultValue(row);
			secondColumn = this.renderLanguageSelect(row, isAddTranslation, isAddTranslation);
			thirdColumn = this.renderTranslation(row);
			fourthColumn = this.renderSaveCancel();
		} else if (isAddingNewValue) {
			firstColumn = this.renderDefaultValueEdit();
			secondColumn = availableLanguages.length > 0 && this.renderLanguageSelect(row);
			thirdColumn = availableLanguages.length > 0 && this.renderTranslation(row);
			fourthColumn = this.renderSaveCancel();
		} else {
			firstColumn = this.renderDefaultValue(row);
			secondColumn = this.renderFlags(row);
			thirdColumn = this.renderMore(row);
			fourthColumn = this.renderHoverActions(row);
			if (shownInStages) {
				showInStageColumn = (
					<TableColumn
						key="shownInStages"
						className={'TranslateField__activeStages'}
						onClick={() =>
							this.setState({ showDropDown: { ...this.state.showDropDown, [row.tagId]: true } })
						}
					>
						{this.getSelectedStageNames(row.tagId)}
						{this.renderShownInStages(row.tagId)}
					</TableColumn>
				);
				checkboxColumn = (
					<TableColumn key="activeInStages" className={'TranslateField__active'}>
						<Checkbox
							checked={!!fields.find(fields => fields.rowId === row.tagId)?.active}
							onClick={() => activate(row.tagId)}
						/>
					</TableColumn>
				);
			}
		}

		return (
			<TableRow key={row.tagId || -1}>
				{shownInStages ? checkboxColumn : null}
				<TableColumn key="defaultValue" className="defaultValue">
					{firstColumn}
				</TableColumn>
				<TableColumn key="language" className="language">
					{secondColumn}
				</TableColumn>
				<TableColumn key="translation" className="translation">
					{thirdColumn}
				</TableColumn>
				{shownInStages ? showInStageColumn : null}
				<TableColumn key="actions" align="right" className="actions">
					{fourthColumn}
				</TableColumn>
			</TableRow>
		);
	}

	renderTable() {
		const { fieldValues, defaultLanguage, availableLanguages, shownInStages } = this.props;

		if (!fieldValues || !fieldValues.length) {
			return this.renderNoFieldValues();
		}

		const tableRows = fieldValues.map(row => this.renderRow(row));

		return (
			<Table>
				<TableHeader>
					{shownInStages ? (
						<th key="activeInStages">{this.lang.active}</th>
					) : (
						<></> /* if I put null here TableHeader crasches */
					)}
					<th key="defaultValue">
						{this.getFlag(defaultLanguage)}
						{this.lang.defaultValue}
					</th>
					<th key="translation">{availableLanguages.length ? this.lang.translation : ''}</th>
					<th key="value" />
					{shownInStages ? (
						<th key="shownInStages">{this.lang.shownInStages}</th>
					) : (
						<></> /* if I put null here TableHeader crasches */
					)}
					<th key="actions" />
				</TableHeader>
				{tableRows}
			</Table>
		);
	}

	render() {
		const { defaultLanguage, edit, editObj, loading, disabled } = this.props;

		const isAddingNewValue = editObj?.type === TYPE.NEW;
		var content = '';
		if (!defaultLanguage && !loading) {
			content = this.renderNoDefaultLanguage();
		} else if (!loading) {
			content = this.renderTable();
		}

		return (
			<Card className={this.classes.b()}>
				<div className={disabled ? 'disabled' : ''} />
				<CardHeader size="md" title={this.lang.fieldName} subtitle={this.lang.fieldDescription}>
					<Button
						onClick={() => edit(TYPE.NEW)}
						disabled={!defaultLanguage || isAddingNewValue}
						className={this.classes.elem('addNewValueButton').b()}
					>
						{this.lang.newValue}
					</Button>
				</CardHeader>
				{content}
			</Card>
		);
	}
}

TranslateField.propTypes = {
	salesProcessId: PropTypes.number,
	loading: PropTypes.bool,
	fieldValues: PropTypes.array,
	availableLanguages: PropTypes.array,
	staticLanguages: PropTypes.array,
	fieldName: PropTypes.string.isRequired,
	defaultLanguage: PropTypes.string,
	editObj: PropTypes.object,
	initComponent: PropTypes.func.isRequired,
	saveDefaultLanguage: PropTypes.func.isRequired,
	getFieldValues: PropTypes.func.isRequired,
	deleteTag: PropTypes.func.isRequired,
	saveTranslation: PropTypes.func.isRequired,
	deleteTranslation: PropTypes.func.isRequired,
	cancelEdit: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	edit: PropTypes.func.isRequired,
	reloadModalPosition: PropTypes.func,
	defaultLang: PropTypes.string,
	editMode: PropTypes.bool,
	shownInStages: PropTypes.bool,
	setFields: PropTypes.func,
	salesCoachId: PropTypes.number,
	fields: PropTypes.array,
	activate: PropTypes.func,
	removeFromFields: PropTypes.func
};

TranslateField.defaultProps = {
	newValue: null,
	loading: true,
	fieldValues: [],
	availableLanguages: [],
	defaultLang: '',
	editMode: false
};

export const detached = TranslateField;
export default connect(mapStateToProps, mapDispatchToProps)(TranslateField);

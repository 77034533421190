import { OutsideClick } from '@upsales/components';

const TopList = window.ReactCreateClass({
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	getInitialState: function () {
		return {
			settingsOpen: false
		};
	},
	getDefaultProps: function () {
		return {
			listLenght: 3,
			idField: 'key',
			valueField: 'doc_count',
			nameField: 'name'
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			title: t(this.props.title),
			settingsTitle: t(this.props.settingsTitle),
			roles: t('default.roles'),
			placeholder: t(this.props.placeholder)
		};
	},
	handleClickOutside: function () {
		this.setState({
			settingsOpen: false
		});
	},
	getUserRow: function (user) {
		var myId = this.props.tools.self.id;

		var classes = 'top-list-row top-list-value-row';

		if (user[this.props.idField] === myId) {
			classes += ' user-row';
		}

		return (
			<div key={user[this.props.idField]} className={classes}>
				<ReactTemplates.TOOLS.avatar user={user} avatarService={this.props.tools.avatarService} />
				<span className="top-list-value-row-text">{user[this.props.nameField]}</span>
				<span className="top-list-value-row-value">{user[this.props.valueField]}</span>
			</div>
		);
	},
	getEmptyRow: function (index) {
		var key = 'empty-' + index;

		return <div className="top-list-row" key={key}></div>;
	},
	toggleSettingsMenu: function () {
		this.setState({
			settingsOpen: !this.state.settingsOpen
		});
	},
	getHeaderRow: function () {
		return (
			<div key={'header-row'} className="top-list-row top-list-header-row">
				<span className="top-list-header-row-text">{this.lang.title}</span>
				<span className="top-list-header-row-icon" onClick={this.toggleSettingsMenu}>
					{this.state.settingsOpen ? <i className="fa fa-times"></i> : <i className="fa fa-cog"></i>}
				</span>
			</div>
		);
	},
	onSettingsChangeType: function (values) {
		this.props.settings.onChange('Type', values);
	},
	onSettingsChangeRole: function (values) {
		this.props.settings.onChange('Role', values);
	},
	isRolePickerInitialized: false,
	isTypePickerInitialized: false,
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		var self = this;

		var formatSelection = function (obj, container, escape) {
			return escape(obj.name);
		};
		var formatResult = function (obj, container, query, escape) {
			return escape(obj.name);
		};
		var matcher = function (term, _, object) {
			return object.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
		};

		if (nextProps.settings.initialSelectedRoles && this.props.roles.length && !this.isRolePickerInitialized) {
			$(ReactDOM.findDOMNode(this.refs['_role_input']))
				.select2({
					multiple: true,
					data: nextProps.roles,
					formatSelection: formatSelection,
					formatResult: formatResult,
					matcher: matcher
				})
				.on('change', function (event) {
					if (event.val) {
						var ids = _.map(event.val, function (value) {
							return parseInt(value);
						});
						self.onSettingsChangeRole(ids);
					}
				})
				.val(nextProps.settings.initialSelectedRoles)
				.trigger('change');

			this.isRolePickerInitialized = true;
		}
		if (nextProps.settings.initialSelectedTypes && !this.isTypePickerInitialized) {
			var data = _.sortByOrder(this.props.tools.AppService.getActivityTypes(nextProps.entity, true), 'name');

			$(ReactDOM.findDOMNode(this.refs['_type_input']))
				.select2({
					multiple: true,
					data: data,
					formatSelection: formatSelection,
					formatResult: formatResult,
					matcher: matcher
				})
				.on('change', function (event) {
					if (event.val) {
						var ids = _.map(event.val, function (value) {
							return parseInt(value);
						});
						self.onSettingsChangeType(ids);
					}
				})
				.val(nextProps.settings.initialSelectedTypes)
				.trigger('change');

			this.isTypePickerInitialized = true;
		}
	},
	getSettingsMenu: function () {
		var classes = 'top-list-settings-menu';

		if (this.state.settingsOpen) {
			classes += ' visible';
		}

		return (
			<div className={classes}>
				<div>
					<label>{this.lang.settingsTitle}</label>

					<input className={'form-control'} ref={'_type_input'} />
				</div>
				<div>
					<label>{this.lang.roles}</label>

					<input className={'form-control'} ref={'_role_input'} />
				</div>
			</div>
		);
	},
	render: function () {
		var rows = [];

		rows.push(this.getHeaderRow());

		var data = this.props.settings.data;

		if (data && data.length) {
			for (var i = 0; i < this.props.listLenght; ++i) {
				if (data && i < data.length) {
					rows.push(this.getUserRow(data[i]));
				}
			}
		} else {
			rows.push(
				<div key={'placeholder-row'} className="top-list-placeholder-row">
					{this.lang.placeholder}
				</div>
			);
		}

		return (
			<div style={this.props.inlineStyle} ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick targetRef={this.getRef} outsideClick={this.handleClickOutside}>
					<div id={this.props.id} className={'top-list'}>
						{this.getSettingsMenu()}
						{rows}
					</div>
				</OutsideClick>
			</div>
		);
	}
});

export default TopList;

'use strict';

import logError from 'App/babel/helpers/logError';

angular.module('upResources').factory('Mail', [
	'$resource',
	'$q',
	'URL',
	'API',
	'MailAttributes',
	'GenericMapper',
	'ResourceHelper',
	'$translate',
	'ParseGeneric',
	function Mail($resource, $q, URL, API, MailAttributes, GenericMapper, ResourceHelper, $translate, ParseGeneric) {
		var Attributes = MailAttributes().attr;
		var Keys = MailAttributes().keys;
		var requiredFields = MailAttributes().requiredFields;

		var helper = new ResourceHelper();

		var isScheduled = function (date) {
			if (!date || moment().isAfter(date)) {
				return false;
			}
			return true;
		};

		var isDraft = function (mail) {
			return !!(mail.isDraft || mail.status === 'DRAFT');
		};

		var saveTitle = function (mail) {
			if (isDraft(mail)) {
				return 'default.saved';
			}

			var scheduled = isScheduled(mail.date);
			return scheduled ? 'mail.mailScheduled' : 'mail.sent';
		};

		var saveBody = function (mail) {
			if (isDraft(mail)) {
				return 'mail.mailWasSavedDraft';
			}

			var scheduled = isScheduled(mail.date);
			var d = moment(mail.sendDate || mail.date).format('L LT');
			return scheduled ? $translate.instant('mail.mailWillBeSent') + ' ' + d : 'mail.mailWasSent';
		};

		// Set entity-specific defaults here
		helper.setDefaults({
			eventPrefix: 'mail',
			createSuccessTitle: saveTitle,
			createSuccessBody: saveBody,
			updateSuccessTitle: saveTitle,
			updateSuccessBody: saveBody,
			deleteSucccessBody: 'deleted.mail',
			createErrorBody: 'saveError.mail',
			updateErrorBody: 'saveError.mail',
			deleteErrorBody: 'deleteError.mail',
			sendTestSuccessTitle: 'mail.sent',
			sendTestSuccessBody: 'mail.testSent',
			sendTestErrorTitle: 'default.error',
			sendTestErrorBody: 'saveError.testMail'
		});

		var baseUrl = URL + API + 'mail/';
		var beeTokenUrl = URL + API + 'function/mailEditor';

		var Resource = $resource(
			baseUrl + ':id',
			{},
			{
				get: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: false, custom: false, skipDateConvert: true })
				},
				query: {
					method: 'GET',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: true, custom: false, skipDateConvert: true })
				},
				create: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: false, custom: false, skipDateConvert: true })
				},
				createMulti: {
					method: 'POST',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: true, custom: false, skipDateConvert: true }),
					url: baseUrl + 'multi'
				},
				update: {
					method: 'PUT',
					isArray: false,
					transformResponse: ParseGeneric(null, { isArray: false, custom: false, skipDateConvert: true })
				},
				sendTest: {
					method: 'POST',
					isArray: false,
					url: baseUrl + 'test'
				}
			}
		);

		var BeeToken = $resource(
			beeTokenUrl,
			{},
			{
				create: { method: 'POST', isArray: false }
			}
		);

		var Model = {
			customer: function () {
				if (Tools.ENV !== 'PROD') {
					console.warn('Please stop using customer syntax');
				}
				return Model;
			},

			beeToken: function () {
				return BeeToken.create({}).$promise;
			},

			find: function (filter, options) {
				var params = _.extend(filter, options);
				return Resource.query(params).$promise;
			},

			get: function (id) {
				if (!id) {
					return $q.reject('Missing id');
				}
				return Resource.get({ id: id }).$promise;
			},

			sendTest: function (mail, options) {
				options = _.defaults(options || {}, helper.defaults);
				var defered = $q.defer();

				if (!mail) {
					return defered.reject('No mail');
				}

				GenericMapper.map(mail, Attributes, function (err, mappedMail) {
					if (err) {
						return defered.reject('mail mapping error: ' + err);
					}

					Resource.sendTest({ mail: mappedMail }, defered.resolve, defered.reject);
				});

				defered.promise
					.then(function (res) {
						// Notify user
						helper.successNotification(options.sendTestSuccessTitle, options.sendTestSuccessBody, res.data);

						helper.triggerEvent(options, 'testSent', res.data);
					})
					.catch(function (e) {
						logError(e, 'Could not send test mail');
						// Notify user
						helper.errorNotification(options.sendTestErrorTitle, options.sendTestErrorBody, null);
					});

				return defered.promise;
			},

			save: function (mail, options) {
				var defered = $q.defer();

				if (!mail) {
					return defered.reject('No mail');
				}

				GenericMapper.map(mail, Attributes, function (err, mappedMail) {
					if (err) {
						return defered.reject('mail mapping error: ' + err);
					}

					if (mail.id) {
						return Resource.update({ id: mail.id }, { mail: mappedMail }, defered.resolve, defered.reject);
					}

					Resource.create({ mail: mappedMail }, defered.resolve, defered.reject);
				});

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(options, res.data, !mail.id);
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, !mail.id, err);
					});

				return defered.promise;
			},

			saveMulti: function (mail, options) {
				var defered = $q.defer();

				if (!mail) {
					return defered.reject('No mail');
				}
				if (!mail.recipients || !mail.recipients.length) {
					return defered.reject('No recipients');
				}

				GenericMapper.map(mail, Attributes, function (err, mappedMail) {
					if (err) {
						return defered.reject('mail mapping error: ' + err);
					}
					Resource.createMulti(mappedMail, defered.resolve, defered.reject);
				});

				defered.promise
					.then(function (res) {
						// Notify user
						helper.onSave(
							{
								skipEvent: true,
								createSuccessBody: result => {
									const scheduled = isScheduled(mail.date);
									const total = mail.recipients.length;
									const partial = result.length;
									const d = moment(mail.sendDate || mail.date).format('L LT');

									return mail.recipients.length - result.length > 0
										? scheduled
											? `${$translate.instant('mail.mailMultiPartialWillBeSent', {
													total,
													partial
											  })} ${d}`
											: $translate.instant('mail.mailMultiPartialWasSent', { total, partial })
										: scheduled
										? `${$translate.instant('mail.mailMultiAllWillBeSent', { total })} ${d}`
										: $translate.instant('mail.mailMultiAllWasSent', { total });
								},
								createSuccessTitle: mail => {
									const scheduled = isScheduled(mail.date);
									return scheduled ? 'mail.mailScheduled' : 'mail.sent';
								}
							},
							res.data,
							true
						);
					})
					.catch(function (err) {
						// Notify user
						helper.onSaveError(options, true, err);
					});

				return defered.promise;
			},

			delete: function (mail, options) {
				return Resource.delete({ id: mail.id })
					.$promise.then(function (res) {
						// sometimes we only get contacts in an array (when we get mail from listActivities) so we fix this
						if (!mail.contact && mail.contacts && mail.contacts.length) {
							mail.contact = mail.contacts[0];
						}
						// Notify user
						helper.onDelete(options, mail);

						return res;
					})
					.catch(function () {
						// Notify user
						helper.onDeleteError(options);
					});
			},

			mailBodyPreview: function (type, id) {
				if (!id) {
					id = type;
					type = 'mail';
				}
				var baseUrl = URL + API + 'file/mailBodyPreview/' + type + '/' + id;
				return baseUrl;
			},
			attr: Attributes,
			keys: Keys,
			requiredFields: requiredFields
		};

		return Model;
	}
]);

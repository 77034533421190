import async from 'async';

angular.module('upModals.controllers').controller('NewMarketEvent', [
	'$scope',
	'$rootScope',
	'$q',
	'$modalParams',
	'AppService',
	'$upModal',
	'NotificationService',
	'Event',
	function ($scope, $rootScope, $q, $modalParams, AppService, $upModal, NotificationService, Event) {
		var MarketEventCtrl = this;
		MarketEventCtrl.customerId = AppService.getCustomerId();
		MarketEventCtrl.account = $modalParams.account;
		MarketEventCtrl.contact = $modalParams.contact;
		MarketEventCtrl.loading = true;
		MarketEventCtrl.score = 0;

		MarketEventCtrl.save = function () {
			var newEvent = {
				score: MarketEventCtrl.score || 0,
				client: MarketEventCtrl.account.id,
				entityType: 'Manual',
				subType: 'ManualAdjustment',
				value: MarketEventCtrl.value || ''
			};
			if (MarketEventCtrl.contact) {
				newEvent.contact = MarketEventCtrl.contact.id;
			}
			Event.customer(MarketEventCtrl.customerId)
				.save(newEvent)
				.then(function (res) {
					NotificationService.addNotification({
						title: 'default.saved',
						body: 'saved.marketEvent',
						style: 'Success',
						icon: 'check'
					});
					$rootScope.$broadcast('market.added', res.data);
					$scope.resolve();
				})
				.catch(function () {
					NotificationService.addNotification({
						title: 'default.error',
						body: 'saveError.marketEvent',
						style: 'Error',
						icon: 'times'
					});
				});
		};

		var init = function () {
			async.series(
				[
					function (cb) {
						return cb();
					}
				],
				function (err) {
					if (err) {
						MarketEventCtrl.err = err;
					}
					MarketEventCtrl.loading = false;
				}
			);
		};

		init();
	}
]);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon, Textarea, Row, Column, Text, Avatar, Title, Loader } from '@upsales/components';
import './Notes.scss';
import { getNotes, setMessage, submitNote, setOrderId } from 'Store/reducers/EditOrderReducer';

const mapStateToProps = state => ({
	notes: state.EditOrder.groupedNotes,
	message: state.EditOrder.message,
	submitting: state.EditOrder.submitting,
	loading: state.EditOrder.loading
});

const mapDispatchToProps = {
	getNotes,
	setMessage,
	submitNote,
	setOrderId
};

class Notes extends React.Component {
	constructor(props) {
		super(props);

		this.props.setOrderId(this.props.orderId);

		this.props.getNotes();

		this.keepScrollBottom = true;

		const t = Tools.$translate;

		this.lang = {
			thereAreNoMessages: t('notes.thereAreNoMessages'),
			writeAMessage: t('notes.writeAMessage')
		};
	}

	onKeyDown(e) {
		if (e.keyCode === 13 && e.shiftKey) {
			// Just allow it
			return;
		}
		if (this.props.submitting || !this.props.message) {
			return;
		}
		if (!this.props.submitting && this.props.message && e.keyCode === 13) {
			e.preventDefault();
			this.props.submitNote();
		}
	}

	renderSeparator(date) {
		return (
			<div key={date} className="Notes__separator">
				<div className="Notes__separator-inner">{date}</div>
			</div>
		);
	}

	renderNote({ id, user = { name: 'Unknown User', email: '' }, message, date }) {
		return (
			<div key={'note_n' + id} className="Notes__note">
				<div className="Notes__sender">
					<Avatar className="Notes__avatar" initials={user.name} email={user.email} size="sm" />
					<div className="Notes__sender-name">{user.name}</div>
					<div className="Notes__sender-time">{moment(date).format('HH:mm')}</div>
				</div>
				<Text color="grey-11" className="Notes__message">
					{message}
				</Text>
			</div>
		);
	}

	onScroll(e) {
		if (e.target.scrollTop + e.target.clientHeight === this._messages.scrollHeight) {
			this.keepScrollBottom = true;
		} else {
			this.keepScrollBottom = false;
		}
	}

	setMessagesHeight() {
		setTimeout(() => {
			this._messages.style.bottom = this._compose.clientHeight + 'px';
			const textarea = this._hidden ? this._hidden._textarea : null;
			if (!textarea) {
				return;
			}
			if (textarea.scrollHeight >= 270) {
				this._compose.classList.add('Notes__compose--fixed-height');
			} else {
				this._compose.classList.remove('Notes__compose--fixed-height');
			}
		}, 10);
	}

	scrollToBottom() {
		this._messages.scrollTop = this._messages.scrollHeight - this._messages.clientHeight;
	}

	componentDidMount() {
		this.setMessagesHeight();
		this.scrollToBottom();
	}

	componentDidUpdate() {
		this.setMessagesHeight();
		if (this.keepScrollBottom) {
			this.scrollToBottom();
		}
	}

	UNSAFE_componentWillUpdate() {
		if (this._messages.scrollTop === this._messages.scrollHeight) {
			this.keepScrollBottom = true;
		}
	}

	submitNote() {
		this.props.submitNote().then(() => this.scrollToBottom());
	}

	render() {
		return (
			<div className="Notes" ref={r => (this._wrapper = r)}>
				<div className="Notes__messages" ref={r => (this._messages = r)} onScroll={e => this.onScroll(e)}>
					{this.props.notes.length && !this.props.loading
						? this.props.notes.map(group => {
								const format = moment(group.date).format('YYYY-MM-DD');
								return (
									<div key={'group_' + format} className="Notes__day-group">
										{this.renderSeparator(format)}
										<div className="Notes__inner-wrapper">
											{group.messages.map(note => this.renderNote(note))}
										</div>
									</div>
								);
						  })
						: null}
					{!this.props.notes.length && !this.props.loading ? (
						<Title size="lg" className="Notes__no-notes" color="grey-10">
							{this.lang.thereAreNoMessages}
						</Title>
					) : null}
					{this.props.loading ? <Loader className="Notes__loader" /> : null}
				</div>
				<div className="Notes__compose" ref={r => (this._compose = r)}>
					<div className="Notes__inner-wrapper">
						<Row>
							<Column>
								<Textarea
									autoHeight
									className="Notes__textarea"
									placeholder={this.lang.writeAMessage}
									value={this.props.message}
									onChange={e => this.props.setMessage(e.target.value)}
									onKeyDown={e => this.onKeyDown(e)}
								/>
								<Textarea
									className="Notes__textarea-hidden"
									value={this.props.message}
									ref={r => (this._hidden = r)}
									autoHeight
								/>
							</Column>
							<Column fixedWidth={50} align="right">
								<Button
									className="Notes__send"
									onClick={() => this.submitNote()}
									loading={this.props.submitting}
									disabled={!this.props.message}
								>
									<Icon name="paper-plane" />
								</Button>
							</Column>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

Notes.propTypes = {
	notes: PropTypes.array,
	getNotes: PropTypes.func.isRequired,
	message: PropTypes.string,
	setMessage: PropTypes.func,
	submitNote: PropTypes.func,
	setOrderId: PropTypes.func,
	submitting: PropTypes.bool,
	orderId: PropTypes.number,
	loading: PropTypes.bool
};

Notes.defaultProps = {
	notes: []
};

export const detached = Notes;
const Component = connect(mapStateToProps, mapDispatchToProps)(Notes);

window.Notes = Component;

export default Component;
